import React, {useContext, useMemo} from "react";
import {
    DEFAULT_DELIVERY_PRICE,
    getDefaultTypeToShow,
    typeToPluralHebrewName,
    DEFAULT_DELIVERY_MIN_NOTICE
} from "./MarketplaceUtils";
import {AppContext} from "../AppContext";

export const DeliveryDisclaimers = ({product, deliveryOptions, minNoticeText}) => {
    const {me} = useContext(AppContext);

    const itemLimitText = useMemo(
        () =>
            deliveryOptions?.itemLimit ? (
                <span>
                    * הזמנה{" "}
                    <b>
                        גדולה מ-{deliveryOptions.itemLimit} {typeToPluralHebrewName(getDefaultTypeToShow(product))}
                    </b>{" "}
                    עשויה להיענות בזמן ארוך יותר מהכתוב לעיל.
                </span>
            ) : null,
        [deliveryOptions?.itemLimit]
    );

    return (
        <>
            {
                <div className="marketplace-category-item-details-regular-delivery-disclaimers">
                    <span className="marketplace-category-item-details-delivery-disclaimers-title">
                        תנאי מענה ושילוח
                    </span>
                    <div className="marketplace-category-item-details-delivery-disclaimers-body">
                        {me?.features?.fast_delivery ? (
                            <>
                                <span>
                                    * ההזמנה תיענה על ידי הספק <b>עד 4 שעות</b> מרגע ביצוע ההזמנה.
                                </span>
                                <span>
                                    * הזמנה בהתראה דחופה - מהיום להיום / מהיום למחר תזכה למענה תוך<b> 1 שעה</b> מרגע
                                    ביצוע ההזמנה.
                                </span>
                            </>
                        ) : null}
                        <span>
                            * זמן ההתראה הדרוש לאספקת מוצר זה הוא{" "}
                            <b>{minNoticeText || DEFAULT_DELIVERY_MIN_NOTICE + " שעות"} לפחות</b> מרגע ביצוע ההזמנה.
                        </span>
                        {itemLimitText}
                        <span>* מחיר השילוח עשוי להשתנות במקרים מיוחדים תוך עדכון ואישור הלקוח.</span>
                    </div>
                </div>
            }
        </>
    );
};
