import React, {useCallback, useContext, useMemo, useState} from "react";
import Icon, {
    AppstoreOutlined,
    BarChartOutlined,
    CalendarOutlined,
    DownOutlined,
    GlobalOutlined,
    HeartOutlined,
    HomeOutlined,
    KeyOutlined,
    LogoutOutlined,
    NotificationOutlined,
    PictureOutlined,
    ScheduleOutlined,
    SwitcherOutlined,
    TagsOutlined,
    TeamOutlined,
    UserOutlined,
    WhatsAppOutlined,
    BugOutlined,
    MailOutlined,
    CalculatorOutlined,
    UserSwitchOutlined
} from "@ant-design/icons";
import {Dropdown} from "antd";
import {useLocation, withRouter} from "react-router";
import {AppContext} from "../AppContext";
import {openWindow} from "../http/WindowOpener";
import {getLogger} from "../Logger";
import {EventBus} from "../bus/EventBus";
import {ADMIN_PATH_NAME} from "../admin/consts";
import {hasEXCenterFeatureOpen, isCompanySubAdmin} from "../center/EXCenterHelper";
import {ContactPhone as contactPhone} from "../support/ContactPhone";
import {ResetPasswordModal} from "../company/ResetPasswordModal";
import {Languages} from "../auth/Login";
import "./app-menu.css";
import {googleLogout} from "@react-oauth/google";
import {useAuth0} from "@auth0/auth0-react";
import {BusinessIcons, InvoicesIcon, OrderIcons} from "../icons/index.jsx";

const AppMenu = ({isDashboard, style, withLaunchAdmin, history}) => {
    const {me, isMobile, company, isolationMode} = useContext(AppContext);
    const [resetPasswordModalVisibility, setResetModalVisibility] = useState(false);

    const location = useLocation();
    const {isAuthenticated: isAuthenticatedByOkta, logout: oktaLogout} = useAuth0();

    const log = useMemo(() => getLogger({}, "AppMenu"), []);

    if (me?.type === "employee" && (!company || hasEXCenterFeatureOpen(me, company))) {
        return null;
    }

    const isAdminDashboard = useMemo(() => {
        return location.pathname.indexOf(ADMIN_PATH_NAME) >= 0;
    }, [location]);

    const signOut = useCallback(async () => {
        googleLogout();

        if (isAuthenticatedByOkta) {
            await oktaLogout();
        }

        openWindow("/api/logout", "_self");
    }, [isAuthenticatedByOkta, oktaLogout]);

    let items = [
        {
            disabled: true,
            key: "name",
            label: me?.firstName || me?.companyName,
            className: "app-menu-menu-company-title"
        },
        {
            key: "home",
            icon: <HomeOutlined className="app-menu-menu-item-icon-black" />,
            label: "דף הבית",
            onClick: () => history.push("/dashboard/services")
        }
    ];

    switch (me.type) {
        case "company":
            items.push(
                {
                    key: "services",
                    icon: <AppstoreOutlined style={{color: "var(--secondary-color)", fontSize: 14}} />,
                    label: hasEXCenterFeatureOpen(me, company) ? "Market" : "Services",
                    onClick: () => history.push("/dashboard/services"),
                    className: withLaunchAdmin ? null : "wb-flex-mobile-only"
                },
                {
                    key: "orders",
                    icon: <BarChartOutlined className="app-menu-menu-item-icon-black" />,
                    label: "ניהול הזמנות",
                    onClick: () => history.push(`/company/orders/${company?.companyId}`)
                }
            );

            if (isCompanySubAdmin(me)) {
                break;
            }

            items.push({
                key: "branding",
                icon: <TagsOutlined className="app-menu-menu-item-icon-black" />,
                label: "שינוי לוגו",
                onClick: () => EventBus.trigger("branding:open")
            });

            if (me.features?.paying_companies) {
                if (hasEXCenterFeatureOpen(me, company) && !withLaunchAdmin) {
                    items.push({
                        key: "ex_center",
                        icon: <GlobalOutlined style={{color: "var(--secondary-color)", fontSize: 14}} />,
                        label: "Launch Employee Center",
                        onClick: () => history.push("/center/branches/direct")
                    });
                }
                if (hasEXCenterFeatureOpen(me, company) && withLaunchAdmin) {
                    items.push({
                        key: "dashboard",
                        icon: <GlobalOutlined style={{color: "var(--secondary-color)", fontSize: 14}} />,
                        label: "Launch admin center",
                        onClick: () => history.push("/dashboard")
                    });

                    items.push({
                        key: "dashboard",
                        icon: <SwitcherOutlined style={{color: "var(--secondary-color)", fontSize: 14}} />,
                        label: "Switch site",
                        onClick: () => history.push("/center/branches/direct")
                    });
                }

                items.push({
                    key: "schedule",
                    icon: <CalendarOutlined style={{color: "var(--secondary-color)", fontSize: 14}} />,
                    label: hasEXCenterFeatureOpen(me, company) ? "Activity Board" : "Schedule",
                    onClick: () => history.push("/dashboard/schedule"),
                    className: withLaunchAdmin ? null : "wb-flex-mobile-only"
                });
            }

            break;
        case "employee":
            items.push(
                {
                    key: "schedule",
                    label: "Schedule",
                    icon: <CalendarOutlined style={{color: "var(--secondary-color)", fontSize: 14}} />,
                    onClick: () => history.push("/dashboard/schedule")
                },
                {
                    key: "bookings",
                    label: "Bookings",
                    icon: <ScheduleOutlined style={{color: "var(--secondary-color)", fontSize: 14}} />,
                    onClick: () => history.push("/dashboard/bookings")
                },
                {
                    key: "employees",
                    label: "Co-workers",
                    icon: <TeamOutlined style={{color: "var(--secondary-color)", fontSize: 14}} />,
                    onClick: () => history.push("/dashboard/employees")
                }
            );
            break;
        default:
            break;
    }

    //TODO: Merge switches

    switch (me.type) {
        case "company":
            if (me.isCompanyMaster || isCompanySubAdmin(me)) {
                items.push({
                    key: "companyProfile",
                    id: "companyProfile",
                    icon: <UserOutlined className="app-menu-menu-item-icon-black" />,
                    label: "הגדרות חשבון",
                    onClick: () => history.push(`/company/profile/${company?.companyId}`)
                });
            }
            if (me.features.orders_calendar) {
                items.push({
                    key: "ordersCalendar",
                    icon: <CalendarOutlined className="app-menu-menu-item-icon-black shine-animation" />,
                    label: "יומן הרווחה שלי",
                    onClick: () => history.push("/dashboard/orders-calendar")
                });
            }

            if (me.features.pinterest && !isolationMode) {
                items.push({
                    key: "eventsGallery",
                    icon: <PictureOutlined className="app-menu-menu-item-icon-black shine-animation" />,
                    label: "פינטרסט סטייל",
                    onClick: () => history.push("/dashboard/gallery")
                });
            }

            items.push({
                key: "likesItems",
                icon: <HeartOutlined className="app-menu-menu-item-icon-black" />,
                label: "המוצרים שאהבתי",
                onClick: () => history.push("/dashboard/mylikes")
            });
            break;
        case "employee":
            items.push({
                key: "notifications",
                icon: <NotificationOutlined style={{color: "var(--secondary-color)", fontSize: 14}} />,
                label: "Notifications",
                onClick: () => history.push("/unsubscribe")
            });
            break;
        default:
            break;
    }

    if (me?.isAdmin) {
        items.push({
            key: "managementDashboard",
            id: "managementDashboard",
            icon: <KeyOutlined className="app-menu-menu-item-icon-black" />,
            label: "מעבר לאדמין",
            onClick: () => history.push(`/${ADMIN_PATH_NAME}/orders`)
        });
    }

    if (!me?.features.hide_contact_box) {
        items.push({
            key: "contact",
            icon: <WhatsAppOutlined className="app-menu-menu-item-icon-black" />,
            label: "יצירת קשר",
            onClick: () => contactPhone.openWhatsApp()
        });
    }

    if (me.type === "provider") {
        items.push({
            key: "changePassword",
            label: "שינוי סיסמה",
            icon: <KeyOutlined className="app-menu-menu-item-icon-black" />,
            onClick: () => {
                setResetModalVisibility(true);
            }
        });

        items.push({
            key: "settings",
            label: "פרטי בית העסק",
            icon: <Icon component={BusinessIcons} className="app-menu-menu-item-icon-black" />,
            onClick: () => history.push("/dashboard/settings")
        });

        items.push({
            key: "orders",
            label: "ניהול הזמנות",
            icon: <Icon component={OrderIcons} className="app-menu-menu-item-icon-black" />,
            onClick: () => history.push("/dashboard/orders")
        });

        items.push({
            key: "products",
            label: "מוצרים פעילים",
            icon: <KeyOutlined className="app-menu-menu-item-icon-black" />,
            onClick: () => history.push("/dashboard/products")
        });

        items.push({
            key: "invoices",
            label: "העלאת חשבונית",
            icon: <Icon component={InvoicesIcon} className="app-menu-menu-item-icon-black" />,
            onClick: () => history.push("/dashboard/invoices")
        });
    }

    if (me.isAdmin && isAdminDashboard) {
        items = [].concat([
            {
                key: "users",
                label: "משתמשים",
                onClick: () => {
                    history.push(`/${ADMIN_PATH_NAME}/users`);
                }
            },
            {
                key: "providers",
                label: "Professionals",
                onClick: () => history.push(`/${ADMIN_PATH_NAME}/providers`)
            },
            {
                key: "professions",
                label: "Professions",
                onClick: () => {
                    history.push(`/${ADMIN_PATH_NAME}/professions`);
                }
            },
            {
                key: "billing",
                label: "Billing",
                onClick: () => {
                    history.push(`/${ADMIN_PATH_NAME}/billing`);
                }
            },
            {
                key: "coupons",
                label: "Coupons",
                onClick: () => {
                    history.push(`/${ADMIN_PATH_NAME}/coupons`);
                }
            },
            {
                key: "locations",
                label: "Locations",
                onClick: () => {
                    history.push(`/${ADMIN_PATH_NAME}/locations`);
                }
            },
            {
                key: "userManagement",
                label: "ניהול משתמשים",
                icon: <UserSwitchOutlined className="app-menu-menu-item-icon-black" />,
                onClick: () => {
                    history.push(`/${ADMIN_PATH_NAME}/userManagement`);
                }
            },
            {
                key: "reviews",
                label: "ביקורות",
                onClick: () => {
                    history.push(`/${ADMIN_PATH_NAME}/reviews`);
                }
            },
            {
                key: "calculator",
                label: "פרוייקט המחשבון",
                icon: <CalculatorOutlined className="app-menu-menu-item-icon-black" />,
                onClick: () => {
                    history.push(`/${ADMIN_PATH_NAME}/calculator`);
                }
            },
            {
                key: "productBundles",
                label: "מוצרים משולבים",
                onClick: () => {
                    history.push(`/${ADMIN_PATH_NAME}/productBundles`);
                }
            },
            {
                key: "categories",
                label: "קטגוריות",
                onClick: () => {
                    history.push(`/${ADMIN_PATH_NAME}/categories`);
                }
            },
            {
                key: "companyAssignCSM",
                label: "הקצאת מנהלות לקוח",
                onClick: () => {
                    history.push(`/${ADMIN_PATH_NAME}/companyAssignCSM`);
                }
            },
            {
                key: "alertsManagement",
                label: "מרכז ההתראות",
                icon: <NotificationOutlined className="app-menu-menu-item-icon-black" />,
                onClick: () => {
                    history.push(`/${ADMIN_PATH_NAME}/alertsManagement`);
                }
            },
            {
                key: "delivery",
                label: "מעקב סטאטוס מיילים",
                icon: <MailOutlined className="app-menu-menu-item-icon-black" />,
                onClick: () => {
                    history.push(`/${ADMIN_PATH_NAME}/delivery`);
                }
            },
            {
                key: "transactions",
                label: "Transactions",
                onClick: () => {
                    history.push(`/${ADMIN_PATH_NAME}/transactions`);
                }
            },
            {
                key: "upload",
                label: "Upload",
                onClick: () => {
                    history.push(`/${ADMIN_PATH_NAME}/upload`);
                }
            },
            me.features.admin_debug
                ? {
                      key: "debug",
                      label: "מרכז המתכנתים",
                      icon: <BugOutlined className="app-menu-menu-item-icon-black" />,
                      onClick: () => {
                          history.push(`/${ADMIN_PATH_NAME}/debug`);
                      }
                  }
                : null
        ]);
    }

    if (me.isAdmin && isAdminDashboard) {
        items.push({
            key: "forgot",
            label: "Forgot password",
            onClick: () => history.push("/forgot?back=true")
        });
    }

    items.push({
        key: "logout",
        label: "התנתקות",
        icon: <LogoutOutlined className="app-menu-menu-item-icon-black" />,
        className: "app-menu-menu-item-icon-red",
        onClick: signOut
    });

    const overlay = {
        items,
        className: "wb-app-menu",
        style: {border: "1px solid #E8EDF5"},
        onClick: ({key}) => {
            log("App Menu Item Clicked", {itemName: key});
        }
    };

    return (
        <div
            style={{
                display: "flex",
                zIndex: 100,
                ...style
            }}>
            <ResetPasswordModal
                onClose={() => setResetModalVisibility(false)}
                language={Languages.Hebrew}
                title="שינוי סיסמה"
                visible={resetPasswordModalVisibility}
            />
            <Dropdown
                trigger={isMobile ? ["click"] : ["hover"]}
                onOpenChange={visible => {
                    log("menu visible", {visible});
                }}
                placement="bottomRight"
                overlayClassName="wb-app-menu-overlay"
                className="wb-app-menu-drpdwn"
                menu={overlay}>
                <span className="app-menu-button">
                    תפריט
                    <DownOutlined />
                </span>
            </Dropdown>
        </div>
    );
};

export default withRouter(AppMenu);
