import React from "react";
import {MarketplaceFilterSearchOptions} from "../../MarketplaceFilterSearchOptions.jsx";
import {NOOP} from "../../../utils/NOOP.jsx";
import {FilterButtons} from "./FilterButtons.jsx";

export const MarketplaceFilterButtons = ({
    filterValues,
    onSortSelected,
    searchSorterType,
    setFilterValues,
    removeSearchInput = NOOP,
    showFilter = true,
    showDisplayBy = false,
    showSorter = true
}) => (
    <>
        <div className="marketplace-filter-container">
            <FilterButtons
                onSortSelected={onSortSelected}
                searchSorterType={searchSorterType}
                showFilter={showFilter}
                showDisplayBy={showDisplayBy}
                showSorter={showSorter}
            />
        </div>
        {showFilter && (
            <MarketplaceFilterSearchOptions
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                removeSearchInput={removeSearchInput}
            />
        )}
    </>
);