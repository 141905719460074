import React from "react";
import moment from "moment";
import {Switch, Tooltip} from "antd";
import {DeleteFilled, EditFilled, EyeFilled, LinkOutlined, SnippetsOutlined, SyncOutlined} from "@ant-design/icons";
import {TableDateCell} from "../components";
import {ConfirmationRequiredWrapper} from "../../components";
import {isObjectWithEmptyFields} from "../../utils/objectUtils";

export const columns = (
    changeActiveStatus,
    openProductBundle,
    copyProductBundleLink,
    onEditProductBundle,
    deleteProductBundle
) => {
    return [
        {
            title: "#",
            key: "#",
            width: 70,
            render(_, __, idx) {
                return `${idx + 1}`;
            }
        },
        {
            title: "מזהה",
            dataIndex: "productBundleId"
        },
        {
            title: "שם מוצר משולב",
            dataIndex: "name"
        },
        {
            title: "תאריך יצירה",
            dataIndex: "createdAt",
            sorter: ({createdAt: r1}, {createdAt: r2}) => moment(r1) - moment(r2),
            render(createdAt) {
                return <TableDateCell date={createdAt} />;
            }
        },
        {
            title: "תאריך עדכון אחרון",
            dataIndex: "updatedAt",
            sorter: ({updatedAt: r1}, {updatedAt: r2}) => moment(r1) - moment(r2),
            render(updatedAt) {
                return <TableDateCell date={updatedAt} />;
            }
        },
        {
            title: "מוצרים",
            dataIndex: "productIds",
            sorter: (r1, r2) => r1.productsIds.length - r2.productsIds.length,
            render(productIds) {
                return <div>{productIds.length}</div>;
            }
        },
        {
            title: "סטטוס",
            filters: [
                {
                    text: "פעיל",
                    value: true
                },
                {
                    text: "לא פעיל",
                    value: false
                }
            ],
            filterMultiple: false,
            onFilter: (value, record) => record.isActive === value,
            dataIndex: "isActive",
            render(isActive, {productBundleId}) {
                return (
                    <Switch
                        checked={!!isActive}
                        onChange={newActiveStatus => changeActiveStatus(productBundleId, newActiveStatus)}
                        checkedChildren="פעיל"
                        unCheckedChildren="לא פעיל"
                    />
                );
            }
        },
        {
            key: "actions",
            render(_, productBundle) {
                return (
                    <div className="admin-product-table-actions-container">
                        <EyeFilled
                            onClick={() => {
                                openProductBundle(productBundle.productBundleId);
                            }}
                        />
                        <EditFilled onClick={() => onEditProductBundle(productBundle.productBundleId)} />
                        <LinkOutlined
                            onClick={() => {
                                copyProductBundleLink(productBundle.productBundleId);
                            }}
                        />
                        <ConfirmationRequiredWrapper
                            onYes={() => {
                                deleteProductBundle(productBundle.productBundleId);
                            }}
                            subTitle="האם אתה בטוח שברצונך למחוק את המוצר המשולב?">
                            <DeleteFilled />
                        </ConfirmationRequiredWrapper>
                    </div>
                );
            }
        }
    ];
};
