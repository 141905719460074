import React from "react";
import {ConfigProvider} from "antd";
import {DynamicForm} from "../../../components/DynamicForm";
import "./company-invoice-manage.css";

export const CompanyInvoiceManageForm = ({formFields, financeInfo, onUpdateForm, onSave}) => {
    return (
        <div className="company-invoice-manage">
            <div className="top-layout">
                <span className="title">הגדרת חשבונית</span>
                <div>
                    <div>אנא הגדירו את הפרטים הנכונים להפקת החשבוניות בחשבונכם.</div>
                    <div>יש לשים 💙 כי על הפרטים להיות מדויקים ככל האפשר על מנת למנוע תקלות התחשבנות בהמשך.</div>
                </div>
            </div>

            <ConfigProvider direction="rtl">
                <DynamicForm
                    validationFunction={async values => {
                        await onSave(values);
                        return false;
                    }}
                    content={financeInfo}
                    onUpdate={onUpdateForm}
                    fields={formFields}
                />
            </ConfigProvider>
        </div>
    );
};
