import CallToActionModal from "../components/CallToActionModal";
import React from "react";
import {Typography} from "antd";
import {ModalTitle} from "../components/ModalTitle";

export const EventAlreadyAcceptedModal = ({visible, history}) => {
    const Title = () => <ModalTitle title="Ohh..." subTitle="Someone was faster than you..." />;

    const SubTitle = () => (
        <div style={{display: "flex", flexDirection: "column"}}>
            <Typography.Paragraph style={{textAlign: "center"}}>
                Meanwhile, you can check out your calendar for your upcoming sessions.
            </Typography.Paragraph>
        </div>
    );

    return (
        <CallToActionModal
            imageName="oops"
            title={<Title />}
            subTitle={<SubTitle />}
            actionText="Check out my calendar"
            actionHandler={() => history.push("/dashboard/schedule")}
            visible={visible}
        />
    );
};
