import React, {useEffect, useMemo, useState} from "react";
import {Button, Modal, Upload, Badge} from "antd";
import Icon from "@ant-design/icons";
import _ from "lodash";
import {CloseIcon, UploadIcon} from "../icons";
import {CancelButton, SaveButton} from "../components/form";
import {EventBus} from "../bus/EventBus";
import {UploadClient} from "../http/UploadClient";
import {HttpClient} from "../http/HttpClient";
import {Images} from "../images/Images";
import {moveSelectedImageToStart} from "../marketplace/MarketplaceHelper";

const pictureIndexes = _.times(6, Number);

const loadPhotos = portfolioImages => {
    return Array.isArray(portfolioImages)
        ? portfolioImages.filter(Boolean).reduce((list, {imageId, imageUrl}, idx) => {
              list[idx] = {imageUrl, imageId};
              return list;
          }, {})
        : {};
};

export const ProviderPortfolioUploadModal = ({onClose, visible, providerDetails, providerNonExist, onSave}) => {
    const [fileList, setFileList] = useState(loadPhotos(providerDetails ? providerDetails.portfolioImages : []));
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [uploading, setUploading] = useState(_.times(pictureIndexes.length, _.constant(false)));
    const [saving, setSaving] = useState(false);
    const [uploadedImages, setUploadedImages] = useState([]);

    useEffect(() => {
        if (providerDetails?.portfolioImages) {
            setFileList(loadPhotos(providerDetails?.portfolioImages));
        }
    }, [providerDetails]);

    useEffect(() => {
        setSelectedImageIndex(0);
    }, [visible]);

    const getPortfolioImages = () => {
        return Object.keys(fileList)
            .filter(idx => !!fileList[idx])
            .map(idx => ({
                imageId: fileList[idx].imageId,
                imageUrl: fileList[idx].imageUrl
            }));
    };

    const savePhotos = async () => {
        setSaving(true);
        let portfolioImages = getPortfolioImages();
        portfolioImages = moveSelectedImageToStart(portfolioImages, selectedImageIndex ?? 0);
        portfolioImages = portfolioImages.filter(image => image);
        if (providerNonExist) {
            onSave(portfolioImages);
        } else {
            try {
                await HttpClient.post("/api/providers/me/portfolio", {portfolioImages, uploadedImages});
                providerDetails.portfolioImages = portfolioImages;
                setTimeout(onClose, 250);
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we couldn't save your portfolio :("}},
                    e.message
                );
            }
        }
        setSaving(false);
    };

    const reset = () => {
        setFileList(loadPhotos(providerDetails ? providerDetails.portfolioImages : []));
        if (!providerNonExist && uploadedImages.length > 0) {
            const candidatesToRemove = _.cloneDeep(uploadedImages);
            Promise.resolve().then(async () => {
                try {
                    await HttpClient.post("/api/providers/me/portfolio", {
                        portfolioImages: providerDetails.portfolioImages?.filter(image => image) || [],
                        uploadedImages: candidatesToRemove?.filter(image => image)
                    });
                } catch (e) {}
            });
        }
        onClose();
    };

    const uploadSingle = async (file, idx) => {
        uploading[idx] = true;
        setUploading(_.clone(uploading));
        try {
            const formData = new FormData();
            formData.append("file", file);
            const {imageId, imageUrl} = await UploadClient.upload(
                "PUT",
                providerNonExist ? "/api/portfolio/create/image" : "/api/portfolio/image",
                formData
            );
            setUploadedImages(uploadedImages.concat([{imageId, imageUrl}]));
            setFileList(Object.assign({}, fileList, {[idx]: {imageUrl, imageId, file}}));
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we couldn't upload your image :("}},
                e.message
            );
        }
        uploading[idx] = false;
        setUploading(_.clone(uploading));
    };

    const isUploading = useMemo(() => {
        return uploading.some(isUploading => isUploading);
    }, [uploading]);

    if (!visible && uploadedImages.length > 0) {
        setUploadedImages([]);
    }

    return (
        <Modal
            className="wb-portfolio-upload-modal"
            title="הוספת תמונות למוצר"
            open={visible}
            onCancel={reset}
            bodyStyle={{padding: 0}}
            footer={[
                <CancelButton
                    disabled={isUploading}
                    text="ביטול"
                    onClick={reset}
                    key="cancelButton"
                    style={{border: "1px solid #1A344A", color: "#1A344A"}}
                />,
                <SaveButton
                    disabled={isUploading}
                    text="שמור תמונות"
                    loading={saving}
                    key="saveButton"
                    onClick={savePhotos}
                    style={{marginLeft: 10, backgroundColor: "#1A344A"}}
                />
            ]}>
            <div className="wb-portfolio-upload-list">
                {pictureIndexes.map(idx =>
                    fileList[idx] && fileList[idx].imageUrl ? (
                        <div
                            key={`upload-${idx}`}
                            style={{display: "flex", justifyContent: "center"}}
                            onClick={() => setSelectedImageIndex(idx)}>
                            <Badge.Ribbon
                                placement={"start"}
                                text="תמונה ראשית"
                                color="#ED7FA6"
                                style={{visibility: selectedImageIndex === idx ? "visible" : "hidden"}}>
                                <div
                                    className="wb-image-container"
                                    style={{
                                        border: selectedImageIndex === idx ? "5px solid #ED7FA6" : "1px solid #E8EDF5",
                                        color: "var(--secondary-color)",
                                        minHeight: 150,
                                        maxHeight: 150,
                                        minWidth: 250,
                                        maxWidth: 250,
                                        backgroundImage: `url(${fileList[idx].imageUrl})`,
                                        borderRadius: 10
                                    }}>
                                    <CloseIcon
                                        onClick={e => {
                                            e.preventDefault();
                                            setFileList(Object.assign({}, fileList, {[idx]: null}));
                                        }}
                                        style={{position: "absolute", right: 7, top: 7, cursor: "pointer"}}
                                    />
                                </div>
                            </Badge.Ribbon>
                        </div>
                    ) : (
                        <Upload
                            disabled={isUploading}
                            name="file"
                            accept="image/png,image/jpeg"
                            showUploadList={false}
                            className="wb-portfolio-upload-card"
                            key={`upload-${idx}`}
                            beforeUpload={async file => {
                                if (file.size >= Images.SIZE_LIMIT) {
                                    EventBus.trigger("server-error", {
                                        content: {
                                            title: "Ohhh... Gush!",
                                            subTitle: "This is a huge file!",
                                            description: `The maximum allowed image size is ${
                                                Images.SIZE_LIMIT / 1024 / 1024
                                            }MB, your image is ${parseInt(file.size / (1024 * 1024))}MB.`,
                                            hideSteps: true
                                        },
                                        cta: {
                                            hide: true
                                        }
                                    });
                                } else {
                                    await uploadSingle(file, idx);
                                    return false;
                                }
                            }}>
                            <Button
                                disabled={isUploading}
                                loading={uploading[idx]}
                                type="link"
                                style={{
                                    fontSize: 16,
                                    padding: 7,
                                    color: "var(--secondary-color)",
                                    opacity: isUploading ? 0.65 : 1
                                }}>
                                <Icon component={UploadIcon} style={{fontSize: 20}} /> העלאת תמונה
                            </Button>
                        </Upload>
                    )
                )}
            </div>
        </Modal>
    );
};
