import {isPrivateEmail} from "../../components/form/validators.jsx";
import common from "@well-b/well-b-shared";
import {PHONE_NUMBER_ERROR_VALIDATION} from "../../marketplace/consts.jsx";

export const REGISTER_FORM_FIELDS = [
    {
        name: "name",
        type: "string",
        label: "שם החברה"
    },
    {
        name: "businessNumber",
        type: "number",
        label: "מס׳ ח.פ",
        rules: [
            {
                validator: (_, value) => {
                    const regexPattern = /^(5[0-9]|[6-8][0-9]|59)\d{7}$/;

                    if (value && !regexPattern.test(value)) {
                        return Promise.reject("מס' ח.פ אינו תקין: יש להזין מס' בן 9 ספרות");
                    } else {
                        return Promise.resolve();
                    }
                }
            }
        ]
    },
    {
        name: "email",
        type: "email",
        label: "כתובת מייל ארגוני",
        rules: [
            {
                validator: (_, value) => {
                    if (isPrivateEmail(value)) {
                        return Promise.reject("אנא הכנס מייל חברה");
                    }

                    return Promise.resolve();
                }
            }
        ]
    },
    {
        name: "contactName",
        type: "string",
        label: "שם מלא"
    },
    {
        name: "phone",
        type: "string",
        label: "מספר טלפון נייד",
        rules: [
            {
                validator: (_, value) => {
                    return value && !common.isValidPhoneMobileNumber(value)
                        ? Promise.reject(PHONE_NUMBER_ERROR_VALIDATION)
                        : Promise.resolve();
                }
            }
        ]
    }
];
