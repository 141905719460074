import React, {useState} from "react";
import moment from "moment-timezone";
import {Button} from "antd";
import {ArrowLeftOutlined, ArrowRightOutlined, DollarOutlined} from "@ant-design/icons";
import {BalanceInfoCard} from "../../billing/BalanceInfoCard";
import creditCardIcon from "../../images/icons/credit_card.svg";
import appointmentIcon from "../../images/icons/appointment.svg";
import {BookingIcon} from "../../icons";
import {Redirect, Route, Switch, withRouter} from "react-router";
import {AdminBillingSummary} from "./AdminBillingSummary";
// import MultiToggle from "react-multi-toggle";
import {AdminBillingToBePaid} from "./AdminBillingToBePaid";
import {AdminBillingToBePaidTransactions} from "./AdminBillingToBePaidTransactions";
import {AdminBillingToBeReceived} from "./AdminBillingToBeReceived";

const ToggleOption = {
    Summary: 0,
    ToBePaid: 1,
    ToBeReceived: 2
};

const toggleOptions = [
    {
        displayName: "Summary",
        value: ToggleOption.Summary
    },
    {
        displayName: "To be paid",
        value: ToggleOption.ToBePaid
    },
    {
        displayName: "To be received",
        value: ToggleOption.ToBeReceived
    }
];

const pathNameToToggleOptionMap = {
    summary: ToggleOption.Summary,
    tobepaid: ToggleOption.ToBePaid,
    tobereceived: ToggleOption.ToBeReceived
};

const MonthNavigation = ({dateMom, onChange}) => {
    const prevWeek = () => {
        const newDate = moment(dateMom).subtract(1, "months");
        onChange(newDate);
    };

    const nextWeek = () => {
        const newDate = moment(dateMom).add(1, "months");
        onChange(newDate);
    };

    return (
        <div style={{display: "flex", width: 340, marginTop: 0}}>
            <Button
                onClick={prevWeek}
                style={{
                    minWidth: 50,
                    height: 50,
                    border: "1px solid #E9EDF4",
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                    borderTopRightRadius: "none",
                    borderBottomRightRadius: "none"
                }}
                icon={<ArrowLeftOutlined />}
            />
            <div
                style={{
                    width: "100%",
                    borderTop: "1px solid #E9EDF4",
                    borderBottom: "1px solid #E9EDF4",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 16,
                    color: "var(--secondary-color)",
                    fontWeight: 700
                }}>
                {dateMom.format("MMMM, YYYY")}
            </div>
            <Button
                onClick={nextWeek}
                style={{
                    minWidth: 50,
                    height: 50,
                    border: "1px solid #E9EDF4",
                    borderTopLeftRadius: "none",
                    borderBottomLeftRadius: "none",
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10
                }}
                icon={<ArrowRightOutlined />}
            />
        </div>
    );
};

const AdminBillingDashboard = ({history, location, match}) => {
    const {date} = match.params;
    const [currentDateMom, setCurrentDateMom] = useState(date ? moment(date, "YYYY-MM") : moment());
    const [toggle, setToggle] = useState(0);

    const currentPath = () => {
        const parts = location.pathname.split("/");
        return parts[parts.length - 1];
    };

    const pathNameToToggleOption = () => {
        const togglePath = currentPath();
        return pathNameToToggleOptionMap[togglePath];
    };

    const onToggleChange = val => {
        setToggle(val);
        const toggleHandler = {
            [ToggleOption.Summary]: () => history.replace(`/admin/billing/${currentDateMom.format("YYYY-MM")}/summary`),
            [ToggleOption.ToBePaid]: () =>
                history.replace(`/admin/billing/${currentDateMom.format("YYYY-MM")}/tobepaid`),
            [ToggleOption.ToBeReceived]: () =>
                history.replace(`/admin/billing/${currentDateMom.format("YYYY-MM")}/tobereceived`)
        };

        toggleHandler[val]();
    };

    const onDateChange = dateMom => {
        setCurrentDateMom(dateMom);
        history.replace(`/admin/billing/${dateMom.format("YYYY-MM")}/${currentPath()}`);
    };

    if (location.pathname === "/admin/billing") {
        return <Redirect to={`/admin/billing/${moment().format("YYYY-MM")}/summary`} />;
    }

    const currentToggle = pathNameToToggleOption();
    if (currentToggle !== toggle) {
        setToggle(currentToggle);
    }

    return (
        <Switch>
            <Route
                exact
                key={currentDateMom.format("YYYY-MM") + toggle}
                path="/admin/billing/:date/providers/:providerId/transactions"
                component={AdminBillingToBePaidTransactions}
            />
            <Route
                render={props => (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            width: "100%",
                            margin: 10,
                            marginBottom: 40
                        }}>
                        <div style={{display: "flex", flexDirection: "column", width: 1230}}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    height: 80,
                                    minWidth: 1000,
                                    justifyContent: "space-between"
                                }}>
                                <MonthNavigation dateMom={currentDateMom} onChange={onDateChange} />
                                <div style={{width: 370}}>
                                    <MultiToggle
                                        options={toggleOptions}
                                        selectedOption={toggle}
                                        onSelectOption={onToggleChange}
                                        className="wb-company-account-toggle wb-billing-toggle"
                                    />
                                </div>
                            </div>
                            <Switch>
                                <Route
                                    exact
                                    key={currentDateMom.format("YYYY-MM") + toggle}
                                    path="/admin/billing/:date/summary"
                                    component={AdminBillingSummary}
                                />
                                <Route
                                    exact
                                    key={currentDateMom.format("YYYY-MM") + toggle}
                                    path="/admin/billing/:date/tobepaid"
                                    component={AdminBillingToBePaid}
                                />
                                <Route
                                    exact
                                    key={currentDateMom.format("YYYY-MM") + toggle}
                                    path="/admin/billing/:date/tobereceived"
                                    component={AdminBillingToBeReceived}
                                />
                            </Switch>
                        </div>
                    </div>
                )}
            />
        </Switch>
    );
};

export default withRouter(AdminBillingDashboard);
