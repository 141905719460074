import {message} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {eventByDate} from "../../EventByDate";
import {dateFormat, timeFormat, toMomentDate} from "../../../utils/DateFormat";
import {transformEvent} from "../EventTransformer";
import {AppContext} from "../../../AppContext";
import {FrequencyMapper} from "../../FrequencyMapper";
import moment from "moment-timezone";
import {RRuleSetBuilder} from "../../RRuleSetBuilder";
import {HttpClient} from "../../../http/HttpClient";
import {DaysMapper} from "../../DaysMapper";
import {PriceType} from "../../../provider/ProviderServices";
import {DTStartCreator} from "./DTStartCreator";
import {EventEditForm} from "./EventEditForm";

export const EventInfoEditV2 = ({eventInfo, currentStart, providerInfo, onClose, onEventUpdate}) => {
    const {me, company} = useContext(AppContext);
    const dateMom = toMomentDate(currentStart);
    const [priceType, setPriceType] = useState(eventInfo.priceType || PriceType.GROUP);
    let [event, setEvent] = useState({
        ...transformEvent(me, eventByDate(eventInfo, dateMom.toDate()), providerInfo),
        startDate: dateMom,
        freq: FrequencyMapper.toFreqString(eventInfo),
        startTime: moment(currentStart)
    });

    const [editMode, setEditMode] = useState("occurrence");
    const [saving, setSaving] = useState(false);
    const [editDetails, setEditDetails] = useState(null);
    let [appointments, setAppointments] = useState(null);

    event = {
        ...transformEvent(me, event, providerInfo)
    };

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                if (event.appointmentBased) {
                    const appts = await fetchAppointments(true);
                    setAppointments(appts);
                }
            } catch (e) {
                message.error("Oopssy... something went wrong, please try again later...");
            }
        });
    }, []);

    const onEditFinish = async details => {
        setSaving(true);
        let employeesAreRegistered = false;
        if (event.appointmentBased && !Array.isArray(appointments)) {
            appointments = await fetchAppointments();
        }

        if (details.eventPhoto && details.eventPhoto.imageUrl) {
            details.productCoverPhoto = details.eventPhoto.imageUrl;
        }

        if (event.appointmentBased && isDateChanged(details)) {
            if (editMode === "recurring") {
                employeesAreRegistered = appointments.some(appt =>
                    moment(appt.date, "YYYY-MM-DD").isSameOrAfter(dateMom)
                );
            } else {
                employeesAreRegistered = appointments.some(appt => moment(appt.date, "YYYY-MM-DD").isSame(dateMom));
            }
        }

        if (editMode === "occurrence" && details.actionType === "externalLink" && details.externalLink) {
            details.externalLink.date = dateMom.format("YYYY-MM-DD");
        } else if (details.actionType === "externalLink" && details.externalLink) {
            details.externalLink.date = null;
        } else {
            details.externalLink = null;
        }

        details.actionType = details.actionType || "system";

        if (employeesAreRegistered) {
            setEditDetails(details);
        } else {
            await sendChanges(details);
        }

        setSaving(false);
    };

    const isDateChanged = ({startTime, startDate, freq, duration}) => {
        const dtstart = DTStartCreator.fromDateTime(startTime || moment(currentStart), startDate);
        if (editMode === "recurring") {
            const oldRule = RRuleSetBuilder.toRRule({
                ...eventInfo,
                dtstart: currentStart.getTime()
            });

            const weekday = toWeekday(startDate);
            const newRule = RRuleSetBuilder.toRRule({
                dtstart,
                freq,
                byweekday: weekday,
                tzid: event.tzid,
                duration: duration || event.duration
            });

            const newText = newRule.toString();
            const oldText = oldRule.toString();

            return newText !== oldText;
        } else {
            const excludeDate = toExcludeTimestamp();
            return excludeDate !== dtstart;
        }
    };

    const toWeekday = startDate => {
        const day = startDate.format("dddd");
        return [DaysMapper.mapDayToKey(day)];
    };

    const toExcludeTimestamp = () => {
        return moment(`${dateFormat(currentStart)} ${timeFormat(currentStart)}`, "YYYY-M-D HH:mm")
            .toDate()
            .getTime();
    };

    const fetchAppointments = async (throwError = false) => {
        try {
            return await HttpClient.get(`/api/events/${event.eventId}/appointments`);
        } catch (e) {
            if (throwError) {
                throw e;
            } else {
                message.error("Oopssy... something went wrong, please try again later...");
            }
        }
    };

    const sendChanges = async details => {
        setEditDetails(null);
        setSaving(true);

        try {
            const dtstart = DTStartCreator.fromDateTime(details.startTime, details.startDate);
            const offeredPrice = details.offeredPrice ? details.offeredPrice | 0 : event.offeredPrice || null;
            const duration = details.duration || event.duration;
            const durationType = event.durationType;
            const productCoverPhoto = details.productCoverPhoto || event.productCoverPhoto;

            if (editMode === "recurring") {
                const weekday = toWeekday(details.startDate);
                const {byweekday, freq, interval, until} = FrequencyMapper.map({
                    freq: details.freq,
                    byweekday: weekday,
                    dtstart,
                    tzid: event.tzid,
                    duration: details.duration || event.duration
                });
                const {originalEvent, newEvent} = await sendRecurringUpdate(eventInfo, {
                    ...details,
                    dtstart,
                    offeredPrice,
                    duration,
                    durationType,
                    productCoverPhoto,
                    freq,
                    byweekday,
                    interval,
                    until
                });

                onEventUpdate({originalEvent, newEvent}, "all");
            } else {
                const updatedEvent = await sendOccurrenceUpdate(eventInfo, {
                    ...details,
                    dtstart,
                    offeredPrice,
                    duration,
                    durationType,
                    productCoverPhoto
                });

                onEventUpdate(updatedEvent, "only");
            }
        } catch (e) {
            message.error("Opps... something went wrong, please try again later.", 3);
        }
        setSaving(false);
    };

    const sendRecurringUpdate = async (event, details) => {
        const {
            dtstart,
            freq,
            byweekday,
            interval,
            until,
            address,
            notes,
            maxParticipants,
            whoPays,
            duration,
            location,
            meetingLink,
            sessionName,
            productCoverPhoto,
            offeredPrice,
            branches,
            durationType,
            externalLink,
            actionType,
            backgroundColor
        } = details;

        const newUntil = moment(`${dateFormat(currentStart)} 00:00`, "YYYY-M-D HH:mm")
            .toDate()
            .getTime();

        const newRecurring = {
            dtstart,
            freq,
            byweekday,
            interval,
            until,
            duration,
            tzid: event.tzid
        };

        return await HttpClient.post(`/api/events/${event.eventId}/recurring`, {
            ...event,
            externalLink,
            actionType,
            address,
            notes,
            maxParticipants: parseInt(maxParticipants, 10),
            whoPays,
            duration,
            location,
            meetingLink,
            sessionName,
            productCoverPhoto,
            offeredPrice,
            priceType,
            branches,
            durationType,
            newUntil,
            backgroundColor,
            ...newRecurring
        });
    };

    const sendOccurrenceUpdate = async (event, details) => {
        const {
            dtstart,
            address,
            notes,
            maxParticipants,
            whoPays,
            duration,
            location,
            meetingLink,
            sessionName,
            productCoverPhoto,
            offeredPrice,
            branches,
            durationType,
            externalLink,
            actionType,
            backgroundColor
        } = details;

        const excludeDate = toExcludeTimestamp();
        const includeDate = dtstart;

        const updatedEvent = await HttpClient.post(`/api/events/${event.eventId}/occurrence`, {
            ...event,
            externalLink,
            actionType,
            address,
            notes,
            maxParticipants: parseInt(maxParticipants, 10),
            whoPays,
            duration,
            location,
            meetingLink,
            sessionName,
            productCoverPhoto,
            offeredPrice,
            priceType,
            branches,
            durationType,
            backgroundColor,
            excludeDate,
            includeDate
        });

        return {
            ...updatedEvent,
            includeDate,
            excludeDate
        };
    };

    return (
        <EventEditForm
            initialEvent={eventInfo}
            event={event}
            currentStart={currentStart}
            onFinish={onEditFinish}
            onCommitChanges={() => sendChanges(editDetails)}
            showConfirmMessage={!!editDetails}
            onConfirmMessageClose={() => setEditDetails(null)}
            onEventChange={evt => setEvent(evt)}
            editMode={editMode}
            onEditModeChange={mode => setEditMode(mode)}
            saving={saving}
            providerInfo={providerInfo}
            priceType={priceType}
            onPriceTypeChange={type => setPriceType(type)}
            onClose={() => onClose(eventInfo)}
        />
    );
};
