import React from "react";
import {Button, ConfigProvider, Dropdown, Menu, message, Tag} from "antd";
import {findIndex, clone} from "lodash";
import {MenuOutlined, PlusCircleFilled} from "@ant-design/icons";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import {AdminCategoriesAddSubCategory} from "./AdminCategoriesAddSubCategory";
import {AdminCategoriesEditSubCategory} from "./AdminCategoriesEditSubCategory";
import {AdminSubCategoriesController} from "./AdminSubCategoriesController";
import {RoundedTable, PageTitleHeader, SearchInput, PageLoader} from "../../components";
import {HttpClient} from "../../http/HttpClient";
import "./admin-categories.css";

const DragHandle = SortableHandle(() => <MenuOutlined style={{cursor: "grab", color: "#999"}} />);
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableCont = SortableContainer(props => <tbody {...props} />);

const columns = (removeSubCategory, editSubCategory, toggleHideSubCategory, onAddNewSubCategory, onEditCategory) => [
    {
        title: "Sort",
        dataIndex: "sort",
        width: 30,
        className: "drag-visible",
        render: () => <DragHandle />
    },
    {
        title: "#",
        key: "#",
        width: 10,
        render(_, __, idx) {
            return `${idx + 1}`;
        }
    },
    {
        title: "ID",
        dataIndex: "serviceId",
        key: "serviceId",
        width: 30
    },
    {
        title: "NAME",
        key: "name",
        width: 30,
        render(_, {categoryName}) {
            return <label style={{color: "#747F92", fontSize: 12, fontWeight: 400}}>{categoryName}</label>;
        }
    },
    {
        title: "SUB_CATEGORIES",
        dataIndex: "subCategories",
        key: "subCategories",
        width: 100,
        render(subCategories, {serviceId}) {
            const getMenu = subCategory => (
                <Menu>
                    <Menu.Item onClick={() => editSubCategory({serviceId, subCategory})}>עריכה</Menu.Item>
                    <Menu.Item
                        onClick={() =>
                            toggleHideSubCategory(
                                {
                                    serviceId,
                                    subCategory
                                },
                                !subCategory.hideInMarketplace
                            )
                        }>
                        {subCategory.hideInMarketplace ? "הצג בעמוד קטגוריה" : "הסתר בעמוד קטגוריה"}
                    </Menu.Item>
                    <Menu.Item danger={true} onClick={() => removeSubCategory({serviceId, subCategory})}>
                        מחק
                    </Menu.Item>
                </Menu>
            );

            return subCategories ? (
                <div>
                    {subCategories.map(sub => (
                        <Dropdown trigger={["contextMenu"]} key={`${serviceId}${sub.name}`} overlay={getMenu(sub)}>
                            <Tag
                                onClick={() => editSubCategory({serviceId, subCategory: sub})}
                                style={{
                                    fontSize: 12,
                                    borderRadius: 5,
                                    padding: "2px 5px",
                                    opacity: sub.hideInMarketplace ? 0.5 : 1,
                                    direction: "rtl"
                                }}
                                color={"var(--standard-text-color)"}>
                                {sub.name}
                            </Tag>
                        </Dropdown>
                    ))}
                    <PlusCircleFilled size={20} onClick={() => onAddNewSubCategory(serviceId)} />
                </div>
            ) : (
                <PlusCircleFilled size={20} onClick={() => onAddNewSubCategory(serviceId)} />
            );
        }
    },
    {
        title: "EDIT",
        key: "edit",
        width: 30,
        render(_, {serviceId}) {
            return (
                <div onClick={e => e.stopPropagation()}>
                    <Button style={{minWidth: 100}} onClick={() => onEditCategory(serviceId)}>
                        Edit
                    </Button>
                </div>
            );
        }
    }
];

export class AdminCategories extends React.Component {
    state = {
        term: "",
        services: [],
        subCategoryToRemove: null,
        addCategoryServiceId: null,
        editCategoryServiceId: null,
        controlledCategoryServiceId: null,
        removeSubCategoryLoading: false
    };

    async componentDidMount() {
        try {
            const allServices = await HttpClient.post("/api/services", {includeDisabled: true});
            this.setState({services: allServices.filter(service => service.v2)});
        } catch (e) {
            message.error(e.message, 3);
        }
    }

    filter(term) {
        this.setState({term});
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        const {services} = this.state;
        if (oldIndex !== newIndex) {
            const newOrder = [...services];
            const [movedItem] = newOrder.splice(oldIndex, 1);
            newOrder.splice(newIndex, 0, movedItem);
            this.setState({services: newOrder});
        }
    };

    DraggableContainer = props => (
        <SortableCont
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={this.onSortEnd}
            {...props}
        />
    );

    DraggableBodyRow = ({className, style, ...restProps}) => {
        const {services} = this.state;
        const index = services.findIndex(({serviceId}) => serviceId === restProps["data-row-key"]);
        return <SortableItem index={index} {...restProps} />;
    };

    updateSubCategoriesOrder = async updateSubs => {
        try {
            const {updatedService} = await HttpClient.post(
                `/admin/api/services/${this.state.controlledCategoryServiceId}/subCategories/order`,
                {subCategories: updateSubs}
            );
            const oldServiceIndex = findIndex(
                this.state.services,
                s => s.serviceId === this.state.controlledCategoryServiceId
            );
            const newServices = clone(this.state.services);
            newServices.splice(oldServiceIndex, 1, updatedService);
            this.setState({services: newServices});
        } catch (e) {
            message.error(e.message, 3);
        }
        this.setState({controlledCategoryServiceId: null});
    };

    removeSubCategory = async subToRemove => {
        this.setState({removeSubCategoryLoading: true});
        try {
            const services = await HttpClient.post(`/admin/api/services/${subToRemove.serviceId}/removeSubCategory`, {
                subCategory: subToRemove.subCategory
            });
            this.setState({services: services.filter(service => service.v2)});
        } catch (e) {
            message.error(e.message, 3);
        }
        this.setState({removeSubCategoryLoading: false});
    };

    toggleHideSubCategory = async ({serviceId, subCategory}, newState) => {
        try {
            const service = await HttpClient.post(
                `/admin/api/services/${serviceId}/${subCategory.id}/setHideSubCategory`,
                {hideInMarketplace: newState}
            );
            this.setState(prevState => {
                const serviceIndexToReplace = prevState.services.findIndex(
                    ({serviceId}) => serviceId === service.serviceId
                );
                const newServices = [...prevState.services];
                newServices.splice(serviceIndexToReplace, 1, service);
                return {services: newServices};
            });
        } catch (e) {
            message.error(e.message, 3);
        }
    };

    render() {
        let {term, services} = this.state;

        if (services) {
            services = services.filter(service => service.categoryName.indexOf(term.toLowerCase()) >= 0);
        }

        return (
            <div style={{display: "flex", justifyContent: "center", margin: 10, marginBottom: 40}}>
                <div style={{display: "flex", flexDirection: "column", maxWidth: 1500, width: "100%"}}>
                    <AdminSubCategoriesController
                        service={services.find(s => s.serviceId === this.state.controlledCategoryServiceId)}
                        visible={!!this.state.controlledCategoryServiceId}
                        onClose={() => this.setState({controlledCategoryServiceId: null})}
                        onSave={async updateSubs => {
                            await this.updateSubCategoriesOrder(updateSubs);
                        }}
                    />
                    <AdminCategoriesAddSubCategory
                        visible={!!this.state.addCategoryServiceId}
                        onClose={() => this.setState({addCategoryServiceId: null})}
                        onAdd={async subCategory => {
                            try {
                                const services = await HttpClient.post(
                                    `/admin/api/services/${this.state.addCategoryServiceId}/addSubCategory`,
                                    {subCategory}
                                );

                                this.setState({addCategoryServiceId: null});
                                this.setState({services: services.filter(service => service.v2)});
                            } catch (e) {
                                message.error(e.message, 3);
                            }
                        }}
                    />
                    <AdminCategoriesEditSubCategory
                        visible={!!this.state.subCategoryToEdit}
                        onClose={() => this.setState({subCategoryToEdit: null})}
                        subCategoryToEdit={this.state.subCategoryToEdit?.subCategory.name}
                        onEdit={async subCategory => {
                            try {
                                const services = await HttpClient.post(
                                    `/admin/api/services/${this.state.subCategoryToEdit.serviceId}/editSubCategory`,
                                    {
                                        oldSubCategory: this.state.subCategoryToEdit.subCategory,
                                        newSubCategory: subCategory
                                    }
                                );

                                this.setState({subCategoryToEdit: null});
                                this.setState({services: services.filter(service => service.v2)});
                            } catch (e) {
                                message.error(e.message, 3);
                            }
                        }}
                    />
                    <PageTitleHeader showBack={false}>Categories Version 2</PageTitleHeader>
                    <div>
                        <SearchInput
                            style={{marginBottom: 30, maxWidth: 500, width: "100%"}}
                            onChange={evt => this.filter(evt.target.value)}
                            placeholder="Search categories"
                        />
                    </div>
                    {this.state.removeSubCategoryLoading ? (
                        <PageLoader style={{zIndex: "2000", position: "absolute", top: "500px"}} />
                    ) : null}
                    <RoundedTable
                        rowKey="serviceId"
                        scroll={{x: 1000, y: 600}}
                        pagination={false}
                        dataSource={services || []}
                        columns={columns(
                            this.removeSubCategory,
                            subCategoryToEdit => this.setState({subCategoryToEdit}),
                            this.toggleHideSubCategory,
                            serviceId => this.setState({addCategoryServiceId: serviceId}),
                            serviceId =>
                                this.setState(
                                    serviceId === "specialcategory"
                                        ? {editCategoryServiceId: serviceId}
                                        : {controlledCategoryServiceId: serviceId}
                                )
                        )}
                        components={{
                            body: {
                                wrapper: this.DraggableContainer,
                                row: this.DraggableBodyRow
                            }
                        }}
                    />
                </div>
            </div>
        );
    }
}
