import React from "react";
import {Select} from "antd";
import {csc} from "../csc/CountyStateCityClient";
import {SearchableSelect} from "./SearchableSelect";

const {Option} = Select;

export const RemoteBranch = {
    countryName: "REMOTE BRANCH",
    countryId: "remote_branch"
};

export function CountrySelect({value, placeholder, onSelect, onChange, remoteBranchEnabled}) {
    let countries = csc.countries();

    if (remoteBranchEnabled) {
        countries.unshift(RemoteBranch);
    }

    const hasValue = countries.findIndex(country => country.countryId === value) >= 0;

    return (
        <SearchableSelect
            placeholder={placeholder || "Select country"}
            value={hasValue ? value : void 0}
            onChange={onChange}
            onSelect={countryId =>
                onSelect(countryId === RemoteBranch.countryId ? RemoteBranch : csc.country(countryId))
            }>
            {countries.map(countryInfo => (
                <Option key={countryInfo.countryId} value={countryInfo.countryId}>
                    {countryInfo.countryName}
                </Option>
            ))}
        </SearchableSelect>
    );
}

export function StateSelect({value, placeholder, countryId, onSelect, onChange}) {
    const states = csc.states(countryId);
    const isUS = csc.country(countryId).countryCode === "US";

    const hasValue = states.findIndex(state => state.stateId === value) >= 0;

    return (
        <SearchableSelect
            disabled={!countryId || !isUS}
            value={hasValue ? value : void 0}
            placeholder={placeholder || "Select state"}
            onChange={onChange}
            onSelect={stateId => onSelect(csc.state(stateId))}>
            {states.map(stateInfo => (
                <Option key={stateInfo.stateId} value={stateInfo.stateId}>
                    {stateInfo.stateName}
                </Option>
            ))}
        </SearchableSelect>
    );
}

export function CitySelect({value, placeholder, stateId, countryId, onSelect, onChange}) {
    const country = csc.country(countryId);
    const isUS = country.countryCode === "US";
    const cities = isUS ? csc.cities(stateId, country.countryCode) : csc.allCities(countryId);

    const hasValue = cities.findIndex(city => city.cityId === value) >= 0;

    return (
        <SearchableSelect
            disabled={isUS ? !stateId : !countryId}
            value={hasValue ? value : void 0}
            placeholder={placeholder || "Select city"}
            onChange={onChange}
            onSelect={cityId => onSelect(csc.city(cityId))}>
            {cities.map(cityInfo => (
                <Option key={cityInfo.cityId} value={cityInfo.cityId}>
                    {cityInfo.cityName}
                </Option>
            ))}
        </SearchableSelect>
    );
}
