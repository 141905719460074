import React, {useContext, useState} from "react";
import {DatePicker, Form, Select, message} from "antd";
import {AppContext} from "../../AppContext";
import {FormInput} from "../../components/form";
import moment from "moment-timezone";
import {StyledButton} from "../../components/StyledButton";
import {HttpClient} from "../../http/HttpClient";
import {EventBus} from "../../bus/EventBus";
import {UploadableCoverPhoto} from "../../components/UploadableCoverPhoto";
import {openWindow} from "../../http/WindowOpener";
import {EXCenterContext} from "../EXCenterContext";
import {GoogleAnalytics} from "../../GoogleAnalytics";

const {Option} = Select;

export const EXCenterProfileInfo = () => {
    const {me} = useContext(AppContext);
    const {theme} = useContext(EXCenterContext);
    const [loading, setLoading] = useState(false);

    const saveInfo = async values => {
        setLoading(true);
        try {
            const updatedUser = await HttpClient.post("/api/users/me", {
                ...values,
                birthday: values.birthday ? values.birthday.format("DD/MM/YYYY") : null
            });
            EventBus.trigger("me:update", updatedUser);
            message.success("Your new information was successfully updated.");
            GoogleAnalytics.event("EX Center - Profile Info", "Update", me.userId);
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setLoading(false);
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                marginTop: 50,
                marginLeft: 10,
                marginRight: 10
            }}>
            <div style={{textAlign: "center", color: theme.textColor, fontSize: 28, fontWeight: 700, marginBottom: 30}}>
                Edit your personal information
            </div>
            <Form
                onFinish={saveInfo}
                initialValues={{
                    ...me,
                    birthday: me.birthday ? moment(me.birthday, "DD/MM/YYYY") : void 0,
                    phone: me.phone ? me.phone.toString().replace("+", "") : void 0
                }}>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Form.Item name="imageUrl" valuePropName="imageUrl" style={{width: 240}}>
                        <UploadableCoverPhoto
                            showUploadOnly={true}
                            width={300}
                            height={300}
                            coverStyle={{
                                minWidth: 240,
                                maxWidth: 240,
                                minHeight: 240,
                                maxHeight: 240,
                                borderRadius: 120
                            }}
                            showPlaceholder={true}
                        />
                    </Form.Item>
                </div>
                <div style={{display: "flex", alignItems: "center"}}>
                    <Form.Item
                        name="firstName"
                        rules={[
                            {
                                required: true,
                                message: "Please enter first name."
                            }
                        ]}>
                        <FormInput placeholder="First name" />
                    </Form.Item>
                    <Form.Item
                        style={{marginLeft: 10}}
                        name="lastName"
                        rules={[
                            {
                                required: true,
                                message: "Please enter last name."
                            }
                        ]}>
                        <FormInput placeholder="Last name" />
                    </Form.Item>
                </div>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "Please enter email."
                        }
                    ]}>
                    <FormInput disabled={true} placeholder="Email" />
                </Form.Item>
                <Form.Item
                    name="gender"
                    rules={[
                        {
                            required: true,
                            message: "Please select gender."
                        }
                    ]}>
                    <Select placeholder="Gender">
                        <Option value="female">Female</Option>
                        <Option value="male">Male</Option>
                        <Option value="generic">None of them</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    style={{width: "100%"}}
                    name="phone"
                    rules={[
                        {
                            validator: (_, value) => {
                                if (value && value.length < 8) {
                                    return Promise.reject("Please enter valid number.");
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        }
                    ]}>
                    <FormInput type="number" placeholder="Phone number (optional)" />
                </Form.Item>
                <Form.Item name="position">
                    <FormInput placeholder="Position (optional)" />
                </Form.Item>
                <Form.Item name="birthday">
                    <DatePicker
                        inputReadOnly={true}
                        style={{width: "100%"}}
                        className="wb-date-picker"
                        format="DD/MM/YYYY"
                        showToday={false}
                        placeholder="Birthday (optional)"
                    />
                </Form.Item>
                <Form.Item name="address">
                    <FormInput placeholder="Address (optional)" />
                </Form.Item>
                <Form.Item>
                    <StyledButton
                        htmlType="submit"
                        loading={loading}
                        style={{
                            color: "white",
                            border: "none",
                            borderRadius: 25,
                            height: 50,
                            backgroundColor: theme.primaryColor,
                            width: "100%"
                        }}>
                        Save
                    </StyledButton>
                </Form.Item>
                <StyledButton
                    onClick={() => openWindow("/api/logout", "_self")}
                    style={{
                        marginTop: 40,
                        height: 50,
                        borderRadius: 25,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        backgroundColor: "white",
                        border: `1px solid ${theme.textColor}`,
                        color: theme.textColor
                    }}>
                    Logout
                </StyledButton>
            </Form>
        </div>
    );
};
