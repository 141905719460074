import {createWorkbook, createWorksheet, DownloadExcel} from "../../utils/excelUtils.js";

export const downloadDeliveryAreaExcel = async areasPricing => {
    const workbook = createWorkbook();
    const sheet = createWorksheet(workbook);
    addColumns(sheet);
    addRows(sheet, areasPricing ?? {});

    await DownloadExcel(workbook, "תמחור אזורי שילוח");
};

const addColumns = sheet => {
    sheet.columns = [
        {header: "שם עיר", key: "subArea"},
        {header: "מחיר", key: "subAreaPrice"},
        {header: "זמן התראה", key: "minNotice"},
        {header: "מזהה", key: "id"},
        {},
        {},
        {header: "שם אזור", key: "area"},
        {header: "מחיר", key: "subAreaPrice"}
    ];
};

const addRows = (sheet, areasPricing) => {
    const mainAreasPricing = Object.values(areasPricing).map(({price, displayName}) => ({price, displayName}));
    const subAreaPricing = Object.values(areasPricing)
        .map(area => Object.values(area.subAreas ?? {}))
        .flat();

    const rows = [];
    const rowsLength = Math.max(mainAreasPricing.length, subAreaPricing.length);

    for (let i = 0; i < rowsLength; i++) {
        rows.push([
            subAreaPricing[i]?.displayName,
            subAreaPricing[i]?.price,
            subAreaPricing[i]?.minNotice,
            subAreaPricing[i]?.placeId,
            "",
            "",
            mainAreasPricing[i]?.displayName,
            mainAreasPricing[i]?.price
        ]);
    }

    sheet.addRows(rows);
};
