import React from "react";
import {ConfigProvider, Skeleton} from "antd";
import "./provider-modular-product-skeleton-list.css";

export const ProviderModularProductList = () => {
    return (
        <div className="provider-product-container-skeleton">
            <ConfigProvider direction="rtl">
                <div className="provider-chosen-product-page-tabs-container">
                    {[...Array(7)].map(index => {
                        return <Skeleton.Button className="provider-chosen-skeleton-tab" key={index} active={true} />;
                    })}
                </div>

                <Skeleton.Button className="product-skeleton-cart-container-category" active={true} />
                <div className="product-skeleton-sub-products-container">
                    {[...Array(12)].map(index => {
                        return <Skeleton.Button className="sub-product-skeleton" key={index} active={true} />;
                    })}
                </div>
            </ConfigProvider>
        </div>
    );
};
