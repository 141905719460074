import React, {useContext, useState} from "react";
// import isURL from "validator/lib/isURL";
import {FormInput} from "../../components/form";
import {StyledButton} from "../../components/StyledButton";
import {Form, message} from "antd";
import {AppContext} from "../../AppContext";
import {HttpClient} from "../../http/HttpClient";
import {EventBus} from "../../bus/EventBus";
import {EXCenterContext} from "../EXCenterContext";
import {GoogleAnalytics} from "../../GoogleAnalytics";

const Dot = () => <div style={{backgroundColor: "#CFD6DA", height: 12, width: 12, borderRadius: 6, margin: 4}} />;

export const EXCenterBrowserNavBar = () => {
    const {me, company} = useContext(AppContext);
    const {theme} = useContext(EXCenterContext);
    const [errorFields, setErrorFields] = useState(null);
    const [loading, setLoading] = useState(false);

    const connectDomain = async ({customDomain}) => {
        setLoading(true);
        try {
            const cmpny = await HttpClient.post("/api/companies", {
                ...company,
                customDomain: customDomain || null
            });
            EventBus.trigger("company:update", cmpny);
            message.success("Your new domain was connected successfully.", 3);
            GoogleAnalytics.event("EX Center - Main", "Connect Domain", me.userId);
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setLoading(false);
    };

    return (
        <div
            className="wb-ms-visible-only"
            style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#F6F7F8",
                height: 48
            }}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 16,
                    marginRight: 16
                }}>
                <Dot />
                <Dot />
                <Dot />
            </div>
            <Form
                style={{display: "flex", alignItems: "center"}}
                onFinish={connectDomain}
                initialValues={company}
                onFinishFailed={({errorFields}) => {
                    setErrorFields(errorFields);
                }}
                onValuesChange={() => {
                    setErrorFields(null);
                }}>
                <Form.Item
                    name="customDomain"
                    noStyle
                    rules={[
                        {
                            validator: (_, url) => {
                                if (!url) {
                                    return Promise.resolve();
                                } else if (url.split(".").length !== 3) {
                                    return Promise.reject("Your custom domain isn't complete (e.g. app.well-b.biz).");
                                } else if (
                                    !isURL(url, {require_protocol: false, allow_underscores: true, require_tld: true})
                                ) {
                                    return Promise.reject("You must enter a valid domain.");
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}>
                    <FormInput
                        placeholder="app.well-b.biz"
                        style={{
                            backgroundColor: "white",
                            height: 28,
                            borderRadius: 14,
                            border: "1px solid #CFD6DA",
                            fontSize: 14,
                            width: 300,
                            color: theme.textColor,
                            marginRight: 10
                        }}
                    />
                </Form.Item>
                <Form.Item noStyle>
                    <StyledButton
                        loading={loading}
                        htmlType="submit"
                        style={{
                            height: 28,
                            borderRadius: 14,
                            backgroundColor: "rgba(8,86,217,0.1)",
                            fontSize: 14,
                            color: errorFields ? "#F06060" : "#0856D9",
                            boxShadow: "none",
                            border: "none",
                            minWidth: 180,
                            fontWeight: errorFields ? 900 : 400
                        }}>
                        {errorFields ? errorFields[0].errors[0] : "Connect your domain"}
                    </StyledButton>
                </Form.Item>
            </Form>
        </div>
    );
};
