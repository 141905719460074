import {excelSheetsTitle} from "./consts";
import moment from "moment/moment";
import {removeEmojis} from "../utils/StringFormat";
import {reduceVAT} from "../utils/calculationUtils";
import {dateTimeRange, dateTimeRangeFormat} from "../utils/DateUtils";
import {ActivityLocationTypes, ProviderActivityPossibleLocations} from "../consts.js";
import {statusToTextAndColor} from "../components/index.jsx";
import {ExportToExcel} from "../utils/exportToExcel.js";
import {calculateDeliveryTime, isOrderHasShippingProvider} from "../event/utils.jsx";

export const ExportTableToCSMSuppliesExcel = async (orders, systemCSMs) => {
    await ExportToExcel({
        fileName: "ordersToCSM",
        sheetsConfig: [
            {
                title: excelSheetsTitle.orders,
                data: orders,
                rowMapper: row => {
                    const {supplyDate, supplyHours} = getSupplyDetails(row);

                    row.productNameWithoutEmoji = removeEmojis(row.productName);
                    row.createdAtFormat = moment(row.createdAt).format("DD/MM/YY");
                    row.supplyDate = supplyDate;
                    row.supplyHours = supplyHours;
                    row.address = `${row.address} ${
                        row.address === ProviderActivityPossibleLocations[ActivityLocationTypes.homeDelivery]
                            ? row?.employeesExcel?.fileUrl ?? ""
                            : ""
                    }`;
                    row.deliveryPriceWithoutVAT = reduceVAT(row.deliveryPrice, row.vat);
                    row.assignedCsm = getOrderCsmOrFirstUser(row, systemCSMs);
                    row.statusFormat = statusToTextAndColor[row.status] ? statusToTextAndColor[row.status][0] : "";
                    row.assignedPartnersFormat = row?.assignedPartners?.length
                        ? row.assignedPartners.map(assignedPartner => assignedPartner.firstName).join(",")
                        : "";
                    row.pickupDateFormat = isOrderHasShippingProvider(row)
                        ? dateTimeRangeFormat(calculateDeliveryTime(row))
                        : "";
                    return row;
                },
                columnsConfig: {
                    columns: getColumns()
                }
            }
        ]
    });
};

function getOrderCsmOrFirstUser(order, systemCSMs) {
    const csmFound = order.assignedCSMs?.find(csmUser => {
        const systemCsmUser = systemCSMs.find(systemCsmUser => systemCsmUser.userId === csmUser.userId);
        return systemCsmUser?.position === "CSM";
    });
    return csmFound?.firstName ?? order?.assignedCSMs?.[0]?.firstName;
}

function getSupplyDetails(subOrder) {
    const {dtStartDateFormat, untilDateFormat, dtStartHourFormat, untilHourFormat} = dateTimeRange(
        subOrder.dtstart,
        subOrder?.until
    );
    const supplyDate = `${dtStartDateFormat} ${untilDateFormat ? ` - ${untilDateFormat}` : ""}`;
    const supplyHours = `${dtStartHourFormat} ${untilHourFormat ? ` - ${untilHourFormat}` : ""}`;
    return {supplyDate, supplyHours};
}

const getColumns = () => {
    return [
        {header: "שם השותף", key: "providerName"},
        {header: "מספר הזמנה", key: "proposalId"},
        {header: "שם החברה", key: "companyName"},
        {header: "שם החבילה", key: "productNameWithoutEmoji"},
        {header: "תאריך הזמנה", key: "createdAtFormat"},
        {header: "תאריך הספקה", key: "supplyDate"},
        {header: "זמן איסוף ההזמנה", key: "pickupDateFormat"},
        {header: "טווח הספקה", key: "supplyHours"},
        {header: "כתובת מלאה לאספקה", key: "address"},
        {header: "עלות משלוח כולל מעמ", key: "deliveryPrice"},
        {header: "עלות משלוח ללא מעמ", key: "deliveryPriceWithoutVAT"},
        {header: "הערות", key: "companyExtraRequests"},
        {header: "מנהלת לקוח", key: "assignedCsm"},
        {header: "סטטוס הזמנה", key: "statusFormat"},
        {header: "מנהל/ת שותף", key: "assignedPartnersFormat"}
    ];
};
