import moment from "moment-timezone";

export const openWindow = (url, target = "_blank", excludeTzid = true) => {
    if (!excludeTzid) {
        const tzid = moment.tz.guess();
        url = url.indexOf("?") >= 0 ? `${url}&tzid=${tzid}` : `${url}?tzid=${tzid}`;
    }

    if (url.indexOf("http") !== 0 && url.indexOf("/") !== 0) {
        url = `http://${url}`;
    }

    window.open(url, target, "noreferrer");
};

export const openExternalLink = url => {
    if (url.indexOf("http") !== 0 && url.indexOf("/") !== 0) {
        url = `http://${url}`;
    }

    window.open(url, "_blank");
};
