import React, {useCallback, useContext, useMemo} from "react";
import {StyledButton} from "../../components";
import {CartModularProductContext} from "./CartModularProductContext.jsx";
import {RenderSubProductsList} from "../OrderFormModularProductsPanel/RenderSubProductsList.jsx";
import {StringBuilder} from "../../AppUtils.js";
import classnames from "classnames";
import {SyncOutlined} from "@ant-design/icons";
import {ConfigProvider, Tooltip} from "antd";

export const PricingCalculatorByModular = ({product, onBook, showModularImages, log}) => {
    const {selectedSubProducts, onPropertyChange, totalPrice, resetCart, originalTotalPrice} =
        useContext(CartModularProductContext);

    const cartMinPrice = useMemo(() => product?.pricingTable?.byModular?.minPrice ?? 0, [product]);

    const onBookClick = useCallback(() => {
        onBook();
        log("Cart booking button clicked", {});
    }, [onBook, log]);

    const isBookingButtonDisable = useMemo(
        () => totalPrice < cartMinPrice || totalPrice === 0,
        [cartMinPrice, totalPrice]
    );

    return (
        <div className="pricing-calculator-by-modular-container">
            <div className="cart-modular-product-container">
                {selectedSubProducts?.length > 0 ? (
                    <>
                        <RenderSubProductsList
                            subProducts={selectedSubProducts}
                            showProviderSubProductsImages={showModularImages}
                            onChangeProperty={onPropertyChange}
                        />
                    </>
                ) : (
                    <div className="empty-cart-description">
                        <div>0 פריטים בהזמנה</div>
                        <div>מבט קליל על האפשרויות יסדר את זה 😏</div>
                    </div>
                )}
            </div>

            <div className="original-package-container">
                <span className="original-package-container-title">
                    {new StringBuilder()
                        .append("מחיר חבילה מקורי:")
                        .append(`${originalTotalPrice.toLocaleString()} ₪`)
                        .toString()}
                </span>
                <div className="back-to-original-package-container">
                    <SyncOutlined />
                    <a className="original-package-btn" onClick={resetCart}>
                        החזר לחבילה המקורית
                    </a>
                </div>
            </div>

            <ConfigProvider direction="rtl">
                <Tooltip
                    title={new StringBuilder()
                        .append("המחיר המינימלי להרכבת החבילה הוא")
                        .append(`${cartMinPrice} ₪`)
                        .toString()}>
                    <StyledButton
                        onClick={onBookClick}
                        disabled={isBookingButtonDisable}
                        className={classnames("pricing-calculator-button", {disabled: isBookingButtonDisable})}>
                        {new StringBuilder()
                            .append("מעבר להזמנה")
                            .append(`(${totalPrice.toLocaleString()}₪)`)
                            .toString()}
                    </StyledButton>
                </Tooltip>
            </ConfigProvider>
        </div>
    );
};
