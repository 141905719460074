import React from "react";
import {MailOutlined} from "@ant-design/icons";
import {FormActionButton} from "../components/form";
import {message} from "antd";
import {HttpClient} from "../http/HttpClient";
import {PageTitleLabel} from "../components/PageTitle";
import {EventBus} from "../bus/EventBus";

export class EmailConfirmationExpired extends React.Component {
    async resend() {
        const {match} = this.props;
        const {hashedEmail} = match.params;

        try {
            await HttpClient.post("/api/users/password/forgot", {email: atob(hashedEmail)});
            message.success("Success! Check your email.", 0);
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "יש בעיה עם כתובת המייל שסיפקת, אנא בדוק שהמייל תקין ורשום במערכת"}},
                e.message
            );
        }
    }

    render() {
        const {match} = this.props;
        const {hashedEmail} = match.params;
        const email = atob(hashedEmail);

        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: 20,
                    padding: 20,
                    paddingTop: 0,
                    marginBottom: 30
                }}>
                <PageTitleLabel>Link expired!</PageTitleLabel>
                <MailOutlined style={{fontSize: 128, margin: 30, color: "#2F3E83"}} />
                <label style={{fontSize: 16, color: "#8492A6", marginBottom: 10, textAlign: "center", maxWidth: 500}}>
                    Click <strong>'Send me new reset password link'</strong> and we will send you a new reset password
                    link to <strong>{email}</strong>.
                </label>
                <FormActionButton
                    onClick={() => this.resend()}
                    style={{
                        marginTop: 20,
                        maxWidth: 400,
                        width: "100%",
                        marginLeft: 20,
                        marginRight: 20
                    }}>
                    Send me new reset password link
                </FormActionButton>
            </div>
        );
    }
}
