import React, {useMemo} from "react";
import classnames from "classnames";
import {ConfigProvider} from "antd";
import {sumBy} from "lodash";
import {momentHebrewFormat} from "../utils/DateFormat";
import {PencilIcon, TrashIcon} from "../icons";
import {NOOP} from "../utils/NOOP";
import {ConfirmationRequiredWrapper} from "../components";
import {Images} from "../images/Images";

const {computerTrashImage} = Images;

export const MonthBudgetBox = ({monthBudget, month, onEdit = NOOP, onDelete = NOOP}) => {
    const totalBudget = useMemo(() => {
        return Object.values(monthBudget.categories).reduce((previousValue, currentValue) => {
            if (currentValue.occasions) {
                return previousValue + sumBy(currentValue.occasions, "price");
            }
            return previousValue;
        }, 0);
    }, [monthBudget]);

    return (
        <div className={classnames("period-step-budget-month")}>
            <div className="period-step-budget-month-title">{momentHebrewFormat(month, "MMMM YY")}</div>
            <div className="period-step-budget-month-content">
                <div>תקציב מסגרת לתוכנית</div>
                <div className="period-step-budget-month-actions">
                    <div>{totalBudget}₪</div>

                    <div className="period-step-budget-month-icons">
                        <PencilIcon onClick={() => onEdit(monthBudget.planId)} />
                        <ConfigProvider direction="rtl">
                            <ConfirmationRequiredWrapper
                                modalClassName={"period-step-budget-month-icons-modal"}
                                title="בטוחים שאתם רוצים למחוק את התכנון?"
                                subTitle="שימו 💙 - לחיצה על אייקון האשפה תמחק את התכנון שבניתם לחודש הרלוונטי לצמיתות. האירועים שבחרתם לקיים באותו החודש לא ייפגעו, אך השיוך התקציבי שלהם יאבד."
                                onYes={() => onDelete(monthBudget.planId)}
                                image={computerTrashImage}
                                yesText={"כן, תמחקו את התכנון לחודש זה"}
                                showNoButton={false}>
                                <TrashIcon />
                            </ConfirmationRequiredWrapper>
                        </ConfigProvider>
                    </div>
                </div>
            </div>
        </div>
    );
};
