import React, {useCallback, useContext, useEffect, useMemo} from "react";
import {Tooltip} from "antd";
import {flatten, uniq} from "lodash";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {CheckboxController} from "./PaymentInputHelper";
import {MarketplaceContext} from "../../marketplaceContext";
import {PaymentType, ServiceIdToName} from "../../../consts.js";
import {AppContext} from "../../../AppContext";
import {SortableList} from "../../../components";
import {getTotalBudget} from "../../../utils/calculationUtils";

const BudgetPaymentInput = ({paymentType, setPaymentType, budgetsPriority, onBudgetCategoriesPriorityChange}) => {
    const {me} = useContext(AppContext);
    const {product, productBundle, isProductBundle} = useContext(MarketplaceContext);

    const productServices = useMemo(() => {
        if (isProductBundle) {
            return uniq(flatten(productBundle.products.map(({services}) => services)));
        }

        return product?.services;
    }, [product, productBundle, isProductBundle]);

    const totalBudget = useMemo(() => getTotalBudget(me?.budget, productServices), [me?.budget, productServices]);

    const onSortEnd = useCallback(
        ({oldIndex, newIndex}) => {
            const newSubCategories = [...budgetsPriority];
            newSubCategories.splice(newIndex, 0, newSubCategories.splice(oldIndex, 1)[0]);
            onBudgetCategoriesPriorityChange(newSubCategories);
        },
        [budgetsPriority, onBudgetCategoriesPriorityChange]
    );

    return (
        <div className="payment-input-container">
            <div className={"payment-input-options-header"}>
                <CheckboxController
                    isChecked={paymentType === PaymentType.Budget}
                    setPaymentType={type => setPaymentType(type)}
                    type={PaymentType.Budget}
                />
                תשלום באמצעות תקציב
                <Tooltip
                    title={
                        <p className="payment-input-tooltip-text">
                            בחירה באפשרות תשלום באמצעות תקציב מאפשרת לכם לשלם על ההזמנה באמצעות הסכום שנטען מהאדמין
                            הראשי בארגון שלכם.
                        </p>
                    }>
                    <QuestionCircleOutlined className="payment-input-tooltip-icon" />
                </Tooltip>
            </div>
            <div className={"payment-input-bank-option-subtitle"}>
                התקציב זמין לרכישת המוצר {(totalBudget ?? 0)?.toLocaleString()} ₪
            </div>
            {me.features?.category_budget && paymentType === PaymentType.Budget && budgetsPriority?.length > 1 ? (
                <div>
                    <div className="payment-input-budgets-list-container">
                        סדר עדיפות למימוש תקציב
                        <QuestionCircleOutlined />
                    </div>
                    <SortableList
                        className="payment-input-budgets-list"
                        itemClassName="payment-input-budgets-list-item"
                        onSortEnd={onSortEnd}>
                        {budgetsPriority?.map((categoryId, index) => {
                            return (
                                <div key={categoryId}>
                                    {`${index + 1}. תקציב ${ServiceIdToName[categoryId] ?? "צף"}: ${me.budget[
                                        categoryId ?? "amount"
                                    ]?.toLocaleString()}`}
                                </div>
                            );
                        })}
                    </SortableList>
                </div>
            ) : null}
        </div>
    );
};

export default BudgetPaymentInput;
