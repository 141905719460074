import React from "react";
import {Spin} from "antd";
import {SmartImageContainer} from "../components";
import {useRequest} from "../utils/hooks";
import "./previously-ordered-companies.css";

export const PreviouslyOrderedCompanies = ({providerIds = []}) => {
    const [companiesLogos, isLoadingCompaniesLogos] = useRequest(
        "/api/companies/byProviderIds",
        "POST",
        {providerIds},
        [providerIds],
        !!providerIds?.length
    );

    return (
        <div className="previously-ordered-companies-logos-container">
            {isLoadingCompaniesLogos ? (
                <Spin />
            ) : (
                companiesLogos?.map(({logo, name}) => (
                    <SmartImageContainer
                        key={name}
                        width={"125"}
                        height={"50"}
                        transformations={{
                            crop: "lpad"
                        }}
                        src={logo}
                        className="previously-ordered-companies-logo"
                        title={name}
                    />
                ))
            )}
        </div>
    );
};
