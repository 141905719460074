import React, {useCallback, useContext, useState} from "react";
import {Divider, Form, message, Spin} from "antd";
import {useAuth0} from "@auth0/auth0-react";
import {FormInputV2} from "../../components/form/index.jsx";
import {HttpClient} from "../../http/HttpClient.jsx";
import {ErrorReporter} from "../../error/ErrorReporter.jsx";
import {useGoogleLogin, useGoogleOneTapLogin} from "@react-oauth/google";
import {StyledButton} from "../../components/index.jsx";
import {NOOP} from "../../utils/NOOP.jsx";
import {GoogleIcon, OktaIcon} from "../../icons/index.jsx";
import {AppContext} from "../../AppContext.jsx";
import {SSOFormModal} from "./SSOFormModal.jsx";
import {RegisterContainer} from "./RegisterContainer.jsx";
import "./username-password-login.css";

export const UsernamePasswordLogin = ({history, onSuccess = NOOP, log}) => {
    const {me} = useContext(AppContext);
    const [formValues, setFormValues] = useState({});
    const [isLoginLoading, setIsLoginLoading] = useState(false);
    const [showSSOFormModal, setShowSSOFormModal] = useState(false);

    const onUsernamePasswordLogin = useCallback(async () => {
        setIsLoginLoading(true);
        try {
            const user = await HttpClient.post("/api/login", {...formValues}, true);
            log("Login success", {email: user.email});
            await onSuccess(user);
        } catch (error) {
            if (error.message === "unauthorized") {
                message.error("שם משתמש או סיסמא אינם נכונים, נסו שנית מאוחר יותר", 4);
            } else {
                message.error("Something went wrong, please try again later.", 5);
                ErrorReporter.reportError(`Failed to login: ${error.message}`);
            }
        } finally {
            setIsLoginLoading(false);
        }
    }, [formValues, onSuccess, history]);

    const handleGoogleLoginResponse = useCallback(
        async loginResponse => {
            if (loginResponse.error) {
                setIsLoginLoading(false);
                if (loginResponse.status === 400) {
                    message.error("יוזר לא נמצא במערכת, לחץ על הרשם למערכת");
                    return;
                }
                message.error(loginResponse.error);
                return;
            }

            await onSuccess(loginResponse);
            setIsLoginLoading(false);
        },
        [onSuccess]
    );

    const onGoogleCredentialLogin = useCallback(
        async credentialResponse => {
            setIsLoginLoading(true);
            const user = await HttpClient.safePost("/api/google/auth/oneTap", {
                credential: credentialResponse.credential
            });

            await handleGoogleLoginResponse(user);
        },
        [handleGoogleLoginResponse]
    );

    const onGoogleAuthCodeLogin = useCallback(
        async codeResponse => {
            setIsLoginLoading(true);
            const user = await HttpClient.safePost("/api/google/auth/oneTap/authCode", {
                code: codeResponse.code
            });

            await handleGoogleLoginResponse(user);
        },
        [handleGoogleLoginResponse]
    );

    useGoogleOneTapLogin({
        onSuccess: onGoogleCredentialLogin,
        onError: () => message.error("שגיאה קרתה במהלך ההתחברות, אנא נסה שוב מאוחר יותר")
    });

    const {getAccessTokenWithPopup} = useAuth0();

    const onForgotPasswordClick = useCallback(() => history.push("/forgot"), [history]);

    const onKeyPressClick = useCallback(
        e => {
            if (e.key === "Enter") {
                onUsernamePasswordLogin();
            }
        },
        [onUsernamePasswordLogin]
    );

    const onClickOktaLogin = useCallback(async () => {
        setIsLoginLoading(true);
        const accessTokenWithPopup = await getAccessTokenWithPopup();

        const user = await HttpClient.safeSendRequest(
            "POST",
            "/api/okta/auth/socialLogin",
            {},
            {
                headers: {
                    Authorization: `Bearer ${accessTokenWithPopup}`
                }
            }
        );

        if (user?.error) {
            message.error("שגיאה קרתה במהלך ההתחברות, אנא נסה שוב מאוחר יותר");
            return;
        }

        await onSuccess(user);
        setIsLoginLoading(false);
    }, []);

    const onGoogleLoginButton = useGoogleLogin({
        onSuccess: onGoogleAuthCodeLogin,
        flow: "auth-code"
    });

    return (
        <div className="username-password-login-container">
            <div className="login-container-form-container">
                <Form
                    className="login-container-form"
                    onValuesChange={(_, allValues) => {
                        setFormValues(allValues);
                    }}>
                    <div className="login-container-input-container">
                        <span className="login-container-input-title">כתובת מייל</span>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "הכנס כתובת מייל תקינה."
                                },
                                {
                                    type: "email",
                                    message: "הכנס כתובת מייל תקינה."
                                }
                            ]}>
                            <FormInputV2 className="login-container-email-input" />
                        </Form.Item>
                    </div>

                    <div className="login-container-input-container">
                        <span className="login-container-input-title">סיסמה</span>
                        <Form.Item
                            name="password"
                            onKeyPress={onKeyPressClick}
                            rules={[
                                {
                                    required: true,
                                    message: "הכנס סיסמה תקינה."
                                }
                            ]}>
                            <div>
                                <FormInputV2.Password className="login-container-password-input" />
                            </div>
                        </Form.Item>
                    </div>
                </Form>

                <a className="login-container-forgot-password-link" onClick={onForgotPasswordClick}>
                    שכחת סיסמה?
                </a>
            </div>

            {isLoginLoading && <Spin className="login-loading" size="large" />}
            <div className="login-button-container">
                <StyledButton id="login-button" className="regular-login-button" onClick={onUsernamePasswordLogin}>
                    התחברות
                </StyledButton>
            </div>

            <div className="login-another-login-options-divider">
                <Divider />
                <span className="login-another-login-options-divider-title">או כניסה דרך</span>
                <Divider />
            </div>

            <div className="login-google-button-container">
                <StyledButton className="google-login-btn" onClick={onGoogleLoginButton}>
                    Google
                    <GoogleIcon />
                </StyledButton>
            </div>
            {!!me?.features?.okta_login_btn && (
                <div className="login-google-button-container">
                    <StyledButton className="okta-login-btn" onClick={() => setShowSSOFormModal(true)}>
                        Okta
                        <OktaIcon />
                    </StyledButton>
                </div>
            )}

            <SSOFormModal
                visible={showSSOFormModal}
                onSuccess={onClickOktaLogin}
                onClose={() => setShowSSOFormModal(false)}
            />

            <RegisterContainer history={history} log={log} />
        </div>
    );
};
