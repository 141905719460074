import React from "react";
import {Button, Dropdown} from "antd";
import {DownOutlined, UpOutlined} from "@ant-design/icons";

export class CategoryDropdown extends React.Component {
    state = {
        visible: false
    };

    select(evt) {
        const {onChange} = this.props;

        this.setState({visible: false});

        onChange && onChange(evt);

        return true;
    }

    render() {
        const {width, menu, index, text, style} = this.props;

        return (
            <Dropdown trigger="click" overlay={menu(evt => this.select(evt))}>
                <div
                    className="wb-service-dropdown"
                    style={{
                        display: "flex",
                        backgroundColor: "#FCFDFE",
                        border: "1px solid #E8EDF5",
                        borderRadius: 10,
                        marginRight: 10,
                        minWidth: width || 160,
                        width: width || 160,
                        ...style
                    }}>
                    <div
                        style={{
                            height: 48,
                            minWidth: 48,
                            width: 48,
                            fontSize: 24,
                            color: "#203448",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRight: "1px solid #E8EDF5",
                            fontWeight: 900
                        }}>
                        {index}
                    </div>
                    <Button
                        style={{
                            fontSize: 16,
                            border: "none",
                            height: 47,
                            width: "90%",
                            borderRadius: 10,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 5,
                            paddingLeft: 10
                        }}
                        onClick={() => this.setState({visible: true})}>
                        {text}
                        {this.state.visible ? (
                            <UpOutlined style={{height: 13, marginTop: -1, paddingRight: 7}} />
                        ) : (
                            <DownOutlined style={{height: 13, paddingTop: 1, paddingRight: 7}} />
                        )}
                    </Button>
                </div>
            </Dropdown>
        );
    }
}
