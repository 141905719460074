export const OrderShortcutFilterValues = {
    PENDING: "pending",
    ACCEPTED: "accepted",
    SUPPLY_TOMORROW: "supplyTomorrow",
    CANCELLED_ORDER: "cancelledOrder"
};

export const OrderShortcutToText = {
    [OrderShortcutFilterValues.PENDING]: "ממתין לאישור",
    [OrderShortcutFilterValues.ACCEPTED]: "הזמנות מאושרות",
    [OrderShortcutFilterValues.SUPPLY_TOMORROW]: "אספקות למחר",
    [OrderShortcutFilterValues.CANCELLED_ORDER]: "הזמנות שסורבו/בוטלו"
};
