import {Select} from "antd";
import {PriceType, PriceTypeLabel} from "../provider/ProviderServices";
import React from "react";

const {Option} = Select;

export const PriceTypeSelect = ({value, onSelect}) => (
    <Select value={value} onSelect={onSelect}>
        <Option value={PriceType.GROUP}>{PriceTypeLabel[PriceType.GROUP]}</Option>
        <Option value={PriceType.EMPLOYEE}>{PriceTypeLabel[PriceType.EMPLOYEE]}</Option>
    </Select>
);
