import React from "react";
import {Switch} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {FormEditModes} from "../ProviderInfoForm/consts";
import {AutoDirectionProvider} from "../../AutoDirectionProvider";
import {ConfirmationRequiredWrapper} from "../../components";
import {TrashIcon} from "../../icons";
import {NOOP} from "../../utils/NOOP.jsx";

export const ProductBundleList = ({
    productBundles,
    editMode = FormEditModes.Readonly,
    onEditProductBundle = NOOP,
    onChangeProductBundleStatus = NOOP,
    onRemoveProductBundle = NOOP
}) => {
    return (
        <>
            {productBundles?.map(productBundle => (
                <AutoDirectionProvider key={productBundle.productBundleId} text="מוצרים">
                    <div className="admin-upload-product-container">
                        <div className="admin-upload-product-name">
                            {productBundle.name}
                            <Switch
                                onChange={isActive =>
                                    onChangeProductBundleStatus(productBundle.productBundleId, isActive)
                                }
                                checked={productBundle.isActive}
                                title={productBundle.isActive ? "פעיל" : "לא פעיל"}
                            />
                        </div>
                        <div className="admin-upload-product-separate-line" />
                        <div className="admin-upload-product-bottom-line">
                            <div className="admin-upload-product-bottom-line-actions">
                                {editMode !== FormEditModes.Readonly ? (
                                    <>
                                        <ConfirmationRequiredWrapper
                                            onYes={() => onRemoveProductBundle(productBundle.productBundleId)}>
                                            <TrashIcon />
                                        </ConfirmationRequiredWrapper>

                                        <EditOutlined onClick={() => onEditProductBundle(productBundle.productBundleId)}
                                        />
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </AutoDirectionProvider>
            ))}
        </>
    );
};
