import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ConfigProvider, Form, message, Tabs} from "antd";
import {get, set} from "lodash";
import {ProviderTypeEnum} from "./AdminUploadUtils";
import {getNewProviderDetails} from "./AdminUploadHelper";

import {providerFormFieldsToName, requiredProviderFields, SuccessImageLink} from "./consts";
import {PageTitleHeader, GenericModal} from "../../components";
import {defaultAvailability} from "../../components/AvailabilityHourly";
import {HttpClient} from "../../http/HttpClient";
import {EventBus} from "../../bus/EventBus";
import ProviderInfoForm from "../../provider/ProviderInfoForm";
import {FormEditModes, providerFormValidators} from "../../provider/ProviderInfoForm/consts";
import {useRequest} from "../../utils/hooks";
import "./admin-upload.css";
import {ProviderSites} from "../../provider/ProviderInfoForm/ProviderSites/ProviderSites.jsx";
import {ProviderProductsPage} from "../../provider/ProviderInfoForm/ProviderProducts/ProviderProductsPage.jsx";
import {StringBuilder} from "../../AppUtils.js";

export const AdminUploadPage = ({match, history}) => {
    const {providerId} = match.params;
    const [selectedTab, setSelectedTab] = useState("1");

    const onSiteSave = useCallback(
        async sites => {
            if (providerId) {
                const res = await HttpClient.safePut(`/admin/api/providers/${providerId}/sites`, {sites});

                if (res.error) {
                    const extraInfoError = Object.entries(res?.extraInfo ?? {})
                        .map(([key, value]) => `${key}: ${value}`)
                        .join(" | ");

                    EventBus.triggerError(
                        "server-error",
                        {
                            content: {
                                description: [
                                    "Unfortunately we didn't manage to complete your changes :(",
                                    `Error: ${res.error}`,
                                    ...(extraInfoError ? [`ExistingUser: ${extraInfoError}`] : [])
                                ],
                                hideSteps: true
                            }
                        },
                        res.error.message
                    );
                    return false;
                }

                if (res.status === 400) {
                    message.error("שגיאה בעת הכנסת הנתונים");
                    return;
                }

                message.success("סניפים נשמרו בהצלחה", 3);
                return res;
            }
        },
        [providerId]
    );

    const tabOptions = useMemo(
        () => [
            {
                label: "פרטי בית העסק",
                key: "1",
                children: <AdminUploadProviderForm history={history} providerId={providerId} />
            },
            {
                label: "סניפים",
                key: "2",
                children: <ProviderSites providerId={providerId} onSave={onSiteSave} />
            },
            {
                label: "מוצרים",
                key: "3",
                children: <ProviderProductsPage providerId={providerId} editMode={FormEditModes.Update} />
            }
        ],
        [providerId, onSiteSave, history]
    );

    const onTabChange = useCallback(key => {
        setSelectedTab(key);
    }, []);

    const currentTabOption = useMemo(() => tabOptions.find(({key}) => selectedTab === key), [tabOptions, selectedTab]);

    return (
        <div className="admin-upload-page-form">
            <ConfigProvider direction="rtl">
                <PageTitleHeader showBack={false}>הוספת ספק</PageTitleHeader>
                <Tabs
                    onChange={onTabChange}
                    className="provider-form-container"
                    defaultActiveKey="1"
                    items={tabOptions.map(({label, key}) => ({label, key}))}
                />
                {!!currentTabOption?.children && currentTabOption.children}
            </ConfigProvider>
        </div>
    );
};

export const AdminUploadProviderForm = ({providerId, history}) => {
    const isEditMode = !!providerId;
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState({
        providerType: ProviderTypeEnum.BRAND,
        availability: defaultAvailability
    });

    const [products, setProducts] = useState([]);

    const [saveProvider, setSaveProvider] = useState(false);
    const [providerSubProducts, setProviderSubProducts] = useState([]);

    const [providerInfo] = useRequest(`/admin/api/providers/${providerId}`, "GET", null, [], !!providerId);
    const [assignPartnerOptions] = useRequest("/admin/api/users/partner", "GET", null, [], true, null, []);

    const [productsSearchTags] = useRequest(
        "/admin/api/searchTags/byProducts",
        "POST",
        providerInfo?.services.map(({productId}) => productId),
        [providerInfo],
        !!providerInfo?.services?.length
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onFormValueChange = useCallback((field, val, validator = null) => {
        setFormValues(currentFormValues => set({...currentFormValues}, field, val));

        if (validator) {
            const errorMessage = validator(val);
            setFormValues(currentFormValues => {
                return set({...currentFormValues}, `errors.${field}`, errorMessage ?? null);
            });
        }
    }, []);

    const onPhotoChange = (imageUrl, imageId) => {
        onFormValueChange("imageUrl", imageUrl);
        onFormValueChange("imageId", imageId);
    };

    const addProvider = async formData => {
        const res = await HttpClient.safePut("/api/providers", formData);

        if (res.error) {
            const extraInfoError = Object.entries(res?.extraInfo ?? {})
                .map(([key, value]) => `${key}: ${value}`)
                .join(" | ");

            EventBus.triggerError(
                "server-error",
                {
                    content: {
                        description: [
                            "Unfortunately we didn't manage to complete your sign up :(",
                            `Error: ${res.error}`,
                            ...(extraInfoError ? [`ExistingUser: ${extraInfoError}`] : [])
                        ],
                        hideSteps: true
                    }
                },
                res.error.message
            );
            return false;
        }

        message.success("הידד העלת שותף חדש למערכת", 3);
        return res;
    };

    const updateProvider = async (formData, providerId) => {
        const res = await HttpClient.safePost(`/admin/api/providers/${providerId}/update`, formData);

        if (res.error) {
            const extraInfoError = Object.entries(res?.extraInfo ?? {})
                .map(([key, value]) => `${key}: ${value}`)
                .join(" | ");

            EventBus.triggerError(
                "server-error",
                {
                    content: {
                        description: [
                            "Unfortunately we didn't manage to complete your changes :(",
                            `Error: ${res.error}`,
                            ...(extraInfoError ? [`ExistingUser: ${extraInfoError}`] : [])
                        ],
                        hideSteps: true
                    }
                },
                res.error.message
            );
            return false;
        }

        message.success("שותף עודכן בהצלחה", 3);
        return res;
    };

    const signupNewProvider = async values => {
        const {user, extraDetails} = await getNewProviderDetails(values);
        const formData = {extraDetails, user, ignoreInvitation: true};
        const result = values.providerId
            ? await updateProvider(formData, values.providerId)
            : await addProvider(formData);

        setSaveProvider(result);
        return result;
    };

    const validateProviderFields = useCallback(() => {
        for (const [field, validator] of Object.entries(providerFormValidators)) {
            const formValueByKey = get(formValues, field);
            const error = validator(formValueByKey);
            onFormValueChange(`errors.${field}`, error ?? null);
        }

        const requiredFieldKeys = Object.keys(requiredProviderFields);

        for (const key of requiredFieldKeys) {
            const formValueByKey = get(formValues, key);

            if (!formValueByKey) {
                const error = new StringBuilder("שגיאה! השדה הבא חסר")
                    .append(`${providerFormFieldsToName[key]}`)
                    .toString();
                return {error};
            }
        }

        if (formValues?.errors) {
            for (const [field, errorMessage] of Object.entries(formValues.errors ?? {})) {
                if (errorMessage) {
                    const error = new StringBuilder("שגיאה!")
                        .append(`${providerFormFieldsToName[field]}:`)
                        .append(errorMessage)
                        .toString();
                    return {error};
                }
            }
        }
    }, [formValues]);

    return (
        <div className="admin-upload-page">
            <div className="admin-upload-page-container">
                <GenericModal
                    visible={saveProvider}
                    image={SuccessImageLink}
                    onClose={() => {
                        if (saveProvider.userId) {
                            history.push(`${saveProvider.userId}`);
                        }

                        setSaveProvider(false);
                    }}
                    className={"order-location-input-upload-excel-modal"}
                    title="נשמר בהצלחה!"
                    options={[
                        {
                            id: "save",
                            text: "מגניבבב",
                            onClick: () => {
                                if (saveProvider.userId) {
                                    history.push(`${saveProvider.userId}`);
                                }

                                setSaveProvider(false);
                            }
                        }
                    ]}
                />

                <ProviderInfoForm
                    providerInfo={providerInfo}
                    form={form}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    onFormValueChange={onFormValueChange}
                    providerSubProducts={providerSubProducts}
                    setProviderSubProducts={setProviderSubProducts}
                    onPhotoChange={onPhotoChange}
                    assignPartnerOptions={assignPartnerOptions}
                    editMode={isEditMode ? FormEditModes.Update : FormEditModes.Create}
                    products={products}
                    setProducts={setProducts}
                    productsSearchTags={productsSearchTags}
                    formProps={{
                        onFinish: async () => {
                            const validationResponse = validateProviderFields();

                            if (validationResponse?.error) {
                                message.error(validationResponse?.error);
                                return;
                            }

                            const {errors, ...providerDetails} = formValues;

                            const isSuccess = await signupNewProvider({
                                ...providerDetails,
                                subProducts: providerSubProducts
                            });

                            window.scrollTo(0, 0);
                        },
                        fields: Object.keys(formValues).map(key => {
                            return {
                                name: [key],
                                value: formValues[key]
                            };
                        }),
                        onValuesChange: changedField => {
                            const [fieldKey, fieldValue] = Object.entries(changedField)[0];
                            onFormValueChange(fieldKey, fieldValue);
                        },
                        onFinishFailed: ({errorFields}) => {
                            if (errorFields.length > 0) {
                                message.error("חסרים לך כמה שדות, שים לב!", 3);
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
};
