import React, {useContext} from "react";
import {Avatar} from "antd";
import {EXCenterContext} from "../EXCenterContext";

export const EXCenterBirthdayAvatar = ({width, height, style, avatarStyle, employee, hoverEffect = true}) => {
    const {theme} = useContext(EXCenterContext);
    return (
        <div
            className={hoverEffect ? "wb-birthday-avatar-container" : null}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: 20,
                paddingTop: 30,
                ...style
            }}>
            {employee.imageUrl ? (
                <Avatar
                    src={employee.imageUrl}
                    style={{
                        minWidth: width,
                        minHeight: height,
                        ...avatarStyle
                    }}
                />
            ) : (
                <Avatar
                    style={{
                        backgroundColor: theme.primaryColor,
                        opacity: 0.9,
                        minWidth: width,
                        minHeight: height,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: 20,
                        fontWeight: 900,
                        ...avatarStyle
                    }}>
                    {employee.firstName.charAt(0).toUpperCase()}
                </Avatar>
            )}
        </div>
    );
};
