import React, {useContext} from "react";
import {AppLogo} from "../../AppLogo";
import {StyledButton} from "../../components/StyledButton";
import {truncate} from "lodash";
import {Link} from "react-router-dom";
import {EXCenterInfoItem} from "../infos/EXCenterInfoItem";
import {EXCenterContext} from "../EXCenterContext";
import {AutoDirectionProvider} from "../../AutoDirectionProvider";
import {EXCenterTitleTooltip} from "../EXCenterTitleTooltip";
import {ScreenSize} from "../EXCenterHelper";

const isSmallScreen = screenSize => {
    return [ScreenSize.DesktopHuge, ScreenSize.DesktopBig, ScreenSize.DesktopMedium].every(size => screenSize !== size);
};

export const EXCenterUpdateCard = ({item}) => {
    const {theme, screenSize} = useContext(EXCenterContext);
    const infoItem = new EXCenterInfoItem(item);

    const smallScreen = isSmallScreen(screenSize);

    const fullSubTitle = infoItem.subTitle || "";
    const subTitle = truncate(fullSubTitle, {separator: " ", length: smallScreen ? 55 : 120});

    return (
        <Link
            style={{cursor: infoItem.hasAdditionalContent() ? "pointer" : "not-allowed"}}
            to={
                infoItem.hasAdditionalContent()
                    ? `/center/sections/${infoItem.sectionId}/content/${infoItem.itemId}`
                    : "#"
            }>
            <div
                onClick={e => {
                    if (!infoItem.hasAdditionalContent()) {
                        e.stopPropagation();
                        e.preventDefault();
                    }
                }}
                className="wb-ss-carousel-card"
                style={{
                    height: 450,
                    width: 900,
                    borderRadius: 10,
                    marginLeft: 100,
                    marginRight: 100,
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        maxWidth: 650
                    }}>
                    <AppLogo
                        style={{
                            position: "relative",
                            left: 0,
                            marginBottom: 0,
                            borderBottom: "none",
                            height: 65,
                            maxWidth: 160
                        }}
                        width="160"
                        height="65"
                    />
                    <AutoDirectionProvider
                        text={infoItem.title}
                        style={{
                            fontSize: 34,
                            fontWeight: 900,
                            color: theme.textColor,
                            marginBottom: smallScreen ? 10 : 25,
                            textAlign: "center"
                        }}>
                        <EXCenterTitleTooltip
                            title={infoItem.title}
                            minLength={smallScreen ? 40 : infoItem.subTitle ? 70 : 120}
                        />
                    </AutoDirectionProvider>
                    <AutoDirectionProvider
                        text={fullSubTitle}
                        style={{
                            fontSize: 24,
                            fontWeight: 200,
                            color: theme.textColor,
                            marginBottom: smallScreen ? 20 : 45,
                            textAlign: "center"
                        }}>
                        {subTitle}
                    </AutoDirectionProvider>
                    <StyledButton
                        className="wb-center-action-button"
                        style={{
                            height: 40,
                            borderRadius: 20,
                            color: "white",
                            backgroundColor: theme.primaryColor,
                            width: 135,
                            display: infoItem.hasAdditionalContent() ? "block" : "none"
                        }}>
                        Read more
                    </StyledButton>
                </div>
            </div>
        </Link>
    );
};
