import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button, DatePicker, message, Tooltip} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import moment from "moment-timezone";
import {partition} from "lodash";
import {
    errorTypes,
    InvoicesStatusToNameAndColor,
    StartOfProviderInvoiceReform,
    StartOfProviderPaymentReform
} from "./consts";
import {
    getSummaryData,
    findInvoiceWithOrder,
    isUserAllowedToUploadInvoiceInSelectedMonth,
    generateErrorMessage
} from "./utils";
import getColumns from "./columns";
import {ConfirmationRequiredWrapper, RoundedTable, SearchInputWithIcon} from "../../components";
import {ProviderAccountingDocumentModal} from "../ProviderAccountingDocumentModal";
import {momentHebrewFormat} from "../../utils/DateFormat";
import {TimelineModal} from "../../components/Timeline";
import {useRequest} from "../../utils/hooks";
import {TrashIcon} from "../../icons";
import {ProviderDealerTypes} from "../../admin/upload/AdminUploadUtils";
import "./provider-manage-invoices-table.css";

const ProviderManageInvoicesTable = ({
    orders,
    loadingOrders,
    selectedMonth,
    setSelectedMonth,
    providerDetails,
    shouldSelectInvoicedOrders = false,
    uploadInvoice,
    invoices,
    deleteInvoice,
    logsURI,
    allowViewDatesBeforeInvoiceReform = false
}) => {
    const [confirmInvoiceData, setConfirmInvoiceData] = useState(null);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [showLogsModal, setShowLogsModal] = useState(false);
    const [showInvoiceOfMonthModal, setShowInvoiceOfMonthModal] = useState(false);
    const [searchInput, setSearchInput] = useState(null);

    const [logsFromServer] = useRequest(logsURI, "GET", null, [], !!showLogsModal);

    const invoiceLogs = useMemo(() => {
        if (!logsFromServer?.length) return [];

        return logsFromServer.map(log => {
            const text = (
                <div className="provider-manage-invoices-logs-modal">
                    <div className="text-container">
                        <div className="text">
                            <span style={{color: InvoicesStatusToNameAndColor[log.invoice?.status.id]?.color}}>
                                <u>{InvoicesStatusToNameAndColor[log.invoice?.status.id].name}</u>
                                {log.invoice?.status.extraInfo?.declineMessage
                                    ? ` - ${log.invoice?.status.extraInfo?.declineMessage}`
                                    : null}
                                {log.specialLogType === "DeleteLog" ? (
                                    <span className="error-text">{" חשבונית נמחקה"}</span>
                                ) : null}
                            </span>
                            <span>({log.editor.email})</span>
                            <span>|</span>
                            <a href={log.invoice?.file.fileUrl} target="_blank">
                                מספר חשבונית: {log.invoice?.invoiceNumber}
                            </a>
                            <span>|</span>
                            <span>{moment(log.createdAt).format("DD/MM/YYYY")}</span>
                        </div>
                        <div>
                            <span>₪</span>
                            <span>{log.invoice?.price}</span>
                        </div>
                    </div>
                </div>
            );

            const icon = (
                <div
                    className="provider-manage-invoices-logs-modal-icon"
                    style={{backgroundColor: InvoicesStatusToNameAndColor[log.invoice?.status.id].color}}
                />
            );

            return {
                id: log.id,
                icon,
                text
            };
        });
    }, [logsFromServer]);

    const invoicesOfMonth = useMemo(() => {
        if (!invoices?.length) return [];

        return invoices.map(invoice => {
            const text = (
                <div className="provider-manage-invoices-logs-modal">
                    <div className="text-container">
                        <div className="text">
                            <span style={{color: InvoicesStatusToNameAndColor[invoice.status.id].color}}>
                                <u>{InvoicesStatusToNameAndColor[invoice.status.id].name}</u>
                            </span>
                            <span>|</span>
                            <a href={invoice.file.fileUrl} target="_blank">
                                מספר חשבונית: {invoice.invoiceNumber}
                            </a>
                            <span>|</span>
                            <span>{moment(invoice.createdAt).format("DD/MM/YYYY")}</span>
                        </div>

                        <div className="end-text">
                            <span>₪</span>
                            <span>{invoice.price}</span>
                            {deleteInvoice ? (
                                <ConfirmationRequiredWrapper
                                    title="למחוק חשבונית?"
                                    onYes={() => deleteInvoice(invoice.id)}>
                                    <TrashIcon />
                                </ConfirmationRequiredWrapper>
                            ) : null}
                        </div>
                    </div>
                    {invoice.status.extraInfo?.declineMessage && (
                        <div>
                            <span>סיבת הדחייה: </span>
                            <span>{invoice.status.extraInfo?.declineMessage}</span>
                        </div>
                    )}
                </div>
            );

            const icon = (
                <div
                    className="provider-manage-invoices-logs-modal-icon"
                    style={{backgroundColor: InvoicesStatusToNameAndColor[invoice.status.id].color}}
                />
            );

            return {
                id: invoice.id,
                icon,
                text
            };
        });
    }, [invoices, deleteInvoice]);

    const filteredOrders = useMemo(
        () =>
            searchInput
                ? orders?.filter(order =>
                      ["productName", "proposalId"].some(key => String(order[key])?.includes(searchInput))
                  )
                : orders,
        [searchInput, orders]
    );

    const selectAll = useCallback(() => {
        if (filteredOrders?.length) {
            const [invoicedOrders, notInvoicedOrders] = partition(filteredOrders, ({eventId}) =>
                findInvoiceWithOrder(eventId, invoices)
            );

            if (shouldSelectInvoicedOrders) {
                setSelectedOrders(invoicedOrders);
            } else {
                setSelectedOrders(notInvoicedOrders);
            }
        } else {
            setSelectedOrders([]);
        }
    }, [shouldSelectInvoicedOrders, filteredOrders, invoices]);

    useEffect(() => {
        selectAll();
    }, [selectAll]);

    const isExemptDealerType = useMemo(
        () => providerDetails?.dealerType === ProviderDealerTypes.exempt,
        [providerDetails?.dealerType]
    );

    const columns = useMemo(
        () =>
            getColumns({
                providerProducts: providerDetails?.services,
                isExemptDealerType,
                invoices,
                maxShippingProvidersDeliveryPrice: providerDetails?.maxShippingProvidersDeliveryPrice
            }),
        [providerDetails?.services, providerDetails?.maxShippingProvidersDeliveryPrice, invoices, isExemptDealerType]
    );

    useEffect(() => {
        if (isUserAllowedToUploadInvoiceInSelectedMonth(selectedMonth)) {
            message.warn(
                generateErrorMessage(errorTypes.DATE_ERROR, {
                    monthToUpload: momentHebrewFormat(moment().subtract(1, "month"), "MMMM")
                })
            );
        }
    }, [selectedMonth]);

    return (
        <>
            <TimelineModal
                visible={showInvoiceOfMonthModal}
                title={`ניהול ${isExemptDealerType ? "קבלות" : "חשבוניות"}: ${providerDetails?.businessName}`}
                list={invoicesOfMonth}
                onClose={() => setShowInvoiceOfMonthModal(false)}
            />
            <TimelineModal
                visible={showLogsModal}
                title={`מעקב פעולות: ${momentHebrewFormat(selectedMonth, "MMMM YYYY")}`}
                list={invoiceLogs}
                onClose={() => setShowLogsModal(false)}
            />
            {confirmInvoiceData ? (
                <ProviderAccountingDocumentModal
                    isExemptDealerType={isExemptDealerType}
                    confirmInvoiceData={confirmInvoiceData}
                    onClose={() => setConfirmInvoiceData(null)}
                    onSave={values => {
                        uploadInvoice({
                            ...values,
                            orders: selectedOrders?.map(order => order.eventId),
                            month: selectedMonth,
                            totalOrders: orders.length
                        });
                        setConfirmInvoiceData(null);
                    }}
                />
            ) : null}

            <div>
                <div className="provider-manage-invoices-top-bar">
                    <div className="search-and-date">
                        {setSelectedMonth ? (
                            <DatePicker
                                format="MM-YYYY"
                                placeholder="בחרו חודש"
                                onChange={setSelectedMonth}
                                value={selectedMonth}
                                picker="month"
                                disabledDate={date =>
                                    date.isBefore(
                                        moment(
                                            allowViewDatesBeforeInvoiceReform
                                                ? StartOfProviderPaymentReform
                                                : StartOfProviderInvoiceReform
                                        ),
                                        "month"
                                    )
                                }
                                allowClear={false}
                            />
                        ) : null}
                        <SearchInputWithIcon searchInput={searchInput} setSearchInput={setSearchInput} />
                    </div>
                    <span onClick={() => setShowInvoiceOfMonthModal(true)}>
                        ניהול {isExemptDealerType ? "קבלות" : "חשבוניות"}
                    </span>
                </div>
                <RoundedTable
                    rowKey="eventId"
                    bordered={true}
                    rowClassName={({eventId}) =>
                        findInvoiceWithOrder(eventId, invoices) ? "linked-to-invoice-row" : null
                    }
                    rowSelection={{
                        selectedRowKeys: selectedOrders?.map(order => order.eventId),
                        onSelect: (_, __, newSelectedRows) => setSelectedOrders(newSelectedRows),
                        onSelectAll: select => (select ? selectAll() : setSelectedOrders([])),
                        getCheckboxProps: ({eventId}) => ({
                            disabled: findInvoiceWithOrder(eventId, invoices) ? "linked-to-invoice-row" : null
                        })
                    }}
                    className="provider-manage-invoices-table"
                    pagination={false}
                    loading={loadingOrders}
                    dataSource={filteredOrders ?? []}
                    columns={columns}
                    summary={() => {
                        const summaryData = getSummaryData(
                            selectedOrders ?? [],
                            isExemptDealerType,
                            providerDetails?.maxShippingProvidersDeliveryPrice
                        );
                        return (
                            <RoundedTable.Summary.Row>
                                <RoundedTable.Summary.Cell colSpan={5}>
                                    {isExemptDealerType ? "סיכום לקבלה לא כולל מע״מ" : "סיכום לחשבונית כולל מע״מ"}
                                </RoundedTable.Summary.Cell>
                                <RoundedTable.Summary.Cell>
                                    {summaryData.invoicePrice ? `${summaryData.invoicePrice.toLocaleString()} ₪` : null}
                                </RoundedTable.Summary.Cell>
                                <RoundedTable.Summary.Cell>
                                    {summaryData.deliveryPrice
                                        ? `${summaryData.deliveryPrice.toLocaleString()} ₪`
                                        : null}
                                </RoundedTable.Summary.Cell>
                                <RoundedTable.Summary.Cell>
                                    {summaryData.deliveryPriceByWellB
                                        ? `${summaryData.deliveryPriceByWellB.toLocaleString()} ₪`
                                        : null}
                                </RoundedTable.Summary.Cell>
                                <RoundedTable.Summary.Cell className="ltr">
                                    {summaryData.providerDiscount
                                        ? `${(-1 * summaryData.providerDiscount).toLocaleString()} ₪`
                                        : null}
                                </RoundedTable.Summary.Cell>
                                <RoundedTable.Summary.Cell>
                                    {summaryData.tip ? `${summaryData.tip.toLocaleString()} ₪` : null}
                                </RoundedTable.Summary.Cell>
                                <RoundedTable.Summary.Cell colSpan={2}>
                                    {summaryData.totalPrice ? `₪ ${summaryData.totalPrice.toLocaleString()}` : null}
                                </RoundedTable.Summary.Cell>
                            </RoundedTable.Summary.Row>
                        );
                    }}
                />

                <div className="provider-manage-invoices-bottom-bar">
                    <span onClick={() => setShowLogsModal(true)}>
                        מעקב פעולות {momentHebrewFormat(selectedMonth, "MMMM YYYY")}
                    </span>
                    <div className="provider-manage-invoices-button-container">
                        {uploadInvoice ? (
                            <Tooltip
                                title={
                                    isUserAllowedToUploadInvoiceInSelectedMonth(selectedMonth)
                                        ? generateErrorMessage(errorTypes.DATE_ERROR, {
                                              monthToUpload: momentHebrewFormat(moment().subtract(1, "month"), "MMMM")
                                          })
                                        : ""
                                }>
                                <Button
                                    disabled={
                                        !selectedOrders?.length ||
                                        isUserAllowedToUploadInvoiceInSelectedMonth(selectedMonth)
                                    }
                                    onClick={() => {
                                        const summaryData = getSummaryData(
                                            selectedOrders ?? [],
                                            isExemptDealerType,
                                            providerDetails?.maxShippingProvidersDeliveryPrice
                                        );
                                        setConfirmInvoiceData({
                                            price: parseFloat(summaryData.totalPrice.toFixed(2)),
                                            monthInHebrew: momentHebrewFormat(selectedMonth, "MMMM YYYY"),
                                            orders: selectedOrders
                                        });
                                    }}>
                                    <UploadOutlined />
                                    טעינת {isExemptDealerType ? "קבלה" : "חשבונית"} עבור{" "}
                                    {momentHebrewFormat(selectedMonth, "MMMM YYYY")}
                                </Button>
                            </Tooltip>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProviderManageInvoicesTable;
