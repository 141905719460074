import {Form} from "antd";
import {FormInput} from "../../../components/form";
import {StyledButton} from "../../../components/StyledButton";
import React from "react";

export const CompanyContentTagInfoForm = ({tags, initialTagInfo = {}, onFinish, submitText}) => {
    const [form] = Form.useForm();

    tags = tags.filter(tagInfo => tagInfo.tagId !== initialTagInfo.tagId);

    const submit = () => {
        form.submit();
    };

    return (
        <Form form={form} onFinish={onFinish} initialValues={initialTagInfo} style={{maxWidth: 300, width: "100%"}}>
            <Form.Item
                name="name"
                validateFirst={true}
                rules={[
                    {
                        required: true,
                        message: "Please enter category name."
                    },
                    {
                        validator: (_, value) => {
                            if (
                                value &&
                                tags.some(({name}) => value.toString().toLowerCase() === name.toString().toLowerCase())
                            ) {
                                return Promise.reject("Category name must be unique.");
                            }

                            return Promise.resolve();
                        }
                    }
                ]}>
                <FormInput placeholder="Category name" />
            </Form.Item>
            <Form.Item>
                <StyledButton
                    onClick={submit}
                    style={{
                        borderRadius: 25,
                        width: "100%",
                        textAlign: "center",
                        backgroundColor: "var(--secondary-color)",
                        height: 50,
                        color: "white"
                    }}>
                    {submitText}
                </StyledButton>
            </Form.Item>
        </Form>
    );
};
