export const ProductDetailsPanelKeys = {
    GeneralDescription: "generalDescription",
    PackageInfo: "packageInfo",
    AdditionalInfo: "additionalInfo",
    ProductTags: "productTags",
    QuestionAndAnswers: "questionAndAnswers"
};

export const allHebrewLetters = [
    "א",
    "ב",
    "ג",
    "ד",
    "ה",
    "ו",
    "ז",
    "ח",
    "ט",
    "י",
    "כ",
    "ל",
    "מ",
    "נ",
    "ס",
    "ע",
    "פ",
    "צ",
    "ק",
    "ר",
    "ש",
    "ת"
];
