import {HttpClient} from "../../http/HttpClient";
import {PRIVATE_EMAIL_DOMAINS} from "./consts.js";

export const validateEmailExistence = async (email, existMsg = "Email already exists.") => {
    try {
        await HttpClient.post("/api/users/email/exist", {email});
    } catch (e) {
        if (e.statusCode === 400) {
            throw Error(existMsg || "Email already exists.");
        } else {
            throw Error("Something went wrong.");
        }
    }
};

export const safeValidateEmailExistence = async email => {
    if (!email?.length) {
        return false;
    }

    const {error, status} = await HttpClient.safePost("/api/users/email/exist", {email});

    if (error && status !== 400) {
        return false;
    }

    return !!error;
};

export const validateEmailOfProvider = async (email, providerId) => {
    try {
        await HttpClient.post("/api/users/email/exist", {email});
    } catch (e) {
        if (e.statusCode === 400) {
            throw Error("Email already exists.");
        } else {
            throw Error("Something went wrong.");
        }
    }
};

export const validateGmail = async email => {
    try {
        return await HttpClient.post("/api/users/email/gmail", {email});
    } catch (e) {
        return false;
    }
};

export const isValidEmailRegex = email => {
    const validEmailRegex =
        /^(?=.{1,256})(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?(?:\.[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?)+$/;
    return validEmailRegex.test(email);
};

export const isPrivateEmail = email => {
    if (!email) return false;
    const domain = email.split("@").pop().toLowerCase();
    return PRIVATE_EMAIL_DOMAINS.has(domain);
};
