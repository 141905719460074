import React, {useState, useEffect, useReducer} from "react";
import PageTitleHeader from "../components/PageTitle";
import {RoundedTable} from "../components/RoundedTable";
import {Button, Form, message, Select} from "antd";
import {StyledButton} from "../components/StyledButton";
import {ProfessionText, ProfessionType, sortProfessions} from "../data/professions";
import {FormInput} from "../components/form";
import {HttpClient} from "../http/HttpClient";
import {SearchableSelect} from "../components/SearchableSelect";

const {Option} = Select;

const columns = (onDelete, deletingName, services) => {
    const limitedTo = name => {
        const boundedServices = services.filter(s => {
            return Array.isArray(s.boundedTo) && s.boundedTo.indexOf(name) >= 0;
        });

        if (boundedServices.length > 0) {
            return boundedServices.map(s => s.categoryName).join(", ");
        }

        return "-";
    };

    return [
        {
            title: "NAME",
            dataIndex: "name",
            key: "name",
            sorter: (r1, r2) => (r1.name.toLowerCase() < r2.name.toLowerCase() ? 1 : -1)
        },
        {
            title: "PAYMENT PLAN",
            dataIndex: "type",
            key: "type",
            filters: [
                {
                    text: ProfessionText[ProfessionType.REVENUE_BASED],
                    value: ProfessionType.REVENUE_BASED
                },
                {
                    text: ProfessionText[ProfessionType.PER_ACTIVE_DAY],
                    value: ProfessionType.PER_ACTIVE_DAY
                }
            ],
            filterMultiple: false,
            onFilter: (value, record) => record.type.indexOf(value) === 0,
            render(type) {
                return ProfessionText[type];
            }
        },
        {
            title: "LIMITED TO",
            key: "limitedTo",
            filters: [
                {
                    text: "3P",
                    value: "3P"
                },
                {
                    text: "Not 3P",
                    value: null
                }
            ],
            filterMultiple: false,
            onFilter: (value, {name}) => (value === "3P" ? limitedTo(name) !== "-" : limitedTo(name) === "-"),
            render: (_, {name}) => limitedTo(name)
        },
        {
            title: "DELETE",
            key: "deleteProfession",
            render(_, {name}) {
                return (
                    <Button
                        style={{minWidth: 100}}
                        loading={deletingName === name}
                        onClick={async () => await onDelete(name)}>
                        Delete
                    </Button>
                );
            }
        }
    ];
};

export const AdminProfessions = () => {
    const [loading, setLoading] = useState(false);
    const [deletingName, setDeletingName] = useState(null);
    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        services: null,
        professions: null
    });

    useEffect(() => {
        (async () => {
            const professions = await fetchProfessions();
            const services = await fetchServices();
            if (professions && services) {
                setState({
                    professions: sortProfessions(professions),
                    services: services.filter(s => !s.comingSoon)
                });
            }
        })();
    }, []);

    const fetchProfessions = async () => {
        try {
            return await HttpClient.get("/api/professions");
        } catch (e) {
            message.error(e.message, 3);
        }
    };

    const fetchServices = async () => {
        try {
            return await HttpClient.get("/admin/api/categories");
        } catch (e) {
            message.error(e.message, 3);
        }
    };

    const addProfession = async ({name, type, serviceId}) => {
        let {services, professions} = state;

        setLoading(true);
        try {
            const newProfession = await HttpClient.put("/admin/api/professions", {
                name,
                type,
                serviceId
            });

            if (serviceId) {
                services.find(s => s.serviceId === serviceId);
                services = services.map(s => {
                    if (s.serviceId === serviceId) {
                        s.boundedTo = Array.isArray(s.boundedTo) ? s.boundedTo.concat([name]) : [name];
                    }

                    return s;
                });
            }

            setState({
                professions: sortProfessions(professions.concat([newProfession])),
                services: services.filter(s => !s.comingSoon)
            });
        } catch (e) {
            message.error(e.message, 3);
        }
        setLoading(false);
    };

    const deleteProfession = async name => {
        const {professions} = state;

        setDeletingName(name);
        try {
            await HttpClient.delete(`/admin/api/professions`, {name});
            setState({
                professions: sortProfessions(professions.filter(p => p.name !== name))
            });
        } catch (e) {
            message.error(e.message, 3);
        }
        setDeletingName(null);
    };

    const {services, professions} = state;

    const AddProfessionForm = () => (
        <div style={{overflow: "auto", height: 80, display: "flex", alignItems: "center"}}>
            <Form layout="inline" style={{minWidth: 800}} onFinish={async values => await addProfession(values)}>
                <Form.Item
                    name="name"
                    style={{marginBottom: 0}}
                    rules={[{required: true, message: "Please enter name."}]}>
                    <FormInput style={{minWidth: 120}} placeholder="Profession name" />
                </Form.Item>
                <Form.Item
                    name="type"
                    style={{marginBottom: 0}}
                    rules={[{required: true, message: "Please select payment plan."}]}>
                    <Select style={{minWidth: 140}} placeholder="Payment plan">
                        {Object.keys(ProfessionType).map(key => {
                            const type = ProfessionType[key];
                            return (
                                <Option key={type} value={type}>
                                    {ProfessionText[type]}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item name="serviceId" style={{marginBottom: 0}}>
                    <SearchableSelect style={{minWidth: 180}} placeholder="Limited to category?">
                        <Option key={"service-none"} value="">
                            None
                        </Option>
                        {(services || []).map(({serviceId, categoryName}) => (
                            <Option key={serviceId} value={serviceId}>
                                {categoryName}
                            </Option>
                        ))}
                    </SearchableSelect>
                </Form.Item>
                <Form.Item style={{marginBottom: 0}}>
                    <StyledButton loading={loading} htmlType="submit" style={{minWidth: 80}}>
                        Add
                    </StyledButton>
                </Form.Item>
            </Form>
        </div>
    );

    return (
        <div style={{display: "flex", justifyContent: "center", margin: 10, marginBottom: 40}}>
            <div style={{display: "flex", flexDirection: "column", maxWidth: 850, width: "100%"}}>
                <PageTitleHeader showBack={false}>Professions</PageTitleHeader>
                <RoundedTable
                    rowKey="name"
                    scroll={{x: 800}}
                    title={() => <AddProfessionForm />}
                    pagination={false}
                    loading={professions === null || services === null}
                    dataSource={professions && services ? professions : []}
                    columns={columns(async name => await deleteProfession(name), deletingName, services || [])}
                />
            </div>
        </div>
    );
};
