import {Collapse, Spin, Select} from "antd";
import {RightOutlined} from "@ant-design/icons";
import React, {useState, useEffect, useReducer, useContext} from "react";
import {EventBus} from "../bus/EventBus";
import {HttpClient} from "../http/HttpClient";
import {SearchableSelect} from "../components/SearchableSelect";
import {uniqWith as _uniqWith, isEqual as _isEqual, truncate, take} from "lodash";
import PageTitleHeader, {PageSubTitleLabel} from "../components/PageTitle";
import moment from "moment-timezone";
import {TrashIcon} from "../icons";
import {LocationLabels} from "../data/locations";
import {AppContext} from "../AppContext";
import {Route, Switch} from "react-router";
import ProviderEventAppointment from "../provider/ProviderEventAppointment";
import {CollapseContent, CollapseHeader} from "../components/CustomCollapse";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {StyledButton} from "../components/StyledButton";
import {Link} from "react-router-dom";
import {FrequencyMapper} from "../event/FrequencyMapper";
import {parse as qsParse} from "query-string";
import {ensureByDate} from "../event/EventByDate";
import {RRuleSetBuilder} from "../event/RRuleSetBuilder";
import {PageLoader} from "../components/PageLoader";

const {Panel} = Collapse;
const {Option} = SearchableSelect;

const AppointmentHeader = ({appointment}) => {
    const {theme} = useContext(AppContext);
    return (
        <CollapseHeader
            name={appointment.productName}
            date={moment(appointment.date, "YYYY-MM-DD").format("MMM D, YYYY")}
            dateWidth={100}
            backgroundColor={theme.secondary}
            color={theme.textSecondary}
        />
    );
};

const EventHeader = ({event}) => {
    const {theme} = useContext(AppContext);
    return (
        <CollapseHeader
            name={event.serviceName || truncate(event.sessionName, {separator: " ", length: 90})}
            date={FrequencyMapper.toShortHumanText(event, true, false)}
            dateWidth={130}
            backgroundColor={theme.secondary}
            color={theme.textSecondary}
        />
    );
};

const AppointmentContent = ({appointment, onEdit, onDelete, deleting}) => {
    const dateMom = moment(`${appointment.date} ${appointment.start}`, "YYYY-MM-DD HH:mm").subtract(1, "hours");
    const now = moment(Date.now());
    const editEnabled = now.isSameOrBefore(dateMom);
    return (
        <CollapseContent
            providerName={`${appointment.providerFirstName} ${
                appointment.providerLastName ? appointment.providerLastName : ""
            }`}
            providerPhone={appointment.providerPhone}
            price={appointment.price}
            address={appointment.address}
            date={dateMom.format("MMMM Do, YYYY")}
            start={appointment.start}
            end={appointment.end}
            onEdit={editEnabled ? () => onEdit(appointment) : null}
            onDelete={editEnabled ? () => onDelete(appointment) : null}
            deleting={deleting}
            deletePopupMessage={
                <span>
                    Delete this appointment?
                    {appointment.confirmationCode ? (
                        <>
                            <br />
                            <strong>
                                If this appointment was pre-paid please contact {appointment.providerFirstName} to get a
                                refund.
                            </strong>
                        </>
                    ) : null}
                </span>
            }
            deleteIcon={<TrashIcon />}
            deleteText="Delete"
        />
    );
};

const EventContent = ({event, dateMom, onDelete, deleting}) => (
    <CollapseContent
        providerName={`${event.providerFirstName} ${event.providerLastName ? event.providerLastName : ""}`}
        providerPhone={event.providerPhone}
        address={event.location === LocationLabels.ONSITE ? event.address : "Online session"}
        date={dateMom ? dateMom.format("dddd MMM D, YYYY") : FrequencyMapper.toHumanText(event, true, true)}
        start={dateMom ? dateMom.format("HH:mm") : moment(event.dtstart).format("HH:mm")}
        end={
            dateMom
                ? moment(dateMom).add(event.duration, "minutes").format("HH:mm")
                : moment(event.dtstart).add(event.duration, "minutes").format("HH:mm")
        }
        onDelete={onDelete ? () => onDelete(event) : null}
        deleting={deleting}
        deletePopupMessage="Unregister from this event?"
        deleteIcon={<TrashIcon />}
        deleteText="Unregister"
    />
);

const AppointmentsView = ({appointments, deletingAppointmentId, onDelete, onEdit}) => {
    const {theme} = useContext(AppContext);
    return Array.isArray(appointments) && appointments.length > 0 ? (
        <Collapse
            className="wb-appointments"
            defaultActiveKey={appointments.map(appt => appt.appointmentId)}
            expandIconPosition="right"
            expandIcon={({isActive}) => (
                <RightOutlined style={{fontSize: 16, color: theme.textSecondary}} rotate={isActive ? 90 : 0} />
            )}
            style={{borderBottom: "none", width: "100%", marginTop: 5}}>
            {appointments.map(appointment => (
                <Panel
                    key={appointment.appointmentId}
                    className="wb-appointment employee"
                    style={{backgroundColor: theme.secondary, color: theme.textSecondary}}
                    showArrow={true}
                    header={<AppointmentHeader appointment={appointment} />}>
                    <AppointmentContent
                        appointment={appointment}
                        deleting={deletingAppointmentId === appointment.appointmentId}
                        onDelete={appointment => onDelete(appointment)}
                        onEdit={appointment => onEdit(appointment)}
                    />
                </Panel>
            ))}
        </Collapse>
    ) : (
        <span style={{marginTop: 60, color: "#8492A6", fontSize: 16}}>No appointments.</span>
    );
};

const EventsView = ({events, selectedService, unregisteringEventId, onUnregister}) => {
    const {theme, me} = useContext(AppContext);

    const isRegisteredToAllSessions = event => {
        return (
            Array.isArray(event.registeredEmployees) &&
            event.registeredEmployees.some(reg => reg.employeeId === me.userId)
        );
    };

    const isRegisteredToDate = (event, date) => {
        event = ensureByDate(event);
        return (
            Array.isArray(event.byDate[date].registeredEmployees) &&
            event.byDate[date].registeredEmployees.some(reg => reg.employeeId === me.userId)
        );
    };

    const getActualRegistrationDate = (event, date, rruleSet) => {
        if (isRegisteredToDate(event, date)) {
            return rruleSet.after(moment(date, "YYYY-MM-DD").toDate(), true) || null;
        }

        return null;
    };

    return (
        <Collapse
            className="wb-appointments"
            defaultActiveKey={events.map(evt => evt.eventId)}
            expandIconPosition="right"
            expandIcon={({isActive}) => (
                <RightOutlined style={{fontSize: 16, color: theme.textSecondary}} rotate={isActive ? 90 : 0} />
            )}
            style={{borderBottom: "none", width: "100%", marginTop: 5}}>
            {Array.isArray(events) && events.length > 0 ? (
                take(events, 30)
                    .filter(
                        evt =>
                            evt.serviceId === selectedService.serviceId ||
                            (selectedService.serviceId === "internal" && evt.customSession === true)
                    )
                    .reduce((panels, event) => {
                        if (isRegisteredToAllSessions(event)) {
                            panels.push({
                                dateMom: moment(event.dtstart),
                                panel: (
                                    <Panel
                                        key={event.eventId}
                                        className="wb-appointment employee"
                                        style={{backgroundColor: theme.secondary, color: theme.textSecondary}}
                                        showArrow={true}
                                        header={<EventHeader event={event} />}>
                                        <EventContent
                                            event={event}
                                            deleting={unregisteringEventId === event.eventId}
                                            onDelete={async event => await onUnregister(event.eventId)}
                                        />
                                    </Panel>
                                )
                            });
                        } else {
                            const rruleSet = RRuleSetBuilder.build(event);
                            panels = panels.concat(
                                Object.keys(event.byDate).reduce((innerPanels, date) => {
                                    const actualDate = getActualRegistrationDate(event, date, rruleSet);
                                    if (actualDate) {
                                        innerPanels.push({
                                            dateMom: moment(actualDate),
                                            panel: (
                                                <Panel
                                                    key={event.eventId}
                                                    className="wb-appointment employee"
                                                    style={{
                                                        backgroundColor: theme.secondary,
                                                        color: theme.textSecondary
                                                    }}
                                                    showArrow={true}
                                                    header={<EventHeader event={event} />}>
                                                    <EventContent
                                                        event={event}
                                                        dateMom={moment(actualDate)}
                                                        deleting={unregisteringEventId === event.eventId}
                                                        onDelete={async event =>
                                                            await onUnregister(event.eventId, "only", date)
                                                        }
                                                    />
                                                </Panel>
                                            )
                                        });
                                    }
                                    return innerPanels;
                                }, [])
                            );
                        }

                        return panels;
                    }, [])
                    .sort((p1, p2) => (p1.dateMom.isAfter(p2.dateMom) ? 1 : -1))
                    .map(p => p.panel)
            ) : (
                <span style={{marginTop: 60, color: "#8492A6", fontSize: 16}}>No sessions.</span>
            )}
        </Collapse>
    );
};

const MyBookings = ({
    appointments,
    events,
    onUnregisterEvent,
    unregisteringEventId,
    onDeleteAppointment,
    deletingAppointmentId,
    onEditAppointment,
    services,
    selectedService,
    onSelectService,
    onChangePreset
}) => (
    <div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center"}}>
        <PageTitleHeader showBack={false}>My bookings</PageTitleHeader>
        <div style={{display: "flex", flexDirection: "column", width: "100%", maxWidth: 600, alignItems: "center"}}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                    paddingLeft: 20,
                    paddingRight: 20,
                    marginBottom: 20,
                    maxWidth: 400
                }}>
                <SearchableSelect
                    defaultValue={selectedService.serviceId}
                    placeholder="No services"
                    style={{width: "100%", marginBottom: 20}}
                    onSelect={serviceId => onSelectService(serviceId)}>
                    {services.map(({serviceId, serviceName}) => (
                        <Option key={serviceId} value={serviceId}>
                            {serviceName}
                        </Option>
                    ))}
                </SearchableSelect>
                {selectedService.appointmentBased ? (
                    <Select defaultValue="future" style={{width: "100%"}} onSelect={preset => onChangePreset(preset)}>
                        <Option value="future">Future bookings</Option>
                        <Option value="past">Past bookings</Option>
                    </Select>
                ) : null}
            </div>
            {selectedService.appointmentBased ? (
                <AppointmentsView
                    appointments={appointments}
                    deletingAppointmentId={deletingAppointmentId}
                    onDelete={onDeleteAppointment}
                    onEdit={onEditAppointment}
                />
            ) : (
                <EventsView
                    events={events}
                    selectedService={selectedService}
                    unregisteringEventId={unregisteringEventId}
                    onUnregister={onUnregisterEvent}
                />
            )}
        </div>
    </div>
);

export const amRegistered = (event, me) => {
    let {registeredEmployees} = event;

    if (event.byDate) {
        registeredEmployees = Object.keys(event.byDate).reduce((regs, date) => {
            if (event.byDate[date] && Array.isArray(event.byDate[date].registeredEmployees)) {
                return regs.concat(event.byDate[date].registeredEmployees);
            }

            return regs;
        }, registeredEmployees || []);
    }

    return (registeredEmployees || []).some(({employeeId}) => employeeId === me.userId);
};

export const EmployeeBookings = ({history}) => {
    const {me, theme} = useContext(AppContext);
    const [deletingAppointmentId, setDeletingAppointmentId] = useState(false);
    const [unregisteringEventId, setUnregisteringEventId] = useState(false);
    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        events: null,
        originalEvents: null,
        appointments: null,
        selectedService: null,
        services: null,
        viewPreset: "future",
        eventToEdit: null,
        appointmentToEdit: null,
        saving: false
    });

    let {
        appointments,
        events,
        originalEvents,
        services,
        selectedService,
        viewPreset,
        eventToEdit,
        appointmentToEdit,
        saving
    } = state;

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const [events, appointments] = await Promise.all([
                    HttpClient.get("/api/events"),
                    HttpClient.get("/api/appointments")
                ]);
                const services = extractServices(events, appointments);
                setState({
                    originalEvents: events,
                    events: events.filter(event => amRegistered(event, me)),
                    appointments,
                    services,
                    selectedService: services.length > 0 ? initialService(services) : null
                });
                GoogleAnalytics.event("Employee Booking", "View Appointments", me.userId);
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we couldn't complete your request :("}},
                    e.message
                );
            }
        });
    }, []);

    const initialService = services => {
        const serviceId = qsParse(location.search).serviceId;
        return services.find(s => s.serviceId === serviceId) || services[0];
    };

    const extractServices = (events, appointments) => {
        const services = events
            .filter(({serviceId, serviceName, appointmentBased, registeredEmployees}) => {
                if (serviceId && serviceName) {
                    return appointmentBased
                        ? appointments.some(appt => appt.serviceId === serviceId)
                        : amRegistered({registeredEmployees}, me);
                }

                return false;
            })
            .map(({serviceId, serviceName, appointmentBased}) => ({serviceId, serviceName, appointmentBased}));

        return _uniqWith(
            [{serviceId: "internal", serviceName: "Internal Sessions", appointmentBased: false}].concat(services || []),
            _isEqual
        );
    };

    const filterAppointmentsByServiceId = (appointments, serviceId) => {
        return appointments.filter(appt => appt.serviceId === serviceId);
    };

    const filterAppointmentsByPreset = (appointments, preset) => {
        return appointments.filter(appt => {
            const now = moment(Date.now());
            const dateMom = moment(`${appt.date} ${appt.start}`, "YYYY-MM-DD HH:mm");
            if (preset === "future") {
                return now.isSameOrBefore(dateMom);
            } else {
                return now.isAfter(dateMom);
            }
        });
    };

    const unregisterEvent = async (eventId, applyType = "all", date = "") => {
        setUnregisteringEventId(eventId);
        try {
            await HttpClient.post(`/api/events/${eventId}/unregister?applyType=${applyType}&date=${date}`, {});
            GoogleAnalytics.event("Employee Booking", "Unregister Session", eventId);
            setState({
                events: events.filter(evt => evt.eventId !== eventId)
            });
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we couldn't delete this appointment :("}},
                e.message
            );
        }
        setUnregisteringEventId(null);
    };

    const deleteAppointment = async appointment => {
        const {appointmentId, eventId} = appointment;
        setDeletingAppointmentId(appointmentId);
        try {
            await HttpClient.delete(`/api/events/${eventId}/appointments/${appointmentId}`);
            GoogleAnalytics.event("Employee Booking", "Delete Appointment", me.userId);
            setState({
                appointments: appointments.filter(appt => appt.appointmentId !== appointmentId)
            });
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we couldn't delete this appointment :("}},
                e.message
            );
        }
        setDeletingAppointmentId(null);
    };

    const editAppointment = async appointmentToEdit => {
        const {eventId, appointmentId, date} = appointmentToEdit;
        const apptDate = moment(date, "YYYY-MM-DD").format("YYYY-M-D");
        setState({
            appointmentToEdit,
            eventToEdit: events.find(evt => evt.eventId === eventId)
        });
        history.push(
            `/dashboard/bookings/events/${eventId}/appointments/${appointmentId}/${apptDate}/${apptDate}/edit`
        );
    };

    const changeViewPreset = viewPreset => {
        setState({viewPreset});
    };

    const selectServiceId = serviceId => {
        setState({selectedService: services.find(s => s.serviceId === serviceId)});
    };

    const saveChanges = async appointment => {
        setState({saving: true});
        try {
            await HttpClient.post(`/api/appointments/${appointment.appointmentId}`, appointment);
            setState({
                appointments: appointments.map(appt => {
                    if (appt.appointmentId === appointment.appointmentId) {
                        return appointment;
                    } else {
                        return appt;
                    }
                })
            });
            GoogleAnalytics.event("Employee Booking", "Update Appointment", appointment.appointmentId);
            history.goBack();
        } catch (e) {
            if (e.statusCode === 400) {
                EventBus.triggerError(
                    "server-error",
                    {
                        content: {
                            title: "Ohh...",
                            hideSubTitle: true,
                            description: e.message,
                            hideSteps: true
                        },
                        cta: {
                            hide: true
                        }
                    },
                    e.message
                );
            } else {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we couldn't update this appointment :("}},
                    e.message
                );
            }
        }
        setState({saving: false});
    };

    if (Array.isArray(events) && events.length === 0 && Array.isArray(appointments) && appointments.length === 0) {
        return (
            <div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center", marginTop: 80}}>
                <PageSubTitleLabel>No bookings yet.</PageSubTitleLabel>
                {Array.isArray(originalEvents) && originalEvents.length > 0 ? (
                    <div style={{display: "flex", width: "100%", justifyContent: "center", marginTop: 20}}>
                        <StyledButton style={{backgroundColor: theme.primary, color: theme.textPrimary, width: 200}}>
                            <Link to="/dashboard/schedule">Book appointment</Link>
                        </StyledButton>
                    </div>
                ) : null}
            </div>
        );
    }

    if (appointments === null || events === null || services === null || selectedService === null) {
        return <PageLoader align="flex-start" top={50} bottom={50} />;
    }

    if (selectedService.appointmentBased) {
        appointments = filterAppointmentsByPreset(
            filterAppointmentsByServiceId(appointments, selectedService.serviceId),
            viewPreset
        ).sort((a1, a2) =>
            moment(`${a1.date} ${a1.start}`, "YYYY-MM-DD HH:mm").isAfter(
                moment(`${a2.date} ${a2.start}`, "YYYY-MM-DD HH:mm")
            )
                ? 1
                : -1
        );
    }

    return (
        <Switch>
            <Route
                exact
                path="/dashboard/bookings"
                render={props => {
                    return (
                        <MyBookings
                            {...props}
                            appointments={appointments}
                            events={events}
                            onUnregisterEvent={(eventId, applyType, date) => unregisterEvent(eventId, applyType, date)}
                            unregisteringEventId={unregisteringEventId}
                            onDeleteAppointment={appointment => deleteAppointment(appointment)}
                            deletingAppointmentId={deletingAppointmentId}
                            onEditAppointment={appointment => editAppointment(appointment)}
                            services={services}
                            selectedService={selectedService}
                            onSelectService={serviceId => selectServiceId(serviceId)}
                            onChangePreset={preset => changeViewPreset(preset)}
                        />
                    );
                }}
            />
            <Route
                exact
                path="/dashboard/bookings/events/:eventId/appointments/:appointmentId/:start/:end/edit"
                render={props => {
                    return (
                        <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                            <ProviderEventAppointment
                                {...props}
                                me={me}
                                eventInfo={eventToEdit}
                                appointmentToEdit={appointmentToEdit}
                                onSave={async appointment => await saveChanges(appointment)}
                                saving={saving}
                                canControlEndTime={false}
                            />
                        </div>
                    );
                }}
            />
        </Switch>
    );
};
