import React, {useCallback, useContext} from "react";
import {ConfigProvider} from "antd";
import "./happy-hours-location-filter-popover.css";
import {SegmentedOptions} from "../../../../../components/SegmentedGroup/index.jsx";
import {MarketplaceFilterProviderContext} from "../../../MarketplaceFilterContext.jsx";
import {happyHoursSegmentedOptions} from "./options.jsx";
import {deliveryAddressOptions} from "../consts.js";
import classnames from "classnames";
import {BasePopoverFilter} from "../../basePopoverFilter.jsx";
import {useLocationFilter} from "../locationFilterHooks.jsx";

export const HappyHoursLocationFilter = ({children}) => {
    const {innerState, updateInnerState, panel} = useContext(MarketplaceFilterProviderContext);
    const {updateAddressTitle, onClearAddress, didStateChange} = useLocationFilter();

    const onChangeSegmentedOption = useCallback(value => {
        updateInnerState("locationFilter.currentTab", value);
    }, []);

    const onOpenPopover = useCallback(() => {
        if (!innerState?.locationFilter?.currentTab) {
            onChangeSegmentedOption(deliveryAddressOptions.deliveryToAddress);
        }
    }, [innerState?.locationFilter?.currentTab]);

    const onClear = useCallback(() => {
        onClearAddress();
        onChangeSegmentedOption(deliveryAddressOptions.deliveryToAddress);
    }, []);

    return (
        <>
            <ConfigProvider direction="rtl">
                <BasePopoverFilter
                    searchOnClose={didStateChange}
                    onClear={onClear}
                    onOpenPopover={onOpenPopover}
                    onClosePopover={updateAddressTitle}
                    className={classnames("happy-hours-location-filter-popover", panel)}
                    title="לאן לשלוח"
                    content={
                        <div id="happy-hour-location-options">
                            <SegmentedOptions
                                value={innerState?.locationFilter?.currentTab}
                                options={happyHoursSegmentedOptions()}
                                onChange={onChangeSegmentedOption}
                            />
                        </div>
                    }>
                    {children}
                </BasePopoverFilter>
            </ConfigProvider>
        </>
    );
};
