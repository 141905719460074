import React from "react";
import {EventInfoTitle} from "../event/EventInfoTitle";
import {toDateString} from "../event/EventInfoModal";
import {PageSubTitleLabel} from "../components/PageTitle";
import {Divider} from "antd";
import {StyledButton} from "../components/StyledButton";

export const EmployeeBookingSummary = ({
    me,
    event,
    currentStart,
    currentEnd,
    onBack,
    selectedTime,
    confirming,
    onConfirm,
    selectedProduct,
    providerInfo
}) => (
    <div
        style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 15
        }}>
        <div
            style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}>
            <EventInfoTitle me={me} event={event} currentStart={currentStart} currentEnd={currentEnd} onBack={onBack} />
            {providerInfo && providerInfo.transactionTerminal && providerInfo.transactionTerminal.enabled ? (
                <PageSubTitleLabel>Here is your booking summary</PageSubTitleLabel>
            ) : (
                <PageSubTitleLabel>
                    {event.whoPays === "Employees pay"
                        ? "Pay directly to professional after the appointment"
                        : "This appointment is pre-paid. Enjoy."}
                </PageSubTitleLabel>
            )}
            <div
                style={{
                    width: "100%",
                    maxWidth: 460,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: 20,
                    marginBottom: 20,
                    justifyContent: "center",
                    border: "1px solid #E8EDF5",
                    backgroundColor: "#FCFDFE",
                    borderRadius: 10
                }}>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: 50,
                        fontSize: 16,
                        padding: "4px 15px",
                        borderBottom: "1px solid #E8EDF5"
                    }}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <span style={{fontWeight: 700, display: "flex", alignItems: "center", textAlign: "left"}}>
                            {selectedProduct.productName}
                        </span>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <Divider
                                type="vertical"
                                style={{height: 15, marginTop: 3, backgroundColor: "rgba(0,0,0,0.65)", width: 1}}
                            />
                            <span style={{display: "flex", alignItems: "center", width: 60}}>
                                {selectedProduct.durationMinutes} min
                            </span>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            color: "#00AB79",
                            minWidth: 50
                        }}>
                        ₪{selectedProduct.price}
                    </div>
                </div>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: 50,
                        fontSize: 16,
                        padding: "4px 15px",
                        borderBottom: "1px solid #E8EDF5"
                    }}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <span style={{display: "flex", alignItems: "center"}}>{toDateString(event, currentStart)}</span>
                        <Divider
                            type="vertical"
                            style={{height: 15, marginTop: 3, backgroundColor: "rgba(0,0,0,0.65)", width: 1}}
                        />
                        <span style={{display: "flex", alignItems: "center"}}>
                            {`${selectedTime.start.format("HH:mm")} - ${selectedTime.end.format("HH:mm")}`}
                        </span>
                    </div>
                </div>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: 50,
                        fontSize: 16,
                        padding: "4px 15px"
                    }}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <span style={{display: "flex", alignItems: "center"}}>{event.address}</span>
                    </div>
                </div>
            </div>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
                <StyledButton
                    loading={confirming}
                    onClick={onConfirm}
                    style={{
                        borderRadius: 25,
                        width: "100%",
                        maxWidth: 400,
                        textAlign: "center",
                        backgroundColor: "#13C296",
                        height: 50,
                        color: "white"
                    }}>
                    Confirm
                </StyledButton>
            </div>
        </div>
    </div>
);
