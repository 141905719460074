import {parse as qsParse} from "query-string";
import {Link} from "react-router-dom";
import React from "react";

const CONTACT_ROUTE = "/contact";

export const extractContactUsLink = company => {
    const {branchId} = qsParse(location.search);

    if (branchId && company) {
        const companyBranch = company.branches.find(b => b.branchId === branchId);
        if (companyBranch && companyBranch.helpLink) {
            return companyBranch.helpLink;
        }
    }

    return "/contact";
};

export const ContactUsLink = ({company, children}) => {
    const helpLink = extractContactUsLink(company);
    if (helpLink === CONTACT_ROUTE) {
        return <Link to={helpLink}>{children}</Link>;
    } else {
        return (
            <a href={helpLink} target="_blank">
                {children}
            </a>
        );
    }
};
