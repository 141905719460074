import React, {useReducer, useEffect, useState} from "react";
import {ProviderProfileCard} from "./ProviderProfileCard";
import PageHeaderTitle from "../components/PageTitle";
import {HttpClient} from "../http/HttpClient";
import {Empty, Spin, Select, Input} from "antd";
import {EventBus} from "../bus/EventBus";
import {ProviderContentCard} from "./ProviderContentCard";
import {shuffle} from "lodash";
import {languages} from "../data/languages";
import {SearchableSelect} from "../components/SearchableSelect";
import {locationKeyToTextMap, LocationLabels} from "../data/locations";
import {FormInput} from "../components/form";
// import LRU from "lru-cache";

const cache = null;

const {Search} = Input;
const {Option} = Select;

const PriceRangeSelect = ({onSelect}) => {
    const [priceRange, setPriceRange] = useState({min: 0, max: Number.MAX_VALUE});

    const onChange = (min, max) => {
        min = min === "" || isNaN(min) ? 0 : parseFloat(min);
        max = max === "" || isNaN(max) ? Number.MAX_VALUE : parseFloat(max);
        onSelect && onSelect({min, max});
        setPriceRange({min, max});
    };

    return (
        <div style={{display: "flex", alignItems: "center"}}>
            <FormInput
                onChange={({target}) => onChange(target.value, priceRange.max)}
                type="number"
                placeholder="MIN PRICE"
                style={{
                    borderTopLeftRadius: 25,
                    borderBottomLeftRadius: 25,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    width: 135,
                    textAlign: "center"
                }}
            />
            <div
                style={{
                    borderTop: "1px solid #E8EDF5",
                    borderBottom: "1px solid #E8EDF5",
                    backgroundColor: "#FCFDFE",
                    fontSize: 16,
                    height: 50,
                    width: 70,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "var(--secondary-color)"
                }}>
                TO
            </div>
            <FormInput
                onChange={({target}) => onChange(priceRange.min, target.value)}
                type="number"
                placeholder="MAX PRICE"
                style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderTopRightRadius: 25,
                    borderBottomRightRadius: 25,
                    width: 135,
                    textAlign: "center"
                }}
            />
        </div>
    );
};

const LanguageSelect = ({onSelect}) => (
    <SearchableSelect
        className="wb-rounded-select"
        style={{borderRadius: 25, width: 165}}
        placeholder="Language"
        onSelect={onSelect}>
        {[{englishName: "All", symbol: "all"}].concat(languages).map(({englishName, symbol}) => (
            <Option key={symbol} value={symbol}>
                {englishName}
            </Option>
        ))}
    </SearchableSelect>
);

const LocationSelect = ({onSelect}) => (
    <Select
        className="wb-rounded-select"
        style={{borderRadius: 25, marginRight: 10, width: 165}}
        placeholder="Medium"
        onSelect={onSelect}>
        {Object.keys(LocationLabels).map(key => {
            const location = LocationLabels[key];
            return (
                <Option key={location} value={location}>
                    {locationKeyToTextMap[location]}
                </Option>
            );
        })}
    </Select>
);

export const Providers = ({history, match}) => {
    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        term: "",
        providers: null,
        service: null,
        contentCards: false,
        contents: null,
        priceRange: {min: 0, max: Number.MAX_VALUE},
        location: null,
        language: null
    });

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const {serviceId} = match.params;
                if (cache.has(serviceId)) {
                    const cachedState = cache.get(serviceId);
                    setState(cachedState);
                } else {
                    const [service, providers] = await Promise.all([
                        HttpClient.get(`/api/services/${serviceId}`),
                        HttpClient.post(`/api/services/${serviceId}/providers`, {onlyV1: true})
                    ]);
                    const fetchedState = {
                        providers: sortProvidersByProfileCompleteness(providers),
                        service,
                        contentCards: service.serviceType === "content",
                        contents:
                            service.serviceType === "content" ? shuffle(buildContentsFromProviders(providers)) : null
                    };
                    cache.set(serviceId, fetchedState);
                    setState(fetchedState);
                }
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {
                        content: {
                            description: "Unfortunately we didn't manage to preset all our amazing professionals :("
                        }
                    },
                    e.message
                );
            }
        });
    }, []);

    const sortProvidersByProfileCompleteness = providers => {
        return providers.sort((p1, p2) => {
            const score1 = calculateScore(p1);
            const score2 = calculateScore(p2);
            return score1 > score2 ? -1 : 1;
        });
    };

    const calculateScore = provider => {
        const VIDEO_SCORE = 10;
        const PORTFOLIO_SCORE = 10;
        let score = 0;

        score += provider.video && provider.video.url ? (provider.providerId === "S9YLxr9ic" ? 1 : VIDEO_SCORE) : 0;
        score += Array.isArray(provider.portfolioImages) && provider.portfolioImages.length > 0 ? PORTFOLIO_SCORE : 0;

        return score;
    };

    const enter = providerId => {
        const {serviceId} = match.params;
        history.push(`/dashboard/services/${serviceId}/providers/${providerId}`);
    };

    const onSearchTermChange = term => {
        setState({term: term.toLowerCase()});
    };

    const buildContentsFromProviders = providers => {
        return providers
            ? providers.reduce((products, provider) => {
                  return products.concat(
                      provider.services
                          .filter(product =>
                              product.service
                                  ? product.service.serviceId === serviceId
                                  : product.services.some(service => service.serviceId === serviceId)
                          )
                          .map(product => ({product, provider}))
                  );
              }, [])
            : [];
    };

    const filterContentsBySearchTerm = contents => {
        return contents.filter(({product: {productName, description}, provider: {firstName, lastName}}) => {
            return (
                productName.toLowerCase().indexOf(term.toLowerCase()) >= 0 ||
                `${firstName} ${lastName || ""}`.toLowerCase().indexOf(term.toLowerCase()) >= 0 ||
                (description || "").toLowerCase().indexOf(term.toLowerCase()) >= 0
            );
        });
    };

    const filterContentsByPrice = contents => {
        const {priceRange} = state;
        return contents.filter(({product}) => product.price >= priceRange.min && product.price <= priceRange.max);
    };

    const filterContentsByMedium = contents => {
        const {location} = state;
        if (!location) {
            return contents;
        }

        return contents.filter(({product}) => {
            return location === LocationLabels.ONSITE_AND_ONLINE
                ? [LocationLabels.ONLINE, LocationLabels.ONSITE].some(l => product.location === l)
                : product.location === location;
        });
    };

    const filterContentsByLanguage = contents => {
        const {language} = state;
        if (!language || language === "all") {
            return contents;
        }

        return contents.filter(({product}) => {
            return Array.isArray(product.languages) && product.languages.some(lang => lang === language);
        });
    };

    const filterProvidersBySearchTerm = providers => {
        return (providers || []).filter(({firstName, lastName}) => {
            return `${firstName} ${lastName || ""}`.toLowerCase().indexOf(term.toLowerCase()) >= 0;
        });
    };

    const filterProvidersByPrice = providers => {
        const {priceRange} = state;
        return providers.filter(({services}) => {
            return (
                Array.isArray(services) &&
                services.some(product => {
                    return product.price >= priceRange.min && product.price <= priceRange.max;
                })
            );
        });
    };

    const filterProvidersByMedium = providers => {
        const {location} = state;
        if (!location) {
            return providers;
        }

        return providers.filter(({services}) => {
            return (
                Array.isArray(services) &&
                services.some(product => {
                    return location === LocationLabels.ONSITE_AND_ONLINE
                        ? [LocationLabels.ONLINE, LocationLabels.ONSITE].some(l => product.location === l)
                        : product.location === location;
                })
            );
        });
    };

    const filterProvidersByLanguage = providers => {
        const {language} = state;
        if (!language || language === "all") {
            return providers;
        }

        return providers.filter(({services}) => {
            return (
                Array.isArray(services) &&
                services.some(product => {
                    return Array.isArray(product.languages) && product.languages.some(lang => lang === language);
                })
            );
        });
    };

    const {serviceId} = match.params;
    let {providers, service, term, contentCards, contents} = state;

    if (contentCards) {
        contents = filterContentsByMedium(
            filterContentsByPrice(filterContentsByLanguage(filterContentsBySearchTerm(contents)))
        );
    } else {
        providers = filterProvidersByMedium(
            filterProvidersByPrice(filterProvidersByLanguage(filterProvidersBySearchTerm(providers)))
        );
    }

    return (
        <div style={{display: "flex", alignItems: "center", flexDirection: "column", marginBottom: 80}}>
            <PageHeaderTitle>{service ? service.categoryName : ""}</PageHeaderTitle>
            <div className="wb-providers-container">
                <Search
                    className="wb-library-search-box"
                    style={{width: 340, marginTop: 10, marginBottom: 10}}
                    onChange={evt => onSearchTermChange(evt.target.value)}
                    placeholder={contentCards ? "Search content" : "Search professional"}
                />
                <div
                    className="wb-library-top-filters"
                    style={{display: "flex", flexWrap: "wrap", width: 700, justifyContent: "space-between"}}>
                    <PriceRangeSelect onSelect={priceRange => setState({priceRange})} />
                    <div style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                        <LocationSelect onSelect={location => setState({location})} />
                        <LanguageSelect onSelect={language => setState({language})} />
                    </div>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", flex: 1, maxWidth: 1200}}>
                <div className="providers" style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                    {providers === null ? (
                        <Spin style={{marginTop: 80}} size="large" />
                    ) : (contentCards ? contents.length === 0 : providers.length === 0) ? (
                        <Empty
                            description={contentCards ? "No such content" : "No such professional"}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{marginTop: 80}}
                        />
                    ) : contentCards ? (
                        contents.map(({product, provider}, idx) => (
                            <ProviderContentCard
                                key={`provider-card-${idx}`}
                                history={history}
                                product={product}
                                provider={provider}
                                service={service}
                            />
                        ))
                    ) : (
                        providers.map((provider, idx) => (
                            <ProviderProfileCard
                                key={`provider-card-${idx}`}
                                serviceId={serviceId}
                                provider={provider}
                                onEnter={provider => enter(provider.providerId)}
                            />
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};
