import moment from "moment-timezone";
import "moment/locale/he";
import hebMoment from "moment/min/moment-with-locales";

moment.locale("en-us");

export const dateFormat = dateObj => {
    if (typeof dateObj === "string") return dateObj;
    return `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;
};

export const timeFormat = dateObj => {
    return `${dateObj.getHours()}:${dateObj.getMinutes()}:${dateObj.getSeconds()}`;
};

export const toMomentDate = dateObj => {
    return moment(dateFormat(dateObj), "YYYY-M-D");
};

export const getHebrewMonthName = month => {
    const hebrewName = hebMoment(month);
    hebrewName.locale("he");
    return hebrewName.format("MMMM");
};

export const momentHebrewFormat = (date, format) => {
    const hebrewName = hebMoment(date);
    hebrewName.locale("he");
    return hebrewName.format(format);
};
