import React, {useCallback, useEffect, useState} from "react";
import {ConfigProvider, message, Tabs} from "antd";
import "./admin-search-tags.css";
import {DraggableBodyRow, DraggableTable} from "../../components/dragableTable.jsx";
import {RoundedTable} from "../../components/index.jsx";
import adminSearchTagsTableColumns from "./columns.jsx";
import {ServiceIdToName} from "../../consts.js";
import {cloneDeep} from "lodash";
import {HttpClient} from "../../http/HttpClient.jsx";
import {useRequest} from "../../utils/hooks.jsx";
import {categoryTabs, noCategoryOption} from "./consts.js";

export const AdminSearchTagsTable = ({searchTags, onSaveTag}) => {
    const [selectedTab, setSelectedTab] = useState(categoryTabs[0]);
    const [tagsInCategory, setTagsInCategory] = useState([]);
    const [categorySearchTags, loadingCategoryTags, errorCategorySearchTags] = useRequest(
        `/api/categorySearchTag/byCategoryId/${selectedTab ?? categoryTabs[0]}`,
        "GET",
        null,
        [selectedTab, searchTags],
        selectedTab !== noCategoryOption.key
    );

    useEffect(() => {
        if (selectedTab === noCategoryOption.key) {
            const noCategoryTags = searchTags.filter(searchTag => !searchTag?.category?.length);
            setTagsInCategory([...noCategoryTags]);
        }
    }, [selectedTab, searchTags]);

    useEffect(() => {
        if (errorCategorySearchTags) {
            setTagsInCategory([]);
        }
    }, [errorCategorySearchTags]);

    useEffect(() => {
        if (categorySearchTags) {
            setTagsInCategory([...categorySearchTags.tagIds]);
        }
    }, [categorySearchTags]);

    const saveSearchTagOrder = useCallback(
        async tags => {
            if (selectedTab === noCategoryOption.key) {
                return;
            }
            const tagsIds = tags.map(searchTag => searchTag.tagId);
            const response = await HttpClient.safePost("/admin/api/categorySearchTag/orderTagIds", {
                categoryId: [selectedTab],
                tagIds: tagsIds
            });
            if (response.error) {
                message.error("סידור תגיות מחדש לא הצליח, אנא נסה שוב");
                return;
            }
            message.success("סידור תגיות הצליח");
        },
        [selectedTab]
    );

    const onSortEnd = useCallback(
        ({oldIndex, newIndex}) => {
            setTagsInCategory(prevCarousel => {
                const newOrder = cloneDeep(prevCarousel);
                const [movedItem] = newOrder.splice(oldIndex, 1);
                newOrder.splice(newIndex, 0, movedItem);
                saveSearchTagOrder(newOrder);
                return newOrder;
            });
        },
        [selectedTab]
    );

    const onChangeActive = useCallback(
        (tag, isActive) => {
            const newTag = {...tag, isActive: isActive};
            setTagsInCategory(tagsInCategory => {
                const indexTag = tagsInCategory.findIndex(tagInCategory => tagInCategory.tagId === tag.tagId);
                tagsInCategory[indexTag] = newTag;
                return [...tagsInCategory];
            });
            onSaveTag(newTag);
        },
        [onSaveTag]
    );

    const onTabChange = useCallback(currentTab => {
        setSelectedTab(currentTab);
    }, []);

    return (
        <div className="admin-search-tags-table">
            <ConfigProvider direction="rtl">
                <Tabs
                    onChange={onTabChange}
                    items={categoryTabs.map(categoryName => ({
                        label: ServiceIdToName[categoryName] ?? noCategoryOption.label,
                        key: categoryName
                    }))}
                />
                <RoundedTable
                    pagination={false}
                    rowKey="name"
                    dataSource={tagsInCategory}
                    loading={loadingCategoryTags}
                    columns={adminSearchTagsTableColumns(selectedTab !== noCategoryOption.key, onChangeActive)}
                    components={{
                        body: {
                            wrapper: props => <DraggableTable onSortEnd={onSortEnd} {...props} />,
                            row: props => <DraggableBodyRow dataSource={tagsInCategory} rowKey="name" {...props} />
                        }
                    }}
                />
            </ConfigProvider>
        </div>
    );
};
