import {Modal} from "antd";
import {CloseIcon} from "../../../icons";
import React, {useContext} from "react";
import {FrequencyMapper} from "../../FrequencyMapper";
import {EventCalendarSyncActions} from "./EventCalendarSyncActions";
import EventBookingConfirmationView from "../EventBookingConfirmationView";
import {AppContext} from "../../../AppContext";
import {SlackAuthButton} from "../../../company/integrations/slack/SlackAuthButton";
import moment from "moment-timezone";

export const EventCalendarSyncModal = ({event, applyType, dateMom, visible, onClose}) => {
    const {me, company} = useContext(AppContext);

    const closeMe = () => {
        onClose();
    };

    return (
        <Modal
            className="wb-modal-booking-confirmation"
            centered={true}
            closable={true}
            bodyStyle={{padding: 0}}
            closeIcon={<CloseIcon fill="white" />}
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            footer={null}
            title={null}>
            <EventBookingConfirmationView
                serviceId={event.customSession === true ? null : event.serviceId}
                subtitle={
                    applyType === "all"
                        ? FrequencyMapper.toHumanText(event)
                        : moment(event.dtstart).format("dddd, MMMM Do, YYYY [at] HH:mm")
                }
                useIcal={company.disabledFeatures?.includes("sync")}>
                <EventCalendarSyncActions
                    onClose={closeMe}
                    eventId={event.eventId}
                    dateMom={dateMom}
                    applyType={"only"}
                    style={{marginBottom: 20}}
                    event={event}
                />
                {!event.appointmentBased ? (
                    <SlackAuthButton
                        path={`/api/slack/employee/auth?eventId=${event.eventId}&date=${dateMom.format(
                            "YYYY-MM-DD"
                        )}&applyType=${applyType}`}
                        style={{marginTop: 10, maxWidth: 250}}
                        description="You will get a notification 15 minutes before the event starts.">
                        Remind me with Slack
                    </SlackAuthButton>
                ) : null}
            </EventBookingConfirmationView>
        </Modal>
    );
};
