import React, {useContext, useEffect, useState} from "react";
import {EXCenterContext} from "../EXCenterContext";
import {Badge, Calendar, message} from "antd";
import {RRuleSetBuilder} from "../../event/RRuleSetBuilder";
import moment from "moment-timezone";
import {sortBy} from "lodash";
import {eventByDate} from "../../event/EventByDate";
import {EXCenterMobileCalendarItem} from "./EXCenterMobileCalendarItem";
import {EXCenterMobileCalendarHeader} from "./EXCenterMobileCalendarHeader";
import {HttpClient} from "../../http/HttpClient";
import {LocalEventsUpdater} from "../../event/LocalEventsUpdater";
import {toEventText} from "../utils/EventUtil";
import {PageLoader} from "../../components/PageLoader";
import {amRegistered} from "../../employee/EmployeeBookings";
import {AppContext} from "../../AppContext";

const getInstantOpenDate = () => {
    const instantEventOpenStr = sessionStorage.getItem("instantEventOpen");
    const instantEventOpen = instantEventOpenStr ? JSON.parse(instantEventOpenStr) : null;
    if (instantEventOpen) {
        return moment(instantEventOpen.date, "YYYY-MM-DD");
    } else {
        return moment();
    }
};

const shouldInstantOpen = (event, date) => {
    let instantOpen = false;

    const dateStr = moment(date).format("YYYY-MM-DD");
    const instantEventOpenStr = sessionStorage.getItem("instantEventOpen");
    const instantEventOpen = instantEventOpenStr ? JSON.parse(instantEventOpenStr) : null;

    if (instantEventOpen && dateStr === instantEventOpen.date && event.eventId === instantEventOpen.eventId) {
        instantOpen = true;
        sessionStorage.removeItem("instantEventOpen");
    }

    return instantOpen;
};

export const EXCenterMobileCalendar = ({selectedCategory, searchTerm, isSmallScreen}) => {
    const instantOpenDate = getInstantOpenDate();
    const {me} = useContext(AppContext);
    const {theme} = useContext(EXCenterContext);
    const [events, setEvents] = useState(null);
    const [selectedDateMom, setSelectedDateMom] = useState(instantOpenDate);
    const [itemsPerDate, setItemsPerDate] = useState(null);
    const [selectedItems, setSelectedItems] = useState(null);
    const [category, setCategory] = useState(selectedCategory || null);
    const [term, setTerm] = useState(searchTerm || null);

    useEffect(() => {
        Promise.resolve().then(fetchEvents);
    }, []);

    const fetchEvents = async () => {
        try {
            let events = await HttpClient.get("/api/events");
            events = events.filter(evt => evt.sessionType !== "special");
            const filteredEvents = isSmallScreen ? events.filter(event => amRegistered(event, me)) : events;
            setEvents(filteredEvents);
            buildItemsPerDate(filterEventsByCategoryAndSearchTerm(filteredEvents, category, term));
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
    };

    const buildItemsPerDate = events => {
        const eventsPerDate = {};
        events.forEach(evt => {
            const rruleSet = RRuleSetBuilder.build(evt);
            const dates = rruleSet.between(
                moment().subtract(4, "months").toDate(),
                moment().add(8, "months").toDate(),
                true
            );
            (dates || []).forEach(date => {
                const dateStr = moment(date).format("YYYY-MM-DD");
                eventsPerDate[dateStr] = eventsPerDate[dateStr] || [];
                eventsPerDate[dateStr].push({
                    event: evt,
                    date
                });
            });
        });
        setItemsPerDate(eventsPerDate);
        setSelectedItems(eventsPerDate[selectedDateMom.format("YYYY-MM-DD")] || null);
    };

    const dateCellRender = dateMom => {
        const dateStr = dateMom.format("YYYY-MM-DD");
        let items = itemsPerDate ? itemsPerDate[dateStr] : null;
        if (items) {
            items = sortBy(items, i => i.date);
        }

        return (
            <div
                className="wb-center-calendar-badge"
                style={{display: "flex", justifyContent: "center", width: "100%"}}>
                <Badge color={items ? items[0].event.backgroundColor || theme.primaryColor : "white"} />
            </div>
        );
    };

    const onSelect = dateMom => {
        setSelectedDateMom(dateMom);
        setSelectedItems(itemsPerDate[dateMom.format("YYYY-MM-DD")] || null);
    };

    const onEventUpdate = updatedEvt => {
        let originalEvent = null;
        if (updatedEvt.newEvent) {
            originalEvent = updatedEvt.originalEvent;
            updatedEvt = updatedEvt.newEvent;
        }

        const updatedEvents = LocalEventsUpdater.massUpdate(events, updatedEvt, originalEvent);

        setEvents(updatedEvents);
        buildItemsPerDate(filterEventsByCategoryAndSearchTerm(events, category, term));
    };

    const filterEventsByCategoryAndSearchTerm = (events, category, term) => {
        if (category) {
            events = events.filter(
                evt => evt.serviceName === category || (!evt.serviceName && category === "Internal")
            );
        }

        if (term) {
            events = events.filter(evt => {
                const text = toEventText(evt);
                return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
            });
        }

        return events;
    };

    if (events === null) {
        return <PageLoader height={300} />;
    }

    if (category !== selectedCategory || term !== searchTerm) {
        buildItemsPerDate(filterEventsByCategoryAndSearchTerm(events, selectedCategory, searchTerm));
        setCategory(selectedCategory);
        setTerm(searchTerm);
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                margin: "10px 30px"
            }}>
            <div className="wb-center-mobile-calendar">
                <Calendar
                    fullscreen={false}
                    headerRender={EXCenterMobileCalendarHeader}
                    dateCellRender={dateCellRender}
                    onChange={onSelect}
                />
            </div>
            {selectedItems ? (
                <div style={{display: "flex", flexDirection: "column", marginTop: 20}}>
                    {sortBy(selectedItems, i => i.date).map(({event, date}) => {
                        const eventToDate = eventByDate(event, moment(date).tz(event.tzid).format("YYYY-M-D"));
                        return (
                            <EXCenterMobileCalendarItem
                                event={eventToDate}
                                date={date}
                                onEventUpdate={onEventUpdate}
                                instantOpen={shouldInstantOpen(event, date)}
                            />
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
};
