import React, {useState} from "react";
import {Modal, Upload, Empty, message} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import {YouTubeLinkGenerator} from "../youtube/YouTubeLinkGenerator";
import {UploadClient} from "../http/UploadClient";
import {StyledButton} from "../components/StyledButton";

const FILE_SIZE_MB = 1024 * 1024;
const MAX_VIDEO_SIZE = 100 * FILE_SIZE_MB;

export const VideoStatus = {
    Initial: "initial",
    Uploading: "uploading",
    Ready: "ready"
};

const isYouTubeVideo = videoUrl => {
    return videoUrl.indexOf("youtu.be") >= 0 || videoUrl.indexOf("youtube.com") >= 0;
};

const uploadVideoAsync = (file, onSuccess, onError) => {
    Promise.resolve().then(async () => {
        try {
            const formData = new FormData();
            formData.append("file", file);
            await UploadClient.upload("POST", "/api/providers/me/video", formData);
            onSuccess();
        } catch (e) {
            onError && onError(e);
        }
    });
};

const uploadProps = (onSuccess, onError, onUpload) => ({
    name: "file",
    action: "/api/providers/me/video",
    showUploadList: false,
    withCredentials: true,
    accept: "video/mpeg,video/mp4,video/quicktime",
    beforeUpload: _.throttle(
        file => {
            if (file.size > MAX_VIDEO_SIZE) {
                message.error(
                    `Maximum video size is ${MAX_VIDEO_SIZE / FILE_SIZE_MB}MB, your video is ${
                        (file.size / FILE_SIZE_MB) | 0
                    }MB.`,
                    7
                );
            } else {
                onUpload();
                uploadVideoAsync(file, onSuccess, onError);
            }
            return false;
        },
        100,
        {trailing: false, leading: true}
    )
});

export const UploadNewVideo = ({onSuccess, onError, onUpload, style, children, icon, loading}) => (
    <Upload {...uploadProps(onSuccess, onError, onUpload)}>
        <StyledButton
            loading={loading}
            icon={icon}
            style={{
                borderRadius: 25,
                minWidth: 160,
                textAlign: "center",
                backgroundColor: "#2F3E83",
                color: "white",
                border: "1px solid #2F3E83",
                ...style
            }}>
            {children || "Upload new video"}
        </StyledButton>
    </Upload>
);

const modalBody = (uploadable, status, videoUrl, onUpload, onSuccess, onError) => {
    const VideoPlayer = () => (
        <video
            style={{outline: "none", borderRadius: 10, width: "100%", height: "100%", maxHeight: 450}}
            src={isYouTubeVideo(videoUrl || "") ? YouTubeLinkGenerator.embedFromWatchLink(videoUrl) : videoUrl}
            controls={true}
            autoPlay={true}
        />
    );

    if (!uploadable || status === VideoStatus.Ready) {
        return <VideoPlayer />;
    }

    switch (status) {
        case VideoStatus.Uploading:
            return (
                <Empty
                    style={{marginTop: -60}}
                    imageStyle={{
                        height: 180,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                    image={
                        <div className="sk-chase">
                            <div className="sk-chase-dot" />
                            <div className="sk-chase-dot" />
                            <div className="sk-chase-dot" />
                            <div className="sk-chase-dot" />
                            <div className="sk-chase-dot" />
                            <div className="sk-chase-dot" />
                        </div>
                    }
                    description="We are processing your video..."
                />
            );
        default:
            return (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No video demo.">
                    <UploadNewVideo onUpload={onUpload} onSuccess={onSuccess} onError={onError} />
                </Empty>
            );
    }
};

export const ProviderVideoModal = ({
    visible,
    onClose,
    status,
    videoUrl,
    uploadable,
    onUpload,
    onSuccess,
    onError,
    onDelete
}) => {
    const [deleting, setDeleting] = useState(false);

    const deleteVideo = async () => {
        setDeleting(true);
        await onDelete();
        setDeleting(false);
        onClose();
    };

    return (
        <div onClick={e => e.stopPropagation()}>
            <Modal
                destroyOnClose={true}
                className="wb-video-modal"
                closable={true}
                onCancel={() => onClose()}
                visible={visible}
                footer={
                    uploadable && status === VideoStatus.Ready ? (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <StyledButton
                                loading={deleting}
                                onClick={() => deleteVideo()}
                                style={{
                                    borderRadius: 25,
                                    minWidth: 160,
                                    textAlign: "center",
                                    marginRight: 10,
                                    backgroundColor: "white !important",
                                    color: "#F06060",
                                    border: "1px solid #F06060"
                                }}>
                                Delete video
                            </StyledButton>
                            <UploadNewVideo onUpload={onUpload} onSuccess={onSuccess} onError={onError} />
                        </div>
                    ) : null
                }
                title={null}
                closeIcon={<CloseOutlined style={{color: "#637381"}} />}>
                <div
                    style={{
                        position: "relative",
                        height: "56.25%",
                        overflow: "hidden",
                        borderRadius: 10,
                        marginTop: 30,
                        marginBottom: 30,
                        display: "flex",
                        justifyContent: "center"
                    }}>
                    {visible ? modalBody(uploadable, status, videoUrl, onUpload, onSuccess, onError) : null}
                </div>
            </Modal>
        </div>
    );
};
