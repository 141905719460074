import React, {useContext, useEffect, useState} from "react";
import PageTitleHeader, {PageSubTitleLabel} from "../components/PageTitle";
import {Button, message, Spin, Tooltip, Upload} from "antd";
import {truncate} from "lodash";
import {BuildingIcon, CreditCardIcon, DownloadIcon, EventAcceptedIcon, UploadIcon} from "../icons";
import {InfoCircleOutlined} from "@ant-design/icons";
import {EventBus} from "../bus/EventBus";
import {HttpClient} from "../http/HttpClient";
import moment from "moment";
import {AppContext} from "../AppContext";
import {BalanceNavigation} from "../billing/BalanceNavigation";
import {BalanceInfoCard} from "../billing/BalanceInfoCard";
import {PaymentPlan} from "./ProviderPaymentSelection";
import {StyledButton} from "../components/StyledButton";
import {UploadClient} from "../http/UploadClient";
import {PageLoader} from "../components/PageLoader";

const paymentPlanText = paymentPlan => {
    return `₪${paymentPlan.replace("NIS", " Subscription")}`;
};

const isAppointmentBasedBilling = billingInfo => {
    return billingInfo.subscription.paymentPlan === PaymentPlan.PER_ACTIVE_DAY;
};

const FILE_SIZE_MB = 1024 * 1024;
const MAX_INVOICE_SIZE = 25 * FILE_SIZE_MB;

const generateUploadProps = (date, onSuccess, onError, onUpload) => ({
    name: "file",
    showUploadList: false,
    withCredentials: true,
    accept: "application/pdf,image/png,image/jpeg",
    beforeUpload: _.throttle(
        file => {
            if (file.size > MAX_INVOICE_SIZE) {
                message.error(
                    `Maximum video size is ${MAX_INVOICE_SIZE / FILE_SIZE_MB}MB, your video is ${
                        (file.size / FILE_SIZE_MB) | 0
                    }MB.`,
                    7
                );
            } else {
                onUpload();
                uploadFileAsync(date, file, onSuccess, onError);
            }
            return false;
        },
        100,
        {trailing: false, leading: true}
    )
});

const uploadFileAsync = (date, file, onSuccess, onError) => {
    Promise.resolve().then(async () => {
        try {
            const formData = new FormData();
            formData.append("file", file);
            const invoice = await UploadClient.upload("POST", `/api/billing/${date}/invoice`, formData);
            onSuccess(invoice);
        } catch (e) {
            onError && onError(e);
        }
    });
};

const TransactionsTable = ({billingInfo, loading, nextMonth, nextYear}) => {
    const transactions = billingInfo.transactions;
    const table = transactions.reduce(
        (info, transaction) => {
            info.serviceNames.push(transaction.serviceName);
            info.companyNames.push(transaction.companyName);
            info.sessionCounts.push(transaction.sessionsCount);
            if (isAppointmentBasedBilling(billingInfo)) {
                info.paymentTypes.push(transaction.paymentType);
            }
            info.totals.push(`₪${transaction.total.toLocaleString()}`);

            return info;
        },
        {
            serviceNames: [],
            companyNames: [],
            sessionCounts: [],
            paymentTypes: [],
            totals: []
        }
    );

    if (loading)
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#FCFDFE",
                    border: "1px solid #E8EDF5",
                    borderRadius: 10,
                    marginTop: 30,
                    width: "100%"
                }}>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        minHeight: 400,
                        borderBottom: "1px solid rgba(131,152,166,0.2)"
                    }}>
                    <PageLoader align="center" top={200} />
                </div>
            </div>
        );

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#FCFDFE",
                border: "1px solid #E8EDF5",
                borderRadius: 10,
                marginTop: 30,
                width: "100%"
            }}>
            <div style={{display: "flex", width: "100%", borderBottom: "1px solid rgba(131,152,166,0.2)"}}>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <div
                        style={{
                            minWidth: 300,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: 20,
                            fontSize: 16,
                            fontWeight: 700,
                            color: "#8398A6",
                            borderBottom: "1px solid rgba(131,152,166,0.2)",
                            height: 80
                        }}>
                        SERVICE
                    </div>
                    {table.serviceNames.map(serviceName => (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                padding: 20,
                                fontSize: 20,
                                fontWeight: 700,
                                color: "var(--secondary-color)",
                                height: 60
                            }}>
                            {serviceName}
                        </div>
                    ))}
                </div>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <div
                        style={{
                            minWidth: 250,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: 20,
                            fontSize: 16,
                            fontWeight: 700,
                            color: "#8398A6",
                            borderBottom: "1px solid rgba(131,152,166,0.2)",
                            height: 80
                        }}>
                        COMPANY
                    </div>
                    {table.companyNames.map(companyName => (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                padding: 20,
                                fontSize: 20,
                                fontWeight: 400,
                                color: "#8398A6",
                                height: 60
                            }}>
                            {truncate(companyName, {separator: " ", length: 25})}
                        </div>
                    ))}
                </div>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <div
                        style={{
                            minWidth: 200,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: 20,
                            fontSize: 16,
                            fontWeight: 700,
                            color: "#8398A6",
                            borderBottom: "1px solid rgba(131,152,166,0.2)",
                            height: 80
                        }}>
                        MONTHLY SESSIONS
                    </div>
                    {table.sessionCounts.map(sessionsCount => (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                padding: 20,
                                fontSize: 20,
                                fontWeight: 400,
                                color: "#8398A6",
                                height: 60
                            }}>
                            {sessionsCount}
                        </div>
                    ))}
                </div>
                {isAppointmentBasedBilling(billingInfo) ? (
                    <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                        <div
                            style={{
                                minWidth: 250,
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                padding: 20,
                                fontSize: 16,
                                fontWeight: 700,
                                color: "#8398A6",
                                borderBottom: "1px solid rgba(131,152,166,0.2)",
                                height: 80
                            }}>
                            PAYMENT
                        </div>
                        {table.paymentTypes.map(paymentType => (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    padding: 20,
                                    fontSize: 20,
                                    fontWeight: 400,
                                    color: "#8398A6",
                                    height: 60
                                }}>
                                {paymentType}
                            </div>
                        ))}
                    </div>
                ) : null}
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <div
                        style={{
                            minWidth: 200,
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            padding: 20,
                            fontSize: 16,
                            fontWeight: 700,
                            color: "#8398A6",
                            borderBottom: "1px solid rgba(131,152,166,0.2)",
                            height: 80
                        }}
                    />
                    {table.totals.map(total => (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                padding: 20,
                                fontSize: 20,
                                fontWeight: 700,
                                color: "var(--secondary-color)",
                                height: 60
                            }}>
                            {total}
                        </div>
                    ))}
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 20,
                    fontSize: 20,
                    fontWeight: 700,
                    color: "#13C296",
                    height: 60,
                    backgroundColor: "rgba(19,194,150,0.1)"
                }}>
                <span style={{display: "flex", alignItems: "center"}}>
                    <span style={{marginRight: 6}}>Total income</span>
                    {isAppointmentBasedBilling(billingInfo) ? null : (
                        <span style={{fontWeight: 400, fontSize: 16, paddingTop: 3}}>
                            Deposit date: {nextMonth} 1st {nextYear}
                        </span>
                    )}
                </span>
                <span>₪{billingInfo.totalIncome.toLocaleString()}</span>
            </div>
        </div>
    );
};

const SubscriptionTable = ({billingInfo, loading, nextMonth, nextYear}) => {
    if (loading)
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#FCFDFE",
                    border: "1px solid #E8EDF5",
                    borderRadius: 10,
                    marginTop: 30,
                    width: "100%"
                }}>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        minHeight: 400,
                        borderBottom: "1px solid rgba(131,152,166,0.2)"
                    }}>
                    <PageLoader align="center" top={200} />
                </div>
            </div>
        );

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#FCFDFE",
                border: "1px solid #E8EDF5",
                borderRadius: 10,
                marginTop: 30,
                width: "100%"
            }}>
            <div style={{display: "flex", width: "100%", borderBottom: "1px solid rgba(131,152,166,0.2)"}}>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <div
                        style={{
                            minWidth: 300,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: 20,
                            fontSize: 16,
                            fontWeight: 700,
                            color: "#8398A6",
                            borderBottom: "1px solid rgba(131,152,166,0.2)",
                            height: 80
                        }}>
                        PLAN
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: 20,
                            fontSize: 20,
                            fontWeight: 700,
                            color: "var(--secondary-color)",
                            height: 60
                        }}>
                        {billingInfo.subscription.override
                            ? "Custom Subscription"
                            : paymentPlanText(billingInfo.subscription.paymentPlan)}
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <div
                        style={{
                            minWidth: 250,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: 20,
                            fontSize: 16,
                            fontWeight: 700,
                            color: "#8398A6",
                            borderBottom: "1px solid rgba(131,152,166,0.2)",
                            height: 80
                        }}>
                        MONTHLY SESSIONS
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: 20,
                            fontSize: 20,
                            fontWeight: 400,
                            color: "#8398A6",
                            height: 60
                        }}>
                        {billingInfo.subscription.sessionsCount}
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <div
                        style={{
                            minWidth: 200,
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            padding: 20,
                            fontSize: 16,
                            fontWeight: 700,
                            color: "#8398A6",
                            borderBottom: "1px solid rgba(131,152,166,0.2)",
                            height: 80
                        }}
                    />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            padding: 20,
                            fontSize: 20,
                            fontWeight: 700,
                            color: "var(--secondary-color)",
                            height: 60
                        }}>
                        ₪{billingInfo.subscription.total.toLocaleString()}
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 20,
                    fontSize: 20,
                    fontWeight: 700,
                    color: "#1260D1",
                    height: 60,
                    backgroundColor: "rgba(18,96,209,0.1)"
                }}>
                <span style={{display: "flex", alignItems: "center"}}>
                    <span style={{marginRight: 6}}>Subscription total</span>
                    <span style={{fontWeight: 400, fontSize: 16, paddingTop: 3}}>
                        Charging date: {nextMonth} 1st {nextYear}
                    </span>
                </span>
                <span>₪{billingInfo.subscription.total.toLocaleString()}</span>
            </div>
        </div>
    );
};

export const ProviderBilling = () => {
    const {me} = useContext(AppContext);
    const [billingInfo, setBillingInfo] = useState(null);
    const [currentDate, setCurrentDate] = useState(moment());
    const [loadingData, setLoadingData] = useState(false);
    const [uploadingInvoice, setUploadingInvoice] = useState(false);

    useEffect(() => {
        fetchBillingInfo(currentDate);
    }, []);

    const changeDate = dateMom => {
        setCurrentDate(dateMom);
        fetchBillingInfo(dateMom);
    };

    const fetchBillingInfo = dateMom => {
        Promise.resolve().then(async () => {
            setLoadingData(true);
            try {
                const date = dateMom.format("YYYY-MM");
                const info = await HttpClient.get(`/api/billing/${date}/provider`);
                setBillingInfo(info);
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we couldn't list your billing information :("}},
                    e.message
                );
            }
            setLoadingData(false);
        });
    };

    const openCreditDetails = () => {
        EventBus.trigger("credit_card_details:open");
    };

    const openBankDetails = () => {
        EventBus.trigger("bank_info_details:open");
    };

    const onInvoiceUploadSuccess = invoice => {
        const date = currentDate.format("YYYY-MM");
        const ownInvoices = billingInfo.ownInvoices || {};
        ownInvoices[date] = invoice;
        setBillingInfo({...billingInfo, ownInvoices});
        setUploadingInvoice(false);
        message.success("We got your invoice, thank you!", 3);
    };

    const onInvoiceUpload = () => {
        setUploadingInvoice(true);
    };

    const onInvoiceUploadError = () => {
        setUploadingInvoice(false);
        message.error("Unfortunately we couldn't upload your invoice, please try again later.", 5);
    };

    if (!billingInfo) return <PageLoader align="center" top={100} />;

    const date = currentDate.format("YYYY-MM");

    const month = currentDate.format("MMMM");
    const year = currentDate.format("YYYY");

    const apptBased = isAppointmentBasedBilling(billingInfo);
    const addMonths = apptBased ? 1 : 2;
    const nextMonth = moment(currentDate).add(addMonths, "months").format("MMMM");
    const nextYear = moment(currentDate).add(addMonths, "months").format("YYYY");

    const UploadInvoiceButton = ({style, children}) => (
        <div style={{display: "flex", alignItems: "center"}}>
            <Tooltip title="Please upload a PDF file or an image.">
                <InfoCircleOutlined style={{fontSize: 25, marginRight: 12, color: style.backgroundColor}} />
            </Tooltip>
            <Upload {...generateUploadProps(date, onInvoiceUploadSuccess, onInvoiceUploadError, onInvoiceUpload)}>
                <StyledButton
                    loading={uploadingInvoice}
                    style={{width: 180, ...style}}
                    icon={<UploadIcon style={{fill: "white", marginRight: 12}} />}>
                    {children}
                </StyledButton>
            </Upload>
        </div>
    );

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: 80,
                marginLeft: 50,
                marginRight: 50,
                minWidth: 1300
            }}>
            <div style={{maxWidth: 1200, minWidth: 1200, width: "100%"}}>
                <PageTitleHeader style={{marginBottom: 0}} subTitleStyle={{marginBottom: 15}} backStyle={{left: 0}}>
                    Manage account & monthly payments
                </PageTitleHeader>
                <PageSubTitleLabel>
                    Your {month} {apptBased ? "payment" : "income"} is ₪
                    {apptBased
                        ? billingInfo.subscription.total.toLocaleString()
                        : billingInfo.totalIncome.toLocaleString()}
                    . {apptBased ? "You will be charged" : "Payment will be deposited"} on {nextMonth} 1st, {nextYear}
                </PageSubTitleLabel>
                <div style={{width: "100%", display: "flex", flexDirection: "column", marginTop: 40}}>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <span style={{display: "flex", alignItems: "center", color: "#243446", fontSize: 28}}>
                            {month} {year}
                        </span>
                        <span style={{display: "flex", alignItems: "center"}}>
                            <BalanceNavigation
                                currantDateMom={currentDate}
                                onDateChange={dateMom => changeDate(dateMom)}
                            />
                        </span>
                    </div>
                    <div
                        style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 30}}>
                        <BalanceInfoCard
                            icon={<CreditCardIcon style={{fill: "#13C296"}} />}
                            title="Total monthly income"
                            subTitle={<span>₪{billingInfo.totalIncome.toLocaleString()}</span>}
                        />
                        <BalanceInfoCard
                            icon={<BuildingIcon style={{fill: "#F6AB2F", width: 38, height: 30}} />}
                            title="Client companies"
                            subTitle={billingInfo.companiesCount.toLocaleString()}
                        />
                        <BalanceInfoCard
                            icon={<EventAcceptedIcon style={{fill: "#F06060"}} />}
                            title="Monthly events"
                            subTitle={billingInfo.eventsCount.toLocaleString()}
                        />
                    </div>
                    <div style={{display: "flex", flexDirection: "column", marginTop: 40}}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: 28,
                                color: "#243446",
                                justifyContent: "flex-start"
                            }}>
                            Transactions
                        </div>
                        <TransactionsTable
                            billingInfo={billingInfo}
                            loading={loadingData}
                            nextMonth={nextMonth}
                            nextYear={nextYear}
                        />
                    </div>
                    {apptBased ? null : !billingInfo.ownInvoices || !billingInfo.ownInvoices[date] ? (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                padding: 20,
                                justifyContent: "space-between",
                                backgroundColor: "rgba(240,96,96,0.24)",
                                height: 70,
                                borderRadius: 10,
                                marginTop: 20
                            }}>
                            <span style={{color: "#F06060", fontSize: 18, fontWeight: 700}}>
                                Please upload an invoice for {month} by the end of the month at the amount of ₪
                                {billingInfo.totalIncome} in order to get paid.
                            </span>
                            <UploadInvoiceButton style={{backgroundColor: "#F06060", color: "white"}}>
                                Upload invoice
                            </UploadInvoiceButton>
                        </div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                padding: 20,
                                justifyContent: "space-between",
                                backgroundColor: "white",
                                border: "1px solid #273444",
                                height: 70,
                                borderRadius: 10,
                                marginTop: 20
                            }}>
                            <span style={{color: "#273444", fontSize: 18, fontWeight: 700}}>
                                We have your invoice for {month}, ₪{billingInfo.totalIncome} will be deposited on{" "}
                                {nextMonth} 1st {nextYear}.
                            </span>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <UploadInvoiceButton
                                    style={{backgroundColor: "#273444", color: "white", marginRight: 10}}>
                                    Update invoice
                                </UploadInvoiceButton>
                            </div>
                        </div>
                    )}
                    <div style={{display: "flex", flexDirection: "column", marginTop: 40}}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: 28,
                                color: "#243446",
                                justifyContent: "flex-start"
                            }}>
                            Platform monthly subscription
                        </div>
                        <SubscriptionTable
                            billingInfo={billingInfo}
                            loading={loadingData}
                            nextMonth={nextMonth}
                            nextYear={nextYear}
                        />
                    </div>
                    {apptBased ? (
                        <div style={{display: "flex", flexDirection: "column", marginTop: 40, marginBottom: 100}}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 28,
                                    color: "#243446",
                                    justifyContent: "flex-start"
                                }}>
                                Payment method
                            </div>
                            <div
                                style={{
                                    backgroundColor: "#FCFDFE",
                                    border: "1px solid #E8EDF5",
                                    borderRadius: 10,
                                    marginTop: 30,
                                    display: "flex",
                                    alignItems: "flex-start",
                                    flexDirection: "column",
                                    padding: 20
                                }}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        color: "#212B36",
                                        fontSize: 20,
                                        fontWeight: 500
                                    }}>
                                    Credit card
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        color: "#212B36",
                                        fontSize: 14,
                                        fontWeight: 500,
                                        marginTop: 30,
                                        width: "100%"
                                    }}>
                                    <div style={{marginLeft: -6, display: "flex", alignItems: "center"}}>
                                        {me.premiumAccount ? (
                                            <>
                                                <CreditCardIcon
                                                    style={{
                                                        width: 51,
                                                        height: 35,
                                                        fill: "var(--secondary-color)",
                                                        marginRight: 10
                                                    }}
                                                />
                                                <span style={{height: 15}}>**** **** **** ****</span>
                                            </>
                                        ) : (
                                            <span
                                                style={{
                                                    marginLeft: 6,
                                                    marginTop: -30,
                                                    fontSize: 16,
                                                    fontWeight: 700,
                                                    color: "red"
                                                }}>
                                                Please insert a credit card
                                            </span>
                                        )}
                                    </div>
                                    <Button
                                        onClick={openCreditDetails}
                                        style={{
                                            borderRadius: 25,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "transparent",
                                            color: "#1260D1",
                                            border: "2px solid #1260D1",
                                            fontSize: 16,
                                            height: 42,
                                            width: 155
                                        }}>
                                        Update card
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{display: "flex", flexDirection: "column", marginTop: 40, marginBottom: 100}}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 28,
                                    color: "#243446",
                                    justifyContent: "flex-start"
                                }}>
                                Bank account information
                            </div>
                            <div
                                style={{
                                    backgroundColor: "#FCFDFE",
                                    border: "1px solid #E8EDF5",
                                    borderRadius: 10,
                                    marginTop: 30,
                                    display: "flex",
                                    alignItems: "flex-start",
                                    flexDirection: "column",
                                    padding: 20
                                }}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        color: "#212B36",
                                        fontSize: 20,
                                        fontWeight: 500
                                    }}>
                                    Plan: {paymentPlanText(billingInfo.subscription.paymentPlan)}
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        color: "#212B36",
                                        fontSize: 14,
                                        fontWeight: 500,
                                        marginTop: 30,
                                        width: "100%"
                                    }}>
                                    <div style={{marginLeft: -6, display: "flex", alignItems: "center"}}>
                                        {me.premiumAccount ? (
                                            <span
                                                style={{marginLeft: 6, marginTop: -30, fontSize: 16, fontWeight: 700}}>
                                                Your next payment will be deposited on {nextMonth} 1st, {nextYear}
                                            </span>
                                        ) : (
                                            <span
                                                style={{
                                                    marginLeft: 6,
                                                    marginTop: -30,
                                                    fontSize: 16,
                                                    fontWeight: 700,
                                                    color: "red"
                                                }}>
                                                Please insert bank information to get payments
                                            </span>
                                        )}
                                    </div>
                                    <Button
                                        onClick={openBankDetails}
                                        style={{
                                            borderRadius: 25,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "transparent",
                                            color: "#1260D1",
                                            border: "2px solid #1260D1",
                                            fontSize: 16,
                                            height: 42,
                                            width: 155
                                        }}>
                                        Update info
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
