import {useContext} from "react";
import {AppContext} from "../../AppContext";
import React from "react";

export const EventInfoTitleV2 = ({dateMom}) => {
    const {theme} = useContext(AppContext);

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                color: theme.textSecondary || "white",
                height: 60,
                fontSize: 20,
                fontWeight: 700,
                paddingLeft: 20,
                backgroundColor: theme.secondary || "#273444"
            }}>
            {dateMom ? dateMom.format("dddd / MMMM Do, YYYY") : null}
        </div>
    );
};
