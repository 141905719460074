import React, {useCallback, useMemo, useState} from "react";
import {CreateConsumptionOccasionModal} from "./CreateConsumptionOccasionModal";
import {getColumns} from "./ConsumptionOccasionsColumns";
import PageTitleHeader from "../../components/PageTitle";
import {RoundedTable, SearchInputWithIcon, SquareButton} from "../../components";
import {useRequest} from "../../utils/hooks";
import {HttpClient} from "../../http/HttpClient";
import "./admin-consumption-occasions.css";

export const AdminConsumptionOccasionTab = () => {
    const [searchInput, setSearchInput] = useState("");
    const [createEditOccasionInfo, setCreateEditOccasionInfo] = useState(null);
    const [consumptionOccasions, loadingConsumptionOccasions, _, fetchConsumptionOccasions] = useRequest(
        "/admin/api/consumptionOccasions"
    );

    const createOrEditOccasion = useCallback(
        async newOccasion => {
            newOccasion?.occasionId
                ? await HttpClient.safePost(`/admin/api/consumptionOccasions/${newOccasion.occasionId}`, newOccasion)
                : await HttpClient.safePut("/admin/api/consumptionOccasions", newOccasion);

            fetchConsumptionOccasions();
            setCreateEditOccasionInfo(null);
        },
        [fetchConsumptionOccasions]
    );

    const onEditClicked = useCallback(occasion => {
        setCreateEditOccasionInfo(occasion);
    }, []);

    const deleteOccasion = useCallback(
        async occasionId => {
            await HttpClient.safeDelete(`/admin/api/consumptionOccasions/${occasionId}`);
            fetchConsumptionOccasions();
        },
        [fetchConsumptionOccasions]
    );

    const columns = useMemo(() => getColumns(onEditClicked, deleteOccasion), [onEditClicked, deleteOccasion]);

    const filteredOccasions = useMemo(
        () => consumptionOccasions?.filter(({name}) => name.includes(searchInput?.trim())) ?? [],
        [consumptionOccasions, searchInput]
    );

    return (
        <>
            <CreateConsumptionOccasionModal
                occasionInfo={createEditOccasionInfo}
                onClose={() => setCreateEditOccasionInfo(null)}
                onSave={createOrEditOccasion}
            />
            <PageTitleHeader showBack={false}>ניהול אירועי צריכה</PageTitleHeader>

            <div className="admin-consumption-occasions-top-bar">
                <SearchInputWithIcon
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    placeholder="חפש אירוע צריכה"
                />
                <SquareButton
                    onClick={() => setCreateEditOccasionInfo({})}
                    className="admin-consumption-occasions-create-button">
                    יצירת אירוע צריכה חדש
                </SquareButton>
            </div>

            <RoundedTable
                rowKey="occasionId"
                className="admin-consumption-occasions-table"
                pagination={false}
                loading={loadingConsumptionOccasions}
                dataSource={filteredOccasions}
                columns={columns}
            />
        </>
    );
};
