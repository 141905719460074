import React, {useEffect, useState} from "react";
import {Avatar, Modal, message} from "antd";
import {HttpClient} from "../http/HttpClient";
import {CloseIcon} from "../icons";
import moment from "moment";
import {ReviewForm} from "./ReviewForm";
import "./icons/review.css";

const REVIEW_KEY = "wb-review-asked";

const ReviewModalHeader = ({pendingReview}) => (
    <div
        style={{
            padding: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "1px solid #DAE0E4"
        }}>
        <Avatar
            style={{minWidth: 50, minHeight: 50, maxWidth: 50, maxHeight: 50, marginRight: 10}}
            src={pendingReview.imageUrl}
        />
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}>
            <div
                style={{
                    fontSize: 20,
                    fontWeight: 700,
                    color: "#273444",
                    width: "100%",
                    textAlign: "left",
                    marginBottom: 5
                }}>
                Do you recommend {pendingReview.providerBrand || pendingReview.providerFirstName}?
            </div>
            <div
                style={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#273444",
                    width: "100%",
                    textAlign: "left"
                }}>
                Help other companies make their choice
            </div>
        </div>
    </div>
);

export const ReviewModal = ({
    skippable = true,
    isAdmin = false,
    initialReview = null,
    eventToReview = null,
    autoPopup = true,
    onClose
}) => {
    const [pendingReview, setPendingReview] = useState(eventToReview);
    const [loading, setLoading] = useState(false);

    const today = moment().format("YYYY-MM-DD");

    if (initialReview && !pendingReview) {
        setPendingReview(initialReview);
    }

    if (eventToReview && !pendingReview) {
        setPendingReview(eventToReview);
    }

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                if (isAdmin || !autoPopup) {
                    return;
                }

                const date = window.localStorage.getItem(REVIEW_KEY);
                if (date && today === date) {
                    return;
                }

                const {pendingReview} = await HttpClient.get("/api/reviews/pending");
                if (pendingReview) {
                    setPendingReview(pendingReview);
                }
            } catch (e) {
                message.error("Something went wrong, please try again later.", 5);
            }
        });
    }, []);

    const closeModal = review => {
        window.localStorage.setItem(REVIEW_KEY, today);
        setPendingReview(null);
        onClose && onClose(review);
    };

    const skipReview = async () => {
        if (skippable) {
            await sendReview({
                rating: -1,
                content: null,
                anonymous: true,
                type: "skipped"
            });
        } else {
            closeModal();
        }
    };

    const addReview = async review => {
        await sendReview({
            ...initialReview,
            ...review,
            type: initialReview ? initialReview.type : "reviewed"
        });
    };

    const sendReview = async review => {
        setLoading(true);
        try {
            const method = initialReview ? "post" : "put";
            review.approved = isAdmin ? true : !!review.approved;
            await HttpClient[method](
                `${isAdmin ? "/admin" : ""}/api/reviews/events/${pendingReview.eventId || pendingReview.reviewId}`,
                review
            );
            closeModal({
                ...initialReview,
                ...review
            });
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setLoading(false);
    };

    return (
        <Modal
            width={400}
            style={{top: 20}}
            className="wb-modal-radius"
            destroyOnClose={true}
            open={pendingReview !== null}
            bodyStyle={{padding: 0}}
            footer={null}
            title={null}
            closeIcon={<CloseIcon fill="#8398A6" />}
            closable={true}
            onCancel={() => skipReview()}
            maskClosable={false}>
            {pendingReview !== null ? <ReviewModalHeader pendingReview={pendingReview} /> : null}
            {pendingReview !== null ? (
                <ReviewForm
                    pendingReview={pendingReview}
                    onFinish={addReview}
                    initialReview={initialReview}
                    loading={loading}
                    onLater={closeModal}
                />
            ) : null}
        </Modal>
    );
};
