import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ConfigProvider, message, Modal} from "antd";
import {useRequest} from "../../utils/hooks";
import "./company-profile-manage-admins.css";
import subAdminsTableColumns from "./subAdminsTableColumns";
import {RoundedTable} from "../../components";
import {HttpClient} from "../../http/HttpClient";
import {getSubAdminIdsToOrder} from "./utils";
import {groupBy} from "lodash";

export const AdminSubAdminManagerModal = ({visible, company, onClose, systemCSMs, onSelectCompanyMasterCSM}) => {
    const [admins, setAdmins] = useState([]);
    const [companyBudgets, setCompanyBudgets] = useState({});
    const [adminsOrders, setAdminsOrders] = useState([]);

    const [subAdmins = [], isSubAdminLoading] = useRequest(
        `/admin/api/companies/${company?.companyId}/users`,
        "get",
        null,
        [],
        visible && !!company
    );

    const [budgets, loadingCompanyBudget] = useRequest(
        `/admin/api/budget/company/${company?.companyId}`,
        "get",
        null,
        [],
        visible && !!company
    );

    const [subAdminsOrders, isSubAdminsOrdersLoading] = useRequest(
        `/admin/api/budgetLogs/many/${company?.companyId}`,
        "POST",
        subAdmins?.map(({userId}) => userId),
        [],
        visible && subAdmins?.length && !!company
    );

    useEffect(() => {
        if (subAdmins) {
            setAdmins(subAdmins);
        }
    }, [subAdmins]);

    useEffect(() => {
        if (budgets) {
            setCompanyBudgets(budgets);
        }
    }, [budgets]);

    useEffect(() => {
        if (subAdminsOrders) {
            setAdminsOrders(subAdminsOrders);
        }
    }, [subAdminsOrders]);

    const [loadingUpdatedUser, setLoadingUpdatedUser] = useState(null);

    const subAdminsIdsToOrders = useMemo(() => {
        return getSubAdminIdsToOrder(admins, subAdminsOrders);
    }, [adminsOrders, admins]);

    const filteredAdmins = useMemo(
        () => admins?.sort(({isCompanyMaster: a}, {isCompanyMaster: b}) => (a === b ? 0 : a ? -1 : 1)) ?? [],
        [admins]
    );

    const onSelectCSM = useCallback(
        async (newCSM, companyId, userId, assignedCSMs, isCompanyMaster) => {
            setLoadingUpdatedUser(userId);
            if (isCompanyMaster) {
                await onSelectCompanyMasterCSM(newCSM, companyId);
                setLoadingUpdatedUser(null);
                return;
            }

            const currentCSMs = assignedCSMs ?? [];
            const selectedCsmAlreadyExists = currentCSMs.some(({userId}) => userId === newCSM.userId);
            const assignedCSMsAdmin = selectedCsmAlreadyExists
                ? currentCSMs.filter(({userId}) => userId !== newCSM.userId)
                : [...currentCSMs, newCSM];

            const updatedUser = await HttpClient.safePut("/admin/api/users", {
                userId: userId,
                assignedCSMs: assignedCSMsAdmin
            });

            if (updatedUser.error) {
                message.error("שגיאה בעידכון CSM");
                setLoadingUpdatedUser(null);
                return;
            }

            const user = admins.find(subAdmin => subAdmin.userId === userId);
            if (user) {
                user.assignedCSMs = assignedCSMsAdmin;
            }
            setLoadingUpdatedUser(null);
        },
        [visible, company, admins]
    );

    const onUpdateCsmPartitionedStatus = useCallback(
        async (userId, companyId, isCompanyMaster, newCsmPartitionedStatus) => {
            setLoadingUpdatedUser(userId);
            if (isCompanyMaster) {
                const updatedCompany = await HttpClient.safePost("/admin/api/companies/update", {
                    companyId,
                    isCSMPartitionedExcluded: newCsmPartitionedStatus
                });

                if (updatedCompany.error) {
                    message.error("שגיאה, המידע על האדמין הראשי לא נשמר");
                } else {
                    message.success("המידע על האדמין הראשי נשמר בהצלחה");
                }

                const user = subAdmins.find(user => user.userId === userId);
                if (user) {
                    user.isCSMPartitionedExcluded = newCsmPartitionedStatus;
                }

                setLoadingUpdatedUser(null);
                return;
            }

            const updatedUser = await HttpClient.safePut("/admin/api/users", {
                userId,
                isCSMPartitionedExcluded: newCsmPartitionedStatus
            });

            if (updatedUser.error) {
                message.error("שגיאה, המידע על התת אדמין לא נשמר");
            } else {
                message.success("המידע על התת אדמין נשמר בהצלחה");
            }

            const user = subAdmins.find(user => user.userId === userId);
            if (user) {
                user.isCSMPartitionedExcluded = newCsmPartitionedStatus;
            }

            setLoadingUpdatedUser(null);
        },
        [subAdmins]
    );

    const onCloseModal = useCallback(() => {
        setAdmins([]);
        setCompanyBudgets({});
        setAdminsOrders([]);
        onClose();
    }, []);

    return (
        <>
            <ConfigProvider direction="rtl">
                <Modal
                    className="admin-sub-admin-manager-modal"
                    width="95%"
                    destroyOnClose={true}
                    closable={true}
                    footer={null}
                    onCancel={onCloseModal}
                    open={visible}>
                    <RoundedTable
                        rowKey="userId"
                        pagination={false}
                        scroll={{x: 1400}}
                        loading={isSubAdminLoading}
                        dataSource={filteredAdmins}
                        columns={subAdminsTableColumns(
                            groupBy(companyBudgets?.budgets, budget => budget.userId),
                            loadingCompanyBudget,
                            null,
                            null,
                            null,
                            subAdminsIdsToOrders,
                            isSubAdminsOrdersLoading,
                            null,
                            null,
                            null,
                            systemCSMs,
                            onSelectCSM,
                            loadingUpdatedUser,
                            false,
                            company,
                            onUpdateCsmPartitionedStatus
                        )}
                    />
                </Modal>
            </ConfigProvider>
        </>
    );
};
