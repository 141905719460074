import React, {useCallback, useMemo, useState} from "react";
import {InputNumber, Modal, Spin, Tabs} from "antd";
import {isFinite} from "lodash";
import {MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {ConfirmationRequiredWrapper} from "./ConfirmationRequiredWrapper";
import {BudgetIdToName} from "../company/CompanyAccountProfile/consts";
import {StringBuilder} from "../AppUtils.js";

const UPDATE_DIRECTION = {
    ADD: "ADD",
    SUBTRACT: "SUB"
};

const EditBudget = ({
    displayName = "",
    budgetId = null,
    amount,
    setBudgetInput,
    budgetInput,
    budgetUpdateDirection,
    setBudgetUpdateDirection,
    innerOnClose,
    innerOnSave,
    enableUpdate,
    budgetAfterUpdate
}) => {
    return (
        <>
            <div className="admin-companies-budget-modal-edit-budget-container">
                <span>
                    {new StringBuilder()
                        .append("כרגע יש בתקציב")
                        .append(displayName)
                        .append(amount?.toLocaleString() ?? 0)
                        .append("₪")
                        .toString()}
                </span>
                <div className="admin-companies-budget-modal-input-container">
                    <InputNumber onChange={setBudgetInput} value={budgetInput} placeholder="טען או משוך תקציב" />
                </div>
                <span>בחר כיוון (משיכה או טעינה)</span>
                <div className="admin-companies-budget-modal-direction-container">
                    <MinusCircleOutlined
                        onClick={() => setBudgetUpdateDirection(UPDATE_DIRECTION.SUBTRACT)}
                        className={budgetUpdateDirection === UPDATE_DIRECTION.SUBTRACT ? "active" : ""}
                    />
                    <PlusCircleOutlined
                        onClick={() => setBudgetUpdateDirection(UPDATE_DIRECTION.ADD)}
                        className={budgetUpdateDirection === UPDATE_DIRECTION.ADD ? "active" : ""}
                    />
                </div>
            </div>

            <div className="admin-companies-budget-modal-save-cancel-buttons">
                <span className="admin-order-modal-cancel-button" onClick={innerOnClose}>
                    ביטול
                </span>
                <ConfirmationRequiredWrapper
                    onYes={() => innerOnSave(budgetId)}
                    disabled={!enableUpdate(budgetId)}
                    subTitle={`לאחר האישור יהיה בתקציב ${BudgetIdToName[budgetId] ?? ""} ${budgetAfterUpdate(
                        budgetId
                    )?.toLocaleString()} ₪`}>
                    <span className={`admin-order-modal-save-button  ${!enableUpdate(budgetId) ? "disabled" : ""}`}>
                        עדכון
                    </span>
                </ConfirmationRequiredWrapper>
            </div>
        </>
    );
};
export const BudgetModal = ({
    budget,
    visibility,
    title,
    loadingBudget,
    onClose,
    onSave,
    emptyState,
    budgetModalExtraContent,
    shouldShowCategoriesPanel = false
}) => {
    const [budgetInput, setBudgetInput] = useState(0);
    const [budgetUpdateDirection, setBudgetUpdateDirection] = useState(null);

    const innerOnClose = useCallback(() => {
        setBudgetInput(0);
        setBudgetUpdateDirection(null);
        onClose();
    }, [onClose]);

    const getBudgetAfterUpdate = useCallback(
        (budgetId = null) => {
            if (!budgetUpdateDirection || !budget || !(Number(budgetInput) > 0)) {
                return null;
            }

            const amount = (shouldShowCategoriesPanel ? budget?.categoryAmount[budgetId] : budget.amount) ?? 0;

            return amount + Number(budgetInput) * (budgetUpdateDirection === UPDATE_DIRECTION.ADD ? 1 : -1);
        },
        [budget, budgetUpdateDirection, budgetInput, shouldShowCategoriesPanel]
    );

    const enableUpdate = useCallback(
        budgetId => {
            const budgetAfterUpdate = getBudgetAfterUpdate(budgetId);
            return isFinite(budgetAfterUpdate) && budgetAfterUpdate >= 0 && budgetUpdateDirection;
        },
        [getBudgetAfterUpdate]
    );

    const innerOnSave = useCallback(
        (budgetId = null) => {
            let amount = Number(budgetInput);

            if (!amount || !budgetUpdateDirection) {
                return;
            }

            amount *= budgetUpdateDirection === UPDATE_DIRECTION.ADD ? 1 : -1;

            onSave(amount, budgetId);
            innerOnClose();
        },
        [budgetInput, onSave, budgetUpdateDirection]
    );

    const budgetsCategory = useMemo(() => {
        return Object.entries(BudgetIdToName)?.map(([budgetId, displayName]) => ({budgetId, displayName})) ?? [];
    }, []);

    const isUserHasBudget = useMemo(() => {
        if (shouldShowCategoriesPanel) {
            return Object.keys(budget?.categoryAmount ?? {}).length;
        }

        return isFinite(budget?.amount);
    }, [budget, shouldShowCategoriesPanel]);

    return (
        <Modal
            centered={true}
            width={700}
            className="admin-companies-budget-modal wb-modal-borderless"
            destroyOnClose={true}
            open={visibility}
            onCancel={innerOnClose}
            footer={null}
            title={title}
            closable={true}>
            {loadingBudget ? (
                <Spin size="large" />
            ) : isUserHasBudget ? (
                <div className="admin-companies-budget-modal-update-form">
                    {budgetModalExtraContent}

                    {shouldShowCategoriesPanel ? (
                        <Tabs>
                            {budgetsCategory.map(({budgetId, displayName}) => (
                                <Tabs.TabPane tab={displayName} key={budgetId}>
                                    <EditBudget
                                        displayName={displayName}
                                        budgetId={budgetId}
                                        amount={budget?.categoryAmount?.[budgetId]}
                                        budgetInput={budgetInput}
                                        budgetUpdateDirection={budgetUpdateDirection}
                                        setBudgetInput={setBudgetInput}
                                        setBudgetUpdateDirection={setBudgetUpdateDirection}
                                        budgetAfterUpdate={getBudgetAfterUpdate}
                                        enableUpdate={enableUpdate}
                                        innerOnClose={innerOnClose}
                                        innerOnSave={innerOnSave}
                                    />
                                </Tabs.TabPane>
                            ))}
                        </Tabs>
                    ) : (
                        <EditBudget
                            amount={budget?.amount}
                            budgetInput={budgetInput}
                            budgetUpdateDirection={budgetUpdateDirection}
                            setBudgetInput={setBudgetInput}
                            setBudgetUpdateDirection={setBudgetUpdateDirection}
                            budgetAfterUpdate={getBudgetAfterUpdate}
                            enableUpdate={enableUpdate}
                            innerOnClose={innerOnClose}
                            innerOnSave={innerOnSave}
                        />
                    )}
                </div>
            ) : (
                emptyState
            )}
        </Modal>
    );
};
