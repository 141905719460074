import React, {useCallback, useContext, useMemo} from "react";
import {ConfigProvider, Skeleton} from "antd";
import {partialRight, uniqBy} from "lodash";
import classnames from "classnames";
import MarketplaceProductsCarousel from "../MarketplaceProductsCarousel";
import {useRequest} from "../../utils/hooks";
import {MAX_CAROUSEL_LENGTH} from "../MarketplaceUtils";
import {AppContext} from "../../AppContext";
import {createProductLink, selectServiceIdFromProduct} from "../MarketplaceHelper";
import {writeProductClickedLog} from "../../Logger";
import {MarketplaceSearchResults} from "../MarketplaceSearchResults/MarketplaceSearchResults.jsx";
import {WBCarousel} from "../../components";

const ITEMS_IN_CAROUSEL = 10;

const SelectionPageDrivenCarousel = ({selectPageId, name, size, log, history, gridNumber}) => {
    const {me, services} = useContext(AppContext);

    const isSpreadSelectionPageCarousel = useMemo(() => !!gridNumber, [gridNumber]);

    const [selectPage, loadingSelectPageData] = useRequest(
        `/api/selectPage/${selectPageId}/${isSpreadSelectionPageCarousel ? "" : `?pageSize=${ITEMS_IN_CAROUSEL}`}`,
        "GET",
        null,
        [selectPageId, isSpreadSelectionPageCarousel],
        !!selectPageId
    );
    const carouselLength = useMemo(() => MAX_CAROUSEL_LENGTH - (size === "small" ? 1 : 0), [size]);

    const onContentClick = useCallback(
        (content, openInNewTab = false) => {
            const serviceId = selectServiceIdFromProduct(me, content, services);
            const url = createProductLink(content, {me, services, serviceId});
            writeProductClickedLog(log, content, serviceId);

            if (openInNewTab) {
                window.open(url, "_blank", "noreferrer");
            } else {
                history.push(url);
            }
        },
        [me, log, services, history]
    );

    const onAllChoicesClicked = useCallback(
        buttonType => {
            log(`All choices ${buttonType} clicked`);
            history.push(`/selectionPage/${selectPageId}`);
        },
        [history, selectPageId, log]
    );

    const title = useMemo(() => {
        return selectPage?.name || name;
    }, [selectPage, name]);

    const products = useMemo(() => {
        if (!selectPage) return [];
        if (!selectPage.products) return [];

        return selectPage.products;
    }, [selectPage]);

    return (
        <ConfigProvider direction="ltr">
            {loadingSelectPageData ? (
                <WBCarousel
                    key="skeleton"
                    carouselProps={{
                        // ...carouselProps,
                        slidesToShow: carouselLength,
                        variableWidth: false
                    }}>
                    {Array.from(Array(carouselLength).keys()).map(index => (
                        <Skeleton.Input
                            key={index}
                            className="marketplace-category-carousel-loading-skeleton"
                            active={true}
                        />
                    ))}
                </WBCarousel>
            ) : (
                <>
                    {!!gridNumber ? (
                        <>
                            <div
                                className={classnames(
                                    "marketplace-category-carousal-title-row",
                                    "flatten-carousel-title-row"
                                )}>
                                <span className="marketplace-category-sub-category-title">{title}</span>
                                {products.length > carouselLength && onAllChoicesClicked ? (
                                    <span
                                        onClick={() => onAllChoicesClicked("label")}
                                        className="marketplace-category-carousal-all-choices">
                                        לכל הבחירות
                                    </span>
                                ) : null}
                            </div>

                            <MarketplaceSearchResults
                                itemsInRow={carouselLength}
                                showSearchAmount={false}
                                searchedContent={products}
                                onContentClick={onContentClick}
                                onNewTab={partialRight(onContentClick, true)}
                            />
                        </>
                    ) : (
                        <MarketplaceProductsCarousel
                            title={title}
                            products={products}
                            carouselLength={carouselLength}
                            onContentClick={onContentClick}
                            onNewTab={partialRight(onContentClick, true)}
                            onAllChoicesClicked={onAllChoicesClicked}
                            loading={loadingSelectPageData}
                        />
                    )}
                </>
            )}
        </ConfigProvider>
    );
};

export default SelectionPageDrivenCarousel;
