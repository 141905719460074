import React from "react";
import {AdminBillingCompanySubscriptionsToBeReceived} from "./AdminBillingCompanySubscriptionsToBeReceived";
import {AdminBillingProvidersToBeReceived} from "./AdminBillingProvidersToBeReceived";
import {AdminBillingCompanyServicesToBeRecceived} from "./AdminBillingCompanyServicesToBeReceived";

export const AdminBillingToBeReceived = ({match}) => {
    const {date} = match.params;

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                marginTop: 30,
                marginLeft: 10,
                marginRight: 10
            }}>
            <AdminBillingCompanySubscriptionsToBeReceived date={date} />
            <AdminBillingCompanyServicesToBeRecceived date={date} />
            <AdminBillingProvidersToBeReceived date={date} />
        </div>
    );
};
