import React, {useCallback, useMemo} from "react";
import "./marketplace-search-empty-search.css";
import SearchIcon from "../../images/searchIcon.svg";
import WhatsappIcon from "../../images/whatsappEmpty.svg";
import {SquareButton} from "../../components";
import {ContactPhone as contactPhone} from "../../support/ContactPhone.jsx";
import {panelsOptions} from "../MarketplaceFilter/panel/consts.jsx";
import {StringBuilder} from "../../AppUtils.js";

export const MarketplaceSearchEmptyResults = ({categories}) => {
    const categoriesToName = useMemo(
        () => panelsOptions.find(category => categories?.includes(category.key))?.text,
        [categories]
    );
    useCallback(() => {}, []);
    return (
        <div className="marketplace-search-empty-search">
            <img src={SearchIcon} alt={"searchIcon"} />
            <div className="marketplace-search-empty-text-container">
                <div>
                    <div className="title">
                        {categoriesToName
                            ? new StringBuilder()
                                  .append("לא נמצאו מוצרים לחיפוש שלך בקטגוריית")
                                  .append(categoriesToName)
                                  .toString()
                            : "לא נמצאו מוצרים לחיפוש שלך"}
                    </div>
                    <div className="description">ניתן לחפש בקטגוריה אחרת, או לדבר איתנו :)</div>
                </div>
                <SquareButton className="start-chat-btn" onClick={() => contactPhone.openWhatsApp()}>
                    <img src={WhatsappIcon} alt={"whatsappIcon"} />
                    התחלת שיחה
                </SquareButton>
            </div>
        </div>
    );
};
