import React from "react";
import {NOOP} from "../../utils/NOOP";

export const EventGalleryMediaItem = ({mediaItem, onClick = NOOP}) => {
    const isVideo = mediaItem?.type?.split("/")[0] === "video";

    return (
        <div key={mediaItem?.id} onClick={onClick}>
            {isVideo ? (
                <video className="order-images-modal-item" autoPlay={true} loop={true} muted={true}>
                    <source src={mediaItem?.url} type={mediaItem?.type} />
                </video>
            ) : (
                <img alt="photo" src={mediaItem?.url} className="order-images-modal-item" />
            )}
        </div>
    );
};
