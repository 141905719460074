import React from "react";
import {round} from "lodash";

export const AdminBillingToBePaidSummaryLine = ({toBePaidOn, sumTotal}) => {
    return (
        <div
            style={{
                marginTop: 30,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "rgba(240,96,96,0.1)",
                height: 70,
                borderRadius: 10,
                padding: 20
            }}>
            <div>
                <span style={{fontSize: 28, fontWeight: 700, color: "#D04949", marginRight: 6}}>Sum total</span>
                <span style={{fontSize: 20, fontWeight: 400, color: "#D04949"}}>to be paid on {toBePaidOn}</span>
            </div>
            <div>
                <span style={{fontSize: 28, fontWeight: 700, color: "#D04949", marginRight: 6}}>{`₪${round(
                    sumTotal,
                    2
                ).toLocaleString()}`}</span>
                <span style={{fontSize: 20, fontWeight: 700, color: "#D04949"}}>+ VAT</span>
            </div>
        </div>
    );
};
