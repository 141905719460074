import React, {useEffect, useState} from "react";
import {Avatar, Button, Menu, Dropdown, message} from "antd";
import {FrownOutlined, LoadingOutlined} from "@ant-design/icons";
import moment from "moment-timezone";
import {truncate, sumBy, round} from "lodash";
import {AdminBillingToBePaidSummaryLine} from "./AdminBillingToBePaidSummaryLine";
import {ADMIN_PATH_NAME} from "../consts";
import {RoundedTable} from "../../components";
import {HttpClient} from "../../http/HttpClient";
import {BankInformationModal} from "../../billing/BankInformationModal";
import {SearchInput} from "../../components";

const columns = (
    date,
    changePaymentState,
    changeInvoiceState,
    onBankInfoClick,
    onProviderRemove,
    onSeeTransactions,
    processingProviderId
) => {
    return [
        {
            title: "#",
            key: "#",
            width: 70,
            render(_, __, idx) {
                return `${idx + 1}`;
            }
        },
        {
            title: "NAME",
            key: "name",
            width: 300,
            sorter: (r1, r2) => {
                const name1 = `${r1.firstName}${r1.lastName ? ` ${r1.lastName}` : ""}`;
                const name2 = `${r2.firstName}${r2.lastName ? ` ${r2.lastName}` : ""}`;
                return name1.toLowerCase() < name2.toLowerCase() ? 1 : -1;
            },
            defaultSortOrder: "descend",
            render(_, {firstName, lastName, phone, imageUrl}) {
                return (
                    <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                        {imageUrl ? (
                            <Avatar
                                style={{minWidth: 32, minHeight: 32, maxWidth: 32, maxHeight: 32, marginRight: 8}}
                                src={imageUrl}
                            />
                        ) : (
                            <FrownOutlined style={{fontSize: 32, marginRight: 8}} />
                        )}
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <label
                                style={{
                                    color: "var(--secondary-color)",
                                    fontSize: 14,
                                    fontWeight: 500,
                                    cursor: "inherit"
                                }}>{`${firstName}${lastName ? ` ${lastName}` : ""}`}</label>
                            <label
                                style={{
                                    color: "#747F92",
                                    fontSize: 12,
                                    fontWeight: 400,
                                    cursor: "inherit"
                                }}>
                                {phone}
                            </label>
                        </div>
                    </div>
                );
            }
        },
        {
            title: "CATEGORY",
            dataIndex: "categories",
            key: "categories",
            width: 250,
            render(categories) {
                return (
                    <span style={{fontSize: 14, color: "#747F92"}}>
                        {truncate(categories.join(", "), {
                            length: 50,
                            separator: ","
                        })}
                    </span>
                );
            }
        },
        {
            title: "MONTHLY SUM",
            dataIndex: "totalSum",
            key: "totalSum",
            width: 250,
            render(totalSum, {firstTransactionDate, lastTransactionDate}) {
                if (lastTransactionDate) {
                    return (
                        <span style={{fontSize: 14, color: "#747F92"}}>
                            {`₪${round(totalSum, 2).toLocaleString()}+VAT | ${moment(
                                firstTransactionDate,
                                "YYYY-MM-DD"
                            ).format("DD")}-${moment(lastTransactionDate, "YYYY-MM-DD").format("DD/MM/YYYY")}`}
                        </span>
                    );
                } else {
                    return (
                        <span style={{fontSize: 14, color: "#747F92"}}>
                            {`₪${round(totalSum, 2).toLocaleString()}+VAT | ${moment(
                                firstTransactionDate,
                                "YYYY-MM-DD"
                            ).format("DD/MM/YYYY")}`}
                        </span>
                    );
                }
            }
        },
        {
            title: "PLAN",
            dataIndex: "paymentPlan",
            key: "paymentPlan",
            width: 200,
            render(paymentPlan) {
                return <span style={{fontSize: 14, color: "#747F92"}}>{paymentPlan}</span>;
            }
        },
        {
            title: "BANK INFO",
            dataIndex: "bankInfoExists",
            key: "bankInfoExists",
            render(bankInfoExists, {providerId}) {
                return (
                    <div onClick={e => e.stopPropagation()}>
                        {bankInfoExists ? (
                            <Button
                                type="link"
                                onClick={() => onBankInfoClick(providerId)}
                                style={{fontSize: 14, color: "#1260D1", fontWeight: 700}}>
                                YES
                            </Button>
                        ) : (
                            <Button
                                type="link"
                                onClick={() => onBankInfoClick(providerId)}
                                style={{fontSize: 14, color: "#747F92", fontWeight: 700}}>
                                NO
                            </Button>
                        )}
                    </div>
                );
            }
        },
        {
            title: "INVOICE",
            dataIndex: "hasInvoice",
            key: "hasInvoice",
            render(hasInvoice, {providerId}) {
                return (
                    <div onClick={e => e.stopPropagation()}>
                        {hasInvoice ? (
                            <Button
                                type="link"
                                onClick={() => changeInvoiceState(providerId, false)}
                                style={{fontSize: 14, color: "#1260D1", fontWeight: 700}}>
                                YES
                            </Button>
                        ) : (
                            <Button
                                type="link"
                                onClick={() => changeInvoiceState(providerId, true)}
                                style={{fontSize: 14, color: "#747F92", fontWeight: 700}}>
                                NO
                            </Button>
                        )}
                    </div>
                );
            }
        },
        {
            title: "TO BE PAID",
            dataIndex: "totalToBePaid",
            key: "totalToBePaid",
            width: 200,
            render(totalToBePaid, {paymentClosed}) {
                return (
                    <span
                        style={{
                            fontSize: 14,
                            fontWeight: 700,
                            color: paymentClosed ? "#13C296" : "#D04949"
                        }}>{`₪${round(totalToBePaid, 2).toLocaleString()}+VAT`}</span>
                );
            }
        },
        {
            title: "",
            key: "actions",
            render(_, {providerId, paymentClosed}) {
                const menu = (
                    <Menu>
                        <Menu.Item key="closePayment">
                            <Button
                                style={{minWidth: 100, color: "var(--secondary-color)"}}
                                type="link"
                                onClick={() => changePaymentState(providerId, !paymentClosed)}>
                                {paymentClosed ? "Open payment" : "Close payment"}
                            </Button>
                        </Menu.Item>
                        <Menu.Item key="removeProvider">
                            <Button style={{minWidth: 100, color: "var(--secondary-color)"}} type="link">
                                Delete row
                            </Button>
                        </Menu.Item>
                        <Menu.Item key="seeTransactions">
                            <Button
                                style={{minWidth: 100, color: "var(--secondary-color)"}}
                                type="link"
                                onClick={() => onSeeTransactions(providerId)}>
                                See transactions
                            </Button>
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <div onClick={e => e.stopPropagation()}>
                        <Dropdown.Button
                            className="wb-action-drpdwn"
                            style={{border: "none"}}
                            overlay={menu}
                            icon={processingProviderId === providerId ? <LoadingOutlined /> : void 0}
                        />
                    </div>
                );
            }
        }
    ];
};

export const AdminBillingToBePaid = ({history, match}) => {
    const {date} = match.params;
    const [processingProviderId, setProcessingProviderId] = useState(null);
    let [providers, setProviders] = useState(null);
    const [bankInfo, setBankInfo] = useState(null);
    const [loadingBankInfo, setLoadingBankInfo] = useState(false);
    const [providerIdForEditBankInfo, setProviderIdForEditBankInfo] = useState(null);
    const [term, setTerm] = useState("");

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const providers = await HttpClient.get(`/admin/api/billing/${date}/tobepaid`);
                setProviders(providers);
            } catch (e) {
                message.error("Something went wrong, please try again later.", 5);
            }
        });
    }, []);

    const changePaymentState = async (providerId, paymentClosed) => {
        setProcessingProviderId(providerId);
        try {
            await HttpClient.post(`/admin/api/billing/${date}/providers/${providerId}`, {
                paymentClosed
            });
            setProviders(
                providers.map(p => {
                    if (p.providerId === providerId) {
                        return {
                            ...p,
                            paymentClosed
                        };
                    } else {
                        return p;
                    }
                })
            );
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setProcessingProviderId(null);
    };

    const changeInvoiceState = async (providerId, hasInvoice) => {
        setProcessingProviderId(providerId);
        try {
            await HttpClient.post(`/admin/api/billing/${date}/providers/${providerId}`, {
                hasInvoice
            });
            setProviders(
                providers.map(p => {
                    if (p.providerId === providerId) {
                        return {
                            ...p,
                            hasInvoice
                        };
                    } else {
                        return p;
                    }
                })
            );
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setProcessingProviderId(null);
    };

    const onBankInfoClick = async providerId => {
        setLoadingBankInfo(true);
        setProviderIdForEditBankInfo(providerId);
        try {
            const bankInfo = await HttpClient.get(`/admin/api/billing/providers/${providerId}/bank`);
            if (bankInfo) {
                setBankInfo(bankInfo);
            }
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setLoadingBankInfo(false);
    };

    const saveBankInfo = async bankInfo => {
        try {
            setBankInfo(bankInfo);
            await HttpClient.post(`/admin/api/billing/providers/${providerIdForEditBankInfo}/bank`, bankInfo);
            setProviders(
                providers.map(p => {
                    if (p.providerId === providerIdForEditBankInfo) {
                        return {
                            ...p,
                            bankInfoExists: true
                        };
                    } else {
                        return p;
                    }
                })
            );
            closeBankInfoModal();
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
    };

    const closeBankInfoModal = () => {
        setBankInfo(null);
        setProviderIdForEditBankInfo(null);
    };

    const onProviderRemove = async providerId => {
        setProcessingProviderId(providerId);
        try {
            await HttpClient.post(`/admin/api/billing/${date}/providers/${providerId}`, {
                hide: true
            });
            setProviders(providers.filter(p => p.providerId !== providerId));
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setProcessingProviderId(null);
    };

    const onSeeTransactions = providerId => {
        history.push(`/${ADMIN_PATH_NAME}/billing/${date}/providers/${providerId}/transactions`);
    };

    const sumTotal = sumBy(providers, p => p.totalToBePaid);

    if (Array.isArray(providers) && providers.length > 0) {
        providers = providers.filter(provider => {
            return (
                `${provider.firstName} ${provider.lastName || ""} ${provider.lastName} ${provider.email} ${
                    provider.phone
                }`
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) >= 0
            );
        });
    }

    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap", marginTop: 30}}>
            <div style={{width: "100%"}}>
                <SearchInput
                    style={{marginBottom: 30, maxWidth: 500, width: "100%"}}
                    onChange={evt => setTerm(evt.target.value)}
                    placeholder="Search professional"
                />
            </div>
            <BankInformationModal
                key={providerIdForEditBankInfo}
                open={bankInfo !== null || loadingBankInfo === true}
                onClose={closeBankInfoModal}
                bankInfo={bankInfo}
                loading={loadingBankInfo}
                onBankInfoSave={saveBankInfo}
            />
            <RoundedTable
                className="wb-clickable-table"
                rowKey="providerId"
                scroll={{x: 1650, y: 600}}
                style={{color: "#747F92"}}
                pagination={false}
                loading={providers === null}
                dataSource={providers || []}
                onRow={({providerId}) => {
                    return {
                        onClick: () => onSeeTransactions(providerId)
                    };
                }}
                columns={columns(
                    date,
                    changePaymentState,
                    changeInvoiceState,
                    onBankInfoClick,
                    onProviderRemove,
                    onSeeTransactions,
                    processingProviderId
                )}
            />
            <AdminBillingToBePaidSummaryLine
                toBePaidOn={moment(date, "YYYY-MM").format("MMMM [1st]")}
                sumTotal={sumTotal}
            />
        </div>
    );
};
