import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ConfigProvider, message} from "antd";
import {merge, set, uniq} from "lodash";
import {AdminMarketplaceViewAutoComplete} from "../../components";
import {FormModal} from "../../../components";
import {MarketplaceViewType} from "../../../marketplace/consts";
import "./admin-upload-new-product-bundle-modal.css";

export const AdminUploadNewProductBundleModal = ({visible, onCancel, editProductBundle, onSave}) => {
    const [formValues, setFormValues] = useState(null);

    const updateFormValues = useCallback((key, value) => {
        setFormValues(currentFormValues => set({...currentFormValues}, key, value));
    }, []);

    const onSelectProduct = useCallback(
        (productFieldKey, contentId, option) => {
            if (contentId === null) {
                updateFormValues(productFieldKey, null);
                return;
            }

            const product = {
                productId: contentId,
                productName: option.children
            };

            updateFormValues(productFieldKey, product);
        },
        [updateFormValues]
    );

    const multiProductInputs = useMemo(
        () => [
            {
                label: "מוצר ראשון",
                name: "_firstProduct",
                type: "custom",
                customComponent: (
                    <AdminMarketplaceViewAutoComplete
                        itemType={MarketplaceViewType.product}
                        content={formValues?.products?.[0]?.productId}
                        onSelect={(contentId, option) => onSelectProduct("products.0", contentId, option)}
                    />
                )
            },
            {
                label: "מוצר שני",
                name: "_secondProduct",
                type: "custom",
                customComponent: (
                    <AdminMarketplaceViewAutoComplete
                        itemType={MarketplaceViewType.product}
                        content={formValues?.products?.[1]?.productId}
                        onSelect={(contentId, option) => onSelectProduct("products.1", contentId, option)}
                    />
                )
            }
        ],
        [formValues]
    );

    useEffect(() => {
        if (visible) {
            setFormValues(currentFormValues => merge(currentFormValues ?? {}, editProductBundle ?? {}));
        }
    }, [editProductBundle, visible]);

    return (
        <>
            <ConfigProvider direction="rtl">
                <FormModal
                    className="admin-upload-new-product-bundle-modal-container"
                    header={editProductBundle?.productBundleId ? "עריכת מוצר משולב" : "יצירת מוצר משולב"}
                    modalWidth={700}
                    visible={visible}
                    onClose={() => {
                        setFormValues(null);
                        onCancel();
                    }}
                    content={formValues}
                    validationFunction={() => {
                        if (formValues?.products.every(product => !product)) {
                            message.error("יש לבחור 2 מוצרים למוצר המשולב");
                            return false;
                        }

                        const productIds = uniq(formValues.products.map(({productId}) => productId));

                        if (productIds.length !== 2) {
                            message.error("יש לבחור 2 מוצרים שונים בשביל לבנות מוצר משולב");
                            return false;
                        }

                        return true;
                    }}
                    onSave={() => {
                        const productIds = uniq(formValues.products.map(({productId}) => productId));
                        onSave({...formValues, productIds});
                        setFormValues(null);
                        onCancel();
                    }}
                    onUpdate={(_, changedField) => {
                        const [key, value] = Object.entries(changedField)[0];
                        updateFormValues(key, value);
                    }}
                    fields={[
                        {
                            name: "name",
                            label: "שם המוצר המשולב"
                        },
                        {
                            name: "_multi_recommendedProductParticipants",
                            label: "המלצת כמות משתתפים לחבילה המשולבת",
                            type: "multi",
                            inputs: [
                                {
                                    label: "",
                                    name: "minRecommendedProductParticipants",
                                    type: "number"
                                },
                                {
                                    label: "",
                                    name: "_custom",
                                    type: "custom",
                                    className: "multiRecommendedProductParticipantsTitle",
                                    customComponent: (
                                        <>
                                            <div>עד</div>
                                        </>
                                    )
                                },
                                {
                                    label: "",
                                    name: "maxRecommendedProductParticipants",
                                    type: "number"
                                }
                            ]
                        },
                        {
                            name: "description",
                            label: "תיאור",
                            type: "textarea",
                            minRows: 5,
                            maxRows: 10
                        },
                        {
                            name: "_multi_products",
                            label: "בחרו עד 2 מוצרים",
                            type: "multi",
                            inputs: multiProductInputs
                        }
                    ]}
                />
            </ConfigProvider>
        </>
    );
};
