import React, {useCallback, useContext, useMemo} from "react";
import {set} from "lodash";
import {
    ACTIVITIES_IDS_TO_NAMES,
    FilterOptionIdToName,
    kosherTypesToHebrew
} from "./ProductsFilterModal/consts";
import {MarketplaceFilterProviderContext} from "./MarketplaceFilter/MarketplaceFilterContext.jsx";
import {FilterSearchOptionsList} from "./MarketplaceFilter/searchOptions/FilterSearchOptionList.jsx";

export const MarketplaceFilterSearchOptions = ({
    filterValues,
    setFilterValues,
    removeSearchInput,
    removeExtraSearchInput,
    onLockedFiltersChange,
    lockedFilters = [],
    allSearchTags = []
}) => {
    const {innerState, updateLocalFilterState} = useContext(MarketplaceFilterProviderContext);
    const getBudgetSection = useCallback(() => {
        const {participantsAmount, pricePerParticipant, totalPrice} = filterValues?.budget ?? {};

        function GetText() {
            if (
                !isNaN(parseInt(participantsAmount)) &&
                isNaN(parseInt(pricePerParticipant)) &&
                isNaN(parseInt(totalPrice?.max))
            ) {
                return (
                    <div style={{direction: "rtl"}}>
                        <span>{participantsAmount}</span>
                        <span> </span>
                        <span>משתתפים</span>
                    </div>
                );
            }

            if (!isNaN(parseInt(participantsAmount)) && !isNaN(parseInt(pricePerParticipant))) {
                return (
                    <div style={{direction: "rtl"}}>
                        <span>{pricePerParticipant}</span>
                        <span> </span>
                        <span>{"₪"}</span>
                        <span> </span>
                        <span>למשתתף</span>
                    </div>
                );
            }
            if (
                !isNaN(parseInt(participantsAmount)) &&
                isNaN(parseInt(pricePerParticipant)) &&
                !isNaN(parseInt(totalPrice?.max))
            ) {
                return (
                    <div style={{direction: "rtl"}}>
                        <span>{totalPrice?.max}</span>
                        <span> </span>
                        <span>{"₪"}</span>
                        <span> </span>
                        <span>תקציב להזמנה</span>
                        <span> </span>
                        <span>ל-</span>
                        <span> </span>
                        <span>{participantsAmount}</span>
                        <span> </span>
                        <span>משתתפים</span>
                    </div>
                );
            }
            return null;
        }

        if (GetText() === null) {
            return null;
        }

        return {
            id: "budget",
            title: <GetText />,
            filter: filterValues.budget,
            onClick: () => {
                setFilterValues(currentFilters => {
                    const {budget, ...otherFilters} = currentFilters;
                    updateLocalFilterState(otherFilters);
                    return otherFilters;
                });
            }
        };
    }, [filterValues?.budget]);

    const filterOptions = useMemo(() => {
        const activeFilters = [];

        if (filterValues.searchText) {
            activeFilters.push({
                id: "searchText",
                filter: filterValues.searchText,
                title: <span>חיפוש טקסט: {filterValues.searchText}</span>,
                onClick: () => removeSearchInput()
            });
        }

        if (filterValues.extraSearchTexts?.length) {
            activeFilters.push(
                ...filterValues.extraSearchTexts.map(searchText => ({
                    id: `extraSearchTexts-${searchText}`,
                    filter: searchText,
                    title: <span>חיפוש טקסט: {searchText}</span>,
                    onClick: () => removeExtraSearchInput(searchText)
                }))
            );
        }

        if (filterValues.deliveryAddress?.length) {
            activeFilters.push(
                ...filterValues.deliveryAddress.map(address => ({
                    id: `deliveryAddress-${address.displayName}`,
                    filter: address,
                    title: (
                        <div>
                            <span>{address.displayName}</span>
                            {address.deliveryPrice ? <span>{` (עד ${address.deliveryPrice} ₪ דמי משלוח)`}</span> : null}
                        </div>
                    ),
                    onClick: () => {
                        setFilterValues(currentFilters => {
                            const {deliveryAddress, ...otherFilters} = currentFilters;
                            updateLocalFilterState(otherFilters);
                            return otherFilters;
                        });
                    }
                }))
            );
        }

        const budgetOptions = getBudgetSection();

        if (budgetOptions) {
            activeFilters.push(budgetOptions);
        }

        if (filterValues.productProperties?.length) {
            activeFilters.push(
                ...filterValues.productProperties.map(property => ({
                    id: `productProperties-${property}`,
                    filter: property,
                    title: <span>{FilterOptionIdToName[property]}</span>,
                    onClick: () => {
                        const newProperties = filterValues.productProperties.filter(
                            productProperty => productProperty !== property
                        );

                        if (!newProperties.length) {
                            setFilterValues(currentFilters => {
                                const {productProperties, ...otherFilters} = currentFilters;
                                updateLocalFilterState(otherFilters);
                                return otherFilters;
                            });
                        } else {
                            setFilterValues(currentFilters => {
                                const values = set({...currentFilters}, "productProperties", newProperties);
                                updateLocalFilterState(values);
                                return values;
                            });
                        }
                    }
                }))
            );
        }

        if (filterValues.kosherTypes?.length) {
            activeFilters.push(
                ...filterValues.kosherTypes.map(property => ({
                    id: `kosherTypes-${property}`,
                    filter: property,
                    title: <span>{kosherTypesToHebrew[property]}</span>,
                    onClick: () => {
                        const newProperties = filterValues.kosherTypes.filter(
                            productProperty => productProperty !== property
                        );

                        if (!newProperties.length) {
                            setFilterValues(currentFilters => {
                                const {kosherTypes, ...otherFilters} = currentFilters;
                                updateLocalFilterState(otherFilters);
                                return otherFilters;
                            });
                        } else {
                            setFilterValues(currentFilters => {
                                const values = set({...currentFilters}, "kosherTypes", newProperties);
                                updateLocalFilterState(values);
                                return values;
                            });
                        }
                    }
                }))
            );
        }

        if (filterValues.activityRequestsProperties?.length) {
            activeFilters.push(
                ...filterValues.activityRequestsProperties.map(property => ({
                    id: `activityRequestsProperties-${property}`,
                    filter: property,
                    title: <span>{ACTIVITIES_IDS_TO_NAMES[property]}</span>,
                    onClick: () => {
                        const newProperties = filterValues.activityRequestsProperties.filter(
                            productProperty => productProperty !== property
                        );

                        if (!newProperties.length) {
                            setFilterValues(currentFilters => {
                                const {activityRequestsProperties, ...otherFilters} = currentFilters;
                                updateLocalFilterState(otherFilters);
                                return otherFilters;
                            });
                        } else {
                            setFilterValues(currentFilters => {
                                const values = set({...currentFilters}, "activityRequestsProperties", newProperties);
                                updateLocalFilterState(values);
                                return values;
                            });
                        }
                    }
                }))
            );
        }

        // activityRequestsProperties
        return activeFilters;
    }, [filterValues, getBudgetSection, innerState]);

    const removeTag = tag => {
        const newTags = filterValues.searchTags.filter(filterTag => filterTag !== tag.tagId);
        const newFilters = {...filterValues, searchTags: newTags};
        setFilterValues(newFilters);
    };

    const tagsToShow = useMemo(() => {
        if (!filterValues?.searchTags?.length || !allSearchTags?.length) {
            return [];
        }
        let tagsIds = filterValues?.searchTags || [];
        let tags = tagsIds.map(tagId => allSearchTags?.find(tag => tag.tagId === tagId));
        return tags;
    }, [filterValues?.searchTags, allSearchTags]);
    return (
        <>
            <FilterSearchOptionsList
                filterOptions={filterOptions}
                onLockedFiltersChange={onLockedFiltersChange}
                lockedFilters={lockedFilters}
                tagsToShow={tagsToShow}
                removeTag={removeTag}
            />
        </>
    );
};
