import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Collapse, DatePicker, Form, message, Popover, Select, Switch} from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    PlusOutlined,
    PushpinOutlined,
    SolutionOutlined
} from "@ant-design/icons";
import moment from "moment/moment";
import {FormEditModes, providerFormValidators} from "./consts";
import {AvailabilityHourly, ConfirmationRequiredWrapper, PageSubTitleLabel, StyledButton} from "../../components";
import {CoverPhotoHandler} from "../../wizards/components/CoverPhotoHandler";
import {AutoDirectionProvider} from "../../AutoDirectionProvider";
import {StepperInput} from "../../wizards/components/StepperInput";
import {StepperDropdownInput} from "../../wizards/components/StepperDropdownInput";
import {
    getDealerTypeReversed,
    kosherOptions,
    ProviderNetPaymentTermsTypes,
    ProviderTypeEnum
} from "../../admin/upload/AdminUploadUtils";
import {DealerType, Years} from "../../wizards/wizardConsts";
import {getDealerType} from "../../wizards/wizardUtils";
import {AutoCompleteAddressFormInput} from "../../utils/AutoCompleteAddress";
import {StepperParagraphInput} from "../../wizards/components/StepperParagraphInput";
import {SingleFileUploadHelper} from "../../admin/upload/AdminUploadHelper";
import {TrashIcon} from "../../icons";
import {NOOP} from "../../utils/NOOP";

import {FileUpload} from "../../components/FileUpload";
import {AdminUploadNewProductDeliveryPanel} from "../../admin/upload/AdminUploadNewProductDeliveryPanel";
import {deliveryFormFields} from "../../admin/upload/consts.jsx";
import {getTransformedProducts} from "./utils.jsx";

const {Panel} = Collapse;
export const ProviderInfoForm = ({
    form,
    formValues,
    setFormValues,
    products,
    setProducts,
    productsSearchTags,
    providerInfo,
    providerSubProducts,
    setProviderSubProducts,
    formProps = {},
    onFormValueChange = NOOP,
    onPhotoChange = NOOP,
    assignPartnerOptions = [],
    editMode = FormEditModes.Readonly
}) => {
    const [editProviderEmail, setEditProviderEmail] = useState(null);
    const [isSavingProviderLoading, setIsSavingProviderLoading] = useState(false);

    const removeProviderFinanceAdditionalFiles = useCallback(
        url => {
            const newProviderFinanceAdditionalFiles = formValues?.financeInfo?.additionalFiles.filter(
                file => file.url !== url
            );
            onFormValueChange("financeInfo.additionalFiles", newProviderFinanceAdditionalFiles);
        },
        [formValues]
    );

    useEffect(() => {
        if (providerInfo) {
            setProviderSubProducts(providerInfo?.subProducts ?? []);
            setFormValues(providerInfo);
            setEditProviderEmail(providerInfo.email);
        } else {
            onShouldPaymentBySupply(true);
        }
    }, [providerInfo]);

    useEffect(() => {
        if (providerInfo) {
            setProviderSubProducts(providerInfo?.subProducts ?? []);
        }

        if (providerInfo?.services?.length) {
            const transformedProducts = getTransformedProducts(providerInfo, productsSearchTags);
            setProducts(transformedProducts ?? []);
        }
    }, [providerInfo, productsSearchTags, setProducts, setProviderSubProducts]);

    const onShouldPaymentBySupply = useCallback(
        checked => {
            onFormValueChange("shouldPaymentBySupply", checked);
        },
        [formValues, onFormValueChange]
    );

    const mergeProviderFinanceAdditionalFiles = useCallback(
        file => {
            const newAdditionalFiles = formValues?.financeInfo?.additionalFiles ?? [];
            const newFileInfo = {
                id: file.imageId,
                name: file.name,
                url: `https://res.cloudinary.com/well-b-prod/image/upload/${file.imageId}`
            };

            newAdditionalFiles.push(newFileInfo);
            onFormValueChange("financeInfo.additionalFiles", newAdditionalFiles);
        },
        [formValues?.financeInfo?.additionalFiles]
    );

    const onContractFileChange = useCallback(
        (imageUrl, imageId, name) => {
            setFormValues(prev => ({
                ...prev,
                contractImageUrl: imageUrl,
                contractImageId: imageId,
                contractFileName: name
            }));
        },
        [setFormValues]
    );

    const onKosherCertificateChange = useCallback((imageUrl, imageId, name) => {
        setFormValues(prev => ({
            ...prev,
            kosherCertificateImageUrl: `https://res.cloudinary.com/well-b-prod/image/upload/${imageId}`,
            kosherCertificateImageId: imageId,
            kosherCertificateFileName: name
        }));
    }, []);

    const brandOptions = useMemo(() => {
        return (formValues?.brandNames ?? []).map(brandName => ({value: brandName, label: brandName}));
    }, [formValues?.brandNames]);

    const onBrandNameOptionChange = useCallback(
        selectedBrandNames => {
            const updatedBrandNames = selectedBrandNames || [];
            const originalBrandNames = formValues?.brandNames || [];
            if (updatedBrandNames.length < originalBrandNames.length) {
                const removedBrandName = originalBrandNames.find(
                    originalBrandName => !updatedBrandNames.includes(originalBrandName)
                );
                const productNamesContainBrandName = products
                    .filter(
                        product => product?.brandName === removedBrandName || product?.engBrandName === removedBrandName
                    )
                    .map(product => product.productName);
                if (productNamesContainBrandName?.length) {
                    const productNamesMessage = productNamesContainBrandName.join(", ");
                    const errMessage = [
                        "לא ניתן למחוק תגית שמקושרת למוצר. על מנת למחוק יש למחוק את התגית מהמוצרים:",
                        productNamesMessage
                    ].join(" ");
                    message.error(errMessage);
                    return;
                }
            }
            onFormValueChange("brandNames", selectedBrandNames);
        },
        [formValues?.brandNames, products]
    );

    return (
        <Form form={form} className="admin-upload-form-container" {...formProps}>
            <PageSubTitleLabel className="admin-upload-sub-title">פרטים על בית העסק</PageSubTitleLabel>
            <CoverPhotoHandler
                placeholder={"תמונה ראשית/לוגו (png/jpeg)"}
                onPhotoChange={onPhotoChange}
                closable={onPhotoChange !== NOOP}
                currentImageUrl={formValues.imageUrl}
                currentImageId={formValues.imageId}
            />
            <Form.Item>
                <div className="admin-upload-form-should-payment-by-supply">
                    <span>תשלום לפי מועד האספקה</span>
                    <Switch
                        onChange={checked => onShouldPaymentBySupply(checked)}
                        checked={formValues?.shouldPaymentBySupply}
                        checkedChildren="כן"
                        unCheckedChildren="לא"
                    />
                </div>
            </Form.Item>
            <div className="admin-upload-main-form-container">
                <AutoDirectionProvider text={"שם בית העסק"}>
                    <label>שם בית העסק</label>
                    <Form.Item
                        validateStatus={formValues?.errors?.businessName ? "error" : ""}
                        help={formValues?.errors?.businessName}>
                        <StepperInput
                            status={formValues?.errors?.businessName ? "error" : ""}
                            className="admin-upload-form-input"
                            placeholder="שם בית העסק"
                            value={formValues?.businessName}
                            onChange={e =>
                                onFormValueChange("businessName", e.target.value, providerFormValidators.businessName)
                            }
                        />
                    </Form.Item>
                </AutoDirectionProvider>
                <AutoDirectionProvider text={"מס׳ עוסק או ח.פ"}>
                    <label>מס׳ עוסק או ח.פ</label>
                    <Form.Item
                        validateStatus={formValues?.errors?.businessNumber ? "error" : ""}
                        help={formValues?.errors?.businessNumber}>
                        <StepperInput
                            status={formValues?.errors?.businessNumber ? "error" : ""}
                            className="businessNumber"
                            placeholder="מס׳ עוסק או ח.פ"
                            value={formValues?.businessNumber}
                            onChange={e =>
                                onFormValueChange(
                                    "businessNumber",
                                    e.target.value,
                                    providerFormValidators.businessNumber
                                )
                            }
                        />
                    </Form.Item>
                </AutoDirectionProvider>
                <AutoDirectionProvider text={"טלפון ראשי לקבלת הזמנות"}>
                    <label>טלפון ראשי לקבלת הזמנות (עבור שירות לקוחות)</label>
                    <Form.Item
                        validateStatus={formValues?.errors?.phone ? "error" : ""}
                        help={formValues?.errors?.phone}>
                        <StepperInput
                            status={formValues?.errors?.phone ? "error" : ""}
                            className="phone"
                            placeholder="טלפון ראשי לקבלת הזמנות"
                            value={formValues?.phone}
                            onChange={e => {
                                const value = e.target.value;
                                onFormValueChange("phone", value, providerFormValidators.phone);
                            }}
                        />
                    </Form.Item>
                </AutoDirectionProvider>
                <AutoDirectionProvider text={"כתובת בית העסק"}>
                    <label>כתובת בית העסק (כתובת ראשית)</label>
                    <Form.Item valuePropName="searchText">
                        <AutoCompleteAddressFormInput
                            editValue={formValues.address}
                            onChange={address => onFormValueChange("address", address)}
                        />
                    </Form.Item>
                </AutoDirectionProvider>
                <AutoDirectionProvider text={"מייל ראשי (כניסה לאזור ספק)"}>
                    <label>מייל ראשי (כניסה לאזור ספק)</label>
                    <Form.Item
                        validateStatus={formValues?.errors?.email ? "error" : ""}
                        help={formValues?.errors?.email}>
                        <StepperInput
                            status={formValues?.errors?.email ? "error" : ""}
                            className="email"
                            placeholder="מייל ראשי (כניסה לאזור ספק)"
                            value={formValues?.email}
                            onChange={e => {
                                const value = e.target.value;
                                onFormValueChange("email", value, providerFormValidators.email);
                            }}
                        />
                    </Form.Item>
                </AutoDirectionProvider>
                <AutoDirectionProvider text={"מייל משני (קבלת מיילים בלבד)"}>
                    <label>מייל משני (קבלת מיילים בלבד)</label>
                    <Form.Item>
                        <StepperInput
                            placeholder="מייל משני (קבלת מיילים בלבד)"
                            value={formValues?.extraEmail}
                            onChange={e => onFormValueChange("extraEmail", e.target.value)}
                        />
                    </Form.Item>
                </AutoDirectionProvider>
                <AutoDirectionProvider text={"תיאור בית העסק"}>
                    <label>תיאור בית העסק</label>
                    <Form.Item
                        validateStatus={formValues?.errors?.description ? "error" : ""}
                        help={formValues?.errors?.description}>
                        <StepperParagraphInput
                            status={formValues?.errors?.description ? "error" : ""}
                            className="description"
                            placeholder="תארו בכמה מילים את השירותים/המוצרים שאתם מציעים בשפה ברורה ושיווקית :)"
                            maxLength={2000}
                            autoSize={{maxRows: 8, minRows: 8}}
                            value={formValues.description}
                            onChange={event =>
                                onFormValueChange("description", event.target.value, providerFormValidators.description)
                            }
                        />
                    </Form.Item>
                </AutoDirectionProvider>
                <AutoDirectionProvider text={"שנות פעילות"}>
                    <label>שנות פעילות</label>
                    <Form.Item>
                        <StepperDropdownInput
                            style={{width: "100%"}}
                            arrowStyle={{position: "absolute", right: "", left: 10}}
                            value={formValues.experience}
                            placeholder={"שנות פעילות"}
                            items={Years.map(val => ({key: val, label: val}))}
                            onChange={val => onFormValueChange("experience", val)}
                        />
                    </Form.Item>
                </AutoDirectionProvider>
                <AutoDirectionProvider text={"שנות פעילות"}>
                    <label>תנאי שוטף</label>
                    <Form.Item
                        validateStatus={formValues?.errors?.netPaymentTerms ? "error" : ""}
                        help={formValues?.errors?.netPaymentTerms}>
                        <StepperDropdownInput
                            status={formValues?.errors?.netPaymentTerms ? "error" : ""}
                            className="netPaymentTerms"
                            style={{width: "100%"}}
                            arrowStyle={{position: "absolute", right: "", left: 10}}
                            value={formValues?.netPaymentTerms}
                            placeholder={"תנאי שוטף"}
                            items={Object.values(ProviderNetPaymentTermsTypes).map((val, index) => ({
                                key: val,
                                label: val,
                                className: `net-payment-terms-option-${index}`
                            }))}
                            onChange={val =>
                                onFormValueChange("netPaymentTerms", val, providerFormValidators.netPaymentTerms)
                            }
                        />
                    </Form.Item>
                </AutoDirectionProvider>
                <AutoDirectionProvider text={"מנהל שותף"}>
                    <label>מנהל/ת שותף</label>
                    <Form.Item
                        validateStatus={formValues?.errors?.assignPartner ? "error" : ""}
                        help={formValues?.errors?.assignPartner}
                        rules={[
                            {
                                required: true,
                                message: "שדה חובה"
                            }
                        ]}>
                        <StepperDropdownInput
                            className="assignPartner"
                            style={{width: "100%"}}
                            arrowStyle={{position: "absolute", right: "", left: 10}}
                            value={formValues?.assignPartner?.[0]?.firstName}
                            placeholder={"מנהל/ת שותף"}
                            items={assignPartnerOptions.map((user, index) => ({
                                label: user?.firstName,
                                key: user?.userId,
                                className: `assign-partner-${index}`
                            }))}
                            onChange={selectedUserId => {
                                const partnerUser = assignPartnerOptions.find(user => user?.userId === selectedUserId);

                                onFormValueChange(
                                    "assignPartner",
                                    [
                                        {
                                            userId: partnerUser.userId,
                                            firstName: partnerUser.firstName,
                                            email: partnerUser.email
                                        }
                                    ],
                                    providerFormValidators.assignPartner
                                );
                            }}
                            status={formValues?.errors?.assignPartner ? "error" : ""}
                        />
                    </Form.Item>
                </AutoDirectionProvider>
                <AutoDirectionProvider text={"שעות פעילות בית העסק"}>
                    <label>שעות פעילות בית העסק</label>
                    <Form.Item>
                        <AvailabilityHourly
                            value={formValues.availability}
                            onChange={values => onFormValueChange("availability", values)}
                        />
                    </Form.Item>
                </AutoDirectionProvider>
                <AutoDirectionProvider text={"עברית"}>
                    <label>בית עסק כשר</label>
                    <Form.Item>
                        <StepperDropdownInput
                            style={{width: "100%"}}
                            arrowStyle={{position: "absolute", right: "", left: 10}}
                            value={formValues.kosher}
                            items={kosherOptions.map(val => ({key: val, label: val}))}
                            onChange={val => onFormValueChange("kosher", val)}
                        />
                    </Form.Item>
                    <SingleFileUploadHelper
                        openFile={() => {
                            window.open(formValues?.kosherCertificateImageUrl, "Image");
                        }}
                        uploadText={"טעינת תעודת כשרות"}
                        fileName={formValues?.kosherCertificateFileName}
                        onFileChange={onKosherCertificateChange}
                    />
                </AutoDirectionProvider>

                <AutoDirectionProvider text={"תוקף תעודת כשרות"}>
                    <label>תוקף תעודת כשרות</label>

                    <DatePicker
                        format={"DD/MM/YYYY"}
                        style={{width: "100%"}}
                        disabled={formValues?.kosher === kosherOptions[1]}
                        disabledDate={date => date.isSameOrBefore(moment(), "day")}
                        placeholder="בחרו תוקף לתעודת הכשרות"
                        value={
                            formValues?.kosherCertificateExpireDate
                                ? moment(formValues?.kosherCertificateExpireDate)
                                : undefined
                        }
                        onChange={newDate => {
                            onFormValueChange(
                                "kosherCertificateExpireDate",
                                newDate ? moment(newDate).valueOf() : null
                            );
                        }}
                    />
                </AutoDirectionProvider>

                <AutoDirectionProvider text={"אתר מקצועי"}>
                    <label>אתר בית העסק</label>
                    <Form.Item>
                        <StepperInput
                            placeholder="אתר בית העסק"
                            value={formValues?.website}
                            onChange={e => onFormValueChange("website", e.target.value)}
                        />
                    </Form.Item>
                </AutoDirectionProvider>
                <AutoDirectionProvider text={"פרופיל לינקדאין"}>
                    <label>פרופיל לינקדאין</label>
                    <Form.Item>
                        <StepperInput
                            placeholder="פרופיל לינקדאין"
                            value={formValues?.linkedin}
                            onChange={e => onFormValueChange("linkedin", e.target.value)}
                        />
                    </Form.Item>
                </AutoDirectionProvider>
                <AutoDirectionProvider text={"פרופיל פייסבוק"}>
                    <label>פרופיל פייסבוק</label>
                    <Form.Item>
                        <StepperInput
                            placeholder="פרופיל פייסבוק"
                            value={formValues?.facebook}
                            onChange={e => onFormValueChange("facebook", e.target.value)}
                        />
                    </Form.Item>
                </AutoDirectionProvider>
                <AutoDirectionProvider text={"פרופיל אינסטגרם"}>
                    <label>פרופיל אינסטגרם</label>
                    <Form.Item>
                        <StepperInput
                            placeholder="פרופיל אינסטגרם"
                            value={formValues?.instagram}
                            onChange={e => onFormValueChange("instagram", e.target.value)}
                        />
                    </Form.Item>
                </AutoDirectionProvider>
                <AutoDirectionProvider text={"שם מותג - עברית"}>
                    <label>שם מותג</label>
                    <Select
                        className="provider-info-form-brand-names-select"
                        mode="tags"
                        direction="rtl"
                        placeholder="שמות מותג"
                        value={formValues?.brandNames ?? []}
                        onChange={onBrandNameOptionChange}
                        options={brandOptions}
                        open={false}
                    />
                </AutoDirectionProvider>
                <PageSubTitleLabel className="admin-upload-sub-title">איש קשר בבית העסק</PageSubTitleLabel>
                <AutoDirectionProvider text={"שם פרטי"}>
                    <label>שם פרטי</label>
                    <Form.Item
                        validateStatus={formValues?.errors?.contactFirstName ? "error" : ""}
                        help={formValues?.errors?.contactFirstName}>
                        <StepperInput
                            status={formValues?.errors?.contactFirstName ? "error" : ""}
                            className="contactFirstName"
                            placeholder="שם פרטי"
                            value={formValues?.contactFirstName}
                            onChange={e =>
                                onFormValueChange(
                                    "contactFirstName",
                                    e.target.value,
                                    providerFormValidators.contactFirstName
                                )
                            }
                        />
                    </Form.Item>
                </AutoDirectionProvider>
                <AutoDirectionProvider text={"שם משפחה"}>
                    <label>שם משפחה</label>
                    <Form.Item
                        validateStatus={formValues?.errors?.contactLastName ? "error" : ""}
                        help={formValues?.errors?.contactLastName}>
                        <StepperInput
                            status={formValues?.errors?.contactLastName ? "error" : ""}
                            className="contactLastName"
                            placeholder="שם משפחה"
                            value={formValues?.contactLastName}
                            onChange={e =>
                                onFormValueChange(
                                    "contactLastName",
                                    e.target.value,
                                    providerFormValidators.contactLastName
                                )
                            }
                        />
                    </Form.Item>
                </AutoDirectionProvider>
                <AutoDirectionProvider text={"מס׳ טלפון (רק ספרות)"}>
                    <label>מס׳ טלפון (רק ספרות)</label>
                    <Form.Item
                        validateStatus={formValues?.errors?.contactPhoneNumber ? "error" : ""}
                        help={formValues?.errors?.contactPhoneNumber}>
                        <StepperInput
                            status={formValues?.errors?.contactPhoneNumber ? "error" : ""}
                            className="contactPhoneNumber"
                            placeholder="מס׳ טלפון (רק ספרות)"
                            value={formValues?.contactPhoneNumber}
                            onChange={e => {
                                onFormValueChange(
                                    "contactPhoneNumber",
                                    e.target.value,
                                    providerFormValidators.contactPhoneNumber
                                );
                            }}
                        />
                    </Form.Item>
                </AutoDirectionProvider>
                <AutoDirectionProvider text={"מייל איש הקשר"}>
                    <AutoDirectionProvider text={"מייל איש הקשר"}>
                        <label>מייל איש הקשר</label>
                        <Form.Item>
                            <StepperInput
                                placeholder="מייל איש הקשר"
                                value={formValues?.contactEmail}
                                onChange={e => onFormValueChange("contactEmail", e.target.value)}
                            />
                        </Form.Item>
                        <SingleFileUploadHelper
                            openFile={() => {
                                window.open(formValues?.contractImageUrl, "Image");
                            }}
                            uploadText={"טעינת הסכם עבודה"}
                            fileName={formValues?.contractFileName}
                            onFileChange={(url, id, name) => onContractFileChange(url, id, name)}
                        />
                    </AutoDirectionProvider>

                    <PageSubTitleLabel className="admin-upload-sub-title">פרטי חשבון בנק</PageSubTitleLabel>

                    <AutoDirectionProvider text={"פרטי חשבון בנק"}>
                        <label>שם הבנק</label>
                        <Form.Item>
                            <StepperInput
                                placeholder="שם הבנק"
                                value={formValues?.financeInfo?.bankName}
                                onChange={e => onFormValueChange("financeInfo.bankName", e.target.value)}
                            />
                        </Form.Item>

                        <label>מספר סניף</label>
                        <Form.Item>
                            <StepperInput
                                placeholder="מספר סניף"
                                value={formValues?.financeInfo?.branchNumber}
                                onChange={e => onFormValueChange("financeInfo.branchNumber", e.target.value)}
                            />
                        </Form.Item>

                        <label>מספר חשבון</label>
                        <Form.Item>
                            <StepperInput
                                placeholder="מספר חשבון"
                                value={formValues?.financeInfo?.accountNumber}
                                onChange={e => onFormValueChange("financeInfo.accountNumber", e.target.value)}
                            />
                        </Form.Item>

                        <label>שם בעל החשבון</label>
                        <Form.Item>
                            <StepperInput
                                placeholder="שם בעל החשבון"
                                value={formValues?.financeInfo?.accountOwnerName}
                                onChange={e => onFormValueChange("financeInfo.accountOwnerName", e.target.value)}
                            />
                        </Form.Item>
                        <label>טעינת מסמכים</label>
                        <div style={{marginBottom: "5px"}}>
                            <SingleFileUploadHelper
                                openFile={() =>
                                    window.open(formValues?.financeInfo?.accountManagementAuthorization?.url)
                                }
                                uploadText={"אישור ניהול חשבון"}
                                fileName={formValues?.financeInfo?.accountManagementAuthorization?.name}
                                onFileChange={(url, id, name) =>
                                    onFormValueChange("financeInfo.accountManagementAuthorization", {
                                        url,
                                        id,
                                        name
                                    })
                                }
                            />
                        </div>

                        <div style={{marginBottom: "5px"}}>
                            <SingleFileUploadHelper
                                openFile={() => window.open(formValues?.financeInfo?.withholdingTaxCertificate?.url)}
                                uploadText={"אישור ניכוי מס במקור"}
                                fileName={formValues?.financeInfo?.withholdingTaxCertificate?.name}
                                onFileChange={(url, id, name) =>
                                    onFormValueChange("financeInfo.withholdingTaxCertificate", {
                                        url,
                                        id,
                                        name
                                    })
                                }
                            />
                        </div>

                        <label>טעינת קבצים</label>
                        {onFormValueChange !== NOOP ? (
                            <Form.Item>
                                <FileUpload
                                    onUploadSuccess={mergeProviderFinanceAdditionalFiles}
                                    acceptedFormats={"application/pdf,image/png,image/jpeg"}
                                />
                            </Form.Item>
                        ) : null}
                        <div>
                            {formValues?.financeInfo?.additionalFiles?.map(({url, name, id}) => {
                                return (
                                    <div key={id}>
                                        {onFormValueChange !== NOOP ? (
                                            <TrashIcon
                                                style={{fill: "black", cursor: "pointer"}}
                                                onClick={() => removeProviderFinanceAdditionalFiles(url)}
                                            />
                                        ) : null}
                                        <a href={url} target="_blank" rel="noopener noreferrer">
                                            {name}
                                        </a>
                                    </div>
                                );
                            })}
                        </div>

                        <label>סוג עוסק</label>
                        <Form.Item
                            validateStatus={formValues?.errors?.dealerType ? "error" : ""}
                            help={formValues?.errors?.dealerType}
                            valuePropName="dealerType"
                            rules={[
                                {
                                    required: true,
                                    message: "שדה חובה"
                                }
                            ]}>
                            <StepperDropdownInput
                                className="dealer-type"
                                style={{width: "100%"}}
                                arrowStyle={{position: "absolute", right: "", left: 10}}
                                value={getDealerTypeReversed(formValues.dealerType)}
                                placeholder="סוג עוסק"
                                items={DealerType.map((val, index) => ({
                                    key: val,
                                    label: val,
                                    className: `dealer-type-${index}`
                                }))}
                                onChange={val =>
                                    onFormValueChange(
                                        "dealerType",
                                        getDealerType(val),
                                        providerFormValidators.dealerType
                                    )
                                }
                                status={formValues?.errors?.dealerType ? "error" : ""}
                            />
                        </Form.Item>
                    </AutoDirectionProvider>
                </AutoDirectionProvider>
                <Collapse className="admin-upload-delivery-option-container">
                    <Panel key={1} header="הגדרות שילוח עבור הספק">
                        <AdminUploadNewProductDeliveryPanel
                            onFormValueChange={onFormValueChange}
                            deliveryOptions={formValues.deliveryOptions}
                            homeDeliveryPrice={formValues?.homeDeliveryPrice}
                            maxShippingProvidersDeliveryPrice={formValues?.maxShippingProvidersDeliveryPrice}
                            disabledFields={[deliveryFormFields.shippingProviders]}
                            deliveryPrice={formValues?.deliveryPrice}
                            doesProviderArriveToCustomer={formValues.doesProviderArriveToCustomer}
                            otherProductsDeliveryAreas={products
                                .filter(product => product.deliveryOptions?.deliveryAreas)
                                .map(product => ({
                                    name: product.productName,
                                    deliveryAreas: product?.deliveryOptions?.deliveryAreas
                                }))}
                        />
                    </Panel>
                </Collapse>
                <PageSubTitleLabel className="admin-upload-sub-title">זכיינים</PageSubTitleLabel>
                <AutoDirectionProvider text="זכיינים">
                    {/* <div className="admin-grey_box"> */}
                    {/* <Switch /> */}
                    {/* <div className="admin-upload-product-name">רולדין גבעתיים</div> */}
                    {/* <div className="sup-provider-actions"> */}
                    {/* <TrashIcon /> */}
                    {/* <EditOutlined /> */}
                    {/* </div> */}
                    {/* </div> */}
                    <div className="sub-products-list-add-product-container">
                        <div
                            className="sub-products-list-add-product-content"
                            onClick={() => {
                                // this is not implemented yet so we show Message
                                message.error("פיצ׳ר בפיתוח");
                            }}>
                            <span className="sub-products-list-add-product-title">הוספת זכיין</span>
                            <div className="sub-products-list-add-product-icon">
                                <PlusOutlined />
                            </div>
                        </div>
                    </div>
                </AutoDirectionProvider>

                {!formValues?.isVoucherProvider ? (
                    <div className="admin-upload-show-provider-sub-product-image">
                        <span>הצג תמונות של מיני מוצרים</span>
                        <Form.Item>
                            <Switch
                                checkedChildren="פעיל"
                                unCheckedChildren="לא פעיל"
                                checked={formValues.showProviderSubProductsImages}
                                onChange={checked => onFormValueChange("showProviderSubProductsImages", checked)}
                            />
                        </Form.Item>
                    </div>
                ) : null}
            </div>

            {formProps?.onFinish ? (
                <div className="admin-upload-submit-buttons-container">
                    <Form.Item>
                        <StyledButton
                            className="admin-upload-submit-button"
                            loading={isSavingProviderLoading}
                            onClick={async () => {
                                setIsSavingProviderLoading(true);
                                await formProps.onFinish();
                                setIsSavingProviderLoading(false);
                            }}>
                            שמירה
                        </StyledButton>
                    </Form.Item>
                    <Form.Item>
                        <ConfirmationRequiredWrapper
                            onYes={() => {
                                setFormValues({
                                    providerType: ProviderTypeEnum.BRAND,
                                    imageId: null,
                                    imageUrl: null
                                });
                                form.resetFields();
                            }}>
                            <StyledButton className="admin-upload-clean-form-button">נקה טופס</StyledButton>
                        </ConfirmationRequiredWrapper>
                    </Form.Item>
                </div>
            ) : null}
        </Form>
    );
};

export default ProviderInfoForm;
