import {FileFilled, SaveFilled, TeamOutlined} from "@ant-design/icons";
import {FIRST_ORDER, UPDATE_INVOICE_DETAILS} from "../consts";
import classnames from "classnames";
import React, {useCallback, useContext, useMemo, useState} from "react";
import {MarketplaceContext} from "../../marketplaceContext";
import {FormModal} from "../../../components";
import {ConfigProvider, message, Typography} from "antd";
import moment from "moment/moment";
import {AsiaJerusalemTZ} from "../../../event/DaysMapper";
import {getMergedDeliveryOptions} from "../utils";
import {DEFAULT_DELIVERY_ARRIVING_TIME_RANGE} from "../../MarketplaceUtils";
import {createBookingEventValues} from "../../MarketplaceHelper";
import {HttpClient} from "../../../http/HttpClient";
import {AppContext} from "../../../AppContext";
import {CompanyInvoiceManage} from "../../../company/CompanyAccountProfile/CompanyInvoiceManage";
import {NOOP} from "../../../utils/NOOP";

export const BookingHeader = ({
    productName,
    setBookingLoading,
    createEvent,
    log = NOOP,
    updateDetailsModals,
    setUpdateDetailsModals = NOOP,
    saveProductAsDraft = NOOP,
    sendOrderAsQuote = NOOP,
    isVoucherProvider
}) => {
    const {me, company, fetchMe, fetchCompany} = useContext(AppContext);
    const {isProductBundle} = useContext(MarketplaceContext);

    const [showQuoteSendModal, setShowQuoteSendModal] = useState(false);

    const isAllowedToEditInvoiceInfo = useMemo(() => {
        if (me?.isCompanyMaster) {
            return true;
        }

        return company?.separateSubAdminInvoices;
    }, [me?.isCompanyMaster, company?.separateSubAdminInvoices]);

    return (
        <>
            <CompanyInvoiceManage
                showAsModal={true}
                visible={updateDetailsModals}
                onClose={() => {
                    setUpdateDetailsModals(null);
                    setBookingLoading(false);
                }}
                onSave={async () => {
                    if (updateDetailsModals === FIRST_ORDER) {
                        await createEvent();
                    }

                    if (me.isCompanySubAdmin) {
                        await fetchMe();
                    }

                    await fetchCompany();
                    setUpdateDetailsModals(null);
                    setBookingLoading(false);
                }}
            />

            <ConfigProvider direction={"rtl"}>
                <FormModal
                    visible={showQuoteSendModal}
                    modalWidth={600}
                    header="שליחת המפרט לגורם נוסף"
                    subTitle={
                        <div style={{direction: "rtl"}}>
                            <div>
                                באפשרותכם לשלוח את ההזמנה שבניתם ישירות למייל של גורם נוסף בארגון על מנת לאשר מולו את
                                המפרט, או סתם להתייעץ :)
                            </div>
                            <div>
                                המפרט יגיע למייל הגורם בצורה מסודרת על מנת שיוכל לחוות דעתו על הרכב ההזמנה שתיכננתם.
                            </div>
                        </div>
                    }
                    confirmButtonTitle="שליחת המפרט"
                    fields={[
                        {
                            name: "email",
                            label: "אימייל",
                            rules: [
                                {
                                    validator: (_, value) => {
                                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                                        return value && emailRegex.test(value)
                                            ? Promise.resolve()
                                            : Promise.reject("אנא הקלידו מייל תקין");
                                    }
                                }
                            ]
                        }
                    ]}
                    onClose={() => setShowQuoteSendModal(false)}
                    onSave={async values => {
                        await sendOrderAsQuote(values.email);
                        setShowQuoteSendModal(false);
                        log("Send Product Quote");
                    }}
                />
            </ConfigProvider>

            <div className="booking-title-bar-background">
                <div className="booking-title-bar-background-color" />
                <div className="booking-title-bar">
                    <div>
                        <span className="booking-product-name-title">{productName}</span>
                    </div>
                    <div className="booking-title-bar-icon-container">
                        {!isVoucherProvider && !isProductBundle ? (
                            <u onClick={() => setShowQuoteSendModal(true)} className="booking-title-bar-icon">
                                <TeamOutlined />
                                <span>שליחת המפרט לגורם נוסף</span>
                            </u>
                        ) : null}

                        {isAllowedToEditInvoiceInfo && setUpdateDetailsModals !== NOOP ? (
                            <u
                                onClick={() => {
                                    setUpdateDetailsModals(UPDATE_INVOICE_DETAILS);
                                    log("Update Company Info Clicked");
                                }}
                                className={classnames("booking-title-bar-icon", "flip-icon")}>
                                <FileFilled />
                                <span>עדכון פרטי חשבונית</span>
                            </u>
                        ) : null}

                        {!isVoucherProvider && saveProductAsDraft !== NOOP ? (
                            <u
                                onClick={async () => {
                                    await saveProductAsDraft();
                                    log("Saving Product as draft");
                                }}
                                className="booking-title-bar-icon">
                                <SaveFilled />
                                <span>שמירה כטיוטה</span>
                            </u>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};
