import {DynamicForm} from "../../components/DynamicForm.jsx";
import {NOOP} from "../../utils/NOOP.jsx";
import {ConfigProvider, message} from "antd";
import React, {useCallback, useMemo, useState} from "react";
import {HttpClient} from "../../http/HttpClient.jsx";
import {set} from "lodash";
import {UserTypeToHebrew} from "../../consts.js";

export const UserCompanyMasterSearch = () => {
    const [formValues, setFormValues] = useState({});
    const [companyMasterUser, setCompanyMasterUser] = useState({});

    const onUpdateForm = useCallback((_allValues, changedField) => {
        const [fieldKey, fieldValue] = Object.entries(changedField)[0];
        setFormValues(currentValues => set({...currentValues}, fieldKey, fieldValue));
    }, []);

    const onSearchCompanyMasterUser = useCallback(async formValues => {
        const res = await HttpClient.safePost("/admin/api/user/getUserCompanyMaster", {email: formValues.email});

        if (res?.error) {
            message.error(`שגיאה בעת החיפוש (${res.error})`);
            setCompanyMasterUser(null);
            return;
        }

        message.success("המשתמש אותר בהצלחה");
        setCompanyMasterUser(res);
    }, []);

    const CompanyMasterFormFields = useMemo(
        () => [
            {
                className: "admin-user-management-input-email",
                name: "email",
                type: "email",
                label: "אימייל"
            }
        ],
        []
    );

    return (
        <>
            <ConfigProvider direction="rtl">
                <DynamicForm
                    validationFunction={async values => {
                        await onSearchCompanyMasterUser(values);
                        return false;
                    }}
                    onSave={NOOP}
                    content={formValues}
                    onUpdate={onUpdateForm}
                    fields={CompanyMasterFormFields}
                />
            </ConfigProvider>

            <div className="admin-user-company-master-search-container">
                {Object.keys(companyMasterUser ?? {}).length ? (
                    <>
                        <div>פרטי האדמין הראשי שלי:</div>

                        <div>
                            <div>שם:</div>
                            <div>{companyMasterUser?.name}</div>
                        </div>

                        <div>
                            <div>מייל:</div>
                            <div>{companyMasterUser?.email}</div>
                        </div>

                        <div>
                            <div>סוג המשתמש:</div>
                            <div>{UserTypeToHebrew[companyMasterUser?.type] ?? "לא זוהה סוג המשתמש"}</div>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
};
