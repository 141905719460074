import React from "react";
import {Checkbox, Form} from "antd";
import {ReviewRating} from "./ReviewRating";
import {FormTextArea} from "../components/form";
import {StyledButton} from "../components/StyledButton";

export const AnonymousCheckbox = ({checked, onChange}) => {
    return (
        <div onClick={() => onChange(!checked)} style={{display: "flex", alignItems: "center", cursor: "pointer"}}>
            <Checkbox checked={checked} onChange={onChange} />
            <span style={{color: "#8398A6", fontSize: 16, marginLeft: 10}}>Stay anonymous</span>
        </div>
    );
};

export const ReviewForm = ({onFinish, loading, pendingReview, initialReview, onLater}) => {
    const isCustomReview = () => {
        return initialReview && initialReview.type === "custom";
    };

    return (
        <Form
            onFinish={onFinish}
            initialValues={{anonymous: false, content: null, ...initialReview}}
            style={{width: "100%", padding: 20}}>
            <div
                style={{
                    marginTop: 20,
                    display: "flex",
                    width: "100%",
                    color: "#C1CBD3",
                    fontSize: 16
                }}>
                How satisfied are you with {pendingReview.providerBrand || pendingReview.providerFirstName}’s services?
            </div>
            <Form.Item
                name="rating"
                rules={[
                    {
                        required: true,
                        type: "number",
                        message: "Please select rating."
                    }
                ]}>
                <ReviewRating />
            </Form.Item>
            <div
                style={{
                    width: "100%",
                    textAlign: "left",
                    fontSize: 16,
                    color: "#C1CBD3",
                    marginTop: 5,
                    marginBottom: 15
                }}>
                Share a review of {pendingReview.providerBrand || pendingReview.providerFirstName} with other companies
            </div>
            <Form.Item name="content">
                <FormTextArea maxLength={120} autoSize={{maxRows: 5, minRows: 5}} />
            </Form.Item>
            <div
                style={{
                    display: "flex",
                    justifyContent: !isCustomReview() ? "space-between" : "flex-end",
                    width: "100%",
                    alignItems: "center"
                }}>
                {!isCustomReview() ? (
                    <Form.Item valuePropName="checked" name="anonymous" style={{marginBottom: 0}}>
                        <AnonymousCheckbox />
                    </Form.Item>
                ) : null}
                <Form.Item style={{marginBottom: 0, display: "flex"}}>
                    <StyledButton
                        loading={loading}
                        htmlType="submit"
                        style={{
                            backgroundColor: "#13C296",
                            color: "white",
                            height: 40,
                            borderRadius: 25,
                            width: initialReview ? 150 : 80
                        }}>
                        {initialReview ? "Update & Approve" : "Send"}
                    </StyledButton>
                </Form.Item>
            </div>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-start"
                }}>
                <StyledButton
                    onClick={onLater}
                    type="link"
                    style={{
                        color: "#8398A6",
                        backgroundColor: "white",
                        boxShadow: "none",
                        justifyContent: "flex-start",
                        padding: 0,
                        border: "none",
                        fontSize: 14,
                        height: 30,
                        textDecoration: "underline"
                    }}>
                    Later
                </StyledButton>
            </div>
        </Form>
    );
};
