import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import "./provider-description.css";
import {ProgressiveImageLoader} from "../../../components/index.jsx";
import cover_placeholder from "../../../images/cover_placeholder.jpg";
import {Skeleton, Tag} from "antd";
import {getProviderFoodOptions, getProviderKosherOptions} from "../../MarketplaceItems/utils.js";
import classnames from "classnames";

export const ProviderDescription = ({provider, log, providerProducts}) => {
    const [isReadMore, setReadMore] = useState(false);
    const [showReadMore, setShowReadMore] = useState(false);
    const refProviderDescription = useRef();

    useEffect(() => {
        setShowReadMore(
            refProviderDescription?.current &&
                refProviderDescription?.current?.offsetHeight < refProviderDescription?.current?.scrollHeight
        );
    }, [refProviderDescription?.current]);

    const providerProperties = useMemo(() => {
        const localTags = [];
        const productKosherType = (provider?.services ?? []).map(service => service.productKosherType);
        const kosherTitle = getProviderKosherOptions(productKosherType);
        if (kosherTitle) {
            localTags.push(kosherTitle);
        }

        const foodRequests = (provider?.services ?? []).flatMap(service => service.foodRequests);
        const foodRequestTitle = getProviderFoodOptions(foodRequests);
        if (foodRequestTitle) {
            localTags.push(foodRequestTitle);
        }
        return localTags;
    }, [provider?.services]);

    const onShowTextClick = useCallback(e => {
        setReadMore(currentIsReadMore => !currentIsReadMore);
        log("click on read more", {isProviderPage: true});
        e.preventDefault();
    }, [log]);

    const firstBrandName = useMemo(() => {
        const product = (providerProducts ?? []).find(product => !!product?.service?.brandName);
        return product?.service?.brandName ?? provider?.businessName;
    }, [providerProducts, provider?.businessName]);

    return (
        <div className="provider-header-page">
            <div className="provider-header-container">
                <div className="provider-header-top-container">
                    <div className="marketplace-provider-img">
                        <ProgressiveImageLoader
                            maxWidth={96}
                            className={"marketplace-product-card-smart-image"}
                            src={provider?.imageUrl ?? cover_placeholder}
                        />
                    </div>
                    <div className="provider-header-page-provider-name">{firstBrandName}</div>
                </div>
                <div className={classnames("provider-header-page-description-container", {"read-more": isReadMore})}>
                    <div
                        ref={refProviderDescription}
                        className={classnames("provider-header-page-description", {"read-more": isReadMore})}>
                        {provider?.description}
                    </div>
                    {!!showReadMore && (
                        <a href="#" onClick={onShowTextClick}>
                            {isReadMore ? "קרא פחות" : "קרא עוד"}
                        </a>
                    )}
                </div>
                <div className="provider-header-page-tags-list">
                    {providerProperties.map((tag, index) => {
                        return <Tag key={index}>{tag}</Tag>;
                    })}
                </div>
            </div>
        </div>
    );
};

export const ProviderHeaderPageSkeleton = () => {
    return (
        <div className="provider-header-page">
            <div className="provider-header-container">
                <div className="provider-header-top-container">
                    <div className="marketplace-provider-img">
                        <Skeleton.Button className="provider-icon-skeleton" active={true} shape="circle" />
                    </div>
                    <div className="provider-header-page-provider-name">
                        <Skeleton.Button className="provider-header-page-provider-name-skeleton" active={true} />
                    </div>
                </div>
                <div className="provider-header-page-description provider-header-page-description-skeleton">
                    <Skeleton paragraph={{rows: 2}} title={false} active />
                </div>
                <div className="provider-header-page-tags-list">
                    {[...Array(2)].map(index => {
                        return <Skeleton.Button className="provider-header-page-tags-skeleton" key={index} active />;
                    })}
                </div>
            </div>
        </div>
    );
};
