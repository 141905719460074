import React, {useEffect, useContext, useState} from "react";
import {AppContext} from "../AppContext";
import isMobileDevice from "is-mobile";

const DESKTOP_NORMAL_SCREEN_WIDTH = 1200;

const calculateZoom = (me, factor = 1.15) => {
    if (window.innerWidth < DESKTOP_NORMAL_SCREEN_WIDTH && !isMobileDevice()) {
        const zoom = window.innerWidth / (DESKTOP_NORMAL_SCREEN_WIDTH * factor);
        return zoom * 100.0 + "%";
    }

    return "100%";
};

export const ScreenSizeAdapter = () => {
    const {me} = useContext(AppContext);

    useEffect(() => {
        document.body.style.zoom = calculateZoom(me);

        return () => {
            document.body.style.zoom = "100%";
        };
    }, []);

    return null;
};

export const SizeAdapterWrapper = ({factor, style, children}) => {
    const {me} = useContext(AppContext);
    const [zoom] = useState(calculateZoom(me, factor));

    return <div style={{zoom, ...style}}>{children}</div>;
};
