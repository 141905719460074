import React from "react";
import "./year-picker.css";

export const YearPicker = ({currentYear, setCurrentYear}) => {
    return (
        <div className="year-picker-container">
            <div className="year-picker-button" onClick={() => setCurrentYear(currentYear => currentYear - 1)}>
                {"<"}
            </div>
            <div>{currentYear}</div>
            <div className="year-picker-button" onClick={() => setCurrentYear(currentYear => currentYear + 1)}>
                {">"}
            </div>
        </div>
    );
};
