import React from "react";
import {isScreenSizeOfTypes, ScreenSize} from "../EXCenterHelper";

export const extractSectionIdFromPath = location => {
    const parts = location.pathname.split("/");
    const sectionId = parts[parts.length - 2];
    if (sectionId === "sections") {
        return parts[parts.length - 1];
    }

    return sectionId;
};

export const isSmallScreen = screenSize => isScreenSizeOfTypes([ScreenSize.Mobile, ScreenSize.Tablet], screenSize);

export const hasDescription = sectionDesignInfo => {
    return sectionDesignInfo.description && sectionDesignInfo.description.enabled;
};

export const getImageProps = sectionDesignInfo => {
    let imageProps = {};
    if (sectionDesignInfo.coverPhoto) {
        imageProps.backgroundSize = "cover";
        imageProps.backgroundPosition = "center";
        imageProps.backgroundRepeat = "no-repeat";
        imageProps.backgroundImage = `url(${sectionDesignInfo.coverPhoto})`;
    }
    return imageProps;
};
