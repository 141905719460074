import {ProviderDealerTypes} from "../admin/upload/AdminUploadUtils";
import {getInvoicePrice} from "../utils/calculationUtils";
import {VAT} from "../consts.js";

export function getInvoicePriceByProviderId(price, commission, providerId, providers, forceVAT = false, vat = VAT) {
    const provider = providers.find(provider => provider.providerId === providerId);
    const isExemptDealerType = provider?.dealerType === ProviderDealerTypes.exempt;
    if (forceVAT) {
        return getInvoicePrice(price, commission, isExemptDealerType ?? false, vat);
    }
    return getInvoicePrice(price, commission, true, vat);
}
