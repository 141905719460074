import React, {useEffect, useState} from "react";
import {Checkbox, Form, Switch} from "antd";
import {defaultPricingTable, getDefaultPricingTableCell} from "../AdminUploadUtils";
import {
    PriceTypes,
    initializeEmptyField,
    StepperPricingMultiInputController,
    PriceTypesToHebrew
} from "../PriceSelectorHelper";
import "./provider-price-by-people-selector.css";
import {SmartSwitch} from "../../../components/SmartSwitch";

export const ProviderPriceByPeopleSelector = ({
    onChange,
    value,
    activeFields,
    onChosenTypeChange,
    chosenPriceType,
    isStepPricing,
    toggleStepPricing
}) => {
    const [pricingTable, setPricingTable] = useState(defaultPricingTable);
    const [chosenType, setChosenType] = useState(null);

    useEffect(() => {
        setChosenType(chosenPriceType);
    }, [chosenPriceType]);

    useEffect(() => {
        if (typeof value !== "undefined") {
            setPricingTable(value);
        } else {
            setPricingTable(defaultPricingTable);
        }
    }, [value]);

    const updateTable = (category, row, type, value) => {
        const newTable = {...pricingTable};

        if (!newTable[category]) {
            newTable[category] = initializeEmptyField();
        }

        newTable[category][row] = newTable[category][row] ?? getDefaultPricingTableCell();
        newTable[category][row][type] = value;
        setPricingTable(newTable);
        onChange(newTable);
    };

    const setChosenTypeHandler = type => {
        setChosenType(type);
        onChosenTypeChange(type);
    };

    const onCheckbox = (isChecked, type) => {
        if (isChecked) {
            setChosenTypeHandler(type);
        } else {
            setChosenTypeHandler(null);
        }
    };
    return (
        <div className="provider-price-by-people-selector">
            {activeFields.includes(chosenPriceType) ? (
                <>
                    <div className="price-type-container">
                        <label className="price-type-label">
                            תמחור לפי {PriceTypesToHebrew[chosenPriceType]} (כולל מע״מ)
                        </label>
                    </div>
                    <StepperPricingMultiInputController
                        pricingTable={pricingTable}
                        updateTable={updateTable}
                        type={chosenPriceType}
                        category={chosenPriceType}
                        placeholder={`מחיר לפי ${PriceTypesToHebrew[chosenPriceType]}`}
                    />
                </>
            ) : null}

            <div className="switch-container">
                <label style={{fontSize: 16, color: "var(--secondary-color)", fontWeight: "bold"}}>
                    תמחור על פי מדרגות
                </label>

                <SmartSwitch
                    checked={isStepPricing}
                    onChange={checked => toggleStepPricing(checked)}
                    checkedText="שוליות"
                    unCheckedText="כלליות"
                />
            </div>
        </div>
    );
};
