import React from "react";

import {dateTimeRangeFormat} from "../../../utils/DateUtils.jsx";
import "../../order-information-card.css";
import {getStatusColor, getStatusTranslation} from "../../../marketplace/MarketplaceUtils.jsx";
import {OrderPricingInformation} from "./OrderPricingInformation.jsx";

export const OrderInformationBillSection = ({eventInfo, additionalInfo}) => {
    return (
        <div className="order-information-card-section order-information-card-bill-section">
            <OrderPricingInformation eventInfo={eventInfo} />

            <div className="order-information-card-break-line" />
            {additionalInfo.map((info, index) => (
                <span className="order-information-card-additional-info-line" key={index}>
                    * {info}
                </span>
            ))}
            <div className="order-information-card-break-line" />
            <div className="order-information-card-billing-end-line">
                <span className="order-information-card-billing-end-line-info">
                    <span>{"סטטוס הזמנה:"}</span>
                    <span style={{color: getStatusColor(eventInfo.status)}}>
                        {getStatusTranslation(eventInfo.status)}
                    </span>
                </span>
                <span className="order-information-card-billing-end-line-info">
                    <span>{"מועד שליחת ההזמנה:"}</span>
                    <span style={{color: "var(--standard-text-color)"}}>
                        {dateTimeRangeFormat(eventInfo.createdAt)}
                    </span>
                </span>
            </div>
        </div>
    );
};
