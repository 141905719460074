import React from "react";
import {SearchItemType} from "../MarketplaceFilter/buttonsFilter/consts.js";
import {MarketplaceCategoryItem} from "./MarketplaceCategoryItem.jsx";
import {MarketplaceProviderItem} from "./MarketplaceProviderItem.jsx";

export const getMarketplaceFactoryItem = (itemType, content, extraProps) => {
    switch (itemType) {
        case SearchItemType.providers:
            return <MarketplaceProviderItem content={content} log={extraProps.log} />;
        default:
            return (
                <MarketplaceCategoryItem
                    content={content}
                    onContentClick={extraProps.onContentClick}
                    onNewTab={extraProps.onNewTab}
                    searchText={extraProps.searchText}
                    log={extraProps.log}
                    showLikeButton={extraProps.showLikeButton}
                />
            );
    }
};
