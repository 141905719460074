import {toEventsWithDate} from "./EventUtil";

export const organizeContent = (contents, events, term) => {
    return contents
        .filter(item => {
            if (!term) {
                return true;
            }

            const text = `
                ${item.sessionName} 
                ${item.productName || ""} 
                ${item.productDescription || ""} 
                ${item.providerFirstName || ""}
                ${item.providerLastName || ""}
                ${item.providerEmail || ""}
                ${item.providerPhone || ""}
                ${item.tags.map(({name}) => name).join(" ")}
                `;

            return text.toLowerCase().indexOf(term) >= 0;
        })
        .concat(toEventsWithDate(events, term))
        .reduce((byCategory, item) => {
            if (item.event) {
                const tagName = item.event.serviceName || "Internal";
                if (byCategory[tagName]) {
                    byCategory[tagName].push(item);
                } else {
                    byCategory[tagName] = [item];
                }
            } else {
                item.tags.forEach(({name}) => {
                    if (byCategory[name]) {
                        byCategory[name].push(item);
                    } else {
                        byCategory[name] = [item];
                    }
                });
            }
            return byCategory;
        }, {});
};
