import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Collapse, Form} from "antd";
import {isEmpty, set, get} from "lodash";
import classNames from "classnames";
import moment from "moment";
import {EditOutlined} from "@ant-design/icons";
import {BOOKING_ERROR_TO_PANEL, BOOKING_TO_PANEL_COLORS} from "./MarketplaceBooking/consts";
import {expandIconController, getOrderTip, MarketplaceSeparatorLine} from "./MarketplaceHelper";
import {OrderFormErrorLabel} from "./OrderFormErrorLabel";
import {OrderLocationInput} from "./OrderLocationInput";
import {FoodRequests} from "./ProductsFilterModal/consts";
import {ChooseContactModal} from "./OrderLocationInput/ChooseContactModal";
import {OrderFormTimePanel} from "./OrderFormTimePanel/OrderFormTimePanel.jsx";
import {OrderFormModularProductsPanel} from "./OrderFormModularProductsPanel";
import {
    CategoryToWhenSectionComment,
    CategoryToWhenSectionTitle,
    deliveryDefaultTimeComment,
    OrderFormPanelKeys
} from "./consts";
import {
    MainServices,
    ProductUpgradeOptionsAmountType,
    ProductUpgradeOptionsAmountTypeToName,
    ValidateBookingError
} from "../consts.js";
import {Images} from "../images/Images";
import {FormInputV2} from "../components/form";
import {SimpleUploader} from "../components";
import {TrashIcon} from "../icons";
import {
    getDefaultPriceTypeMaximum,
    getDefaultPriceTypeMinimumAmount,
    getDefaultTypeToShow,
    typeToPluralHebrewName,
    PLACE_ERROR,
    WARN_LEVEL,
    ERROR_LEVEL,
    TIME_ERROR
} from "./MarketplaceUtils";
import OrderFormTippingPanel from "./OrderFormTippingPanel";
import {MarketplaceBookingContext} from "./MarketplaceBooking/MarketplaceBookingContext";
import {getMergedDeliveryOptions} from "./MarketplaceBooking/utils";
import {DEFAULT_DELIVERY_ARRIVING_TIME_RANGE} from "../marketplace/MarketplaceUtils";
import {AllPossibleRequest} from "../admin/upload/AdminUploadUtils";
import {StepperParagraphInput} from "../wizards/components/StepperParagraphInput";
import {createUrlWithoutTransformations} from "../utils/cloudinaryUtils";
import {NOOP} from "../utils/NOOP";
import {openWindow} from "../http/WindowOpener";
import {momentHebrewFormat} from "../utils/DateFormat";
import "./MarketplaceBooking/marketplace-booking.css";
import {PriceTypes} from "../admin/upload/PriceSelectorHelper";
import {AppContext} from "../AppContext";
import common from "@well-b/well-b-shared";
import {StringBuilder} from "../AppUtils.js";
import classnames from "classnames";

const {Panel} = Collapse;

export const OrderForm = ({
    content,
    officeAddress,
    mainContact,
    formValues,
    setFormValues,
    updateFormValues = NOOP,
    extraFields,
    providerDetails,
    defaultActiveKey,
    onDefaultActiveKeyChange,
    allowPastDates = false,
    bookingError,
    calculateModularProductPrice,
    log = NOOP,
    adminMode = false,
    disabledFields
}) => {
    const [form] = Form.useForm();
    const [activeKey, setActiveKey] = useState([]);
    const [chooseExtraContactModalVisibility, setChooseExtraContactModalVisibility] = useState(false);
    const defaultTypeToShow = useMemo(() => getDefaultTypeToShow(content), [content]);
    const {offeredPrice, highlightOrderPanel} = useContext(MarketplaceBookingContext);
    const {me} = useContext(AppContext);

    useEffect(() => {
        if (defaultActiveKey) {
            setActiveKey(defaultActiveKey);
        }
    }, [defaultActiveKey]);

    const contactView = useMemo(() => {
        const extraContactName = formValues?.extraContactInfo?.contactName;
        const extraContactOrder = formValues?.extraContactInfo?.contactOrder;

        let title = extraContactName || mainContact;
        if (extraContactOrder?.id === "mainly") {
            title = `${extraContactName}, ${mainContact}`;
        } else if (extraContactOrder?.id === "secondly") {
            title = `${mainContact}, ${extraContactName}`;
        }
        return <span>{title}</span>;
    }, [mainContact, formValues.extraContactInfo]);

    const deliveryOptions = useMemo(
        () => getMergedDeliveryOptions(providerDetails, content),
        [providerDetails?.deliveryOptions, content?.deliveryOptions]
    );

    const selectedProductsNames = useMemo(() => {
        return formValues?.modularProducts?.reduce((acc, subProduct) => {
            if (subProduct?.amount > 0) {
                return `${acc}${acc === "" ? "" : ","} ${subProduct.amount} ${subProduct.name}`;
            }

            return acc;
        }, "");
    }, [formValues.modularProducts]);

    const onActiveKeyChange = useCallback(
        key => {
            setActiveKey(key);
            onDefaultActiveKeyChange && onDefaultActiveKeyChange(key);
        },
        [onDefaultActiveKeyChange]
    );

    const [specialRequests, setSpecialRequests] = useState([]);

    const onFormValueChange = useCallback(
        (field, val) => {
            log("Order Form value change", {field, val});

            if (updateFormValues !== NOOP) {
                updateFormValues(field, val);
            } else {
                setFormValues(prevValues => set({...prevValues}, field, val));
            }
        },
        [updateFormValues, setFormValues, formValues]
    );

    const toggleSpecialRequest = useCallback(
        request => {
            const {requests} = formValues;

            if (!requests) {
                onFormValueChange("requests", [request]);
                return;
            }

            const isRequestToggle = requests.some(({id}) => id === request.id);

            onFormValueChange(
                "requests",
                isRequestToggle ? requests.filter(({id}) => request.id !== id) : [...requests, request]
            );
        },
        [formValues.requests, onFormValueChange]
    );

    const toggleUpgradeOption = useCallback(
        option => {
            const {upgradeOptions} = formValues;

            const optionToModify = {
                ...option,
                ...(option.type === ProductUpgradeOptionsAmountType.byUnit ? {amount: 1} : {})
            };

            if (!upgradeOptions?.options) {
                onFormValueChange("upgradeOptions.options", [optionToModify]);
                return;
            }

            const options = upgradeOptions.options;

            const isOptionToggle = options.some(({name}) => name === option.name);

            onFormValueChange(
                "upgradeOptions.options",
                isOptionToggle ? options.filter(({name}) => option.name !== name) : [...options, optionToModify]
            );
        },
        [formValues.upgradeOptions?.options, onFormValueChange]
    );

    const addUpgradeOptionsFile = useCallback(
        file => {
            const {upgradeOptions} = formValues;

            const newFiles = [...(upgradeOptions?.files ?? []), file];

            onFormValueChange("upgradeOptions.files", newFiles);
        },
        [formValues.upgradeOptions?.files, onFormValueChange]
    );

    const removeUpgradeOptionsFile = useCallback(
        fileId => {
            const {files} = formValues.upgradeOptions;
            const newFiles = files.filter(file => file.fileId !== fileId);
            onFormValueChange("upgradeOptions.files", newFiles);
        },
        [formValues.upgradeOptions?.files, onFormValueChange]
    );

    const onSpecialRequestAmountChange = useCallback(
        (newRequest, fieldName, idKey) => {
            const requests = get(formValues, fieldName);

            const oldRequestIndex = requests.findIndex(req => req[idKey] === newRequest[idKey]);
            const newRequests = [...requests];

            if (oldRequestIndex === -1) {
                newRequests.push(newRequest);
            } else {
                newRequests[oldRequestIndex] = newRequest;
            }

            onFormValueChange(fieldName, newRequests);
        },
        [formValues, onFormValueChange]
    );

    useEffect(() => {
        setSpecialRequests(() => {
            if (content?.presentation?.length > 1) {
                return AllPossibleRequest.filter(
                    ({id}) => content.foodRequests.includes(id) || content.presentation.includes(id)
                );
            } else {
                return AllPossibleRequest.filter(({id}) => content.foodRequests.includes(id));
            }
        });
    }, [content]);

    const getPanelClassName = useCallback(
        panelKey =>
            classNames("marketplace-booking-collapse", {
                panelError:
                    BOOKING_ERROR_TO_PANEL[bookingError] === panelKey &&
                    highlightOrderPanel?.id === panelKey &&
                    highlightOrderPanel?.color === BOOKING_TO_PANEL_COLORS.ERROR,
                panelRedirect:
                    highlightOrderPanel?.id === panelKey &&
                    highlightOrderPanel?.color === BOOKING_TO_PANEL_COLORS.REDIRECT
            }),
        [bookingError, highlightOrderPanel]
    );

    const deliveryArrivingTimeRange = useMemo(() => {
        return deliveryOptions?.arrivingTimeRange ?? DEFAULT_DELIVERY_ARRIVING_TIME_RANGE;
    }, [deliveryOptions?.arrivingTimeRange]);

    const deliveryMaxTime = useMemo(() => {
        if (!formValues?.time || !formValues?.date) {
            return null;
        }

        const selectedTime = moment(formValues?.date).set({
            hour: formValues?.time?.hours(),
            minute: formValues?.time?.minutes()
        });

        return moment(selectedTime).add(deliveryArrivingTimeRange, "hours");
    }, [formValues?.date, formValues?.time, deliveryArrivingTimeRange]);

    const timeTitle = useCallback(
        (fromDate, fromTime) => {
            if (!fromDate || !fromTime) {
                return CategoryToWhenSectionComment[content?.services?.[0]] ?? deliveryDefaultTimeComment;
            }

            const formattedTime = moment(fromTime).format("HH:mm");
            const formattedDate = moment(fromDate).format("DD/MM/YYYY");
            const endTime = deliveryMaxTime ? deliveryMaxTime.format("HH:mm") : null;
            const endDate = deliveryMaxTime ? deliveryMaxTime.format("DD/MM/YYYY") : null;
            const isStartAndEndSame = endDate === formattedDate;

            const deliveryTitle = new StringBuilder()
                .append(content?.services?.[0] === MainServices.WORKSHOPS ? "הפעילות תחל" : "המוצר יסופק")
                .append(deliveryMaxTime && !isStartAndEndSame ? "מתאריך" : "בתאריך")
                .append(formattedDate)
                .append("בשעה")
                .append(formattedTime);

            if (deliveryMaxTime) {
                deliveryTitle.append("עד");
                if (!isStartAndEndSame) {
                    deliveryTitle.append(`${endDate},`);
                }
                deliveryTitle.append(endTime);
            }
            return deliveryTitle.toString();
        },
        [deliveryMaxTime, content]
    );

    return (
        <Form
            form={form}
            className="order-form"
            fields={Object.keys(formValues).map(key => ({
                name: [key],
                value: formValues[key]
            }))}>
            <MarketplaceSeparatorLine />
            <OrderLocationInput
                content={content}
                providerDetails={providerDetails}
                officeAddress={officeAddress}
                mainContact={mainContact}
                onValueChange={onFormValueChange}
                floor={formValues?.floor}
                chosenAddress={formValues?.chosenAddress || formValues?.address}
                chosenActivityLocation={formValues?.chosenActivityLocation}
                employeesExcel={formValues?.employeesExcel}
                singleEmployeeDetails={formValues?.singleEmployeeDetails}
                homeDeliveryUploadMethod={formValues?.homeDeliveryUploadMethod}
                onActiveKeyChange={onActiveKeyChange}
                activeKey={activeKey}
                error={formValues?.errors?.[PLACE_ERROR]}
                bookingError={bookingError}
                log={log}
                isDisabled={disabledFields?.includes(OrderFormPanelKeys.Location)}
            />
            <MarketplaceSeparatorLine />
            <Collapse
                ghost={true}
                accordion={true}
                className={getPanelClassName(OrderFormPanelKeys.Time)}
                expandIconPosition="left"
                activeKey={activeKey}
                onChange={onActiveKeyChange}
                expandIcon={({isActive}) => expandIconController(isActive)}>
                <Panel
                    key={OrderFormPanelKeys.Time}
                    showArrow={true}
                    forceRender={true}
                    header={
                        <OrderFormPanelHeader
                            title={new StringBuilder()
                                .append("📅")
                                .append(`${CategoryToWhenSectionTitle[content?.services?.[0]] ?? "מתי"}?`)
                                .toString()}
                            subTitle={
                                <>
                                    <OrderFormErrorLabel error={formValues?.errors?.[TIME_ERROR]} />
                                    {timeTitle(formValues.date, formValues.time)}
                                </>
                            }
                            isActive={!!(formValues.time && formValues.date)}
                        />
                    }>
                    <OrderFormTimePanel
                        Panel={Panel}
                        product={content}
                        formValues={formValues}
                        providerDetails={providerDetails}
                        deliveryOptions={deliveryOptions}
                        onFormValueChange={onFormValueChange}
                        allowPastDates={allowPastDates}
                        deliveryMaxTime={deliveryMaxTime}
                    />
                </Panel>
            </Collapse>
            <MarketplaceSeparatorLine />
            <Collapse
                ghost={true}
                accordion={true}
                className={getPanelClassName(OrderFormPanelKeys.Amount)}
                expandIconPosition="left"
                activeKey={activeKey}
                onChange={onActiveKeyChange}
                expandIcon={({isActive}) => expandIconController(isActive)}>
                {
                    <Panel
                        key={OrderFormPanelKeys.Amount}
                        showArrow={true}
                        header={
                            <div className="marketplace-booking-amount-choose">
                                {content.chosenPriceType === PriceTypes.ByModular ? (
                                    <>
                                        <div className="marketplace-booking-amount-choose-title">🧑‍🤝‍🧑 הרכב החבילה</div>
                                        <div
                                            className="marketplace-booking-amount-choose-subtitle"
                                            data-color-bold={!!formValues?.modularProducts}>
                                            {selectedProductsNames !== ""
                                                ? selectedProductsNames ?? "בחרו את המוצרים הרצויים"
                                                : "לא נבחרו מוצרים"}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="marketplace-booking-amount-choose-title">
                                            🧑‍🤝‍🧑 כמה {typeToPluralHebrewName(defaultTypeToShow)}?
                                        </div>

                                        <div
                                            className="marketplace-booking-amount-choose-subtitle"
                                            data-color-bold={!!formValues.amount}>
                                            {formValues.amount
                                                ? `${formValues.amount} ${typeToPluralHebrewName(defaultTypeToShow)}`
                                                : `לבחירת כמות ה${typeToPluralHebrewName(
                                                      defaultTypeToShow
                                                  )} הרצויה (מינימום ${getDefaultPriceTypeMinimumAmount(content)}
                                        ${
                                            defaultTypeToShow === "byGroup" && getDefaultPriceTypeMaximum(content)
                                                ? ` מקסימום ${getDefaultPriceTypeMaximum(content)}`
                                                : ""
                                        })`}

                                            {!common.isProductActive(content, providerDetails, me.companyId) && (
                                                <span className="non-active-product-error"> אזל מהמלאי</span>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        }>
                        {content.chosenPriceType === PriceTypes.ByModular ? (
                            <>
                                <OrderFormModularProductsPanel
                                    onChange={values => onFormValueChange("modularProducts", values)}
                                    showProviderSubProductsImages={providerDetails?.showProviderSubProductsImages}
                                    calculateModularProductPrice={calculateModularProductPrice}
                                    subProducts={formValues?.modularProducts ?? []}
                                    log={log}
                                    adminMode={adminMode}
                                />

                                <div style={{marginTop: 10}}>
                                    {calculateModularProductPrice < content?.pricingTable?.byModular?.minPrice && (
                                        <OrderFormErrorLabel
                                            error={{
                                                message: `המחיר המינימלי להרכבת החבילה הוא ${content?.pricingTable?.byModular?.minPrice} ₪`,
                                                level: ERROR_LEVEL
                                            }}
                                        />
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <Form.Item
                                    name="amount"
                                    rules={[
                                        {
                                            required: true,
                                            validator: (_, value) => {
                                                let error;

                                                if (!value || value < getDefaultPriceTypeMinimumAmount(content)) {
                                                    error = ValidateBookingError.MIN_PRODUCT_AMOUNT;
                                                } else if (value > getDefaultPriceTypeMaximum(content)) {
                                                    error = ValidateBookingError.MAX_PRODUCT_AMOUNT;
                                                }
                                                return error ? Promise.reject(error) : Promise.resolve();
                                            }
                                        }
                                    ]}>
                                    <FormInputV2
                                        disabled={
                                            !common.isProductActive(content, providerDetails, me.companyId) &&
                                            !adminMode
                                        }
                                        type="number"
                                        placeholder={"הזן מספר"}
                                        style={{width: "150px", marginRight: "10px"}}
                                        onWheel={e => e.target.blur()}
                                        onChange={e => {
                                            const amount = Number(e.target.value);
                                            onFormValueChange("amount", amount.toFixed(0));
                                        }}
                                    />
                                </Form.Item>
                                {formValues?.amount > deliveryOptions?.itemLimit && (
                                    <OrderFormErrorLabel
                                        error={{
                                            message:
                                                "יש לשים לב כי כמות זו גדולה מהכמות שהספק הגדיר לשילוח מהיר. עם זאת בקשתכם תועבר לספק לבדיקת זמן ההתראה הנדרש - ניצור איתכם קשר ונעדכן בהתאם.",
                                            level: WARN_LEVEL
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </Panel>
                }
            </Collapse>
            <MarketplaceSeparatorLine />
            {(!isEmpty(specialRequests) && content.chosenPriceType !== PriceTypes.ByModular) || adminMode ? (
                <div>
                    <Collapse
                        ghost={true}
                        accordion={true}
                        className={getPanelClassName(OrderFormPanelKeys.Requests)}
                        expandIconPosition="left"
                        activeKey={activeKey}
                        onChange={onActiveKeyChange}
                        expandIcon={({isActive}) => expandIconController(isActive)}>
                        <Panel
                            key={OrderFormPanelKeys.Requests}
                            showArrow={true}
                            header={
                                <OrderFormPanelHeader
                                    title="🙏 צרכים ספציפיים?"
                                    isActive={formValues.requests && formValues.requests.length}
                                    subTitle={
                                        formValues.requests && formValues.requests.length
                                            ? formValues.requests
                                                  .map(({title, amount}) => `${title} ${amount ? `(${amount})` : ""}`)
                                                  .join(", ")
                                            : "אנא ציינו אם ישנם צרכים מיוחדים שברצונכם להדגיש, ופרטו כמויות במידת הצורך"
                                    }
                                />
                            }>
                            <Form.Item name="requests">
                                {specialRequests.map(request => {
                                    const currentRequest =
                                        formValues.requests && formValues.requests.find(({id}) => id === request.id);

                                    return (
                                        <div key={request.id} className="booking-special-request">
                                            <div style={{display: "flex", flexDirection: "row"}}>
                                                <div
                                                    style={{width: "30px"}}
                                                    onClick={() => toggleSpecialRequest(request)}>
                                                    {currentRequest ? (
                                                        <img
                                                            style={{
                                                                cursor: "pointer",
                                                                width: "20px",
                                                                height: "20px"
                                                            }}
                                                            src={Images.marketplaceBookingCheckboxChecked}
                                                            alt="booking-check-icon"
                                                        />
                                                    ) : (
                                                        <img
                                                            style={{
                                                                cursor: "pointer",
                                                                width: "20px",
                                                                height: "20px"
                                                            }}
                                                            src={Images.marketplaceBookingCheckboxEmpty}
                                                            alt="booking-empty-icon"
                                                        />
                                                    )}
                                                </div>
                                                <div
                                                    style={{
                                                        fontSize: "16px",
                                                        color: "var(--secondary-color)",
                                                        fontWeight: "bold",
                                                        width: "180px"
                                                    }}>
                                                    {request.title}
                                                </div>
                                            </div>
                                            {FoodRequests.some(({id}) => id === request.id) ? (
                                                <FormInputV2
                                                    type="number"
                                                    disabled={!currentRequest}
                                                    defaultValue={currentRequest?.amount}
                                                    placeholder="הזן מספר"
                                                    className="booking-special-request-amount-input"
                                                    onWheel={e => e.target.blur()}
                                                    onChange={({target}) =>
                                                        onSpecialRequestAmountChange(
                                                            {
                                                                ...request,
                                                                amount: target.value
                                                            },
                                                            "requests",
                                                            "id"
                                                        )
                                                    }
                                                />
                                            ) : null}
                                        </div>
                                    );
                                })}
                            </Form.Item>
                        </Panel>
                    </Collapse>
                    <MarketplaceSeparatorLine />
                </div>
            ) : null}
            {content.upgradeOptions?.options?.length ? (
                <div>
                    <Collapse
                        ghost={true}
                        accordion={true}
                        className={getPanelClassName(OrderFormPanelKeys.UpgradeOptions)}
                        expandIconPosition="left"
                        activeKey={activeKey}
                        onChange={onActiveKeyChange}
                        expandIcon={({isActive}) => expandIconController(isActive)}>
                        {
                            <Panel
                                key={OrderFormPanelKeys.UpgradeOptions}
                                showArrow={true}
                                header={
                                    <OrderFormPanelHeader
                                        title="👨‍🎤 אפשרויות שדרוג"
                                        isActive={!!formValues.upgradeOptions?.options?.length}
                                        subTitle={
                                            formValues.upgradeOptions?.options?.length
                                                ? formValues.upgradeOptions?.options?.map(({name}) => name).join(", ")
                                                : "באפשרותכם לשדרג את המוצר לפי הצורך :) תוספת המחיר המעודכן תעודכן בהתאם לבחירות שלכם"
                                        }
                                    />
                                }>
                                <Form.Item>
                                    {content?.upgradeOptions?.options.map(option => {
                                        const formOption = formValues.upgradeOptions?.options?.find(
                                            ({name}) => name === option.name
                                        );
                                        return (
                                            <div
                                                key={option.name}
                                                className={`booking-upgrade-options ${
                                                    formOption ? "active-option" : ""
                                                }`}>
                                                <div onClick={() => toggleUpgradeOption(option)}>
                                                    {formOption ? (
                                                        <img
                                                            style={{
                                                                cursor: "pointer",
                                                                width: "20px",
                                                                height: "20px"
                                                            }}
                                                            src={Images.marketplaceBookingCheckboxChecked}
                                                            alt="booking-check-icon"
                                                        />
                                                    ) : (
                                                        <img
                                                            style={{
                                                                cursor: "pointer",
                                                                width: "20px",
                                                                height: "20px"
                                                            }}
                                                            src={Images.marketplaceBookingCheckboxEmpty}
                                                            alt="booking-empty-icon"
                                                        />
                                                    )}
                                                </div>
                                                <div className="booking-upgrade-option-title">
                                                    <span>{option.name}</span>
                                                    <span> | </span>
                                                    <span>
                                                        {option.price}
                                                        {" ₪ "}
                                                        {ProductUpgradeOptionsAmountTypeToName[option.type]}
                                                    </span>
                                                </div>

                                                <span>
                                                    {ProductUpgradeOptionsAmountTypeToName[option.type] ===
                                                    ProductUpgradeOptionsAmountTypeToName.byUnit ? (
                                                        <FormInputV2
                                                            type="number"
                                                            disabled={!formOption}
                                                            value={formOption?.amount}
                                                            placeholder="הזן כמות"
                                                            className="booking-upgrade-options-amount-input"
                                                            onWheel={e => e.target.blur()}
                                                            onChange={({target}) =>
                                                                onSpecialRequestAmountChange(
                                                                    {
                                                                        ...option,
                                                                        amount: target.value
                                                                    },
                                                                    "upgradeOptions.options",
                                                                    "name"
                                                                )
                                                            }
                                                        />
                                                    ) : null}
                                                </span>
                                            </div>
                                        );
                                    })}
                                    {content?.upgradeOptions?.enableUploadFiles ? (
                                        <div className="order-form-upgrade-options-file-line">
                                            <span>טעינת קבצים רלוונטים (לוגו, גרפיקות וכו׳)</span>
                                            <div className="order-form-upgrade-options-files-container">
                                                {formValues?.upgradeOptions?.files?.map(
                                                    ({fileId, fileName, fileUrl}) => (
                                                        <span key={fileId} className="order-form-upgrade-options-file">
                                                            <span
                                                                onClick={() =>
                                                                    openWindow(createUrlWithoutTransformations(fileUrl))
                                                                }>
                                                                {fileName}
                                                            </span>
                                                            <TrashIcon
                                                                onClick={() => removeUpgradeOptionsFile(fileId)}
                                                            />
                                                        </span>
                                                    )
                                                )}
                                            </div>
                                            <SimpleUploader accept="*" onFinish={addUpgradeOptionsFile}>
                                                העלת קובץ
                                            </SimpleUploader>
                                        </div>
                                    ) : null}
                                </Form.Item>
                            </Panel>
                        }
                    </Collapse>
                    <MarketplaceSeparatorLine />
                </div>
            ) : null}
            {content.participantsExtraInfo ? (
                <div>
                    <Collapse
                        ghost={true}
                        accordion={true}
                        className={getPanelClassName(OrderFormPanelKeys.ParticipantsExtraInfo)}
                        expandIconPosition="left"
                        activeKey={activeKey}
                        onChange={onActiveKeyChange}
                        expandIcon={({isActive}) => expandIconController(isActive)}>
                        {
                            <Panel
                                key={OrderFormPanelKeys.ParticipantsExtraInfo}
                                showArrow={true}
                                header={
                                    <OrderFormPanelHeader
                                        title="☝️ שאלות של הספק אליכם"
                                        isActive={!!content.participantsExtraInfo}
                                        subTitle={content.participantsExtraInfo}
                                    />
                                }>
                                <Form.Item name="extraInfo">
                                    <StepperParagraphInput
                                        onChange={e => onFormValueChange("extraInfo", e.target.value)}
                                        style={{
                                            marginTop: 10,
                                            width: "600px"
                                        }}
                                        maxLength={2000}
                                        autoSize={{
                                            maxRows: 8,
                                            minRows: 8
                                        }}
                                    />
                                </Form.Item>
                            </Panel>
                        }
                    </Collapse>
                    <MarketplaceSeparatorLine />
                </div>
            ) : null}
            <div>
                <Collapse
                    ghost={true}
                    accordion={true}
                    className={getPanelClassName(OrderFormPanelKeys.ExtraRequests)}
                    expandIconPosition="left"
                    activeKey={activeKey}
                    onChange={onActiveKeyChange}
                    expandIcon={({isActive}) => expandIconController(isActive)}>
                    {
                        <Panel
                            key={OrderFormPanelKeys.ExtraRequests}
                            showArrow={true}
                            header={
                                <OrderFormPanelHeader
                                    title={new StringBuilder()
                                        .append("✍️")
                                        .append("הערות לבית העסק")
                                        .append(content?.brandName || content?.engBrandName ? "-" : "")
                                        .append(
                                            content?.brandName || content?.engBrandName
                                                ? content?.brandName || content?.engBrandName
                                                : ""
                                        )
                                        .toString()}
                                    isActive={!isEmpty(formValues?.extraRequests)}
                                    subTitle={
                                        !isEmpty(formValues?.extraRequests)
                                            ? formValues?.extraRequests
                                            : "במידה ויש לכם שאלות או דגשים לבית העסק ממוזמנים לכתוב לנו כאן"
                                    }
                                />
                            }>
                            <StepperParagraphInput
                                value={formValues?.extraRequests}
                                onChange={e => onFormValueChange("extraRequests", e.target.value)}
                                style={{
                                    marginTop: 10,
                                    width: "600px"
                                }}
                                maxLength={2000}
                                autoSize={{
                                    maxRows: 8,
                                    minRows: 8
                                }}
                            />
                        </Panel>
                    }
                </Collapse>
                <MarketplaceSeparatorLine />
            </div>
            <div>
                <Collapse
                    ghost={true}
                    accordion={true}
                    className={getPanelClassName(OrderFormPanelKeys.deliveryRequests)}
                    expandIconPosition="left"
                    activeKey={activeKey}
                    onChange={onActiveKeyChange}
                    expandIcon={({isActive}) => expandIconController(isActive)}>
                    {
                        <Panel
                            key={OrderFormPanelKeys.deliveryRequests}
                            showArrow={true}
                            header={
                                <OrderFormPanelHeader
                                    title={new StringBuilder().append("🚚").append("הערות לשליח").toString()}
                                    isActive={!isEmpty(formValues.deliveryRequests)}
                                    subTitle={
                                        !isEmpty(formValues?.deliveryRequests)
                                            ? formValues?.deliveryRequests
                                            : "מוזמנים לכתוב דגשים לשליח - לא ניתן להתחייב על זמן הגעה בתוך הטווח אספקה"
                                    }
                                />
                            }>
                            <StepperParagraphInput
                                value={formValues?.deliveryRequests}
                                onChange={e => onFormValueChange("deliveryRequests", e.target.value)}
                                style={{
                                    marginTop: 10,
                                    width: "600px"
                                }}
                                maxLength={2000}
                                autoSize={{
                                    maxRows: 8,
                                    minRows: 8
                                }}
                            />
                        </Panel>
                    }
                </Collapse>
                <MarketplaceSeparatorLine />
            </div>
            <div>
                <Collapse
                    ghost={true}
                    accordion={true}
                    className={getPanelClassName(OrderFormPanelKeys.ExtraContactInfo)}
                    expandIconPosition="left"
                    activeKey={activeKey}
                    onChange={onActiveKeyChange}
                    expandIcon={({isActive}) => expandIconController(isActive)}>
                    {
                        <Panel
                            key={OrderFormPanelKeys.ExtraContactInfo}
                            showArrow={true}
                            header={
                                <OrderFormPanelHeader
                                    title="📞️ איש קשר לתפעול ההזמנה"
                                    isActive={!isEmpty(formValues.extraContactInfo)}
                                    subTitle={
                                        !isEmpty(formValues?.extraContactInfo?.contactName)
                                            ? formValues?.extraContactInfo?.contactName
                                            : "במידה וברצונכם לשנות, להוסיף או להגדיר איש קשר נוסף להזמנה אנא הזינו את הפרטים שלו"
                                    }
                                />
                            }>
                            <Form.Item name="extraContactInfo">
                                <ChooseContactModal
                                    visible={chooseExtraContactModalVisibility}
                                    onClose={() => setChooseExtraContactModalVisibility(false)}
                                    values={formValues?.extraContactInfo}
                                    onSave={values => {
                                        onFormValueChange("extraContactInfo", values);
                                        setChooseExtraContactModalVisibility(false);
                                    }}
                                />
                                <div
                                    onClick={() => setChooseExtraContactModalVisibility(true)}
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        color: "#1260D1",
                                        cursor: "pointer"
                                    }}>
                                    <EditOutlined style={{marginLeft: 5}} />
                                    <span>{"איש קשר: "}</span>
                                    {contactView}
                                </div>
                            </Form.Item>
                        </Panel>
                    }
                </Collapse>
                <MarketplaceSeparatorLine />
            </div>
            {content?.allowTipping ? (
                <div>
                    <Collapse
                        ghost={true}
                        accordion={true}
                        className={getPanelClassName(OrderFormPanelKeys.Tipping)}
                        expandIconPosition="left"
                        activeKey={activeKey}
                        onChange={onActiveKeyChange}
                        expandIcon={({isActive}) => expandIconController(isActive)}>
                        <Panel
                            key={OrderFormPanelKeys.Tipping}
                            showArrow={true}
                            header={
                                <OrderFormPanelHeader
                                    title="🏢 תרצו להוסיף טיפ?"
                                    isActive={formValues.tipPercentage}
                                    subTitle={
                                        formValues?.tipPercentage
                                            ? `${formValues.tipPercentage}% (${getOrderTip(
                                                  offeredPrice,
                                                  formValues.tipPercentage
                                              )} ₪)`
                                            : "הטיפ יחושב כאחוז ממחיר המוצר"
                                    }
                                />
                            }>
                            <OrderFormTippingPanel formValues={formValues} onFormValueChange={onFormValueChange} />
                        </Panel>
                    </Collapse>
                    <MarketplaceSeparatorLine />
                </div>
            ) : null}
            {extraFields}
        </Form>
    );
};

export const OrderFormPanelHeader = ({title, error, subTitle, isActive}) => {
    return (
        <div className="order-form-panel-header-container">
            <div className="order-form-title">{title}</div>
            {error ? <OrderFormErrorLabel error={error} /> : null}
            <div
                className={classnames("booking-special-request-sum-title", {
                    "booking-special-request-sum-title-active": isActive
                })}>
                {subTitle}
            </div>
        </div>
    );
};
