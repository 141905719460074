import {Input, message, Select} from "antd";
import {
    AdminOrderSearchFields,
    AdminOrderSearchFieldsSettings,
    AdminOrderSearchFieldsToFieldId,
    AdminOrderSearchFieldsToHebrew,
    DefaultAdminOrderSearchField
} from "./consts.js";
import {SquareButton} from "../../components/index.jsx";
import {useCallback, useEffect, useState} from "react";
import {HttpClient} from "../../http/HttpClient.jsx";
import {StringBuilder} from "../../AppUtils.js";

export const SearchTopBar = ({
    onSearch,
    setSearchField,
    setSearchInput,
    searchInput,
    searchField,
    unDebouncedSearchInput
}) => {
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const shouldDisableSearch = AdminOrderSearchFieldsSettings[searchField]?.shouldBlockSearchButton;

    const fetchAutocompleteOptions = useCallback(async () => {
        if (searchField !== AdminOrderSearchFields.providerName || !searchInput) return;

        const params = new URLSearchParams({search: searchInput, page: "1", pageSize: "10"});
        const uri = `/admin/api/vendors?${params.toString()}`;

        const response = await HttpClient.safePost(uri, {
            projection: {
                provider: ["providerId", "businessName"],
                product: ["productId"]
            }
        });

        if (!response.error) {
            setAutoCompleteOptions(
                response.providers.map(provider => ({
                    value: provider.providerId,
                    label: provider.businessName
                }))
            );
        }
    }, [searchField, searchInput]);

    useEffect(() => {
        if (searchInput === unDebouncedSearchInput) {
            fetchAutocompleteOptions();
        }
    }, [fetchAutocompleteOptions, searchInput, unDebouncedSearchInput]);

    const handleAutocompleteSelect = useCallback(
        ({fieldId, searchInputId}) => {
            onSearch(searchInputId, fieldId);
        },
        [onSearch]
    );

    const handleSearch = useCallback(async () => {
        const mappedFieldId = AdminOrderSearchFieldsToFieldId[searchField];

        if (mappedFieldId) {
            switch (searchField) {
                case AdminOrderSearchFields.email:
                    const userResponse = await HttpClient.safePost("/admin/api/users/byField", {
                        searchInput,
                        searchField: mappedFieldId,
                        projection: ["userId"]
                    });

                    if (userResponse.error) {
                        message.error(
                            new StringBuilder("שגיאה בעת איתור משתמש").append(`(${userResponse.error})`).toString()
                        );
                        return;
                    }

                    onSearch(userResponse[0].userId, AdminOrderSearchFields.creatingUserId);
                    break;
                default:
                    message.error("החיפוש לא זמין לשדה הזה כרגע");
                    break;
            }
        } else {
            onSearch(searchInput, searchField);
        }
    }, [searchField, searchInput, onSearch]);

    const renderSearchInput = useCallback(
        shouldUseAutocomplete => {
            if (shouldUseAutocomplete) {
                return (
                    <Select
                        className="select-container"
                        showSearch
                        options={autoCompleteOptions}
                        showArrow
                        onSearch={setSearchInput}
                        onSelect={value => {
                            handleAutocompleteSelect({
                                fieldId: AdminOrderSearchFieldsToFieldId[searchField],
                                searchInputId: value
                            });
                        }}
                        inputValue={unDebouncedSearchInput}
                        placeholder={AdminOrderSearchFieldsToHebrew[searchField] ?? ""}
                        filterOption={false}
                    />
                );
            }

            return (
                <Input
                    className="select-container"
                    value={unDebouncedSearchInput}
                    onChange={e => setSearchInput(e.target.value)}
                    placeholder={AdminOrderSearchFieldsToHebrew[searchField] ?? ""}
                />
            );
        },
        [autoCompleteOptions, handleAutocompleteSelect, searchField, setSearchInput, unDebouncedSearchInput]
    );

    return (
        <div className="admin-orders-management-table-top-bar-container">
            <Select
                defaultValue={DefaultAdminOrderSearchField}
                className="select-container"
                value={searchField}
                onChange={setSearchField}>
                {Object.entries(AdminOrderSearchFields)
                    .filter(([key]) => !!AdminOrderSearchFieldsToHebrew[key])
                    .map(([key, value]) => (
                        <Select.Option key={key} value={value}>
                            {AdminOrderSearchFieldsToHebrew[key]}
                        </Select.Option>
                    ))}
            </Select>

            {renderSearchInput(AdminOrderSearchFieldsSettings[searchField]?.shouldShowDropdown)}

            <SquareButton disabled={!searchField || shouldDisableSearch} onClick={handleSearch}>
                חפש
            </SquareButton>
        </div>
    );
};
