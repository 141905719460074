import CallToActionModal from "../components/CallToActionModal";
import React from "react";
import {Typography} from "antd";
import {ModalTitle} from "../components/ModalTitle";

export const ContactUsThankYouModal = ({history, visible, onClose, me}) => {
    const Title = () => <ModalTitle title="Thank you!" />;

    const SubTitle = () => (
        <div style={{display: "flex", flexDirection: "column"}}>
            <Typography.Text style={{textAlign: "center", fontSize: 20, marginBottom: 10}}>
                We got your message and will be in touch soon.
            </Typography.Text>
            <Typography.Text style={{textAlign: "center", fontSize: 20, marginBottom: 20}}>
                {me.userId
                    ? me.type === "provider"
                        ? "Meanwhile, what about checking your upcoming sessions?"
                        : "Meanwhile, what about checking your upcoming sessions or scheduling a session with one of our professionals?"
                    : null}
            </Typography.Text>
        </div>
    );

    return (
        <CallToActionModal
            imageName="success"
            title={<Title />}
            closable={true}
            onClose={onClose}
            subTitle={<SubTitle />}
            actionText={me.userId ? (me.type === "company" ? "Back To Categories" : "Check Out Calendar") : null}
            actionHandler={
                me.userId
                    ? () =>
                          me.type === "company"
                              ? history.push("/dashboard/services")
                              : history.push("/dashboard/schedule")
                    : null
            }
            anotherActionText={me.type === "company" ? "Check Out Calendar" : void 0}
            anotherActionHandler={me.type === "company" ? () => history.push("/dashboard/schedule") : void 0}
            visible={visible}
        />
    );
};
