import React from "react";
import {HappyHoursLocationFilter} from "./happyHoursLocationFilter/HappyHoursLocationFilter.jsx";
import {GiftLocationFilter} from "./giftLocationFilter/GiftsLocationFilter.jsx";
import {BondingActivitiesLocationFilter} from "./boundingActivitiesLocationFilter/BondingActivitiesLocationFilter.jsx";
import {CategoryToLocationFilterText} from "./consts.js";
import "../../variables.css";
import {MainServices} from "../../../../consts.js";
import classnames from "classnames";

export const LocationFilterPopoverFactory = ({panel, addressName}) => {
    const component = (
        <div className="advanced-filter-segmented-label-container">
            <div className="advanced-filter-option-title">{CategoryToLocationFilterText[panel]}</div>
            <div className={classnames("advanced-filter-option-sub-title", {selected: !!addressName})}>
                {addressName ?? "עיר ורחוב"}{" "}
            </div>
        </div>
    );
    switch (panel) {
        case MainServices.PACKAGES:
            return <GiftLocationFilter>{component}</GiftLocationFilter>;
        case MainServices.WORKSHOPS:
            return <BondingActivitiesLocationFilter>{component}</BondingActivitiesLocationFilter>;
        default:
            return <HappyHoursLocationFilter>{component}</HappyHoursLocationFilter>;
    }
};
