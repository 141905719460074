import React, {useContext} from "react";
import {AppContext} from "../AppContext";
import {Modal, Form, Input, DatePicker} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import {PageTitleLabel} from "../components/PageTitle";
import {FormInput} from "../components/form";
import {StyledButton} from "../components/StyledButton";
import {CompanyBranchSelect, hasBranches} from "./CompanyBranchSelect";
import moment from "moment-timezone";

const EmployeeEditForm = ({employee, onClose, saving, onSave}) => {
    const {theme, me, company} = useContext(AppContext);
    const [form] = Form.useForm();

    return (
        <Form
            form={form}
            style={{width: "100%", maxWidth: 340, marginTop: 20}}
            onFinish={vals =>
                onSave(
                    Object.assign({}, employee, vals, {
                        birthday: vals.birthday ? vals.birthday.format("DD/MM/YYYY") : null
                    })
                )
            }
            initialValues={{
                ...employee,
                birthday: employee.birthday ? moment(employee.birthday, "DD/MM/YYYY") : void 0
            }}>
            <Form.Item name="firstName" rules={[{required: true, message: "Please enter first name."}]}>
                <FormInput placeholder="First name" />
            </Form.Item>
            <Form.Item name="lastName" rules={[{required: true, message: "Please enter last name."}]}>
                <FormInput placeholder="Last name" />
            </Form.Item>
            <Form.Item name="email">
                <FormInput disabled={true} placeholder="Email" />
            </Form.Item>
            <Form.Item
                name="phone"
                rules={[
                    {
                        validator: (_, value) => {
                            if (value && value.length < 8) {
                                return Promise.reject("Please enter valid number.");
                            } else {
                                return Promise.resolve();
                            }
                        }
                    }
                ]}>
                <FormInput type="number" placeholder="Phone (optional)" />
            </Form.Item>
            <Form.Item name="address">
                <FormInput placeholder="Address (optional)" />
            </Form.Item>
            <Form.Item name="birthday">
                <DatePicker
                    style={{width: "100%"}}
                    className="wb-date-picker"
                    format="DD/MM/YYYY"
                    showToday={false}
                    placeholder="Birthday (optional)"
                />
            </Form.Item>
            <Form.Item name="position">
                <FormInput placeholder="Position (optional)" />
            </Form.Item>
            {hasBranches(me, company) ? (
                <Form.Item
                    name="branch"
                    rules={[
                        {
                            required: true,
                            message: "Please select site."
                        }
                    ]}>
                    <CompanyBranchSelect branches={company.branches} />
                </Form.Item>
            ) : null}
            <Input.Group style={{display: "flex", justifyContent: "center", marginTop: 40}} compact>
                <StyledButton
                    onClick={onClose}
                    style={{
                        width: 160,
                        backgroundColor: "white !important",
                        color: "#2F3E83",
                        border: "1px solid #2F3E83",
                        borderRadius: 25
                    }}>
                    Cancel
                </StyledButton>
                <Form.Item>
                    <StyledButton
                        onClick={() => form.submit()}
                        loading={saving}
                        style={{
                            width: 160,
                            borderRadius: 25,
                            color: theme.textPrimary,
                            backgroundColor: theme.primary,
                            marginLeft: 10
                        }}>
                        Save
                    </StyledButton>
                </Form.Item>
            </Input.Group>
        </Form>
    );
};

export const CompanyEmployeeEdit = ({visible, employee, company, onClose, saving, onSave}) => {
    return (
        <Modal
            className="wb-modal"
            closable={true}
            onCancel={() => onClose()}
            destroyOnClose={true}
            bodyStyle={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 0
            }}
            open={visible}
            footer={null}
            closeIcon={<CloseOutlined style={{color: "#637381"}} />}>
            <PageTitleLabel>Edit employee</PageTitleLabel>
            {visible ? (
                <EmployeeEditForm employee={employee} onClose={onClose} saving={saving} onSave={onSave} />
            ) : null}
        </Modal>
    );
};
