import styled from "styled-components";
import {Input} from "antd";

const {Search} = Input;

export const SearchInput = styled(Search)`
    border: 1px solid #e8edf5;
    background-color: #fcfdfe;
    font-size: 16px;
    border-radius: 10px;
    height: 50px;
    display: flex;
    align-items: center;
    outline: none;
    box-shadow: none !important;
`;
