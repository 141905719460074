export class HttpError extends Error {
    statusCode = 0;
    extraInfo = null;

    constructor(message, statusCode, extraInfo) {
        super(message);
        this.statusCode = statusCode;
        this.extraInfo = extraInfo;
    }
}
