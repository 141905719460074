import React from "react";
import "../../order-information-card.css";
import {generateUpgradeOptions} from "../utils.jsx";
import {downloadFromUrl} from "../../../utils/downloadFromUrl.jsx";
import {createUrlWithoutTransformations} from "../../../utils/cloudinaryUtils.jsx";

export const OrderUpgradeOptions = ({eventInfo}) => {
    return (
        <>
            <div>{generateUpgradeOptions(eventInfo?.upgradeOptions)}</div>
            <div className="order-information-card-upgrade-option-files-line">
                {eventInfo?.upgradeOptions?.files?.map(({fileName, fileId, fileUrl}) => (
                    <span
                        className="order-information-card-upgrade-option-file"
                        key={fileId}
                        onClick={() => downloadFromUrl(createUrlWithoutTransformations(fileUrl), fileName)}>
                        {fileName}
                    </span>
                ))}
            </div>
        </>
    );
};
