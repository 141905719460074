import React, {useMemo} from "react";
import {Carousel} from "antd";
import {EventGalleryMediaItem} from "../../company/EventsGallary/EventGalleryMediaItem";
import {EventGalleryItem} from "../../company/EventsGallary/EventGalleryItem";
import {keyBy} from "lodash";

export const ImagesCarousel = ({
    images,
    carouselRef,
    carouselProps,
    history,
    products,
    userLikedItems,
    setUserLikedItems,
    log
}) => {
    const productsById = useMemo(() => keyBy(products, product => product.service.productId), [products]);

    return (
        <Carousel
            className="order-images-modal-carusel-container"
            variableWidth={true}
            centerMode={true}
            ref={carouselRef}
            slidesToShow={1}
            slidesToScroll={1}
            infinite={images?.length > 3}
            dots={false}
            swipeToSlide={true}
            {...carouselProps}>
            {images &&
                images.map(image => {
                    if (image.productId) {
                        const isLikedMedia = userLikedItems
                            .filter(({type}) => type === "orderImage")
                            .find(({targetId}) => targetId === image.id + ":" + image.productId);

                        return (
                            <EventGalleryItem
                                key={image.productId}
                                image={image}
                                history={history}
                                productId={image.productId}
                                product={productsById[image.productId]}
                                customImageClass={true}
                                like={isLikedMedia}
                                setLikeImages={setUserLikedItems}
                                log={log}
                            />
                        );
                    }

                    return <EventGalleryMediaItem mediaItem={image} />;
                })}
        </Carousel>
    );
};
