import {dateFormat} from "../utils/DateFormat";
import moment from "moment-timezone";
import {SYSTEM_DEFAULT_END_TIME, SYSTEM_DEFAULT_START_TIME} from "../data/defaults";

const byDate = (event, dateStr, fieldName) => {
    return event.byDate && event.byDate[dateStr] && event.byDate[dateStr][fieldName]
        ? event.byDate[dateStr][fieldName]
        : event[fieldName];
};

const timeRangeByDate = (event, dateStr) => {
    const {minTime = SYSTEM_DEFAULT_START_TIME, maxTime = SYSTEM_DEFAULT_END_TIME} = event;

    return event.timeRangeByDate && event.timeRangeByDate[dateStr]
        ? event.timeRangeByDate[dateStr]
        : {
              minTime,
              maxTime
          };
};

const notesByDate = (event, dateStr) => {
    return event.notesByDate && event.notesByDate[dateStr] ? event.notesByDate[dateStr] : event.notes;
};

const registeredEmployees = (event, dateStr) => {
    const registeredEmployeesForThisDate = byDate(event, dateStr, "registeredEmployees") || [];
    const registeredEmployeesToAllSessions = event.registeredEmployees || [];

    let perEmployee = registeredEmployeesToAllSessions.reduce((perEmp, reg) => {
        perEmp[reg.employeeId] = reg;
        return perEmp;
    }, {});

    perEmployee = registeredEmployeesForThisDate.reduce((perEmp, reg) => {
        perEmp[reg.employeeId] = reg;
        return perEmp;
    }, perEmployee);

    return Object.keys(perEmployee).map(employeeId => perEmployee[employeeId]);
};

export const ensureByDate = event => {
    event.byDate = event.byDate || {};
    return event;
};

export const eventByDate = (event, date) => {
    if (!date || !event || !event.eventId) return event;

    const today = moment(typeof date === "string" ? date : dateFormat(date), "YYYY-M-D").format("YYYY-MM-DD");

    const timeRange = timeRangeByDate(event, today);

    return {
        ...event,
        meetingLink: byDate(event, today, "meetingLink"),
        address: byDate(event, today, "address"),
        whoPays: byDate(event, today, "whoPays"),
        duration: byDate(event, today, "duration"),
        durationType: byDate(event, today, "durationType") || "min",
        location: byDate(event, today, "location"),
        breakSlots: byDate(event, today, "breakSlots"),
        maxParticipants: byDate(event, today, "maxParticipants"),
        registeredEmployees: registeredEmployees(event, today),
        minTime: timeRange.minTime,
        maxTime: timeRange.maxTime,
        notes: notesByDate(event, today),
        externalLink: byDate(event, today, "externalLink"),
        actionType: byDate(event, today, "actionType"),
        backgroundColor: byDate(event, today, "backgroundColor")
    };
};
