import React, {useCallback, useContext, useMemo} from "react";
import {Avatar, Divider, Spin, Tag} from "antd";
import "./search-text-filter-popover.css";
import classnames from "classnames";
import {MarketplaceFilterProviderContext} from "../../MarketplaceFilterContext.jsx";
import {ProgressiveImageLoader} from "../../../../components/index.jsx";

const {CheckableTag} = Tag;

export const SearchItemList = ({
    currentSearchResults,
    tags,
    updateTitleSearchText,
    loading,
    searchText,
    log,
    onSearchItem
}) => {
    const {innerState} = useContext(MarketplaceFilterProviderContext);

    const onTagClick = useCallback(
        tagName => {
            updateTitleSearchText(tagName);
            onSearchItem(tagName);
            log("Search option selected", {text: tagName, isTag: true, type: "tag"});
        },
        [onSearchItem, log]
    );

    const onProductClick = useCallback(
        product => {
            log("Search option selected", {
                text: product.productName,
                productId: product.productId,
                isTag: false,
                type: "product"
            });
            window.open(`/dashboard/services/product/${product.productId}`, "_blank");
        },
        [log]
    );

    const onProviderClick = useCallback(
        provider => {
            if (provider?.providerId) {
                window.open(`/dashboard/services/providerPage/${provider.providerId}`, "_blank");
            }
            log("Search option selected", {text: provider.providerName, isTag: false, type: "product"});
        },
        [onSearchItem, log]
    );

    const hasSearchResult = useMemo(
        () =>
            currentSearchResults?.products?.length ||
            currentSearchResults?.providers?.length ||
            currentSearchResults?.searchTags?.length ||
            tags?.length,
        [currentSearchResults, tags]
    );

    const onShowAllItemClick = useCallback(() => {
        onSearchItem();
    }, [innerState]);

    return (
        <div id="search-text-filter">
            {loading ? (
                <div className="search-text-spinner">
                    <Spin />
                </div>
            ) : (
                <>
                    {!hasSearchResult && <span className="items-container-title">{searchText}</span>}
                    {tags?.length > 0 && (
                        <>
                            <span className="items-container-title">תגיות</span>
                            <div className="most-popular-tags-container">
                                {tags.map((searchTag, index) => {
                                    return (
                                        <CheckableTag
                                            key={index}
                                            checked={false}
                                            onChange={() => onTagClick(searchTag.name)}>
                                            {searchTag.name}
                                        </CheckableTag>
                                    );
                                })}
                            </div>
                            {currentSearchResults?.products?.length > 0 && <Divider />}
                        </>
                    )}

                    {currentSearchResults?.products?.length > 0 && (
                        <>
                            <span className="items-container-title">מוצרים</span>
                            <div>
                                {currentSearchResults?.products?.map((product, index) => {
                                    return (
                                        <ItemRow
                                            key={index}
                                            itemName={product.productName}
                                            subTitle={product.brandName}
                                            onClick={() => onProductClick(product)}
                                            imageUrl={product.imageUrl}
                                            itemType="product"
                                        />
                                    );
                                })}
                            </div>
                            <a onClick={onShowAllItemClick} className="display-all" href="#">
                                לצפייה בכל המוצרים
                            </a>
                            {currentSearchResults?.providers?.length > 0 && <Divider />}
                        </>
                    )}

                    {currentSearchResults?.providers?.length > 0 && (
                        <>
                            <span className="items-container-title">שותפים</span>
                            <div>
                                {currentSearchResults?.providers?.map((provider, index) => {
                                    return (
                                        <ItemRow
                                            key={index}
                                            itemName={provider.providerName}
                                            imageUrl={provider.imageUrl}
                                            onClick={() => onProviderClick(provider)}
                                            itemType="provider"
                                        />
                                    );
                                })}
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

const ItemRow = ({itemName, subTitle, onClick, imageUrl, itemType}) => {
    return (
        <div key={itemName} className={classnames("product-filter-container", itemType)} onClick={onClick}>
            <ProgressiveImageLoader
                width={50}
                height={50}
                src={
                    imageUrl ??
                    "https://res.cloudinary.com/well-b-prod/image/upload/c_fill,f_auto,g_faces,q_80,w_500/quLmpxH8h.png"
                }
            />
            <div className={classnames("product-filter-container-all-titles", itemType)}>
                <span className="product-filter-container-title" key={itemName}>
                    {itemName}
                </span>
                {subTitle && (
                    <span className="product-filter-container-sub-title" key={itemName}>
                        {subTitle}
                    </span>
                )}
            </div>
        </div>
    );
};
