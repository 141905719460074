import React from "react";
import moment from "moment";
import {Dropdown, Menu, message, Spin, Switch, Tooltip} from "antd";
import {CheckOutlined, DownOutlined, EllipsisOutlined, WarningOutlined} from "@ant-design/icons";
import {DEFAULT_PAGE} from "./AdminCompanies";
import {companyTierBreakPoints, CustomerType} from "./consts";
import {getCompanyPodium, getCompanySizeTier, getCustomerTypeLabel, getLastOrderColor} from "./utils";
import {TableDateCell} from "../components";
import {ConfirmationRequiredWrapper, SmartImageContainer, TableDoubleValueCell} from "../../components";
import profilePlaceholder from "../../images/icons/profile-placeholder.png";
import {SectorsNames} from "../../consts.js";
import {HttpClient} from "../../http/HttpClient";
import {CommentModal} from "../components/CommentModal";
import common from "@well-b/well-b-shared";

const columns = (
    currentPage,
    companiesOrderStatistics,
    companiesOrderStatisticsLoading,
    companyCustomerStats,
    onIgnoreDataChange,
    loadingChangeIgnoreData,
    onDelete,
    onEditCompanyDetails,
    onLoadBudget,
    sendEmployeeEmails,
    copyInviteLink,
    changeLogo,
    openLoadAdminModal,
    cancelBudgetRequest,
    masterBudgets,
    loadingMasterBudget,
    systemCSMs,
    onSelectCSM,
    loadingUpdateCSM,
    onEditCompanyBlockedProducts,
    me,
    updateCompany,
    setupPageLayoutForCompany,
    setAddNewCompanyDomainModal,
    setViewSubAdminModal,
    onTransferAllCompanyBudgetsToCompanyMaster
) => [
    {
        title: "#",
        key: "#",
        width: 50,
        render(_, __, idx) {
            const realIndex = currentPage * DEFAULT_PAGE + idx + 1;
            return `${realIndex}`;
        }
    },
    {
        title: "ID",
        key: "companyId",
        render(_, {companyId}) {
            return <div style={{width: "90px"}}>{companyId}</div>;
        }
    },
    {
        title: "לוגו",
        width: 50,
        key: "logo",
        render(_, company) {
            return (
                <SmartImageContainer
                    width={40}
                    height={40}
                    transformations={{
                        crop: "lpad"
                    }}
                    onClick={() => changeLogo(company)}
                    style={{cursor: "pointer"}}
                    src={company.branding?.logoUrl ?? profilePlaceholder}
                />
            );
        }
    },
    {
        title: "שם חברה",
        width: 220,
        dataIndex: "name",
        key: "company",
        sorter: (r1, r2) => (r1.name.toLowerCase() < r2.name.toLowerCase() ? 1 : -1),
        render(companyName, {contactName, contact, phone}) {
            return (
                <TableDoubleValueCell>
                    {companyName}
                    {`${contactName} | ${phone ? `${phone} | ` : ""} ${contact ?? ""}`}
                </TableDoubleValueCell>
            );
        }
    },
    {
        title: "ח.פ",
        dataIndex: "businessNumber",
        width: 50,
        render(businessNumber) {
            return businessNumber;
        }
    },
    {
        title: "כתובת",
        dataIndex: "address",
        width: 150,
        render(address) {
            return address;
        }
    },
    {
        title: "תאריך יצירה",
        dataIndex: "createdAt",
        sorter: ({createdAt: r1}, {createdAt: r2}) => (r1 < r2 ? 1 : -1),
        width: 150,
        render(createdAt) {
            return <TableDateCell date={createdAt} />;
        }
    },
    {
        title: "סקטור",
        dataIndex: "sector",
        sorter: ({sector: r1}, {sector: r2}) => (SectorsNames[r1] ?? "").localeCompare(SectorsNames[r2] ?? ""),
        width: 150,
        render(sector) {
            return SectorsNames[sector];
        }
    },
    {
        title: "תנאי תשלום",
        dataIndex: "paymentOption",
        width: 150,
        render(paymentOption) {
            return `שוטף +${paymentOption ?? 30}`;
        }
    },
    {
        title: "בקשה לטעינת תקציב",
        dataIndex: "requestingBudget",
        sorter: ({requestingBudget: r1}, {requestingBudget: r2}) => ((r1 ?? 0) < (r2 ?? 0) ? 1 : -1),
        width: 150,
        render(requestingBudget) {
            return requestingBudget ? `${requestingBudget.toLocaleString()} ₪` : null;
        }
    },
    {
        title: "תקציב בפועל",
        key: "totalBudget",
        sorter: ({companyId: r1}, {companyId: r2}) =>
            (masterBudgets?.[r1]?.totalBudget ?? 0) < (masterBudgets?.[r2]?.totalBudget ?? 0) ? 1 : -1,
        width: 150,
        render(_, {companyId}) {
            if (loadingMasterBudget) {
                return <Spin />;
            }

            return (masterBudgets?.[companyId]?.totalBudget || null)?.toLocaleString();
        }
    },
    {
        title: "מספר עובדים",
        dataIndex: "employeesCount",
        sorter: ({employeesCount: r1}, {employeesCount: r2}) =>
            (Number.parseInt(r1) || 0) < (Number.parseInt(r2) || 0) ? 1 : -1,
        width: 150,
        render(employeesCount) {
            return employeesCount;
        }
    },
    {
        title: "tier",
        dataIndex: "employeesCount",
        filters: [
            ...Object.values(companyTierBreakPoints).map(({name}) => ({
                text: name,
                value: name
            }))
        ],
        onFilter: (value, record) => getCompanySizeTier(Number.parseInt(record.employeesCount)) === value,
        width: 150,
        render(employeesCount) {
            return getCompanySizeTier(Number.parseInt(employeesCount));
        }
    },
    {
        title: "מנהלת לקוח",
        dataIndex: "assignedCSMs",
        filters: [
            ...(systemCSMs ?? []).map(csm => ({
                text: csm.firstName,
                value: csm.userId
            })),
            {text: "ללא מנהלת לקוח", value: "empty"}
        ],
        onFilter: (value, record) =>
            value === "empty"
                ? !record.assignedCSMs?.length
                : record.assignedCSMs?.some(({userId}) => userId === value),
        width: 150,
        render(assignedCSMs, {companyId}) {
            return (
                <Dropdown
                    trigger={["click"]}
                    overlay={
                        <Menu>
                            {systemCSMs?.map(csm => (
                                <Menu.Item key={csm?.userId} onClick={() => onSelectCSM(csm, companyId)}>
                                    {assignedCSMs?.some(({userId}) => userId === csm.userId) ? <CheckOutlined /> : null}
                                    {csm.firstName}
                                </Menu.Item>
                            ))}
                        </Menu>
                    }>
                    <span className="admin-companies-csm-cell">
                        {loadingUpdateCSM === companyId ? (
                            <Spin />
                        ) : (
                            <>
                                {assignedCSMs?.length ? assignedCSMs.map(({firstName}) => firstName).join(", ") : "ללא"}
                                <DownOutlined />
                            </>
                        )}
                    </span>
                </Dropdown>
            );
        }
    },
    {
        title: "מערכת",
        filters: Object.values(common.themeNames ?? {}).map(systemId => ({
            text: systemId,
            value: systemId?.toLowerCase()
        })),
        onFilter: (value, record) => record?.systemId?.toLowerCase() === value,
        key: "systemId",
        render(_, {systemId}) {
            return systemId;
        }
    },
    {
        title: "הזמנה ממוצעת",
        key: "averageOrderPrice",
        sorter: ({companyId: r1}, {companyId: r2}) =>
            (companiesOrderStatistics?.[r1]?.averageOrderPrice ?? 0) <
            (companiesOrderStatistics?.[r2]?.averageOrderPrice ?? 0)
                ? 1
                : -1,
        width: 150,
        render(_, {companyId}) {
            if (companiesOrderStatisticsLoading) {
                return <Spin />;
            }

            return (Math.round(companiesOrderStatistics?.[companyId]?.averageOrderPrice) || null)?.toLocaleString();
        }
    },
    {
        title: "ממוצע הזמנות חודשי",
        key: "averageMonthlyOrders",
        sorter: ({companyId: r1}, {companyId: r2}) =>
            (companiesOrderStatistics?.[r1]?.averageMonthlyOrders ?? 0) <
            (companiesOrderStatistics?.[r2]?.averageMonthlyOrders ?? 0)
                ? 1
                : -1,
        width: 150,
        render(_, {companyId}) {
            if (companiesOrderStatisticsLoading) {
                return <Spin />;
            }

            return (companiesOrderStatistics?.[companyId]?.averageMonthlyOrders ?? null)?.toFixed(2);
        }
    },
    {
        title: "ת. טאצ׳פוינט אחרונה",
        key: "lastTouchPointDate",
        sorter: ({companyId: r1}, {companyId: r2}) =>
            (companiesOrderStatistics?.[r1]?.lastTouchPointDate ?? 0) <
            (companiesOrderStatistics?.[r2]?.lastTouchPointDate ?? 0)
                ? 1
                : -1,
        width: 150,
        render(_, {companyId}) {
            if (companiesOrderStatisticsLoading) {
                return <Spin />;
            }

            const lastOrder = companiesOrderStatistics?.[companyId]?.lastTouchPointDate;
            const color = getLastOrderColor(lastOrder).color;

            return (
                <label style={{color: color}}>{lastOrder ? moment(lastOrder).format("HH:mm | DD.MM.YY") : null}</label>
            );
        }
    },
    {
        title: "סטטוס לקוח",
        key: "numberOfOrders",
        sorter: ({companyId: r1}, {companyId: r2}) =>
            (companiesOrderStatistics?.[r1]?.numberOfOrders ?? -1) <
            (companiesOrderStatistics?.[r2]?.numberOfOrders ?? -1)
                ? 1
                : -1,
        width: 150,
        render(_, {companyId}) {
            if (companyCustomerStats?.length) {
                return <Spin />;
            }

            const customerType = companyCustomerStats?.[companyId]?.customerType;

            const {label, color} = getCustomerTypeLabel(customerType);

            return (
                <label style={{color: color}}>
                    {label}
                    {customerType === CustomerType.ActiveReturning &&
                    getLastOrderColor(companiesOrderStatistics?.[companyId]?.lastTouchPointDate).color ? (
                        <Tooltip title="עברו יותר מ-30 ימים מאז ההזמנה האחרונה">
                            <WarningOutlined className="admin-companies-warning-icon" />
                        </Tooltip>
                    ) : null}
                </label>
            );
        }
    },
    {
        title: "פודיום",
        key: "companyTier",
        sorter: ({companyId: r1}, {companyId: r2}) =>
            (companiesOrderStatistics?.[r1]?.companyScore ?? 0) < (companiesOrderStatistics?.[r2]?.companyScore ?? 0)
                ? 1
                : -1,
        width: 40,
        render(_, {companyId}) {
            if (companiesOrderStatisticsLoading) {
                return <Spin />;
            }

            const companyScore = companiesOrderStatistics?.[companyId]?.companyScore;

            const companyTier = getCompanyPodium(companyScore);

            return companyTier ? (
                <img
                    className="admin-companies-tier-cell"
                    src={companyTier.image}
                    title={companyScore}
                    alt="companyRanking"
                />
            ) : null;
        }
    },
    {
        title: "USE DATA",
        dataIndex: "ignoreData",
        render(ignoreData, {companyId}) {
            return (
                <div onClick={e => e.stopPropagation()}>
                    <Switch
                        loading={loadingChangeIgnoreData === companyId}
                        checked={!ignoreData}
                        onChange={() => onIgnoreDataChange(companyId, !ignoreData)}
                    />
                </div>
            );
        }
    },
    {
        render(_, company) {
            return (
                <div className="admin-orders-actions-cell">
                    <CommentModal
                        comment={company.adminComment}
                        onSave={async adminComment => {
                            const adminCommentToUpdate = adminComment
                                ? {
                                      content: adminComment,
                                      author: me.firstName,
                                      lastUpdate: Date.now()
                                  }
                                : null;

                            const updatedCompany = await HttpClient.safePost("/admin/api/companies/update", {
                                companyId: company.companyId,
                                adminComment: adminCommentToUpdate
                            });

                            if (updatedCompany.error) {
                                message.error("שגיאה, ההערה על החברה לא נשמרה");
                                return;
                            }

                            message.success("ההערה על החברה נשמרה בהצלחה");
                            updateCompany(updatedCompany);
                        }}
                    />
                    <Dropdown
                        className="admin-companies-columns"
                        overlay={
                            <Menu>
                                <Menu.Item danger={true}>
                                    <ConfirmationRequiredWrapper onYes={() => onDelete(company)}>
                                        מחיקת חשבון
                                    </ConfirmationRequiredWrapper>
                                </Menu.Item>
                                <Menu.Item danger={true}>
                                    <ConfirmationRequiredWrapper
                                        subTitle={
                                            <div>
                                                <div>
                                                    בלחיצה על כפתור האישור הינך עומד למשוך את כל התקציבים של תתי האדמין
                                                    של החברה בחזרה אל האדמין הראשי
                                                </div>
                                                <div> ---</div>
                                                <div>אזהרה! פעולה זו עלולה להיות הרסנית ואיננה ניתנת לביטול</div>
                                                <b>אם אתה לא בטוח מה אתה עושה התייעץ עם הפרודקט הקרוב אליך</b>
                                            </div>
                                        }
                                        onYes={() => onTransferAllCompanyBudgetsToCompanyMaster(company)}>
                                        משיכת תקציבים לאדמין הראשי
                                    </ConfirmationRequiredWrapper>
                                </Menu.Item>
                                <Menu.Item onClick={() => onEditCompanyDetails(company)}>עריכת פרטי חברה</Menu.Item>
                                <Menu.Item onClick={() => onLoadBudget(company)}>טעינת תקציב</Menu.Item>
                                <Menu.Item onClick={() => cancelBudgetRequest(company)}>ביטול בקשה לתקציב</Menu.Item>
                                <Menu.Item onClick={() => openLoadAdminModal(company)}>טעינת תתי אדמין</Menu.Item>
                                <Menu.Item onClick={() => sendEmployeeEmails(company)}>
                                    שליחת מייל הרשמה לעובדים
                                </Menu.Item>
                                <Menu.Item onClick={() => copyInviteLink(company)}>העתק לינק הרשמה לעובדים</Menu.Item>
                                <Menu.Item onClick={() => onEditCompanyBlockedProducts(company)}>
                                    חסום מוצרים לחברה
                                </Menu.Item>
                                <Menu.Item onClick={() => setupPageLayoutForCompany(company)}>
                                    הגדר עמוד בית לחברה
                                </Menu.Item>
                                <Menu.Item onClick={() => setAddNewCompanyDomainModal(company)}>
                                    הוספת סיומת מייל חדשה לחברה
                                </Menu.Item>
                                <Menu.Item id="admin-manage-option" onClick={() => setViewSubAdminModal(company)}>
                                    ניהול משתמשי חברה
                                </Menu.Item>
                            </Menu>
                        }
                        trigger={["click"]}>
                        <EllipsisOutlined className="admin-orders-order-action-button" />
                    </Dropdown>
                </div>
            );
        }
    }
];

export default columns;
