import React, {useMemo} from "react";
import moment from "moment";
import {intersection} from "lodash";
import classNames from "classnames";
import {
    DEFAULT_DELIVERY_ARRIVING_TIME_RANGE,
    getDefaultPriceTypeArray,
    getDefaultTypeToShow,
    typeToPluralHebrewName
} from "./MarketplaceUtils";
import {getMergedDeliveryOptions} from "./MarketplaceBooking/utils";
import {ArrowNextIcon, ArrowPrevIcon} from "../icons";
import {HttpClient} from "../http/HttpClient";
import {ActivityLocation, ActivityLocationTypes, ActivityLocationTypesV1, PaymentType} from "../consts.js";
import {PriceTypes} from "../admin/upload/PriceSelectorHelper";
import {getAllowedCategories} from "../center/EXCenterHelper";
import "./marketplace-helper.css";
import {message} from "antd";
import {CouponDiscountTypes} from "../admin/AdminCoupons/consts.js";
import {openWindow} from "../http/WindowOpener.jsx";

export const getPriceByAmount = (amount, product, findPriceIfAmountIsOutOfRange = true) => {
    const priceOptionArray = getDefaultPriceTypeArray(product ?? {}) ?? [];

    return getPriceByAmountWithPriceOptionArray(amount, priceOptionArray, findPriceIfAmountIsOutOfRange);
};

export const getPriceByAmountWithPriceOptionArray = (
    amount,
    priceOptionArray,
    findPriceIfAmountIsOutOfRange = true
) => {
    if (!priceOptionArray?.length) {
        return 0;
    }

    if (!amount) {
        let lowestPrice = null;
        priceOptionArray.forEach(row => {
            if (!lowestPrice || parseFloat(lowestPrice) > parseFloat(row.price)) {
                lowestPrice = row.price;
            }
        });
        return lowestPrice;
    }

    const cleanOptions = priceOptionArray.filter(row => row.price);
    const index = cleanOptions.findIndex(row => {
        return row.price && parseInt(row.min) <= parseInt(amount) && parseInt(row.max) >= parseInt(amount);
    });
    if (index >= 0) {
        return cleanOptions[index].price;
    }

    if (!findPriceIfAmountIsOutOfRange) {
        return NaN;
    }

    if (parseInt(amount) < parseInt(cleanOptions[0].min)) {
        return cleanOptions[0].price;
    } else if (parseInt(amount) > parseInt(cleanOptions[cleanOptions.length - 1].max)) {
        return cleanOptions[cleanOptions.length - 1].price;
    }
    return "---";
};

export const BesideCarouselArrow = ({onClick, direction}) => {
    const ArrowIcon = direction === "next" ? ArrowNextIcon : ArrowPrevIcon;

    return (
        <div onClick={onClick}>
            <div className={classNames("beside-carousel-arrow", direction === "next" ? "next" : "prev")}>
                <ArrowIcon />
            </div>
        </div>
    );
};

export const BesideCarouselArrowWithDisable = props => {
    const {onClick, direction, style, className} = props;
    const ArrowIcon = direction === "next" ? ArrowNextIcon : ArrowPrevIcon;

    const isDisabled = useMemo(() => className?.includes("disabled"), [className]);

    return (
        <>
            <div
                onClick={onClick}
                className={classNames("beside-carousel-arrow", direction === "next" ? "next" : "prev", {
                    disabled: isDisabled
                })}>
                <ArrowIcon />
            </div>
        </>
    );
};

export const createProductLink = (
    product,
    {me, services, marketplaceRoot = "/dashboard/services", serviceId = null}
) => {
    let selectedServiceId = serviceId;

    if (!selectedServiceId) {
        selectedServiceId = selectServiceIdFromProduct(me, product, services);
    }

    return `${marketplaceRoot}/${selectedServiceId}/providersV2/${product.provider.providerId}/${product.service.productId}`;
};

export const selectServiceIdFromProduct = (me, product, services) => {
    const allowedCategories = getAllowedCategories(me, services);
    const productAllowedCategories = intersection(allowedCategories, product.service.services);
    return productAllowedCategories[Math.floor(Math.random() * productAllowedCategories.length)];
};

export const MarketplaceCategoryCarouselArrow = props => {
    const {onClick, direction, bottomStyle, className} = props;

    const ArrowIcon = direction === "next" ? ArrowNextIcon : ArrowPrevIcon;

    const isDisabled = useMemo(() => className?.includes("disabled"), [className]);

    return (
        <div onClick={onClick}>
            <div
                className={classNames("marketplace-carousel-arrow", direction, {isDisabled})}
                style={{
                    bottom: bottomStyle
                }}>
                <ArrowIcon />
            </div>
        </div>
    );
};

export const MarketplaceCarouselProps = {
    nextArrow: <MarketplaceCategoryCarouselArrow direction={"next"} />,
    prevArrow: <MarketplaceCategoryCarouselArrow direction={"prev"} />,
    arrows: true,
    dots: false,
    accessibility: true,
    infinite: false
};

export const MarketplaceCategoryCarouselArrowV2 = ({onClick, direction, className}) => {
    const ArrowIcon = direction === "next" ? ArrowNextIcon : ArrowPrevIcon;

    return (
        <div onClick={onClick} className={className}>
            <ArrowIcon style={{width: 20, height: 20, fill: "#8398A6"}} />
        </div>
    );
};

export const MarketplaceSeparatorLine = ({opacity = "0.2"}) => (
    <div
        style={{
            backgroundColor: "var(--standard-text-color)",
            opacity,
            height: "2px",
            width: "100%"
        }}
    />
);

export const expandIconController = isActive => (
    <div
        style={{
            fontSize: 16,
            color: isActive ? "var(--standard-text-color)" : "var(--primary-color)",
            fontWeight: "bold",
            marginTop: 10
        }}>
        {isActive ? "סיימתי" : "שינוי"}
    </div>
);

export const moveSelectedImageToStart = (array, selectedIndex) => {
    const element = array[selectedIndex];
    array.splice(selectedIndex, 1);
    array.splice(0, 0, element);
    return array;
};

export const getAllLocations = (content, officeAddress) =>
    content.location.reduce((acc, location) => {
        const title = ActivityLocation[location];
        if (ActivityLocation.office === title) {
            acc.push({
                id: ActivityLocation.office,
                activityLocationTypeId: ActivityLocationTypes.office,
                title,
                subtitle: officeAddress,
                ...(!officeAddress
                    ? {
                          elementSubtitle: (
                              <div style={{color: "red"}}>
                                  {"אוי, נראה שלא הוגדרה כתובת. ניתן להוסיף כתובת משרדית תחת תפריט -> הגדרות חשבון"}
                              </div>
                          )
                      }
                    : {})
            });
        }
        if (ActivityLocation.online === title) {
            acc.push({
                id: ActivityLocation.online,
                activityLocationTypeId: ActivityLocationTypes.online,
                title,
                subtitle: "ניתן לסנכרן חשבון זום ולייצור לינק לאחר אישור הזמנה"
            });
        }

        if (ActivityLocation.manualLocation === title) {
            acc.push({
                activityLocationTypeId: ActivityLocationTypes.manualLocation,
                title: ActivityLocation.manualLocation,
                subtitle: ActivityLocation.manualLocation,
                elementSubtitle: ActivityLocation.manualLocationSubtitle
            });
        }

        if (ActivityLocation.onsite === title || ActivityLocation.offsite === title) {
            acc.push(
                content.address.map(address => {
                    const addressName = address.name;
                    return {
                        id: ActivityLocation.onsite === title ? ActivityLocation.onsite : ActivityLocation.offsite,
                        activityLocationTypeId:
                            ActivityLocation.onsite === title
                                ? ActivityLocationTypes.onsite
                                : ActivityLocationTypesV1.offsite,
                        address: addressName,
                        title: `${title}: ${addressName} ${address.isAccessible ? "(סניף נגיש)" : ""}`,
                        subtitle: addressName,
                        elementSubtitle: <div>{address.directionInfo}</div>
                    };
                })
            );
        }

        if (ActivityLocation.homeDelivery === title) {
            acc.push({
                id: ActivityLocation.homeDelivery,
                activityLocationTypeId: ActivityLocationTypes.homeDelivery,
                title: "משלוח לבית מקבל המארז",
                subtitle: title,
                elementSubtitle: (
                    <div className="home-delivery-subtitle">
                        אנא הזינו את פרטי הקשר של מקבל/י המארז וכתובותיהם לפי הפורמט והמארז/ים יגיעו עד הבית!
                    </div>
                )
            });
        }

        return acc;
    }, []);

export const getOrderCommission = (orderCommission, chosenPriceType, modularProducts, upgradeOptionsPrice) => {
    if (chosenPriceType !== PriceTypes.ByModular) {
        return orderCommission;
    }

    const [totalOrderPrice, totalCommissionPrice] = [
        ...modularProducts,
        ...(upgradeOptionsPrice
            ? [
                  {
                      price: upgradeOptionsPrice,
                      amount: 1
                  }
              ]
            : [])
    ].reduce(
        (acc, product) => {
            if (!product.amount) {
                return acc;
            }

            const productCommission = isNaN(Number.parseFloat(product.commission))
                ? orderCommission
                : product.commission;
            const productPrice = product.amount * product.price;

            return [acc[0] + productPrice, acc[1] + (productPrice * productCommission) / 100];
        },
        [0, 0]
    );

    const modularProductCommission = (totalCommissionPrice / totalOrderPrice) * 100;
    return Number(modularProductCommission.toLocaleString("en-GB", {maximumFractionDigits: 2}));
};

export const getManualLocationOption = () => ({
    title: ActivityLocation.manualLocation,
    subtitle: ActivityLocation.manualLocation,
    elementSubtitle: ActivityLocation.manualLocationSubtitle
});

export const CancellationPolicy = ({isVoucherProduct = false}) =>
    isVoucherProduct ? (
        <div
            style={{
                color: "var(--standard-text-color)",
                fontSize: "14px",
                marginBottom: "20px",
                overflow: "auto",
                textOverflow: "ellipsis",
                marginLeft: "20px",
                marginRight: "20px"
            }}>
            <p>
                <b style={{color: "var(--secondary-color)"}}>מדיניות ביטולים:</b> יש לשים לב כי לא ניתן לבטל שובר לאחר
                הפקתו ושליחתו אל הנמען
            </p>
        </div>
    ) : (
        <div
            style={{
                color: "var(--standard-text-color)",
                fontSize: "14px",
                marginBottom: "20px",
                overflow: "auto",
                textOverflow: "ellipsis",
                marginLeft: "20px",
                marginRight: "20px"
            }}>
            <p>עלות משלוח עשויה להשתנות בנסיבות מיוחדות תוך עדכונכם ואישורכם.</p>
            <p>
                <b style={{color: "var(--secondary-color)"}}>מדיניות ביטולים:</b> התוכניות שלכם השתנו? בהתאם מדיניות
                הביטולים שלנו, ניתן לקבל החזר כספי מלא על כל הזמנה אם מבטלים לפחות 7 ימים לפני תאריך האספקה, או תוך 24
                שעות ממועד הרכישה. קלי קלות! אם רוצים לשנות את פרטי ההזמנה, ניתן לעשות זאת תוך 24 שעות ממועד אישור
                ההזמנה ועד 72 שעות לפני תאריך האספקה.
            </p>
        </div>
    );

const getBookingCore = (values, content, providerDetails) => ({
    serviceId: values.serviceId,
    offeredPrice: values.offeredPrice,
    employeesExcel: values.employeesExcel,
    separateInvoice: values?.separateInvoice,
    creditCardData: values.creditCard,
    tenBisCreditCard: values.tenBisCreditCard,
    deliveryPrice: values.deliveryPrice,
    companyPhone: values.companyPhone,
    commission: getOrderCommission(
        content.commission,
        content.chosenPriceType,
        values.modularProducts,
        values.upgradeOptionsPrice
    ),
    isDemo: content.demo,
    v2: true,
    couponCode: values.coupon?.code,
    discount:
        values.couponDiscountAmount || values.companyDiscount
            ? Number(values.couponDiscountAmount ?? 0) + (values.companyDiscount ?? 0)
            : undefined,
    couponDiscountPercentage:
        values?.coupon?.discountType === CouponDiscountTypes.ByPercentage ? values?.coupon?.discount : undefined,
    couponDiscountAmount: values.couponDiscountAmount,
    companyDiscountAmount: values.companyDiscount,
    companyDiscountPercentage: values.companyDiscountPercentage,
    paymentType: values.paymentType,
    productAmountType: typeToPluralHebrewName(getDefaultTypeToShow(content)),
    chosenPriceType: content.chosenPriceType,
    providerBrand: content.brandName ?? content?.engBrandName ?? providerDetails.businessName,
    providerFirstName: providerDetails.contactFirstName,
    providerLastName: providerDetails.contactLastName,
    providerId: providerDetails.providerId,
    whoPays: "Company Pays",
    dtstart: values.dtstart,
    productCoverPhoto: content.portfolioImages[0]?.imageUrl,
    productDescription: content.description,
    productPackageInfo: content.packageInfo,
    productName: content.productName,
    productId: content.productId,
    planBudgetOrder: values.planBudgetOrder,
    ...([PaymentType.BankTransfer, PaymentType.Budget].includes(values.paymentType)
        ? {
              invoiceComment: values.invoiceComment,
              invoicePONumber: values.invoicePONumber
          }
        : {}),
    ...(values.budgetCategoriesPriority
        ? {budgetCategories: values.budgetCategoriesPriority.map(category => ({category}))}
        : {}),
    ...(values.tipPercentage ? {tip: getOrderTip(values.offeredPrice, values.tipPercentage)} : {})
});

export const createBookingEventValues = (formValues, product, providerDetails) => ({
    ...getBookingCore(formValues, product, providerDetails),
    floor: formValues.floor,
    freq: "once",
    byweekday: [moment(formValues.date).format("dd").toUpperCase()],
    until: formValues.until,
    interval: 1,
    duration: product.durationMinutes || 60,
    location: "office",
    sourcePlaceId: formValues.sourcePlaceId,
    sourceAddressName: formValues.sourceAddressName,
    shippingProviders: product.shippingProviders,
    address: formValues.address,
    addressId: formValues.addressId,
    appointmentBased: false,
    offsiteBased: false,
    priceType: "group",
    branches: formValues.branches,
    participants: formValues.amount,
    productAmount: formValues.amount,
    companyRequests: formValues.requests,
    providerQuestions: product.participantsExtraInfo,
    companyAnswers: formValues.extraInfo,
    companyExtraRequests: formValues.extraRequests,
    deliveryRequests: formValues.deliveryRequests,
    message: "",
    employeesDeliveryDetail: formValues.singleEmployeeDetails ? [formValues.singleEmployeeDetails] : undefined,
    upgradeOptions: formValues.upgradeOptions,
    extraContactInfo: formValues.extraContactInfo,
    deliveryArrivingTimeRange:
        getMergedDeliveryOptions(providerDetails, product)?.arrivingTimeRange ?? DEFAULT_DELIVERY_ARRIVING_TIME_RANGE,
    modularProducts: formValues.modularProducts,
    chosenActivityLocation: formValues.chosenActivityLocation,
    homeDeliveryUploadMethod: formValues.homeDeliveryUploadMethod
});

export const createProductBundleEvent = async ({subOrders, ...productBundleProperties}) =>
    await HttpClient.put("/api/events/createProductBundleOrder", {
        ...productBundleProperties,
        subOrders
    });

export const createBookingEvent = async (formValues, product, providerDetails, shouldUseSocket = false) => {
    const params = new URLSearchParams();
    params.append("shouldUseSocket", String(shouldUseSocket));

    return HttpClient.put(
        `/api/events/offerV2?${params.toString()}`,
        createBookingEventValues(formValues, product, providerDetails)
    );
};

export const createVoucherBookingEvent = async (values, content, providerDetails) =>
    await HttpClient.put("/api/events/createVoucherOrder", {
        ...getBookingCore(values, content, providerDetails),
        isVoucherOrder: true,
        voucherOption: values.voucherOption,
        sendOptions: values.sendOptions,
        senderName: values.senderName,
        singleEmployeeDetails: values.singleEmployeeDetails,
        timeOption: values.timeOption
    });

export const getOrderTip = (offeredPrice, tipPercentage) =>
    tipPercentage && offeredPrice ? Math.round(offeredPrice * (tipPercentage / 100) * 10) / 10 : 0;
