import React, {useContext, useMemo, useState} from "react";
import moment from "moment/moment";
import {sumBy} from "lodash";
import {AppContext} from "../../AppContext";
import {useRequest} from "../../utils/hooks";
import {OnlyMonthFormat} from "../consts";
import {momentHebrewFormat} from "../../utils/DateFormat";
import OrdersCalendarCategoriesBar from "../OrdersCalendarCategoriesBar";
import {SquareButton} from "../../components";
import {getCategoriesSummary} from "../../happinessCalculator/utils";
import {getOrderPrice} from "../../utils/calculationUtils";
import "./yearly-view.css";
import {YearPicker} from "../../components/YearPicker";

export const YearlyView = ({}) => {
    const {me} = useContext(AppContext);
    const [currentYear, setCurrentYear] = useState(Number(moment().format("YYYY")));

    const monthsOfTheYear = useMemo(() => {
        return [...Array(12)].map((_, index) => {
            return moment(currentYear, "YYYY").startOf("year").add(index, "months");
        });
    }, [currentYear]);

    const [orders] = useRequest(
        `/api/companies/orders/${me?.companyId}/?userId=${me?.userId ?? ""}&byCreatedAt=false`,
        "POST",
        {
            startTime: moment(currentYear, "YYYY").startOf("year").valueOf(),
            endTime: moment(currentYear, "YYYY").endOf("year").valueOf()
        },
        [me, currentYear],
        !!currentYear
    );

    const [currentYearPlan, _, error] = useRequest(
        `/api/budgetPlan/betweenDates?` +
            `from=${moment(currentYear, "YYYY").startOf("year").format(OnlyMonthFormat)}` +
            `&to=${moment(currentYear, "YYYY").endOf("year").format(OnlyMonthFormat)}` +
            `&populate=true`,
        "GET",
        null,
        [currentYear],
        !!currentYear
    );

    const relevantYearBudgetPlanOrders = useMemo(() => {
        if (!currentYearPlan) return [];

        const budgetPlanMonths = currentYearPlan.map(({planedMonth}) => moment(planedMonth).format(OnlyMonthFormat));
        return orders?.filter(order => {
            const formatedOrder = moment(order.dtstart).format(OnlyMonthFormat);
            return budgetPlanMonths.includes(formatedOrder);
        });
    }, [orders, currentYearPlan]);

    const allMonthPlanCategoriesWithTotalPrice = useMemo(
        () =>
            currentYearPlan
                ? currentYearPlan.reduce((currentMonthPlan, monthPlan) => {
                      const {categories, ...restPlanMonth} = monthPlan;
                      currentMonthPlan.push({
                          categories: getCategoriesSummary(monthPlan.categories, monthPlan.employeesAmount),
                          ...restPlanMonth
                      });
                      return currentMonthPlan;
                  }, [])
                : null,
        [currentYearPlan]
    );

    const allYearPlanCategoriesWithTotalPrice = useMemo(() => {
        return allMonthPlanCategoriesWithTotalPrice
            ? allMonthPlanCategoriesWithTotalPrice.reduce((acc, currentMonth) => {
                  const monthCategories = currentMonth.categories;

                  if (!monthCategories) {
                      return acc;
                  }

                  for (const monthCategory of monthCategories) {
                      const foundCategoryIndex = acc.findIndex(({id}) => id === monthCategory.id);

                      if (foundCategoryIndex !== -1) {
                          acc[foundCategoryIndex].totalPrice += monthCategory.totalPrice;
                          acc[foundCategoryIndex].occasions.push(...monthCategory.occasions);
                      } else {
                          acc.push({...monthCategory});
                      }
                  }

                  return acc;
              }, [])
            : null;
    }, [allMonthPlanCategoriesWithTotalPrice]);

    const allOrdersPrice = useMemo(() => {
        if (!currentYearPlan) return "-";

        const budgetPlanMonths = currentYearPlan.map(({planedMonth}) => moment(planedMonth).format(OnlyMonthFormat));
        const relevantOrders = orders?.filter(order => {
            const formatedOrder = moment(order.dtstart).format(OnlyMonthFormat);
            return budgetPlanMonths.includes(formatedOrder);
        });
        return sumBy(relevantOrders, getOrderPrice);
    }, [orders, currentYearPlan]);

    const allYearPlanPrice = useMemo(
        () =>
            allMonthPlanCategoriesWithTotalPrice
                ? allMonthPlanCategoriesWithTotalPrice.reduce((currentPrice, monthPlan) => {
                      currentPrice += sumBy(monthPlan.categories, "totalPrice");
                      return currentPrice;
                  }, 0)
                : "-",
        [allMonthPlanCategoriesWithTotalPrice]
    );

    return (
        <div className="yearly-view-container">
            <div className="yearly-view-title">
                <span>סיכום שנתי</span>
                <div></div>

                {me.companyName && (
                    <>
                        <span>של</span>
                        <div></div>
                        <span>{me.companyName}</span>
                    </>
                )}

                <span>:</span>
                <div></div>
                <span>תכנון מול ביצוע 🤗</span>
            </div>

            <div className="yearly-view-toolbar">
                <YearPicker currentYear={currentYear} setCurrentYear={setCurrentYear} />

                <div className="yearly-view-summary-stat">
                    <span>תקציב מסגרת שנתית</span>
                    <span>
                        {allYearPlanPrice ? allYearPlanPrice.toLocaleString("en-GB", {maximumFractionDigits: 1}) : "-"}{" "}
                        ₪
                    </span>
                </div>
                <div className="yearly-view-summary-stat">
                    <span>תקציב שנתי בשימוש</span>
                    <span className="yearly-view-used-budget-summary-container">
                        {allOrdersPrice ? (
                            <span className="yearly-view-used-budget-bar">
                                <OrdersCalendarCategoriesBar
                                    orders={relevantYearBudgetPlanOrders}
                                    categoriesWithTotalPrice={allYearPlanCategoriesWithTotalPrice}
                                    totalCategoriesPrice={allYearPlanPrice}
                                />
                            </span>
                        ) : null}
                        <span>
                            {allOrdersPrice ? allOrdersPrice.toLocaleString("en-GB", {maximumFractionDigits: 1}) : "-"}{" "}
                            ₪
                        </span>
                    </span>
                </div>
                <div className="yearly-view-summary-stat">
                    <span>עודף תכנון מול ביצוע בפועל</span>
                    <span>
                        {allYearPlanPrice && allOrdersPrice
                            ? (allYearPlanPrice - allOrdersPrice).toLocaleString("en-GB", {maximumFractionDigits: 1})
                            : "-"}{" "}
                        ₪
                    </span>
                </div>

                <SquareButton className="primary-color-button">דוח תיכנון מול ביצוע</SquareButton>
            </div>

            <div className="yearly-view-months">
                {monthsOfTheYear.map(month => {
                    const plannedMonthFound = currentYearPlan?.find(monthPlan => {
                        const formatedMonthPlan = moment(monthPlan.planedMonth).format(OnlyMonthFormat);
                        const formatedMonth = moment(month).format(OnlyMonthFormat);
                        return formatedMonth === formatedMonthPlan;
                    });

                    if (plannedMonthFound) {
                        const filterOrders = orders?.filter(
                            order =>
                                order.dtstart > moment(month, "YYYY").startOf("month").valueOf() &&
                                order.dtstart < moment(month, "YYYY").endOf("month").valueOf()
                        );
                        const plannedMonthCategories = allMonthPlanCategoriesWithTotalPrice.find(
                            ({planId}) => plannedMonthFound.planId === planId
                        )?.categories;
                        const monthPlanPrice = sumBy(plannedMonthCategories, "totalPrice");
                        const monthOrdersPrice = sumBy(filterOrders, getOrderPrice);

                        return (
                            <div key={plannedMonthFound.planId} className="yearly-view-planned-month">
                                <span className="yearly-view-month-name">{momentHebrewFormat(month, "MMMM YY")}</span>
                                <span className="yearly-view-month-text">תקציב מסגרת לתוכנית</span>
                                <span className="yearly-view-month-text-white">
                                    {monthPlanPrice.toLocaleString("en-GB", {maximumFractionDigits: 1})} ₪
                                </span>
                                <span className="yearly-view-month-text">תקציב בשימוש</span>
                                <span className="yearly-view-used-budget-summary-container">
                                    {filterOrders ? (
                                        <span className="yearly-view-used-budget-bar">
                                            <OrdersCalendarCategoriesBar
                                                orders={filterOrders}
                                                categoriesWithTotalPrice={plannedMonthCategories}
                                                totalCategoriesPrice={monthPlanPrice}
                                            />
                                        </span>
                                    ) : null}
                                    <span className="yearly-view-month-text-white">
                                        {monthOrdersPrice
                                            ? monthOrdersPrice.toLocaleString("en-GB", {maximumFractionDigits: 1})
                                            : "-"}{" "}
                                        ₪
                                    </span>
                                </span>
                            </div>
                        );
                    }

                    return (
                        <div key={month.format(OnlyMonthFormat)} className="yearly-view-unplanned-month">
                            <span>{momentHebrewFormat(month, "MMMM YY")}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
