export const PRIVATE_EMAIL_DOMAINS = new Set([
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "aol.com",
    "msn.com",
    "outlook.com",
    "icloud.com",
    "live.com",
    "mail.com",
    "yandex.ru",
    "gmx.com",
    "protonmail.com",
    "zoho.com",
    "inbox.com",
    "rediffmail.com",
    "bigpond.com",
    "tutanota.com",
    "qq.com",
    "gmx.net",
    "web.de",
    "earthlink.net",
    "optonline.net"
]);
