import React from "react";
import {SmartImageContainer} from "./SmartImageContainer";
import {SquareButton} from "./SquareButton";
import cover_placeholder from "../images/placeholder-image.jpg";
import {NOOP} from "../utils/NOOP";
import "./banner.css";

export const Banner = ({image, name, text, buttonText, width, height, onClick}) => (
    <SmartImageContainer
        onClick={onClick || NOOP}
        width={width}
        height={height}
        src={image ?? cover_placeholder}
        className="wb-banner"
        style={{
            cursor: onClick ? "pointer" : "default"
        }}>
        <div id={name}>
            {text}
            {buttonText ? <SquareButton>{buttonText}</SquareButton> : null}
        </div>
    </SmartImageContainer>
);
