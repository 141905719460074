import React, {useCallback} from "react";
import {Input, InputNumber} from "antd";
import {MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {NOOP} from "../../utils/NOOP";
import "./counter.css";
import classNames from "classnames";

export const Counter = ({onChange = NOOP, value = 0, allowNegative = false, disabled = false}) => {
    const onCounterClick = useCallback(
        type => {
            if ((!allowNegative && value === 0 && type === "minus") || disabled) return;

            switch (type) {
                case "plus":
                    onChange(value + 1);
                    break;
                case "minus":
                    onChange(value - 1);
                    break;
                default:
                    value = 0;
            }
        },
        [onChange, value]
    );

    const onChangeInputValue = useCallback(newValue => {
        const value = new Intl.NumberFormat("en-US", {
            maximumFractionDigits: 0,
            roundingMode: "floor"
        }).format(newValue);

        if (isNaN(value) || (!allowNegative && newValue < 0)) {
            return;
        }

        onChange(parseInt(value));
    }, []);

    return (
        <div className="counter-container">
            <div className="counter-icon" onClick={() => onCounterClick("plus")}>
                <PlusCircleOutlined className={classNames({"disable-counter-icon": disabled})} />
            </div>
            <div className="counter-value">
                <InputNumber
                    className={classNames({"disable-counter-icon": disabled})}
                    disabled={disabled}
                    bordered={false}
                    controls={false}
                    min={0}
                    value={value}
                    onChange={onChangeInputValue}
                />
            </div>
            <div className="counter-icon" onClick={() => onCounterClick("minus")}>
                <MinusCircleOutlined className={classNames({"disable-counter-icon": disabled})} />
            </div>
        </div>
    );
};
