import React, {useReducer, useContext, useEffect} from "react";
import {PageSubTitleLabel} from "../components/PageTitle";
import {Card, Slider, Form, Input, DatePicker, Select, Spin, Menu} from "antd";
import {FormTextArea, FormLabel, FormBottomBar} from "../components/form";
import {StyledButton} from "../components/StyledButton";
import moment from "moment-timezone";
import {HttpClient} from "../http/HttpClient";
import {daysArray, dayKeys} from "../components/Availability";
import {ClockCircleOutlined} from "@ant-design/icons";
import {CategoryDropdown} from "../components/CategoryDropdown";
import PageTitleHeader from "../components/PageTitle";
import {FreeOfCharge} from "../components/FreeOfChrage";
import {EventOfferSent} from "./EventOfferSent";
import {AsiaJerusalemTZ, DaysMapper} from "./DaysMapper";
import {FrequencyMapper} from "./FrequencyMapper";
import {EventBus} from "../bus/EventBus";
import {locationKeyToTextMap, LocationLabels} from "../data/locations";
import {inject, observer} from "mobx-react";
import {isAppointmentBasedProfession} from "../data/professions";
import {AppContext} from "../AppContext";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {CustomTimePicker} from "../components/CustomTimePicker";
import {SYSTEM_DEFAULT_START_TIME} from "../data/defaults";
import {CompanyBranchSelect, hasBranches} from "../company/CompanyBranchSelect";
import {PageLoader} from "../components/PageLoader";

const {Option} = Select;
const {useForm} = Form;

const daysOfTheWeek = [
    {
        value: ["SU"],
        title: "Sunday"
    },
    {
        value: ["MO"],
        title: "Monday"
    },
    {
        value: ["TU"],
        title: "Tuesday"
    },
    {
        value: ["WE"],
        title: "Wednesday"
    },
    {
        value: ["TH"],
        title: "Thursday"
    },
    {
        value: ["FR"],
        title: "Friday"
    },
    {
        value: ["SA"],
        title: "Saturday"
    }
];

const allDaysString = tzid => {
    return DaysMapper.allWorkingWeekDays(tzid, "short").join(", ");
};

const generateLocationSelect = (appointmentBased, location, address, onSelectLocation) => {
    const onSelect = location => onSelectLocation && onSelectLocation(location);

    if (location === LocationLabels.OFFSITE) {
        return <FormLabel style={{marginBottom: 24, fontSize: 16, fontWeight: 400}}>{address}</FormLabel>;
    }

    return !appointmentBased && location ? (
        <Form.Item
            name="location"
            rules={[
                {
                    required: true,
                    message: "Where will it happen?"
                }
            ]}>
            <Select onSelect={onSelect} className="wb-location-select" style={{width: "100%"}} placeholder="Where">
                {location === LocationLabels.ONSITE_AND_ONLINE ? (
                    <>
                        <Option value={LocationLabels.ONSITE}>{locationKeyToTextMap[LocationLabels.ONSITE]}</Option>
                        <Option value={LocationLabels.ONLINE}>{locationKeyToTextMap[LocationLabels.ONLINE]}</Option>
                    </>
                ) : (
                    <Option value={location}>{locationKeyToTextMap[location]}</Option>
                )}
            </Select>
        </Form.Item>
    ) : appointmentBased ? (
        <Form.Item
            name="location"
            rules={[
                {
                    required: true,
                    message: "Where will it happen?"
                }
            ]}>
            <Select onSelect={onSelect} className="wb-location-select" style={{width: "100%"}} placeholder="Where">
                <Option value={LocationLabels.ONSITE}>{locationKeyToTextMap[LocationLabels.ONSITE]}</Option>
            </Select>
        </Form.Item>
    ) : (
        <Form.Item
            name="location"
            rules={[
                {
                    required: true,
                    message: "Where will it happen?"
                }
            ]}>
            <Select onSelect={onSelect} className="wb-location-select" style={{width: "100%"}} placeholder="Where">
                <Option value={LocationLabels.ONSITE}>{locationKeyToTextMap[LocationLabels.ONSITE]}</Option>
                <Option value={LocationLabels.ONLINE}>{locationKeyToTextMap[LocationLabels.ONLINE]}</Option>
            </Select>
        </Form.Item>
    );
};

export const OfferFields = ({
    tzid,
    appointmentBased,
    offsiteBased,
    location,
    address,
    // askedPrice,
    offeredPrice,
    employeesCanPay,
    duration,
    freq,
    company,
    onTimeChange,
    onDayChange,
    onRepetitionChange,
    onDateChange,
    onMessageChange,
    onPriceChange,
    onSelectLocation,
    byweekday
}) => {
    const {me} = useContext(AppContext);
    // let min = _.min([askedPrice, offeredPrice]) - 120;
    // let max = _.max([askedPrice, offeredPrice]) + 80;
    let min = offeredPrice - 120;
    let max = offeredPrice + 80;
    min = min < 0 ? 0 : min;

    // if (Math.abs(offeredPrice - askedPrice) > 300) {
    //     min = 0;
    //     max = MAX_SERVICE_PRICE_NIS + 500;
    // }

    const marks = {};

    // if (askedPrice) {
    //     marks[askedPrice] = {
    //         style: {
    //             color: '#F7B348',
    //             fontSize: 15,
    //             fontWeight: 700,
    //             userSelect: "none"
    //         },
    //         label: <div style={{height: 60, fontSize: 15, display: "flex", alignItems: "center", justifyContent: "flex-end", flexDirection: "column", marginTop: -85}}>
    //             {`₪${askedPrice}`}
    //             <br/>
    //             <label style={{fontSize: 12}}>Market price</label>
    //         </div>
    //     };
    // }

    if (offeredPrice /*&& askedPrice !== offeredPrice*/) {
        marks[offeredPrice] = {
            style: {
                color: "#243446",
                fontSize: 16,
                fontWeight: 700,
                userSelect: "none",
                zIndex: 1
            },
            label: (
                <div
                    style={{
                        height: 80,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                        marginTop: -30
                    }}>
                    {`₪${offeredPrice}`}
                    <br />
                    <label style={{fontSize: 12}}>Your offer</label>
                </div>
            )
        };
    }

    return (
        <>
            <Input.Group style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                {freq === "daily" ? (
                    <FormLabel style={{width: 235, minWidth: 235, maxHeight: 50, fontSize: 16, fontWeight: 400}}>
                        {allDaysString(tzid)}
                    </FormLabel>
                ) : (
                    <Form.Item name="byweekday" rules={[{required: !byweekday, message: "Please select week day."}]}>
                        {byweekday ? (
                            <Select
                                showArrow={true}
                                showSearch={false}
                                disabled={true}
                                className="event-day"
                                style={{width: 235}}
                                placeholder={daysOfTheWeek.find(day => day.value[0] === byweekday[0]).title}></Select>
                        ) : (
                            <Select
                                showArrow={true}
                                showSearch={false}
                                disabled={true}
                                className="event-day"
                                onChange={value => onDayChange && onDayChange([value])}
                                style={{width: 235}}
                                placeholder="day">
                                {tzid === AsiaJerusalemTZ ? <Option value="SU">Sunday</Option> : null}
                                <Option value="MO">Monday</Option>
                                <Option value="TU">Tuesday</Option>
                                <Option value="WE">Wednesday</Option>
                                <Option value="TH">Thursday</Option>
                                <Option value="FR">Friday</Option>
                                <Option value="SA">Saturday</Option>
                                {tzid !== AsiaJerusalemTZ ? <Option value="SU">Sunday</Option> : null}
                            </Select>
                        )}
                    </Form.Item>
                )}
                {!appointmentBased ? (
                    <Input.Group
                        style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            paddingLeft: 15,
                            paddingRight: 0
                        }}>
                        <Form.Item
                            name="startTime"
                            rules={[{type: "object", required: true, message: "Please select start time."}]}>
                            <CustomTimePicker
                                onChange={time => onTimeChange && onTimeChange(time)}
                                style={{width: 150}}
                                format="HH:mm"
                                minuteStep={15}
                                placeholder={"Start Time"}
                            />
                        </Form.Item>
                        <Form.Item>
                            <FormLabel
                                style={{
                                    fontWeight: 400,
                                    width: 140,
                                    fontSize: 16,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}>
                                {duration ? `${duration} Minutes` : ""}
                                <ClockCircleOutlined style={{fontSize: 14, paddingTop: 2, color: "rgba(0,0,0,0.25)"}} />
                            </FormLabel>
                        </Form.Item>
                    </Input.Group>
                ) : (
                    <Form.Item
                        name="whoPays"
                        rules={[
                            {
                                required: true,
                                message: "Please choose who pays."
                            }
                        ]}>
                        <Select style={{width: 300}} placeholder="Who pays?">
                            {employeesCanPay ? <Option value="Employees pay">Employees pay</Option> : null}
                            <Option value="Company pays">Company pays</Option>
                        </Select>
                    </Form.Item>
                )}
            </Input.Group>
            <Input.Group style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                <Form.Item name="freq" rules={[{required: true, message: "Please select repetition type."}]}>
                    <Select
                        onChange={value => onRepetitionChange && onRepetitionChange(value)}
                        style={{width: 235, minWidth: 235}}
                        placeholder="Repetition">
                        <Option value="once">Just Once</Option>
                        <Option value="daily">Repeats Every Day</Option>
                        <Option value="weekly">Repeats Every Week</Option>
                        <Option value="2-weekly">Repeats Every 2 Weeks</Option>
                        <Option value="monthly">Repeats Every Month</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="startDate"
                    rules={[{type: "object", required: true, message: "Please select starting date."}]}>
                    <DatePicker
                        inputReadOnly={true}
                        className="wb-date-picker"
                        onChange={date => onDateChange && onDateChange(date)}
                        style={{width: 300}}
                        format="MMM Do, YYYY"
                        placeholder="Starting date"
                    />
                </Form.Item>
            </Input.Group>
            {generateLocationSelect(appointmentBased, location, address, onSelectLocation)}
            {hasBranches(me, company) ? (
                <Form.Item
                    name="branches"
                    rules={[
                        {
                            required: true,
                            type: "array",
                            message: "Please select site."
                        }
                    ]}>
                    <CompanyBranchSelect mode="tags" branches={company.branches} />
                </Form.Item>
            ) : null}
            <FormTextArea
                onChange={evt => onMessageChange && onMessageChange(evt.target.value)}
                autoSize={{minRows: 4, maxRows: 4}}
                style={{marginBottom: appointmentBased ? 0 : 24}}
                placeholder="If you have anything to add or clarify about your request, please write it here"
            />
            {
                /*askedPrice*/ offeredPrice === 0 ? (
                    <div style={{display: "flex", justifyContent: "center", marginBottom: 0}}>
                        <FreeOfCharge />
                    </div>
                ) : /*askedPrice*/ offeredPrice ? (
                    <>
                        <label
                            style={{
                                fontSize: 16,
                                marginBottom: 40,
                                fontWeight: 700,
                                color: "rgba(0, 0, 0, 0.65)",
                                paddingLeft: 10
                            }}>
                            Budget per session
                        </label>
                        <Slider
                            tooltipVisible={false}
                            style={{marginLeft: 10, marginTop: 20, marginBottom: 60}}
                            step={10}
                            disabled={true}
                            onChange={value => onPriceChange && onPriceChange(value)}
                            min={min}
                            max={max}
                            //defaultValue={askedPrice || 0}
                            value={offeredPrice}
                            marks={marks}
                        />
                    </>
                ) : null
            }
        </>
    );
};

export const fixStartDateToStartOnTheDaySelected = (startDate, byweekday) => {
    if (byweekday && startDate) {
        byweekday = Array.isArray(byweekday) ? byweekday[0] : byweekday;
        startDate = startDate.day(DaysMapper.mapKeyToDay(byweekday));
    }

    return startDate;
};

export const EventOffer = inject("store")(
    observer(props => {
        const {professions, me, company} = useContext(AppContext);
        const {store} = props;
        const {productToBook} = store;
        const [form] = useForm();
        const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
            offeredPrice: null,
            priceType: null,

            freq: null,
            byweekday: null,
            dtstart: null,
            until: null,
            interval: 1,
            tzid: moment.tz.guess(),
            duration: null,
            message: null,

            providerDetails: null,

            // askedPrice: null,
            availableDays: null,
            productName: null,
            productDescription: null,
            productCoverPhoto: null,
            location: null,
            address: null,

            service: null,

            sendingOffer: false,
            offerSent: false,

            startDate: null,
            startTime: null
        });

        useEffect(() => {
            (async () => {
                await fetchServiceInfo();
            })();

            return () => {
                store.productToBook = null;
            };
        }, []);

        const fetchServiceInfo = async () => {
            try {
                const {match} = props;
                const {providerId, serviceId} = match.params;

                const [service, providerDetails] = await Promise.all([
                    HttpClient.get(`/api/services/${serviceId}`),
                    HttpClient.get(`/api/providers/${providerId}`)
                ]);

                const availableDays = Object.keys(providerDetails.availability)
                    .reduce((days, dayKey) => {
                        if (providerDetails.availability[dayKey].selected) {
                            days.push(daysArray[dayKeys[dayKey]]);
                        }

                        return days;
                    }, [])
                    .sort(function sortByDay(a, b) {
                        const day1 = a.toLowerCase();
                        const day2 = b.toLowerCase();
                        return dayKeys[day1] - dayKeys[day2];
                    })
                    .join(", ");

                setState({providerDetails, availableDays, service});
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we didn't manage to load the offer details :("}},
                    e.message
                );
            }
        };

        const selectProduct = product => {
            const {service} = state;
            setState({
                // askedPrice: service.recommendedPrice,
                offeredPrice: product.price,
                duration: product.durationMinutes || service.recommendedDurationMinutes,
                productName: product.productName,
                priceType: product.priceType || service.defaultPriceType,
                productDescription: product.description,
                productCoverPhoto: product.coverPhoto ? product.coverPhoto.imageUrl : null,
                location: product.location,
                address: product.address
            });
        };

        const resetProduct = () => {
            setState({
                // askedPrice: null,
                offeredPrice: null,
                duration: null,
                productName: null,
                priceType: null,
                productDescription: null,
                productCoverPhoto: null,
                location: null,
                address: null,
                freq: null,
                byweekday: null,
                dtstart: null,
                until: null,
                interval: 1,
                message: null,
                startDate: null,
                startTime: null
            });
        };

        const priceChanged = price => {
            setState({offeredPrice: price});
        };

        const dayChanged = byweekday => {
            setState({byweekday});
        };

        const repetitionChanged = freq => {
            setState({freq});
        };

        const dateChanged = startDate => {
            if (startDate) {
                dayChanged([moment(startDate).format("dd").toUpperCase()]);
            }
            setState({startDate});
        };

        const timeChanged = startTime => {
            setState({startTime});
        };

        const messageChanged = message => {
            setState({message});
        };

        const sendOffer = async values => {
            try {
                const {match} = props;
                const {serviceId, providerId} = match.params;

                let {
                    productName,
                    productDescription,
                    productCoverPhoto,
                    tzid,
                    interval,
                    duration,
                    providerDetails,
                    message,
                    // askedPrice,
                    offeredPrice,
                    priceType,
                    service,
                    address,
                    byweekday
                } = state;

                const serviceName = service.displayName;
                const appointmentBased = !!service.appointmentBased;

                let {
                    freq,
                    startTime,
                    startDate,
                    whoPays = "Company Pays",
                    location = state.location || "onsite",
                    branches
                } = values;

                startDate = fixStartDateToStartOnTheDaySelected(startDate, byweekday);
                byweekday = Array.isArray(byweekday) ? byweekday : [byweekday];

                const dtstart = !appointmentBased
                    ? moment(`${startDate.format("YYYY-MM-DD")} ${startTime.format("HH:mm")}`, "YYYY-MM-DD HH:mm")
                          .toDate()
                          .getTime()
                    : moment(`${startDate.format("YYYY-MM-DD")} ${SYSTEM_DEFAULT_START_TIME}`, "YYYY-MM-DD HH:mm")
                          .toDate()
                          .getTime();

                const info = FrequencyMapper.map({
                    freq,
                    interval,
                    byweekday,
                    dtstart,
                    tzid,
                    duration: duration || 60 * 10
                });
                const until = info.until;
                byweekday = info.byweekday;
                interval = info.interval;
                freq = info.freq;

                setState({sendingOffer: true});

                await HttpClient.put("/api/events/offer", {
                    serviceId,
                    serviceName,
                    productName,
                    productDescription,
                    productCoverPhoto,
                    freq,
                    byweekday,
                    dtstart,
                    until,
                    interval,
                    tzid,
                    duration,
                    whoPays,
                    location,
                    address: address || null,
                    appointmentBased: !!service.appointmentBased,
                    offsiteBased: !!service.offsiteBased,
                    providerId,
                    providerFirstName: providerDetails.firstName,
                    providerLastName: providerDetails.lastName,
                    message,
                    // askedPrice,
                    offeredPrice,
                    priceType,
                    branches: branches || null
                });

                GoogleAnalytics.event("Professional Offer", "Proposal", me.companyId);

                setState({offerSent: true});
                form.resetFields();
                resetProduct();
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we didn't manage to send your offer :("}},
                    e.message
                );
            }

            setState({sendingOffer: false});
        };

        const productsMenu = onChange => {
            const {match} = props;
            const {serviceId} = match.params;
            const {providerDetails} = state;

            const select = (evt, product) => {
                selectProduct(product);
                onChange(evt);
            };

            if (!providerDetails) return null;

            return (
                <Menu>
                    {providerDetails.services.reduce((items, supportedService, idx) => {
                        if (supportedService.service.serviceId === serviceId) {
                            items.push(
                                <Menu.Item
                                    onClick={evt => select(evt, supportedService)}
                                    key={`product-${idx}`}
                                    style={{fontSize: 16}}>
                                    {supportedService.productName}
                                </Menu.Item>
                            );
                        }
                        return items;
                    }, [])}
                </Menu>
            );
        };

        const {history} = props;

        const {
            offeredPrice,
            // askedPrice,
            providerDetails,
            freq,
            availableDays,
            sendingOffer,
            offerSent,
            duration,
            productName,
            location,
            address,
            service,
            tzid
        } = state;

        if (!providerDetails) {
            return (
                <div style={{display: "flex", justifyContent: "center", marginTop: 80}}>
                    <PageLoader align="flex-start" top={100} width={600} />
                </div>
            );
        }

        if (location) {
            form.setFieldsValue({
                location: location === LocationLabels.ONSITE_AND_ONLINE ? LocationLabels.ONLINE : location
            });
        }

        if (providerDetails && isAppointmentBasedProfession(providerDetails.profession, professions)) {
            form.setFieldsValue({location: LocationLabels.ONSITE});
        }

        const serviceName = service.displayName;
        const {employeesCanPay, appointmentBased} = service;

        if (productToBook && service && !productName) {
            selectProduct(productToBook);
            form.setFieldsValue({productName: productToBook.productName});
        }

        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", minWidth: 700}}>
                <EventOfferSent onClose={() => setState({offerSent: false})} visible={offerSent} history={history} />
                <PageTitleHeader style={{marginBottom: 0}}>
                    {serviceName} {` with ${providerDetails.firstName}`}
                </PageTitleHeader>
                <PageSubTitleLabel>{`Available on ${availableDays}`}</PageSubTitleLabel>
                <div
                    style={{
                        width: 600,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginBottom: 60
                    }}>
                    <Card style={{border: "1px solid #E8EDF5", backgroundColor: "#FCFDFE", borderRadius: 10}}>
                        <Form
                            form={form}
                            initialValues={{
                                startTime: moment(SYSTEM_DEFAULT_START_TIME, "HH:mm"),
                                whoPays: employeesCanPay ? void 0 : "Company pays"
                            }}
                            onFinish={async values => await sendOffer(values)}
                            style={{display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                            {!appointmentBased ? (
                                <Form.Item
                                    style={{marginBottom: 0}}
                                    name="productName"
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                !value
                                                    ? Promise.reject("Please select product or course.")
                                                    : Promise.resolve()
                                        }
                                    ]}>
                                    <CategoryDropdown
                                        style={{marginRight: 0, marginBottom: 24, width: "100%", maxWidth: 550}}
                                        index={1}
                                        text={productName ? productName : "Choose type of session"}
                                        menu={onChange => productsMenu(onChange)}
                                    />
                                </Form.Item>
                            ) : null}
                            <OfferFields
                                tzid={tzid}
                                appointmentBased={appointmentBased}
                                location={location}
                                address={address}
                                // askedPrice={askedPrice}
                                offeredPrice={offeredPrice}
                                company={company}
                                employeesCanPay={employeesCanPay}
                                duration={duration}
                                freq={freq}
                                onDateChange={value => dateChanged(value)}
                                onDayChange={value => dayChanged(value)}
                                onMessageChange={value => messageChanged(value)}
                                onPriceChange={value => priceChanged(value)}
                                onRepetitionChange={value => repetitionChanged(value)}
                                onTimeChange={value => timeChanged(value)}
                                onSelectLocation={location => setState({location})}
                                byweekday={state.byweekday}
                            />
                            <Form.Item className="wb-form-item-hidden">
                                <FormBottomBar backgroundColor="#00C693" color="white">
                                    <label className="wb-block-desktop-only" style={{fontSize: 18, fontWeight: 700}}>
                                        Offer {providerDetails.firstName} the chance to work with you!
                                    </label>
                                    <StyledButton
                                        className="wb-form-bottom-btn"
                                        loading={sendingOffer}
                                        htmlType="submit"
                                        style={{color: "#00A373", borderRadius: 25}}>
                                        Send offer
                                    </StyledButton>
                                </FormBottomBar>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>
        );
    })
);
