import React, {useCallback, useContext, useMemo, useState} from "react";
import {Checkbox, Collapse, Dropdown, Form, Menu, message, Progress, Spin, Switch, Tooltip, Upload} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {chunk, set} from "lodash";
import {downloadDeliveryAreaExcel} from "./excelUtils";
import {DaysHoursInput} from "./DaysHoursInput";
import {MAIN_AREAS} from "./AdminUploadUtils";
import {AutoDirectionProvider} from "../../AutoDirectionProvider";
import {ConfirmationRequiredWrapper, PageSubTitleLabel, SearchableSelect, StyledButton} from "../../components";
import {FormInputV2} from "../../components/form";
import {AutoCompleteAddressFormInput} from "../../utils/AutoCompleteAddress";
import {HttpClient} from "../../http/HttpClient";
import {NOOP} from "../../utils/NOOP";
import {getCellText, loadExcel} from "../../utils/excelUtils.js";
import {
    ActivityLocationTypes,
    GoogleMapMainAreaKey,
    GoogleMapSubAreaApiKeys,
    ProviderActivityPossibleLocations,
    ServiceIdToName
} from "../../consts.js";
import {DEFAULT_HOME_DELIVERY_PRICE} from "../../marketplace/MarketplaceBooking/consts";
import {
    deliveryFormFields,
    MaxShippingProvidersDeliveryPriceWithVAT,
    shippingProvidersOptions,
    shippingProvidersOptionsToName
} from "./consts.jsx";
import {isProductHasShippingProvider} from "./utils.js";
import {AppContext} from "../../AppContext.jsx";

const {Panel} = Collapse;

export const AdminUploadNewProductDeliveryPanel = ({
    deliveryOptions,
    onFormValueChange,
    otherProductsDeliveryAreas,
    doesProviderArriveToCustomer,
    deliveryPrice,
    homeDeliveryPrice,
    isUsingProviderDeliveryOptions,
    selectedShippingProviders,
    maxShippingProvidersDeliveryPrice,
    disabledFields,
    shippingProviders = []
}) => {
    const {me} = useContext(AppContext);
    const [loadingExcel, setLoadingExcel] = useState(0);

    const updateAreaPrice = useCallback(
        (evt, placeId) => {
            const parsedValue = parseInt(evt.target.value);
            onFormValueChange(
                `deliveryOptions.deliveryAreas.${placeId}.price`,
                isNaN(parsedValue) ? null : parsedValue
            );

            if (!deliveryOptions?.deliveryAreas?.[placeId]?.displayName) {
                onFormValueChange(
                    `deliveryOptions.deliveryAreas.${placeId}.displayName`,
                    MAIN_AREAS.find(area => area.placeId === placeId)?.displayName
                );
            }
        },
        [deliveryOptions, onFormValueChange]
    );

    const addManyAreas = useCallback(
        async areasToAdd => {
            const areasChunks = chunk(areasToAdd, 20);

            const failedAreas = [];

            const deliveryAreas = Object.entries(deliveryOptions?.deliveryAreas ?? {}).reduce(
                (acc, [mainAreaPlaceId, mainAreaProps]) => {
                    acc[mainAreaPlaceId] = {...mainAreaProps, subAreas: null};
                    return acc;
                },
                {}
            );

            for (let i = 0; i < areasChunks.length; i++) {
                const results = await HttpClient.safePost("/api/location/address", {
                    addresses: areasChunks[i].map(({area, placeId}) => ({
                        address: area,
                        placeId
                    })),
                    addressesTypes: GoogleMapSubAreaApiKeys,
                    country: "IL"
                });

                if (results.error) {
                    message.error("כישלון בהעלת אקסל");
                    return;
                }

                failedAreas.push(...results.filter(({error}) => error));

                results.forEach(addressInfo => {
                    const parentArea = addressInfo?.address_components?.find(({types}) =>
                        types.includes(GoogleMapMainAreaKey)
                    );

                    if (!parentArea) {
                        failedAreas.push(addressInfo);
                        return;
                    }

                    const currentArea = areasChunks[i].find(
                        ({area, placeId}) =>
                            addressInfo.place_id === placeId || addressInfo.textualAddresses.includes(area)
                    );

                    set(deliveryAreas, `${parentArea.place_id}.subAreas.${addressInfo.place_id}`, {
                        displayName: addressInfo.textualAddresses[0],
                        placeId: addressInfo.place_id,
                        minNotice: currentArea?.minNotice,
                        price: currentArea?.price
                    });

                    setLoadingExcel(Math.round(100 * ((i + 1) / areasChunks.length)));
                });
            }

            onFormValueChange("deliveryOptions.deliveryAreas", deliveryAreas);

            if (failedAreas.length) {
                message.error(
                    `שגיאה בהוספת אזורים: ${failedAreas.map(({textualAddresses}) => textualAddresses[0]).join(", ")}`
                );
            }
        },
        [onFormValueChange, deliveryOptions]
    );

    const excelUploadProps = useMemo(
        () => ({
            customRequest: NOOP,
            action: async file => {
                return new Promise(async (resolve, reject) => {
                    const fileReader = new FileReader();
                    fileReader.onload = async data => {
                        setLoadingExcel(1);
                        const arrayBuffer = data.target.result;
                        const workbook = await loadExcel(arrayBuffer, file.type);
                        const sheet = workbook.worksheets[0];

                        const results = [];

                        sheet.eachRow(({values: row}) => {
                            results.push({
                                area: getCellText(row[1])?.toString(),
                                price: getCellText(row[2]),
                                minNotice: getCellText(row[3]),
                                placeId: getCellText(row[4])
                            });
                        });

                        const cleanResults = results.splice(1).filter(({area}) => area);

                        await addManyAreas(cleanResults);
                        setLoadingExcel(0);
                        return resolve();
                    };

                    fileReader.onerror = () => {
                        setLoadingExcel(0);
                    };

                    fileReader.readAsArrayBuffer(file);
                });
            },
            name: "file",
            accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            showUploadList: false,
            withCredentials: true
        }),
        []
    );

    const onDeliveryAreaAdded = useCallback(
        async (searchText, {place_id}) => {
            const addressInfo = await HttpClient.safeGet(`/api/location/placeId/${place_id}`);
            const parentArea = addressInfo?.address_components?.find(({types}) => types.includes(GoogleMapMainAreaKey));

            if (!parentArea) {
                message.error("קרתה שגיאה בהוספת איזור");
                return;
            }

            onFormValueChange(`deliveryOptions.deliveryAreas.${parentArea.place_id}.subAreas.${addressInfo.place_id}`, {
                displayName: addressInfo.textualAddresses[0],
                placeId: addressInfo.place_id
            });
        },
        [onFormValueChange]
    );

    return (
        <div className="admin-upload-new-product-delivery-options">
            <PageSubTitleLabel
                style={{
                    marginTop: 30,
                    marginBottom: 10,
                    fontSize: 20,
                    color: "#ED7FA6",
                    textDecoration: "underline"
                }}>
                משלוח
            </PageSubTitleLabel>
            <Form.Item name="doesProviderArriveToCustomer">
                <div className="admin-upload-does-provider-arrive-to-customer">
                    <span>האם הספק נוסע אל הלקוח?</span>
                    <Switch
                        onChange={checked => onFormValueChange("doesProviderArriveToCustomer", checked)}
                        checked={doesProviderArriveToCustomer ?? false}
                        checkedChildren="נוסע"
                        unCheckedChildren="משלח"
                    />
                </div>
            </Form.Item>

            <div className="admin-upload-new-product-input-line">
                <label>חברות שילוח</label>
                <div>
                    <SearchableSelect
                        id="product-shipping-providers"
                        style={{width: "100%"}}
                        mode="multiple"
                        disabled={disabledFields.includes(deliveryFormFields.shippingProviders)}
                        onDeselect={value => {
                            const updatedShippingProviders = selectedShippingProviders?.filter(sp => sp !== value);
                            onFormValueChange("shippingProviders", updatedShippingProviders);
                        }}
                        onSelect={value => {
                            const updatedShippingProviders = [...(selectedShippingProviders ?? []), value];
                            onFormValueChange("shippingProviders", updatedShippingProviders);
                        }}
                        className="wb-profession-selection"
                        value={selectedShippingProviders}
                        placeholder="בחרו חברת שילוח שתספק את המוצרים שלכם">
                        {[
                            {
                                deliveryProviderId: shippingProvidersOptions.regular,
                                name: shippingProvidersOptionsToName[shippingProvidersOptions.regular]
                            },
                            ...shippingProviders
                        ]?.map(shippingProvider => {
                            return (
                                <Option
                                    key={shippingProvider.deliveryProviderId}
                                    value={shippingProvider.deliveryProviderId}>
                                    {shippingProvider.name}
                                </Option>
                            );
                        })}
                    </SearchableSelect>
                </div>
                {isProductHasShippingProvider({shippingProviders: selectedShippingProviders}) ? (
                    <div className="admin-upload-new-product-disclaimer-container">
                        <b>מוצר זה יישלח דרך שליח וולבי 🥳</b>

                        {!!me?.isAdmin && (
                            <>
                                <br></br>
                                <div>עד 10 ק״מ מבית העסק = 70₪</div>
                                <div>מעבר ל-10 ק״מ = הוספת 15 ₪ לכל 10 ק״מ</div>
                                <br></br>
                                <div>סה״כ מחיר המשלוח תמיד בטווח</div>
                                <div>70-320 ₪</div>
                            </>
                        )}
                    </div>
                ) : null}
            </div>

            <div className="admin-upload-new-product-input-line">
                <label>סכום מקסימלי לעלות משלוח עם חברת שילוח</label>
                <div>
                    <FormInputV2
                        placeholder={`מחיר ברירת מחדל: ${MaxShippingProvidersDeliveryPriceWithVAT}`}
                        onChange={evt =>
                            onFormValueChange("maxShippingProvidersDeliveryPrice", evt.target?.valueAsNumber)
                        }
                        disabled={disabledFields.includes(deliveryFormFields.maxShippingProvidersDeliveryPrice)}
                        onWheel={e => e.target.blur()}
                        type="number"
                        value={maxShippingProvidersDeliveryPrice}
                    />
                    <StyledButton
                        className="single-button"
                        disabled={disabledFields.includes(deliveryFormFields.maxShippingProvidersDeliveryPrice)}
                        style={{height: 25, alignSelf: "end"}}
                        onClick={() => {
                            onFormValueChange("maxShippingProvidersDeliveryPrice", null);
                            message.success("השדה נמחק בהצלחה");
                        }}>
                        מחק שדה
                    </StyledButton>
                </div>
            </div>

            <div className="admin-upload-new-product-input-line">
                <label>עלות {ProviderActivityPossibleLocations[ActivityLocationTypes.homeDelivery]}</label>
                <div>
                    <FormInputV2
                        placeholder={`מחיר ברירת מחדל: ${DEFAULT_HOME_DELIVERY_PRICE}`}
                        onChange={evt => onFormValueChange("homeDeliveryPrice", Number(evt.target.value))}
                        onWheel={e => e.target.blur()}
                        type="number"
                        value={homeDeliveryPrice}
                    />
                    <StyledButton
                        className="single-button"
                        style={{height: 25, alignSelf: "end"}}
                        onClick={() => {
                            onFormValueChange("homeDeliveryPrice", null);
                            message.success(
                                `השדה עלות ${
                                    ProviderActivityPossibleLocations[ActivityLocationTypes.homeDelivery]
                                } נמחק בהצלחה`
                            );
                        }}>
                        מחק שדה
                    </StyledButton>
                </div>
            </div>
            <div className="admin-upload-new-product-input-line">
                <label>עלות משלוח</label>
                <div>
                    <FormInputV2
                        onWheel={e => e.target.blur()}
                        onChange={evt => onFormValueChange("deliveryPrice", Number(evt.target.value))}
                        type="number"
                        value={deliveryPrice}
                    />
                </div>
            </div>
            <div className="admin-upload-new-product-input-line">
                <label>
                    דיוק טווח שילוח אפשרי
                    <Tooltip title="כמה זמן יקח לספק להביא את המוצר אל הלקוח מרגע ההזמנה">
                        <QuestionCircleOutlined style={{cursor: "pointer"}} />
                    </Tooltip>
                </label>
                <div id="delivery-define-time-range" className="admin-upload-new-product-delivery-time-range">
                    <span>מועד המוגדר על ידי הלקוח +</span>
                    <Form.Item>
                        <DaysHoursInput
                            time={deliveryOptions?.arrivingTimeRange}
                            setTime={value => {
                                onFormValueChange("deliveryOptions.arrivingTimeRange", value);
                            }}
                        />
                    </Form.Item>
                </div>
            </div>
            <div className="admin-upload-new-product-input-line">
                <label>
                    דיוק טווח התראה לאספקה
                    <Tooltip title="כמה זמן מראש צריך להתריע לספק">
                        <QuestionCircleOutlined style={{cursor: "pointer"}} />
                    </Tooltip>
                </label>
                <div id="delivery-time-range" className="admin-upload-new-product-delivery-time-range">
                    <span>מועד ההזמנה על ידי הלקוח +</span>
                    <Form.Item>
                        <DaysHoursInput
                            time={deliveryOptions?.minNotice}
                            setTime={value => {
                                onFormValueChange("deliveryOptions.minNotice", value);
                            }}
                        />
                    </Form.Item>
                </div>
            </div>

            {/*temporary disabled*/}

            {/*<div className="admin-upload-new-product-input-line">*/}
            {/*    <label>*/}
            {/*        דיוק טווח התראה לאיסוף*/}
            {/*    </label>*/}
            {/*    <div className="admin-upload-new-product-delivery-time-range">*/}
            {/*                        <span>*/}
            {/*                            מועד ההזמנה על ידי הלקוח +*/}
            {/*                        </span>*/}
            {/*        <Form.Item name={["deliveryOptions", "pickupNotice"]}>*/}
            {/*            <DaysHoursInput time={(deliveryOptions?.pickupNotice)}*/}
            {/*                            setTime={(value) => {*/}
            {/*                                onFormValueChange("deliveryOptions.pickupNotice", value)*/}
            {/*                            }}*/}
            {/*            />*/}
            {/*        </Form.Item>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<div className="admin-upload-new-product-input-line">*/}
            {/*    <label>*/}
            {/*        אפשרויות שילוח מהירות*/}
            {/*    </label>*/}
            {/*    <Form.Item name={["deliveryOptions", "deliveryCompanies"]}>*/}
            {/*        <Checkbox.Group>*/}
            {/*            {*/}
            {/*                Object.values(FAST_DELIVERY_COMPANIES).map(deliveryCompany => (*/}
            {/*                    <Checkbox key={deliveryCompany} value={deliveryCompany}*/}
            {/*                              style={{lineHeight: '32px'}}>*/}
            {/*                        שילוח עם {deliveryCompany}*/}
            {/*                    </Checkbox>*/}
            {/*                ))*/}
            {/*            }*/}
            {/*        </Checkbox.Group>*/}
            {/*    </Form.Item>*/}
            {/*</div>*/}
            <div className="admin-upload-new-product-input-line">
                <label>הגבלת כמות לשילוח</label>
                <div style={{marginRight: 10}}>
                    <FormInputV2
                        onChange={evt => onFormValueChange("deliveryOptions.itemLimit", Number(evt.target.value))}
                        placeholder="הגבלת כמות לשילוח מהיר"
                        value={deliveryOptions?.itemLimit}
                        onWheel={e => e.target.blur()}
                        type="number"
                    />
                </div>
            </div>
            <div className="admin-upload-new-product-input-line">
                <Collapse>
                    <Panel
                        header={
                            <>
                                {loadingExcel ? <Spin /> : null}
                                תמחור משלוח על ידי הספק
                                {isUsingProviderDeliveryOptions ? (
                                    <>
                                        <span> </span>
                                        <span>{"(משתמש בהגדרות השילוח של הספק)"}</span>
                                    </>
                                ) : null}
                            </>
                        }
                        key="1">
                        {MAIN_AREAS.map(area => (
                            <React.Fragment key={area.placeId}>
                                <div className="admin-upload-new-product-delivery-time-range admin-upload-new-product-delivery-area-line">
                                    <span>{area.displayName}</span>
                                    <Form.Item style={{marginRight: 10}}>
                                        <FormInputV2
                                            onChange={evt => updateAreaPrice(evt, area.placeId)}
                                            placeholder="מחיר משלוח לאזור"
                                            value={deliveryOptions?.deliveryAreas?.[area.placeId]?.price}
                                            onWheel={e => e.target.blur()}
                                            type="number"
                                        />
                                    </Form.Item>
                                </div>
                                {deliveryOptions?.deliveryAreas?.[area.placeId]?.subAreas ? (
                                    <div className="admin-upload-new-product-delivery-sub-areas">
                                        {Object.values(deliveryOptions.deliveryAreas[area.placeId].subAreas).map(
                                            subArea => (
                                                <div
                                                    key={subArea.placeId}
                                                    className="admin-upload-new-product-delivery-time-range admin-upload-new-product-delivery-area-line">
                                                    <span>{subArea.displayName}</span>
                                                    <Form.Item className="admin-upload-new-product-delivery-sub-area-inputs">
                                                        <FormInputV2
                                                            onChange={evt =>
                                                                onFormValueChange(
                                                                    `deliveryOptions.deliveryAreas.${area.placeId}.subAreas.${subArea.placeId}.price`,
                                                                    isNaN(parseInt(evt.target.value))
                                                                        ? null
                                                                        : parseInt(evt.target.value)
                                                                )
                                                            }
                                                            placeholder="מחיר משלוח לאזור"
                                                            value={
                                                                deliveryOptions?.deliveryAreas?.[area.placeId]
                                                                    .subAreas?.[subArea.placeId].price
                                                            }
                                                            onWheel={e => e.target.blur()}
                                                            type="number"
                                                        />
                                                        <FormInputV2
                                                            onChange={evt =>
                                                                onFormValueChange(
                                                                    `deliveryOptions.deliveryAreas.${area.placeId}.subAreas.${subArea.placeId}.minNotice`,
                                                                    isNaN(parseInt(evt.target.value))
                                                                        ? null
                                                                        : parseInt(evt.target.value)
                                                                )
                                                            }
                                                            placeholder="טווח התראה לאזור"
                                                            value={
                                                                deliveryOptions?.deliveryAreas?.[area.placeId]
                                                                    .subAreas?.[subArea.placeId].minNotice
                                                            }
                                                            onWheel={e => e.target.blur()}
                                                            type="number"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            )
                                        )}
                                    </div>
                                ) : null}
                                <div className="break-line" />
                            </React.Fragment>
                        ))}
                        <AutoCompleteAddressFormInput
                            placeholder="שם עיר או אזור נוסף"
                            onChange={onDeliveryAreaAdded}
                            locationTypes={GoogleMapSubAreaApiKeys}
                            countriesToSearchIn={"IL"}
                        />
                    </Panel>
                </Collapse>
                <div className="admin-upload-new-product-delivery-areas-buttons">
                    <span onClick={() => downloadDeliveryAreaExcel(deliveryOptions?.deliveryAreas)}>
                        <ArrowDownOutlined />
                        הורדת אקסל
                    </span>
                    <Upload {...excelUploadProps}>
                        <span className="admin-upload-new-product-delivery-areas-upload-excel">
                            <ArrowUpOutlined />
                            {loadingExcel ? <Progress width={60} type="circle" percent={loadingExcel} /> : null}
                            טעינת אקסל
                        </span>
                    </Upload>
                    <Dropdown
                        trigger="click"
                        overlay={
                            <Menu
                                onClick={({item}) => {
                                    onFormValueChange("deliveryOptions.deliveryAreas", item.props.deliveryAreas);
                                }}>
                                {otherProductsDeliveryAreas?.map(({name, deliveryAreas}) => (
                                    <Menu.Item key={name} deliveryAreas={deliveryAreas}>
                                        {name}
                                    </Menu.Item>
                                ))}
                            </Menu>
                        }>
                        <span>העתקה ממוצר אחר</span>
                    </Dropdown>
                    <ConfirmationRequiredWrapper
                        title="תמחורי אזור השילוח ימחקו"
                        onYes={() => onFormValueChange("deliveryOptions.deliveryAreas", null)}>
                        <span className="admin-upload-new-product-delivery-areas-delete-all">מחיקת אזורי שילוח</span>
                    </ConfirmationRequiredWrapper>
                </div>
            </div>
        </div>
    );
};
