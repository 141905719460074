import {GoogleAnalytics} from "./GoogleAnalytics";

export class FormAbandonment {
    interactedFields = {};
    stopListening = false;

    constructor(formName) {
        this.formName = formName;
    }

    listToAbandonment() {
        console.log("Listening to abandonment on form:", this.formName, "...");
        window.addEventListener("beforeunload", () => {
            if (!this.stopListening) {
                const fields = Object.keys(this.interactedFields).sort((f1, f2) => {
                    return f1 < f2 ? -1 : 1;
                });
                const label = fields.join(" > ");
                console.log(this.formName + " Abandonment(!):", label);
                GoogleAnalytics.event(this.formName, "Abandonment", label, fields.length, "beacon");
            }
        });
    }

    stopListeningToAbandonment() {
        console.log("Stop listening to abandonment on form:", this.formName);
        this.stopListening = true;
    }

    addInteractedFields(names, index) {
        names.forEach(name => {
            name = name.replace("extra.", "");
            console.log("Detected new interacted field:", name);
            this.interactedFields[typeof index !== "undefined" ? `${index}-${name}` : name] = true;
        });
    }
}
