import cover_placeholder from "../../images/placeholder-image.jpg";
import React from "react";
import {EventPreviewCover} from "./EventPreviewCover";
import {EventPreviewContent} from "./EventPreviewContent";
import {EventPreviewActions} from "./EventPreviewActions";

export const EventInfoPreview = ({
    event,
    dateMom,
    onEdit,
    hideBorderBottom = false,
    showReload = false,
    showCoverTag = false,
    onChange,
    value,
    onRegister,
    onUnregister,
    onBook,
    actionLoading,
    onJoin,
    viewOnly = false,
    coverStyle,
    coverSize,
    bodyStyle,
    useDefaultTheme = false,
    withDefaultCover = true,
    onDisplayChange,
    participantsCount,
    contentStyle,
    actionsStyle,
    eventDescription
}) => {
    if (viewOnly) {
        onRegister = null;
        onUnregister = null;
        onBook = null;
    }

    const getImageUrl = () => {
        if (value) {
            return value.imageUrl;
        } else if (event.product && event.product.coverPhoto && event.product.coverPhoto.imageUrl) {
            return event.product.coverPhoto.imageUrl;
        } else if (event.productCoverPhoto) {
            return event.productCoverPhoto;
        }
        return withDefaultCover ? cover_placeholder : null;
    };

    let imageUrl = getImageUrl();

    const isDisplayed = typeof event.displayed === "undefined" || event.displayed === true;

    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: hideBorderBottom ? 0 : 30,
                borderBottom: hideBorderBottom ? "none" : "1px solid #DADEE4",
                opacity: isDisplayed ? 1 : 0.5
            }}>
            <EventPreviewCover
                event={event}
                coverStyle={coverStyle}
                showReload={showReload}
                imageUrl={imageUrl}
                onChange={onChange}
                coverSize={coverSize}
                showCoverTag={showCoverTag}
            />
            <div style={{display: "flex", flexDirection: "column", width: "100%", ...bodyStyle}}>
                <EventPreviewContent
                    event={event}
                    dateMom={dateMom}
                    participantsCount={participantsCount}
                    contentStyle={contentStyle}
                    eventDescription={eventDescription}
                />
                <EventPreviewActions
                    event={event}
                    viewOnly={viewOnly}
                    dateMom={dateMom}
                    useDefaultTheme={useDefaultTheme}
                    actionLoading={actionLoading}
                    participantsCount={participantsCount}
                    onEdit={onEdit}
                    onJoin={onJoin}
                    onRegister={onRegister}
                    onUnregister={onUnregister}
                    onBook={onBook}
                    onDisplayChange={onDisplayChange}
                    isDisplayed={isDisplayed}
                    actionsStyle={actionsStyle}
                />
            </div>
        </div>
    );
};
