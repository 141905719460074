import moment from "moment";
import {excelSheetsTitle, BUDGET_LOAD_TRANSACTION, getOrderPaymentType} from "./consts";
import {getCompanyOrdersDiscountRow, getDeliveryRow, getDiscountRow, getTipRow} from "./orderExcelExporterUtils";
import {PaymentType} from "../consts.js";
import {createWorkbook, createWorksheet, DownloadExcel, EXCEL_MAX_ROWS} from "../utils/excelUtils.js";
import {extractVatPercent, reduceVAT} from "../utils/calculationUtils";
import {getStatus, OrderStatus} from "../components";
import {removeEmojis} from "../utils/StringFormat";
import {ProviderDealerTypes} from "../admin/upload/AdminUploadUtils";
import {InvoicesStatusToNameAndColor} from "../provider/ProviderManageInvoices/consts";
import {getInvoicePriceByProviderId} from "./utils";

const PROVIDER_PAYMENT_NUMBER = 15;
const CREDIT_CARD_ERROR = "אשראי לא נסלק";

export const ExportTableToCRMExcel = async (
    transactions,
    adminMode,
    firstTimeOrderingCompaniesInfo,
    companyUsers,
    systemCSMs,
    providersDetails,
    providersInvoices
) => {
    const workbook = createWorkbook();

    const [needInvoiceTransactions, budgetsOrder, creditCardOrders] = transactions.reduce((acc, order) => {
        let listIndexToAddTo = 0;

        if (order.paymentType === PaymentType.Budget && !order.payOnOrderBudgetTransaction) {
            listIndexToAddTo = 1;
        } else if (
            order.paymentType === PaymentType.Card &&
            !getStatus(order).some(({id}) => id === OrderStatus.creditCardNotPaid)
        ) {
            listIndexToAddTo = 2;
        }

        acc[listIndexToAddTo] = acc[listIndexToAddTo] ?? [];
        acc[listIndexToAddTo].push(order);

        return acc;
    }, []);

    const excelSheets = adminMode
        ? [
              {
                  title: excelSheetsTitle.needInvoiceTransactions,
                  list: needInvoiceTransactions
              },
              {
                  title: excelSheetsTitle.budgetsOrder,
                  list: budgetsOrder
              },
              {
                  title: excelSheetsTitle.creditCardOrders,
                  list: creditCardOrders
              }
          ]
        : [
              {
                  title: excelSheetsTitle.orders,
                  list: transactions
              }
          ];

    excelSheets.forEach(({list = [], title}, index) => {
        const sheet = createWorksheet(workbook, title);
        addColumns(sheet, adminMode);
        addRows(sheet, list, !index, adminMode, title, companyUsers, systemCSMs, providersDetails, providersInvoices);

        if (adminMode) {
            addFormatting(sheet);
        }
    });

    if (adminMode && firstTimeOrderingCompaniesInfo?.length) {
        const sheet = createWorksheet(workbook, "לקוחות חדשים בטווח");
        addNewCustomersColumns(sheet);
        addNewCustomersRows(sheet, firstTimeOrderingCompaniesInfo);
    }

    await DownloadExcel(workbook, "orders");
};

const addColumns = (sheet, adminMode) => {
    sheet.columns = [
        {header: "ח.פ", key: "businessNumber"},
        ...(adminMode ? [{header: "מספר לקוח", key: "customerNumber"}] : []),
        {header: "שם לחשבונית", key: "companyName"},
        {header: "שם החבילה", key: "productName"},
        {header: "מס׳ הזמנה", key: "proposalId"},
        {header: "תאריך הזמנה", key: "orderDate"},
        {header: "תאריך הספקה", key: "startTime"},
        {header: "שם המוצר ללקוח", key: "orderSummary"},
        {header: "סכום ₪ (כולל מעמ)", key: "price"},
        {header: "סכום ₪ (לפני מעמ)", key: "priceWithoutTax"},
        {header: "איש קשר (מבצע ההזמנה)", key: "contactName"},
        {header: "טלפון (מבצע ההזמנה)", key: "contactNumber"},
        {header: "מייל (מבצע ההזמנה)", key: "contactEmail"},
        {header: "איש קשר (חשבונאות)", key: "financeContactName"},
        {header: "טלפון (חשבונאות)", key: "financeContactNumber"},
        {header: "מייל (חשבונאות)", key: "financeContactEmail"},
        ...(adminMode
            ? [
                  {header: "גבייה", key: "paymentType"},
                  {header: "תנאי תשלום", key: "paymentCondition"}
              ]
            : []),
        {header: "שם הספק", key: "providerName"},
        {header: "מנהלת הלקוח וולבי", key: "csmName"},
        ...(adminMode
            ? [
                  {header: "אחוז עמלה", key: "commission"},
                  {header: "עלות המכר (ללא מע״מ)", key: "providerPaymentWithoutVAT"},
                  {header: "עלות המכר (כולל מע״מ)", key: "providerPayment"},
                  {header: "סטטוס חשבונית", key: "providerInvoiceStatus"}
              ]
            : []),
        {header: "להפריד לחשבונית נפרדת", key: "separateInvoice"},
        ...(adminMode ? [{header: "הערות", key: "comments"}] : []),
        ...(!adminMode ? [{header: "מחלקה", key: "department"}] : []),
        {header: "מע״מ", key: "vat"}
    ];
};

const addNewCustomersColumns = sheet => {
    sheet.columns = [
        {header: "ח.פ", key: "businessNumber"},
        {header: "מספר לקוח", key: "customerNumber"},
        {header: "שם לחשבונית", key: "companyName"},
        {header: "איש קשר", key: "contactName"},
        {header: "טלפון", key: "contactNumber"},
        {header: "מייל", key: "contactEmail"}
    ];
};

const addRows = (
    sheet,
    orders,
    isMonthlyBill,
    adminMode,
    sheetTitle,
    companyUsers,
    systemCSMs,
    providersDetails,
    providersInvoices
) => {
    const rows = orders.reduce((allRows, order, index) => {
        const subOrders = order?.subOrders?.length ? order.subOrders : [order];

        for (const subOrder of subOrders) {
            const isBudgetTransaction = !!subOrder?.isBudgetTransaction;
            subOrder.productName = removeEmojis(subOrder.productName);
            const createdAt = moment(subOrder.createdAt).format("DD.MM.YY");
            const supplyDate = moment(subOrder.dtstart).format("DD.MM.YY");

            const paymentTypeId =
                subOrder.appointmentId || subOrder.paymentType === BUDGET_LOAD_TRANSACTION
                    ? PaymentType.BankTransfer
                    : subOrder.paymentType;

            const paymentType = getStatus(subOrder).some(({id}) => id === OrderStatus.creditCardNotPaid)
                ? CREDIT_CARD_ERROR
                : getOrderPaymentType({...subOrder, paymentType: paymentTypeId});

            const isOrderPartOfProductBundleOrder = subOrder.isSubOrder && subOrder?.parentOrderId;

            function getOrderUserDepartment(order) {
                if (!companyUsers?.length) return "";

                const userOrder = companyUsers.find(user => user.userId === order.creatingUser);
                return userOrder.department;
            }

            function getOrderCsmOrFirstUser(order) {
                const csmFound = order.assignedCSMs?.find(csmUser => {
                    const systemCsmUser = systemCSMs.find(systemCsmUser => systemCsmUser.userId === csmUser.userId);
                    return systemCsmUser?.position === "CSM";
                });

                return csmFound?.firstName ?? order?.assignedCSMs?.[0]?.firstName;
            }

            function getProviderInvoiceStatus(order) {
                const providerInvoice = providersInvoices?.[order.eventId]?.[0];
                return InvoicesStatusToNameAndColor[providerInvoice?.status?.id]?.name ?? "";
            }

            function calculateOfferedPrice(order) {
                if (!order?.isBudgetTransaction || !order.ordersDiscount) {
                    return Number(order.offeredPrice);
                }

                const calculateCompanyOrdersDiscountAmount =
                    (Number(order.ordersDiscount) * Number(order.offeredPrice)) / 100;

                return Number(order?.offeredPrice) + calculateCompanyOrdersDiscountAmount;
            }

            const isHasFinanceContactInfo = !!Object.values(subOrder?.financeContact ?? {})?.length;

            const row = [
                subOrder?.company?.businessNumber || "",
                ...(adminMode ? [isOrderPartOfProductBundleOrder] : []),
                subOrder.companyName,
                subOrder.productName,
                subOrder?.proposalId ?? subOrder.eventId,
                createdAt,
                supplyDate,
                `${subOrder.productName} ${supplyDate} (${subOrder.productAmount ?? ""} ${
                    subOrder.productAmountType ?? ""
                }) ${subOrder.invoiceComment ? `- ${subOrder.invoiceComment}` : ""} ${
                    subOrder.invoicePONumber ? `- ${subOrder.invoicePONumber}` : ""
                }`,
                calculateOfferedPrice(subOrder),
                reduceVAT(calculateOfferedPrice(subOrder), subOrder.vat),
                subOrder.companyContactName,
                subOrder.companyPhone,
                subOrder.companyContactEmail,
                isHasFinanceContactInfo
                    ? subOrder?.financeContact?.name
                    : `${subOrder?.companyContactName} (מבצע ההזמנה)`,
                isHasFinanceContactInfo ? subOrder?.financeContact?.phone : subOrder?.companyPhone,
                isHasFinanceContactInfo ? subOrder?.financeContact?.email : subOrder?.companyContactEmail,
                ...(adminMode
                    ? [
                          paymentType ?? subOrder.paymentType,
                          isMonthlyBill ? `שוטף +${subOrder.companyPaymentOption ?? 30}` : "שולם"
                      ]
                    : []),
                subOrder.providerName,
                getOrderCsmOrFirstUser(subOrder),
                ...(adminMode
                    ? [
                          subOrder.commission,
                          getInvoicePriceByProviderId(
                              calculateOfferedPrice(subOrder),
                              subOrder.commission,
                              subOrder.providerId,
                              providersDetails,
                              false,
                              subOrder.vat
                          ),
                          getInvoicePriceByProviderId(
                              calculateOfferedPrice(subOrder),
                              subOrder.commission,
                              subOrder.providerId,
                              providersDetails,
                              true,
                              subOrder.vat
                          ),
                          getProviderInvoiceStatus(subOrder)
                      ]
                    : []),
                subOrder.separateInvoice ? "להפריד לחשבונית נפרדת" : null,
                ...(adminMode ? [subOrder.adminComment?.content ?? subOrder.adminComment] : []),
                ...(!adminMode ? [getOrderUserDepartment(subOrder)] : []),
                extractVatPercent(subOrder.vat)
            ];

            allRows.push(row);

            if (subOrder.deliveryPrice) {
                const deliveryRow = getDeliveryRow(row, subOrder, supplyDate, false, adminMode);
                allRows.push(deliveryRow);
            }

            if (order?.couponDiscountAmount || order?.companyDiscountAmount) {
                const discountRow = getDiscountRow(row, subOrder, createdAt, adminMode);
                allRows.push(discountRow);
            }

            if (subOrder.tip) {
                const tipRow = getTipRow(row, subOrder, createdAt, adminMode);

                if (adminMode) {
                    tipRow[PROVIDER_PAYMENT_NUMBER] = "";
                }

                allRows.push(tipRow);
            }

            if (subOrder?.ordersDiscount) {
                const ordersDiscount = getCompanyOrdersDiscountRow(row, subOrder, createdAt, adminMode);

                if (adminMode) {
                    ordersDiscount[PROVIDER_PAYMENT_NUMBER] = "";
                }

                allRows.push(ordersDiscount);
            }
        }

        return allRows;
    }, []);

    if (sheetTitle === excelSheetsTitle.needInvoiceTransactions) {
        for (const row of rows) {
            const [, isOrderPartOfProductBundleOrder] = row;

            const isOrderPartOfProductBundleOrderIndex = row.indexOf(isOrderPartOfProductBundleOrder);

            if (isOrderPartOfProductBundleOrderIndex !== -1) {
                row[isOrderPartOfProductBundleOrderIndex] = "";
            }

            const selectedRow = sheet.addRow(row);

            if (isOrderPartOfProductBundleOrder) {
                selectedRow.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: {argb: "FFFFA000"}
                };
            }
        }
    } else {
        sheet.addRows(rows);
    }
};

const addNewCustomersRows = (sheet, orders) => {
    const rows = orders.map(order => [
        order?.company?.businessNumber,
        "",
        order.companyName,
        {
            richText: [
                {
                    text: order?.financeContact?.name ?? order.companyContactName,
                    ...(order?.financeContact?.name && {
                        font: {
                            bold: true,
                            color: {
                                argb: "0000FF00",
                                theme: 1
                            }
                        }
                    })
                }
            ]
        },
        order?.financeContact?.phone ?? order.companyPhone,
        order?.financeContact?.email ?? order.companyContactEmail
    ]);

    sheet.addRows(rows);
};

const addFormatting = sheet => {
    sheet.addConditionalFormatting({
        ref: `$1:${EXCEL_MAX_ROWS}`,
        rules: [
            {
                type: "expression",
                formulae: [`$L1="אשראי לא נסלק"`],
                style: {
                    fill: {
                        type: "pattern",
                        pattern: "solid",
                        bgColor: {argb: "E6B8B7"}
                    }
                }
            }
        ]
    });
};
