import React, {useCallback, useMemo, useState} from "react";
import classNames from "classnames";
import {groupBy, partition} from "lodash";
import {Collapse} from "antd";
import {DownOutlined, SyncOutlined} from "@ant-design/icons";
import {Counter} from "../../components/Counter";
import {NOOP} from "../../utils/NOOP";
import {ProgressiveImageLoader} from "../../components";
import "./order-form-modular-products-panel.css";
import {RenderSubProductsList} from "./RenderSubProductsList.jsx";
import Cultery from "../../images/cutlery.svg";

const {Panel} = Collapse;

export const OrderFormModularProductsPanel = ({
    onChange = NOOP,
    showProviderSubProductsImages = false,
    calculateModularProductPrice,
    subProducts = [],
    log,
    adminMode
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(true);

    const defaultPrice = useMemo(() => {
        return subProducts?.reduce((acc, subProduct) => {
            return acc + subProduct.price * (subProduct.defaultAmount ?? 0);
        }, 0);
    }, [subProducts]);

    const onChangeProperty = useCallback(
        (subProductId, field, value) => {
            const newSubProducts = [...subProducts];
            newSubProducts.find(({id}) => id === subProductId)[field] = value;
            onChange(newSubProducts);
        },
        [subProducts]
    );

    const subProductCategories = useMemo(() => {
        const groupedSubProducts = groupBy(subProducts, "subProductCategory");

        return Object.entries(groupedSubProducts)
            .sort(([category], [otherCategory]) => {
                if (category === "undefined") {
                    return 1;
                }

                if (otherCategory === "undefined") {
                    return -1;
                }

                return category;
            })
            .map(([category, subProducts]) => ({
                category,
                sortedSubProducts: subProducts
            }));
    }, [subProducts]);

    const onResetAllPrice = useCallback(() => {
        const resetedSubProducts = subProducts.map(subProduct => {
            return {
                ...subProduct,
                amount: subProduct.defaultAmount ?? 0
            };
        });
        onChange(resetedSubProducts);
    }, []);

    const categoryToSubProducts = useMemo(() => {
        const filterSubProducts = subProducts.filter(subProduct => subProduct.amount > 0);
        return groupBy(filterSubProducts, subProduct => subProduct.subProductCategory);
    }, [subProducts]);

    return (
        <div className="order-form-modular-products-container">
            <Collapse
                ghost={true}
                defaultActiveKey={["1"]}
                onChange={newKey => {
                    log("Edit package button clicked", {});
                    setIsMenuOpen(newKey.includes("1"));
                }}>
                <Panel
                    key="1"
                    header={
                        <div className="order-form-modular-products-panel">
                            <div className="order-form-modular-products-panel-text">
                                <span>{isMenuOpen ? "סיימתי לערוך את החבילה" : "לעריכת הרכב החבילה"}</span>
                            </div>
                            {
                                <DownOutlined
                                    className={classNames({
                                        "order-form-modular-products-rotate-90-cw": isMenuOpen,
                                        "order-form-modular-products-rotate-90-ccw": !isMenuOpen
                                    })}
                                />
                            }
                        </div>
                    }
                    showArrow={false}>
                    <Collapse
                        className="order-form-modular-products-inner-collapse"
                        expandIcon={({isActive}) => (
                            <DownOutlined
                                className={classNames({
                                    "order-form-modular-products-rotate-90-cw": isActive,
                                    "order-form-modular-products-rotate-90-ccw": !isActive
                                })}
                            />
                        )}>
                        {subProductCategories?.length === 1 ? (
                            <>
                                {
                                    <RenderSubProductsList
                                        subProducts={subProductCategories[0].sortedSubProducts}
                                        showProviderSubProductsImages={showProviderSubProductsImages}
                                        onChangeProperty={onChangeProperty}
                                        adminMode={adminMode}
                                    />
                                }
                            </>
                        ) : (
                            subProductCategories.map(({category, sortedSubProducts}) => (
                                <Panel
                                    key={category}
                                    header={
                                        <div>
                                            <div className="order-form-modular-products-category-title">
                                                {category === "undefined" ? "כללי" : category}
                                            </div>
                                            <div className="order-form-modular-products-category-desc">
                                                {categoryToSubProducts[category]?.map((subProduct, index) => {
                                                    if (subProduct.amount > 0)
                                                        return (
                                                            <>
                                                                <div>
                                                                    <span>{subProduct.amount}</span>
                                                                    <span> </span>
                                                                    <span>{subProduct.name}</span>
                                                                    <span>
                                                                        {categoryToSubProducts[category].length - 1 !==
                                                                        index
                                                                            ? ","
                                                                            : ""}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        );
                                                })}
                                            </div>
                                        </div>
                                    }>
                                    {
                                        <RenderSubProductsList
                                            subProducts={sortedSubProducts}
                                            showProviderSubProductsImages={showProviderSubProductsImages}
                                            onChangeProperty={onChangeProperty}
                                            adminMode={adminMode}
                                        />
                                    }
                                </Panel>
                            ))
                        )}
                    </Collapse>
                </Panel>
            </Collapse>
            <div className="order-form-modular-products-price-container">
                <div>
                    <div className="order-form-modular-products-price-content">
                        <div className="order-form-modular-products-price-text">
                            <span>סיכום מחיר חבילה</span>
                            <span className="order-form-modular-products-price-text-underline">מעודכן</span>
                        </div>

                        <div className="order-form-modular-products-price-text">
                            <span>₪</span>
                            <span>{calculateModularProductPrice?.toLocaleString()}</span>
                        </div>
                    </div>
                </div>
                <div className="order-form-modular-products-original-container">
                    <div className="order-form-modular-products-original-content">
                        <span className="order-form-modular-products-original-text">מחיר חבילה מקורי:</span>
                        <span>{defaultPrice?.toLocaleString()}</span>
                        <span>₪</span>
                    </div>

                    <div
                        className="order-form-modular-products-original-content order-form-modular-products-original-clickable"
                        onClick={onResetAllPrice}>
                        <div className="order-form-modular-products-original-icon">
                            <SyncOutlined />
                        </div>

                        <span className="order-form-modular-products-original-text-underline">החזר לחבילה המקורית</span>
                    </div>
                </div>
            </div>
        </div>
    );
};