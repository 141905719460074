import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Spin} from "antd";
import {ITEMS_PER_CAROUSAL} from "../MarketplaceUtils";
import MarketplaceProductsCarousel from "../MarketplaceProductsCarousel";
import {MarketplaceFilterPanel} from "../MarketplaceFilterPanel";
import {createProductLink} from "../MarketplaceHelper";
import {useRequest} from "../../utils/hooks";
import {isObjectWithEmptyFields} from "../../utils/objectUtils";
import {AppContext} from "../../AppContext";
import {getLogger, writeProductClickedLog} from "../../Logger";
import {MarketplaceSearchResults} from "../MarketplaceSearchResults/MarketplaceSearchResults.jsx";
import {translateFilterOption} from "../../admin/upload/AdminUploadUtils";
import {isAllowedAndExistingCategory} from "../../center/EXCenterHelper";
import "./cross-page.css";
import "../marketplace.css";

const FILTERS_KEY = "CROSS_PAGE_FILTERS";

export const CrossServicePage = ({match, history}) => {
    const [filterValues, setFilterValues] = useState({});
    const [searchSorterType, setSearchSortType] = useState(null);

    const {pageId} = match.params;

    const {services, me} = useContext(AppContext);

    const log = useMemo(() => getLogger({pageId}, "CrossPage"), [pageId]);

    const [crossPageInfo, loadingCrossPageInfo] = useRequest(`/api/site/crossPages/${pageId}`);
    const [subCategories, loadingSubCategories] = useRequest(
        `/api/products/getProductsBySubCategories/?amount=${ITEMS_PER_CAROUSAL}`,
        "post",
        crossPageInfo?.subCategories,
        [crossPageInfo],
        !!crossPageInfo
    );

    useEffect(() => {
        const storedData = JSON.parse(window.localStorage.getItem(FILTERS_KEY));

        if (storedData?.filters && storedData.crossPageId === pageId) {
            setFilterValues(storedData.filters);
            setSearchSortType(storedData.sort);
        }
    }, [pageId]);

    const searchRequestBody = useMemo(
        () => ({
            ...filterValues,
            options: filterValues?.options?.map(translateFilterOption)
        }),
        [filterValues]
    );

    const subCategoriesToSearch = useMemo(
        () =>
            filterValues.subCategory
                ? [subCategories?.find(({subCategory}) => subCategory === filterValues.subCategory).id]
                : subCategories?.map(({id}) => id),
        [filterValues, subCategories]
    );

    const [searchedContent, loadingSearchedContent] = useRequest(
        "/api/products/getSubCategoriesContentByFilters",
        "post",
        {subCategoriesIds: subCategoriesToSearch, ...searchRequestBody},
        [subCategoriesToSearch, searchRequestBody],
        !isObjectWithEmptyFields(filterValues) && subCategories
    );

    const allowedSearchedContent = useMemo(
        () =>
            searchedContent?.filter(({service}) =>
                service.services.some(category =>
                    isAllowedAndExistingCategory(me, category.serviceId ?? category, services)
                )
            ),
        [me, searchedContent]
    );

    const onContentClick = useCallback(
        (content, openInNewTab = false, serviceId = null) => {
            writeProductClickedLog(log, content, serviceId);

            const url = createProductLink(content, {serviceId, me, services});

            if (openInNewTab) {
                window.open(url, "_blank", "noreferrer");
                return;
            }

            const yOffset = window.pageYOffset;
            const dataToStore = {
                crossPageId: pageId,
                yOffset,
                ...(searchSorterType ? {sort: searchSorterType} : {})
            };

            if (!isObjectWithEmptyFields(filterValues)) {
                window.localStorage.setItem(
                    FILTERS_KEY,
                    JSON.stringify({
                        ...dataToStore,
                        filters: filterValues
                    })
                );
            } else {
                window.localStorage.setItem(FILTERS_KEY, JSON.stringify(dataToStore));
            }

            history.push(url);
        },
        [me, log, history, filterValues, searchSorterType, services]
    );

    const getOnAllChoicesClickedFunction = useCallback(
        subCategory => buttonType => {
            log(`All choices ${buttonType} clicked`, {subCategory});
            setFilterValues(prev => ({
                ...prev,
                subCategory
            }));
        },
        [log, setFilterValues]
    );

    useEffect(() => {
        const doesHaveFilterValues = !isObjectWithEmptyFields(filterValues);
        if (
            (doesHaveFilterValues && !loadingSearchedContent && searchedContent?.length) ||
            (!doesHaveFilterValues && subCategories && !loadingSubCategories)
        ) {
            const storedData = JSON.parse(window.localStorage.getItem(FILTERS_KEY));

            if (storedData?.crossPageId === pageId) {
                if (storedData.yOffset) {
                    window.scrollTo(0, storedData.yOffset);
                }

                window.localStorage.removeItem(FILTERS_KEY);
            }
        }
    }, [subCategories, pageId, searchedContent, loadingSearchedContent, loadingSubCategories, filterValues]);

    return (
        <div className="marketplace-category-container cross-page">
            <div className="marketplace-category-filters-container">
                <div className="marketplace-category-title-row">{crossPageInfo?.name}</div>
                {!loadingSubCategories && !loadingCrossPageInfo ? (
                    <MarketplaceFilterPanel
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                        searchSorterType={searchSorterType}
                        setSearchSortType={setSearchSortType}
                        log={log}
                    />
                ) : null}
            </div>
            {!isObjectWithEmptyFields(filterValues) ? (<div></div>
                // <MarketplaceSearchResults
                //     onContentClick={onContentClick}
                //     itemsInRow={3}
                //     onNewTab={content => onContentClick(content, true)}
                //     searchedContent={allowedSearchedContent}
                //     searchText={filterValues?.searchText}
                //     searchSorterType={searchSorterType}
                //     loadingSearchedContent={loadingSearchedContent}
                // />
            ) : loadingSubCategories ? (
                <Spin size="large" />
            ) : (
                <div className="marketplace-main-page-carousels-container marketplace-category-providers">
                    {subCategories?.map(sub =>
                        isAllowedAndExistingCategory(me, sub.serviceId, services) ? (
                            <MarketplaceProductsCarousel
                                key={sub.subCategory}
                                onAllChoicesClicked={getOnAllChoicesClickedFunction(sub.subCategory)}
                                onContentClick={(content, openInNewTab) =>
                                    onContentClick(content, openInNewTab, sub.serviceId)
                                }
                                onNewTab={content => onContentClick(content, true, sub.serviceId)}
                                products={sub.products}
                                title={sub.subCategory}
                            />
                        ) : null
                    )}
                </div>
            )}
        </div>
    );
};
