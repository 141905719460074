import React, {useContext, useState} from "react";
import {Card, message, Modal} from "antd";
import {AppContext} from "../AppContext";
import {CloseIcon} from "../icons";
import {ResetPasswordCardBody} from "../auth/ResetPasswordCard";
import {HttpClient} from "../http/HttpClient";
import {EventBus} from "../bus/EventBus";

export const ResetPasswordModal = ({visible, onClose, language = "english", title = "Change your password"}) => {
    const {me} = useContext(AppContext);
    const [loading, setLoading] = useState(false);

    const onFinish = async values => {
        setLoading(true);
        try {
            const user = await HttpClient.post(
                `/api/users/password/resetByUserId`,
                {
                    ...values,
                    email: me.email
                },
                true
            );
            message.success("Password was changed.", 3);
            onClose();
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {
                    content: {
                        description: [
                            "תקלה בנסיון שינוי סיסמה. יש לנסות להתחבר מחדש עם הסיסמה הישנה ולאחר מכן לנסות לאפס סיסמה שוב. אם לא מצליחים יש לנסות עם הסיסמה החדשה",
                            `Error: ${e?.message}`,
                            "אם אתם נתקלים בעוד בעיות אפשר לפנות אלינו ב: " + process.env.SUPPORT_EMAIL
                        ],
                        hideSteps: true
                    },
                    cta: {
                        hide: true
                    }
                },
                e.message
            );
        }
        setLoading(false);
    };

    return (
        <Modal
            className="wb-modal-radius wb-modal-borderless"
            destroyOnClose={true}
            open={visible}
            onCancel={onClose}
            footer={null}
            title={null}
            closable={true}
            closeIcon={<CloseIcon fill="#767F90" />}>
            <Card
                bordered={true}
                bodyStyle={{borderRadius: "5px"}}
                style={{
                    boxSizing: "border-box",
                    border: "1px solid rgba(131,152,166,0.2)",
                    borderRadius: "5px"
                }}
                cover={
                    <ResetPasswordCardBody
                        language={language}
                        onFinish={vals => onFinish(vals)}
                        isMobileView={false}
                        sendingEmail={loading}
                        title={title}
                        isNeedCurrentPassword={true}
                    />
                }></Card>
        </Modal>
    );
};
