import {isEmpty, minBy, sumBy, uniq} from "lodash";
import {getDefaultPriceTypeArray, getProductMinPrice} from "../MarketplaceUtils";
import {PriceTypes} from "../../admin/upload/PriceSelectorHelper";

export const priceTypeOrder = ["byPerson", "byUnit", "byPackage"];

export const getProductsPriceByMin = (chosenPriceType, product) => {
    switch (chosenPriceType) {
        case "byModular":
            return product.pricingTable.byModular?.minPrice ?? 0;
        case "byVoucher":
            return minBy(product.pricingTable.byVoucher, "redeemableValue")?.redeemableValue;
    }
};

export const getPriceByAmount = (participantsAmount, service) => {
    if (!service.pricingTable) {
        return null;
    }

    let pricingTable;

    if (service.chosenPriceType && priceTypeOrder.includes(service.chosenPriceType)) {
        pricingTable = service.pricingTable[service.chosenPriceType];
    } else {
        const typeIndex = priceTypeOrder.findIndex(
            type => !isEmpty(service.pricingTable[type]) && service.pricingTable[type].some(row => row.price)
        );
        pricingTable = typeIndex >= 0 ? service.pricingTable[priceTypeOrder[typeIndex]] : null;
    }

    if (!pricingTable) {
        return NaN;
    }

    const matchingPriceRange = pricingTable.find(
        ({min, max}) => parseFloat(min) <= participantsAmount && participantsAmount <= parseFloat(max)
    );

    return parseFloat(matchingPriceRange?.price) * participantsAmount;
};

export const calculateProductBundlePrice = productBundleProducts => {
    const productsChosenPrice = uniq(productBundleProducts.map(({chosenPriceType}) => chosenPriceType));

    if (productsChosenPrice?.length === 1) {
        return sumBy(productBundleProducts, product => getProductMinPrice({service: product}));
    }

    return sumBy(productBundleProducts, product => {
        const defaultPriceTypeArray = getDefaultPriceTypeArray(product) || [];
        let minPrice = null;

        defaultPriceTypeArray?.forEach(row => {
            const rowPrice = parseFloat(row.price ?? row.redeemableValue);
            if (!minPrice || minPrice > rowPrice) {
                minPrice = rowPrice;
            }
        });

        if (product.chosenPriceType === PriceTypes.ByPerson) {
            return Number(minPrice.toFixed(0) - 0);
        }

        const maxRecommendedProductParticipants = product?.maxRecomendedProductParticipants || 1;
        const recommendedPrice = minPrice / maxRecommendedProductParticipants;
        return Number(recommendedPrice.toFixed(0) - 0);
    });
};
