import "../admin-products.css";
import React, {useCallback, useContext, useMemo, useState} from "react";
import {Button, ConfigProvider, Dropdown, Input, message, Table} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {columns} from "./columns";
import {useDebounceState, useRequest} from "../../../utils/hooks";
import {AppContext} from "../../../AppContext";
import PageTitleHeader from "../../../components/PageTitle";
import copyToClipboard from "copy-to-clipboard";
import {HttpClient} from "../../../http/HttpClient";
import {AdminEditTagsModal} from "../AdminEditTagsModal";
import {AdminUploadNewProductModal} from "../../upload/AdminUploadNewProductModal";
import classNames from "classnames";
import {isEmpty} from "lodash";
import {ConfirmationRequiredWrapper, SquareButton} from "../../../components";
import ProductsFiltersModal from "../../../marketplace/ProductsFilterModal";
import {MarketplaceFilterSearchOptions} from "../../../marketplace/MarketplaceFilterSearchOptions";
import {NOOP} from "../../../utils/NOOP";
import {uniq} from "lodash";
import {convertFilterValues} from "../../components/SelectProductsModal/utils.jsx";

export const AdminProducts = () => {
    const {me} = useContext(AppContext);
    const [searchInput, setSearchInput] = useDebounceState("", 200);
    const [productsPagination, setProductsPagination] = useState({});
    const [isProductEditModalVisible, setIsProductEditModalVisible] = useState(false);
    const [currentSelectedProduct, setCurrentSelectedProduct] = useState(null);
    const [currentSelectedProvider, setCurrentSelectedProvider] = useState(null);
    const [editProductSearchTags, setEditProductsSearchTags] = useState(null);

    const [productFiltersModalVisible, setProductFiltersModalVisible] = useState(false);

    const [extraFilters, setExtraFilters] = useState({});
    const [searchedTag, setSearchedTag] = useState("");

    const [allSearchTags] = useRequest("/admin/api/searchTags", "GET", null, [], true, null, []);
    const [categories] = useRequest("/api/v2/categories", "POST", {});
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [deliveryProviders] = useRequest("/admin/api/deliveryProviders");

    const allFilterValues = useMemo(
        () => ({
            ...extraFilters,
            searchText: searchInput,
            searchedTag
        }),
        [extraFilters, searchInput, searchedTag]
    );

    const [productsFromServer = [], isLoadingProducts, _isLoadingProductsError, fetchProducts] = useRequest(
        `/api/products/getProducts?onlyTableData=true&page=${productsPagination?.page ?? 1}&pageSize=${
            productsPagination?.itemsInPage ?? 30
        }&filter=${encodeURIComponent(JSON.stringify(convertFilterValues(allFilterValues)))}`,
        "GET",
        null,
        [productsPagination, allFilterValues],
        productsPagination?.page && productsPagination?.itemsPerPage
    );

    const filteredProductSearch = useMemo(() => productsFromServer?.products ?? [], [productsFromServer?.products]);

    const tablePaginationSettings = useMemo(
        () => ({
            position: ["bottomCenter"],
            pageSize: 30,
            total: productsFromServer?.totalProducts,
            showTotal: total => <span>סה״כ {total}</span>,
            onChange: (page, pageSize) => setProductsPagination({page, pageSize})
        }),
        [productsFromServer?.totalProducts]
    );

    const copyProductLinkToClipboard = useCallback((serviceId, providerId, productId) => {
        const link = `${window.location.origin}/dashboard/services/${serviceId}/providersV2/${providerId}/${productId}`;
        copyToClipboard(link);
    }, []);

    const openLinkInNewTab = useCallback((serviceId, providerId, productId) => {
        const link = `${window.location.origin}/dashboard/services/${serviceId}/providersV2/${providerId}/${productId}`;
        window.open(link, "_blank");
    }, []);

    const onUpdateProductStatus = useCallback(async ({providerId, productId, status, updatedAt}) => {
        const res = await HttpClient.safePut(
            `/admin/api/provider/${providerId}/products/${productId}?updatedAt=${updatedAt}`,
            {
                product: {isActive: status, productId}
            }
        );
        if (res?.error) {
            if (res.status === 409) {
                message.error("שים לב יש גרסא יותר חדשה של המוצר, אנא רענן את הדף");
            } else {
                message.error("נכשל בעת עדכון המוצר");
            }
            return;
        }
        await fetchProducts();
        message.success("מוצר עודכן בהצלחה");
    }, [fetchProducts]);

    const openProductSearchTagsModal = useCallback(async product => {
        const productsSearchTags = await HttpClient.post("/admin/api/searchTags/byProducts", [product.productId]);

        setEditProductsSearchTags({
            searchTags: productsSearchTags[product.productId],
            productId: product.productId,
            providerId: product.providerId
        });
    }, []);

    const onDeleteProduct = useCallback(async (providerId, productId) => {
        const {error} = await HttpClient.safeDelete(`/admin/api/provider/${providerId}/products/${productId}`);

        if (error) {
            message.error("אירעה שגיאה במחיקת המוצר");
        } else {
            fetchProducts();
            message.success("המוצר נמחק בהצלחה");
        }
    }, [fetchProducts]);

    const onEditProduct = useCallback(async updatedProduct => {
        const {provider, productId, serviceIds, ...restProduct} = updatedProduct;
        const res = await HttpClient.safePut(
            `/admin/api/provider/${updatedProduct?.providerId}/products/${productId}?updatedAt=${updatedProduct.updatedAt}`,
            {
                product: restProduct
            }
        );

        if (res?.error) {
            if (res.status === 409) {
                message.error("שים לב יש גרסא יותר חדשה של המוצר, אנא רענן את הדף");
                return;
            } else {
                message.error("נכשל בעת עדכון המוצר");
            }
        } else {
            fetchProducts();
            message.success("עודכן בהצלחה");
        }
        setIsProductEditModalVisible(false);
        setCurrentSelectedProduct(null);
    }, [fetchProducts]);

    const openEditProductModal = useCallback(async selectedProduct => {
        const productsSearchTags = await HttpClient.post("/admin/api/searchTags/byProducts", [
            selectedProduct.productId
        ]);
        const {service: product} = await HttpClient.safeGet(`/api/products/byId/${selectedProduct.productId}`);
        const providerDetails = await HttpClient.safeGet(`/admin/api/providers/${selectedProduct.providerId}`);

        const productWithSearchTags = {
            ...selectedProduct,
            ...product,
            searchTags: productsSearchTags[selectedProduct.productId] ?? [],
            serviceIds: selectedProduct.services
        };

        setCurrentSelectedProduct(productWithSearchTags);
        setCurrentSelectedProvider(providerDetails);
        setIsProductEditModalVisible(true);
    }, []);

    const updateBulkProductStatus = useCallback(
        async status => {
            const {error} = await HttpClient.safePut(`/admin/api/products/updateProductStatus`, {
                productIds: selectedProducts,
                status
            });

            if (error) {
                message.error("עידכון מצב המוצרים נכשל");
            } else {
                setSelectedProducts([]);
                fetchProducts();
                message.success("מצב המוצרים עודכן בהצלחה");
            }
        },
        [selectedProducts, fetchProducts]
    );

    const ruleActions = useMemo(() => {
        return {
            items: [
                {
                    key: "הפעלת מוצרים",
                    disabled: selectedProducts.length === 0,
                    label: (
                        <div>
                            <ConfirmationRequiredWrapper
                                onYes={() => updateBulkProductStatus(true)}
                                subTitle="פעולה זאת תגרום לכל המוצרים אשר נבחרו להדלק">
                                <div className="page-layout-admin-top-bar-dropdown-item">הפעלת מוצרים</div>
                            </ConfirmationRequiredWrapper>
                        </div>
                    )
                },
                {
                    key: "כבה מוצרים",
                    disabled: selectedProducts.length === 0,
                    label: (
                        <div>
                            <ConfirmationRequiredWrapper
                                onYes={() => updateBulkProductStatus(false)}
                                subTitle="פעולה זאת תגרום לכל המוצרים אשר נבחרו להכבות">
                                <div className="page-layout-admin-top-bar-dropdown-item">כבה מוצרים</div>
                            </ConfirmationRequiredWrapper>
                        </div>
                    )
                }
            ]
        };
    }, [selectedProducts, updateBulkProductStatus]);

    const onProductSelect = useCallback(
        (record, selected) => {
            if (selected) {
                setSelectedProducts(prevSelectedRowKeys => uniq([...prevSelectedRowKeys, record.productId]));
            } else {
                setSelectedProducts(prevSelectedRowKeys => {
                    const updatedSelectedKeys = prevSelectedRowKeys.filter(productId => productId !== record.productId);
                    return uniq([...updatedSelectedKeys]);
                });
            }
        },
        [setSelectedProducts, setSelectedProducts]
    );

    const onProductSelectAll = useCallback(
        (selected, selectedRows, changeRows) => {
            const changed = changeRows.map(product => product.productId);

            if (selected) {
                setSelectedProducts(newSelectedProductIds => uniq([...newSelectedProductIds, ...changed]));
            } else {
                setSelectedProducts(newSelectedProductIds => newSelectedProductIds.filter(id => !changed.includes(id)));
            }
        },
        [setSelectedProducts, setSelectedProducts]
    );

    const onSearchChange = useCallback(
        searchText => {
            setProductsPagination({page: 1, pageSize: tablePaginationSettings.pageSize});
            setSearchInput(searchText);
        },
        [tablePaginationSettings.pageSize]
    );

    return (
        <div className="admin-product">
            <PageTitleHeader showBack={false}>מוצרים</PageTitleHeader>
            <div className="admin-product-top-bar">
                <div className="admin-product-search-container">
                    <Input
                        className="search"
                        onChange={e => onSearchChange(e.target.value)}
                        placeholder="חפש מוצר"
                        suffix={<SearchOutlined />}
                    />

                    <div id="filters-button">
                        <Button
                            className={classNames("admin-product-search-refresh-button", {
                                isActive: !isEmpty(extraFilters)
                            })}
                            onClick={() => {
                                setProductFiltersModalVisible(true);
                            }}>
                            מסננים
                        </Button>
                    </div>
                </div>

                <div className="admin-product-rules-actions">
                    <div>
                        <ConfigProvider direction="rtl">
                            <Dropdown disabled={selectedProducts.length === 0} menu={ruleActions}>
                                <SquareButton className="primary-color-button" disabled={selectedProducts.length === 0}>
                                    {`הוספת כלל ` + `(${selectedProducts.length})`}
                                </SquareButton>
                            </Dropdown>
                        </ConfigProvider>
                    </div>

                    <div id="refresh-button">
                        <ConfirmationRequiredWrapper
                            onYes={async () => HttpClient.safeGet("/admin/api/search/generateSearchOptions")}
                            title="שימו לב!"
                            subTitle="אין צורך לרענן מוצרים יותר במערכת, השימוש בכפתור תחת אחריותכם בלבד ו/או באישור מתכנת">
                            <Button className="admin-product-search-refresh-button">רענן מוצרים</Button>
                        </ConfirmationRequiredWrapper>
                    </div>
                </div>
            </div>
            <MarketplaceFilterSearchOptions
                filterValues={allFilterValues}
                setFilterValues={setExtraFilters}
                onLockedFiltersChange={NOOP}
                lockedFilters={[]}
                allSearchTags={allSearchTags}
                removeSearchInput={() => {
                    setSearchInput("");
                    setSearchedTag("");
                }}
                removeExtraSearchInput={searchText => {
                    setExtraFilters(currentExtraFilters => {
                        const {extraSearchTexts, ...restExtraFilters} = currentExtraFilters;

                        const newExtraSearchTexts = extraSearchTexts.filter(text => text !== searchText);

                        return {extraSearchTexts: newExtraSearchTexts, ...restExtraFilters};
                    });
                }}
            />
            <ConfigProvider direction="rtl">
                <ProductsFiltersModal
                    filters={null}
                    onClose={() => setProductFiltersModalVisible(false)}
                    onCancel={() => {
                        setExtraFilters({});
                        setProductFiltersModalVisible(false);
                    }}
                    onSave={() => {
                        setProductFiltersModalVisible(false);
                    }}
                    visible={productFiltersModalVisible}
                    onFiltersChange={setExtraFilters}
                    foundProductsCount={productsFromServer?.totalProducts}
                />

                <AdminEditTagsModal
                    visible={!!editProductSearchTags}
                    onClose={() => setEditProductsSearchTags(null)}
                    onCancel={() => setEditProductsSearchTags(null)}
                    productId={editProductSearchTags?.productId}
                    editProductSearchTags={editProductSearchTags?.searchTags}
                    onEditProduct={async searchTags => {
                        const {error} = await HttpClient.put(
                            `/admin/api/provider/${editProductSearchTags.providerId}/updateProductTags/${editProductSearchTags.productId}`,
                            {searchTags}
                        );

                        if (error) {
                            message.error("שמירת תגיות המוצר נכשלה");
                        } else {
                            message.success("תגיות המוצר נשמרו בהצלחה");
                        }
                        setEditProductsSearchTags(null);
                    }}
                    allSearchTags={allSearchTags}
                />

                <AdminUploadNewProductModal
                    visibility={isProductEditModalVisible}
                    onClose={() => setIsProductEditModalVisible(false)}
                    onCancel={() => setIsProductEditModalVisible(false)}
                    editProduct={currentSelectedProduct}
                    providerDetails={currentSelectedProvider}
                    providerSubProducts={currentSelectedProduct?.provider?.subProducts ?? []}
                    services={categories}
                    onEditProduct={onEditProduct}
                    deliveryProviders={deliveryProviders}
                />

                <Table
                    rowSelection={{
                        selectedRowKeys: selectedProducts,
                        onSelect: onProductSelect,
                        onSelectAll: onProductSelectAll
                    }}
                    rowKey="productId"
                    className="admin-product-table-container"
                    scroll={{x: 800}}
                    pagination={tablePaginationSettings}
                    loading={isLoadingProducts}
                    dataSource={filteredProductSearch}
                    columns={columns(
                        me,
                        productsPagination?.page,
                        copyProductLinkToClipboard,
                        openLinkInNewTab,
                        onDeleteProduct,
                        openEditProductModal,
                        openProductSearchTagsModal,
                        onUpdateProductStatus
                    )}
                />
            </ConfigProvider>
        </div>
    );
};
