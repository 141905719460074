import React, {useMemo} from "react";
import moment from "moment";
import {Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import {PaymentType} from "../consts.js";
import {StringBuilder} from "../AppUtils.js";

export const OrderStatus = {
    accepted: "accepted",
    pending: "pending",
    cancelled: "cancelled",
    declined: "declined",
    expired: "expired",
    cancelPending: "cancel-pending",
    billed: "billed",
    paid: "paid",
    pendingDelivery: "pendingDelivery",
    deliveryExpired: "deliveryExpired",
    delivered: "delivered",
    deliveryError: "deliveryError",
    creditCardError: "creditCardError",
    creditCardNotPaid: "creditCardNotPaid",
    firstOrderForUser: "firstOrderForUser",
    notFirstOrderForUser: "notFirstOrderForUser",
    pendingManagerConfirm: "PendingManagerConfirm",
    canceledByManager: "CanceledByManager",
    PickedUpByDeliveryProvider: "PickedUpByDeliveryProvider"
};

export const OrderStatusPriority = {
    [OrderStatus.pending]: 0,
    [OrderStatus.accepted]: 1
};

export const PaymentStatuses = [
    OrderStatus.billed,
    OrderStatus.paid,
    OrderStatus.creditCardError,
    OrderStatus.creditCardNotPaid
];

export const statusToTextAndColor = {
    [OrderStatus.accepted]: ["הזמנה אושרה", "var(--standard-primary-color)"],
    [OrderStatus.pending]: ["ממתין לאישור", ""],
    [OrderStatus.pendingDelivery]: ["מועד האספקה חלף, ממתין לחיווי", ""],
    [OrderStatus.deliveryExpired]: ["מועד האספקה חלף, ממתין לחיווי", "var(--error-color)"],
    [OrderStatus.cancelled]: ["הזמנה בוטלה", "var(--standard-text-color)"],
    [OrderStatus.declined]: ["הזמנה סורבה", "var(--error-color)"],
    [OrderStatus.expired]: ["פג תוקף", "#FFFFFF", "var(--error-color)"],
    [OrderStatus.deliveryError]: ["תקלה בשילוח", "var(--error-color)"],
    [OrderStatus.cancelPending]: ["ממתין לסירוב", "var(--error-color)"],
    [OrderStatus.paid]: ["שולם", "var(--accept-color)"],
    [OrderStatus.delivered]: ["הזמנה סופקה", "var(--accept-color)"],
    [OrderStatus.creditCardError]: ["שגיאה בסליקה", "var(--error-color)"],
    [OrderStatus.creditCardNotPaid]: ["אשראי לא נסלק", "#FFFFFF", "var(--error-color)"],
    [OrderStatus.firstOrderForUser]: ["הזמנה ראשונה למשתמש", ""],
    [OrderStatus.notFirstOrderForUser]: ["לא הזמנה ראשונה למשתמש", ""],
    [OrderStatus.pendingManagerConfirm]: ["ממתין לאישור מנהל", ""],
    [OrderStatus.canceledByManager]: ["סורב על ידי מנהל", "var(--error-color)"],
    [OrderStatus.PickedUpByDeliveryProvider]: ["בשילוח ליעד", "var(--accept-color)"]
};

export const getStatus = order => {
    const statuses = [];

    if (
        order.secondaryStatus &&
        !(
            order.secondaryStatus === OrderStatus.creditCardError &&
            order.eventStatusMap?.some(({id}) => id === OrderStatus.paid)
        )
    ) {
        statuses.push({id: order.secondaryStatus});
    }

    if (
        order.status === OrderStatus.accepted &&
        order.paymentType === PaymentType.Card &&
        order.secondaryStatus !== OrderStatus.paid &&
        !order.eventStatusMap?.find(({id}) => id === OrderStatus.paid) &&
        moment(order.dtstart).isBefore(moment(), "day")
    ) {
        statuses.push({id: OrderStatus.creditCardNotPaid});
    }

    if (order.status === OrderStatus.pending && moment().diff(moment(order.createdAt), "hours") > 24) {
        statuses.push({id: OrderStatus.expired});
    }

    if (
        (order.status === OrderStatus.declined || order.status === OrderStatus.cancelled) &&
        order.eventStatusMap?.some(({id}) => id === OrderStatus.delivered)
    ) {
        statuses.push({id: order.status});
    }

    if (order.eventStatusMap?.length) {
        statuses.push(...order.eventStatusMap);
    }

    if (order.status === OrderStatus.accepted && !order.eventStatusMap?.some(({id}) => id === OrderStatus.delivered)) {
        const timePastAfterDelivery = moment().diff(
            moment(order.dtstart).add(order.deliveryArrivingTimeRange, "hours"),
            "hours"
        );
        if (timePastAfterDelivery > 0) {
            if (timePastAfterDelivery > 24) {
                statuses.push({id: OrderStatus.deliveryExpired});
            } else {
                statuses.push({id: OrderStatus.pendingDelivery});
            }
        }
    }

    if (!statuses.find(({id}) => id === order.status)) {
        statuses.push({id: order.status});
    }
    return statuses;
};

export const OrderStatusLabel = ({
    order,
    className,
    statusesToShow = null,
    hidePaymentStatuses = false,
    hideEditedBy = false
}) => {
    const {id: status, ...restOfInfo} = useMemo(() => {
        const statusArray = getStatus(order);

        const filteredStatues = statusArray.filter(
            ({id: status}) => !hidePaymentStatuses || !PaymentStatuses.includes(status)
        );

        const firstRelevantStatus = filteredStatues.find(({id: status}) => {
            const shouldShowStatus = statusesToShow?.length ? statusesToShow.includes(status) : true;
            return shouldShowStatus && !!statusToTextAndColor?.[status]?.[0];
        });

        return firstRelevantStatus ?? {};
    }, [order]);

    const editedByText = useMemo(() => {
        if (order?.lastEditedBy && !hideEditedBy) {
            return (
                <Tooltip
                    title={new StringBuilder("נערך על ידי")
                        .append(order.lastEditedBy?.isAdmin ? order.lastEditedBy?.firstName : "הלקוח")
                        .toString()}>
                    ✏️
                </Tooltip>
            );
        }
        return null;
    }, [order?.lastEditedBy, hideEditedBy]);

    if (status === OrderStatus.pending) {
        return (
            <span>
                {"ממתין לאישור | "}
                <span style={{color: "var(--error-color)"}}>
                    {24 - moment().diff(moment(order.createdAt), "hours")} ש׳
                </span>
                <span>{editedByText}</span>
            </span>
        );
    } else if (status === OrderStatus.pendingDelivery) {
        return (
            <span>
                {"מועד האספקה חלף, ממתין לחיווי | "}
                <span style={{color: "var(--error-color)"}}>
                    {24 - moment().diff(moment(order.dtstart).add(order.deliveryArrivingTimeRange, "hours"), "hours")}{" "}
                    ש׳
                </span>
            </span>
        );
    } else if (status === OrderStatus.deliveryError) {
        const [text, color] = statusToTextAndColor[status];
        return (
            <div className={className} style={{color}}>
                {text}
                <Tooltip title={restOfInfo.errorText}>
                    <InfoCircleOutlined />
                </Tooltip>
            </div>
        );
    } else if (statusToTextAndColor[status]) {
        const [text, color, backgroundColor] = statusToTextAndColor[status];

        return (
            <div className={className} style={{color, backgroundColor}}>
                {text}
                <span>{editedByText}</span>
            </div>
        );
    }

    return null;
};
