import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import moment from "moment";
import classNames from "classnames";
import {debounce, set, sumBy} from "lodash";
import {Collapse, message, Typography} from "antd";
import {BOOKING_ERROR_TO_PANEL, BOOKING_TO_PANEL_COLORS, FIRST_ORDER, MIN_USER_FIELD_TRIGGER} from "../consts";
import {BookingCompleteModal} from "../BookingCompleteModal";
import {MarketplaceOrderPreview} from "../MarketplaceOrderPreview";
import {OrderForm} from "../../OrderForm";
import {BookingHeader} from "../BookingHeader";
import {MarketplaceBookingContext} from "../MarketplaceBookingContext";
import {
    getDeliveryArea,
    getDeliveryPriceForBooking,
    getMergedDeliveryOptions,
    getUserChangedFormValues,
    removeFloorText,
    validateBooking,
    validateVoucherBooking
} from "../utils";
import {PaymentPanel} from "../Payment/PaymentPanel";
import {
    DEFAULT_DELIVERY_ARRIVING_TIME_RANGE,
    DEFAULT_DELIVERY_PRICE,
    ERROR_LEVEL,
    getDefaultTypeToShow,
    PLACE_ERROR,
    sendAlertToCsmWithEmail,
    typeToPluralHebrewName,
    WARN_LEVEL
} from "../../MarketplaceUtils";
import {
    createBookingEvent,
    createBookingEventValues,
    createVoucherBookingEvent,
    expandIconController,
    getOrderTip,
    getPriceByAmount,
    MarketplaceSeparatorLine
} from "../../MarketplaceHelper";
import {OrderFormPanelKeys} from "../../consts";
import {ActivityLocationTypes, AllowedSystemIdPaymentType, PaymentType} from "../../../consts.js";
import {GoogleAnalytics} from "../../../GoogleAnalytics";
import {AppContext} from "../../../AppContext";
import {EventBus} from "../../../bus/EventBus";
import {HttpClient} from "../../../http/HttpClient";
import {PageLoader} from "../../../components";
import {getLogger} from "../../../Logger";
import {useQuery, useRequest} from "../../../utils/hooks";
import {isCompanySubAdmin} from "../../../center/EXCenterHelper";
import {MarketplaceContext} from "../../marketplaceContext";
import {OrderVoucherForm} from "../../OrderVoucherForm";
import {AsiaJerusalemTZ} from "../../../event/DaysMapper";
import "../marketplace-booking.css";
import {CouponDiscountTypes} from "../../../admin/AdminCoupons/consts.js";
import {isProductHasShippingProvider} from "../../../admin/upload/utils.js";
import {CartModularProductContext} from "../../PricingCalculator/CartModularProductContext.jsx";
import {HomeDeliveryUploadMethod} from "../../OrderLocationInput/consts.jsx";
import {getValuesForCreateEvent} from "./utils.js";
import {PriceTypes} from "../../../admin/upload/PriceSelectorHelper.jsx";
import {getThemeByOrigin} from "../../../utils.js";
import {useSocket} from "../../../components/socket/useSockets.jsx";
import {useListenToSocket} from "../../../components/socket/useListenToSocket.jsx";
import {TickerNotification} from "../../../ordersCalendar/OrderTicker/index.jsx";
import {NOOP} from "../../../utils/NOOP.jsx";

export const ProductBooking = ({history}) => {
    const {me, company, planBudgetOrder, setPlanBudgetOrder} = useContext(AppContext);
    const {
        product,
        providerDetails,
        serviceId,
        providerId,
        productId,
        userFormValues,
        participants,
        highlightOrderPanel,
        setHighlightOrderPanel
    } = useContext(MarketplaceContext);

    const [isSocketError, setIsSocketError] = useState(null);

    const onReceivedBookingOfferSocketMessage = useCallback(message => {
        if (message.status === "success") {
            setBookingCompleteModalVisibility(true);
            setBookingLoading(false);
        } else if (message.status === "error") {
            EventBus.triggerError("server-error", {
                content: {
                    description: [
                        `Unfortunately we didn't manage to send your offer :(`,
                        `Error: ${message.errorMessage}`
                    ]
                }
            });

            setBookingLoading(false);
        }
    }, []);

    useListenToSocket({eventName: "BOOKING_OFFER", onUpdate: onReceivedBookingOfferSocketMessage});
    useListenToSocket({eventName: "connect_error", onUpdate: () => setIsSocketError(true)});
    useListenToSocket({eventName: "connect", onUpdate: () => setIsSocketError(false)});

    const {selectedSubProducts, setSubProducts} = useContext(CartModularProductContext);
    const [formValues, setFormValues] = useState({});
    const [loadingDeliveryPrice, setLoadingDeliveryPrice] = useState(false);
    const [updateDetailsModals, setUpdateDetailsModals] = useState(null);
    const [bookingCompleteModalVisibility, setBookingCompleteModalVisibility] = useState(false);
    const [creditCard, setCreditCard] = useState(false);
    const [bookingLoading, setBookingLoading] = useState(false);
    const [createdEvent, setCreatedEvent] = useState();
    const [paymentType, setPaymentType] = useState(null);
    const [couponCode, setCouponCode] = useState(null);
    const [coupon, setCoupon] = useState(null);
    const [deliveryPrice, setDeliveryPrice] = useState(DEFAULT_DELIVERY_PRICE);
    const [activeKey, setActiveKey] = useState(null);
    const [currentDeliveryArea, setCurrentDeliveryArea] = useState(null);

    const productRef = useRef(product);

    let query = useQuery();

    const savedOrderURI = useMemo(() => {
        if (!query) return null;

        const fromSavedOrder = query.get("fromSavedOrder");
        const fromEventId = query.get("fromEventId");

        if (!fromSavedOrder && !fromEventId) {
            return null;
        }

        return fromEventId
            ? `/api/savedOrder/byEventId/${encodeURIComponent(fromEventId)}`
            : `/api/savedOrder/${encodeURIComponent(fromSavedOrder)}`;
    }, [query]);

    const [savedOrder] = useRequest(savedOrderURI, "get", null, [query], !!savedOrderURI);

    const calculateModularProductPrice = useMemo(() => {
        return formValues?.modularProducts?.reduce((acc, formValue) => {
            return acc + formValue?.price * formValue?.amount;
        }, 0);
    }, [formValues.modularProducts]);

    useEffect(() => {
        if (product?.chosenPriceType === PriceTypes.ByModular) {
            const isSavedOrderHasSubProducts = savedOrder?.modularProducts?.length;
            const modularProducts = product?.pricingTable?.byModular?.subProducts?.reduce((acc, subProduct) => {
                const foundProviderSubProduct = providerDetails.subProducts?.find(({id}) => id === subProduct?.id);

                if (foundProviderSubProduct?.isActive && subProduct.isActive) {
                    const foundSavedOrderSubProduct = savedOrder?.modularProducts.find(({id}) => id === subProduct.id);

                    if (foundSavedOrderSubProduct || isSavedOrderHasSubProducts) {
                        return [
                            ...acc,
                            {
                                ...subProduct,
                                ...foundProviderSubProduct,
                                amount: foundSavedOrderSubProduct ? foundSavedOrderSubProduct.amount ?? 0 : 0
                            }
                        ];
                    }

                    if (selectedSubProducts?.length) {
                        const foundSelectedProduct = selectedSubProducts?.find(({id}) => id === subProduct?.id);
                        return [
                            ...acc,
                            {
                                ...subProduct,
                                ...foundProviderSubProduct,
                                amount: foundSelectedProduct ? foundSelectedProduct.amount ?? 0 : 0
                            }
                        ];
                    }

                    return [...acc, {...subProduct, ...foundProviderSubProduct, amount: subProduct.defaultAmount ?? 0}];
                }

                return acc;
            }, []);

            setFormValues(current => ({...current, modularProducts}));
        }
    }, [product, providerDetails, selectedSubProducts]);

    const log = useMemo(
        () =>
            getLogger(
                {
                    serviceId,
                    providerId,
                    productId,
                    providerName: providerDetails?.businessName,
                    productName: product?.productName
                },
                "ProductDetails"
            ),
        [serviceId, providerId, productId, product, providerDetails]
    );

    useEffect(() => {
        if (product?.chosenPriceType) {
            setFormValues(current => ({...current, priceType: typeToPluralHebrewName(product?.chosenPriceType)}));
        }
    }, [product?.chosenPriceType]);

    useEffect(() => {
        if (product) {
            productRef.current = product;
        }
    }, [product]);

    const onExitWindow = useCallback(async e => {
        if (getUserChangedFormValues(userFormValues.current).length >= MIN_USER_FIELD_TRIGGER) {
            await sendAlertToCMSs(
                me?.companyId,
                null,
                "המשתמש מילא פרטים ונטש את עמוד ההזמנה",
                productRef.current?.productName,
                null
            );
            userFormValues.current = null;

            const confirmationMessage = "האם אתה בטוח שברצונך לצאת מהעמוד?";
            (e || window.event).returnValue = confirmationMessage;
            return confirmationMessage;
        }
    }, []);

    useEffect(() => {
        window.addEventListener("beforeunload", onExitWindow);

        return () => {
            window.removeEventListener("beforeunload", onExitWindow);
        };
    }, [onExitWindow]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const isUserCompanySubAdmin = useMemo(() => isCompanySubAdmin(me), [me]);

    useEffect(() => {
        async function calculateDeliveryPrice() {
            setLoadingDeliveryPrice(true);

            const isValidShippingProvidersFields = !!(isProductHasShippingProvider(product) && product?.productId);
            const isExcelHomeDelivery =
                formValues.chosenActivityLocation === ActivityLocationTypes.homeDelivery &&
                formValues.homeDeliveryUploadMethod === HomeDeliveryUploadMethod.excel;

            const isSingleHomeDelivery =
                formValues.chosenActivityLocation === ActivityLocationTypes.homeDelivery &&
                formValues.homeDeliveryUploadMethod === HomeDeliveryUploadMethod.singleEmployee;

            const isSingleOrManuelLocationDelivery =
                formValues.chosenActivityLocation === ActivityLocationTypes.manualLocation || isSingleHomeDelivery;

            if (isValidShippingProvidersFields && isExcelHomeDelivery && formValues.employeesExcel?.rowCount) {
                const deliveryProviderRes = await HttpClient.safeGet(
                    `/api/deliveryProvider/byId/${product.shippingProviders?.[0]}`
                );

                if (deliveryProviderRes?.homeDeliveryPrice) {
                    setDeliveryPrice(deliveryProviderRes?.homeDeliveryPrice * formValues.employeesExcel?.rowCount);
                }
                setFormValues(formValues => ({
                    ...formValues,
                    sourcePlaceId: product.address?.[0]?.placeId,
                    sourceAddressName: product.address?.[0]?.name
                }));
                setLoadingDeliveryPrice(false);
            } else if (isValidShippingProvidersFields && isSingleOrManuelLocationDelivery) {
                const destinationPlaceId = isSingleHomeDelivery
                    ? formValues.singleEmployeeDetails.placeId
                    : formValues.addressId;

                const deliveryPriceRes = await HttpClient.safePost("/api/products/shippingPrice", [
                    {
                        productId: product.productId,
                        destinationPlaceId
                    }
                ]);

                if (!deliveryPriceRes.error) {
                    const [firstDeliveryPriceRes] = deliveryPriceRes;
                    setDeliveryPrice(firstDeliveryPriceRes.price);

                    setFormValues(formValues => ({
                        ...formValues,
                        sourcePlaceId: firstDeliveryPriceRes.sourcePlaceId,
                        sourceAddressName: firstDeliveryPriceRes.sourceAddressName
                    }));
                }

                setLoadingDeliveryPrice(false);
            } else {
                const officeAddress = {
                    address: isUserCompanySubAdmin ? me?.address : company?.address,
                    addressId: isUserCompanySubAdmin ? me?.addressId : company?.addressId
                };

                const searchedAddress = formValues?.singleEmployeeDetails?.address ?? formValues?.chosenAddress;
                const searchedAddressId = formValues?.singleEmployeeDetails?.addressId ?? formValues?.addressId;

                const address = {
                    address: searchedAddress,
                    addressId:
                        officeAddress?.address?.trim() === removeFloorText(searchedAddress?.trim())
                            ? searchedAddressId
                            : searchedAddressId
                };

                const deliveryArea = await getDeliveryArea(address, product, providerDetails, log);

                if (product) {
                    product.deliveryPrice = product.deliveryPrice ?? providerDetails.deliveryPrice;
                }

                const deliveryPrice = getDeliveryPriceForBooking(
                    formValues?.chosenAddress,
                    providerDetails,
                    product,
                    formValues,
                    deliveryArea,
                    log
                );

                setDeliveryPrice(deliveryPrice?.price);
                setLoadingDeliveryPrice(false);
                setCurrentDeliveryArea(deliveryArea);

                setFormValues(prev => ({
                    ...prev,
                    errors: {
                        ...(prev.errors ?? {}),
                        [PLACE_ERROR]: deliveryPrice?.message
                            ? {
                                  level: deliveryPrice.price ? WARN_LEVEL : ERROR_LEVEL,
                                  message: deliveryPrice.message
                              }
                            : null
                    }
                }));
            }
        }

        calculateDeliveryPrice();
    }, [
        formValues.employeesExcel,
        formValues.chosenAddress,
        formValues.chosenActivityLocation,
        company?.address,
        company?.addressId,
        formValues.singleEmployeeDetails,
        product?.deliveryPrice,
        providerDetails?.deliveryOptions,
        log,
        me?.address,
        me?.addressId,
        isUserCompanySubAdmin
    ]);

    useEffect(() => {
        if (participants) {
            setFormValues(currentFormValues => set(currentFormValues, "amount", participants));
        }
    }, [participants]);

    const upgradeOptionsPrice = useMemo(
        () =>
            sumBy(formValues.upgradeOptions?.options, option =>
                option.type === "byUnit" ? option.price * (option.amount ?? 0) : option.price
            ),
        [formValues.upgradeOptions?.options, formValues.amount]
    );

    const offeredPrice = useMemo(() => {
        let total;

        switch (product?.chosenPriceType) {
            case "byModular":
                total = (calculateModularProductPrice ?? 0) + upgradeOptionsPrice;
                break;
            case "byVoucher":
                const voucherReceiversAmount = formValues.singleEmployeeDetails
                    ? 1
                    : formValues.employeesExcel?.rowCount;
                total = formValues.voucherOption?.redeemableValue * voucherReceiversAmount;
                break;
            default:
                const currentPrice = getPriceByAmount(formValues.amount, product);
                total = currentPrice
                    ? (
                          parseFloat(currentPrice.toString().replace(",", "")) *
                              (getDefaultTypeToShow(product) === "byGroup" ? 1 : parseFloat(formValues?.amount)) +
                          upgradeOptionsPrice
                      ).toFixed(0) - 0
                    : null;
        }
        return total;
    }, [
        product,
        formValues?.amount,
        upgradeOptionsPrice,
        formValues?.modularProducts,
        formValues?.voucherOption,
        formValues?.singleEmployeeDetails,
        formValues.employeesExcel
    ]);

    const onFormValueChange = useCallback(
        (field, val) => {
            setFormValues(prevValues => set({...prevValues}, field, val));
        },
        [setFormValues]
    );

    const onInvoiceCommentChange = useCallback(
        val => {
            setFormValues(prevValues => ({...prevValues, invoiceComment: val}));
        },
        [setFormValues]
    );

    const onInvoicePONumberChange = useCallback(
        val => {
            setFormValues(prevValues => ({...prevValues, invoicePONumber: val}));
        },
        [setFormValues]
    );

    const onSeparateInvoiceChange = useCallback(val => {
        setFormValues(prevValues => ({...prevValues, separateInvoice: val}));
    }, []);

    const onBudgetCategoriesPriorityChange = useCallback(val => {
        setFormValues(prevValues => ({...prevValues, budgetCategoriesPriority: val}));
    }, []);

    const couponDiscountAmount = useMemo(() => {
        if (coupon?.discountType === CouponDiscountTypes.ByPercentage) {
            return (offeredPrice * coupon?.discount) / 100;
        }

        return coupon?.discount;
    }, [coupon, offeredPrice]);

    const bookingError = useMemo(
        () =>
            providerDetails?.isVoucherProvider
                ? validateVoucherBooking(
                      formValues,
                      company,
                      paymentType,
                      product,
                      creditCard,
                      me,
                      offeredPrice,
                      deliveryPrice,
                      couponDiscountAmount
                  )
                : validateBooking(
                      me,
                      paymentType,
                      offeredPrice,
                      product,
                      formValues,
                      creditCard,
                      company,
                      deliveryPrice,
                      couponDiscountAmount,
                      formValues.companyDiscount
                  ),
        [
            me,
            paymentType,
            offeredPrice,
            product,
            formValues,
            creditCard,
            company,
            deliveryPrice,
            providerDetails,
            coupon
        ]
    );

    const deliveryOptions = useMemo(() => {
        return getMergedDeliveryOptions(providerDetails, product);
    }, [providerDetails, product]);

    const valuesForCreateEvent = useMemo(() => {
        return getValuesForCreateEvent(
            formValues,
            deliveryOptions,
            serviceId,
            paymentType,
            offeredPrice,
            company,
            deliveryPrice,
            coupon,
            couponDiscountAmount,
            creditCard,
            upgradeOptionsPrice,
            planBudgetOrder,
            providerDetails
        );
    }, [
        providerDetails,
        product?.durationMinutes,
        providerDetails,
        serviceId,
        paymentType,
        offeredPrice,
        company?.address,
        me.companyId,
        formValues,
        coupon,
        deliveryPrice,
        creditCard,
        upgradeOptionsPrice,
        company?.branches,
        deliveryOptions?.arrivingTimeRange,
        planBudgetOrder,
        company?.ordersDiscount,
        company?.phone,
        couponDiscountAmount
    ]);

    const createEvent = useCallback(
        debounce(async () => {
            try {
                const {eventId} = providerDetails?.isVoucherProvider
                    ? await createVoucherBookingEvent(valuesForCreateEvent, product, providerDetails)
                    : await createBookingEvent(
                          valuesForCreateEvent,
                          product,
                          providerDetails,
                          me?.features?.booking_offer_sockets
                      );
                setSubProducts([]);
                GoogleAnalytics.event("Professional Offer", "Proposal", me.companyId);

                GoogleAnalytics.ecommerceEvent(
                    "purchase",
                    product,
                    serviceId,
                    {
                        transaction_id: eventId,
                        shipping: deliveryPrice,
                        value: offeredPrice,
                        checkout_option: paymentType,
                        coupon: coupon?.valid ? coupon.code : null
                    },
                    {
                        quantity: formValues.amount
                    }
                );

                log("Order Booked", {...valuesForCreateEvent});
                setCreatedEvent(eventId);
                setPlanBudgetOrder(null);

                if (!me?.features?.booking_offer_sockets) {
                    setBookingCompleteModalVisibility(true);
                }

                if (valuesForCreateEvent.paymentType === PaymentType.Budget) {
                    EventBus.trigger("me:updateBudget");
                }
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {
                        content: {
                            description: [
                                `Unfortunately we didn't manage to send your offer :(`,
                                `Error: ${e.message}`,
                                e.extraInfo
                            ]
                        }
                    },
                    e.message
                );
            }

            if (!me?.features?.booking_offer_sockets) {
                setBookingLoading(false);
            }
        }, 50),
        [
            providerDetails,
            product?.durationMinutes,
            providerDetails,
            serviceId,
            paymentType,
            offeredPrice,
            company?.address,
            me.companyId,
            me?.features?.booking_offer_sockets,
            formValues,
            coupon,
            deliveryPrice,
            creditCard,
            upgradeOptionsPrice,
            log,
            valuesForCreateEvent
        ]
    );

    const officeAddress = useMemo(() => {
        if (isCompanySubAdmin(me) && me?.address) {
            return `${me?.address}${me?.floor ? ` קומה ${me?.floor}` : ""}`;
        } else if (me.isCompanyMaster && company?.address) {
            return `${company?.address}${company?.floor ? ` קומה ${company?.floor}` : ""}`;
        } else {
            return null;
        }
    }, [me, company]);

    const onBookingFinish = useCallback(async () => {
        const isMasterWithoutFinanceContact = me.isCompanyMaster && !company?.financeContact;
        const isSubAdminWithoutFinanceContact =
            me.isCompanySubAdmin && company?.separateSubAdminInvoices && !me.financeContact;

        if (isMasterWithoutFinanceContact || isSubAdminWithoutFinanceContact) {
            setUpdateDetailsModals(FIRST_ORDER);
        } else {
            await createEvent();
        }
    }, [
        createEvent,
        me.isCompanyMaster,
        me.isCompanySubAdmin,
        me.financeContact,
        company?.financeContact,
        company?.separateSubAdminInvoices
    ]);

    const sendAlertToCMSs = useCallback(
        debounce(
            async (companyId, price, status, productName, productId, filledValues) =>
                sendAlertToCsmWithEmail(companyId, price, status, productName, productId, filledValues),
            1000
        ),
        []
    );

    const onFinishClick = useCallback(async () => {
        log("Execute Order Button Clicked");
        setBookingLoading(true);

        if (!bookingError) {
            await onBookingFinish();
            userFormValues.current = null;
        } else {
            if (formValues?.errors) {
                for (const errorKey in formValues.errors) {
                    if (formValues.errors[errorKey]?.level === "error") {
                        await sendAlertToCMSs(
                            me.companyId,
                            offeredPrice,
                            formValues.errors[errorKey].message,
                            product.productName,
                            productId,
                            formValues
                        );
                        userFormValues.current = null;
                        break;
                    }
                }
            }

            message.error(bookingError ?? "יש למלא את כל שדות החובה לפני שליחת הזמנה", 3);

            if (BOOKING_ERROR_TO_PANEL[bookingError]) {
                setHighlightOrderPanel({
                    id: BOOKING_ERROR_TO_PANEL[bookingError],
                    color: BOOKING_TO_PANEL_COLORS.ERROR
                });
            }

            setBookingLoading(false);
        }
    }, [formValues?.error, onBookingFinish, bookingError, providerDetails, log]);

    const saveProductAsDraft = useCallback(async () => {
        const values = createBookingEventValues(valuesForCreateEvent, product, providerDetails);

        const draft = {
            ...formValues,
            ...values,
            productUrl: history.location.pathname,
            couponCode,
            upgradeOptions: {
                options: formValues?.upgradeOptions?.options || [],
                files: formValues?.upgradeOptions?.files || []
            }
        };

        try {
            const hide = message.loading("שומרים את ההזמנה...", 0);
            const res = await HttpClient.safePost("/api/savedOrder", draft);
            hide();
            message.success(
                <>
                    <>הזמנתך נשמרה בהצלחה</>
                    <Typography.Link
                        onClick={e => {
                            e.preventDefault();
                            history.push(`/company/profile/${company?.companyId}`);
                        }}>
                        לניהול הטיוטות שלי
                    </Typography.Link>
                </>,
                4
            );
            return res.savedOrederId;
        } catch (e) {
            hide(false);
            message.error("לא הצלחנו לשמור את ההזמנה", 3);
        }
    }, [formValues, couponCode, product, paymentType, providerId]);

    useEffect(() => {
        const validateCoupon = async code => {
            if (!company?.companyId) {
                return;
            }

            const order = {offeredPrice, companyId: company.companyId};
            const {
                error: requestError,
                discount,
                valid,
                errorMessage,
                discountType
            } = await HttpClient.safePost("/api/coupons/validate", {code, order});

            if (requestError) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we didn't manage to validate your coupon :("}},
                    requestError.message
                );
                return;
            }

            log("Coupon Validated", {code, discount, valid, errorMessage, discountType});
            setCoupon({valid, code, discount, errorMessage, discountType});
        };

        if (couponCode) {
            validateCoupon(couponCode);
        }
    }, [offeredPrice, couponCode, company?.companyId]);

    useEffect(() => {
        if (highlightOrderPanel?.id) {
            setActiveKey(highlightOrderPanel?.id);
        }
    }, [highlightOrderPanel]);

    const tip = useMemo(
        () => getOrderTip(offeredPrice, formValues.tipPercentage),
        [offeredPrice, formValues.tipPercentage]
    );

    useEffect(() => {
        if (company?.ordersDiscount) {
            const totalOrderPrice = offeredPrice + (deliveryPrice || 0) - (coupon?.discount || 0) + tip;
            const companyDiscount = Math.floor(totalOrderPrice * (company.ordersDiscount / 100));

            setFormValues(prevValues => ({...prevValues, companyDiscount}));
        }
    }, [company?.ordersDiscount, offeredPrice, deliveryPrice, coupon?.discount]);

    const onCancelCoupon = useCallback(() => {
        setCouponCode(null);
        setCoupon(null);
    }, []);

    useEffect(() => {
        if (!savedOrder) return;
        if (savedOrder?.productId !== productId) return;
        const {
            productName,
            providerId,
            productId: productIdFromSavedOrder,
            couponCode: couponCodeFromSavedOrder,
            paymentType: paymentTypeFromSavedOrder,
            date = null,
            time = null,
            address,
            companyRequests: requests,
            ...formValuesFromSavedOrder
        } = savedOrder;

        setFormValues({
            ...formValuesFromSavedOrder,
            ...(formValuesFromSavedOrder?.employeesDeliveryDetail?.length
                ? {singleEmployeeDetails: formValuesFromSavedOrder.employeesDeliveryDetail?.[0]}
                : {}),
            ...(date ? {date: moment(date)} : {}),
            ...(time ? {time: moment(time)} : {}),
            ...(requests ? {requests} : {})
        });
        if (couponCodeFromSavedOrder) {
            setCouponCode(couponCodeFromSavedOrder);
        }
        if (paymentTypeFromSavedOrder) {
            setPaymentType(paymentTypeFromSavedOrder);
        }
        setFormValues(formValues => ({
            ...formValues,
            upgradeOptions: {...(formValuesFromSavedOrder?.upgradeOptions || {})},
            chosenAddress: address || formValues.chosenAddress || null
        }));
    }, [savedOrder, productId]);

    const removeQueryParams = (searchQuery, keys) => {
        const searchParams = new URLSearchParams(searchQuery);
        for (const key of keys) {
            if (searchParams.has(key)) {
                searchParams.delete(key);
            }
        }
        return searchParams.toString();
    };

    const sendOrderAsQuote = useCallback(
        async receiverEmail => {
            const dateFormatted = moment(formValues.date).format("DD/MM/YYYY");
            const timeFormatted = moment(formValues.time).format("HH:mm");
            const dtstart = moment(`${dateFormatted} ${timeFormatted}`, "DD/MM/YYYY HH:mm")
                .tz(AsiaJerusalemTZ)
                .valueOf();
            const deliveryArrivingTimeRange =
                deliveryOptions?.arrivingTimeRange ?? DEFAULT_DELIVERY_ARRIVING_TIME_RANGE;
            const until = moment(dtstart).add(deliveryArrivingTimeRange, "hours").valueOf();
            const modularProducts = formValues.modularProducts?.filter(product => product?.amount > 0);

            const savedOrder = {
                ...formValues,
                serviceId,
                dtstart,
                until,
                paymentType,
                offeredPrice,
                address: formValues.chosenAddress || company.address,
                branches: company.branches?.[0] || null,
                companyPhone: company.phone,
                deliveryPrice,
                coupon: coupon?.valid ? coupon : null,
                creditCard,
                modularProducts,
                upgradeOptionsPrice,
                deliveryArrivingTimeRange,
                companyDiscountPercentage: company.ordersDiscount
            };

            const bookingEventValues = createBookingEventValues(savedOrder, product, providerDetails);
            const hide = message.loading("שולח...", 0);

            const savedOrderId = await saveProductAsDraft();
            const {error} = await HttpClient.safePost("/api/sendRawSavedOrderToEmail", {
                savedOrder: {...bookingEventValues, chosenAddress: formValues.chosenAddress},
                receiverEmail,
                savedOrderId
            });
            hide();

            if (error) {
                message.error("אירעה שגיאה בעת שליחת המייל", 4);
                return;
            }

            await message.success("המייל נשלח בהצלחה", 4);
        },
        [formValues, couponCode, product, paymentType, providerId, saveProductAsDraft, deliveryOptions]
    );

    useEffect(() => {
        const clearSavedOrder = (newLocation, routerChangeType) => {
            if (location.pathname !== newLocation.pathname) {
                if (newLocation.pathname !== location.pathname || newSearch !== location.search) {
                    if (newLocation.search.length > 0) {
                        const newSearch = removeQueryParams(location.search, ["fromSavedOrder", "fromEventId"]);
                        history.replace({
                            pathname: newLocation.pathname,
                            search: newSearch
                        });
                    }
                }
            }
        };

        return history.listen(clearSavedOrder);
    }, [history, location]);

    return (
        <MarketplaceBookingContext.Provider
            value={{
                deliveryAreaProperties: currentDeliveryArea,
                offeredPrice,
                highlightOrderPanel
            }}>
            <>
                {product ? (
                    <>
                        <BookingCompleteModal
                            visible={bookingCompleteModalVisibility}
                            eventId={createdEvent}
                            onSave={link => {
                                setBookingCompleteModalVisibility(false);
                                history.push(link);
                            }}
                            onClose={() => setBookingCompleteModalVisibility(false)}
                            company={company}
                            me={me}
                        />

                        {!!(isSocketError && bookingLoading && me?.features?.booking_offer_sockets) && (
                            <TickerNotification
                                contentString="קיבלנו את בקשתך, וההזמנה שלך בדרך להיכנס למערכת! אנחנו כאן, ונעדכן אותך במייל ובסמס ברגע שזה יקרה"
                                onClose={NOOP}
                            />
                        )}

                        <div className="booking-container">
                            <div className="booking-container-width">
                                <BookingHeader
                                    productName={product?.productName}
                                    isVoucherProvider={providerDetails?.isVoucherProvider}
                                    log={log}
                                    setBookingLoading={setBookingLoading}
                                    saveProductAsDraft={saveProductAsDraft}
                                    updateDetailsModals={updateDetailsModals}
                                    setUpdateDetailsModals={setUpdateDetailsModals}
                                    createEvent={createEvent}
                                    sendOrderAsQuote={sendOrderAsQuote}
                                />

                                <div className="booking-container-wrapper">
                                    <div className="booking-container-content">
                                        <MarketplaceOrderPreview
                                            onFinishClick={onFinishClick}
                                            bookingLoading={bookingLoading}
                                            canFinish={!bookingError}
                                            content={product}
                                            formValues={formValues}
                                            offeredPrice={offeredPrice}
                                            coupon={coupon}
                                            couponDiscountAmount={couponDiscountAmount}
                                            onCouponAdded={setCouponCode}
                                            onCancelCoupon={onCancelCoupon}
                                            deliveryPrice={deliveryPrice}
                                            loadingDeliveryPrice={loadingDeliveryPrice}
                                            upgradeOptionsPrice={upgradeOptionsPrice}
                                            companyDiscount={formValues.companyDiscount}
                                            tip={tip}
                                            bookingError={bookingError}
                                        />
                                        <div className="booking-form-content">
                                            {providerDetails?.isVoucherProvider ? (
                                                <OrderVoucherForm
                                                    formValues={formValues}
                                                    setFormValues={setFormValues}
                                                    content={product}
                                                    bookingError={bookingError}
                                                    defaultActiveKey={activeKey}
                                                    onDefaultActiveKeyChange={setActiveKey}
                                                    mainContact={
                                                        isCompanySubAdmin(me) ? me?.firstName : company?.contactName
                                                    }
                                                    extraFields={
                                                        <div>
                                                            <Collapse
                                                                ghost={true}
                                                                accordion={true}
                                                                className={classNames("marketplace-booking-collapse", {
                                                                    panelError:
                                                                        BOOKING_ERROR_TO_PANEL[bookingError] ===
                                                                            OrderFormPanelKeys.Payment &&
                                                                        highlightOrderPanel?.id ===
                                                                            OrderFormPanelKeys.Payment
                                                                })}
                                                                expandIconPosition="left"
                                                                activeKey={activeKey}
                                                                onChange={setActiveKey}
                                                                expandIcon={({isActive}) =>
                                                                    expandIconController(isActive)
                                                                }>
                                                                <PaymentPanel
                                                                    updateCreditCard={setCreditCard}
                                                                    onPaymentTypeChange={type => setPaymentType(type)}
                                                                    onInvoiceCommentChange={onInvoiceCommentChange}
                                                                    invoiceComment={formValues?.invoiceComment}
                                                                    onInvoicePONumberChange={onInvoicePONumberChange}
                                                                    invoicePONumber={formValues?.invoicePONumber}
                                                                    onSeparateInvoiceChange={onSeparateInvoiceChange}
                                                                    budgetCategoriesPriority={
                                                                        formValues?.budgetCategoriesPriority
                                                                    }
                                                                    onBudgetCategoriesPriorityChange={
                                                                        onBudgetCategoriesPriorityChange
                                                                    }
                                                                    separateInvoice={formValues?.separateInvoice}
                                                                    tenBisCreditCard={formValues?.tenBisCreditCard}
                                                                    allowedPaymentMethods={
                                                                        AllowedSystemIdPaymentType[
                                                                            getThemeByOrigin(window.location.origin)
                                                                        ]
                                                                    }
                                                                />
                                                            </Collapse>
                                                            <MarketplaceSeparatorLine />
                                                        </div>
                                                    }
                                                />
                                            ) : (
                                                <OrderForm
                                                    calculateModularProductPrice={calculateModularProductPrice}
                                                    officeAddress={officeAddress}
                                                    mainContact={
                                                        isCompanySubAdmin(me) ? me?.firstName : company?.contactName
                                                    }
                                                    content={product}
                                                    formValues={formValues}
                                                    setFormValues={setFormValues}
                                                    updateFormValues={(field, val) => {
                                                        userFormValues.current = set(
                                                            {...userFormValues?.current},
                                                            field,
                                                            val
                                                        );
                                                        setFormValues(prevValues => set({...prevValues}, field, val));
                                                    }}
                                                    bookingError={bookingError}
                                                    providerDetails={providerDetails}
                                                    defaultActiveKey={activeKey}
                                                    onDefaultActiveKeyChange={setActiveKey}
                                                    log={log}
                                                    extraFields={
                                                        <div>
                                                            <Collapse
                                                                ghost={true}
                                                                accordion={true}
                                                                className={classNames("marketplace-booking-collapse", {
                                                                    panelError:
                                                                        BOOKING_ERROR_TO_PANEL[bookingError] ===
                                                                            OrderFormPanelKeys.Payment &&
                                                                        highlightOrderPanel?.id ===
                                                                            OrderFormPanelKeys.Payment
                                                                })}
                                                                expandIconPosition="left"
                                                                activeKey={activeKey}
                                                                onChange={setActiveKey}
                                                                expandIcon={({isActive}) =>
                                                                    expandIconController(isActive)
                                                                }>
                                                                <PaymentPanel
                                                                    panelKey={OrderFormPanelKeys.Payment}
                                                                    updateCreditCard={setCreditCard}
                                                                    onPaymentTypeChange={type => setPaymentType(type)}
                                                                    onInvoiceCommentChange={onInvoiceCommentChange}
                                                                    invoiceComment={formValues?.invoiceComment}
                                                                    onInvoicePONumberChange={onInvoicePONumberChange}
                                                                    invoicePONumber={formValues?.invoicePONumber}
                                                                    onSeparateInvoiceChange={onSeparateInvoiceChange}
                                                                    budgetCategoriesPriority={
                                                                        formValues?.budgetCategoriesPriority
                                                                    }
                                                                    onBudgetCategoriesPriorityChange={
                                                                        onBudgetCategoriesPriorityChange
                                                                    }
                                                                    separateInvoice={formValues?.separateInvoice}
                                                                    updateFormValues={(field, value) =>
                                                                        onFormValueChange(field, value)
                                                                    }
                                                                    tenBisCreditCard={formValues?.tenBisCreditCard}
                                                                    allowedPaymentMethods={
                                                                        AllowedSystemIdPaymentType[
                                                                            getThemeByOrigin(window.location.origin)
                                                                        ]
                                                                    }
                                                                />
                                                            </Collapse>
                                                            <MarketplaceSeparatorLine />
                                                        </div>
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <PageLoader style={{top: "80px"}} />
                )}
            </>
        </MarketplaceBookingContext.Provider>
    );
};

function updateAddressIdFunction(user, addressId) {
    if (isCompanySubAdmin(user)) {
        return HttpClient.post(`/api/companies/me/employees/${user.userId}`, {
            userId: user.userId,
            addressId
        });
    } else {
        return HttpClient.post("/api/companies/updateCompanyAddressId", {addressId});
    }
}
