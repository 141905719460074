export const FoodRequestsIds = {
    veganFriendly: "veganFriendly",
    vegetarianFriendly: "vegetarianFriendly",
    glutenFree: "glutenFree",
    lactoseFree: "lactoseFree",
    furOption: "furOption"
};

export const FoodRequestsIdToName = {
    [FoodRequestsIds.veganFriendly]: "אפשרויות לטבעונים",
    [FoodRequestsIds.vegetarianFriendly]: "אפשרויות לצמחונים",
    [FoodRequestsIds.glutenFree]: "אפשרויות ללא גלוטן",
    [FoodRequestsIds.lactoseFree]: "אפשרויות ללא לקטוז",
    [FoodRequestsIds.furOption]: "אפשרויות פרווה"
};

export const FoodRequests = [
    {
        id: FoodRequestsIds.veganFriendly,
        title: "אפשרויות לטבעונים"
    },
    {
        id: FoodRequestsIds.vegetarianFriendly,
        title: "אפשרויות לצמחונים"
    },
    {
        id: FoodRequestsIds.glutenFree,
        title: "אפשרויות ללא גלוטן"
    },
    {
        id: FoodRequestsIds.lactoseFree,
        title: "אפשרויות ללא לקטוז"
    },
    {
        id: FoodRequestsIds.furOption,
        title: "אפשרויות פרווה"
    }
];

export const ACTIVITIES_IDS = {
    accesibility: "accesibility",
    payedParking: "payedParking",
    freeParking: "freeParking",
    privateRoom: "privateRoom",
    projector: "projector",
    tv: "tv",
    wifi: "wifi"
};

export const ACTIVITIES_IDS_TO_NAMES = {
    [ACTIVITIES_IDS.projector]: "מקרן",
    [ACTIVITIES_IDS.accesibility]: "נגישות",
    [ACTIVITIES_IDS.tv]: "טלוויזיה",
    [ACTIVITIES_IDS.wifi]: "Wifi",
    [ACTIVITIES_IDS.payedParking]: "חניה בתשלום",
    [ACTIVITIES_IDS.freeParking]: "חניה בחינם",
    [ACTIVITIES_IDS.privateRoom]: "חדר פרטי"
};

export const ACTIVITY_REQUESTS = [
    {
        id: ACTIVITIES_IDS.projector,
        title: "מקרן"
    },
    {
        id: ACTIVITIES_IDS.accesibility,
        title: "נגישות"
    },
    {
        id: ACTIVITIES_IDS.tv,
        title: "טלוויזיה"
    },
    {
        id: ACTIVITIES_IDS.wifi,
        title: "Wifi"
    },
    {
        id: ACTIVITIES_IDS.payedParking,
        title: "חניה בתשלום"
    },
    {
        id: ACTIVITIES_IDS.freeParking,
        title: "חניה בחינם"
    },
    {
        id: ACTIVITIES_IDS.privateRoom,
        title: "חדר פרטי"
    }
];

export const kosherTypes = {
    REGULAR: "regular",
    MEHADRIN: "mehadrin",
    BEDATZ: "bedatz",
    NOT_NEEDED: "notneeded",
    TZOHAR: "tzohar"
};

export const kosherTypesToHebrew = {
    [kosherTypes.REGULAR]: "כשרות רגילה",
    [kosherTypes.TZOHAR]: "כשרות צוהר",
    [kosherTypes.MEHADRIN]: "כשרות מהדרין",
    [kosherTypes.BEDATZ]: "כשרות בד״צ",
    [kosherTypes.NOT_NEEDED]: "אין צורך בכשרות לפעילות זו"
};

export const ProductKosherTypesPriority = {
    [kosherTypes.MEHADRIN]: 2,
    [kosherTypes.BEDATZ]: 1,
    [kosherTypes.REGULAR]: 0
};

export const FilterOptions = {
    Kosher: "kosher",
    // ShortNotice: "shortNotice",
    HomeDelivery: "homeDelivery",
    ...FoodRequests.reduce(
        (acc, request) => ({
            ...acc,
            [request.id]: request.id
        }),
        {}
    )
};

export const FilterOptionIdToName = {
    [FilterOptions.Kosher]: "בית עסק כשר",
    // [FilterOptions.ShortNotice]: "בהתראה קצרה - מהיום למחר",
    [FilterOptions.HomeDelivery]: "הפצה לבתי העובדים",
    ...FoodRequests.reduce(
        (acc, request) => ({
            ...acc,
            [request.id]: request.title
        }),
        {}
    )
};

export const FilterErrorTypes = {
    MISSING_PARTICIPANTS_AMOUNT: "חסר מספר משתתפים"
};

export const FoodRequestsShortIdToName = {
    [FoodRequestsIds.veganFriendly]: "טבעוני",
    [FoodRequestsIds.vegetarianFriendly]: "צמחוני",
    [FoodRequestsIds.glutenFree]: "ללא גלוטן",
    [FoodRequestsIds.lactoseFree]: "ללא לקטוז",
    [FoodRequestsIds.furOption]: "פרווה"
};
