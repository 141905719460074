import React, {useContext, useState} from "react";
import {Form, Modal, Select, Switch} from "antd";
import {CloseIcon, ColorPickerIcon} from "../../icons";
import {FormInput, FormTextArea} from "../../components/form";
// import isURL from "validator/lib/isURL";
import {UploadableCoverPhoto} from "../../components/UploadableCoverPhoto";
import {AppContext} from "../../AppContext";
import {WBCheckbox} from "../../components/WBCheckbox";
import {StyledButton} from "../../components/StyledButton";
import {parse as qsParse} from "query-string";
import {EXCenterColorPickerModal} from "./EXCenterColorPickerModal";
import cover_placeholder from "../../images/placeholder-image.jpg";
import {isEqual} from "lodash";
import {findBranchById} from "../../company/CompanyBranches";
import {CoverSizeTag} from "../../components/CoverSizeTag";
import {EXCenterContext} from "../EXCenterContext";

const {Option} = Select;

export const DesignDisplayItem = ({value, onChange}) => (
    <div style={{display: "flex", alignItems: "center"}}>
        <Switch
            checked={value}
            onChange={onChange}
            style={{backgroundColor: value === true ? "#13C296" : "#767F90", marginRight: 12}}
        />{" "}
        Displayed
    </div>
);

const DesignInputItem = ({label, value, onChange, textArea = false, autoSize}) => {
    return (
        <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
            <div style={{display: "flex", justifyContent: "space-between", marginBottom: 15}}>
                <span
                    style={{
                        fontSize: 16,
                        color: "var(--secondary-color)"
                    }}>
                    {label}
                </span>
            </div>
            {textArea ? (
                <FormTextArea
                    className="wb-center-textarea-title-input"
                    autoSize={autoSize || {maxRows: 4, minRows: 4}}
                    value={value}
                    onChange={onChange}
                    placeholder={label}
                />
            ) : (
                <FormInput value={value} onChange={onChange} placeholder={label} />
            )}
        </div>
    );
};

const ControlledDesignInputItem = ({label, value, onChange, textArea = false}) => {
    const {enabled, text} = value || {
        enabled: false
    };

    const doChange = (en, txt) => {
        onChange({enabled: en, text: txt});
    };

    return (
        <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
            <div style={{display: "flex", justifyContent: "space-between", marginBottom: 15}}>
                <span
                    style={{
                        fontSize: 16,
                        color: "var(--secondary-color)"
                    }}>
                    {label}
                </span>
                <WBCheckbox
                    onChange={({target}) => doChange(target.checked, text)}
                    checked={enabled}
                    style={{marginRight: 10}}
                />
            </div>
            <div style={{display: "flex"}} onClick={() => doChange(true, text)}>
                {textArea ? (
                    <FormTextArea
                        style={{pointerEvents: enabled ? "auto" : "none"}}
                        disabled={!enabled}
                        autoSize={{maxRows: 4, minRows: 4}}
                        value={text}
                        onChange={({target}) => doChange(enabled, target.value)}
                        placeholder={label}
                    />
                ) : (
                    <FormInput
                        style={{pointerEvents: enabled ? "auto" : "none"}}
                        disabled={!enabled}
                        value={text}
                        onChange={({target}) => doChange(enabled, target.value)}
                        placeholder={label}
                    />
                )}
            </div>
        </div>
    );
};

const DesignBranchesSelect = ({value, onChange}) => {
    const {company} = useContext(AppContext);

    const {branchId} = qsParse(location.search);

    if (!value && branchId) {
        onChange([findBranchById(company, branchId)]);
    }

    const doChange = branchIds => {
        onChange(
            branchIds.map(branchId => {
                return findBranchById(company, branchId);
            })
        );
    };

    return (
        <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
            <div style={{display: "flex", justifyContent: "space-between", marginBottom: 15}}>
                <span
                    style={{
                        fontSize: 16,
                        color: "var(--secondary-color)"
                    }}>
                    Apply changes to these branches
                </span>
            </div>
            <Select mode="multiple" defaultValue={branchId || void 0} value={value} onSelect={doChange}>
                {company.branches.map(({branchId, name}) => (
                    <Option key={branchId} value={branchId}>
                        {name}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

const ControlledDesignLinkButtonInput = ({value, onChange}) => {
    const {enabled, text, link, color} = value || {
        enabled: false,
        color: "#595AD4"
    };

    const doChange = (en, txt, lnk, clr) => {
        onChange({enabled: en, text: txt, link: lnk, color: clr});
    };

    return (
        <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
            <div style={{display: "flex", justifyContent: "space-between", marginBottom: 15}}>
                <span
                    style={{
                        fontSize: 16,
                        color: "var(--secondary-color)"
                    }}>
                    Button
                </span>
                <WBCheckbox
                    onChange={({target}) => doChange(target.checked, text, link, color)}
                    checked={enabled}
                    style={{marginRight: 10}}
                />
            </div>
            <div style={{display: "flex", alignItems: "center"}} onClick={() => doChange(true, text, link, color)}>
                <FormInput
                    disabled={!enabled}
                    placeholder="Read more"
                    value={text}
                    onChange={({target}) => doChange(enabled, target.value, link, color)}
                    style={{marginRight: 10, maxWidth: 150, pointerEvents: enabled ? "auto" : "none"}}
                />
                <FormInput
                    disabled={!enabled}
                    placeholder="Link"
                    value={link}
                    onChange={({target}) => doChange(enabled, text, target.value, color)}
                    style={{marginRight: 10, pointerEvents: enabled ? "auto" : "none"}}
                />
                <div
                    onClick={() => doChange(true, text, link, color)}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        height: 50,
                        width: 50,
                        borderRadius: 25,
                        boxShadow: "0 1px 4px 0 rgba(0,0,0,0.1)",
                        padding: 10
                    }}>
                    <StyledButton
                        disabled={!enabled}
                        style={{
                            backgroundColor: color,
                            height: 40,
                            width: 40,
                            borderRadius: 20,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "none",
                            boxShadow: "none",
                            pointerEvents: enabled ? "auto" : "none"
                        }}>
                        <ColorPickerIcon style={{width: 16, height: 16}} />
                    </StyledButton>
                </div>
            </div>
        </div>
    );
};

const ControlledDesignNoLinkButtonInput = ({label, placeholder, withColor = true, value, onChange}) => {
    const {theme} = useContext(EXCenterContext);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const {enabled, text, color} = value || {
        enabled: false,
        color: theme.textColor
    };

    const doChange = (en, txt, clr) => {
        onChange({enabled: en, text: txt, color: clr});
    };

    return (
        <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
            {withColor ? (
                <EXCenterColorPickerModal
                    label="Choose color"
                    color={color}
                    visible={showColorPicker}
                    onClose={() => setShowColorPicker(false)}
                    onColorPick={({hex}) => {
                        doChange(enabled, text, hex);
                        setShowColorPicker(false);
                    }}
                />
            ) : null}
            <div style={{display: "flex", justifyContent: "space-between", marginBottom: 15}}>
                <span
                    style={{
                        fontSize: 16,
                        color: "var(--secondary-color)"
                    }}>
                    {label}
                </span>
                <WBCheckbox
                    onChange={({target}) => doChange(target.checked, text, color)}
                    checked={enabled}
                    style={{marginRight: 10}}
                />
            </div>
            <div style={{display: "flex", alignItems: "center"}} onClick={() => doChange(true, text, color)}>
                <FormInput
                    disabled={!enabled}
                    placeholder={placeholder}
                    value={text}
                    onChange={({target}) => doChange(enabled, target.value, color)}
                    style={{marginRight: 10, pointerEvents: enabled ? "auto" : "none"}}
                />
                {withColor ? (
                    <div
                        onClick={() => doChange(true, text, color)}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "white",
                            height: 50,
                            width: 50,
                            borderRadius: 25,
                            boxShadow: "0 1px 4px 0 rgba(0,0,0,0.1)",
                            padding: 10,
                            pointerEvents: enabled ? "auto" : "none"
                        }}>
                        <StyledButton
                            onClick={() => setShowColorPicker(true)}
                            style={{
                                backgroundColor: color,
                                height: 40,
                                width: 40,
                                borderRadius: 20,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "none",
                                boxShadow: "none"
                            }}>
                            <ColorPickerIcon style={{width: 16, height: 16}} />
                        </StyledButton>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

const DesignEditForm = ({
    width,
    onSave,
    designInfo,
    onDesignInfoChange,
    coverWidth,
    coverHeight,
    displayName,
    onClose,
    loading,
    items = {
        coverPhoto: true,
        title: true,
        branches: true,
        description: true,
        search: true,
        buttonLink: false,
        buttonNoLink: true
    }
}) => {
    const [title, setTitle] = useState(designInfo.title);

    return (
        <Form
            style={{
                width: "100%",
                backgroundColor: "#F6F8FA"
            }}
            onFinish={onSave}
            initialValues={designInfo}
            onValuesChange={(_, allValues) => {
                onDesignInfoChange && onDesignInfoChange(allValues);
                setTitle(allValues.title);
            }}>
            {items.coverPhoto ? (
                <Form.Item
                    name="coverPhoto"
                    valuePropName="imageUrl"
                    className="wb-error-padding"
                    style={{marginBottom: isEqual(items, {coverPhoto: true}) ? 0 : 24}}>
                    <UploadableCoverPhoto
                        width={coverWidth}
                        height={coverHeight}
                        quality={100}
                        original={true}
                        query={title ? title : encodeURIComponent(displayName)}
                        showPlaceholder={true}
                        showReload={true}
                        coverStyle={{
                            borderRadius: "none",
                            width,
                            paddingBottom: (coverHeight / coverWidth) * 100 + "%"
                        }}>
                        <CoverSizeTag width={coverWidth} height={coverHeight} />
                    </UploadableCoverPhoto>
                </Form.Item>
            ) : null}
            <div
                style={{
                    display: isEqual(items, {coverPhoto: true}) ? "none" : "flex",
                    flexDirection: "column",
                    width: "100%",
                    backgroundColor: "#F6F8FA",
                    padding: 20,
                    paddingBottom: 0
                }}>
                {items.title ? (
                    <Form.Item
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: "Please enter title."
                            }
                        ]}>
                        <DesignInputItem label="Title" textArea={true} autoSize={{minRows: 1, maxRows: 1}} />
                    </Form.Item>
                ) : null}
                {items.branches ? (
                    <Form.Item
                        name="branches"
                        rules={[
                            {
                                type: "array",
                                required: true,
                                message: "Please select at least one branch."
                            }
                        ]}>
                        <DesignBranchesSelect />
                    </Form.Item>
                ) : null}
                {items.description ? (
                    <Form.Item
                        name="description"
                        rules={[
                            {
                                validator: (_, value) => {
                                    const {enabled, text} = value || {enabled: false};
                                    if (enabled && !text) {
                                        return Promise.reject("Please enter description.");
                                    }

                                    return Promise.resolve();
                                }
                            }
                        ]}>
                        <ControlledDesignInputItem textArea={true} label="Description" />
                    </Form.Item>
                ) : null}
                {items.buttonLink ? (
                    <Form.Item
                        name="action"
                        rules={[
                            {
                                validator: (_, value) => {
                                    const {enabled, text, link, color} = value || {enabled: false};
                                    if (enabled && (!text || !link || !color)) {
                                        return Promise.reject("Please fill all details.");
                                    } else if (
                                        enabled &&
                                        !isURL(link, {require_protocol: false, allow_underscores: true})
                                    ) {
                                        return Promise.reject("Invalid URL.");
                                    }

                                    return Promise.resolve();
                                }
                            }
                        ]}>
                        <ControlledDesignLinkButtonInput />
                    </Form.Item>
                ) : null}
                {items.buttonNoLink ? (
                    <Form.Item
                        name="action"
                        rules={[
                            {
                                validator: (_, value) => {
                                    const {enabled, text, color} = value || {enabled: false};
                                    if (enabled && (!text || !color)) {
                                        return Promise.reject("Please fill all details.");
                                    }

                                    return Promise.resolve();
                                }
                            }
                        ]}>
                        <ControlledDesignNoLinkButtonInput label="Button" placeholder="Read more" />
                    </Form.Item>
                ) : null}
                {items.search ? (
                    <Form.Item
                        name="search"
                        rules={[
                            {
                                validator: (_, value) => {
                                    const {enabled, text} = value || {enabled: false};
                                    if (enabled && !text) {
                                        return Promise.reject("Please fill all details.");
                                    }

                                    return Promise.resolve();
                                }
                            }
                        ]}>
                        <ControlledDesignNoLinkButtonInput
                            label="Search box"
                            withColor={false}
                            placeholder="Search for anything"
                        />
                    </Form.Item>
                ) : null}
            </div>
            <div
                style={{
                    height: 90,
                    backgroundColor: "#F6F8FA",
                    borderTop: "1px solid #DADEE4",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: 20,
                    paddingRight: 20
                }}>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
                    <Form.Item name="display" style={{marginBottom: 0}}>
                        <DesignDisplayItem />
                    </Form.Item>
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Form.Item noStyle>
                        <StyledButton
                            htmlType="submit"
                            loading={loading}
                            style={{backgroundColor: "#00CF82", color: "white", border: "none", width: 100}}>
                            Save
                        </StyledButton>
                    </Form.Item>
                </div>
            </div>
        </Form>
    );
};

export const EXCenterSectionDesignEditModal = ({
    width = 520,
    style,
    coverWidth,
    coverHeight,
    onClose,
    visible,
    onSave,
    loading,
    title = "Edit component",
    sectionId,
    displayName,
    designInfo,
    onDesignInfoChange,
    items
}) => {
    return (
        <Modal
            style={style}
            width={width}
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            bodyStyle={{padding: 0}}
            footer={null}
            title={null}
            closable={true}
            closeIcon={<CloseIcon fill="white" style={{marginTop: 20}} />}>
            <div style={{display: "flex", width: "100%", flexDirection: "column", alignItems: "center"}}>
                <div
                    style={{
                        height: 56,
                        width: "100%",
                        backgroundColor: "#595AD4",
                        fontSize: 20,
                        color: "white",
                        fontWeight: 700,
                        paddingLeft: 20,
                        display: "flex",
                        alignItems: "center"
                    }}>
                    {title}
                </div>
                {visible ? (
                    <DesignEditForm
                        width={width}
                        onSave={onSave}
                        designInfo={designInfo}
                        coverWidth={coverWidth}
                        coverHeight={coverHeight}
                        displayName={displayName}
                        onClose={onClose}
                        onDesignInfoChange={onDesignInfoChange}
                        loading={loading}
                        items={items}
                    />
                ) : null}
            </div>
        </Modal>
    );
};
