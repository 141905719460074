import React, {useContext, useEffect, useState} from "react";
import {isFinite} from "lodash";
import {Form} from "antd";
import classNames from "classnames";
import {TipsOptions} from "./consts";
import {MarketplaceBookingContext} from "./MarketplaceBooking/MarketplaceBookingContext";
import {getOrderTip} from "./MarketplaceHelper";
import {FormInputV2} from "../components/form";

const OrderFormTippingPanel = ({formValues, onFormValueChange}) => {
    const [customTip, setCustomTip] = useState(null);
    const {offeredPrice} = useContext(MarketplaceBookingContext);

    useEffect(() => {
        if (isFinite(customTip?.percentage)) {
            onFormValueChange("tipPercentage", customTip.percentage);
        }
    }, [customTip, offeredPrice]);

    return (
        <Form.Item>
            <div className="marketplace-booking-tipping-options">
                {TipsOptions.map(tipAmount => (
                    <div
                        onClick={() => onFormValueChange("tipPercentage", tipAmount)}
                        className={classNames("marketplace-booking-tip-option", {
                            isActive: tipAmount === formValues.tipPercentage
                        })}
                        key={tipAmount}>
                        {tipAmount}%
                    </div>
                ))}
                <div className="marketplace-booking-tipping-else">
                    <FormInputV2
                        onChange={e => {
                            if (isNaN(Number(e.target.value))) {
                                return;
                            }

                            setCustomTip({
                                percentage: Number(e.target.value),
                                amount: offeredPrice ? getOrderTip(offeredPrice, e.target.value) : null
                            });
                        }}
                        value={customTip?.percentage}
                        placeholder="אחוז"
                        prefix="%"
                    />
                    <FormInputV2
                        onChange={e =>
                            setCustomTip({
                                amount: e.target.value,
                                percentage: offeredPrice
                                    ? Math.round((e.target.value / offeredPrice) * 1000) / 10
                                    : null
                            })
                        }
                        value={customTip?.amount}
                        placeholder="סכום"
                        suffix="₪"
                    />
                </div>
            </div>
        </Form.Item>
    );
};

export default OrderFormTippingPanel;
