import OrdersCalendar from "./OrdersCalendar";
import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import {OrdersCalendarContext} from "./OrdersCalendarContext";
import {CalendarDateFormat} from "./consts";
import {useRequest} from "../utils/hooks";
import {AppContext} from "../AppContext";

const OrdersCalendarEventsInjector = ({history, dateCellClicked}) => {
    const {me} = useContext(AppContext);
    const {setMonth, setOrders} = useContext(OrdersCalendarContext);

    const [calendarStartEndDates, setCalendarStartEndDates] = useState([]);

    const [holidays] = useRequest(
        `/api/consumptionOccasions/betweenDates?startDate=${calendarStartEndDates[0]}&endDate=${calendarStartEndDates[1]}`,
        "GET",
        null,
        [calendarStartEndDates[0], calendarStartEndDates[1]],
        calendarStartEndDates?.length === 2
    );

    const [orders] = useRequest(
        `/api/companies/orders/${me?.companyId}/?userId=${me?.userId ?? ""}&byCreatedAt=false`,
        "POST",
        {
            startTime: moment(calendarStartEndDates[0], CalendarDateFormat).valueOf(),
            endTime: moment(calendarStartEndDates[1], CalendarDateFormat).valueOf()
        },
        [me, calendarStartEndDates[0], calendarStartEndDates[1]],
        calendarStartEndDates?.length === 2
    );

    useEffect(() => {
        setOrders(orders);
    }, [orders, setOrders]);

    return (
        <OrdersCalendar
            history={history}
            holidays={holidays}
            dateCellClicked={dateCellClicked}
            onMonthChanged={(newMonth, newCalendarRange) => {
                setMonth(newMonth);
                setCalendarStartEndDates(newCalendarRange.map(date => date?.format(CalendarDateFormat)));
            }}
        />
    );
};

export default OrdersCalendarEventsInjector;
