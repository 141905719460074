import React, {useContext, useEffect, useState} from "react";
import PageTitleHeader, {PageSubTitleLabel} from "../components/PageTitle";
import {HttpClient} from "../http/HttpClient";
import {Collapse, Modal, Spin} from "antd";
import {EventBus} from "../bus/EventBus";
import {RightOutlined} from "@ant-design/icons";
import {CollapseContent, CollapseHeader} from "../components/CustomCollapse";
import {AppContext} from "../AppContext";
import moment from "moment-timezone";
import {INVALID_EMAIL_MARK} from "../data/defaults";
import {PageLoader} from "../components/PageLoader";
import {CloseIcon} from "../icons";

const {Panel} = Collapse;

export const EventBookings = ({eventId, date, visible, onClose}) => {
    const {theme} = useContext(AppContext);
    const [bookings, setBookings] = useState(null);
    const [event, setEvent] = useState(null);

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const {bookings, event} = await HttpClient.get(`/api/events/${eventId}/bookings/${date}`);
                setBookings(bookings);
                setEvent(event);
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we couldn't list this event's bookings :("}},
                    e.message
                );
            }
        });
    }, [visible]);

    const hasBookings = () => {
        return Array.isArray(bookings) && bookings.length > 0;
    };

    return (
        <Modal
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={onClose}
            footer={null}
            title={null}
            closable={true}
            closeIcon={<CloseIcon fill="#767F90" />}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                    marginBottom: 100
                }}>
                {!bookings || !event ? (
                    <PageLoader align="flex-start" top={100} />
                ) : (
                    <>
                        <PageTitleHeader
                            showBack={false}
                            style={{marginBottom: 0}}
                            subTitleStyle={{marginBottom: 0, maxHeight: "fit-content", maxWidth: 800}}>
                            {event.productName || event.sessionName || event.serviceName}
                        </PageTitleHeader>
                        <PageSubTitleLabel style={{marginBottom: 0, marginTop: 10}}>
                            {moment(date, "YYYY-MM-DD").format("MMMM Do, YYYY")}
                        </PageSubTitleLabel>
                        {hasBookings() ? (
                            <PageSubTitleLabel style={{marginBottom: 30, marginTop: 5, fontSize: 15}}>
                                {bookings.length} {bookings.length === 1 ? "employee" : "employees"}
                            </PageSubTitleLabel>
                        ) : null}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                maxWidth: 600,
                                alignItems: "center"
                            }}>
                            {hasBookings() ? (
                                <Collapse
                                    className="wb-appointments"
                                    defaultActiveKey={bookings.map((_, idx) => `booking-${idx}`)}
                                    expandIconPosition="right"
                                    expandIcon={({isActive}) => (
                                        <RightOutlined
                                            style={{fontSize: 16, color: theme.textSecondary}}
                                            rotate={isActive ? 90 : 0}
                                        />
                                    )}
                                    style={{borderBottom: "none", width: "100%", marginTop: 5}}>
                                    {bookings.map((booking, idx) => (
                                        <Panel
                                            key={`booking-${idx}`}
                                            className="wb-appointment"
                                            style={{backgroundColor: theme.secondary, color: theme.textSecondary}}
                                            showArrow={true}
                                            header={
                                                <CollapseHeader
                                                    name={`${booking.employeeFirstName} ${booking.employeeLastName}`}
                                                    backgroundColor={theme.secondary}
                                                    color={theme.textSecondary}
                                                />
                                            }>
                                            <CollapseContent
                                                providerName={`${booking.employeeFirstName} ${booking.employeeLastName}`}
                                                providerPhone={booking.employeeEmail
                                                    .toLowerCase()
                                                    .replace(`${INVALID_EMAIL_MARK} `, "")}
                                                address={booking.productName}
                                                date={
                                                    booking.registeredToAllSessions ? (
                                                        <span style={{fontSize: 13, color: "#13C296", fontWeight: 900}}>
                                                            Registered to all sessions
                                                        </span>
                                                    ) : (
                                                        moment(date, "YYYY-MM-DD").format("MMMM Do, YYYY")
                                                    )
                                                }
                                                start={booking.registeredToAllSessions ? null : booking.start}
                                                end={booking.registeredToAllSessions ? null : booking.end}
                                                hideActions={true}
                                            />
                                        </Panel>
                                    ))}
                                </Collapse>
                            ) : (
                                <div style={{textAlign: "center", marginTop: 50}}>
                                    <span style={{color: "#8492A6", fontSize: 18, fontWeight: 400}}>No bookings.</span>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};
