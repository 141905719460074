import React from "react";
import {FileUpload} from "../../components/FileUpload";
import {TrashIcon} from "../../icons";
import {ProgressiveImageLoader} from "../../components";
import "./page-layouts-admin.css";

export const getPopupStepFields = (selectedPopup, onUpdateStep, index) => [
    {
        name: "title",
        label: "כותרת ראשית",
        info: "כותרת ראשית שתוצג בפופאפ"
    },
    {
        name: "description",
        label: "תיאור",
        optional: true,
        info: "תיאור קצר של הפופאפ",
        type: "textarea",
        minRows: 3,
        maxRows: 10,
        maxLength: 256
    },
    {
        name: "buttonText",
        label: "כיתוב לכפתור"
    },
    {
        name: "link",
        label: "קישור לאתר",
        info: "בלחיצה על הכפתור המשתמש יועבר לקישור המוגדר כאן",
        className: "popup-settings-ltr"
    },
    {
        name: "img",
        type: "custom",
        label: "תמונה",
        info: "תמונה שתוצג בפופאפ",
        customComponent: selectedPopup?.img ? (
            <>
                <ProgressiveImageLoader src={selectedPopup.img.imageUrl} width={399} height={180} />
                <div>
                    <TrashIcon
                        style={{fill: "black", cursor: "pointer"}}
                        onClick={() => {
                            const {img, ...otherFields} = selectedPopup;
                            onUpdateStep(index, otherFields);
                        }}
                    />
                    <a href={selectedPopup.img?.imageUrl} target="_blank" rel="noopener noreferrer">
                        {selectedPopup.img?.name}
                    </a>
                </div>
            </>
        ) : (
            <FileUpload
                acceptedFormats={"image/png,image/jpeg,.gif"}
                multiple={false}
                onUploadSuccess={fileInfo => {
                    const newStep = {...selectedPopup, img: fileInfo};
                    onUpdateStep(index, newStep);
                }}
            />
        )
    }
];
