import React, {useContext, useState} from "react";
import {SearchInput} from "../components/Search";
import {Card, Empty, Spin, Tag, Tooltip, message, Modal, Form} from "antd";
import styled from "styled-components";
import {HttpClient} from "../http/HttpClient";
import {ArrowRightOutlined} from "@ant-design/icons";
import {CancelButton, FormBottomBar, FormInput, SaveButton} from "../components/form";
import {AppContext} from "../AppContext";
import {PageTitleLabel, PageSubTitleLabel} from "../components/PageTitle";
import {Images} from "../images/Images";
import {EventBus} from "../bus/EventBus";
import {StyledButton} from "../components/StyledButton";
import {GoogleAnalytics} from "../GoogleAnalytics";

const CategoryCard = styled(Card)`
    width: ${props => (props.type === "large" ? "600px" : "280px")};
    height: 200px;
    border-radius: 20px;
    border: 1px solid #e8edf5;
    background-color: #2f3e83;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: ${props => (props.mobileview === "true" ? "10px" : "20px")};
    position: relative;
`;

const CardContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: inherit;
`;

const CategoryTitle = styled.label`
    font-size: 30px;
    font-weight: 700;
    color: white;
    margin-bottom: 20px;
    cursor: inherit;
    text-align: center;
`;

const CategorySubTitle = styled.label`
    font-size: 18px;
    color: white;
    cursor: inherit;
`;

export const ComingSoonTag = styled(Tag)`
    background-color: rgb(38, 52, 68);
    border-radius: 10px;
    font-size: 20px;
    color: white;
    position: absolute;
    left: 10px;
    top: 10px;
    font-family: Caveat, sans-serif !important;
`;

export const ProfessionalInvitationModal = ({visible, onClose}) => {
    const [sending, setSending] = useState(false);
    const {me} = useContext(AppContext);

    const sendInvitation = async ({email}) => {
        setSending(true);
        try {
            await HttpClient.post("/api/providers/invite", {email});
            GoogleAnalytics.event("Company Services", "Invite Own Professional", me.companyId);
            message.success("Invitation sent!");
        } catch (e) {
            const provider = e.extraInfo;
            if (e.statusCode === 400 && provider && provider.type === "provider") {
                EventBus.trigger("server-error", {
                    image: "success2",
                    content: {
                        title: "OMG!",
                        subTitle: "You probably didn't notice...",
                        description: [
                            `${email} is already registered to Well B...ֿ`,
                            "Please send'em an offer through their profile ;)"
                        ],
                        hideSteps: true
                    },
                    cta: {
                        text: "Open profile",
                        path: `/providers/${provider.userId}`
                    }
                });
            } else if (e.statusCode === 400) {
                EventBus.trigger("server-error", {
                    image: "oops",
                    content: {
                        title: "Oopsy...",
                        subTitle: "You probably didn't notice...",
                        description: e.message,
                        hideSteps: true
                    },
                    cta: {
                        hide: true
                    }
                });
            } else {
                message.error("Opps... something went wrong, please try again later.", 3);
            }
        }
        onClose();
        setSending(false);
    };

    return (
        <Modal
            className="wb-modal"
            open={visible}
            title={null}
            footer={null}
            onCancel={() => onClose()}
            destroyOnClose={true}>
            <PageTitleLabel>Invite a professional</PageTitleLabel>
            <PageSubTitleLabel>Know someone who would be perfect for this platform?</PageSubTitleLabel>
            <PageSubTitleLabel>Already have a professional you work with?</PageSubTitleLabel>
            <PageSubTitleLabel>Invite them to the platform!</PageSubTitleLabel>
            <div style={{display: "flex", justifyContent: "center"}}>
                <Form style={{marginTop: 24, maxWidth: 400, width: "100%"}} onFinish={sendInvitation}>
                    <Form.Item
                        validateFirst={true}
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: "email",
                                message: "Please enter valid company's email."
                            }
                        ]}>
                        <FormInput type="email" placeholder="Email" />
                    </Form.Item>
                    <Form.Item>
                        <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
                            <CancelButton
                                onClick={onClose}
                                style={{backgroundColor: "transparent", color: "#1A344A"}}
                            />
                            <SaveButton
                                text="Send"
                                loading={sending}
                                style={{backgroundColor: "#00C693", marginLeft: 10}}
                            />
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};

export class CompanyServices extends React.Component {
    static contextType = AppContext;

    state = {
        term: "",
        services: null,
        showInvitationModal: false
    };

    filter(term) {
        this.setState({term: term.toLowerCase()});
    }

    async componentDidMount() {
        await this.fetchCategories();
    }

    async fetchCategories() {
        try {
            const services = await HttpClient.get("/api/categories");
            this.setState({services});
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we didn't manage to preset all our amazing categories :("}},
                e.message
            );
        }
    }

    enterService(service) {
        const {history} = this.props;

        if (service.providers.length > 0) {
            history.push(`/dashboard/services/${service.serviceId}/providers`);
        }
    }

    openInvitationModal() {
        this.setState({showInvitationModal: true});
    }

    closeInvitationModal() {
        this.setState({showInvitationModal: false});
    }

    render() {
        const {isMobile} = this.context;

        let {term, services, showInvitationModal} = this.state;

        if (Array.isArray(services) && term) {
            services = services.filter(service => service.categoryName.toLowerCase().indexOf(term) >= 0);
        }

        return (
            <div
                className="wb-categories-container"
                style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                <ProfessionalInvitationModal
                    visible={showInvitationModal}
                    onClose={() => this.closeInvitationModal()}
                />
                <PageTitleLabel style={{textAlign: "center", flex: 0, marginBottom: 20}}>
                    {isMobile ? "Our Professionals" : "Enjoy a variety of professionals, online & on-site!"}
                </PageTitleLabel>
                <div
                    style={{
                        width: "100%",
                        maxWidth: 800,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: 20
                    }}>
                    <SearchInput
                        style={{maxWidth: 520, width: "100%", marginLeft: 25, marginRight: 25}}
                        onChange={evt => this.filter(evt.target.value)}
                        placeholder="Search category"
                    />
                </div>
                <div
                    className="wb-categories-width"
                    style={{display: "flex", flexWrap: "wrap", justifyContent: "center", marginBottom: 20}}>
                    {!services ? (
                        <Spin style={{marginTop: 80}} size="large" />
                    ) : services.length === 0 ? (
                        <Empty
                            description="No such category"
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{marginTop: 80}}
                        />
                    ) : (
                        services.map(service => {
                            const imageName = service.serviceId;
                            const image = isMobile ? Images.services.mobile[imageName] : Images.services[imageName];

                            const card = (
                                <CategoryCard
                                    className="wb-category-card"
                                    key={service.serviceId}
                                    type={service.viewType}
                                    onClick={() => (service.comingSoon ? {} : this.enterService(service))}
                                    style={{
                                        backgroundImage: `url(${image})`,
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        backgroundColor: "white",
                                        backgroundRepeat: "no-repeat",
                                        border: "none",
                                        cursor: service.providers.length > 0 ? "pointer" : "not-allowed"
                                    }}>
                                    {service.comingSoon ? (
                                        <ComingSoonTag color="#263444">Coming Soon</ComingSoonTag>
                                    ) : null}
                                    <CardContainer>
                                        <CategoryTitle style={{marginBottom: service.comingSoon ? 0 : 20}}>
                                            {service.categoryName}
                                        </CategoryTitle>
                                        {!service.comingSoon ? (
                                            <CategorySubTitle>
                                                {service.providers.length} Professionals
                                            </CategorySubTitle>
                                        ) : null}
                                    </CardContainer>
                                </CategoryCard>
                            );

                            return service.providers.length > 0 || service.comingSoon ? (
                                card
                            ) : (
                                <Tooltip
                                    key={`service-${service.serviceId}`}
                                    title={`No ${service.categoryName} professionals yet.`}>
                                    {card}
                                </Tooltip>
                            );
                        })
                    )}
                </div>
                <FormBottomBar backgroundColor="#273444" color="white">
                    <div
                        className="wb-justify-bottom-bar"
                        style={{display: "flex", width: "100%", alignItems: "center"}}>
                        <label className="wb-block-desktop-only" style={{marginRight: 5, fontSize: 16, color: "white"}}>
                            Already have professionals of your own? Invite them to the platform!
                        </label>
                        <StyledButton
                            onClick={() => this.openInvitationModal()}
                            style={{
                                color: "#273444",
                                border: "1px solid #273444",
                                backgroundColor: "white",
                                minWidth: 280,
                                borderRadius: 100
                            }}>
                            Invite my own professionals
                            <ArrowRightOutlined style={{marginLeft: 10, paddingTop: 4}} />
                        </StyledButton>
                    </div>
                </FormBottomBar>
            </div>
        );
    }
}
