import React from "react";
import moment from "moment-timezone";
import {Button} from "antd";
import {OrderStatus, OrderStatusLabel} from "../../components";
import {TableDateCell} from "../../components/Table/TableDateCell";
import "./provider-orders.css";
import classNames from "classnames";
import {isOrderHasShippingProvider} from "../../event/utils.jsx";

const columns = (providerDetails, onDetails, onDelivered, onAccept, eventsQueue) => [
    {
        title: "#",
        key: "#",
        width: 50,
        fixed: "center",
        render(_, __, idx) {
            return `${idx + 1}`;
        }
    },
    {
        title: "מספר הזמנה",
        dataIndex: "proposalId",
        render(proposalId) {
            return proposalId;
        }
    },
    {
        title: "שם המוצר",
        dataIndex: "productName",
        render(productName) {
            return productName;
        }
    },
    {
        title: "כמות",
        dataIndex: "productAmount",
        render(productAmount, {productAmountType, eventId}) {
            if (!productAmount) {
                return (
                    <div className="link-text" onClick={() => onDetails(eventId)}>
                        לחץ כאן לפרטים
                    </div>
                );
            }

            return `${productAmount} ${productAmountType}`;
        }
    },
    {
        title: "תאריך הזמנה",
        dataIndex: "createdAt",
        sorter: ({createdAt: r1}, {createdAt: r2}) => (r1 < r2 ? 1 : -1),
        render(createdAt) {
            return createdAt ? moment(createdAt).format("DD/MM/YY | HH:mm") : "";
        }
    },
    {
        title: "תאריך הספקה",
        dataIndex: "dtstart",
        sorter: ({dtstart: r1}, {dtstart: r2}) => r1 - r2,
        render(startDate, {until}) {
            return <TableDateCell date={startDate} endDate={until ?? startDate} />;
        }
    },
    {
        title: "איפה",
        dataIndex: "address"
    },
    {
        title: "סכום ההזמנה",
        dataIndex: "offeredPrice",
        render(offeredPrice) {
            return offeredPrice?.toLocaleString();
        }
    },
    {
        title: "עלות משלוח",
        key: "delivery",
        render(_, {deliveryPrice, providerDeliveryPrice}) {
            return providerDeliveryPrice ?? deliveryPrice;
        }
    },
    {
        title: "זיכוי",
        dataIndex: "providerDiscount",
        sorter: (r1, r2) => (r1.providerDiscount ?? 0) - (r2.providerDiscount ?? 0),
        render(providerDiscount) {
            return (-1 * providerDiscount || 0).toLocaleString();
        }
    },
    {
        title: "טיפ",
        dataIndex: "tip",
        render(tip) {
            return (tip || 0).toLocaleString();
        }
    },
    {
        title: "סטטוס",
        dataIndex: "status",
        sorter: ({status: r1}, {status: r2}) => r1?.localeCompare(r2),
        render(_, event) {
            if (event.status === "pending") {
                return (
                    <Button
                        className={classNames(
                            "provider-order-action-button provider-order-action-button-accept-order",
                            {
                                "provider-order-action-button-accept-edited-order": Boolean(event.lastEditedBy)
                            }
                        )}
                        disabled={eventsQueue.find(queueEventId => queueEventId === event.eventId)}
                        onClick={async e => {
                            e.stopPropagation();
                            await onAccept(event);
                        }}>
                        {Boolean(event.lastEditedBy) ? "אישור הזמנה מחדש" : "אישור הזמנה"}
                    </Button>
                );
            }
            return (
                <OrderStatusLabel
                    className="provider-orders-status"
                    order={event}
                    hidePaymentStatuses={true}
                    hideEditedBy={true}
                />
            );
        }
    },
    {
        title: "מנהלת לקוח",
        dataIndex: "assignedCSMs",
        render(assignedCSMs) {
            return assignedCSMs?.[0]?.firstName;
        }
    },
    {
        title: "פעולות",
        dataIndex: "actions",
        fixed: "center",
        render(_, event) {
            return (
                <div className="provider-orders-status-buttons-container">
                    <Button
                        className="provider-order-action-button provider-order-action-button-view-order"
                        onClick={() => onDetails(event?.eventId)}>
                        צפייה בהזמנה
                    </Button>
                    <Button
                        className="provider-order-action-button provider-order-action-button-delivered"
                        disabled={
                            event.status !== OrderStatus.accepted ||
                            event.eventStatusMap?.some(({id}) => id === OrderStatus.delivered) ||
                            isOrderHasShippingProvider(event)
                        }
                        onClick={() => onDelivered(event?.eventId)}>
                        סמן כסופק
                    </Button>
                </div>
            );
        }
    }
];

export default columns;
