import React from "react";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {Button} from "antd";

export const BackButton = props => {
    const {style} = props;

    props = Object.keys(props).reduce((theProps, key) => {
        if (key !== "style") {
            theProps[key] = props[key];
        }

        return theProps;
    }, {});

    return (
        <Button className="wb-back-btn" style={style} {...props}>
            <ArrowLeftOutlined />
        </Button>
    );
};
