import {ActivityLocationTypes, ProviderActivityPossibleLocations} from "../../consts.js";
import {ExportToExcel} from "../../utils/exportToExcel.js";
import {employeesExcelKeys, employeesExcelKeyToDisplayName} from "../../marketplace/OrderLocationInput/consts.jsx";

const getTranslatedColumns = () => {
    return Object.entries(employeesExcelKeyToDisplayName).map(([key, header]) => ({
        key,
        header
    }));
};

export const addressesListExcelExporter = async (addresses, fileName) => {
    const sheetsConfig = [
        {
            title: ProviderActivityPossibleLocations[ActivityLocationTypes.homeDelivery],
            data: addresses,
            columnsConfig: {
                columns: getTranslatedColumns()
            }
        }
    ];

    await ExportToExcel({sheetsConfig, fileName});
};

export const OrderMultipleAddressFields = {
    ...employeesExcelKeys,
    deliveryId: "deliveryId",
    error: "error",
    shippingDetails: "shippingDetails",
    status: "status"
};
