import React, {useCallback, useMemo, useState} from "react";
import {ProgressiveImageLoader} from "../../components";
import {Counter} from "../../components/Counter";
import {NOOP} from "../../utils/NOOP";
import classNames from "classnames";
import GeneralImage from "../../images/general-image.svg";
import {ModularProductDetailsModal} from "./ModularProductDetailsModal.jsx";
import {ZoomInOutlined} from "@ant-design/icons";
import {getLogger} from "../../Logger.jsx";
import {Tooltip} from "antd";
import {FoodRequestToIcon} from "./consts.jsx";
import {FoodRequestsShortIdToName} from "../ProductsFilterModal/consts.jsx";

export const RenderSubProductsList = ({
    subProducts,
    showProviderSubProductsImages,
    onChangeProperty = NOOP,
    adminMode = false
}) => {
    const [selectedProduct, setSelectedProduct] = useState(false);

    const log = useMemo(() => getLogger({}, "RenderSubProductsList"), []);

    const onPictureClick = useCallback(
        product => {
            setSelectedProduct(product);
            log("subProductModalImageClick", {productId: product.id});
        },
        [log]
    );

    const onPictureClose = useCallback(() => {
        setSelectedProduct(null);
    }, [log]);

    return (
        <>
            <ModularProductDetailsModal
                open={!!selectedProduct}
                onClose={onPictureClose}
                product={selectedProduct}
                showProviderSubProductsImages={showProviderSubProductsImages}
            />
            {subProducts.map(subProduct => (
                <div
                    key={"subproducts_" + subProduct?.id}
                    id={"subproducts_" + subProduct?.id}
                    className="order-form-modular-products-sub-products-container">
                    <div className="provider-price-by-modular-info-container">
                        <div className="picture-container" onClick={() => onPictureClick(subProduct)}>
                            <div
                                className={classNames("provider-price-by-modular-content-picture", {
                                    default: !(showProviderSubProductsImages && subProduct?.subProductImage?.imageUrl)
                                })}>
                                <ProgressiveImageLoader
                                    src={
                                        showProviderSubProductsImages && subProduct?.subProductImage?.imageUrl
                                            ? subProduct?.subProductImage?.imageUrl
                                            : GeneralImage
                                    }
                                    alt={subProduct?.subProductImage?.imageId}
                                    width={70}
                                    height={70}
                                    maxWidth={150}
                                />
                            </div>
                            <ZoomInOutlined className="zoom-icon" />
                        </div>

                        <div
                            className={classNames("provider-price-by-modular-content", {
                                "non-active-product": !subProduct.isActive
                            })}>
                            <SubProductTitleContainer subProduct={subProduct} />
                            <div
                                className={classNames(
                                    "provider-price-by-modular-item-description",
                                    "provider-price-by-modular-item-description-line-limit",
                                    {
                                        "non-active-product": !subProduct.isActive
                                    }
                                )}>
                                {subProduct?.description}
                            </div>
                            <div className="provider-footer-container">
                                <div
                                    className={classNames("provider-price-by-modular-item-price", {
                                        "non-active-product": !subProduct.isActive
                                    })}>
                                    <span>{Number(subProduct?.price)?.toLocaleString()}</span>
                                    <span> ₪ </span>
                                    <span> ליחידה </span>
                                </div>
                                <div className="provider-price-by-modular-content-price">
                                    <Counter
                                        value={subProduct?.amount ?? subProduct?.defaultAmount}
                                        onChange={newAmount => {
                                            onChangeProperty(subProduct?.id, "amount", newAmount);
                                        }}
                                        disabled={!subProduct.isActive && !adminMode}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export const SubProductTitleContainer = ({subProduct}) => {
    return (
        <div className="order-form-modular-products-sub-product-title">
            <span className="sub-product-name">{subProduct?.name}</span>
            {!subProduct.isActive && <span className="non-active-product-error">אזל מהמלאי</span>}
            <div className="sub-product-food-requests-option">
                {(subProduct?.foodRequests ?? []).map((foodOption, index) => {
                    return (
                        <Tooltip key={index} title={FoodRequestsShortIdToName[foodOption]}>
                            <img
                                key={index}
                                className="sub-product-food-request-image"
                                src={FoodRequestToIcon[foodOption]}
                                alt={foodOption}
                            />
                        </Tooltip>
                    );
                })}
            </div>
        </div>
    );
};
