import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Carousel, Modal, message, Upload, Button} from "antd";
import {InboxOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import "./order-images-modal.css";
import {TrashIcon} from "../../../icons";
import {StyledButton} from "../../../components";

const {Dragger} = Upload;

export const OrderImagesModal = ({orderImages, onClose, onSave, visible}) => {
    const [images, setImages] = useState([]);
    const carouselRef = useRef(null);
    const [fileList, setFileList] = useState([]);

    const removeImage = useCallback(
        imageId => {
            setImages(currentImages => currentImages.filter(image => image.id !== imageId));
            carouselRef.current.prev();
        },
        [carouselRef]
    );

    const onBeforeUpload = useCallback(file => {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error("Image must smaller than 5MB!");
        }

        return isLt5M;
    }, []);

    const onUpload = useCallback((info, filelist) => {
        const {status, uid} = info.file;

        if (status !== "uploading") {
            console.log(info.file, info.fileList);
        }

        if (status === "done") {
            message.success(`${info.file.name} file uploaded successfully.`);
            const type = info.file.type.split("/")[0];

            setImages(currentImages => [
                ...currentImages,
                {
                    id: info.file.response.imageId,
                    name: info.file.name,
                    url: `https://res.cloudinary.com/well-b-prod/${type}/upload/${info.file.response.imageId}`,
                    type: info.file.type
                }
            ]);
        } else if (status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }

        setFileList(prev => {
            const currentFileIndex = prev.findIndex(file => file.uid === uid);
            if (currentFileIndex < 0) {
                return [...prev, info.file];
            }

            const updatedList = [...prev];
            updatedList[currentFileIndex] = info.file;

            return updatedList;
        });
    }, []);

    useEffect(() => {
        setImages(orderImages ?? []);
        setFileList([]);
    }, [visible]);

    return (
        <Modal
            onOk={() => onSave(images)}
            onCancel={onClose}
            open={visible}
            className="order-images-modal-container"
            width="50vw"
            footer={null}>
            <>
                <div className="order-images-modal-title">הוספת תמונות</div>

                {images.length > 0 && (
                    <div className="order-images-modal-slider">
                        <button className="order-images-modal-button-right" onClick={() => carouselRef.current.next()}>
                            <RightOutlined />
                        </button>
                        <button className="order-images-modal-button-left" onClick={() => carouselRef.current.prev()}>
                            <LeftOutlined />
                        </button>

                        <Carousel
                            className="order-images-modal-carusel-container"
                            variableWidth={true}
                            centerMode={true}
                            ref={carouselRef}
                            slidesToShow={1}
                            slidesToScroll={1}
                            infinite={images.length > 3}
                            dots={false}
                            swipeToSlide={true}>
                            {images &&
                                images.map((image, index) => {
                                    const isVideo = image?.type.split("/")[0] === "video";
                                    return (
                                        <>
                                            <div className="order-images-modal-delete-icon">
                                                <TrashIcon
                                                    style={{fill: "white"}}
                                                    onClick={() => removeImage(image.id)}
                                                />
                                            </div>
                                            {isVideo ? (
                                                <video
                                                    className="order-images-modal-item"
                                                    autoPlay={true}
                                                    loop={true}
                                                    muted={true}>
                                                    <source src={image?.url} type={image?.type} />
                                                </video>
                                            ) : (
                                                <img alt="photo" src={image?.url} className="order-images-modal-item" />
                                            )}
                                        </>
                                    );
                                })}
                        </Carousel>
                    </div>
                )}

                <Dragger
                    name="file"
                    accept=".png,.jpg,.jpeg,.mp4"
                    multiple={true}
                    action={"/api/upload/"}
                    fileList={fileList ?? []}
                    beforeUpload={onBeforeUpload}
                    onChange={onUpload}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">לחץ או גרור קובץ לכאן על מנת להעלות</p>
                    <p className="ant-upload-hint">נתמך בבחירה יחידה או מרובה של קבצים</p>
                    <p className="ant-upload-hint">שימו לב! מגבלת העלאה עבור כל קובץ היא עד 5MB</p>
                </Dragger>

                <div className="order-images-modal-actions">
                    <Button className="order-images-modal-actions-button-cancel" onClick={onClose}>
                        ביטול
                    </Button>
                    <Button className="order-images-modal-actions-button-confirm" onClick={() => onSave(images)}>
                        שמירה
                    </Button>
                </div>
            </>
        </Modal>
    );
};
