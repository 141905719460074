import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Input, Spin} from "antd";
import moment from "moment";
import {OrderStatus, SquareButton} from "../../components";
import {useRequest} from "../../utils/hooks";
import {HttpClient} from "../../http/HttpClient";
import "./provider-approve-delivery.css";

const ProviderApproveDelivery = ({match}) => {
    const [showErrorInputBox, seShowErrorInputBox] = useState(false);
    const [errorText, setErrorText] = useState(null);
    const eventId = useMemo(() => match.params.eventId, [match]);

    const [eventInfo, loadingEventInfo] = useRequest(`/api/events/${eventId}/proposalV2`);

    const [innerEventInfo, setInnerEventInfo] = useState(null);

    useEffect(() => {
        if (eventInfo) {
            setInnerEventInfo(eventInfo);
        }
    }, [eventInfo]);

    const markAsDelivered = useCallback(async () => {
        const newEvent = await HttpClient.safePost(`/api/events/${eventId}/delivered`);

        setInnerEventInfo(newEvent);
    }, [eventId]);

    const markAsDeliveryError = useCallback(async () => {
        const newEvent = await HttpClient.safePost(`/api/events/${eventId}/deliveryError`, {errorText});
        setInnerEventInfo(newEvent);
    }, [errorText]);

    const alreadyMarkedText = useMemo(() => {
        if (innerEventInfo?.eventStatusMap?.some(({id}) => id === OrderStatus.delivered)) {
            return " משלוח סומן כסופק, תודה על העדכון :)";
        }

        if (innerEventInfo?.eventStatusMap?.some(({id}) => id === OrderStatus.deliveryError)) {
            return "תקלת המשלוח נשלחה בהצלחה, תודה על העדכון";
        }
    }, [innerEventInfo]);

    return (
        <div className="provider-approve-delivery">
            {loadingEventInfo || !innerEventInfo ? (
                <Spin />
            ) : (
                <>
                    <div className="provider-approve-delivery-title">
                        <span>{`הזמנה ${innerEventInfo?.proposalId}`}</span>
                        <span>{`עד תאריך ${moment(innerEventInfo?.dtstart)
                            .add(innerEventInfo?.deliveryArrivingTimeRange ?? 0, "hours")
                            .format("DD/MM/YYYY HH:mm")}`}</span>
                        <span>{`לחברת ${innerEventInfo?.companyName}`}</span>
                    </div>
                    {alreadyMarkedText ? (
                        <span className="provider-approve-already-delivered">{alreadyMarkedText}</span>
                    ) : (
                        <>
                            <p>
                                נא לעדכן את מצב המשלוח
                                <br /> אם המשלוח עוד לא הגיע ליעד, יש לחכות לפני שלוחצים על הכפתור
                            </p>
                            <div className="provider-approve-delivery-buttons-container">
                                <SquareButton onClick={markAsDelivered} className="primary-color-button">
                                    המשלוח נמסר ללקוח :)
                                </SquareButton>
                                {showErrorInputBox ? (
                                    <div className="provider-approve-delivery-error-section">
                                        <Input.TextArea
                                            autoFocus={true}
                                            onChange={({target}) => setErrorText(target.value)}
                                            placeholder="נא פרטו כאן מה הייתה הבעיה"
                                        />
                                        <SquareButton onClick={markAsDeliveryError}>שלח שגיאה</SquareButton>
                                    </div>
                                ) : (
                                    <SquareButton
                                        onClick={() => seShowErrorInputBox(true)}
                                        className="standard-color-button">
                                        הייתה בעיה במשלוח
                                    </SquareButton>
                                )}
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default ProviderApproveDelivery;
