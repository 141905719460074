import React from "react";
import {CarouselArrow} from "../components/carousel/CarouselArrows";
import {WBCarousel} from "../components/carousel/WBCarousel";
import {Rate} from "antd";
import {SmartImageContainer} from "../components/SmartImageContainer";
import {isTextContainHebrew} from "../utils/langaugeUtils";

const ReviewPanel = ({review}) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: 320,
                padding: 20,
                direction: "ltr"
            }}>
            {review.logoUrl ? (
                <SmartImageContainer
                    width={160}
                    height={65}
                    src={review.logoUrl}
                    transformations={{
                        crop: "lpad"
                    }}
                    style={{
                        width: 160,
                        height: 65,
                        marginBottom: 20
                    }}
                />
            ) : null}
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 20
                }}>
                <Rate style={{fontSize: 14, color: "#F7B348"}} disabled={true} defaultValue={review.rating} />
            </div>
            {review.content ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                    <div
                        style={{
                            fontSize: 16,
                            color: "#273444",
                            fontWeight: 500,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: 10,
                            textAlign: "center",
                            maxWidth: 460,
                            direction: isTextContainHebrew(review.content) ? "rtl" : "ltr"
                        }}>
                        {review.content}
                    </div>
                </div>
            ) : null}
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 300,
                    color: "#203448",
                    fontSize: 14
                }}>
                {review.anonymous ? review.companyName : `${review.userFirstName} from ${review.companyName}`}
            </div>
        </div>
    );
};

export const ProviderReviews = ({reviews}) => {
    if (!Array.isArray(reviews) || reviews.length === 0) {
        return null;
    }

    const carouselProps = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        nextArrow: (
            <CarouselArrow
                backgroundColor="white"
                arrowColor="var(--secondary-color)"
                direction={CarouselArrow.Direction.NEXT}
            />
        ),
        prevArrow: (
            <CarouselArrow
                backgroundColor="white"
                arrowColor="var(--secondary-color)"
                direction={CarouselArrow.Direction.PREV}
            />
        ),
        accessibility: true,
        arrows: true,
        className: "wb-carousel wb-provider-reviews"
    };

    return (
        <div
            style={{
                display: "block",
                backgroundColor: "#F6F7F8",
                height: 320,
                width: "100%",
                marginTop: 20,
                borderRadius: 10
            }}>
            <WBCarousel carouselProps={carouselProps}>
                {reviews.map(review => (
                    <ReviewPanel key={review.reviewId} review={review} />
                ))}
            </WBCarousel>
        </div>
    );
};
