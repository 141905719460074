import React from "react";
import classNames from "classnames";
import {NOOP} from "../../utils/NOOP";
import {Images} from "../../images/Images";
import "./like.css";

export const Like = ({id, classname = "", onChange = NOOP, checked = false, disabled = false}) => (
    <div
        id={id}
        className={classNames("like-actions-container", classname)}
        aria-checked={!!checked}
        onClick={e => e.stopPropagation()}>
        <label className="like-actions-like">
            <input
                className="like-actions-checkbox"
                type="checkbox"
                checked={checked}
                onChange={e => onChange(e.target.checked)}
                disabled={disabled}
            />
            <div
                style={{
                    backgroundImage: `url(${Images.LikeHeartImage})`
                }}
                className="like-actions-hearth"
            />
        </label>
    </div>
);
