import React, {useCallback, useState} from "react";
import {Button, message, Upload} from "antd";
import {InboxOutlined} from "@ant-design/icons";
import {NOOP} from "../../utils/NOOP";
import {HttpClient} from "../../http/HttpClient";
import {FileUploadBase} from "./FileUploadBase";
import {createUrlWithoutTransformations} from "../../utils/cloudinaryUtils";

export const FileUpload = ({
    onUploadSuccess = NOOP,
    sizeLimitInMB = 5,
    acceptedFormats = ".png,.jpg,.jpeg,.mp4",
    uploadServerURL = "/api/upload/",
    multiple = true,
    showUploadList = false,
    showUploadButton = true
}) => {
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const onBeforeUpload = useCallback(
        file => {
            const isLtSizeLimitInMB = file.size / 1024 / 1024 < sizeLimitInMB;
            if (!isLtSizeLimitInMB) {
                message.error(`File must smaller than ${sizeLimitInMB}MB!`);
                return false;
            }

            if (multiple) {
                setFileList(currentFileList => [...currentFileList, file]);
            } else {
                setFileList([file]);
            }
            return false;
        },
        [sizeLimitInMB]
    );

    const handleUpload = useCallback(async () => {
        setUploading(true);

        for (const file of fileList) {
            const formData = new FormData();
            formData.append("file", file);

            const fileRequest = await fetch(uploadServerURL, {
                method: "POST",
                body: formData
            });

            if (!fileRequest.ok) {
                message.error("upload failed.");
                continue;
            }

            const fileJson = await fileRequest.json();

            const imageUrl = createUrlWithoutTransformations(fileJson.imageUrl);

            onUploadSuccess({
                ...fileJson,
                name: file.name,
                imageUrl
            });
            setFileList(currentFileList => currentFileList.filter(({id}) => id !== file.id));
            message.success("upload successfully " + file.name);
        }

        setUploading(false);
    }, [fileList]);

    return (
        <>
            <FileUploadBase
                fileList={fileList}
                setFileList={setFileList}
                sizeLimitInMB={sizeLimitInMB}
                multiple={multiple}
                acceptedFormats={acceptedFormats}
                showUploadList={showUploadList}
                onBeforeUpload={onBeforeUpload}
            />
            {showUploadButton ? (
                <Button
                    type="primary"
                    onClick={handleUpload}
                    disabled={fileList.length === 0}
                    loading={uploading}
                    style={{
                        marginTop: 16
                    }}>
                    {uploading ? "מעלה קבצים, אנא המתן" : "לחץ להעלאה"}
                </Button>
            ) : null}
        </>
    );
};
