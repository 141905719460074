import React, {useCallback, useEffect, useMemo, useState} from "react";
import {List, Modal} from "antd";
import shortid from "shortid";
import moment from "moment";
import {SearchInputWithIcon} from "../components";
import "./happiness-calculator-add-occasion-modal.css";

const HappinessCalculatorAddOccasionModal = ({visible, occasionCategory, onClose, onSave}) => {
    const [searchInput, setSearchInput] = useState(null);
    const [customOccasionInput, setCustomOccasionInput] = useState(null);
    const [results, setResults] = useState([]);

    useEffect(() => {
        if (occasionCategory?.otherOccasions) {
            setResults(occasionCategory?.otherOccasions.map(current => ({...current, isActive: false})));
        }
    }, [occasionCategory?.otherOccasions]);

    useEffect(() => {
        if (occasionCategory?.categoryName) {
            setCustomOccasionInput(`${occasionCategory?.categoryName} כללי`);
        }
    }, [occasionCategory?.categoryName]);

    const filterResults = useMemo(
        () => (searchInput?.length ? results.filter(({name}) => name.includes(searchInput)) : results),
        [results, searchInput]
    );

    const addCustomOccasion = useCallback(name => {
        setResults(current => [
            {
                name,
                occasionId: shortid.generate(),
                icon: "🚀",
                isActive: true,
                price: null
            },
            ...current
        ]);
    }, []);

    const onSelectOccasion = useCallback(occasionId => {
        setResults(current =>
            current.map(occasion => {
                if (occasion.occasionId === occasionId) {
                    return {...occasion, isActive: !occasion.isActive};
                }

                return occasion;
            })
        );
    }, []);

    const onSaveNewOccasions = useCallback(() => {
        return onSave(
            occasionCategory,
            results.filter(({isActive}) => isActive)
        );
    }, [results, occasionCategory]);

    return (
        <Modal
            className="wb-modal-radius simple-modal-container"
            style={{borderRadius: "5px"}}
            bodyStyle={{maxHeight: "calc(100vh - 200px)"}}
            destroyOnClose={true}
            open={visible}
            onCancel={onClose}
            footer={null}
            title={null}>
            <div className="happiness-calculator-add-modal-top-bar">
                <div className="happiness-calculator-add-modal-content">
                    <span className="happiness-calculator-add-modal-title">הוספת מועדים מיוחדים נוספים</span>
                    <span className="happiness-calculator-add-modal-subtitle">
                        לקטגוריית {occasionCategory?.categoryName}
                    </span>
                </div>

                <div className="happiness-calculator-add-modal-search">
                    <SearchInputWithIcon
                        placeholder="חיפוש מועד"
                        searchInput={searchInput}
                        setSearchInput={setSearchInput}
                    />
                </div>
            </div>

            <div className="happiness-calculator-add-modal-add-occasion">
                <input
                    value={customOccasionInput}
                    onChange={e => setCustomOccasionInput(e.target.value)}
                    placeholder={`${occasionCategory?.categoryName} כללי..`}
                    onKeyDown={e => (e?.key === "Enter" ? addCustomOccasion(customOccasionInput) : null)}
                />
                <button onClick={() => addCustomOccasion(customOccasionInput)}>הוספה</button>
            </div>

            <div className="happiness-calculator-add-modal-list">
                <List
                    locale={{emptyText: "לא נמצאו תוצאות"}}
                    dataSource={filterResults}
                    itemLayout="horizontal"
                    style={{overflowY: "auto", minHeight: "200px", maxHeight: "calc(100vh - 600px)", direction: "ltr"}}
                    renderItem={occasion => (
                        <List.Item style={{direction: "rtl"}} highlight={occasion.isActive.toString()}>
                            <div
                                onClick={() => onSelectOccasion(occasion.occasionId)}
                                className="happiness-calculator-add-modal-container">
                                <div className="happiness-calculator-add-modal-content">
                                    <span className="happiness-calculator-add-modal-logo">{occasion.icon}</span>
                                </div>

                                <div className="happiness-calculator-add-modal-content-width">
                                    <div className="happiness-calculator-add-modal-content-name-price">
                                        <span className="happiness-calculator-add-modal-text-bold">
                                            {occasion.name}
                                        </span>

                                        {occasion.price !== null && (
                                            <span className="happiness-calculator-add-modal-text-price">
                                                החל מ-{occasion.price}₪
                                            </span>
                                        )}
                                    </div>

                                    <div className="happiness-calculator-add-modal-content">
                                        <span className="happiness-calculator-add-modal-text">
                                            {occasion.startDate
                                                ? `${moment(occasion.startDate).format("DD.MM.YYYY")}`
                                                : ""}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </List.Item>
                    )}
                />
            </div>

            <button onClick={onSaveNewOccasions} className="happiness-calculator-add-modal-button">
                הוספת מועדים
            </button>
        </Modal>
    );
};

export default HappinessCalculatorAddOccasionModal;
