import React, {useCallback, useMemo, useState} from "react";
import {sumBy} from "lodash";
import {ConfigProvider, Input, message, Tooltip} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import AdminProviderPaymentHistoryModal from "./AdminProviderPaymentHistoryModal";
import {OldInvoicesModal} from "./OldInvoiceModal";
import {adminVendorsColumns} from "./AdminVendorsColumns";
import {ExportProvidersTableToExcel} from "./AdminProvidersExcelExporter";
import {AdminProviderStatisticsLabel} from "./AdminProviderStatisticsLabel";
import {ProviderNetPaymentTermsModal} from "./ProviderNetPaymentTermsModal";
import {ADMIN_PATH_NAME} from "../consts";
import {
    AreYouSureModal,
    ConfirmationRequiredWrapper,
    PageTitleHeader,
    RoundedTable,
    SquareButton
} from "../../components";
import {HttpClient} from "../../http/HttpClient";
import {useDebounceState, useRequest} from "../../utils/hooks";
import "./admin-vendors.css";
import {AdminProviderActiveStatisticsLabel} from "./AdminProviderStatisticsLabel/AdminProviderActiveStatisticsLabel";
import {ProviderOrdersProjection, ProviderProjectionForExcel} from "./consts.js";

export const AdminVendors = ({history}) => {
    const [searchInput, setSearchInput] = useDebounceState("", 200);
    const [deletingProviderId, setDeletingProviderId] = useState(null);
    const [providerPaymentModalData, setProviderPaymentModalData] = useState(null);
    const [oldInvoiceModalData, setOldInvoiceModalData] = useState(null);
    const [providerNetPaymentTermsModal, setProviderNetPaymentTermsModal] = useState(null);
    const [isExcelLoading, setIsExcelLoading] = useState(false);

    const [providersPagination, setProvidersPagination] = useState({});

    const providersRequestURI = useMemo(() => {
        const params = new URLSearchParams();

        if (searchInput) {
            params.append("search", searchInput);
        }

        params.append("page", providersPagination?.page);
        params.append("pageSize", providersPagination?.itemsInPage);

        const queryString = params.toString();

        return `/admin/api/vendors?${queryString}`;
    }, [searchInput, providersPagination?.page, providersPagination?.itemsInPage]);

    const [providersFromServer = [], isLoadingProviders, __, fetchProviders] = useRequest(
        providersRequestURI,
        "POST",
        null,
        [providersRequestURI],
        providersPagination?.page && providersPagination?.itemsInPage
    );
    const [services] = useRequest("/api/services");

    const providers = useMemo(() => providersFromServer?.providers ?? [], [providersFromServer?.providers]);

    const deleteProvider = useCallback(async () => {
        try {
            await HttpClient.delete(`/admin/api/providers/${deletingProviderId}`);
            fetchProviders();
        } catch (e) {
            message.error(e.message, 3);
        }
        setDeletingProviderId(null);
    }, [providersFromServer, deletingProviderId, fetchProviders]);

    const editProvider = useCallback(
        providerId => {
            history.push(`/${ADMIN_PATH_NAME}/upload/${providerId}`);
        },
        [history]
    );

    const approvingProvider = useCallback(
        async (providerId, isApprove) => {
            const {error} = await HttpClient.safePost(`/admin/api/providers/${providerId}/approval`, {
                isApprove: isApprove
            });

            if (error) {
                message.error("שגיאת בעת עידכון סטאטוס השותף");
                message.error(error);
                return;
            }

            fetchProviders();
        },
        [fetchProviders]
    );

    const onUploadNewVendor = useCallback(() => {
        history.push(`/${ADMIN_PATH_NAME}/upload`);
    }, [history]);

    const totalProducts = useMemo(
        () =>
            sumBy(providers, provider =>
                provider.approved ? provider.services.filter(({isActive, demo}) => isActive && !demo).length : 0
            ),
        [providers]
    );

    const providersDebt = useMemo(() => {
        const arrearsDebt = sumBy(providers, provider => Number(provider?.arrearsDebt ?? 0));
        const futureDebt = sumBy(providers, provider => Number(provider?.futureDebt ?? 0));

        return {arrearsDebt, futureDebt, totalDebt: arrearsDebt + futureDebt};
    }, [providers]);

    const [statistics] = useRequest("/admin/api/vendor/statistics", "GET", null, [providersDebt]);

    const onExportToExcelClicked = useCallback(async () => {
        setIsExcelLoading(true);
        try {
            const filteredProvidersIds = providers.map(({providerId}) => providerId);

            const [providersDetails, providersOrders] = await Promise.all([
                HttpClient.safePost("/admin/api/vendors/byIds", {
                    providerIds: filteredProvidersIds,
                    projection: ProviderProjectionForExcel
                }),
                HttpClient.safePost("/admin/api/orders/getAllProviderOrders/byProviderIds", {
                    providerIds: filteredProvidersIds,
                    fields: ProviderOrdersProjection
                })
            ]);

            await ExportProvidersTableToExcel(providersDetails, providersOrders);
        } catch (error) {
            message.error("שגיאה בעת הדפסת נתוני אקסל");
        } finally {
            setIsExcelLoading(false);
        }
    }, [providers]);

    const sendInvoiceUploadReminder = useCallback(async () => {
        const {error} = await HttpClient.safePost("/admin/api/providerInvoice/sendProviderInvoiceUploadReminder");

        if (error) {
            message.error("שגיאה בעת שליחת תזכורות לספקים להעלות חשבונית");
            return;
        }

        message.success("התזכורות נשלחו בהצלחה");
    }, []);

    const onChangePagination = useCallback((pageNumber, pageSize) => {
        setProvidersPagination({page: pageNumber, pageSize});
    }, []);

    const openProviderNetPaymentTermsModal = useCallback(
        providerId => {
            const foundProvider = providers.find(provider => provider.providerId === providerId);
            const {businessName, netPaymentTerms} = foundProvider ?? {};
            setProviderNetPaymentTermsModal({providerId, businessName, netPaymentTerms});
        },
        [providers]
    );

    const onSearchChange = useCallback(
        searchText => {
            onChangePagination(1, providersFromServer?.pageSize);
            setSearchInput(searchText);
        },
        [providersFromServer?.pageSize]
    );

    return (
        <div style={{display: "flex", justifyContent: "center", margin: 10, marginBottom: 40}}>
            <AreYouSureModal
                visible={!!deletingProviderId}
                onClose={() => setDeletingProviderId(null)}
                title="בטוח שאתה רוצה למחוק את הספק הזה?"
                saveStyle={{color: "white", backgroundColor: "#F06060"}}
                onYes={deleteProvider}
                onNo={() => setDeletingProviderId(null)}
            />
            <AdminProviderPaymentHistoryModal
                provider={providerPaymentModalData}
                visible={!!providerPaymentModalData}
                onCancel={() => setProviderPaymentModalData(null)}
            />
            <OldInvoicesModal
                onClose={() => setOldInvoiceModalData(null)}
                visible={!!oldInvoiceModalData}
                modalData={oldInvoiceModalData}
                updateMonthlyInvoice={invoices =>
                    setOldInvoiceModalData(prev => ({
                        ...prev,
                        invoices
                    }))
                }
            />

            <ProviderNetPaymentTermsModal
                visible={!!providerNetPaymentTermsModal}
                providerInfo={providerNetPaymentTermsModal}
                onSave={async ({providerId, providerNetPaymentTerms, businessName}) => {
                    const {error} = await HttpClient.safePost(`/admin/api/providers/${providerId}/updateField`, {
                        field: "netPaymentTerms",
                        value: providerNetPaymentTerms
                    });

                    if (error) {
                        message.error("שגיאה! תנאי השוטף לא התעדכנו");
                        return;
                    }

                    message.success(`תנאי השוטף של הספק ${businessName} עודכנו לתנאי שוטף +${providerNetPaymentTerms}`);
                    setProviderNetPaymentTermsModal(null);
                    fetchProviders();
                }}
                onClose={() => setProviderNetPaymentTermsModal(null)}
            />

            <div className="admin-vendors-container">
                <PageTitleHeader showBack={false}>כרטסת שותפים</PageTitleHeader>
                <div className="admin-vendors-top-bar">
                    <div className="admin-vendors-top-left-bar">
                        <ConfirmationRequiredWrapper
                            subTitle="שימו לב! מדובר בפעולה כבדה שיכולה לקחת עד כמה דקות עד שתסתיים"
                            onYes={() => onExportToExcelClicked()}>
                            <SquareButton
                                className="admin-vendors-export-to-excel-button"
                                loading={isExcelLoading}
                                disabled={!providers}>
                                ייצא פירוט לאקסל
                            </SquareButton>
                        </ConfirmationRequiredWrapper>

                        <SquareButton className="admin-vendors-load-new-provider" onClick={onUploadNewVendor}>
                            טעינת שותף חדש
                        </SquareButton>

                        <ConfirmationRequiredWrapper
                            subTitle={"הפעולה תשלח תזכורת להעלות חשבונית לכל הספקים שלא העלו חשבונית"}
                            onYes={sendInvoiceUploadReminder}>
                            <Tooltip title="שליחת תזכורת להעלות חשבונית לכל הספקים שלא העלו חשבונית">
                                <SquareButton className="admin-vendors-send-invoice-upload-reminder">
                                    שליחת תזכורות
                                </SquareButton>
                            </Tooltip>
                        </ConfirmationRequiredWrapper>

                        <ConfigProvider direction="rtl">
                            <AdminProviderStatisticsLabel providersDebt={providersDebt} />
                        </ConfigProvider>
                    </div>
                    <div className="admin-vendors-search-container">
                        <ConfigProvider direction="rtl">
                            <AdminProviderActiveStatisticsLabel statistics={statistics} />
                        </ConfigProvider>

                        <Input
                            className="admin-vendors-search"
                            onChange={e => onSearchChange(e.target.value)}
                            placeholder="חפש שותף"
                            suffix={<SearchOutlined />}
                        />
                    </div>
                </div>
                <ConfigProvider direction="rtl">
                    <RoundedTable
                        rowKey="providerId"
                        scroll={{y: 600}}
                        pagination={{
                            pageSize: providersFromServer?.pageSize,
                            total: providersFromServer?.totalProviders,
                            showTotal: total => <span>סה״כ {total}</span>,
                            onChange: (pageNumber, pageSize) => onChangePagination(pageNumber, pageSize)
                        }}
                        loading={isLoadingProviders}
                        dataSource={providers || []}
                        columns={adminVendorsColumns(
                            async providerId => setDeletingProviderId(providerId),
                            services || [],
                            setProviderPaymentModalData,
                            providerId => editProvider(providerId),
                            async (providerId, isApprove) => approvingProvider(providerId, isApprove),
                            setOldInvoiceModalData,
                            providerId => openProviderNetPaymentTermsModal(providerId)
                        )}
                    />
                </ConfigProvider>
            </div>
        </div>
    );
};
