import {ORDERS_PER_PAGE} from "../AdminOrders/consts.jsx";
import {OrderStatus, RoundedTable} from "../../components/index.jsx";
import columns from "../AdminOrders/columns.jsx";
import React, {useCallback, useContext, useState} from "react";
import {AppContext} from "../../AppContext.jsx";
import {HttpClient} from "../../http/HttpClient.jsx";
import {ConfigProvider, message} from "antd";
import {OrderChangesHistoryModal} from "../AdminOrders/OrderChangesHistoryModal.jsx";
import {openWindow} from "../../http/WindowOpener.jsx";
import {UpdateOrderStatusModal} from "../AdminOrders/UpdateOrderModal/index.js";
import {EventBus} from "../../bus/EventBus.jsx";
import {GoogleAnalytics} from "../../GoogleAnalytics.jsx";
import {StringBuilder} from "../../AppUtils.js";
import {DeclineOrderModal} from "../../event/DeclineOrderModal/DeclineOrderModal.jsx";
import {SearchTopBar} from "./SearchTopBar.jsx";
import {useDebounceState, useRequest} from "../../utils/hooks.jsx";
import "./AdminOrdersManagement.css";
import {AdminOrderSearchProjection, DefaultAdminOrderSearchField} from "./consts.js";

export const AdminOrdersManagement = () => {
    const {me, services} = useContext(AppContext);
    const [orders, setOrders] = useState([]);
    const [loadingOrders, setLoadingOrders] = useState(false);
    const [historyOrder, setHistoryOrder] = useState(null);
    const [updateOrderStatusInfo, setUpdateOrderStatusInfo] = useState(null);
    const [declineOrder, setDeclineOrder] = useState(null);

    const [searchInput, setSearchInput, unDebouncedSearchInput] = useDebounceState("", 500);
    const [searchField, setSearchField] = useState(DefaultAdminOrderSearchField);

    const [deliveryProviders] = useRequest("/admin/api/deliveryProviders");

    const onEditOrder = useCallback(order => {
        openWindow(`/admin/events/${order.eventId}/edit`);
    }, []);

    const onSearch = useCallback(async (searchInput, searchField) => {
        setLoadingOrders(true);
        const ordersFromServer = await HttpClient.safePost("/admin/api/orders/byField", {
            searchInput,
            searchField,
            projection: AdminOrderSearchProjection
        });

        if (ordersFromServer?.error) {
            message.error("שגיאה בעת טעינת הזמנות");
        } else {
            setOrders(ordersFromServer);
        }

        setLoadingOrders(false);
    }, []);

    const onAcceptOrder = useCallback(async event => {
        const acceptedOrder = await HttpClient.safePost(`/api/events/${event.eventId}/acceptV2`, {
            updatedAt: event.updatedAt
        });

        if (acceptedOrder.error) {
            EventBus.triggerError("server-error", {content: {description: acceptedOrder.error}}, acceptedOrder.error);
            message.error(new StringBuilder("שגיאה בעת אישור ההזמנה").append(`(${acceptedOrder.error})`).toString());
        } else {
            message.success("ההזמנה אושרה בהצלחה");
        }
        GoogleAnalytics.event("Session Proposal", "Accept", event.eventId);
    }, []);

    const onCancelOrder = useCallback(async event => {
        const canceledOrder = await HttpClient.post(
            `/admin/api/orders/${event.eventId}/cancel?updatedAt=${event.updatedAt}`
        );

        if (canceledOrder.error) {
            message.error(new StringBuilder("שגיאה בעת ביטול ההזמנה").append(`(${canceledOrder.error})`).toString());
        } else {
            message.success("ההזמנה בוטלה בהצלחה");
        }
    }, []);

    const onDeliveredOrder = useCallback(async event => {
        const deliveredOrder = await HttpClient.post(`/api/events/${event.eventId}/delivered`);

        if (deliveredOrder.error) {
            message.error(
                new StringBuilder("שגיאה בעת שינוי סטאטוס ההזמנה לסופק").append(`(${deliveredOrder.error})`).toString()
            );
        } else {
            message.success("סטאטוס ההזמנה שונה לסופקה בהצלחה");
        }
    }, []);

    const onDeclineOrder = useCallback(
        async ({declineReason, declineComment}) => {
            try {
                const declinedOrder = await HttpClient.post(`/api/events/${declineOrder.eventId}/declineV2`, {
                    declineReason,
                    declineComment
                });

                if (declinedOrder.error) {
                    message.error(
                        new StringBuilder("שגיאה בעת סירוב ההזמנה").append(`(${declinedOrder.error})`).toString()
                    );
                } else {
                    message.success("ההזמנה סורבה בהצלחה");
                }

                onSearch();
            } catch (e) {
                message.error(e.message, 3);
            }
            setDeclineOrder(null);
        },
        [declineOrder?.eventId, onSearch]
    );

    const onChangeOrderStatus = useCallback(
        async (event, status, toRemove) => {
            try {
                if (toRemove) {
                    await HttpClient.post(`/admin/api/orders/${event.eventId}/changeStatus/${status}`, {
                        remove: toRemove,
                        updatedAt: event.updatedAt
                    });
                } else {
                    switch (status) {
                        case OrderStatus.accepted: {
                            await onAcceptOrder(event);
                            break;
                        }
                        case OrderStatus.declined: {
                            setDeclineOrder(event);
                            break;
                        }
                        case OrderStatus.delivered: {
                            await onDeliveredOrder(event);
                            break;
                        }
                        case OrderStatus.cancelled: {
                            await onCancelOrder(event);
                            break;
                        }
                        default: {
                            const changeStatusRes = await HttpClient.post(
                                `/admin/api/orders/${event.eventId}/changeStatus/${status}`,
                                {
                                    remove: toRemove,
                                    updatedAt: event.updatedAt
                                }
                            );

                            if (changeStatusRes.error) {
                                message.error(
                                    new StringBuilder("שגיאה בעת שינוי סטאטוס ההזמנה")
                                        .append(`(${changeStatusRes.error})`)
                                        .toString()
                                );
                            } else {
                                message.success("סטאטוס ההזמנה שונה בהצלחה");
                            }

                            break;
                        }
                    }
                }

                onSearch();
            } catch (e) {
                message.error(e.message, 3);
            }
            setUpdateOrderStatusInfo(null);
        },
        [onAcceptOrder, onCancelOrder, onDeliveredOrder]
    );

    return (
        <>
            <ConfigProvider direction={"rtl"}>
                <DeclineOrderModal
                    visible={!!declineOrder}
                    onSave={onDeclineOrder}
                    onClose={() => setDeclineOrder(null)}
                />

                <UpdateOrderStatusModal
                    setUpdateOrderStatusInfo={setUpdateOrderStatusInfo}
                    updateOrderStatusInfo={updateOrderStatusInfo}
                    onChangeOrderStatus={onChangeOrderStatus}
                />

                <OrderChangesHistoryModal
                    visible={!!historyOrder}
                    order={historyOrder}
                    onClose={() => setHistoryOrder(null)}
                />
            </ConfigProvider>

            <div className="admin-orders-management">
                <SearchTopBar
                    onSearch={onSearch}
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    unDebouncedSearchInput={unDebouncedSearchInput}
                    searchField={searchField}
                    setSearchField={setSearchField}
                />

                <ConfigProvider direction={"rtl"}>
                    <div className="admin-orders-management-table-container">
                        <RoundedTable
                            className="admin-orders-management-table"
                            rowKey="eventId"
                            pagination={{
                                position: ["bottomCenter"],
                                showSizeChanger: false,
                                defaultPageSize: ORDERS_PER_PAGE,
                                showTotal: total => <span>סה״כ {total}</span>
                            }}
                            scroll={{x: 1400}}
                            dataSource={orders}
                            loading={loadingOrders}
                            columns={columns(
                                me,
                                services,
                                onEditOrder,
                                (order, status, toRemove) =>
                                    setUpdateOrderStatusInfo({
                                        event: order,
                                        status,
                                        toRemove
                                    }),
                                () => onSearch(searchInput, searchField),
                                null,
                                null,
                                null,
                                order => setHistoryOrder(order),
                                null,
                                true,
                                null,
                                null,
                                null,
                                deliveryProviders,
                                true
                            )}
                        />
                    </div>
                </ConfigProvider>
            </div>
        </>
    );
};
