import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Modal, Switch} from "antd";
import {uniq, without} from "lodash";
import {getColumns} from "./columns";
import {ProductsBase} from "./ProductsBase";
import {transformFilterValueToLockedFilters, transformLockedFiltersToFilterValue} from "./utils";
import {AppContext} from "../../../AppContext";
import {SquareButton} from "../../../components";
import {openWindow} from "../../../http/WindowOpener";
import {isObjectWithEmptyFields} from "../../../utils/objectUtils";
import "./select-products-modal.css";

const SelectProductsModal = ({
    visible,
    onSave,
    onCancel,
    filterValues,
    productIds,
    topBarButtons,
    lockedProducts: defaultLockedProducts
}) => {
    const {me} = useContext(AppContext);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [currentSubCategory, setCurrentSubCategory] = useState(null);
    const [lockedFilters, setLockedFilters] = useState([]);
    const [lockedProducts, setLockedProducts] = useState({});
    const [currentSubCategoryItems, setCurrentSubCategoryItems] = useState([]);
    const [currentTablePage, setCurrentTablePage] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [showOnlyActive, setShowOnlyActive] = useState(true);
    const [extraFilters, setExtraFilters] = useState({});

    const openLinkInNewTab = useCallback(productId => {
        openWindow(`/dashboard/services/product/${productId}`, "_blank");
    }, []);

    const onTableSelectRowChange = useCallback((currentView = [], toAdd) => {
        setSelectedRowKeys(prev => (toAdd ? uniq([...(prev ?? []), ...currentView]) : without(prev, ...currentView)));
    }, []);

    useEffect(() => {
        setSelectedRowKeys(productIds ?? []);
    }, [productIds]);

    useEffect(() => {
        setLockedProducts(defaultLockedProducts ?? {});
    }, [defaultLockedProducts]);

    useEffect(() => {
        setLockedFilters(transformFilterValueToLockedFilters(filterValues ?? {}));
    }, [filterValues]);

    const filterProducts = useMemo(
        () => (showOnlyActive ? currentSubCategoryItems?.filter(({isActive}) => isActive) : currentSubCategoryItems),
        [currentSubCategoryItems, showOnlyActive]
    );

    const lockProduct = useCallback((productId, newLockState) => {
        setLockedProducts(prev => {
            if (prev[productId] === newLockState) {
                const {[productId]: _, ...rest} = prev;
                return rest;
            }

            return {
                ...prev,
                [productId]: newLockState
            };
        });
    }, []);

    const clearState = useCallback(() => {
        setExtraFilters({});
        setLockedFilters([]);
        setLockedProducts({});
        setCurrentTablePage(0);
        setSelectedRowKeys([]);
        setShowOnlyActive(true);
        setCurrentCategory(null);
        setCurrentSubCategory(null);
        setCurrentSubCategoryItems([]);
    }, []);

    const save = useCallback(() => {
        const filterValues = transformLockedFiltersToFilterValue(lockedFilters);
        const tags = extraFilters.searchTags;
        filterValues.searchTags = tags;

        const isSaved = onSave(
            selectedRowKeys,
            isObjectWithEmptyFields(filterValues) ? null : filterValues,
            isObjectWithEmptyFields(lockedProducts) ? null : lockedProducts
        );
        if (isSaved) {
            clearState();
        }
    }, [clearState, lockedFilters, lockedProducts, onSave, selectedRowKeys, extraFilters]);

    return (
        <Modal
            maskClosable={false}
            open={visible}
            onCancel={() => {
                clearState();
                onCancel();
            }}
            footer={null}
            width={1240}>
            <div className="select-products-modal-container">
                <div className="select-products-modal-top-bar-buttons">
                    {topBarButtons}
                    <Switch
                        checked={showOnlyActive}
                        onChange={setShowOnlyActive}
                        checkedChildren="הצג רק מוצרים פעילים"
                        unCheckedChildren="הצג את כל המוצרים"
                    />
                </div>
                <div className="select-products-modal-info-container">
                    <span>{selectedRowKeys?.length} מוצרים באתר </span>
                    <SquareButton onClick={() => save()}>שמירה</SquareButton>
                </div>
            </div>
            <ProductsBase
                setExtraFilters={setExtraFilters}
                extraFilters={extraFilters}
                currentCategory={currentCategory}
                setCurrentCategory={setCurrentCategory}
                setCurrentTablePage={setCurrentTablePage}
                currentSubCategory={currentSubCategory}
                setCurrentSubCategory={setCurrentSubCategory}
                currentSubCategoryItems={filterProducts}
                setCurrentSubCategoryItems={setCurrentSubCategoryItems}
                filterValues={filterValues}
                lockedFilters={lockedFilters.map(({id}) => id)}
                onLockedFiltersChange={(filter, remove) => {
                    setLockedFilters(prev => (remove ? prev.filter(({id}) => id !== filter.id) : [...prev, filter]));
                }}
                columns={getColumns(me, lockProduct)}
                tableProps={{
                    rowClassName: product => {
                        if (product.productId in lockedProducts) {
                            return lockedProducts[product.productId] ? "white-list-product" : "black-list-product";
                        }
                    }
                }}
                rowSelection={{
                    selectedRowKeys,
                    onChange: onTableSelectRowChange
                }}
            />
        </Modal>
    );
};

export default SelectProductsModal;
