import React, {useCallback, useEffect, useMemo, useState} from "react";
import {message, Select} from "antd";
import {sortBy} from "lodash";
import {AdminOccasionsCategoriesColumns, prioritys} from "./AdminOccasionsCategoriesColumns";
import {FormModal, RoundedTable, SearchInputWithIcon, SquareButton} from "../../components";
import {useRequest} from "../../utils/hooks";
import {HttpClient} from "../../http/HttpClient";
import PageTitleHeader from "../../components/PageTitle";
import "./admin-occasions-categories.css";

export const AdminOccasionsCategories = () => {
    const [consumptionOccasions] = useRequest("/admin/api/consumptionOccasions");
    const [occasionsCategorysRequest, isOccasionsCategorysRequestLoading] = useRequest("/api/occasions/categories");

    const [searchInput, setSearchInput] = useState("");
    const [occasionsCategorys, setOccasionsCategorys] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedOccasion, setSelectedOccasion] = useState(null);

    const filterCategory = useMemo(() => {
        const occasionsCategory = occasionsCategorys.find(category => category.occasionCategoryId === selectedCategory);
        return occasionsCategory?.occasions ?? [];
    }, [selectedCategory, occasionsCategorys]);

    const filterCategoryBySearch = useMemo(() => {
        return filterCategory.filter(({occasionId}) => {
            const name = consumptionOccasions.find(occasion => occasionId === occasion.occasionId)?.name;
            return name?.toLowerCase().includes(searchInput?.toLowerCase());
        });
    }, [filterCategory, consumptionOccasions, searchInput]);

    const filterConsumptionOccasions = useMemo(() => {
        return sortBy(
            consumptionOccasions?.filter(({occasionId}) => {
                const foundOccasionId = filterCategory.find(
                    ({occasionId: categoryOccasionId}) => categoryOccasionId === occasionId
                );
                return !foundOccasionId;
            }),
            "name"
        );
    }, [consumptionOccasions, filterCategory]);

    const addOccasionToCategory = useCallback(
        async (occasionCategoryId, occasionValues) => {
            const occasionsCategory = occasionsCategorys.find(
                category => category.occasionCategoryId === occasionCategoryId
            );
            if (!occasionsCategory) {
                message.error("קטגוריה לא נמצאה");
                return;
            }

            const isOccasionExist = occasionsCategory.occasions.find(
                occasion => occasion.occasionId === occasionValues.occasionId
            );
            const {minPrice, priority} = occasionValues;

            if (isOccasionExist) {
                await HttpClient.safePost(
                    `/admin/occasions/occasionCategory/${occasionsCategory.occasionCategoryId}/occasionId/${isOccasionExist.occasionId}`,
                    {minPrice: Number(minPrice), priority: Number(priority)}
                );

                const newOccasions = occasionsCategory.occasions.map(occasion => {
                    if (occasion.occasionId === occasionValues.occasionId) {
                        return {
                            ...occasionValues
                        };
                    }
                    return occasion;
                });

                setOccasionsCategorys(
                    occasionsCategorys.map(category => {
                        if (category.occasionCategoryId === occasionCategoryId) {
                            return {
                                ...category,
                                occasions: newOccasions
                            };
                        }
                        return category;
                    })
                );

                return;
            }

            await HttpClient.safePut(
                `/admin/occasions/occasionCategory/${occasionsCategory.occasionCategoryId}/occasionId/${occasionValues.occasionId}`,
                {minPrice: Number(minPrice), priority: Number(priority)}
            );

            setOccasionsCategorys(
                occasionsCategorys.map(category => {
                    if (category.occasionCategoryId === occasionCategoryId) {
                        return {
                            ...category,
                            occasions: [...category.occasions, occasionValues]
                        };
                    }
                    return category;
                })
            );
        },
        [occasionsCategorys]
    );

    const onCreate = useCallback(() => {
        if (filterConsumptionOccasions.length === 0) {
            message.error("אין אירועים להוספה");
            return;
        }

        setSelectedOccasion({});
    }, [filterConsumptionOccasions]);

    const onEdit = useCallback(
        occasionId => {
            const occasionsCategory = occasionsCategorys.find(
                category => category.occasionCategoryId === selectedCategory
            );

            if (!occasionsCategory) {
                message.error("קטגוריה לא נמצאה");
                return;
            }

            const occasion = occasionsCategory.occasions.find(occasion => occasion.occasionId === occasionId);

            if (!occasion) {
                message.error("אירוע לא נמצא");
                return;
            }

            setSelectedOccasion(occasion);
        },
        [occasionsCategorys, selectedCategory]
    );

    const onDelete = useCallback(
        async occasionId => {
            const occasionsCategory = occasionsCategorys.find(
                category => category.occasionCategoryId === selectedCategory
            );

            if (!occasionsCategory) {
                message.error("קטגוריה לא נמצאה");
                return;
            }

            const newOccasions = occasionsCategory.occasions.filter(occasion => occasion.occasionId !== occasionId);

            await HttpClient.safeDelete(
                `/admin/occasions/occasionCategory/${occasionsCategory.occasionCategoryId}/occasionId/${occasionId}`
            );
            setOccasionsCategorys(currentOccasionsCategorys =>
                currentOccasionsCategorys.map(category => {
                    if (category.occasionCategoryId === selectedCategory) {
                        return {
                            ...category,
                            occasions: newOccasions
                        };
                    }
                    return category;
                })
            );
        },
        [selectedCategory, occasionsCategorys]
    );

    useEffect(() => {
        if (occasionsCategorysRequest) {
            setOccasionsCategorys(occasionsCategorysRequest);
        }
    }, [isOccasionsCategorysRequestLoading]);

    return (
        <div>
            <PageTitleHeader showBack={false}>ניהול קטגוריות מחשבון</PageTitleHeader>

            <div className="admin-occasions-categories-actions-container">
                <SearchInputWithIcon
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    placeholder="חפש אירוע"
                />

                <Select
                    className="admin-occasions-categories-selector-category"
                    onSelect={value => setSelectedCategory(value)}
                    placeholder="בחר קטגוריה">
                    {occasionsCategorys.map(category => (
                        <Select.Option key={category.occasionCategoryId} value={category.occasionCategoryId}>
                            {category.categoryName}
                        </Select.Option>
                    ))}
                </Select>

                <SquareButton onClick={onCreate} disabled={!selectedCategory}>
                    הגדר מידע לאירוע
                </SquareButton>
            </div>

            <RoundedTable
                rowKey="occasionId"
                dataSource={filterCategoryBySearch}
                columns={AdminOccasionsCategoriesColumns(
                    consumptionOccasions,
                    occasionId => onEdit(occasionId),
                    occasionId => onDelete(occasionId)
                )}
            />

            <FormModal
                header={selectedOccasion?.occasionId ? "עריכת אירוע ✏️" : "הוספת אירוע 🥳"}
                visible={selectedOccasion ?? false}
                content={selectedOccasion}
                onSave={async values => {
                    await addOccasionToCategory(selectedCategory, values);
                    setSelectedOccasion(null);
                }}
                onClose={() => {
                    setSelectedOccasion(null);
                }}
                fields={[
                    {
                        name: "occasionId",
                        label: "שם האירוע",
                        type: "select",
                        disabled: !!selectedOccasion?.occasionId,
                        required: true,
                        options: !!selectedOccasion?.occasionId
                            ? consumptionOccasions?.map(event => {
                                  return {
                                      label: event.name,
                                      name: event.occasionId
                                  };
                              })
                            : filterConsumptionOccasions?.map(event => {
                                  return {
                                      label: event.name,
                                      name: event.occasionId
                                  };
                              })
                    },
                    {
                        name: "minPrice",
                        label: "מחיר מינימלי",
                        type: "text",
                        required: true
                    },
                    {
                        name: "priority",
                        label: "עדיפות",
                        type: "select",
                        required: true,
                        options: prioritys.map(priority => ({
                            label: priority.text,
                            name: priority.priority
                        }))
                    }
                ]}
            />
        </div>
    );
};
