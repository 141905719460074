import React, {useMemo} from "react";
import {Modal} from "antd";
import classnames from "classnames";
import {PageTitleHeader} from "../../components";
import "../marketplace.css";
import common from "@well-b/well-b-shared";
import {getThemeByOrigin} from "../../utils.js";
import {BookingCompleteImage} from "../../images/Images.jsx";

export const BookingCompleteModal = ({visible, eventId, onSave, onClose, company, me}) => {
    const bookingCompleteImg = useMemo(() => {
        const theme = getThemeByOrigin(window.location.origin);
        return BookingCompleteImage[theme] || BookingCompleteImage.image;
    }, []);
    return (
        <Modal
            centered={true}
            width={500}
            className={classnames("wb-modal-radius", "booking-complete-modal-container")}
            style={{borderRadius: "5px"}}
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            maskClosable={false}
            footer={null}
            title={null}
            closable={false}>
            <div className="booking-complete-modal">
                <div style={{display: "flex", flexDirection: "column"}}>
                    <img src={bookingCompleteImg} alt="Booking success" className="booking-complete-modal-image" />
                    <PageTitleHeader className="booking-complete-modal-title" removeInlineCSS={true} showBack={false}>
                        שמחים לקחת חלק בכל הרגעים המיוחדים שלכם
                    </PageTitleHeader>
                    <span className="booking-complete-modal-text">
                        {common.isUserNeedManagerConfirm(company, me)
                            ? "בקשתכם נשלחה לאישור מנהל, רק לאחר אישור המנהל ההזמנה תשלח לאישור בית העסק."
                            : "אז מה בעצם קורה עכשיו? שלחנו את ההזמנה לאישור בבית העסק שבחרתם ואנחנו כמובן נדאג לעדכן אתכם בכל שלב בתהליך לאחר אישור ההזמנה המשלוח יגיע עד אליכם בהתאם לטווח הזמנים שבחרתם :)"}
                    </span>
                </div>

                <div className="booking-complete-modal-buttons-container">
                    <div
                        className={classnames(
                            "booking-complete-modal-button",
                            "booking-complete-modal-next-order-button"
                        )}
                        onClick={() => onSave("/dashboard/services")}>
                        להמשך גלישה באתר
                    </div>
                </div>
            </div>
        </Modal>
    );
};
