import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Input, message, Switch} from "antd";
import {cloneDeep, difference, intersection, keyBy, noop, uniq, uniqBy} from "lodash";
import {ProductsTable} from "./ProductsTable";
import {ProductSearch} from "./ProductSearch";
import {FilterTiles} from "./FilterTiles";
import {useRequest} from "../../../utils/hooks";
import {isObjectWithEmptyFields} from "../../../utils/objectUtils";
import {EditBannerModal} from "../EditBannerModal";
import {SquareButton} from "../../../components";
import {HttpClient} from "../../../http/HttpClient";
import "./edit-selection-page.css";
import {SearchOutlined} from "@ant-design/icons";
import {
    cacheProductToProductWithProvider,
    filterProducts,
    productWithProviderToCacheProduct
} from "../../AdminProducts/utils";
import {translateFilterOption} from "../../upload/AdminUploadUtils";
import {convertFilterValues} from "./utils.jsx";

const PRODUCTS_IN_PAGE = 30;

const TABLE_MODES = {
    SearchResults: 0,
    SelectPage: 1
};

export const EditSelectionPage = ({selectPageId, onSave}) => {
    const [productsPagination, setProductsPagination] = useState({});
    const [searchFilters, setSearchFilters] = useState({});
    const [tableProductsShowSelector, setTableProductsShowSelector] = useState(
        selectPageId ? TABLE_MODES.SelectPage : TABLE_MODES.SearchResults
    );
    const [newSelectedProductIds, setNewSelectedProductsIds] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [selectionPageName, setSelectionPageName] = useState("");
    const [editBannerVisibility, setEditBannerVisibility] = useState(false);
    const [banner, setBanner] = useState(null);
    const [blacklistedProductIds, setBlacklistedProductIds] = useState([]);
    const [includeNotApproved, setIncludeNotApproved] = useState(false);
    const [saving, setSaving] = useState(false);
    const [orderedProductIds, setOrderedProductIds] = useState([]);
    const [innerSearchText, setInnerSearchText] = useState("");
    const includeNotApprovedString = includeNotApproved ? "true" : "false";
    const [currentSelectPage, isCurrentSelectPageLoading, selectPageError, fetchSelectPage] = useRequest(
        `/api/selectPage/${selectPageId}?includeNotApproved=${includeNotApprovedString}&slim=true&page=${productsPagination?.page}`,
        // `/admin/api/selectPage/tablePreview/${selectPageId}`,
        "GET",
        null,
        [selectPageId, productsPagination?.page, tableProductsShowSelector],
        selectPageId && productsPagination?.page && tableProductsShowSelector === TABLE_MODES.SelectPage
    );

    const getAllContentByFiltersRequestBody = useMemo(
        () => ({
            filters: {
                ...convertFilterValues(searchFilters),
                options: searchFilters?.options?.map(translateFilterOption)
            },
            page: productsPagination?.page ?? 1
        }),
        [searchFilters, productsPagination]
    );
    const [searchedProducts, searchResultsLoading] = useRequest(
        `/api/products/getAllContentByFilters?includeNotApproved=${includeNotApprovedString}&enrichProduct=true&pageSize=${PRODUCTS_IN_PAGE}`,
        "POST",
        getAllContentByFiltersRequestBody,
        [getAllContentByFiltersRequestBody, tableProductsShowSelector],
        !isObjectWithEmptyFields(searchFilters) &&
            searchFilters?.searchText &&
            tableProductsShowSelector === TABLE_MODES.SearchResults,
        null,
        []
    );

    const modifyBlacklistProductById = useCallback(productId => {
        setBlacklistedProductIds(currentBlackListItems => {
            if (currentBlackListItems.includes(productId)) {
                return currentBlackListItems.filter(blacklistItem => blacklistItem !== productId);
            } else {
                return [...currentBlackListItems, productId];
            }
        });
    }, []);

    const newSelectionPage = useMemo(() => {
        return {
            name: selectionPageName,
            banner,
            selectionRules: selectedFilters,
            productIds: orderedProductIds,
            blackListProducts: blacklistedProductIds,
            orderedProductIds
        };
    }, [selectionPageName, banner, selectedFilters, newSelectedProductIds, blacklistedProductIds, orderedProductIds]);

    const shouldFetchSelectPage = useMemo(() => {
        if (!newSelectionPage) return false;
        const containsProducts = newSelectionPage?.selectionRules?.length || newSelectionPage?.productIds?.length;
        if (containsProducts) return true;
    }, [newSelectionPage, tableProductsShowSelector]);

    const [newSelectPage, isNewSelectionPageLoading] = useRequest(
        "/api/selectPage",
        "POST",
        {selectPage: newSelectionPage, includeNotApproved, page: productsPagination?.page, pageSize: PRODUCTS_IN_PAGE},
        [newSelectionPage, includeNotApproved, productsPagination?.page, tableProductsShowSelector],
        shouldFetchSelectPage && tableProductsShowSelector === TABLE_MODES.SelectPage
    );

    const selectPageProducts = useMemo(() => {
        if (!newSelectPage) return [];
        return [...newSelectPage.products];
    }, [newSelectPage, newSelectPage?.products]);

    const newOrderedProductIds = useMemo(() => {
        // const productIds = selectPageProducts.map(product => product.service.productId);
        // const ordered = intersection(orderedProductIds, productIds);
        // const rest = difference(productIds, ordered);
        //
        // const finalOrderedProductIds = [...rest, ...ordered];
        //
        // return finalOrderedProductIds;

        return currentSelectPage?.productIds;
    }, [currentSelectPage]);

    const productsToShow = useMemo(() => {
        if (tableProductsShowSelector === TABLE_MODES.SelectPage) {
            return selectPageProducts;
        } else if (tableProductsShowSelector === TABLE_MODES.SearchResults) {
            return searchedProducts?.products ?? [];
        } else {
            return [];
        }

        // if (!showSelectPageProducts) {
        //     return searchedProducts.products;
        // }
        //
        // const productByProductId = keyBy(selectPageProducts, product => product.service.productId);
        //
        // const finalOrderedProducts = newOrderedProductIds.map(productId => productByProductId[productId]);
        //
        // return finalOrderedProducts;
    }, [tableProductsShowSelector, selectPageProducts, searchedProducts]);

    const removeSelectedProductId = useCallback(productIdToRemove => {
        setOrderedProductIds(newSelectedProductIds => newSelectedProductIds.filter(id => id !== productIdToRemove));
    }, []);

    const rowSelection = useMemo(() => {
        return {
            selectedRowKeys: orderedProductIds,
            onSelect: (record, selected) => {
                if (selected) {
                    setOrderedProductIds(newSelectedProductIds =>
                        uniq([...newSelectedProductIds, record.service.productId])
                    );
                } else {
                    removeSelectedProductId(record.service.productId);
                }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                const changed = changeRows.map(({service: {productId}}) => productId);

                if (selected) {
                    setOrderedProductIds(newSelectedProductIds => uniq([...newSelectedProductIds, ...changed]));
                } else {
                    setOrderedProductIds(newSelectedProductIds =>
                        newSelectedProductIds.filter(id => !changed.includes(id))
                    );
                }
            },
            getCheckboxProps: product => {
                const isFilterProduct = product.service.manualOrder === -1;

                return {
                    indeterminate: isFilterProduct,
                    disabled: blacklistedProductIds.find(
                        blackListProductId => blackListProductId === product.service.productId
                    )
                };
            }
        };
    }, [newSelectedProductIds, productsToShow, orderedProductIds]);

    // // delete state on close
    // const cleanup = () => {
    //     setBanner(null);
    //     setSelectionPageName("");
    //     setSelectedFilters({});
    //     setBlacklistedProductIds([]);
    //     setNewSelectedProductsIds([]);
    // };
    // useEffect(() => {
    //     return cleanup;
    // }, []);

    // populate data on load
    useEffect(() => {
        if (currentSelectPage) {
            setBanner(currentSelectPage?.banner);
            setSelectionPageName(currentSelectPage?.name);
            setBlacklistedProductIds(currentSelectPage?.blackListProducts || []);
        }
    }, [selectPageId, currentSelectPage, currentSelectPage?.filters, productsPagination?.page]);

    useEffect(() => {
        setProductsPagination({page: 1});
    }, []);

    const useEffectRef = useRef(false);

    useEffect(() => {
        if (selectPageId && !useEffectRef.current && currentSelectPage?.productIds) {
            useEffectRef.current = true;
            setOrderedProductIds(currentSelectPage?.productIds || []);
            setSelectedFilters(currentSelectPage?.selectionRules || {});
        }
    }, [currentSelectPage, selectPageId]);

    const saveChanges = useCallback(async () => {
        setSaving(true);
        const {searchText, ...rest} = newSelectionPage?.selectedFilters || {};
        const selectedFiltersToSave = rest;

        const finalSelectionPage = {
            ...newSelectionPage,
            productIds: orderedProductIds,
            selectedFilters: selectedFiltersToSave
        };

        delete finalSelectionPage.searchText;
        delete finalSelectionPage.selectionRules.searchText;

        if (selectPageId) {
            const res = await HttpClient.safePost(`/admin/api/selectPage/${selectPageId}`, finalSelectionPage);

            if (res?.error) {
                message.error("שגיאה בעת שמירת האתר");
                setSaving(false);
                return {error: res.error};
            }

            await onSave(res);
            message.success("האתר נשמר בהצלחה");
        } else {
            const res = await HttpClient.safePut(`/admin/api/selectPage`, finalSelectionPage);

            if (res?.error) {
                message.error("שגיאה בעת יצירת האתר");
                setSaving(false);
                return {error: res.error};
            }

            message.success("האתר נוצר בהצלחה");
            await onSave(res);
        }

        fetchSelectPage();
        setSaving(false);
    }, [newSelectionPage, orderedProductIds]);

    const innerFilteredProductsWithProviders = useMemo(() => {
        if (!productsToShow || !productsToShow.length) return [];
        const cachedProducts = productsToShow.map(productWithProviderToCacheProduct);
        const filteredCachedProducts = filterProducts(cachedProducts, innerSearchText);
        const filteredProductsWithProviders = filteredCachedProducts.map(cacheProductToProductWithProvider);

        return filteredProductsWithProviders;
    }, [productsToShow, innerSearchText]);

    return (
        <div className="edit-selection-page">
            <div className="row-section">
                <div className="row-section-item">
                    <Input
                        width="300"
                        placeholder="שם אתר הבחירה"
                        value={selectionPageName}
                        onChange={e => setSelectionPageName(e.target.value)}
                    />
                    <SquareButton onClick={() => setEditBannerVisibility(true)}>עריכת באנר</SquareButton>
                    <Switch
                        style={{
                            width: "255px",
                            height: "25px"
                        }}
                        unCheckedChildren="מציג תוצאות חיפוש"
                        checkedChildren="מציג אתר בחירה"
                        onClick={checked => {
                            setTableProductsShowSelector(checked ? TABLE_MODES.SelectPage : TABLE_MODES.SearchResults);
                        }}
                        checked={tableProductsShowSelector}
                    />
                </div>
                <div className="row-section-item">
                    <div>
                        <span>כמות מוצרים פעילים:</span>
                        <span> </span>
                        <span>
                            {newSelectPage?.totalProducts}
                            {/*{selectPageProducts?.filter(*/}
                            {/*    product => product.service.isActive && product.provider.approved*/}
                            {/*)?.length ?? 0}*/}
                        </span>
                    </div>

                    <SquareButton className="row-section-item-button" loading={saving} onClick={saveChanges}>
                        שמירה
                    </SquareButton>
                </div>
            </div>

            <div className="row-section" style={{justifyContent: "flex-start", gap: "20px"}}>
                <div className="row-section-item">
                    <ProductSearch
                        searchFilters={tableProductsShowSelector ? selectedFilters : searchFilters}
                        setSearchFilters={tableProductsShowSelector ? setSelectedFilters : setSearchFilters}
                        advancedSearch={tableProductsShowSelector}
                        placeholder={tableProductsShowSelector ? "הפעלת נשימה" : null}
                        actionText={tableProductsShowSelector ? "הוסף" : null}
                    />
                </div>
                <div className="row-section-item">
                    <FilterTiles
                        searchFilters={searchFilters}
                        setSearchFilters={setSearchFilters}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        showSelectPageProducts={tableProductsShowSelector}
                    />
                </div>
            </div>
            <br />
            <br />

            <Input
                className="admin-table-date-search-search-input"
                onChange={e => setInnerSearchText(e.target.value)}
                placeholder="חיפוש בטבלה"
                suffix={<SearchOutlined />}
            />
            <EditBannerModal
                hideFields={["link", "buttonText"]}
                banner={banner}
                visible={editBannerVisibility}
                onClose={() => setEditBannerVisibility(false)}
                onSave={updatedBanner => {
                    if (Object.keys(updatedBanner ?? {}).length) {
                        setBanner(updatedBanner);
                    } else {
                        setBanner(null);
                    }
                }}
                bannerDimension={{width: 620, height: 105}}
            />

            <ProductsTable
                modifyBlacklistProductById={modifyBlacklistProductById}
                setOrderedProductIds={
                    tableProductsShowSelector === TABLE_MODES.SelectPage ? setOrderedProductIds : noop
                }
                orderedProductIds={orderedProductIds}
                shouldSort={tableProductsShowSelector}
                blacklistedProductIds={blacklistedProductIds}
                products={innerFilteredProductsWithProviders}
                loading={tableProductsShowSelector ? isNewSelectionPageLoading : searchResultsLoading}
                rowSelection={rowSelection}
                pageSize={PRODUCTS_IN_PAGE}
                onUpdateTablePagination={({page, pageSize}) => {
                    setProductsPagination({page, pageSize});
                }}
                totalProducts={
                    tableProductsShowSelector ? newSelectPage?.totalProducts : searchedProducts?.totalProducts
                }
            />
        </div>
    );
};
