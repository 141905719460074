import React from "react";
import {Select} from "antd";
import shortid from "shortid";

export class SearchableSelect extends React.Component {
    uniqueID = shortid.generate();

    componentDidMount() {
        try {
            document.getElementById(`wb-searchable-select-${this.uniqueID}`).setAttribute("autocomplete", "none");
        } catch (e) {}
    }

    render() {
        return (
            <Select
                id={`wb-searchable-select-${this.uniqueID}`}
                showSearch={true}
                optionFilterProp="children"
                filterOption={(value, option) => {
                    return option.children && option.children.toLowerCase().indexOf(value.toLowerCase()) >= 0;
                }}
                {...this.props}>
                {this.props.children}
            </Select>
        );
    }
}

SearchableSelect.Option = Select.Option;
