import {MainServices, ServiceIdToName} from "../../consts.js";

export const LOGS_TO_SHOW = 100;

export const BudgetCategoryTypes = {
    ...MainServices,
    GENERAL: "general"
};

export const BudgetIdToName = {
    [BudgetCategoryTypes.GENERAL]: "צף",
    ...ServiceIdToName
};
