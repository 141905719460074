import React from "react";
import {Tabs} from "antd";
import {Redirect, Route, Switch} from "react-router";
import {AdminTasks} from "./AdminTasks";
import {ADMIN_PATH_NAME} from "../consts";
import {AppContext} from "../../AppContext";
import {extractTabFromUrl} from "../../dashboards/DefaultAppDashboard";

const {TabPane} = Tabs;

export class AdminConsoleDashboard extends React.Component {
    static contextType = AppContext;

    navigateTo(key) {
        const {history} = this.props;
        history.push(`/${ADMIN_PATH_NAME}/console/${key}`);
    }

    render() {
        const {me, isMobile} = this.context;

        const activeKey = extractTabFromUrl(/^\/admin\/console\/(query|tasks)(.*)$/);

        if (!me.isAdmin) return null;

        if (!activeKey) {
            return <Redirect to="/admin/console/query" />;
        }

        const defaultTabKey = "query";
        const tabs = [
            <TabPane style={{paddingTop: 20, overflow: "hidden", overflowX: "auto"}} tab="Query" key="query">
                <Switch>
                    <Route exact path="/admin/console/query" component={AdminDatabaseQuery} />
                </Switch>
            </TabPane>,
            <TabPane style={{paddingTop: 20, overflow: "hidden", overflowX: "auto"}} tab="Tasks" key="tasks">
                <Switch>
                    <Route exact path="/admin/console/tasks" component={AdminTasks} />
                </Switch>
            </TabPane>
        ];

        return (
            <Tabs
                animated={false}
                className="wb-admin-tabs"
                onTabClick={key => this.navigateTo(key)}
                activeKey={activeKey}
                tabBarStyle={{
                    display: isMobile ? "none" : "block",
                    color: "#2F3E83",
                    fontSize: 16,
                    fontWeight: 700,
                    position: "fixed",
                    marginBottom: 20,
                    left: 0,
                    top: 0,
                    backgroundColor: "white",
                    width: "100%",
                    zIndex: 2
                }}
                renderTabBar={(DefaultTabBarProps, DefaultTabBar) =>
                    isMobile ? <></> : <DefaultTabBar {...DefaultTabBarProps} />
                }
                defaultActiveKey={defaultTabKey}>
                {tabs}
            </Tabs>
        );
    }
}
