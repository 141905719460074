import React, {useCallback, useMemo, useRef, useState} from "react";
import {ConfigProvider, Dropdown, message, Spin, Tag, Tooltip, Tree} from "antd";
import {PlusCircleOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {FormModal, SquareButton} from "../../components";
import {FormInputV2} from "../../components/form";
import {useRequest} from "../../utils/hooks";
import {HttpClient} from "../../http/HttpClient";
import "./admin-search-tags.css";
import {MainServices, ServiceIdToName} from "../../consts.js";
import {AdminSearchTagsTable} from "./AdminSearchTagsTable.jsx";
import {CoverPhotoHandler} from "../../wizards/components/CoverPhotoHandler.jsx";

export const AdminSearchTags = ({}) => {
    const [newTag, setNewTag] = useState(null);
    const [newTagKeyWords, setNewTagKeyWords] = useState([]);
    const [defaultExpandKeys, setDefaultExpandedKeys] = useState([]);
    const [image, setImage] = useState({});

    const keyWordInputRef = useRef(null);

    const [searchTags = [], loadSearchTags, _, fetchSearchTags] = useRequest("/admin/api/searchTags");

    const onAddNewTag = useCallback(async parentId => {
        setNewTag({parentId});
    }, []);

    const addKeyWord = useCallback(
        event => {
            event.preventDefault();
            const newWord = keyWordInputRef?.current.input.value;

            if (newWord && !newTagKeyWords?.includes(newWord)) {
                setNewTagKeyWords(prevState => [...prevState, newWord]);
            } else {
                message.warn(`${newWord} כבר מופיע ברשימה`);
            }

            keyWordInputRef.current.input.value = "";
        },
        [keyWordInputRef, newTagKeyWords]
    );

    const removeKeyWord = useCallback(wordToRemove => {
        setNewTagKeyWords(prevState => prevState.filter(word => word !== wordToRemove));
    }, []);

    const closeCreateModal = useCallback(() => {
        setNewTag(null);
        setNewTagKeyWords([]);
        setImage({});
    }, []);

    const createTag = useCallback(
        async newValues => {
            const newTagData = {...newTag, ...newValues, keyWords: newTagKeyWords, image: newValues.image ?? image};

            newTagData.keyWords = newTagData.keyWords ?? [];

            newTagData.keyWords = !newTagData.keyWords.includes(newTagData.name)
                ? [...newTagData?.keyWords, newTagData.name]
                : newTagData.keyWords;

            const {children, productsIds, ...relevantUpdateData} = newTagData;

            const res = newTagData.tagId
                ? await HttpClient.safePost("/admin/api/searchTags", relevantUpdateData)
                : await HttpClient.safePut("/admin/api/searchTags", relevantUpdateData);

            if (res.error) {
                message.error("קרתה שגיאה ביצרת התג");
                return;
            }
            message.success("תגית נשמרה בהצלחה");

            closeCreateModal();
            fetchSearchTags();
        },
        [newTag, closeCreateModal, newTagKeyWords, image]
    );

    const onDuplicateTag = useCallback(async (tagData, parentId) => {
        const {children, _id, productIds, ...relevantData} = tagData;
        const newTagData = {...relevantData, parentId};

        const res = await HttpClient.safePut("/admin/api/searchTags", newTagData);

        if (res.error) {
            message.error("קרתה שגיאה בשכפול תג");
            return;
        }

        fetchSearchTags();
    }, []);

    const onDeleteTag = useCallback(async tagId => {
        const res = await HttpClient.safeDelete(`/admin/api/searchTags/${tagId}`);

        if (res.error) {
            message.error("קרתה שגיאה במחיקת תג");
            return;
        }

        fetchSearchTags();
    }, []);

    const treeData = useMemo(() => {
        if (!searchTags) {
            return [];
        }

        const rootNodes = searchTags.filter(tag => !searchTags.find(t => t.children?.includes(tag.tagId)));
        const tagsMap = searchTags.reduce((acc, tag) => ({...acc, [tag.tagId]: tag}), {});

        const createTreeNode = (node, fatherKey) => {
            if (!node) {
                return null;
            }

            const key = fatherKey ? `${fatherKey}-${node.tagId}` : node.tagId;
            const parentTagId = fatherKey?.split("-").pop();
            const addNode = {
                title: (
                    <PlusCircleOutlined
                        className="admin-search-tags-add-tag-button"
                        onClick={() => onAddNewTag(node.tagId)}
                    />
                ),
                key: `${key}-ADD`,
                disabled: true
            };

            const tagElement = (
                <>
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    label: "שכפל",
                                    key: "duplicate",
                                    onClick: () => onDuplicateTag(node, parentTagId)
                                },
                                {
                                    label: "מחק",
                                    key: "delete",
                                    danger: true,
                                    onClick: () => onDeleteTag(node.tagId)
                                }
                            ]
                        }}
                        trigger={["contextMenu"]}>
                        <span
                            onDoubleClick={() => {
                                setNewTag(node);
                                setNewTagKeyWords(node.keyWords);
                            }}>
                            {" "}
                            {node.name}
                        </span>
                    </Dropdown>{" "}
                    <span>
                        {node?.products?.length ? (
                            <>
                                ({node.products.length}){" "}
                                <Tooltip
                                    title={node.products.map(({productId}) => (
                                        <div>{productId}</div>
                                    ))}>
                                    <QuestionCircleOutlined style={{cursor: "pointer"}} />
                                </Tooltip>
                            </>
                        ) : null}
                    </span>
                </>
            );

            return {
                title: tagElement,
                key,
                children: [
                    ...(node?.children ?? []).map(childId => createTreeNode(tagsMap[childId], key)).filter(Boolean),
                    addNode
                ]
            };
        };

        const rootAddNode = {
            title: <PlusCircleOutlined className="admin-search-tags-add-tag-button" onClick={() => onAddNewTag()} />,
            key: "root-ADD",
            disabled: true
        };

        return [...rootNodes.map(rootNode => createTreeNode(rootNode)), rootAddNode];
    }, [searchTags]);

    const onDragEnd = useCallback(
        async info => {
            const draggedKey = info.dragNode.key;
            const [draggedNodeId, draggedNodeParentId] = draggedKey.split("-").reverse();

            const droppedLocationNodesIds = info.node.key.split("-").reverse();
            const newParentId = info.dropToGap ? droppedLocationNodesIds[1] : droppedLocationNodesIds[0];

            await HttpClient.safePost("/admin/api/searchTags/moveSearchTag", {
                tagId: draggedNodeId,
                from: draggedNodeParentId,
                to: newParentId,
                position: info.dropPosition
            });

            fetchSearchTags();
        },
        [fetchSearchTags]
    );

    const refreshMostSearchedTags = useCallback(async () => {
        const res = await HttpClient.safePost("/admin/api/searchTags/refreshMostSearchedTags");
        if (res.error) {
            message.error("שגיאה בעדכון תגיות הכי מחופשות");
        } else {
            message.success("תגיות הכי מוחפשות עודכנו בהצלחה");
        }
    }, []);

    const onUploadImage = (imageUrl, imageId) => {
        setImage({imageId, imageUrl});
    };

    return (
        <div className="admin-search-tags">
            <ConfigProvider direction="rtl">
                <FormModal
                    header={newTag?.tagId ? "עדכן טאג חיפוש קיים" : "יצירת טאג חיפוש"}
                    visible={!!newTag}
                    content={newTag}
                    onSave={createTag}
                    onClose={closeCreateModal}
                    fields={[
                        {
                            name: "name",
                            label: "שם התגית"
                        },
                        {
                            name: "category",
                            label: "קטגורייה",
                            type: "select",
                            multiple: true,
                            options: Object.values(MainServices)
                                .filter(mainService => mainService !== MainServices.PLATTERS)
                                .map(categoryId => ({
                                    name: categoryId,
                                    label: ServiceIdToName[categoryId]
                                }))
                        },
                        {
                            name: "keyWords",
                            label: "מילות חיפוש",
                            type: "custom",
                            optional: true,
                            customComponent: (
                                <div>
                                    <FormInputV2 ref={keyWordInputRef} onPressEnter={addKeyWord} />
                                    <div className="admin-search-tags-key-words-container">
                                        {newTagKeyWords?.map(word => (
                                            <Tag
                                                className="admin-search-tags-key-word-tag"
                                                closable={true}
                                                onClose={() => removeKeyWord(word)}
                                                key={word}>
                                                {word}
                                            </Tag>
                                        ))}
                                    </div>
                                </div>
                            )
                        },
                        {
                            name: "icon",
                            type: "custom",
                            label: "העלאת תמונה",
                            info: "גודל 24/24 פיקסל",
                            optional: true,
                            customComponent: (
                                <CoverPhotoHandler
                                    placeholder={"אייקון לתגית (svg)"}
                                    onPhotoChange={onUploadImage}
                                    onClose={() => {
                                        onUploadImage(null, null);
                                    }}
                                    currentImageUrl={newTag?.image?.imageUrl}
                                    currentImageId={newTag?.image?.imageId}
                                    formatAccepted=".svg"
                                />
                            )
                        },
                        {
                            name: "isActive",
                            type: "boolean",
                            label: "הצגת התגית בתוצאות חיפוש",
                            checkedChildren: "כן",
                            unCheckedChildren: "לא"
                        }
                    ]}
                />
                <div className="admin-search-tags-top-bar">
                    <span>תגיות חיפוש</span>
                    <SquareButton onClick={refreshMostSearchedTags}>תגיות חיפוש הכי פופולריות</SquareButton>
                    <SquareButton onClick={() => onAddNewTag()}>
                        <span>יצירת תגית</span>
                        <PlusCircleOutlined className="admin-search-tags-add-tag-button" />
                    </SquareButton>
                </div>
                <div className="admin-search-tags-tree-view-container">
                    {loadSearchTags ? (
                        <Spin />
                    ) : (
                        <Tree
                            onDrop={onDragEnd}
                            showLine={{showLeafIcon: false}}
                            draggable={true}
                            showIcon={false}
                            selectable={false}
                            treeData={treeData}
                            expandedKeys={defaultExpandKeys}
                            onExpand={setDefaultExpandedKeys}
                        />
                    )}
                </div>
                <AdminSearchTagsTable searchTags={searchTags} onSaveTag={createTag} />
            </ConfigProvider>
        </div>
    );
};
