import React, {useState} from "react";
import {isEmpty} from "lodash";
import {UploadOutlined} from "@ant-design/icons";
import {message, Spin, Upload} from "antd";
import {defaultPricingTable} from "./AdminUploadUtils";
import {TrashIcon} from "../../icons";
import {EventBus} from "../../bus/EventBus";
import {AreYouSureModal} from "../../components";
import {HttpClient} from "../../http/HttpClient";
import {GoogleMapMainAreaKey, GoogleMapSubAreaApiKeys} from "../../consts.js";
import {parseFloatNumber} from "../../utils/NumberUtils";

export const getSupportedServices = products =>
    products.map(product => ({
        doesProviderArriveToCustomer: product.doesProviderArriveToCustomer,
        address: product.address,
        allowedCompanies: product.allowedCompanies,
        breakMargin: null,
        portfolioImages: product.portfolioImages || [],
        description: product.description || null,
        durationMinutes:
            !isEmpty(product.durationMinutes) && product.durationMinutes !== 0 ? product.durationMinutes : null,
        languages: product.languages || [],
        location: product.location || [],
        price: null,
        isActive: product.isActive,
        brandName: product.brandName || "",
        engBrandName: product?.engBrandName || "",
        pricingTable: product.pricingTable || defaultPricingTable,
        stepPricing: product.stepPricing || null,
        chosenPriceType: product.chosenPriceType || null,
        productName: product.productName,
        productId: product.productId,
        subCategories: product.subCategories || [],
        serviceType: product.serviceType || [],
        serviceTags: product.serviceTags || [],
        maxParticipants: product.maxParticipants || null,
        minParticipants: product.minParticipants || null,
        foodRequests: product.foodRequests || [],
        activityRequests: product.activityRequests || [],
        deliveryPrice: product.deliveryPrice || null,
        homeDeliveryPrice: product.homeDeliveryPrice ?? null,
        activityExtras: product.activityExtras || [],
        presentation: product.presentation || [],
        commission: product.commission || null,
        deliveryCommission: product.deliveryCommission ?? null,
        shippingProviders: product.shippingProviders || [],
        activityManagement: product.activityManagement || [],
        area: product.area || [],
        participantsExtraInfo: product.participantsExtraInfo || null,
        extraInfo: product.extraInfo || null,
        services: product.services || [],
        createdAt: product.createdAt ?? Date.now(),
        upgradeOptions: product.upgradeOptions,
        searchTags: product.searchTags ?? [],
        deliveryOptions: product.deliveryOptions,
        disabledDates: product.disabledDates,
        allowTipping: product.allowTipping,
        popularQuestions: product.popularQuestions,
        productKosherType: product.productKosherType,
        minRecomendedProductParticipants: product.minRecomendedProductParticipants,
        maxRecomendedProductParticipants: product.maxRecomendedProductParticipants,
        packageInfo: product.packageInfo,
        isNew: !!product?.isNew,
        isPrimary: !!product?.isPrimary
    }));

export const getSupportedServicesForVoucherProvider = products => {
    return products.map(product => {
        return {
            allowedCompanies: product.allowedCompanies,
            portfolioImages: product.portfolioImages || [],
            description: product.description || null,
            price: null,
            isActive: product.isActive,
            brandName: product.brandName || "",
            engBrandName: product?.engBrandName || "",
            pricingTable: product.pricingTable || [],
            chosenPriceType: product.chosenPriceType || null,
            productName: product.productName,
            productId: product.productId,
            subCategories: product.subCategories || [],
            serviceTags: product.serviceTags || [],
            deliveryPrice: null,
            commission: product.commission || null,
            services: product.services || [],
            createdAt: product.createdAt ?? Date.now(),
            searchTags: product.searchTags ?? [],
            voucherId: product.voucherId,
            supplyBrandsLink: product.supplyBrandsLink,
            isNew: !!product?.isNew
        };
    });
};

const buildProviderUser = values => {
    return {
        email: values.email,
        extraEmail: values.extraEmail,
        firstName: values.businessName,
        phone: values.phone || "",
        imageId: values.imageId || "",
        imageUrl: values.imageUrl || "",
        cityId: values.cityId || "50005",
        cityName: values.cityName || "Tel Aviv - Yafo",
        countryCode: values.countryCode || "IL",
        countryId: values.countryId || "106",
        countryName: values.countryName || "Israel",
        gender: values.gender || "generic"
    };
};

const extractExtraDetails = values => {
    return {
        paymentPlan: "0NIS + 20% of Revenues",
        description: values.description || null,
        experience: values.experience || null,
        availability: values.availability || {},
        hasCertification: true,
        hasInsurance: true,
        imageUrl: values.imageUrl || "",
        imageId: values.imageId || "",
        providerType: values.providerType,
        kosher: values.kosher,
        businessName: values.businessName,
        dealerType: values.dealerType,
        businessNumber: values.businessNumber,
        contactPhoneNumber: values.contactPhoneNumber || null,
        website: values.website || null,
        facebook: values.facebook || null,
        linkedin: values.linkedin || null,
        instagram: values.instagram || null,
        contactFirstName: values.contactFirstName,
        contactLastName: values.contactLastName,
        contactEmail: values.contactEmail || null,
        address: values.address || null,
        rating: Number((values.rating / 10).toFixed(2)),
        v2: true,
        kosherCertificateImageUrl: values.kosherCertificateImageUrl,
        kosherCertificateImageId: values.kosherCertificateImageId,
        kosherCertificateFileName: values.kosherCertificateFileName,
        kosherCertificateExpireDate: values.kosherCertificateExpireDate,
        contractImageUrl: values.contractImageUrl,
        contractImageId: values.contractImageId,
        contractFileName: values.contractFileName,
        shouldPaymentBySupply: values.shouldPaymentBySupply,
        financeInfo: values.financeInfo,
        deliveryOptions: values.deliveryOptions,
        deliveryPrice: values.deliveryPrice,
        homeDeliveryPrice: values.homeDeliveryPrice,
        maxShippingProvidersDeliveryPrice: values.maxShippingProvidersDeliveryPrice,
        doesProviderArriveToCustomer: values.doesProviderArriveToCustomer,
        showProviderSubProductsImages: values.showProviderSubProductsImages,
        netPaymentTerms: Number(values.netPaymentTerms),
        assignPartner: values.assignPartner,
        brandNames: values.brandNames || []
    };
};

export const addAddressAreaId = async productAddresses => {
    if (!productAddresses) {
        return null;
    }

    const addressToSearch = productAddresses.reduce((acc, address) => {
        if (!address.subAreaId && (address.placeId || address.name)) {
            acc.push({
                placeId: address.placeId,
                address: address.name
            });
        }

        return acc;
    }, []);

    if (!addressToSearch.length) {
        return productAddresses;
    }

    const foundLocations = await HttpClient.safePost("/api/location/address", {addresses: addressToSearch});

    return productAddresses.map(address => {
        if (address.subAreaId) {
            return address;
        }

        const newLocationInfo = foundLocations.find(
            ({place_id, textualAddresses}) => place_id === address.placeId || textualAddresses.includes(address.name)
        );
        if (!newLocationInfo?.address_components) {
            return address;
        }

        const areaId = newLocationInfo.address_components.find(({types}) =>
            types.includes(GoogleMapMainAreaKey)
        )?.place_id;
        const subAreaId = newLocationInfo.address_components.find(({types}) =>
            types.some(type => GoogleMapSubAreaApiKeys.includes(type))
        )?.place_id;

        const {isAccessible, ...addressWithoutTypo} = address;

        return {
            ...addressWithoutTypo,
            isAccessible,
            placeId: newLocationInfo.place_id,
            areaId,
            subAreaId
        };
    });
};

export const getNewProviderDetails = async formValues => {
    const user = buildProviderUser(formValues);
    const extraDetails = extractExtraDetails(formValues);
    return {user, extraDetails};
};

export const SingleFileUploadHelper = ({onFileChange, fileName, uploadText, openFile}) => {
    const [removeCertificateModalVisibility, setRemoveCertificateModalVisibility] = useState(false);
    const [loading, setLoading] = useState(false);

    const props = {
        action: "/api/upload/",
        name: "file",
        accept: "application/pdf,image/png,image/jpeg",
        showUploadList: false,
        withCredentials: true,
        onChange: async info => {
            if (info.file.status === "done") {
                setLoading(false);
                const {imageId} = info.file.response;
                const fileName = info.file.name;
                const imageUrl = `https://res.cloudinary.com/well-b-prod/image/upload/${imageId}`;
                onFileChange(imageUrl, imageId, fileName);
                message.success(`קובץ הועלה בהצלחה`, 4);
            } else if (info.file.status === "error") {
                setLoading(false);
                EventBus.triggerError(
                    "server-error",
                    {
                        content: {
                            title: "Upload Failed!",
                            hideSubTitle: true,
                            description: "הייתה בעיה עם העלאת התמונה שבחרת."
                        }
                    },
                    info.file.response.error || `${info.file.name} file upload failed.`
                );
            } else {
                setLoading(true);
            }
        }
    };

    return (
        <div>
            <AreYouSureModal
                visible={!!removeCertificateModalVisibility}
                onClose={() => setRemoveCertificateModalVisibility(false)}
                title="בטוח שאתה רוצה למחוק את הקובץ?"
                saveStyle={{color: "white", backgroundColor: "#F06060"}}
                onYes={() => {
                    onFileChange(null, null, null);
                    setRemoveCertificateModalVisibility(false);
                }}
                onNo={() => setRemoveCertificateModalVisibility(false)}
            />
            <div className="admin-upload-upload-actions">
                <Upload {...props}>
                    <div className={"admin-upload-upload-container"}>
                        <UploadOutlined className={"admin-upload-upload-icon"} />
                        <p className={"admin-upload-upload-icon-text"}>{uploadText}</p>
                    </div>
                </Upload>
                {loading ? (
                    <Spin size="small" />
                ) : fileName ? (
                    <div className={"admin-upload-upload-file-wrapper"}>
                        <p
                            onClick={() => openFile()}
                            style={{cursor: "pointer"}}
                            className={"admin-upload-upload-file-text"}>
                            {fileName}
                        </p>
                        <TrashIcon
                            onClick={() => setRemoveCertificateModalVisibility(true)}
                            style={{
                                fill: "#767F90",
                                height: "18px",
                                width: "18px",
                                cursor: "pointer"
                            }}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};
