import React, {useMemo} from "react";
import {orderBy} from "lodash";
import {Skeleton, Spin} from "antd";
import classnames from "classnames";
import {CategorySearchSorterType, getFromPriceForProduct} from "../MarketplaceUtils.jsx";
import {NOOP} from "../../utils/NOOP.jsx";
import {getLogger} from "../../Logger.jsx";
import {PlaceholdersHeights} from "../../company/EventsGallary/consts.jsx";
import {RenderOnlyInViewWrapper} from "../../components/index.jsx";
import {MarketplaceSearchEmptyResults} from "./MarketplaceSearchEmptyResults.jsx";
import {FilterSearchOptions} from "../MarketplaceFilter/searchOptions/FilterSearchOptions.jsx";
import {useWindowDimensions} from "../../utils/hooks.jsx";
import {getMarketplaceFactoryItem} from "../MarketplaceItems/MarketplaceFactoryItem.jsx";
import {SearchItemType} from "../MarketplaceFilter/buttonsFilter/consts.js";

export const MarketplaceSearchResults = ({
    searchText = "",
    searchedContent,
    searchSorterType,
    loadingSearchedContent,
    onContentClick = NOOP,
    onNewTab = NOOP,
    showSearchAmount = true,
    showLikeButton = true,
    itemsInRow = 4,
    totalResultsAmount = null,
    onLastContentReached = NOOP,
    filterValues,
    setFilterValues,
    removeSearchInput = NOOP,
    showFilter = false,
    skeletonRows = 2,
    itemType = SearchItemType.products
}) => {
    const width = useWindowDimensions();
    const sortedSearchContent = useMemo(() => {
        switch (searchSorterType) {
            case CategorySearchSorterType.HighestFirst:
                return orderBy(searchedContent, content => parseInt(getFromPriceForProduct(content)), "desc");
            case CategorySearchSorterType.LowestFirst:
                return orderBy(searchedContent, content => parseInt(getFromPriceForProduct(content)));
            case CategorySearchSorterType.BestSeller:
                return orderBy(searchedContent, content => content.service.recentOrdersAmount || 0, "desc");
            default:
                return searchedContent;
        }
    }, [searchSorterType, searchedContent]);

    const log = useMemo(() => getLogger({}, "SearchResults"), []);

    const itemsPerRow = useMemo(() => {
        return width.width < 1630 ? 3 : 4;
    }, [width]);

    const cardProps = useMemo(() => {
        switch (itemType) {
            case SearchItemType.providers:
                return {log};
            default:
                return {searchText, log, onContentClick, onNewTab, showLikeButton};
        }
    }, [itemType, searchText, log, onNewTab, showLikeButton, onContentClick]);

    return (
        <div className="marketplace-category-providers">
            <div className="marketplace-category-filter-text-container">
                {loadingSearchedContent || !searchedContent ? (
                    <SkeletonItemsLoading itemPerRow={itemsPerRow * skeletonRows} />
                ) : (
                    <>
                        {showSearchAmount && (
                            <div className="marketplace-category-filter-text-inner-container">
                                <span className="marketplace-category-filter-result-number">
                                    {totalResultsAmount ?? searchedContent.length} תוצאות{" "}
                                </span>
                                {showFilter && (
                                    <FilterSearchOptions
                                        filterValues={filterValues}
                                        setFilterValues={setFilterValues}
                                        removeSearchInput={removeSearchInput}
                                    />
                                )}
                            </div>
                        )}

                        {sortedSearchContent?.length > 0 ? (
                            <div
                                className={classnames("marketplace-category-filtered-items", {
                                    "marketplace-category-filtered-responsive": itemsInRow !== 3
                                })}>
                                {sortedSearchContent.map((content, index) => {
                                    return (
                                        <RenderOnlyInViewWrapper
                                            key={index}
                                            onEnterView={
                                                index === sortedSearchContent.length - 1 &&
                                                sortedSearchContent.length < totalResultsAmount
                                                    ? () => {
                                                          onLastContentReached();
                                                      }
                                                    : NOOP
                                            }
                                            placeholder={
                                                <div className="events-gallery-image-placeholder">
                                                    <Skeleton.Input
                                                        style={{
                                                            height: PlaceholdersHeights[0]
                                                        }}
                                                        active={true}
                                                    />
                                                </div>
                                            }>
                                            <div
                                                key={`category-item-${index}`}
                                                className="marketplace-category-filtered-item">
                                                {getMarketplaceFactoryItem(itemType, content, cardProps)}
                                            </div>
                                        </RenderOnlyInViewWrapper>
                                    );
                                })}
                            </div>
                        ) : (
                            <MarketplaceSearchEmptyResults categories={filterValues?.categories} />
                        )}

                        {sortedSearchContent?.length < totalResultsAmount ? (
                            <Spin style={{marginTop: 80}} size="large" />
                        ) : null}
                    </>
                )}
            </div>
        </div>
    );
};

const SkeletonItemsLoading = ({itemPerRow}) => {
    return (
        <>
            <div className="search-result-count-skeleton-container">
                <Skeleton.Button className="search-result-skeleton-title" active={true} />
            </div>
            <div className="search-result-skeleton-items-container marketplace-category-filtered-items marketplace-category-filtered-responsive">
                {[...Array(itemPerRow)].map(index => {
                    return (
                        <div key={index}>
                            <Skeleton.Button
                                className="search-result-skeleton-item marketplace-product-card"
                                key={index}
                                active={true}
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
};
