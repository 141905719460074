import {Link} from "react-router-dom";
import React, {useContext} from "react";
import {EXCenterContext} from "../EXCenterContext";
import {AppContext} from "../../AppContext";
import {extractContactUsLink} from "../utils/ContactUsLink";

export const EXCenterNavBottomBar = () => {
    const {theme} = useContext(EXCenterContext);
    const {company} = useContext(AppContext);

    const helpLink = extractContactUsLink(company);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: 40,
                marginBottom: 40,
                marginLeft: 10,
                marginRight: 10
            }}>
            <div
                className="wb-footer-nav"
                style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", maxWidth: 800}}>
                <Link className="wb-footer-nav-item" style={{color: theme.textColor}} to="/contact">
                    Technical Support
                </Link>
                <a
                    className="wb-footer-nav-item"
                    style={{color: theme.textColor}}
                    href="https://www.well-b.biz/privacy-policy"
                    target="_blank">
                    Privacy Policy
                </a>
                <a
                    className="wb-footer-nav-item"
                    style={{color: theme.textColor}}
                    href="https://www.well-b.biz/terms-and-conditions"
                    target="_blank">
                    Terms of Service
                </a>
                {helpLink ? (
                    <a className="wb-footer-nav-item" style={{color: theme.textColor}} href={helpLink} target="_blank">
                        Contact Your Site Admin
                    </a>
                ) : null}
            </div>
        </div>
    );
};
