import top_cover from "./top_cover.png";
import generic_cover from "./generic_cover.png";
import picker_events_cover from "./picker_events_cover.png";
import picker_services_cover from "./picker_services_cover.png";
import picker_updates_cover from "./picker_updates_cover.png";
import picker_benefits_cover from "./picker_benefits_cover.png";
import picker_discounts_cover from "./picker_discounts_cover.png";
import picker_information_cover from "./picker_information_cover.png";
import picker_recommendations_cover from "./picker_recommendations_cover.png";

export const LuxuryImages = {
    top_cover,
    generic_cover,
    picker_events_cover,
    picker_services_cover,
    picker_updates_cover,
    picker_benefits_cover,
    picker_discounts_cover,
    picker_information_cover,
    picker_recommendations_cover
};
