import {errorTypesToMessage, InvoiceStatusTypes, StartOfProviderInvoiceReform} from "./consts";
import {getDeliveryPriceForInvoice, getInvoicePrice, reduceVAT} from "../../utils/calculationUtils";
import {isOrderHasShippingProvider} from "../../event/utils.jsx";
import moment from "moment";

export const getSummaryData = (pageDate, isExemptDealerType = false, maxShippingProvidersDeliveryPrice) =>
    pageDate.reduce((acc, order) => {
        const invoicePrice = getInvoicePrice(order.offeredPrice, order.commission, isExemptDealerType, order.vat);
        const isWellBDelivery = isOrderHasShippingProvider(order);

        const deliveryPrice = isExemptDealerType ? reduceVAT(order.deliveryPrice ?? 0, order.vat) : order.deliveryPrice;
        const deliveryPriceByWellB = getDeliveryPriceForInvoice(
            order,
            order.deliveryCommission,
            isExemptDealerType,
            maxShippingProvidersDeliveryPrice
        );
        const providerDiscount = isExemptDealerType
            ? reduceVAT(order.providerDiscount ?? 0, order.vat)
            : order.providerDiscount;
        const tip = isExemptDealerType ? reduceVAT(order.tip ?? 0, order.vat) : order.tip;
        const totalPrice =
            invoicePrice +
            ((isWellBDelivery ? deliveryPriceByWellB : deliveryPrice) ?? 0) -
            (providerDiscount ?? 0) +
            (tip ?? 0);

        return {
            ...acc,
            invoicePrice: (acc.invoicePrice ?? 0) + invoicePrice,
            ...(isWellBDelivery
                ? {deliveryPriceByWellB: (acc.deliveryPriceByWellB ?? 0) + (deliveryPriceByWellB ?? 0)}
                : {deliveryPrice: (acc.deliveryPrice ?? 0) + (deliveryPrice ?? 0)}),
            providerDiscount: (acc.providerDiscount ?? 0) + (providerDiscount ?? 0),
            tip: (acc.tip ?? 0) + (tip ?? 0),
            totalPrice: (acc.totalPrice ?? 0) + totalPrice
        };
    }, {});

export const findInvoiceWithOrder = (eventId, invoices) =>
    invoices?.find(({orders, status}) => status.id !== InvoiceStatusTypes.DECLINED && orders.includes(eventId));

export const isUserAllowedToUploadInvoiceInSelectedMonth = selectedMonth =>
    selectedMonth &&
    (selectedMonth?.isSameOrAfter(moment(), "month") || selectedMonth.isBefore(StartOfProviderInvoiceReform, "month"));

export const generateErrorMessage = (errorType, placeholders) => {
    const errorTemplate = errorTypesToMessage[errorType];

    if (!errorTemplate) return "Unknown error";

    let message = errorTemplate.message;

    Object.keys(placeholders).forEach(key => {
        message = message.replace(`{${key}}`, placeholders[key]);
    });

    return message;
};
