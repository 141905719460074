import {ProviderVideoModal} from "./ProviderVideoModal";
import {WatchIcon} from "../icons";
import cover_placeholder from "../images/cover_placeholder.jpg";
import {Button, Card, Progress} from "antd";
import React, {useState} from "react";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {PageTitleLabel} from "../components/PageTitle";
import {truncate} from "lodash";
import {inject, observer} from "mobx-react";
import {AutoDirectionProvider, isRTL} from "../AutoDirectionProvider";
import {SmartImageContainer} from "../components/SmartImageContainer";

const {Meta} = Card;

export const ProviderContentCard = inject("store")(
    observer(({store, history, provider, product, service}) => {
        const [watch, setWatch] = useState(false);

        const {firstName, video, rating, providerId} = provider;

        const {productName, price, coverPhoto, priceType} = product;

        const {serviceId} = service;

        const enterContent = () => {
            store.focusContent = product;
            history.push(`/dashboard/services/${serviceId}/providers/${providerId}`);
        };

        const watchClip = () => {
            if (video && video.url) {
                GoogleAnalytics.event("Professional Card", "Watch Clip", providerId);
                setWatch(true);
            }
        };

        const closeClip = () => {
            setWatch(false);
        };

        return (
            <Card
                className="wb-provider-content-card"
                bordered={false}
                onClick={() => enterContent()}
                hoverable={true}
                bodyStyle={{paddingTop: 10}}
                style={{
                    width: 340,
                    borderRadius: 20,
                    backgroundColor: "white",
                    boxShadow: "0 5px 15px 0 rgba(0,0,0,0.1)",
                    overflow: "hidden",
                    margin: 10
                }}
                cover={
                    <div
                        style={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column"
                        }}>
                        <ProviderVideoModal
                            status={video ? video.status : void 0}
                            uploadable={false}
                            visible={watch}
                            onClose={() => closeClip()}
                            videoUrl={video ? video.url : null}
                        />
                        <SmartImageContainer
                            width={340}
                            height={200}
                            src={coverPhoto ? coverPhoto.imageUrl : cover_placeholder}
                        />
                        <AutoDirectionProvider text={productName} style={{width: "100%"}}>
                            <PageTitleLabel
                                id="productName"
                                style={{
                                    fontSize: 20,
                                    minHeight: 60,
                                    maxHeight: 60,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    marginBottom: 0,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-start",
                                    width: "100%",
                                    textAlign: "center",
                                    overflow: "hidden"
                                }}>
                                {isRTL(productName)
                                    ? truncate(productName, {separator: " ", length: 50})
                                    : truncate(productName, {separator: " ", length: 90})}
                            </PageTitleLabel>
                        </AutoDirectionProvider>
                        <span style={{marginBottom: 10, marginTop: 10, fontSize: 16, color: "#1260D1"}}>
                            with {firstName}
                        </span>
                    </div>
                }
                actions={[
                    <div
                        style={{
                            height: 60,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "4px 15px",
                            color: "#273444"
                        }}
                        onClick={e => e.stopPropagation()}>
                        {price === 0 ? (
                            <label style={{fontWeight: 700, marginRight: 5, color: "#3da393"}}>FREE</label>
                        ) : (
                            <>
                                ₪
                                <label style={{fontWeight: 700, marginRight: 5, color: "#273444"}}>
                                    {price}
                                    {priceType ? ` / ${priceType}` : ""}
                                </label>
                            </>
                        )}
                    </div>,
                    <div onClick={e => e.stopPropagation()}>
                        <Button
                            onClick={() => watchClip()}
                            icon={
                                <WatchIcon style={{marginRight: 8}} fill={video && video.url ? "#1A344A" : "#B7BABD"} />
                            }
                            style={{
                                height: 60,
                                border: "none",
                                width: "100%",
                                cursor: video && video.url ? "pointer" : "not-allowed",
                                color: video && video.url ? "#273444" : "#B7BABD",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            Watch clip
                        </Button>
                    </div>
                ]}>
                <Meta
                    description={
                        <div style={{marginTop: 15}}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    marginTop: 10
                                }}>
                                <label style={{fontSize: 14, color: "#273444"}}>Professional rating</label>
                                <Progress
                                    className="wb-professional-rating wb-no-right"
                                    percent={rating * 10}
                                    strokeColor="#ABE9E2"
                                    format={percent => percent / 10}
                                />
                            </div>
                        </div>
                    }
                />
            </Card>
        );
    })
);
