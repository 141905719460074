import {EXCenterSectionContainer} from "../infos/EXCenterSectionContainer";
import React, {useContext} from "react";
import {EXCenterContext} from "../EXCenterContext";
import CompanySchedule from "../../company/CompanySchedule";
import {EXCenterMobileCalendar} from "./EXCenterMobileCalendar";
import {isScreenSizeOfTypes, ScreenSize} from "../EXCenterHelper";

export const EXCenterCalendarSection = () => {
    const sectionId = "calendar";
    const {designInfoPerSection, screenSize} = useContext(EXCenterContext);
    const sectionDesignInfo = designInfoPerSection[sectionId];

    const isSmallScreen = isScreenSizeOfTypes([ScreenSize.Mobile, ScreenSize.Tablet], screenSize);

    return (
        <EXCenterSectionContainer
            sectionId={sectionId}
            sectionDesignInfo={sectionDesignInfo}
            hideSeeMore={true}
            editItems={{title: true, buttonNoLink: false}}>
            {isSmallScreen ? (
                <EXCenterMobileCalendar />
            ) : (
                <CompanySchedule
                    hideCustomSession={true}
                    enableSpecialInteraction={false}
                    hideTitle={true}
                    style={{marginBottom: 20, width: "100%"}}
                    className="wb-center-calendar"
                />
            )}
        </EXCenterSectionContainer>
    );
};
