import moment from "moment-timezone";
import {SYSTEM_DEFAULT_START_TIME} from "../../../data/defaults";

export class DTStartCreator {
    static fromDateTime(startTime, startDate) {
        startTime = startTime || moment(SYSTEM_DEFAULT_START_TIME, "HH:mm");
        return moment(`${startDate.format("YYYY-MM-DD")} ${startTime.format("HH:mm")}`, "YYYY-MM-DD HH:mm")
            .toDate()
            .getTime();
    }
}
