import React from "react";
import "../../order-information-card.css";

export const OrderVoucherInfo = ({eventInfo}) => {
    return (
        <div>
            {eventInfo.singleEmployeeDetails ? (
                <div>
                    {eventInfo?.singleEmployeeDetails?.name ? (
                        <div>שם מקבל השובר: {eventInfo.singleEmployeeDetails?.name}</div>
                    ) : null}

                    {eventInfo?.sendOptions?.sms ? <div>טלפון של מקבל השובר: {eventInfo.sendOptions.sms}</div> : null}

                    {eventInfo?.sendOptions?.email ? (
                        <div>אימייל של מקבל השובר: {eventInfo.sendOptions.email}</div>
                    ) : null}

                    <a href={eventInfo?.voucherDetails?.[0]?.url} target="_blank">
                        לחץ כאן למעבר לשובר
                    </a>
                </div>
            ) : (
                eventInfo?.voucherDetails?.map((voucher, idx) => (
                    <div key={idx}>
                        <a href={voucher?.url} target="_blank">
                            לחץ כאן למעבר לשובר מספר {++idx}
                        </a>
                    </div>
                ))
            )}
        </div>
    );
};
