import {HttpClient} from "../http/HttpClient";

export class ZoomMeetingClient {
    static async generateOccurrenceLink() {
        return await HttpClient.get("/api/zoom/generate/occurrence");
    }

    static async generateRecurringMeetingLink() {
        return await HttpClient.get("/api/zoom/generate/recurring");
    }
}
