import {AppLogo} from "../AppLogo";
import React from "react";
import {EXCenterProfileDropdown} from "./profile/EXCenterProfileDropdown";
import {EXCenterNavTopBar} from "./navigation/EXCenterNavTopBar";
import {withRouter} from "react-router";
import {EXCenterMobileSideNav} from "./navigation/EXCenterMobileSideNav";
import {ArrowLeftIcon} from "../icons";

const BackButton = ({history}) => {
    const showBackButtonForPath = [
        /\/center\/sections\/(.*)/,
        /\/center\/content\/(.*)/,
        /\/center\/search(.*)/,
        /\/center\/profile(.*)/
    ];

    if (showBackButtonForPath.some(pathRegex => pathRegex.test(location.pathname))) {
        return (
            <div className="wb-ss-visible-only" onClick={() => history.goBack()}>
                <ArrowLeftIcon
                    style={{
                        marginLeft: 20,
                        marginTop: 0,
                        width: 25,
                        height: 25,
                        cursor: "pointer",
                        fill: "#767F90"
                    }}
                />
            </div>
        );
    }

    return <div className="wb-ss-visible-only" style={{width: 25}} />;
};

const CompanyLogo = ({history}) => (
    <>
        <AppLogo
            className="wb-bs-visible-only"
            style={{
                position: "relative",
                marginLeft: 100,
                left: 0
            }}
            onClick={() => history.push("/center/main")}
        />
        <AppLogo
            className="wb-ss-visible-only"
            style={{
                position: "relative",
                left: 0,
                alignItems: "center",
                justifyContent: "flex-start",
                maxWidth: 125
            }}
            onClick={() => history.push("/center/main")}
        />
    </>
);

const EXCenterHeader = ({history}) => {
    return (
        <header
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                borderBottom: "1px solid #DAE0E4",
                height: 80
            }}>
            <div className="wb-ss-space-between" style={{display: "flex", alignItems: "center", width: "100%"}}>
                <BackButton history={history} />
                <CompanyLogo history={history} />
                <EXCenterNavTopBar history={history} />
                <EXCenterMobileSideNav history={history} />
            </div>
            <EXCenterProfileDropdown style={{marginRight: 100}} />
        </header>
    );
};

export default withRouter(EXCenterHeader);
