import React, {useContext} from "react";
import {AppContext} from "../AppContext";
import {FormInput} from "../components/form";
import {CompanyBranchSelect, hasBranches} from "./CompanyBranchSelect";
import {StyledButton} from "../components/StyledButton";
import {DatePicker, Form, Modal} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import {PageTitleLabel} from "../components/PageTitle";
import {validateEmailExistence} from "../components/form/validators";
import {COMPANY_ALL_BRANCHES} from "../AppTopBar";

export const CompanyEmployeeAddForm = ({visible, onClose, onAdd, adding, form}) => {
    const {me, company, theme} = useContext(AppContext);

    const getCompanyBranches = () => {
        return company.branches.filter(branch => branch.branchId !== COMPANY_ALL_BRANCHES);
    };

    return (
        <Modal
            className="wb-modal-radius"
            closable={true}
            style={{top: 10}}
            onCancel={() => onClose()}
            destroyOnClose={true}
            bodyStyle={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 0
            }}
            open={visible}
            footer={null}
            closeIcon={<CloseOutlined style={{color: "#637381"}} />}>
            <PageTitleLabel style={{marginBottom: 30}}>Add employee</PageTitleLabel>
            <Form
                style={{maxWidth: 400, minWidth: 340}}
                form={form}
                onFinish={async values =>
                    await onAdd({...values, birthday: values.birthday ? values.birthday.format("DD/MM/YYYY") : null})
                }>
                <Form.Item name="firstName" rules={[{required: true, message: "Please enter first name."}]}>
                    <FormInput placeholder="First name" />
                </Form.Item>
                <Form.Item name="lastName" rules={[{required: true, message: "Please enter last name."}]}>
                    <FormInput placeholder="Last name" />
                </Form.Item>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            type: "email",
                            message: "Please enter email."
                        },
                        {
                            validateTrigger: "onSubmit",
                            validator: async (_, email) => await validateEmailExistence(email)
                        }
                    ]}>
                    <FormInput placeholder="Email" />
                </Form.Item>
                <Form.Item
                    name="phone"
                    rules={[
                        {
                            validator: (_, value) => {
                                if (value && value.length < 8) {
                                    return Promise.reject("Please enter valid number.");
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        }
                    ]}>
                    <FormInput type="number" placeholder="Phone (optional)" />
                </Form.Item>
                <Form.Item name="address">
                    <FormInput placeholder="Address (optional)" />
                </Form.Item>
                <Form.Item name="birthday">
                    <DatePicker
                        style={{width: "100%"}}
                        className="wb-date-picker"
                        format="DD/MM/YYYY"
                        showToday={false}
                        placeholder="Birthday (optional)"
                    />
                </Form.Item>
                <Form.Item name="position">
                    <FormInput placeholder="Position (optional)" />
                </Form.Item>
                {hasBranches(me, company) ? (
                    <Form.Item
                        name="branch"
                        rules={[
                            {
                                required: true,
                                message: "Please select site."
                            }
                        ]}>
                        <CompanyBranchSelect branches={getCompanyBranches()} />
                    </Form.Item>
                ) : null}
                <Form.Item>
                    <StyledButton
                        loading={adding}
                        htmlType="submit"
                        style={{width: "100%", backgroundColor: theme.primary, color: theme.textPrimary}}>
                        Add
                    </StyledButton>
                </Form.Item>
            </Form>
        </Modal>
    );
};
