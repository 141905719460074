import React from "react";
import {ConfigProvider, Tabs} from "antd";
import {AdminConsumptionOccasionTab} from "./AdminConsumptionOccasionTab";
import AdminCalculatorPreset from "./AdminCalculatorPreset";
import {AdminOccasionsCategories} from "../AdminOccasionsCategories";

export const AdminCalculator = () => (
    <div className="admin-consumption-occasions">
        <ConfigProvider direction="rtl">
            <Tabs
                defaultActiveKey="2"
                items={[
                    {
                        label: "ניהול אירועי צריכה",
                        key: "1",
                        children: <AdminConsumptionOccasionTab />
                    },
                    {
                        label: "ניהול קטגוריות מחשבון",
                        key: "2",
                        children: <AdminOccasionsCategories />
                    },
                    {
                        label: "ניהול פריסטים",
                        key: "3",
                        children: <AdminCalculatorPreset />
                    }
                ]}
            />
        </ConfigProvider>
    </div>
);
