import React from "react";
import "./separator-line.css";

export const SeparatorLine = ({text = null}) => {
    return (
        <div className="separator-line">
            {text ? (
                <>
                    <div className="separator-line-item" />
                    <div>{text}</div>
                    <div className="separator-line-item" />
                </>
            ) : (
                <div className="separator-line-item" />
            )}
        </div>
    );
};
