import {isEmpty, sumBy} from "lodash";
import {ActivityLocationTypes, VAT} from "../consts.js";
import {isOrderHasShippingProvider} from "../event/utils.jsx";
import {MaxShippingProvidersDeliveryPriceWithVAT} from "../admin/upload/consts.jsx";
import {HomeDeliveryUploadMethod} from "../marketplace/OrderLocationInput/consts.jsx";
import common from "@well-b/well-b-shared";

export const reduceVAT = (price, vat = VAT, withFixed = true) => common.reduceVAT(price, vat ?? VAT, withFixed);

export const getInvoicePrice = (offeredPrice, commission = "20", isExemptDealerType = true, vat = VAT) => {
    const calculateOfferedPrice = isExemptDealerType ? reduceVAT(offeredPrice, vat) : offeredPrice;
    const parseCalculateOfferedPrice = parseFloat(calculateOfferedPrice);
    const parseCommission = parseFloat(commission) / 100;

    return Math.ceil(
        parseCalculateOfferedPrice -
            parseFloat(offeredPrice) * (parseCommission * (isExemptDealerType ? 1 : vat ?? VAT))
    );
};

export const getDeliveryPriceForInvoice = (
    order,
    deliveryCommission = "8",
    isExemptDealerType = true,
    maxShippingProvidersDeliveryPrice
) => {
    const priceLimitWithVAT =
        order?.chosenActivityLocation === ActivityLocationTypes.homeDelivery &&
        order?.homeDeliveryUploadMethod === HomeDeliveryUploadMethod.excel
            ? Number.MAX_SAFE_INTEGER
            : maxShippingProvidersDeliveryPrice ?? MaxShippingProvidersDeliveryPriceWithVAT;

    if (isOrderHasShippingProvider(order)) {
        const parseCommission = parseFloat(deliveryCommission) / 100;

        const calculateDeliveryCommissionPrice = Math.ceil(
            parseFloat(order.offeredPrice) * (parseCommission * (isExemptDealerType ? 1 : order.vat ?? VAT))
        );

        if (calculateDeliveryCommissionPrice > priceLimitWithVAT) {
            return priceLimitWithVAT * -1;
        }

        return calculateDeliveryCommissionPrice * -1;
    }

    if (isExemptDealerType) {
        return reduceVAT(order.deliveryPrice, order.vat);
    }

    return order.deliveryPrice;
};

export const getOrderPrice = (order, includeVAT = true) => {
    const totalDiscount = getOrderDiscountPrice(order, includeVAT);

    const calculatePrice =
        parseFloat(order.offeredPrice) +
        (parseFloat(totalDiscount) || 0) +
        (parseFloat(order.deliveryPrice) || 0) +
        (order.tip || 0);
    return includeVAT ? calculatePrice : reduceVAT(calculatePrice, order.vat);
};

export const getOrderDiscountPrice = (order, includeVAT = true) => {
    const totalDiscount =
        (parseFloat(order.couponDiscountAmount) || 0) + (parseFloat(order.companyDiscountAmount) || 0);
    return includeVAT ? totalDiscount * -1 : reduceVAT(totalDiscount, order.vat) * -1;
};

export const getTotalBudget = (budget, services) =>
    !isEmpty(budget) && services ? (budget.amount ?? 0) + sumBy(services, serviceId => budget[serviceId] ?? 0) : null;

export const getNumberFormat = (number, minimumFractionDigits = 0, maximumFractionDigits = 0) =>
    new Intl.NumberFormat("he-il", {
        minimumFractionDigits,
        maximumFractionDigits
    }).format(number);

export const extractVatPercent = (vat = VAT) => Math.round(((vat ?? VAT) - 1) * 100);
