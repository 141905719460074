import React from "react";
import {NOOP} from "../utils/NOOP";

export const OrdersCalendarContext = React.createContext({
    month: null,
    setMonth: NOOP,
    currentMonthPlan: null,
    orders: [],
    setOrders: NOOP,
    plannedOccasions: [],
    setPlannedOccasions: NOOP,
    linkEvent: NOOP,
    changeEventStartTime: NOOP,
    previewMode: false,
    log: NOOP
});
