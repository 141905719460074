import React from "react";
import zoomLogo from "../images/zoom_meeting.png";
import appLogo from "../images/app_icon.png";
import syncLogo from "../images/sync.png";
import {StyledButton} from "../components/StyledButton";
import {parse as qsParse} from "query-string";

export const ZoomAuthCompleted = () => {
    const storeMeetingLink = () => {
        const {sessionId, meetingUrl} = qsParse(window.location.search);
        window.localStorage.setItem(sessionId, decodeURIComponent(meetingUrl));
        setTimeout(() => window.close(), 4000);
    };

    storeMeetingLink();

    return (
        <div
            style={{
                display: "flex",
                width: "100%",
                height: "100vh",
                marginTop: -20,
                justifyContent: "center",
                alignItems: "center"
            }}>
            <div
                style={{
                    width: 360,
                    height: 340,
                    borderRadius: 20,
                    border: "1px solid #747F92",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                <div
                    style={{
                        height: 100,
                        borderBottom: "1px solid #747F92",
                        display: "flex",
                        justifyContent: "center",
                        width: "100%"
                    }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            width: 250
                        }}>
                        <img
                            alt="app_logo"
                            style={{marginRight: 14, marginLeft: 10}}
                            src={appLogo}
                            width={60}
                            height={60}
                        />
                        <img alt="sync_image" src={syncLogo} width={30} height={30} />
                        <img alt="zoom_logo" src={zoomLogo} width={80} height={60} />
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column"
                    }}>
                    <div
                        style={{
                            color: "var(--secondary-color)",
                            fontSize: 20,
                            fontWeight: 700,
                            textAlign: "center"
                        }}>
                        Successfully synced to Zoom!
                    </div>
                    <div
                        style={{
                            color: "#8398A6",
                            fontSize: 14,
                            fontWeight: 400,
                            marginTop: 10,
                            textAlign: "center"
                        }}>
                        You can generate Zoom links for your events now.
                    </div>
                </div>
                <div style={{textAlign: "center"}}>
                    <StyledButton
                        style={{
                            borderRadius: 20,
                            height: 40,
                            backgroundColor: "#13C296",
                            color: "white",
                            boxShadow: "none",
                            width: 150
                        }}
                        onClick={() => window.close()}>
                        Continue
                    </StyledButton>
                    <div
                        style={{
                            color: "#8398A6",
                            fontSize: 14,
                            fontWeight: 400,
                            marginTop: 10,
                            textAlign: "center",
                            marginBottom: 30
                        }}>
                        Or wait 4 seconds...
                    </div>
                </div>
            </div>
        </div>
    );
};
