import React from "react";
import {FormModal} from "../../components";

export const MoveCarouselModal = ({
    visible,
    onSave,
    onClose,
    moveCarouselDetails,
    pageLayouts = [],
    currentPageLayoutId
}) => {
    return (
        <>
            <FormModal
                visible={visible}
                onSave={({newPageLayoutId}) =>
                    onSave({
                        newPageLayoutId,
                        carouselId: moveCarouselDetails.carouselId
                    })
                }
                onClose={onClose}
                header={"העבר קרוסלה לעמוד אחר"}
                fields={[
                    {
                        name: "carouselName",
                        label: "שם הקרוסלה הנבחרת",
                        type: "custom",
                        customComponent: <>{moveCarouselDetails?.carouselName}</>
                    },
                    {
                        name: "carouselId",
                        label: "מזהה הקרוסלה הנבחרת",
                        type: "custom",
                        customComponent: <>{moveCarouselDetails?.carouselId}</>
                    },
                    {
                        name: "newPageLayoutId",
                        label: "בחר את העמוד אליו תרצה להעביר את הקרוסלה",
                        type: "select",
                        options: pageLayouts
                            ?.filter(({pageLayoutId}) => pageLayoutId !== currentPageLayoutId)
                            .map(({pageLayoutId, name}) => ({label: name, name: pageLayoutId}))
                    }
                ]}
            />
        </>
    );
};
