import {Modal} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {CloseIcon, CreditCardIcon} from "../icons";
import {Images} from "../images/Images";
import {StyledButton} from "../components/StyledButton";
import {PageTitleLabel} from "../components/PageTitle";
import {EventBus} from "../bus/EventBus";
import {AppContext} from "../AppContext";
import {withRouter} from "react-router";
import {GoogleAnalytics} from "../GoogleAnalytics";

const CreditCardRequiredModal = ({history}) => {
    const {me} = useContext(AppContext);
    const [visible, setVisible] = useState(false);
    const [type, setType] = useState("company");

    useEffect(() => {
        EventBus.on("credit_card_required:open", ({label, type}) => {
            GoogleAnalytics.event("Billing", "Credit Card Required", label);
            setVisible(true);
            setType(type);
        });
    }, []);

    const closeMe = () => {
        setVisible(false);
    };

    const openCreditCardModal = () => {
        EventBus.trigger("credit_card_details:open");
        closeMe();
    };

    const openBillingPage = () => {
        if (me.type === "provider") {
            history.push("/provider/billing");
        } else {
            history.push("/company/billing");
        }
        GoogleAnalytics.event("Billing", "Open From Credit Card Modal", me.type);
        closeMe();
    };

    const openContactUsPage = () => {
        history.push("/contact?subject=general");
        closeMe();
    };

    return (
        <Modal
            className="wb-modal-radius"
            destroyOnClose={true}
            closable={true}
            closeIcon={<CloseIcon style={{fill: "white"}} />}
            title={null}
            footer={null}
            onCancel={closeMe}
            bodyStyle={{padding: 0}}
            open={visible}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <img src={Images.creditCardRequired} alt="credit-card-required" />
                <PageTitleLabel style={{marginBottom: 30}}>Credit card information required</PageTitleLabel>
                <div style={{fontSize: 16, color: "#8492A6"}}>
                    Please fill in credit card info to use your platform.
                </div>
                <div style={{fontSize: 16, color: "#8492A6"}}>Unleash Well-B's full potential right away!</div>
                <StyledButton
                    onClick={openCreditCardModal}
                    icon={<CreditCardIcon style={{fill: "white", width: 22, height: 17, marginRight: 12}} />}
                    style={{color: "white", backgroundColor: "#13C296", marginTop: 30, marginBottom: 10, width: 240}}>
                    Insert card information
                </StyledButton>
                {type === "company" ? (
                    <a
                        onClick={openContactUsPage}
                        style={{color: "#8492A6", textDecoration: "underline", fontSize: 14, marginBottom: 30}}>
                        Ask us anything
                    </a>
                ) : (
                    <a
                        onClick={openBillingPage}
                        style={{color: "#8492A6", textDecoration: "underline", fontSize: 14, marginBottom: 30}}>
                        Go to your billing page
                    </a>
                )}
            </div>
        </Modal>
    );
};

export default withRouter(CreditCardRequiredModal);
