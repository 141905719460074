import React from "react";
import moment from "moment";
import {Switch, Tooltip} from "antd";
import {DeleteFilled, EditFilled, EyeFilled, LinkOutlined, SnippetsOutlined, SyncOutlined} from "@ant-design/icons";
import {TableDateCell} from "../components";
import {ConfirmationRequiredWrapper} from "../../components";
import {isObjectWithEmptyFields} from "../../utils/objectUtils";

export const columns = (
    openPage,
    editPage,
    copyLink,
    deletePage,
    changeActiveStatus,
    changeIsolatedStatus,
    duplicatePage,
    runSelectionPageRules
) => {
    return [
        {
            title: "#",
            key: "#",
            width: 70,
            render(_, __, idx) {
                return `${idx + 1}`;
            }
        },
        {
            title: "מזהה",
            dataIndex: "selectPageId"
        },
        {
            title: "שם אתר הבחירה",
            dataIndex: "name"
        },
        {
            title: "תאריך יצירה",
            dataIndex: "createdAt",
            defaultSortOrder: "descend",
            sorter: ({createdAt: r1}, {createdAt: r2}) => moment(r1) - moment(r2),
            render(createdAt) {
                return <TableDateCell date={createdAt} />;
            }
        },
        {
            title: "תאריך עדכון אחרון",
            dataIndex: "updatedAt",
            sorter: ({updatedAt: r1}, {updatedAt: r2}) => moment(r1) - moment(r2),
            render(updatedAt) {
                return <TableDateCell date={updatedAt} />;
            }
        },
        {
            title: "נוצר על ידי",
            dataIndex: "createdBy"
        },
        {
            title: "מוצרים",
            dataIndex: "productIds",
            sorter: (r1, r2) => r1.productsIds.length - r2.productsIds.length,
            render(productIds) {
                return <div>{productIds.length}</div>;
            }
        },
        {
            title: "סטטוס",
            dataIndex: "isActive",
            sorter: (r1, r2) => (r1.isActive ? 1 : -1) - (r2.isActive ? 1 : -1),
            render(isActive, {selectPageId}) {
                return (
                    <Switch
                        className="admin-select-page-status-switch"
                        checked={!!isActive}
                        onChange={newStatus => changeActiveStatus(selectPageId, newStatus)}
                        checkedChildren="באוויר"
                        unCheckedChildren="לא פעיל"
                    />
                );
            }
        },
        {
            title: "אתר סגור/פתוח",
            dataIndex: "isIsolated",
            sorter: (r1, r2) => (r1.isIsolated ? 1 : -1) - (r2.isIsolated ? 1 : -1),
            render(isIsolated, {selectPageId}) {
                return (
                    <Switch
                        className="admin-select-page-status-switch"
                        checked={!isIsolated}
                        onChange={newStatus => changeIsolatedStatus(selectPageId, !newStatus)}
                        checkedChildren="פתוח"
                        unCheckedChildren="סגור"
                    />
                );
            }
        },
        {
            key: "actions",
            render(_, selectPage) {
                return (
                    <div className="admin-product-table-actions-container">
                        <EyeFilled
                            onClick={() => {
                                openPage(selectPage.selectPageId);
                            }}
                        />
                        <EditFilled onClick={() => editPage(selectPage)} />
                        <LinkOutlined
                            onClick={() => {
                                copyLink(selectPage.selectPageId + "/");
                            }}
                        />
                        <Tooltip title="שכפל אתר בחירה">
                            <SnippetsOutlined onClick={() => duplicatePage(selectPage.selectPageId)} />
                        </Tooltip>
                        <ConfirmationRequiredWrapper
                            onYes={() => {
                                deletePage(selectPage.selectPageId);
                            }}
                            subTitle="האם אתה בטוח שברצונך למחוק את העמוד?">
                            <DeleteFilled />
                        </ConfirmationRequiredWrapper>
                    </div>
                );
            }
        }
    ];
};
