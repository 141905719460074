import React, {useContext, useEffect, useState} from "react";
import {StepTitle} from "../../components/StepTitle";
import {Form, Select, Switch} from "antd";
import {StepContainer} from "../../components/StepContainer";
import {ContinuableForm} from "../../components/ContinuableForm";
import {Years, DealerType, Gender} from "../../wizardConsts";
import {StepSubTitle} from "../../components/StepSubTitle";
import {StepperDropdownInput} from "../../components/StepperDropdownInput";
import {StepperInput} from "../../components/StepperInput";
// import isURL from "validator/lib/isURL";
import {StepperParagraphInput} from "../../components/StepperParagraphInput";
import {isEmpty} from "lodash";
import {SearchableSelect} from "../../../components/SearchableSelect";
import {AppContext} from "../../../AppContext";
import {Availability} from "../../../components/Availability";
import {CoverPhotoHandler} from "../../components/CoverPhotoHandler";

const {Option} = Select;

const ProfessionalTypeEnum = {
    BRAND: "organization",
    INDIVIDUAL: "individual"
};

export const ProviderProfessionalStep = ({initialValues, basicInfo, onNext}) => {
    let {professions, me} = useContext(AppContext);
    const [professionalType, setProfessionalType] = useState(ProfessionalTypeEnum.BRAND);
    const [selectedProfessions, setSelectedProfessions] = useState([]);
    const [formValues, setFormValues] = useState({
        ...initialValues,
        professionalType: ProfessionalTypeEnum.BRAND
    });
    const [imageValues, setImageValues] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onProfessionsSelect = value => {
        const profession = professions.find(pro => pro.name === value);
        setSelectedProfessions(selectedProfessions.concat([profession]));
    };

    const onProfessionsDeselect = value => {
        setSelectedProfessions(selectedProfessions.filter(pro => pro.name !== value));
    };

    const onProfessionTypeChange = state => {
        const newProfessionalType = state ? ProfessionalTypeEnum.INDIVIDUAL : ProfessionalTypeEnum.BRAND;
        setProfessionalType(newProfessionalType);
        onFormValueChange("professionalType", newProfessionalType);
    };

    const onFormValueChange = (field, val) => {
        setFormValues({
            ...formValues,
            [field]: val
        });
    };

    const getProfessions = () => {
        if (isEmpty(selectedProfessions)) {
            return null;
        }
        return selectedProfessions.reduce(function (prevVal, currVal, idx) {
            return idx === 0 ? currVal.name : prevVal + ", " + currVal.name;
        }, "");
    };

    const getParagraphText = () => {
        const professions = getProfessions();
        return professionalType === ProfessionalTypeEnum.BRAND
            ? `Hey! We are ${formValues.brandName || "[BRAND NAME]"}.
We are very excited for considering us as your partner. Please be sure that we'll do our very best in order to spread happiness within your organization! :)`
            : `Hey! My name is ${(basicInfo && basicInfo.firstName) || "[FIRST NAME]"}. 
I am a ${professions || "[YOUR PROFESSION]"} with ${formValues.years || "[YEARS]"} of experience. 
Please be sure that i'll do my very best in order to spread happiness within your organization! :)`;
    };

    const hasAvailability = availability => {
        if (!availability) return false;
        return Object.keys(availability).reduce(
            (hasAvailability, daySetting) => {
                if (availability[daySetting].selected) {
                    return (
                        hasAvailability &&
                        (availability[daySetting].morning ||
                            availability[daySetting].noon ||
                            availability[daySetting].evening)
                    );
                } else return hasAvailability;
            },
            Object.keys(availability).some(daySetting => availability[daySetting].selected)
        );
    };

    const getImagePlaceholder = () => {
        return professionalType === ProfessionalTypeEnum.INDIVIDUAL
            ? "Profile Picture (PNG / JPEG)"
            : "Upload logo (PNG / JPEG)";
    };

    const onPhotoChange = (imageUrl, imageId) => {
        setImageValues({
            imageId: imageId,
            imageUrl: imageUrl
        });
    };

    return (
        <StepContainer style={{maxWidth: 900}}>
            <StepTitle>
                Nice to meet you {basicInfo ? basicInfo.firstName : "!"}.<br />
                Let's setup your {professionalType === ProfessionalTypeEnum.BRAND ? "business" : "professional"}{" "}
                profile.
            </StepTitle>
            <StepSubTitle>Please select if you want to setup your profile as a brand or as an individual</StepSubTitle>
            <ContinuableForm
                style={{width: "100%", maxWidth: 300, marginTop: 50, display: "flex", flexDirection: "column"}}
                canContinueColor="#ED7FA6"
                onFinish={vals => {
                    vals.about = getParagraphText();
                    vals.imageUrl = imageValues.imageUrl;
                    vals.imageId = imageValues.imageId;
                    onNext(vals);
                }}
                initialValues={initialValues}
                nonRequiredFieldNames={["brandName", "website", "socialMedia", "about"]}
                fields={Object.keys(formValues).map(key => {
                    return {
                        name: [key],
                        value: formValues[key]
                    };
                })}>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        marginLeft: "20px"
                    }}>
                    <Form.Item name="professionalType" valuePropName="professionalType">
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <div
                                className="wb-professional-profile-switch-options"
                                style={professionalType === ProfessionalTypeEnum.BRAND ? {color: "#4CB8C6"} : null}>
                                Brand
                            </div>
                            <Switch
                                className={
                                    professionalType === ProfessionalTypeEnum.BRAND
                                        ? "wb-professional-profile-switch-brand"
                                        : "wb-professional-profile-switch-individual"
                                }
                                onChange={onProfessionTypeChange}
                            />
                            <div
                                className="wb-professional-profile-switch-options"
                                style={
                                    professionalType === ProfessionalTypeEnum.INDIVIDUAL ? {color: "#F7B348"} : null
                                }>
                                Individual
                            </div>
                        </div>
                    </Form.Item>
                </div>
                <CoverPhotoHandler
                    placeholder={getImagePlaceholder()}
                    onPhotoChange={(url, id) => onPhotoChange(url, id)}
                />
                <div
                    style={{
                        display: "flex",
                        width: "400px",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginLeft: "-40px"
                    }}>
                    {professionalType === ProfessionalTypeEnum.BRAND ? (
                        <Form.Item
                            name="brandName"
                            shouldUpdate={(last, curr) => onFormValueChange("brandName", curr.brandName)}
                            rules={[
                                {
                                    required: false,
                                    message: "Please enter your Brand name."
                                }
                            ]}>
                            <StepperInput placeholder="Brand name (Optional)" />
                        </Form.Item>
                    ) : null}
                    {professionalType === ProfessionalTypeEnum.INDIVIDUAL ? (
                        <Form.Item
                            name="professions"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select profession."
                                },
                                {
                                    validator: (_, value) => {
                                        if (Array.isArray(value) && value.length > 3) {
                                            return Promise.reject("Maximum 3 professions allowed.");
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ]}>
                            <SearchableSelect
                                mode="multiple"
                                onDeselect={value => onProfessionsDeselect(value)}
                                onSelect={value => onProfessionsSelect(value)}
                                className="wb-profession-selection"
                                style={{width: "400px"}}
                                placeholder="Profession (up to 3)">
                                {professions.map(({name}) => (
                                    <Option key={name} value={name}>
                                        {name}
                                    </Option>
                                ))}
                            </SearchableSelect>
                        </Form.Item>
                    ) : null}
                    {professionalType === ProfessionalTypeEnum.INDIVIDUAL ? (
                        <Form.Item name="gender" rules={[{required: true, message: "Please select gender."}]}>
                            <Select onChange={val => onFormValueChange("gender", val)} placeholder="Gender">
                                {Gender.map(({value, title}) => (
                                    <Option key={value} value={value}>
                                        {title}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    ) : null}
                    <Form.Item
                        name="years"
                        valuePropName="years"
                        rules={[
                            {
                                required: true,
                                message: "Please select your years of experience."
                            }
                        ]}>
                        {/*<StepperDropdownInput*/}
                        {/*    value={formValues.years}*/}
                        {/*    placeholder={*/}
                        {/*        professionalType === ProfessionalTypeEnum.BRAND*/}
                        {/*            ? "Years of operation"*/}
                        {/*            : "Years of experience"*/}
                        {/*    }*/}
                        {/*    menuValues={Years}*/}
                        {/*    onChange={val => onFormValueChange("years", val)}*/}
                        {/*/>*/}
                    </Form.Item>
                    <Form.Item
                        name="about"
                        rules={[
                            {
                                required: false,
                                message: "Please Tell Us About Your Business."
                            }
                        ]}>
                        <StepperParagraphInput
                            initialValue={getParagraphText()}
                            placeholder="Tell Us About Your Business (Optional)"
                            maxLength={240}
                            autoSize={{maxRows: 8, minRows: 8}}
                        />
                    </Form.Item>
                    <Form.Item
                        name="dealerType"
                        valuePropName="dealerType"
                        rules={[
                            {
                                required: true,
                                message: "Please select your type of dealer."
                            }
                        ]}>
                        {/*<StepperDropdownInput*/}
                        {/*    value={formValues.dealerType}*/}
                        {/*    placeholder="Type of dealer"*/}
                        {/*    menuValues={DealerType}*/}
                        {/*    onChange={val => onFormValueChange("dealerType", val)}*/}
                        {/*/>*/}
                    </Form.Item>
                    {formValues.dealerType && formValues.dealerType === "LLC (חברה בע״מ)" ? (
                        <Form.Item
                            name="businessNumber"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter a Business Number."
                                }
                            ]}>
                            <StepperInput placeholder="Business Number (ח״פ)" />
                        </Form.Item>
                    ) : (
                        <Form.Item
                            name="licenseNo"
                            rules={[
                                {
                                    required: true,
                                    message: "Please add your license number."
                                }
                            ]}>
                            <StepperInput placeholder="License no. (מספר עוסק)" />
                        </Form.Item>
                    )}
                    <Form.Item
                        name="website"
                        rules={[
                            {
                                required: false,
                                message: "Please enter website URL."
                            },
                            {
                                validator: (_, value) => {
                                    if (
                                        !isEmpty(value) &&
                                        !isURL(value, {require_protocol: false, allow_underscores: true})
                                    ) {
                                        return Promise.reject("Please enter valid website URL.");
                                    }

                                    return Promise.resolve();
                                }
                            }
                        ]}>
                        <StepperInput placeholder="Company website (Optional)" />
                    </Form.Item>
                    <Form.Item
                        name="socialMedia"
                        rules={[
                            {
                                required: false,
                                message: "Please enter website URL."
                            },
                            {
                                validator: (_, value) => {
                                    if (
                                        !isEmpty(value) &&
                                        !isURL(value, {require_protocol: false, allow_underscores: true})
                                    ) {
                                        return Promise.reject("Please enter valid website URL.");
                                    }

                                    return Promise.resolve();
                                }
                            }
                        ]}>
                        <StepperInput placeholder="Instagram / Facebook / Linkedin (Optional)" />
                    </Form.Item>
                    <Form.Item
                        name="availability"
                        rules={[
                            {
                                required: true,
                                validator: async (_, value) => {
                                    if (hasAvailability(value)) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject(
                                            "Please select availability including the part of the day."
                                        );
                                    }
                                }
                            }
                        ]}>
                        <Availability />
                    </Form.Item>
                </div>
            </ContinuableForm>
        </StepContainer>
    );
};
