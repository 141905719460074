import React from "react";
import {Collapse, Tag} from "antd";

const {Panel} = Collapse;

export const ProductTagsPanel = ({key, productSearchTags, ...props}) => {
    return (
        <Panel
            {...props}
            key={key}
            showArrow={true}
            header={
                <>
                    <div className="panel-header-title">{"תגיות המוצר"}</div>
                </>
            }>
            <div style={{direction: "rtl"}}>
                <div className="product-tags-container">
                    {productSearchTags?.map(({name}) => (
                        <Tag key={name}>{name}</Tag>
                    ))}
                </div>
            </div>
        </Panel>
    );
};
