import {CloseIcon} from "../../icons";
import {Modal, message, Form} from "antd";
import React, {useState} from "react";
import {HttpClient} from "../../http/HttpClient";
import {FormInput} from "../../components/form";
import {StyledButton} from "../../components/StyledButton";
import {PageSubTitleLabel, PageTitleLabel} from "../../components/PageTitle";
import {round} from "lodash";
import moment from "moment-timezone";

const AdminBillingEditTransactionForm = ({initialValues, onFinish, loading}) => {
    return (
        <Form
            style={{maxWidth: 400}}
            initialValues={{
                total: round(initialValues.price, 2),
                revShare: round(initialValues.revShare, 2),
                notes: initialValues.notes
            }}
            onFinish={onFinish}>
            <Form.Item
                name="total"
                label="Transaction price"
                rules={[
                    {
                        required: true,
                        message: "Please enter price."
                    }
                ]}>
                <FormInput
                    className="wb-input-with-postfix-and-prefix"
                    type="number"
                    placeholder="Transaction price"
                    addonBefore=" ₪ "
                    addonAfter=" +VAT "
                />
            </Form.Item>
            <Form.Item
                name="revShare"
                label="Rev share"
                rules={[
                    {
                        required: true,
                        message: "Please enter rev share."
                    }
                ]}>
                <FormInput
                    className="wb-input-with-postfix-and-prefix"
                    type="number"
                    placeholder="Rev share"
                    addonBefore=" ₪ "
                    addonAfter=" +VAT "
                />
            </Form.Item>
            <Form.Item name="notes">
                <FormInput placeholder="Notes" />
            </Form.Item>
            <Form.Item>
                <StyledButton
                    loading={loading}
                    style={{backgroundColor: "#13C296", color: "white", width: "100%"}}
                    htmlType="submit">
                    Save
                </StyledButton>
            </Form.Item>
        </Form>
    );
};

export const AdminBillingEditTransactionModal = ({visible, onClose, providerName, transactionInfo}) => {
    const [loading, setLoading] = useState(false);

    const updateTransactionInfo = async info => {
        setLoading(true);
        try {
            const date = moment(transactionInfo.date, "DD/MM/YYYY").format("YYYY-MM-DD");
            await HttpClient.post(`/admin/api/billing/${date}/events/${transactionInfo.eventId}`, info);
            onClose({
                ...transactionInfo,
                price: info.total,
                revShare: info.revShare,
                notes: info.notes
            });
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setLoading(false);
    };

    return (
        <Modal
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            footer={null}
            title={null}
            closable={true}
            closeIcon={<CloseIcon fill="#767F90" />}>
            {visible && transactionInfo ? (
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <PageTitleLabel style={{marginBottom: 10, fontSize: 20}}>{providerName}</PageTitleLabel>
                    <PageSubTitleLabel style={{marginTop: 0, marginBottom: 30, fontSize: 16}}>
                        {transactionInfo.serviceName} ({transactionInfo.date})
                    </PageSubTitleLabel>
                    <AdminBillingEditTransactionForm
                        initialValues={transactionInfo}
                        onFinish={updateTransactionInfo}
                        loading={loading}
                    />
                </div>
            ) : null}
        </Modal>
    );
};
