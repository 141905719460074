import {Button, message, Upload} from "antd";
import {LoadingOutlined, PictureOutlined, ReloadOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {HttpClient} from "../http/HttpClient";
import cover_placeholder from "../images/placeholder-image.jpg";
import {CoverSizeTag} from "./CoverSizeTag";
import {SmartImageContainer} from "./SmartImageContainer";

const FILE_SIZE_MB = 1024 * 1024;
const MAX_FILE_SIZE = 10 * FILE_SIZE_MB;
const MAX_DIMENSIONS_SIZE = 25 * FILE_SIZE_MB;

const getFileDimensions = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.addEventListener("load", () => {
            const url = reader.result;
            const image = new Image();
            image.addEventListener("error", () => {
                message.error("Something went wrong, please try again later.", 5);
                reject();
            });
            image.addEventListener("load", () => {
                resolve({width: image.width, height: image.height});
            });
            image.src = url;
        });

        reader.readAsDataURL(file);
    });
};

const UploadButton = ({uploading}) => (
    <Button
        icon={
            uploading ? (
                <LoadingOutlined style={{color: "white"}} />
            ) : (
                <PictureOutlined style={{fontSize: 16, color: "white"}} />
            )
        }
        style={{
            width: 150,
            height: 40,
            borderRadius: 20,
            marginLeft: 10,
            marginRight: 10,
            color: "white",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
        Upload image
    </Button>
);

export const UploadPhotoButton = ({
    CustomUploadButton,
    width,
    height,
    quality,
    crop = "fit",
    gravity = "center",
    original,
    withColorsExtraction,
    onFileUploadFinish,
    onChange,
    eventId,
    wrap = "1"
}) => {
    const [uploading, setUploading] = useState(false);

    const uploadProps = {
        name: "file",
        action: `/api/image?width=${width || 650}${
            height > 0 ? `&height=${height}` : ""
        }&crop=${crop}&gravity=${gravity}&quality=${quality}&wrap=${wrap}${original ? `&original=1` : ""}${
            withColorsExtraction ? "&colors=1" : ""
        }`,
        method: "PUT",
        showUploadList: false,
        withCredentials: true,
        accept: "image/png,image/jpeg",
        beforeUpload: file => {
            return Promise.resolve().then(async () => {
                if (file.size > MAX_FILE_SIZE) {
                    message.error(
                        `Maximum cover size is ${MAX_FILE_SIZE / FILE_SIZE_MB}MB, your cover is ${
                            (file.size / FILE_SIZE_MB) | 0
                        }MB.`,
                        7
                    );
                    throw Error();
                }

                const {width, height} = await getFileDimensions(file);
                const dimensionsSize = width * height;
                if (dimensionsSize >= MAX_DIMENSIONS_SIZE) {
                    message.error(
                        `Maximum cover size is ${MAX_DIMENSIONS_SIZE / FILE_SIZE_MB} megapixels, your cover is ${
                            (dimensionsSize / FILE_SIZE_MB) | 0
                        } megapixels.`,
                        7
                    );
                    throw Error();
                }
            });
        },
        onChange: async info => {
            if (info.file.status === "uploading") {
                setUploading(true);
            } else if (info.file.status === "done") {
                GoogleAnalytics.event(eventId ? "Weekly Emails" : "Section Edit", "Upload Cover");

                const {imageUrl, palette} = info.file.response;
                onChange && onChange(imageUrl, palette);
                onFileUploadFinish && onFileUploadFinish(imageUrl, palette);
                setUploading(false);
            } else if (info.file.status === "error") {
                message.error(info.file.response.error, 5);
                setUploading(false);
            }
        }
    };

    return (
        <Upload {...uploadProps}>
            {CustomUploadButton ? <CustomUploadButton uploading={uploading} /> : <UploadButton uploading={uploading} />}
        </Upload>
    );
};

export const UploadableCoverPhoto = ({
    eventId,
    coverStyle,
    imageUrl,
    showReload,
    onChange,
    query,
    showPlaceholder,
    width,
    height,
    showUploadOnly,
    children,
    CustomUploadButton,
    uploadStyle,
    quality = 90,
    gravity = "center",
    crop = "fit",
    showCoverTag = false,
    original = false,
    withColorsExtraction,
    onFileUploadFinish,
    reloadButtonInfo
}) => {
    const [reloading, setReloading] = useState(false);

    const reloadImage = async () => {
        setReloading(true);
        try {
            const {imageUrl} = await HttpClient.get(
                eventId ? `/api/weekly/events/${eventId}/images/random` : `/api/weekly/images/random?q=${query}`
            );
            GoogleAnalytics.event(
                eventId ? "Weekly Emails" : "Section Edit",
                "Reload Image",
                query ? decodeURIComponent(query) : void 0
            );
            onChange(imageUrl);
        } catch (e) {
            message.error(
                "We couldn't upload your image. If the issue persist, please select a different image and try again.",
                7
            );
        }
        setReloading(false);
    };

    return (
        <SmartImageContainer
            className="wb-uploadable-cover"
            width={width}
            height={height}
            src={imageUrl || (showPlaceholder ? cover_placeholder : void 0)}
            style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "transparent",
                width: "100%",
                borderRadius: 10,
                paddingBottom: "47%",
                ...coverStyle
            }}>
            {showReload ? (
                <div
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: "calc(50% - 20px)",
                        left: "calc(50% - 115px)",
                        ...uploadStyle
                    }}>
                    {reloadButtonInfo ? (
                        <Button
                            onClick={reloadImage}
                            icon={reloading ? <LoadingOutlined style={{color: "white"}} /> : reloadButtonInfo.icon}
                            style={reloadButtonInfo.style}>
                            {reloadButtonInfo.text}
                        </Button>
                    ) : (
                        <Button
                            onClick={reloadImage}
                            icon={
                                reloading ? (
                                    <LoadingOutlined style={{color: "white"}} />
                                ) : (
                                    <ReloadOutlined style={{color: "white"}} />
                                )
                            }
                            style={{
                                width: 40,
                                height: 40,
                                borderRadius: 20,
                                marginLeft: 10,
                                backgroundColor: "rgba(0,0,0,0.5)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        />
                    )}
                    <UploadPhotoButton
                        width={width}
                        height={height}
                        quality={quality}
                        gravity={gravity}
                        crop={crop}
                        original={original}
                        withColorsExtraction={withColorsExtraction}
                        CustomUploadButton={CustomUploadButton}
                        onChange={onChange}
                        onFileUploadFinish={onFileUploadFinish}
                    />
                </div>
            ) : null}
            {showUploadOnly ? (
                <div
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: "calc(50% - 20px)",
                        left: "calc(50% - 85px)",
                        ...uploadStyle
                    }}>
                    <UploadPhotoButton
                        width={width}
                        height={height}
                        quality={quality}
                        gravity={gravity}
                        crop={crop}
                        original={original}
                        withColorsExtraction={withColorsExtraction}
                        CustomUploadButton={CustomUploadButton}
                        onChange={onChange}
                        onFileUploadFinish={onFileUploadFinish}
                    />
                </div>
            ) : null}
            {children}
            {showCoverTag && width && height ? <CoverSizeTag width={width} height={height} /> : null}
        </SmartImageContainer>
    );
};
