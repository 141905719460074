import styled from "styled-components";
import {BackButton} from "./BackButton";
import React from "react";
import {withRouter} from "react-router";
import classnames from "classnames";
import "./page-title-header.css";

export const PageTitleLabel = styled.label`
    font-size: 28px;
    font-weight: 700;
    color: #273444;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    flex: 1;
    text-align: center;
    margin-top: 20px;
    max-height: 80px;
`;

export const MarketplaceTitleLabel = styled.label`
    font-size: 32px;
    color: var(--secondary-color);
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 20px;
    max-height: 80px;
`;

export const PageSubTitleLabel = styled.label`
    font-size: 16px;
    color: #8492a6;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    text-align: center;
`;

const PageTitleHeader = ({
    children,
    className,
    style,
    subTitleStyle,
    history,
    backStyle,
    showBack = true,
    onBack,
    onClick,
    removeInlineCSS = false
}) => {
    return (
        <div
            className={classnames("page-title-header", className, {
                inlineCSS: !removeInlineCSS
            })}
            onClick={onClick}
            style={removeInlineCSS ? {} : style}>
            {showBack ? (
                <BackButton style={{marginTop: 20, ...backStyle}} onClick={onBack || (() => history.goBack())} />
            ) : null}
            <PageTitleLabel
                className={classnames("page-title-header", {"wb-title-with-back": showBack})}
                style={{marginLeft: 0, textAlign: "center", ...subTitleStyle}}>
                {children}
            </PageTitleLabel>
        </div>
    );
};

export default withRouter(PageTitleHeader);
