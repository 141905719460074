import {Modal} from "antd";
import {PageTitleLabel} from "../../../components/PageTitle";
import React from "react";
import {CompanyContentTagInfoForm} from "./CompanyContentTagInfoForm";

export const CompanyContentNewTagModal = ({tags, visible, onClose, onAdd}) => {
    return (
        <Modal
            centered={true}
            width={340}
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            footer={null}
            title={null}
            closable={true}>
            <div style={{display: "flex", width: "100%", flexDirection: "column", alignItems: "center"}}>
                <PageTitleLabel style={{marginTop: 20, marginBottom: 20}}>Category information</PageTitleLabel>
                <CompanyContentTagInfoForm tags={tags} onFinish={tagInfo => onAdd(tagInfo)} submitText="Add" />
            </div>
        </Modal>
    );
};
