import React from "react";
import {DeliveryAddressInput} from "../DeliveryAddressInput.jsx";
import {deliveryAddressOptions} from "../consts.js";
import {GoogleMapSubAreaApiKeys} from "../../../../../consts.js";

export const happyHoursSegmentedOptions = () => {
    return [
        {
            key: deliveryAddressOptions.deliveryToAddress,
            text: "משלוח",
            component: (
                <DeliveryAddressInput
                    path={`locationFilter.${deliveryAddressOptions.deliveryToAddress}`}
                    deliveryType={deliveryAddressOptions.deliveryToAddress}
                    placeholder="הזן רחוב/ מס׳ בניין/ עיר"
                    showDeliveryPrice={false}
                />
            )
        },
        {
            key: deliveryAddressOptions.onSite,
            text: "איסוף עצמי",
            component: (
                // <DeliveryAddressInput
                //     path={`locationFilter.${deliveryAddressOptions.onSite}`}
                //     deliveryType={deliveryAddressOptions.onSite}
                //     placeholder={"הזן עיר רלוונטית לאיסוף"}
                //     showDeliveryPrice={false}
                //     searchType={GoogleMapSubAreaApiKeys}
                //     isSelfPickupPossible={true}
                // />
                <span className="pickup-address-title">בקרוב תפתח האופציה לאיסוף עצמי 😃</span>
            )
        }
    ];
};
