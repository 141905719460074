import React from "react";
import {StringBuilder} from "../../../AppUtils.js";
import "../../order-information-card.css";

export const OrderContactsInfo = ({contactsInfo}) => {
    return (
        <div>
            {contactsInfo?.map(({phone, email, name}, index) => {
                const isMainPersonContact = index === 0;
                return (
                    <div key={index}>
                        <span>
                            {new StringBuilder()
                                .append(isMainPersonContact ? "ראשי" : "משני")
                                .append("-")
                                .append(name)
                                .toString()}
                        </span>
                        <span>{new StringBuilder().append(phone ? `, ${phone}` : null).toString()}</span>
                    </div>
                );
            })}
        </div>
    );
};
