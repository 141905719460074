import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../AppContext";
import {HttpClient} from "../../http/HttpClient";
import {Dropdown, message, Menu} from "antd";
import {Link} from "react-router-dom";
import {DownOutlined} from "@ant-design/icons";
import React from "react";
import {CompanyContent, findContentSectionById} from "../../company/content/CompanyContent";
import {uniq, takeRight, take, uniqBy} from "lodash";
import {isEventsSection} from "../utils/EventUtil";
import {UserBranch} from "../branches/UserBranch";
import {openWindow} from "../../http/WindowOpener";
import {EXCenterContext} from "../EXCenterContext";
import {RRuleSetBuilder} from "../../event/RRuleSetBuilder";
import moment from "moment-timezone";
import {withRouter} from "react-router";
import {ContactUsLink} from "../utils/ContactUsLink";
import {ScreenSize} from "../EXCenterHelper";

const {SubMenu} = Menu;

const getItemsToShowInMenu = screenSize => {
    if (screenSize === ScreenSize.DesktopHuge) {
        return 7;
    } else if (screenSize === ScreenSize.DesktopBig) {
        return 7;
    } else if (screenSize === ScreenSize.DesktopMedium) {
        return 7;
    } else {
        return 5;
    }
};

export const getSectionsToShow = (contents, showEvents) => {
    const sectionIdsToShow = contents ? uniq(contents.map(({sectionId}) => sectionId)) : [];
    return CompanyContent.Sections.filter(({sectionId}) => {
        return sectionIdsToShow.some(id => sectionId === id) || (sectionId === "events" && showEvents);
    });
};

const isHorizontalMenu = mode => mode === "horizontal";

const EXCenterMenuProvider = ({className, mode, withMore = false, history, withExtraItems = false}) => {
    const {me, company} = useContext(AppContext);
    const {allContents, events, theme, screenSize} = useContext(EXCenterContext);
    const [categoriesOfSections, setCategoriesOfSections] = useState(null);

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const categories = await HttpClient.get("/api/tags");
                setCategoriesOfSections(onlyCategoriesOfContents(categories));
            } catch (e) {
                message.error("Something went wrong, please try again later.", 5);
            }
        });
    }, []);

    const onlyCategoriesOfContents = allCategories => {
        const contentsPerSectionId = allContents.reduce((bySectionId, content) => {
            bySectionId[content.sectionId] = bySectionId[content.sectionId] || [];
            bySectionId[content.sectionId].push(content);
            return bySectionId;
        }, {});

        return allCategories.reduce((filteredCategories, category) => {
            const {sectionId} = category;
            const contentsOfSection = contentsPerSectionId[sectionId];
            if (!contentsOfSection) {
                return filteredCategories;
            }

            const tags = uniqBy(
                contentsOfSection.reduce((allTags, content) => {
                    return allTags.concat(content.tags);
                }, []),
                t => t.tagId
            );

            return filteredCategories.concat([
                {
                    ...category,
                    tags
                }
            ]);
        }, []);
    };

    const generateEventsSubMenu = () => {
        const onGoingEvents = events.reduce((onGo, evt) => {
            const rruleSet = RRuleSetBuilder.build(evt);
            const isOnGoing = rruleSet.after(moment().startOf("day").toDate(), true);
            if (isOnGoing) {
                onGo.push(evt);
            }

            return onGo;
        }, []);

        const categories = uniq(onGoingEvents.map(evt => evt.serviceName || "Internal"));
        if (!categories) {
            return null;
        } else {
            const MenuComponent = isHorizontalMenu(mode) ? Menu : SubMenu;
            return (
                <MenuComponent
                    onTitleClick={() => history.push("/center/sections/events")}
                    key="submenu-events"
                    title={
                        <span style={{color: theme.textColor, fontWeight: isHorizontalMenu(mode) ? 400 : 700}}>
                            Events
                        </span>
                    }>
                    {categories.map(name => (
                        <Menu.Item key={`category-menu-item-${name}`}>
                            <Link to={`/center/sections/events/${encodeURIComponent(name)}`}>
                                <span style={{color: theme.textColor, marginLeft: isHorizontalMenu(mode) ? 0 : 20}}>
                                    {name}
                                </span>
                            </Link>
                        </Menu.Item>
                    ))}
                </MenuComponent>
            );
        }
    };

    const generateSubMenu = sectionId => {
        if (!categoriesOfSections) {
            return null;
        }

        const categories = categoriesOfSections.find(c => c.sectionId === sectionId);
        if (!categories) {
            return null;
        } else {
            const MenuComponent = isHorizontalMenu(mode) ? Menu : SubMenu;
            return (
                <MenuComponent
                    onTitleClick={() => history.push(`/center/sections/${sectionId}`)}
                    key={`submenu-${sectionId}`}
                    title={
                        <span style={{color: theme.textColor, fontWeight: isHorizontalMenu(mode) ? 400 : 700}}>
                            {findContentSectionById(sectionId).displayName}
                        </span>
                    }>
                    {categories.tags.map(({name}) => (
                        <Menu.Item key={`category-menu-item-${name}`}>
                            <Link to={`/center/sections/${sectionId}/${encodeURIComponent(name)}`}>
                                <span style={{color: theme.textColor, marginLeft: isHorizontalMenu(mode) ? 0 : 25}}>
                                    {name}
                                </span>
                            </Link>
                        </Menu.Item>
                    ))}
                </MenuComponent>
            );
        }
    };

    const selectedSection = sectionIdInQuestion => {
        const pathParts = location.pathname.split("/");
        return pathParts.some(part => part === sectionIdInQuestion);
    };

    const itemsToShowInMenu = getItemsToShowInMenu(screenSize);
    const showEventsInMenu = Array.isArray(events) && events.length > 0;
    const sectionsToShow = getSectionsToShow(allContents, showEventsInMenu);
    const sections = withMore ? take(sectionsToShow, itemsToShowInMenu) : sectionsToShow;
    const moreSections =
        withMore && sectionsToShow.length > itemsToShowInMenu
            ? takeRight(sectionsToShow, sectionsToShow.length - itemsToShowInMenu)
            : [];

    const moreMenu = (
        <Menu>
            {moreSections.map(({sectionId, displayName}) => (
                <Menu.Item key={`more-menu-item-${sectionId}`}>
                    <Link to={`/center/sections/${sectionId}`}>
                        <span style={{color: theme.textColor}}>{displayName}</span>
                    </Link>
                </Menu.Item>
            ))}
        </Menu>
    );

    const defaultOpenKeys = sectionsToShow.map(({sectionId}) => `submenu-${sectionId}`);

    const userBranch = new UserBranch(me, company);

    return (
        <Menu defaultOpenKeys={defaultOpenKeys} className={`wb-center-menu ${className || ""}`} mode={mode}>
            {withExtraItems && userBranch.canSeeOtherBranches() ? (
                <Menu.Item key="category-menu-item-branches">
                    <Link to="/center/branches/direct">
                        <span style={{marginLeft: 14, color: theme.textColor, fontWeight: 700}}>Switch site</span>
                    </Link>
                </Menu.Item>
            ) : null}
            {sections.map(({sectionId, displayName}) => {
                const subMenu = isEventsSection(sectionId) ? generateEventsSubMenu() : generateSubMenu(sectionId);
                if (subMenu) {
                    return isHorizontalMenu(mode) ? (
                        <Menu.Item key={`center-menu-item-${sectionId}`}>
                            <Link to={`/center/sections/${sectionId}`}>
                                <span
                                    style={{
                                        color: selectedSection(sectionId) ? theme.secondaryColor : theme.textColor
                                    }}>
                                    {displayName}
                                </span>
                            </Link>
                        </Menu.Item>
                    ) : (
                        subMenu
                    );
                } else {
                    return (
                        <Menu.Item key={`center-menu-item-${sectionId}`}>
                            <Link to={`/center/sections/${sectionId}`}>
                                <span style={{color: theme.textColor}}>{displayName}</span>
                            </Link>
                        </Menu.Item>
                    );
                }
            })}
            {moreSections.length > 0 ? (
                <Menu.Item key={`center-menu-item-more`}>
                    <Dropdown style={{cursor: "pointer"}} overlay={moreMenu}>
                        <span>
                            More <DownOutlined style={{color: theme.textColor, fontSize: 8, fontWeight: 300}} />
                        </span>
                    </Dropdown>
                </Menu.Item>
            ) : null}
            {withExtraItems ? (
                <Menu.Item>
                    <ContactUsLink company={company}>
                        <span style={{marginLeft: 14, color: theme.textColor, fontWeight: 700}}>Contact us</span>
                    </ContactUsLink>
                </Menu.Item>
            ) : null}
            {withExtraItems ? (
                <Menu.Item>
                    <span
                        onClick={() => openWindow("/api/logout", "_self")}
                        style={{marginLeft: 14, color: theme.textColor, fontWeight: 700}}>
                        Logout
                    </span>
                </Menu.Item>
            ) : null}
        </Menu>
    );
};

export default withRouter(EXCenterMenuProvider);
