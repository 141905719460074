import {CloseIcon} from "../../icons";
import {EmployeeBooking} from "../../employee/EmployeeBooking";
import moment from "moment-timezone";
import {Modal} from "antd";
import React, {useContext} from "react";
import {EXCenterContext} from "../EXCenterContext";
import {AppContext} from "../../AppContext";

export const EXCenterBookingModal = ({visible, onClose, eventInfo, date}) => {
    const {me} = useContext(AppContext);
    const {theme} = useContext(EXCenterContext);

    return (
        <Modal
            className="wb-modal-booking-confirmation"
            closable={true}
            destroyOnClose={true}
            bodyStyle={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 0
            }}
            closeIcon={<CloseIcon fill={theme.textColor} />}
            onCancel={onClose}
            open={visible}
            footer={null}>
            {visible ? (
                <EmployeeBooking
                    me={me}
                    event={eventInfo}
                    currentStart={date}
                    currentEnd={
                        !eventInfo.appointmentBased ? moment(date).add(eventInfo.duration, "minutes").toDate() : null
                    }
                    onConfirm={onClose}
                    onBack={onClose}
                    onClose={onClose}
                />
            ) : null}
        </Modal>
    );
};
