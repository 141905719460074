import React, {useEffect, useMemo, useState} from "react";
import moment from "moment";
import {Card, Form} from "antd";
import {inject, observer} from "mobx-react";
import {FormConfirmButtonV2, FormInputV2} from "../../components/form";
import {LANGUAGE, Languages} from "../../auth/Login";
import {openWindow} from "../../http/WindowOpener";
import {TERMS_AND_CONDITIONS} from "../../data/links";
import {Images} from "../../images/Images";
import {safeValidateEmailExistence, validateGmail} from "../../components/form/validators";
import {getLogger} from "../../Logger";
import "./company-sign-up-short-stepper.css";

export const LOGIN_EMAIL = "loginEmail";

export const CompanySignUpShortStepper = inject("store")(
    observer(({history, store}) => {
        const [language, setLanguage] = useState(
            JSON.parse(localStorage.getItem(LANGUAGE)) ??
                (moment.tz.guess() !== "Asia/Jerusalem" ? Languages.English : Languages.Hebrew)
        );
        const [formValues, setFormValues] = useState({});

        const onFinish = () => {
            store.loginEmail = formValues.email?.toLowerCase();
            store.language = language;
            localStorage.setItem(LOGIN_EMAIL, JSON.stringify(formValues.email));
            localStorage.setItem(LANGUAGE, JSON.stringify(language));
            log("Click signup button", {email: formValues.email?.toLowerCase()});
            history.push("/company/wizard/details");
        };

        const log = useMemo(() => getLogger({}, "Signup"), []);

        useEffect(() => {
            const storedLanguage = JSON.parse(localStorage.getItem(LANGUAGE));

            if (storedLanguage) {
                setLanguage(storedLanguage);
            }
        }, [store.language]);

        const isMobileView = () => {
            return window.innerWidth < 550;
        };

        const openTermsOfUse = () => {
            log("Click terms of use link", {});
            openWindow(TERMS_AND_CONDITIONS, "_blank", true);
        };

        const onLogin = (existEmail = null) => {
            if (existEmail) {
                log("click login button - user exist in system", {email: existEmail});
            } else {
                log("click login button");
            }

            history.push("/login");
        };

        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    overflow: "auto",
                    height: isMobileView() ? "100%" : "100vh",
                    backgroundImage: isMobileView() ? void 0 : `url(${Images.loginBackground})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat"
                }}>
                <div
                    onClick={() => {
                        setLanguage(language === Languages.Hebrew ? Languages.English : Languages.Hebrew);
                        localStorage.setItem(
                            LANGUAGE,
                            JSON.stringify(language === Languages.Hebrew ? Languages.English : Languages.Hebrew)
                        );
                    }}
                    style={{
                        fontSize: isMobileView() ? "16px" : "20px",
                        marginTop: "30px",
                        cursor: "pointer",
                        color: isMobileView() ? "var(--secondary-color)" : "#FFFFFF",
                        display: "flex",
                        justifyContent: "center"
                    }}>
                    {language === Languages.Hebrew ? "Change language" : "שינוי שפה"}
                </div>
                <Card
                    bordered={!isMobileView()}
                    bodyStyle={{borderRadius: "5px", paddingBottom: "0"}}
                    style={{
                        boxSizing: "border-box",
                        height: "500px",
                        width: isMobileView() ? "400px" : "500px",
                        border: !isMobileView() ? "1px solid rgba(131,152,166,0.2)" : void 0,
                        borderRadius: "5px",
                        marginTop: isMobileView() ? "25px" : "110px"
                    }}
                    cover={
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    direction: language === Languages.Hebrew ? "rtl" : "ltr",
                                    justifyContent: "center"
                                }}>
                                <span
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "700",
                                        color: "#ED7FA6",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "53px"
                                    }}>
                                    {language === Languages.Hebrew ? "הצטרפו אלינו לפלטפורמה 👋🏼" : "Join"}
                                </span>
                                <img style={{margin: "55px 10px 0 10px"}} src={Images.loginLogo} alt={"login-logo"} />
                                <span
                                    style={{
                                        fontSize: "24px",
                                        color: "#ED7FA6",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "53px"
                                    }}>
                                    👋
                                </span>
                            </div>
                            <div
                                style={{
                                    marginTop: "15px",
                                    marginBottom: "30px",
                                    alignSelf: "center",
                                    backgroundColor: "rgba(131,152,166,0.2)",
                                    height: "2px",
                                    width: isMobileView() ? "350px" : "400px"
                                }}></div>
                            <span
                                style={{
                                    fontSize: "16px",
                                    textAlign: "center",
                                    alignSelf: "center",
                                    color: "#8398A6",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                {language === Languages.Hebrew
                                    ? "Happy Hours כאן תוכלו להזמין מגוון"
                                    : "Book a variety of happy hours and other welfare"}
                            </span>
                            <span
                                style={{
                                    fontSize: "16px",
                                    textAlign: "center",
                                    alignSelf: "center",
                                    color: "#8398A6",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                {language === Languages.Hebrew
                                    ? ";) ופעילויות רווחה עבור הארגון שלכם"
                                    : "activities for you organization ;)"}
                            </span>
                            <Form
                                onValuesChange={(_, allValues) => {
                                    setFormValues(allValues);
                                }}
                                onFinish={onFinish}
                                style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                                <label
                                    style={{
                                        marginRight: isMobileView() ? "30px" : "50px",
                                        marginLeft: isMobileView() ? "30px" : "50px",
                                        marginTop: "30px",
                                        marginBottom: "15px",
                                        fontSize: 16,
                                        color: "var(--secondary-color)",
                                        textAlign: language === Languages.Hebrew ? "right" : "left"
                                    }}>
                                    {language === Languages.Hebrew ? "כתובת מייל" : "Email"}
                                </label>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            type: "email",
                                            message: (
                                                <div className="company-sign-up-short-stepper-email-validator">
                                                    <span>אנא הכנס כתובת מייל תקנית</span>
                                                </div>
                                            )
                                        },
                                        {
                                            validator: async (_, email) => {
                                                if (email?.toLowerCase().endsWith("@gmail.com")) {
                                                    const valid = await validateGmail(email.toLowerCase());
                                                    return valid
                                                        ? Promise.resolve()
                                                        : Promise.reject(
                                                              <div className="company-sign-up-short-stepper-email-validator">
                                                                  <span>אנא הכנס מייל חברה</span>
                                                              </div>
                                                          );
                                                }

                                                const isEmailExist = await safeValidateEmailExistence(
                                                    email?.toLowerCase()
                                                );
                                                return isEmailExist
                                                    ? Promise.reject(
                                                          <div className="company-sign-up-short-stepper-email-validator">
                                                              <span>
                                                                  {language === Languages.Hebrew
                                                                      ? (() => (
                                                                            <>
                                                                                {"חשבון קיים במערכת "}
                                                                                <a
                                                                                    onClick={() =>
                                                                                        onLogin(email?.toLowerCase())
                                                                                    }>
                                                                                    לחץ כאן
                                                                                </a>
                                                                                {" על מנת להתחבר"}
                                                                            </>
                                                                        ))()
                                                                      : (() => (
                                                                            <>
                                                                                {"Account exists in the system "}
                                                                                <a
                                                                                    onClick={() =>
                                                                                        onLogin(email?.toLowerCase())
                                                                                    }>
                                                                                    click here
                                                                                </a>
                                                                                {" to login"}
                                                                            </>
                                                                        ))()}
                                                              </span>
                                                          </div>
                                                      )
                                                    : Promise.resolve();
                                            }
                                        }
                                    ]}
                                    style={{width: isMobileView() ? "350px" : "400px", alignSelf: "center"}}>
                                    <FormInputV2 />
                                </Form.Item>
                                <Form.Item
                                    style={{marginBottom: 10, width: "350px", alignSelf: "center", marginTop: "15px"}}>
                                    <FormConfirmButtonV2 htmlType="submit">
                                        {language === Languages.Hebrew ? "להמשך" : "Continue"}
                                    </FormConfirmButtonV2>
                                </Form.Item>
                            </Form>
                            <p
                                dir={language === Languages.Hebrew ? "rtl" : "ltr"}
                                className="company-sign-up-short-stepper-terms-paragraph">
                                <span
                                    style={{
                                        fontSize: "14px",
                                        textAlign: "center",
                                        alignSelf: "center",
                                        color: "#8398A6",
                                        justifyContent: "center"
                                    }}>
                                    {language === Languages.Hebrew
                                        ? "בלחיצה להמשך אתם מאשרים כי קראתם ואישרתם את"
                                        : "By clicking continue you confirm that you have read our"}
                                </span>
                                <a
                                    className="company-sign-up-short-stepper-terms-link"
                                    onClick={() => openTermsOfUse()}>
                                    {language === Languages.Hebrew ? "תנאי השימוש" : "terms and conditions"}
                                </a>
                                <span style={{flexDirection: "row", textAlign: "center", alignSelf: "center"}}>
                                    <span
                                        style={{
                                            fontSize: "14px",
                                            textAlign: "center",
                                            alignSelf: "center",
                                            color: "#8398A6",
                                            justifyContent: "center"
                                        }}>
                                        {language === Languages.Hebrew
                                            ? "של well-b שתאסוף מידע לצורך תפעול פעילויות רווחה, ושליחת פרסומות. להפסקת קבלת דיוור ניתן לבטל הרשמה מהמייל או לפנות ל-support@well-b.biz"
                                            : "that will collect information for the purpose of operating welfare activities, and send advertisements. To stop receiving mailings, you can unsubscribe or contact support@well-b.biz"}
                                    </span>
                                </span>
                            </p>
                        </div>
                    }></Card>
                <div style={{display: "flex", flexDirection: "column", marginTop: "20px", marginBottom: "20px"}}>
                    <div
                        style={{
                            width: "100%",
                            marginLeft: 10,
                            direction: language === Languages.Hebrew ? "rtl" : "ltr",
                            display: "flex"
                        }}>
                        <label
                            style={{
                                marginLeft: 5,
                                marginRight: "5px",
                                fontSize: 18,
                                color: isMobileView() ? "var(--secondary-color)" : "#FFFFFF"
                            }}>
                            {language === Languages.Hebrew ? "כבר יש לכם חשבון?" : "Already have an account?"}
                        </label>
                        <div
                            style={{
                                fontSize: 18,
                                color: isMobileView() ? "var(--secondary-color)" : "#FFFFFF",
                                cursor: "pointer"
                            }}
                            onClick={() => onLogin()}>
                            <u>{language === Languages.Hebrew ? "היכנסו לכאן" : "Login here"}</u>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);
