import {GoogleCalendarSyncButton} from "./GoogleCalendarSyncButton";
import {OutlookCalendarSyncButton} from "./OutlookCalendarSyncButton";
import React, {useContext, useState} from "react";
import {HttpClient} from "../../../http/HttpClient";
import {message} from "antd";
import {GoogleAnalytics} from "../../../GoogleAnalytics";
import {capitalize} from "lodash";
import {ErrorReporter} from "../../../error/ErrorReporter";
import moment from "moment-timezone";
import {AppContext} from "../../../AppContext";

export const AppointmentCalendarSyncActions = ({onClose, appointment, style}) => {
    const {company} = useContext(AppContext);
    const [syncingType, setSyncingType] = useState(null);

    const syncAppointment = async type => {
        setSyncingType(type);
        try {
            await HttpClient.post(`/api/appointments/${appointment.appointmentId}/sync/${type}`);
            message.success("Successfully synced appointment to your calendar.", 3);
            GoogleAnalytics.event("Calendar Sync", `${capitalize(type)} Appointment Sync`, appointment.appointmentId);
            onClose && onClose();
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setSyncingType(null);
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                ...style
            }}>
            {company.disabledFeatures?.includes("sync") ? (
                <GoogleCalendarSyncButton
                    loading={syncingType === "google"}
                    onSuccess={async () => await syncAppointment("google")}
                    onFailure={() => message.error("We couldn't sync your appointment, please try gain later.", 5)}
                    onClose={onClose}
                    useIcal={company.disabledFeatures?.includes("sync")}
                />
            ) : (
                <div>
                    <GoogleCalendarSyncButton
                        loading={syncingType === "google"}
                        onSuccess={async () => await syncAppointment("google")}
                        onFailure={() => message.error("We couldn't sync your appointment, please try gain later.", 5)}
                        onClose={onClose}
                    />
                    <OutlookCalendarSyncButton
                        eventId={appointment.eventId}
                        appointmentId={appointment.appointmentId}
                        EventInfoManager
                        time={appointment.start}
                        style={{marginTop: 10}}
                        onSync={async () => await syncAppointment("microsoft")}
                    />
                </div>
            )}
        </div>
    );
};

export const EventCalendarSyncActions = ({onClose, eventId, applyType, dateMom, style, event}) => {
    const {company} = useContext(AppContext);
    const [syncingType, setSyncingType] = useState(null);

    const syncEvent = async type => {
        setSyncingType(type);
        try {
            const date = dateMom.format("YYYY-MM-DD");
            const time = moment(event.dtstart).format("HH:mm");

            await HttpClient.post(`/api/events/${eventId}/sync/${type}?date=${date}&time=${time}`);
            message.success("Successfully synced event to your calendar.", 3);
            GoogleAnalytics.event("Calendar Sync", `${capitalize(type)} Event Sync`, eventId);
            onClose && onClose();
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setSyncingType(null);
    };

    return (
        <div
            className="wb-event-calendar-sync"
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                ...style
            }}>
            {company.disabledFeatures?.includes("sync") ? (
                <GoogleCalendarSyncButton
                    loading={syncingType === "google"}
                    onSuccess={async () => await syncEvent("google")}
                    onFailure={async error => {
                        message.error("We couldn't sync your event, please try again later.", 5);
                        ErrorReporter.reportError(error);
                    }}
                    onClose={onClose}
                    useIcal={company.disabledFeatures?.includes("sync")}
                />
            ) : (
                <div>
                    <GoogleCalendarSyncButton
                        loading={syncingType === "google"}
                        onSuccess={async () => await syncEvent("google")}
                        onFailure={async error => {
                            message.error("We couldn't sync your event, please try again later.", 5);
                            ErrorReporter.reportError(error);
                        }}
                        onClose={onClose}
                        useIcal={company.disabledFeatures?.includes("sync")}
                    />
                    <OutlookCalendarSyncButton
                        eventId={eventId}
                        date={applyType === "all" ? null : dateMom.format("YYYY-MM-DD")}
                        time={applyType === "all" ? null : dateMom.format("HH:mm")}
                        style={{marginTop: 10}}
                        onSync={async () => await syncEvent("microsoft")}
                    />
                </div>
            )}
        </div>
    );
};
