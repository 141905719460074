import React, {useCallback, useState} from "react";
import {DatePicker, message} from "antd";
import {GenericModal, SquareButton} from "../../../../components/index.jsx";
import {HttpClient} from "../../../../http/HttpClient.jsx";
import {Images} from "../../../../images/Images.jsx";
import "./report-management-sub-admin-usage.css";
import moment from "moment";
import common from "@well-b/well-b-shared";

const {RangePicker} = DatePicker;

export const ReportManagementSubAdminUsage = ({companyId}) => {
    const [rangeDatePicker, setRangeDatePicker] = useState([]);
    const [sendRequestForExcelModal, setSendRequestForExcelModal] = useState(false);

    const onClick = useCallback(async () => {
        const response = await HttpClient.safePost(`/api/report/send`, {
            reportName: common.report.ReportName.TOTAL_SUB_ADMINS_COMPANY_BUDGET_USAGE,
            params: {
                companyId,
                startDate: moment(rangeDatePicker[0]).valueOf(),
                endDate: moment(rangeDatePicker[1]).valueOf()
            }
        });

        if (response?.error) {
            message.error("שגיאה! בעת שליחת בקשה לייצוא דוח");
            message.error(response?.error);
            return;
        }

        setSendRequestForExcelModal(true);
    }, [companyId, rangeDatePicker]);

    const onChangeRangePicker = useCallback(([startDate, endDate]) => {
        setRangeDatePicker([startDate, endDate]);
    }, []);

    const onCloseSendRequestForExcelModal = useCallback(() => {
        setSendRequestForExcelModal(false);
    }, []);

    return (
        <div className="report-management-sub-admins-container">
            <GenericModal
                visible={sendRequestForExcelModal}
                image={Images.areYouSure}
                onClose={onCloseSendRequestForExcelModal}
                className="report-management-sub-admins-excel-modal"
                title="קיבלנו את בקשתך"
                text={["יישלח אלייך בדקות הקרובות מייל עם הדוח הרלוונטי", ""]}
                options={[
                    {
                        id: "save",
                        text: "תודה :)",
                        onClick: onCloseSendRequestForExcelModal
                    }
                ]}
            />
            <span className="report-management-sub-admins-container-sub-title">
                באקסל זה יוצג מימוש התקציבים של התתי אדמינים לפי הטווח תאריכים שיוגדר
            </span>

            <div className="report-management-sub-admins-dates-container">
                <span className="report-management-sub-admins-dates-container-sub-title">טווח תאריכים</span>
                <div className="report-management-sub-admins-dates">
                    <RangePicker
                        format="DD-MM-YYYY"
                        value={rangeDatePicker}
                        onChange={onChangeRangePicker}
                        allowClear={false}
                    />

                    <SquareButton onClick={onClick} className="excel-button">
                        ייצוא לאקסל
                    </SquareButton>
                </div>
            </div>
        </div>
    );
};
