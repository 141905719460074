import React, {useCallback, useContext, useMemo, useState} from "react";
import {Form, Tabs} from "antd";
import ProviderInfoForm from "./ProviderInfoForm";
import {ProviderContext} from "../ProviderContext";
import {ProviderTypeEnum} from "../../admin/upload/AdminUploadUtils";
import {defaultAvailability} from "../../components/AvailabilityHourly";
import "./readonly-provider-info-form.css";

const ReadonlyProviderInfoForm = () => {
    const {providerDetails} = useContext(ProviderContext);
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState({
        providerType: ProviderTypeEnum.BRAND,
        availability: defaultAvailability
    });
    const [products, setProducts] = useState([]);
    const [providerSubProducts, setProviderSubProducts] = useState([]);
    const [selectedTab, setSelectedTab] = useState("1");

    const tabOptions = useMemo(() =>
        [
            {
                label: "פרטי בית העסק",
                key: "1",
                children: <ProviderInfoForm
                    providerInfo={providerDetails}
                    form={form}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    providerSubProducts={providerSubProducts}
                    setProviderSubProducts={setProviderSubProducts}
                    products={products}
                    setProducts={setProducts}
                    formProps={{
                        disabled: true,
                        fields: Object.keys(formValues).map(key => {
                            return {
                                name: [key],
                                value: formValues[key]
                            };
                        })
                    }}/>
            }
        ], [
            providerDetails,
        form, formValues, setFormValues, providerSubProducts, setProviderSubProducts, products, setProducts]);

    const onTabChange = useCallback(key => {
        setSelectedTab(key);
    }, []);

    const currentTabOption = useMemo(() => tabOptions.find(({key}) => selectedTab === key), [tabOptions, selectedTab])

    return (
        <div className="readonly-provider-info-form">
            <Tabs
                className="provider-form-container"
                onChange={onTabChange}
                defaultActiveKey="1"
                items={tabOptions.map(({label, key}) => ({label, key}))}
            />
            {!!currentTabOption?.children && currentTabOption.children}
        </div>
    );
};

export default ReadonlyProviderInfoForm;
