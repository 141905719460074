import moment from "moment-timezone";
import {Button} from "antd";
import {ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";
import React from "react";

export const BalanceNavigation = ({currantDateMom, onDateChange}) => {
    const next = () => {
        onDateChange(moment(currantDateMom).add(1, "months"));
    };

    const prev = () => {
        onDateChange(moment(currantDateMom).subtract(1, "months"));
    };

    return (
        <span style={{display: "flex", alignItems: "center"}}>
            <Button
                onClick={prev}
                icon={<ArrowLeftOutlined style={{color: "#243446"}} />}
                style={{
                    height: 50,
                    width: 50,
                    fontSize: 16,
                    border: "1px solid #E9EDF4",
                    backgroundColor: "#FCFDFE",
                    color: "var(--secondary-color)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10
                }}
            />
            <Button
                onClick={next}
                icon={<ArrowRightOutlined style={{color: "#243446"}} />}
                style={{
                    height: 50,
                    width: 50,
                    fontSize: 16,
                    border: "1px solid #E9EDF4",
                    backgroundColor: "#FCFDFE",
                    color: "var(--secondary-color)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10
                }}
            />
        </span>
    );
};
