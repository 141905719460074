import React, {lazy, Suspense, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Card, ConfigProvider, Spin, Tooltip} from "antd";
import {isEmpty} from "lodash";
import moment from "moment/moment";
import {createProductLink} from "./MarketplaceHelper";
import {CompanyMarketplaceImagesCarousel} from "./OrderImagesCarousel/CompanyMarketplaceImagesCarousel";
import {BannersCarousel} from "./BannersCarousel";
import {MAIN_PAGE_ITEMS_PER_CAROUSAL, serviceToImageV2} from "./MarketplaceUtils";
import {GenericModal} from "../components";
import {CompanyBudgetLabel} from "../company/CompanyBudgetLabel";
import {Images} from "../images/Images";
import {getLogger, writeProductClickedLog} from "../Logger";
import {useRequest} from "../utils/hooks";
import {isAllowedAndExistingCategory, isAllowedForCategory} from "../center/EXCenterHelper";
import {AppContext} from "../AppContext";
import "./marketplace.css";
import "./marketplace-category.css";

const MarketplaceProductsCarousel = lazy(() => import("./MarketplaceProductsCarousel"));

export const CompanyMarketplace = ({history}) => {
    const {me, services} = useContext(AppContext);
    const [shouldShowOccasionModal, setShouldShowOccasionModal] = useState(false);

    const [banners] = useRequest("/api/site/landingPageBanners");
    const [mainPageSubCategories] = useRequest("/api/site/getMainPageCarousels");
    const [carousels] = useRequest(
        `/api/products/getProductsBySubCategories/?amount=${MAIN_PAGE_ITEMS_PER_CAROUSAL}`,
        "post",
        mainPageSubCategories?.subCategories,
        [mainPageSubCategories],
        !!mainPageSubCategories
    );
    const log = useMemo(() => getLogger({}, "MainPage"), []);

    const shouldBlockCategory = useCallback(
        service => {
            if (isEmpty(service.subCategories)) {
                return "בקרוב";
            }

            if (!isAllowedForCategory(me, service.serviceId, false)) {
                return "הרשאה לקטגוריה זו אינה פתוחה לך, באפשרותך לפנות אדמין הראשי בארגון שלך למידע נוסף";
            }

            return false;
        },
        [me]
    );

    useEffect(() => {
        const occasionMessageDate = "2022-10-09";
        const occasionMessageEndDate = "2022-10-17";

        if (moment() > moment(occasionMessageDate) && moment() < moment(occasionMessageEndDate)) {
            setShouldShowOccasionModal(moment(me?.lastLoginDate) < moment(occasionMessageDate));
        }
    }, [me?.lastLoginDate]);

    const closeOccasionModal = useCallback(() => {
        location.reload();
    }, []);

    const enterService = useCallback(
        service => {
            if (!shouldBlockCategory(service)) {
                log("Service Clicked", {serviceId: service.serviceId});
                history.push({
                    pathname: `/dashboard/services/${service.serviceId}/providersV2`
                });
            }
        },
        [history]
    );

    const getOnContentClickFunction = useCallback(
        serviceId =>
            (content, openInNewTab = false) => {
                writeProductClickedLog(log, content, serviceId);
                const url = createProductLink(content, {serviceId});

                if (openInNewTab) {
                    window.open(url, "_blank", "noreferrer");
                } else {
                    history.push(url);
                }
            },
        []
    );

    const getOnAllChoicesClickedFunction = useCallback(
        (serviceId, subCategory, shouldGoGeneralCategoryPage = false) =>
            buttonType => {
                log(`All choices ${buttonType} clicked`, {serviceId, subCategory});

                const url = `/dashboard/services/${serviceId}/providersV2`;

                history.push(shouldGoGeneralCategoryPage ? url : `${url}?subCategory=${subCategory}`);
            },
        []
    );

    // enable when switching to new page layout
    // const {width} = useWindowDimensions();
    //
    // const carouselLength = useMemo(() => (
    //     width > 1680 ? MAX_CAROUSEL_LENGTH : MAX_CAROUSEL_LENGTH - 1
    // ), [width]);
    //
    // const carouselMaxWidth = useMemo(() => {
    //     return carouselLength * MAX_PRODUCT_ITEM_WIDTH + (carouselLength - 1) * CAROUSEL_PRODUCT_GAP;
    // }, [carouselLength]);

    return (
        <div
            className="wb-categories-container"
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column"
            }}>
            <GenericModal
                className="pass-over-modal"
                visible={shouldShowOccasionModal}
                title="חג שמח חברים שלנו 🌸🥂"
                text={[
                    "מעדכנים כי במהלך חול המועד המענה האנושי יוגבל למקרים דחופים וזמינות הספקים תהיה חלקית." +
                        "תוכלו כמובן להמשיך ולהזמין מוצרים דרך הפלטפורמה אך פניות לאספקה במהלך חול המועד אינה מובטחת :)"
                ]}
                onClose={closeOccasionModal}
                options={[
                    {
                        id: "close",
                        text: "תודה על העדכון :)",
                        onClick: closeOccasionModal
                    }
                ]}
                image={Images.WellBSukot}
            />

            <div className="marketplace-main-page-header-row">
                <label>הביאו אושר לעובדים שלכם 🤗</label>
                <CompanyBudgetLabel />
            </div>
            <BannersCarousel history={history} banners={banners} log={log} />
            <ConfigProvider direction="rtl">
                {!services?.length ? (
                    <Spin style={{marginTop: 80}} size="large" />
                ) : (
                    <div className="marketplace-main-page-categories-row">
                        {services.map(service => {
                            const {lightImage, hoveredImage} =
                                Images.services.icons[serviceToImageV2(service.serviceId)] ?? {};
                            const error = shouldBlockCategory(service);

                            return (
                                <Tooltip key={service.serviceId} title={error}>
                                    <Card
                                        className="marketplace-main-page-category-card"
                                        style={{
                                            "--image": `url(${lightImage})`,
                                            "--hover-image": `url(${hoveredImage})`,
                                            cursor: error ? "not-allowed" : "pointer"
                                        }}
                                        onClick={() => enterService(service)}
                                    />
                                </Tooltip>
                            );
                        })}
                    </div>
                )}
            </ConfigProvider>
            {me.features.pinterest && (
                <div className="marketplace-main-page-carousels-container" style={{width: "100%"}}>
                    <CompanyMarketplaceImagesCarousel
                        history={history}
                        log={log}
                        title="בואו לקבל השראה מאירועים שלקחנו בהם חלק 🤩"
                    />
                </div>
            )}
            <div
                className="marketplace-main-page-carousels-container marketplace-category-providers"
                // style={{
                //     maxWidth: carouselMaxWidth
                // }}
            >
                {carousels
                    ? carousels.map(({serviceId, subCategory, hideInMarketplace, products}) =>
                          isAllowedAndExistingCategory(me, serviceId, services) ? (
                              <Suspense key={`${serviceId}-${subCategory}`} fallback={null}>
                                  <MarketplaceProductsCarousel
                                      title={subCategory}
                                      products={products}
                                      // carouselLength={carouselLength}
                                      onContentClick={getOnContentClickFunction(serviceId)}
                                      onNewTab={getOnContentClickFunction(serviceId, true)}
                                      onAllChoicesClicked={getOnAllChoicesClickedFunction(
                                          serviceId,
                                          subCategory,
                                          hideInMarketplace
                                      )}
                                  />
                              </Suspense>
                          ) : null
                      )
                    : null}
            </div>
        </div>
    );
};
