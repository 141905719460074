import {ModalTitle} from "../components/ModalTitle";
import {Typography} from "antd";
import CallToActionModal from "../components/CallToActionModal";
import React, {useEffect, useState} from "react";
import {EventBus} from "../bus/EventBus";

export const ErrorModal = ({history}) => {
    const [visible, setVisible] = useState(false);
    const [callToActionText, setCallToActionText] = useState(null);
    const [imageName, setImageName] = useState(null);
    const [callToActionPath, setCallToActionPath] = useState(null);
    const [description, setDescription] = useState(null);
    const [title, setTitle] = useState(null);
    const [subTitle, setSubTitle] = useState(null);
    const [hideCTA, setHideCTA] = useState(false);
    const [hideNextSteps, setHideNextSteps] = useState(false);
    const [hideCloseButton, setHideCloseButton] = useState(false);
    const [hideTheSubTitle, setHideSubTitle] = useState(false);

    useEffect(() => {
        const onError = data => {
            const {image, cta = {}, content = {}, hideClose} = data;

            setImageName(image || null);

            const {text, path, hide} = cta;
            setCallToActionText(text);
            setCallToActionPath(path);
            setHideCTA(!!hide);

            const {title, subTitle, description, hideSteps, hideSubTitle} = content;
            setTitle(title);
            setSubTitle(subTitle);
            setDescription(description);
            setHideNextSteps(!!hideSteps);
            setHideSubTitle(hideSubTitle);

            setHideCloseButton(!!hideClose);
            setVisible(true);
        };

        EventBus.on("server-error", onError);

        return () => {
            EventBus.off("server-error", onError);
        };
    }, []);

    const goTo = path => {
        setVisible(false);
        history.push(path);
    };

    const closeModal = () => {
        setTimeout(() => {
            setTitle(null);
            setSubTitle(null);
            setDescription(null);
            setHideNextSteps(false);
            setCallToActionText(null);
            setCallToActionPath(null);
            setHideCTA(false);
        }, 100);
        setVisible(false);
    };

    const Title = () => (
        <ModalTitle
            title={title ? title : "Oopssy...!"}
            subTitle={hideTheSubTitle ? null : subTitle ? subTitle : "Something went wrong"}
        />
    );

    const SubTitle = () => (
        <div style={{display: "flex", flexDirection: "column"}}>
            <Typography.Text style={{textAlign: "center", fontSize: 20, marginBottom: 10}}>
                What Happened?
            </Typography.Text>
            <Typography.Paragraph>
                <ul>
                    {typeof description !== "undefined"
                        ? (Array.isArray(description) ? description : [description])
                              .filter(Boolean)
                              .map((desc, idx) => <li key={`desc-${idx}`}>{desc}</li>)
                        : null}
                    {hideNextSteps ? null : (
                        <>
                            <li>Please help us improve, take a moment and report this issue back to us.</li>
                            <li>You can try again or ignore by closing this error.</li>
                        </>
                    )}
                </ul>
            </Typography.Paragraph>
        </div>
    );

    return (
        <CallToActionModal
            imageName={imageName || "oops"}
            closable={!hideCloseButton}
            onClose={() => setVisible(false)}
            title={<Title />}
            subTitle={<SubTitle />}
            subTitleStyle={{textAlign: "left"}}
            actionText={hideCTA ? void 0 : callToActionText ? callToActionText : "Contact us"}
            actionHandler={
                hideCTA
                    ? void 0
                    : () => goTo(callToActionPath ? callToActionPath : "/contact?subject=technical-problem")
            }
            anotherActionText={hideCTA ? void 0 : "Close"}
            anotherActionHandler={hideCTA ? void 0 : () => closeModal()}
            visible={visible}
        />
    );
};
