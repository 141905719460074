import React from "react";
import {Avatar, Button, message, Tooltip} from "antd";
import {FrownOutlined} from "@ant-design/icons";
import {RoundedTable} from "../components/RoundedTable";
import moment from "moment-timezone";
import {SearchInput} from "../components/Search";
import {HttpClient} from "../http/HttpClient";
import PageTitleHeader from "../components/PageTitle";
import {capitalize as _capitalize} from "lodash";
import {PaymentPlan} from "../provider/ProviderPaymentSelection";
import {toProfessionName} from "../data/professions";
import {openWindow} from "../http/WindowOpener";
import {ContactPhone} from "../support/ContactPhone";
import {uniq} from "lodash";

export const columns = (onDelete, deletingProviderId, services) => [
    {
        title: "#",
        key: "#",
        width: 70,
        render(_, __, idx) {
            return `${idx + 1}`;
        }
    },
    {
        title: "ID",
        dataIndex: "providerId",
        key: "providerId",
        width: 150,
        filters: [
            {
                text: "Missing services",
                value: "missing"
            },
            {
                text: "Pending approval",
                value: "pending"
            },
            {
                text: "Ready & Approved",
                value: "ready"
            }
        ],
        filterMultiple: false,
        onFilter: (value, {services, approved}) => {
            switch (value) {
                case "missing":
                    return !Array.isArray(services) || services.length === 0;
                case "pending":
                    return !approved;
                case "ready":
                    return Array.isArray(services) && services.length > 0 && approved;
            }
        },
        render(providerId, {services, approved}) {
            const hasServices = Array.isArray(services) && services.length > 0;

            return (
                <div style={{display: "flex", alignItems: "center"}}>
                    <Tooltip
                        title={
                            hasServices && approved
                                ? "Ready & Approved"
                                : !hasServices
                                ? "Missing services"
                                : "Pending approval"
                        }>
                        <div
                            style={{
                                height: 10,
                                width: 10,
                                borderRadius: 5,
                                marginRight: 8,
                                backgroundColor: hasServices && approved ? "#0eca0e" : !hasServices ? "red" : "#ccc"
                            }}
                        />
                    </Tooltip>
                    {providerId}
                </div>
            );
        }
    },
    {
        title: "NAME",
        key: "name",
        width: 250,
        sorter: (r1, r2) => {
            const name1 = `${r1.firstName}${r1.lastName ? ` ${r1.lastName}` : ""}`;
            const name2 = `${r2.firstName}${r2.lastName ? ` ${r2.lastName}` : ""}`;
            return name1.toLowerCase() < name2.toLowerCase() ? 1 : -1;
        },
        render(_, {firstName, lastName, email, imageUrl}) {
            return (
                <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                    {imageUrl ? (
                        <Avatar
                            style={{minWidth: 32, minHeight: 32, maxWidth: 32, maxHeight: 32, marginRight: 8}}
                            src={imageUrl}
                        />
                    ) : (
                        <FrownOutlined style={{fontSize: 32, marginRight: 8}} />
                    )}
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <label style={{color: "var(--secondary-color)", fontSize: 14, fontWeight: 500}}>{`${firstName}${
                            lastName ? ` ${lastName}` : ""
                        }`}</label>
                        <label style={{color: "#747F92", fontSize: 12, fontWeight: 400}}>{email}</label>
                    </div>
                </div>
            );
        }
    },
    {
        title: "BRAND NAME",
        dataIndex: "brandName",
        key: "brandName",
        render(brandName) {
            return brandName || "";
        }
    },
    {
        title: "PHONE",
        dataIndex: "phone",
        key: "phone",
        render(phone) {
            return (
                <a target="_blank" href={ContactPhone.whatsAppLink(phone)}>
                    {ContactPhone.prettyPhoneNumber(phone)}
                </a>
            );
        }
    },
    {
        title: "TYPE",
        dataIndex: "providerType",
        key: "providerType",
        sorter: (r1, r2) => {
            const proType1 = r1.providerType ? r1.providerType : "individual";
            const proType2 = r2.providerType ? r2.providerType : "individual";
            return proType1.toLowerCase() < proType2.toLowerCase() ? 1 : -1;
        },
        render(providerType) {
            return providerType ? _capitalize(providerType) : "Individual";
        }
    },
    {
        title: "DEALER TYPE",
        dataIndex: "dealerType",
        key: "dealerType",
        render(dealerType) {
            return dealerType ? _capitalize(dealerType) : "Unknown";
        }
    },
    {
        title: "LICENSE NUMBER",
        dataIndex: "licenseNo",
        key: "licenseNo",
        render(licenseNo) {
            return licenseNo || "";
        }
    },
    {
        title: "PROFESSION",
        dataIndex: "profession",
        key: "profession",
        render(profession) {
            return <span>{toProfessionName(profession, "-")}</span>;
        }
    },
    {
        title: "CATEGORIES",
        key: "categories",
        width: 200,
        filters: services.map(s => ({
            text: s.categoryName,
            value: s.serviceId
        })),
        filterMultiple: false,
        onFilter: (serviceId, {services}) => {
            return Array.isArray(services) && !!services.find(s => s.service.serviceId === serviceId);
        },
        render(_, provider) {
            const {services} = provider;
            return (
                <span>{Array.isArray(services) ? uniq(services.map(s => s.service.categoryName)).join(", ") : ""}</span>
            );
        }
    },
    {
        title: "EXPERIENCE",
        dataIndex: "experience",
        key: "experience",
        render(experience) {
            return `${experience} yrs.`;
        }
    },
    {
        title: "RATING",
        dataIndex: "rating",
        key: "rating",
        sorter: (r1, r2) => {
            const rate1 = r1.rating ? r1.rating : 0;
            const rate2 = r2.rating ? r2.rating : 0;
            return rate1 < rate2 ? 1 : -1;
        }
    },
    {
        title: "VIDEO",
        dataIndex: "video",
        key: "video",
        render(video) {
            return video ? (
                <a href={video.url} target="_blank">
                    Watch It
                </a>
            ) : (
                ""
            );
        }
    },
    {
        title: "SOCIAL",
        dataIndex: "portfolioLink",
        key: "portfolioLink",
        render(portfolioLink) {
            return portfolioLink ? (
                <a href={portfolioLink} target="_blank">
                    See It
                </a>
            ) : (
                ""
            );
        }
    },
    {
        title: "SERVICES",
        dataIndex: "services",
        key: "services",
        sorter: (r1, r2) => {
            if (!Array.isArray(r1.services)) r1.services = [];
            if (!Array.isArray(r2.services)) r2.services = [];
            return r1.services.length < r2.services.length ? 1 : -1;
        },
        render(services, {providerId, firstName}) {
            return (
                <Button
                    type="link"
                    onClick={() =>
                        openWindow(`/admin/providers/${providerId}/services?fn=${firstName}`, "_blank", true)
                    }>
                    {Array.isArray(services) ? services.length : 0}
                </Button>
            );
        }
    },
    {
        title: "PAYMENT PLAN",
        dataIndex: "paymentPlan",
        key: "paymentPlan",
        filters: [
            {
                text: PaymentPlan.POPULAR,
                value: PaymentPlan.POPULAR
            },
            {
                text: PaymentPlan.NOT_POPULAR,
                value: PaymentPlan.NOT_POPULAR
            },
            {
                text: PaymentPlan.PER_ACTIVE_DAY,
                value: PaymentPlan.PER_ACTIVE_DAY
            }
        ],
        filterMultiple: false,
        onFilter: (value, {paymentPlan}) => paymentPlan === value,
        width: 200
    },
    {
        title: "CREATED AT",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 200,
        sorter: (r1, r2) => (r1.createdAt < r2.createdAt ? 1 : -1),
        defaultSortOrder: "ascend",
        render(createdAt) {
            return createdAt ? moment(createdAt).format("MMMM Do, YYYY HH:mm") : "";
        }
    },
    {
        title: "PROFILE",
        dataIndex: "approved",
        key: "approved",
        filters: [
            {
                text: "Approved",
                value: true
            },
            {
                text: "Not approved",
                value: false
            }
        ],
        filterMultiple: false,
        onFilter: (value, {approved}) => !!approved === value,
        render(approved, {email}) {
            return (
                <a onClick={() => openWindow(`/admin/providers/approval?email=${email}`, "_blank", true)}>
                    {approved ? "Edit" : "Approve"}
                </a>
            );
        }
    },
    {
        title: "DELETE",
        key: "delete",
        render(_, {providerId}) {
            return (
                <Button
                    style={{minWidth: 100}}
                    loading={deletingProviderId === providerId}
                    onClick={async () => await onDelete(providerId)}>
                    Delete
                </Button>
            );
        }
    }
];

export class AdminProviders extends React.Component {
    state = {
        term: "",
        providers: null,
        deletingProviderId: null,
        services: null
    };

    async componentDidMount() {
        try {
            const [allProviders, services] = await Promise.all([
                HttpClient.get("/admin/api/providers"),
                HttpClient.get("/api/services")
            ]);
            const v1Providers = allProviders.filter(provider => !provider.v2);
            this.setState({providers: v1Providers, services});
        } catch (e) {
            message.error(e.message, 3);
        }
    }

    filter(term) {
        this.setState({term});
    }

    async deleteProvider(providerId) {
        const {providers} = this.state;

        this.setState({deletingProviderId: providerId});
        try {
            await HttpClient.delete(`/admin/api/providers/${providerId}`);
            this.setState({providers: providers.filter(p => p.providerId !== providerId)});
        } catch (e) {
            message.error(e.message, 3);
        }
        this.setState({deletingProviderId: null});
    }

    render() {
        let {providers, term, deletingProviderId, services} = this.state;

        if (providers) {
            providers = providers.filter(
                provider =>
                    `${provider.firstName} ${provider.lastName || ""} ${provider.phone} ${provider.email}`
                        .toLowerCase()
                        .indexOf(term.toLowerCase()) >= 0
            );
        }

        return (
            <div style={{display: "flex", justifyContent: "center", margin: 10, marginBottom: 40}}>
                <div style={{display: "flex", flexDirection: "column", maxWidth: 1500, width: "100%"}}>
                    <PageTitleHeader showBack={false}>Professionals</PageTitleHeader>
                    <div>
                        <SearchInput
                            style={{marginBottom: 30, maxWidth: 500, width: "100%"}}
                            onChange={evt => this.filter(evt.target.value)}
                            placeholder="Search professional"
                        />
                    </div>
                    <RoundedTable
                        rowKey="providerId"
                        scroll={{x: 2600, y: 600}}
                        pagination={false}
                        loading={providers === null}
                        dataSource={providers || []}
                        columns={columns(
                            async providerId => this.deleteProvider(providerId),
                            deletingProviderId,
                            services || []
                        )}
                    />
                </div>
            </div>
        );
    }
}
