import {excelSheetsTitle} from "./consts";
import moment from "moment/moment";
import {createWorkbook, createWorksheet, DownloadExcel} from "../utils/excelUtils.js";
import {removeEmojis} from "../utils/StringFormat";
import {getOrderDiscountPrice, reduceVAT} from "../utils/calculationUtils";
import {dateTimeRange, dateTimeRangeFormat} from "../utils/DateUtils";
import {getOrderContactInfo} from "../event/utils";
import {ActivityLocationTypes, ProviderActivityPossibleLocations} from "../consts.js";
import {statusToTextAndColor} from "../components";
import {getInvoicePriceByProviderId} from "./utils";

export const ExportTableToProviderExcel = async (orders, systemCSMs, providers) => {
    const workbook = createWorkbook();

    const excelSheets = [
        {
            title: excelSheetsTitle.orders,
            list: orders
        }
    ];

    excelSheets.forEach(({list = [], title}, index) => {
        const sheet = createWorksheet(workbook, title);
        addColumns(sheet);
        addRows(sheet, list, !index, title, systemCSMs, providers);
    });

    await DownloadExcel(workbook, "ordersToProvider");
};

const addRows = (sheet, orders, isMonthlyBill, sheetTitle, systemCSMs, providers) => {
    const rows = orders.reduce((allRows, order) => {
        const subOrders = order?.subOrders?.length ? order.subOrders : [order];

        for (const subOrder of subOrders) {
            subOrder.productName = removeEmojis(subOrder.productName);
            const createdAt = moment(subOrder.createdAt).format("DD/MM/YY");

            const {dtStartDateFormat, untilDateFormat, dtStartHourFormat, untilHourFormat} = dateTimeRange(
                subOrder.dtstart,
                subOrder?.until
            );

            const address = `${subOrder.address} ${
                subOrder.address === ProviderActivityPossibleLocations[ActivityLocationTypes.homeDelivery]
                    ? subOrder?.employeesExcel?.fileUrl ?? ""
                    : ""
            }`;

            const supplyDate = `${dtStartDateFormat} ${untilDateFormat ? ` - ${untilDateFormat}` : ""}`;
            const supplyHours = `${dtStartHourFormat} ${untilHourFormat ? ` - ${untilHourFormat}` : ""}`;

            const orderContactInfo = getOrderContactInfo(subOrder)[0];

            function getOrderCsmOrFirstUser(order) {
                const csmFound = order.assignedCSMs?.find(csmUser => {
                    const systemCsmUser = systemCSMs.find(systemCsmUser => systemCsmUser.userId === csmUser.userId);
                    return systemCsmUser?.position === "CSM";
                });

                return csmFound?.firstName ?? order?.assignedCSMs?.[0]?.firstName;
            }

            const row = [
                subOrder.providerName,
                subOrder.proposalId,
                subOrder.companyName,
                subOrder.productName,
                createdAt,
                supplyDate,
                supplyHours,
                address,
                subOrder.offeredPrice,
                reduceVAT(subOrder.offeredPrice, subOrder.vat),
                subOrder.deliveryPrice,
                reduceVAT(subOrder.deliveryPrice, subOrder.vat),
                getOrderDiscountPrice(subOrder),
                orderContactInfo.name ?? subOrder.companyContactName,
                orderContactInfo.phone ?? subOrder.companyPhone,
                subOrder?.parentOrderId ?? "-",
                getOrderCsmOrFirstUser(subOrder),
                subOrder.companyExtraRequests,
                statusToTextAndColor[subOrder.status] ? statusToTextAndColor[subOrder.status][0] : ""
            ];

            if (subOrder.modularProducts?.length > 0) {
                const newRows = subOrder.modularProducts.map(modularOrderProduct => {
                    const totalProductPrice = modularOrderProduct.price * modularOrderProduct.amount;
                    const commission = modularOrderProduct.commission ?? subOrder.commission;
                    const priceWithoutCommission = getInvoicePriceByProviderId(
                        totalProductPrice,
                        commission,
                        subOrder.providerId,
                        providers,
                        false,
                        subOrder.vat
                    );
                    const priceWithCommission = getInvoicePriceByProviderId(
                        totalProductPrice,
                        commission,
                        subOrder.providerId,
                        providers,
                        true,
                        subOrder.vat
                    );
                    return [
                        ...row,
                        modularOrderProduct.name,
                        modularOrderProduct.amount,
                        commission ?? "",
                        totalProductPrice,
                        priceWithoutCommission,
                        priceWithCommission
                    ];
                });
                allRows.push(...newRows);
            } else {
                const priceWithoutCommission = getInvoicePriceByProviderId(
                    subOrder.offeredPrice,
                    subOrder.commission,
                    subOrder.providerId,
                    providers,
                    false,
                    subOrder.vat
                );
                const priceWithCommission = getInvoicePriceByProviderId(
                    subOrder.offeredPrice,
                    subOrder.commission,
                    subOrder.providerId,
                    providers,
                    true,
                    subOrder.vat
                );
                allRows.push([
                    ...row,
                    subOrder.productName,
                    subOrder.productAmount,
                    subOrder.commission,
                    subOrder.offeredPrice,
                    priceWithoutCommission,
                    priceWithCommission
                ]);
            }
        }

        return allRows;
    }, []);

    sheet.addRows(rows);
};

const addColumns = sheet => {
    sheet.columns = [
        {header: "שם השותף", key: "businessNumber"},
        {header: "מספר הזמנה", key: "orderId"},
        {header: "שם החברה", key: "companyName"},
        {header: "שם החבילה", key: "productName"},
        {header: "תאריך הזמנה", key: "createdAt"},
        {header: "תאריך הספקה", key: "dtStartDate"},
        {header: "טווח הספקה", key: "dtStartHours"},
        {header: "כתובת מלאה לאספקה", key: "address"},
        {header: "סכום ₪ (כולל מעמ)", key: "offeredPrice"},
        {header: "סכום ₪ (לפני מעמ)", key: "offeredPriceWithoutVAT"},
        {header: "עלות משלוח כולל מעמ", key: "deliveryPrice"},
        {header: "עלות משלוח ללא מעמ", key: "deliveryPriceWithoutVAT"},
        {header: "הנחה", key: "discount"},
        {header: "איש קשר למשלוח", key: "contactName"},
        {header: "טלפון", key: "phone"},
        {header: "מזהה הזמנה משולבת", key: "parentOrderId"},
        {header: "מנהלת הלקוח וולבי", key: "CSM"},
        {header: "הערות", key: "comments"},
        {header: "סטטוס הזמנה", key: "status"},
        {header: "שם מוצר", key: "products"},
        {header: "כמות", key: "productsAmount"},
        {header: "עמלה", key: "commission"},
        {header: "סכום עבור המוצר ₪ (כולל מע״מ)", key: "pricePerProduct"},
        {header: "עלות המכר ₪ (ללא מע״מ)", key: "providerPaymentWithoutVAT"},
        {header: "עלות המכר ₪ (כולל מע״מ)", key: "providerPayment"}
    ];
};
