import React from "react";
import {Avatar, Switch} from "antd";
import {DragHandle} from "../../components/dragableTable.jsx";

const adminSearchTagsTableColumns = (showSort = true, onActiveChange) => [
    {
        title: "#",
        key: "#",
        width: 70,
        render(_, __, idx) {
            return `${idx + 1}`;
        }
    },
    showSort
        ? {
              title: "סידור",
              dataIndex: "sort",
              width: 30,
              className: "drag-visible",
              render: () => <DragHandle />
          }
        : {},
    {
        title: "שם התגית",
        dataIndex: "name",
        width: 30,
    },
    {
        title: "אייקון",
        dataIndex: "image",
        width: 30,
        render: image => {
            return <div>{image?.imageUrl && <Avatar size={32} src={image?.imageUrl} />}</div>;
        }
    },
    {
        title: "כמות מוצרים פעילים",
        dataIndex: "products",
        width: 30,
        render: products => {
            return <div>{products?.length > 0 ? products.length : 0}</div>;
        }
    },
    {
        title: "סטטוס",
        dataIndex: "isActive",
        width: 30,
        render: (isActive, tag) => {
            return (
                <div>
                    <Switch defaultChecked={isActive ?? false} onChange={(isActive) => onActiveChange(tag, isActive)} />
                </div>
            );
        }
    }
];

export default adminSearchTagsTableColumns;
