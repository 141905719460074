import React from "react";
import {Button, Card, Progress} from "antd";
import {truncate as _truncate} from "lodash";
import {ProviderProfession} from "./ProviderProfession";
import {ManIcon, WomanIcon, WatchIcon, LocationIcon, BuildingIcon, DestinationIcon} from "../icons";
import {ProviderVideoModal} from "./ProviderVideoModal";
import {AppContext} from "../AppContext";
import {isAppointmentBasedProfession} from "../data/professions";
import {ProviderPortfolioViewModal} from "./ProviderPortfolioViewModal";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {LocationLabels} from "../data/locations";
import {SmartImageContainer} from "../components/SmartImageContainer";

const {Meta} = Card;

export class ProviderProfileCard extends React.Component {
    static contextType = AppContext;

    state = {
        watch: false,
        showPortfolio: false
    };

    watchDemo({video, providerId}) {
        if (video && video.url) {
            GoogleAnalytics.event("Professional Card", "Watch Demo", providerId);
            this.setState({watch: true});
        }
    }

    closeDemo() {
        this.setState({watch: false});
    }

    openPortfolio({providerId}) {
        if (this.hasPortfolioImages()) {
            GoogleAnalytics.event("Professional Card", "View Portfolio", providerId);
            this.setState({showPortfolio: true});
        }
    }

    closePortfolio() {
        this.setState({showPortfolio: false});
    }

    hasPortfolioImages() {
        const {provider} = this.props;
        const {portfolioImages} = provider;
        return Array.isArray(portfolioImages) && portfolioImages.length > 0;
    }

    enterProvider() {
        const {onEnter, provider} = this.props;
        onEnter(provider);
    }

    priceRangeFormat(priceRange) {
        if (priceRange.min === priceRange.max) {
            return priceRange.min;
        } else {
            return `${priceRange.min} - ${priceRange.max}`;
        }
    }

    officeOrOnlineProvider(services) {
        const locationSupportMap = {
            [LocationLabels.ONSITE]: false,
            [LocationLabels.ONLINE]: false,
            [LocationLabels.ONSITE_AND_ONLINE]: false
        };

        services.forEach(service => {
            locationSupportMap[service.location] = true;
        });

        if (
            locationSupportMap[LocationLabels.ONSITE_AND_ONLINE] ||
            (locationSupportMap[LocationLabels.ONLINE] && locationSupportMap[LocationLabels.ONSITE])
        ) {
            return "At the office / online";
        } else if (locationSupportMap[LocationLabels.ONLINE]) {
            return "Online";
        } else {
            return "At the office";
        }
    }

    render() {
        const {watch, showPortfolio} = this.state;
        const {provider} = this.props;
        const {professions} = this.context;

        const {
            firstName,
            customers,
            countryName,
            cityName,
            services,
            rating,
            imageUrl,
            profession,
            portfolioImages,
            video,
            priceRange
        } = provider;

        return (
            <Card
                className="wb-provider-card"
                bordered={false}
                onClick={() => this.enterProvider()}
                hoverable={true}
                style={{
                    width: 340,
                    borderRadius: 20,
                    backgroundColor: "white",
                    boxShadow: "0 5px 15px 0 rgba(0,0,0,0.1)",
                    overflow: "hidden",
                    margin: 10
                }}
                cover={
                    <div
                        style={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column"
                        }}>
                        <ProviderPortfolioViewModal
                            portfolioImages={portfolioImages || []}
                            onClose={() => this.closePortfolio()}
                            visible={showPortfolio}
                        />
                        <ProviderVideoModal
                            status={video ? video.status : void 0}
                            uploadable={false}
                            visible={watch}
                            onClose={() => this.closeDemo()}
                            videoUrl={video ? video.url : null}
                        />
                        {imageUrl ? (
                            <SmartImageContainer
                                width={100}
                                height={100}
                                src={imageUrl}
                                style={{
                                    borderRadius: 50,
                                    marginTop: 30
                                }}
                            />
                        ) : provider.gender === "female" ? (
                            <WomanIcon style={{width: 100, height: 100, marginTop: 30}} />
                        ) : (
                            <ManIcon style={{width: 100, height: 100, marginTop: 30}} />
                        )}
                        <ProviderProfession profession={profession} style={{marginTop: 20}} />
                    </div>
                }
                actions={[
                    <div
                        style={{
                            height: 60,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "4px 15px",
                            color: "#273444"
                        }}
                        onClick={e => e.stopPropagation()}>
                        ₪<label style={{fontWeight: 700, marginRight: 5}}>{this.priceRangeFormat(priceRange)}</label>
                    </div>,
                    <div onClick={e => e.stopPropagation()}>
                        {isAppointmentBasedProfession(profession, professions) ? (
                            <Button
                                onClick={() => this.openPortfolio(provider)}
                                icon={
                                    <WatchIcon
                                        style={{marginRight: 8}}
                                        fill={this.hasPortfolioImages() ? "#1A344A" : "#B7BABD"}
                                    />
                                }
                                style={{
                                    height: 60,
                                    border: "none",
                                    width: "100%",
                                    cursor: this.hasPortfolioImages() ? "pointer" : "not-allowed",
                                    color: this.hasPortfolioImages() ? "#273444" : "#B7BABD",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                View portfolio
                            </Button>
                        ) : (
                            <Button
                                onClick={() => this.watchDemo(provider)}
                                icon={
                                    <WatchIcon
                                        style={{marginRight: 8}}
                                        fill={video && video.url ? "#1A344A" : "#B7BABD"}
                                    />
                                }
                                style={{
                                    height: 60,
                                    border: "none",
                                    width: "100%",
                                    cursor: video && video.url ? "pointer" : "not-allowed",
                                    color: video && video.url ? "#273444" : "#B7BABD",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                Watch demo
                            </Button>
                        )}
                    </div>
                ]}>
                <Meta
                    title={
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: 20,
                                color: "#273444",
                                fontWeight: 700
                            }}>
                            {firstName}
                        </div>
                    }
                    description={
                        <div style={{marginTop: 15}}>
                            <div style={{display: "flex", flexDirection: "column", minHeight: 105}}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: 10,
                                        fontSize: 16,
                                        paddingLeft: 3
                                    }}>
                                    <LocationIcon />
                                    <span style={{marginLeft: 10}}>
                                        {countryName}, {cityName}
                                    </span>
                                </div>
                                {customers ? (
                                    <div
                                        style={{display: "flex", alignItems: "center", marginBottom: 10, fontSize: 16}}>
                                        <BuildingIcon />
                                        <span style={{marginLeft: 10}}>
                                            {_truncate(`Customers: ${customers}`, {
                                                length: 32,
                                                separator: " "
                                            })}
                                        </span>
                                    </div>
                                ) : null}
                                {Array.isArray(services) && services.length > 0 ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginBottom: 10,
                                            fontSize: 16,
                                            paddingLeft: 3
                                        }}>
                                        <DestinationIcon />
                                        <span style={{marginLeft: 10}}>{this.officeOrOnlineProvider(services)}</span>
                                    </div>
                                ) : null}
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    marginTop: 10
                                }}>
                                <label style={{fontSize: 14, color: "#273444"}}>Professional rating</label>
                                <Progress
                                    className="wb-professional-rating wb-no-right"
                                    percent={rating * 10}
                                    strokeColor="#ABE9E2"
                                    format={percent => percent / 10}
                                />
                            </div>
                        </div>
                    }
                />
            </Card>
        );
    }
}
