import React, {useCallback, useMemo, useRef} from "react";
import {Input, message, Switch} from "antd";
import {isFinite} from "lodash";
import {HttpClient} from "../../http/HttpClient";
import {BudgetModal, ConfirmationRequiredWrapper, SquareButton} from "../../components";
import {useRequest} from "../../utils/hooks";

export const AdminBudgetModal = ({companyDetails, onClose, onFinish, updateCompany}) => {
    const discountInputRef = useRef(null);
    const [companyBudget, loadingCompanyBudget, , fetchBudget] = useRequest(
        `/admin/api/budget/company/${companyDetails?.companyId}`,
        "get",
        null,
        [],
        !!companyDetails?.companyId
    );

    const createBudget = useCallback(async () => {
        if (!companyDetails?.companyId) {
            message.error("קרתה שגיאה ביצרת התקציב, נסה שנית");
            return;
        }

        const {error} = await HttpClient.safePut("/admin/api/budget", {companyId: companyDetails.companyId});

        if (error) {
            message.error("קרתה שגיאה ביצרת התקציב");
            return;
        }

        message.success("תקציב נוצר בהצלחה");
        fetchBudget();
    }, [companyDetails, fetchBudget]);

    const updateBudget = useCallback(
        async amount => {
            if (!companyDetails?.companyId) {
                message.error("קרתה שגיאה בטעינת התקציב, נסה שנית");
                return;
            }

            const body = {
                companyId: companyDetails.companyId,
                amount: amount
            };

            const {error} = await HttpClient.safePost("/admin/api/updateBudget", body);

            if (error) {
                message.error("קרתה שגיאה בעדכון התקציב");
                return;
            }

            message.success("תקציב עודכן בהצלחה");
            onFinish();
        },
        [companyDetails, onFinish]
    );

    const changeCompanyBudgetPlan = useCallback(async () => {
        const updatedCompany = await HttpClient.safePost("/admin/api/companies/update", {
            companyId: companyDetails.companyId,
            payOnOrderBudget: !companyDetails.payOnOrderBudget
        });

        updateCompany(updatedCompany);
    }, [companyDetails]);

    const toggleCompanyOrdersDiscount = useCallback(
        async checked => {
            const updatedCompany = await HttpClient.safePost("/admin/api/companies/update", {
                companyId: companyDetails.companyId,
                ordersDiscount: checked ? 0 : null
            });

            updateCompany(updatedCompany);
        },
        [companyDetails]
    );

    const updateCompanyOrdersDiscount = useCallback(async () => {
        const newDiscount = parseFloat(discountInputRef.current.input.value);

        if (!newDiscount) {
            message.error("קלט לא תקין");
            return;
        }

        const updatedCompany = await HttpClient.safePost("/admin/api/companies/update", {
            companyId: companyDetails.companyId,
            ordersDiscount: newDiscount
        });

        message.success("הנחת תקציב עודכנה בהצלחה");

        updateCompany(updatedCompany);
    }, [companyDetails, discountInputRef]);

    const budget = useMemo(
        () => ({
            amount:
                companyBudget?.budgets?.length && companyBudget.budgets.some(({isMasterBudget}) => isMasterBudget)
                    ? companyBudget?.totalBudget
                    : null
        }),
        [companyBudget]
    );

    return (
        <BudgetModal
            budget={budget}
            title={`עריכת תקציב: ${companyDetails?.name ?? ""}`}
            visibility={!!companyDetails}
            onClose={onClose}
            onSave={amount => updateBudget(amount)}
            loadingBudget={loadingCompanyBudget}
            budgetModalExtraContent={
                <>
                    <ConfirmationRequiredWrapper
                        onYes={changeCompanyBudgetPlan}
                        title="שים לב!"
                        subTitle=" שינוי זה ישפיע על הדרך שבה גובים כסף מהחברה בהקשרי תקציב. אם לחברה זאת כבר יש תקציב פעיל פעולה זאת לא מומלצת.">
                        <Switch
                            checkedChildren="תשלום באמצעות תקציב מסגרת"
                            unCheckedChildren="תשלום באמצעות תקציב ששולם מראש"
                            checked={!!companyDetails?.payOnOrderBudget}
                        />
                    </ConfirmationRequiredWrapper>
                    <div className="admin-companies-budget-modal-budget-discount">
                        <Switch
                            checkedChildren="הנחת קאשבק לתתי אדמין"
                            unCheckedChildren="ללא הנחה"
                            onChange={toggleCompanyOrdersDiscount}
                            checked={isFinite(companyDetails?.ordersDiscount)}
                        />
                        <Input
                            ref={discountInputRef}
                            defaultValue={companyDetails?.ordersDiscount}
                            prefix="%"
                            disabled={!isFinite(companyDetails?.ordersDiscount)}
                        />
                        <SquareButton
                            disabled={!isFinite(companyDetails?.ordersDiscount)}
                            onClick={updateCompanyOrdersDiscount}>
                            שמור הנחה
                        </SquareButton>
                    </div>
                    <div className="break-line" />
                </>
            }
            emptyState={
                <div className="admin-companies-budget-modal-no-budget">
                    <span>נראה שאין לחברה זאת תקציב</span>
                    <ConfirmationRequiredWrapper
                        onYes={createBudget}
                        subTitle="לאחר האישור יהיה לחברה אפשרות לשלם עם תקציב">
                        <span className="admin-order-modal-save-button">יצירת תקציב לחברה</span>
                    </ConfirmationRequiredWrapper>
                </div>
            }
        />
    );
};
