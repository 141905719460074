import React, {useContext} from "react";
import {AppContext} from "../../AppContext";
import {StyledButton} from "../../components";
import {Link} from "react-router-dom";
import {Redirect, Route, Switch} from "react-router";
import {UnsubscribePage} from "../../auth/UnsubscribePage";
import {EXCenterProfileSettingsAdditionalOptions} from "./EXCenterProfileSettingsAdditionalOptions";
import {EXCenterProfileBirthdayWishes} from "./EXCenterProfileBirthdayWishes";
import CompanySchedule from "../../company/CompanySchedule";
import {EXCenterProfileAppointmentEdit} from "./EXCenterProfileAppointmentEdit";
import {EXCenterProfileInfo} from "./EXCenterProfileInfo";
import {EXCenterContext} from "../EXCenterContext";
import {EXCenterMobileCalendar} from "../calendar/EXCenterMobileCalendar";
import {EXCenterProperties} from "../utils/EXCenterProperties";
import {isScreenSizeOfTypes, ScreenSize} from "../EXCenterHelper";

const KEY_INDEX_IN_PATH = 3;

const extractKeyFromPath = location => {
    const parts = location.pathname.split("/");
    return parts[KEY_INDEX_IN_PATH];
};

export const EXCenterProfilePage = ({location}) => {
    const {me} = useContext(AppContext);
    const {theme, screenSize} = useContext(EXCenterContext);

    const isSmallScreen = isScreenSizeOfTypes([ScreenSize.Mobile, ScreenSize.Tablet], screenSize);

    const links = [
        {
            text: "My bookings",
            path: "/center/profile/bookings",
            key: "bookings"
        },
        {
            text: "Personal info",
            path: "/center/profile/info",
            key: "info"
        },
        {
            text: "Settings",
            path: "/center/profile/settings",
            key: "settings"
        },
        {
            text: "Birthday wishes",
            path: "/center/profile/wishes",
            key: "wishes"
        }
    ];

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: EXCenterProperties.Section.Cover.Height,
                    width: "100%",
                    backgroundColor: theme.primaryColor
                }}>
                <span style={{color: "white", fontSize: 46, fontWeight: 900}}>
                    Hello {me.firstName || me.companyName}
                </span>
            </div>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    minHeight: 100,
                    boxShadow: "0 5px 15px 0 rgba(22,29,37,0.1)"
                }}>
                {links.map(({text, path, key}) => {
                    const activeKey = extractKeyFromPath(location);
                    const isActive = activeKey === key;
                    return (
                        <Link key={path} to={path}>
                            <StyledButton
                                className="wb-section-category-button"
                                style={{
                                    borderRadius: 25,
                                    margin: 10,
                                    height: 40,
                                    color: isActive ? "white" : theme.primaryColor,
                                    backgroundColor: isActive ? theme.primaryColor : "white",
                                    border: isActive ? "none" : `1px solid ${theme.primaryColor}`
                                }}>
                                {text}
                            </StyledButton>
                        </Link>
                    );
                })}
            </div>
            <div style={{display: "flex", maxWidth: 1200, width: "100%"}}>
                <Switch>
                    <Route
                        exact
                        path="/center/profile"
                        render={() => <Redirect to={`/center/profile/bookings${location.search || ""}`} />}
                    />
                    <Route
                        exact
                        path="/center/profile/bookings"
                        render={props => (
                            <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                                {isSmallScreen ? (
                                    <div style={{marginTop: 40}}>
                                        <EXCenterMobileCalendar isSmallScreen />
                                    </div>
                                ) : (
                                    <CompanySchedule
                                        {...props}
                                        onlyRegistered={true}
                                        hideTitle={true}
                                        className="wb-center-calendar"
                                    />
                                )}
                            </div>
                        )}
                    />
                    <Route exact path="/center/profile/info" component={EXCenterProfileInfo} />
                    <Route
                        exact
                        path="/center/profile/settings"
                        render={props => {
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                        alignItems: "center"
                                    }}>
                                    <UnsubscribePage
                                        {...props}
                                        hideBack={true}
                                        hideIntro={true}
                                        instant={true}
                                        style={{marginBottom: 0, marginTop: 50}}
                                    />
                                    <EXCenterProfileSettingsAdditionalOptions style={{marginTop: 50}} />
                                </div>
                            );
                        }}
                    />
                    <Route exact path="/center/profile/wishes" component={EXCenterProfileBirthdayWishes} />
                    <Route
                        exact
                        path="/center/profile/bookings/:eventId/appointments/:appointmentId/:start/:end/edit"
                        component={EXCenterProfileAppointmentEdit}
                    />
                </Switch>
            </div>
        </div>
    );
};
