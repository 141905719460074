import React from "react";
import {isEmpty} from "lodash";
import {getInvoicePrice} from "../../utils/calculationUtils";
import {ProviderDealerTypes} from "../../admin/upload/AdminUploadUtils";
import "./provider-orders.css";
import classNames from "classnames";
import {Switch} from "antd";

export const getCommission = (services, productId) => {
    const [service] = services ? services.filter(s => s.productId === productId) : [];
    if (!isEmpty(service)) {
        return service.commission || "";
    }
    return "";
};

export const getTotalDelivery = (providerDetails, transactions) => {
    return isEmpty(transactions)
        ? 0
        : transactions.reduce((acc, transaction) => {
              return (
                  parseFloat(acc) + parseFloat(transaction.providerDeliveryPrice ?? (transaction.deliveryPrice || 0))
              );
          }, 0);
};

export const getTotalPriceForInvoice = (providerDetails, transactions) => {
    const isExemptDealerType = providerDetails?.dealerType === ProviderDealerTypes.exempt;

    return isEmpty(transactions)
        ? 0
        : transactions.reduce((acc, transaction) => {
              return Math.ceil(
                  parseFloat(acc) +
                      getInvoicePrice(
                          transaction.offeredPrice,
                          transaction.commission || getCommission(providerDetails?.services, transaction.productId),
                          isExemptDealerType,
                          transaction.vat
                      )
              );
          }, 0);
};

export const getTotalPayment = (providerDetails, transactions) => {
    return Math.ceil(
        getTotalDelivery(providerDetails, transactions) + getTotalPriceForInvoice(providerDetails, transactions)
    );
};

export const SearchByCreatedSwitch = ({searchByCreated, onChange}) => {
    return (
        <div
            className={classNames("provider-orders-date-search-switch-container", {
                active: searchByCreated
            })}>
            <span className="provider-orders-date-search-switch-label">
                סינון לפי תאריך {searchByCreated ? "הזמנה" : "אספקה"}
            </span>
            <Switch checked={searchByCreated} onClick={onChange} />
        </div>
    );
};
