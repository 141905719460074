import React from "react";
import moment from "moment";
import {Spin, Tooltip} from "antd";
import {MonthFormat} from "./consts";
import {TableDoubleValueCell} from "../../../components";
import {InvoicesStatusToNameAndColor} from "../../../provider/ProviderManageInvoices/consts";

const getColumns = ({providerInvoices, loadingInvoices, openProviderOrders}) => [
    {
        title: "#",
        key: "#",
        width: 70,
        render(_, __, idx) {
            return `${idx + 1}`;
        }
    },
    {
        title: "חודש פעילות",
        dataIndex: "hebrewMonthName",
        render(hebrewMonthName, {month}) {
            return (
                <TableDoubleValueCell className="admin-invoices-table-clickable-sub-text">
                    {hebrewMonthName}
                    <span onClick={() => openProviderOrders(moment(month, MonthFormat).add(1, "days"))}>
                        לפירוט החודשי
                    </span>
                </TableDoubleValueCell>
            );
        }
    },
    {
        title: (
            <>
                סכום הזמנות לצרכן
                <br />
                (כולל מע״מ)
            </>
        ),
        dataIndex: "offeredPrice",
        sorter: (r1, r2) => {
            return r1.offeredPrice - r2.offeredPrice;
        },
        render(offeredPrice) {
            return `${offeredPrice.toLocaleString("en-GB", {maximumFractionDigits: 2})} ₪`;
        }
    },
    {
        title: (
            <>
                תשלום לשותף
                <br />
                (כולל מע״מ)
            </>
        ),
        dataIndex: "providerPayment",
        sorter: (r1, r2) => {
            return r1.providerPayment - r2.providerPayment;
        },
        render(providerPayment) {
            return `${providerPayment.toLocaleString("en-GB", {maximumFractionDigits: 2})} ₪`;
        }
    },
    {
        title: (
            <>
                עלויות משלוח
                <br />
                (כולל מע״מ)
            </>
        ),
        dataIndex: "deliveryPrice",
        sorter: (r1, r2) => {
            return (r1.deliveryPrice ?? 0) - (r2.deliveryPrice ?? 0);
        },
        render(deliveryPrice) {
            return `${(deliveryPrice ?? 0).toLocaleString("en-GB", {maximumFractionDigits: 2})} ₪`;
        }
    },
    {
        title: (
            <>
                עלויות משלוח עם וולבי
                <br />
                (כולל מע״מ)
            </>
        ),
        dataIndex: "deliveryPriceByWellB",
        sorter: (r1, r2) => {
            return (r1.deliveryPriceByWellB ?? 0) - (r2.deliveryPriceByWellB ?? 0);
        },
        render(deliveryPriceByWellB) {
            return `${(deliveryPriceByWellB ?? 0)?.toLocaleString("en-GB", {maximumFractionDigits: 2})} ₪`;
        }
    },
    {
        title: (
            <>
                זיכוי שותף
                <br />
                (כולל מע״מ)
            </>
        ),
        dataIndex: "providerDiscount",
        sorter: (r1, r2) => {
            return r1.providerDiscount - r2.providerDiscount;
        },
        render(providerDiscount) {
            return providerDiscount ? (
                <div className="ltr">{`₪ ${(-1 * providerDiscount || 0).toLocaleString("en-GB", {
                    maximumFractionDigits: 2
                })}`}</div>
            ) : null;
        }
    },
    {
        title: (
            <>
                טיפ
                <br />
                (כולל מע״מ)
            </>
        ),
        dataIndex: "tip",
        sorter: (r1, r2) => r1.tip - r2.tip,
        render(tip) {
            return `${tip?.toLocaleString("en-GB", {maximumFractionDigits: 2})} ₪`;
        }
    },
    {
        title: "סה״כ תשלום לשותף",
        key: "total",
        render(_, summary) {
            return `${(
                summary.providerPayment +
                summary.deliveryPrice +
                summary.deliveryPriceByWellB-
                summary.providerDiscount +
                summary.tip
            ).toLocaleString()} ₪`;
        }
    },
    {
        title: (
            <>
                מס׳ ההזמנות
                <br />
                שסופקו
            </>
        ),
        dataIndex: "ordersCount",
        sorter: (r1, r2) => {
            return r1.ordersCount - r2.ordersCount;
        },
        render(ordersCount) {
            return ordersCount;
        }
    },
    {
        title: "סטטוס",
        key: "status",
        render(_, {month}) {
            if (loadingInvoices) {
                return <Spin />;
            }

            const invoices = providerInvoices.filter(
                ({month: invoiceMonth}) => moment(invoiceMonth).format(MonthFormat) === month
            );
            return (
                <div className="admin-provider-payment-history-modal-status-cell">
                    {invoices.map(({status, file}) => {
                        const {name, color} = InvoicesStatusToNameAndColor[status?.id];

                        return (
                            <Tooltip title={file?.fileName}>
                                <span style={{color}}>{name}</span>
                            </Tooltip>
                        );
                    })}
                </div>
            );
        }
    }
];

export default getColumns;
