export const createAppointmentDetails = (me, event, product, date, start, end) => {
    return {
        eventId: event.eventId,
        companyId: event.companyId,
        employeeId: me.userId,
        providerId: event.providerId,
        productName: product.productName,
        price: product.price,
        durationMinutes: product.durationMinutes,
        date,
        start,
        end
    };
};
