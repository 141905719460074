import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {message} from "antd";
import "../../admin/AdminOrders/admin-orders.css";
import {HttpClient} from "../../http/HttpClient";
import {useRequest} from "../../utils/hooks";
import {Images} from "../../images/Images";
import {GenericModal} from "../../components";
import "./company-edit-order-page.css";
import {validateBooking} from "../../marketplace/MarketplaceBooking/utils";
import {AppContext} from "../../AppContext";
import {OrderFormPanelKeys} from "../../marketplace/consts.jsx";
import {isOrderHasShippingProvider} from "../../event/utils.jsx";
import {ActivityLocationTypes} from "../../consts.js";
import {HomeDeliveryUploadMethod} from "../../marketplace/OrderLocationInput/consts.jsx";
import {getOrderPrice} from "../../utils/calculationUtils.jsx";
import {EditOrderView} from "../../orders/EditOrderView/EditOrderView.jsx";
import {isEmpty} from "lodash";
import {StringBuilder} from "../../AppUtils.js";
import {openWindow} from "../../http/WindowOpener.jsx";
import {EventBus} from "../../bus/EventBus.jsx";
import {validateOrderDisabledForEdit} from "../../admin/AdminOrders/utils.jsx";
import {isProductActive, isProductBundleActive} from "../../marketplace/MarketplaceUtils.jsx";
import {ContactPhone} from "../../support/ContactPhone.jsx";
import {isAllowedAndExistingCategory} from "../../center/EXCenterHelper.jsx";

export const priceSectionLines = [
    {
        title: "מחיר הזמנה",
        key: "offeredPrice",
        disabled: "offeredPrice"
    },
    {
        title: "מחיר משלוח",
        key: "deliveryPrice",
        disabled: "deliveryPrice"
    },
    {
        title: "הנחת קוד קופון",
        key: "couponDiscountAmount",
        disabled: "couponDiscountAmount",
        hideIfEmpty: true
    },
    {
        title: "הנחה כללית",
        key: "companyDiscountAmount",
        disabled: "companyDiscountAmount",
        hideIfEmpty: true
    },
    {
        title: "טיפ",
        key: "tip",
        disabled: "יש לעדכן טיפ דרך הטופס הזמנה",
        hideIfEmpty: true
    }
];

export const CompanyEditOrderPage = ({history, match}) => {
    const [notifyAboutOrderUpdateModalVisibility, setNotifyAboutOrderUpdateModalVisibility] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [updateOrder, setUpdateOrder] = useState({});
    const {me} = useContext(AppContext);
    const [orderChangesSentToApproveModalVisibility, setOrderChangesSentToApproveModalVisibility] = useState(false);

    const [order = {}, isLoading, fetchOrderError] = useRequest(
        `/api/events/${match?.params?.eventId}/orderInfo`,
        "GET",
        null,
        [],
        !!match?.params?.eventId
    );

    const [provider, isLoadingProvider] = useRequest(
        `/api/providers/${order?.providerId}`,
        "get",
        null,
        [order],
        !!order?.providerId
    );

    const onSaveOrder = useCallback(async () => {
        setNotifyAboutOrderUpdateModalVisibility(false);
        const {error, status} = await HttpClient.safePost(`/api/events/updateOrder?updatedAt=${order.updatedAt}`, {
            order: updateOrder
        });

        if (error) {
            if (status === 409) {
                message.error(error);
            } else {
                message.error("שמירת השינויים בהזמנה נכשלה");
            }

            console.error(error);
            return;
        }
        setOrderChangesSentToApproveModalVisibility(true);
    }, [formValues, updateOrder, order?.updatedAt]);

    const onEditOrderModalSave = useCallback(
        updatedOrder => {
            const validationEditOrder = validateOrderDisabledForEdit(order, false);

            if (validationEditOrder?.error) {
                message.error(validationEditOrder?.error);
                return;
            }

            const error = validateBooking(
                me,
                order.paymentType,
                formValues.offeredPrice,
                provider?.services.find(service => service.productId === order?.productId),
                {...formValues, chosenAddress: formValues.address},
                order.creditCard,
                order.company,
                formValues.deliveryPrice,
                formValues.couponDiscountAmount,
                formValues.companyDiscountAmount,
                getOrderPrice(order)
            );

            if (error) {
                message.error(error);
                return;
            }

            setUpdateOrder(updatedOrder);
            setNotifyAboutOrderUpdateModalVisibility(true);
        },
        [formValues, order, provider, me]
    );

    const isExcelHomeDelivery = useMemo(
        () =>
            order?.chosenActivityLocation === ActivityLocationTypes.homeDelivery &&
            order?.homeDeliveryUploadMethod === HomeDeliveryUploadMethod.excel,
        [order?.chosenActivityLocation, order?.homeDeliveryUploadMethod]
    );

    const onClickSummaryOrder = useCallback(() => {
        openWindow(`/events/${order?.eventId}/orderV2`);
    }, [order?.eventId]);

    const notAllowedForPage = useMemo(() => {
        if (fetchOrderError) {
            return {
                title: "יש בעיה עם הזמנה זו, אנא צור קשר מנהלת הלקוח",
                text: [fetchOrderError?.message]
            };
        }

        if (!isEmpty(order)) {
            const validation = validateOrderDisabledForEdit(order, false);
            if (validation?.error) {
                return {
                    title: "לא ניתן לערוך הזמנה זו",
                    text: [validation?.error]
                };
            }
        }

        return null;
    }, [fetchOrderError, order]);

    const goToMainPage = useCallback(() => {
        history.push("/dashboard/services");
    }, [history]);

    return (
        <div className="edit-order-container">
            {!isEmpty(order) && !notAllowedForPage && (
                <EditOrderView
                    onSave={onEditOrderModalSave}
                    order={order}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    priceSectionLines={priceSectionLines}
                    provider={provider}
                    isLoadingProvider={isLoadingProvider}
                    disabledFields={
                        isOrderHasShippingProvider(order) && isExcelHomeDelivery ? [OrderFormPanelKeys.Location] : []
                    }
                    onCancel={() => history.push(`/events/${order?.eventId}/orderV2`)}
                />
            )}

            <GenericModal
                title="האם אתם בטוחים שתרצו לשנות את ההזמנה"
                className="company-edit-order-sure-modal"
                text={["יתקבל עדכון עד 4 שעות מרגע השליחה"]}
                image={Images.areYouSure}
                closeable={true}
                visible={notifyAboutOrderUpdateModalVisibility}
                onClose={() => setNotifyAboutOrderUpdateModalVisibility(false)}
                options={[
                    {
                        id: "accept",
                        onClick: () => onSaveOrder(),
                        text: "כן, תשלחו"
                    },
                    {
                        id: "back",
                        onClick: () => setNotifyAboutOrderUpdateModalVisibility(false),
                        text: "לא, חזור עריכה"
                    }
                ]}
            />

            <GenericModal
                title={new StringBuilder("השינויים שביצעת נשלחו").append(`ל${provider?.businessName}`).toString()}
                className="company-edit-order-sure-page"
                text={["נעדכן אותך על אישור ההזמנה ברגע שבית העסק יאשר את השינויים"]}
                image={Images.handshake}
                closeable={true}
                visible={orderChangesSentToApproveModalVisibility}
                onClose={() => setOrderChangesSentToApproveModalVisibility(false)}
                options={[
                    {
                        id: "accept",
                        onClick: onClickSummaryOrder,
                        text: "לצפייה בהזמנה החדשה"
                    }
                ]}
            />

            {notAllowedForPage ? (
                <GenericModal
                    className="no-content-modal"
                    visible={!!notAllowedForPage}
                    image={Images.outOfStock}
                    title={notAllowedForPage?.title}
                    text={notAllowedForPage?.text ?? []}
                    options={[
                        {
                            id: "goToHomePage",
                            text: "חזרה לעמוד הראשי",
                            onClick: goToMainPage
                        }
                    ]}
                    closeable={false}
                    onClose={goToMainPage}
                />
            ) : null}
        </div>
    );
};
