import React, {useCallback, useContext, useMemo, useState} from "react";
import {withRouter} from "react-router";
import {inject, observer} from "mobx-react";
import {AppLogo} from "./AppLogo";
import AppMenu from "./AppMenu";
import {AppContext} from "./AppContext";
import {hasEXCenterFeatureOpen} from "./center/EXCenterHelper";
import {openWindow} from "./http/WindowOpener";
import {ADMIN_PATH_NAME} from "./admin/consts";
import {MarketplaceSearchHeader} from "./marketplace/MarketplaceSearchHeader";
import {getLogger} from "./Logger";
import "./dashboards/app-dashboard.css";
import {MarketplaceFilterProviderContext} from "./marketplace/MarketplaceFilter/MarketplaceFilterContext.jsx";
import "./app-top-bar.css";
import {CompanyBudgetLabel} from "./company/CompanyBudgetLabel.jsx";
import classnames from "classnames";
import {panelOptions} from "./marketplace/MarketplaceFilter/panel/consts.jsx";

export const COMPANY_ALL_BRANCHES = "All";
export const COMPANY_ADD_BRANCH = "Add branch";

const globalBranch = {
    branchId: COMPANY_ALL_BRANCHES,
    name: COMPANY_ALL_BRANCHES
};
const AppTopBar = inject("store")(
    observer(({pathname, store, history}) => {
        const {me, company, isolationMode} = useContext(AppContext);
        const [debouncedSearchText, setDebouncedSearchText] = useState(null);
        const {resetState, setPanel} = useContext(MarketplaceFilterProviderContext);

        const log = useMemo(() => getLogger({}, "AppTopBar"), []);

        const goToMain = () => {
            if (me && me.type === "employee" && hasEXCenterFeatureOpen(me, company)) {
                openWindow("/center", "_self", true);
            } else {
                history.push("/dashboard/services");
            }
        };

        const isDashboard = (withAdmin = true) => {
            const paths = ["/dashboard", "/marketplace"];
            if (withAdmin) {
                paths.push(`/${ADMIN_PATH_NAME}`);
            }
            return paths.some(path => pathname.indexOf(path) >= 0);
        };

        const shouldIncludeMarketplaceSearchInput = () => {
            if (isolationMode || me?.type !== "company") {
                return false;
            }

            const pathRegExps = [/\/selectionPage/, /\/marketplace/];
            return pathRegExps.some(pathReg => pathReg.test(pathname)) || isDashboard(false);
        };
        const isLoggedIn = () => {
            return (
                isDashboard() ||
                [
                    "/contact",
                    "/company/account",
                    "/company/profile",
                    "/company/insights",
                    "/company/orders",
                    "/provider/billing",
                    "/selectionPage",
                    "/happiness-calculator",
                    "/events"
                ].some(path => pathname.indexOf(path) >= 0)
            );
        };

        const shouldIncludeMenu = () => {
            return me.type && me.type !== "unauthorized" && isLoggedIn();
        };
        const isAllSitesAvailable = () => {
            return ["/dashboard/employees", "/company/insights"].some(path => pathname.indexOf(path) >= 0);
        };
        if (company && company.branches) {
            if (isAllSitesAvailable()) {
                if (!company.branches.find(branch => branch.branchId === COMPANY_ALL_BRANCHES)) {
                    company.branches.push(globalBranch);
                }
            } else {
                company.branches = company.branches.filter(branch => branch.branchId !== COMPANY_ALL_BRANCHES);
            }
        }

        const onAppLogoClick = useCallback(() => {
            setPanel(panelOptions.homePage);
            resetState();
            const destinationPath = isolationMode?.selectPageId
                ? `/selectionPage/${isolationMode.selectPageId}`
                : "/dashboard/services";

            const currentPathname = history?.location?.pathname;
            const cleanedCurrentPathname = currentPathname.replace(/\/$/, "");

            if (cleanedCurrentPathname !== destinationPath) {
                history.push(destinationPath);
            }
        }, [isolationMode, history, resetState, setPanel]);

        const isAdminDashboard = useMemo(() => {
            return history?.location.pathname.indexOf(ADMIN_PATH_NAME) >= 0;
        }, [history?.location]);

        return (
            isLoggedIn() && (
                <div className={classnames("app-top-bar-container","no-print")} id="app-top-bar">
                    <div className={classnames("app-top-bar-wrapper")}>
                        <div className="logo-and-budget-container">
                            <div id="app-logo">
                                <AppLogo onClick={onAppLogoClick} />
                            </div>

                            {!isAdminDashboard && me?.type === "company" ? (
                                <div className="app-budget-container" id="app-budget">
                                    <CompanyBudgetLabel />
                                </div>
                            ) : null}

                            {!isAdminDashboard && !me?.isCompanySubAdmin && me?.type === "company" ? (
                                <div className="app-budget-container" id="app-budget">
                                    <CompanyBudgetLabel forceHideLoadBudgetButton={true} />
                                </div>
                            ) : null}
                        </div>

                        {pathname && shouldIncludeMarketplaceSearchInput() ? (
                            <MarketplaceSearchHeader
                                history={history}
                                log={log}
                                debouncedSearchText={debouncedSearchText}
                                setDebouncedSearchText={setDebouncedSearchText}
                            />
                        ) : null}

                        {shouldIncludeMenu() ? (
                            <div className="app-menu-container" id="app-menu">
                                <AppMenu isDashboard={isDashboard()} />
                            </div>
                        ) : null}
                    </div>
                </div>
            )
        );
    })
);

export default withRouter(AppTopBar);
