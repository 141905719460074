export const LocationLabels = {
    ONLINE: "online",
    ONSITE: "onsite",
    ONSITE_AND_ONLINE: "onsite+online",
    OFFSITE: "offsite"
};

export const locationKeyToTextMap = {
    [LocationLabels.ONSITE]: "At the office",
    [LocationLabels.ONLINE]: "Online session",
    [LocationLabels.ONSITE_AND_ONLINE]: "Office / Online",
    [LocationLabels.OFFSITE]: "Offsite"
};
