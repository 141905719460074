import React, {useCallback, useMemo} from "react";
import {ConfigProvider, Radio} from "antd";
import "./radio-group-options.css";

export const RadioGroupOptions = ({options, value, onChange}) => {
    const onChooseOption = useCallback(
        selectedOption => {
            onChange(selectedOption);
        },
        [onChange]
    );

    const showComponent = useMemo(() => {
        const component = options.find(option => option.key === value);
        if (component) {
            return component.component;
        }
    }, [value, options]);

    const onOptionClick = useCallback(
        option => {
            if (value === option.key) {
                onChooseOption(null);
            }
        },
        [value, onChooseOption]
    );

    return (
        <div id="radio-group-options">
            <ConfigProvider direction="rtl">
                <div>
                    <Radio.Group value={value} onChange={event => onChooseOption(event.target.value)}>
                        {options.map((option, index) => {
                            return (
                                <div key={index}>
                                    <Radio
                                        onClick={() => onOptionClick(option)}
                                        className="option-component-text"
                                        key={index}
                                        value={option.key}>
                                        <span className="radio-group-option-text">{option.text}</span>
                                    </Radio>

                                    {value === option.key && <div className="option-component">{showComponent}</div>}
                                </div>
                            );
                        })}
                    </Radio.Group>
                </div>
            </ConfigProvider>
        </div>
    );
};
