import React, {useCallback, useMemo, useRef, useState} from "react";
import {Button, Input, message, Progress, Upload} from "antd";
import TextArea from "antd/es/input/TextArea";
import {ConfirmationRequiredWrapper, FormModal, StyledButton} from "../components";
import {HttpClient} from "../http/HttpClient";
import {ExportTableToExcel} from "../orders/tempOrderExcelExporter";
import "./admin-debug.css";
import {ExportTableToProviderExcel} from "../orders/orderExcelProviderExporter";
import {ArrowUpOutlined} from "@ant-design/icons";
import {NOOP} from "../utils/NOOP.jsx";
import {getCellText, loadExcel} from "../utils/excelUtils.js";
import common from "@well-b/well-b-shared";
import {StringBuilder} from "../AppUtils.js";

const tryParseJSONObject = jsonString => {
    try {
        const o = JSON.parse(jsonString);

        if (o && typeof o === "object") {
            return o;
        }
    } catch (e) {}

    return false;
};

const apiKey = "0cc175b9c0f1b6a831c399e269772661";

export const AdminDebug = () => {
    const [requestResult, setRequestResult] = useState();
    const [progress, setProgress] = useState();
    const updateCollectionRef = useRef(null);
    const updateIdRef = useRef(null);
    const newValueRef = useRef(null);
    const [customWishesVisible, setCustomWishesVisible] = useState(false);
    const [redisRemoveKeysByPatternModal, setRedisRemoveKeysByPatternModal] = useState(false);
    const [allowedCompaniesModal, setAllowedCompaniesModal] = useState(false);
    const [providerExcelModal, setProviderExcelModal] = useState(false);

    const updateSubCategories = useCallback(async () => {
        const result = await HttpClient.get("/api/script/updateSubCategoriesIds_script");
        setRequestResult(result);
    }, []);

    const getIAIExcels = useCallback(async () => {
        const res = await HttpClient.safeGet("/admin/api/IAISouth");
        const {southOrders, nonSouthOrders, errorOrders} = res;

        await ExportTableToExcel(southOrders, nonSouthOrders, errorOrders);
        console.log(res);
    }, []);

    const getAddress = useCallback(async () => {
        const companiesStats = await HttpClient.get("/admin/api/statistics/getAllCompaniesOrderStatistics");
        const companies = await HttpClient.post("/admin/api/companies/byIds", Object.keys(companiesStats));

        let count = 0;
        const errorsAddress = [];

        for (const company of companies) {
            const result = await HttpClient.safeGet(`/api/location/address/${company.address}`);

            if (result?.place_id) {
                await HttpClient.post("/admin/api/companies/update", {
                    companyId: company.companyId,
                    addressId: result.place_id
                });
            } else {
                errorsAddress.push(`${company.companyId}-${company.name}-${company.address}`);
            }

            count++;
            setProgress((count / companies.length) * 100);
        }

        setRequestResult(errorsAddress.join(", "));
    }, []);

    const updateItem = useCallback(async () => {
        const newValue = tryParseJSONObject(newValueRef.current.resizableTextArea.textArea.value);

        if (!newValue) {
            message.error("invalid json body");
            return;
        }

        const body = {
            collection: updateCollectionRef.current.input.value.trim(),
            itemId: updateIdRef.current.input.value.trim(),
            newValue: newValue
        };

        const res = await HttpClient.safePost("/admin/api/database/set", body);

        setRequestResult(res);
    }, [updateCollectionRef, updateIdRef, newValueRef]);

    const updateProductsCategory = useCallback(async () => {
        const products = []; //await HttpClient.get("/api/products/getProducts");

        for (const product of products) {
            const {provider, productId, ...restProduct} = product;

            if (!product.services?.length) continue;
            if (typeof product?.services?.[0] == "string") continue;

            const migratedServices = product.services.map(service => service.serviceId);

            const {error} = await HttpClient.safePut(
                `/admin/api/provider/${product?.providerId}/products/${productId}`,
                {
                    product: {...restProduct, services: migratedServices, productId}
                }
            );

            if (error)
                console.log(
                    `[X] error updating product ${productId} from provider ${product?.providerId} with error ${error}`
                );
            else console.log(`product ${productId} from provider ${product?.providerId} updated`);

            // console.log(`product ${productId} from provider ${product?.providerId} updated`, product.services);
        }
    }, []);

    const massUpdateDepartment = useCallback(async () => {
        await HttpClient.safeGet("/admin/api/script/massUpdateDepartment");
    }, []);

    const switchProvidersPaymentType = useCallback(async isShouldPaymentBySupply => {
        const {error} = await HttpClient.safeGet(
            `/api/script/switchProvidersPaymentType?isShouldPaymentBySupply=${isShouldPaymentBySupply}&apiKey=${apiKey}`
        );

        if (error) message.error("הפעולה נכשלה, אנא בדוק את הקונסול");
        else message.success("הפעולה בוצעה בהצלחה");
    }, []);

    const providerAdressMigrationScript = useCallback(async () => {
        const result = await HttpClient.get("/api/script/providerAdressMigrationScript");
        console.log(result);
    }, []);

    const sendErrorPing = useCallback(async () => {
        const result = await HttpClient.get("/api/error/ping/fetch");
    }, []);

    const excelUploadProps = useMemo(
        () => ({
            customRequest: NOOP,
            action: async file => {
                return new Promise(async (resolve, reject) => {
                    const fileReader = new FileReader();
                    fileReader.onload = async data => {
                        const arrayBuffer = data.target.result;
                        const workbook = await loadExcel(arrayBuffer, file.type);
                        const sheet = workbook.worksheets[0];

                        const results = [];

                        sheet.eachRow(({values: row}) => {
                            console.log(row);
                            results.push({
                                Cli: getCellText(row[5])?.toString(),
                                Text: new StringBuilder()
                                    .append(getCellText(row[13]))
                                    .append("(משלוח קטן בדרך אליך)")
                                    .toString()
                            });
                        });

                        const cleanResults = results.filter(({Cli}) =>
                            common.isValidPhoneMobileNumber(common.sanitizePhone(Cli))
                        );

                        console.log(cleanResults);
                        return resolve();
                    };

                    fileReader.onerror = () => {
                        console.error("ERROR on load excel");
                    };

                    fileReader.readAsArrayBuffer(file);
                });
            },
            name: "file",
            accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            showUploadList: false,
            withCredentials: true
        }),
        []
    );

    return (
        <div>
            <button
                onClick={() => {
                    throw new Error("This is your first error!");
                }}>
                Sentry Error Trigger
            </button>
            <TextArea value={requestResult} />
            <Progress percent={progress} />
            <StyledButton onClick={getAddress}>Update address DB</StyledButton>
            <div className="admin-debug-set-item-form">
                <div className="admin-debug-set-item-input">
                    <span>collection name</span>
                    <Input ref={updateCollectionRef} />
                </div>
                <div className="admin-debug-set-item-input">
                    <span>Item id</span>
                    <Input ref={updateIdRef} />
                </div>
                <span>Item json</span>
                <TextArea ref={newValueRef} />
                <div>
                    <ConfirmationRequiredWrapper onYes={updateItem}>
                        <Button danger={true}>Set item in db</Button>
                    </ConfirmationRequiredWrapper>
                </div>
                <StyledButton onClick={updateSubCategories}>/api/script/updateSubCategoriesIds_script</StyledButton>
            </div>

            <div style={{marginTop: "20px"}}>
                <p>get IAI excels</p>
                <StyledButton onClick={getIAIExcels}>get IaI excels</StyledButton>
            </div>

            <div style={{marginTop: "20px"}}>
                <p>Providers Products Category migration</p>
                <StyledButton onClick={updateProductsCategory}>migrate Products Category</StyledButton>
            </div>

            <div style={{marginTop: "20px"}}>
                <p>massUpdateDepartment</p>
                <StyledButton onClick={massUpdateDepartment}>Providers subProducts</StyledButton>
            </div>

            <div style={{marginTop: "20px"}}>
                <p>Change all companies to pay by order dtstart/createdAt</p>
                <div style={{display: "flex"}}>
                    <StyledButton onClick={() => switchProvidersPaymentType(false)}>Pay by createdAt</StyledButton>
                    <div style={{width: "10px"}}></div>
                    <StyledButton onClick={() => switchProvidersPaymentType(true)}>Pay by dtstart</StyledButton>
                </div>
            </div>
            <div style={{marginTop: "20px"}}>
                <p>Provider adress migration</p>
                <div style={{display: "flex"}}>
                    <StyledButton onClick={providerAdressMigrationScript}>Provider adress migration</StyledButton>
                </div>
            </div>

            <div style={{marginTop: "20px"}}>
                <p>ping error</p>

                <StyledButton danger={true} onClick={sendErrorPing}>
                    Error Ping
                </StyledButton>
            </div>

            <div style={{marginTop: "20px"}}>
                <p>Add custom wish</p>

                <StyledButton onClick={() => setCustomWishesVisible(true)}>Open modal</StyledButton>

                <FormModal
                    visible={customWishesVisible}
                    onSave={async ({companyId, title, text}) => {
                        const {error} = await HttpClient.safePost(`/api/script/companyAddWishes`, {
                            companyId,
                            title,
                            text
                        });

                        if (error) message.error("הפעולה נכשלה, אנא בדוק את הקונסול");
                        else message.success("הפעולה בוצעה בהצלחה");
                    }}
                    onClose={() => {
                        setCustomWishesVisible(false);
                    }}
                    header={"הוספת ברכה לחברה 🙋‍️"}
                    subTitle={"במידה וברצונכם להוסיף ברכה מותאמת אישית לחברה, אנא מלאו את הפרטים הבאים"}
                    fields={[
                        {
                            name: "companyId",
                            label: "מזהה חברה"
                        },
                        {
                            name: "title",
                            label: "כותרת"
                        },
                        {
                            name: "text",
                            label: "ברכה (עד 100 תווים)",
                            type: "textarea",
                            minRows: 2,
                            maxRows: 10,
                            maxLength: 100
                        }
                    ]}
                />
            </div>

            <div style={{marginTop: "20px"}}>
                <p>Remove Redis Keys by pattern</p>

                <StyledButton onClick={() => setRedisRemoveKeysByPatternModal(true)}>Open modal</StyledButton>

                <FormModal
                    visible={redisRemoveKeysByPatternModal}
                    onSave={async ({pattern}) => {
                        const {error} = await HttpClient.safePost("/admin/api/redis/removeKeysByPattern", {
                            pattern
                        });

                        if (error) {
                            message.error("הפעולה נכשלה, אנא בדוק את הקונסול");
                            console.error(error);
                        } else {
                            message.success("הפעולה בוצעה בהצלחה");
                        }
                    }}
                    onClose={() => {
                        setRedisRemoveKeysByPatternModal(false);
                    }}
                    header={"Remove Redis Key By Pattern"}
                    fields={[
                        {
                            name: "pattern",
                            label: "pattern"
                        }
                    ]}
                />
            </div>

            <div style={{marginTop: "20px"}}>
                <p>Add company to allowedCompanies</p>

                <StyledButton onClick={() => setAllowedCompaniesModal(true)}>Open modal</StyledButton>

                <FormModal
                    visible={allowedCompaniesModal}
                    onSave={async ({providerId, companyId}) => {
                        const {error} = await HttpClient.safePost("/admin/api/debug/addCompanyToAllowedCompanies", {
                            providerId,
                            companyId
                        });

                        if (error) {
                            message.error("הפעולה נכשלה, אנא בדוק את הקונסול");
                            console.error(error);
                        } else {
                            message.success("הפעולה בוצעה בהצלחה");
                        }
                    }}
                    onClose={() => {
                        setAllowedCompaniesModal(false);
                    }}
                    header={"Add company to allowedCompanies"}
                    fields={[
                        {
                            name: "providerId",
                            label: "providerId"
                        },
                        {
                            name: "companyId",
                            label: "companyId"
                        }
                    ]}
                />
            </div>

            <StyledButton
                onClick={async () => {
                    console.log("generate delivery provider");
                    await HttpClient.safePost("/admin/api/debug/deliveryProviderHealthCheck");
                }}>
                Generate Delivery Provider
            </StyledButton>

            <div style={{marginTop: "20px"}}>
                <p>Provider Excel</p>

                <StyledButton onClick={() => setProviderExcelModal(true)}>Provider Excel</StyledButton>

                <FormModal
                    visible={providerExcelModal}
                    onSave={async ({providerId}) => {
                        const orders = await HttpClient.safeGet(`/admin/api/events/getProviderOrders/${providerId}`);
                        const systemUsers = await HttpClient.safeGet("/admin/api/users/systemStaffUsers");
                        const providers = await HttpClient.safePost("/admin/api/vendors/byIds", {
                            providerIds: [providerId],
                            projection: {provider: ["dealerType"]}
                        });
                        await ExportTableToProviderExcel(orders, systemUsers, providers);
                    }}
                    onClose={() => {
                        setProviderExcelModal(false);
                    }}
                    header={"ייצוא אקסל לספקים"}
                    fields={[
                        {
                            name: "providerId",
                            label: "providerId"
                        }
                    ]}
                />
            </div>

            <div style={{marginTop: "20px"}}>
                <p>Send Multiple SMS To different numbers</p>

                <Upload {...excelUploadProps}>
                    <StyledButton>
                        <span className="admin-upload-new-product-delivery-areas-upload-excel">
                            <ArrowUpOutlined />
                            טעינת אקסל
                        </span>
                    </StyledButton>
                </Upload>
            </div>
        </div>
    );
};
