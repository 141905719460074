import React from "react";
import {Modal} from "antd";
import {PageTitleHeader} from "./index";
import "./simple-modal.css";
import classnames from "classnames";

export const GenericModal = ({
    visible,
    onClose,
    options = [],
    image,
    title,
    text = [],
    className = "",
    closeable = true,
    showBackButton = false,
    width = 500
}) => (
    <Modal
        centered={true}
        width={width}
        className="wb-modal-radius simple-modal-container"
        style={{borderRadius: "5px"}}
        destroyOnClose={true}
        open={visible}
        onCancel={() => onClose()}
        maskClosable={closeable}
        footer={null}
        title={null}
        closable={closeable}>
        <div className={"simple-modal " + className}>
            {!!image && <img src={image} alt="modal image" className="simple-modal-image" />}
            <PageTitleHeader className="simple-modal-title" showBack={showBackButton}>
                {title}
            </PageTitleHeader>
            <div className="simple-modal-break-line" />
            <div className="simple-modal-text-container">
                {text.map((paragraph, index) => (
                    <p className="simple-modal-text" id={`simpleModalText${index}`} key={index}>
                        {paragraph}
                    </p>
                ))}
            </div>
            <div className="simple-modal-buttons-container">
                {options.filter(Boolean).map(({id, text, onClick, disabled}) => (
                    <button
                        id={id}
                        disabled={disabled}
                        key={id}
                        className={classnames("simple-modal-button")}
                        onClick={onClick}>
                        {text}
                    </button>
                ))}
            </div>
        </div>
    </Modal>
);
