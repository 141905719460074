import {GoogleButton} from "./GoogleButton";
import React, {useState} from "react";
import {HttpClient} from "../http/HttpClient";
import {message} from "antd";

export const GoogleAuthButton = ({scopes, onSuccess, onFailure, style, children, ctx}) => {
    const [loading, setLoading] = useState(false);

    const signIn = async () => {
        try {
            const info = await window.googleAuth2.grantOfflineAccess({
                scope: scopes.join(" "),
                prompt: "select_account"
            });
            setLoading(true);
            const user = await HttpClient.post("/api/google/auth", {info, requiredScopes: scopes, ctx}, true);
            onSuccess(user);
        } catch (e) {
            let failure = true;
            if (e.error && (e.error === "popup_closed_by_user" || e.error === "access_denied")) {
                failure = false;
            }

            if (failure && e.statusCode === 400) {
                message.error(e.message, 5);
            } else if (failure) {
                onFailure(e.error || e.message || e);
            }
        }

        setLoading(false);
    };

    return (
        <GoogleButton onClick={signIn} style={style} loading={loading}>
            {children}
        </GoogleButton>
    );
};
