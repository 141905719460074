import React, {useCallback, useMemo} from "react";
import {Switch} from "antd";
import {findIndex, flatten, groupBy} from "lodash";
import {CaretDownOutlined, CaretUpOutlined} from "@ant-design/icons";
import {DndContext, closestCenter} from "@dnd-kit/core";
import {CSS} from "@dnd-kit/utilities";
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {Counter} from "../../components/Counter";

export function MultipleSubProductsCategory({
    subProducts,
    providerSubProducts,
    updateSubProductsOrder,
    onChangeSubProductProperty
}) {
    const subProductCategoryToSubProduct = useMemo(() => {
        const subProductsToCategory = groupBy(subProducts, subProduct => subProduct?.subProductCategory);
        return Object.entries(subProductsToCategory);
    }, [subProducts]);

    const SortableItem = useCallback(({id, children}) => {
        const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id});

        const style = {
            transform: CSS.Transform.toString(transform),
            transition
        };

        return (
            <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
                {children}
            </div>
        );
    }, []);

    const handleDragEnd = useCallback(
        (event, selectedCategory) => {
            const {active, over} = event;

            if (active.id !== over.id) {
                const newSubProductsOrder = subProductCategoryToSubProduct.reduce(
                    (acc, [category, subProducts = []]) => {
                        if (selectedCategory === category) {
                            const activeIndex = findIndex(subProducts, subProduct => subProduct.id === active.id);
                            const overIndex = findIndex(subProducts, subProduct => subProduct.id === over.id);
                            acc.push(...(arrayMove(subProducts, activeIndex, overIndex) ?? []));
                            return acc;
                        }

                        acc.push(...subProducts);
                        return acc;
                    },
                    []
                );

                updateSubProductsOrder(newSubProductsOrder);
            }
        },
        [subProductCategoryToSubProduct]
    );

    const moveCategoryDown = useCallback((subProductCategoryToSubProduct, categoryName) => {
        const index = findIndex(subProductCategoryToSubProduct, ([firstElement]) => firstElement === categoryName);

        if (index < 0 || index >= subProductCategoryToSubProduct.length - 1) {
            return;
        }

        [subProductCategoryToSubProduct[index], subProductCategoryToSubProduct[index + 1]] = [
            subProductCategoryToSubProduct[index + 1],
            subProductCategoryToSubProduct[index]
        ];
        flattenSubProductsAndUpdate(subProductCategoryToSubProduct);
    }, []);

    const moveCategoryUp = useCallback((subProductCategoryToSubProduct, categoryName) => {
        const index = findIndex(subProductCategoryToSubProduct, ([firstElement]) => firstElement === categoryName);

        if (index <= 0 || index >= subProductCategoryToSubProduct.length) {
            return;
        }

        [subProductCategoryToSubProduct[index - 1], subProductCategoryToSubProduct[index]] = [
            subProductCategoryToSubProduct[index],
            subProductCategoryToSubProduct[index - 1]
        ];
        flattenSubProductsAndUpdate(subProductCategoryToSubProduct);
    }, []);

    const flattenSubProductsAndUpdate = useCallback(subProductCategoryToSubProduct => {
        const newCategorySubProductsOrder = flatten(
            subProductCategoryToSubProduct.map(([, subProducts]) => subProducts)
        );
        updateSubProductsOrder(newCategorySubProductsOrder);
    }, []);

    return (
        <>
            {subProductCategoryToSubProduct.map(([categoryName, subProducts]) => {
                return (
                    <div key="categoryName">
                        <div className="provider-price-by-modular-category-container">
                            <span>{categoryName === "undefined" ? "כללי" : categoryName}</span>

                            <div className="provider-price-by-modular-category-actions">
                                <CaretUpOutlined
                                    style={{fontSize: "30px"}}
                                    onClick={() => moveCategoryUp(subProductCategoryToSubProduct, categoryName)}
                                />

                                <CaretDownOutlined
                                    style={{fontSize: "30px"}}
                                    onClick={() => moveCategoryDown(subProductCategoryToSubProduct, categoryName)}
                                />
                            </div>
                        </div>

                        <DndContext
                            collisionDetection={closestCenter}
                            onDragEnd={dragEndEvent => handleDragEnd(dragEndEvent, categoryName)}>
                            <SortableContext items={subProducts} strategy={verticalListSortingStrategy}>
                                {subProducts.map(subProduct => {
                                    const foundProviderSubProduct =
                                        providerSubProducts?.find(
                                            providerSubProduct => providerSubProduct.id === subProduct.id
                                        ) ?? {};
                                    const isFoundProviderSubProductActive = foundProviderSubProduct?.isActive;

                                    return (
                                        <div
                                            id={"subproducts_" + subProduct?.id}
                                            key={subProduct?.id}
                                            className="provider-price-by-modular-container">
                                            <SortableItem key={subProduct.id} id={subProduct.id} name={subProduct.name}>
                                                <div className="provider-price-by-modular-content">
                                                    <div className="provider-price-by-modular-item-title">
                                                        {subProduct?.name}
                                                    </div>
                                                    <div className="provider-price-by-modular-item-description">
                                                        {subProduct?.description}
                                                    </div>
                                                </div>
                                            </SortableItem>

                                            <div className="provider-price-by-modular-content-price">
                                                <div className="provider-price-by-modular-item-price">
                                                    <span>{Number(subProduct?.price).toLocaleString()}</span>
                                                    <span> ₪ </span>
                                                    <span> ליחידה </span>
                                                </div>

                                                <div>
                                                    <Counter
                                                        value={subProduct?.defaultAmount ?? 0}
                                                        onChange={defaultAmount => {
                                                            onChangeSubProductProperty(
                                                                subProduct.id,
                                                                "defaultAmount",
                                                                defaultAmount
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="provider-price-by-modular-content-isActive">
                                                <Switch
                                                    checked={!!subProduct.isActive}
                                                    checkedChildren={"פעיל"}
                                                    style={{
                                                        backgroundColor: !isFoundProviderSubProductActive ? "red" : null
                                                    }}
                                                    unCheckedChildren={"לא פעיל"}
                                                    onClick={isActive =>
                                                        onChangeSubProductProperty(subProduct.id, "isActive", isActive)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </SortableContext>
                        </DndContext>
                    </div>
                );
            })}
        </>
    );
}
