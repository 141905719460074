import React from "react";
import moment from "moment-timezone";
import {TableDoubleValueCell} from "./TableDoubleValueCell";
import "./table-double-value-cell.css";

export const TableDateCell = ({date, endDate}) => {
    return endDate ? (
        !moment(date).isSame(moment(endDate), "day") ? (
            <div className="table-date-cell-different-end-day">
                <span>
                    <span>{moment(date).format("HH:mm")}</span>
                    <span>{moment(date).format("DD-MM-YY")}</span>
                </span>
                <span>
                    <span>{moment(endDate).format("HH:mm")}</span>
                    <span>{moment(endDate).format("DD-MM-YY")}</span>
                </span>
            </div>
        ) : (
            <TableDoubleValueCell>
                {moment(date).format("DD-MM-YYYY")}
                <>
                    {moment(endDate).format("HH:mm")}-{moment(date).format("HH:mm")}
                </>
            </TableDoubleValueCell>
        )
    ) : (
        <TableDoubleValueCell>
            {moment(date).format("DD-MM-YYYY")}
            {moment(date).format("HH:mm")}
        </TableDoubleValueCell>
    );
};
