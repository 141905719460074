import React, {useCallback, useEffect, useState} from "react";
import {Checkbox, Modal} from "antd";
import {Images} from "../../images/Images";
import {PageTitleHeader} from "../../components";
import "./notify-about-order-update-modal.css";
import {editOrderNotificationsOption, editOrderNotificationsReceivers} from "./consts.jsx";

const {companyWizardSuccess} = Images;

export const NotifyAboutAdminOrderUpdateModal = ({visible, onClose, onSave}) => {
    const [whoToSendUpdateEmail, setWhoToSendUpdateEmail] = useState([
        editOrderNotificationsReceivers.provider,
        editOrderNotificationsReceivers.company
    ]);

    const onFinish = useCallback(() => {
        onSave(whoToSendUpdateEmail);
        setWhoToSendUpdateEmail([editOrderNotificationsReceivers.provider, editOrderNotificationsReceivers.company]);
    }, [onSave, setWhoToSendUpdateEmail, whoToSendUpdateEmail]);

    return (
        <Modal
            centered={true}
            width={500}
            height={562}
            className="wb-modal-radius wb-modal-borderless notify-about-order-update-modal"
            style={{borderRadius: "5px"}}
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            footer={null}
            title={null}
            closable={true}>
            <img src={companyWizardSuccess} alt="modal image" />
            <PageTitleHeader className="notify-about-order-update-modal-header" showBack={false}>
                האם לשלוח מייל עידכון?
            </PageTitleHeader>
            <div className="simple-modal-text-container"></div>
            <div className="notify-about-order-update-modal-checkbox-break-line" />
            <Checkbox.Group
                onChange={setWhoToSendUpdateEmail}
                className="notify-about-order-update-modal-checkboxes-container"
                options={editOrderNotificationsOption}
                defaultValue={whoToSendUpdateEmail}
            />
            <div onClick={onFinish} className="notify-about-order-update-modal-send">
                שליחה
            </div>
        </Modal>
    );
};
