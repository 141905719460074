import React, {useContext} from "react";
import EXCenterInfoCard from "./EXCenterInfoCard";
import {take} from "lodash";
import {EXCenterContext} from "../EXCenterContext";
import {EXCenterSectionContainer} from "./EXCenterSectionContainer";

const ITEMS_TO_SHOW = 3;

export const EXCenterInfoSection = ({title, displayName, sectionId}) => {
    let {homeContents, designInfoPerSection} = useContext(EXCenterContext);

    if (homeContents) {
        homeContents = homeContents.filter(c => c.sectionId === sectionId);
    }

    if (!Array.isArray(homeContents) || homeContents.length === 0) {
        return null;
    }

    const sectionDesignInfo = designInfoPerSection[sectionId];

    return (
        <EXCenterSectionContainer
            sectionId={sectionId}
            sectionDesignInfo={sectionDesignInfo}
            editItems={{
                coverPhoto: true,
                description: true
            }}
            coverSize={{width: 1440, height: 420}}>
            <div style={{display: "flex", justifyContent: "center", flexWrap: "wrap", width: "100%"}}>
                {take(homeContents, ITEMS_TO_SHOW).map(item => (
                    <EXCenterInfoCard key={item.eventId} item={item} />
                ))}
            </div>
        </EXCenterSectionContainer>
    );
};
