import {useContext, useEffect, useState} from "react";
import {AppContext} from "../AppContext";
import {PageSubTitleLabel, PageTitleLabel} from "../components/PageTitle";
import {Button, Form, message, Modal} from "antd";
import {StyledButton} from "../components/StyledButton";
import {CloseIcon, CreditCardIcon} from "../icons";
import React from "react";
import {EventBus} from "../bus/EventBus";
import {HttpClient} from "../http/HttpClient";
import moment from "moment-timezone";
import {FormInput} from "../components/form";
import {RoundedTable} from "../components/RoundedTable";
import {openWindow} from "../http/WindowOpener";

const SHOW_BILLING_HISTORY_BUTTON = false;

const BillingInfoForm = ({initialValues, onSave, saving}) => {
    const [currentValues, setCurrentValues] = useState(initialValues);
    return (
        <Form
            onFinish={onSave}
            fields={Object.keys(currentValues).map(field => {
                return {
                    name: [field],
                    value: currentValues[field]
                };
            })}
            onValuesChange={(_, allValues) => {
                setCurrentValues(allValues);
            }}>
            <Form.Item
                name="client_name"
                rules={[
                    {
                        required: true,
                        message: "Please enter customer name."
                    }
                ]}>
                <FormInput placeholder="Customer name" />
            </Form.Item>
            <Form.Item
                name="email"
                rules={[
                    {
                        required: true,
                        message: "Please enter billing email."
                    },
                    {
                        type: "email",
                        message: "Please enter valid billing email."
                    }
                ]}>
                <FormInput placeholder="Billing email" />
            </Form.Item>
            <div style={{display: "flex"}}>
                <Form.Item
                    name="bus_country"
                    rules={[
                        {
                            required: true,
                            message: "Please enter billing country."
                        }
                    ]}>
                    <FormInput placeholder="Billing country" />
                </Form.Item>
                <Form.Item
                    style={{marginLeft: 10}}
                    name="bus_city"
                    rules={[
                        {
                            required: true,
                            message: "Please enter billing city."
                        }
                    ]}>
                    <FormInput placeholder="Billing city" />
                </Form.Item>
            </div>
            <div style={{display: "flex"}}>
                <Form.Item
                    name="bus_street"
                    rules={[
                        {
                            required: true,
                            message: "Please enter billing address."
                        }
                    ]}>
                    <FormInput placeholder="Billing address" />
                </Form.Item>
                <Form.Item
                    style={{marginLeft: 10}}
                    name="bus_zip"
                    rules={[
                        {
                            required: true,
                            message: "Please enter billing zip code."
                        }
                    ]}>
                    <FormInput placeholder="Billing zip code" />
                </Form.Item>
            </div>
            <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                <Form.Item style={{marginBottom: 0}}>
                    <StyledButton
                        htmlType="submit"
                        loading={saving}
                        style={{color: "white", backgroundColor: "#13C296", width: 145}}>
                        Save info
                    </StyledButton>
                </Form.Item>
            </div>
        </Form>
    );
};

const BillingInfoModal = ({billingInfo, onClose, visible}) => {
    const {me} = useContext(AppContext);
    const [saving, setSaving] = useState(false);

    const closeMe = () => {
        onClose(billingInfo);
    };

    const saveChanges = async values => {
        setSaving(true);
        try {
            const info = {...billingInfo, ...values};
            await HttpClient.post(`/api/billing/companies/${me.companyId}/info`, info);
            onClose(info);
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setSaving(false);
    };

    return (
        <Modal
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={closeMe}
            footer={null}
            title={null}
            closable={true}
            closeIcon={<CloseIcon fill="#767F90" />}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <PageTitleLabel style={{fontSize: 20, fontWeight: 400, marginBottom: 30}}>
                    Billing information
                </PageTitleLabel>
                {visible ? <BillingInfoForm onSave={saveChanges} initialValues={billingInfo} saving={saving} /> : null}
            </div>
        </Modal>
    );
};

const historyColumns = [
    {
        title: "DATE",
        dataIndex: "date",
        key: "date",
        width: 140,
        render(date) {
            return moment(date, "YYYY-MM").format("MMMM, YYYY");
        }
    },
    {
        title: "DOWNLOAD",
        dataIndex: "url",
        key: "url",
        width: 140,
        render(url) {
            return (
                <Button
                    type="link"
                    style={{padding: 0, textAlign: "left"}}
                    onClick={() => openWindow(url, "_blank", true)}>
                    Download
                </Button>
            );
        }
    }
];

const BillingHistoryModal = ({billingInfo, onClose, visible}) => {
    const invoices = billingInfo.ownInvoices
        ? Object.keys(billingInfo.ownInvoices).map(date => ({
              date,
              url: billingInfo.ownInvoices[date].url
          }))
        : [];

    return (
        <Modal
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            footer={null}
            title={null}
            closable={true}
            closeIcon={<CloseIcon fill="#767F90" />}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", minHeight: 300}}>
                <PageTitleLabel style={{fontSize: 20, fontWeight: 400, marginBottom: 30}}>
                    Billing invoices
                </PageTitleLabel>
                {invoices.length === 0 ? (
                    <PageSubTitleLabel style={{maxHeight: 30}}>No invoices yet.</PageSubTitleLabel>
                ) : (
                    <RoundedTable
                        scroll={{x: "fit-content", y: 600}}
                        pagination={false}
                        dataSource={invoices}
                        columns={historyColumns}
                    />
                )}
            </div>
        </Modal>
    );
};

export const CompanySubscriptionSettings = ({visible}) => {
    const {me} = useContext(AppContext);
    const [showBillingInfoModal, setShowBillingInfoModal] = useState(false);
    const [showBillingHistoryModal, setShowBillingHistoryModal] = useState(false);
    const [billingInfo, setBillingInfo] = useState({
        totalSubscription: 0
    });

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const billing = await HttpClient.get(`/api/billing/companies/${me.companyId}`);
                if (billing && typeof billing.totalSubscription === "number") {
                    setBillingInfo(billing);
                }
            } catch (e) {
                message.error("Something went wrong, please try again later.", 5);
            }
        });
    }, []);

    const closeBillingInfoModal = billing => {
        setBillingInfo(billing);
        setShowBillingInfoModal(false);
    };

    const nextDate = moment().add(1, "months");
    const nextMonth = nextDate.format("MMMM");
    const nextYear = nextDate.format("YYYY");

    return (
        <div style={{display: visible ? "flex" : "none", alignItems: "center", flexDirection: "column"}}>
            <BillingInfoModal
                onClose={billing => closeBillingInfoModal(billing)}
                billingInfo={billingInfo}
                visible={showBillingInfoModal}
            />
            <BillingHistoryModal
                onClose={() => setShowBillingHistoryModal(false)}
                billingInfo={billingInfo}
                visible={showBillingHistoryModal}
            />
            <div style={{width: "100%"}}>
                <PageTitleLabel style={{marginBottom: 30, marginTop: 0, justifyContent: "flex-start", fontWeight: 400}}>
                    Platform billing
                </PageTitleLabel>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "space-between",
                        justifyContent: "flex-start",
                        height: 145,
                        backgroundColor: "#FCFDFE",
                        border: "1px solid #E8EDF5",
                        borderRadius: 10,
                        padding: 30
                    }}>
                    <PageTitleLabel style={{marginTop: 0, fontSize: 20, justifyContent: "flex-start"}}>
                        Your next platform payment is ₪{billingInfo.totalSubscription}, to be charged on {nextMonth}{" "}
                        1st, {nextYear}
                    </PageTitleLabel>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: SHOW_BILLING_HISTORY_BUTTON ? "space-between" : "flex-end",
                            alignItems: "center"
                        }}>
                        {SHOW_BILLING_HISTORY_BUTTON ? (
                            <Button
                                onClick={() => setShowBillingHistoryModal(true)}
                                type="link"
                                style={{
                                    color: "#1260D1",
                                    fontSize: 20,
                                    fontWeight: 500,
                                    padding: 0,
                                    width: 310,
                                    textAlign: "left"
                                }}>
                                Check subscription billing history
                            </Button>
                        ) : null}
                        <StyledButton
                            onClick={() => setShowBillingInfoModal(true)}
                            style={{
                                color: "var(--secondary-color)",
                                border: "1px solid var(--secondary-color)",
                                width: 190,
                                height: 42
                            }}>
                            Update billing info
                        </StyledButton>
                    </div>
                </div>
                <PageTitleLabel
                    style={{marginBottom: 30, marginTop: 50, justifyContent: "flex-start", fontWeight: 400}}>
                    Payment method
                </PageTitleLabel>
                <div
                    style={{
                        backgroundColor: "#FCFDFE",
                        border: "1px solid #E8EDF5",
                        borderRadius: 10,
                        marginTop: 30,
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        padding: 20
                    }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            color: "#212B36",
                            fontSize: 20,
                            fontWeight: 500
                        }}>
                        Credit card
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            color: "#212B36",
                            fontSize: 14,
                            fontWeight: 500,
                            marginTop: 15
                        }}>
                        {me.premiumAccount ? (
                            <span>
                                <u style={{marginRight: 5}}>
                                    <strong>Premium account</strong>
                                </u>{" "}
                                monthly subscription.
                            </span>
                        ) : (
                            <span style={{color: "red"}}>
                                <u>
                                    <strong>Limited account</strong>
                                </u>
                                , enter credit card to enable premium features.
                            </span>
                        )}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            color: "#212B36",
                            fontSize: 14,
                            fontWeight: 500,
                            marginTop: 30,
                            width: "100%"
                        }}>
                        <div style={{marginLeft: -6, display: "flex", alignItems: "center"}}>
                            {me.premiumAccount ? (
                                <>
                                    <CreditCardIcon
                                        style={{width: 51, height: 35, fill: "var(--secondary-color)", marginRight: 10}}
                                    />
                                    <span style={{height: 15}}>**** **** **** ****</span>
                                </>
                            ) : null}
                        </div>
                        <Button
                            onClick={() => EventBus.trigger("credit_card_details:open")}
                            style={{
                                borderRadius: 25,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "transparent",
                                color: "var(--secondary-color)",
                                border: "1px solid var(--secondary-color)",
                                fontSize: 16,
                                height: 42,
                                width: 155
                            }}>
                            Update card
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
