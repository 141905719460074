import React from "react";
import {withRouter} from "react-router";
import {GoogleAnalytics} from "./GoogleAnalytics";
import {inject, observer} from "mobx-react";

const AppRouteListener = inject("store")(
    observer(props => {
        const {history, onRouteChange, location, store} = props;

        const pathsWithBackButton = ["/dashboard/schedule/", "/dashboard/bookings/"];

        const backButtonCheck = location => {
            store.topBarBackButton = pathsWithBackButton.some(path => location.pathname.indexOf(path) >= 0);
        };

        setTimeout(() => {
            backButtonCheck(location);
            onRouteChange(location);
        });

        history.listen(location => {
            GoogleAnalytics.page(location.pathname);
            backButtonCheck(location);
            onRouteChange(location);
        });

        return <></>;
    })
);

export default withRouter(AppRouteListener);
