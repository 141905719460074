import {Form} from "antd";
import React, {useState} from "react";
import {StepperButton} from "./StepperButton";

export const ContinuableForm = ({
    className,
    style,
    children,
    onValuesChange,
    onFinish,
    fields,
    initialValues,
    nonRequiredFieldNames = [],
    continueButton = "Continue",
    continueButtonStyle,
    forceContinueEnabled = false,
    loading = false,
    canContinueColor
}) => {
    const [canContinue, setCanContinue] = useState(forceContinueEnabled);
    const [form] = Form.useForm();

    return (
        <Form
            className={className}
            style={style}
            form={form}
            initialValues={initialValues}
            fields={fields}
            onFinish={onFinish}
            onValuesChange={(changedValue, allValues) => {
                const fieldsValue = form.getFieldsValue();
                const canContinue = Object.keys(fieldsValue).every(key => {
                    let val = fieldsValue[key];
                    if (Array.isArray(val)) {
                        val = val.length > 0;
                    }

                    return !!val || nonRequiredFieldNames.some(field => field === key);
                });
                setCanContinue(canContinue);
                onValuesChange && onValuesChange(changedValue, allValues);
            }}>
            {children}
            <Form.Item>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <StepperButton
                        style={{marginTop: 26, ...continueButtonStyle}}
                        loading={loading}
                        disabled={!canContinue}
                        canContinueColor={canContinueColor}
                        onClick={() => form.submit()}>
                        {continueButton}
                    </StepperButton>
                </div>
            </Form.Item>
        </Form>
    );
};
