import React from "react";
import {Button} from "antd";
import {Images} from "../../images/Images";
import {ConfirmationRequiredWrapper} from "../../components";

export const prioritys = [
    {
        priority: 1,
        text: "זהב",
        icon: Images.goldMedal
    },
    {
        priority: 2,
        text: "כסף",
        icon: Images.silverMedal
    },
    {
        priority: 3,
        text: "ארד",
        icon: Images.bronzeMedal
    }
];

export const AdminOccasionsCategoriesColumns = (events, onEdit, onDelete) => [
    {
        title: "#",
        key: "#",
        width: 50,
        render(_, __, idx) {
            return `${idx + 1}`;
        }
    },
    {
        title: "שם האירוע",
        key: "occasionId",
        render(_, {occasionId}) {
            const name = events.find(event => event.occasionId === occasionId)?.name;
            return <div>{name}</div>;
        },
        sorter: (r1, r2) => {
            const name1 = events.find(event => event.occasionId === r1.occasionId)?.name;
            const name2 = events.find(event => event.occasionId === r2.occasionId)?.name;
            return name1.localeCompare(name2);
        }
    },
    {
        title: "מינימום מחיר",
        key: "minPrice",
        dataIndex: "minPrice",
        sorter: (r1, r2) => Number(r1.minPrice ?? 0) - Number(r2.minPrice ?? 0)
    },
    {
        title: "עדיפות",
        key: "priority",
        dataIndex: "priority",
        render(priority) {
            const priorityObj = prioritys.find(p => p.priority === priority);
            return (
                <div>
                    <img height="35px" src={priorityObj?.icon} alt={priorityObj?.text} />
                </div>
            );
        },
        sorter: (r1, r2) => Number(r1.priority ?? 0) - Number(r2.priority ?? 0)
    },

    {
        title: "פעולות",
        key: "actions",
        width: 200,
        render(_, {occasionId, minPrice, priority}) {
            return (
                <div style={{display: "flex", gap: "10px"}}>
                    <Button onClick={() => onEdit(occasionId)}>עריכה</Button>
                    <ConfirmationRequiredWrapper
                        onYes={() => onDelete(occasionId)}
                        title="האם אתה בטוח שאתה רוצה למחוק את האירוע?"
                        subTitle="שימו לב! פעולה זו אינה ניתנת לביטול">
                        <Button danger>מחיקה</Button>
                    </ConfirmationRequiredWrapper>
                </div>
            );
        }
    }
];
