function levenshteinDistance(str1, str2) {
    const len1 = str1.length;
    const len2 = str2.length;

    // Early exit if one of the strings is empty
    if (len1 === 0) return len2;
    if (len2 === 0) return len1;

    // Early exit if strings are identical
    if (str1 === str2) return 0;

    // Initialize a single row for dynamic programming
    let prevRow = Array(len2 + 1).fill(0);
    for (let j = 0; j <= len2; j++) {
        prevRow[j] = j;
    }

    for (let i = 1; i <= len1; i++) {
        let currentRow = [i];
        for (let j = 1; j <= len2; j++) {
            const insertCost = prevRow[j] + 1;
            const deleteCost = currentRow[j - 1] + 1;
            const replaceCost = prevRow[j - 1] + (str1[i - 1] === str2[j - 1] ? 0 : 1);
            currentRow[j] = Math.min(insertCost, deleteCost, replaceCost);
        }
        prevRow = currentRow;
    }

    return prevRow[len2];
}

/**
 * Calculate the match percentage between two strings.
 * The closer the Levenshtein distance is to 0, the higher the match percentage.
 */
function calculateMatchPercentage(str1, str2) {
    const distance = levenshteinDistance(str1, str2);
    const maxLength = Math.max(str1.length, str2.length);
    return (1 - distance / maxLength) * 100;
}

/**
 * Check the match of a string in an array of strings and return percentages.
 */
export function checkStringMatchesInArray(targetString, arrayOfStrings) {
    return arrayOfStrings.map(str => ({
        string: str,
        matchPercentage: calculateMatchPercentage(targetString, str)
    }));
}
