import React from "react";
import {ConfirmationRequiredWrapper, CopyableSpan, FormModal} from "../../components/index.jsx";
import {StringBuilder} from "../../AppUtils.js";
import {getMainAreaLayer, getSubAreaLayer} from "./utils.js";
import {Button} from "antd";

export const AdminOverrideAddressComponentsModal = ({overrideAddressComponentsModal, onClose, onSave, onReset}) => {
    const {overrideAddressComponents, address_components} = overrideAddressComponentsModal ?? {};
    const relevantAddressComponents = overrideAddressComponents ?? address_components;
    const [relevantAreaLayer, relevantSubAreaLayer, originalAreaLayer, originalSubAreaLayer] = [
        getMainAreaLayer(relevantAddressComponents),
        getSubAreaLayer(relevantAddressComponents),
        getMainAreaLayer(address_components),
        getSubAreaLayer(address_components)
    ];

    return (
        <FormModal
            visible={!!overrideAddressComponentsModal}
            onClose={onClose}
            header={"הגדרת מיקום חלופי"}
            onSave={({sourcePlaceId}) => onSave(overrideAddressComponentsModal?.place_id, sourcePlaceId)}
            fields={[
                {
                    name: "placeId",
                    label: "מזהה מיקום",
                    type: "custom",
                    customComponent: <CopyableSpan>{overrideAddressComponentsModal?.place_id}</CopyableSpan>
                },
                ...(Array.isArray(relevantAddressComponents) && relevantAddressComponents?.length
                    ? [
                          {
                              name: "_currentLocationLayers",
                              label: "רבדים נוכחיים",
                              type: "custom",
                              customComponent: (
                                  <div>
                                      <div>
                                          {new StringBuilder()
                                              .append("מחוז:")
                                              .append(
                                                  relevantAreaLayer?.place_id ? relevantAreaLayer?.long_name : "ללא"
                                              )
                                              .toString()}
                                      </div>

                                      <div>
                                          {new StringBuilder()
                                              .append("עיר:")
                                              .append(
                                                  relevantSubAreaLayer?.place_id
                                                      ? relevantSubAreaLayer?.long_name
                                                      : "ללא"
                                              )
                                              .toString()}
                                      </div>
                                  </div>
                              )
                          }
                      ]
                    : []),
                ...(Array.isArray(address_components) && address_components?.length
                    ? [
                          {
                              name: "_originalLocationLayers",
                              label: "רבדים מקוריים",
                              info: "בעת איפוס אלו יהיו ערכי ברירת מחדל",
                              type: "custom",
                              customComponent: (
                                  <div>
                                      <div>
                                          {new StringBuilder()
                                              .append("מחוז:")
                                              .append(
                                                  originalAreaLayer?.place_id ? originalAreaLayer?.long_name : "ללא"
                                              )
                                              .toString()}
                                      </div>

                                      <div>
                                          {new StringBuilder()
                                              .append("עיר:")
                                              .append(
                                                  originalSubAreaLayer?.place_id
                                                      ? originalSubAreaLayer?.long_name
                                                      : "ללא"
                                              )
                                              .toString()}
                                      </div>
                                  </div>
                              )
                          }
                      ]
                    : []),
                {
                    name: "sourcePlaceId",
                    label: "מזהה מיקום ללקיחת מידע"
                },
                {
                    name: "_resetOverrideAddressComponentsButton",
                    label: "אפס מיקום לברירת מחדל",
                    type: "custom",
                    customComponent: (
                        <ConfirmationRequiredWrapper
                            subTitle="איפוס המיקום עלול להיות פעולה בלתי הפיכה"
                            onYes={() => onReset(overrideAddressComponentsModal?.place_id)}>
                            <Button danger={true}>אפס ושמור הגדרות</Button>
                        </ConfirmationRequiredWrapper>
                    )
                }
            ]}
        />
    );
};
