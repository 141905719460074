import React, {useCallback, useRef} from "react";
import classNames from "classnames";
import {round} from "lodash";
import moment from "moment";
import {Checkbox, Input, Popover, Switch} from "antd";
import {EditOutlined} from "@ant-design/icons";
import HappinessCalculatorRangeOccasionInput from "./HappinessCalculatorRangeOccasionInput";
import {ConsumptionOccasionType, OccasionPriceType, OccasionPriceTypeDisplayName} from "./consts";
import "./happiness-calculator-consumption-occasion-input.css";

export const HappinessCalculatorConsumptionOccasionInput = ({
    onFieldChanged,
    employeesAmount,
    occasion,
    disabled,
    isRangePolicy,
    monthsRange
}) => {
    const budgetInputRef = useRef(null);
    const oddsInputRef = useRef(null);

    const onPriceTypeToggle = useCallback(
        checked => {
            onFieldChanged("priceType", checked ? OccasionPriceType.byGroup : OccasionPriceType.byParticipant);

            if (occasion.price) {
                const newPrice = checked ? occasion.price * employeesAmount : occasion.price / employeesAmount;
                const roundNewPrice = round(newPrice, 2);
                onFieldChanged("price", roundNewPrice);
            }
        },
        [occasion.price, employeesAmount]
    );

    return (
        <>
            {occasion ? (
                <div
                    className={classNames("happiness-calculator-consumption-occasion-input", "primary-color-checkbox", {
                        isActive: occasion.isActive
                    })}>
                    <div className="happiness-calculator-consumption-occasion-input-right-side">
                        <Checkbox
                            checked={occasion.isActive && !disabled}
                            onChange={({target}) => onFieldChanged("isActive", target.checked)}
                        />

                        {isRangePolicy ? (
                            <HappinessCalculatorRangeOccasionInput
                                isActive={occasion?.isActive}
                                monthAmount={monthsRange}
                                onFieldChanged={onFieldChanged}
                            />
                        ) : (
                            <>
                                <span>{occasion.name}</span>
                                <span>{occasion.icon}</span>
                                <span> | {moment(occasion.startDate).format("D.M.YY")}</span>
                            </>
                        )}
                    </div>
                    <div className="happiness-calculator-consumption-occasion-input-line-separator" />
                    {occasion?.type === ConsumptionOccasionType.LifeCycle && occasion.isActive ? (
                        <Popover
                            trigger="click"
                            onVisibleChange={isVisible => {
                                if (isVisible) {
                                    //Hack to mack focus work
                                    setTimeout(() => {
                                        oddsInputRef?.current?.focus();
                                    }, 50);
                                }
                            }}
                            content={
                                <Input
                                    ref={oddsInputRef}
                                    placeholder="0"
                                    onChange={({target}) =>
                                        onFieldChanged("odds", (target.value / employeesAmount) * 100)
                                    }
                                />
                            }>
                            <div className="happiness-calculator-consumption-occasion-input-odds">
                                {(((occasion.odds ?? 0) / 100) * employeesAmount).toLocaleString("en-GB", {
                                    maximumFractionDigits: 1
                                })}
                                {" מקבלים משוערים "}
                                <EditOutlined />|
                            </div>
                        </Popover>
                    ) : null}
                    <Popover
                        trigger="click"
                        onVisibleChange={isVisible => {
                            if (isVisible) {
                                //Hack to mack focus work
                                setTimeout(() => {
                                    budgetInputRef?.current?.focus();
                                }, 50);
                            }
                        }}
                        content={
                            <div
                                className={classNames("happiness-calculator-consumption-occasion-input-popover", {
                                    "is-active": occasion.priceType === OccasionPriceType.byGroup
                                })}>
                                <div>
                                    <span>תקציב למשתתף</span>

                                    <Switch onChange={onPriceTypeToggle} />
                                    <span>תקציב לקבוצה</span>
                                </div>
                                <Input
                                    ref={budgetInputRef}
                                    value={occasion?.price}
                                    placeholder={`הזן תקציב ${
                                        occasion.priceType === OccasionPriceType.byGroup ? "לקבוצה" : "למשתתף"
                                    }`}
                                    onChange={({target}) => {
                                        onFieldChanged("price", target.value);
                                        if (!occasion.isActive) {
                                            onFieldChanged("isActive", true);
                                        } else if (!target.value) {
                                            onFieldChanged("isActive", false);
                                        }
                                    }}
                                    suffix={
                                        <>
                                            {occasion.price ? (
                                                <span className="happiness-calculator-consumption-occasion-input-">
                                                    ₪
                                                </span>
                                            ) : null}
                                        </>
                                    }
                                />
                            </div>
                        }>
                        {
                            <span
                                className={classNames("happiness-calculator-consumption-occasion-input-text", {
                                    "is-active": occasion.isActive
                                })}>
                                {occasion.isActive ? (
                                    <span>
                                        {occasion.price}
                                        {" ₪ "}
                                        {
                                            OccasionPriceTypeDisplayName[
                                                occasion.priceType ?? OccasionPriceType.byParticipant
                                            ]
                                        }
                                        <EditOutlined />
                                    </span>
                                ) : occasion?.minPrice ? (
                                    `החל מ-${occasion.minPrice} ₪`
                                ) : null}
                            </span>
                        }
                    </Popover>
                </div>
            ) : null}
        </>
    );
};
