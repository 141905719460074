import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {sumBy} from "lodash";
import OrdersCalendarEventsInjector from "./OrdersCalendarEventsInjector";
import OrdersCalendarCategoriesBar from "./OrdersCalendarCategoriesBar";
import {OrdersCalendarContext} from "./OrdersCalendarContext";
import {DisabledClickClassName, OrdersCalendarRoutes} from "./consts";
import {getCategoriesSummary} from "../happinessCalculator/utils";
import {AppContext} from "../AppContext";
import {GenericModal, SquareButton} from "../components";
import {Images} from "../images/Images";
import {HappinessCalculatorRoutes} from "../happinessCalculator/consts";
import {getOrderPrice} from "../utils/calculationUtils";
import "./orders-calendar-page.css";

const OrdersCalendarPage = ({history}) => {
    const {me} = useContext(AppContext);
    const {month, orders, setPlannedOccasions, log, currentMonthPlan} = useContext(OrdersCalendarContext);

    const [showPlanModal, setShowPlanModal] = useState(false);

    useEffect(() => {
        const loadedPlannedOccasions = Object.values(currentMonthPlan?.categories ?? {}).reduce(
            (acc, {occasions, occasionCategoryId}) => [
                ...acc,
                ...occasions.map(occasion => ({
                    ...occasion,
                    occasionCategoryId
                }))
            ],
            []
        );

        setPlannedOccasions(loadedPlannedOccasions);
    }, [currentMonthPlan]);

    const goToPlanPage = useCallback(() => {
        log("Create Plan Button Clicked");
        setShowPlanModal(false);
        history.push({
            pathname: HappinessCalculatorRoutes.PLAN,
            state: {month}
        });
    }, [log, history, month]);

    const goToYearlySummaryPage = useCallback(() => {
        log("Yearly View Button Clicked");

        history.push(OrdersCalendarRoutes.YEARLY_VIEW);
    }, [log, history, month]);

    const onClickEditPlanButton = useCallback(() => {
        log("Edit Plan Button Clicked");
        history.push({
            pathname: `${HappinessCalculatorRoutes.PLAN}/${currentMonthPlan?.planId ?? ""}`,
            state: {}
        });
    }, [log, history, currentMonthPlan]);

    const dateCellClicked = useCallback(
        event => {
            const eventsClassList = event.dayEl.classList;

            if (eventsClassList.contains(DisabledClickClassName) || currentMonthPlan) {
                return;
            }

            log("Add Plan Date Cell Clicked", {date: event.dateStr});
            setShowPlanModal(me.features?.calculator);
        },
        [currentMonthPlan, log]
    );

    const categoriesWithTotalPrice = useMemo(
        () =>
            currentMonthPlan
                ? getCategoriesSummary(currentMonthPlan.categories, currentMonthPlan.employeesAmount)
                : null,
        [currentMonthPlan]
    );

    const allPlanPrice = useMemo(() => sumBy(categoriesWithTotalPrice, "totalPrice"), [categoriesWithTotalPrice]);

    const allOrdersPrice = useMemo(() => sumBy(orders, getOrderPrice), [orders]);

    const cssVars = useMemo(
        () =>
            Object.values(currentMonthPlan?.categories ?? {}).reduce(
                (acc, {color, occasionCategoryId}) => ({
                    ...acc,
                    [`--${occasionCategoryId}`]: color
                }),
                {}
            ),
        [currentMonthPlan?.categories]
    );

    return (
        <>
            <GenericModal
                className="orders-calendar-page-button"
                visible={showPlanModal}
                title="לורם איפסום"
                text={[
                    "לורם איפסום לורם איפסום לורם איפסום לורם איפסום לורם איפסום לורם איפסום לורם איפסום לורם איפסום"
                ]}
                onClose={() => setShowPlanModal(false)}
                options={[
                    {
                        id: "close",
                        text: "הגדרת מסגרת תקציב",
                        onClick: goToPlanPage
                    }
                ]}
                image={Images.unplannedCalendarImage}
            />

            <div className="orders-calendar-page" style={cssVars}>
                <div className="orders-calendar-page-title-row">
                    {me.companyName ? (
                        <div className="orders-calendar-page-title">יומן הרווחה של {me.companyName} 🤗</div>
                    ) : null}

                    {me.features?.calculator && (
                        <div className="orders-calendar-page-year-summary" onClick={goToYearlySummaryPage}>
                            סיכום שנתי
                        </div>
                    )}
                </div>

                {me.features?.calculator && (
                    <div className="orders-calendar-page-toolbar">
                        <div className="orders-calendar-page-summary-stat">
                            <span>תקציב מסגרת חודשית</span>
                            <span>
                                {allPlanPrice ? allPlanPrice.toLocaleString("en-GB", {maximumFractionDigits: 1}) : "-"}{" "}
                                ₪
                            </span>
                        </div>
                        <div className="orders-calendar-page-summary-stat">
                            <span>תקציב בשימוש</span>
                            <span className="orders-calendar-page-used-budget-summary-container">
                                {allOrdersPrice ? (
                                    <span className="orders-calendar-page-used-budget-bar">
                                        <OrdersCalendarCategoriesBar
                                            orders={orders}
                                            categoriesWithTotalPrice={categoriesWithTotalPrice}
                                            totalCategoriesPrice={allPlanPrice}
                                        />
                                    </span>
                                ) : null}
                                <span>
                                    {allOrdersPrice
                                        ? allOrdersPrice.toLocaleString("en-GB", {maximumFractionDigits: 1})
                                        : "-"}{" "}
                                    ₪
                                </span>
                            </span>
                        </div>
                        {currentMonthPlan ? (
                            <div onClick={onClickEditPlanButton} className="square-link-label">
                                עריכת תקציב מסגרת
                            </div>
                        ) : (
                            <SquareButton onClick={() => setShowPlanModal(true)} className="primary-color-button">
                                הגדרת תקציב מסגרת
                            </SquareButton>
                        )}
                    </div>
                )}

                <OrdersCalendarEventsInjector history={history} dateCellClicked={dateCellClicked} />
            </div>
        </>
    );
};

export default OrdersCalendarPage;
