import React, {useEffect, useState} from "react";
import {HttpClient} from "../http/HttpClient";
import {message, Carousel} from "antd";
import moment from "moment-timezone";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {eventByDate} from "../event/EventByDate";
import {EmployeeBookingProductSelection} from "./EmployeeBookingProductSelection";
import {EmployeeBookingTimeSelection} from "./EmployeeBookingTimeSelection";
import {EmployeeBookingSummary} from "./EmployeeBookingSummary";
import {EmployeeBookingTransaction} from "./EmployeeBookingTransaction";
import {EmployeeBookingConfirmation} from "./EmployeeBookingConfrmation";
import {createAppointmentDetails} from "./EmployeeAppointmentBuilder";

export const EmployeeBooking = ({me, event, currentStart, currentEnd, onConfirm, onBack, onClose}) => {
    event = eventByDate(event, currentStart);

    const {providerId, eventId} = event;
    const [providerInfo, setProviderInfo] = useState(null);
    const [appointments, setAppointments] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [saving, setSaving] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [confirmedAppointment, setConfirmedAppointment] = useState(null);
    const [slideNumber, setSlideNumber] = useState(0);
    let [slider, setSlider] = useState(null);
    const [tokenUrls, setTokenUrls] = useState({
        successUrl: null,
        failUrl: null
    });

    const today = moment(currentStart).format("YYYY-MM-DD");

    const hasTransactionTerminal = provider => {
        return provider && provider.transactionTerminal && provider.transactionTerminal.enabled;
    };

    const NUMBER_OF_SLIDES = hasTransactionTerminal(providerInfo) ? 5 : 4;

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const [providerInfo, appointments] = await Promise.all([
                    HttpClient.get(`/api/providers/${providerId}`),
                    HttpClient.get(`/api/events/${eventId}/appointments/dates/${today}`)
                ]);

                setProviderInfo(providerInfo);
                setAppointments(appointments);

                if (hasTransactionTerminal(providerInfo)) {
                    const urls = await HttpClient.get(`/api/events/${event.eventId}/booking/token`);
                    setTokenUrls(urls);
                }
            } catch (e) {
                message.error("Unfortunately we didn't manage to set a new appointment.");
            }
        });
    }, []);

    const goBackward = () => {
        if (slideNumber === 0) {
            onBack();
        } else {
            slider.goTo(slideNumber - 1, true);
            setSlideNumber(slideNumber - 1);
        }
    };

    const goForward = () => {
        if (slideNumber < NUMBER_OF_SLIDES - 1) {
            slider.goTo(slideNumber + 1, true);
            setSlideNumber(slideNumber + 1);
        }
    };

    const confirmAppointment = async confirmationCode => {
        setSaving(true);

        try {
            const appointmentDetails = createAppointmentDetails(
                me,
                event,
                selectedProduct,
                today,
                selectedTime.start.format("HH:mm"),
                selectedTime.end.format("HH:mm")
            );

            if (confirmationCode) {
                appointmentDetails.confirmationCode = confirmationCode;
            }

            const confirmedAppt = await HttpClient.put(`/api/events/${eventId}/appointments`, appointmentDetails);
            setConfirmedAppointment(confirmedAppt);
            GoogleAnalytics.event("Employee Booking", "Create Appointment", me.userId);
            goForward();
        } catch (e) {
            if (e.statusCode === 400) {
                message.error(e.message, 10);
            } else {
                message.error("Unfortunately we didn't manage to set this appointment.", 5);
            }
        }
        setSaving(false);
    };

    const selectProduct = product => {
        setSelectedProduct(product);
        goForward();
    };

    const selectTime = time => {
        setSelectedTime(time);
        goForward();
    };

    const onTransactionComplete = async payment => {
        setPaymentStatus(payment);
        if (payment.status === "confirmed") {
            await confirmAppointment(payment.code);
        } else {
            setTimeout(goForward, 100);
        }
    };

    return (
        <div style={{maxWidth: 600, width: "100%", minWidth: 340, minHeight: 400, marginBottom: 10}}>
            <Carousel
                swipe={false}
                adaptiveHeight={true}
                lazyLoad="ondemand"
                dots={false}
                ref={_slider => setSlider(_slider)}>
                <EmployeeBookingProductSelection
                    me={me}
                    event={event}
                    currentStart={currentStart}
                    currentEnd={currentEnd}
                    onBack={goBackward}
                    providerInfo={providerInfo}
                    onProductSelect={selectProduct}
                />
                <EmployeeBookingTimeSelection
                    me={me}
                    event={event}
                    appointments={appointments}
                    selectedProduct={selectedProduct}
                    currentStart={currentStart}
                    currentEnd={currentEnd}
                    onBack={goBackward}
                    onTimeSelect={selectTime}
                    onClose={onClose}
                />
                <EmployeeBookingSummary
                    me={me}
                    event={event}
                    currentStart={currentStart}
                    currentEnd={currentEnd}
                    onBack={goBackward}
                    selectedTime={selectedTime}
                    selectedProduct={selectedProduct}
                    providerInfo={providerInfo}
                    confirming={saving}
                    onConfirm={hasTransactionTerminal(providerInfo) ? () => goForward() : () => confirmAppointment()}
                />
                {hasTransactionTerminal(providerInfo) ? (
                    <EmployeeBookingTransaction
                        providerInfo={providerInfo}
                        event={event}
                        product={selectedProduct}
                        onComplete={async payment => await onTransactionComplete(payment)}
                        tokenUrls={tokenUrls}
                    />
                ) : null}
                <EmployeeBookingConfirmation
                    providerInfo={providerInfo}
                    paymentStatus={paymentStatus}
                    event={event}
                    confirmedAppointment={confirmedAppointment}
                    onClose={onClose}
                    onBack={goBackward}
                />
            </Carousel>
        </div>
    );
};
