import React, {useContext, useMemo} from "react";
import {ConfigProvider} from "antd";
import {AppContext} from "../../AppContext";
import "../../dashboards/app-dashboard.css";
import {PanelFilter} from "./panel/PanelFilter.jsx";
import {AdvancedFilter} from "./advancedFilter/AdvancedFilter.jsx";
import "./marketplace-filter.css";
import {MarketplaceFilterProviderContext} from "./MarketplaceFilterContext";
import classnames from "classnames";
import {StringBuilder} from "../../AppUtils.js";
import {panelOptions} from "./panel/consts.jsx";

export const MarketplaceFilter = () => {
    const {me} = useContext(AppContext);
    const {panel} = useContext(MarketplaceFilterProviderContext);

    const filterTitle = useMemo(() => {
        return me?.firstName
            ? new StringBuilder().append("היי").append(`${me.firstName},`).append("מה מזמינים היום?").toString()
            : null;
    }, [me?.firstName]);

    return (
        <div className="marketplace-advanced-search-container">
            <ConfigProvider direction="rtl">
                <div className={classnames("marketplace-advanced-search-header", panel)}>
                    <div className="marketplace-advanced-search-header-title">{filterTitle}</div>
                    <PanelFilter />

                    {panel !== panelOptions.homePage && (
                        <div className="advanced-filter">
                            <AdvancedFilter />
                        </div>
                    )}
                </div>
            </ConfigProvider>
        </div>
    );
};
