import _ from "lodash";
// import * as EmailValidator from "email-validator";

export class EmailOrPhoneDetector {
    static detect(text) {
        if (!text) return false;
        const words = _.words(text, /[^, ]+/g);
        return words.some(word => {
            word = word.replace("-", "").replace("\n", "");
            return (word.length >= 7 && /^\d+$/.test(word)) || EmailValidator.validate(word);
        });
    }
}
