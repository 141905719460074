import React, {useCallback, useContext, useMemo, useRef, useState} from "react";
import {flatten, isEmpty, sumBy} from "lodash";
import {Spin} from "antd";
import classNames from "classnames";
import {CloseCircleOutlined} from "@ant-design/icons";
import {MarketplaceBookingContext} from "../MarketplaceBookingContext";
import {
    DEFAULT_DELIVERY_PRICE,
    getDefaultTypeToShow,
    INITIAL_AMOUNT,
    typeToHebrewName,
    typeToPluralHebrewName
} from "../../MarketplaceUtils";
import {MarketplaceContext} from "../../marketplaceContext";
import {CancellationPolicy, getPriceByAmount} from "../../MarketplaceHelper";
import {SmartImageContainer, StyledButton} from "../../../components";
import cover_placeholder from "../../../images/cover_placeholder.jpg";
import {AutoDirectionProvider} from "../../../AutoDirectionProvider";
import {AppContext} from "../../../AppContext";
import {FormInputV2} from "../../../components/form";
import {reduceVAT} from "../../../utils/calculationUtils";
import "../marketplace-order-preview.css";
import "../../marketplace.css";
import {PriceTypes} from "../../../admin/upload/PriceSelectorHelper";
import {allHebrewLetters} from "../../ProductDetailsPage/consts";
import classnames from "classnames";

export const ProductBundleOrderPreview = ({
    providerDetails,
    formValues,
    bookingLoading,
    onFinishClick,
    canFinish,
    coupon,
    onCouponAdded,
    onCancelCoupon,
    deliveryPrice,
    loadingDeliveryPrice,
    upgradeOptionsPrice,
    tip,
    companyDiscount
}) => {
    const {company} = useContext(AppContext);
    const {productBundle} = useContext(MarketplaceContext);
    const {offeredPrice} = useContext(MarketplaceBookingContext);

    const couponInputRef = useRef(null);
    const [couponCode, setCouponCode] = useState(null);

    const onCouponSendClicked = useCallback(async () => {
        const couponCode = couponInputRef.current.input.value;
        setCouponCode(couponCode);
        onCouponAdded(couponCode);
    }, [couponInputRef, onCouponAdded]);

    const cancelCoupon = useCallback(async () => {
        setCouponCode(null);
        onCancelCoupon();
    }, [onCancelCoupon]);

    const PriceDescriptionByChosenPriceType = useCallback(({product, formValues}) => {
        switch (product.chosenPriceType) {
            case PriceTypes.ByModular:
                const modularProductsPrice = formValues?.modularProducts?.reduce((acc, formValue) => {
                    return acc + formValue.price * (formValue?.amount ?? formValue?.defaultAmount);
                }, 0);
                return (
                    <>
                        <div>הרכב החבילה</div>
                        <div>{modularProductsPrice?.toFixed(0) ?? "XX"} ₪</div>
                    </>
                );
            case PriceTypes.ByVoucher:
                const voucherReceiversAmount = formValues.singleEmployeeDetails
                    ? 1
                    : formValues.employeesExcel?.rowCount ?? "XXX";
                const voucherAmount = formValues?.voucherOption?.redeemableValue ?? "XXX";

                return (
                    <>
                        <div>{voucherReceiversAmount} מקבלי מתנות</div>
                        <div>{voucherAmount} ₪ למקבל</div>
                    </>
                );
            default:
                return (
                    <>
                        <div>
                            {formValues?.amount || INITIAL_AMOUNT}{" "}
                            {typeToPluralHebrewName(getDefaultTypeToShow(product))}
                        </div>
                        {
                            <div>
                                {getPriceByAmount(formValues?.amount, product)} ₪ {"ל"}
                                {typeToHebrewName(getDefaultTypeToShow(product))}
                            </div>
                        }
                    </>
                );
        }
    }, []);

    const total = useMemo(() => {
        return sumBy(
            productBundle?.products,
            ({productId}) => offeredPrice[productId] + (deliveryPrice?.[productId] || 0) + tip[productId]
        );
    }, [productBundle?.products, offeredPrice, deliveryPrice, tip]);

    const totalAfterDiscount = useMemo(() => {
        if (coupon?.discount || companyDiscount) {
            return total - (companyDiscount || 0) - (coupon?.discount || 0);
        }
        return null;
    }, [offeredPrice, deliveryPrice, coupon, companyDiscount, tip]);

    const totalWithoutVat = useMemo(() => reduceVAT(totalAfterDiscount ?? total), [totalAfterDiscount, total]);

    const productBundlePortfolioImages = useMemo(
        () => flatten(productBundle.products.map(({portfolioImages}) => portfolioImages).filter(Boolean)),
        [productBundle.products]
    );

    return (
        <div className="marketplace-order-preview">
            <SmartImageContainer
                className="marketplace-order-preview-product-image"
                width={400}
                height={220}
                src={
                    !isEmpty(productBundlePortfolioImages)
                        ? productBundlePortfolioImages[0]?.imageUrl
                        : cover_placeholder
                }
            />
            <AutoDirectionProvider text={"עברית"}>
                <div className="marketplace-order-preview-summary-container">
                    <div className="marketplace-order-preview-title">סיכום הזמנה</div>

                    {productBundle.products.map((product, index) => {
                        const productFormValues = formValues[product.productId];
                        const providerDetails = productBundle.providers.find(
                            provider => provider.providerId === product.providerId
                        );

                        return (
                            <>
                                <div
                                    className={classnames(
                                        "marketplace-order-preview-summary-line",
                                        "marketplace-order-preview-summary-line-title"
                                    )}>
                                    חלק {allHebrewLetters[index]} - {product.productName}
                                </div>
                                <div className="marketplace-order-preview-summary-line">
                                    <PriceDescriptionByChosenPriceType
                                        product={product}
                                        formValues={productFormValues}
                                    />
                                </div>

                                {!providerDetails?.isVoucherProvider && (
                                    <div className="marketplace-order-preview-summary-line">
                                        <div>
                                            {product?.doesProviderArriveToCustomer ??
                                            providerDetails?.doesProviderArriveToCustomer
                                                ? "עלות נסיעות"
                                                : "עלות משלוח"}
                                        </div>
                                        <div>
                                            {loadingDeliveryPrice ? (
                                                <Spin />
                                            ) : (
                                                <>{deliveryPrice[product.productId] ?? INITIAL_AMOUNT} ₪</>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {upgradeOptionsPrice[product.productId] ? (
                                    <div className="marketplace-order-preview-summary-line">
                                        <div>שדרוגים</div>
                                        <div>{upgradeOptionsPrice[product.productId]} ₪</div>
                                    </div>
                                ) : null}

                                {productFormValues?.tipPercentage ? (
                                    <div className="marketplace-order-preview-summary-line">
                                        <div>טיפ</div>
                                        <div>{tip?.[product.productId]?.toLocaleString()} ₪</div>
                                    </div>
                                ) : null}
                            </>
                        );
                    })}

                    {coupon?.discount ? (
                        <div className={classNames("marketplace-order-preview-summary-line", "discount-line")}>
                            <span>הנחת קוד קופון</span>
                            <span>{coupon.discount}- ₪</span>
                        </div>
                    ) : null}
                    {company?.ordersDiscount ? (
                        <div className={classNames("marketplace-order-preview-summary-line", "discount-line")}>
                            <span>
                                הנחה מיוחדת
                                <span className="marketplace-order-preview-subtitle">
                                    {` בגובה ${company.ordersDiscount}%`}
                                </span>
                            </span>

                            <span>{company?.ordersDiscount && companyDiscount ? `${companyDiscount}-` : "XX"} ₪</span>
                        </div>
                    ) : null}
                </div>
                <div className="simple-modal-break-line" />
                <CancellationPolicy isVoucherProduct={providerDetails?.isVoucherProvider} />
                <div className="marketplace-order-preview-bottom-panel">
                    <div
                        className={`marketplace-order-preview-pay-amount-line${
                            coupon?.discount || company?.ordersDiscount ? " discount-applied" : ""
                        }`}>
                        {total ? (
                            <>
                                <div>סה״כ</div>
                                <div className="marketplace-order-preview-pay-amount">
                                    <div>{total?.toFixed(0)} ₪</div>
                                    <span>כולל מע״מ</span>
                                </div>
                            </>
                        ) : null}
                    </div>
                    {totalAfterDiscount ? (
                        <div className="marketplace-order-preview-pay-amount-line">
                            <span>סה״כ אחרי הנחה</span>
                            <span>{totalAfterDiscount?.toFixed(0)} ₪ כולל מע״מ</span>
                        </div>
                    ) : null}
                    <div className={classNames("marketplace-order-preview-pay-amount-line", "without-vat")}>
                        <div />
                        {(formValues?.amount ?? formValues?.modularProducts) && offeredPrice ? (
                            <div className="marketplace-order-preview-pay-amount">
                                <div>({totalWithoutVat?.toFixed(0)} ₪</div>
                                <span>ללא מע״מ)</span>
                            </div>
                        ) : null}
                    </div>
                    {!providerDetails?.isVoucherProvider && (
                        <>
                            {coupon?.discount ? (
                                <div className="marketplace-order-preview-coupon-applied">
                                    <span>קופון:</span>
                                    <div>
                                        {coupon.code}
                                        <CloseCircleOutlined
                                            className="marketplace-order-preview-cancel-coupon"
                                            onClick={cancelCoupon}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="marketplace-order-preview-add-coupon">
                                    <FormInputV2
                                        defaultValue={couponCode}
                                        ref={couponInputRef}
                                        placeholder="הזן קוד קופון"
                                    />
                                    <StyledButton onClick={onCouponSendClicked}>הוסף</StyledButton>
                                </div>
                            )}
                            {coupon?.errorMessage ? (
                                <div className="marketplace-order-preview-coupon-error-message">
                                    {coupon.errorMessage}
                                </div>
                            ) : null}
                        </>
                    )}
                </div>
                <StyledButton
                    loading={bookingLoading}
                    onClick={onFinishClick}
                    className={classNames("marketplace-order-preview-confirm-button", {canFinish})}>
                    אישור הזמנה
                </StyledButton>
            </AutoDirectionProvider>
        </div>
    );
};
