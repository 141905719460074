import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Avatar, ConfigProvider, Skeleton} from "antd";
import {WBCarousel} from "../../../components";
import "./tags.css";
import {defaultTag, generatePropsNew, getItemPerRow, skeletonItems} from "./options.jsx";
import {MarketplaceFilterProviderContext} from "../MarketplaceFilterContext.jsx";
import classnames from "classnames";
import {AppContext} from "../../../AppContext.jsx";
import {useRequest, useWindowDimensions} from "../../../utils/hooks.jsx";
import {buildFilters} from "../advancedFilter/utils.js";
import {useHistory} from "react-router";
import {panelOptions} from "../panel/consts.jsx";

export const TagFilters = () => {
    const width = useWindowDimensions();
    const {filterValues} = useContext(AppContext);
    const history = useHistory();
    const {innerState, panel, setInnerState, onSearch} = useContext(MarketplaceFilterProviderContext);
    const [searchTags, setSearchTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState(null);
    const [categorySearchTags, searchTagLoading, categorySearchTagsError] = useRequest(
        `/api/categorySearchTag/byCategoryId/${panel}?isActive=true`,
        "GET",
        null,
        [panel],
        !!panel && panel !== panelOptions.homePage
    );

    const searchTagList = useMemo(() => [defaultTag, ...searchTags], [searchTags]);

    useEffect(() => {
        if (categorySearchTagsError) {
            setSearchTags([]);
        }
    }, [categorySearchTagsError]);

    useEffect(() => {
        if (categorySearchTags?.tagIds) {
            const searchTagValid = categorySearchTags.tagIds.filter(searchTag => searchTag?.image);
            setSearchTags(searchTagValid);
        }
    }, [categorySearchTags]);

    useEffect(() => {
        if (selectedTag && selectedTag?.name !== filterValues?.searchText?.[0]) {
            const filters = buildFilters(innerState, panel);
            onSearch(history, filters);
        }
    }, [selectedTag]);

    useEffect(() => {
        if (selectedTag?.name === filterValues.searchText?.[0]) {
            return;
        }

        const selectedSearchTag = searchTagList.find(searchTag => searchTag.name === filterValues?.searchText?.[0]);
        if (selectedSearchTag) {
            onTagClick(selectedSearchTag);
            return;
        }
        setSelectedTag(null);
    }, [filterValues?.searchText, searchTagList]);

    const onTagClick = useCallback(
        tag => {
            if (tag.isDefault) {
                setSelectedTag(null);
                const {searchText, ...innerStateUpdated} = innerState;
                setInnerState(({searchText, ...prevValues}) => prevValues);
                const filters = buildFilters(innerStateUpdated, panel);
                onSearch(history, filters);
                return;
            }
            setInnerState(prevState => ({...prevState, searchText: tag.name}));
            setSelectedTag(tag);
        },
        [innerState, panel, onSearch]
    );
    const carouselProps = useMemo(
        () => generatePropsNew(searchTagList, width, filterValues?.searchText?.[0]),
        [searchTagList, width, filterValues?.searchText]
    );

    const skeletonItemPerRow = useMemo(() => getItemPerRow(width), [width]);

    return (
        <>
            <ConfigProvider direction="rtl">
                <div className="tags-filter">
                    {searchTagLoading ? (
                        <SkeletonItemsLoading itemPerRow={skeletonItemPerRow} />
                    ) : (
                        <WBCarousel carouselProps={carouselProps}>
                            {searchTagList.map((searchTag, index) => {
                                const isSelectedTag = searchTag.isDefault
                                    ? !selectedTag
                                    : selectedTag?.name === searchTag.name;
                                return (
                                    <div
                                        onClick={() => onTagClick(searchTag)}
                                        key={index}
                                        className={classnames("icon-tag-container", {checked: isSelectedTag})}>
                                        <Avatar className="tag-icon-img" src={searchTag.image?.imageUrl} />
                                        <div className="tag-name-container">
                                            <span className="tag-name">{searchTag.name}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </WBCarousel>
                    )}
                </div>
            </ConfigProvider>
        </>
    );
};

const SkeletonItemsLoading = ({itemPerRow}) => {
    return (
        <div className="tags-filter-skeleton">
            {[...Array(itemPerRow)].map(index => {
                return (
                    <div key={index} className="tags-filter-item-container">
                        <Skeleton.Button
                            className="tags-filter-skeleton-item-avatar"
                            key={index}
                            active={true}
                            shape={"circle"}
                            paragraph={{rows: 1}}
                        />
                        <Skeleton.Button
                            className="tags-filter-skeleton-item-text"
                            key={index}
                            active={true}
                            paragraph={{rows: 1}}
                        />
                    </div>
                );
            })}
        </div>
    );
};
