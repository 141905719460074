import React, {useContext, useEffect, useState} from "react";
import {Form} from "antd";
import {isEmpty} from "lodash";
import {
    getDefaultPriceTypeMaximum,
    getDefaultPriceTypeMinimumAmount,
    getDefaultTypeToShow,
    typeToHebrewName,
    typeToPluralHebrewName
} from "../MarketplaceUtils";
import {getPriceByAmount} from "../MarketplaceHelper";
import {FormInputV2} from "../../components/form";
import {StyledButton} from "../../components";
import "./pricing-calculator.css";
import {AppContext} from "../../AppContext";

export const PricingCalculatorByAmount = ({service, onBook, onParticipantsChange}) => {
    const {company} = useContext(AppContext);
    const [type, setType] = useState(getDefaultTypeToShow(service));
    const [totalPrice, setTotalPrice] = useState(null);
    const [chosenTable, setChosenTable] = useState(null);

    useEffect(() => {
        if (!isEmpty(service.pricingTable)) {
            const typeToShow = getDefaultTypeToShow(service);
            setType(typeToShow);
            setChosenTable(service.pricingTable[typeToShow]);
        }
    }, [service.pricingTable]);

    return (
        <>
            <div>
                <div className="pricing-calculator-content-title">תמחור לפי {typeToHebrewName(type)}</div>
                <div className="pricing-calculator-content-pricing-list">
                    {chosenTable
                        ? chosenTable.map(row => {
                              if (row.min && row.max && row.price) {
                                  return (
                                      <div
                                          key={`${type}-${row?.min}`}
                                          className="pricing-calculator-content-pricing-list-item">
                                          <span>{`✨ ${row.max} - ${row.min} ${typeToPluralHebrewName(type)} | ${
                                              row.price
                                          }₪ ל${typeToHebrewName(type)}`}</span>
                                          <span className="including-vat-Text">כולל מעמ</span>
                                      </div>
                                  );
                              }
                          })
                        : null}
                </div>
            </div>

            <Form
                onFinish={onBook}
                onValuesChange={(changedValue, _) => {
                    if (changedValue.participants) {
                        onParticipantsChange(changedValue.participants);
                        const priceBeforeDiscount = getPriceByAmount(changedValue.participants, service);
                        const priceAfterDiscount = company?.ordersDiscount
                            ? priceBeforeDiscount - Math.floor(priceBeforeDiscount * (company?.ordersDiscount / 100))
                            : priceBeforeDiscount;

                        setTotalPrice(
                            type === "byGroup"
                                ? priceAfterDiscount
                                : (priceAfterDiscount * changedValue.participants).toFixed(0)
                        );
                    } else {
                        setTotalPrice(null);
                    }
                }}>
                <Form.Item
                    name="participants"
                    style={{width: "100%", margin: "10px 0 25px 0"}}
                    rules={[
                        {
                            validator: (_, value) => {
                                let error;

                                if (value && value < getDefaultPriceTypeMinimumAmount(service)) {
                                    error = "למוצר זה יש מינימום";
                                } else if (value && value > getDefaultPriceTypeMaximum(service)) {
                                    error = "למוצר זה יש מקסימום";
                                }
                                return error ? Promise.reject(error) : Promise.resolve();
                            }
                        }
                    ]}>
                    <FormInputV2 type="number" placeholder={`מספר ${typeToPluralHebrewName(type)}`} />
                </Form.Item>
                <StyledButton htmlType="submit" className="pricing-calculator-button">
                    {totalPrice ? `מעבר להזמנה (${totalPrice}₪)` : "מעבר להזמנה"}
                </StyledButton>
            </Form>
        </>
    );
};
