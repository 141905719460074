import React from "react";
import {TransactionWithIframe} from "./transactions/TransactionWithIframe";
import {PageSubTitleLabel, PageTitleLabel} from "../components/PageTitle";

export const EmployeeBookingTransaction = ({providerInfo, event, product, onComplete, tokenUrls}) => {
    const terminalTypeToView = {
        iframe: () => (
            <TransactionWithIframe
                providerInfo={providerInfo}
                event={event}
                product={product}
                onComplete={onComplete}
                tokenUrls={tokenUrls}
            />
        )
    };

    const terminal = providerInfo.transactionTerminal;

    if (!terminal) {
        throw Error("No transaction terminal");
    } else if (!(terminal.type in terminalTypeToView)) {
        throw Error("No such transaction terminal");
    }

    const TransactionVew = terminalTypeToView[terminal.type];

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                marginBottom: 30,
                padding: 15
            }}>
            <PageTitleLabel>Payment information</PageTitleLabel>
            <PageSubTitleLabel>Don't worry, we won't save your payment information.</PageSubTitleLabel>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", marginTop: 30}}>
                <TransactionVew />
            </div>
        </div>
    );
};
