import React from "react";
import "./admin-user-management.css";
import {UserPlusOneForm} from "./UserPlusOneForm.jsx";
import {UserCompanyMasterSearch} from "./UserCompanyMasterSearch.jsx";
import {RemoveUserForm} from "./RemoveUserForm.jsx";

export const AdminUserManagement = () => {
    return (
        <div className="admin-user-management-container">
            <div>
                <div className="top-layout">
                    <span className="title">מי האדמין הראשי שלי?</span>
                    <div>
                        <div>ניתן לאתר את האדמין הראשי של החברה על פי מייל של אחד המשתמשים של החברה</div>
                    </div>
                </div>

                <UserCompanyMasterSearch />
            </div>

            <div>
                <div className="top-layout">
                    <span className="title">ניהול משתמשים</span>
                    <div>
                        <div>לצורך הוספת +1 לכתובת המשתמש יש להזין את המייל הנוכחי שלו למטה</div>
                    </div>
                </div>

                <UserPlusOneForm />
            </div>

            <div>
                <div className="top-layout">
                    <span className="title">מחיקת משתמש</span>
                    <div>
                        <div>לצורך מחיקת המשתמש המשתמש יש להזין את המייל הנוכחי שלו למטה</div>
                    </div>
                </div>

                <RemoveUserForm />
            </div>
        </div>
    );
};
