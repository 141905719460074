import React, {useEffect, useState} from "react";
import PageTitleHeader from "../../components/PageTitle";
import {HttpClient} from "../../http/HttpClient";
import {EventBus} from "../../bus/EventBus";
import {CompanyEventInsights} from "./CompanyEventInsights";
import {CompanyInsightsSummary} from "./CompanyInsightsSummary";

export const CompanyInsights = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const data = await HttpClient.get("/api/companies/insights");
                setData(data);
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we didn't manage to present your company's insights :("}},
                    e.message
                );
            }
        });
    }, []);

    return (
        <div style={{display: "flex", justifyContent: "center", marginTop: 80}}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: 10,
                    paddingRight: 10,
                    maxWidth: 1200,
                    flex: 1,
                    minWidth: 360
                }}>
                <PageTitleHeader style={{marginBottom: 15}}>Insights</PageTitleHeader>
                <CompanyInsightsSummary data={data} />
                <CompanyEventInsights data={data} />
            </div>
        </div>
    );
};
