import React from "react";
import {Logo} from "../../images/Images";
import {Link} from "react-router-dom";
import {StyledButton} from "../../components/StyledButton";
import {UndoIcon} from "../../icons";

export const StepperHeader = ({onBack, progress}) => {
    return (
        <header
            style={{
                height: 80,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: 50,
                paddingRight: 50,
                borderBottom: "1px solid #DAE0E4",
                width: "100%",
                position: "relative"
            }}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center"
                }}>
                <img width="80" height="20" alt="well-b-logo" src={Logo.image} />
                <div
                    style={{
                        color: "#8492A6",
                        fontSize: 16,
                        marginLeft: 30
                    }}>
                    Already have an account? <Link to="/login">Signin</Link>
                </div>
            </div>
            <StyledButton
                style={{
                    boxShadow: "none",
                    border: "none",
                    backgroundColor: "transparent"
                }}
                icon={<UndoIcon />}
                onClick={onBack}
            />
            <hr
                style={{
                    backgroundColor: "#13C296",
                    height: 5,
                    borderTopRightRadius: 25,
                    borderBottomRightRadius: 25,
                    width: "100%",
                    maxWidth: `${progress}%`,
                    position: "absolute",
                    margin: 0,
                    border: "none",
                    bottom: -3,
                    left: 0
                }}
            />
        </header>
    );
};
