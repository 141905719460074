import {GoogleCalendarSyncButton} from "./GoogleCalendarSyncButton";
import React, {useContext, useState} from "react";
import {HttpClient} from "../../../http/HttpClient";
import {message} from "antd";
import {ErrorReporter} from "../../../error/ErrorReporter";
import {AppContext} from "../../../AppContext";

export const EventCalendarInviteEmployeesActions = ({onClose, eventId, dateMom, style}) => {
    const [syncingType, setSyncingType] = useState(null);
    const {company} = useContext(AppContext);

    const date = dateMom.format("YYYY-MM-DD");
    const time = dateMom.format("HH:mm");

    const syncEvent = async type => {
        setSyncingType(type);
        try {
            await HttpClient.post(`/api/events/${eventId}/invite/${type}?date=${date}&time=${time}`);
            message.success("Successfully synced event to your employees calendar.", 3);
            onClose();
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setSyncingType(null);
    };

    return (
        <div
            className="wb-event-calendar-sync"
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                ...style
            }}>
            <GoogleCalendarSyncButton
                loading={syncingType === "google"}
                onSuccess={async () => await syncEvent("google")}
                onFailure={async error => {
                    message.error("We couldn't sync your event, please try again later.", 5);
                    ErrorReporter.reportError(error);
                }}
                onClose={onClose}
                useIcal={company.disabledFeatures?.includes("sync")}
            />
        </div>
    );
};
