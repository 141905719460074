import React from "react";
import {SearchOutlined} from "@ant-design/icons";
import {FormInputV2} from "./form";
import "./search-input.css";

export const SearchInputWithIcon = ({searchInput, setSearchInput, placeholder = "חיפוש חופשי"}) => (
    <FormInputV2
        onChange={e => setSearchInput(e.target.value)}
        suffix={<SearchOutlined width={14} height={14} />}
        className="search-input"
        placeholder={placeholder}
        value={searchInput}
    />
);
