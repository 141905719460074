import React, {useCallback, useContext} from "react";
import {ConfigProvider, Form} from "antd";
import {FormInputV2} from "../../../../components/form";
import "./budget-filter-popover.css";
import {
    budgetFields,
    CategoryToBudgetAmountParticipantFilterText,
    CategoryToPricePerParticipantFilterText,
    CategoryToTotalBudgetPriceFilterText
} from "./consts.js";
import {MarketplaceFilterProviderContext} from "../../MarketplaceFilterContext.jsx";
import {useBudgetUpdates} from "./BudgetHooks.jsx";

export const BudgetFilterInput = ({showAmountInput = true, showBudgetPerParticipantInput = true}) => {
    const {innerState, panel} = useContext(MarketplaceFilterProviderContext);
    const {onAmountParticipantsChange, onBudgetPerParticipant, onOrderBudgetChange, setLatestChangeField} =
        useBudgetUpdates();

    const onParticipantsAmountBlur = useCallback(
        e => {
            if (innerState?.budget?.participantsAmount || "" !== e.target.value) {
                setLatestChangeField(budgetFields.participantsAmount);
            }
        },
        [innerState?.budget]
    );

    const onPricePerParticipantBlur = useCallback(
        e => {
            if (innerState?.budget?.pricePerParticipant?.max || "" !== e.target.value) {
                setLatestChangeField(budgetFields.pricePerParticipant);
            }
        },
        [innerState?.budget]
    );

    const onTotalPriceBlur = useCallback(
        e => {
            if (innerState?.budget?.totalPrice?.max || "" !== e.target.value) {
                setLatestChangeField(budgetFields.totalPrice);
            }
        },
        [innerState?.budget]
    );

    return (
        <>
            <ConfigProvider direction="rtl">
                <div className="products-filters-modal-budget-panel">
                    {showAmountInput && (
                        <Form.Item>
                            <div className="products-filters-modal-budget-participants-amount">
                                <FormInputV2
                                    type="number"
                                    placeholder={CategoryToBudgetAmountParticipantFilterText[panel]}
                                    value={innerState?.budget?.participantsAmount ?? null}
                                    onChange={onAmountParticipantsChange}
                                    className="products-filters-modal-number-input"
                                    onBlur={onParticipantsAmountBlur}
                                />
                            </div>
                            <span className="products-filters-modal-budget-panel-math-sign">X</span>
                        </Form.Item>
                    )}
                    {showBudgetPerParticipantInput && (
                        <Form.Item>
                            <div className="products-filters-modal-budget-participants-amount">
                                <FormInputV2
                                    type="number"
                                    placeholder={CategoryToPricePerParticipantFilterText[panel]}
                                    value={innerState?.budget?.pricePerParticipant?.max ?? null}
                                    onChange={onBudgetPerParticipant}
                                    className="products-filters-modal-number-input"
                                    onBlur={onPricePerParticipantBlur}
                                />
                            </div>
                            <span className="products-filters-modal-budget-panel-math-sign">=</span>
                        </Form.Item>
                    )}
                    <Form.Item>
                        <div className="products-filters-modal-budget-participants-amount">
                            <FormInputV2
                                type="number"
                                onChange={onOrderBudgetChange}
                                placeholder={CategoryToTotalBudgetPriceFilterText[panel]}
                                className="products-filters-modal-number-input"
                                value={innerState?.budget?.totalPrice?.max ?? null}
                                onBlur={onTotalPriceBlur}
                            />
                        </div>
                    </Form.Item>
                </div>
            </ConfigProvider>
        </>
    );
};
