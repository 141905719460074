import {orderBy} from "lodash";
import moment from "moment";
import {ConsumptionOccasionType, OccasionPriceType, TechnologyId, TechnologyPrice} from "./consts";

export const getCategoriesSummary = (categories, employeesAmount, monthAmounts = 1) => {
    if (!categories) {
        return [];
    }

    const enrichedCategories = Object.values(categories)
        .filter(({disabled, occasions}) => !disabled && occasions?.length)
        .map(categoryData => {
            const [totalPrice, pricePerEmployee, occasionsCount] = categoryData.occasions.reduce(
                (sum, {isActive, price, odds, type, priceType, actualStartDate, extraDates}) => {
                    if (
                        !isActive ||
                        !price ||
                        (type === ConsumptionOccasionType.LifeCycle && !Number(odds)) ||
                        (categoryData.isRangePolicy && !actualStartDate)
                    ) {
                        return sum;
                    }

                    const parsedPrice = Number(price);
                    const parsedOdds = Number(odds);

                    const oddsOccasionPerEmployee = parsedOdds / 100 / monthAmounts;

                    const occasionPrice =
                        priceType === OccasionPriceType.byGroup ? parsedPrice / employeesAmount : parsedPrice;

                    const occasionsAmount = actualStartDate
                        ? 1 + (extraDates?.length || 0)
                        : oddsOccasionPerEmployee || 1;

                    const pricePerEmployee = occasionPrice * occasionsAmount;

                    return [
                        sum[0] + pricePerEmployee * employeesAmount,
                        sum[1] + pricePerEmployee,
                        sum[2] + occasionsAmount
                    ];
                },
                [0, 0, 0]
            );
            return {
                ...categoryData,
                totalPrice,
                pricePerEmployee,
                occasionsCount
            };
        });

    if (categories[TechnologyId]?.benefits.some(({isActive}) => isActive)) {
        const totalTechPrice = monthAmounts * TechnologyPrice;
        enrichedCategories.push({
            ...categories[TechnologyId],
            totalPrice: totalTechPrice * employeesAmount,
            pricePerEmployee: totalTechPrice
        });
    }

    return orderBy(
        enrichedCategories.filter(({totalPrice}) => totalPrice),
        "totalPrice",
        "desc"
    );
};

export const transformAndPrioritizeNewOccasions = (occasions, maxAmountInPrioritized, planTimeRange) => {
    const sortedOccasions = orderBy(occasions, ["priority", "startDate"], ["asc", "asc"]).map(occasion => ({
        ...occasion,
        price: occasion.minPrice,
        actualStartDate: occasion.actualStartDate ?? pickDateForOccasion(occasion, planTimeRange)
    }));

    const unPrioritizedOccasions = sortedOccasions.splice(maxAmountInPrioritized);

    return [sortedOccasions, unPrioritizedOccasions];
};

export const mergePresetWithTableData = (presetTableData, currentTableData) =>
    Object.values(currentTableData).reduce((acc, tableCategory) => {
        const presetCategory = presetTableData[tableCategory.occasionCategoryId];

        if (!presetCategory) {
            return {
                ...acc,
                [tableCategory.occasionCategoryId]: tableCategory
            };
        }

        const occasions = tableCategory.occasions.reduce((allOccasions, currentOccasion) => {
            const presetOccasion = presetCategory.occasions.find(
                ({occasionId}) => occasionId === currentOccasion.occasionId
            );

            const occasionToAdd = presetOccasion
                ? {
                      ...currentOccasion,
                      ...presetOccasion
                  }
                : {
                      ...currentOccasion,
                      isActive: false,
                      price: currentOccasion.minPrice
                  };

            return [...allOccasions, occasionToAdd];
        }, []);

        return {
            ...acc,
            [tableCategory.occasionCategoryId]: {
                ...tableCategory,
                disabled: presetCategory.disabled,
                occasions
            }
        };
    }, {});

export const transformTableDataForSaving = tableData => {
    return Object.values(tableData).reduce(
        (acc, category) => ({
            ...acc,
            [category.occasionCategoryId]: {
                occasionCategoryId: category.occasionCategoryId,
                isRangePolicy: category.isRangePolicy,
                occasions: category.occasions.reduce(
                    (allOccasion, occasion) => [
                        ...allOccasion,
                        ...(occasion.isActive
                            ? [
                                  {
                                      occasionId: occasion.occasionId,
                                      price: occasion.price,
                                      odds: occasion.odds,
                                      priceType: occasion.priceType ?? OccasionPriceType.byParticipant
                                  }
                              ]
                            : [])
                    ],
                    []
                )
            }
        }),
        {}
    );
};

const pickDateForOccasion = (occasion, planTimeRange) => {
    if (!occasion.startDate) {
        return null;
    }

    const allowedDates = [];
    const allowedWeekEndDates = [];

    for (
        let currentDay = moment(occasion.startDate);
        currentDay.isSameOrBefore(occasion.endDate, "day");
        currentDay.add(1, "day")
    ) {
        if (currentDay.isBetween(planTimeRange[0], planTimeRange[1], "day")) {
            currentDay.day() >= 5
                ? allowedDates.push(currentDay.clone())
                : allowedWeekEndDates.push(currentDay.clone());
        }
    }

    if (allowedDates.length) {
        return allowedDates[0];
    }

    return allowedWeekEndDates[0];
};
