import React, {useCallback, useContext, useMemo, useState} from "react";
import classnames from "classnames";
import {message} from "antd";
import {ColumnsNumber} from "./consts";
import {ProgressiveImageLoader} from "../../components";
import {Like} from "../../components/Like";
import {useWindowDimensions} from "../../utils/hooks";
import {HttpClient} from "../../http/HttpClient";
import {createProductLink} from "../../marketplace/MarketplaceHelper";
import {AppContext} from "../../AppContext";
import {createAutoQualityVideoUrl} from "../../utils/cloudinaryUtils";
import {NOOP} from "../../utils/NOOP";

export const EventGalleryItem = ({
    history,
    productId,
    product,
    image,
    like,
    setLikeImages,
    log = NOOP,
    openProductsInNewTab = false,
    customImageClass = false
}) => {
    const {me, services} = useContext(AppContext);

    const [isLoadingLike, setIsLoadingLike] = useState(false);

    const {width} = useWindowDimensions();

    const innerLog = useCallback(
        message => {
            log(message, {
                productId,
                productName: product?.service.productName,
                providerName: product?.provider.businessName
            });
        },
        [log, productId, product]
    );

    const onImageClicked = useCallback(async () => {
        let url = `/dashboard/services/product/${productId}`;

        const logProperties = {
            productId
        };

        if (product) {
            url = createProductLink(
                {service: product.service, provider: {providerId: product.service.providerId}},
                {
                    me,
                    services
                }
            );

            logProperties.productName = productId;
        }

        innerLog("Event Image Clicked");

        openProductsInNewTab ? window.open(url, "_blank", "noreferrer") : history.push(url);
    }, [productId, innerLog, openProductsInNewTab, product, me, services]);

    const imageMaxWidth = useMemo(() => Math.floor(width / ColumnsNumber - 50), [width]);

    const likeTargetHandler = useCallback(
        async imageId => {
            const newLike = await HttpClient.safePost(`/api/like/likeItem/orderImage?targetId=${imageId}`);

            if (newLike.error) {
                message.error("לא ניתן לסמן לייק לתמונה זאת");
                return;
            }

            innerLog("Liked Image");

            setLikeImages(currentLikeImages => [...currentLikeImages, newLike]);
        },
        [innerLog]
    );

    const unlikeTargetHandler = useCallback(
        async imageId => {
            const {error} = await HttpClient.safeDelete(`/api/like/unlikeItem/${imageId}`);

            if (error) {
                message.error("לא ניתן להוריד לייק לתמונה זאת");
                return;
            }

            innerLog("Unliked Image");

            setLikeImages(current => current.filter(like => like.targetId !== imageId));
        },
        [innerLog]
    );

    return (
        <div className={classnames("events-gallery-content-container", "like-actions-object")}>
            <div className="event-gallery-item-details">
                <span>{product?.service?.productName}</span>
                <span>{product?.service?.brandName || product?.service?.engBrandName}</span>
            </div>

            <div
                className={classnames("events-gallery-image", {"events-gallery-custom-image": customImageClass})}
                onClick={() => onImageClicked(productId)}>
                {image.type.split("/")[0] === "video" ? (
                    <video className="events-gallery-video" autoPlay={true} loop={true} muted={true}>
                        <source src={createAutoQualityVideoUrl(image?.url)} type={image?.type} />
                    </video>
                ) : (
                    <ProgressiveImageLoader maxWidth={imageMaxWidth} src={image.url} />
                )}
            </div>
            <Like
                disabled={isLoadingLike}
                checked={like}
                onChange={async checked => {
                    setIsLoadingLike(true);
                    if (checked) {
                        await likeTargetHandler(`${image.id}:${productId}`);
                    } else {
                        await unlikeTargetHandler(`${image.id}:${productId}`);
                    }
                    setIsLoadingLike(false);
                }}
            />
        </div>
    );
};
