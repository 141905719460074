import React, {useContext, useEffect, useReducer, useState} from "react";
import {message} from "antd";
import {HttpClient} from "../http/HttpClient";
import {Calendar} from "../components/Calendar";
import {AppContext} from "../AppContext";
import {PageTitleLabel} from "../components/PageTitle";
import {EventMapper} from "../event/EventMapper";
import EventInfoModal from "../event/EventInfoModal";
import {EventCustomSessionModal} from "../event/EventCustomSessionModal";
import {EventCreateModal} from "../event/EventCreateModal";
import {EventBus} from "../bus/EventBus";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {LocalEventsUpdater} from "../event/LocalEventsUpdater";
import {EventReminderModal} from "../event/EventReminderModal";
import {parse as qsParse} from "query-string";
import {eventByDate} from "../event/EventByDate";
import moment from "moment-timezone";
import {toEventText} from "../center/utils/EventUtil";
import {PageLoader} from "../components/PageLoader";
import {withRouter} from "react-router-dom";
import {removeKeysFromSearch} from "../AppHistoryAdapter";
import {SpecialEventModal} from "../event/special/SpecialEventModal";
import {CompanyEventEdit} from "./CompanyEventEdit";
import {EventCalendarSyncModal} from "../event/info/sync/EventCalendarSyncModal";
import {EventCalendarInviteEmployeesModal} from "../event/info/sync/EventCalendarInviteEmployeesModal";

export const announceSyncResult = syncResult => {
    if (syncResult === "success") {
        message.success("Successfully synced event.", 5);
    } else if (syncResult === "fail") {
        message.error("Unfortunately we couldn't sync the event, please try again later.", 7);
    }
};

const CompanySchedule = ({
    history,
    hideTitle = false,
    onlyRegistered = false,
    hideCustomSession = false,
    hideSpecialEvents = false,
    enableSpecialInteraction = true,
    className,
    style,
    maxHeight,
    category,
    term
}) => {
    const {me, company} = useContext(AppContext);
    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        events: null,
        clickedEvent: {},
        eventInstance: {},
        newSessionDate: null,
        newSessionWeek: null,
        employees: [],
        appointments: null,
        specialEvent: null,
        hideSpecialEvents: company.hideSpecialEvents,
        eventToEdit: null,
        eventToInvite: {}
    });

    const [inviteModalVisibility, setInviteModalVisibility] = useState(false);
    const [createEventModalVisibility, setCreateEventModalVisibility] = useState(false);

    useEffect(() => {
        listenToSpecialEventClick();

        Promise.resolve().then(async () => {
            await Promise.all([fetchEmployees(), fetchEvents()]);

            syncResultPopup();
        });
    }, []);

    const listenToSpecialEventClick = () => {
        EventBus.on("special_event:click", specialEvent => {
            setState({specialEvent});
        });
    };

    const onSpecialEventsVisibilityChange = () => {
        company.hideSpecialEvents = !company.hideSpecialEvents;

        Promise.resolve().then(async () => {
            try {
                await HttpClient.post("/api/companies", company);
            } catch (e) {
                message.error("Something went wrong, please try again later.");
            }
        });

        setState({hideSpecialEvents: company.hideSpecialEvents});
    };

    const fetchEmployees = async () => {
        try {
            const employees = await HttpClient.get("/api/employees");
            setState({employees});
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we didn't manage to preset your schedule :("}},
                e.message
            );
        }
    };

    const fetchEvents = async () => {
        try {
            let appointments = null;
            if (onlyRegistered) {
                appointments = await HttpClient.get("/api/appointments");
            }

            const events = await HttpClient.get("/api/events?specials=true");
            setState({
                events,
                companyName: me.companyName,
                companyId: me.companyId,
                appointments
            });
            instantOpen(events);
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we didn't manage to preset your schedule :("}},
                e.message
            );
        }
    };

    const instantOpen = events => {
        const instantEventOpen = sessionStorage.getItem("instantEventOpen");
        const {eventId, date} = instantEventOpen ? JSON.parse(instantEventOpen) : qsParse(location.search);
        if (eventId && date) {
            let event = events.find(evt => evt.eventId === eventId);
            if (event) {
                event = eventByDate(event, moment(date, "YYYY-MM-DD").format("YYYY-M-D"));
                const dateMom = moment(date, "YYYY-MM-DD");
                setState({
                    eventInstance: {
                        start: dateMom.toDate(),
                        end: null
                    },
                    clickedEvent: event
                });
                sessionStorage.removeItem("instantEventOpen");

                if (location.search) {
                    const search = removeKeysFromSearch(["eventId", "date"]);
                    history.replaceWithoutReload(`${location.pathname}${search ? `?${search}` : ""}`);
                }
            }
        }
    };

    const syncResultPopup = () => {
        const {sync} = qsParse(location.search);
        const storedSyncResult = sessionStorage.getItem("syncResult");
        announceSyncResult(storedSyncResult || sync);
        sessionStorage.removeItem("syncResult");
    };

    const onEventClick = (clickedEvent, eventInstance) => {
        if (["cancel-pending"].some(status => clickedEvent.status === status)) return;
        GoogleAnalytics.event("Company Schedule", "Session Info", clickedEvent.eventId);
        setState({clickedEvent, eventInstance});
    };

    const onEventUpdate = updatedEvt => {
        const {events, appointments} = state;
        setState({
            clickedEvent: {},
            eventInstance: {},
            events: updatedEvt.type !== "appointment" ? LocalEventsUpdater.massUpdate(events, updatedEvt) : events,
            appointments:
                updatedEvt.type === "appointment"
                    ? appointments.map(appt => {
                          if (appt.appointmentId === updatedEvt.appointmentId) {
                              return updatedEvt;
                          } else {
                              return appt;
                          }
                      })
                    : appointments
        });
    };

    const onUpdateSingleEvent = async updatedEvt => {
        const {events} = state;

        let originalEvent = null;
        if (updatedEvt.newEvent) {
            originalEvent = updatedEvt.originalEvent;
            updatedEvt = updatedEvt.newEvent;
        }

        const newStart = updatedEvt.includeDate || updatedEvt.dtstart;

        setState({
            eventInstance: {
                start: new Date(newStart),
                end: new Date(newStart + parseInt(updatedEvt.duration, 10) * 60 * 1000)
            },
            clickedEvent: updatedEvt,
            events: LocalEventsUpdater.massUpdate(events, updatedEvt, originalEvent)
        });
    };

    const onCustomEventSave = async values => {
        const {events} = state;
        if (values) {
            try {
                GoogleAnalytics.event("Company Schedule", "Create Internal Session", me.companyId);
                const event = await HttpClient.put("/api/events/custom", values);
                events.push(event);
                setState({events});
                return true;
            } catch (e) {
                const provider = e.extraInfo;
                if (e.message && e.message.indexOf("already registered") >= 0 && provider.type === "provider") {
                    EventBus.trigger("server-error", {
                        content: {
                            title: "OMG!",
                            subTitle: "You probably didn't notice...",
                            description: [
                                `${values.providerFirstName} is already registered to Well B...ֿ`,
                                "Please send'em an offer through their profile ;)"
                            ],
                            hideSteps: true
                        },
                        cta: {
                            text: "Open profile",
                            path: `/providers/${provider.userId}`
                        }
                    });
                } else if (e.message && e.message.indexOf("already registered") >= 0) {
                    EventBus.trigger("server-error", {
                        content: {
                            title: "OMG!",
                            subTitle: "You probably didn't notice...",
                            description: [`${values.providerFirstName} is already registered to Well B...ֿ`],
                            hideSteps: true
                        },
                        cta: {
                            hide: true
                        }
                    });
                } else if (e.message === "already in sign up process") {
                    EventBus.trigger("server-error", {
                        content: {
                            title: "Psst...",
                            subTitle: "We couldn't make it...",
                            description: [
                                `${values.providerFirstName} hasn't completed the sign up to Well B...ֿ`,
                                "Please ask'em to speed up, we can't wait to meet them ;)"
                            ],
                            hideSteps: true
                        },
                        cta: {
                            hide: true
                        }
                    });
                } else {
                    EventBus.triggerError(
                        "server-error",
                        {content: {description: "Unfortunately we couldn't create your custom session :("}},
                        e.message
                    );
                }
                return false;
            }
        } else {
            return false;
        }
    };

    const dismissForever = async event => {
        const {events} = state;
        setState({
            events: events.filter(evt => evt.eventId !== event.eventId),
            clickedEvent: {},
            eventInstance: {}
        });
        try {
            await HttpClient.delete(`/api/specials/${event.eventId}/dismiss`);
        } catch (e) {
            message.error("something went wrong, please try again later.", 3);
        }
    };

    const buildProviderName = evt => {
        if (evt.sessionType === "special") {
            return "";
        }

        const statusMap = {
            accepted:
                evt.providerBrand ||
                `${evt.providerFirstName}${evt.providerLastName ? ` ${evt.providerLastName}` : ""}`,
            pending: "Pending approval",
            "cancel-pending": "Pending cancellation"
        };

        return statusMap[evt.status];
    };

    const tooltipText = evt => {
        if (evt.sessionType === "special") {
            return "Click to setup an event";
        }

        if (me.type === "company") {
            const statusMap = {
                accepted: "Click to setup the event",
                pending: "Pending approval, click to cancel or send a reminder.",
                "cancel-pending": "We are processing your request and will update soon."
            };

            return statusMap[evt.status];
        }

        return "Click to join";
    };

    const byCategoryAndTerm = events => {
        if (!category && !term) {
            return events;
        }

        return events
            .filter(evt => {
                const text = toEventText(evt);
                return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
            })
            .filter(evt => {
                return !category || evt.serviceName === category || (evt.sessionName && category === "Internal");
            });
    };

    const addAppointments = events => {
        const {appointments} = state;
        if (!appointments) {
            return events;
        }

        return events.concat(
            appointments.filter(appt => appt.status !== "cancelled").map(EventMapper.toAppointmentOnCalendar)
        );
    };

    const filterNotRelevant = events => {
        if (company.hideSpecialEvents || hideSpecialEvents) {
            events = events.filter(evt => evt.sessionType !== "special");
        }
        if (me.type === "employee") {
            events = events.filter(evt => !evt.v2);
        }

        return events.filter(evt => evt.status !== "cancelled");
    };

    const setWeeklyFormattedEvent = async (clickedEvent, eventInstance) => {
        const weeklyFormattedEvent = await HttpClient.get(
            `/api/weekly/events/${clickedEvent.eventId}/${moment(eventInstance.start).format("YYYY-MM-DD")}/${moment(
                eventInstance.end
            ).format("YYYY-MM-DD")}`
        );
        setState({eventToEdit: weeklyFormattedEvent});
    };

    const onSingleEventSend = async (event, isTest = false) => {
        const start = moment(eventInstance.start).format("YYYY-MM-DD");
        const end = moment(eventInstance.end).format("YYYY-MM-DD");
        try {
            await HttpClient.post(`/api/event/publish?start=${start}&end=${end}&isTest=${isTest}`, event);
            message.success("Email was sent successfully!", 3);
            setState({eventToEdit: null});
        } catch (e) {
            message.error("Oppsy... something went wrong, please try again later.", 3);
        }
    };

    const {events, clickedEvent, eventInstance, companyName, newSessionDate, newSessionWeek, employees, specialEvent} =
        state;

    const fixDescriptionForEvent = event => {
        if (event) {
            event.product.description.text = event.notes;
        }
        return event;
    };

    return (
        <div
            className={`wb-company-schedule ${className}`}
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 20,
                paddingRight: 20,
                marginBottom: 100,
                ...style
            }}>
            <PageTitleLabel style={{display: hideTitle ? "none" : "flex"}}>
                {companyName ? `${companyName} activities` : null}
            </PageTitleLabel>
            <SpecialEventModal
                enableSpecialInteraction={enableSpecialInteraction}
                specialEvent={specialEvent}
                onClose={() => setState({specialEvent: null})}
                history={history}
                onInternalEventClick={() => {
                    setState({
                        specialEvent: null,
                        newSessionDate: specialEvent.date
                    });
                }}
            />
            <CompanyEventEdit
                event={fixDescriptionForEvent(state.eventToEdit)}
                dateMom={moment(eventInstance.start)}
                visible={state.eventToEdit !== null}
                onClose={() => setState({eventToEdit: null})}
                onSend={event => onSingleEventSend(event)}
                onTest={event => onSingleEventSend(event, true)}
                onChange={event => setState({eventToEdit: event})}
            />
            <EventCalendarInviteEmployeesModal
                event={state.eventToInvite}
                onClose={() => {
                    setInviteModalVisibility(false);
                    setState({
                        eventToInvite: {}
                    });
                }}
                visible={inviteModalVisibility}
                dateMom={moment(eventInstance.start)}
            />
            <EventInfoModal
                event={clickedEvent}
                currentStart={eventInstance.start}
                currentEnd={eventInstance.end}
                onClose={updatedEvt => onEventUpdate(updatedEvt)}
                onUpdateEvent={async (updatedEvt, applyType) => await onUpdateSingleEvent(updatedEvt, applyType)}
                visible={
                    !!clickedEvent.eventId && ["accepted", "confirmed"].some(status => clickedEvent.status === status)
                }
                onNewCustomEvent={() => {
                    setState({
                        newSessionDate: void 0,
                        newSessionWeek: void 0,
                        clickedEvent: {},
                        eventInstance: {}
                    });
                }}
                onDismiss={dismissForever}
                onSendEvent={async () => {
                    await setWeeklyFormattedEvent(clickedEvent, eventInstance);
                }}
                onCalendarInvite={() => {
                    setState({
                        eventToInvite: clickedEvent
                    });
                    setInviteModalVisibility(true);
                }}
                openActivityDetails={() => {
                    history.push(`/events/${clickedEvent.eventId}/orderV2`);
                }}
            />
            <EventReminderModal
                visible={!!clickedEvent.eventId && clickedEvent.status === "pending"}
                event={clickedEvent}
                onClose={updatedEvt => onEventUpdate(updatedEvt)}
            />
            {me.type === "company" && !hideCustomSession ? (
                <EventCreateModal
                    visible={createEventModalVisibility}
                    date={newSessionDate}
                    onSave={async values => await onCustomEventSave(values)}
                    onClose={() => setCreateEventModalVisibility(false)}
                    employees={employees}
                />
            ) : null}
            {events === null ? (
                <PageLoader align="flex-start" top={80} />
            ) : (
                <Calendar
                    height={maxHeight}
                    enableSpecialInteraction={enableSpecialInteraction}
                    onSpecialEventsVisibilityChange={onSpecialEventsVisibilityChange}
                    onNewSession={
                        me.type === "company" && !hideCustomSession
                            ? (newSessionDate, newSessionWeek) => {
                                  setState({newSessionDate, newSessionWeek});
                                  setCreateEventModalVisibility(true);
                              }
                            : null
                    }
                    pendingCursor="pointer"
                    events={addAppointments(
                        filterNotRelevant(byCategoryAndTerm(events)).reduce((allEvts, evt) => {
                            const providerName = buildProviderName(evt);
                            return allEvts.concat(
                                EventMapper.reduce({
                                    event: evt,
                                    title: `${evt.productName || evt.serviceName || evt.sessionName}`,
                                    subtitle: providerName,
                                    tooltipText: tooltipText(evt),
                                    onlyRegistered,
                                    me
                                })
                            );
                        }, [])
                    )}
                    onEventClick={(clickedEvent, eventInstance) => onEventClick(clickedEvent, eventInstance)}
                />
            )}
        </div>
    );
};

export default withRouter(CompanySchedule);
