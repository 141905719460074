import React, {useCallback} from "react";
import "../../../admin/upload/admin-upload.css";
import "./provider-site-form.css";
import {Checkbox, ConfigProvider, Form} from "antd";
import {AutoCompleteAddressFormInput} from "../../../utils/AutoCompleteAddress.jsx";
import {FormInputV2} from "../../../components/form/index.jsx";
import {addAddressAreaId} from "../../../admin/upload/AdminUploadHelper.jsx";
import {isEmpty} from "lodash";
import common from "@well-b/well-b-shared";
import {PHONE_NUMBER_ERROR_VALIDATION} from "../../../marketplace/consts.jsx";

export const ProviderSitesForm = ({formValues, onUpdateSite, readOnly = false}) => {
    const onAddressChange = useCallback(
        async (newAddress, {place_id}) => {
            const [address] = await addAddressAreaId([{name: newAddress, place_id}]);
            onUpdateSite("address.name", newAddress, formValues?.siteId);
            onUpdateSite("address.placeId", place_id, formValues?.siteId);
            onUpdateSite("address.areaId", address?.areaId, formValues?.siteId);
            onUpdateSite("address.subAreaId", address?.subAreaId, formValues?.siteId);
        },
        [onUpdateSite, formValues?.siteId]
    );

    const onClearAddress = useCallback(
        address => {
            if (isEmpty(address)) {
                onUpdateSite("address", null, formValues?.siteId);
            }
        },
        [onUpdateSite, formValues?.siteId]
    );

    return (
        <div className="provider-site-form-container">
            <Form>
                <ConfigProvider direction="rtl">
                    <div className="site-basic-details">
                        <div>
                            <label>שם הסניף *</label>
                            <FormInputV2
                                disabled={readOnly}
                                status={isEmpty(formValues?.name) ? "error" : null}
                                placeholder="שם הסניף"
                                defaultValue={formValues?.name}
                                onChange={e => onUpdateSite("name", e.target.value, formValues?.siteId)}
                            />
                        </div>

                        <div>
                            <label>כתובת הסניף *</label>
                            <AutoCompleteAddressFormInput
                                showDefaultOption={false}
                                onInputChange={onClearAddress}
                                disabled={readOnly}
                                statusInput={isEmpty(formValues?.address?.name) ? "error" : null}
                                editValue={formValues?.address?.name}
                                placeholder="כתובת הסניף"
                                countriesToSearchIn={["IL"]}
                                allowSelectRegularText={false}
                                onChange={onAddressChange}
                            />
                        </div>
                    </div>

                    <div className="operation-person-contact-container">
                        <div className="operation-person-contact-container-title"> איש קשר בסניף לתפעול הזמנות *</div>
                        <div className="site-basic-details">
                            <FormInputV2
                                disabled={readOnly}
                                status={isEmpty(formValues?.operationalContactFirstName) ? "error" : null}
                                placeholder="שם פרטי"
                                defaultValue={formValues?.operationalContactFirstName}
                                onChange={e =>
                                    onUpdateSite("operationalContactFirstName", e.target.value, formValues?.siteId)
                                }
                            />

                            <FormInputV2
                                disabled={readOnly}
                                status={isEmpty(formValues?.operationalContactLastName) ? "error" : null}
                                placeholder="שם משפחה"
                                defaultValue={formValues?.operationalContactLastName}
                                onChange={e =>
                                    onUpdateSite("operationalContactLastName", e.target.value, formValues?.siteId)
                                }
                            />
                        </div>
                        <div className="site-basic-details">
                            <FormInputV2
                                disabled={readOnly}
                                status={isEmpty(formValues?.operationalContactEmail) ? "error" : null}
                                placeholder="מייל"
                                defaultValue={formValues?.operationalContactEmail}
                                onChange={e =>
                                    onUpdateSite("operationalContactEmail", e.target.value, formValues?.siteId)
                                }
                            />
                            <Form.Item
                                name="operationalContactPhone"
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            return common.isValidPhoneMobileNumber(value)
                                                ? Promise.resolve()
                                                : Promise.reject(PHONE_NUMBER_ERROR_VALIDATION);
                                        }
                                    }
                                ]}>
                                <FormInputV2
                                    disabled={readOnly}
                                    status={isEmpty(formValues?.operationalContactPhone) ? "error" : null}
                                    placeholder="טלפון (רק ספרות)"
                                    defaultValue={formValues?.operationalContactPhone}
                                    onChange={e =>
                                        onUpdateSite("operationalContactPhone", e.target.value, formValues?.siteId)
                                    }
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="operation-person-contact-container">
                        <Checkbox
                            checked={!!formValues?.address?.isAccessible}
                            value={formValues?.address?.isAccessible ?? null}
                            disabled={readOnly}
                            onChange={e => onUpdateSite("address.isAccessible", e.target.checked, formValues?.siteId)}>
                            נגיש
                        </Checkbox>

                        <Checkbox
                            checked={!!formValues?.address?.isSelfPickupPossible}
                            disabled={readOnly}
                            value={formValues?.address?.isSelfPickupPossible ?? null}
                            onChange={e =>
                                onUpdateSite("address.isSelfPickupPossible", e.target.checked, formValues?.siteId)
                            }>
                            איסוף עצמי
                        </Checkbox>

                        <FormInputV2
                            disabled={readOnly}
                            placeholder="הנחיות לאיסוף עצמי"
                            defaultValue={formValues?.address?.directionInfo}
                            onChange={e => onUpdateSite("address.directionInfo", e.target.value, formValues?.siteId)}
                        />
                    </div>
                </ConfigProvider>
            </Form>
        </div>
    );
};
