import {MainServices} from "../../consts.js";

export const noCategoryOption = {key: "noCategory", label: "ללא קטגוריה"};

export const categoryTabs = [
    MainServices.HAPPY_HOURS,
    MainServices.PACKAGES,
    MainServices.WORKSHOPS,
    noCategoryOption.key
];
