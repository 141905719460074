import React from "react";
import moment from "moment-timezone";
import {Dropdown, message, Switch} from "antd";
import {CopyFilled, DeleteFilled, DownOutlined, EditFilled, EyeFilled, TagFilled} from "@ant-design/icons";
import {DEFAULT_PAGE} from "./consts";
import {ConfirmationRequiredWrapper, CopyableSpan} from "../../../components";
import {NOOP} from "../../../utils/NOOP";
import {ServiceIdToName} from "../../../consts.js";
import common from "@well-b/well-b-shared";

export const columns = (
    me,
    currentTablePage,
    copyProductLinkToClipboard = NOOP,
    openLinkInNewTab = NOOP,
    deleteProduct = NOOP,
    editProduct = NOOP,
    editProductTags = NOOP,
    editProductStatus = NOOP
) => [
    {
        title: "#",
        key: "#",
        render(_, __, idx) {
            const calculatedIndex = ((currentTablePage ?? 1) - 1) * DEFAULT_PAGE + idx + 1;
            return `${calculatedIndex}`;
        }
    },
    {
        title: "מזהה מוצר",
        key: "productId",
        dataIndex: "productId",
        render: productId => {
            return <CopyableSpan>{productId}</CopyableSpan>;
        }
    },
    {
        title: "שם המוצר",
        dataIndex: "productName",
        sorter: (a, b) => a.productName.localeCompare(b.productName)
    },
    {
        title: "שם השותף",
        key: "provider",
        render: (_, {provider, providerId}) => {
            return (
                <div>
                    {me.features?.admin_debug ? (
                        <CopyableSpan textToCopy={providerId}>
                            <div>{provider?.businessName}</div>
                            <div>
                                {provider?.contactFirstName} | {provider?.contactPhoneNumber ?? "אין טלפון"}
                            </div>
                        </CopyableSpan>
                    ) : (
                        <>
                            <div>{provider?.businessName}</div>
                            <div>
                                {provider?.contactFirstName} | {provider?.contactPhoneNumber ?? "אין טלפון"}
                            </div>
                        </>
                    )}
                </div>
            );
        },
        sorter: (a, b) => a?.provider?.businessName.localeCompare(b?.provider?.businessName)
    },
    {
        title: "תאריך יצירה",
        dataIndex: "createdAt",
        render: createdAt => {
            return <>{moment(createdAt).format("DD.MM.YY | HH:mm")}</>;
        },
        sorter: (a, b) => a.createdAt - b.createdAt
    },
    {
        title: "קטגוריות",
        key: "categories",
        render: ({services}) => {
            const menu = {
                items: services?.map((category, index) => ({
                    key: index,
                    label: ServiceIdToName?.[category] ?? category
                }))
            };
            return (
                <>
                    {services.length > 1 ? (
                        <Dropdown menu={menu}>
                            <a onClick={e => e.preventDefault()}>
                                {services?.length} קטגוריות <DownOutlined />
                            </a>
                        </Dropdown>
                    ) : (
                        <>{[menu]?.label ?? "ללא קטגוריה"}</>
                    )}
                </>
            );
        }
    },
    {
        title: "כמות מכירות ב3 חודשים האחרונים",
        dataIndex: "recentOrdersAmount",
        render: recentOrdersAmount => {
            return <>{recentOrdersAmount ?? "X"}</>;
        },
        sorter: (a, b) => (a.recentOrdersAmount ?? 0) - (b.recentOrdersAmount ?? 0)
    },
    {
        title: "סטאטוס",
        key: "active",
        render: (_, product) => {
            const {isActive, provider, providerId, productId, updatedAt} = product;
            const isProviderApproved = provider?.approved;
            return (
                <Switch
                    disabled={!isProviderApproved || !!common.isProductValid(product)?.error}
                    checked={!!isActive}
                    checkedChildren={isProviderApproved ? "פעיל" : "ספק לא אושר"}
                    unCheckedChildren={isProviderApproved ? "לא פעיל" : "ספק לא אושר"}
                    onChange={newStatus => editProductStatus({providerId, productId, status: newStatus, updatedAt})}
                />
            );
        },
        sorter: (a, b) => (a.isActive && a.provider.approved) - (b.isActive && b.provider.approved)
    },
    {
        title: "",
        key: "actions",
        render(_, product) {
            return (
                <div className="admin-product-table-actions-container">
                    <EyeFilled
                        onClick={() => {
                            const allServices =
                                product.services instanceof Array ? product.services : [product.serviceId];
                            const selectedServiceId = allServices[0];
                            openLinkInNewTab(selectedServiceId, product.providerId, product.productId);
                        }}
                    />
                    <EditFilled onClick={() => editProduct(product)} />
                    <CopyFilled
                        onClick={e => {
                            e.stopPropagation();
                            const {productId, providerId} = product;
                            const allServices =
                                product.services instanceof Array ? product.services : [product.serviceId];
                            const serviceId = allServices[0];
                            copyProductLinkToClipboard(serviceId, providerId, productId);
                            message.success("הקישור הועתק ללוח");
                        }}
                    />
                    <TagFilled
                        onClick={() => {
                            editProductTags(product);
                        }}
                    />
                    <ConfirmationRequiredWrapper
                        onYes={() => {
                            const {provider, productId} = product;
                            deleteProduct(provider.providerId, productId);
                        }}
                        subTitle="האם אתה בטוח שברצונך למחוק את המוצר?">
                        <DeleteFilled />
                    </ConfirmationRequiredWrapper>
                </div>
            );
        }
    }
];
