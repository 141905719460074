import React from "react";
import classnames from "classnames";
import {AutoComplete, Form, Input, Select, Switch, Tooltip} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {FormInputV2} from "./form";
import {AutoCompleteAddressFormInput} from "../utils/AutoCompleteAddress";
import "./FormModal/form-modal.css";

const {Option} = Select;
const {TextArea} = Input;

export const getInputComponent = input => {
    let component;

    switch (input.type) {
        case "custom":
            component = <Form.Item name={input.name}>{input.customComponent}</Form.Item>;
            break;
        case "address":
            component = <AutoCompleteAddressFormInput allowSelectRegularText={true} editValue={input.value} />;
            break;
        case "boolean":
            component = (
                <Form.Item name={input.name} valuePropName={"checked"}>
                    <Switch
                        disabled={input.disabled}
                        checkedChildren={input.checkedChildren}
                        unCheckedChildren={input.unCheckedChildren}
                    />
                </Form.Item>
            );
            break;
        case "select":
            component = (
                <Select
                    id={input.id}
                    disabled={input.disabled}
                    mode={input.multiple ? "multiple" : ""}
                    placeholder={input.placeholder}>
                    {input.options?.map(option => (
                        <Option key={option.name} value={option.name} disabled={option.disabled}>
                            {option.label}

                            {option.info ? (
                                <Tooltip title={option.info}>
                                    <QuestionCircleOutlined className="form-modal-input-info" />
                                </Tooltip>
                            ) : null}
                        </Option>
                    ))}
                </Select>
            );
            break;
        case "autocomplete":
            component = (
                <AutoComplete disabled={input.disabled} placeholder={input.placeholder}>
                    {input.options?.map(option => (
                        <Option key={option.name} value={option.name}>
                            {option.label}
                        </Option>
                    ))}
                </AutoComplete>
            );
            break;
        case "multi":
            component = (
                <div className={classnames("form-modal-multiple-input-line", input.className)}>
                    {input.inputs.map(subInput => (
                        <div
                            className={classnames("form-modal-multiple-input-sub-input", subInput.className)}
                            key={subInput.name}>
                            {subInput.label ? (
                                <label>
                                    {subInput.label}
                                    {subInput.info ? (
                                        <Tooltip title={subInput.info}>
                                            <QuestionCircleOutlined className="form-modal-input-info" />
                                        </Tooltip>
                                    ) : null}
                                </label>
                            ) : null}
                            {getInputComponent(subInput)}
                        </div>
                    ))}
                </div>
            );
            break;
        case "textarea":
            component = (
                <TextArea
                    name={input.name}
                    autoSize={{minRows: input.minRows, maxRows: input.maxRows}}
                    maxLength={input.maxLength}
                />
            );
            break;
        default:
            component = (
                <FormInputV2
                    className={input.className}
                    disabled={input.disabled}
                    type={input.type}
                    placeholder={input.placeholder}
                    onWheel={e => e.target.blur()}
                />
            );
            break;
    }

    return !["multi", "custom", "boolean"].includes(input.type) ? (
        <Form.Item
            name={input.name}
            rules={[
                ...(!input.optional
                    ? [
                          {
                              required: true,
                              validator: (_, result) => {
                                  if (!result && result !== 0) {
                                      return Promise.reject("שדה חובה");
                                  }
                                  return Promise.resolve();
                              }
                          }
                      ]
                    : []),
                ...(input.rules ?? [])
            ]}>
            {component}
        </Form.Item>
    ) : (
        component
    );
};

export const renderInputLabel = input => {
    if (!input.label) {
        return <></>;
    }

    return (
        <label>
            {input.label}
            {input.info ? (
                <Tooltip title={input.info}>
                    <QuestionCircleOutlined className="form-modal-input-info" />
                </Tooltip>
            ) : null}
        </label>
    );
};
