import React, {useState} from "react";
import {Card, ConfigProvider, Form, Select, Tooltip} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {Languages} from "../../auth/Login";
import {FormConfirmButtonV2, FormInputV2} from "../../components/form";
import {AutoCompleteAddressFormInput} from "../../utils/AutoCompleteAddress";
import {Sectors, SectorsNames} from "../../consts.js";
import {PHONE_NUMBER_ERROR_VALIDATION} from "../../marketplace/consts";
import common from "@well-b/well-b-shared";

export const CompanyDetailsForm = ({
    setFormValues,
    formValues = {},
    header,
    onFinish,
    submitText,
    otherInputs = [],
    language = Languages.Hebrew
}) => {
    const isMobileView = () => false;
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Card
            className="company-sign-up-short-stepper-details-form-card"
            bordered={!isMobileView()}
            cover={
                <div
                    className={`company-sign-up-short-stepper-details-form-container ${
                        language === Languages.Hebrew ? "hebrew" : "english"
                    }`}>
                    {header}
                    <Form
                        fields={Object.keys(formValues).map(key => ({
                            name: [key],
                            value: formValues[key]
                        }))}
                        onValuesChange={(_, allValues) => {
                            setFormValues(allValues);
                        }}
                        onFinish={async () => {
                            setIsLoading(true);
                            await onFinish();
                            setIsLoading(false);
                        }}
                        className="company-sign-up-short-stepper-details-form">
                        <label>{language === Languages.Hebrew ? "שם החברה" : "Company name"}</label>
                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "שדה חובה"
                                }
                            ]}>
                            <FormInputV2 />
                        </Form.Item>
                        <label style={{}}>{language === Languages.Hebrew ? "מס׳ ח.פ" : "Business number (ח.פ)"}</label>
                        <Form.Item
                            name="businessNumber"
                            rules={[
                                {
                                    required: true,
                                    message: "שדה חובה"
                                },
                                {
                                    validator: (_, value) => {
                                        const regexPattern = /^(5[0-9]|[6-8][0-9]|59)\d{7}$/;

                                        if (value && !regexPattern.test(value)) {
                                            return Promise.reject(
                                                "מס' ח.פ אינו תקין: יש להזין מס' בן 9 ספרות שמתחיל ב50-59"
                                            );
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }
                                }
                            ]}>
                            <FormInputV2 onWheel={e => e.target.blur()} type="number" />
                        </Form.Item>
                        <label>{language === Languages.Hebrew ? "מס׳ עובדים בארגון" : "Number of employees"}</label>
                        <Form.Item
                            name="employeesCount"
                            rules={[
                                {
                                    required: true,
                                    message: "שדה חובה"
                                }
                            ]}>
                            <FormInputV2 onWheel={e => e.target.blur()} type="number" />
                        </Form.Item>
                        <div className="company-sign-up-short-stepper-details-address-label">
                            <label>
                                {language === Languages.Hebrew ? "כתובת משרד ראשי מלאה" : "Full office address"}
                                <Tooltip
                                    title={
                                        language === Languages.Hebrew
                                            ? "כתובת מלאה כוללת רחוב, מס׳ בניין, עיר וקומה. כתבות שתזינו פה תשמש את הספק למשלוח במידה ותבצעו הזמנה"
                                            : "Full address should include city, street name, building number and floor. The address you set here will be used by providers in case of ordering"
                                    }>
                                    <QuestionCircleOutlined className="company-sign-up-short-stepper-details-address-label-help" />
                                </Tooltip>
                            </label>
                            <label>{language === Languages.Hebrew ? "קומה" : "Floor"}</label>
                        </div>
                        <div className="company-sign-up-short-stepper-details-address-input">
                            <Form.Item
                                name="address"
                                valuePropName="address"
                                rules={[
                                    {
                                        validator: (_, result) => {
                                            const errorMessage =
                                                language === Languages.Hebrew
                                                    ? "אנא בחרו כתובת מלאה, כולל מספר בניין"
                                                    : "Please provider a full address, including building number";
                                            if (!result) {
                                                return Promise.reject(errorMessage);
                                            }
                                            return Promise.resolve();
                                        }
                                    }
                                ]}>
                                <AutoCompleteAddressFormInput
                                    editValue={formValues.address}
                                    allowSelectRegularText={true}
                                    placeholder={language === Languages.Hebrew ? "חיפוש כתובת" : "Address search"}
                                />
                            </Form.Item>
                            <Form.Item
                                name="floor"
                                rules={[
                                    {
                                        validator: (_, result) => {
                                            const errorMessage =
                                                language === Languages.Hebrew
                                                    ? "אנא הכניסו קומה"
                                                    : "Please provide a floor number";
                                            if (!result) {
                                                return Promise.reject(errorMessage);
                                            }
                                            return Promise.resolve();
                                        }
                                    }
                                ]}>
                                <FormInputV2
                                    className="company-sign-up-short-stepper-details-floor-input"
                                    type="number"
                                />
                            </Form.Item>
                        </div>
                        <label>{language === Languages.Hebrew ? "תחום פעילות" : "Company sector"}</label>
                        <ConfigProvider direction="rtl">
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: "שדה חובה"
                                    }
                                ]}
                                name="sector">
                                <Select
                                    options={Object.values(Sectors).map(sector => ({
                                        value: sector,
                                        label: SectorsNames[sector]
                                    }))}
                                />
                            </Form.Item>
                        </ConfigProvider>
                        <div>
                            <div className="company-sign-up-short-stepper-details-break-line">
                                <span>{language === Languages.Hebrew ? "פרטי איש קשר" : "Contact info"}</span>
                            </div>
                        </div>
                        <label>{language === Languages.Hebrew ? "שם מלא" : "Full name"}</label>
                        <Form.Item
                            name="contactName"
                            rules={[
                                {
                                    required: true,
                                    message: "שדה חובה"
                                }
                            ]}>
                            <FormInputV2 />
                        </Form.Item>
                        <label>{language === Languages.Hebrew ? "מס׳ טלפון נייד" : "Phone number"}</label>
                        <Form.Item
                            name="phone"
                            onWheel={e => e.target.blur()}
                            rules={[
                                {
                                    required: true,
                                    message: "שדה חובה"
                                },
                                {
                                    validator: (_, value) => {
                                        return common.isValidPhoneMobileNumber(value)
                                            ? Promise.resolve()
                                            : Promise.reject(PHONE_NUMBER_ERROR_VALIDATION);
                                    }
                                }
                            ]}>
                            <FormInputV2 type="number" />
                        </Form.Item>
                        {otherInputs?.map(({name, key, type}) => (
                            <>
                                <label>{name}</label>
                                <Form.Item name={key} key={key}>
                                    <FormInputV2 type={type} />
                                </Form.Item>
                            </>
                        ))}
                        <Form.Item>
                            <FormConfirmButtonV2 loading={isLoading} htmlType="submit">
                                {submitText}
                            </FormConfirmButtonV2>
                        </Form.Item>
                    </Form>
                </div>
            }></Card>
    );
};
