import {Modal} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {CloseIcon} from "../icons";
import {Images} from "../images/Images";
import {StyledButton} from "../components/StyledButton";
import {PageTitleLabel} from "../components/PageTitle";
import {EventBus} from "../bus/EventBus";
import {withRouter} from "react-router";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {AppContext} from "../AppContext";

const BankInformationRequiredModal = ({history}) => {
    const {me} = useContext(AppContext);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        EventBus.on("bank_info_required:open", label => {
            GoogleAnalytics.event("Billing", "Bank Info Required", label);
            setVisible(true);
        });
    }, []);

    const closeMe = () => {
        setVisible(false);
    };

    const openBankInfoModal = () => {
        EventBus.trigger("bank_info_details:open");
        closeMe();
    };

    const openBillingPage = () => {
        history.push("/provider/billing");
        GoogleAnalytics.event("Billing", "Open From Bank Modal", me.type);
        closeMe();
    };

    return (
        <Modal
            className="wb-modal-radius"
            destroyOnClose={true}
            closable={true}
            closeIcon={<CloseIcon style={{fill: "white"}} />}
            title={null}
            footer={null}
            onCancel={closeMe}
            bodyStyle={{padding: 0}}
            open={visible}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <img src={Images.creditCardRequired} alt="credit-card-required" />
                <PageTitleLabel style={{marginBottom: 30}}>Bank account information required</PageTitleLabel>
                <div style={{fontSize: 16, color: "#8492A6"}}>Do you want to be able to receive payments?</div>
                <div style={{fontSize: 16, color: "#8492A6"}}>Insert your bank account information.</div>
                <StyledButton
                    onClick={openBankInfoModal}
                    style={{color: "white", backgroundColor: "#13C296", marginTop: 30, marginBottom: 30, width: 220}}>
                    Insert bank information
                </StyledButton>
                <a
                    onClick={openBillingPage}
                    style={{color: "#8492A6", textDecoration: "underline", fontSize: 14, marginBottom: 30}}>
                    Go to your billing page
                </a>
            </div>
        </Modal>
    );
};

export default withRouter(BankInformationRequiredModal);
