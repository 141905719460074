import moment from "moment-timezone";
import {AsiaJerusalemTZ, AsiaJerusalemWorkingDays, DaysMapper, ROWWorkingDays} from "./DaysMapper";
// import {RRule} from "rrule";

export class FrequencyMapper {
    static map({freq, interval, byweekday, dtstart, tzid, duration}) {
        let until = null;

        switch (freq) {
            case "once":
                freq = "weekly";
                until = moment(dtstart).add(duration, "minutes").toDate().getTime();
                interval = 1;
                break;
            case "daily":
                if (tzid === AsiaJerusalemTZ) {
                    byweekday = Object.keys(AsiaJerusalemWorkingDays);
                } else {
                    byweekday = Object.keys(ROWWorkingDays);
                }
                interval = 1;
                break;
            case "2-weekly":
                freq = "weekly";
                interval = 2;
                break;
            case "weekly":
                interval = interval || 1;
                break;
            case "monthly":
                freq = "weekly";
                interval = 4;
                break;
            default:
                break;
        }

        return {byweekday, freq, interval, until};
    }

    static toFreqString(event) {
        if (event.oneTime) {
            return "once";
        } else if (event.freq === "daily" && event.interval === 1) {
            return "daily";
        } else if (event.freq === "weekly" && event.interval === 2) {
            return "2-weekly";
        } else if ((event.freq === "weekly" && event.interval === 4) || event.freq === "monthly") {
            return "monthly";
        } else {
            return "weekly";
        }
    }

    static toHumanText({freq, byweekday, until, dtstart, interval, tzid}, exactDate = false, includeDay = true) {
        const days =
            freq === "daily"
                ? tzid === AsiaJerusalemTZ
                    ? "Sunday - Thursday"
                    : "Monday - Friday"
                : byweekday.map(daySymbol => DaysMapper.mapKeyToDay(daySymbol)).join(", ");

        if (freq === "daily" || (until && moment(until).diff(moment(dtstart), "days") <= 7)) {
            return exactDate ? moment(dtstart).format(includeDay ? "dddd MMM D, YYYY" : "MMM D, YYYY") : `On ${days}`;
        } else if (freq === "weekly" && interval === 2) {
            return `Every 2nd ${days}`;
        } else if ((freq === "weekly" && interval === 4) || freq === "monthly") {
            return `Every month on ${days}`;
        } else {
            return `Every ${days}`;
        }
    }

    static toShortHumanText({freq, byweekday, until, dtstart, interval, tzid}, exactDate = false, includeDay = true) {
        const days =
            freq === "daily"
                ? tzid === AsiaJerusalemTZ
                    ? "Sunday - Thursday"
                    : "Monday - Friday"
                : byweekday.map(daySymbol => DaysMapper.mapKeyToDay(daySymbol)).join(", ");

        if (freq === "daily" || (until && moment(until).diff(moment(dtstart), "days") <= 7)) {
            return exactDate ? moment(dtstart).format(includeDay ? "dddd MMM D, YYYY" : "MMM D, YYYY") : `On ${days}`;
        } else if (freq === "weekly" && interval === 2) {
            return `Every 2nd week`;
        } else if ((freq === "weekly" && interval === 4) || freq === "monthly") {
            return `Every month`;
        } else {
            return `Every ${days}`;
        }
    }

    static oneTime({oneTime, dtstart, until}) {
        if (oneTime) {
            return true;
        } else if (until && moment(until).diff(moment(dtstart), "days") <= 7) {
            return true;
        }

        return false;
    }

    static toFreqNum(freq) {
        const freqMap = {
            daily: RRule.DAILY,
            weekly: RRule.WEEKLY,
            monthly: RRule.MONTHLY
        };

        return freqMap[freq];
    }
}
