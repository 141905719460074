import React, {useState, useContext} from "react";
import {PageSubTitleLabel, PageTitleLabel} from "../components/PageTitle";
import {Switch, message, Spin} from "antd";
import {FormCancelSaveBottomBar} from "../components/form";
import {HttpClient} from "../http/HttpClient";
import _ from "lodash";
import {AppContext} from "../AppContext";

const NotificationType = {
    CompanyOfferSingleDeclined: "company-offer-single-declined",
    CompanyOfferMultiDeclined: "company-offer-multi-declined",
    CompanySessionAboutToStart: "company-session-about-to-start",
    EmployeeRegisterProposal: "employee-register-proposal",
    EmployeeCustomSessionCancelled: "employee-custom-session-cancelled",
    EmployeeSessionAboutToStart: "employee-session-about-to-start",
    EmployeeInternalSessionHost: "employee-internal-session-host"
};

const CompanyNotificationContent = {
    [NotificationType.CompanyOfferSingleDeclined]: {
        title: <>Offer declined by a single professional</>,
        description: (
            <>
                We send you an email when{" "}
                <strong>
                    <u>one</u> of your booking requests
                </strong>{" "}
                with a professional gets declined. These emails are your way of knowing whether the professional you
                tried to book declined your offer.
            </>
        )
    },
    [NotificationType.CompanyOfferMultiDeclined]: {
        title: <>Offer declined by all professionals</>,
        description: (
            <>
                We send you an email when{" "}
                <strong>
                    <u>all</u> of your booking requests
                </strong>{" "}
                on a specific category have been declined. These emails are your way of knowing that you don’t have any
                additional pending requests out there.
            </>
        )
    },
    [NotificationType.CompanySessionAboutToStart]: {
        title: <>Session about to start</>,
        description: (
            <>
                We send you an email <strong>30 minutes before</strong> one of your events on the company schedule
                starts.
            </>
        )
    }
};

const EmployeesNotificationContent = {
    [NotificationType.EmployeeInternalSessionHost]: {
        title: <>Invitation to host internal session</>,
        description: (
            <>
                Notify your employees when they were chosen to host a{" "}
                <strong>
                    <u>new</u> internal
                </strong>{" "}
                session.
            </>
        )
    },
    [NotificationType.EmployeeRegisterProposal]: {
        title: <>New session register proposal</>,
        description: (
            <>
                Notify your employees when an event of <strong>any kind</strong> gets booked on the company schedule and
                give them the chance to register.
            </>
        )
    },
    [NotificationType.EmployeeCustomSessionCancelled]: {
        title: <>Internal session cancelled</>,
        description: (
            <>
                Notify your employees when one of your{" "}
                <strong>
                    <u>internal</u> sessions gets cancelled
                </strong>{" "}
                for whatever reason. This is your employees’ way of knowing that the event won't take place as initially
                scheduled.
            </>
        )
    },
    [NotificationType.EmployeeSessionAboutToStart]: {
        title: <>Session about to start</>,
        description: (
            <>
                Send a reminder to your employees,{" "}
                <strong>
                    <u>that registered</u>
                </strong>{" "}
                to a specific session, <strong>10 minutes before</strong> the session starts.
            </>
        )
    }
};

export const EmailNotificationSetting = ({title, description, enabled, onChange}) => (
    <div
        style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 30,
            borderBottom: "1px solid #D3D8E0",
            width: "100%"
        }}>
        <div style={{display: "flex", justifyContent: "space-between", marginBottom: 10}}>
            <label style={{fontSize: 20, fontWeight: 700, color: "#212B36"}}>{title}</label>
            <Switch onChange={onChange} checked={enabled} style={{backgroundColor: enabled ? "#37C186" : "#767F90"}} />
        </div>
        <p style={{fontSize: 14, color: "#212B36", marginBottom: 30}}>{description}</p>
    </div>
);

export const CompanyNotificationSettings = ({visible}) => {
    const {company} = useContext(AppContext);
    const [saving, setSaving] = useState(false);
    const [initialSettings, setInitialSettings] = useState(null);
    const [notificationSettings, setNotificationSettings] = useState({
        [NotificationType.CompanyOfferSingleDeclined]: true,
        [NotificationType.CompanyOfferMultiDeclined]: true,
        [NotificationType.CompanySessionAboutToStart]: true,
        [NotificationType.EmployeeRegisterProposal]: false,
        [NotificationType.EmployeeCustomSessionCancelled]: false,
        [NotificationType.EmployeeSessionAboutToStart]: true
    });

    if (company && initialSettings === null) {
        const {notificationSettings} = company;
        setInitialSettings(notificationSettings);
        setNotificationSettings(notificationSettings);
    }

    const undoAll = () => {
        setNotificationSettings(initialSettings);
    };

    const saveAll = async () => {
        setSaving(true);
        try {
            await HttpClient.post("/api/companies/notifications", {notificationSettings});
            setInitialSettings(notificationSettings);
            message.success("Your notification settings changes were saved.", 3);
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setSaving(false);
    };

    const onSettingChange = (type, enabled) => {
        const cloned = _.cloneDeep(notificationSettings);
        cloned[type] = enabled;
        setNotificationSettings(cloned);
    };

    if (!company || !initialSettings) {
        return null;
    }

    return (
        <div style={{display: visible ? "flex" : "none", justifyContent: "center", width: "100%"}}>
            <div style={{display: "flex", alignItems: "center", flexDirection: "column", maxWidth: 800, width: "100%"}}>
                <PageTitleLabel style={{marginBottom: 0, marginTop: 0, justifyContent: "flex-start", width: "100%"}}>
                    Admin email notifications
                </PageTitleLabel>
                <PageSubTitleLabel style={{justifyContent: "flex-start", width: "100%"}}>
                    Control email notifications you receive
                </PageSubTitleLabel>
                <div style={{display: "flex", flexDirection: "column", width: "100%", marginBottom: 50}}>
                    <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                        {Object.keys(CompanyNotificationContent).map((type, idx) => {
                            const {title, description} = CompanyNotificationContent[type];
                            return (
                                <EmailNotificationSetting
                                    key={`${type}-${idx}`}
                                    title={title}
                                    description={description}
                                    enabled={notificationSettings[type]}
                                    onChange={enabled => onSettingChange(type, enabled)}
                                />
                            );
                        })}
                    </div>
                    <div style={{display: "flex", flexDirection: "column", width: "100%", marginTop: 80}}>
                        <PageTitleLabel
                            style={{marginBottom: 0, marginTop: 0, justifyContent: "flex-start", width: "100%"}}>
                            Employees email notifications
                        </PageTitleLabel>
                        <PageSubTitleLabel style={{justifyContent: "flex-start", width: "100%"}}>
                            Control email notifications your employees receive
                        </PageSubTitleLabel>
                        <div style={{display: "flex", flexDirection: "column", width: "100%", marginTop: 20}}>
                            {Object.keys(EmployeesNotificationContent).map((type, idx) => {
                                const {title, description} = EmployeesNotificationContent[type];
                                return (
                                    <EmailNotificationSetting
                                        key={`${type}-${idx}`}
                                        title={title}
                                        description={description}
                                        enabled={notificationSettings[type]}
                                        onChange={enabled => onSettingChange(type, enabled)}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
                <FormCancelSaveBottomBar
                    onCancel={() => undoAll()}
                    onSave={async () => await saveAll()}
                    loading={saving}
                    saveProps={{
                        style: {
                            backgroundColor: "#273444"
                        }
                    }}
                    cancelProps={{
                        style: {
                            border: "1px solid #273444",
                            color: "#273444"
                        }
                    }}
                />
            </div>
        </div>
    );
};
