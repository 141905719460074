import React, {useCallback, useContext, useEffect, useState} from "react";
import {Modal} from "antd";
import {PeriodStep} from "./PeriodStep";
import {OccasionCategoryStep} from "./OccasionCategoryStep";
import {EditSettingsModalTypes} from "./consts";
import {HappinessCalculatorContext} from "../HappinessCalculatorContext";
import {SquareButton} from "../../components";
import {Images} from "../../images/Images";
import "./happiness-calculator-edit-settings-modals.css";

const {CalculatorBG} = Images;

export const PlanEditSettingsModal = ({history, periods, categories, modalToShow, onClose}) => {
    const [innerPeriods, setInnerPeriods] = useState([]);
    const [innerCategories, setInnerCategories] = useState([]);
    const {setPlanTimeRange, setPlanOccasionsCategories} = useContext(HappinessCalculatorContext);

    useEffect(() => {
        if (categories) {
            setInnerCategories(categories);
        }
    }, [categories]);

    const onFinish = useCallback(() => {
        if (modalToShow === EditSettingsModalTypes.TimeEdit) {
            setPlanTimeRange([innerPeriods[0].clone().startOf("month"), innerPeriods[1].clone().endOf("month")]);
            setInnerPeriods([]);
        } else {
            setPlanOccasionsCategories(innerCategories);
        }
        onClose();
    }, [innerPeriods, innerCategories, setPlanOccasionsCategories, setPlanTimeRange]);

    return (
        <Modal
            width="80vw"
            open={modalToShow}
            onCancel={onClose}
            closable={
                modalToShow === EditSettingsModalTypes.TimeEdit ? innerPeriods.length >= 2 : innerCategories.length
            }
            bodyStyle={{backgroundImage: `url(${CalculatorBG})`}}
            className="happiness-calculator-edit-settings-modals"
            footer={null}
            title={null}
            centered={true}>
            {modalToShow === EditSettingsModalTypes.TimeEdit ? (
                <PeriodStep
                    history={history}
                    showTitle={false}
                    periods={innerPeriods}
                    setPeriods={setInnerPeriods}
                    onClose={onClose}
                />
            ) : (
                <div className="happiness-calculator-edit-settings-category-step">
                    <OccasionCategoryStep
                        defaultAllSelected={false}
                        selectedOccasionCategories={innerCategories}
                        setSelectedOccasionCategories={setInnerCategories}
                    />
                </div>
            )}

            <SquareButton
                className="primary-color-button"
                disabled={
                    modalToShow === EditSettingsModalTypes.TimeEdit ? innerPeriods.length < 2 : !innerCategories.length
                }
                onClick={onFinish}>
                שמירה
            </SquareButton>
        </Modal>
    );
};
