import React, {useContext} from "react";
import {Logo} from "../images/Images";
import {AppLogo} from "../AppLogo";
import {EXCenterNavBottomBar} from "./navigation/EXCenterNavBottomBar";
import {AppContext} from "../AppContext";
import {parse as qsParse} from "query-string";
import {EXCenterContext} from "./EXCenterContext";

export const EXCenterFooter = ({hideBottomNav = false}) => {
    const {company} = useContext(AppContext);
    const {theme} = useContext(EXCenterContext);
    const {branchId} = qsParse(location.search);

    if (!company) {
        return null;
    }

    const companyBranch = company.branches.find(b => b.branchId === branchId);
    const address = companyBranch ? companyBranch.address : company.address;

    return (
        <footer
            style={{
                width: "100%",
                backgroundColor: theme.backgroundColor,
                display: "flex",
                flexDirection: "column",
                marginTop: 120,
                position: "absolute",
                bottom: 0,
                left: 0
            }}>
            {hideBottomNav ? null : (
                <div
                    style={{
                        paddingTop: 50,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%"
                    }}>
                    <AppLogo
                        style={{
                            position: "relative",
                            left: 0,
                            backgroundColor: "transparent",
                            borderBottom: "none",
                            height: 50
                        }}
                    />
                    <span
                        style={{
                            fontSize: 20,
                            fontWeight: 700,
                            color: theme.textColor,
                            padding: 20,
                            textAlign: "center"
                        }}>
                        {address}
                    </span>
                    <EXCenterNavBottomBar />
                </div>
            )}
            <div
                style={{
                    height: 80,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(218,224,228,0.4)"
                }}>
                <span
                    style={{
                        color: theme.textColor,
                        fontSize: 20,
                        fontWeight: "bold",
                        marginRight: 10
                    }}>
                    Powered by
                </span>
                <img width="80" height="20" alt="well-b-logo" src={Logo.image} />
            </div>
        </footer>
    );
};
