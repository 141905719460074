import React, {useContext, useMemo} from "react";
import {Spin} from "antd";
import {CompanyDashboard} from "./CompanyDashboard";
import ProviderDashboard from "./ProviderDashboard";
import {DefaultAppDashboard} from "./DefaultAppDashboard";
import {AppContext} from "../AppContext";

export const AppDashboardSelector = ({history}) => {
    const {me} = useContext(AppContext);

    const userDashboard = useMemo(() => {
        if (me.type === "company" && !me.features?.paying_companies) {
            return <CompanyDashboard history={history} />;
        }

        //TODO: Wait to see if we need to check for v1 providers. If no, delete all files related to v1 provider (can be found in DefaultAppDashboard)
        if (me.type === "provider") {
            return <ProviderDashboard history={history} />;
        }

        return <DefaultAppDashboard history={history} />;
    }, [me, history]);

    return userDashboard ?? <Spin />;
};
