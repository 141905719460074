import {sortBy, memoize} from "lodash";

export const TutorialScreens = sortBy(
    [
        {
            screenName: "Company Library",
            screenId: "company_library",
            pathRegex: /^\/dashboard\/services/,
            userType: "company"
        },
        {
            screenName: "Company Content",
            screenId: "company_content",
            pathRegex: /^\/dashboard\/content\/(?!events)/,
            userType: "company"
        },
        {
            screenName: "Company Calendar",
            screenId: "company_calendar",
            pathRegex: /^\/dashboard\/content\/events/,
            userType: "company"
        },
        {
            screenName: "Company Insights",
            screenId: "company_insights",
            pathRegex: /^\/dashboard\/insights/,
            userType: "company"
        },
        {
            screenName: "Company Employees",
            screenId: "company_employees",
            pathRegex: /^\/dashboard\/employees/,
            userType: "company"
        },
        {
            screenName: "Company Weekly",
            screenId: "company_weekly",
            pathRegex: /^\/company\/weekly/,
            userType: "company"
        },
        {
            screenName: "Company Billing",
            screenId: "company_billing",
            pathRegex: /^\/company\/billing/,
            userType: "company"
        },
        {
            screenName: "Company Account",
            screenId: "company_account",
            pathRegex: /^\/company\/account/,
            userType: "company"
        },
        {
            screenName: "Company Branches",
            screenId: "company_branches",
            pathRegex: /^\/center\/branches/,
            userType: "company"
        },
        {
            screenName: "Company EX Center",
            screenId: "company_ex_center",
            pathRegex: /^\/center\/main/,
            userType: "company"
        },
        {
            screenName: "Employee Main View",
            screenId: "employee_main_view",
            pathRegex: /^\/center\/main/,
            userType: "employee"
        },
        {
            screenName: "Vendor Calendar",
            screenId: "vendor_calendar",
            pathRegex: /^\/dashboard\/schedule/,
            userType: "provider"
        },
        {
            screenName: "Vendor Services",
            screenId: "vendor_services",
            pathRegex: /^\/dashboard\/myservices/,
            userType: "provider"
        },
        {
            screenName: "Vendor Profile",
            screenId: "vendor_profile",
            pathRegex: /^\/dashboard\/profile/,
            userType: "provider"
        },
        {
            screenName: "Vendor Billing",
            screenId: "vendor_billing",
            pathRegex: /^\/provider\/billing/,
            userType: "provider"
        }
    ],
    s => s.screenName
);

export const findTutorialScreenById = memoize(screenId => {
    return TutorialScreens.find(s => s.screenId === screenId);
});
