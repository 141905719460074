import React, {useCallback, useContext, useEffect, useState} from "react";
import {Collapse, ConfigProvider, DatePicker, Dropdown, Form, Menu} from "antd";
import {set} from "lodash";
import classNames from "classnames";
import moment from "moment/moment";
import {DownOutlined} from "@ant-design/icons";
import {CheckboxController, SwitchController} from "./OrderVoucherFormHelper";
import {OrderEmployeeInput} from "./OrderEmployeeInput";
import {sendOptions, timeOptions} from "./consts";
import {expandIconController, MarketplaceSeparatorLine} from "../MarketplaceHelper";
import {MarketplaceBookingContext} from "../MarketplaceBooking/MarketplaceBookingContext";
import {BOOKING_ERROR_TO_PANEL} from "../MarketplaceBooking/consts";
import {OrderFormPanelKeys, PHONE_NUMBER_ERROR_VALIDATION} from "../consts";
import {FormInputV2} from "../../components/form";
import {CustomTimePicker, SquareButton} from "../../components";
import {NOOP} from "../../utils/NOOP";
import "./order-voucher-form.css";
import {isValidEmailRegex} from "../../components/form/validators.jsx";
import common from "@well-b/well-b-shared";

const {Panel} = Collapse;
export const OrderVoucherForm = ({
    content,
    formValues,
    setFormValues,
    defaultActiveKey,
    onDefaultActiveKeyChange,
    extraFields,
    bookingError,
    log = NOOP
}) => {
    const [form] = Form.useForm();
    const [activeKey, setActiveKey] = useState([]);
    const [voucherSelect, setVoucherSelect] = useState(null);
    const [selectTimeOption, setSelectTimeOption] = useState(null);
    const [selectSendOptions, setSelectSendOptions] = useState({});

    const {highlightOrderPanel} = useContext(MarketplaceBookingContext);

    const onActiveKeyChange = useCallback(
        key => {
            setActiveKey(key);
            onDefaultActiveKeyChange && onDefaultActiveKeyChange(key);
        },
        [onDefaultActiveKeyChange]
    );

    const onFormValueChange = useCallback(
        (field, val) => {
            log("Order Form value change", {field, val});
            setFormValues(prevValues => set({...prevValues}, field, val));
        },
        [setFormValues]
    );

    const getPanelClassName = useCallback(
        panelKey =>
            classNames("marketplace-booking-collapse", {
                panelError: BOOKING_ERROR_TO_PANEL[bookingError] === panelKey && highlightOrderPanel?.id === panelKey
            }),
        [bookingError, highlightOrderPanel]
    );

    const onSendOptionsChange = useCallback((sendType, isActive) => {
        setSelectSendOptions(currentSelectSendOptions => set({...currentSelectSendOptions}, sendType, isActive));
    }, []);

    useEffect(() => {
        if (defaultActiveKey) {
            setActiveKey(defaultActiveKey);
        }
    }, [defaultActiveKey]);

    return (
        <Form
            form={form}
            className="order-form"
            fields={Object.keys(formValues).map(key => ({
                name: [key],
                value: formValues[key]
            }))}>
            <MarketplaceSeparatorLine />
            <Collapse
                ghost={true}
                accordion={true}
                className={getPanelClassName(OrderFormPanelKeys.VoucherAmount)}
                expandIconPosition="left"
                activeKey={activeKey}
                onChange={onActiveKeyChange}
                expandIcon={({isActive}) => expandIconController(isActive)}>
                <Panel
                    key={OrderFormPanelKeys.VoucherAmount}
                    showArrow={true}
                    header={
                        <div className="marketplace-booking-amount-choose">
                            {
                                <>
                                    <div className="marketplace-booking-amount-choose-title">💸 גובה השובר</div>

                                    <div
                                        className="marketplace-booking-amount-choose-subtitle"
                                        data-color-bold={!!voucherSelect?.redeemableValue}>
                                        {voucherSelect?.redeemableValue
                                            ? `${voucherSelect?.redeemableValue} ₪`
                                            : "הזינו את גובה השובר הרצוי עבור כל מקבל מתנה"}
                                    </div>
                                </>
                            }
                        </div>
                    }>
                    <div style={{display: "flex", flexDirection: "column", paddingLeft: "450px"}}>
                        <ConfigProvider direction="rtl">
                            <Dropdown
                                overlay={
                                    <Menu>
                                        {content?.pricingTable?.byVoucher.map(option => {
                                            return (
                                                <Menu.Item
                                                    onClick={() => {
                                                        onFormValueChange("voucherOption", option);
                                                        setVoucherSelect(option);
                                                    }}>
                                                    {"₪"} {option.redeemableValue}
                                                </Menu.Item>
                                            );
                                        })}
                                    </Menu>
                                }
                                trigger={["click"]}>
                                <SquareButton>
                                    {voucherSelect ? (
                                        <span>
                                            {voucherSelect.redeemableValue}
                                            {"₪"}
                                        </span>
                                    ) : (
                                        <>
                                            <span>בחרו סכום לשובר</span> <DownOutlined />
                                        </>
                                    )}
                                </SquareButton>
                            </Dropdown>
                        </ConfigProvider>
                    </div>
                </Panel>
            </Collapse>

            <MarketplaceSeparatorLine />
            <Collapse
                ghost={true}
                accordion={true}
                className={getPanelClassName(OrderFormPanelKeys.VoucherRecipient)}
                expandIconPosition="left"
                activeKey={activeKey}
                onChange={onActiveKeyChange}
                expandIcon={({isActive}) => expandIconController(isActive)}>
                <Panel
                    key={OrderFormPanelKeys.VoucherRecipient}
                    showArrow={true}
                    header={
                        <div className="marketplace-booking-amount-choose">
                            {
                                <>
                                    <div className="marketplace-booking-amount-choose-title">👤 למי המתנה?</div>

                                    <div
                                        className="marketplace-booking-amount-choose-subtitle"
                                        data-color-bold={
                                            !!(formValues.employeesExcel || formValues?.singleEmployeeDetails)
                                        }>
                                        {formValues?.singleEmployeeDetails
                                            ? "שילוח למקבל יחיד"
                                            : formValues?.singleEmployeeDetails
                                            ? "הפצה למס׳ מקבלים עם קובץ אקסל"
                                            : "הזינו את פרטי מקבל/י המתנה"}
                                    </div>
                                </>
                            }
                        </div>
                    }>
                    <div className="order-voucher-form-checkbox-container">
                        <OrderEmployeeInput
                            log={log}
                            employeesExcel={formValues?.employeesExcel}
                            singleEmployeeDetails={formValues?.singleEmployeeDetails}
                            onValueChange={onFormValueChange}
                        />
                    </div>
                </Panel>
            </Collapse>
            <MarketplaceSeparatorLine />
            {/*//CR: remove all inline css */}
            <Collapse
                ghost={true}
                accordion={true}
                className={getPanelClassName(OrderFormPanelKeys.VoucherTime)}
                expandIconPosition="left"
                activeKey={activeKey}
                onChange={onActiveKeyChange}
                expandIcon={({isActive}) => expandIconController(isActive)}>
                <Panel
                    key={OrderFormPanelKeys.VoucherTime}
                    showArrow={true}
                    header={
                        <div className="marketplace-booking-amount-choose">
                            {
                                <>
                                    <div className="marketplace-booking-amount-choose-title">
                                        ⌚ מתי לשלוח את המתנה?
                                    </div>

                                    <div
                                        className="marketplace-booking-amount-choose-subtitle"
                                        data-color-bold={!!selectTimeOption}>
                                        {formValues?.timeOption
                                            ? formValues.timeOption.type === "NOW"
                                                ? "עכשיו"
                                                : "במועד מאוחר יותר"
                                            : "הזינו את מועד שליחת המתנה הרצוי"}
                                    </div>
                                </>
                            }
                        </div>
                    }>
                    <div className="order-voucher-form-checkbox-container">
                        <div>
                            <CheckboxController
                                isChecked={timeOptions.NOW === selectTimeOption}
                                type={timeOptions.NOW}
                                setType={type => {
                                    setSelectTimeOption(type);
                                    onFormValueChange("timeOption.type", "NOW");
                                }}
                            />
                            <span>{timeOptions.NOW}</span>
                        </div>

                        <div>
                            <CheckboxController
                                isChecked={timeOptions.CUSTOM_DATE === selectTimeOption}
                                type={timeOptions.CUSTOM_DATE}
                                setType={type => {
                                    setSelectTimeOption(type);
                                    onFormValueChange("timeOption.type", "CUSTOM_DATE");
                                }}
                            />
                            <span>{timeOptions.CUSTOM_DATE}</span>

                            {timeOptions.CUSTOM_DATE === selectTimeOption && (
                                <div className="order-voucher-form-time-container">
                                    <Form.Item name="timeOption.date">
                                        <DatePicker
                                            inputReadOnly={true}
                                            style={{
                                                minWidth: 130,
                                                maxWidth: 130,
                                                height: "40px",
                                                marginRight: 10,
                                                fontSize: "14px",
                                                borderRadius: "5px"
                                            }}
                                            suffixIcon={null}
                                            onChange={momentDate => onFormValueChange("timeOption.date", momentDate)}
                                            format="MMM DD, YYYY"
                                            placeholder={"תאריך"}
                                            disabledDate={date => date.isBefore(moment(Date.now()).subtract(1, "days"))}
                                        />
                                    </Form.Item>
                                    <Form.Item name="timeOption.time">
                                        <CustomTimePicker
                                            style={{
                                                width: 90,
                                                height: "40px",
                                                fontSize: "14px",
                                                borderRadius: "5px",
                                                marginRight: 5
                                            }}
                                            format="HH:mm"
                                            suffixIcon={null}
                                            minuteStep={15}
                                            inputReadOnly={false}
                                            onChange={momentTime => onFormValueChange("timeOption.time", momentTime)}
                                            placeholder={"שעה"}
                                            showNow={true}
                                        />
                                    </Form.Item>
                                </div>
                            )}
                        </div>
                    </div>
                </Panel>
            </Collapse>

            <MarketplaceSeparatorLine />
            <Collapse
                ghost={true}
                accordion={true}
                className={getPanelClassName(OrderFormPanelKeys.VoucherSendingMethod)}
                expandIconPosition="left"
                activeKey={activeKey}
                onChange={onActiveKeyChange}
                expandIcon={({isActive}) => expandIconController(isActive)}>
                <Panel
                    key={OrderFormPanelKeys.VoucherSendingMethod}
                    showArrow={!formValues.employeesExcel}
                    disabled={!!formValues.employeesExcel}
                    header={
                        <div className="marketplace-booking-amount-choose">
                            <div className="marketplace-booking-amount-choose-title">🚀 איך לשלוח את המתנה?</div>

                            <div
                                className="marketplace-booking-amount-choose-subtitle"
                                data-color-bold={Object.values(sendOptions).some(option => selectSendOptions[option])}>
                                {Object.values(sendOptions).some(option => selectSendOptions[option])
                                    ? Object.entries(selectSendOptions)
                                          .filter(([_, isActive]) => isActive)
                                          .map(([name]) => name)
                                          .join(", ")
                                    : "ניתן לבחור יותר מדרך אחת לשליחה"}
                            </div>
                        </div>
                    }>
                    <div className="order-voucher-form-checkbox-container">
                        <div>
                            <SwitchController
                                isChecked={selectSendOptions[sendOptions.SMS]}
                                type={sendOptions.SMS}
                                setType={(type, isActive) => onSendOptionsChange(type, isActive)}
                            />
                            <span>{`שליחה ב${sendOptions.SMS}`}</span>

                            {selectSendOptions[sendOptions.SMS] && (
                                <div className="order-voucher-form-time-container">
                                    <Form.Item
                                        name={["sendOptions", "sms"]}
                                        rules={[
                                            {
                                                required: true,
                                                validator: (_, value) => {
                                                    return !value || !common.isValidPhoneMobileNumber(value)
                                                        ? Promise.reject(PHONE_NUMBER_ERROR_VALIDATION)
                                                        : Promise.resolve();
                                                }
                                            }
                                        ]}>
                                        <FormInputV2
                                            type="number"
                                            placeholder={"הזן מספר"}
                                            style={{width: "150px", marginRight: "10px"}}
                                            onWheel={e => e.target.blur()}
                                            onChange={e => onFormValueChange("sendOptions.sms", e.target.value)}
                                        />
                                    </Form.Item>
                                </div>
                            )}
                        </div>

                        <div>
                            <SwitchController
                                isChecked={selectSendOptions[sendOptions.EMAIL]}
                                type={sendOptions.EMAIL}
                                setType={(type, isActive) => onSendOptionsChange(type, isActive)}
                            />
                            <span>{`שליחה ב${sendOptions.EMAIL}`}</span>
                            {selectSendOptions[sendOptions.EMAIL] && (
                                <div className="order-voucher-form-time-container">
                                    <Form.Item
                                        name={["sendOptions", "email"]}
                                        rules={[
                                            {
                                                validator: async (_, email) => {
                                                    if (email?.length && !isValidEmailRegex(email)) {
                                                        throw new Error("פרטי המייל אינם תקינים");
                                                    }
                                                }
                                            }
                                        ]}>
                                        <FormInputV2
                                            type="email"
                                            placeholder={"הזן מייל"}
                                            style={{width: "150px", marginRight: "10px"}}
                                            onWheel={e => e.target.blur()}
                                            onChange={e =>
                                                onFormValueChange(
                                                    "sendOptions.email",
                                                    e.target.value?.length > 0 ? e.target.value : null
                                                )
                                            }
                                        />
                                    </Form.Item>
                                </div>
                            )}
                        </div>
                    </div>
                </Panel>
            </Collapse>

            <MarketplaceSeparatorLine />
            <Collapse
                ghost={true}
                accordion={true}
                className={getPanelClassName(OrderFormPanelKeys.VoucherSender)}
                expandIconPosition="left"
                activeKey={activeKey}
                onChange={onActiveKeyChange}
                expandIcon={({isActive}) => expandIconController(isActive)}>
                <Panel
                    key={OrderFormPanelKeys.VoucherSender}
                    showArrow={true}
                    header={
                        <div className="marketplace-booking-amount-choose">
                            {
                                <>
                                    <div className="marketplace-booking-amount-choose-title">🕺 ממי המתנה?</div>

                                    <div
                                        className="marketplace-booking-amount-choose-subtitle"
                                        data-color-bold={!!formValues.senderName}>
                                        {formValues?.senderName
                                            ? formValues?.senderName
                                            : "הזינו את השם שיופיע בתור מי שנתן את המתנה"}
                                    </div>
                                </>
                            }
                        </div>
                    }>
                    <div>
                        <Form.Item
                            name="senderName"
                            rules={[
                                {
                                    required: true
                                }
                            ]}>
                            <FormInputV2
                                type="string"
                                placeholder={"הזן שם"}
                                style={{width: "250px", marginRight: "10px"}}
                                onWheel={e => e.target.blur()}
                                onChange={e => onFormValueChange("senderName", e.target.value)}
                                maxLength={30}
                            />
                        </Form.Item>
                    </div>
                </Panel>
            </Collapse>
            <MarketplaceSeparatorLine />
            {extraFields}
        </Form>
    );
};
