export const ADDRESS_EXCEL_EXAMPLE_FILE_URL =
    "https://res.cloudinary.com/well-b-prod/raw/upload/v1698062086/Assets/address_format_v2.xlsx\n";
export const ADDRESS_EXCEL_EXAMPLE_FILE_NAME = "פורמט_טעינת_מקבלי_מארז.xlsx";

export const BrokenYehudAddressId = "ChIJpzw_5m01HRURkMHzaBYKjtk";
export const FixedYehudAddressId = "ChIJkUlvXgk1HRURnQewlc2OkT4";

export const HomeDeliveryUploadMethod = {
    excel: "excel",
    singleEmployee: "singleEmployee"
};

export const employeesExcelKeys = {
    senderName: "senderName",
    senderPhoneNumber: "senderPhoneNumber",
    receiverFirstName: "receiverFirstName",
    receiverLastName: "receiverLastName",
    receiverPhoneNumber: "receiverPhoneNumber",
    receiverCity: "receiverCity",
    receiverStreetAddress: "receiverStreetAddress",
    receiverHouseNumber: "receiverHouseNumber",
    receiverEntranceNumber: "receiverEntranceNumber",
    receiverFloorNumber: "receiverFloorNumber",
    receiverApartmentNumber: "receiverApartmentNumber",
    deliveryNotes: "deliveryNotes",
    greetingMessage: "greetingMessage"
};

export const employeesExcelKeyPositions = {
    1: [employeesExcelKeys.senderName],
    2: [employeesExcelKeys.senderPhoneNumber],
    3: [employeesExcelKeys.receiverFirstName],
    4: [employeesExcelKeys.receiverLastName],
    5: [employeesExcelKeys.receiverPhoneNumber],
    6: [employeesExcelKeys.receiverCity],
    7: [employeesExcelKeys.receiverStreetAddress],
    8: [employeesExcelKeys.receiverHouseNumber],
    9: [employeesExcelKeys.receiverEntranceNumber],
    10: [employeesExcelKeys.receiverFloorNumber],
    11: [employeesExcelKeys.receiverApartmentNumber],
    12: [employeesExcelKeys.deliveryNotes],
    13: [employeesExcelKeys.greetingMessage]
};

export const employeesExcelKeyToDisplayName = {
    [employeesExcelKeys.senderName]: "שם העובד - שולח",
    [employeesExcelKeys.senderPhoneNumber]: "טלפון - שולח",
    [employeesExcelKeys.receiverFirstName]: "שם המקבל - שם פרטי",
    [employeesExcelKeys.receiverLastName]: "שם המקבל - שם משפחה",
    [employeesExcelKeys.receiverPhoneNumber]: "טלפון - מקבל",
    [employeesExcelKeys.receiverCity]: "עיר - מקבל",
    [employeesExcelKeys.receiverStreetAddress]: "רחוב - מקבל",
    [employeesExcelKeys.receiverHouseNumber]: "מספר בית - מקבל",
    [employeesExcelKeys.receiverEntranceNumber]: "כניסה - מקבל",
    [employeesExcelKeys.receiverFloorNumber]: "קומה - מקבל",
    [employeesExcelKeys.receiverApartmentNumber]: "דירה - מקבל",
    [employeesExcelKeys.deliveryNotes]: "הערות לשליח",
    [employeesExcelKeys.greetingMessage]: "ברכה"
};

export const requiredEmployeesExcelKeys = [
    employeesExcelKeys.receiverFirstName,
    employeesExcelKeys.receiverLastName,
    employeesExcelKeys.receiverPhoneNumber,
    employeesExcelKeys.receiverCity,
    employeesExcelKeys.receiverStreetAddress,
    employeesExcelKeys.receiverApartmentNumber
];
