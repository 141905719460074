import React, {createContext, useCallback} from "react";
import {useDebounceState} from "../../utils/hooks.jsx";
import {NOOP} from "../../utils/NOOP.jsx";

export const TableDateSearchContext = createContext({onClearDebouncedSearchText: NOOP, setUnDebouncedSearchText: NOOP});

export const TableDateSearchProvider = ({children}) => {
    const [debouncedSearchText, setUnDebouncedSearchText, unDebouncedSearchText] = useDebounceState("", 500);

    const onClearDebouncedSearchText = useCallback(() => {
        setUnDebouncedSearchText("");
    }, []);

    return (
        <TableDateSearchContext.Provider
            value={{debouncedSearchText, setUnDebouncedSearchText, unDebouncedSearchText, onClearDebouncedSearchText}}>
            {children}
        </TableDateSearchContext.Provider>
    );
};
