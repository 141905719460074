import React, {useCallback, useState} from "react";
import {message, Modal} from "antd";
import ProviderManageInvoicesTable from "../../provider/ProviderManageInvoices/ProviderManageInvoicesTable";
import {useRequest} from "../../utils/hooks";
import {FormModal, SquareButton} from "../../components";
import {InvoiceStatusTypes} from "../../provider/ProviderManageInvoices/consts";
import {HttpClient} from "../../http/HttpClient";
import {DeclineInvoiceModal} from "./DeclineInvoiceModal";

const AdminProviderInvoiceModal = ({visible, onCancel, onRemove, invoice, updateStatus}) => {
    const [orders, loadingOrders] = useRequest(
        "/admin/api/orders/byIds",
        "POST",
        invoice?.orders,
        [invoice?.orders],
        !!invoice?.orders?.length
    );
    const [providerInfo] = useRequest(
        `/admin/api/providers/${invoice?.providerId}`,
        "GET",
        null,
        [],
        !!invoice?.providerId
    );
    const [showDeclineModal, setShowDeclineModal] = useState(false);

    const deleteInvoice = useCallback(
        async invoiceId => {
            const {error} = await HttpClient.safeDelete(
                `/admin/api/providerInvoice/${invoice?.providerId}/${invoiceId}`
            );

            if (error) {
                message.error("שגיאה בעת מחיקת חשבונית");
                return;
            }

            message.success("חשבונית נמחקה בהצלחה");
            onRemove();
        },
        [invoice?.providerId]
    );

    return (
        <>
            <DeclineInvoiceModal
                visible={showDeclineModal}
                onClose={() => setShowDeclineModal(false)}
                onSave={async values => {
                    await updateStatus(InvoiceStatusTypes.DECLINED, values.declineMessage);
                    setShowDeclineModal(false);
                }}
            />

            <Modal
                className="admin-provider-month-invoices-modal"
                width="90vw"
                destroyOnClose={true}
                open={visible}
                onCancel={onCancel}
                footer={null}
                title={null}>
                <ProviderManageInvoicesTable
                    shouldSelectInvoicedOrders={true}
                    invoices={[invoice]}
                    orders={orders}
                    loadingOrders={loadingOrders}
                    deleteInvoice={deleteInvoice}
                    providerDetails={providerInfo}
                />
                <div className="admin-invoice-provider-invoice-modal-buttons">
                    <SquareButton className="decline-color-button" onClick={() => setShowDeclineModal(true)}>
                        דחה חשבונית
                    </SquareButton>
                    <SquareButton
                        className="accept-color-button"
                        onClick={() => updateStatus(InvoiceStatusTypes.ACCEPTED)}>
                        אשר חשבונית
                    </SquareButton>
                </div>
            </Modal>
        </>
    );
};

export default AdminProviderInvoiceModal;
