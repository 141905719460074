import React, {useCallback, useContext, useMemo, useState} from "react";
import {groupBy, uniq} from "lodash";
import {message, Tooltip} from "antd";
import {CrownFilled, DownloadOutlined, PlusOutlined} from "@ant-design/icons";
import {BudgetCategoryTypes} from "./consts";
import {constructBudgetObject, getSubAdminIdsToOrder} from "./utils";
import {exportAdminsToExcel} from "./excelExporter";
import {CompanyBudgetHistoryModal} from "./CompanyBudgetHistoryModal";
import {EditSubAdminModal} from "./EditSubAdminModal";
import subAdminsTableColumns from "./subAdminsTableColumns";
import {AppContext} from "../../AppContext";
import {BudgetModal, RoundedTable, SearchInputWithIcon, SquareButton} from "../../components";
import {useRequest} from "../../utils/hooks";
import {HttpClient} from "../../http/HttpClient";
import {getLogger} from "../../Logger";
import "./company-profile-manage-admins.css";
import {StringBuilder} from "../../AppUtils.js";
import classNames from "classnames";

export const CompanyProfileManageAdmins = ({budgets, budgetLoading, fetchBudgets, history, services}) => {
    const {me, fetchMe, company} = useContext(AppContext);

    const [editedAdmin, setEditedAdmin] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [userBudgetHistory, setUserBudgetHistory] = useState(null);
    const [transferBudgetAdmin, setTransferBudgetAdmin] = useState(null);
    const [loadingSendMail, setLoadingSendMail] = useState(false);
    const [loadingExportToExcel, setLoadingExportToExcel] = useState(false);
    const [subAdmins = [], isSubAdminLoading, , fetchAdmins] = useRequest("/api/companies/me/admins");
    const [subAdminsOrders, isSubAdminsOrdersLoading] = useRequest(
        "/api/budgetLogs/many",
        "POST",
        subAdmins?.map(({userId}) => userId),
        [],
        subAdmins?.length
    );

    const log = useMemo(
        () => getLogger({companyId: me.companyId, companyName: me.companyName}, "CompanyProfileManageAdmins"),
        [me.companyId, me.companyName]
    );

    const filteredAdmins = useMemo(
        () =>
            subAdmins
                ?.filter(admin =>
                    ["firstName", "phone", "email", "department"].some(key =>
                        admin[key]?.toLowerCase?.().includes(searchInput?.toLowerCase())
                    )
                )
                .sort(({isCompanyMaster: a}, {isCompanyMaster: b}) => (a === b ? 0 : a ? -1 : 1)) ?? [],
        [subAdmins, searchInput]
    );

    const subAdminsIdsToOrders = useMemo(() => {
        return getSubAdminIdsToOrder(subAdmins, subAdminsOrders);
    }, [subAdminsOrders, subAdmins]);

    const onSaveSingleAdmin = useCallback(
        async values => {
            const {budget, ...newInfo} = values;
            const user = {...editedAdmin, ...newInfo};

            if (user.userId) {
                const result = await HttpClient.safePost(`/api/companies/me/employees/${user.userId}`, user);
                if (result?.error) {
                    message.error(`שגיאה בעריכת אדמין: ${result.error}`, 10);
                } else {
                    message.success("אדמין נערך בהצלחה");
                }
            } else {
                const createdUser = await HttpClient.safePut("/api/companies/createSubAdmin", user);
                if (createdUser?.error) {
                    message.error(`שגיאה ביצירת אדמין: ${createdUser.error}`, 10);
                    setEditedAdmin(null);
                    return;
                }

                if (Object.values(budget).every(amount => Number.parseInt(amount))) {
                    const transformBudgetCategory = Object.entries(budget)
                        .filter(([, amount]) => amount > 0)
                        .map(([categoryId, amount]) => ({
                            amount,
                            subAdminId: createdUser.userId,
                            ...(categoryId !== BudgetCategoryTypes.GENERAL ? {category: categoryId} : {})
                        }));

                    const results = await HttpClient.safePost("/api/budget/transferBudget", [
                        ...transformBudgetCategory
                    ]);

                    if (results?.error) {
                        message.error(` אדמין נוצר בהצלחה אך הייתה שגיאה בטעינת תקציב: ${results.error}`, 5);
                    }
                }

                log("Created sub admin user", {user: createdUser});
                message.success("אדמין נוצר בהצלחה");
                fetchBudgets();
            }

            setEditedAdmin(null);
            await fetchAdmins();
        },
        [editedAdmin, fetchAdmins]
    );

    const onEditAdmin = useCallback(
        admin => {
            setEditedAdmin({
                ...admin,
                budget: constructBudgetObject(
                    budgets?.filter(budget => budget.userId === admin.userId),
                    BudgetCategoryTypes.GENERAL
                )
            });
        },
        [budgets]
    );

    const createBudget = useCallback(async () => {
        const results = await HttpClient.safePut("/api/budget/createBudget", {subAdminId: transferBudgetAdmin.userId});

        if (results?.error) {
            message.error("קרתה שגיאה, יש לרפרש ולנסות שוב");
            return;
        }

        fetchBudgets();
    }, [transferBudgetAdmin, fetchBudgets]);

    const onTransferBudget = useCallback(
        async (amount, budgetId) => {
            if (!Number(amount) || !transferBudgetAdmin?.userId) {
                message.error("קרתה שגיאה, יש לבדוק שהסכום שהוכנס נכון ולנסות שוב");
                return;
            }

            const results = await HttpClient.safePost("/api/budget/transferBudget", [
                {
                    amount,
                    subAdminId: transferBudgetAdmin.userId,
                    ...(budgetId !== BudgetCategoryTypes.GENERAL && {category: budgetId})
                }
            ]);

            if (results?.error) {
                message.error("קרתה שגיאה, יש לבדוק אם ההסכום שהוכנס נכון ושיש מספיק תקציב למשיכה");
                return;
            }

            fetchBudgets();
        },
        [transferBudgetAdmin, fetchBudgets]
    );

    const onViewOrders = useCallback(admin => {
        history.push(`/company/orders/${admin.companyId}?userId=${admin.userId}`);
    }, []);

    const onSendInvitationToAllSubAdmin = useCallback(async () => {
        setLoadingSendMail(true);
        const subAdminsToSendTo = filteredAdmins
            .filter(({firstLogin}) => firstLogin !== false)
            .map(({userId}) => userId);

        if (!subAdminsToSendTo.length) {
            message.info("לא נשלחו מיילים מכיוון שאין תת אדמינים לשלוח אליהם", 5);
            setLoadingSendMail(false);
            return;
        }

        const results = await HttpClient.safePost("/api/companies/sendInvitationsToSubAdmins", subAdminsToSendTo);

        setLoadingSendMail(false);

        if (results.error) {
            message.error("קרתה שגיאה בעת שליחת המייל, יש לנסות שנית");
        } else {
            const emails = filteredAdmins.filter(({firstLogin}) => firstLogin !== false).map(({email}) => email);
            log("Sent invitations to sub admins", {emails});
            message.success("המיילים נשלח בהצלחה");
        }

        fetchAdmins();
    }, [filteredAdmins, fetchAdmins]);

    const onSendInvitationToSubAdmin = useCallback(
        async admin => {
            setLoadingSendMail(true);

            const results = await HttpClient.safePost("/api/companies/sendInvitationsToSubAdmins", [admin.userId]);

            setLoadingSendMail(false);

            if (results.error) {
                message.error("קרתה שגיאה בעת שליחת המייל, יש לנסות שנית");
            } else {
                message.success("המייל נשלח בהצלחה");
            }

            fetchAdmins();
        },
        [fetchAdmins]
    );

    const transferringSubAdminBudget = useMemo(() => {
        if (me.features?.category_budget) {
            const subAdminBudgets = budgets?.filter(budget => budget.userId === transferBudgetAdmin?.userId) ?? [];
            return subAdminBudgets.reduce(
                (acc, current) => {
                    const {amount, ...otherProps} = current;
                    acc.categoryAmount[current?.category ?? BudgetCategoryTypes.GENERAL] = amount;
                    return {...acc, ...otherProps};
                },
                {categoryAmount: {}}
            );
        } else {
            return budgets?.find(budget => budget.userId === transferBudgetAdmin?.userId);
        }
    }, [budgets, transferBudgetAdmin, me.features?.category_budget]);

    const exportTableToExcel = useCallback(async () => {
        setLoadingExportToExcel(true);
        await exportAdminsToExcel(filteredAdmins, services, budgets, subAdminsIdsToOrders);
        log("Exported admins to excel", {admins: filteredAdmins.map(({email}) => email)});
        setLoadingExportToExcel(false);
    }, [filteredAdmins, services, budgets, subAdminsIdsToOrders]);

    const allSubAdminsDepartments = useMemo(
        () => uniq(subAdmins?.map(({department}) => department).filter(department => department) ?? []),
        [subAdmins]
    );

    const onPromoteUserToCompanyMaster = useCallback(async user => {
        const {error} = await HttpClient.safePost("/api/companies/promoteUserToCompanyMaster", {
            subAdminsId: user.userId
        });

        if (error) {
            message.error("קרתה שגיאה בעת תהליך קידום משתמש לאדמין ראשי בחברה", 5);
            message.error(error, 5);
        } else {
            message.success("הפעולה בוצעה בהצלחה");
        }

        fetchMe();
    }, []);

    return (
        <div className="company-profile-manage-admins">
            <EditSubAdminModal
                visible={!!editedAdmin}
                onSave={onSaveSingleAdmin}
                mainServices={services}
                departments={allSubAdminsDepartments}
                onClose={() => setEditedAdmin(null)}
                subAdmin={editedAdmin}
            />

            <BudgetModal
                budget={transferringSubAdminBudget}
                shouldShowCategoriesPanel={me.features?.category_budget}
                onSave={onTransferBudget}
                visibility={transferBudgetAdmin}
                loadingBudget={budgetLoading}
                onClose={() => setTransferBudgetAdmin(null)}
                title={
                    <>
                        <span>
                            {transferBudgetAdmin?.manageDepartments?.length ? (
                                <CrownFilled
                                    title={new StringBuilder()
                                        .append("אחראי על מחלקות:")
                                        .append(transferBudgetAdmin?.manageDepartments.join(", "))
                                        .toString()}
                                    className={classNames("sub-admin-table-crown", "manage-departments")}
                                />
                            ) : null}
                        </span>
                        <span>
                            {new StringBuilder().append("עריכת תקציב:").append(transferBudgetAdmin?.email).toString()}
                        </span>
                        <div>
                            {!!transferBudgetAdmin?.manageDepartments?.length && (
                                <span>יש לשים לב שמשתמש זו יוכל לחלק תקציב זה למשתמשים הכפופים לו במחלקה</span>
                            )}
                        </div>
                    </>
                }
                emptyState={
                    <div className="company-profile-manage-admins-no-budget">
                        <span>נראה שאין לאדמין זה תקציב</span>
                        <span>
                            שימו ❤️: לאחר יצירת התקציב יש לערוך את פרטי האדמין ולוודא שיש לו את האופציה לשלם בעזרת תקציב
                        </span>
                        <SquareButton onClick={createBudget}>יצירת תקציב</SquareButton>
                    </div>
                }
            />
            <CompanyBudgetHistoryModal
                history={history}
                visible={!!userBudgetHistory}
                user={userBudgetHistory}
                budget={constructBudgetObject(budgets?.filter(budget => budget.userId === userBudgetHistory?.userId))}
                onClose={() => setUserBudgetHistory(null)}
            />
            <span className="company-profile-manage-admins-header">אדמינים</span>
            <div className="company-profile-manage-admins-top-bar">
                <SearchInputWithIcon searchInput={searchInput} setSearchInput={setSearchInput} />
                <div className="company-profile-manage-admins-top-bar-button">
                    <Tooltip title="בלחיצה על כפתור זה תוכלו לשלוח בבת אחת מייל הזמנה להתחבר לפלטפורמה לכלל האדמינים שטענתם לתוך המערכת ועדיין לא התחברו">
                        <SquareButton loading={loadingSendMail} onClick={onSendInvitationToAllSubAdmin}>
                            <span>שליחת מייל התחברות</span>
                        </SquareButton>
                    </Tooltip>
                    {me.isCompanyMaster ? (
                        <SquareButton id="add-admin-button" onClick={() => setEditedAdmin({})}>
                            <PlusOutlined />
                            <span>הוספת אדמינים</span>
                        </SquareButton>
                    ) : null}
                    <SquareButton
                        loading={loadingExportToExcel}
                        disabled={isSubAdminLoading || budgetLoading || isSubAdminsOrdersLoading}
                        onClick={exportTableToExcel}>
                        <DownloadOutlined />
                        <span>יצא לאקסל</span>
                    </SquareButton>
                </div>
            </div>
            <RoundedTable
                rowKey="userId"
                pagination={false}
                scroll={{x: 1400}}
                loading={isSubAdminLoading}
                dataSource={filteredAdmins}
                columns={subAdminsTableColumns(
                    groupBy(budgets, budget => budget.userId),
                    budgetLoading,
                    onViewOrders,
                    onSendInvitationToSubAdmin,
                    setUserBudgetHistory,
                    subAdminsIdsToOrders,
                    isSubAdminsOrdersLoading,
                    ...(me.isCompanyMaster ? [onEditAdmin] : [null]),
                    ...(me.isCompanyMaster ||
                    (me?.manageDepartments?.length && company?.allowBudgetTransferByManageDepartment)
                        ? [setTransferBudgetAdmin]
                        : []),
                    ...(me.isCompanyMaster && me.features?.promote_user_to_company_master
                        ? [onPromoteUserToCompanyMaster]
                        : [])
                )}
            />
        </div>
    );
};
