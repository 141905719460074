import React, {useMemo} from "react";
import classNames from "classnames";
import {Tooltip} from "antd";
import {sumBy} from "lodash";
import {MinCategoryBarWidth} from "./consts";
import "./happiness-calculator-category-bar.css";

const CalculatorCategoriesBar = ({categoriesWithTotalPrice, totalPrice, className = ""}) => {
    let offset = 0;

    const categoriesPriceBar = useMemo(() => {
        const categoriesToShow = categoriesWithTotalPrice?.filter(({totalPrice}) => totalPrice);

        if (!categoriesToShow?.length) {
            return null;
        }

        const widthDebt = sumBy(categoriesToShow, category =>
            Math.max(0, MinCategoryBarWidth - Math.round((category.totalPrice / totalPrice) * 100))
        );

        return categoriesToShow.map((category, index) => {
            let width = Math.max((category.totalPrice / totalPrice) * 100, MinCategoryBarWidth);

            if (width !== MinCategoryBarWidth) {
                width = ((100 - widthDebt) / 100) * width;
            }

            const bar = (
                <Tooltip key={category.occasionCategoryId} placement="bottom" title={category.categoryName}>
                    <div
                        style={{
                            width: `calc(${width}% ${index ? "+ 9px" : ""})`,
                            zIndex: categoriesWithTotalPrice.length - index,
                            right: `calc(${offset}% ${index ? "- 9px" : ""})`,
                            backgroundColor: category.color,
                            color: category.color
                        }}
                        className={classNames("happiness-calculator-summary-category-bar", {
                            "has-category-id": category.occasionCategoryId
                        })}
                    />
                </Tooltip>
            );
            offset += width;
            return bar;
        });
    }, [categoriesWithTotalPrice]);

    return (
        <div className={classNames("happiness-calculator-summary-category-bar-container", className)}>
            {categoriesPriceBar?.length ? (
                categoriesPriceBar
            ) : (
                <div className={classNames("happiness-calculator-summary-category-bar", "default-bar")} />
            )}
        </div>
    );
};

export default CalculatorCategoriesBar;
