import React, {useCallback} from "react";

export const LoginContainer = ({history, log}) => {
    const onLoginClick = useCallback(() => {
        log("Login click from signup page");
        history.push("/login");
    }, [history]);

    return (
        <div className="login-register-container">
            <span>כבר יש לכם חשבון?</span>
            <a onClick={onLoginClick}>היכנסו לכאן</a>
        </div>
    );
};
