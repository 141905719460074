import React, {useCallback, useEffect, useMemo} from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import {AutoComplete} from "antd";
import classNames from "classnames";
import {NOOP} from "./NOOP";
import {checkStringMatchesInArray} from "./levenshteinDistanceUtils.js";

export const AutoCompleteAddressFormInput = ({
    searchText,
    onChange,
    editValue,
    placeholder,
    className,
    locationTypes = ["address"],
    onInputChange = NOOP,
    allowSelectRegularText = false,
    countriesToSearchIn = null,
    showDefaultOption = true,
    disabled = false,
    statusInput
}) => {
    useEffect(() => {
        if (editValue || editValue === "") {
            innerInputChanged(editValue);
        }
    }, [editValue]);

    const {
        ready,
        value,
        suggestions: {status, data},
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete({
        requestOptions: {
            language: "iw",
            types: locationTypes,
            componentRestrictions: {
                country: countriesToSearchIn
            }
        },
        cacheKey: locationTypes.join("-"),
        debounce: 1000
    });

    const autoCompleteOptions = useMemo(() => {
        if (!value || status !== "OK") {
            return [];
        }

        const googleOptions = data.map(({place_id, structured_formatting: {main_text, secondary_text}}) => {
            return {
                label: (
                    <div
                        style={{
                            borderBottom: "1px solid #EDEDED",
                            height: 60,
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column"
                        }}>
                        <span style={{fontSize: 16, color: "var(--secondary-color)"}}>{main_text}</span>
                        <span style={{fontSize: 14, color: "#747F92", marginTop: 5}}>{secondary_text}</span>
                    </div>
                ),
                value: place_id,
                place_id,
                isGoogleAddress: true,
                name: `${main_text}, ${secondary_text}`
            };
        });

        const defaultOption = allowSelectRegularText
            ? [
                  {
                      label: <i>{JSON.stringify(value)}</i>,
                      value: `${value} `,
                      name: value,
                      isGoogleAddress: false
                  }
              ]
            : [];

        const subStringMatch = checkStringMatchesInArray(
            value,
            data.map(({description}) => description)
        );
        const isSomeStringHasMatch = subStringMatch.some(stringMatch => stringMatch.matchPercentage > 50);

        if ((!data?.length || !isSomeStringHasMatch) && showDefaultOption) {
            return [...googleOptions, ...defaultOption];
        }

        return [...googleOptions];
    }, [value, data, allowSelectRegularText, status]);

    const innerInputChanged = useCallback(
        (newValue, shouldFetchData = true) => {
            setValue(newValue, shouldFetchData);
            onInputChange(newValue);
        },
        [setValue, onInputChange]
    );

    const selectAddress = (_, option) => {
        const trimmedAddress = option.name.trim();
        innerInputChanged(trimmedAddress, false);
        onChange(trimmedAddress, option);
        clearSuggestions();
    };

    return (
        <div
            className={classNames("wb-address-autosearch", className)}
            style={{
                display: "flex"
            }}>
            <AutoComplete
                status={statusInput}
                disabled={!ready || disabled}
                mode="multiple"
                loading={!ready}
                defaultActiveFirstOption={true}
                value={typeof value === "undefined" || value === null ? searchText : value}
                style={{width: "100%", border: value ? "1px solid var(--secondary-color)" : void 0}}
                options={autoCompleteOptions}
                onSearch={text => innerInputChanged(text)}
                onSelect={(text, option) => selectAddress(text, option)}
                placeholder={placeholder || "Street / Address / City / State"}
            />
        </div>
    );
};
