import {StringBuilder} from "../../AppUtils.js";

export const StartOfProviderInvoiceReform = new Date("01.01.23");
export const StartOfProviderPaymentReform = new Date("10.01.22");

export const InvoiceStatusTypes = {
    PENDING: "pending",
    DECLINED: "declined",
    ACCEPTED: "accepted",
    PAID: "paid"
};

export const InvoicesStatusToNameAndColor = {
    [InvoiceStatusTypes.PENDING]: {
        name: "חשבונית ממתינה לאישור",
        color: "var(--standard-text-color)"
    },
    [InvoiceStatusTypes.ACCEPTED]: {
        name: "חשבונית אושרה לתשלום",
        color: "var(--link-color)"
    },
    [InvoiceStatusTypes.DECLINED]: {
        name: "חשבונית נדחתה",
        color: "var(--error-color)"
    },
    [InvoiceStatusTypes.PAID]: {
        name: "חשבונית שולמה",
        color: "var(--accept-color)"
    }
};

export const errorTypes = {
    DATE_ERROR: "DATE_ERROR"
};

export const errorTypesToMessage = {
    [errorTypes.DATE_ERROR]: {
        message: new StringBuilder().append("ניתן לטעון חשבוניות רק עד חודש").append("{monthToUpload}").toString()
    }
};
