import {PageTitleLabel} from "../../components/PageTitle";
import {RoundedTable} from "../../components/RoundedTable";
import {AdminBillingToBeReceivedSummaryLine} from "./AdminBillingToBeReceivedSummaryLine";
import moment from "moment-timezone";
import React, {useEffect, useState} from "react";
import {Button, message} from "antd";
import {HttpClient} from "../../http/HttpClient";
import {sumBy} from "lodash";

const columns = () => {
    return [
        {
            title: "#",
            key: "#",
            width: 70,
            render(_, __, idx) {
                return `${idx + 1}`;
            }
        },
        {
            title: "COMPANY",
            dataIndex: "name",
            width: 300,
            sorter: (r1, r2) => {
                return r1.name.toLowerCase() < r2.name.toLowerCase() ? 1 : -1;
            },
            defaultSortOrder: "descend",
            render(_, {name, contact}) {
                return (
                    <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <label style={{color: "var(--secondary-color)", fontSize: 14, fontWeight: 500}}>
                                {name}
                            </label>
                            <label style={{color: "#747F92", fontSize: 12, fontWeight: 400}}>{contact}</label>
                        </div>
                    </div>
                );
            }
        },
        {
            title: "PAYMENT METHOD",
            dataIndex: "paymentMethod",
            render(paymentMethod) {
                return <span style={{fontSize: 14, color: "#747F92"}}>{paymentMethod}</span>;
            }
        },
        {
            title: "PROFESSIONALS",
            dataIndex: "providers",
            render(providers) {
                return <span style={{fontSize: 14, color: "#747F92"}}>{providers ? providers.length : 0}</span>;
            }
        },
        {
            title: "SECURITY CARD",
            dataIndex: "securityCard",
            render(securityCard) {
                return securityCard ? (
                    <Button type="link" style={{fontSize: 14, color: "#1260D1", fontWeight: 700}}>
                        YES
                    </Button>
                ) : (
                    <Button type="link" style={{fontSize: 14, color: "#747F92", fontWeight: 700}}>
                        NO
                    </Button>
                );
            }
        },
        {
            title: "TO BE RECEIVED",
            dataIndex: "totalToBeReceived",
            render(totalToBeReceived, {paymentClosed}) {
                return (
                    <span
                        style={{
                            fontSize: 14,
                            fontWeight: 700,
                            color: paymentClosed ? "#13C296" : "#D04949"
                        }}>{`₪${totalToBeReceived.toLocaleString()}+VAT`}</span>
                );
            }
        }
    ];
};

export const AdminBillingCompanyServicesToBeRecceived = ({date}) => {
    const [services, setServices] = useState(null);
    const [totalServices, setTotalServices] = useState(null);

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const srvs = await HttpClient.get(`/admin/api/billing/${date}/tobereceived/companies/services`);
                setServices(srvs);
                setTotalServices(sumBy(srvs, s => s.totalToBeReceived));
            } catch (e) {
                message.error("Something went wrong, please try again later.", 5);
            }
        });
    }, []);

    return (
        <>
            <PageTitleLabel
                style={{marginTop: 30, marginBottom: 20, fontSize: 20, justifyContent: "flex-start", fontWeight: 400}}>
                Companies services
            </PageTitleLabel>
            <RoundedTable
                rowKey="companyName"
                scroll={{x: 1000, y: 600}}
                style={{color: "#747F92"}}
                pagination={false}
                loading={services === null}
                dataSource={services || []}
                columns={columns()}
            />
            <AdminBillingToBeReceivedSummaryLine
                toBeReceivedOn={moment(date, "YYYY-MM").format("MMMM [1st]")}
                sumTotal={totalServices ? totalServices : 0}
            />
        </>
    );
};
