import CallToActionModal from "../components/CallToActionModal";
import React, {useEffect, useState} from "react";
import {Typography} from "antd";
import {ModalTitle} from "../components/ModalTitle";
import {ContactPhone} from "../support/ContactPhone";
import {HttpClient} from "../http/HttpClient";

export const EventNoMeetingLinkModal = ({history, match}) => {
    const [sessionName, setSessionName] = useState("");
    const {eventId} = match.params;

    useEffect(() => {
        (async () => {
            try {
                const {sessionName, serviceName} = await HttpClient.get(`/api/events/${eventId}`);
                setSessionName(sessionName || serviceName);
            } catch (e) {
                setSessionName("Hey there!");
            }
        })();
    }, []);

    const Title = () => <ModalTitle title={sessionName} subTitle="This session is missing an online link!" />;

    const SubTitle = () => (
        <div style={{display: "flex", flexDirection: "column"}}>
            <Typography.Text style={{textAlign: "center", fontSize: 20, marginBottom: 10}}>
                What's next?
            </Typography.Text>
            <Typography.Paragraph>
                <ul>
                    <li>We are waiting for the session's manager to set this session's link.</li>
                    <li>
                        You can contact us if you need help or join this session again later by clicking the session in
                        your calendar.
                    </li>
                    <li style={{display: "flex", flexWrap: "wrap", marginTop: 10}}>
                        Feel free to call: <span style={{marginLeft: 5}}>{ContactPhone.prettyPhoneNumber()}</span>
                    </li>
                </ul>
            </Typography.Paragraph>
        </div>
    );

    return sessionName ? (
        <CallToActionModal
            imageName="oops2"
            title={<Title />}
            subTitle={<SubTitle />}
            subTitleStyle={{textAlign: "left"}}
            actionText="Go to calendar"
            actionHandler={() => history.push("/dashboard/schedule")}
            anotherActionText="Contact us"
            anotherActionHandler={() => history.push("/contact?subject=general")}
            visible={true}
        />
    ) : null;
};
