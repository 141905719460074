import {RoundedTable} from "../../../components/RoundedTable";
import React, {useContext, useState} from "react";
import {truncate} from "lodash";
import {Button, Dropdown, Menu, Tooltip} from "antd";
import {LoadingOutlined, PlusCircleOutlined, FlagFilled, SwapOutlined} from "@ant-design/icons";
import {EyeIcon, PencilIcon, TrashIcon} from "../../../icons";
import moment from "moment-timezone";
import {parse as qsParse} from "query-string";
import {AppContext} from "../../../AppContext";
import {findBranchById} from "../../CompanyBranches";
import {CompanyContentSectionModal} from "./CompanyContentSectionModal";

const canEditContent = (me, content) => {
    if (!me.isCompanyMaster) {
        return (
            Array.isArray(content.branches) &&
            content.branches.length === 1 &&
            content.branches[0].branchId === me.branch.branchId
        );
    }

    return true;
};

const columns = (me, company, processingId, onEdit, onDisplayChange, onRemove, onManualPick, displayType, onMove) => {
    let {branchId} = qsParse(location.search);
    if (branchId === "Global") {
        branchId = null;
    }

    const cols = [
        {
            title: "#",
            key: "#",
            width: 70,
            render(_, __, idx) {
                return `${idx + 1}`;
            }
        },
        {
            title: "CONTENT PROVIDER",
            key: "contentProvider",
            width: 200,
            render(_, {sessionType, companyName, providerBrand, providerFirstName, providerLastName, providerEmail}) {
                return (
                    <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            {sessionType === "company" ? (
                                <label style={{color: "var(--secondary-color)", fontSize: 14, fontWeight: 500}}>
                                    {companyName}
                                </label>
                            ) : (
                                <>
                                    <label
                                        style={{
                                            color: "var(--secondary-color)",
                                            fontSize: 14,
                                            fontWeight: 500
                                        }}>{`${providerFirstName}${
                                        providerLastName ? ` ${providerLastName}` : ""
                                    }`}</label>
                                    <label style={{color: "#747F92", fontSize: 12, fontWeight: 400}}>
                                        {providerEmail || ""}
                                    </label>
                                </>
                            )}
                        </div>
                    </div>
                );
            }
        },
        {
            title: "CONTENT",
            key: "contentName",
            width: 200,
            render(_, {sessionName, serviceName, productName}) {
                return (
                    <span style={{fontSize: 14, color: "#747F92"}}>
                        {truncate(sessionName || productName || serviceName, {separator: " ", length: 25})}
                    </span>
                );
            }
        },
        {
            title: "CATEGORY",
            dataIndex: "tags",
            width: 200,
            render(tags) {
                return (
                    <span style={{fontSize: 14, color: "#747F92"}}>
                        {tags ? truncate(tags.map(({name}) => name).join(", "), {separator: ",", length: 30}) : ""}
                    </span>
                );
            }
        },
        {
            title: "BRANCHES",
            dataIndex: "branches",
            width: 200,
            render(branches) {
                branches = branches.map(b => {
                    return findBranchById(company, b.branchId);
                });

                return (
                    <span style={{fontSize: 14, color: "#747F92"}}>
                        {branches ? truncate(branches.map(b => b.name).join(", "), {separator: ",", length: 30}) : ""}
                    </span>
                );
            }
        },
        {
            title: "UPDATED",
            dataIndex: "updatedAt",
            width: 200,
            render(updatedAt) {
                return (
                    <span style={{fontSize: 14, color: "#747F92"}}>
                        {moment(updatedAt).format("DD/MM/YYYY, HH:mm")}
                    </span>
                );
            }
        }
    ];

    if (displayType === "manual" && branchId) {
        cols.push({
            title: "",
            key: "manualPick",
            width: 50,
            render(_, item) {
                return (
                    <Tooltip
                        title={
                            item.pickedForHomePage
                                ? "Unmark this if you would like this item to be hidden from the employee center home page."
                                : "Mark this if you would like this item to be presented in the employee center home page."
                        }>
                        <span onClick={() => onManualPick(item)} style={{cursor: "pointer"}}>
                            <FlagFilled
                                style={{
                                    fontSize: 18,
                                    color: item.pickedForHomePage ? "#005EDA" : "rgba(132, 146, 166, 0.7)"
                                }}
                            />
                        </span>
                    </Tooltip>
                );
            }
        });
    }

    cols.push({
        title: "",
        key: "actions",
        render(_, content) {
            const menu = (
                <Menu>
                    <Menu.Item key="display">
                        <Button
                            style={{
                                minWidth: 100,
                                color: "var(--secondary-color)",
                                display: "flex",
                                alignItems: "center"
                            }}
                            icon={
                                <EyeIcon
                                    style={{fill: "var(--secondary-color)", width: 22, height: 22, marginRight: 12}}
                                />
                            }
                            type="link"
                            onClick={() => onDisplayChange(content)}>
                            {content.status === "info-hidden" ? "Display" : "Undisplay"}
                        </Button>
                    </Menu.Item>
                    {canEditContent(me, content) ? (
                        <Menu.Item key="edit">
                            <Button
                                style={{
                                    minWidth: 100,
                                    color: "var(--secondary-color)",
                                    display: "flex",
                                    alignItems: "center"
                                }}
                                icon={
                                    <PencilIcon
                                        style={{fill: "var(--secondary-color)", width: 20, height: 20, marginRight: 12}}
                                    />
                                }
                                type="link"
                                onClick={() => onEdit(content)}>
                                Edit
                            </Button>
                        </Menu.Item>
                    ) : null}
                    <Menu.Item key="delete">
                        <Button
                            style={{
                                minWidth: 100,
                                color: "var(--secondary-color)",
                                display: "flex",
                                alignItems: "center"
                            }}
                            icon={
                                <TrashIcon
                                    style={{fill: "var(--secondary-color)", width: 20, height: 20, marginRight: 12}}
                                />
                            }
                            type="link"
                            onClick={() => onRemove(content)}>
                            Delete
                        </Button>
                    </Menu.Item>
                    <Menu.Item key="move">
                        <Button
                            style={{
                                minWidth: 100,
                                color: "var(--secondary-color)",
                                display: "flex",
                                alignItems: "center"
                            }}
                            icon={
                                <SwapOutlined style={{color: "var(--secondary-color)", fontSize: 16, marginRight: 8}} />
                            }
                            type="link"
                            onClick={() => onMove(content)}>
                            Move
                        </Button>
                    </Menu.Item>
                    {displayType === "manual" && branchId ? (
                        <Menu.Item key="delete">
                            <Button
                                style={{
                                    minWidth: 100,
                                    color: "var(--secondary-color)",
                                    display: "flex",
                                    alignItems: "center"
                                }}
                                icon={
                                    <FlagFilled
                                        style={{color: "var(--secondary-color)", fontSize: 16, marginRight: 12}}
                                    />
                                }
                                type="link"
                                onClick={() => onManualPick(content)}>
                                {content.pickedForHomePage ? "Deselect from home page" : "Select for home page"}
                            </Button>
                        </Menu.Item>
                    ) : null}
                </Menu>
            );

            return (
                <div onClick={e => e.stopPropagation()}>
                    <Dropdown.Button
                        className="wb-action-drpdwn"
                        style={{border: "none"}}
                        overlay={menu}
                        icon={processingId === content.eventId ? <LoadingOutlined /> : void 0}
                    />
                </div>
            );
        }
    });

    return cols;
};

export const CompanyContentTable = ({
    dataSource,
    onEdit,
    onDisplayChange,
    onRemove,
    onManualPick,
    onContentChange,
    processingId,
    displayType,
    maxHeight,
    maxWidth
}) => {
    const {company, me} = useContext(AppContext);
    const [contentToChangeSection, setContentToChangeSection] = useState(null);

    const openSectionModal = content => {
        setContentToChangeSection(content);
    };

    const doContentChange = changedContent => {
        setContentToChangeSection(null);
        onContentChange(changedContent);
    };

    if (!company || !me) {
        return null;
    }

    return (
        <>
            <CompanyContentSectionModal
                onClose={() => setContentToChangeSection(null)}
                content={contentToChangeSection}
                onChange={doContentChange}
            />
            <RoundedTable
                rowKey="eventId"
                className="wb-content-table"
                scroll={{x: maxWidth || "max-content", y: maxHeight || 600}}
                style={{color: "#747F92"}}
                pagination={false}
                loading={dataSource === null}
                dataSource={dataSource || []}
                onRow={content => ({
                    onClick: () => onEdit(content)
                })}
                rowClassName={({status}) =>
                    status === "info-hidden" ? "wb-content-row wb-content-hidden" : "wb-content-row"
                }
                columns={columns(
                    me,
                    company,
                    processingId,
                    onEdit,
                    onDisplayChange,
                    onRemove,
                    onManualPick,
                    displayType,
                    openSectionModal
                )}
            />
        </>
    );
};
