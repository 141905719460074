import {kosherTypes} from "../../../ProductsFilterModal/consts.jsx";

export const kosherTab = {
    anyKosher: "anyKosher",
    allKosher: "allKosher"
};

export const kosherTypesToShortHebrew = {
    [kosherTypes.REGULAR]: "רגילה",
    [kosherTypes.TZOHAR]: "צוהר",
    [kosherTypes.MEHADRIN]: "מהדרין",
    [kosherTypes.BEDATZ]: "בד״צ",
};
