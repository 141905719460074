import React from "react";
import "../../marketplace-category-item-details.css";
import "./provider-additional-products-skeleton.css";
import {Skeleton} from "antd";

export const ProviderAdditionalProductsSkeleton = () => {
    return (
        <div className="provider-footer-page-skeleton-container">
            <div className="provider-footer-page-skeleton-above-carousel-container">
                <Skeleton.Button className="provider-footer-page-skeleton-title" active={true} />
            </div>
            <div className="search-result-skeleton-items-container marketplace-category-filtered-items marketplace-category-filtered-responsive">
                {[...Array(4)].map(index => {
                    return (
                        <div key={index}>
                            <Skeleton.Button
                                className="search-result-skeleton-item marketplace-product-card"
                                key={index}
                                active={true}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
