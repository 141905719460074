import React from "react";
import styled from "styled-components";
import {Button, Input, Form} from "antd";
import {Link} from "react-router-dom";
import _ from "lodash";
import {StyledButton} from "../StyledButton";
import {ContactPhone} from "../../support/ContactPhone";

const {TextArea} = Input;

export const FormInput = styled(Input)`
    border: 1px solid #e8edf5;
    background-color: #fcfdfe;
    font-size: 16px;
    border-radius: 10px;
    height: 50px;
    width: 100%;
    outline: none;
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
`;

export const FormInputV2 = styled(Input)`
    border: 1px solid #e8edf5;
    background-color: #fcfdfe;
    font-size: 14px;
    border-radius: 5px;
    height: 40px;
    width: 100%;
    outline: none;
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
`;

export const FormLabel = styled.label`
    display: flex;
    align-items: center;
    padding: 4px 11px;
    user-select: none;
    border: 1px solid #e8edf5;
    background-color: #fcfdfe;
    font-size: 15px;
    font-weight: 700;
    border-radius: 10px;
    min-height: 50px;
    width: 100%;
    outline: none;
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
`;

export const FormConfirmButton = styled(Button)`
    height: 50px;
    color: #ffffff;
    background-color: ${({disabled}) => (disabled ? "#64D7BB" : "#13C296")};
    width: 100%;
    border-radius: 100px;
    font-weight: 700;
    font-size: 16px;
    outline: none;
    border: 1px solid white;

    &:hover,
    &:active {
        opacity: ${({hoveropacity}) => (hoveropacity ? hoveropacity : 0.95)};
        color: #ffffff;
        background-color: #64d7bb;
        font-weight: 700;
        font-size: 16px;
        outline: none;
        border: 1px solid white !important;
    }
`;

export const FormConfirmButtonV2 = styled(Button)`
    height: 50px;
    color: #ffffff;
    background-color: ${({disabled}) => (disabled ? "var(--primary-color)" : "var(--primary-color)")};
    width: 100%;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    outline: none;
    border: 1px solid white;

    &:hover,
    &:active {
        opacity: ${({hoveropacity}) => (hoveropacity ? hoveropacity : 0.95)};
        color: white;
        background-color: var(--primary-color);
        font-weight: 700;
        font-size: 16px;
        outline: none;
        border: 1px solid white !important;
    }
`;

export const FormCancelButton = styled(FormConfirmButton)`
    background-color: ${({disabled}) =>
        disabled ? "rgba(255, 0, 0, 0.25) !important" : "rgba(255, 0, 0, 0.65) !important"};

    &:hover,
    &:active {
        background-color: rgba(255, 0, 0, 0.25) !important;
    }
`;

export const FormActionButton = styled(Button)`
    height: 50px;
    color: #ffffff;
    background-color: #2f3e83;
    width: 100%;
    border-radius: 100px;
    font-weight: 700;
    font-size: 16px;
    color: white;
    outline: none;
`;

export const FormTextArea = styled(TextArea)`
    border: 1px solid #e8edf5;
    background-color: #fcfdfe;
    font-size: 16px;
    border-radius: 10px;
    height: 50px;
    width: 100%;
    outline: none;
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
`;

export const createThrottledFormInput = () => {
    let _onChange = null;
    const onChangeThrottled = _.throttle(
        e => {
            _onChange(e);
        },
        1000,
        {trailing: true}
    );

    return ({onChange, placeholder}) => {
        if (!_onChange) _onChange = onChange;
        return (
            <FormInput
                placeholder={placeholder}
                onChange={e => {
                    e.persist();
                    onChangeThrottled(e);
                }}
            />
        );
    };
};

export const FormBottomBar = ({children, backgroundColor, color, borderTop, justify}) => (
    <Form.Item
        style={{
            position: "fixed",
            left: 0,
            bottom: 0,
            marginBottom: 0,
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            zIndex: 2000,
            borderTop
        }}>
        <div
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
            }}>
            <div
                className="wb-form-bottom-bar"
                style={{
                    width: "100%",
                    height: 80,
                    backgroundColor: backgroundColor || "#2F3E83",
                    color,
                    display: "flex",
                    justifyContent: justify || void 0,
                    alignItems: "center"
                }}>
                {children}
            </div>
        </div>
    </Form.Item>
);

export const SignUpFormBottomBar = ({children, text}) => {
    return (
        <FormBottomBar>
            <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                <div>
                    <label style={{marginRight: 5, fontSize: 16, color: "white"}}>{text}</label>
                    <Link style={{fontSize: 16, color: "white", textDecoration: "underline"}} to="/login">
                        Sign in
                    </Link>
                </div>
            </div>
            {children}
        </FormBottomBar>
    );
};

export const SaveButton = ({loading, onClick, style, disabled, text}) => (
    <StyledButton
        htmlType="submit"
        disabled={disabled}
        loading={loading}
        onClick={onClick}
        style={{
            color: "white",
            backgroundColor: "#2F3E83",
            minWidth: 120,
            borderRadius: 25,
            marginLeft: 20,
            ...style
        }}>
        {text ? text : "Save"}
    </StyledButton>
);

export const TestButton = ({loading, onClick, style, disabled, text}) => (
    <StyledButton
        htmlType="submit"
        loading={loading}
        onClick={onClick}
        disabled={disabled}
        style={{
            color: "white",
            backgroundColor: "#2F3E83",
            minWidth: 120,
            borderRadius: 25,
            marginLeft: 20,
            ...style
        }}>
        {text ? text : "Send test email"}
    </StyledButton>
);

export const SendButton = ({loading, onClick, style, disabled, text}) => (
    <StyledButton
        htmlType="submit"
        disabled={disabled}
        loading={loading}
        onClick={onClick}
        style={{
            color: "white",
            backgroundColor: "#2F3E83",
            minWidth: 120,
            borderRadius: 25,
            marginLeft: 20,
            ...style
        }}>
        {text ? text : "Send"}
    </StyledButton>
);

export const CancelButton = ({onClick, style, disabled, text = "Cancel"}) => (
    <StyledButton
        onClick={onClick}
        disabled={disabled}
        style={{
            color: "#2F3E83",
            border: "1px solid #2F3E83",
            backgroundColor: "white",
            minWidth: 120,
            borderRadius: 25,
            ...style
        }}>
        {text}
    </StyledButton>
);

export const FormCancelSaveBottomBar = ({onSave, onCancel, loading, saveProps, cancelProps}) => (
    <FormBottomBar justify="center" backgroundColor="white" borderTop="1px solid rgb(237, 237, 237)">
        {onCancel ? <CancelButton onClick={onCancel} {...cancelProps} /> : null}
        <SaveButton onClick={onSave} loading={loading} {...saveProps} />
    </FormBottomBar>
);

export const ContactUsPhone = ({style}) => (
    <div
        style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            marginTop: 30,
            marginBottom: 120,
            ...style
        }}>
        <label>
            <strong>Need Help?</strong> Give us a call or chat with us at{" "}
        </label>
        <label style={{marginLeft: 5}}>{ContactPhone.prettyPhoneNumber()}</label>
    </div>
);
