import {EventInfoTitle} from "../event/EventInfoTitle";
import {PageSubTitleLabel} from "../components/PageTitle";
import {Divider, Spin} from "antd";
import React from "react";
import styled from "styled-components";
import {PageLoader} from "../components/PageLoader";

export const ProductButton = styled.div`
    font-size: 16px;
    color: var(--secondary-color);
    background-color: #fcfdfe;
    border: 1px solid #e8edf5;
    border-radius: 10px;
    min-height: 50px;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 4px 11px;
    user-select: none;
    cursor: pointer;

    &:hover {
        color: #1260d1;
        background-color: rgba(7, 77, 178, 0.1);
        border: 1px solid #1260d1;
    }
`;

export const EmployeeBookingProductSelection = ({
    me,
    event,
    currentStart,
    currentEnd,
    onBack,
    providerInfo,
    onProductSelect
}) => (
    <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: 15}}>
        <EventInfoTitle me={me} event={event} currentStart={currentStart} currentEnd={currentEnd} onBack={onBack} />
        <PageSubTitleLabel style={{marginBottom: 20}}>{event.address}</PageSubTitleLabel>
        {providerInfo && Array.isArray(providerInfo.services) ? (
            <div style={{display: "flex", flexDirection: "column", flexWrap: "wrap", width: "100%", maxWidth: 450}}>
                {providerInfo.services.map((product, idx) => (
                    <ProductButton key={`prod-${idx}`} onClick={() => onProductSelect(product)}>
                        <span
                            style={{
                                fontWeight: 700,
                                whiteSpace: "break-spaces",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                textAlign: "left"
                            }}>
                            {product.productName}
                        </span>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <Divider
                                type="vertical"
                                style={{height: 15, marginTop: 3, backgroundColor: "var(--secondary-color)", width: 2}}
                            />
                            <span style={{display: "flex", alignItems: "center", width: 60}}>
                                {product.durationMinutes} min
                            </span>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    color: "#00AB79",
                                    width: 50
                                }}>
                                ₪{product.price}
                            </div>
                        </div>
                    </ProductButton>
                ))}
            </div>
        ) : (
            <PageLoader align="flex-start" top={50} bottom={50} />
        )}
    </div>
);
