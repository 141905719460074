import React, {useCallback, useState} from "react";
import {set} from "lodash";
import {message} from "antd";
import {AdminMarketplaceViewAutoComplete} from "../components";
import {FormModal} from "../../components";
import {MarketplaceViewType} from "../../marketplace/consts";

export const AddExistingSelectPageCarouselModal = ({visible, onSave, onClose}) => {
    const [formValues, setFormValues] = useState({});

    const updateFormValues = useCallback((key, value) => {
        setFormValues(currentFormValues => set({...currentFormValues}, key, value));
    }, []);

    return (
        <>
            <FormModal
                visible={visible}
                validationFunction={() => {
                    if (!formValues?.selectPageId) {
                        message.error("יש צורך לבחור קרוסלה מתוך הרשימה");
                        return false;
                    }

                    return true;
                }}
                onSave={() => onSave(formValues)}
                onClose={onClose}
                header="צור קרוסלה חדשה"
                subTitle="הקרוסלה תווצר על סמך אתר בחירה קיים במערכת"
                fields={[
                    {
                        name: "_selectPageId",
                        label: "בחר אתר בחירה קיים",
                        type: "custom",
                        customComponent: (
                            <AdminMarketplaceViewAutoComplete
                                itemType={MarketplaceViewType.selectionPage}
                                content={formValues.selectPageId}
                                onSelect={existSelectPageId => updateFormValues("selectPageId", existSelectPageId)}
                            />
                        )
                    }
                ]}
            />
        </>
    );
};
