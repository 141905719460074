import React, {useCallback, useMemo, useState} from "react";
import {ConfigProvider, Switch, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined, SnippetsOutlined} from "@ant-design/icons";
import shortid from "shortid";
import {SubProductsEditOrAddModal, ModalModes} from "./SubProductsEditOrAddModal";
import "./sub-products-list.css";
import {HttpClient} from "../../http/HttpClient.jsx";
import {NOOP} from "../../utils/NOOP.jsx";

export const SubProductsList = ({
                                    allSubProducts,
                                    setAllSubProducts,
                                    readonly = false,
                                    onCreateSUbProduct = NOOP,
                                    onUpdateSubProduct = NOOP,
                                    onDeleteSubProduct = NOOP
                                }) => {
    const [visibleSubProductsModal, setVisibleSubProductsModal] = useState(false);
    const [selectedSubProduct, setSelectedSubProduct] = useState(null);

    const onCreationSUbProduct = useCallback(subProduct => {
        onCreateSUbProduct(subProduct);
        setSelectedSubProduct(null);
        setVisibleSubProductsModal(false);
    }, [onCreateSUbProduct])

    const onUpdateSubProductDetails = useCallback((subProductId, subProduct) => {
        onUpdateSubProduct(subProductId, subProduct);
        setSelectedSubProduct(null);
        setVisibleSubProductsModal(false);
    }, [onUpdateSubProduct])

    const onEdit = useCallback(subProduct => {
        setSelectedSubProduct(subProduct);
        setVisibleSubProductsModal(true);
    }, []);

    const onNew = useCallback(() => {
        setSelectedSubProduct(null);
        setVisibleSubProductsModal(true);
    }, []);

    const onSubProductDelete = useCallback(subProductId => {
        onDeleteSubProduct(subProductId);
    }, [onDeleteSubProduct]);

    const onStatus = useCallback((subProductId, status) => {
        onUpdateSubProduct(subProductId, {isActive: status});
    }, [onUpdateSubProduct]);

    const subProductsCategories = useMemo(
        () =>
            allSubProducts?.reduce((acc, {subProductCategory}) => {
                if (subProductCategory && !acc.includes(subProductCategory)) {
                    return [...acc, subProductCategory];
                }
                return acc;
            }, []),
        [allSubProducts]
    );

    const onDuplicateSubProduct = useCallback(subProductItem => {
        const {id, ...newSubProduct} = subProductItem;
        setSelectedSubProduct({...newSubProduct, name: `${subProductItem?.name} 2`})
        setVisibleSubProductsModal(true);
    }, []);

    return (
        <>
            {allSubProducts.map(item => {
                return (
                    <div id={"subproducts_" + item?.id} key={item?.id} className="sub-products-list-item">
                        <div className="sub-products-list-item-content">
                            <div className="sub-products-list-item-title">{item?.name}</div>

                            <div className="sub-products-list-item-price">
                                <span>{Number(item?.price).toLocaleString()}</span>
                                <span> ₪ </span>
                                <span> ליחידה </span>
                            </div>
                        </div>

                        <div className="sub-products-list-item-desc">{item?.description}</div>

                        <div className="sub-products-list-item-content">
                            <div className="sub-products-list-item-actions">
                                {!readonly ? (
                                    <>
                                        <div
                                            className="sub-products-list-item-actions-icon"
                                            onClick={() => onEdit(item)}>
                                            <EditOutlined />
                                        </div>
                                        <Tooltip title="שכפל מיני מוצר">
                                            <div
                                                className="sub-products-list-item-actions-icon"
                                                onClick={() => onDuplicateSubProduct(item)}>
                                                <SnippetsOutlined />
                                            </div>
                                        </Tooltip>
                                        <div
                                            className="sub-products-list-item-actions-icon"
                                            onClick={() => onSubProductDelete(item?.id)}>
                                            <DeleteOutlined />
                                        </div>
                                    </>
                                ) : null}

                                <div>
                                    <Switch
                                        checkedChildren="פעיל גלובלית"
                                        unCheckedChildren="לא פעיל גלובלית"
                                        checked={item.isActive}
                                        onChange={newStatus => onStatus(item.id, newStatus)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
            {!readonly ? (
                <div className="sub-products-list-add-product-container">
                    <div className="sub-products-list-add-product-content" onClick={onNew}>
                        <span className="sub-products-list-add-product-title">הוספת מיני מוצר</span>
                        <div className="sub-products-list-add-product-icon">
                            <PlusOutlined />
                        </div>
                    </div>
                </div>
            ) : null}
            <ConfigProvider direction="rtl">
                <SubProductsEditOrAddModal
                    onUpdateSubProduct={onUpdateSubProductDetails}
                    onCreateSUbProduct={onCreationSUbProduct}
                    subProduct={selectedSubProduct}
                    subProductsCategories={subProductsCategories}
                    visible={visibleSubProductsModal}
                    onClose={() => {
                        setVisibleSubProductsModal(false);
                        setSelectedSubProduct(null);
                    }}
                />
            </ConfigProvider>
        </>
    );
};
