import React, {useMemo} from "react";
import {Modal} from "antd";
import {ProgressiveImageLoader} from "../../components/index.jsx";
import GeneralImage from "../../images/general-image.svg";
import "./modular-product-details-modal.css";
import classNames from "classnames";
import {StringBuilder} from "../../AppUtils.js";
import {SubProductTitleContainer} from "./RenderSubProductsList.jsx";
import "./order-form-modular-products-panel.css";

export const ModularProductDetailsModal = ({open, onClose, product, showProviderSubProductsImages}) => {
    const showImage = useMemo(
        () => showProviderSubProductsImages && product?.subProductImage?.imageUrl,
        [product, showProviderSubProductsImages]
    );
    return (
        <>
            <Modal
                centered={true}
                open={open}
                footer={null}
                className="modular-product-details-modal"
                destroyOnClose={true}
                closable={false}
                onCancel={onClose}>
                <div className={classNames("product-image-container", {default: !showImage})}>
                    <ProgressiveImageLoader
                        src={showImage ? product?.subProductImage?.imageUrl : GeneralImage}
                        alt={product?.subProductImage?.imageId}
                        maxWidth={580}
                        height={392}
                        {...(showImage ? {width: 570} : {})}
                    />
                </div>
                <div className="modular-product-details-container">
                    <div className="modular-product-details">
                        <SubProductTitleContainer subProduct={product} />
                        <div
                            className={classNames("provider-price-by-modular-item-price", {
                                "non-active-product": !product?.isActive
                            })}>
                            <span>
                                {new StringBuilder()
                                    .append(Number(product?.price)?.toLocaleString())
                                    .append("₪")
                                    .append("ליחידה")
                                    .toString()}
                            </span>
                        </div>
                    </div>
                    <div
                        className={classNames("provider-price-by-modular-item-description", {
                            "non-active-product": !product?.isActive
                        })}>
                        {product?.description}
                    </div>
                </div>
            </Modal>
        </>
    );
};
