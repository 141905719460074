import React from "react";
import {ProviderOrders} from "../provider/providerOrders";
import {TableDateSearchProvider} from "../admin/Context/TableDateSearchContext.jsx";
import {ProviderOrderContextProvider} from "../provider/providerOrders/ProviderOrdersContext.jsx";
import {Devices} from "../components/ResponsiveView/consts.js";
import {ResponsiveView} from "../components/index.jsx";
import {ProviderOrdersMobile} from "../provider/providerOrders/ProviderOrders.mobile.jsx";

export const ProviderOrdersWrapper = () => {
    return (
        <TableDateSearchProvider>
            <ProviderOrderContextProvider>
                <ResponsiveView devices={[Devices.desktop]}>
                    <ProviderOrders />
                </ResponsiveView>

                <ResponsiveView devices={[Devices.mobile]}>
                    <ProviderOrdersMobile />
                </ResponsiveView>
            </ProviderOrderContextProvider>
        </TableDateSearchProvider>
    );
};
