import React, {useCallback, useEffect, useMemo, useState} from "react";
import {difference, isEmpty, noop, union, uniq} from "lodash";
import classNames from "classnames";
import {Button, Checkbox, ConfigProvider, Select, Table} from "antd";
import {filterProducts} from "../../AdminProducts/utils";
import {HttpClient} from "../../../http/HttpClient";
import {useProducts, useRequest} from "../../../utils/hooks";
import {MarketplaceSearchInput} from "../../../marketplace/MarketplaceSearchInput";
import ProductsFiltersModal from "../../../marketplace/ProductsFilterModal";
import {MarketplaceFilterSearchOptions} from "../../../marketplace/MarketplaceFilterSearchOptions";
import {ConfirmationRequiredWrapper} from "../../../components";

export const DEFAULT_PAGE = 60;
const {Option} = Select;

export const ProductsBase = ({
    currentCategory,
    setCurrentCategory,
    setCurrentTablePage,
    extraFilters,
    setExtraFilters,
    tableProps,
    rowSelection,
    topBarButtons,
    filterValues,
    lockedFilters = [],
    onLockedFiltersChange = noop,
    columns = []
}) => {
    const [productFiltersModalVisible, setProductFiltersModalVisible] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [searchedTag, setSearchedTag] = useState("");
    const [viewSelectedProducts, setViewSelectedProducts] = useState(false);

    useEffect(() => {
        setCurrentCategory("*");
    }, []);

    const [categories, isLoadingCategories, categoriesError, fetchCategories] = useRequest(
        "/api/v2/categories",
        "post",
        {}
    );
    const [tagProductIds, isLoadingTagProductsIds] = useRequest(
        `/api/searchTags/productIdsByTagIds`,
        "post",
        [!!searchedTag?.tagId],
        [],
        !!searchedTag?.tagId
    );
    const [allSearchTags = [], isLoadingSearchTags, tagsError, fetchSearchTags] = useRequest(
        "/admin/api/searchTags",
        "get",
        null,
        [],
        true,
        null,
        []
    );

    const {allProducts, isLoadingAllProducts, productsError, fetchAllProducts} = useProducts();

    const [productToSelectionSites, isLoadingProductToSelectionSite, productToSelectionSiteError] = useRequest(
        "/admin/api/selectPage/byProductIds",
        "post",
        allProducts.map(({productId}) => productId),
        [allProducts],
        true,
        null,
        null
    );

    const enrichedProducts = useMemo(() => {
        if (!allProducts.length || !productToSelectionSites) return allProducts;

        const newProducts = allProducts.map(product => {
            const productId = product.productId;
            const selectionSites = productToSelectionSites[productId] || [];

            return {
                ...product,
                selectionSites: selectionSites
            };
        });

        return newProducts;
    }, [productToSelectionSites, allProducts]);

    const categoryProducts = useMemo(() => {
        if (currentCategory === "*") return enrichedProducts;
        return enrichedProducts.filter(({services}) => services.includes(currentCategory));
    }, [enrichedProducts, currentCategory]);

    const filteredProductSearch = useMemo(() => {
        const productsToFilter = viewSelectedProducts
            ? categoryProducts.filter(({productId}) => rowSelection.selectedRowKeys.includes(productId))
            : categoryProducts;
        return filterProducts(
            productsToFilter,
            searchText,
            extraFilters,
            searchedTag?.name === searchText ? tagProductIds : [],
            viewSelectedProducts ? [] : extraFilters?.extraSearchTexts ?? []
        );
    }, [
        categoryProducts,
        searchText,
        extraFilters,
        viewSelectedProducts,
        rowSelection?.selectedRowKeys,
        searchedTag,
        tagProductIds
    ]);

    const onRefresh = useCallback(async () => {
        await fetchAllProducts(true);
        HttpClient.safeGet("/admin/api/search/generateSearchOptions");
    }, []);

    useEffect(() => {
        const {searchText = "", searchedTag = "", ...rest} = filterValues ?? {};
        setSearchText(searchText);
        setSearchedTag(searchedTag);
        setExtraFilters(rest);
    }, [filterValues]);

    const onTagSelected = useCallback(
        tag => {
            const newTags = union(extraFilters?.searchTags ?? [], tag.tagIds);
            setExtraFilters(extraFilters => ({...extraFilters, searchTags: newTags}));
        },
        [extraFilters]
    );

    const addTagByName = useCallback(
        name => {
            const tag = allSearchTags.find(tag => tag.name === name) ?? null;
            if (!tag) return;
            onTagSelected({tagIds: [tag.tagId]});
        },
        [searchedTag, onTagSelected]
    );

    const shouldLoadTable = useMemo(
        () => !!(!enrichedProducts.length || isLoadingCategories || (!!searchedTag?.tagId && isLoadingTagProductsIds)),
        [enrichedProducts, isLoadingCategories, isLoadingTagProductsIds, searchedTag]
    );
    const extraValuesDefaultValues = useMemo(() => {
        if (!filterValues) {
            return null;
        }

        const {searchText, searchedTag, ...rest} = filterValues;
        return rest;
    }, [filterValues]);

    useEffect(() => {
        if (currentCategory === "*") {
        } else {
            const subCategories = categories?.find(category => category.serviceId === currentCategory)?.subCategories;
        }
    }, [currentCategory]);

    const allFilterValues = useMemo(
        () => ({
            ...extraFilters,
            searchText,
            searchedTag
        }),
        [extraFilters, searchText, searchedTag]
    );

    const headerCheckbox = useMemo(
        () =>
            rowSelection ? (
                <Checkbox
                    //TODO: check if can be simplified
                    checked={
                        rowSelection.selectedRowKeys.length &&
                        filteredProductSearch.length &&
                        rowSelection.selectedRowKeys.length >= filteredProductSearch.length &&
                        difference(
                            filteredProductSearch.map(r => r.productId),
                            rowSelection.selectedRowKeys
                        ).length === 0
                    }
                    indeterminate={
                        rowSelection.selectedRowKeys.length &&
                        filteredProductSearch.length &&
                        difference(
                            filteredProductSearch.map(r => r.productId),
                            rowSelection.selectedRowKeys
                        ).length !== 0 &&
                        union(
                            filteredProductSearch.map(r => r.productId),
                            rowSelection.selectedRowKeys
                        ).length > 0
                    }
                    onChange={() => {
                        const currentProductsIds = filteredProductSearch.map(r => r.productId);
                        if (difference(currentProductsIds, rowSelection.selectedRowKeys).length === 0) {
                            rowSelection.onChange(currentProductsIds, false);
                            return;
                        }

                        rowSelection.onChange(currentProductsIds, true);
                    }}
                />
            ) : null,
        [rowSelection, filteredProductSearch]
    );

    const onTextSearch = useCallback(() => {
        setExtraFilters(currentExtraFilters => {
            const {extraSearchTexts = [], ...restExtraFilters} = currentExtraFilters;
            const newExtraSearchTexts = uniq([searchText, ...extraSearchTexts]);

            return {extraSearchTexts: newExtraSearchTexts, ...restExtraFilters};
        });
        setSearchText("");
    }, [extraFilters, searchText]);

    return (
        <ConfigProvider direction="rtl">
            <div className="admin-product-content">
                <ProductsFiltersModal
                    filters={extraValuesDefaultValues}
                    onClose={() => setProductFiltersModalVisible(false)}
                    onCancel={() => {
                        setExtraFilters({});
                        setProductFiltersModalVisible(false);
                    }}
                    onSave={() => {
                        setProductFiltersModalVisible(false);
                    }}
                    visible={productFiltersModalVisible}
                    onFiltersChange={setExtraFilters}
                    foundProductsCount={filteredProductSearch?.length}
                />
                <div className="admin-product-top-bar">
                    <div className="admin-product-search-container">
                        <div id="category-select">
                            <Select
                                showSearch
                                defaultValue={"*"}
                                defaultActiveFirstOption={true}
                                value={currentCategory}
                                disabled={!categories}
                                onSelect={setCurrentCategory}
                                placeholder="קטגוריה"
                                className="admin-product-search-select"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                                optionFilterProp="children"
                                listHeight={600}>
                                <Option value="*">כל הקטגוריות</Option>
                                {categories?.map(category => (
                                    <Option key={category.serviceId} value={category.serviceId}>
                                        {category.displayName ?? category.categoryName}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div id="filters-button">
                            <Button
                                className={classNames("admin-product-search-refresh-button", {
                                    isActive: !isEmpty(extraFilters)
                                })}
                                onClick={() => {
                                    setProductFiltersModalVisible(true);
                                }}>
                                מסננים
                            </Button>
                        </div>
                        <div id="refresh-button">
                            <ConfirmationRequiredWrapper
                                onYes={onRefresh}
                                title="שימו לב!"
                                subTitle="אין צורך לרענן מוצרים יותר במערכת, השימוש בכפתור תחת אחריותכם בלבד ו/או באישור מתכנת">
                                <Button className="admin-product-search-refresh-button">רענן מוצרים</Button>
                            </ConfirmationRequiredWrapper>
                        </div>
                        <div id="show-selection-button">
                            {rowSelection ? (
                                <Button
                                    className={classNames("admin-product-search-refresh-button", {
                                        isActive: viewSelectedProducts
                                    })}
                                    onClick={() => {
                                        setViewSelectedProducts(prev => !prev);
                                    }}>
                                    הצג מוצרים מסומנים
                                </Button>
                            ) : null}
                        </div>
                        {topBarButtons}
                    </div>
                    <div id="search-input" className="admin-product-search-container">
                        <Button disabled={!searchText || !rowSelection} onClick={onTextSearch}>
                            הוספת חיפוש
                        </Button>

                        <MarketplaceSearchInput
                            className="admin-product-search-input"
                            history={history}
                            searchInput={searchText}
                            setSearchInput={setSearchText}
                            placeholder="חיפוש חופשי"
                            debouncedSearchText={searchText}
                            setDebouncedSearchText={setSearchText}
                            showFilters={false}
                            showSearchButton={false}
                            showFiltersButton={false}
                            onItemClick={({link, text}) => {
                                setSearchText(text);
                            }}
                            onEnter={e => {
                                onTextSearch();
                                addTagByName(e.target.value);
                            }}
                            tagClicked={onTagSelected}
                        />
                    </div>
                </div>
                <MarketplaceFilterSearchOptions
                    filterValues={allFilterValues}
                    setFilterValues={setExtraFilters}
                    onLockedFiltersChange={onLockedFiltersChange}
                    lockedFilters={lockedFilters}
                    allSearchTags={allSearchTags}
                    removeSearchInput={() => {
                        setSearchText("");
                        setSearchedTag("");
                    }}
                    removeExtraSearchInput={searchText => {
                        setExtraFilters(currentExtraFilters => {
                            const {extraSearchTexts, ...restExtraFilters} = currentExtraFilters;

                            const newExtraSearchTexts = extraSearchTexts.filter(text => text !== searchText);

                            return {extraSearchTexts: newExtraSearchTexts, ...restExtraFilters};
                        });
                    }}
                />

                <Table
                    rowKey="productId"
                    className="admin-product-table-container"
                    scroll={{x: 800}}
                    pagination={{
                        position: ["bottomCenter"],
                        showSizeChanger: false,
                        defaultPageSize: DEFAULT_PAGE,
                        showTotal: total => <span>סה״כ {total}</span>,
                        onChange: (page, pageSize) => setCurrentTablePage(page - 1)
                    }}
                    loading={shouldLoadTable}
                    dataSource={filteredProductSearch || []}
                    columns={columns}
                    {...(rowSelection
                        ? {
                              rowSelection: {
                                  ...rowSelection,
                                  columnTitle: headerCheckbox,
                                  onSelect: ({productId}, selected) => rowSelection.onChange([productId], selected)
                              }
                          }
                        : {})}
                    {...tableProps}
                />
            </div>
        </ConfigProvider>
    );
};
