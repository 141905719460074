import React, {useContext} from "react";
import {StyledButton} from "../../components/StyledButton";
import {PencilIcon} from "../../icons";
import {AppContext} from "../../AppContext";
import {EXCenterContext} from "../EXCenterContext";
import {SmartImageContainer} from "../../components/SmartImageContainer";

export const EXCenterBranchCard = ({branchInfo, onBranchSelect, onEditBranchClick}) => {
    const {me} = useContext(AppContext);
    const {theme} = useContext(EXCenterContext);

    const link = `/center/main?branchId=${encodeURIComponent(branchInfo.branchId)}`;

    const editBranch = e => {
        onEditBranchClick(branchInfo);
        e.preventDefault();
        e.stopPropagation();
    };

    const onSelect = async e => {
        e.preventDefault();
        await onBranchSelect(branchInfo);
        window.location.href = link;
    };

    return (
        <a onClick={onSelect} style={{cursor: "pointer"}} target="_self">
            <div
                className="wb-ss-branch-card"
                style={{
                    width: 360,
                    height: 360,
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: 10,
                    margin: 10,
                    overflow: "hidden",
                    boxShadow: "0 10px 30px 0 rgba(22,29,37,0.1)",
                    cursor: "inherit"
                }}>
                <SmartImageContainer
                    className="wb-on-hover"
                    src={branchInfo.coverPhoto}
                    width={360}
                    height={300}
                    style={{
                        position: "relative",
                        backgroundColor: "#DAE0E4",
                        width: "100%",
                        cursor: "inherit"
                    }}>
                    {me.type === "company" && me.isCompanyMaster ? (
                        <StyledButton
                            onClick={editBranch}
                            className="wb-on-hover-show-flex"
                            style={{
                                width: 32,
                                height: 32,
                                position: "absolute",
                                left: 10,
                                top: 10,
                                backgroundColor: "white",
                                borderRadius: 4,
                                border: "none",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: 0
                            }}>
                            <PencilIcon style={{width: 15, height: 15, fill: theme.textColor}} />
                        </StyledButton>
                    ) : null}
                </SmartImageContainer>
                <div
                    style={{
                        height: 60,
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: 24,
                        color: theme.textColor,
                        fontWeight: 300,
                        backgroundColor: "white",
                        cursor: "inherit"
                    }}>
                    {branchInfo.name}
                </div>
            </div>
        </a>
    );
};
