import React, {useContext, useEffect, useState} from "react";
import {message} from "antd";
import {AppContext} from "../../../AppContext";
import {GoogleButton} from "../../../google/GoogleButton";
import {GoogleAuthButton} from "../../../google/GoogleAuthButton";
import {HttpClient} from "../../../http/HttpClient";

export const GoogleCalendarSyncButton = ({loading, onSuccess, onFailure, onClose, style, useIcal}) => {
    const {me} = useContext(AppContext);

    const [tokenValid, setTokenValid] = useState(false);

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const {isTokenValid} = await HttpClient.get("/api/users/calendar/valid");
                setTokenValid(isTokenValid);
            } catch (e) {
                message.error("Something went wrong, please try again later.");
            }
        });
    });

    const hasCalendarPermissions = () => {
        return (
            useIcal ||
            (tokenValid &&
                me.calendarApi &&
                me.calendarApi.type === "google" &&
                me.calendarApi.tokenObject &&
                me.calendarApi.tokenObject.scope &&
                me.calendarApi.tokenObject.scope.toLowerCase().indexOf("calendar.events") >= 0)
        );
    };

    return hasCalendarPermissions() ? (
        <GoogleButton loading={loading} onClick={onSuccess} style={style}>
            Sync to Google calendar
        </GoogleButton>
    ) : (
        <GoogleAuthButton
            scopes={["profile", "email", "https://www.googleapis.com/auth/calendar.events"]}
            onSuccess={onSuccess}
            onFailure={error => onFailure(`[Google Login Error] ${error}`)}
            ctx="sync">
            Sync to Google calendar
        </GoogleAuthButton>
    );
};
