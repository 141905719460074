import React, {useMemo} from "react";
import classnames from "classnames";
import {NOOP} from "../../utils/NOOP";
import {momentHebrewFormat} from "../../utils/DateFormat";

export const MonthBox = ({
    month,
    onClick,
    selectedIndex = -1,
    periods,
    onMouseEnter = NOOP,
    indexesDistanceOfPossibleEnd
}) => {
    const stateName = useMemo(() => {
        if (month === periods[0] && month === periods[1]) {
            return "חודש בודד";
        }

        const firstSelect = periods[0] > periods[1] ? periods[1] : periods[0];
        const lastSelect = periods[0] > periods[1] ? periods[0] : periods[1];

        switch (month) {
            case firstSelect:
                return "תחילת התקופה";
            case lastSelect:
                return "סוף התקופה";
            default:
                return null;
        }
    }, [month, periods]);

    return (
        <div
            onClick={onClick}
            onMouseEnter={() => onMouseEnter(month)}
            className={classnames("period-step-month", {
                "period-step-month-hover": !indexesDistanceOfPossibleEnd.length,
                pink: selectedIndex >= 0 || indexesDistanceOfPossibleEnd.indexOf(month) >= 0,
                transparent: selectedIndex === -1 && indexesDistanceOfPossibleEnd.indexOf(month) === -1
            })}>
            <div>{momentHebrewFormat(month, "MMMM YY")}</div>
            {stateName && <div className="selected">X {stateName}</div>}
        </div>
    );
};
