import React, {useContext, useEffect, useMemo, useState} from "react";
import moment from "moment";
import locale from "antd/lib/locale/he_IL";
import {isEmpty} from "lodash";
import classNames from "classnames";
import {ConfigProvider, DatePicker, Form, TimePicker} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {
    DEFAULT_DELIVERY_ARRIVING_TIME_RANGE,
    DEFAULT_DELIVERY_MIN_NOTICE,
    DEFAULT_MINIMUM_ORDER_NOTICE_HOURS,
    DELIVERY_PROVIDER_DEADLINE_HOUR,
    DISABLED_DATE_ERROR,
    ERROR_LEVEL,
    hebDayTranslate,
    OUT_OF_BUSINESS_HOURS_ERROR,
    OUT_OF_DELIVERY_PROVIDER_BUSINESS_HOURS_ERROR,
    SYSTEM_MINIMUM_ORDER_NOTICE_HOURS,
    TIME_ERROR,
    WARN_LEVEL,
    WeekdaysSorter
} from "../MarketplaceUtils.jsx";
import {DeliveryDisclaimers} from "../DeliveryDisclaimers.jsx";
import {MarketplaceBookingContext} from "../MarketplaceBooking/MarketplaceBookingContext.jsx";
import {CustomTimePicker} from "../../components/index.jsx";
import {isProductHasShippingProvider} from "../../admin/upload/utils.js";
import {formatHebrewTimeString} from "../../utils/DateUtils.jsx";
import {CategoryToWhenSectionNoticeTitle, productDefaultNoticeTitle} from "../consts.jsx";
import "./order-form-time-panel.css";
import isMobileDevice from "is-mobile";
import {useWindowDimensions} from "../../utils/hooks.jsx";

export const OrderFormTimePanel = ({
    product,
    providerDetails,
    allowPastDates,
    deliveryOptions,
    formValues,
    onFormValueChange,
    deliveryMaxTime
}) => {
    const {deliveryAreaProperties} = useContext(MarketplaceBookingContext);

    const [showDeliveryDisclaimers, setShowDeliveryDisclaimers] = useState(null);
    const deliveryArrivingTimeRange = useMemo(
        () => deliveryOptions?.arrivingTimeRange ?? DEFAULT_DELIVERY_ARRIVING_TIME_RANGE,
        [deliveryOptions?.arrivingTimeRange]
    );
    const {width} = useWindowDimensions();

    useEffect(() => {
        const {date, time} = formValues;
        const selectedTime = moment(date).set({
            hour: time?.hours(),
            minute: time?.minutes()
        });

        const minNotice =
            deliveryAreaProperties?.minNotice ?? deliveryOptions?.minNotice ?? DEFAULT_MINIMUM_ORDER_NOTICE_HOURS;
        const minNoticeDate = moment().add(minNotice, "hours");

        const deliveryMaxTime = moment(selectedTime).add(deliveryArrivingTimeRange, "hours");

        const isTimeInProviderBusinessHours = () => {
            if (isEmpty(providerDetails?.availability)) {
                return true;
            }

            return [selectedTime, deliveryMaxTime].every(testedTime => {
                const timeFormat = moment(testedTime).format("HH:mm");
                const dayFormat = moment(testedTime).format("dddd");
                const {start, end, selected} = providerDetails.availability[dayFormat.toLowerCase()];
                return (
                    selected &&
                    moment(timeFormat, "HH:mm").isSameOrAfter(moment(start, "HH:mm")) &&
                    moment(timeFormat, "HH:mm").isSameOrBefore(moment(end, "HH:mm"))
                );
            });
        };

        const isTimeInShippingDeliveryBusinessHours = selectedTime => {
            const isSelectedTimeIsToday = moment().isSame(selectedTime, "day");
            if (isSelectedTimeIsToday) {
                return false;
            }

            const isSelectedTimeIsTomorrow = selectedTime.isSame(moment().add(1, "day"), "day");
            const isCurrentTimeAfterDeliveryProviderDeadline = moment().isAfter(
                moment().set({
                    hour: DELIVERY_PROVIDER_DEADLINE_HOUR,
                    minute: 0,
                    second: 0,
                    millisecond: 0
                })
            );
            // return false if now is after DELIVERY_PROVIDER_DEADLINE_HOUR and selectedTime is tomorrow
            if (isCurrentTimeAfterDeliveryProviderDeadline && isSelectedTimeIsTomorrow) {
                return false;
            }
            return true;
        };

        const isDateDisabled = () => {
            if (!product.disabledDates?.length) {
                return false;
            }
            const currentDate = moment(date);
            return product.disabledDates?.find(({from, to}) => currentDate.isBetween(from, to, "date", "[]"));
        };

        let errorMessage = null;
        let errorLevel = null;

        const disabledDate = isDateDisabled();

        if (!time || !date) {
            errorMessage = null;
        } else if (selectedTime.isBefore(moment().add(SYSTEM_MINIMUM_ORDER_NOTICE_HOURS, "hours"))) {
            errorMessage = `לא ניתן להזמין מוצר בהתראה של פחות מ-${SYSTEM_MINIMUM_ORDER_NOTICE_HOURS} שעות`;
            errorLevel = ERROR_LEVEL;
        } else if (disabledDate) {
            errorMessage = DISABLED_DATE_ERROR.replace(
                "{DISABLED_DATES}",
                `${moment(disabledDate.from).format("DD/MM")} עד ${moment(disabledDate.to).format("DD/MM")} `
            );
            errorLevel = ERROR_LEVEL;
        } else if (!isTimeInProviderBusinessHours()) {
            errorMessage = OUT_OF_BUSINESS_HOURS_ERROR;
            errorLevel = ERROR_LEVEL;
        } else if (isProductHasShippingProvider(product) && !isTimeInShippingDeliveryBusinessHours(selectedTime)) {
            errorMessage = OUT_OF_DELIVERY_PROVIDER_BUSINESS_HOURS_ERROR;
            errorLevel = ERROR_LEVEL;
        } else if (selectedTime.isBefore(minNoticeDate)) {
            errorMessage = ` יש לשים לב כי זמן ההתראה הרגיל הדרוש למוצר הוא${minNoticeText}. עם זאת בקשתכם תועבר לספק לבדיקת היתכנות זמן התראה קצר יותר.`;
            errorLevel = WARN_LEVEL;
        }

        onFormValueChange(`errors.${TIME_ERROR}`, errorMessage ? {message: errorMessage, level: errorLevel} : null);
    }, [
        formValues?.date,
        formValues?.time,
        providerDetails?.availability,
        deliveryArrivingTimeRange,
        product?.disabledDates,
        deliveryAreaProperties
    ]);

    const minNoticeText = useMemo(() => {
        const minNoticeDays = Math.floor((deliveryOptions?.minNotice ?? DEFAULT_DELIVERY_MIN_NOTICE) / 24);
        const minNoticeHours = (deliveryOptions?.minNotice ?? DEFAULT_DELIVERY_MIN_NOTICE) % 24;
        return `
        ${minNoticeDays > 0 ? ` ${minNoticeDays} ימים` : ""}
        ${minNoticeDays > 0 && minNoticeHours > 0 ? "ו" : " "}
        ${minNoticeHours > 0 ? `${minNoticeHours} שעות` : ""}`;
    }, [product]);

    const noticeTitle = useMemo(
        () => formatHebrewTimeString(moment.duration(deliveryArrivingTimeRange, "hours")),
        [deliveryArrivingTimeRange]
    );

    const isMobileScreen = useMemo(() => width <= 425, [width]);

    return (
        <>
            <div className="marketplace-booking-time-date-container">
                <ConfigProvider direction="rtl" locale={locale}>
                    <div className="date-form-container">
                        <div className="date-form-container-sub-title">
                            <span>
                                {CategoryToWhenSectionNoticeTitle[product?.services?.[0]] ?? productDefaultNoticeTitle}
                            </span>
                            <span className="date-form-container-total-time">{noticeTitle}</span>
                        </div>
                        <div className="date-form">
                            <Form.Item name="date">
                                <DatePicker
                                    popupClassName={classNames("order-form-time-panel-date-picker", {
                                        mobile: isMobileDevice() || isMobileScreen
                                    })}
                                    inputReadOnly={true}
                                    style={{
                                        minWidth: 130,
                                        maxWidth: 130,
                                        height: "40px",
                                        fontSize: "14px",
                                        borderRadius: "5px"
                                    }}
                                    suffixIcon={null}
                                    onChange={momentDate => onFormValueChange("date", momentDate)}
                                    format="DD MMM, YYYY"
                                    placeholder={"בחירת תאריך"}
                                    disabledDate={date =>
                                        !allowPastDates && date.isBefore(moment(Date.now()).subtract(1, "days"))
                                    }
                                />
                            </Form.Item>
                            <Form.Item name="time">
                                <CustomTimePicker
                                    style={{
                                        width: 90,
                                        height: "40px",
                                        fontSize: "14px",
                                        borderRadius: "5px"
                                    }}
                                    locale={locale}
                                    format="HH:mm"
                                    suffixIcon={null}
                                    minuteStep={15}
                                    inputReadOnly={false}
                                    onChange={momentTime => onFormValueChange("time", momentTime)}
                                    placeholder={"משעה"}
                                    showNow={true}
                                />
                            </Form.Item>
                            <span>עד</span>

                            {deliveryMaxTime && deliveryMaxTime.date() !== formValues?.date?.date() ? (
                                <DatePicker
                                    popupClassName={classNames("order-form-time-panel-date-picker", {
                                        mobile: isMobileDevice()
                                    })}
                                    inputReadOnly={true}
                                    style={{
                                        minWidth: 130,
                                        maxWidth: 130,
                                        height: "40px",
                                        fontSize: "14px",
                                        borderRadius: "5px"
                                    }}
                                    suffixIcon={null}
                                    onChange={momentDate =>
                                        onFormValueChange(
                                            "date",
                                            momentDate?.subtract(deliveryArrivingTimeRange, "hours")
                                        )
                                    }
                                    format="DD MMM, YYYY"
                                    placeholder={"בחירת תאריך"}
                                    disabledDate={date =>
                                        !allowPastDates && date.isBefore(moment(Date.now()).subtract(1, "days"))
                                    }
                                    value={formValues?.date ? deliveryMaxTime : null}
                                />
                            ) : null}

                            <CustomTimePicker
                                style={{
                                    width: 90,
                                    height: "40px",
                                    fontSize: "14px",
                                    borderRadius: "5px"
                                }}
                                locale={locale}
                                format="HH:mm"
                                suffixIcon={null}
                                minuteStep={15}
                                inputReadOnly={false}
                                onChange={momentTime =>
                                    onFormValueChange("time", momentTime.subtract(deliveryArrivingTimeRange, "hours"))
                                }
                                placeholder="שעה"
                                showNow={true}
                                value={
                                    formValues?.time
                                        ? moment(formValues?.time).add(deliveryArrivingTimeRange, "hours")
                                        : null
                                }
                            />
                        </div>
                    </div>
                </ConfigProvider>
            </div>
            <div
                onClick={() => setShowDeliveryDisclaimers(prev => !prev)}
                className="marketplace-booking-delivery-disclaimer">
                {"לצפייה "}
                <u>בתנאי אספקה ושילוח</u>
                <DownOutlined
                    className={classNames("marketplace-booking-delivery-disclaimer-icon", {
                        active: showDeliveryDisclaimers
                    })}
                />
                {showDeliveryDisclaimers ? (
                    <DeliveryDisclaimers
                        product={product}
                        deliveryOptions={deliveryOptions}
                        minNoticeText={minNoticeText}
                    />
                ) : null}
            </div>
            {product && providerDetails.availability ? (
                <div>
                    <div style={{color: "#8398A6", fontSize: "16px"}}>שעות פעילות בית העסק</div>
                    {WeekdaysSorter(Object.keys(providerDetails.availability)).map(day =>
                        providerDetails.availability[day].selected ? (
                            <div
                                key={day}
                                style={{
                                    color: "#8398A6",
                                    fontSize: "16px"
                                }}>
                                {providerDetails.availability[day].start} - {providerDetails.availability[day].end} |{" "}
                                {hebDayTranslate(day)}
                            </div>
                        ) : null
                    )}
                </div>
            ) : null}
        </>
    );
};
