import React, {useContext, useEffect, useMemo, useState} from "react";
import {AutoComplete, Select} from "antd";
import {TrashIcon} from "../../icons";
import {MarketplaceViewType} from "../../marketplace/consts";
import {useDebounceState, useRequest} from "../../utils/hooks";
import {HttpClient} from "../../http/HttpClient";
import {isProductActive} from "../../marketplace/MarketplaceUtils";
import {AppContext} from "../../AppContext";
import "./admin-marketplace-view-auto-complete.css";

const {Option} = Select;

export const AdminMarketplaceViewAutoComplete = ({onSelect, content, itemType}) => {
    const {me} = useContext(AppContext);
    const [searchInput, setSearchInput, unDebouncedSearchInput] = useDebounceState("", 200);
    const [translateContent, setTranslateContent] = useState(null);

    useEffect(() => {
        if (itemType) {
            setSearchInput(null);
        }
    }, [itemType]);

    const [searchResultsOfSelectPage] = useRequest(
        `/admin/api/selectPage/search/${searchInput}`,
        "GET",
        null,
        [searchInput],
        !!searchInput && itemType === MarketplaceViewType.selectionPage
    );

    const [searchResultsOfProduct] = useRequest(
        `/api/search/${searchInput}?amount=10&searchAutoCompleteType=products`,
        "GET",
        null,
        [searchInput],
        !!searchInput && itemType === MarketplaceViewType.product
    );

    const searchResultsFormatted = useMemo(() => {
        if (searchResultsOfSelectPage && itemType === MarketplaceViewType.selectionPage) {
            return searchResultsOfSelectPage?.map(selectPage => ({
                name: selectPage.selectPageId,
                label: selectPage.name,
                shouldHighlightOption: !selectPage.isActive
            }));
        } else if (searchResultsOfProduct && itemType === MarketplaceViewType.product) {
            return searchResultsOfProduct?.products.map(({productName, productId}) => ({
                name: productId,
                label: productName
            }));
        }
    }, [searchResultsOfSelectPage, searchResultsOfProduct, itemType]);

    useEffect(() => {
        async function run() {
            if (content && itemType && me) {
                if (itemType === MarketplaceViewType.product) {
                    const res = await HttpClient.safeGet(`/api/products/byId/${content}?includeNotApproved=true`);
                    setTranslateContent(
                        res?.error
                            ? null
                            : {
                                  displayName: res.service.productName,
                                  isActive: isProductActive(res.service, res.provider, me)
                              }
                    );
                } else if (itemType === MarketplaceViewType.selectionPage) {
                    const res = await HttpClient.safeGet(`/api/selectPage/${content}`);
                    setTranslateContent(res?.error ? null : {displayName: res.name, isActive: res.isActive});
                }
            }
        }

        run();
    }, [itemType, content, me]);

    return (
        <div>
            <div>
                <AutoComplete
                    onChange={newValue => {
                        setSearchInput(newValue);
                    }}
                    value={unDebouncedSearchInput}
                    onSelect={(contentId, option) => {
                        onSelect(contentId, option);

                        if (itemType === MarketplaceViewType.product) {
                            const foundProduct = searchResultsOfProduct?.products.find(
                                ({productId}) => productId === contentId
                            );
                            setTranslateContent(foundProduct.productName);
                        } else if (itemType === MarketplaceViewType.selectionPage) {
                            const foundSelectPage = searchResultsOfSelectPage?.find(
                                ({selectPageId}) => selectPageId === contentId
                            );
                            setTranslateContent(foundSelectPage.name);
                        }
                    }}>
                    {searchResultsFormatted?.map(option => (
                        <Option
                            style={option?.shouldHighlightOption ? {backgroundColor: "var(--warning-color)"} : {}}
                            key={option.name}
                            value={option.name}>
                            {option.label}
                        </Option>
                    ))}
                </AutoComplete>
            </div>

            {content && (
                <div className="admin-marketplace-view-auto-complete-translated-content">
                    <span style={!translateContent?.isActive ? {backgroundColor: "var(--warning-color)"} : {}}>
                        {translateContent?.displayName ?? content}
                    </span>
                    <TrashIcon
                        style={{fill: "black"}}
                        onClick={() => {
                            onSelect(null);
                        }}
                    />
                </div>
            )}
        </div>
    );
};
