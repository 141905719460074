import {createContext, useCallback, useEffect, useMemo, useState} from "react";

export const StepperContext = createContext({});

export const StepperContextProvider = ({tabs = [], defaultTabKey = null, children}) => {
    const [currentTab, setCurrentTab] = useState("");
    const [stepIndices, setStepIndices] = useState({});

    useEffect(() => {
        if (tabs) {
            const defaultTab = tabs.find(tab => tab?.tabKey === defaultTabKey);
            setCurrentTab(defaultTab?.tabKey ?? tabs[0]?.tabKey);
            setStepIndices(
                tabs.reduce((acc, tab) => {
                    acc[tab.tabKey] = 1;
                    return acc;
                }, {})
            );
        }
    }, [tabs]);

    const currentTabData = useMemo(() => {
        return tabs.find(tab => tab.tabKey === currentTab);
    }, [tabs, currentTab]);

    const currentStepIndex = useMemo(() => {
        return stepIndices[currentTab] || 1;
    }, [stepIndices, currentTab]);

    const currentComponent = useMemo(() => {
        return currentTabData?.steps[currentStepIndex - 1] || null;
    }, [currentStepIndex, currentTabData]);

    const nextStep = useCallback(() => {
        if (currentStepIndex - 1 < currentTabData?.steps.length) {
            setStepIndices(prev => ({
                ...prev,
                [currentTab]: prev[currentTab] + 1
            }));
        }
    }, [currentStepIndex, currentTab, currentTabData?.steps.length]);

    const prevStep = useCallback(() => {
        if (currentStepIndex > 1) {
            setStepIndices(prev => ({
                ...prev,
                [currentTab]: prev[currentTab] - 1
            }));
        }
    }, [currentStepIndex, currentTab]);

    const switchTab = tabKey => {
        if (tabs.some(tab => tab.tabKey === tabKey)) {
            setCurrentTab(tabKey);
        }
    };

    return (
        <StepperContext.Provider
            value={{
                currentTab,
                currentStepIndex,
                currentComponent,
                nextStep,
                prevStep,
                switchTab,
                tabs
            }}>
            {children}
        </StepperContext.Provider>
    );
};
