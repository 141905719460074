import moment from "moment";
import {maxBy, minBy, sumBy} from "lodash";
import {getOrderPrice} from "../../utils/calculationUtils";
import {getDealerTypeReversed} from "../upload/AdminUploadUtils";
import {kosherTypesToHebrew, ProductKosherTypesPriority} from "../../marketplace/ProductsFilterModal/consts";
import {createWorkbook, createWorksheet, DownloadExcel} from "../../utils/excelUtils.js";
import {MainServices} from "../../consts.js";

const columns = [
    {header: "ח.פ/מס׳ עוסק", key: "businessNumber"},
    {header: "שם השותף", key: "providerName"},
    {header: "סוג העוסק", key: "businessType"},
    {header: "מאושר/לא מאושר", key: "isApprove"},
    {header: "תאריך עליה לאויר", key: "createdAt"},
    {header: "כשרות", key: "kosherStatus"},
    {header: "תעודת כשרות", key: "kosherCertificate"},
    {header: "האפי האוורס", key: "happyHours"},
    {header: "מגשי אירוח", key: "platters"},
    {header: "מתנות", key: "packages"},
    {header: "פעילות צוותית", key: "workshops"},
    {header: "חוב כללי משוער (כולל מעמ)", key: "estimatedTotalDebt"},
    {header: "חוב בפיגור (כולל מעמ)", key: "outstandingDebt"},
    {header: "עמלה ממוצעת", key: "averageCommission"},
    {header: "עמלה ממוצעת בפועל", key: "actualAverageCommission"},
    {header: "עמלה תחתית", key: "minimumCommission"},
    {header: "סה״כ מכירות (כולל מעמ)", key: "totalSales"},
    {header: "כמות עסקאות", key: "transactionCount"},
    {header: "איש קשר", key: "contactPerson"},
    {header: "טלפון", key: "phoneNumber"},
    {header: "מייל", key: "email"},
    {header: "הערות", key: "comments"}
];

export const ExportProvidersTableToExcel = async (providers, orders) => {
    const workbook = createWorkbook();
    const sheet = createWorksheet(workbook);
    sheet.columns = columns;
    addRows(sheet, providers, orders);
    await DownloadExcel(workbook, "vendors");
};

const addRows = (sheet, providers, orders) => {
    const rows = providers.reduce((allRows, provider) => {
        const providerProducts = provider?.services ?? [];
        const providerActiveProducts = providerProducts.filter(product => product.isActive);
        const isApproved = provider.approved ? "מאושר" : "לא מאושר";

        const createdAt = moment(provider.createdAt).format("DD/MM/YY");
        const dealerType = getDealerTypeReversed(provider.dealerType);

        const kosherCertificateExpireDate = provider?.kosherCertificateExpireDate
            ? moment(provider.kosherCertificateExpireDate).format("DD/MM/YY")
            : "";

        const providerHighestKosherKey =
            maxBy(providerProducts, product => ProductKosherTypesPriority[product.productKosherType])
                ?.productKosherType ?? "";
        const providerHighestKosher = kosherTypesToHebrew[providerHighestKosherKey];

        const providerProductsCategoriesSum = providerProducts.reduce(
            (acc, product) => {
                if (product?.services?.length && product.isActive) {
                    for (const service of product.services) {
                        acc[service] += 1;
                    }
                }

                return acc;
            },
            {
                [MainServices.HAPPY_HOURS]: 0,
                [MainServices.WORKSHOPS]: 0,
                [MainServices.PACKAGES]: 0,
                [MainServices.PLATTERS]: 0
            }
        );

        const arrearsDebt = provider?.arrearsDebt ?? 0;
        const futureDebt = provider?.futureDebt ?? 0;
        const totalDebt = arrearsDebt + futureDebt;

        const providerOrders = orders.filter(({providerId}) => provider.providerId === providerId);
        const totalOrdersPrice = sumBy(providerOrders, order => getOrderPrice(order));
        const totalOrdersCommissionsPrice = sumBy(providerOrders, order => {
            const offeredPrice = Number(order.offeredPrice);
            const commission = Number(order.commission);

            if (!commission || !offeredPrice) {
                return 0;
            }

            return offeredPrice / commission;
        });
        const actualAverageCommission = totalOrdersPrice / (totalOrdersCommissionsPrice || 1);
        const productTotalCommission = sumBy(providerActiveProducts, product => Number(product.commission) || 0);
        const productAverageCommission = productTotalCommission / (providerActiveProducts.length || 1);
        const productLowestCommission = minBy(providerActiveProducts, "commission")?.commission ?? 0;
        const contactFullName = `${provider?.contactFirstName} ${provider?.contactLastName}`;

        const row = [
            provider.businessNumber,
            provider.businessName,
            dealerType,
            isApproved,
            createdAt,
            providerHighestKosher,
            [kosherCertificateExpireDate, provider?.kosherCertificateImageUrl].filter(Boolean).join(" | "),
            ...Object.values(providerProductsCategoriesSum),
            totalDebt,
            arrearsDebt,
            productAverageCommission.toFixed(2),
            actualAverageCommission.toFixed(2),
            productLowestCommission,
            totalOrdersPrice,
            providerOrders?.length,
            contactFullName,
            provider.contactPhoneNumber,
            provider.contactEmail
        ];

        const transformRow = row.map(item => item ?? "");
        allRows.push(transformRow);
        return allRows;
    }, []);

    sheet.addRows(rows);
};
