import React, {useContext, useEffect, useReducer, useState} from "react";
import {truncate} from "lodash";
// import randomcolor from "randomcolor";
import {message, Tag, Tooltip, Avatar, Button, Form, Dropdown, Menu} from "antd";
import {
    CloudUploadOutlined,
    LoadingOutlined,
    InfoCircleOutlined,
    SendOutlined,
    PlusCircleOutlined,
    LinkOutlined,
    CheckCircleOutlined
} from "@ant-design/icons";
import "./company-employees.css";
import {CompanyEmployeesHibobModal} from "./CompanyEmployeesHibobModal";
import {CompanyEmployeesSpreadsheetModal} from "./CompanyEmployeesSpreadsheetModal";
import {CompanyEmployeeEdit} from "./CompanyEmployeeEdit";
import {hasBranches} from "./CompanyBranchSelect";
import {CompanyEmployeeAddForm} from "./CompanyEmployeeAddForm";
import {findBranchById} from "./CompanyBranches";
import {HttpClient} from "../http/HttpClient";
import {SearchInput, StyledButton, RoundedTable} from "../components";
import {AppContext} from "../AppContext";
import {EventBus} from "../bus/EventBus";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {INVALID_EMAIL_MARK} from "../data/defaults";
import PageTitleHeader from "../components/PageTitle";
import {copyPathToClipboard} from "../utils/copyToClipboard";

let columns = (me, company, onDelete, deletingEmployeeId, onEdit) => {
    let cols = [
        {
            title: "#",
            key: "#",
            width: 70,
            fixed: "left",
            render(_, __, idx) {
                return `${idx + 1}`;
            }
        },
        {
            title: "NAME",
            key: "name",
            width: 250,
            fixed: "left",
            render(_, record) {
                const {firstName, lastName, email, imageUrl} = record;
                return (
                    <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                        {imageUrl ? (
                            <Avatar src={imageUrl} />
                        ) : (
                            <Avatar style={{backgroundColor: "#ff9f1a", verticalAlign: "middle"}} size="large">
                                {firstName.charAt(0).toUpperCase()}
                            </Avatar>
                        )}
                        <div style={{display: "flex", flexDirection: "column", marginLeft: 20}}>
                            <label className={"company-employees-table-label-name-title"}>
                                {firstName} {lastName}
                            </label>
                            <label className={"company-employees-table-label-name-subtitle"}>
                                {email.toLowerCase().replace(`${INVALID_EMAIL_MARK} `, "")}
                            </label>
                        </div>
                    </div>
                );
            }
        }
    ];

    if (me.type === "company") {
        cols.push({
            title: "ADDRESS",
            dataIndex: "address",
            key: "address",
            render(address) {
                return <label className={"company-employees-table-label"}>{address}</label>;
            }
        });
    }

    cols.push({
        title: "BIRTHDAY",
        dataIndex: "birthday",
        render(birthday) {
            return <label className={"company-employees-table-label"}>{birthday || ""}</label>;
        }
    });

    cols.push({
        title: "POSITION",
        dataIndex: "position",
        key: "position",
        render(position) {
            return <label className={"company-employees-table-label"}>{position}</label>;
        }
    });

    if (hasBranches(me, company)) {
        cols.push({
            title: "SITE",
            dataIndex: "branch",
            key: "branch",
            width: 200,
            render(branch) {
                if (branch) {
                    const companyBranch = findBranchById(company, branch.branchId, "");
                    if (companyBranch) {
                        return companyBranch.name;
                    }
                }
                return "";
            }
        });
    }

    if (me.type === "company") {
        cols.push({
            title: "SIGNED IN",
            dataIndex: "verified",
            key: "verified",
            sorter: (r1, r2) => {
                if (
                    (r1.verified && r2.verified) ||
                    (r1.email.toLowerCase().indexOf(INVALID_EMAIL_MARK) >= 0 &&
                        r2.email.toLowerCase().indexOf(INVALID_EMAIL_MARK) >= 0)
                ) {
                    return 0;
                } else if (r1.verified || r2.email.toLowerCase().indexOf(INVALID_EMAIL_MARK) >= 0) {
                    return -1;
                } else {
                    return 1;
                }
            },
            defaultSortOrder: "ascend",
            render(verified, {email}) {
                if (email.toLowerCase().indexOf(INVALID_EMAIL_MARK) >= 0) {
                    return (
                        <div style={{display: "flex", alignItems: "center"}}>
                            <span style={{fontSize: 16, color: "rgb(255, 159, 26)"}}>INVALID EMAIL</span>
                            <Tooltip title="This email address is incorrect or not exist. Please delete this employee and add a new employee with a correct email address.">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginLeft: 5
                                    }}>
                                    <InfoCircleOutlined style={{fontSize: 16, color: "rgb(255, 159, 26)"}} />
                                </div>
                            </Tooltip>
                        </div>
                    );
                }
                return verified ? "YES" : "NO";
            }
        });
    }

    cols.push({
        title: "REGISTERED SESSIONS",
        dataIndex: "eventsRegistered",
        key: "eventsRegistered",
        render(eventsRegistered, {userId}) {
            if (!eventsRegistered || eventsRegistered.length === 0) return null;
            return (
                <div style={{display: "flex", alignItems: "center"}}>
                    {eventsRegistered.slice(0, 5).map((eventRegistered, idx) => {
                        const eventName =
                            eventRegistered.productName || eventRegistered.serviceName || eventRegistered.sessionName;
                        return (
                            <Tooltip key={`${userId}-${idx}`} title={eventName}>
                                <Tag
                                    color={eventRegistered.backgroundColor || randomcolor({luminosity: "dark"})}
                                    style={{borderRadius: 10, fontSize: 14, color: "white"}}>
                                    {truncate(eventName, {
                                        separator: " ",
                                        length: 30
                                    })}
                                </Tag>
                            </Tooltip>
                        );
                    })}
                </div>
            );
        }
    });

    if (me.type === "company") {
        cols.push({
            title: "ACTION",
            key: "action",
            render(_, employee) {
                const {userId} = employee;

                const menu = (
                    <Menu>
                        <Menu.Item key="deleteItem">
                            <Button
                                style={{minWidth: 100, color: "var(--secondary-color)"}}
                                type="link"
                                loading={deletingEmployeeId === userId}
                                onClick={async () => await onDelete(userId)}>
                                Delete
                            </Button>
                        </Menu.Item>
                        <Menu.Item key="editItem">
                            <Button
                                type="link"
                                style={{minWidth: 100, color: "var(--secondary-color)"}}
                                onClick={async () => await onEdit(employee)}>
                                Edit
                            </Button>
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <Dropdown.Button
                        className="wb-action-drpdwn"
                        style={{border: "none"}}
                        overlay={menu}
                        icon={deletingEmployeeId === userId ? <LoadingOutlined /> : void 0}
                    />
                );
            }
        });
    }

    return cols;
};

export const CompanyEmployees = () => {
    const {me, company} = useContext(AppContext);
    const [form] = Form.useForm();
    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        employees: null,
        term: "",
        loading: false,
        deletingEmployeeId: null,
        editEmployee: null,
        saving: false,
        adding: false,
        sending: false,
        showAddModal: false
    });

    const [hibobModalVisibility, setHibobModalVisibility] = useState(false);
    const [spreadsheetModalVisibility, setSpreadsheetModalVisibility] = useState(false);

    useEffect(() => {
        Promise.resolve().then(async () => {
            await fetchEmployees();
        });
    }, []);

    const fetchEmployees = async () => {
        try {
            const employees = await HttpClient.get("/api/employees");
            setState({employees});
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we couldn't list all your friends :("}},
                e.message
            );
        }
    };

    const deleteEmployee = async employeeId => {
        const {employees} = state;

        setState({deletingEmployeeId: employeeId});
        try {
            await HttpClient.delete(`/api/companies/me/employees/${employeeId}`);
            GoogleAnalytics.event("Employees", "Delete", me.companyId);
            setState({employees: employees.filter(e => e.userId !== employeeId)});
        } catch (e) {
            message.error(e.message, 3);
        }
        setState({deletingEmployeeId: null});
    };

    const updateEmployee = async employeeToUpdate => {
        const {employees} = state;

        setState({saving: true});
        try {
            await HttpClient.post(`/api/companies/me/employees/${employeeToUpdate.userId}`, employeeToUpdate);
            GoogleAnalytics.event("Employees", "Update", me.companyId);
            setState({
                employees: employees
                    .map(emp => {
                        if (emp.userId === employeeToUpdate.userId) {
                            return employeeToUpdate;
                        } else {
                            return emp;
                        }
                    })
                    .filter(emp => emp.type === "employee"),
                editEmployee: null
            });
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we couldn't update your employee :("}},
                e.message
            );
        }
        setState({saving: false});
    };

    const copySignupLinkToClipboard = () => {
        copyPathToClipboard(
            `/companies/${me.companyId}/signup?cn=${btoa(company.name)}`,
            "Employee signup link copied to clipboard successfully!"
        );
    };

    const sendInvitations = async () => {
        setState({sending: true});
        try {
            await HttpClient.post(`/api/companies/${me.companyId}/invitations/send`, {});
            GoogleAnalytics.event("Employees", "Send Invitations", me.companyId);
            message.success("New invitations were sent to all non-signed up employees", 5);
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we couldn't send the invitations :("}},
                e.message
            );
        }
        setState({sending: false});
    };

    const addEmployee = async employee => {
        if (!me.premiumAccount) {
            return EventBus.trigger("activate_account:open", {label: "Employees Add", type: "company"});
        }

        const {employees} = state;

        setState({adding: true});
        try {
            const newEmployee = await HttpClient.put("/api/companies/me/employees", employee, true);
            GoogleAnalytics.event("Employees", "Add", me.companyId);
            setState({employees: employees.concat([newEmployee])});
            form.resetFields();
        } catch (e) {
            if (e.statusCode === 400) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: e.message, hideSteps: true}, cta: {hide: true}},
                    e.message
                );
            } else {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we couldn't add your employee :("}},
                    e.message
                );
            }
        }
        setState({adding: false});
    };

    const filter = term => {
        setState({term});
    };

    const onEdit = employee => {
        setState({editEmployee: employee});
    };

    const uploadEmployeesCheck = e => {
        if (!me.premiumAccount) {
            EventBus.trigger("activate_account:open", {label: "Employees Import", type: "company"});
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const {employees, loading, deletingEmployeeId, editEmployee, saving, adding, showAddModal, sending} = state;

    const filteredEmployees = (employees || []).filter(emp => {
        return `${emp.firstName} ${emp.lastName} ${emp.email}`.toLowerCase().indexOf(state.term.toLowerCase()) >= 0;
    });

    const addEmployeesWithBob = async token => {
        const integrations = {
            people: {
                params: {
                    token: token
                },
                type: "hibob"
            }
        };
        const branches = company.branches.filter(branch => branch.branchId !== "All");
        const info = {
            ...company,
            branches: branches,
            integrations: integrations
        };
        try {
            const cmpny = await HttpClient.post("/api/companies", info);
            EventBus.trigger("company:update", cmpny);
            message.success("Your information changes were saved.", 3);
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setHibobModalVisibility(false);
    };

    const hibobIntegrationExist = () => {
        return (
            company &&
            company.integrations &&
            company.integrations.people &&
            company.integrations.people.type === "hibob"
        );
    };

    const importMenu = (
        <Menu>
            <Menu.Item onClick={() => setHibobModalVisibility(true)}>
                <div style={{display: "flex", justifyContent: "flex-start"}}>
                    {hibobIntegrationExist() ? (
                        <CheckCircleOutlined
                            style={{
                                width: "20px",
                                height: "20px",
                                marginTop: "5px",
                                marginLeft: "-5px",
                                color: "#ED7FA6"
                            }}
                        />
                    ) : null}
                    Integrate from HiBob
                </div>
            </Menu.Item>
            <Menu.Item onClick={() => setSpreadsheetModalVisibility(true)}>Upload spreadsheet</Menu.Item>
        </Menu>
    );

    return (
        <div style={{display: "flex", justifyContent: "center", marginTop: 0}}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: 10,
                    paddingRight: 10,
                    maxWidth: 1400,
                    flex: 1,
                    minWidth: 360
                }}>
                <PageTitleHeader showBack={false}>
                    {me.type === "company" ? "My employees" : "My friends' plan"}
                </PageTitleHeader>
                <CompanyEmployeesHibobModal
                    visible={hibobModalVisibility}
                    onClose={() => setHibobModalVisibility(false)}
                    onAdd={addEmployeesWithBob}
                />
                <CompanyEmployeesSpreadsheetModal
                    visible={spreadsheetModalVisibility}
                    onClose={() => setSpreadsheetModalVisibility(false)}
                    fetchEmployees={fetchEmployees}
                    uploadEmployeesCheck={uploadEmployeesCheck}
                />
                <CompanyEmployeeAddForm
                    visible={showAddModal}
                    onClose={() => setState({showAddModal: false})}
                    onAdd={addEmployee}
                    adding={adding}
                    form={form}
                />
                <CompanyEmployeeEdit
                    onClose={() => setState({editEmployee: null})}
                    visible={editEmployee !== null}
                    onSave={async employee => await updateEmployee(employee)}
                    saving={saving}
                    employee={editEmployee}
                />
                <div style={{marginTop: 30, marginBottom: 20, display: "flex"}}>
                    <div style={{flex: 1, display: "flex", flexDirection: "row"}}>
                        <SearchInput
                            placeholder={me.type === "company" ? "Search employee" : "Search colleague"}
                            style={{maxWidth: 400, height: 40}}
                            onChange={evt => filter(evt.target.value)}
                        />
                        {me.type === "company" ? (
                            <Tooltip title="Send Invitation to join the platform email to all non-signed up employees">
                                <StyledButton
                                    loading={sending}
                                    className="wb-employee-action-button"
                                    style={{width: 185, marginLeft: 20, backgroundColor: "white"}}
                                    onClick={sendInvitations}>
                                    <SendOutlined style={{fontSize: 19, paddingTop: 2, marginRight: 3}} /> Send
                                    invitations
                                </StyledButton>
                            </Tooltip>
                        ) : null}
                    </div>
                    {me.type === "company" ? (
                        <div className="wb-flex-desktop-only" style={{alignItems: "center"}}>
                            <StyledButton
                                className="wb-employee-action-button"
                                style={{width: 250, backgroundColor: "white"}}
                                onClick={copySignupLinkToClipboard}>
                                <LinkOutlined style={{fontSize: 19, paddingTop: 2, marginRight: 3}} /> Copy employee
                                signup link
                            </StyledButton>
                            <StyledButton
                                className="wb-employee-action-button"
                                style={{width: 125, backgroundColor: "white", marginRight: "10px"}}
                                onClick={() => setState({showAddModal: true})}>
                                <PlusCircleOutlined style={{fontSize: 19, paddingTop: 2, marginRight: 3}} /> Add single
                            </StyledButton>
                            <Dropdown overlay={importMenu}>
                                <StyledButton
                                    style={{
                                        height: "40px",
                                        width: "126px",
                                        borderRadius: "5px",
                                        backgroundColor: "#ED7FA6 !important",
                                        color: "#FFF"
                                    }}>
                                    <CloudUploadOutlined style={{fontSize: 19, paddingTop: 4, marginRight: 3}} /> Import
                                    list
                                </StyledButton>
                            </Dropdown>
                        </div>
                    ) : null}
                </div>
                <RoundedTable
                    rowKey="email"
                    scroll={{x: "max-content"}}
                    loading={employees === null}
                    pagination={false}
                    dataSource={filteredEmployees}
                    rowClassName={() => "wb-employee-row"}
                    columns={columns(
                        me,
                        company,
                        async employeeId => await deleteEmployee(employeeId),
                        deletingEmployeeId,
                        onEdit
                    )}
                />
            </div>
        </div>
    );
};
