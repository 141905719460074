import React, {useCallback, useEffect, useMemo, useState} from "react";
import {groupBy, isEmpty} from "lodash";
import moment from "moment";
import {Dropdown, Menu, message, Modal} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {InvoiceStatus, InvoiceStatusToTextAndColor, ManualInvoiceStatus} from "./InvoiceStatusLabel";
import {PageTitleLabel, InvoiceLabel, AreYouSureModal, StyledButton, PageLoader, SquareButton} from "../../components";
import {HttpClient} from "../../http/HttpClient";
import {getHebrewMonthName} from "../../utils/DateFormat";
import "./admin-vendors.css";

export const OldInvoicesModal = ({modalData, visible, onClose, updateMonthlyInvoice}) => {
    const {invoices = [], provider = {}} = modalData ?? {};

    const [invoiceToRemove, setInvoiceToRemove] = useState(null);
    const [shouldRefreshDataOnClose, setShouldRefreshDataOnClose] = useState(false);
    const [statusChange, setStatusChange] = useState(false);

    useEffect(() => {
        if (visible) {
            setShouldRefreshDataOnClose(false);
        }
    }, [visible]);

    const monthsFromProviderCreated = useMemo(() => {
        if (!provider?.createdAt) {
            return [];
        }

        const dateStart = moment(provider.createdAt);
        const dateEnd = moment().subtract(1, "month");
        const months = [];

        while (dateEnd > dateStart || dateStart.format("M") === dateEnd.format("M")) {
            months.push(moment(dateStart));
            dateStart.add(1, "month");
        }

        return months;
    }, [provider]);

    const groupedInvoices = useMemo(() => groupBy(invoices, ({month}) => month), [invoices]);

    const onRemoveInvoice = useCallback(
        async invoice => {
            try {
                await HttpClient.post(
                    `/api/providers/${provider.providerId}/monthlyInvoice/${invoice.month}/remove/${invoice.imageId}`
                );
                setShouldRefreshDataOnClose(true);
                updateMonthlyInvoice(invoices.filter(({imageId}) => imageId !== invoice.imageId));
            } catch (e) {
                message.error("מחיקת החשבונית נכשלה, אנא נסה שנית מאוחר יותר", 5);
            }
        },
        [invoices, provider]
    );

    const getMonthInvoiceStatus = useCallback(monthlyInvoices => {
        const needApproval = monthlyInvoices.some(invoiceObj => isEmpty(invoiceObj.status));
        if (needApproval) {
            return InvoiceStatusToTextAndColor[InvoiceStatus.Waiting][0];
        }
        return InvoiceStatusToTextAndColor[monthlyInvoices[0].status][0];
    }, []);

    const onStatusChange = useCallback(
        async (month, status) => {
            setStatusChange(true);
            try {
                const {invoices} = await HttpClient.post(
                    `/admin/api/providers/${provider.providerId}/monthlyInvoice/${month}/changeStatus/${status}`
                );
                setShouldRefreshDataOnClose(true);
                updateMonthlyInvoice(invoices);
            } catch (e) {
                message.error("עדכון סטטוס החשבוניות נכשל, אנא נסה שנית מאוחר יותר", 5);
            }
            setStatusChange(false);
        },
        [provider]
    );

    return (
        <Modal
            className="invoices-modal"
            open={visible}
            closable={true}
            onCancel={() => onClose(shouldRefreshDataOnClose)}
            destroyOnClose={true}
            footer={null}
            width={700}
            title={null}>
            <AreYouSureModal
                visible={!!invoiceToRemove}
                onClose={() => setInvoiceToRemove(null)}
                title="בטוח שאתה רוצה למחוק את הקובץ?"
                saveStyle={{color: "white", backgroundColor: "#F06060"}}
                onYes={async () => {
                    await onRemoveInvoice(invoiceToRemove);
                    setInvoiceToRemove(null);
                }}
                onNo={() => setInvoiceToRemove(null)}
            />
            <PageTitleLabel className="invoices-modal-header">
                {`לוג חשבוניות | ${provider.businessName}`}
            </PageTitleLabel>
            <div className="invoices-modal-body">
                {monthsFromProviderCreated.map(month => (
                    <div key={month.format("MM.YY")} className="invoices-modal-month-line">
                        <div style={{display: "flex", direction: "row"}}>
                            <span className="invoices-modal-month-title">
                                {getHebrewMonthName(month)} {month.format("YY")}
                            </span>
                            <span className="invoices-modal-invoices-container">
                                {groupedInvoices[month.format("MMMM-YY")]?.map(invoice => (
                                    <InvoiceLabel
                                        key={invoice.imageId}
                                        invoice={invoice}
                                        onRemoveInvoice={() => setInvoiceToRemove(invoice)}
                                    />
                                ))}
                            </span>
                        </div>
                        {!isEmpty(groupedInvoices[month.format("MMMM-YY")]) ? (
                            <Dropdown
                                overlay={
                                    <Menu className="invoices-modal-change-status-dropdown">
                                        {Object.values(ManualInvoiceStatus).map(status => (
                                            <Menu.Item
                                                key={status}
                                                onClick={() => onStatusChange(month.format("MMMM-YY"), status)}>
                                                {InvoiceStatusToTextAndColor[status][0]}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                trigger={["click"]}>
                                <StyledButton
                                    style={{
                                        height: "40px",
                                        width: "150px",
                                        borderRadius: "5px",
                                        marginLeft: "10px",
                                        border: "1px solid var(--secondary-color)",
                                        backgroundColor: "white",
                                        color: "var(--secondary-color)"
                                    }}>
                                    {statusChange ? (
                                        <PageLoader />
                                    ) : (
                                        <div>{getMonthInvoiceStatus(groupedInvoices[month.format("MMMM-YY")])}</div>
                                    )}
                                    <DownOutlined style={{height: 13, paddingTop: 1, paddingRight: 7}} />
                                </StyledButton>
                            </Dropdown>
                        ) : null}
                    </div>
                ))}
            </div>
        </Modal>
    );
};
