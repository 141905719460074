import React, {useContext, useEffect, useState} from "react";
import {Dropdown, Menu, message} from "antd";
import isMobileDevice from "is-mobile";
import {DownIcon} from "./icons";
import {truncate, find, uniqBy} from "lodash";
import {withRouter} from "react-router";
import {parse as qsParse} from "query-string";
import {EventBus} from "./bus/EventBus";
import {AppContext} from "./AppContext";
import {findBranchById, NewCompanyBranchModal} from "./company/CompanyBranches";
import {replaceKeyValuePairsFromSearch} from "./AppHistoryAdapter";
import {COMPANY_ALL_BRANCHES, COMPANY_ADD_BRANCH} from "./AppTopBar";
import {HttpClient} from "./http/HttpClient";

const AppBranchesSelect = ({history, branches, className, style}) => {
    const {company, me} = useContext(AppContext);
    const [selectedBranchId, setSelectedBranchId] = useState(find(branches, branch => branch.isHQ).branchId);
    const [showNewBranchModal, setShowNewBranchModal] = useState(false);
    const [admins, setAdmins] = useState([me]);

    const {branchId} = qsParse(window.location.search);

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const [admins, employees] = await Promise.all([
                    HttpClient.get("/api/companies/me/admins"),
                    HttpClient.get("/api/employees")
                ]);
                setAdmins(uniqBy(admins.concat(employees), a => a.email));
            } catch (e) {
                message.error("Something went wrong, please try again later.", 5);
            }
        });
    }, []);

    const onBranchSelect = branchId => {
        setSelectedBranchId(branchId);
        EventBus.trigger("branch:select", branchId);
    };

    const changeBranch = (branchId, withQuery = false) => {
        if (location.search && withQuery) {
            const search = replaceKeyValuePairsFromSearch({branchId});
            window.location.replace(`${location.pathname}?${search}`);
        } else {
            window.location.replace(`${location.pathname}?branchId=${branchId}`);
        }
    };

    const onBranchAdd = async newBranchInfo => {
        if (newBranchInfo) {
            const newBranches = branches
                .filter(branch => branch.branchId !== COMPANY_ADD_BRANCH && branch.branchId !== COMPANY_ALL_BRANCHES)
                .concat(newBranchInfo);
            try {
                const cmpny = await HttpClient.post("/api/companies", {...company, branches: newBranches});
                EventBus.trigger("company:update", cmpny);
                message.success("Sites were updated.", 3);
                setShowNewBranchModal(false);
            } catch (e) {
                message.error("Something went wrong, please try again later.", 5);
            }
        } else {
            setShowNewBranchModal(false);
        }
    };

    if (!branchId && !me.isCompanyMaster) {
        changeBranch(me.branch.branchId, true);
    } else if (!branchId) {
        changeBranch(branches[0].branchId, true);
    }

    if (
        selectedBranchId === COMPANY_ALL_BRANCHES &&
        !find(branches, branch => branch.branchId === COMPANY_ALL_BRANCHES)
    ) {
        changeBranch(find(branches, branch => branch.isHQ).branchId);
    }

    if (branchId !== selectedBranchId && branches.find(b => b.branchId === branchId)) {
        onBranchSelect(branchId, true);
    }

    if (!me.isCompanyMaster) {
        return null;
    }

    const overlay = (
        <Menu
            className="wb-app-menu"
            style={{border: "1px solid #E8EDF5"}}
            onClick={({key}) => {
                if (key === COMPANY_ADD_BRANCH) {
                    setShowNewBranchModal(true);
                } else {
                    onBranchSelect(key);
                    changeBranch(key);
                }
            }}>
            {branches.map(({branchId, name, isHQ, style, icon, iconStyle}) => {
                return (
                    <Menu.Item
                        key={branchId}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            color: "var(--secondary-color)",
                            fontSize: 14,
                            ...style
                        }}>
                        {icon ? (
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                <div style={{marginLeft: "-2px", marginRight: "5px", ...iconStyle}}>{icon}</div>
                                {name}
                                {isHQ ? " (HQ)" : ""}
                            </div>
                        ) : (
                            `${name}${isHQ ? " (HQ)" : ""}`
                        )}
                    </Menu.Item>
                );
            })}
        </Menu>
    );

    return (
        <>
            <div className={className} style={{maxWidth: 150, ...style}}>
                <Dropdown trigger={isMobileDevice() ? ["click"] : ["hover"]} placement="bottomLeft" overlay={overlay}>
                    <div style={{fontSize: 14, fontWeight: 300, color: "var(--secondary-color)", cursor: "pointer"}}>
                        {truncate(findBranchById(company, selectedBranchId, branches[0]).name, {
                            separator: " ",
                            length: 20
                        })}{" "}
                        <DownIcon
                            style={{
                                paddingLeft: 6,
                                fontSize: 12,
                                color: "rgba(17, 52, 76, 1)",
                                width: 14,
                                height: 14,
                                paddingTop: 5
                            }}
                        />
                    </div>
                </Dropdown>
            </div>
            <NewCompanyBranchModal
                branches={branches}
                visible={showNewBranchModal}
                onClose={newBranchInfo => onBranchAdd(newBranchInfo)}
                admins={admins}
            />
        </>
    );
};

export default withRouter(AppBranchesSelect);
