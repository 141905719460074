import {Dropdown, Menu} from "antd";
import {DownOutlined} from "@ant-design/icons";
import React, {useContext} from "react";
import {AppContext} from "../../AppContext";
import {Link} from "react-router-dom";
import {EXCenterProfileAvatar} from "./EXCenterProfileAvatar";
import {UserBranch} from "../branches/UserBranch";
import {openWindow} from "../../http/WindowOpener";
import {EXCenterContext} from "../EXCenterContext";
import {ContactUsLink} from "../utils/ContactUsLink";

export const EXCenterProfileDropdown = ({style}) => {
    const {me, company} = useContext(AppContext);
    const {theme} = useContext(EXCenterContext);

    const userBranch = new UserBranch(me, company);

    const employeeMenu = (
        <Menu>
            {userBranch.canSeeOtherBranches() ? (
                <Menu.Item>
                    <Link to={"/center/branches/direct"}>
                        <span style={{color: theme.textColor}}>Switch site</span>
                    </Link>
                </Menu.Item>
            ) : null}
            <Menu.Item>
                <Link to={"/center/profile/bookings"}>
                    <span style={{color: theme.textColor}}>My bookings</span>
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to={"/center/profile/info"}>
                    <span style={{color: theme.textColor}}>Personal info</span>
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to={"/center/profile/settings"}>
                    <span style={{color: theme.textColor}}>Settings</span>
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to={"/center/profile/wishes"}>
                    <span style={{color: theme.textColor}}>Birthday wishes</span>
                </Link>
            </Menu.Item>
            <Menu.Item>
                <ContactUsLink company={company}>
                    <span style={{color: theme.textColor}}>Contact us</span>
                </ContactUsLink>
            </Menu.Item>
            <Menu.Item>
                <span style={{color: theme.textColor}} onClick={() => openWindow("/api/logout", "_self")}>
                    Logout
                </span>
            </Menu.Item>
        </Menu>
    );

    const Hello = ({children}) => (
        <span style={{fontWeight: 300, color: theme.textColor, cursor: children ? "pointer" : "default"}}>
            Hello, {me.firstName || me.companyName} {children}
        </span>
    );

    return (
        <div
            className="wb-bs-visible-only"
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                minWidth: 150,
                width: "100%",
                ...style
            }}>
            <EXCenterProfileAvatar />
            {me.type === "employee" ? (
                <Dropdown style={{cursor: "pointer"}} overlay={employeeMenu}>
                    <div>
                        <Hello>
                            <DownOutlined
                                style={{color: theme.textColor, cursor: "pointer", fontSize: 8, fontWeight: 300}}
                            />
                        </Hello>
                    </div>
                </Dropdown>
            ) : (
                <Hello />
            )}
        </div>
    );
};
