import {Select} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../AppContext";
import {useResizeHandler} from "../utils/useResizeHandler";

const {Option} = Select;

export const hasBranches = (me, company) => {
    return company && Array.isArray(company.branches) && company.branches.length > 0;
};

const isSmallScreen = () => window.innerWidth < 600;

export const CompanyBranchSelect = ({branches, value, onChange, mode}) => {
    const {me} = useContext(AppContext);
    const [smallScreen, setSmallScreen] = useState(isSmallScreen());

    useEffect(() => {
        return useResizeHandler(() => setSmallScreen(isSmallScreen()));
    }, []);

    if (me.type === "company" && !me.isCompanyMaster) {
        branches = [me.branch];
    }

    const onSelect = idx => {
        if (typeof mode === "undefined") {
            onChange(branches[idx]);
        } else {
            onChange(Array.isArray(value) ? value.concat(branches[idx]) : [branches[idx]]);
        }
    };

    const onDeselect = idx => {
        if (typeof mode === "undefined") {
            return;
        }

        const b = branches[idx];
        onChange(value.filter(({branchId}) => b.branchId !== branchId));
    };

    const currentIndexSelected = () => {
        if (!value) {
            return typeof mode === "undefined" ? null : [];
        }

        if (typeof mode === "undefined") {
            return branches.findIndex(({branchId}) => branchId === value.branchId);
        } else {
            return branches.reduce((selectedArr, {branchId}, idx) => {
                if (value.some(b => b.branchId === branchId)) {
                    return selectedArr.concat(idx);
                } else {
                    return selectedArr;
                }
            }, []);
        }
    };

    return (
        <Select
            placeholder="Site"
            style={{minWidth: 150}}
            value={currentIndexSelected()}
            maxTagTextLength={20}
            maxTagCount={smallScreen ? 2 : void 0}
            mode={mode}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={() => onChange(null)}
            allowClear={true}>
            {branches.map(({name}, idx) => (
                <Option key={name} value={idx}>
                    {name}
                </Option>
            ))}
        </Select>
    );
};
