import {shippingProvidersOptions} from "../admin/upload/consts.jsx";
import moment from "moment/moment";
import {ContactPhone} from "../support/ContactPhone.jsx";
import {StringBuilder} from "../AppUtils.js";
import common from "@well-b/well-b-shared";

export const getOrderContactInfo = eventInfo => {
    const companyContactInfo = {
        phone: eventInfo?.companyPhone,
        email: eventInfo?.companyEmail,
        name: eventInfo?.companyContact
    };

    if (!eventInfo?.extraContactInfo) {
        return [companyContactInfo];
    }

    const extraContact = {
        phone: eventInfo.extraContactInfo.contactPhone,
        name: eventInfo.extraContactInfo.contactName
    };

    if (eventInfo.extraContactInfo.contactOrder === "single") {
        return [extraContact];
    }

    return eventInfo.extraContactInfo.contactOrder === "mainly"
        ? [extraContact, companyContactInfo]
        : [companyContactInfo, extraContact];
};

export const isOrderHasShippingProvider = order =>
    Array.isArray(order?.shippingProviders) && !order?.shippingProviders?.includes(shippingProvidersOptions.regular);

export const getFirstOrderShippingProvider = (order, deliveryProviders = []) => {
    if (!isOrderHasShippingProvider(order)) {
        return null;
    }

    const [firstShippingProvider] = order.shippingProviders;
    return deliveryProviders?.find(shippingProvider => shippingProvider.deliveryProviderId === firstShippingProvider);
};

export function calculateDeliveryTime(order, minutesToSubtract = 30, referenceHour = 6, referenceMinute = 0) {
    if (!isOrderHasShippingProvider(order)) {
        return null;
    }

    const dtStartMoment = moment(order?.dtstart);

    const adjustedTime = dtStartMoment.clone().subtract(minutesToSubtract, "minutes");

    const referenceTime = dtStartMoment
        .clone()
        .startOf("day")
        .add(referenceHour, "hours")
        .add(referenceMinute, "minutes");

    if (adjustedTime.isBefore(referenceTime)) {
        return referenceTime;
    } else {
        return adjustedTime;
    }
}

export const generateAssignUserContactWhatsAppLink = systemId => {
    return ContactPhone.whatsAppLink(
        ContactPhone.phoneNumberBySystemId(systemId),
        new StringBuilder().append(",שלום").append("אני פונה בנוגע להזמנה:").append(window.location.href).toString()
    );
};

export function isOrderHasValidDeliveryInfo(order) {
    return !!order?.shippingDetails?.task_id;
}
