import React from "react";
import {Card, Input} from "antd";
import {FormConfirmButton, FormCancelButton, FormBottomBar} from "../components/form";
import moment from "moment-timezone";
import {HttpClient} from "../http/HttpClient";
import {PageTitleLabel} from "../components/PageTitle";
import {AppContext} from "../AppContext";
import {EventAcceptedModal} from "./EventAcceptedModal";
import {EventDeclinedModal} from "./EventDeclinedModal";
import {EventAlreadyAcceptedModal} from "./EventAlreadyAcceptedModal";
import {EventAlreadyConfirmedByMeModal} from "./EventAlreadyConfirmedByMeModal";
import {EventBus} from "../bus/EventBus";
import {LocationLabels} from "../data/locations";
import {EventCancelledModal} from "./EventCancelledModal";
import {AutoDirectionProvider} from "../AutoDirectionProvider";
import {isAppointmentBasedProfession} from "../data/professions";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {StyledButton} from "../components/StyledButton";
import {ContactPhone} from "../support/ContactPhone";
import {WhatsAppOutlined} from "@ant-design/icons";
import {FrequencyMapper} from "./FrequencyMapper";
import {DaysMapper} from "./DaysMapper";

const EventInfo = ({label, value, style}) => (
    <div style={{display: "flex", flexDirection: "column", marginBottom: 30, ...style}}>
        <label style={{color: "#8492A6", fontSize: 16, marginBottom: 10}}>{label}</label>
        <AutoDirectionProvider text={value} style={{width: "100%", textAlign: "left"}}>
            <span style={{color: "var(--secondary-color)", fontSize: 20, fontWeight: 700, width: "100%"}}>{value}</span>
        </AutoDirectionProvider>
    </div>
);

export class EventProposal extends React.Component {
    static contextType = AppContext;

    state = {
        acceptState: "disabled",
        declineState: "disabled",
        eventInfo: null,
        answered: false,
        alreadyConfirmed: false,
        doesNotExist: false,
        cancelled: false
    };

    async componentDidMount() {
        const eventId = this.extractEventId();

        try {
            const eventInfo = await HttpClient.get(`/api/events/${eventId}/proposal`);
            this.setState({eventInfo});

            if (eventInfo.status === "cancelled") {
                this.setState({cancelled: true});
            } else if (eventInfo.status !== "pending") {
                this.setState({alreadyConfirmed: true});
            } else {
                this.enabledActionButtons();
            }
        } catch (e) {
            if (e.message.toLowerCase().indexOf("does not exist") >= 0) {
                this.setState({doesNotExist: true});
            } else {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we didn't manage to present the session details :("}},
                    e.message
                );
            }
        }
    }

    extractEventId() {
        const {match} = this.props;
        const {eventId} = match.params;
        return eventId;
    }

    enabledActionButtons() {
        this.setState({acceptState: "normal", declineState: "normal"});
    }

    async accept() {
        this.setState({acceptState: "loading", declineState: "disabled"});

        const eventId = this.extractEventId();

        try {
            await HttpClient.post(`/api/events/${eventId}/accept`, null, true);
            GoogleAnalytics.event("Session Proposal", "Accept", eventId);
            this.thankYou("Accepted");
        } catch (e) {
            this.disableActionButtons();
            if (e.message.toLowerCase().indexOf("not pending") >= 0) {
                this.setState({alreadyConfirmed: true});
            } else if (e.message.toLowerCase().indexOf("does not exist") >= 0) {
                this.setState({doesNotExist: true});
            } else {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we didn't manage to accept this proposal :("}},
                    e.message
                );
            }
        }
    }

    async decline() {
        this.setState({acceptState: "disabled", declineState: "loading"});

        const eventId = this.extractEventId();

        try {
            await HttpClient.post(`/api/events/${eventId}/decline`, null, true);
            GoogleAnalytics.event("Session Proposal", "Decline", eventId);
            this.thankYou("Declined");
        } catch (e) {
            this.disableActionButtons();
            if (e.message.toLowerCase().indexOf("not pending") >= 0) {
                this.setState({alreadyConfirmed: true});
            } else if (e.message.toLowerCase().indexOf("does not exist") >= 0) {
                this.setState({doesNotExist: true});
            } else {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we didn't manage to decline this proposal :("}},
                    e.message
                );
            }
        }
    }

    thankYou(answer) {
        this.disableActionButtons();
        this.setState({answered: answer});
    }

    disableActionButtons() {
        this.setState({acceptState: "disabled", declineState: "disabled"});
    }

    render() {
        let alreadyConfirmedByMe = false;

        let {acceptState, declineState, eventInfo, answered, alreadyConfirmed, doesNotExist, cancelled} = this.state;

        const {history} = this.props;

        const {isMobile, me} = this.context;

        if (cancelled) {
            return <EventCancelledModal visible={true} history={history} />;
        }

        if (doesNotExist) {
            return <EventAlreadyConfirmedByMeModal visible={true} history={history} />;
        }

        if (!eventInfo) return null;

        const {
            sessionName,
            serviceName,
            productName,
            offeredPrice,
            priceType,
            message,
            dtstart,
            byweekday,
            duration,
            location,
            appointmentBased,
            address,
            companySize
        } = eventInfo;

        const repeatsText = FrequencyMapper.toHumanText(eventInfo);
        const oneTime = FrequencyMapper.oneTime(eventInfo);

        if (alreadyConfirmed && eventInfo.providerId === me.userId) {
            answered = eventInfo.status;
            alreadyConfirmed = false;
            alreadyConfirmedByMe = true;
        } else if (!me.userId) {
            alreadyConfirmed = false;
        }

        const time = appointmentBased
            ? null
            : `${moment(dtstart).format("HH:mm")}-${moment(dtstart).add(duration, "minutes").format("HH:mm")}`;

        const startDate = moment(dtstart).day(DaysMapper.mapKeyToDay(byweekday[0])).format("MMM Do, YYYY");

        return (
            <div style={{display: "flex", justifyContent: "center", marginBottom: 150}}>
                <EventAcceptedModal visible={answered === "Accepted"} history={history} />
                <EventDeclinedModal visible={answered === "Declined"} history={history} event={eventInfo} />
                <EventAlreadyAcceptedModal visible={alreadyConfirmed} history={history} />
                <EventAlreadyConfirmedByMeModal visible={alreadyConfirmedByMe} history={history} answer={answered} />
                <div
                    style={{
                        maxWidth: 600,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginLeft: 10,
                        marginRight: 10
                    }}>
                    <PageTitleLabel style={{display: "flex", flexWrap: "wrap", alignItems: "center", marginBottom: 30}}>
                        {isMobile ? "New booking request!" : "You've got a new company booking request!"}
                    </PageTitleLabel>
                    <Card style={{border: "1px solid #E8EDF5", backgroundColor: "#FCFDFE", borderRadius: 10}}>
                        <EventInfo label="Required service:" value={productName || serviceName || sessionName} />
                        {typeof offeredPrice === "number" ? (
                            <EventInfo
                                label="Your price:"
                                value={
                                    offeredPrice === 0
                                        ? "FREE"
                                        : `₪${offeredPrice}${priceType ? ` / per ${priceType}` : ""}`
                                }
                            />
                        ) : null}
                        <EventInfo
                            label="Time & date:"
                            value={`${repeatsText}${time ? ` ${time}` : ""} | ${
                                oneTime ? "" : "starting "
                            }${startDate}`}
                        />
                        <EventInfo
                            label="Where:"
                            value={
                                location === LocationLabels.ONSITE || location === LocationLabels.OFFSITE
                                    ? address
                                    : "Online session"
                            }
                        />
                        <EventInfo
                            label="Company size:"
                            value={`${companySize} employees`}
                            style={{marginBottom: message ? 30 : 0}}
                        />
                        {message ? <EventInfo label="Notes:" value={message} style={{marginBottom: 0}} /> : null}
                    </Card>
                    <Input.Group style={{display: "flex", justifyContent: "center", marginTop: 30}}>
                        <FormCancelButton
                            disabled={declineState === "disabled"}
                            loading={declineState === "loading"}
                            style={{maxWidth: 250, flex: 1, marginRight: 20}}
                            onClick={async () => await this.decline()}>
                            Decline
                        </FormCancelButton>
                        <FormConfirmButton
                            disabled={acceptState === "disabled"}
                            loading={acceptState === "loading"}
                            style={{maxWidth: 250, flex: 1}}
                            onClick={async () => await this.accept()}>
                            Accept
                        </FormConfirmButton>
                    </Input.Group>
                    <div
                        style={{
                            fontSize: 16,
                            color: "var(--secondary-color)",
                            marginTop: 30,
                            display: "flex",
                            justifyContent: "center"
                        }}>
                        <strong style={{marginRight: 4}}>Need help?</strong> give us a call or chat with us:
                    </div>
                    <div
                        style={{
                            fontSize: 14,
                            color: "var(--secondary-color)",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 5
                        }}>
                        {isMobile ? (
                            <a href={`tel:+${ContactPhone.phoneNumber()}`}>{ContactPhone.prettyPhoneNumber()}</a>
                        ) : (
                            ContactPhone.prettyPhoneNumber()
                        )}
                    </div>
                </div>
                <FormBottomBar backgroundColor="#055E53" color="white">
                    <div
                        className="wb-justify-bottom-bar"
                        style={{display: "flex", width: "100%", alignItems: "center"}}>
                        <label className="wb-block-desktop-only" style={{marginRight: 5, fontSize: 16, color: "white"}}>
                            Too shy to talk? :)
                        </label>
                        <StyledButton
                            onClick={() => ContactPhone.openWhatsApp()}
                            className="wb-form-bottom-btn"
                            style={{
                                color: "#055E53",
                                border: "1px solid #055E53",
                                backgroundColor: "white",
                                borderRadius: 100
                            }}>
                            <WhatsAppOutlined style={{paddingTop: 3, fontSize: 18}} />
                            Let's chat
                        </StyledButton>
                    </div>
                </FormBottomBar>
            </div>
        );
    }
}
