import React, {useCallback, useContext, useEffect, useState} from "react";
import {ConfigProvider} from "antd";
import {FormModal} from "../../components";
import {wishesOptions} from "./consts";
import {AppContext} from "../../AppContext";

export const OrderEmployeSingleInfoModal = ({content, visible, onSave, onClose}) => {
    const {company} = useContext(AppContext);
    const [wishesTemplate, setWishesTemplate] = useState(null);

    const fixLineBreak = useCallback(
        text =>
            text
                .split(/(\r\n|\n|\r)/)
                .filter(substring => substring.trim() !== "")
                .join("\n"),
        []
    );

    return (
        <ConfigProvider direction="rtl">
            <FormModal
                content={{...content, wishes: wishesTemplate}}
                visible={visible}
                onSave={val => {
                    if (!val.wishes) {
                        onSave(val);
                    } else {
                        const substringWishes = val.wishes.substring(0, 100);
                        onSave({...val, wishes: fixLineBreak(substringWishes)});
                    }
                }}
                onUpdate={(_, changedField) => {
                    if (changedField.wishes) {
                        const substringWishes = changedField.wishes;
                        const correctedStr = fixLineBreak(substringWishes);
                        setWishesTemplate(correctedStr);
                    }

                    if (changedField.template) {
                        setWishesTemplate(fixLineBreak(changedField.template));
                    }
                }}
                onClose={onClose}
                header={"שילוח למקבל יחיד 🙋‍️"}
                subTitle={"במידה וברצונכם לשלח למקבל יחיד, אנא מלאו את הפרטים הבאים"}
                fields={[
                    {
                        name: "name",
                        label: "שם מלא של הנמען (עד 30 תווים)",
                        rules: [
                            {
                                validator: (_, value) => {
                                    if (value && value.length > 30) {
                                        return Promise.reject("אנא הזינו עד 30 תווים בשם המקבל");
                                    } else {
                                        return Promise.resolve();
                                    }
                                }
                            }
                        ]
                    },
                    {
                        name: "template",
                        label: "ברכה מוכנה מראש",
                        type: "select",
                        options: company?.customWishes ?? wishesOptions,
                        optional: true
                    },
                    {
                        name: "wishes",
                        label: "ברכה (עד 100 תווים)",
                        type: "textarea",
                        minRows: 2,
                        maxRows: 10,
                        optional: true,
                        maxLength: 100
                    }
                ]}
            />
        </ConfigProvider>
    );
};
