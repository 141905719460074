import {Tag} from "antd";
import React from "react";

export const ProviderProfession = ({profession, style}) => {
    const professions = Array.isArray(profession) ? profession : [profession];
    return (
        <div
            style={{
                height: "auto",
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                alignItems: "center",
                ...style
            }}>
            {professions.map(pro => (
                <Tag
                    color="rgba(240,96,96,0.2)"
                    style={{
                        color: "#D04949",
                        padding: "4px 11px",
                        borderRadius: 10,
                        fontSize: 14,
                        flex: 0,
                        margin: 5,
                        maxWidth: 300
                    }}>
                    {pro}
                </Tag>
            ))}
        </div>
    );
};
