import {DEFAULT_HOME_DELIVERY_PRICE, TEN_BIS_CARD_LENGTH} from "./consts";
import {BrokenYehudAddressId, FixedYehudAddressId} from "../OrderLocationInput/consts";
import {DEFAULT_DELIVERY_PRICE, ERROR_LEVEL, isAmountOutOfBoundaries} from "../MarketplaceUtils";
import {
    ActivityLocation,
    ActivityLocationSubtitles,
    GoogleMapMainAreaKey,
    GoogleMapSubAreaApiKeys,
    PaymentType,
    ProductUpgradeOptionsAmountTypeToName,
    ValidateBookingError,
    ValidateVoucherBookingError
} from "../../consts.js";
import {HttpClient} from "../../http/HttpClient";
import {NOOP} from "../../utils/NOOP";
import {getOrderPrice, getTotalBudget} from "../../utils/calculationUtils";
import {get, isFinite, isNumber} from "lodash";
import {isProductHasShippingProvider} from "../../admin/upload/utils.js";
import common from "@well-b/well-b-shared";
import {isValidEmailRegex} from "../../components/form/validators.jsx";

let deliveryAreaCache = {};

export const getDeliveryPriceForBooking = (
    locationOrLocationType,
    providerDetails = {},
    product = {},
    formValues,
    deliveryArea,
    log = NOOP
) => {
    const singleEmployeeDetails = formValues?.singleEmployeeDetails;
    const employeesExcel = formValues?.employeesExcel;

    if (!locationOrLocationType || locationOrLocationType === ActivityLocation.manualLocation) return null;

    if (locationOrLocationType === ActivityLocation.homeDelivery && !singleEmployeeDetails && !employeesExcel?.rowCount)
        return null;

    if (locationOrLocationType === ActivityLocation.homeDelivery && !singleEmployeeDetails) {
        const homeDeliveryPrice =
            product.homeDeliveryPrice ?? providerDetails.homeDeliveryPrice ?? DEFAULT_HOME_DELIVERY_PRICE;

        return formValues.employeesExcel?.rowCount
            ? {price: formValues.employeesExcel?.rowCount * homeDeliveryPrice}
            : null;
    }

    if (
        product?.address?.some(address => address?.name === locationOrLocationType) ||
        [ActivityLocationSubtitles[ActivityLocation.online]].includes(locationOrLocationType)
    ) {
        return {price: 0};
    }

    const deliveryPrice = !isNaN(Number(product?.deliveryPrice ?? providerDetails?.deliveryPrice))
        ? Number(product?.deliveryPrice ?? providerDetails?.deliveryPrice)
        : DEFAULT_DELIVERY_PRICE;

    const deliveryOptions = getMergedDeliveryOptions(providerDetails, product);

    if (!deliveryOptions?.deliveryAreas) {
        return {price: deliveryPrice};
    }

    if (!deliveryArea) {
        return {
            message: "לא הצלחנו למצוא את המיקום שצויין לכן מחיר המשלוח עלול להשתנות לאחר בחינה נוספת",
            price: deliveryPrice
        };
    }

    const areaPrice = parseFloat(deliveryArea.price);

    if (isNaN(areaPrice)) {
        log("error: area price is not not a number", {areaPrice, deliveryArea, productId: product.productId});
        return {message: "לצערנו הספק לא משלח לכתובת שציינת"};
    }

    return {price: areaPrice};
};

export const getDeliveryArea = async (addressObj, product, providerDetails, log = NOOP) => {
    const address = addressObj?.address;
    const deliveryAreaCacheKey = `${product?.productId}-${address}`;

    let deliveryArea = deliveryAreaCache[deliveryAreaCacheKey];
    if (deliveryAreaCache[deliveryAreaCacheKey]) return deliveryArea;

    const deliveryOptions = getMergedDeliveryOptions(providerDetails, product);

    if (
        !address ||
        !deliveryOptions?.deliveryAreas ||
        [
            ActivityLocationSubtitles[ActivityLocation.online],
            ActivityLocation.manualLocation,
            ActivityLocation.homeDelivery
        ].includes(address) ||
        product?.address?.some(address => address?.name === address)
    ) {
        return null;
    }

    const googleLocation = await getGoogleLocation(addressObj);

    if (Array.isArray(googleLocation?.overrideAddressComponents) && googleLocation?.overrideAddressComponents?.length) {
        googleLocation.address_components = googleLocation.overrideAddressComponents;
    }

    // מחוז
    const mainArea = googleLocation?.address_components?.find(({types}) => types.includes(GoogleMapMainAreaKey));

    if (!mainArea || !googleLocation) {
        log("error: Failed to find address", {address: address, productId: product.productId});
        return null;
    }

    const mainAreaDeliveryInfo = deliveryOptions.deliveryAreas[mainArea.place_id];

    if (!mainAreaDeliveryInfo) {
        log("error: Main address failed", {mainArea, addressObj, productId: product.productId});
        return {};
    }

    // עיר
    const subAreas = googleLocation.address_components.filter(({types}) =>
        types.some(type => GoogleMapSubAreaApiKeys.includes(type))
    );

    const subAreaId = subAreas.find(({place_id}) => mainAreaDeliveryInfo.subAreas?.[place_id])?.place_id;

    let subAreaDeliveryInfo = mainAreaDeliveryInfo.subAreas?.[subAreaId];

    if (!subAreaDeliveryInfo && subAreas.some(({place_id}) => place_id === FixedYehudAddressId)) {
        subAreaDeliveryInfo = mainAreaDeliveryInfo.subAreas?.[BrokenYehudAddressId];
    }

    deliveryArea = subAreaDeliveryInfo ?? mainAreaDeliveryInfo;
    if (deliveryArea) {
        deliveryAreaCache[deliveryAreaCacheKey] = deliveryArea;
    }

    return deliveryArea;
};

export const validateVoucherBooking = (
    formValues,
    company,
    paymentType,
    product,
    creditCard,
    me,
    totalPrice,
    deliveryPrice,
    couponDiscountAmount
) => {
    if (company?.disabledFeatures?.includes("booking")) {
        return ValidateBookingError.NO_ACCESS;
    }

    if (!formValues?.senderName) {
        return ValidateVoucherBookingError.NO_SENDER_NAME;
    }

    if (
        formValues.singleEmployeeDetails &&
        typeof formValues?.sendOptions?.sms === "string" &&
        !common.isValidPhoneMobileNumber(formValues?.sendOptions?.sms)
    ) {
        return ValidateVoucherBookingError.NO_RECEIVER_SMS_PHONE;
    }

    if (
        formValues.singleEmployeeDetails &&
        typeof formValues?.sendOptions?.email === "string" &&
        !isValidEmailRegex(formValues?.sendOptions?.email)
    ) {
        return ValidateVoucherBookingError.NO_RECEIVER_EMAIL;
    }

    if (!Object.keys(formValues.timeOption ?? {})?.length) {
        return ValidateVoucherBookingError.NO_TIME_OPTION;
    }

    if (
        formValues?.timeOption?.type === "CUSTOM_DATE" &&
        (!formValues?.timeOption?.date || !formValues?.timeOption?.time)
    ) {
        return ValidateVoucherBookingError.NO_DATE_OR_TIME;
    }

    if (formValues.singleEmployeeDetails && !Object.keys(formValues.sendOptions ?? {})?.length) {
        return ValidateVoucherBookingError.NO_SEND_OPTION;
    }

    if (!formValues?.voucherOption?.redeemableValue) {
        return ValidateVoucherBookingError.NO_VOUCHER;
    }

    if (!formValues?.singleEmployeeDetails && !formValues?.employeesExcel) {
        return ValidateVoucherBookingError.NO_RECEIVER;
    }

    if (!paymentType) {
        return ValidateBookingError.NO_PAYMENT_TYPE;
    }

    if (paymentType === PaymentType.Card && !creditCard) {
        return ValidateBookingError.NO_PAYMENT_TYPE_CREDIT_CARD;
    }

    if (!formValues.invoicePONumber?.length && (company?.requirePONumber || me?.isMustProvidePurchaseOrderNumber)) {
        return ValidateBookingError.NO_INVOICE_PO_NUMBER;
    }

    if (
        paymentType === PaymentType.Budget &&
        !(
            getTotalBudget(me?.budget, product?.services) >=
            totalPrice + (deliveryPrice || 0) - (Number(couponDiscountAmount) || 0) - (formValues.companyDiscount || 0)
        )
    ) {
        return ValidateBookingError.BUDGET_NOT_ENOUGH;
    }
};

export const validateBooking = (
    me,
    paymentType,
    totalPrice,
    product,
    formValues,
    creditCard,
    company,
    deliveryPrice,
    couponDiscountAmount,
    companyDiscountAmount,
    originalTotalPrice = 0
) => {
    if (company?.disabledFeatures?.includes("booking")) {
        return ValidateBookingError.NO_ACCESS;
    }

    if (!formValues.date || !formValues.time) {
        return ValidateBookingError.NO_TIME_OR_DATE;
    }

    if (!paymentType) {
        return ValidateBookingError.NO_PAYMENT_TYPE;
    }

    if (paymentType === PaymentType.Card && !creditCard) {
        return ValidateBookingError.NO_PAYMENT_TYPE_CREDIT_CARD;
    }

    if (paymentType === PaymentType.TenBisCard && (formValues.tenBisCreditCard?.length || 0) < TEN_BIS_CARD_LENGTH) {
        return ValidateBookingError.NO_PAYMENT_TYPE_TEN_BIS_CARD;
    }

    if (!formValues.invoicePONumber?.length && (company?.requirePONumber || me?.isMustProvidePurchaseOrderNumber)) {
        return ValidateBookingError.NO_INVOICE_PO_NUMBER;
    }

    if (!formValues?.amount && product?.chosenPriceType !== "byModular") {
        return ValidateBookingError.NO_AMOUNT;
    }

    const currentTotalOrderPrice = getOrderPrice({
        offeredPrice: totalPrice,
        companyDiscountAmount,
        couponDiscountAmount,
        deliveryPrice: deliveryPrice ?? 0,
        tip: formValues?.tip
    });
    const hasUserEnoughMoney =
        getTotalBudget(me?.budget, product?.services) >= currentTotalOrderPrice - (originalTotalPrice ?? 0);
    if (paymentType === PaymentType.Budget && !hasUserEnoughMoney) {
        return ValidateBookingError.BUDGET_NOT_ENOUGH;
    }

    if (isNaN(deliveryPrice) || !formValues?.chosenActivityLocation) {
        return ValidateBookingError.NO_DELIVERY;
    }

    const amountOutOfBoundaries = product && formValues?.amount && isAmountOutOfBoundaries(product, formValues.amount);
    if (amountOutOfBoundaries) {
        return `יש לשים לב שכמות המוצר ${amountOutOfBoundaries < 0 ? "נמוכה" : "גבוהה"} מהמותר`;
    }

    const formError = Object.values(formValues?.errors ?? {}).find(error => error?.level === ERROR_LEVEL);

    if (formError) {
        return formError.message;
    }

    if (product?.chosenPriceType === "byModular") {
        if (product.pricingTable.byModular.minPrice > totalPrice) {
            return `מחיר ההזמנה לא יכול להיות פחות מ${product.pricingTable.byModular.minPrice}₪`;
        }

        if (formValues.modularProducts?.every(product => (product?.amount ?? product?.defaultAmount) === 0)) {
            return ValidateBookingError.NO_MODULAR_AMOUNT;
        }
    }

    if (!formValues.chosenAddress || formValues.chosenAddress === ActivityLocation.manualLocation) {
        return ValidateBookingError.NO_LOCATION;
    }

    if (
        isProductHasShippingProvider(product) &&
        formValues.chosenAddress === ActivityLocation.manualLocation &&
        !isNumber(formValues.floor)
    ) {
        return ValidateBookingError.NO_FLOOR;
    }

    if (
        formValues.upgradeOptions?.options?.some(
            option =>
                ProductUpgradeOptionsAmountTypeToName[option.type] === ProductUpgradeOptionsAmountTypeToName.byUnit &&
                !option.amount
        )
    ) {
        return ValidateBookingError.NO_AMOUNT_UPGRADE_OPTIONS;
    }
};

export const removeFloorText = originalString => originalString?.replace(/קומה\s+\d+$/, "")?.trim();

async function getGoogleLocation(address) {
    const cleanAddress = removeFloorText(address?.address);
    const addressId = address?.addressId;

    if (addressId) {
        return fetchLocation("placeId", addressId);
    } else {
        return fetchLocation("address", cleanAddress);
    }
}

const fetchLocation = async (type, id) => {
    const encodedId = encodeURIComponent(id);
    return HttpClient.safeGet(`/api/location/${type}/${encodedId}`);
};

export const getMergedDeliveryOptions = (provider = {}, product = {}) => {
    if (!provider?.deliveryOptions && !product?.deliveryOptions) return {};
    const productDeliveryOptions = get(product, "deliveryOptions", {});
    const providerDeliveryOptions = get(provider, "deliveryOptions", {});

    const relevantDeliveryAreas = isDeliveryAreasEmpty(productDeliveryOptions?.deliveryAreas)
        ? providerDeliveryOptions?.deliveryAreas
        : productDeliveryOptions?.deliveryAreas;

    return {
        ...filterNonNullEntries(providerDeliveryOptions),
        ...filterNonNullEntries(productDeliveryOptions),
        deliveryAreas: {
            ...relevantDeliveryAreas
        }
    };
};
const filterNonNullEntries = obj => Object.fromEntries(Object.entries(obj || {}).filter(([, value]) => value !== null));
const isDeliveryAreasEmpty = deliveryAreas => {
    if (!deliveryAreas) return true;

    return !Object.values(deliveryAreas).some(area => {
        const isAreaPriceValid = isFinite(area?.price);

        return (
            isAreaPriceValid ||
            Object.values(area?.subAreas || {}).some(subArea => subArea?.price !== undefined && subArea?.price !== null)
        );
    });
};

export const getUserChangedFormValues = userFormValues => {
    const {chosenAddress, modularProducts, date, time, requests, upgradeOptions, extraRequests, extraContactInfo} =
        userFormValues ?? {};

    const changesValues = [];

    if (chosenAddress !== null) {
        changesValues.push(chosenAddress);
    }

    if (modularProducts?.some(({defaultAmount, amount}) => defaultAmount !== amount)) {
        changesValues.push(modularProducts);
    }

    if (date !== null || time !== null) {
        changesValues.push(date || time);
    }

    if (requests?.length) {
        changesValues.push(requests);
    }

    if (upgradeOptions?.options?.length) {
        changesValues.push(upgradeOptions);
    }

    if (extraRequests !== null && extraRequests?.length) {
        changesValues.push(extraRequests);
    }

    if (Object.values(extraContactInfo ?? {}).length) {
        changesValues.push(extraRequests);
    }

    return changesValues;
};

export const getDeliveryPrice = async (me, formValues, provider, product) => {
    const address = {
        address: formValues?.singleEmployeeDetails?.address ?? formValues?.chosenAddress,
        addressId: formValues?.singleEmployeeDetails?.addressId ?? formValues?.addressId
    };

    const deliveryArea = await getDeliveryArea(address, product, provider);

    if (product) {
        product.deliveryPrice = product.deliveryPrice ?? provider.deliveryPrice;
    }

    return getDeliveryPriceForBooking(formValues?.chosenAddress, provider, product, formValues, deliveryArea);
};
