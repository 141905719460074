import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Cascader, ConfigProvider, Form, Modal, Tag, TreeSelect} from "antd";
import {SquareButton, StyledButton} from "../../components";
import {AdminProductSearchTags} from "../upload/AdminProductSearchTags";

export const AdminEditTagsModal = ({visible, onClose, onCancel, onEditProduct, editProductSearchTags, productId}) => {
    const [productTags, setProductTags] = useState([]);

    useEffect(() => {
        setProductTags(editProductSearchTags);
    }, [editProductSearchTags]);

    const onFinish = useCallback(() => {
        onEditProduct(productTags);
        onClose();
    }, [productTags]);

    return (
        <Modal
            centered={true}
            width={800}
            className="admin-edit-tags-modal"
            destroyOnClose={true}
            open={visible}
            onCancel={onClose}
            footer={null}
            maskClosable={false}
            title={null}
            closable={true}>
            <ConfigProvider direction="rtl">
                <AdminProductSearchTags
                    productTags={productTags}
                    setProductTags={setProductTags}
                    productId={productId}
                />
            </ConfigProvider>
            <div className="admin-edit-tags-modal-buttons">
                <SquareButton onClick={onCancel}>ביטול</SquareButton>
                <SquareButton onClick={onFinish}>שמור תגיות</SquareButton>
            </div>
        </Modal>
    );
};
