import React from "react";
import moment from "moment";
import {Dropdown} from "antd";
import {EllipsisOutlined} from "@ant-design/icons";
import {ConsumptionOccasionTypeToName} from "../../happinessCalculator/consts";
import {momentHebrewFormat} from "../../utils/DateFormat";

export const getColumns = (onEdit, onDelete) => [
    {
        title: "מזהה",
        dataIndex: "occasionId"
    },
    {
        title: "שם",
        dataIndex: "name"
    },
    {
        title: "אייקון",
        dataIndex: "icon"
    },
    {
        title: "סוג אירוע",
        dataIndex: "type",
        render(type) {
            return ConsumptionOccasionTypeToName[type];
        }
    },
    {
        title: "תאריך התחלה",
        dataIndex: "startDate",
        render(startDate, {isRecurringEvent}) {
            if (isRecurringEvent) {
                return startDate ? momentHebrewFormat(startDate, "D MMMM") : null;
            }

            return startDate ? moment(startDate).format("DD/MM/YYYY") : null;
        }
    },
    {
        title: "תאריך סוף",
        dataIndex: "endDate",
        render(endDate, {isRecurringEvent}) {
            if (isRecurringEvent) {
                return endDate ? momentHebrewFormat(endDate, "D MMMM") : null;
            }

            return endDate ? moment(endDate).format("DD/MM/YYYY") : null;
        }
    },
    {
        key: "action",
        render(_, occasion) {
            const menu = {
                items: [
                    {
                        label: "ערוך",
                        key: "edit",
                        onClick: () => onEdit(occasion)
                    },
                    {
                        label: "מחק אירוע",
                        key: "delete",
                        danger: true,
                        onClick: () => onDelete(occasion.occasionId)
                    }
                ]
            };

            return (
                <div className="admin-orders-actions-cell">
                    <Dropdown menu={menu} trigger={["click"]}>
                        <EllipsisOutlined className="admin-orders-order-action-button" />
                    </Dropdown>
                </div>
            );
        }
    }
];
