import React from "react";

export const AppPrivacy = () => (
    <iframe
        src="https://www.well-b.biz/privacy-policy"
        frameBorder="0"
        allowFullScreen="false"
        allowTransparency="true"
        width="100%"
        height="100%"
        marginHeight="0"
        marginWidth="0"
        style={{position: "absolute", left: 0, top: 0, width: "100%", height: "100%"}}
    />
);
