import React from "react";
import {ConfigProvider} from "antd";
import {Devices} from "../../components/ResponsiveView/consts.js";
import {ResponsiveView} from "../../components/index.jsx";
import "./side-by-side-layout.css";

export const SideBySideLayout = ({children, srcImage}) => {
    return (
        <div className="side-by-side-layout-container">
            <ConfigProvider direction="rtl">
                <ResponsiveView devices={[Devices.desktop]}>
                    <img loading="lazy" className="side-by-side-layout-image" src={srcImage} alt="layout-image" />

                    <div className="side-by-side-layout-content-container">{children}</div>
                </ResponsiveView>

                <ResponsiveView devices={[Devices.mobile]}>
                    <div className="side-by-side-layout-content-container">{children}</div>
                </ResponsiveView>
            </ConfigProvider>
        </div>
    );
};
