import React, {useCallback, useMemo, useState} from "react";
import {FormModal} from "../../components/index.jsx";
import {ConfigProvider, message} from "antd";
import "./change-order-delivery-shipping-modal.css";
import {StringBuilder} from "../../AppUtils.js";
import {getFirstOrderShippingProvider} from "../utils.jsx";
import {HttpClient} from "../../http/HttpClient.jsx";
import {keyBy} from "lodash";
import {useRequest} from "../../utils/hooks.jsx";
import classnames from "classnames";

export const ChangeOrderDeliveryShippingModal = ({visible, deliveryProviders = [], onClose, onSave, orderId}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [finalStatus, setFinalStatus] = useState(false);

    const [order] = useRequest(
        `/api/events/${orderId}/orderInfo`,
        "POST",
        {
            fields: {
                orders: ["eventId", "shippingProviders"]
            }
        },
        [orderId],
        !!orderId,
        null,
        {}
    );

    const deliveryProviderById = useMemo(
        () => keyBy(deliveryProviders, deliveryProvider => deliveryProvider.deliveryProviderId),
        [deliveryProviders]
    );

    const onCloseModal = useCallback(() => {
        setFinalStatus(null);
        setIsLoading(false);
        onClose();
    }, [onClose]);

    const onFinish = useCallback(
        async deliveryOptions => {
            setIsLoading(true);

            const res = await HttpClient.safePut(`/admin/api/events/${order?.eventId}/deliveryProvider/update`, {
                newDeliveryProviderId: deliveryOptions?.deliveryProviderId
            });

            if (res?.error) {
                message.error(res.error);
                setIsLoading(false);
                return;
            }

            if (!res.previousDeliveryProvider?.isCancelled || !res.newDeliveryProvider?.isCreated) {
                setFinalStatus(res);
                setIsLoading(false);
                return;
            }

            message.success("הזמנה סונכרנה מול חברת המשלוחים בהצלחה");
            onSave();
            onCloseModal();
        },
        [onCloseModal, onSave, order?.eventId]
    );

    const currentDeliveryProvider = useMemo(
        () => getFirstOrderShippingProvider(order, deliveryProviders),
        [deliveryProviders, order]
    );

    const deliveryProviderSelectOptions = useMemo(
        () =>
            (deliveryProviders ?? [])
                .filter(({deliveryProviderId}) => deliveryProviderId !== currentDeliveryProvider?.deliveryProviderId)
                .map(({deliveryProviderId, name}) => ({
                    name: deliveryProviderId,
                    label: name
                })),
        [deliveryProviders, currentDeliveryProvider]
    );

    const previousDeliveryProviderOrderStatus = useMemo(
        () =>
            new StringBuilder(deliveryProviderById?.[finalStatus?.previousDeliveryProvider?.id]?.name)
                .append("-")
                .append(finalStatus?.previousDeliveryProvider?.isCancelled ? "בוטלה בהצלחה" : "נכשל בתהליך הביטול")
                .toString(),
        [deliveryProviderById, finalStatus]
    );

    const newDeliveryProviderOrderStatus = useMemo(
        () =>
            new StringBuilder(deliveryProviderById?.[finalStatus?.newDeliveryProvider?.id]?.name)
                .append("-")
                .append(finalStatus?.newDeliveryProvider?.isCreated ? "נוצרה בהצלחה" : "לא נוצר")
                .toString(),
        [deliveryProviderById, finalStatus]
    );

    return (
        <div>
            <ConfigProvider direction="rtl">
                <FormModal
                    className="change-order-delivery-shipping-modal-container"
                    header="שינוי חברת שילוח"
                    subTitle={
                        <>
                            <div className="current-delivery-provider-title">
                                {new StringBuilder("ספק שילוחים נוכחי")
                                    .append(currentDeliveryProvider?.name)
                                    .toString()}
                            </div>
                            <div>
                                יש לבחור את חברת השילוח שתשלח את ההזמנה. שימו לב ברגע שתלחצו אישור ההזמנה תתבטל בחברת
                                .השילוח הנוכחית ותשלח לחברת שילוח שבחרתם
                            </div>
                            <div className="instructions-action-failed">
                                שימו לב: במידה והמשלוח בחברת השילוח התבטל ולא נוצר חדש בחברת השילוחים החדש יש ללכת לחברת
                                השילוחים הישנה ולהחזיר לסטטוס אושר
                            </div>
                        </>
                    }
                    visible={visible}
                    onClose={onCloseModal}
                    onSave={onFinish}
                    isConfirmButtonLoading={isLoading}
                    showCancelButton={true}
                    fields={[
                        {
                            name: "deliveryProviderId",
                            label: "ספק השילוח",
                            type: "select",
                            options: deliveryProviderSelectOptions,
                            placeholder: "בחרו מהאפשרויות"
                        },
                        ...(finalStatus
                            ? [
                                  {
                                      name: "_changeDeliveryProviderResult",
                                      type: "custom",
                                      customComponent: (
                                          <>
                                              <div
                                                  className={classnames("new-delivery-provider-title", {
                                                      failed: !finalStatus?.newDeliveryProvider?.isCancelled
                                                  })}>
                                                  {previousDeliveryProviderOrderStatus}
                                              </div>
                                              <div
                                                  className={classnames("new-delivery-provider-title", {
                                                      failed: !finalStatus?.newDeliveryProvider?.isCreated
                                                  })}>
                                                  {newDeliveryProviderOrderStatus}
                                              </div>
                                          </>
                                      )
                                  }
                              ]
                            : [])
                    ]}
                />
            </ConfigProvider>
        </div>
    );
};
