import moment from "moment";
import {AdminOrdersPaymentTypeToName, BUDGET_LOAD_TRANSACTION} from "./consts";
import {PaymentType} from "../consts.js";
import {createWorkbook, createWorksheet, DownloadExcel} from "../utils/excelUtils.js";
import {reduceVAT} from "../utils/calculationUtils";
import {getStatus, OrderStatus} from "../components";
import {removeEmojis} from "../utils/StringFormat";

const CREDIT_CARD_ERROR = "אשראי לא נסלק";

export const ExportTableToExcel = async (southOrders, nonSouthOrders, errorOrders) => {
    const workbook = createWorkbook();

    const excelSheets = [
        {
            title: "הזמנות מהדרום",
            list: southOrders
        },
        {
            title: "הזמנות לא מהדרום",
            list: nonSouthOrders
        },
        {
            title: "הזמנות שגויות",
            list: errorOrders
        }
    ];

    excelSheets.forEach(({list = [], title}, index) => {
        const sheet = createWorksheet(workbook, title);
        addColumns(sheet);
        addRows(sheet, list);
    });

    await DownloadExcel(workbook, "orders");
};

const addColumns = sheet => {
    sheet.columns = [
        {header: "שם לחשבונית", key: "companyName"},
        {header: "שם החבילה", key: "productName"},
        {header: "תאריך הזמנה", key: "orderDate"},
        {header: "שם המוצר ללקוח", key: "orderSummary"},
        {header: "סכום ₪ (כולל מעמ)", key: "price"},
        {header: "מחיר משלוח", key: "deliveryPrice"},
        {header: "סכום ₪ (לפני מעמ)", key: "priceWithoutTax"},
        {header: "גבייה", key: "paymentType"},
        {header: "כתובת", key: "address"}
    ];
};

const addRows = (sheet, orders) => {
    const rows = orders.reduce((allRows, order) => {
        order.productName = removeEmojis(order.productName ?? order.proposalId ?? order.eventId);
        const createdAt = moment(order.createdAt).format("DD.MM.YY");

        const paymentTypeId =
            order.appointmentId || order.paymentType === BUDGET_LOAD_TRANSACTION
                ? PaymentType.BankTransfer
                : order.paymentType;

        const paymentType = getStatus(order).some(({id}) => id === OrderStatus.creditCardNotPaid)
            ? CREDIT_CARD_ERROR
            : AdminOrdersPaymentTypeToName[paymentTypeId];

        const row = [
            order.companyName,
            order.productName,
            createdAt,
            `${order.productName} ${createdAt} (${order.productAmount ?? ""} ${order.productAmountType ?? ""}) ${
                order.invoiceComment ? `- ${order.invoiceComment}` : ""
            }`,
            order.offeredPrice,
            order.deliveryPrice,
            reduceVAT(order.offeredPrice, order.vat),
            paymentType ?? order.paymentType,
            order.address
        ];

        allRows.push(row);
        return allRows;
    }, []);

    sheet.addRows(rows);
};
