import * as Sentry from "@sentry/react";

export const initialize = user => {
    const isAdminLogin = window.localStorage.getItem("isAdminLogin") ?? false;

    const userProperty = {
        userType: user.type,
        companyName: user.companyName,
        email: user.email,
        featureTests: user.featureTests,
        ...(isAdminLogin
            ? {
                  isAdminLogin: true
              }
            : {})
    };

    Sentry.setUser(userProperty);
};
