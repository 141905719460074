import React, {useEffect, useReducer} from "react";
import {Avatar, Button, message} from "antd";
import {RoundedTable} from "../components/RoundedTable";
import moment from "moment-timezone";
import {SearchInput} from "../components/Search";
import {HttpClient} from "../http/HttpClient";
import PageTitleHeader from "../components/PageTitle";
import {capitalize as _capitalize} from "lodash";

const columns = (onResetPassword, resettingPasswordUserId) => [
    {
        title: "#",
        key: "#",
        width: 70,
        render(_, __, idx) {
            return `${idx + 1}`;
        }
    },
    {
        title: "ID",
        dataIndex: "userId",
        key: "userId",
        width: 140
    },
    {
        title: "NAME",
        key: "name",
        width: 250,
        sorter: (r1, r2) => {
            const name1 = `${r1.firstName}${r1.lastName ? ` ${r1.lastName}` : ""}`;
            const name2 = `${r2.firstName}${r2.lastName ? ` ${r2.lastName}` : ""}`;
            return name1.toLowerCase() < name2.toLowerCase() ? 1 : -1;
        },
        render(_, {firstName, lastName, email}) {
            return (
                <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                    <Avatar
                        style={{
                            backgroundColor: "#ff9f1a",
                            color: "white",
                            verticalAlign: "middle",
                            marginRight: 8,
                            minWidth: 40,
                            minHeight: 40
                        }}
                        size="large"
                        gap={4}>
                        {firstName ? firstName.charAt(0).toUpperCase() : "---"}
                    </Avatar>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <label
                            style={{
                                color: "var(--secondary-color)",
                                fontSize: 14,
                                fontWeight: 500
                            }}>{`${firstName} ${lastName || ""}`}</label>
                        <label style={{color: "#747F92", fontSize: 12, fontWeight: 400}}>{email}</label>
                    </div>
                </div>
            );
        }
    },
    {
        title: "TYPE",
        dataIndex: "type",
        key: "type",
        width: 200,
        filters: [
            {
                text: "Company admin",
                value: "company"
            },
            {
                text: "Employee",
                value: "employee"
            },
            {
                text: "Professional",
                value: "provider"
            }
        ],
        filterMultiple: false,
        onFilter: (value, {type}) => {
            return value === type;
        },
        render(type) {
            switch (type) {
                case "employee":
                    return "EMPLOYEE";
                case "provider":
                    return "PROFESSIONAL";
                default:
                    return "COMPANY ADMIN";
            }
        }
    },
    {
        title: "PHONE",
        dataIndex: "phone",
        key: "phone",
        width: 200,
        render(phone) {
            return phone ? phone : "-";
        }
    },
    {
        title: "ADDRESS",
        key: "address",
        width: 200,
        render(_, {countryName, cityName}) {
            return countryName && cityName ? `${countryName}, ${cityName}` : "-";
        }
    },
    {
        title: "COMPANY",
        dataIndex: "companyName",
        key: "companyName",
        width: 200,
        render(companyName) {
            return companyName ? companyName : "-";
        }
    },
    {
        title: "GENDER",
        dataIndex: "gender",
        key: "gender",
        render(gender) {
            return gender ? _capitalize(gender) : "-";
        }
    },
    {
        title: "POSITION",
        dataIndex: "position",
        key: "position",
        width: 150,
        render(position) {
            return position ? position : "-";
        }
    },
    {
        title: "CREATED AT",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 200,
        sorter: (r1, r2) => (r1.createdAt < r2.createdAt ? 1 : -1),
        render(createdAt) {
            return createdAt ? moment(createdAt).format("MMMM Do, YYYY HH:mm") : "";
        }
    },
    {
        title: "RESET PASSWORD",
        key: "resetPassword",
        width: 200,
        render(_, {userId}) {
            return (
                <div onClick={e => e.stopPropagation()}>
                    <Button
                        style={{minWidth: 130}}
                        loading={resettingPasswordUserId === userId}
                        onClick={async () => await onResetPassword(userId)}>
                        Reset password
                    </Button>
                </div>
            );
        }
    }
];

export const AdminUsers = () => {
    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        term: "",
        users: null,
        resettingPasswordUserId: null
    });

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const users = await HttpClient.get("/admin/api/users");
                setState({users});
            } catch (e) {
                message.error(e.message, 3);
            }
        });
    }, []);

    const filter = term => {
        setState({term});
    };

    const resetUserPassword = async userId => {
        setState({resettingPasswordUserId: userId});
        try {
            await HttpClient.post(`/admin/api/users/password/reset`, {userId});
            message.success("Reset password email sent!");
        } catch (e) {
            message.error(e.message, 3);
        }
        setState({resettingPasswordUserId: null});
    };

    let {users, term, resettingPasswordUserId} = state;

    if (users) {
        users = users.filter(
            ({firstName, lastName, email, phone, companyName}) =>
                `${firstName} ${lastName} ${email} ${phone || ""}`.toLowerCase().indexOf(term.toLowerCase()) >= 0 ||
                (companyName && companyName.toLowerCase().indexOf(term.toLowerCase()) >= 0)
        );
    }

    return (
        <div style={{display: "flex", justifyContent: "center", margin: 10, marginBottom: 40}}>
            <div style={{display: "flex", flexDirection: "column", maxWidth: 1500, width: "100%"}}>
                <PageTitleHeader showBack={false}>Users</PageTitleHeader>
                <div>
                    <SearchInput
                        style={{marginBottom: 30, maxWidth: 500, width: "100%"}}
                        onChange={evt => filter(evt.target.value)}
                        placeholder="Search user or company"
                    />
                </div>
                <RoundedTable
                    rowKey="userId"
                    scroll={{x: 2000, y: 600}}
                    pagination={false}
                    loading={users === null}
                    dataSource={users || []}
                    columns={columns(async userId => await resetUserPassword(userId), resettingPasswordUserId)}
                />
            </div>
        </div>
    );
};
