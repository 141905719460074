import React, {useCallback, useContext, useMemo} from "react";
import {SegmentedOptions} from "../../../components/SegmentedGroup/index.jsx";
import {MarketplaceFilterProviderContext} from "../MarketplaceFilterContext.jsx";
import "./panel-filter.css";
import {panelsOptions, panelOptions, getMainCategories} from "./consts.jsx";
import {AppContext} from "../../../AppContext.jsx";
import {useHistory} from "react-router";

export const PanelFilter = () => {
    const history = useHistory();
    const {me} = useContext(AppContext);
    const {setPanel, resetState, panel} = useContext(MarketplaceFilterProviderContext);

    const onChange = useCallback(
        selectedPanel => {
            setPanel(selectedPanel);
            if (selectedPanel === panelOptions.homePage) {
                history.replace("/dashboard/services");
                return;
            }
            resetState(history, selectedPanel);
        },
        [history, panel, resetState]
    );

    const panels = useMemo(() => {
        if (me?.allowedServices?.length > 0) {
            const categories = getMainCategories(me.allowedServices);
            return panelsOptions.filter(
                category => categories.includes(category.key) || category.key === panelOptions.homePage
            );
        }
        return panelsOptions;
    }, [me?.allowedServices]);

    return (
        <div id="panel-filter">
            <SegmentedOptions value={panel} options={panels} onChange={onChange} />
        </div>
    );
};
