import {CategorySearchSorterType} from "../../MarketplaceUtils.jsx";

export const searchSorterTypeText = {
    [CategorySearchSorterType.BestSeller]: "מיון לפי: הנמכרים ביותר",
    [CategorySearchSorterType.LowestFirst]: "מחיר: מהנמוך לגבוה",
    [CategorySearchSorterType.HighestFirst]: "מחיר: מהגבוה לנמוך"
}

export const SearchItemType = {
    products: "product",
    providers: "provider",
    pinterest: "pinterest"
}

export const SearchItemTypeText = {
    [SearchItemType.products]: "מוצרים",
    [SearchItemType.providers]: "שותפים",
    [SearchItemType.pinterest]: "תמונות השראה"
}
