import {Link} from "react-router-dom";
import {StyledButton} from "../../components/StyledButton";
import React, {useContext} from "react";
import {findContentSectionById} from "../../company/content/CompanyContent";
import {EXCenterContext} from "../EXCenterContext";

export const EXCenterSectionSeeMoreButton = ({sectionId, designInfo}) => {
    const {theme} = useContext(EXCenterContext);
    const defaultDesign = findContentSectionById(sectionId).defaultDesign(theme);

    if (!designInfo.action && defaultDesign.action) {
        designInfo.action = defaultDesign.action;
    }

    if (!designInfo.action || !designInfo.action.enabled) {
        return null;
    }

    return (
        <div
            className="wb-ss-no-padding"
            style={{
                display: "flex",
                justifyContent: "center",
                width: "100%"
            }}>
            <Link className="wb-ss-full-width wb-ss-card-max-width" to={`/center/sections/${sectionId}`}>
                <StyledButton
                    className="wb-ss-full-width wb-ss-see-more wb-center-action-button"
                    style={{
                        color: designInfo.action.color,
                        backgroundColor: "transparent",
                        border: "none",
                        marginTop: 40,
                        fontSize: 18,
                        textDecoration: "underline",
                        boxShadow: "none"
                    }}>
                    {designInfo.action.text}
                </StyledButton>
            </Link>
        </div>
    );
};
