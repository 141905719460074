import {Modal, message} from "antd";
import {CloseIcon} from "../icons";
import React, {useEffect, useState} from "react";
import {Images} from "../images/Images";
import {PageTitleLabel} from "../components/PageTitle";
import {Link} from "react-router-dom";
import {StyledButton} from "../components/StyledButton";
import {HttpClient} from "../http/HttpClient";

export const EventReminderContent = ({event, onClose}) => {
    const [reminding, setReminding] = useState(false);
    const [cancelling, setCancelling] = useState(false);
    const [provider, setProvider] = useState(null);

    const {eventId, providerId, providerFirstName, providerLastName} = event;

    useEffect(() => {
        if (!providerId) {
            return;
        }

        Promise.resolve().then(async () => {
            try {
                const provider = await HttpClient.get(`/api/providers/${providerId}`);
                setProvider(provider);
            } catch (e) {
                setProvider(null);
            }
        });
    }, []);

    const sendReminder = async () => {
        setReminding(true);
        try {
            await HttpClient.post(`/api/events/${eventId}/proposal/remind`, {});
            onClose(event);
        } catch (e) {
            message.error("Something went wrong, please try again later.");
        }
        setReminding(false);
    };

    const cancelOffer = async () => {
        setCancelling(true);
        try {
            const cancelledEvent = await HttpClient.delete(`/api/events/${eventId}/cancel`);
            onClose(cancelledEvent);
        } catch (e) {
            message.error("Something went wrong, please try again later.");
        }
        setCancelling(false);
    };

    const providerName = `${providerFirstName}${providerLastName ? ` ${providerLastName}` : ""}`;

    return (
        <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
            <div
                style={{
                    background: `url(${Images.eventReminder})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    paddingBottom: "52%"
                }}
            />
            <div style={{display: "flex", flexDirection: "column", padding: 30, width: "100%", alignItems: "center"}}>
                <PageTitleLabel style={{marginBottom: 0, marginTop: 0}}>
                    Your offer is pending approval of:
                </PageTitleLabel>
                {provider ? (
                    <Link
                        style={{marginTop: 15, color: "#1260D1", fontSize: 20}}
                        to={`/dashboard/schedule/events/${eventId}/providers/${providerId}`}>
                        {providerName}
                    </Link>
                ) : (
                    <div style={{marginTop: 15, color: "#273444", fontSize: 20}}>{providerName}</div>
                )}
                <div style={{display: "flex", width: "100%", marginTop: 30, justifyContent: "center"}}>
                    <StyledButton
                        onClick={cancelOffer}
                        loading={cancelling}
                        style={{backgroundColor: "#F06060", color: "#FFF", width: 140}}>
                        Cancel offer
                    </StyledButton>
                    <StyledButton
                        onClick={sendReminder}
                        loading={reminding}
                        style={{backgroundColor: "#13C296", color: "#FFF", marginLeft: 20, width: 140}}>
                        Send reminder
                    </StyledButton>
                </div>
            </div>
        </div>
    );
};

export const EventReminderModal = ({visible, event, onClose}) => {
    const closeMe = () => {
        onClose(event);
    };

    return (
        <Modal
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={closeMe}
            footer={null}
            title={null}
            closable={true}
            closeIcon={<CloseIcon fill="#FFF" />}
            bodyStyle={{padding: 0}}>
            {visible ? <EventReminderContent event={event} onClose={onClose} /> : null}
        </Modal>
    );
};
