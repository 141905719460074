import React from "react";
import {cloneDeep} from "lodash";

export const attachCountryStateAndCity = companyInfo => {
    companyInfo = cloneDeep(companyInfo);

    const addressParts = companyInfo.address.split(",");
    if (addressParts.length === 4) {
        companyInfo.countryName = addressParts[3].trim();
        companyInfo.stateName = addressParts[2].trim();
        companyInfo.cityName = addressParts[1].trim();
    } else if (addressParts.length === 3) {
        companyInfo.countryName = addressParts[2].trim();
        companyInfo.stateName = null;
        companyInfo.cityName = addressParts[1].trim();
    } else if (addressParts.length === 2) {
        companyInfo.countryName = addressParts[1].trim();
        companyInfo.stateName = null;
        companyInfo.cityName = addressParts[0].trim();
    }

    return companyInfo;
};

export const getServiceIdByType = type => {
    switch (type) {
        case "Lecture":
            return "professional";
        case "Workshop":
            return "workshops";
        default:
            return null;
    }
};

export const getDealerType = type => {
    switch (type) {
        case "Licensed dealer (עוסק מורשה)":
            return "licensed";
        case "Exempt dealer (עוסק פטור)":
            return "exempt";
        case "LLC (חברה בע״מ)":
            return "llc";
    }
};

export const getDefaultService = type => {
    return {
        serviceId: getServiceIdByType(type),
        displayName: type || null,
        categoryName: null
    };
};
