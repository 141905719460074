import PageTitleHeader, {PageSubTitleLabel} from "../../components/PageTitle";
import React, {useContext, useEffect, useReducer, useState} from "react";
import {ArrowLeftOutlined, ArrowRightOutlined, EditOutlined} from "@ant-design/icons";
import {Button, Spin, message} from "antd";
import {Logo} from "../../images/Images";
import {AppContext} from "../../AppContext";
import moment from "moment-timezone";
import {HttpClient} from "../../http/HttpClient";
import {EventBus} from "../../bus/EventBus";
import {RRuleSetBuilder} from "../../event/RRuleSetBuilder";
import {StyledButton} from "../../components/StyledButton";
import {CompanyEventEdit} from "../CompanyEventEdit";
import {CompanyWeeklyEmailProperties} from "./CompanyWeeklyEmailProperties";
import {GoogleAnalytics} from "../../GoogleAnalytics";
import {Link} from "react-router-dom";
import {EventInfoPreview} from "../../event/preview/EventInfoPreview";
import {PageLoader} from "../../components/PageLoader";
import {eventByDate} from "../../event/EventByDate";
import {SmartImageContainer} from "../../components/SmartImageContainer";
import {StepperParagraphInput} from "../../wizards/components/StepperParagraphInput";

const WeekNavigation = ({startDateMom, endDateMom, onChange}) => {
    const prevWeek = () => {
        const newStart = moment(startDateMom).subtract(1, "weeks");
        const newEnd = moment(endDateMom).subtract(1, "weeks");
        onChange(newStart, newEnd);
    };

    const nextWeek = () => {
        const newStart = moment(startDateMom).add(1, "weeks");
        const newEnd = moment(endDateMom).add(1, "weeks");
        onChange(newStart, newEnd);
    };

    return (
        <div style={{display: "flex", width: "100%"}}>
            <Button
                onClick={prevWeek}
                style={{
                    minWidth: 50,
                    height: 50,
                    border: "1px solid #E9EDF4",
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                    borderTopRightRadius: "none",
                    borderBottomRightRadius: "none"
                }}
                icon={<ArrowLeftOutlined />}
            />
            <div
                style={{
                    width: "100%",
                    borderTop: "1px solid #E9EDF4",
                    borderBottom: "1px solid #E9EDF4",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 16,
                    color: "var(--secondary-color)"
                }}>
                {startDateMom.format("MMMM Do")} - {endDateMom.format("MMMM Do")}
            </div>
            <Button
                onClick={nextWeek}
                style={{
                    minWidth: 50,
                    height: 50,
                    border: "1px solid #E9EDF4",
                    borderTopLeftRadius: "none",
                    borderBottomLeftRadius: "none",
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10
                }}
                icon={<ArrowRightOutlined />}
            />
        </div>
    );
};

const DateTitle = ({dateObj}) => {
    const {theme} = useContext(AppContext);
    const {weeklyTheme} = theme;
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: 30,
                borderRadius: 10,
                marginBottom: 30,
                height: 50,
                width: "100%",
                fontSize: 20,
                color: weeklyTheme.textSecondary,
                backgroundColor: weeklyTheme.secondary
            }}>
            {moment(dateObj).format("dddd / MMMM Do, YYYY")}
        </div>
    );
};

const WeeklyEvents = ({startDateMom, endDateMom, eventsPerDay, onEdit, onDisplayChange, loading}) => {
    if (loading) {
        return <PageLoader top={40} bottom={60} />;
    }

    if (Object.keys(eventsPerDay).length === 0)
        return (
            <PageSubTitleLabel style={{marginTop: 50, marginBottom: 80}}>No events for this week.</PageSubTitleLabel>
        );

    return (
        <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
            {Object.keys(eventsPerDay)
                .sort((d1, d2) => {
                    return moment(d1, "YYYY-MM-DD").isSameOrBefore(moment(d2, "YYYY-MM-DD")) ? -1 : 1;
                })
                .map(date => {
                    const eventsOnThisDate = eventsPerDay[date];
                    return (
                        <>
                            <DateTitle key={`weekly-date-${date}`} dateObj={moment(date, "YYYY-MM-DD").toDate()} />
                            {eventsOnThisDate
                                .sort((e1, e2) => {
                                    if (e1.event.appointmentBased && !e2.event.appointmentBased) {
                                        return -1;
                                    } else if (!e1.event.appointmentBased && e2.event.appointmentBased) {
                                        return 1;
                                    }

                                    return moment(e1.date).isSameOrBefore(moment(e2.date)) ? -1 : 1;
                                })
                                .map((eventOnThisDate, idx) => (
                                    <EventInfoPreview
                                        key={`weekly-card-${idx}`}
                                        event={eventByDate(eventOnThisDate.event, eventOnThisDate.date)}
                                        dateMom={moment(eventOnThisDate.date)}
                                        onEdit={onEdit}
                                        onDisplayChange={onDisplayChange}
                                    />
                                ))}
                        </>
                    );
                })}
        </div>
    );
};

export const CompanyWeeklyTemplate = () => {
    const {me} = useContext(AppContext);
    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        events: null,
        startDateMom: moment().startOf("week"),
        endDateMom: moment().endOf("week"),
        editEvent: null,
        editDateMom: null,
        sendingTest: false,
        publishing: false,
        showEmailProps: false,
        loading: false
    });

    const {events, startDateMom, endDateMom, editEvent, editDateMom, sendingTest, publishing, showEmailProps, loading} =
        state;

    const getPreviewDescriptionDefault = () => {
        return `This week at ${me.companyName}:`;
    };

    const [weeklyPreviewDescription, setWeeklyPreviewDescription] = useState(getPreviewDescriptionDefault());
    const [editPreviewDescription, setEditPreviewDescription] = useState(false);

    useEffect(() => {
        fetchEventForDateRangeAsync(startDateMom, endDateMom);
    }, []);

    const fetchEventForDateRangeAsync = (startDateMom, endDateMom) => {
        Promise.resolve().then(async () => {
            setState({loading: true});
            try {
                const events = await HttpClient.get(
                    `/api/weekly/events/${startDateMom.format("YYYY-MM-DD")}/${endDateMom.format("YYYY-MM-DD")}`
                );
                setState({events});
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we couldn't list all your events :("}},
                    e.message
                );
            }
            setState({loading: false});
        });
    };

    const eventsByDay = events => {
        return events.reduce((perDay, event) => {
            const rruleSet = RRuleSetBuilder.build(event);
            const dates = rruleSet.between(startDateMom.toDate(), endDateMom.toDate(), true);
            (dates || []).forEach(date => {
                perDay[moment(date).format("YYYY-MM-DD")] = perDay[moment(date).format("YYYY-MM-DD")] || [];
                perDay[moment(date).format("YYYY-MM-DD")].push({
                    event,
                    date
                });
            });

            return perDay;
        }, {});
    };

    const onWeekChange = (newStart, newEnd) => {
        setState({
            startDateMom: newStart,
            endDateMom: newEnd
        });

        fetchEventForDateRangeAsync(newStart, newEnd);
    };

    const onEdit = (event, dateMom) => {
        setState({editEvent: event, editDateMom: dateMom});
    };

    const onDisplayChange = (event, displayed) => {
        setState({
            events: events.map(evt => {
                if (evt.eventId === event.eventId) {
                    return {
                        ...evt,
                        displayed
                    };
                } else {
                    return evt;
                }
            })
        });
    };

    const closeEdit = () => {
        setState({editEvent: null});
    };

    const onEditEventChange = event => {
        setState({editEvent: event});
    };

    const saveChanges = async event => {
        try {
            const start = startDateMom.format("YYYY-MM-DD");
            const end = endDateMom.format("YYYY-MM-DD");
            await HttpClient.post(`/api/weekly/events/${event.eventId}/${start}/${end}`, transformToWeeklyEvent(event));
            setState({events: events.map(evt => (evt.eventId === event.eventId ? event : evt))});
            GoogleAnalytics.event("Weekly Emails", "Edit Event");
            closeEdit();
        } catch (e) {
            message.error("Oppsy... something went wrong, please try again later.");
        }
    };

    const transformToWeeklyEvent = evt => {
        return {
            eventId: evt.eventId,
            companyId: evt.companyId,
            actionType: evt.actionType || "system",
            externalLink: evt.externalLink || null,
            displayed: typeof evt.displayed === "undefined" ? true : evt.displayed,
            product: evt.product,
            provider: evt.provider,
            callToAction: evt.callToAction
        };
    };

    const isPreviewDescriptionChanged = () => {
        return weeklyPreviewDescription !== getPreviewDescriptionDefault();
    };

    const sendTestEmail = async () => {
        setState({sendingTest: true});
        try {
            const start = startDateMom.format("YYYY-MM-DD");
            const end = endDateMom.format("YYYY-MM-DD");
            await HttpClient.post(`/api/weekly/test?start=${start}&end=${end}`, {
                events: events.map(evt => transformToWeeklyEvent(evt)),
                previewDescription: isPreviewDescriptionChanged() ? weeklyPreviewDescription : null
            });
            GoogleAnalytics.event("Weekly Emails", "Send Test");
            message.success(
                <span>
                    Email was sent successfully to: <strong>{me.email}</strong>
                </span>,
                5
            );
        } catch (e) {
            message.error("Oppsy... something went wrong, please try again later.", 3);
        }
        setState({sendingTest: false});
    };

    const publishAndSend = async () => {
        setState({publishing: true});
        try {
            const start = startDateMom.format("YYYY-MM-DD");
            const end = endDateMom.format("YYYY-MM-DD");
            await HttpClient.post(`/api/weekly/publish?start=${start}&end=${end}`, {
                events: events.map(evt => transformToWeeklyEvent(evt)),
                previewDescription: isPreviewDescriptionChanged() ? weeklyPreviewDescription : null
            });
            GoogleAnalytics.event("Weekly Emails", "Publish & Send");
            message.success("Email was sent successfully!", 3);
        } catch (e) {
            message.error("Oppsy... something went wrong, please try again later.", 3);
        }
        setState({publishing: false});
        closeEmailProps();
    };

    const openEmailProps = () => {
        if (!me.premiumAccount) {
            return EventBus.trigger("activate_account:open", {label: "Weekly Publish", type: "company"});
        }
        setState({showEmailProps: true});
    };

    const closeEmailProps = () => {
        setState({showEmailProps: false});
    };

    if (events === null) return <PageLoader align="center" top={60} height={200} />;

    const CompanyLogo = ({width, height, top}) => (
        <div
            style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                marginTop: top
            }}>
            <SmartImageContainer
                width={width}
                height={height}
                style={{cursor: "pointer"}}
                src={me.branding && me.branding.logoUrl ? me.branding.logoUrl : Logo.image}
                transformations={{crop: "lpad"}}
            />
        </div>
    );

    const noEvents = eventsPerDay => {
        return !eventsPerDay || Object.keys(eventsPerDay).length === 0;
    };

    const eventsPerDay = eventsByDay(events);
    const blockPublish = noEvents(eventsPerDay);

    return (
        <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
            <CompanyEventEdit
                visible={editEvent !== null}
                event={editEvent}
                dateMom={editDateMom}
                onChange={onEditEventChange}
                onSave={saveChanges}
                onClose={closeEdit}
            />
            <CompanyWeeklyEmailProperties
                visible={showEmailProps}
                companyName={me.companyName}
                companyEmail={me.email}
                onClose={closeEmailProps}
                onSend={publishAndSend}
                sending={publishing}
            />
            <PageTitleHeader showBack={false}>Weekly email</PageTitleHeader>
            <PageSubTitleLabel>Your weekly events are ready to be sent out</PageSubTitleLabel>
            <PageSubTitleLabel style={{fontSize: 13, color: "#273444"}}>
                Looking for email best practices?{" "}
                <Link
                    style={{marginLeft: 4, fontSize: 13, color: "#273444", textDecoration: "underline"}}
                    to="/company/weekly/practices">
                    Click here
                </Link>
            </PageSubTitleLabel>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    maxWidth: 570,
                    width: "100%",
                    marginTop: 40,
                    marginBottom: 80
                }}>
                <WeekNavigation onChange={onWeekChange} startDateMom={startDateMom} endDateMom={endDateMom} />
                <CompanyLogo width={160} height={64} top={50} />
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div
                        style={{
                            fontSize: 16,
                            color: "var(--secondary-color)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: 20,
                            marginBottom: 30
                        }}>
                        {editPreviewDescription ? (
                            <div>
                                <StepperParagraphInput
                                    value={weeklyPreviewDescription}
                                    onChange={e => setWeeklyPreviewDescription(e.target.value)}
                                    maxLength={400}
                                    style={{width: "570px"}}
                                />
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        height: 60,
                                        backgroundColor: "white",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        boxShadow: "0 -10px 20px 0 rgba(76,88,105,0.05)"
                                    }}>
                                    <StyledButton
                                        onClick={() => {
                                            setEditPreviewDescription(false);
                                            setWeeklyPreviewDescription(getPreviewDescriptionDefault());
                                        }}
                                        style={{
                                            height: 50,
                                            width: 150,
                                            marginRight: 20,
                                            border: "1px solid var(--secondary-color)",
                                            borderRadius: 25,
                                            color: "var(--secondary-color)"
                                        }}>
                                        Cancel
                                    </StyledButton>
                                    <StyledButton
                                        onClick={() => {
                                            setEditPreviewDescription(false);
                                        }}
                                        style={{
                                            height: 50,
                                            width: 150,
                                            borderRadius: 25,
                                            color: "white",
                                            backgroundColor: "var(--secondary-color)"
                                        }}>
                                        Save
                                    </StyledButton>
                                </div>
                            </div>
                        ) : (
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <EditOutlined
                                    style={{fontSize: "20px", marginTop: "2px", marginRight: "20px"}}
                                    onClick={() => setEditPreviewDescription(true)}
                                />
                                <div>{weeklyPreviewDescription}</div>
                            </div>
                        )}
                    </div>
                </div>
                <WeeklyEvents
                    eventsPerDay={eventsPerDay}
                    startDateMom={startDateMom}
                    endDateMom={endDateMom}
                    onEdit={onEdit}
                    onDisplayChange={onDisplayChange}
                    loading={loading}
                />
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingBottom: 30,
                        borderBottom: "1px solid #DADEE4",
                        marginBottom: 30
                    }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 16,
                            color: "var(--secondary-color)"
                        }}>
                        {me.companyName} · {me.address} · {me.cityName} · {me.countryName}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 16,
                            color: "var(--secondary-color)"
                        }}>
                        Thank you for choosing us! {me.companyName} team.
                    </div>
                    <CompanyLogo width={75} height={30} top={20} />
                    <a
                        style={{
                            textAlign: "center",
                            marginTop: 20,
                            height: 18,
                            fontSize: 14,
                            textDecoration: "underline",
                            color: "#8492A6"
                        }}>
                        Unsubscribe from this list
                    </a>
                </div>
                <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                    <div
                        style={{
                            fontSize: 14,
                            color: "var(--secondary-color)",
                            marginRight: 12,
                            display: "flex",
                            alignItems: "center"
                        }}>
                        Powered by
                    </div>
                    <div
                        style={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: top
                        }}>
                        <img width="80" height="20" style={{cursor: "pointer"}} alt="well-b-logo" src={Logo.image} />
                    </div>
                </div>
                <a
                    style={{
                        textAlign: "center",
                        marginTop: 20,
                        height: 18,
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#8492A6"
                    }}
                    href="https://www.well-b.biz">
                    Learn more
                </a>
            </div>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    height: 80,
                    backgroundColor: "white",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0 -10px 20px 0 rgba(76,88,105,0.05)"
                }}>
                <StyledButton
                    loading={sendingTest}
                    onClick={sendTestEmail}
                    disabled={blockPublish}
                    style={{
                        height: 50,
                        width: 150,
                        marginRight: 20,
                        border: "1px solid var(--secondary-color)",
                        borderRadius: 25,
                        color: "var(--secondary-color)"
                    }}>
                    Send test email
                </StyledButton>
                <StyledButton
                    onClick={openEmailProps}
                    disabled={blockPublish}
                    style={{height: 50, width: 150, borderRadius: 25, color: "white", backgroundColor: "#13C296"}}>
                    Publish & Send
                </StyledButton>
            </div>
        </div>
    );
};
