import React from "react";
import {message} from "antd";
import {FormModal} from "../../components/index.jsx";

const allowedSSODomains = ["@hibob.io", "@well-b.biz"];

export const SSOFormModal = ({onSuccess, visible, onClose}) => {
    return (
        <FormModal
            header="משתמשים בSSO?"
            fields={[
                {
                    name: "email",
                    label: "מייל חברה",
                    type: "email"
                }
            ]}
            visible={visible}
            onClose={onClose}
            validationFunction={({email}) => {
                if (!allowedSSODomains.some(domain => email.includes(domain))) {
                    message.error("לצערנו, לא זיהינו מייל זה בתור משתמש SSO, ניתן ליצור איתנו קשר לפרטים נוספים");
                    return false;
                }

                return true;
            }}
            onSave={onSuccess}
            confirmButtonTitle={"התחברות"}
        />
    );
};
