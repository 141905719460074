import React from "react";
import ProviderProfile from "./ProviderProfile";
import {HttpClient} from "../http/HttpClient";
import {Spin} from "antd";
import {EventBus} from "../bus/EventBus";
import phoneFormat from "phone";
import {ContactPhone} from "../support/ContactPhone";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {AppContext} from "../AppContext";
import {isAppointmentBasedProfession} from "../data/professions";

export class ProviderDetails extends React.Component {
    static contextType = AppContext;

    state = {
        providerDetails: null,
        phoneNumber: null
    };

    setTerms(isBookClicked) {
        const {history, match} = this.props;
        const {providerId} = match.params;
        const serviceId = this.serviceId();

        if (isBookClicked) {
            GoogleAnalytics.event("Professional Profile", "Book Now", providerId);
        } else {
            GoogleAnalytics.event("Professional Profile", "Set Terms", providerId);
        }

        history.push(`/dashboard/services/${serviceId}/providers/${providerId}/offer/single`);
    }

    async componentDidMount() {
        try {
            const {match} = this.props;
            const {providerId, eventId} = match.params;
            const providerDetails = await HttpClient.get(
                `/api/providers/${providerId}${eventId ? `?eventId=${eventId}` : ""}`
            );
            if (eventId) {
                this.extractPhoneNumber(providerDetails);
            }
            this.setState({providerDetails});
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we didn't manage to fetch the professional's details :("}},
                e.message
            );
        }
    }

    serviceId() {
        const {match} = this.props;
        const {serviceId} = match.params;
        const {professions} = this.context;
        const {providerDetails} = this.state;

        if (serviceId) {
            return serviceId;
        }

        if (
            providerDetails &&
            isAppointmentBasedProfession(providerDetails.profession, professions) &&
            Array.isArray(providerDetails.services) &&
            providerDetails.services.length > 0
        ) {
            return providerDetails.services[0].service.serviceId;
        }

        return null;
    }

    extractPhoneNumber(providerDetails) {
        if (providerDetails && providerDetails.phone) {
            const [formattedPhoneNumber] = phoneFormat(providerDetails.phone, providerDetails.countryCode);
            const phoneNumber = (formattedPhoneNumber || providerDetails.phone).replace("+", "");
            this.setState({phoneNumber});
        }
    }

    render() {
        const {me} = this.context;
        const {providerDetails, phoneNumber} = this.state;
        const serviceId = this.serviceId();

        return (
            <div
                style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginTop: -20}}>
                {providerDetails ? (
                    <ProviderProfile
                        onChatClick={phoneNumber && me.type === "company" ? () => ContactPhone.openWhatsApp() : null}
                        onBookClick={me.type === "company" && serviceId ? () => this.setTerms(true) : void 0}
                        serviceId={serviceId}
                        providerDetails={providerDetails}
                    />
                ) : (
                    <Spin size="large" style={{marginTop: 100}} />
                )}
            </div>
        );
    }
}
