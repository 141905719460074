import React, {useCallback, useContext, useEffect, useMemo, useRef} from "react";
import classnames from "classnames";
import {uniq} from "lodash";
import {Order_Images_Link} from "./consts";
import {useRequest} from "../../utils/hooks";
import {ImagesCarousel} from "./ImagesCarousel";
import {MarketplaceCategoryCarouselArrow} from "../MarketplaceHelper";
import {AppContext} from "../../AppContext";
import "./marketplace-images-carousel.css";
import "./company-marketplace-images-carousel.css";

export const CompanyMarketplaceImagesCarousel = ({history, title, log}) => {
    const {userLikedItems, setUserLikedItems} = useContext(AppContext);
    const [allOrderImagesFromServer] = useRequest(`/api/orderImages/products/1`);
    const carouselRef = useRef(null);

    const orderImagesFromServer = useMemo(() => {
        if (allOrderImagesFromServer && allOrderImagesFromServer.length > 0) {
            return allOrderImagesFromServer?.slice(0, 50);
        } else {
            return [];
        }
    }, [allOrderImagesFromServer]);

    const productIds = useMemo(
        () => uniq(orderImagesFromServer?.map(({productId}) => productId)),
        [orderImagesFromServer]
    );

    const [products] = useRequest(
        "/api/products/byIds",
        "POST",
        {
            productIds,
            pickedFields: ["brandName", "engBrandName", "productName", "services", "provider.businessName"],
            includeNotApprove: true
        },
        [productIds.length],
        !!orderImagesFromServer?.length,
        null,
        []
    );

    const flattenOrderImages = useMemo(
        () =>
            orderImagesFromServer &&
            orderImagesFromServer.map(orderImage => ({
                ...orderImage.image,
                productId: orderImage.productId
            })),
        [orderImagesFromServer]
    );

    const goToProductPage = useCallback(
        image => {
            log("Event Image Clicked");
            const foundOrderImage = orderImagesFromServer.find(orderImage => orderImage.image.id === image.id);

            if (foundOrderImage) {
                history.push(`/marketplace/product/${foundOrderImage.productId}`);
            }
        },
        [history, flattenOrderImages, log, orderImagesFromServer]
    );

    const carouselProps = useMemo(() => {
        return {
            prevArrow: (
                <MarketplaceCategoryCarouselArrow
                    direction="prev"
                    onClick={() => {
                        log("Image Carousel Previous Arrow Clicked");
                        carouselRef.current.prev();
                    }}
                    bottomStyle={"368px"}
                />
            ),
            nextArrow: (
                <MarketplaceCategoryCarouselArrow
                    direction="next"
                    onClick={() => {
                        log("Image Carousel Next Arrow Clicked");
                        carouselRef.current.next();
                    }}
                    bottomStyle={"368px"}
                />
            ),
            arrows: true,
            slidesToScroll: 2
        };
    }, [log]);

    return (
        <>
            {orderImagesFromServer && orderImagesFromServer.length > 0 && (
                <div className="marketplace-images-carousel-container">
                    <div className="company-marketplace-images-carousel-title-row">
                        <span
                            className={classnames(
                                "company-marketplace-images-carousel-title",
                                "marketplace-category-sub-category-title"
                            )}>
                            {title}
                        </span>

                        <div
                            onClick={() => {
                                log("Go To Inspiration Board Clicked");
                                history.push(Order_Images_Link);
                            }}
                            className="company-marketplace-images-carousel-button">
                            ללוח ההשראה ✨
                        </div>
                    </div>

                    <ImagesCarousel
                        history={history}
                        carouselRef={carouselRef}
                        images={flattenOrderImages}
                        carouselProps={carouselProps}
                        log={log}
                        onClick={goToProductPage}
                        products={products}
                        setUserLikedItems={setUserLikedItems}
                        userLikedItems={userLikedItems}
                    />
                </div>
            )}
        </>
    );
};
