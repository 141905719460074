import React, {useState, useEffect, useContext} from "react";
import PageTitleHeader from "../components/PageTitle";
import {Form, Card, Select, Slider, Spin, Button, message, Upload, Tooltip, Checkbox} from "antd";
import {ContactUsPhone, FormCancelSaveBottomBar, FormInput, FormTextArea} from "../components/form";
import {HttpClient} from "../http/HttpClient";
import {EventBus} from "../bus/EventBus";
import _ from "lodash";
import {ProviderChangesSavedModal} from "./ProviderChangesSavedModal";
import {EmailOrPhoneDetector} from "../utils/EmailOrPhoneDetector";
import {AutoDirectionProvider} from "../AutoDirectionProvider";
import {CloseIcon, RemoveIcon, UploadIcon} from "../icons";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {AppContext} from "../AppContext";
import {ChangesNotSavedModal} from "../components/ChangesNotSavedModal";
import {UploadClient} from "../http/UploadClient";
import Icon from "@ant-design/icons";
import {CustomSelect} from "../components/CustomSelect";
import {InfoCircleOutlined} from "@ant-design/icons";
import {MAX_SERVICE_PRICE_NIS} from "../data/defaults";
import {OffsiteLocationInput, ServiceLocationSelect} from "../components/LocationSelect";
import {LocationLabels} from "../data/locations";
import {toProfessionName} from "../data/professions";
import {DurationInput} from "../components/DurationInput";
import {SearchableSelect} from "../components/SearchableSelect";
import {languages} from "../data/languages";
import {SmartImageContainer} from "../components/SmartImageContainer";

const {Option} = CustomSelect;
const {useForm} = Form;

const CoverUpload = ({imageData, onChange}) => {
    const [uploading, setUploading] = useState(false);
    const {me} = useContext(AppContext);

    const uploadCoverPhoto = async file => {
        setUploading(true);
        try {
            const formData = new FormData();
            formData.append("file", file);
            const path = me.isAdmin ? "/admin/api/providers/cover" : "/api/provider/cover";
            const imageData = await UploadClient.upload("PUT", path, formData);
            GoogleAnalytics.event("Professional Services", "Upload Cover", me.userId);
            onChange(imageData);
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we couldn't upload your cover :("}},
                e.message
            );
        }
        setUploading(false);
    };

    const imageUrl = imageData ? imageData.imageUrl : null;

    return imageUrl ? (
        <div style={{display: "flex", justifyContent: "center"}}>
            <SmartImageContainer
                className="wb-image-container"
                width={600}
                height={340}
                src={imageUrl}
                style={{
                    border: "1px solid #E8EDF5",
                    color: "var(--secondary-color)",
                    width: "100%",
                    borderRadius: 10,
                    margin: 0
                }}>
                <CloseIcon
                    onClick={() => onChange(null)}
                    style={{position: "absolute", right: 15, top: 12, cursor: "pointer"}}
                />
            </SmartImageContainer>
        </div>
    ) : (
        <Upload
            disabled={uploading}
            name="file"
            className="wb-cover-upload"
            accept="image/png,image/jpeg"
            showUploadList={false}
            beforeUpload={async file => {
                await uploadCoverPhoto(file);
                return false;
            }}>
            <Button
                disabled={uploading}
                loading={uploading}
                type="link"
                style={{fontSize: 16, padding: 7, color: "var(--secondary-color)", opacity: uploading ? 0.65 : 1}}>
                <Icon component={UploadIcon} style={{fontSize: 20}} /> Upload cover photo
            </Button>
        </Upload>
    );
};

const ServiceTitle = ({onChange, value, index}) => (
    <div
        className="wb-service-title"
        style={{
            display: "flex",
            backgroundColor: "#FCFDFE",
            border: "1px solid #E8EDF5",
            borderRadius: 10,
            width: "100%"
        }}>
        <div
            style={{
                height: 48,
                minWidth: 48,
                width: 48,
                fontSize: 24,
                color: "#203448",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRight: "1px solid #E8EDF5",
                fontWeight: 900
            }}>
            {(index + 1).toString(10)}
        </div>
        <AutoDirectionProvider style={{width: "100%"}} text={value}>
            <FormInput
                style={{border: "none", borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                onChange={onChange}
                value={value}
                placeholder="Service title"
            />
        </AutoDirectionProvider>
    </div>
);

export const PriceType = {
    GROUP: "group",
    EMPLOYEE: "employee"
};

export const PriceTypeLabel = {
    [PriceType.GROUP]: "Per group",
    [PriceType.EMPLOYEE]: "Per employee"
};

const BreakBetweenAppointments = ({onChange, value}) => {
    const [enabled, setEnabled] = useState(value ? value.enabled : false);
    const [marginMinutes, setMarginMinutes] = useState(value ? value.marginMinutes : null);

    const change = val => {
        setEnabled(val.enabled);
        setMarginMinutes(val.marginMinutes);
        onChange({enabled: val.enabled, marginMinutes: val.marginMinutes | 0});
    };

    return (
        <div style={{display: "flex", alignItems: "center"}}>
            <FormInput
                disabled={!enabled}
                onChange={e => change({enabled, marginMinutes: e.target.value})}
                className="wb-input-with-postfix"
                type="number"
                placeholder="Break"
                addonAfter="MIN"
                value={marginMinutes}
                style={{marginRight: 10, backgroundColor: enabled ? "#FCFDFE" : "rgb(247,247,247)"}}
            />
            <Checkbox
                onChange={e => change({enabled: e.target.checked, marginMinutes})}
                checked={enabled}
                style={{marginRight: 10}}
            />
            <Tooltip title="Enable to reserve a break between appointments.">
                <InfoCircleOutlined style={{fontSize: 18, color: "var(--secondary-color)"}} />
            </Tooltip>
        </div>
    );
};

const ServicePricePicker = ({service, /*recommendedPrice,*/ price, onChange}) => (
    <div
        style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            marginRight: -10,
            marginLeft: -10,
            marginBottom: 5,
            padding: 1
        }}>
        <div style={{display: "flex", alignItems: "center", marginBottom: 3}}>
            <FormInput
                className="wb-input-with-prefix"
                type="number"
                style={{borderRadius: 10, width: 100, marginRight: 10}}
                placeholder="Price"
                defaultValue={typeof price.value === "number" ? price.value : /*recommendedPrice*/ void 0}
                value={price.value}
                onChange={e =>
                    onChange({value: e.target.value ? parseInt(e.target.value, 10) : void 0, type: price.type})
                }
                addonBefore="₪"
            />
            <CustomSelect
                style={{width: 135}}
                defaultValue={price.type}
                value={price.type}
                onSelect={value =>
                    onChange({
                        value: typeof price.value === "number" ? price.value : /*recommendedPrice*/ void 0,
                        type: value
                    })
                }>
                {service.defaultPriceType === PriceType.GROUP ? (
                    <>
                        <Option value={PriceType.GROUP}>{PriceTypeLabel[PriceType.GROUP]}</Option>
                        <Option value={PriceType.EMPLOYEE}>{PriceTypeLabel[PriceType.EMPLOYEE]}</Option>
                    </>
                ) : (
                    <>
                        <Option value={PriceType.EMPLOYEE}>{PriceTypeLabel[PriceType.EMPLOYEE]}</Option>
                        <Option value={PriceType.GROUP}>{PriceTypeLabel[PriceType.GROUP]}</Option>
                    </>
                )}
            </CustomSelect>
            <Tooltip
                title="Take into consideration a 10-20% platform fee according to the payment plan you chose."
                placement="topRight">
                <div style={{marginLeft: 10, width: 30}}>
                    <InfoCircleOutlined style={{fontSize: 22, cursor: "pointer"}} />
                </div>
            </Tooltip>
        </div>
        <label style={{fontSize: 14, paddingLeft: 11, color: "rgba(0,0,0,0.55)"}}>(Per session)</label>
    </div>
);

const ServiceForm = ({
    me,
    onFormCreated,
    index,
    initialValues,
    values = {},
    onValuesChange,
    services,
    onFormFailed,
    onRemove,
    servicesCount
}) => {
    const [form] = useForm();
    const [description, setDescription] = useState(initialValues.description || "");

    onFormCreated(form);

    const onDescriptionChange = _.throttle(
        value => {
            setDescription(value);
        },
        100,
        {trailing: true, leading: false}
    );

    const serviceInfo = serviceId => {
        return services.find(s => s.serviceId === serviceId);
    };

    const hasService = () => {
        return !!selectedServiceId();
    };

    const selectedServiceId = () => {
        return values.serviceId;
    };

    const selectService = serviceId => {
        const service = serviceInfo(serviceId);
        // const recommendedPrice = service.recommendedPrice;
        onValuesChange(
            Object.assign({}, values, {
                serviceId
                // price: {value: recommendedPrice, type: service.defaultPriceType}
            })
        );
    };

    const isAppointmentBasedService = () => {
        if (!hasService()) {
            return false;
        }

        const service = serviceInfo(selectedServiceId());
        return Array.isArray(service.boundedTo) && service.boundedTo.length > 0;
    };

    const isOffsiteBased = () => {
        if (hasService()) {
            return serviceInfo(selectedServiceId()).offsiteBased;
        } else if (initialValues && initialValues.service && initialValues.service.serviceId) {
            return serviceInfo(initialValues.service.serviceId).offsiteBased;
        }

        return false;
    };

    return (
        <Form
            id={`service-${index}`}
            name={`service-${index}`}
            key={`service-${index}`}
            form={form}
            initialValues={{
                ...initialValues,
                serviceId: initialValues && initialValues.service ? initialValues.service.serviceId : void 0,
                price: {value: initialValues.price, type: initialValues.priceType}
            }}
            fields={[
                {name: ["productName"], value: values.productName},
                {name: ["serviceId"], value: values.serviceId},
                {name: ["durationMinutes"], value: values.durationMinutes},
                {name: ["location"], value: values.location},
                {name: ["address"], value: values.address},
                {name: ["description"], value: values.description},
                {name: ["coverPhoto"], value: values.coverPhoto},
                {
                    name: ["price"],
                    value: {
                        value: values.price ? values.price.value : void 0,
                        type: values.price ? values.price.type : void 0
                    }
                }
            ]}
            onValuesChange={(_, allValues) => {
                allValues.location = isOffsiteBased() ? LocationLabels.OFFSITE : allValues.location;
                onValuesChange(allValues);
            }}
            onFinishFailed={info => onFormFailed(info)}
            style={{
                maxWidth: 600,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginBottom: 40,
                paddingLeft: 10,
                paddingRight: 10,
                position: "relative"
            }}>
            {servicesCount > 1 ? (
                <RemoveIcon
                    className="wb-remove-icon"
                    onClick={() => onRemove()}
                    style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: 0,
                        top: -10,
                        backgroundColor: "white",
                        zIndex: 1
                    }}
                />
            ) : null}
            <Card
                style={{
                    width: "100%",
                    border: "1px solid #E8EDF5",
                    backgroundColor: "#FCFDFE",
                    borderRadius: 10
                }}>
                <div
                    style={{
                        height: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around"
                    }}>
                    <Form.Item
                        name="productName"
                        rules={[
                            {
                                required: true,
                                message: "Please enter service title.",
                                validateTrigger: "onSubmit"
                            }
                        ]}>
                        <ServiceTitle index={index} />
                    </Form.Item>
                    <Form.Item
                        name="serviceId"
                        rules={[
                            {
                                required: true,
                                message: "Please select category.",
                                validateTrigger: "onSubmit"
                            }
                        ]}>
                        <Select
                            allowClear={true}
                            onSelect={serviceId => selectService(serviceId)}
                            placeholder="Service category">
                            {services.map(service => (
                                <Option key={service.serviceId} value={service.serviceId}>
                                    {service.displayName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item className="wb-service-duration-where" style={{marginBottom: 0}}>
                        <Form.Item
                            name="durationMinutes"
                            style={{
                                display: "inline-block",
                                width: isAppointmentBasedService() ? "calc(40% - 5px)" : "calc(50% - 5px)"
                            }}
                            rules={[
                                {
                                    required: true,
                                    validateTrigger: "onSubmit",
                                    validator: (_, value) => {
                                        return value ? Promise.resolve() : Promise.reject("Please enter duration.");
                                    }
                                }
                            ]}>
                            <DurationInput />
                        </Form.Item>
                        {isAppointmentBasedService() ? (
                            <Form.Item
                                name="breakMargin"
                                style={{
                                    display: "inline-block",
                                    width: "calc(60% - 5px)",
                                    marginLeft: 10
                                }}
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (value && value.enabled && !value.marginMinutes) {
                                                return Promise.reject("Please enter break time.");
                                            } else if (value && value.enabled && value.marginMinutes <= 0) {
                                                return Promise.reject("Please enter positive break time.");
                                            } else {
                                                return Promise.resolve();
                                            }
                                        }
                                    }
                                ]}>
                                <BreakBetweenAppointments />
                            </Form.Item>
                        ) : isOffsiteBased() ? (
                            <Form.Item
                                name="address"
                                style={{
                                    display: "inline-block",
                                    width: "calc(50% - 5px)",
                                    marginLeft: 10
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Where will it happen?",
                                        validateTrigger: "onSubmit"
                                    }
                                ]}>
                                <OffsiteLocationInput />
                            </Form.Item>
                        ) : (
                            <Form.Item
                                name="location"
                                style={{
                                    display: "inline-block",
                                    width: "calc(50% - 5px)",
                                    marginLeft: 10
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Where will it happen?",
                                        validateTrigger: "onSubmit"
                                    }
                                ]}>
                                <ServiceLocationSelect provider={me} placeholder="Where" />
                            </Form.Item>
                        )}
                    </Form.Item>
                    <Form.Item
                        name="languages"
                        rules={[
                            {
                                required: true,
                                type: "array",
                                message: "Please select language."
                            }
                        ]}>
                        <SearchableSelect placeholder="Language" mode="multiple">
                            {languages.map(langInfo => (
                                <Option key={langInfo.symbol} value={langInfo.symbol}>
                                    {langInfo.englishName}
                                </Option>
                            ))}
                        </SearchableSelect>
                    </Form.Item>
                    {isAppointmentBasedService() ? (
                        <Form.Item
                            name="location"
                            rules={[
                                {
                                    required: true,
                                    message: "Where will it happen?",
                                    validateTrigger: "onSubmit"
                                }
                            ]}>
                            <ServiceLocationSelect provider={me} placeholder="Where" />
                        </Form.Item>
                    ) : null}
                    <AutoDirectionProvider text={description}>
                        <Form.Item
                            name="description"
                            rules={[
                                {
                                    validateTrigger: "onSubmit",
                                    validator: (_, value) => {
                                        return EmailOrPhoneDetector.detect(value)
                                            ? Promise.reject("Emails or phone numbers are not allowed.")
                                            : Promise.resolve();
                                    }
                                }
                            ]}>
                            <FormTextArea
                                onChange={e => {
                                    e.persist();
                                    onDescriptionChange(e.target.value);
                                }}
                                autoSize={{minRows: 4, maxRows: 4}}
                                placeholder="If you have anything to add or clarify about your service, please write it here"
                            />
                        </Form.Item>
                    </AutoDirectionProvider>
                    {hasService() && serviceInfo(selectedServiceId()).serviceType === "content" ? (
                        <Form.Item name="coverPhoto" valuePropName="imageData">
                            <CoverUpload />
                        </Form.Item>
                    ) : null}
                    {hasService()
                        ? [serviceInfo(selectedServiceId())].map(({appointmentBased /*recommendedPrice*/}) => {
                              return (
                                  <div style={{paddingLeft: 10, paddingRight: 10, marginBottom: 0}}>
                                      <Form.Item
                                          name="price"
                                          valuePropName="price"
                                          rules={[
                                              {
                                                  validator: (_, price) => {
                                                      if (typeof price.value !== "number") {
                                                          return Promise.reject("Please enter price");
                                                      } else if (!price.type) {
                                                          return Promise.reject("Please select price type");
                                                      } else if (price.value > MAX_SERVICE_PRICE_NIS) {
                                                          return Promise.reject(
                                                              `Maximum allowed price is ₪${MAX_SERVICE_PRICE_NIS}.`
                                                          );
                                                      } else if (price.value < 0) {
                                                          return Promise.reject(`Please enter a positive price.`);
                                                      } else {
                                                          return Promise.resolve();
                                                      }
                                                  }
                                              }
                                          ]}>
                                          <ServicePricePicker
                                              service={serviceInfo(selectedServiceId())}
                                              // recommendedPrice={recommendedPrice}
                                          />
                                      </Form.Item>
                                      {/*<Slider style={{marginLeft: 10, marginTop: 20, marginBottom: 30}}*/}
                                      {/*step={appointmentBased ? 5 : 10}*/}
                                      {/*min={0} max={MAX_SERVICE_PRICE_NIS}*/}
                                      {/*disabled={true}*/}
                                      {/*value={values.price ? values.price.value : initialValues.price}*/}
                                      {/*// marks={{*/}
                                      {/*//     [recommendedPrice]: {*/}
                                      {/*//         style: {*/}
                                      {/*//             color: "#F7B348",*/}
                                      {/*//             fontSize: 16,*/}
                                      {/*//             fontWeight: 700,*/}
                                      {/*//             userSelect: "none"*/}
                                      {/*//         },*/}
                                      {/*//         label:*/}
                                      {/*//             <div style={{*/}
                                      {/*//                 height: 60,*/}
                                      {/*//                 display: "flex",*/}
                                      {/*//                 alignItems: "center",*/}
                                      {/*//                 justifyContent: "flex-end",*/}
                                      {/*//                 flexDirection: "column",*/}
                                      {/*//                 marginTop: 0,*/}
                                      {/*//                 fontSize: 15*/}
                                      {/*//             }}>*/}
                                      {/*//                 {`₪${recommendedPrice}`}*/}
                                      {/*//                 <br/>*/}
                                      {/*//                 <label>Market price</label>*/}
                                      {/*//             </div>*/}
                                      {/*//     }*/}
                                      {/*// }}*/}
                                      {/*/>*/}
                                  </div>
                              );
                          })[0]
                        : null}
                </div>
            </Card>
        </Form>
    );
};

const saveAll = _.throttle(
    (isAdmin, me, services, formValues, onNext, providerId, onStart, onDone, onSuccess) => {
        Promise.resolve().then(async () => {
            const supportedServices = buildSupportedServices(services, formValues);
            if (onNext) {
                return onNext(supportedServices);
            }

            onStart();
            try {
                if (!isAdmin) GoogleAnalytics.event("Professional Services", "Update", me.userId);
                await HttpClient.post(`${isAdmin ? "/admin" : ""}/api/providers/${providerId || "me"}/services`, {
                    services: supportedServices
                });
                onSuccess(_.cloneDeep(supportedServices));
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we didn't manage to save your changes :("}},
                    e.message
                );
            }
            onDone();
        });
    },
    3000,
    {trailing: false, leading: true}
);

const buildSupportedServices = (services, formValues) => {
    return Object.keys(formValues).map(formName => {
        const service = services.find(s => s.serviceId === formValues[formName].serviceId);
        return {
            description: formValues[formName].description || "",
            durationMinutes: formValues[formName].durationMinutes,
            price: formValues[formName].price.value,
            priceType: formValues[formName].price.type,
            productName: formValues[formName].productName,
            service,
            location: service.offsiteBased ? LocationLabels.OFFSITE : formValues[formName].location,
            address: formValues[formName].address || null,
            coverPhoto: formValues[formName].coverPhoto || null,
            breakMargin: formValues[formName].breakMargin || null,
            languages: formValues[formName].languages || null
        };
    });
};

export const ProviderServices = props => {
    const {me} = useContext(AppContext);
    const {
        history,
        onNext,
        profession,
        formSubmit,
        visible = true,
        onValuesChange,
        providerId,
        providerName,
        isAdmin = false
    } = props;
    const [services, setServices] = useState([]);
    const [initialValues, setInitialValues] = useState(null);
    const [initialServices, setInitialServices] = useState(null);
    const [saving, setSaving] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [formsCount, setFormsCount] = useState(1);
    const [formValues, setFormValues] = useState({});
    const [isDirty, setDirty] = useState(false);
    const [forceRender, setForceRender] = useState(1);

    if (formSubmit) {
        formSubmit.onSubmit(() => submitAll());
    }

    const forms = [];

    useEffect(() => {
        (async () => {
            await fetchAllServices();
            if (!onNext) {
                await fetchSupportedServices();
            } else {
                setInitialValues([]);
                setInitialServices([]);
            }
        })();
        return () => {
            setDirty(false);
        };
    }, []);

    const fetchAllServices = async () => {
        try {
            const professionNames = toProfessionName(profession);
            const allServices = await HttpClient.get(
                `${isAdmin ? "/admin" : ""}/api/services` +
                    (professionNames ? `/signup?profession=${professionNames}` : "")
            );

            setServices(
                allServices
                    .filter(service => !service.comingSoon)
                    .map(
                        ({
                            categoryName,
                            displayName,
                            // recommendedPrice,
                            recommendedDurationMinutes,
                            serviceId,
                            appointmentBased,
                            offsiteBased,
                            defaultPriceType,
                            serviceType,
                            boundedTo,
                            languages
                        }) => ({
                            categoryName,
                            displayName,
                            // recommendedPrice,
                            recommendedDurationMinutes,
                            serviceId,
                            appointmentBased,
                            offsiteBased,
                            defaultPriceType,
                            serviceType,
                            boundedTo,
                            languages
                        })
                    )
            );
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we didn't manage to list all supported services :("}},
                e.message
            );
        }
    };

    const fetchSupportedServices = async () => {
        try {
            const supportedServices = await HttpClient.get(
                `${isAdmin ? "/admin" : ""}/api/providers/${providerId || "me"}/services`
            );
            setInitialValues(_.cloneDeep(supportedServices || []));
            setInitialServices(_.cloneDeep(supportedServices || []));
            if (Array.isArray(supportedServices) && supportedServices.length > 0) {
                setFormsCount(supportedServices.length);
            }

            setFormValues(
                supportedServices.reduce((values, supported, idx) => {
                    values[`service-${idx}`] = {
                        description: supported.description || "",
                        durationMinutes: supported.durationMinutes,
                        price: {value: supported.price, type: supported.priceType},
                        productName: supported.productName,
                        serviceId: supported.service.serviceId,
                        location: supported.location,
                        address: supported.address,
                        coverPhoto: supported.coverPhoto,
                        breakMargin: supported.breakMargin,
                        languages: supported.languages
                    };
                    return values;
                }, {})
            );
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we didn't manage to list all your supported services :("}},
                e.message
            );
        }
    };

    const undoAll = () => {
        setDirty(false);
        setFormValues(
            Object.assign(
                {},
                initialServices.reduce((values, supported, idx) => {
                    values[`service-${idx}`] = {
                        description: supported.description || "",
                        durationMinutes: supported.durationMinutes,
                        price: {value: supported.price, type: supported.priceType},
                        productName: supported.productName,
                        serviceId: supported.service.serviceId,
                        location: supported.location,
                        address: supported.address,
                        coverPhoto: supported.coverPhoto,
                        breakMargin: supported.breakMargin,
                        languages: supported.languages
                    };
                    return values;
                }, {})
            )
        );
        setInitialValues(_.cloneDeep(initialServices));
        setFormsCount(Array.isArray(initialServices) && initialServices.length > 0 ? initialServices.length : 1);
        setForceRender(forceRender + 1);
    };

    const submitAll = () => {
        setSaving(true);
        setTimeout(() => {
            forms.forEach(form => form.validateFields());
            forms.forEach(form => form.submit());
        }, 500);
    };

    const allFormsOK = () => {
        return noErrorsInAllForms() && allFormsFilled();
    };

    const noErrorsInAllForms = () => {
        return forms.every(form => {
            const errorsList = form.getFieldsError();
            const hasErrors = errorsList.some(errObj => Array.isArray(errObj.errors) && errObj.errors.length > 0);
            return !hasErrors;
        });
    };

    const allFormsFilled = () => {
        const serviceMap = services.reduce((map, service) => {
            map[service.serviceId] = service;
            return map;
        }, {});

        return forms.every((form, idx) => {
            const notRequired = ["description", "coverPhoto"];

            const formName = `service-${idx}`;
            if (!formValues[formName]) return true;

            if (!formValues[formName].serviceId) return false;
            const service = serviceMap[formValues[formName].serviceId];
            if (service && !service.offsiteBased) {
                notRequired.push("address");
            }

            if (!formValues[formName].breakMargin || !formValues[formName].breakMargin.enabled) {
                notRequired.push("breakMargin");
            }

            return Object.keys(formValues[formName]).every(
                fieldName =>
                    !!formValues[formName][fieldName] ||
                    formValues[formName][fieldName] === 0 ||
                    notRequired.some(notRequiredField => fieldName === notRequiredField)
            );
        });
    };

    const removeService = index => {
        forms.splice(index, 1);
        delete formValues[`service-${index}`];
        setFormValues(
            Object.assign(
                {},
                Object.keys(formValues).reduce((values, name, idx) => {
                    values[`service-${idx}`] = formValues[name];
                    return values;
                }, {})
            )
        );
        initialValues.splice(index, 1);
        setFormsCount(formsCount - 1);
    };

    const scrollToId = _.throttle(
        id => {
            const rect = document.getElementById(id).getBoundingClientRect();
            window.scrollTo({left: rect.left + +window.scrollX, top: rect.top + window.scrollY - 80});
        },
        200,
        {leading: true, trailing: false}
    );

    const onFinish = fVals => {
        if (allFormsOK()) {
            saveAll(
                isAdmin,
                me,
                services,
                fVals,
                onNext,
                providerId,
                () => setSaving(true),
                () => setSaving(false),
                supportedServices => {
                    if (isAdmin) {
                        message.success("Changes saved successfully!");
                    } else {
                        setShowSuccessModal(true);
                    }
                    setInitialValues(supportedServices);
                    setInitialServices(supportedServices);
                    setDirty(false);
                }
            );
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                marginTop: 20,
                marginBottom: 40,
                visibility: visible ? "visible" : "hidden"
            }}>
            <ChangesNotSavedModal
                history={history}
                changesMade={isDirty && !onNext}
                onSave={() => onFinish(formValues)}
            />
            {!onNext ? (
                <>
                    <PageTitleHeader style={{marginBottom: 40}} showBack={isAdmin}>
                        {isAdmin ? `${providerName} services` : "Add/Edit your services"}
                    </PageTitleHeader>
                    <ProviderChangesSavedModal
                        visible={showSuccessModal}
                        history={history}
                        onClose={() => setShowSuccessModal(false)}
                    />
                </>
            ) : null}
            {initialValues && !_.isEmpty(formValues) && !_.isEmpty(services) ? (
                <Form.Provider
                    onFormFinish={(name, {values}) => {
                        const fVals = Object.assign({}, formValues, {[name]: values});
                        setFormValues(fVals);
                        onFinish(fVals);
                    }}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        maxWidth: 600,
                        width: "100%",
                        marginBottom: 60
                    }}>
                    {_.times(formsCount, Number).map(index => {
                        return (
                            <ServiceForm
                                key={`service-${index}-${forceRender}`}
                                me={me}
                                servicesCount={formsCount}
                                onRemove={() => removeService(index)}
                                onFormCreated={form => forms.push(form)}
                                initialValues={
                                    Array.isArray(initialValues) && initialValues.length > 0
                                        ? initialValues[index] || {}
                                        : {}
                                }
                                values={formValues[`service-${index}`]}
                                onValuesChange={values => {
                                    if (onValuesChange) onValuesChange(values);
                                    setDirty(true);
                                    setFormValues(Object.assign({}, formValues, {[`service-${index}`]: values}));
                                }}
                                services={services}
                                index={index}
                                onFormFailed={() => {
                                    setSaving(false);
                                    scrollToId(`service-${index}`);
                                }}
                            />
                        );
                    })}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: onNext ? 0 : 50
                        }}>
                        <Button
                            style={{fontSize: 16, marginTop: -20}}
                            type="link"
                            onClick={() => setFormsCount(formsCount + 1)}>
                            + Add another service
                        </Button>
                    </div>
                    {onNext ? (
                        <ContactUsPhone />
                    ) : (
                        <Form.Item noStyle>
                            <FormCancelSaveBottomBar
                                onCancel={() => undoAll()}
                                onSave={() => submitAll()}
                                loading={saving}
                            />
                        </Form.Item>
                    )}
                </Form.Provider>
            ) : (
                <Spin style={{marginTop: 80}} size="large" />
            )}
        </div>
    );
};
