import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import copyToClipboard from "copy-to-clipboard";
import {Button, ConfigProvider, message, Select} from "antd";
import {AdminProductsSettingModal} from "./AdminProductsSettingModal";
import {AdminProductsSubCategoriesModal} from "./AdminProductsSubCategoriesModal";
import {AdminProductsBase} from "./AdminProductsBase";
import {columns} from "./AdminProductColumns";
import {AdminEditTagsModal} from "./AdminEditTagsModal";
import {SearchableSelect} from "../../components";
import PageTitleHeader from "../../components/PageTitle";
import {DraggableBodyRow, DraggableTable} from "../../components/dragableTable";
import {HttpClient} from "../../http/HttpClient";
import {useRequest} from "../../utils/hooks";
import {AdminBannersEdit} from "../components";
import {AdminUploadNewProductModal} from "../upload/AdminUploadNewProductModal";
import {AppContext} from "../../AppContext";
import "./admin-products.css";

const {Option} = Select;

export const DEFAULT_PAGE = 60;

export const AdminProductsOld = () => {
    const {me} = useContext(AppContext);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [currentSubCategory, setCurrentSubCategory] = useState(null);
    const [allProducts, setAllProducts] = useState([]);
    const [currentSubCategoryItems, setCurrentSubCategoryItems] = useState([]);
    const [settingModalVisibility, setSettingModalVisibility] = useState(false);
    const [categoryBanners, setCategoriesBanners] = useState([]);
    const [currentProductIdForBanner, setCurrentProductIdForBanner] = useState(null);
    const [isProductEditModalVisible, setIsProductEditModalVisible] = useState(false);
    const [currentSelectedProduct, setCurrentSelectedProduct] = useState(null);
    const [currentSelectedProvider, setCurrentSelectedProvider] = useState(null);
    const [subCategoriesList, setSubCategoriesList] = useState([]);
    const [currentTablePage, setCurrentTablePage] = useState(0);
    const [editProductSearchTags, setEditProductsSearchTags] = useState(null);

    const [extraFilters, setExtraFilters] = useState({});

    const [productEditSubCategoriesModalData, setProductEditSubCategoriesModalData] = useState(null);

    const [allSearchTags = [], loadSearchTags, _, fetchSearchTags] = useRequest("/admin/api/searchTags");
    const [autoGeneratedSubs] = useRequest("/api/products/allAutoGeneratedSubCategories");
    const [categories, categoriesLoading, errorInCategoryFetch, fetchCategories] = useRequest(
        "/api/v2/categories",
        "post",
        {}
    );
    const [allProductsFromServer, isAllProductsLoading, errorInAllProducts] = useRequest(
        `/api/products/getProducts?onlyTableData=true`,
        "GET",
        null,
        []
    );

    const allProductsIds = useMemo(
        () => allProductsFromServer?.products?.map(({productId}) => productId),
        [allProductsFromServer]
    );

    const [productsToSubCategories] = useRequest(
        "/admin/api/products/bySubCategories",
        "POST",
        allProductsIds,
        [allProductsFromServer],
        !!allProductsFromServer?.length
    );
    const [innerProductsToSubCategories, setInnerProductsToSubCategories] = useState(null);

    useEffect(() => {
        setInnerProductsToSubCategories(productsToSubCategories);
    }, [productsToSubCategories]);

    const editProduct = useCallback(async selectedProduct => {
        const productsSearchTags = await HttpClient.post("/admin/api/searchTags/byProducts", [
            selectedProduct.productId
        ]);
        const {service: product} = await HttpClient.safeGet(`/api/products/byId/${selectedProduct.productId}`);
        const providerDetails = await HttpClient.safeGet(`/admin/api/providers/${selectedProduct.providerId}`);

        const productWithSearchTags = {
            ...selectedProduct,
            ...product,
            searchTags: productsSearchTags[selectedProduct.productId] ?? [],
            serviceIds: selectedProduct.services
        };

        setCurrentSelectedProduct(productWithSearchTags);
        setCurrentSelectedProvider(providerDetails);
        setIsProductEditModalVisible(true);
    }, []);

    const editProductStatus = useCallback(async (providerId, productId, status) => {
        const {error} = await HttpClient.safePost(
            `/admin/api/provider/${providerId}/updateProductStatus/${productId}`,
            {status}
        );

        if (error) {
            message.error("עידכון מצב המוצר נכשל");
        } else {
            message.success("מצב המוצר עודכן בהצלחה");

            setCurrentSubCategoryItems(currentSubCategoryItems =>
                currentSubCategoryItems.map(item => {
                    if (item.productId === productId) return {...item, isActive: status};
                    return item;
                })
            );

            setAllProducts(allProducts =>
                allProducts.map(item => {
                    if (item.productId === productId) return {...item, isActive: status};
                    return item;
                })
            );
        }
    }, []);

    const editProductTags = useCallback(async product => {
        const productsSearchTags = await HttpClient.post("/admin/api/searchTags/byProducts", [product.productId]);

        setEditProductsSearchTags({
            searchTags: productsSearchTags[product.productId],
            productId: product.productId,
            providerId: product.providerId
        });
    }, []);

    const deleteProduct = useCallback(async (providerId, productId) => {
        const {error} = await HttpClient.safeDelete(`/admin/api/provider/${providerId}/products/${productId}`);

        if (error) {
            message.error("אירעה שגיאה במחיקת המוצר");
        } else {
            message.success("המוצר נמחק בהצלחה");
            setAllProducts(currentProducts => currentProducts.filter(p => p.productId !== productId));
            setCurrentSubCategoryItems(currentSubCategoryProducts =>
                currentSubCategoryProducts.filter(p => p.productId !== productId)
            );
        }
    }, []);

    const categoriesWithSubCategories = useMemo(
        () => categories?.filter(({subCategories}) => subCategories?.length),
        [categories]
    );

    const onSaveBanners = useCallback(
        async newBanners => {
            const {error} = await HttpClient.safePost(
                `/admin/api/services/${currentCategory}/updateBanners`,
                newBanners
            );

            if (error) {
                message.error("קרתה שגיאה בשמירת המידע");
                return;
            }

            message.success("באנרים עודכנו בהצלחה");
        },
        [currentCategory]
    );

    const getProductsBySubCategories = useCallback(async subCategoryId => {
        return await HttpClient.safeGet(`/api/products/getProductIdsBySubCategory/${subCategoryId}`);
    }, []);

    const onMoveToEdgeClicked = useCallback((product, toTop) => {
        setCurrentSubCategoryItems(prevOrder => {
            const newOrder = [...prevOrder];
            const itemToMoveIndex = newOrder.findIndex(({productId}) => productId === product.productId);
            newOrder.splice(itemToMoveIndex, 1);
            return toTop ? [product, ...newOrder] : [...newOrder, product];
        });
    }, []);

    const onSortEnd = useCallback(({oldIndex, newIndex}) => {
        setCurrentSubCategoryItems(prevProductOrder => {
            const newOrder = [...prevProductOrder];
            const [movedItem] = newOrder.splice(oldIndex, 1);
            newOrder.splice(newIndex, 0, movedItem);
            return newOrder;
        });
    }, []);

    const onSave = useCallback(async () => {
        if (currentCategory && currentSubCategory && currentSubCategoryItems.length) {
            const result = await HttpClient.safePost(`/api/products/updateSubCategoryToContent`, {
                subCategory: currentSubCategory,
                serviceId: currentCategory,
                products: currentSubCategoryItems.map(({productId}) => productId)
            });

            if (result.error) {
                message.error("שמירה נכשלה", 5);
                return;
            }

            if (result.success) {
                message.success("נשמר בהצלחה", 5);
            }
        }
    }, [currentCategory, currentSubCategory, currentSubCategoryItems]);

    const copyProductLinkToClipboard = useCallback((serviceId, providerId, productId) => {
        const link = `${window.location.origin}/dashboard/services/${serviceId}/providersV2/${providerId}/${productId}`;
        copyToClipboard(link);
    }, []);

    const openLinkInNewTab = useCallback((serviceId, providerId, productId) => {
        const link = `${window.location.origin}/dashboard/services/${serviceId}/providersV2/${providerId}/${productId}`;
        window.open(link, "_blank");
    }, []);

    const allCategoryProducts = useMemo(
        () => allProducts.filter(product => product.services.includes(currentCategory)),
        [allProducts, currentCategory]
    );

    const newBannerFromProduct = useMemo(() => {
        if (!currentProductIdForBanner) {
            return null;
        }

        const currentItem = allCategoryProducts.find(({productId}) => productId === currentProductIdForBanner);

        return currentItem
            ? {
                  name: currentItem.productName,
                  text: currentItem.productName,
                  link: `/dashboard/services/${currentItem?.services?.[0]}/providersV2/${currentItem.providerId}/${currentItem.productId}`
              }
            : null;
    }, [currentProductIdForBanner, allCategoryProducts]);

    const onEditProductSubCategories = useCallback(
        (productId, subCategories, productServiceIds) => {
            const services = categories.filter(
                ({serviceId, subCategories}) => subCategories && productServiceIds?.includes(serviceId)
            );

            setProductEditSubCategoriesModalData({
                productId,
                services,
                subCategories
            });
        },
        [categories]
    );

    const onEditProduct = useCallback(
        async updatedProduct => {
            const {provider, productId, serviceIds, ...restProduct} = updatedProduct;
            const res = await HttpClient.safePut(
                `/admin/api/provider/${updatedProduct?.providerId}/products/${productId}`,
                {
                    product: {...restProduct, productId}
                }
            );

            if (res?.error) {
                message.error("נכשל בעת עדכון המוצר");
            } else {
                message.success("עודכן בהצלחה");

                const newProducts = allProducts.map(product => {
                    if (product.productId === productId) return updatedProduct;
                    return product;
                });

                setAllProducts(newProducts);
            }
            setIsProductEditModalVisible(false);
            setCurrentSelectedProduct(null);
        },
        [allProducts]
    );

    useEffect(() => {
        if (allProductsFromServer?.products) {
            setAllProducts(allProductsFromServer.products);
        }
    }, [allProductsFromServer?.products]);

    useEffect(() => {
        if (currentCategory === "*") {
            setCurrentSubCategoryItems(allProducts);
            setCurrentSubCategory(null);
        } else {
            const subCategories = categories?.find(category => category.serviceId === currentCategory)?.subCategories;
            setSubCategoriesList(subCategories);
        }
    }, [currentCategory, allProducts, categories]);

    useEffect(() => {
        async function fetchProductsBySubCategories() {
            if (currentSubCategory) {
                if (currentSubCategory === "*") {
                    const categoryProducts = allProducts.filter(({services}) => services.includes(currentCategory));
                    setCurrentSubCategoryItems(categoryProducts);
                } else {
                    const subCategoryId = subCategoriesList.find(
                        subCategory => subCategory.name === currentSubCategory
                    )?.id;
                    const sortedProducts = await getProductsBySubCategories(subCategoryId);
                    const tableItems =
                        sortedProducts?.products
                            ?.map(productId => allProducts.find(product => product.productId === productId))
                            .filter(product => product) ?? [];
                    setCurrentSubCategoryItems(tableItems);
                }
            }
        }

        fetchProductsBySubCategories();
    }, [currentSubCategory]);

    useEffect(() => {
        if (currentCategory && currentCategory !== "*") {
            const banners = categoriesWithSubCategories.find(({serviceId}) => serviceId === currentCategory)?.banners;
            setCategoriesBanners(banners ?? []);
        } else {
            setCategoriesBanners([]);
        }
    }, [categoriesWithSubCategories, currentCategory]);

    return (
        <div className="admin-product">
            <ConfigProvider direction="rtl">
                <AdminProductsSubCategoriesModal
                    visible={!!productEditSubCategoriesModalData}
                    productSubCategories={productEditSubCategoriesModalData?.subCategories}
                    productServices={productEditSubCategoriesModalData?.services}
                    productId={productEditSubCategoriesModalData?.productId}
                    onClose={() => setProductEditSubCategoriesModalData(false)}
                    onFinishEditProductSubCategories={newSubCategories => {
                        const {productId} = productEditSubCategoriesModalData;

                        setInnerProductsToSubCategories(prev => ({
                            ...prev,
                            [productId]: newSubCategories
                        }));

                        setProductEditSubCategoriesModalData(false);
                    }}
                />

                <AdminEditTagsModal
                    visible={!!editProductSearchTags}
                    onClose={() => setEditProductsSearchTags(null)}
                    onCancel={() => setEditProductsSearchTags(null)}
                    productId={editProductSearchTags?.productId}
                    editProductSearchTags={editProductSearchTags?.searchTags}
                    onEditProduct={async searchTags => {
                        const {error} = await HttpClient.put(
                            `/admin/api/provider/${editProductSearchTags.providerId}/updateProductTags/${editProductSearchTags.productId}`,
                            {searchTags}
                        );

                        if (error) {
                            message.error("שמירת תגיות המוצר נכשלה");
                        } else {
                            message.success("תגיות המוצר נשמרו בהצלחה");
                        }
                        setEditProductsSearchTags(null);
                    }}
                    allSearchTags={allSearchTags}
                />

                <AdminUploadNewProductModal
                    visibility={isProductEditModalVisible}
                    onClose={() => setIsProductEditModalVisible(false)}
                    onCancel={() => setIsProductEditModalVisible(false)}
                    editProduct={currentSelectedProduct}
                    providerDetails={currentSelectedProvider}
                    providerSubProducts={currentSelectedProduct?.provider?.subProducts ?? []}
                    services={categories}
                    onEditProduct={onEditProduct}
                />

                <AdminProductsSettingModal
                    autoGeneratedCategories={autoGeneratedSubs}
                    serviceId={currentCategory}
                    visible={settingModalVisibility}
                    onCancel={() => {
                        setSettingModalVisibility(false);
                    }}
                    onClose={() => setSettingModalVisibility(false)}
                    onFinish={() => {
                        message.success("!קטגוריה התעדכנה בהצלחה", 5);
                        setSettingModalVisibility(false);
                    }}
                />

                <PageTitleHeader showBack={false}>מוצרים</PageTitleHeader>
                <AdminProductsBase
                    extraFilters={extraFilters}
                    setExtraFilters={setExtraFilters}
                    setCurrentTablePage={setCurrentTablePage}
                    currentCategory={currentCategory}
                    setCurrentCategory={setCurrentCategory}
                    currentSubCategory={currentSubCategory}
                    setCurrentSubCategory={setCurrentSubCategory}
                    currentSubCategoryItems={currentSubCategoryItems}
                    setCurrentSubCategoryItems={setCurrentSubCategoryItems}
                    loadingTable={isAllProductsLoading}
                    topBarButtons={
                        <>
                            <Button
                                className="admin-product-search-button"
                                disabled={
                                    !currentCategory ||
                                    !currentSubCategory ||
                                    !currentSubCategoryItems.length ||
                                    currentSubCategory === "*"
                                }
                                onClick={onSave}>
                                שמור תת קטגוריה
                            </Button>
                            <Button
                                className="admin-product-search-button"
                                disabled={!currentCategory || currentCategory === "*"}
                                onClick={() => setSettingModalVisibility(true)}>
                                הגדרות קטגוריה
                            </Button>
                        </>
                    }
                    columns={columns(
                        currentTablePage,
                        onMoveToEdgeClicked,
                        (serviceId, providerId, productId) =>
                            copyProductLinkToClipboard(serviceId, providerId, productId),
                        (serviceId, providerId, productId) => openLinkInNewTab(serviceId, providerId, productId),
                        innerProductsToSubCategories,
                        currentSubCategory,
                        currentCategory,
                        (providerId, productId) => deleteProduct(providerId, productId),
                        editProduct,
                        product => editProductTags(product),
                        me,
                        (providerId, productId, newStatus) => editProductStatus(providerId, productId, newStatus),
                        onEditProductSubCategories
                    )}
                    tableProps={{
                        components: {
                            body: {
                                wrapper: props => <DraggableTable onSortEnd={onSortEnd} {...props} />,
                                row: props => (
                                    <DraggableBodyRow
                                        dataSource={currentSubCategoryItems}
                                        rowKey="productId"
                                        {...props}
                                    />
                                )
                            }
                        }
                    }}
                />
                <div className="admin-products-carousel-container">
                    <span>באנרים מוצרים</span>
                    <AdminBannersEdit
                        banners={categoryBanners}
                        defaultNewBanner={{
                            buttonText: "לפרטי המוצר",
                            ...(newBannerFromProduct ?? {})
                        }}
                        onSave={onSaveBanners}
                        disabled={!currentCategory}
                        otherButtons={
                            <SearchableSelect
                                value={currentProductIdForBanner}
                                onSelect={setCurrentProductIdForBanner}
                                style={{minWidth: 180}}
                                placeholder="צור באנר על פי מוצר קיים">
                                {allCategoryProducts?.map(({productId, productName}) => (
                                    <Option key={productId} value={productId}>
                                        {productName}
                                    </Option>
                                ))}
                            </SearchableSelect>
                        }
                    />
                </div>
            </ConfigProvider>
        </div>
    );
};
