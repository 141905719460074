import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Route, Switch} from "react-router-dom";
import moment from "moment";
import {get, set, sumBy} from "lodash";
import {ConfigProvider, Input, Popover} from "antd";
import {EditOutlined, FileFilled, FormOutlined} from "@ant-design/icons";
import {getCategoriesSummary} from "./utils";
import {ForceOccasionPolicyTypes, HappinessCalculatorRoutes} from "./consts";
import {HappinessCalculatorContext} from "./HappinessCalculatorContext";
import {PlanEditSettingsModal} from "./calculatorStepper/PlanEditSettingsModal";
import {HappinessCalculatorPlanPage} from "./HappinessCalculatorPlanPage";
import {HappinessCalculatorSummaryPage} from "./calculatorSummaryPage";
import {EditSettingsModalTypes} from "./calculatorStepper/consts";
import {getLogger} from "../Logger";
import {AppContext} from "../AppContext";
import {SquareButton} from "../components";
import {useRequest} from "../utils/hooks";
import {Images} from "../images/Images";
import {momentHebrewFormat} from "../utils/DateFormat";
import "./happiness-calculator.css";

const {CalculatorBG} = Images;

export const HappinessCalculatorBasePage = ({history, match}) => {
    const {company} = useContext(AppContext);
    const {planTimeRange, planOccasionsCategories} = useContext(HappinessCalculatorContext);
    const [openCategories, setOpenCategories] = useState([]);

    const [tableData, setTableData] = useState();
    const [editSettingsModalToShow, setEditSettingsModalToShow] = useState(null);
    const [employeesAmount, setEmployeesAmount] = useState(parseInt(company?.employeesCount) || 100);
    const userActions = useRef([]);
    const undoUserActions = useRef([]);

    const {planId} = useMemo(() => match.params, [match.params]);

    const timeRanges = useMemo(
        () =>
            planTimeRange ? [moment(planTimeRange[0]).startOf("month"), moment(planTimeRange[1]).endOf("month")] : null,
        [planTimeRange]
    );

    const relevantTableData = useMemo(() => {
        if (!tableData) {
            return {};
        }

        return Object.entries(tableData).reduce((acc, [categoryKey, currentCategory]) => {
            const transformedCategory = {
                ...currentCategory,
                occasionCategoryId: categoryKey
            };

            if (transformedCategory.forceOccasionPolicy) {
                transformedCategory.isRangePolicy =
                    currentCategory.forceOccasionPolicy === ForceOccasionPolicyTypes.RANGE_POLICY;
            }

            if (transformedCategory.isRangePolicy) {
                transformedCategory.occasions = currentCategory?.recurringOccasions ?? [];
            }

            return {
                ...acc,
                [categoryKey]: transformedCategory
            };
        }, {});
    }, [tableData]);

    useEffect(() => {
        if (company?.employeesCount) {
            setEmployeesAmount(parseInt(company?.employeesCount) || 100);
        }
    }, [company?.employeesCount]);

    const monthAmounts = useMemo(
        () => (timeRanges[0] ? Math.round(timeRanges[1].diff(timeRanges[0], "month", true)) : 0),
        [timeRanges]
    );

    const categoriesWithTotalPrice = useMemo(
        () => getCategoriesSummary(relevantTableData, employeesAmount, monthAmounts),
        [relevantTableData, employeesAmount, monthAmounts]
    );

    const allCategoriesPrice = useMemo(() => sumBy(categoriesWithTotalPrice, "totalPrice"), [categoriesWithTotalPrice]);

    const queryParams = useMemo(
        () => `?startDate=${timeRanges[0]?.valueOf()}&endDate=${timeRanges[1]?.valueOf()}`,
        [timeRanges]
    );

    const log = useMemo(
        () =>
            getLogger(
                {
                    employeesAmount,
                    timeRange: {
                        from: timeRanges?.[0]?.format("MM/YY"),
                        to: timeRanges?.[1]?.format("MM/YY")
                    }
                },
                "HappinessCalculator"
            ),
        [timeRanges, employeesAmount]
    );

    const [categoriesData, loadingCategoriesData] = useRequest(
        `/api/occasions/categoriesByDates${queryParams}`,
        "POST",
        {
            categories: openCategories
        },
        [openCategories],
        openCategories?.length && timeRanges?.length
    );

    const userActionsButtonsClicked = useCallback(
        redo => {
            const [fromUserActions, toUsersActionsArray] = redo
                ? [undoUserActions, userActions]
                : [userActions, undoUserActions];

            if (!fromUserActions.current.length) {
                return;
            }

            const actionToUndo = fromUserActions.current.pop();

            log(`${redo ? "Redo" : "Undo"} Button Clicked`);

            setTableData(prev => {
                const currentValue = get(prev, actionToUndo.path);
                toUsersActionsArray.current.push({path: actionToUndo.path, value: currentValue});
                return set({...prev}, actionToUndo.path, actionToUndo.value);
            });
        },
        [userActions.current, undoUserActions.current, log]
    );

    const [savedPlan, loadingSavedPlan] = useRequest(`/api/budgetPlan/byId/${planId}`, "GET", null, [], !!planId);

    return (
        <div className="happiness-calculator" style={{backgroundImage: `url(${CalculatorBG})`}}>
            <ConfigProvider direction="rtl">
                <PlanEditSettingsModal
                    history={history}
                    periods={timeRanges}
                    modalToShow={editSettingsModalToShow}
                    categories={planOccasionsCategories}
                    onClose={() => setEditSettingsModalToShow(null)}
                />
                <div className="happiness-calculator-body">
                    <div className="happiness-calculator-top-bar">
                        <div className="happiness-calculator-top-bar-right-side">
                            <div>
                                <span>תקופת תקציב</span>
                                <div
                                    onClick={() => {
                                        log("Change Plan Time Clicked");
                                        setEditSettingsModalToShow(EditSettingsModalTypes.TimeEdit);
                                    }}>
                                    {`${momentHebrewFormat(timeRanges[0], "MMMM YYYY")} - ${momentHebrewFormat(
                                        timeRanges[1],
                                        "MMMM YYYY"
                                    )}`}
                                    <EditOutlined />
                                </div>
                            </div>
                            <div>
                                <span>מס׳ עובדים</span>
                                <Popover
                                    trigger="click"
                                    content={
                                        <Input
                                            value={employeesAmount}
                                            onChange={({target}) => {
                                                log("Change Employee Number", {employeesAmount: target.value});
                                                setEmployeesAmount(target.value);
                                            }}
                                        />
                                    }>
                                    <div>
                                        {employeesAmount}
                                        <EditOutlined />
                                    </div>
                                </Popover>
                            </div>
                            <div>
                                <span>סעיפי תקציב של התכונית</span>
                                <div onClick={() => setEditSettingsModalToShow(EditSettingsModalTypes.CategoriesEdit)}>
                                    {`${planOccasionsCategories.length} סעיפי תקציב`}
                                    <EditOutlined />
                                </div>
                            </div>
                        </div>
                        <div className="happiness-calculator-top-bar-left-side">
                            {!history.location.pathname.includes("summary") ? (
                                <>
                                    <div
                                        onClick={() => userActionsButtonsClicked(true)}
                                        className="happiness-calculator-top-bar-redo">
                                        <img alt="redo" src={Images.undo} />
                                    </div>
                                    <div
                                        onClick={() => userActionsButtonsClicked(false)}
                                        className="happiness-calculator-top-bar-undo">
                                        <img alt="undo" src={Images.undo} />
                                    </div>
                                    <SquareButton
                                        onClick={() => {
                                            log("Plan Summary Button Clicked");
                                            history.push(`${HappinessCalculatorRoutes.SUMMARY}/${planId ?? ""}`);
                                        }}>
                                        <FileFilled />
                                        סיכום תכנון
                                    </SquareButton>
                                </>
                            ) : (
                                <>
                                    <SquareButton
                                        onClick={() =>
                                            history.push(`${HappinessCalculatorRoutes.PLAN}/${planId ?? ""}`)
                                        }>
                                        <FormOutlined />
                                        חזרה לתיכנון
                                    </SquareButton>
                                </>
                            )}
                        </div>
                    </div>

                    <Switch>
                        <Route exact path={`${HappinessCalculatorRoutes.SUMMARY}/:planId?`}>
                            <HappinessCalculatorSummaryPage
                                timeRanges={timeRanges}
                                employeesAmount={employeesAmount}
                                allCategoriesPrice={allCategoriesPrice}
                                categoriesWithTotalPrice={categoriesWithTotalPrice}
                                monthAmounts={monthAmounts}
                            />
                        </Route>

                        <Route
                            exact
                            path={`${HappinessCalculatorRoutes.PLAN}/:planId?`}
                            render={props => (
                                <HappinessCalculatorPlanPage
                                    {...props}
                                    planId={planId}
                                    savedPlan={savedPlan}
                                    loadingSavedPlan={loadingSavedPlan}
                                    tableData={relevantTableData}
                                    setTableData={setTableData}
                                    timeRanges={timeRanges}
                                    employeesAmount={employeesAmount}
                                    setEmployeesAmount={setEmployeesAmount}
                                    userActions={userActions}
                                    setEditSettingsModalToShow={setEditSettingsModalToShow}
                                    categoriesWithTotalPrice={categoriesWithTotalPrice}
                                    allCategoriesPrice={allCategoriesPrice}
                                    monthAmounts={monthAmounts}
                                    loadingCategoriesData={loadingCategoriesData}
                                    setOpenCategories={setOpenCategories}
                                    categoriesData={categoriesData}
                                    log={log}
                                />
                            )}
                        />
                    </Switch>
                </div>
            </ConfigProvider>
        </div>
    );
};
