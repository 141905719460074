import React, {createContext, useCallback, useContext, useState} from "react";
import moment from "moment-timezone";
import {openWindow} from "../../http/WindowOpener.jsx";
import {HttpClient} from "../../http/HttpClient.jsx";
import {EventBus} from "../../bus/EventBus.jsx";
import {GenericModal} from "../../components/index.jsx";
import {GoogleAnalytics} from "../../GoogleAnalytics.jsx";
import {OrderShortcutFilterValues} from "./consts.js";
import {isBoolean} from "lodash";
import {isTomorrowDate} from "./utils.js";
import {TableDateSearchContext} from "../../admin/Context/TableDateSearchContext.jsx";
import {Images} from "../../images/Images.jsx";
import {ProviderContext} from "../ProviderContext.jsx";
import {message} from "antd";
import {useProviderOrders} from "./ProviderOrdersHook.jsx";

export const ProviderOrderContext = createContext({});

export const ProviderOrderContextProvider = ({children}) => {
    const {log} = useContext(ProviderContext);
    const {onClearDebouncedSearchText} = useContext(TableDateSearchContext);

    const {
        searchDate,
        searchInput,
        loadingOrders,
        fetchOrders,
        setSearchInput,
        setSearchDate,
        setSearchByCreated,
        setSearchStatuses,
        filteredOrders: orders,
        searchByCreated,
        setInnerOrders
    } = useProviderOrders();

    const [eventsQueue, setEventsQueue] = useState([]);
    const [eventActionModal, setEventActionModal] = useState(null);
    const [eventActionModalText, setEventActionModalText] = useState(null);
    const [selectedOption, setSelectedOption] = useState("");

    const onDetails = useCallback(eventId => openWindow(`/events/${eventId}/proposalV2`, "_blank", true), []);

    const acceptOffer = useCallback(
        async acceptingEvent => {
            setEventsQueue(currentEvents => [...currentEvents, acceptingEvent.eventId]);
            const {error, status} = await HttpClient.safePost(`/api/events/${acceptingEvent.eventId}/acceptV2`, {
                updatedAt: acceptingEvent.updatedAt
            });

            if (status >= 400) {
                const errorMessage = `Failed to accept event ${acceptingEvent.eventId}. Please contact support.`;
                EventBus.triggerError("server-error", {content: {description: errorMessage}}, error.message);
            } else if (error) {
                setEventActionModalText(error);
                setEventActionModal(acceptingEvent);
            } else {
                message.success("הזמנה אושרה בהצלחה ועברה להזמנות מאושרות");
                fetchOrders();
                GoogleAnalytics.event("Session Proposal", "Accept", acceptingEvent.eventId);
            }

            setEventsQueue(currentEvents =>
                currentEvents.filter(queueEventId => queueEventId !== acceptingEvent.eventId)
            );
        },
        [fetchOrders]
    );

    const onSearchByCreatedAtChange = useCallback(() => {
        if (selectedOption === OrderShortcutFilterValues.SUPPLY_TOMORROW) {
            setSelectedOption(null);
            setSearchStatuses(null);
        }
        setSearchByCreated(prevState => !prevState);
    }, [selectedOption, setSearchByCreated, setSearchStatuses]);

    const onShortcutClick = useCallback(
        option => {
            const transformOption = {
                value: option ? option?.value : null,
                statuses: option?.statuses?.length ? option?.statuses : null,
                searchByCreated: isBoolean(option?.searchByCreated) ? option?.searchByCreated : true,
                datePicked: option?.datePicked
                    ? option?.datePicked()
                    : [moment().startOf("month"), moment().endOf("month")]
            };

            setSelectedOption(transformOption.value);
            setSearchStatuses(transformOption.statuses);
            setSearchByCreated(transformOption.searchByCreated);
            setSearchDate(transformOption.datePicked);
            onClearDebouncedSearchText();
            log("provider click on order shortcut button", {option: transformOption});
        },
        [log, onClearDebouncedSearchText, setSearchByCreated, setSearchDate, setSearchStatuses]
    );

    const onDatePicker = useCallback(
        date => {
            setSearchDate(date);

            if (selectedOption !== OrderShortcutFilterValues.SUPPLY_TOMORROW) {
                return;
            }

            const isSearchDateTomorrow = isTomorrowDate(searchDate);
            if (!isSearchDateTomorrow) {
                setSelectedOption(null);
                setSearchStatuses(null);
            }
        },
        [searchDate, selectedOption, setSearchDate, setSearchStatuses]
    );

    const onDelivered = useCallback(
        async eventId => {
            const deliveredEvent = await HttpClient.safePost(`/api/events/${eventId}/delivered`);
            setInnerOrders(prev => prev.map(order => (order.eventId === eventId ? deliveredEvent : order)));
        },
        [setInnerOrders]
    );

    return (
        <ProviderOrderContext.Provider
            value={{
                onDetails,
                acceptOffer,
                orders,
                onSearchByCreatedAtChange,
                onShortcutClick,
                onDatePicker,
                eventsQueue,
                setSearchInput,
                searchInput,
                selectedOption,
                searchByCreated,
                searchDate,
                loadingOrders,
                fetchOrders,
                onDelivered
            }}>
            {children}

            <GenericModal
                title="שימו לב!"
                className="admin-coupons-are-you-sure-modal"
                text={[eventActionModalText]}
                image={Images.areYouSure}
                closeable={true}
                visible={!!eventActionModal}
                onClose={() => setEventActionModal(null)}
                options={[
                    {
                        id: "refresh",
                        onClick: () => {
                            window.location.reload();
                            setEventActionModal(null);
                            setEventActionModalText(null);
                        },
                        text: "רענן"
                    }
                ]}
            />
        </ProviderOrderContext.Provider>
    );
};
