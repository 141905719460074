import React, {useContext} from "react";
import {AppContext} from "../../AppContext";
import {message} from "antd";
import {HttpClient} from "../../http/HttpClient";
import {KeyIcon} from "../../icons";
import {GoogleAnalytics} from "../../GoogleAnalytics";
import {EXCenterContext} from "../EXCenterContext";

export const EXCenterProfileSettingsAdditionalOptions = ({style}) => {
    const {theme} = useContext(EXCenterContext);
    const {me} = useContext(AppContext);

    const resetPassword = async () => {
        try {
            await HttpClient.post("/api/users/password/forgot", {email: me.email || me.contact});
            message.success("Please check your email for further instructions.", 3);
            GoogleAnalytics.event("EX Center - Profile Settings", "Forgot Password", me.userId);
        } catch (e) {
            message.error("יש בעיה עם כתובת המייל שסיפקת, אנא בדוק שהמייל תקין ורשום במערכת", 5);
        }
    };

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", ...style}}>
            <span style={{color: theme.textColor, fontSize: 28, fontWeight: 700, marginBottom: 30}}>
                Additional options
            </span>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    maxWidth: 800,
                    height: 85,
                    borderTop: "1px solid #D3D8E0",
                    borderBottom: "1px solid #D3D8E0",
                    fontSize: 18,
                    fontWeight: 400,
                    width: "100%",
                    paddingLeft: 10,
                    paddingRight: 10
                }}>
                <span>
                    To send a reset password email{" "}
                    <a style={{color: "#005DD9"}} onClick={resetPassword}>
                        click here
                    </a>
                </span>
                <KeyIcon />
            </div>
        </div>
    );
};
