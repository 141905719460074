import React, {useState} from "react";
import "../wizard.css";
import {WelcomeStep} from "../components/WelcomeStep";
import {ProviderBasicInfoStep} from "./steps/ProviderBasicInfoStep";
import {ProviderProfessionalStep} from "./steps/ProviderProfessionalStep";
import {ProviderServiceCenterStep} from "./steps/ProviderServicesCenterStep";
import {StepperHeader} from "../components/StepperHeader";
import {ProviderStepperSignUpClient} from "./ProviderStepperSignUpClient";
import {Steps} from "./ProviderWizardSteps";
import providerWelcome from "../images/provider-welcome.png";
import {MakeTheMagicHappenStep} from "../companyStepper/steps/MakeTheMagicStep";
import {message} from "antd";

const STEPS_COUNT = 2;

const providerWelcomeInfo = {
    backgroundImage: providerWelcome,
    title: "Join us and make earning easy.",
    subtitle: "Answer a few questions to help us get the right clients for you.",
    canContinueColor: "#ED7FA6",
    containerWidth: "800px"
};

export const ProviderSignUpStepper = ({history, match}) => {
    const {step} = match.params;
    const [currentStep, setCurrentStep] = useState(Steps.WELCOME);
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);
    let [providerInfoBySteps, setProviderInfoBySteps] = useState(null);

    if (currentStep === -1 && step && currentStep !== parseInt(step, 10)) {
        return (window.location.href = "/provider/wizard");
    }

    const nextStep = info => {
        if (currentStep >= 0) {
            providerInfoBySteps = providerInfoBySteps || {};
            providerInfoBySteps = {
                ...providerInfoBySteps,
                [step]: info
            };
            setProviderInfoBySteps(providerInfoBySteps);
        }

        if (currentStep === STEPS_COUNT) {
            signUpProvider(providerInfoBySteps);
            goToStep(Steps.COMPLETE);
        } else {
            goToStep(currentStep + 1);
        }
    };

    const previousStep = () => {
        goToStep(currentStep - 1);
    };

    const goToStep = step => {
        setCurrentStep(step);
        history.push(`/provider/wizard/${step}`);
    };

    const signUpProvider = providerInfoBySteps => {
        Promise.resolve().then(async () => {
            setLoading(true);
            try {
                await ProviderStepperSignUpClient.signUp(providerInfoBySteps);
                setCompleted(true);
            } catch (e) {
                message.error("Something went wrong, please try again later.");
            }
            setLoading(false);
        });
    };

    return (
        <div
            className="wb-company-wizard"
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center"
            }}>
            {[Steps.WELCOME, Steps.COMPLETE].indexOf(currentStep) === -1 ? (
                <StepperHeader progress={(currentStep / STEPS_COUNT) * 100.0} onBack={previousStep} />
            ) : null}
            {currentStep === Steps.WELCOME ? (
                <WelcomeStep welcomeProps={providerWelcomeInfo} onNext={nextStep} />
            ) : null}
            {currentStep === Steps.BASIC ? (
                <ProviderBasicInfoStep
                    initialValues={providerInfoBySteps ? providerInfoBySteps[Steps.BASIC] : null}
                    onNext={nextStep}
                />
            ) : null}
            {currentStep === Steps.PROFESSIONAL_INFO ? (
                <ProviderProfessionalStep
                    initialValues={providerInfoBySteps ? providerInfoBySteps[Steps.PROFESSIONAL_INFO] : null}
                    basicInfo={providerInfoBySteps ? providerInfoBySteps[Steps.BASIC] : null}
                    onNext={nextStep}
                />
            ) : null}
            {currentStep === Steps.SERVICES_CENTER ? (
                <ProviderServiceCenterStep
                    initialValues={providerInfoBySteps ? providerInfoBySteps[Steps.SERVICES_CENTER] : null}
                    basicInfo={providerInfoBySteps ? providerInfoBySteps[Steps.BASIC] : null}
                    onNext={nextStep}
                />
            ) : null}
            {currentStep === Steps.COMPLETE ? <MakeTheMagicHappenStep history={history} completed={completed} /> : null}
        </div>
    );
};
