import React from "react";
import {BudgetFilter} from "../popoverFilters/budgetFilter/budgetFilter.jsx";
import {LocationFilterPopoverFactory} from "../popoverFilters/locationFilter/LocationFilterPopverFactory.jsx";
import {SpecificResultFilterPopoverFactory} from "../popoverFilters/accuracyResultsFilter/AccuracyResultsPopverFactory.jsx";
import {SearchTextFilter} from "../popoverFilters/searchTextFilter/SearchTextFilter.jsx";

const filters = {
    budgetFilter: "BudgetFilter",
    locationFilter: "LocationFilter",
    accuracyResultsFilter: "AccuracyResultsFilter",
    searchFilter: "searchFilter"
};

export const filterOptions = (panel, addressName) => {
    return [
        {
            label: <BudgetFilter />,
            value: filters.budgetFilter
        },
        {
            label: <LocationFilterPopoverFactory panel={panel} addressName={addressName} />,
            value: filters.locationFilter
        },
        {
            label: <SpecificResultFilterPopoverFactory category={panel} />,
            value: filters.accuracyResultsFilter
        },
        {
            label: <SearchTextFilter />,
            value: filters.searchFilter
        }
    ];
};
