import {EventInfoTitle} from "../event/EventInfoTitle";
import {PageSubTitleLabel} from "../components/PageTitle";
import React from "react";
import {isSlotAvailableBetweenAppointments} from "../utils/AppointmentValidator";
import {StyledButton} from "../components/StyledButton";
import styled from "styled-components";
import moment from "moment-timezone";
import {ProductButton} from "./EmployeeBookingProductSelection";
import {dateFormat} from "../utils/DateFormat";

const TimeButton = styled(ProductButton)`
    max-width: 125px;
    margin: 10px;
    font-weight: 700;
    justify-content: center;
`;

const slicedTimes = ({date, event, selectedProduct, minTime, maxTime, appointments}) => {
    const start = moment(minTime, "HH:mm");
    const end = moment(maxTime, "HH:mm");
    const totalMinutes = end.diff(start, "minutes");
    const duration = parseInt(selectedProduct.durationMinutes, 10);

    if (Array.isArray(event.breakSlots) && event.breakSlots.length > 0) {
        event.breakSlots.forEach(slot => {
            appointments.push({
                date,
                start: slot.start,
                end: slot.end
            });
        });
    }

    let slots = [];
    const marginMinutes =
        selectedProduct.breakMargin && selectedProduct.breakMargin.enabled
            ? selectedProduct.breakMargin.marginMinutes
            : 0;
    for (let mins = 0; mins <= totalMinutes - duration; mins += duration + marginMinutes) {
        const startSlot = moment(start).add(mins, "minutes");
        const endSlot = moment(start).add(mins + duration, "minutes");
        if (isSlotAvailableBetweenAppointments(appointments, startSlot, endSlot)) {
            slots.push({
                start: startSlot,
                end: endSlot
            });
        }
    }

    return slots;
};

const timesView = (slicedTimes, onTimeSelect, onClose) => {
    if (Array.isArray(slicedTimes) && slicedTimes.length > 0) {
        return slicedTimes.map(({start, end}, idx) => (
            <TimeButton key={`time-${idx}`} onClick={() => onTimeSelect({start, end})}>
                {`${start.format("HH:mm")} - ${end.format("HH:mm")}`}
            </TimeButton>
        ));
    } else {
        return (
            <div style={{fontSize: 16, width: "100%", display: "flex", alignItems: "center", flexDirection: "column"}}>
                <span style={{textAlign: "center", marginBottom: 30, marginTop: 30}}>
                    Oh no... there are no available spots left! Want to book for another day?
                </span>
                <StyledButton
                    onClick={() => onClose()}
                    style={{
                        borderRadius: 25,
                        color: "#2F3E83",
                        border: "1px solid #2F3E83",
                        marginBottom: 10,
                        width: 200
                    }}>
                    View options
                </StyledButton>
            </div>
        );
    }
};

export const EmployeeBookingTimeSelection = ({
    me,
    event,
    appointments,
    selectedProduct,
    currentStart,
    currentEnd,
    onBack,
    onTimeSelect,
    onClose
}) => (
    <div
        style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            padding: 15
        }}>
        <EventInfoTitle me={me} event={event} currentStart={currentStart} currentEnd={currentEnd} onBack={onBack} />
        <PageSubTitleLabel>{event.address}</PageSubTitleLabel>
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
            {timesView(
                slicedTimes({
                    date: dateFormat(currentStart),
                    event,
                    selectedProduct,
                    minTime: event.minTime,
                    maxTime: event.maxTime,
                    appointments
                }),
                onTimeSelect,
                onClose
            )}
        </div>
    </div>
);
