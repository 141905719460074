import React, {useState, useEffect} from "react";
import {message, Switch} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {PageTitleLabel} from "../../components/PageTitle";
import {RoundedTable} from "../../components/RoundedTable";
import {HttpClient} from "../../http/HttpClient";
import moment from "moment-timezone";

const toHumanReadableTime = timestamp => {
    return moment(timestamp).format("DD/MM/YYYY, HH:mm:ss");
};

const buildColumns = (processingTaskName, onEnableChange) => {
    return [
        {
            title: "NAME",
            dataIndex: "name",
            render(name) {
                return <strong>{name}</strong>;
            }
        },
        {
            title: "LAST STARTED",
            dataIndex: "startTime",
            sorter: (r1, r2) => {
                return r1.startTime > r2.startTime ? 1 : -1;
            },
            defaultSortOrder: "descend",
            render: toHumanReadableTime
        },
        {
            title: "LAST ENDED",
            dataIndex: "endTime",
            sorter: (r1, r2) => {
                return r1.startTime > r2.startTime ? 1 : -1;
            },
            render: toHumanReadableTime
        },
        {
            title: "ENABLED",
            dataIndex: "enabled",
            filters: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ],
            filterMultiple: false,
            onFilter: (value, {enabled}) => {
                return enabled === value;
            },
            render(enabled, task) {
                return (
                    <Switch
                        loading={processingTaskName === task.name}
                        checked={enabled}
                        onChange={() => onEnableChange(task)}
                    />
                );
            }
        }
    ];
};

export const AdminTasks = () => {
    const [processingTaskName, setProcessingTaskName] = useState(null);
    const [tasks, setTasks] = useState(null);

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const tasks = await HttpClient.get("/admin/api/tasks");
                setTasks(tasks);
            } catch (e) {
                message.error(e.message, 3);
            }
        });
    }, []);

    const changeEnableState = async task => {
        setProcessingTaskName(task.name);
        try {
            task.enabled = !task.enabled;
            await HttpClient.post("/admin/api/tasks", task);
            setTasks(tasks.map(t => (t.name === task.name ? task : t)));
        } catch (e) {
            message.error(e.message, 3);
        }
        setProcessingTaskName(null);
    };

    return (
        <div style={{display: "flex", justifyContent: "center", margin: 10, marginBottom: 40}}>
            <div style={{display: "flex", flexDirection: "column", maxWidth: 1000, width: "100%"}}>
                <PageTitleLabel style={{marginBottom: 40}}>Tasks</PageTitleLabel>
                <RoundedTable
                    rowKey="name"
                    scroll={{x: 800}}
                    pagination={false}
                    loading={tasks === null}
                    dataSource={tasks || []}
                    columns={buildColumns(processingTaskName, changeEnableState)}
                />
            </div>
        </div>
    );
};
