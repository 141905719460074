import React, {useMemo} from "react";
import "./range-picker.mobile.css";
import classnames from "classnames";
import {DatePicker} from "antd";

const {RangePicker} = DatePicker;

export const RangePickerMobile = ({props}) => {
    const datePickerProps = useMemo(() => {
        return {
            ...props,
            popupClassName: classnames("range-picker-mobile", props?.popupClassName)
        };
    }, [props]);

    return <RangePicker separator={<span>עד</span>} {...datePickerProps} format="DD-MM-YYYY" />;
};
