import {SlackIcon} from "../../../icons";
import React, {useContext} from "react";
import {AppContext} from "../../../AppContext";

export const SlackAuthButton = ({path, children, style, description}) => {
    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
            <a
                style={{
                    display: "flex",
                    color: "var(--secondary-color)",
                    fontSize: 16,
                    borderRadius: 25,
                    height: 50,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    border: "1px solid var(--secondary-color)",
                    ...style
                }}
                href={path}>
                <SlackIcon style={{width: 20, height: 20, marginRight: 10}} />
                {children}
            </a>
            {description ? (
                <label style={{fontSize: 14, color: "#C1CBD3", padding: 20, paddingTop: 10, textAlign: "center"}}>
                    {description}
                </label>
            ) : null}
        </div>
    );
};
