import React from "react";
import {FormInput} from "../../components/form";

export const StepperInput = ({value, onChange, placeholder, className, style, disabled = false, status = ""}) => {
    return (
        <FormInput
            disabled={disabled}
            placeholder={placeholder}
            className={className}
            onChange={onChange}
            value={value}
            status={status}
            style={{
                color: "var(--secondary-color)",
                height: 50,
                borderRadius: 5,
                backgroundColor: "#FCFDFE",
                ...(status
                    ? {}
                    : {border: status ? status : value ? "1px solid var(--secondary-color)" : "1px solid #E8EDF5"}),
                ...style
            }}
        />
    );
};
