import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import classNames from "classnames";
import classnames from "classnames";
import {debounce, set, sumBy} from "lodash";
import {Collapse, ConfigProvider, message} from "antd";
import {BOOKING_ERROR_TO_PANEL, FIRST_ORDER} from "../consts";
import {OrderForm} from "../../OrderForm";
import {BookingHeader} from "../BookingHeader";
import {MarketplaceBookingContext} from "../MarketplaceBookingContext";
import {PaymentPanel} from "../Payment/PaymentPanel";
import {
    DEFAULT_DELIVERY_ARRIVING_TIME_RANGE,
    DEFAULT_DELIVERY_PRICE,
    ERROR_LEVEL,
    getDefaultTypeToShow,
    PLACE_ERROR,
    WARN_LEVEL
} from "../../MarketplaceUtils";
import {
    createBookingEvent,
    createBookingEventValues,
    createProductBundleEvent,
    createVoucherBookingEvent,
    expandIconController,
    getOrderTip,
    getPriceByAmount,
    MarketplaceSeparatorLine
} from "../../MarketplaceHelper";
import {OrderFormPanelKeys} from "../../consts";
import {PaymentType} from "../../../consts.js";
import {AppContext} from "../../../AppContext";
import {EventBus} from "../../../bus/EventBus";
import {HttpClient} from "../../../http/HttpClient";
import {PageLoader} from "../../../components";
import {getLogger} from "../../../Logger";
import {useEffectDebugger, useQuery, useRequest} from "../../../utils/hooks";
import {isCompanySubAdmin} from "../../../center/EXCenterHelper";
import {MarketplaceContext} from "../../marketplaceContext";
import {OrderVoucherForm} from "../../OrderVoucherForm";
import {NOOP} from "../../../utils/NOOP";
import "../marketplace-booking.css";
import "./product-bundle-booking.css";
import {ProductBundleOrderPreview} from "../ProductBundleOrderPreview";
import {allHebrewLetters} from "../../ProductDetailsPage/consts";
import {
    getDeliveryArea,
    getDeliveryPriceForBooking,
    getMergedDeliveryOptions,
    removeFloorText,
    validateBooking,
    validateVoucherBooking
} from "../utils";
import shortid from "shortid";
import {AsiaJerusalemTZ} from "../../../event/DaysMapper";
import moment from "moment/moment";
import {GoogleAnalytics} from "../../../GoogleAnalytics";
import {BookingCompleteModal} from "../BookingCompleteModal";

const {Panel} = Collapse;

export const ProductBundleBooking = ({history}) => {
    const {me, company, planBudgetOrder, setPlanBudgetOrder} = useContext(AppContext);
    const {productId, userFormValues, participants, highlightOrderPanel, setHighlightOrderPanel, productBundle} =
        useContext(MarketplaceContext);
    const [formValues, setFormValues] = useState({});
    const [triggerFormValues, setTriggerFormValues] = useState({});
    const [loadingDeliveryPrice, setLoadingDeliveryPrice] = useState(false);
    const [updateDetailsModals, setUpdateDetailsModals] = useState(null);
    const [bookingCompleteModalVisibility, setBookingCompleteModalVisibility] = useState(false);
    const [creditCard, setCreditCard] = useState(false);
    const [bookingLoading, setBookingLoading] = useState(false);
    const [createdEvent, setCreatedEvent] = useState();
    const [paymentType, setPaymentType] = useState(PaymentType.BankTransfer);
    const [couponCode, setCouponCode] = useState(null);
    const [coupon, setCoupon] = useState(null);
    const [deliveryPrice, setDeliveryPrice] = useState({});
    const [activeKey, setActiveKey] = useState(null);
    const [currentDeliveryArea, setCurrentDeliveryArea] = useState(null);
    //
    const activityTimer = useRef();
    const timerTrigger = useRef(null);
    // const productBundleRef = useRef(productBundle);

    let query = useQuery();

    const savedOrderURI = useMemo(() => {
        if (!query) return null;

        const fromSavedOrder = query.get("fromSavedOrder");
        const fromEventId = query.get("fromEventId");

        if (!fromSavedOrder && !fromEventId) {
            return null;
        }

        return fromEventId
            ? `/api/savedOrder/byEventId/${encodeURIComponent(fromEventId)}`
            : `/api/savedOrder/${encodeURIComponent(fromSavedOrder)}`;
    }, [query]);

    const [savedOrder] = useRequest(savedOrderURI, "get", null, [query], !!savedOrderURI);

    const calculateModularProductPrice = useMemo(() => {
        return productBundle?.products?.reduce((acc, product) => {
            const productFormValues = formValues[product.productId];
            acc[product.productId] = productFormValues?.modularProducts?.reduce((acc, formValue) => {
                return acc + formValue?.price * formValue?.amount;
            }, 0);
            return acc;
        }, {});
    }, [triggerFormValues.modularProducts, productBundle?.products]);

    useEffect(() => {
        const isSavedOrderHasSubProducts = savedOrder?.modularProducts?.length;

        if (productBundle?.products?.length) {
            for (const product of productBundle.products) {
                const providerDetails = productBundle?.providers.find(
                    provider => provider.providerId === product.providerId
                );

                const modularProducts = product?.pricingTable?.byModular?.subProducts?.reduce((acc, subProduct) => {
                    const foundProviderSubProduct = providerDetails.subProducts?.find(({id}) => id === subProduct?.id);

                    if (foundProviderSubProduct?.isActive && subProduct.isActive) {
                        const foundSavedOrderSubProduct = savedOrder?.modularProducts.find(
                            ({id}) => id === subProduct.id
                        );

                        if (foundSavedOrderSubProduct || isSavedOrderHasSubProducts) {
                            return [
                                ...acc,
                                {
                                    ...subProduct,
                                    ...foundProviderSubProduct,
                                    amount: foundSavedOrderSubProduct ? foundSavedOrderSubProduct.amount ?? 0 : 0
                                }
                            ];
                        }

                        return [
                            ...acc,
                            {
                                ...subProduct,
                                ...foundProviderSubProduct,
                                amount: subProduct.defaultAmount ?? 0
                            }
                        ];
                    }

                    return acc;
                }, []);

                updateProductFormValues(product.productId, "modularProducts", modularProducts);
            }
        }
    }, [productBundle]);

    const log = useMemo(
        () =>
            getLogger(
                {
                    productId,
                    providerName: productBundle?.providers
                        ?.map(providerDetails => providerDetails?.businessName)
                        .join(" | "),
                    productName: productBundle?.products?.map(product => product?.productName).join(" | ")
                },
                "ProductBundleDetails"
            ),
        [productId, productBundle]
    );

    // useEffect(() => {
    //     if (product?.chosenPriceType) {
    //         setFormValues(current => ({...current, priceType: typeToPluralHebrewName(product?.chosenPriceType)}));
    //     }
    // }, [product?.chosenPriceType]);
    //
    // useEffect(() => {
    //     if (product) {
    //         productBundleRef.current = product;
    //     }
    // }, [product]);
    //
    // const onExitWindow = useCallback(async e => {
    //     if (timerTrigger.current) {
    //         await sendAlertToCMSs(
    //             me?.companyId,
    //             null,
    //             "המשתמש מילא פרטים ונטש את עמוד ההזמנה",
    //             productBundleRef.current?.productName,
    //             null
    //         );
    //         timerTrigger.current = null;
    //
    //         const confirmationMessage = "האם אתה בטוח שברצונך לצאת מהעמוד?";
    //         (e || window.event).returnValue = confirmationMessage;
    //         return confirmationMessage;
    //     }
    // }, []);
    //
    // useEffect(() => {
    //     window.addEventListener("beforeunload", onExitWindow);
    //
    //     return () => {
    //         window.removeEventListener("beforeunload", onExitWindow);
    //     };
    // }, [onExitWindow]);
    //
    // useEffect(() => {
    //     const {date, time, amount, extraRequests, extraContactInfo, invoiceComment, modularProducts} = formValues;
    //     const timerTriggerFormValues =
    //         date ||
    //         time ||
    //         amount ||
    //         extraRequests ||
    //         extraContactInfo ||
    //         invoiceComment ||
    //         modularProducts?.some(({defaultAmount, amount}) => defaultAmount !== amount);
    //
    //     if (timerTriggerFormValues) {
    //         timerTrigger.current = timerTriggerFormValues;
    //     }
    // }, [formValues]);
    //
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, []);
    //
    // useEffect(() => {
    //     const {date, time, amount, extraRequests, extraContactInfo, invoiceComment, modularProducts} = formValues;
    //     const timerTriggerFormValues =
    //         date ||
    //         time ||
    //         amount ||
    //         extraRequests ||
    //         extraContactInfo ||
    //         invoiceComment ||
    //         modularProducts?.some(({defaultAmount, amount}) => defaultAmount !== amount);
    //
    //     if (timerTriggerFormValues && product?.productName && me?.companyId) {
    //         clearTimeout(activityTimer.current);
    //         userFormValues.current = formValues;
    //
    //         activityTimer.current = setTimeout(
    //             async () =>
    //                 await sendAlertToCMSs(
    //                     me.companyId,
    //                     null,
    //                     "המשתמש לא ביצע פעולה במשך ה30 דקות האחרונות",
    //                     product.productName,
    //                     formValues
    //                 ),
    //             3000 * 60 * 10
    //         );
    //     }
    // }, [formValues, product?.productName, me?.companyId]);

    useEffect(() => {
        async function calculateDeliveryPrice(formValues, product, providerDetails) {
            setLoadingDeliveryPrice(true);

            const officeAddress = {
                address: isCompanySubAdmin(me) ? me?.address : company?.address,
                addressId: isCompanySubAdmin(me) ? me?.addressId : company?.addressId
            };

            const searchedAddress = formValues?.singleEmployeeDetails?.address ?? formValues?.chosenAddress;
            const searchedAddressId = formValues?.singleEmployeeDetails?.addressId ?? formValues?.addressId;

            const address = {
                address: searchedAddress,
                addressId:
                    officeAddress?.address?.trim() === removeFloorText(searchedAddress?.trim())
                        ? searchedAddressId
                        : searchedAddressId
            };

            const deliveryArea = await getDeliveryArea(address, product, providerDetails, log);

            if (product) {
                product.deliveryPrice = product.deliveryPrice ?? providerDetails.deliveryPrice;
            }

            const deliveryPrice = getDeliveryPriceForBooking(
                formValues?.chosenAddress,
                providerDetails,
                product,
                formValues,
                deliveryArea,
                log
            );

            setDeliveryPrice(currentDeliveryPrice =>
                set({...currentDeliveryPrice}, product.productId, deliveryPrice?.price)
            );

            setLoadingDeliveryPrice(false);
            setCurrentDeliveryArea(deliveryArea);

            updateProductFormValues(product.productId, "errors", {
                [PLACE_ERROR]: deliveryPrice?.message
                    ? {
                          level: deliveryPrice.price ? WARN_LEVEL : ERROR_LEVEL,
                          message: deliveryPrice.message
                      }
                    : null
            });

            // setFormValues(prev => ({
            //     ...prev,
            //     errors: {
            //         ...(prev.errors ?? {}),
            //         [PLACE_ERROR]: deliveryPrice?.message
            //             ? {
            //                   level: deliveryPrice.price ? WARN_LEVEL : ERROR_LEVEL,
            //                   message: deliveryPrice.message
            //               }
            //             : null
            //     }
            // }));
        }

        if (productBundle?.products?.length) {
            for (const product of productBundle?.products) {
                const productFormValues = formValues?.[product.productId];
                const foundProvider = productBundle.providers.find(
                    provider => provider.providerId === product.providerId
                );
                calculateDeliveryPrice(productFormValues, product, foundProvider);
            }
        }
    }, [
        triggerFormValues.employeesExcel,
        triggerFormValues.chosenAddress,
        productBundle?.products,
        // formValues.employeesExcel,
        // formValues.chosenAddress,
        company,
        formValues.singleEmployeeDetails,
        // product?.deliveryPrice,
        // providerDetails?.deliveryOptions,
        me,
        company,
        log
    ]);

    // useEffect(() => {
    //     if (participants) {
    //         setFormValues(currentFormValues => set(currentFormValues, "amount", participants));
    //     }
    // }, [participants]);

    const upgradeOptionsPrice = useMemo(
        () =>
            productBundle?.products?.reduce((acc, product) => {
                acc[product.productId] = sumBy(formValues[product.productId]?.upgradeOptions?.options, option =>
                    option.type === "byUnit" ? option.price * (option.amount ?? 0) : option.price
                );
                return acc;
            }, {}),
        [triggerFormValues.upgradeOptions?.options, triggerFormValues.amount, productBundle?.products]
    );

    useEffect(() => {}, []);

    const offeredPrice = useMemo(() => {
        return productBundle?.products?.reduce((acc, product) => {
            const productFormValues = formValues?.[product.productId];
            switch (product?.chosenPriceType) {
                case "byModular":
                    acc[product.productId] =
                        (calculateModularProductPrice[product.productId] ?? 0) + upgradeOptionsPrice[product.productId];
                    break;
                case "byVoucher":
                    const voucherReceiversAmount = productFormValues.singleEmployeeDetails
                        ? 1
                        : productFormValues.employeesExcel?.rowCount;
                    acc[product.productId] = productFormValues.voucherOption?.redeemableValue * voucherReceiversAmount;
                    break;
                default:
                    const currentPrice = getPriceByAmount(productFormValues?.amount, product);
                    acc[product.productId] = currentPrice
                        ? (
                              parseFloat(currentPrice.toString().replace(",", "")) *
                                  (getDefaultTypeToShow(product) === "byGroup"
                                      ? 1
                                      : parseFloat(productFormValues?.amount)) +
                              upgradeOptionsPrice[product.productId]
                          ).toFixed(0) - 0
                        : null;
                    break;
            }

            return acc;
        }, {});
    }, [
        triggerFormValues.amount,
        triggerFormValues?.modularProducts,
        triggerFormValues?.voucherOption,
        triggerFormValues?.singleEmployeeDetails,
        triggerFormValues.employeesExcel,
        upgradeOptionsPrice,
        calculateModularProductPrice,
        productBundle?.products
    ]);

    // const onInvoiceCommentChange = useCallback(
    //     val => {
    //         setFormValues(prevValues => ({...prevValues, invoiceComment: val}));
    //     },
    //     [setFormValues]
    // );
    //
    // const onInvoicePONumberChange = useCallback(
    //     val => {
    //         setFormValues(prevValues => ({...prevValues, invoicePONumber: val}));
    //     },
    //     [setFormValues]
    // );
    //
    // const onSeparateInvoiceChange = useCallback(val => {
    //     setFormValues(prevValues => ({...prevValues, separateInvoice: val}));
    // }, []);
    //
    // const onBudgetCategoriesPriorityChange = useCallback(val => {
    //     setFormValues(prevValues => ({...prevValues, budgetCategoriesPriority: val}));
    // }, []);

    const bookingError = useMemo(() => {
        return productBundle?.products?.reduce((acc, product) => {
            const providerDetails = productBundle.providers.find(
                provider => provider.providerId === product.providerId
            );
            const productFormValues = formValues?.[product.productId];

            acc[product.productId] = providerDetails?.isVoucherProvider
                ? validateVoucherBooking(
                      {...productFormValues, ...formValues},
                      company,
                      paymentType,
                      product,
                      creditCard,
                      me,
                      offeredPrice?.[product.productId],
                      deliveryPrice?.[product.productId],
                      coupon
                  )
                : validateBooking(
                      me,
                      paymentType,
                      offeredPrice?.[product.productId],
                      product,
                      {...productFormValues, ...formValues},
                      creditCard,
                      company,
                      deliveryPrice?.[product.productId],
                      coupon,
                      productFormValues.companyDiscount
                  );

            return acc;
        }, {});
    }, [
        productBundle?.products,
        me,
        paymentType,
        offeredPrice,
        formValues,
        creditCard,
        company,
        deliveryPrice,
        coupon
    ]);

    const createEvent = useCallback(
        debounce(async () => {
            function generateOrderValues(formValues, product, providerDetails) {
                const dateFormatted = moment(formValues.date).format("DD/MM/YYYY");
                const timeFormatted = moment(formValues.time).format("HH:mm");
                const dtstart = moment(`${dateFormatted} ${timeFormatted}`, "DD/MM/YYYY HH:mm")
                    .tz(AsiaJerusalemTZ)
                    .valueOf();
                const deliveryOptions = getMergedDeliveryOptions(providerDetails, product);
                const deliveryArrivingTimeRange =
                    deliveryOptions?.arrivingTimeRange ?? DEFAULT_DELIVERY_ARRIVING_TIME_RANGE;
                const until = moment(dtstart).add(deliveryArrivingTimeRange, "hours").valueOf();
                const modularProducts = formValues.modularProducts?.filter(product => product?.amount > 0);

                try {
                    const valuesForCreateEvent = {
                        ...formValues,
                        // serviceId,
                        dtstart,
                        until,
                        paymentType,
                        offeredPrice: offeredPrice[product.productId],
                        address: formValues.chosenAddress || company.address,
                        branches: company.branches?.[0] || null,
                        companyPhone: company.phone,
                        deliveryPrice: deliveryPrice[product.productId],
                        coupon: coupon?.valid ? coupon : null,
                        creditCard,
                        modularProducts,
                        upgradeOptionsPrice,
                        deliveryArrivingTimeRange,
                        companyDiscountPercentage: company.ordersDiscount
                    };

                    if (planBudgetOrder) {
                        valuesForCreateEvent.planBudgetOrder = {
                            planId: planBudgetOrder.planId,
                            occasionCategoryId: planBudgetOrder.occasionCategoryId,
                            occasionId: planBudgetOrder.occasionId
                        };
                    }

                    if (providerDetails?.isVoucherProvider) {
                        if (formValues.timeOption.type === "CUSTOM_DATE") {
                            const {date, time} = formValues.timeOption;
                            valuesForCreateEvent.dtstart = moment(
                                `${date.format("YYYY-MM-DD")} ${time.format("HH:mm")}`,
                                "YYYY-MM-DD HH:mm"
                            )
                                .toDate()
                                .getTime();
                        }

                        valuesForCreateEvent.until = valuesForCreateEvent.dtstart;
                    }

                    return providerDetails?.isVoucherProvider
                        ? null
                        : createBookingEventValues(valuesForCreateEvent, product, providerDetails);
                } catch (e) {
                    EventBus.triggerError(
                        "server-error",
                        {
                            content: {
                                description: `Unfortunately we didn't manage to send your offer :( | Error: (${e.message})`
                            }
                        },
                        e.message
                    );
                }
            }

            const productsOrderValues = productBundle.products.map(product => {
                const foundProvider = productBundle.providers.find(
                    provider => provider.providerId === product.providerId
                );
                const productFormValues = formValues[product.productId];
                return generateOrderValues(productFormValues, product, foundProvider);
            });

            const {eventId} =
                (await createProductBundleEvent({
                    subOrders: productsOrderValues,
                    paymentType,
                    productName: productBundle.name,
                    productId: productBundle.productBundleId
                })) ?? {};

            if (!eventId) {
                setBookingLoading(false);
                return;
            }

            GoogleAnalytics.event("Professional Offer", "Proposal", me.companyId);

            // GoogleAnalytics.ecommerceEvent(
            //     "purchase",
            //     product,
            //     serviceId,
            //     {
            //         transaction_id: eventId,
            //         shipping: deliveryPrice,
            //         value: offeredPrice,
            //         checkout_option: paymentType,
            //         coupon: coupon?.valid ? coupon.code : null
            //     },
            //     {
            //         quantity: formValues.amount
            //     }
            // );

            log("Order Booked", productsOrderValues);
            setCreatedEvent(eventId);
            setPlanBudgetOrder(null);
            setBookingCompleteModalVisibility(true);

            if (paymentType === PaymentType.Budget) {
                EventBus.trigger("me:updateBudget");
            }

            setBookingLoading(false);
        }, 50),
        [
            paymentType,
            offeredPrice,
            company?.address,
            me.companyId,
            formValues,
            coupon,
            deliveryPrice,
            creditCard,
            upgradeOptionsPrice,
            log
        ]
    );

    const officeAddress = useMemo(() => {
        if (isCompanySubAdmin(me) && me?.address) {
            return `${me?.address}${me?.floor ? ` קומה ${me?.floor}` : ""}`;
        } else if (me.isCompanyMaster && company?.address) {
            return `${company?.address}${company?.floor ? ` קומה ${company?.floor}` : ""}`;
        } else {
            return null;
        }
    }, [me, company]);

    const onBookingFinish = useCallback(async () => {
        const isMasterWithoutFinanceContact = me.isCompanyMaster && !company?.financeContact;
        const isSubAdminWithoutFinanceContact =
            me.isCompanySubAdmin && company?.separateSubAdminInvoices && !me.financeContact;

        if (isMasterWithoutFinanceContact || isSubAdminWithoutFinanceContact) {
            setUpdateDetailsModals(FIRST_ORDER);
        } else {
            await createEvent();
        }
    }, [
        createEvent,
        me.isCompanyMaster,
        me.isCompanySubAdmin,
        me.financeContact,
        company?.financeContact,
        company?.separateSubAdminInvoices
    ]);

    // const sendAlertToCMSs = useCallback(
    //     debounce(
    //         async (companyId, price, status, productName, productId, filledValues) =>
    //             sendAlertToCsmWithEmail(companyId, price, status, productName, productId, filledValues),
    //         1000
    //     ),
    //     []
    // );

    const onFinishClick = useCallback(async () => {
        setBookingLoading(true);

        if (Object.values(bookingError).every(error => !error)) {
            await onBookingFinish();
            timerTrigger.current = null;
            clearTimeout(activityTimer.current);
        } else {
            // for (const product of productBundle.products) {
            //     const relevantFormValues = formValues[product.productId];
            //
            //     if (relevantFormValues?.errors) {
            //         for (const errorKey in relevantFormValues.errors) {
            //             if (relevantFormValues.errors[errorKey]?.level === "error") {
            //                 // await sendAlertToCMSs(
            //                 //     me.companyId,
            //                 //     offeredPrice,
            //                 //     formValues.errors[errorKey].message,
            //                 //     product.productName,
            //                 //     productId,
            //                 //     formValues
            //                 // );
            //                 timerTrigger.current = null;
            //                 clearTimeout(activityTimer.current);
            //                 break;
            //             }
            //         }
            //     }
            // }

            for (const [productId, error] of Object.entries(bookingError)) {
                const foundProduct = productBundle.products.find(product => product.productId === productId);
                if (error) {
                    message.error(
                        error ? `${foundProduct.productName} - ${error}` : "יש למלא את כל שדות החובה לפני שליחת הזמנה",
                        3
                    );
                    break;
                }
            }

            // if (BOOKING_ERROR_TO_PANEL[bookingError]) {
            //     setHighlightOrderPanel({
            //         id: BOOKING_ERROR_TO_PANEL[bookingError],
            //         color: BOOKING_TO_PANEL_COLORS.ERROR
            //     });
            // }

            setBookingLoading(false);
        }
    }, [triggerFormValues?.error, onBookingFinish, bookingError, activityTimer]);

    useEffect(() => {
        const validateCoupon = async code => {
            if (!company?.companyId) {
                return;
            }

            const order = {
                offeredPrice: sumBy(Object.values(offeredPrice), price => price || 0),
                companyId: company.companyId
            };
            const {
                error: requestError,
                discount,
                valid,
                errorMessage
            } = await HttpClient.safePost("/api/coupons/validate", {code, order});

            if (requestError) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we didn't manage to validate your coupon :("}},
                    requestError.message
                );
                return;
            }

            log("Coupon Validated", {code, discount, valid, errorMessage});
            setCoupon({valid, code, discount, errorMessage});
        };

        if (couponCode) {
            validateCoupon(couponCode);
        }
    }, [offeredPrice, couponCode, company?.companyId]);

    // useEffect(() => {
    //     if (highlightOrderPanel?.id) {
    //         setActiveKey(highlightOrderPanel?.id);
    //     }
    // }, [highlightOrderPanel]);
    //
    const tip = useMemo(
        () =>
            productBundle?.products?.reduce((acc, product) => {
                acc[product.productId] = getOrderTip(
                    offeredPrice[product.productId],
                    formValues[product.productId]?.tipPercentage
                );
                return acc;
            }, {}),
        [formValues, offeredPrice, productBundle?.products]
    );

    const companyDiscountWithCoupon = useMemo(() => {
        const companyDiscount = sumBy(
            productBundle?.products,
            product => formValues[product.productId]?.companyDiscount || 0
        );

        return (companyDiscount || 0) - (coupon?.discount || 0);
    }, [triggerFormValues?.companyDiscount, productBundle?.products, coupon?.discount]);

    useEffect(() => {
        if (company?.ordersDiscount && productBundle?.products?.length) {
            for (const product of productBundle.products) {
                const totalOrderPrice =
                    offeredPrice[product.productId] +
                    (deliveryPrice[product.productId] || 0) +
                    (tip[product.productId] || 0);

                const companyDiscount = Math.floor(totalOrderPrice * (company.ordersDiscount / 100));

                updateProductFormValues(product.productId, "companyDiscount", companyDiscount);
            }
        }
    }, [company?.ordersDiscount, offeredPrice, deliveryPrice, coupon?.discount, productBundle?.products]);

    const onCancelCoupon = useCallback(() => {
        setCouponCode(null);
        setCoupon(null);
    }, []);

    // useEffect(() => {
    //     if (!savedOrder) return;
    //     if (savedOrder?.productId !== productId) return;
    //     const {
    //         productName,
    //         providerId,
    //         productId: productIdFromSavedOrder,
    //         couponCode: couponCodeFromSavedOrder,
    //         paymentType: paymentTypeFromSavedOrder,
    //         date = null,
    //         time = null,
    //         address,
    //         ...formValuesFromSavedOrder
    //     } = savedOrder;
    //
    //     setFormValues({
    //         ...formValuesFromSavedOrder,
    //         ...(date ? {date: moment(date)} : {}),
    //         ...(time ? {time: moment(time)} : {})
    //     });
    //     if (couponCodeFromSavedOrder) {
    //         setCouponCode(couponCodeFromSavedOrder);
    //     }
    //     if (paymentTypeFromSavedOrder) {
    //         setPaymentType(paymentTypeFromSavedOrder);
    //     }
    //     setFormValues(formValues => ({
    //         ...formValues,
    //         upgradeOptions: {...(formValuesFromSavedOrder?.upgradeOptions || {})},
    //         chosenAddress: address || formValues.chosenAddress || null
    //     }));
    // }, [savedOrder, productId]);
    //
    // const removeQueryParams = (searchQuery, keys) => {
    //     const searchParams = new URLSearchParams(searchQuery);
    //     for (const key of keys) {
    //         if (searchParams.has(key)) {
    //             searchParams.delete(key);
    //         }
    //     }
    //     return searchParams.toString();
    // };
    //
    // useEffect(() => {
    //     const clearSavedOrder = (newLocation, routerChangeType) => {
    //         if (location.pathname !== newLocation.pathname) {
    //             if (newLocation.pathname !== location.pathname || newSearch !== location.search) {
    //                 if (newLocation.search.length > 0) {
    //                     const newSearch = removeQueryParams(location.search, ["fromSavedOrder", "fromEventId"]);
    //                     history.replace({
    //                         pathname: newLocation.pathname,
    //                         search: newSearch
    //                     });
    //                 }
    //             }
    //         }
    //     };
    //
    //     return history.listen(clearSavedOrder);
    // }, [history, location]);

    const updateProductFormValues = useCallback((productId, key, value) => {
        setTriggerFormValues(currentTriggerFormValues => set({...currentTriggerFormValues}, key, shortid()));
        setFormValues(currentFormValues => set({...currentFormValues}, `${productId}.${key}`, value));
    }, []);

    const updateFormValues = useCallback((key, value) => {
        setFormValues(currentFormValues => set({...currentFormValues}, key, value));
    }, []);

    const renderRelevantOrderForm = (providerDetails, product, formValues, updateFormValues) => {
        return (
            <>
                {providerDetails?.isVoucherProvider ? (
                    <OrderVoucherForm
                        formValues={formValues}
                        setFormValues={setFormValues}
                        content={product}
                        bookingError={bookingError[product.productId]}
                        defaultActiveKey={activeKey}
                        onDefaultActiveKeyChange={setActiveKey}
                        mainContact={isCompanySubAdmin(me) ? me?.firstName : company?.contactName}
                    />
                ) : (
                    <OrderForm
                        calculateModularProductPrice={calculateModularProductPrice[product.productId]}
                        officeAddress={officeAddress}
                        mainContact={isCompanySubAdmin(me) ? me?.firstName : company?.contactName}
                        content={product}
                        formValues={formValues}
                        setFormValues={NOOP}
                        updateFormValues={updateFormValues}
                        bookingError={bookingError[product.productId]}
                        providerDetails={providerDetails}
                        defaultActiveKey={null}
                        onDefaultActiveKeyChange={NOOP}
                        log={NOOP}
                    />
                )}
            </>
        );
    };

    return (
        <MarketplaceBookingContext.Provider
            value={{
                // deliveryAreaProperties: currentDeliveryArea,
                offeredPrice,
                highlightOrderPanel
            }}>
            <>
                {productBundle?.products?.length ? (
                    <>
                        <BookingCompleteModal
                            visible={bookingCompleteModalVisibility}
                            eventId={createdEvent}
                            onSave={link => {
                                setBookingCompleteModalVisibility(false);
                                history.push(link);
                            }}
                            onClose={() => setBookingCompleteModalVisibility(false)}
                            company={company}
                            me={me}
                        />

                        <div className={classnames("booking-container", "product-bundle-booking")}>
                            <div className="booking-container-width">
                                <BookingHeader
                                    productName={productBundle?.name}
                                    setUpdateDetailsModals={setUpdateDetailsModals}
                                    updateDetailsModals={updateDetailsModals}
                                    setBookingLoading={setBookingLoading}
                                    createEvent={createEvent}
                                    log={log}
                                />

                                <div className="booking-container-wrapper">
                                    <div className="booking-container-content">
                                        <ProductBundleOrderPreview
                                            onFinishClick={onFinishClick}
                                            bookingLoading={bookingLoading}
                                            canFinish={Object.values(bookingError).every(error => !error)}
                                            formValues={formValues}
                                            offeredPrice={offeredPrice}
                                            coupon={coupon}
                                            onCouponAdded={setCouponCode}
                                            onCancelCoupon={onCancelCoupon}
                                            deliveryPrice={deliveryPrice}
                                            loadingDeliveryPrice={loadingDeliveryPrice}
                                            upgradeOptionsPrice={upgradeOptionsPrice}
                                            tip={tip}
                                            companyDiscount={companyDiscountWithCoupon}
                                        />
                                        <div
                                            className={classnames(
                                                "booking-form-content",
                                                "product-bundle-form-content"
                                            )}>
                                            <ConfigProvider direction={"rtl"}>
                                                <Collapse
                                                    className="product-bundle-booking-collapse"
                                                    defaultActiveKey={[productBundle?.products[0]?.productName]}
                                                    ghost={true}
                                                    accordion={true}
                                                    expandIconPosition={"end"}>
                                                    {productBundle.products.map((product, index) => {
                                                        const foundProvider = productBundle.providers.find(
                                                            provider => provider.providerId === product.providerId
                                                        );

                                                        return (
                                                            <>
                                                                <Panel
                                                                    key={product.productName}
                                                                    showArrow={true}
                                                                    header={
                                                                        <div className="product-bundle-booking-product-panel-title">
                                                                            חלק {allHebrewLetters[index]} -{" "}
                                                                            {product.productName}
                                                                        </div>
                                                                    }>
                                                                    {renderRelevantOrderForm(
                                                                        foundProvider,
                                                                        product,
                                                                        formValues[product.productId] ?? {},
                                                                        (key, value) =>
                                                                            updateProductFormValues(
                                                                                product.productId,
                                                                                key,
                                                                                value
                                                                            )
                                                                    )}
                                                                </Panel>
                                                            </>
                                                        );
                                                    })}
                                                </Collapse>

                                                <Collapse
                                                    ghost={true}
                                                    accordion={true}
                                                    className={classNames("marketplace-booking-collapse", {
                                                        panelError:
                                                            BOOKING_ERROR_TO_PANEL[null] ===
                                                                OrderFormPanelKeys.Payment &&
                                                            highlightOrderPanel?.id === OrderFormPanelKeys.Payment
                                                    })}
                                                    expandIconPosition="end"
                                                    activeKey={activeKey}
                                                    onChange={setActiveKey}
                                                    expandIcon={({isActive}) => expandIconController(isActive)}>
                                                    <PaymentPanel
                                                        panelKey={OrderFormPanelKeys.Payment}
                                                        updateCreditCard={setCreditCard}
                                                        onPaymentTypeChange={type => setPaymentType(type)}
                                                        onInvoiceCommentChange={invoiceComment =>
                                                            updateFormValues("invoiceComment", invoiceComment)
                                                        }
                                                        invoiceComment={formValues?.invoiceComment}
                                                        onInvoicePONumberChange={invoicePONumber =>
                                                            updateFormValues("invoicePONumber", invoicePONumber)
                                                        }
                                                        invoicePONumber={formValues?.invoicePONumber}
                                                        onSeparateInvoiceChange={separateInvoice =>
                                                            updateFormValues("separateInvoice", separateInvoice)
                                                        }
                                                        budgetCategoriesPriority={formValues?.budgetCategoriesPriority}
                                                        onBudgetCategoriesPriorityChange={budgetCategoriesPriority =>
                                                            updateFormValues(
                                                                "budgetCategoriesPriority",
                                                                budgetCategoriesPriority
                                                            )
                                                        }
                                                        separateInvoice={formValues?.separateInvoice}
                                                        allowedPaymentMethods={[
                                                            PaymentType.BankTransfer,
                                                            PaymentType.Budget
                                                        ]}
                                                    />
                                                </Collapse>
                                                <MarketplaceSeparatorLine />
                                            </ConfigProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <PageLoader style={{top: "80px"}} />
                )}
            </>
        </MarketplaceBookingContext.Provider>
    );
};
