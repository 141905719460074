import React, {useCallback, useEffect, useMemo, useState} from "react";
import moment from "moment";
import JSZip from "jszip";
import classNames from "classnames";
import {ConfigProvider, DatePicker, message} from "antd";
import {DeclineInvoiceModal} from "./DeclineInvoiceModal";
import AdminProviderMonthOrdersModal from "./AdminProviderMonthOrdersModal";
import AdminProviderInvoiceModal from "./AdminProviderInvoiceModal";
import {exportTableToExcel} from "./excelExporter";
import getColumns from "./columns";
import PageTitleHeader from "../../components/PageTitle";
import {RoundedTable, SearchInputWithIcon, SquareButton} from "../../components";
import {useRequest} from "../../utils/hooks";
import {HttpClient} from "../../http/HttpClient";
import {downLoadBlob, getBlobFromUrl} from "../../utils/downloadFromUrl";
import {momentHebrewFormat} from "../../utils/DateFormat";
import {InvoicesStatusToNameAndColor, InvoiceStatusTypes} from "../../provider/ProviderManageInvoices/consts";
import "./admin-invoices.css";

const AdminInvoices = () => {
    const [providerOrdersModal, setProviderOrdersModal] = useState(null);
    const [providerInvoiceModal, setProviderInvoiceModal] = useState(null);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [selectedMonth, setSelectedMonth] = useState(moment().subtract(1, "month"));
    const [innerInvoices, setInnerInvoices] = useState();
    const [declineModalInvoiceId, setDeclineModalInvoiceId] = useState(null);

    const [invoices, loadingInvoices] = useRequest(
        selectedMonth
            ? `/admin/api/providerInvoice/byMonth/${selectedMonth.format("MM-YYYY")}`
            : `/admin/api/providerInvoice/all`
    );

    useEffect(() => {
        setInnerInvoices(invoices);
    }, [invoices]);

    const filteredInvoices = useMemo(
        () =>
            searchInput
                ? innerInvoices?.filter(invoice =>
                      ["providerName", "providerEmail"].some(key => invoice[key]?.includes(searchInput))
                  )
                : innerInvoices,
        [searchInput, innerInvoices]
    );

    const totalInvoicesPrice = useMemo(
        () => (filteredInvoices ?? []).reduce((acc, invoice) => acc + (invoice?.price ?? 0), 0),
        [filteredInvoices]
    );

    const updateStatus = useCallback(
        async (invoiceId, statusId, declineMessage = null) => {
            const updatedInvoice = await HttpClient.safePost(`/admin/api/providerInvoice/changeStatus/${invoiceId}`, {
                status: statusId,
                declineMessage
            });

            if (updatedInvoice.error) {
                message.error("שגיאה בעת עדכון סטטוס חשבונית");
                message.error(updatedInvoice.error);
                return;
            }

            message.success(`סטאטוס החשבונית שונה ל${InvoicesStatusToNameAndColor[statusId].name}`);
            setInnerInvoices(prev =>
                prev.map(invoice =>
                    invoice.invoiceId === invoiceId
                        ? {
                              ...invoice,
                              status: updatedInvoice.status
                          }
                        : invoice
                )
            );

            if (providerInvoiceModal) {
                setProviderInvoiceModal(updatedInvoice);
            }
        },
        [providerInvoiceModal]
    );

    const updateInvoiceStatusHandler = useCallback(
        async (invoiceId, statusId) => {
            if (statusId === InvoiceStatusTypes.DECLINED) {
                setDeclineModalInvoiceId(invoiceId);
            } else {
                await updateStatus(invoiceId, statusId);
            }
        },
        [updateStatus]
    );

    const exportToExcel = useCallback(async () => {
        setLoadingExcel(true);
        const invoicesToExport = await HttpClient.safeGet(
            `/admin/api/providerInvoice/getInvoicesForExcel/${selectedMonth.format("MM-YYYY")}`
        );

        if (invoicesToExport.error) {
            message.error("שגיאה בהורדה לאקסל");
            setLoadingExcel(false);
            return;
        }

        const zip = new JSZip();

        const invoiceDocumentsFiles = invoicesToExport.currentMonthInvoice
            .filter(invoice => invoice.status.id === InvoiceStatusTypes.ACCEPTED)
            .map(async ({month, providerName, file}) => {
                const fileBlob = await getBlobFromUrl(file.fileUrl);

                zip.file(`${providerName} - ${momentHebrewFormat(month, "MMMM YY")} | ${file.fileName}`, fileBlob);
            });

        await Promise.all(invoiceDocumentsFiles);

        const excelBlob = await exportTableToExcel(
            [invoicesToExport.currentMonthInvoice, invoicesToExport.oldInvoices],
            selectedMonth
        );

        zip.file("חשבוניות שותפים לתשלום.xlsx", excelBlob);
        const res = await zip.generateAsync({type: "blob"});
        const fileName = `חשבונית - ${momentHebrewFormat(selectedMonth, "MMMM YYYY")}.zip`;
        downLoadBlob(res, fileName);
        setLoadingExcel(false);
    }, [selectedMonth]);

    return (
        <div className="admin-invoices">
            <ConfigProvider direction="rtl">
                <AdminProviderMonthOrdersModal
                    providerId={providerOrdersModal?.providerId}
                    visible={!!providerOrdersModal}
                    invoices={
                        providerOrdersModal
                            ? innerInvoices?.filter(({providerId}) => providerId === providerOrdersModal.providerId)
                            : []
                    }
                    onCancel={() => setProviderOrdersModal(null)}
                    addInvoice={newInvoice => {
                        if (moment(newInvoice.month).isSame(providerOrdersModal.month, "month")) {
                            setInnerInvoices(prev => [
                                ...prev,
                                {
                                    ...newInvoice,
                                    providerName: providerOrdersModal.providerName,
                                    providerEmail: providerOrdersModal.providerEmail
                                }
                            ]);
                        }
                    }}
                    removeInvoice={invoiceId => {
                        setInnerInvoices(prev => prev.filter(invoice => invoice.invoiceId !== invoiceId));
                    }}
                    month={providerOrdersModal?.month}
                    fetchInvoicesOnMonthChange={true}
                />
                <AdminProviderInvoiceModal
                    visible={providerInvoiceModal}
                    invoice={providerInvoiceModal}
                    updateStatus={(statusId, declineMessage) =>
                        updateStatus(providerInvoiceModal.invoiceId, statusId, declineMessage)
                    }
                    onRemove={() => {
                        setInnerInvoices(prev =>
                            prev.filter(({invoiceId}) => invoiceId !== providerInvoiceModal.invoiceId)
                        );
                        setProviderInvoiceModal(null);
                    }}
                    onCancel={() => setProviderInvoiceModal(null)}
                />

                <DeclineInvoiceModal
                    visible={declineModalInvoiceId}
                    onClose={() => {
                        setDeclineModalInvoiceId(null);
                    }}
                    onSave={async values => {
                        await updateStatus(declineModalInvoiceId, InvoiceStatusTypes.DECLINED, values.declineMessage);
                        setDeclineModalInvoiceId(null);
                    }}
                />

                <PageTitleHeader showBack={false}>חשבוניות שותפים</PageTitleHeader>
                <div className="admin-invoices-top-bar">
                    <div>
                        <SquareButton
                            onClick={() =>
                                setSelectedMonth(prev => {
                                    if (prev) {
                                        return null;
                                    }

                                    return moment().subtract(1, "month");
                                })
                            }
                            className={classNames({"secondary-color-button": !selectedMonth})}>
                            הצג את כל המסמכים
                        </SquareButton>
                        <DatePicker
                            format="MM-YYYY"
                            placeholder="בחרו חודש"
                            onChange={setSelectedMonth}
                            value={selectedMonth}
                            picker="month"
                            disabledDate={date => date.isSameOrAfter(moment(), "month")}
                            allowClear={false}
                        />
                        <SearchInputWithIcon searchInput={searchInput} setSearchInput={setSearchInput} />
                    </div>

                    <div>
                        <div className="total-invoice-debt">
                            <div>סה״כ חוב לשותפים</div>
                            <div>{totalInvoicesPrice?.toLocaleString()}</div>
                            <div>₪</div>
                        </div>

                        <SquareButton loading={loadingExcel} onClick={exportToExcel} className="excel-button">
                            ייצא לאקסל
                        </SquareButton>
                    </div>
                </div>
                <RoundedTable
                    rowKey="invoiceId"
                    scroll={{x: 800}}
                    className="admin-invoices-table"
                    pagination={false}
                    loading={loadingInvoices}
                    dataSource={filteredInvoices}
                    columns={getColumns({
                        openProviderOrders: setProviderOrdersModal,
                        openInvoice: setProviderInvoiceModal,
                        updateStatus: updateInvoiceStatusHandler
                    })}
                />
            </ConfigProvider>
        </div>
    );
};

export default AdminInvoices;
