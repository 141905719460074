import React, {useState} from "react";
import {StyledButton} from "../../../components";
import "./payment-form.css";

const errMessage = (msgs, c) => {
    let message = msgs.find(x => x === c);

    if (message) {
        return message;
    }
    return "";
};

const getError = err => {
    switch (err) {
        case "credit_card_number":
            return "מספר כרטיס אשראי לא תקין";
        case "expiry":
            return "תוקף כרטיס לא תקין";
        case "cvv":
            return "מספר לא תקין";
    }
};

const ErrorMsg = props => {
    const err = props.msg;

    if (!err || err === "") {
        return "";
    }

    const errorMessage = getError(err);
    return (
        <div className="cross-validation-error-message alert alert-danger">
            <span>{errorMessage}</span>
        </div>
    );
};

const PaymentForm = ({msg, handleSubmit, loading, creditCardExist}) => {
    const [saveCard, setSaveCard] = useState(true);
    return (
        <form id="payment_form" onSubmit={e => handleSubmit(e, saveCard)}>
            <div className="payment-form-container">
                <div className="payment-form-inputs-container">
                    <div className="payment-form-input-card-num">
                        <div className="form-control" id="credit_card_num" />
                        <div id="errors_for_number" className="error_message" />
                        <ErrorMsg msg={errMessage(msg, "credit_card_number")} />
                    </div>
                    <div className="payment-form-input-card-expiry">
                        <div className="form-control" id="expiry" />
                        <div id="errors_for_expiry" className="error_message" />
                        <ErrorMsg msg={errMessage(msg, "expiry")} />
                    </div>
                    <div className="payment-form-input-card-cvv">
                        <div className="form-control" id="cvv" />
                        <div id="errors_for_cvv" className="error_message" />
                        <ErrorMsg msg={errMessage(msg, "cvv")} />
                    </div>
                    <StyledButton
                        loading={loading}
                        className="payment-form-card-btn"
                        onClick={e => handleSubmit(e, saveCard)}>
                        {creditCardExist ? "החלפת כרטיס" : "הוספת כרטיס"}
                    </StyledButton>
                </div>
            </div>
        </form>
    );
};

export default PaymentForm;
