import React, {useCallback, useContext, useEffect, useMemo} from "react";
import {Collapse} from "antd";
import {allHebrewLetters} from "./consts";
import {isHTML} from "../MarketplaceUtils";
import {MarketplaceContext} from "../marketplaceContext";
import {NOOP} from "../../utils/NOOP";
import {PriceTypes} from "../../admin/upload/PriceSelectorHelper";
import {ProviderModularProductList} from "../OrderFormModularProductsPanel/modularProductList/ProviderModularProductList.jsx";
import {CartModularProductContext} from "../PricingCalculator/CartModularProductContext.jsx";
import {getModularProductSubProduct} from "../PricingCalculator/utils.js";

const {Panel} = Collapse;

export const PackageInfoPanel = ({key, onBookRedirectCollapse = NOOP, additionalElement, ...props}) => {
    const {product, providerDetails, isProductBundle, productBundle} = useContext(MarketplaceContext);
    const {setSubProducts, onPropertyChange, subProducts} = useContext(CartModularProductContext);

    useEffect(() => {
        if (providerDetails && product && product?.chosenPriceType === PriceTypes.ByModular) {
            const subProducts = getModularProductSubProduct(providerDetails, product);
            setSubProducts(subProducts);
        }
    }, [providerDetails, product, setSubProducts]);

    const recommendedProductParticipantsText = useMemo(() => {
        const minRecommendedProductParticipants = isProductBundle
            ? productBundle.minRecommendedProductParticipants
            : product?.minRecomendedProductParticipants;

        const maxRecommendedProductParticipants = isProductBundle
            ? productBundle.maxRecommendedProductParticipants
            : product?.maxRecomendedProductParticipants;

        if (!minRecommendedProductParticipants && !maxRecommendedProductParticipants) {
            return null;
        }

        if (minRecommendedProductParticipants && !maxRecommendedProductParticipants) {
            return `מינימום ${minRecommendedProductParticipants} משתתפים`;
        } else if (!minRecommendedProductParticipants && maxRecommendedProductParticipants) {
            return `מקסימום ${maxRecommendedProductParticipants} משתתפים`;
        } else if (
            minRecommendedProductParticipants &&
            maxRecommendedProductParticipants &&
            minRecommendedProductParticipants === maxRecommendedProductParticipants
        ) {
            return `עבור ${minRecommendedProductParticipants} משתתפים`;
        } else {
            return `עבור ${minRecommendedProductParticipants} - ${maxRecommendedProductParticipants} משתתפים`;
        }
    }, [product, productBundle, isProductBundle]);

    const renderPackageInfo = useCallback(
        (product, providerDetails) => {
            const PackageInfoElement = () => {
                if (!product?.packageInfo) return null;

                return isHTML(product?.packageInfo) ? (
                    <div
                        style={{
                            color: "var(--secondary-color)",
                            fontSize: "16px",
                            whiteSpace: "pre-wrap",
                            direction: "rtl"
                        }}
                        className="content"
                        dangerouslySetInnerHTML={{__html: product?.packageInfo}}
                    />
                ) : (
                    <div
                        style={{
                            color: "var(--secondary-color)",
                            whiteSpace: "pre-wrap",
                            direction: "rtl"
                        }}>
                        {product?.packageInfo}
                    </div>
                );
            };

            if (!product?.packageInfo && product.chosenPriceType !== PriceTypes.ByModular) {
                return null;
            }

            return (
                <>
                    <PackageInfoElement />

                    {!!subProducts?.length && (
                        <div style={{direction: "rtl"}}>
                            <ProviderModularProductList
                                subProducts={subProducts}
                                showProviderSubProductsImages={providerDetails?.showProviderSubProductsImages}
                                onPropertyChange={onPropertyChange}
                            />
                        </div>
                    )}
                </>
            );
        },
        [subProducts, product, onPropertyChange]
    );

    const renderPanelContent = useMemo(
        () =>
            isProductBundle ? (
                <>
                    {productBundle.products
                        .map((product, index) => {
                            const foundProvider = productBundle.providers.find(
                                provider => provider.providerId === product.providerId
                            );

                            const renderPackageInfoComponent = renderPackageInfo(product, foundProvider);
                            const hebrewLetter = allHebrewLetters[index];

                            return renderPackageInfoComponent ? (
                                <div className="package-info-panel-item">
                                    <div className="package-info-panel-item-title">
                                        חלק {hebrewLetter}: {product.productName}
                                    </div>
                                    {renderPackageInfoComponent}
                                </div>
                            ) : null;
                        })
                        .filter(Boolean)}
                </>
            ) : (
                renderPackageInfo(product, providerDetails)
            ),
        [isProductBundle, productBundle, product, providerDetails, subProducts, onPropertyChange]
    );

    return renderPanelContent ? (
        <>
            <Panel
                {...props}
                showArrow={true}
                header={
                    <>
                        <div className="panel-header-title">
                            {"הרכב החבילה"}
                            {recommendedProductParticipantsText ? ` | ${recommendedProductParticipantsText}` : null}
                        </div>
                    </>
                }>
                <div className="package-info-panel">{renderPanelContent}</div>
                {additionalElement}
            </Panel>
        </>
    ) : null;
};
