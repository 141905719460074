import React, {useContext, useMemo, useState} from "react";
import {isFinite} from "lodash";
import {ConfigProvider, Tooltip, message} from "antd";
import {AppContext} from "../AppContext";
import {CompanyBudgetLoadModal} from "./CompanyBudgetLoadModal";
import {StatisticsLabel} from "../admin/components/StatisticsLabel";
import {ServiceIdToName} from "../consts.js";
import {getTotalBudget} from "../utils/calculationUtils";
import {StyledButton} from "../components";
import {HttpClient} from "../http/HttpClient";
import {StringBuilder} from "../AppUtils.js";

export const CompanyBudgetLabel = ({forceHideLoadBudgetButton = false}) => {
    const {me, company, services, fetchUserBudget} = useContext(AppContext);
    const [loadBudgetModal, setLoadBudgetModal] = useState(false);

    const totalBudgetAmount = useMemo(
        () =>
            getTotalBudget(
                me.budget,
                services?.map(({serviceId}) => serviceId)
            ),
        [me.budget, services]
    );

    const budgetsBreakdown = useMemo(
        () =>
            totalBudgetAmount
                ? [
                      {
                          name: "תקציב צף",
                          value: me.budget.amount,
                          removeIfEmpty: true,
                          children: [],
                          tooltip: "תקציב לשימוש כל הקטגוריות בפלטפורמה",
                          suffix: "₪"
                      },
                      {
                          name: "divider",
                          children: []
                      },
                      ...services.map(({serviceId}) => ({
                          name: ServiceIdToName[serviceId],
                          value: me.budget[serviceId],
                          removeIfEmpty: true,
                          children: [],
                          tooltip: new StringBuilder()
                              .append("תקציב לשימוש מוצרים בקטגוריית")
                              .append(ServiceIdToName[serviceId])
                              .append("בלבד")
                              .toString(),
                          suffix: "₪"
                      }))
                  ]
                : [],
        [totalBudgetAmount]
    );

    const isCategorizedBudget = useMemo(() => {
        return services?.some(({serviceId}) => me.budget?.hasOwnProperty(serviceId));
    }, [services, me.budget]);

    const CompanyBudgetComponent = useMemo(() => {
        if (me.isCompanyMaster && !forceHideLoadBudgetButton && !company?.payOnOrderBudget) {
            return (
                <>
                    <Tooltip
                        title={
                            <>
                                <div>
                                    באפשרותכם לטעון תקציב ולשלם במרוכז עבור הזמנות במערכת עד לגודל התקציב הטעון :)
                                </div>
                                <div>
                                    ניתן גם לחלק את התקציב למשתמשים כפופים לכם ולעקוב אחר מימושו על ידם אם תרצו בכך.
                                </div>
                            </>
                        }>
                        <StyledButton className="company-budget-load-button" onClick={() => setLoadBudgetModal(true)}>
                            לטעינת תקציב
                        </StyledButton>
                    </Tooltip>
                </>
            );
        }

        if (isFinite(totalBudgetAmount) && totalBudgetAmount > 0) {
            return (
                <StatisticsLabel
                    trigger={["hover"]}
                    statistics={isCategorizedBudget ? budgetsBreakdown : []}
                    className="company-budget-label"
                    title={
                        isCategorizedBudget
                            ? `תקציב כללי בחשבון: ${totalBudgetAmount?.toLocaleString()} ₪`
                            : `יתרת התקציב בחשבון: ${totalBudgetAmount?.toLocaleString()}`
                    }
                />
            );
        }

        return <></>;
    }, [
        company?.payOnOrderBudget,
        forceHideLoadBudgetButton,
        totalBudgetAmount,
        isCategorizedBudget,
        budgetsBreakdown
    ]);

    return (
        <ConfigProvider direction="rtl">
            <CompanyBudgetLoadModal
                visible={loadBudgetModal}
                validationFunction={async budgetLoad => {
                    const {error} = (await HttpClient.safePost("/api/budget/loadCompanyAdminBudget", budgetLoad)) ?? {};

                    if (error) {
                        message.error("שגיאה בעת טעינת התקציב");
                        return false;
                    }

                    message.success("הטעינה בוצעה בהצלחה");
                    await fetchUserBudget();
                    return true;
                }}
                onSave={async () => {
                    setLoadBudgetModal(false);
                }}
                onClose={() => setLoadBudgetModal(false)}
            />

            {CompanyBudgetComponent}
        </ConfigProvider>
    );
};
