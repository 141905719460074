import classnames from "classnames";
import {Tooltip} from "antd";
import {StringBuilder} from "../../AppUtils.js";
import {getRowIndexByPagination} from "./utils.jsx";

export const generateColumns = (columnsSettings, validationResponse, pagination) => {
    return columnsSettings.map(({key, title}) => {
        return {
            title,
            dataIndex: key,
            onCell: (record, rowIndex) => {
                const rowPaginationIndex = getRowIndexByPagination(pagination?.current, pagination?.pageSize, rowIndex);
                const fieldValidation = validationResponse?.[rowPaginationIndex]?.[key];
                return {
                    className: classnames("view-cell", {
                        warning: fieldValidation?.warnings,
                        error: fieldValidation?.errors
                    })
                };
            },
            render: (value, record, rowIndex) => {
                const rowPaginationIndex = getRowIndexByPagination(pagination?.current, pagination?.pageSize, rowIndex);
                const fieldValidation = validationResponse?.[rowPaginationIndex]?.[key];
                return (
                    <Tooltip
                        title={
                            fieldValidation?.errors?.length
                                ? fieldValidation?.errors.map((e, index) => (
                                      <div key={index}>{new StringBuilder(`${index}.`).append(e).toString()}</div>
                                  ))
                                : null
                        }>
                        <div>{value ? value.toString() : fieldValidation?.errors?.length ? "שגיאה" : ""}</div>
                        {!!fieldValidation?.warnings?.length && <span>{fieldValidation.warnings.join(", ")}</span>}
                    </Tooltip>
                );
            }
        };
    });
};

export const DefaultReportPageSize = 50;
