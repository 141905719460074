import React from "react";
import {DeliveryAddressInput} from "../DeliveryAddressInput.jsx";
import {RadioGroupOptions} from "../../../../../components/RadioGroup/index.jsx";
import {boundingActivitiesRadioGroupOption} from "./radio-group-options.jsx";
import {deliveryAddressOptions} from "../consts.js";

export const boundingActivitiesSegmentOptions = (innerState, onAreaSelected, onGroupChange, onAreaDeliveryAccessibleChange, onDeliveryToAddressAccessibleChange) => {
    return [
        {
            key: deliveryAddressOptions.onSite,
            text: "בבית העסק",
            component: (
                <RadioGroupOptions
                    options={boundingActivitiesRadioGroupOption(onAreaSelected, innerState, onAreaDeliveryAccessibleChange, onDeliveryToAddressAccessibleChange)}
                    value={innerState?.locationFilter?.[deliveryAddressOptions.onSite]?.key}
                    onChange={onGroupChange}
                />
            )
        },
        {
            key: deliveryAddressOptions.deliveryToAddress,
            text: "מגיע לכתובת אחרת",
            component: (
                <DeliveryAddressInput
                    path={`locationFilter.${deliveryAddressOptions.deliveryToAddress}`}
                    placeholder={"הזן עיר ורחוב"}
                    deliveryType={deliveryAddressOptions.deliveryToAddress}
                    showDeliveryPrice={false}
                />
            )
        }
    ];
};
