import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {partialRight} from "lodash";
import {MarketplaceSearchResults} from "../../marketplace/MarketplaceSearchResults/MarketplaceSearchResults.jsx";
import {AppContext} from "../../AppContext";
import {useRequest} from "../../utils/hooks";
import {MarketplaceFilterPanel} from "../../marketplace/MarketplaceFilterPanel";
import {getLogger} from "../../Logger";
import EventsGallery from "../EventsGallary/EventsGallery";
import {createProductLink, selectServiceIdFromProduct} from "../../marketplace/MarketplaceHelper";
import {openWindow} from "../../http/WindowOpener";
import "./my-likes.css";

export const MyLikes = ({history}) => {
    const {me, services, userLikedItems} = useContext(AppContext);
    const [searchSorterType, setSearchSortType] = useState(null);
    const [allImagesByImageIds, setAllImagesByImageIds] = useState([]);
    const [likedImages, setLikedImages] = useState([]);

    const log = useMemo(() => getLogger({}, "MyLikes"), []);

    const likedProductsIds = useMemo(() => userLikedItems?.map(({targetId}) => targetId), [userLikedItems]);
    const imageProductsIds = useMemo(() => allImagesByImageIds.map(image => image.productId), [allImagesByImageIds]);

    const [imagesProducts] = useRequest(
        "/api/products/byIds",
        "POST",
        {
            productIds: imageProductsIds,
            pickedFields: ["brandName", "engBrandName", "productName", "services", "provider.businessName"],
            includeNotApprove: true
        },
        [imageProductsIds.length],
        !!imageProductsIds?.length
    );

    const [products] = useRequest(
        "/api/products/byIds",
        "POST",
        {productIds: likedProductsIds},
        [],
        !!likedProductsIds?.length
    );

    const [likeImagesFromServer] = useRequest("/api/like/mylikes/orderImage", "GET", null, [], !!me.features.pinterest);

    const likedImagesIds = useMemo(() => likeImagesFromServer?.map(({targetId}) => targetId), [likeImagesFromServer]);

    const [imagesByImageIdsFromServer] = useRequest(
        "/api/orderImages/byImageIds",
        "POST",
        {images: likedImagesIds},
        [],
        !!likedImagesIds?.length
    );

    const onContentClick = useCallback(
        (content, newTab = false) => {
            log("MyLikes page content click", {
                productId: content.service.productId,
                productName: content.service.productName,
                providerName: content.provider.providerName
            });

            const serviceId = selectServiceIdFromProduct(me, content, services);
            const url = createProductLink(content, {me, services, serviceId});

            if (newTab) {
                openWindow(url);
            } else {
                history.push(url);
            }
        },
        [log, me, services]
    );

    const onSortSelected = useCallback(
        sortBy => {
            log("Sort option selected", {sortBy});
            setSearchSortType(sortBy);
        },
        [log]
    );

    useEffect(() => {
        if (likeImagesFromServer) setLikedImages(likeImagesFromServer);
    }, [likeImagesFromServer]);

    useEffect(() => {
        if (imagesByImageIdsFromServer) setAllImagesByImageIds(imagesByImageIdsFromServer);
    });

    return (
        <div className="my-likes-container">
            <div className="marketplace-context-search-filters-container">
                <div className="marketplace-context-search-filters-container-width">
                    <div className="marketplace-category-title-row">❤️ המוצרים שאהבתי</div>

                    {likedProductsIds?.length ? (
                        <MarketplaceFilterPanel
                            searchSorterType={searchSorterType}
                            setSearchSortType={setSearchSortType}
                            onSortSelected={onSortSelected}
                            showFilter={false}
                            log={log}
                        />
                    ) : null}
                </div>
            </div>

            {!likedProductsIds?.length ? (
                <div className="my-likes-text">
                    כדאי לסמן מוצרים שאהבתם. ככה תוכלו לחזור אליהם בהזדמנות אחרת או להשלים הזמנה אחרי שהתייעצתם עם
                    הקולגות.
                </div>
            ) : (
                <div className="marketplace-category-providers">
                    <MarketplaceSearchResults
                        onContentClick={onContentClick}
                        onNewTab={partialRight(onContentClick, true)}
                        searchedContent={products ?? []}
                        searchSorterType={searchSorterType}
                        loadingSearchedContent={false}
                        showSearchAmount={false}
                    />
                </div>
            )}

            {me.features.pinterest && (
                <>
                    <div className="marketplace-context-search-filters-container">
                        <div className="marketplace-context-search-filters-container-width">
                            <div className="marketplace-category-title-row">❤️ ההשראות שלי</div>
                        </div>
                    </div>

                    {!allImagesByImageIds?.length ? (
                        <div className="my-likes-text">
                            השראה היא מקור היצירה! ראיתם משהו מדליק שהצית את הדמיון? סמנו אותו והשתמשו בו כהשראה לאירוע
                            הבא שלכם.
                        </div>
                    ) : (
                        <EventsGallery
                            history={history}
                            likedImages={likedImages}
                            allImages={allImagesByImageIds}
                            products={imagesProducts}
                            log={log}
                            setLikeImages={setLikedImages}
                        />
                    )}
                </>
            )}
        </div>
    );
};
