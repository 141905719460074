import React, {useContext} from "react";
import {EXCenterSectionsPickerCard} from "./EXCenterSectionsPickerCard";
import {getSectionsToShow} from "../navigation/EXCenterMenuProvider";
import {EXCenterSectionDesignMenu} from "../control/EXCenterSectionDesignMenu";
import {EXCenterContext} from "../EXCenterContext";
import cover_placeholder from "../../images/placeholder-image.jpg";
import {AppContext} from "../../AppContext";
import {EXCenterProperties} from "../utils/EXCenterProperties";

export const EXCenterSectionsPicker = () => {
    const sectionId = "picker";
    const {me} = useContext(AppContext);
    const {allContents, events, designInfoPerSection, designInfo, theme} = useContext(EXCenterContext);

    if (!Array.isArray(allContents) || !Array.isArray(events)) {
        return null;
    }

    const showEventsSection = Array.isArray(events) && events.length > 0;
    const sectionsToShow = getSectionsToShow(allContents, showEventsSection);

    if (sectionsToShow.length === 0 && !showEventsSection) {
        return null;
    }

    const sectionDesignInfo = designInfoPerSection[sectionId];

    if (me.type === "employee" && !sectionDesignInfo.display) {
        return null;
    }

    return (
        <div
            className="wb-ss-top wb-center-section"
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: EXCenterProperties.Section.Top
            }}>
            <EXCenterSectionDesignMenu editItems={{title: true}} sectionId={sectionId} />
            <div
                className="wb-ss-no-padding wb-center-section-padding"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    opacity: EXCenterProperties.Section.opacityByDisplay(sectionDesignInfo)
                }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        flexWrap: "wrap",
                        maxWidth: 1230
                    }}>
                    <div
                        className="wb-ss-section-title wb-ss-no-padding"
                        style={{
                            paddingLeft: 25,
                            marginBottom: 25,
                            textAlign: "center",
                            fontSize: 34,
                            fontWeight: 900,
                            color: theme.textColor,
                            width: "100%"
                        }}>
                        {sectionDesignInfo.title}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            width: "100%"
                        }}>
                        {sectionsToShow.map(({sectionId, displayName}) => (
                            <EXCenterSectionsPickerCard
                                key={`picker-${sectionId}`}
                                coverPhoto={
                                    designInfo.pickerPhotos && designInfo.pickerPhotos[sectionId]
                                        ? designInfo.pickerPhotos[sectionId]
                                        : cover_placeholder
                                }
                                sectionId={sectionId}
                                sectionName={displayName}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
