import React, {useEffect, useRef, useState} from "react";
import {useIsInViewport} from "../utils/hooks";
import {NOOP} from "../utils/NOOP";

export const RenderOnlyInViewWrapper = ({children, placeholder = null, onEnterView = NOOP}) => {
    const checkerRef = useRef();
    const isInView = useIsInViewport(checkerRef);
    const [wasInView, setWasInView] = useState(false);

    useEffect(() => {
        if (isInView && !wasInView) {
            onEnterView();
            setWasInView(true);
        }
    }, [isInView, wasInView]);

    return wasInView ? children : <div ref={checkerRef}>{placeholder}</div>;
};
