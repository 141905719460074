import React, {useState} from "react";
import classNames from "classnames";
import {GenericModal} from "./GenericModal";
import {NOOP} from "../utils/NOOP";
import {Images} from "../images/Images";
import "./confirmation-required-wrapper.css";

export const ConfirmationRequiredWrapper = ({
    children,
    classname,
    modalClassName,
    title = "האם אתה בטוח?",
    subTitle = "",
    yesText = "אישור",
    noText = "ביטול",
    onNo = NOOP,
    showNoButton = true,
    onYes = NOOP,
    disabled,
    image = Images.areYouSure
}) => {
    const [areYouSureVisibility, setAreYouSureVisibility] = useState(false);
    const [yesLoading, setIsLoading] = useState(false);

    return (
        <>
            <GenericModal
                className={classNames("confirmation-required-wrapper-modal", modalClassName)}
                title={title}
                text={Array.isArray(subTitle) ? subTitle : [subTitle]}
                image={image}
                visible={areYouSureVisibility}
                onClose={() => setAreYouSureVisibility(false)}
                options={[
                    {
                        id: "yes",
                        text: yesText,
                        disabled: yesLoading,
                        onClick: () => {
                            setIsLoading(true);
                            onYes();
                            setIsLoading(false);
                            setAreYouSureVisibility(false);
                        }
                    },
                    showNoButton && {id: "no", text: noText, onClick: () => setAreYouSureVisibility(false)}
                ]}
            />
            <div
                className={classNames("confirmation-required-wrapper", classname)}
                onClick={() => setAreYouSureVisibility(true)}
                style={{pointerEvents: disabled ? "none" : ""}}>
                {children}
            </div>
        </>
    );
};
