import React, {useCallback, useContext, useMemo} from "react";
import {Divider, Tabs} from "antd";
import "./login.css";
import {UsernamePasswordLogin} from "./LoginOptions/UsernamePasswordLogin.jsx";
import {RequestTempCode} from "./LoginOptions/RequestTempCode.jsx";
import {RightOutlined} from "@ant-design/icons";
import {TempCodeContextProvider} from "./LoginOptions/TempCodeProvider.jsx";
import {StepperContext, StepperContextProvider} from "../components/Stepper/StepperContext.jsx";
import {TempCodeVerification} from "./LoginOptions/TempCodeVerification.jsx";
import {redirectToMainView} from "./LoginOptions/utils.jsx";
import {loginOptionsKeys} from "./consts.js";
import {SideBySideLayout} from "./SideBySideLayout";
import {AuthPagesImage, Images} from "../images/Images.jsx";
import {getLogger} from "../Logger.jsx";
import {getThemeByOrigin} from "../utils.js";

export const Languages = {Hebrew: "עברית", English: "English"};

export const LANGUAGE = "language";

export const Login = ({history, location, onSuccess}) => {
    const log = useMemo(() => getLogger({}, "Login"), []);

    const onLoginSuccess = useCallback(
        async user => {
            await onSuccess(user);
            redirectToMainView(history, location);
        },
        [onSuccess, history, location]
    );

    const loginOptions = useMemo(
        () => [
            {
                tabKey: loginOptionsKeys.tempCode,
                text: "קוד חד פעמי",
                steps: [
                    <RequestTempCode key={1} history={history} log={log} />,
                    <TempCodeVerification
                        key={2}
                        history={history}
                        location={location}
                        onSuccess={onLoginSuccess}
                        log={log}
                    />
                ]
            },
            {
                tabKey: loginOptionsKeys.usernamePassword,
                text: "סיסמא קבועה",
                steps: [
                    <UsernamePasswordLogin
                        key={1}
                        history={history}
                        location={location}
                        onSuccess={onLoginSuccess}
                        log={log}
                    />
                ]
            }
        ],
        [history, location, onLoginSuccess]
    );

    const loginPageImage = useMemo(() => {
        const themeByOrigin = getThemeByOrigin(window.location.origin);
        return AuthPagesImage?.[themeByOrigin] || AuthPagesImage.image;
    }, []);

    return (
        <SideBySideLayout srcImage={loginPageImage}>
            <div className="login-container-page">
                <div className="login-container">
                    <span className="login-container-title">היי, טוב לראות אותך 👋</span>

                    <span className="login-sub-title">כניסה לחשבון שלי</span>
                    <Divider />
                    <StepperContextProvider tabs={loginOptions} defaultTabKey={loginOptionsKeys.usernamePassword}>
                        <LoginOptionContainer />
                    </StepperContextProvider>
                </div>
            </div>
        </SideBySideLayout>
    );
};

export const LoginOptionContainer = () => {
    const {currentStepIndex, currentComponent, prevStep, switchTab, tabs, currentTab} = useContext(StepperContext);

    return (
        <>
            <div className="login-container-tab">
                {currentStepIndex > 1 && <RightOutlined onClick={prevStep} className="back-button" />}

                <Tabs
                    onChange={switchTab}
                    className="login-container-options-tab"
                    activeKey={currentTab}
                    items={(tabs ?? []).map(({tabKey, text}) => ({label: text, key: tabKey}))}
                />
            </div>

            <TempCodeContextProvider>
                {!!currentComponent && <div className="login-container-component">{currentComponent}</div>}
            </TempCodeContextProvider>
        </>
    );
};
