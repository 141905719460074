import {OrderStatus} from "../../components";
import {StringBuilder} from "../../AppUtils.js";

export const statusToHebrewAction = {
    [OrderStatus.accepted]: "לאשר",
    [OrderStatus.cancelled]: "לבטל",
    [OrderStatus.pending]: "לחדש"
};

export const PRICE_THRESHOLD = 100000;

export const ORDERS_PER_PAGE = 100;

export const OrdersStatisticsLabelFields = {
    customersOrdered: "לקוחות שהזמינו",
    totalWithoutVAT: "סה״כ ללא מע״מ",
    paymentTypes: "צורות תשלום",
    uniqueTransactions: "עסקאות יוניקיות",
    transactions: "טרנזאקציות"
};

export const triggerParentLabels = [OrdersStatisticsLabelFields.customersOrdered];

export const PRICE_WARNING_PERCENT = 0.8;

export const ORDER_DEADLINE_HOURS = 72;

export const EDIT_ORDER_ERRORS = {
    CONTACT_CSM: "לא ניתן לערוך את ההזמנה - צרו קשר עם מנהלת הלקוח שלכם",
    MANAGER_APPROVE: "לא ניתן לערוך הזמנה שממתינה לאישור מנהל, מוזמנים ליצור קשר עם המנהל לסירוב/ אישור ההזמנה",
    VOUCHER: "לא ניתן לערוך שובר. ניתן לכתוב לנו בוואטסאפ ונמצא פתרון",
    CANCEL_OR_DECLINE: "לא ניתן לערוך הזמנה זו. מזמינים אותך לבצע הזמנה חדשה",
    CREDIT_CARD: "לא ניתן לערוך הזמנה שבוצעה בכרטיס אשארי. ניתן לכתוב לנו בוואטסאפ ונמצא פתרון",
    ORDER_CHANGE_TIME_EXPIRED: new StringBuilder("לא ניתן לערוך הזמנה בטווח של")
        .append(ORDER_DEADLINE_HOURS)
        .append("שעות לפני האספקה, ניתן לכתוב לנו בוואטסאפ")
        .toString()
};
