export const ProfessionType = {
    REVENUE_BASED: "revenue-based",
    PER_ACTIVE_DAY: "per-active-day"
};

export const ProfessionText = {
    [ProfessionType.REVENUE_BASED]: "Revenue Based",
    [ProfessionType.PER_ACTIVE_DAY]: "Per Active Day"
};

export const isAppointmentBasedProfession = (professionName, professions) => {
    if (Array.isArray(professionName) && professionName.length > 1) {
        return false;
    } else if (Array.isArray(professionName)) {
        const name = professionName[0];
        const profession = professions.find(p => p.name === name);
        return profession && profession.type === ProfessionType.PER_ACTIVE_DAY;
    } else {
        const profession = professions.find(p => p.name === professionName);
        return profession && profession.type === ProfessionType.PER_ACTIVE_DAY;
    }
};

export const toProfessionName = (profession, defaultValue) =>
    Array.isArray(profession) ? profession.join(", ") : profession || defaultValue;

export const sortProfessions = professions => {
    return professions.sort((p1, p2) => {
        if (p1.name.toLowerCase() > p2.name.toLowerCase()) return 1;
        else if (p2.name.toLowerCase() > p1.name.toLowerCase()) return -1;
        else return 0;
    });
};
