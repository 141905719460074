import {SearchOutlined} from "@ant-design/icons";
import {Input} from "antd";
import React from "react";

export const EXCenterSearchInput = ({ref, value, onEnter, onSearch, placeholder = "Search", style, containerStyle}) => {
    return (
        <div
            className="wb-ss-full-width wb-ss-justify-center"
            style={{display: "flex", paddingLeft: 10, paddingRight: 10, ...containerStyle}}>
            <div
                className="wb-ss-full-width"
                style={{
                    width: 340,
                    backgroundColor: "#F2F4F6",
                    borderRadius: 25,
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    marginBottom: 30,
                    maxWidth: 550,
                    ...style
                }}>
                <span style={{marginLeft: 20, marginTop: 2}}>
                    <SearchOutlined style={{color: "#767F90"}} />
                </span>
                <Input
                    style={{
                        display: "flex",
                        alignItems: "center",
                        height: 50,
                        backgroundColor: "#F2F4F6",
                        border: "none",
                        fontSize: 16,
                        fontWeight: 300,
                        color: "#8398A6",
                        paddingLeft: 12,
                        boxShadow: "none"
                    }}
                    autoFocus={true}
                    placeholder={placeholder}
                    ref={ref}
                    onPressEnter={onEnter}
                    value={value}
                    onChange={({target}) => onSearch(target.value)}
                />
            </div>
        </div>
    );
};
