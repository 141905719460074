import {PageSubTitleLabel} from "../../../components/PageTitle";
import {Select, Form} from "antd";
import React from "react";
import {FormInput} from "../../../components/form";
import {isValidURL} from "../../../utils/UrlValidations";

const {Option} = Select;

const ExternalLinkInput = ({value, onChange, maxLength}) => {
    const {enabled, link, buttonText} = value || {enabled: true};

    const doChange = (en, val, lnk) => {
        onChange({enabled: en, buttonText: val, link: lnk});
    };

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", alignItems: "center"}} onClick={() => doChange(true, buttonText, link)}>
                <FormInput
                    style={{maxWidth: 200, marginRight: 20, pointerEvents: enabled ? "auto" : "none"}}
                    placeholder="Button text"
                    maxLength={maxLength}
                    value={buttonText}
                    onChange={({target}) => doChange(enabled, target.value, link)}
                />
                <FormInput
                    placeholder="Button link"
                    style={{pointerEvents: enabled ? "auto" : "none"}}
                    value={link}
                    onChange={({target}) => doChange(enabled, buttonText, target.value)}
                />
            </div>
        </div>
    );
};

export const EventActionButtonFormInput = ({
    actionButtonType,
    onActionButtonTypeChange,
    labelStyle,
    actionTypeStyle,
    onActionButtonTextChange
}) => {
    return (
        <>
            <PageSubTitleLabel
                style={
                    labelStyle
                        ? labelStyle
                        : {
                              marginBottom: 5,
                              justifyContent: "flex-start",
                              paddingLeft: 8
                          }
                }>
                Choose how to display the event's action button
            </PageSubTitleLabel>
            <Form.Item name="actionType" style={{marginBottom: 0, ...actionTypeStyle}}>
                <Select
                    defaultValue={actionButtonType}
                    className="wb-event-action-button-select"
                    onSelect={type => onActionButtonTypeChange(type)}>
                    <Option value="system">System button</Option>
                    <Option value="externalLink">External link</Option>
                    <Option value="none">No button</Option>
                </Select>
            </Form.Item>
            {actionButtonType === "externalLink" ? (
                <Form.Item
                    name="externalLink"
                    rules={[
                        {
                            validator: (_, value) => {
                                if (!value) {
                                    return Promise.reject("Please enter button text and link.");
                                } else {
                                    const {buttonText, link} = value;
                                    if (!buttonText) {
                                        return Promise.reject("Please enter button text.");
                                    } else if (!link || !isValidURL(link)) {
                                        return Promise.reject("Please enter valid button link.");
                                    }
                                }

                                return Promise.resolve();
                            }
                        }
                    ]}
                    style={{marginBottom: 10}}>
                    <ExternalLinkInput
                        maxLength={30}
                        onChange={obj => {
                            if (onActionButtonTextChange) {
                                return onActionButtonTextChange(obj.buttonText);
                            }
                        }}
                    />
                </Form.Item>
            ) : null}
        </>
    );
};
