import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Route} from "react-router";
import {extractTabFromUrl} from "./DefaultAppDashboard";
import {WhiteListSearchKeys} from "./consts";
import {ProviderDetails} from "../provider/ProviderDetails";
import {EventOffer} from "../event/EventOffer";
import {CompanyMarketplaceContextProvider} from "../marketplace/CompanyMarketplaceContextProvider";
import {MarketplaceSearchResultsPage} from "../marketplace/MarketplaceSearchResults";
import {getLogger} from "../Logger";
import EventsGalleryPage from "../company/EventsGallary";
import {MyLikes} from "../company/MyLikes";
import {AppContext} from "../AppContext";
import OrdersCalendarPage from "../ordersCalendar";
import {TickerNotification} from "../ordersCalendar/OrderTicker";
import {OrdersCalendarRoutes} from "../ordersCalendar/consts";
import {YearlyView} from "../ordersCalendar/YearlyView";
import {EventBus} from "../bus/EventBus";
import {isObjectWithEmptyFields} from "../utils/objectUtils";
import "./app-dashboard.css";
import {FILTER_VERSION} from "../marketplace/MarketplaceFilter/consts.js";
import {MarketplaceSearchHeader} from "../marketplace/MarketplaceSearchHeader.jsx";
import {StringBuilder} from "../AppUtils.js";

export const CompanyDashboard = ({history}) => {
    const {me, searchInput, setSearchInput, filterValues, setFilterValues} = useContext(AppContext);
    const [debouncedSearchText, setDebouncedSearchText] = useState(null);

    const removeSearchInput = useCallback(text => {
        setSearchInput(null);
        setDebouncedSearchText(null);
        setFilterValues(currentFilterValues => {
            const {searchText, searchTags, ...otherFilterValues} = currentFilterValues;
            return otherFilterValues;
        });
    }, []);

    const log = useMemo(
        () =>
            getLogger(
                {
                    companyId: me.companyId,
                    companyName: me.companyName,
                    version: FILTER_VERSION
                },
                "CompanyDashboard"
            ),
        [me.companyId, me.companyName]
    );

    const activeKey = useMemo(() => {
        return extractTabFromUrl(
            /^\/dashboard\/(services|orders|search|schedule|content|billing|profile|employees|insights|myservices|bookings|weekly|gallery|mylikes|orders-calendar)(.*)$/
        );
    }, []);

    useEffect(() => {
        EventBus.on("clearFilterValues", async () => {
            setFilterValues({});
            setSearchInput("");

            const queryParams = new URLSearchParams(history.location.search);
            const queryParamsKeys = Array.from(queryParams.keys());
            for (const key of queryParamsKeys) {
                if (!Object.values(WhiteListSearchKeys).includes(key)) {
                    queryParams.delete(key);
                }
            }

            const newQueryParam = queryParams.toString();
            history.replace({
                pathname: history.location.pathname,
                search: newQueryParam
            });
        });

        return () => {
            EventBus.off("clearFilterValues");
        };
    }, []);

    useEffect(() => {
        if (!activeKey) {
            history.push(`/dashboard/services${window.location.search || ""}`);
        }
    }, [activeKey]);

    useEffect(() => {
        const pathname = window.location.pathname;
        if (
            isObjectWithEmptyFields(filterValues) &&
            pathname === "/dashboard/search" &&
            !Object.keys(filterValues).length
        ) {
            history.replace("/dashboard/services");
        }
    }, [filterValues, window.location.pathname]);

    const {planBudgetOrder, setPlanBudgetOrder} = useContext(AppContext);

    return (
        <div>
            <MarketplaceSearchHeader
                history={history}
                log={log}
                debouncedSearchText={debouncedSearchText}
                setDebouncedSearchText={setDebouncedSearchText}
            />

            {!!Object.keys(planBudgetOrder ?? {})?.length && (
                <TickerNotification
                    onClose={() => setPlanBudgetOrder(null)}
                    contentString={new StringBuilder()
                        .append("תיאום עבור:")
                        .append(planBudgetOrder?.name)
                        .append("|")
                        .append(`${planBudgetOrder?.price}₪`)
                        .append(planBudgetOrder?.priceType)
                        .toString()}
                    backgroundColor={planBudgetOrder?.color}
                />
            )}

            <div id="services">
                <Route exact path="/dashboard/search">
                    <MarketplaceSearchResultsPage
                        history={history}
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                        searchInput={searchInput}
                        removeSearchInput={removeSearchInput}
                        log={log}
                    />
                </Route>
                <Route path="/dashboard/services">
                    <CompanyMarketplaceContextProvider history={history} rootPath="/dashboard/services" log={log} />
                </Route>
                <Route path="/dashboard/mylikes" component={MyLikes} />
                {me.features?.pinterest ? <Route path="/dashboard/gallery" component={EventsGalleryPage} /> : <div />}
                <Route exact path="/dashboard/services/:serviceId/providers/:providerId" component={ProviderDetails} />
                <Route
                    exact
                    path="/dashboard/services/:serviceId/providers/:providerId/offer/single"
                    component={EventOffer}
                />
                <Route
                    exact
                    path="/dashboard/services/:serviceId/providers/:sessionId/offer/multiple"
                    component={EventOffer}
                />
                {me.features?.orders_calendar ? (
                    <Route exact path={OrdersCalendarRoutes.ORDERS_CALENDAR} component={OrdersCalendarPage} />
                ) : null}
                {me.features?.calculator ? (
                    <Route path={OrdersCalendarRoutes.YEARLY_VIEW} component={YearlyView} />
                ) : null}
            </div>
        </div>
    );
};
