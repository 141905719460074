import React from "react";
import {Tooltip} from "antd";
import classNames from "classnames";
import {CopyableSpan} from "../../components";
import {GoogleMapMainAreaKey, GoogleMapSubAreaApiKeys} from "../../consts.js";
import {StringBuilder} from "../../AppUtils.js";
import {InteractionOutlined} from "@ant-design/icons";
import classnames from "classnames";

export const columns = (editLocation, openOverrideAddressComponentsModal) => {
    return [
        {
            title: "#",
            key: "#",
            width: 70,
            render(_, __, idx) {
                return `${idx + 1}`;
            }
        },
        {
            title: "כתובת",
            dataIndex: "formatted_address",
            render(address, {isFirstLocation}) {
                return <span className={classNames({isFirstLocation})}>{address}</span>;
            }
        },
        {
            title: "חיפושים אפשריים",
            dataIndex: "textualAddresses",
            render(textualAddresses, location) {
                return (
                    <Tooltip title={textualAddresses?.join("\n")}>
                        <span onClick={() => editLocation(location)} className="admin-locations-textual-address-cell">
                            {textualAddresses?.join(", ")}
                        </span>
                    </Tooltip>
                );
            }
        },
        {
            title: "רבדים",
            render({address_components, overrideAddressComponents}) {
                const isHasOverrideAddressComponents =
                    Array.isArray(overrideAddressComponents) && overrideAddressComponents?.length;
                const addressComponents = isHasOverrideAddressComponents
                    ? overrideAddressComponents
                    : address_components;
                const areaId = addressComponents.find(({types}) => types.includes(GoogleMapMainAreaKey))?.place_id;
                const subAreaId = addressComponents.find(({types}) =>
                    types.some(type => GoogleMapSubAreaApiKeys.includes(type))
                )?.place_id;

                const layersFormat = [areaId ? "עיר" : "", subAreaId ? "מחוז" : ""].filter(Boolean).join(", ");

                return (
                    <span
                        className={classnames({"is-has-override-address-components": isHasOverrideAddressComponents})}>
                        {layersFormat}
                    </span>
                );
            }
        },
        {
            title: "מזהה",
            dataIndex: "place_id",
            width: 100,
            render(id) {
                return <CopyableSpan>{id}</CopyableSpan>;
            }
        },
        {
            title: "פעולות",
            render({place_id}) {
                return (
                    <>
                        <InteractionOutlined
                            style={{fontSize: 16}}
                            onClick={() => openOverrideAddressComponentsModal(place_id)}
                        />
                    </>
                );
            }
        }
    ];
};
