import React from "react";
import {Images} from "../images/Images";
import {openWindow} from "../http/WindowOpener";

export const FeedbackBox = () => {
    const openFeedbackPage = () => {
        openWindow("https://wellbfeedback.hipporello.net/desk", "_blank", true);
    };

    return (
        <div
            onClick={openFeedbackPage}
            style={{
                width: 90,
                height: 90,
                position: "fixed",
                right: 20,
                bottom: 70,
                zIndex: 1000,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer"
            }}>
            <img width={90} height={90} src={Images.feedback} alt="tutorial" />
        </div>
    );
};
