import React, {useContext, useState} from "react";
// import MultiToggle from "react-multi-toggle";
import PageTitleHeader, {PageSubTitleLabel} from "../components/PageTitle";
import CompanyInfoSettings from "./CompanyInfoSettings";
import {CompanySubscriptionSettings} from "./CompanySubscriptionSettings";
import {CompanyNotificationSettings} from "./CompanyNotificationSettings";
import {AppContext} from "../AppContext";

const ToggleOption = {
    Information: 0,
    Subscription: 1,
    Notifications: 2
};

const toggleOptions = [
    {
        displayName: "Information",
        value: ToggleOption.Information
    },
    {
        displayName: "Subscription",
        value: ToggleOption.Subscription
    },
    {
        displayName: "Notifications",
        value: ToggleOption.Notifications
    }
];

export const CompanyAccountSettings = () => {
    const {me} = useContext(AppContext);
    const [toggle, setToggle] = useState(0);

    const onToggleChange = val => {
        setToggle(val);
    };

    return (
        <div style={{display: "flex", alignItems: "center", flexDirection: "column", marginTop: 80}}>
            <PageTitleHeader style={{marginBottom: 15}}>Account settings</PageTitleHeader>
            <PageSubTitleLabel style={{marginTop: 0, marginBottom: 30}}>
                Edit your account information
            </PageSubTitleLabel>
            <MultiToggle
                options={toggleOptions}
                selectedOption={toggle}
                onSelectOption={onToggleChange}
                className="wb-company-account-toggle"
            />
            <div style={{display: "flex", justifyContent: "center", width: "100%", marginTop: 20}}>
                <div style={{maxWidth: 1000, width: "100%", marginLeft: 10, marginRight: 10, marginBottom: 150}}>
                    <CompanyInfoSettings visible={toggle === ToggleOption.Information} />
                    <CompanySubscriptionSettings visible={toggle === ToggleOption.Subscription} />
                    <CompanyNotificationSettings visible={toggle === ToggleOption.Notifications} />
                </div>
            </div>
        </div>
    );
};
