import React, {useContext, useMemo, useState} from "react";
import moment from "moment-timezone";
import {message} from "antd";
import ProviderManageInvoicesTable from "./ProviderManageInvoicesTable";
import {ProviderContext} from "../ProviderContext";
import {OrderStatus} from "../../components";
import {useRequest} from "../../utils/hooks";
import {HttpClient} from "../../http/HttpClient";
import {ProviderNetPaymentTermsTypes} from "../../admin/upload/AdminUploadUtils";
import "./provider-manage-invoices.css";

const ProviderManageInvoices = () => {
    const {providerDetails} = useContext(ProviderContext);
    const [selectedMonth, setSelectedMonth] = useState(moment().subtract(1, "month"));

    const ordersReqBody = useMemo(
        () => ({
            from: selectedMonth.clone().startOf("month").valueOf(),
            to: selectedMonth.clone().endOf("month").valueOf(),
            byCreatedAt: false,
            statuses: [OrderStatus.accepted, OrderStatus.pending]
        }),
        [selectedMonth]
    );

    const [orders, loadingOrders] = useRequest(
        "/api/events/provider/myOrders",
        "POST",
        ordersReqBody,
        [ordersReqBody],
        !!selectedMonth
    );
    const [monthInvoices, _, __, refreshMonthInvoices] = useRequest(
        `/api/providerInvoice/getMonthInvoices/${selectedMonth.format("MM-YYYY")}`
    );

    return (
        <div className="provider-manage-invoices">
            <div className="provider-manage-invoices-title">{providerDetails?.businessName}</div>
            <ProviderManageInvoicesTable
                orders={orders}
                loadingOrders={loadingOrders}
                invoices={monthInvoices}
                selectedMonth={selectedMonth}
                setSelectedMonth={setSelectedMonth}
                providerDetails={providerDetails}
                logsURI={`/api/providerInvoiceLog/byMonth/${selectedMonth.format("MM-YYYY")}`}
                uploadInvoice={async invoice => {
                    const {error} = await HttpClient.safePut("/api/providerInvoice/createInvoice", invoice);

                    if (error) {
                        message.error("יצירת החשבונית נכשלה, אנא נסה שנית מאוחר יותר");
                    }

                    refreshMonthInvoices();
                }}
            />
            <div className="provider-manage-invoices-disclaimers">
                <div>
                    <b>תנאי תשלום ⛳</b>
                    <div>
                        <ul>
                            <li>
                                התשלום לשותפים מתבצע על ידי well-b עצמה בתנאי שוטף +
                                {providerDetails?.netPaymentTerms ?? ProviderNetPaymentTermsTypes.Net38} מרגע{" "}
                                <mark>האספקה</mark>
                            </li>
                            <li>
                                על מנת שנוכל להבטיח את התשלום בתנאים האמורים, הנכם מתבקשים לטעון פה את החשבונית עד ה-10
                                בחודש העוקב לחודש שבו <mark>סופקה ההזמנה</mark>.
                                <div>
                                    לדוגמה - חשבונית עבור הזמנות <mark>שסופקו</mark>
                                    באוקטובר צריכה להגיע אלינו עד ה-10 בנובמבר. חשבונית מס שתועבר לאחר מכן, התשלום בגינה
                                    ידחה למועד התשלום הבא.
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <b>דגשים להוצאת החשבונית 🧰</b>
                    <ul>
                        <li>
                            ריכוז כלל העסקאות: החשבונית שאתם מוציאים צריכה להיות מרכזת - כלומר כל העסקאות שהיו באותו
                            חודש.
                        </li>
                        <li>הורדת העמלה: הסכום הכולל בחשבונית שאתם מוציאים צריך להיות פחות העמלה.</li>
                        <li>פרטים לחשבונית: וולביז בע״מ | 516369964 | כנרת 15, בני ברק 5120260</li>
                        <li>פירוט מוצרים: פירוט המוצרים בחשבונית צריך לכלול את מס׳ ההזמנה, שם החבילה והמחיר.</li>
                        <li>כותרת החשבונית: חשבונית מס מ_____ עבור פעילות חודש XX</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ProviderManageInvoices;
