// import cscFns from "country-state-city";

const {getAllCountries, getStatesOfCountry, getCitiesOfState, getCountryById, getStateById, getCityById} = {};

const citiesFixMap = {
    IL: [
        {id: "50000", name: "Herzliya"},
        {id: "50001", name: "Ramat Gan"},
        {id: "50002", name: "Giv'atayim"},
        {id: "50003", name: "Holon"},
        {id: "50004", name: "Haifa"},
        {id: "50005", name: "Tel Aviv - Yafo"},
        {id: "50006", name: "Kiryat Tam"},
        {id: "50007", name: "Jaffa"},
        {id: "50008", name: "Beit Hanania"},
        {id: "50009", name: "Bat Yam"}
    ],
    AU: [{id: "50010", name: "Sydney"}]
};

export const isUS = countryId => countryId === "231";

class CountyStateCityClient {
    country(countryId) {
        const country = getCountryById(countryId);
        return {
            countryName: country.name,
            countryId: country.id,
            countryCode: country.sortname,
            phonePrefix: `+${country.phonecode}`
        };
    }

    state(stateId) {
        const state = getStateById(stateId);
        return {
            stateName: state.name,
            stateId: state.id
        };
    }

    city(cityId) {
        const foundIndexObj = Object.keys(citiesFixMap).reduce(
            (idx, countryCode) => {
                const cities = citiesFixMap[countryCode];
                const foundIdx = cities.findIndex(city => city.id === cityId);
                if (foundIdx >= 0) {
                    return {countryCode, idx: foundIdx};
                }

                return idx;
            },
            {countryCode: null, idx: -1}
        );

        if (foundIndexObj.idx >= 0) {
            const cityFix = citiesFixMap[foundIndexObj.countryCode][foundIndexObj.idx];
            return {
                cityName: cityFix.name,
                cityId: cityFix.id
            };
        }

        const city = getCityById(cityId);
        return {
            cityName: city.name,
            cityId: city.id
        };
    }

    countries() {
        const countries = getAllCountries();
        return countries.map(country => {
            return {
                countryName: country.name,
                countryId: country.id,
                countryCode: country.sortname,
                phonePrefix: `+${country.phonecode}`
            };
        });
    }

    states(countryId) {
        const states = getStatesOfCountry(countryId);
        return states.map(state => {
            return {
                stateName: state.name,
                stateId: state.id
            };
        });
    }

    cities(stateId, countryCode) {
        const cities = getCitiesOfState(stateId);
        const citiesToFix = citiesFixMap[countryCode];
        return cities.concat(citiesToFix ? citiesToFix : []).map(city => {
            return {
                cityName: city.name,
                cityId: city.id
            };
        });
    }

    allCities(countryId) {
        const country = this.country(countryId);
        const states = this.states(countryId);
        const all = states
            .reduce((allCities, state) => {
                const cities = this.cities(state.stateId, country.countryCode);
                return allCities.concat(cities);
            }, [])
            .sort((c1, c2) => {
                if (c1.cityName.toLowerCase() > c2.cityName.toLowerCase()) return 1;
                else if (c2.cityName.toLowerCase() > c1.cityName.toLowerCase()) return -1;
                else return 0;
            });

        return all.filter((city, idx) => all.map(city => city.cityName).indexOf(city.cityName) === idx);
    }
}

export const csc = new CountyStateCityClient();
