import React, {Fragment, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {flatten, isEmpty, isNumber} from "lodash";
import {Collapse, ConfigProvider, message, Spin, Upload} from "antd";
import {DownloadOutlined, UploadOutlined, UserOutlined} from "@ant-design/icons";
import classNames from "classnames";
import classnames from "classnames";
import {OrderLocationSingleAddressInfoModal} from "./OrderLocationSingleAddressInfoModal";
import {downloadTemplate, validateAndTransformDeliveryProviderExcel, validateExcel} from "./orderLocationExcelUtils";
import {expandIconController, getAllLocations} from "../MarketplaceHelper";
import {OrderFormErrorLabel} from "../OrderFormErrorLabel";
import {GenericModal} from "../../components";
import {ActivityLocation, ActivityLocationTypes} from "../../consts.js";
import {Images} from "../../images/Images";
import {TrashIcon} from "../../icons";
import {EventBus} from "../../bus/EventBus";
import {loadExcel} from "../../utils/excelUtils.js";
import {downloadFromUrl} from "../../utils/downloadFromUrl";
import {NOOP} from "../../utils/NOOP";
import {AutoCompleteAddressFormInput} from "../../utils/AutoCompleteAddress";
import {OrderFormPanelKeys} from "../consts";
import {
    BOOKING_ERROR_TO_PANEL,
    BOOKING_TO_PANEL_COLORS,
    DEFAULT_HOME_DELIVERY_PRICE
} from "../MarketplaceBooking/consts";
import {MarketplaceBookingContext} from "../MarketplaceBooking/MarketplaceBookingContext";
import "../marketplace.css";
import {HomeDeliveryUploadMethod} from "./consts";
import {isProductHasShippingProvider} from "../../admin/upload/utils.js";
import {FormInputV2} from "../../components/form/index.jsx";
import {OrderFormPanelHeader} from "../OrderForm.jsx";

const {Panel} = Collapse;
const {excelAddressModalCover} = Images;
export const OrderLocationInput = ({
    content,
    providerDetails,
    onValueChange,
    officeAddress,
    employeesExcel,
    singleEmployeeDetails,
    homeDeliveryUploadMethod,
    chosenAddress,
    chosenActivityLocation,
    onActiveKeyChange,
    activeKey,
    error,
    bookingError,
    floor,
    log = NOOP,
    isDisabled
}) => {
    const {highlightOrderPanel} = useContext(MarketplaceBookingContext);
    const [locations, setLocations] = useState([]);
    const [manualLocation, setManualLocation] = useState("");
    const [numberOfExcelRows, setNumberOfExcelRows] = useState(0);
    const [innerEmployeeExcel, setInnerEmployeeExcel] = useState(employeesExcel);
    const [innerSingleEmployeeDetails, setInnerSingleEmployeeDetails] = useState(singleEmployeeDetails);
    const [uploadExcelForHomeDeliveryVisibility, setUploadExcelForHomeDeliveryVisibility] = useState(false);
    const [inputSingleEmployeeForHomeDeliveryVisibility, setInputSingleEmployeeForHomeDeliveryVisibility] =
        useState(false);
    const [loadingExcel, setLoadingExcel] = useState(false);

    const homeDeliveryPrice = useMemo(
        () => content.homeDeliveryPrice ?? providerDetails.homeDeliveryPrice ?? DEFAULT_HOME_DELIVERY_PRICE,
        [content?.homeDeliveryPrice, providerDetails?.homeDeliveryPrice]
    );

    const excelUploadProps = useMemo(
        () => ({
            action: "/api/upload",
            name: "file",
            accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            showUploadList: false,
            withCredentials: true,
            onChange: async info => {
                if (info.file.status === "done") {
                    setLoadingExcel(false);
                    const {imageId, imageUrl} = info.file.response;
                    const fileName = info.file.name;
                    setInnerEmployeeExcel({fileUrl: imageUrl, fileId: imageId, fileName});
                    setUploadExcelForHomeDeliveryVisibility(false);
                    message.success(`קובץ הועלה בהצלחה`, 4);
                } else if (info.file.status === "error") {
                    setLoadingExcel(false);
                    EventBus.triggerError(
                        "server-error",
                        {
                            content: {
                                title: "Upload Failed!",
                                hideSubTitle: true,
                                description: "הייתה בעיה עם העלאת רשימת העובדים."
                            }
                        },
                        info.file.response.error || `${info.file.name} file upload failed.`
                    );
                } else {
                    setLoadingExcel(true);
                }
            },
            beforeUpload: async file => {
                return new Promise(async (resolve, reject) => {
                    const fileReader = new FileReader();
                    fileReader.onload = async data => {
                        const arrayBuffer = data.target.result;
                        const workbook = await loadExcel(arrayBuffer, file.type);
                        const {rowsCount, error} = await validateExcel(workbook);

                        if (!error.length) {
                            setNumberOfExcelRows(rowsCount);
                            return resolve();
                        }

                        log("User Had An Error With Validating Excel");

                        EventBus.triggerError("server-error", {
                            content: {
                                title: "שגיאה בטעינת אקסל",
                                description: error,
                                hideSteps: true
                            }
                        });

                        return reject();
                    };

                    fileReader.readAsArrayBuffer(file);
                });
            }
        }),
        []
    );

    const excelUploadDeliveryProviderProps = useMemo(
        () => ({
            customRequest: NOOP,
            action: async file => {
                return new Promise(async (resolve, reject) => {
                    setLoadingExcel(true);
                    const fileReader = new FileReader();
                    fileReader.onload = async data => {
                        const arrayBuffer = data.target.result;
                        const workbook = await loadExcel(arrayBuffer, file.type);
                        const {errorList, addressesArray} = await validateAndTransformDeliveryProviderExcel(workbook);

                        if (errorList.length) {
                            log("User Had An Error With Validating Excel");

                            EventBus.triggerError("server-error", {
                                content: {
                                    title: "שגיאה בטעינת אקסל",
                                    description: errorList,
                                    hideSteps: true
                                }
                            });
                        } else {
                            setInnerEmployeeExcel({addresses: addressesArray, rowCount: addressesArray.length});
                            setUploadExcelForHomeDeliveryVisibility(false);
                        }
                        setLoadingExcel(false);
                        return resolve();
                    };

                    fileReader.readAsArrayBuffer(file);
                });
            },
            name: "file",
            accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            showUploadList: false,
            withCredentials: true
        }),
        []
    );

    const updateHomeDeliveryMethod = useCallback(
        (chosenMethod = null) => {
            if (!innerSingleEmployeeDetails || chosenMethod === HomeDeliveryUploadMethod.excel) {
                onValueChange("employeesExcel", innerEmployeeExcel);
                onValueChange("singleEmployeeDetails", null);
                onValueChange("homeDeliveryUploadMethod", HomeDeliveryUploadMethod.excel);
            } else if (!innerEmployeeExcel || chosenMethod === HomeDeliveryUploadMethod.singleEmployee) {
                onValueChange("employeesExcel", null);
                onValueChange("singleEmployeeDetails", innerSingleEmployeeDetails);
                onValueChange("homeDeliveryUploadMethod", HomeDeliveryUploadMethod.singleEmployee);
            }
        },
        [innerEmployeeExcel, innerSingleEmployeeDetails]
    );

    const deleteHomeDeliveryMethod = useCallback(
        homeDeliveryUploadMethod => {
            onValueChange(homeDeliveryUploadMethod, null);

            if (!innerEmployeeExcel && !innerSingleEmployeeDetails) {
                onValueChange("homeDeliveryUploadMethod", null);
            }
        },
        [innerEmployeeExcel, innerSingleEmployeeDetails]
    );

    useEffect(() => {
        if (innerEmployeeExcel) {
            updateHomeDeliveryMethod(HomeDeliveryUploadMethod.excel);
        } else if (innerSingleEmployeeDetails) {
            updateHomeDeliveryMethod(HomeDeliveryUploadMethod.singleEmployee);
        }
    }, [innerEmployeeExcel, innerSingleEmployeeDetails]);

    const updateChosenAddress = useCallback(
        chosenAddress => {
            if (chosenAddress === ActivityLocation.manualLocation && manualLocation) {
                onValueChange("chosenAddress", manualLocation);
            } else {
                onValueChange("chosenAddress", chosenAddress);
            }
        },
        [manualLocation]
    );

    useEffect(() => {
        const allLocations = getAllLocations(content, officeAddress);
        const flattenLocations = flatten(allLocations);

        if (flattenLocations.length === 1 && !chosenAddress) {
            const [firstFlattenLocation] = flattenLocations;
            onValueChange("chosenActivityLocation", firstFlattenLocation.activityLocationTypeId);
            updateChosenAddress(firstFlattenLocation.subtitle);
        }

        setLocations([...flattenLocations]);
    }, [officeAddress, content, chosenAddress]);

    useEffect(() => {
        if (innerEmployeeExcel?.fileId) {
            setInnerEmployeeExcel(prev => ({
                ...prev,
                rowCount: numberOfExcelRows
            }));
        }
    }, [setInnerEmployeeExcel, innerEmployeeExcel?.fileId, numberOfExcelRows]);

    const openEmployeesExcel = useCallback(async () => {
        if (!innerEmployeeExcel) {
            return;
        }

        await downloadFromUrl(innerEmployeeExcel.fileUrl, innerEmployeeExcel.fileName);
    }, [innerEmployeeExcel]);

    const addManualAddress = useCallback((manualAddressText, option) => {
        setManualLocation(manualAddressText);
        updateChosenAddress(manualAddressText);
        onValueChange("addressId", option.isGoogleAddress ? option?.place_id : null);
    }, []);

    const removeManualAddress = useCallback(() => {
        setManualLocation("");
        updateChosenAddress(ActivityLocation.manualLocation);
    }, []);

    useEffect(() => {
        const newManualLocation = manualLocation || ActivityLocation.manualLocation;
        if (content?.location.includes(ActivityLocationTypes.manualLocation)) {
            setLocations(prevLocations => {
                const manualAddress = prevLocations.find(({title}) => title === ActivityLocation.manualLocation);

                if (newManualLocation === ActivityLocation.onsite) {
                    manualAddress.subtitle = newManualLocation.name;
                } else {
                    manualAddress.subtitle = newManualLocation;
                }

                return [...prevLocations];
            });
        }
    }, [manualLocation, content?.location]);

    useEffect(() => {
        const isChosenAddressManual =
            chosenAddress && !!locations.length && locations.every(location => location.subtitle !== chosenAddress);

        if (isChosenAddressManual) {
            setManualLocation(chosenAddress);
        }
    }, [locations]);

    const isToggleHomeDeliveryAvailable = useMemo(
        () => innerEmployeeExcel && innerSingleEmployeeDetails,
        [innerEmployeeExcel, innerSingleEmployeeDetails]
    );

    return (
        <Fragment>
            <GenericModal
                visible={uploadExcelForHomeDeliveryVisibility}
                image={excelAddressModalCover}
                onClose={() => setUploadExcelForHomeDeliveryVisibility(false)}
                className={"order-location-input-upload-excel-modal"}
                options={[
                    {
                        id: "uploadExcelForHomeDelivery",
                        text: (
                            <Upload
                                {...(isProductHasShippingProvider(content)
                                    ? excelUploadDeliveryProviderProps
                                    : excelUploadProps)}>
                                <span className="order-location-input-load-excel-title">
                                    {loadingExcel ? <Spin /> : <UploadOutlined style={{marginLeft: "5px"}} />}
                                    טעינת רשימת מקבלים
                                </span>
                            </Upload>
                        )
                    }
                ]}
                title="הפצה למס׳ מקבלים באמצעות קובץ אקסל 📗"
                text={[
                    "אנא תורידו את הפורמט המצורף, תמלאו את המידע לפי העמודות",
                    <span>
                        {" "}
                        {"הרלוונטיות"} <u>{"בעברית בלבד"}</u> {" ותטענו את הרשימה למערכת"}{" "}
                    </span>,
                    <span>
                        {" "}
                        {"שימו לב!"} <u>{"אחרי העלאת הקובץ תינתן עלות השילוח"}</u>
                    </span>,
                    <div style={{cursor: "pointer", direction: "ltr", fontWeight: "bold"}} onClick={downloadTemplate}>
                        הורדת פורמט אקסל <DownloadOutlined style={{color: "#243446"}} />
                    </div>
                ]}
            />
            <OrderLocationSingleAddressInfoModal
                isAllowSelectRegularText={!isProductHasShippingProvider(content)}
                content={innerSingleEmployeeDetails}
                visible={inputSingleEmployeeForHomeDeliveryVisibility}
                onClose={() => setInputSingleEmployeeForHomeDeliveryVisibility(false)}
                onSave={values => {
                    setInnerSingleEmployeeDetails(values);
                    setInputSingleEmployeeForHomeDeliveryVisibility(false);
                }}
            />
            <Collapse
                ghost={true}
                accordion={true}
                className={classNames("marketplace-booking-collapse", {
                    panelError:
                        BOOKING_ERROR_TO_PANEL[bookingError] === OrderFormPanelKeys.Location &&
                        highlightOrderPanel?.id === OrderFormPanelKeys.Location,
                    panelRedirect:
                        highlightOrderPanel?.id === OrderFormPanelKeys.Location &&
                        highlightOrderPanel?.color === BOOKING_TO_PANEL_COLORS.REDIRECT
                })}
                expandIconPosition="left"
                activeKey={activeKey}
                onChange={onActiveKeyChange}
                expandIcon={({isActive}) => expandIconController(isActive)}>
                {
                    <Panel
                        key={"location"}
                        showArrow={true}
                        disabled={isDisabled}
                        header={
                            <OrderFormPanelHeader
                                title="🏢 איפה?"
                                error={error ?? null}
                                subTitle={
                                    !isEmpty(chosenAddress)
                                        ? [
                                              chosenAddress,
                                              isNumber(floor) ? "קומה" : null,
                                              isNumber(floor) ? floor : null
                                          ]
                                              .filter(item => item !== null)
                                              .join(" ")
                                        : "לבחירת פורמט הפעילות הרצוי"
                                }
                                isActive={!isEmpty(chosenAddress)}
                            />
                        }>
                        <div>
                            {locations.map(({id, title, subtitle, elementSubtitle, activityLocationTypeId}) => (
                                <div
                                    key={`${id}-${subtitle}`}
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        flexDirection: "row",
                                        marginBottom: "20px"
                                    }}>
                                    {/*{chosenActivityLocation}*/}

                                    <div style={{marginLeft: "10px"}}>
                                        {(
                                            activityLocationTypeId === ActivityLocationTypes.onsite
                                                ? chosenAddress === subtitle
                                                : chosenActivityLocation === activityLocationTypeId
                                        ) ? (
                                            <img
                                                style={{
                                                    cursor: "pointer",
                                                    width: "20px",
                                                    height: "20px"
                                                }}
                                                src={Images.marketplaceBookingCheckboxChecked}
                                                onClick={() => {
                                                    updateChosenAddress(null);
                                                    onValueChange("chosenActivityLocation", null);
                                                }}
                                                alt="booking-check-icon"
                                            />
                                        ) : (
                                            <img
                                                style={{
                                                    cursor: "pointer",
                                                    width: "20px",
                                                    height: "20px"
                                                }}
                                                src={Images.marketplaceBookingCheckboxEmpty}
                                                onClick={() => {
                                                    updateChosenAddress(subtitle);
                                                    onValueChange("chosenActivityLocation", activityLocationTypeId);
                                                }}
                                                alt="booking-empty-icon"
                                            />
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            flexDirection: "column",
                                            width: "100%"
                                        }}>
                                        <div
                                            style={{
                                                fontSize: "16px",
                                                color: "var(--secondary-color)",
                                                fontWeight: "bold"
                                            }}>
                                            {title}
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%"
                                            }}>
                                            <div
                                                style={{
                                                    fontSize: "16px",
                                                    color: "var(--secondary-color)"
                                                }}>
                                                {elementSubtitle ?? subtitle}
                                            </div>
                                        </div>
                                        {chosenAddress === ActivityLocation.homeDelivery &&
                                        subtitle === ActivityLocation.homeDelivery ? (
                                            <div
                                                className={classnames({
                                                    "order-location-input-home-delivery-toggle-available":
                                                        isToggleHomeDeliveryAvailable
                                                })}>
                                                <span className="order-location-input-home-deliver-disclaimers">
                                                    עלות משוערת לשילוח למספר נק׳ שילוח היא {homeDeliveryPrice} ₪ כולל
                                                    מע״מ, עשוי להשתנות במקרי קיצון 🙏
                                                </span>
                                                <div
                                                    className={classnames(
                                                        "order-location-input-home-delivery-option-row",
                                                        {
                                                            "selected-row":
                                                                employeesExcel &&
                                                                homeDeliveryUploadMethod ===
                                                                    HomeDeliveryUploadMethod.excel
                                                        }
                                                    )}
                                                    onClick={
                                                        isToggleHomeDeliveryAvailable
                                                            ? () =>
                                                                  updateHomeDeliveryMethod(
                                                                      HomeDeliveryUploadMethod.excel
                                                                  )
                                                            : NOOP
                                                    }>
                                                    <span>הפצה למס׳ מקבלים באמצעות קובץ אקסל</span>
                                                    {innerEmployeeExcel ? (
                                                        <span className="order-location-input-home-delivery-has-value">
                                                            <span onClick={openEmployeesExcel}>
                                                                {innerEmployeeExcel.fileName}
                                                            </span>
                                                            <TrashIcon
                                                                onClick={() => {
                                                                    setInnerEmployeeExcel(null);
                                                                    deleteHomeDeliveryMethod("employeesExcel");
                                                                }}
                                                            />
                                                        </span>
                                                    ) : (
                                                        <span
                                                            onClick={() =>
                                                                setUploadExcelForHomeDeliveryVisibility(true)
                                                            }>
                                                            <UploadOutlined />
                                                            טעינת רשימת מקבלים
                                                        </span>
                                                    )}
                                                </div>
                                                <div
                                                    className={classnames(
                                                        "order-location-input-home-delivery-option-row",
                                                        {
                                                            "selected-row":
                                                                singleEmployeeDetails &&
                                                                homeDeliveryUploadMethod ===
                                                                    HomeDeliveryUploadMethod.singleEmployee
                                                        }
                                                    )}
                                                    onClick={
                                                        isToggleHomeDeliveryAvailable
                                                            ? () =>
                                                                  updateHomeDeliveryMethod(
                                                                      HomeDeliveryUploadMethod.singleEmployee
                                                                  )
                                                            : NOOP
                                                    }>
                                                    <span>שילוח למקבל יחיד</span>
                                                    {innerSingleEmployeeDetails ? (
                                                        <span className="order-location-input-home-delivery-has-value">
                                                            <span
                                                                onClick={() =>
                                                                    setInputSingleEmployeeForHomeDeliveryVisibility(
                                                                        true
                                                                    )
                                                                }>
                                                                {innerSingleEmployeeDetails.name}
                                                            </span>
                                                            <TrashIcon
                                                                onClick={() => {
                                                                    setInnerSingleEmployeeDetails(null);
                                                                    deleteHomeDeliveryMethod("singleEmployeeDetails");
                                                                }}
                                                            />
                                                        </span>
                                                    ) : (
                                                        <span
                                                            onClick={() =>
                                                                setInputSingleEmployeeForHomeDeliveryVisibility(true)
                                                            }>
                                                            <UserOutlined />
                                                            הזנת פרטי קשר
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        ) : null}
                                        {title === ActivityLocation.manualLocation &&
                                        [ActivityLocation.manualLocation, manualLocation].includes(chosenAddress) ? (
                                            <>
                                                <ConfigProvider direction="rtl">
                                                    <div className="order-location-input-manual-address-input">
                                                        {manualLocation ? (
                                                            <span
                                                                className="order-location-change-manual-address"
                                                                onClick={removeManualAddress}>
                                                                {`${manualLocation} -  החלף כתובת`}
                                                            </span>
                                                        ) : (
                                                            <AutoCompleteAddressFormInput
                                                                locationTypes={[]}
                                                                onChange={addManualAddress}
                                                                editValue={content.address}
                                                                id="manual-address"
                                                                placeholder="הזן רחוב/ מס׳ בנין/ עיר"
                                                                countriesToSearchIn={["IL"]}
                                                                allowSelectRegularText={
                                                                    !isProductHasShippingProvider(content)
                                                                }
                                                            />
                                                        )}
                                                        <FormInputV2
                                                            className="order-location-input-manual-address-floor-input"
                                                            placeholder="קומה"
                                                            type="number"
                                                            min="0"
                                                            value={floor}
                                                            onChange={e =>
                                                                onValueChange(
                                                                    "floor",
                                                                    e.target.value ? Number(e.target.value) : null
                                                                )
                                                            }
                                                            onWheel={e => e.target.blur()}
                                                        />
                                                    </div>
                                                </ConfigProvider>
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Panel>
                }
            </Collapse>
        </Fragment>
    );
};
