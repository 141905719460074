export const EMPLOYEE_RECEIVER_EXCEL_EXAMPLE_FILE_URL =
    "https://res.cloudinary.com/well-b-prod/raw/upload/v1666522956/Assets/employee_receiver_list.xlsx";
export const EMPLOYEE_RECEIVER_EXAMPLE_FILE_NAME = "פורמט_טעינת_מקבלי_מתנות.xlsx";

export const timeOptions = {
    NOW: "עכשיו",
    CUSTOM_DATE: "במועד מאוחר יותר"
};

export const sendOptions = {
    SMS: "הודעת סמס",
    EMAIL: "מייל"
};

export const receiverOptions = {
    single: "מקבל יחיד",
    multi: "הפצה למס׳ מקבלים"
};

export const wishesOptions = [
    {
        label: "לידה",
        name: "המון מזל טוב לרגל הלידה!\n" + "שיהיה הרבה שמחה, נחת וכמובן - לילות שקטים\n" + "באהבה גדולה,"
    },
    {
        label: "חתונה",
        name: "מזל טוב והמון שנים של זוגיות מאושרת!\n" + "תהנו מהמסע המשותף יחד :)"
    },
    {
        label: "רפואה שלמה",
        name: "איחולי רפואה שלמה לך אנו שולחים,\n" + "ולרפואתך תמיד מתפללים."
    },
    {
        label: "פשוט תודה",
        name: "תודה היא מילה יפה עם משמעות גדולה,\n" + "אז פשוט תודה!"
    },
    {
        label: "שנה טובה ומתוקה",
        name: "שנה טובה ומתוקה לכל המשפחה,\n" + "שנה של הצלחות, בריאות, צחוק ושמחה."
    },
    {
        label: "יום הולדת שמח",
        name: "המון מזל טוב ליום ההולדת!\n" + "שיהיה יום מדהים, מלא באושר ובחיוכים :)"
    },
    {
        label: "סתם כי בא לי לפנק",
        name: "סתם ככה בלי שום סיבה מיוחדת\n" + "התחשק לי לשלוח לך מתנה מפנקת\n" + "באהבה ♥"
    },
    {
        label: "יום נישואין שמח",
        name: "המון מזל טוב ליום הנישואין,\n" + "לעוד הרבה שנים של אהבה ואושר\n" + "תהנו מהמתנה!"
    }
];

export const voucherExcelProperty = {
    receiverName: "receiverName",
    phone: "phone",
    email: "email",
    greeting: "greeting"
};

export const voucherExcelPropertyToHebrew = {
    [voucherExcelProperty.receiverName]: "שם המקבל",
    [voucherExcelProperty.phone]: "טלפון",
    [voucherExcelProperty.email]: "אימייל",
    [voucherExcelProperty.greeting]: "ברכה"
};
