import React, {useCallback, useContext, useMemo, useRef, useState} from "react";
import {isEmpty} from "lodash";
import {Checkbox, Spin, Tooltip} from "antd";
import classNames from "classnames";
import classnames from "classnames";
import {CloseCircleOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {MarketplaceBookingContext} from "./MarketplaceBookingContext";
import {getDefaultTypeToShow, INITIAL_AMOUNT, typeToHebrewName, typeToPluralHebrewName} from "../MarketplaceUtils";
import {MarketplaceContext} from "../marketplaceContext";
import {getPriceByAmount} from "../MarketplaceHelper";
import {SmartImageContainer, StyledButton} from "../../components";
import cover_placeholder from "../../images/cover_placeholder.jpg";
import {AutoDirectionProvider} from "../../AutoDirectionProvider";
import {AppContext} from "../../AppContext";
import {FormInputV2} from "../../components/form";
import {reduceVAT} from "../../utils/calculationUtils";
import "./marketplace-order-preview.css";
import "../marketplace.css";
import {CouponDiscountTypes} from "../../admin/AdminCoupons/consts.js";
import common from "@well-b/well-b-shared";
import {StringBuilder} from "../../AppUtils.js";
import {PolicyModal} from "../Policy/PolicyModal.jsx";
import {OrderFormPanelKeys} from "../consts.jsx";
import {ValidateBookingError} from "../../consts.js";
import {BOOKING_ERROR_TO_PANEL} from "./consts.jsx";

export const MarketplaceOrderPreview = ({
    content,
    formValues,
    bookingLoading,
    onFinishClick,
    canFinish,
    coupon,
    couponDiscountAmount,
    onCouponAdded,
    onCancelCoupon,
    deliveryPrice,
    loadingDeliveryPrice,
    upgradeOptionsPrice,
    companyDiscount,
    tip,
    bookingError
}) => {
    const {company, me} = useContext(AppContext);
    const {providerDetails} = useContext(MarketplaceContext);
    const {offeredPrice, highlightOrderPanel} = useContext(MarketplaceBookingContext);

    const couponInputRef = useRef(null);
    const [couponCodeInput, setCouponCodeInput] = useState(null);
    const [visiblePolicyModal, setVisibleModal] = useState(false);

    const onCouponSendClicked = useCallback(async () => {
        const couponCode = couponInputRef.current.input.value;
        setCouponCodeInput(couponCode);
        onCouponAdded(couponCode);
    }, [couponInputRef, onCouponAdded]);

    const cancelCoupon = useCallback(async () => {
        setCouponCodeInput(null);
        onCancelCoupon();
    }, [onCancelCoupon]);

    const calculatePrice = useMemo(() => {
        return formValues?.modularProducts?.reduce((acc, formValue) => {
            return acc + formValue.price * (formValue?.amount ?? formValue?.defaultAmount);
        }, 0);
    }, [formValues.modularProducts]);

    const PriceDescriptionByChosenPriceType = useCallback(() => {
        switch (content.chosenPriceType) {
            case "byModular":
                return (
                    <>
                        <div>הרכב החבילה</div>
                        <div>{calculatePrice?.toFixed(0)} ₪</div>
                    </>
                );
            case "byVoucher":
                const voucherReceiversAmount = formValues.singleEmployeeDetails
                    ? 1
                    : formValues.employeesExcel?.rowCount ?? "XXX";
                const voucherAmount = formValues?.voucherOption?.redeemableValue ?? "XXX";

                return (
                    <>
                        <div>{voucherReceiversAmount} מקבלי מתנות</div>
                        <div>{voucherAmount} ₪ למקבל</div>
                    </>
                );
            default:
                return (
                    <>
                        <div>
                            {formValues.amount || INITIAL_AMOUNT}{" "}
                            {typeToPluralHebrewName(getDefaultTypeToShow(content))}
                        </div>
                        {
                            <div>
                                {getPriceByAmount(formValues.amount, content)} ₪ {"ל"}
                                {typeToHebrewName(getDefaultTypeToShow(content))}
                            </div>
                        }
                    </>
                );
        }
    }, [
        calculatePrice,
        content.chosenPriceType,
        formValues?.singleEmployeeDetails,
        formValues?.employeesExcel,
        formValues?.voucherOption,
        formValues.amount
    ]);

    const total = useMemo(() => offeredPrice + (deliveryPrice || 0) + tip, [offeredPrice, deliveryPrice, tip]);

    const totalAfterDiscount = useMemo(() => {
        if (couponDiscountAmount || companyDiscount) {
            return offeredPrice + (deliveryPrice || 0) - (companyDiscount || 0) - (couponDiscountAmount || 0) + tip;
        }
        return null;
    }, [offeredPrice, deliveryPrice, couponDiscountAmount, companyDiscount, tip]);

    const totalWithoutVat = useMemo(() => reduceVAT(totalAfterDiscount ?? total), [totalAfterDiscount, total]);

    return (
        <div className="marketplace-order-preview">
            <SmartImageContainer
                className="marketplace-order-preview-product-image"
                width={400}
                height={220}
                src={!isEmpty(content.portfolioImages) ? content.portfolioImages[0]?.imageUrl : cover_placeholder}
            />
            <AutoDirectionProvider text={"עברית"}>
                <div className="marketplace-order-preview-summary-container">
                    <div className="marketplace-order-preview-title">סיכום הזמנה</div>
                    <div className="marketplace-order-preview-summary-line">
                        <PriceDescriptionByChosenPriceType />
                    </div>
                    {!providerDetails?.isVoucherProvider && (
                        <div className="marketplace-order-preview-summary-line">
                            <div>
                                <div>
                                    {content?.doesProviderArriveToCustomer ??
                                    providerDetails?.doesProviderArriveToCustomer
                                        ? "עלות נסיעות"
                                        : "עלות משלוח"}
                                </div>

                                {formValues?.employeesExcel ? (
                                    <div className="marketplace-order-preview-summary-line-tooltip-container">
                                        <Tooltip
                                            overlayClassName="marketplace-order-preview-summary-line-tooltip"
                                            title={
                                                <div>
                                                    {new StringBuilder()
                                                        .append(formValues.employeesExcel?.rowCount ?? "XX")
                                                        .append("מקבלי מארז")
                                                        .toString()}
                                                </div>
                                            }>
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </div>
                                ) : null}
                            </div>
                            <div>{loadingDeliveryPrice ? <Spin /> : <>{deliveryPrice ?? INITIAL_AMOUNT} ₪</>}</div>
                        </div>
                    )}
                    {upgradeOptionsPrice ? (
                        <div className="marketplace-order-preview-summary-line">
                            <div>שדרוגים</div>
                            <div>{upgradeOptionsPrice} ₪</div>
                        </div>
                    ) : null}
                    {formValues.tipPercentage ? (
                        <div className="marketplace-order-preview-summary-line">
                            <div>טיפ</div>
                            <div>{tip.toLocaleString()} ₪</div>
                        </div>
                    ) : null}
                    {couponDiscountAmount ? (
                        <div className={classNames("marketplace-order-preview-summary-line", "discount-line")}>
                            <span>
                                הנחת קוד קופון
                                {coupon.discountType === CouponDiscountTypes.ByPercentage ? (
                                    <span className="marketplace-order-preview-subtitle">
                                        {` בגובה ${coupon?.discount}%`}
                                    </span>
                                ) : null}
                            </span>

                            <span>{couponDiscountAmount}- ₪</span>
                        </div>
                    ) : null}
                    {company?.ordersDiscount ? (
                        <div className={classNames("marketplace-order-preview-summary-line", "discount-line")}>
                            <span>
                                הנחה מיוחדת
                                <span className="marketplace-order-preview-subtitle">
                                    {` בגובה ${company.ordersDiscount}%`}
                                </span>
                            </span>

                            <span>{companyDiscount ? `${companyDiscount}-` : "XX"} ₪</span>
                        </div>
                    ) : null}
                </div>
                <div className="marketplace-order-preview-bottom-panel">
                    <div
                        className={classnames("marketplace-order-preview-pay-amount-line", {
                            "discount-applied": couponDiscountAmount || companyDiscount
                        })}>
                        <div>סה״כ</div>
                        {(formValues?.amount ?? formValues?.modularProducts) && offeredPrice ? (
                            <div className="marketplace-order-preview-pay-amount">
                                <div>{total?.toFixed(0)} ₪</div>
                                <span>כולל מע״מ</span>
                            </div>
                        ) : null}
                    </div>
                    {totalAfterDiscount ? (
                        <div className="marketplace-order-preview-pay-amount-line">
                            <span>סה״כ אחרי הנחה</span>
                            <span>{totalAfterDiscount?.toFixed(0)} ₪ כולל מע״מ</span>
                        </div>
                    ) : null}
                    <div className={classNames("marketplace-order-preview-pay-amount-line", "without-vat")}>
                        <div />
                        {(formValues?.amount ?? formValues?.modularProducts) && offeredPrice ? (
                            <div className="marketplace-order-preview-pay-amount">
                                <div>({totalWithoutVat?.toFixed(0)} ₪</div>
                                <span>ללא מע״מ)</span>
                            </div>
                        ) : null}
                    </div>
                    {!providerDetails.isVoucherProvider && (
                        <>
                            {couponDiscountAmount ? (
                                <div className="marketplace-order-preview-coupon-applied">
                                    <span>קופון:</span>
                                    <div>
                                        {coupon.code}
                                        <CloseCircleOutlined
                                            className="marketplace-order-preview-cancel-coupon"
                                            onClick={cancelCoupon}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="marketplace-order-preview-add-coupon">
                                    <FormInputV2
                                        defaultValue={couponCodeInput}
                                        ref={couponInputRef}
                                        placeholder="הזן קוד קופון"
                                    />
                                    <StyledButton onClick={onCouponSendClicked}>הוסף</StyledButton>
                                </div>
                            )}
                            {coupon?.errorMessage ? (
                                <div className="marketplace-order-preview-coupon-error-message">
                                    {coupon.errorMessage}
                                </div>
                            ) : null}
                        </>
                    )}

                    <div className="marketplace-order-preview-policy-confirmation-container">
                        <span>בעת לחיצה על כפתור אישור ההזמנה, אני מאשר.ת את</span>
                        <a onClick={() => setVisibleModal(true)}> תנאי השימוש במערכת</a>
                    </div>
                    {BOOKING_ERROR_TO_PANEL[bookingError] === OrderFormPanelKeys.PolicyConfirmation &&
                        highlightOrderPanel?.id === OrderFormPanelKeys.PolicyConfirmation && (
                            <div className="marketplace-order-preview-coupon-error-message">
                                {ValidateBookingError.POLICY_CONFIRMATION_REQUIRED}
                            </div>
                        )}
                </div>

                <PolicyModal
                    visible={visiblePolicyModal}
                    onClose={() => setVisibleModal(false)}
                    onConfirm={() => setVisibleModal(false)}
                    isVoucher={providerDetails?.isVoucherProvider}
                />

                <StyledButton
                    loading={bookingLoading}
                    onClick={onFinishClick}
                    className={classNames("marketplace-order-preview-confirm-button", {canFinish})}>
                    {common.isUserNeedManagerConfirm(company, me) ? "שליחת הזמנה לאישור המנהל" : "אישור הזמנה"}
                </StyledButton>
            </AutoDirectionProvider>
        </div>
    );
};
