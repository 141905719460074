import React from "react";
import {NOOP} from "../utils/NOOP";

export const MarketplaceContextDefaultValues = {
    serviceId: null,
    providerId: null,
    productId: null,
    product: null,
    providerDetails: null,
    userFormValues: null,
    marketplaceRoot: null,
    participants: null,
    setParticipants: NOOP,
    productBundle: null,
    isProductBundle: null
};

export const MarketplaceContext = React.createContext(MarketplaceContextDefaultValues);
