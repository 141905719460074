import {Modal} from "antd";
import React from "react";
import {StyledButton} from "../../components/StyledButton";
import {CloseIcon} from "../../icons";
import {SmartImageContainer} from "../../components/SmartImageContainer";

const ModalContent = ({event, onInternalEventClick, enableSpecialInteraction, history}) => {
    return event ? (
        <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
            <SmartImageContainer
                width={480}
                height={250}
                src={event.productCoverPhoto}
                style={{
                    width: "100%",
                    marginBottom: 20
                }}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: 24,
                    paddingTop: 0
                }}>
                <div
                    style={{
                        fontWeight: 700,
                        color: "#273444",
                        fontSize: 28,
                        marginBottom: event.productDescription ? 10 : 30
                    }}>
                    {event.sessionName}
                </div>
                {event.productDescription ? (
                    <div
                        style={{
                            fontWeight: 400,
                            color: "#8398A6",
                            fontSize: 16,
                            marginBottom: 30,
                            whiteSpace: "pre-line"
                        }}>
                        {event.productDescription}
                    </div>
                ) : null}
                {enableSpecialInteraction ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                        <StyledButton
                            style={{
                                border: "none",
                                boxShadow: "none",
                                fontSize: 16,
                                color: "#1260D1",
                                backgroundColor: "white",
                                padding: 0,
                                textDecoration: "underline"
                            }}
                            onClick={onInternalEventClick}>
                            Create internal event
                        </StyledButton>
                        <StyledButton
                            style={{
                                border: "none",
                                boxShadow: "none",
                                fontSize: 16,
                                color: "white",
                                backgroundColor: "#13C296"
                            }}
                            onClick={() => history.push("/dashboard/services")}>
                            Go to library
                        </StyledButton>
                    </div>
                ) : null}
            </div>
        </div>
    ) : null;
};

export const SpecialEventModal = ({enableSpecialInteraction, specialEvent, onInternalEventClick, history, onClose}) => {
    const event = specialEvent ? specialEvent.event : null;
    return (
        <Modal
            centered={true}
            width={480}
            className="wb-modal-radius"
            destroyOnClose={true}
            open={!!event}
            bodyStyle={{padding: 0}}
            footer={null}
            title={null}
            closable={true}
            closeIcon={<CloseIcon fill="white" />}
            onCancel={() => onClose()}>
            <ModalContent
                event={event}
                enableSpecialInteraction={enableSpecialInteraction}
                onInternalEventClick={onInternalEventClick}
                history={history}
            />
        </Modal>
    );
};
