import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {OrderInformationCard} from "./OrderInformationCard";
import {getOrderContactInfo} from "./utils";
import {GenericModal, StyledButton} from "../components";
import {AppContext} from "../AppContext";
import {HttpClient} from "../http/HttpClient";
import {EventBus} from "../bus/EventBus";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {Images} from "../images/Images";
import "./event-proposal.css";
import {systemThemeName, themeNamesToText, UserType} from "../consts.js";
import {isEmpty} from "lodash";
import {DeclineOrderModal} from "./DeclineOrderModal/DeclineOrderModal.jsx";
import {ContactPhone} from "../support/ContactPhone.jsx";
import {useRequest} from "../utils/hooks.jsx";
import classnames from "classnames";
import {StringBuilder} from "../AppUtils.js";
import {getThemeByOrigin} from "../utils.js";
import common from "@well-b/well-b-shared";

const {areYouSure} = Images;

export const EventProposalV2 = ({history, match}) => {
    const {me} = useContext(AppContext);
    const eventId = match?.params?.eventId;
    const [showFinishModal, setShowFinishModal] = useState(false);
    const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
    const [finishModalType, setFinishModalType] = useState("accept");
    const [finishModalErrorMessage, setFinishModalErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [declineOrderModalVisible, setDeclineOrderModalVisible] = useState(false);

    const [eventInfo, _loadingEvent, eventError, fetchEvent] = useRequest(
        `/api/events/${eventId}/proposalV2`,
        "GET",
        null,
        [eventId],
        !!eventId,
        order => ({...order, discount: null, couponCode: null}),
        {}
    );
    const contactsInfo = useMemo(() => {
        if (eventInfo.status !== "accepted") {
            return null;
        }

        return getOrderContactInfo(eventInfo);
    }, [eventInfo]);

    useEffect(() => {
        if (eventError) {
            EventBus.triggerError(
                "server-error",
                {
                    content: {
                        description: "Unfortunately we didn't manage to present the session details :("
                    }
                },
                eventError?.message
            );
        }
    }, [eventError]);

    const acceptOffer = useCallback(async () => {
        setShowAreYouSureModal(false);
        setLoading(true);

        const response = await HttpClient.safePost(`/api/events/${eventId}/acceptV2`, {
            updatedAt: eventInfo.updatedAt
        });

        if (response.status >= 500) {
            EventBus.triggerError(
                "server-error",
                {
                    content: {
                        description: "Unfortunately we didn't manage to accept this proposal :("
                    }
                },
                response.error
            );
        } else if (response.error) {
            setFinishModalErrorMessage(response.error);
            setShowFinishModal(true);
        } else {
            GoogleAnalytics.event("Session Proposal", "Accept", eventId);
            setShowFinishModal(true);
            setFinishModalType("accept");
        }
        setLoading(false);
    }, [me, eventId, eventInfo]);

    const declineOffer = useCallback(
        async declineDetails => {
            try {
                const status = await HttpClient.post(`/api/events/${eventId}/declineV2`, {
                    declineReason: declineDetails?.declineReason,
                    declineComment: declineDetails?.declineComment
                });
                GoogleAnalytics.event("Session Proposal", "Decline", eventId);
                setDeclineOrderModalVisible(false);
                setShowFinishModal(true);
                setFinishModalType("decline");
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {
                        content: {
                            description: "Unfortunately we didn't manage to decline this proposal :("
                        }
                    },
                    e.message
                );
            }
        },
        [eventId]
    );

    const title = useMemo(() => {
        if (finishModalErrorMessage) {
            return "שים לב";
        }
        return finishModalType === "accept" ? "שמחים לשמוע" : "מצטערים לשמוע";
    }, [finishModalType, finishModalErrorMessage]);

    const subtitle = useMemo(() => {
        if (finishModalErrorMessage) {
            return finishModalErrorMessage;
        }
        return finishModalType === "accept" ? "הלקוחות שלך כבר מחכים בציפייה" : "נדאג ליצור איתך קשר ולנסות לתאם מחדש";
    }, [finishModalType, finishModalErrorMessage]);

    const orderTitle = useMemo(() => {
        const theme = getThemeByOrigin(eventInfo?.originUrl);
        const themeText = themeNamesToText[theme];

        return new StringBuilder("לקוח של")
            .append(systemThemeName)
            .append(themeText ? new StringBuilder("בשיתוף").append(themeText) : "")
            .append("בחר בך 👻")
            .toString();
    }, [eventInfo?.originUrl]);

    return (
        <div className="event-proposal-v2-container">
            <GenericModal
                visible={showAreYouSureModal}
                image={areYouSure}
                onClose={() => setShowAreYouSureModal(false)}
                options={[
                    {
                        id: "eventProposalProviderSure",
                        onClick: acceptOffer,
                        text: "לאישור הזמנה",
                        disabled: loading
                    }
                ]}
                className="event-proposal-are-you-sure-modal"
                title="שותף יקר, שים 💙"
                text={[
                    "באישורך את ההזמנה, הלקוח יחויב בתשלום עבור ההזמנה.",
                    "אישור ההזמנה הינו התחייבות מצידך לאספקה תחת תנאי ההזמנה המצוינים בטופס ההזמנה."
                ]}
            />

            <DeclineOrderModal
                visible={!!declineOrderModalVisible}
                onSave={declineOffer}
                onClose={() => setDeclineOrderModalVisible(false)}
                onContactClick={() =>
                    ContactPhone.openWhatsApp(ContactPhone.phoneNumberBySystemId(common.themeNames.wellB))
                }
            />

            <GenericModal
                title={title}
                className="company-edit-order-sure-modal"
                text={[subtitle]}
                image={Images.areYouSure}
                closeable={true}
                visible={showFinishModal}
                onClose={() => setShowFinishModal(false)}
                options={[
                    {
                        id: "accept",
                        onClick: () => window.location.reload(),
                        text: "סגירה"
                    }
                ]}
            />
            {!isEmpty(eventInfo) && (
                <OrderInformationCard
                    title={orderTitle}
                    eventInfo={eventInfo}
                    contactsInfo={contactsInfo}
                    userType={me?.type === UserType.Provider || me.isAdmin ? UserType.Provider : null}
                    additionalInfo={[
                        "ההזמנה תמתין לאישורך עד 12 שעות ולאחר מכן במידה ולא תאשר היא תחזור בחזרה ללקוח.",
                        "במידה ונדרש שינוי בסכום ההזמנה הסופי אנא צרו איתנו קשר. במידה והבקשה מוצדקת אנחנו נדאג לעדכן את תמחור החבילה בהתאם.",
                        "לכל שאלה הקשורה בהשלמת ההזמנה יכולים לפנות אלינו במייל roy@well-b.biz.",
                        "פרטי קשר של הלקוח לתיאומים ובירורים נוספים יימסרו לך לאחר אישור ההזמנה."
                    ]}
                    fetchOrder={fetchEvent}>
                    {eventInfo.status === "pending" ? (
                        <div className="cta-container">
                            <StyledButton
                                loading={loading}
                                onClick={() => setShowAreYouSureModal(true)}
                                className="confirm-btn">
                                מאשר את ההזמנה!
                            </StyledButton>
                            <StyledButton
                                onClick={async () =>
                                    me?.features?.declineReason
                                        ? setDeclineOrderModalVisible(true)
                                        : await declineOffer()
                                }
                                className="decline-btn">
                                לא מסתדר לי
                            </StyledButton>
                        </div>
                    ) : null}
                </OrderInformationCard>
            )}
        </div>
    );
};
