import {message, Modal, Tooltip, Upload} from "antd";
import React, {useContext, useState} from "react";
import {CloseIcon} from "../icons";
import {Images} from "../images/Images";
import {PageTitleLabel} from "../components/PageTitle";
import {StyledButton} from "../components/StyledButton";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {EventBus} from "../bus/EventBus";
import {AppContext} from "../AppContext";
import {DownloadOutlined} from "@ant-design/icons";
import {openWindow} from "../http/WindowOpener";
import {parse as qsParse} from "query-string";

export const CompanyEmployeesSpreadsheetModal = ({visible, onClose, fetchEmployees, uploadEmployeesCheck}) => {
    let {branchId} = qsParse(location.search);
    if (!branchId) {
        branchId = "Global";
    }

    const {me} = useContext(AppContext);
    const [loading, setLoading] = useState(false);

    const props = {
        name: "file",
        accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
        action: "/api/employees/import",
        showUploadList: false,
        withCredentials: true,
        onChange: async info => {
            if (info.file.status === "uploading") {
                GoogleAnalytics.event("Employees", "Import", me.companyId);
                setLoading(true);
            } else if (info.file.status === "done") {
                message.success(`Great! we just added all your friends!`, 4);
                fetchEmployees();
                setLoading(false);
                onClose();
            } else if (info.file.status === "error") {
                if (info.file.error.status === 400) {
                    EventBus.trigger("server-error", {
                        content: {
                            title: "Upload Failed!",
                            hideSubTitle: true,
                            description: info.file.response.error,
                            hideSteps: true
                        }
                    });
                } else {
                    EventBus.triggerError(
                        "server-error",
                        {
                            content: {
                                title: "Upload Failed!",
                                hideSubTitle: true,
                                description: "Unfortunately we couldn't add your friends."
                            }
                        },
                        info.file.response.error || `${info.file.name} file upload failed.`
                    );
                }
                setLoading(false);
            }
        }
    };

    const downloadTemplate = () => {
        openWindow(`/api/employees/export?branchId=${branchId}`, "_blank", true);
    };

    return (
        <Modal
            className="wb-modal-radius"
            destroyOnClose={true}
            closable={true}
            closeIcon={<CloseIcon style={{fill: "var(--secondary-color)", marginTop: "20px"}} />}
            title={null}
            footer={null}
            onCancel={onClose}
            width={600}
            height={800}
            bodyStyle={{padding: 0}}
            open={visible}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <img width={600} height={250} src={Images.spreadsheet} alt="spreadsheet" />
            </div>
            <PageTitleLabel
                style={{marginBottom: 10, color: "var(--secondary-color)", fontSize: "32px", fontWeight: "300"}}>
                Upload spreadsheet
            </PageTitleLabel>
            <div
                style={{
                    fontSize: 20,
                    color: "var(--secondary-color)",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column"
                }}>
                <p>Use our excel template to upload all your employees at</p>
                <p>once! Employees’ names and emails are mandatory,</p>
                <p>everything else is a bonus :)</p>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    paddingBottom: "30px",
                    margin: "30px 10px"
                }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: "190px",
                        cursor: "pointer",
                        marginTop: "5px"
                    }}
                    onClick={() => downloadTemplate()}>
                    <DownloadOutlined style={{fontSize: 19, paddingTop: 4, marginRight: 3}} />
                    <p
                        style={{
                            fontSize: "14px",
                            color: "var(--secondary-color)",
                            marginLeft: "5px",
                            textDecoration: "underline"
                        }}>
                        Download work template here
                    </p>
                </div>
                <Tooltip title="Upload the filled template you downloaded">
                    <Upload {...props}>
                        <StyledButton
                            onClick={uploadEmployeesCheck}
                            loading={loading}
                            style={{
                                height: "40px",
                                width: "126px",
                                borderRadius: "5px",
                                backgroundColor: "#ED7FA6 !important",
                                color: "#FFF"
                            }}>
                            Upload list
                        </StyledButton>
                    </Upload>
                </Tooltip>
            </div>
        </Modal>
    );
};
