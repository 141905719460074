import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import heLocale from "@fullcalendar/core/locales/he";
import classNames from "classnames";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import {Dropdown, Tooltip} from "antd";
import {OrdersCalendarContext} from "./OrdersCalendarContext";
import {CalendarDateFormat, OnlyMonthFormat} from "./consts";
import {AppContext} from "../AppContext";
import {OccasionPriceType, OccasionPriceTypeDisplayName} from "../happinessCalculator/consts";
import {getMarketplaceViewLink} from "../marketplace/MarketplaceUtils";
import "./orders-calendar.css";

const OrdersCalendar = ({history, holidays, onMonthChanged, dateCellClicked}) => {
    const {setPlanBudgetOrder} = useContext(AppContext);
    const {plannedOccasions, changeEventStartTime, linkEvent, orders, month, currentMonthPlan, previewMode, log} =
        useContext(OrdersCalendarContext);

    const calendarRef = useRef(null);
    const [draggedEvent, setDraggedEvent] = useState(null);
    const [potentialDroppedEvent, setPotentialDroppedEvent] = useState(null);

    const formattedHolidaysEvents = useMemo(
        () =>
            holidays?.reduce((parsedEvents, {occasionId, name, icon, startDate, endDate, marketplaceView}) => {
                let tempStartDate = moment(startDate).endOf("day");
                do {
                    const dateString = tempStartDate.format(CalendarDateFormat);

                    parsedEvents.push({
                        id: `${occasionId}-${dateString}`,
                        start: dateString,
                        title: `${name} ${icon}`,
                        classNames: ["orders-calendar-holiday-event"],
                        display: "background",
                        extendedProps: {
                            marketplaceView
                        }
                    });

                    tempStartDate = tempStartDate.add(1, "day");
                } while (tempStartDate.isBefore(moment(endDate), "date"));

                return parsedEvents;
            }, []) ?? [],
        [holidays]
    );

    const formattedOrdersEvents = useMemo(
        () =>
            orders?.map(({eventId, dtstart, productName, providerBrand, deliveryArrivingTimeRange}) => {
                const endTime = moment(dtstart).add(deliveryArrivingTimeRange ?? 0, "hours");
                const linkedPlanedOccasion = plannedOccasions.find(({linkedEvent}) => linkedEvent === eventId);
                const orderColor = linkedPlanedOccasion ? `var(--${linkedPlanedOccasion.occasionCategoryId})` : null;
                const title = `${productName} | ${moment(dtstart).format("HH:mm")} - ${endTime.format("HH:mm")}`;
                const tooltip = linkedPlanedOccasion ? (
                    <>
                        {title}
                        <br />
                        {`${linkedPlanedOccasion.name} ${linkedPlanedOccasion.icon}`}
                    </>
                ) : null;

                return {
                    id: eventId,
                    title,
                    start: moment(dtstart).format(CalendarDateFormat),
                    end: endTime.format(CalendarDateFormat),
                    classNames: ["orders-calendar-order-event"],
                    backgroundColor: orderColor,
                    borderColor: orderColor,
                    extendedProps: {
                        linkedPlanedOccasion,
                        type: "order",
                        tooltip,
                        subtitle: providerBrand
                    }
                };
            }) ?? [],
        [orders, plannedOccasions]
    );

    const formattedPlannedEvents = useMemo(() => {
        return plannedOccasions
            .filter(({linkedEvent}) => !linkedEvent)
            .map(({occasionId, name, icon, price, priceType, actualStartDate, occasionCategoryId}) => ({
                id: occasionId,
                title: `${name} ${icon}`,
                start: moment(actualStartDate).format(CalendarDateFormat),
                classNames: ["orders-calendar-planed-event"],
                textColor: `var(--${occasionCategoryId})`,
                borderColor: `var(--${occasionCategoryId})`,
                editable: true,
                durationEditable: false,
                extendedProps: {
                    occasionCategoryId,
                    occasionId,
                    planId: currentMonthPlan?.planId,
                    color: currentMonthPlan?.categories[occasionCategoryId].color,
                    price,
                    priceType,
                    subtitle: (
                        <u>{`לתיאום לחצו | ${price.toLocaleString()} ₪ ל${
                            OccasionPriceTypeDisplayName[priceType ?? OccasionPriceType.byParticipant]
                        }}`}</u>
                    )
                }
            }));
    }, [plannedOccasions, currentMonthPlan]);

    useEffect(() => {
        if (calendarRef.current && month) {
            const calendarApi = calendarRef.current?.getApi();
            const currentDate = moment(calendarApi.getCurrentData().currentDate);
            const monthToChangeTo = moment(month, OnlyMonthFormat);

            if (!currentDate.isSame(month, "month")) {
                calendarApi.gotoDate(monthToChangeTo.format(CalendarDateFormat));
            }
        }
    }, [calendarRef.current, month]);

    const eventOnMouseEnter = useCallback(
        ({event}) => {
            if (!draggedEvent) {
                return;
            }

            if (event.extendedProps?.type === "order" && !event.extendedProps?.linkedPlanedOccasion) {
                setPotentialDroppedEvent(event);
            }
        },
        [draggedEvent]
    );

    const eventClicked = useCallback(
        event => {
            if (previewMode) return;

            if (event?.extendedProps?.occasionCategoryId) {
                const eventDate = {
                    name: event.title,
                    occasionCategoryId: event?.extendedProps?.occasionCategoryId,
                    priceType:
                        OccasionPriceTypeDisplayName[
                            event?.extendedProps?.priceType ?? OccasionPriceType.byParticipant
                        ],
                    price: event?.extendedProps?.price,
                    color: event?.extendedProps?.color,
                    planId: event?.extendedProps?.planId,
                    occasionId: event?.extendedProps?.occasionId
                };

                setPlanBudgetOrder(eventDate);

                log("Plan Event Clicked", eventDate);

                history.push("/dashboard/services");
                return;
            }

            log("Order Event Clicked", {orderId: event.id});
            window.open(`/events/${event.id}/orderV2`, "_blank", "noreferrer");
        },
        [log, setPlanBudgetOrder, previewMode]
    );

    const onEventReceived = useCallback(
        ({event, revert}) => {
            revert();

            if (potentialDroppedEvent) {
                linkEvent(event.id, event.extendedProps.occasionCategoryId, potentialDroppedEvent.id);
            } else {
                changeEventStartTime(event.id, event.extendedProps.occasionCategoryId, event.start);
            }
        },
        [potentialDroppedEvent, linkEvent, changeEventStartTime]
    );

    const allEvents = useMemo(
        () => [...formattedOrdersEvents, ...formattedHolidaysEvents, ...formattedPlannedEvents],
        [formattedOrdersEvents, formattedHolidaysEvents, formattedPlannedEvents]
    );

    const onClickHolidayEvent = useCallback(event => {
        const {marketplaceView} = event.extendedProps;
        const url = getMarketplaceViewLink(marketplaceView.viewType, marketplaceView.viewId);

        window.open(url, "_blank", "noreferrer");
    }, []);

    return (
        <div className={classNames("orders-calendar", {"planed-month": plannedOccasions?.length})}>
            <FullCalendar
                dir="rtl"
                ref={calendarRef}
                locale={heLocale}
                plugins={[dayGridPlugin, interactionPlugin]}
                droppable={true}
                editable={false}
                eventDragStart={({event}) => {
                    setDraggedEvent(event);
                }}
                eventDragStop={() => {
                    setDraggedEvent(null);
                }}
                eventDrop={onEventReceived}
                eventReceive={onEventReceived}
                headerToolbar={{
                    start: "prev title next",
                    end: ""
                }}
                eventContent={({event}) => {
                    if (event.display === "background") {
                        return (
                            <Dropdown
                                disabled={!event.extendedProps.marketplaceView?.viewId}
                                menu={{
                                    items: [
                                        {
                                            label: "פתח אירוע צריכה",
                                            key: "newTab",
                                            onClick: () => onClickHolidayEvent(event)
                                        }
                                    ]
                                }}>
                                <div className="orders-calendar-holiday-content">{event.title}</div>
                            </Dropdown>
                        );
                    }
                    if (event.display === "auto") {
                        const shouldColorEvent = event.id === potentialDroppedEvent?.id;
                        return (
                            <div
                                onMouseEnter={jsEvent => eventOnMouseEnter({event, jsEvent})}
                                onMouseLeave={() => setPotentialDroppedEvent(null)}
                                onClick={() => eventClicked(event)}
                                style={
                                    shouldColorEvent
                                        ? {
                                              "--hover-color": `var(--${draggedEvent?.extendedProps?.occasionCategoryId})`
                                          }
                                        : null
                                }
                                className={classNames("orders-calendar-order-event-content", {
                                    potentialDroppedEvent: shouldColorEvent
                                })}>
                                <Tooltip
                                    overlayClassName="orders-calendar-order-event-tooltip"
                                    title={event.extendedProps?.tooltip ?? event.title}>
                                    <span>{event.title}</span>
                                </Tooltip>
                                <Tooltip title={event.extendedProps.subtitle}>
                                    <span>{event.extendedProps.subtitle}</span>
                                </Tooltip>
                            </div>
                        );
                    }
                }}
                dateClick={dateCellClicked}
                dayMaxEvents={3}
                dayCellClassNames={["orders-calendar-cell"]}
                initialDate={month ? moment(month, OnlyMonthFormat).format(CalendarDateFormat) : null}
                datesSet={({start, end}) => {
                    const midDay = moment((start.getTime() + end.getTime()) / 2);
                    onMonthChanged(midDay.format(OnlyMonthFormat), [moment(start), moment(end)]);
                }}
                showNonCurrentDates={false}
                dayHeaderClassNames={"orders-calendar-day-header"}
                events={allEvents}
            />
        </div>
    );
};

export default OrdersCalendar;
