import moment from "moment";
import {statusToTextAndColor} from "../components";
import {createWorkbook, createWorksheet, DownloadExcel} from "../utils/excelUtils.js";
import {getDeliveryPriceForInvoice} from "../utils/calculationUtils";
import {dateTimeRange} from "../utils/DateUtils.jsx";
import {keyBy} from "lodash";
import {ProviderDealerTypes} from "../admin/upload/AdminUploadUtils.jsx";

export const ExportWellBShippingExcel = async (orders, providersWithSelectedFields, deliveryProviders) => {
    const workbook = createWorkbook();

    const excelSheets = [
        {
            title: "הזמנות",
            list: orders
        }
    ];

    excelSheets.forEach(({list = [], title}) => {
        const sheet = createWorksheet(workbook, title);
        addColumns(sheet);
        addRows(sheet, list, providersWithSelectedFields, deliveryProviders);
    });

    await DownloadExcel(workbook, "orders");
};

const addColumns = sheet => {
    sheet.columns = [
        {header: "שם החברה", key: "companyName"},
        {header: "מספר הזמנה", key: "proposalId"},
        {header: "שם החבילה", key: "productName"},
        {header: "תאריך הזמנה", key: "createdAt"},
        {header: "תאריך אספקה", key: "dtStart"},
        {header: "טווח אספקה", key: "dtStartHours"},
        {header: "כתובת מלאה לאספקה", key: "address"},
        {header: "מרחק בין כתובת איסוף לשילוח (ק״מ)", key: "distanceInKM"},
        {header: "סכום הזמנה (כולל מעמ)", key: "offeredPrice"},
        {header: "עלות משלוח ללקוח (כולל מעמ)", key: "deliveryPriceForCompany"},
        {header: "עלות משלוח well-b (כולל מעמ)", key: "deliveryPriceForShippingProvider"},
        {header: "דמי שילוח (כולל מעמ)", key: "deliveryPriceForProvider"},
        {header: "ספק שילוח", key: "shippingProviderName"},
        {header: "מנהלת הלקוח וולבי", key: "csmName"},
        {header: "סטטוס הזמנה", key: "status"}
    ];
};

const addRows = (sheet, orders, providersWithSelectedFields, deliveryProviders) => {
    const deliveryProviderByDeliveryProviderId = keyBy(
        deliveryProviders,
        deliveryProvider => deliveryProvider.deliveryProviderId
    );
    const providersByProviderId = keyBy(providersWithSelectedFields, provider => provider.providerId);
    const rows = orders.reduce((allRows, order) => {
        const provider = providersByProviderId[order.providerId];
        const {supplyHours} = getSupplyDetails(order);

        const row = {
            companyName: order.companyName,
            proposalId: order.proposalId,
            productName: order.productName,
            createdAt: moment(order.createdAt).format("DD/MM/YYYY"),
            dtStart: moment(order.dtstart).format("DD/MM/YYYY"),
            dtStartHours: supplyHours,
            address: order.address,
            offeredPrice: order.offeredPrice,
            deliveryPriceForCompany: order.deliveryPrice,
            deliveryPriceForShippingProvider: order.deliveryPrice,
            deliveryPriceForProvider:
                typeof provider?.dealerType === "string"
                    ? getDeliveryPriceForInvoice(
                          order,
                          order.deliveryCommission,
                          provider?.dealerType === ProviderDealerTypes.exempt,
                          provider?.maxShippingProvidersDeliveryPrice
                      )
                    : "ספק לא זוהה",
            shippingProviderName:
                deliveryProviderByDeliveryProviderId[order.shippingProviders?.[0]]?.name ?? "לא זוהה ספק משלוחים",
            csmName: order?.assignedCSMs?.[0]?.firstName ?? "ללא מנהלת לקוח",
            status: statusToTextAndColor[order.status] ? statusToTextAndColor[order.status][0] : ""
        };

        allRows.push(row);
        return allRows;
    }, []);

    sheet.addRows(rows);
};

function getSupplyDetails(order) {
    const {dtStartDateFormat, untilDateFormat, dtStartHourFormat, untilHourFormat} = dateTimeRange(
        order.dtstart,
        order?.until
    );
    const supplyDate = `${dtStartDateFormat} ${untilDateFormat ? ` - ${untilDateFormat}` : ""}`;
    const supplyHours = `${dtStartHourFormat} ${untilHourFormat ? ` - ${untilHourFormat}` : ""}`;
    return {supplyDate, supplyHours};
}
