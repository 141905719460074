import React, {useCallback, useEffect, useState} from "react";
import {Tooltip} from "antd";
import moment from "moment";
import {set} from "lodash";
import classnames from "classnames";
import {Images} from "../../../images/Images";
import {FormModal} from "../../../components";
import "./comment-modal.css";

const {comment: commentImage} = Images;

export const CommentModal = ({comment, onSave, tooltip = null}) => {
    const [content, setContent] = useState({});
    const [modalVisibility, setModalVisibility] = useState(false);

    const updateContent = useCallback((key, value) => {
        setContent(currentContent => set({...currentContent}, key, value));
    }, []);

    const innerOnSave = useCallback(async commentText => {
        onSave(commentText);
        onClose();
    }, [onSave]);

    const onClose = useCallback(() => {
        setModalVisibility(false);
    }, []);

    useEffect(() => {
        if (comment?.content) {
            updateContent("comment", comment.content);
        }
    }, [comment]);

    return (
        <>
            <Tooltip title={tooltip}>
                <img
                    onClick={() => setModalVisibility(true)}
                    className={classnames("comment-modal-icon", {"has-comment": comment})}
                    src={commentImage}
                    alt="orderComment"
                />
            </Tooltip>
            <FormModal
                header="ערוך תגובה"
                visible={modalVisibility}
                content={content}
                onUpdate={(_, changedField) => {
                    const [key, value] = Object.entries(changedField)[0];
                    updateContent(key, value);
                }}
                onSave={({comment}) => innerOnSave(comment)}
                onClose={onClose}
                fields={[
                    {
                        name: "comment",
                        label: "תגובה",
                        type: "textarea",
                        minRows: 2,
                        maxRows: 10,
                        maxLength: 200,
                        optional: true
                    },
                    ...(comment?.author
                        ? [
                              {
                                  name: "prevComment",
                                  label: "",
                                  type: "custom",
                                  optional: true,
                                  customComponent: (
                                      <>
                                          {comment?.author ? (
                                              <label>
                                                  {`נכתב על ידי: `}
                                                  <b>{comment?.author}</b>
                                                  {` בתאריך ${moment(comment.lastUpdate).format("DD/MM/YY HH:mm")}`}
                                              </label>
                                          ) : null}
                                      </>
                                  )
                              }
                          ]
                        : [])
                ]}
            />
        </>
    );
};
