import React from "react";
import {Modal} from "antd";
import {CloseIcon} from "../../icons";
import {PageTitleLabel} from "../../components/PageTitle";
import {StyledButton} from "../../components/StyledButton";

const WeeklyEmailProperty = ({label, value}) => (
    <div
        style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            marginTop: 30,
            border: "1px solid #E8EDF5",
            backgroundColor: "#FCFDFE",
            paddingLeft: 30,
            height: 50,
            borderRadius: 10
        }}>
        <span
            style={{
                fontSize: 16,
                color: "#8398A6",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start"
            }}>
            {label}
        </span>
        <span
            style={{
                fontSize: 16,
                color: "var(--secondary-color)",
                paddingLeft: 8,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start"
            }}>
            {value}
        </span>
    </div>
);

export const CompanyWeeklyEmailProperties = ({companyName, companyEmail, visible, onClose, onSend, sending}) => {
    return (
        <Modal
            destroyOnClose={true}
            closable={true}
            onCancel={() => onClose()}
            footer={null}
            title={null}
            open={visible}
            closeIcon={<CloseIcon fill="#767F90" />}
            className="wb-weekly-props-modal">
            <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
                <PageTitleLabel style={{fontSize: 20, fontWeight: 400, color: "#273444", marginBottom: 0}}>
                    Send to employees/members
                </PageTitleLabel>
                <WeeklyEmailProperty label="Subject:" value={`This week at ${companyName}`} />
                <WeeklyEmailProperty label="From:" value={companyName} />
                <WeeklyEmailProperty label="Reply to:" value={companyEmail} />
                <StyledButton
                    loading={sending}
                    onClick={onSend}
                    style={{
                        borderRadius: 25,
                        backgroundColor: "#13C296",
                        color: "white",
                        marginTop: 30,
                        width: "100%"
                    }}>
                    Send now
                </StyledButton>
            </div>
        </Modal>
    );
};
