import React, {useCallback, useContext, useMemo} from "react";
import {useRequest} from "../../utils/hooks.jsx";
import {ProviderDescription, ProviderHeaderPageSkeleton} from "./providerDescription/ProviderDescription.jsx";
import "./marketplace-provider-page.css";
import {BackTop, ConfigProvider, Divider} from "antd";
import {getLogger, writeProductClickedLog} from "../../Logger.jsx";
import {ProviderAdditionalProductsSkeleton} from "./providerAdditionalProductsSkeleton/ProviderAdditionalProductsSkeleton.jsx";
import {ProviderProductCart} from "./providerProductCart/ProviderProductCart.jsx";
import {CartModularProductContext} from "../PricingCalculator/CartModularProductContext.jsx";
import {ProviderProductCartSkeleton} from "./providerProductCart/ProviderProductCartSkeleton.jsx";
import {GenericModal} from "../../components/index.jsx";
import {Images} from "../../images/Images.jsx";
import {ContactPhone} from "../../support/ContactPhone.jsx";
import {AppContext} from "../../AppContext.jsx";
import {getProviderProduct} from "./utils.js";
import {isBoolean, partialRight} from "lodash";
import {getPrimaryProduct} from "./utils.js";
import {createProductLink, selectServiceIdFromProduct} from "../MarketplaceHelper.jsx";
import {MarketplaceSearchResults} from "../MarketplaceSearchResults/index.jsx";
import {ProviderPinterest, ProviderPinterestSkeleton} from "./providerPinterest/ProviderPinterest.jsx";

export const MarketplaceProvider = ({match, history}) => {
    const {providerId} = match?.params ?? {};
    const [provider, isLoadingProvider] = useRequest(
        `/api/providers/${providerId}`,
        "get",
        null,
        [providerId],
        !!providerId
    );
    const {me, company, services, isolationMode} = useContext(AppContext);
    const {subProducts} = useContext(CartModularProductContext);

    const log = useMemo(() => getLogger({}, "MarketplaceProviderPage"), []);

    const goToMainPage = useCallback(() => history.push("/dashboard/services"), [history]);

    const providerProducts = useMemo(
        () => getProviderProduct(me, provider, services, isolationMode),
        [provider, isolationMode, me, services]
    );

    const primaryProduct = useMemo(() => getPrimaryProduct(provider), [provider]);

    const isAllowedForPage = useMemo(() => {
        if (!provider || !providerProducts) {
            return;
        }
        return provider?.approved && (!!providerProducts?.length || !!primaryProduct);
    }, [provider, providerProducts, primaryProduct]);

    const onContentClick = useCallback(
        content => {
            if (content.service.isProductBundle) {
                const url = `/dashboard/services/productBundle/${content.service.productId}`;
                window.open(url, "_blank", "noreferrer");
                return;
            }
            const serviceId = selectServiceIdFromProduct(me, content, services);
            const url = createProductLink(content, {me, services, serviceId});
            writeProductClickedLog(log, content, serviceId);
            window.open(url, "_blank", "noreferrer");
        },
        [me, log, services]
    );

    return (
        <>
            <GenericModal
                className="no-content-modal"
                visible={isBoolean(isAllowedForPage) && !isAllowedForPage && !!provider}
                image={Images.outOfStock}
                title={"אופסי, כנראה שהמלאי אזל 😬"}
                text={[
                    "לצערנו לא ניתן לראות את הספק כרגע, עובדים קשה מאחורי הקלעים בשביל להמשיך להנגיש את הספק בהקדם האפשרי!",
                    <span
                        key="no-content-modal-outOfStock"
                        onClick={() => ContactPhone.openWhatsApp(company.assignedCSMs?.[0]?.phone)}
                        className="no-content-modal-contact-us">
                        צריכים התייעצות? דברו איתנו בוואטסאפ
                    </span>
                ]}
                options={[
                    {
                        id: "goToHomePage",
                        text: "חזרה לעמוד הראשי",
                        onClick: () => {}
                    }
                ]}
                closeable={false}
                onClose={goToMainPage}
            />

            <div className="marketplace-provider-page">
                {isLoadingProvider || !providerId ? (
                    <>
                        <ProviderHeaderPageSkeleton />
                        <ProviderProductCartSkeleton />
                        <Divider className="marketplace-provider-page-footer-devider" />
                        <ProviderAdditionalProductsSkeleton />
                        <Divider />
                        <ProviderPinterestSkeleton />
                    </>
                ) : (
                    <>
                        {!!isAllowedForPage && (
                            <>
                                <ConfigProvider direction="rtl">
                                    <ProviderDescription
                                        provider={provider}
                                        log={log}
                                        providerProducts={providerProducts}
                                    />
                                    <ProviderProductCart
                                        provider={provider}
                                        subProducts={subProducts}
                                        primaryProduct={primaryProduct}
                                    />
                                    {!!subProducts?.length && (
                                        <Divider className="marketplace-provider-page-footer-devider" />
                                    )}

                                    <div className="provider-footer-page">
                                        {!!providerProducts?.length && (
                                            <>
                                                <div className="provider-additional-products-container">
                                                    <span className="provider-products-title">
                                                        מוצרים נוספים של הספק
                                                    </span>
                                                    <MarketplaceSearchResults
                                                        onContentClick={onContentClick}
                                                        onNewTab={partialRight(onContentClick, true)}
                                                        searchedContent={providerProducts}
                                                        showSearchAmount={false}
                                                    />
                                                </div>
                                                <Divider />
                                                <ProviderPinterest provider={provider} />
                                            </>
                                        )}
                                    </div>
                                </ConfigProvider>
                            </>
                        )}
                    </>
                )}
                <BackTop />
            </div>
        </>
    );
};
