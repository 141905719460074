import CallToActionModal from "../components/CallToActionModal";
import React from "react";
import {Typography} from "antd";
import {ModalTitle} from "../components/ModalTitle";

export const EventDeclinedModal = ({visible, history, event}) => {
    const Title = () => <ModalTitle title="Auwwwch..." subTitle="That will probably hurt..." />;

    const SubTitle = () => (
        <div style={{display: "flex", flexDirection: "column"}}>
            <Typography.Text style={{textAlign: "center", fontSize: 20}}>
                Don’t worry, we’ll let them know.
            </Typography.Text>
            <Typography.Text style={{textAlign: "center", fontSize: 20, marginBottom: 10}}>
                That’s what friends are for!
            </Typography.Text>
            <Typography.Paragraph style={{textAlign: "center"}}>
                Please tell us why you decided to decline the offer.
            </Typography.Paragraph>
        </div>
    );

    return (
        <CallToActionModal
            imageName="oops"
            title={<Title />}
            subTitle={<SubTitle />}
            actionText="Tell us why"
            actionHandler={() => history.push(`/contact?subject=decline-reason&context=transaction-${event.eventId}`)}
            anotherActionText="Check my calendar"
            anotherActionHandler={() => history.push("/dashboard/schedule")}
            visible={visible}
        />
    );
};
