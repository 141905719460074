import React from "react";
import PageTitleHeader, {PageSubTitleLabel} from "../components/PageTitle";
import {Form} from "antd";
import {FormConfirmButton, FormInput} from "../components/form";
import {HttpClient} from "../http/HttpClient";
import {EventBus} from "../bus/EventBus";
import {parse as qsParse} from "query-string";
import logger from "../Logger";

export class ForgotPassword extends React.Component {
    state = {
        emailSent: false,
        sendingEmail: false
    };

    async onFinish(values) {
        this.setState({sendingEmail: true});
        try {
            await HttpClient.post("/api/users/password/forgot", {email: values.email});
            logger.track("Send Forgot Password Request", {email: values.email});
            this.setState({emailSent: true});
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "יש בעיה עם כתובת המייל שסיפקת, אנא בדוק שהמייל תקין ורשום במערכת"}},
                e.message
            );
        }
        this.setState({sendingEmail: false});
    }

    render() {
        const {emailSent, sendingEmail} = this.state;
        const {location} = this.props;
        const {search} = location;
        const backParam = qsParse(search).back;

        return (
            <div style={{display: "flex", alignItems: "center", flexDirection: "column", marginTop: 20}}>
                <PageTitleHeader showBack={backParam === "true"}>Forgot your password?</PageTitleHeader>
                <PageSubTitleLabel>Let us help you by providing your email address</PageSubTitleLabel>
                <Form
                    onFinish={async vals => {
                        await this.onFinish(vals);
                    }}
                    style={{maxWidth: 440, width: "100%", padding: 20}}>
                    <Form.Item
                        name="email"
                        rules={[{type: "email", required: true, message: "Please enter valid email"}]}>
                        <FormInput disabled={emailSent} placeholder="Your email" />
                    </Form.Item>
                    {emailSent ? (
                        <p style={{fontSize: 14, paddingLeft: 10, paddingRight: 10}}>
                            We got your request.
                            <br />
                            If your email is registered on our system you will get an email to reset your password.
                        </p>
                    ) : null}
                    {!emailSent ? (
                        <Form.Item>
                            <FormConfirmButton loading={sendingEmail} htmlType="submit">
                                Submit
                            </FormConfirmButton>
                        </Form.Item>
                    ) : null}
                </Form>
            </div>
        );
    }
}
