import React, {useCallback} from "react";
import {MarketplaceFilterButtons} from "./MarketplaceFilter/buttonsFilter/MarketplaceFilterButtons.jsx";
import {NOOP} from "../utils/NOOP";

export const MarketplaceFilterPanel = ({
    filterValues = {},
    setFilterValues,
    searchSorterType,
    setSearchSortType,
    showFilter = true,
    showSorter = true,
    log = NOOP
}) => {
    const onSortSelected = useCallback(
        sortBy => {
            log("Sort option selected", {sortBy});
            setSearchSortType(sortBy);
        },
        [log]
    );

    return (
        <div className="marketplace-category-filters-search-container" style={{overflow: "auto"}}>
            <MarketplaceFilterButtons
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                searchSorterType={searchSorterType}
                onSortSelected={onSortSelected}
                showFilter={showFilter}
                showSorter={showSorter}
            />
        </div>
    );
};
