import React from "react";
import {Form, Select, Input} from "antd";
import {FormInput} from "../components/form";
import {SearchableSelect} from "../components/SearchableSelect";
import {industries} from "../data/industries";
// import emailDomains from "personal-email-domains";
import isMobileDevice from "is-mobile";
import {CompanyBranches} from "./CompanyBranches";
import {CompanyBranchesList} from "./CompanyBranchesList";
import {validateEmailExistence} from "../components/form/validators";

const {Option} = Select;

const personalEmailDomains = []; //emailDomains.concat(["walla.co.il", "icloud.com"]);

export const CompanyInfoFields = ({
    selectedCountry,
    onCountryChange,
    selectedState,
    onStateChange,
    selectedCity,
    onCityChange,
    disableEmail = false,
    hideBranches = false
}) => {
    return (
        <>
            <Input.Group style={{width: "100%", display: "flex"}}>
                <Form.Item
                    style={{width: "100%", minWidth: 165}}
                    name="name"
                    rules={[{required: true, message: "Please enter company's name."}]}>
                    <FormInput placeholder="Company name" />
                </Form.Item>
                <Form.Item
                    style={{marginLeft: 10, width: "100%", minWidth: 165}}
                    name="industry"
                    rules={[{required: true, message: "Please enter select company's industry."}]}>
                    <SearchableSelect placeholder="Industry">
                        {industries.map((industry, idx) => (
                            <Option key={`industy-${idx}`} value={industry}>
                                {industry}
                            </Option>
                        ))}
                    </SearchableSelect>
                </Form.Item>
            </Input.Group>
            <Form.Item
                validateFirst={true}
                style={{width: "100%"}}
                name="contact"
                rules={[
                    {
                        required: true,
                        type: "email",
                        message: "Please enter valid company's email."
                    },
                    {
                        validator: (_, email) => {
                            if (disableEmail) {
                                return Promise.resolve();
                            }

                            return personalEmailDomains.some(domain => email.indexOf(domain) >= 0)
                                ? Promise.reject("Personal emails are not allowed.")
                                : Promise.resolve();
                        }
                    },
                    {
                        validateTrigger: "onSubmit",
                        validator: async (_, email) => {
                            if (disableEmail) {
                                return;
                            }

                            await validateEmailExistence(email);
                        }
                    }
                ]}>
                <FormInput disabled={disableEmail} type="email" placeholder="Company email" />
            </Form.Item>
            <Input.Group style={{width: "100%", display: "flex"}}>
                <Form.Item
                    style={{width: "100%", minWidth: 165}}
                    name="contactName"
                    rules={[{required: true, message: "Please enter your personal name."}]}>
                    <FormInput placeholder={isMobileDevice() ? "Personal name" : "Your personal name"} />
                </Form.Item>
                <Form.Item
                    style={{marginLeft: 10, width: "100%", minWidth: 165}}
                    name="contactPosition"
                    rules={[{required: true, message: "Please enter your personal position."}]}>
                    <FormInput placeholder="Your position" />
                </Form.Item>
            </Input.Group>
            <Form.Item
                style={{width: "100%"}}
                name="phone"
                rules={[
                    {
                        required: true,
                        message: "Please enter your phone number."
                    },
                    {
                        min: 8,
                        message: "Please enter a valid phone number."
                    }
                ]}>
                <FormInput type="number" placeholder="Your phone number" />
            </Form.Item>
            {!hideBranches ? (
                <Form.Item
                    name="branches"
                    rules={[
                        {
                            validator: (_, branches) => {
                                if (!Array.isArray(branches) || branches.length === 0) {
                                    return Promise.reject("You must define at least one branch.");
                                } else if (branches.every(b => !b.isHQ)) {
                                    return Promise.reject("You must select one HQ.");
                                }

                                return Promise.resolve();
                            }
                        }
                    ]}>
                    <CompanyBranches>
                        {({
                            branches,
                            onNewBranchClick,
                            onEditBranchClick,
                            onRemoveBranchClick,
                            onBranchVisibilityChange,
                            onMarkMainBranchClick
                        }) => (
                            <CompanyBranchesList
                                branches={branches}
                                onNewBranchClick={onNewBranchClick}
                                onEditBranchClick={onEditBranchClick}
                                onRemoveBranchClick={onRemoveBranchClick}
                                onBranchVisibilityChange={onBranchVisibilityChange}
                                onMarkMainBranchClick={onMarkMainBranchClick}
                            />
                        )}
                    </CompanyBranches>
                </Form.Item>
            ) : null}
        </>
    );
};
