import React, {useReducer, useState} from "react";
import {FormInput} from "../components/form";
import {StyledButton} from "../components/StyledButton";
import {Modal, Form, Tooltip, Select, Input, message} from "antd";
import {cloneDeep} from "lodash";
import {CloseIcon} from "../icons";
// import isURL from "validator/lib/isURL";
import {UploadableCoverPhoto} from "../components/UploadableCoverPhoto";
import {CitySelect, CountrySelect, RemoteBranch, StateSelect} from "../components/CountryStateCitySelect";
import {AreYouSureModal} from "../components/AreYouSureModal";
import {isValidURL} from "../utils/UrlValidations";
import {SearchableSelect} from "../components/SearchableSelect";

const {Option} = Select;

export const findBranchById = (company, branchId, defaultValue = null) => {
    if (branchId === "Global" || branchId === "All") {
        return {name: branchId, branchId};
    }

    if (company && Array.isArray(company.branches)) {
        const companyBranch = company.branches.find(b => b.branchId === branchId);
        if (companyBranch) {
            return companyBranch;
        }
    }

    return defaultValue;
};

const CompanyBranchInfoForm = ({branches, initialBranchInfo = {}, onFinish, submitText, admins, onRemove}) => {
    const [form] = Form.useForm();
    const [branchInfo, setBranchInfo] = useState(initialBranchInfo);
    const [pendingBranch, setPendingBranch] = useState(null);
    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        selectedCountry: initialBranchInfo.countryId
            ? {countryName: initialBranchInfo.countryName, countryId: initialBranchInfo.countryId}
            : null,
        selectedState: initialBranchInfo.stateId
            ? {stateName: initialBranchInfo.stateName, stateId: initialBranchInfo.stateId}
            : null,
        selectedCity: initialBranchInfo.cityId
            ? {cityName: initialBranchInfo.cityName, cityId: initialBranchInfo.cityId}
            : null
    });

    branches = branches.filter(branchInfo => branchInfo.branchId !== initialBranchInfo.branchId);

    const selectCountry = selectedCountry => {
        setState({
            selectedCountry,
            selectedState: null,
            selectedCity: null
        });
    };

    const selectState = selectedState => {
        setState({
            selectedState,
            selectedCity: null
        });
    };

    const selectCity = selectedCity => {
        setState({selectedCity});
    };

    const submit = () => {
        form.submit();
    };

    const onSubmit = values => {
        values = {
            ...values,
            countryName: selectedCountry.countryName,
            countryCode: selectedCountry.countryCode,
            stateName: selectedState ? selectedState.stateName : null,
            cityName: selectedCity ? selectedCity.cityName : null
        };

        if (isEmployeeTheBranchManager(values)) {
            setPendingBranch(values);
        } else {
            onFinish(values);
        }
    };

    const isEmployeeTheBranchManager = branchInfo => {
        if (!admins) return false;
        const admin = admins.find(a => a.userId === branchInfo.adminId);
        return admin.type === "employee";
    };

    const savePendingBranch = () => {
        onFinish(pendingBranch);
    };

    const {selectedCountry, selectedState, selectedCity} = state;

    return (
        <Form
            form={form}
            onFinish={onSubmit}
            initialValues={initialBranchInfo}
            style={{width: 360}}
            onValuesChange={(_, allValues) => setBranchInfo(allValues)}>
            <AreYouSureModal
                visible={!!pendingBranch}
                title="Employee as branch admin, are you sure?"
                subTitle="You assigned an employee to become a branch manager. This action will make him/her a branch admin and not an employee anymore."
                yesText="Make admin"
                noText="Cancel"
                onYes={() => savePendingBranch()}
                onNo={() => setPendingBranch(null)}
            />
            <Form.Item
                name="coverPhoto"
                valuePropName="imageUrl"
                className="wb-error-padding"
                rules={[
                    {
                        validator: (_, url) => {
                            if (!url) {
                                return Promise.reject("You must upload a cover.");
                            } else if (!isURL(url, {require_protocol: true, allow_underscores: true})) {
                                return Promise.reject("Invalid cover URL.");
                            }
                            return Promise.resolve();
                        }
                    }
                ]}>
                <UploadableCoverPhoto
                    width={360}
                    height={300}
                    quality={100}
                    query={branchInfo.name ? branchInfo.name : "office"}
                    showPlaceholder={true}
                    showReload={true}
                    coverStyle={{
                        borderRadius: 0,
                        width: "100%",
                        paddingBottom: "83%"
                    }}
                />
            </Form.Item>
            <div style={{display: "block", paddingLeft: 20, paddingRight: 20}}>
                <Form.Item
                    name="name"
                    validateFirst={true}
                    rules={[
                        {
                            required: true,
                            message: "Please enter branch name."
                        },
                        {
                            validator: (_, value) => {
                                if (value && branches.some(({name}) => value.toLowerCase() === name.toLowerCase())) {
                                    return Promise.reject("Branch name must be unique.");
                                }

                                return Promise.resolve();
                            }
                        }
                    ]}>
                    <FormInput placeholder="Branch name" />
                </Form.Item>
                <Input.Group style={{width: "100%", display: "flex"}}>
                    <Form.Item
                        style={{width: "100%", minWidth: 155}}
                        name="countryId"
                        rules={[
                            {
                                validator: (_, value) => {
                                    return value ? Promise.resolve() : Promise.reject("Please select country.");
                                }
                            }
                        ]}>
                        <CountrySelect
                            value={selectedCountry ? selectedCountry.countryId : null}
                            placeholder="Country"
                            onSelect={selectedCountry => selectCountry(selectedCountry)}
                            remoteBranchEnabled={true}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{marginLeft: 10, width: "100%", minWidth: 155}}
                        name="stateId"
                        rules={[
                            {
                                validator: (_, value) => {
                                    return !value && selectedCountry.countryCode === "US"
                                        ? Promise.reject("Please select state.")
                                        : Promise.resolve();
                                }
                            }
                        ]}>
                        <StateSelect
                            value={selectedState ? selectedState.stateId : null}
                            placeholder="State"
                            countryId={selectedCountry ? selectedCountry.countryId : null}
                            onSelect={selectedState => selectState(selectedState)}
                        />
                    </Form.Item>
                </Input.Group>
                <Form.Item
                    name="cityId"
                    rules={[
                        {
                            validator: (_, value) => {
                                return (selectedCountry && selectedCountry.countryId === RemoteBranch.countryId) ||
                                    value
                                    ? Promise.resolve()
                                    : Promise.reject("Please select city.");
                            }
                        }
                    ]}>
                    <CitySelect
                        value={selectedCity ? selectedCity.cityId : null}
                        placeholder="City"
                        countryId={selectedCountry ? selectedCountry.countryId : null}
                        stateId={selectedState ? selectedState.stateId : null}
                        onSelect={selectedCity => selectCity(selectedCity)}
                    />
                </Form.Item>
                <Form.Item
                    name="address"
                    rules={[
                        {
                            validator: (_, value) => {
                                return (selectedCountry && selectedCountry.countryId === RemoteBranch.countryId) ||
                                    value
                                    ? Promise.resolve()
                                    : Promise.reject("Please enter company's address.");
                            }
                        }
                    ]}>
                    <FormInput placeholder="Address" />
                </Form.Item>
                {Array.isArray(admins) && admins.length > 0 ? (
                    <Form.Item
                        name="adminId"
                        rules={[
                            {
                                required: true,
                                message: "Please select a contact."
                            }
                        ]}>
                        <SearchableSelect placeholder="Branch manager">
                            {admins.map(admin => {
                                return (
                                    <Option key={admin.userId} value={admin.userId}>
                                        {admin.firstName && admin.lastName
                                            ? `${admin.firstName} ${admin.lastName} (${admin.email})`
                                            : `Admin master (${admin.email})`}
                                    </Option>
                                );
                            })}
                        </SearchableSelect>
                    </Form.Item>
                ) : null}
                <Form.Item
                    name="helpLink"
                    rules={[
                        {
                            validator: (_, value) => {
                                if (value && !isValidURL(value)) {
                                    return Promise.reject("Please enter URL or 'mailto:email'");
                                }

                                return Promise.resolve();
                            }
                        }
                    ]}>
                    <FormInput placeholder="Contact link" />
                </Form.Item>
                <Form.Item
                    name="visibility"
                    rules={[
                        {
                            required: true,
                            message: "Please select a visibility setup."
                        }
                    ]}>
                    <Select placeholder="Branch visibility">
                        <Option value="all">
                            <Tooltip title="Employees from this office see other offices data">
                                <span>Employees from this office see other offices data</span>
                            </Tooltip>
                        </Option>
                        <Option value="only">
                            <Tooltip title="Employees from this office can see its data only">
                                <span>Employees from this office can see its data only</span>
                            </Tooltip>
                        </Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <div
                        style={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}}>
                        {onRemove ? (
                            <StyledButton
                                onClick={() => onRemove()}
                                type="link"
                                style={{
                                    border: "none",
                                    width: 150,
                                    textAlign: "center",
                                    color: "var(--secondary-color)",
                                    boxShadow: "none",
                                    backgroundColor: "white",
                                    padding: 0,
                                    paddingLeft: 10,
                                    justifyContent: "flex-start",
                                    textDecoration: "underline"
                                }}>
                                Delete branch
                            </StyledButton>
                        ) : null}
                        <StyledButton
                            onClick={submit}
                            style={{
                                borderRadius: 25,
                                width: onRemove ? 120 : "100%",
                                minWidth: 120,
                                textAlign: "center",
                                backgroundColor: "var(--secondary-color)",
                                height: 50,
                                color: "white"
                            }}>
                            {submitText}
                        </StyledButton>
                    </div>
                </Form.Item>
            </div>
        </Form>
    );
};

const BranchModal = ({children, onClose, visible}) => (
    <Modal
        width={360}
        className="wb-modal-radius"
        destroyOnClose={true}
        open={visible}
        onCancel={() => onClose()}
        footer={null}
        title={null}
        closable={true}
        closeIcon={<CloseIcon fill="white" />}
        style={{top: 10, marginBottom: 200}}
        bodyStyle={{padding: 0}}>
        <div style={{display: "flex", width: "100%", flexDirection: "column", alignItems: "center"}}>
            <div
                style={{
                    height: 56,
                    width: "100%",
                    backgroundColor: "#595AD4",
                    fontSize: 20,
                    color: "white",
                    fontWeight: 700,
                    paddingLeft: 20,
                    display: "flex",
                    alignItems: "center"
                }}>
                Branch information
            </div>
            {children}
        </div>
    </Modal>
);

export const NewCompanyBranchModal = ({branches, visible, onClose, admins}) => {
    const addBranch = branchInfo => {
        onClose(branchInfo);
    };

    return (
        <BranchModal visible={visible} onClose={onClose}>
            <CompanyBranchInfoForm branches={branches} onFinish={addBranch} submitText="Add" admins={admins} />
        </BranchModal>
    );
};

export const EditCompanyBranch = ({branches, branchToEdit, visible, onClose, admins, onRemove}) => {
    branchToEdit = cloneDeep(branchToEdit);

    const updateBranch = branchInfo => {
        onClose(
            branches.map(branch => {
                if (branch.branchId !== branchToEdit.branchId) {
                    return branch;
                } else {
                    return {...branchToEdit, ...branchInfo};
                }
            })
        );
    };

    return (
        <BranchModal visible={visible} onClose={onClose}>
            <CompanyBranchInfoForm
                branches={branches}
                initialBranchInfo={branchToEdit}
                onFinish={updateBranch}
                submitText="Update"
                admins={admins}
                onRemove={onRemove}
            />
        </BranchModal>
    );
};

export const CompanyBranches = ({value, onChange, admins, children, onAppearanceChange = () => {}}) => {
    const [showNewBranchModal, setShowNewBranchModal] = useState(false);
    const [editBranchInfo, setEditBranchInfo] = useState(null);
    const branches = value || [];

    const doChange = newBranches => {
        onChange(newBranches);
    };

    const closeNewModal = branchInfo => {
        let newBranches = null;
        if (branchInfo) {
            newBranches = branches.concat(branchInfo);
            markSingleBranchAsHQ(newBranches);
            doChange(newBranches);
        }
        setShowNewBranchModal(false);
        onAppearanceChange(false, newBranches);
    };

    const markAsMainBranch = branchId => {
        doChange(
            branches.map(b => {
                b.isHQ = b.branchId === branchId;
                return b;
            })
        );
    };

    const removeBranch = branchId => {
        const newBranches = branches.filter(branchInfo => branchInfo.branchId !== branchId);
        markSingleBranchAsHQ(newBranches);
        doChange(newBranches);
        return newBranches;
    };

    const closeEditModal = newBranches => {
        if (newBranches) {
            doChange(newBranches);
        }
        setEditBranchInfo(null);
        onAppearanceChange(false, newBranches);
    };

    const newBranch = () => {
        setShowNewBranchModal(true);
        onAppearanceChange(true);
    };

    const editBranch = branchToEdit => {
        setEditBranchInfo(branchToEdit);
        onAppearanceChange(true);
    };

    const markSingleBranchAsHQ = newBranches => {
        if (newBranches.length === 1) {
            newBranches[0].isHQ = true;
        }
    };

    const changeVisibilityState = branchInfo => {
        doChange(
            branches.map(b => {
                if (b.branchId === branchInfo.branchId) {
                    b.visibility =
                        typeof branchInfo.visibility === "undefined" || branchInfo.visibility === "all"
                            ? "only"
                            : "all";
                }

                return b;
            })
        );
    };

    const instantlyRemoveBranch = () => {
        if (editBranchInfo.isHQ) {
            message.warn("You cannot remove your HQ, please select different HQ first.", 5);
        } else {
            const newBranches = removeBranch(editBranchInfo.branchId);
            closeEditModal(newBranches);
        }
    };

    return (
        <div>
            <NewCompanyBranchModal
                branches={branches}
                visible={showNewBranchModal}
                onClose={closeNewModal}
                admins={admins}
            />
            <EditCompanyBranch
                branches={branches}
                visible={!!editBranchInfo}
                branchToEdit={editBranchInfo}
                onClose={closeEditModal}
                admins={admins}
                onRemove={() => instantlyRemoveBranch()}
            />
            {children({
                branches,
                onNewBranchClick: () => newBranch(),
                onEditBranchClick: branchInfo => editBranch(branchInfo),
                onRemoveBranchClick: branchId => removeBranch(branchId),
                onBranchVisibilityChange: branchInfo => changeVisibilityState(branchInfo),
                onMarkMainBranchClick: branchId => markAsMainBranch(branchId)
            })}
        </div>
    );
};
