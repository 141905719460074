import {shippingProvidersOptions} from "./consts.jsx";

export function calculateModularProductPrice(subProducts, providerSubProducts) {
    return subProducts.reduce((acc, subProduct) => {
        const foundProviderSubProduct =
            providerSubProducts?.find(providerSubProduct => providerSubProduct.id === subProduct.id) ?? {};
        return subProduct.isActive && foundProviderSubProduct?.isActive
            ? acc + subProduct?.price * (subProduct?.defaultAmount ?? 0)
            : acc;
    }, 0);
}

export function isProductHasShippingProvider(product) {
    return !product?.shippingProviders?.includes(shippingProvidersOptions.regular);
}
