import React from "react";

export const WBCheckbox = ({onChange, checked = false, style}) => {
    const checkBoxId = `checkbox-${Math.random()}`;
    return (
        <div className="container" style={style}>
            <div className="wb-checkbox-round">
                <input onChange={onChange} checked={checked} type="checkbox" id={checkBoxId} />
                <label htmlFor={checkBoxId} />
            </div>
        </div>
    );
};
