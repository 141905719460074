import React, {useCallback, useContext, useEffect, useMemo} from "react";
import moment from "moment";
import {sumBy} from "lodash";
import {message, Spin} from "antd";
import {mergePresetWithTableData, transformTableDataForSaving} from "./utils";
import HappinessCalculatorCategoriesPanel from "./HappinessCalculatorCategoriesPanel";
import HappinessCalculatorSummary from "./HappinessCalculatorSummary";
import {EditSettingsModalTypes} from "./calculatorStepper/consts";
import {HappinessCalculatorContext} from "./HappinessCalculatorContext";
import {AppContext} from "../AppContext";
import {useRequest} from "../utils/hooks";
import {HttpClient} from "../http/HttpClient";
import {OrdersCalendarRoutes} from "../ordersCalendar/consts";
import "./happiness-calculator.css";

export const HappinessCalculatorPlanPage = ({
    history,
    planId,
    savedPlan,
    loadingSavedPlan,
    categoriesWithTotalPrice,
    allCategoriesPrice,
    categoriesData,
    loadingCategoriesData,
    employeesAmount,
    setEmployeesAmount,
    monthAmounts,
    setEditSettingsModalToShow,
    setTableData,
    tableData,
    timeRanges,
    userActions,
    setOpenCategories,
    log
}) => {
    const {company} = useContext(AppContext);
    const {planOccasionsCategories, planTimeRange, setPlanOccasionsCategories, setPlanTimeRange} =
        useContext(HappinessCalculatorContext);

    useEffect(() => {
        if (savedPlan && !savedPlan.error) {
            setEmployeesAmount(savedPlan.employeesAmount);
            const planedMonth = moment(savedPlan.planedMonth);
            setPlanTimeRange([planedMonth.startOf("month"), planedMonth.endOf("month")]);
            setPlanOccasionsCategories(Object.keys(savedPlan.categories));
        }
    }, [savedPlan]);

    useEffect(() => {
        if (planOccasionsCategories?.length) {
            setOpenCategories(planOccasionsCategories);
        }
    }, [planOccasionsCategories]);

    const [companySectorPreset] = useRequest(
        `/api/calculatorPresets/${company?.sector}`,
        "get",
        null,
        [],
        !!company?.sector
    );

    const enrichedSectorPreset = useMemo(() => {
        if (!companySectorPreset) {
            return null;
        }

        return {
            ...companySectorPreset,
            tableData: Object.values(companySectorPreset.tableData).reduce(
                (acc, category) => ({
                    ...acc,
                    [category.occasionCategoryId]: {
                        ...category,
                        yearlyEmployeePrice: sumBy(
                            category.occasions,
                            ({price, odds, ratio}) => price * ((odds ?? 100) / 100) * (12 * (ratio ?? 1 / 12))
                        )
                    }
                }),
                {}
            )
        };
    }, [companySectorPreset]);

    const applyPreset = useCallback(async () => {
        if (!companySectorPreset) {
            message.error("לא הצלחנו לזהות את סוג החברה שלכם, לצורך המלצה :(");
            return;
        }

        setTableData(prev => mergePresetWithTableData(companySectorPreset.tableData, prev));
    }, [companySectorPreset]);

    useEffect(() => {
        if (!employeesAmount && company?.employeesCount) {
            setEmployeesAmount(parseInt(company?.employeesCount) || 100);
        }
    }, [employeesAmount, company?.employeesCount]);

    const onSavePlan = useCallback(async () => {
        const transformedTableData = transformTableDataForSaving(tableData);

        const newPlanData = {
            employeesAmount,
            planTimeRange: planTimeRange.map(time => time.format("MM/YYYY")),
            categories: transformedTableData
        };

        const {error} = planId
            ? await HttpClient.safePut(`/api/budgetPlan/update/${planId}`, newPlanData)
            : await HttpClient.safePost("/api/budgetPlan", newPlanData);

        if (error) {
            message.error("ניסון שמירה נכשל");
        } else {
            log("Save plan clicked", {tableData});
            history.push(OrdersCalendarRoutes.ORDERS_CALENDAR);
        }
    }, [log, tableData, employeesAmount, planTimeRange, planId, history]);

    return (
        <div className="happiness-calculator-body2">
            {loadingCategoriesData || loadingSavedPlan ? (
                <Spin />
            ) : (
                <HappinessCalculatorCategoriesPanel
                    log={log}
                    tableData={tableData}
                    setTableData={setTableData}
                    employeesAmount={employeesAmount}
                    timeRanges={timeRanges}
                    userActions={userActions}
                    onAddNewCategoriesClicked={() => {
                        log("Add New Category Button Clicked");
                        setEditSettingsModalToShow(EditSettingsModalTypes.CategoriesEdit);
                    }}
                    savedPlan={savedPlan}
                    categoriesData={categoriesData}
                />
            )}
            <HappinessCalculatorSummary
                applyPreset={applyPreset}
                monthAmounts={monthAmounts}
                employeesAmount={employeesAmount}
                sectorPreset={enrichedSectorPreset}
                tableData={tableData}
                onSave={onSavePlan}
                categoriesWithTotalPrice={categoriesWithTotalPrice}
                allCategoriesPrice={allCategoriesPrice}
                log={log}
            />
        </div>
    );
};
