import styled from "styled-components";
import {Button} from "antd";

export const StyledButton = styled(Button)`
    opacity: 1 !important;
    height: 50px;
    color: ${props => (props.style && props.style.color ? props.style.color : "var(--secondary-color)")};
    background-color: ${props =>
        props.style && props.style.backgroundColor
            ? props.style.backgroundColor
            : props.disabled
            ? "#d6d8da"
            : "#FCFDFE"};
    border-radius: 25px;
    font-size: 16px;
    outline: none;
    border: 1px solid #E9EDF4;
    display: flex;
    align-items: center;
    justify-content: center;
    boz-shadow: none !important;
    cursor: ${props => (props.disabled ? "not-allowed" : "cursor")}
    
    &:hover, &:active {
        opacity: 0.95 !important;
        font-size: 16px;
        outline: none;
        border: 1px solid #c3c6ca !important;
        boz-shadow: none !important;
    }
`;
