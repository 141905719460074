import React, {useCallback, useEffect, useState} from "react";
import {ConfigProvider} from "antd";
import {EditBannerModal} from "./EditBannerModal";
import {Banner, SortableList, SquareButton} from "../../components";
import {TrashIcon} from "../../icons";
import "./admin-banners-edit.css";

export const AdminBannersEdit = ({banners, onSave, defaultNewBanner = {}, disabled = false, otherButtons = null}) => {
    const [editBannersModalProps, setEditBannersModalProps] = useState(null);
    const [editedBanners, setEditedBanners] = useState(null);

    useEffect(() => {
        setEditedBanners(banners);
    }, [banners]);

    const onSortEnd = useCallback(
        ({oldIndex, newIndex}) => {
            setEditedBanners(prevBanners => {
                const newBanners = [...prevBanners];
                newBanners.splice(newIndex, 0, newBanners.splice(oldIndex, 1)[0]);
                return newBanners;
            });
        },
        [setEditedBanners]
    );

    const onBannerEdit = useCallback(
        (newBanner, index) => {
            setEditedBanners(prevBanners => {
                const newBanners = [...prevBanners];
                newBanners.splice(index === -1 ? 0 : index, index === -1 ? 0 : 1, newBanner);
                return newBanners;
            });
        },
        [setEditedBanners]
    );

    const removeBanner = useCallback(indexToRemove => {
        setEditedBanners(prevBanners => prevBanners.filter((_, index) => indexToRemove !== index));
    }, []);

    return (
        <div className="admin-banners-edit">
            <ConfigProvider direction="rtl">
                <EditBannerModal
                    banner={editBannersModalProps?.banner}
                    index={editBannersModalProps?.index}
                    visible={!!editBannersModalProps}
                    onClose={() => setEditBannersModalProps(null)}
                    onSave={onBannerEdit}
                    bannerDimension={{width: 800, height: 300}}
                />
                <div className="admin-banners-edit-landing-page-banners">
                    <div className="admin-banners-edit-landing-page-banners-buttons">
                        <SquareButton
                            disabled={disabled}
                            className="admin-banners-edit-landing-page-banners-save-button"
                            onClick={() => onSave(editedBanners)}>
                            שמירה
                        </SquareButton>
                        <SquareButton
                            disabled={disabled}
                            className="admin-banners-edit-landing-page-banners-add-button"
                            onClick={() =>
                                setEditBannersModalProps({
                                    banner: {...defaultNewBanner},
                                    index: -1
                                })
                            }>
                            הוסף באנר
                        </SquareButton>
                        {otherButtons}
                    </div>
                    <SortableList onSortEnd={onSortEnd}>
                        {editedBanners?.map((banner, index) => (
                            <div key={banner.name} className="admin-banners-edit-landing-page-banner-line">
                                <Banner
                                    onClick={() => setEditBannersModalProps({banner, index})}
                                    width={700}
                                    height={190}
                                    image={banner.image}
                                    text={banner.text}
                                    buttonText={banner.buttonText}
                                />
                                <TrashIcon onClick={() => removeBanner(index)} />
                            </div>
                        ))}
                    </SortableList>
                </div>
            </ConfigProvider>
        </div>
    );
};
