import React from "react";
import "../../../../dashboards/app-dashboard.css";
import "./accuracy-results-filter-popover.css";
import {AccuracyResultsFilterPopover} from "./AccuracyResultsFilterPopover";
import {BoundingActivitiesAccuracyResultsFilterPopover} from "./BoundingActivitiesAccuracyResultsFilterPopover";
import {MainServices} from "../../../../consts.js";

export const SpecificResultFilterPopoverFactory = ({panel}) => {
    switch (panel) {
        case MainServices.WORKSHOPS:
            // will be in future
            // return <BoundingActivitiesAccuracyResultsFilterPopover />;
            return <AccuracyResultsFilterPopover />;
        default:
            return <AccuracyResultsFilterPopover />;
    }
};
