import React, {useContext, useMemo} from "react";
import {filterOptionsList} from "./utils.jsx";
import {FilterSearchOptionsList} from "./FilterSearchOptionList.jsx";
import {MarketplaceFilterProviderContext} from "../MarketplaceFilterContext.jsx";

export const FilterSearchOptions = ({
    filterValues,
    setFilterValues,
    removeSearchInput,
    removeExtraSearchInput,
    onLockedFiltersChange,
    lockedFilters = []
}) => {
    const {innerState, updateLocalFilterState, removeKeyFromInnerState, panel} = useContext(
        MarketplaceFilterProviderContext
    );

    const filterOptions = useMemo(() => {
        return filterOptionsList(
            filterValues,
            removeSearchInput,
            removeExtraSearchInput,
            setFilterValues,
            updateLocalFilterState,
            innerState,
            removeKeyFromInnerState,
            panel
        );
    }, [filterValues]);

    return (
        <>
            <FilterSearchOptionsList
                filterOptions={filterOptions}
                onLockedFiltersChange={onLockedFiltersChange}
                lockedFilters={lockedFilters}
            />
        </>
    );
};
