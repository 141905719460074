import CallToActionModal from "../components/CallToActionModal";
import React from "react";
import {Typography} from "antd";
import {ModalTitle} from "../components/ModalTitle";
import {ContactPhone} from "../support/ContactPhone";

export const EventAcceptedModal = ({visible, history}) => {
    const Title = () => <ModalTitle title="Congrats!" subTitle="You’ve got a new client!" />;

    const SubTitle = () => (
        <div style={{display: "flex", flexDirection: "column"}}>
            <Typography.Text style={{textAlign: "center", fontSize: 20, marginBottom: 10}}>
                What's next?
            </Typography.Text>
            <Typography.Paragraph>
                <ul>
                    <li>Check out your updated calendar. It looks really great!</li>
                    <li>Contact us to get your payment. You deserve it :)</li>
                    <li style={{display: "flex", flexWrap: "wrap"}}>
                        Have any questions? Feel free to call:{" "}
                        <span style={{marginLeft: 5}}>{ContactPhone.prettyPhoneNumber()}</span>
                    </li>
                </ul>
            </Typography.Paragraph>
        </div>
    );

    return (
        <CallToActionModal
            imageName="success3"
            title={<Title />}
            subTitle={<SubTitle />}
            subTitleStyle={{textAlign: "left"}}
            actionText="Contact us"
            actionHandler={() => history.push("/contact?subject=payments")}
            anotherActionText="Check my calendar"
            anotherActionHandler={() => history.push("/dashboard/schedule")}
            visible={visible}
        />
    );
};
