import React from "react";
import {downloadFromUrl} from "../../../utils/downloadFromUrl.jsx";
import {ActivityLocation, ActivityLocationTypes, ProviderActivityPossibleLocations} from "../../../consts.js";
import {StringBuilder} from "../../../AppUtils.js";
import "../../order-information-card.css";
import {generateLionWheelOrderDeliveryStatusLink} from "../../OrderDeliveryStatus/utils.jsx";
import {addressesListExcelExporter} from "../addressesListExcelExporter.js";

const DetailRow = ({label, value}) => {
    return (
        <div>
            <b>{label}</b>
            {value}
        </div>
    );
};

export const OrderWhereInfo = ({eventInfo, readMore, toggleReadMore, openAddressInNewTab}) => {
    return (
        <>
            {eventInfo.address ? (
                <div className="order-information-card-open-address-container">
                    <span>
                        {new StringBuilder()
                            .append(eventInfo.address)
                            .append(eventInfo?.floor ? "קומה" : "")
                            .append(eventInfo?.floor ? eventInfo.floor : "")
                            .toString()}
                    </span>
                    {!Object.values(ActivityLocation).includes(eventInfo.address) ? (
                        <span
                            className="order-information-card-open-link"
                            onClick={() => openAddressInNewTab(eventInfo?.address)}>
                            {"פתח בגוגל מפות"}
                        </span>
                    ) : null}
                </div>
            ) : null}

            {!!eventInfo?.employeesExcel?.addresses?.length && (
                <div className="order-information-card-employees-details">
                    <div className="order-information-card-employees-details-title">
                        <b>
                            {new StringBuilder()
                                .append("סך הכל")
                                .append(eventInfo.employeesExcel.addresses.length)
                                .append("מקבלי מארזים")
                                .toString()}
                        </b>
                    </div>

                    <div className="order-information-card-employees-details-title">
                        <div>
                            <span
                                className="order-information-card-open-link"
                                onClick={() =>
                                    addressesListExcelExporter(
                                        eventInfo?.employeesExcel?.addresses,
                                        new StringBuilder(
                                            ProviderActivityPossibleLocations[ActivityLocationTypes.homeDelivery]
                                        )
                                            .append("-")
                                            .append("הזמנה מספר")
                                            .append(eventInfo?.proposalId ?? eventInfo?.eventId)
                                            .toString()
                                    )
                                }>
                                הורדת קובץ אקסל
                            </span>
                        </div>
                        <div className="order-information-card-open-link" onClick={() => toggleReadMore()}>
                            {readMore ? "הסתר רשימת מקבלים" : "הצג רשימת מקבלים"}
                        </div>
                    </div>

                    {readMore &&
                        eventInfo.employeesExcel.addresses.map(
                            ({
                                receiverFirstName,
                                receiverLastName,
                                receiverPhoneNumber,
                                shippingDetails,
                                status,
                                greetingMessage
                            }) => {
                                return (
                                    <>
                                        <span>פרטי המקבל:</span>
                                        <div
                                            className="order-information-card-employee-details"
                                            key={`${receiverFirstName}-${receiverLastName}`}>
                                            <DetailRow
                                                label="שם מקבל המארז:"
                                                value={new StringBuilder()
                                                    .append(receiverFirstName)
                                                    .append(receiverLastName)
                                                    .toString()}
                                            />
                                            {!!receiverPhoneNumber && (
                                                <DetailRow label="טלפון:" value={receiverPhoneNumber} />
                                            )}

                                            {!!status && <DetailRow label="סטאטוס:" value={status} />}

                                            {!!greetingMessage && <DetailRow label="ברכה:" value={greetingMessage} />}

                                            {!!shippingDetails?.tracking_link && (
                                                <DetailRow
                                                    label="לינק מעקב להזמנה:"
                                                    value={
                                                        <a
                                                            target="_blank"
                                                            href={generateLionWheelOrderDeliveryStatusLink(
                                                                shippingDetails
                                                            )}>
                                                            לחץ כאן
                                                        </a>
                                                    }
                                                />
                                            )}
                                        </div>
                                    </>
                                );
                            }
                        )}
                </div>
            )}

            {eventInfo?.employeesExcel?.fileUrl ? (
                <div className="order-information-card-employees-excel">
                    <span>לחץ להורדת קובץ אקסל הכתובות:</span>
                    <span
                        onClick={() =>
                            downloadFromUrl(
                                eventInfo.employeesExcel.fileUrl,
                                `אקסל כתובות - הזמנה מספר ${eventInfo?.proposalId ?? eventInfo?.eventId}.xlsx`
                            )
                        }>
                        {eventInfo.employeesExcel.fileName}
                    </span>
                </div>
            ) : null}

            {eventInfo?.employeesDeliveryDetail ? <EmployeeDeliveryDetails eventInfo={eventInfo} /> : null}
        </>
    );
};

export const EmployeeDeliveryDetails = ({eventInfo}) => {
    return (
        <div className="order-information-card-employees-details">
            {eventInfo?.employeesDeliveryDetail?.map(({address, apartment, name, phone, comments, wishes}) => {
                return (
                    <>
                        <span>פרטי המקבל:</span>
                        <div className="order-information-card-employee-details" key={`${name}-${address}`}>
                            <DetailRow label="שם המקבל:" value={name} />
                            {!!address && <DetailRow label="כתובת:" value={address} />}
                            {!!apartment && <DetailRow label="דירה:" value={apartment} />}
                            {!!phone && <DetailRow label="טלפון:" value={phone} />}
                            {!!comments && <DetailRow label="הערות:" value={comments} />}
                            {!!wishes && <DetailRow label="ברכה:" value={wishes} />}
                        </div>
                    </>
                );
            })}
        </div>
    );
};
