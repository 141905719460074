import {openWindow} from "../http/WindowOpener";
import common from "@well-b/well-b-shared";
import {getThemeByOrigin} from "../utils.js";
import {Config, ConfigProps} from "../Config.js";

export class ContactPhone {
    static whatsAppLink(phoneNumber = null, message = null) {
        const number = phoneNumber ? phoneNumber : ContactPhone.phoneNumber();
        const cleanNumber = number.replace(/[^\d]/g, "");

        const params = new URLSearchParams();

        if (message) {
            params.append("text", message);
        }

        params.append("phone", cleanNumber);

        return `https://api.whatsapp.com/send?${params.toString()}`;
    }

    static openWhatsApp(phoneNumber = null) {
        openWindow(ContactPhone.whatsAppLink(phoneNumber), "_blank", true);
    }

    static phoneNumber() {
        const systemIdToSupportPhoneNumber = Config.envJson(ConfigProps.SYSTEM_ID_TO_SUPPORT_PHONE_NUMBER);
        const systemId = getThemeByOrigin(window.location.origin);
        return systemIdToSupportPhoneNumber[systemId.toLowerCase()];
    }

    static phoneNumberBySystemId(systemId) {
        const systemIdToSupportPhoneNumber = Config.envJson(ConfigProps.SYSTEM_ID_TO_SUPPORT_PHONE_NUMBER);
        const selectedSystemId = systemId || common.themeNames.wellB;
        return (
            systemIdToSupportPhoneNumber[selectedSystemId.toLowerCase()] || Config.env(ConfigProps.SUPPORT_PHONE_NUMBER)
        );
    }

    static prettyPhoneNumber(phoneNumber = null) {
        phoneNumber = (phoneNumber || ContactPhone.phoneNumber()).replace("+", "");
        return `+${phoneNumber.match(/.{1,3}/g).join(" ")}`;
    }
}
