import {useCallback, useContext, useState} from "react";
import {MarketplaceFilterProviderContext} from "../../MarketplaceFilterContext.jsx";
import {parseFloatNumber} from "../../../../utils/NumberUtils.jsx";
import {budgetFields} from "./consts.js";

export const useBudgetUpdates = () => {
    const {innerState, updateInnerState, removeKeyFromInnerState} = useContext(MarketplaceFilterProviderContext);
    const [latestChangeField, setLatestChangeField] = useState(null);

    const onAmountParticipantsChange = useCallback(
        event => {
            const amountParticipant = event.target.value;
            if (!parseInt(amountParticipant) || parseInt(amountParticipant) < 0) {
                removeKeyFromInnerState("budget.participantsAmount");
                return;
            }
            updateInnerState("budget.participantsAmount", Number(parseFloatNumber(event.target.value)));
            if (latestChangeField === budgetFields.totalPrice && innerState?.budget?.totalPrice?.max) {
                updateInnerState(
                    "budget.pricePerParticipant.max",
                    Number(parseFloatNumber(Number(innerState.budget.totalPrice.max) / Number(amountParticipant)))
                );
            } else if (innerState?.budget?.pricePerParticipant?.max) {
                updateInnerState(
                    "budget.totalPrice.max",
                    Number(parseFloatNumber(Number(amountParticipant) * innerState.budget.pricePerParticipant?.max))
                );
            }
        },
        [innerState?.budget, latestChangeField]
    );

    const onBudgetPerParticipant = useCallback(
        event => {
            const pricePerParticipant = event.target.value;
            if (!parseInt(pricePerParticipant) || parseInt(pricePerParticipant) < 0) {
                removeKeyFromInnerState("budget.pricePerParticipant");
                return;
            }
            updateInnerState("budget.pricePerParticipant.max", Number(parseFloatNumber(event.target.value)));
            if (latestChangeField === budgetFields.totalPrice && innerState?.budget?.totalPrice?.max) {
                updateInnerState(
                    "budget.participantsAmount",
                    Number(parseFloatNumber(Number(innerState.budget.totalPrice.max) / Number(pricePerParticipant)))
                );
            } else if (innerState?.budget?.participantsAmount) {
                updateInnerState(
                    "budget.totalPrice.max",
                    Number(parseFloatNumber(Number(pricePerParticipant) * innerState.budget.participantsAmount))
                );
            }
        },
        [innerState?.budget, latestChangeField]
    );

    const onOrderBudgetChange = useCallback(
        event => {
            const totalPrice = event.target.value;
            if (!parseInt(totalPrice) || parseInt(totalPrice) < 0) {
                removeKeyFromInnerState("budget.totalPrice");
                return;
            }

            updateInnerState("budget.totalPrice.max", Number(parseFloatNumber(totalPrice)));
            if (
                latestChangeField === budgetFields.pricePerParticipant &&
                innerState?.budget?.pricePerParticipant?.max
            ) {
                updateInnerState(
                    "budget.participantsAmount",
                    Number(parseFloatNumber(Number(totalPrice) / innerState.budget.pricePerParticipant.max))
                );
            } else if (innerState?.budget?.participantsAmount) {
                updateInnerState(
                    "budget.pricePerParticipant.max",
                    Number(parseFloatNumber(Number(totalPrice) / innerState.budget.participantsAmount))
                );
            }
        },
        [innerState?.budget, latestChangeField]
    );
    return {onAmountParticipantsChange, onBudgetPerParticipant, onOrderBudgetChange, setLatestChangeField};
};
