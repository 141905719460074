import {StringBuilder} from "../../AppUtils.js";
import {kosherTypesToShortHebrew} from "../MarketplaceFilter/popoverFilters/accuracyResultsFilter/consts.js";
import {FoodRequestsShortIdToName, kosherTypes} from "../ProductsFilterModal/consts.jsx";
import {uniq} from "lodash";

export const getProviderFoodOptions = providerFoodRequests => {
    const foodRequests = (uniq(providerFoodRequests) ?? []).map(kosher => FoodRequestsShortIdToName[kosher]);
    return foodRequests.length > 0
        ? new StringBuilder().append("🌱").append("אפשרויות").append(foodRequests.join(" | ")).toString()
        : null;
};

export const getProviderKosherOptions = providerKosherTypes => {
    const productKosherType = Array.isArray(providerKosherTypes)
        ? uniq(providerKosherTypes).filter(kosherType => kosherType !== kosherTypes.NOT_NEEDED && Boolean(kosherType))
        : [];
    const koshers = productKosherType.map(kosher => kosherTypesToShortHebrew[kosher]).filter(Boolean);
    return koshers.length > 0
        ? new StringBuilder().append("✡️").append("כשרות").append(koshers.join(" | ")).toString()
        : null;
};
