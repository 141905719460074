import {Modal} from "antd";
import {PageTitleLabel} from "../../../components/PageTitle";
import {CompanyContentTagInfoForm} from "./CompanyContentTagInfoForm";
import React from "react";
import {cloneDeep} from "lodash";

export const CompanyContentTagEdit = ({tags, tagToEdit, visible, onClose, onEdit}) => {
    tagToEdit = cloneDeep(tagToEdit);

    const updateTag = tagInfo => {
        tagInfo = {
            ...tagToEdit,
            ...tagInfo
        };

        onEdit(tagInfo);
    };

    return (
        <Modal
            centered={true}
            width={340}
            style={{marginBottom: 200}}
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            footer={null}
            title={null}
            closable={true}>
            <div style={{display: "flex", width: "100%", flexDirection: "column", alignItems: "center"}}>
                <PageTitleLabel style={{marginTop: 20, marginBottom: 20}}>Category information</PageTitleLabel>
                <CompanyContentTagInfoForm
                    tags={tags}
                    initialTagInfo={tagToEdit}
                    onFinish={updateTag}
                    submitText="Update"
                />
            </div>
        </Modal>
    );
};
