import React from "react";
import {AdminMarketplaceViewAutoComplete} from "./AdminMarketplaceViewAutoComplete";
import {MarketplaceViewTypeToHebrew} from "./consts";
import {MarketplaceViewType} from "../../marketplace/consts";

export const FormModalMarketplaceViewAutoComplete = ({
    itemType,
    content,
    updateFormValues,
    contentFieldName = "content",
    contentTypeFieldName = "type",
    optional = false
}) => {
    const customCarouselItemType = [];
    if (itemType) {
        customCarouselItemType.push(
            itemType !== MarketplaceViewType.link
                ? {
                      name: contentFieldName,
                      label: "בחר מקבץ",
                      type: "custom",
                      customComponent: (
                          <AdminMarketplaceViewAutoComplete
                              itemType={itemType}
                              content={content}
                              onSelect={contentId => updateFormValues(contentFieldName, contentId)}
                          />
                      )
                  }
                : {
                      name: contentFieldName,
                      label: MarketplaceViewTypeToHebrew[MarketplaceViewType.link]
                  }
        );
    }

    return [
        {
            name: contentTypeFieldName,
            label: "סוג מקבץ למיני קטגוריה",
            optional,
            type: "select",
            options: Object.entries(MarketplaceViewTypeToHebrew).map(([name, label]) => ({label, name})),
            onSelect: () => {
                updateFormValues(contentFieldName, null);
            }
        },
        ...customCarouselItemType
    ];
};
