import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import moment from "moment";
import {message} from "antd";
import {monthsRequired} from "./consts";
import {MonthBox} from "./MonthBox";
import {MonthBudgetBox} from "../MonthBudgetBox";
import {HappinessCalculatorRoutes} from "../consts";
import {AppContext} from "../../AppContext";
import {useRequest} from "../../utils/hooks";
import {NOOP} from "../../utils/NOOP";
import {OnlyMonthFormat} from "../../ordersCalendar/consts";
import "./calculator-wizard.css";
import {HttpClient} from "../../http/HttpClient";
import {YearPicker} from "../../components/YearPicker";

export const PeriodStep = ({history, onClose = NOOP, periods = [], setPeriods, showTitle = true}) => {
    const {me} = useContext(AppContext);
    const [possibleEnd, setPossibleEnd] = useState("");
    const [currentYear, setCurrentYear] = useState(Number(moment().format("YYYY")));

    const planBudgetArray = useMemo(() => {
        return [
            moment(currentYear, "YYYY").startOf("year").format(OnlyMonthFormat),
            moment(currentYear, "YYYY").endOf("year").format(OnlyMonthFormat)
        ];
    }, [currentYear]);

    const [budgetBetweenMonthsFromServer] = useRequest(
        `/api/budgetPlan/betweenDates?from=${planBudgetArray[0]}&to=${planBudgetArray[1]}`,
        "GET",
        null,
        [currentYear]
    );

    const [budgetBetweenMonths, setBudgetBetweenMonths] = useState([]);

    useEffect(() => {
        if (budgetBetweenMonthsFromServer) setBudgetBetweenMonths(budgetBetweenMonthsFromServer);
    }, [budgetBetweenMonthsFromServer]);

    const monthsNamesInHebrew = useMemo(() => {
        return [...Array(monthsRequired)].map((_, index) => {
            return moment().startOf("year").add(index, "months");
        });
    }, []);

    const isBudgetMonthInDistance = useCallback(
        distanceIndexs => {
            return distanceIndexs.some(month =>
                budgetBetweenMonths?.find(
                    budget => moment(budget.planedMonth).format("MM/YYYY") === month.format("MM/YYYY")
                )
            );
        },
        [budgetBetweenMonths]
    );

    const indexesDistance = useMemo(() => {
        if (periods.length === 2) {
            const firstPeriod = monthsNamesInHebrew.findIndex(
                monthInHebrew => monthInHebrew.format("MM/YYYY") === periods[0].format("MM/YYYY")
            );
            const secondPeriod = monthsNamesInHebrew.findIndex(
                monthInHebrew => monthInHebrew.format("MM/YYYY") === periods[1].format("MM/YYYY")
            );

            const firstSelect = Math.min(firstPeriod, secondPeriod);
            const lastSelect = Math.max(firstPeriod, secondPeriod);

            const distanceIndexs = monthsNamesInHebrew.slice(firstSelect, lastSelect + 1);
            if (isBudgetMonthInDistance(distanceIndexs)) {
                return periods;
            } else {
                return distanceIndexs;
            }
        }
        return periods;
    }, [periods, monthsNamesInHebrew]);

    const indexesDistanceOfPossibleEnd = useMemo(() => {
        if (periods?.length === 1) {
            const possibleEndIndex = monthsNamesInHebrew.indexOf(possibleEnd);
            const firstSelect = Math.min(monthsNamesInHebrew.indexOf(periods[0]), possibleEndIndex);
            const lastSelect = Math.max(monthsNamesInHebrew.indexOf(periods[0]), possibleEndIndex);

            const distanceIndexs = monthsNamesInHebrew.slice(firstSelect, lastSelect + 1);
            if (isBudgetMonthInDistance(distanceIndexs)) {
                return periods;
            } else {
                return distanceIndexs;
            }
        }
        return periods;
    }, [periods, monthsNamesInHebrew, possibleEnd]);

    const onMouseEnter = useCallback(
        endMonth => {
            if (periods.length <= 1) {
                const possibleEndIndex = monthsNamesInHebrew.indexOf(endMonth);
                const firstSelect = Math.min(monthsNamesInHebrew.indexOf(periods[0]), possibleEndIndex);
                const lastSelect = Math.max(monthsNamesInHebrew.indexOf(periods[0]), possibleEndIndex);

                const distanceIndexs = monthsNamesInHebrew.slice(firstSelect, lastSelect + 1);
                if (!isBudgetMonthInDistance(distanceIndexs)) {
                    setPossibleEnd(endMonth);
                }
            }
        },
        [periods]
    );

    const onSelectMonth = useCallback(
        month => {
            if (periods.length === 1) {
                const possibleEndIndex = monthsNamesInHebrew.indexOf(month);
                const firstSelect = Math.min(monthsNamesInHebrew.indexOf(periods[0]), possibleEndIndex);
                const lastSelect = Math.max(monthsNamesInHebrew.indexOf(periods[0]), possibleEndIndex);

                const distanceIndexs = monthsNamesInHebrew.slice(firstSelect, lastSelect + 1);
                if (!isBudgetMonthInDistance(distanceIndexs)) {
                    setPeriods(current => [...current, month]);
                } else {
                    message.error("לא ניתן לבחור תאריך שנמצא בין חודשים עם תקציב מסגרת לתוכנית");
                }
            } else {
                setPeriods(current => [...current, month]);
            }
        },
        [periods, monthsNamesInHebrew]
    );

    useEffect(() => {
        if (periods?.length > 2) {
            setPeriods([]);
            setPossibleEnd("");
        }
    }, [periods]);

    return (
        <div className="period-step">
            {showTitle ? (
                <div className="calculator-wizard-title-container">
                    <div className="calculator-wizard-title">שלום {me?.firstName ?? "לך"}, כיף שבאת :)</div>
                    <div className="calculator-wizard-title">מהי תקופת הזמן הרלוונטית לתוכנית הרווחה שלכם?</div>
                </div>
            ) : null}
            <div class="period-step-toolbar">
                <div className="period-step-toolbar-title">תקופת התקציב</div>
                <YearPicker currentYear={currentYear} setCurrentYear={setCurrentYear} />
            </div>
            <div className="period-step-months-container">
                {monthsNamesInHebrew.map((month, index) => {
                    const monthBudget = budgetBetweenMonths?.find(
                        budget => moment(budget.planedMonth).format("MM/YYYY") === month.format("MM/YYYY")
                    );

                    return monthBudget ? (
                        <MonthBudgetBox
                            key={monthBudget.planId}
                            monthBudget={monthBudget}
                            month={month}
                            onEdit={planId => {
                                history.push(`${HappinessCalculatorRoutes.PLAN}/${planId}`);
                                onClose();
                            }}
                            onDelete={async planId => {
                                const {error} = await HttpClient.safeDelete(`/api/budgetPlan/${planId}`);

                                if (error) {
                                    message.error("התוכנית לא נמחקה - אנא נסו שנית מאוחר יותר");
                                } else {
                                    setBudgetBetweenMonths(currentBudgetBetweenMonths =>
                                        currentBudgetBetweenMonths.filter(budgetMonth => {
                                            if (budgetMonth.planId !== planId) return true;
                                        })
                                    );
                                    message.success("התוכנית נמחקה בהצלחה");
                                }

                                onClose();
                            }}
                        />
                    ) : (
                        <MonthBox
                            key={month}
                            month={month}
                            selectedIndex={indexesDistance?.indexOf(month)}
                            periods={periods}
                            onMouseEnter={onMouseEnter}
                            indexesDistanceOfPossibleEnd={indexesDistanceOfPossibleEnd}
                            onClick={() => onSelectMonth(month)}
                        />
                    );
                })}
            </div>
        </div>
    );
};
