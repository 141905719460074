import {StyledButton} from "../components/StyledButton";
import {LoadingOutlined} from "@ant-design/icons";
import {GoogleIcon} from "../icons";
import React from "react";

export const GoogleButton = ({loading, disabled, onClick, style, children}) => (
    <StyledButton
        style={{
            width: 250,
            border: "1px solid var(--secondary-color)",
            color: "var(--secondary-color)",
            ...style
        }}
        icon={
            loading ? (
                <LoadingOutlined style={{fontSize: 20}} />
            ) : (
                <GoogleIcon style={{width: 25, height: 25, marginRight: 10}} />
            )
        }
        disabled={disabled}
        onClick={onClick}>
        {children}
    </StyledButton>
);
