import React from "react";
import "./ticker-notification.css";
import {NOOP} from "../../utils/NOOP.jsx";

export const TickerNotification = ({contentString, onClose, backgroundColor}) => {
    return (
        <div className="ticker-notification-container">
            <div className="ticker-notification-full-background" {...(backgroundColor && {style: {backgroundColor}})} />

            <div className="ticker-notification">
                <div className="ticker-notification-content">{contentString}</div>

                {onClose !== NOOP ? <button onClick={onClose}>X</button> : <></>}
            </div>
        </div>
    );
};
