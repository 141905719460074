import React, {useCallback, useContext, useMemo, useState} from "react";
import {isEmpty, truncate} from "lodash";
import classnames from "classnames";
import {Card, Dropdown, message, Popover} from "antd";
import {TagsOutlined} from "@ant-design/icons";
import {kosherTypesToHebrew} from "../ProductsFilterModal/consts.jsx";
import {
    getDefaultPriceTypeArray,
    getDefaultTypeToShow,
    getProductMinOrderText,
    isHTML,
    typeToHebrewName
} from "../MarketplaceUtils.jsx";
import {calculateProductBundlePrice} from "../ProductBundleDetailsPage/utils.jsx";
import {ProgressiveImageLoader} from "../../components/index.jsx";
import {extractTextFromHtml} from "../../center/utils/DomUtil.jsx";
import {AutoDirectionProvider, isRTL} from "../../AutoDirectionProvider.jsx";
import {AppContext} from "../../AppContext.jsx";
import {kosherOptions} from "../../admin/upload/AdminUploadUtils.jsx";
import cover_placeholder from "../../images/cover_placeholder.jpg";
import {HttpClient} from "../../http/HttpClient.jsx";
import {Like} from "../../components/Like/index.jsx";
import {NOOP} from "../../utils/NOOP.jsx";
import {reduceVAT} from "../../utils/calculationUtils.jsx";
import "./marketplace-category-item-card.css";

const StopSigns = [".", ",", ";", "\n"];
const MaxCharDistance = 15;

export const MarketplaceCategoryItem = ({
    content,
    onContentClick,
    onNewTab,
    actionStyle,
    searchText,
    showLikeButton = true,
    log = NOOP
}) => {
    const {me, company, userLikedItems, setUserLikedItems} = useContext(AppContext);
    const [isLoadingLike, setIsLoadingLike] = useState(false);

    const calculatePrice = useMemo(
        () =>
            content.service?.pricingTable?.byModular?.subProducts?.reduce((acc, subProduct) => {
                const foundProviderSubProduct = content.provider.subProducts.find(({id}) => id === subProduct?.id);
                if (foundProviderSubProduct?.isActive && subProduct?.isActive)
                    return acc + foundProviderSubProduct.price * (subProduct?.defaultAmount ?? 0);
                return acc;
            }, 0),
        [content?.provider?.subProducts, content.service?.pricingTable?.byModular?.subProducts]
    );

    const fromPrice = useMemo(() => {
        if (content?.service?.isProductBundle) {
            return calculateProductBundlePrice(content?.service.productBundleProducts);
        }

        const defaultPriceTypeArray = getDefaultPriceTypeArray(content?.service) || [];
        let minPrice = null;

        defaultPriceTypeArray?.forEach(row => {
            const rowPrice = parseFloat(row.price ?? row.redeemableValue);
            if (!minPrice || minPrice > rowPrice) {
                minPrice = rowPrice;
            }
        });

        return minPrice;
    }, [content?.service]);

    const focusedDescription = useMemo(() => {
        if (!content?.service?.description) {
            return null;
        }

        const description = isHTML(content.service.description)
            ? extractTextFromHtml(content.service.description)
            : content.service.description;

        if (!searchText) {
            return description;
        }

        const searchIndex = description.indexOf(searchText);

        if (searchIndex === -1) {
            return description;
        }

        const startOfSearchedWordIndex = Math.max([...description].slice(0, searchIndex).lastIndexOf(" "), 0);

        const textBefore = description.slice(0, startOfSearchedWordIndex);
        const stopSignBefore = Math.max(
            ...StopSigns.map(sign => textBefore.lastIndexOf(sign)).filter(index => index > -1)
        );

        const getToStartOfWord = text => text.slice([...text].findIndex(c => ![...StopSigns, " "].includes(c)));

        if (searchIndex - MaxCharDistance < stopSignBefore) {
            return getToStartOfWord(description.slice(stopSignBefore));
        }

        const closetStartOfWordBefore = Math.max([...textBefore.slice(-MaxCharDistance)].reverse().lastIndexOf(" "), 0);

        return getToStartOfWord(description.slice(startOfSearchedWordIndex - closetStartOfWordBefore));
    }, [searchText, content?.service?.description]);

    const highlightedText = useMemo(() => {
        if (!focusedDescription) {
            return "";
        }

        if (!searchText) {
            return focusedDescription;
        }

        let index;
        let startIndex = 0;
        let numberOfModification = 0;

        let modifiedText = [...focusedDescription];

        while ((index = focusedDescription.indexOf(searchText, startIndex)) > -1) {
            const modifiedTextIndex = index - numberOfModification * (searchText.length - 1);
            const textToHighlight = modifiedText.slice(modifiedTextIndex, modifiedTextIndex + searchText.length);
            const highlightedElement = (
                <span className="marketplace-product-card-highlighted-search-text">{textToHighlight}</span>
            );
            modifiedText.splice(modifiedTextIndex, searchText.length, highlightedElement);
            startIndex = index + searchText.length;
            numberOfModification++;
        }

        return modifiedText;
    }, [searchText, focusedDescription]);

    const likeTargetHandler = useCallback(async productId => {
        const newLike = await HttpClient.safePost(`/api/like/likeItem/product?targetId=${productId}`);

        if (newLike.error) {
            message.error("לא ניתן לסמן לייק למוצר זה");
            return;
        }

        setUserLikedItems(currentLikeImages => [...currentLikeImages, newLike]);
    }, []);

    const unlikeTargetHandler = useCallback(async productId => {
        const {error} = await HttpClient.safeDelete(`/api/like/unlikeItem/${productId}`);

        if (error) {
            message.error("לא ניתן להוריד לייק למוצר זה");
            return;
        }

        setUserLikedItems(current => current.filter(like => like.targetId !== productId));
    }, []);

    const isLikeProduct = useMemo(
        () => !!userLikedItems?.find(item => item.targetId === content?.service?.productId),
        [userLikedItems, content?.service?.productId]
    );

    const calculatedPriceBeforeVAT = useCallback((priceBeforeDiscount, companyOrdersDiscount) => {
        const priceAfterDiscount = companyOrdersDiscount
            ? priceBeforeDiscount - Math.floor(priceBeforeDiscount * (companyOrdersDiscount / 100))
            : priceBeforeDiscount;
        return reduceVAT(priceAfterDiscount);
    }, []);

    const ProductPriceHeader = useCallback(
        ({companyOrdersDiscount, priceBeforeDiscount, chosenPriceType}) => {
            const priceAfterDiscount = companyOrdersDiscount
                ? priceBeforeDiscount - Math.floor(priceBeforeDiscount * (companyOrdersDiscount / 100))
                : priceBeforeDiscount;

            return (
                <>
                    <span
                        className={classnames("marketplace-product-card-price-title", {
                            "responsive-font-size": companyOrdersDiscount && priceAfterDiscount > 999
                        })}>
                        {chosenPriceType === "byModular" ? (
                            <>
                                <span>חבילה מומלצת</span>
                                <span
                                    className={classnames({
                                        "line-through":
                                            companyOrdersDiscount && priceAfterDiscount !== priceBeforeDiscount
                                    })}>
                                    {calculatePrice?.toLocaleString(undefined, {maximumFractionDigits: 2})}₪
                                </span>
                            </>
                        ) : (
                            <>
                                <span>החל מ-</span>
                                <span
                                    className={classnames({
                                        "line-through":
                                            companyOrdersDiscount && priceAfterDiscount !== priceBeforeDiscount
                                    })}>
                                    {fromPrice?.toLocaleString(undefined, {maximumFractionDigits: 2})}₪
                                </span>
                            </>
                        )}
                        {companyOrdersDiscount && priceAfterDiscount !== priceBeforeDiscount ? (
                            <span className="marketplace-category-item-details-price-title-discount">
                                {priceAfterDiscount?.toLocaleString(undefined, {maximumFractionDigits: 2})}₪
                            </span>
                        ) : null}
                    </span>
                </>
            );
        },
        [company?.ordersDiscount, calculatePrice, fromPrice]
    );

    const ProductKosherTag = useMemo(() => {
        if (content.service?.isProductBundle) {
            if (content.service.productKosherType) {
                return (
                    <div>
                        <span>{kosherTypesToHebrew[content.service.productKosherType]}</span>
                        <span>{" ✓ "}</span>
                    </div>
                );
            } else {
                return null;
            }
        }

        return (
            <>
                {content?.provider?.kosher === kosherOptions[0] || content?.provider?.kosher === kosherOptions[2] ? (
                    <div>
                        <span>
                            {content?.service?.productKosherType
                                ? kosherTypesToHebrew[content?.service?.productKosherType]
                                : "בית עסק כשר"}
                        </span>
                        <span>{" ✓ "}</span>
                    </div>
                ) : null}
            </>
        );
    }, [content?.provider?.kosher, content?.service?.productKosherType]);

    const ProductMinOrderTag = useMemo(() => {
        return (
            <>
                {getProductMinOrderText(content) ? (
                    <div
                        style={{
                            direction: "rtl",
                            display: "flex",
                            alignItems: "center",
                            gap: "0.3rem"
                        }}>
                        <TagsOutlined />
                        {getProductMinOrderText(content)}
                    </div>
                ) : null}
            </>
        );
    }, [content]);

    const ProviderBrandName = useMemo(() => {
        const brandName = content?.service?.isProductBundle
            ? content?.service?.brandName
            : content?.service?.brandName || content?.service?.engBrandName || content?.provider?.businessName;

        return truncate(brandName, {
            separator: " ",
            length: 55
        });
    }, [content]);

    return (
        <Dropdown
            key={"dropdown_" + content?.service?.productId}
            menu={{
                items: [
                    {
                        label: "פתח בכרטיסיה חדשה",
                        key: "newTab",
                        onClick: () => onNewTab(content, true)
                    }
                ]
            }}
            trigger={["contextMenu"]}>
            <Card
                key={"card_" + content?.service?.productId}
                bordered={true}
                hoverable={true}
                onClick={() => onContentClick(content)}
                bodyStyle={{paddingTop: 10, borderRadius: "5px"}}
                className="marketplace-product-card"
                cover={
                    <div className={classnames("marketplace-product-card-cover", "like-actions-object")}>
                        <div className="marketplace-product-card-image-container">
                            <div className="marketplace-product-card-fast-delivery-container">
                                {me?.features?.fast_delivery &&
                                content?.service?.deliveryOptions?.deliveryCompanies?.length ? (
                                    <span className="marketplace-product-card-fast-delivery-option">
                                        משלוח מהיר במיוחד
                                    </span>
                                ) : null}

                                {content?.service?.isProductBundle ? (
                                    <span className="marketplace-product-card-fast-delivery-option">חבילה משולבת</span>
                                ) : null}
                            </div>
                            <div className="marketplace-product-card-smart-image-container">
                                <ProgressiveImageLoader
                                    // width={440}
                                    height={220}
                                    maxWidth={440}
                                    className={"marketplace-product-card-smart-image"}
                                    src={
                                        !isEmpty(content?.service?.portfolioImages)
                                            ? content?.service?.portfolioImages?.[0]?.imageUrl
                                            : cover_placeholder
                                    }
                                />
                            </div>

                            {showLikeButton ? (
                                <Like
                                    checked={isLikeProduct}
                                    disabled={isLoadingLike}
                                    onChange={async checked => {
                                        log("like button clicked", {like: checked});

                                        setIsLoadingLike(true);
                                        if (checked) {
                                            await likeTargetHandler(content?.service?.productId);
                                        } else {
                                            await unlikeTargetHandler(content?.service?.productId);
                                        }
                                        setIsLoadingLike(false);
                                    }}
                                />
                            ) : null}
                        </div>
                        <AutoDirectionProvider text={"עברית"} style={{width: "100%"}}>
                            <div className="marketplace-product-details-container">
                                <span className="product-name" id="productName">
                                    {isRTL(content?.service?.productName)
                                        ? truncate(content?.service?.productName, {separator: " ", length: 50})
                                        : truncate(content?.service?.productName, {separator: " ", length: 90})}
                                </span>
                                <span className="provider-brand-name">{ProviderBrandName}</span>
                                <span className="content">{highlightedText}</span>
                            </div>
                        </AutoDirectionProvider>
                        <div className="product-tags-container">
                            {ProductMinOrderTag}
                            {ProductKosherTag}
                        </div>
                    </div>
                }
                actions={[
                    <div className="marketplace-product-card-bottom-container" style={{...actionStyle}}>
                        <Popover
                            className="marketplace-product-card-bottom-popover"
                            content={
                                <div className="not-include-vat-text">
                                    <span>
                                        ₪
                                        {calculatedPriceBeforeVAT(fromPrice || calculatePrice, company?.ordersDiscount)}
                                    </span>
                                    <span>(לא כולל מעמ)</span>
                                </div>
                            }>
                            <ProductPriceHeader
                                chosenPriceType={content?.service?.chosenPriceType}
                                priceBeforeDiscount={fromPrice || calculatePrice}
                                companyOrdersDiscount={company?.ordersDiscount}
                            />

                            <span className="price-description">
                                {typeToHebrewName(getDefaultTypeToShow(content?.service))} / (כולל מע״מ)
                            </span>
                        </Popover>
                    </div>
                ]}
            />
        </Dropdown>
    );
};
