export const declineOrderOptions = {
    cannotDeliverOnThisDate: "cannotDeliverOnThisDate",
    doesNotDeliverToThisAddress: "doesNotDeliverToThisAddress",
    missingProductInOrder: "missingProductInOrder",
    missingCustomerDetails: "missingCustomerDetails",
    other: "Other"
};

export const declineOrderOptionsToText = {
    [declineOrderOptions.cannotDeliverOnThisDate]: "לא יכול לספק בתאריך הזה",
    [declineOrderOptions.doesNotDeliverToThisAddress]: "לא מגיע לכתובת שהוזנה",
    [declineOrderOptions.missingProductInOrder]: "חסר מוצר מתוך ההזמנה",
    [declineOrderOptions.missingCustomerDetails]: "חסרים פרטים של הלקוח",
    [declineOrderOptions.other]: "אחר"
};
