import React, {useCallback, useEffect, useMemo, useState} from "react";
import {groupBy, sumBy, uniqBy} from "lodash";
import moment from "moment";
import {AdminOrdersPaymentTypeToName, getOrderPaymentType, TransactionServiceIdToName} from "../../orders/consts";
import {numberWithCommas} from "../../utils/StringFormat";
import {useRequest} from "../../utils/hooks";
import {reduceVAT} from "../../utils/calculationUtils";
import {getPercentageItemProperties, StatisticsLabel} from "../components/StatisticsLabel";
import {OrdersStatisticsLabelFields, triggerParentLabels} from "./consts";

export const OrdersStatisticsLabel = ({orders = [], startTime = null, endTime = null, companiesStats}) => {
    const [shouldFetchCompaniesStats, setShouldFetchCompaniesStats] = useState(false);
    const [allCompaniesStatsUntilStartTime, loadingAllCompaniesStatsUntilStartTime] = useRequest(
        `/admin/api/statistics/getAllCompaniesOrderStatistics?endTime=${startTime}`,
        "get",
        null,
        [startTime],
        !!startTime && shouldFetchCompaniesStats
    );
    const [allCompaniesStatsUntilEndTime, loadingAllCompaniesStatsUntilEndTime] = useRequest(
        `/admin/api/statistics/getAllCompaniesOrderStatistics?endTime=${endTime}`,
        "get",
        null,
        [endTime],
        !!endTime && shouldFetchCompaniesStats
    );

    const getOrderCompletePrice = useCallback(
        order => (Number(order.offeredPrice) || 0) + (order.deliveryPrice - 0 || 0) + (order.tip || 0),
        []
    );

    const totalOrdersPrice = useMemo(() => sumBy(orders, getOrderCompletePrice), [orders, getOrderCompletePrice]);

    const uniqOrders = useMemo(
        () =>
            Object.values(
                groupBy(
                    orders,
                    order => `${order.companyId}${order.address}${moment(order.dtstart).format("DD.MM.YY")}`
                )
            ).map(groupedOrders => ({
                ...groupedOrders[0],
                offeredPrice: sumBy(groupedOrders, order => order.offeredPrice - 0 || 0),
                couponDiscountAmount: sumBy(groupedOrders, order => order.couponDiscountAmount - 0 || 0),
                companyDiscountAmount: sumBy(groupedOrders, order => order.companyDiscountAmount - 0 || 0)
            })),
        [orders]
    );

    const statistics = useMemo(() => {
        const orderedCompanies = uniqBy(orders, order => order.companyId);
        const clientsNumber = orderedCompanies.length;

        const returningClients = companiesStats
            ? Object.values(companiesStats).filter(({numberOfOrders}) => numberOfOrders > 1)
            : 0;
        const totalOrdersPriceWithoutVat = parseFloat(
            sumBy(orders, order => reduceVAT(getOrderCompletePrice(order), order.vat, false))?.toFixed(0)
        );

        const clientsThatOrderedBeforeAndAfterStartTime = orderedCompanies.filter(
            ({companyId}) => allCompaniesStatsUntilStartTime?.[companyId]
        ).length;
        const incomeFormReturningCompaniesWithoutVat = parseFloat(
            sumBy(
                orders.filter(({companyId}) => allCompaniesStatsUntilStartTime?.[companyId]),
                order => reduceVAT(getOrderCompletePrice(order), order.vat, false)
            )?.toFixed(0)
        );

        return [
            {
                name: OrdersStatisticsLabelFields.totalWithoutVAT,
                value: totalOrdersPriceWithoutVat,
                suffix: "₪",
                children: [
                    ...Object.keys(TransactionServiceIdToName).map(serviceId => {
                        const serviceTotalPaymentWithoutVat = parseFloat(
                            sumBy(
                                orders.filter(order => order.serviceId === serviceId),
                                order => reduceVAT(order.offeredPrice, order.vat, false)
                            )?.toFixed(0)
                        );

                        return {
                            name: TransactionServiceIdToName[serviceId],
                            ...getPercentageItemProperties(serviceTotalPaymentWithoutVat, totalOrdersPriceWithoutVat),
                            suffix: "₪",
                            removeIfEmpty: true
                        };
                    }),
                    {
                        name: "משלוחים",
                        ...getPercentageItemProperties(
                            parseFloat(
                                sumBy(orders, order => reduceVAT(order.deliveryPrice, order.vat, false))?.toFixed(0)
                            ),
                            totalOrdersPriceWithoutVat
                        ),
                        suffix: "₪",
                        removeIfEmpty: true
                    },
                    {
                        name: "טיפים",
                        ...getPercentageItemProperties(
                            parseFloat(sumBy(orders, order => reduceVAT(order.tip, order.vat, false))?.toFixed(0)),
                            totalOrdersPriceWithoutVat
                        ),
                        suffix: "₪",
                        removeIfEmpty: true
                    },
                    {
                        name: "הנחות",
                        value:
                            sumBy(uniqOrders, order => order.couponDiscountAmount + order.companyDiscountAmount) * -1,
                        suffix: "₪",
                        removeIfEmpty: true
                    }
                ]
            },
            {
                name: OrdersStatisticsLabelFields.customersOrdered,
                value: clientsNumber,
                children: [
                    {
                        name: "הכנסה מלקוחות חדשים",
                        ...getPercentageItemProperties(
                            totalOrdersPriceWithoutVat - incomeFormReturningCompaniesWithoutVat,
                            totalOrdersPriceWithoutVat
                        ),
                        suffix: "₪",
                        removeIfEmpty: true,
                        loading: loadingAllCompaniesStatsUntilEndTime
                    },
                    {
                        name: "הכנסה מלקוחות חוזרים",
                        ...getPercentageItemProperties(
                            incomeFormReturningCompaniesWithoutVat,
                            totalOrdersPriceWithoutVat
                        ),
                        suffix: "₪",
                        removeIfEmpty: true,
                        loading: loadingAllCompaniesStatsUntilEndTime
                    },
                    {
                        name: "divider"
                    },
                    {
                        name: "לקוחות חוזרים - בטווח",
                        ...getPercentageItemProperties(
                            Object.values(groupBy(uniqOrders, order => order.companyId)).filter(
                                companyUniqOrders => companyUniqOrders.length > 1
                            ).length,
                            clientsNumber || 1
                        )
                    },
                    {
                        name: "לקוחות חוזרים - מסך הלקוחות בטווח",
                        ...getPercentageItemProperties(returningClients.length, clientsNumber || 1),
                        loading: !companiesStats
                    },
                    {
                        name: "אחוז הלקוחות שחזרו לא כולל הטווח",
                        ...getPercentageItemProperties(
                            clientsThatOrderedBeforeAndAfterStartTime,
                            Object.keys(allCompaniesStatsUntilStartTime ?? {})?.length
                        ),
                        loading: loadingAllCompaniesStatsUntilStartTime
                    },
                    {
                        name: "אחוז הלקוחות שחזרו כולל הטווח",
                        ...getPercentageItemProperties(
                            returningClients.length,
                            Object.keys(allCompaniesStatsUntilEndTime ?? {})?.length
                        ),
                        loading: loadingAllCompaniesStatsUntilEndTime
                    }
                ]
            },
            {
                name: OrdersStatisticsLabelFields.paymentTypes,
                children: Object.values(AdminOrdersPaymentTypeToName).map(paymentType => ({
                    name: paymentType,
                    suffix: "₪",
                    removeIfEmpty: true,
                    ...getPercentageItemProperties(
                        parseFloat(
                            sumBy(
                                orders
                                    .filter(order => getOrderPaymentType(order) === paymentType)
                                    .map(order => reduceVAT(getOrderCompletePrice(order), order.vat, false))
                            )?.toFixed(0)
                        ),
                        totalOrdersPriceWithoutVat
                    )
                }))
            },
            {
                name: OrdersStatisticsLabelFields.uniqueTransactions,
                value: uniqOrders.length,
                children: [
                    {
                        name: "עסקאות mini",
                        ...getPercentageItemProperties(
                            uniqOrders.filter(order => order.offeredPrice <= 1500).length,
                            uniqOrders.length
                        ),
                        removeIfEmpty: true
                    },
                    {
                        name: "עסקאות Medium",
                        ...getPercentageItemProperties(
                            uniqOrders.filter(order => order.offeredPrice > 1500 && order.offeredPrice <= 15000).length,
                            uniqOrders.length
                        ),
                        removeIfEmpty: true
                    },
                    {
                        name: "עסקאות MEGA",
                        ...getPercentageItemProperties(
                            uniqOrders.filter(order => order.offeredPrice > 15000).length,
                            uniqOrders.length
                        ),
                        removeIfEmpty: true
                    },
                    {
                        name: "divider"
                    },
                    {
                        name: "עסקה ממוצעת",
                        value:
                            (
                                parseFloat(
                                    sumBy(
                                        uniqOrders.filter(({offeredPrice}) => offeredPrice),
                                        order => reduceVAT(order.offeredPrice - 0, order.vat, false)
                                    )?.toFixed(0)
                                ) / (uniqOrders.filter(({offeredPrice}) => offeredPrice).length || 1)
                            ).toFixed(0) - 0,
                        suffix: "₪"
                    },
                    {
                        name: "עמלה ממוצעת",
                        value:
                            (
                                sumBy(
                                    orders.filter(({commission}) => commission),
                                    order => order.commission - 0
                                ) / (orders.filter(({commission}) => commission).length || 1)
                            ).toFixed(1) - 0,
                        prefix: "%"
                    }
                ]
            },
            {
                name: OrdersStatisticsLabelFields.transactions,
                value: orders.length,
                children: Object.keys(TransactionServiceIdToName).map(serviceId => ({
                    name: TransactionServiceIdToName[serviceId],
                    ...getPercentageItemProperties(
                        orders.filter(order => order.serviceId === serviceId).length,
                        orders.length
                    ),
                    removeIfEmpty: true
                }))
            }
        ];
    }, [
        orders,
        uniqOrders,
        companiesStats,
        allCompaniesStatsUntilStartTime,
        loadingAllCompaniesStatsUntilStartTime,
        allCompaniesStatsUntilEndTime,
        loadingAllCompaniesStatsUntilEndTime,
        getOrderCompletePrice
    ]);

    useEffect(() => {
        setShouldFetchCompaniesStats(false);
    }, [startTime, endTime]);

    return (
        <StatisticsLabel
            triggerParentLabels={triggerParentLabels}
            onChangeTriggerPrentLabel={() => {
                setShouldFetchCompaniesStats(true);
            }}
            statistics={statistics}
            title={
                <span>
                    סה״כ הזמנות לתקופה:
                    <span>{numberWithCommas(totalOrdersPrice?.toFixed(0))}</span>₪
                </span>
            }
        />
    );
};
