import React from "react";
import classNames from "classnames";
import {CloseOutlined, LockFilled} from "@ant-design/icons";
import {NOOP} from "../../../utils/NOOP";
import {Tag} from "antd";
import {SquareButton} from "../../../components";

export const FilterSearchOptionsList = ({filterOptions = [], onLockedFiltersChange, lockedFilters = [], tagsToShow = [], removeTag}) => {

    return (
        <div className="marketplace-category-filter-option-list">
            {tagsToShow?.map(tag => (
                <Tag
                    key={`tag-${tag.tagId}`}
                    className="admin-search-tags-key-word-tag"
                    closable
                    onClose={() => removeTag(tag)}>
                    {tag.name}
                </Tag>
            ))}
            {filterOptions?.map(({id, title, onClick, filter}) => {
                const isLocked = lockedFilters.includes(id);
                return (
                    <SquareButton
                        key={id}
                        onClick={!onLockedFiltersChange ? onClick : NOOP}
                        className={classNames({isLocked})}>
                        <div className="marketplace-category-filter-option-list-icons-container">
                            {onLockedFiltersChange && (!id?.includes("searchText") || isLocked) ? (
                                <LockFilled onClick={() => onLockedFiltersChange({id, filter}, isLocked)} />
                            ) : null}
                            <CloseOutlined
                                onClick={
                                    onLockedFiltersChange
                                        ? () => {
                                              onClick();
                                              onLockedFiltersChange({id}, true);
                                          }
                                        : NOOP
                                }
                            />
                        </div>
                        {title}
                    </SquareButton>
                );
            })}
        </div>
    );
};
