import mixpanel from "mixpanel-browser";
import {GoogleAnalytics} from "./GoogleAnalytics";

const VITE_MIXPANEL_PROJECT =
    process.env.REACT_APP_MIXPANEL_PROJECT ??
    import.meta.env.VITE_MIXPANEL_PROJECT ??
    "13056c4d4c2b3502a7bf5cbf4c0efc85";

console.log(VITE_MIXPANEL_PROJECT);
const logger = mixpanel.init(VITE_MIXPANEL_PROJECT, {}, "well-b user action logger");

export const initialize = user => {
    logger.identify(user.userId);
    const isAdminLogin = window.localStorage.getItem("isAdminLogin") ?? false;

    const userProperty = {
        userType: user.type,
        companyName: user.companyName,
        email: user.email,
        featureTests: user.featureTests,
        ...(isAdminLogin
            ? {
                  isAdminLogin: true
              }
            : {})
    };

    logger.people.set(userProperty);
    logger.register(userProperty);
};

export const getLogger =
    (defaultProperties, componentName) =>
    (eventName, properties = {}) => {
        logger.track(eventName, {...defaultProperties, ...properties, componentName});
    };

export const writeProductClickedLog = (logger, product, serviceId) => {
    logger("Content clicked", {productName: product.service.productName, productId: product.service.productId});

    GoogleAnalytics.ecommerceEvent("select_content", product, serviceId, {
        content_type: "product"
    });
};

export default logger;
