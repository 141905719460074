import React, {useContext, useEffect, useState} from "react";
import {ConfigProvider, Form, Modal, Select, Tooltip} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import PageTitleHeader from "../components/PageTitle";
import {AutoDirectionProvider} from "../AutoDirectionProvider";
import {StyledButton} from "../components";
import {Sectors, SectorsNames} from "../consts.js";
import {AppContext} from "../AppContext";
import {FormInputV2} from "../components/form";
import {AutoCompleteAddressFormInput} from "../utils/AutoCompleteAddress";
import "./pre-booking-update-company-details-modal.css";

export const UpdateCompanyDetailsModal = ({
    visible,
    onSave,
    onClose,
    title,
    saveButtonText = "שמירה",
    contactSection = {},
    initFormValues
}) => {
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState({});
    const {company} = useContext(AppContext);

    useEffect(() => {
        if (initFormValues) {
            setFormValues(currentFormValues => ({
                ...currentFormValues,
                ...initFormValues
            }));
        }
    }, [initFormValues]);

    useEffect(() => {
        if (company) {
            setFormValues(currentFormValues => ({
                ...currentFormValues,
                name: company.name,
                businessNumber: company.businessNumber,
                address: company.address,
                addressId: company.addressId ?? null,
                floor: company.floor,
                sector: company.sector
            }));
        }
    }, [company]);

    return (
        <Modal
            centered={true}
            width={501}
            className="wb-modal-radius pre-booking-update-company-details-modal"
            style={{borderRadius: "5px"}}
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            maskClosable={false}
            footer={null}
            title={null}
            closable={true}>
            <ConfigProvider direction="rtl">
                <div className="update-company-details-modal">
                    <PageTitleHeader className="update-company-details-modal-title" showBack={false}>
                        {title}
                    </PageTitleHeader>
                    <div
                        style={{
                            marginTop: "-10px",
                            marginBottom: "20px",
                            alignSelf: "center",
                            backgroundColor: "rgba(131,152,166,0.2)",
                            height: "1px"
                        }}
                    />
                    <Form
                        form={form}
                        onFinish={() => {
                            onSave(formValues);
                        }}
                        fields={Object.keys(formValues).map(key => {
                            return {
                                name: [key],
                                value: formValues[key]
                            };
                        })}
                        onValuesChange={(_, allValues) => {
                            setFormValues(allValues);
                        }}>
                        <AutoDirectionProvider text={"עברית"} style={{display: "flex", flexDirection: "column"}}>
                            <div style={{display: "flex", flexDirection: "column", marginRight: "25px"}}>
                                <label
                                    style={{
                                        fontSize: 16,
                                        color: "var(--secondary-color)",
                                        textAlign: "right",
                                        marginBottom: "5px"
                                    }}>
                                    שם חברה
                                </label>
                                <Form.Item
                                    name="name"
                                    style={{width: "400px"}}
                                    rules={[
                                        {
                                            required: true,
                                            message: "שדה חובה"
                                        }
                                    ]}>
                                    <FormInputV2 />
                                </Form.Item>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", marginRight: "25px"}}>
                                <label
                                    style={{
                                        fontSize: 16,
                                        color: "var(--secondary-color)",
                                        textAlign: "right",
                                        marginBottom: "5px"
                                    }}>
                                    ח.פ
                                </label>
                                <Form.Item
                                    name="businessNumber"
                                    style={{width: "400px"}}
                                    rules={[
                                        {
                                            required: true,
                                            message: "שדה חובה"
                                        },
                                        {
                                            validator: (_, value) => {
                                                const regexPattern = /^(5[0-9]|[6-8][0-9]|59)\d{7}$/;

                                                if (value && !regexPattern.test(value)) {
                                                    return Promise.reject(
                                                        "מס' ח.פ אינו תקין: יש להזין מס' בן 9 ספרות שמתחיל ב50-59"
                                                    );
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            }
                                        }
                                    ]}>
                                    <FormInputV2 />
                                </Form.Item>
                            </div>

                            <div style={{display: "flex", flexDirection: "column", marginRight: "25px"}}>
                                <label
                                    style={{
                                        fontSize: 16,
                                        color: "var(--secondary-color)",
                                        textAlign: "right",
                                        marginBottom: "5px"
                                    }}>
                                    מספר עובדים בארגון
                                </label>
                                <Form.Item
                                    name="employeesCount"
                                    style={{width: "400px"}}
                                    rules={[
                                        {
                                            required: true,
                                            message: "שדה חובה"
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (value <= 0) {
                                                    return Promise.reject("אנחנו בטוחים שאתם לא עובדים לבד בחברה");
                                                }
                                                return Promise.resolve();
                                            }
                                        }
                                    ]}>
                                    <FormInputV2 type="number" />
                                </Form.Item>
                            </div>

                            <div style={{display: "flex"}}>
                                <div style={{display: "flex", flexDirection: "column", marginRight: "25px"}}>
                                    <label
                                        style={{
                                            fontSize: 16,
                                            color: "var(--secondary-color)",
                                            textAlign: "right",
                                            marginBottom: "5px"
                                        }}>
                                        כתובת משרד ראשי מלאה
                                        <Tooltip
                                            title={
                                                <p className="pre-booking-update-company-details-modal-tooltip-text">
                                                    כתובת מלאה כוללת רחוב, מס׳ בניין, עיר
                                                </p>
                                            }>
                                            <QuestionCircleOutlined className="pre-booking-update-company-details-modal-tooltip-icon" />
                                        </Tooltip>
                                    </label>
                                    <Form.Item
                                        name="address"
                                        valuePropName="address"
                                        rules={[
                                            {
                                                required: true,
                                                message: "שדה חובה",
                                                validator: (_, result) => {
                                                    if (!result) {
                                                        return Promise.reject("אנא בחרו כתובת מלאה, כולל מספר בניין");
                                                    }
                                                    return Promise.resolve();
                                                }
                                            }
                                        ]}
                                        style={{
                                            marginBottom: 15,
                                            width: "270px"
                                        }}>
                                        <AutoCompleteAddressFormInput
                                            allowSelectRegularText={true}
                                            onChange={(value, {place_id}) =>
                                                setFormValues(prev => ({
                                                    ...prev,
                                                    address: value,
                                                    addressId: place_id ?? null
                                                }))
                                            }
                                            editValue={formValues.address}
                                            placeholder="חיפוש כתובת"
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{display: "flex", flexDirection: "column", marginRight: "25px"}}>
                                    <label
                                        style={{
                                            fontSize: 16,
                                            color: "var(--secondary-color)",
                                            textAlign: "right",
                                            marginBottom: "5px"
                                        }}>
                                        קומה
                                    </label>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "שדה חובה"
                                            }
                                        ]}
                                        name="floor"
                                        style={{width: "100px"}}>
                                        <FormInputV2 />
                                    </Form.Item>
                                </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", marginRight: "25px"}}>
                                <label
                                    style={{
                                        fontSize: 16,
                                        color: "var(--secondary-color)",
                                        textAlign: "right",
                                        marginBottom: "5px"
                                    }}>
                                    תחום פעילות
                                </label>
                                <Form.Item
                                    name="sector"
                                    rules={[
                                        {
                                            required: true,
                                            message: "שדה חובה"
                                        }
                                    ]}
                                    style={{width: "400px"}}>
                                    <Select
                                        options={Object.values(Sectors).map(sector => ({
                                            value: sector,
                                            label: SectorsNames[sector]
                                        }))}
                                    />
                                </Form.Item>
                            </div>
                            <div className="pre-booking-update-company-details-modal-break-line">
                                <span>{contactSection?.title}</span>
                            </div>

                            {contactSection.fields?.map((field, index) => (
                                <div
                                    key={index}
                                    style={{display: "flex", flexDirection: "column", marginRight: "25px"}}>
                                    <label
                                        style={{
                                            fontSize: 16,
                                            color: "var(--secondary-color)",
                                            textAlign: "right",
                                            marginBottom: "5px"
                                        }}>
                                        {field?.label}
                                    </label>
                                    <Form.Item
                                        initialValue={field.initialValue}
                                        name={field.name}
                                        rules={field?.rules}
                                        style={{width: "400px"}}>
                                        <FormInputV2 type={field?.type ?? "text"} />
                                    </Form.Item>
                                </div>
                            ))}

                            <StyledButton className="update-company-details-modal-save-btn" htmlType="submit">
                                {saveButtonText}
                            </StyledButton>
                        </AutoDirectionProvider>
                    </Form>
                </div>
            </ConfigProvider>
        </Modal>
    );
};
