import isMobileDevice from "is-mobile";
import React from "react";
import {TimePicker} from "antd";
import moment from "moment";
import shortid from "shortid";
import {DownOutlined} from "@ant-design/icons";

const PICKER_HEIGHT = 350;

export const CustomTimePicker = ({
    placeholder,
    value,
    onChange,
    style,
    minuteStep,
    disabled,
    inputReadOnly,
    showNow
}) => {
    const uniqueId = shortid.generate();

    return isMobileDevice() ? (
        <div style={{position: "relative"}}>
            <input
                disabled={disabled}
                id={`wb-time-picker-${uniqueId}`}
                onChange={e => onChange(moment(e.target.value, "HH:mm"))}
                type="time"
                placeholder={placeholder}
                style={{
                    padding: "4px 11px",
                    backgroundColor: "#FCFDFE",
                    border: "1px solid #E8EDF5",
                    height: 50,
                    width: "100%",
                    borderRadius: 10,
                    fontSize: 16,
                    outline: "none",
                    WebkitAppearance: "none",
                    appearance: "none",
                    MozAppearance: "none",
                    ...style
                }}
                onClick={e => {
                    e.persist();
                    setTimeout(() => {
                        const target = document.getElementById(`wb-time-picker-${uniqueId}`);
                        if (target && target.getBoundingClientRect) {
                            const rect = target.getBoundingClientRect();
                            const diff = window.screen.height - rect.y;
                            if (diff < PICKER_HEIGHT) {
                                window.scroll(window.screen.width / 2, rect.y + 60);
                            }
                        }
                    }, 100);
                }}
                step={minuteStep ? minuteStep * 60 : 60}
                value={value ? value.format("HH:mm") : void 0}
            />
            <DownOutlined
                style={{
                    position: "absolute",
                    right: 11,
                    top: 20,
                    fontSize: 12,
                    color: "rgba(0,0,0,0.25)"
                }}
            />
        </div>
    ) : (
        <TimePicker
            disabled={disabled}
            onSelect={onChange}
            placeholder={placeholder}
            inputReadOnly={inputReadOnly || true}
            showNow={showNow || true}
            format="HH:mm"
            style={{
                backgroundColor: "#FCFDFE",
                border: "1px solid #E8EDF5",
                height: 50,
                width: "100%",
                borderRadius: 10,
                fontSize: 16,
                ...style
            }}
            minuteStep={minuteStep || 1}
            value={value}
        />
    );
};
