import React, {useState} from "react";
import {Input, List, Modal} from "antd";
import {TrashIcon} from "../../icons";
import {SquareButton} from "../../components";

const AdminLocationsAddressesModal = ({visible, onClose, addressList, addAddress, removeAddress}) => {
    const [newAddressText, setNewAddressText] = useState();

    return (
        <Modal
            centered={true}
            width={500}
            className="admin-locations-addresses-modal simple-modal-container"
            style={{borderRadius: "5px"}}
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            footer={null}
            title={null}>
            <List
                dataSource={addressList}
                renderItem={item => (
                    <List.Item key={item}>
                        {item}
                        <TrashIcon onClick={() => removeAddress(item)} />
                    </List.Item>
                )}
            />
            <div>
                <Input onChange={({target}) => setNewAddressText(target.value)} />
                <SquareButton disabled={!newAddressText?.length} onClick={() => addAddress(newAddressText)}>
                    שמור
                </SquareButton>
            </div>
        </Modal>
    );
};

export default AdminLocationsAddressesModal;
