import React, {useEffect, useState} from "react";
import {Form} from "antd";
import {getInputComponent, renderInputLabel} from "./InputComponent";
import {SquareButton} from "./SquareButton";
import {NOOP} from "../utils/NOOP";
import "./FormModal/form-modal.css";
import classnames from "classnames";

export const DynamicForm = ({
    content,
    onSave,
    fields,
    disables = [],
    onUpdate = NOOP,
    showCancelButton = false,
    cancelButtonTitle = "ביטול",
    saveButtonTitle = "שמירה",
    onClose = NOOP,
    validationFunction = null,
    className
}) => {
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState({});

    useEffect(() => {
        if (content) {
            setFormValues(prev => ({
                ...prev,
                ...content
            }));
        }
    }, [content]);

    return (
        <Form
            className={className}
            form={form}
            onFinish={async values => {
                if (validationFunction === null || (await validationFunction(values))) {
                    onSave(values);
                    form.resetFields();
                    setFormValues({});
                }
            }}
            fields={Object.keys(formValues).map(key => {
                return {
                    name: [key],
                    value: formValues[key]
                };
            })}
            onValuesChange={(changedField, allValues) => {
                setFormValues(allValues);
                onUpdate(allValues, changedField);
            }}>
            <div className="form-modal">
                {fields
                    .map(input => (disables.includes(input.name) ? {...input, disabled: true} : input))
                    .map(input => (
                        <div key={input.name} className={classnames("form-modal-input-line", input.className)}>
                            {renderInputLabel(input)}
                            {getInputComponent(input, formValues)}
                        </div>
                    ))}

                <div className="form-modal-buttons-container">
                    <SquareButton className="form-modal-save-button" htmlType="submit">
                        {saveButtonTitle}
                    </SquareButton>

                    {showCancelButton ? (
                        <SquareButton className="form-modal-cancel-button" onClick={onClose}>
                            {cancelButtonTitle}
                        </SquareButton>
                    ) : null}
                </div>
            </div>
        </Form>
    );
};
