import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {AutoComplete, Button, Spin} from "antd";
import classNames from "classnames";
import {CloseOutlined} from "@ant-design/icons";
import {useDebounceState, useRequest} from "../../../utils/hooks";
import {SquareButton} from "../../../components";
import ProductsFiltersModal from "../../../marketplace/ProductsFilterModal";
import {isObjectWithEmptyFields} from "../../../utils/objectUtils";
import {AMOUNT_OF_SEARCH_RESULTS} from "../../../consts.js";
import {NOOP} from "../../../utils/NOOP";
import "./marketplace-search.css";
import {uniq} from "lodash";

export const ProductSearch = ({
    searchFilters,
    setSearchFilters = NOOP,
    advancedSearch = false,
    placeholder = null,
    actionText = "חיפוש"
}) => {
    const autocompleteRef = useRef(null);
    const [debouncedSearchText, setUnDebouncedSearchText, unDebouncedSearchText] = useDebounceState("", 200);
    const [extraFilters, setExtraFilters] = useState({});
    const [productFiltersModalVisible, setProductFiltersModalVisible] = useState(false);
    const [topTags] = useRequest("/api/searchTags/getTopSearchTags");

    const validateInput = search => {
        try {
            RegExp(search);
            return true;
        } catch {}
        return false;
    };

    useEffect(() => {
        setUnDebouncedSearchText(searchFilters?.searchText || "");
    }, [searchFilters]);

    const onSelect = useCallback(
        (value, option, action = null) => {
            const isValid = validateInput(value);
            if (!isValid) {
                return;
            }
            setUnDebouncedSearchText(value);
            const tagIds = option?.tags?.tagIds ?? [];
            setSearchFilters(currentFilterValues => {
                const newSearchFilters = {
                    ...currentFilterValues,
                    ...(tagIds?.length ? {searchTags: tagIds} : {}),
                    searchText: value
                };

                if (advancedSearch) {
                    newSearchFilters.searchTags = [];
                    if (action === "ADD" && value?.trim() !== "") {
                        const newExtraSearchTexts = [...(newSearchFilters?.extraSearchTexts || []), value?.trim()];
                        newSearchFilters.extraSearchTexts = uniq(newExtraSearchTexts);
                        setUnDebouncedSearchText("");
                        delete newSearchFilters.searchText;
                    }
                }

                if (value?.trim() === "") {
                    delete newSearchFilters.searchText;
                }

                return newSearchFilters;
            });
        },
        [setSearchFilters, advancedSearch]
    );

    const onSearchKeyDown = useCallback(
        e => {
            if (e.key === "Enter") onSelect(unDebouncedSearchText, null, "ADD");
        },
        [unDebouncedSearchText]
    );

    const clearSearchText = useCallback(() => {
        setSearchFilters(currentValues => ({...currentValues, searchText: null}));
    }, [setSearchFilters]);

    const [searchResults, loadingSearchResults] = useRequest(
        `/api/search/${debouncedSearchText?.toLowerCase()}?amount=${AMOUNT_OF_SEARCH_RESULTS}`,
        "GET",
        null,
        [],
        !!debouncedSearchText?.length
    );

    const onTagClicked = useCallback(
        (e, tags) => {
            onSelect(tags.name, {tags: tags}, "ADD");

            autocompleteRef?.current.blur();
        },
        [onSelect, autocompleteRef, autocompleteRef?.current]
    );

    const renderTitle = useCallback(title => <span className="marketplace-search-render-title">{title}</span>, []);

    const renderItem = useCallback(
        (title, type, link = null, isRTL = true) => {
            let directionClass = isRTL ? "marketplace-search-render-item-rtl" : "marketplace-search-render-item-ltr";
            let boldSearchTitle = title.replace(/ /g, "\u00A0").split("");

            if (debouncedSearchText) {
                const trimmedInput = debouncedSearchText.trim();
                const searchInputIndex = title.indexOf(trimmedInput);
                if (searchInputIndex >= 0) {
                    if (isRTL) {
                        boldSearchTitle.splice(searchInputIndex, trimmedInput.length, <b key="key">{trimmedInput}</b>);
                    } else {
                        boldSearchTitle = [
                            ...boldSearchTitle.slice(searchInputIndex + trimmedInput.length),
                            <b>
                                {boldSearchTitle[searchInputIndex + trimmedInput.length] === "\u00A0" ? "\u00A0" : null}
                                {trimmedInput}
                            </b>,
                            ...boldSearchTitle.slice(0, searchInputIndex)
                        ];
                    }
                }
            }

            if (debouncedSearchText[0].match(/[a-zA-Z]/)) {
                directionClass = "marketplace-search-render-item-ltr";
            }

            return {
                value: title,
                label: (
                    <div
                        onClick={e => {
                            onSelect(title, null, "ADD");
                        }}
                        className={classNames("marketplace-search-render-item", directionClass)}>
                        {boldSearchTitle}
                    </div>
                )
            };
        },
        [debouncedSearchText, history]
    );

    const mergedSearchTags = useMemo(() => {
        const searchTags = debouncedSearchText && searchResults?.searchTags ? searchResults?.searchTags : topTags;

        if (!searchTags?.length) {
            return null;
        }

        const mergedTags = searchTags.reduce((acc, tag) => {
            if (acc[tag.name]) {
                acc[tag.name].tagIds.push(tag.tagId);
            } else {
                acc[tag.name] = {tagIds: [tag.tagId], name: tag.name};
            }

            return acc;
        }, {});

        return mergedTags;
    }, [debouncedSearchText, searchResults, topTags]);

    const tagsOptionsPanel = useMemo(() => {
        if (!mergedSearchTags) {
            return null;
        }

        return (
            <>
                <div className="simple-modal-break-line" />
                <div className="marketplace-search-tags-panel">
                    {Object.values(mergedSearchTags).map(tag => (
                        <span key={tag.name} onClick={e => onTagClicked(e, tag)}>
                            {tag.name}
                        </span>
                    ))}
                </div>
            </>
        );
    }, [mergedSearchTags, onTagClicked]);

    const searchOptions = useMemo(() => {
        if (loadingSearchResults) {
            return [{label: <Spin className="marketplace-search-loading" />, options: []}];
        }

        const searchOptions = [];

        if (searchResults && debouncedSearchText) {
            const {providers, products, subCategories} = searchResults;

            if (products?.length) {
                searchOptions.push({
                    label: renderTitle("מוצרים"),
                    options: products.map(({productName, productId, providerId, category}) =>
                        renderItem(
                            productName,
                            "product",
                            `/dashboard/services/${category}/providersV2/${providerId}/${productId}`
                        )
                    )
                });
            }

            if (providers?.length) {
                searchOptions.push({
                    label: renderTitle("שמות שותפים"),
                    options: providers.map(({providerName}) => renderItem(providerName, "provider"))
                });
            }

            if (subCategories?.length) {
                searchOptions.push({
                    label: renderTitle("קרוסלות"),
                    options: subCategories.map(({serviceId, subCategory}) =>
                        renderItem(
                            subCategory,
                            "subCategory",
                            `/dashboard/services/${serviceId}/providersV2?subCategory=${subCategory}`,
                            false
                        )
                    )
                });
            }
        }

        if (tagsOptionsPanel) {
            const tagsOptions = {
                label: tagsOptionsPanel,
                options: []
            };

            searchOptions.push(tagsOptions);
        }

        return searchOptions;
    }, [searchResults, debouncedSearchText, tagsOptionsPanel, loadingSearchResults]);

    const filterValuesWithoutSearchAndTags = useMemo(() => {
        const {searchTags, searchText, ...restFilters} = searchFilters ?? {};
        return restFilters;
    }, [searchFilters]);

    const hasFilters = useMemo(
        () => isObjectWithEmptyFields(filterValuesWithoutSearchAndTags),
        [filterValuesWithoutSearchAndTags]
    );

    return (
        <div className="marketplace-search-container">
            <ProductsFiltersModal
                filters={searchFilters}
                setFilters={setSearchFilters}
                onClose={() => setProductFiltersModalVisible(false)}
                onCancel={() => {
                    setExtraFilters({});
                    setProductFiltersModalVisible(false);
                }}
                onSave={() => {
                    setProductFiltersModalVisible(false);

                    const relevantFilters = cleanFilters(extraFilters);
                    setSearchFilters(prev => ({
                        ...prev,
                        ...relevantFilters
                    }));
                }}
                visible={productFiltersModalVisible}
                onFiltersChange={setExtraFilters}
            />
            <AutoComplete
                className="marketplace-search-autocomplete"
                allowClear
                showSearch={true}
                ref={autocompleteRef}
                options={searchOptions}
                onSelect={onSelect}
                popupClassName="marketplace-search-dropdown-menu"
                showArrow={false}
                listHeight={600}
                dropdownMatchSelectWidth={400}
                placeholder={placeholder ?? "חפש מוצרים, שמות שותפים, קרוסלות ותגיות"}
                onClear={clearSearchText}
                onSearch={onSelect}
                onKeyDown={onSearchKeyDown}
                searchValue={unDebouncedSearchText}
                value={unDebouncedSearchText ?? searchFilters?.searchText}
            />
            <Button onClick={() => onSelect(unDebouncedSearchText, null, "ADD")} className="marketplace-search-button">
                {actionText ?? "חיפוש"}
            </Button>
            <SquareButton
                onClick={() => setProductFiltersModalVisible(true)}
                className={classNames("marketplace-search-filter-button", {
                    "filters-active": !hasFilters
                })}>
                <span>מסננים</span>

                {!hasFilters ? (
                    <div>
                        <CloseOutlined
                            className="marketplace-search-filter-close-button"
                            onClick={e => {
                                setSearchFilters({});
                                e.stopPropagation();
                            }}
                        />
                    </div>
                ) : null}
            </SquareButton>
        </div>
    );
};

function cleanFilters(filters) {
    const relevantFilters = Object.entries(filters).reduce((acc, [key, value]) => {
        if (Array.isArray(value) && !value.length) {
            return acc;
        }

        if (value === "" || value === null) {
            return acc;
        }

        acc[key] = value;
        return acc;
    }, {});

    return relevantFilters;
}
