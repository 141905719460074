import React from "react";
import {withRouter} from "react-router";
import {parse as qsParse, stringify as qsStringify} from "query-string";

export const replaceKeyValuePairsFromSearch = keyValuePairs => {
    if (location.search) {
        const queryObj = qsParse(location.search);
        Object.keys(keyValuePairs).forEach(key => {
            queryObj[key] = keyValuePairs[key];
        });
        return qsStringify(queryObj);
    }

    return location.search;
};

export const removeKeysFromSearch = keys => {
    if (location.search) {
        const queryObj = qsParse(location.search);
        keys.forEach(key => (queryObj[key] = void 0));
        return qsStringify(queryObj);
    }

    return location.search;
};

const AppHistoryAdapter = ({history}) => {
    const historyPush = history.push;
    history.push = pathOrObject => {
        if (typeof pathOrObject === "string") {
            let locationQueryObj = void 0;

            if (window.location.search) {
                locationQueryObj = qsParse(window.location.search);
                locationQueryObj.returnTo = void 0;
            }

            if (pathOrObject.indexOf("?") >= 0) {
                const parts = pathOrObject.split("?");
                locationQueryObj = locationQueryObj || {};
                const queryString = `?${parts[1]}`;
                const pathQueryObj = qsParse(queryString);
                Object.keys(pathQueryObj).forEach(key => {
                    locationQueryObj[key] = pathQueryObj[key];
                });
                pathOrObject = parts[0];
            }

            historyPush({
                pathname: pathOrObject,
                search: locationQueryObj ? `?${qsStringify(locationQueryObj)}` : void 0
            });
        } else {
            historyPush(pathOrObject);
        }
    };

    history.replaceWithoutReload = path => {
        window.history.replaceState(null, window.document.title, path);
    };

    return null;
};

export default withRouter(AppHistoryAdapter);
