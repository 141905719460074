import React from "react";
import {PageTitleLabel} from "../components/PageTitle";
import zoomLogo from "../images/zoom_meeting.png";
import {Link} from "react-router-dom";

export const ZoomDocs = () => {
    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div style={{display: "flex", flexDirection: "column", width: "100%", maxWidth: 500}}>
                <PageTitleLabel>Zoom Installation Guide</PageTitleLabel>
                <ul style={{display: "flex", flexDirection: "column", color: "#747F92"}}>
                    <li>
                        If you have an online event, click <img alt="zoom_logo" src={zoomLogo} height={24} width={32} />{" "}
                        within your event.
                    </li>
                    <li>Authorized your user for Well B app.</li>
                    <li>An online meeting link will be created for your own usage.</li>
                </ul>
                <PageTitleLabel>Zoom Usage</PageTitleLabel>
                <ul style={{display: "flex", flexDirection: "column", color: "#747F92"}}>
                    <li>
                        We use your Zoom account information to create online meeting links and only for this purpose.
                    </li>
                </ul>
                <PageTitleLabel>Zoom Uninstall Guide</PageTitleLabel>
                <ul style={{display: "flex", flexDirection: "column", color: "#747F92"}}>
                    <li>Login to your Zoom Account and navigate to the Zoom App Marketplace.</li>
                    <li>Click Manage {">"} Installed Apps or search for the Well B app.</li>
                    <li>Click the Well B app.</li>
                    <li>Click Uninstall.</li>
                </ul>
                <div style={{marginTop: 40}}>
                    For any further questions contact us <Link to="/contact">here</Link>.
                </div>
            </div>
        </div>
    );
};
