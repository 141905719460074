import React, {useCallback, useContext, useState} from "react";

import "./temp-code-verification.css";
import {FormInputV2} from "../../components/form/index.jsx";
import {StyledButton} from "../../components/index.jsx";
import {TempCodeContext} from "./TempCodeProvider.jsx";
import {StringBuilder} from "../../AppUtils.js";
import {HttpClient} from "../../http/HttpClient.jsx";
import {Form, Spin} from "antd";
import {TempCodeLoginSenderKind} from "./consts.js";
import {NOOP} from "../../utils/NOOP.jsx";

export const TempCodeVerification = ({onSuccess = NOOP}) => {
    const [tempCode, setTempCode] = useState();
    const {sendLoginTempCode, remainingTime, formValues} = useContext(TempCodeContext);
    const [hasTempCodeError, setHasTempCodeError] = useState(false);
    const [isLoadingLogin, setIsLoadingLogin] = useState(false);

    const onLogin = useCallback(async () => {
        setIsLoadingLogin(true);
        const response = await HttpClient.safePost("/api/tempcode/login", {
            email: formValues?.email,
            code: tempCode,
            sessionToken: formValues?.sessionToken
        });

        if (response?.error) {
            setHasTempCodeError(true);
            setIsLoadingLogin(false);
            return;
        }

        await onSuccess(response);
        setIsLoadingLogin(false);
    }, [formValues?.email, formValues?.sessionToken, tempCode, onSuccess]);

    return (
        <div className="temp-code-verification-login-container">
            <span className="temp-code-verification-title">{formValues?.email}</span>

            <div className="temp-code-verification-sub-title">
                <span>
                    {formValues?.type === TempCodeLoginSenderKind.phone
                        ? new StringBuilder("שלחנו הודעת SMS עם הקוד למספר").toString()
                        : new StringBuilder("הטלפון במערכת שגוי, הקוד נשלח לכתובת מייל המשתמש").toString()}
                </span>
                {formValues?.type === TempCodeLoginSenderKind.phone && <span>{formValues?.maskedPhone}</span>}
            </div>

            <div className="temp-code-verification-form">
                <div className="login-container-input-container">
                    <Form.Item
                        validateStatus={hasTempCodeError ? "error" : null}
                        hasFeedback
                        help={hasTempCodeError ? "הקוד שהוקלד שגוי, שננסה שוב?" : null}>
                        <FormInputV2 placeholder="יש להזין את הקוד" onChange={e => setTempCode(e.target.value)} />
                    </Form.Item>
                </div>

                <div>
                    <span className="temp-code-verification-deadline">הקוד תקף ל 5 דקות</span>
                    {remainingTime ? (
                        <div className="temp-code-verification-send-again">
                            {new StringBuilder("ניתן לשלוח שוב את הקוד בעוד").append(remainingTime).toString()}
                        </div>
                    ) : (
                        <div className="temp-code-verification-send-again">
                            <span>עדיין מחכה לקוד?</span>
                            <a onClick={() => sendLoginTempCode(formValues?.sessionToken)}>שלח שוב</a>
                        </div>
                    )}
                </div>
            </div>

            <div className="login-button-container">
                <StyledButton className="regular-login-button" onClick={onLogin}>
                    {isLoadingLogin ? <Spin size="large" /> : "התחברות"}
                </StyledButton>
            </div>
        </div>
    );
};
