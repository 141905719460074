import {originMapping} from "./consts.js";
import common from "@well-b/well-b-shared";

export const getThemeByOrigin = originUrl => {
    try {
        const hostname = new URL(originUrl).hostname;
        const theme = originMapping[hostname];
        return theme || common.themeNames.wellB;
    } catch (e) {
        console.error({error: e.message, stack: e.stack});
    }
};
