import React from "react";
import {StatisticsLabel} from "../../components/StatisticsLabel";

export const AdminProviderStatisticsLabel = ({providersDebt}) => {
    return (
        <StatisticsLabel
            statistics={[
                {
                    name: "חוב בפיגור",
                    value: providersDebt.arrearsDebt.toLocaleString(),
                    removeIfEmpty: true,
                    children: [],
                    suffix: "₪"
                },
                {
                    name: "חוב עתידי",
                    value: providersDebt.futureDebt.toLocaleString(),
                    removeIfEmpty: true,
                    children: [],
                    suffix: "₪"
                }
            ]}
            title={<span>סך הכל חוב לשותפים {providersDebt.totalDebt.toLocaleString()} ₪</span>}
        />
    );
};
