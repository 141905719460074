import {EXCenterEventCard} from "../events/EXCenterEventCard";
import EXCenterInfoCard from "../infos/EXCenterInfoCard";
import React, {useContext} from "react";
import {EXCenterContext} from "../EXCenterContext";
import {toValidHtmlId} from "../utils/DomUtil";

export const EXCenterSectionItems = ({itemsByCategory}) => {
    const {theme} = useContext(EXCenterContext);
    return (
        <div style={{display: "flex", justifyContent: "center", width: "100%", marginLeft: 10, marginRight: 10}}>
            <div style={{display: "flex", flexDirection: "column", maxWidth: 1200, width: "100%"}}>
                {Object.keys(itemsByCategory).map(category => {
                    const items = itemsByCategory[category];
                    return (
                        <div
                            key={`items-${category}`}
                            style={{display: "flex", flexDirection: "column", marginTop: 50, width: "100%"}}>
                            <div
                                className="wb-ss-no-padding wb-ss-justify-center"
                                id={`category-${toValidHtmlId(category)}`}
                                style={{
                                    fontSize: 34,
                                    fontWeight: 900,
                                    color: theme.textColor,
                                    paddingLeft: 20
                                }}>
                                {category}
                            </div>
                            <div
                                className="wb-ss-justify-center"
                                style={{display: "flex", flexWrap: "wrap", width: "100%"}}>
                                {items.map(item => {
                                    if (item.event) {
                                        return (
                                            <EXCenterEventCard
                                                key={`item-${item.event.eventId}`}
                                                eventInfo={item.event}
                                                date={item.date}
                                            />
                                        );
                                    } else {
                                        return (
                                            <EXCenterInfoCard
                                                key={`item-${item.eventId}`}
                                                item={item}
                                                category={category}
                                            />
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
