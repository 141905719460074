import React from "react";
import {PriceTypes} from "../../admin/upload/PriceSelectorHelper";
import {StyledButton} from "../../components";

export const PricingCalculatorByVoucher = ({product, onBook}) => (
    <>
        <div>
            <div className="pricing-calculator-content-title">סכומי שוברים</div>
            <div className="pricing-calculator-content-pricing-list">
                {product.pricingTable[PriceTypes.ByVoucher]
                    .sort(({redeemableValue: r1}, {redeemableValue: r2}) => r1 - r2)
                    .map(({redeemableValue}, index) => (
                        <div key={index} className="pricing-calculator-content-pricing-list-item">
                            <span>{`✨ שובר ${redeemableValue}₪`}</span>
                            <span className="including-vat-Text">כולל מעמ</span>
                        </div>
                    ))}
            </div>
        </div>

        <StyledButton onClick={onBook} className="pricing-calculator-button">
            {"מעבר להזמנה"}
        </StyledButton>
    </>
);
