import {useContext, useEffect} from "react";
import {AppContext} from "../../AppContext.jsx";

export const useListenToSocket = ({eventName, onUpdate}) => {
    const {listenToEvent, unListenToEvent} = useContext(AppContext);

    useEffect(() => {
        if (eventName && listenToEvent && unListenToEvent) {
            listenToEvent(eventName, onUpdate);
            return () => {
                unListenToEvent(eventName);
            };
        }
    }, [eventName, listenToEvent, onUpdate, unListenToEvent]);
};
