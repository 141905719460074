import {RoundedTable} from "../../components/RoundedTable";
import React, {useEffect, useState} from "react";
import PageTitleHeader from "../../components/PageTitle";
import {message, Spin, Select, Button, Menu, Dropdown} from "antd";
import {HttpClient} from "../../http/HttpClient";
import moment from "moment-timezone";
import {AdminBillingToBePaidSummaryLine} from "./AdminBillingToBePaidSummaryLine";
import {sumBy, round} from "lodash";
import {PaymentPlan} from "../../provider/ProviderPaymentSelection";
import {AdminBillingEditTransactionModal} from "./AdminBillingEditTransactionModal";
import {PageLoader} from "../../components/PageLoader";

const {Option} = Select;

const columns = onEditInfo => {
    return [
        {
            title: "#",
            key: "#",
            width: 70,
            render(_, __, idx) {
                return `${idx + 1}`;
            }
        },
        {
            title: "SERVICE NAME",
            dataIndex: "serviceName",
            width: 200,
            render(serviceName) {
                return <span style={{fontSize: 14, color: "#747F92"}}>{serviceName}</span>;
            }
        },
        {
            title: "COMPANY",
            dataIndex: "companyName",
            width: 200,
            render(companyName) {
                return <span style={{fontSize: 14, color: "#747F92"}}>{companyName}</span>;
            }
        },
        {
            title: "REFERRAL",
            dataIndex: "isReferralCompany",
            width: 100,
            render(isReferralCompany) {
                return <span style={{fontSize: 14, color: "#747F92"}}>{isReferralCompany ? "YES" : "NO"}</span>;
            }
        },
        {
            title: "DATE",
            dataIndex: "date",
            sorter: (r1, r2) => {
                return moment(r1.date, "DD/MM/YYYY").isBefore(moment(r2.date, "DD/MM/YYYY")) ? -1 : 1;
            },
            defaultSortOrder: "ascend",
            render(date) {
                return <span style={{fontSize: 14, color: "#747F92"}}>{date}</span>;
            }
        },
        {
            title: "TRANSACTION PRICE",
            dataIndex: "price",
            width: 200,
            render(price) {
                return (
                    <span style={{fontSize: 14, color: "#747F92"}}>{`₪${round(price, 2).toLocaleString()}+VAT`}</span>
                );
            }
        },
        {
            title: "REV SHARE",
            dataIndex: "revShare",
            width: 200,
            render(revShare) {
                return (
                    <span style={{fontSize: 14, color: "#747F92"}}>{`₪${round(
                        revShare,
                        2
                    ).toLocaleString()}+VAT`}</span>
                );
            }
        },
        {
            title: "NOTES",
            dataIndex: "notes",
            render(notes) {
                return <span style={{fontSize: 14, color: "#747F92"}}>{notes}</span>;
            }
        },
        {
            title: "",
            key: "actions",
            render(_, transaction) {
                const menu = (
                    <Menu>
                        <Menu.Item key="editInfo">
                            <Button style={{minWidth: 100, color: "var(--secondary-color)"}} type="link">
                                Edit information
                            </Button>
                        </Menu.Item>
                        <Menu.Item key="deleteTransaction">
                            <Button style={{minWidth: 100, color: "var(--secondary-color)"}} type="link">
                                Delete row
                            </Button>
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <div onClick={e => e.stopPropagation()}>
                        <Dropdown.Button
                            className="wb-action-drpdwn"
                            style={{border: "none"}}
                            overlay={menu}
                            // icon={processingProviderId === providerId ? <LoadingOutlined/> : void 0}
                        />
                    </div>
                );
            }
        }
    ];
};

export const AdminBillingToBePaidTransactions = ({match}) => {
    const {date, providerId} = match.params;
    const [providerInfo, setProviderInfo] = useState(null);
    const [transactions, setTransactions] = useState(null);
    const [sumTotal, setSumTotal] = useState(null);
    const [transactionToEdit, setTransactionToEdit] = useState(null);

    useEffect(() => {
        Promise.resolve().then(async () => await fetchTransactions());
    }, []);

    const fetchTransactions = async () => {
        try {
            const [provider, trans] = await Promise.all([
                HttpClient.get(`/admin/api/providers/${providerId}`),
                HttpClient.get(`/admin/api/billing/${date}/providers/${providerId}/transactions`)
            ]);
            setProviderInfo(provider);
            setTransactions(trans);
            setSumTotal(toSumTotal(trans));
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
    };

    const updateProviderInfo = async (paymentPlan, dealerType) => {
        try {
            await HttpClient.post(`/admin/api/providers/${providerInfo.providerId}/payment`, {
                paymentPlan,
                dealerType
            });
            setProviderInfo({
                ...providerInfo,
                paymentPlan,
                dealerType
            });
            setTransactions(null);
            setSumTotal(null);
            await fetchTransactions();
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
    };

    const onEditInfo = transaction => {
        setTransactionToEdit(transaction);
    };

    const closeEditModal = newTransaction => {
        if (newTransaction) {
            const newTransactions = transactions.map(transaction => {
                if (transaction.date === newTransaction.date && transaction.eventId === newTransaction.eventId) {
                    return newTransaction;
                } else {
                    return transaction;
                }
            });
            setTransactions(newTransactions);
            setSumTotal(toSumTotal(newTransactions));
        }
        setTransactionToEdit(null);
    };

    const toSumTotal = trans => {
        return sumBy(trans, t => t.price - t.revShare);
    };

    const providerName = () => {
        return `${providerInfo.firstName}${providerInfo.lastName ? ` ${providerInfo.lastName}` : ""}`;
    };

    const toActivityMonth = () => {
        return moment(date, "YYYY-MM").subtract(2, "months").format("MMMM");
    };

    if (providerInfo === null) {
        return <PageLoader align="flex-start" top={80} />;
    }

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                margin: 10,
                marginBottom: 40
            }}>
            <AdminBillingEditTransactionModal
                providerName={providerInfo ? providerName() : ""}
                visible={transactionToEdit !== null}
                onClose={transaction => closeEditModal(transaction)}
                transactionInfo={transactionToEdit}
            />
            <div style={{display: "flex", flexDirection: "column", width: 1230}}>
                <PageTitleHeader subTitleStyle={{marginBottom: 20}} backStyle={{left: 0}}>
                    Billing
                </PageTitleHeader>
                <div style={{display: "flex", justifyContent: "space-betwen", alignItems: "center", marginBottom: 20}}>
                    <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", width: "33%"}}>
                        <Select
                            onSelect={async paymentPlan =>
                                await updateProviderInfo(paymentPlan, providerInfo.dealerType || "licensed")
                            }
                            defaultValue={providerInfo.paymentPlan}>
                            {Object.keys(PaymentPlan).map(key => (
                                <Option key={key} value={PaymentPlan[key]}>
                                    {PaymentPlan[key]}
                                </Option>
                            ))}
                        </Select>
                        <Select
                            onSelect={async dealerType =>
                                await updateProviderInfo(providerInfo.paymentPlan, dealerType)
                            }
                            defaultValue={providerInfo.dealerType || "exempt"}
                            style={{marginLeft: 10}}>
                            <Option value="exempt">Exempt dealer</Option>
                            <Option value="licensed">Licensed dealer</Option>
                            <Option value="llc">LLC (חברה בע״מ)</Option>
                        </Select>
                    </div>
                    <div
                        style={{
                            fontSize: 20,
                            color: "#273444",
                            height: 50,
                            fontWeight: 400,
                            display: "flex",
                            alignItems: "center"
                        }}>
                        {providerName()} | {toActivityMonth()} Activity
                    </div>
                    <div />
                </div>
                <RoundedTable
                    rowKey="providerId"
                    scroll={{x: 1400, y: 600}}
                    style={{color: "#747F92"}}
                    pagination={false}
                    loading={transactions === null}
                    dataSource={transactions || []}
                    columns={columns(onEditInfo)}
                />
                <AdminBillingToBePaidSummaryLine
                    toBePaidOn={moment(date, "YYYY-MM").format("MMMM [1st]")}
                    sumTotal={sumTotal || 0}
                />
            </div>
        </div>
    );
};
