import React from "react";
import {PeriodStep} from "./PeriodStep";
import {OccasionCategoryStep} from "./OccasionCategoryStep";

export const monthsRequired = 12;

export const calculatorWizardSteps = [
    {
        content: props => <PeriodStep {...props} />,
        percent: 33
    },
    {
        content: props => <OccasionCategoryStep {...props} />,
        percent: 66
    }
];

export const EditSettingsModalTypes = {
    TimeEdit: "TimeEdit",
    CategoriesEdit: "CategoriesEdit"
};

export const getCategoryPolicyName = isRangePolicy => (isRangePolicy ? "מדיניות כללית בטווח" : "בחירת מועדים");
