import React, {useContext} from "react";
import {BaloonsIcon} from "../../icons";
import {EXCenterContext} from "../EXCenterContext";

export const EXCenterNoBirthdaysCard = () => {
    const {theme} = useContext(EXCenterContext);
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 30,
                width: 270,
                height: 290,
                borderRadius: 10,
                boxShadow: "0 10px 20px 0 rgba(22,29,37,0.1)",
                margin: 15,
                backgroundColor: "white",
                marginTop: 30,
                marginBottom: 30
            }}>
            <BaloonsIcon style={{width: 80, height: 100, marginBottom: 40}} />
            <span style={{fontSize: 20, height: 30, fontWeight: 500, color: theme.textColor, marginBottom: 10}}>
                No birthdays today...
            </span>
        </div>
    );
};
