import React, {useContext, useEffect, useState} from "react";
import {EXCenterInfoItem} from "../infos/EXCenterInfoItem";
import {StyledButton} from "../../components/StyledButton";
import {openWindow} from "../../http/WindowOpener";
import {truncate, take, shuffle} from "lodash";
import {HttpClient} from "../../http/HttpClient";
import {message} from "antd";
import {findContentSectionById} from "../../company/content/CompanyContent";
import EXCenterInfoCard from "../infos/EXCenterInfoCard";
import {DesignMenuButton} from "../control/EXCenterSectionDesignMenu";
import {PencilIcon} from "../../icons";
import {AppContext} from "../../AppContext";
import {CompanyContentEditModal} from "../../company/content/edit/CompanyContentEditModal";
import {EventBus} from "../../bus/EventBus";
import {isMyBranchSelected} from "../branches/EXCenterBranches";
import {CompanyContentTagsModal} from "../../company/content/tags/CompanyContentTagsModal";
import {EXCenterContext} from "../EXCenterContext";
import {isCenterInPreviewMode} from "../utils/ViewUtil";
import {AutoDirectionProvider, AutoDirectionProviderWithValueChange} from "../../AutoDirectionProvider";
import {PageLoader} from "../../components/PageLoader";
// import Markdown from "markdown-it";
// import * as htmlParse from "html-react-parser";
import {SmartImageContainer} from "../../components/SmartImageContainer";
import {GoogleAnalytics} from "../../GoogleAnalytics";

const md = null;

// const md = new Markdown({
//     html: false,
//     linkify: true
// });

const MAX_RELATED_ITEMS = 3;

const getPreviewContent = contentId => {
    const contentString = window.localStorage.getItem(`wb-content-${contentId}`);
    if (contentString) {
        return JSON.parse(contentString);
    }

    return null;
};

export const EXCenterDetailsPage = ({match}) => {
    const {me} = useContext(AppContext);
    const {theme} = useContext(EXCenterContext);
    const {contentId} = match.params;
    const [infoItem, setInfoItem] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showTagsModal, setShowTagsModal] = useState(false);
    const [relatedItems, setRelatedItems] = useState(null);
    const [pageViewStartTimeInMs, _] = useState(Date.now());

    if (infoItem) {
        window.sessionStorage.setItem(contentId, infoItem.title);
    }

    useEffect(() => {
        reloadDetails(contentId);
        return () => {
            if (me.type === "employee") {
                const contentName = window.sessionStorage.getItem(contentId);
                GoogleAnalytics.time("Employee Center", contentName, pageViewStartTimeInMs);
            }
        };
    }, []);

    const reloadDetails = itemId => {
        if (isCenterInPreviewMode()) {
            const content = getPreviewContent(itemId);
            setInfoItem(new EXCenterInfoItem(content));
        } else {
            Promise.resolve().then(async () => await fetchContentInfo(itemId));
        }
    };

    const fetchContentInfo = async itemId => {
        try {
            const item = await HttpClient.get(`/api/content/items/${itemId}`);

            let related = await HttpClient.get(`/api/content/sections/${item.sectionId}`);
            related = related.filter(i => i.eventId !== item.eventId);

            const infoItem = new EXCenterInfoItem(item);
            GoogleAnalytics.trackContent(infoItem, "View", me.branch.name);
            setInfoItem(infoItem);
            setRelatedItems(take(shuffle(related), MAX_RELATED_ITEMS).map(item => new EXCenterInfoItem(item)));
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
    };

    const onContentChange = async changedContent => {
        try {
            changedContent = await HttpClient.post(`/api/content`, changedContent);
            setInfoItem(new EXCenterInfoItem(changedContent));
            EventBus.trigger("ex_center:refresh");
            setShowEditModal(false);
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
    };

    const readMore = () => {
        if (isCenterInPreviewMode()) {
            openWindow(infoItem.button.link, "_blank", true);
        } else {
            GoogleAnalytics.trackContent(infoItem, "CTA", me.branch.name);
            openWindow(`/api/content/items/${infoItem.itemId}/track`, "_blank", true);
        }
    };

    if (infoItem === null) {
        return <PageLoader align="center" top={80} style={{marginTop: 120}} />;
    }

    const category = findContentSectionById(infoItem.sectionId).displayName;

    return (
        <div
            style={{
                display: "flex",
                width: "100%",
                justifyContent: "center"
            }}>
            <div
                key={infoItem.itemId}
                style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", maxWidth: 1140}}>
                <CompanyContentEditModal
                    sectionId={infoItem.sectionId}
                    category={category}
                    contentToEdit={infoItem.originalItem}
                    title="Edit content"
                    onClose={() => setShowEditModal(false)}
                    visible={showEditModal}
                    onEditDone={async content => {
                        await onContentChange(content);
                    }}
                    onAddDone={null}
                    onValuesChange={null}
                    onDelete={null}
                    onDisplayChange={async () => {
                        const changedContent = infoItem.originalItem;
                        changedContent.status = changedContent.status === "info-hidden" ? "info-shown" : "info-hidden";
                        await onContentChange(changedContent);
                    }}
                    onNewCategory={() => setShowTagsModal(true)}
                />
                <CompanyContentTagsModal
                    sectionId={infoItem.sectionId}
                    category={category}
                    visible={showTagsModal}
                    onClose={() => setShowTagsModal(false)}
                />
                <SmartImageContainer
                    className="wb-center-details-cover"
                    width={1200}
                    height={680}
                    src={infoItem.coverPhoto}
                    style={{
                        position: "relative",
                        width: "100%",
                        paddingBottom: "56%",
                        maxHeight: 600,
                        marginTop: 50
                    }}>
                    {me.type === "company" && isMyBranchSelected(me) && !isCenterInPreviewMode() ? (
                        <DesignMenuButton
                            onClick={() => setShowEditModal(true)}
                            style={{
                                position: "absolute",
                                left: 10,
                                top: 10
                            }}>
                            <PencilIcon style={{width: 18, height: 18, fill: theme.textColor}} />
                        </DesignMenuButton>
                    ) : null}
                </SmartImageContainer>
                <div
                    className="wb-center-responsive-content-details"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                        marginTop: 50
                    }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            width: "100%",
                            paddingBottom: 30,
                            marginBottom: 30,
                            borderBottom: "1px solid #DAE0E4"
                        }}>
                        <AutoDirectionProvider
                            text={infoItem.title}
                            style={{
                                fontSize: 46,
                                fontWeight: 900,
                                lineHeight: "60px",
                                color: theme.textColor,
                                marginBottom: 20,
                                width: "100%"
                            }}>
                            {infoItem.title}
                        </AutoDirectionProvider>
                        {!!infoItem.subTitle ? (
                            <AutoDirectionProvider
                                text={infoItem.subTitle}
                                style={{
                                    fontSize: 34,
                                    fontWeight: 500,
                                    lineHeight: "40px",
                                    color: theme.textColor,
                                    marginBottom: 10,
                                    width: "100%"
                                }}>
                                {infoItem.subTitle}
                            </AutoDirectionProvider>
                        ) : null}
                        <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                            {infoItem.providerType !== "company" && infoItem.provider ? (
                                <AutoDirectionProviderWithValueChange
                                    style={{width: "100%"}}
                                    value={infoItem.provider.name}>
                                    {({dir, value}) => (
                                        <span
                                            style={{
                                                fontSize: 34,
                                                fontWeight: 200,
                                                lineHeight: "40px",
                                                color: theme.textColor,
                                                marginRight: 10
                                            }}>
                                            {dir === "ltr" ? `By ${value}` : `עם ${value}`}
                                        </span>
                                    )}
                                </AutoDirectionProviderWithValueChange>
                            ) : null}
                            {!!infoItem.location ? (
                                <AutoDirectionProvider
                                    text={infoItem.location.address || "Click to join"}
                                    style={{
                                        fontSize: 24,
                                        fontWeight: 200,
                                        lineHeight: "34px",
                                        color: theme.textColor,
                                        marginTop: 20,
                                        width: "100%"
                                    }}>
                                    {infoItem.location.address ? (
                                        infoItem.location.address
                                    ) : (
                                        <a href={`/api/events/${infoItem.itemId}/track`} target="_blank">
                                            Click to join
                                        </a>
                                    )}
                                </AutoDirectionProvider>
                            ) : null}
                        </div>
                    </div>
                    {(infoItem.description || "").split(/\r?\n/).map(line => (
                        <AutoDirectionProvider
                            text={line}
                            style={{
                                fontSize: 20,
                                fontWeight: 200,
                                lineHeight: 1.5,
                                color: theme.textColor,
                                whiteSpace: "pre-line",
                                width: "100%",
                                display: "inline",
                                wordBreak: "break-word"
                            }}>
                            {line ? htmlParse(md.renderInline(line)) : <br />}
                        </AutoDirectionProvider>
                    ))}
                    {!!infoItem.button ? (
                        <div style={{display: "flex"}}>
                            <StyledButton
                                style={{
                                    color: "white",
                                    backgroundColor: theme.primaryColor,
                                    borderRadius: 20,
                                    height: 40,
                                    minWidth: 135,
                                    maxWidth: 320,
                                    marginTop: 50
                                }}
                                onClick={() => readMore()}>
                                {truncate(infoItem.button.text, {length: 37})}
                            </StyledButton>
                        </div>
                    ) : null}
                </div>
                {Array.isArray(relatedItems) && relatedItems.length > 0 ? (
                    <div style={{display: "flex", width: "100%", justifyContent: "center", marginTop: 100}}>
                        <div
                            className="wb-center-responsive-content-details"
                            style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            <span
                                className="wb-ss-section-title  wb-ss-full-width wb-center-related-content"
                                style={{
                                    fontSize: 34,
                                    fontWeight: 900,
                                    color: theme.textColor,
                                    width: "100%",
                                    marginBottom: 30,
                                    paddingLeft: 20
                                }}>
                                Related {findContentSectionById(infoItem.sectionId).displayName.toLowerCase()}
                            </span>
                            <div
                                className=""
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    width: "100%"
                                }}>
                                {relatedItems.map(rItem => (
                                    <EXCenterInfoCard
                                        onClick={() => reloadDetails(rItem.itemId)}
                                        key={rItem.itemId}
                                        item={rItem.originalItem}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
