import moment from "moment-timezone";
import React, {useContext, useState} from "react";
import {EXCenterContext} from "../EXCenterContext";
import {EventInfoModalV2} from "../../event/info/EventInfoModalV2";
import {EventInfoManager} from "../../event/info/EventInfoManager";
import {EXCenterBookingModal} from "../events/EXCenterBookingModal";
import {AutoDirectionProvider} from "../../AutoDirectionProvider";
import {LocationLabels} from "../../data/locations";

export const EXCenterMobileCalendarItem = ({event, date, onEventUpdate, instantOpen = false}) => {
    const {theme} = useContext(EXCenterContext);
    const [showBooking, setShowBooking] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(instantOpen);

    const title = event.productName || event.sessionName || event.serviceName;

    const updateEvent = (updatedEvt, applyType) => {
        onEventUpdate(updatedEvt, applyType);
        setShowBooking(false);
        setShowInfoModal(false);
    };

    return (
        <div className="wb-mobile-calendar-item">
            <EXCenterBookingModal
                visible={showBooking}
                onClose={() => setShowBooking(false)}
                eventInfo={event}
                date={date}
            />
            <EventInfoManager
                eventInfo={event}
                dateMom={moment(date)}
                onBook={() => setShowBooking(true)}
                ignoreProviderInfo={true}
                maxWidth={800}
                Component={({event}) => (
                    <div style={{width: "100%"}}>
                        <EventInfoModalV2
                            event={event}
                            currentStart={date}
                            currentEnd={
                                !event.appointmentBased ? moment(date).add(event.duration, "minutes").toDate() : null
                            }
                            visible={showInfoModal}
                            onClose={() => setShowInfoModal(false)}
                            onBook={() => {
                                setShowInfoModal(false);
                                setShowBooking(true);
                            }}
                            onEventUpdate={updateEvent}
                        />
                        <div
                            onClick={() => setShowInfoModal(true)}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                backgroundColor: event.backgroundColor || theme.primaryColor,
                                borderRadius: 6,
                                padding: 10,
                                marginBottom: 10
                            }}>
                            <AutoDirectionProvider
                                text={title}
                                style={{
                                    color: "white",
                                    fontSize: 14,
                                    lineHeight: 1.2,
                                    width: "100%"
                                }}>
                                {title}
                            </AutoDirectionProvider>
                            {event.appointmentBased ? (
                                <span style={{fontSize: 12, color: "rgba(255,255,255,0.4)"}}>
                                    {event.minTime} - {event.maxTime}
                                </span>
                            ) : (
                                <div style={{display: "flex"}}>
                                    <span style={{fontSize: 12, color: "rgba(255,255,255,0.4)"}}>
                                        {moment(date).format("HH:mm")} -{" "}
                                        {moment(date).add(event.duration, "minutes").format("HH:mm")}
                                    </span>
                                    {event.location !== LocationLabels.ONLINE ? (
                                        <span style={{marginLeft: 4, fontSize: 12, color: "rgba(255,255,255,0.4)"}}>
                                            | {event.address}
                                        </span>
                                    ) : null}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            />
        </div>
    );
};
