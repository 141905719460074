import React, {useCallback, useContext, useEffect} from "react";
import {Form, Input, message, Modal} from "antd";
import shortid from "shortid";
import {getColumns} from "./columns";
import {AdminSiteContext} from "../AdminSiteContext";
import {RoundedTable, SquareButton} from "../../../components";
import {DraggableBodyRow, DraggableTable} from "../../../components/dragableTable";
import "./sub-category-carousel-modal.css";

export const SubCategoryCarouselModal = ({visible, isEditMode, onSave, onClose}) => {
    const {
        setAddOrEditSubCategoryCarouselModal,
        selectedCarousel,
        setSelectedCarousel,
        setSelectedCarouselItem,
        setIsEditCarouselItem
    } = useContext(AdminSiteContext);

    const [form] = Form.useForm();

    const onSortEnd = useCallback(({oldIndex, newIndex}) => {
        setSelectedCarousel(prevCarousel => {
            const newItems = [...prevCarousel.carouselItems];
            const [movedItem] = newItems.splice(oldIndex, 1);
            newItems.splice(newIndex, 0, movedItem);
            return {...prevCarousel, carouselItems: newItems};
        });
    }, []);

    const removeCarouselItem = useCallback(carouselItemId => {
        setSelectedCarousel(currentCarousel => ({
            ...currentCarousel,
            carouselItems: currentCarousel.carouselItems.filter(item => item.carouselItemId !== carouselItemId)
        }));
    }, []);

    const openEditCarouselItemModal = useCallback(
        selectedCarouselItemId => {
            const foundCarouselItem = selectedCarousel.carouselItems.find(
                ({carouselItemId}) => carouselItemId === selectedCarouselItemId
            );

            if (!foundCarouselItem) {
                message.error("carouselItemId not found");
            }

            setIsEditCarouselItem(true);
            setSelectedCarouselItem(foundCarouselItem);
            setAddOrEditSubCategoryCarouselModal(true);
        },
        [selectedCarousel]
    );

    const innerClose = useCallback(() => {
        form.resetFields();
        onClose();
    }, [form]);

    useEffect(() => {
        if (visible) {
            form.setFieldsValue(selectedCarousel);
        }
    }, [visible]);

    return (
        <Modal open={visible} width={800} footer={null} onCancel={innerClose} maskClosable={false}>
            <Form
                form={form}
                onFinish={async values => {
                    onSave(
                        {
                            ...values,
                            carouselItems: selectedCarousel.carouselItems,
                            carouselId: isEditMode ? selectedCarousel.carouselId : shortid.generate()
                        },
                        isEditMode
                    );
                }}>
                <div className="sub-category-carousel-modal">
                    <div className="title">{isEditMode ? "עריכת" : "הוספת"} קרוסלת מיני קטגוריות</div>

                    <div className="top-bar">
                        <Form.Item name="carouselName">
                            <Input placeholder="שם קרוסלת מיני קטגוריות" />
                        </Form.Item>

                        <Form.Item name="gridNumber">
                            <Input placeholder="מספר גריד" type="number" />
                        </Form.Item>

                        <Form.Item name="customCarouselHeight">
                            <Input placeholder="גובה האייטמים בקרוסלה" type="number" />
                        </Form.Item>

                        <SquareButton onClick={() => setAddOrEditSubCategoryCarouselModal(true)}>
                            יצירת מיני קטגוריות
                        </SquareButton>
                    </div>

                    <RoundedTable
                        rowKey="carouselItemId"
                        pagination={false}
                        dataSource={selectedCarousel.carouselItems ?? []}
                        columns={getColumns(removeCarouselItem, openEditCarouselItemModal)}
                        components={{
                            body: {
                                wrapper: props => (
                                    <DraggableTable
                                        helperClass="sub-category-carousel-modal-row-dragging"
                                        onSortEnd={onSortEnd}
                                        {...props}
                                    />
                                ),
                                row: props => (
                                    <DraggableBodyRow
                                        dataSource={selectedCarousel?.carouselItems ?? []}
                                        rowKey="carouselItemId"
                                        {...props}
                                    />
                                )
                            }
                        }}
                    />

                    <div className="actions">
                        <SquareButton htmlType="submit">שמור מידע</SquareButton>

                        <SquareButton onClick={() => innerClose()}>סגור</SquareButton>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};
