import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Input} from "antd";
import {set, uniqBy} from "lodash";
import classnames from "classnames";
import {SearchOutlined} from "@ant-design/icons";
import {MarketplaceFilterPanel} from "../MarketplaceFilterPanel";
import {MarketplaceSearchResults} from "../MarketplaceSearchResults/MarketplaceSearchResults.jsx";
import {useDebounceState, useRequest} from "../../utils/hooks";
import {Banner, GenericModal} from "../../components";
import {Images} from "../../images/Images";
import {getLogger} from "../../Logger";
import {AppContext} from "../../AppContext";
import {EventBus} from "../../bus/EventBus";
import {CompanyBudgetLabel} from "../../company/CompanyBudgetLabel";
import {openWindow} from "../../http/WindowOpener";
import "./select-page.css";
import {buildURI} from "../../utils/UrlUtils";

export const SelectPage = ({history, match}) => {
    const {me, isolationMode} = useContext(AppContext);
    const [searchSorterType, setSearchSortType] = useState(null);
    const [filterValues, setFilterValues] = useState({});
    const [searchedProductsContent, setSearchedProductsContent] = useState(null);
    const [productPagination, setProductPagination] = useState({});
    const [searchTextInput, setSearchTextInput] = useDebounceState("", 1000);

    const {pageId} = match.params;

    const selectPageURI = useMemo(() => {
        const page = productPagination.page ?? 1;
        const pageSize = 30;
        const sortOption = productPagination.sortOption ?? null;

        const params = new URLSearchParams();

        if (sortOption) {
            params.append("sortOption", sortOption);
        }

        if (searchTextInput) {
            params.append("searchText", searchTextInput);
        }

        params.append("page", page);
        params.append("pageSize", pageSize);

        const queryString = params.toString();

        return `/api/selectPage/${pageId}?${queryString}`;
    }, [pageId, productPagination, searchTextInput]);

    const [selectPageFromServer, isLoadingSelectPageFromServer] = useRequest(selectPageURI, "GET", null, [
        selectPageURI
    ]);

    useEffect(() => {
        setSearchedProductsContent(current => [...(current ?? []), ...(selectPageFromServer?.products ?? [])]);
    }, [selectPageFromServer?.products]);

    useEffect(() => {
        setProductPagination({page: 1, sortOption: searchSorterType});
        setSearchedProductsContent([]);
    }, [searchSorterType, searchTextInput]);

    const products = useMemo(() => searchedProductsContent ?? [], [searchedProductsContent]);

    const filteredProducts = useMemo(
        () =>
            filterValues?.searchText
                ? products.filter(({service}) => {
                      const {productName, brandName, engBrandName, description} = service;
                      return [productName, brandName, engBrandName, description].some(property =>
                          property?.toLowerCase()?.includes(filterValues?.searchText?.toLowerCase())
                      );
                  })
                : products,
        [filterValues, products]
    );

    const onSearch = useCallback(searchText => {
        setSearchTextInput(searchText);
    }, []);

    useEffect(() => {
        if (selectPageFromServer) {
            EventBus.trigger(
                "setIsolationMode",
                selectPageFromServer.isIsolated
                    ? {
                          selectPageId: pageId,
                          productsIds: selectPageFromServer.productIds
                      }
                    : null
            );
        }
    }, [selectPageFromServer]);

    const log = useMemo(
        () =>
            getLogger(
                {
                    selectPageId: pageId,
                    pageName: selectPageFromServer?.name
                },
                "SelectPage"
            ),
        [pageId, selectPageFromServer?.name]
    );

    const onContentClick = useCallback(
        content => {
            log("Select Page content click", {
                productId: content.service.productId,
                productName: content.service.productName,
                providerName: content.provider.providerName
            });

            const baseUrl = content.service.isProductBundle
                ? `/dashboard/services/productBundle/${content.service.productId}`
                : `/marketplace/product/${content.service.productId}`;

            const url = `${baseUrl}${isolationMode ? `?iMode=${isolationMode.selectPageId}` : ""}`;
            history.push(url);
        },
        [log, isolationMode]
    );

    const onNewTab = useCallback(
        content => {
            log("Select Page content click", {
                productId: content.service.productId,
                productName: content.service.productName,
                providerName: content.provider.providerName
            });

            const url = `/marketplace/product/${content.service.productId}${
                isolationMode ? `?iMode=${isolationMode.selectPageId}` : ""
            }`;

            openWindow(url, "_blank", true);
        },
        [log, isolationMode]
    );

    const goToMainPage = useCallback(() => {
        history.push("/dashboard/services");
    }, []);

    const onSortSelected = useCallback(
        sortBy => {
            log("Sort option selected", {sortBy});
            setSearchSortType(sortBy);
        },
        [log]
    );

    const notAllowedForPage = useMemo(() => {
        if (selectPageFromServer?.error) {
            return {
                title: "אופסי, הדף לא נמצא 😬",
                text: ["לצערנו הדף המבוקש לא קיים, עמכם הסליחה"]
            };
        }

        if (selectPageFromServer && !selectPageFromServer.isActive) {
            return {
                title: "אופסי, הדף לא פעיל 😬",
                text: ["לצערנו הדף אינו פעיל כרגע, אנא נסה שוב מאוחר יותר"]
            };
        }

        return null;
    }, [selectPageFromServer]);

    return (
        <>
            {notAllowedForPage ? (
                <GenericModal
                    className="no-content-modal"
                    visible={!!notAllowedForPage}
                    image={Images.outOfStock}
                    title={notAllowedForPage?.title}
                    text={notAllowedForPage?.text ?? []}
                    options={[
                        {
                            id: "goToHomePage",
                            text: "חזרה לעמוד הראשי",
                            onClick: goToMainPage
                        }
                    ]}
                    closeable={false}
                    onClose={goToMainPage}
                />
            ) : null}

            <div className="select-page-container">
                {selectPageFromServer?.banner ? (
                    <Banner
                        name={selectPageFromServer.banner.name}
                        text={selectPageFromServer.banner.text}
                        image={selectPageFromServer.banner.image}
                        width={1240}
                        height={210}
                    />
                ) : null}
                <div className="marketplace-context-search-filters-container">
                    <div
                        className={classnames("marketplace-context-search-filters-container-width", {
                            "select-page-width-fixed": selectPageFromServer?.banner
                        })}>
                        <div className="marketplace-category-title-row">{selectPageFromServer?.name}</div>

                        <div className="select-page-left-top-bar">
                            <Input
                                className="admin-table-date-search-search-input"
                                onChange={e => onSearch(e.target.value)}
                                placeholder="חיפוש בעמוד זה"
                                suffix={<SearchOutlined />}
                            />

                            <MarketplaceFilterPanel
                                filterValues={filterValues}
                                setFilterValues={setFilterValues}
                                searchSorterType={searchSorterType}
                                setSearchSortType={setSearchSortType}
                                onSortSelected={onSortSelected}
                                showFilter={false}
                                log={log}
                            />
                        </div>
                    </div>
                </div>

                <div
                    className={classnames(
                        {"select-page-search-results": !selectPageFromServer?.banner},
                        {"select-page-width-fixed": selectPageFromServer?.banner}
                    )}>
                    {!notAllowedForPage && (
                        <MarketplaceSearchResults
                            SearchResultsTitle={selectPageFromServer?.name}
                            onContentClick={onContentClick}
                            onNewTab={onNewTab}
                            searchedContent={searchedProductsContent}
                            searchSorterType={null}
                            loadingSearchedContent={isLoadingSelectPageFromServer && !searchedProductsContent?.length}
                            searchText={searchTextInput}
                            itemsInRow={selectPageFromServer?.banner ? 3 : 4}
                            totalResultsAmount={selectPageFromServer?.totalProducts}
                            onLastContentReached={() => {
                                setProductPagination(currentPagination => ({
                                    ...currentPagination,
                                    page: currentPagination?.page + 1
                                }));
                            }}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
