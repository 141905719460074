import React, {useCallback, useContext, useEffect} from "react";
import {ConfigProvider} from "antd";
import "../happyHoursLocationFilter/happy-hours-location-filter-popover.css";
import "./bounding-activites-location-filter-popover.css";
import {SegmentedOptions} from "../../../../../components/SegmentedGroup/index.jsx";
import {MarketplaceFilterProviderContext} from "../../../MarketplaceFilterContext.jsx";
import {boundingActivitiesSegmentOptions} from "./segmented-options.jsx";
import {areaOption, groupOptionKeys} from "./radio-group-options.jsx";
import {deliveryAddressOptions} from "../consts.js";
import classnames from "classnames";
import {BasePopoverFilter} from "../../basePopoverFilter.jsx";
import {useLocationFilter} from "../locationFilterHooks.jsx";
import {get} from "lodash";

export const BondingActivitiesLocationFilter = ({children}) => {
    const {innerState, updateInnerState, panel, removeKeyFromInnerState} = useContext(
        MarketplaceFilterProviderContext
    );
    const {updateAddressTitle, onClearAddress, didStateChange} = useLocationFilter();

    useEffect(() => {
        const currentTab = innerState?.locationFilter?.[deliveryAddressOptions.onSite];
        if (currentTab?.key) {
            const areaDeliveryGroup = currentTab?.[groupOptionKeys.areaDelivery];
            if (!areaDeliveryGroup) {
                onAreaSelected("entireCountry");
            }
        }
    }, [innerState?.locationFilter?.[deliveryAddressOptions.onSite]?.key]);

    const onOpenPopover = useCallback(() => {
        if (!innerState?.locationFilter?.currentTab) {
            onTabChange(deliveryAddressOptions.onSite);
        }
    }, [innerState?.locationFilter?.currentTab]);

    const onAreaSelected = useCallback(selectedArea => {
        const option = areaOption.find(option => option.key === selectedArea);
        const deliveryAddressArea = {
            displayName: option.title,
            deliveryType: deliveryAddressOptions.onSite,
            placeKey: option.key
        };
        if (option.placeIds) {
            deliveryAddressArea.placeIds = option.placeIds;
        }

        updateInnerState(
            `locationFilter.${deliveryAddressOptions.onSite}.${groupOptionKeys.areaDelivery}`,
            deliveryAddressArea
        );
    }, [updateInnerState]);

    const onGroupChange = useCallback(groupOption => {
        updateInnerState(`locationFilter.${deliveryAddressOptions.onSite}.key`, groupOption);
    }, []);

    const onTabChange = useCallback(selectedTab => {
        updateInnerState("locationFilter.currentTab", selectedTab);
    }, []);

    const onAreaDeliveryAccessibleChange = useCallback(accessible => {
        const deliveryAddress = get(innerState, `locationFilter.${deliveryAddressOptions.onSite}.${groupOptionKeys.areaDelivery}`);
        deliveryAddress.isAccessible = accessible[0]
        if (deliveryAddress?.areaIncluded?.length) {
            for (const includedDeliveryAddress of deliveryAddress.areaIncluded) {
                includedDeliveryAddress.isAccessible = accessible[0];
            }
        }
        updateInnerState(`locationFilter.${deliveryAddressOptions.onSite}.${groupOptionKeys.areaDelivery}`, deliveryAddress);
    }, [innerState, updateInnerState]);

    const onDeliveryToAddressAccessibleChange = useCallback(accessible => {
        updateAccessible(
            accessible,
            `locationFilter.${deliveryAddressOptions.onSite}.${groupOptionKeys.deliveryToAddress}.isAccessible`
        );
    }, []);

    const updateAccessible = useCallback((accessible, valuePathLocation) => {
        if (accessible?.length === 0) {
            removeKeyFromInnerState(valuePathLocation);
            return;
        }
        updateInnerState(valuePathLocation, accessible[0]);
    }, []);

    const onClear = useCallback(() => {
        onClearAddress();
        onTabChange(deliveryAddressOptions.onSite);
    }, []);

    return (
        <div>
            <ConfigProvider direction="rtl">
                <BasePopoverFilter
                    searchOnClose={didStateChange}
                    onClear={onClear}
                    onOpenPopover={onOpenPopover}
                    onClosePopover={updateAddressTitle}
                    className={classnames("bounding-activities-location-filter-popover", panel)}
                    title="איפה הפעילות"
                    content={
                        <div id="bonding-activities-location-filter">
                            <div id="happy-hour-location-options">
                                <SegmentedOptions
                                    value={innerState?.locationFilter?.currentTab}
                                    options={boundingActivitiesSegmentOptions(
                                        innerState,
                                        onAreaSelected,
                                        onGroupChange,
                                        onAreaDeliveryAccessibleChange,
                                        onDeliveryToAddressAccessibleChange
                                    )}
                                    onChange={onTabChange}
                                />
                            </div>
                        </div>
                    }>
                    {children}
                </BasePopoverFilter>
            </ConfigProvider>
        </div>
    );
};
