import React, {useCallback, useMemo, useState} from "react";
import {ConfigProvider, message} from "antd";
import {columns} from "./columns";
import {ConditionTypes, CouponConditionNames, CouponDiscountTypes, CouponDiscountTypeToName} from "./consts.js";
import {useRequest} from "../../utils/hooks";
import {FormModal, GenericModal, RoundedTable, SquareButton} from "../../components";
import PageTitleHeader from "../../components/PageTitle";
import {HttpClient} from "../../http/HttpClient";
import {Images} from "../../images/Images";
import "./admin-coupons.css";

export const AdminCoupons = () => {
    const [addCouponModalVisibility, setAddCouponModalVisibility] = useState(false);
    const [loadAdvanceStats, setLoadAdvanceStats] = useState(false);
    const [areYouSureYouWantToDeleteModalInfo, setAreYouSureYouWantToDeleteModalInfo] = useState(null);
    const [coupons, loadingCoupons, _, fetchCoupons] = useRequest("/admin/api/coupons");

    const couponsCode = useMemo(() => coupons?.map(({code}) => code), [coupons]);

    const [couponsUses, loadingCouponsUses] = useRequest(
        "/admin/api/coupons/couponsUses",
        "post",
        couponsCode,
        [couponsCode, loadAdvanceStats],
        !!couponsCode?.length && loadAdvanceStats
    );

    const createCoupon = useCallback(
        async couponValues => {
            const requestBody = {
                code: couponValues.code,
                discount: couponValues.discount,
                condition: {
                    type: couponValues.conditionType,
                    value: couponValues.conditionValue
                },
                discountType: couponValues.discountType,
                singleUse: couponValues.singleUse
            };

            const {error} = await HttpClient.safePut("/admin/api/coupons", requestBody);

            if (error) {
                message.error(error, 5);
            }

            setAddCouponModalVisibility(false);
            await fetchCoupons();
        },
        [fetchCoupons]
    );

    const deleteCoupon = useCallback(
        async ({code, singleUse}, force = false) => {
            const couponUses = await HttpClient.safePost("/admin/api/coupons/couponsUses", [code]);

            if (couponUses[code]?.length && !force) {
                if (singleUse) {
                    message.error("מחיקה של קופון חד פעמי שכבר שומש לא מומלצת, יש לשמור לצורכי תיעוד", 5);
                    return;
                }

                setAreYouSureYouWantToDeleteModalInfo({code});
                return;
            }

            message.success("קוד הקופון נמחק בהצלחה");
            await HttpClient.safeDelete(`/admin/api/coupons/${code}`);
            await fetchCoupons();
        },
        [fetchCoupons]
    );

    const onChangeStatus = useCallback(async ({code, newStatus}) => {
        const updateCouponRes = await HttpClient.safePost("/admin/api/coupons/updateStatus", {code, newStatus});

        if (updateCouponRes?.error) {
            message.error("שינוי סטאטוס הקופון נכשל, אנא נסו שנית מאוחר יותר");
            return;
        }

        message.success("סטאטוס הקופון שונה בהצלחה");
        await fetchCoupons();
    }, []);

    return (
        <ConfigProvider direction="rtl">
            <FormModal
                header="יצירת קופון"
                fields={[
                    {
                        name: "code",
                        label: "קוד"
                    },
                    {
                        name: "_discount_multi",
                        type: "multi",
                        inputs: [
                            {
                                name: "discountType",
                                label: "סוג ההנחה",
                                type: "select",
                                options: Object.values(CouponDiscountTypes).map(type => ({
                                    name: type,
                                    label: CouponDiscountTypeToName[type]
                                }))
                            },
                            {
                                name: "discount",
                                label: "כמות הנחה",
                                type: "number"
                            }
                        ]
                    },
                    {
                        name: "singleUse",
                        label: "חד פעמי",
                        info: (
                            <>
                                <div>הנחה חד פעמית - לשימוש חד פעמי בלבד</div>
                                <div>הנחה רב פעמית - כל חברה יכולה להשתמש בקופון פעם אחת בלבד</div>
                            </>
                        ),
                        type: "boolean",
                        checkedChildren: "חד פעמי",
                        unCheckedChildren: "רב פעמי"
                    },
                    {
                        name: "conditionType",
                        label: "סוג תנאי",
                        type: "select",
                        options: [
                            {
                                name: ConditionTypes.MINIMUM,
                                label: CouponConditionNames[ConditionTypes.MINIMUM]
                            }
                        ]
                    },
                    {
                        name: "conditionValue",
                        label: "ערך תנאי",
                        type: "number"
                    }
                ]}
                visible={addCouponModalVisibility}
                onClose={() => setAddCouponModalVisibility(false)}
                onSave={createCoupon}
            />
            <GenericModal
                title="שימו לב!"
                className="admin-coupons-are-you-sure-modal"
                text={[
                    "אתם עומדים למחוק קופון שכבר שומש, מעבר למחיקת התיעוד על השימוש בקופון," +
                        " אתם יכול ליצור בעיות אם תיצרו קופון עם אותו קוד, שכן חברות שכבר הזמינו עם הקופון הזה" +
                        " לא יכול להזמין עם הקופון החדש.",
                    <b> אם אתם לא בטוחים מה אתם עושים - עדיף לא למחוק!</b>
                ]}
                image={Images.areYouSure}
                closeable={true}
                visible={!!areYouSureYouWantToDeleteModalInfo}
                onClose={() => setAreYouSureYouWantToDeleteModalInfo(null)}
                options={[
                    {
                        id: "cancel",
                        onClick: () => setAreYouSureYouWantToDeleteModalInfo(null),
                        text: "בטל מחיקה"
                    },
                    {
                        disabled: true,
                        id: "delete",
                        onClick: () => {
                            deleteCoupon(areYouSureYouWantToDeleteModalInfo, true);
                            setAreYouSureYouWantToDeleteModalInfo(null);
                        },
                        text: "מחק - אני מבין מה אני עושה"
                    }
                ]}
            />
            <div className="admin-coupons">
                <PageTitleHeader showBack={false}>קופונים</PageTitleHeader>
                <div className="admin-coupons-top-bar">
                    <SquareButton
                        onClick={() => setAddCouponModalVisibility(true)}
                        className="admin-coupons-create-coupon-button">
                        צור קופון
                    </SquareButton>

                    <SquareButton onClick={() => setLoadAdvanceStats(true)}>טען סטטיסטיקה מורחבת</SquareButton>
                </div>
                <RoundedTable
                    rowKey="code"
                    className="admin-coupons-table"
                    scroll={{x: 800}}
                    pagination={false}
                    loading={loadingCoupons}
                    dataSource={coupons}
                    columns={columns(couponsUses, loadingCouponsUses, deleteCoupon, onChangeStatus, loadAdvanceStats)}
                />
            </div>
        </ConfigProvider>
    );
};
