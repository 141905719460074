import React from "react";
import {StepperInput} from "../../wizards/components/StepperInput";

export const PRICING_ROWS = 4;

export const StepperPricingMultiInputController = ({pricingTable, category, placeholder, type, updateTable}) => (
    <div>
        {Array(PRICING_ROWS)
            .fill(null)
            .map((_, index) => (
                <StepperPricingMultiInput
                    key={`${type}-${index}`}
                    value={
                        pricingTable && pricingTable[type] && pricingTable[type].length > index
                            ? pricingTable[type][index]
                            : {}
                    }
                    placeholder={placeholder}
                    onPriceChange={price => updateTable(category, index, InputType.PRICE, price)}
                    onMinChange={min => updateTable(category, index, InputType.MIN, min)}
                    onMaxChange={max => updateTable(category, index, InputType.MAX, max)}
                />
            ))}
    </div>
);

export const StepperPricingMultiInput = ({onMinChange, onMaxChange, onPriceChange, placeholder, value}) => (
    <div style={{display: "flex", flexDirection: "row", marginBottom: 10, alignItems: "baseline"}}>
        <StepperInput
            value={value.min}
            style={{marginLeft: 15, width: "80px"}}
            placeholder="X"
            onChange={e => onMinChange(e.target.value)}
        />
        <div style={{marginLeft: 15, marginTop: 10}}>עד</div>
        <StepperInput
            value={value.max}
            style={{marginLeft: 15, width: "80px"}}
            placeholder="X"
            onChange={e => onMaxChange(e.target.value)}
        />
        <StepperInput
            value={value.price}
            style={{width: "180px"}}
            placeholder={placeholder || "מחיר"}
            onChange={e => onPriceChange(e.target.value)}
        />
    </div>
);

export const initializeEmptyField = () =>
    [...Array(PRICING_ROWS)].map(() => ({
        min: null,
        max: null,
        price: null
    }));

export const PriceTypesByStep = {
    ByPerson: "byPerson",
    ByGroup: "byGroup",
    ByPlatte: "byPlatte",
    ByUnit: "byUnit",
    ByPackage: "byPackage"
};

export const PriceTypes = {
    ...PriceTypesByStep,
    ByModular: "byModular",
    ByVoucher: "byVoucher"
};

export const PriceTypesToHebrew = {
    [PriceTypes.ByPerson]: "משתתף",
    [PriceTypes.ByGroup]: "קבוצה",
    [PriceTypes.ByPlatte]: "מגש",
    [PriceTypes.ByUnit]: "יחידה",
    [PriceTypes.ByPackage]: "מארז",
    [PriceTypes.ByModular]: "מודולרי"
};

export const InputType = {
    MIN: "min",
    MAX: "max",
    PRICE: "price"
};
