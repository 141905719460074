import {intersection, isFinite} from "lodash";
import {kosherOptions} from "../upload/AdminUploadUtils";
import {getPriceByAmount} from "../../marketplace/MarketplaceHelper";
import {PriceTypes} from "../upload/PriceSelectorHelper";
import {FilterOptions} from "../../marketplace/ProductsFilterModal/consts";
import {getMergedDeliveryOptions} from "../../marketplace/MarketplaceBooking/utils";
import {getProductMinPrice} from "../../marketplace/MarketplaceUtils";

export const filterProducts = (allProducts, searchText, extraFilters, tagProductIds, extraSearchTexts = []) =>
    allProducts?.filter(product => {
        const {productName, provider, brandName, engBrandName = null, productId, serviceId} = product;
        const {categories, budget, deliveryAddress, productProperties} = extraFilters ?? {};
        const {businessName} = provider;

        const deliveryOptions = getMergedDeliveryOptions(provider, product);
        let searchTextArray = [...extraSearchTexts, searchText];

        const filterBySearchText = () =>
            searchTextArray.some(searchText =>
                [productName, businessName, brandName, engBrandName, productId, product.description].some(
                    property => property && property?.toLowerCase()?.includes(searchText?.toLowerCase())
                )
            );

        const filterByTag = () =>
            !tagProductIds?.length || tagProductIds.includes(product.productId) || filterBySearchText();

        const filterByCategory = categories
            ? () => !categories?.length || intersection(categories, product.services).length
            : () => true;

        const filterByAddress = deliveryAddress
            ? () => {
                  if (!deliveryAddress || !deliveryAddress?.length) {
                      return true;
                  }

                  //TODO: update like server side filters
                  if (deliveryOptions?.deliveryAreas) {
                      return deliveryAddress.some(address => {
                          const mainArea = deliveryOptions.deliveryAreas[address.mainArea];
                          return isFinite((mainArea?.subAreas?.[address.subArea] ?? mainArea)?.price);
                      });
                  }

                  return product.area?.some(
                      area => area === "כל הארץ" || deliveryAddress.some(({displayName}) => displayName.includes(area))
                  );
              }
            : () => true;

        const filterByBudget = budget
            ? () => {
                  if (!(budget?.participantsAmount && budget?.totalPrice)) {
                      return true;
                  }

                  const {participantsAmount, totalPrice} = budget;

                  const isModularOrVoucherProduct =
                      product.chosenPriceType === "byModular" || product.chosenPriceType === "byVoucher";

                  const productsPrice = isModularOrVoucherProduct
                      ? getProductMinPrice({service: product})
                      : getPriceByAmount(participantsAmount, product, false) * participantsAmount;

                  return (
                      productsPrice <= (totalPrice?.max ?? Number.POSITIVE_INFINITY) &&
                      (isModularOrVoucherProduct || productsPrice >= (totalPrice?.min ?? -1))
                  );
              }
            : () => true;

        const filterByProductProperties = productProperties
            ? () => {
                  if (!productProperties?.length || product.chosenPriceType === PriceTypes.ByVoucher) {
                      return true;
                  }

                  return productProperties.every(productProperty => {
                      if (productProperty === FilterOptions.Kosher) {
                          return (
                              product.provider?.kosher === kosherOptions[0] ||
                              product.provider?.kosher === kosherOptions[2]
                          );
                      }

                      if (productProperty === FilterOptions.ShortNotice) {
                          return parseInt(deliveryOptions?.minNotice) <= 48;
                      }

                      if (productProperty === FilterOptions.HomeDelivery) {
                          return product.location?.includes("homeDelivery");
                      }

                      return product.foodRequests?.includes(productProperty);
                  });
              }
            : () => true;

        return [
            tagProductIds?.length ? filterByTag : filterBySearchText,
            filterByCategory,
            filterByAddress,
            filterByBudget,
            filterByProductProperties
        ].every(validateFunction => validateFunction());
    });

export function cacheProductToProductWithProvider(cacheProduct) {
    return {
        service: cacheProduct,
        provider: cacheProduct.provider,
        isFromFilters: !!cacheProduct?.isFromFilters,
        isTagged: !!cacheProduct?.isTagged
    };
}

export function productWithProviderToCacheProduct({service, provider, ...rest} = {}) {
    return {
        ...service,
        provider: provider,
        ...rest
    };
}
