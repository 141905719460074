import React, {useRef} from "react";
import {ImagesCarousel} from "./ImagesCarousel";
import {MarketplaceCategoryCarouselArrowV2} from "../MarketplaceHelper";
import "./marketplace-images-carousel.css";

export const MarketplaceImagesCarousel = ({images, log, carouselProps, showCarouselArrows = true}) => {
    const carouselRef = useRef(null);

    return (
        <div className="marketplace-images-carousel-container">
            {showCarouselArrows ? (
                <>
                    <MarketplaceCategoryCarouselArrowV2
                        direction="prev"
                        className="marketplace-images-carousel-button-left"
                        onClick={() => {
                            log("Image Carousel Previous Arrow Clicked");
                            carouselRef.current.prev();
                        }}
                    />
                    <MarketplaceCategoryCarouselArrowV2
                        direction="next"
                        className="marketplace-images-carousel-button-right"
                        onClick={() => {
                            log("Image Carousel Next Arrow Clicked");
                            carouselRef.current.next();
                        }}
                    />
                </>
            ) : null}

            <ImagesCarousel carouselRef={carouselRef} images={images} log={log} carouselProps={carouselProps} />
        </div>
    );
};
