import React from "react";
import {NOOP} from "../../utils/NOOP.jsx";
import {GenericModal} from "../../components/index.jsx";
import "./policy-modal.css";
import {ConfigProvider} from "antd";
import {generatePolicy} from "./consts.jsx";

export const PolicyModal = ({visible = false, onConfirm = NOOP, onClose = NOOP, isVoucher = false}) => {
    return (
        <ConfigProvider direction="rtl">
            <GenericModal
                width={574}
                visible={visible}
                onClose={onClose}
                className="policy-modal"
                title="תנאי השימוש במערכת"
                text={generatePolicy(isVoucher)}
                options={[
                    {
                        id: "save",
                        text: "קראתי את תנאי השימוש",
                        onClick: onConfirm
                    }
                ]}
            />
        </ConfigProvider>
    );
};

export const PolicySection = ({title, sections = []}) => (
    <div className="policy-option-container">
        <span className="title">{title}</span>
        {sections.map((section, index) => (
            <div key={index} className="policy-sub-section">
                {section.description && <span>{section.description}</span>}
                <div className="policy-sub-option-container">
                    {section?.policies?.map((policy, idx) => (
                        <OptionPolicy key={idx} policy={policy} />
                    ))}
                </div>
            </div>
        ))}
    </div>
);

const OptionPolicy = ({policy}) => <span>• {policy}</span>;
