import React, {useContext} from "react";
import moment from "moment-timezone";
import {ConfigProvider} from "antd";
import columns from "./columns";
import {RoundedTable, ButtonGroup} from "../../components";
import {TableDateSearch} from "../../admin/components";
import {ProviderContext} from "../ProviderContext";
import {generateProviderOrdersShortcuts} from "./utils.js";
import {ProviderOrderContext} from "./ProviderOrdersContext.jsx";
import {SearchByCreatedSwitch} from "./ProviderOrdersHelper.jsx";
import "./provider-orders.css";

export const ProviderOrders = () => {
    const {providerDetails} = useContext(ProviderContext);

    const {
        onDetails,
        acceptOffer,
        orders,
        onSearchByCreatedAtChange,
        onShortcutClick,
        onDatePicker,
        eventsQueue,
        setSearchInput,
        selectedOption,
        searchByCreated,
        searchDate,
        loadingOrders,
        onDelivered
    } = useContext(ProviderOrderContext);

    return (
        <div className="provider-orders">
            <div className="provider-manage-invoices-title">ניהול הזמנות</div>
            <div className="provider-orders-sub-title">
                לחצו על כפתור ֿ״צפייה בהזמנה״ על מנת לעבור על פרטי ההזמנה ולאשרה
            </div>

            <ButtonGroup
                onSelect={onShortcutClick}
                options={generateProviderOrdersShortcuts}
                selectedOption={selectedOption}
            />

            <div className="provider-orders-top-bar">
                <TableDateSearch
                    date={searchDate}
                    onDatePicked={onDatePicker}
                    onSearch={setSearchInput}
                    todayButtonText="העסקאות של היום"
                    disableFuture={searchByCreated}
                    allowClear={false}
                    defaultValue={[moment().startOf("month"), moment().endOf("month")]}
                />
                <SearchByCreatedSwitch searchByCreated={searchByCreated} onChange={onSearchByCreatedAtChange} />
            </div>
            <ConfigProvider direction="rtl">
                <RoundedTable
                    rowKey="eventId"
                    scroll={{x: 1400}}
                    className="provider-order-table"
                    pagination={false}
                    loading={loadingOrders}
                    dataSource={orders || []}
                    columns={columns(providerDetails, onDetails, onDelivered, acceptOffer, eventsQueue)}
                />
            </ConfigProvider>
        </div>
    );
};
