import React, {useMemo} from "react";
import classnames from "classnames";
import {ContactPhone} from "./ContactPhone";
import {Images} from "../images/Images";
import {useWindowDimensions} from "../utils/hooks.jsx";
import "./contact-us-box.css";

export const ContactUsBox = () => {
    const {width} = useWindowDimensions();
    const isMobileView = useMemo(() => width < 800, [width]);

    return (
        <div
            className={classnames("contact-us-box-container", {isMobileView})}
            onClick={() => ContactPhone.openWhatsApp()}>
            <img className="whatsapp-icon" src={Images.whatsapp} alt="whatsapp" />
        </div>
    );
};
