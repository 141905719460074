import {TrashIcon} from "../icons";
import React, {useCallback} from "react";
import {openWindow} from "../http/WindowOpener";
import {createUrlWithoutTransformations} from "../utils/cloudinaryUtils";

export const InvoiceLabel = ({invoice, onRemoveInvoice}) => {
    const openInvoice = useCallback(invoice => {
        openWindow(createUrlWithoutTransformations(invoice.imageUrl));
    }, []);

    return (
        <div className={"provider-orders-upload-file-wrapper"}>
            <p
                onClick={() => openInvoice(invoice)}
                style={{cursor: "pointer"}}
                className={"provider-orders-upload-file-text"}>
                {invoice.filename}
            </p>
            <TrashIcon
                onClick={() => {
                    onRemoveInvoice(invoice);
                }}
                style={{fill: "#767F90", height: "18px", width: "18px", cursor: "pointer"}}
            />
        </div>
    );
};
