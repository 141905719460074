import React from "react";

export const EXCenterContext = React.createContext({
    allContents: null,
    homeContents: null,
    events: null,
    designInfo: null,
    designInfoPerSection: null,
    theme: null,
    screenSize: null,
    defaultBranchId: null
});
