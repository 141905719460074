export const ConditionTypes = {
    MINIMUM: "minimum"
};

export const CouponConditionNames = {
    [ConditionTypes.MINIMUM]: "מינימום הזמנה"
};

export const CouponDiscountTypes = {
    ByPrice: "byPrice",
    ByPercentage: "byPercentage"
};

export const CouponDiscountTypeToName = {
    [CouponDiscountTypes.ByPrice]: "הנחה קבועה (₪)",
    [CouponDiscountTypes.ByPercentage]: "הנחה לפי אחוזים (%)"
};
