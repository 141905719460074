import React, {useCallback, useState} from "react";
import {Dropdown, Menu, Input} from "antd";
import {PlusOutlined, CloseOutlined} from "@ant-design/icons";
import {SquareButton} from "../../components/SquareButton";

export const DropdownWithTextFields = ({itemList, onEdit, title}) => {
    const [fields, setFields] = useState(itemList.map((item, index) => ({id: index, value: item})));
    const [isOpened, setIsOpened] = useState(false);

    // Define a function to handle the dropdown menu click
    const handleMenuClick = useCallback(
        ({domEvent, key}) => {
            domEvent.preventDefault();
            if (key === "add") {
                setFields([...fields, {id: Date.now(), value: ""}]);
                setIsOpened(true); // keep the dropdown open after adding a new field
            }
        },
        [fields]
    );

    // Define a function to handle text field value change
    const handleTextChange = useCallback(
        (id, value) => {
            setFields(
                fields.map(field => {
                    if (field.id === id) {
                        return {...field, value};
                    }
                    return field;
                })
            );
        },
        [fields]
    );

    const handleVisibleChange = useCallback(
        visible => {
            setIsOpened(visible);
            if (!visible) {
                onEdit(fields.filter(field => field.value).map(field => field.value));
            }
        },
        [fields, onEdit]
    );

    const handleRemoveField = useCallback(
        id => {
            setFields(fields.filter(field => field.id !== id));
        },
        [fields]
    );

    // Define the dropdown menu content
    const menu = (
        <Menu onClick={handleMenuClick}>
            {fields.map((field, index) => (
                <Menu.Item key={field.id}>
                    <Input
                        placeholder="Enter text"
                        addonAfter={<CloseOutlined onClick={() => handleRemoveField(field.id)} />}
                        value={field.value}
                        onChange={e => handleTextChange(field.id, e.target.value)}
                    />
                </Menu.Item>
            ))}
            <Menu.Divider />
            <Menu.Item key="add">
                <a>
                    <PlusOutlined /> Add field
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} open={isOpened} trigger={["click"]} onOpenChange={handleVisibleChange}>
            <SquareButton> {title}</SquareButton>
        </Dropdown>
    );
};
