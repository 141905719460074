import React, {useCallback, useContext, useMemo} from "react";
import {ConfigProvider, Divider} from "antd";
import "./accuracy-results-filter-popover.css";
import {MarketplaceFilterProviderContext} from "../../MarketplaceFilterContext";
import {CheckboxGroupOptions} from "../../../../components/CheckboxGroup/";
import {RadioGroupOptions} from "../../../../components/RadioGroup";
import {kosherGroupOptions} from "./kosher-group-options.jsx";
import classnames from "classnames";
import {getAccuracyResultsFilterTitle} from "./utils.js";
import {BasePopoverFilter} from "../basePopoverFilter.jsx";
import {isEmpty, xor} from "lodash";
import {AppContext} from "../../../../AppContext.jsx";
import {buildFilters} from "../../advancedFilter/utils.js";
import {FoodRequestsShortIdToName} from "../../../ProductsFilterModal/consts.jsx";

export const AccuracyResultsFilterPopover = ({extraContent = null}) => {
    const {filterValues} = useContext(AppContext);
    const {
        innerState,
        updateInnerState,
        removeKeyFromInnerState,
        panel,
        setInnerState,
        accuracyResultsTitle,
        setAccuracyResultsTitle
    } = useContext(MarketplaceFilterProviderContext);

    const onKosherOptionChange = useCallback(
        (tag, checked) => {
            const selectedTags = innerState?.accuracyResults?.foodOptions ?? [];
            const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
            updateFoodOptions(nextSelectedTags);
        },
        [innerState?.accuracyResults?.foodOptions]
    );

    const onSelectedGroup = useCallback(option => {
        updateInnerState("accuracyResults.kosherTypesKey", option);
    }, []);

    const updateFoodOptions = useCallback(foodOptions => {
        if (foodOptions?.length > 0) {
            updateInnerState("accuracyResults.foodOptions", foodOptions);
            return;
        }
        removeKeyFromInnerState("accuracyResults.foodOptions");
    }, []);

    const updateProductProperties = useCallback(productProperties => {
        if (productProperties?.length > 0) {
            updateInnerState("accuracyResults.productProperties", productProperties);
            return;
        }
        removeKeyFromInnerState("accuracyResults.productProperties");
    }, []);

    const onClear = useCallback(() => {
        setInnerState(({accuracyResults, ...otherProperties}) => ({...otherProperties}));
    }, [setInnerState]);

    const onClosePopover = useCallback(() => {
        setAccuracyResultsTitle(getAccuracyResultsFilterTitle(innerState.accuracyResults));
    }, [innerState?.accuracyResults]);

    const didStateChange = useMemo(() => {
        const currentFilter = buildFilters(innerState);
        const isProductPropertieEsqual = isEmpty(
            xor(currentFilter?.productProperties, filterValues?.productProperties)
        );
        const isKosherTypesEqual = isEmpty(xor(currentFilter?.kosherTypes, filterValues?.kosherTypes));

        const isActivityRequestsPropertiesEqual = isEmpty(
            xor(currentFilter?.activityRequestsProperties, filterValues?.activityRequestsProperties)
        );
        return !isProductPropertieEsqual || !isKosherTypesEqual || !isActivityRequestsPropertiesEqual;
    }, [
        innerState?.accuracyResults,
        accuracyResultsTitle,
        filterValues?.productProperties,
        filterValues?.kosherTypes,
        filterValues?.activityRequestsProperties
    ]);

    return (
        <div>
            <ConfigProvider direction="rtl">
                <BasePopoverFilter
                    onClear={onClear}
                    onClosePopover={onClosePopover}
                    searchOnClose={didStateChange}
                    className={classnames("accuracy-results-filter-popover", panel)}
                    title="דיוק תוצאות"
                    content={
                        <div id="specific-result-filter-popover">
                            <CheckboxGroupOptions
                                value={innerState?.accuracyResults?.productProperties}
                                title="התייחסויות שקשורות לאוכל"
                                options={Object.entries(FoodRequestsShortIdToName).map(([key, title]) => ({
                                    value: key,
                                    label: title
                                }))}
                                onChange={updateProductProperties}
                            />
                            <Divider />
                            <div>
                                <span className="title">כשרות בית העסק</span>
                                <RadioGroupOptions
                                    value={innerState?.accuracyResults?.kosherTypesKey}
                                    onChange={onSelectedGroup}
                                    options={kosherGroupOptions(innerState, onKosherOptionChange)}
                                />
                                {extraContent}
                            </div>
                        </div>
                    }>
                    <div className="advanced-filter-segmented-label-container">
                        <div className="advanced-filter-option-title">דיוק תוצאות</div>
                        <div
                            className={classnames("advanced-filter-option-sub-title", {
                                selected: accuracyResultsTitle > 0
                            })}>
                            {accuracyResultsTitle > 0 ? `${accuracyResultsTitle} בחירות` : "בחר מהאפשרויות הבאות"}{" "}
                        </div>
                    </div>
                </BasePopoverFilter>
            </ConfigProvider>
        </div>
    );
};
