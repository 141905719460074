import React, {useCallback, useMemo} from "react";
import classnames from "classnames";
import {Dropdown} from "antd";
import {getMarketplaceViewLink} from "../MarketplaceUtils";
import cover_placeholder from "../../images/cover_placeholder.jpg";
import {StyledButton} from "../../components";

const MiniCategoryItem = ({
    history,
    carouselItemType,
    name,
    content,
    image,
    showItemsAmountTitle,
    amount,
    btnText,
    customCarouselHeight,
    showAsBannerCarousel
}) => {
    const imageUrl = useMemo(() => image?.imageUrl || cover_placeholder, [image]);

    const onClick = useCallback(
        (contextClick = false) => {
            const url = getMarketplaceViewLink(carouselItemType, content);

            // hack for links outside of the platform
            if (url.startsWith("http")) {
                window.open(url, "_blank", "noreferrer");
                return;
            }

            contextClick ? window.open(url, "_blank", "noreferrer") : history.push(url);
        },
        [carouselItemType, content]
    );

    return (
        <div className="mini-categories-carousel-item">
            <Dropdown
                trigger={["contextMenu"]}
                menu={{
                    items: [
                        {
                            label: "פתיחה בכרטיסיה חדשה",
                            key: "open",
                            onClick: () => onClick(true)
                        }
                    ]
                }}>
                <div
                    className={classnames("mini-categories-carousel-item-image-container")}
                    style={
                        customCarouselHeight
                            ? {
                                  height: customCarouselHeight,
                                  minHeight: customCarouselHeight
                              }
                            : null
                    }>
                    <img
                        alt="text"
                        className="mini-categories-carousel-item-image"
                        src={imageUrl}
                        onClick={() => onClick()}
                    />
                </div>
            </Dropdown>

            <div className="mini-categories-carousel-item-label">
                <span className="mini-categories-carousel-item-name" dangerouslySetInnerHTML={{__html: name}} />
                {btnText && showAsBannerCarousel ? (
                    <div className="mini-categories-carousel-item-button">
                        <StyledButton onClick={() => onClick()}>{btnText}</StyledButton>
                    </div>
                ) : null}
                {showItemsAmountTitle && amount && (
                    <span className="mini-categories-carousel-item-count">{amount + "  מוצרים "}</span>
                )}
            </div>
        </div>
    );
};

export default MiniCategoryItem;
