import React, {createContext, useCallback, useState} from "react";
import {message} from "antd";
import {useTimer} from "../../utils/hooks.jsx";
import moment from "moment";
import {HttpClient} from "../../http/HttpClient.jsx";
import {EXPIRE_TEMP_CODE_SEND_AGAIN_TIME_IN_SECONDS} from "./consts.js";
import {set} from "lodash";

export const TempCodeContext = createContext({});

export const TempCodeContextProvider = ({children}) => {
    const [timerEndTime, setTimerEndTime] = useState(null);
    const {remainingTime} = useTimer(timerEndTime);
    const [formValues, setFormValues] = useState({});

    const sendLoginTempCode = useCallback(
        async sessionToken => {
            let response = await HttpClient.safePost("/api/tempcode/send", {
                email: formValues?.email,
                ...(sessionToken ? {sessionToken} : {})
            });

            if (response.error) {
                message.error(response.error);
                return false;
            }

            setTimerEndTime(moment().add(EXPIRE_TEMP_CODE_SEND_AGAIN_TIME_IN_SECONDS, "seconds").valueOf());
            setFormValues(prev => response);
            return true;
        },
        [formValues?.email]
    );

    const onFormValueChange = useCallback((field, val) => {
        setFormValues(prevValues => set({...prevValues}, field, val));
    }, []);

    return (
        <TempCodeContext.Provider
            value={{
                sendLoginTempCode,
                remainingTime,
                formValues,
                onFormValueChange
            }}>
            {children}
        </TempCodeContext.Provider>
    );
};
