import React, {useState, useEffect} from "react";
import moment from "moment-timezone";
import {Menu, Dropdown, message} from "antd";
import {Button, Popconfirm} from "antd";
import {AlertFilled, LoadingOutlined} from "@ant-design/icons";
import PageTitleHeader from "../../components/PageTitle";
import {SearchInput} from "../../components/Search";
import {StyledButton} from "../../components/StyledButton";
import {RoundedTable} from "../../components/RoundedTable";
import {findTutorialScreenById} from "../../tutorial/TutorialScreens";
import {AdminTutorialModal} from "./AdminTutorialModal";
import {HttpClient} from "../../http/HttpClient";

const columns = (tutorialInProcess, onEdit, onDelete) => {
    return [
        {
            title: "#",
            key: "#",
            width: 70,
            render(_, __, idx) {
                return `${idx + 1}`;
            }
        },
        {
            title: "TITLE",
            dataIndex: "title",
            key: "title",
            width: 200
        },
        {
            title: "SCREEN NAME",
            dataIndex: "screenId",
            width: 200,
            render(screenId) {
                const screen = findTutorialScreenById(screenId);
                return screen ? screen.screenName : "--";
            }
        },
        {
            title: "ORDER ON SCREEN",
            dataIndex: "orderOnScreen",
            width: 200,
            render(order) {
                return order ? order : "--";
            }
        },
        {
            title: "UPLOADED BY",
            key: "uploadedBy",
            width: 200,
            render(_, {userName, userEmail}) {
                return (
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <label style={{color: "var(--secondary-color)", fontSize: 14, fontWeight: 500}}>
                            {userName}
                        </label>
                        <label style={{color: "#747F92", fontSize: 12, fontWeight: 400}}>{userEmail}</label>
                    </div>
                );
            }
        },
        {
            title: "UPDATED AT",
            dataIndex: "updatedAt",
            width: 250,
            sorter: (r1, r2) => (r1.updatedAt < r2.updatedAt ? -1 : 1),
            defaultSortOrder: "descend",
            render(updatedAt) {
                return moment(updatedAt).format("MMMM Do, YYYY HH:mm");
            }
        },
        {
            title: "ACTION",
            key: "action",
            width: 120,
            render(_, tutorial) {
                const menu = (
                    <Menu>
                        <Menu.Item key="editItem">
                            <Button
                                style={{minWidth: 100, color: "var(--secondary-color)"}}
                                type="link"
                                onClick={() => onEdit(tutorial)}>
                                Edit
                            </Button>
                        </Menu.Item>
                        <Menu.Item key="deleteItem">
                            <div
                                onClick={e => {
                                    e.stopPropagation();
                                }}>
                                <Popconfirm
                                    placement="bottomRight"
                                    title={`Remove ${tutorial.title}?`}
                                    onConfirm={() => {
                                        onDelete(tutorial);
                                    }}
                                    icon={<AlertFilled style={{color: "#F06060", fontSize: 16}} />}
                                    okText="Yes"
                                    cancelText="No">
                                    <Button style={{minWidth: 100, color: "var(--secondary-color)"}} type="link">
                                        Delete
                                    </Button>
                                </Popconfirm>
                            </div>
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <Dropdown.Button
                        className="wb-action-drpdwn"
                        style={{border: "none"}}
                        overlay={menu}
                        icon={
                            tutorialInProcess && tutorialInProcess.tutorialId === tutorial.tutorialId ? (
                                <LoadingOutlined />
                            ) : (
                                void 0
                            )
                        }
                    />
                );
            }
        }
    ];
};

export const AdminTutorials = () => {
    const [tutorialInProcess, setTutorialInProcess] = useState(null);
    const [tutorialToEdit, setTutorialToEdit] = useState(null);
    const [showTutorialModal, setShowTutorialModal] = useState(false);
    let [tutorials, setTutorials] = useState(null);
    const [term, setTerm] = useState("");

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const tutorials = await HttpClient.get("/api/tutorials");
                setTutorials(tutorials);
            } catch (e) {
                message.error("Something went wrong, please try again later.");
            }
        });
    }, []);

    const closeModal = tutorial => {
        if (tutorial && tutorialToEdit) {
            updateTutorials(tutorial);
        } else if (tutorial) {
            addNewTutorial(tutorial);
        }

        setShowTutorialModal(false);
        setTutorialInProcess(null);
        setTutorialToEdit(null);
    };

    const updateTutorials = tutorial => {
        setTutorials(
            tutorials.map(t => {
                if (t.tutorialId === tutorial.tutorialId) {
                    return tutorial;
                } else {
                    return t;
                }
            })
        );
    };

    const addNewTutorial = tutorial => {
        setTutorials(tutorials.concat([tutorial]));
    };

    const deleteTutorial = async tutorial => {
        setTutorialInProcess(tutorial);
        try {
            await HttpClient.delete(`/admin/api/tutorials/${tutorial.tutorialId}`);
            setTutorials(tutorials.filter(t => t.tutorialId !== tutorial.tutorialId));
        } catch (e) {
            message.error("Something went wrong, please try again later.");
        }
        setTutorialInProcess(null);
    };

    const editTutorial = tutorial => {
        setTutorialToEdit(tutorial);
        setShowTutorialModal(true);
    };

    if (term) {
        tutorials = tutorials.filter(({title, description, userName, screenId}) => {
            const screen = findTutorialScreenById(screenId) || {};
            const screenName = screen.screenName || "";
            return `${title} ${description} ${userName} ${screenName}`.indexOf(term) >= 0;
        });
    }

    return (
        <div style={{display: "flex", justifyContent: "center", margin: 10, marginBottom: 40}}>
            <AdminTutorialModal onClose={closeModal} visible={showTutorialModal} tutorialToEdit={tutorialToEdit} />
            <div style={{display: "flex", flexDirection: "column", maxWidth: 1400, width: "100%"}}>
                <PageTitleHeader showBack={false}>Tutorials</PageTitleHeader>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 20}}>
                    <SearchInput
                        style={{height: 40, maxWidth: 500, width: "100%"}}
                        onChange={evt => setTerm(evt.target.value)}
                        placeholder="Search tutorial"
                    />
                    <StyledButton
                        onClick={() => setShowTutorialModal(true)}
                        style={{
                            backgroundColor: "white",
                            color: "var(--secondary-color)",
                            borderRadius: 20,
                            height: 40,
                            width: 160,
                            border: "1px solid var(--secondary-color)"
                        }}>
                        Add new tutorial
                    </StyledButton>
                </div>
                <RoundedTable
                    rowKey="name"
                    scroll={{x: "max-content", y: 800}}
                    pagination={false}
                    onRow={tutorial => {
                        return {
                            onClick: () => editTutorial(tutorial)
                        };
                    }}
                    rowClassName={() => "wb-selectable-table-row"}
                    loading={tutorials === null}
                    dataSource={tutorials || []}
                    columns={columns(tutorialInProcess, editTutorial, deleteTutorial)}
                />
            </div>
        </div>
    );
};
