export class UserBranch {
    constructor(me, company) {
        this.me = me;
        this.company = company;
    }

    canSeeOtherBranches(defaultValue = true) {
        if (this.me && this.company && this.me.branch && Array.isArray(this.company.branches)) {
            const branch = this.company.branches.find(({branchId}) => this.me.branch.branchId === branchId);
            return branch.visibility === "all";
        }

        return defaultValue;
    }
}
