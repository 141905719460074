import moment from "moment";
import {AsiaJerusalemTZ} from "../../../event/DaysMapper";
import {DEFAULT_DELIVERY_ARRIVING_TIME_RANGE} from "../../MarketplaceUtils.jsx";

export const getValuesForCreateEvent = (
    formValues,
    deliveryOptions,
    serviceId,
    paymentType,
    offeredPrice,
    company,
    deliveryPrice,
    coupon,
    couponDiscountAmount,
    creditCard,
    upgradeOptionsPrice,
    planBudgetOrder,
    providerDetails
) => {
    const dateFormatted = moment(formValues?.date).format("DD/MM/YYYY");
    const timeFormatted = moment(formValues?.time).format("HH:mm");
    const dtstart = moment(`${dateFormatted} ${timeFormatted}`, "DD/MM/YYYY HH:mm").tz(AsiaJerusalemTZ).valueOf();
    const deliveryArrivingTimeRange = deliveryOptions?.arrivingTimeRange ?? DEFAULT_DELIVERY_ARRIVING_TIME_RANGE;
    const until = moment(dtstart).add(deliveryArrivingTimeRange, "hours").valueOf();
    const modularProducts = formValues?.modularProducts?.filter(product => product?.amount > 0);

    const valuesForCreateEvent = {
        ...formValues,
        serviceId,
        dtstart,
        until,
        paymentType,
        offeredPrice,
        address: formValues?.chosenAddress || company?.address,
        branches: company?.branches?.[0] || null,
        companyPhone: company?.phone,
        deliveryPrice,
        coupon: coupon?.valid ? coupon : null,
        couponDiscountAmount: coupon?.valid ? couponDiscountAmount : null,
        creditCard,
        modularProducts,
        upgradeOptionsPrice,
        deliveryArrivingTimeRange,
        companyDiscountPercentage: company?.ordersDiscount
    };

    if (planBudgetOrder) {
        valuesForCreateEvent.planBudgetOrder = {
            planId: planBudgetOrder?.planId,
            occasionCategoryId: planBudgetOrder?.occasionCategoryId,
            occasionId: planBudgetOrder?.occasionId
        };
    }

    if (providerDetails?.isVoucherProvider) {
        if (formValues?.timeOption?.type === "CUSTOM_DATE") {
            const {date, time} = formValues.timeOption;
            valuesForCreateEvent.dtstart = moment(
                `${date.format("YYYY-MM-DD")} ${time.format("HH:mm")}`,
                "YYYY-MM-DD HH:mm"
            )
                .toDate()
                .getTime();
        }

        valuesForCreateEvent.until = valuesForCreateEvent?.dtstart;
    }
    return valuesForCreateEvent;
};
