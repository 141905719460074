import {OrderStatus} from "../../components/index.jsx";

export const statusLevel = {
    error: "error"
};

export const orderStatusToStepsLevel = {
    [OrderStatus.cancelled]: statusLevel.error,
    [OrderStatus.declined]: statusLevel.error
};
