import {openWindow} from "../../http/WindowOpener.jsx";
import {parse as qsParse} from "query-string";

export const removeOrigin = url => {
    if (url.startsWith("/")) {
        return url;
    }

    const parsedURL = new URL(url);
    const path = parsedURL.pathname + parsedURL.search;
    return path;
};

export const redirectToMainView = (history, location) => {
    const returnTo = qsParse(location.search)?.returnTo;
    if (returnTo) {
        const url = decodeURIComponent(returnTo);
        const previousPath = removeOrigin(url);

        if (previousPath.indexOf("/api/") >= 0) {
            openWindow(previousPath, "_self", true);
        } else {
            history.push(previousPath);
        }
        return;
    }
    history.push("/dashboard");
};
