import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {matchPath, Route} from "react-router";
import {Switch} from "react-router-dom";
import {debounce} from "lodash";
import {CompanyMarketplace} from "./CompanyMarketplace";
import DynamicLayoutPage, {DynamicCompanyHomePage} from "./DynamicLayoutPage";
import {SpecialRoutesOverride} from "./SpecialRedirects";
import {ProductBooking} from "./MarketplaceBooking/ProductBooking";
import {MarketplaceContext} from "./marketplaceContext";
import CompanyMarketplaceProductDetailsRedirect from "./CompanyMarketplaceProductDetailsRedirect";
import {isProductActive, isProductBundleActive, sendAlertToCsmWithEmail} from "./MarketplaceUtils";
import {MarketplaceCategoryItemDetails} from "./MarketplaceCategoryItemDetails";
import {CrossServicePage} from "./CrossServicePage";
import {ProductBundleBooking} from "./MarketplaceBooking/ProductBundleBooking";
import {MIN_USER_FIELD_TRIGGER} from "./MarketplaceBooking/consts";
import {getUserChangedFormValues} from "./MarketplaceBooking/utils";
import {CompanyServices} from "../company/CompanyServices";
import {Providers} from "../provider/Providers";
import {isAllowedAndExistingCategory} from "../center/EXCenterHelper";
import {GenericModal} from "../components";
import {Images} from "../images/Images";
import {ContactPhone} from "../support/ContactPhone";
import {useRequest} from "../utils/hooks";
import {AppContext} from "../AppContext";
import {NOOP} from "../utils/NOOP";
import {MarketplaceProvider} from "./MarketplaceProviderPage/MarketplaceProvider.jsx";
import {CartModularProductContextProvider} from "./PricingCalculator/CartModularProductContext.jsx";
import {NotFoundPage} from "../notFoundPage/NotFoundPage.jsx";

export const CompanyMarketplaceContextProvider = ({history, rootPath, log = NOOP}) => {
    const {
        serviceId,
        providerId,
        productId,
        isProductBundle = null
    } = useMemo(() => {
        const productPath =
            matchPath(history.location.pathname, {
                isExact: false,
                path: `${rootPath}/:serviceId/ProvidersV2/:providerId?/:productId?`
            })?.params ?? {};

        if (Object.keys(productPath ?? {}).length) {
            return productPath;
        }

        const productBundlePath =
            matchPath(history.location.pathname, {
                isExact: false,
                path: `${rootPath}/productBundle/:productId?`
            })?.params ?? {};

        if (Object.keys(productBundlePath ?? {}).length) {
            return {...productBundlePath, isProductBundle: true};
        }

        return {};
    }, [history.location.pathname, rootPath]);

    const isUserJoinBookingPage = useRef(null);
    const currentProduct = useRef(null);
    const userFormValues = useRef(null);
    const [participants, setParticipants] = useState(null);
    const [highlightOrderPanel, setHighlightOrderPanel] = useState(null);
    const [showCsmContactSuggest, setShowCsmContactSuggest] = useState(null);

    const {me, company, services} = useContext(AppContext);

    const sendAlertToCMSs = useCallback(
        debounce(
            async (companyId, price, status, productName, productId, filledValues) =>
                sendAlertToCsmWithEmail(companyId, price, status, productName, productId, filledValues),
            1000
        ),
        []
    );

    const [providerDetails, loadingProviderDetails, providerDetailsError] = useRequest(
        `/api/providers/${providerId}`,
        "get",
        null,
        [providerId],
        !!providerId
    );

    const [productBundle] = useRequest(
        `/api/productBundles/byId/${productId}`,
        "GET",
        null,
        [productId, isProductBundle],
        !!productId && !!isProductBundle
    );

    const product = useMemo(
        () => providerDetails?.services?.find(service => service.productId === productId),
        [providerDetails, productId]
    );

    const goToMainPage = useCallback(() => {
        history.push("/dashboard/services");
    }, [history]);

    const notAllowedForPage = useMemo(() => {
        if (isProductBundle) {
            if (productBundle && !isProductBundleActive(productBundle, me)) {
                return {
                    title: "אופסי, כנראה שהמלאי אזל 😬",
                    text: [
                        "לצערנו לא ניתן להזמין את המוצר כרגע, עובדים קשה מאחורי הקלעים בשביל להמשיך להנגיש את המוצר בהקדם האפשרי!",
                        <span onClick={() => ContactPhone.openWhatsApp()} className="no-content-modal-contact-us">
                            צריכים התייעצות? דברו איתנו בוואטסאפ
                        </span>
                    ]
                };
            }

            return null;
        }

        if (!serviceId || !services) {
            return null;
        }

        if (!isAllowedAndExistingCategory(me, serviceId, services)) {
            return {
                title: "הרשאה לקטגוריה זו אינה פתוחה לך 😬",
                text: ["באפשרותך לפנות אדמין הראשי בארגון שלך למידע נוסף"]
            };
        }

        if (!productId || loadingProviderDetails || (!providerDetails && !providerDetailsError)) {
            return null;
        }

        if (!isProductActive(product, providerDetails, me)) {
            return {
                title: "אופסי, כנראה שהמלאי אזל 😬",
                text: [
                    "לצערנו לא ניתן להזמין את המוצר כרגע, עובדים קשה מאחורי הקלעים בשביל להמשיך להנגיש את המוצר בהקדם האפשרי!",
                    <span onClick={() => ContactPhone.openWhatsApp()} className="no-content-modal-contact-us">
                        צריכים התייעצות? דברו איתנו בוואטסאפ
                    </span>
                ]
            };
        }

        return null;
    }, [
        me,
        company,
        serviceId,
        services,
        productId,
        product,
        loadingProviderDetails,
        providerDetailsError,
        providerDetails,
        productBundle,
        isProductBundle
    ]);

    useEffect(() => {
        if (history.location.pathname.endsWith("/booking")) {
            isUserJoinBookingPage.current = true;
            currentProduct.current = product;
        }

        if (
            isUserJoinBookingPage.current !== null &&
            userFormValues.current !== null &&
            getUserChangedFormValues(userFormValues.current).length >= MIN_USER_FIELD_TRIGGER &&
            !history.location.pathname.endsWith("/booking")
        ) {
            setShowCsmContactSuggest(true);
            sendAlertToCMSs(
                me?.companyId,
                null,
                "המשתמש מילא פרטים ונטש את עמוד ההזמנה",
                currentProduct.current?.productName,
                currentProduct.current?.productId,
                userFormValues.current
            );
            userFormValues.current = null;
            currentProduct.current = null;
        }
    }, [history.location.pathname, product]);

    return (
        <CartModularProductContextProvider>
            <MarketplaceContext.Provider
                value={{
                    serviceId,
                    providerDetails,
                    product,
                    providerId,
                    productId,
                    marketplaceRoot: rootPath,
                    userFormValues,
                    participants,
                    setParticipants,
                    highlightOrderPanel,
                    setHighlightOrderPanel,
                    productBundle,
                    isProductBundle
                }}>
                {notAllowedForPage ? (
                    <GenericModal
                        className="no-content-modal"
                        visible={!!notAllowedForPage}
                        image={Images.outOfStock}
                        title={notAllowedForPage?.title}
                        text={notAllowedForPage?.text ?? []}
                        options={[
                            {
                                id: "goToHomePage",
                                text: "חזרה לעמוד הראשי",
                                onClick: goToMainPage
                            }
                        ]}
                        closeable={false}
                        onClose={goToMainPage}
                    />
                ) : null}

                <GenericModal
                    visible={showCsmContactSuggest}
                    image={Images.areYouSureV2}
                    onClose={() => {
                        setShowCsmContactSuggest(false);
                        log("User close the modal", {isCsmSuggestModal: true});
                    }}
                    className={"csm-suggest-modal"}
                    title={
                        <div>
                            <div>לא מצאת בדיוק את מה שחיפשת?</div>
                            <div>אנחנו כאן בשביל זה</div>
                        </div>
                    }
                    text={[
                        "נשמח לעזור לך להתאים את המארז או האירוע",
                        "באופן המדויק ביותר.",
                        "זמינים בוואטסאפ, במיוחד בשבילך :)"
                    ]}
                    options={[
                        {
                            id: "save",
                            text: "בחזרה לאתר",
                            onClick: () => {
                                setShowCsmContactSuggest(false);
                                log("User click back to website", {isCsmSuggestModal: true});
                            }
                        },
                        {
                            id: "csm-whatsapp",
                            text: "להתחלת שיחה בוואטסאפ",
                            onClick: () => {
                                ContactPhone.openWhatsApp();
                                log("User open whatsapp chat", {isCsmSuggestModal: true});
                            }
                        }
                    ]}
                />

                <Switch>
                    {SpecialRoutesOverride({me, company})}
                    <Route exact={true} path={rootPath}>
                        {me && me.features && me.features.market_place ? (
                            me.features.market_place_v3 ? (
                                <DynamicCompanyHomePage history={history} />
                            ) : (
                                <CompanyMarketplace history={history} />
                            )
                        ) : (
                            <CompanyServices history={history} />
                        )}
                    </Route>

                    <Route exact path={`${rootPath}/:serviceId/providers`} component={Providers} />
                    <Route path={`${rootPath}/crossPage/:pageId`} component={CrossServicePage} />
                    <Route exact={true} path={`${rootPath}/page/:pageId`} component={DynamicLayoutPage} />
                    <Route exact={true} path={`${rootPath}/page`} component={DynamicCompanyHomePage} />
                    {/*<Route exact={true} path={`${rootPath}/:serviceId/providersV2`} component={MarketplaceCategory} />*/}
                    <Route exact={true} path={`${rootPath}/providerPage/:providerId`} component={MarketplaceProvider} />

                    {me?.features?.product_bundles && !notAllowedForPage
                        ? [
                              <Route
                                  exact={true}
                                  path={`${rootPath}/productBundle/:productBundleId`}
                                  component={MarketplaceCategoryItemDetails}
                              />,
                              <Route
                                  exact={true}
                                  path={`${rootPath}/productBundle/:productBundleId/booking`}
                                  component={ProductBundleBooking}
                              />
                          ].map(component => component)
                        : null}

                    <Route
                        exact={true}
                        path={`${rootPath}/product/:productId`}
                        component={CompanyMarketplaceProductDetailsRedirect}
                    />
                    <Route
                        exact={true}
                        path={`${rootPath}/:serviceId/providersV2/:providerId/:productId`}
                        component={MarketplaceCategoryItemDetails}
                    />
                    <Route
                        exact={true}
                        path={`${rootPath}/:serviceId/providersV2/:providerId/:productId/booking`}
                        component={ProductBooking}
                    />
                    <Route component={NotFoundPage} />
                </Switch>
            </MarketplaceContext.Provider>
        </CartModularProductContextProvider>
    );
};
