import {Modal, Select, Popconfirm, message} from "antd";
import {CloseIcon} from "../../../icons";
import React, {useState} from "react";
import {CompanyContent} from "../CompanyContent";
import {StyledButton} from "../../../components/StyledButton";
import {PageSubTitleLabel} from "../../../components/PageTitle";
import {AutoDirectionProvider} from "../../../AutoDirectionProvider";
import {truncate} from "lodash";
import {GoogleAnalytics} from "../../../GoogleAnalytics";

const {Option} = Select;

export const CompanyContentSectionModal = ({onClose, content, onChange}) => {
    const [selectedSection, setSelectedSection] = useState(null);
    const [showConfirmQuestion, setShowConfirmQuestion] = useState(false);

    const onSectionSelect = _sectionId => {
        setSelectedSection(CompanyContent.Sections.find(({sectionId}) => sectionId === _sectionId) || null);
    };

    const doChange = () => {
        if (!selectedSection) {
            return message.error("Please select a category first.", 3);
        }

        const {sectionId, displayName} = selectedSection;
        onChange({
            ...content,
            sectionId,
            serviceName: displayName,
            tags: []
        });

        GoogleAnalytics.event("Content", "Move Section", content.eventId);
    };

    const doClose = () => {
        setShowConfirmQuestion(false);
        setSelectedSection(null);
        onClose();
    };

    return (
        <Modal
            className="wb-modal-radius"
            width={320}
            destroyOnClose={true}
            open={!!content}
            onCancel={doClose}
            footer={null}
            title={null}
            closable={true}
            closeIcon={<CloseIcon fill="#767F90" style={{marginTop: 20}} />}>
            {content ? (
                <div style={{display: "flex", flexDirection: "column", marginTop: 30}}>
                    <PageSubTitleLabel style={{marginBottom: 15}}>
                        <AutoDirectionProvider text={content.sessionName}>
                            {truncate(content.sessionName, {length: 65})}
                        </AutoDirectionProvider>
                    </PageSubTitleLabel>
                    <Select placeholder="Choose category" onSelect={sectionId => onSectionSelect(sectionId)}>
                        {CompanyContent.Sections.filter(({value}) => !!value).map(({sectionId, displayName}) => {
                            return (
                                <Option key={sectionId} value={sectionId}>
                                    {displayName}
                                </Option>
                            );
                        })}
                    </Select>
                    <Popconfirm
                        visible={showConfirmQuestion}
                        title={
                            <span>
                                <strong>Move to {selectedSection ? selectedSection.displayName : ""}</strong>
                                <br />
                                This action will remove the categories assigned:
                                <br />
                                <strong>{content.tags.map(({name}) => name).join(", ")}</strong>
                            </span>
                        }
                        onConfirm={doChange}
                        onCancel={doClose}
                        okText="Move"
                        cancelText="Cancel">
                        <StyledButton
                            style={{
                                color: "white",
                                backgroundColor: "var(--secondary-color)",
                                height: 50,
                                borderRadius: 25,
                                width: "100%",
                                boxShadow: "none",
                                border: "none",
                                marginTop: 20
                            }}
                            disabled={!selectedSection}
                            onClick={() => {
                                if (selectedSection) {
                                    setShowConfirmQuestion(true);
                                }
                            }}>
                            Move
                        </StyledButton>
                    </Popconfirm>
                </div>
            ) : null}
        </Modal>
    );
};
