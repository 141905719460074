import CallToActionModal from "../components/CallToActionModal";
import React from "react";
import {Typography} from "antd";

export const EventOfferSent = ({visible, history, onClose}) => {
    const SubTitle = () => (
        <div style={{display: "flex", flexDirection: "column"}}>
            <Typography.Text style={{textAlign: "center", fontSize: 20, marginBottom: 10, fontWeight: 700}}>
                Your booking is now pending approval.
            </Typography.Text>
            <Typography.Paragraph>
                <ul>
                    <li>We will notify you when someone accepts or declines your order.</li>
                    <li>Meanwhile, you can order another session or check out your company's updated calendar.</li>
                </ul>
            </Typography.Paragraph>
        </div>
    );

    return (
        <CallToActionModal
            imageName="success"
            title="Thank you!"
            closable={true}
            onClose={onClose}
            subTitle={<SubTitle />}
            subTitleStyle={{textAlign: "left"}}
            actionText="Back to categories"
            actionHandler={() => history.push("/dashboard/services")}
            anotherActionText="Go to calendar"
            anotherActionHandler={() => history.push("/dashboard/schedule")}
            visible={visible}
        />
    );
};
