export const downloadFromUrl = async (url, fileName) => {
    const blob = await getBlobFromUrl(url);
    downLoadBlob(blob, fileName);
};

export const getBlobFromUrl = async (url, transformUrlToHttps = true) => {
    try {
        const secureUrl = transformUrlToHttps ? url.replace("http://", "https://") : url;
        const urlData = await fetch(secureUrl);
        return await urlData.blob();
    } catch (e) {
        return null;
    }
};

export const downLoadBlob = (blob, fileName) => {
    const blobUrl = window.URL.createObjectURL(blob);

    const tempLink = document.createElement("a");
    tempLink.href = blobUrl;
    tempLink.setAttribute("download", fileName);
    tempLink.click();
};
