import React, {useContext, useMemo} from "react";
import {minBy} from "lodash";
import {DEFAULT_DELIVERY_MIN_NOTICE} from "./MarketplaceUtils";
import {DeliveryDisclaimers} from "./DeliveryDisclaimers";
import {MarketplaceContext} from "./marketplaceContext";
import {getMergedDeliveryOptions} from "./MarketplaceBooking/utils";

export const MarketplaceItemDetailsDeliveryInformation = () => {
    const {product, providerDetails, isProductBundle, productBundle} = useContext(MarketplaceContext);

    const relevantProductDeliveryOptions = useMemo(() => {
        if (isProductBundle) {
            const productsDeliveryOptions = productBundle?.products.map(product => {
                const foundProviderDetails = productBundle.providers.find(
                    provider => provider.providerId === product.providerId
                );

                return {product, deliveryOptions: getMergedDeliveryOptions(foundProviderDetails, product)};
            });

            return minBy(productsDeliveryOptions, ({deliveryOptions}) => deliveryOptions?.minNotice);
        }

        return {product, deliveryOptions: getMergedDeliveryOptions(providerDetails, product)};
    }, [providerDetails?.deliveryOptions, product?.deliveryOptions, isProductBundle, productBundle]);

    const minNoticeText = useMemo(() => {
        const minNoticeDays = Math.floor(
            (relevantProductDeliveryOptions?.deliveryOptions?.minNotice ?? DEFAULT_DELIVERY_MIN_NOTICE) / 24
        );
        const minNoticeHours =
            (relevantProductDeliveryOptions?.deliveryOptions?.minNotice ?? DEFAULT_DELIVERY_MIN_NOTICE) % 24;

        return `
        ${minNoticeDays > 0 ? ` ${minNoticeDays} ימים` : ""}
        ${minNoticeDays > 0 && minNoticeHours > 0 ? "ו" : " "}
        ${minNoticeHours > 0 ? `${minNoticeHours} שעות` : ""}
        `;
    }, [relevantProductDeliveryOptions]);

    return (
        <div style={{marginBottom: "30px"}}>
            <div
                style={{
                    color: "var(--secondary-color)",
                    fontSize: "16px",
                    direction: "rtl"
                }}>
                <span className="marketplace-category-item-details-delivery-information-title">
                    {"🦸🏻 "}
                    {`הספק מקבל הזמנות בטווח התראה של${minNoticeText} `}
                </span>
                <DeliveryDisclaimers
                    product={relevantProductDeliveryOptions.product}
                    minNoticeText={minNoticeText}
                    deliveryOptions={relevantProductDeliveryOptions.deliveryOptions}
                />
            </div>
        </div>
    );
};
