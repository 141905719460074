import React from "react";
import {groupBy, sumBy} from "lodash";
import {excelSheetsTitle} from "./consts";
import {createWorkbook, createWorksheet, DownloadExcel} from "../utils/excelUtils.js";
import {getOrderPrice} from "../utils/calculationUtils";
import {getLoginStatus} from "../company/CompanyAccountProfile/utils";

export const ExportSubAdminOrdersStatics = async (orders, companyAdmins, companyBudgets) => {
    const workbook = createWorkbook();

    const excelSheets = [
        {
            title: excelSheetsTitle.orders,
            list: orders
        }
    ];

    excelSheets.forEach(({list = [], title}) => {
        const sheet = createWorksheet(workbook, title);
        addColumns(sheet);
        addRows(sheet, list, companyAdmins, companyBudgets);
    });

    await DownloadExcel(workbook, "subAdminOrdersStatics");
};

const addColumns = sheet => {
    sheet.columns = [
        {header: "מחלקה", key: "department"},
        {header: "שם מלא", key: "firstName"},
        {header: "כתובת מייל", key: "email"},
        {header: "טלפון", key: "phone"},
        {header: "כתובת", key: "address"},
        {header: "יתרת תקציב (כולל מעמ)", key: "remainingBudget"},
        {header: "תקציב שנוצל בתקופת הזמן (כולל מעמ)", key: "usedBudget"},
        {header: "סטטוס התחברות", key: "loginStatus"}
    ];
};

const addRows = (sheet, orders, companyAdmins, companyBudgets) => {
    const ordersToSubAdmin = groupBy(orders, order => order?.creatingUserId);

    const rows = companyAdmins.map(companyUser => {
        const isCompanyUserHasOrders = Object.hasOwn(ordersToSubAdmin, companyUser.userId);
        const companyUserOrdersBudgets = companyBudgets?.filter(userBudget => userBudget.userId === companyUser.userId);
        const companyUserBudgetAmount = sumBy(companyUserOrdersBudgets, budget => budget.amount);
        const loginStatus = getLoginStatus(companyUser);
        const usedBudget = isCompanyUserHasOrders
            ? sumBy(ordersToSubAdmin[companyUser.userId], order => getOrderPrice(order))
            : 0;

        return [
            companyUser.department,
            companyUser.firstName,
            companyUser.email,
            companyUser.phone,
            companyUser.address,
            companyUserBudgetAmount,
            usedBudget,
            loginStatus
        ];
    });

    sheet.addRows(rows);
};
