import {Collapse, ConfigProvider, DatePicker, Form} from "antd";
import classNames from "classnames";
import moment from "moment";
import React, {useCallback, useState} from "react";
import {expandIconController, MarketplaceSeparatorLine} from "../../marketplace/MarketplaceHelper";

const {Panel} = Collapse;

const EditCreatedOrderDate = ({date, onChange}) => {
    const [createdAt, setCreateAt] = useState(moment(date));

    const onDateChange = useCallback(
        momentDate => {
            setCreateAt(momentDate);
            onChange(momentDate);
        },
        [date, onChange]
    );

    return (
        <div>
            <Collapse
                ghost={true}
                accordion={true}
                className={classNames("marketplace-booking-collapse", "admin-edit-order-modal-create-at-panel")}
                expandIconPosition="left"
                expandIcon={({isActive}) => expandIconController(isActive)}>
                <Panel
                    key="שינוי תאריך ביצוע ההזמנה"
                    showArrow={true}
                    header={
                        <div>
                            <div>📅 תאריך ביצוע ההזמנה</div>
                            <div>{createdAt?.format("HH:mm DD-MM-YYYY")} </div>
                        </div>
                    }>
                    <ConfigProvider direction="rtl">
                        <Form.Item required={true}>
                            <DatePicker
                                defaultValue={createdAt}
                                format="HH:mm DD-MM-YYYY"
                                allowClear={false}
                                danger={true}
                                onChange={onDateChange}
                                showTime
                            />
                        </Form.Item>
                    </ConfigProvider>
                </Panel>
            </Collapse>
            <MarketplaceSeparatorLine />
        </div>
    );
};

export default EditCreatedOrderDate;
