import Cutlery from "../../../images/cutlery.svg";
import Bag from "../../../images/bag.svg";
import People from "../../../images/people.svg";
import {MainServices} from "../../../consts.js";
import {CategoryTextToOriginalCategories} from "../consts.js";

export const panelOptions = {
    homePage: "homePage",
    happyHours: MainServices.HAPPY_HOURS,
    packages: MainServices.PACKAGES,
    workshops: MainServices.WORKSHOPS
};

export const panelsOptions = [
    {
        key: panelOptions.homePage,
        text: "⭐ ️עדכונים בולטים",
        className: panelOptions.homePage
    },
    {
        key: panelOptions.happyHours,
        text: "כיבוד והאפי האוורס",
        image: Cutlery,
        className: panelOptions.happyHours
    },
    {
        key: panelOptions.packages,
        text: "מתנות",
        image: Bag,
        className: panelOptions.packages
    },
    {
        key: panelOptions.workshops,
        text: "פעילות גיבוש",
        image: People,
        className: panelOptions.workshops
    }
];

export const getMainCategories = categories => {
    return Object.keys(CategoryTextToOriginalCategories).filter(key => {
        const value = CategoryTextToOriginalCategories[key];
        return categories.some(category => value.includes(category));
    });
};
