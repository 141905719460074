import React, {useContext} from "react";
import {ConfirmationRequiredWrapper, PageSubTitleLabel, StyledButton} from "../../../components/index.jsx";
import "../../../admin/upload/admin-upload.css";
import {DeleteOutlined, EditOutlined, EyeOutlined, PushpinOutlined, SolutionOutlined} from "@ant-design/icons";
import {Popover, Switch} from "antd";

import {AutoDirectionProvider} from "../../../AutoDirectionProvider.jsx";
import {StringBuilder} from "../../../AppUtils.js";
import {PriceTypes} from "../../../admin/upload/PriceSelectorHelper.jsx";
import classnames from "classnames";
import "../../../admin/upload/admin-upload.css";
import "./provider-products.css";
import {ServiceIdToName} from "../../../consts.js";
import {AppContext} from "../../../AppContext.jsx";
import {createProductLink} from "../../../marketplace/MarketplaceHelper.jsx";
import {FormInputV2} from "../../../components/form/index.jsx";
import common from "@well-b/well-b-shared";
import {NOOP} from "../../../utils/NOOP.jsx";

export const ProviderProductsList = ({
    providerInfo,
    editProductClicked,
    removeProduct,
    onCreateProduct,
    onIsActiveChange,
    onIsPrimaryChange,
    onUpdateProductName,
    products
}) => {
    const {me, services} = useContext(AppContext);

    return (
        <>
            {products.map(product => {
                const isNewProduct = product.isNew && !product.productId;
                return (
                    <AutoDirectionProvider key={product.productId} text="מוצרים">
                        <div className="admin-upload-product-container">
                            <div className="admin-upload-product-name">
                                {isNewProduct ? (
                                    <>
                                        <FormInputV2
                                            onChange={evt => onUpdateProductName(evt.target.value)}
                                            placeholder={`שם מוצר`}
                                        />
                                        {onCreateProduct && onCreateProduct !== NOOP ? (
                                            <StyledButton
                                                className="admin-upload-add-product-button"
                                                onClick={() => onCreateProduct(product)}>
                                                שמירה
                                            </StyledButton>
                                        ) : null}
                                    </>
                                ) : (
                                    <>
                                        {product.productName}
                                        <Switch
                                            disabled={
                                                !providerInfo?.approved ||
                                                !!common.isProductValid(product)?.error ||
                                                !onIsActiveChange
                                            }
                                            onChange={isActive =>
                                                onIsActiveChange(product.productId, isActive, product.updatedAt)
                                            }
                                            checked={product.isActive}
                                            title="על מנת לשנות סטטוס מוצר יש להכנס לתוך המוצר"
                                        />
                                    </>
                                )}
                            </div>
                            <div className="admin-upload-product-separate-line" />
                            <div className="admin-upload-product-bottom-line">
                                <div>
                                    {services
                                        ? (product?.services ?? [])
                                              .map(service => {
                                                  const {serviceId, categoryName} = service;
                                                  if (serviceId != null || categoryName != null) return serviceId;
                                                  return ServiceIdToName?.[service] ?? service;
                                              })
                                              .filter(el => el !== null)
                                              .join(" / ")
                                        : null}
                                </div>
                                <div className="admin-upload-product-bottom-line-actions">
                                    {!isNewProduct && !!product?.allowedCompanies?.length ? (
                                        <>
                                            <Popover
                                                content={new StringBuilder()
                                                    .append("המוצר מוצג ל-")
                                                    .append(product?.allowedCompanies?.length)
                                                    .append("חברות")
                                                    .toString()}>
                                                <SolutionOutlined />
                                            </Popover>
                                        </>
                                    ) : null}

                                    {!isNewProduct &&
                                    onIsPrimaryChange &&
                                    onIsPrimaryChange !== NOOP &&
                                    product?.isActive &&
                                    product.chosenPriceType === PriceTypes.ByModular ? (
                                        <ConfirmationRequiredWrapper
                                            classname={classnames("admin-upload-product-bottom-line-icon", {
                                                "admin-upload-product-bottom-line-icon-highlight": !!product?.isPrimary
                                            })}
                                            subTitle="שימו לב! לחיצה על אישור תסמן את המוצר כמוצר ראשי שיופיע בעמוד שותף"
                                            onYes={() => onIsPrimaryChange(product.productId, true, product.updatedAt)}>
                                            <PushpinOutlined />
                                        </ConfirmationRequiredWrapper>
                                    ) : null}
                                    <>
                                        {isNewProduct && removeProduct && removeProduct !== NOOP ? (
                                            <DeleteOutlined onClick={() => removeProduct(product)} />
                                        ) : (
                                            <ConfirmationRequiredWrapper
                                                classname="admin-upload-product-bottom-line-icon"
                                                onYes={() => removeProduct(product)}>
                                                <DeleteOutlined />
                                            </ConfirmationRequiredWrapper>
                                        )}
                                    </>
                                    {!isNewProduct && editProductClicked && editProductClicked !== NOOP ? (
                                        <>
                                            <div className="admin-upload-product-bottom-line-icon">
                                                <EditOutlined
                                                    id="edit-product-button"
                                                    onClick={() => editProductClicked(product)}
                                                />
                                            </div>

                                            <div className="admin-upload-product-bottom-line-icon">
                                                <EyeOutlined
                                                    onClick={() => {
                                                        const productAndProvider = {
                                                            service: product,
                                                            provider: {providerId: providerInfo.providerId}
                                                        };

                                                        const url = createProductLink(productAndProvider, {
                                                            me,
                                                            services
                                                        });
                                                        window.open(url, "_blank", "noreferrer");
                                                    }}
                                                />
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </AutoDirectionProvider>
                );
            })}
        </>
    );
};
