export const SuccessImageLink =
    "https://res.cloudinary.com/well-b-prod/video/upload/e_loop:9/Assets/SavedProviderGif.gif";

export const shippingProvidersOptions = {
    regular: "regular",
    wellbDelivery: "wellb"
};

export const shippingProvidersOptionsToName = {
    [shippingProvidersOptions.regular]: "שילוח רגיל",
    [shippingProvidersOptions.wellbDelivery]: "שילוח עם וולבי"
};

export const MaxShippingProvidersDeliveryPriceWithVAT = 118;

export const requiredProviderFields = {
    shouldPaymentBySupply: "shouldPaymentBySupply",
    businessName: "businessName",
    businessNumber: "businessNumber",
    phone: "phone",
    email: "email",
    description: "description",
    netPaymentTerms: "netPaymentTerms",
    assignPartner: "assignPartner",
    contactFirstName: "contactFirstName",
    contactLastName: "contactLastName",
    contactPhoneNumber: "contactPhoneNumber",
    dealerType: "dealerType"
};
export const providerFormFields = {
    ...requiredProviderFields
};

export const providerFormFieldsToName = {
    [providerFormFields.shouldPaymentBySupply]: "תשלום לפי מועד האספקה",
    [providerFormFields.businessName]: "שם בית העסק",
    [providerFormFields.businessNumber]: "מס׳ עוסק או ח.פ",
    [providerFormFields.phone]: "טלפון ראשי לקבלת הזמנות (עבור שירות לקוחות)",
    [providerFormFields.email]: "מייל ראשי (כניסה לאזור ספק)",
    [providerFormFields.description]: "תיאור בית העסק",
    [providerFormFields.netPaymentTerms]: "תנאי שוטף",
    [providerFormFields.assignPartner]: "מנהל/ת שותף",
    [providerFormFields.contactFirstName]: "שם פרטי",
    [providerFormFields.contactLastName]: "שם משפחה",
    [providerFormFields.contactPhoneNumber]: "מס׳ טלפון (רק ספרות)",
    [providerFormFields.dealerType]: "סוג עוסק"
};

export const deliveryFormFields = {
    maxShippingProvidersDeliveryPrice: "maxShippingProvidersDeliveryPrice",
    shippingProviders: "shippingProviders"
};
