import React from "react";
import {List} from "antd";
import {MenuOutlined} from "@ant-design/icons";
import {
    SortableContainer as _SortableContainer,
    SortableElement as _SortableElement,
    SortableHandle
} from "react-sortable-hoc";

const DragHandle = SortableHandle(() => <MenuOutlined style={{cursor: "grab", color: "#999"}} />);

const SortableItem = _SortableElement(({key, value, className}) => {
    return (
        <List.Item key={key} className={className} header={<DragHandle />}>
            <List.Item.Meta avatar={<DragHandle />} />
            {value}
        </List.Item>
    );
});

const SortableContainer = _SortableContainer(({children, className}) => {
    return (
        <List className={className} bordered>
            {children}
        </List>
    );
});

export const SortableList = ({onSortEnd, className, itemClassName, children}) => {
    return (
        <SortableContainer onSortEnd={onSortEnd} className={className} useDragHandle={true}>
            {children?.map((value, index) => (
                <SortableItem className={itemClassName} key={`item-${index}`} index={index} value={value} />
            ))}
        </SortableContainer>
    );
};
