import React, {useContext, useEffect, useState} from "react";
import {message} from "antd";
import {OutlookIcon} from "../../../icons";
import {StyledButton} from "../../../components";
import {AppContext} from "../../../AppContext";
import {openWindow} from "../../../http/WindowOpener";
import {HttpClient} from "../../../http/HttpClient";

export const OutlookCalendarSyncButton = ({onSync, appointmentId, eventId, date, time, style}) => {
    const {me} = useContext(AppContext);

    let query = `eventId=${eventId}`;

    if (date && time) {
        query = `${query}&date=${date}&time=${time}`;
    }

    if (appointmentId) {
        query = `${query}&appointmentId=${appointmentId}`;
    }

    const doAuthentication = () => {
        openWindow(`/api/microsoft/login?${query}`, "_self");
    };

    const [tokenValid, setTokenValid] = useState(false);

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const {isTokenValid} = await HttpClient.get("/api/users/calendar/valid");
                setTokenValid(isTokenValid);
            } catch (e) {
                message.error("Something went wrong, please try again later.");
            }
        });
    });

    const hasCalendarPermissions = () => {
        return (
            tokenValid &&
            me.calendarApi &&
            me.calendarApi.type === "microsoft" &&
            me.calendarApi.tokenObject &&
            me.calendarApi.tokenObject.scope &&
            me.calendarApi.tokenObject.scope.toLowerCase().indexOf("calendars.readwrite") >= 0
        );
    };

    const doSync = () => {
        if (hasCalendarPermissions()) {
            onSync();
        } else {
            doAuthentication();
        }
    };

    return (
        <StyledButton
            onClick={() => doSync()}
            style={{
                width: 250,
                border: "1px solid var(--secondary-color)",
                color: "var(--secondary-color)",
                ...style
            }}
            icon={<OutlookIcon style={{width: 25, height: 25, marginRight: 10}} />}>
            Sync to Outlook calendar
        </StyledButton>
    );
};
