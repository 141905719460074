import React, {useMemo, useState} from "react";
import {message, Spin, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {EventBus} from "../bus/EventBus";
import {NOOP} from "../utils/NOOP";
import "./simple-uploader.css";

export const SimpleUploader = ({children, accept, onFinish, beforeUpload = NOOP, showMessageOnFinish = true}) => {
    const [loading, setLoading] = useState(false);

    const uploadProps = useMemo(
        () => ({
            action: "/api/upload",
            name: "file",
            accept: accept ?? "application/pdf,image/png,image/jpeg",
            showUploadList: false,
            withCredentials: true,
            onChange: async info => {
                if (info.file.status === "done") {
                    setLoading(false);
                    const {imageId, imageUrl} = info.file.response;
                    const fileName = info.file.name;
                    onFinish({fileUrl: imageUrl, fileId: imageId, fileName});

                    if (showMessageOnFinish) {
                        message.success(`קובץ הועלה בהצלחה`, 4);
                    }
                } else if (info.file.status === "error") {
                    setLoading(false);
                    EventBus.triggerError(
                        "server-error",
                        {
                            content: {
                                title: "Upload Failed!",
                                hideSubTitle: true,
                                description: "הייתה בעיה עם העלאת הקובץ"
                            }
                        },
                        info.file.response.error || `${info.file.name} file upload failed.`
                    );
                } else {
                    setLoading(true);
                }
            },
            beforeUpload: beforeUpload
        }),
        [onFinish, showMessageOnFinish, accept, beforeUpload]
    );

    return (
        <Upload {...uploadProps}>
            <span className="simple-uploader">
                {loading ? <Spin /> : <UploadOutlined />}
                {children}
            </span>
        </Upload>
    );
};
