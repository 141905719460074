import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {message} from "antd";
import moment from "moment";
import OrdersCalendarPage from "./OrdersCalendarPage";
import {OrdersCalendarContext} from "./OrdersCalendarContext";
import {useRequest} from "../utils/hooks";
import {HttpClient} from "../http/HttpClient";
import notifications from "../utils/Notifications";
import {getLogger} from "../Logger";
import {AppContext} from "../AppContext";

const OrdersCalendarPageContextProvider = ({history}) => {
    const {me} = useContext(AppContext);
    const [orders, setOrders] = useState([]);
    const [plannedOccasions, setPlannedOccasions] = useState([]);
    const [currentMonthPlan, setCurrentMonthPlan] = useState([]);
    const [month, setMonth] = useState();

    const [currentMonthPlanFromServer, _, error] = useRequest(
        `/api/budgetPlan/betweenDates?` + `from=${month}` + `&to=${month}` + `&populate=true`,
        "GET",
        null,
        [month],
        !!(month && me.features?.calculator),
        data => {
            return data?.[0];
        }
    );

    const log = useMemo(
        () => getLogger({month, planId: currentMonthPlanFromServer?.planId}, "OrdersCalendarPage"),
        [month, currentMonthPlanFromServer]
    );

    useEffect(() => {
        setCurrentMonthPlan(currentMonthPlanFromServer);
    }, [currentMonthPlanFromServer]);

    const linkEvent = useCallback(
        (planedOccasionId, plannedCategoryId, orderEventId) => {
            const {error} = HttpClient.safePut("/api/budgetPlan/changeOccasionField", {
                planId: currentMonthPlan.planId,
                occasionCategoryId: plannedCategoryId,
                occasionId: planedOccasionId,
                field: "linkedEvent",
                value: orderEventId
            });

            if (error) {
                message.error("שגיאה בעת שיוך אירוע");
                return;
            }

            log("Link event by dragging", {
                orderId: orderEventId,
                categoryId: plannedCategoryId,
                occasionId: planedOccasionId
            });

            setCurrentMonthPlan(prev => {
                const occasionToUpdate = prev.categories[plannedCategoryId]?.occasions?.find(
                    ({occasionId}) => occasionId === planedOccasionId
                );

                if (!occasionToUpdate) {
                    return prev;
                }

                occasionToUpdate.linkedEvent = orderEventId;
                return {...prev};
            });

            if (orderEventId) {
                notifications.openUndoNotification("אירוע שויך בהצלחה", () =>
                    linkEvent(planedOccasionId, plannedCategoryId)
                );
            }
        },
        [currentMonthPlan, log]
    );

    const changeEventStartTime = useCallback(
        (planedOccasionId, plannedCategoryId, newStartTime) => {
            const {error} = HttpClient.safePut("/api/budgetPlan/changeOccasionField", {
                planId: currentMonthPlan.planId,
                occasionCategoryId: plannedCategoryId,
                occasionId: planedOccasionId,
                field: "actualStartDate",
                value: newStartTime
            });

            if (error) {
                message.error("שגיאה בעת שינוי תאריך האירוע");
                return;
            }

            let oldActualStartDate;

            setCurrentMonthPlan(prev => {
                const occasionToUpdate = prev.categories[plannedCategoryId]?.occasions?.find(
                    ({occasionId}) => occasionId === planedOccasionId
                );

                if (!occasionToUpdate) {
                    return prev;
                }

                oldActualStartDate = occasionToUpdate.actualStartDate;
                occasionToUpdate.actualStartDate = newStartTime;
                return {...prev};
            });

            log("Changed planed event start time", {
                newStartTime: moment(newStartTime).format("DD/MM/YYYY"),
                oldStartTime: oldActualStartDate ? moment(oldActualStartDate).format("DD/MM/YYYY") : null,
                categoryId: plannedCategoryId,
                occasionId: planedOccasionId
            });

            notifications.openUndoNotification("תאריך אירוע שונה", () =>
                changeEventStartTime(planedOccasionId, plannedCategoryId, oldActualStartDate)
            );
        },
        [currentMonthPlan, log]
    );

    return (
        <OrdersCalendarContext.Provider
            value={{
                month,
                setMonth,
                currentMonthPlan,
                orders,
                setOrders,
                plannedOccasions,
                setPlannedOccasions,
                linkEvent,
                changeEventStartTime,
                log
            }}>
            <OrdersCalendarPage history={history} />
        </OrdersCalendarContext.Provider>
    );
};

export default OrdersCalendarPageContextProvider;
