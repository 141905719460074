import React from "react";
import {BesideCarouselArrowWithDisable} from "../../MarketplaceHelper.jsx";
import AllOptions from "../../../images/allOptionsIcon.svg";

const getInitialSlide = (itemsPerRow, searchText, searchTags) => {
    let initialSlide = searchTags.length - itemsPerRow;
    if (!searchText || initialSlide < 0) {
        return Math.max(initialSlide, 0);
    }

    const index = searchTags.findIndex(searchTag => searchTag.name === searchText);
    if (index >= 0) {
        if (index === itemsPerRow) {
            initialSlide = searchTags.length - itemsPerRow * 2;
        } else if (index < itemsPerRow) {
            initialSlide = searchTags.length - itemsPerRow;
        } else {
            const result = itemsPerRow * Math.ceil(index / itemsPerRow);
            initialSlide = result >= searchTags.length ? 0 : searchTags.length - result;
        }
    }
    return searchTags.length > itemsPerRow ? Math.max(initialSlide, 0) : searchTags.length;
};

export const getItemPerRow = width => {
    let itemsPerRow = 14;
    if (width.width <= 1300) {
        itemsPerRow = 6;
    } else if (width.width <= 1500) {
        itemsPerRow = 9;
    } else if (width.width <= 1600) {
        itemsPerRow = 10;
    }
    return itemsPerRow;
};

export const generatePropsNew = (searchTags, width, searchText) => {
    const itemsPerRow = getItemPerRow(width);
    const initialSlide = getInitialSlide(itemsPerRow, searchText, searchTags);
    return {
        dots: false,
        infinite: false,
        prevArrow: <BesideCarouselArrowWithDisable direction={"prev"} />,
        nextArrow: <BesideCarouselArrowWithDisable direction={"next"} />,
        accessibility: false,
        arrows: true,
        className: "tags",
        speed: 1000,
        rtl: true,
        slidesToShow: itemsPerRow,
        slidesToScroll: 4,
        initialSlide: initialSlide
    };
};

export const skeletonItems = 15;

export const defaultTag = {
    name: "כל האפשרויות",
    isDefault: true,
    image: {imageUrl: AllOptions}
};
