import React from "react";
import {SketchColorPicker} from "../../components/ColorPicker";
import {CloseIcon} from "../../icons";
import {Modal} from "antd";
import {defaultPickerColors} from "../../company/branding/ColorPalette";

export const EXCenterColorPickerModal = ({onClose, visible, color, onColorPick, style}) => {
    return (
        <Modal
            width={140}
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            bodyStyle={{padding: 0}}
            footer={null}
            title={null}
            closable={true}
            closeIcon={<CloseIcon fill="#ccc" />}>
            <div style={{position: "relative"}}>
                <SketchColorPicker
                    colors={defaultPickerColors}
                    color={color}
                    onColorPick={onColorPick}
                    style={{position: "relative", left: 0, width: 140, ...style}}
                    closeStyle={{display: "none"}}
                />
            </div>
        </Modal>
    );
};
