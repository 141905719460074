import React, {useContext} from "react";
import {AppLogo} from "../AppLogo";
import {withRouter} from "react-router";
import {StyledButton} from "../components";
import {EventBus} from "../bus/EventBus";
import {Divider} from "antd";
import AppBranchesSelect from "../AppBranchesSelect";
import {AppContext} from "../AppContext";
import {CloseCircleFilled} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {isBranchesPage} from "./EXCenterHelper";

const EXCenterEditModeTopBar = ({history}) => {
    const {company, me} = useContext(AppContext);

    if (!company) {
        return null;
    }

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "white",
                width: "100%",
                height: 80
            }}>
            <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                <AppLogo
                    style={{
                        position: "relative",
                        marginLeft: 20,
                        left: 0
                    }}
                    onClick={() => history.push("/center/branches/auto")}
                />
                {me.isCompanyMaster && !isBranchesPage(location) ? (
                    <>
                        <Divider
                            className="wb-ms-visible-only"
                            type="vertical"
                            style={{height: 20, marginLeft: 20, marginRight: 20}}
                        />
                        <AppBranchesSelect className="wb-ms-visible-only" branches={company.branches} />
                    </>
                ) : null}
                <StyledButton
                    className="wb-ms-visible-only"
                    onClick={() => EventBus.trigger("branding:open")}
                    style={{
                        borderRadius: 15,
                        height: 30,
                        backgroundColor: "rgba(19,194,150,0.15)",
                        boxShadow: "none",
                        border: "none",
                        color: "#13C296",
                        fontSize: 14,
                        width: 105,
                        marginLeft: 20
                    }}>
                    Select theme
                </StyledButton>
            </div>
            <Link to="/dashboard" style={{height: "100%"}}>
                <div
                    style={{
                        width: 180,
                        height: "100%",
                        backgroundColor: "rgba(0,94,218,0.1)",
                        color: "#005EDA",
                        fontSize: 16,
                        fontWeight: 700,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    <CloseCircleFilled style={{fontSize: 14, color: "#005EDA", marginRight: 12}} />
                    <span>Back to admin</span>
                </div>
            </Link>
        </div>
    );
};

export default withRouter(EXCenterEditModeTopBar);
