import {FoodRequestsIds, FoodRequestsShortIdToName} from "../../../marketplace/ProductsFilterModal/consts.jsx";

export const excelSubProductsKeys = {
    name: "name",
    description: "description",
    subProductCategory: "subProductCategory",
    price: "price",
    commission: "commission",
    priceWithoutCommission: "priceWithoutCommission",
    catalogNumber: "catalogNumber",
    isActive: "isActive",
    id: "id",
    [FoodRequestsIds.veganFriendly]: FoodRequestsIds.veganFriendly,
    [FoodRequestsIds.vegetarianFriendly]: FoodRequestsIds.vegetarianFriendly,
    [FoodRequestsIds.glutenFree]: FoodRequestsIds.glutenFree,
    [FoodRequestsIds.lactoseFree]: FoodRequestsIds.lactoseFree,
    [FoodRequestsIds.furOption]: FoodRequestsIds.furOption
};

export const excelSubProductsKeyToHeader = {
    [excelSubProductsKeys.id]: "מזהה מיני מוצר",
    [excelSubProductsKeys.catalogNumber]: "מק״ט",
    [excelSubProductsKeys.name]: "שם מוצר",
    [excelSubProductsKeys.description]: "תיאור המוצר",
    [excelSubProductsKeys.subProductCategory]: "קטגוריה",
    [excelSubProductsKeys.veganFriendly]: FoodRequestsShortIdToName[FoodRequestsIds.veganFriendly],
    [excelSubProductsKeys.vegetarianFriendly]: FoodRequestsShortIdToName[FoodRequestsIds.vegetarianFriendly],
    [excelSubProductsKeys.glutenFree]: FoodRequestsShortIdToName[FoodRequestsIds.glutenFree],
    [excelSubProductsKeys.lactoseFree]: FoodRequestsShortIdToName[FoodRequestsIds.lactoseFree],
    [excelSubProductsKeys.furOption]: FoodRequestsShortIdToName[FoodRequestsIds.furOption],
    [excelSubProductsKeys.price]: "מחיר ללקוח",
    [excelSubProductsKeys.commission]: "עמלה (פלוס מע״מ)",
    [excelSubProductsKeys.priceWithoutCommission]: "מחיר קנייה",
    [excelSubProductsKeys.isActive]: "סטטוס"
};

export const headerToExcelSubProductsKey = Object.fromEntries(
    Object.entries(excelSubProductsKeyToHeader).map(([key, value]) => [value, key])
);

export const FoodRequestAnswerOption = {
    YES: "כן",
    NONE: "-"
};

export const isActiveAnswerOption = {
    ACTIVE: "פעיל גלובלית",
    NONE_ACTIVE: "לא פעיל גלובלית"
};

export const ExcelRowBase = 2;
