import React from "react";
import {Card, Modal} from "antd";
import {isHTML} from "../marketplace/MarketplaceUtils";

export const EventDetailsModal = ({visible, event, onClose}) => {
    const isMobileView = () => {
        return window.innerWidth < 550;
    };

    const renderProductOrderInfo = event => {
        return (
            <>
                <span
                    style={{
                        color: "var(--secondary-color)",
                        fontSize: "16px",
                        direction: "rtl",
                        fontWeight: "bold",
                        marginBottom: "20px",
                        marginTop: "20px",
                        display: "flex",
                        marginRight: "20px"
                    }}>
                    {event.productName}
                </span>

                {event.productDescription ? (
                    <span
                        style={{
                            direction: "rtl",
                            marginRight: "20px",
                            textAlign: "right",
                            fontSize: "16px",
                            textDecoration: "underline"
                        }}>
                        תיאור המוצר
                    </span>
                ) : null}
                {isHTML(event.productDescription) ? (
                    <div
                        style={{
                            color: "var(--secondary-color)",
                            fontSize: "16px",
                            direction: "rtl",
                            overflowY: isMobileView() ? "auto" : void 0,
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "right",
                            marginRight: "20px",
                            marginLeft: "20px",
                            whiteSpace: "pre-wrap"
                        }}
                        className="content"
                        dangerouslySetInnerHTML={{__html: event.productDescription}}></div>
                ) : (
                    <span
                        style={{
                            color: "var(--secondary-color)",
                            fontSize: "16px",
                            direction: "rtl",
                            overflowY: isMobileView() ? "auto" : void 0,
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "right",
                            marginRight: "20px",
                            marginLeft: "20px",
                            whiteSpace: "pre-wrap"
                        }}>
                        {event.productDescription}
                    </span>
                )}

                {event.productPackageInfo ? (
                    <span
                        style={{
                            direction: "rtl",
                            marginRight: "20px",
                            textAlign: "right",
                            fontSize: "16px",
                            textDecoration: "underline"
                        }}>
                        הרכב החבילה
                    </span>
                ) : null}
                {isHTML(event.productPackageInfo) ? (
                    <div
                        style={{
                            color: "var(--secondary-color)",
                            fontSize: "16px",
                            direction: "rtl",
                            overflowY: isMobileView() ? "auto" : void 0,
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "right",
                            marginRight: "20px",
                            marginLeft: "20px",
                            whiteSpace: "pre-wrap"
                        }}
                        className="content"
                        dangerouslySetInnerHTML={{__html: event.productPackageInfo}}></div>
                ) : (
                    <span
                        style={{
                            color: "var(--secondary-color)",
                            fontSize: "16px",
                            direction: "rtl",
                            overflowY: isMobileView() ? "auto" : void 0,
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "right",
                            marginRight: "20px",
                            marginLeft: "20px",
                            whiteSpace: "pre-wrap"
                        }}>
                        {event.productPackageInfo}
                    </span>
                )}
            </>
        );
    };

    return (
        <Modal
            centered={true}
            width={isMobileView() ? 450 : 600}
            height={isMobileView() ? 600 : 830}
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={onClose}
            footer={null}
            maskClosable={true}
            title={null}
            closable={true}>
            <Card
                bordered={true}
                bodyStyle={{borderRadius: "5px"}}
                style={{
                    boxSizing: "border-box",
                    height: isMobileView() ? "550px" : "750px",
                    width: isMobileView() ? "400px" : "500px",
                    border: "1px solid rgba(131,152,166,0.2)",
                    borderRadius: "5px",
                    marginBottom: "10px",
                    marginLeft: isMobileView() ? "0px" : "20px",
                    display: isMobileView() ? "contents" : void 0,
                    backgroundColor: "rgba(131,152,166,0.1)"
                }}
                cover={
                    <div
                        style={{
                            position: "relative",
                            overflowY: "auto",
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: "5px",
                            height: "100%"
                        }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: isMobileView() ? "350px" : "100%",
                                borderRadius: "5px 5px 0 0",
                                height: "100%"
                            }}>
                            <span
                                style={{
                                    color: "#8398A6",
                                    fontSize: "16px",
                                    marginBottom: isMobileView() ? "5px" : "20px",
                                    marginTop: "18px",
                                    alignSelf: "center",
                                    fontWeight: "bold"
                                }}>
                                טופס אישור הזמנה
                            </span>
                            <span
                                style={{
                                    color: "#ED7FA6",
                                    fontSize: isMobileView() ? "16px" : "24px",
                                    marginBottom: "16px",
                                    fontWeight: "bold",
                                    alignSelf: "center",
                                    direction: "rtl"
                                }}>
                                {"איזה כיף! לקוח של well-b בחר בכם 👻 "}
                            </span>

                            {event?.orders?.length
                                ? event.orders.map(order => renderProductOrderInfo(order))
                                : renderProductOrderInfo(event)}
                        </div>
                    </div>
                }></Card>
        </Modal>
    );
};
