import {EXCenterProperties} from "../utils/EXCenterProperties";
import {EXCenterSectionDesignMenu} from "../control/EXCenterSectionDesignMenu";
import {EXCenterSectionSeeMoreButton} from "./EXCenterSectionSeeMoreButton";
import React, {useContext} from "react";
import {EXCenterContext} from "../EXCenterContext";
import {AppContext} from "../../AppContext";

export const EXCenterSectionContainer = ({
    sectionId,
    sectionDesignInfo,
    children,
    hideSeeMore = false,
    editItems,
    style,
    coverSize
}) => {
    const {me} = useContext(AppContext);
    const {theme} = useContext(EXCenterContext);

    if (me.type === "employee" && !sectionDesignInfo.display) {
        return null;
    }

    return (
        <div
            className="wb-ss-top wb-center-section"
            id={`wb-section-${sectionId}`}
            style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: EXCenterProperties.Section.Top,
                width: "100%",
                ...style
            }}>
            <EXCenterSectionDesignMenu
                sectionId={sectionId}
                coverSize={coverSize}
                editItems={{
                    title: true,
                    buttonNoLink: true,
                    ...editItems
                }}
            />
            <div
                className="wb-ss-no-padding wb-center-section-padding"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    opacity: EXCenterProperties.Section.opacityByDisplay(sectionDesignInfo)
                }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        flexWrap: "wrap",
                        maxWidth: 1200,
                        width: "100%"
                    }}>
                    <div
                        style={{
                            paddingLeft: 20,
                            paddingRight: 20,
                            marginBottom: 25,
                            width: "100%",
                            display: "flex",
                            justifyContent: "center"
                        }}>
                        <span
                            className="wb-ss-section-title  wb-ss-full-width"
                            style={{
                                fontSize: 34,
                                fontWeight: 900,
                                color: theme.textColor
                            }}>
                            {sectionDesignInfo.title}
                        </span>
                    </div>
                    {children}
                    {!hideSeeMore ? (
                        <EXCenterSectionSeeMoreButton sectionId={sectionId} designInfo={sectionDesignInfo} />
                    ) : null}
                </div>
            </div>
        </div>
    );
};
