import {downloadFromUrl} from "../../utils/downloadFromUrl";
import {
    EMPLOYEE_RECEIVER_EXCEL_EXAMPLE_FILE_URL,
    EMPLOYEE_RECEIVER_EXAMPLE_FILE_NAME,
    voucherExcelProperty,
    voucherExcelPropertyToHebrew
} from "./consts";
import {getCellText} from "../../utils/excelUtils.js";
import {StringBuilder} from "../../AppUtils.js";
import common from "@well-b/well-b-shared";

export const downloadTemplate = async () => {
    await downloadFromUrl(EMPLOYEE_RECEIVER_EXCEL_EXAMPLE_FILE_URL, EMPLOYEE_RECEIVER_EXAMPLE_FILE_NAME);
};

export const validateExcel = async workbook => {
    const sheet = workbook.worksheets[0];
    let rowsCount = 0;

    const HEADERS_ROW = 2;
    const END_COLUMN_INDEX = 4;

    const defaultValueForRequiredColumns = "חובה";

    const headerRows = sheet.getRow(HEADERS_ROW).values;

    const errorParagraph = [];

    const tableTitles = Object.values(voucherExcelProperty);
    const rows = [];

    sheet.eachRow(({values: row, number}) => {
        if (!row.slice(0, END_COLUMN_INDEX).filter(cell => cell).length) {
            return;
        }

        if (number <= HEADERS_ROW) return;

        const filterRow = row.slice(1, END_COLUMN_INDEX + 1).reduce((previousValue, currentValue, currentIndex) => {
            previousValue[tableTitles[currentIndex]] = currentValue;
            return previousValue;
        }, {});

        const errorMessage = [];
        const [phone, email, receiverName] = [
            filterRow[voucherExcelProperty.phone],
            filterRow[voucherExcelProperty.email],
            filterRow[voucherExcelProperty.receiverName]
        ];

        if (typeof receiverName !== "string") {
            errorMessage.push(
                new StringBuilder().append("חסר").append(voucherExcelPropertyToHebrew.receiverName).toString()
            );
        }

        if (phone && typeof phone === "string" && !common.isValidPhoneMobileNumber(phone)) {
            errorMessage.push("הטלפון שהוזן אינו תקין");
        }

        if (typeof phone !== "string" && typeof email !== "string") {
            errorMessage.push(
                new StringBuilder()
                    .append("חסר")
                    .append(voucherExcelPropertyToHebrew.phone)
                    .append("או")
                    .append(voucherExcelPropertyToHebrew.email)
                    .toString()
            );
        }

        if (filterRow[voucherExcelProperty.greeting] && typeof filterRow[voucherExcelProperty.greeting] !== "string") {
            errorMessage.push("פרטי הברכה שהוזנו לא תקינים");
        }

        if (errorMessage.length) {
            errorParagraph.push(
                new StringBuilder()
                    .append("בשורה")
                    .append(number)
                    .append("-")
                    .append(errorMessage.join(", "))
                    .toString()
            );
        }

        rowsCount++;
        rows.push(filterRow);
    });

    if (rowsCount < 1) {
        errorParagraph.push("לא הוזנו פרטי מקבלי המתנה");
    }

    return {rowsCount: rowsCount, error: errorParagraph, rows};
};
