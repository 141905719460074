import {BaloonsIcon, CloseIcon} from "../../icons";
import {Form, Modal, message, Tooltip} from "antd";
import React, {useContext, useState} from "react";
import {FormTextArea} from "../../components/form";
import {StyledButton} from "../../components/StyledButton";
import {EXCenterBirthdayAvatar} from "./EXCenterBirthdayAvatar";
import {HttpClient} from "../../http/HttpClient";
import moment from "moment";
import {EXCenterContext} from "../EXCenterContext";
import {GoogleAnalytics} from "../../GoogleAnalytics";
import {AppContext} from "../../AppContext";

const BirthdayQuickWishButton = props => {
    return (
        <NotAllowedTooltip>
            <StyledButton
                {...props}
                style={{backgroundColor: "#DEDEF6", color: "#595AD4", height: 40, borderRadius: 20, margin: 5}}>
                {props.children}
            </StyledButton>
        </NotAllowedTooltip>
    );
};

const NotAllowedTooltip = ({className, children}) => {
    const {me} = useContext(AppContext);
    return me.type === "company" ? (
        <Tooltip className={className} overlayStyle={{minWidth: 270}} title="Only employees can send a birthday wish.">
            {children}
        </Tooltip>
    ) : (
        children
    );
};

const quickWishesButtons = [
    "Happy birthday!",
    "Have a fabulous birthday!",
    "May all your wishes come true!",
    "Have a great one!"
];

const BirthdayWishForm = ({employee, onFinish, loading}) => {
    const {me} = useContext(AppContext);
    const {theme} = useContext(EXCenterContext);
    const [form] = Form.useForm();

    const quickWish = wishText => {
        onFinish({wishText, isQuick: true});
    };

    const isCompanyUser = me.type === "company";

    return (
        <Form form={form} onFinish={onFinish}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", padding: 30}}>
                <EXCenterBirthdayAvatar style={{marginBottom: 20}} width={136} height={136} employee={employee} />
                <div style={{textAlign: "center", fontSize: 20, color: theme.textColor, fontWeight: 500}}>
                    {employee.firstName} {employee.lastName}
                </div>
                <div
                    style={{
                        textAlign: "center",
                        fontSize: 20,
                        marginTop: 10,
                        height: 30,
                        color: theme.textColor,
                        fontWeight: 300
                    }}>
                    {moment(employee.birthday, "DD/MM/YYYY").format("D.M")}
                </div>
                <Form.Item
                    name="wishText"
                    rules={[
                        {
                            required: true,
                            message: "Please enter your wish."
                        }
                    ]}
                    style={{marginBottom: 20, marginTop: 20, width: "100%"}}>
                    <FormTextArea
                        autoSize={{minRows: 8, maxRows: 8}}
                        style={{width: "100%"}}
                        placeholder="Write something here for their birthday..."
                    />
                </Form.Item>
                <div
                    style={{
                        fontSize: 20,
                        fontWeight: 500,
                        color: theme.textColor,
                        textAlign: "center",
                        marginBottom: 20
                    }}>
                    Or send a quicky
                </div>
                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center"}}>
                    {quickWishesButtons.map(wishText => (
                        <BirthdayQuickWishButton disabled={isCompanyUser} onClick={() => quickWish(wishText)}>
                            {wishText}
                        </BirthdayQuickWishButton>
                    ))}
                </div>
            </div>
            <Form.Item>
                <NotAllowedTooltip className="wb-not-allowed-tooltip">
                    <StyledButton
                        disabled={isCompanyUser}
                        htmlType="submit"
                        loading={loading}
                        style={{
                            borderRadius: 0,
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                            width: "100%",
                            border: "none",
                            height: 60,
                            color: "white",
                            backgroundColor: "#00CF82"
                        }}>
                        Send your wishes
                    </StyledButton>
                </NotAllowedTooltip>
            </Form.Item>
        </Form>
    );
};

export const EXCenterBirthdayWishModal = ({visible, onClose, employee}) => {
    const {me} = useContext(AppContext);
    const [loading, setLoading] = useState(false);

    const addWish = async ({wishText, isQuick}) => {
        setLoading(true);
        try {
            await HttpClient.put("/api/wishes", {
                toId: employee.userId,
                date: moment().format("YYYY-MM-DD"),
                wishText
            });
            onClose();
            message.success({
                content: `${employee.firstName} got your wish! Thank you!`,
                duration: 3,
                icon: <BaloonsIcon style={{width: 24, height: 24, marginRight: 8}} />
            });
            GoogleAnalytics.event("EX Center - Birthday", isQuick ? "Quick Wish" : "Send Wish", me.userId);
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setLoading(false);
    };

    return (
        <Modal
            style={{top: 10}}
            width={530}
            className="wb-modal-radius wb-birthdays-modal"
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            bodyStyle={{padding: 0}}
            footer={null}
            title={null}
            closable={true}
            closeIcon={<CloseIcon fill="#767F90" style={{marginTop: 20}} />}>
            {visible ? <BirthdayWishForm loading={loading} employee={employee} onFinish={addWish} /> : null}
        </Modal>
    );
};
