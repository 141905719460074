import React, {useCallback, useMemo, useState} from "react";
import {getColumns} from "./columns";
import PageTitleHeader from "../../../components/PageTitle";
import {RoundedTable, SearchInputWithIcon} from "../../../components";
import {useRequest} from "../../../utils/hooks";
import {HttpClient} from "../../../http/HttpClient";

const AdminCalculatorPreset = () => {
    const [searchInput, setSearchInput] = useState("");

    const [calculatorPresets, loadingCalculatorPresets, _, fetchCalculatorPresets] = useRequest(
        "/admin/api/happinessCalculator/preset"
    );

    // const createOrEditOccasion = useCallback(async (newOccasion) => {
    //     newOccasion?.occasionId ?
    //         await HttpClient.safePost(`/admin/api/consumptionOccasions/${newOccasion.occasionId}`, newOccasion)
    //         : await HttpClient.safePut("/admin/api/consumptionOccasions", newOccasion);
    //
    //     fetchConsumptionOccasions();
    //     setCreateEditOccasionInfo(null);
    // }, [fetchConsumptionOccasions]);

    const deletePreset = useCallback(
        async presetId => {
            await HttpClient.safeDelete(`/admin/api/happinessCalculator/preset/${presetId}`);
            fetchCalculatorPresets();
        },
        [fetchCalculatorPresets]
    );

    const columns = useMemo(() => getColumns(deletePreset), [deletePreset]);

    const filteredPresets = useMemo(
        () => calculatorPresets?.filter(({name}) => name.includes(searchInput?.trim())) ?? [],
        [calculatorPresets, searchInput]
    );

    return (
        <>
            <PageTitleHeader showBack={false}>ניהול פריסטים</PageTitleHeader>
            <div className="admin-consumption-occasions-top-bar">
                <SearchInputWithIcon
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    placeholder="חפש פריסט"
                />
            </div>

            <RoundedTable
                rowKey="presetId"
                className="admin-consumption-occasions-table"
                pagination={false}
                loading={loadingCalculatorPresets}
                dataSource={filteredPresets}
                columns={columns}
            />
        </>
    );
};

export default AdminCalculatorPreset;
