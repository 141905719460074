import React from "react";
import shortid from "shortid";
import {isEmpty} from "lodash";
import {PRICING_ROWS} from "./PriceSelectorHelper";
import {FoodRequests} from "../../marketplace/ProductsFilterModal/consts";

export const languages = [
    {hebrewName: "עברית", englishName: "hebrew"},
    {hebrewName: "אנגלית", englishName: "english"},
    {hebrewName: "ספרדית", englishName: "spanish"},
    {hebrewName: "צרפתית", englishName: "french"},
    {hebrewName: "רוסית", englishName: "russian"}
];

export const allLocations = {
    general: {
        values: ["כל הארץ"]
    },
    north: {
        title: "הצפון",
        values: [
            "כל אזור הצפון",
            "אזור חיפה והסביבה",
            "אזור הקריות",
            "אזור עכו, נהריה והסביבה",
            "אזור הכנרת והסביבה",
            "אזור כרמיאל והסביבה",
            "אזור שפרעם, נצרת והסביבה",
            "אזור הגליל התחתון",
            "רמת הגולן",
            "אזור זכרון יעקב וחוף הכרמל",
            "אזור חדרה והסביבה",
            "אזור קיסריה והסביבה",
            "אזור יקנעם, טבעון והסביבה",
            "אזור עמק בית שאן",
            "אזור עפולה והעמקים",
            "אזור רמות מנשה"
        ]
    },
    center: {
        title: "המרכז",
        values: [
            "כל אזור המרכז",
            "אזור תל אביב יפו",
            "אזור ראשון לציון והסביבה",
            "אזור חולון בת ים",
            "אזור רמת גן גבעתיים",
            "פתח תקוה והסביבה",
            "אזור ראש העין והסביבה",
            "בקעת אונו",
            "אזור רמלה לוד",
            "אזור בני ברק וגבעת שמואל",
            "אזור עמק איילון",
            "אזור שוהם והסביבה",
            "אזור מודיעין והסביבה"
        ]
    },
    south: {
        title: "הדרום",
        values: [
            "כל אזור דרום",
            "אזור באר שבע והסביבה",
            "אזור אילת והערבה",
            "אזור הר הנגב",
            "אזור הנגב המערבי",
            "אזור דרום ים המלח"
        ]
    },
    jerusalem: {
        title: "ירושלים",
        values: [
            "כל אזור ירושלים",
            "ירושלים",
            "אזור בית שמש והסביבה",
            "אזור הרי יהודה, מבשרת והסביבה",
            "אזור מעלה אדומים והסביבה"
        ]
    },
    haSharon: {
        title: "השרון",
        values: [
            "כל אזור השרון",
            "אזור נתניה והסביבה",
            "אזור רמת השרון והרצליה",
            "אזור רעננה כפר סבא",
            "אזור הוד השרון והסביבה"
        ]
    },
    westBank: {
        title: "איו״ש והירדן",
        values: [
            "כל אזור יהודה, שומרון ובקעת הירדן",
            "אזור דרום הר חברון",
            "אזור ישובי השומרון",
            "אזור גוש עציון",
            "אזור בקעת הירדן וצפון ים המלח",
            "אזור אריאל וישובי יהודה"
        ]
    },
    haShfela: {
        title: "השפלה",
        values: [
            "כל אזור שפלה מישור חוף דרומי",
            "אזור נס ציונה ורחובות",
            "אזור אשדוד, אשקלון והסביבה",
            "אזור גדרה, יבנה והסביבה",
            "אזור קרית גת והסביבה",
            "אזור השפלה"
        ]
    }
};

export const FAST_DELIVERY_COMPANIES = {
    YANGO: "Yango",
    TAPUZ: "Tapuz"
};

export const MAIN_AREAS_PLACE_ID = {
    TEL_AVIV: "ChIJsTJP_rZLHRURQNXiBl_Sf9s",
    CENTRAL: "ChIJ1dI8PjMVHRURwtHEUjGbcmU",
    NORTH: "ChIJdVSqdIk3HBURL7ZpTXHxZaU",
    HAIFA: "ChIJRegNdUy6HRURCSRuL32qiX0",
    JERUSALEM: "ChIJI57rru3EAhURPVFStGthoUw",
    SOUTH: "ChIJ-_NYfvhxABURGRCoNYCkMvU"
}

export const MAIN_AREAS = [
    {
        displayName: "מחוז תל אביב",
        placeId: MAIN_AREAS_PLACE_ID.TEL_AVIV
    },
    {
        displayName: "מחוז המרכז",
        placeId: MAIN_AREAS_PLACE_ID.CENTRAL
    },
    {
        displayName: "מחוז הצפון",
        placeId: MAIN_AREAS_PLACE_ID.NORTH
    },
    {
        displayName: "מחוז חיפה",
        placeId: MAIN_AREAS_PLACE_ID.HAIFA
    },
    {
        displayName: "מחוז ירושלים",
        placeId: MAIN_AREAS_PLACE_ID.JERUSALEM
    },
    {
        displayName: "מחוז דרום",
        placeId: MAIN_AREAS_PLACE_ID.SOUTH
    }
];

export const locationInArea = (loc, area) => {
    const resKeys = Object.keys(allLocations).filter(
        key => allLocations[key].title === area && allLocations[key].values.includes(loc)
    );
    return resKeys && resKeys.length;
};

export const getLocationsMainAreas = () => {
    return Object.keys(allLocations)
        .map(area => {
            return allLocations[area];
        })
        .filter(el => el.title && el.title !== "איו״ש והירדן");
};

export const getMinNoticeTitle = id => {
    const minNotice = MinNoticeOptions.find(o => o.id === id);
    if (isEmpty(minNotice)) {
        return id;
    }
    return minNotice.title;
};

export const getMinNoticeId = title => {
    const minNotice = MinNoticeOptions.find(o => o.title === title);
    if (isEmpty(minNotice)) {
        return title;
    }
    return minNotice.id;
};

export const MinNoticeOptions = [
    {
        id: "today",
        title: "הספק מקבל הזמנות מהיום להיום"
    },
    {
        id: "nextDay",
        title: "הספק מקבל הזמנות בהתראה קצרה - מהיום למחר"
    },
    {
        id: "next3Days",
        title: "הספק מקבל הזמנות בהתראה של 3 ימי עסקים"
    },
    {
        id: "next5Days",
        title: "הספק מקבל הזמנות בהתראה של 5 ימי עסקים"
    }
];

export const ActivityManagement = [
    {
        id: "together",
        title: "פעילות כקבוצה אחת הכוללת את כל המשתתפים יחד"
    },
    {
        id: "sameTimeSeparated",
        title: "פעילות בקבוצות קטנות נפרדות בו זמנית"
    },
    {
        id: "differentTimeSeparated",
        title: "פעילות בקבוצות קטנות נפרדות, לא באותו זמן"
    },
    {
        id: "separated",
        title: "פעילות בנפרד לכל משתתף, לא באותו זמן"
    }
];

export const ActivityExtras = [
    {
        id: "projector",
        title: "הפעילות עושה שימוש במקרן"
    },
    {
        id: "sodas",
        title: "הפעילות כוללת שתיה קלה"
    },
    {
        id: "hotDrink",
        title: "הפעילות כוללת שתיה חמה"
    },
    {
        id: "refreshments",
        title: "הפעילות כוללת כיבוד"
    },
    {
        id: "handicappedAccess",
        title: "הפעילות נגישה לנכים"
    }
];

export const translateFilterOption = option => {
    let translatedOption = FoodRequests.find(request => request.title === option);
    if (!translatedOption) {
        translatedOption = PresentationsRequest.find(request => request.title === option);
    }
    if (translatedOption) {
        return translatedOption.id;
    }
    return option;
};

export const translateFoodRequest = req => {
    return FoodRequests.find(request => request.id === req).title;
};

export const translatePresentation = req => {
    return PresentationsRequest.find(request => request.id === req).title;
};

export const kosherOptions = ["בית עסק כשר (עם תעודת כשרות)", "לא בית עסק כשר", "בית עסק שבו חלק מהסניפים כשרים"];

export const PresentationsRequest = [
    {
        id: "noServiceArrange",
        title: "סידור עצמי"
    },
    {
        id: "serviceArrange",
        title: "סידור ע''י הספק"
    },
    {
        id: "noServicePost",
        title: "עמדה ללא איש שירות"
    },
    {
        id: "servicePost",
        title: "עמדה עם איש שירות"
    }
];

export const AllPossibleRequest = [...FoodRequests, ...PresentationsRequest];

export const getLocations = () => {
    return Object.keys(allLocations).map(zone => {
        return allLocations[zone].values;
    });
};

export const ProviderTypeEnum = {
    BRAND: "organization",
    INDIVIDUAL: "individual"
};

export const ProviderDealerTypes = {
    licensed: "licensed",
    exempt: "exempt",
    llc: "llc"
};

export const ProviderNetPaymentTermsTypes = {
    Net38: 38,
    Net54: 54,
    Net68: 68
};

export const getDealerTypeReversed = type => {
    switch (type) {
        case "licensed":
            return "Licensed dealer (עוסק מורשה)";
        case "exempt":
            return "Exempt dealer (עוסק פטור)";
        case "llc":
            return "LLC (חברה בע״מ)";
    }
};

export const getDefaultPricingTableCell = () => ({
    min: null,
    max: null,
    price: null
});

const getDefaultPricingTableData = () => [...Array(PRICING_ROWS)].map(getDefaultPricingTableCell);

export const defaultPricingTable = () => {
    return {
        byPerson: getDefaultPricingTableData(),
        byPlatte: getDefaultPricingTableData(),
        byUnit: getDefaultPricingTableData(),
        byPackage: getDefaultPricingTableData(),
        byModular: {minPrice: null, subProducts: []}
    };
};

export const defaultState = () => {
    return {
        productId: shortid.generate(),
        serviceIds: []
    };
};
