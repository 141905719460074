import React from "react";
import ProductsContext from "./ProductsContext";
import {useRequest} from "../../utils/hooks";

export const ProductsProvider = ({children}) => {
    const [allProducts, isLoadingAllProducts, productsError, fetchAllProducts] = useRequest(
        `/api/products/getProducts?skipCache=${true}`,
        "get",
        null,
        [],
        false,
        null,
        []
    );

    return (
        <ProductsContext.Provider value={{allProducts, isLoadingAllProducts, productsError, fetchAllProducts}}>
            {children}
        </ProductsContext.Provider>
    );
};
