import React, {useContext, useState} from "react";
import {MenuOutlined} from "@ant-design/icons";
import {Drawer} from "antd";
import EXCenterMenuProvider from "./EXCenterMenuProvider";
import {AppContext} from "../../AppContext";
import {EXCenterProfileAvatar} from "../profile/EXCenterProfileAvatar";
import {Link} from "react-router-dom";
import {EXCenterContext} from "../EXCenterContext";

export const EXCenterMobileSideNav = () => {
    const {me} = useContext(AppContext);
    const {theme} = useContext(EXCenterContext);
    const [visible, setVisible] = useState(false);

    const profileTopBar = (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 300,
                color: theme.textColor,
                cursor: me.type === "employee" ? "pointer" : "default"
            }}>
            <EXCenterProfileAvatar style={{width: 30, height: 30}} /> {me.firstName || me.companyName}
        </div>
    );

    return (
        <div
            className="wb-ss-visible-only wb-side-nav"
            onClick={() => setVisible(!visible)}
            style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: 20,
                cursor: "pointer"
            }}>
            <MenuOutlined />
            <Drawer
                placement="right"
                closable={false}
                destroyOnClose={true}
                onClose={() => setVisible(!visible)}
                visible={visible}
                style={{opacity: 0.95}}
                title={me.type === "employee" ? <Link to={"/center/profile"}>{profileTopBar}</Link> : profileTopBar}>
                <EXCenterMenuProvider mode="inline" withExtraItems={me.type === "employee"} />
            </Drawer>
        </div>
    );
};
