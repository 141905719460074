import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ConfigProvider, Form, message, Modal, Select, Tooltip} from "antd";
import {set} from "lodash";
import {
    AreYouSureModal,
    EditableInput,
    PageSubTitleLabel,
    PageTitleHeader,
    SearchableSelect,
    StyledButton
} from "../../../components";
import {ProviderPortfolioUploadModal} from "../../../provider/ProviderPortfolioUploadModal";
import {defaultState} from "../AdminUploadUtils";
import {PortfolioIcon, TrashIcon} from "../../../icons";
import {AutoDirectionProvider} from "../../../AutoDirectionProvider";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {StepperInput} from "../../../wizards/components/StepperInput";
import {AdminProductSearchTags} from "../AdminProductSearchTags";
import {FormInput} from "../../../components/form";
import {useRequest} from "../../../utils/hooks";

const {Option} = Select;

const changeTypes = {
    SELECT: "select",
    DESELECT: "Deselect"
};

export const AdminUploadNewVoucherProductModal = ({
    editProduct,
    services,
    onCancel,
    onNewProduct,
    onEditProduct,
    visibility
}) => {
    const [suppliersFromServer] = useRequest("/api/buyme/items", "GET", null, [visibility], visibility);
    const [formValues, setFormValues] = useState(defaultState());
    const [form] = Form.useForm();
    const [portfolioVisibility, setPortfolioVisibility] = useState(false);
    const [searchTags, setSearchTags] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedProductName, setSelectedProductName] = useState(null);
    const [selectedPriceList, setSelectedPriceList] = useState([]);
    const [subCategories, setSubCategories] = useState(null);
    const [description, setDescription] = useState(null);
    const [exitProduct, setExitProduct] = useState(false);

    const voucherPrices = useMemo(() => {
        const foundPackages =
            suppliersFromServer && suppliersFromServer.find(({supplierId}) => formValues?.voucherId === supplierId);
        return foundPackages?.packages;
    }, [formValues?.voucherId, suppliersFromServer, selectedProductName]);

    const onFormValueChange = useCallback((field, val) => {
        setFormValues(prevValues => set({...prevValues}, field, val));
    }, []);

    const onCategorySelect = useCallback(
        value => {
            const newCategorySelected = selectedCategory.concat([value]);
            setSelectedCategory(newCategorySelected);
        },
        [selectedCategory]
    );

    const onCategoryDeselect = useCallback(
        value => {
            const newCategorySelected = selectedCategory.filter(category => category !== value);
            setSelectedCategory(newCategorySelected);
        },
        [selectedCategory]
    );

    const onPriceListChange = useCallback(
        (changeType, itemsId) => {
            if (changeType === changeTypes.SELECT) {
                const priceProperty = voucherPrices.find(({items_id}) => items_id === itemsId);
                setSelectedPriceList(currentPriceList => [...currentPriceList, priceProperty]);
            } else if (changeType === changeTypes.DESELECT) {
                setSelectedPriceList(currentPriceList =>
                    currentPriceList?.filter(({items_id}) => items_id !== itemsId)
                );
            }
        },
        [voucherPrices]
    );

    const onDescriptionChange = useCallback(html => {
        setDescription(html);
        onFormValueChange("description", html);
    }, []);

    const onFinish = useCallback(
        values => {
            const productDetails = {...values, ...formValues};
            productDetails.searchTags = searchTags;
            productDetails.chosenPriceType = "byVoucher";
            set(productDetails, "pricingTable.byVoucher", selectedPriceList ?? []);
            productDetails.productName = selectedProductName;

            if (subCategories) {
                productDetails.subCategories = subCategories;
            }

            if (editProduct) {
                onEditProduct(productDetails);
            } else {
                onNewProduct(productDetails);
            }
        },
        [formValues, searchTags, selectedPriceList, selectedProductName]
    );

    useEffect(() => {
        form.resetFields();
        setFormValues(defaultState());

        if (editProduct) {
            let tempEditProduct = {...editProduct};

            setFormValues({...defaultState(), ...tempEditProduct});
            form.setFieldsValue({...defaultState(), ...tempEditProduct});

            setDescription(tempEditProduct.description);
            setSubCategories(tempEditProduct.subCategories);
            setSearchTags(tempEditProduct.searchTags);
            setSelectedPriceList(tempEditProduct.pricingTable?.byVoucher ?? []);

            const supply = suppliersFromServer?.find(supply => supply.supplierId === tempEditProduct.voucherId);
            setSelectedProductName(supply?.supplier_name);
        } else {
            setDescription(null);
            setSubCategories([]);
        }
    }, [visibility, editProduct, suppliersFromServer]);

    return (
        <Modal
            centered={true}
            width={800}
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visibility}
            onCancel={() => setExitProduct(true)}
            footer={null}
            maskClosable={false}
            getContainer={false}
            title={null}
            closable={true}>
            <div style={{display: "flex", width: "100%", flexDirection: "column", alignItems: "center"}}>
                <PageTitleHeader showBack={false}>{editProduct ? "עריכת מוצר" : "הוספת מוצר"}</PageTitleHeader>
                <PageSubTitleLabel
                    style={{marginBottom: 10, fontSize: 20, color: "#ED7FA6", textDecoration: "underline"}}>
                    {"פרטי המוצר"}
                </PageSubTitleLabel>
                <AreYouSureModal
                    visible={exitProduct}
                    onClose={() => setExitProduct(false)}
                    title="?למחוק את השינויים שלא נשמרו"
                    yesText={"מחיקה"}
                    noText={"ביטול"}
                    saveStyle={{color: "white", backgroundColor: "#F06060"}}
                    onYes={() => {
                        setExitProduct(false);
                        onCancel();
                    }}
                    onNo={() => setExitProduct(false)}
                />
                <ProviderPortfolioUploadModal
                    onClose={() => setPortfolioVisibility(false)}
                    onSave={portfolioImages => {
                        onFormValueChange("portfolioImages", portfolioImages);
                        setPortfolioVisibility(false);
                    }}
                    providerDetails={formValues}
                    providerNonExist={true}
                    visible={portfolioVisibility}
                />
                <Form
                    form={form}
                    style={{maxWidth: 800, marginTop: 10, width: "100%"}}
                    onFinish={onFinish}
                    initialValues={defaultState()}
                    onValuesChange={(_, allValues) => {
                        setFormValues(prev => ({
                            ...prev,
                            ...allValues
                        }));
                    }}
                    scrollToFirstError={true}
                    onFinishFailed={({errorFields}) => {
                        message.error("חסרים לך כמה שדות, שים לב!", 3);
                    }}>
                    <ConfigProvider direction="rtl">
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Form.Item>
                                <StyledButton
                                    icon={<PortfolioIcon style={{marginRight: 11, marginTop: 2}} />}
                                    style={{
                                        borderRadius: 25,
                                        backgroundColor: "#273444",
                                        color: "white",
                                        margin: 5,
                                        width: 160
                                    }}
                                    onClick={() => setPortfolioVisibility(true)}>
                                    הוספת תמונות
                                </StyledButton>
                            </Form.Item>
                        </div>
                        <AutoDirectionProvider
                            text={"קטגוריה"}
                            style={{
                                marginBottom: 15,
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column"
                            }}>
                            <label style={{fontSize: 16, color: "var(--secondary-color)", marginLeft: 520}}>
                                {"קטגוריה"}{" "}
                                <Tooltip title="יש באפשרותכם לשייך מוצר/שירות לקטגוריה אחת או יותר עבורה הוא מתאים.">
                                    <QuestionCircleOutlined style={{cursor: "pointer"}} />
                                </Tooltip>
                            </label>
                            <Form.Item
                                name="services"
                                rules={[
                                    {
                                        required: true,
                                        message: "שדה חובה"
                                    }
                                ]}>
                                <SearchableSelect
                                    mode="multiple"
                                    onDeselect={value => onCategoryDeselect(value)}
                                    onSelect={value => onCategorySelect(value)}
                                    className="wb-profession-selection"
                                    style={{marginTop: 10, width: "600px"}}
                                    placeholder="קטגוריה">
                                    {services?.map(service => {
                                        const {serviceId, categoryName} = service;
                                        return (
                                            <Option key={serviceId} value={serviceId}>
                                                {categoryName}
                                            </Option>
                                        );
                                    })}
                                </SearchableSelect>
                            </Form.Item>
                        </AutoDirectionProvider>
                        <AutoDirectionProvider
                            text={"שם מותג - בעברית"}
                            style={{
                                marginBottom: 15,
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column"
                            }}>
                            <label style={{fontSize: 16, color: "var(--secondary-color)", marginLeft: 515}}>
                                {"שם מותג - בעברית"}{" "}
                                <Tooltip title="בחרו שם שיווקי למוצר/שירות שיסביר מה אתם מוכרים בצורה הטובה ביותר">
                                    <QuestionCircleOutlined style={{cursor: "pointer"}} />
                                </Tooltip>
                            </label>
                            <Form.Item name="brandName">
                                <StepperInput style={{marginTop: 10, width: "600px"}} placeholder="שם מותג - בעברית" />
                            </Form.Item>
                        </AutoDirectionProvider>
                        <AutoDirectionProvider
                            text={"שם מותג -באנגלית"}
                            style={{
                                marginBottom: 15,
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column"
                            }}>
                            <label style={{fontSize: 16, color: "var(--secondary-color)", marginLeft: 515}}>
                                {"שם מותג -באנגלית"}{" "}
                                <Tooltip title="בחרו שם שיווקי למוצר/שירות שיסביר מה אתם מוכרים בצורה הטובה ביותר">
                                    <QuestionCircleOutlined style={{cursor: "pointer"}} />
                                </Tooltip>
                            </label>
                            <Form.Item name="engBrandName">
                                <StepperInput style={{marginTop: 10, width: "600px"}} placeholder="שם מותג -באנגלית" />
                            </Form.Item>
                        </AutoDirectionProvider>
                        <AutoDirectionProvider
                            text={"שם השובר"}
                            style={{
                                marginBottom: 15,
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column"
                            }}>
                            <label style={{fontSize: 16, color: "var(--secondary-color)", marginLeft: 520}}>
                                {"שם השובר"}{" "}
                                <Tooltip title="אנא בחרו שובר אחד מתוך רשימת השוברים של ביימי">
                                    <QuestionCircleOutlined style={{cursor: "pointer"}} />
                                </Tooltip>
                            </label>
                            <Form.Item
                                name="voucherId"
                                rules={[
                                    {
                                        required: true,
                                        message: "שדה חובה"
                                    }
                                ]}>
                                <SearchableSelect
                                    className="wb-profession-selection"
                                    style={{marginTop: 10, width: "600px"}}
                                    placeholder="שם השובר"
                                    onSelect={supplierId => {
                                        const supply = suppliersFromServer.find(
                                            supply => supply.supplierId === supplierId
                                        );
                                        setSelectedProductName(supply?.supplier_name);
                                    }}>
                                    {suppliersFromServer?.map(supply => (
                                        <Option key={supply.supplierId} value={supply.supplierId}>
                                            {supply.supplier_name}
                                        </Option>
                                    ))}
                                </SearchableSelect>
                            </Form.Item>
                        </AutoDirectionProvider>

                        <AutoDirectionProvider
                            text={"מחירי שוברים"}
                            style={{
                                marginBottom: 15,
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column"
                            }}>
                            <label style={{fontSize: 16, color: "var(--secondary-color)", marginLeft: 494}}>
                                {"מחירי שוברים"}{" "}
                                <Tooltip title="אנא בחרו את מחירי השוברים שיהיו פעילים">
                                    <QuestionCircleOutlined style={{cursor: "pointer"}} />
                                </Tooltip>
                            </label>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: "שדה חובה"
                                    }
                                ]}>
                                <SearchableSelect
                                    mode="multiple"
                                    onSelect={selectedPrice => onPriceListChange(changeTypes.SELECT, selectedPrice)}
                                    onDeselect={selectedPrice => onPriceListChange(changeTypes.DESELECT, selectedPrice)}
                                    value={selectedPriceList?.map(({items_id}) => items_id)}
                                    className="wb-profession-selection"
                                    style={{marginTop: 10, width: "600px"}}
                                    placeholder="מחירי שוברים">
                                    {voucherPrices?.map(price => (
                                        <Option key={price.items_id} value={price.items_id}>
                                            {price.redeemableValue}
                                        </Option>
                                    ))}
                                </SearchableSelect>
                            </Form.Item>
                        </AutoDirectionProvider>
                        <AutoDirectionProvider
                            text={"לינק לבתי עסק מכבדים"}
                            style={{
                                marginBottom: 15,
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column"
                            }}>
                            <label style={{fontSize: 16, color: "var(--secondary-color)", marginLeft: 395}}>
                                {"לינק בתי עסק מכבדים לשובר"}{" "}
                                <Tooltip title="הוסיפו את הקישור של בתי העסק המכבדים של השובר">
                                    <QuestionCircleOutlined style={{cursor: "pointer"}} />
                                </Tooltip>
                            </label>
                            <Form.Item name="supplyBrandsLink">
                                <StepperInput
                                    style={{marginTop: 10, width: "600px"}}
                                    placeholder="לינק בתי עסק מכבדים לשובר"
                                />
                            </Form.Item>
                        </AutoDirectionProvider>

                        <Form.Item>
                            <AdminProductSearchTags productTags={searchTags} setProductTags={setSearchTags} />
                        </Form.Item>
                        <AutoDirectionProvider
                            text={"תיאור השובר"}
                            style={{
                                marginBottom: 15,
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column"
                            }}>
                            <label style={{fontSize: 16, color: "var(--secondary-color)", marginLeft: 475}}>
                                {"תיאור השובר"}{" "}
                                <Tooltip title="יש לתאר בפירוט מה הפעילות כוללת ועבור כמה אנשים היא מתאימה.">
                                    <QuestionCircleOutlined style={{cursor: "pointer"}} />
                                </Tooltip>
                            </label>
                            <Form.Item
                                name="description"
                                valuePropName="description"
                                rules={[
                                    {
                                        required: true,
                                        message: "שדה חובה"
                                    }
                                ]}>
                                <EditableInput
                                    style={{
                                        width: "600px",
                                        minHeight: "20em",
                                        border: "1px solid #E8EDF5",
                                        backgroundColor: "#FCFDFE",
                                        fontSize: "16px",
                                        marginTop: 10,
                                        borderRadius: "5px",
                                        outline: "none"
                                    }}
                                    onChange={html => onDescriptionChange(html)}
                                    value={description}
                                />
                            </Form.Item>
                        </AutoDirectionProvider>

                        <AutoDirectionProvider
                            text={"עמלה"}
                            style={{
                                marginBottom: 15,
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column"
                            }}>
                            <label style={{fontSize: 16, color: "var(--secondary-color)", marginLeft: 330}}>
                                עמלת מערכת לקבלת הזמנה (באחוזים)
                            </label>
                            <Form.Item
                                name="commission"
                                style={{marginRight: 10}}
                                rules={[{required: true, message: "נא לבחור עמלה"}]}>
                                <FormInput
                                    onWheel={e => e.target.blur()}
                                    type="number"
                                    style={{marginTop: 10, marginRight: 10, width: "620px"}}
                                    placeholder="לשימוש מנהל המערכת בלבד"
                                />
                            </Form.Item>
                        </AutoDirectionProvider>

                        <div style={{display: "flex", justifyContent: "space-around"}}>
                            <Form.Item>
                                <StyledButton
                                    style={{
                                        backgroundColor: "white",
                                        color: "#ED7FA6",
                                        width: "200px",
                                        marginTop: "50px",
                                        borderRadius: "5px"
                                    }}
                                    onClick={() => {
                                        setExitProduct(true);
                                    }}>
                                    ביטול
                                </StyledButton>
                            </Form.Item>
                            <Form.Item>
                                <StyledButton
                                    style={{
                                        backgroundColor: "#ED7FA6",
                                        color: "white",
                                        width: "200px",
                                        marginTop: "50px",
                                        borderRadius: "5px"
                                    }}
                                    htmlType="submit">
                                    הוסף מוצר לספק
                                </StyledButton>
                            </Form.Item>
                        </div>
                    </ConfigProvider>
                </Form>
            </div>
        </Modal>
    );
};
