import {useState} from "react";
import {Input} from "antd";
import {CustomTimePicker} from "./CustomTimePicker";
import React from "react";

export const StartEndTimePicker = ({value, onChange, canControlEndTime}) => {
    const [start, setStart] = useState(value ? value.start : null);
    const [end, setEnd] = useState(value ? value.end : null);

    const change = (_start, _end) => {
        setStart(_start);
        setEnd(_end);
        onChange({start: _start, end: _end});
    };

    return (
        <Input.Group style={{display: "flex"}}>
            <CustomTimePicker
                placeholder="Start"
                style={{
                    width: 150,
                    fontSize: 16
                }}
                onChange={time => change(time, end)}
                value={value ? value.start : null}
            />
            <CustomTimePicker
                placeholder="Finish"
                style={{
                    marginLeft: 10,
                    width: 150,
                    fontSize: 16
                }}
                onChange={time => change(start, time)}
                value={value ? value.end : null}
                disabled={!canControlEndTime}
            />
        </Input.Group>
    );
};
