import React from "react";
import {Collapse} from "antd";
import {RightOutlined} from "@ant-design/icons";
import "./report-management.css"

const {Panel} = Collapse;

export const ReportManagement = ({companyId, reports, history, match}) => {
    return (
        <div className="report-management-container">
            {!!reports?.length &&
                <>
                    <span className="report-management-container-sub-title">הפקת דוחות</span>
                    <Collapse
                        className="report-management-collapse"
                        expandIconPosition="right"
                        expandIcon={({isActive}) => (
                            <RightOutlined style={{fontSize: 16}} rotate={isActive ? 90 : 0}/>
                        )}>
                        {reports.map(({key, title, component}, index) => {
                            return (
                                <Panel className="panel" header={title} key={index}>
                                    {component}
                                </Panel>
                            )
                        })}
                    </Collapse>
                </>}
        </div>
    )
};
