import React, {useState, useEffect} from "react";
import {Button, Modal, Upload, message} from "antd";
import Icon from "@ant-design/icons";
import {EventBus} from "../../bus/EventBus";
import {PageTitleLabel, SmartImageContainer, StyledButton} from "../../components";
import {HttpClient} from "../../http/HttpClient";
import {CloseIcon, UploadIcon} from "../../icons";
import {UploadClient} from "../../http/UploadClient";
import {GoogleAnalytics} from "../../GoogleAnalytics";

const imageProps = {
    width: 300,
    height: 120
};

export const AdminCompanyBranding = ({visible, onClose, companyInfo}) => {
    const [uploading, setUploading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [logo, setLogo] = useState(null);

    useEffect(() => {
        if (companyInfo) {
            setLogo({
                logoUrl: companyInfo.branding?.logoUrl ?? null,
                logoId: companyInfo.branding?.logoId ?? null
            });
        }
    }, [companyInfo]);

    const saveSettings = async () => {
        setSaving(true);
        try {
            const branding = {
                logoUrl: logo?.logoUrl ?? null,
                logoId: logo?.logoId ?? null
            };
            await HttpClient.post(`/admin/api/companies/${companyInfo.companyId}/brand`, {branding: branding});
            onClose();
        } catch (e) {
            message.error("Oppsy... something went wrong, please try again later.");
        }
        setSaving(false);
    };

    const uploadLogo = async file => {
        setUploading(true);
        try {
            const formData = new FormData();
            formData.append("file", file);
            const {imageUrl, imageId} = await UploadClient.upload(
                "PUT",
                `/api/image?width=${imageProps.width}&height=${imageProps.height}&crop=fill&gravity=center&colors=1`,
                formData
            );
            GoogleAnalytics.event("Company Branding", "Upload Logo", companyInfo.companyId);
            setLogo({logoUrl: imageUrl, logoId: imageId});
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we couldn't upload your logo :("}},
                e.message
            );
        }
        setUploading(false);
    };

    return (
        <Modal
            className="wb-branding-modal"
            title={null}
            footer={null}
            bodyStyle={{padding: 0}}
            destroyOnClose={true}
            closable={true}
            onCancel={onClose}
            open={visible}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        padding: 30
                    }}>
                    <PageTitleLabel
                        style={{
                            fontSize: 20,
                            fontWeight: 700,
                            color: "#273444",
                            width: "100%",
                            justifyContent: "center",
                            marginBottom: 30,
                            marginTop: 0
                        }}>
                        Company branding
                    </PageTitleLabel>
                    <div style={{display: "flex", justifyContent: "center", position: "relative", marginTop: 15}}>
                        {logo && logo.logoUrl ? (
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <SmartImageContainer
                                    className="wb-image-container"
                                    width={imageProps.width}
                                    height={imageProps.height}
                                    src={logo.logoUrl}
                                    transformations={{
                                        crop: "lpad"
                                    }}
                                    style={{
                                        border: "1px solid #E8EDF5",
                                        color: "var(--secondary-color)",
                                        borderRadius: 10,
                                        margin: 0
                                    }}>
                                    <CloseIcon
                                        fill="#ccc"
                                        onClick={() => setLogo(null)}
                                        style={{
                                            position: "absolute",
                                            right: 15,
                                            top: 12,
                                            cursor: "pointer"
                                        }}
                                    />
                                </SmartImageContainer>
                            </div>
                        ) : (
                            <Upload
                                disabled={uploading}
                                name="file"
                                className="wb-logo-upload"
                                accept="image/png,image/jpeg"
                                showUploadList={false}
                                beforeUpload={async file => {
                                    await uploadLogo(file);
                                    return false;
                                }}>
                                <Button
                                    disabled={uploading}
                                    loading={uploading}
                                    type="link"
                                    style={{
                                        fontSize: 16,
                                        padding: 7,
                                        color: "var(--secondary-color)",
                                        opacity: uploading ? 0.65 : 1
                                    }}>
                                    <Icon component={UploadIcon} style={{fontSize: 20}} /> Upload logo (PNG / JPEG)
                                </Button>
                            </Upload>
                        )}
                    </div>
                </div>
                <div
                    style={{
                        height: 0,
                        width: "80%",
                        borderBottom: "1px dashed #DAE0E4",
                        marginBottom: 20
                    }}
                />
                <StyledButton
                    onClick={saveSettings}
                    loading={saving}
                    style={{
                        backgroundColor: "#ED7FA6",
                        color: "white",
                        width: "200px",
                        borderRadius: "5px",
                        justifyContent: "center",
                        marginBottom: "20px"
                    }}>
                    Apply
                </StyledButton>
            </div>
        </Modal>
    );
};
