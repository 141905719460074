import React, {useCallback} from "react";
import {DeleteOutlined} from "@ant-design/icons";
import {message, Table, Typography} from "antd";
import {useRequest} from "../../utils/hooks";
import {HttpClient} from "../../http/HttpClient";
import {CommentModal} from "../../admin/components/CommentModal";
import {ConfirmationRequiredWrapper} from "../ConfirmationRequiredWrapper.jsx";
import "./saved-order-table.css";

export const SavedOrderTable = ({history}) => {
    const [savedOrders, isSavedOrdersLoading, error, fetchSavedOrders] = useRequest("/api/savedOrder", "GET");

    const deleteSavedOrder = useCallback(async orderId => {
        const {error} = await HttpClient.safeDelete(`/api/savedOrder/${orderId}`);

        if (error) {
            message.error("בעקבות שגיאה הטיוטה לא נמחקה");
        } else {
            message.success("הטיוטה נמחקה בהצלחה");
            fetchSavedOrders();
        }
    }, []);

    const updateSavedOrderComment = useCallback(async (savedOrderId, comment) => {
        const updatedSavedOrder = await HttpClient.safePost("/api/savedOrder/updateField", {
            savedOrderId,
            fieldName: "savedOrderComment",
            fieldValue: comment
        });

        if (updatedSavedOrder.error) {
            message.error("שגיאה, ההערה לא נשמרה");
            return;
        }

        message.success("ההערה נשמרה בהצלחה");
        await fetchSavedOrders();
    }, []);

    return (
        <Table
            className="saved-order-table-container"
            loading={isSavedOrdersLoading}
            dataSource={savedOrders}
            pagination={{
                position: ["bottomCenter"],
                defaultPageSize: 20
            }}
            columns={[
                {
                    title: "שם המוצר",
                    dataIndex: "productName",
                    key: "productName",
                    render: (text, record) => (
                        <Typography.Link
                            className="link"
                            onClick={e => {
                                e.preventDefault();
                                const url = `${record.productUrl}?fromSavedOrder=${record.savedOrederId}`;
                                history.push(url);
                            }}>
                            {record.productName}
                        </Typography.Link>
                    )
                },
                {
                    title: "שם השותף",
                    key: "businessName",
                    render: (_, {provider}) => <span>{provider?.businessName}</span>
                },
                {
                    title: "תאריך יצירה",
                    dataIndex: "createdAt",
                    key: "createdAt",
                    render: (text, record) => (
                        <span>
                            {new Date(record.createdAt).toLocaleDateString("he-IL", {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric"
                            })}
                        </span>
                    )
                },
                {
                    title: "פעולות",
                    dataIndex: "action",
                    key: "action",
                    render: (_, {savedOrderComment, savedOrederId}) => {
                        const comment = savedOrderComment ? {content: savedOrderComment} : null;
                        return (
                            <div className="saved-order-table-actions-container">
                                <div>
                                    <CommentModal
                                        comment={comment}
                                        onSave={comment => updateSavedOrderComment(savedOrederId, comment)}
                                        tooltip={
                                            <>
                                                <div>רוצים לשמור הערה על הטיוטה?</div>
                                                <div>זה המקום בשבילכם!</div>
                                            </>
                                        }
                                    />
                                </div>
                                <div className="saved-order-table-actions-delete-icon-container">
                                    <ConfirmationRequiredWrapper
                                        subTitle="בעת לחיצה על אישור, טיוטא זו תמחק"
                                        onYes={async () => await deleteSavedOrder(savedOrederId)}>
                                        <DeleteOutlined />
                                    </ConfirmationRequiredWrapper>
                                </div>
                            </div>
                        );
                    }
                }
            ]}
        />
    );
};
