import React from "react";
import {Form} from "antd";
import classnames from "classnames";
import {Languages} from "./Login";
import {FormInputV2, FormConfirmButtonV2} from "../components/form";
import {Images} from "../images/Images";
import "./reset-password.css";

export const ResetPasswordCardBody = ({
    language,
    onFinish,
    isMobileView,
    sendingEmail,
    title,
    isNeedCurrentPassword = false
}) => (
    <div
        className={classnames("reset-password-card", {
            "ant-form-item-explain-error-rtl": language === Languages.Hebrew
        })}
        style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
        {title ? (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    direction: language === Languages.Hebrew ? "rtl" : "ltr",
                    justifyContent: "center"
                }}>
                <span className="reset-password-card-title">{title}</span>
            </div>
        ) : (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    direction: language === Languages.Hebrew ? "rtl" : "ltr",
                    justifyContent: "center"
                }}>
                <span
                    style={{
                        fontSize: "24px",
                        fontWeight: "700",
                        color: "var(--primary-color)",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "53px"
                    }}>
                    {language === Languages.Hebrew ? "ברוכים הבאים" : "welcome to"}
                </span>
            </div>
        )}
        <div
            style={{
                marginTop: "15px",
                marginBottom: "30px",
                alignSelf: "center",
                backgroundColor: "rgba(131,152,166,0.2)",
                height: "2px",
                width: "400px"
            }}></div>

        <label className="password-select-explain">
            יש לשים 💙 כי הסיסמה צריכה להיות באורך 10 תווים באנגלית, ללא רווחים ולכלול לכל הפחות ספרה (0-9), סימן (!@#),
            ואות גדולה אחת באנגלית
        </label>

        <Form
            onFinish={vals => onFinish(vals)}
            style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
            {isNeedCurrentPassword ? (
                <>
                    <label
                        style={{
                            marginRight: isMobileView ? 0 : "50px",
                            marginLeft: isMobileView ? 0 : "50px",
                            marginTop: "20px",
                            marginBottom: "15px",
                            fontSize: 16,
                            color: "var(--secondary-color)",
                            textAlign: language === Languages.Hebrew ? "right" : "left"
                        }}>
                        {language === Languages.Hebrew ? "סיסמא נוכחית" : "Current Password"}
                    </label>
                    <Form.Item
                        name="currentPassword"
                        style={{width: "400px", alignSelf: "center"}}
                        validateFirst={true}
                        rules={[
                            {
                                required: true,
                                message: "Please enter your current password."
                            }
                        ]}>
                        <FormInputV2.Password
                            style={{
                                border: "1px solid #E8EDF5",
                                backgroundColor: "#FCFDFE",
                                fontSize: 14,
                                borderRadius: 5,
                                height: 40,
                                width: "100%",
                                outline: "none"
                            }}
                        />
                    </Form.Item>
                </>
            ) : null}

            <label
                style={{
                    marginRight: isMobileView ? 0 : "50px",
                    marginLeft: isMobileView ? 0 : "50px",
                    marginTop: "20px",
                    marginBottom: "15px",
                    fontSize: 16,
                    color: "var(--secondary-color)",
                    textAlign: language === Languages.Hebrew ? "right" : "left"
                }}>
                {language === Languages.Hebrew ? "בחרו סיסמא" : "Choose password"}
            </label>
            <Form.Item
                name="newPassword"
                style={{width: "400px", alignSelf: "center"}}
                validateFirst={true}
                rules={[
                    {
                        validator: (_, value) => {
                            const errors = [];

                            if (language === Languages.English) {
                                if (!value) {
                                    errors.push("- Please enter your password.");
                                }

                                if (/\s/.test(value)) {
                                    errors.push("- Your password must contain no whitespaces.");
                                }

                                if (!/(?=.*[A-Z])/.test(value)) {
                                    errors.push("- Your password must contain at least one capital letter (A-Z).");
                                }

                                if (!/(?=.*[a-z])/.test(value)) {
                                    errors.push("- Your password must contain at least one lowercase letter (a-z).");
                                }

                                if (!/(?=.*\d)/.test(value)) {
                                    errors.push("- Your password must contain at least one number (0-9).");
                                }

                                if (!/(?=.*[!@#$%^&*()])/.test(value)) {
                                    errors.push("- Your password must contain at least one special character (!@#).");
                                }

                                if (value.length < 10) {
                                    errors.push("- Your password must contain at least 10 characters.");
                                }
                            } else if (language === Languages.Hebrew) {
                                if (!value) {
                                    errors.push("- נא להזין את הסיסמה שלך.");
                                }

                                if (/\s/.test(value)) {
                                    errors.push("- הסיסמה שלך חייבת לא לכלול רווחים.");
                                }

                                if (!/(?=.*[A-Z])/.test(value)) {
                                    errors.push("- הסיסמה שלך חייבת לכלול לפחות אות גדולה אחת (A-Z).");
                                }

                                if (!/(?=.*[a-z])/.test(value)) {
                                    errors.push("- הסיסמה שלך חייבת לכלול לפחות אות קטנה אחת (a-z).");
                                }

                                if (!/(?=.*\d)/.test(value)) {
                                    errors.push("- הסיסמה שלך חייבת לכלול לפחות מספר אחד (0-9).");
                                }

                                if (!/(?=.*[!@#$%^&*()])/.test(value)) {
                                    errors.push("- הסיסמה שלך חייבת לכלול לפחות תו מיוחד אחד (!@#).");
                                }

                                if (value.length < 10) {
                                    errors.push("- הסיסמה שלך חייבת לכלול לפחות 10 תווים.");
                                }
                            }

                            if (errors.length > 0) {
                                return Promise.reject(errors);
                            }

                            return Promise.resolve();
                        }
                    }
                ]}>
                <FormInputV2.Password
                    style={{
                        border: "1px solid #E8EDF5",
                        backgroundColor: "#FCFDFE",
                        fontSize: 14,
                        borderRadius: 5,
                        height: 40,
                        width: "100%",
                        outline: "none"
                    }}
                />
            </Form.Item>
            <label
                style={{
                    marginRight: isMobileView ? 0 : "50px",
                    marginLeft: isMobileView ? 0 : "50px",
                    marginBottom: "15px",
                    fontSize: 16,
                    color: "var(--secondary-color)",
                    textAlign: language === Languages.Hebrew ? "right" : "left"
                }}>
                {language === Languages.Hebrew ? "אימות סיסמה" : "Verify password"}
            </label>
            <Form.Item
                name="newConfirmPassword"
                style={{width: "400px", alignSelf: "center"}}
                dependencies={["newPassword"]}
                rules={[
                    {
                        required: true,
                        message: "Please enter password confirmation."
                    },
                    ({getFieldValue}) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue("newPassword") === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject("Password doesn't match.");
                        }
                    })
                ]}>
                <FormInputV2.Password
                    visibilityToggle={false}
                    style={{
                        border: "1px solid #E8EDF5",
                        backgroundColor: "#FCFDFE",
                        fontSize: 14,
                        borderRadius: 5,
                        height: 40,
                        width: "100%",
                        outline: "none"
                    }}
                />
            </Form.Item>
            <Form.Item style={{marginTop: 40, width: "400px", alignSelf: "center"}}>
                <FormConfirmButtonV2 loading={sendingEmail} htmlType="submit">
                    {language === Languages.Hebrew ? "המשך" : "Confirm"}
                </FormConfirmButtonV2>
            </Form.Item>
        </Form>
    </div>
);
