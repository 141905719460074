import {Select, Radio, Form, Input} from "antd";
import {ContactUsPhone, FormInput} from "../components/form";
import {CitySelect, CountrySelect, StateSelect} from "../components/CountryStateCitySelect";
import React, {useReducer, useState} from "react";
// import * as EmailValidator from "email-validator";
import {HttpClient} from "../http/HttpClient";
import {csc, isUS} from "../csc/CountyStateCityClient";
import {parse as qsParse} from "query-string";
import {validateEmailExistence} from "../components/form/validators";

const {Option} = Select;

export const ProviderSignUpBasicInfoFields = ({
    providerType,
    onProviderTypeChange,
    defaultCountry,
    defaultState,
    defaultCity,
    hideProviderTypeToggle = false,
    disableName = false,
    disableEmail = false,
    allowExistingEmail = false
}) => {
    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        selectedCountry: defaultCountry ? defaultCountry : null,
        selectedState: defaultState ? defaultState : null,
        selectedCity: defaultCity ? defaultCity : null
    });

    const selectCountry = selectedCountry => {
        setState({
            selectedCountry,
            selectedState: null,
            selectedCity: null
        });
    };

    const selectState = selectedState => {
        setState({
            selectedState,
            selectedCity: null
        });
    };

    const selectCity = selectedCity => {
        setState({selectedCity});
    };

    const changeProviderType = type => {
        onProviderTypeChange(type);
    };

    const {selectedCountry, selectedState, selectedCity} = state;

    return (
        <>
            {!hideProviderTypeToggle ? (
                <Form.Item name="extra.providerType">
                    <Radio.Group
                        onChange={e => changeProviderType(e.target.value)}
                        style={{width: "100%"}}
                        defaultValue={providerType || "individual"}
                        size="large">
                        <Radio.Button
                            style={{
                                textAlign: "center",
                                width: "50%",
                                borderBottomLeftRadius: 5,
                                borderTopLeftRadius: 5
                            }}
                            value="individual">
                            Individual
                        </Radio.Button>
                        <Radio.Button
                            style={{
                                textAlign: "center",
                                width: "50%",
                                borderBottomRightRadius: 5,
                                borderTopRightRadius: 5
                            }}
                            value="organization">
                            Organization
                        </Radio.Button>
                    </Radio.Group>
                </Form.Item>
            ) : null}
            {providerType === "individual" || !providerType ? (
                <Input.Group style={{display: "flex", justifyContent: "space-between"}}>
                    <Form.Item name="firstName" rules={[{required: true, message: "Please enter your first name."}]}>
                        <FormInput disabled={disableName} placeholder="First name" />
                    </Form.Item>
                    <Form.Item
                        style={{marginLeft: 10}}
                        name="lastName"
                        rules={[{required: true, message: "Please enter your last name."}]}>
                        <FormInput disabled={disableName} placeholder="Last name" />
                    </Form.Item>
                </Input.Group>
            ) : (
                <Form.Item name="firstName" rules={[{required: true, message: "Please enter your first name."}]}>
                    <FormInput disabled={disableName} placeholder="Organization name" />
                </Form.Item>
            )}
            <Form.Item
                name="email"
                validateFirst={true}
                rules={[
                    {
                        required: true,
                        type: "email",
                        message: "Please enter a valid email."
                    },
                    {
                        validateTrigger: "onSubmit",
                        validator: async (_, email) => {
                            if (allowExistingEmail) return;
                            await validateEmailExistence(email);
                        }
                    }
                ]}>
                <FormInput disabled={disableEmail} type="email" placeholder="Email" />
            </Form.Item>
            <Form.Item name="gender" rules={[{required: true, message: "Please select gender."}]}>
                <Select placeholder="Gender">
                    <Option value="female">Female</Option>
                    <Option value="male">Male</Option>
                    <Option value="generic">None of them</Option>
                </Select>
            </Form.Item>
            <Form.Item
                name="phone"
                rules={[
                    {required: true, message: "Please enter your phone number."},
                    {min: 8, message: "Please enter a valid phone number."}
                ]}>
                <FormInput type="number" placeholder="Phone" />
            </Form.Item>
            <Form.Item
                name="countryId"
                rules={[
                    {
                        required: true,
                        message: "Please select country."
                    }
                ]}>
                <CountrySelect
                    value={selectedCountry ? selectedCountry.countryId : null}
                    onSelect={selectedCountry => selectCountry(selectedCountry)}
                />
            </Form.Item>
            <Form.Item
                name="stateId"
                shouldUpdate={true}
                rules={[
                    {
                        validator: async (_, value) => {
                            return !selectedCountry || (!value && selectedCountry.countryCode === "US")
                                ? Promise.reject("Please select state.")
                                : Promise.resolve();
                        }
                    }
                ]}>
                <StateSelect
                    value={selectedState ? selectedState.stateId : null}
                    countryId={selectedCountry ? selectedCountry.countryId : null}
                    onSelect={selectedState => selectState(selectedState)}
                />
            </Form.Item>
            <Form.Item name="cityId" rules={[{required: true, message: "Please select city."}]}>
                <CitySelect
                    value={selectedCity ? selectedCity.cityId : null}
                    countryId={selectedCountry ? selectedCountry.countryId : null}
                    stateId={selectedState ? selectedState.stateId : null}
                    onSelect={selectedCity => selectCity(selectedCity)}
                />
            </Form.Item>
        </>
    );
};

export const fillLocationDetails = vals => {
    const {countryId, stateId, cityId} = vals;
    const country = csc.country(countryId);
    const state = stateId ? csc.state(stateId) : {};
    const city = csc.city(cityId);
    return {...vals, ...country, ...state, ...city};
};

export const missingStateDetector = form => changedFields => {
    const countryFieldIdx = changedFields.findIndex(field => field.name[0] === "countryId");
    if (countryFieldIdx >= 0 && !isUS(changedFields[countryFieldIdx].value)) {
        form.setFields([
            {
                touched: false,
                validating: false,
                errors: [],
                name: ["stateId"],
                value: void 0
            }
        ]);
    }
};

export const ProviderSignUpBasicInfo = props => {
    const {onNext, values, form, visible, onValuesChange} = props;
    const [providerType, setProviderType] = useState("individual");

    let email = null;

    try {
        const encodedEmail = qsParse(window.location.search).em;
        email = encodedEmail ? atob(encodedEmail) : null;
    } catch (e) {}

    return (
        <Form
            form={form}
            scrollToFirstError={true}
            onFinish={vals => onNext(fillLocationDetails(vals))}
            onFieldsChange={missingStateDetector(form)}
            onValuesChange={onValuesChange}
            style={{
                padding: 10,
                maxWidth: 360,
                minWidth: 360,
                width: "100%",
                visibility: visible ? "visible" : "hidden"
            }}
            initialValues={{
                ...values,
                "extra.providerType": providerType,
                email: email || void 0
            }}>
            <ProviderSignUpBasicInfoFields
                providerType={providerType}
                onProviderTypeChange={type => setProviderType(type)}
                disableEmail={!!email}
            />
            <ContactUsPhone />
        </Form>
    );
};
