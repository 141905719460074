import React from "react";

export const AutoDirectionProvider = ({children, text, style, className}) => {
    let dir = "ltr";
    if (text) {
        dir = /[\u0591-\u07FF]/.test(text) ? "rtl" : "ltr";
    }

    return (
        <div className={className} style={{padding: 1, ...style}} dir={dir}>
            {children}
        </div>
    );
};

export const AutoDirectionProviderWithValueChange = ({children, onChange, defaultValue, value, style}) => {
    let dir = "ltr";
    if (value || defaultValue) {
        dir = /[\u0591-\u07FF]/.test(value || defaultValue) ? "rtl" : "ltr";
    }

    return (
        <div style={{padding: 1, ...style}} dir={dir}>
            {children({onChange, defaultValue, value, dir})}
        </div>
    );
};

export const isRTL = text => {
    return /[\u0591-\u07FF]/.test(text) === true;
};
