import {budgetTitleAmount, budgetTitlePricePerParticipant} from "./consts.js";
import {StringBuilder} from "../../../../AppUtils.js";

export const getBudgetSectionTitle = (budget, category) => {
    const {participantsAmount, pricePerParticipant, totalPrice, consistentBudget} = budget ?? {};

    let title;

    if (
        !isNaN(parseInt(totalPrice?.max)) &&
        isNaN(parseInt(pricePerParticipant?.max)) &&
        isNaN(parseInt(participantsAmount))
    ) {
        title = new StringBuilder().append("עד").append(budget.totalPrice.max).append("ש״ח");
    }

    if (
        !isNaN(parseInt(participantsAmount)) &&
        isNaN(parseInt(pricePerParticipant?.max)) &&
        isNaN(parseInt(totalPrice?.max))
    ) {
        title = new StringBuilder().append(participantsAmount).append(budgetTitleAmount[category]);
    }

    if (!isNaN(parseInt(participantsAmount)) && !isNaN(parseInt(pricePerParticipant?.max))) {
        title = new StringBuilder()
            .append(participantsAmount)
            .append(budgetTitleAmount[category])
            .append(pricePerParticipant.max)
            .append("₪")
            .append(budgetTitlePricePerParticipant[category]);
    }

    if (isNaN(parseInt(participantsAmount)) && !isNaN(parseInt(pricePerParticipant?.max))) {
        title = new StringBuilder()
            .append(pricePerParticipant.max)
            .append("₪")
            .append(budgetTitlePricePerParticipant[category]);
    }

    if (!title) {
        return;
    }

    if (consistentBudget?.length) {
        return new StringBuilder().append(`${title.toString()}`).append("(גמיש)").toString();
    }

    return title.toString();
};
