import React, {useCallback, useContext, useMemo, useState} from "react";
import {UserType} from "../../consts.js";
import "../order-information-card.css";
import {generateOrderViewStatusContent, generateProviderOrderViewStatusContent} from "./utils.jsx";
import {OrderStatusView} from "../OrderStatusView/OrderStatusView.jsx";
import {AppContext} from "../../AppContext.jsx";
import {generateOrderRowsInfo, generateSquareOrderInfoCompany, generateSquareOrderInfoProvider} from "./consts.jsx";

import {getLogger} from "../../Logger.jsx";
import {HttpClient} from "../../http/HttpClient.jsx";
import {openWindow} from "../../http/WindowOpener.jsx";
import {set} from "lodash";
import {generateLionWheelOrderDeliveryStatusLink} from "../OrderDeliveryStatus/utils.jsx";
import {message} from "antd";
import {NOOP} from "../../utils/NOOP.jsx";

let addressLocationCache = {};

export const OrderInformationView = ({eventInfo, userType, contactsInfo, fetchOrder = NOOP}) => {
    const {me} = useContext(AppContext);
    const [readMore, setReadMore] = useState({});
    const [isLoadingOnDelivered, setIsLoadingOnDelivered] = useState(false);

    const onDelivered = useCallback(async () => {
        setIsLoadingOnDelivered(true);
        const res = await HttpClient.safePost(`/api/events/${eventInfo?.eventId}/delivered`);
        setIsLoadingOnDelivered(false);
        if (res?.error) {
            message.error(res?.error);
            return;
        }

        fetchOrder();
    }, [eventInfo, fetchOrder]);

    const latestOrderUpdate = useMemo(
        () =>
            userType === UserType.Company
                ? generateOrderViewStatusContent(eventInfo).pop()
                : generateProviderOrderViewStatusContent(eventInfo, onDelivered, isLoadingOnDelivered).pop(),
        [userType, eventInfo, onDelivered, isLoadingOnDelivered]
    );

    const log = useMemo(() => getLogger({}, "OrderInformationCard"), []);

    const openAddressInNewTab = useCallback(async address => {
        if (!address) {
            return;
        }

        if (addressLocationCache?.[address]) {
            const geometry = addressLocationCache?.[address];

            window.open(
                `https://www.google.com/maps/place/?q=${geometry?.location?.lat},${geometry?.location?.lng}`,
                "noreferrer"
            );
            return;
        }

        const {geometry} = (await HttpClient.safeGet(`/api/location/address/${address}`)) ?? {};

        if (geometry) {
            addressLocationCache[address] = geometry;
            window.open(
                `https://www.google.com/maps/place/?q=${geometry?.location?.lat},${geometry?.location?.lng}`,
                "noreferrer"
            );
        }
    }, []);

    const onClickProviderHelpLink = useCallback(() => {
        log("delivery provider help link clicked", {});
        openWindow(generateLionWheelOrderDeliveryStatusLink(eventInfo?.shippingDetails));
    }, [log, eventInfo]);

    const toggleReadMore = useCallback(() => {
        setReadMore(currentReadMore =>
            set({...currentReadMore}, eventInfo?.eventId, !currentReadMore[eventInfo?.eventId])
        );
    }, [eventInfo?.eventId]);

    const orderRowsInfo = useMemo(() => generateOrderRowsInfo(eventInfo), [eventInfo]);

    const orderSquareInfo = useMemo(
        () =>
            userType === UserType.Company
                ? generateSquareOrderInfoCompany(
                      eventInfo,
                      openAddressInNewTab,
                      readMore[eventInfo?.eventId],
                      toggleReadMore,
                      contactsInfo
                  )
                : generateSquareOrderInfoProvider(
                      eventInfo,
                      openAddressInNewTab,
                      onClickProviderHelpLink,
                      readMore[eventInfo?.eventId],
                      toggleReadMore,
                      contactsInfo
                  ),
        [contactsInfo, eventInfo, onClickProviderHelpLink, openAddressInNewTab, readMore, toggleReadMore, userType]
    );

    return (
        <>
            {!!me?.features?.order_status_view && latestOrderUpdate && (
                <OrderStatusView
                    title={latestOrderUpdate?.title}
                    titleColor={latestOrderUpdate?.titleColor}
                    description={latestOrderUpdate?.description}
                    subTitle={latestOrderUpdate?.subTitle}
                    subTitleColor={latestOrderUpdate?.subTitleColor}
                    backgroundColor={latestOrderUpdate?.backgroundColor}
                    endTime={latestOrderUpdate?.endTime}
                    actions={latestOrderUpdate?.actions}
                />
            )}

            {orderSquareInfo?.map(({row = []}, index) => {
                return <SquareOrderInfo key={index} row={row} />;
            })}

            {orderRowsInfo?.map(({label, component}, index) => {
                return <OrderInfoRow key={index} label={label} component={component} />;
            })}
        </>
    );
};

export const OrderInfoRow = ({label, component}) => {
    return (
        <div className="order-information-card-customer-information">
            <div>{label}</div>
            {!!component && component}
        </div>
    );
};

export const SquareOrderInfo = ({row = []}) => {
    return (
        <div className="order-information-card-details-containers">
            {row?.map(({title, component}, index) => {
                return (
                    <div key={index} className="order-information-card-details-container">
                        <div className="order-information-card-details-container-title">{title}</div>
                        {!!component && component}
                    </div>
                );
            })}
        </div>
    );
};
