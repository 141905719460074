import {AutoDirectionProvider, isRTL} from "../../AutoDirectionProvider";
import moment from "moment-timezone";
import {Link} from "react-router-dom";
import {MessageIcon} from "../../icons";
import React, {useContext} from "react";
import {AppContext} from "../../AppContext";

const getPreviewTimeFormatted = (event, dateMom, me) => {
    const startTime =
        event.appointmentBased || event.type === "appointment"
            ? me.type === "employee" && event.start
                ? event.start
                : event.minTime
            : event.dtstart
            ? moment(event.dtstart).format("H:mm")
            : moment(dateMom).format("H:mm");
    const endTime =
        event.appointmentBased || event.type === "appointment"
            ? me.type === "employee" && event.end
                ? event.end
                : event.maxTime
            : event.dtstart
            ? moment(event.dtstart).add(event.duration, "minutes").format("H:mm")
            : moment(dateMom).add(event.duration, "minutes").format("H:mm");
    return event.sessionType === "special" ? "" : `, ${startTime} - ${endTime}`;
};

export const EventPreviewContent = ({event, dateMom, contentStyle, participantsCount, eventDescription}) => {
    const {me} = useContext(AppContext);
    return (
        <AutoDirectionProvider text={event.product.name ? event.product.name.text : null}>
            <div style={{display: "flex", flexDirection: "column", width: "100%", ...contentStyle}}>
                {event.product.name.selected ? (
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            marginTop: 30,
                            fontSize: 28,
                            fontWeight: 700,
                            color: "#273444"
                        }}>
                        {event.product.name.text}
                    </div>
                ) : null}
                {dateMom ? (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            marginTop: 15,
                            fontSize: 16,
                            color: "var(--secondary-color)"
                        }}>
                        {dateMom.format("MMMM Do, dddd")}
                        {getPreviewTimeFormatted(event, dateMom, me)}
                        {event.appointmentBased || event.location === "offsite" || event.location === "onsite"
                            ? ` | ${event.address}`
                            : ""}
                        {event.maxParticipants
                            ? ` | ${typeof participantsCount !== "undefined" ? `${participantsCount} / ` : ""}${
                                  event.maxParticipants
                              } Participants${typeof participantsCount !== "undefined" ? "" : " max"}`
                            : ""}
                    </div>
                ) : null}
                {event.provider.name.selected && event.sessionType !== "special" ? (
                    <div style={{width: "100%", display: "flex", marginTop: 15, fontSize: 20, color: "#1260D1"}}>
                        {event.provider.name.link ? (
                            <Link
                                style={{fontSize: 20, color: "#1260D1", textDecoration: "none"}}
                                to={event.provider.name.link}>
                                {event.provider.name.text}
                            </Link>
                        ) : (
                            <span>{event.provider.name.text}</span>
                        )}
                    </div>
                ) : null}
                {event.provider.profession && event.provider.profession.selected && event.sessionType !== "special" ? (
                    <div style={{width: "100%", display: "flex", marginTop: 3, fontSize: 15, color: "#1260D1"}}>
                        <span>{event.provider.profession.text}</span>
                    </div>
                ) : null}
                {event.product.description.selected ? (
                    <div style={{width: "100%", marginTop: 15, display: "flex"}}>
                        <MessageIcon style={{marginRight: 10, marginTop: 7, marginLeft: "10px"}} />
                        <div
                            style={{direction: isRTL(event.product.name.text) ? "rtl" : "ltr"}}
                            dangerouslySetInnerHTML={{
                                __html: eventDescription || event.product.description.text
                            }}></div>
                    </div>
                ) : null}
                {event.extra && event.extra.notes && event.extra.notes.selected ? (
                    <div style={{width: "100%", marginTop: 15, display: "flex"}}>
                        <MessageIcon style={{marginRight: 10, marginTop: 7, marginLeft: "10px"}} />
                        <div
                            style={{direction: isRTL(event.product.name.text) ? "rtl" : "ltr"}}
                            dangerouslySetInnerHTML={{__html: eventDescription || event.extra.notes.text}}></div>
                    </div>
                ) : null}
            </div>
        </AutoDirectionProvider>
    );
};
