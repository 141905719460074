import moment from "moment";
import {OrderShortcutFilterValues, OrderShortcutToText} from "./consts.js";
import {OrderStatus} from "../../components/index.jsx";

export const generateProviderOrdersShortcuts = [
    {
        label: OrderShortcutToText[OrderShortcutFilterValues.PENDING],
        value: OrderShortcutFilterValues.PENDING,
        statuses: [OrderStatus.pending],
        searchByCreated: true,
        datePicked: () => [moment().startOf("month"), moment().endOf("month")]
    },
    {
        label: OrderShortcutToText[OrderShortcutFilterValues.ACCEPTED],
        value: OrderShortcutFilterValues.ACCEPTED,
        statuses: [OrderStatus.accepted],
        searchByCreated: false,
        datePicked: () => [moment().startOf("month"), moment().endOf("month")]
    },
    {
        label: OrderShortcutToText[OrderShortcutFilterValues.SUPPLY_TOMORROW],
        value: OrderShortcutFilterValues.SUPPLY_TOMORROW,
        statuses: [OrderStatus.accepted, OrderStatus.pending],
        searchByCreated: false,
        datePicked: () => [moment().add(1, "day").startOf("day"), moment().add(1, "day").endOf("day")]
    },
    {
        label: OrderShortcutToText[OrderShortcutFilterValues.CANCELLED_ORDER],
        value: OrderShortcutFilterValues.CANCELLED_ORDER,
        statuses: [OrderStatus.cancelled, OrderStatus.declined],
        searchByCreated: true,
        datePicked: () => [moment().startOf("month"), moment().endOf("month")]
    }
];

export const isTomorrowDate = date => {
    return date?.length > 1 && moment().diff(date[0], "days") === 1 && moment().diff(date[1], "days") === 1;
};
