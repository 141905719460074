import {CloseIcon} from "../icons";
import {Form, Modal, message} from "antd";
import React, {useContext, useState} from "react";
import {FormInput} from "../components/form";
import {PageSubTitleLabel, PageTitleLabel} from "../components/PageTitle";
import {StyledButton} from "../components/StyledButton";
import {HttpClient} from "../http/HttpClient";
import {EventBus} from "../bus/EventBus";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {AppContext} from "../AppContext";
import {Images} from "../images/Images";

export const EmployeeUpdateInfoModal = ({visible, onClose}) => {
    const {me} = useContext(AppContext);
    const [loading, setLoading] = useState(false);

    const updateUser = async ({phone}) => {
        setLoading(true);
        try {
            const updatedUser = await HttpClient.post("/api/users/me", {phone});
            GoogleAnalytics.event("Employee", "Update Info", me.userId);
            EventBus.trigger("me:update", updatedUser);
            onClose();
        } catch (e) {
            message.error("Something went wrong, please try again later.");
        }
        setLoading(false);
    };

    return (
        <Modal
            className="wb-modal-radius"
            width={480}
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            footer={null}
            title={null}
            closable={true}
            closeIcon={<CloseIcon fill="white" />}
            bodyStyle={{display: "flex", justifyContent: "center", padding: 0}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <div
                    style={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        paddingBottom: "52%",
                        width: "100%",
                        backgroundImage: `url(${Images.phone})`
                    }}
                />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: 24,
                        paddingTop: 0
                    }}>
                    <PageTitleLabel style={{marginTop: 20, marginBottom: 10}}>Phone number required</PageTitleLabel>
                    <PageSubTitleLabel style={{marginTop: 0, marginBottom: 30}}>
                        Please fill in your phone number before booking so that the vendor could get in touch with you
                        in case of any changes.
                    </PageSubTitleLabel>
                    <div style={{width: "100%"}}>
                        <Form style={{width: "100%"}} onFinish={updateUser}>
                            <Form.Item
                                name="phone"
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (!value) {
                                                return Promise.reject("Please enter phone number");
                                            } else if (value.length < 8) {
                                                return Promise.reject("Please enter a valid phone number");
                                            }

                                            return Promise.resolve();
                                        }
                                    }
                                ]}>
                                <FormInput type="number" placeholder="Phone" />
                            </Form.Item>
                            <Form.Item style={{marginBottom: 0}}>
                                <StyledButton
                                    loading={loading}
                                    htmlType="submit"
                                    style={{
                                        borderRadius: 25,
                                        width: "100%",
                                        textAlign: "center",
                                        backgroundColor: "var(--secondary-color)",
                                        height: 50,
                                        color: "white"
                                    }}>
                                    Save
                                </StyledButton>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
