import React, {useEffect, useState} from "react";
import {Radio, Button} from "antd";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import styled from "styled-components";
import {isEmpty, cloneDeep} from "lodash";
import {CustomTimePicker} from "./CustomTimePicker";
import moment from "moment";

const StyledButton = styled(Button)`
    border: 1px solid #e8edf5 !important;
    background-color: #fcfdfe;
    font-size: 16px;
    border-radius: 10px;
    padding-right: 11px;
    display: flex;
    color: rgba(0, 0, 0, 0.65) !important;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    width: 100%;
    outline: none;
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
`;

export const defaultAvailability = {
    sunday: {start: "09:00", end: "19:00", selected: true},
    monday: {start: "09:00", end: "19:00", selected: true},
    tuesday: {start: "09:00", end: "19:00", selected: true},
    wednesday: {start: "09:00", end: "19:00", selected: true},
    thursday: {start: "09:00", end: "19:00", selected: true},
    friday: {start: "09:00", end: "19:00", selected: true},
    saturday: {start: "09:00", end: "19:00", selected: true}
};

export const daysArray = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const AvailabilityHourly = ({value, onChange}) => {
    const [state, setState] = useState(defaultAvailability);
    const [visibility, setVisibility] = useState(false);

    useEffect(() => {
        if (value && !isEmpty(value)) {
            setState(value);
        }
    }, [value]);

    const onSelectDay = (dayKey, selected) => {
        let stateCopy = cloneDeep(state);
        stateCopy[dayKey].selected = selected;
        setState(stateCopy);
        onChange(stateCopy);
    };

    const onSelect = (dayKey, field, time) => {
        let stateCopy = cloneDeep(state);
        if (!stateCopy[dayKey].selected) {
            return;
        }
        stateCopy[dayKey][field] = time;
        setState(stateCopy);
        onChange(stateCopy);
    };

    const availabilityDays = () => {
        return daysArray.reduce((arr, day) => {
            const dayKey = day.toLowerCase();
            if (state[dayKey].selected) {
                arr.push(day);
            }
            return arr;
        }, []);
    };

    const availabilityText = () => {
        const days = availabilityDays();
        return days.length > 0 ? (
            <span style={{color: "rgba(0, 0, 0, 0.65)", overflow: "hidden"}}>{days.join(", ")}</span>
        ) : (
            <span style={{color: "rgba(0, 0, 0, 0.3)"}}>Choose Availability</span>
        );
    };

    const menu = () => {
        return (
            <div
                className="provider-availability"
                onClick={evt => evt.stopPropagation()}
                style={{
                    zIndex: 1,
                    width: 500,
                    padding: 7,
                    backgroundColor: "white",
                    borderRadius: 20,
                    boxShadow: "0 10px 40px 0 rgba(76,88,105,0.1)"
                }}>
                <table>
                    <tbody>
                        {daysArray.map(day => {
                            const dayKey = day.toLowerCase();
                            return (
                                <tr key={dayKey} style={{height: 40}}>
                                    <td>
                                        <Radio
                                            onClick={() => onSelectDay(dayKey, !state[dayKey].selected)}
                                            style={{display: "flex"}}
                                            checked={state[dayKey].selected}
                                        />
                                    </td>
                                    <td
                                        onClick={() => onSelectDay(dayKey, !state[dayKey].selected)}
                                        style={{width: 100, fontSize: 16, color: "#637381", textAlign: "left"}}>
                                        {day}
                                    </td>
                                    <td>
                                        <CustomTimePicker
                                            style={{
                                                width: 90,
                                                fontSize: "14px",
                                                height: "40px !important",
                                                marginRight: "50px"
                                            }}
                                            format="HH:mm"
                                            disabled={!state[dayKey].selected}
                                            suffixIcon={null}
                                            minuteStep={15}
                                            inputReadOnly={false}
                                            showNow={true}
                                            onChange={time => onSelect(dayKey, "end", time.format("HH:mm"))}
                                            value={moment(state[dayKey].end, "HH:mm")}
                                            placeholder={"End"}
                                        />
                                    </td>
                                    <td>
                                        <div style={{width: "35px", fontSize: 16}}>to</div>
                                    </td>
                                    <td>
                                        <CustomTimePicker
                                            style={{width: 90, fontSize: "14px", height: "40px !important"}}
                                            format="HH:mm"
                                            disabled={!state[dayKey].selected}
                                            suffixIcon={null}
                                            minuteStep={15}
                                            inputReadOnly={false}
                                            showNow={true}
                                            onChange={time => onSelect(dayKey, "start", time.format("HH:mm"))}
                                            value={moment(state[dayKey].start, "HH:mm")}
                                            placeholder={"Start"}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div>
            <StyledButton className="wb-availability-btn" onClick={() => setVisibility(!visibility)}>
                {availabilityText()}
                {visibility ? (
                    <UpOutlined style={{fontSize: 12, paddingTop: 4, color: "rgba(0, 0, 0, 0.3)"}} />
                ) : (
                    <DownOutlined style={{fontSize: 12, paddingTop: 5, color: "rgba(0, 0, 0, 0.3)"}} />
                )}
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        position: "absolute",
                        left: 0,
                        top: 40,
                        padding: 10
                    }}>
                    {visibility ? menu() : null}
                </div>
            </StyledButton>
        </div>
    );
};
