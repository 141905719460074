import {Button, Switch, Tooltip} from "antd";
import {CheckOutlined} from "@ant-design/icons";
import React, {useContext} from "react";
import {AppContext} from "../../AppContext";
import {openExternalLink} from "../../http/WindowOpener";
import {GoogleAnalytics} from "../../GoogleAnalytics";
import moment from "moment-timezone";
import {AutoDirectionProvider} from "../../AutoDirectionProvider";

const isCtaDisabled = (event, participantsCount, onUnregister) => {
    return event.maxParticipants && participantsCount >= event.maxParticipants && !onUnregister;
};

const CTATooltip = ({viewOnly, disabled, children}) => {
    if (viewOnly) {
        return (
            <Tooltip
                title={
                    event.appointmentBased
                        ? "Only employees can book an appointment."
                        : "Only employees can register for an event."
                }>
                <div>{children}</div>
            </Tooltip>
        );
    } else if (disabled) {
        return (
            <Tooltip title={"Event is already fully booked."}>
                <div>{children}</div>
            </Tooltip>
        );
    } else {
        return children;
    }
};

const ActionButton = ({loading, disabled, onClick, style, children}) => {
    const {me, theme} = useContext(AppContext);
    let {weeklyTheme} = theme;

    return (
        <Button
            loading={loading}
            disabled={disabled}
            onClick={onClick}
            style={{
                minWidth: 130,
                padding: "4px 11px",
                height: 50,
                borderRadius: 10,
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 16,
                backgroundColor: weeklyTheme.primary,
                cursor: "pointer",
                userSelect: "none",
                border: "none",
                boxShadow: "none",
                ...style
            }}>
            {children}
        </Button>
    );
};

export const EventPreviewActions = ({
    event,
    viewOnly,
    dateMom,
    useDefaultTheme,
    actionLoading,
    participantsCount,
    onEdit,
    onJoin,
    onRegister,
    onUnregister,
    onBook,
    onDisplayChange,
    isDisplayed,
    actionsStyle
}) => {
    const {me, theme} = useContext(AppContext);

    const openEventExternalLink = () => {
        const date = dateMom ? dateMom.format("YYYY-MM-DD") : null;
        GoogleAnalytics.trackCalendarEvent(event, "CTA", me.branch.name);
        openExternalLink(`/api/events/${event.eventId}/click${date ? `?date=${date}` : ""}`);
    };

    let {weeklyTheme} = theme;

    weeklyTheme = useDefaultTheme ? theme : weeklyTheme;
    const ctaDisabled = isCtaDisabled(event, participantsCount, onUnregister);

    const oldEvent = dateMom.endOf("day").isBefore(moment());

    return (event.callToAction.name.selected || onEdit || onJoin) && event.type !== "appointment" ? (
        <AutoDirectionProvider text={event.product.name ? event.product.name.text : null}>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    paddingTop: 20,
                    marginBottom: 30,
                    ...actionsStyle
                }}>
                {(!event.externalLink || !event.externalLink.enabled) &&
                (!event.actionType || event.actionType === "system") &&
                event.callToAction.name.selected &&
                event.sessionType !== "special" ? (
                    <CTATooltip viewOnly={viewOnly} disabled={ctaDisabled}>
                        <ActionButton
                            loading={actionLoading}
                            disabled={ctaDisabled || oldEvent}
                            onClick={viewOnly ? null : onRegister || onUnregister || onBook}
                            style={
                                oldEvent
                                    ? {
                                          color: "rgba(0,0,0,.26)",
                                          backgroundColor: "rgba(0,0,0,.06)",
                                          cursor: "not-allowed"
                                      }
                                    : {
                                          color: onUnregister ? "white" : weeklyTheme.textPrimary,
                                          backgroundColor: onUnregister ? "#00C693" : weeklyTheme.primary,
                                          cursor: onUnregister || onBook || onRegister ? "pointer" : "not-allowed"
                                      }
                            }>
                            {onUnregister && !viewOnly ? <CheckOutlined style={{color: "white"}} /> : null}
                            {onUnregister && !viewOnly ? "Registered" : event.callToAction.name.text}
                        </ActionButton>
                    </CTATooltip>
                ) : null}
                {event.externalLink && event.externalLink.enabled ? (
                    <ActionButton
                        onClick={openEventExternalLink}
                        style={{
                            backgroundColor: weeklyTheme.primary,
                            cursor: "pointer"
                        }}>
                        {event.externalLink.buttonText}
                    </ActionButton>
                ) : null}
                {onEdit && !viewOnly ? (
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            height: 25
                        }}>
                        {onDisplayChange ? (
                            <div style={{display: "flex", alignItems: "center", paddingTop: 5}}>
                                <Switch
                                    checked={isDisplayed}
                                    onChange={displayed => onDisplayChange(event, displayed)}
                                    style={{marginRight: 10, backgroundColor: isDisplayed ? "#00C693" : void 0}}
                                />
                                <span
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: 16,
                                        color: "var(--secondary-color)",
                                        marginRight: 20
                                    }}>
                                    Displayed
                                </span>
                            </div>
                        ) : null}
                        <a
                            onClick={() => onEdit(event, dateMom)}
                            style={{
                                marginRight: 5,
                                height: 20,
                                fontSize: 16,
                                textDecoration: "underline",
                                color: "#8492A6"
                            }}>
                            Edit
                        </a>
                    </div>
                ) : null}
                {onJoin && (onUnregister || viewOnly) ? (
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            height: 25
                        }}>
                        <a
                            onClick={() => onJoin()}
                            style={{
                                marginRight: 5,
                                height: 24,
                                fontSize: 18,
                                textDecoration: "underline",
                                color: weeklyTheme.primary
                            }}>
                            Click here to join
                        </a>
                    </div>
                ) : null}
            </div>
        </AutoDirectionProvider>
    ) : (
        <div style={{marginBottom: 30}} />
    );
};
