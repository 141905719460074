import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Input, Modal, message, Dropdown, Tooltip} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import shortid from "shortid";
import {debounce} from "lodash";
import getColumns from "./adminPageLayoutColumn";
import {AdminSiteContext} from "./AdminSiteContext";
import {SubCategoryCarouselModal} from "./SubCategoryCarouselModal";
import {AddOrEditSubCategoryCarousel} from "./AddOrEditSubCategoryCarousel";
import {AddExistingSelectPageCarouselModal} from "./AddExistingSelectPageCarouselModal";
import {MoveCarouselModal} from "./MoveCarouselModal";
import {EditSelectionPage} from "../components/SelectProductsModal/EditSelectionPage";
import {RoundedTable, SearchInputWithIcon, SquareButton} from "../../components";
import {HttpClient} from "../../http/HttpClient";
import {useDebounceState, useRequest} from "../../utils/hooks";
import {SelectWithInputOption} from "../../components/SelectWithInputOption";
import {DraggableBodyRow, DraggableTable} from "../../components/dragableTable";
import {openWindow} from "../../http/WindowOpener";
import {SmartSwitch} from "../../components/SmartSwitch";
import "./page-layouts-admin.css";

const AdminPageLayouts = () => {
    const {
        subCategoryCarouselModal,
        setSubCategoryCarouselModal,
        carousels,
        setCarousels,
        selectedCarousel,
        setSelectedCarousel
    } = useContext(AdminSiteContext);

    const [searchInput, setSearchInput] = useState(null);
    const [selectedPageLayoutId, setSelectedPageLayoutId] = useState(null);
    const [selectPageModalVisible, setSelectPageModalVisible] = useState(false);
    const [selectorPageValues, setSelectorPageValues] = useState({});
    const [selectedSelectPageId, setSelectedSelectPageId] = useState(null);
    const [isEditSubCategoryCarouselModal, setIsEditSubCategoryCarouselModal] = useState(false);
    const [moveCarouselModal, setMoveCarouselModal] = useState(null);
    const [pageLayoutsFromServer, isPageLayoutLoading, _pageLayoutFromServerError, fetchPageLayoutFromServer] =
        useRequest("/api/pageLayout");
    const [titleInput, setTitleInput, unDebouncedTitleInput] = useDebounceState("", 2000);

    const [addExistingSelectPageCarouselModal, setAddExistingSelectPageCarouselModal] = useState(null);

    const pageLayoutOptions = useMemo(
        () =>
            pageLayoutsFromServer?.map(pageLayout => ({
                label: pageLayout.name,
                value: pageLayout.pageLayoutId
            })) ?? [],
        [pageLayoutsFromServer]
    );

    const filteredCarousels = useMemo(() => {
        if (!searchInput) return carousels;

        return (
            carousels.filter(({carouselName}) =>
                carouselName?.toLowerCase().includes(searchInput?.toLowerCase().trim())
            ) ?? []
        );
    }, [carousels, searchInput]);

    const addCarousel = useCallback(
        async carousel => {
            const {error} = await HttpClient.safePost("/api/pageLayout/addCarousel", {
                pageLayoutId: selectedPageLayoutId,
                carousel
            });

            if (error) {
                message.error("Error add new carousel to page");
                return {error};
            }

            fetchPageLayoutFromServer();
        },
        [selectedPageLayoutId]
    );

    const editCarousel = useCallback(async editedCarousel => {
        const {error} = await HttpClient.safePost("/api/carousel", {
            carousel: editedCarousel
        });

        if (error) {
            message.error("Error edit carousel");
            return {error};
        }

        fetchPageLayoutFromServer();
    }, []);

    const deleteCarousel = useCallback(async selectedCarouselId => {
        const {error} = await HttpClient.safeDelete(`/api/carousel/${selectedCarouselId}`);

        if (error) {
            message.error("שגיאה! הקרוסלה לא נמחקה");
            return {error};
        }
        fetchPageLayoutFromServer();
        setCarousels(currentCarousels => currentCarousels.filter(({carouselId}) => carouselId !== selectedCarouselId));
    }, []);

    const openEditCarouselModal = useCallback(
        selectedCarouselId => {
            const foundCarousel = carousels.find(({carouselId}) => carouselId === selectedCarouselId);

            if (!foundCarousel) {
                message.error("Carousel not found");
                return;
            }

            setSelectedCarousel(foundCarousel);
            setIsEditSubCategoryCarouselModal(true);
            setSubCategoryCarouselModal(true);
        },
        [carousels]
    );

    const openEditSelectPageCarouselModal = useCallback(
        async (selectPageId, selectedCarouselId) => {
            const selectPage = await HttpClient.safeGet(`/admin/api/selectPage/${selectPageId}`);

            if (selectPage?.error) {
                message.error(`SelectPage ${selectPageId} not found`);
                return;
            }

            const foundCarousel = carousels.find(({carouselId}) => carouselId === selectedCarouselId);

            if (!foundCarousel) {
                message.error("Carousel not found");
                return;
            }

            setSelectedCarousel(foundCarousel);
            setSelectorPageValues(selectPage);
            setSelectedSelectPageId(selectPageId);
            setSelectPageModalVisible(true);
        },
        [carousels]
    );

    const updateCarouselField = useCallback(async (carouselId, fieldName, fieldValue) => {
        const {error} = await HttpClient.safePost("/api/carousel/updateField", {
            carouselId,
            fieldName,
            fieldValue
        });

        if (error) {
            message.error("Error edit carousel field");
            return {error};
        }

        fetchPageLayoutFromServer();
    }, []);

    const updatePageLayoutField = useCallback(async (pageLayoutId, fieldName, fieldValue) => {
        const {error} = await HttpClient.safePost("/admin/api/pageLayout/updateField", {
            pageLayoutId,
            fieldName,
            fieldValue
        });

        if (error) {
            message.error("המידע על עמוד הקרוס לא נשמר");
            return {error};
        }

        message.success("המידע על עמוד הקרוס נשמר בהצלחה");
        await fetchPageLayoutFromServer();
    }, []);

    const createSelectPage = useCallback(async selectPageInfo => {
        const res = await HttpClient.safePut("/admin/api/selectPage", selectPageInfo);

        if (res?.error) {
            message.error("שגיאה בעת יצירת האתר");
            return {error: res.error};
        }

        message.success("האתר נוצר בהצלחה");
        fetchPageLayoutFromServer();
        return res;
    }, []);

    const editSelectPage = useCallback(async (selectPageId, selectPageInfo) => {
        const {error} = await HttpClient.safePost(`/admin/api/selectPage/${selectPageId}`, selectPageInfo);

        if (error) {
            message.error("שגיאה בעת עידכון נתוני עמוד הבחירה");
            return {error};
        }

        message.success("המידע התעדכן בהצלחה");
        fetchPageLayoutFromServer();
    }, []);

    useEffect(() => {
        if (!isPageLayoutLoading && pageLayoutsFromServer && selectedPageLayoutId) {
            const foundPageLayout = pageLayoutsFromServer.find(
                ({pageLayoutId}) => selectedPageLayoutId === pageLayoutId
            );
            setCarousels(foundPageLayout?.carousels ?? []);
            setTitleInput(foundPageLayout?.title ?? "");
        }
    }, [selectedPageLayoutId, pageLayoutsFromServer, isPageLayoutLoading]);

    const updateCarouselsOrder = useCallback(
        debounce(async carousels => {
            const {error} = await HttpClient.safePost(`/admin/api/pageLayout/${selectedPageLayoutId}/carousels`, {
                carousels
            });

            if (error) {
                message.error("שגיאה בעת שמירת סדר קרוסולות");
            } else {
                fetchPageLayoutFromServer();
                message.info("סדר קרוסלות עודכן :)");
            }
        }, 1000),
        [selectedPageLayoutId]
    );

    const onSortEnd = useCallback(
        ({oldIndex, newIndex}) => {
            setCarousels(prevCarousel => {
                const newOrder = [...prevCarousel];
                const [movedItem] = newOrder.splice(oldIndex, 1);
                newOrder.splice(newIndex, 0, movedItem);
                updateCarouselsOrder(newOrder.map(({carouselId}) => carouselId));
                return newOrder;
            });
        },
        [updateCarouselsOrder]
    );

    const openSelectionPage = useCallback(selectionPageId => {
        const link = `${window.location.origin}/selectionPage/${selectionPageId}`;
        window.open(link, "_blank");
    }, []);

    const openMoveCarouselModal = useCallback((carouselId, carouselName) => {
        setMoveCarouselModal({carouselId, carouselName});
    }, []);

    const moveCarouselToDifferentPageLayout = useCallback(
        async ({carouselId, newPageLayoutId}) => {
            const {error} = await HttpClient.safePost("/admin/api/pageLayout/moveCarousel", {
                carouselId,
                currentPageLayoutId: selectedPageLayoutId,
                newPageLayoutId
            });

            if (error) {
                message.error("שגיאה! הקרסולה לא הועברה לדף הנבחר");
                message.error(error);
                return;
            }

            message.success("הקרוסלה הועברה לדף הנבחר");
            setMoveCarouselModal(null);
            fetchPageLayoutFromServer();
        },
        [selectedPageLayoutId]
    );

    const currentSiteTitle = useMemo(() => {
        if (!selectedPageLayoutId) return "";
        const foundPageLayout = pageLayoutsFromServer.find(({pageLayoutId}) => pageLayoutId === selectedPageLayoutId);
        return foundPageLayout?.title ?? "";
    }, [selectedPageLayoutId, pageLayoutsFromServer]);

    const updatePageLayoutTitle = useCallback(
        title => {
            if (!selectedPageLayoutId) return;
            if (title === titleInput) return;
            if (!title) return;
            if (currentSiteTitle === title) return;

            const {error} = HttpClient.safePost(`/admin/api/pageLayout/${selectedPageLayoutId}/title`, {title});

            if (error) {
                message.error("שגיאה בעת שמירת כותרת האתר");
            } else {
                message.info("כותרת האתר עודכנה :)");
            }
        },
        [selectedPageLayoutId]
    );

    const onAddExistingSelectPageCarousel = useCallback(
        async ({selectPageId}) => {
            const {error} = await HttpClient.safePost("/admin/api/pageLayout/duplicatedSelectPageCarousel", {
                selectPageId,
                pageLayoutId: selectedPageLayoutId
            });

            if (error) {
                message.error(error);
                return;
            }

            message.success("הקרוסלה נוצרה בהצלחה");
            setAddExistingSelectPageCarouselModal(null);
            fetchPageLayoutFromServer();
        },
        [selectedPageLayoutId]
    );

    useEffect(() => {
        if (isPageLayoutLoading) {
            return;
        }
        updatePageLayoutTitle(titleInput);
    }, [isPageLayoutLoading, titleInput]);

    return (
        <>
            <SubCategoryCarouselModal
                visible={subCategoryCarouselModal}
                isEditMode={isEditSubCategoryCarouselModal}
                onClose={() => {
                    setSelectedCarousel([]);
                    setSubCategoryCarouselModal(false);
                    setIsEditSubCategoryCarouselModal(false);
                }}
                onSave={async (carousel, isEdit) => {
                    const res = await (isEdit ? editCarousel(carousel) : addCarousel(carousel));

                    if (!res?.error) {
                        setSubCategoryCarouselModal(false);
                        setIsEditSubCategoryCarouselModal(false);
                    }
                }}
            />

            <AddOrEditSubCategoryCarousel />

            <Modal
                maskClosable={false}
                footer={null}
                open={selectPageModalVisible}
                width={1800}
                destroyOnClose={true}
                onCancel={page => {
                    setSelectPageModalVisible(false);
                    setSelectorPageValues({});
                }}>
                <EditSelectionPage
                    selectPageId={selectorPageValues?.selectPageId}
                    onSave={async page => {
                        const isNew = !!!selectorPageValues?.selectPageId;
                        if (isNew) {
                            const newCarousel = await addCarousel({
                                carouselName: page.name,
                                selectPageId: page.selectPageId,
                                carouselId: shortid.generate()
                            });
                        } else {
                            await editCarousel({...selectedCarousel, carouselName: page.name});
                        }

                        setSelectPageModalVisible(false);
                        setSelectorPageValues({});
                    }}
                />
            </Modal>

            <MoveCarouselModal
                visible={!!moveCarouselModal}
                moveCarouselDetails={moveCarouselModal}
                pageLayouts={pageLayoutsFromServer}
                onSave={moveCarouselToDifferentPageLayout}
                onClose={() => setMoveCarouselModal(null)}
                currentPageLayoutId={selectedPageLayoutId}
            />

            <AddExistingSelectPageCarouselModal
                visible={!!addExistingSelectPageCarouselModal}
                onSave={onAddExistingSelectPageCarousel}
                onClose={() => setAddExistingSelectPageCarouselModal(null)}
            />

            <div className="page-layout-admin">
                <div className="page-layout-admin-top-bar">
                    <div className="page-layout-admin-top-bar-right-side">
                        <SelectWithInputOption
                            showSearch={true}
                            filterOption={(input, {label}) => label.includes(input)}
                            className="page-layout-admin-page-select"
                            options={pageLayoutOptions}
                            onSelect={async selectedOptionValue => {
                                const isExistOption = pageLayoutOptions.find(
                                    ({value}) => value === selectedOptionValue
                                );

                                if (!isExistOption) {
                                    const newPageLayout = await HttpClient.safePut("/api/pageLayout", {
                                        name: selectedOptionValue
                                    });
                                    if (newPageLayout?.error) {
                                        message.error("error on create new pageLayout");
                                        return;
                                    }

                                    fetchPageLayoutFromServer();
                                    setSelectedPageLayoutId(newPageLayout.pageLayoutId);
                                } else {
                                    setSelectedPageLayoutId(selectedOptionValue);
                                }
                            }}
                        />
                        <SearchInputWithIcon searchInput={searchInput} setSearchInput={setSearchInput} />
                        <Input
                            className="page-layout-custom-title"
                            value={unDebouncedTitleInput}
                            disabled={!selectedPageLayoutId}
                            onChange={({currentTarget}) => {
                                const title = currentTarget.value;
                                setTitleInput(title);
                            }}
                            placeholder="כותרת האתר"
                        />
                    </div>
                    <div className="page-layout-admin-top-bar-left-side">
                        <SmartSwitch
                            checkedText="עמוד קרוס סגור"
                            unCheckedText="עמוד קרוס פתוח"
                            disabled={!selectedPageLayoutId}
                            checked={
                                pageLayoutsFromServer?.find(({pageLayoutId}) => pageLayoutId === selectedPageLayoutId)
                                    ?.isIsolated
                            }
                            onChange={newValue => updatePageLayoutField(selectedPageLayoutId, "isIsolated", newValue)}
                        />

                        <SquareButton
                            disabled={!selectedPageLayoutId}
                            onClick={() =>
                                openWindow(`/dashboard/services/page/${selectedPageLayoutId}`, "_blank", true)
                            }>
                            פתח עמוד
                        </SquareButton>
                        <SquareButton
                            className="primary-color-button"
                            disabled={!selectedPageLayoutId}
                            onClick={() => setSubCategoryCarouselModal(true)}>
                            קרוסלת מיני קטגוריות
                        </SquareButton>

                        <Dropdown
                            disabled={!selectedPageLayoutId}
                            menu={{
                                items: [
                                    {
                                        key: "צור קרוסלה חדשה",
                                        disabled: !selectedPageLayoutId,
                                        label: (
                                            <div
                                                className="page-layout-admin-top-bar-dropdown-item"
                                                onClick={() => {
                                                    setSelectorPageValues({});
                                                    setSelectPageModalVisible(true);
                                                }}>
                                                צור קרוסלה חדשה
                                            </div>
                                        )
                                    },
                                    {
                                        key: "שייך קרוסלה קיימת",
                                        disabled: !selectedPageLayoutId,
                                        label: (
                                            <>
                                                <div
                                                    className="page-layout-admin-top-bar-dropdown-item"
                                                    onClick={() => {
                                                        setAddExistingSelectPageCarouselModal(true);
                                                    }}>
                                                    <div>שייך אתר בחירה לקרוסלה חדשה</div>
                                                    <Tooltip title="יצירה קרוסלה חדשה אשר מסתמכת על אתר בחירה קיים">
                                                        <QuestionCircleOutlined />
                                                    </Tooltip>
                                                </div>
                                            </>
                                        )
                                    }
                                ]
                            }}>
                            <SquareButton className="primary-color-button" disabled={!selectedPageLayoutId}>
                                קרוסלת מוצרים
                            </SquareButton>
                        </Dropdown>
                    </div>
                </div>
                <RoundedTable
                    rowKey="carouselId"
                    pagination={false}
                    loading={isPageLayoutLoading}
                    dataSource={filteredCarousels}
                    columns={getColumns(
                        async carouselId => deleteCarousel(carouselId),
                        carouselId => openEditCarouselModal(carouselId),
                        (carouselId, newValue) => updateCarouselField(carouselId, "isActive", newValue),
                        (selectPageId, carouselId) => openEditSelectPageCarouselModal(selectPageId, carouselId),
                        selectionPageId => openSelectionPage(selectionPageId),
                        (carouselId, carouselName) => openMoveCarouselModal(carouselId, carouselName),
                        (carouselId, newValue) => updateCarouselField(carouselId, "gridNumber", newValue)
                    )}
                    components={{
                        body: {
                            wrapper: props => <DraggableTable onSortEnd={onSortEnd} {...props} />,
                            row: props => (
                                <DraggableBodyRow dataSource={filteredCarousels} rowKey="carouselId" {...props} />
                            )
                        }
                    }}
                />
            </div>
        </>
    );
};

export default AdminPageLayouts;
