import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Select, Tag} from "antd";
import {SearchableSelect} from "../../components";
import {AutoDirectionProvider} from "../../AutoDirectionProvider";

const {Option, OptGroup} = Select;

export const AdminVendorsServicesModalItem = ({productSubCategories, productServices, onChange}) => {
    //TODO: useCallback

    const onSubCategorySelect = props => {
        const {name, ...value} = props;
        const newCategorySelected = (productSubCategories || []).concat([{...value, subCategory: name}]);
        onChange(newCategorySelected);
    };

    const onSubCategoryDeselect = id => {
        const newCategorySelected = productSubCategories.filter(subCategory => subCategory.id !== id);
        onChange(newCategorySelected);
    };

    const tagRender = useCallback(
        ({value, onClose}) => {
            const tag = productSubCategories.find(({id}) => id === value);
            return (
                <Tag key={tag.id} closable={true} onClose={onClose}>
                    {tag.subCategory}
                </Tag>
            );
        },
        [productSubCategories]
    );

    return (
        <div>
            <SearchableSelect
                mode="multiple"
                onDeselect={value => onSubCategoryDeselect(value)}
                style={{marginTop: 10, width: "400px"}}
                value={productSubCategories?.map(({id}) => id)}
                onSelect={(_, {sub}) => onSubCategorySelect(sub)}
                tagRender={tagRender}
                className="wb-profession-selection"
                placeholder="Select sub-categories">
                {productServices
                    ? productServices.map(({categoryName, subCategories}) => {
                          return (
                              <OptGroup key={categoryName} label={categoryName}>
                                  {subCategories
                                      .filter(({autoGenerated}) => !autoGenerated)
                                      .map(sub => {
                                          return (
                                              <Option key={sub.id} sub={sub} value={sub.id} title={sub.name}>
                                                  {sub.name}
                                              </Option>
                                          );
                                      })}
                              </OptGroup>
                          );
                      })
                    : []}
            </SearchableSelect>
        </div>
    );
};
