import React, {useCallback, useContext, useMemo} from "react";
import {message} from "antd";
import {set} from "lodash";
import shortid from "shortid";
import {AdminSiteContext} from "../AdminSiteContext";
import {FormModalMarketplaceViewAutoComplete} from "../../components";
import {EditableInput, FormModal} from "../../../components";
import {TrashIcon} from "../../../icons";
import {FileUpload} from "../../../components/FileUpload";
import MiniCategoryItem from "../../../marketplace/Carousel/MiniCategoryItem";
import {ServiceIdToName} from "../../../consts.js";

export const AddOrEditSubCategoryCarousel = () => {
    const {
        addOrEditSubCategoryCarouselModal,
        setAddOrEditSubCategoryCarouselModal,
        setSelectedCarousel,
        selectedCarouselItem,
        setSelectedCarouselItem,
        isEditCarouselItem,
        setIsEditCarouselItem
    } = useContext(AdminSiteContext);

    const addCarouselItem = useCallback(item => {
        setSelectedCarousel(currentCarousel => ({
            ...currentCarousel,
            carouselItems: [...(currentCarousel?.carouselItems ?? []), item]
        }));
    }, []);

    const editCarouselItem = useCallback(carouselItem => {
        setSelectedCarousel(currentCarousel => ({
            ...currentCarousel,
            carouselItems: currentCarousel?.carouselItems.map(item => {
                if (item.carouselItemId === carouselItem.carouselItemId) {
                    return carouselItem;
                }
                return item;
            })
        }));
    }, []);

    const onCloseModal = useCallback(() => {
        setSelectedCarouselItem({});
        setIsEditCarouselItem(false);
        setAddOrEditSubCategoryCarouselModal(false);
    }, []);

    const updateFormValues = useCallback((key, value) => {
        setSelectedCarouselItem(currentFormValues => set({...currentFormValues}, key, value));
    }, []);

    const contentFields = useMemo(
        () =>
            FormModalMarketplaceViewAutoComplete({
                itemType: selectedCarouselItem?.carouselItemType,
                content: selectedCarouselItem?.content,
                contentTypeFieldName: "carouselItemType",
                updateFormValues
            }),
        [selectedCarouselItem?.carouselItemType, selectedCarouselItem?.content, updateFormValues]
    );

    return (
        <FormModal
            modalWidth={600}
            visible={addOrEditSubCategoryCarouselModal}
            validationFunction={() => {
                if (!selectedCarouselItem.content) {
                    message.error("לא הוזן/נבחר אחד מאפשרויות המקבץ");
                    return false;
                }

                if (!selectedCarouselItem.image) {
                    message.error("לא נטענה תמונה");
                    return false;
                }

                return true;
            }}
            content={selectedCarouselItem}
            header={`${isEditCarouselItem ? "עריכת" : "הוספת"} מיני קטגוריה`}
            onUpdate={(_, changedField) => {
                const [key, value] = Object.entries(changedField)[0];
                updateFormValues(key, value);
            }}
            onSave={() => {
                if (isEditCarouselItem) {
                    editCarouselItem({...selectedCarouselItem});
                } else {
                    addCarouselItem({...selectedCarouselItem, carouselItemId: shortid.generate()});
                }
                onCloseModal();
            }}
            onClose={() => onCloseModal()}
            fields={[
                {
                    name: "name",
                    label: "כותרת מיני קטגוריה",
                    type: "custom",
                    customComponent: (
                        <>
                            <EditableInput
                                style={{
                                    width: "100%",
                                    minHeight: "20em",
                                    border: "1px solid #E8EDF5",
                                    backgroundColor: "#FCFDFE",
                                    fontSize: "16px",
                                    borderRadius: "5px",
                                    outline: "none"
                                }}
                                toolbar={{
                                    options: ["inline"],
                                    inline: {
                                        options: ["bold", "italic", "underline"]
                                    }
                                }}
                                onChange={newHtml => updateFormValues("name", newHtml)}
                                value={selectedCarouselItem.name}
                            />
                        </>
                    )
                },
                {
                    name: "btnText",
                    label: "טקסט כפתור",
                    type: "text",
                    optional: true,
                    info: "הטקסט של הכפתור יוצג בקרוסלת באנרים בלבד"
                },
                ...contentFields,
                {
                    name: "showItemsAmountTitle",
                    label: "הצג כמות אייטמים מתחת לכותרת המיני קטגורייה",
                    type: "boolean"
                },
                {
                    name: "categories",
                    label: "קטגוריות",
                    type: "select",
                    multiple: true,
                    optional: true,
                    options: Object.entries(ServiceIdToName).map(([key, value]) => ({name: key, label: value}))
                },
                {
                    name: "image",
                    type: "custom",
                    label: "תמונה",
                    customComponent: selectedCarouselItem?.image ? (
                        <>
                            <div style={{width: "260px"}}>
                                <MiniCategoryItem {...selectedCarouselItem} />
                            </div>
                            <div>
                                <TrashIcon
                                    style={{fill: "black", cursor: "pointer"}}
                                    onClick={() =>
                                        setSelectedCarouselItem(currentFields => {
                                            const {image, ...otherFields} = currentFields;
                                            return otherFields;
                                        })
                                    }
                                />
                                <a
                                    href={selectedCarouselItem.image?.imageUrl}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {selectedCarouselItem.image?.name}
                                </a>
                            </div>
                        </>
                    ) : (
                        <>
                            <FileUpload
                                acceptedFormats={"image/png,image/jpeg"}
                                multiple={false}
                                sizeLimitInMB={10}
                                onUploadSuccess={fileInfo => {
                                    updateFormValues("image", fileInfo);
                                }}
                            />
                        </>
                    )
                }
            ]}
        />
    );
};
