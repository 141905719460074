import React from "react";
import {Switch} from "antd";
import "./smart-switch.css";

export const SmartSwitch = ({checkedText, unCheckedText, onChange, checked, disabled}) => {
    return (
        <div className="smart-switch-container">
            <label>{unCheckedText}</label>
            <Switch disabled={disabled} onChange={onChange} checked={checked} />
            <label>{checkedText}</label>
        </div>
    );
};
