import {shippingProvidersOptions, shippingProvidersOptionsToName} from "./admin/upload/consts.jsx";
import common from "@well-b/well-b-shared";

export const VAT = 1.18;

export const AMOUNT_OF_SEARCH_RESULTS = 10;

export const ActivityLocationTypes = {
    online: "online",
    office: "office",
    onsite: "onsite",
    homeDelivery: "homeDelivery",
    manualLocation: "manualLocation",
    selfPickup: "selfPickup"
};

export const ActivityLocationTypesV1 = {
    offsite: "offsite"
};

export const ProviderActivityPossibleLocations = {
    [ActivityLocationTypes.online]: "זמין אונליין",
    [ActivityLocationTypes.office]: "שילוח לכתובת הרשומה",
    [ActivityLocationTypes.onsite]: "בבית העסק",
    [ActivityLocationTypes.homeDelivery]: "משלוח לבית מקבל המארז",
    [ActivityLocationTypes.manualLocation]: "כתובת לשילוח",
    [ActivityLocationTypes.selfPickup]: "איסוף עצמי"
};

export const UserType = {
    Company: "company",
    Provider: "provider",
    Employee: "employee"
};

export const UserTypeToHebrew = {
    [UserType.Company]: "חברה",
    [UserType.Provider]: "שותף",
    [UserType.Employee]: "עובד חברה (V1)"
};

export const ActivityLocation = {
    ...ProviderActivityPossibleLocations,
    offsite: "במיקום חיצוני",
    manualLocationSubtitle: "יש להזין כתובת מלאה הכוללת עיר ורחוב"
};

export const ActivityLocationSubtitles = {
    [ActivityLocation.online]: "ניתן לסנכרן חשבון זום ולייצור לינק לאחר אישור הזמנה"
};

export const ProductUpgradeOptionsAmountType = {
    byUnit: "byUnit",
    fixedPrice: "fixedPrice"
};

export const ProductUpgradeOptionsAmountTypeToName = {
    [ProductUpgradeOptionsAmountType.byUnit]: "ליחידה",
    [ProductUpgradeOptionsAmountType.fixedPrice]: "עלות קבועה"
};

export const PaymentType = {
    Card: "card",
    BankTransfer: "bankTransfer",
    Budget: "budget",
    TenBisCard: "tenBisCard"
};

export const PaymentTypeToName = {
    [PaymentType.Card]: "כרטיס אשראי",
    [PaymentType.BankTransfer]: "חשבונית מרכזת",
    [PaymentType.Budget]: "תקציב טעון",
    [PaymentType.TenBisCard]: "כרטיס תן ביס"
};

export const AllowedSystemIdPaymentType = {
    [common.themeNames.tenBis]: [PaymentType.TenBisCard, PaymentType.Budget],
    [common.themeNames.wellB]: [PaymentType.Card, PaymentType.BankTransfer, PaymentType.Budget]
};

export const AllowedSystemIdBudgetLoadPaymentType = {
    [common.themeNames.tenBis]: [PaymentType.TenBisCard],
    [common.themeNames.wellB]: [PaymentType.Card, PaymentType.BankTransfer]
};

export const MainServices = {
    HAPPY_HOURS: "happyhours",
    PACKAGES: "packages",
    PLATTERS: "platters",
    LECTURES: "lectures",
    WORKSHOPS: "workshops"
};

export const ServiceIdToName = {
    [MainServices.HAPPY_HOURS]: "האפי האוורס",
    [MainServices.PACKAGES]: "מתנות",
    [MainServices.PLATTERS]: "מגשי אירוח",
    [MainServices.WORKSHOPS]: "פעילות צוותית",
    [MainServices.LECTURES]: "הרצאות"
};

export const timeToAccept = {
    FAST: {name: shippingProvidersOptionsToName[shippingProvidersOptions.wellbDelivery], timeInMinutes: 60},
    REGULAR: {name: shippingProvidersOptionsToName[shippingProvidersOptions.regular], timeInMinutes: 240}
};

export const GoogleMapMainAreaKey = "administrative_area_level_1";
export const GoogleMapSubAreaApiKeys = ["administrative_area_level_2", "locality"];

export const ValidateBookingError = {
    NO_ACCESS: "אינך בעל הרשאה לביצוע הזמנה",
    NO_TIME_OR_DATE: "יש להזין תאריך ושעה לאספקה",
    NO_PAYMENT_TYPE: "יש לבחור אמצעי תשלום",
    NO_PAYMENT_TYPE_CREDIT_CARD: "יש להזין מס׳ כרטיס אשראי או לבחור באמצעי תשלום אחר",
    NO_PAYMENT_TYPE_TEN_BIS_CARD: "יש להזין מס׳ כרטיס תן ביס תקין",
    BUDGET_NOT_ENOUGH: "לא נותר מספיק תקציב לביצוע ההזמנה",
    NO_AMOUNT: "יש להגדיר כמות מבוקשת",
    NO_DELIVERY: "בעיה במחיר השילוח, יש לוודא שאין בעיה בחלק בטופס בשם ״איפה?״",
    NO_LOCATION: "יש להגדיר כתובת לאספקה",
    NO_FLOOR: "יש להגדיר קומה בכתובת לאספקה",
    NO_AMOUNT_UPGRADE_OPTIONS: "יש לבחור כמות לאפשרויות שדרוג",
    NO_INVOICE_PO_NUMBER: "בארגון שלכם חובה להזין מס׳ הזמנת רכש במעמד ההזמנה",
    NO_MODULAR_AMOUNT: "יש לבחור לפחות פריט אחד להזמנה",
    MAX_PRODUCT_AMOUNT: "יש לשים לב שכמות המוצר גבוהה מהמותר",
    MIN_PRODUCT_AMOUNT: "יש לשים לב שכמות המוצר נמוכה מהמותר",
    POLICY_CONFIRMATION_REQUIRED: "יש לאשר את תנאי השימוש לפני ביצוע ההזמנה"
};

export const ValidateVoucherBookingError = {
    NO_SENDER_NAME: "יש להזין את שם השולח",
    NO_TIME_OPTION: "יש לבחור מתי לשלוח את המתנה",
    NO_SEND_OPTION: "יש לבחור איך לשלוח את המתנה",
    NO_VOUCHER: "יש לבחור את סכום השובר מתוך הרשימה",
    NO_RECEIVER: "יש לבחור ולהזין את פרטי מקבל/י המתנה",
    NO_RECEIVER_SMS_PHONE: "יש להזין מספר תקין לפרטי מקבל המתנה",
    NO_RECEIVER_EMAIL: "יש להזין כתובת מייל תקינה לפרטי מקבל המתנה",
    NO_DATE_OR_TIME: "יש להזין תאריך ושעה למועד שליחה מאוחר יותר"
};

export const Sectors = {
    HighTech: "highTech",
    Cellular: "cellular",
    Finance: "finance",
    Defence: "defence",
    Food: "food",
    Insurance: "insurance",
    Advertising: "advertising",
    Law: "law",
    Accounting: "accounting",
    Investing: "investing",
    RealEstate: "realEstate",
    CoworkingSpaces: "coworkingSpaces",
    public: "public",
    Other: "other"
};

export const SectorsNames = {
    [Sectors.HighTech]: "הייטק",
    [Sectors.Cellular]: "תקשורת וסלולר",
    [Sectors.Finance]: "בנקאות ופיננסים",
    [Sectors.Defence]: "תעשיה בטחונית",
    [Sectors.Food]: "ייצור מזון ומשקאות",
    [Sectors.Insurance]: "ביטוח",
    [Sectors.Advertising]: "פרסום ושיווק",
    [Sectors.Law]: "פירמת עורכי דין",
    [Sectors.Accounting]: "ראיית חשבון",
    [Sectors.Investing]: "השקעות / הון סיכון",
    [Sectors.RealEstate]: "נדלן",
    [Sectors.CoworkingSpaces]: "חללי עבודה",
    [Sectors.public]: "ציבורי",
    [Sectors.Other]: "אחר"
};

export const excludePaths = [
    {path: /^\/$/, method: "GET"},
    {path: /^\/email\/(.*)\/verify/, method: "GET"},
    {path: /^\/public(.*)/, method: "GET"},
    {path: /^\/zoomverify(.*)/, method: "GET"},
    {path: /^\/static(.*)/, method: "GET"},
    {path: /^\/favicon(.*)/, method: "GET"},
    {path: /^\/api\/login/, method: "POST"},
    {path: /^\/login/, method: "GET"},
    {path: /^\/forgot/, method: "GET"},
    {path: /^\/contact/, method: "GET"},
    {path: /^\/zoom_docs/, method: "GET"},
    {path: /^\/api\/zoom\/logout/, method: "POST"},
    {path: /^\/api\/contact/, method: "PUT"},
    {path: /^\/terms/, method: "GET"},
    {path: /^\/privacy/, method: "GET"},
    {path: /^\/api\/logout/, method: "GET"},
    {path: /^\/api\/companies/, method: "PUT"},
    {path: /^\/company\/signup/, method: "GET"},
    {path: /^\/company\/wizard(.*)/, method: "GET"},
    {path: /^\/api\/companies\/brand/, method: "GET"},
    {path: /^\/api\/companies\/(.*)\/signup/, method: "PUT"},
    {path: /^\/api\/companies\/(.*)\/shallow/, method: "GET"},
    {path: /^\/companies\/(.*)\/signup(.*)/, method: "GET"},
    {path: /^\/api\/providers/, method: "PUT"},
    {path: /^\/provider\/signup/, method: "GET"},
    {path: /^\/provider\/upload/, method: "GET"},
    {path: /^\/api\/services/, method: "POST"},
    {path: /^\/provider\/wizard/, method: "GET"},
    {path: /^\/api\/users\/me/, method: "GET"},
    {path: /^\/api\/services\/signup/, method: "GET"},
    {path: /^\/api\/professions/, method: "GET"},
    {path: /^\/api\/users\/password\/forgot/, method: "POST"},
    {path: /^\/api\/billing\/banks/, method: "GET"},
    {path: /^\/api\/provider\/image/, method: "PUT"},
    {path: /^\/api\/provider\/cover/, method: "PUT"},
    {path: /^\/api\/users\/email\/exist/, method: "POST"},
    {path: /^\/api\/users\/email\/gmail/, method: "POST"},
    {path: /^\/password\/(.*)\/expired/, method: "GET"},
    {path: /^\/api\/google\/oauth2callback/, method: "GET"},
    {path: /^\/api\/google\/auth/, method: "POST"},
    {path: /^\/api\/microsoft\/login/, method: "GET"},
    {path: /^\/api\/zoom\/logout/, method: "POST"},
    {path: /^\/api\/slack\/grant/, method: "GET"},
    {path: /^\/api\/mail\/webhook/, method: "POST"},
    {path: /^\/unsubscribe/, method: "GET"},
    {path: /^\/api\/mail\/unsubscribe/, method: "GET"},
    {path: /^\/api\/mail\/unsubscribe/, method: "POST"},
    {path: /^\/api\/mail\/track/, method: "GET"},
    {path: /^\/unsubscribe/, method: "GET"},
    {path: /^\/api\/terms/, method: "GET"},
    {path: /^\/api\/home/, method: "GET"},
    {path: /^\/api\/image\/download/, method: "GET"},
    {path: /^\/api\/image/, method: "PUT"},
    {path: /^\/api\/weekly\/images\/random/, method: "GET"},
    {path: /^\/api\/portfolio\/create\/image/, method: "PUT"},
    {path: /^\/password\/reset/, method: "GET"},
    {path: /^\/api\/users\/password\/resetByToken/, method: "POST"}
];

export const originMapping = {
    "127.0.0.1": common.themeNames.tenBis,
    "app-stg-tenbis.well-b.biz": common.themeNames.tenBis,
    "10bis-moments.co.il": common.themeNames.tenBis,
    "www.10bis-moments.co.il": common.themeNames.tenBis
};

export const themeNamesToText = {
    [common.themeNames.tenBis]: "תן ביס Moments"
};

export const systemThemeName = "well-b";
