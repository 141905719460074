import React, {useEffect, useRef, useState} from "react";
import {Carousel} from "antd";
import "./carousel.css";
import {isNull, isNumber, isUndefined} from "lodash";

let settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

settings = {};

export const WBCarousel = ({containerClassName, carouselProps, children, primaryColor = "var(--secondary-color)"}) => {
    const [originalOnInit] = useState(carouselProps.onInit || null);
    const [originalAfterChange] = useState(carouselProps.afterChange || null);
    const carouselRef = useRef(null);

    if (!containerClassName && carouselProps.className) {
        containerClassName = `.${carouselProps.className.replace(" ", ".")}`;
    }

    useEffect(() => {
        if (carouselRef.current && !isNaN(parseInt(carouselProps?.initialSlide))) {
            carouselRef.current.goTo(carouselProps.initialSlide, true);
        }
    }, [carouselProps?.initialSlide, carouselRef]);

    const markActiveDot = () => {
        const dots = document.querySelectorAll(`${containerClassName} .slick-dots.slick-dots-bottom li button`);
        if (dots && dots.length > 0) {
            for (let idx = 0; idx < dots.length; idx++) {
                dots[idx].style.backgroundColor = "#DAE0E4";
            }
        }

        const activeDot = document.querySelector(
            `${containerClassName} .slick-dots.slick-dots-bottom li.slick-active button`
        );
        if (activeDot) {
            activeDot.style.backgroundColor = primaryColor;
        }
    };

    carouselProps.onInit = () => {
        markActiveDot();
        originalOnInit && originalOnInit();
    };

    carouselProps.afterChange = index => {
        markActiveDot();
        originalAfterChange && originalAfterChange(index);
    };

    return (
        <Carousel {...settings} ref={carouselRef} slidesToShow={3} className="wb-carousel" {...carouselProps}>
            {children}
        </Carousel>
    );
};
