import React, {useMemo} from "react";
import {useWindowDimensions} from "../../utils/hooks.jsx";
import {isDesktop, isMobile} from "react-device-detect";
import {Devices, MIN_WIDTH_DESKTOP} from "./consts.js";
import classnames from "classnames";

export const ResponsiveView = ({devices = [], children}) => {
    const {width} = useWindowDimensions();
    const isUseResponsiveScreen = import.meta.env.VITE_USE_RESPONSIVE_SCREEN === "true";

    const showComponent = useMemo(() => {
        const deviceCheckers = {
            [Devices.desktop]: isUseResponsiveScreen ? width > MIN_WIDTH_DESKTOP : isDesktop,
            [Devices.mobile]: isUseResponsiveScreen ? width <= MIN_WIDTH_DESKTOP : isMobile
        };

        return devices.some(device => deviceCheckers[device]);
    }, [devices, isUseResponsiveScreen, width]);

    if (!showComponent) {
        return <></>;
    }

    return React.isValidElement(children)
        ? React.cloneElement(children, {
              className: classnames(children.props.className, devices.join("."))
          })
        : children;
};
