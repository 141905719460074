import React from "react";
import {Dropdown, Menu, Spin, Switch, Tooltip} from "antd";
import {DeleteOutlined, EllipsisOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {CouponConditionNames, CouponDiscountTypes, CouponDiscountTypeToName} from "./consts.js";
import {NOOP} from "../../utils/NOOP.jsx";
import {TrashIcon} from "../../icons/index.jsx";

export const columns = (couponsUses, loadingCoupons, deleteCoupon, onChangeStatus = NOOP, loadAdvanceStats) => {
    return [
        {
            title: "#",
            key: "#",
            width: 70,
            render(_, __, idx) {
                return `${idx + 1}`;
            }
        },
        {
            title: "קוד",
            dataIndex: "code"
        },
        {
            title: "סוג ההנחה",
            dataIndex: "discountType",
            render(discountType = CouponDiscountTypes.ByPrice) {
                return CouponDiscountTypeToName?.[discountType] ?? "---";
            }
        },
        {
            title: "הנחה",
            dataIndex: "discount"
        },
        {
            title: "סוג קופון",
            key: "singleUse",
            render(_, {singleUse}) {
                return (
                    <span className={singleUse ? "admin-coupons-single-use" : "admin-coupons-multi-use"}>
                        {singleUse ? "חד פעמי" : "רב פעמי"}
                    </span>
                );
            }
        },
        {
            title: (
                <span className="admin-coupons-uses-title">
                    מספר שימושים
                    <Tooltip title="העבירו את העכבר על המספר בשביל לראות איזה חברות השתמשו">
                        <InfoCircleOutlined />
                    </Tooltip>
                </span>
            ),
            key: "uses",
            render(_, {code}) {
                if (!loadAdvanceStats) {
                    return <Tooltip title={"טען סטטיסטיקה מורחבת על מנת לחשוף את הערכים הנוכחיים"}>{"---"}</Tooltip>;
                }

                return loadingCoupons ? (
                    <Spin />
                ) : (
                    <Tooltip
                        title={couponsUses?.[code]?.map(company => (
                            <div>{company}</div>
                        ))}>
                        {couponsUses?.[code]?.length ?? 0}
                    </Tooltip>
                );
            }
        },
        {
            title: "סוג תנאי",
            key: "conditionType",
            render(_, {condition}) {
                return CouponConditionNames[condition.type];
            }
        },
        {
            title: "ערך תנאי",
            key: "conditionValue",
            render(_, {condition}) {
                return condition.value;
            }
        },
        {
            title: "סטאטוס",
            dataIndex: "isActive",
            render(isActive, {code}) {
                return (
                    <Switch
                        checked={!!isActive}
                        onChange={newStatus => onChangeStatus({code, newStatus})}
                        checkedChildren={"פעיל"}
                        unCheckedChildren={"לא פעיל"}></Switch>
                );
            }
        },
        {
            key: "action",
            width: 120,
            render(_, coupon) {
                return (
                    <div className="admin-orders-actions-cell">
                        <Dropdown
                            menu={{
                                items: [
                                    {
                                        label: (
                                            <Tooltip
                                                placement="topLeft"
                                                title={
                                                    !loadAdvanceStats ? "נא להרחיב סטטיסטיקה נוספת על מנת למחוק" : ""
                                                }>
                                                <span>מחק קופון</span>
                                            </Tooltip>
                                        ),
                                        key: "deleteCoupon",
                                        danger: true,
                                        disabled: !loadAdvanceStats,
                                        icon: <DeleteOutlined />,
                                        onClick: () => deleteCoupon(coupon)
                                    }
                                ]
                            }}>
                            <EllipsisOutlined className="admin-orders-order-action-button" />
                        </Dropdown>
                    </div>
                );
            }
        }
    ];
};
