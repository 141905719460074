import {Dropdown} from "antd";
import {EllipsisOutlined} from "@ant-design/icons";
import React from "react";
import {ConfirmationRequiredWrapper} from "../../../components";

export const getColumns = onDelete => [
    {
        title: "מזהה",
        dataIndex: "presetId"
    },
    {
        title: "שם",
        dataIndex: "name"
    },
    {
        key: "action",
        render(_, {presetId}) {
            const menu = {
                items: [
                    {
                        danger: true,
                        key: "delete",
                        label: (
                            <ConfirmationRequiredWrapper onYes={() => onDelete(presetId)}>
                                מחק פריסט
                            </ConfirmationRequiredWrapper>
                        )
                    }
                ]
            };

            return (
                <div className="admin-orders-actions-cell">
                    <Dropdown menu={menu} trigger={["click"]}>
                        <EllipsisOutlined className="admin-orders-order-action-button" />
                    </Dropdown>
                </div>
            );
        }
    }
];
