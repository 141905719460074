import React, {useCallback, useEffect} from "react";
import {CheckOutlined} from "@ant-design/icons";
import classnames from "classnames";
import {useRequest} from "../../utils/hooks";
import "./calculator-wizard.css";

export const OccasionCategoryStep = ({
    selectedOccasionCategories,
    setSelectedOccasionCategories,
    defaultAllSelected = true
}) => {
    const [allOccasionCategories] = useRequest("/api/occasions/categories");

    useEffect(() => {
        if (allOccasionCategories?.length && defaultAllSelected) {
            setSelectedOccasionCategories(allOccasionCategories?.map(({occasionCategoryId}) => occasionCategoryId));
        }
    }, [allOccasionCategories, defaultAllSelected]);

    const selectedEventTypeHandler = useCallback(id => {
        setSelectedOccasionCategories(current => {
            if (current.indexOf(id) === -1) {
                return [...current, id];
            } else {
                return current.filter(item => item !== id);
            }
        });
    }, []);

    return (
        <>
            <div className="calculator-wizard-title">אילו סעיפי תקציב תרצו להגדיר בתוכנית שלכם?</div>

            <div className="occasion-category-step-container">
                {allOccasionCategories &&
                    allOccasionCategories.map(({occasionCategoryId, icon, categoryName, description}) => {
                        const isItemSelected = selectedOccasionCategories.indexOf(occasionCategoryId) !== -1;

                        return (
                            <div key={occasionCategoryId} className="occasion-category-step-item">
                                <div
                                    className={classnames("occasion-category-step-left-arrow", {
                                        selected: isItemSelected
                                    })}>
                                    <CheckOutlined />
                                </div>
                                <div
                                    className={classnames("occasion-category-step-content", {selected: isItemSelected})}
                                    onClick={() => selectedEventTypeHandler(occasionCategoryId)}>
                                    <div>{icon}</div>
                                    <div>{categoryName}</div>
                                    <div>{description}</div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </>
    );
};
