import React from "react";
import {message, Table} from "antd";
import {sumBy} from "lodash";
import {StringBuilder} from "../../AppUtils.js";
import {ProductUpgradeOptionsAmountType} from "../../consts.js";
import {OrderStatus, statusToTextAndColor} from "../../components/index.jsx";
import {isOrderHasShippingProvider} from "../utils.jsx";
import {
    generateLionWheelOrderDeliveryCertificateLink,
    generateLionWheelOrderDeliveryStatusLink,
    getOrderStepStatus
} from "../OrderDeliveryStatus/utils.jsx";
import moment from "moment";
import {declineOrderOptionsToText} from "../DeclineOrderModal/consts.js";
import {dateTimeRange} from "../../utils/DateUtils.jsx";
import {kosherOptions} from "../../admin/upload/AdminUploadUtils.jsx";
import {openWindow} from "../../http/WindowOpener.jsx";
import {createUrlWithoutTransformations} from "../../utils/cloudinaryUtils.jsx";
import classnames from "classnames";
import {NOOP} from "../../utils/NOOP.jsx";

export const generateTableContent = eventInfo => {
    if (eventInfo.isVoucherOrder) {
        return {
            dataSource: [],
            columns: [],
            summary: () => <></>
        };
    }

    const isModularOrder =
        Array.isArray(eventInfo?.modularProducts) &&
        eventInfo?.modularProducts?.length &&
        eventInfo?.productAmountType === "חבילה";

    const amountTitle = new StringBuilder("כמות")
        .append(isModularOrder ? "פריטים" : eventInfo.productAmountType)
        .toString();

    const priceTitle = isModularOrder ? "מחיר" : `מחיר ל${eventInfo.productAmountType}`;

    const columns = [
        {
            title: "תיאור",
            dataIndex: "desc"
        },
        {
            title: amountTitle,
            className: "amount",
            dataIndex: "amount"
        },
        ...(isModularOrder
            ? [
                  {
                      title: priceTitle,
                      dataIndex: "pricePerUnit"
                  },
                  {
                      title: "סה״כ",
                      dataIndex: "totalPrice"
                  }
              ]
            : [])
    ];

    const dataSource = isModularOrder
        ? eventInfo.modularProducts.map(subProduct => ({
              desc: (
                  <>
                      <div className="order-information-card-details-table-title">{subProduct?.name}</div>
                      <div>{subProduct?.description}</div>
                      {!!subProduct.catalogNumber && (
                          <div>{new StringBuilder().append(`מק"ט:`).append(subProduct.catalogNumber).toString()}</div>
                      )}
                  </>
              ),
              amount: subProduct.amount,
              pricePerUnit: new StringBuilder().append(subProduct.price).append("₪").toString(),
              totalPrice: new StringBuilder()
                  .append(subProduct?.price * subProduct.amount)
                  .append("₪")
                  .toString()
          }))
        : [
              {
                  desc: (
                      <>
                          <span>
                              <b>{eventInfo.productName}</b>
                          </span>
                          {eventInfo.productPackageInfo ? (
                              <div className="order-information-card-details-table-desc">
                                  {eventInfo.productPackageInfo}
                              </div>
                          ) : (
                              <div
                                  className="order-information-card-details-table-desc"
                                  dangerouslySetInnerHTML={{__html: eventInfo.productDescription}}
                              />
                          )}
                      </>
                  ),
                  amount: eventInfo.productAmount
              }
          ];

    if (eventInfo.upgradeOptions?.options?.length && isModularOrder) {
        for (const upgradeOption of eventInfo.upgradeOptions.options) {
            const amount = upgradeOption.type === ProductUpgradeOptionsAmountType.byUnit ? upgradeOption.amount : 1;
            dataSource.push({
                desc: (
                    <div>
                        <div className="order-information-card-details-table-title">
                            {new StringBuilder().append("שידרוג - ").append(upgradeOption?.name).toString()}
                        </div>
                        <div className="order-information-card-details-table-desc">{upgradeOption?.description}</div>
                    </div>
                ),
                amount,
                pricePerUnit: new StringBuilder().append(`${upgradeOption.price}`).append("₪").toString(),
                totalPrice: new StringBuilder()
                    .append(amount * upgradeOption.price)
                    .append("₪")
                    .toString()
            });
        }
    }

    if (eventInfo?.upgradeOptions?.files?.length) {
        dataSource.push({
            desc: (
                <div>
                    <div className="order-information-card-details-table-title">
                        {new StringBuilder().append("קבצים רלוונטים (לוגו, גרפיקות וכו׳)").toString()}
                    </div>
                    <div
                        className={classnames(
                            "order-information-card-details-table-desc",
                            "order-information-card-details-table-link"
                        )}
                        onClick={() =>
                            openWindow(createUrlWithoutTransformations(eventInfo?.upgradeOptions?.files?.[0]?.fileUrl))
                        }>
                        {"פתיחת קבצים רלוונטים"}
                    </div>
                </div>
            ),
            amount: eventInfo?.upgradeOptions?.files?.length,
            pricePerUnit: "",
            totalPrice: ""
        });
    }

    return {
        summary: () => {
            if (!isModularOrder) {
                return (
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>סה״כ</Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>{eventInfo?.productAmount}</Table.Summary.Cell>
                    </Table.Summary.Row>
                );
            }

            const totalAmount =
                sumBy(eventInfo.modularProducts, subProduct => subProduct.amount) +
                (eventInfo.upgradeOptions?.options?.length ?? 0) +
                (eventInfo?.upgradeOptions?.files?.length ?? 0);

            const totalModularProducts = sumBy(
                eventInfo.modularProducts,
                subProduct => subProduct?.price * subProduct.amount
            );

            const totalUpgradePrice = sumBy(eventInfo.upgradeOptions?.options, upgradeOption => {
                const amount = upgradeOption.type === ProductUpgradeOptionsAmountType.byUnit ? upgradeOption.amount : 1;
                return amount * upgradeOption.price;
            });

            const totalPrice = totalModularProducts + totalUpgradePrice;

            return (
                <>
                    <Table.Summary.Row className="">
                        <Table.Summary.Cell index={0}>{"סה״כ כולל מע״מ"}</Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>{totalAmount}</Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>{""}</Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                            {new StringBuilder().append(totalPrice.toFixed(2)).append("₪").toString()}
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                </>
            );
        },
        dataSource,
        columns
    };
};

export const getOrderViewStatusContentForRegularShippingProvider = order => {
    if (!order) {
        return [];
    }

    const {isOrderReviewedByManager, isOrderAccepted, isOrderDelivered} = getOrderStepStatus(order);

    return [
        {
            id: "pending for manager approve",
            title: statusToTextAndColor?.[order?.status]?.[0],
            subTitle: "ההזמנה ממתינה לאישור מנהל, לאחר האישור היא תעבור לבית העסק",
            isStepComplete: !!order?.needManagerConfirm,
            shouldHide: !order?.needManagerConfirm
        },
        {
            id: "manager approve comments",
            title: statusToTextAndColor?.[order?.status]?.[0],
            subTitle: "סיבת סירוב המנהל:",
            description: [order?.cancelReason || "-"],
            isStepComplete: !!isOrderReviewedByManager,
            shouldHide: !order?.needManagerConfirm
        },
        {
            id: "order sent to provider approve",
            title: statusToTextAndColor?.[order?.status]?.[0],
            titleColor: "yellow",
            backgroundColor: "yellow",
            description: [
                new StringBuilder()
                    .append("ברגעים אלה צוות")
                    .append(order.providerBrand)
                    .append("עובר על הזמנתך")
                    .toString()
            ],
            endTime: moment(order.createdAt).add("4", "hour"),
            isStepComplete: order?.status === OrderStatus.pending
        },
        {
            id: "order canceled",
            title: statusToTextAndColor?.[order?.status]?.[0],
            titleColor: "gray",
            backgroundColor: "gray",
            isStepComplete: order?.status === OrderStatus.cancelled
        },
        {
            id: "order declined",
            title: statusToTextAndColor?.[order?.status]?.[0],
            titleColor: "red",
            subTitle: "סיבת סירוב:",
            description: [declineOrderOptionsToText?.[order?.declineReason], order.declineComment].filter(Boolean),
            backgroundColor: "red",
            isStepComplete: order?.status === OrderStatus.declined && !isOrderReviewedByManager
        },
        {
            id: "provider approve",
            title: statusToTextAndColor?.[order?.status]?.[0],
            titleColor: "blue",
            backgroundColor: "blue",
            description: [
                new StringBuilder()
                    .append("הזמנה שביצעת אושרה על ידי")
                    .append(order?.providerBrand || "הספק")
                    .append("איזה כיף! 🤩")
                    .toString()
            ],
            isStepComplete: order.status === OrderStatus.accepted,
            actions: [
                {
                    text: "לבירור איפה ההזמנה מול בית העסק",
                    onClick: () => {
                        message.error("ביום האספקה ניתן יהיה לדבר ישירות עם בית העסק לבדיקת סטטוס האספקה.");
                    },
                    color: "gray"
                }
            ]
        },
        {
            id: "provider approve and start of dtStart",
            title: statusToTextAndColor?.[order?.status]?.[0],
            titleColor: "blue",
            backgroundColor: "blue",
            description: [
                new StringBuilder().append("לבירור איפה ההזמנה מול בית העסק ניתן להתקשר לטלפון:").toString(),
                <a href={`tel:${order?.providerPhone}`}>{order?.providerPhone}</a>
            ],
            isStepComplete: !!(isOrderAccepted && moment().isAfter(order.dtstart)),
            shouldHide: !!isOrderHasShippingProvider(order)
        },
        {
            id: "order arrived to destination",
            title: statusToTextAndColor?.[OrderStatus.delivered]?.[0],
            description: ["ההזמנה הגיעה ליעד"],
            isStepComplete: !!isOrderDelivered,
            titleColor: "green",
            backgroundColor: "green"
        }
    ].filter(orderStep => !orderStep.shouldHide && orderStep.isStepComplete);
};

export const getOrderViewStatusContentForShippingProvider = order => {
    if (!order) {
        return [];
    }

    const {isOrderReviewedByManager, isOrderDelivered, isOrderOnTheWay} = getOrderStepStatus(order);

    return [
        {
            id: "pending for manager approve",
            title: statusToTextAndColor?.[order?.status]?.[0],
            subTitle: "ההזמנה ממתינה לאישור מנהל, לאחר האישור היא תעבור לבית העסק",
            isStepComplete: !!order?.needManagerConfirm,
            shouldHide: !order?.needManagerConfirm
        },
        {
            id: "manager approve comments",
            title: statusToTextAndColor?.[order?.status]?.[0],
            subTitle: "סיבת סירוב המנהל:",
            description: [order?.cancelReason || "-"],
            isStepComplete: !!isOrderReviewedByManager,
            shouldHide: !order?.needManagerConfirm
        },
        {
            id: "order sent to provider approve",
            title: statusToTextAndColor?.[order?.status]?.[0],
            titleColor: "yellow",
            backgroundColor: "yellow",
            description: [
                new StringBuilder()
                    .append("ברגעים אלה צוות")
                    .append(order.providerBrand)
                    .append("עובר על הזמנתך")
                    .toString()
            ],
            endTime: moment(order.createdAt).add("4", "hour"),
            isStepComplete: order?.status === OrderStatus.pending
        },
        {
            id: "order canceled",
            title: statusToTextAndColor?.[order?.status]?.[0],
            titleColor: "gray",
            backgroundColor: "gray",
            isStepComplete: order?.status === OrderStatus.cancelled
        },
        {
            id: "order declined",
            title: statusToTextAndColor?.[order?.status]?.[0],
            titleColor: "red",
            subTitle: "סיבת סירוב:",
            description: [declineOrderOptionsToText?.[order?.declineReason], order.declineComment].filter(Boolean),
            backgroundColor: "red",
            isStepComplete: order.needManagerConfirm
                ? order?.status === OrderStatus.declined && isOrderReviewedByManager
                : order?.status === OrderStatus.declined
        },
        {
            id: "provider approve",
            title: statusToTextAndColor?.[order?.status]?.[0],
            titleColor: "blue",
            backgroundColor: "blue",
            description: [
                new StringBuilder()
                    .append("הזמנה שביצעת אושרה על ידי")
                    .append(order?.providerBrand || "הספק")
                    .append("איזה כיף! 🤩")
                    .toString()
            ],
            isStepComplete: order.status === OrderStatus.accepted,
            actions: [
                {
                    text: "הצגת פרטי השליח ומעקב ההזמנה",
                    onClick: () => {
                        message.error("ביום האספקה נשלח לך הודעה שההזמנה נאספה עם לינק מעקב ופרטי השליח.");
                    },
                    color: "gray"
                }
            ]
        },
        {
            id: "pickup by delivery provider status",
            title: statusToTextAndColor?.[order?.status]?.[0],
            titleColor: "blue",
            backgroundColor: "blue",
            description: ["ההזמנה יצאה לדרך! ניתן לראות איפה ההזמנה נמצאת בכל רגע"],
            actions: [
                {
                    text: "הצגת פרטי השליח ומעקב ההזמנה",
                    onClick: () => {
                        window.open(generateLionWheelOrderDeliveryStatusLink(order?.shippingDetails), "_blank");
                    }
                }
            ],
            isStepComplete: isOrderOnTheWay
        },
        {
            id: "order arrived to destination",
            title: statusToTextAndColor?.[OrderStatus.delivered]?.[0],
            description: ["ההזמנה הגיעה ליעד"],
            isStepComplete: !!isOrderDelivered,
            titleColor: "green",
            backgroundColor: "green"
        }
    ].filter(orderStep => !orderStep.shouldHide && orderStep.isStepComplete);
};

export const getProviderOrderViewStatusContentForRegularShippingProvider = (
    order,
    onDelivered,
    isLoadingOnDelivered
) => {
    if (!order) {
        return [];
    }

    const {isOrderDelivered, isOrderWaitingForDeliveredFeedback} = getOrderStepStatus(order);

    return [
        {
            id: "order sent to provider approve",
            title: statusToTextAndColor?.[order?.status]?.[0],
            titleColor: "yellow",
            backgroundColor: "yellow",
            description: ["יש לעבור על פרטי ההזמנה ולסמן האם תוכלו לבצע אותה"],
            endTime: moment(order.createdAt).add("4", "hour"),
            isStepComplete: order?.status === OrderStatus.pending
        },
        {
            id: "order canceled",
            title: statusToTextAndColor?.[order?.status]?.[0],
            titleColor: "gray",
            backgroundColor: "gray",
            isStepComplete: order?.status === OrderStatus.cancelled
        },
        {
            id: "order declined",
            title: statusToTextAndColor?.[order?.status]?.[0],
            titleColor: "red",
            subTitle: "סיבת סירוב:",
            description: [declineOrderOptionsToText?.[order?.declineReason], order.declineComment].filter(Boolean),
            backgroundColor: "red",
            isStepComplete: order?.status === OrderStatus.declined
        },
        {
            id: "provider approve",
            title: statusToTextAndColor?.[order?.status]?.[0],
            titleColor: "blue",
            backgroundColor: "blue",
            description: ["יש לשריין ביומן את הכתובת ומועד האספקה"],
            isStepComplete: order.status === OrderStatus.accepted
        },
        {
            id: "order waiting for delivered feedback",
            title: "מועד האספקה חלף, האם ההזמנה סופקה",
            titleColor: "blue",
            backgroundColor: "blue",
            actions: [
                {
                    text: "ההזמנה הגיע ליעד",
                    onClick: onDelivered,
                    loading: isLoadingOnDelivered
                }
            ],
            isStepComplete: isOrderWaitingForDeliveredFeedback
        },
        {
            id: "order arrived to destination",
            title: statusToTextAndColor?.[OrderStatus.delivered]?.[0],
            description: ["ההזמנה הגיעה ליעד"],
            titleColor: "green",
            backgroundColor: "green",
            isStepComplete: !!isOrderDelivered
        }
    ].filter(orderStep => !orderStep.shouldHide && orderStep.isStepComplete);
};

export const getProviderOrderViewStatusContentForShippingProvider = order => {
    if (!order) {
        return [];
    }

    const {isOrderDelivered, isOrderOnTheWay} = getOrderStepStatus(order);

    return [
        {
            id: "order sent to provider approve",
            title: statusToTextAndColor?.[order?.status]?.[0],
            titleColor: "yellow",
            backgroundColor: "yellow",
            description: ["יש לעבור על פרטי ההזמנה ולסמן האם תוכלו לבצע אותה"],
            endTime: moment(order.createdAt).add("4", "hour"),
            isStepComplete: order?.status === OrderStatus.pending
        },
        {
            id: "order canceled",
            title: statusToTextAndColor?.[order?.status]?.[0],
            titleColor: "gray",
            backgroundColor: "gray",
            isStepComplete: order?.status === OrderStatus.cancelled
        },
        {
            id: "order declined",
            title: statusToTextAndColor?.[order?.status]?.[0],
            titleColor: "red",
            subTitle: "סיבת סירוב:",
            description: [declineOrderOptionsToText?.[order?.declineReason], order.declineComment].filter(Boolean),
            backgroundColor: "red",
            isStepComplete: order?.status === OrderStatus.declined
        },
        {
            id: "provider approve",
            title: statusToTextAndColor?.[order?.status]?.[0],
            titleColor: "blue",
            backgroundColor: "blue",
            description: [
                "יש לשריין ביומן את מועד האספקה. הזמנה זו תאסף על ידי שליח well-b",
                <a
                    className="order-information-card-open-link"
                    key="delivery-certificate"
                    onClick={() =>
                        window.open(generateLionWheelOrderDeliveryCertificateLink(order?.shippingDetails), "_blank")
                    }>
                    להדפסת תעודת משלוח
                </a>
            ],
            actions: [
                {
                    text: "📞 ליצירת קשר עם השליח",
                    onClick: () => {
                        window.open(generateLionWheelOrderDeliveryStatusLink(order?.shippingDetails), "_blank");
                    }
                }
            ],
            isStepComplete: order.status === OrderStatus.accepted
        },
        {
            id: "pickup by delivery provider status",
            title: statusToTextAndColor?.[order?.status]?.[0],
            titleColor: "blue",
            backgroundColor: "blue",
            description: ["ההזמנה נאספה מבית העסק"],
            isStepComplete: isOrderOnTheWay
        },
        {
            id: "order arrived to destination",
            title: statusToTextAndColor?.[OrderStatus.delivered]?.[0],
            description: ["ההזמנה הגיעה ליעד"],
            titleColor: "green",
            backgroundColor: "green",
            isStepComplete: !!isOrderDelivered
        }
    ].filter(orderStep => !orderStep.shouldHide && orderStep.isStepComplete);
};

export const generateOrderViewStatusContent = order => {
    if (!order) {
        return [];
    }

    if (isOrderHasShippingProvider(order)) {
        return getOrderViewStatusContentForShippingProvider(order);
    }

    return getOrderViewStatusContentForRegularShippingProvider(order);
};

export const generateProviderOrderViewStatusContent = (order, onDelivered = NOOP, isLoadingOnDelivered) => {
    if (!order) {
        return [];
    }

    const {isOrderSentForProviderApproval} = getOrderStepStatus(order);

    if (!isOrderSentForProviderApproval) {
        return [];
    }

    if (isOrderHasShippingProvider(order)) {
        return getProviderOrderViewStatusContentForShippingProvider(order);
    }

    return getProviderOrderViewStatusContentForRegularShippingProvider(order, onDelivered, isLoadingOnDelivered);
};

export const getWhenSectionTitle = eventInfo => {
    const whenSection = dateTimeRange(eventInfo.dtstart, eventInfo.until);

    const whenSectionStringBuilder = new StringBuilder()
        .append(whenSection.dtStartDateFormat)
        .append("בשעה")
        .append(whenSection.dtStartHourFormat);

    if (eventInfo.until && whenSection.untilDateFormat) {
        whenSectionStringBuilder.append("עד");
        whenSectionStringBuilder.append(whenSection.untilDateFormat);
    }

    if (eventInfo.until && whenSection.untilHourFormat) {
        whenSectionStringBuilder.append("עד השעה");
        whenSectionStringBuilder.append(whenSection.untilHourFormat);
        whenSectionStringBuilder.append("(טווח של");
        whenSectionStringBuilder.append(`${whenSection.durationFormat})`);
    }

    return whenSectionStringBuilder.toString();
};

export const shouldShowKosherCertificate = eventInfo =>
    eventInfo?.kosherCertificateImageUrl &&
    (eventInfo?.kosher === kosherOptions[0] || eventInfo?.kosher === kosherOptions[2]);

export const generateCompanyRequests = companyRequests => {
    const companyRequestsStringBuilder = new StringBuilder(null, ", ");

    for (const req of companyRequests ?? []) {
        const companyRequestStringBuilder = new StringBuilder();
        if (req?.title) {
            companyRequestStringBuilder.append(req.title);
            companyRequestStringBuilder.append(req?.amount ? `(${req.amount})` : "");
        } else {
            companyRequestStringBuilder.append(req);
        }

        companyRequestsStringBuilder.append(companyRequestStringBuilder.toString());
    }

    return companyRequestsStringBuilder.toString();
};

export const generateUpgradeOptions = upgradeOptions => {
    const upgradeOptionsStringBuilder = new StringBuilder(null, "\n");
    for (const upgradeOption of upgradeOptions?.options ?? []) {
        const upgradeOptionStringBuilder = new StringBuilder();
        if (upgradeOption?.amount) {
            upgradeOptionStringBuilder.append(upgradeOption?.amount);
            upgradeOptionStringBuilder.append("-");
        }
        upgradeOptionStringBuilder.append(upgradeOption?.name);
        upgradeOptionStringBuilder.append("|");
        upgradeOptionStringBuilder.append(`${upgradeOption?.price * (upgradeOption?.amount ?? 1)}₪`);

        upgradeOptionsStringBuilder.append(upgradeOptionStringBuilder.toString());
    }

    return upgradeOptionsStringBuilder.toString();
};

export const shouldShowCancelReason = canceledEvent =>
    canceledEvent?.status === OrderStatus.cancelled &&
    canceledEvent?.cancelReason &&
    (canceledEvent?.eventStatusMap ?? []).some(({id}) => id === OrderStatus.canceledByManager);
