import React from "react";
import {Images} from "../../../images/Images";
import {PaymentType, PaymentTypeToName} from "../../../consts.js";
import "../../marketplace.css";
import classnames from "classnames";
import {NOOP} from "../../../utils/NOOP.jsx";
import {StringBuilder} from "../../../AppUtils.js";

export const CheckboxController = ({isChecked, setPaymentType, type}) =>
    isChecked ? (
        <img
            className={"payment-input-checkbox"}
            src={Images.marketplaceBookingCheckboxChecked}
            alt="booking-check-icon"
        />
    ) : (
        <img
            onClick={() => setPaymentType(type)}
            className={"payment-input-checkbox"}
            src={Images.marketplaceBookingCheckboxEmpty}
            alt="booking-empty-icon"
        />
    );

export const CreditCardPreview = ({last4Digits, removeSavedCard = NOOP}) => (
    <div className={classnames("credit-card-preview-container", {full: !!last4Digits})}>
        {last4Digits ? (
            <div className="credit-card-preview">
                <div className="credit-card-number">
                    {new StringBuilder(last4Digits).append("**** **** ****").toString()}
                </div>

                {removeSavedCard !== NOOP && (
                    <img
                        onClick={e => removeSavedCard(e)}
                        src={Images.trashBin}
                        height={16}
                        width={16}
                        className="credit-card-preview-trash-bin"
                        alt="trash-bin"
                    />
                )}
            </div>
        ) : null}
    </div>
);

export const PaymentInputHeader = ({paymentType, cardDigits}) => (
    <div className="payment-input-header-container">
        <div className="payment-input-header-title">{"🧾 בחירת אמצעי תשלום"}</div>
        <div className="payment-input-header-sub-title-container">
            <div
                className={classnames("payment-input-header-sub-title", {
                    mark: cardDigits || paymentType !== PaymentType.Card
                })}>
                {paymentType === PaymentType.Card && !cardDigits
                    ? "החיוב יבוצע על פי אמצעי התשלום הנבחר"
                    : PaymentTypeToName[paymentType]}
            </div>
        </div>
    </div>
);
