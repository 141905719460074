import React, {useState, useEffect} from "react";
import timeline from "../../images/magic/Timeline.gif";
import profile from "../../images/magic/Swipe_Profiles.gif";
import search from "../../images/magic/Search_engines.gif";
import design from "../../images/magic/Design_tools.gif";
// import FadeIn from "react-fade-in";

const images = [
    {src: search, title: "Processing your organizational information"},
    {src: design, title: "Creating your unique style"},
    {src: profile, title: "Matching you the best vendors"},
    {src: timeline, title: "Building your well-being program"}
];

const ANIMATION_TIME_MS = 4000;

export const MakeTheMagicHappenStep = ({history, completed}) => {
    const [currentImageIndex, setIndex] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if (checkCompleteness()) {
                goToConfirmPage();
            } else if (currentImageIndex < images.length - 1) {
                setIndex((currentImageIndex + 1) % images.length);
            }
        }, ANIMATION_TIME_MS);
    }, [currentImageIndex]);

    const checkCompleteness = () => {
        return currentImageIndex === images.length - 1 && completed;
    };

    const goToConfirmPage = () => {
        history.push("/company/confirm");
    };

    if (checkCompleteness()) {
        setTimeout(() => {
            goToConfirmPage();
        }, ANIMATION_TIME_MS);
    }

    return (
        <div
            style={{
                display: "flex",
                height: "100vh",
                width: "100%",
                alignItems: "center",
                justifyContent: "center"
            }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: 400,
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                <img alt="wizard" width={400} height={400} src={images[currentImageIndex].src} />
                <FadeIn key={images[currentImageIndex].title}>
                    <div
                        style={{
                            fontSize: 20,
                            textAlign: "center",
                            color: "var(--secondary-color)"
                        }}>
                        {images[currentImageIndex].title}
                    </div>
                </FadeIn>
            </div>
        </div>
    );
};
