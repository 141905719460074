import moment from "moment-timezone";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import React from "react";

export const EXCenterMobileCalendarHeader = ({value, onChange}) => {
    const isCurrentMonth = monthMom => {
        return moment().format("YYYY-MM") === monthMom.format("YYYY-MM");
    };

    const nextMonth = () => {
        const monthMom = moment(value).add(1, "months");
        moveToMonth(monthMom);
    };

    const prevMonth = () => {
        const monthMom = moment(value).subtract(1, "months");
        moveToMonth(monthMom);
    };

    const moveToMonth = monthMom => {
        if (isCurrentMonth(monthMom)) {
            onChange(moment());
        } else {
            onChange(monthMom.startOf("month"));
        }
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 10
            }}>
            <span
                style={{
                    fontSize: 20,
                    color: "#333333",
                    paddingLeft: 10
                }}>
                {value.format("MMMM YYYY")}
            </span>
            <span
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <LeftOutlined onClick={prevMonth} className="wb-center-mobile-calendar-nav" style={{marginRight: 20}} />
                <RightOutlined
                    onClick={nextMonth}
                    className="wb-center-mobile-calendar-nav"
                    style={{marginRight: 10}}
                />
            </span>
        </div>
    );
};
