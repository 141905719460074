import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {PageSubTitleLabel, SquareButton, StyledButton} from "../../../components/index.jsx";
import "../../../admin/upload/admin-upload.css";
import {Dropdown, Form, message, Spin, Switch} from "antd";

import {useRequest} from "../../../utils/hooks.jsx";
import {getTransformedProducts} from "../utils.jsx";
import "../../../admin/upload/admin-upload.css";
import "./provider-products.css";
import {AppContext} from "../../../AppContext.jsx";

import {AdminUploadNewVoucherProductModal} from "../../../admin/upload/AdminUploadNewVoucherProductModal/index.jsx";
import {AdminUploadNewProductBundleModal} from "../../../admin/upload/AdminUploadNewProductBundleModal/index.jsx";
import {AdminUploadNewProductModal} from "../../../admin/upload/AdminUploadNewProductModal/index.jsx";
import {ProductBundleList} from "../../ProductBundleList/index.jsx";
import {FormEditModes} from "../consts.jsx";
import {HttpClient} from "../../../http/HttpClient.jsx";
import {
    getSupportedServices,
    getSupportedServicesForVoucherProvider
} from "../../../admin/upload/AdminUploadHelper.jsx";
import {parseInt} from "lodash";
import {ProviderProductsList} from "./ProviderProductsList.jsx";
import {PriceTypes} from "../../../admin/upload/PriceSelectorHelper.jsx";
import {SubProductsList} from "../../SubProductsList/index.jsx";
import {ExportSubProductsProviderExcel} from "./subProductProviderExcelExporter.jsx";
import {LoadSubProductProviderExcelModal} from "./LoadSubProductProviderExcelModal.jsx";

export const ProviderProductsPage = ({providerId, editMode = FormEditModes.Readonly}) => {
    const {me} = useContext(AppContext);
    const [products, setProducts] = useState([]);
    const [productBundles, setProductBundles] = useState([]);

    const [providerSubProducts, setProviderSubProducts] = useState([]);
    const [newProductVisibility, setProductModalVisibility] = useState(false);
    const [newProductBundleVisibility, setProductBundleModalVisibility] = useState(false);
    const [newVoucherProductVisibility, setNewVoucherProductVisibility] = useState(false);
    const [editProductBundle, setEditProductBundle] = useState(null);
    const [editProduct, setEditProduct] = useState(null);
    const [allServices, setAllServices] = useState([]);
    const [loadSubProductProviderExcelModalVisible, setLoadSubProductProviderExcelModalVisible] = useState(false);

    const [deliveryProviders] = useRequest("/admin/api/deliveryProviders");

    const [services = []] = useRequest(
        "/api/services",
        "POST",
        {includeDisabled: true, noCookie: true},
        [],
        !!providerId
    );

    const [providerInfo, isLoadingProviderInfo, providerInfoError, fetchProviderInfo] = useRequest(
        `/admin/api/providers/${providerId}`,
        "GET",
        null,
        [],
        !!providerId
    );

    const [
        providerProductBundles,
        isLoadingProviderProductBundles,
        providerProductBundlesError,
        fetchProviderProductBundles
    ] = useRequest(`/api/productBundles/byProviderId/${providerId}`, "GET", null, [], !!providerId);

    const [productsSearchTags, isLoadingProductsSearchTags] = useRequest(
        "/admin/api/searchTags/byProducts",
        "POST",
        providerInfo?.services.map(({productId}) => productId),
        [providerInfo],
        !!providerInfo?.services?.length
    );

    useEffect(() => {
        if (services?.length) {
            setAllServices(services?.filter(category => category?.v2));
        }
    }, [services]);

    useEffect(() => {
        if (providerProductBundles?.length) {
            setProductBundles(providerProductBundles);
        }
    }, [providerProductBundles]);

    useEffect(() => {
        if (providerInfo) {
            setProviderSubProducts(providerInfo?.subProducts ?? []);
        }

        if (providerInfo?.services?.length) {
            const transformedProducts = getTransformedProducts(providerInfo, productsSearchTags);
            setProducts(transformedProducts ?? []);
        }
    }, [providerInfo, productsSearchTags]);

    const editProductClicked = useCallback(
        product => {
            if (product) {
                setEditProduct({
                    ...product,
                    serviceIds: product.serviceIds ?? product.services
                });
            } else {
                setEditProduct(null);
            }
            providerInfo?.isVoucherProvider ? setNewVoucherProductVisibility(true) : setProductModalVisibility(true);
        },
        [providerInfo?.isVoucherProvider]
    );

    const onCreateProductClicked = useCallback(() => {
        setProducts(currentProducts => [...currentProducts, {isNew: true}]);
    }, []);

    const editProductBundleClicked = useCallback(
        productBundleIdToEdit => {
            const foundProductBundle = productBundles.find(
                productBundle => productBundle.productBundleId === productBundleIdToEdit
            );

            if (foundProductBundle) {
                foundProductBundle.products = foundProductBundle.productIds.map(productId => ({productId}));
                setEditProductBundle(foundProductBundle);
            } else {
                setEditProductBundle({});
            }
            setProductBundleModalVisibility(true);
        },
        [productBundles]
    );

    const removeProduct = useCallback(
        async productToRemove => {
            if (productToRemove.isNew) {
                setProducts(currentProduct => currentProduct.filter(product => !product.isNew));
                return;
            }

            const {error} = await HttpClient.safeDelete(
                `/admin/api/provider/${providerId}/products/${productToRemove.productId}`
            );

            if (error) {
                message.error("אירעה שגיאה במחיקת המוצר");
            } else {
                fetchProviderInfo();
                message.success("המוצר נמחק בהצלחה");
            }
        },
        [fetchProviderInfo, providerId]
    );

    const onSaveEditProductBundle = useCallback(async productBundleToEdit => {
        const {error} = await HttpClient.safePost("/admin/api/productBundles", productBundleToEdit);

        if (error) {
            message.error("שגיאה! המוצר המשולב לא נשמר בהצלחה");
            message.error(error);
        }
    }, []);

    const onSaveNewProductBundle = useCallback(async productBundleToCreate => {
        const {error} = await HttpClient.safePut("/admin/api/productBundles", productBundleToCreate);

        if (error) {
            message.error("שגיאה! המוצר המשולב לא נוצר בהצלחה");
            message.error(error);
        }
    }, []);

    const addOrEditProductBundle = useCallback(
        async productBundleToAddOrEdit => {
            if (productBundleToAddOrEdit.minRecommendedProductParticipants) {
                productBundleToAddOrEdit.minRecommendedProductParticipants = parseInt(
                    productBundleToAddOrEdit?.minRecommendedProductParticipants
                );
            }
            if (productBundleToAddOrEdit.maxRecommendedProductParticipants) {
                productBundleToAddOrEdit.maxRecommendedProductParticipants = parseInt(
                    productBundleToAddOrEdit?.maxRecommendedProductParticipants
                );
            }

            if (!productBundleToAddOrEdit?.productBundleId) {
                await onSaveNewProductBundle(productBundleToAddOrEdit);
            } else {
                await onSaveEditProductBundle(productBundleToAddOrEdit);
            }
            await fetchProviderProductBundles();
            setProductBundleModalVisibility(false);
        },
        [onSaveNewProductBundle, fetchProviderProductBundles, setProductBundleModalVisibility, onSaveEditProductBundle]
    );

    const onCreateProduct = useCallback(
        async newProduct => {
            const res = await HttpClient.safePost(`/admin/api/provider/${providerId}/products`, {
                productName: newProduct.productName
            });

            if (res?.error) {
                message.error("נכשל בעת יצירת המוצר");
            } else {
                setProductModalVisibility(false);
                setEditProduct(null);
                fetchProviderInfo();
                message.success("נוצר בהצלחה");
            }
        },
        [fetchProviderInfo, providerId, setProductModalVisibility, setEditProduct, setProducts]
    );

    const onEditProduct = useCallback(
        async updatedProduct => {
            const {provider, productId, serviceIds, ...restProduct} = updatedProduct;
            const [supportedProducts] = updatedProduct?.isVoucherProvider
                ? getSupportedServicesForVoucherProvider([restProduct])
                : getSupportedServices([restProduct]);
            const res = await HttpClient.safePut(
                `/admin/api/provider/${providerId}/products/${productId}?updatedAt=${updatedProduct.updatedAt}`,
                {
                    product: {...supportedProducts, productId}
                }
            );

            if (res?.error) {
                if (res.status === 409) {
                    message.error("שים לב יש גרסא יותר חדשה של המוצר, אנא רענן את הדף");
                } else {
                    message.error("נכשל בעת עדכון המוצר");
                }
            } else {
                await fetchProviderInfo();
                if (updatedProduct.chosenPriceType === PriceTypes.ByVoucher) {
                    setNewVoucherProductVisibility(false);
                } else {
                    setProductModalVisibility(false);
                }
                setEditProduct(null);
                message.success("עודכן בהצלחה");
            }
        },
        [fetchProviderInfo, providerId, setProductModalVisibility, setEditProduct]
    );

    const onIsActiveChange = useCallback(
        async (productId, isActive, updatedAt) => {
            const res = await HttpClient.safePut(
                `/admin/api/provider/${providerId}/products/${productId}?updatedAt=${updatedAt}`,
                {
                    product: {isActive}
                }
            );
            if (res?.error) {
                if (res.status === 409) {
                    message.error("שים לב יש גרסא יותר חדשה של המוצר, אנא רענן את הדף");
                } else {
                    message.error("נכשל בעת עדכון המוצר");
                }
                return;
            }
            await fetchProviderInfo();
            message.success("מוצר עודכן בהצלחה");
        },
        [providerId, fetchProviderInfo]
    );

    const onIsPrimaryChange = useCallback(
        async (productId, isPrimary, updatedAt) => {
            const res = await HttpClient.safePut(
                `/admin/api/provider/${providerId}/products/${productId}?updatedAt=${updatedAt}`,
                {
                    product: {isPrimary}
                }
            );
            if (res?.error) {
                if (res.status === 409) {
                    message.error("שים לב יש גרסא יותר חדשה של המוצר, אנא רענן את הדף");
                } else {
                    message.error("נכשל בעת עדכון המוצר");
                }
                return;
            }
            await fetchProviderInfo();
            message.success("מוצר עודכן בהצלחה");
        },
        [providerId, fetchProviderInfo]
    );

    const isAbleToAddProducts = useMemo(() => products?.every(product => product.productId), [products]);

    const onUpdateProductName = useCallback(
        productName => {
            setProducts(currentProducts => {
                const newProduct = currentProducts.find(product => product.isNew);
                newProduct.productName = productName;
                return currentProducts;
            });
        },
        [setProducts]
    );

    const onDeleteProductBundle = useCallback(
        async productBundleId => {
            const {error} = await HttpClient.safeDelete(`/admin/api/productBundles/${productBundleId}`);

            if (error) {
                message.error("שגיאה! המוצר המשולב לא נמחק בהצלחה");
                return;
            }

            message.success("המוצר נמחק בהצלחה");
            await fetchProviderProductBundles();
        },
        [fetchProviderProductBundles]
    );

    const onChangeProductBundleStatus = useCallback(
        async (productBundleId, isActive) => {
            const {error} = await HttpClient.safePost("/admin/api/productBundles/updateField", {
                productBundleId,
                productBundleField: "isActive",
                productBundleValue: isActive
            });

            if (error) {
                message.error("שינוי סטטוס המוצר המשולב נכשל");
            } else {
                message.success("שינוי סטטוס המוצר בוצע בהצלחה");
            }

            fetchProviderProductBundles();
        },
        [fetchProviderProductBundles]
    );

    const onCreateSubProduct = useCallback(
        async subProduct => {
            const {error} = await HttpClient.safePost(`/admin/api/providers/${providerId}/subProducts`, subProduct);

            if (error) {
                message.error("קרתה שגיאה במהלך יצירת המיני מוצר");
                return;
            }

            message.success("מיני מוצר נוצר בהצלחה");
            fetchProviderInfo();
        },
        [providerId, fetchProviderInfo]
    );

    const onUpdateSubProduct = useCallback(
        async (subProductId, subProduct) => {
            const {error} = await HttpClient.safePut(
                `/admin/api/providers/${providerId}/subProducts/${subProductId}`,
                subProduct
            );

            if (error) {
                message.error("קרתה שגיאה במהלך עריכת המיני מוצר");
                return;
            }
            message.success("מיני מוצר עודכן בהצלחה");
            fetchProviderInfo();
        },
        [providerId, fetchProviderInfo]
    );

    const onDeleteSubProduct = useCallback(
        async (subProductId, subProduct) => {
            // const {error} = await HttpClient.safeDelete(`/admin/api/providers/${providerId}/subProducts/${subProductId}`, subProduct);
            //
            // if (error) {
            //     message.error("קרתה שגיאה במהלך מחיקת המיני מוצר");
            //     return;
            // }
            // message.success("מיני מוצר נמחק בהצלחה");
            // fetchProviderInfo()
            message.error("שימו לב, כרגע לא ניתן למחוק זמנית מיני מוצר");
        },
        [providerId, fetchProviderInfo]
    );

    const onExportSubProductsProviderExcelClicked = useCallback(async () => {
        await ExportSubProductsProviderExcel(providerSubProducts);
    }, [providerSubProducts]);

    const onLoadSubProductsProviderExcelClicked = useCallback(async () => {
        setLoadSubProductProviderExcelModalVisible(true);
    }, []);

    const onFinishedSaveBulkSubProducts = useCallback(() => {
        setLoadSubProductProviderExcelModalVisible(false);
        fetchProviderInfo();
    }, [fetchProviderInfo]);

    return (
        <div className="provider-products-container">
            <AdminUploadNewVoucherProductModal
                visibility={newVoucherProductVisibility}
                services={allServices}
                editProduct={editProduct}
                onEditProduct={onEditProduct}
                onNewProduct={onCreateProduct}
                onCancel={() => {
                    setNewVoucherProductVisibility(false);
                    setEditProduct(null);
                }}
            />

            <AdminUploadNewProductBundleModal
                visible={newProductBundleVisibility}
                editProductBundle={editProductBundle}
                onSave={addOrEditProductBundle}
                onCancel={() => {
                    setProductBundleModalVisibility(false);
                    setEditProductBundle(null);
                }}
            />

            <AdminUploadNewProductModal
                visibility={newProductVisibility}
                services={allServices}
                editProduct={editProduct}
                providerSubProducts={providerSubProducts}
                providerDetails={providerInfo}
                otherProductsDeliveryCities={products
                    .filter(product => product.productId !== editProduct?.productId)
                    .map(product => ({
                        name: product.productName,
                        deliveryAreas: product?.deliveryOptions?.deliveryAreas
                    }))}
                onEditProduct={onEditProduct}
                onCancel={() => {
                    setProductModalVisibility(false);
                    setEditProduct(null);
                }}
                deliveryProviders={deliveryProviders}
            />

            <PageSubTitleLabel className="provider-products-container-sub-title">מוצרים של בית העסק</PageSubTitleLabel>
            {isLoadingProviderInfo || isLoadingProductsSearchTags || isLoadingProviderProductBundles ? (
                <Spin size="small" />
            ) : (
                <div className="provider-products-list">
                    <ProviderProductsList
                        providerInfo={providerInfo}
                        editProductClicked={editProductClicked}
                        removeProduct={removeProduct}
                        onCreateProduct={onCreateProduct}
                        onIsActiveChange={onIsActiveChange}
                        onIsPrimaryChange={onIsPrimaryChange}
                        onUpdateProductName={onUpdateProductName}
                        products={products}
                    />

                    {providerInfo?.isVoucherProvider && isAbleToAddProducts ? (
                        <StyledButton className="admin-upload-add-product-button" onClick={onCreateProductClicked}>
                            הוספת שוברים
                        </StyledButton>
                    ) : (
                        <>
                            {isAbleToAddProducts ? (
                                <StyledButton
                                    id="admin-upload-add-regular-product"
                                    className="admin-upload-add-product-button"
                                    onClick={onCreateProductClicked}>
                                    הוספת מוצרים
                                </StyledButton>
                            ) : null}

                            {me?.features?.product_bundles ? (
                                <>
                                    <PageSubTitleLabel className="provider-products-container-sub-title">
                                        מוצרים משולבים של בית העסק
                                    </PageSubTitleLabel>

                                    <div className="provider-product-bundle-list">
                                        <ProductBundleList
                                            productBundles={productBundles}
                                            setProductBundles={setProductBundles}
                                            editMode={editMode}
                                            onEditProductBundle={editProductBundleClicked}
                                            onChangeProductBundleStatus={onChangeProductBundleStatus}
                                            onRemoveProductBundle={onDeleteProductBundle}
                                        />
                                    </div>

                                    <StyledButton
                                        className="admin-upload-add-product-button"
                                        onClick={editProductBundleClicked}>
                                        הוספת מוצר משולב
                                    </StyledButton>
                                </>
                            ) : null}

                            <PageSubTitleLabel className="provider-products-container-sub-title">
                                מיני מוצרים של בית העסק
                            </PageSubTitleLabel>

                            <Dropdown
                                menu={{
                                    items: [
                                        {
                                            key: "ייצוא מיני מוצרים",
                                            disabled: !providerSubProducts?.length,
                                            label: (
                                                <div onClick={onExportSubProductsProviderExcelClicked}>
                                                    ייצוא מיני מוצרים
                                                </div>
                                            )
                                        },
                                        {
                                            key: "ייבוא מיני מוצרים",
                                            label: (
                                                <div onClick={onLoadSubProductsProviderExcelClicked}>
                                                    ייבוא מיני מוצרים
                                                </div>
                                            )
                                        }
                                    ]
                                }}>
                                <SquareButton className="sub-products-export-button">הוצאת אקסל</SquareButton>
                            </Dropdown>

                            <LoadSubProductProviderExcelModal
                                providerId={providerId}
                                visible={loadSubProductProviderExcelModalVisible}
                                onClose={() => setLoadSubProductProviderExcelModalVisible(false)}
                                onFinish={onFinishedSaveBulkSubProducts}
                            />

                            <SubProductsList
                                onCreateSUbProduct={onCreateSubProduct}
                                onUpdateSubProduct={onUpdateSubProduct}
                                onDeleteSubProduct={onDeleteSubProduct}
                                allSubProducts={providerSubProducts}
                                setAllSubProducts={setProviderSubProducts}
                                readonly={editMode === FormEditModes.Readonly}
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
};
