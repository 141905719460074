import PageTitleHeader from "../components/PageTitle";
import React, {useState, useEffect} from "react";
import {Form, Spin} from "antd";
import {EventBus} from "../bus/EventBus";
import {HttpClient} from "../http/HttpClient";
import {FormCancelSaveBottomBar} from "../components/form";
import {fillLocationDetails, missingStateDetector, ProviderSignUpBasicInfoFields} from "./ProviderSignUpBasicInfo";
import {ProviderExtraFields, ProviderSignUpExtraInfoFields} from "./ProviderSignUpExtraInfo";
import {buildProviderUser, extractExtraDetails} from "./ProviderSignUp";
import {ProviderChangesSavedModal} from "./ProviderChangesSavedModal";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {PageLoader} from "../components/PageLoader";

const {useForm} = Form;

export const ProviderEditProfile = ({history}) => {
    const [providerId, setProviderId] = useState(null);
    const [initialValues, setInitialValues] = useState(null);
    const [providerType, setProviderType] = useState(null);
    const [saving, setSaving] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [form] = useForm();

    useEffect(() => {
        (async () => {
            try {
                const providerDetails = await HttpClient.get("/api/providers/me");
                setProviderId(providerDetails.providerId);
                setProviderType(providerDetails.providerType);
                setInitialValues(convertExtraFields(providerDetails));
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we didn't manage to present your profile :("}},
                    e.message
                );
            }
        })();
    }, []);

    const convertExtraFields = providerDetails => {
        return Object.keys(ProviderExtraFields).reduce((details, key) => {
            const fieldName = ProviderExtraFields[key];
            details["extra." + fieldName] = details[fieldName];
            return details;
        }, providerDetails);
    };

    const undo = () => {
        form.setFieldsValue(initialValues);
    };

    const updateProfile = async values => {
        setSaving(true);
        try {
            const updatedValues = fillLocationDetails(values);
            const userDetails = buildProviderUser(updatedValues, {});
            const extraDetails = extractExtraDetails({}, updatedValues);
            await HttpClient.post("/api/providers/me", {
                userDetails,
                providerDetails: {...extraDetails, providerId}
            });
            GoogleAnalytics.event("Professional Profile", "Update", providerId);
            setInitialValues(values);
            setShowSuccessModal(true);
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we didn't manage to save your changes :("}},
                e.message
            );
        }
        setSaving(false);
    };

    const closeSuccessModal = () => {
        setShowSuccessModal(false);
    };

    if (!initialValues) return <PageLoader align="flex-start" top={100} />;

    return (
        <div style={{display: "flex", flexDirection: "column", width: "100%", marginTop: -10, marginBottom: 80}}>
            <ProviderChangesSavedModal
                onClose={() => closeSuccessModal()}
                history={history}
                visible={showSuccessModal}
            />
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                <PageTitleHeader style={{marginBottom: 0, marginTop: 20}}>Edit your profile</PageTitleHeader>
                <Form
                    form={form}
                    scrollToFirstError={true}
                    onFinish={async vals => updateProfile(vals)}
                    onFieldsChange={missingStateDetector(form)}
                    style={{padding: 10, maxWidth: 340, minWidth: 340, width: "100%"}}
                    initialValues={initialValues}>
                    <ProviderSignUpBasicInfoFields
                        hideProviderTypeToggle={true}
                        disableName={true}
                        disableEmail={true}
                        allowExistingEmail={true}
                        defaultCountry={{
                            countryId: initialValues.countryId,
                            countryName: initialValues.countryName,
                            countryCode: initialValues.countryCode,
                            phonePrefix: initialValues.phonePrefix
                        }}
                        defaultState={{
                            stateId: initialValues.stateId,
                            stateName: initialValues.stateName
                        }}
                        defaultCity={{
                            cityId: initialValues.cityId,
                            cityName: initialValues.cityName
                        }}
                        providerType={providerType || initialValues.providerType || "individual"}
                        onProviderTypeChange={type => setProviderType(type)}
                    />
                    <ProviderSignUpExtraInfoFields
                        disableProfession={true}
                        defaultDescription={initialValues.description}
                    />
                    <Form.Item noStyle>
                        <FormCancelSaveBottomBar onCancel={() => undo()} loading={saving} />
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};
