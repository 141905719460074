import React, {useCallback, useMemo, useState} from "react";
import {declineOrderOptionsToText} from "./consts.js";
import {FormModal} from "../../components/index.jsx";
import {Images} from "../../images/Images.jsx";
import {ConfigProvider} from "antd";
import "./decline-order-modal.css";

const {noteImage} = Images;

export const DeclineOrderModal = ({visible, onClose, onSave, onContactClick}) => {
    const [isLoading, setIsLoading] = useState(false);

    const onFinish = useCallback(
        async declineDetails => {
            setIsLoading(true);
            await onSave(declineDetails);
            setIsLoading(false);
        },
        [onSave]
    );

    const declineOrderReasons = useMemo(() => {
        return Object.entries(declineOrderOptionsToText).map(([key, value]) => ({name: key, label: value}));
    }, []);

    return (
        <div>
            <ConfigProvider direction="rtl">
                <FormModal
                    className="decline-order-modal-container"
                    image={noteImage}
                    header="יש להוסיף עבור הלקוח את סיבת הסירוב להזמנה"
                    subTitle={
                        onContactClick ? (
                            <a className="decline-order-modal-container-help-link" onClick={onContactClick}>
                                לבירור מול מנהלת הלקוח לחצו כאן
                            </a>
                        ) : null
                    }
                    visible={visible}
                    onClose={onClose}
                    onSave={onFinish}
                    isConfirmButtonLoading={isLoading}
                    fields={[
                        {
                            name: "declineReason",
                            label: "בחרו את סיבת הסירוב שתשלח ללקוח",
                            type: "select",
                            options: declineOrderReasons,
                            placeholder: "בחרו את אחת האפשרויות"
                        },
                        {
                            name: "declineComment",
                            className: "declineComment",
                            label: "ניתן להוסיף הסבר לסיבת הסירוב, בשביל שהלקוח יוכל לתקן את ההזמנה בהתאם",
                            type: "text",
                            placeholder: "הקלידו כאן",
                            optional: true
                        }
                    ]}
                />
            </ConfigProvider>
        </div>
    );
};
