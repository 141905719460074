import {RRuleSetBuilder} from "./RRuleSetBuilder";
// import randomColor from "randomcolor";
import moment from "moment-timezone";
import {eventByDate} from "./EventByDate";
import {dateFormat} from "../utils/DateFormat";
import {CALENDAR_MAX_VIEW_IN_MONTHS, CALENDAR_MIN_VIEW_IN_MONTHS} from "../data/defaults";

function hashString(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash += Math.pow(str.charCodeAt(i) * 31, str.length - i);
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

const isRegistered = (evtToDate, me) => {
    return (evtToDate.registeredEmployees || []).some(reg => reg.employeeId === me.userId);
};

export class EventMapper {
    static colorsMap = {};

    static toAppointmentOnCalendar(appointment) {
        const {date, start, end, tzid = moment.tz.guess(), providerFirstName, providerLastName} = appointment;

        const startDate = moment.tz(`${date} ${start}`, tzid).toDate();
        const endDate = moment.tz(`${date} ${end}`, tzid).toDate();

        return {
            id: appointment.eventId,
            title: appointment.productName,
            start: startDate,
            end: endDate,
            backgroundColor: "#9EA0A5",
            borderColor: "transparent",
            textColor: "#fff",
            extendedProps: {
                ...{
                    ...appointment,
                    duration: moment(endDate).diff(startDate, "minutes"),
                    type: "appointment"
                },
                "extra.tooltipText": `${providerFirstName}${providerLastName ? ` ${providerLastName}` : ""}`
            }
        };
    }

    static reduce({event, title, tooltipText, onlyRegistered, me, subtitle}) {
        if (!event.eventId) {
            return [];
        }
        const rruleSet = RRuleSetBuilder.build(event);

        const hash = hashString(event.parentEventId || event.eventId);

        if (!EventMapper.colorsMap.hasOwnProperty(hash)) {
            EventMapper.colorsMap[hash] =
                event.backgroundColor ||
                randomColor({
                    luminosity: "dark"
                });
        }

        const dates = rruleSet.between(
            moment().subtract(CALENDAR_MIN_VIEW_IN_MONTHS, "months").toDate(),
            moment().add(CALENDAR_MAX_VIEW_IN_MONTHS, "months").toDate(),
            true
        );

        if (!dates) {
            return [];
        }

        return dates.reduce((relevantEvents, start) => {
            const evtToDate = eventByDate(event, moment(start).tz(event.tzid).format("YYYY-M-D"));
            if (onlyRegistered && !isRegistered(evtToDate, me)) {
                return relevantEvents;
            }

            let end = moment(start)
                .add(evtToDate.duration || 0, "minutes")
                .toDate();
            if (evtToDate.appointmentBased) {
                end = moment(`${dateFormat(start)} ${evtToDate.maxTime}`, "YYYY-M-D HH:mm").toDate();
            }

            return relevantEvents.concat({
                id: event.eventId,
                groupId: event.eventId,
                title,
                start,
                end,
                subtitle,
                backgroundColor:
                    event.status === "pending" ? "#9EA0A5" : evtToDate.backgroundColor || EventMapper.colorsMap[hash],
                borderColor: "transparent",
                textColor: "#fff",
                display: "block",
                extendedProps: {
                    ...event,
                    "extra.tooltipText": tooltipText
                }
            });
        }, []);
    }
}
