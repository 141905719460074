import React, {useCallback, useContext, useMemo} from "react";
import {Spin} from "antd";
import {ITEMS_PER_CAROUSAL} from "../MarketplaceUtils";
import {isAllowedForCategory} from "../../center/EXCenterHelper";
import MarketplaceProductsCarousel from "../MarketplaceProductsCarousel";
import {useRequest} from "../../utils/hooks";
import {AppContext} from "../../AppContext";
import {getLogger, writeProductClickedLog} from "../../Logger";
import {createProductLink} from "../MarketplaceHelper";

const IAI_PAGE_ID = "AHuHAauzi";

// Temporary page for IaI to replace their package marketplace
export const IaiPackagesMarketplace = ({history}) => {
    const {me, services} = useContext(AppContext);

    const log = useMemo(() => getLogger({IAI_PAGE_ID}, "CrossPage"), []);

    const onContentClick = useCallback(
        (content, serviceId, newTab = false) => {
            writeProductClickedLog(log, content, serviceId);

            const url = createProductLink(content, {serviceId, me, services});

            if (newTab) {
                window.open(url, "_blank");
            } else {
                history.push(url);
            }
        },
        [log, history, me, services]
    );

    const [crossPageInfo, loadingCrossPageInfo] = useRequest(`/api/site/crossPages/${IAI_PAGE_ID}`);
    const [subCategories, loadingSubCategories] = useRequest(
        `/api/products/getProductsBySubCategories/?amount=${ITEMS_PER_CAROUSAL}&filterInactive=false`,
        "post",
        crossPageInfo?.subCategories,
        [crossPageInfo],
        !!crossPageInfo
    );

    return (
        <div className="marketplace-category-container cross-page">
            <div className="marketplace-category-filters-container">
                <div className="marketplace-category-title-row">{crossPageInfo?.name}</div>
            </div>
            {loadingSubCategories || loadingCrossPageInfo ? (
                <Spin size="large" />
            ) : (
                <div className="marketplace-main-page-carousels-container marketplace-category-providers">
                    {subCategories?.map(sub =>
                        isAllowedForCategory(me, sub.serviceId, false) ? (
                            <MarketplaceProductsCarousel
                                key={sub.subCategory}
                                onContentClick={content => onContentClick(content, sub.serviceId)}
                                onNewTab={content => onContentClick(content, sub.serviceId, true)}
                                products={sub.products}
                                title={sub.subCategory}
                            />
                        ) : null
                    )}
                </div>
            )}
        </div>
    );
};
