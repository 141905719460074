import {HttpError} from "./HttpError";

export class UploadClient {
    static async upload(method, path, formData) {
        const opts = {
            method,
            credentials: "same-origin",
            body: formData
        };

        const response = await fetch(path, opts);

        if (!response.ok) {
            let error = "Oopss... please try again later.";
            try {
                error = (await response.json()).error;
            } catch (ignore) {}
            throw new HttpError(error, response.status);
        }

        return await response.json();
    }
}
