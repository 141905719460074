import React, {useCallback, useMemo} from "react";
import {NOOP} from "../../../utils/NOOP";
import {FormModal} from "../../../components";
import "./AddNewCompanyDomainModal.css";
import {message} from "antd";

export const AddNewCompanyDomainModal = ({visible, onSave = NOOP, onClose = NOOP, company = null}) => {
    const onSaveCompanyDomain = useCallback(
        values => {
            onSave(values?.companyDomain?.toLowerCase(), company);
        },
        [company, onSave]
    );

    const validation = useCallback(
        values => {
            const isDomainAlreadyExist = company?.allowedEmailDomains?.includes(values?.companyDomain?.toLowerCase());
            if (isDomainAlreadyExist) {
                message.error("לא ניתן להוסיף דומיין שכבר קיים");
                return false;
            }

            const domainRegex = /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]{2,})+$/;
            const isValidDomain = domainRegex.test(values.companyDomain);
            if (!isValidDomain) {
                message.error("סיומת מייל לא תקינה");
            }
            return isValidDomain;
        },
        [company?.allowedEmailDomains]
    );

    const modalFields = useMemo(() => {
        return [
            {
                name: "companyDomain",
                className: "newCompanyDomain",
                label: "הזן את הסיומת",
                placeholder: "e.g. Walla.com"
            },
            {
                name: "_allowedEmailDomainsDiv",
                type: "custom",
                customComponent: (
                    <div className="allowedEmailDomainsDiv">
                        {company?.allowedEmailDomains?.map(allowedEmailDomain => {
                            return <span>{allowedEmailDomain}</span>;
                        })}
                    </div>
                )
            }
        ];
    }, [company?.allowedEmailDomains]);

    return (
        <>
            <FormModal
                className="add-new-company-domain-modal"
                header={"הוספת סיומת מייל חדשה לחברה"}
                subTitle={"לאחר הוספת סיומת המייל החדשה, יהיה ניתן להוסיף את המייל לאותה חברה שנבחרה"}
                visible={visible}
                validationFunction={validation}
                fields={modalFields}
                onSave={onSaveCompanyDomain}
                onClose={onClose}
            />
        </>
    );
};
