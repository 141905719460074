import {ExcelRowBase, excelSubProductsKeys, FoodRequestAnswerOption, isActiveAnswerOption} from "./consts.jsx";
import {isNumber} from "lodash";
import {FoodRequestsIds} from "../../../marketplace/ProductsFilterModal/consts.jsx";

import common from "@well-b/well-b-shared";
import {VAT} from "../../../consts.js";

export const addSystemCalculatedValidation = (subProducts, validationResults) => {
    for (const subProduct of subProducts) {
        const {systemCalculatedField, ...calculatedPrices} = calculateMissingSubProductsPrices(
            subProduct.price,
            subProduct.commission,
            subProduct.priceWithoutCommission
        );

        const isFieldAlreadyHasError = validationResults.some(
            val => val.index + ExcelRowBase === subProduct.excelRowNumber && val.key === systemCalculatedField
        );

        if (!systemCalculatedField || isFieldAlreadyHasError) {
            continue;
        }

        subProduct[systemCalculatedField] = calculatedPrices[systemCalculatedField];

        validationResults.push({
            index: subProducts.indexOf(subProduct),
            key: systemCalculatedField,
            warning: "חושב על ידי המערכת"
        });
    }

    return validationResults;
};

export const calculateMissingSubProductsPrices = (price, commission, priceWithoutCommission) => {
    const filledFields = [price, commission, priceWithoutCommission].filter(isNumber).length;
    let systemCalculatedField;

    if (filledFields !== 2) {
        return {price, commission, priceWithoutCommission};
    }

    if (filledFields === 2) {
        if (isNumber(price) && isNumber(commission)) {
            priceWithoutCommission = common.calculateSubProductPriceWithoutCommission(price, commission, VAT);
            systemCalculatedField = excelSubProductsKeys.priceWithoutCommission;
        } else if (isNumber(price) && isNumber(priceWithoutCommission)) {
            commission = common.calculateSubProductCommission(price, priceWithoutCommission, VAT);
            systemCalculatedField = excelSubProductsKeys.commission;
        } else if (isNumber(commission) && isNumber(priceWithoutCommission)) {
            price = common.calculateSubProductPrice(priceWithoutCommission, commission, VAT);
            systemCalculatedField = excelSubProductsKeys.price;
        }
    }

    return {price, commission, priceWithoutCommission, systemCalculatedField};
};

export const transformedSubProducts = subProducts => {
    return subProducts.map(
        ({veganFriendly, vegetarianFriendly, glutenFree, lactoseFree, furOption, ...restSubProduct}) => {
            return {
                ...restSubProduct,
                foodRequests: [
                    ...(veganFriendly === FoodRequestAnswerOption.YES ? [FoodRequestsIds.veganFriendly] : []),
                    ...(vegetarianFriendly === FoodRequestAnswerOption.YES ? [FoodRequestsIds.vegetarianFriendly] : []),
                    ...(glutenFree === FoodRequestAnswerOption.YES ? [FoodRequestsIds.glutenFree] : []),
                    ...(lactoseFree === FoodRequestAnswerOption.YES ? [FoodRequestsIds.lactoseFree] : []),
                    ...(furOption === FoodRequestAnswerOption.YES ? [FoodRequestsIds.furOption] : [])
                ],
                isActive: restSubProduct.isActive === isActiveAnswerOption.ACTIVE
            };
        }
    );
};
