import React, {useContext} from "react";
import {StyledButton} from "../../components/StyledButton";
import {truncate} from "lodash";
import {EXCenterInfoItem} from "./EXCenterInfoItem";
import {Link} from "react-router-dom";
import {withRouter} from "react-router";
import {EXCenterInfoTag} from "./EXCenterInfoTag";
import {EXCenterContext} from "../EXCenterContext";
import {AutoDirectionProvider} from "../../AutoDirectionProvider";
import {EXCenterProperties} from "../utils/EXCenterProperties";
import {EXCenterTitleTooltip} from "../EXCenterTitleTooltip";
import {SmartImageContainer} from "../../components/SmartImageContainer";

const EXCenterInfoCard = ({item, category, onClick}) => {
    const {theme} = useContext(EXCenterContext);
    const infoItem = new EXCenterInfoItem(item);

    const fullSubTitle = infoItem.subTitle || "";
    const subTitle = truncate(fullSubTitle, {separator: " ", length: 90});

    return (
        <Link
            style={{cursor: infoItem.hasAdditionalContent() ? "pointer" : "not-allowed"}}
            to={
                infoItem.hasAdditionalContent()
                    ? `/center/sections/${infoItem.sectionId}/content/${infoItem.itemId}`
                    : "#"
            }>
            <div
                onClick={e => {
                    infoItem.hasAdditionalContent() && onClick && onClick();
                    if (!infoItem.hasAdditionalContent()) {
                        e.stopPropagation();
                        e.preventDefault();
                    }
                }}
                className="wb-ss-no-margin wb-ss-card-margin"
                style={{
                    height: 472,
                    width: 360,
                    borderRadius: 10,
                    overflow: "hidden",
                    backgroundColor: "white",
                    boxShadow: "0 10px 30px 0 rgba(22,29,37,0.1)",
                    margin: 10,
                    display: "flex",
                    flexDirection: "column"
                }}>
                <SmartImageContainer
                    width={360}
                    height={220}
                    src={infoItem.coverPhoto}
                    style={{
                        width: "100%",
                        position: "relative"
                    }}>
                    <EXCenterInfoTag tags={category ? [category] : infoItem.categories} />
                </SmartImageContainer>
                <div style={{display: "flex", flexDirection: "column", padding: 20, height: 252}}>
                    <AutoDirectionProvider
                        text={infoItem.title}
                        style={{
                            color: theme.textColor,
                            fontSize: 24,
                            fontWeight: 500,
                            height: 30
                        }}>
                        <EXCenterTitleTooltip
                            title={infoItem.title}
                            minLength={infoItem.subTitle ? EXCenterProperties.Section.Card.Title.Length : 55}
                        />
                    </AutoDirectionProvider>
                    <AutoDirectionProvider
                        text={fullSubTitle}
                        style={{
                            color: theme.textColor,
                            fontSize: 16,
                            fontWeight: 400,
                            marginTop: 20,
                            height: 80
                        }}>
                        {subTitle}
                    </AutoDirectionProvider>
                    <div style={{display: "flex"}}>
                        <StyledButton
                            className="wb-center-action-button"
                            style={{
                                color: "white",
                                backgroundColor: theme.primaryColor,
                                borderRadius: 20,
                                height: 40,
                                width: 135,
                                marginTop: 30,
                                display: infoItem.hasAdditionalContent() ? "block" : "none"
                            }}>
                            Read more
                        </StyledButton>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default withRouter(EXCenterInfoCard);
