import React, {useContext, useEffect, useMemo, useState} from "react";
import {DatePicker, Dropdown, Menu} from "antd";
import moment from "moment";
import {Frequency} from "../consts";
import {HappinessCalculatorContext} from "../HappinessCalculatorContext";
import {HappinessCalculatorRepeatOccasionModal} from "./HappinessCalculatorRepeatOccasionModal";
import "./happiness-calculator-range-occasion-input.css";

const HappinessCalculatorRangeOccasionInput = ({monthAmount, onFieldChanged, isActive}) => {
    const [startDate, setStartDate] = useState();
    const [repeatModalData, setRepeatModalData] = useState(null);
    const [customRepeatModalVisible, setCustomRepeatModalVisible] = useState(false);
    const {planTimeRange} = useContext(HappinessCalculatorContext);

    const timeRanges = useMemo(
        () =>
            planTimeRange ? [moment(planTimeRange[0]).startOf("month"), moment(planTimeRange[1]).endOf("month")] : null,
        [planTimeRange]
    );

    const repeatText = useMemo(
        () =>
            repeatModalData
                ? `חזרה כל ${
                      repeatModalData.repeatAmount === 1
                          ? repeatModalData.selectedFrequency.name
                          : `${repeatModalData.repeatAmount} ${repeatModalData.selectedFrequency.pluralName}`
                  }`
                : "ללא חזרה",
        [repeatModalData]
    );

    const actualStartDates = useMemo(() => {
        if (!timeRanges?.[1] || !startDate || !repeatModalData) {
            return null;
        }

        const frequencyInfo = repeatModalData.selectedFrequency;
        const jumpType = frequencyInfo.id === Frequency.Weekly.id ? "week" : "month";
        const jumpSize = (frequencyInfo.ratio < 1 ? 1 / frequencyInfo.ratio : 1) * repeatModalData.repeatAmount;
        const allStartDates = [];

        for (let i = moment(startDate); i.isSameOrBefore(timeRanges?.[1]); i.add(jumpSize, jumpType)) {
            allStartDates.push(i.clone());
        }

        return allStartDates;
    }, [timeRanges, repeatModalData, startDate]);

    useEffect(() => {
        if (startDate) {
            onFieldChanged("actualStartDate", startDate);

            if (!isActive) {
                onFieldChanged("isActive", true);
            }
        }
    }, [startDate]);

    useEffect(() => {
        onFieldChanged(
            "extraDates",
            actualStartDates?.filter(date => !date.isSame(startDate))
        );
    }, [actualStartDates, startDate]);

    return (
        <>
            <HappinessCalculatorRepeatOccasionModal
                monthAmount={monthAmount}
                onClose={() => setCustomRepeatModalVisible(false)}
                onSave={data => {
                    setRepeatModalData(data);
                    setCustomRepeatModalVisible(false);
                }}
                visible={customRepeatModalVisible}
            />
            <DatePicker
                defaultPickerValue={timeRanges[0]}
                disabledDate={optional => !optional.isBetween(timeRanges[0], timeRanges[1], "day", "[]")}
                allowClear={false}
                onChange={setStartDate}
                format="DD-MM-YY"
                suffixIcon={null}
                className="happiness-calculator-range-occasion-date-input"
                placeholder="הגדר תאריך"
            />
            <Dropdown
                disabled={!startDate}
                overlay={
                    <Menu>
                        <Menu.Item onClick={() => setRepeatModalData(null)}>ללא חזרה</Menu.Item>
                        <Menu.Item onClick={() => setCustomRepeatModalVisible(true)}>בהתאמה אישית</Menu.Item>
                    </Menu>
                }>
                <div className="happiness-calculator-range-occasion-input">{repeatText}</div>
            </Dropdown>
        </>
    );
};

export default HappinessCalculatorRangeOccasionInput;
