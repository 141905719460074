import React, {useCallback} from "react";
import {StyledButton} from "../components/index.jsx";
import {Result} from "antd";
import {Images} from "../images/Images";
import "./not-found-page.css";

const {PageNotFoundImage} = Images;

export const NotFoundPage = ({history}) => {
    const onNavigateHomeClick = useCallback(() => {
        history.push("/dashboard");
    }, [history]);

    return (
        <Result
            className="not-found-page"
            icon={<img src={PageNotFoundImage} alt="page_not_found_image" />}
            style={{marginTop: 40}}
            title="אופס.. הלינק אליו הגעת כבר לא עדכני"
            subTitle={
                <div className="not-found-description">
                    <span>,מתנצלים זה לא אתם זה אנחנו</span>
                    <span>למעבר לדף בית לחצו כאן</span>
                </div>
            }
            extra={
                <StyledButton className="back-home-btn" onClick={onNavigateHomeClick}>
                    לדף הבית
                </StyledButton>
            }
        />
    );
};
