import React, {useCallback, useContext} from "react";
import {debounce} from "lodash";
import {ConfigProvider} from "antd";
import {MarketplaceSearchInput} from "./MarketplaceSearchInput";
import {AppContext} from "../AppContext";
import "../dashboards/app-dashboard.css";

export const MarketplaceSearchHeader = ({history, debouncedSearchText, setDebouncedSearchText, log}) => {
    const {me, searchInput, setSearchInput, filterValues, setFilterValues, isolationMode} = useContext(AppContext);

    const setUrlSearch = useCallback(
        (tagIds, searchInput) => {
            const queryParams = new URLSearchParams(history.location.search);

            if (tagIds) {
                queryParams.set("tags", JSON.stringify(tagIds));
            } else {
                queryParams.delete("tags");
            }

            if (searchInput) {
                queryParams.set("query", searchInput);
            }

            history.push(`/dashboard/search?${queryParams.toString()}`);
        },
        [filterValues, history?.location?.search]
    );

    const searchSelected = useCallback(
        debounce(
            (text, {tags}) => {
                setSearchInput(text);
                log("Search option selected", {text, isTag: !!tags});

                const tagIds = tags?.tagIds;

                setFilterValues(currentFilterValues => ({
                    ...currentFilterValues,
                    searchTags: tagIds,
                    searchText: text?.trim()
                }));

                setUrlSearch(tagIds, text);
            },
            30,
            {leading: true, trailing: false}
        ),
        [log, setUrlSearch]
    );

    const filterModalSaved = useCallback(
        modalFilters => {
            log("Filters Selected", modalFilters);

            setFilterValues(prev => ({
                ...prev,
                ...modalFilters
            }));

            const queryParams = new URLSearchParams(history.location.search);

            if (history.location.pathname === "/dashboard/search") {
                history.replace(`/dashboard/search?${queryParams}`);
            } else {
                history.push(`/dashboard/search?${queryParams}`);
            }
        },
        [history?.location?.search, log]
    );

    return me?.features?.show_old_search_top_bar ? (
        <div className="app-dashboard-sticky-search-container">
            <>
                {!isolationMode ? (
                    <div className="marketplace-category-filters-search-container">
                        <ConfigProvider direction="rtl">
                            <MarketplaceSearchInput
                                history={history}
                                className="marketplace-context-search-box"
                                filterValues={filterValues}
                                setFilterValues={setFilterValues}
                                searchInput={searchInput}
                                setSearchInput={setSearchInput}
                                searchSelected={searchSelected}
                                placeholder={"חיפוש בכל הפלטפורמה"}
                                debouncedSearchText={debouncedSearchText}
                                setDebouncedSearchText={setDebouncedSearchText}
                                filterModalSaved={filterModalSaved}
                            />
                        </ConfigProvider>
                    </div>
                ) : null}
            </>

            <div id="border_line" className="app-dashboard-line-seperator" />
        </div>
    ) : (
        <></>
    );
};
