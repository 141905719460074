import React from "react";
import {isEmpty, minBy, sumBy} from "lodash";
import {MarketplaceViewType} from "./consts";
import {HttpClient} from "../http/HttpClient";
import {PriceTypes} from "../admin/upload/PriceSelectorHelper";

export const MAX_CAROUSEL_LENGTH = 4;
export const OLD_MAX_CAROUSEL_LENGTH = 3;
export const MAX_PRODUCT_ITEM_WIDTH = 440;
export const CAROUSEL_PRODUCT_GAP = 20;
export const ITEMS_PER_CAROUSAL = 9;
export const MAIN_PAGE_ITEMS_PER_CAROUSAL = 12;
export const CATEGORY_ITEM_DETAILS = "categoryItemDetails";
export const PARTICIPANTS = "participants";
export const INITIAL_AMOUNT = "XX";
export const DEFAULT_MINIMUM_ORDER_NOTICE_HOURS = 24;
export const SYSTEM_MINIMUM_ORDER_NOTICE_HOURS = 3;
export const DEFAULT_DELIVERY_ARRIVING_TIME_RANGE = 1;
export const ALL_PRODUCTS_SUBCATEGORY_KEY = "*";

export const TIME_ERROR = "TIME_ERROR";
export const PLACE_ERROR = "PLACE_ERROR";
export const WARN_LEVEL = "warn";
export const ERROR_LEVEL = "error";
export const OUT_OF_BUSINESS_HOURS_ERROR = "מועד האספקה שהוגדר חורג משעות הפעילות של בית העסק";
export const OUT_OF_DELIVERY_PROVIDER_BUSINESS_HOURS_ERROR = "סיימנו לקבל הזמנות למחר, מוזמנים להזמין ליום העסקים הבא, או לכתוב לנו בכפתור הוואטסאפ למטה";
export const DELIVERY_PROVIDER_DEADLINE_HOUR = 18;
export const DISABLED_DATE_ERROR = "הזמנות לתאריך אלו נסגרו עקב גמר המלאי ({DISABLED_DATES}), אנא בחרו תאריך אחר 🙏🏻";

const PRODUCT_BUNDLE_MIN_PRODUCTS = 2;

export const getAllProductsSubCategory = categoryName => ({
    subCategory: `לכל ${categoryName}`
});

export const isProductActive = (product, provider, me) =>
    provider?.approved && (product?.isActive || product?.allowedCompanies?.includes(me.companyId));

export const isProductBundleActive = (productBundle, me) => {
    if (!productBundle?.isActive) {
        return false;
    }

    const isProductBundleHasMinProducts =
        productBundle?.products?.filter(Boolean).length >= PRODUCT_BUNDLE_MIN_PRODUCTS;

    const isAllProductsHasRecommendedProductParticipants = productBundle?.products?.every(
        product => product.minRecomendedProductParticipants || product.maxRecomendedProductParticipants
    );

    const isAllProductsActive = productBundle?.products?.every(product => product?.isActive);
    const isAllProductsProvidersApprove = productBundle?.providers?.every(provider => provider?.approved);

    return (
        isAllProductsProvidersApprove &&
        isAllProductsHasRecommendedProductParticipants &&
        isProductBundleHasMinProducts &&
        (isAllProductsActive || productBundle?.allowedCompanies?.includes(me.companyId))
    );
};

export const CategorySearchSorterType = {
    BestSeller: "bestSeller",
    LowestFirst: "lowestFirst",
    HighestFirst: "highestFirst",
    Rate: "rate"
};

export const DEFAULT_DELIVERY_PRICE = 50;

export const DEFAULT_DELIVERY_MIN_NOTICE = 24;

export const getSwitchImageByServiceId = serviceId => {
    switch (serviceId) {
        case "happyhours":
            return "happyHourSwitchImage";
        case "packages":
            return "packageSwitchImage";
        case "workshops":
            return "workshopSwitchImage";
        case "lectures":
            return "lectureSwitchImage";
        case "platters":
            return "platterSwitchImage";
    }
};

export const serviceToImageV2 = name => {
    switch (name) {
        case "happyhours":
            return "happyHoursV2";
        case "packages":
            return "packagesV2";
        case "workshops":
            return "workshopsV2";
        case "lectures":
            return "lecturesV2";
        case "platters":
            return "plattersV2";
        default:
            return name;
    }
};

export const priceTypeOrder = ["byPerson", "byGroup", "byPlatte", "byUnit", "byPackage"];

export const calculateModularProductDefaultPrice = (product, providerDetails) =>
    product?.pricingTable?.byModular?.subProducts?.reduce((acc, subProduct) => {
        const foundProviderSubProduct = providerDetails.subProducts.find(({id}) => id === subProduct?.id);
        if (foundProviderSubProduct && subProduct?.isActive)
            return acc + foundProviderSubProduct.price * (subProduct?.defaultAmount ?? 0);
        return acc;
    }, 0);

export const getFromPriceForProduct = ({service, provider}) => {
    if (service.chosenPriceType === "byModular") {
        return calculateModularProductDefaultPrice(service, provider);
    }

    const defaultPriceTypeArray = getDefaultPriceTypeArray(service) || [];

    const fromPrice = minBy(defaultPriceTypeArray, row => parseFloat(row.price ?? row.redeemableValue));

    return fromPrice?.price ?? fromPrice?.redeemableValue;
};

export const getDefaultTypeToShow = service => {
    if (service.chosenPriceType) {
        return service.chosenPriceType;
    }
    const typeIndex = priceTypeOrder.findIndex(
        type => !isEmpty(service.pricingTable[type]) && service.pricingTable[type].some(row => row.price)
    );
    return typeIndex >= 0 ? priceTypeOrder[typeIndex] : priceTypeOrder[0];
};

export const getDefaultPriceTypeArray = service => {
    if (isEmpty(service.pricingTable)) return [];

    switch (service.chosenPriceType) {
        case "byModular":
            return [];
        default:
            return service.pricingTable[getDefaultTypeToShow(service)];
    }
};

export const getProductMinPrice = product => {
    switch (product.service.chosenPriceType) {
        case "byModular":
            return product.service.pricingTable.byModular?.minPrice ?? 0;
        case "byVoucher":
            return minBy(product.service.pricingTable.byVoucher, "redeemableValue")?.redeemableValue;
        default:
            return (
                getFromPriceForProduct(product) *
                (product.service.chosenPriceType === PriceTypes.ByGroup
                    ? 1
                    : getDefaultPriceTypeMinimumAmount(product.service))
            );
    }
};

export const getDefaultPriceTypeMinimumAmount = service => {
    const priceTypeArray = getDefaultPriceTypeArray(service);

    if (isEmpty(priceTypeArray) || !priceTypeArray[0].min) {
        return "1";
    }
    const minArray = priceTypeArray.filter(row => row.price).map(row => parseInt(row.min));
    return isEmpty(priceTypeArray) ? "1" : Math.min(...minArray);
};

export const getDefaultPriceTypeMaximum = service => {
    const priceTypeArray = getDefaultPriceTypeArray(service);
    if (isEmpty(priceTypeArray)) {
        return Infinity;
    }
    const maxArray = priceTypeArray.filter(row => row.price).map(row => parseInt(row.max));
    return isEmpty(priceTypeArray) ? Infinity : Math.max(...maxArray);
};

export const isAmountOutOfBoundaries = (service, price) => {
    if (!price) {
        return null;
    }

    const minimum = getDefaultPriceTypeMinimumAmount(service);

    if (price < minimum) {
        return -1;
    }

    if (getDefaultTypeToShow(service) !== "byGroup" && price > getDefaultPriceTypeMaximum(service)) {
        return 1;
    }

    return 0;
};

export const isHTML = str => {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return [].slice.call(doc.body.childNodes).some(node => node.nodeType === 1);
};

export const hebDayTranslate = day => {
    switch (day) {
        case "sunday":
            return "ראשון";
        case "monday":
            return "שני";
        case "tuesday":
            return "שלישי";
        case "wednesday":
            return "רביעי";
        case "thursday":
            return "חמישי";
        case "friday":
            return "שישי";
        case "saturday":
            return "שבת";
    }
};

export const hebLocationTranslate = loc => {
    switch (loc) {
        case "online":
            return "אונליין";
        case "offsite":
            return "בית העסק";
        case "office":
            return "משרד";
        case "onsite":
            return "בית העסק";
    }
};

export const getStatusColor = status => {
    switch (status) {
        case "pending":
            return "#1260D1";
        case "accepted":
            return "#4DC95C";
        case "declined":
            return "#8398A6";
        case "cancelled":
            return "#FF0000";
    }
};

export const getStatusTranslation = status => {
    switch (status) {
        case "pending":
            return "ממתינה לאישור";
        case "accepted":
            return "אושרה";
        case "declined":
            return "בבדיקה";
        case "cancelled":
            return "בוטלה";
    }
};

export const getFormattedDayTranslate = day => {
    switch (day) {
        case "SU":
            return "יום א׳";
        case "MO":
            return "יום ב׳";
        case "TU":
            return "יום ג׳";
        case "WE":
            return "יום ד׳";
        case "TH":
            return "יום ה׳";
        case "FR":
            return "יום ו׳";
        case "SA":
            return "יום שבת";
    }
};

export const typeToHebrewName = type => {
    switch (type) {
        case "byGroup":
            return "קבוצה";
        case "byPerson":
            return "משתתף";
        case "byPlatte":
            return "מגש";
        case "byUnit":
            return "יחידה";
        case "byPackage":
            return "מארז";
        case "byModular":
            return "חבילה";
    }
};

export const typeToPluralHebrewName = type => {
    switch (type) {
        case "byGroup":
            return "משתתפים";
        case "byPerson":
            return "משתתפים";
        case "byPlatte":
            return "מגשים";
        case "byUnit":
            return "יחידות";
        case "byPackage":
            return "מארזים";
        case "byModular":
            return "חבילה";
        case "byVoucher":
            return "שובר";
    }
};

export const WeekdaysOrder = {
    sunday: 1,
    monday: 2,
    tuesday: 3,
    wednesday: 4,
    thursday: 5,
    friday: 6,
    saturday: 7
};

export const WeekdaysSorter = weekdaysMap => {
    return weekdaysMap.sort((a, b) => WeekdaysOrder[a] - WeekdaysOrder[b]);
};

export const sendAlertToCsmWithEmail = async (companyId, price, status, productName, productId, filledValues) =>
    await HttpClient.safePost("/api/events/emailBookingErrorToCSM", {
        companyId,
        price,
        status,
        productName,
        productId,
        filledValues
    });

export const getMarketplaceViewLink = (type, content) => {
    let url;
    switch (type) {
        case MarketplaceViewType.product: {
            url = `/dashboard/services/product/${content}`;
            break;
        }
        case MarketplaceViewType.selectionPage: {
            url = `/selectionPage/${content}`;
            break;
        }
        case MarketplaceViewType.link: {
            url = content;
        }
    }

    return url;
};

export const getProductMinOrderText = content => {
    if (content.service.isProductBundle && content.service.productBundleProducts?.length) {
        const minPrice = sumBy(content.service.productBundleProducts, product =>
            getProductMinPrice({service: product})
        );
        return `מינ׳ הזמנה: ${minPrice} ₪`;
    }

    const productType = getDefaultTypeToShow(content?.service);

    if (productType === PriceTypes.ByModular) {
        return `מינ׳ הזמנה: ${getProductMinPrice(content)} ₪`;
    }

    return `מינ׳ הזמנה: ${getDefaultPriceTypeMinimumAmount(content?.service)} ${typeToPluralHebrewName(
        getDefaultTypeToShow(content?.service)
    )}`;
};
