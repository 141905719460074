import React, {useContext, useCallback} from "react";
import {Table} from "antd";
import {getColumns} from "./columns";
import {AppContext} from "../../../AppContext";
import {useRequest} from "../../../utils/hooks";
import {DraggableBodyRow, DraggableTable} from "../../../components/dragableTable";
import {uniq} from "lodash";
import {NOOP} from "../../../utils/NOOP";

const DEFAULT_PAGE_SIZE = 50;

export const ProductsTable = ({
    products = [],
    loading,
    rowSelection = null,
    pageSize,
    modifyBlacklistProductById,
    blacklistedProductIds,
    setOrderedProductIds,
    orderedProductIds = null,
    shouldSort = false,
    onUpdateTablePagination = NOOP,
    totalProducts
}) => {
    const {me} = useContext(AppContext);

    const [productToSelectionSites] = useRequest(
        "/admin/api/selectPage/byProductIds",
        "post",
        uniq(products.map(product => product.service.productId)),
        [products],
        !!products.length,
        null,
        {}
    );

    const onSortEnd = useCallback(
        ({oldIndex, newIndex, collection, isKeySorting}) => {
            const newOrder = [...orderedProductIds];
            const [movedItem] = newOrder.splice(oldIndex, 1);
            newOrder.splice(newIndex, 0, movedItem);
            setOrderedProductIds(newOrder);
        },
        [setOrderedProductIds, orderedProductIds, products]
    );

    const moveToEnd = useCallback(
        product => {
            const productId = product.service.productId;
            const index = orderedProductIds.indexOf(productId);
            let newOrder = [...orderedProductIds];

            if (index === -1) {
                newOrder = [productId, ...newOrder];
            } else {
                const [movedItem] = newOrder.splice(index, 1);
                newOrder = [...newOrder, movedItem];
            }
            setOrderedProductIds(newOrder);
        },
        [setOrderedProductIds, orderedProductIds]
    );

    const moveToStart = useCallback(
        product => {
            const productId = product.service.productId;
            const index = orderedProductIds.indexOf(productId);
            let newOrder = [...orderedProductIds];

            if (index === -1) {
                newOrder = [productId, ...newOrder];
            } else {
                const [movedItem] = newOrder.splice(index, 1);
                newOrder = [movedItem, ...newOrder];
            }
            setOrderedProductIds(newOrder);
        },
        [setOrderedProductIds, orderedProductIds]
    );

    const columns = getColumns(
        me,
        modifyBlacklistProductById,
        productToSelectionSites,
        shouldSort,
        moveToStart,
        moveToEnd
    );

    const componentsProps = shouldSort
        ? {
              components: {
                  body: {
                      wrapper: props => (
                          <DraggableTable
                              helperClass="edit-selection-page-row-dragging"
                              onSortEnd={onSortEnd}
                              {...props}
                          />
                      ),
                      row: props => <DraggableBodyRow rowKey="service.productId" dataSource={products} {...props} />
                  }
              }
          }
        : {};

    return (
        <Table
            rowKey={product => product.service.productId}
            className="admin-product-table-container"
            scroll={{x: 1300, y: 400}}
            pagination={{
                position: ["bottomCenter"],
                showSizeChanger: false,
                defaultPageSize: pageSize,
                total: totalProducts,
                onChange: (page, pageSize) => onUpdateTablePagination({page, pageSize}),
                showTotal: total => <span>סה״כ {total}</span>
            }}
            rowClassName={product => {
                if (blacklistedProductIds.includes(product.service.productId)) {
                    return "black-list-product";
                }
            }}
            loading={loading}
            dataSource={products}
            columns={columns}
            rowSelection={rowSelection}
            {...componentsProps}
        />
    );
};
