import {DynamicForm} from "../../components/DynamicForm.jsx";
import {NOOP} from "../../utils/NOOP.jsx";
import {ConfigProvider, message} from "antd";
import React, {useCallback, useContext, useMemo, useState} from "react";
import {HttpClient} from "../../http/HttpClient.jsx";
import {set} from "lodash";
import {AppContext} from "../../AppContext.jsx";

export const UserPlusOneForm = () => {
    const {me} = useContext(AppContext);
    const [userInfo, setUserInfo] = useState({});

    const onUpdateForm = useCallback((_allValues, changedField) => {
        const [fieldKey, fieldValue] = Object.entries(changedField)[0];
        setUserInfo(currentValues => set({...currentValues}, fieldKey, fieldValue));
    }, []);

    const onSaveUserPlusOne = useCallback(async formValues => {
        const {error} = await HttpClient.safePost("/admin/api/user/updateEmail", {email: formValues.email});

        if (error) {
            message.error(`שגיאה בעת עידכון המייל (${error})`);
            return;
        }

        message.success("הוספת +1 למייל בוצעה בהצלחה");
    }, []);

    const EmailPlusOneFormFields = useMemo(
        () => [
            {
                className: "admin-user-management-input-email",
                name: "email",
                type: "email",
                label: "אימייל"
            }
        ],
        []
    );

    return (
        <ConfigProvider direction="rtl">
            <DynamicForm
                validationFunction={async values => {
                    if (me?.position !== "PRODUCT") {
                        message.error("אינך בעל הרשאות לבצע פעולה זאת");
                        return false;
                    }

                    await onSaveUserPlusOne(values);
                    return false;
                }}
                onSave={NOOP}
                content={userInfo}
                onUpdate={onUpdateForm}
                fields={EmailPlusOneFormFields}
            />
        </ConfigProvider>
    );
};
