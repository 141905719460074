import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {OrderInformationCard} from "./OrderInformationCard";
import {generateAssignUserContactWhatsAppLink, getOrderContactInfo} from "./utils";
import {HttpClient} from "../http/HttpClient";
import {EventBus} from "../bus/EventBus";
import {ConfirmationRequiredWrapper, GenericModal, OrderStatus, StyledButton} from "../components/index.jsx";
import {Images} from "../images/Images.jsx";
import {AppContext} from "../AppContext.jsx";
import {systemThemeName, themeNamesToText, UserType} from "../consts.js";
import {StringBuilder} from "../AppUtils.js";
import {FormInputV2} from "../components/form/index.jsx";
import {useRequest} from "../utils/hooks.jsx";
import {validateOrderDisabledForEdit} from "../admin/AdminOrders/utils.jsx";
import {openWindow} from "../http/WindowOpener.jsx";
import {generateLionWheelOrderDeliveryStatusLink, getOrderStepStatus} from "./OrderDeliveryStatus/utils.jsx";
import {message} from "antd";
import {EditOutlined, WhatsAppOutlined} from "@ant-design/icons";
import moment from "moment";
import {TruckDeliveryIcon} from "../icons/index.jsx";
import "./event-order.css";
import {getThemeByOrigin} from "../utils.js";

export const EventOrderV2 = ({match}) => {
    const {me, company} = useContext(AppContext);
    const [showFinishModal, setShowFinishModal] = useState(false);
    const [isUserApproveEvent, setIsUserApproveEvent] = useState(true);
    const [finishModalErrorMessage, setFinishModalErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showCancelReasonInput, setShowCancelReasonInput] = useState(false);
    const [cancelReason, setCancelReason] = useState(null);

    const [eventInfo, loadingEvent, eventError, fetchEvent] = useRequest(
        `/api/events/${match?.params?.eventId}/orderV2`,
        "GET",
        null,
        [match?.params?.eventId],
        !!match?.params?.eventId
    );

    const contactsInfo = useMemo(() => getOrderContactInfo(eventInfo), [eventInfo]);

    const isMobileView = () => window.innerWidth < 550;

    useEffect(() => {
        if (eventError) {
            EventBus.triggerError(
                "server-error",
                {
                    content: {
                        description: "Unfortunately we didn't manage to present the session details :("
                    }
                },
                eventError.message
            );
        }
    }, [eventError]);

    const ProviderInfoSection = useCallback(({eventInfo, includeProductName}) => {
        return (
            <span className="event-order-provider-info-section-container">
                {includeProductName ? (
                    <div>
                        {eventInfo?.productName} {" | "}
                    </div>
                ) : null}
                <div>
                    {"טלפון:"} {eventInfo?.providerPhone}
                </div>
                {eventInfo?.providerEmail ? <div>{`אימייל: ${eventInfo?.providerEmail}`}</div> : null}
            </span>
        );
    }, []);

    const isPendingManagerConfirm = useMemo(
        () =>
            eventInfo?.status === OrderStatus.pending &&
            eventInfo?.eventStatusMap?.some(({id}) => id === OrderStatus.pendingManagerConfirm),
        [eventInfo]
    );

    const isManagerApproveMode = useMemo(() => {
        const isWaitingForApproveByManager =
            (eventInfo?.needManagerConfirm || (eventInfo?.orders ?? []).some(order => !!order.needManagerConfirm)) &&
            isPendingManagerConfirm;
        if (me?.isAdmin && isWaitingForApproveByManager) {
            return true;
        }
        return (
            company?.companyId === eventInfo?.companyId &&
            eventInfo?.creatingUserId !== me?.userId &&
            isWaitingForApproveByManager &&
            me?.type !== UserType.Provider &&
            (me?.isCompanyMaster || (me?.manageDepartments ?? []).includes(eventInfo?.creatingUserDepartment))
        );
    }, [me, isPendingManagerConfirm, eventInfo, company]);

    const acceptOfferByManager = useCallback(async () => {
        setLoading(true);
        const response = await HttpClient.safePost(`/api/events/${eventInfo.eventId}/acceptByManager`);
        if (response.error) {
            setFinishModalErrorMessage(response.error);
            EventBus.triggerError(
                "server-error",
                {
                    content: {
                        description: "Unfortunately we didn't manage to accept this proposal :("
                    }
                },
                response.error
            );
            return;
        } else {
            setIsUserApproveEvent(true);
            fetchEvent();
        }
        setShowFinishModal(true);
        setLoading(false);
    }, [eventInfo, fetchEvent]);

    const declineOfferByManager = useCallback(async () => {
        try {
            await HttpClient.safePost(`/api/events/${eventInfo.eventId}/declineByManager`, {cancelReason});
            setShowFinishModal(true);
            setIsUserApproveEvent(false);
            fetchEvent();
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {
                    content: {
                        description: "Unfortunately we didn't manage to decline this proposal :("
                    }
                },
                e.message
            );
        }
    }, [cancelReason, eventInfo, fetchEvent]);

    const finishModalTitle = useMemo(() => {
        if (finishModalErrorMessage) {
            return "שים לב";
        }
        return isUserApproveEvent ? "שמחים לשמוע" : "הזמנה זו סורבה";
    }, [isUserApproveEvent, finishModalErrorMessage]);

    const finishModalText = useMemo(() => {
        if (finishModalErrorMessage) {
            return [finishModalErrorMessage];
        }
        return isUserApproveEvent
            ? ["ההזמנה נשלחה עכשיו לאישור הספק.", "התת אדמין שביצע את ההזמנה יהיה אחראי עליה"]
            : ["נשלח עדכון לאדמין על סיבת הסירוב להזמנה. במידה ויבצע הזמנה חדשה נשלח לך לאישור"];
    }, [isUserApproveEvent, finishModalErrorMessage]);

    const onDeclineClick = useCallback(() => {
        window.scrollTo(0, document.body.scrollHeight);
        setShowCancelReasonInput(true);
    }, []);

    const oncancelReasonChange = useCallback(event => setCancelReason(event.target.value), []);

    const onCloseFinishModal = useCallback(() => setShowFinishModal(false), []);

    const isSupplyDayOrderToday = useMemo(
        () => moment().isSameOrAfter(eventInfo?.dtstart, "day"),
        [eventInfo?.dtstart]
    );

    const onFollowDeliveryOrderClick = useCallback(() => {
        if (!isSupplyDayOrderToday) {
            message.error("ביום האספקה נשלח לך הודעה שההזמנה נאספה עם לינק מעקב ופרטי השליח");
            return;
        }
        openWindow(generateLionWheelOrderDeliveryStatusLink(eventInfo?.shippingDetails));
    }, [eventInfo?.shippingDetails, isSupplyDayOrderToday]);

    const onContactClick = useCallback(() => {
        openWindow(generateAssignUserContactWhatsAppLink(eventInfo.systemId));
    }, [eventInfo?.assignedCSMs]);

    const onEditOrder = useCallback(() => {
        const validation = validateOrderDisabledForEdit(eventInfo, false);

        if (validation?.error) {
            message.error(validation.error);
            return;
        }

        openWindow(`/events/${eventInfo.eventId}/edit`);
    }, [eventInfo]);

    const orderTitle = useMemo(() => {
        const theme = getThemeByOrigin(eventInfo?.originUrl);
        return new StringBuilder("כיף שהזמנת עם").append(themeNamesToText[theme] ?? systemThemeName).toString();
    }, [eventInfo?.originUrl]);

    return (
        <div className="event-order-v2-container">
            <GenericModal
                title={finishModalTitle}
                className="company-edit-order-sure-modal"
                text={finishModalText}
                image={Images.areYouSure}
                closeable={true}
                visible={showFinishModal}
                onClose={() => setShowFinishModal(false)}
                options={[
                    {
                        id: "accept",
                        onClick: onCloseFinishModal,
                        text: "סגירה"
                    }
                ]}
            />
            {!!eventInfo && (
                <OrderInformationCard
                    eventInfo={eventInfo}
                    contactsInfo={contactsInfo}
                    userType={me?.type === UserType.Company || me.isAdmin ? UserType.Company : null}
                    title={orderTitle}
                    additionalInfo={[
                        "איש קשר לקבלת ההזמנה - יש לוודא שהפרטים תקינים ומדויקים. מומלץ לשים איש קשר נוסף למקרה הצורך שיהיה זמין בעת ביום האספקה.",
                        "משלוח ללא מענה - בעת קבלת המשלוח במידה ואיש הקשר אינו זמין, המשלוח יושאר בכתובת המצוינת. במידה ולא ניתן להשאיר את המשלוח, השליח ייקח אותה חזרה, ותחוייבו על ההזמנה במלואה.",
                        "זמן אספקה - זמן האספקה הוא בטווח של שעתיים, בחגים ייתכנו עיכובים קלים בזמני השילוח. אנו מציעים לקחת בחשבון זמן נוסף ולהיות סבלניים."
                    ]}
                    actions={[
                        {
                            text: "עריכה",
                            icon: <EditOutlined />,
                            onClick: onEditOrder,
                            color: validateOrderDisabledForEdit(eventInfo, false) ? "gray" : null
                        },
                        {
                            text: "ליצירת קשר עם חווית לקוח",
                            icon: <WhatsAppOutlined />,
                            onClick: onContactClick
                        },
                        ...(getOrderStepStatus(eventInfo).isOrderOnTheWay
                            ? [
                                  {
                                      text: "מעקב בזמן אמת",
                                      icon: <TruckDeliveryIcon />,
                                      onClick: onFollowDeliveryOrderClick,
                                      color: !isSupplyDayOrderToday ? "gray" : null
                                  }
                              ]
                            : [])
                    ]}>
                    {eventInfo?.status === "pending" && (
                        <>
                            {!!isManagerApproveMode && (
                                <div className="cta-container">
                                    <ConfirmationRequiredWrapper
                                        title="מנהל יקר, שים לב 💙"
                                        subTitle="בעת לחיצה על אישור ההזמנה, ההזמנה תכנס למערכת ותעבור לאישור הספק. התת אדמין שביצע את ההזמנה יהיה אחראי עליה."
                                        yesText="מאשר לבצע את ההזמנה"
                                        onYes={acceptOfferByManager}>
                                        <StyledButton loading={loading} className="confirm-btn">
                                            מאשר לבצע הזמנה
                                        </StyledButton>
                                    </ConfirmationRequiredWrapper>
                                    <StyledButton onClick={onDeclineClick} className="decline-btn">
                                        מסרב לבצע את ההזמנה
                                    </StyledButton>
                                </div>
                            )}
                            <div className="order-information-card-children-container">
                                {!!(
                                    eventInfo?.needManagerConfirm &&
                                    !isPendingManagerConfirm &&
                                    me?.type !== UserType.Provider
                                ) && (
                                    <span className="accepted-manager-order-title">
                                        בקשתך אושרה ונשלחה לאישור בית העסק
                                    </span>
                                )}

                                {!!(showCancelReasonInput && isManagerApproveMode) && (
                                    <div className="event-decline-reason-container">
                                        <span className="event-decline-reason-container-title">סיבת הסירוב</span>
                                        <span className="event-decline-reason-container-sub-title">
                                            {new StringBuilder()
                                                .append("רשמו")
                                                .append(`ל${eventInfo?.companyContact}`)
                                                .append("את סיבת הסירוב")
                                                .toString()}
                                        </span>
                                        <div className="event-decline-reason-input-container">
                                            <FormInputV2
                                                status={!cancelReason ? "error" : null}
                                                placeholder="הקלידו כאן"
                                                onChange={oncancelReasonChange}
                                                className="event-decline-reason-input"
                                            />
                                            <StyledButton
                                                disabled={!cancelReason}
                                                className="event-decline-reason-send-btn"
                                                onClick={declineOfferByManager}>
                                                שליחה
                                            </StyledButton>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    <span className="event-order-help-title">{"לכל עניין או בקשה מוזמנים ליצור קשר עם הספק:"}</span>

                    {eventInfo?.orders?.length ? (
                        eventInfo.orders.map((order, index) => (
                            <ProviderInfoSection key={index} eventInfo={order} includeProductName={true} />
                        ))
                    ) : (
                        <ProviderInfoSection eventInfo={eventInfo} />
                    )}
                </OrderInformationCard>
            )}
        </div>
    );
};
