import React, {useCallback, useContext, useEffect, useState} from "react";
import {message} from "antd";
import {throttle} from "lodash";
import {parse as qsParse} from "query-string";
import {EXCenterFooter} from "./EXCenterFooter";
import EXCenterHeader from "./EXCenterHeader";
import {EXCenterContext} from "./EXCenterContext";
import {EXCenterRoutes} from "./EXCenterRoutes";
import {EXCenterBrowserNavBar} from "./domain/EXCenterBrowserNavBar";
import {ensureDesignForSection} from "./control/ensureDeisgnForSection";
import EXCenterEditModeTopBar from "./EXCenterEditModeTopBar";
import {isCenterInPreviewMode} from "./utils/ViewUtil";
import {
    fillPickerPhotos,
    getCurrentScreenSize,
    isBranchesPage,
    isScreenSizeOfTypes,
    ScreenSize,
    shouldRedirectToBranchesSelection
} from "./EXCenterHelper";
import {
    defaultSelectedTheme,
    defaultThemes,
    THEME_COLOR_1,
    THEME_COLOR_2,
    THEME_COLOR_3,
    THEME_COLOR_4
} from "../color/ColorTheme";
import ScrollToTop from "../components/ScrollToTop";
import {HttpClient} from "../http/HttpClient";
import {EventBus} from "../bus/EventBus";
import {AppContext} from "../AppContext";
import {CompanyContent} from "../company/content/CompanyContent";
import {PageLoader} from "../components";
import {useResizeHandler} from "../utils/useResizeHandler";
import {ReviewModal} from "../review/ReviewModal";

export const EXCenter = () => {
    const location = window.location;
    const {branchId} = qsParse(location.search);
    const {me, company} = useContext(AppContext);
    const [homeContents, setHomeContents] = useState(null);
    const [allContents, setAllContents] = useState(null);
    const [events, setEvents] = useState(null);
    const [designInfo, setDesignInfo] = useState(null);
    const [screenSize, setScreenSize] = useState(getCurrentScreenSize());
    const [defaultBranchId, setDefaultBranchId] = useState(branchId);

    if (defaultBranchId !== branchId) {
        setTimeout(async () => {
            await refreshCenter();
            setDefaultBranchId(branchId || null);
        }, 50);
    }

    useEffect(() => {
        if (!shouldRedirectToBranchesSelection(location, branchId)) {
            Promise.resolve().then(async () => await refreshCenter());
        }

        EventBus.on("ex_center_design:update", designInfo => {
            setDesignInfo({...designInfo});
        });

        EventBus.on("ex_center:refresh", async () => {
            await refreshCenter();
        });

        document.addEventListener(
            "scroll",
            throttle(() => {
                document.documentElement.dataset.scroll = window.scrollY.toString(10);
                if (window.scrollY > 0) {
                    document.documentElement.dataset.scrolled = "true";
                }
            }, 500)
        );

        return useResizeHandler(() => setScreenSize(getCurrentScreenSize()));
    }, []);

    if (shouldRedirectToBranchesSelection(location, branchId)) {
        window.location.href = `/center/branches/auto${location.search || ""}`;
        return null;
    }

    const getEventsByApp = useCallback(
        events => {
            if (events && me.type === "employee") {
                return events.filter(evt => !evt.v2);
            }
            return events;
        },
        [events]
    );

    const refreshCenter = async () => {
        try {
            const [allContents, homeContents, events, designInfo] = await Promise.all([
                HttpClient.get("/api/content"),
                HttpClient.get("/api/content/home"),
                HttpClient.get("/api/events"),
                HttpClient.get("/api/website/settings")
            ]);

            setAllContents(allContents);
            setHomeContents(homeContents);
            setEvents(getEventsByApp(events));
            setDesignInfo(designInfo);
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
    };

    const shouldHideNavigation = () => {
        return isBranchesPage(location);
    };

    const shouldShowBrowserLookAndFeel = () => {
        return me.type === "company" && !isCenterInPreviewMode();
    };

    if (allContents === null || homeContents === null || events === null || designInfo === null || !me || !company) {
        return <PageLoader align="flex-start" top={200} />;
    }

    if (me && !me.branch && !isBranchesPage(location)) {
        return (window.location.href = "/center/branches/direct");
    }

    const branding = company.branding;
    const companyTheme =
        branding && branding.selectedTheme && branding.themes
            ? branding.themes[branding.selectedTheme]
            : defaultThemes[defaultSelectedTheme];

    const theme = {
        name: branding.selectedTheme,
        primaryColor: companyTheme.colors[THEME_COLOR_1],
        secondaryColor: companyTheme.colors[THEME_COLOR_2],
        backgroundColor: companyTheme.colors[THEME_COLOR_3],
        textColor: companyTheme.colors[THEME_COLOR_4]
    };

    const designInfoPerSection = CompanyContent.Sections.reduce((perSection, sectionInfo) => {
        const ensuredDesignInfo = ensureDesignForSection({...designInfo}, sectionInfo.sectionId, theme);
        perSection[sectionInfo.sectionId] = ensuredDesignInfo.design[sectionInfo.sectionId];
        return perSection;
    }, {});

    return (
        <EXCenterContext.Provider
            value={{
                allContents,
                homeContents,
                events,
                originalDesignInfo: designInfo,
                designInfo: {
                    ...designInfo,
                    pickerPhotos: fillPickerPhotos(designInfo, theme)
                },
                designInfoPerSection,
                theme,
                screenSize,
                defaultBranchId
            }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center"
                }}>
                {me.type === "employee" ? <ReviewModal /> : null}
                {shouldShowBrowserLookAndFeel() ? <EXCenterEditModeTopBar /> : null}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        padding: shouldShowBrowserLookAndFeel()
                            ? isScreenSizeOfTypes([ScreenSize.Mobile, ScreenSize.Tablet], screenSize)
                                ? 5
                                : 20
                            : 0,
                        backgroundColor: shouldShowBrowserLookAndFeel() ? "#DCE1E8" : "white"
                    }}>
                    <div
                        className="wb-center"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            width: "100%",
                            minHeight: "100vh",
                            borderRadius: shouldShowBrowserLookAndFeel() ? 20 : 0,
                            backgroundColor: "white",
                            overflow: "hidden"
                        }}>
                        {shouldShowBrowserLookAndFeel() ? <EXCenterBrowserNavBar /> : null}
                        <div
                            className="wb-center"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                paddingBottom: shouldHideNavigation() ? 200 : 600
                            }}>
                            <ScrollToTop />
                            {shouldHideNavigation() ? null : <EXCenterHeader />}
                            <EXCenterRoutes />
                        </div>
                        <EXCenterFooter hideBottomNav={shouldHideNavigation()} />
                    </div>
                </div>
            </div>
        </EXCenterContext.Provider>
    );
};
