import React, {useCallback, useContext, useEffect, useState} from "react";
import {Input, Switch} from "antd";
import {set} from "lodash";
import {BudgetCategoryTypes} from "./consts";
import {ServiceIdToName} from "../../consts.js";
import {NOOP} from "../../utils/NOOP";
import {AppContext} from "../../AppContext";

export const BudgetTypeSelectOption = ({
    subAdminBudget,
    className,
    mainServices,
    onChange = NOOP,
    hasCategoryBudget,
    disabled
}) => {
    const {me} = useContext(AppContext);
    const [budgets, setBudgets] = useState({});

    const onUpdateBudgets = useCallback(
        (budgetName, value) => {
            if (budgetName === BudgetCategoryTypes.GENERAL && !hasCategoryBudget) {
                setBudgets({[BudgetCategoryTypes.GENERAL]: value});
                return;
            }

            setBudgets(currentBudgets => set({...currentBudgets}, budgetName, value));
        },
        [hasCategoryBudget]
    );

    useEffect(() => {
        if (subAdminBudget) {
            setBudgets(subAdminBudget);
        }
    }, [subAdminBudget]);

    useEffect(() => {
        onChange(budgets);
    }, [budgets]);

    return (
        <div className={className}>
            <div className="header">
                <label>תקציב</label>
                {me.features?.category_budget && (
                    <Switch
                        checked={hasCategoryBudget}
                        checkedChildren="מבוזר"
                        unCheckedChildren="כללי"
                        disabled={disabled}
                        onChange={newValue => onUpdateBudgets("hasCategoryBudget", newValue)}
                    />
                )}
            </div>
            <Input
                type="number"
                disabled={disabled}
                value={
                    subAdminBudget || hasCategoryBudget
                        ? budgets[BudgetCategoryTypes.GENERAL]
                        : Object.values(budgets ?? {}).reduce((acc, value) => Number(value) + acc, 0)
                }
                onChange={e => onUpdateBudgets(BudgetCategoryTypes.GENERAL, e.target.value)}
                suffix={hasCategoryBudget ? "תקציב צף" : ""}
            />
            {hasCategoryBudget &&
                mainServices.map(({serviceId}) => (
                    <Input
                        key={serviceId}
                        disabled={disabled}
                        type="number"
                        value={budgets[serviceId]}
                        onChange={e => onUpdateBudgets(serviceId, e.target.value)}
                        suffix={`תקציב קטגוריית ${ServiceIdToName[serviceId]}`}
                    />
                ))}
        </div>
    );
};
