import React, {useContext, useEffect, useState} from "react";
import {message} from "antd";
import {isEmpty} from "lodash";
import {EXCenterSectionCategories} from "../sections/EXCenterSectionCategories";
import {EXCenterSectionItems} from "../sections/EXCenterSectionItems";
import {organizeContent} from "../utils/ContentUtil";
import {EXCenterSearchInput} from "./EXCenterSearchInput";
import {HttpClient} from "../../http/HttpClient";
import {EXCenterContext} from "../EXCenterContext";
import {scrollToElement} from "../utils/ViewUtil";
import {toValidHtmlId} from "../utils/DomUtil";
import {PageLoader} from "../../components";
import {parse as qsParse} from "query-string";
import {removeKeysFromSearch} from "../../AppHistoryAdapter";

export const EXCenterSearchPage = ({history}) => {
    const topSectionId = "top";
    const {events, designInfoPerSection, theme} = useContext(EXCenterContext);
    const [contents, setContents] = useState(null);
    const [searchInFocus, setSearchInFocus] = useState(false);
    const [itemsByCategory, setItemsByCategory] = useState(null);
    const [categories, setCategories] = useState(null);
    const [term, setTerm] = useState("");

    let searchInputRef = null;
    let categoriesRef = null;

    const sectionDesignInfo = designInfoPerSection[topSectionId];

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const contents = await HttpClient.get("/api/content");
                setContents(contents);
            } catch (e) {
                message.error("Something went wrong, please try again later.", 5);
            }
        });
    }, []);

    useEffect(() => {
        if (!isEmpty(term) && isEmpty(itemsByCategory)) {
            message.destroy();
            message.error("no results for this search", 2);
        }
    }, [itemsByCategory]);

    useEffect(() => {
        if (searchInputRef && !searchInFocus) {
            setSearchInFocus(true);
            searchInputRef.focus();
        }
    });

    const onCategorySelect = category => {
        if (category) {
            scrollToElement(`#category-${toValidHtmlId(category)}`);
        }
    };

    const onSearch = value => {
        setTerm(value ? value.toLowerCase().trim() : "");
        categoriesRef && categoriesRef.reset();
        setItemsByCategory(null);
        setCategories(null);
    };

    const searchByImmediateQuery = () => {
        const {q} = qsParse(location.search);
        if (typeof q === "string" && q.length > 0 && !term) {
            const search = removeKeysFromSearch(["q"]);
            history.replaceWithoutReload(`${location.pathname}${search ? `?${search}` : ""}`);
            onSearch(decodeURIComponent(q));
        }
    };

    if (contents && events && (itemsByCategory === null || categories === null)) {
        const byCategory = organizeContent(contents, events, term);
        setItemsByCategory(byCategory);
        setCategories(Object.keys(byCategory));
    }

    if (!contents || !events || itemsByCategory === null || categories === null) {
        return <PageLoader align="flex-start" top={80} height={400} />;
    }

    searchByImmediateQuery();

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                    minHeight: 300,
                    boxShadow: "0 5px 20px 0 rgba(22,29,37,0.1)"
                }}>
                <div
                    style={{
                        fontSize: 46,
                        textAlign: "center",
                        color: theme.textColor,
                        fontWeight: 900,
                        marginBottom: 30
                    }}>
                    {sectionDesignInfo.search.text}
                </div>
                <EXCenterSearchInput ref={ref => (searchInputRef = ref)} onSearch={onSearch} value={term} />
                <EXCenterSectionCategories
                    categories={categories}
                    onCategorySelect={onCategorySelect}
                    style={{boxShadow: "none"}}
                    attachRef={ref => (categoriesRef = ref)}
                    maxLength={5}
                />
            </div>
            <EXCenterSectionItems itemsByCategory={itemsByCategory} />
        </div>
    );
};
