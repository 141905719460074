import React from "react";
import {Rating_1, Rating_2, Rating_3, Rating_4, Rating_5} from "./icons";

export const ReviewRating = ({value, onChange}) => {
    return (
        <div
            style={{
                display: "flex",
                marginTop: 5
            }}>
            <Rating_1 onSelect={onChange} activeIndex={value} />
            <Rating_2 onSelect={onChange} activeIndex={value} />
            <Rating_3 onSelect={onChange} activeIndex={value} />
            <Rating_4 onSelect={onChange} activeIndex={value} />
            <Rating_5 onSelect={onChange} activeIndex={value} />
        </div>
    );
};
