import React, {useMemo} from "react";
import {ConfigProvider, Tag} from "antd";
import {OrderStatusView} from "../../../event/OrderStatusView/OrderStatusView.jsx";
import {generateProviderOrderViewStatusContent} from "../../../event/OrderInformationCard/utils.jsx";
import "./order-collapse.mobile.css";
import {StringBuilder} from "../../../AppUtils.js";
import {useTimer} from "../../../utils/hooks.jsx";
import {TableDateCell} from "../../../components/Table/TableDateCell.jsx";
import {OrderCollapseInfoRow} from "./OrderCollapse.mobile.jsx";

export const OrderCollapseHeader = ({order}) => {
    const option = useMemo(() => generateProviderOrderViewStatusContent(order).pop(), [order]);

    const endResponseTime = useMemo(() => option.endTime ?? null, [option]);

    const {remainingTime} = useTimer(endResponseTime);

    return (
        <ConfigProvider direction="rtl">
            <OrderStatusView
                className="order-collapse-container"
                backgroundColor="white"
                avatarBackgroundColor={option.titleColor}
                avatar={
                    <>
                        <div className="order-collapse-avatar-title">{remainingTime}</div>
                        <div className="order-collapse-avatar-title">{option.title}</div>
                    </>
                }
                description={[
                    <>
                        <div>
                            <Tag className="order-collapse-tag proposal-id">{order?.proposalId}</Tag>

                            {order?.lastEditedBy && <Tag className="order-collapse-tag edited-order">הזמנה נערכה</Tag>}
                        </div>

                        <span className="order-collapse-title">
                            {new StringBuilder(order?.productName, null)
                                .append("|")
                                .append(order?.offeredPrice?.toLocaleString())
                                .append("₪")
                                .toString()}
                        </span>

                        <OrderCollapseInfoRow
                            title="מועד אספקה:"
                            value={<TableDateCell date={order?.dtstart} endDate={order.until ?? order?.dtstart} />}
                        />
                    </>
                ]}
            />
        </ConfigProvider>
    );
};
