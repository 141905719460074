import React, {createContext, useCallback, useMemo, useState} from "react";
import {NOOP} from "../../utils/NOOP.jsx";
import {sumBy} from "lodash";

export const CartModularProductContext = createContext({
    selectedSubProducts: [],
    totalPrice: null,
    onPropertyChange: NOOP,
    setSubProducts: NOOP,
    originalTotalPrice: null,
    resetCart: NOOP,
    subProducts: []
});

export const CartModularProductContextProvider = ({children}) => {
    const [subProducts, setSubProducts] = useState([]);

    const selectedSubProducts = useMemo(() => subProducts.filter(products => products.amount > 0), [subProducts]);

    const totalPrice = useMemo(() => {
        const sum = sumBy(
            selectedSubProducts,
            selectedSubProduct => Number(selectedSubProduct.price) * Number(selectedSubProduct.amount ?? 1)
        );
        return Number(sum ?? 0);
    }, [selectedSubProducts]);

    const originalTotalPrice = useMemo(() => {
        const defaultProducts = (subProducts ?? []).filter(subProduct => subProduct?.defaultAmount > 0);
        const sum = sumBy(
            defaultProducts,
            subProduct => Number(subProduct.price) * Number(subProduct?.defaultAmount ?? 1)
        );
        return Number(sum ?? 0);
    }, [subProducts]);

    const onPropertyChange = useCallback(
        (subProductId, field, amount) => {
            setSubProducts((currentSubProducts = []) => {
                const subProductChanged = currentSubProducts.find(subProduct => subProduct.id === subProductId);
                subProductChanged.amount = amount;
                return [...currentSubProducts];
            });
        },
        [setSubProducts]
    );

    const resetCart = useCallback(() => {
        setSubProducts(currentSubProducts => {
            subProducts.forEach(subProducts => {
                subProducts.amount = subProducts?.defaultAmount ?? 0;
            });
            return [...currentSubProducts];
        });
    }, [subProducts]);

    return (
        <CartModularProductContext.Provider
            value={{
                selectedSubProducts,
                totalPrice,
                onPropertyChange,
                subProducts,
                setSubProducts,
                originalTotalPrice,
                resetCart
            }}>
            {children}
        </CartModularProductContext.Provider>
    );
};
