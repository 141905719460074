import {Modal} from "antd";
import React, {useEffect, useState} from "react";
// import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {StyledButton, PageTitleHeader} from "../../components";

const GRID_SIZE = 5;

export const AdminSubCategoriesController = ({visible, service, onClose, onSave}) => {
    const [subs, setSubs] = useState([]);

    useEffect(() => {
        if (service) {
            setSubs(service.subCategories || []);
        }
    }, [service]);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: GRID_SIZE * 2,
        margin: `0 0 ${GRID_SIZE}px 0`,
        background: isDragging ? "var(--primary-color)" : "#FFFFFF",
        ...draggableStyle
    });

    const getListStyle = () => ({
        background: "var(--secondary-color)",
        padding: GRID_SIZE,
        width: 250
    });

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }
        const items = reorder(subs, result.source.index, result.destination.index);
        setSubs(items);
    };

    return (
        <Modal
            centered={true}
            width={600}
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            maskClosable={false}
            footer={null}
            title={null}
            closable={true}>
            <div style={{display: "flex", width: "100%", flexDirection: "column", alignItems: "center"}}>
                <PageTitleHeader showBack={false}>Sub-Category Controller</PageTitleHeader>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}>
                                {subs.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}>
                                                {item.name}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <StyledButton
                    class
                    style={{
                        backgroundColor: "white",
                        color: "var(--primary-color)",
                        marginTop: "20px",
                        width: "150px",
                        borderRadius: "5px"
                    }}
                    onClick={() => onSave(subs)}>
                    Save
                </StyledButton>
            </div>
        </Modal>
    );
};
