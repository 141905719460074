import React, {useEffect, useState, useContext, useReducer} from "react";
import {Button, Form, Input, message, Spin} from "antd";
import {EventInfoTitle} from "../event/EventInfoTitle";
import {EventBus} from "../bus/EventBus";
import {HttpClient} from "../http/HttpClient";
import {AppContext} from "../AppContext";
import {FormLabel, FormTextArea, SaveButton} from "../components/form";
import moment from "moment";
import {StyledButton} from "../components/StyledButton";
import {ContactPhone} from "../support/ContactPhone";
import {withRouter} from "react-router";
import isMobileDevice from "is-mobile";
import {ChangesNotSavedModal} from "../components/ChangesNotSavedModal";
import {FutureEventsModal} from "../event/FutureEventsModal";
import {eventByDate} from "../event/EventByDate";
import {PageSubTitleLabel} from "../components/PageTitle";
import {StartEndTimePicker} from "../components/StartEndTimePicker";
import {PageLoader} from "../components/PageLoader";

const ProviderEventInfo = ({match, history, eventInfo, onViewAppointments, onUpdate}) => {
    const {me} = useContext(AppContext);
    const [saving, setSaving] = useState(false);
    const [changesMade, setChangesMade] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const [showFutureEventsModal, setShowFutureEventsModal] = useState(false);
    const {start, end, eventId} = match.params;
    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        event: null,
        companyPhone: null,
        today: null,
        minTime: null,
        maxTime: null
    });

    const {event, companyPhone, today, minTime, maxTime} = state;

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                let event = eventInfo || (await HttpClient.get(`/api/events/${eventId}`));
                const {phone} = await HttpClient.get(`/api/companies/${event.companyId}`);

                const today = moment(start, "YYYY-M-D").format("YYYY-MM-DD");

                event = eventByDate(event, start);

                setState({
                    event,
                    companyPhone: phone,
                    today,
                    minTime: event.minTime,
                    maxTime: event.maxTime
                });
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we couldn't complete your request :("}},
                    e.message
                );
            }
        });
    }, []);

    const saveChanges = async (values, applyType = "only") => {
        const {minTime, maxTime, notes, breakSlot} = values;
        setSaving(true);
        try {
            if ([minTime, maxTime, notes, breakSlot].some(val => typeof val !== "undefined")) {
                const updatedEvent = await HttpClient.post(
                    `/api/events/${event.eventId}?apply=${applyType}&date=${today}`,
                    {
                        minTime: minTime.format("HH:mm"),
                        maxTime: maxTime.format("HH:mm"),
                        notes,
                        address: event.address,
                        breakSlots:
                            breakSlot && breakSlot.start && breakSlot.end
                                ? [
                                      {
                                          start: breakSlot.start.format("HH:mm"),
                                          end: breakSlot.end.format("HH:mm"),
                                          tzid: moment.tz.guess()
                                      }
                                  ]
                                : null
                    }
                );
                onUpdate(updatedEvent, applyType);
            }
            message.success("Changes saved.");
        } catch (e) {
            if (e.statusCode === 400) {
                EventBus.triggerError(
                    "server-error",
                    {
                        content: {
                            title: "Ohh...",
                            hideSubTitle: true,
                            description: e.message,
                            hideSteps: true
                        },
                        cta: {
                            hide: true
                        }
                    },
                    e.message
                );
            } else {
                EventBus.triggerError(
                    "server-error",
                    {
                        content: {
                            description: "Unfortunately we couldn't update your event :("
                        }
                    },
                    e.message
                );
            }
        }
        setSaving(false);
        setChangesMade(false);
    };

    const askToApplyOnFutureEvents = () => {
        setShowFutureEventsModal(true);
    };

    const closeFutureEventsModal = () => {
        setShowFutureEventsModal(false);
    };

    const submitForm = async values => {
        if (changesMade) {
            askToApplyOnFutureEvents();
        } else {
            await saveChanges(values);
        }
    };

    const breakSlot = breakSlots => {
        if (Array.isArray(event.breakSlots) && breakSlots.length > 0) {
            const breakSlot = breakSlots[0];
            return {
                breakSlot: {
                    start: moment(breakSlot.start, "HH:mm"),
                    end: moment(breakSlot.end, "HH:mm")
                }
            };
        } else {
            return {
                breakSlot: void 0
            };
        }
    };

    if (!event || !today) {
        return <PageLoader align="flex-start" top={50} bottom={50} />;
    }

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                maxWidth: 800
            }}>
            <ChangesNotSavedModal
                history={history}
                changesMade={changesMade}
                onSave={async () => await submitForm(formValues)}
            />
            <EventInfoTitle
                me={me}
                event={event}
                currentStart={start}
                currentEnd={end}
                titleStyle={{marginTop: 0}}
                subTitleStyle={{marginBottom: 5}}
                showBack={!isMobileDevice()}
            />
            <FutureEventsModal
                visible={showFutureEventsModal}
                onClose={() => closeFutureEventsModal()}
                loading={saving}
                onSave={async ({applyType}) => {
                    await saveChanges(formValues, applyType);
                    closeFutureEventsModal();
                }}
            />
            <Form
                className="wb-provider-evt-info"
                onFinish={async values => {
                    if (values.timeRange) {
                        values.minTime = values.timeRange.start;
                        values.maxTime = values.timeRange.end;
                    }
                    setFormValues(values);
                    await submitForm(values);
                }}
                initialValues={{
                    timeRange: {
                        start: moment(minTime || "09:00", "HH:mm"),
                        end: moment(maxTime || "18:00", "HH:mm")
                    },
                    notes: event.notes,
                    ...breakSlot(event.breakSlots)
                }}
                onValuesChange={(changedValues, allValues) => {
                    if (allValues.timeRange) {
                        allValues.minTime = allValues.timeRange.start;
                        allValues.maxTime = allValues.timeRange.end;
                    }
                    setFormValues(allValues);
                    setChangesMade(true);
                }}>
                <Form.Item style={{marginBottom: 10}}>
                    <FormLabel style={{fontSize: 16, fontWeight: 400, marginTop: 10}}>{event.address}</FormLabel>
                </Form.Item>
                <PageSubTitleLabel style={{justifyContent: "flex-start"}}>Event time:</PageSubTitleLabel>
                <Form.Item
                    validateFirst={true}
                    name="timeRange"
                    rules={[
                        {
                            validator: (_, {start, end}) => {
                                if (!start || !end) {
                                    return Promise.reject("Please select event time.");
                                } else if (end.diff(start, "minutes") < 0) {
                                    return Promise.reject("Start time is bigger than finish time.");
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        }
                    ]}>
                    <StartEndTimePicker canControlEndTime={true} />
                </Form.Item>
                <PageSubTitleLabel style={{justifyContent: "flex-start"}}>Break time:</PageSubTitleLabel>
                <Form.Item
                    name="breakSlot"
                    rules={[
                        {
                            validator: (_, breakSlot) => {
                                const {start, end} = breakSlot || {start: void 0, end: void 0};
                                if (end && start && end.diff(start, "minutes") < 0) {
                                    return Promise.reject("Start time is bigger than finish time.");
                                } else if (start && !end) {
                                    return Promise.reject("Please select finish time.");
                                } else if (end && !start) {
                                    return Promise.reject("Please select start time.");
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        }
                    ]}>
                    <StartEndTimePicker canControlEndTime={true} />
                </Form.Item>
                <Form.Item name="notes">
                    <FormTextArea placeholder="Write your notes here" autoSize={{minRows: 4, maxRows: 4}} />
                </Form.Item>
                <Form.Item>
                    <SaveButton loading={saving} style={{width: "100%", marginLeft: 0}} text="Save changes" />
                </Form.Item>
                <Form.Item>
                    <StyledButton
                        onClick={() => onViewAppointments(eventId, start, end)}
                        style={{borderRadius: 25, backgroundColor: "#13C296", color: "white", width: "100%"}}>
                        View appointments
                    </StyledButton>
                </Form.Item>
                <Form.Item>
                    <Button
                        onClick={() => ContactPhone.openWhatsApp()}
                        style={{width: "100%", fontSize: 16}}
                        type="link">
                        Contact {event.companyName}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default withRouter(ProviderEventInfo);
