import {useContext, useState} from "react";
import React from "react";
import {EXCenterEventCard} from "./EXCenterEventCard";
import {take, shuffle} from "lodash";
import {toEventsThisWeek} from "../utils/EventUtil";
import {EXCenterSectionSeeMoreButton} from "../infos/EXCenterSectionSeeMoreButton";
import {EXCenterSectionDesignMenu} from "../control/EXCenterSectionDesignMenu";
import {EXCenterContext} from "../EXCenterContext";
import {AppContext} from "../../AppContext";
import {EXCenterProperties} from "../utils/EXCenterProperties";

const EVENTS_TO_SHOW = 3;

export const EXCenterEventsSection = () => {
    const sectionId = "events";
    let {events, designInfoPerSection, theme} = useContext(EXCenterContext);
    const {me} = useContext(AppContext);
    const [eventsThisWeek, setEventsThisWeek] = useState(null);

    if (!Array.isArray(events) || events.length === 0) {
        return null;
    }

    if (eventsThisWeek === null) {
        setEventsThisWeek(shuffle(toEventsThisWeek(events)));
    } else if (Array.isArray(eventsThisWeek) && eventsThisWeek.length === 0) {
        return null;
    }

    const sectionDesignInfo = designInfoPerSection[sectionId];

    if (me.type === "employee" && !sectionDesignInfo.display) {
        return null;
    }

    return (
        <div
            className="wb-ss-top wb-center-section"
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: EXCenterProperties.Section.Top
            }}>
            <EXCenterSectionDesignMenu
                editItems={{
                    title: true,
                    buttonNoLink: true,
                    coverPhoto: true,
                    description: true
                }}
                coverSize={{width: 1440, height: 420}}
                sectionId={sectionId}
            />
            <div
                className="wb-ss-no-padding wb-center-section-padding"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    opacity: EXCenterProperties.Section.opacityByDisplay(sectionDesignInfo)
                }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        flexWrap: "wrap",
                        maxWidth: 1200
                    }}>
                    <div
                        className="wb-ss-no-padding"
                        style={{
                            paddingLeft: 20,
                            paddingRight: 20,
                            marginBottom: 25,
                            width: "100%",
                            display: "flex",
                            justifyContent: "center"
                        }}>
                        <span
                            className="wb-ss-section-title wb-ss-full-width"
                            style={{
                                fontSize: 34,
                                fontWeight: 900,
                                color: theme.textColor
                            }}>
                            {sectionDesignInfo.title}
                        </span>
                    </div>
                    <div style={{display: "flex", justifyContent: "center", flexWrap: "wrap", width: "100%"}}>
                        {take(eventsThisWeek, EVENTS_TO_SHOW).map(({event, date}) => (
                            <EXCenterEventCard key={event.eventId} eventInfo={event} date={date} />
                        ))}
                    </div>
                    <EXCenterSectionSeeMoreButton sectionId={sectionId} designInfo={sectionDesignInfo} />
                </div>
            </div>
        </div>
    );
};
