import {parse as qsParse} from "query-string";
import {Cloudinary} from "cloudinary-core";

const DEFAULT_CLOUD_NAME = "well-b-prod";
const CDN_URL = "://res.cloudinary.com/";

const EMAIL_SERVER_URL = "https://img.well-b.email/";

const extractInnerUrl = src => {
    const a = document.createElement("a");
    a.href = src;
    return decodeURIComponent(qsParse(a.search).url);
};

const extractCloudName = src => {
    const matches = src.match(/:\/\/res\.cloudinary\.com\/(.+?)\//);
    if (matches && matches.length > 1) {
        return matches[1];
    }

    return DEFAULT_CLOUD_NAME;
};

export const extractPublicIdAndCloudName = src => {
    if (src && src.indexOf(CDN_URL) >= 0) {
        const parts = src.split("/");
        const publicId = parts[parts.length - 1].split(".")[0];
        const cloudName = extractCloudName(src);
        return {publicId, cloudName};
    } else if (isEmailServerWithUrl(src)) {
        return extractPublicIdAndCloudName(extractInnerUrl(src));
    }

    return {publicId: null};
};

const isEmailServerWithUrl = src => src && src.indexOf(EMAIL_SERVER_URL) === 0 && src.indexOf("url=") >= 0;

const extractProxyUrl = src => {
    return isEmailServerWithUrl(src) ? extractInnerUrl(src) : null;
};

export const createUrlWithoutTransformations = src => {
    const {publicId, cloudName} = extractPublicIdAndCloudName(src);

    if (publicId && cloudName) {
        const cloudinary = new Cloudinary({cloud_name: cloudName});
        const cleanUrl = cloudinary.url(publicId);

        return cleanUrl;
    }

    return src;
};

export const createUrlWithTransformations = (src, width, height, transformations) => {
    const {publicId, cloudName} = extractPublicIdAndCloudName(src);
    if (publicId && cloudName) {
        const cloudinary = new Cloudinary({cloud_name: cloudName});
        const transformedUrl = cloudinary.url(publicId, {
            width,
            height,
            crop: "fill",
            gravity: "center",
            fetch_format: "auto",
            secure: true,
            ...transformations
        });

        return transformedUrl;
    } else if (isEmailServerWithUrl(src)) {
        return extractProxyUrl(src);
    }

    return src;
};

export const createAutoQualityVideoUrl = src => {
    const {publicId, cloudName} = extractPublicIdAndCloudName(src);
    if (publicId && cloudName) {
        const cloudinary = new Cloudinary({cloud_name: cloudName});
        return cloudinary.video_url(publicId, {
            quality: "auto",
            secure: true,
            fetch_format: "auto",
            resource_type: "video"
        });
    } else if (isEmailServerWithUrl(src)) {
        return extractProxyUrl(src);
    }

    return src;
};
