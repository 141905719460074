import React, {useCallback, useEffect, useState} from "react";
import {ConfigProvider, List, message, Tabs} from "antd";
import copyToClipboard from "copy-to-clipboard";
import PageLayout from "./AdminPageLayout";
import {AdminEditCrossPageModal} from "./AdminEditCrossPageModal";
import {AdminSiteContext} from "./AdminSiteContext";
import {AdminBannersEdit} from "../components";
import {ProductsProvider} from "../Context/ProductsProvider";
import {ConfirmationRequiredWrapper, SquareButton} from "../../components";
import {useRequest} from "../../utils/hooks";
import {HttpClient} from "../../http/HttpClient";
import "./admin-site-settings.css";

export const AdminSiteSettings = ({history}) => {
    const [crossPageEdited, setCrossPageEdited] = useState(null);
    const [banners] = useRequest("/api/site/landingPageBanners");
    const [crossPages, , , fetchCrossPages] = useRequest("/api/site/crossPages");

    const [innerCrossPage, setInnerCrossPage] = useState([]);

    useEffect(() => {
        if (crossPages) {
            setInnerCrossPage(crossPages);
        }
    }, [crossPages]);

    const onFinishEditingCrossPage = useCallback((newPage, index) => {
        setCrossPageEdited(null);
        setInnerCrossPage(prevPages => {
            const newPages = [...prevPages];
            newPages.splice(index === -1 ? 0 : index, index === -1 ? 0 : 1, newPage);
            return newPages;
        });
    }, []);

    const onRemoveCrossPage = useCallback(indexToRemove => {
        setInnerCrossPage(prevPages => {
            const newPages = [...prevPages];
            newPages.splice(indexToRemove, 1);
            return newPages;
        });
    }, []);

    const onSaveBanners = useCallback(async newBanners => {
        const {error} = await HttpClient.safePost("/admin/api/siteSettings/updateLandingPageBanners", newBanners);

        if (error) {
            message.error("קרתה שגיאה בשמירת המידע");
            return;
        }

        message.success("באנרים עודכנו בהצלחה");
    }, []);

    const onSaveCrossPages = useCallback(async () => {
        const {error} = await HttpClient.safePost("/admin/api/siteSettings/updateCrossPages", innerCrossPage);

        if (error) {
            message.error("קרתה שגיאה בשמירת המידע");
            return;
        }

        message.success("עמודים נשמרו בהצלחה עודכנו בהצלחה");
        fetchCrossPages();
    }, [innerCrossPage]);

    const copyCrossPageLink = useCallback(crossPageId => {
        if (!crossPageId) {
            message.error("בשביל להעתיק קישור יש לשמור קודם");
            return;
        }

        copyToClipboard(`${window.location.origin}/dashboard/services/crossPage/${crossPageId}`);
        message.success("קישור הועתק בהצלחה");
    }, []);

    const [subCategoryCarouselModal, setSubCategoryCarouselModal] = useState(false);
    const [addOrEditSubCategoryCarouselModal, setAddOrEditSubCategoryCarouselModal] = useState(false);
    const [selectedCarousel, setSelectedCarousel] = useState([]);
    const [selectedCarouselItem, setSelectedCarouselItem] = useState({});
    const [isEditCarouselItem, setIsEditCarouselItem] = useState(false);
    const [carousels, setCarousels] = useState([]);

    return (
        <ProductsProvider>
            <AdminSiteContext.Provider
                value={{
                    subCategoryCarouselModal,
                    setSubCategoryCarouselModal,
                    addOrEditSubCategoryCarouselModal,
                    setAddOrEditSubCategoryCarouselModal,
                    selectedCarousel,
                    setSelectedCarousel,
                    selectedCarouselItem,
                    setSelectedCarouselItem,
                    isEditCarouselItem,
                    setIsEditCarouselItem,
                    carousels,
                    setCarousels
                }}>
                <div className="admin-site-settings">
                    <AdminEditCrossPageModal
                        crossPage={crossPageEdited?.crossPage}
                        onSave={newPage => onFinishEditingCrossPage(newPage, crossPageEdited.index)}
                        onClose={() => setCrossPageEdited(null)}
                        visible={!!crossPageEdited}
                    />
                    <ConfigProvider direction="rtl">
                        <Tabs
                            animated={false}
                            className="admin-site-settings-tabs"
                            defaultActiveKey="3"
                            items={[
                                {
                                    label: "באנרים עמוד ראשי",
                                    key: "1",
                                    children: (
                                        <>
                                            <div className="admin-site-settings-landing-page-banners-title">
                                                <span>באנרים עמוד ראשי</span>
                                            </div>
                                            <AdminBannersEdit
                                                banners={banners}
                                                defaultNewBanner={{buttonText: "לכל הבחירות"}}
                                                onSave={onSaveBanners}
                                            />
                                        </>
                                    )
                                },
                                {
                                    label: "עמודי אירועי צריכה",
                                    key: "2",
                                    children: (
                                        <div>
                                            <div className="admin-site-settings-landing-page-banners-title">
                                                <span>עמודי אירועי צריכה</span>
                                            </div>
                                            <div className="admin-banners-edit-landing-page-banners-buttons">
                                                <SquareButton
                                                    className="admin-banners-edit-landing-page-banners-save-button"
                                                    onClick={() => onSaveCrossPages()}>
                                                    שמירה
                                                </SquareButton>
                                                <SquareButton
                                                    className="admin-banners-edit-landing-page-banners-add-button"
                                                    onClick={() =>
                                                        setCrossPageEdited({
                                                            crossPage: {},
                                                            index: -1
                                                        })
                                                    }>
                                                    הוסף עמוד
                                                </SquareButton>
                                            </div>
                                            <List
                                                className="admin-site-settings-cross-pages"
                                                dataSource={innerCrossPage}
                                                renderItem={(crossPage, index) => (
                                                    <List.Item
                                                        key={crossPage.id}
                                                        className="admin-site-settings-cross-page-line"
                                                        actions={[
                                                            <span onClick={() => copyCrossPageLink(crossPage.id)}>
                                                                העתקת קישור
                                                            </span>,
                                                            <span
                                                                onClick={() => setCrossPageEdited({crossPage, index})}>
                                                                עריכה
                                                            </span>,
                                                            <ConfirmationRequiredWrapper
                                                                onYes={() => onRemoveCrossPage(index)}
                                                                yesText="כן כן למחוק"
                                                                noText="אופס, אל תמחק">
                                                                <span className="admin-site-settings-cross-page-delete-label">
                                                                    מחיקה
                                                                </span>
                                                            </ConfirmationRequiredWrapper>
                                                        ]}>
                                                        {crossPage.name}
                                                    </List.Item>
                                                )}></List>
                                        </div>
                                    )
                                },
                                {
                                    label: "הגדרות אתר",
                                    key: "3",
                                    children: <PageLayout history={history} />
                                }
                            ]}
                        />
                    </ConfigProvider>
                </div>
            </AdminSiteContext.Provider>
        </ProductsProvider>
    );
};
