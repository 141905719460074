import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {debounce} from "lodash";
import {Collapse, ConfigProvider} from "antd";
import classnames from "classnames";
import {ProductDetailsPanelKeys} from "./consts";
import {GeneralDescriptionPanel} from "./GeneralDescriptionPanel";
import {PackageInfoPanel} from "./PackageInfoPanel";
import {AdditionalInfoPanel} from "./AdditionalInfoPanel";
import {ProductTagsPanel} from "./ProductTagsPanel";
import {PopularQuestionsPanel} from "./PopularQuestionsPanel";
import {getDefaultPriceTypeArray, getDefaultTypeToShow, typeToHebrewName} from "../MarketplaceUtils";
import {PreviouslyOrderedCompanies} from "../PreviouslyOrderedCompanies";
import {PricingCalculatorComponent} from "../PricingCalculator";
import {MarketplaceContext} from "../marketplaceContext";
import {BesideCarouselArrow, MarketplaceSeparatorLine} from "../MarketplaceHelper";
import {getDeliveryArea, getDeliveryPriceForBooking} from "../MarketplaceBooking/utils";
import {BOOKING_ERROR_TO_PANEL, BOOKING_TO_PANEL_COLORS} from "../MarketplaceBooking/consts";
import {PriceTypes} from "../../admin/upload/PriceSelectorHelper";
import {ProgressiveImageLoader, WBCarousel} from "../../components";
import cover_placeholder from "../../images/cover_placeholder.jpg";
import {NOOP} from "../../utils/NOOP";
import {useRequest} from "../../utils/hooks";
import {GoogleAnalytics} from "../../GoogleAnalytics";
import {AppContext} from "../../AppContext";
import {ActivityLocation, ActivityLocationTypes} from "../../consts.js";
import {isCompanySubAdmin} from "../../center/EXCenterHelper";
import "./product-details-page.css";
import {ProviderAboutSection} from "./ProviderAboutSection";
import {TopBarSection} from "./TopBarSection";
import {CartModularProductContext} from "../PricingCalculator/CartModularProductContext.jsx";

export const ProductDetailsPage = ({onBook = NOOP, anyQuestionsClicked = NOOP, log = NOOP}) => {
    const {me, company} = useContext(AppContext);
    const {
        product,
        providerDetails,
        providerId,
        productId,
        serviceId,
        setParticipants,
        setHighlightOrderPanel = NOOP
    } = useContext(MarketplaceContext);
    const {subProducts} = useContext(CartModularProductContext);

    const [productSearchTags] = useRequest(
        `/api/searchTags/getProductSearchTags/${productId}`,
        "GET",
        null,
        [productId],
        !!productId
    );

    const [fromPrice, setFromPrice] = useState(null);
    const [isDeliverableToOffice, setIsDeliverableToOffice] = useState(null);
    const [activeKey, setActiveKey] = useState([]);

    const previouslyOrderedCompaniesProviderIds = useMemo(() => [providerId], [providerId]);

    const calculatePrice = useMemo(
        () =>
            product?.pricingTable?.byModular?.subProducts?.reduce((acc, subProduct) => {
                const foundProviderSubProduct = providerDetails.subProducts.find(({id}) => id === subProduct?.id);
                if (foundProviderSubProduct && foundProviderSubProduct.isActive && subProduct?.isActive) {
                    return acc + foundProviderSubProduct.price * (subProduct?.defaultAmount ?? 0);
                }
                return acc;
            }, 0),
        [product?.pricingTable?.byModular?.subProducts, providerDetails]
    );

    const onBookRedirectCollapse = useCallback(redirectPanelId => {
        setHighlightOrderPanel({
            id: BOOKING_ERROR_TO_PANEL[redirectPanelId],
            color: BOOKING_TO_PANEL_COLORS.REDIRECT
        });
        onBook();
    }, []);

    const carouselProps = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        nextArrow: <BesideCarouselArrow direction={"next"} />,
        prevArrow: <BesideCarouselArrow direction={"prev"} />,
        accessibility: true,
        arrows: true,
        className: "wb-carousel"
    };

    const ProductPriceHeader = useCallback(
        ({companyOrdersDiscount}) => {
            const priceBeforeDiscount = product?.chosenPriceType === PriceTypes.ByModular ? calculatePrice : fromPrice;
            const priceAfterDiscount = companyOrdersDiscount
                ? priceBeforeDiscount - Math.floor(priceBeforeDiscount * (companyOrdersDiscount / 100))
                : priceBeforeDiscount;
            const shouldShowDiscountedPrice = companyOrdersDiscount && priceAfterDiscount !== priceBeforeDiscount;
            const title = product?.chosenPriceType === PriceTypes.ByModular ? "חבילה מומלצת" : "החל מ-";

            return (
                <>
                    <div className="marketplace-category-item-details-price-container">
                        <span className="marketplace-category-item-details-price-title">
                            <span>{title}</span>
                            <span className={classnames({"line-through": shouldShowDiscountedPrice})}>
                                {priceBeforeDiscount?.toLocaleString(undefined, {maximumFractionDigits: 2})}₪
                            </span>
                        </span>

                        {shouldShowDiscountedPrice ? (
                            <span
                                className={classnames(
                                    "marketplace-category-item-details-price-title-discount",
                                    "marketplace-category-item-details-price-title",
                                    {"responsive-font-size": companyOrdersDiscount && priceAfterDiscount > 999}
                                )}>
                                {priceAfterDiscount?.toLocaleString(undefined, {maximumFractionDigits: 2})}₪
                            </span>
                        ) : null}

                        <span style={{color: "#8398A6", fontSize: "12px", margin: "9px 5px 0 0"}}>
                            / {typeToHebrewName(getDefaultTypeToShow(product))} (כולל מע״מ)
                        </span>
                    </div>
                </>
            );
        },
        [calculatePrice, fromPrice, product]
    );

    const onParticipantsChange = debounce(participants => {
        if (participants) {
            setParticipants(participants);
            log("Participants Number Set", {participantsNumber: participants});
        }
    }, 200);

    useEffect(() => {
        if (product) {
            const defaultPriceTypeArray = getDefaultPriceTypeArray(product);
            let fromPrice = null;
            defaultPriceTypeArray?.forEach(row => {
                const rowPrice = parseFloat(row.price ?? row.redeemableValue);
                if (!fromPrice || fromPrice > rowPrice) {
                    fromPrice = rowPrice;
                }
            });
            setFromPrice(fromPrice);
            onParticipantsChange(null);
            GoogleAnalytics.ecommerceEvent("view_item", product, serviceId);
        }
        window.scrollTo(0, 0);
    }, [product]);

    useEffect(() => {
        if (!product || !providerDetails || !me) return;
        const checkDeliverable = async () => {
            const isOfficeActivity = product.location?.includes(ActivityLocationTypes.office);
            if (isOfficeActivity) {
                const officeAddress = {
                    address: isCompanySubAdmin(me) ? me?.address : company?.address,
                    addressId: isCompanySubAdmin(me) ? me?.addressId : company?.addressId
                };

                const deliveryArea = await getDeliveryArea(officeAddress, product, providerDetails, log);

                if (product) {
                    product.deliveryPrice = product.deliveryPrice ?? providerDetails.deliveryPrice;
                }

                const deliveryPrice = getDeliveryPriceForBooking(
                    officeAddress.address,
                    providerDetails,
                    product,
                    {},
                    deliveryArea,
                    log
                );

                setIsDeliverableToOffice(Boolean(deliveryPrice?.price));
            }
        };

        checkDeliverable();
    }, [product, providerDetails, me, log, company, isCompanySubAdmin, setIsDeliverableToOffice]);

    const locations = useMemo(() => {
        const officeText = ActivityLocation[ActivityLocationTypes.office];
        if (!product || !product.location) return [];

        return product.location
            .map(location => ActivityLocation[location])
            .filter(loc => loc !== officeText || isDeliverableToOffice);
    }, [product, isDeliverableToOffice]);

    useEffect(() => {
        setActiveKey(Object.values(ProductDetailsPanelKeys));
    }, []);

    const onCartBookClick = useCallback(() => {
        log("Go To Booking Clicked");
        setHighlightOrderPanel(null);
        onBook();
    }, [log, onBook, setHighlightOrderPanel, subProducts]);

    return (
        <div className="product-details-page">
            <ConfigProvider direction="ltr">
                <TopBarSection
                    productName={product?.productName}
                    locations={locations}
                    fromPrice={fromPrice}
                    calculatePrice={calculatePrice}
                    anyQuestionsClicked={anyQuestionsClicked}
                    ProductPriceHeader={ProductPriceHeader}
                />

                <div className="product-details-page-container">
                    <div className="product-details-page-wrapper">
                        <div>
                            <div className="product-details-page-pricing-table-container">
                                <PricingCalculatorComponent
                                    product={product}
                                    onBook={onCartBookClick}
                                    onParticipantsChange={onParticipantsChange}
                                    modularCalculatedPrice={calculatePrice}
                                    PriceCalculatorHeader={
                                        product?.chosenPriceType === PriceTypes.ByModular
                                            ? "הזמנה שלי"
                                            : ProductPriceHeader({
                                                  companyOrdersDiscount: company?.ordersDiscount,
                                                  priceBeforeDiscount: fromPrice || calculatePrice,
                                                  chosenPriceType: product?.chosenPriceType,
                                                  product
                                              })
                                    }
                                    showModularImages={providerDetails?.showProviderSubProductsImages}
                                    log={log}
                                />
                                <div className="product-details-page-pricing-table-disclaimer">
                                    ההזמנה והתמחור כפופים לאישור סופי של הספק*
                                </div>
                            </div>
                        </div>

                        <div className="product-details-page-content">
                            <WBCarousel key={productId} carouselProps={carouselProps}>
                                {product.portfolioImages.map(image => (
                                    <div
                                        id={`portfolioImages_${image?.imageId}`}
                                        key={image?.imageId}
                                        className="marketplace-category-item-details-item-portfolio-image">
                                        <ProgressiveImageLoader
                                            src={image?.imageUrl || cover_placeholder}
                                            alt={image?.imageId}
                                            width={800}
                                            height={440}
                                        />
                                    </div>
                                ))}
                            </WBCarousel>
                            <div style={{userSelect: "none", marginTop: "7px"}}>תמונה להמחשה בלבד*</div>

                            <MarketplaceSeparatorLine opacity={"0.1"} />
                            <>
                                <Collapse
                                    ghost={true}
                                    className="product-details-page-collapse"
                                    expandIconPosition="start"
                                    activeKey={activeKey}
                                    onChange={newKeys => setActiveKey(newKeys)}>
                                    {product?.description ? (
                                        <>
                                            <GeneralDescriptionPanel key={ProductDetailsPanelKeys.GeneralDescription} />
                                            <MarketplaceSeparatorLine opacity={"0.1"} />
                                        </>
                                    ) : null}

                                    <>
                                        <PackageInfoPanel
                                            key={ProductDetailsPanelKeys.PackageInfo}
                                            onBookRedirectCollapse={onBookRedirectCollapse}
                                            additionalElement={<MarketplaceSeparatorLine opacity={"0.1"} />}
                                        />
                                    </>

                                    <>
                                        <AdditionalInfoPanel
                                            key={ProductDetailsPanelKeys.AdditionalInfo}
                                            log={log}
                                            onBookRedirectCollapse={onBookRedirectCollapse}
                                            locations={locations}
                                        />
                                        <MarketplaceSeparatorLine opacity={"0.1"} />
                                    </>

                                    {productSearchTags?.length ? (
                                        <>
                                            <ProductTagsPanel
                                                key={ProductDetailsPanelKeys.ProductTags}
                                                productSearchTags={productSearchTags}
                                            />
                                            <MarketplaceSeparatorLine opacity={"0.1"} />
                                        </>
                                    ) : null}

                                    {product.popularQuestions?.length ? (
                                        <>
                                            <PopularQuestionsPanel key={ProductDetailsPanelKeys.QuestionAndAnswers} />
                                            <MarketplaceSeparatorLine opacity={"0.1"} />
                                        </>
                                    ) : null}
                                </Collapse>
                            </>

                            <div className="product-details-page-section-container">
                                <div className="product-details-page-section-title">
                                    ✅ הארגונים בקהילה שלנו סומכים על הספק
                                </div>
                                <div className={classnames("product-details-page-section-desc")}>
                                    מחפשים ספק שאפשר לסמוך עליו? חברי הקהילה הארגונית שלנו כבר עובדים איתו :)
                                    <PreviouslyOrderedCompanies providerIds={previouslyOrderedCompaniesProviderIds} />
                                </div>

                                <ProviderAboutSection providerDetails={providerDetails} product={product} />
                            </div>
                        </div>
                    </div>
                </div>
            </ConfigProvider>
        </div>
    );
};
