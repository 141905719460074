import {UploadableCoverPhoto} from "../../components/UploadableCoverPhoto";
import React from "react";

export const EventPreviewCover = ({
    event,
    coverStyle,
    showReload,
    coverSize,
    showCoverTag,
    imageUrl,
    onChange,
    reloadButtonInfo,
    query
}) =>
    imageUrl ? (
        <UploadableCoverPhoto
            eventId={event.eventId}
            imageUrl={imageUrl}
            coverStyle={coverStyle}
            width={coverSize ? coverSize.width : void 0}
            height={coverSize ? coverSize.height : void 0}
            quality={100}
            showReload={showReload}
            showCoverTag={showCoverTag}
            onChange={imageUrl => onChange({imageUrl, selected: true})}
            reloadButtonInfo={reloadButtonInfo}
            query={query}
        />
    ) : null;
