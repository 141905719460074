import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {isFinite, set, sumBy} from "lodash";
import moment from "moment";
import {ConfigProvider, Input, message, Modal, Spin, Tooltip} from "antd";
import {SaveCancelButtons} from "../../admin/components/SaveCancelButtons.jsx";
import {OrderForm} from "../../marketplace/OrderForm.jsx";
import {getOrderCommission, getOrderTip, getPriceByAmount} from "../../marketplace/MarketplaceHelper.jsx";
import {
    DEFAULT_DELIVERY_ARRIVING_TIME_RANGE,
    ERROR_LEVEL,
    getDefaultTypeToShow,
    PLACE_ERROR,
    WARN_LEVEL
} from "../../marketplace/MarketplaceUtils.jsx";
import {getMergedDeliveryOptions, getDeliveryPrice} from "../../marketplace/MarketplaceBooking/utils.jsx";
import {MarketplaceBookingContext} from "../../marketplace/MarketplaceBooking/MarketplaceBookingContext.jsx";
import {getOrderPrice} from "../../utils/calculationUtils.jsx";
import {FormInputV2} from "../../components/form/index.jsx";
import {HomeDeliveryUploadMethod} from "../../marketplace/OrderLocationInput/consts.jsx";
import {ActivityLocationTypes, ProviderActivityPossibleLocations} from "../../consts.js";
import {AppContext} from "../../AppContext.jsx";
import {isOrderHasShippingProvider} from "../../event/utils.jsx";
import {HttpClient} from "../../http/HttpClient.jsx";
import {StringBuilder} from "../../AppUtils.js";
import "./edit-order-view.css";
import {NOOP} from "../../utils/NOOP.jsx";

export const EditOrderView = ({
    order,
    onCancel = NOOP,
    onSave,
    priceSectionLines = [],
    extraOrderFormFields,
    formValues,
    setFormValues,
    provider,
    isLoadingProvider,
    adminMode = false,
    disabledFields,
    hideTitle = false
}) => {
    const [hasUserChangedPrice, setHasUserChangedPrice] = useState(false);
    const {me} = useContext(AppContext);

    const content = useMemo(
        () => ({
            provider,
            service: provider?.services.find(service => service.productId === order?.productId)
        }),
        [provider, order?.productId]
    );

    const calculatedModularProductPrice = useMemo(
        () => formValues?.modularProducts?.reduce((acc, subProduct) => acc + subProduct.price * subProduct.amount, 0),
        [formValues?.modularProducts]
    );

    const innerOnCancel = useCallback(() => {
        onCancel();
        setHasUserChangedPrice(false);
    }, [onCancel]);

    const beforeSave = useCallback(() => {
        const homeDeliveryActivityLocationName = ProviderActivityPossibleLocations[ActivityLocationTypes.homeDelivery];
        if (homeDeliveryActivityLocationName === formValues?.chosenAddress && !formValues.homeDeliveryUploadMethod) {
            message.error(`לא נבחרה שיטת ${homeDeliveryActivityLocationName}`);
            return;
        }

        const updatedOrder = getUpdatedOrder();
        onSave(updatedOrder);
    }, [formValues]);

    const getUpdatedOrder = () => {
        const dateFormatted = moment(formValues.date).format("DD/MM/YYYY");
        const timeFormatted = moment(formValues.time).format("HH:mm");
        const dtstart = moment(`${dateFormatted} ${timeFormatted}`, "DD/MM/YYYY HH:mm").toDate().getTime();
        const deliveryOptions = getMergedDeliveryOptions(provider, content.service);
        const deliveryArrivingTimeRange = deliveryOptions?.arrivingTimeRange ?? DEFAULT_DELIVERY_ARRIVING_TIME_RANGE;
        const until = moment(dtstart).add(deliveryArrivingTimeRange, "hours").valueOf();
        const modularProducts = formValues.modularProducts.filter(subProduct => subProduct.amount > 0);

        return {
            eventId: order.eventId,
            invoicePONumber: formValues?.invoicePONumber,
            invoiceComment: formValues?.invoiceComment,
            byweekday: [moment(formValues.date).format("dd").toUpperCase()],
            dtstart,
            until,
            address: formValues.chosenAddress,
            addressId: formValues.addressId,
            sourcePlaceId: formValues.sourcePlaceId,
            sourceAddressName: formValues.sourceAddressName,
            ...priceSectionLines.reduce(
                (acc, {key}) => ({
                    ...acc,
                    [key]: isNaN(Number(formValues[key])) ? formValues[key] : Number(formValues[key])
                }),
                {}
            ),
            participants: formValues.amount,
            productAmount: formValues.amount,
            companyRequests: formValues.requests,
            companyAnswers: formValues.extraInfo,
            companyExtraRequests: formValues.extraRequests,
            upgradeOptions: formValues.upgradeOptions,
            extraContactInfo: formValues.extraContactInfo,
            modularProducts: modularProducts,
            createdAt: formValues.createdAt.valueOf(),
            homeDeliveryUploadMethod: formValues?.homeDeliveryUploadMethod,
            employeesDeliveryDetail:
                formValues?.homeDeliveryUploadMethod === HomeDeliveryUploadMethod.singleEmployee &&
                formValues.singleEmployeeDetails
                    ? [formValues.singleEmployeeDetails]
                    : null,
            employeesExcel:
                formValues?.employeesExcel && formValues?.homeDeliveryUploadMethod === HomeDeliveryUploadMethod.excel
                    ? formValues?.employeesExcel
                    : null,
            deliveryRequests: formValues.deliveryRequests,
            chosenActivityLocation: formValues.chosenActivityLocation
        };
    };

    const upgradeOptionsPrice = useMemo(
        () =>
            sumBy(formValues.upgradeOptions?.options, option =>
                option.type === "byUnit" ? option.price * (option.amount ?? 0) : option.price
            ),
        [formValues.upgradeOptions?.options, formValues.amount]
    );

    const updateFormValuesField = useCallback((fieldName, fieldValue) => {
        setFormValues(currentFormValue => set({...currentFormValue}, fieldName, fieldValue));
    }, []);

    useEffect(() => {
        const modularProducts = content?.service?.pricingTable?.byModular?.subProducts?.reduce((acc, subProduct) => {
            const foundSubProduct = content?.provider?.subProducts?.find(({id}) => id === subProduct?.id);
            const findOrderSubProduct = order.modularProducts?.find(({id}) => id === subProduct?.id);

            if (!subProduct.isActive) return acc;
            if (foundSubProduct) {
                return [
                    ...acc,
                    {
                        ...subProduct,
                        amount: subProduct?.amount ?? 0,
                        ...findOrderSubProduct,
                        ...foundSubProduct
                    }
                ];
            }

            return acc;
        }, []);

        if (modularProducts) {
            order?.modularProducts?.forEach(subProduct => {
                if (!modularProducts.some(({id}) => id === subProduct.id)) {
                    modularProducts.push({...subProduct});
                }
            });
        }

        setFormValues(current => ({
            ...current,
            modularProducts: modularProducts ?? []
        }));
    }, [content.provider, order?.modularProducts]);

    useEffect(() => {
        if (order) {
            const startTime = moment(order.dtstart);

            setFormValues({
                invoicePONumber: order.invoicePONumber,
                invoiceComment: order.invoiceComment,
                date: startTime,
                time: startTime,
                createdAt: moment(order.createdAt),
                address: order.address,
                amount: order.productAmount,
                requests: order.companyRequests,
                extraInfo: order.companyAnswers,
                extraRequests: order.companyExtraRequests,
                offeredPrice: Number(order.offeredPrice),
                deliveryPrice: order.deliveryPrice,
                tip: order.tip,
                tipPercentage: (order.tip / order.offeredPrice) * 100,
                providerDiscount: order.providerDiscount,
                discount: order.discount,
                couponDiscountAmount: order.couponDiscountAmount,
                companyDiscountAmount: order.companyDiscountAmount,
                commission: order.commission,
                deliveryCommission: order.deliveryCommission,
                employeesExcel: order.employeesExcel,
                singleEmployeeDetails: order.employeesDeliveryDetail?.[0],
                upgradeOptions: {...order.upgradeOptions},
                extraContactInfo: order.extraContactInfo,
                priceType: order.productAmountType,
                floor: order.floor,
                shippingProviders: order.shippingProviders,
                productId: order.productId,
                deliveryRequests: order.deliveryRequests,
                sourcePlaceId: order.sourcePlaceId,
                sourceAddressName: order.sourceAddressName,
                chosenActivityLocation: order.chosenActivityLocation,
                homeDeliveryUploadMethod: order.homeDeliveryUploadMethod,
                vat: order.vat
            });
        }
    }, [order]);

    useEffect(() => {
        if (hasUserChangedPrice) {
            const toUpdate = {};
            if (content?.service?.chosenPriceType === "byModular") {
                toUpdate.offeredPrice = calculatedModularProductPrice + upgradeOptionsPrice;
                toUpdate.commission = getOrderCommission(
                    content.service.commission,
                    content.service.chosenPriceType,
                    formValues?.modularProducts ?? [],
                    upgradeOptionsPrice
                );
            } else {
                const currentPriceByAmount = getPriceByAmount(formValues.amount || 0, content.service);
                const newOfferedPrice = currentPriceByAmount
                    ? (
                          parseFloat(currentPriceByAmount.toString().replace(",", "")) *
                          (getDefaultTypeToShow(content.service) === "byGroup"
                              ? 1
                              : parseFloat(formValues.amount || "0"))
                      ).toFixed(0) - 0
                    : null;

                toUpdate.offeredPrice = newOfferedPrice + upgradeOptionsPrice;
            }

            setFormValues(prevState => ({
                ...prevState,
                ...toUpdate
            }));
        }
    }, [
        content.service,
        content.commission,
        formValues.amount,
        formValues.tipPercentage,
        formValues.modularProducts,
        formValues.upgradeOptions?.options,
        hasUserChangedPrice,
        upgradeOptionsPrice
    ]);

    useEffect(() => {
        if (formValues.offeredPrice && isFinite(formValues?.tipPercentage)) {
            setFormValues(prevState => ({
                ...prevState,
                tip: getOrderTip(formValues.offeredPrice, formValues?.tipPercentage)
            }));
        }
    }, [formValues.offeredPrice, formValues?.tipPercentage]);

    useEffect(() => {
        if (content?.service) {
            let hasUserChangePrice;
            if (content?.service?.chosenPriceType === "byModular") {
                const isModularProductChanged = formValues?.modularProducts?.some(({amount, id}) => {
                    const originalOrderModularProduct = order?.modularProducts?.find(mProduct => mProduct.id === id);

                    return (amount || originalOrderModularProduct) && amount !== originalOrderModularProduct?.amount;
                });
                hasUserChangePrice =
                    formValues.modularProducts &&
                    order?.modularProducts &&
                    (isModularProductChanged || order?.upgradeOptions?.options !== formValues?.upgradeOptions?.options);
            } else {
                const isAmountExistOrEmpty = formValues.amount || formValues.amount === "";
                const isAmountChanged =
                    formValues.amount !== order.productAmount ||
                    order?.upgradeOptions?.options !== formValues?.upgradeOptions?.options;
                hasUserChangePrice = isAmountExistOrEmpty && order?.productAmount && isAmountChanged;
            }

            if (hasUserChangePrice) {
                setHasUserChangedPrice(true);
            }
        }
    }, [
        formValues.amount,
        formValues.modularProducts,
        order?.modularProducts,
        order?.productAmount,
        order?.upgradeOptions?.options,
        formValues?.upgradeOptions?.options
    ]);

    const totalPrice = useMemo(
        () => getOrderPrice(formValues),
        [
            formValues.offeredPrice,
            formValues.deliveryPrice,
            formValues.companyDiscountAmount,
            formValues.couponDiscountAmount,
            formValues.tip,
            formValues.vat
        ]
    );

    useEffect(() => {
        if (order?.companyDiscountPercentage && hasUserChangedPrice) {
            const totalOrderPriceWithoutDiscount =
                parseFloat(formValues.offeredPrice) +
                (parseFloat(formValues.deliveryPrice) || 0) +
                (formValues.tip || 0);
            const discount = Math.floor(totalOrderPriceWithoutDiscount * (order.companyDiscountPercentage / 100));

            setFormValues(prevValues => ({...prevValues, companyDiscountAmount: discount}));
        }
    }, [
        hasUserChangedPrice,
        order?.companyDiscount,
        formValues.offeredPrice,
        formValues.deliveryPrice,
        formValues.tip
    ]);

    useEffect(() => {
        if (order?.couponDiscountPercentage && hasUserChangedPrice) {
            const totalOrderPriceWithoutDiscount =
                parseFloat(formValues.offeredPrice) +
                (parseFloat(formValues.deliveryPrice) || 0) +
                (formValues.tip || 0);
            const discount = Math.floor(totalOrderPriceWithoutDiscount * (order.couponDiscountPercentage / 100));

            setFormValues(prevValues => ({...prevValues, couponDiscountAmount: discount}));
        }
    }, [
        hasUserChangedPrice,
        formValues.offeredPrice,
        formValues.deliveryPrice,
        formValues.tip,
        order.couponDiscountPercentage
    ]);

    useEffect(() => {
        const calculateDeliveryPrice = async () => {
            if (isOrderHasShippingProvider(formValues) && formValues.productId && formValues.addressId) {
                const deliveryPriceRes = await HttpClient.safePost("/api/products/shippingPrice", [
                    {
                        productId: content.service.productId,
                        destinationPlaceId: formValues.addressId
                    }
                ]);

                if (!deliveryPriceRes.error) {
                    const [firstDeliveryPriceRes] = deliveryPriceRes;

                    const changedPriceMessage = new StringBuilder("מחיר המשלוח השתנה ל-").append(
                        firstDeliveryPriceRes?.price
                    );

                    if (adminMode) {
                        changedPriceMessage.append(firstDeliveryPriceRes?.sourceAddressName);
                    }

                    message.success(changedPriceMessage.toString());

                    setFormValues(formValues => ({
                        ...formValues,
                        deliveryPrice: firstDeliveryPriceRes.price,
                        sourcePlaceId: firstDeliveryPriceRes.sourcePlaceId,
                        sourceAddressName: firstDeliveryPriceRes.sourceAddressName
                    }));
                }
            } else if (content?.service) {
                const deliveryPrice = await getDeliveryPrice(me, formValues, provider, content.service);

                setFormValues(prevValues => {
                    const error = deliveryPrice?.message
                        ? {
                              level: deliveryPrice?.price ? WARN_LEVEL : ERROR_LEVEL,
                              message: deliveryPrice?.message
                          }
                        : null;
                    return set({...prevValues}, `errors.${PLACE_ERROR}`, error);
                });

                if (deliveryPrice) {
                    setFormValues(prevValues => set({...prevValues}, "deliveryPrice", deliveryPrice?.price));
                }
            }
        };
        calculateDeliveryPrice();
    }, [
        formValues?.singleEmployeeDetails,
        formValues?.employeesExcel,
        formValues?.chosenAddress,
        content,
        formValues?.addressId
    ]);

    return (
        <div className="edit-order-view-container">
            <ConfigProvider direction="rtl">
                {!order?.productId ? (
                    <div>
                        <h2>להזמנה זו אין מזהה מוצר</h2>
                        <h3>יש לפנות למפתח הקרוב לביתך</h3>
                    </div>
                ) : null}
                <MarketplaceBookingContext.Provider
                    value={{
                        offeredPrice: formValues.offeredPrice
                    }}>
                    {content.service && !isLoadingProvider ? (
                        <>
                            {!hideTitle ? <EditViewOrderTitle order={order} /> : null}
                            <div className="admin-edit-order-modal-form-container">
                                <OrderForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    officeAddress={order?.companyAddress}
                                    content={content.service}
                                    providerDetails={content.provider}
                                    allowPastDates={true}
                                    calculateModularProductPrice={calculatedModularProductPrice}
                                    extraFields={extraOrderFormFields}
                                    adminMode={adminMode}
                                    disabledFields={disabledFields}
                                />

                                <div className="payment-input-comments-type-container">
                                    <div className="payment-input-invoice-comment-container">
                                        <div className="payment-input-invoice-comment-input">
                                            <FormInputV2
                                                value={formValues?.invoiceComment}
                                                placeholder={"הערות לציון על החשבונית"}
                                                onChange={e => updateFormValuesField("invoiceComment", e.target.value)}
                                            />
                                        </div>
                                        <div className="payment-input-tooltip-text-bank-transfer">{"עד 100 תווים"}</div>
                                    </div>

                                    <div>
                                        <FormInputV2
                                            placeholder={`מס׳ הזמנת רכש`}
                                            value={formValues?.invoicePONumber}
                                            style={{width: "150px"}}
                                            onChange={e => updateFormValuesField("invoicePONumber", e.target.value)}
                                        />
                                    </div>
                                </div>

                                <ConfigProvider direction="rtl">
                                    <div className="admin-edit-order-modal-price-section">
                                        <div className="admin-edit-order-modal-price-edit-section">
                                            {priceSectionLines.map(
                                                ({title, key, suffix, disabled, hideIfEmpty, format}) => {
                                                    if (hideIfEmpty && !formValues[key]) {
                                                        return null;
                                                    }

                                                    const value = format ? format(formValues[key]) : formValues[key];

                                                    const inputElement = (
                                                        <Input
                                                            className="admin-edit-order-modal-price-edit-section-input"
                                                            value={value ?? 0}
                                                            disabled={!!disabled}
                                                            onChange={({target}) =>
                                                                setFormValues(prevValues => ({
                                                                    ...prevValues,
                                                                    [key]: target.value
                                                                }))
                                                            }
                                                        />
                                                    );
                                                    return (
                                                        <div className="admin-edit-order-modal-price-line" key={key}>
                                                            <span className="admin-edit-order-modal-price-line-title">
                                                                {title}
                                                            </span>
                                                            <div className="admin-edit-order-modal-price-line-container">
                                                                {isNaN(Number(formValues[key] ?? 0)) ? (
                                                                    <span className="admin-edit-order-modal-price-line-error">
                                                                        יש להכניס מספר!
                                                                    </span>
                                                                ) : null}
                                                                {disabled ? (
                                                                    <Tooltip title={disabled}>{inputElement}</Tooltip>
                                                                ) : (
                                                                    inputElement
                                                                )}

                                                                <span className="pricing-description">
                                                                    {suffix ?? "₪ כולל מע״מ"}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                        <div className="admin-edit-order-modal-price-total-line">
                                            <span>סה״כ</span>
                                            <span>₪{totalPrice.toLocaleString()} כולל מע״מ</span>
                                        </div>
                                    </div>
                                </ConfigProvider>
                            </div>
                            <SaveCancelButtons onSave={beforeSave} onClose={innerOnCancel} />
                        </>
                    ) : (
                        <Spin className="admin-edit-order-modal-loader" size="large" />
                    )}
                </MarketplaceBookingContext.Provider>
            </ConfigProvider>
        </div>
    );
};

export const EditViewOrderTitle = ({order}) => {
    return (
        <div className="edit-order-view-title-container">
            <span>{new StringBuilder(`עריכת הזמנה`).append(order?.proposalId).toString()}</span>
            <span>{order?.productName}</span>
        </div>
    );
};
