import {CategorySearchSorterType} from "../../../MarketplaceUtils.jsx";

export const SortOptions = (onSortSelected) => {
    return {
        items: [
            {
                key: CategorySearchSorterType.LowestFirst,
                label: " מחיר: מהנמוך לגבוה",
                onClick: () => onSortSelected(CategorySearchSorterType.LowestFirst)
            },
            {
                key: CategorySearchSorterType.HighestFirst,
                label: "מחיר: מהגבוה לנמוך",
                onClick: () => onSortSelected(CategorySearchSorterType.HighestFirst)
            },
            {
                key: CategorySearchSorterType.BestSeller,
                label: "הנמכרים ביותר",
                onClick: () => onSortSelected(CategorySearchSorterType.BestSeller)
            },
            // {
            //     key: CategorySearchSorterType.Rate,
            //     label: "דירוג",
            //     onClick: () => onSortSelected(CategorySearchSorterType.Rate)
            // }
        ]
    }
}