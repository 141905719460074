import moment from "moment";
import {Images} from "../../images/Images";
import {companyTierBreakPoints, CustomerType, LastOrderBreakPoints} from "./consts";
import common from "@well-b/well-b-shared";

export const PodiumBreakPoints = [8, 5];

export const getCustomerTypeLabel = customerType => {
    switch (customerType) {
        case CustomerType.Casual: {
            return {
                label: "לקוח מזדמן"
            };
        }
        case CustomerType.ActiveReturning: {
            return {
                label: "לקוח חוזר פעיל",
                color: "var(--accept-color)",
                excelColor: "13c296"
            };
        }
        case CustomerType.InactiveReturning: {
            return {
                label: "לקוח חוזר לא פעיל",
                color: "var(--error-color)",
                excelColor: "FFC7CE"
            };
        }
        default: {
            return {
                label: "משתמש שאינו לקוח",
                color: "var(--standard-text-color)",
                excelColor: "8398A6"
            };
        }
    }
};

export const getCompanyPodium = companyScore => {
    if (companyScore === undefined) {
        return;
    }

    if (companyScore >= PodiumBreakPoints[0]) {
        return {
            title: "זהב",
            image: Images.goldMedal
        };
    }
    if (companyScore >= PodiumBreakPoints[1]) {
        return {
            title: "כסף",
            image: Images.silverMedal
        };
    }

    return {
        title: "ארד",
        image: Images.bronzeMedal
    };
};

export const getCompanySizeTier = employeesCount => {
    return companyTierBreakPoints.find(({breakpoint}) => employeesCount >= breakpoint)?.name;
};

export const getLastOrderColor = lastOrder => {
    const timeFromLastOrder = moment().diff(lastOrder, "day", true);
    return LastOrderBreakPoints.find(order => timeFromLastOrder > order.breakpoint) ?? {};
};

export const normalizePhoneNumber = phoneNumber => {
    const sanitizePhone = common.sanitizePhone(String(phoneNumber));

    if (!sanitizePhone.startsWith("0")) {
        return `0${sanitizePhone}`;
    }

    return sanitizePhone;
};
