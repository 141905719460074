import React, {useCallback, useEffect, useState} from "react";
import {Modal} from "antd";
import {isEmpty} from "lodash";
import {CompanyDetailsForm} from "../../wizards/companyStepper/CompanyDetailsForm";
import {HttpClient} from "../../http/HttpClient";
import {attachCountryStateAndCity} from "../../wizards/wizardUtils";

const AdminInputs = [
    {
        name: "תנאי תשלום (שוטף פלוס)",
        key: "paymentOption"
    }
];

export const AdminUpdateCompanyDetailsModal = ({visibility, onClose, onSave, companyDetails}) => {
    const [formValues, setFormValues] = useState({});

    const innerOnClose = useCallback(() => {
        setFormValues({});
        onClose();
    }, [onClose]);

    const innerOnSave = useCallback(async () => {
        if (!isEmpty(formValues)) {
            const company = attachCountryStateAndCity(formValues);
            await HttpClient.post(`/api/companies/${companyDetails.companyId}/updateCompanyOrderDetails`, company);
            onSave();
        }
        innerOnClose();
    }, [innerOnClose, companyDetails, formValues]);

    useEffect(() => {
        if (companyDetails) {
            setFormValues({
                name: companyDetails.name,
                businessNumber: companyDetails.businessNumber,
                employeesCount: companyDetails.employeesCount,
                address: companyDetails.address,
                floor: companyDetails.floor,
                contactName: companyDetails.contactName,
                phone: companyDetails.phone,
                paymentOption: companyDetails.paymentOption,
                sector: companyDetails.sector
            });
        }
    }, [companyDetails]);

    return (
        <Modal
            centered={true}
            width={700}
            className="admin-update-company-details-modal wb-modal-borderless"
            destroyOnClose={true}
            open={visibility}
            onCancel={innerOnClose}
            footer={null}
            title={`עריכת חברה:  ${companyDetails?.name ?? ""}`}
            closable={true}>
            <CompanyDetailsForm
                formValues={formValues}
                onFinish={innerOnSave}
                setFormValues={setFormValues}
                submitText="שמור"
                otherInputs={AdminInputs}
            />
        </Modal>
    );
};
