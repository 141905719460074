import React, {useCallback, useMemo, useState} from "react";
import {message, Modal, Spin, Upload} from "antd";
import {DownloadOutlined, UploadOutlined} from "@ant-design/icons";
import "../../../admin/AdminCompanies/load-admins-excel-modal.css";
import {GenericModal} from "../../../components/index.jsx";
import {NOOP} from "../../../utils/NOOP.jsx";
import {extractDataFromExcel, loadExcel} from "../../../utils/excelUtils.js";
import {HttpClient} from "../../../http/HttpClient.jsx";

import {
    ExcelRowBase,
    excelSubProductsKeys,
    excelSubProductsKeyToHeader,
    headerToExcelSubProductsKey,
    isActiveAnswerOption
} from "./consts.jsx";
import {Images} from "../../../images/Images.jsx";
import {ExportSubProductsProviderExcel} from "./subProductProviderExcelExporter.jsx";
import {addSystemCalculatedValidation, transformedSubProducts} from "./utils.js";
import "./load-sub-product-provider-excel-modal.css";
import {ReportTable} from "../../../components/ReportTable/ReportTable.jsx";

const {pinkFolders} = Images;

export const LoadSubProductProviderExcelModal = ({providerId, visible, onClose, onFinish}) => {
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [subProducts, setSubProducts] = useState(null);
    const [subProductsValidationResponse, setSubProductsValidationResponse] = useState([]);

    const excelUploadProps = useMemo(
        () => ({
            customRequest: NOOP,
            action: async file => {
                return new Promise((resolve, reject) => {
                    setLoadingExcel(true);
                    const fileReader = new FileReader();
                    fileReader.onload = async data => {
                        const arrayBuffer = data.target.result;
                        const workbook = await loadExcel(arrayBuffer, file.type);
                        const sheet = workbook.worksheets[0];

                        const cleanResults = extractDataFromExcel(
                            sheet,
                            headerToExcelSubProductsKey,
                            (rowData, index) => {
                                if (!(excelSubProductsKeys.isActive in rowData)) {
                                    rowData.isActive = isActiveAnswerOption.ACTIVE;
                                    setSubProductsValidationResponse(prev => [
                                        ...prev,
                                        {
                                            index: index - ExcelRowBase,
                                            key: excelSubProductsKeys.isActive,
                                            warning: "חושב על ידי המערכת"
                                        }
                                    ]);
                                }

                                if (excelSubProductsKeys.catalogNumber in rowData) {
                                    rowData.catalogNumber = rowData[excelSubProductsKeys.catalogNumber].toString();
                                }

                                if (excelSubProductsKeys.subProductCategory in rowData) {
                                    rowData.subProductCategory =
                                        rowData[excelSubProductsKeys.subProductCategory].trim();
                                }

                                if (excelSubProductsKeys.name in rowData) {
                                    rowData.name = rowData[excelSubProductsKeys.name].trim();
                                }

                                return rowData;
                            }
                        );
                        await validateSubProducts(cleanResults);

                        setLoadingExcel(false);
                        setSubProducts(cleanResults);
                        return resolve();
                    };

                    fileReader.readAsArrayBuffer(file);
                });
            },
            name: "file",
            accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            showUploadList: false,
            withCredentials: true
        }),
        []
    );

    const innerOnClose = useCallback(() => {
        setSubProducts(null);
        setSubProductsValidationResponse([]);
        onClose();
    }, [onClose]);

    const validateSubProducts = useCallback(
        async subProducts => {
            const subProductsToValidate = transformedSubProducts(subProducts);
            const res = await HttpClient.safePost(
                `/admin/api/providers/${providerId}/subProductsBulk/validate`,
                subProductsToValidate
            );

            const validationResponse = res?.extraInfo ? res?.extraInfo : [];
            const validationPricesCalculation = addSystemCalculatedValidation(subProducts, res?.extraInfo ?? []);
            setSubProductsValidationResponse(prev => [...prev, ...validationPricesCalculation]);

            if (res.error) {
                message.error(res.error);
                return;
            }

            if (validationResponse?.length) {
                message.error("שים לב יש הערות באקסל");
            } else {
                message.success("הכל תקין :)");
            }
        },
        [providerId]
    );

    const onSave = useCallback(async () => {
        const subProductsToSave = subProducts.map(
            ({excelRowNumber, priceWithoutCommission, ...subProduct}) => subProduct
        );
        const transformedSubProduct = transformedSubProducts(subProductsToSave);

        const res = await HttpClient.safePost(`/admin/api/providers/${providerId}/subProductsBulk`, {
            subProducts: transformedSubProduct
        });

        if (res.error) {
            message.error(res.error);
        } else {
            onFinish();
        }

        innerOnClose();
    }, [providerId, subProducts, innerOnClose, onFinish]);

    const onReportTableModalClose = useCallback(() => {
        setSubProducts(null);
        setSubProductsValidationResponse([]);
    }, []);

    return (
        <>
            <GenericModal
                visible={visible}
                image={pinkFolders}
                onClose={innerOnClose}
                className={"load-sub-products-bulk-container"}
                options={[
                    {
                        id: "uploadExcelForHomeDelivery",
                        text: (
                            <Upload {...excelUploadProps}>
                                <span className="order-location-input-load-excel-title">
                                    {loadingExcel ? <Spin /> : <UploadOutlined style={{marginLeft: "5px"}} />}
                                    טעינת אקסל מיני מוצרים מעודכנים
                                </span>
                            </Upload>
                        )
                    }
                ]}
                title="הוספת/ עדכון מיני מוצרים"
                text={[
                    "יש לשים לב - לא לערוך את עמודת מזהה המיני מוצר.",
                    "לאחר הטעינה תוכלו לצפות בתצוגת המקדימה של המיני מוצרים",
                    <span
                        key={1}
                        className="load-sub-products-excel-format"
                        onClick={() => ExportSubProductsProviderExcel()}>
                        הורדת פורמט אקסל מיני מוצרים
                        <DownloadOutlined style={{color: "#243446"}} />
                    </span>
                ]}
            />
            <Modal
                centered={true}
                width={1500}
                className="wb-modal-radius simple-modal-container"
                style={{borderRadius: "5px"}}
                destroyOnClose={true}
                open={!!subProducts}
                onCancel={onReportTableModalClose}
                footer={null}
                closable={true}>
                <ReportTable
                    data={subProducts}
                    onSave={onSave}
                    validationDetails={subProductsValidationResponse}
                    columnsSettings={[
                        {title: "שורת באקסל", key: "excelRowNumber"},
                        ...Object.entries(excelSubProductsKeyToHeader).map(([key, title]) => ({
                            title,
                            key
                        }))
                    ]}
                    increaseIndexNumber={2}
                />
            </Modal>
        </>
    );
};
