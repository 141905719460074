// import {RRule, RRuleSet, Weekday} from "rrule";
import {FrequencyMapper} from "./FrequencyMapper";
import moment from "moment-timezone";
import {eventByDate} from "./EventByDate";

const keepOriginalTime = (event, rruleSet) => {
    const ensureOriginalTime = date => {
        if ((event.includeDates || []).some(include => date.getTime() === include)) {
            return date;
        } else if (event.appointmentBased) {
            const dateTzMom = moment(date).tz(event.tzid);
            const eventToDate = eventByDate(event, dateTzMom.format("YYYY-M-D"));
            const dateTzFormat = dateTzMom.format("YYYY-MM-DD");
            const originalMinTime = moment(eventToDate.minTime, "HH:mm").tz(event.tzid).format("HH:mm");
            return moment.tz(`${dateTzFormat} ${originalMinTime}`, "YYYY-MM-DD HH:mm", event.tzid).toDate();
        } else {
            const originalMom = moment(event.dtstart).tz(event.tzid);
            const dateTzMom = moment(date).tz(event.tzid);
            return moment
                .tz(
                    `${dateTzMom.format("YYYY-MM-DD")} ${originalMom.hours()}:${originalMom.minutes()}`,
                    "YYYY-MM-DD HH:mm",
                    event.tzid
                )
                .toDate();
        }
    };

    const originalBetween = rruleSet.between.bind(rruleSet);
    rruleSet.between = (after, before, inc, iterator) => {
        const dates = originalBetween(after, before, inc, iterator);
        if (!dates || dates.length === 0) {
            return dates;
        }

        return dates.map(date => ensureOriginalTime(date));
    };

    const originalBefore = rruleSet.before.bind(rruleSet);
    rruleSet.before = (dt, inc) => {
        const date = originalBefore(dt, inc);
        if (!date) {
            return date;
        }

        return ensureOriginalTime(date);
    };

    const originalAfter = rruleSet.after.bind(rruleSet);
    rruleSet.after = (dt, inc) => {
        const date = originalAfter(dt, inc);
        if (!date) {
            return date;
        }

        return ensureOriginalTime(date);
    };

    return rruleSet;
};

export class RRuleSetBuilder {
    static build(event) {
        const rruleSet = new RRuleSet();

        const until = event.until && event.until >= event.dtstart ? new Date(event.until) : void 0;

        const repetition = FrequencyMapper.map(event);
        event = {
            ...event,
            ...repetition
        };

        if (event.eventId) {
            rruleSet.rrule(
                new RRule({
                    freq: FrequencyMapper.toFreqNum(event.freq),
                    interval: event.interval,
                    byweekday: event.byweekday ? event.byweekday.map(day => Weekday.fromStr(day.toUpperCase())) : [],
                    dtstart: new Date(event.dtstart),
                    until
                })
            );
        }

        if (Array.isArray(event.excludeDates)) {
            event.excludeDates.forEach(datetime => {
                rruleSet.exdate(new Date(datetime));
            });
        }

        if (Array.isArray(event.includeDates)) {
            event.includeDates.forEach(datetime => {
                rruleSet.rdate(new Date(datetime));
            });
        }

        return keepOriginalTime(event, rruleSet);
    }

    static toRRule(event) {
        const {byweekday, freq, interval, until} = FrequencyMapper.map(event);
        return new RRule({
            freq: FrequencyMapper.toFreqNum(freq),
            interval: interval,
            byweekday: byweekday.map(day => Weekday.fromStr(day.toUpperCase())),
            dtstart: new Date(event.dtstart),
            until: until,
            tzid: event.tzid
        });
    }
}
