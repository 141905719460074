import React from "react";
import {OrderPackageDetails} from "./Sections/OrderPackageDetails.jsx";
import {OrderVoucherInfo} from "./Sections/OrderVoucherInfo.jsx";
import {generateCompanyRequests, getWhenSectionTitle, shouldShowKosherCertificate} from "./utils.jsx";
import {OrderUpgradeOptions} from "./Sections/OrderUpgradeOptions.jsx";
import {StringBuilder} from "../../AppUtils.js";
import {kosherTypesToHebrew} from "../../marketplace/ProductsFilterModal/consts.jsx";
import {openWindow} from "../../http/WindowOpener.jsx";
import {calculateDeliveryTime, isOrderHasShippingProvider} from "../utils.jsx";
import {dateTimeRangeFormat} from "../../utils/DateUtils.jsx";
import {OrderWhereInfo} from "./Sections/OrderWhereInfo.jsx";
import {OrderContactsInfo} from "./Sections/OrderContactsInfo.jsx";

export const generateOrderRowsInfo = eventInfo => {
    if (!eventInfo) {
        return [];
    }

    return [
        {
            label: "פרטי החבילה",
            component: <OrderPackageDetails eventInfo={eventInfo} />,
            shouldShow: true
        },
        {
            label: "🗂 מידע על השובר",
            component: <OrderVoucherInfo eventInfo={eventInfo} />,
            shouldShow: !!eventInfo?.isVoucherOrder
        },
        {
            label: "🙏 בקשות מיוחדות שהלקוח הדגיש",
            component: <div>{generateCompanyRequests(eventInfo?.companyRequests)}</div>,
            shouldShow: !!eventInfo?.companyRequests
        },
        {
            label: "👨‍🎤 אפשרויות שדרוג",
            component: <OrderUpgradeOptions eventInfo={eventInfo} />,
            shouldShow: !!(
                eventInfo.upgradeOptions?.options?.length &&
                !eventInfo?.modularProducts?.length &&
                eventInfo?.upgradeOptions
            )
        },
        {
            label: "☝️ מענה לשאלות שלכם",
            component: (
                <>
                    {eventInfo.providerQuestions ? (
                        <div style={{color: "var(--standard-text-color)"}}>{eventInfo.providerQuestions}</div>
                    ) : null}
                    {eventInfo.companyAnswers ? <div>{eventInfo.companyAnswers}</div> : null}
                </>
            ),
            shouldShow: !eventInfo.isVoucherOrder && eventInfo.providerQuestions
        },
        {
            label: new StringBuilder()
                .append("✍️")
                .append("הערות לבית העסק")
                .append(eventInfo?.providerBrand?.length ? "-" : "")
                .append(eventInfo?.providerBrand?.length ? eventInfo.providerBrand : "")
                .toString(),
            component: <div>{eventInfo.companyExtraRequests}</div>,
            shouldShow: !!eventInfo.companyExtraRequests
        },
        {
            label: new StringBuilder().append("🚚").append("הערות לשליח").toString(),
            component: (
                <>
                    <div>חשוב לציין 🩵 לא ניתן להתחייב על זמן הגעה בתוך הטווח אספקה</div>
                    <div>{eventInfo.deliveryRequests}</div>
                </>
            ),
            shouldShow: !!eventInfo?.deliveryRequests
        },
        {
            label: new StringBuilder().append("✡️").append(eventInfo.kosher).toString(),
            component: (
                <>
                    {eventInfo?.productKosherType && (
                        <span>{`${kosherTypesToHebrew[eventInfo.productKosherType]} | `}</span>
                    )}
                    <a onClick={() => openWindow(eventInfo.kosherCertificateImageUrl, "_blank", true)}>
                        תעודת כשרות להורדה
                    </a>
                </>
            ),
            shouldShow: shouldShowKosherCertificate(eventInfo)
        }
    ].filter(info => !!info.shouldShow);
};

export const generateSquareOrderInfoProvider = (
    eventInfo,
    openAddressInNewTab,
    onClickProviderHelpLink,
    readMore,
    toggleReadMore,
    contactsInfo
) => {
    if (!eventInfo) {
        return [];
    }

    return [
        {
            row: [
                {
                    title: "🏢 מכתובת בית העסק",
                    component: (
                        <div className="order-information-card-open-address-container">
                            <span>{eventInfo?.sourceAddressName}</span>
                            <span
                                className="order-information-card-open-link"
                                onClick={() => openAddressInNewTab(eventInfo?.sourceAddressName)}>
                                {"פתח בגוגל מפות"}
                            </span>
                        </div>
                    ),
                    shouldShow: isOrderHasShippingProvider(eventInfo) && !!eventInfo?.sourceAddressName
                }
            ].filter(info => !!info.shouldShow)
        },
        {
            row: [
                {
                    title: "🚚 שעת איסוף משוערת",
                    component: (
                        <>
                            <div>
                                {new StringBuilder()
                                    .append(dateTimeRangeFormat(calculateDeliveryTime(eventInfo)))
                                    .toString()}
                            </div>
                            <div>
                                {new StringBuilder("מופעל ע״י").append(eventInfo?.deliveryProvider?.name).toString()}
                            </div>
                            <div>
                                <a className="order-information-card-open-link" onClick={onClickProviderHelpLink}>
                                    {new StringBuilder().append("📞").append("ליצירת קשר עם השליח").toString()}
                                </a>
                            </div>
                        </>
                    ),
                    shouldShow: isOrderHasShippingProvider(eventInfo)
                },
                {
                    title: "🏢 איפה?",
                    component: (
                        <OrderWhereInfo
                            eventInfo={eventInfo}
                            readMore={readMore}
                            toggleReadMore={toggleReadMore}
                            openAddressInNewTab={openAddressInNewTab}
                        />
                    ),
                    shouldShow: !eventInfo.isVoucherOrder
                }
            ].filter(info => !!info.shouldShow)
        },
        {
            row: [
                {
                    title: "📅 מתי?",
                    component: <div>{getWhenSectionTitle(eventInfo)}</div>,
                    shouldShow: true
                },
                {
                    title: "️ איש קשר לקבלת ההזמנה",
                    component: <OrderContactsInfo contactsInfo={contactsInfo} />,
                    shouldShow: !!contactsInfo?.length
                }
            ].filter(info => !!info.shouldShow)
        }
    ];
};

export const generateSquareOrderInfoCompany = (
    eventInfo,
    openAddressInNewTab,
    readMore,
    toggleReadMore,
    contactsInfo
) => {
    if (!eventInfo) {
        return [];
    }

    return [
        {
            row: [
                {
                    title: "🏢 איפה?",
                    component: (
                        <OrderWhereInfo
                            eventInfo={eventInfo}
                            readMore={readMore}
                            toggleReadMore={toggleReadMore}
                            openAddressInNewTab={openAddressInNewTab}
                        />
                    ),
                    shouldShow: !eventInfo.isVoucherOrder
                }
            ].filter(info => !!info.shouldShow)
        },
        {
            row: [
                {
                    title: "📅 מתי?",
                    component: <div>{getWhenSectionTitle(eventInfo)}</div>,
                    shouldShow: true
                },
                {
                    title: "️ איש קשר לקבלת ההזמנה",
                    component: <OrderContactsInfo contactsInfo={contactsInfo} />,
                    shouldShow: !!contactsInfo?.length
                }
            ].filter(info => !!info.shouldShow)
        }
    ];
};
