import React, {useCallback, useMemo, useState} from "react";
import {Collapse, ConfigProvider} from "antd";
import {OrderStatusView} from "../../../event/OrderStatusView/OrderStatusView.jsx";
import {RightOutlined} from "@ant-design/icons";
import {OrderActions} from "../../../event/OrderActionList/OrderActions.jsx";
import {NOOP} from "../../../utils/NOOP.jsx";
import "./order-collapse.mobile.css";
import {OrderStatus} from "../../../components/index.jsx";
import {OrderCollapseHeader} from "./OrderCollapseHeader.mobile.jsx";

const {Panel} = Collapse;

export const OrderCollapse = ({order, onAcceptOffer = NOOP, onDecline = NOOP, onDetails = NOOP}) => {
    const [isLoadAccept, setIdLoadAccept] = useState(false);
    const [isLoadDecline, setIdLoadDecline] = useState(false);

    const onDeclineClick = useCallback(
        async order => {
            try {
                setIdLoadDecline(true);
                await onDecline(order);
            } finally {
                setIdLoadDecline(false);
            }
        },
        [onDecline]
    );

    const onAcceptClick = useCallback(
        async order => {
            try {
                setIdLoadAccept(true);
                await onAcceptOffer(order);
            } finally {
                setIdLoadAccept(false);
            }
        },
        [onAcceptOffer]
    );

    const isOrderLoading = useMemo(() => isLoadDecline || isLoadAccept, [isLoadAccept, isLoadDecline]);

    return (
        <ConfigProvider direction="rtl">
            <Collapse
                className="order-collapse"
                key={order?.eventId}
                expandIconPosition="end"
                expandIcon={({isActive}) => <RightOutlined style={{fontSize: 16}} rotate={isActive ? 90 : 0} />}>
                <Panel
                    className="order-panel-container"
                    key={order?.eventId}
                    header={<OrderCollapseHeader order={order} />}>
                    <div className="order-collapse-content-container">
                        <OrderStatusView
                            className="provider-orders-collapse-content"
                            backgroundColor="white"
                            description={[
                                <OrderCollapseInfoRow key="address" title="כתובת:" value={order?.address} />,
                                <OrderCollapseInfoRow
                                    key="deliveryRequests"
                                    title="הערות לשליח:"
                                    value={order?.deliveryRequests}
                                />,
                                <OrderCollapseInfoRow
                                    key="companyExtraRequests"
                                    title="הערות לבית עסק"
                                    value={order?.companyExtraRequests}
                                />,
                                <OrderCollapseInfoRow
                                    key="assignedCsm"
                                    title="מנהלת לקוח:"
                                    value={order?.assignedCSMs?.[0]?.firstName}
                                />
                            ]}
                            actions={[
                                {
                                    text: "צפייה בכל פרטי ההזמנה",
                                    onClick: () => onDetails(order?.eventId),
                                    color: "transparent-blue",
                                    className: "order-info-btn"
                                }
                            ]}
                        />

                        {order.status === OrderStatus.pending ? (
                            <OrderActions
                                className="order-collapse-cta-container"
                                actions={[
                                    {
                                        text: "מאשר את ההזמנה",
                                        onClick: isOrderLoading ? NOOP : () => onAcceptClick(order),
                                        color: isOrderLoading ? "gray" : "blue",
                                        loading: isLoadAccept
                                    },
                                    {
                                        text: "לא מסתדר לי",
                                        onClick: isOrderLoading ? NOOP : () => onDeclineClick(order),
                                        color: isOrderLoading ? "gray" : "red",
                                        loading: isLoadDecline
                                    }
                                ]}
                            />
                        ) : null}
                    </div>
                </Panel>
            </Collapse>
        </ConfigProvider>
    );
};

export const OrderCollapseInfoRow = ({title, value}) => {
    return (
        <>
            {!!value && (
                <div className="order-info-row">
                    <span className="order-info-row-title">{title}</span>
                    <span>{value}</span>
                </div>
            )}
        </>
    );
};
