import React, {useCallback, useContext, useMemo} from "react";
import {inject, observer} from "mobx-react";
import {isEmpty, partition} from "lodash";
import classnames from "classnames";
import {CATEGORY_ITEM_DETAILS, OLD_MAX_CAROUSEL_LENGTH} from "./MarketplaceUtils";
import {MarketplaceCategoryCarouselArrow} from "./MarketplaceHelper";
import {MarketplaceCategoryItem} from "./MarketplaceItems/MarketplaceCategoryItem.jsx";
import {ProductBundleDetailsPage} from "./ProductBundleDetailsPage";
import {ProductDetailsPage} from "./ProductDetailsPage";
import {MarketplaceContext} from "./marketplaceContext";
import {PageLoader, WBCarousel} from "../components";
import {AppContext} from "../AppContext";
import {ContactPhone} from "../support/ContactPhone";
import {isAllowedAndExistingCategory} from "../center/EXCenterHelper";
import {getLogger} from "../Logger";
import {GoogleAnalytics} from "../GoogleAnalytics";
import "./marketplace-category-item-details.css";
import {CartModularProductContextProvider} from "./PricingCalculator/CartModularProductContext.jsx";

export const MarketplaceCategoryItemDetails = inject("store")(
    observer(({history, store}) => {
        const {me, services, company, isolationMode} = useContext(AppContext);
        const {
            product,
            providerDetails,
            serviceId,
            providerId,
            productId,
            marketplaceRoot,
            isProductBundle,
            productBundle
        } = useContext(MarketplaceContext);

        const log = useMemo(
            () =>
                getLogger(
                    {
                        serviceId,
                        providerId,
                        productId,
                        providerName: isProductBundle
                            ? productBundle?.providers
                                  ?.map(providerDetails => providerDetails?.businessName)
                                  .join(" | ")
                            : providerDetails?.businessName,
                        productName: isProductBundle ? productBundle?.productBundleName : product?.productName,
                        productPage: true
                    },
                    "ProductDetails"
                ),
            [serviceId, providerId, productId, product, providerDetails, productBundle]
        );

        const otherProviderProducts = useMemo(() => {
            if (isProductBundle) {
                return [];
            }

            return (
                partition(providerDetails?.services ?? [], service => service.productId === productId)?.[1] ?? []
            ).filter(
                product =>
                    product.isActive &&
                    product.services.some(service =>
                        isAllowedAndExistingCategory(me, service.serviceId ?? service, services)
                    ) &&
                    (!isolationMode || isolationMode.productsIds?.includes(product.productId))
            );
        }, [providerDetails, isolationMode, productId, services, me, isProductBundle]);

        const onBook = useCallback(() => {
            GoogleAnalytics.ecommerceEvent("begin_checkout", product, serviceId);

            if (isProductBundle) {
                history.push(`${marketplaceRoot}/productBundle/${productId}/booking`);
                return;
            }

            history.push(`${marketplaceRoot}/${serviceId}/providersV2/${providerId}/${productId}/booking`);
        }, [product, services, providerId, marketplaceRoot, productId]);

        const getProductLink = product => {
            const productServices = product.service.services.filter(({serviceId: productServiceId}) =>
                services.some(s => s.serviceId === productServiceId)
            );

            const productServiceId =
                productServices[Math.floor(Math.random() * productServices.length)]?.serviceId ?? serviceId;

            return `${marketplaceRoot}/${productServiceId}/providersV2/${providerId}/${product.service.productId}`;
        };

        const onAnotherContentClick = product => {
            store.categoryItemDetails = product;
            localStorage.setItem(CATEGORY_ITEM_DETAILS, JSON.stringify(product));
            log("Another content from provider clicked", {anotherContent: product.service.productName});

            const url = getProductLink(product);
            history.push(url);
        };

        const onAnotherContentNewTab = product => {
            log("Another content from provider clicked", {
                anotherContent: product.service.productName,
                newTab: true
            });
            const url = getProductLink(product);

            window.open(url, "_blank");
        };

        const footerCarouselProps = length => {
            const isShortCarousel = length < OLD_MAX_CAROUSEL_LENGTH;

            return {
                dots: false,
                infinite: true,
                slidesToShow: isShortCarousel ? length : OLD_MAX_CAROUSEL_LENGTH,
                nextArrow: <MarketplaceCategoryCarouselArrow direction={"next"} />,
                prevArrow: <MarketplaceCategoryCarouselArrow direction={"prev"} />,
                accessibility: true,
                arrows: true,
                className: "wb-carousel" + (isShortCarousel ? " short-carousel" : ""),
                variableWidth: isShortCarousel
            };
        };

        const anyQuestionsClicked = useCallback(() => {
            ContactPhone.openWhatsApp(company?.assignedCSMs?.[0]?.phone);
            log("Any Questions Button Clicked");
        }, [log, company]);

        const renderRelevantProductDetailsPage = useMemo(() => {
            if (isProductBundle) {
                return <ProductBundleDetailsPage log={log} anyQuestionsClicked={anyQuestionsClicked} onBook={onBook} />;
            } else if (product) {
                return (
                    <ProductDetailsPage log={log} anyQuestionsClicked={anyQuestionsClicked} onBook={onBook} />
                );
            }
        }, [log, anyQuestionsClicked, onBook, isProductBundle, product]);

        return (
            <div className={classnames("dynamic-marketplace-product-details", "marketplace-category-item-details")}>
                {product || productBundle ? (
                    <div className="product-details-container" style={{maxWidth: "1820px"}}>
                        {renderRelevantProductDetailsPage}

                        <div style={{width: 1240}}>
                            {!isEmpty(otherProviderProducts) ? (
                                <div>
                                    <div
                                        style={{
                                            marginTop: "27px",
                                            marginBottom: "27px",
                                            backgroundColor: "rgba(131,152,166,0.2)",
                                            height: "2px",
                                            width: "1240px"
                                        }}
                                    />
                                    <div style={{display: "flex", flexDirection: "column", marginBottom: "40px"}}>
                                        <span
                                            style={{
                                                fontSize: "20px",
                                                textAlign: "end",
                                                marginRight: "15px",
                                                color: "11344C",
                                                fontWeight: "700",
                                                marginBottom: "20px"
                                            }}>
                                            מוצרים נוספים של הספק
                                        </span>
                                        <div style={{display: "block", width: 1240, zIndex: 2}}>
                                            <WBCarousel
                                                carouselProps={footerCarouselProps(otherProviderProducts.length)}>
                                                {otherProviderProducts
                                                    .map(service => {
                                                        return {
                                                            service,
                                                            provider: providerDetails
                                                        };
                                                    })
                                                    .map((content, idx) => (
                                                        <MarketplaceCategoryItem
                                                            key={`category-item-${idx}`}
                                                            content={content}
                                                            log={log}
                                                            actionStyle={{marginTop: "-12px"}}
                                                            onContentClick={() => onAnotherContentClick(content)}
                                                            onNewTab={() => onAnotherContentNewTab(content)}
                                                        />
                                                    ))}
                                            </WBCarousel>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div style={{marginTop: "75px"}} />
                            )}
                        </div>
                    </div>
                ) : (
                    <PageLoader style={{top: "80px"}} />
                )}
            </div>
        );
    })
);
