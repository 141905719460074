import React, {useContext, useEffect, useState} from "react";
import PageTitleHeader, {PageSubTitleLabel, PageTitleLabel} from "../components/PageTitle";
import {DownloadOutlined} from "@ant-design/icons";
import {Button, Form, Modal, Spin, message} from "antd";
import {truncate} from "lodash";
import appointmentIcon from "../images/icons/appointment.svg";
import teamIcon from "../images/icons/team.svg";
import creditCardIcon from "../images/icons/credit_card.svg";
import {EventBus} from "../bus/EventBus";
import {HttpClient} from "../http/HttpClient";
import moment from "moment-timezone";
import {BalanceNavigation} from "../billing/BalanceNavigation";
import {BalanceInfoCard} from "../billing/BalanceInfoCard";
import {openWindow} from "../http/WindowOpener";
import {CloseIcon} from "../icons";
import {FormInput} from "../components/form";
import {StyledButton} from "../components/StyledButton";
import {AppContext} from "../AppContext";
import {PageLoader} from "../components/PageLoader";

const SHOW_DOWNLOAD_INVOICE_BUTTON = false;

const UpdatePaymentForm = ({details, onSuccess}) => {
    const {me} = useContext(AppContext);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const {eventId, currentDate, total} = details;

    const saveChanges = async ({total}) => {
        setLoading(true);
        try {
            const updatedBillingSummary = await HttpClient.post(
                `/api/billing/${moment(currentDate).format("YYYY-MM")}/transactions/${eventId}`,
                {
                    eventId,
                    companyId: me.companyId,
                    total: parseFloat(total)
                }
            );
            onSuccess(updatedBillingSummary);
        } catch (e) {
            message.error("Something went wrong, please try again later.");
        }
        setLoading(false);
    };

    return (
        <Form form={form} onFinish={saveChanges} initialValues={{total}}>
            <Form.Item
                name="total"
                rules={[
                    {
                        required: true,
                        message: "Please enter total amount."
                    }
                ]}>
                <FormInput
                    style={{maxWidth: 180}}
                    type="number"
                    placeholder="Total amount (ILS)"
                    className="wb-input-with-prefix"
                    addonBefore=" ₪ "
                />
            </Form.Item>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Form.Item style={{marginBottom: 0}}>
                    <StyledButton
                        onClick={() => form.submit()}
                        loading={loading}
                        style={{color: "white", backgroundColor: "#13C296", width: 200}}>
                        Save
                    </StyledButton>
                </Form.Item>
            </div>
        </Form>
    );
};

const UpdatePaymentModal = ({details, visible, onClose, onBillingInfoUpdate}) => {
    if (!details) return null;

    const {currentDate, serviceName, providerName} = details;

    return (
        <Modal
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            footer={null}
            title={null}
            closable={true}
            closeIcon={<CloseIcon fill="#767F90" />}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <PageTitleLabel style={{fontSize: 20, fontWeight: 400, marginBottom: 5}}>{serviceName}</PageTitleLabel>
                <PageSubTitleLabel style={{marginTop: 0, marginBottom: 10}}>with {providerName}</PageSubTitleLabel>
                <PageSubTitleLabel style={{marginTop: 0, marginBottom: 20}}>
                    {moment(currentDate).format("MMMM, YYYY")}
                </PageSubTitleLabel>
                {!!details ? <UpdatePaymentForm details={details} onSuccess={onBillingInfoUpdate} /> : null}
            </div>
        </Modal>
    );
};

const TransactionsTable = ({currentDate, billingInfo, loading, nextMonth, onBillingInfoUpdate}) => {
    const [editDetails, setEditDetails] = useState(null);

    const editPayment = details => {
        setEditDetails(details);
    };

    const closeMe = () => {
        setEditDetails(null);
    };

    const eventInfoById = {};

    const transactions = billingInfo.transactions;
    const table = transactions.reduce(
        (info, transaction) => {
            eventInfoById[transaction.eventId] = {
                custom: transaction.custom
            };
            info.eventIds.push(transaction.eventId);
            info.serviceNames.push(
                transaction.paymentType === "Platform" ? (
                    <span style={{color: "#1260D1"}}>{transaction.serviceName}</span>
                ) : (
                    transaction.serviceName
                )
            );
            info.providerNames.push(transaction.providerName);
            info.sessionCounts.push(transaction.sessionsCount || "");
            info.paymentTypes.push(transaction.paymentType === "Platform" ? "" : transaction.paymentType);
            info.totals.push(
                transaction.paymentType === "Platform" ? (
                    <span style={{color: "#1260D1"}}>₪{transaction.total.toLocaleString()}</span>
                ) : (
                    `₪${transaction.total.toLocaleString()}`
                )
            );

            return info;
        },
        {
            eventIds: [],
            serviceNames: [],
            providerNames: [],
            sessionCounts: [],
            paymentTypes: [],
            totals: []
        }
    );

    if (loading)
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#FCFDFE",
                    border: "1px solid #E8EDF5",
                    borderRadius: 10,
                    marginTop: 30,
                    width: "100%"
                }}>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        minHeight: 400,
                        borderBottom: "1px solid rgba(131,152,166,0.2)"
                    }}>
                    <PageLoader align="center" top={200} />
                </div>
            </div>
        );

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#FCFDFE",
                border: "1px solid #E8EDF5",
                borderRadius: 10,
                marginTop: 30,
                width: "100%"
            }}>
            <UpdatePaymentModal
                details={editDetails}
                visible={!!editDetails}
                onClose={closeMe}
                onBillingInfoUpdate={updatedBillingSummary => {
                    closeMe();
                    onBillingInfoUpdate(updatedBillingSummary);
                }}
            />
            <div style={{display: "flex", width: "100%", borderBottom: "1px solid rgba(131,152,166,0.2)"}}>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <div
                        style={{
                            minWidth: 300,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: 20,
                            fontSize: 16,
                            fontWeight: 700,
                            color: "#8398A6",
                            borderBottom: "1px solid rgba(131,152,166,0.2)",
                            height: 80
                        }}>
                        SERVICE
                    </div>
                    {table.serviceNames.map(serviceName => (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                padding: 20,
                                fontSize: 20,
                                fontWeight: 700,
                                color: "var(--secondary-color)",
                                height: 60
                            }}>
                            {serviceName}
                        </div>
                    ))}
                </div>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <div
                        style={{
                            minWidth: 250,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: 20,
                            fontSize: 16,
                            fontWeight: 700,
                            color: "#8398A6",
                            borderBottom: "1px solid rgba(131,152,166,0.2)",
                            height: 80
                        }}>
                        VENDOR
                    </div>
                    {table.providerNames.map(providerName => (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                padding: 20,
                                fontSize: 20,
                                fontWeight: 400,
                                color: "#8398A6",
                                height: 60
                            }}>
                            {truncate(providerName, {separator: " ", length: 25})}
                        </div>
                    ))}
                </div>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <div
                        style={{
                            minWidth: 200,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: 20,
                            fontSize: 16,
                            fontWeight: 700,
                            color: "#8398A6",
                            borderBottom: "1px solid rgba(131,152,166,0.2)",
                            height: 80
                        }}>
                        MONTHLY SESSIONS
                    </div>
                    {table.sessionCounts.map(sessionsCount => (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                padding: 20,
                                fontSize: 20,
                                fontWeight: 400,
                                color: "#8398A6",
                                height: 60
                            }}>
                            {sessionsCount}
                        </div>
                    ))}
                </div>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <div
                        style={{
                            minWidth: 250,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: 20,
                            fontSize: 16,
                            fontWeight: 700,
                            color: "#8398A6",
                            borderBottom: "1px solid rgba(131,152,166,0.2)",
                            height: 80
                        }}>
                        PAYMENT
                    </div>
                    {table.paymentTypes.map(paymentType => (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                padding: 20,
                                fontSize: 20,
                                fontWeight: 400,
                                color: "#8398A6",
                                height: 60
                            }}>
                            {paymentType}
                        </div>
                    ))}
                </div>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <div
                        style={{
                            minWidth: 200,
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            padding: 20,
                            fontSize: 16,
                            fontWeight: 700,
                            color: "#8398A6",
                            borderBottom: "1px solid rgba(131,152,166,0.2)",
                            height: 80
                        }}
                    />
                    {table.totals.map((total, idx) => (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                padding: 20,
                                fontSize: 20,
                                fontWeight: 700,
                                color: "var(--secondary-color)",
                                height: 60
                            }}>
                            {eventInfoById[table.eventIds[idx]] && eventInfoById[table.eventIds[idx]].custom ? (
                                <Button
                                    type="link"
                                    style={{fontSize: 20, fontWeight: 700, padding: 0}}
                                    onClick={() => {
                                        const serviceName = table.serviceNames[idx];
                                        const providerName = table.providerNames[idx];
                                        const eventId = table.eventIds[idx];
                                        editPayment({
                                            eventId,
                                            currentDate,
                                            providerName,
                                            serviceName,
                                            total: parseFloat(total.replace(/[^0-9-.]/g, ""))
                                        });
                                    }}>
                                    {total}
                                </Button>
                            ) : (
                                total
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 20,
                    fontSize: 20,
                    fontWeight: 700,
                    color: "var(--secondary-color)",
                    height: 60
                }}>
                <span>Sub total</span>
                <span>₪{billingInfo.subTotal.toLocaleString()}</span>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 20,
                    fontSize: 20,
                    fontWeight: 700,
                    color: "var(--secondary-color)",
                    height: 60
                }}>
                <span>VAT</span>
                <span>₪{billingInfo.vat.toLocaleString()}</span>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 20,
                    fontSize: 28,
                    fontWeight: 700,
                    color: "#13C296",
                    height: 80,
                    backgroundColor: "rgba(19,194,150,0.1)",
                    borderTop: "1px solid rgba(131,152,166,0.2)"
                }}>
                <span>
                    Sum total{" "}
                    <span style={{marginLeft: 4, fontSize: 20, fontWeight: 400}}>to be charged on {nextMonth} 1st</span>
                </span>
                <span>₪{(billingInfo.subTotal + billingInfo.vat).toLocaleString()}</span>
            </div>
        </div>
    );
};

export const CompanyBilling = () => {
    const [billingInfo, setBillingInfo] = useState(null);
    const [currentDate, setCurrentDate] = useState(moment());
    const [loadingData, setLoadingData] = useState(false);

    useEffect(() => {
        fetchBillingInfo(currentDate);
    }, []);

    const changeDate = dateMom => {
        setCurrentDate(dateMom);
        fetchBillingInfo(dateMom);
    };

    const fetchBillingInfo = dateMom => {
        Promise.resolve().then(async () => {
            setLoadingData(true);
            try {
                const date = dateMom.format("YYYY-MM");
                const info = await HttpClient.get(`/api/billing/${date}/company`);
                setBillingInfo(info);
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we couldn't list your billing information :("}},
                    e.message
                );
            }
            setLoadingData(false);
        });
    };

    const downloadInvoice = () => {
        openWindow(billingInfo.invoices[currentDate.format("YYYY-MM")].url, "_blank", true);
    };

    if (!billingInfo) return <PageLoader align="center" top={100} />;

    const month = currentDate.format("MMMM");
    const year = currentDate.format("YYYY");

    const nextMonth = moment(currentDate).add(1, "months").format("MMMM");
    const nextYear = moment(currentDate).add(1, "months").format("YYYY");

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginBottom: 100,
                marginLeft: 50,
                marginRight: 50,
                minWidth: 1300
            }}>
            <div style={{maxWidth: 1200, minWidth: 1200, width: "100%"}}>
                <PageTitleHeader style={{marginBottom: 0}} subTitleStyle={{marginBottom: 15}} showBack={false}>
                    Manage account & monthly payments
                </PageTitleHeader>
                <PageSubTitleLabel>
                    Your {month} payment is ₪{billingInfo.subTotal.toLocaleString()} + VAT, to be charged on {nextMonth}{" "}
                    1st, {nextYear}
                </PageSubTitleLabel>
                <div style={{width: "100%", display: "flex", flexDirection: "column", marginTop: 40}}>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <span style={{display: "flex", alignItems: "center", color: "#243446", fontSize: 28}}>
                            {month} {year}
                        </span>
                        <span style={{display: "flex", alignItems: "center"}}>
                            <BalanceNavigation
                                currantDateMom={currentDate}
                                onDateChange={dateMom => changeDate(dateMom)}
                            />
                        </span>
                    </div>
                    <div
                        style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 30}}>
                        <BalanceInfoCard
                            icon={<img src={creditCardIcon} alt="credit" />}
                            title="Total monthly payment"
                            subTitle={
                                <span>
                                    <span style={{marginRight: 8}}>₪{billingInfo.subTotal.toLocaleString()}</span>
                                    <span style={{fontSize: 16}}>+ VAT</span>
                                </span>
                            }
                        />
                        <BalanceInfoCard
                            icon={<img src={teamIcon} alt="team" />}
                            title="Registered employees"
                            subTitle={billingInfo.employeesCount.toLocaleString()}
                        />
                        <BalanceInfoCard
                            icon={<img src={appointmentIcon} alt="appointment" />}
                            title="Monthly events"
                            subTitle={billingInfo.eventsCount.toLocaleString()}
                        />
                    </div>
                    <div style={{display: "flex", flexDirection: "column", marginTop: 40}}>
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 28,
                                    color: "#243446",
                                    justifyContent: "flex-start"
                                }}>
                                Transactions
                            </div>
                            {SHOW_DOWNLOAD_INVOICE_BUTTON ? (
                                <Button
                                    icon={<DownloadOutlined style={{color: "#243446"}} />}
                                    onClick={downloadInvoice}
                                    disabled={
                                        !billingInfo.invoices || !billingInfo.invoices[currentDate.format("YYYY-MM")]
                                    }
                                    style={{
                                        height: 50,
                                        fontSize: 16,
                                        borderRadius: 10,
                                        border: "1px solid #E9EDF4",
                                        width: 250,
                                        backgroundColor: "#FCFDFE",
                                        color: "var(--secondary-color)",
                                        marginRight: 10
                                    }}>
                                    Download invoice
                                </Button>
                            ) : null}
                        </div>
                        <TransactionsTable
                            currentDate={currentDate}
                            billingInfo={billingInfo}
                            loading={loadingData}
                            nextMonth={nextMonth}
                            onBillingInfoUpdate={updatedBillingSummary => setBillingInfo(updatedBillingSummary)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
