import CallToActionModal from "../components/CallToActionModal";
import React from "react";
import {Typography} from "antd";
import {ModalTitle} from "../components/ModalTitle";

export const EventAlreadyConfirmedByMeModal = ({visible, history, answer}) => {
    const Title = () => (
        <ModalTitle title="We got your replay" subTitle={`This proposal was already ${answer} by you`} />
    );

    const SubTitle = () => (
        <div style={{display: "flex", flexDirection: "column"}}>
            <Typography.Paragraph style={{textAlign: "center"}}>
                So... what about checking your calendar for your upcoming sessions?
            </Typography.Paragraph>
        </div>
    );

    return (
        <CallToActionModal
            imageName="oops2"
            title={<Title />}
            subTitle={<SubTitle />}
            actionText="Check out my calendar"
            actionHandler={() => history.push("/dashboard/schedule")}
            visible={visible}
        />
    );
};
