import React, {useEffect} from "react";
import {Button, Checkbox, Form} from "antd";
import {StepperInput} from "../../components/StepperInput";
import {StepTitle} from "../../components/StepTitle";
import {StepContainer} from "../../components/StepContainer";
import {openWindow} from "../../../http/WindowOpener";
import {ContinuableForm} from "../../components/ContinuableForm";
import {validateEmailExistence} from "../../../components/form/validators";
import {AutoCompleteAddressFormInput} from "../../../utils/AutoCompleteAddress";
import {TERMS_AND_CONDITIONS} from "../../../data/links";

export const ProviderBasicInfoStep = ({initialValues, onNext}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const openTermsOfUse = () => {
        openWindow(TERMS_AND_CONDITIONS, "_blank", true);
    };

    return (
        <StepContainer
            style={{
                maxWidth: 600
            }}>
            <StepTitle
                style={{
                    color: "var(--secondary-color)",
                    fontSize: 46,
                    fontWeight: 500,
                    lineHeight: "70px",
                    textAlign: "center"
                }}>
                Let’s start with the basics
            </StepTitle>
            <ContinuableForm
                style={{marginTop: 50}}
                onFinish={onNext}
                initialValues={initialValues}
                canContinueColor="#ED7FA6">
                <div
                    style={{
                        display: "flex"
                    }}>
                    <Form.Item
                        name="firstName"
                        style={{width: "50%"}}
                        rules={[
                            {
                                required: true,
                                message: "Please enter your first name."
                            }
                        ]}>
                        <StepperInput placeholder="First name" />
                    </Form.Item>
                    <Form.Item
                        name="lastName"
                        style={{width: "50%", marginLeft: 20}}
                        rules={[
                            {
                                required: true,
                                message: "Please enter your last name."
                            }
                        ]}>
                        <StepperInput placeholder="Last name" />
                    </Form.Item>
                </div>
                <Form.Item
                    name="contact"
                    rules={[
                        {
                            required: true,
                            type: "email",
                            message: "Please enter a valid email."
                        },
                        {
                            validator: async (_, email) => await validateEmailExistence(email)
                        }
                    ]}>
                    <StepperInput placeholder="Personal email" />
                </Form.Item>
                <Form.Item
                    name="phone"
                    rules={[
                        {
                            required: true,
                            min: 8,
                            message: "Please enter a valid phone number."
                        }
                    ]}>
                    <StepperInput placeholder="Phone Number" />
                </Form.Item>
                <Form.Item
                    name="address"
                    valuePropName="searchText"
                    style={{width: "100%"}}
                    rules={[
                        {
                            required: true,
                            message: "Please select a business address."
                        }
                    ]}>
                    <AutoCompleteAddressFormInput />
                </Form.Item>
                <Form.Item
                    style={{marginLeft: 5}}
                    name="terms"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject("Please accept terms of use.")
                        }
                    ]}>
                    <Checkbox>
                        Agree to the{" "}
                        <Button
                            style={{textDecoration: "underline", padding: 0}}
                            type="link"
                            onClick={() => openTermsOfUse()}>
                            Terms & Conditions
                        </Button>
                    </Checkbox>
                </Form.Item>
            </ContinuableForm>
        </StepContainer>
    );
};
