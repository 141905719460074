import React from "react";
import {Typography} from "antd";
import {ModalTitle} from "../components/ModalTitle";
import CallToActionModal from "../components/CallToActionModal";

export const ProviderChangesSavedModal = ({history, onClose, visible}) => {
    const Title = () => <ModalTitle title="Your changes were saved!" subTitle="What's next?" />;

    const SubTitle = () => (
        <div style={{display: "flex", justifyContent: "center"}}>
            <Typography.Paragraph style={{textAlign: "center"}}>
                You can check the new changes on your profile now.
            </Typography.Paragraph>
        </div>
    );

    return (
        <CallToActionModal
            imageName="success2"
            closable={true}
            onClose={onClose}
            title={<Title />}
            subTitle={<SubTitle />}
            actionText="Go To Profile"
            actionHandler={() => history.push("/dashboard/profile")}
            visible={visible}
        />
    );
};
