import React, {useContext, useState} from "react";
import {AppContext} from "../../AppContext";
import {EyeCrossIcon, EyeIcon, PencilIcon, PictureIcon} from "../../icons";
import {StyledButton} from "../../components/StyledButton";
import {AppstoreFilled, LoadingOutlined} from "@ant-design/icons";
import {EXCenterSectionDesignEditModal} from "./EXCenterSectionDesignEditModal";
import {EXCenterContext} from "../EXCenterContext";
import {HttpClient} from "../../http/HttpClient";
import {findContentSectionById, onlyContentSectionsWithManagedData} from "../../company/content/CompanyContent";
import {EventBus} from "../../bus/EventBus";
import {message, Upload} from "antd";
import {cloneDeep} from "lodash";
import {EXCenterContentModal} from "./EXCenterContentModal";
import {memoize} from "lodash";
import {isEventsSection} from "../utils/EventUtil";
import {isMyBranchSelected} from "../branches/EXCenterBranches";
import {GoogleAnalytics} from "../../GoogleAnalytics";
import {UploadPhotoButton} from "../../components/UploadableCoverPhoto";

export const DesignMenuButton = ({children, onClick, style}) => (
    <StyledButton
        onClick={onClick}
        style={{
            width: 32,
            height: 32,
            backgroundColor: "white",
            borderRadius: 4,
            border: "none",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
            marginRight: 10,
            boxShadow: "0 2px 8px 0 rgba(0,0,0,0.1)",
            ...style
        }}>
        {children}
    </StyledButton>
);

const hasManagedDataContent = memoize(sectionId => {
    return !isEventsSection(sectionId) && !!onlyContentSectionsWithManagedData().find(s => s.sectionId === sectionId);
});

export const EXCenterSectionDesignMenu = ({className, sectionId, editItems, coverSize, onChange}) => {
    const [showDesignEditModal, setShowDesignEditModal] = useState(false);
    const [showContentEditModal, setShowContentEditModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [backgroundUploading, setBackgroundUploading] = useState(false);
    const {me} = useContext(AppContext);
    const {designInfoPerSection, designInfo, originalDesignInfo, theme} = useContext(EXCenterContext);

    const updateDesignInfo = async sectionDesignInfo => {
        setLoading(true);
        try {
            let updatedCompanyDesign = cloneDeep(originalDesignInfo);
            updatedCompanyDesign.design = {
                ...updatedCompanyDesign.design,
                [sectionId]: sectionDesignInfo
            };
            updatedCompanyDesign = await HttpClient.post("/api/website/settings", updatedCompanyDesign);
            EventBus.trigger("ex_center_design:update", updatedCompanyDesign);
            setShowDesignEditModal(false);
            GoogleAnalytics.event("EX Center - Design", "Update", sectionId);
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setLoading(false);
    };

    const onDisplayChange = async () => {
        await updateDesignInfo({
            ...sectionDesignInfo,
            display: !sectionDesignInfo.display
        });
    };

    const onBackgroundUpload = async imageUrl => {
        await updateDesignInfo({
            ...sectionDesignInfo,
            backgroundPhoto: imageUrl
        });
    };

    const onContentEdit = () => {
        setShowContentEditModal(true);
    };

    if (!me || me.type !== "company" || !designInfo || !isMyBranchSelected(me)) {
        return null;
    }

    const sectionDesignInfo = designInfoPerSection[sectionId];

    return (
        <div
            className={(className || "wb-center-design-menu") + (backgroundUploading ? " wb-force-show" : "")}
            style={{zIndex: 1}}>
            {editItems ? (
                <EXCenterSectionDesignEditModal
                    title={findContentSectionById(sectionId).displayName}
                    onClose={() => setShowDesignEditModal(false)}
                    designInfo={sectionDesignInfo}
                    visible={showDesignEditModal}
                    coverWidth={coverSize ? coverSize.width : 0}
                    coverHeight={coverSize ? coverSize.height : 0}
                    onSave={updateDesignInfo}
                    style={{top: 10}}
                    items={editItems}
                    loading={loading}
                />
            ) : null}
            {hasManagedDataContent(sectionId) ? (
                <EXCenterContentModal
                    sectionId={sectionId}
                    visible={showContentEditModal}
                    onClose={() => setShowContentEditModal(false)}
                    onDisplayChange={onDisplayChange}
                    onChange={onChange}
                />
            ) : null}
            <div style={{display: "flex", alignItems: "center", padding: 20}}>
                {editItems && editItems.backgroundPhoto ? (
                    <UploadPhotoButton
                        width={editItems.backgroundPhoto.width}
                        height={editItems.backgroundPhoto.height}
                        quality={100}
                        original={true}
                        wrap="0"
                        onChange={onBackgroundUpload}
                        CustomUploadButton={({uploading}) => {
                            setBackgroundUploading(uploading);
                            return (
                                <DesignMenuButton>
                                    {uploading ? (
                                        <LoadingOutlined style={{fontSize: 18, color: "var(--secondary-color)"}} />
                                    ) : (
                                        <PictureIcon style={{width: 18, height: 18, fill: "var(--secondary-color)"}} />
                                    )}
                                </DesignMenuButton>
                            );
                        }}
                    />
                ) : null}
                <DesignMenuButton onClick={onDisplayChange}>
                    {sectionDesignInfo.display ? (
                        <EyeIcon style={{width: 18, height: 18, fill: "var(--secondary-color)"}} />
                    ) : (
                        <EyeCrossIcon style={{width: 18, height: 18, fill: "var(--secondary-color)"}} />
                    )}
                </DesignMenuButton>
                {editItems && !editItems.hideControls ? (
                    <DesignMenuButton onClick={() => setShowDesignEditModal(true)}>
                        <PencilIcon style={{width: 18, height: 18, fill: "var(--secondary-color)"}} />
                    </DesignMenuButton>
                ) : null}
                {hasManagedDataContent(sectionId) && !(editItems && editItems.hideControls) ? (
                    <DesignMenuButton onClick={onContentEdit}>
                        <AppstoreFilled style={{fontSize: 16, color: "var(--secondary-color)"}} />
                    </DesignMenuButton>
                ) : null}
            </div>
        </div>
    );
};
