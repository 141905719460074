import React, {useContext, useReducer, useEffect} from "react";
import {SignUpFormBottomBar} from "../components/form";
import {Form, Tabs} from "antd";
import {HttpClient} from "../http/HttpClient";
import PageTitleHeader from "../components/PageTitle";
import {PageSubTitleLabel} from "../components/PageTitle";
import {StyledButton} from "../components/StyledButton";
import {AppContext} from "../AppContext";
import {ProviderSignUpBasicInfo} from "./ProviderSignUpBasicInfo";
import {ProviderSignUpExtraInfo} from "./ProviderSignUpExtraInfo";
import {ProviderSignUpPaymentSelection} from "./ProviderSignUpPaymentSelection";
import {PaymentPlan} from "./ProviderPaymentSelection";
import {EventBus} from "../bus/EventBus";
import {isAppointmentBasedProfession, ProfessionType} from "../data/professions";
import {ProviderServices} from "./ProviderServices";
import {FormAbandonment} from "../FormAbandonment";
import {parse as qsParse} from "query-string";
import {Redirect} from "react-router";

const {TabPane} = Tabs;

const SignUpViews = {
    BASIC: "1",
    EXTRA: "2",
    SERVICES: "3",
    PAYMENT: "4"
};

export const buildProviderUser = (basicInfo, extraInfo) => {
    const basicWithNoExtra = Object.keys(basicInfo).reduce((info, key) => {
        if (key.indexOf("extra.") < 0) {
            info[key] = basicInfo[key];
        }
        return info;
    }, {});

    const {imageUrl, imageId} = extraInfo;
    return {...basicWithNoExtra, imageUrl, imageId};
};

export const extractExtraDetails = (basicInfo, extraInfo) => {
    const allInfo = Object.assign({}, extraInfo, basicInfo);
    return Object.keys(allInfo).reduce((info, key) => {
        if (key.indexOf("extra.") >= 0) {
            info[key.replace("extra.", "")] = allInfo[key];
        }
        return info;
    }, {});
};

const FormSubmit = () => {
    let submitCallback = () => {};

    const onSubmit = callback => {
        submitCallback = callback;
    };

    const submit = () => {
        submitCallback();
    };

    return {
        onSubmit,
        submit
    };
};

const formSubmit = FormSubmit();

const formAbandonment = new FormAbandonment("Professional Sign Up");

export const ProviderSignUp = props => {
    const {location} = props;
    const {isMobile, professions} = useContext(AppContext);
    const [formBasicInfo] = Form.useForm();
    const [formExtraInfo] = Form.useForm();
    const [formPaymentSelect] = Form.useForm();
    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        activeView: SignUpViews.BASIC,
        basicInfo: {},
        extraInfo: {},
        supportedServices: null,
        paymentSelection: {paymentPlan: PaymentPlan.NOT_POPULAR},
        signingUp: false,
        uploading: false,
        referralCompany: null
    });

    let {activeView, basicInfo, extraInfo, supportedServices, paymentSelection, signingUp, uploading, referralCompany} =
        state;

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const referralCompanyId = qsParse(window.location.search).ref;
                if (!referralCompanyId) {
                    return;
                }
                const company = await HttpClient.get(`/api/companies/${referralCompanyId}/shallow`);
                setState({referralCompany: company});
            } catch (e) {}
        });

        formAbandonment.listToAbandonment();
        return () => {
            formAbandonment.stopListeningToAbandonment();
        };
    }, []);

    const signUp = async formData => {
        const {history} = props;
        try {
            setState({signingUp: true});
            await HttpClient.put("/api/providers", formData);
            formAbandonment.stopListeningToAbandonment();
            history.push(`/email/${btoa(formData.user.email)}/verify`);
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we didn't manage to complete your sign up :("}},
                e.message
            );
        }

        setState({signingUp: false});
    };

    const next = data => {
        const stateMap = {
            [SignUpViews.BASIC]: () => setState({basicInfo: data}),
            [SignUpViews.EXTRA]: () => setState({extraInfo: data}),
            [SignUpViews.SERVICES]: () => setState({supportedServices: data}),
            [SignUpViews.PAYMENT]: () => setState({paymentSelection: data})
        };

        stateMap[activeView]();

        let nextActiveView = activeView;
        if (activeView === SignUpViews.EXTRA && isAppointmentBasedProfession(data["extra.profession"], professions)) {
            nextActiveView = (parseInt(activeView) + 2).toString(10);
        } else {
            nextActiveView = (parseInt(activeView) + 1).toString(10);
        }

        setState({activeView: nextActiveView});
        navigateTo(nextActiveView);

        window.scrollTo({left: window.innerWidth / 2, top: 0});
    };

    const navigateTo = view => {
        const {history} = props;
        history.push(`/provider/signup/${view}${location.search || ""}`);
    };

    const goBack = () => {
        const profession = extraInfo["extra.profession"];
        let nextActiveView = activeView;
        if (profession && activeView === SignUpViews.PAYMENT && isAppointmentBasedProfession(profession, professions)) {
            nextActiveView = (parseInt(activeView) - 2).toString(10);
        } else {
            nextActiveView = (parseInt(activeView) - 1).toString(10);
        }

        setState({activeView: nextActiveView});
        navigateTo(nextActiveView);
    };

    const createAccount = async paymentSelection => {
        setState({paymentSelection});

        const {basicInfo, extraInfo, supportedServices} = state;

        const user = buildProviderUser(basicInfo, extraInfo);
        const extraDetails = extractExtraDetails(basicInfo, extraInfo);

        const formData = {
            extraDetails: {...extraDetails, ...paymentSelection},
            user,
            supportedServices
        };

        await signUp(formData);
    };

    const professionType = extraInfo => {
        if (!!extraInfo["extra.profession"]) {
            const professionName = extraInfo["extra.profession"];
            if (Array.isArray(professionName)) {
                const professionsFound = professions.filter(pro => professionName.some(name => pro.name === name));
                return professionsFound.some(p => p.type === ProfessionType.PER_ACTIVE_DAY)
                    ? ProfessionType.PER_ACTIVE_DAY
                    : ProfessionType.REVENUE_BASED;
            } else {
                const profession = professions.find(pro => pro.name === professionName);
                if (profession) {
                    return profession.type;
                }
            }
        }

        return ProfessionType.REVENUE_BASED;
    };

    const extractViewName = () => {
        const view = location.pathname.replace("/provider/signup/", "");

        if (isNaN(view)) {
            return null;
        }

        return view;
    };

    const titlesMap = {
        [SignUpViews.BASIC]: "Please fill your basic information",
        [SignUpViews.EXTRA]: "Describe the benefit of working with you",
        [SignUpViews.SERVICES]: "Offer at least one service you provide",
        [SignUpViews.PAYMENT]:
            professionType(extraInfo) === ProfessionType.PER_ACTIVE_DAY
                ? "Pay a small part of your revenue"
                : "Choose the right plan for you"
    };

    const formInstanceMap = {
        [SignUpViews.BASIC]: formBasicInfo,
        [SignUpViews.EXTRA]: formExtraInfo,
        [SignUpViews.SERVICES]: formSubmit,
        [SignUpViews.PAYMENT]: formPaymentSelect
    };

    if (
        activeView === SignUpViews.BASIC &&
        !basicInfo.email &&
        location.pathname !== `/provider/signup/${SignUpViews.BASIC}`
    ) {
        return <Redirect to={`/provider/signup/${SignUpViews.BASIC}${location.search || ""}`} />;
    } else {
        const currentView = extractViewName();
        if (currentView && activeView !== currentView) {
            setState({activeView: currentView});
            activeView = currentView;
        }
    }

    return (
        <div style={{overflowX: "hidden"}}>
            <PageTitleHeader
                style={{marginBottom: 0}}
                showBack={activeView !== SignUpViews.BASIC}
                onBack={() => goBack()}>
                {isMobile ? "Create an account" : "Create a professional account"}
            </PageTitleHeader>
            <PageSubTitleLabel>{titlesMap[activeView]}</PageSubTitleLabel>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    marginBottom: 80,
                    marginTop: 20
                }}>
                <Tabs
                    animated={false}
                    className="wb-provider-signup"
                    tabBarStyle={{display: "none"}}
                    activeKey={activeView}>
                    <TabPane tab="basic" key={SignUpViews.BASIC}>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <ProviderSignUpBasicInfo
                                visible={activeView === SignUpViews.BASIC}
                                form={formBasicInfo}
                                onNext={basicInfo => next(basicInfo)}
                                onValuesChange={changedValues =>
                                    formAbandonment.addInteractedFields(Object.keys(changedValues), 1)
                                }
                                values={basicInfo}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="extra" key={SignUpViews.EXTRA}>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <ProviderSignUpExtraInfo
                                visible={activeView === SignUpViews.EXTRA}
                                form={formExtraInfo}
                                onNext={extraInfo => next(extraInfo)}
                                values={extraInfo}
                                onValuesChange={changedValues =>
                                    formAbandonment.addInteractedFields(Object.keys(changedValues), 2)
                                }
                                onUploadChange={isUploading => setState({uploading: isUploading})}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="services" key={SignUpViews.SERVICES}>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <ProviderServices
                                visible={activeView === SignUpViews.SERVICES}
                                formSubmit={formSubmit}
                                profession={extraInfo["extra.profession"]}
                                onNext={supportedServices => next(supportedServices)}
                                onValuesChange={changedValues =>
                                    formAbandonment.addInteractedFields(Object.keys(changedValues), 3)
                                }
                                values={supportedServices}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="payments" key={SignUpViews.PAYMENT}>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <ProviderSignUpPaymentSelection
                                visible={activeView === SignUpViews.PAYMENT}
                                professionType={professionType(extraInfo)}
                                freePlanCompany={referralCompany}
                                form={formPaymentSelect}
                                onNext={async paymentSelection => await createAccount(paymentSelection)}
                                onValuesChange={changedValues =>
                                    formAbandonment.addInteractedFields(Object.keys(changedValues), 4)
                                }
                                values={paymentSelection}
                            />
                        </div>
                    </TabPane>
                </Tabs>
            </div>
            <SignUpFormBottomBar text={isMobile ? "Have an account?" : "Already have an account?"}>
                <StyledButton
                    loading={signingUp}
                    disabled={signingUp || uploading}
                    style={{color: "#2F3E83", backgroundColor: "white", minWidth: 150, borderRadius: 25}}
                    htmlType="submit"
                    onClick={() => formInstanceMap[activeView].submit()}>
                    {activeView === SignUpViews.PAYMENT ? "Create account" : "Next"}
                </StyledButton>
            </SignUpFormBottomBar>
        </div>
    );
};
