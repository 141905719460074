import React, {useCallback, useMemo, useState} from "react";
import {Divider, message} from "antd";
import {AuthPagesImage} from "../../images/Images.jsx";
import {SideBySideLayout} from "../../auth/SideBySideLayout/index.js";
import {DynamicForm} from "../../components/DynamicForm.jsx";
import "./company-register.css";
import {LoginContainer} from "../LoginContainer.jsx";
import {getLogger} from "../../Logger.jsx";
import {parseInt, set} from "lodash";
import {REGISTER_FORM_FIELDS} from "./consts.js";
import {HttpClient} from "../../http/HttpClient.jsx";
import {StringBuilder} from "../../AppUtils.js";
import {getThemeByOrigin} from "../../utils.js";

export const CompanyRegister = ({history}) => {
    const [registerForm, setRegisterForm] = useState({});

    const onUpdateRegisterForm = useCallback((_allValues, changedField) => {
        const [fieldKey, fieldValue] = Object.entries(changedField)[0];
        setRegisterForm(currentValues => set({...currentValues}, fieldKey, fieldValue));
    }, []);

    const onCreateNewCompany = useCallback(
        async formValues => {
            const companyInfo = {
                ...formValues,
                contact: formValues.email,
                businessNumber: parseInt(formValues.businessNumber)
            };
            const company = await HttpClient.safePut("/api/companies", companyInfo);

            if (company?.error) {
                message.error({
                    content: new StringBuilder("הפעולה נכשלה").append(`(${company?.error})`).toString(),
                    duration: 5
                });

                return false;
            }

            history.push("/company/wizard/success");
            return true;
        },
        [history]
    );

    const log = useMemo(() => getLogger({}, "CompanyRegister"), []);

    const registerPageImage = useMemo(() => {
        const themeByOrigin = getThemeByOrigin(window.location.origin);
        return AuthPagesImage?.[themeByOrigin] || AuthPagesImage.image;
    }, []);

    return (
        <>
            <SideBySideLayout srcImage={registerPageImage}>
                <div className="company-register-container-page">
                    <div className="register-company-container">
                        <span className="register-company-container-title">כמה פרטים לפני שנתחיל 🙏</span>

                        <span className="register-company-sub-title">
                            ביקור ראשון שלכם אצלנו? הזינו את הפרטים הבאים
                        </span>
                        <Divider />

                        <DynamicForm
                            className="register-company-form-container"
                            saveButtonTitle="שנתחיל?"
                            onUpdate={onUpdateRegisterForm}
                            content={registerForm}
                            validationFunction={onCreateNewCompany}
                            fields={REGISTER_FORM_FIELDS}
                        />

                        <LoginContainer history={history} log={log} />
                    </div>
                </div>
            </SideBySideLayout>
        </>
    );
};
