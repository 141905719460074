import React, {useContext, useState} from "react";
import {AppContext} from "../../../AppContext";
// import isURL from "validator/lib/isURL";
import {UploadableCoverPhoto} from "../../../components/UploadableCoverPhoto";
import {CompanyBranchSelect} from "../../CompanyBranchSelect";
// import * as EmailValidator from "email-validator";
import {LocationLabels} from "../../../data/locations";
import {EventInfoActionButton} from "../../../event/info/EventInfoFooterV2";
import {EyeIcon, TrashIcon} from "../../../icons";
import {StyledButton} from "../../../components/StyledButton";
import {Form, Tooltip} from "antd";
import {
    ContentEditButtonItem,
    ContentEditLabel,
    ContentEditProviderItem,
    ContentEditInputItem,
    ContentEditTagsItem,
    ContentEditDescriptionItem,
    ContentEditLocationDropdownItem
} from "./ContentEditFormItems";
import {CoverSizeTag} from "../../../components/CoverSizeTag";
import {isValidURL} from "../../../utils/UrlValidations";

const COVER_WIDTH = 1040;
const COVER_HEIGHT = 508;

export const actionWithLinkValidator =
    ({textField, linkField, enabledField}) =>
    (_, item) => {
        if (item && item[enabledField] && !item[textField]) {
            return Promise.reject("Please enter button text or disable this field.");
        } else if (item && item[enabledField] && !item[linkField]) {
            return Promise.reject("Please enter button link or disable this field.");
        } else if (item && item[enabledField] && !isValidURL(item[linkField])) {
            return Promise.reject("Please enter valid link or disable this field.");
        }

        return Promise.resolve();
    };

export const ContentEditForm = ({
    sectionId,
    category,
    fields,
    contentToEdit,
    loading,
    onValuesChange,
    onFinish,
    onEditDone,
    onDelete,
    onDisplayChange,
    onClose,
    onNewCategory,
    onPreview
}) => {
    const {company} = useContext(AppContext);
    const [form] = Form.useForm();
    const [fieldsWithErrors, setFieldsWithErrors] = useState([]);
    const [query, setQuery] = useState(
        contentToEdit && contentToEdit.sessionName
            ? encodeURIComponent(contentToEdit.sessionName)
            : encodeURIComponent(category)
    );

    const resetErrors = () => {
        fieldsWithErrors.forEach(fieldWithError => {
            const fieldValue = form.getFieldValue(fieldWithError.name[0]);
            form.setFields([
                {
                    name: fieldWithError.name,
                    value: fieldValue,
                    errors: [],
                    validating: false
                }
            ]);
        });
        setFieldsWithErrors([]);
    };

    const openPreview = async () => {
        try {
            const values = await form.validateFields();
            onPreview(values);
        } catch ({errorFields}) {
            setFieldsWithErrors(errorFields);
        }
    };

    return (
        <Form
            form={form}
            style={{backgroundColor: "#F6F8FA", width: "100%"}}
            fields={fields}
            validateTrigger="onSubmit"
            onFinishFailed={({errorFields}) => {
                setFieldsWithErrors(errorFields);
            }}
            onFinish={onFinish}
            onValuesChange={(_, allValues) => {
                if (allValues.sessionName) {
                    setQuery(encodeURIComponent(allValues.sessionName.value));
                }
                onValuesChange(allValues);
                resetErrors();
            }}>
            <Form.Item
                name="productCoverPhoto"
                valuePropName="imageUrl"
                className="wb-error-padding"
                rules={[
                    {
                        validator: (_, url) => {
                            if (!url) {
                                return Promise.reject("You must upload a cover.");
                            } else if (!isURL(url, {require_protocol: true, allow_underscores: true})) {
                                return Promise.reject("Invalid cover URL.");
                            }
                            return Promise.resolve();
                        }
                    }
                ]}>
                <UploadableCoverPhoto
                    width={COVER_WIDTH}
                    height={COVER_HEIGHT}
                    quality={100}
                    query={query}
                    showPlaceholder={true}
                    showReload={true}
                    coverStyle={{
                        borderRadius: "none",
                        paddingBottom: 0,
                        height: COVER_HEIGHT
                    }}>
                    <CoverSizeTag width={COVER_WIDTH} height={COVER_HEIGHT} />
                </UploadableCoverPhoto>
            </Form.Item>
            <div style={{display: "flex", flexDirection: "column", paddingLeft: 20, paddingRight: 20, paddingTop: 20}}>
                <Form.Item
                    name="sessionName"
                    valuePropName="item"
                    rules={[
                        {
                            validator: (_, item) => {
                                if (!item || !item.enabled || !item.value) {
                                    return Promise.reject("You must have a title.");
                                }

                                return Promise.resolve();
                            }
                        }
                    ]}>
                    <ContentEditInputItem label="Title" mustInclude={true} />
                </Form.Item>
                <Form.Item
                    name="provider"
                    valuePropName="item"
                    rules={[
                        {
                            validator: (_, item) => {
                                if (!item || !item.enabled) {
                                    return Promise.reject("You must select provider.");
                                } else if (item && item.enabled) {
                                    const {sessionType, providerFirstName, providerLastName, providerEmail} =
                                        item.value;

                                    if (["employee", "provider", "company"].every(type => sessionType !== type)) {
                                        return Promise.reject("Please select session type.");
                                    } else if (
                                        sessionType === "employee" &&
                                        (!providerFirstName || !providerLastName || !providerEmail)
                                    ) {
                                        return Promise.reject("Please select employee.");
                                    } else if (sessionType === "provider" && !providerFirstName) {
                                        return Promise.reject("Please enter provider's first/business name.");
                                    } else if (
                                        sessionType !== "company" &&
                                        providerEmail &&
                                        !EmailValidator.validate(providerEmail)
                                    ) {
                                        return Promise.reject("Provider's email is invalid.");
                                    }
                                }

                                return Promise.resolve();
                            }
                        }
                    ]}>
                    <ContentEditProviderItem />
                </Form.Item>
                <div>
                    <ContentEditLabel>Branches</ContentEditLabel>
                    <Form.Item
                        style={{marginTop: 10}}
                        name="branches"
                        rules={[
                            {
                                required: true,
                                type: "array",
                                message: "Please select at least one site."
                            }
                        ]}>
                        <CompanyBranchSelect mode="tags" branches={company ? company.branches : []} />
                    </Form.Item>
                </div>
                <Form.Item
                    name="tags"
                    valuePropName="item"
                    rules={[
                        {
                            validator: (_, item) => {
                                if (
                                    !item ||
                                    (item && item.enabled && (!Array.isArray(item.value) || item.value.length === 0))
                                ) {
                                    return Promise.reject("Please select at least one category.");
                                }

                                return Promise.resolve();
                            }
                        }
                    ]}>
                    <ContentEditTagsItem onNewCategory={onNewCategory} sectionId={sectionId} />
                </Form.Item>
                <Form.Item
                    name="productName"
                    valuePropName="item"
                    rules={[
                        {
                            validator: (_, item) => {
                                if (item && item.enabled && !item.value) {
                                    return Promise.reject("Please enter subtitle or disable this field.");
                                }

                                return Promise.resolve();
                            }
                        }
                    ]}>
                    <ContentEditInputItem label="Subtitle" />
                </Form.Item>
                <Form.Item
                    name="location"
                    valuePropName="item"
                    rules={[
                        {
                            validator: (_, item) => {
                                if (item && item.enabled && !item.value) {
                                    return Promise.reject("Please select location or disable this field.");
                                } else if (
                                    item &&
                                    item.enabled &&
                                    item.value === LocationLabels.ONLINE &&
                                    !item.extraValue
                                ) {
                                    return Promise.reject("Please enter online link or disable this field.");
                                } else if (item && item.enabled && !item.extraValue) {
                                    return Promise.reject("Please enter address or disable this field.");
                                } else if (
                                    item &&
                                    item.enabled &&
                                    item.value === LocationLabels.ONLINE &&
                                    !isURL(item.extraValue, {require_protocol: true, allow_underscores: true})
                                ) {
                                    return Promise.reject(
                                        "Please enter valid URL (e.g. https://www.well-b.biz/awesome)."
                                    );
                                }

                                return Promise.resolve();
                            }
                        }
                    ]}>
                    <ContentEditLocationDropdownItem />
                </Form.Item>
                <Form.Item
                    name="productDescription"
                    valuePropName="item"
                    rules={[
                        {
                            validator: (_, item) => {
                                if (item && item.enabled && !item.value) {
                                    return Promise.reject("Please enter description or disable this field.");
                                }

                                return Promise.resolve();
                            }
                        }
                    ]}>
                    <ContentEditDescriptionItem label="Description" />
                </Form.Item>
                <Form.Item
                    name="actionText"
                    valuePropName="item"
                    rules={[
                        {
                            validator: actionWithLinkValidator({
                                textField: "value",
                                linkField: "extraValue",
                                enabledField: "enabled"
                            })
                        }
                    ]}>
                    <ContentEditButtonItem maxLength={30} />
                </Form.Item>
            </div>
            <div
                style={{
                    height: 90,
                    backgroundColor: "#F6F8FA",
                    borderTop: "1px solid #DADEE4",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: 20,
                    paddingRight: 20
                }}>
                {onEditDone ? (
                    <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                        {onDelete ? (
                            <EventInfoActionButton
                                textVisibleOnDesktopOnly={true}
                                key="delete"
                                onClick={() => {
                                    onDelete(contentToEdit);
                                    onClose();
                                }}
                                icon={<TrashIcon style={{fill: "var(--secondary-color)", width: 20, height: 20}} />}>
                                Delete
                            </EventInfoActionButton>
                        ) : null}
                        <EventInfoActionButton
                            textVisibleOnDesktopOnly={true}
                            key="display"
                            onClick={() => {
                                onDisplayChange(contentToEdit);
                                onClose();
                            }}
                            icon={<EyeIcon style={{fill: "var(--secondary-color)", width: 22, height: 22}} />}>
                            {contentToEdit.status === "info-hidden" ? "Display" : "Undisplay"}
                        </EventInfoActionButton>
                    </div>
                ) : (
                    <div />
                )}
                {Array.isArray(fieldsWithErrors) && fieldsWithErrors.length > 0 ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#F06060"
                        }}>
                        Hang on, you have some errors above.
                    </div>
                ) : null}
                <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                    <Tooltip title={"Click to preview within the Employee Center."}>
                        <StyledButton
                            onClick={openPreview}
                            style={{
                                backgroundColor: "white",
                                height: 50,
                                border: "1px solid #13C296",
                                color: "#13C296",
                                marginRight: 10
                            }}>
                            Preview in Employee Center
                        </StyledButton>
                    </Tooltip>
                    <Form.Item noStyle>
                        <StyledButton
                            htmlType="submit"
                            loading={loading}
                            style={{backgroundColor: "#00CF82", color: "white", border: "none", width: 100}}>
                            Save
                        </StyledButton>
                    </Form.Item>
                </div>
            </div>
        </Form>
    );
};
