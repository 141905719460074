import {Button, Divider, Popconfirm, Tag} from "antd";
import React from "react";
import {PencilIcon, PhoneIcon, WhatsappIcon} from "../icons";
import {MailOutlined} from "@ant-design/icons";
import {ContactPhone} from "../support/ContactPhone";

export const ActionButton = ({style, onClick, icon}) => (
    <Button
        icon={icon}
        onClick={onClick}
        style={{
            borderRadius: 25,
            width: 50,
            height: 50,
            marginRight: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "none",
            ...style
        }}
    />
);

export const CollapseHeader = ({backgroundColor, color, name, start, end, date, dateWidth}) => (
    <div
        style={{
            width: "100%",
            backgroundColor,
            color: color || "white",
            display: "flex",
            minHeight: 36,
            alignItems: "center",
            justifyContent: "space-between"
        }}>
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: 16,
                width: "100%",
                color: color || "white"
            }}>
            <span>{name}</span>
            {date || (start && end) ? (
                <div style={{display: "flex", alignItems: "center"}}>
                    <Divider type="vertical" style={{backgroundColor: color || "white", width: 2, height: 15}} />
                    <span style={{width: dateWidth || 105}}>{date ? date : `${start} - ${end}`}</span>
                </div>
            ) : null}
        </div>
    </div>
);

export const CollapseContent = ({
    providerName,
    providerPhone,
    email,
    price,
    transactionTerminal,
    confirmationCode,
    date,
    start,
    end,
    address,
    onEdit,
    onDelete,
    deleting,
    deletePopupMessage,
    deleteIcon,
    deleteText,
    hideActions
}) => {
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    fontSize: 13,
                    color: "var(--secondary-color)",
                    marginBottom: 10
                }}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <span>{providerName}</span>
                    {providerPhone ? (
                        <>
                            <Divider
                                type="vertical"
                                style={{backgroundColor: "var(--secondary-color)", width: 2, height: 11}}
                            />
                            <span>{providerPhone}</span>
                        </>
                    ) : null}
                </div>
                {price ? (
                    <div style={{display: "flex", alignItems: "center", color: "#00C693", fontSize: 16}}>₪{price}</div>
                ) : null}
            </div>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 10}}>
                <div style={{display: "flex", alignItems: "center", fontSize: 13, color: "var(--secondary-color)"}}>
                    <span>{date}</span>
                    {start && end ? (
                        <>
                            <Divider
                                type="vertical"
                                style={{backgroundColor: "var(--secondary-color)", width: 2, height: 11}}
                            />
                            <span>
                                {start} - {end}
                            </span>
                        </>
                    ) : null}
                </div>
                <div>
                    {transactionTerminal && confirmationCode ? (
                        <Tag style={{margin: 0, fontSize: 13, padding: "2px 8px"}} color="green">
                            {confirmationCode}
                        </Tag>
                    ) : transactionTerminal ? (
                        <Tag style={{margin: 0, fontSize: 13, padding: "2px 8px"}} color="red">
                            Not payed
                        </Tag>
                    ) : null}
                </div>
            </div>
            {address ? (
                <div style={{display: "flex", alignItems: "center", fontSize: 13, color: "var(--secondary-color)"}}>
                    <span>{address}</span>
                </div>
            ) : null}
            {!hideActions ? (
                <div style={{display: "flex", marginTop: 20}}>
                    {providerPhone ? (
                        <>
                            <a href={`tel:${providerPhone}`} target="_blank">
                                <ActionButton
                                    icon={<PhoneIcon style={{width: 24}} />}
                                    style={{backgroundColor: "#1665D8"}}
                                />
                            </a>
                            <ActionButton
                                onClick={() => ContactPhone.openWhatsApp(providerPhone)}
                                icon={<WhatsappIcon />}
                                style={{backgroundColor: "#055E53"}}
                            />
                        </>
                    ) : null}
                    {email ? (
                        <a href={`mailto:${email}`} target="_blank">
                            <ActionButton
                                icon={<MailOutlined style={{fontSize: 22, paddingTop: 3, color: "white"}} />}
                                style={{backgroundColor: "#FD815D"}}
                            />
                        </a>
                    ) : null}
                    {onEdit ? (
                        <ActionButton
                            onClick={() => onEdit()}
                            icon={<PencilIcon style={{width: 20, height: 20, fill: "white"}} />}
                            style={{backgroundColor: "#F7B348"}}
                        />
                    ) : null}
                    {onDelete ? (
                        <Popconfirm
                            icon={null}
                            placement="topRight"
                            title={deletePopupMessage}
                            onConfirm={() => onDelete()}
                            okText={deleteText}
                            cancelText="No"
                            cancelButtonProps={{style: {height: 40, width: 80}}}
                            okButtonProps={{
                                style: {height: 40, width: 80},
                                loading: deleting
                            }}>
                            <ActionButton icon={deleteIcon} style={{backgroundColor: "#F06060"}} />
                        </Popconfirm>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};
