import {PriceTypes} from "../../admin/upload/PriceSelectorHelper.jsx";
import {isAllowedAndExistingCategory} from "../../center/EXCenterHelper.jsx";

export const getPrimaryProduct = provider => {
    const activeProducts = provider?.services?.filter(product => product.isActive) ?? [];

    const firstPrimaryProduct = activeProducts.find(product => product.chosenPriceType === PriceTypes.ByModular && !!product.isPrimary);

    if (firstPrimaryProduct) {
        return firstPrimaryProduct;
    }

    const firstModularProduct = activeProducts.find(product => product.chosenPriceType === PriceTypes.ByModular);

    if (firstModularProduct) {
        return firstModularProduct;
    }
};

export const getProviderProduct = (me, provider, services, isolationMode) => {
    return (provider?.services ?? [])
        .filter(
            product =>
                product.isActive &&
                product.services.some(service =>
                    isAllowedAndExistingCategory(me, service.serviceId ?? service, services)
                ) &&
                (!isolationMode || isolationMode.productsIds?.includes(product.productId))
        )
        .map(service => ({service, provider: provider}));
};
