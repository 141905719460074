import React, {useCallback, useMemo, useState} from "react";
import {ConfigProvider, message} from "antd";
import AdminLocationsAddressesModal from "./AdminLocationsAddressesModal";
import {columns} from "./columns";
import {useDebounceState, useRequest} from "../../utils/hooks";
import {FormModal, RoundedTable, SearchInputWithIcon} from "../../components";
import PageTitleHeader from "../../components/PageTitle";
import {HttpClient} from "../../http/HttpClient";
import "./admin-locations.css";
import {AdminOverrideAddressComponentsModal} from "./AdminOverrideAddressComponentsModal.jsx";
import {StringBuilder} from "../../AppUtils.js";

const AdminLocations = () => {
    const [searchInput, setSearchInput, unDebouncedSearchInput] = useDebounceState("", 200);
    const [searchedLocations, loadingSearchLocations, _, manualFetch] = useRequest(
        `/admin/api/location/address/${searchInput}`,
        "GET",
        null,
        [],
        !!searchInput?.length
    );
    const [editedLocation, setEditedLocation] = useState(null);
    const [overrideAddressComponentsModal, setOverrideAddressComponentsModal] = useState(null);

    const addTextualAddress = useCallback(
        async newAddress => {
            const result = await HttpClient.safePut("/admin/api/location/address/addTextualAddress", {
                placeId: editedLocation.place_id,
                newAddressText: newAddress
            });

            if (result.error) {
                message.error("שגיאה בעת הוספת אופציית חיפוש לכתובת");
                return;
            }

            setEditedLocation({
                ...editedLocation,
                textualAddresses: [...editedLocation.textualAddresses, newAddress]
            });
        },
        [editedLocation]
    );

    const removeTextualAddress = useCallback(
        async addressToRemove => {
            const result = await HttpClient.safePut("/admin/api/location/address/removeTextualAddress", {
                placeId: editedLocation.place_id,
                addressTextToRemove: addressToRemove
            });

            if (result.error) {
                message.error("שגיאה בעת מחיקת אופציית חיפוש לכתובת");
                return;
            }

            setEditedLocation({
                ...editedLocation,
                textualAddresses: editedLocation.textualAddresses.filter(address => address !== addressToRemove)
            });
        },
        [editedLocation]
    );

    const openOverrideAddressComponentsModal = useCallback(
        placeId => {
            const location = searchedLocations.find(location => location.place_id === placeId);

            if (!location) {
                message.error("שגיאה בעת איתור המיקום שנבחר");
                return;
            }

            setOverrideAddressComponentsModal(location);
        },
        [searchedLocations]
    );

    const closeOverrideAddressComponentsModal = useCallback(() => {
        setOverrideAddressComponentsModal(null);
    }, []);

    const saveOverrideAddressComponentsModal = useCallback(async (placeIdToUpdate, sourcePlaceId) => {
        const res = await HttpClient.safePost("/admin/api/location/address/setLocationOverrideAddressComponents", {
            placeIdToUpdate,
            sourcePlaceId
        });

        if (res?.error) {
            message.error(new StringBuilder().append("שגיאה בעת עידכון כתובת").append(`(${res.error})`).toString());
            return;
        }

        message.success("הכתובת עודכנה בהצלחה");
        manualFetch();
        setOverrideAddressComponentsModal(null);
    }, []);

    const resetOverrideAddressComponentsModal = useCallback(async placeIdToUpdate => {
        const res = await HttpClient.safePost("/admin/api/location/address/resetLocationOverrideAddressComponents", {
            placeIdToUpdate
        });

        if (res?.error) {
            message.error(new StringBuilder().append("שגיאה בעת עידכון כתובת").append(`(${res.error})`).toString());
            return;
        }

        message.success("הכתובת עודכנה בהצלחה");
        manualFetch();
        setOverrideAddressComponentsModal(null);
    }, []);

    return (
        <ConfigProvider direction="rtl">
            <AdminLocationsAddressesModal
                visible={!!editedLocation}
                addressList={editedLocation?.textualAddresses}
                addAddress={addTextualAddress}
                removeAddress={removeTextualAddress}
                onClose={() => {
                    setEditedLocation(null);
                    manualFetch();
                }}
            />

            <AdminOverrideAddressComponentsModal
                overrideAddressComponentsModal={overrideAddressComponentsModal}
                onClose={closeOverrideAddressComponentsModal}
                onSave={saveOverrideAddressComponentsModal}
                onReset={resetOverrideAddressComponentsModal}
            />

            <div className="admin-coupons">
                <PageTitleHeader showBack={false}>כתובות</PageTitleHeader>
                <SearchInputWithIcon
                    searchInput={unDebouncedSearchInput}
                    setSearchInput={setSearchInput}
                    placeholder="חפש מיקום"
                />
                <RoundedTable
                    className="admin-locations-table"
                    rowKey="place_id"
                    scroll={{x: 800}}
                    pagination={false}
                    loading={loadingSearchLocations}
                    dataSource={searchedLocations}
                    columns={columns(setEditedLocation, openOverrideAddressComponentsModal)}
                />
            </div>
        </ConfigProvider>
    );
};

export default AdminLocations;
