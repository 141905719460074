import React, {useMemo} from "react";
import {ConfigProvider} from "antd";
import {FormModal} from "../../../components";
import {ProviderNetPaymentTermsTypes} from "../../upload/AdminUploadUtils";

export const ProviderNetPaymentTermsModal = ({visible, onSave, onClose, providerInfo}) => {
    const {providerId, businessName, netPaymentTerms} = useMemo(() => providerInfo ?? {}, [providerInfo]);

    return (
        <>
            <ConfigProvider direction="rtl">
                <FormModal
                    header={"הגדרת תנאי שוטף לספק"}
                    subTitle={"בחרו את תנאי השוטף הרצויים עבור הספק"}
                    visible={visible}
                    fields={[
                        {
                            name: "_providerId",
                            label: "מזהה שותף",
                            type: "custom",
                            customComponent: <span>{providerId}</span>
                        },
                        {
                            name: "_providerName",
                            label: "שם השותף",
                            type: "custom",
                            customComponent: <span>{businessName}</span>
                        },
                        {
                            name: "_providerNetPaymentTerms",
                            label: "תנאי שוטף נוכחיים",
                            type: "custom",
                            customComponent: <span>{netPaymentTerms}</span>
                        },

                        {
                            name: "providerNetPaymentTerms",
                            label: "תנאי שוטף חדשים לספק",
                            type: "select",
                            options: Object.values(ProviderNetPaymentTermsTypes).map(value => ({
                                label: `שוטף +${value}`,
                                name: value
                            }))
                        }
                    ]}
                    onSave={({providerNetPaymentTerms}) => onSave({providerId, providerNetPaymentTerms, businessName})}
                    onClose={onClose}
                />
            </ConfigProvider>
        </>
    );
};
