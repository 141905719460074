import React, {useCallback, useState} from "react";
import {ConfigProvider, Form, Modal} from "antd";
import {FormInput} from "../../components/form";
import {StyledButton} from "../../components";

export const AdminCategoriesEditSubCategory = ({visible, subCategoryToEdit, onClose, onEdit}) => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);

    const innerOnEdit = useCallback(
        async newSubCategory => {
            setIsLoading(true);
            await onEdit(newSubCategory?.trim());
            form.resetFields();
            setIsLoading(false);
        },
        [onEdit]
    );

    return (
        <Modal
            centered={true}
            width={340}
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={onClose}
            footer={null}
            title={null}
            closable={true}>
            <div style={{display: "flex", width: "100%", flexDirection: "column", alignItems: "center"}}>
                <ConfigProvider direction="rtl">
                    <Form
                        form={form}
                        onFinish={values => {
                            innerOnEdit(values.subCategory);
                        }}
                        style={{maxWidth: 300, width: "100%"}}>
                        <Form.Item
                            name="subCategory"
                            style={{marginTop: 40}}
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter sub-category name."
                                }
                            ]}>
                            <FormInput defaultValue={subCategoryToEdit} placeholder="Sub-Category name" />
                        </Form.Item>
                        <Form.Item>
                            <StyledButton
                                style={{
                                    backgroundColor: "#ED7FA6",
                                    color: "white",
                                    width: "150px",
                                    marginLeft: "65px",
                                    borderRadius: "5px"
                                }}
                                htmlType="submit"
                                loading={isLoading}>
                                Edit Sub-Category
                            </StyledButton>
                        </Form.Item>
                    </Form>
                </ConfigProvider>
            </div>
        </Modal>
    );
};
