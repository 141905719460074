import React from "react";
import {Images} from "../../images/Images";

export const CheckboxController = ({isChecked, setType, type}) =>
    isChecked ? (
        <img
            className={"order-voucher-checkbox-form-container"}
            src={Images.marketplaceBookingCheckboxChecked}
            alt="booking-check-icon"
        />
    ) : (
        <img
            onClick={() => setType(type)}
            className={"order-voucher-checkbox-form-container"}
            src={Images.marketplaceBookingCheckboxEmpty}
            alt="booking-empty-icon"
        />
    );

export const SwitchController = ({isChecked, setType, type}) =>
    isChecked ? (
        <img
            className={"order-voucher-checkbox-form-container"}
            src={Images.marketplaceBookingCheckboxChecked}
            alt="booking-check-icon"
            onClick={() => setType(type, !isChecked)}
        />
    ) : (
        <img
            className={"order-voucher-checkbox-form-container"}
            src={Images.marketplaceBookingCheckboxEmpty}
            alt="booking-empty-icon"
            onClick={() => setType(type, !isChecked)}
        />
    );
