import React, {useState} from "react";
import {Button, Form, message, Modal, Select, Tooltip} from "antd";
import {RoundedTable} from "../components/RoundedTable";
import moment from "moment-timezone";
import {DaysMapper} from "../event/DaysMapper";
import {SearchInput} from "../components/Search";
import {HttpClient} from "../http/HttpClient";
import PageTitleHeader, {PageTitleLabel} from "../components/PageTitle";
import _ from "lodash";
import {CancelButton, SaveButton} from "../components/form";
import {SearchableSelect} from "../components/SearchableSelect";
import {fixStartDateToStartOnTheDaySelected, OfferFields} from "../event/EventOffer";
import {FrequencyMapper} from "../event/FrequencyMapper";
import {SYSTEM_DEFAULT_START_TIME} from "../data/defaults";
import {StyledButton} from "../components";
import {openWindow} from "../http/WindowOpener";

const {Option} = Select;

const optionsForResultsToShow = [100, 400, 800, 1500, 3000];

const isInactive = (status, dtstart, until) =>
    status === "accepted" && ((typeof until === "number" && until < Date.now()) || dtstart > Date.now());

const statusToColor = (status, dtstart, until) => {
    if (isInactive(status, dtstart, until)) {
        return "#F06060";
    }

    const statusMap = {
        pending: "#ccc",
        accepted: "#0eca0e",
        "cancel-pending": "#ffa025"
    };

    return statusMap[status];
};

const statusToTooltip = (status, dtstart, until) => {
    if (isInactive(status, dtstart, until)) {
        return "Inactive event";
    }

    const statusMap = {
        pending: "Pending professional approval",
        accepted: "Active event",
        "cancel-pending": "Pending admin cancellation"
    };

    return statusMap[status];
};

const columns = (onCancel, cancellingId, onReject, rejectingId) => [
    {
        title: "#",
        key: "#",
        width: 70,
        fixed: "left",
        render(_, __, idx) {
            return `${idx + 1}`;
        }
    },
    {
        title: "TRANSACTION",
        dataIndex: "eventId",
        key: "eventId",
        width: 150,
        fixed: "left",
        filters: [
            {
                text: "Active",
                value: "accepted"
            },
            {
                text: "Inactive",
                value: "inactive"
            },
            {
                text: "Pending Professional",
                value: "pending"
            },
            {
                text: "Pending Cancellation",
                value: "cancel-pending"
            }
        ],
        filterMultiple: false,
        onFilter: (value, {status, dtstart, until}) => {
            if (value === "inactive") {
                return isInactive(status, dtstart, until);
            } else {
                return status === value;
            }
        },
        render(eventId, {status, dtstart, until}) {
            return (
                <div style={{display: "flex", alignItems: "center"}}>
                    <Tooltip title={statusToTooltip(status, dtstart, until)}>
                        <div
                            style={{
                                height: 10,
                                width: 10,
                                borderRadius: 5,
                                marginRight: 8,
                                backgroundColor: statusToColor(status, dtstart, until)
                            }}
                        />
                    </Tooltip>
                    {eventId}
                </div>
            );
        }
    },
    {
        title: "COMPANY",
        dataIndex: "companyName",
        key: "company",
        fixed: "left",
        sorter: (r1, r2) => (r1.companyName.toLowerCase() < r2.companyName.toLowerCase() ? 1 : -1),
        render(companyName, {companyEmail}) {
            return (
                <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <label style={{color: "var(--secondary-color)", fontSize: 14, fontWeight: 500}}>
                            {companyName}
                        </label>
                        <label style={{color: "#747F92", fontSize: 12, fontWeight: 400}}>{companyEmail}</label>
                    </div>
                </div>
            );
        }
    },
    {
        title: "EMAIL",
        dataIndex: "companyEmail",
        key: "email",
        fixed: "left",
        render(companyEmail) {
            return companyEmail;
        }
    },
    {
        title: "PROFESSIONAL",
        key: "professional",
        width: 200,
        fixed: "left",
        sorter: (r1, r2) => {
            const name1 = r1.providerFirstName
                ? `${r1.providerFirstName}${r1.providerLastName ? ` ${r1.providerLastName}` : ""}`
                : "";
            const name2 = r2.providerFirstName
                ? `${r2.providerFirstName}${r2.providerLastName ? ` ${r2.providerLastName}` : ""}`
                : "";
            return name1.toLowerCase() < name2.toLowerCase() ? 1 : -1;
        },
        render(_, {providerFirstName, providerLastName, providerEmail}) {
            if (!providerFirstName) {
                return (
                    <label style={{color: "var(--secondary-color)", fontSize: 14, fontWeight: 700}}>Multi Offer</label>
                );
            }
            return (
                <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <label
                            style={{
                                color: "var(--secondary-color)",
                                fontSize: 14,
                                fontWeight: 500
                            }}>{`${providerFirstName}${providerLastName ? ` ${providerLastName}` : ""}`}</label>
                        <label style={{color: "#747F92", fontSize: 12, fontWeight: 400}}>{providerEmail}</label>
                    </div>
                </div>
            );
        }
    },
    {
        title: "SESSION",
        key: "session",
        render(_, {serviceName, sessionName}) {
            return serviceName || sessionName;
        }
    },
    {
        title: "CREATED",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 200,
        sorter: (r1, r2) => (r1.createdAt < r2.createdAt ? 1 : -1),
        defaultSortOrder: "ascend",
        render(createdAt) {
            return createdAt ? moment(createdAt).format("MMMM Do, YYYY HH:mm") : "";
        }
    },
    {
        title: "BUDGET PER SESSION",
        dataIndex: "offeredPrice",
        key: "budget",
        width: 180,
        sorter: (r1, r2) => {
            const price1 = r1.appointmentBased ? -1 : r1.offeredPrice || 0;
            const price2 = r2.appointmentBased ? -1 : r2.offeredPrice || 0;
            return price1 < price2 ? 1 : -1;
        },
        render(price, {appointmentBased, customSession}) {
            if (appointmentBased) {
                return "-";
            }
            return price ? `₪${price}` : customSession ? "-" : "FREE";
        }
    },
    {
        title: "DISCOUNT",
        dataIndex: "discount",
        key: "discount",
        render(price) {
            return price ? `₪${price}` : "";
        }
    },
    {
        title: "COUPON",
        dataIndex: "couponCode",
        width: 180
    },
    {
        title: "INTERNAL",
        dataIndex: "customSession",
        key: "custom",
        filters: [
            {
                text: "YES",
                value: true
            },
            {
                text: "NO",
                value: false
            }
        ],
        filterMultiple: false,
        width: 180,
        onFilter: (value, {customSession}) => !!customSession === value,
        render(customSession) {
            return customSession ? "YES (no charge)" : "NO";
        }
    },
    {
        title: "PRODUCT",
        dataIndex: "productName",
        key: "product",
        render(productName) {
            return productName || "-";
        }
    },
    {
        title: "START DATE",
        dataIndex: "dtstart",
        key: "startDate",
        render(dtstart, {appointmentBased}) {
            return (
                <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <label
                            style={{
                                color: "var(--secondary-color)",
                                fontSize: 14,
                                fontWeight: 500
                            }}>
                            {moment(dtstart).format("YYYY-MM-DD")}
                        </label>
                        {!appointmentBased ? (
                            <label
                                style={{
                                    color: "#747F92",
                                    fontSize: 12,
                                    fontWeight: 400,
                                    textAlign: "center"
                                }}>
                                {moment(dtstart).format("HH:mm")}
                            </label>
                        ) : null}
                    </div>
                </div>
            );
        }
    },
    {
        title: "LAST DATE",
        dataIndex: "until",
        key: "startDate",
        render(until) {
            return until ? (
                <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <label
                            style={{
                                color: "var(--secondary-color)",
                                fontSize: 14,
                                fontWeight: 500
                            }}>
                            {moment(until).format("YYYY-MM-DD")}
                        </label>
                    </div>
                </div>
            ) : (
                <span>-</span>
            );
        }
    },
    {
        title: "DURATION",
        dataIndex: "duration",
        key: "duration",
        render(duration, {appointmentBased}) {
            return appointmentBased ? "All day" : `${duration} minutes`;
        }
    },
    {
        title: "FREQUENCY",
        dataIndex: "freq",
        key: "frequency",
        width: 120,
        render(freq, {interval, dtstart, until}) {
            return until && moment(until).diff(moment(dtstart), "days") <= 7
                ? "Just Once"
                : freq === "weekly" && interval === 2
                ? "Every 2 Weeks"
                : `${_.capitalize(freq)}`;
        }
    },
    {
        title: "WEEK DAY",
        dataIndex: "byweekday",
        key: "weekDay",
        width: 100,
        render(byweekday) {
            return byweekday ? byweekday.map(dayKey => DaysMapper.mapKeyToDay(dayKey, "short")).join(", ") : "";
        }
    },
    {
        title: "WHO PAYS",
        dataIndex: "whoPays",
        key: "whoPays",
        width: 180,
        filters: [
            {
                text: "Company pays",
                value: "Company pays"
            },
            {
                text: "Employees pay",
                value: "Employees pay"
            }
        ],
        filterMultiple: false,
        onFilter: (value, {whoPays}) => (whoPays || "").indexOf(value) === 0,
        render(whoPays, {appointmentBased}) {
            if (!appointmentBased) {
                return "-";
            }
            return whoPays ? whoPays : "Company pays";
        }
    },
    {
        title: "CANCEL",
        key: "cancelEvent",
        width: 250,
        render(_, {eventId, status}) {
            return (
                <div style={{display: "flex"}}>
                    {status === "cancel-pending" ? (
                        <>
                            <Button
                                style={{backgroundColor: "#F06060", color: "white"}}
                                loading={rejectingId === eventId}
                                onClick={async () => await onReject(eventId)}>
                                Decline
                            </Button>
                            <Button
                                style={{marginLeft: 10, backgroundColor: "#13C296", color: "white"}}
                                loading={cancellingId === eventId}
                                onClick={async () => await onCancel(eventId, "Event cancellation request approved!")}>
                                Approve
                            </Button>
                        </>
                    ) : (
                        <Button
                            loading={cancellingId === eventId}
                            onClick={async () => await onCancel(eventId, "Event was cancelled successfully!")}>
                            Cancel
                        </Button>
                    )}
                </div>
            );
        }
    }
];

const AddNewTransactionModal = ({
    onClose,
    visible,
    saving,
    onSave,
    companies,
    services,
    onSelectService,
    providers,
    onSelectProvider,
    products
}) => {
    const [selectedProduct, setSelectedProduct] = useState(void 0);
    const [selectedProductIdx, setSelectedProductIdx] = useState(void 0);
    const [selectedService, setSelectedService] = useState(void 0);
    const [selectedServiceId, setSelectedServiceId] = useState(void 0);
    const [selectedProviderId, setSelectedProviderId] = useState(void 0);
    const [selectedProvider, setSelectedProvider] = useState(void 0);
    const [freq, setFreq] = useState(null);

    const tzid = moment.tz.guess();

    const employeesCanPay = selectedService ? selectedService.employeesCanPay : false;
    const appointmentBased = selectedService ? selectedService.appointmentBased : false;
    const location = selectedProduct ? selectedProduct.location : "online";
    const askedPrice =
        selectedService && selectedProduct && !appointmentBased ? selectedService.recommendedPrice : null;
    const offeredPrice = selectedProduct && !appointmentBased ? selectedProduct.price : null;
    const duration = selectedProduct ? selectedProduct.durationMinutes : null;

    const selectService = async serviceId => {
        try {
            const service = await HttpClient.get(`/api/services/${serviceId}`);
            setSelectedService(service);
            setSelectedProviderId(void 0);
            setSelectedProvider(void 0);
            setSelectedProduct(void 0);
            setSelectedProductIdx(void 0);
            setSelectedServiceId(serviceId);
            onSelectService(serviceId);
        } catch (e) {
            message.error(e.message, 5);
        }
    };

    const selectProvider = providerId => {
        setSelectedProduct(void 0);
        setSelectedProductIdx(void 0);
        setSelectedProviderId(providerId);
        setSelectedProvider(providers.find(p => p.providerId === providerId));
        onSelectProvider(providerId);
    };

    const selectProduct = index => {
        setSelectedProduct(products[index]);
        setSelectedProductIdx(index);
    };

    const save = async values => {
        let {companyId, byweekday, startDate, startTime, freq, whoPays, location, message} = values;

        const providerFirstName = selectedProvider.firstName;
        const providerLastName = selectedProvider.lastName || "";

        startDate = fixStartDateToStartOnTheDaySelected(startDate, byweekday);
        byweekday = [byweekday];

        const dtstart = !appointmentBased
            ? moment(`${startDate.format("YYYY-MM-DD")} ${startTime.format("HH:mm")}`, "YYYY-MM-DD HH:mm")
                  .toDate()
                  .getTime()
            : moment(`${startDate.format("YYYY-MM-DD")} ${SYSTEM_DEFAULT_START_TIME}`, "YYYY-MM-DD HH:mm")
                  .toDate()
                  .getTime();

        const info = FrequencyMapper.map({freq, interval: 1, byweekday, dtstart, tzid, duration: duration || 60 * 10});
        const until = info.until;
        byweekday = info.byweekday;
        const interval = info.interval;
        freq = info.freq;

        await onSave({
            companyId,
            serviceId: selectedServiceId,
            serviceName: selectedService.displayName,
            productName: selectedProduct ? selectedProduct.productName : null,
            freq,
            byweekday,
            dtstart,
            until,
            interval,
            tzid,
            duration: selectedProduct ? duration : null,
            whoPays,
            location,
            appointmentBased,
            providerId: selectedProviderId,
            providerFirstName,
            providerLastName,
            message,
            askedPrice,
            offeredPrice
        });

        setSelectedService(void 0);
        setSelectedProviderId(void 0);
        setSelectedProduct(void 0);
        setSelectedProductIdx(void 0);
    };

    return (
        <Modal
            destroyOnClose={true}
            className="wb-modal wb-transaction-modal"
            open={visible}
            onCancel={() => onClose()}
            style={{minWidth: 640, top: 10}}
            footer={null}>
            <PageTitleLabel>Create transaction</PageTitleLabel>
            <Form
                initialValues={{
                    startTime: moment(SYSTEM_DEFAULT_START_TIME, "HH:mm"),
                    whoPays: employeesCanPay ? void 0 : "Company pays"
                }}
                onFinish={async values => await save(values)}
                style={{padding: 20}}>
                <Form.Item name="companyId" rules={[{required: true, message: "Please select company."}]}>
                    <SearchableSelect placeholder="Select company">
                        {companies.map(({name, companyId}) => (
                            <Option key={companyId} value={companyId}>
                                {name}
                            </Option>
                        ))}
                    </SearchableSelect>
                </Form.Item>
                <Form.Item name="serviceId" rules={[{required: true, message: "Please select service."}]}>
                    <SearchableSelect
                        value={selectedServiceId}
                        onSelect={async serviceId => await selectService(serviceId)}
                        placeholder="Select service">
                        {services.map(({displayName, serviceId}) => (
                            <Option key={serviceId} value={serviceId}>
                                {displayName}
                            </Option>
                        ))}
                    </SearchableSelect>
                </Form.Item>
                <Form.Item name="providerId" rules={[{required: true, message: "Please select professional."}]}>
                    <SearchableSelect
                        value={selectedProviderId}
                        onSelect={providerId => selectProvider(providerId)}
                        placeholder="Select professional">
                        {providers.map(({firstName, lastName, providerId}) => (
                            <Option key={providerId} value={providerId}>{`${firstName}${
                                lastName ? ` ${lastName}` : ""
                            }`}</Option>
                        ))}
                    </SearchableSelect>
                </Form.Item>
                {!appointmentBased ? (
                    <Form.Item name="productIndex" rules={[{required: true, message: "Please select product."}]}>
                        <SearchableSelect
                            value={selectedProductIdx}
                            onSelect={index => selectProduct(index)}
                            placeholder="Select product">
                            {products.map(({productName}, index) => (
                                <Option key={`product-${index}`} value={index}>
                                    {productName}
                                </Option>
                            ))}
                        </SearchableSelect>
                    </Form.Item>
                ) : null}
                <OfferFields
                    tzid={tzid}
                    appointmentBased={appointmentBased}
                    location={location}
                    duration={duration}
                    askedPrice={askedPrice}
                    offeredPrice={offeredPrice}
                    employeesCanPay={employeesCanPay}
                    freq={freq}
                    onRepetitionChange={value => setFreq(value)}
                />
                <Form.Item noStyle>
                    <div style={{display: "flex", justifyContent: "center", marginTop: 30}}>
                        <CancelButton onClick={onClose} style={{border: "1px solid #1A344A", color: "#1A344A"}} />,
                        <SaveButton loading={saving} style={{marginLeft: 10, backgroundColor: "#1A344A"}} />
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export class AdminEvents extends React.Component {
    state = {
        term: "",
        transactions: null,
        showAddTransactionModal: false,
        saving: false,
        companies: [],
        services: [],
        providers: [],
        products: [],
        cancellingId: null,
        rejectingId: null,
        eventsShowing: optionsForResultsToShow[0]
    };

    async componentDidMount() {
        await this.fetchTransactions();
        await this.fetchAllServices();
        await this.fetchAllCompanies();
    }

    async fetchTransactions(amount) {
        try {
            const transactions = await HttpClient.get(`/admin/api/transactions/${amount || this.state.eventsShowing}`);
            this.setState({transactions});
        } catch (e) {
            message.error(e.message, 3);
        }
    }

    async fetchAllCompanies() {
        try {
            const companies = await HttpClient.get("/admin/api/companies");
            this.setState({companies});
        } catch (e) {
            message.error(e.message, 3);
        }
    }

    async fetchAllServices() {
        try {
            const services = await HttpClient.get("/api/categories");
            this.setState({services: services || []});
        } catch (e) {
            message.error(e.message, 3);
        }
    }

    async fetchProvidersOfService(serviceId) {
        try {
            const providers = await HttpClient.get(`/api/services/${serviceId}/providers`);
            this.setState({providers: providers || [], products: []});
        } catch (e) {
            message.error(e.message, 3);
        }
    }

    async fetchProductsOfProvider(providerId) {
        try {
            const providerDetails = await HttpClient.get(`/api/providers/${providerId}`);
            this.setState({products: providerDetails.services || []});
        } catch (e) {
            message.error(e.message, 3);
        }
    }

    async sendOffer(offer) {
        this.setState({saving: true});
        try {
            await HttpClient.put("/admin/api/transactions", offer);
            this.setState({providers: [], products: [], showAddTransactionModal: false, transactions: null});
            await this.fetchTransactions();
        } catch (e) {
            message.error(e.message, 5);
        }
        this.setState({saving: false});
    }

    filter(term) {
        this.setState({term});
    }

    async onCancel(eventId, msg) {
        this.setState({cancellingId: eventId});
        try {
            const transactions = await HttpClient.delete(`/admin/api/transactions/${eventId}/cancel`);
            message.success(msg);
            this.setState({transactions});
        } catch (e) {
            message.error(e.message, 3);
        }
        this.setState({cancellingId: null});
    }

    async onReject(eventId) {
        this.setState({rejectingId: eventId});
        try {
            const transactions = await HttpClient.post(`/admin/api/transactions/${eventId}/reject`, {});
            message.info("Event cancellation request declined!");
            this.setState({transactions});
        } catch (e) {
            message.error(e.message, 3);
        }
        this.setState({rejectingId: null});
    }

    render() {
        let {
            transactions,
            term,
            showAddTransactionModal,
            saving,
            companies,
            services,
            providers,
            products,
            cancellingId,
            rejectingId
        } = this.state;

        if (transactions) {
            transactions = transactions.filter(
                event =>
                    `${event.providerFirstName} ${event.providerLastName || ""} ${event.providerEmail}`
                        .toLowerCase()
                        .indexOf(term.toLowerCase()) >= 0 ||
                    `${event.companyName} ${event.companyEmail}`.toLowerCase().indexOf(term.toLowerCase()) >= 0
            );
        }

        return (
            <div style={{display: "flex", justifyContent: "center", margin: 10, marginBottom: 40}}>
                <div style={{display: "flex", flexDirection: "column", maxWidth: 2000, width: "100%"}}>
                    <AddNewTransactionModal
                        visible={showAddTransactionModal}
                        onClose={() => this.setState({showAddTransactionModal: false})}
                        companies={companies}
                        services={services}
                        providers={providers}
                        products={products}
                        onSelectService={async serviceId => await this.fetchProvidersOfService(serviceId)}
                        onSelectProvider={async providerId => await this.fetchProductsOfProvider(providerId)}
                        saving={saving}
                        onSave={async vals => await this.sendOffer(vals)}
                    />
                    <PageTitleHeader showBack={false}>Transactions</PageTitleHeader>
                    <div style={{display: "flex"}}>
                        <SearchInput
                            style={{marginBottom: 30, marginRight: 10, maxWidth: 500, width: "100%"}}
                            onChange={evt => this.filter(evt.target.value)}
                            placeholder="Search professional or company"
                        />
                        <div>
                            <label style={{marginRight: 5}}>Number of events showing:</label>
                            <Select
                                value={this.state.eventsShowing}
                                onSelect={newAmount => {
                                    this.setState({eventsShowing: newAmount || optionsForResultsToShow[0]});
                                    this.fetchTransactions(newAmount);
                                }}>
                                {optionsForResultsToShow.map(option => (
                                    <Option key={option} value={option}>
                                        {option}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <RoundedTable
                        title={() => (
                            <div style={{height: 30, display: "flex", alignItems: "center"}}>
                                <Button
                                    style={{fontSize: 16}}
                                    type="link"
                                    onClick={() => this.setState({showAddTransactionModal: true})}>
                                    + New transaction
                                </Button>
                            </div>
                        )}
                        rowKey="eventId"
                        scroll={{x: 2700, y: 600}}
                        pagination={false}
                        loading={transactions === null}
                        dataSource={transactions || []}
                        columns={columns(
                            async (eventId, message) => await this.onCancel(eventId, message),
                            cancellingId,
                            async eventId => await this.onReject(eventId),
                            rejectingId
                        )}
                    />
                </div>
            </div>
        );
    }
}
