import CallToActionModal from "../components/CallToActionModal";
import React, {useContext, useEffect, useState} from "react";
import {message, Typography} from "antd";
import {HttpClient} from "../http/HttpClient";
import {ModalTitle} from "../components/ModalTitle";
import {parse as qsParse} from "query-string";
import {EventCalendarSyncActions} from "./info/sync/EventCalendarSyncActions";
import moment from "moment-timezone";
import {EventBus} from "../bus/EventBus";
import {RRuleSetBuilder} from "./RRuleSetBuilder";
import {PageLoader} from "../components/PageLoader";
import {AppContext} from "../AppContext";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {capitalize} from "lodash";

const hasGoogleSync = me => {
    return (
        me.calendarApi &&
        me.calendarApi.type === "google" &&
        me.calendarApi.tokenObject &&
        me.calendarApi.tokenObject.scope &&
        me.calendarApi.tokenObject.scope.toLowerCase().indexOf("calendar.events") >= 0
    );
};

const hasOutlookSync = me => {
    return (
        me.calendarApi &&
        me.calendarApi.type === "microsoft" &&
        me.calendarApi.tokenObject &&
        me.calendarApi.tokenObject.scope &&
        me.calendarApi.tokenObject.scope.toLowerCase().indexOf("calendars.readwrite") >= 0
    );
};

export const hasCalendarSync = async me => {
    try {
        const {isTokenValid} = await HttpClient.get("/api/users/calendar/valid");
        return isTokenValid && (hasGoogleSync(me) || hasOutlookSync(me));
    } catch (e) {
        message.error(e.message, 3);
    }
};

export const EventRegisterModal = ({history, match}) => {
    const [event, setEvent] = useState(null);
    const [hasSync, setHasSync] = useState(false);
    const {eventId} = match.params;
    const date = qsParse(location.search).date;
    const {company} = useContext(AppContext);

    const companyAllowSync = () => {
        return (
            !company ||
            !company.disabledFeatures ||
            (company.disabledFeatures && !company.disabledFeatures.includes("sync"))
        );
    };

    const syncEvent = async (currentUser, currentEvent) => {
        const type = currentUser.calendarApi.type;

        try {
            const date = toDateMom(currentEvent).format("YYYY-MM-DD");
            const time = moment(currentEvent.dtstart).format("HH:mm");

            await HttpClient.post(`/api/events/${eventId}/sync/${type}?date=${date}&time=${time}`);
            message.success("Successfully synced event to your calendar.", 3);
            GoogleAnalytics.event("Calendar Sync", `${capitalize(type)} Event Sync`, eventId);
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const applyType = "only";
                const event = await HttpClient.post(
                    `/api/events/${eventId}/register?applyType=${applyType}&date=${date}`,
                    {}
                );
                setEvent(event);
                const currentUser = await HttpClient.get("/api/users/me", true);
                const calSync = await hasCalendarSync(currentUser);
                setHasSync(calSync);
                if (calSync) {
                    await syncEvent(currentUser, event);
                }
            } catch (e) {
                if (e.statusCode === 400) {
                    EventBus.triggerError(
                        "server-error",
                        {
                            content: {
                                description: e.message,
                                hideSteps: true,
                                hideSubTitle: true,
                                title: "Ohh..."
                            },
                            cta: {hide: true}
                        },
                        e.message
                    );
                } else {
                    EventBus.triggerError(
                        "server-error",
                        {content: {description: "Unfortunately we couldn't complete your registration :("}},
                        e.message
                    );
                }
            }
        })();
    }, []);

    const toDateMom = currentEvent => {
        const rruleSet = RRuleSetBuilder.build(currentEvent);
        const actualDate = rruleSet.after(moment(date, "YYYY-MM-DD").toDate(), true);
        if (!actualDate) {
            const eventStartTime = moment(currentEvent.dtstart).format("HH:mm");
            return moment(`${date} ${eventStartTime}`);
        }
        return moment(actualDate);
    };

    if (!event) {
        return <PageLoader align="center" top={60} height={200} />;
    }

    const {serviceName, sessionName, providerFirstName, providerLastName, sessionType} = event;

    const Title = () => <ModalTitle title="Awesome!" subTitle="We reserved a seat for you!" />;

    const SubTitle = () => (
        <div style={{display: "flex", flexDirection: "column"}}>
            <Typography.Text style={{textAlign: "center", fontSize: 20, marginBottom: 10, color: "#1260D1"}}>
                {serviceName || sessionName} {sessionType === "company" ? "by" : "with"} {providerFirstName}
                {providerLastName ? ` ${providerLastName}` : ""}
            </Typography.Text>
            <Typography.Text style={{textAlign: "center", fontSize: 20, marginBottom: 10}}>
                What's next?
            </Typography.Text>
            <Typography.Paragraph>
                <ul>
                    <li>You will get a notification 10 minutes before the session starts.</li>
                    <li>You can check out your company's calendar for this session and more.</li>
                </ul>
            </Typography.Paragraph>
            {date && !hasSync && companyAllowSync() ? (
                <EventCalendarSyncActions
                    applyType={"only"}
                    dateMom={toDateMom(event)}
                    eventId={eventId}
                    style={{marginBottom: 20}}
                    event={event}
                />
            ) : null}
        </div>
    );

    return (
        <CallToActionModal
            imageName="success3"
            title={<Title />}
            subTitle={<SubTitle />}
            subTitleStyle={{textAlign: "left"}}
            actionText="Go to calendar"
            actionHandler={() => history.push("/dashboard/schedule")}
            actionStyle={{width: 260}}
            visible={true}
        />
    );
};
