export class YouTubeLinkGenerator {
    static embedFromWatchLink(watchLink) {
        return `https://www.youtube.com/embed/${this._extractYoutubeHash(watchLink)}`;
    }

    static thumbnailFromWatchLink(watchLink) {
        return `https://img.youtube.com/vi/${this._extractYoutubeHash(watchLink)}/mqdefault.jpg`;
    }

    static _extractYoutubeHash(watchLink) {
        const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = watchLink.match(regExp);
        if (match && match[2].length === 11) {
            return match[2];
        } else {
            return null;
        }
    }
}
