import React, {useContext, useMemo} from "react";
import {Collapse} from "antd";
import {flatten} from "lodash";
import {MarketplaceContext} from "../marketplaceContext";

const {Panel} = Collapse;

export const PopularQuestionsPanel = ({key, ...props}) => {
    const {product, productBundle, isProductBundle} = useContext(MarketplaceContext);

    const popularQuestions = useMemo(
        () =>
            isProductBundle
                ? flatten(productBundle?.products?.map(product => product?.popularQuestions))
                : product?.popularQuestions,
        [isProductBundle, productBundle, product?.popularQuestions]
    );

    return popularQuestions?.length ? (
        <Panel {...props} key={key} showArrow={true} header={<div className="panel-header-title">שאלות ותשובות</div>}>
            <div style={{direction: "rtl"}} className="popular-questions-product-container">
                {popularQuestions.map(({question, answer}, index) => (
                    <Collapse key={index} ghost={true} accordion={true} className="popular-questions-product-collapse">
                        <Panel key={index} showArrow={true} header={<div>{question}</div>}>
                            <div>{answer}</div>
                        </Panel>
                    </Collapse>
                ))}
            </div>
        </Panel>
    ) : null;
};
