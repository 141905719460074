import {Modal} from "antd";
import React from "react";
import {CompanyContentTagsList} from "./CompanyContentTagsList";

export const CompanyContentTagsModal = ({sectionId, category, onClose, visible}) => {
    return (
        <Modal
            centered={true}
            width={340}
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            bodyStyle={{padding: 0}}
            footer={null}
            title={null}
            closable={false}>
            {visible ? <CompanyContentTagsList sectionId={sectionId} category={category} onClose={onClose} /> : null}
        </Modal>
    );
};
