import React from "react";
import {PageSubTitleLabel, PageTitleLabel} from "./PageTitle";
import {StyledButton} from "./StyledButton";
import {Modal, Tooltip} from "antd";
import {withRouter} from "react-router";
import {Images} from "../images/Images";
import {CloseOutlined} from "@ant-design/icons";

class CallToActionModal extends React.Component {
    render() {
        const {
            history,
            visible,
            imageName,
            title,
            subTitle,
            actionBackgroundColor,
            actionBorderColor,
            actionColor,
            actionText,
            actionHandler,
            actionExtraIcon,
            actionLoading,
            anotherActionText,
            anotherActionHandler,
            anotherActionExtraIcon,
            anotherActionBackgroundColor,
            anotherActionBorderColor,
            anotherActionColor,
            tooltipText,
            anotherTooltipText,
            anotherActionLoading,
            closable = false,
            onClose,
            bodyStyle,
            modalStyle,
            subTitleStyle,
            actionStyle,
            anotherActionStyle,
            actionsContainerStyle
        } = this.props;

        return (
            <Modal
                className="wb-modal"
                closable={closable}
                onCancel={onClose ? () => onClose() : null}
                bodyStyle={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 0,
                    ...bodyStyle
                }}
                style={modalStyle}
                open={visible}
                footer={null}
                destroyOnClose={true}
                closeIcon={<CloseOutlined style={{color: "#637381"}} />}>
                {imageName ? (
                    <div
                        style={{
                            backgroundImage: `url(${Images[imageName]})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            border: "none",
                            width: 600,
                            height: 200,
                            marginBottom: 10,
                            borderRadius: 30
                        }}
                    />
                ) : null}
                <PageTitleLabel>{title || "Thank You!"}</PageTitleLabel>
                <PageSubTitleLabel
                    style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        marginBottom: 0,
                        ...subTitleStyle
                    }}>
                    {subTitle}
                </PageSubTitleLabel>
                {anotherActionText ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: 20,
                            ...actionsContainerStyle
                        }}>
                        <Tooltip visible={!!anotherTooltipText} title={anotherTooltipText}>
                            <StyledButton
                                loading={anotherActionLoading}
                                style={{
                                    width: 160,
                                    backgroundColor: anotherActionBackgroundColor || "white !important",
                                    color: anotherActionColor || "#2F3E83",
                                    border: `1px solid ${anotherActionBorderColor || "#2F3E83"} !important`,
                                    borderRadius: 25,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    ...anotherActionStyle
                                }}
                                onClick={() => anotherActionHandler()}>
                                {anotherActionExtraIcon}
                                {anotherActionText}
                            </StyledButton>
                        </Tooltip>
                        <Tooltip visible={!!tooltipText} title={tooltipText}>
                            <StyledButton
                                loading={actionLoading}
                                style={{
                                    width: 160,
                                    backgroundColor: actionBackgroundColor || "#2F3E83 !important",
                                    color: actionColor || "white",
                                    borderRadius: 25,
                                    border: actionBorderColor ? `1px solid ${actionBorderColor}` : null,
                                    marginLeft: 10,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    ...actionStyle
                                }}
                                onClick={() => (actionHandler ? actionHandler() : history.push("/dashboard"))}>
                                {actionExtraIcon}
                                {actionText}
                            </StyledButton>
                        </Tooltip>
                    </div>
                ) : actionText ? (
                    <div style={actionsContainerStyle}>
                        <Tooltip visible={!!tooltipText} title={tooltipText}>
                            <StyledButton
                                disableld={actionLoading}
                                loading={actionLoading}
                                style={{
                                    width: 240,
                                    backgroundColor: actionBackgroundColor || "#2F3E83 !important",
                                    color: actionColor || "white",
                                    border: actionBorderColor ? `1px solid ${actionBorderColor}` : null,
                                    borderRadius: 25,
                                    marginBottom: 20,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    ...actionStyle
                                }}
                                onClick={() => (actionHandler ? actionHandler() : history.push("/dashboard"))}>
                                {actionExtraIcon}
                                {actionText}
                            </StyledButton>
                        </Tooltip>
                    </div>
                ) : null}
            </Modal>
        );
    }
}

export default withRouter(CallToActionModal);
