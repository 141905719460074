import React from "react";
import {set} from "lodash";
import {
    ACTIVITIES_IDS_TO_NAMES,
    FilterOptionIdToName,
    kosherTypes,
    kosherTypesToHebrew
} from "../../ProductsFilterModal/consts.jsx";
import "../../marketplace-category.css";
import {kosherTab} from "../popoverFilters/accuracyResultsFilter/consts.js";
import {getBudgetSectionTitle} from "../popoverFilters/budgetFilter/utils.js";
import {getLocationFilterInnerState, getLocationTitle} from "../popoverFilters/locationFilter/utils.js";

export const filterOptionsList = (
    filterValues,
    removeSearchInput,
    removeExtraSearchInput,
    setFilterValues,
    updateLocalFilterState,
    innerState,
    removeKeyFromInnerState,
    category
) => {
    const activeFilters = [];

    if (filterValues.searchText) {
        activeFilters.push({
            id: "searchText",
            filter: filterValues.searchText,
            title: <span>חיפוש טקסט: {filterValues.searchText}</span>,
            onClick: () => removeSearchInput()
        });
    }

    if (filterValues.extraSearchTexts?.length) {
        activeFilters.push(
            ...filterValues.extraSearchTexts.map(searchText => ({
                id: `extraSearchTexts-${searchText}`,
                filter: searchText,
                title: <span>חיפוש טקסט: {searchText}</span>,
                onClick: () => removeExtraSearchInput(searchText)
            }))
        );
    }

    const address = getLocationTitle(innerState?.locationFilter);
    if (address) {
        activeFilters.push({
            id: `deliveryAddress`,
            filter: filterValues.deliveryAddress,
            title: <span>{address}</span>,
            onClick: () => {
                setFilterValues(({deliveryAddress, ...otherFilters}) => {
                    updateLocalFilterState(otherFilters);
                    return otherFilters;
                });
            }
        });
    }

    const budgetTitle = getBudgetSectionTitle(innerState.budget, category);
    if (budgetTitle) {
        activeFilters.push({
            id: "budget",
            title: <span>{budgetTitle}</span>,
            filter: filterValues.budget,
            onClick: () => {
                setFilterValues(({budget, ...otherFilters}) => {
                    updateLocalFilterState(otherFilters);
                    return otherFilters;
                });
            }
        });
    }

    if (filterValues.productProperties?.length) {
        activeFilters.push(
            ...filterValues.productProperties.map(property => ({
                id: `productProperties-${property}`,
                filter: property,
                title: <span>{FilterOptionIdToName[property]}</span>,
                onClick: () => {
                    const newProperties = filterValues.productProperties.filter(
                        productProperty => productProperty !== property
                    );

                    if (!newProperties.length) {
                        setFilterValues(currentFilters => {
                            const {productProperties, ...otherFilters} = currentFilters;
                            updateLocalFilterState(otherFilters);
                            return otherFilters;
                        });
                    } else {
                        setFilterValues(currentFilters => {
                            const values = set({...currentFilters}, "productProperties", newProperties);
                            updateLocalFilterState(values);
                            return values;
                        });
                    }
                }
            }))
        );
    }

    if (innerState?.accuracyResults?.kosherTypesKey === kosherTab.anyKosher) {
        activeFilters.push({
            id: `kosherTypes-${kosherTab.anyKosher}`,
            title: <span>כל כשרות תספיק לי</span>,
            onClick: () => {
                removeKeyFromInnerState("accuracyResults.kosherTypesKey");
                setFilterValues(currentFilters => {
                    const {kosherTypes, ...otherFilters} = currentFilters;
                    updateLocalFilterState(otherFilters);
                    return otherFilters;
                });
            }
        });
    } else if (filterValues?.kosherTypes?.length) {
        activeFilters.push(
            ...filterValues.kosherTypes.map(property => ({
                id: `kosherTypes-${property}`,
                filter: property,
                title: <span>{kosherTypesToHebrew[property]}</span>,
                onClick: () => {
                    const newProperties = filterValues.kosherTypes.filter(
                        productProperty => productProperty !== property
                    );

                    if (!newProperties.length) {
                        setFilterValues(currentFilters => {
                            const {kosherTypes, ...otherFilters} = currentFilters;
                            updateLocalFilterState(otherFilters);
                            return otherFilters;
                        });
                    } else {
                        setFilterValues(currentFilters => {
                            const values = set({...currentFilters}, "kosherTypes", newProperties);
                            updateLocalFilterState(values);
                            return values;
                        });
                    }
                }
            }))
        );
    }

    if (filterValues.activityRequestsProperties?.length) {
        activeFilters.push(
            ...filterValues.activityRequestsProperties.map(property => ({
                id: `activityRequestsProperties-${property}`,
                filter: property,
                title: <span>{ACTIVITIES_IDS_TO_NAMES[property]}</span>,
                onClick: () => {
                    const newProperties = filterValues.activityRequestsProperties.filter(
                        productProperty => productProperty !== property
                    );

                    if (!newProperties.length) {
                        setFilterValues(({activityRequestsProperties, ...otherFilters}) => {
                            updateLocalFilterState(otherFilters);
                            return otherFilters;
                        });
                    } else {
                        setFilterValues(currentFilters => {
                            const values = set({...currentFilters}, "activityRequestsProperties", newProperties);
                            updateLocalFilterState(values);
                            return values;
                        });
                    }
                }
            }))
        );
    }
    return activeFilters;
};
