import React, {useCallback, useMemo} from "react";
import moment from "moment";
import {isObject} from "lodash";
import {ConfigProvider, Modal, Spin, Timeline} from "antd";
import {useRequest} from "../../utils/hooks";
import {getOrderPrice} from "../../utils/calculationUtils";
import {Images} from "../../images/Images";
import {statusToTextAndColor} from "../../components";
import "./order-changes-history-modal.css";
import {StringBuilder} from "../../AppUtils.js";

export const OrderChangesHistoryModal = ({visible, onClose, order}) => {
    const [orderChangesLogs, loadingLogs] = useRequest(
        `/admin/api/orderChangeLog/${order?.eventId}`,
        "get",
        null,
        [],
        !!order?.eventId
    );

    const extractFieldValues = useCallback((field, withFieldName = true) => {
        let extractedValues;
        const values = [field.oldValue, field.newValue];
        switch (field.fieldId) {
            case "adminComment":
                extractedValues = values.map(val => val?.content);
                break;
            case "status":
                extractedValues = values.map(val => statusToTextAndColor[val]?.[0] || JSON.stringify(val));
                break;
            case "dtstart":
                extractedValues = values.map(val => moment(val).format("HH:mm DD/MM/YYYY"));
                break;
            case "companyRequests":
                extractedValues = values.map(val => val?.map(({title, amount}) => `${title} (${amount})`).join(", "));
                break;
            case "extraContactInfo":
                extractedValues = values.map(val => (val ? `${val.contactName} (${val.contactPhone})` : null));
                break;
            case "modularProducts":
                extractedValues = values.map(val => (val ? val.map(({name, amount}) => `${name}(${amount})`) : null));
                break;
            case "employeesExcel":
                extractedValues = values.map(val => val?.fileName ?? null);
                break;
            case "assignedCSMs":
                extractedValues = values.map(val => (val ? val.map(({firstName}) => firstName).join(", ") : null));
                break;
            case "shippingProviders":
                extractedValues = values.map(val => (val ? val.map(({name}) => name).join(", ") : null));
                break;
            case "shippingDetails":
                extractedValues = values.map(val => val?.task_id);
                break;
            default:
                extractedValues = values.map(val => (isObject(val) ? JSON.stringify(val) : val));
                break;
        }

        return (
            <div>
                {withFieldName ? `${field.fieldName}: ` : null}
                {<span className="order-changes-history-modal-old-value">{extractedValues[0] ?? "ללא ערך"}</span>}
                שונה ל-
                {<span className="order-changes-history-modal-new-value">{extractedValues[1] ?? "ללא ערך"}</span>}
            </div>
        );
    }, []);

    const timeLines = useMemo(() => {
        if (!order) {
            return null;
        }

        const getIcon = log => {
            if (!log.priceDifference) {
                return <img src={Images.yellowCircle} alt="natural" />;
            }

            return log.priceDifference > 0 ? (
                <img src={Images.companyBudgetHistoryPlusIcon} alt="plus" />
            ) : (
                <img src={Images.companyBudgetHistoryMinusIcon} alt="minus" />
            );
        };

        let price = getOrderPrice(order);
        return orderChangesLogs?.map(log => {
            if (!log.changedFields?.length) {
                return null;
            }

            const multipleFieldsChanged = log.changedFields?.length > 1;
            const logTitle = multipleFieldsChanged ? "עריכת הזמנה" : log.changedFields[0].fieldName;
            const timeLine = (
                <Timeline.Item key={log.logId} dot={getIcon(log)}>
                    <div className="budget-history-timeline-item">
                        <span>
                            <u>{logTitle}</u>
                            {` | ${moment(log.createdAt).format("DD.MM.YY HH:mm")} | ${log.editor.email}`}
                        </span>
                        <span>{price} ₪</span>
                    </div>
                    <div className="order-changes-history-modal-log-body">
                        {multipleFieldsChanged
                            ? log.changedFields.map(changedField => (
                                  <div key={changedField.fieldId}>{extractFieldValues(changedField)}</div>
                              ))
                            : extractFieldValues(log.changedFields[0], false)}
                    </div>
                </Timeline.Item>
            );

            price -= log.priceDifference ?? 0;
            return timeLine;
        });
    }, [order, orderChangesLogs]);

    return (
        <Modal
            centered={true}
            width={640}
            className="wb-modal-radius"
            style={{borderRadius: "5px"}}
            destroyOnClose={true}
            open={visible}
            onCancel={onClose}
            footer={null}
            title={null}>
            <div className="order-changes-history-modal">
                <div className="order-changes-history-modal-title">
                    {new StringBuilder("היסטוריית הזמנה:")
                        .append(order?.proposalId)
                        .append(order?.productName)
                        .toString()}
                </div>
                <div className="simple-modal-break-line" />
                <ConfigProvider direction="rtl">
                    {loadingLogs ? (
                        <Spin size="large" />
                    ) : timeLines?.length ? (
                        <Timeline>{timeLines}</Timeline>
                    ) : (
                        <div>
                            <i>"הזמנה שאין לה עבר, אין לה הווה ועתידה לוט בערפל"</i>
                            <div>להודעה זאת אין עבר</div>
                        </div>
                    )}
                </ConfigProvider>
            </div>
        </Modal>
    );
};
