import React, {useCallback, useContext, useMemo} from "react";
import {Button, ConfigProvider, Segmented} from "antd";
import "./advanced-filter.css";
import {MarketplaceFilterProviderContext} from "../MarketplaceFilterContext.jsx";
import {useHistory} from "react-router";
import {filterOptions} from "./filter-options.jsx";
import {buildFilters} from "./utils.js";
import classnames from "classnames";
import {getLogger} from "../../../Logger.jsx";
import {FILTER_VERSION} from "../consts.js";

export const AdvancedFilter = () => {
    const history = useHistory();
    const {panel, resetState, addressName, onSearch, innerState} = useContext(MarketplaceFilterProviderContext);

    const log = useMemo(() => getLogger({version: FILTER_VERSION}, "AdvancedFilter"), []);

    const onReset = useCallback(() => {
        resetState(history);
    }, [history, resetState]);

    const onSearchClick = useCallback(async () => {
        const filters = buildFilters(innerState, panel);
        onSearch(history, filters);
        log("Filter Search", {mainButton: true});
    }, [innerState, history, panel, log, onSearch]);

    const isCleanable = useMemo(() => Object.keys(buildFilters(innerState)).length > 0, [innerState]);

    return (
        <div className="advanced-filter-container">
            <ConfigProvider direction="rlt">
                <Segmented
                    value={null}
                    size="large"
                    id="advanced-filter"
                    options={filterOptions(panel, addressName)}
                />

                <Button className="show-result-button" onClick={onSearchClick}>
                    הצג אפשרויות
                </Button>
                <a
                    className={classnames("clean-filter-btn", {
                        cleanAble: isCleanable
                    })}
                    href="#"
                    onClick={onReset}>
                    ניקוי חיפוש
                </a>
            </ConfigProvider>
        </div>
    );
};
