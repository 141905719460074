import React from "react";

const calcFillColor = (initialIndex, activeIndex) => {
    if (activeIndex === initialIndex) {
        return "#FFCC00";
    } else if (activeIndex <= 0) {
        return "rgba(255, 204, 0, 0.4)";
    } else {
        return "#DAE0E4";
    }
};

const RatingLabel = ({children}) => <label style={{color: "#C1CBD3", fontSize: 14, marginTop: 5}}>{children}</label>;

const RatingContainer = ({children, onClick, style}) => (
    <span
        onClick={onClick}
        style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", ...style}}
        className="wb-rating-icon">
        {children}
    </span>
);

export const Rating_1 = ({style = {}, onSelect, activeIndex = -1}) => (
    <RatingContainer onClick={() => onSelect(1)}>
        <svg
            width="50px"
            height="50px"
            viewBox="0 0 50 50"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Admin" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Reviews-1" transform="translate(-239.000000, -1012.000000)">
                    <g id="Group-60" transform="translate(239.000000, 1012.000000)">
                        <circle
                            id="Oval"
                            fill={calcFillColor(1, activeIndex)}
                            cx="24.996519"
                            cy="24.996519"
                            r="24.996519"
                        />
                        <circle
                            id="Oval"
                            fill="#333333"
                            fillRule="nonzero"
                            cx="17.3985232"
                            cy="20.3233122"
                            r="3.9257384"
                        />
                        <circle
                            id="Oval"
                            fill="#333333"
                            fillRule="nonzero"
                            cx="32.2433544"
                            cy="20.3233122"
                            r="3.9257384"
                        />
                        <path
                            d="M33.571519,10.6866034 C30.5469409,12.0270042 27.5219409,13.367827 24.4972574,14.7082278 C24.8292194,14.7082278 25.1614979,14.7082278 25.4931435,14.7082278 C22.4685654,13.367827 19.4443038,12.0270042 16.4197257,10.6866034 C15.2671941,10.1758439 14.2622363,11.8758439 15.4238397,12.3905063 C17.0555907,13.1136076 18.6868143,13.8371308 20.3181435,14.5602321 C21.3652954,15.0243671 22.4128692,15.4886076 23.4600211,15.9527426 C23.8855485,16.1414557 24.5107595,16.5471519 24.9954641,16.5471519 C25.4801688,16.5471519 26.1053797,16.1413502 26.5309072,15.9527426 C27.5780591,15.4886076 28.6256329,15.0243671 29.6731013,14.5602321 C31.3048523,13.8371308 32.9364979,13.1136076 34.567827,12.3905063 C35.7285865,11.8758439 34.7236287,10.1758439 33.571519,10.6866034 L33.571519,10.6866034 Z"
                            id="Path"
                            fill="#333333"
                            fillRule="nonzero"
                        />
                        <path
                            d="M11.1017932,34.478481 C20.214346,34.478481 29.3272152,34.478481 38.4397679,34.478481 C39.7126582,34.478481 39.7126582,32.5049578 38.4397679,32.5049578 C29.3272152,32.5049578 20.214346,32.5049578 11.1017932,32.5049578 C9.82890295,32.5049578 9.82890295,34.478481 11.1017932,34.478481 L11.1017932,34.478481 Z"
                            id="Path"
                            fill="#333333"
                            fillRule="nonzero"
                        />
                    </g>
                </g>
            </g>
        </svg>
        <RatingLabel>Horrible</RatingLabel>
    </RatingContainer>
);

export const Rating_2 = ({style = {}, onSelect, activeIndex = -1}) => (
    <RatingContainer onClick={() => onSelect(2)}>
        <svg
            width="50px"
            height="50px"
            viewBox="0 0 50 50"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Admin" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Reviews-1" transform="translate(-309.000000, -1012.000000)">
                    <g id="Group-61" transform="translate(309.000000, 1012.000000)">
                        <circle
                            id="Oval"
                            fill={calcFillColor(2, activeIndex)}
                            cx="24.996519"
                            cy="24.996519"
                            r="24.996519"
                        />
                        <circle
                            id="Oval"
                            fill="#333333"
                            fillRule="nonzero"
                            cx="17.3985232"
                            cy="21.9021097"
                            r="3.9257384"
                        />
                        <ellipse
                            id="Oval"
                            fill="#333333"
                            fillRule="nonzero"
                            cx="33.0840717"
                            cy="19.8654008"
                            rx="3.9257384"
                            ry="5.9600211"
                        />
                        <path
                            d="M20.6063291,10.835443 C19.2924051,12.3724684 17.9787975,13.9089662 16.6648734,15.4459916 C17.1302743,15.4459916 17.5948312,15.4459916 18.0601266,15.4459916 C15.4081224,13.3177215 12.7561181,11.189557 10.1041139,9.06128692 C9.12204641,8.27310127 7.7157173,9.6600211 8.70886076,10.4565401 C11.360865,12.5848101 14.0128692,14.7129747 16.6648734,16.8412447 C17.057173,17.1562236 17.6836498,17.2817511 18.0601266,16.8412447 C19.3740506,15.3042194 20.6876582,13.7677215 22.0015823,12.2306962 C22.8237342,11.2691983 21.4348101,9.86635021 20.6063291,10.835443 L20.6063291,10.835443 Z"
                            id="Path"
                            fill="#333333"
                            fillRule="nonzero"
                        />
                        <path
                            d="M29.5814346,13.1589662 C30.8953586,11.6219409 32.2093882,10.085443 33.5229958,8.54841772 C33.0575949,8.54841772 32.593038,8.54841772 32.1277426,8.54841772 C34.7797468,10.6766878 37.4317511,12.8048523 40.0837553,14.9331224 C41.0658228,15.721308 42.4721519,14.3343882 41.4790084,13.5378692 C38.8270042,11.4095992 36.175,9.2814346 33.5229958,7.15316456 C33.1306962,6.83818565 32.5042194,6.71265823 32.1277426,7.15316456 C30.8138186,8.69018987 29.499789,10.2266878 28.1861814,11.7637131 C27.3640295,12.725211 28.7533755,14.1280591 29.5814346,13.1589662 L29.5814346,13.1589662 Z"
                            id="Path"
                            fill="#333333"
                            fillRule="nonzero"
                        />
                        <path
                            d="M18.8245781,40.7937764 C22.7886076,40.7937764 26.7526371,40.7937764 30.7165612,40.7937764 C31.9894515,40.7937764 31.9894515,38.8202532 30.7165612,38.8202532 C26.7525316,38.8202532 22.7885021,38.8202532 18.8245781,38.8202532 C17.5521097,38.8202532 17.5521097,40.7937764 18.8245781,40.7937764 L18.8245781,40.7937764 Z"
                            id="Path"
                            fill="#333333"
                            fill-rule="nonzero"
                        />
                    </g>
                </g>
            </g>
        </svg>
        <RatingLabel>Bad</RatingLabel>
    </RatingContainer>
);

export const Rating_3 = ({style = {}, onSelect, activeIndex = -1}) => (
    <RatingContainer onClick={() => onSelect(3)}>
        <svg
            width="50px"
            height="50px"
            viewBox="0 0 50 50"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Admin" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Reviews-1" transform="translate(-379.000000, -1012.000000)">
                    <g id="Group-62" transform="translate(379.000000, 1012.000000)">
                        <circle
                            id="Oval"
                            fill={calcFillColor(3, activeIndex)}
                            cx="24.9964135"
                            cy="24.9964135"
                            r="24.9964135"
                        />
                        <circle
                            id="Oval"
                            fill="#333333"
                            fillRule="nonzero"
                            cx="17.3984177"
                            cy="16.1389241"
                            r="3.9257384"
                        />
                        <circle
                            id="Oval"
                            fill="#333333"
                            fillRule="nonzero"
                            cx="32.243038"
                            cy="16.1389241"
                            r="3.9257384"
                        />
                        <path
                            d="M24.9948312,38.257173 C20.632173,38.1731013 16.7258439,36.4644515 13.692616,33.3297468 C12.8065401,32.4136076 11.4124473,33.810865 12.2973629,34.725 C15.685443,38.2267932 20.1262658,40.1371308 24.9948312,40.2306962 C26.2684599,40.2550633 26.2657173,38.2812236 24.9948312,38.257173 L24.9948312,38.257173 Z"
                            id="Path"
                            fill="#333333"
                            fillRule="nonzero"
                        />
                        <path
                            d="M12.6899789,11.1755274 C14.796097,9.40770042 17.2160338,8.26772152 19.903903,7.71086498 C21.1495781,7.45274262 20.6206751,5.55031646 19.378903,5.80759494 C16.3491561,6.43554852 13.6597046,7.79451477 11.2946203,9.78027426 C10.3186709,10.5992616 11.7225738,11.9881857 12.6899789,11.1755274 L12.6899789,11.1755274 Z"
                            id="Path"
                            fill="#333333"
                            fillRule="nonzero"
                        />
                        <path
                            d="M30.7045359,7.18902954 C33.3280591,8.01118143 35.6011603,9.41751055 37.5139241,11.3910338 C38.4008439,12.3063291 39.7949367,10.9094937 38.9091772,9.99578059 C36.7636076,7.78185654 34.1687764,6.20706751 31.2295359,5.28544304 C30.0113924,4.90411392 29.4944093,6.80938819 30.7045359,7.18902954 L30.7045359,7.18902954 Z"
                            id="Path"
                            fill="#333333"
                            fillRule="nonzero"
                        />
                    </g>
                </g>
            </g>
        </svg>
        <RatingLabel>Meh</RatingLabel>
    </RatingContainer>
);

export const Rating_4 = ({style = {}, onSelect, activeIndex = -1}) => (
    <RatingContainer onClick={() => onSelect(4)}>
        <svg
            width="50px"
            height="50px"
            viewBox="0 0 50 50"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Admin" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Reviews-1" transform="translate(-449.000000, -1012.000000)">
                    <g id="Group-63" transform="translate(449.000000, 1012.000000)">
                        <circle
                            id="Oval"
                            fill={calcFillColor(4, activeIndex)}
                            cx="24.996519"
                            cy="24.996519"
                            r="24.996519"
                        />
                        <circle
                            id="Oval"
                            fill="#333333"
                            fillRule="nonzero"
                            cx="17.3985232"
                            cy="16.3746835"
                            r="3.9257384"
                        />
                        <circle
                            id="Oval"
                            fill="#333333"
                            fillRule="nonzero"
                            cx="32.2433544"
                            cy="16.3746835"
                            r="3.9257384"
                        />
                        <path
                            d="M36.2085443,33.4242616 C29.9552743,39.7880802 19.8938819,39.7379747 13.6927215,33.3295359 C12.8066456,32.4133966 11.4125527,33.810654 12.2974684,34.724789 C19.2351266,41.8946203 30.6144515,41.9336498 37.603903,34.8195148 C38.4962025,33.9112869 37.1013713,32.5152954 36.2085443,33.4242616 L36.2085443,33.4242616 Z"
                            id="Path"
                            fill="#333333"
                            fillRule="nonzero"
                        />
                    </g>
                </g>
            </g>
        </svg>
        <RatingLabel>Good</RatingLabel>
    </RatingContainer>
);

export const Rating_5 = ({style = {}, onSelect, activeIndex = -1}) => (
    <RatingContainer onClick={() => onSelect(5)}>
        <svg
            width="50px"
            height="50px"
            viewBox="0 0 50 50"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Admin" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Reviews-1" transform="translate(-519.000000, -1012.000000)">
                    <g id="Group-64" transform="translate(519.000000, 1012.000000)">
                        <circle
                            id="Oval"
                            fill={calcFillColor(5, activeIndex)}
                            cx="24.9964135"
                            cy="24.9964135"
                            r="24.9964135"
                        />
                        <path
                            d="M8.58554852,25.0129747 C8.58554852,34.0753165 15.9324895,41.4222574 24.9948312,41.4222574 C34.0575949,41.4222574 41.4045359,34.0753165 41.4045359,25.0129747 L8.58554852,25.0129747 L8.58554852,25.0129747 Z"
                            id="Path"
                            fill="#FFFFFF"
                            fillRule="nonzero"
                        />
                        <path
                            d="M17.692827,25.0129747 C17.692827,29.9111814 17.692827,34.8093882 17.692827,39.7077004 C18.32827,40.0242616 18.9874473,40.2993671 19.6663502,40.5325949 C19.6663502,35.3592827 19.6663502,30.1862869 19.6663502,25.0129747 L17.692827,25.0129747 Z"
                            id="Path"
                            fill="#CCCBCB"
                            fillRule="nonzero"
                        />
                        <path
                            d="M29.8859705,25.0129747 C29.8859705,30.235654 29.8859705,35.4579114 29.8859705,40.6805907 C30.5644515,40.4689873 31.2220464,40.210865 31.8594937,39.9168776 C31.8594937,34.9487342 31.8594937,29.9811181 31.8594937,25.0129747 L29.8859705,25.0129747 L29.8859705,25.0129747 Z"
                            id="Path"
                            fill="#CCCBCB"
                            fillRule="nonzero"
                        />
                        <path
                            d="M23.1203586,16.7503165 C22.9427215,13.4533755 20.589557,11.1005274 17.293038,10.9229958 C14.0035865,10.7457806 11.628692,13.7206751 11.4657173,16.7503165 C11.3970464,18.0227848 13.3708861,18.0161392 13.4392405,16.7503165 C13.7068565,11.7830169 20.8797468,11.7830169 21.1469409,16.7503165 C21.2151899,18.0161392 23.1890295,18.0227848 23.1203586,16.7503165 L23.1203586,16.7503165 Z"
                            id="Path"
                            fill="#333333"
                            fillRule="nonzero"
                        />
                        <path
                            d="M37.3401899,16.7503165 C37.1625527,13.4533755 34.8093882,11.1005274 31.5128692,10.9229958 C28.2234177,10.7457806 25.8489451,13.7206751 25.6855485,16.7503165 C25.6168776,18.0227848 27.5907173,18.0161392 27.6590717,16.7503165 C27.9266878,11.7830169 35.0991561,11.7830169 35.3667722,16.7503165 C35.4350211,18.0161392 37.4089662,18.0227848 37.3401899,16.7503165 L37.3401899,16.7503165 Z"
                            id="Path"
                            fill="#333333"
                            fillRule="nonzero"
                        />
                    </g>
                </g>
            </g>
        </svg>
        <RatingLabel>Awesome</RatingLabel>
    </RatingContainer>
);
