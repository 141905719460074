import React, {useCallback, useContext, useState} from "react";
import {Progress} from "antd";
import {calculatorWizardSteps} from "./consts";
import {HappinessCalculatorContext} from "../HappinessCalculatorContext";
import {HappinessCalculatorRoutes} from "../consts";
import {StyledButton} from "../../components";
import {Images} from "../../images/Images";
import "./calculator-wizard.css";

const {CalculatorBG} = Images;

export const CalculatorWizard = ({history}) => {
    const {setPlanTimeRange, setPlanOccasionsCategories} = useContext(HappinessCalculatorContext);
    const [current, setCurrent] = useState(0);
    const [periods, setPeriods] = useState([]);
    const [occasionCategories, setOccasionCategories] = useState([]);

    const goNextStep = useCallback(() => {
        setCurrent(current => current + 1);
    }, [current]);

    const onStepperFinish = useCallback(() => {
        setPlanTimeRange(periods);
        setPlanOccasionsCategories(occasionCategories);

        history.push(HappinessCalculatorRoutes.PLAN);
    }, [periods, occasionCategories, setPlanTimeRange, setPlanOccasionsCategories, history]);

    return (
        <div className="calculator-wizard-container" style={{backgroundImage: `url(${CalculatorBG})`}}>
            <Progress
                className="calculator-wizard-progress"
                percent={calculatorWizardSteps[current].percent}
                showInfo={false}
                strokeColor="#ED7FA6"
            />

            <div className="calculator-wizard-steps-content">
                {calculatorWizardSteps[current].content({
                    history,
                    periods,
                    setPeriods,
                    selectedOccasionCategories: occasionCategories,
                    setSelectedOccasionCategories: setOccasionCategories
                })}
            </div>

            <div className="calculator-wizard-steps-action">
                {current < calculatorWizardSteps.length - 1 && (
                    <StyledButton disabled={periods.length !== 2} onClick={goNextStep}>
                        המשך לבניית התוכנית!
                    </StyledButton>
                )}
                {current === calculatorWizardSteps.length - 1 && (
                    <StyledButton onClick={onStepperFinish}>סיום</StyledButton>
                )}
            </div>
        </div>
    );
};
