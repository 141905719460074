import React, {useMemo} from "react";
import {Table} from "antd";
import "../../order-information-card.css";
import {generateTableContent} from "../utils.jsx";

export const OrderPackageDetails = ({eventInfo}) => {
    const tableContent = useMemo(() => generateTableContent(eventInfo), [eventInfo]);

    const AmountSection = ({productAmountType, eventInfo}) => {
        switch (productAmountType) {
            case "חבילה":
                return (
                    <>
                        <div>{`🧑‍🤝‍🧑 הרכב החבילה`}</div>
                        <div className="order-information-card-customer-information-modular-product-container">
                            {eventInfo.modularProducts.map((subProduct, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="order-information-card-customer-information-modular-product">
                                        <div>
                                            <span>{subProduct.amount}</span>
                                            <span>{" - "}</span>
                                            <span>{subProduct?.name}</span>
                                            <span> </span>
                                            {subProduct?.catalogNumber ? (
                                                <span>{`(מק״ט ${subProduct.catalogNumber})`}</span>
                                            ) : null}
                                            <span>{" | "}</span>
                                            <span>₪{subProduct?.price * subProduct.amount}</span>
                                        </div>
                                        <div className="order-information-card-customer-information-modular-product-desc">
                                            {subProduct?.description}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                );
            case "שובר":
                const voucherAmount = eventInfo.singleEmployeeDetails ? "1" : eventInfo.employeesExcel.rowCount;
                return (
                    <>
                        <div>{`🧑‍🤝‍🧑 כמה שוברים?`}</div>
                        <div>{voucherAmount} שוברים</div>
                    </>
                );

            default:
                return eventInfo.productAmount && eventInfo.productAmountType ? (
                    <>
                        <div>{`🧑‍🤝‍🧑 כמה ${eventInfo.productAmountType}?`}</div>
                        <div>
                            {eventInfo.productAmount} {eventInfo.productAmountType}
                        </div>
                    </>
                ) : (
                    <>
                        <div>{"🧑‍🤝‍🧑 כמה משתתפים?"}</div>
                        <div>{eventInfo.participants} משתתפים</div>
                    </>
                );
        }
    };

    return (
        <>
            {tableContent?.dataSource?.length ? (
                <Table
                    className="order-information-card-details-table"
                    columns={tableContent?.columns}
                    dataSource={tableContent?.dataSource}
                    summary={tableContent.summary}
                    pagination={{position: [], pageSize: tableContent?.dataSource?.length}}
                />
            ) : (
                <div className="order-information-card-customer-information">
                    <AmountSection productAmountType={eventInfo.productAmountType} eventInfo={eventInfo} />
                </div>
            )}
        </>
    );
};
