import {CloseIcon} from "../../../icons";
import {Modal} from "antd";
import React, {useState} from "react";
import {LocationLabels} from "../../../data/locations";
import {pickBy} from "lodash";
import {ContentEditForm} from "./ContentEditForm";
import * as shortid from "shortid";
import {openWindow} from "../../../http/WindowOpener";
import {parse as qsParse} from "query-string";
import {CompanyContentTagsModal} from "../tags/CompanyContentTagsModal";

const storePreviewContent = values => {
    const contentId = shortid.generate();
    window.localStorage.setItem(`wb-content-${contentId}`, JSON.stringify(values));
    return contentId;
};

export const CompanyContentEditModal = ({
    title,
    contentToEdit,
    sectionId,
    category,
    visible,
    onClose,
    onValuesChange,
    onEditDone,
    onAddDone,
    onDelete,
    onDisplayChange,
    onAddCategory
}) => {
    const [loading, setLoading] = useState(false);

    const {branchId} = qsParse(location.search);

    const closeMe = () => {
        onClose();
    };

    const transformInside = contentValues => {
        const keysToExclude = ["productCoverPhoto", "branches"];

        const content = Object.keys(contentValues)
            .filter(key => keysToExclude.every(exclude => exclude !== key))
            .map(key => {
                let extraValue = null;
                if (key === "location" && contentValues[key] === LocationLabels.ONLINE) {
                    extraValue = contentValues.meetingLink || "";
                } else if (key === "location") {
                    extraValue = contentValues.address || "";
                }

                if (key === "actionText") {
                    extraValue = contentValues.actionLink || "";
                }

                return {
                    name: [key],
                    value: {
                        enabled:
                            contentValues.viewState &&
                            contentValues.viewState[key] &&
                            !!contentValues.viewState[key].enabled,
                        value: contentValues[key],
                        extraValue
                    }
                };
            });

        return content.concat([
            {
                name: ["provider"],
                value: {
                    enabled: true,
                    value: {
                        sessionType: contentValues.sessionType,
                        providerId: contentValues.providerId,
                        providerFirstName: contentValues.providerFirstName,
                        providerLastName: contentValues.providerLastName,
                        providerEmail: contentValues.providerEmail
                    }
                }
            },
            {
                name: ["productCoverPhoto"],
                value: contentValues.productCoverPhoto
            },
            {
                name: ["branches"],
                value: contentValues.branches
            }
        ]);
    };

    const transformOutside = contentValues => {
        const keyToExclude = [
            "viewState",
            "meetingLink",
            "address",
            "actionLink",
            "sessionType",
            "providerId",
            "providerFirstName",
            "providerLastName",
            "providerEmail",
            "productCoverPhoto",
            "branches"
        ];

        const content = Object.keys(contentValues).reduce((content, key) => {
            if (keyToExclude.every(exclude => key !== exclude) && contentValues[key]) {
                content[key] = contentValues[key].value;

                if (key === "provider" && contentValues.provider.value) {
                    if (contentValues.provider.value.sessionType !== "company") {
                        content.providerId = contentValues.provider.value.providerId;
                        content.providerFirstName = contentValues.provider.value.providerFirstName;
                        content.providerLastName = contentValues.provider.value.providerLastName;
                        content.providerEmail = contentValues.provider.value.providerEmail;
                    } else {
                        content.providerId = null;
                        content.providerFirstName = null;
                        content.providerLastName = null;
                        content.providerEmail = null;
                    }
                    content.sessionType = contentValues.provider.value.sessionType;
                }

                if (key === "location") {
                    if (contentValues[key] && !!contentValues[key].enabled) {
                        if (contentValues[key].value === LocationLabels.ONLINE) {
                            content.meetingLink = contentValues[key].extraValue;
                        } else if (key === "location") {
                            content.address = contentValues[key].extraValue;
                        }
                    } else {
                        content.meetingLink = content.address = null;
                    }
                }

                if (key === "actionText") {
                    if (contentValues[key] && !!contentValues[key].enabled) {
                        content.actionLink = contentValues[key].extraValue;
                    } else {
                        content.actionText = content.actionLink = null;
                    }
                }

                content.viewState = content.viewState || {};
                content.viewState[key] = {
                    enabled: contentValues[key] && !!contentValues[key].enabled
                };
            }
            return content;
        }, {});

        content.productCoverPhoto =
            typeof contentValues.productCoverPhoto === "object"
                ? contentValues.productCoverPhoto.value
                : contentValues.productCoverPhoto;

        delete content.provider;
        content.branches = contentValues.branches;

        return content;
    };

    const onFinish = async allValues => {
        setLoading(true);
        const content = pickBy(transformOutside(allValues), v => v !== void 0);
        if (onEditDone) {
            await onEditDone({
                ...contentToEdit,
                ...content
            });
        } else if (onAddDone) {
            await onAddDone(content);
        }
        setLoading(false);
        closeMe();
    };

    const openPreview = async values => {
        const content = pickBy(transformOutside(values), v => v !== void 0);
        const defaultBranch = content.branches[0];
        const contentId = storePreviewContent({
            ...contentToEdit,
            ...content,
            sectionId
        });
        openWindow(
            `/center/sections/${sectionId}/content/${contentId}/preview?branchId=${branchId || defaultBranch.branchId}`,
            "_blank",
            true
        );
    };

    const onChange = allValues => {
        const content = transformOutside(allValues);
        onValuesChange &&
            onValuesChange({
                ...contentToEdit,
                ...content
            });
    };

    return (
        <Modal
            style={{top: 10, marginBottom: 50}}
            width={1040}
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={closeMe}
            bodyStyle={{padding: 0}}
            footer={null}
            title={null}
            closable={true}
            closeIcon={<CloseIcon fill="white" style={{marginTop: 20}} />}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <div
                    style={{
                        height: 60,
                        width: "100%",
                        backgroundColor: "#595AD4",
                        fontSize: 20,
                        color: "white",
                        fontWeight: 700,
                        paddingLeft: 20,
                        display: "flex",
                        alignItems: "center"
                    }}>
                    {title}
                </div>
                {visible ? (
                    <ContentEditForm
                        sectionId={sectionId}
                        category={category}
                        contentToEdit={contentToEdit}
                        fields={contentToEdit ? transformInside(contentToEdit) : null}
                        loading={loading}
                        onFinish={onFinish}
                        onDelete={onDelete}
                        onClose={closeMe}
                        onEditDone={onEditDone}
                        onDisplayChange={onDisplayChange}
                        onValuesChange={onChange}
                        onNewCategory={onAddCategory}
                        onPreview={openPreview}
                    />
                ) : null}
            </div>
        </Modal>
    );
};
