import {locationKeyToTextMap, LocationLabels} from "../data/locations";
import React, {useContext} from "react";
import {CustomSelect} from "./CustomSelect";
import {FormInput} from "./form";
import {isAppointmentBasedProfession} from "../data/professions";
import {AppContext} from "../AppContext";

const {Option} = CustomSelect;

export const AppointmentBasedLocationSelect = props => {
    return (
        <CustomSelect style={{width: "100%"}} {...props}>
            <Option value={LocationLabels.ONSITE}>{locationKeyToTextMap[LocationLabels.ONSITE]}</Option>
        </CustomSelect>
    );
};

export const FullLocationSelect = props => {
    return (
        <CustomSelect style={{width: "100%"}} {...props}>
            <Option value={LocationLabels.ONSITE}>{locationKeyToTextMap[LocationLabels.ONSITE]}</Option>
            <Option value={LocationLabels.ONLINE}>{locationKeyToTextMap[LocationLabels.ONLINE]}</Option>
            <Option value={LocationLabels.ONSITE_AND_ONLINE}>
                {locationKeyToTextMap[LocationLabels.ONSITE_AND_ONLINE]}
            </Option>
        </CustomSelect>
    );
};

export const AllLocationsSelect = props => (
    <CustomSelect style={{width: "100%"}} {...props}>
        <Option value={LocationLabels.ONSITE}>{locationKeyToTextMap[LocationLabels.ONSITE]}</Option>
        <Option value={LocationLabels.ONLINE}>{locationKeyToTextMap[LocationLabels.ONLINE]}</Option>
        <Option value={LocationLabels.OFFSITE}>{locationKeyToTextMap[LocationLabels.OFFSITE]}</Option>
    </CustomSelect>
);

export const ServiceLocationSelect = props => {
    const {professions} = useContext(AppContext);
    const {provider} = props;
    if (isAppointmentBasedProfession(provider.profession, professions)) {
        return <AppointmentBasedLocationSelect {...props} />;
    } else {
        return <FullLocationSelect {...props} />;
    }
};

export const EventLocationSelect = props => {
    const {professions} = useContext(AppContext);
    const {provider} = props;
    if (provider && isAppointmentBasedProfession(provider.profession, professions)) {
        return <AppointmentBasedLocationSelect {...props} />;
    } else {
        return <AllLocationsSelect {...props} />;
    }
};

export const OffsiteLocationInput = ({value, onChange}) => {
    return <FormInput placeholder="Offsite address" value={value} onChange={onChange} />;
};
