import React from "react";
import {Dropdown, Spin} from "antd";
import classNames from "classnames";
import {ArrowDownOutlined, ArrowUpOutlined, DownOutlined, EyeFilled, LockFilled} from "@ant-design/icons";
import moment from "moment-timezone";
import {CopyableSpan} from "../../../components";
import {DragHandle} from "../../../components/dragableTable";
import {ServiceIdToName} from "../../../consts.js";

const openLinkInNewTab = product => {
    const {
        service: {productId, services},
        provider: {providerId}
    } = product;

    const link = `${window.location.origin}/dashboard/services/${services[0]}/providersV2/${providerId}/${productId}`;
    window.open(link, "_blank");
};

export const getColumns = (me, lockProduct, productToSelectionSites = {}, shouldSort, moveToStart, moveToEnd) => [
    ...(!!shouldSort
        ? [
              {
                  width: 30,
                  render: (text, product, index) => {
                      return (
                          <>
                              <ArrowUpOutlined onClick={() => moveToStart(product)} />
                              <ArrowDownOutlined onClick={() => moveToEnd(product)} />
                          </>
                      );
                  }
              },

              {
                  dataIndex: "sort",
                  width: 80,
                  className: "drag-visible",
                  render: (_, product) => {
                      const isFilterProduct = product?.service?.manualOrder === -1;

                      if (!isFilterProduct) {
                          return <DragHandle />;
                      }

                      return <></>;
                  }
              }
          ]
        : []),
    {
        title: "מזהה מוצר",
        key: "productId",
        render: product => {
            return <CopyableSpan>{product.service.productId}</CopyableSpan>;
        }
    },

    {
        title: "שם המוצר",
        sorter: (a, b) => a.service.productName?.localeCompare(b.service.productName),
        key: "productName",
        dataIndex: ["service", "productName"]
    },
    {
        title: "שם השותף",
        key: "provider",
        render: (_, {provider, providerId}) => {
            return (
                <div>
                    {me.features?.admin_debug ? (
                        <CopyableSpan textToCopy={providerId}>
                            <div>{provider?.businessName}</div>
                            <div>
                                {provider?.contactFirstName} | {provider?.contactPhoneNumber ?? "אין טלפון"}
                            </div>
                        </CopyableSpan>
                    ) : (
                        <>
                            <div>{provider?.businessName}</div>
                            <div>
                                {provider?.contactFirstName} | {provider?.contactPhoneNumber ?? "אין טלפון"}
                            </div>
                        </>
                    )}
                </div>
            );
        },
        sorter: (a, b) => a?.provider?.businessName.localeCompare(b?.provider?.businessName)
    },
    {
        title: "תאריך יצירה",
        dataIndex: ["service", "createdAt"],
        render: createdAt => {
            return <>{moment(createdAt).format("DD.MM.YY | HH:mm")}</>;
        },
        sorter: (a, b) => a.createdAt - b.createdAt
    },
    {
        title: "קטגוריות",
        key: "categories",
        render: ({service: {services}}) => {
            const menu = {
                items: services?.map((category, index) => ({
                    key: index,
                    label: typeof category === "string" ? ServiceIdToName[category] : "מוצר פגום"
                }))
            };
            return (
                <>
                    {services?.length > 1 ? (
                        <Dropdown menu={menu}>
                            <a onClick={e => e.preventDefault()}>
                                {services?.length} קטגוריות <DownOutlined />
                            </a>
                        </Dropdown>
                    ) : (
                        <>
                            {typeof services?.[0] === "string"
                                ? ServiceIdToName[services?.[0]] ?? "ללא קטגוריה"
                                : "מוצר פגום"}
                        </>
                    )}
                </>
            );
        }
    },
    {
        title: "אתרי בחירה",
        key: "carusals",
        render: (_, product) => {
            const selectionSites = productToSelectionSites[product?.service?.productId];

            if (!selectionSites) {
                return <Spin />;
            }
            const menu = {
                items:
                    selectionSites?.map((selectionSite, index) => ({
                        key: index,
                        label: selectionSite.name || selectionSite.selectPageId
                    })) ?? []
            };
            return (
                <div>
                    {menu.items?.length > 1 ? (
                        <Dropdown menu={menu}>
                            <a onClick={e => e.preventDefault()}>
                                {menu.items?.length} אתרי בחירה <DownOutlined />
                            </a>
                        </Dropdown>
                    ) : (
                        <>{menu.items?.length[0] ?? "ללא"}</>
                    )}
                </div>
            );
        }
    },
    {
        title: "כמות מכירות ב3 חודשים האחרונים",
        dataIndex: ["service", "recentOrdersAmount"],
        render: recentOrdersAmount => {
            return <>{recentOrdersAmount ?? "0"}</>;
        },
        sorter: (a, b) => (a.recentOrdersAmount ?? 0) - (b.recentOrdersAmount ?? 0)
    },
    {
        title: "סטאטוס",
        key: "active",
        render: (_, {service: {isActive}, provider}) => {
            return (
                <span
                    className={classNames("admin-product-table-status-cell", {active: provider?.approved && isActive})}>
                    {provider?.approved ? (isActive ? "פעיל" : "לא פעיל") : "ספק לא אושר"}
                </span>
            );
        },
        sorter: (a, b) => (a.isActive && a.provider.approved) - (b.isActive && b.provider.approved)
    },
    {
        title: "פעולות",
        key: "actions",
        width: 70,
        render(_, product) {
            return (
                <div className="admin-product-table-actions-container">
                    <EyeFilled
                        onClick={() => {
                            openLinkInNewTab(product);
                        }}
                    />
                    <LockFilled onClick={() => lockProduct(product.service.productId, false)} id="black-list" />
                </div>
            );
        }
    }
];
