import {cloneDeep, pick} from "lodash";
import {kosherTypes} from "../../ProductsFilterModal/consts.jsx";
import {CategoryTextToOriginalCategories} from "../consts.js";
import {consistentOption} from "../popoverFilters/budgetFilter/consts.js";
import {kosherTab} from "../popoverFilters/accuracyResultsFilter/consts.js";
import {getLocationFilterInnerState} from "../popoverFilters/locationFilter/utils.js";

const buildDeliveryAddressFilter = locationFilter => {
    const deliveryAddressInnerState = getLocationFilterInnerState(locationFilter);
    if (Object.keys(deliveryAddressInnerState ?? {}).length > 0) {
        const deliveryAddressOption = cloneDeep(
            pick(deliveryAddressInnerState, [
                "displayName",
                "deliveryPrice",
                "deliveryType",
                "isAccessible",
                "isSelfPickupPossible"
            ])
        );
        const deliveryAddress = deliveryAddressInnerState?.placeIds?.length
            ? (deliveryAddressInnerState?.placeIds ?? []).map(placeId => ({...deliveryAddressOption, placeId}))
            : [deliveryAddressOption];
        if (deliveryAddress.length > 0) {
            return {deliveryAddress};
        }
    }
    return {};
};

const buildBudgetFilter = budgetState => {
    const budget = cloneDeep(pick(budgetState, ["totalPrice", "participantsAmount", "pricePerParticipant"]));
    if (budgetState?.consistentBudget?.[0] === consistentOption.flexible) {
        if (budget?.totalPrice?.max) {
            budget.totalPrice.max = budget.totalPrice.max + budget.totalPrice.max * 0.1;
        }
        if (budget?.participantsAmount) {
            budget.participantsAmount = Math.ceil(budget.participantsAmount + budget.participantsAmount * 0.1);
        }
        if (budget?.pricePerParticipant?.max) {
            budget.pricePerParticipant.max = budget.pricePerParticipant.max + budget.pricePerParticipant.max * 0.1;
        }
    }
    if (Object.keys(budget).length > 0) {
        return {budget: budget};
    }
    return {};
};

const buildAccuracyResultsFilter = accuracyResults => {
    const accuracyResultsData = pick(accuracyResults, ["productProperties", "activityRequestsProperties"]);
    if (accuracyResults.kosherTypesKey) {
        const finalKosherTypes =
            accuracyResults.kosherTypesKey === kosherTab.anyKosher
                ? (accuracyResultsData.kosherTypes = Object.values(kosherTypes).filter(
                      kosher => kosher !== kosherTypes.NOT_NEEDED
                  ))
                : accuracyResults?.foodOptions;
        if (finalKosherTypes) {
            accuracyResultsData.kosherTypes = finalKosherTypes;
        }
    }
    return accuracyResultsData;
};

export const buildFilters = (formValues, category) => {
    let filterData = {};
    if (formValues?.locationFilter) {
        filterData = {...filterData, ...buildDeliveryAddressFilter(formValues.locationFilter)};
    }

    if (formValues?.budget) {
        filterData = {...filterData, ...buildBudgetFilter(formValues.budget)};
    }

    if (formValues?.accuracyResults) {
        filterData = {...filterData, ...buildAccuracyResultsFilter(formValues?.accuracyResults)};
    }

    if (formValues?.searchText) {
        filterData = {...filterData, searchText: [formValues.searchText]};
    }

    if (formValues?.tags?.displayName) {
        filterData = {...filterData, tag: formValues?.tags?.displayName};
    }

    if (category) {
        filterData.categories = CategoryTextToOriginalCategories[category];
    }

    return filterData;
};
