import React, {useMemo, useState} from "react";
import {Select} from "antd";

export const SelectWithInputOption = ({options, ...selectProps}) => {
    const [inputValue, setInputValue] = useState("");

    const filterOptions = useMemo(() => {
        const trimmedInputValue = inputValue?.trim();
        return [
            ...options?.filter(({label}) => label.toString().includes(trimmedInputValue)),
            ...(inputValue && options.every(({label}) => label !== trimmedInputValue)
                ? [
                      {
                          value: trimmedInputValue,
                          label: <i>{trimmedInputValue}</i>
                      }
                  ]
                : [])
        ];
    }, [options, inputValue]);

    return (
        <Select
            {...selectProps}
            showSearch={true}
            filterOption={false}
            options={filterOptions}
            onSearch={setInputValue}
            inputValue={inputValue}
        />
    );
};
