import React from "react";
import {StyledButton} from "../../components";

export const PricingCalculatorByProductBundle = ({onBook}) => (
    <>
        <div>
            <div className="pricing-calculator-content-title">תמחור מדוייק של החבילה ניתן לחשוב בעמוד ההזמנה</div>
        </div>

        <StyledButton onClick={onBook} className="pricing-calculator-button">
            {"מעבר להזמנה"}
        </StyledButton>
    </>
);
