import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Checkbox, message, Tooltip} from "antd";
import {isEmpty} from "lodash";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {Payment} from "../Payment.jsx";
import {CheckboxController, CreditCardPreview} from "../PaymentInputHelper.jsx";
import BudgetPaymentInput from "../BudgetPaymentInput.jsx";
import {FormInputV2} from "../../../../components/form/index.jsx";
import {PaymentType} from "../../../../consts.js";
import {Images} from "../../../../images/Images.jsx";
import {EventBus} from "../../../../bus/EventBus.jsx";
import {HttpClient} from "../../../../http/HttpClient.jsx";
import {AppContext} from "../../../../AppContext.jsx";
import {AreYouSureModal} from "../../../../components/index.jsx";
import {TenBisPaymentInput} from "../TenBisPaymentInput/index.js";
import "./payment-input.css";

export const PaymentInput = ({
    onInvoiceCommentChange,
    invoiceComment,
    onInvoicePONumberChange,
    invoicePONumber,
    onSeparateInvoiceChange,
    paymentType,
    setPaymentType,
    budgetCategoriesPriority,
    separateInvoice,
    creditCard,
    setCreditCard,
    onBudgetCategoriesPriorityChange,
    allowedPaymentMethods,
    updateFormValues,
    creditCardToolTipInfo,
    tenBisCreditCard
}) => {
    const {me, company} = useContext(AppContext);
    const [removeCardModalVisibility, setRemoveCardModalVisibility] = useState(false);
    const creditCardKey = useRef(0);

    const removeSavedCard = useCallback(e => {
        e.preventDefault();
        e.stopPropagation();
        setRemoveCardModalVisibility(true);
    }, []);

    const onYesToRemoveSavedCard = useCallback(async () => {
        setRemoveCardModalVisibility(null);

        try {
            await HttpClient.post(`/api/users/${me.userId}/removeCreditCard`);
            setCreditCard({});
            message.success("הכרטיס נמחק בהצלחה!", 5);
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we couldn't complete your request :("}},
                e.message
            );
        }
    }, [me?.userId, setCreditCard]);

    const onCardSavedSuccess = useCallback(
        (creditCardData, saveCard) => {
            setCreditCard({
                token: creditCardData.token,
                expDate: creditCardData.expiry_month + creditCardData.expiry_year,
                last4Digits: creditCardData.credit_card_last_4_digits
            });

            if (saveCard) {
                Promise.resolve().then(async () => {
                    try {
                        await HttpClient.post(`/api/users/${me.userId}/updateCreditCard`, {
                            creditCardData: creditCardData
                        });
                        message.success("!הכרטיס נשמר בהצלחה", 5);
                    } catch (e) {
                        message.error("Something went wrong, please try again later.", 5);
                    }
                });
            }
        },
        [me?.userId, setCreditCard]
    );

    const parseResponse = useCallback(
        (response, saveCard, key) => {
            if (key !== creditCardKey.current) {
                return;
            }

            if (response.errors != null) {
                let tmpArray = [];
                response.errors.forEach(message => {
                    tmpArray.push(message.message);
                });
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we didn't manage to save your card :("}},
                    tmpArray.join()
                );
            } else {
                if (response.transaction_response.success === false) {
                    EventBus.triggerError(
                        "server-error",
                        {content: {description: response.transaction_response.error}},
                        response.transaction_response.error
                    );
                } else {
                    creditCardKey.current++;
                    onCardSavedSuccess(response.transaction_response, saveCard);
                }
            }
        },
        [creditCardKey.current]
    );

    const creditCardPaymentComponent = useMemo(() => {
        const key = creditCardKey.current;
        return (
            <Payment
                key={key}
                creditCardExist={creditCard.last4Digits}
                parseResponse={(response, saveCard) => parseResponse(response, saveCard, key)}
            />
        );
    }, [parseResponse, creditCard]);

    useEffect(() => {
        async function getCreditCard() {
            const creditCardData = await HttpClient.safeGet(`/api/companies/${company.companyId}/getCreditCardData`);

            if (creditCardData?.error) {
                message.error("Error while fetching credit card data");
                return;
            }

            if (!isEmpty(creditCardData)) {
                setCreditCard({
                    token: creditCardData.token,
                    expDate: creditCardData.expDate,
                    last4Digits: creditCardData.last4Digits
                });
            }
        }

        if (company) {
            getCreditCard();
        }
    }, [company, setCreditCard]);

    const paymentMethodsWithAllowedStatus = useMemo(
        () =>
            Object.values(allowedPaymentMethods ?? PaymentType).reduce(
                (allowed, paymentType) => ({
                    ...allowed,
                    [paymentType]: me.isCompanySubAdmin ? me.allowedPaymentsMethods?.includes(paymentType) : true
                }),
                {}
            ),
        [allowedPaymentMethods, me?.allowedPaymentsMethods, me?.isCompanySubAdmin]
    );

    useEffect(() => {
        const onlyAllowedPaymentMethods = Object.entries(paymentMethodsWithAllowedStatus).filter(([key, val]) => val);
        if (onlyAllowedPaymentMethods.length === 1) {
            setPaymentType(onlyAllowedPaymentMethods[0][0]);
        }
    }, [paymentMethodsWithAllowedStatus, setPaymentType]);

    return (
        <>
            <AreYouSureModal
                visible={!!removeCardModalVisibility}
                onClose={() => setRemoveCardModalVisibility(null)}
                title="בטוח שאתה רוצה למחוק את פרטי האשראי?"
                saveStyle={{color: "white", backgroundColor: "#1260D1"}}
                onYes={onYesToRemoveSavedCard}
                onNo={() => setRemoveCardModalVisibility(null)}
            />

            <div className="payment-input-containers">
                {[PaymentType.BankTransfer, PaymentType.Budget].includes(paymentType) ? (
                    <div className="payment-input-comments-type-container">
                        <div className="payment-input-invoice-comment-container">
                            <div className="payment-input-invoice-comment-input">
                                <FormInputV2
                                    placeholder={"הערות לציון על החשבונית"}
                                    onChange={e => onInvoiceCommentChange(e.target.value)}
                                    value={invoiceComment}
                                />
                            </div>
                            <div className="payment-input-tooltip-text-bank-transfer">{"עד 100 תווים"}</div>
                        </div>

                        <div>
                            <FormInputV2
                                placeholder={`מס׳ הזמנת רכש`}
                                value={invoicePONumber}
                                style={{width: "150px"}}
                                onChange={e => onInvoicePONumberChange(e.target.value)}
                            />
                        </div>
                    </div>
                ) : null}

                {paymentMethodsWithAllowedStatus[PaymentType.Card] ? (
                    <div className="payment-input-container">
                        <div className="payment-input-options-header">
                            <CheckboxController
                                isChecked={paymentType === PaymentType.Card}
                                setPaymentType={type => setPaymentType(type)}
                                type={PaymentType.Card}
                            />
                            כרטיס אשראי
                            <Tooltip title={creditCardToolTipInfo}>
                                <QuestionCircleOutlined className="payment-input-tooltip-icon" />
                            </Tooltip>
                        </div>
                        <CreditCardPreview last4Digits={creditCard.last4Digits} removeSavedCard={removeSavedCard} />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start"
                            }}>
                            {paymentType === PaymentType.Card ? (
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    {creditCardPaymentComponent}
                                    <img
                                        alt="credit-card-image"
                                        src={Images.creditCardsInARow}
                                        style={{width: "200px", marginTop: "20px"}}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                ) : null}

                {paymentMethodsWithAllowedStatus[PaymentType.BankTransfer] ? (
                    <div className="payment-input-container">
                        <div className={"payment-input-options-header"}>
                            <CheckboxController
                                isChecked={paymentType === PaymentType.BankTransfer}
                                setPaymentType={type => setPaymentType(type)}
                                type={PaymentType.BankTransfer}
                            />
                            {"הוספה לחשבונית החודשית שלנו"}
                            <Tooltip
                                title={
                                    <p className="payment-input-tooltip-text">
                                        בחירה באפשרות החשבונית החודשית כאמצעי תשלום לעסקה, מאפשרת לכם להוסיף את העסקה
                                        הספציפית לתוך החשבונית החודשית שלכם. החשבונית תשלח בסוף החודש ותרכז את כל
                                        ההזמנות ששייכתם אליה באותו חודש קלנדרי, לתשלום בהעברה בנקאית בתנאי שוטף +30.
                                    </p>
                                }>
                                <QuestionCircleOutlined className="payment-input-tooltip-icon" />
                            </Tooltip>
                        </div>
                        <div className={"payment-input-bank-option-subtitle"}>
                            {"חשבונית מרוכזת לתשלום בהעברה בנקאית בתנאי "}
                            <u>שוטף+30</u>
                        </div>
                        {paymentType === PaymentType.BankTransfer ? (
                            <div className="payment-input-bank-option-subtitle">
                                <div className="payment-input-bank-transfer-different-invoice">
                                    <Checkbox
                                        onChange={e => onSeparateInvoiceChange(e.target.checked)}
                                        checked={separateInvoice}>
                                        אשמח בהפרדת העסקה לחשבונית נפרדת
                                    </Checkbox>
                                </div>
                            </div>
                        ) : null}
                    </div>
                ) : null}

                {paymentMethodsWithAllowedStatus[PaymentType.Budget] ? (
                    <BudgetPaymentInput
                        paymentType={paymentType}
                        setPaymentType={setPaymentType}
                        budgetsPriority={budgetCategoriesPriority}
                        onBudgetCategoriesPriorityChange={onBudgetCategoriesPriorityChange}
                    />
                ) : null}

                {me?.features?.ten_bis_payment && paymentMethodsWithAllowedStatus[PaymentType.TenBisCard] ? (
                    <TenBisPaymentInput
                        setPaymentType={setPaymentType}
                        paymentType={paymentType}
                        updateFormValues={updateFormValues}
                        tenBisCreditCard={tenBisCreditCard}
                    />
                ) : null}
            </div>
        </>
    );
};
