import React from "react";
import moment from "moment-timezone";
import {Avatar, Dropdown, Menu, Tooltip} from "antd";
import {EllipsisOutlined, FrownOutlined} from "@ant-design/icons";
import {ContactPhone} from "../../support/ContactPhone";
import {ConfirmationRequiredWrapper} from "../../components";
import {ProviderNetPaymentTermsTypes} from "../upload/AdminUploadUtils";

export const adminVendorsColumns = (
    onDelete,
    services,
    openPaymentHistory,
    onEdit,
    onApprove,
    openOldInvoiceModalData,
    updateProviderNetPaymentTerms
) => [
    {
        title: "#",
        key: "#",
        width: 70,
        render(_, __, idx) {
            return `${idx + 1}`;
        }
    },
    {
        title: "מזהה",
        dataIndex: "providerId",
        width: 150,
        filters: [
            {
                text: "ספק ללא מוצרים",
                value: "missing"
            },
            {
                text: "ספק ממתין לאישור",
                value: "pending"
            },
            {
                text: "ספק פעיל עם מוצרים",
                value: "ready"
            }
        ],
        filterMultiple: true,
        onFilter: (value, {services, approved}) => {
            switch (value) {
                case "missing":
                    return !Array.isArray(services) || services.length === 0;
                case "pending":
                    return !approved;
                case "ready":
                    return Array.isArray(services) && services.length > 0 && approved;
            }
        },
        render(providerId, {services, approved}) {
            const hasServices = Array.isArray(services) && services.length > 0;

            return (
                <div className="admin-vendors-id-cell">
                    <Tooltip
                        title={!hasServices ? "ספק ללא מוצרים" : approved ? "ספק פעיל עם מוצרים" : "ספק ממתין לאישור"}>
                        <div
                            className="admin-vendors-id-cell-indicator"
                            style={{
                                backgroundColor: !hasServices ? "red" : hasServices && approved ? "#0eca0e" : "#cccccc"
                            }}
                        />
                    </Tooltip>
                    {providerId}
                </div>
            );
        }
    },
    {
        title: "שם השותף",
        key: "name",
        width: 230,
        sorter: (a, b) => a?.businessName?.localeCompare(b?.businessName),
        render(_, {businessName, contactEmail, imageUrl}) {
            return (
                <div className="admin-vendors-provider-name-cell">
                    {imageUrl ? (
                        <Avatar className="admin-vendors-provider-name-cell-avatar" src={imageUrl} />
                    ) : (
                        <FrownOutlined style={{fontSize: 32, marginRight: 8}} />
                    )}
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <label
                            style={{
                                color: "var(--secondary-color)",
                                fontSize: 14,
                                fontWeight: 500
                            }}>
                            {businessName}
                        </label>
                        <label style={{color: "#747F92", fontSize: 12, fontWeight: 400}}>{contactEmail}</label>
                    </div>
                </div>
            );
        }
    },
    {
        title: "טלפון",
        dataIndex: "contactPhoneNumber",
        render(phone) {
            return (
                <div style={{direction: "ltr"}}>
                    <a target="_blank" href={ContactPhone.whatsAppLink(phone)}>
                        {ContactPhone.prettyPhoneNumber(phone)}
                    </a>
                </div>
            );
        }
    },
    {
        title: "תאריך יצירה",
        dataIndex: "createdAt",
        sorter: (r1, r2) => (r1.createdAt < r2.createdAt ? 1 : -1),
        defaultSortOrder: "ascend",
        render(createdAt) {
            return createdAt ? moment(createdAt).format("DD.MM.YY | HH:mm") : "";
        }
    },
    {
        title: "מוצרים פעילים",
        width: 80,
        key: "activeProducts",
        sorter: (r1, r2) => getActiveProductsNumber(r1) - getActiveProductsNumber(r2),
        render(_, order) {
            return getActiveProductsNumber(order);
        }
    },
    {
        title: "חוב בפיגור",
        dataIndex: "arrearsDebt",
        sorter: (r1, r2) => Number(r1.arrearsDebt ?? 0) - Number(r2.arrearsDebt ?? 0),
        render: arrearsDebt => {
            return <span>{arrearsDebt?.toLocaleString() ?? "-"}</span>;
        }
    },
    {
        title: "חוב עתידי",
        dataIndex: "futureDebt",
        sorter: (r1, r2) => Number(r1.futureDebt ?? 0) - Number(r2.futureDebt ?? 0),
        render: futureDebt => {
            return <span>{futureDebt?.toLocaleString() ?? "-"}</span>;
        }
    },
    {
        title: "חוב כולל",
        key: "sumOfFutureDebtAndArrearsDebt",
        sorter: (r1, r2) => {
            const sumOfR1 = Number(r1.arrearsDebt ?? 0) + Number(r1.futureDebt ?? 0);
            const sumOfR2 = Number(r2.arrearsDebt ?? 0) + Number(r2.futureDebt ?? 0);
            return sumOfR1 - sumOfR2;
        },
        render(_, {arrearsDebt, futureDebt}) {
            const sumOfFutureDebtAndArrearsDebt = Number(arrearsDebt ?? 0) + Number(futureDebt ?? 0);
            return <span>{sumOfFutureDebtAndArrearsDebt}</span>;
        }
    },
    {
        title: "סטטוס ספק",
        dataIndex: "approved",
        width: 100,
        sorter: (r1, r2) => (r1.approved ? 1 : -1) - (r2.approved ? 1 : -1),
        render(approved) {
            return (
                <div className={`admin-vendors-approved-cell${approved ? " vendor-approved" : ""}`}>
                    {approved ? "באוויר" : "ממתין לאישור"}
                </div>
            );
        }
    },
    {
        title: "תנאי שוטף",
        dataIndex: "netPaymentTerms",
        width: 100,
        filters: Object.values(ProviderNetPaymentTermsTypes).map(netPaymentValue => ({
            text: netPaymentValue,
            value: netPaymentValue
        })),
        filterMultiple: true,
        onFilter: (value, {netPaymentTerms}) => value === netPaymentTerms
    },

    {
        render(_, provider) {
            const {providerId, approved} = provider;

            return (
                <Dropdown
                    className="admin-vendors-provider-settings-cell"
                    overlay={
                        <Menu>
                            <Menu.Item className="edit-provider-option" onClick={() => onEdit(providerId)}>
                                עריכת פרופיל השותף
                            </Menu.Item>
                            <ConfirmationRequiredWrapper
                                onYes={() => onApprove(providerId, !approved)}
                                subTitle={`האם אתה בטוח שאתה רוצה ${approved ? "לבטל את השותף" : "לאשר את השותף"}`}>
                                <Menu.Item>{approved ? "בטל שותף" : "אישור שותף"}</Menu.Item>
                            </ConfirmationRequiredWrapper>
                            <Menu.Item onClick={() => openPaymentHistory(provider)}>היסטוריית תשלומים</Menu.Item>
                            <Menu.Item
                                onClick={() => openOldInvoiceModalData({provider, invoices: provider.monthlyInvoices})}>
                                מודל חשבוניות ישן
                            </Menu.Item>
                            <Menu.Item onClick={() => onDelete(providerId)}>מחיקת השותף</Menu.Item>
                            <Menu.Item onClick={() => updateProviderNetPaymentTerms(providerId)}>
                                עריכת תנאי השוטף
                            </Menu.Item>
                        </Menu>
                    }
                    trigger={["click"]}>
                    <EllipsisOutlined className="admin-orders-order-action-button" />
                </Dropdown>
            );
        }
    }
];

const getActiveProductsNumber = ({services}) => services.reduce((sum, {isActive}) => sum + (isActive ? 1 : 0), 0);
