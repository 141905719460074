export const EXCenterProperties = {
    Section: {
        Top: 120,
        opacityByDisplay: sectionDesignInfo => (sectionDesignInfo.display ? 1 : 0.3),
        Card: {
            Title: {
                Length: 25
            }
        },
        Cover: {
            Height: 420
        }
    }
};
