import React, {useState} from "react";
import {Button, message, Upload} from "antd";
import {LoadingOutlined, VideoCameraOutlined} from "@ant-design/icons";
import {CloseIcon} from "../icons";

const FILE_SIZE_MB = 1024 * 1024;
const MAX_VIDEO_SIZE = 100 * FILE_SIZE_MB;

const UploadButton = ({uploading}) => (
    <Button
        icon={
            uploading ? (
                <LoadingOutlined style={{color: "white"}} />
            ) : (
                <VideoCameraOutlined style={{fontSize: 16, color: "white"}} />
            )
        }
        style={{
            width: 150,
            height: 40,
            borderRadius: 20,
            marginLeft: 10,
            marginRight: 10,
            color: "white",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "calc(50% - 75px)",
            top: "calc(50% - 20px)"
        }}>
        Upload video
    </Button>
);

export const VideoUpload = ({onChange, fileUrl, containerStyle, videoStyle}) => {
    const [uploading, setUploading] = useState(false);

    const uploadProps = {
        name: "file",
        action: "/api/videos",
        method: "POST",
        showUploadList: false,
        withCredentials: true,
        accept: "video/mp4",
        beforeUpload: file => {
            return Promise.resolve().then(async () => {
                if (file.size > MAX_VIDEO_SIZE) {
                    message.error(
                        `Maximum video size is ${MAX_VIDEO_SIZE / FILE_SIZE_MB}MB, your cover is ${
                            (file.size / FILE_SIZE_MB) | 0
                        }MB.`,
                        7
                    );
                    throw Error();
                }
            });
        },
        onChange: async info => {
            if (info.file.status === "uploading") {
                setUploading(true);
            } else if (info.file.status === "done") {
                const {fileUrl} = info.file.response;
                onChange(fileUrl);
                setUploading(false);
            } else if (info.file.status === "error") {
                message.error(info.file.response.error || "Failed to upload video", 5);
                setUploading(false);
            }
        }
    };

    return (
        <div
            style={{
                position: "relative",
                border: fileUrl ? void 0 : "1px dashed #747F92",
                borderRadius: 10,
                overflow: "hidden",
                ...videoStyle,
                ...containerStyle
            }}>
            {fileUrl ? (
                <div style={{position: "relative"}}>
                    <video
                        width={videoStyle.width}
                        height={videoStyle.height}
                        style={{outline: "none", ...videoStyle}}
                        src={fileUrl}
                        controls={true}
                    />
                    <CloseIcon
                        onClick={() => onChange(null)}
                        style={{
                            fill: "#747F92",
                            width: 16,
                            height: 16,
                            cursor: "pointer",
                            position: "absolute",
                            right: 10,
                            top: 10
                        }}
                    />
                </div>
            ) : (
                <Upload {...uploadProps}>
                    <UploadButton uploading={uploading} />
                </Upload>
            )}
        </div>
    );
};
