import {BudgetCategoryTypes} from "./consts";
import {MainServices} from "../../consts.js";
import {TRANSFER_COMMENTS} from "./excelExporter";

export const constructBudgetObject = (budgets, generalBudgetKey = "amount") =>
    budgets?.reduce((acc, {category, amount, ...budget}) => {
        return {
            ...acc,
            ...budget,
            [category ?? generalBudgetKey]: amount
        };
    }, {});

export const destructBudgetObject = (budget = {}, services) => {
    const budgetCategories = services?.filter(categoryId => budget.hasOwnProperty(categoryId)) ?? [];
    return [...(budget.hasOwnProperty("amount") ? [BudgetCategoryTypes.GENERAL] : []), ...budgetCategories];
};

export const userHasCategoryBudget = budget =>
    budget ? Object.values(MainServices).some(categoryId => budget.hasOwnProperty(categoryId)) : false;

export const getLoginStatus = ({firstLogin, sentInvitationLink}) =>
    firstLogin === false ? "המשתמש התחבר" : sentInvitationLink ? "נשלח מייל התחברות" : "לא נשלח מייל התחברות";

export const getSubAdminIdsToOrder = (admins, adminsOrders) => {
    const idsToOrderAmount = admins?.reduce((acc, subAdmin) => {
        const totalOrderAmount = adminsOrders?.[subAdmin?.userId]?.reduce((total, {amount, comment}) => {
            if (!TRANSFER_COMMENTS.some(transferComment => comment.includes(transferComment))) {
                total += amount * -1;
            }

            return total;
        }, 0);

        return {
            ...acc,
            [subAdmin.userId]: totalOrderAmount
        };
    }, {});

    return Object.keys(idsToOrderAmount ?? {}).length ? idsToOrderAmount : {};
};
