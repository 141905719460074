import React from "react";
import BaseDynamicLayoutPage from "./BaseDynamicLayoutPage";
import {useRequest} from "../../utils/hooks";
import {MarketplaceFilter} from "../MarketplaceFilter/MarketplaceFilter.jsx";
import {Skeleton} from "antd";
import "./dynamic-layout-page.css"

const DynamicLayoutPage = ({history, match}) => {
    const {pageId} = match.params;
    const [pageLayout, loadingPageLayoutPage] = useRequest(`/api/pageLayout/${pageId}`, "GET", null, [], !!pageId);

    return (
        <>
            {!loadingPageLayoutPage && !pageLayout?.isIsolated ? (
                <MarketplaceFilter />
            ) : null}
            <BaseDynamicLayoutPage
                history={history}
                pageLayout={pageLayout}
                loadingPageLayoutPage={loadingPageLayoutPage}
            />
        </>
    );
};

export default DynamicLayoutPage;
