import React, {useCallback, useContext, useMemo, useState} from "react";
import moment from "moment-timezone";
import {parse as qsParse} from "query-string";
import {useLocation} from "react-router-dom";
import {sumBy} from "lodash";
import {ConfigProvider, Dropdown, message, Switch} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import PageTitleHeader from "../../components/PageTitle";
import {OrderStatus, RoundedTable, SquareButton} from "../../components";
import {TableDateSearch} from "../../admin/components";
import columns from "../../admin/AdminOrders/columns";
import {AppContext} from "../../AppContext";
import {useRequest} from "../../utils/hooks";
import {getLogger} from "../../Logger";
import {HttpClient} from "../../http/HttpClient";
import {ExportCompanyOrdersToExcel} from "../../orders/ExportCompanyOrdersToExcel";
import {ExportSubAdminOrdersStatics} from "../../orders/ExportSubAdminOrdersStatics";
import {ORDERS_PER_PAGE} from "../../admin/AdminOrders/consts";
import "../../admin/AdminOrders/admin-orders.css";
import {getOrderPrice} from "../../utils/calculationUtils.jsx";
import {openWindow} from "../../http/WindowOpener.jsx";
import {TableDateSearchProvider} from "../../admin/Context/TableDateSearchContext.jsx";

export const CompanyOrders = ({match, withHeader = true}) => {
    const {me, services, company} = useContext(AppContext);
    const [searchInput, setSearchInput] = useState(null);
    const [loadingExportToExcel, setLoadingExportToExcel] = useState(false);
    const [searchByCreated, setSearchByCreated] = useState(true);
    const [searchDate, setSearchDate] = useState([moment().startOf("month"), moment().endOf("month")]);
    const location = useLocation();
    const [editOrder, setEditOrder] = useState(null);

    const ordersUrl = useMemo(() => {
        const queryParams = qsParse(location.search);

        return `/api/companies/orders/${match.params.companyId}/?userId=${
            queryParams.userId ?? ""
        }&byCreatedAt=${searchByCreated}`;
    }, [match.params, location.search, searchByCreated]);

    const log = useMemo(() => getLogger({}, "CompanyOrders"), []);

    const [orders = [], isLoading, _isErrorOnLoadingOrders, fetchOrders] = useRequest(
        ordersUrl,
        "post",
        {
            startTime: searchDate[0].valueOf(),
            endTime: searchDate[1].valueOf()
        },
        [searchDate],
        searchDate?.length
    );

    const filteredOrders = useMemo(
        () =>
            searchInput
                ? orders?.filter(
                      order =>
                          ["companyName", "providerBrand", "proposalId", "productName", "companyContactName"].some(
                              key => String(order[key])?.toLowerCase().includes(searchInput.toLowerCase())
                          ) || searchInput === order.eventId
                  )
                : orders,
        [orders, searchInput]
    );

    const totalOrdersPrice = useMemo(
        () => sumBy(filteredOrders, order => (order.status === "accepted" ? Math.max(0, getOrderPrice(order)) : 0)),
        [filteredOrders]
    );

    const exportCompanyOrdersTableToExcel = useCallback(async () => {
        setLoadingExportToExcel(true);
        const ordersToExport = (filteredOrders ?? []).filter(
            ({status}) => ![OrderStatus.cancelled, OrderStatus.declined, OrderStatus.cancelPending].includes(status)
        );

        const companyAdmins = await HttpClient.safeGet("/api/companies/me/admins");
        await ExportCompanyOrdersToExcel(ordersToExport, companyAdmins).catch(() =>
            message.error("שגיאה בעת ייצוא הנתונים")
        );
        log("Exported orders to excel", {
            orders: ordersToExport.map(({proposalId}) => proposalId)
        });
        setLoadingExportToExcel(false);
    }, [filteredOrders]);

    const exportOrdersToSubAdminsToExcel = useCallback(async () => {
        setLoadingExportToExcel(true);
        const ordersToExport = (filteredOrders ?? []).filter(
            ({status}) => ![OrderStatus.cancelled, OrderStatus.declined, OrderStatus.cancelPending].includes(status)
        );

        const companyAdmins = await HttpClient.safeGet("/api/companies/me/admins");
        const companyBudgets = await HttpClient.safeGet("/api/budget/company");
        await ExportSubAdminOrdersStatics(ordersToExport, companyAdmins, companyBudgets?.budgets);
        log("Exported sub admin orders statics to excel", {
            orders: ordersToExport.map(({proposalId}) => proposalId)
        });
        setLoadingExportToExcel(false);
    }, [filteredOrders]);

    const onEditOrder = useCallback(order => {
        openWindow(`/events/${order.eventId}/edit`);
    }, []);

    return (
        <div style={{display: "flex", justifyContent: "center"}}>
            <ConfigProvider direction="rtl">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: 10,
                        paddingRight: 10,
                        width: "100px",
                        flex: 1
                    }}>
                    {withHeader ? (
                        <PageTitleHeader style={{marginBottom: 15, marginTop: 80, marginLeft: "15px"}}>
                            הזמנות
                        </PageTitleHeader>
                    ) : null}
                    <div className="admin-orders-top-bar">
                        <div className="admin-orders-top-bar-right-side">
                            <TableDateSearchProvider>
                                <TableDateSearch
                                    date={searchDate}
                                    onDatePicked={setSearchDate}
                                    onSearch={setSearchInput}
                                    disableFuture={searchByCreated}
                                    allowClear={false}
                                />
                            </TableDateSearchProvider>
                            <div
                                className={`admin-orders-date-search-switch-container ${
                                    !searchByCreated ? "active" : ""
                                }`}>
                                <span className="admin-orders-date-search-switch-label">
                                    סינון לפי תאריך {searchByCreated ? "הזמנה" : "הספקה"}
                                </span>
                                <Switch
                                    className="admin-orders-date-search-switch"
                                    checked={!searchByCreated}
                                    onClick={() => setSearchByCreated(prevState => !prevState)}
                                />
                            </div>
                        </div>
                        <div className="admin-orders-top-bar-left-side">
                            <div className="admin-orders-total-orders-amount">
                                סה״כ הזמנות לתקופה:
                                <span>{totalOrdersPrice?.toLocaleString()}</span>₪
                            </div>

                            <Dropdown
                                disabled={!orders?.length}
                                menu={{
                                    items: [
                                        {
                                            key: "דוח תקופתי - הזמנות",
                                            disabled: loadingExportToExcel,
                                            label: (
                                                <div onClick={exportCompanyOrdersTableToExcel}>דוח תקופתי - הזמנות</div>
                                            )
                                        },
                                        {
                                            key: "דוח תקופתי - אדמינים",
                                            disabled: loadingExportToExcel,
                                            label: (
                                                <div onClick={exportOrdersToSubAdminsToExcel}>דוח תקופתי - אדמינים</div>
                                            )
                                        }
                                    ]
                                }}>
                                <SquareButton
                                    className="admin-orders-export-button"
                                    loading={loadingExportToExcel}
                                    disabled={!orders?.length}>
                                    <DownloadOutlined />
                                    <span>יצא לאקסל</span>
                                </SquareButton>
                            </Dropdown>
                        </div>
                    </div>
                    <RoundedTable
                        rowKey="eventId"
                        pagination={{
                            position: ["bottomCenter"],
                            showSizeChanger: false,
                            defaultPageSize: ORDERS_PER_PAGE,
                            showTotal: total => <span>סה״כ {total}</span>
                        }}
                        scroll={{x: 1400}}
                        loading={isLoading}
                        dataSource={filteredOrders}
                        columns={columns(me, services, onEditOrder)}
                    />
                </div>
            </ConfigProvider>
        </div>
    );
};
