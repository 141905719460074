import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../AppContext";
import {HttpClient} from "../../http/HttpClient";
import {message, Spin} from "antd";
import {EXCenterBirthdayAvatar} from "../birhtdays/EXCenterBirthdayAvatar";
import moment from "moment-timezone";
import {EXCenterContext} from "../EXCenterContext";
import {PageLoader} from "../../components/PageLoader";

const BirthdayWish = ({wish}) => {
    const {theme} = useContext(EXCenterContext);
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: 25,
                borderTop: "1px solid #DAE0E4",
                borderBottom: "1px solid #DAE0E4"
            }}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <EXCenterBirthdayAvatar
                        style={{width: "auto", padding: 0, paddingTop: 0}}
                        hoverEffect={false}
                        width={40}
                        height={40}
                        employee={{
                            imageUrl: wish.fromImageUrl,
                            firstName: wish.fromName
                        }}
                    />
                    <span style={{color: theme.textColor, fontSize: 14, fontWeight: 500, marginLeft: 10}}>
                        {wish.fromName}
                    </span>
                </div>
                <span style={{color: theme.textColor, fontSize: 14, fontWeight: 500, marginLeft: 10}}>
                    {moment(wish.createdAt).format("DD/MM/YYYY")}
                </span>
            </div>
            <div
                style={{
                    display: "flex",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#747F92",
                    lineHeight: "22px",
                    marginTop: 10,
                    paddingLeft: 5
                }}>
                {wish.wishText}
            </div>
        </div>
    );
};

export const EXCenterProfileBirthdayWishes = () => {
    const {me} = useContext(AppContext);
    const {theme} = useContext(EXCenterContext);
    const [wishes, setWishes] = useState(null);

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const wishes = await HttpClient.get("/api/wishes/me");
                setWishes(wishes);
            } catch (e) {
                message.error("Something went wrong, please try again later.", 5);
            }
        });
    }, []);

    if (wishes === null) {
        return <PageLoader align="flex-start" top={80} height={400} />;
    }

    return (
        <div style={{display: "flex", justifyContent: "center", marginTop: 50, width: "100%"}}>
            <div
                style={{display: "flex", flexDirection: "column", alignItems: "center", maxWidth: 1200, width: "100%"}}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        fontSize: 28,
                        fontWeight: 700,
                        color: theme.textColor,
                        marginBottom: 30
                    }}>
                    Happy birthday {me.firstName || me.companyName}!
                </div>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    {wishes.map(wish => (
                        <BirthdayWish key={wish.createdAt} wish={wish} />
                    ))}
                </div>
            </div>
        </div>
    );
};
