import {useContext, useEffect, useReducer, useState} from "react";
import {AppContext} from "../AppContext";
import {eventByDate} from "./EventByDate";
import {HttpClient} from "../http/HttpClient";
import {message, Tag, Form} from "antd";
import {GoogleAnalytics} from "../GoogleAnalytics";
import moment from "moment-timezone";
import {dateFormat, toMomentDate} from "../utils/DateFormat";
import {FrequencyMapper} from "./FrequencyMapper";
import phoneFormat from "phone";
import {locationKeyToTextMap, LocationLabels} from "../data/locations";
import {AutoDirectionProviderWithValueChange} from "../AutoDirectionProvider";
import {FormInput, FormLabel, FormTextArea} from "../components/form";
import React from "react";
import {EventInfoTitle} from "./EventInfoTitle";
import {EventInfoFooter} from "./EventInfoFooter";
import {uniq} from "lodash";
import {withRouter} from "react-router";
import {openWindow} from "../http/WindowOpener";
import {EventBookings} from "./EventBookings";
import {EditableInput} from "../components/EditableInput";

const EventInfoFrom = ({
    history,
    event,
    currentStart,
    currentEnd,
    onClose,
    onBookAppointment,
    onEventUpdate,
    onEventCancel,
    onEditEnter,
    cancelling,
    saving
}) => {
    const {me, theme} = useContext(AppContext);
    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        registering: false,
        registered: false,
        changedFields: [],
        unregisterEvent: null,
        phoneNumber: null,
        providerApproved: false
    });

    const [bookingModalVisibility, setBookingModalVisibility] = useState(false);

    const {registering, registered, changedFields, registerEvent, phoneNumber, providerApproved} = state;

    const {
        eventId,
        meetingLink,
        notes,
        address,
        location,
        appointmentBased,
        customSession,
        sessionType,
        providerId,
        companyId,
        registeredEmployees
    } = eventByDate(event, currentStart);

    const editableEvent = moment(dateFormat(currentStart), "YYYY-M-D").isSameOrAfter(
        moment(dateFormat(new Date()), "YYYY-M-D")
    );

    useEffect(() => {
        Promise.resolve().then(async () => {
            if (me.type === "provider") {
                await fetchCompanyPhoneNumber();
            } else if (me.type === "company") {
                if (!customSession || (customSession && sessionType === "provider")) {
                    await fetchProviderPhoneNumber();
                }
            } else {
                amIRegistered();
            }
        });
    }, []);

    const amIRegistered = () => {
        if (Array.isArray(registeredEmployees)) {
            setState({
                registered: registeredEmployees.some(reg => reg.employeeId === me.userId)
            });
        }
    };

    const fetchCompanyPhoneNumber = async () => {
        try {
            const {phone, countryCode} = await HttpClient.get(`/api/companies/${companyId}`);
            setPhoneNumber(phone, countryCode);
        } catch (e) {
            message.error(e.message, 3);
        }
    };

    const fetchProviderPhoneNumber = async () => {
        try {
            const {phone, countryCode, approved} = await HttpClient.get(
                `/api/providers/${providerId}?eventId=${eventId}`
            );
            if (event.appointmentBased && me.type === "employee") {
                setPhoneNumber(phone, countryCode);
            }
            setState({providerApproved: approved});
        } catch (e) {
            message.error(e.message, 3);
        }
    };

    const register = async (applyType = "only", date = "") => {
        setState({registering: true});
        try {
            GoogleAnalytics.event("Company Schedule", "Register Session", eventId);
            await HttpClient.post(`/api/events/${eventId}/register?applyType=${applyType}&date=${date}`, {});
            setState({registered: true});

            if (applyType === "all") {
                addRegisteredEmployeeLocallyToAllSessions();
            } else {
                addRegisteredEmployeeLocallyPerDate();
            }
        } catch (e) {
            message.error(e.message, 3);
        }
        setState({registering: false});
    };

    const ensureRegisteredEmployeesPerDate = () => {
        const date = moment(dateFormat(currentStart), "YYYY-M-D").format("YYYY-MM-DD");
        event.byDate = event.byDate || {};
        event.byDate[date] = event.byDate[date] || {};
        event.byDate[date].registeredEmployees = event.byDate[date].registeredEmployees || [];
        return event.byDate[date].registeredEmployees;
    };

    const addRegisteredEmployeeLocallyPerDate = () => {
        addRegisteredEmployeeByRef(ensureRegisteredEmployeesPerDate());
    };

    const addRegisteredEmployeeLocallyToAllSessions = () => {
        event.registeredEmployees = event.registeredEmployees || [];
        addRegisteredEmployeeByRef(event.registeredEmployees);
    };

    const addRegisteredEmployeeByRef = registeredEmployeesRef => {
        if (registeredEmployeesRef.every(reg => reg.employeeId !== me.userId)) {
            registeredEmployeesRef.push({
                timestampMs: Date.now(),
                employeeId: me.userId,
                employeeFirstName: me.firstName,
                employeeLastName: me.lastName,
                employeeEmail: me.email
            });
        }
    };

    const removeRegisteredEmployeeLocallyPerDate = () => {
        removeRegisteredEmployeeByRef(ensureRegisteredEmployeesPerDate());
    };

    const removeRegisteredEmployeeLocallyFromAllSessions = () => {
        event.registeredEmployees = event.registeredEmployees || [];
        removeRegisteredEmployeeByRef(event.registeredEmployees);
        Object.keys(event.byDate || {}).forEach(date => {
            const perDate = event.byDate ? event.byDate[date] || {} : {};
            if (Array.isArray(perDate.registeredEmployees)) {
                perDate.registeredEmployees = perDate.registeredEmployees.filter(reg => reg.employeeId !== me.userId);
            }
        });
    };

    const removeRegisteredEmployeeByRef = registeredEmployeesRef => {
        const foundIdx = registeredEmployeesRef.findIndex(reg => reg.employeeId === me.userId);
        if (foundIdx >= 0) {
            registeredEmployeesRef.splice(foundIdx, 1);
        }
    };

    const doRegistration = async event => {
        if (FrequencyMapper.oneTime(event)) {
            await register();
        } else {
            const date = moment(dateFormat(currentStart), "YYYY-M-D").format("YYYY-MM-DD");
            await register("only", date);
        }
    };

    const unregister = async () => {
        setState({registering: true});
        try {
            const applyType = isRegisteredToAllSessions() ? "all" : "only";
            const date = applyType === "all" ? "" : moment(dateFormat(currentStart), "YYYY-M-D").format("YYYY-MM-DD");

            GoogleAnalytics.event("Company Schedule", "Unregister Session", event.eventId);
            await HttpClient.post(`/api/events/${eventId}/unregister?applyType=${applyType}&date=${date}`, {});
            setState({registered: false});

            if (applyType === "all") {
                removeRegisteredEmployeeLocallyFromAllSessions();
            } else {
                removeRegisteredEmployeeLocallyPerDate();
            }
        } catch (e) {
            message.error(e.message, 3);
        }
        setState({registering: false});
    };

    const isRegisteredToAllSessions = () => {
        if (Array.isArray(event.registeredEmployees)) {
            return event.registeredEmployees.some(reg => reg.employeeId === me.userId);
        }

        return false;
    };

    const join = async () => {
        if (meetingLink) {
            openWindow(
                `/api/events/${eventId}/track?date=${toMomentDate(currentStart).format("YYYY-MM-DD")}`,
                "_blank"
            );
            GoogleAnalytics.event("Company Schedule", "Join Session", eventId);
            onClose(event);
        }
    };

    const setPhoneNumber = (phone, countryCode) => {
        const [formattedPhone] = phoneFormat(phone, countryCode);
        setState({
            phoneNumber: formattedPhone ? formattedPhone.replace("+", "") : phone.replace("+", "")
        });
    };

    const canEditAddress = () => {
        return me.type === "provider" && event.offsiteBased && editableEvent;
    };

    const canEditNotes = () => {
        return me.type === "provider" && editableEvent;
    };

    const backColor = isEnabled => {
        return isEnabled ? "#FCFDFE" : "#fafafa";
    };

    const cursorType = (isEnabled, defaultCursor) => {
        return isEnabled ? defaultCursor || "text" : "not-allowed";
    };
    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", padding: 15}}>
            {bookingModalVisibility ? (
                <EventBookings
                    visible={bookingModalVisibility}
                    eventId={eventId}
                    date={moment(currentStart, "YYYY-M-D").format("YYYY-MM-DD")}
                    onClose={() => setBookingModalVisibility(false)}
                />
            ) : null}
            <EventInfoTitle
                me={me}
                event={event}
                providerApproved={providerApproved}
                currentStart={currentStart}
                currentEnd={currentEnd}
                showBack={false}
            />
            <Tag
                color="white"
                style={{
                    borderRadius: 20,
                    padding: "4px 11px",
                    marginTop: 5,
                    fontSize: 15,
                    color: "#11C296",
                    border: "1px solid #11C296"
                }}>
                {location ? locationKeyToTextMap[location] : locationKeyToTextMap[LocationLabels.ONLINE]}
            </Tag>
            {eventId ? (
                <Form
                    initialValues={{
                        meetingLink,
                        notes,
                        address
                    }}
                    style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}
                    onFinish={values => onEventUpdate(values, changedFields)}
                    onValuesChange={changedValues => {
                        setState({
                            changedFields: uniq(changedFields.concat(Object.keys(changedValues)))
                        });
                    }}>
                    {appointmentBased || location === LocationLabels.ONSITE || location === LocationLabels.OFFSITE ? (
                        <Form.Item
                            className="wb-meeting-link"
                            style={{
                                marginTop: 20,
                                marginBottom: 0,
                                display: "flex",
                                textAlign: "center",
                                justifyContent: "center",
                                width: "100%"
                            }}
                            name="address">
                            <AutoDirectionProviderWithValueChange style={{width: "100%"}}>
                                {({onChange, defaultValue, value}) => (
                                    <FormInput
                                        onChange={onChange}
                                        defaultValue={defaultValue}
                                        value={value}
                                        style={{
                                            maxWidth: 400,
                                            cursor: cursorType(canEditAddress()),
                                            userSelect: "none",
                                            backgroundColor: backColor(canEditAddress())
                                        }}
                                        disabled={!canEditAddress()}
                                        placeholder={
                                            me.type === "company"
                                                ? "Address / Room / Floor number"
                                                : "No room scheduled yet."
                                        }
                                    />
                                )}
                            </AutoDirectionProviderWithValueChange>
                        </Form.Item>
                    ) : (
                        <Form.Item
                            className="wb-meeting-link"
                            style={{
                                marginTop: 20,
                                marginBottom: 0,
                                display: "flex",
                                textAlign: "center",
                                justifyContent: "center",
                                width: "100%"
                            }}
                            name="meetingLink"
                            rules={[{type: "url", message: "Please enter valid meeting link."}]}>
                            {me.type === "provider" ? (
                                <FormInput
                                    disabled={!editableEvent}
                                    style={{
                                        maxWidth: 400,
                                        textAlign: "center",
                                        userSelect: "none",
                                        backgroundColor: backColor(editableEvent),
                                        cursor: cursorType(editableEvent)
                                    }}
                                    placeholder="Insert meeting link"
                                />
                            ) : (
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <FormLabel
                                        onClick={async () => await join()}
                                        style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            cursor: cursorType(!!meetingLink, "pointer"),
                                            maxWidth: 400,
                                            textAlign: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                            backgroundColor: backColor(false),
                                            userSelect: "none"
                                        }}>
                                        {meetingLink || "No meeting link yet."}
                                    </FormLabel>
                                </div>
                            )}
                        </Form.Item>
                    )}
                    <Form.Item
                        className="wb-meeting-notes"
                        style={{
                            marginBottom: 0,
                            marginTop: 10,
                            display: "flex",
                            textAlign: "center",
                            justifyContent: "center",
                            width: "100%"
                        }}
                        name="notes">
                        <EditableInput
                            style={{
                                color: "rgba(0, 0, 0, 0.65)",
                                maxWidth: 400,
                                cursor: cursorType(canEditNotes()),
                                userSelect: "none",
                                backgroundColor: backColor(canEditNotes())
                            }}
                            disabled={!canEditNotes()}
                        />
                    </Form.Item>
                    <Form.Item style={{marginBottom: 0, width: "100%"}}>
                        <EventInfoFooter
                            me={me}
                            event={eventByDate(event, currentStart)}
                            currentStart={currentStart}
                            phoneNumber={phoneNumber}
                            saving={saving}
                            cancelling={cancelling}
                            onCancel={onEventCancel}
                            registered={registered}
                            registering={registering}
                            onRegister={event => doRegistration(event)}
                            onUnregister={event => unregister(event)}
                            onBookAppointment={editableEvent ? onBookAppointment : null}
                            onRegistrationView={() => setBookingModalVisibility(true)}
                            onEdit={onEditEnter}
                        />
                    </Form.Item>
                </Form>
            ) : null}
        </div>
    );
};

export default withRouter(EventInfoFrom);
