import React, {useCallback, useMemo} from "react";
import {Tag, Tree, TreeSelect} from "antd";
import {flatten, uniq, uniqBy} from "lodash";
import {useRequest} from "../../utils/hooks";

export const AdminProductSearchTags = ({productTags, setProductTags, productId}) => {
    const [allSearchTags = []] = useRequest("/admin/api/searchTags", "GET", null, [], !!productId);

    const treeData = useMemo(() => {
        if (!allSearchTags) return [];

        const searchTags = allSearchTags.filter(tag => !productTags?.some(({tagId}) => tagId === tag.tagId));

        const rootNodes = searchTags.filter(tag => !searchTags.find(t => t.children?.includes(tag.tagId)));
        const tagsMap = searchTags.reduce((acc, tag) => ({...acc, [tag.tagId]: tag}), {});

        const createTreeNode = (node, fatherKey) => {
            if (!node) {
                return null;
            }

            const key = fatherKey ? `${fatherKey}-${node?.tagId}` : node?.tagId;

            return {
                key: node.tagId,
                title: node?.name,
                value: node?.tagId,
                children: [
                    ...(node?.children ?? []).map(childId => createTreeNode(tagsMap?.[childId], key)).filter(Boolean)
                ]
            };
        };

        const tree = [...rootNodes.map(rootNode => createTreeNode(rootNode))];

        function compareHebrew(a, b) {
            return a.title.localeCompare(b.title, "he");
        }

        function recursiveSort(tag) {
            if (tag.children) {
                tag.children.sort(compareHebrew);
                tag.children.forEach(child => recursiveSort(child));
            }
        }

        tree.sort(compareHebrew);
        tree.forEach(tag => recursiveSort(tag));

        return tree;
    }, [allSearchTags, productTags]);

    const onRemoveTag = useCallback(tagIdToRemove => {
        setProductTags(prevTags => prevTags.filter(({tagId: prevTagId}) => prevTagId !== tagIdToRemove));
    }, []);

    const getTagWeight = useCallback(
        tag => {
            if (!tag || !tag?.products) return null;

            const tagWeight = tag?.products.find(product => product.productId === productId)?.weight;
            return tagWeight ?? null;
        },
        [productId]
    );

    const onWeightChange = useCallback(
        (tagId, weight) => {
            setProductTags(prevTags =>
                prevTags.map(tag => {
                    if (tag.tagId === tagId) {
                        const updatedProducts = (tag.products || []).map(product => {
                            if (product.productId === productId) {
                                return {...product, weight};
                            }
                            return product;
                        });
                        return {...tag, products: updatedProducts};
                    }
                    return tag;
                })
            );
        },
        [productId]
    );

    const onToggleWeight = useCallback(tag => {
        const weight = getTagWeight(tag);
        if (weight === 1) {
            onWeightChange(tag.tagId, 2);
        } else if (weight === 2) {
            onWeightChange(tag.tagId, 1);
        }
    }, []);

    const updateProductSearchTags = useCallback(
        selectedNodes => {
            for (const node of selectedNodes) {
                const {value: selectedSearchTagId, title: selectedSearchTagName} = node;
                if (!selectedSearchTagId || !selectedSearchTagName) continue;

                const globalSearchTag = allSearchTags.find(tag => tag.tagId === selectedSearchTagId);

                globalSearchTag.products = globalSearchTag.products
                    ? [...globalSearchTag.products, {productId, weight: 1}]
                    : [{productId, weight: 1}];

                globalSearchTag.products = uniqBy(globalSearchTag.products, tagProducts => tagProducts.productId);

                setProductTags(prevTags => [...prevTags, globalSearchTag]);
            }
        },
        [allSearchTags, productTags, productId]
    );

    return (
        <div className="admin-product-search-tags">
            <div className="admin-product-search-tags-label">תגיות חיפוש</div>
            {!productId ? <span>לא ניתן לבחור תגיות למוצר חדש</span> : null}

            <Tree
                treeData={treeData}
                multiple={true}
                selectedKeys={[]}
                disabled={!productId}
                onSelect={(selectedKeys, {selectedNodes}) => {
                    updateProductSearchTags(selectedNodes);
                }}
            />

            <div className="admin-search-tags-key-words-container">
                {productTags?.map(tag => (
                    <Tag
                        className="admin-search-tags-key-word-tag"
                        closable={true}
                        onClick={() => onToggleWeight(tag)}
                        {...(getTagWeight(tag) > 1 ? {color: "#ED7FA6"} : {})}
                        onClose={() => onRemoveTag(tag.tagId)}
                        key={tag.name}>
                        {tag.name}
                    </Tag>
                ))}
            </div>
        </div>
    );
};
