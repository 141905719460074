import React from "react";
import {Tabs} from "antd";
import {AppContext} from "../AppContext";
import {Redirect, Route, Switch} from "react-router";
import {AdminProviderApproval} from "./AdminProviderApproval";
import {AdminEvents} from "./AdminEvents";
import {AdminProviders} from "./AdminProviders";
import {AdminVendors} from "./AdminVendors";
import {AdminCategories} from "./categories/AdminCategories";
import {extractTabFromUrl} from "../dashboards/DefaultAppDashboard";
import {AdminProfessions} from "./AdminProfessions";
import {AdminProviderServices} from "./AdminProviderServices";
import {AdminCompanyEmployees} from "./AdminCompanyEmployees";
import {AdminUsers} from "./AdminUsers";
import {AdminEmails} from "./AdminEmails";
import {AdminUploadPage} from "./upload/AdminUploadPage.jsx";
import {AdminReviews} from "./review/AdminReviews";
import {AdminTutorials} from "./tutorial/AdminTutorials";
import AdminBillingDashboard from "./billing/AdminBillingDashboard";
import {AdminProducts, AdminProductsOld} from "./AdminProducts";
import {AdminDebug} from "./AdminDebug";
import {AdminOrders} from "./AdminOrders";
import {AdminCompanies} from "./AdminCompanies";
import {AdminSiteSettings} from "./AdminSiteSettings";
import {PopupSettings} from "./PopupSettings";
import {AdminSearchTags} from "./AdminSearchTags";
import {AdminCoupons} from "./AdminCoupons";
import AdminLocations from "./AdminLocations";
import {AdminCalculator} from "./AdminCalculator";
import AdminSelectPage from "./AdminSelectPage";
import AdminInvoices from "./AdminInvoices";
import {ADMIN_PATH_NAME} from "./consts";
import {AdminProductBundles} from "./AdminProductBundles";
import {AdminUserManagement} from "./AdminUserManagement";
import "./admin-dashboard.css";
import {AdminCompanyAssignCSM} from "./AdminCompanyAssignCSM/index.jsx";
import {AdminOrdersManagement} from "./AdminOrdersManagement/index.jsx";
import {AdminAlertsManagement} from "./AdminAlertsManagement/index.js";

export class AdminDashboard extends React.Component {
    static contextType = AppContext;

    navigateTo(key) {
        const {history} = this.props;
        history.push(`/${ADMIN_PATH_NAME}/${key}`);
    }

    render() {
        const {me, isMobile} = this.context;

        const activeKey = extractTabFromUrl(
            /^\/wellbAdmin2019\/(providers|vendors|invoices|categories|products|productBundles|transactions|orders|companies|users|siteSettings|professions|billing|delivery|reviews|tutorials|upload|searchTags|calculator|selectPage|coupons|locations|debug|popups|userManagement|companyAssignCSM|findOrders|alertsManagement)(.*)$/
        );

        if (!me.type) return null;

        if (!activeKey) {
            return <Redirect to={me.type === "company" ? `/${ADMIN_PATH_NAME}/findOrders` : "/dashboard/schedule"} />;
        }

        const defaultTabKey = "services";

        const tabs = [
            {
                label: "חברות",
                key: "companies",
                children: (
                    <Switch>
                        <Route exact path={`/${ADMIN_PATH_NAME}/companies`} component={AdminCompanies} />
                        <Route
                            exact
                            path={`/${ADMIN_PATH_NAME}/companies/:companyId/employees`}
                            component={AdminCompanyEmployees}
                        />
                    </Switch>
                )
            },
            {
                label: "שותפים",
                key: "vendors",
                children: (
                    <Switch>
                        <Route exact path={`/${ADMIN_PATH_NAME}/vendors`} component={AdminVendors} />
                    </Switch>
                )
            },
            {
                label: "חשבונית שותפים",
                key: "invoices",
                children: (
                    <Switch>
                        <Route exact path={`/${ADMIN_PATH_NAME}/invoices`} component={AdminInvoices} />
                    </Switch>
                )
            },

            {
                label: "מוצרים",
                key: "products",
                children: (
                    <Switch>
                        <Route exact path={`/${ADMIN_PATH_NAME}/products`} component={AdminProducts} />
                    </Switch>
                )
            },
            {
                label: "הזמנות",
                key: "orders",
                children: (
                    <Switch>
                        <Route exact path={`/${ADMIN_PATH_NAME}/orders`} component={AdminOrders} />
                    </Switch>
                )
            },
            {
                label: "מאתר העסקאות",
                key: "findOrders",
                children: (
                    <Switch>
                        <Route exact path={`/${ADMIN_PATH_NAME}/findOrders`} component={AdminOrdersManagement} />
                    </Switch>
                )
            },
            {
                label: "אתרי בחירה",
                key: "selectPage",
                children: (
                    <Switch>
                        <Route exact path={`/${ADMIN_PATH_NAME}/selectPage`} component={AdminSelectPage} />
                    </Switch>
                )
            },
            {
                label: "תגיות חיפוש",
                key: "searchTags",
                children: (
                    <Switch>
                        <Route exact path={`/${ADMIN_PATH_NAME}/searchTags`} component={AdminSearchTags} />
                    </Switch>
                )
            },
            {
                label: "הגדרות אתר",
                key: "siteSettings",
                children: (
                    <Switch>
                        <Route exact path={`/${ADMIN_PATH_NAME}/siteSettings`} component={AdminSiteSettings} />
                    </Switch>
                )
            },
            {
                label: "פופאפים",
                key: "popups",
                children: (
                    <Switch>
                        <Route exact path={`/${ADMIN_PATH_NAME}/popups`} component={PopupSettings} />
                    </Switch>
                )
            }
        ];

        return (
            <>
                <Tabs
                    animated={false}
                    className="wb-admin-tabs"
                    onTabClick={key => this.navigateTo(key)}
                    activeKey={activeKey}
                    items={tabs}
                    renderTabBar={(DefaultTabBarProps, DefaultTabBar) =>
                        isMobile ? <></> : <DefaultTabBar {...DefaultTabBarProps} />
                    }
                    defaultActiveKey={defaultTabKey}></Tabs>
                <Switch>
                    <Route exact path={`/${ADMIN_PATH_NAME}/upload`} component={AdminUploadPage} />
                    <Route exact path={`/${ADMIN_PATH_NAME}/upload/:providerId`} component={AdminUploadPage} />
                    <Route exact path={`/${ADMIN_PATH_NAME}/providers`} component={AdminProviders} />
                    <Route exact path={`/${ADMIN_PATH_NAME}/providers/approval`} component={AdminProviderApproval} />
                    <Route
                        exact
                        path={`/${ADMIN_PATH_NAME}/providers/:providerId/services`}
                        component={AdminProviderServices}
                    />
                    <Route path={`/${ADMIN_PATH_NAME}/billing/:date`} component={AdminBillingDashboard} />
                    <Route path={`/${ADMIN_PATH_NAME}/billing`} component={AdminBillingDashboard} />
                    <Route path={`/${ADMIN_PATH_NAME}/coupons`} component={AdminCoupons} />
                    <Route path={`/${ADMIN_PATH_NAME}/locations`} component={AdminLocations} />
                    <Route path={`/${ADMIN_PATH_NAME}/userManagement`} component={AdminUserManagement} />
                    <Route exact path={`/${ADMIN_PATH_NAME}/ordersManagement`} component={AdminOrdersManagement} />
                    <Route path={`/${ADMIN_PATH_NAME}/companyAssignCSM`} component={AdminCompanyAssignCSM} />
                    <Route path={`/${ADMIN_PATH_NAME}/alertsManagement`} component={AdminAlertsManagement} />
                    <Route exact path={`/${ADMIN_PATH_NAME}/delivery`} component={AdminEmails} />
                    <Route exact path={`/${ADMIN_PATH_NAME}/transactions`} component={AdminEvents} />
                    <Route exact path={`/${ADMIN_PATH_NAME}/professions`} component={AdminProfessions} />
                    <Route exact path={`/${ADMIN_PATH_NAME}/tutorials`} component={AdminTutorials} />
                    <Route exact path={`/${ADMIN_PATH_NAME}/users`} component={AdminUsers} />
                    <Route exact path={`/${ADMIN_PATH_NAME}/categories`} component={AdminCategories} />
                    <Route exact path={`/${ADMIN_PATH_NAME}/calculator`} component={AdminCalculator} />

                    {me?.features?.product_bundles ? (
                        <Route exact path={`/${ADMIN_PATH_NAME}/productBundles`} component={AdminProductBundles} />
                    ) : null}

                    <Route exact path={`/${ADMIN_PATH_NAME}/reviews`} component={AdminReviews} />
                    <Route exact path={`/${ADMIN_PATH_NAME}/reviews/:selectedReviewId`} component={AdminReviews} />

                    {me.features?.admin_debug ? (
                        <Route exact path={`/${ADMIN_PATH_NAME}/debug`} component={AdminDebug} />
                    ) : null}
                </Switch>
            </>
        );
    }
}
