import React, {useMemo} from "react";
import {Timeline} from "antd";
import {useRequest} from "../../utils/hooks.jsx";
import "./order-delivery-status.css";
import {CheckCircleFilled} from "@ant-design/icons";
import {openWindow} from "../../http/WindowOpener.jsx";
import classnames from "classnames";
import {StringBuilder} from "../../AppUtils.js";
import {StyledButton} from "../../components/index.jsx";
import {dateTimeRangeFormat} from "../../utils/DateUtils.jsx";
import {generateAssignUserContactWhatsAppLink} from "../utils.jsx";
import {generateOrderStatusSteps} from "./utils.jsx";

export const OrderDeliveryStatus = ({match}) => {
    const [eventInfo, loadingEvent, eventError, fetchEvent] = useRequest(
        `/api/events/${match?.params?.eventId}/orderDeliveryStatus`,
        "GET",
        null,
        [match?.params?.eventId],
        !!match?.params?.eventId
    );

    const orderTimelineSteps = useMemo(() => generateOrderStatusSteps(eventInfo), [eventInfo]);

    return (
        <>
            <div className="order-delivery-status-container">
                <div className="order-delivery-status-top-bar-container">
                    <div className="order-delivery-status-top-bar-title">
                        {new StringBuilder().append("הזמנה:").append(eventInfo?.proposalId).toString()}
                    </div>
                    <div className="order-delivery-status-top-bar-sub-title">🚚 סטטוס ההזמנה שלי</div>
                </div>

                <Timeline mode="right">
                    {(orderTimelineSteps ?? []).map((step, index) => {
                        return (
                            <Timeline.Item
                                key={index}
                                dot={
                                    step.isStepComplete ? (
                                        <CheckCircleFilled className="timeline-check-circle-icon" />
                                    ) : (
                                        step.icon
                                    )
                                }>
                                <div
                                    className={classnames("timeline-item-container", {
                                        "step-not-complete": !step.isStepComplete
                                    })}>
                                    <div className="order-delivery-status-title">{step.title}</div>
                                    <div
                                        className={classnames(
                                            "order-delivery-status-subtitle",
                                            {
                                                "order-delivery-status-subtitle-link":
                                                    !!step.subTitleLink && step.isStepComplete
                                            },
                                            step.subTitleLevel
                                        )}
                                        onClick={() => {
                                            if (step.subTitleLink && step.isStepComplete) {
                                                openWindow(step.subTitleLink);
                                            }
                                        }}>
                                        {step.subTitle}
                                    </div>
                                    <div>{step.description}</div>
                                </div>
                            </Timeline.Item>
                        );
                    })}
                </Timeline>

                <div className="order-delivery-status-updatedAt-container">
                    <div>תאריך עדכון אחרון</div>
                    <div>{dateTimeRangeFormat(eventInfo?.updatedAt)}</div>
                </div>

                <div className="order-delivery-status-button-container">
                    <StyledButton
                        className="order-delivery-status-button-qna"
                        onClick={() => {
                            eventInfo?.assignedCSMs?.[0] &&
                                openWindow(generateAssignUserContactWhatsAppLink(eventInfo.systemId));
                        }}>
                        לשאלות
                    </StyledButton>
                    <StyledButton
                        className="order-delivery-status-button-order-details"
                        onClick={() => openWindow(`/events/${eventInfo?.eventId}/orderV2`)}>
                        לצפייה בפרטי ההזמנה
                    </StyledButton>
                </div>
            </div>
        </>
    );
};
