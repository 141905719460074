import React from "react";
import {StatisticsLabel} from "../../components/StatisticsLabel";

export const AdminProviderActiveStatisticsLabel = ({statistics}) => {
    return (
        <StatisticsLabel
            statistics={[
                {
                    name: "מוצרים פעילים",
                    value: statistics?.activeProducts?.toLocaleString() || 0,
                    removeIfEmpty: true,
                    children: []
                },
                {
                    name: "שותפים פעילים",
                    value: statistics?.activeProviders?.toLocaleString() || 0,
                    removeIfEmpty: true,
                    children: []
                }
            ]}
            title={<span>סטטוס פעיל</span>}
        />
    );
};
