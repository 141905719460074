import React, {useCallback, useEffect, useMemo, useState} from "react";
import {PageSubTitleLabel, StyledButton} from "../../../components/index.jsx";
import "../../../admin/upload/admin-upload.css";
import {PlusOutlined, RightOutlined} from "@ant-design/icons";
import "./provider-sites.css";
import {ProviderSitesForm} from "./ProviderSitesForm.jsx";
import {Collapse, ConfigProvider, message, Spin} from "antd";
import {cloneDeep, set} from "lodash";
import shortid from "shortid";
import {TrashIcon} from "../../../icons/index.jsx";
import classnames from "classnames";
import {useRequest} from "../../../utils/hooks.jsx";
import {isSiteParamValid} from "../utils.jsx";

const {Panel} = Collapse;

export const ProviderSites = ({providerId, onSave}) => {
    const [sites, setSites] = useState([]);
    const [sitesStatus, setSitesStatus] = useState({});
    const [providerInfo, isLoadingProviderInfo] = useRequest(
        `/admin/api/providers/${providerId}`,
        "GET",
        null,
        [],
        !!providerId
    );

    useEffect(() => {
        if (providerInfo?.sites?.length) {
            setSites(providerInfo.sites);
        }
    }, [providerInfo]);

    useEffect(() => {
        setSitesStatus(current => sites?.reduce((acc, site) => ({...acc, [site?.siteId]: isSiteParamValid(site)}), []));
    }, [sites]);

    const onAddNewSite = useCallback(() => {
        setSites(currentSites => {
            const doesAllSitesHaveName = currentSites.every(site => !!site.name);
            if (!doesAllSitesHaveName) {
                return currentSites;
            }
            return [...currentSites, {siteId: shortid.generate()}];
        });
    }, [setSites]);

    const onRemoveSite = useCallback(
        siteId => {
            const isSiteAlreadyConnectedToAnyProduct = providerInfo?.services?.some(product =>
                product?.address?.some(address => address.siteId === siteId)
            );
            if (isSiteAlreadyConnectedToAnyProduct) {
                message.error("לא ניתו למחוק סניף שמקושר למוצרים");
                return;
            }
            setSites(currentSites => currentSites.filter(site => site.siteId !== siteId));
        },
        [setSites, providerInfo]
    );

    const isValidForm = useMemo(
        () => Object.values(sitesStatus).every(isValidForm => !!isValidForm),
        [sitesStatus, sites]
    );

    const onUpdateSite = useCallback((field, value, siteId) => {
        setSites(currentSites => {
            const updatedSiteIndex = currentSites.findIndex(site => site.siteId === siteId);
            currentSites[updatedSiteIndex] = set({...currentSites[updatedSiteIndex]}, field, value);
            return cloneDeep(currentSites);
        });
    }, []);

    return (
        <div className="provider-sites">
            {isLoadingProviderInfo ? (
                <Spin />
            ) : (
                <>
                    <div>
                        <PageSubTitleLabel className="admin-upload-sub-title">סניפי בית העסק</PageSubTitleLabel>
                        <div className="provider-sites-sub-title">סניפים המספקים הזמנות של בית העסק</div>
                        <div className="creation-new-site-container" onClick={onAddNewSite}>
                            <span className="creation-new-site-container-title">הוספת סניף חדש</span>
                            <div className="creation-new-site-container-icon">
                                <PlusOutlined />
                            </div>
                        </div>
                    </div>

                    <div className="sites-list-container">
                        {sites?.map((site, index) => {
                            return (
                                <ConfigProvider key={index} direction="rtl">
                                    <Collapse
                                        expandIcon={({isActive}) => (
                                            <RightOutlined style={{fontSize: 16}} rotate={isActive ? 90 : 0} />
                                        )}>
                                        <Panel
                                            header={site.name ?? "(שם הסניף)"}
                                            key={index}
                                            className={classnames("site-collapse", {
                                                error: !sitesStatus?.[site?.siteId]
                                            })}
                                            extra={
                                                !!onSave && (
                                                    <TrashIcon
                                                        style={{fill: "red", height: "20px"}}
                                                        onClick={() => onRemoveSite(site.siteId)}
                                                    />
                                                )
                                            }>
                                            <ProviderSitesForm
                                                formValues={site}
                                                onUpdateSite={onUpdateSite}
                                                readOnly={!onSave}
                                            />
                                        </Panel>
                                    </Collapse>
                                </ConfigProvider>
                            );
                        })}
                    </div>

                    {!!onSave && (
                        <StyledButton
                            className="provider-sites-save-btn"
                            onClick={() => onSave(sites)}
                            disabled={!isValidForm}>
                            שמירה
                        </StyledButton>
                    )}
                </>
            )}
        </div>
    );
};
