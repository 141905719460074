import {cloneDeep, isEmpty} from "lodash";
import {defaultSelectedTheme, defaultThemes} from "../../color/ColorTheme";
import {HttpClient} from "../../http/HttpClient";
import {attachCountryStateAndCity} from "../wizardUtils";

export class StepperSignUpClient {
    static async signUp(companyInfoBySteps) {
        let companyInfo = this.flatten(companyInfoBySteps);

        delete companyInfo.terms;

        companyInfo = this.attachContactName(companyInfo);
        companyInfo = this.attachBranding(companyInfo);
        companyInfo = attachCountryStateAndCity(companyInfo);
        companyInfo = await this.attachInitialBranch(companyInfo);

        await HttpClient.put("/api/companies", companyInfo);
    }

    static flatten(companyInfoBySteps) {
        return Object.keys(companyInfoBySteps).reduce((flatten, step) => {
            const stepInfo = companyInfoBySteps[step];
            return {
                ...flatten,
                ...stepInfo
            };
        }, {});
    }

    static attachBranding(companyInfo) {
        companyInfo = cloneDeep(companyInfo);

        companyInfo.branding = {
            logoUrl: companyInfo.logoUrl || null,
            primaryColor: !isEmpty(companyInfo.palette) ? companyInfo.palette[0] : "#3b99fc",
            secondaryColor: !isEmpty(companyInfo.palette) ? companyInfo.palette[1] : "#273444",
            selectedTheme: defaultSelectedTheme,
            themes: {
                ...defaultThemes
            }
        };

        delete companyInfo.logoUrl;
        delete companyInfo.palette;

        return companyInfo;
    }

    static attachContactName(companyInfo) {
        companyInfo = cloneDeep(companyInfo);
        companyInfo.contactName = `${companyInfo.firstName} ${companyInfo.lastName}`;
        delete companyInfo.firstName;
        delete companyInfo.lastName;
        return companyInfo;
    }

    static async attachInitialBranch(companyInfo) {
        const {imageUrl} = await HttpClient.get("/api/weekly/images/random?q=office");
        companyInfo = cloneDeep(companyInfo);
        companyInfo.branches = [
            {
                name: companyInfo.cityName || companyInfo.address,
                countryName: companyInfo.countryName,
                stateName: companyInfo.stateName,
                cityName: companyInfo.cityName,
                address: companyInfo.address,
                employeeCount: companyInfo.employeesCount || "300",
                isHQ: true,
                visibility: "all",
                coverPhoto: imageUrl
            }
        ];
        return companyInfo;
    }
}
