import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import moment from "moment";
import {ConfigProvider, Modal, Timeline, Spin, Tabs} from "antd";
import {isNumber} from "lodash";
import {BudgetCategoryTypes, LOGS_TO_SHOW} from "./consts";
import {SquareButton} from "../../components";
import {AppContext} from "../../AppContext";
import {Images} from "../../images/Images";
import {useRequest} from "../../utils/hooks";
import {NOOP} from "../../utils/NOOP";
import "./budget-history.css";
import {destructBudgetObject} from "./utils";
import {ServiceIdToName} from "../../consts.js";

const {TabPane} = Tabs;

export const CompanyBudgetHistoryModal = ({visible, onClose, history, budget: userBudget, user}) => {
    const {me, services} = useContext(AppContext);
    const [budgetLogs, loadingLogs] = useRequest(`/api/budgetLogs/${user?.userId ?? ""}`);
    const [logsToShow, setLogsToShow] = useState(LOGS_TO_SHOW);
    const [selectedCategory, setSelectedCategory] = useState();

    const getIcon = useCallback(
        ({type}) =>
            type === "ADD" ? (
                <img src={Images.companyBudgetHistoryPlusIcon} alt="plus" />
            ) : (
                <img src={Images.companyBudgetHistoryMinusIcon} alt="minus" />
            ),
        []
    );

    const budgetObject = useMemo(() => (user ? userBudget ?? 0 : me.budget), [user, userBudget, me.budget]);

    const budgetAmount = useMemo(() => {
        if (budgetObject?.hasOwnProperty(selectedCategory)) {
            return budgetObject[selectedCategory];
        }

        return budgetObject?.amount;
    }, [budgetObject, selectedCategory]);

    const budgetsCategories = useMemo(
        () =>
            destructBudgetObject(
                budgetObject,
                services?.map(({serviceId}) => serviceId)
            ),
        [budgetObject, services]
    );

    useEffect(() => {
        if (budgetsCategories) {
            setSelectedCategory(budgetsCategories[0]);
        }
    }, [budgetsCategories]);

    const userName = useMemo(
        () => (user ? `${user?.firstName} ${user?.lastName || ""}` : `${me?.firstName} ${me?.lastName || ""}`),
        [user, me]
    );

    const goToEvent = useCallback(
        eventId => {
            if (!eventId) return;

            history.push(`/events/${eventId}/orderV2`);
        },
        [history]
    );

    const getCommentLine = useCallback(
        ({comment, amount, createdAt, eventId}) => (
            <span className="budget-history-comment-line">
                {eventId ? (
                    <a
                        className="link-comment"
                        href={`/events/${eventId}/orderV2`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={e => {
                            e.preventDefault();
                            goToEvent(eventId);
                        }}>
                        {comment}
                    </a>
                ) : (
                    <span>{comment}</span>
                )}

                <span>
                    | {Math.abs(amount)} ₪ | {moment(createdAt).format("DD.MM.YY HH:mm")}
                </span>
            </span>
        ),
        [goToEvent, selectedCategory]
    );

    const timeLines = useMemo(() => {
        if (!isNumber(budgetAmount) || !budgetLogs?.length) {
            return [];
        }

        let totalBudget = budgetAmount ?? 0;
        const specificBudgetLogs = selectedCategory
            ? budgetLogs?.filter(
                  ({budgetCategory}) =>
                      budgetCategory === selectedCategory ||
                      (selectedCategory === BudgetCategoryTypes.GENERAL && !budgetCategory)
              )
            : budgetLogs;

        return specificBudgetLogs?.map(budgetLog => {
            const logLine = (
                <Timeline.Item key={budgetLog.id} dot={getIcon(budgetLog)}>
                    <div className="budget-history-timeline-item">
                        <span>{getCommentLine(budgetLog)}</span>
                        <span>₪ {totalBudget?.toLocaleString("en-GB", {maximumFractionDigits: 0})} </span>
                    </div>
                </Timeline.Item>
            );

            totalBudget -= budgetLog.amount;

            return logLine;
        });
    }, [budgetAmount, budgetLogs, getCommentLine]);

    const filteredTimeLines = useMemo(() => timeLines.slice(0, logsToShow), [timeLines, logsToShow]);

    const budgetTitle = useMemo(() => {
        if (me.features?.category_budget) {
            return ` היסטוריית תקציב${
                selectedCategory ? ` ${ServiceIdToName[selectedCategory] ?? "צף"}` : ""
            }: ${userName}`;
        }

        return ` היסטוריית תקציב: ${userName}`;
    }, [me, userName, selectedCategory]);

    return (
        <Modal
            centered={true}
            className="wb-modal-radius company-budget-history-modal"
            style={{borderRadius: "5px"}}
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            maskClosable={false}
            footer={null}
            title={null}
            closable={true}>
            <div className="budget-history-layout">
                <div className="budget-history-title-layout">
                    <div className="budget-history-title">{budgetTitle}</div>
                    <span className="budget-history-title-amount">
                        ₪ {budgetAmount?.toLocaleString("en-GB", {maximumFractionDigits: 0})}
                    </span>
                </div>
                <div className="budget-history-separator" />
                {me.features?.category_budget && budgetsCategories.length > 1 ? (
                    <Tabs className="budget-history-tabs-container" type="card" onChange={setSelectedCategory}>
                        {budgetsCategories.map(category => (
                            <TabPane tab={ServiceIdToName[category] ?? "תקציב צף"} key={category} />
                        ))}
                    </Tabs>
                ) : null}
                <ConfigProvider direction="rtl">
                    {loadingLogs ? (
                        <Spin size="large" />
                    ) : (
                        <>
                            <Timeline>{filteredTimeLines}</Timeline>
                            <SquareButton
                                className="budget-history-load-more-button"
                                disabled={logsToShow >= timeLines?.length}
                                onClick={() => setLogsToShow(prev => prev + LOGS_TO_SHOW)}>
                                טען עוד
                            </SquareButton>
                        </>
                    )}
                </ConfigProvider>
            </div>
        </Modal>
    );
};
