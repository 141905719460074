import moment from "moment";
import {StringBuilder} from "../AppUtils.js";

export const dateTimeRangeFormat = (dtstart, until = null) => {
    const dtStartHourFormat = moment(dtstart).format("HH:mm");
    const dtStartDateFormat = moment(dtstart).format("DD/MM/YYYY");
    const untilHourFormat = until ? moment(until).format("HH:mm") : null;
    const untilDateFormat = until ? moment(until).format("DD/MM/YYYY") : null;
    const isSameDtStartAndUntilDate = untilDateFormat === dtStartDateFormat;

    return `${dtStartDateFormat}, ${dtStartHourFormat} ${
        until ? `- ${isSameDtStartAndUntilDate ? "" : `${untilDateFormat}, `}${untilHourFormat}` : ""
    }`;
};

export const dateTimeRange = (dtStart = null, until) => {
    if (!dtStart) {
        return null;
    }

    const dtStartHourFormat = moment(dtStart).format("HH:mm");
    const dtStartDateFormat = moment(dtStart).format("DD/MM/YYYY");
    const untilHourFormat = until ? moment(until).format("HH:mm") : null;
    const untilDateFormat = until ? moment(until).format("DD/MM/YYYY") : null;
    const isSameDtStartAndUntilDate = dtStartDateFormat === untilDateFormat;
    const isSameDtStartAndUntilHour = dtStartHourFormat === untilHourFormat;

    const duration = until ? moment(until).diff(moment(dtStart)) : null;
    const durationFormat = duration ? formatHebrewTimeString(moment.duration(Math.abs(duration))) : null;

    return {
        ...(duration ? {duration, durationFormat} : {}),
        ...(isSameDtStartAndUntilDate ? {dtStartDateFormat} : {dtStartDateFormat, untilDateFormat}),
        ...(isSameDtStartAndUntilHour ? {dtStartHourFormat} : {dtStartHourFormat, untilHourFormat})
    };
};

const extractTimeDetails = time => {
    if (!time || isNaN(time.asMinutes())) {
        return null;
    }

    const days = Math.floor(time.asDays());
    const hours = Math.floor(time.asHours()) - days * 24;
    const minutes = Math.floor(time.asMinutes()) - (days * 24 * 60 + hours * 60);
    const seconds = Math.floor(time.asSeconds()) - (days * 24 * 60 * 60 + hours * 3600 + minutes * 60);

    return {days, hours, minutes, seconds};
};

export const formatHebrewTimeString = time => {
    if (!time || isNaN(time.asMinutes())) {
        return null;
    }

    const days = Math.floor(time.asDays());
    const hours = Math.floor(time.asHours()) - days * 24;
    const minutes = Math.floor(time.asMinutes()) - (days * 24 * 60 + hours * 60);

    const stringBuilder = new StringBuilder();

    if (days > 0) {
        stringBuilder.append(`${days} ימים`);
    }

    if (hours > 0) {
        stringBuilder.append(`${hours} שעות`);
    }

    if (minutes > 0) {
        stringBuilder.append(hours > 0 || days > 0 ? "ו-" : "").append(`${minutes} דקות`);
    }

    if (stringBuilder.toString().trim() === "") {
        return "0 דקות";
    }

    return stringBuilder.toString();
};

export const formatTimeToTimer = time => {
    const {days, hours, minutes, seconds} = extractTimeDetails(time);

    const stringBuilder = new StringBuilder(days > 0 ? `${days}` : "", ":");
    stringBuilder
        .append(hours > 0 ? String(hours).padStart(2, "0") : "")
        .append(minutes > 0 ? String(minutes).padStart(2, "0") : "00")
        .append(seconds > 0 ? String(seconds).padStart(2, "0") : "00");
    return stringBuilder.toString();
};
