function getConfig() {
    // Configure the audience here. By default, it will take whatever is in the config
    // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
    // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
    // don't have an API).
    // If this resolves to `null`, the API page changes to show some helpful info about what to do
    // with the audience.
    const VITE_okta_audience = import.meta.env.VITE_okta_audience;
    const audience = VITE_okta_audience && VITE_okta_audience !== "YOUR_API_IDENTIFIER" ? VITE_okta_audience : null;

    return {
        domain: import.meta.env.VITE_okta_domain,
        clientId: import.meta.env.VITE_okta_clientId,
        ...(audience ? {audience} : null)
    };
}

const config = getConfig();

export const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    authorizationParams: {
        redirect_uri: `${window.location.origin}`,
        ...(config.audience ? {audience: config.audience} : null)
    }
};
