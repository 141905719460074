const VITE_GOOGLE_CODE = process.env.VITE_GOOGLE_CODE ?? import.meta.env.VITE_GOOGLE_CODE;

export class GoogleAnalytics {
    static _me = null;
    static allowedGoogleAnaltics = process.env.VITE_GOOGLE_CODE;

    static injectSnippet() {
        if (VITE_GOOGLE_CODE) {
            const script = document.createElement("script");
            script.src = "https://www.googletagmanager.com/gtag/js?id=" + VITE_GOOGLE_CODE;
            document.head.appendChild(script);
            window.dataLayer = window.dataLayer || [];
            window.gtag = function () {
                window.dataLayer.push(arguments);
            };
            window.gtag("js", new Date());
            // window.gtag('set',
            //     {
            //         'custom_map':
            //             {
            //                 'dimension4': 'branch'
            //             }
            //     });
            window.gtag("config", VITE_GOOGLE_CODE, {
                send_page_view: false,
                custom_map: {
                    dimension4: "branch"
                }
            });
        }
    }

    static listenToWindowExceptions() {
        if (VITE_GOOGLE_CODE) {
            window.onerror = message => {
                window.gtag("event", "exception", {
                    description: message,
                    fatal: true
                });
            };
        }
    }

    static setUser(user) {
        if (VITE_GOOGLE_CODE) {
            GoogleAnalytics._me = user;
            // window.gtag('config', VITE_GOOGLE_CODE, {
            //     'user_id': user.userId,
            //     'custom_map':
            //         {
            //             'dimension4': 'branch'
            //         }
            // });
        }
    }

    static page(pathname) {
        if (VITE_GOOGLE_CODE) {
            // window.gtag('config', VITE_GOOGLE_CODE, {
            //     'page_path': pathname,
            //     'custom_map':
            //         {
            //             'dimension4': 'branch'
            //         }
            // });
            gtag("event", "page_view", {
                page_title: document.title,
                page_path: pathname,
                send_to: VITE_GOOGLE_CODE
            });
        }
    }

    static event(category, action, label, branchName, transport_type) {
        if (VITE_GOOGLE_CODE) {
            const opts = {
                event_category: category,
                event_label: label
            };
            if (branchName) {
                opts.branch = branchName;
            }

            if (transport_type) {
                opts.transport_type = transport_type;
            }

            // window.gtag('config', VITE_GOOGLE_CODE, {
            //     'custom_map':
            //         {
            //             'dimension4': 'branch'
            //         }
            // });

            // window.gtag('event', "branch_dimension", opts);
            window.gtag("event", action, opts);
        }
    }

    static ecommerceEvent(eventName, product, category, eventProps = {}, productExtraInfo = {}) {
        if (!product) {
            return;
        }

        window.gtag("event", eventName, {
            items: [
                {
                    id: product.productId,
                    name: product.name,
                    brand: product.brandName ?? product?.engBrandName,
                    category: category,
                    ...productExtraInfo
                }
            ],
            ...eventProps
        });
    }

    static time(category, label, startTime) {
        if (VITE_GOOGLE_CODE) {
            window.gtag("event", "timing_complete", {
                name: "time_spent",
                value: Date.now() - startTime,
                event_category: category,
                event_label: label
            });
        }
    }

    static trackCalendarEvent(event, action, branchName) {
        if (VITE_GOOGLE_CODE && GoogleAnalytics._me && GoogleAnalytics._me.type === "employee") {
            GoogleAnalytics.event(
                "Employee Center",
                action,
                event.sessionName || event.productName || event.serviceName,
                branchName
            );
        }
    }

    static trackContent(infoItem, action, branchName) {
        if (VITE_GOOGLE_CODE && GoogleAnalytics._me && GoogleAnalytics._me.type === "employee") {
            GoogleAnalytics.event("Employee Center", action, infoItem.title, branchName);
        }
    }
}
