import {uniq} from "lodash";
import common from "@well-b/well-b-shared";

export const fixProductServiceIds = item => {
    const currIds = item.serviceIds || [];
    const allIds = item.services.map(s => s.serviceId);
    return uniq(currIds.concat(allIds));
};

export const isSiteParamValid = (site) => Boolean(
    site?.name &&
    site.address &&
    site?.operationalContactFirstName &&
    common.isValidPhoneMobileNumber(site?.operationalContactPhone) &&
    site?.operationalContactEmail &&
    site?.operationalContactLastName
);


export const getTransformedProducts = (providerInfo, productsSearchTags) => {
    let transformedProducts = [];

    if (providerInfo?.services?.length) {
        transformedProducts = providerInfo.services.map(product => ({
            ...product,
            serviceIds: fixProductServiceIds(product)
        }));

        if (productsSearchTags) {
            transformedProducts = transformedProducts.map(product => ({
                ...product,
                searchTags: productsSearchTags[product.productId] ?? []
            }));
        }
        return transformedProducts;
    }
}
