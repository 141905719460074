import React, {useContext, useState} from "react";
import {generateAssignUserContactWhatsAppLink} from "../utils.jsx";
import {StringBuilder} from "../../AppUtils.js";
import "../order-information-card.css";
import {StyledButton} from "../../components/index.jsx";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {AppContext} from "../../AppContext.jsx";
import {OrderActions} from "../OrderActionList/OrderActions.jsx";

export const OrderInformationCardHeader = ({eventInfo, actions = [], title}) => {
    const {me} = useContext(AppContext);
    const [isShowOrderActions, setIsShowOrderActions] = useState(false);

    return (
        <>
            <div className="order-information-card-top-bar-container">
                <div className="order-information-card-top-bar-item">
                    {!!title && <div className="title">{title}</div>}
                    <div className="title">{eventInfo.productName}</div>
                    <div className="sub-title">
                        {new StringBuilder().append("סיכום הזמנה מספר:").append(eventInfo.proposalId).toString()}
                    </div>
                    <div>
                        <span>אנחנו פה לכל דבר ועניין</span>
                        <span> </span>
                        <a href={generateAssignUserContactWhatsAppLink(eventInfo.systemId)}>בוואטסאפ</a>
                    </div>
                </div>

                {!!me?.features?.order_actions_btn && actions?.length ? (
                    <StyledButton
                        className="order-information-action-btn"
                        onClick={() => setIsShowOrderActions(isShowOrderActions => !isShowOrderActions)}
                        icon={isShowOrderActions ? <DownOutlined /> : <UpOutlined />}>
                        פעולות
                    </StyledButton>
                ) : null}
            </div>

            {isShowOrderActions ? <OrderActions actions={actions} /> : null}
        </>
    );
};
