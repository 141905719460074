import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {Carousel} from "antd";
import {BesideCarouselArrow} from "./MarketplaceHelper";
import {Banner} from "../components";

export const BannersCarousel = ({history, banners, log}) => {
    const carouselRef = useRef();
    const autoPlayInterval = useRef(null);
    const onClick = useCallback(
        (link, name) => {
            log("Banner Clicked", {bannerName: name});

            history.push(link);
        },
        [log, history]
    );

    const rtlOrderedBanners = useMemo(() => {
        if (!banners?.length) {
            return [];
        }

        const [firstItem, ...rest] = banners;
        return [firstItem, ...rest.reverse()];
    }, [banners]);

    const resetTimer = useCallback(() => {
        const autoPlay = () => {
            carouselRef.current?.prev();
        };

        clearInterval(autoPlayInterval.current);
        autoPlayInterval.current = setInterval(autoPlay, 5000);
    }, []);

    useEffect(() => {
        if (carouselRef?.current) {
            resetTimer();
            return () => {
                clearInterval(autoPlayInterval.current);
            };
        }
    }, [carouselRef?.current]);

    return (
        <div className="landing-page-banners">
            <Carousel
                ref={carouselRef}
                prevArrow={
                    <>
                        <BesideCarouselArrow
                            direction={"prev"}
                            onClick={() => {
                                resetTimer();
                                carouselRef?.current.prev();
                            }}
                        />
                    </>
                }
                nextArrow={
                    <>
                        <BesideCarouselArrow
                            direction={"next"}
                            onClick={() => {
                                resetTimer();
                                carouselRef?.current.next();
                            }}
                        />
                    </>
                }
                arrows={true}>
                {rtlOrderedBanners?.map(({name, text, image, link, buttonText}) => (
                    <Banner
                        key={name}
                        name={name}
                        onClick={() => onClick(link, name)}
                        text={text}
                        image={image}
                        width={1240}
                        height={330}
                        buttonText={buttonText}
                    />
                ))}
            </Carousel>
        </div>
    );
};
