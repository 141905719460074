import React, {useMemo} from "react";
import CloudinaryImage from "react-cloudinary-lazy-image";
import {extractPublicIdAndCloudName} from "../utils/cloudinaryUtils";

export const ProgressiveImageLoader = ({src, width, maxWidth = null, height, blurSize = 200}) => {
    const {cloudName, publicId} = useMemo(() => extractPublicIdAndCloudName(src), [src]);

    return cloudName ? (
        <CloudinaryImage
            cloudName={cloudName}
            imageName={publicId}
            {...(maxWidth ? {fluid: {maxWidth}} : {fixed: {width, height}})}
            urlParams={""}
            imgFormat={false}
            quality={false}
            blurSize={blurSize}
        />
    ) : (
        <img className="image-place-holder" alt="place-holder" src={src} style={{width, height}} />
    );
};
