import React, {useCallback, useEffect, useMemo, useState} from "react";
import {flatMap} from "lodash";
import {ConfigProvider, Form, Modal, Select} from "antd";
import {PageTitleHeader, SortableList, SquareButton} from "../../components";
import {FormInputV2} from "../../components/form";
import {useRequest} from "../../utils/hooks";
import {TrashIcon} from "../../icons";
import "./admin-site-settings.css";

const {Option, OptGroup} = Select;

export const AdminEditCrossPageModal = ({crossPage, onSave, visible, onClose}) => {
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState({});
    const [allSubCategories] = useRequest("/api/services/allSubCategories");

    useEffect(() => {
        setFormValues(crossPage ?? {});
    }, [crossPage]);

    const innerOnClose = useCallback(() => {
        form.resetFields();
        setFormValues({});
        onClose();
    }, [form, onClose]);

    const onSortEnd = useCallback(({oldIndex, newIndex}) => {
        setFormValues(prevValues => {
            const newSubCategories = [...prevValues.subCategories];
            newSubCategories.splice(newIndex, 0, newSubCategories.splice(oldIndex, 1)[0]);
            return {
                ...prevValues,
                subCategories: newSubCategories
            };
        });
    }, []);

    const removeSubCategory = useCallback(id => {
        setFormValues(prevValues => ({
            ...prevValues,
            subCategories: prevValues.subCategories.filter(subId => subId !== id)
        }));
    }, []);

    const onSelectSub = useCallback(newSubId => {
        setFormValues(prevValues => ({
            ...prevValues,
            subCategories: [...(prevValues.subCategories ?? []), newSubId]
        }));
    }, []);

    const pageSubs = useMemo(() => {
        if (!allSubCategories || !formValues.subCategories) {
            return [];
        }

        const flat = flatMap(allSubCategories, service => service.subCategories);

        return formValues.subCategories.map(subId => flat.find(({id}) => subId === id)).filter(sub => sub);
    }, [formValues?.subCategories, allSubCategories]);

    const selectableSubCategories = useMemo(() => {
        return allSubCategories?.map(service => ({
            ...service,
            subCategories: service.subCategories.filter(({id}) => !formValues?.subCategories?.includes(id))
        }));
    }, [formValues?.subCategories, allSubCategories]);

    return (
        <Modal
            centered={true}
            width={501}
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={() => innerOnClose()}
            maskClosable={false}
            footer={null}
            title={null}
            closable={true}>
            <ConfigProvider direction="rtl">
                <div className="admin-edit-cross-page-modal">
                    <PageTitleHeader showBack={false}>עריכת עמוד {formValues?.name ?? ""}‍️</PageTitleHeader>
                    <div className="single-delivery-modal-break-line" />
                    <Form
                        className="admin-edit-cross-page-modal-form"
                        form={form}
                        onFinish={() => {
                            onSave(formValues);
                            form.resetFields();
                            setFormValues({});
                        }}
                        fields={Object.keys(formValues).map(key => ({
                            name: [key],
                            value: formValues[key]
                        }))}
                        onValuesChange={(_, allValues) => {
                            setFormValues(allValues);
                        }}>
                        <div>
                            <label>שם העמוד</label>
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "שדה חובה"
                                    }
                                ]}>
                                <FormInputV2 />
                            </Form.Item>
                        </div>
                        <div>
                            <label>קרוסלות</label>
                            <Select
                                className="admin-edit-cross-page-sub-categories-selector"
                                showSearch={true}
                                placeholder="בחר קרוסלות"
                                optionFilterProp="children"
                                onChange={onSelectSub}
                                value={null}>
                                {selectableSubCategories?.map(({serviceId, subCategories}) => (
                                    <OptGroup key={serviceId} label={serviceId}>
                                        {subCategories.map(sub => (
                                            <Option key={sub.id} value={sub.id}>
                                                {sub.name}
                                            </Option>
                                        ))}
                                    </OptGroup>
                                ))}
                            </Select>
                            {pageSubs?.length ? (
                                <div className="admin-edit-cross-page-selected-sub-categories">
                                    <SortableList
                                        itemClassName="admin-edit-cross-page-selected-sub-category"
                                        onSortEnd={onSortEnd}>
                                        {pageSubs.map(subCategory => (
                                            <div
                                                key={subCategory.id}
                                                className="admin-edit-cross-page-selected-sub-category-name">
                                                <span>{subCategory.name}</span>
                                                <TrashIcon onClick={() => removeSubCategory(subCategory.id)} />
                                            </div>
                                        ))}
                                    </SortableList>
                                </div>
                            ) : null}
                        </div>

                        <SquareButton className="admin-edit-cross-page-modal-save" htmlType="submit">
                            שמירה
                        </SquareButton>
                    </Form>
                </div>
            </ConfigProvider>
        </Modal>
    );
};
