import React from "react";
import {notification} from "antd";
import "./notifications.css";

const openUndoNotification = (actionText, undoAction, key = null) => {
    notification.open({
        key: key ?? actionText,
        placement: "bottomCenter",
        className: "undo-notification",
        message: (
            <div className="undo-notification-message">
                <span>{actionText}</span>
                <span
                    onClick={() => {
                        notification.close(key);
                        undoAction();
                    }}>
                    ביטול הפעולה
                </span>
            </div>
        )
    });
};

export default {
    openUndoNotification
};
