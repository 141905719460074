import React, {useContext, useEffect, useState} from "react";
import {AppLogo} from "../../AppLogo";
import {AppContext} from "../../AppContext";
import {EXCenterBranchCard} from "./EXCenterBranchCard";
import {message} from "antd";
import {Redirect} from "react-router";
import {UserBranch} from "./UserBranch";
import {CompanyBranches} from "../../company/CompanyBranches";
import {HttpClient} from "../../http/HttpClient";
import {EventBus} from "../../bus/EventBus";
import {EXCenterAddBranchCard} from "./EXCenterAddBranchCard";
import {parse as qsParse} from "query-string";
import {EXCenterContext} from "../EXCenterContext";
import {GoogleAnalytics} from "../../GoogleAnalytics";
import {PageLoader} from "../../components/PageLoader";
import _ from "lodash";

const DEFAULT_BRANCH_KEY = "wb-default-branch";

export const isMyBranchSelected = me => {
    if (me.isCompanyMaster) {
        return true;
    }

    const {branchId} = qsParse(location.search);
    return me.branch.branchId === branchId;
};

export const EXCenterBranches = ({history, match}) => {
    const {company, me} = useContext(AppContext);
    const {theme} = useContext(EXCenterContext);
    const [admins, setAdmins] = useState(null);

    const {type} = match.params;
    const companyBranches = company && Array.isArray(company.branches) ? company.branches : [];

    useEffect(() => {
        if (me && me.type === "company" && !Array.isArray(admins)) {
            Promise.resolve().then(async () => {
                try {
                    const admins = await HttpClient.get("/api/companies/me/admins");
                    setAdmins(admins);
                } catch (e) {
                    message.error("Something went wrong, please try again later.", 5);
                }
            });
        }
    });

    const storeSelection = async branchInfo => {
        const updatedUser = await HttpClient.post("/api/users/me", {
            ...me,
            branch: branchInfo
        });
        EventBus.trigger("me:update", updatedUser);
        window.localStorage.setItem(DEFAULT_BRANCH_KEY, branchInfo.branchId);
    };

    const resetSelection = () => {
        window.localStorage.removeItem(DEFAULT_BRANCH_KEY);
    };

    const saveNewBranches = async newBranches => {
        try {
            const cmpny = await HttpClient.post("/api/companies", {...company, branches: newBranches});
            EventBus.trigger("company:update", cmpny);
            GoogleAnalytics.event("EX Center - Branches", "Add Branch", me.userId);
        } catch (e) {
            if (e.statusCode === 400) {
                EventBus.triggerError(
                    "server-error",
                    {
                        content: {
                            description: e.message,
                            hideSteps: true,
                            hideSubTitle: true,
                            title: "Ohh..."
                        },
                        cta: {hide: true}
                    },
                    e.message
                );
            } else {
                message.error("Something went wrong, please try again later.", 5);
            }
        }
    };

    const isEmployee = () => me && me.type === "employee";

    const defaultBranchId = window.localStorage.getItem(DEFAULT_BRANCH_KEY);
    const userBranch = new UserBranch(me, company);

    if (isEmployee()) {
        if (me.branch) {
            if (type === "auto" || !userBranch.canSeeOtherBranches(false)) {
                return <Redirect to={`/center/main?branchId=${me.branch.branchId}`} />;
            } else if (companyBranches.length === 1) {
                return <Redirect to={`/center/main?branchId=${companyBranches[0].branchId}`} />;
            } else if (defaultBranchId && type !== "direct") {
                return <Redirect to={`/center/main?branchId=${defaultBranchId}`} />;
            }
        }
    } else {
        if (!me.isCompanyMaster && me.branch && !userBranch.canSeeOtherBranches(false)) {
            return <Redirect to={`/center/main?branchId=${me.branch.branchId}`} />;
        } else if (defaultBranchId && type === "auto") {
            return <Redirect to={`/center/main?branchId=${defaultBranchId}`} />;
        }
    }

    resetSelection();

    return (
        <div
            className="wb-ss-no-margin"
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                marginRight: 10,
                marginLeft: 10,
                marginTop: 80
            }}>
            <div
                className="wb-ss-logo-margin"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: 30
                }}>
                <AppLogo
                    width={200}
                    height={80}
                    style={{
                        position: "relative",
                        left: 0,
                        alignItems: "center",
                        justifyContent: "center",
                        maxWidth: 200,
                        borderBottom: "none"
                    }}
                />
            </div>
            <div
                className="wb-ss-title-margin"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    color: theme.textColor,
                    fontSize: 34,
                    fontWeight: 900,
                    marginBottom: 50,
                    textAlign: "center"
                }}>
                Please select your site
            </div>
            {company && me ? (
                <CompanyBranches value={companyBranches} onChange={saveNewBranches} admins={admins}>
                    {({branches, onNewBranchClick, onEditBranchClick}) => (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexWrap: "wrap",
                                maxWidth: 1230
                            }}>
                            {branches.map(branchInfo => (
                                <EXCenterBranchCard
                                    key={branchInfo.branchId}
                                    branchInfo={branchInfo}
                                    onBranchSelect={storeSelection}
                                    onEditBranchClick={onEditBranchClick}
                                />
                            ))}
                            {me.type === "company" && me.isCompanyMaster ? (
                                <EXCenterAddBranchCard onNewBranchClick={onNewBranchClick} />
                            ) : null}
                        </div>
                    )}
                </CompanyBranches>
            ) : (
                <PageLoader align="flex-start" top={200} />
            )}
        </div>
    );
};
