import {EmailNotificationSetting} from "../company/CompanyNotificationSettings";
import PageTitleHeader, {PageSubTitleLabel} from "../components/PageTitle";
import React, {useContext, useEffect, useState} from "react";
import {FormCancelSaveBottomBar} from "../components/form";
import {HttpClient} from "../http/HttpClient";
import {parse as qsParse} from "query-string";
import {message} from "antd";
import {AppContext} from "../AppContext";
import {EventBus} from "../bus/EventBus";

const UnsubscribeType = {
    General: "general",
    Weekly: "weekly",
    AboutToStart: "aboutToStart",
    InvitationToRegister: "invitationToRegister"
};

export const UnsubscribePage = ({hideIntro = false, hideBack = false, instant = false, style}) => {
    const {me} = useContext(AppContext);
    const [saving, setSaving] = useState(false);
    const [settings, setSettings] = useState({
        weekly: true,
        general: true,
        aboutToStart: true,
        invitationToRegister: true
    });

    const {search} = location;
    const email = me.email ? me.email : qsParse(search).email;

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const [{settings}, {branding}] = await Promise.all([
                    HttpClient.get(`/api/mail/unsubscribe?email=${email}`),
                    HttpClient.get(`/api/companies/brand?email=${email}`)
                ]);
                setSettings(settings);

                if (branding.logoUrl) {
                    EventBus.trigger("me:update", {...me, branding});
                }
            } catch (e) {
            }
        });
    }, []);

    const onSettingChange = (type, enabled) => {
        const newSettings = {...settings, [type]: enabled};
        setSettings(newSettings);
        if (instant) {
            Promise.resolve().then(async () => await saveAll(newSettings));
        }
    };

    const saveAll = async settings => {
        setSaving(true);
        try {
            await HttpClient.post(`/api/mail/unsubscribe?email=${email}`, settings);
        } catch (e) {
        }
        message.success("Your settings were saved.");
        setSaving(false);
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                marginTop: 0,
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 150,
                ...style
            }}>
            <PageTitleHeader style={{marginBottom: 0}} showBack={!hideBack && !!me.email}>
                Email notifications
            </PageTitleHeader>
            {!hideIntro ? (
                <>
                    <PageSubTitleLabel style={{display: "flex"}}>
                        <span>
                            Control notifications for <strong style={{marginLeft: 2}}>{email}</strong>
                        </span>
                    </PageSubTitleLabel>
                    <PageSubTitleLabel style={{display: "flex", marginBottom: 10}}>
                        <span>
                            <strong style={{marginRight: 2}}>Our Tip:</strong> keep all buttons green to be updated with
                            all that happening at the company (activities, discounts, free stuff, etc...).
                        </span>
                    </PageSubTitleLabel>
                    <PageSubTitleLabel style={{marginTop: 0, fontSize: 18, fontWeight: 700}}>
                        You WILL NEVER get any commercial content!
                    </PageSubTitleLabel>
                </>
            ) : null}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    maxWidth: 800,
                    marginTop: 20
                }}>
                <EmailNotificationSetting
                    title="Weekly emails"
                    description={
                        <>
                            This type of emails includes{" "}
                            <u>
                                <b>your</b>
                            </u>{" "}
                            upcoming company's events.
                        </>
                    }
                    enabled={settings[UnsubscribeType.Weekly]}
                    onChange={enabled => onSettingChange(UnsubscribeType.Weekly, enabled)}
                />
                <EmailNotificationSetting
                    title="Reminder emails"
                    description={
                        <>
                            This type of emails are reminders that you will get just before{" "}
                            <u>
                                <b>your registered</b>
                            </u>{" "}
                            event is about to start.
                        </>
                    }
                    enabled={settings[UnsubscribeType.AboutToStart]}
                    onChange={enabled => onSettingChange(UnsubscribeType.AboutToStart, enabled)}
                />
                <EmailNotificationSetting
                    title="Invitation emails"
                    description={
                        <>
                            This type of emails are invitations to register for{" "}
                            <u>
                                <b>your new</b>
                            </u>{" "}
                            company's events.
                        </>
                    }
                    enabled={settings[UnsubscribeType.InvitationToRegister]}
                    onChange={enabled => onSettingChange(UnsubscribeType.InvitationToRegister, enabled)}
                />
                <EmailNotificationSetting
                    title="Platform emails"
                    description={
                        <>
                            This type of emails includes updates on{" "}
                            <u>
                                <b>your registered</b>
                            </u>{" "}
                            events, appointments and general platform messages which are{" "}
                            <u>
                                <b>not</b>
                            </u>{" "}
                            marketing materials.
                        </>
                    }
                    enabled={settings[UnsubscribeType.General]}
                    onChange={enabled => onSettingChange(UnsubscribeType.General, enabled)}
                />
            </div>
            {!instant ? (
                <FormCancelSaveBottomBar
                    onSave={async () => await saveAll(settings)}
                    saveProps={{style: {width: 200}}}
                    loading={saving}
                />
            ) : null}
        </div>
    );
};
