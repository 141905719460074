import {FormTextArea} from "../../components/form";
import React from "react";

export const StepperParagraphInput = ({
    initialValue,
    value,
    onChange,
    placeholder,
    style,
    maxLength = 120,
    autoSize = {maxRows: 5, minRows: 5},
    className,
    disabled = false,
    status = ""
}) => {
    if (!value) {
        value = initialValue;
    }
    return (
        <FormTextArea
            disabled={disabled}
            className={className}
            placeholder={placeholder}
            maxLength={maxLength}
            autoSize={autoSize}
            onChange={onChange}
            value={value || initialValue}
            style={{
                color: "var(--secondary-color)",
                height: 50,
                borderRadius: 5,
                backgroundColor: "#FCFDFE",
                ...(status ? {} : {border: value ? "1px solid var(--secondary-color)" : "1px solid #E8EDF5"}),
                ...style
            }}
        />
    );
};
