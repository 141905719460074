import React, {useState, useCallback, useEffect, useMemo} from "react";
import {ConfigProvider} from "antd";
import {noop} from "lodash";
import {getPopupStepFields} from "./PopupStepFields";
import getColumns from "./PopupColumn";
import {RoundedTable, SearchInputWithIcon, SquareButton} from "../../components";
import {useRequest, useDebounceState} from "../../utils/hooks";
import {HttpClient} from "../../http/HttpClient";
import {TabbedFormModal} from "../../components";
import {Popup} from "../../components";
import "./page-layouts-admin.css";

export const PopupSettings = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [searchInput, setSearchInput, unDebouncedSearchInput] = useDebounceState("", 200);
    const [data, loading, error, manualFetch] = useRequest(`/admin/api/popup/search/${searchInput}`, "GET");
    const [popups, setPopups] = useState([]);

    const [shouldPreview, setShouldPreview] = useState(false);

    useEffect(() => {
        setPopups(data);
    }, [data]);

    const onEdit = useCallback(
        async updatedPopup => {
            if (!updatedPopup) {
                return;
            }
            const popupTemp = [...popups];
            if (updatedPopup.popupId) {
                const popupIndex = popupTemp.findIndex(popup => popup.popupId === updatedPopup.popupId);
                popupTemp[popupIndex] = {...popupTemp[popupIndex], ...updatedPopup};
                HttpClient.post(`/admin/api/popup/${updatedPopup.popupId}`, updatedPopup);
            } else if (updatedPopup.isEditing) {
                const popupIndex = popupTemp.findIndex(popup => popup.isEditing);
                popupTemp[popupIndex] = {...popupTemp[popupIndex], ...updatedPopup};
            }
            setPopups(popupTemp);
        },
        [popups]
    );

    const onDelete = useCallback(
        async ({popupId}) => {
            if (!popupId) {
                return;
            }

            const popupTemp = [...popups];

            const popupIndex = popupTemp.findIndex(popup => popup.popupId === popupId);
            popupTemp.splice(popupIndex, 1);
            setPopups(popupTemp);
            await HttpClient.delete(`/admin/api/popup/${popupId}`);
        },
        [popups]
    );

    const onAdd = useCallback(async () => {
        const newPopup = {isEditing: true};
        setPopups([newPopup, ...popups]);
    }, [popups]);

    const onSave = useCallback(async () => {
        const newPopup = popups.find(popup => popup.isEditing);
        delete newPopup.isEditing;
        if (!newPopup) {
            return;
        }
        const {error, data} = await HttpClient.put("/admin/api/popup", newPopup);

        manualFetch();
    }, [popups]);

    // getting the isEditing popup from the popups array
    const editedPopup = useMemo(() => {
        if (!popups) {
            return null;
        }
        const newPopup = popups.find(popup => popup.isEditing);
        return newPopup;
    }, [popups]);

    const isEditedValid = useMemo(() => {
        if (!editedPopup) {
            return false;
        }
        return editedPopup.name && editedPopup.startDate && editedPopup.endDate;
    }, [editedPopup]);

    const [selectedPopup, setSelectedPopup] = useState(null);
    const onEditSteps = popup => {
        setSelectedPopup(popup);
        setIsVisible(true);
    };

    const onPreview = popup => {
        setSelectedPopup(popup);
        setShouldPreview(true);
    };
    const onClosePreview = () => {
        setSelectedPopup(null);
        setShouldPreview(false);
    };

    const onCloseModal = () => {
        setSelectedPopup(null);
        setIsVisible(false);
    };

    return (
        <ConfigProvider direction="rtl">
            <div className="popup-settings">
                <TabbedFormModal
                    width={1000}
                    header={"עריכת שלבי הפופאפ"}
                    visible={isVisible}
                    onClose={onCloseModal}
                    content={selectedPopup && selectedPopup.steps}
                    onUpdate={noop}
                    onSave={steps => {
                        onEdit({steps, popupId: selectedPopup.popupId});
                        onCloseModal();
                    }}
                    fields={getPopupStepFields}
                    tabTitleField={"title"}
                />
                {selectedPopup && (
                    <ConfigProvider direction="ltr">
                        <Popup open={shouldPreview} onClose={onClosePreview} popup={selectedPopup} />
                    </ConfigProvider>
                )}
                <div className="popup-settings-top-bar">
                    <div>
                        <SquareButton onClick={onAdd} className="primary-color-button">
                            הוספת פופאפ
                        </SquareButton>
                    </div>
                    <div>
                        <SearchInputWithIcon searchInput={unDebouncedSearchInput} setSearchInput={setSearchInput} />
                    </div>
                </div>
                <RoundedTable
                    rowKey="popupId"
                    pagination={false}
                    dataSource={popups}
                    columns={getColumns(onDelete, onEdit, noop, onPreview, onEditSteps, onSave, isEditedValid)}
                    loading={loading}
                />
            </div>
        </ConfigProvider>
    );
};
