import React, {useState, useEffect} from "react";
import {message, Tag, Tooltip} from "antd";
import {RoundedTable} from "../components/RoundedTable";
import moment from "moment-timezone";
import {SearchInput} from "../components/Search";
import {HttpClient} from "../http/HttpClient";
import PageTitleHeader from "../components/PageTitle";
// import randomColor from "randomcolor";

const columns = () => [
    {
        title: "#",
        key: "#",
        width: 70,
        render(_, __, idx) {
            return `${idx + 1}`;
        }
    },
    {
        title: "ID",
        dataIndex: "userId",
        key: "userId",
        width: 140
    },
    {
        title: "NAME",
        key: "name",
        width: 200,
        render(_, {firstName, lastName, email}) {
            return (
                <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <label
                            style={{
                                color: "var(--secondary-color)",
                                fontSize: 14,
                                fontWeight: 500
                            }}>{`${firstName} ${lastName}`}</label>
                        <label style={{color: "#747F92", fontSize: 12, fontWeight: 400}}>{email}</label>
                    </div>
                </div>
            );
        }
    },
    {
        title: "PHONE",
        dataIndex: "phone",
        key: "phone",
        width: 200
    },
    {
        title: "CREATED AT",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 200,
        sorter: (r1, r2) => (r1.createdAt < r2.createdAt ? 1 : -1),
        render(createdAt) {
            return createdAt ? moment(createdAt).format("MMMM Do, YYYY HH:mm") : "";
        }
    },
    {
        title: "VERIFIED",
        dataIndex: "verified",
        key: "verified",
        width: 100,
        filters: [
            {
                text: "YES",
                value: true
            },
            {
                text: "NO",
                value: false
            }
        ],
        filterMultiple: false,
        onFilter: (value, {verified}) => {
            return value === !!verified;
        },
        render(verified) {
            return verified ? "YES" : "NO";
        }
    },
    {
        title: "REGISTERED SESSIONS",
        dataIndex: "eventsRegistered",
        key: "eventsRegistered",
        render(eventsRegistered, {userId}) {
            if (!eventsRegistered || eventsRegistered.length === 0) return null;
            return (
                <div style={{display: "flex", alignItems: "center"}}>
                    {eventsRegistered.slice(0, 4).map((eventRegistered, idx) => (
                        <Tooltip
                            key={`${userId}-${idx}`}
                            title={moment(eventRegistered.dtstart).local().format("MMM Do, YYYY")}>
                            <Tag
                                color={eventRegistered.backgroundColor || randomColor({luminosity: "dark"})}
                                style={{borderRadius: 10, fontSize: 14, color: "white"}}>
                                {eventRegistered.serviceName || eventRegistered.sessionName}
                            </Tag>
                        </Tooltip>
                    ))}
                </div>
            );
        }
    }
];

export const AdminCompanyEmployees = ({match}) => {
    const {companyId} = match.params;
    const [term, setTerm] = useState("");
    const [employees, setEmployees] = useState(null);
    const [companyName, setCompanyName] = useState(null);

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const {employees, companyName} = await HttpClient.get(`/admin/api/companies/${companyId}/employees`);
                setEmployees(employees);
                setCompanyName(companyName);
            } catch (e) {
                message.error(e.message, 3);
            }
        });
    }, []);

    const filter = term => {
        setTerm(term);
    };

    let filteredEmployees = null;
    if (employees) {
        filteredEmployees = employees.filter(
            emp =>
                `${emp.firstName} ${emp.lastName} ${emp.phone || ""} ${emp.email}`
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) >= 0
        );
    }

    return (
        <div style={{display: "flex", alignItems: "center", flexDirection: "column", margin: 10, marginBottom: 40}}>
            <PageTitleHeader showBack={true}>{companyName ? `${companyName} employees` : null}</PageTitleHeader>
            <div style={{display: "flex", flexDirection: "column", maxWidth: 1250, width: "100%"}}>
                <div>
                    <SearchInput
                        style={{marginBottom: 30, maxWidth: 500, width: "100%"}}
                        onChange={evt => filter(evt.target.value)}
                        placeholder="Search employee"
                    />
                </div>
                <RoundedTable
                    rowKey="userId"
                    scroll={{x: 1500, y: 600}}
                    pagination={false}
                    loading={filteredEmployees === null}
                    dataSource={filteredEmployees || []}
                    columns={columns()}
                />
            </div>
        </div>
    );
};
