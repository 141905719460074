import dancingImage from "./dancing.png";
import yogaImage from "./yoga.png";
import musicImage from "./music.png";
import kidsImage from "./kids.png";
import sportsImage from "./sports.png";
import nutritionistImage from "./nutritionist.png";
import professionalImage from "./professional.png";
import dogsImage from "./dogs.png";
import deliveriesImage from "./deliveries.png";
import giftsImage from "./gifts.png";
import happyhoursImage from "./happyhours.png";
import volunteerImage from "./volunteer.png";
import massageImage from "./massage.png";
import hairstylistImage from "./hairstylist.png";
import manicuristImage from "./manicurist.png";
import meditationImage from "./meditation.png";
import entertainmentImage from "./entertainment.png";
import personaldevelopImage from "./personaldevelop.png";
import offsitesImage from "./offsites.png";
import workshopsImage from "./workshops.png";
import psychologicalImage from "./psychological.png";
import groupactivityImage from "./groupactivity.png";
import specialCategoryImage from "./groupactivity.png";
import nonChrome from "./non-chrome-background.png";

import loginBackgroundImage from "./wb-login-background.png";
import loginLogoImage from "./login-logo.svg";
import companyWizardSuccessImage from "./company-wizard-success.png";
import bookingSuccessImage from "./booking-success.png";

import dancingMobileImage from "./mobile/dancing.png";
import yogaMobileImage from "./mobile/yoga.png";
import musicMobileImage from "./mobile/music.png";
import kidsMobileImage from "./mobile/kids.png";
import sportsMobileImage from "./mobile/sports.png";
import nutritionistMobileImage from "./mobile/nutritionist.png";
import professionalMobileImage from "./mobile/professional.png";
import dogsMobileImage from "./mobile/dogs.png";
import deliveriesMobileImage from "./mobile/deliveries.png";
import giftsMobileImage from "./mobile/gifts.png";
import happyhoursMobileImage from "./mobile/happyhours.png";
import volunteerMobileImage from "./mobile/volunteer.png";
import massageMobileImage from "./mobile/massage.png";
import hairstylistMobileImage from "./mobile/hairstylist.png";
import manicuristMobileImage from "./mobile/manicurist.png";
import meditationMobileImage from "./mobile/meditation.png";
import entertainmentMobileImage from "./mobile/entertainment.png";
import personaldevelopMobileImage from "./mobile/personaldevelop.png";
import offsitesMobileImage from "./mobile/offsites.png";
import workshopsMobileImage from "./mobile/workshops.png";
import psychologicalMobileImage from "./mobile/psychological.png";
import handshake from "./handshake.png";

import logoImage from "./logo.png";
import tenBisLogoImage from "./tenbis/tenBis-logo.svg";
import tenBisLoginPageImage from "./tenbis/ten-bis-login-page.svg";

import oopsImage from "./oops.png";
import oops2Image from "./oops2.png";
import outOfStockImage from "./out_of_stock.png";
import areYouSureImage from "./are_you_sure_man.png";
import failImage from "./fail.png";
import failWideImage from "./failWide.png";
import successImage from "./success.png";
import success2Image from "./success2.png";
import success3Image from "./success3.png";
import successWideImage from "./success_wide.png";
import selfieImage from "./selfie.png";
import confirmImage from "./confirm_appointment.png";
import eventReminder from "./event_reminder.png";

import activeImage from "./icons/active.svg";
import undo from "./icons/undo.svg";
import declinedImage from "./icons/appointment_declined.svg";
import acceptedImage from "./icons/appointment_accepted.svg";
import dealImage from "./icons/deal.svg";
import commentsImage from "./icons/comment.svg";
import employeesImage from "./icons/employees.svg";
import sendImage from "./icons/send.svg";
import marketplaceBookingCheckboxEmptyImage from "./icons/marketplace-booking-checkbox-empty.svg";
import marketplaceBookingCheckboxCheckedImage from "./icons/marketplace-booking-checkbox-checked.svg";

import marketplaceFilterCheckboxEmptyImage from "./icons/marketplace-filter-unchecked.svg";
import marketplaceFilterCheckboxCheckedImage from "./icons/marketplace-filter-checked.svg";

import priceImage from "./price.png";
import phoneImage from "./phone.png";

import paymentPopularSelectedImage from "./payment-popular-selected.png";
import paymentNoPopularSelectedImage from "./payment-not-popular-selected.png";
import paymentPopularUnselectedImage from "./payment-popular-unselected.png";
import paymentNotPopularUnselectedImage from "./payment-not-popular-unselected.png";
import paymentPerActiveDayImage from "./payment-per-active-day.png";

import creditCardRequiredImage from "./credit_card_required.png";

import exitDemoImage from "./exit-demo.png";
import employeeViewImage from "./employee-view.png";
import employeeViewInvertImage from "./employee-view-invert.png";
import menuIconImage from "./menu-icon.png";

import hibobGif from "./hibob-gif.gif";
import spreadsheetImage from "./spreadsheet-example.png";

import tutorialIcon from "./tutorial-icon.png";
import feedbackIcon from "./feedbackIcon.png";
import whatsappIcon from "./whatsappIcon.svg";
import calendarInviteIcon from "./calendar-invite.png";
import allChoicesImage from "./all-choices.jpeg";
import creditCardsInARowImage from "./booking-credit-cards-in-a-row.png";
import pinkFoldersImage from "./pink-folders.png";

import goldMedalImage from "./gold-medal.png";
import silverMedalImage from "./silver-medal.png";
import bronzeMedalImage from "./bronze-medal.png";
import WellBPassover from "./well-b-passover.png";
import WellBRoshHasana from "./well-b-roshHasana.png";
import WellBSukot from "./well-b-sukot.png";

import CalculatorBG from "./calculator-bg.svg";
import unplannedCalendarImage from "./unplannedCalendarImage.png";
import LikeHeartImage from "./like-heart.svg";
import computerTrashImage from "./computer_trashcan.png";
import PageNotFoundImage from "./page_not_found_image.svg";
import lactoseFreeImage from "./lactoseFree.svg";
import glutenFreeImage from "./glutenFree.svg";
import vegetarianImage from "./vegetarian.svg";
import veganImage from "./vegan.svg";
import parveImage from "./parve.svg";
import noteImage from "./note.png";
import loginPageImage from "./login_page.png";

// MarketPlace V2 images
import workshopsV2Image from "./workshops_v2.png";
import happyHourV2Image from "./happy_hours_v2.png";
import lecturesV2Image from "./lectures_v2.png";
import moveYourBodyV2Image from "./move_your_body_v2.png";
import personalServiceProviderV2Image from "./personal_service_provider_v2.png";
import hairStylingV2Image from "./hair_styling_v2.png";
import maniPediV2Image from "./mani_pedi_v2.png";
import massageTherapyV2Image from "./massage_therapy_v2.png";
import activitiesV2Image from "./activities_v2.png";
import sportsV2Image from "./sports_v2.png";
import yogaPilatesV2Image from "./yoga_pilates_v2.png";
import noOrders from "./no-orders.svg";

// Marketplace Main Page Category Icon
import happyHourV2Icon from "./marketplace-main-page-category-happyhours.png";
import happyHourV2LightIcon from "./marketplace-main-page-category-happyhours_light.png";
import packageV2Icon from "./main-page-category-package.png";
import packageV2LightIcon from "./main-page-category-package_light.png";
import platterV2Icon from "./main-page-category-platter.png";
import platterV2LightIcon from "./main-page-category-platter_light.png";
import workshopV2Icon from "./main-page-category-workshop.png";
import workshopV2LightIcon from "./main-page-category-workshop_light.png";
import lectureV2Icon from "./main-page-category-lacture.png";
import lectureV2LightIcon from "./main-page-category-lacture_light.png";

import happyHourSwitchImage from "./marketplace-category-switch-happyhours.png";
import packageSwitchImage from "./marketplace-category-switch-packages.png";
import platterSwitchImage from "./marketplace-category-switch-platters.png";
import workshopSwitchImage from "./marketplace-category-switch-workshops.png";
import lectureSwitchImage from "./marketplace-category-switch-lectures.svg";

import categoryCardPersonImage from "./category_card_person_icon.svg";
import categoryCardRatingImage from "./category_card_rating_icon.svg";
import categoryCardLocationImage from "./category_card_location_icon.svg";
import trashBinIcon from "./trash_bin.svg";
import excelAddressModalCoverImage from "./excel-address-modal-cover.png";

import companyBudgetHistoryMinusIcon from "./company-budget-history-minus-icon.svg";
import companyBudgetHistoryPlusIcon from "./company-budget-history-plus-icon.svg";
import yellowCircle from "./yellow-circle.svg";
import tenBisBookingComplete from "./tenbis/booking-complete.svg";

import areYouSureV2 from "./young-woman-discover.png";
import common from "@well-b/well-b-shared";

export const Images = {
    SIZE_LIMIT: 20 * 1024 * 1024,
    services: {
        yoga: yogaImage,
        dancing: dancingImage,
        music: musicImage,
        kids: kidsImage,
        workout: sportsImage,
        nutritionist: nutritionistImage,
        professional: professionalImage,
        dogs: dogsImage,
        deliveries: deliveriesImage,
        gifts: giftsImage,
        happyhours: happyhoursImage,
        groupactivity: groupactivityImage,
        volunteer: volunteerImage,
        massage: massageImage,
        hairstylist: hairstylistImage,
        manicurist: manicuristImage,
        meditation: meditationImage,
        entertainment: entertainmentImage,
        personaldevelop: personaldevelopImage,
        offsites: offsitesImage,
        workshops: workshopsImage,
        psychological: psychologicalImage,
        specialCategory: specialCategoryImage,
        happyHourV2: happyHourV2Image,
        lecturesV2: lecturesV2Image,
        moveYourBodyV2: moveYourBodyV2Image,
        personalServiceProviderV2: personalServiceProviderV2Image,
        workshopsV2: workshopsV2Image,
        hairStylingV2: hairStylingV2Image,
        maniPediV2: maniPediV2Image,
        massageTherapyV2: massageTherapyV2Image,
        activitiesV2: activitiesV2Image,
        sportsV2: sportsV2Image,
        yogaPilatesV2: yogaPilatesV2Image,
        mobile: {
            yoga: yogaMobileImage,
            dancing: dancingMobileImage,
            music: musicMobileImage,
            kids: kidsMobileImage,
            workout: sportsMobileImage,
            nutritionist: nutritionistMobileImage,
            professional: professionalMobileImage,
            dogs: dogsMobileImage,
            deliveries: deliveriesMobileImage,
            gifts: giftsMobileImage,
            happyhours: happyhoursMobileImage,
            groupactivity: groupactivityImage,
            volunteer: volunteerMobileImage,
            massage: massageMobileImage,
            hairstylist: hairstylistMobileImage,
            manicurist: manicuristMobileImage,
            meditation: meditationMobileImage,
            entertainment: entertainmentMobileImage,
            personaldevelop: personaldevelopMobileImage,
            offsites: offsitesMobileImage,
            workshops: workshopsMobileImage,
            psychological: psychologicalMobileImage,
            specialCategory: specialCategoryImage,
            happyHourV2: happyHourV2Image,
            lecturesV2: lecturesV2Image,
            moveYourBodyV2: moveYourBodyV2Image,
            personalServiceProviderV2: personalServiceProviderV2Image,
            workshopsV2: workshopsV2Image,
            hairStylingV2: hairStylingV2Image,
            maniPediV2: maniPediV2Image,
            massageTherapyV2: massageTherapyV2Image,
            activitiesV2: activitiesV2Image,
            sportsV2: sportsV2Image,
            yogaPilatesV2: yogaPilatesV2Image
        },
        icons: {
            happyHoursV2: {
                lightImage: happyHourV2LightIcon,
                hoveredImage: happyHourV2Icon
            },
            packagesV2: {
                lightImage: packageV2LightIcon,
                hoveredImage: packageV2Icon
            },
            plattersV2: {
                lightImage: platterV2LightIcon,
                hoveredImage: platterV2Icon
            },
            workshopsV2: {
                lightImage: workshopV2LightIcon,
                hoveredImage: workshopV2Icon
            },
            lecturesV2: {
                lightImage: lectureV2LightIcon,
                hoveredImage: lectureV2Icon
            }
        }
    },
    selfie: selfieImage,
    oops: oopsImage,
    oops2: oops2Image,
    outOfStock: outOfStockImage,
    areYouSureV2,
    areYouSure: areYouSureImage,
    fail: failImage,
    failWide: failWideImage,
    success: successImage,
    success2: success2Image,
    success3: success3Image,
    successWide: successWideImage,
    confirm: confirmImage,
    employees: employeesImage,
    "active-employees": activeImage,
    "offers-made": sendImage,
    "offers-accepted": acceptedImage,
    "offers-declined": declinedImage,
    "active-professionals": dealImage,
    comment: commentsImage,
    marketplaceBookingCheckboxChecked: marketplaceBookingCheckboxCheckedImage,
    marketplaceBookingCheckboxEmpty: marketplaceBookingCheckboxEmptyImage,
    marketplaceFilterCheckboxChecked: marketplaceFilterCheckboxCheckedImage,
    marketplaceFilterCheckboxEmpty: marketplaceFilterCheckboxEmptyImage,
    price: priceImage,
    paymentPopularSelected: paymentPopularSelectedImage,
    paymentNoPopularSelected: paymentNoPopularSelectedImage,
    paymentPopularUnselected: paymentPopularUnselectedImage,
    paymentNotPopularUnselected: paymentNotPopularUnselectedImage,
    paymentPerActiveDay: paymentPerActiveDayImage,
    creditCardRequired: creditCardRequiredImage,
    eventReminder: eventReminder,
    phone: phoneImage,
    exitDemo: exitDemoImage,
    employeeView: employeeViewImage,
    employeeViewInvert: employeeViewInvertImage,
    menuIcon: menuIconImage,
    hibob: hibobGif,
    spreadsheet: spreadsheetImage,
    tutorial: tutorialIcon,
    feedback: feedbackIcon,
    whatsapp: whatsappIcon,
    calendarInvite: calendarInviteIcon,
    trashBin: trashBinIcon,
    categoryCardPerson: categoryCardPersonImage,
    categoryCardLocation: categoryCardLocationImage,
    categoryCardRating: categoryCardRatingImage,
    loginBackground: loginBackgroundImage,
    loginLogo: loginLogoImage,
    companyWizardSuccess: companyWizardSuccessImage,
    bookingSuccess: bookingSuccessImage,
    allChoices: allChoicesImage,
    creditCardsInARow: creditCardsInARowImage,
    goldMedal: goldMedalImage,
    silverMedal: silverMedalImage,
    bronzeMedal: bronzeMedalImage,
    excelAddressModalCover: excelAddressModalCoverImage,
    happyHourSwitchImage: happyHourSwitchImage,
    packageSwitchImage: packageSwitchImage,
    platterSwitchImage: platterSwitchImage,
    workshopSwitchImage: workshopSwitchImage,
    lectureSwitchImage: lectureSwitchImage,
    companyBudgetHistoryMinusIcon: companyBudgetHistoryMinusIcon,
    companyBudgetHistoryPlusIcon: companyBudgetHistoryPlusIcon,
    pinkFolders: pinkFoldersImage,
    WellBPassover,
    WellBRoshHasana,
    WellBSukot,
    yellowCircle: yellowCircle,
    CalculatorBG,
    unplannedCalendarImage,
    LikeHeartImage,
    undo,
    computerTrashImage,
    nonChrome,
    PageNotFoundImage,
    veganImage,
    lactoseFreeImage,
    glutenFreeImage,
    vegetarianImage,
    parveImage,
    noteImage,
    handshake,
    loginPageImage,
    noOrders
};

export const Logo = {
    image: logoImage,
    [common.themeNames.tenBis]: tenBisLogoImage
};

export const AuthPagesImage = {
    image: loginPageImage,
    [common.themeNames.tenBis]: tenBisLoginPageImage
};

export const BookingCompleteImage = {
    image: Images.bookingSuccess,
    [common.themeNames.tenBis]: tenBisBookingComplete
};
