import React from "react";
import {message} from "antd";
import {ErrorReporter} from "./error/ErrorReporter";

const errorExclusionList = ["ResizeObserver loop limit exceeded", "Script error"];

const stackExclusionList = ["chrome-extension"];

export class AppErrorHandler extends React.Component {
    componentDidMount() {
        window.onerror = function (msg, file, line, col, error) {
            if (typeof msg === "string" && errorExclusionList.some(errMsg => msg.indexOf(errMsg) >= 0)) {
                return;
            }

            if (
                typeof error === "object" &&
                error.stack &&
                stackExclusionList.some(errMsg => error.stack.indexOf(errMsg) >= 0)
            ) {
                return;
            }

            Promise.resolve().then(async () => {
                if (error && typeof error === "object") {
                    ErrorReporter.reportError(error);
                } else {
                    ErrorReporter.reportError(msg);
                }
            });

            console.log("WIN ERROR>", msg, file, line, col, error);
            message.error("Oppsy... something went wrong, please try again later.", 3);
            return true;
        };
    }

    render() {
        const {children} = this.props;
        return children;
    }
}
