import React, {useContext, useEffect, useState} from "react";
import {message, Tabs, Tag, Tooltip} from "antd";
import {Redirect, Route, Switch} from "react-router";
import {LockOutlined} from "@ant-design/icons";
import {CompanyEmployees} from "../company/CompanyEmployees";
import CompanySchedule from "../company/CompanySchedule";
import {AppContext} from "../AppContext";
import {ProviderSchedule} from "../provider/ProviderSchedule";
import ProviderProfile from "../provider/ProviderProfile";
import {ProviderDetails} from "../provider/ProviderDetails";
import {EventOffer} from "../event/EventOffer";
import {ProviderServices} from "../provider/ProviderServices";
import {ProviderDefineServicesNotification} from "../provider/ProviderDefineServicesNotification";
import {ProviderEditProfile} from "../provider/ProviderEditProfile";
import {EmployeeBookings} from "../employee/EmployeeBookings";
import {CompanyContent} from "../company/content/CompanyContent";
import {parse as qsParse} from "query-string";
import {ReviewModal} from "../review/ReviewModal";
import {PageLoader, ScreenSizeAdapter} from "../components";
import {EventBus} from "../bus/EventBus";
import {CompanyWeeklyTemplate} from "../company/weekly/CompanyWeeklyTemplate";
import {CompanyBilling} from "../company/CompanyBilling";
import {hasEXCenterFeatureOpen, isCompanySubAdmin} from "../center/EXCenterHelper";
import {HttpClient} from "../http/HttpClient";
import {ProviderOrders} from "../provider/providerOrders";
import {CompanyMarketplaceContextProvider} from "../marketplace/CompanyMarketplaceContextProvider";

const {TabPane} = Tabs;

const RedirectToCenter = ({branch}) => {
    return branch ? <Redirect to={`/center/main?branchId=${branch.branchId}`} /> : <Redirect to="/center" />;
};

export const extractTabFromUrl = pathPattern => {
    const path = window.location.pathname;
    const match = path.match(pathPattern);

    if (match) {
        return match[1];
    }

    return null;
};

const OldScheduleHandler = () => {
    const {me} = useContext(AppContext);
    const {sync, eventId, date} = qsParse(location.search);

    if (["fail", "success"].some(s => s === sync)) {
        sessionStorage.setItem("syncResult", sync);
    } else if (eventId && date) {
        sessionStorage.setItem(
            "instantEventOpen",
            JSON.stringify({
                eventId,
                date
            })
        );

        return <RedirectToCenter branch={me.branch} />;
    } else if (!location.search && location.pathname === "/dashboard/schedule") {
        sessionStorage.setItem("scrollToSectionId", "calendar");
    }

    return <RedirectToCenter />;
};

export const DefaultAppDashboard = ({history}) => {
    const {me, company} = useContext(AppContext);
    const [providerDetails, setProviderDetails] = useState(null);
    const [loadingProviderDetails, setLoadingProviderDetails] = useState(false);

    useEffect(() => {
        if (me && me.firstLogin) {
            EventBus.trigger("activate_account:open", {label: "First Login", type: "company"});
        }
    }, [me]);

    useEffect(() => {
        if (me && me.type === "provider") {
            setLoadingProviderDetails(true);
            Promise.resolve().then(async () => {
                try {
                    const providerDetails = await HttpClient.get("/api/providers/me");
                    setProviderDetails(providerDetails);

                    if (window.location.pathname.includes("dashboard")) {
                        navigateTo("orders");
                    }
                } catch (e) {
                    message.error("פרטי התחברות נכשלו אנא נסה שוב מאוחר יותר", 5);
                }
            });
            setLoadingProviderDetails(false);
        }
    }, [me]);

    const navigateTo = key => {
        history.push(`/dashboard/${key}`);
    };

    let defaultTabKey = "";
    let tabs = [];
    const activeKey = extractTabFromUrl(
        /^\/dashboard\/(services|orders|schedule|content|billing|profile|employees|insights|myservices|bookings|weekly)(.*)$/
    );

    if (!me.type || (me.type !== "provider" && !company)) return null;

    if (!activeKey) {
        return <Redirect to={`/dashboard/services${window.location.search || ""}`} />;
    }

    if (loadingProviderDetails) {
        return <PageLoader />;
    }

    switch (me.type) {
        case "company":
            defaultTabKey = "services";
            tabs = [
                <TabPane
                    style={{paddingTop: 10, overflow: "hidden", overflowX: "auto"}}
                    tab={
                        !isCompanySubAdmin(me) ? (
                            hasEXCenterFeatureOpen(me, company) ? (
                                <span
                                    data-title="Book vendors from our organizational community"
                                    data-intro="Find cool ideas, locate the right vendors and book them to provide their services for your employees guided by similar companies’ recommendations."
                                    data-namespace="admin"
                                    data-position="bottom"
                                    data-step="4"
                                    data-highlight-class="wb-walk-through-highlight-library"
                                    data-tooltip-class="wb-walk-through-tooltip-library"
                                    data-scroll-to="tooltip">
                                    Market
                                </span>
                            ) : (
                                "Services"
                            )
                        ) : null
                    }
                    key="services">
                    <Route path="/dashboard/services">
                        <CompanyMarketplaceContextProvider history={history} rootPath="/dashboard/services" />
                    </Route>
                    <Route
                        exact
                        path="/dashboard/services/:serviceId/providers/:providerId"
                        component={ProviderDetails}
                    />
                    <Route
                        exact
                        path="/dashboard/services/:serviceId/providers/:providerId/offer/single"
                        component={EventOffer}
                    />
                    <Route
                        exact
                        path="/dashboard/services/:serviceId/providers/:sessionId/offer/multiple"
                        component={EventOffer}
                    />
                </TabPane>
            ];

            if (isCompanySubAdmin(me)) {
                break;
            }

            tabs.push(
                <TabPane
                    style={{paddingTop: 10, overflow: "hidden", overflowX: "auto", color: "#ED7FA6 !important"}}
                    tab={
                        me && me.features.paying_companies ? (
                            hasEXCenterFeatureOpen(me, company) ? (
                                "Activity Board"
                            ) : (
                                "Company Schedule"
                            )
                        ) : (
                            <Tooltip
                                title={
                                    "רוצים לנהל את כל פעילויות הרווחה שלכם במקום אחד? דברו איתנו בווטסאפ למידע נוסף"
                                }>
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <LockOutlined style={{marginRight: "5px", marginTop: "5px"}} />
                                    {hasEXCenterFeatureOpen(me, company) ? "Activity Board" : "Company Schedule"}
                                </div>
                            </Tooltip>
                        )
                    }
                    key="schedule"
                    disabled={me && !me.features.paying_companies}>
                    <Switch>
                        {hasEXCenterFeatureOpen(me, company) ? (
                            <Route
                                exact
                                path="/dashboard/schedule"
                                render={() => {
                                    return <Redirect to={`/dashboard/content${location.search || ""}`} />;
                                }}
                            />
                        ) : (
                            <Route exact path="/dashboard/schedule" component={CompanySchedule} />
                        )}
                        {hasEXCenterFeatureOpen(me, company) ? (
                            <Route path="/dashboard/content" component={CompanyContent} />
                        ) : null}
                        <Route
                            exact
                            path="/dashboard/schedule/events/:eventId/providers/:providerId"
                            component={ProviderDetails}
                        />
                    </Switch>
                </TabPane>
            );

            if (!hasEXCenterFeatureOpen(me, company)) {
                tabs.push(
                    <TabPane
                        style={{paddingTop: 10, overflow: "hidden", overflowX: "auto"}}
                        tab={
                            me && me.features.paying_companies ? (
                                "Employees"
                            ) : (
                                <Tooltip
                                    title={
                                        "רוצים לנהל את כל פעילויות הרווחה שלכם במקום אחד? דברו איתנו בווטסאפ למידע נוסף"
                                    }>
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        <LockOutlined style={{marginRight: "5px", marginTop: "5px"}} />
                                        Employees
                                    </div>
                                </Tooltip>
                            )
                        }
                        disabled={me && !me.features.paying_companies}
                        key="employees">
                        <Switch>
                            <Route exact path="/dashboard/employees" component={CompanyEmployees} />
                        </Switch>
                    </TabPane>
                );
            }

            tabs.push(
                <TabPane
                    style={{paddingTop: 10, overflow: "hidden", overflowX: "auto"}}
                    tab={
                        me && me.features.paying_companies ? (
                            "Employees"
                        ) : (
                            <Tooltip
                                title={
                                    "רוצים לנהל את כל פעילויות הרווחה שלכם במקום אחד? דברו איתנו בווטסאפ למידע נוסף"
                                }>
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <LockOutlined style={{marginRight: "5px", marginTop: "5px"}} />
                                    Employees
                                </div>
                            </Tooltip>
                        )
                    }
                    disabled={me && !me.features.paying_companies}
                    key="employees">
                    <Switch>
                        <Route exact path="/dashboard/employees" component={CompanyEmployees} />
                    </Switch>
                </TabPane>
            );
            tabs.push(
                <TabPane
                    style={{paddingTop: 10, overflow: "hidden", overflowX: "auto"}}
                    tab={
                        me && me.features.paying_companies ? (
                            "Weekly Email"
                        ) : (
                            <Tooltip
                                title={
                                    "רוצים לנהל את כל פעילויות הרווחה שלכם במקום אחד? דברו איתנו בווטסאפ למידע נוסף"
                                }>
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <LockOutlined style={{marginRight: "5px", marginTop: "5px"}} />
                                    Weekly Email
                                </div>
                            </Tooltip>
                        )
                    }
                    disabled={me && !me.features.paying_companies}
                    key="weekly">
                    <Switch>
                        <Route exact path="/dashboard/weekly" component={CompanyWeeklyTemplate} />
                    </Switch>
                </TabPane>
            );
            tabs.push(
                <TabPane
                    style={{paddingTop: 10, overflow: "hidden", overflowX: "auto"}}
                    tab={
                        me && me.features.paying_companies ? (
                            "Billing"
                        ) : (
                            <Tooltip
                                title={
                                    "רוצים לנהל את כל פעילויות הרווחה שלכם במקום אחד? דברו איתנו בווטסאפ למידע נוסף"
                                }>
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <LockOutlined style={{marginRight: "5px", marginTop: "5px"}} />
                                    Billing
                                </div>
                            </Tooltip>
                        )
                    }
                    disabled={me && !me.features.paying_companies}
                    key="billing">
                    <Switch>
                        <Route exact path="/dashboard/billing" component={CompanyBilling} />
                    </Switch>
                </TabPane>
            );

            break;
        case "employee":
            defaultTabKey = "schedule";
            if (hasEXCenterFeatureOpen(me, company)) {
                tabs = [
                    <TabPane tab="Company Schedule" key="schedule">
                        <Switch>
                            <Route exact path="/dashboard/schedule" component={OldScheduleHandler} />
                            <Route
                                exact
                                path="/dashboard/schedule/events/:eventId/providers/:providerId"
                                component={ProviderDetails}
                            />
                        </Switch>
                    </TabPane>,
                    <TabPane tab="My Bookings" key="bookings">
                        <Switch>
                            <Route path="/dashboard/bookings" component={RedirectToCenter} />
                        </Switch>
                    </TabPane>,
                    <TabPane tab="Co-Workers" key="employees">
                        <Switch>
                            <Route exact path="/dashboard/employees" component={RedirectToCenter} />
                        </Switch>
                    </TabPane>
                ];
            } else {
                tabs = [
                    <TabPane tab="Company Schedule" key="schedule">
                        <Switch>
                            <Route exact path="/dashboard/schedule" component={CompanySchedule} />
                            <Route
                                exact
                                path="/dashboard/schedule/events/:eventId/providers/:providerId"
                                component={ProviderDetails}
                            />
                        </Switch>
                    </TabPane>,
                    <TabPane tab="My Bookings" key="bookings">
                        <Switch>
                            <Route path="/dashboard/bookings" component={EmployeeBookings} />
                        </Switch>
                    </TabPane>,
                    <TabPane tab="Co-Workers" key="employees">
                        <Switch>
                            <Route exact path="/dashboard/employees" component={CompanyEmployees} />
                        </Switch>
                    </TabPane>
                ];
            }
            break;
        case "provider":
            defaultTabKey = "schedule";
            if (providerDetails && providerDetails.v2) {
                defaultTabKey = "orders";
                tabs = [
                    <TabPane tab="הזמנות" key="orders">
                        <Switch>
                            <Route path="/dashboard/orders" component={ProviderOrders} />
                        </Switch>
                    </TabPane>
                ];
            } else {
                tabs = [
                    <TabPane tab="My Schedule" key="schedule">
                        <Switch>
                            <Route path="/dashboard/schedule" component={ProviderSchedule} />
                        </Switch>
                    </TabPane>,
                    <TabPane tab="My Services" key="myservices">
                        <Switch>
                            <Route exact path="/dashboard/myservices" component={ProviderServices} />
                        </Switch>
                    </TabPane>,
                    <TabPane tab="My Profile" key="profile">
                        <Switch>
                            <Route exact path="/dashboard/profile" component={ProviderProfile} />
                            <Route exact path="/dashboard/profile/edit" component={ProviderEditProfile} />
                        </Switch>
                    </TabPane>
                ];
            }
            break;
        case "unauthorized":
            window.location.replace("/login");
            break;
        default:
            break;
    }

    return (
        <>
            {me && me.features && me.userId ? <ScreenSizeAdapter /> : null}
            {me.userId ? (
                <>
                    <Route component={ProviderDefineServicesNotification} />
                </>
            ) : null}
            {me.type === "company" ? <ReviewModal /> : null}
            <Tabs
                className={me.type === "company" ? "wb-dashboard-tabs-company" : "wb-dashboard-tabs"}
                animated={false}
                onTabClick={key => navigateTo(key)}
                activeKey={activeKey === "content" ? "schedule" : activeKey}
                tabBarStyle={{
                    color: "#ED7FA6",
                    fontSize: 16,
                    fontWeight: 700,
                    position: "fixed",
                    marginBottom: 20,
                    paddingLeft: me.type === "company" ? 340 : 0,
                    left: 0,
                    top: 0,
                    backgroundColor: "white",
                    width: "100%",
                    zIndex: 10
                }}
                renderTabBar={(DefaultTabBarProps, DefaultTabBar) => {
                    return me.type === "employee" && hasEXCenterFeatureOpen(me, company) ? null : (
                        <DefaultTabBar
                            className={me.type === "company" ? "wb-dashboard-tabs-company" : "wb-dashboard-tabs"}
                            {...DefaultTabBarProps}
                        />
                    );
                }}
                defaultActiveKey={defaultTabKey}>
                {tabs}
            </Tabs>
        </>
    );
};
