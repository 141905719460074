import React, {useContext, useMemo, useRef, useState} from "react";
import {partial} from "lodash";
import {Card, Carousel, Modal} from "antd";
import moment from "moment";
import {withRouter} from "react-router";
import {PageTitleLabel, ProgressiveImageLoader} from "./index";
import {SquareButton} from "./SquareButton";
import {useLocalStorage, useRequest} from "../utils/hooks";
import {AppContext} from "../AppContext";
import "./popup.css";
import "./simple-modal.css";

export const PopupComponent = ({open, onClose, popup, history}) => {
    const steps = popup?.steps || [];
    const carouselRef = useRef(null);
    const [currentStep, setCurrentStep] = useState(0);
    const isSingleStep = steps.length === 1;
    const [isOpen, setIsOpen] = useState(open);

    const CarouselButtons = () => (
        <div
            className="popup-step-buttons-container"
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            }}>
            {currentStep === steps.length - 1 ? (
                <SquareButton
                    className="primary-color-button"
                    onClick={() => {
                        steps[currentStep]?.link && history.push(steps[currentStep].link);
                        onClose && onClose();
                    }}>
                    <div style={{direction: "rtl"}}>{steps[currentStep].buttonText}</div>
                </SquareButton>
            ) : (
                <SquareButton className="primary-color-button" onClick={() => carouselRef.current.next()}>
                    הבא
                </SquareButton>
            )}
            <SquareButton
                disabled={currentStep === 0}
                className="secondary-color-button"
                onClick={() => carouselRef.current.prev()}>
                קודם
            </SquareButton>
        </div>
    );

    return steps.length ? (
        <Modal
            centered
            closable
            destroyOnClose
            mask
            maskClosable={false}
            className="popup-modal-container"
            onCancel={() => {
                onClose && onClose();
                setIsOpen(false);
            }}
            footer={null}
            title={null}
            width={700}
            open={isOpen}>
            <Carousel
                dots={{className: "popup-step-dots"}}
                effect={"fade"}
                ref={carouselRef}
                style={{display: "flex", flexDirection: "column"}}
                afterChange={setCurrentStep}>
                {steps.map((step, index) => {
                    return (
                        <div key={index}>
                            <PopupStep {...step} />
                        </div>
                    );
                })}
            </Carousel>
            {isSingleStep ? (
                <div className="popup-step-single-button-container">
                    <SquareButton
                        className="primary-color-button"
                        onClick={() => {
                            steps[currentStep]?.link && history.push(steps[currentStep].link);
                            onClose && onClose();
                        }}>
                        <div style={{direction: "rtl"}}>{steps[currentStep].buttonText}</div>
                    </SquareButton>
                </div>
            ) : (
                <CarouselButtons />
            )}
        </Modal>
    ) : (
        <></>
    );
};

export const PopupStep = ({title = null, description = null, link = "#", img = null}) => (
    <Card bordered={false} cover={<ProgressiveImageLoader src={img.imageUrl} width={700} height={400} />}>
        <div className="popup-modal-content">
            {title && (
                <PageTitleLabel className="popup-modal-title">
                    <div style={{direction: "rtl"}}>{title}</div>
                </PageTitleLabel>
            )}
            <div className="popup-modal-break-line" />

            {description && (
                <div className="popup-modal-text-container">
                    {description.split("\n").map((paragraph, index) => (
                        <p className="popup-modal-text" id={`simpleModalText${index}`} key={index}>
                            {paragraph}
                        </p>
                    ))}
                </div>
            )}
        </div>
    </Card>
);

export const Popup = withRouter(PopupComponent);

function isPopupActive(popup) {
    const startDate = new moment(popup.startDate);
    const endDate = new moment(popup.endDate);
    const now = moment.now();

    return startDate <= now && now <= endDate;
}

function showPopupInCurrentPath(currentPath, popup) {
    if (popup.pages.includes(currentPath)) return true;
    if (popup.pages.some(page => currentPath.startsWith(page))) return true;
}

export const PopupManagerCommponent = ({history}) => {
    const [viewedPopups, setViewedPopups] = useLocalStorage("viewedPopups", []);
    const {me} = useContext(AppContext);

    const [data, loading, error, manualFetch] = useRequest(
        "/api/popup",
        "GET",
        null,
        [me?.userId],
        me?.userId || false,
        null,
        []
    );
    const popup = useMemo(() => {
        if (!data) return [];

        let currentPath = history.location.pathname;
        currentPath = currentPath.substring(1);

        const relevantPopups = data
            .filter(popup => popup.isActive)
            .filter(popup => popup?.pages?.length > 0)
            .filter(isPopupActive)
            .filter(partial(showPopupInCurrentPath, currentPath))
            .filter(popup => !viewedPopups.includes(popup._id));

        return relevantPopups[0];
    }, [data, history.location.pathname, viewedPopups, setViewedPopups, me]);

    return popup ? (
        <Popup
            open
            onClose={() => {
                setViewedPopups([...viewedPopups, popup._id]);
            }}
            popup={popup}
        />
    ) : (
        <></>
    );
};

export const PopupManager = withRouter(PopupManagerCommponent);
