import React, {useState} from "react";
import {FormInput} from "../components/form";
import {StyledButton} from "../components/StyledButton";
import zoomLogo from "../images/zoom_meeting.png";
import {ZoomMeetingClient} from "./ZoomMeetingClient";
import {message, Tooltip} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {stringify as qsStringify} from "query-string";
import shortid from "shortid";

const toType = isRecurring => (isRecurring ? "recurring" : "occurrence");

export const ZoomLinkInput = ({placeholder, onChange, value, recurring = false}) => {
    const [generating, setGenerating] = useState(false);

    const listenToMeetingLinkGenerationFromAuthenticationProcess = (openedWindow, sessionId) => {
        const onMessage = ({key, newValue}) => {
            if (key === sessionId) {
                onChange(newValue);
                window.removeEventListener("storage", onMessage);
                message.success("Meeting link created successfully.", 8);
                openedWindow && openedWindow.close();
                window.focus();
                window.localStorage.removeItem(key);
            }
        };

        window.addEventListener("storage", onMessage);
    };

    const generateZoomMeetingLink = async () => {
        setGenerating(true);

        try {
            const result = recurring
                ? await ZoomMeetingClient.generateRecurringMeetingLink()
                : await ZoomMeetingClient.generateOccurrenceLink();

            if (!result.authenticated) {
                const sessionId = shortid.generate();

                const openedWindow = window.open(
                    `/api/zoom/login?state=${encodeURIComponent(
                        qsStringify({
                            type: toType(recurring),
                            sessionId
                        })
                    )}`,
                    "_blank"
                );

                listenToMeetingLinkGenerationFromAuthenticationProcess(openedWindow, sessionId);
            } else {
                onChange(result.meetingLink);
            }
        } catch (e) {
            message.error("Failed to generate meeting link.");
        }

        setGenerating(false);
    };

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                width: "100%"
            }}>
            <FormInput style={{width: "100%"}} value={value} onChange={onChange} placeholder={placeholder} />
            <Tooltip title="Create a Zoom meeting link">
                <StyledButton
                    style={{
                        width: 48,
                        height: 48,
                        border: "none",
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 0
                    }}
                    onClick={generateZoomMeetingLink}>
                    {generating ? (
                        <LoadingOutlined style={{fontSize: 16}} />
                    ) : (
                        <img alt="zoom_logo" src={zoomLogo} width={32} height={24} />
                    )}
                </StyledButton>
            </Tooltip>
        </div>
    );
};
