import {PaymentType, PaymentTypeToName, ServiceIdToName} from "../consts.js";

export const BUDGET_LOAD_TRANSACTION = "budgetLoad";
export const ON_ORDER_BUDGET_TRANSACTION = "onOrderBudgetTransaction";

export const TransactionServiceIdToName = {
    ...ServiceIdToName,
    [BUDGET_LOAD_TRANSACTION]: "טעינת תקציב"
};

export const AdminOrdersPaymentTypeToName = {
    ...PaymentTypeToName,
    [BUDGET_LOAD_TRANSACTION]: "טעינת תקציב",
    [PaymentType.Budget]: "מימושי תקציב (שולם מראש)",
    [ON_ORDER_BUDGET_TRANSACTION]: "מימושי תקציב (מסגרת)"
};

export const getOrderPaymentType = order => {
    if (order.paymentType === PaymentType.Budget && order.payOnOrderBudgetTransaction) {
        return AdminOrdersPaymentTypeToName[ON_ORDER_BUDGET_TRANSACTION];
    }

    return AdminOrdersPaymentTypeToName[order.paymentType];
};

export const excelSheetsTitle = {
    needInvoiceTransactions: "חשבונית להפקה",
    budgetsOrder: "עסקאות תקציב - לא לגביה",
    creditCardOrders: "עסקאות אשראי - לא לגביה",
    orders: "הזמנות"
};
