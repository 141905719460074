export class EventBus {
    static on(name, handler) {
        if (!("_wbEventBus" in window)) {
            window["_wbEventBus"] = {};
        }

        if (!(name in window["_wbEventBus"])) {
            window["_wbEventBus"][name] = [handler];
        } else {
            window["_wbEventBus"][name].push(handler);
        }
    }

    static off(name, handler) {
        if (!("_wbEventBus" in window) || !(name in window["_wbEventBus"])) {
            return;
        }

        if (!handler) {
            window["_wbEventBus"][name] = [];
        } else {
            window["_wbEventBus"][name].filter(func => func !== handler);
        }
    }

    static trigger(name, data) {
        if ("_wbEventBus" in window && name in window["_wbEventBus"] && Array.isArray(window["_wbEventBus"][name])) {
            window["_wbEventBus"][name].forEach(handler => {
                handler(data);
            });
        }
    }

    static triggerError(name, data, realError) {
        console.log("ERROR>", realError);
        this.trigger(name, data);
    }
}
