import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {cloneDeep, set, unset} from "lodash";
import {useRequest} from "../../utils/hooks";
import {AppContext} from "../../AppContext";
import {buildFilters} from "./advancedFilter/utils.js";
import {getLogger} from "../../Logger.jsx";
import {FILTER_VERSION} from "./consts.js";
import {getLocationTitle} from "./popoverFilters/locationFilter/utils.js";
import {getBudgetSectionTitle} from "./popoverFilters/budgetFilter/utils.js";
import {getAccuracyResultsFilterTitle} from "./popoverFilters/accuracyResultsFilter/utils.js";
import {panelOptions} from "./panel/consts.jsx";

export const MarketplaceFilterProviderContext = createContext();

export const MarketplaceFilterContext = ({children}) => {
    const [innerState, setInnerState] = useState({});
    const [panel, setPanel] = useState(panelOptions.homePage);
    const {filterValues, setFilterValues} = useContext(AppContext);
    const [topTags] = useRequest("/api/searchTags/getTopSearchTags");
    const [budgetFilter, setBudgetFilter] = useState({});
    const [accuracyResultsTitle, setAccuracyResultsTitle] = useState(null);
    const [budgetTitle, setBudgetTitle] = useState(null);
    const [addressName, setAddressName] = useState(null);

    const log = useMemo(() => getLogger({version: FILTER_VERSION, panel}, "MarketplaceFilterContext"), [panel]);

    useEffect(() => {
        if (Object.keys(innerState).length) {
            const filterProperties = buildFilters(innerState, panel);
            const eventProperties = {
                ...filterProperties,
                filterProperties: filterProperties
            };
            log("Filter input change", eventProperties);
        }
    }, [innerState]);

    const updateInnerState = useCallback((key, value) => {
        setInnerState(currentFormValues => cloneDeep(set({...currentFormValues}, key, value)));
    }, []);

    const onSearch = useCallback(
        (history, filters) => {
            setFilterValues({...filters});
            if (filters?.searchText) {
                history.push(`/dashboard/search?search=${filters.searchText}`);
            } else {
                history.push(`/dashboard/search`);
            }
        },
        [innerState, panel]
    );

    const resetState = useCallback(
        (history, selectedPanel) => {
            setInnerState({});
            setFilterValues({});
            setBudgetTitle(null);
            setAccuracyResultsTitle(null);
            setAddressName(null);
            if (history) {
                const filters = buildFilters({}, selectedPanel ?? panel);
                onSearch(history, filters);
            }
        },
        [onSearch, panel]
    );

    const removeKeyFromInnerState = useCallback(key => {
        setInnerState(currentFormValues => {
            const newState = cloneDeep(currentFormValues);
            unset(newState, key);
            return newState;
        });
    }, []);

    useEffect(() => {
        setBudgetTitle(getBudgetSectionTitle(innerState.budget, panel));
        setAddressName(getLocationTitle(innerState?.locationFilter));
        setAccuracyResultsTitle(getAccuracyResultsFilterTitle(innerState.accuracyResults));
    }, [filterValues]);

    const updateLocalFilterState = useCallback(
        filterValues => {
            if (!filterValues.budget) {
                removeKeyFromInnerState("budget");
            }

            if (filterValues?.kosherTypes?.length > 0) {
                updateInnerState("accuracyResults.foodOptions", filterValues?.kosherTypes);
            } else {
                removeKeyFromInnerState("accuracyResults.foodOptions");
            }

            if (filterValues?.productProperties?.length > 0) {
                updateInnerState("accuracyResults.productProperties", filterValues?.productProperties);
            } else {
                removeKeyFromInnerState("accuracyResults.productProperties");
            }

            if (filterValues?.activityRequestsProperties?.length > 0) {
                updateInnerState(
                    "accuracyResults.activityRequestsProperties",
                    filterValues?.activityRequestsProperties
                );
            } else {
                removeKeyFromInnerState("accuracyResults.activityRequestsProperties");
            }

            const currentTab = innerState?.locationFilter?.currentTab;
            const currentTabDetails = innerState?.locationFilter?.[currentTab];
            if (!filterValues?.deliveryAddress && currentTabDetails) {
                if (currentTabDetails?.key) {
                    removeKeyFromInnerState(`locationFilter.${currentTab}.${currentTabDetails?.key}`);
                    removeKeyFromInnerState(`locationFilter.${currentTab}.key`);
                } else {
                    removeKeyFromInnerState(`locationFilter.${currentTab}`);
                }
            }
        },
        [innerState]
    );

    return (
        <MarketplaceFilterProviderContext.Provider
            value={{
                panel,
                setPanel,
                topTags,
                resetState,
                updateInnerState,
                innerState,
                removeKeyFromInnerState,
                addressName,
                updateLocalFilterState,
                onSearch,
                setInnerState,
                budgetFilter,
                setBudgetFilter,
                accuracyResultsTitle,
                setAccuracyResultsTitle,
                budgetTitle,
                setBudgetTitle,
                setAddressName
            }}>
            {children}
        </MarketplaceFilterProviderContext.Provider>
    );
};
