import React from "react";
import {Select} from "antd";
import {FormInput} from "../../../components/form";

const {Option} = Select;

const integrations = [
    {name: "Hibob", value: "hibob"},
    {name: "BambooHR", value: "bamboohr"}
];

export const PeopleManagementIntegrationSelect = ({value, onChange}) => {
    let selectedIntegrationType = void 0;
    let integrationToken = void 0;

    const doChange = (type, token) => {
        if (!type) {
            onChange();
        } else {
            onChange({
                type,
                params: {
                    token
                }
            });
        }
    };

    if (value) {
        const {type, params} = value;
        selectedIntegrationType = type || void 0;
        integrationToken = params && params.token ? params.token : void 0;
    }

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                width: "100%"
            }}>
            <Select
                onChange={type => doChange(type, void 0)}
                value={selectedIntegrationType}
                placeholder="Integration"
                style={{marginRight: 10}}
                allowClear={true}
                onClear={() => doChange(void 0, void 0)}>
                {integrations.map(({name, value}) => (
                    <Option key={value} value={value}>
                        {name}
                    </Option>
                ))}
            </Select>
            <FormInput
                value={integrationToken}
                disabled={!selectedIntegrationType}
                type="password"
                placeholder="API Token"
                onChange={({target}) => doChange(selectedIntegrationType, target.value)}
            />
        </div>
    );
};
