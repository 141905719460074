import React, {useContext, useEffect, useMemo} from "react";
import {Skeleton} from "antd";
import classNames from "classnames";
import {pinterestCarouselId} from "./consts";
import {CAROUSEL_PRODUCT_GAP, MAX_CAROUSEL_LENGTH, MAX_PRODUCT_ITEM_WIDTH} from "../MarketplaceUtils";
import MiniCategoriesCarousel from "../Carousel/MiniCategoriesCarousel";
import {useWindowDimensions} from "../../utils/hooks";
import SelectionPageDrivenCarousel from "../Carousel/SelectionPageDrivenCarousel";
import {getLogger} from "../../Logger";
import {CompanyBudgetLabel} from "../../company/CompanyBudgetLabel";
import {CompanyMarketplaceImagesCarousel} from "../OrderImagesCarousel/CompanyMarketplaceImagesCarousel";
import {AppContext} from "../../AppContext";
import {WBCarousel} from "../../components";
import "./dynamic-layout-page.css";
import {MarketplaceFilter} from "../MarketplaceFilter/MarketplaceFilter.jsx";
import {isNull} from "lodash";

const BaseDynamicLayoutPage = ({history, pageLayout, loadingPageLayoutPage}) => {
    const {me, setIsolationMode} = useContext(AppContext);
    const {width} = useWindowDimensions();

    const carouselLength = useMemo(() => (width > 1680 ? MAX_CAROUSEL_LENGTH : MAX_CAROUSEL_LENGTH - 1), [width]);

    const carouselMaxWidth = useMemo(() => {
        return carouselLength * MAX_PRODUCT_ITEM_WIDTH + (carouselLength - 1) * CAROUSEL_PRODUCT_GAP;
    }, [carouselLength]);

    const log = useMemo(
        () => getLogger({pageId: pageLayout?.pageLayoutId}, "Dynamic Layout Page"),
        [pageLayout?.pageLayoutId]
    );

    useEffect(() => {
        setIsolationMode(pageLayout?.isIsolated ? {pageLayoutId: pageLayout.pageLayoutId} : null);
    }, [pageLayout?.isIsolated]);

    return (
        <div>
            <div className="dynamic-layout-page">
                <div
                    className={classNames("dynamic-layout-page-carousels-container", "marketplace-category-providers")}
                    style={{
                        maxWidth: carouselMaxWidth
                    }}>
                    {!!pageLayout?.title && (
                        <div className="marketplace-main-page-header-row">
                            <label>{pageLayout?.title}</label>
                        </div>
                    )}
                    {loadingPageLayoutPage ? (
                        <div className="dynamic-layout-page-skeleton-container">
                            {[...Array(5)].map((_, index) => (
                                <div key={index} className="dynamic-layout-page-skeleton-carousel">
                                    <WBCarousel
                                        key="skeleton"
                                        carouselProps={{
                                            slidesToShow: carouselLength,
                                            variableWidth: false
                                        }}>
                                        {Array.from(Array(carouselLength).keys()).map(index => (
                                            <Skeleton.Input
                                                key={index}
                                                className="dynamic-layout-page-skeleton"
                                                active={true}
                                            />
                                        ))}
                                    </WBCarousel>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="dynamic-layout-page-carousels-container-list">
                            {pageLayout?.carousels.map((carouselProps, index) => {
                                if (me.features.pinterest && carouselProps.carouselId === pinterestCarouselId) {
                                    return (
                                        <div key={index} className="dynamic-layout-page-pinterest-carousel-container">
                                            <CompanyMarketplaceImagesCarousel
                                                history={history}
                                                log={log}
                                                title={carouselProps?.carouselName || ""}
                                            />
                                        </div>
                                    );
                                }

                                return carouselProps.selectPageId ? (
                                    <div key={index} className="dynamic-layout-page-select-page-carousel-container">
                                        <SelectionPageDrivenCarousel
                                            selectPageId={carouselProps.selectPageId}
                                            name={carouselProps?.carouselName || ""}
                                            log={log}
                                            size={carouselLength === MAX_CAROUSEL_LENGTH ? "regular" : "small"}
                                            history={history}
                                            gridNumber={carouselProps.gridNumber}
                                        />
                                    </div>
                                ) : (
                                    <MiniCategoriesCarousel
                                        history={history}
                                        size={carouselLength === MAX_CAROUSEL_LENGTH ? "regular" : "small"}
                                        carousel={carouselProps}
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BaseDynamicLayoutPage;
