import {Select} from "antd";
import React, {useEffect, useState} from "react";
import {FormInput} from "./form";

const {Option} = Select;

const DurationTypeSelect = ({value, onSelect, selectorStyle}) => (
    <Select style={selectorStyle} value={value} onSelect={onSelect}>
        <Option style={selectorStyle} value={DurationInput.Type.MIN}>
            {DurationInput.Type.MIN.toUpperCase()}
        </Option>
        <Option style={selectorStyle} value={DurationInput.Type.HOUR}>
            {DurationInput.Type.HOUR.toUpperCase()}
        </Option>
        <Option style={selectorStyle} value={DurationInput.Type.DAY}>
            {DurationInput.Type.DAY.toUpperCase()}
        </Option>
    </Select>
);

export const DurationInput = ({value = 0, onChange, onDurationTypeChange, durationType, style, selectorStyle}) => {
    const [_durationType, setDurationType] = useState(durationType || DurationInput.Type.MIN);

    const handleChange = e => {
        const val = e.target.value;
        if (_durationType === DurationInput.Type.HOUR) {
            onChange(!!val ? val * 60 : void 0);
        } else if (_durationType === DurationInput.Type.DAY) {
            onChange(!!val ? val * 60 * 24 : void 0);
        } else {
            onChange(val);
        }
    };

    const doDurationTypeChange = val => {
        onDurationTypeChange && onDurationTypeChange(val);
        setDurationType(val);
    };

    if (!!value && !isNaN(value)) {
        if (_durationType === DurationInput.Type.HOUR) {
            value = value / 60;
        } else if (_durationType === DurationInput.Type.DAY) {
            value = value / 24 / 60;
        }
    }

    return (
        <FormInput
            className="wb-input-with-postfix"
            type="number"
            onChange={handleChange}
            value={Math.round(value * 100) / 100}
            style={{borderRadius: 10, ...style}}
            placeholder="Duration"
            addonAfter={
                <DurationTypeSelect
                    selectorStyle={selectorStyle}
                    onSelect={val => doDurationTypeChange(val)}
                    value={durationType || _durationType}
                />
            }
        />
    );
};

DurationInput.Type = {
    MIN: "min",
    HOUR: "hour",
    DAY: "day"
};
