import React, {useEffect, useState} from "react";
import {HttpClient} from "../../../http/HttpClient";
import {Button, message, Spin, List} from "antd";
import {StyledButton} from "../../../components/StyledButton";
import {CloseOutlined, PlusOutlined} from "@ant-design/icons";
import {CompanyContentNewTagModal} from "./CompanyContentNewTagModal";
import {CompanyContentTagEdit} from "./CompanyContentTagEdit";
import {EventBus} from "../../../bus/EventBus";
import {GoogleAnalytics} from "../../../GoogleAnalytics";
import shortid from "shortid";
import {PageLoader} from "../../../components/PageLoader";

export const CompanyContentTagsList = ({sectionId, category, onClose}) => {
    const [loading, setLoading] = useState(false);
    const [tagToEdit, setTagToEdit] = useState(null);
    const [addNewTag, setAddNewTag] = useState(false);
    const [tags, setTags] = useState(null);
    const [initialTags, setInitialTags] = useState(null);

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const tgs = await HttpClient.get(`/api/tags/${sectionId}`);
                setTags(tgs);
                setInitialTags(tgs);
            } catch (e) {
                message.error("Something went wrong, please try again later.", 5);
            }
        });
    }, []);

    const saveTags = async () => {
        setLoading(true);
        try {
            const savedTags = await HttpClient.post(`/api/tags/${sectionId}`, tags || []);
            EventBus.trigger(`tags_update:${sectionId}`, savedTags);
            GoogleAnalytics.event("EX Center - Tags", "Update Tags", sectionId);
            onClose();
        } catch (e) {
            if (e.statusCode === 400) {
                setTags(initialTags);
                EventBus.triggerError(
                    "server-error",
                    {
                        content: {
                            description: e.message,
                            hideSteps: true,
                            hideSubTitle: true,
                            title: "Ohh..."
                        },
                        cta: {hide: true}
                    },
                    e.message
                );
            } else {
                message.error("Something went wrong, please try again later.", 5);
            }
        }
        setLoading(false);
    };

    const removeTag = tagInfo => {
        setTags(tags.filter(({tagId}) => tagId !== tagInfo.tagId));
    };

    const addTag = () => {
        setAddNewTag(true);
    };

    const closeNewTagModal = tagInfo => {
        if (tagInfo) {
            tagInfo.tagId = shortid.generate();
            setTags(tags.concat([{...tagInfo, sectionId}]));
        }
        setAddNewTag(false);
    };

    const editTag = tagInfo => {
        setTagToEdit({...tagInfo, sectionId});
    };

    const closeEditTagModal = tagInfo => {
        if (tagInfo) {
            setTags(
                tags.map(tag => {
                    if (tag.tagId === tagInfo.tagId) {
                        return {...tagInfo, sectionId};
                    } else {
                        return tag;
                    }
                })
            );
        }
        setTagToEdit(null);
    };

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
            <CompanyContentNewTagModal tags={tags} visible={addNewTag} onClose={closeNewTagModal} />
            <CompanyContentTagEdit
                tags={tags}
                tagToEdit={tagToEdit}
                visible={tagToEdit !== null}
                onClose={closeEditTagModal}
            />
            {tags === null ? (
                <PageLoader align="flex-start" top={80} />
            ) : (
                <List
                    size="small"
                    style={{borderRadius: 10, width: "100%"}}
                    bordered={true}
                    header={
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                height: 50
                            }}>
                            <label style={{fontSize: 18, color: "var(--secondary-color)"}}>Categories</label>
                            <StyledButton
                                onClick={() => addTag()}
                                style={{border: "none", width: 30, height: 30}}
                                icon={<PlusOutlined style={{fontSize: 16, color: "var(--secondary-color)"}} />}
                            />
                        </div>
                    }
                    locale={{
                        emptyText: (
                            <div style={{height: 100, display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <StyledButton
                                    onClick={() => addTag()}
                                    style={{
                                        borderRadius: 25,
                                        width: 150,
                                        textAlign: "center",
                                        backgroundColor: "var(--secondary-color)",
                                        height: 50,
                                        color: "white"
                                    }}>
                                    Add category
                                </StyledButton>
                            </div>
                        )
                    }}
                    footer={
                        <div style={{display: "flex", justifyContent: "center", height: 70, alignItems: "center"}}>
                            <StyledButton
                                onClick={() => onClose()}
                                style={{
                                    backgroundColor: "white",
                                    border: "1px solid var(--secondary-color)",
                                    color: "var(--secondary-color)",
                                    width: 120,
                                    marginRight: 10
                                }}>
                                Cancel
                            </StyledButton>
                            <StyledButton
                                loading={loading}
                                onClick={saveTags}
                                style={{backgroundColor: "#00CF82", border: "none", color: "white", width: 120}}>
                                Save
                            </StyledButton>
                        </div>
                    }
                    dataSource={tags || []}
                    renderItem={tagInfo => {
                        const {name} = tagInfo;
                        return (
                            <List.Item>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%"
                                    }}>
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                        <label style={{color: "var(--secondary-color)", fontSize: 14, fontWeight: 500}}>
                                            <Button
                                                onClick={() => editTag(tagInfo)}
                                                type="link"
                                                style={{padding: 0, fontSize: 16}}>
                                                {name}
                                            </Button>
                                        </label>
                                        <label style={{color: "#747F92", fontSize: 12, fontWeight: 400}}>
                                            {category}
                                        </label>
                                    </div>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <StyledButton
                                            onClick={() => removeTag(tagInfo)}
                                            style={{
                                                border: "none",
                                                width: 20,
                                                height: 20,
                                                paddingRight: 10,
                                                marginLeft: 10
                                            }}
                                            icon={<CloseOutlined style={{fontSize: 14, color: "#767F90"}} />}
                                        />
                                    </div>
                                </div>
                            </List.Item>
                        );
                    }}
                />
            )}
        </div>
    );
};
