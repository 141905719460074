import React, {useMemo} from "react";
import classNames from "classnames";
import MiniCategoryItem from "./MiniCategoryItem";
import {WBCarousel} from "../../components";
import {MarketplaceCarouselProps} from "../MarketplaceHelper";
import "./mini-categories-carousel.css";

const MiniCategoriesCarousel = ({history, size, carousel}) => {
    const carouselLength = useMemo(() => carousel.gridNumber - (size === "small" ? 1 : 0), [carousel.gridNumber, size]);
    const showAsBannerCarousel = useMemo(() => carousel.gridNumber === 0, [carousel.gridNumber]);
    const slidesToShow = showAsBannerCarousel ? 2.5 : carouselLength;
    const initialSlide = Math.round(carousel.carouselItems.length - slidesToShow);

    if (!carousel.carouselItems.length) {
        return null;
    }

    return (
        <div className={classNames("mini-categories-carousel-container", {showAsBannerCarousel})}>
            <div className="mini-categories-carousel-title">{carousel?.carouselName || ""}</div>
            <WBCarousel
                carouselProps={{
                    ...MarketplaceCarouselProps,
                    slidesToShow: slidesToShow,
                    className: "mini-categories-carousel",
                    initialSlide: initialSlide,
                    slidesToScroll: showAsBannerCarousel ? 1 : carouselLength,
                    ...(showAsBannerCarousel
                        ? {
                              infinite: carousel?.carouselItems?.length >= 3,
                              autoplay: true,
                              autoplaySpeed: 5000,
                              rtl: true
                          }
                        : {})
                }}>
                {carousel.carouselItems
                    .map(props => {
                        const itemProps = {...props};
                        const amount =
                            props.carouselItemType === "selectionPage" ? props?.selectPage?.productIds?.length ?? 0 : 0;
                        if (amount) {
                            itemProps.amount = amount;
                        }
                        return (
                            <MiniCategoryItem
                                history={history}
                                key={props.carouselItemId}
                                customCarouselHeight={carousel.customCarouselHeight}
                                showAsBannerCarousel={showAsBannerCarousel}
                                {...itemProps}
                            />
                        );
                    })
                    .reverse()}
            </WBCarousel>
        </div>
    );
};

export default MiniCategoriesCarousel;
