import {Avatar} from "antd";
import React, {useContext} from "react";
import {AppContext} from "../../AppContext";
import {EXCenterContext} from "../EXCenterContext";

export const EXCenterProfileAvatar = ({style}) => {
    const {me} = useContext(AppContext);
    const {theme} = useContext(EXCenterContext);

    return me.imageUrl ? (
        <Avatar
            src={me.imageUrl}
            style={{
                marginRight: 10,
                width: 40,
                height: 40,
                ...style
            }}
        />
    ) : (
        <Avatar
            style={{
                marginRight: 10,
                width: 40,
                height: 40,
                fontSize: 16,
                fontWeight: 900,
                backgroundColor: theme.primaryColor,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ...style
            }}>
            {(me.firstName || me.companyName).charAt(0).toUpperCase()}
        </Avatar>
    );
};
