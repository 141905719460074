import React, {useContext, useState} from "react";
import {EXCenterContext} from "../EXCenterContext";
import {EXCenterSectionDesignMenu} from "../control/EXCenterSectionDesignMenu";
import {AppContext} from "../../AppContext";
import {EXCenterSearchInput} from "../search/EXCenterSearchInput";
import {StyledButton, SmartImageContainer} from "../../components";
import {isScreenSizeOfTypes, ScreenSize} from "../EXCenterHelper";

export const EXCenterTopTitleAndSearch = ({designInfo, history}) => {
    const {theme, screenSize} = useContext(EXCenterContext);
    const [searchTerm, setSearchTerm] = useState("");

    const doSearch = () => {
        if (searchTerm) {
            history.push(`/center/search?q=${encodeURIComponent(searchTerm)}`);
        }
    };

    return (
        <div
            className="wb-ss-full-width"
            style={{
                width: "50%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "8%",
                zIndex: 2
            }}>
            <div
                className="wb-ss-justify-center"
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    color: theme.textColor,
                    fontSize: isScreenSizeOfTypes([ScreenSize.Tablet, ScreenSize.Mobile], screenSize) ? 46 : 52,
                    fontWeight: 900,
                    lineHeight: "70px",
                    width: "100%",
                    maxWidth: 550,
                    whiteSpace: "pre-line"
                }}>
                {designInfo.title}
            </div>
            {designInfo.description.enabled ? (
                <div
                    className="wb-ss-justify-center"
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        color: theme.textColor,
                        marginTop: 25,
                        fontSize: 20,
                        fontWeight: 200,
                        lineHeight: "35px",
                        maxWidth: 550,
                        width: "100%",
                        whiteSpace: "pre-line"
                    }}>
                    {designInfo.description.text}
                </div>
            ) : null}
            {designInfo.search.enabled ? (
                <div style={{width: "100%", textAlign: "left", maxWidth: 550}}>
                    <div
                        className="wb-ss-justify-center"
                        style={{display: "flex", alignItems: "center", justifyContent: "flex-start", marginTop: 30}}>
                        <EXCenterSearchInput
                            placeholder={designInfo.search.text}
                            onEnter={() => doSearch()}
                            onSearch={term => setSearchTerm(term)}
                            containerStyle={{paddingLeft: 0, maxWidth: 250}}
                            style={{marginBottom: 0, width: 250, maxWidth: 250, pointerEvents: "auto"}}
                        />
                        <StyledButton
                            style={{
                                width: 90,
                                borderRadius: 25,
                                height: 50,
                                border: "none",
                                color: "white",
                                backgroundColor: theme.primaryColor,
                                boxShadow: "none",
                                marginLeft: 10,
                                pointerEvents: "auto"
                            }}
                            disabled={!searchTerm}
                            onClick={doSearch}>
                            Search
                        </StyledButton>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export const EXCenterTopCover = ({designInfo}) => (
    <SmartImageContainer
        className="wb-ss-full-width wb-center-top-cover"
        width={770}
        height={700}
        src={designInfo.coverPhoto}
        style={{
            width: "50%",
            minWidth: 360,
            paddingBottom: "45%",
            maxHeight: 700,
            backgroundColor: "white"
        }}
    />
);

export const EXCenterTop = ({history}) => {
    const sectionId = "top";
    const {me} = useContext(AppContext);
    const {designInfoPerSection} = useContext(EXCenterContext);

    const sectionDesignInfo = designInfoPerSection[sectionId];

    if (me.type === "employee" && !sectionDesignInfo.display) {
        return null;
    }

    return (
        <div
            className="wb-center-section"
            style={{
                display: "block",
                width: "100%"
            }}>
            <EXCenterSectionDesignMenu
                className="wb-center-design-top-menu"
                coverSize={{width: 770, height: 700}}
                sectionId={sectionId}
                editItems={{
                    coverPhoto: true,
                    title: true,
                    description: true,
                    search: true
                }}
            />
            <div
                className="wb-ss-wrap-reverse"
                style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    opacity: sectionDesignInfo.display ? 1 : 0.3,
                    pointerEvents: "none"
                }}>
                <EXCenterTopTitleAndSearch designInfo={sectionDesignInfo} history={history} />
                <EXCenterTopCover designInfo={sectionDesignInfo} />
            </div>
        </div>
    );
};
