import {findContentSectionById} from "../../company/content/CompanyContent";

export const ensureDesignForSection = (designInfo, sectionId, theme) => {
    const defaultDesign = findContentSectionById(sectionId).defaultDesign(theme);
    if (designInfo && (!designInfo.design || !designInfo.design[sectionId])) {
        designInfo.design = {
            ...designInfo.design,
            [sectionId]: defaultDesign
        };
    }

    if (designInfo && designInfo.design && designInfo.design[sectionId] && !designInfo.design[sectionId].coverPhoto) {
        designInfo.design[sectionId].coverPhoto = defaultDesign.coverPhoto || null;
    }

    return designInfo;
};
