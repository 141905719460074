import React, {useCallback, useEffect, useState} from "react";
import {StyledButton} from "../index.jsx";
import "./button-group.css";
import classnames from "classnames";
import {NOOP} from "../../utils/NOOP.jsx";

export const ButtonGroup = ({
    options = [],
    onSelect = NOOP,
    selectedOption,
    defaultSelectedOption,
    allowDeselect = true
}) => {
    const [innerSelectedOption, setSelectedOption] = useState(defaultSelectedOption);

    useEffect(() => {
        setSelectedOption(selectedOption);
    }, [selectedOption]);

    const onClickOption = useCallback(
        option => {
            if (!allowDeselect && option?.value === innerSelectedOption) {
                return;
            }

            onSelect(option?.value === innerSelectedOption ? null : option);
            setSelectedOption(option?.value === innerSelectedOption ? null : option.value);
        },
        [onSelect, innerSelectedOption, setSelectedOption]
    );

    return (
        <div className="button-group-container">
            {options.map(option => (
                <StyledButton
                    key={option.value}
                    onClick={() => onClickOption(option)}
                    className={classnames("shortcut-btn", {selected: innerSelectedOption === option.value})}>
                    {option.label}
                </StyledButton>
            ))}
        </div>
    );
};
