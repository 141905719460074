import React from "react";
import {Tag, Radio, Button} from "antd";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import styled from "styled-components";
import * as _ from "lodash";
// import * as $ from "jquery";

const {CheckableTag} = Tag;

const StyledCheckableTag = styled(CheckableTag)`
    width: 70px;
    height: 30px;
    font-size: 11px !important;
    color: ${props => (props.checked ? "white" : "#2F3E83")};
    background-color: ${props => (props.checked ? "#2F3E83" : "#F4F5F9")};
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`;

const StyledButton = styled(Button)`
    border: 1px solid #e8edf5 !important;
    background-color: #fcfdfe;
    font-size: 16px;
    border-radius: 10px;
    padding-right: 11px;
    display: flex;
    color: rgba(0, 0, 0, 0.65) !important;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    width: 100%;
    outline: none;
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
`;

export const daysArray = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const dayKeys = daysArray.reduce((obj, day, idx) => {
    obj[day.toLowerCase()] = idx;
    return obj;
}, {});

export class Availability extends React.Component {
    state = {
        sunday: {},
        monday: {},
        tuesday: {},
        wednesday: {},
        thursday: {},
        friday: {},
        saturday: {},
        showMenu: false
    };

    closeMenuBinded = this.closeMenu.bind(this);

    closeMenu() {
        this.setState({showMenu: false});
    }

    componentDidMount() {
        $(window).on("click", this.closeMenuBinded);
        this.addDefaults();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.addDefaults();
    }

    addDefaults() {
        const {value} = this.props;
        if (value && !this.isEqual(this.state, value)) {
            this.setState(Object.assign({}, this.state, value));
        }
    }

    componentWillUnmount() {
        $(window).unbind("click", this.closeMenuBinded);
    }

    onSelect(dayKey, field, checked) {
        const {onChange} = this.props;
        const dayInfo = this.state[dayKey];
        if (field !== "selected" && !dayInfo.selected) {
            return;
        }

        if (
            field === "selected" &&
            !dayInfo.selected &&
            checked &&
            ["morning", "noon", "evening"].every(dayPart => typeof dayInfo[dayPart] === "undefined")
        ) {
            dayInfo.morning = dayInfo.noon = dayInfo.evening = true;
        } else if (field === "selected" && dayInfo.selected && !checked) {
            dayInfo.morning = dayInfo.noon = dayInfo.evening = void 0;
        }

        dayInfo[field] = checked;
        this.setState({[dayKey]: dayInfo});
        const vals = _.cloneDeep(this.state);
        delete vals.showMenu;
        onChange(vals);
    }

    availabilityDays() {
        return daysArray.reduce((arr, day) => {
            const dayKey = day.toLowerCase();
            if (this.state[dayKey].selected) {
                arr.push(day);
            }
            return arr;
        }, []);
    }

    availabilityText() {
        const days = this.availabilityDays();
        return days.length > 0 ? (
            <span style={{color: "rgba(0, 0, 0, 0.65)", overflow: "hidden"}}>{days.join(", ")}</span>
        ) : (
            <span style={{color: "rgba(0, 0, 0, 0.3)"}}>Choose Availability</span>
        );
    }

    openMenu(e) {
        e.stopPropagation();
        this.setState({showMenu: !this.state.showMenu});
    }

    isEqual(currentAvailability, newAvailability) {
        return ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"].every(day => {
            const currentDay = currentAvailability[day];
            const newDay = newAvailability[day];
            if (newDay && newDay.selected && !_.isEqual(newDay, currentDay)) {
                return false;
            }

            return true;
        });
    }

    render() {
        const radioStyle = {
            display: "flex"
        };

        const menu = (
            <div
                className="provider-availability"
                onClick={evt => evt.stopPropagation()}
                style={{
                    zIndex: 1,
                    width: 360,
                    padding: 7,
                    backgroundColor: "white",
                    borderRadius: 20,
                    boxShadow: "0 10px 40px 0 rgba(76,88,105,0.1)"
                }}>
                <table>
                    <tbody>
                        {daysArray.map(day => {
                            const dayKey = day.toLowerCase();
                            return (
                                <tr key={dayKey} style={{height: 40}}>
                                    <td>
                                        <Radio
                                            onClick={() =>
                                                this.onSelect(dayKey, "selected", !this.state[dayKey].selected)
                                            }
                                            style={radioStyle}
                                            checked={this.state[dayKey].selected}
                                        />
                                    </td>
                                    <td
                                        onClick={() => this.onSelect(dayKey, "selected", !this.state[dayKey].selected)}
                                        style={{width: 100, fontSize: 16, color: "#637381", textAlign: "left"}}>
                                        {day}
                                    </td>
                                    <td>
                                        <StyledCheckableTag
                                            disabled={!this.state[dayKey].selected}
                                            onChange={checked => this.onSelect(dayKey, "morning", checked)}
                                            checked={this.state[dayKey].morning}>
                                            Morning
                                        </StyledCheckableTag>
                                    </td>
                                    <td>
                                        <StyledCheckableTag
                                            disabled={!this.state[dayKey].selected}
                                            onChange={checked => this.onSelect(dayKey, "noon", checked)}
                                            checked={this.state[dayKey].noon}>
                                            Noon
                                        </StyledCheckableTag>
                                    </td>
                                    <td>
                                        <StyledCheckableTag
                                            disabled={!this.state[dayKey].selected}
                                            onChange={checked => this.onSelect(dayKey, "evening", checked)}
                                            checked={this.state[dayKey].evening}>
                                            Evening
                                        </StyledCheckableTag>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );

        return (
            <div style={{maxWidth: "calc(100vw - 20px)"}}>
                <StyledButton className="wb-availability-btn" onClick={e => this.openMenu(e)}>
                    {this.availabilityText()}
                    {this.state.showMenu ? (
                        <UpOutlined style={{fontSize: 12, paddingTop: 4, color: "rgba(0, 0, 0, 0.3)"}} />
                    ) : (
                        <DownOutlined style={{fontSize: 12, paddingTop: 5, color: "rgba(0, 0, 0, 0.3)"}} />
                    )}
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            position: "absolute",
                            left: 0,
                            top: 40,
                            padding: 10
                        }}>
                        {this.state.showMenu ? menu : null}
                    </div>
                </StyledButton>
            </div>
        );
    }
}
