import React, {useContext, useState, useEffect, useReducer} from "react";
import {EventInfoTitle} from "../event/EventInfoTitle";
import {AppContext} from "../AppContext";
import {Button, Collapse, Divider, Spin} from "antd";
import {RightOutlined, PlusOutlined} from "@ant-design/icons";
import {HttpClient} from "../http/HttpClient";
import {TrashIcon} from "../icons";
import {withRouter} from "react-router";
import isMobileDevice from "is-mobile";
import {EventBus} from "../bus/EventBus";
import {CollapseContent, CollapseHeader} from "../components/CustomCollapse";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {eventByDate} from "../event/EventByDate";
import {PageSubTitleLabel} from "../components/PageTitle";
import Moment from "moment-timezone";
// import {extendMoment} from "moment-range";
import {PageLoader} from "../components/PageLoader";

const moment = null; //extendMoment(Moment);

const {Panel} = Collapse;

const AppointmentHeader = ({appointment: {productName, start, end}}) => (
    <CollapseHeader backgroundColor="#1A344A" name={productName} start={start} end={end} />
);

const AppointmentContent = ({appointment, deleting, onDelete, onEdit}) => {
    const {me} = useContext(AppContext);

    const dateMom = moment(`${appointment.date} ${appointment.start}`, "YYYY-MM-DD HH:mm");
    const now = moment(Date.now());
    const editEnabled = now.isSameOrBefore(dateMom);
    return (
        <CollapseContent
            providerName={`${appointment.employeeFirstName} ${appointment.employeeLastName}`}
            providerPhone={appointment.employeePhone}
            email={appointment.employeeEmail}
            price={appointment.price}
            address={appointment.address}
            date={dateMom.format("MMMM Do, YYYY")}
            start={appointment.start}
            end={appointment.end}
            transactionTerminal={me.transactionTerminal}
            confirmationCode={appointment.confirmationCode}
            onEdit={editEnabled ? () => onEdit(appointment) : null}
            onDelete={editEnabled ? () => onDelete(appointment) : null}
            deleting={deleting}
            deletePopupMessage={
                <span>
                    Delete this appointment?
                    {appointment.confirmationCode ? (
                        <>
                            <br />
                            <strong>
                                If this appointment was pre-paid please contact {appointment.employeeFirstName} to
                                handle a refund first.
                            </strong>
                        </>
                    ) : null}
                </span>
            }
            deleteIcon={<TrashIcon />}
            deleteText="Delete"
        />
    );
};

const AppointmentFiller = ({filler, index, onNew}) => (
    <Panel
        key={`appt-filler-${index}`}
        className="wb-appointment-filler"
        showArrow={false}
        header={
            <div
                onClick={() => onNew(filler.start, filler.end)}
                style={{
                    width: "100%",
                    height: 36,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    fontSize: 16,
                    color: "#13C296"
                }}>
                <span style={{fontWeight: 700}}>Open for booking</span>
                <div style={{display: "flex", alignItems: "center"}}>
                    <Divider type="vertical" style={{backgroundColor: "#13C296", width: 2, height: 15}} />
                    <span style={{width: 102}}>
                        {filler.start} - {filler.end}
                    </span>
                    <Button
                        style={{
                            border: "none",
                            padding: "none",
                            width: 30,
                            height: 30,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#13C296",
                            backgroundColor: "transparent"
                        }}
                        icon={<PlusOutlined style={{fontSize: 18, color: "#13C296", paddingTop: 2}} />}
                    />
                </div>
            </div>
        }
    />
);

const ProviderEventAppointments = ({match, eventInfo, onNew, onEdit}) => {
    const {me} = useContext(AppContext);
    const [deletingAppointmentId, setDeletingAppointmentId] = useState(null);
    const {eventId, start, end} = match.params;
    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        today: null,
        minTime: null,
        maxTime: null,
        event: null,
        appointments: null
    });

    let {minTime, maxTime, appointments, event} = state;

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                let event = eventInfo || (await HttpClient.get(`/api/events/${eventId}`));

                event = eventByDate(event, start);

                const today = moment(start, "YYYY-M-D").format("YYYY-MM-DD");
                const minTime = event.minTime;
                const maxTime = event.maxTime;

                let appointments = await HttpClient.get(`/api/events/${eventId}/appointments/dates/${today}`);
                appointments = appointments.sort((a1, a2) =>
                    moment(`${a1.date} ${a1.start}`, "YYYY-MM-DD HH:mm").isAfter(
                        moment(`${a2.date} ${a2.start}`, "YYYY-MM-DD HH:mm")
                    )
                        ? 1
                        : -1
                );

                setState({
                    event,
                    appointments: addBreak(
                        insertFillers(appointments, minTime, maxTime),
                        Array.isArray(event.breakSlots) && event.breakSlots.length > 0 ? event.breakSlots[0] : null
                    ),
                    today,
                    minTime,
                    maxTime
                });
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we couldn't fetch your appointments :("}},
                    e.message
                );
            }
        });
    }, []);

    const insertFillers = (appts, minTime, maxTime) => {
        if (!Array.isArray(appts) || appts.length === 0) {
            return [appointmentFiller(start, minTime, maxTime)];
        }

        const apptWithFillers = [];
        const diffFromMinTime = timeDiff(minTime, appts[0].start);
        if (diffFromMinTime > 0) {
            apptWithFillers.push(appointmentFiller(start, minTime, appts[0].start));
        }

        for (let idx = 0; idx < appts.length - 1; idx++) {
            const appt1 = appts[idx];
            const appt2 = appts[idx + 1];
            apptWithFillers.push(appt1);
            if (appt1.end !== appt2.start) {
                apptWithFillers.push(appointmentFiller(start, appt1.end, appt2.start));
            }
        }
        apptWithFillers.push(appts[appts.length - 1]);

        const diffFromMaxTime = timeDiff(appts[appts.length - 1].end, maxTime);
        if (diffFromMaxTime > 0) {
            apptWithFillers.push(appointmentFiller(start, appts[appts.length - 1].end, maxTime));
        }

        return apptWithFillers;
    };

    const addBreak = (appointments, breakSlot) => {
        if (!breakSlot) {
            return appointments;
        }

        return appointments.reduce(
            (newFillers, appt) => {
                if (!appt.filler || appt.isBreak) {
                    return newFillers.concat([appt]);
                }

                const startMom = moment(appt.start, "HH:mm");
                const endMom = moment(appt.end, "HH:mm");
                const fillerRangeMom = moment.range(startMom, endMom);
                const breakStartMom = moment(breakSlot.start, "HH:mm");
                const breakEndMom = moment(breakSlot.end, "HH:mm");
                const breakRangeMom = moment.range(breakStartMom, breakEndMom);
                if (
                    breakRangeMom.contains(startMom.add(1, "seconds")) &&
                    breakRangeMom.contains(endMom.subtract(1, "seconds"))
                ) {
                    return newFillers;
                } else if (breakRangeMom.contains(startMom, {excludeStart: false, excludeEnd: false})) {
                    return newFillers.concat([appointmentFiller(start, breakSlot.end, appt.end)]);
                } else if (breakRangeMom.contains(endMom, {excludeStart: false, excludeEnd: false})) {
                    return newFillers.concat([appointmentFiller(start, appt.start, breakSlot.start)]);
                } else if (fillerRangeMom.contains(breakStartMom, {excludeStart: false, excludeEnd: false})) {
                    return newFillers.concat([
                        appointmentFiller(start, appt.start, breakSlot.start),
                        appointmentFiller(start, breakSlot.end, appt.end)
                    ]);
                }

                return newFillers.concat([appt]);
            },
            [appointmentFiller(start, breakSlot.start, breakSlot.end, true)]
        );
    };

    const appointmentFiller = (date, start, end, isBreak) => {
        return {
            filler: true,
            isBreak: !!isBreak,
            date: moment(date, "YYYY-M-D").format("YYYY-MM-DD"),
            start,
            end
        };
    };

    const timeDiff = (time1, time2) => {
        return moment(time2, "HH:mm").diff(moment(time1, "HH:mm"), "minutes");
    };

    const deleteAppointment = async appointment => {
        const {appointmentId} = appointment;
        setDeletingAppointmentId(appointmentId);
        try {
            await HttpClient.delete(`/api/events/${eventId}/appointments/${appointmentId}`);
            GoogleAnalytics.event("Professional Booking", "Delete Appointment", me.userId);
            setState({
                appointments: addBreak(
                    insertFillers(
                        appointments.filter(appt => appt.appointmentId !== appointmentId && !appt.filler),
                        minTime,
                        maxTime
                    ),
                    Array.isArray(event.breakSlots) && event.breakSlots.length > 0 ? event.breakSlots[0] : null
                )
            });
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we couldn't delete this appointment :("}},
                e.message
            );
        }
        setDeletingAppointmentId(null);
    };

    if (!event) {
        return <PageLoader align="flex-start" top={50} bottom={50} height={200} />;
    }

    appointments = appointments.sort((a1, a2) =>
        moment(`${a1.date} ${a1.start}`, "YYYY-MM-DD HH:mm").isAfter(
            moment(`${a2.date} ${a2.start}`, "YYYY-MM-DD HH:mm")
        )
            ? 1
            : -1
    );

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                maxWidth: 600
            }}>
            <EventInfoTitle
                me={me}
                event={event}
                currentStart={start}
                currentEnd={end}
                titleStyle={{marginTop: 0}}
                subTitleStyle={{marginBottom: 5}}
                showBack={!isMobileDevice()}
            />
            {minTime === maxTime ? (
                <div style={{display: "flex", justifyContent: "center", marginTop: 80}}>
                    <PageSubTitleLabel>No available spots.</PageSubTitleLabel>
                </div>
            ) : appointments === null ? (
                <PageLoader align="flex-start" top={50} bottom={50} />
            ) : Array.isArray(appointments) && appointments.length > 0 ? (
                <Collapse
                    className="wb-appointments"
                    expandIconPosition="right"
                    expandIcon={({isActive}) => (
                        <RightOutlined style={{fontSize: 16, color: "white"}} rotate={isActive ? 90 : 0} />
                    )}
                    style={{borderBottom: "none", width: "100%", marginTop: 5}}>
                    {appointments
                        .filter(appt => !appt.isBreak)
                        .map((appointment, idx) =>
                            appointment.filler ? (
                                <AppointmentFiller
                                    key={idx}
                                    filler={appointment}
                                    index={idx}
                                    onNew={() =>
                                        onNew(eventId, start, end, {
                                            startTime: appointment.start,
                                            endTime: appointment.end
                                        })
                                    }
                                />
                            ) : (
                                <Panel
                                    key={appointment.appointmentId}
                                    className="wb-appointment"
                                    showArrow={true}
                                    header={<AppointmentHeader appointment={appointment} />}>
                                    <AppointmentContent
                                        appointment={appointment}
                                        deleting={deletingAppointmentId === appointment.appointmentId}
                                        onDelete={appointmentId => deleteAppointment(appointmentId)}
                                        onEdit={appointment => onEdit(appointment, start, end)}
                                    />
                                </Panel>
                            )
                        )}
                </Collapse>
            ) : (
                <span style={{marginTop: 60, color: "#8492A6", fontSize: 16}}>No appointments.</span>
            )}
        </div>
    );
};

export default withRouter(ProviderEventAppointments);
