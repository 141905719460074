import React from "react";
import PageTitleHeader, {PageSubTitleLabel} from "../../components/PageTitle";
import {Timeline} from "antd";

export const CompanyWeeklyBestPractices = () => {
    return (
        <div style={{width: "100%", paddingLeft: 20, paddingRight: 20, display: "flex", justifyContent: "center"}}>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    maxWidth: 900,
                    marginTop: 30,
                    marginBottom: 30
                }}>
                <PageTitleHeader>Best practices for your weekly emails</PageTitleHeader>
                <PageSubTitleLabel style={{marginBottom: 40}}>
                    Take control over your weekly email's content
                </PageSubTitleLabel>
                <Timeline style={{maxWidth: 600}}>
                    <Timeline.Item color="gray">
                        <span style={{fontSize: 16}}>
                            <b>Avoid words or sentences that might sound inappropriate</b>. Inappropriate words will
                            result in your email being marked as spam (e.g "Someone will do a free massage therapy for
                            you", etc...).
                        </span>
                    </Timeline.Item>
                    <Timeline.Item color="gray">
                        <span style={{fontSize: 16}}>
                            <b>Avoid words that might express excitement</b>, excitement might be interpreted as
                            marketing material (e.g. "Once in a life chance!", "Join us to get the best experience
                            ever!", etc...).
                        </span>
                    </Timeline.Item>
                    <Timeline.Item color="gray">
                        <span style={{fontSize: 16}}>
                            <b>Avoid marketing terms</b> such as: "Free", "Discount", "Sale", "Deals", "% Off".
                        </span>
                    </Timeline.Item>
                    <Timeline.Item color="gray">
                        <span style={{fontSize: 16}}>
                            <b>Do not include external links</b>, external links might be interpreted as marketing
                            material as well (e.g. Instgram / Facebook / Linkedin / YouTube links).
                        </span>
                    </Timeline.Item>
                </Timeline>
            </div>
        </div>
    );
};
