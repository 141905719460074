import React from "react";
import {Dropdown, Menu, Tooltip} from "antd";
import {EllipsisOutlined} from "@ant-design/icons";
import {InvoicesStatusToNameAndColor} from "./consts";
import {findInvoiceWithOrder} from "./utils";
import {TableDateCell} from "../../admin/components";
import {TableDoubleValueCell} from "../../components";
import {getDeliveryPriceForInvoice, getInvoicePrice, reduceVAT} from "../../utils/calculationUtils";
import {getCommission} from "../providerOrders/ProviderOrdersHelper";
import {isOrderHasShippingProvider} from "../../event/utils.jsx";

export const getColumns = ({providerProducts, invoices, isExemptDealerType, maxShippingProvidersDeliveryPrice}) => [
    {
        title: "#",
        key: "#",
        width: 50,
        render(_, __, idx) {
            return idx + 1;
        }
    },
    {
        title: "מספר הזמנה",
        className: "no-side-borders",
        dataIndex: "proposalId",
        render(_, {proposalId, eventId}) {
            return <span id={eventId}>{proposalId ?? eventId}</span>;
        }
    },
    {
        title: "שם מוצר",
        className: "no-side-borders",
        dataIndex: "productName"
    },
    {
        title: "תאריך אספקה",
        className: "no-side-borders",
        key: "deliveryTime",
        sorter: (r1, r2) =>
            r1.dtstart +
            (r1.deliveryArrivingTimeRange ?? 0) * 1000 * 60 * 60 -
            (r2.dtstart + (r2.deliveryArrivingTimeRange ?? 0) * 1000 * 60 * 60),
        render(_, {dtstart, deliveryArrivingTimeRange}) {
            return (
                <TableDateCell date={dtstart} endDate={dtstart + (deliveryArrivingTimeRange ?? 0) * 1000 * 60 * 60} />
            );
        }
    },
    {
        title: isExemptDealerType ? "סכום הזמנה לקבלה (לא כולל מע״מ)" : "סכום הזמנה לחשבונית (כולל מע״מ)",
        key: "price",
        sorter: (r1, r2) =>
            getInvoicePrice(
                r1.offeredPrice,
                r1.commission ?? getCommission(providerProducts, r1.productId),
                isExemptDealerType,
                r1.vat
            ) -
            getInvoicePrice(
                r2.offeredPrice,
                r2.commission ?? getCommission(providerProducts, r2.productId),
                isExemptDealerType,
                r2.vat
            ),
        render(_, {offeredPrice, commission, productId, vat}) {
            const orderCommission = commission ?? getCommission(providerProducts, productId);
            const invoicePrice = getInvoicePrice(offeredPrice, orderCommission, isExemptDealerType, vat);

            return (
                <TableDoubleValueCell className="provider-manage-invoices-invoice-price-cell">
                    {`חישוב עבור ${isExemptDealerType ? "קבלה" : "חשבונית"}: ${invoicePrice}₪`}
                    {`מחיר הזמנה מלא: ${offeredPrice}₪ | ${orderCommission}% עמלה ${
                        isExemptDealerType ? "" : "+ מע״מ"
                    }`}
                </TableDoubleValueCell>
            );
        }
    },
    {
        title: () => (
            <span>
                עלות משלוח שותף
                <br />
                {isExemptDealerType ? "(לא כולל מע״מ)" : "(כולל מע״מ)"}
            </span>
        ),
        dataIndex: "deliveryPrice",
        sorter: (r1, r2) => (r1.deliveryPrice ?? 0) - (r2.deliveryPrice ?? 0),
        render(deliveryPrice, order) {
            const calculatedDeliveryPrice = isExemptDealerType ? reduceVAT(deliveryPrice, order.vat) : deliveryPrice;

            if (isOrderHasShippingProvider(order)) {
                return `₪0`;
            }

            return `${(calculatedDeliveryPrice ?? 0).toLocaleString()} ₪`;
        }
    },
    {
        title: () => (
            <span>
                עלות משלוח עם well-b
                <br />
                {isExemptDealerType ? "(לא כולל מע״מ)" : "(כולל מע״מ)"}
            </span>
        ),
        dataIndex: "deliveryPrice",
        sorter: (r1, r2) => (r1.deliveryPrice ?? 0) - (r2.deliveryPrice ?? 0),
        render(deliveryPrice, order, index) {
            const calculatedDeliveryPrice = getDeliveryPriceForInvoice(
                order,
                order.deliveryCommission,
                isExemptDealerType,
                maxShippingProvidersDeliveryPrice
            );

            if (isOrderHasShippingProvider(order)) {
                return `${(calculatedDeliveryPrice ?? 0).toLocaleString()} ₪`;
            }

            return `₪0`;
        }
    },
    {
        title: () => (
            <span>
                זיכוי
                <br />
                {isExemptDealerType ? "(לא כולל מע״מ)" : "(כולל מע״מ)"}
            </span>
        ),
        dataIndex: "providerDiscount",
        sorter: (r1, r2) => (r1.providerDiscount ?? 0) - (r2.providerDiscount ?? 0),
        render(providerDiscount, {vat}) {
            const calculatedProviderDiscount = isExemptDealerType ? reduceVAT(providerDiscount, vat) : providerDiscount;
            return <div className="ltr">{`₪ ${(-1 * calculatedProviderDiscount || 0).toLocaleString()}`}</div>;
        }
    },
    {
        title: () => (
            <span>
                טיפ
                <br />
                {isExemptDealerType ? "(לא כולל מע״מ)" : "(כולל מע״מ)"}
            </span>
        ),
        dataIndex: "tip",
        sorter: (r1, r2) => (r1.tip ?? 0) - (r2.tip ?? 0),
        render(tip, {vat}) {
            const calculatedTip = isExemptDealerType ? reduceVAT(tip, vat) : tip;
            return <div>{`${(calculatedTip || 0).toLocaleString()} ₪`}</div>;
        }
    },
    {
        title: "סטטוס חשבונית",
        key: "invoiceStatus",
        render(_, {eventId}) {
            const orderInvoice = findInvoiceWithOrder(eventId, invoices);
            const statusColorAndName = InvoicesStatusToNameAndColor[orderInvoice?.status.id];
            return statusColorAndName ? (
                <Tooltip
                    title={
                        orderInvoice?.status.extraInfo?.declineMessage ? (
                            <div>
                                <span>סיבת הדחייה: </span>
                                <span>{orderInvoice.status.extraInfo?.declineMessage}</span>
                            </div>
                        ) : null
                    }>
                    <span style={{color: statusColorAndName.color}}>{statusColorAndName.name}</span>
                </Tooltip>
            ) : (
                ""
            );
        }
    },
    {
        key: "actions",
        render(_, order) {
            return (
                <Dropdown
                    overlay={
                        <Menu>
                            <Menu.Item onClick={() => window.open(`/events/${order.eventId}/proposalV2`, "_blank")}>
                                צפייה בהזמנה
                            </Menu.Item>
                        </Menu>
                    }
                    trigger={["click"]}>
                    <EllipsisOutlined className="provider-manage-invoices-action-button" />
                </Dropdown>
            );
        }
    }
];

export default getColumns;
