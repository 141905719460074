import {Form, Modal} from "antd";
import React from "react";
import {FormInput} from "../../components/form";
import {StyledButton} from "../../components/StyledButton";

export const AdminCategoriesAddSubCategory = ({visible, onClose, onAdd}) => {
    const [form] = Form.useForm();

    return (
        <Modal
            centered={true}
            width={340}
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            footer={null}
            title={null}
            closable={true}>
            <div style={{display: "flex", width: "100%", flexDirection: "column", alignItems: "center"}}>
                <Form
                    form={form}
                    onFinish={values => {
                        onAdd(values.subCategory?.trim());
                        form.resetFields();
                    }}
                    style={{maxWidth: 300, width: "100%"}}>
                    <Form.Item
                        name="subCategory"
                        style={{marginTop: 40}}
                        rules={[
                            {
                                required: true,
                                message: "Please enter sub-category name."
                            }
                        ]}>
                        <FormInput placeholder="Sub-Category name" />
                    </Form.Item>
                    <Form.Item>
                        <StyledButton
                            style={{
                                backgroundColor: "#ED7FA6",
                                color: "white",
                                width: "150px",
                                marginLeft: "65px",
                                borderRadius: "5px"
                            }}
                            htmlType="submit">
                            Add Sub-Category
                        </StyledButton>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};
