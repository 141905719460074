import React, {useEffect, useReducer, useState} from "react";
import {Form, Select, Button} from "antd";
import {CheckCircleFilled} from "@ant-design/icons";
import {EventInfoTitle} from "../event/EventInfoTitle";
import {SearchableSelect} from "../components/SearchableSelect";
import moment from "moment-timezone";
import {HttpClient} from "../http/HttpClient";
import {CustomSelect} from "../components/CustomSelect";
import {withRouter} from "react-router";
import isMobileDevice from "is-mobile";
import {EventBus} from "../bus/EventBus";
import {truncate as _truncate} from "lodash";
import {isSlotAvailableBetweenAppointments} from "../utils/AppointmentValidator";
import {eventByDate} from "../event/EventByDate";
import {StartEndTimePicker} from "../components/StartEndTimePicker";
import {PageLoader} from "../components/PageLoader";
import {RRuleSetBuilder} from "../event/RRuleSetBuilder";

const {Option} = CustomSelect;

const SERVICE_PLACEHOLDER = "Choose service";

const ProviderEventAppointment = ({
    match,
    me,
    eventInfo,
    appointmentToEdit,
    onSave,
    saving,
    canControlEndTime = true,
    fixedSaveButton = true,
    saveButtonColor = "#1F6FE5"
}) => {
    const {eventId, start, end, appointmentId, startTime, endTime} = match.params;
    const [dates, setDates] = useState(null);
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState(null);
    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        employees: null,
        products: null,
        appointments: null,
        event: null,
        appointment: null,
        today: null,
        rule: null,
        minTime: null,
        maxTime: null
    });

    const {employees, products, appointments, event, appointment, today, rule, minTime, maxTime} = state;

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                let evt = eventInfo || (await HttpClient.get(`/api/events/${eventId}`));
                evt = eventByDate(evt, start);

                const today = moment(start, "YYYY-M-D").format("YYYY-MM-DD");
                const minTime = evt.minTime;
                const maxTime = evt.maxTime;

                const rule = RRuleSetBuilder.build(evt);

                let appt = appointmentId
                    ? appointmentToEdit || (await HttpClient.get(`/api/appointments/${appointmentId}`))
                    : null;

                let employees, products, appointments;

                if (me.type === "employee") {
                    const [providerDetails, appts] = await Promise.all([
                        HttpClient.get(`/api/providers/${appt.providerId}?eventId=${evt.eventId}`),
                        fetchAppointmentsByDate(appt ? appt.date : today, true)
                    ]);

                    employees = [me];
                    products = providerDetails.services;
                    appointments = appts;
                } else {
                    const [emps, prods, appts] = await Promise.all([
                        HttpClient.get(`/api/companies/${evt.companyId}/events/${eventId}/employees`),
                        HttpClient.get("/api/providers/me/services"),
                        fetchAppointmentsByDate(appt ? appt.date : today, true)
                    ]);

                    employees = emps;
                    products = prods;
                    appointments = appts;
                }

                setState({
                    appointments,
                    employees,
                    products,
                    event: evt,
                    appointment: appt,
                    today,
                    rule,
                    minTime,
                    maxTime
                });
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we couldn't complete your request :("}},
                    e.message
                );
            }
        });
    }, []);

    const fetchAppointmentsByDate = async (date, throwError = false) => {
        try {
            return await HttpClient.get(`/api/events/${eventId}/appointments/dates/${date}`);
        } catch (e) {
            if (!throwError) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we couldn't complete your request :("}},
                    e.message
                );
            } else {
                throw e;
            }
        }
    };

    const toNiceDateFormat = date => {
        return moment(date, "YYYY-MM-DD").format("MMMM Do, YYYY");
    };

    const saveChanges = ({date, employeeId, productIdx, timeRange}) => {
        const product = products[productIdx];

        onSave({
            ...(appointment || {}),
            providerId: event.providerId,
            eventId: event.eventId,
            companyId: event.companyId,
            date: moment(date, "MMMM Do, YYYY").format("YYYY-MM-DD"),
            employeeId,
            start: timeRange.start.format("HH:mm"),
            end: timeRange.end.format("HH:mm"),
            productName: product.productName,
            price: product.price,
            durationMinutes: product.durationMinutes
        });
    };

    const isProductSelected = values => {
        return values && typeof values.productIdx !== "undefined" && values.productIdx !== SERVICE_PLACEHOLDER;
    };

    const hasStartTime = values => {
        return values && values.timeRange && values.timeRange.start;
    };

    const isStartTimeChanged = (currentValues, previousValues) => {
        return (
            hasStartTime(currentValues) &&
            (!hasStartTime(previousValues) ||
                currentValues.timeRange.start.format("HH:mm") !== previousValues.timeRange.start.format("HH:mm"))
        );
    };

    const isFieldChanged = (fieldName, values) => {
        return fieldName in values;
    };

    if (!dates && rule) {
        setDates(
            rule
                .between(moment(start, "YYYY-M-D").toDate(), moment(end, "YYYY-M-D").add(6, "months").toDate())
                .map(toNiceDateFormat)
        );
    }

    if (
        !event ||
        !Array.isArray(employees) ||
        !Array.isArray(products) ||
        !Array.isArray(appointments) ||
        !Array.isArray(dates)
    ) {
        return <PageLoader align="flex-start" top={50} bottom={50} />;
    }

    const initialValues = {
        date: appointment ? toNiceDateFormat(appointment.date) : toNiceDateFormat(today),
        employeeId: appointment ? appointment.employeeId : void 0,
        productIdx: appointment ? products.findIndex(p => p.productName === appointment.productName) : void 0,
        timeRange: appointment
            ? {
                  start: moment(appointment.start, "HH:mm"),
                  end: moment(appointment.end, "HH:mm")
              }
            : startTime && endTime
            ? {
                  start: moment(startTime, "HH:mm"),
                  end: moment(endTime, "HH:mm")
              }
            : {}
    };

    if (!formValues) {
        setFormValues(initialValues);
    }

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                maxWidth: 800
            }}>
            <EventInfoTitle
                me={me}
                event={event}
                currentStart={start}
                currentEnd={end}
                showBack={!isMobileDevice()}
                titleStyle={{marginTop: 0}}
            />
            <Form
                form={form}
                onFinish={values => saveChanges(values)}
                style={{marginBottom: 80, marginTop: 5, paddingLeft: 10, paddingRight: 10}}
                initialValues={initialValues}
                onValuesChange={(changedValues, allValues) => {
                    if (isFieldChanged("date", changedValues)) {
                        const {date} = changedValues;
                        setState({appointments: []});
                        Promise.resolve().then(async () => {
                            const appts = await fetchAppointmentsByDate(
                                moment(date, "MMMM Do, YYYY").format("YYYY-MM-DD")
                            );
                            setState({appointments: appts});
                        });
                    } else if (
                        isFieldChanged("timeRange", changedValues) &&
                        isStartTimeChanged(changedValues, formValues) &&
                        isProductSelected(allValues)
                    ) {
                        const timeRange = changedValues.timeRange;
                        const product = products[allValues.productIdx];
                        timeRange.end = moment(timeRange.start).add(parseInt(product.durationMinutes, 10), "minutes");
                        form.setFieldsValue({timeRange});
                        allValues.timeRange = timeRange;
                    } else if (
                        isFieldChanged("productIdx", changedValues) &&
                        isProductSelected(changedValues) &&
                        hasStartTime(formValues)
                    ) {
                        const timeRange = formValues.timeRange;
                        const product = products[changedValues.productIdx];
                        timeRange.endTime = moment(timeRange.start).add(
                            parseInt(product.durationMinutes, 10),
                            "minutes"
                        );
                        form.setFieldsValue({timeRange});
                        allValues.timeRange = timeRange;
                    }

                    setFormValues(allValues);
                }}>
                <Form.Item
                    name="date"
                    rules={[
                        {
                            required: true,
                            message: "Please select date"
                        }
                    ]}>
                    <CustomSelect>
                        {dates.map(date => (
                            <Option key={date} value={date}>
                                {date}
                            </Option>
                        ))}
                    </CustomSelect>
                </Form.Item>
                <Form.Item
                    name="employeeId"
                    rules={[
                        {
                            required: true,
                            message: "Please select a client"
                        }
                    ]}>
                    <SearchableSelect placeholder="Client name">
                        {employees.map(emp => (
                            <Select.Option
                                key={emp.userId}
                                value={emp.userId}>{`${emp.firstName} ${emp.lastName}`}</Select.Option>
                        ))}
                    </SearchableSelect>
                </Form.Item>
                <Form.Item
                    name="productIdx"
                    rules={[
                        {
                            validator: (_, value) => {
                                return typeof value !== "undefined" && value !== SERVICE_PLACEHOLDER
                                    ? Promise.resolve()
                                    : Promise.reject("Please select a service");
                            }
                        }
                    ]}>
                    <CustomSelect placeholder={SERVICE_PLACEHOLDER}>
                        <Option key={`prod-choose`} value={SERVICE_PLACEHOLDER}>
                            {SERVICE_PLACEHOLDER}
                        </Option>
                        {products.map((product, idx) => (
                            <Option key={`prod-${idx}`} value={idx}>
                                {_truncate(product.productName, {
                                    length: 40,
                                    separator: " "
                                })}
                            </Option>
                        ))}
                    </CustomSelect>
                </Form.Item>
                <Form.Item
                    name="timeRange"
                    rules={[
                        {
                            validator: (_, timeRange) => {
                                if (!timeRange || !timeRange.start || !timeRange.end) {
                                    return Promise.reject("Please select time");
                                }

                                const {start, end} = timeRange;

                                if (!isSlotAvailableBetweenAppointments(appointments, start, end, appointmentId)) {
                                    return Promise.reject("This time is already booked.");
                                } else if (end.diff(start, "minutes") < 0) {
                                    return Promise.reject("Start time is bigger than finish time.");
                                } else if (end.diff(moment(maxTime, "HH:mm"), "minutes") > 0) {
                                    return Promise.reject(`Maximum finish time is ${maxTime}`);
                                } else if (start.diff(moment(minTime, "HH:mm"), "minutes") < 0) {
                                    return Promise.reject(`Minimum start time is ${minTime}`);
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        }
                    ]}>
                    <StartEndTimePicker canControlEndTime={canControlEndTime} />
                </Form.Item>
                {fixedSaveButton ? (
                    <Form.Item noStyle>
                        <Button
                            loading={saving}
                            htmlType="submit"
                            style={{
                                position: "fixed",
                                bottom: 0,
                                left: 0,
                                width: "100%",
                                height: 60,
                                backgroundColor: saveButtonColor,
                                color: "white",
                                borderRadius: 0,
                                border: "none",
                                textAlign: "center",
                                fontSize: 16,
                                zIndex: 9999
                            }}
                            icon={<CheckCircleFilled style={{fontSize: 18}} />}>
                            {appointment ? "Save changes" : "Book now"}
                        </Button>
                    </Form.Item>
                ) : (
                    <Form.Item noStyle>
                        <Button
                            loading={saving}
                            htmlType="submit"
                            style={{
                                width: "100%",
                                height: 40,
                                borderRadius: 20,
                                backgroundColor: saveButtonColor,
                                color: "white",
                                border: "none",
                                textAlign: "center",
                                fontSize: 16
                            }}
                            icon={<CheckCircleFilled style={{fontSize: 18, paddingTop: 4}} />}>
                            {appointment ? "Save changes" : "Book now"}
                        </Button>
                    </Form.Item>
                )}
            </Form>
        </div>
    );
};

export default withRouter(ProviderEventAppointment);
