import {SearchItemType} from "../consts.js";

export const DisplayByOptions = (onSortSelected) => {
    return {
        items: [
            {
                key: SearchItemType.products,
                label: "מוצרים",
                onClick: () => onSortSelected(SearchItemType.products)
            },
            {
                key: SearchItemType.providers,
                label: "שותפים",
                onClick: () => onSortSelected(SearchItemType.providers),
            },
            {
                key: SearchItemType.pinterest,
                label: "תמונות השראה - בקרוב",
                onClick: () => onSortSelected(SearchItemType.pinterest),
                disabled: true
            }
        ]
    }
}
