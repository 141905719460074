import {useState} from "react";
import moment from "moment-timezone";
import {dateFormat} from "../utils/DateFormat";
import {EventAcceptedIcon, PencilIcon, TrashIcon, WhatsappIcon} from "../icons";
import {CheckOutlined} from "@ant-design/icons";
import {ContactPhone} from "../support/ContactPhone";
import React from "react";
import {EventInfoActionButton} from "./info/EventInfoFooterV2";

export const EventInfoFooter = ({
    me,
    event,
    phoneNumber,
    registered,
    registering,
    onRegister,
    onUnregister,
    cancelling,
    onCancel,
    onBookAppointment,
    onRegistrationView,
    onEdit,
    saving,
    currentStart
}) => {
    const [trashHover, setTrashHover] = useState(false);
    const [editHover, setEditHover] = useState(false);

    let LeftButton = null;
    let RightButton = null;

    const {appointmentBased} = event;

    const SaveButton = () => (
        <EventInfoActionButton htmlType="submit" loading={saving}>
            Save
        </EventInfoActionButton>
    );

    const ViewRegistrationsButton = () => (
        <EventInfoActionButton
            textVisibleOnDesktopOnly={true}
            icon={<EventAcceptedIcon style={{fill: "var(--secondary-color)", width: 20, height: 20}} />}
            onClick={onRegistrationView}>
            Registrations
        </EventInfoActionButton>
    );

    switch (me.type) {
        case "company":
            const today = moment(dateFormat(currentStart), "YYYY-M-D").format("YYYY-MM-DD");
            const now = moment(Date.now()).format("YYYY-MM-DD");
            const past = moment(today, "YYYY-MM-DD").isBefore(moment(now, "YYYY-MM-DD"));

            if (!past) {
                LeftButton = () => (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            width: "100%",
                            paddingLeft: 5
                        }}>
                        <EventInfoActionButton
                            onMouseEnter={() => setTrashHover(true)}
                            onMouseLeave={() => setTrashHover(false)}
                            loading={cancelling}
                            onClick={async () => await onCancel()}
                            icon={<TrashIcon style={{fill: trashHover ? "#F06060" : "#767F90"}} />}
                        />
                        <EventInfoActionButton
                            onMouseEnter={() => setEditHover(true)}
                            onMouseLeave={() => setEditHover(false)}
                            loading={cancelling}
                            onClick={async () => await onEdit()}
                            icon={
                                <PencilIcon style={{fill: editHover ? "#F7B348" : "#767F90", width: 24, height: 24}} />
                            }
                        />
                    </div>
                );
            }

            RightButton = ViewRegistrationsButton;
            break;
        case "employee":
            if (appointmentBased) {
                if (onBookAppointment) {
                    LeftButton = () => (
                        <EventInfoActionButton onClick={() => onBookAppointment()}>
                            Book Appointment
                        </EventInfoActionButton>
                    );
                }
            } else {
                LeftButton = () => (
                    <EventInfoActionButton
                        loading={registering}
                        onClick={() => {
                            if (registered) {
                                onUnregister(event);
                            } else {
                                onRegister(event);
                            }
                        }}>
                        {registered ? <CheckOutlined style={{color: "white"}} /> : null}
                        {registered ? "Registered" : "Register"}
                    </EventInfoActionButton>
                );
            }
            break;
        case "provider":
            const eventDate = moment(currentStart);
            const isEditable = eventDate.isSameOrAfter(moment());
            LeftButton = () => (
                <>
                    <EventInfoActionButton
                        buttonStyle={{marginLeft: 0}}
                        textVisibleOnDesktopOnly={true}
                        onClick={() => ContactPhone.openWhatsApp(phoneNumber)}
                        icon={<WhatsappIcon style={{fill: "var(--secondary-color)", width: 20, height: 20}} />}>
                        Contact company
                    </EventInfoActionButton>
                    <ViewRegistrationsButton />
                </>
            );
            if (isEditable) {
                RightButton = () => <SaveButton />;
            }
            break;
    }

    return (
        <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div
                onClick={e => e.stopPropagation()}
                style={{
                    width: "100%",
                    maxWidth: 400,
                    display: "flex",
                    justifyContent: me.type === "company" && LeftButton ? "space-between" : "center",
                    alignItems: "center",
                    marginTop: 20,
                    marginBottom: 10
                }}>
                {LeftButton ? <LeftButton /> : null}
                {RightButton ? <RightButton /> : null}
            </div>
        </div>
    );
};
