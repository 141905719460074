import {FormInput} from "../../components/form";
import React, {useState} from "react";
import {Dropdown, Menu} from "antd";
import {DownOutlined} from "@ant-design/icons";

export const StepperDropdownInput = ({
    value,
    onChange,
    className,
    placeholder,
    style,
    items,
    arrowStyle,
    disabled,
    status = ""
}) => {
    const overlay = (
        <Menu
            className="wb-app-menu"
            style={{border: "1px solid #E8EDF5"}}
            onClick={({key}) => {
                onChange(key);
            }}
            items={items}
        />
    );

    return (
        <Dropdown trigger={["click"]} placement="bottomLeft" overlay={overlay}>
            <div style={{display: "flex", width: style && style.width ? style.width : "415px"}}>
                <FormInput
                    placeholder={placeholder}
                    className={className}
                    onChange={onChange}
                    disabled={disabled}
                    value={value}
                    status={status}
                    style={{
                        color: "var(--secondary-color)",
                        height: 50,
                        borderRadius: 5,
                        backgroundColor: "#FCFDFE",
                        ...(status ? {} : {border: value ? "1px solid var(--secondary-color)" : "1px solid #E8EDF5"}),
                        width: "100%",
                        ...style
                    }}
                />
                <div style={{position: "relative", top: "15px", right: "30px", ...arrowStyle}}>
                    <DownOutlined style={{color: "rgba(131,152,166,0.5)"}} />
                </div>
            </div>
        </Dropdown>
    );
};
