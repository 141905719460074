import React, {useMemo} from "react";
import ReactConfetti from "react-confetti";
import {AuthPagesImage} from "../../images/Images.jsx";
import {SideBySideLayout} from "../../auth/SideBySideLayout/index.js";
import "./register-success.css";
import {getThemeByOrigin} from "../../utils.js";

export const RegisterSuccess = () => {
    const registerSuccessPageImage = useMemo(() => {
        const themeByOrigin = getThemeByOrigin(window.location.origin);
        return AuthPagesImage?.[themeByOrigin] || AuthPagesImage.image;
    }, []);

    return (
        <>
            <ReactConfetti
                gravity={0.1}
                initialVelocityX={2}
                initialVelocityY={2}
                numberOfPieces={200}
                opacity={1}
                recycle
                run
                wind={0}
            />

            <SideBySideLayout srcImage={registerSuccessPageImage}>
                <div className="company-register-container-page">
                    <div className="register-company-container">
                        <span className="register-company-container-title">איזה כיף שאתם פה! 🎉</span>

                        <span className="register-company-sub-title">
                            שלחנו לכם מייל שמאפשר להפעיל את החשבון בלחיצת כפתור :)
                        </span>
                    </div>
                </div>
            </SideBySideLayout>
        </>
    );
};
