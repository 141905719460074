import {WBCheckbox} from "../../../components/WBCheckbox";
import {FormInput, FormTextArea} from "../../../components/form";
import React, {useEffect, useState} from "react";
import {HttpClient} from "../../../http/HttpClient";
import {Dropdown, Select, Menu, Tooltip} from "antd";
import {locationKeyToTextMap, LocationLabels} from "../../../data/locations";
import {DownOutlined, PlusOutlined} from "@ant-design/icons";
import {StyledButton} from "../../../components/StyledButton";
import {EventBus} from "../../../bus/EventBus";
import {AutoDirectionProviderWithValueChange} from "../../../AutoDirectionProvider";

const {Option} = Select;

export const ContentEditLabel = ({children, style}) => {
    return <span style={{fontSize: 16, color: "var(--secondary-color)", ...style}}>{children}</span>;
};

const AutoDirectionFormInput = props => {
    return (
        <AutoDirectionProviderWithValueChange value={props.value} onChange={props.onChange} style={{width: "100%"}}>
            {({onChange, value}) => <FormInput {...props} onChange={onChange} value={value} />}
        </AutoDirectionProviderWithValueChange>
    );
};

const AutoDirectionFormTextArea = props => {
    return (
        <AutoDirectionProviderWithValueChange value={props.value} onChange={props.onChange} style={{width: "100%"}}>
            {({onChange, value}) => <FormTextArea {...props} onChange={onChange} value={value} />}
        </AutoDirectionProviderWithValueChange>
    );
};

export const ContentEditInputItem = ({item, onChange, label, type = "text", mustInclude = false, maxLength}) => {
    const {enabled, value} = item || {
        enabled: mustInclude
    };

    const doChange = (en, val) => {
        onChange({enabled: en, value: val});
    };

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", justifyContent: "space-between", marginBottom: 10}}>
                <ContentEditLabel>{label}</ContentEditLabel>
                {!mustInclude ? (
                    <WBCheckbox
                        onChange={({target}) => doChange(target.checked, value)}
                        checked={enabled}
                        style={{marginRight: 10}}
                    />
                ) : null}
            </div>
            <div style={{display: "flex"}} onClick={() => doChange(true, value)}>
                <AutoDirectionFormInput
                    style={{pointerEvents: enabled ? "auto" : "none"}}
                    disabled={!enabled}
                    type={type}
                    placeholder={label}
                    value={value}
                    maxLength={maxLength}
                    onChange={({target}) => doChange(enabled, target.value)}
                />
            </div>
        </div>
    );
};

export const ContentEditDescriptionItem = ({item, onChange, label}) => {
    const {enabled, value} = item || {
        enabled: false
    };

    const doChange = (en, val) => {
        onChange({enabled: en, value: val});
    };

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", justifyContent: "space-between", marginBottom: 10}}>
                <ContentEditLabel>{label}</ContentEditLabel>
                <WBCheckbox
                    onChange={({target}) => doChange(target.checked, value)}
                    checked={enabled}
                    style={{marginRight: 10}}
                />
            </div>
            <div style={{display: "flex"}} onClick={() => doChange(true, value)}>
                <AutoDirectionFormTextArea
                    style={{pointerEvents: enabled ? "auto" : "none"}}
                    autoSize={{minRows: 5, maxRows: 5}}
                    disabled={!enabled}
                    placeholder={label}
                    value={value}
                    onChange={({target}) => doChange(enabled, target.value)}
                />
            </div>
        </div>
    );
};

const mapTagNameToId = (tags, tagName) => {
    const tag = tags.find(t => t.name === tagName);
    return tag ? tag.tagId : null;
};

const mapTagIdToTagObject = (tags, tagId) => {
    return tags.find(t => t.tagId === tagId);
};

export const ContentEditTagsItem = ({item, sectionId, onChange, onNewCategory}) => {
    const [allTags, setAllTags] = useState(null);

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const tgs = await HttpClient.get(`/api/tags/${sectionId}`);
                setAllTags(tgs);
            } catch (e) {
                message.error("Something went wrong, please try again later.", 5);
            }
        });

        EventBus.on(`tags_update:${sectionId}`, tags => {
            setAllTags(tags);
        });
    }, []);

    let {enabled, value} = item || {
        enabled: true
    };

    const doChange = (en, tagId) => {
        const elemToAdd = [mapTagIdToTagObject(allTags, tagId)];
        onChange({enabled: en, value: Array.isArray(value) ? value.concat(elemToAdd) : elemToAdd});
    };

    const doFilterChange = (en, tagId) => {
        onChange({enabled: en, value: value.filter(t => t.tagId !== tagId)});
    };

    if (allTags === null) {
        return null;
    }

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", justifyContent: "space-between", marginBottom: 10}}>
                <ContentEditLabel>Categories</ContentEditLabel>
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
                <Select
                    mode="multiple"
                    disabled={!enabled}
                    placeholder="Categories"
                    value={
                        Array.isArray(value) && value.length > 0
                            ? value.map(({name}) => mapTagNameToId(allTags, name))
                            : void 0
                    }
                    onSelect={val => doChange(enabled, val)}
                    onDeselect={val => doFilterChange(enabled, val)}>
                    {allTags.map(tagInfo => (
                        <Option key={tagInfo.name} value={tagInfo.tagId}>
                            {tagInfo.name}
                        </Option>
                    ))}
                </Select>
                {onNewCategory ? (
                    <Tooltip title="Add new category">
                        <StyledButton
                            onClick={onNewCategory}
                            style={{
                                marginLeft: 20,
                                borderRadius: 10,
                                backgroundColor: "#BFC9D1",
                                height: 48,
                                width: 48,
                                boxShadow: "none",
                                border: "none",
                                display: "flex",
                                flexAlign: "center",
                                justifyContent: "center"
                            }}>
                            <PlusOutlined style={{fontSize: 20, color: "#6B7E8D", paddingTop: 3}} />
                        </StyledButton>
                    </Tooltip>
                ) : null}
            </div>
        </div>
    );
};

export const ContentEditLocationDropdownItem = ({item, onChange}) => {
    const {enabled, value, extraValue} = item || {
        enabled: false,
        value: LocationLabels.ONLINE
    };

    const doChange = (en, val, lnk) => {
        onChange({enabled: en, value: val, extraValue: lnk});
    };

    const menu = (
        <Menu selectable={true} selectedKeys={[value]} onSelect={({key}) => doChange(enabled, key, extraValue)}>
            <Menu.Item key={LocationLabels.ONLINE}>Online session</Menu.Item>
            <Menu.Item key={LocationLabels.ONSITE}>At the office</Menu.Item>
            <Menu.Item key={LocationLabels.OFFSITE}>Offsite</Menu.Item>
        </Menu>
    );

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", justifyContent: "space-between", marginBottom: 20}}>
                <Dropdown onClick={() => doChange(true, value, extraValue)} overlay={menu}>
                    <div style={{fontSize: 16, color: "var(--secondary-color)"}}>
                        {locationKeyToTextMap[value]} <DownOutlined style={{color: "var(--secondary-color)"}} />
                    </div>
                </Dropdown>
                <WBCheckbox
                    onChange={({target}) => doChange(target.checked, value, extraValue)}
                    checked={enabled}
                    style={{marginRight: 10}}
                />
            </div>
            <div style={{display: "flex"}} onClick={() => doChange(true, value, extraValue)}>
                <AutoDirectionFormInput
                    style={{pointerEvents: enabled ? "auto" : "none"}}
                    disabled={!enabled}
                    placeholder={value === LocationLabels.ONLINE ? "Insert link here" : "Enter address"}
                    value={extraValue}
                    onChange={({target}) => doChange(enabled, value, target.value)}
                />
            </div>
        </div>
    );
};

export const ContentEditProviderItem = ({item, onChange}) => {
    const [employees, setEmployees] = useState([]);
    const {enabled, value} = item || {
        enabled: true
    };
    const {sessionType, providerId, providerFirstName, providerLastName, providerEmail} = value || {
        sessionType: "company"
    };

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const emps = await HttpClient.get("/api/employees");
                setEmployees(emps);
            } catch (e) {
                message.error("Something went wrong, please try again later.", 5);
            }
        });
    }, []);

    const doChange = (en, val) => {
        onChange({enabled: en, value: val});
    };

    const findEmployee = userId => {
        return employees.find(emp => emp.userId === userId);
    };

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", justifyContent: "space-between", marginBottom: 10}}>
                <ContentEditLabel>Provider</ContentEditLabel>
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
                <Select
                    disabled={!enabled}
                    placeholder="Session type"
                    value={sessionType}
                    onSelect={val => doChange(enabled, {...value, sessionType: val})}>
                    <Option value="provider">By vendor</Option>
                    <Option value="employee">By employee</Option>
                    <Option value="company">By company</Option>
                </Select>
            </div>
            {sessionType === "employee" ? (
                <div style={{display: "flex", alignItems: "center", marginTop: 20}}>
                    <Select
                        value={providerId}
                        placeholder="Select employee"
                        onSelect={userId => {
                            const employee = findEmployee(userId);
                            doChange(enabled, {
                                ...value,
                                providerId: employee.userId,
                                providerFirstName: employee.firstName,
                                providerLastName: employee.lastName,
                                providerEmail: employee.email
                            });
                        }}>
                        {employees.map(emp => (
                            <Option value={emp.userId}>
                                {emp.firstName} {emp.lastName}
                            </Option>
                        ))}
                    </Select>
                </div>
            ) : null}
            {sessionType === "provider" ? (
                <div style={{display: "flex", alignItems: "center", flexDirection: "column", marginTop: 20}}>
                    <div style={{display: "flex", alignItems: "center", width: "100%"}}>
                        <FormInput
                            placeholder="First name / Business name"
                            style={{marginRight: 20}}
                            value={providerFirstName}
                            disabled={!enabled}
                            onChange={({target}) => doChange(enabled, {...value, providerFirstName: target.value})}
                        />
                        <FormInput
                            placeholder="Last name (Optional)"
                            value={providerLastName}
                            disabled={!enabled}
                            onChange={({target}) => doChange(enabled, {...value, providerLastName: target.value})}
                        />
                    </div>
                    <div style={{display: "flex", alignItems: "center", marginTop: 20, width: "100%"}}>
                        <FormInput
                            placeholder="Email (Optional)"
                            value={providerEmail}
                            disabled={!enabled}
                            onChange={({target}) => doChange(enabled, {...value, providerEmail: target.value})}
                        />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export const ContentEditButtonItem = ({item, onChange, maxLength}) => {
    const {enabled, value, extraValue} = item || {};

    const doChange = (en, val, lnk) => {
        onChange({enabled: en, value: val, extraValue: lnk});
    };

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", justifyContent: "space-between", marginBottom: 10}}>
                <ContentEditLabel>Button</ContentEditLabel>
                <WBCheckbox
                    onChange={({target}) => doChange(target.checked, value, extraValue)}
                    checked={enabled}
                    style={{marginRight: 10}}
                />
            </div>
            <div style={{display: "flex", alignItems: "center"}} onClick={() => doChange(true, value, extraValue)}>
                <FormInput
                    style={{maxWidth: 200, marginRight: 20, pointerEvents: enabled ? "auto" : "none"}}
                    disabled={!enabled}
                    placeholder="Button text"
                    maxLength={maxLength}
                    value={value}
                    onChange={({target}) => doChange(enabled, target.value, extraValue)}
                />
                <FormInput
                    disabled={!enabled}
                    placeholder="Button link"
                    style={{pointerEvents: enabled ? "auto" : "none"}}
                    value={extraValue}
                    onChange={({target}) => doChange(enabled, value, target.value)}
                />
            </div>
        </div>
    );
};
