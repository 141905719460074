import {HttpClient} from "../../http/HttpClient";
import {Steps} from "./ProviderWizardSteps";
import {attachCountryStateAndCity, getDefaultService, getDealerType} from "../wizardUtils";

export class ProviderStepperSignUpClient {
    static async signUp(providerInfoBySteps) {
        const user = this.arrangeUserDetails(providerInfoBySteps);
        const extraDetails = this.arrangeExtraDetails(providerInfoBySteps);
        const supportedServices = this.arrangeSupportedServicesDetails(providerInfoBySteps);

        const providerInfo = {
            user: user,
            extraDetails: extraDetails,
            supportedServices: supportedServices,
            ignoreInvitation: true
        };

        await HttpClient.put("/api/providers", providerInfo);
    }

    static arrangeSupportedServicesDetails(providerInfoBySteps) {
        return providerInfoBySteps[Steps.SERVICES_CENTER].services.map(service => {
            return {
                type: service.type,
                themes: service.themes,
                groupSize: service.groupSize,
                currency: service.currency,
                workZone: service.cities,
                address: service.address,
                breakMargin: null,
                coverPhoto: {
                    imageUrl: service.imageUrl,
                    imageId: service.imageId
                },
                description: service.details,
                durationMinutes: service.duration,
                languages: service.languages,
                location: service.location,
                price: service.price,
                priceType: service.per === "PER GROUP" ? "group" : "employee",
                productName: service.name,
                service: getDefaultService(service.type)
            };
        });
    }

    static arrangeUserDetails(providerInfoBySteps) {
        const basics = attachCountryStateAndCity(providerInfoBySteps[Steps.BASIC]);

        return {
            address: basics.address,
            cityId: "",
            cityName: basics.cityName,
            countryCode: "IL",
            countryId: "106",
            countryName: basics.countryName,
            email: basics.contact,
            firstName: basics.firstName,
            gender: providerInfoBySteps[Steps.PROFESSIONAL_INFO].gender,
            imageId: providerInfoBySteps[Steps.PROFESSIONAL_INFO].imageId,
            imageUrl: providerInfoBySteps[Steps.PROFESSIONAL_INFO].imageUrl,
            lastName: basics.lastName,
            phone: basics.phone,
            phonePrefix: "+972",
            stateId: undefined,
            stateName: basics.stateName
        };
    }

    static arrangeExtraDetails(providerInfoBySteps) {
        return {
            availability: providerInfoBySteps[Steps.PROFESSIONAL_INFO].availability,
            couponCode: undefined,
            description: providerInfoBySteps[Steps.PROFESSIONAL_INFO].about,
            experience: providerInfoBySteps[Steps.PROFESSIONAL_INFO].years,
            hasCertification: true,
            hasInsurance: true,
            paymentPlan: "0NIS + 20% of Revenues",
            portfolioLink: providerInfoBySteps[Steps.PROFESSIONAL_INFO].website,
            socialMediaLink: providerInfoBySteps[Steps.PROFESSIONAL_INFO].socialMedia,
            profession: providerInfoBySteps[Steps.PROFESSIONAL_INFO].professions,
            providerType: providerInfoBySteps[Steps.PROFESSIONAL_INFO].professionalType,
            brandName: providerInfoBySteps[Steps.PROFESSIONAL_INFO].brandName,
            dealerType: getDealerType(providerInfoBySteps[Steps.PROFESSIONAL_INFO].dealerType),
            licenseNo: providerInfoBySteps[Steps.PROFESSIONAL_INFO].licenseNo
        };
    }
}
