import React, {useEffect, useState} from "react";
import {message} from "antd";
import PaymentForm from "./PaymentForm";
import {HttpClient} from "../../../http/HttpClient";

export const Payment = ({parseResponse, creditCardExist}) => {
    const [fields, setFields] = useState(null);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tranzilaData, setTranzilaData] = useState(null);

    useEffect(() => {
        if (!fields) {
            tzlHostedFields();
        }
    }, []);

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const tranzilaData = await HttpClient.get("/api/tranzila");
                setTranzilaData(tranzilaData);
            } catch (e) {
                message.error("Something went wrong, please try again later.", 5);
            }
        });
    }, []);

    const handleSubmit = (event, saveCard) => {
        event.preventDefault();
        chargeCcData(event, saveCard);
    };

    const chargeCcData = (e, saveCard) => {
        setLoading(true);
        fields.charge(
            {
                terminal_name: tranzilaData.terminal,
                amount: 1,
                currency_code: "ILS",
                response_language: "english",
                tokenize: true,
                tran_mode: "V",
                TranzilaPW: tranzilaData.tranzilaPW
            },
            (error, response) => {
                if (response) {
                    parseResponse(response, saveCard);
                }
                if (error) {
                    if (error.messages !== null) {
                        handleError(error);
                    }
                }
                setLoading(false);
            }
        );
    };

    const handleError = err => {
        let tmpArray = [];
        err.messages.forEach(message => {
            tmpArray.push(message.param);
        });
        setMessages(tmpArray);
    };

    const validityChange = result => {
        const tmpArray = messages.slice();
        if (result && result.field) {
            tmpArray.splice(result.field.index, 1);
        }
        setMessages(tmpArray);
    };

    const tzlHostedFields = () => {
        const tzlFields = window.TzlaHostedFields.create({
            sandbox: false,

            fields: {
                credit_card_number: {
                    selector: "#credit_card_num",
                    placeholder: "מס׳ כרטיס אשראי",
                    tabindex: 1
                },
                cvv: {
                    selector: "#cvv",
                    placeholder: "CVV",
                    tabindex: 4
                },
                expiry: {
                    selector: "#expiry",
                    placeholder: "MM/YY",
                    tabindex: 3,
                    version: "1"
                }
            },
            styles: {
                input: {
                    "text-align": "center"
                }
            }
        });
        tzlFields.onEvent("blur", validityChange);
        setFields(tzlFields);
    };

    return (
        <PaymentForm handleSubmit={handleSubmit} creditCardExist={creditCardExist} loading={loading} msg={messages} />
    );
};
