import {StringBuilder} from "../../../AppUtils.js";
import {statusToHebrewAction} from "../consts.jsx";
import {isOrderHasShippingProvider} from "../../../event/utils.jsx";
import {AreYouSureModal} from "../../../components/index.jsx";

export const UpdateOrderStatusModal = ({updateOrderStatusInfo, setUpdateOrderStatusInfo, onChangeOrderStatus}) => {
    return (
        <>
            <AreYouSureModal
                visible={!!updateOrderStatusInfo}
                onClose={() => {
                    setUpdateOrderStatusInfo(null);
                }}
                title={new StringBuilder()
                    .append("בטוח שאתה רוצה")
                    .append(statusToHebrewAction[updateOrderStatusInfo?.status] ?? "לשנות")
                    .append("את ההזמנה הזאת?")
                    .toString()}
                subTitle={
                    isOrderHasShippingProvider(updateOrderStatusInfo?.event) ? "שימו לב! זאת הזמנה בשילוח עם וולבי" : ""
                }
                saveStyle={{color: "white", backgroundColor: "#F06060"}}
                onYes={() =>
                    onChangeOrderStatus(
                        updateOrderStatusInfo.event,
                        updateOrderStatusInfo.status,
                        updateOrderStatusInfo.toRemove
                    )
                }
                onNo={() => setUpdateOrderStatusInfo(null)}
            />
        </>
    );
};
