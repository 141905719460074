import React from "react";
import {PolicySection} from "./PolicyModal.jsx";

export const generatePolicy = isVoucher => {
    return [
        <PolicySection
            key="delivery"
            title="מדיניות משלוחים"
            sections={[
                {
                    policies: [
                        "אין באפשרותנו להתחייב על שעת אספקה מדויקת במסגרת חלון האספקה שהוגדר בהזמנה. אם תבקשו לתאם שעה ספציפית בהערות, נעשה את מירב המאמצים, אך איננו יכולים להבטיח זאת.",
                        "אם איש הקשר אינו זמין בזמן האספקה, המשלוח יושאר בכתובת שצוינה בעת ההזמנה.",
                        "אם לא ניתן להשאיר את המשלוח בכתובת שהוזנה, תחויבו במלוא סכום ההזמנה.",
                        "מחיר השילוח עשוי להשתנות במקרים מיוחדים תוך עדכון ואישור הלקוח."
                    ]
                }
            ]}
        />,
        <PolicySection
            key="cancel"
            title="מדיניות ביטולים"
            sections={[
                {
                    description: "ניתן לבטל הזמנה ולקבל החזר כספי מלא אם מתקיים אחד מהתנאים הבאים:",
                    policies: [
                        "ביטול ההזמנה לפחות 7 ימים לפני תאריך האספקה.",
                        "ביטול ההזמנה תוך 24 שעות ממועד אישור ההזמנה."
                    ]
                },
                ...(isVoucher
                    ? [
                          {
                              description: "לא ניתן לבטל שובר לאחר הפקתו ושליחתו אל הנמען"
                          }
                      ]
                    : [])
            ]}
        />,
        <PolicySection
            key="changes"
            title="מדיניות שינויים"
            sections={[
                {
                    description: "ניתן לבצע שינויים בהזמנה אם מתקיים אחד מהתנאים הבאים:",
                    policies: [
                        "שינוי בהזמנה עד 72 שעות לפני תאריך האספקה.",
                        "שינוי בהזמנה תוך 24 שעות ממועד אישור ההזמנה."
                    ]
                },
                ...(isVoucher
                    ? [
                          {
                              description: "לא ניתן לערוך שובר לאחר הפקתו ושליחתו אל הנמען"
                          }
                      ]
                    : [])
            ]}
        />
    ];
};
