export const languages = [
    {nativeName: "Lwo", englishName: "Acholi", symbol: "ach"},
    {nativeName: "Адыгэбзэ", englishName: "Adyghe", symbol: "ady"},
    {nativeName: "Afrikaans", englishName: "Afrikaans", symbol: "af"},
    {nativeName: "Tɕɥi", englishName: "Akan", symbol: "ak"},
    {nativeName: "العربية", englishName: "Arabic", symbol: "ar"},
    {nativeName: "Azərbaycan dili", englishName: "Azerbaijani", symbol: "az"},
    {nativeName: "Български", englishName: "Bulgarian", symbol: "bg"},
    {nativeName: "বাংলা", englishName: "Bengali", symbol: "bn"},
    {nativeName: "Català", englishName: "Catalan", symbol: "ca"},
    {nativeName: "Maya Kaqchikel", englishName: "Kaqchikel", symbol: "cak"},
    {nativeName: "Čeština", englishName: "Czech", symbol: "cs"},
    {nativeName: "Cymraeg", englishName: "Welsh", symbol: "cy"},
    {nativeName: "Dansk", englishName: "Danish", symbol: "da"},
    {nativeName: "Deutsch", englishName: "German", symbol: "de"},
    {nativeName: "Dolnoserbšćina", englishName: "Lower Sorbian", symbol: "dsb"},
    {nativeName: "Ελληνικά", englishName: "Greek", symbol: "el"},
    {nativeName: "English", englishName: "English", symbol: "en"},
    {nativeName: "Esperanto", englishName: "Esperanto", symbol: "eo"},
    {nativeName: "Español", englishName: "Spanish", symbol: "es"},
    {nativeName: "eesti keel", englishName: "Estonian", symbol: "et"},
    {nativeName: "Euskara", englishName: "Basque", symbol: "eu"},
    {nativeName: "فارسی", englishName: "Persian", symbol: "fa"},
    {nativeName: "Fulah", englishName: "Fulah", symbol: "ff"},
    {nativeName: "Suomi", englishName: "Finnish", symbol: "fi"},
    {
        nativeName: "Français",
        englishName: "French",
        symbol: "fr"
    },
    {nativeName: "Gaeilge", englishName: "Irish", symbol: "ga"},
    {
        nativeName: "Galego",
        englishName: "Galician",
        symbol: "gl"
    },
    {nativeName: "עברית‏", englishName: "Hebrew", symbol: "he"},
    {
        nativeName: "हिन्दी",
        englishName: "Hindi",
        symbol: "hi"
    },
    {nativeName: "Hrvatski", englishName: "Croatian", symbol: "hr"},
    {
        nativeName: "Hornjoserbšćina",
        englishName: "Upper Sorbian",
        symbol: "hsb"
    },
    {nativeName: "Kreyòl", englishName: "Haitian Creole", symbol: "ht"},
    {
        nativeName: "Magyar",
        englishName: "Hungarian",
        symbol: "hu"
    },
    {nativeName: "Bahasa Indonesia", englishName: "Indonesian", symbol: "id"},
    {
        nativeName: "Íslenska",
        englishName: "Icelandic",
        symbol: "is"
    },
    {nativeName: "Italiano", englishName: "Italian", symbol: "it"},
    {
        nativeName: "日本語",
        englishName: "Japanese",
        symbol: "ja"
    },
    {nativeName: "ភាសាខ្មែរ", englishName: "Khmer", symbol: "km"},
    {
        nativeName: "Taqbaylit",
        englishName: "Kabyle",
        symbol: "kab"
    },
    {nativeName: "ಕನ್ನಡ", englishName: "Kannada", symbol: "kn"},
    {
        nativeName: "한국어",
        englishName: "Korean",
        symbol: "ko"
    },
    {nativeName: "Latin", englishName: "Latin", symbol: "la"},
    {
        nativeName: "Lëtzebuergesch",
        englishName: "Luxembourgish",
        symbol: "lb"
    },
    {nativeName: "Lietuvių", englishName: "Lithuanian", symbol: "lt"},
    {
        nativeName: "Latviešu",
        englishName: "Latvian",
        symbol: "lv"
    },
    {nativeName: "मैथिली, মৈথিলী", englishName: "Maithili", symbol: "mai"},
    {
        nativeName: "Македонски",
        englishName: "Macedonian",
        symbol: "mk"
    },
    {nativeName: "മലയാളം", englishName: "Malayalam", symbol: "ml"},
    {
        nativeName: "मराठी",
        englishName: "Marathi",
        symbol: "mr"
    },
    {nativeName: "Bahasa Melayu", englishName: "Malay", symbol: "ms"},
    {
        nativeName: "Malti",
        englishName: "Maltese",
        symbol: "mt"
    },
    {nativeName: "ဗမာစကာ", englishName: "Burmese", symbol: "my"},
    {
        nativeName: "Norsk",
        englishName: "Norwegian",
        symbol: "no"
    },
    {
        nativeName: "नेपाली",
        englishName: "Nepali",
        symbol: "ne"
    },
    {nativeName: "Nederlands", englishName: "Dutch", symbol: "nl"},
    {
        nativeName: "Occitan",
        englishName: "Occitan",
        symbol: "oc"
    },
    {nativeName: "ਪੰਜਾਬੀ", englishName: "Punjabi", symbol: "pa"},
    {
        nativeName: "Polski",
        englishName: "Polish",
        symbol: "pl"
    },
    {nativeName: "Português", englishName: "Portuguese", symbol: "pt"},
    {
        nativeName: "Română",
        englishName: "Romanian",
        symbol: "ro"
    },
    {nativeName: "Русский", englishName: "Russian", symbol: "ru"},
    {
        nativeName: "Slovenčina",
        englishName: "Slovak",
        symbol: "sk"
    },
    {nativeName: "Slovenščina", englishName: "Slovenian", symbol: "sl"},
    {
        nativeName: "Shqip",
        englishName: "Albanian",
        symbol: "sq"
    },
    {nativeName: "Српски", englishName: "Serbian", symbol: "sr"},
    {
        nativeName: "Basa Sunda",
        englishName: "Sundanese",
        symbol: "su"
    },
    {nativeName: "Svenska", englishName: "Swedish", symbol: "sv"},
    {
        nativeName: "Kiswahili",
        englishName: "Swahili",
        symbol: "sw"
    },
    {nativeName: "தமிழ்", englishName: "Tamil", symbol: "ta"},
    {
        nativeName: "తెలుగు",
        englishName: "Telugu",
        symbol: "te"
    },
    {nativeName: "забо́ни тоҷикӣ́", englishName: "Tajik", symbol: "tg"},
    {
        nativeName: "ภาษาไทย",
        englishName: "Thai",
        symbol: "th"
    },
    {nativeName: "Filipino", englishName: "Filipino", symbol: "tl"},
    {
        nativeName: "tlhIngan-Hol",
        englishName: "Klingon",
        symbol: "tlh"
    },
    {nativeName: "Türkçe", englishName: "Turkish", symbol: "tr"},
    {
        nativeName: "Українська",
        englishName: "Ukrainian",
        symbol: "uk"
    },
    {nativeName: "اردو", englishName: "Urdu", symbol: "ur"},
    {
        nativeName: "O'zbek",
        englishName: "Uzbek",
        symbol: "uz"
    },
    {nativeName: "Tiếng Việt", englishName: "Vietnamese", symbol: "vi"},
    {
        nativeName: "ייִדיש",
        englishName: "Yiddish",
        symbol: "yi"
    },
    {nativeName: "中文", englishName: "Chinese", symbol: "zh"}
];
