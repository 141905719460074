import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import {message} from "antd";
import {StepperSignUpClient} from "./StepperSignUpClient";
import {LOGIN_EMAIL} from "./CompanySignUpShortStepper";
import {CompanyDetailsForm} from "./CompanyDetailsForm";
import {attachCountryStateAndCity} from "../wizardUtils";
import {Images} from "../../images/Images";
import {LANGUAGE, Languages} from "../../auth/Login";
import {HttpClient} from "../../http/HttpClient";
import {GoogleAnalytics} from "../../GoogleAnalytics";

export const CompanySignUpShortStepperDetails = inject("store")(
    observer(props => {
        const {history, store} = props;
        const [language, setLanguage] = useState(store.language || JSON.parse(localStorage.getItem(LANGUAGE)));
        const [email, setEmail] = useState(store.loginEmail || JSON.parse(localStorage.getItem(LOGIN_EMAIL)));
        const [formValues, setFormValues] = useState({});

        const isMobileView = () => {
            return window.innerWidth < 550;
        };

        useEffect(() => {
            setLanguage(store.language || JSON.parse(localStorage.getItem(LANGUAGE)));
            setEmail(store.loginEmail || JSON.parse(localStorage.getItem(LOGIN_EMAIL)));
        }, [store.loginEmail]);

        const onFinish = () => {
            let companyInfo = formValues;
            companyInfo.contact = email;
            companyInfo = attachCountryStateAndCity(companyInfo);
            companyInfo = StepperSignUpClient.attachBranding(companyInfo);
            Promise.resolve().then(async () => {
                try {
                    companyInfo = await StepperSignUpClient.attachInitialBranch(companyInfo);
                    const company = await HttpClient.put("/api/companies", companyInfo);
                    GoogleAnalytics.event("Company", "Signup", company);
                    fbq("track", "account_success");
                    window.lintrk("track", {conversion_id: 9138914});
                    history.push("/company/wizard/success");
                } catch (e) {
                    message.error("Something went wrong, please try again later.");
                }
            });
        };

        return (
            <div
                className="company-sign-up-short-stepper-details"
                style={{
                    backgroundImage: isMobileView() ? void 0 : `url(${Images.loginBackground})`
                }}>
                <div
                    onClick={() => {
                        setLanguage(language === Languages.Hebrew ? Languages.English : Languages.Hebrew);
                        localStorage.setItem(
                            LANGUAGE,
                            JSON.stringify(language === Languages.Hebrew ? Languages.English : Languages.Hebrew)
                        );
                    }}
                    className="company-sign-up-short-stepper-details-change-language">
                    {language === Languages.Hebrew ? "Change language" : "שינוי שפה"}
                </div>

                <CompanyDetailsForm
                    setFormValues={setFormValues}
                    onFinish={onFinish}
                    language={language}
                    submitText={language === Languages.Hebrew ? "בואו נתחיל!" : "Let’s get started!"}
                    header={
                        <>
                            <div>
                                <span className="company-sign-up-short-stepper-details-title">
                                    {language === Languages.Hebrew
                                        ? "כמה פרטים קטנים לפני שנתחיל? 🙏"
                                        : "Before we get started  🙏"}
                                </span>
                            </div>
                            <div className="company-sign-up-short-stepper-details-break-line" />
                            <div className="company-sign-up-short-stepper-details-sub-title">
                                <div>
                                    {language === Languages.Hebrew
                                        ? "ביקור ראשון שלכם אצלנו? כמה פרטים שיעזרו"
                                        : "First time visiting? Fill in some blanks to help"}
                                </div>
                                <div>
                                    {language === Languages.Hebrew
                                        ? "לנו לתת יחס אישי :)"
                                        : "us understand you a little better :)"}
                                </div>
                            </div>
                        </>
                    }
                />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: isMobileView() ? 0 : "20px",
                        marginBottom: "20px"
                    }}>
                    <div
                        style={{
                            width: "100%",
                            marginLeft: 10,
                            direction: language === Languages.Hebrew ? "rtl" : "ltr",
                            display: "flex"
                        }}>
                        <label
                            style={{
                                marginLeft: 5,
                                marginRight: "5px",
                                fontSize: 18,
                                color: isMobileView() ? "var(--secondary-color)" : "#FFFFFF"
                            }}>
                            {language === Languages.Hebrew ? "כבר יש לכם חשבון?" : "Already have an account?"}
                        </label>
                        <div
                            style={{
                                fontSize: 18,
                                color: isMobileView() ? "var(--secondary-color)" : "#FFFFFF",
                                cursor: "pointer"
                            }}
                            onClick={() => history.push("/login")}>
                            <u>{language === Languages.Hebrew ? "היכנסו לכאן" : "Login here"}</u>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);
