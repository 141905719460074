import {MainServices} from "../consts.js";

export const TipsOptions = [10, 12, 15, 20];

export const OrderFormPanelKeys = {
    Location: "location",
    Time: "time",
    Amount: "amount",
    Requests: "requests",
    UpgradeOptions: "upgradeOptions",
    ParticipantsExtraInfo: "participantsExtraInfo",
    ExtraRequests: "extraRequests",
    ExtraContactInfo: "extraContactInfo",
    deliveryRequests: "deliveryRequests",
    Tipping: "tipping",

    VoucherAmount: "voucherAmount",
    VoucherRecipient: "voucherRecipient",
    VoucherTime: "voucherTime",
    VoucherSendingMethod: "voucherSendingMethod",
    VoucherSender: "voucherSender",
    // For some reason I couldn't make the key of the payment panel change from '0'. If you can change this and still have the feature of the red error highlight works, Great :)
    Payment: "0",
    PolicyConfirmation: "policyConfirmation"
};

export const MarketplaceViewType = {
    selectionPage: "selectionPage",
    product: "product",
    link: "link"
};

export const PHONE_NUMBER_ERROR_VALIDATION =
    "אנא הזינו מספר טלפון חוקי המתחיל ב-'05' ומורכב מ-10 ספרות, ללא רווחים או תווים מיוחדים";

export const CategoryToWhenSectionTitle = {
    [MainServices.WORKSHOPS]: "מתי הפעילות"
};

export const productDefaultNoticeTitle =
    "כשבוחרים חלון לאספקה כדאי לקחת בחשבון שחלון הזמן לאספקה של מוצר זה הוא טווח של";

export const CategoryToWhenSectionNoticeTitle = {
    [MainServices.WORKSHOPS]: "כשבוחרים את שעת תחילת הפעילות, כדאי לקחת בחשבון שמשך הפעילות היא כ-"
};

export const deliveryDefaultTimeComment =
    "יש לבחור את טווח האספקה לקבלת המשלוח, כלומר המשלוח עשוי להגיע בכל נקודה בזמן בתוך הטווח המוגדר";

export const CategoryToWhenSectionComment = {
    [MainServices.WORKSHOPS]: "יש לבחור את התאריך והשעה בה תתחיל הפעילות"
};
