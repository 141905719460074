import {capitalize} from "lodash";

export const THEME_COLOR_1 = 0;
export const THEME_COLOR_2 = 1;
export const THEME_COLOR_3 = 2;
export const THEME_COLOR_4 = 3;

export const ThemeType = {
    LUXURY: "luxury",
    CLASSIC: "classic",
    PLAYFUL: "playful"
};

export const defaultSelectedTheme = ThemeType.CLASSIC;

export const defaultThemes = {
    luxury: {
        order: 1,
        name: capitalize(ThemeType.LUXURY),
        colors: ["#000000", "#8398A6", "#F2F4F6", "#333333"]
    },
    classic: {
        order: 2,
        name: capitalize(ThemeType.CLASSIC),
        colors: ["#11538C", "#6AADD9", "#E7F3FA", "#022340"]
    },
    playful: {
        order: 3,
        name: capitalize(ThemeType.PLAYFUL),
        colors: ["#595AD4", "#00CF82", "#F2F4F6", "var(--secondary-color)"]
    }
};
