import React, {useContext, useEffect, useState} from "react";
import {message} from "antd";
import {isEmpty, truncate} from "lodash";
import {extractSectionIdFromPath, getImageProps, hasDescription, isSmallScreen} from "./EXCenterSectionPageHelper";
import {EXCenterSectionCategories} from "./EXCenterSectionCategories";
import {EXCenterSectionItems} from "./EXCenterSectionItems";
import {isEventsSection} from "../utils/EventUtil";
import {organizeContent} from "../utils/ContentUtil";
import {EXCenterSearchInput} from "../search/EXCenterSearchInput";
import {EXCenterContext} from "../EXCenterContext";
import {scrollToElement} from "../utils/ViewUtil";
import {toValidHtmlId} from "../utils/DomUtil";
import {EXCenterMobileCalendar} from "../calendar/EXCenterMobileCalendar";
import {EXCenterSectionDesignMenu} from "../control/EXCenterSectionDesignMenu";
import {EXCenterProperties} from "../utils/EXCenterProperties";
import CompanySchedule from "../../company/CompanySchedule";
import {HttpClient} from "../../http/HttpClient";
import {findContentSectionById} from "../../company/content/CompanyContent";
import {PageLoader} from "../../components";

export const EXCenterSectionPage = ({history, location, match}) => {
    const {tag} = match.params;
    const [sectionId, setSectionId] = useState(match.params.sectionId);
    const {events, designInfoPerSection, theme, screenSize} = useContext(EXCenterContext);
    const [itemsByCategory, setItemsByCategory] = useState(null);
    const [categories, setCategories] = useState(null);
    const [items, setItems] = useState(null);
    const [term, setTerm] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(null);
    let categoriesRef = null;
    const sectionDesignInfo = designInfoPerSection[sectionId];

    useEffect(() => {
        if (items === null && isEventsSection(sectionId) && events) {
            setItems(events);
        }
    });

    useEffect(() => {
        Promise.resolve().then(async () => await refreshItems(location));
        const unlisten = history.listen(refreshItems);
        return () => {
            unlisten();
        };
    }, []);

    useEffect(() => {
        if (!isEmpty(term) && isEmpty(itemsByCategory)) {
            message.destroy();
            message.error("no results for this search", 2);
        }
    }, [itemsByCategory]);

    const setStateValues = state => {
        setItems(state.items);
        setCategories(state.categories);
        setItemsByCategory(state.itemsByCategory);
    };

    const refreshItems = async loc => {
        const currentSectionId = extractSectionIdFromPath(loc);
        if (!findContentSectionById(currentSectionId)) {
            return;
        }

        setSectionId(currentSectionId);
        if (!isEventsSection(currentSectionId)) {
            setStateValues({items: null, categories: null, itemsByCategory: null});
            try {
                const items = await HttpClient.get(`/api/content/sections/${currentSectionId}`);
                setTimeout(() => {
                    setStateValues({items: items, categories: null, itemsByCategory: null});
                }, 50);
            } catch (e) {
                message.error("Something went wrong, please try again later.", 5);
            }
        } else {
            setTimeout(() => {
                setStateValues({items: events, categories: null, itemsByCategory: null});
            }, 50);
        }
    };

    const onSearch = value => {
        setTerm(value ? value.toLowerCase().trim() : "");
        categoriesRef.reset();
        setItemsByCategory(null);
        setCategories(null);
    };

    const onCategorySelect = category => {
        if (category && !isEventsSection(sectionId)) {
            scrollToElement(`#category-${toValidHtmlId(category)}`);
        }
        setSelectedCategory(category);
    };

    if (items && (itemsByCategory === null || categories === null)) {
        const byCategory = organizeContent(
            isEventsSection(sectionId) ? [] : items,
            isEventsSection(sectionId) ? items : [],
            term
        );
        setItemsByCategory(byCategory);
        setCategories(Object.keys(byCategory));
    }

    if (itemsByCategory === null || categories === null) {
        return <PageLoader align="flex-start" top={80} height={400} />;
    }

    return (
        <div
            style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%"
            }}>
            <EXCenterSectionDesignMenu
                className="wb-center-section-page-design"
                sectionId={sectionId}
                editItems={{
                    title: true,
                    buttonNoLink: false,
                    coverPhoto: true,
                    description: true
                }}
                coverSize={{width: 1440, height: 420}}
            />
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    height: EXCenterProperties.Section.Cover.Height,
                    backgroundColor: sectionDesignInfo.coverPhoto ? "transparent" : theme.primaryColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    ...getImageProps(sectionDesignInfo),
                    opacity: EXCenterProperties.Section.opacityByDisplay(sectionDesignInfo)
                }}>
                <div
                    className="wb-center-section-padding"
                    style={{display: "flex", justifyContent: "center", width: "100%", marginTop: -10}}>
                    <div
                        className="wb-ss-align-center"
                        style={{display: "flex", flexDirection: "column", width: "100%", maxWidth: 1200}}>
                        <div
                            className="wb-ss-justify-center wb-ss-full-width"
                            style={{
                                fontSize: 46,
                                fontWeight: 900,
                                color: "white",
                                maxHeight: 120,
                                maxWidth: 800,
                                overflow: "hidden",
                                lineHeight: "60px",
                                marginBottom: hasDescription ? 0 : 40,
                                padding: 2,
                                paddingLeft: 10
                            }}>
                            {sectionDesignInfo.title || findContentSectionById(sectionId).displayName}
                        </div>
                        {hasDescription(sectionDesignInfo) ? (
                            <div
                                className="wb-ss-justify-center wb-ss-full-width"
                                style={{
                                    marginTop: 25,
                                    fontSize: 24,
                                    fontWeight: 200,
                                    lineHeight: "35px",
                                    color: "white",
                                    overflow: "hidden",
                                    marginBottom: 40,
                                    padding: 2,
                                    paddingLeft: 10,
                                    maxWidth: 800,
                                    maxHeight: 110
                                }}>
                                {truncate(sectionDesignInfo.description.text, {length: 125})}
                            </div>
                        ) : null}
                        <EXCenterSearchInput placeholder="Search" onSearch={onSearch} style={{marginBottom: 0}} />
                    </div>
                </div>
            </div>
            <EXCenterSectionCategories
                withAll={isEventsSection(sectionId)}
                categories={categories}
                autoCategory={tag}
                onCategorySelect={onCategorySelect}
                attachRef={ref => (categoriesRef = ref)}
                maxLength={5}
            />
            {isEventsSection(sectionId) ? (
                isSmallScreen(screenSize) ? (
                    <div style={{marginTop: 40}}>
                        <EXCenterMobileCalendar selectedCategory={selectedCategory} searchTerm={term} />
                    </div>
                ) : (
                    <div style={{width: "100%", marginTop: 40}}>
                        <CompanySchedule
                            hideCustomSession={true}
                            enableSpecialInteraction={false}
                            term={term}
                            category={selectedCategory}
                            hideTitle={true}
                            className="wb-center-calendar"
                        />
                    </div>
                )
            ) : (
                <EXCenterSectionItems itemsByCategory={itemsByCategory} />
            )}
        </div>
    );
};
