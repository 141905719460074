import React from "react";
import moment from "moment";
import {ConfigProvider, Dropdown, Menu, message, Tooltip} from "antd";
import {flatten, parseInt, uniqBy} from "lodash";
import classnames from "classnames";
import {
    CheckOutlined,
    CrownFilled,
    DownOutlined,
    EllipsisOutlined,
    InfoCircleOutlined,
    QuestionCircleOutlined
} from "@ant-design/icons";
import {EDIT_ORDER_ERRORS, PRICE_THRESHOLD, PRICE_WARNING_PERCENT} from "./consts";
import {CommentModal} from "../components/CommentModal";
import {TableDateCell} from "../components";
import {
    CopyableSpan,
    getStatus,
    OrderStatus,
    OrderStatusLabel,
    statusToTextAndColor,
    StyledButton,
    TableDoubleValueCell
} from "../../components";
import {HttpClient} from "../../http/HttpClient";
import {PaymentStatuses} from "../../components/OrderStatusLabel";
import {AdminOrdersPaymentTypeToName, BUDGET_LOAD_TRANSACTION, getOrderPaymentType} from "../../orders/consts";
import {PaymentType} from "../../consts.js";
import {createProductLink} from "../../marketplace/MarketplaceHelper";
import {openWindow} from "../../http/WindowOpener";
import {NOOP} from "../../utils/NOOP";
import {validateOrderDisabledForEdit, isOrderWaitingForManagerApprove} from "./utils.jsx";
import {
    getFirstOrderShippingProvider,
    isOrderHasShippingProvider,
    isOrderHasValidDeliveryInfo
} from "../../event/utils.jsx";

import {PLACE_ERROR, WARN_LEVEL} from "../../marketplace/MarketplaceUtils.jsx";

import {generateLionWheelOrderDeliveryStatusLink} from "../../event/OrderDeliveryStatus/utils.jsx";
import {StringBuilder} from "../../AppUtils.js";
import {getOrderPrice} from "../../utils/calculationUtils.jsx";

const columns = (
    me,
    services,
    onEdit,
    updateStatus,
    updateOrder,
    allCSMs,
    onChangeCSM,
    onChangePartner,
    onHistory,
    onImage,
    isAdmin,
    companiesStats = null,
    chargeCreditCard,
    onSaveComment = NOOP,
    deliveryProviders,
    enableOrderBudgetHandler,
    onSyncOrder,
    onChangeDeliveryProvider = NOOP
) => [
    {
        title: "#",
        key: "#",
        width: 50,
        render(_, __, idx) {
            return `${idx + 1}`;
        }
    },
    {
        title: "שם חברה",
        width: 220,
        dataIndex: "companyName",
        key: "company",
        sorter: (r1, r2) => (r1.companyName.toLowerCase() < r2.companyName.toLowerCase() ? 1 : -1),
        render(companyName, {companyContactEmail, companyContactName, companyPhone, creatingUser, company}) {
            return (
                <TableDoubleValueCell>
                    {companyName}
                    <div className="names-order-details">
                        <Tooltip
                            placement="topRight"
                            title={creatingUser?.isCompanySubAdmin ? ` אדמין ראשי: ${company?.contactName}` : null}>
                            <span>{companyContactName}</span>
                            {creatingUser?.isCompanyMaster && (
                                <CrownFilled title="אדמין ראשי" className="admin-order-crown-filled" />
                            )}
                        </Tooltip>
                        {companyPhone && <span>{companyPhone}</span>}
                        <CopyableSpan>{companyContactEmail}</CopyableSpan>
                    </div>
                </TableDoubleValueCell>
            );
        }
    },
    {
        title: "שם שותף",
        width: 200,
        key: "professional",
        sorter: (r1, r2) => {
            return r1.providerBrand?.toLowerCase() < r2.providerBrand?.toLowerCase() ? 1 : -1;
        },
        render(_, {providerBrand, providerContactName, providerPhone, providerEmail}) {
            return providerBrand || providerContactName || providerPhone || providerEmail ? (
                <TableDoubleValueCell>
                    {providerBrand}
                    <div className="names-order-details">
                        {[providerContactName, providerPhone, providerEmail].filter(Boolean).map(val => (
                            <span>{val}</span>
                        ))}
                    </div>
                </TableDoubleValueCell>
            ) : null;
        }
    },
    {
        title: "שם המוצר",
        width: 180,
        key: "productName",
        sorter: ({productName: r1}, {productName: r2}) => r1?.localeCompare(r2),
        render(_, {productName, sessionName, serviceId, providerId, productId, subOrders, paymentType}) {
            const url = !!subOrders?.length
                ? `/dashboard/services/productBundle/${productId}`
                : `/dashboard/services/${serviceId}/providersV2/${providerId}/${productId}`;

            return productId ? (
                <a href={url} target="_blank" rel="noreferrer">
                    {productName || sessionName}
                </a>
            ) : (
                <>
                    <div>{productName || sessionName}</div>
                    {paymentType !== BUDGET_LOAD_TRANSACTION ? (
                        <div className="admin-orders-table-order-budget-load-request">בוצע על ידי הלקוח</div>
                    ) : null}
                </>
            );
        }
    },
    {
        title: "תאריך הזמנה",
        dataIndex: "createdAt",
        width: 150,
        sorter: ({createdAt: r1}, {createdAt: r2}) => (r1 < r2 ? 1 : -1),
        defaultSortOrder: "ascend",
        render(createdAt) {
            return <TableDateCell date={createdAt} />;
        }
    },
    {
        title: "תאריך הספקה",
        dataIndex: "dtstart",
        width: 150,
        sorter: ({dtstart: r1}, {dtstart: r2}) => r1 - r2,
        render(startDate, {until}) {
            return startDate ? <TableDateCell date={startDate} endDate={until ?? startDate} /> : null;
        }
    },
    {
        title: "מחיר הזמנה",
        key: "orderPrice",
        width: 120,
        sorter: ({offeredPrice: of1}, {offeredPrice: of2}) => of1 - of2,
        render(order, {serviceId, offeredPrice, originalOfferedPrice, errors}) {
            const price = serviceId !== BUDGET_LOAD_TRANSACTION ? Math.max(getOrderPrice(order), 0) : offeredPrice;
            const showPriceWarning = originalOfferedPrice * PRICE_WARNING_PERCENT >= offeredPrice;
            return (
                <div className="cell-container">
                    <span
                        className={classnames("admin-orders-table-order-price-cell", {
                            "admin-orders-table-order-price-cell-threshold-exceeded": price >= PRICE_THRESHOLD
                        })}>
                        {price?.toLocaleString()}
                    </span>
                    {isAdmin && showPriceWarning && (
                        <Tooltip
                            title={
                                <span className="admin-orders-table-order-price-cell-under-percent-tooltip">{`המחיר המקורי הוא: ${originalOfferedPrice?.toLocaleString()}`}</span>
                            }>
                            <InfoCircleOutlined className="admin-orders-table-order-price-cell-under-percent" />
                        </Tooltip>
                    )}

                    {isAdmin && errors?.[PLACE_ERROR]?.level === WARN_LEVEL && (
                        <span>
                            <ConfigProvider direction={"rtl"}>
                                <Tooltip title={errors?.[PLACE_ERROR]?.message}>
                                    <span className="admin-orders-table-order-highlight-content">❗️</span>
                                </Tooltip>
                            </ConfigProvider>
                        </span>
                    )}
                </div>
            );
        }
    },
    {
        title: "מספר הזמנה",
        key: "proposalId",
        width: 100,
        render(order, {proposalId, eventId}) {
            const elementsToShow = [<span id={eventId}>{proposalId ?? eventId}</span>];

            if (isAdmin && isOrderHasShippingProvider(order)) {
                const deliveryProvider = getFirstOrderShippingProvider(order, deliveryProviders);
                elementsToShow.push(
                    <Tooltip
                        title={new StringBuilder()
                            .append("שילוח דרך מערכת וולבי")
                            .append(deliveryProvider?.name ? `(${deliveryProvider.name})` : null)
                            .toString()}>
                        <a onClick={() => openWindow(`/events/${eventId}/orderDeliveryStatus`)}>🚚</a>
                    </Tooltip>
                );
            }

            return <div>{elementsToShow}</div>;
        }
    },
    {
        title: "סטטוס",
        dataIndex: "status",
        width: 155,
        filters: [
            ...Object.entries(statusToTextAndColor).map(([key, value]) => {
                const [text, color, backgroundColor] = value;

                return {
                    text: <span style={{color, backgroundColor}}>{text}</span>,
                    value: key
                };
            })
        ],
        onFilter: (value, record) => {
            if (value === OrderStatus.firstOrderForUser) {
                return (
                    companiesStats?.[record?.companyId]?.firstOrder?.[record?.creatingUserId] === record.eventId &&
                    record.paymentType !== BUDGET_LOAD_TRANSACTION
                );
            } else if (value === OrderStatus.notFirstOrderForUser) {
                return (
                    companiesStats?.[record?.companyId]?.firstOrder?.[record?.creatingUserId] !== record.eventId &&
                    record.paymentType !== BUDGET_LOAD_TRANSACTION
                );
            }

            return getStatus(record)[0]?.id === value;
        },
        render(status, order) {
            let firstOrderText;
            const isWaitingForManagerApprove = isOrderWaitingForManagerApprove(order, me);
            if (companiesStats?.[order.companyId]?.companyFirstOrder === order.eventId) {
                firstOrderText = "עסקה ראשונה לאדמין ראשי";
            } else if (companiesStats?.[order.companyId]?.firstOrder[order?.creatingUserId] === order.eventId) {
                firstOrderText = "עסקה ראשונה לתת אדמין";
            }

            return (
                <div className="admin-orders-table-status-cell">
                    <OrderStatusLabel hidePaymentStatuses={true} order={order} />
                    {companiesStats?.[order?.companyId] && order.paymentType !== BUDGET_LOAD_TRANSACTION && (
                        <span className="admin-orders-table-is-first-order">{firstOrderText}</span>
                    )}
                    {!!isWaitingForManagerApprove && (
                        <>
                            <StyledButton
                                className="admin-orders-table-show-event-btn"
                                onClick={() => openWindow(`/events/${order.eventId}/orderV2`)}>
                                לצפייה ואישור הזמנה
                            </StyledButton>
                        </>
                    )}
                </div>
            );
        }
    },
    {
        title: "ניהול מכירה",
        dataIndex: "assignedCSMs",
        filters: [
            ...(allCSMs ?? []).map(csm => ({
                text: csm.firstName,
                value: csm.userId
            })),
            {text: "ללא", value: "empty"}
        ],
        onFilter: (value, record) =>
            value === "empty"
                ? !record.assignedCSMs?.length
                : record.assignedCSMs?.some(({userId}) => userId === value),
        render(assignedCSMs, {eventId, serviceId, parentOrderId, subOrders, updatedAt}) {
            const assignedCSMsList = subOrders?.length
                ? uniqBy(flatten(subOrders.map(({assignedCSMs}) => assignedCSMs)), assignedCSM => assignedCSM.userId)
                : assignedCSMs;

            const menuOverlay = {
                items: (allCSMs ?? [])?.map(csm => ({
                    key: csm.userId,
                    label: (
                        <div
                            onClick={() =>
                                !subOrders?.length
                                    ? onChangeCSM(
                                          csm,
                                          assignedCSMs,
                                          eventId,
                                          serviceId === BUDGET_LOAD_TRANSACTION,
                                          updatedAt,
                                          parentOrderId
                                      )
                                    : NOOP
                            }>
                            {assignedCSMsList?.some(({userId}) => userId === csm.userId) ? <CheckOutlined /> : null}
                            {csm.firstName}
                        </div>
                    )
                }))
            };

            return (
                <Dropdown trigger={isAdmin ? ["click"] : []} menu={menuOverlay}>
                    <span className="admin-companies-csm-cell">
                        {
                            <>
                                {assignedCSMs?.length ? assignedCSMs.map(({firstName}) => firstName).join(", ") : "ללא"}
                                {isAdmin ? <DownOutlined /> : null}
                            </>
                        }
                    </span>
                </Dropdown>
            );
        }
    },
    ...(isAdmin
        ? [
              {
                  title: "מנהל/ת שותף",
                  dataIndex: "assignedPartners",
                  filters: allCSMs?.map(csm => ({
                      text: csm.firstName,
                      value: csm.userId
                  })),
                  onFilter: (value, record) => record.assignedPartners?.some(({userId}) => userId === value),
                  render(assignedPartners, {eventId, parentOrderId, subOrders, updatedAt}) {
                      const assignPartnersList =
                          subOrders?.length && assignedPartners?.length
                              ? uniqBy(
                                    flatten(subOrders.map(({assignPartners}) => assignPartners)),
                                    assignPartner => assignPartner.userId
                                )
                              : assignedPartners;

                      const menuOverlay = {
                          items: (allCSMs ?? [])?.map(partner => ({
                              key: partner.userId,
                              label: (
                                  <div
                                      onClick={() =>
                                          !subOrders?.length
                                              ? onChangePartner(
                                                    partner,
                                                    assignedPartners,
                                                    eventId,
                                                    updatedAt,
                                                    parentOrderId
                                                )
                                              : NOOP
                                      }>
                                      {assignPartnersList?.some(({userId}) => userId === partner.userId) ? (
                                          <CheckOutlined />
                                      ) : null}
                                      {partner.firstName}
                                  </div>
                              )
                          }))
                      };

                      return (
                          <Dropdown trigger={["click"]} menu={menuOverlay}>
                              <span className="admin-companies-csm-cell">
                                  {
                                      <>
                                          {assignedPartners?.length
                                              ? assignedPartners.map(({firstName}) => firstName).join(", ")
                                              : "ללא"}
                                          <DownOutlined />
                                      </>
                                  }
                              </span>
                          </Dropdown>
                      );
                  }
              }
          ]
        : []),
    {
        title: "הנחה",
        render(_, {couponDiscountAmount, companyDiscountAmount}) {
            const totalDiscount = (parseInt(couponDiscountAmount) || 0) + (parseInt(companyDiscountAmount) || 0);
            return (
                <div className="ltr">
                    <Tooltip
                        title={
                            isAdmin ? (
                                <>
                                    <div>* המידע מעודכן בהתאם למועד הכנסת ההזמנה</div>
                                    <div>הנחת קוד קופון - {couponDiscountAmount}</div>
                                    <div>הנחת חברה - {companyDiscountAmount}</div>
                                </>
                            ) : null
                        }>
                        {totalDiscount ? totalDiscount * -1 : null}
                    </Tooltip>
                </div>
            );
        }
    },
    ...(isAdmin
        ? [
              {
                  title: "זיכוי",
                  dataIndex: "providerDiscount",
                  render(providerDiscount) {
                      return <div className="ltr">{providerDiscount ? providerDiscount * -1 : null}</div>;
                  }
              }
          ]
        : []),
    {
        title: "טיפ",
        dataIndex: "tip",
        render(tip) {
            return tip?.toLocaleString();
        }
    },
    {
        title: "קוד קופון",
        dataIndex: "couponCode",
        width: 150,
        render(_, {couponCode, discount, couponDiscountAmount}) {
            return couponCode ? (
                <TableDoubleValueCell>
                    {couponCode || ""}
                    {couponDiscountAmount ?? discount}
                </TableDoubleValueCell>
            ) : null;
        }
    },
    {
        title: "גביה",
        dataIndex: "paymentType",
        width: 150,
        filters: Object.values(AdminOrdersPaymentTypeToName).map(value => ({
            text: value,
            value
        })),
        onFilter: (value, record) => getOrderPaymentType(record) === value,
        render(paymentType, order) {
            return (
                <div>
                    {getOrderPaymentType(order) ?? paymentType}
                    <OrderStatusLabel order={order} statusesToShow={PaymentStatuses} />
                </div>
            );
        }
    },
    {
        render(_, order) {
            const isOrderPaid = order.eventStatusMap?.some(({id}) => id === OrderStatus.paid);
            const isOrderDelivered = order.eventStatusMap?.some(({id}) => id === OrderStatus.delivered);
            const disableEditOrder = !!validateOrderDisabledForEdit(order, isAdmin);
            const isWaitingForManagerApprove = isOrderWaitingForManagerApprove(order, me);

            const adminOptions = [
                ...(order.shippingDetails?.public_id
                    ? [
                          <Menu.Item
                              onClick={() =>
                                  openWindow(generateLionWheelOrderDeliveryStatusLink(order?.shippingDetails))
                              }>
                              צפייה בהזמנה בחברת השילוח
                          </Menu.Item>
                      ]
                    : []),
                <Menu.Item key="images" disabled={onImage === null} onClick={() => onImage(order)}>
                    הוספת תמונות או סרטונים
                </Menu.Item>,
                <Menu.Item key="history" onClick={() => onHistory(order)}>
                    צפייה בהיסטוריית הזמנה
                </Menu.Item>,
                <Menu.Item
                    key="approve"
                    disabled={
                        [OrderStatus.accepted].includes(order.status) ||
                        isWaitingForManagerApprove ||
                        updateStatus === null
                    }
                    onClick={() => updateStatus(order, OrderStatus.accepted)}>
                    אישור עסקה (צד ספק)
                </Menu.Item>,
                <Menu.Item
                    key="cancel"
                    disabled={
                        [OrderStatus.cancelled].includes(order.status) ||
                        isWaitingForManagerApprove ||
                        updateStatus === null
                    }
                    onClick={() => updateStatus(order, OrderStatus.cancelled)}>
                    ביטול עסקה (צד אדמין)
                </Menu.Item>,
                <Menu.Item
                    key="decline"
                    disabled={
                        [OrderStatus.cancelled, OrderStatus.declined].includes(order.status) ||
                        isWaitingForManagerApprove ||
                        updateStatus === null
                    }
                    onClick={() => updateStatus(order, OrderStatus.declined)}>
                    סירוב עסקה (צד ספק)
                </Menu.Item>,
                <Menu.Item
                    key="renew"
                    disabled={
                        ![OrderStatus.declined, OrderStatus.cancelled].includes(order.status) || updateStatus === null
                    }
                    onClick={() => updateStatus(order, OrderStatus.pending)}>
                    חידוש עסקה
                </Menu.Item>,
                <Menu.Item
                    key="delivered"
                    disabled={order.status !== OrderStatus.accepted || updateStatus === null}
                    onClick={() => updateStatus(order, OrderStatus.delivered, isOrderDelivered)}>
                    {isOrderDelivered ? "בטלי סימון כסופק" : "סמני כסופק"}
                </Menu.Item>,
                ...(order.paymentType === PaymentType.Card &&
                order.status === OrderStatus.accepted &&
                !isOrderPaid &&
                moment(order.dtstart).isBefore(moment(), "day")
                    ? [
                          <Menu.Item
                              danger={true}
                              key="chargeCreditCard"
                              disabled={order.status !== OrderStatus.accepted}
                              onClick={() => chargeCreditCard(order.eventId)}>
                              חייב אשראי
                          </Menu.Item>
                      ]
                    : []),
                <Menu.Item
                    key="markAsPayed"
                    disabled={updateStatus === null}
                    onClick={() => updateStatus(order, OrderStatus.paid, isOrderPaid)}>
                    {isOrderPaid ? "בטל סימון שולם" : "סמן כשולם"}
                </Menu.Item>,
                ...(onSyncOrder &&
                me?.features?.sync_delivery_order &&
                isOrderHasShippingProvider(order) &&
                isOrderHasValidDeliveryInfo(order)
                    ? [
                          <Menu.Item key="sync" onClick={() => onSyncOrder(order)}>
                              סנכרן הזמנה מול חברת המשלוחים
                          </Menu.Item>
                      ]
                    : []),
                ...(onChangeDeliveryProvider &&
                me?.features?.change_delivery_shipping_provider &&
                isOrderHasShippingProvider(order) &&
                isOrderHasValidDeliveryInfo(order)
                    ? [
                          <Menu.Item key="deivery-provider-change" onClick={() => onChangeDeliveryProvider(order)}>
                              שינוי חברת שילוחים
                          </Menu.Item>
                      ]
                    : [])
            ];
            return (
                <div className="admin-orders-actions-cell">
                    {!order?.subOrders?.length && isAdmin && updateOrder !== null ? (
                        <CommentModal
                            comment={order.adminComment}
                            onSave={async commentText => {
                                const adminComment = commentText
                                    ? {
                                          content: commentText,
                                          author: me.firstName,
                                          lastUpdate: Date.now()
                                      }
                                    : null;

                                const toUpdate = {
                                    eventId: order.eventId,
                                    adminComment
                                };

                                const {error, status} = order.isBudgetTransaction
                                    ? await HttpClient.safePost(
                                          `/admin/api/updateBudgetTransactionAdminComment/${order.eventId}`,
                                          adminComment
                                      )
                                    : await HttpClient.safePost(
                                          `/admin/api/transactions/updateOrder?updatedAt=${order.updatedAt}`,
                                          {
                                              order: toUpdate,
                                              whoToSendUpdateEmail: "none"
                                          }
                                      );

                                if (status === 409) {
                                    message.error(error);
                                    return;
                                }

                                if (error) {
                                    message.error("שגיאה, ההערה על ההזמנה לא נשמרה");
                                    return;
                                }

                                message.success("ההערה על ההזמנה נשמרה בהצלחה");
                                updateOrder(
                                    {
                                        eventId: order.eventId,
                                        adminComment
                                    },
                                    order.paymentType === BUDGET_LOAD_TRANSACTION
                                );
                                onSaveComment();
                            }}
                        />
                    ) : null}
                    {order.v2 ? (
                        <Dropdown
                            overlay={
                                <Menu>
                                    {enableOrderBudgetHandler && me?.features?.orderbudgethandler ? (
                                        <>
                                            <Menu.Item
                                                danger="true"
                                                onClick={async () => {
                                                    await orderBudgetHandlerRequest(order, "+");
                                                }}>
                                                <span>החזרת כסף ללקוח</span>
                                            </Menu.Item>
                                            <Menu.Item
                                                danger="true"
                                                onClick={async () => {
                                                    await orderBudgetHandlerRequest(order, "-");
                                                }}>
                                                <span>לקיחת כסף מהלקוח</span>
                                            </Menu.Item>
                                        </>
                                    ) : null}

                                    <Menu.Item onClick={() => openWindow(`/events/${order.eventId}/orderV2`)}>
                                        צפייה בהזמנה
                                    </Menu.Item>
                                    <Menu.Item
                                        onClick={() => openWindow(`/events/${order.eventId}/orderDeliveryStatus`)}>
                                        צפייה בסטטוס השילוח
                                    </Menu.Item>

                                    {isAdmin || me?.features?.edit_order ? (
                                        <Menu.Item
                                            title={disableEditOrder && !isAdmin ? EDIT_ORDER_ERRORS.CONTACT_CSM : null}
                                            disabled={disableEditOrder || onEdit === null}
                                            key="edit"
                                            onClick={() => onEdit(order)}>
                                            <span>עריכת הזמנה</span>
                                            {disableEditOrder && !isAdmin ? (
                                                <span>
                                                    <Tooltip title={EDIT_ORDER_ERRORS.CONTACT_CSM}>
                                                        <QuestionCircleOutlined style={{cursor: "pointer"}} />
                                                    </Tooltip>
                                                </span>
                                            ) : null}
                                        </Menu.Item>
                                    ) : null}

                                    {!isAdmin ? (
                                        <Menu.Item
                                            disabled={!!order.isVoucherOrder}
                                            key="duplicate"
                                            onClick={() => {
                                                const product = {
                                                    provider: {
                                                        providerId: order.providerId
                                                    },
                                                    service: {
                                                        productId: order.productId
                                                    }
                                                };
                                                const productLinkUrl = createProductLink(product, {
                                                    me,
                                                    services,
                                                    serviceId: order.serviceId
                                                });

                                                const params = new URLSearchParams();
                                                params.append("fromEventId", order.eventId);

                                                openWindow(`${productLinkUrl}/booking?${params.toString()}`);
                                            }}>
                                            שכפל הזמנה
                                        </Menu.Item>
                                    ) : null}
                                    {isAdmin && !order?.subOrders?.length ? adminOptions : null}
                                </Menu>
                            }
                            trigger={["click"]}>
                            <EllipsisOutlined className="admin-orders-order-action-button" />
                        </Dropdown>
                    ) : null}
                </div>
            );
        }
    }
];

async function orderBudgetHandlerRequest(order, operator) {
    const orderRes = await HttpClient.safePost("/admin/api/transactions/orderBudgetHandler", {
        order: {eventId: order.eventId},
        updatedAt: order.updatedAt,
        operator
    });

    if (orderRes?.error) {
        message.error(orderRes.error);
    } else {
        message.success(orderRes);
    }
}

export default columns;
