import React, {useMemo} from "react";
import moment from "moment-timezone";
import {isEmpty} from "lodash";

export const ManualInvoiceStatus = {
    Waiting: "Waiting",
    Approved: "Approved",
    Improve: "Improve",
    Payed: "Payed"
};

export const InvoiceStatus = {
    Received: "Received",
    NotReceived: "NotReceived",
    Late: "Late",
    NoNeed: "NoNeed",
    ...ManualInvoiceStatus
};

export const InvoiceStatusToTextAndColor = {
    [InvoiceStatus.Received]: ["התקבלה", "var(--secondary-color)"],
    [InvoiceStatus.NotReceived]: ["טרם התקבלה", ""],
    [InvoiceStatus.Late]: ["טרם התקבלה", "var(--error-color)"],
    [InvoiceStatus.NoNeed]: ["אין צורך", "var(--standard-text-color)"],
    [InvoiceStatus.Approved]: ["מאושרת", "var(--accept-color)"],
    [InvoiceStatus.Improve]: ["טעון שיפור", "var(--warning-color)"],
    [InvoiceStatus.Waiting]: ["ממתין לאישור", "var(--link-color)"],
    [InvoiceStatus.Payed]: ["נשלחה להדפסה", "var(--standard-text-color)"]
};

export const getInvoiceStatus = (monthlyInvoices, providerLastMonthOrders) => {
    if (!providerLastMonthOrders) {
        return InvoiceStatus.NoNeed;
    }

    const previousMonth = moment().subtract(1, "month").format("MMMM-YY");
    const lastMonthInvoices = monthlyInvoices?.filter(({month}) => month === previousMonth);

    if (!isEmpty(lastMonthInvoices)) {
        const needApproval = lastMonthInvoices.some(
            invoiceObj => isEmpty(invoiceObj.status) || invoiceObj.status === InvoiceStatus.Waiting
        );
        return needApproval ? InvoiceStatus.Waiting : lastMonthInvoices[0].status;
    }

    return moment().date() > 9 ? InvoiceStatus.Late : InvoiceStatus.NotReceived;
};

export const InvoiceStatusLabel = ({monthlyInvoices, providerLastMonthOrders}) => {
    const [text, color] = useMemo(
        () => InvoiceStatusToTextAndColor[getInvoiceStatus(monthlyInvoices, providerLastMonthOrders)],
        [monthlyInvoices]
    );

    return <span style={{color}}>{text}</span>;
};
