import React, {useMemo} from "react";
import {CloseOutlined} from "@ant-design/icons";
import {ServiceIdToName} from "../../../consts.js";
import {FilterOptionIdToName} from "../../../marketplace/ProductsFilterModal/consts";
import {useRequest} from "../../../utils/hooks";
import {SquareButton} from "../../../components";

const titleMapper = {
    extraSearchTexts: filter => `חיפוש טקסט: ${filter}`,
    categories: filter => `קטגוריית ${ServiceIdToName[filter]}`,
    deliveryAddress: filter =>
        `${filter.displayName}${filter.deliveryPrice ? ` (עד ${filter.deliveryPrice} ₪ דמי משלוח)` : ""}`,
    productProperties: filter => FilterOptionIdToName[filter],
    budget: filter => {
        const {participantsAmount, pricePerParticipant} = filter;
        if (
            !isNaN(parseInt(participantsAmount)) &&
            isNaN(parseInt(pricePerParticipant?.min)) &&
            isNaN(parseInt(pricePerParticipant?.max))
        ) {
            return `${participantsAmount} משתתפים`;
        }

        if (
            !isNaN(parseInt(participantsAmount)) &&
            !isNaN(pricePerParticipant.min) &&
            !isNaN(pricePerParticipant.max)
        ) {
            return `${pricePerParticipant.min} - ${pricePerParticipant.max} ₪ למשתתף`;
        } else if (!isNaN(parseInt(participantsAmount)) && !isNaN(pricePerParticipant.min)) {
            return `לפחות ${pricePerParticipant.min} ₪ למשתתף`;
        }
    }
};

const createTiles = (filters, filterKey) => {
    const filtersToAdd = Array.isArray(filters) ? filters : [filters];
    return filtersToAdd
        .filter(() => titleMapper[filterKey])
        .map(filter => ({
            key: `${filterKey}`,
            filter,
            title: titleMapper[filterKey](filter)
        }));
};

export const FilterTiles = ({
    searchFilters = {},
    setSearchFilters,
    selectedFilters,
    setSelectedFilters,
    showSelectPageProducts
}) => {
    const [allSearchTags = []] = useRequest("/admin/api/searchTags", "GET", null, [], true, null, []);

    const searchFilterTiles = Object.entries(searchFilters).reduce(
        (accum, [filterKey, filterValues]) => [...accum, ...createTiles(filterValues, filterKey)],
        []
    );

    const selectedFilterTiles = Object.entries(selectedFilters).reduce(
        (accum, [filterKey, filterValues]) => [...accum, ...createTiles(filterValues, filterKey)],
        []
    );

    const searchedTags = useMemo(() => {
        const tagIds = searchFilters?.searchTags || [];

        return tagIds.map(tagId => allSearchTags.find(tag => tag.tagId === tagId));
    }, [allSearchTags, searchFilters?.searchTags, searchFilters]);

    const tagsFromSelectPage = useMemo(() => {
        const tagIds = selectedFilters?.searchTags || [];

        return tagIds.map(tagId => ({
            ...allSearchTags.find(tag => tag.tagId === tagId),
            selected: true
        }));
    }, [allSearchTags, selectedFilters?.searchTags, selectedFilters]);

    const handleTagClick = (tagId, selected) => {
        setSelectedFilters(oldFilters => {
            const newFilters = {...oldFilters};
            if (!newFilters?.searchTags) newFilters.searchTags = [];
            if (!newFilters.searchTags.includes(tagId)) {
                newFilters.searchTags.push(tagId);
            } else {
                newFilters.searchTags = newFilters.searchTags.filter(tag => tag !== tagId);
            }

            return newFilters;
        });
    };

    const handleFilterClick = (filterKey, filterValue, selected) => {
        setSelectedFilters(oldFilters => {
            const newFilters = {...oldFilters};
            if (!newFilters?.searchTags) newFilters.searchTags = [];
            if (!newFilters?.extraSearchTexts) newFilters.extraSearchTexts = [];
            if (!newFilters?.productProperties) newFilters.productProperties = [];

            if (filterKey == "searchText") {
                if (selected) {
                    newFilters.extraSearchTexts = newFilters.extraSearchTexts.filter(filter => filter !== filterValue);
                    delete newFilters.searchText;
                } else {
                    !newFilters.extraSearchTexts.includes(filterValue) && newFilters.extraSearchTexts.push(filterValue);
                }
            } else if (filterKey == "extraSearchTexts") {
                if (selected) {
                    newFilters.extraSearchTexts = newFilters.extraSearchTexts.filter(filter => filter !== filterValue);
                } else {
                    !newFilters.extraSearchTexts.includes(filterValue) && newFilters.extraSearchTexts.push(filterValue);
                }
            } else if (filterKey == "productProperties") {
                if (selected) {
                    newFilters.productProperties = newFilters.productProperties.filter(
                        filter => filter !== filterValue
                    );
                } else {
                    !newFilters.productProperties.includes(filterValue) &&
                        newFilters.productProperties.push(filterValue);
                }
            } else if (selected) {
                if (Array.isArray(newFilters[filterKey])) {
                    newFilters[filterKey] = newFilters[filterKey].filter(filter => filter !== filterValue);
                } else {
                    delete newFilters[filterKey];
                }
            } else {
                if (Array.isArray(newFilters[filterKey])) {
                    if (newFilters[filterKey].includes(filterValue)) {
                        newFilters[filterKey] = newFilters[filterKey].filter(filter => filter !== filterValue);
                    } else {
                        newFilters[filterKey].push(filterValue);
                    }
                } else {
                    newFilters[filterKey] = filterValue;
                }
            }

            return newFilters;
        });
    };

    const removeFilterFromSearch = (filterKey, filterValue) => {
        setSearchFilters(oldFilters => {
            const newFilters = {...oldFilters};
            if (!newFilters?.searchTags) newFilters.searchTags = [];
            if (!newFilters?.extraSearchTexts) newFilters.extraSearchTexts = [];
            if (!newFilters?.productProperties) newFilters.productProperties = [];

            if (filterKey == "searchText") {
                delete newFilters.searchText;
            } else if (filterKey == "extraSearchTexts") {
                newFilters.extraSearchTexts = newFilters.extraSearchTexts.filter(filter => filter !== filterValue);
            } else if (filterKey == "productProperties") {
                newFilters.productProperties = newFilters.productProperties.filter(filter => filter !== filterValue);
            } else {
                if (Array.isArray(newFilters[filterKey])) {
                    newFilters[filterKey] = newFilters[filterKey].filter(filter => filter !== filterValue);
                } else {
                    delete newFilters[filterKey];
                }
            }

            return newFilters;
        });
    };

    return (
        <div>
            {showSelectPageProducts ? (
                <div className="marketplace-category-filter-option-list" style={{flexWrap: "wrap"}}>
                    <span>פילטרי אתר בחירה</span>
                    {selectedFilterTiles.map(({key, title, filter}) => (
                        <SquareButton
                            style={{backgroundColor: "var(--primary-color)"}}
                            icon={
                                <CloseOutlined
                                    onClick={e => {
                                        handleFilterClick(key, filter, true);
                                        e.stopPropagation();
                                    }}
                                />
                            }
                            key={title}>
                            {title}
                        </SquareButton>
                    ))}
                </div>
            ) : (
                <div className="marketplace-category-filter-option-list">
                    <span>פילטרי חיפוש</span>
                    {searchFilterTiles.map(({key, title, filter}) => (
                        <SquareButton
                            icon={
                                <CloseOutlined
                                    onClick={e => {
                                        removeFilterFromSearch(key, filter);
                                        e.stopPropagation();
                                    }}
                                />
                            }
                            key={title}>
                            {title}
                        </SquareButton>
                    ))}
                </div>
            )}
        </div>
    );
};
