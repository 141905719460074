import {Modal} from "antd";
import {CloseIcon} from "../../../icons";
import React from "react";
import {EventCalendarInviteEmployeesActions} from "./EventCalendarInviteEmployeesActions";
import {Images} from "../../../images/Images";
import {PageSubTitleLabel, PageTitleLabel} from "../../../components/PageTitle";

export const EventCalendarInviteEmployeesModal = ({event, dateMom, visible, onClose}) => {
    return (
        <Modal
            className="wb-modal-booking-confirmation"
            centered={true}
            closable={true}
            bodyStyle={{padding: 0}}
            closeIcon={<CloseIcon fill="white" />}
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            footer={null}
            title={null}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <div
                    style={{
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundImage: `url(${Images.successWide})`,
                        backgroundRepeat: "no-repeat",
                        width: "100%",
                        paddingBottom: "52%"
                    }}
                />
                <PageTitleLabel style={{marginBottom: 20, marginTop: 30}}>
                    Send invitation to all your employees!
                </PageTitleLabel>
                <PageSubTitleLabel style={{marginTop: 0, marginBottom: 30}}>
                    {dateMom.format("dddd, MMMM Do, YYYY [at] HH:mm")}
                </PageSubTitleLabel>
                <EventCalendarInviteEmployeesActions
                    onClose={() => onClose()}
                    eventId={event.eventId}
                    dateMom={dateMom}
                    style={{marginBottom: 20}}
                />
            </div>
        </Modal>
    );
};
