import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ConfigProvider, Input, message} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import copyToClipboard from "copy-to-clipboard";
import {columns} from "./columns";
import {AdminUploadNewProductBundleModal} from "../upload/AdminUploadNewProductBundleModal";
import {RoundedTable, SquareButton} from "../../components";
import {useRequest} from "../../utils/hooks";
import PageTitleHeader from "../../components/PageTitle";
import {HttpClient} from "../../http/HttpClient";
import {openWindow} from "../../http/WindowOpener";
import "./admin-product-bundle.css";
import {parseInt} from "lodash";

export const AdminProductBundles = () => {
    const [productBundlesFromServer, isLoadingProductBundlesFromServer, _, fetchProductBundlesFromServer] =
        useRequest("/admin/api/productBundles");
    const [searchText, setSearchText] = useState("");
    const [productBundles, setProductBundles] = useState(null);
    const [editProductBundle, setEditProductBundle] = useState(null);
    const [newProductBundleVisibility, setProductBundleModalVisibility] = useState(false);

    const changeActiveStatus = useCallback(async (productBundleId, newActiveStatus) => {
        const {error} = await HttpClient.safePost("/admin/api/productBundles/updateField", {
            productBundleId,
            productBundleField: "isActive",
            productBundleValue: newActiveStatus
        });

        if (error) {
            message.error("שינוי סטטוס המוצר המשולב נכשל");
        } else {
            message.success("שינוי סטטוס המוצר בוצע בהצלחה");
        }

        fetchProductBundlesFromServer();
    }, []);

    const openProductBundle = useCallback(productBundleId => {
        openWindow(`/dashboard/services/productBundle/${productBundleId}`);
    }, []);

    const copyProductBundleLink = useCallback(productBundleId => {
        copyToClipboard(`${window.location.origin}/dashboard/services/productBundle/${productBundleId}`);
        message.success("הקישור הועתק בהצלחה");
    }, []);

    const onEditProductBundle = useCallback(
        productBundleIdToEdit => {
            const foundProductBundle = productBundles.find(
                productBundle => productBundle.productBundleId === productBundleIdToEdit
            );

            if (foundProductBundle) {
                foundProductBundle.products = foundProductBundle.productIds.map(productId => ({productId}));
                setEditProductBundle(foundProductBundle);
                setProductBundleModalVisibility(true);
            } else {
                message.error("המוצר המשולב המבוקש לא נמצא");
            }
        },
        [productBundles]
    );

    const onCreateProductBundle = useCallback(() => {
        setEditProductBundle(null);
        setProductBundleModalVisibility(true);
    }, []);

    const deleteProductBundle = useCallback(async productBundleId => {
        const {error} = await HttpClient.safeDelete(`/admin/api/productBundles/${productBundleId}`);

        if (error) {
            message.error("שגיאה! המוצר המשולב לא נמחק בהצלחה");
            return;
        }

        message.success("המוצר נמחק בהצלחה");
        await fetchProductBundlesFromServer();
    }, []);

    const onSaveEditProductBundle = useCallback(async productBundleToEdit => {
        productBundleToEdit.isNewProductBundle = false;

        const {error} = await HttpClient.safePost("/admin/api/productBundles", productBundleToEdit);

        if (error) {
            message.error("שגיאה! המוצר המשולב לא נשמר בהצלחה");
            message.error(error);
            return;
        }

        await fetchProductBundlesFromServer();
        setProductBundleModalVisibility(false);
    }, []);

    const onSaveNewProductBundle = useCallback(async productBundleToCreate => {
        productBundleToCreate.isNewProductBundle = true;

        const {error} = await HttpClient.safePut("/admin/api/productBundles", productBundleToCreate);

        if (error) {
            message.error("שגיאה! המוצר המשולב לא נוצר בהצלחה");
            message.error(error);
            return;
        }

        await fetchProductBundlesFromServer();
        setProductBundleModalVisibility(false);
    }, []);

    const filterProductBundles = useMemo(
        () =>
            productBundles?.filter(productBundle =>
                productBundle.name.toLowerCase().includes(searchText?.toLowerCase() ?? "")
            ),
        [productBundles, searchText]
    );

    useEffect(() => {
        if (productBundlesFromServer) {
            setProductBundles(productBundlesFromServer);
        }
    }, [productBundlesFromServer]);

    const addOrEditProductBundle = useCallback(async productBundleToAddOrEdit => {
        if (productBundleToAddOrEdit.minRecommendedProductParticipants) {
            productBundleToAddOrEdit.minRecommendedProductParticipants = parseInt(productBundleToAddOrEdit?.minRecommendedProductParticipants);
        }
        if (productBundleToAddOrEdit.maxRecommendedProductParticipants) {
            productBundleToAddOrEdit.maxRecommendedProductParticipants = parseInt(productBundleToAddOrEdit?.maxRecommendedProductParticipants);
        }

        if (!productBundleToAddOrEdit?.productBundleId) {
            await onSaveNewProductBundle(productBundleToAddOrEdit)
        } else {
            await onSaveEditProductBundle(productBundleToAddOrEdit)
        }
        setProductBundleModalVisibility(false);
    }, [onSaveEditProductBundle, onSaveNewProductBundle]);


    return (
        <>
            <AdminUploadNewProductBundleModal
                visible={newProductBundleVisibility}
                editProductBundle={editProductBundle}
                onSave={addOrEditProductBundle}
                onCancel={() => {
                    setProductBundleModalVisibility(false);
                    setEditProductBundle(null);
                }}
            />
            <ConfigProvider direction="rtl">
                <div className="admin-product-bundle-container">
                    <PageTitleHeader removeInlineCSS={true} showBack={false}>
                        מוצרים משולבים
                    </PageTitleHeader>

                    <div className="admin-product-bundle-top-bar">
                        <SquareButton onClick={onCreateProductBundle}>יצירת מוצר משולב</SquareButton>
                        <Input
                            className="admin-table-date-search-search-input"
                            onChange={e => setSearchText(e.target.value)}
                            placeholder="חיפוש חופשי"
                            suffix={<SearchOutlined />}
                        />
                    </div>

                    <RoundedTable
                        rowKey="productBundleId"
                        className="admin-select-page-table"
                        scroll={{y: 720}}
                        pagination={false}
                        loading={isLoadingProductBundlesFromServer}
                        dataSource={filterProductBundles}
                        columns={columns(
                            changeActiveStatus,
                            openProductBundle,
                            copyProductBundleLink,
                            onEditProductBundle,
                            deleteProductBundle
                        )}
                    />
                </div>
            </ConfigProvider>
        </>
    );
};
