import React, {useCallback, useEffect, useMemo, useState} from "react";
import moment from "moment";
import {DatePicker} from "antd";
import {FormModalMarketplaceViewAutoComplete} from "../components";
import {ConsumptionOccasionType} from "../../happinessCalculator/consts";
import {FormModal} from "../../components";

const {RangePicker} = DatePicker;

export const CreateConsumptionOccasionModal = ({occasionInfo, onSave, onClose}) => {
    const [newOccasionValues, setNewOccasionValues] = useState();

    const innerOnSave = useCallback(
        ({isLifeCycle, icon, dates, marketplaceViewType, marketplaceView, ...rest}) => {
            const newOccasion = {
                ...occasionInfo,
                ...rest,
                icon: icon?.trim(),
                type: isLifeCycle ? ConsumptionOccasionType.LifeCycle : ConsumptionOccasionType.Holiday,
                startDate: !isLifeCycle ? dates?.[0] : null,
                endDate: !isLifeCycle ? dates?.[1] : null,
                marketplaceView: {
                    viewId: marketplaceView,
                    viewType: marketplaceViewType
                }
            };

            onSave(newOccasion);
        },
        [onSave, occasionInfo]
    );

    useEffect(() => {
        setNewOccasionValues(() => {
            if (!occasionInfo?.occasionId) {
                return occasionInfo;
            }

            const {startDate, endDate, type, marketplaceView, ...rest} = occasionInfo;

            return {
                ...rest,
                isLifeCycle: type === ConsumptionOccasionType.LifeCycle,
                dates: [moment(startDate), moment(endDate)],
                marketplaceView: marketplaceView?.viewId,
                marketplaceViewType: marketplaceView?.viewType
            };
        });
    }, [occasionInfo]);

    return (
        <FormModal
            header={`${occasionInfo?.occasionId ? "עריכת" : "יצירת"} אירוע צריכה`}
            visible={occasionInfo}
            onClose={onClose}
            content={newOccasionValues}
            onUpdate={(_, changedField) => {
                const [key, value] = Object.entries(changedField)[0];
                setNewOccasionValues(prev => ({
                    ...prev,
                    [key]: value
                }));
            }}
            onSave={innerOnSave}
            fields={[
                {
                    name: "name",
                    label: "שם אירוע"
                },
                {
                    name: "icon",
                    label: "אימוג׳י",
                    optional: true,
                    info: "בעת לחיצה על מקש ונידווס ועל נקודה (או CTRL + CMD + רווח במק), יפתח לכם מקלדת אימוג׳ים :)"
                },
                {
                    name: "isLifeCycle",
                    type: "boolean",
                    info: "חיי עובד: לידה, יומהלדת, אונבורדינג. חגים ומועדים: ראש השנה, יום החתול, יום השאבי העולמי",
                    label: "סוג אירוע",
                    checkedChildren: "חיי עובד",
                    unCheckedChildren: "חגים ומועדים"
                },
                {
                    name: "isRecurringEvent",
                    type: "boolean",
                    info: "האם מדובר במועד חד פעמי או אירוע שחוזר מידי שנה באותו התאריך?",
                    label: "אירוע חוזר שנתי?",
                    checkedChildren: "כן",
                    unCheckedChildren: "לא"
                },
                {
                    name: "dates",
                    label: "תאריך האירוע",
                    type: "custom",
                    customComponent: <RangePicker disabled={newOccasionValues?.isLifeCycle} />
                },
                ...FormModalMarketplaceViewAutoComplete({
                    itemType: newOccasionValues?.marketplaceViewType,
                    content: newOccasionValues?.marketplaceView,
                    contentTypeFieldName: "marketplaceViewType",
                    contentFieldName: "marketplaceView",
                    optional: true,
                    updateFormValues: (key, value) => {
                        setNewOccasionValues(prev => ({
                            ...prev,
                            [key]: value
                        }));
                    }
                })
            ]}
        />
    );
};
