import {useCallback, useEffect, useRef, useState} from "react";
import {io} from "socket.io-client";
import {Config, ConfigProps} from "../../Config.js";

const SOCKET_SERVER_URL = Config.env(ConfigProps.SOCKET_SERVER_URL) || window.location.origin;

export const useSocket = room => {
    const socketRef = useRef(null);
    const eventsListenerRef = useRef({});
    const [socketMessages, setSocketMessages] = useState([]);

    useEffect(() => {
        if (room) {
            const consoleTimeLabel = `[${new Date().getTime()}] [SOCKET]`;
            console.time(consoleTimeLabel);
            const socket = io(SOCKET_SERVER_URL, {
                transports: ["websocket"],
                reconnectionAttempts: 5,
                reconnectionDelay: 2000,
                query: {
                    userId: room
                }
            });

            socketRef.current = socket;

            console.timeLog(consoleTimeLabel, {state: "join room"});

            const handleMessage = message => {
                if (message?.eventName) {
                    eventsListenerRef.current[message.eventName]?.(message);
                }
                setSocketMessages(prev => [...prev, message]);
            };

            socket.on("message", handleMessage);
            console.timeLog(consoleTimeLabel, {state: "handle message event"});
            socket.on("connect", () => {
                eventsListenerRef.current["connect"]?.();
            });
            console.timeLog(consoleTimeLabel, {state: "handle connect event"});
            socket.on("connect_error", err => {
                eventsListenerRef.current["connect_error"]?.(err);
            });
            console.timeLog(consoleTimeLabel, {state: "handle connect_error event"});

            console.timeEnd(consoleTimeLabel);

            return () => {
                console.info("debug >> on disconnect from socket");
                socket.emit("leaveRoom", room);
                socket.off("message", handleMessage);
                socket.disconnect();
            };
        }
    }, [room]);

    const listenToEvent = useCallback((eventName, callback) => {
        eventsListenerRef.current[eventName] = callback;
    }, []);

    const unListenToEvent = useCallback(eventName => {
        delete eventsListenerRef.current[eventName];
    }, []);

    return {
        socketMessages,
        listenToEvent,
        unListenToEvent
    };
};
