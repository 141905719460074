import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {partialRight} from "lodash";
import {createProductLink, selectServiceIdFromProduct} from "../MarketplaceHelper.jsx";
import {MarketplaceSearchResults} from "./MarketplaceSearchResults.jsx";
import {AppContext} from "../../AppContext.jsx";
import {translateFilterOption} from "../../admin/upload/AdminUploadUtils.jsx";
import {useRequest} from "../../utils/hooks.jsx";
import {isObjectWithEmptyFields} from "../../utils/objectUtils.jsx";
import {writeProductClickedLog} from "../../Logger.jsx";
import "../marketplace-context-search.css";
import {MarketplaceFilter} from "../MarketplaceFilter/MarketplaceFilter.jsx";
import {Avatar, Divider} from "antd";
import {FilterButtonContainer} from "../MarketplaceFilter/buttonsFilter/FilterButtonContainer.jsx";
import {SearchItemType} from "../MarketplaceFilter/buttonsFilter/consts.js";
import {cloneDeep} from "lodash";
import {UpOutlined} from "@ant-design/icons";

const WELL_B_SEARCH_PAGE = "WELL_B_SEARCH_PAGE";

export const MarketplaceSearchResultsPage = ({history, filterValues, setFilterValues, removeSearchInput, log}) => {
    const {me, services} = useContext(AppContext);
    const [searchSorterType, setSearchSortType] = useState(null);
    const [searchItemType, setSearchItemType] = useState(SearchItemType.products);
    const [searchedProductsContent, setSearchedProductsContent] = useState(null);
    const [productRound, setProductRound] = useState(1);
    const [loadingSearchResult, setLoadingSearchResult] = useState(false);

    useEffect(() => {
        if (searchItemType) {
            switch (searchItemType) {
                case SearchItemType.products:
                    setFilterValues(cloneDeep(filterValues));
            }
        }
    }, [searchItemType]);

    const onSortSelected = useCallback(sortBy => {
        log("Sort option selected", {sortBy});
        setSearchSortType(sortBy);
    }, []);

    const onDisplaySelected = useCallback(displayBy => {
        log("Sort option selected", {displayBy});
        setSearchedProductsContent(null);
        setLoadingSearchResult(true);
        setSearchItemType(displayBy);
    }, []);

    const searchRequestBody = useMemo(
        () => ({
            filters: {
                ...filterValues,
                options: filterValues?.options?.map(translateFilterOption)
            },
            itemType: searchItemType,
            page: productRound,
            ...(searchSorterType ? {sortOption: searchSorterType} : {})
        }),
        [filterValues, productRound, searchSorterType, searchItemType]
    );

    const [searchedContentFromServer, loadingSearchedContent] = useRequest(
        "/api/products/getAllContentByFilters",
        "post",
        searchRequestBody,
        [searchRequestBody],
        !isObjectWithEmptyFields(filterValues)
    );

    useEffect(() => {
        setSearchedProductsContent(current => [...(current ?? []), ...(searchedContentFromServer?.products ?? [])]);
        setLoadingSearchResult(false);
    }, [searchedContentFromServer?.products]);

    useEffect(() => {
        setSearchedProductsContent(null);
        setProductRound(1);
    }, [filterValues, searchSorterType]);

    const onContentClick = useCallback(
        (content, openInNewTab = false) => {
            if (content.service.isProductBundle) {
                const url = `/dashboard/services/productBundle/${content.service.productId}`;
                window.open(url, "_blank", "noreferrer");
                return;
            }

            const serviceId = selectServiceIdFromProduct(me, content, services);
            const url = createProductLink(content, {me, services, serviceId});

            writeProductClickedLog(log, content, serviceId);
            if (openInNewTab) {
                window.open(url, "_blank", "noreferrer");
            } else {
                const yOffset = window.pageYOffset;
                const dataToStore = {
                    yOffset
                };
                window.localStorage.setItem(WELL_B_SEARCH_PAGE, JSON.stringify(dataToStore));
                window.open(url, "_blank", "noreferrer");
            }
        },
        [me, history, filterValues, services]
    );

    const onScrollToTopClick = useCallback(() => {
        window.scrollTo({top: 0});
    }, []);

    useEffect(() => {
        if (searchedContentFromServer) {
            const eventProperties = {
                ...filterValues,
                filterProperties: filterValues,
                searchResultCount: searchedContentFromServer.totalProducts,
                itemType: searchItemType,
                page: productRound,
                ...(searchSorterType ? {sortOption: searchSorterType} : {})
            };
            log("Filter results", eventProperties);
        }
    }, [searchedContentFromServer]);

    const onLastContentReached = useCallback(() => {
        setProductRound(current => current + 1);
    }, [productRound, setProductRound]);

    return (
        <>
            <MarketplaceFilter />
            <div className="marketplace-context-search-search-container">
                <div className="marketplace-category-providers">
                    <FilterButtonContainer
                        onSortSelected={onSortSelected}
                        onDisplaySelected={onDisplaySelected}
                        displaySelected={searchItemType}
                        searchSorterType={searchSorterType}
                        disabledSorterButton={searchItemType !== SearchItemType.products}
                    />
                    <Divider className="marketplace-search-result-divider" />

                    {!isObjectWithEmptyFields(filterValues) && (
                        <MarketplaceSearchResults
                            onContentClick={onContentClick}
                            onNewTab={partialRight(onContentClick, true)}
                            searchedContent={searchedProductsContent}
                            searchSorterType={null}
                            loadingSearchedContent={
                                (loadingSearchedContent && !searchedProductsContent?.length) || loadingSearchResult
                            }
                            searchText={filterValues?.searchText?.[0]}
                            totalResultsAmount={searchedContentFromServer?.totalProducts}
                            onLastContentReached={onLastContentReached}
                            filterValues={filterValues}
                            setFilterValues={setFilterValues}
                            removeSearchInput={removeSearchInput}
                            showFilter={true}
                            skeletonRows={1}
                            itemType={searchItemType}
                        />
                    )}
                </div>
                <Avatar onClick={onScrollToTopClick} className="marketplace-top-jump" size={50} icon={<UpOutlined />} />
            </div>
        </>
    );
};
