import React from "react";
import "./filter-buttons.css";
import {DisplayByOptions} from "./displayButtonsOptionFilter/displayByOptions.jsx";
import {ButtonFilter} from "./displayButtonsOptionFilter/ButtonFilter.jsx";
import {SortOptions} from "./displayButtonsOptionFilter/sortOptions.jsx";
import {SearchItemTypeText, searchSorterTypeText} from "./consts.js";

export const FilterButtons = ({
    onSortSelected,
    searchSorterType,
    showFilter = true,
    showSorter = true,
    showDisplayBy = true,
    onDisplaySelected,
    displaySelected,
    disabledSorterButton = false
}) => {
    return (
        <div className="marketplace-filter-buttons">
            {showSorter && (
                <ButtonFilter
                    menuOption={SortOptions(onSortSelected)}
                    showFilter={showFilter}
                    buttonTypeText={searchSorterTypeText[searchSorterType] ?? "סידור לפי"}
                    disabled={disabledSorterButton}
                />
            )}

            {showDisplayBy && (
                <ButtonFilter
                    menuOption={DisplayByOptions(onDisplaySelected)}
                    showFilter={showFilter}
                    buttonTypeText={SearchItemTypeText[displaySelected] ?? "הצגה לפי"}
                />
            )}
        </div>
    );
};
