import {StyledButton} from "../../components/StyledButton";
import React from "react";
import {Tooltip} from "antd";

export const StepperButton = ({style, onClick, icon, disabled, children, htmlType, loading, canContinueColor}) => {
    const continueColor = canContinueColor || "#13C296";

    return (
        <Tooltip title={disabled ? "Please fill in any missing fields" : null}>
            <StyledButton
                loading={loading}
                htmlType={htmlType}
                style={{
                    height: 50,
                    padding: "11px 20px",
                    backgroundColor: disabled ? "rgba(0,0,0,.06)" : continueColor,
                    borderRadius: 5,
                    color: disabled ? "rgba(0,0,0,.26)" : "white",
                    marginTop: 26,
                    fontSize: 20,
                    ...style
                }}
                onClick={onClick}
                disabled={disabled}
                icon={icon}>
                {children}
            </StyledButton>
        </Tooltip>
    );
};
