import React, {useCallback, useContext, useEffect, useState} from "react";
import {Form, Modal, Input, ConfigProvider, Select} from "antd";
import PageTitleHeader from "../../components/PageTitle";
import {AutoDirectionProvider} from "../../AutoDirectionProvider";
import {AppContext} from "../../AppContext";
import {StyledButton} from "../../components";
import {FormInputV2} from "../../components/form";
import {AutoCompleteAddressFormInput} from "../../utils/AutoCompleteAddress";
import "../marketplace.css";
import "./order-location-single-address-info-modal.css";

const {TextArea} = Input;

export const OrderLocationSingleAddressInfoModal = ({
    content,
    visible,
    onSave,
    onClose,
    isAllowSelectRegularText = true
}) => {
    const {company} = useContext(AppContext);
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState({});

    useEffect(() => {
        if (content && visible) {
            setFormValues(prev => ({
                ...prev,
                ...content
            }));
        }
    }, [content, visible]);

    const updateFormValue = useCallback((field, value) => {
        setFormValues(currentFormValues => ({
            ...currentFormValues,
            [field]: value
        }));
    }, []);

    return (
        <Modal
            centered={true}
            width={501}
            className="wb-modal-radius order-location-single-address-info-modal"
            style={{borderRadius: "5px"}}
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            maskClosable={false}
            footer={null}
            title={null}
            closable={true}>
            <div className="order-location-single-address-info-modal-container">
                <PageTitleHeader className="order-location-single-address-info-modal-title" showBack={false}>
                    שילוח למקבל יחיד 🙋‍️
                </PageTitleHeader>
                <div className="single-delivery-modal-break-line" />
                <p className="single-delivery-modal-subtitle">
                    {"במידה וברצונכם לשלח למקבל יחיד, אנא מלאו את הפרטים הבאים"}
                </p>
                <Form
                    className="order-location-single-address-info-form-container"
                    form={form}
                    onFinish={values => {
                        onSave({...values, placeId: formValues.placeId});
                        form.resetFields();
                        setFormValues({});
                    }}
                    fields={Object.keys(formValues).map(key => {
                        return {
                            name: [key],
                            value: formValues[key]
                        };
                    })}
                    onValuesChange={changedField => {
                        if (changedField?.templateWishes) {
                            updateFormValue("wishes", changedField.templateWishes);
                        } else {
                            const [field, value] = Object.entries(changedField)[0];
                            updateFormValue(field, value);
                        }
                    }}>
                    <AutoDirectionProvider text={"עברית"} className="order-location-single-address-info-container">
                        <div className="order-location-single-address-info-input-container">
                            <label className="order-location-single-address-info-input-title">שם מלא של הנמען</label>
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "שדה חובה"
                                    }
                                ]}>
                                <FormInputV2 />
                            </Form.Item>
                        </div>

                        <div className="order-location-single-address-info-input-container">
                            <label className="order-location-single-address-info-input-title">כתובת</label>
                            <Form.Item
                                name="address"
                                valuePropName="address"
                                rules={[
                                    {
                                        required: true,
                                        message: "שדה חובה"
                                    }
                                ]}>
                                <AutoCompleteAddressFormInput
                                    allowSelectRegularText={isAllowSelectRegularText}
                                    editValue={formValues.address}
                                    onChange={(_trimmedAddress, option) => {
                                        updateFormValue("placeId", option?.place_id);
                                    }}
                                    locationTypes={[]}
                                    className="single-delivery-modal-address-input"
                                    placeholder="כתובת העובד"
                                />
                            </Form.Item>
                        </div>
                        <div className="order-location-single-address-info-input-department-details-container">
                            <div className="order-location-single-address-info-input-container">
                                <label className="order-location-single-address-info-input-title">קומה</label>
                                <Form.Item name="floor">
                                    <FormInputV2 />
                                </Form.Item>
                            </div>
                            <div className="order-location-single-address-info-input-container">
                                <label className="order-location-single-address-info-input-title">דירה</label>
                                <Form.Item name="apartment">
                                    <FormInputV2 />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="order-location-single-address-info-input-container">
                            <label className="order-location-single-address-info-input-title">טלפון</label>
                            <Form.Item
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: "שדה חובה"
                                    },
                                    {
                                        validator: (_, value) => {
                                            if (value && value.length !== 10) {
                                                return Promise.reject("אנא הכניסו מספר המכיל 10 ספרות.");
                                            } else {
                                                return Promise.resolve();
                                            }
                                        }
                                    }
                                ]}>
                                <FormInputV2 type="number" />
                            </Form.Item>
                        </div>

                        {company?.customWishes?.length ? (
                            <div className="order-location-single-address-info-input-container">
                                <label className="order-location-single-address-info-input-title">
                                    ברכה מוכנה מראש
                                </label>
                                <ConfigProvider direction="rtl">
                                    <Form.Item name="templateWishes">
                                        <Select>
                                            {company?.customWishes?.map(option => (
                                                <Option key={option.name} value={option.name}>
                                                    {option.label}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </ConfigProvider>
                            </div>
                        ) : null}

                        <div className="order-location-single-address-info-input-container">
                            <label className="order-location-single-address-info-input-title">ברכה</label>
                            <Form.Item name="wishes">
                                <TextArea autoSize={{minRows: 4, maxRows: 10}} />
                            </Form.Item>
                        </div>
                        <StyledButton className="order-location-single-address-info-save-btn" htmlType="submit">
                            שמירה
                        </StyledButton>
                    </AutoDirectionProvider>
                </Form>
            </div>
        </Modal>
    );
};
