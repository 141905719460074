export class LocalEventsUpdater {
    static massUpdate(events, updatedEvent, originalEvent) {
        let found = false;

        delete updatedEvent.includeDate;
        delete updatedEvent.excludeDate;
        delete updatedEvent.newUntil;

        events = events.reduce((all, evt) => {
            if (evt.status === "cancelled") return all;
            if (evt.eventId === updatedEvent.eventId && updatedEvent.status !== "cancelled") {
                found = true;
                all.push(updatedEvent);
            } else if (evt.eventId === updatedEvent.eventId && updatedEvent.status === "cancelled") {
                return all;
            } else {
                all.push(evt);
            }

            return all;
        }, []);

        if (!found) {
            events.push(updatedEvent);
        }

        if (!originalEvent) {
            return events;
        }

        return events.reduce((all, evt) => {
            if (originalEvent && evt.eventId === originalEvent.eventId) {
                return originalEvent.status === "cancelled" ? all : all.concat([originalEvent]);
            }

            return all.concat([evt]);
        }, []);
    }
}
