import React, {useCallback, useEffect, useMemo, useState} from "react";
import {RoundedTable} from "./RoundedTable";
import {Checkbox, Modal} from "antd";
import {EditOutlined} from "@ant-design/icons";

export const EditableColumnsTable = props => {
    const {name, columns, editButtonLocation} = props;

    const [columnsToShow, setColumnsToShow] = useState([]);
    const [editColumnsModalVisibility, setEditColumnsModalVisibility] = useState(false);

    const checkBoxOptions = useMemo(
        () =>
            columns
                .map(({title, key, dataIndex}) => ({
                    label: title,
                    value: key ?? dataIndex
                }))
                .filter(({value}) => value && value !== "#"),
        [columns]
    );

    const onOk = useCallback(() => {
        localStorage.setItem(name, JSON.stringify(columnsToShow));
        setEditColumnsModalVisibility(false);
    }, [columnsToShow, name]);

    const onCancel = useCallback(() => {
        initializeColumnsToShow();
        setEditColumnsModalVisibility(false);
    }, []);

    const initializeColumnsToShow = useCallback(() => {
        const storedColumnsToShow = localStorage.getItem(name);

        if (storedColumnsToShow) {
            setColumnsToShow(JSON.parse(storedColumnsToShow));
            return;
        }

        if (checkBoxOptions) {
            setColumnsToShow(checkBoxOptions.map(({value}) => value));
        }
    }, [checkBoxOptions, name]);

    useEffect(() => {
        initializeColumnsToShow();
    }, [initializeColumnsToShow]);

    const activeColumns = useMemo(() => {
        const newColumns = columns.filter(({key, dataIndex}) => {
            const columnId = key ?? dataIndex;

            if (!columnId || columnId === "#") {
                return true;
            }

            return columnsToShow.includes(columnId);
        });

        if (editButtonLocation === "firstHeader") {
            newColumns[0].title = <EditOutlined onClick={() => setEditColumnsModalVisibility(true)} />;
        }

        return newColumns;
    }, [columns, columnsToShow, editButtonLocation]);

    return (
        <>
            <Modal open={editColumnsModalVisibility} onOk={onOk} onCancel={onCancel}>
                <Checkbox.Group onChange={setColumnsToShow} value={columnsToShow} options={checkBoxOptions} />
            </Modal>

            <RoundedTable {...props} columns={activeColumns} />
        </>
    );
};
