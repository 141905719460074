import React from "react";
import {EditFilled} from "@ant-design/icons";
import {MarketplaceViewTypeToHebrew} from "../../components/consts";
import {TableDateCell} from "../../../components/Table/TableDateCell";
import {DragHandle} from "../../../components/dragableTable";
import {TrashIcon} from "../../../icons";
import {NOOP} from "../../../utils/NOOP";

export const getColumns = (removeCarouselItem = NOOP, editCarouselItem = NOOP) => [
    {
        title: "#",
        key: "#",
        width: 70,
        render(_, __, idx) {
            return `${idx + 1}`;
        }
    },
    {
        title: "סידור",
        dataIndex: "sort",
        width: 30,
        className: "drag-visible",
        render: () => {
            return <DragHandle />;
        }
    },
    {
        title: "שם מיני קטגורייה",
        dataIndex: "name",
        render(name) {
            return <span dangerouslySetInnerHTML={{__html: name}} />;
        }
    },
    {
        title: "תאריך יצירה",
        dataIndex: "createdAt",
        sorter: ({createdAt: r1}, {createdAt: r2}) => r1 - r2,
        render(createdAt) {
            return <TableDateCell date={createdAt} />;
        }
    },
    {
        title: "תאריך עדכון",
        dataIndex: "updatedAt",
        sorter: ({updatedAt: r1}, {updatedAt: r2}) => r1 - r2,
        render(updatedAt) {
            return <TableDateCell date={updatedAt} />;
        }
    },
    {
        title: "כמות מוצרים",
        dataIndex: "itemsAmount"
    },
    {
        title: "סוג המקבץ",
        dataIndex: "carouselItemType",
        render(carouselItemType) {
            return <span>{MarketplaceViewTypeToHebrew[carouselItemType]}</span>;
        }
    },
    {
        title: "פעולות",
        render(_, {carouselItemId}) {
            return (
                <div className="sub-category-carousel-modal-columns-actions">
                    <TrashIcon style={{fill: "black"}} onClick={() => removeCarouselItem(carouselItemId)} />

                    <EditFilled onClick={() => editCarouselItem(carouselItemId)} />
                </div>
            );
        }
    }
];
