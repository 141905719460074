import React, {useCallback, useContext, useState} from "react";

import "./request-temp-code-login.css";
import {FormInputV2} from "../../components/form/index.jsx";
import {StyledButton} from "../../components/index.jsx";
import {TempCodeContext} from "./TempCodeProvider.jsx";
import {StepperContext} from "../../components/Stepper/StepperContext.jsx";
import {Spin} from "antd";
import {RegisterContainer} from "./RegisterContainer.jsx";

export const RequestTempCode = ({history, log}) => {
    const {onFormValueChange, sendLoginTempCode, formValues} = useContext(TempCodeContext);
    const {nextStep} = useContext(StepperContext);
    const [isLoadingLogin, setIsLoadingLogin] = useState(false);

    const onEmailInputChange = useCallback(e => onFormValueChange("email", e.target.value), [onFormValueChange]);

    const onSendTempCode = useCallback(async () => {
        setIsLoadingLogin(true);
        const isSuccess = await sendLoginTempCode();
        if (isSuccess) {
            nextStep();
        }
        setIsLoadingLogin(false);
    }, [nextStep, sendLoginTempCode]);

    return (
        <div className="request-temp-code-login-container">
            <div className="login-container-input-container">
                <span className="request-temp-code-login-input-title">כתובת מייל המשתמש</span>
                <FormInputV2 value={formValues?.email} onChange={onEmailInputChange} />
            </div>

            <div className="login-button-container">
                <StyledButton className="regular-login-button" onClick={onSendTempCode}>
                    קבלת קוד ב SMS
                </StyledButton>

                <RegisterContainer history={history} log={log} />

                {isLoadingLogin && <Spin className="loading" size="large" />}
            </div>
        </div>
    );
};
