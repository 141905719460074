export const AccountingDocumentType = {
    BILLING_INVOICE: "חשבונית חיוב",
    CREDIT_INVOICE: "חשבונית זיכוי"
};

export const FileSizeLimitInMB = 5;

export const UploadServerURI = "/api/upload/";

export const FormErrorTypes = {
    FILE_UPLOAD: "file_upload",
    INVOICE_AMOUNT_NOT_MATCH: "invoice_amount_not_match"
};
