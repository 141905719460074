import React, {useContext, useMemo} from "react";
import {PricingCalculatorByModular} from "./PricingCalculatorByModular";
import {PricingCalculatorByAmount} from "./PricingCalculatorByAmount";
import {PricingCalculatorByVoucher} from "./PricingCalculatorByVoucher";
import {PricingCalculatorByProductBundle} from "./PricingCalculatorByProductBundle";
import {MarketplaceContext} from "../marketplaceContext";
import {PriceTypes} from "../../admin/upload/PriceSelectorHelper";
import classnames from "classnames";

export const PricingCalculatorComponent = ({
    product,
    onBook,
    onParticipantsChange,
    modularCalculatedPrice,
    PriceCalculatorHeader,
    showModularImages = false,
    log
}) => {
    const {isProductBundle} = useContext(MarketplaceContext);

    const PriceCalculatorBody = useMemo(() => {
        if (isProductBundle) {
            return <PricingCalculatorByProductBundle onBook={onBook} />;
        }

        switch (product?.chosenPriceType) {
            case PriceTypes.ByVoucher: {
                return <PricingCalculatorByVoucher product={product} onBook={onBook} />;
            }
            case PriceTypes.ByModular: {
                return <PricingCalculatorByModular product={product} onBook={onBook} showModularImages={showModularImages} log={log} />;
            }
            default: {
                return (
                    <PricingCalculatorByAmount
                        service={product}
                        onBook={onBook}
                        onParticipantsChange={onParticipantsChange}
                    />
                );
            }
        }
    }, [product, modularCalculatedPrice, isProductBundle, showModularImages, log]);

    return (
        <>
            <div className={classnames("pricing-calculator-container", product?.chosenPriceType)}>
                <div className="pricing-calculator-content-price-by-field">
                    <div className="pricing-calculator-header-background">
                        <div className="pricing-calculator-header">{PriceCalculatorHeader}</div>
                    </div>
                    <div className="pricing-calculator-body">{PriceCalculatorBody}</div>
                </div>
            </div>
        </>
    );
};
