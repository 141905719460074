import React, {useCallback} from "react";

export const RegisterContainer = ({history, log}) => {
    const onRegisterClick = useCallback(() => {
        log("Signup click from login page", {});
        history.push("/company/wizard");
    }, [history]);

    return (
        <div className="login-register-container">
            <span>עדיין אין לכם משתמש?</span>
            <a onClick={onRegisterClick}>להצטרפות</a>
        </div>
    );
};
