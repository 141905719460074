import React, {useMemo} from "react";
import {ConfigProvider, Spin, Tabs} from "antd";
import {useLocation} from "react-router-dom";
import {Redirect, Route, Switch} from "react-router";
import {extractTabFromUrl} from "./DefaultAppDashboard";
import {useRequest} from "../utils/hooks";
import {ProviderContext} from "../provider/ProviderContext";
import {ReadonlyProviderInfoForm} from "../provider/ProviderInfoForm";
import ProviderManageInvoices from "../provider/ProviderManageInvoices";
import {ProviderProducts} from "../provider/ProviderProducts";
import {ProviderProductItemDetails} from "../provider/ProviderItemDetails";
import "./provider-dashboard.css";
import {CartModularProductContextProvider} from "../marketplace/PricingCalculator/CartModularProductContext.jsx";
import {ProviderOrdersWrapper} from "./ProviderOrdersWrapper.jsx";
import {getLogger} from "../Logger.jsx";
import {ResponsiveView} from "../components/index.jsx";
import {Devices} from "../components/ResponsiveView/consts.js";

const {TabPane} = Tabs;

const ProviderDashboard = ({history}) => {
    const [providerDetails, isLoadingProviderDetails] = useRequest("/api/providers/me");
    const location = useLocation();

    const log = useMemo(() => getLogger({}, "ProviderDashboard"), []);

    const activeKey = useMemo(() => {
        const routes = ["settings", "products", "orders", "invoices"];

        return extractTabFromUrl(RegExp(`^\/dashboard\/(${routes.join("|")})(.*)$`));
    }, [location.pathname]);

    return !activeKey ? (
        <Redirect to="/dashboard/orders" />
    ) : (
        <ConfigProvider direction="rtl">
            <ProviderContext.Provider value={{providerDetails, log}}>
                <Switch>
                    <CartModularProductContextProvider>
                        <Route exact={true} path="/dashboard/settings" component={ReadonlyProviderInfoForm} />
                        <Route
                            exact={true}
                            path="/dashboard/products"
                            component={({history}) =>
                                isLoadingProviderDetails ? <Spin /> : <ProviderProducts history={history} />
                            }
                        />

                        <Route
                            exact={true}
                            path="/dashboard/products/:productId"
                            component={ProviderProductItemDetails}
                        />
                        <Route exact={true} path="/dashboard/orders" component={ProviderOrdersWrapper} />
                        <Route exact={true} path="/dashboard/invoices" component={ProviderManageInvoices} />
                    </CartModularProductContextProvider>
                </Switch>

                <ResponsiveView devices={[Devices.desktop]}>
                    <Tabs
                        activeKey={activeKey}
                        onTabClick={key => history.push(`/dashboard/${key}`)}
                        centered={true}
                        className="provider-dashboard-tabs">
                        <TabPane tab="פרטי בית העסק" key="settings" />
                        <TabPane tab="מוצרים פעילים" key="products" />
                        <TabPane tab="ניהול הזמנות" key="orders" />
                        <TabPane tab="העלאת חשבונית" key="invoices" />
                    </Tabs>
                </ResponsiveView>
            </ProviderContext.Provider>
        </ConfigProvider>
    );
};

export default ProviderDashboard;
