import React, {useReducer, useEffect, useContext} from "react";
import {Spin, message} from "antd";
import {HttpClient} from "../http/HttpClient";
import {Calendar} from "../components/Calendar";
import {PageTitleLabel} from "../components/PageTitle";
import {EventMapper} from "../event/EventMapper";
import EventInfoModal from "../event/EventInfoModal";
// import randomcolor from "randomcolor";
import {EventBus} from "../bus/EventBus";
import {GoogleAnalytics} from "../GoogleAnalytics";
import ProviderEventInfo from "./ProviderEventInfo";
import ProviderEventAppointments from "./ProviderEventAppointments";
import {AppContext} from "../AppContext";
import ProviderEventAppointment from "./ProviderEventAppointment";
import {Route, Switch} from "react-router";
import {dateFormat} from "../utils/DateFormat";
import isMobileDevice from "is-mobile";
import {LocalEventsUpdater} from "../event/LocalEventsUpdater";

export const ProviderSchedule = ({history}) => {
    const {me} = useContext(AppContext);
    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        events: null,
        clickedEvent: {},
        eventInstance: {},
        updating: false,
        creating: false,
        appointmentToEdit: null
    });

    const {updating, creating} = state;

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                let events = await HttpClient.get("/api/events");
                events = events.map(evt => ({
                    ...evt,
                    backgroundColor:
                        evt.backgroundColor ||
                        randomcolor({
                            luminosity: "dark"
                        })
                }));
                setState({events});
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we didn't manage to preset your schedule :("}},
                    e.message
                );
            }
        });
    }, []);

    const onEventClick = (clickedEvent, eventInstance) => {
        if (clickedEvent.status === "pending") {
            GoogleAnalytics.event("Professional Schedule", "View Proposal", clickedEvent.eventId);
            history.push(`/events/${clickedEvent.eventId}/proposal`);
        } else if (clickedEvent.status !== "cancel-pending") {
            GoogleAnalytics.event("Professional Schedule", "Session Info", clickedEvent.eventId);
            setState({clickedEvent, eventInstance});
            if (clickedEvent.appointmentBased) {
                goToEventInfo(clickedEvent.eventId, eventInstance.start, eventInstance.end);
            }
        }
    };

    const onEventUpdate = updatedEvt => {
        const {events} = state;
        setState({
            clickedEvent: {},
            eventInstance: {},
            events: LocalEventsUpdater.massUpdate(events, updatedEvt)
        });
    };

    const updateAppointment = async appointment => {
        setState({updating: true});
        try {
            await HttpClient.post(`/api/appointments/${appointment.appointmentId}`, appointment);
            if (me.type === "employee") {
                GoogleAnalytics.event("Employee Booking", "Update Appointment", appointment.appointmentId);
            } else {
                GoogleAnalytics.event("Professional Booking", "Update Appointment", appointment.appointmentId);
            }
            history.goBack();
        } catch (e) {
            if (e.statusCode === 400) {
                EventBus.triggerError(
                    "server-error",
                    {
                        content: {
                            title: "Ohh...",
                            hideSubTitle: true,
                            description: e.message,
                            hideSteps: true
                        },
                        cta: {
                            hide: true
                        }
                    },
                    e.message
                );
            } else {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we couldn't update this appointment :("}},
                    e.message
                );
            }
        }
        setState({updating: false});
    };

    const createAppointment = async appointment => {
        setState({creating: true});
        try {
            await HttpClient.put(`/api/events/${appointment.eventId}/appointments`, appointment);
            GoogleAnalytics.event("Professional Booking", "Create Appointment", appointment.eventId);
            history.goBack();
        } catch (e) {
            if (e.statusCode === 400) {
                EventBus.triggerError(
                    "server-error",
                    {
                        content: {
                            title: "Ohh...",
                            hideSubTitle: true,
                            description: e.message,
                            hideSteps: true
                        },
                        cta: {
                            hide: true
                        }
                    },
                    e.message
                );
            } else {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we couldn't update this appointment :("}},
                    e.message
                );
            }
        }
        setState({creating: false});
    };

    const goToEventInfo = (eventId, start, end) => {
        history.push(`/dashboard/schedule/events/${eventId}/${dateFormat(start)}/${dateFormat(end)}`);
    };

    const goToAppointmentEdit = (eventId, appointmentId, start, end) => {
        history.push(
            `/dashboard/schedule/events/${eventId}/appointments/${appointmentId}/${dateFormat(start)}/${dateFormat(
                end
            )}/edit`
        );
    };

    const goToAppointments = (eventId, start, end) => {
        GoogleAnalytics.event("Professional Booking", "View Appointments", eventId);
        history.push(`/dashboard/schedule/events/${eventId}/appointments/${dateFormat(start)}/${dateFormat(end)}`);
    };

    const goToNewAppointment = (eventId, start, end, defaultTime) => {
        history.push(
            `/dashboard/schedule/events/${eventId}/appointments/${dateFormat(start)}/${dateFormat(end)}/new/${
                defaultTime.startTime
            }/${defaultTime.endTime}`
        );
    };

    const getClickedEvent = () => {
        return clickedEvent && clickedEvent.eventId ? clickedEvent : null;
    };

    const companyName = evt => {
        const statusMap = {
            accepted: evt.companyName,
            pending: "Pending approval",
            "cancel-pending": "Pending cancellation"
        };

        return statusMap[evt.status];
    };

    const tooltipText = evt => {
        const statusMap = {
            accepted: "Click to join",
            pending: "Pending your approval, please click to accept or decline",
            "cancel-pending": "Pending cancellation"
        };

        return statusMap[evt.status];
    };

    const {events, clickedEvent, eventInstance, appointmentToEdit} = state;

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: isMobileDevice() ? 0 : 20,
                paddingLeft: 0,
                paddingRight: 0,
                width: "100%"
            }}>
            {clickedEvent && clickedEvent.appointmentBased ? null : (
                <EventInfoModal
                    event={clickedEvent}
                    currentStart={eventInstance.start}
                    currentEnd={eventInstance.end}
                    onClose={updatedEvt => onEventUpdate(updatedEvt)}
                    visible={!!clickedEvent.eventId}
                />
            )}
            {events === null ? (
                <Spin size="large" style={{marginTop: 80}} />
            ) : (
                <Switch>
                    <Route
                        exact
                        path="/dashboard/schedule"
                        render={() => (
                            <>
                                <PageTitleLabel>Your monthly activities</PageTitleLabel>
                                <Calendar
                                    pendingCursor="pointer"
                                    events={events.reduce((allEvts, evt) => {
                                        return allEvts.concat(
                                            EventMapper.reduce({
                                                event: evt,
                                                title: `${evt.serviceName || evt.sessionName}\n${companyName(evt)}`,
                                                tooltipText: tooltipText(evt)
                                            })
                                        );
                                    }, [])}
                                    onEventClick={(event, eventInstance) => onEventClick(event, eventInstance)}
                                />
                            </>
                        )}
                    />
                    <Route
                        exact
                        path="/dashboard/schedule/events/:eventId/:start/:end"
                        render={() => (
                            <ProviderEventInfo
                                eventInfo={getClickedEvent()}
                                onViewAppointments={(eventId, start, end) => {
                                    goToAppointments(eventId, start, end);
                                }}
                                onUpdate={onEventUpdate}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/dashboard/schedule/events/:eventId/appointments/:start/:end"
                        render={() => (
                            <ProviderEventAppointments
                                eventInfo={getClickedEvent()}
                                onEdit={(appointmentToEdit, start, end) => {
                                    goToAppointmentEdit(
                                        appointmentToEdit.eventId,
                                        appointmentToEdit.appointmentId,
                                        start,
                                        end
                                    );
                                }}
                                onNew={(eventId, start, end, defaultTime) => {
                                    goToNewAppointment(eventId, start, end, defaultTime);
                                }}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/dashboard/schedule/events/:eventId/appointments/:start/:end/new/:startTime/:endTime"
                        render={() => (
                            <ProviderEventAppointment
                                me={me}
                                eventInfo={getClickedEvent()}
                                onSave={appointment => createAppointment(appointment)}
                                saving={creating}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/dashboard/schedule/events/:eventId/appointments/:appointmentId/:start/:end/edit"
                        render={() => (
                            <ProviderEventAppointment
                                me={me}
                                eventInfo={getClickedEvent()}
                                appointmentToEdit={appointmentToEdit}
                                onSave={appointment => updateAppointment(appointment)}
                                saving={updating}
                            />
                        )}
                    />
                </Switch>
            )}
        </div>
    );
};
