import {ModalTitle} from "../components/ModalTitle";
import {Form, Input, Select, Tooltip, Typography, DatePicker} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import CallToActionModal from "../components/CallToActionModal";
import React, {useState, useContext, useEffect} from "react";
import {FormInput, FormTextArea} from "../components/form";
import {StyledButton} from "../components/StyledButton";
import moment from "moment-timezone";
import {AsiaJerusalemTZ, DaysMapper} from "./DaysMapper";
import {FrequencyMapper} from "./FrequencyMapper";
import {SearchableSelect} from "../components/SearchableSelect";
import {locationKeyToTextMap, LocationLabels} from "../data/locations";
import {CustomTimePicker} from "../components/CustomTimePicker";
import {SYSTEM_DEFAULT_START_TIME} from "../data/defaults";
import {dateFormat} from "../utils/DateFormat";
import {AppContext} from "../AppContext";
import {DurationInput} from "../components/DurationInput";
import {PriceType} from "../provider/ProviderServices";
import {PriceTypeSelect} from "../components/PriceTypeSelect";
import {CompanyBranchSelect, hasBranches} from "../company/CompanyBranchSelect";
import {ZoomLinkInput} from "../zoom/ZoomLinkInput";

const {Option} = Select;
const {useForm} = Form;

export const CostFormItem = ({priceType, onPriceTypeChange}) => {
    return (
        <div style={{display: "flex", alignItems: "flex-start", width: "100%"}}>
            <Form.Item
                name="offeredPrice"
                style={{width: "100%"}}
                rules={[
                    {
                        validator: (_, value) => {
                            if (value && isNaN(value)) {
                                return Promise.reject("Please enter valid cost.");
                            } else if (value && value < 0) {
                                return Promise.reject("Please enter positive cost.");
                            } else if (value && value % 1 !== 0) {
                                return Promise.reject("Please enter a whole number.");
                            } else {
                                return Promise.resolve();
                            }
                        }
                    }
                ]}>
                <FormInput
                    type="number"
                    placeholder="Cost (optional)"
                    className="wb-input-with-postfix-and-prefix"
                    addonBefore=" ₪ "
                    addonAfter={<PriceTypeSelect value={priceType} onSelect={type => onPriceTypeChange(type)} />}
                />
            </Form.Item>
            <Tooltip title='Enter your cost of one single session, so it can be paid easily trough the platform together with your other payments. You will always be able to edit your monthly cost through your "Billing" section.'>
                <InfoCircleOutlined
                    style={{color: "rgba(0, 0, 0, 0.65)", fontSize: 18, marginLeft: 10, marginTop: 16}}
                />
            </Tooltip>
        </div>
    );
};

const SessionForm = ({
    form,
    date,
    week,
    tzid,
    saving,
    startTime,
    onStartTimeChange,
    employees,
    sessionType,
    onSave,
    onSessionTypeChange,
    location,
    onLocationChange
}) => {
    const [internalDateMom, setInternalDateMom] = useState(date);
    const [priceType, setPriceType] = useState(PriceType.GROUP);
    const [durationType, setDurationType] = useState(DurationInput.Type.MIN);
    const {theme, me, company} = useContext(AppContext);

    const dateTitle = () => {
        try {
            if (date) {
                return moment(date).format("MMMM Do, dddd");
            } else if (week) {
                return `${moment(week.start).format("MMMM Do")} - ${moment(week.end).format("MMMM Do")}`;
            }
        } catch (ignore) {}
        return null;
    };

    const toByWeekday = () => {
        if (date) {
            return DaysMapper.mapDayToKey(moment(dateFormat(date), "YYYY-M-D").format("dddd"));
        } else if (internalDateMom) {
            return internalDateMom;
        } else {
            return void 0;
        }
    };

    const today = dateTitle();

    return (
        <div style={{display: "flex", flexDirection: "column"}} onClick={e => e.preventDefault()}>
            {today ? (
                <Typography.Text style={{textAlign: "center", fontSize: 16, color: "#767F90"}}>
                    {dateTitle()}
                </Typography.Text>
            ) : null}
            <Form
                scrollToFirstError={true}
                form={form}
                initialValues={{
                    startTime: moment(SYSTEM_DEFAULT_START_TIME, "HH:mm"),
                    sessionType: "employee",
                    location: "onsite"
                }}
                fields={[
                    {name: ["byweekday"], value: toByWeekday()},
                    {name: ["startTime"], value: startTime},
                    {name: ["sessionType"], value: sessionType},
                    {name: ["location"], value: location}
                ]}
                onFinish={values =>
                    onSave({
                        ...values,
                        priceType,
                        durationType,
                        offeredPrice: values.offeredPrice ? values.offeredPrice | 0 : null
                    })
                }>
                <div style={{margin: 20}}>
                    <Input.Group style={{display: "flex"}}>
                        <Form.Item
                            style={{width: 155}}
                            name="startTime"
                            rules={[{type: "object", required: true, message: "Please choose time."}]}>
                            <CustomTimePicker
                                style={{
                                    width: "100%"
                                }}
                                onChange={startTime => onStartTimeChange(startTime)}
                                minuteStep={15}
                                placeholder="Time"
                            />
                        </Form.Item>
                        <Form.Item
                            style={{marginLeft: 10, width: 155}}
                            name="duration"
                            validateFirst={true}
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter duration."
                                },
                                {
                                    validator: (_, value) => {
                                        if (!isNaN(value) && value <= 0) {
                                            return Promise.reject("Please enter positive duration.");
                                        }

                                        return Promise.resolve();
                                    }
                                }
                            ]}>
                            <DurationInput
                                durationType={durationType}
                                onDurationTypeChange={val => setDurationType(val)}
                            />
                        </Form.Item>
                    </Input.Group>
                    <Input.Group style={{display: "flex"}}>
                        {today ? (
                            <Form.Item
                                style={{marginBottom: 0, width: 155}}
                                name="byweekday"
                                rules={[{required: true, message: "Please choose day."}]}>
                                <Select disabled={!!date} placeholder="Day">
                                    {tzid === AsiaJerusalemTZ ? <Option value="SU">Sunday</Option> : null}
                                    <Option value="MO">Monday</Option>
                                    <Option value="TU">Tuesday</Option>
                                    <Option value="WE">Wednesday</Option>
                                    <Option value="TH">Thursday</Option>
                                    <Option value="FR">Friday</Option>
                                    <Option value="SA">Saturday</Option>
                                    {tzid !== AsiaJerusalemTZ ? <Option value="SU">Sunday</Option> : null}
                                </Select>
                            </Form.Item>
                        ) : (
                            <Form.Item
                                style={{marginBottom: 0, width: 155}}
                                name="byweekday"
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (!value || !moment.isMoment(value) || !moment(value).isValid()) {
                                                return Promise.reject("Please choose date.");
                                            }

                                            return Promise.resolve();
                                        }
                                    }
                                ]}>
                                <DatePicker
                                    inputReadOnly={true}
                                    className="wb-date-picker"
                                    format="MMM Do, YYYY"
                                    onChange={dateMom => setInternalDateMom(dateMom)}
                                    disabledDate={currentDate =>
                                        currentDate.isBefore(moment(Date.now()).subtract(1, "days"))
                                    }
                                    placeholder="Date"
                                />
                            </Form.Item>
                        )}
                        <Form.Item
                            style={{marginBottom: 0, marginLeft: 10, width: 155}}
                            name="freq"
                            rules={[{required: true, message: "Choose repetition."}]}>
                            <Select placeholder="Repetition">
                                <Option value="once">Just Once</Option>
                                <Option value="daily">Every Day</Option>
                                <Option value="weekly">Every Week</Option>
                                <Option value="2-weekly">Every 2 Weeks</Option>
                                <Option value="monthly">Every Month</Option>
                            </Select>
                        </Form.Item>
                    </Input.Group>
                </div>
                <div style={{margin: 20}}>
                    <Form.Item name="sessionName" rules={[{required: true, message: "Please enter session's name."}]}>
                        <FormInput placeholder="Session name" />
                    </Form.Item>
                    {hasBranches(me, company) ? (
                        <Form.Item
                            name="branches"
                            rules={[
                                {
                                    required: true,
                                    type: "array",
                                    message: "Please select site."
                                }
                            ]}>
                            <CompanyBranchSelect mode="tags" branches={company.branches} />
                        </Form.Item>
                    ) : null}
                    <CostFormItem priceType={priceType} onPriceTypeChange={type => setPriceType(type)} />
                    <Form.Item name="sessionType" rules={[{required: true, message: "Please select session host."}]}>
                        <Select onSelect={sessionType => onSessionTypeChange(sessionType)} placeholder="Session type">
                            <Option value="employee">By employee</Option>
                            <Option value="provider">By professional</Option>
                            <Option value="company">By the company</Option>
                        </Select>
                    </Form.Item>
                    {sessionType === "provider" ? (
                        <div>
                            <Input.Group style={{display: "flex"}}>
                                <Form.Item
                                    style={{width: 155}}
                                    name="providerFirstName"
                                    rules={[{required: true, message: "Please enter first name."}]}>
                                    <FormInput style={{textAlign: "left"}} placeholder="First name" />
                                </Form.Item>
                                <Form.Item
                                    style={{marginLeft: 10, width: 155}}
                                    name="providerLastName"
                                    rules={[{required: true, message: "Please enter last name."}]}>
                                    <FormInput style={{textAlign: "left"}} placeholder="Last name" />
                                </Form.Item>
                            </Input.Group>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter professional's email."
                                    },
                                    {
                                        type: "email",
                                        message: "Please enter valid email."
                                    }
                                ]}>
                                <FormInput placeholder="Professional's email" />
                            </Form.Item>
                        </div>
                    ) : sessionType === "employee" ? (
                        <Form.Item name="employeeId" rules={[{required: true, message: "Please select session host."}]}>
                            <SearchableSelect placeholder="Session's host">
                                {employees.map(emp => (
                                    <Option
                                        key={`emp-${emp.userId}`}
                                        value={emp.userId}>{`${emp.firstName} ${emp.lastName}`}</Option>
                                ))}
                            </SearchableSelect>
                        </Form.Item>
                    ) : null}
                    <Form.Item
                        name="location"
                        rules={[
                            {
                                required: true,
                                message: "Where will it happen?"
                            }
                        ]}>
                        <Select onSelect={value => onLocationChange(value)} style={{width: "100%"}} placeholder="Where">
                            <Option value={LocationLabels.ONSITE}>{locationKeyToTextMap[LocationLabels.ONSITE]}</Option>
                            <Option value={LocationLabels.ONLINE}>{locationKeyToTextMap[LocationLabels.ONLINE]}</Option>
                            <Option value={LocationLabels.OFFSITE}>
                                {locationKeyToTextMap[LocationLabels.OFFSITE]}
                            </Option>
                        </Select>
                    </Form.Item>
                    {location === LocationLabels.ONSITE || location === LocationLabels.OFFSITE ? (
                        <Form.Item name="address">
                            <FormInput placeholder="Address / Room / Floor number" />
                        </Form.Item>
                    ) : (
                        <Form.Item
                            name="meetingLink"
                            rules={[{type: "url", message: "Please enter valid meeting link."}]}>
                            <ZoomLinkInput placeholder="Insert meeting link" recurring={true} />
                        </Form.Item>
                    )}
                    <Form.Item style={{marginBottom: 0}} name="notes">
                        <FormTextArea
                            autoSize={{minRows: 3, maxRows: 3}}
                            placeholder="If you have anything to add or clarify about your request, please write it here"
                        />
                    </Form.Item>
                </div>
                <Form.Item>
                    <div style={{display: "flex", justifyContent: "flex-end", marginLeft: 20, marginRight: 20}}>
                        <StyledButton
                            loading={saving}
                            onClick={() => form.submit()}
                            style={{
                                width: 155,
                                backgroundColor: theme.primary,
                                color: theme.textPrimary,
                                borderRadius: 25,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            Save session
                        </StyledButton>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};

export const EventCustomSessionModal = ({visible, date, week, onSave, onClose, employees}) => {
    const [saving, setSaving] = useState(false);
    const [sessionType, setSessionType] = useState("employee");
    const [startTime, setStartTime] = useState(moment(SYSTEM_DEFAULT_START_TIME, "HH:mm"));
    const [location, setLocation] = useState(LocationLabels.ONSITE);
    const [form] = useForm();

    const tzid = moment.tz.guess();

    const Title = () => (
        <ModalTitle
            title="Book a new session"
            titleStyle={{color: "#27344", fontSize: 20, textAlign: "center", marginTop: 35, marginBottom: 0}}
            subtitleStyle={{marginBottom: 0}}
        />
    );

    const save = async values => {
        let dtstart;

        if (date) {
            dtstart = moment(
                `${moment(date).format("YYYY-MM-DD")} ${values.startTime.format("HH:mm")}`,
                "YYYY-MM-DD HH:mm"
            )
                .toDate()
                .getTime();
        } else if (week) {
            const day = moment(week.start).day(DaysMapper.mapKeyToDay(values.byweekday));
            dtstart = moment(`${day.format("YYYY-MM-DD")} ${values.startTime.format("HH:mm")}`, "YYYY-MM-DD HH:mm")
                .toDate()
                .getTime();
        } else {
            dtstart = moment(
                `${values.byweekday.format("YYYY-MM-DD")} ${values.startTime.format("HH:mm")}`,
                "YYYY-MM-DD HH:mm"
            )
                .toDate()
                .getTime();
            const day = values.byweekday.format("dddd");
            values.byweekday = DaysMapper.mapDayToKey(day);
        }

        values.duration = parseInt(values.duration, 10);
        const info = FrequencyMapper.map({
            freq: values.freq,
            interval: 1,
            byweekday: [values.byweekday],
            tzid,
            dtstart,
            duration: values.duration
        });
        values.byweekday = info.byweekday;
        values.until = info.until;
        values.interval = info.interval;
        values.freq = info.freq;
        values.tzid = tzid;

        setSaving(true);
        const success = await onSave({...values, dtstart});
        setSaving(false);
        if (success) {
            closeModal();
        }
    };

    const closeModal = () => {
        setLocation("onsite");
        setSessionType("employee");
        setStartTime(moment(SYSTEM_DEFAULT_START_TIME, "HH:mm"));
        form.resetFields();
        onClose();
    };

    return (
        <CallToActionModal
            title={<Title />}
            subTitle={
                <SessionForm
                    form={form}
                    date={date}
                    week={week}
                    tzid={tzid}
                    saving={saving}
                    startTime={startTime}
                    onStartTimeChange={startTime => setStartTime(startTime)}
                    location={location}
                    onLocationChange={location => setLocation(location)}
                    sessionType={sessionType}
                    onSessionTypeChange={sessionType => setSessionType(sessionType)}
                    onSave={values => save(values)}
                    employees={employees}
                />
            }
            closable={true}
            onClose={closeModal}
            subTitleStyle={{padding: 0}}
            modalStyle={{maxWidth: 360, top: 20}}
            visible={visible}
        />
    );
};
