import React, {useCallback} from "react";
import {Checkbox, Col, ConfigProvider, Row, Tooltip} from "antd";
import "./checkbox-group-options.css";

export const CheckboxGroupOptions = ({title, options, onChange, value, span = 10}) => {
    const onChooseOption = useCallback(
        selectedOptions => {
            onChange(selectedOptions);
        },
        [onChange]
    );

    return (
        <div id="checkbox-group-options">
            <ConfigProvider direction="rtl">
                <div>
                    {title && <span className="title">{title}</span>}
                    <Checkbox.Group value={value ?? []} className="food-options-container" onChange={onChooseOption}>
                        <Row>
                            {options.map((option, index) => {
                                return (
                                    <Col key={index} span={span} className="checkbox-container">
                                        <Checkbox className="checkbox-label" key={index} value={option.value}>
                                            <Tooltip placement="left" title={option?.tooltip}>
                                                {option.label}
                                            </Tooltip>
                                        </Checkbox>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Checkbox.Group>
                </div>
            </ConfigProvider>
        </div>
    );
};
