import React, {useCallback, useMemo} from "react";
import {Card, Dropdown} from "antd";
import {ProgressiveImageLoader} from "../../components/index.jsx";
import "./marketplace-provider-item-card.css";
import cover_placeholder from "../../images/cover_placeholder.jpg";
import {getProviderFoodOptions, getProviderKosherOptions} from "./utils.js";

export const MarketplaceProviderItem = ({content, log}) => {
    const providerFoodOptionTitle = useMemo(() => getProviderFoodOptions(content?.service?.foodRequests), [content]);

    const kosher = useMemo(() => getProviderKosherOptions(content?.service?.productKosherType), [content]);

    const onClick = useCallback(() => {
        window.open(`/dashboard/services/providerPage/${content?.provider?.providerId}`, "_blank");
        log("provider card clicked", {
            providerId: content?.provider?.providerId,
            providerBrandName: content?.service?.brandName,
            providerBusinessName: content?.provider?.businessName
        });
    }, [content, log]);

    return (
        <Dropdown
            key={"dropdown-provider-item"}
            menu={{
                items: [
                    {
                        label: "פתח בכרטיסיה חדשה",
                        key: "newTab",
                        onClick: onClick
                    }
                ]
            }}
            trigger={["contextMenu"]}>
            <Card
                bordered={true}
                hoverable={true}
                onClick={onClick}
                bodyStyle={{paddingTop: 10, borderRadius: "5px"}}
                className="marketplace-product-card marketplace-provider-card"
                cover={
                    <div className="marketplace-provider-item-container">
                        <div className="marketplace-provider-header-container">
                            <div className="marketplace-provider-img">
                                <ProgressiveImageLoader
                                    maxWidth={180}
                                    className={"marketplace-product-card-smart-image"}
                                    src={content?.provider?.imageUrl ?? cover_placeholder}
                                />
                            </div>
                            <div className="provider-name-title">
                                {content?.service?.brandName || content?.provider?.businessName}
                            </div>
                        </div>

                        <div className="marketplace-provider-content-container">
                            {kosher && <div className="provider-kosher-title">{kosher}</div>}
                            {providerFoodOptionTitle && (
                                <div className="provider-kosher-title">{providerFoodOptionTitle}</div>
                            )}
                        </div>
                    </div>
                }
            />
        </Dropdown>
    );
};
