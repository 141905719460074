import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {message} from "antd";
import {set} from "lodash";
import {CompanyInvoiceManageModal} from "./CompanyInvoiceManageModal";
import {CompanyInvoiceManageForm} from "./CompanyInvoiceManageForm";
import {AppContext} from "../../../AppContext";
import {HttpClient} from "../../../http/HttpClient";
import {NOOP} from "../../../utils/NOOP";
import {SeparatorLine} from "../../../components/SeparatorLine";
import "./company-invoice-manage.css";

export const CompanyInvoiceManage = ({showAsModal = false, visible, onSave = NOOP, onClose = NOOP}) => {
    const {me, company} = useContext(AppContext);
    const [financeInfo, setFinanceInfo] = useState({});

    const onUpdateForm = useCallback((_allValues, changedField) => {
        const [fieldKey, fieldValue] = Object.entries(changedField)[0];
        setFinanceInfo(currentFinanceValues => set({...currentFinanceValues}, fieldKey, fieldValue));
    }, []);

    const updateCompanyFinanceInfo = useCallback(
        async formValues => {
            const newCompanyFinanceData = {
                businessNumber: formValues?.businessNumber,
                financeContact: {
                    name: formValues?.name,
                    email: formValues?.email,
                    phone: formValues?.phone,
                    companyNameForInvoice: formValues?.companyNameForInvoice
                },
                ...(me?.isCompanyMaster
                    ? {
                          requirePONumber: !!formValues?.requirePONumber,
                          separateSubAdminInvoices: !!formValues?.separateSubAdminInvoices
                      }
                    : {})
            };

            const updateCompanyFinanceContactRequest = await HttpClient.safePost(
                "/api/companies/updateCompanyFinanceContact",
                newCompanyFinanceData
            );

            if (updateCompanyFinanceContactRequest?.error) {
                message.error("שגיאה בעת עידכון המידע החשבונאי של החברה");
            } else {
                message.success("המידע החשבונאי עודכן בהצלחה");
            }
        },
        [me?.isCompanyMaster]
    );

    const formFields = useMemo(
        () => [
            {
                name: "sectionA",
                type: "multi",
                inputs: [
                    {
                        name: "companyNameForInvoice",
                        label: "שם לחשבונית",
                        info: "השם לרישום על החשבונית המונפקת לפי רשם החברות"
                    },
                    {
                        label: "ח.פ",
                        name: "businessNumber"
                    }
                ]
            },
            {
                type: "custom",
                name: "SeparatorLineA",
                customComponent: <SeparatorLine text="פרטי איש הקשר בחברה" />
            },
            {
                name: "sectionB",
                type: "multi",
                inputs: [
                    {
                        name: "name",
                        label: "שם מלא איש קשר"
                    },
                    {
                        name: "phone",
                        label: "טלפון"
                    }
                ]
            },
            {
                name: "email",
                label: "אימייל"
            },
            ...(me?.isCompanyMaster
                ? [
                      {
                          type: "custom",
                          name: "SeparatorLine",
                          customComponent: <SeparatorLine text="כללים להפקת חשבונית" />
                      },
                      {
                          name: "requirePONumber",
                          label: "חיוב הזנת מס׳ רכש במעמד ההזמנה",
                          info: "סימון התיבה יחייב הזנה של מס׳ הרכש במעמד ביצוע הזמנה של האדמין הראשי של החשבון וכל תתי האדמינים מתחתיו",
                          type: "boolean",
                          checkedChildren: "כן",
                          unCheckedChildren: "לא"
                      },
                      {
                          name: "separateSubAdminInvoices",
                          label: "ניהול חשבונות עצמאי של תתי-האדמינים תחת החשבון",
                          info: "סימון התיבה יביא להפרדת החשבוניות של כל תתי-האדמין תחת החשבון (במידה ואינם פועלים באמצעות תקציב) כך שמול כל אחד מהם יתנהל תהליך חשבונאות וגבייה נפרד",
                          type: "boolean",
                          checkedChildren: "כן",
                          unCheckedChildren: "לא"
                      }
                  ]
                : [])
        ],
        [me?.isCompanyMaster]
    );

    useEffect(() => {
        if (company && me?.isCompanyMaster) {
            setFinanceInfo(currentFinanceInfo => ({
                ...currentFinanceInfo,
                businessNumber: company?.businessNumber,
                name: company?.financeContact?.name,
                email: company?.financeContact?.email,
                phone: company?.financeContact?.phone,
                companyNameForInvoice: company?.financeContact?.companyNameForInvoice ?? company?.name,
                requirePONumber: !!company?.requirePONumber,
                separateSubAdminInvoices: !!company?.separateSubAdminInvoices
            }));
        } else if (company && me?.isCompanySubAdmin) {
            setFinanceInfo(currentFinanceInfo => ({
                ...currentFinanceInfo,
                businessNumber: me?.financeContact?.businessNumber,
                name: me?.financeContact?.name,
                email: me?.financeContact?.email,
                phone: me?.financeContact?.phone,
                companyNameForInvoice: me?.financeContact?.companyNameForInvoice
            }));
        }
    }, [company, me]);

    return showAsModal ? (
        <CompanyInvoiceManageModal
            formFields={formFields}
            financeInfo={financeInfo}
            visible={visible}
            onSave={async newValues => {
                await updateCompanyFinanceInfo(newValues);
                onSave(newValues);
            }}
            onClose={onClose}
        />
    ) : (
        <CompanyInvoiceManageForm
            formFields={formFields}
            financeInfo={financeInfo}
            onUpdateForm={onUpdateForm}
            onSave={updateCompanyFinanceInfo}
        />
    );
};
