import React from "react";
import {EventAcceptedIcon, EventDeclinedIcon, OkIcon, SendIcon, SupermanIcon, TeamIcon} from "../../icons";
import {Skeleton, Statistic} from "antd";

const keyToInfo = {
    employees: {icon: <TeamIcon />, color: "rgba(80,181,255,0.1)"},
    "active-employees": {icon: <SupermanIcon />, color: "rgba(255,190,61,0.1)"},
    "active-professionals": {icon: <OkIcon />, color: "rgba(19,194,150,0.1)"},
    "offers-accepted": {icon: <EventAcceptedIcon />, color: "rgba(191,122,169,0.1)"},
    "offers-declined": {icon: <EventDeclinedIcon />, color: "rgba(255,92,117,0.1)"},
    "offers-made": {icon: <SendIcon />, color: "rgba(57,56,117,0.1)"}
};

const StatisticsCard = ({loading, iconName, title, value, info: {icon, color}}) => (
    <div
        style={{
            display: "flex",
            padding: 25,
            margin: 15,
            border: "1px solid #EAEDF3",
            maxWidth: 360,
            width: "100%",
            borderRadius: 20
        }}>
        <Skeleton loading={loading} active paragraph={{rows: 1}}>
            <div
                style={{
                    width: 70,
                    height: 70,
                    borderRadius: 20,
                    backgroundColor: color,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                {icon}
            </div>
            <div style={{height: 70, marginLeft: 25}}>
                <Statistic title={title} value={value} valueStyle={{fontWeight: 700}} />
            </div>
        </Skeleton>
    </div>
);

export const CompanyInsightsSummary = ({data}) => {
    return (
        <div
            style={{display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap", minHeight: 304}}>
            {(data ? data.totals : Object.keys(keyToInfo)).map(({title, value, key}, idx) => (
                <StatisticsCard
                    key={`${key}-${idx}`}
                    loading={!data}
                    title={title}
                    value={value}
                    iconName={key}
                    info={keyToInfo[key] || {}}
                />
            ))}
        </div>
    );
};
