import React from "react";
import {ArrowNextIcon, ArrowPrevIcon} from "../../icons";
import arrowLeft from "./arrow_left.png";

export const CarouselArrow = ({className, style, arrowStyle, onClick, backgroundColor, arrowColor, direction}) => {
    const ArrowIcon = direction === CarouselArrow.Direction.NEXT ? ArrowNextIcon : ArrowPrevIcon;

    return (
        <div onClick={onClick} className={className} style={style}>
            <div
                style={{
                    width: 40,
                    height: 40,
                    borderRadius: 20,
                    backgroundColor: backgroundColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0 5px 15px 0 rgba(0,0,0,0.1)",
                    ...arrowStyle
                }}>
                {arrowColor === "white" ? (
                    <img
                        width={20}
                        height={20}
                        src={arrowLeft}
                        style={{transform: direction === CarouselArrow.Direction.NEXT ? "rotate(180deg)" : void 0}}
                        alt="arrow"
                    />
                ) : (
                    <ArrowIcon style={{width: 20, height: 20, fill: arrowColor}} />
                )}
            </div>
        </div>
    );
};

CarouselArrow.Direction = {
    NEXT: "next",
    PREV: "prev"
};
