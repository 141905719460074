import {cloneDeep} from "lodash";
import {FilterOptions, kosherTypes} from "../../../marketplace/ProductsFilterModal/consts.jsx";

export const transformLockedFiltersToFilterValue = lockedFilters => {
    return lockedFilters.reduce((acc, {id, filter}) => {
        if (!filter) {
            return acc;
        }

        const key = id.split("-")[0];

        if (key === id) {
            return {
                ...acc,
                [key]: filter
            };
        }

        return {
            ...acc,
            [key]: [...(acc[key] ?? []), filter]
        };
    }, {});
};

export const transformFilterValueToLockedFilters = filterValues => {
    return Object.entries(filterValues).reduce((acc, [key, value]) => {
        if (Array.isArray(value)) {
            return [
                ...acc,
                ...value.map(filterValuePart => {
                    const id = filterValuePart?.displayName
                        ? `${key}-${filterValuePart.displayName}`
                        : `${key}-${filterValuePart}`;
                    return {
                        id,
                        filter: filterValuePart
                    };
                })
            ];
        } else {
            return [...acc, {id: key, filter: value}];
        }
    }, []);
};

export const convertFilterValues = filterValues => {
    const {searchText, extraSearchTexts, searchTags, ...updatedFilterValues} = cloneDeep(filterValues);
    const filterSearchText = [searchText, ...(extraSearchTexts || [])].filter(Boolean);

    if (
        Array.isArray(updatedFilterValues?.productProperties) &&
        updatedFilterValues?.productProperties.includes(FilterOptions.Kosher)
    ) {
        updatedFilterValues.kosherTypes = Object.values(kosherTypes).filter(
            kosher => kosher !== kosherTypes.NOT_NEEDED
        );
        updatedFilterValues.productProperties = updatedFilterValues.productProperties.filter(
            p => p !== FilterOptions.Kosher
        );
    }

    if (filterSearchText.length) {
        updatedFilterValues.searchText = filterSearchText;
    }
    return updatedFilterValues;
};
