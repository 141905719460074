import React from "react";
import {ConfigProvider, Skeleton} from "antd";
import "./provider-product-cart.css";
import {ProviderModularProductList} from "../../OrderFormModularProductsPanel/modularProductList/ProviderModularProductSkeletonList.jsx";

export const ProviderProductCartSkeleton = () => {
    return (
        <div className="provider-chosen-product-page provider-chosen-product-page-skeleton">
            <ConfigProvider direction="rtl">
                <Skeleton.Button className="provider-product-title-container-sekeleton" active={true} />
                <div className="product-list-skeleton-container">
                    <ProviderModularProductList/>
                    <div>
                        <Skeleton.Button className="product-skeleton-cart-container" active={true}/>
                    </div>
                </div>
            </ConfigProvider>
        </div>
    );
};
