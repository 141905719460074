import {useContext, useState} from "react";
import {AppContext} from "../../AppContext";
import React from "react";
import EventInfoFooterV2 from "./EventInfoFooterV2";
import {EventInfoPreview} from "../preview/EventInfoPreview";
import {EventInfoManager} from "./EventInfoManager";
import {ReviewModal} from "../../review/ReviewModal";
import {eventByDate} from "../EventByDate";
import {EventBookings} from "../EventBookings";

export const EventInfoCardV2 = ({
    eventInfo,
    providerInfo,
    dateMom,
    onBook,
    onEdit,
    onDelete,
    deleting,
    onClose,
    onNewCustomEvent,
    onDismiss,
    onSendEvent,
    onCalendarInvite,
    openActivityDetails
}) => {
    const {me} = useContext(AppContext);
    const [eventToReview, setEventToReview] = useState(null);
    const [bookingModalVisibility, setBookingModalVisibility] = useState(false);

    return (
        <EventInfoManager
            eventInfo={eventInfo}
            providerInfo={providerInfo}
            dateMom={dateMom}
            onBook={onBook}
            onClose={onClose}
            Component={({
                event,
                isAppointmentBased,
                registering,
                registered,
                doRegistration,
                unregister,
                doBooking,
                join
            }) => (
                <>
                    {bookingModalVisibility ? (
                        <EventBookings
                            visible={bookingModalVisibility}
                            onClose={() => setBookingModalVisibility(false)}
                            eventId={eventInfo.eventId}
                            date={dateMom.format("YYYY-MM-DD")}
                        />
                    ) : null}
                    <ReviewModal
                        eventToReview={eventToReview ? {...eventToReview, imageUrl: providerInfo.imageUrl} : null}
                        autoPopup={false}
                        onClose={() => setEventToReview(null)}
                    />
                    <div
                        className="wb-event-info-preview-container"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            paddingBottom: 0,
                            width: "100%"
                        }}>
                        <EventInfoPreview
                            event={eventByDate(event, dateMom.format("YYYY-MM-DD"))}
                            dateMom={dateMom}
                            hideBorderBottom={true}
                            onRegister={
                                !isAppointmentBased && !registered ? async () => await doRegistration(event) : null
                            }
                            onUnregister={!isAppointmentBased && registered ? unregister : null}
                            onBook={isAppointmentBased ? doBooking : null}
                            actionLoading={registering}
                            onJoin={event.location === "online" && event.meetingLink ? join : null}
                            viewOnly={me.type !== "employee"}
                            coverStyle={{borderRadius: 0}}
                            bodyStyle={{padding: 0}}
                            useDefaultTheme={true}
                            withDefaultCover={me.type === "company"}
                            participantsCount={
                                Array.isArray(event.registeredEmployees) ? event.registeredEmployees.length : void 0
                            }
                            contentStyle={{overflow: "auto", maxHeight: 265, paddingLeft: 24, paddingRight: 24}}
                            actionsStyle={{paddingLeft: 24, paddingRight: 24}}
                        />
                    </div>
                    <EventInfoFooterV2
                        providerInfo={providerInfo}
                        event={event}
                        dateMom={dateMom}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        deleting={deleting}
                        onReview={providerInfo ? () => setEventToReview(eventInfo) : null}
                        onNewCustomEvent={onNewCustomEvent}
                        onDismiss={onDismiss}
                        onSendEvent={onSendEvent}
                        onBookingModalOpen={() => setBookingModalVisibility(true)}
                        onCalendarInvite={onCalendarInvite}
                        openActivityDetails={openActivityDetails}
                    />
                </>
            )}
        />
    );
};
