import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormModal} from "../../components";
import {SelectWithInputOption} from "../../components/SelectWithInputOption";
import {FileUpload} from "../../components/FileUpload";
import {TrashIcon} from "../../icons";
import {FoodRequestsIds, FoodRequestsIdToName} from "../../marketplace/ProductsFilterModal/consts.jsx";
import common from "@well-b/well-b-shared";
import {VAT} from "../../consts.js";

export const ModalModes = {
    EDIT: "עריכת מיני מוצר ✏️",
    NEW: "יצירת מיני מוצר ✏️"
};

const isNumber = possibleNumber => !isNaN(parseFloat(possibleNumber));

export const SubProductsEditOrAddModal = ({
    visible,
    onClose,
    subProductsCategories,
    onSave,
    subProduct = [],
    onCreateSUbProduct,
    onUpdateSubProduct
}) => {
    const typeMode = useMemo(() => (subProduct ? ModalModes.EDIT : ModalModes.NEW), [subProduct]);
    const [fields, setFields] = useState({});

    useEffect(() => {
        if (isNumber(subProduct?.price) && isNumber(subProduct?.commission)) {
            setFields({
                ...subProduct,
                priceWithoutCommission: common.calculateSubProductPriceWithoutCommission(
                    subProduct.price,
                    subProduct.commission,
                    VAT
                )
            });
        } else {
            setFields(subProduct);
        }
    }, [subProduct]);

    const onUpdate = useCallback((newFields, changedField) => {
        const tempNewFields = {...newFields};

        if (changedField.price) {
            if (isNumber(newFields.commission)) {
                tempNewFields.priceWithoutCommission = common.calculateSubProductPriceWithoutCommission(
                    tempNewFields.price,
                    tempNewFields.commission,
                    VAT
                );
            } else if (isNumber(newFields.priceWithoutCommission)) {
                tempNewFields.commission = common.calculateSubProductCommission(
                    tempNewFields.price,
                    tempNewFields.priceWithoutCommission,
                    VAT
                );
            }
        } else if (changedField.commission) {
            if (isNumber(newFields.price)) {
                tempNewFields.priceWithoutCommission = common.calculateSubProductPriceWithoutCommission(
                    tempNewFields.price,
                    tempNewFields.commission,
                    VAT
                );
            } else if (isNumber(newFields.priceWithoutCommission)) {
                tempNewFields.price = common.calculateSubProductPrice(
                    tempNewFields.priceWithoutCommission,
                    tempNewFields.commission,
                    VAT
                );
            }
        } else if (changedField?.priceWithoutCommission) {
            if (isNumber(newFields.price)) {
                tempNewFields.commission = common.calculateSubProductCommission(
                    tempNewFields.price,
                    tempNewFields.priceWithoutCommission,
                    VAT
                );
            } else if (isNumber(newFields.commission)) {
                tempNewFields.price = common.calculateSubProductPrice(
                    tempNewFields.priceWithoutCommission,
                    tempNewFields.commission,
                    VAT
                );
            }
        }

        setFields(tempNewFields);
    }, []);

    const onSubProductSave = useCallback(
        savedSubProduct => {
            const price = Number(savedSubProduct.price);
            const commission = parseFloat(savedSubProduct.commission);
            const {priceWithoutCommission, ...subProductToSave} = {...fields, price, commission};

            if (subProduct?.id) {
                onUpdateSubProduct(subProduct.id, subProductToSave);
            } else {
                onCreateSUbProduct(subProductToSave);
            }
            setFields({});
        },
        [fields, onSave, subProduct, typeMode, onCreateSUbProduct, onUpdateSubProduct]
    );

    return (
        <FormModal
            header={typeMode}
            visible={visible}
            content={fields}
            onUpdate={onUpdate}
            onSave={onSubProductSave}
            onClose={() => {
                setFields({});
                onClose();
            }}
            fields={[
                {
                    name: "name",
                    label: "שם המוצר"
                },
                {
                    name: "description",
                    label: "תיאור המוצר"
                },
                {
                    name: "catalogNumber",
                    label: "מק״ט (מספר קטלוגי)",
                    optional: true
                },
                {
                    name: "subProductCategory",
                    type: "custom",
                    label: "קטגוריה",
                    customComponent: (
                        <SelectWithInputOption
                            options={subProductsCategories.map(category => ({
                                value: category,
                                label: category
                            }))}
                        />
                    ),
                    optional: true
                },
                {
                    name: "foodRequests",
                    label: "התאמות אוכל",
                    type: "select",
                    multiple: true,
                    options: Object.values(FoodRequestsIds).map(foodRequestsId => ({
                        label: FoodRequestsIdToName[foodRequestsId],
                        name: foodRequestsId
                    })),
                    optional: true
                },
                {
                    name: "priceAndCommission",
                    type: "multi",
                    inputs: [
                        {
                            name: "price",
                            type: "number",
                            label: "מחיר ללקוח"
                        },
                        {
                            name: "commission",
                            type: "number",
                            label: "עמלה (פלוס מע״מ)"
                        },
                        {
                            name: "priceWithoutCommission",
                            type: "number",
                            label: "מחיר קנייה"
                        }
                    ]
                },
                {
                    name: "subProductImage",
                    type: "custom",
                    label: "תמונה",
                    customComponent: (
                        <>
                            <FileUpload
                                acceptedFormats={"image/png,image/jpeg"}
                                multiple={false}
                                onUploadSuccess={fileInfo => {
                                    setFields(currentFields => ({...currentFields, subProductImage: fileInfo}));
                                }}
                            />
                            {fields?.subProductImage && (
                                <div>
                                    <TrashIcon
                                        style={{fill: "black", cursor: "pointer"}}
                                        onClick={() => {
                                            const {subProductImage, ...otherFields} = fields;
                                            setFields(otherFields);
                                        }}
                                    />
                                    <a
                                        href={fields.subProductImage?.imageUrl}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        {fields.subProductImage?.name}
                                    </a>
                                </div>
                            )}
                        </>
                    )
                }
            ]}
        />
    );
};
