import {RRuleSetBuilder} from "../../event/RRuleSetBuilder";
import moment from "moment-timezone";
import {eventByDate} from "../../event/EventByDate";
import {memoize} from "lodash";
import {CALENDAR_MAX_VIEW_IN_MONTHS, CALENDAR_MIN_VIEW_IN_MONTHS} from "../../data/defaults";

export const isEventsSection = memoize(sectionId => sectionId === "events");

export const toEventText = evt => {
    return `
        ${evt.sessionName || ""}
        ${evt.productName || ""}
        ${evt.serviceName || ""}
        ${evt.productDescription || ""}
        ${evt.notes || ""}
        ${evt.providerFirstName || ""}
        ${evt.providerLastName || ""}
        ${evt.providerEmail || ""}
        ${evt.providerPhone || ""}
        ${evt.address || ""}
        `;
};

export const toEventsWithDate = (events, term) => {
    return events
        .filter(evt => {
            const text = toEventText(evt);
            return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
        })
        .reduce((withDate, event) => {
            const rruleSet = RRuleSetBuilder.build(event);

            const dates = rruleSet.between(
                moment().subtract(CALENDAR_MIN_VIEW_IN_MONTHS, "months").toDate(),
                moment().add(CALENDAR_MAX_VIEW_IN_MONTHS, "months").toDate(),
                true
            );

            if (dates && dates.length > 0) {
                dates.forEach(date => {
                    withDate.push({
                        event: eventByDate(event, date),
                        date
                    });
                });
            }
            return withDate;
        }, []);
};

export const toEventsThisWeek = events => {
    const startOfWeek = moment().startOf("week");
    const endOfWeek = moment().endOf("week");
    return events
        .filter(evt => evt.status === "accepted")
        .reduce((thisWeek, event) => {
            const rruleSet = RRuleSetBuilder.build(event);
            const dates = rruleSet.between(startOfWeek.toDate(), endOfWeek.toDate(), true);
            if (Array.isArray(dates) && dates.length > 0) {
                dates.forEach(date => {
                    const eventThisDate = eventByDate(event, date);
                    thisWeek.push({event: eventThisDate, date});
                });
            }
            return thisWeek;
        }, []);
};
