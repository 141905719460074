import React, {useCallback} from "react";
import {SmartImageContainer} from "../../components";
import cover_placeholder from "../../images/cover_placeholder.jpg";
import {ProviderReviews} from "../../provider/ProviderReviews";
import "./provider-about-section.css";

export const ProviderAboutSection = ({product, providerDetails}) => {
    const onProviderNameClick = useCallback(() => {
        if (providerDetails?.providerId) {
            window.open(`/dashboard/services/providerPage/${providerDetails.providerId}`, "_blank");
        }
    }, [providerDetails]);
    return (
        <>
            <div className="provider-action-section-container">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "row",
                        marginTop: "27px",
                        marginBottom: "27px",
                        marginRight: "27px"
                    }}>
                    <SmartImageContainer
                        width={80}
                        height={80}
                        transformations={{gravity: "faces"}}
                        style={{borderRadius: "40px"}}
                        src={providerDetails?.imageUrl || cover_placeholder}
                    />
                    <div className="provider-details-container">
                        <span className="provider-about-title">אודות הספק</span>
                        <span className="provider-name" onClick={onProviderNameClick}>
                            {product.brandName || product?.engBrandName || providerDetails.businessName}
                        </span>
                    </div>
                </div>
                <div className="provider-about-header-divider"></div>
                <div className="provider-description">{providerDetails.description}</div>
                {providerDetails.reviews ? <ProviderReviews reviews={providerDetails.reviews} /> : null}
            </div>
        </>
    );
};
