import Moment from "moment-timezone";
// import {extendMoment} from "moment-range";

const moment = null; //extendMoment(Moment);

export const isSlotAvailableBetweenAppointments = (appointments, startMom, endMom, appointmentId = null) => {
    return appointments
        .filter(appt => !appt.filler)
        .every(appt => {
            if (appt.appointmentId === appointmentId) return true;
            return !isIntersectingWithExistingSlot(appt, startMom, endMom);
        });
};

export const isIntersectingWithExistingSlot = (existingSlot, startMom, endMom) => {
    return (
        moment
            .range(moment(existingSlot.start, "HH:mm"), moment(existingSlot.end, "HH:mm"))
            .contains(moment(startMom).add(1, "seconds")) ||
        moment
            .range(moment(existingSlot.start, "HH:mm"), moment(existingSlot.end, "HH:mm"))
            .contains(moment(endMom).subtract(1, "seconds")) ||
        moment
            .range(moment(startMom, "HH:mm").add(1, "seconds"), moment(endMom, "HH:mm").subtract(1, "seconds"))
            .contains(moment(existingSlot.start, "HH:mm")) ||
        moment
            .range(moment(startMom, "HH:mm").add(1, "seconds"), moment(endMom, "HH:mm").subtract(1, "seconds"))
            .contains(moment(existingSlot.end, "HH:mm"))
    );
};
