import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import moment from "moment";
import {Collapse, Spin, Switch, message} from "antd";
import classNames from "classnames";
import {differenceBy, get, set} from "lodash";
import {getCategoryPolicyName} from "./calculatorStepper/consts";
import {HappinessCalculatorConsumptionOccasionInput} from "./HappinessCalculatorConsumptionOccasionInput";
import {transformAndPrioritizeNewOccasions} from "./utils";
import {DefaultMaxOccasionsInColumn} from "./consts";
import HappinessCalculatorAddOccasionModal from "./HappinessCalculatorAddOccasionModal";
import {HappinessCalculatorContext} from "./HappinessCalculatorContext";
import "./happiness-calculator-categories-panel.css";

const {Panel} = Collapse;

const HappinessCalculatorCategoriesPanel = ({
    tableData = {},
    setTableData,
    employeesAmount,
    userActions,
    savedPlan,
    categoriesData,
    log,
    onAddNewCategoriesClicked,
    loadingCategoriesData,
    timeRanges
}) => {
    const {planOccasionsCategories} = useContext(HappinessCalculatorContext);
    const [selectedOccasionCategory, setSelectedOccasionCategory] = useState(null);
    const [isCustomOccasionModalVisibility, setCustomOccasionModalVisibility] = useState(false);
    const [forceTableUpdate, setForceTableUpdate] = useState(false);

    useEffect(() => {
        if (savedPlan && !savedPlan.error) {
            setTableData(savedPlan.categories);
        }
    }, [planOccasionsCategories]);

    const openCustomOccasionModal = useCallback(occasionCategory => {
        setSelectedOccasionCategory(occasionCategory);
        setCustomOccasionModalVisibility(true);
    }, []);

    useEffect(() => {
        if (categoriesData) {
            setTableData(prev => {
                if (prev) {
                    const newTableData = Object.values(prev).reduce((acc, category) => {
                        const relevantCategory = categoriesData.find(
                            ({occasionCategoryId}) => occasionCategoryId === category.occasionCategoryId
                        );

                        const currentlyActiveOccasions = category?.occasions.filter(({isActive}) => isActive) ?? [];

                        const newOccasions = (relevantCategory?.occasions ?? []).filter(
                            ({occasionId}) =>
                                !currentlyActiveOccasions.some(occasion => occasion.occasionId === occasionId)
                        );

                        const [occasionsToShow, otherOccasions] = transformAndPrioritizeNewOccasions(
                            newOccasions ?? [],
                            category.showUnlimitedOccasions
                                ? Number.POSITIVE_INFINITY
                                : Math.max(0, DefaultMaxOccasionsInColumn - currentlyActiveOccasions.length),
                            timeRanges
                        );

                        return {
                            ...acc,
                            [category.occasionCategoryId]: {
                                ...category,
                                ...relevantCategory,
                                occasions: relevantCategory
                                    ? [...currentlyActiveOccasions, ...occasionsToShow]
                                    : category.occasions,
                                otherOccasions: relevantCategory ? otherOccasions : category.otherOccasions,
                                isDisabled: !relevantCategory
                            }
                        };
                    }, {});

                    const newCategories = categoriesData.filter(({occasionCategoryId}) => !prev[occasionCategoryId]);

                    return {
                        ...newTableData,
                        ...newCategories.reduce((acc, category) => {
                            const [occasionToShow, otherOccasions] = transformAndPrioritizeNewOccasions(
                                category.occasions,
                                category.showUnlimitedOccasions
                                    ? Number.POSITIVE_INFINITY
                                    : DefaultMaxOccasionsInColumn,
                                timeRanges
                            );
                            return {
                                ...acc,
                                [category.occasionCategoryId]: {
                                    ...category,
                                    occasions: occasionToShow,
                                    otherOccasions
                                }
                            };
                        }, {})
                    };
                }

                return {
                    ...categoriesData.reduce((acc, category) => {
                        const [occasionToShow, otherOccasions] = transformAndPrioritizeNewOccasions(
                            category.occasions,
                            category.showUnlimitedOccasions ? Number.POSITIVE_INFINITY : DefaultMaxOccasionsInColumn,
                            timeRanges
                        );
                        return {
                            ...acc,
                            [category.occasionCategoryId]: {
                                ...category,
                                occasions: occasionToShow,
                                otherOccasions
                            }
                        };
                    }, {})
                };
            });
        }
    }, [categoriesData, forceTableUpdate, timeRanges]);

    const updateTableData = useCallback((path, value) => {
        setTableData(prev => {
            const currentValue = get(prev, path);
            userActions.current.push({path, value: currentValue});
            return set({...prev}, path, value);
        });
    }, []);

    const monthsRange = useMemo(
        () =>
            timeRanges.length === 2
                ? Math.abs(Math.round(moment.duration(timeRanges[0].diff(timeRanges[1])).asMonths()))
                : 0,
        [timeRanges]
    );

    useEffect(() => {
        if (tableData) {
            const isEmptyRecurringOccasion = occasion => occasion.isActive === undefined;

            Object.values(tableData).forEach(category => {
                if (
                    category.isRangePolicy &&
                    (!category.occasions.length ||
                        !isEmptyRecurringOccasion(category.occasions[category.occasions.length - 1]))
                ) {
                    const emptyRecurringOccasion = {
                        occasionId: `${category.occasionCategoryId}-${category.occasions.length}`,
                        name: `${category.categoryName} כללי ${
                            category.occasions.length > 0 ? category.occasions.length + 1 : ""
                        }`,
                        icon: category.icon
                    };

                    updateTableData(`${category.occasionCategoryId}.recurringOccasions`, [
                        ...category.occasions,
                        emptyRecurringOccasion
                    ]);
                }
            });
        }
    }, [tableData]);

    return (
        <>
            <HappinessCalculatorAddOccasionModal
                occasionCategory={selectedOccasionCategory}
                visible={isCustomOccasionModalVisibility}
                onClose={() => {
                    setSelectedOccasionCategory(null);
                    setCustomOccasionModalVisibility(false);
                }}
                onSave={(currentOccasion, newOccasions) => {
                    message.success("השינויים שלכם נשמרו בהצלחה");
                    setSelectedOccasionCategory(null);
                    setCustomOccasionModalVisibility(false);

                    const newOtherOccasions = differenceBy(currentOccasion?.otherOccasions, newOccasions, "occasionId");
                    updateTableData(`${currentOccasion.occasionCategoryId}.otherOccasions`, newOtherOccasions);
                    updateTableData(`${currentOccasion.occasionCategoryId}.occasions`, [
                        ...currentOccasion?.occasions,
                        ...newOccasions
                    ]);
                }}
            />

            <div className="happiness-calculator-categories-panel">
                {loadingCategoriesData || !tableData ? (
                    <Spin />
                ) : (
                    <>
                        <Collapse expandIconPosition="left" accordion={true}>
                            {Object.values(tableData).map(
                                ({
                                    occasionCategoryId,
                                    occasions,
                                    categoryName,
                                    color,
                                    isDisabled,
                                    otherOccasions,
                                    forceOccasionPolicy = null,
                                    isRangePolicy = false,
                                    showUnlimitedOccasions = false
                                }) => {
                                    return !isDisabled ? (
                                        <Panel
                                            style={{
                                                "--category-color": color
                                            }}
                                            className={classNames("happiness-calculator-category-panel")}
                                            header={categoryName}
                                            key={occasionCategoryId}>
                                            <>
                                                {!forceOccasionPolicy && (
                                                    <>
                                                        <div className="happiness-calculator-category-panel-policy-container">
                                                            <span>בחרו את רמת ההגדרה הרצויה</span>

                                                            <div>
                                                                <span
                                                                    className={classNames({
                                                                        "happiness-calculator-category-panel-policy-selected":
                                                                            isRangePolicy
                                                                    })}>
                                                                    {getCategoryPolicyName(true)}
                                                                </span>
                                                                <Switch
                                                                    key={`switch_${occasionCategoryId}`}
                                                                    onClick={isActive => {
                                                                        log("Switch Category Policy", {
                                                                            categoryName,
                                                                            policy: getCategoryPolicyName(!isActive)
                                                                        });
                                                                        updateTableData(
                                                                            `${occasionCategoryId}.isRangePolicy`,
                                                                            !isActive
                                                                        );
                                                                    }}
                                                                    checked={!isRangePolicy}
                                                                />
                                                                <span
                                                                    className={classNames({
                                                                        "happiness-calculator-category-panel-policy-selected":
                                                                            !isRangePolicy
                                                                    })}>
                                                                    {getCategoryPolicyName(false)}
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="happiness-calculator-category-panel-line" />
                                                    </>
                                                )}

                                                {occasions.map((occasion, index) => {
                                                    return (
                                                        <HappinessCalculatorConsumptionOccasionInput
                                                            onFieldChanged={(field, newState) => {
                                                                log("Event input changed", {
                                                                    field,
                                                                    newState,
                                                                    name: occasion.name,
                                                                    category: categoryName
                                                                });

                                                                const occasionsFieldName = isRangePolicy
                                                                    ? "recurringOccasions"
                                                                    : "occasions";
                                                                updateTableData(
                                                                    `${occasionCategoryId}.${occasionsFieldName}[${index}].${field}`,
                                                                    newState
                                                                );
                                                            }}
                                                            employeesAmount={employeesAmount}
                                                            key={`${occasionCategoryId}-${occasion.occasionId}`}
                                                            occasion={occasion}
                                                            isRangePolicy={isRangePolicy}
                                                            monthsRange={monthsRange}
                                                        />
                                                    );
                                                })}

                                                {!isRangePolicy && (
                                                    <>
                                                        <div className="happiness-calculator-category-panel-line" />

                                                        <div className="happiness-calculator-category-panel-actions-container">
                                                            <div
                                                                onClick={() => {
                                                                    log("Add General Event Clicked", {categoryName});
                                                                    openCustomOccasionModal({
                                                                        occasionCategoryId,
                                                                        occasions,
                                                                        categoryName,
                                                                        otherOccasions
                                                                    });
                                                                }}>
                                                                + הוספת {categoryName} כללי
                                                            </div>

                                                            {((!showUnlimitedOccasions && otherOccasions?.length) ||
                                                                showUnlimitedOccasions) && (
                                                                <div
                                                                    onClick={() => {
                                                                        log("Expend Events List Click", {categoryName});
                                                                        updateTableData(
                                                                            `${occasionCategoryId}.showUnlimitedOccasions`,
                                                                            !showUnlimitedOccasions
                                                                        );
                                                                        setForceTableUpdate(current => !current);
                                                                    }}>
                                                                    <span>✨</span>
                                                                    <span> </span>
                                                                    <span className="happiness-calculator-category-panel-underline-text">
                                                                        <span>
                                                                            {showUnlimitedOccasions
                                                                                ? "לצימצום"
                                                                                : "להרחבת"}
                                                                        </span>
                                                                        <span> </span>
                                                                        <span>רשימת המועדים בתקופה</span>
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        </Panel>
                                    ) : null;
                                }
                            )}
                        </Collapse>
                        <div
                            onClick={onAddNewCategoriesClicked}
                            className="happiness-calculator-categories-panel-add-category">
                            <span>להוספת ראשי פרק נוספים</span>
                            <span>+</span>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default HappinessCalculatorCategoriesPanel;
