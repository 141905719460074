import {CloseIcon} from "../../icons";
import {Modal, Form, Select} from "antd";
import React, {useState} from "react";
import {HttpClient} from "../../http/HttpClient";
import {TutorialScreens} from "../../tutorial/TutorialScreens";
import {FormInput, FormTextArea} from "../../components/form";
import {VideoUpload} from "../../components/VideoUpload";
import {StyledButton} from "../../components/StyledButton";

const {Option} = Select;

const AdminTutorialForm = ({tutorialToEdit, onFinish, loading}) => {
    return (
        <Form initialValues={tutorialToEdit || void 0} onFinish={onFinish} style={{width: "100%", padding: 20}}>
            <div
                style={{
                    fontSize: 20,
                    fontWeight: 700,
                    marginTop: 20,
                    marginBottom: 24,
                    color: "#273444",
                    width: "100%",
                    textAlign: "left"
                }}>
                {tutorialToEdit ? "Edit tutorial" : "Add tutorial"}
            </div>
            <Form.Item
                name="videoUrl"
                valuePropName="fileUrl"
                rules={[
                    {
                        required: true,
                        message: "Please upload video."
                    }
                ]}>
                <VideoUpload videoStyle={{width: 426, height: 240}} />
            </Form.Item>
            <Form.Item
                name="title"
                rules={[
                    {
                        required: true,
                        message: "Please enter title."
                    }
                ]}>
                <FormInput placeholder="Title" />
            </Form.Item>
            <Form.Item
                name="description"
                rules={[
                    {
                        required: true,
                        message: "Please enter description."
                    }
                ]}>
                <FormTextArea autoSize={{minRows: 4, maxRows: 4}} placeholder="Write something..." />
            </Form.Item>
            <Form.Item
                name="screenId"
                rules={[
                    {
                        required: true,
                        message: "Please select screen."
                    }
                ]}>
                <Select placeholder="Screen">
                    {TutorialScreens.map(({screenName, screenId}) => (
                        <Option key={screenId} value={screenId}>
                            {screenName}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item name="orderOnScreen">
                <FormInput type="number" placeholder="Order (Optional)" />
            </Form.Item>
            <Form.Item>
                <StyledButton
                    loading={loading}
                    htmlType="submit"
                    style={{
                        color: "white",
                        backgroundColor: "#13C296",
                        width: "100%"
                    }}>
                    {tutorialToEdit ? "Update" : "Add"}
                </StyledButton>
            </Form.Item>
        </Form>
    );
};

export const AdminTutorialModal = ({onClose, visible, tutorialToEdit}) => {
    const [loading, setLoading] = useState(false);

    const addTutorial = async tutorial => {
        setLoading(true);
        try {
            tutorial = await HttpClient.post("/admin/api/tutorials", {
                ...tutorialToEdit,
                ...tutorial
            });
            onClose(tutorial);
        } catch (e) {
            message.error(e.message, 3);
        }
        setLoading(false);
    };

    return (
        <Modal
            centered={true}
            width={466}
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            bodyStyle={{padding: 0}}
            footer={null}
            title={null}
            closeIcon={<CloseIcon fill="#8398A6" />}
            closable={true}
            onCancel={() => onClose()}>
            {visible ? (
                <AdminTutorialForm loading={loading} onFinish={addTutorial} tutorialToEdit={tutorialToEdit} />
            ) : null}
        </Modal>
    );
};
