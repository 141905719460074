import React, {useCallback, useRef, useState} from "react";
import {FormInputV2} from "../form/index.jsx";
import {NOOP} from "../../utils/NOOP.jsx";
import {defaultProps} from "./consts.js";
import "./grouped-digit-input.css";
import classnames from "classnames";
import {chunk} from "lodash";

const isNumber = value => /^[0-9]*$/.test(value);

export const GroupedDigitInput = ({className, onUpdate = NOOP, disabled, placeholder}) => {
    const [innerGroupDigits, setInnerGroupDigits] = useState(["", "", "", ""]);
    const inputRefs = useRef(Array.from({length: 4}, () => React.createRef()));

    const onUpdateGroupNumber = useCallback(
        (index, value) => {
            if (!/^[0-9]*$/.test(value)) return;

            setInnerGroupDigits(prev => {
                const updatedDigits = [...prev];
                updatedDigits[index] = value.slice(0, 4);
                onUpdate(updatedDigits.join(""));
                return updatedDigits;
            });

            if (value.length === 4 && index < innerGroupDigits.length - 1) {
                inputRefs.current[index + 1].current?.focus();
            }
        },
        [innerGroupDigits, onUpdate]
    );

    const onRemoveDigits = useCallback(
        (index, e) => {
            if (e.key === "Backspace" && !innerGroupDigits[index] && index > 0) {
                inputRefs.current[index - 1].current.focus();
            }
        },
        [innerGroupDigits]
    );

    const onPaste = useCallback(
        (index, event) => {
            event.preventDefault();
            const pastedNumber = event.clipboardData.getData("text");
            if (!isNumber(pastedNumber)) {
                return;
            }

            const groupNumbers = chunk(pastedNumber.split(""), 4).map(chunk => chunk.join(""));
            let currentGroupDigitsIndex = pastedNumber.length === 16 ? 0 : index;
            for (const number of groupNumbers) {
                if (currentGroupDigitsIndex >= 4) {
                    break;
                }
                onUpdateGroupNumber(currentGroupDigitsIndex, number);
                currentGroupDigitsIndex++;
            }
        },
        [onUpdateGroupNumber]
    );

    return (
        <>
            <div className={classnames("grouped-digit-input-container", className)}>
                {innerGroupDigits.map((digit, index) => (
                    <FormInputV2
                        placeholder={placeholder ?? defaultProps.placeholder}
                        key={index}
                        type="text"
                        maxLength="4"
                        value={digit}
                        onChange={e => onUpdateGroupNumber(index, e.target.value)}
                        onKeyDown={e => onRemoveDigits(index, e)}
                        ref={inputRefs.current[index]}
                        disabled={disabled}
                        onPaste={e => onPaste(index, e)}
                    />
                ))}
            </div>
        </>
    );
};
