import React, {useContext, useEffect, useReducer, useState} from "react";
import {Form, message} from "antd";
import {withRouter} from "react-router";
// import isValidDomain from "is-valid-domain";
import {uniqBy, cloneDeep} from "lodash";
import {parse as qsParse} from "query-string";
import {CompanyBranches} from "./CompanyBranches";
import {CompanyBranchesList} from "./CompanyBranchesList";
import {CompanyInfoFields} from "./CompanyInfoFields";
import {SlackAuthButton} from "./integrations/slack/SlackAuthButton";
import {PeopleManagementIntegrationSelect} from "./integrations/management/PeopleManagementIntegrationSelect";
import {FormCancelSaveBottomBar, FormInput} from "../components/form";
import {AppContext} from "../AppContext";
import {HttpClient} from "../http/HttpClient";
import {EventBus} from "../bus/EventBus";
import {PageLoader, StyledButton} from "../components";
import {removeKeysFromSearch} from "../AppHistoryAdapter";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {ResetPasswordModal} from "./ResetPasswordModal";

export const announceSlackAuthResult = (slackAuth, history, company) => {
    if (slackAuth) {
        if (slackAuth === "success") {
            GoogleAnalytics.event("Slack", "Company Auth", company.companyId);
            message.success("Slack integration enabled.", 3);
        } else if (slackAuth === "fail") {
            message.error("Failed to authenticated Slack.", 5);
        }

        const search = removeKeysFromSearch(["slackAuth"]);
        history.replaceWithoutReload(`${location.pathname}${search ? `?${search}` : ""}`);
    }
};

const CompanyInfoSettings = ({history, visible}) => {
    const {me, company} = useContext(AppContext);
    const [resetPasswordVisibility, setResetPasswordVisibility] = useState(false);
    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        selectedCountry: null,
        selectedState: null,
        selectedCity: null,
        companyInfo: null,
        saving: false,
        admins: [me],
        hideBottomBar: false
    });

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const [admins, employees] = await Promise.all([
                    HttpClient.get("/api/companies/me/admins"),
                    HttpClient.get("/api/employees")
                ]);
                setState({admins: uniqBy(admins.concat(employees), a => a.email)});
            } catch (e) {
                message.error("Something went wrong, please try again later.", 5);
            }

            const {slackAuth} = qsParse(location.search);
            announceSlackAuthResult(slackAuth, history, company);
        });
    }, []);

    const {selectedCountry, selectedState, selectedCity, companyInfo, admins, saving, hideBottomBar} = state;

    const onBranchesModalAppearanceChange = async (show, newBranches) => {
        setState({hideBottomBar: show});
        if (newBranches) {
            try {
                const cmpny = await HttpClient.post("/api/companies", {...company, branches: newBranches});
                EventBus.trigger("company:update", cmpny);
                message.success("Sites were updated.", 3);
            } catch (e) {
                handleError(e);
            }
        }
    };

    const selectCountry = selectedCountry => {
        setState({
            selectedCountry,
            selectedState: null,
            selectedCity: null
        });
        setState({
            companyInfo: {
                ...companyInfo,
                countryId: selectedCountry.countryId,
                stateId: void 0,
                cityId: void 0
            }
        });
    };

    const selectState = selectedState => {
        setState({
            selectedState,
            selectedCity: null
        });
        setState({
            companyInfo: {
                ...companyInfo,
                stateId: selectedState.stateId,
                cityId: void 0
            }
        });
    };

    const selectCity = selectedCity => {
        setState({selectedCity});
        setState({
            companyInfo: {
                ...companyInfo,
                cityId: selectedCity.cityId
            }
        });
    };

    const undoAll = initialCompanyInfo => {
        initialCompanyInfo = cloneDeep(initialCompanyInfo);
        setState({companyInfo: initialCompanyInfo});
        setState({
            selectedCountry: {
                countryId: initialCompanyInfo.countryId,
                countryName: initialCompanyInfo.countryName,
                countryCode: initialCompanyInfo.countryCode,
                phonePrefix: initialCompanyInfo.phonePrefix
            },
            selectedState: initialCompanyInfo.stateId
                ? {
                      stateId: initialCompanyInfo.stateId,
                      stateName: initialCompanyInfo.stateName
                  }
                : null,
            selectedCity: {
                stateId: initialCompanyInfo.stateId,
                stateName: initialCompanyInfo.stateName
            }
        });
    };

    const saveChanges = async values => {
        setState({saving: true});
        try {
            const {selectedCountry, selectedState, selectedCity} = state;

            const info = {
                ...values,
                countryName: selectedCountry ? selectedCountry.countryName : null,
                countryCode: selectedCountry ? selectedCountry.countryCode : null,
                stateName: selectedState ? selectedState.stateName : null,
                cityName: selectedCity ? selectedCity.cityName : null,
                companyId: companyInfo.companyId
            };

            const cmpny = await HttpClient.post("/api/companies", info);
            EventBus.trigger("me:update", {...me, companyName: info.name});
            EventBus.trigger("company:update", cmpny);
            message.success("Your information changes were saved.", 3);
        } catch (e) {
            handleError(e);
        }
        setState({saving: false});
    };

    const handleError = e => {
        if (e.statusCode === 400) {
            undoAll({...companyInfo, branches: company.branches});
            EventBus.triggerError(
                "server-error",
                {
                    content: {
                        description: e.message,
                        hideSteps: true,
                        hideSubTitle: true,
                        title: "Ohh..."
                    }
                },
                e.message
            );
        } else {
            message.error("Something went wrong, please try again later.", 5);
        }
    };

    if (company && companyInfo === null) {
        undoAll(company);
    }

    if (companyInfo === null) {
        return <PageLoader align="flex-start" top={80} height={400} />;
    }

    const isBambooHrIntegrationSelected = () => {
        return (
            companyInfo &&
            companyInfo.integrations &&
            companyInfo.integrations.people &&
            companyInfo.integrations.people.type === "bamboohr"
        );
    };

    return (
        <div style={{display: visible ? "flex" : "none", justifyContent: "center", width: "100%"}}>
            <div style={{maxWidth: 400, width: "100%"}}>
                <ResetPasswordModal
                    visible={resetPasswordVisibility}
                    onClose={() => setResetPasswordVisibility(false)}
                />
                <Form
                    onFinish={saveChanges}
                    fields={Object.keys(companyInfo).map(field => {
                        if (field === "phone") {
                            return {
                                name: [field],
                                value: companyInfo[field].replace("+", "")
                            };
                        } else {
                            return {
                                name: [field],
                                value: companyInfo[field]
                            };
                        }
                    })}
                    onValuesChange={(changedValues, allValues) => {
                        if (changedValues.companyDomain) {
                            allValues.integrations.people.params = {
                                ...allValues.integrations.people.params,
                                companyDomain: changedValues.companyDomain
                            };
                        }

                        if (changedValues.reportId) {
                            allValues.integrations.people.params = {
                                ...allValues.integrations.people.params,
                                reportId: changedValues.reportId
                            };
                        }
                        setState({companyInfo: allValues});
                    }}>
                    <StyledButton
                        style={{
                            color: "white",
                            backgroundColor: "var(--secondary-color)",
                            borderRadius: 5,
                            height: 40,
                            width: 400,
                            marginBottom: "20px"
                        }}
                        onClick={() => setResetPasswordVisibility(true)}>
                        {"Change password"}
                    </StyledButton>
                    <CompanyInfoFields
                        selectedCountry={selectedCountry}
                        onCountryChange={country => selectCountry(country)}
                        selectedState={selectedState}
                        onStateChange={state => selectState(state)}
                        selectedCity={selectedCity}
                        onCityChange={city => selectCity(city)}
                        disableEmail={true}
                        hideBranches={true}
                    />
                    <Form.Item
                        name={["integrations", "people"]}
                        rules={[
                            {
                                validator: (_, integration) => {
                                    if (
                                        typeof integration === "object" &&
                                        (!integration.params || !integration.params.token)
                                    ) {
                                        return Promise.reject("Please enter API token.");
                                    }

                                    return Promise.resolve();
                                }
                            }
                        ]}>
                        <PeopleManagementIntegrationSelect />
                    </Form.Item>
                    {isBambooHrIntegrationSelected() ? (
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <Form.Item
                                name="companyDomain"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter your BambooHR company domain"
                                    }
                                ]}>
                                <FormInput placeholder="Company domain" />
                            </Form.Item>
                            <Form.Item
                                name="reportId"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter your BambooHR report ID"
                                    }
                                ]}>
                                <FormInput placeholder="Report ID" />
                            </Form.Item>
                        </div>
                    ) : null}
                    <Form.Item
                        name="customDomain"
                        rules={[
                            {
                                validator: (_, domain) => {
                                    if (domain && !isValidDomain(domain, {subdomain: true, wildcard: false})) {
                                        return Promise.reject("Please enter a valid domain (e.g. www.example.com).");
                                    } else {
                                        return Promise.resolve();
                                    }
                                }
                            }
                        ]}>
                        <FormInput placeholder="Custom domain" />
                    </Form.Item>
                    {me.isCompanyMaster ? (
                        <Form.Item
                            name="branches"
                            rules={[
                                {
                                    validator: (_, branches) => {
                                        if (
                                            Array.isArray(branches) &&
                                            branches.length > 0 &&
                                            branches.every(b => !b.isHQ)
                                        ) {
                                            return Promise.reject("You must select one HQ.");
                                        }

                                        return Promise.resolve();
                                    }
                                }
                            ]}>
                            <CompanyBranches admins={admins} onAppearanceChange={onBranchesModalAppearanceChange}>
                                {({
                                    branches,
                                    onNewBranchClick,
                                    onEditBranchClick,
                                    onRemoveBranchClick,
                                    onBranchVisibilityChange,
                                    onMarkMainBranchClick
                                }) => (
                                    <CompanyBranchesList
                                        branches={branches}
                                        onNewBranchClick={onNewBranchClick}
                                        onEditBranchClick={onEditBranchClick}
                                        onRemoveBranchClick={onRemoveBranchClick}
                                        onBranchVisibilityChange={onBranchVisibilityChange}
                                        onMarkMainBranchClick={onMarkMainBranchClick}
                                    />
                                )}
                            </CompanyBranches>
                        </Form.Item>
                    ) : null}
                    <SlackAuthButton
                        path={company.slackAuth ? void 0 : "/api/slack/company/auth"}
                        style={{cursor: company.slackAuth ? "not-allowed" : "pointer", marginTop: 30}}
                        description="Reminders will be sent to a specific channel you choose">
                        {company.slackAuth ? "Connected to Slack app" : "Add to our Slack app"}
                    </SlackAuthButton>
                    {hideBottomBar ? null : (
                        <FormCancelSaveBottomBar
                            loading={saving}
                            saveProps={{
                                style: {
                                    backgroundColor: "#273444"
                                }
                            }}
                            cancelProps={{
                                style: {
                                    border: "1px solid #273444",
                                    color: "#273444"
                                }
                            }}
                            onCancel={() => undoAll(company)}
                        />
                    )}
                </Form>
            </div>
        </div>
    );
};

export default withRouter(CompanyInfoSettings);
