import React, {useEffect, useState} from "react";
import PageTitleHeader from "../../components/PageTitle";
import {RoundedTable} from "../../components/RoundedTable";
import {Button, Menu, Dropdown, message, Popconfirm} from "antd";
import moment from "moment-timezone";
import {ReviewModal} from "../../review/ReviewModal";
import {LoadingOutlined, AlertFilled} from "@ant-design/icons";
import {HttpClient} from "../../http/HttpClient";
import {SearchInput} from "../../components/Search";
import {StyledButton} from "../../components/StyledButton";
import {AdminCustomReviewModal} from "./AdminCustomReviewModal";

const columns = (reviewInProcess, onEdit, onDelete, onApprove) => {
    return [
        {
            title: "#",
            key: "#",
            width: 70,
            render(_, __, idx) {
                return `${idx + 1}`;
            }
        },
        {
            title: "USER",
            key: "user",
            width: 200,
            render(_, {userFirstName, userLastName, userEmail}) {
                if (!userEmail) {
                    return "Well B";
                }
                return (
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <label
                            style={{
                                color: "var(--secondary-color)",
                                fontSize: 14,
                                fontWeight: 500
                            }}>{`${userFirstName}${userLastName ? ` ${userLastName}` : ""}`}</label>
                        <label style={{color: "#747F92", fontSize: 12, fontWeight: 400}}>{userEmail}</label>
                    </div>
                );
            }
        },
        {
            title: "USER TYPE",
            dataIndex: "userType",
            width: 120,
            render(userType) {
                return userType ? userType.toUpperCase() : "--";
            }
        },
        {
            title: "COMPANY",
            dataIndex: "companyName",
            width: 200,
            render(companyName) {
                return companyName ? companyName : "--";
            }
        },
        {
            title: "PROFESSIONAL",
            key: "professional",
            width: 200,
            render(_, {providerFirstName, providerLastName, providerEmail}) {
                return (
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <label
                            style={{
                                color: "var(--secondary-color)",
                                fontSize: 14,
                                fontWeight: 500
                            }}>{`${providerFirstName}${providerLastName ? ` ${providerLastName}` : ""}`}</label>
                        <label style={{color: "#747F92", fontSize: 12, fontWeight: 400}}>{providerEmail}</label>
                    </div>
                );
            }
        },
        {
            title: "CONTENT",
            dataIndex: "content",
            width: 200,
            render(content) {
                return content || "--";
            }
        },
        {
            title: "RATING",
            dataIndex: "rating",
            width: 120
        },
        {
            title: "ANONYMOUS",
            dataIndex: "anonymous",
            width: 120,
            filters: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ],
            filterMultiple: false,
            onFilter: (value, {anonymous}) => {
                return anonymous === value;
            },
            render(anonymous) {
                return anonymous ? "YES" : "NO";
            }
        },
        {
            title: "APPROVED",
            dataIndex: "approved",
            width: 120,
            filters: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ],
            filterMultiple: false,
            onFilter: (value, {approved}) => {
                return approved === value;
            },
            render(approved) {
                return approved ? "YES" : "NO";
            }
        },
        {
            title: "CREATED AT",
            dataIndex: "createdAt",
            width: 250,
            sorter: (r1, r2) => (r1.createdAt < r2.createdAt ? -1 : 1),
            defaultSortOrder: "descend",
            render(createdAt) {
                return moment(createdAt).format("MMMM Do, YYYY HH:mm");
            }
        },
        {
            title: "ACTION",
            key: "action",
            width: 120,
            render(_, review) {
                const menu = (
                    <Menu>
                        <Menu.Item key="editItem">
                            <Button
                                style={{minWidth: 100, color: "var(--secondary-color)"}}
                                type="link"
                                onClick={() => onEdit(review)}>
                                Edit
                            </Button>
                        </Menu.Item>
                        <Menu.Item key="deleteItem">
                            <div
                                onClick={e => {
                                    e.stopPropagation();
                                }}>
                                <Popconfirm
                                    placement="bottomRight"
                                    title={`Remove ${review.providerFirstName}'s review?`}
                                    onConfirm={() => {
                                        onDelete(review);
                                    }}
                                    icon={<AlertFilled style={{color: "#F06060", fontSize: 16}} />}
                                    okText="Yes"
                                    cancelText="No">
                                    <Button style={{minWidth: 100, color: "var(--secondary-color)"}} type="link">
                                        Delete
                                    </Button>
                                </Popconfirm>
                            </div>
                        </Menu.Item>
                        <Menu.Item key="approveItem">
                            <Button
                                type="link"
                                style={{minWidth: 100, color: "var(--secondary-color)"}}
                                onClick={() => onApprove(review)}>
                                {review.approved ? "Disapprove" : "Approve"}
                            </Button>
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <Dropdown.Button
                        className="wb-action-drpdwn"
                        style={{border: "none"}}
                        overlay={menu}
                        icon={
                            reviewInProcess && reviewInProcess.reviewId === review.reviewId ? (
                                <LoadingOutlined />
                            ) : (
                                void 0
                            )
                        }
                    />
                );
            }
        }
    ];
};

export const AdminReviews = ({match}) => {
    const {selectedReviewId} = match.params;
    const [showCustomReviewModal, setShowCustomReviewModal] = useState(false);
    const [reviewInProcess, setReviewInProcess] = useState(null);
    const [reviewToOpen, setReviewToOpen] = useState(null);
    let [reviews, setReviews] = useState(null);
    const [term, setTerm] = useState("");

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const revs = await HttpClient.get("/admin/api/reviews");
                setReviews(revs);
                if (selectedReviewId) {
                    setReviewToOpen(revs.find(r => r.reviewId === selectedReviewId));
                }
            } catch (e) {
                message.error("Something went wrong, please try again later.", 5);
            }
        });
    }, []);

    const openReview = review => {
        setReviewToOpen(review);
    };

    const deleteReview = async review => {
        setReviewInProcess(review);
        try {
            await HttpClient.delete(`/admin/api/reviews/${review.reviewId}`);
            setReviews(reviews.filter(r => r.reviewId !== review.reviewId));
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setReviewInProcess(null);
    };

    const approveReview = async review => {
        setReviewInProcess(review);
        try {
            review.approved = !review.approved;
            await HttpClient.post(`/admin/api/reviews/events/${review.eventId}`, review);
            setReviews(reviews.map(r => (r.reviewId === review.reviewId ? review : r)));
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setReviewInProcess(null);
    };

    const closeEditModal = updatedReview => {
        if (updatedReview) {
            setReviews(reviews.map(r => (r.reviewId === updatedReview.reviewId ? updatedReview : r)));
        }
        setReviewToOpen(null);
    };

    const closeCustomModal = newReview => {
        if (newReview) {
            setReviews(reviews.concat([newReview]));
        }
        setShowCustomReviewModal(false);
    };

    reviews = reviews
        ? reviews.filter(r => {
              return (
                  `
        ${r.content} 
        ${r.providerFirstName} 
        ${r.providerLastName || ""}
        ${r.providerEmail} 
        ${r.userFirstName} 
        ${r.userLastName || ""}
        ${r.userEmail}
        ${r.companyName}
        `
                      .toLowerCase()
                      .indexOf(term.toLowerCase()) >= 0
              );
          })
        : null;

    return (
        <div style={{display: "flex", justifyContent: "center", margin: 10, marginBottom: 40}}>
            <ReviewModal onClose={closeEditModal} skippable={false} isAdmin={true} initialReview={reviewToOpen} />
            <AdminCustomReviewModal visible={showCustomReviewModal} onClose={closeCustomModal} />
            <div style={{display: "flex", flexDirection: "column", maxWidth: 1400, width: "100%"}}>
                <PageTitleHeader showBack={false}>Reviews</PageTitleHeader>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 20}}>
                    <SearchInput
                        style={{height: 40, maxWidth: 500, width: "100%"}}
                        onChange={evt => setTerm(evt.target.value)}
                        placeholder="Search review"
                    />
                    <StyledButton
                        onClick={() => setShowCustomReviewModal(true)}
                        style={{
                            backgroundColor: "white",
                            color: "var(--secondary-color)",
                            borderRadius: 20,
                            height: 40,
                            width: 160,
                            border: "1px solid var(--secondary-color)"
                        }}>
                        Add custom review
                    </StyledButton>
                </div>
                <RoundedTable
                    rowKey="name"
                    scroll={{x: "max-content", y: 800}}
                    pagination={false}
                    loading={reviews === null}
                    dataSource={reviews || []}
                    columns={columns(reviewInProcess, openReview, deleteReview, approveReview)}
                />
            </div>
        </div>
    );
};
