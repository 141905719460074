import React from "react";
import {FormModal} from "../../components";

export const DeclineInvoiceModal = ({onClose, onSave, visible}) => {
    return (
        <FormModal
            onClose={onClose}
            onSave={onSave}
            visible={visible}
            header="דחה חשבונית"
            subTitle="שימו ❤️ שהספק חשוף לסיבת הדחייה של החשבונית"
            fields={[
                {
                    name: "declineMessage",
                    label: "סיבת הדחייה",
                    type: "textarea"
                }
            ]}
        />
    );
};
