import {requiredProviderFields} from "../../admin/upload/consts.jsx";
import common from "@well-b/well-b-shared";
import {PHONE_NUMBER_ERROR_VALIDATION} from "../../marketplace/consts.jsx";
import {isValidEmailRegex} from "../../components/form/validators.jsx";
import {isEmpty} from "lodash";

export const FormEditModes = {
    Create: "Create",
    Update: "Update",
    Readonly: "Readonly"
};

export const providerFormValidators = {
    [requiredProviderFields.contactPhoneNumber]: contactPhoneNumber => {
        if (isEmpty(contactPhoneNumber)) {
            return "יש להזין מספר טלפון";
        }

        if (!common.isValidPhoneMobileNumber(contactPhoneNumber)) {
            return PHONE_NUMBER_ERROR_VALIDATION;
        }
    },
    [requiredProviderFields.contactLastName]: contactLastName => {
        if (isEmpty(contactLastName)) {
            return "יש להזין שם משפחה";
        }
    },
    [requiredProviderFields.contactFirstName]: contactFirstName => {
        if (isEmpty(contactFirstName)) {
            return "יש להזין שם פרטי";
        }
    },
    [requiredProviderFields.netPaymentTerms]: netPaymentTerms => {
        if (isEmpty(netPaymentTerms)) {
            return "יש להזין תנאי שוטף";
        }
    },
    [requiredProviderFields.description]: description => {
        if (isEmpty(description)) {
            return "יש להזין תיאור בית העסק";
        }
    },
    [requiredProviderFields.email]: email => {
        if (isEmpty(email)) {
            return "יש להזין אימייל";
        }
        if (!isValidEmailRegex(email)) {
            return "יש להזין אימייל תקין";
        }
    },
    [requiredProviderFields.phone]: phone => {
        if (isEmpty(phone)) {
            return "יש להזין טלפון ראשי לקבלת הזמנות";
        }
    },
    [requiredProviderFields.businessNumber]: businessNumber => {
        if (isEmpty(businessNumber)) {
            return "יש להזין מס׳ עוסק או ח.פ";
        }
    },
    [requiredProviderFields.businessName]: businessName => {
        if (isEmpty(businessName)) {
            return "יש להזין שם בית העסק";
        }
    },
    [requiredProviderFields.dealerType]: dealerType => {
        if (isEmpty(dealerType)) {
            return "יש להזין סוג עוסק";
        }
    },
    [requiredProviderFields.assignPartner]: assignPartner => {
        if (isEmpty(assignPartner)) {
            return "יש להזין מנהל/ת שותף";
        }
    }
};
