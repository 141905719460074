import {StringBuilder} from "../../AppUtils.js";
import {dateTimeRangeFormat} from "../../utils/DateUtils.jsx";
import {HeartFilled, ShoppingFilled, UserOutlined} from "@ant-design/icons";
import classnames from "classnames";
import {OrderStatus, statusToTextAndColor} from "../../components/index.jsx";
import {isOrderHasShippingProvider} from "../utils.jsx";
import {orderStatusToStepsLevel} from "./consts.js";
import common from "@well-b/well-b-shared";
import moment from "moment/moment.js";

export const getOrderStepStatus = order => {
    const isOrderReviewedByManager = common.isOrderReviewedByManager(order, order?.needManagerConfirm);

    const isOrderSentForProviderApproval = order?.needManagerConfirm
        ? common.isOrderApprovedByManager(order, order?.needManagerConfirm)
        : true;

    const isOrderReviewedByProvider =
        (order?.status === OrderStatus.accepted || order?.status === OrderStatus.declined) &&
        isOrderSentForProviderApproval;

    const isOrderAccepted = order?.status === OrderStatus.accepted;

    const isOrderDelivered = isOrderAccepted && order?.eventStatusMap?.find(({id}) => id === OrderStatus.delivered);

    const isOrderOnTheWay =
        isOrderAccepted && order?.eventStatusMap?.find(({id}) => id === OrderStatus.PickedUpByDeliveryProvider);

    const timePastAfterDelivery = moment().diff(
        moment(order.dtstart).add(order.deliveryArrivingTimeRange, "hours"),
        "hours"
    );
    const isOrderWaitingForDeliveredFeedback =
        isOrderAccepted && !isOrderDelivered && !isOrderHasShippingProvider(order) && timePastAfterDelivery > 0;

    return {
        isOrderReviewedByManager,
        isOrderSentForProviderApproval,
        isOrderReviewedByProvider,
        isOrderOnTheWay,
        isOrderDelivered,
        isOrderAccepted,
        isOrderWaitingForDeliveredFeedback
    };
};

export const generateLionWheelOrderDeliveryStatusLink = shippingDetails => {
    return `https://members.lionwheel.com/live_track/${shippingDetails?.public_id}`;
};

export const generateLionWheelOrderDeliveryCertificateLink = shippingDetails =>
    `https://members.lionwheel.com/tasks/waybill/${shippingDetails?.public_id}`;

export const generateOrderStatusSteps = order => {
    if (!order) {
        return [];
    }
    const {
        isOrderReviewedByManager,
        isOrderSentForProviderApproval,
        isOrderReviewedByProvider,
        isOrderOnTheWay,
        isOrderDelivered
    } = getOrderStepStatus(order);
    return [
        {
            id: "pending for manager approve",
            title: "ההזמנה נשלחה לאישור מנהל",
            subTitle: new StringBuilder()
                .append(order?.productName)
                .append("|")
                .append(dateTimeRangeFormat(order?.createdAt))
                .toString(),
            isStepComplete: order?.needManagerConfirm,
            icon: (
                <UserOutlined
                    className={classnames("timeline-custom-icon", {
                        "step-not-complete-icon": !order?.needManagerConfirm
                    })}
                />
            ),
            shouldHide: !order?.needManagerConfirm
        },
        {
            id: "manager approve comments",
            title: "מענה מנהל",
            subTitle:
                !isOrderReviewedByProvider && order?.status === OrderStatus.cancelled
                    ? statusToTextAndColor[OrderStatus.canceledByManager]?.[0] ?? ""
                    : "",
            subTitleLevel: orderStatusToStepsLevel[order?.status] ?? "",
            description:
                order?.status === OrderStatus.cancelled && !!order?.cancelReason
                    ? new StringBuilder().append("סיבת ביטול -").append(`${order.cancelReason}`).toString()
                    : "",
            isStepComplete: isOrderReviewedByManager,
            icon: (
                <UserOutlined
                    className={classnames("timeline-custom-icon", {
                        "step-not-complete-icon": !isOrderReviewedByManager
                    })}
                />
            ),
            shouldHide: !order?.needManagerConfirm
        },
        {
            id: "order sent to provider approve",
            title: "ההזמנה נשלחה לאישור השותף",
            subTitle: new StringBuilder()
                .append(order?.productName)
                .append("|")
                .append(dateTimeRangeFormat(order?.createdAt))
                .toString(),
            isStepComplete: isOrderSentForProviderApproval,
            icon: (
                <UserOutlined
                    className={classnames("timeline-custom-icon", {
                        "step-not-complete-icon": !isOrderSentForProviderApproval
                    })}
                />
            )
        },
        {
            id: "pending for provider approve",
            title: "מענה השותף",
            subTitle: isOrderReviewedByProvider
                ? statusToTextAndColor?.[order?.status]?.[0] ?? ""
                : "ההזמנה טרם הגיעה לאישור השותף",
            subTitleLevel: isOrderReviewedByProvider ? orderStatusToStepsLevel[order?.status] ?? "" : "",
            isStepComplete: isOrderReviewedByProvider,
            icon: (
                <UserOutlined
                    className={classnames("timeline-custom-icon", {
                        "step-not-complete-icon": !isOrderReviewedByProvider
                    })}
                />
            )
        },
        {
            id: "delivery arrive",
            title: "ההזמנה בשילוח ליעד",
            subTitle: "קישור מעקב אחרי המשלוח",
            subTitleLink: generateLionWheelOrderDeliveryStatusLink(order?.shippingDetails),
            isStepComplete: isOrderOnTheWay || isOrderDelivered,
            icon: (
                <ShoppingFilled
                    className={classnames("timeline-custom-icon", {
                        "step-not-complete-icon": !isOrderOnTheWay
                    })}
                />
            ),
            shouldHide: !isOrderHasShippingProvider(order)
        },
        {
            id: "order arrived to destination",
            title: "ההזמנה הגיעה ליעד",
            subTitle: "",
            isStepComplete: isOrderDelivered,
            icon: (
                <HeartFilled
                    className={classnames("timeline-custom-icon", {
                        "step-not-complete-icon": !isOrderDelivered
                    })}
                />
            )
        }
    ].filter(orderStep => !orderStep.shouldHide);
};
