import React, {useCallback, useContext, useMemo} from "react";
import classnames from "classnames";
import {matchPath} from "react-router";
import {ProviderContext} from "./ProviderContext";
import {MarketplaceContext, MarketplaceContextDefaultValues} from "../marketplace/marketplaceContext";
import {ProductDetailsPage} from "../marketplace/ProductDetailsPage";
import {PageLoader} from "../components";

export const ProviderProductItemDetails = ({history}) => {
    const {providerDetails} = useContext(ProviderContext);

    const {productId} = useMemo(
        () =>
            matchPath(history.location.pathname, {
                isExact: false,
                path: `/dashboard/products/:productId?`
            })?.params ?? {},
        [history.location.pathname]
    );
    const product = useMemo(() => {
        if (!productId || !providerDetails?.services) return null;

        return providerDetails.services.find(product => product.productId === productId);
    }, [productId, providerDetails]);

    const goBack = useCallback(() => {
        if (history.length > 1) {
            history.goBack();
        } else {
            history.push("/dashboard/products");
        }
    }, [history]);

    return (
        <>
            <MarketplaceContext.Provider
                value={{
                    ...MarketplaceContextDefaultValues,
                    providerDetails,
                    product,
                    providerId: providerDetails?.providerId,
                    productId
                }}>
                <div
                    className={classnames("wb-categories-container", "marketplace-category-item-details")}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        direction: "ltr",
                        position: "sticky",
                        marginTop: 50
                    }}>
                    {product ? (
                        <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                                <ProductDetailsPage goBack={goBack} />
                        </div>
                    ) : (
                        <PageLoader style={{top: "80px"}} />
                    )}
                </div>
            </MarketplaceContext.Provider>
        </>
    );
};
