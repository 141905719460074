import React from "react";

import "../../order-information-card.css";
import classnames from "classnames";
import {PLACE_ERROR, WARN_LEVEL} from "../../../marketplace/MarketplaceUtils.jsx";
import {ConfigProvider, Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import {getOrderDiscountPrice, getOrderPrice} from "../../../utils/calculationUtils.jsx";
import {StringBuilder} from "../../../AppUtils.js";

export const OrderPricingInformation = ({eventInfo}) => {
    return (
        <div className="order-information-card-bill-section-title">
            <PriceLine
                className={`${
                    !(
                        eventInfo?.couponDiscountAmount ||
                        eventInfo?.companyDiscountAmount ||
                        eventInfo?.deliveryPrice ||
                        eventInfo?.tip
                    )
                        ? " total-price"
                        : ""
                }`}
                label="מחיר הזמנה"
                value={new StringBuilder(eventInfo?.offeredPrice?.toFixed(2)).append("₪ כולל מע״מ").toString()}
            />

            {!!eventInfo.deliveryPrice && (
                <PriceLine
                    titleClassName={classnames({
                        "order-information-card-price-line-warning":
                            eventInfo.errors?.[PLACE_ERROR]?.level === WARN_LEVEL
                    })}
                    label="מחיר משלוח"
                    value={new StringBuilder(eventInfo.deliveryPrice).append(`₪ כולל מע״מ`).toString()}
                    tooltip={
                        eventInfo.errors?.[PLACE_ERROR]?.level === WARN_LEVEL
                            ? "לא הצלחנו למצוא את הכתובת שהוזנה. לכן מחיר המשלוח עלול להשתנות לאחר בחינה נוספת."
                            : null
                    }
                />
            )}

            {getOrderDiscountPrice(eventInfo) ? (
                <>
                    <PriceLine
                        label="הנחה"
                        value={new StringBuilder(`${Math.abs(getOrderDiscountPrice(eventInfo))}-`)
                            .append("₪")
                            .toString()}
                    />

                    {!!eventInfo.couponCode && <PriceLine label="קופון" value={eventInfo.couponCode} />}
                </>
            ) : null}

            {!!eventInfo.tip && (
                <PriceLine label="טיפ" value={new StringBuilder(eventInfo.tip).append("₪ כולל מע״מ").toString()} />
            )}

            {eventInfo?.couponDiscountAmount ||
            eventInfo?.companyDiscountAmount ||
            eventInfo?.deliveryPrice ||
            eventInfo?.tip ? (
                <>
                    <PriceLine
                        className="total-price"
                        label="סה״כ"
                        value={new StringBuilder(Math.max(0, getOrderPrice(eventInfo)))
                            .append("₪ כולל מע״מ")
                            .toString()}
                    />

                    <PriceLine
                        className="total-price"
                        label="סה״כ (לא כולל מע״מ)"
                        value={new StringBuilder(Math.max(0, getOrderPrice(eventInfo, false)))
                            .append("₪  לא כולל מע״מ")
                            .toString()}
                    />
                </>
            ) : null}
        </div>
    );
};

const PriceLine = ({className, titleClassName, label, value, tooltip}) => {
    return (
        <div className={classnames("order-information-card-price-line", className)}>
            <div>
                <span className={titleClassName}>{label}</span>
                {!!tooltip && (
                    <span>
                        <ConfigProvider direction={"rtl"}>
                            <Tooltip title={tooltip}>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </ConfigProvider>
                    </span>
                )}
            </div>
            <div>{value}</div>
        </div>
    );
};
