import React from "react";

export const BalanceInfoCard = ({icon, title, subTitle, style = {}, margin}) => (
    <div
        style={{
            backgroundColor: "rgba(131,152,166,0.1)",
            borderRadius: 20,
            width: 380,
            display: "flex",
            alignItems: "center",
            padding: margin || 25,
            height: 120,
            ...style
        }}>
        <div
            style={{
                minWidth: 70,
                minHeight: 70,
                maxWidth: 70,
                maxHeight: 70,
                borderRadius: 20,
                marginRight: margin || 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white"
            }}>
            {icon}
        </div>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <span
                style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 16,
                    opacity: 0.6,
                    color: "var(--secondary-color)",
                    marginBottom: 5
                }}>
                {title}
            </span>
            <span
                style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 24,
                    color: "var(--secondary-color)",
                    fontWeight: 900
                }}>
                {subTitle}
            </span>
        </div>
    </div>
);
