import React, {useState, useEffect, useContext} from "react";
import {Form, Checkbox, Button, Input, Select, Spin} from "antd";
import {Link} from "react-router-dom";
import {FormInput, FormConfirmButton} from "../components/form";
import {HttpClient} from "../http/HttpClient";
import {PageSubTitleLabel, PageTitleLabel} from "../components/PageTitle";
import {EventBus} from "../bus/EventBus";
// import * as EmailValidator from "email-validator";
import {FormAbandonment} from "../FormAbandonment";
import {parse as qsParse} from "query-string";
import {AppContext} from "../AppContext";
import {Logo} from "../images/Images";
import {openWindow} from "../http/WindowOpener";
import {CompanyBranchSelect} from "./CompanyBranchSelect";
import {PageLoader} from "../components/PageLoader";
import {validateEmailExistence} from "../components/form/validators";
import {SmartImageContainer} from "../components/SmartImageContainer";

const {Option} = Select;

const formAbandonment = new FormAbandonment("Employee Sign Up");

export const CompanyEmployeeSignUp = ({history, match, location}) => {
    let {theme} = useContext(AppContext);
    const [signingUp, setSigningUp] = useState(false);
    const [companyName, setCompanyName] = useState(null);
    const [company, setCompany] = useState(null);

    useEffect(() => {
        formAbandonment.listToAbandonment();

        const {search} = location;
        const {cn} = qsParse(search);
        try {
            if (cn) setCompanyName(atob(cn));
        } catch (ignore) {}
        fetchCompany();

        return () => {
            formAbandonment.stopListeningToAbandonment();
        };
    }, []);

    const fetchCompany = () => {
        Promise.resolve().then(async () => {
            const {companyId} = match.params;
            try {
                const company = await HttpClient.get(`/api/companies/${companyId}/shallow`);
                setCompanyName(company.name);
                setCompany(company);
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we couldn't complete your request :("}, cta: {hide: true}},
                    e.message
                );
            }
        });
    };

    const onFinish = async values => {
        const {companyId} = match.params;
        try {
            setSigningUp(true);
            await HttpClient.put(`/api/companies/${companyId}/signup`, values, true);
            formAbandonment.stopListeningToAbandonment();
            history.push(`/email/${btoa(values.email)}/verify`);
        } catch (e) {
            if (e.statusCode === 400) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: e.message, hideSteps: true}, cta: {hide: true}},
                    e.message
                );
            } else {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we couldn't complete your sign up :("}, cta: {hide: true}},
                    e.message
                );
            }
        }

        setSigningUp(false);
    };

    const openTermsOfUse = () => {
        openWindow("https://www.well-b.biz/terms-and-conditions", "_blank", true);
    };

    if (!companyName || !company) {
        return <PageLoader align="flex-start" top={100} />;
    }

    theme = company.branding
        ? {
              primary: company.branding.primaryColor,
              secondary: company.branding.secondaryColor
          }
        : theme;

    const logo = company.branding && company.branding.logoUrl ? company.branding.logoUrl : Logo.image;

    return (
        <div style={{display: "flex", alignItems: "center", flexDirection: "column", marginBottom: 40, marginTop: 40}}>
            {logo === Logo.image ? (
                <img width={"160"} height={"40"} alt="well-b-logo" src={logo} />
            ) : (
                <SmartImageContainer
                    width={"125"}
                    height={"50"}
                    transformations={{
                        crop: "lpad"
                    }}
                    src={logo}
                />
            )}
            <PageTitleLabel style={{fontSize: 32, fontWeight: 900}}>{companyName}</PageTitleLabel>
            <PageTitleLabel style={{marginTop: companyName ? 0 : 20}}>Create your account</PageTitleLabel>
            <PageSubTitleLabel>Enjoy rich online culture with your peers</PageSubTitleLabel>
            <Form
                onFinish={async vals => await onFinish(vals)}
                onValuesChange={changedValues => formAbandonment.addInteractedFields(Object.keys(changedValues))}
                style={{maxWidth: 440, padding: 10, width: "100%"}}>
                <Input.Group style={{width: "100%", display: "flex"}}>
                    <Form.Item
                        style={{width: "100%", minWidth: 165}}
                        name="firstName"
                        rules={[{required: true, message: "Please enter your first name."}]}>
                        <FormInput placeholder="First name" />
                    </Form.Item>
                    <Form.Item
                        style={{marginLeft: 10, width: "100%", minWidth: 165}}
                        name="lastName"
                        rules={[{required: true, message: "Please enter your last name."}]}>
                        <FormInput placeholder="Last name" />
                    </Form.Item>
                </Input.Group>
                <Form.Item
                    validateFirst={true}
                    style={{width: "100%"}}
                    name="email"
                    rules={[
                        {
                            required: true,
                            type: "email",
                            message: "Please enter valid company's email."
                        },
                        {
                            validateTrigger: "onSubmit",
                            validator: async (_, email) => await validateEmailExistence(email)
                        }
                    ]}>
                    <FormInput type="email" placeholder="Your work email" />
                </Form.Item>
                <Form.Item name="gender" rules={[{required: true, message: "Please select gender."}]}>
                    <Select placeholder="Gender">
                        <Option value="female">Female</Option>
                        <Option value="male">Male</Option>
                        <Option value="generic">None of them</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    style={{width: "100%"}}
                    name="phone"
                    rules={[
                        {
                            validator: (_, value) => {
                                if (value && value.length < 8) {
                                    return Promise.reject("Please enter valid number.");
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        }
                    ]}>
                    <FormInput type="number" placeholder="Your phone number" />
                </Form.Item>
                <Form.Item name="position">
                    <FormInput placeholder="Your position title (optional)" />
                </Form.Item>
                {company && Array.isArray(company.branches) && company.branches.length > 0 ? (
                    <Form.Item
                        name="branch"
                        rules={[
                            {
                                required: true,
                                message: "Please select site."
                            }
                        ]}>
                        <CompanyBranchSelect branches={company.branches} />
                    </Form.Item>
                ) : null}
                <Form.Item
                    style={{marginLeft: 5}}
                    name="terms"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject("Please accept terms of use.")
                        }
                    ]}>
                    <Checkbox>
                        Agree to{" "}
                        <Button
                            style={{textDecoration: "underline", padding: 0}}
                            type="link"
                            onClick={evt => openTermsOfUse(evt)}>
                            Terms & Conditions
                        </Button>
                    </Checkbox>
                </Form.Item>
                <Form.Item style={{marginBottom: 15}}>
                    <FormConfirmButton
                        loading={signingUp}
                        htmlType="submit"
                        style={{backgroundColor: theme.primary, color: theme.textPrimary}}>
                        Create account
                    </FormConfirmButton>
                </Form.Item>
                <div style={{width: "100%"}}>
                    <label style={{marginRight: 5, fontSize: 16, color: "#8492A6"}}>Already have an account?</label>
                    <Link style={{fontSize: 16, marginRight: 5, color: "#005DD9"}} to="/login">
                        Sign in
                    </Link>
                </div>
                <div style={{width: "100%", marginTop: 10}}>
                    <Link style={{fontSize: 16, color: "#005DD9"}} to="/forgot">
                        Forgot your password?
                    </Link>
                </div>
            </Form>
        </div>
    );
};
