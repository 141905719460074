import {Form, Modal, Input, message} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {CloseIcon, CreditCardIcon} from "../icons";
import {PageTitleLabel} from "../components/PageTitle";
import {FormInput} from "../components/form";
import {StyledButton} from "../components/StyledButton";
import {endsWith} from "lodash";
import moment from "moment";
import {HttpClient} from "../http/HttpClient";
import {EventBus} from "../bus/EventBus";
import {AppContext} from "../AppContext";
import {GoogleAnalytics} from "../GoogleAnalytics";

export const CreditCardDetailsModal = () => {
    const {me} = useContext(AppContext);
    const [visible, setVisible] = useState(false);
    const [saving, setSaving] = useState(false);
    const [cardExpiration, setCardExpiration] = useState(null);
    const [form] = Form.useForm();

    useEffect(() => {
        EventBus.on("credit_card_details:open", () => {
            setVisible(true);
        });
    }, []);

    const saveCard = async ({holderName, cardNumber, cardExpiration, cardCvv}) => {
        try {
            const {hasToken} = await HttpClient.post(`/api/billing/card`, {
                cc_number: cardNumber,
                cc_cvv: cardCvv,
                cc_validity: moment(cardExpiration, "MM / YY").format("MMYY"),
                cc_holder_name: holderName
            });

            if (hasToken) {
                GoogleAnalytics.event("Billing", "Saved New Credit Card", me.type);
                EventBus.trigger("me:update", {...me, premiumAccount: true});
                closeMe();
            } else {
                showErrorMessage();
            }
        } catch (e) {
            showErrorMessage();
        }
    };

    const showErrorMessage = () => {
        message.error("Unfortunately we couldn't save your credit card information :(", 3);
    };

    const onCardExpirationChange = value => {
        if (value.length === 2) {
            setCardExpiration(value + " / ");
        } else if (endsWith(value, " /")) {
            setCardExpiration(value.replace(" /", ""));
        } else {
            setCardExpiration(value);
        }
    };

    const onlyExpirationNumbers = e => {
        if (e.key === "Delete" || e.key === "Backspace") {
            return;
        } else if (
            e.key === "ArrowRight" ||
            e.key === "ArrowLeft" ||
            isNaN(parseInt(e.key, 10)) ||
            (cardExpiration && cardExpiration.length >= 7)
        ) {
            e.preventDefault();
        }
    };

    const onlyNumbers = e => {
        if (
            e.key !== "ArrowRight" &&
            e.key !== "ArrowLeft" &&
            e.key !== "Delete" &&
            e.key !== "Backspace" &&
            isNaN(parseInt(e.key, 10))
        ) {
            e.preventDefault();
        }
    };

    const closeMe = () => {
        form.resetFields();
        setVisible(false);
    };

    const validateCreditCard = async values => {
        setSaving(true);
        try {
            const {cardNumber, cardExpiration} = values;
            await HttpClient.post(
                "/api/billing/card/validate",
                {
                    cardNumber,
                    cardExpiration
                },
                true
            );
            await saveCard(values);
        } catch (e) {
            if (e.statusCode === 400) {
                form.setFields([
                    {name: ["cardNumber"], errors: ["Invalid card information"]},
                    {name: ["cardExpiration"], errors: ["Invalid card information"]}
                ]);
            } else {
                showErrorMessage();
            }
        }
        setSaving(false);
    };

    return (
        <Modal
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={closeMe}
            footer={null}
            title={null}
            closable={true}
            closeIcon={<CloseIcon fill="#767F90" />}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <PageTitleLabel style={{fontSize: 20, fontWeight: 400, marginBottom: 30}}>
                    Credit card information
                </PageTitleLabel>
                <Form
                    form={form}
                    onFinish={validateCreditCard}
                    fields={[{name: ["cardExpiration"], value: cardExpiration}]}>
                    <Form.Item
                        name="holderName"
                        rules={[
                            {
                                required: true,
                                message: "Please enter name."
                            }
                        ]}>
                        <FormInput placeholder="Name on the card" />
                    </Form.Item>
                    <Form.Item
                        name="cardNumber"
                        rules={[
                            {
                                required: true,
                                message: "Please enter card number."
                            }
                        ]}>
                        <FormInput onKeyDown={onlyNumbers} placeholder="Card number" />
                    </Form.Item>
                    <Form.Item>
                        <Input.Group
                            compact={true}
                            style={{display: "flex", alignItems: "flex-start", marginBottom: 0}}>
                            <Form.Item
                                name="cardExpiration"
                                style={{marginRight: 10, marginBottom: 0}}
                                rules={[
                                    {
                                        required: true,
                                        validator: (_, value) => {
                                            try {
                                                const parts = value.split(" / ");
                                                const month = parts[0];
                                                const year = parts[1];
                                                if (
                                                    month.length === 2 &&
                                                    !isNaN(parseInt(month, 10)) &&
                                                    year.length === 2 &&
                                                    !isNaN(parseInt(year, 10)) &&
                                                    month <= 12 &&
                                                    year >= moment().format("YY")
                                                ) {
                                                    return Promise.resolve();
                                                }
                                            } catch (e) {}
                                            return Promise.reject("Please enter valid expiration.");
                                        }
                                    }
                                ]}>
                                <FormInput
                                    onKeyDown={onlyExpirationNumbers}
                                    onChange={e => onCardExpirationChange(e.target.value)}
                                    placeholder="MM / YY"
                                />
                            </Form.Item>
                            <Form.Item
                                style={{marginBottom: 0}}
                                name="cardCvv"
                                rules={[
                                    {
                                        required: true,
                                        min: 3,
                                        message: "Please enter valid CVV."
                                    }
                                ]}>
                                <FormInput onKeyDown={onlyNumbers} placeholder="CVV" />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                    <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                        <Form.Item style={{marginBottom: 0}}>
                            <StyledButton
                                onClick={() => form.submit()}
                                loading={saving}
                                icon={
                                    <CreditCardIcon style={{fill: "white", width: 22, height: 17, marginRight: 10}} />
                                }
                                style={{color: "white", backgroundColor: "#13C296", width: 145}}>
                                Save card
                            </StyledButton>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};
