import React, {useContext, useState} from "react";
import {StyledButton} from "../../components/StyledButton";
import moment from "moment-timezone";
import {locationKeyToTextMap, LocationLabels} from "../../data/locations";
import {EventInfoModalV2} from "../../event/info/EventInfoModalV2";
import {EventInfoManager} from "../../event/info/EventInfoManager";
import {AppContext} from "../../AppContext";
import {EXCenterInfoTag} from "../infos/EXCenterInfoTag";
import {EXCenterContext} from "../EXCenterContext";
import {AutoDirectionProvider} from "../../AutoDirectionProvider";
import {EXCenterBookingModal} from "./EXCenterBookingModal";
import {EXCenterProperties} from "../utils/EXCenterProperties";
import {ExternalLinkButton} from "../../components/ExternalLinkButton";
import {EXCenterTitleTooltip} from "../EXCenterTitleTooltip";
import {SmartImageContainer} from "../../components/SmartImageContainer";

const EventActionButton = ({event, registering, doBooking, registered, unregister, doRegistration, date}) => {
    const {me} = useContext(AppContext);
    const {theme} = useContext(EXCenterContext);

    if (event.externalLink && event.externalLink.enabled) {
        return (
            <ExternalLinkButton
                className="wb-center-action-button"
                style={{
                    color: "white",
                    backgroundColor: theme.primaryColor,
                    borderRadius: 20,
                    height: 40,
                    width: 135,
                    marginTop: 30
                }}
                href={`/api/events/${event.eventId}/click?date=${date}`}>
                {event.externalLink.buttonText}
            </ExternalLinkButton>
        );
    } else if (me.type === "employee") {
        return (
            <StyledButton
                className="wb-center-action-button"
                loading={registering}
                style={{
                    color: "white",
                    backgroundColor: theme.primaryColor,
                    borderRadius: 20,
                    height: 40,
                    width: 135,
                    marginTop: 30
                }}
                onClick={e => {
                    if (event.appointmentBased) {
                        doBooking();
                    } else {
                        if (registered) {
                            unregister();
                        } else {
                            doRegistration(event);
                        }
                    }
                    e.stopPropagation();
                    e.preventDefault();
                }}>
                {event.appointmentBased ? "Book now" : registered ? "Unregister" : "Register now"}
            </StyledButton>
        );
    } else {
        return (
            <StyledButton
                className="wb-center-action-button"
                style={{
                    color: "white",
                    backgroundColor: theme.primaryColor,
                    borderRadius: 20,
                    height: 40,
                    width: 135,
                    marginTop: 30
                }}>
                Read more
            </StyledButton>
        );
    }
};

export const EXCenterEventCard = ({eventInfo, date}) => {
    const {theme} = useContext(EXCenterContext);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showAppointmentFlow, setShowAppointmentFlow] = useState(false);

    const doBooking = () => {
        setShowAppointmentFlow(true);
    };

    const closeAppointmentFlow = () => {
        setShowAppointmentFlow(false);
    };

    const sessionName = eventInfo.sessionName || eventInfo.productName || eventInfo.serviceName;

    const oldEvent = moment(date).endOf("day").isBefore(moment());

    return (
        <>
            <EXCenterBookingModal
                visible={showAppointmentFlow}
                onClose={closeAppointmentFlow}
                eventInfo={eventInfo}
                date={date}
            />
            <EventInfoManager
                eventInfo={eventInfo}
                dateMom={moment(date)}
                onBook={doBooking}
                ignoreProviderInfo={true}
                maxWidth={380}
                Component={({
                    event,
                    isAppointmentBased,
                    registering,
                    registered,
                    doRegistration,
                    unregister,
                    doBooking,
                    join
                }) => (
                    <>
                        <EventInfoModalV2
                            event={event}
                            currentStart={date}
                            currentEnd={
                                !event.appointmentBased
                                    ? moment(date).add(eventInfo.duration, "minutes").toDate()
                                    : null
                            }
                            visible={showInfoModal}
                            onClose={() => setShowInfoModal(false)}
                            onBook={doBooking}
                        />
                        <div
                            className="wb-ss-no-margin wb-ss-card-margin"
                            onClick={() => setShowInfoModal(true)}
                            style={{
                                height: 472,
                                width: 360,
                                borderRadius: 10,
                                overflow: "hidden",
                                backgroundColor: "white",
                                boxShadow: "0 10px 30px 0 rgba(22,29,37,0.1)",
                                margin: 10,
                                display: "flex",
                                flexDirection: "column",
                                cursor: "pointer"
                            }}>
                            <SmartImageContainer
                                src={event.productCoverPhoto}
                                width={360}
                                height={220}
                                style={{
                                    width: "100%",
                                    position: "relative"
                                }}>
                                <EXCenterInfoTag tags={[event.serviceName || "Internal"]} />
                            </SmartImageContainer>
                            <div style={{display: "flex", flexDirection: "column", padding: 20, height: 252}}>
                                <AutoDirectionProvider
                                    text={sessionName}
                                    style={{
                                        color: theme.textColor,
                                        fontSize: 24,
                                        fontWeight: 500,
                                        height: 30
                                    }}>
                                    <EXCenterTitleTooltip
                                        title={sessionName}
                                        minLength={EXCenterProperties.Section.Card.Title.Length}
                                    />
                                </AutoDirectionProvider>
                                <div
                                    style={{
                                        marginTop: 20,
                                        height: 80,
                                        display: "flex",
                                        flexDirection: "column"
                                    }}>
                                    <span
                                        style={{
                                            color: theme.textColor,
                                            fontSize: 16,
                                            fontWeight: 600
                                        }}>
                                        {moment(date).format("MMM Do, dddd, HH:mm")}-
                                        {moment(date).add(eventInfo.duration, "minutes").format("HH:mm")}
                                    </span>
                                    <span
                                        style={{
                                            color: theme.textColor,
                                            fontSize: 16,
                                            fontWeight: 200,
                                            marginTop: 5
                                        }}>
                                        {event.location === LocationLabels.ONLINE
                                            ? locationKeyToTextMap[LocationLabels.ONLINE]
                                            : event.address}
                                    </span>
                                </div>
                                {!oldEvent ? (
                                    <div style={{display: "flex", justifyContent: "space-between"}}>
                                        <EventActionButton
                                            event={event}
                                            registering={registering}
                                            registered={registered}
                                            doBooking={doBooking}
                                            doRegistration={doRegistration}
                                            unregister={unregister}
                                            date={moment(date).format("YYYY-MM-DD")}
                                        />
                                        {event.location === LocationLabels.ONLINE && event.meetingLink && registered ? (
                                            <span
                                                style={{display: "flex", alignItems: "flex-end"}}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                }}>
                                                <a
                                                    style={{fontSize: 16, paddingBottom: 10}}
                                                    href={`/api/events/${event.eventId}/track`}
                                                    target="_blank">
                                                    Join
                                                </a>
                                            </span>
                                        ) : null}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </>
                )}
            />
        </>
    );
};
