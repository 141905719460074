export const ProviderProjectionForExcel = {
    provider: [
        "approved",
        "createdAt",
        "dealerType",
        "kosherCertificateExpireDate",
        "kosherCertificateImageUrl",
        "services",
        "providerId",
        "businessName",
        "businessNumber",
        "contactPhoneNumber",
        "contactEmail",
        "arrearsDebt",
        "futureDebt",
        "contactFirstName",
        "contactLastName"
    ],
    product: ["isActive", "productKosherType", "services", "commission", "productId"]
};

export const ProviderOrdersProjection = [
    "providerId",
    "offeredPrice",
    "commission",
    "discount",
    "deliveryPrice",
    "tip"
];
