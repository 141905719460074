import React, {useEffect} from "react";
import BaseDynamicLayoutPage from "./BaseDynamicLayoutPage";
import {useRequest} from "../../utils/hooks";
import {MarketplaceFilter} from "../MarketplaceFilter/MarketplaceFilter.jsx";
import {CompanyHomePageTypes} from "../../admin/AdminCompanies/CompanyPageLayoutModal/consts.js";

export const DynamicCompanyHomePage = ({history}) => {
    const [mainPageLayout, isLoadingMainPageLayout] = useRequest("/api/pageLayout/getMainPageLayout");

    useEffect(() => {
        if (mainPageLayout?.pageLayoutType === CompanyHomePageTypes.selectPage) {
            history.push(`/selectionPage/${mainPageLayout.selectPageId}`);
        }
    }, [mainPageLayout]);

    return (
        <>
            {mainPageLayout?.pageLayoutType === CompanyHomePageTypes.pageLayout && (
                <>
                    {!isLoadingMainPageLayout && !mainPageLayout?.pageLayout?.isIsolated ? <MarketplaceFilter /> : null}
                    <BaseDynamicLayoutPage
                        history={history}
                        pageLayout={mainPageLayout?.pageLayout}
                        loadingPageLayoutPage={isLoadingMainPageLayout}
                    />
                </>
            )}
        </>
    );
};
