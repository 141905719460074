import React from "react";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import {MenuOutlined} from "@ant-design/icons";
import classnames from "classnames";
import {get} from "lodash";

export const DragHandle = SortableHandle(() => <MenuOutlined style={{cursor: "grab", color: "#999"}} />);
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableTableContainer = SortableContainer(props => <tbody {...props} />);

export const DraggableBodyRow = ({className, style, dataSource, rowKey, ...restProps}) => {
    const index = dataSource.findIndex(row => get(row, rowKey) === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
};

export const DraggableTable = ({helperClass, ...props}) => (
    <SortableTableContainer
        useDragHandle
        disableAutoscroll
        helperClass={classnames("row-dragging", helperClass)}
        {...props}
    />
);
