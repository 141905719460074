import React, {useContext, useEffect, useState} from "react";
import {message, Spin} from "antd";
import {EXCenterUpdateCard} from "./EXCenterUpdateCard";
import {HttpClient} from "../../http/HttpClient";
import {EXCenterSectionDesignMenu} from "../control/EXCenterSectionDesignMenu";
import {EXCenterContext} from "../EXCenterContext";
import {AppContext} from "../../AppContext";
import {CarouselArrow} from "../../components/carousel/CarouselArrows";
import {WBCarousel} from "../../components/carousel/WBCarousel";
import {EXCenterProperties} from "../utils/EXCenterProperties";
import {PageLoader} from "../../components/PageLoader";
import {SmartImageContainer} from "../../components/SmartImageContainer";

export const EXCenterUpdatesSection = () => {
    const sectionId = "updates";
    const [updates, setUpdates] = useState(null);
    const {designInfoPerSection, theme} = useContext(EXCenterContext);
    const {me} = useContext(AppContext);

    const carouselProps = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        nextArrow: (
            <CarouselArrow
                backgroundColor={theme.primaryColor}
                arrowColor="white"
                direction={CarouselArrow.Direction.NEXT}
            />
        ),
        prevArrow: (
            <CarouselArrow
                backgroundColor={theme.primaryColor}
                arrowColor="white"
                direction={CarouselArrow.Direction.PREV}
            />
        ),
        accessibility: true,
        arrows: true,
        className: "wb-carousel wb-center-carousel-updates"
    };

    useEffect(() => {
        Promise.resolve().then(async () => await refreshUpdates());
    }, []);

    const refreshUpdates = async () => {
        try {
            const contents = await HttpClient.get(`/api/content/sections/${sectionId}`);
            setUpdates(contents);
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
    };

    if (!Array.isArray(updates) || updates.length === 0) {
        return null;
    }

    const sectionDesignInfo = designInfoPerSection[sectionId];

    if (me.type === "employee" && !sectionDesignInfo.display) {
        return null;
    }

    return (
        <div className="wb-center-updates-section" style={{marginTop: EXCenterProperties.Section.Top}}>
            <EXCenterSectionDesignMenu
                className="wb-center-design-updates-menu"
                sectionId={sectionId}
                coverSize={{width: 1440, height: 700}}
                onChange={refreshUpdates}
                editItems={{
                    coverPhoto: true,
                    description: true,
                    hideControls: true,
                    backgroundPhoto: {
                        width: 1440,
                        height: 700
                    }
                }}
            />
            <SmartImageContainer
                className="wb-center-section"
                width={1440}
                height={700}
                src={sectionDesignInfo.backgroundPhoto || sectionDesignInfo.coverPhoto}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: 120,
                    padding: 130,
                    width: "100%",
                    opacity: EXCenterProperties.Section.opacityByDisplay(sectionDesignInfo)
                }}>
                <div className="wb-ss-carousel" style={{display: "block", maxWidth: 900, width: "100%"}}>
                    {updates === null ? <PageLoader align="flex-start" top={80} height={400} /> : null}
                    <WBCarousel carouselProps={carouselProps} primaryColor={theme.primaryColor}>
                        {updates !== null ? (
                            Array.isArray(updates) && updates.length === 0 ? (
                                <EXCenterUpdateCard
                                    item={{
                                        sessionName: "",
                                        productName: "No updates..."
                                    }}
                                />
                            ) : (
                                updates.map(item => <EXCenterUpdateCard key={item.eventId} item={item} />)
                            )
                        ) : null}
                    </WBCarousel>
                </div>
            </SmartImageContainer>
        </div>
    );
};
