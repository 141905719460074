import React, {useEffect, useContext, useReducer, useState} from "react";
import {Button, Collapse, Divider, Progress, Spin, Tooltip, Upload, message, Card} from "antd";
import {HttpClient} from "../http/HttpClient";
import {daysArray} from "../components/Availability";
import PageTitleHeader, {PageTitleLabel} from "../components/PageTitle";
import {FormBottomBar} from "../components/form";
import {StyledButton} from "../components/StyledButton";
import {withRouter} from "react-router";
import {memoize, truncate, uniq} from "lodash";
import {AppContext} from "../AppContext";
import {EventBus} from "../bus/EventBus";
import {WhatsAppOutlined, InfoCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import {ContactPhone} from "../support/ContactPhone";
import {Images} from "../images/Images";
import {ProviderProfession} from "./ProviderProfession";
import {languages} from "../data/languages";

import {
    AppointmentIcon,
    BuildingIcon,
    CertificationIcon,
    ClockIcon,
    DestinationIcon,
    EditIcon,
    GlobeIcon,
    InsuranceIcon,
    LocationIcon,
    PencilIcon,
    PortfolioIcon,
    TimerIcon,
    YouTubeIcon
} from "../icons";
import {locationKeyToTextMap, LocationLabels} from "../data/locations";
import {inject, observer} from "mobx-react";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {ProviderVideoModal, UploadNewVideo, VideoStatus} from "./ProviderVideoModal";
import {isAppointmentBasedProfession} from "../data/professions";
import {ProviderPortfolioUploadModal} from "./ProviderPortfolioUploadModal";
import {ProviderPortfolioViewModal} from "./ProviderPortfolioViewModal";
import {AutoDirectionProvider} from "../AutoDirectionProvider";
import cover_placeholder from "../images/cover_placeholder.jpg";
import {ProviderReviews} from "./ProviderReviews";
import {PageLoader} from "../components/PageLoader";
import {SmartImageContainer} from "../components/SmartImageContainer";

const {Panel} = Collapse;

const ProviderInfoCard = ({children}) => (
    <div
        style={{display: "flex", alignItems: "center", fontSize: 16, minWidth: 220, marginBottom: 20, marginRight: 15}}>
        {children}
    </div>
);

const PortfolioButton = ({isMe, providerDetails, onPortfolioView, onEditPortfolio}) => {
    const {theme} = useContext(AppContext);
    const hasImages = Array.isArray(providerDetails.portfolioImages) && providerDetails.portfolioImages.length > 0;

    if (isMe) {
        if (hasImages) {
            return (
                <StyledButton
                    icon={<PortfolioIcon style={{marginRight: 11, marginTop: 2}} />}
                    style={{borderRadius: 25, backgroundColor: "#273444", color: "white", margin: 5, width: 160}}
                    onClick={() => onEditPortfolio()}>
                    Edit portfolio
                </StyledButton>
            );
        } else {
            return (
                <StyledButton
                    icon={<PortfolioIcon fill="var(--secondary-color)" style={{marginRight: 11, marginTop: 2}} />}
                    style={{
                        borderRadius: 25,
                        backgroundColor: "white",
                        color: "var(--secondary-color)",
                        border: "1px dashed var(--secondary-color)",
                        margin: 5,
                        width: 160
                    }}
                    onClick={() => onEditPortfolio()}>
                    Add portfolio
                </StyledButton>
            );
        }
    }

    return hasImages ? (
        <StyledButton
            icon={<PortfolioIcon style={{marginRight: 11, marginTop: 2}} />}
            style={{borderRadius: 25, backgroundColor: theme.primary, color: theme.textPrimary, margin: 5, width: 160}}
            onClick={() => onPortfolioView()}>
            View portfolio
        </StyledButton>
    ) : null;
};

const WatchDemoButton = ({isMe, providerDetails, onEditVideoDemo, onWatchVideoDemo, onUploading, onError}) => {
    const {theme} = useContext(AppContext);
    const [uploading, setUploading] = useState(
        isMe && providerDetails.video && providerDetails.video.status === VideoStatus.Uploading
    );

    const hasVideo = providerDetails.video && providerDetails.video.url;

    if (isMe) {
        if (providerDetails.video && providerDetails.video.status !== VideoStatus.Uploading && uploading) {
            setUploading(false);
        } else if (providerDetails.video && providerDetails.video.status === VideoStatus.Uploading && !uploading) {
            setUploading(true);
        }

        if (hasVideo) {
            return (
                <StyledButton
                    icon={
                        <div style={{marginRight: 10, marginTop: 4}}>
                            <YouTubeIcon />
                        </div>
                    }
                    style={{borderRadius: 25, backgroundColor: "#273444", color: "white", margin: 5, width: 160}}
                    onClick={() => onEditVideoDemo()}>
                    Edit demo
                </StyledButton>
            );
        } else {
            return (
                <UploadNewVideo
                    onUpload={() => setUploading(true)}
                    onSuccess={() => onUploading()}
                    onError={onError}
                    loading={uploading}
                    icon={
                        <div style={{marginRight: 10, marginTop: 4}}>
                            <YouTubeIcon fill="var(--secondary-color)" />
                        </div>
                    }
                    style={{
                        borderRadius: 25,
                        backgroundColor: "white",
                        color: "var(--secondary-color)",
                        border: "1px dashed var(--secondary-color)",
                        margin: 5,
                        width: 180
                    }}>
                    Add video demo
                </UploadNewVideo>
            );
        }
    }

    return hasVideo ? (
        <StyledButton
            icon={
                <div style={{marginRight: 10, marginTop: 4}}>
                    <YouTubeIcon />
                </div>
            }
            style={{borderRadius: 25, backgroundColor: theme.primary, color: theme.textPrimary, margin: 5, width: 160}}
            onClick={() => onWatchVideoDemo()}>
            Watch demo
        </StyledButton>
    ) : null;
};

export const generateImageUploadProps = (onUploadingStateChange, onImageUploadSuccess, action) => ({
    name: "file",
    action: action || "/api/providers/me/image",
    showUploadList: false,
    withCredentials: true,
    accept: "image/png,image/jpeg",
    beforeUpload: file => {
        if (file.size >= Images.SIZE_LIMIT) {
            EventBus.trigger("server-error", {
                content: {
                    title: "Ohhh... Gush!",
                    subTitle: "This is a huge file!",
                    description: `The maximum allowed image size is ${
                        Images.SIZE_LIMIT / 1024 / 1024
                    }MB, your image is ${parseInt(file.size / (1024 * 1024))}MB.`,
                    hideSteps: true
                },
                cta: {
                    hide: true
                }
            });
            return false;
        } else {
            onUploadingStateChange(true);
            return true;
        }
    },
    onChange: info => {
        if (info.file.status === "done") {
            onUploadingStateChange(false);
            const {imageId, imageUrl} = info.file.response;
            onImageUploadSuccess(imageId, imageUrl);
        } else if (info.file.status === "error") {
            onUploadingStateChange(false);
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we couldn't upload your image :("}},
                "Failed to upload image to server"
            );
        }
    }
});

const getLocation = (location, address) => {
    if (location === LocationLabels.OFFSITE) {
        return address;
    }

    return location ? locationKeyToTextMap[location] : locationKeyToTextMap[LocationLabels.ONSITE_AND_ONLINE];
};

const ContentBookingCard = ({me, product, onBookNow, index}) => {
    const {
        coverPhoto,
        productName,
        price,
        description,
        location,
        durationMinutes,
        priceType,
        service,
        address,
        languages
    } = product;

    const hasLanguages = Array.isArray(languages) && languages.length > 0;

    return (
        <Card
            className="wb-provider-content-card"
            bordered={false}
            hoverable={true}
            bodyStyle={{paddingTop: 10}}
            style={{
                maxWidth: 800,
                borderRadius: 10,
                backgroundColor: "white",
                boxShadow: "0 5px 15px 0 rgba(0,0,0,0.1)",
                overflow: "hidden",
                margin: 10,
                marginBottom: 40
            }}
            cover={
                <SmartImageContainer
                    width={800}
                    height={450}
                    src={coverPhoto ? coverPhoto.imageUrl : cover_placeholder}
                    style={{
                        width: "100%",
                        paddingBottom: "56%"
                    }}
                />
            }>
            <div
                style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                }}>
                <div style={{display: "flex", alignItems: "center", color: "#203448", width: "100%"}}>
                    <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                        <AutoDirectionProvider
                            text={productName}
                            style={{marginBottom: 5, marginRight: 10, width: "100%", textAlign: "left"}}>
                            <label
                                style={{
                                    minHeight: 24,
                                    fontSize: 20,
                                    fontWeight: 700,
                                    lineHeight: 1.5,
                                    width: "100%"
                                }}>
                                {productName}
                            </label>
                        </AutoDirectionProvider>
                        {me.type !== "employee" ? (
                            <label style={{fontSize: 14, color: "rgba(0,0,0,0.65)", fontWeight: 300}}>
                                <label
                                    style={{
                                        fontSize: 16,
                                        color: "#203448",
                                        fontWeight: 700,
                                        marginRight: 7
                                    }}>{`₪${price}`}</label>
                                / {priceType || service.defaultPriceType} per session
                            </label>
                        ) : null}
                    </div>
                    <div style={{flex: 1}} />
                    {me.type === "company" ? (
                        <StyledButton
                            id={`bookNow-content-${index}`}
                            onMouseEnter={() =>
                                (document.getElementById(`bookNow-content-${index}`).style.backgroundColor =
                                    "rgba(0,171,121,0.1)")
                            }
                            onMouseLeave={() =>
                                (document.getElementById(`bookNow-content-${index}`).style.backgroundColor =
                                    "transparent")
                            }
                            onClick={e => onBookNow(e, product)}
                            style={{
                                borderRadius: 20,
                                width: 120,
                                color: "#00AB79",
                                fontSize: 18,
                                border: "none",
                                backgroundColor: "transparent",
                                boxShadow: "none"
                            }}>
                            Book now
                        </StyledButton>
                    ) : null}
                </div>
                <Divider type="horizontal" style={{marginTop: 20, marginBottom: 20}} />
                <AutoDirectionProvider text={description} style={{width: "100%"}}>
                    <p style={{fontSize: 16, color: "#637381", marginBottom: 20, whiteSpace: "pre-line"}}>
                        {description}
                    </p>
                </AutoDirectionProvider>
                <div
                    className="wb-ss-flex-column"
                    style={{
                        fontSize: 16,
                        color: "#637381",
                        display: "flex",
                        width: "100%",
                        flexWrap: "wrap"
                    }}>
                    <span style={{display: "flex", alignItems: "center", marginRight: 25, marginBottom: 10}}>
                        <TimerIcon style={{marginRight: 10}} />
                        {durationMinutes} Minutes
                    </span>
                    {me.type !== "employee" ? (
                        <span
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: hasLanguages ? 25 : 0,
                                marginBottom: 10
                            }}>
                            <DestinationIcon style={{marginRight: 10}} />
                            {getLocation(location, address)}
                        </span>
                    ) : null}
                    {hasLanguages ? (
                        <span style={{display: "flex", alignItems: "center", marginBottom: 10}}>
                            <GlobeIcon style={{marginRight: 10}} />
                            {languages.join(", ")}
                        </span>
                    ) : null}
                </div>
            </div>
        </Card>
    );
};

const ProviderProfile = inject("store")(
    observer(props => {
        const {store} = props;
        const {focusContent} = store;
        const {me, isMobile, professions} = useContext(AppContext);
        const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
            providerDetails: null,
            isMe: false,
            uploading: false,
            watch: false,
            editPortfolio: false,
            showPortfolio: false
        });

        useEffect(() => {
            (async () => {
                try {
                    const {providerDetails} = props;

                    if (providerDetails) {
                        setState({providerDetails});
                    } else {
                        const provider = await HttpClient.get("/api/providers/me");
                        setState({providerDetails: provider, isMe: true});
                        if (provider.video && provider.video.status === VideoStatus.Uploading) {
                            uploadingVideoProcess();
                        }
                    }
                } catch (e) {
                    EventBus.triggerError(
                        "server-error",
                        {content: {description: "Unfortunately we didn't manage to present your profile :("}},
                        e.message
                    );
                }
            })();

            return () => {
                store.focusContent = null;
            };
        }, []);

        const extractAvailabilityDays = availability => {
            return daysArray.reduce((arr, day) => {
                const dayKey = day.toLowerCase();
                if (availability[dayKey].selected) {
                    const dayData = availability[dayKey];
                    if (dayData.morning && dayData.noon && dayData.evening) {
                        day = (
                            <span>
                                <strong>{day}</strong>
                                {" (all day)"}
                            </span>
                        );
                    } else {
                        const parts = [];

                        if (dayData.morning) {
                            parts.push("morning");
                        }
                        if (dayData.noon) {
                            parts.push("noon");
                        }
                        if (dayData.evening) {
                            parts.push("evening");
                        }
                        const partOfDay = " (" + parts.join(", ") + ")";
                        day = (
                            <span>
                                <strong>{day}</strong>
                                {partOfDay.replace("()", "(all day)")}
                            </span>
                        );
                    }
                    arr.push(day);
                }
                return arr;
            }, []);
        };

        const hasServices = memoize(providerDetails => {
            let services = getRelevantServices(providerDetails);
            return Array.isArray(services) && services.length > 0;
        });

        const hasContent = memoize(providerDetails => {
            let services = getRelevantContent(providerDetails);
            return Array.isArray(services) && services.length > 0;
        });

        const hasMoreContent = memoize(providerDetails => {
            let services = getRelevantContent(providerDetails).filter(product => !isFocusedContent(product));
            return Array.isArray(services) && services.length > 0;
        });

        const isContentService = product => {
            return product.service.serviceType === "content";
        };

        const getRelevantServices = memoize(providerDetails => {
            return (providerDetails.services || []).filter(product => {
                if (isContentService(product)) {
                    return false;
                } else if (typeof serviceId === "string") {
                    return product.service.serviceId === serviceId;
                } else {
                    return true;
                }
            });
        });

        const getRelevantContent = memoize(providerDetails => {
            return (providerDetails.services || []).filter(product => {
                if (!isContentService(product)) {
                    return false;
                } else if (typeof serviceId === "string") {
                    return product.service.serviceId === serviceId;
                } else {
                    return true;
                }
            });
        });

        const migrateStatusToProviderDetails = status => {
            setState({
                providerDetails: Object.assign({}, providerDetails, {
                    video: {
                        status,
                        url: status === VideoStatus.Ready && providerDetails.video ? providerDetails.video.url : null,
                        videoId:
                            status === VideoStatus.Ready && providerDetails.video ? providerDetails.video.videoId : null
                    }
                })
            });
        };

        const uploadingVideoProcess = () => {
            const timerId = setInterval(async () => {
                try {
                    const provider = await HttpClient.get("/api/providers/me");
                    if (provider.video && provider.video.status === VideoStatus.Ready) {
                        setState({providerDetails: provider});
                        clearInterval(timerId);
                    }
                } catch (e) {
                    clearInterval(timerId);
                }
            }, 5000);
        };

        const deleteVideo = async () => {
            try {
                const provider = await HttpClient.delete("/api/providers/me/video");
                setState({providerDetails: provider});
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we didn't manage to delete your video demo :("}},
                    e.message
                );
            }
        };

        const watchDemo = ({providerId}) => {
            GoogleAnalytics.event("Professional Profile", "Watch Video", providerId);
            setState({watch: true});
        };

        const viewPortfolio = ({providerId}) => {
            GoogleAnalytics.event("Professional Profile", "View Portfolio", providerId);
            setState({showPortfolio: true});
        };

        const closePortfolio = () => {
            setState({showPortfolio: false});
        };

        const openEditPortfolio = ({providerId}) => {
            GoogleAnalytics.event("Professional Profile", "Edit Portfolio", providerId);
            setState({editPortfolio: true});
        };

        const closePortfolioEdit = () => {
            setState({editPortfolio: false});
        };

        const editVideoDemo = ({providerId}) => {
            GoogleAnalytics.event("Professional Profile", "Edit Video", providerId);
            setState({watch: true});
        };

        const closeDemo = () => {
            setState({watch: false});
        };

        const goToEditProfile = () => {
            const {history} = props;
            history.push("/dashboard/profile/edit");
        };

        const goToMyServices = () => {
            const {history} = props;
            history.push("/dashboard/myservices");
        };

        const uploadError = () => {
            message.error("Oppsy... we didn't manage to upload your new video.", 7);
            migrateStatusToProviderDetails(VideoStatus.Ready);
        };

        const bookNow = (e, productToBook) => {
            const {history, providerDetails} = props;
            e.stopPropagation();
            store.productToBook = productToBook;
            GoogleAnalytics.event("Professional Profile", "Book Now", providerDetails.providerId);
            history.push(
                `/dashboard/services/${productToBook.service.serviceId}/providers/${providerDetails.providerId}/offer/single`
            );
        };

        const isFocusedContent = product => {
            return (
                focusContent && product.productName === focusContent.productName && product.price === focusContent.price
            );
        };

        const isSameLanguage = (lang, langItem) => {
            return langItem.englishName === lang || langItem.symbol === lang || langItem.nativeName === lang;
        };

        const supportedLanguages = memoize(() => {
            const servicesWithLanguages = providerDetails.services.filter(
                s => Array.isArray(s.languages) && s.languages.length > 0
            );
            const languagesMap = servicesWithLanguages.reduce((langs, s) => {
                s.languages = s.languages.map(
                    lang => languages.find(language => isSameLanguage(lang, language)).englishName
                );
                return langs.concat(s.languages);
            }, []);
            return uniq(languagesMap);
        });

        const {providerDetails, isMe, uploading, watch, editPortfolio, showPortfolio} = state;
        const {serviceId, history, onBookClick, onChatClick} = props;

        if (!providerDetails) return <PageLoader align="flex-start" top={100} />;

        const uploadProps = generateImageUploadProps(
            isUploading => {
                setState({uploading: isUploading});
            },
            (imageId, imageUrl) => {
                setState({providerDetails: Object.assign({}, providerDetails, {imageId, imageUrl})});
            }
        );

        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    marginTop: isMe ? -10 : 0,
                    marginBottom: 80
                }}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                    <PageTitleHeader style={{marginBottom: 0, marginTop: 20}} showBack={!isMe}>
                        {isMe ? `${providerDetails.firstName}'s profile` : `About ${providerDetails.firstName}`}
                    </PageTitleHeader>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 10,
                            flexDirection: "column",
                            maxWidth: 768,
                            width: "100%"
                        }}>
                        <ProviderPortfolioViewModal
                            onClose={() => closePortfolio()}
                            visible={showPortfolio}
                            portfolioImages={providerDetails.portfolioImages || []}
                        />
                        <ProviderPortfolioUploadModal
                            onClose={() => closePortfolioEdit()}
                            providerDetails={providerDetails}
                            visible={editPortfolio}
                        />
                        <ProviderVideoModal
                            status={providerDetails.video ? providerDetails.video.status : void 0}
                            uploadable={isMe}
                            visible={watch}
                            onClose={() => closeDemo()}
                            videoUrl={providerDetails.video ? providerDetails.video.url : null}
                            onUpload={() => migrateStatusToProviderDetails(VideoStatus.Uploading)}
                            onSuccess={() => uploadingVideoProcess()}
                            onError={() => uploadError()}
                            onDelete={async () => await deleteVideo()}
                        />
                        <div style={{width: "100%"}}>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <SmartImageContainer
                                    height={240}
                                    width={240}
                                    src={providerDetails.imageUrl}
                                    transformations={{gravity: "faces"}}
                                    style={{
                                        width: 240,
                                        height: 240,
                                        borderRadius: 120,
                                        position: "relative"
                                    }}>
                                    {isMe ? (
                                        <Upload {...uploadProps}>
                                            <Button
                                                className="wb-provider-edit-image"
                                                icon={
                                                    uploading ? (
                                                        <LoadingOutlined style={{color: "white", paddingBottom: 4}} />
                                                    ) : (
                                                        <EditIcon style={{paddingTop: 2}} />
                                                    )
                                                }
                                            />
                                        </Upload>
                                    ) : null}
                                </SmartImageContainer>
                                <ProviderProfession profession={providerDetails.profession} style={{marginTop: 20}} />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                        flexWrap: "wrap",
                                        marginTop: 20
                                    }}>
                                    {onChatClick ? (
                                        <StyledButton
                                            icon={
                                                <WhatsAppOutlined
                                                    style={{fontSize: 18, paddingTop: 4, marginRight: 4}}
                                                />
                                            }
                                            style={{
                                                borderRadius: 25,
                                                backgroundColor: "#055E53",
                                                color: "white",
                                                margin: 5,
                                                minWidth: 160
                                            }}
                                            onClick={() => onChatClick()}>
                                            {`Contact ${providerDetails.firstName}`}
                                        </StyledButton>
                                    ) : null}
                                    {onBookClick ? (
                                        <StyledButton
                                            icon={<AppointmentIcon style={{marginRight: 11}} />}
                                            style={{
                                                borderRadius: 25,
                                                backgroundColor: "#00C693",
                                                color: "white",
                                                margin: 5,
                                                minWidth: 160
                                            }}
                                            onClick={() => onBookClick()}>
                                            Book {providerDetails.firstName}
                                        </StyledButton>
                                    ) : null}
                                    {isAppointmentBasedProfession(providerDetails.profession, professions) ? (
                                        <PortfolioButton
                                            isMe={isMe}
                                            providerDetails={providerDetails}
                                            onEditPortfolio={() => openEditPortfolio(providerDetails)}
                                            onPortfolioView={() => viewPortfolio(providerDetails)}
                                        />
                                    ) : (
                                        <WatchDemoButton
                                            isMe={isMe}
                                            onUploading={() => {
                                                migrateStatusToProviderDetails(VideoStatus.Uploading);
                                                uploadingVideoProcess();
                                            }}
                                            providerDetails={providerDetails}
                                            onError={() => uploadError()}
                                            onEditVideoDemo={() => editVideoDemo(providerDetails)}
                                            onWatchVideoDemo={() => watchDemo(providerDetails)}
                                        />
                                    )}
                                    {!onChatClick ? (
                                        <StyledButton
                                            icon={<WhatsAppOutlined style={{paddingTop: 4, fontSize: 18}} />}
                                            style={{
                                                borderRadius: 25,
                                                backgroundColor: "#055E53",
                                                color: "white",
                                                margin: 5
                                            }}
                                            onClick={() => ContactPhone.openWhatsApp()}>
                                            Have questions?
                                        </StyledButton>
                                    ) : null}
                                </div>
                                <AutoDirectionProvider text={providerDetails.description}>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color: "#273444",
                                            padding: 10,
                                            fontSize: 16,
                                            textAlign: "center"
                                        }}>
                                        <span style={{marginLeft: 10, marginRight: 10}}>
                                            {providerDetails.description}
                                        </span>
                                        {isMe ? (
                                            <PencilIcon
                                                style={{minWidth: 16, minHeight: 16, cursor: "pointer"}}
                                                onClick={() => goToEditProfile()}
                                            />
                                        ) : null}
                                    </div>
                                </AutoDirectionProvider>
                                <div
                                    style={{
                                        padding: 20,
                                        backgroundColor: "#F6F7F8",
                                        width: "100%",
                                        marginTop: 10,
                                        borderRadius: 10,
                                        height: 80
                                    }}>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <label>Professional rating</label>
                                        <Tooltip
                                            title={`Rating is based upon ${
                                                isMe ? "your" : "their"
                                            } experience, professional certifications and ${
                                                isMe ? "your" : "their"
                                            } customers reviews`}
                                            trigger={isMobile ? ["click"] : ["hover"]}>
                                            <InfoCircleOutlined
                                                style={{marginLeft: 8, fontSize: 14, cursor: "pointer"}}
                                            />
                                        </Tooltip>
                                    </div>
                                    <Progress
                                        className="wb-professional-rating"
                                        strokeColor="#00C693"
                                        strokeWidth={3}
                                        format={percent => percent / 10}
                                        percent={providerDetails.rating * 10}
                                    />
                                </div>
                                <ProviderReviews reviews={providerDetails.reviews} />
                                <div
                                    style={{
                                        position: "relative",
                                        padding: 20,
                                        backgroundColor: "#F6F7F8",
                                        width: "100%",
                                        marginTop: 20,
                                        borderRadius: 10
                                    }}>
                                    {isMe ? (
                                        <PencilIcon
                                            style={{
                                                position: "absolute",
                                                right: 15,
                                                top: 15,
                                                zIndex: 1,
                                                cursor: "pointer"
                                            }}
                                            onClick={() => goToEditProfile()}
                                        />
                                    ) : null}
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            flexWrap: "wrap"
                                        }}>
                                        <ProviderInfoCard>
                                            <LocationIcon style={{marginRight: 11}} />
                                            {providerDetails.countryName}, {providerDetails.cityName}
                                        </ProviderInfoCard>
                                        {providerDetails.hasCertification ? (
                                            <ProviderInfoCard>
                                                <CertificationIcon style={{marginRight: 11}} />
                                                With certification
                                            </ProviderInfoCard>
                                        ) : null}
                                        {providerDetails.hasInsurance ? (
                                            <ProviderInfoCard>
                                                <InsuranceIcon style={{marginRight: 11}} />
                                                With insurance
                                            </ProviderInfoCard>
                                        ) : null}
                                        <ProviderInfoCard>
                                            <ClockIcon style={{marginRight: 11}} />
                                            {providerDetails.experience} Years experience
                                        </ProviderInfoCard>
                                        {typeof providerDetails.customers === "string" ? (
                                            <ProviderInfoCard>
                                                <div style={{display: "flex"}}>
                                                    <BuildingIcon style={{marginRight: 11, marginTop: 2}} />
                                                    <p style={{marginBottom: 0}}>
                                                        <strong>Customers:&nbsp;&nbsp;</strong>
                                                        {truncate(providerDetails.customers, {
                                                            length: 45,
                                                            separator: " "
                                                        })}
                                                    </p>
                                                </div>
                                            </ProviderInfoCard>
                                        ) : null}
                                        {supportedLanguages().length > 0 ? (
                                            <ProviderInfoCard>
                                                <GlobeIcon style={{marginRight: 11}} />
                                                {supportedLanguages().join(", ")}
                                            </ProviderInfoCard>
                                        ) : null}
                                    </div>
                                    {me.type !== "employee" ? (
                                        <>
                                            <Divider />
                                            <div style={{fontSize: 16}}>
                                                <label>Availability:</label>
                                                <p>
                                                    {extractAvailabilityDays(providerDetails.availability).map(
                                                        (day, idx) => {
                                                            if (idx > 0) {
                                                                return <span key={`day-${idx}`}>, {day}</span>;
                                                            } else {
                                                                return <span key={`day-${idx}`}>{day}</span>;
                                                            }
                                                        }
                                                    )}
                                                </p>
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {hasContent(providerDetails) ? (
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <PageTitleLabel
                            style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#203448",
                                fontSize: 28,
                                fontWeight: 700,
                                marginTop: 20,
                                marginBottom: 20
                            }}>
                            <span style={{marginRight: 15}}>Content</span>
                            {isMe ? <PencilIcon style={{cursor: "pointer"}} onClick={() => goToMyServices()} /> : null}
                        </PageTitleLabel>
                        <div style={{maxWidth: 800, width: "100%", padding: 20, paddingTop: 0, paddingBottom: 0}}>
                            {focusContent ? (
                                <>
                                    <ContentBookingCard
                                        me={me}
                                        product={focusContent}
                                        onBookNow={(e, product) => bookNow(e, product)}
                                        index={"000"}
                                    />
                                    {hasMoreContent(providerDetails) ? (
                                        <PageTitleLabel
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                color: "#203448",
                                                fontSize: 28,
                                                fontWeight: 700,
                                                marginTop: 30,
                                                marginBottom: 20
                                            }}>
                                            <span style={{marginRight: 15}}>More content</span>
                                        </PageTitleLabel>
                                    ) : null}
                                </>
                            ) : null}
                            {getRelevantContent(providerDetails)
                                .filter(product => (focusContent ? !isFocusedContent(product) : true))
                                .map((product, index) => (
                                    <ContentBookingCard
                                        me={me}
                                        product={product}
                                        onBookNow={(e, product) => bookNow(e, product)}
                                        index={index}
                                    />
                                ))}
                        </div>
                    </div>
                ) : null}
                {hasServices(providerDetails) ? (
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <PageTitleLabel
                            style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#203448",
                                fontSize: 28,
                                fontWeight: 700,
                                marginTop: 20,
                                marginBottom: 30
                            }}>
                            <span style={{marginRight: 15}}>Services</span>
                            {isMe ? <PencilIcon style={{cursor: "pointer"}} onClick={() => goToMyServices()} /> : null}
                        </PageTitleLabel>
                        <div style={{maxWidth: 768, width: "100%", padding: 20, paddingTop: 0}}>
                            <Collapse
                                style={{backgroundColor: "transparent", borderBottom: "none"}}
                                defaultActiveKey={providerDetails.services.map((_, idx) => idx)}
                                key={providerDetails.services.map((_, idx) => idx).join("_")}
                                expandIconPosition="right"
                                bordered={false}>
                                {getRelevantServices(providerDetails).map(
                                    (
                                        {
                                            service,
                                            productName,
                                            description,
                                            price,
                                            durationMinutes,
                                            location,
                                            priceType,
                                            address
                                        },
                                        index
                                    ) => (
                                        <Panel
                                            className="wb-provider-profile-menu"
                                            style={{borderBottom: "1px solid #D3D8E0", minHeight: 50, marginBottom: 8}}
                                            header={
                                                <div style={{display: "flex", alignItems: "center", color: "#203448"}}>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <AutoDirectionProvider
                                                            text={productName}
                                                            style={{marginBottom: 5, marginRight: 10}}>
                                                            <label
                                                                style={{
                                                                    minHeight: 24,
                                                                    fontSize: 20,
                                                                    fontWeight: 700,
                                                                    lineHeight: 1.5,
                                                                    maxWidth: 550
                                                                }}>
                                                                {productName}
                                                            </label>
                                                        </AutoDirectionProvider>
                                                        {me.type === "employee" &&
                                                        !isAppointmentBasedProfession(
                                                            providerDetails.profession,
                                                            professions
                                                        ) ? null : (
                                                            <label
                                                                style={{
                                                                    fontSize: 14,
                                                                    color: "rgba(0,0,0,0.65)",
                                                                    fontWeight: 300
                                                                }}>
                                                                <label
                                                                    style={{
                                                                        fontSize: 16,
                                                                        color: "#203448",
                                                                        fontWeight: 700,
                                                                        marginRight: 7
                                                                    }}>{`₪${price}`}</label>
                                                                / {priceType || service.defaultPriceType} per session
                                                            </label>
                                                        )}
                                                    </div>
                                                    <div style={{flex: 1}} />
                                                    {me.type === "company" &&
                                                    !isAppointmentBasedProfession(
                                                        providerDetails.profession,
                                                        professions
                                                    ) ? (
                                                        <StyledButton
                                                            id={`bookNow-${index}`}
                                                            onMouseEnter={() =>
                                                                (document.getElementById(
                                                                    `bookNow-${index}`
                                                                ).style.backgroundColor = "rgba(0,171,121,0.1)")
                                                            }
                                                            onMouseLeave={() =>
                                                                (document.getElementById(
                                                                    `bookNow-${index}`
                                                                ).style.backgroundColor = "transparent")
                                                            }
                                                            onClick={e =>
                                                                bookNow(e, {
                                                                    service,
                                                                    productName,
                                                                    description,
                                                                    price,
                                                                    durationMinutes,
                                                                    location
                                                                })
                                                            }
                                                            style={{
                                                                borderRadius: 20,
                                                                width: 120,
                                                                color: "#00AB79",
                                                                fontSize: 18,
                                                                border: "none",
                                                                backgroundColor: "transparent",
                                                                boxShadow: "none"
                                                            }}>
                                                            Book now
                                                        </StyledButton>
                                                    ) : null}
                                                </div>
                                            }
                                            key={`${index}`}>
                                            <AutoDirectionProvider text={description}>
                                                <p style={{fontSize: 16, color: "#637381"}}>{description}</p>
                                            </AutoDirectionProvider>
                                            <div
                                                style={{
                                                    fontSize: 16,
                                                    color: "#637381",
                                                    display: "flex",
                                                    width: "100%",
                                                    flexWrap: "wrap"
                                                }}>
                                                <span
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginRight: 25,
                                                        marginBottom: 10
                                                    }}>
                                                    <TimerIcon style={{marginRight: 10}} />
                                                    {durationMinutes} Minutes
                                                </span>
                                                {me.type !== "employee" ? (
                                                    <span
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            marginRight: 25,
                                                            marginBottom: 10
                                                        }}>
                                                        <DestinationIcon style={{marginRight: 10}} />
                                                        {getLocation(location, address)}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </Panel>
                                    )
                                )}
                            </Collapse>
                        </div>
                    </div>
                ) : null}
                {isMe ? (
                    <FormBottomBar>
                        <div
                            className="wb-justify-bottom-bar"
                            style={{display: "flex", width: "100%", alignItems: "center"}}>
                            <label
                                className="wb-block-desktop-only"
                                style={{marginRight: 5, fontSize: 16, color: "white"}}>
                                Do you want to edit your profile?
                            </label>
                            <StyledButton
                                className="wb-contact-us-chat-btn"
                                onClick={() => history.push("/dashboard/profile/edit")}
                                style={{color: "#2F3E83", backgroundColor: "white", borderRadius: 100}}>
                                {isMobile ? "Edit Profile" : "Want To Make A Change?"}
                            </StyledButton>
                        </div>
                    </FormBottomBar>
                ) : null}
            </div>
        );
    })
);

export default withRouter(ProviderProfile);
