import React, {useContext, useMemo} from "react";
import {Collapse} from "antd";
import {MarketplaceContext} from "../marketplaceContext";
import {isHTML} from "../MarketplaceUtils";

const {Panel} = Collapse;

export const GeneralDescriptionPanel = ({key, ...props}) => {
    const {product, productBundle, isProductBundle} = useContext(MarketplaceContext);

    const description = useMemo(
        () => (isProductBundle ? productBundle?.description : product?.description),
        [isProductBundle, productBundle?.description, product?.description]
    );

    return (
        <Panel {...props} key={key} showArrow={true} header={<div className="panel-header-title">{"תיאור כללי"}</div>}>
            {isHTML(description) ? (
                <div
                    style={{
                        color: "var(--secondary-color)",
                        fontSize: "16px",
                        whiteSpace: "pre-wrap",
                        direction: "rtl"
                    }}
                    className="content"
                    dangerouslySetInnerHTML={{__html: description}}
                />
            ) : (
                <div
                    style={{
                        color: "var(--secondary-color)",
                        fontSize: "16px",
                        whiteSpace: "pre-wrap",
                        direction: "rtl"
                    }}>
                    {description}
                </div>
            )}
        </Panel>
    );
};
