import React, {useState, useEffect, useCallback} from "react";
import {Tabs} from "antd";
import {DndContext, PointerSensor, useSensor} from "@dnd-kit/core";
import {SortableContext, arrayMove, horizontalListSortingStrategy, useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {css} from "@emotion/css";

const DraggableTabNode = ({className, onActiveBarTransform, ...props}) => {
    const {attributes, listeners, setNodeRef, transform, transition, isSorting} = useSortable({
        id: props["data-node-key"]
    });
    const style = {
        ...props.style,
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: "move"
    };
    useEffect(() => {
        if (!isSorting) {
            onActiveBarTransform("");
        } else if (className?.includes("ant-tabs-tab-active")) {
            onActiveBarTransform(css`
                .ant-tabs-ink-bar {
                    transform: ${CSS.Transform.toString(transform)};
                    transition: ${transition} !important;
                }
            `);
        }
    }, [className, isSorting, transform]);

    return React.cloneElement(props.children, {
        ref: setNodeRef,
        style,
        ...attributes,
        ...listeners
    });
};

export const SortableTabs = ({tabs = [], onRemoveTab = NOOP, onAddTab = NOOP, onSort = NOOP, ...props}) => {
    const [items, setItems] = useState(tabs);
    const [className, setClassName] = useState("");
    const sensor = useSensor(PointerSensor, {activationConstraint: {distance: 10}});

    useEffect(() => {
        setItems(tabs);
    }, [tabs]);

    const onDragEnd = useCallback(
        ({active, over}) => {
            if (active.id !== over?.id) {
                setItems(prev => {
                    const activeIndex = prev.findIndex(i => i.key === active.id);
                    const overIndex = prev.findIndex(i => i.key === over?.id);
                    return arrayMove(prev, activeIndex, overIndex);
                });
                onSort(active, over);
            }
        },
        [items, onSort]
    );

    const add = useCallback(() => {
        onAddTab();
    }, [items, onAddTab]);
    const onEdit = (targetKey, action) => {
        if (action === "add") {
            add();
        } else {
            onRemoveTab(targetKey);
        }
    };

    return (
        <Tabs
            centered
            type="editable-card"
            className={className}
            items={items}
            onEdit={onEdit}
            renderTabBar={(tabBarProps, DefaultTabBar) => (
                <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                    <SortableContext items={items.map(i => i.key)} strategy={horizontalListSortingStrategy}>
                        <DefaultTabBar {...tabBarProps}>
                            {node => (
                                <DraggableTabNode {...node.props} key={node.key} onActiveBarTransform={setClassName}>
                                    {node}
                                </DraggableTabNode>
                            )}
                        </DefaultTabBar>
                    </SortableContext>
                </DndContext>
            )}
        />
    );
};
