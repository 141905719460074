import {useContext, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {debounce} from "lodash";
import {HttpClient} from "../http/HttpClient";
import ProductsContext from "../admin/Context/ProductsContext";
import moment from "moment/moment.js";
import {StringBuilder} from "../AppUtils.js";
import {formatHebrewTimeString, formatTimeToTimer} from "./DateUtils.jsx";

export const useQuery = () => {
    const {search} = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

export const useRequest = (
    url,
    method = "get",
    body = null,
    dependencies = [],
    shouldFetch = true,
    formatDataFunction = null,
    defaultValue = null
) => {
    const [data, setData] = useState(defaultValue);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [manualTrigger, setManualTrigger] = useState(0);
    const abortControllerRef = useRef(null);

    const run = useCallback(() => {
        setManualTrigger(prevState => prevState + 1);
    }, []);

    useEffect(() => {
        const fetchRequest = async () => {
            setIsLoading(true);
            setError(null);

            try {
                abortControllerRef.current?.abort();
                const newController = new AbortController();
                abortControllerRef.current = newController;
                const signal = newController.signal;
                const data = await HttpClient.sendRequest(method, url, body, false, {signal});
                setData(formatDataFunction ? formatDataFunction(data) : data);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        };

        if (shouldFetch) {
            fetchRequest();
        }

        return () => {
            abortControllerRef.current?.abort();
        };
    }, [url, shouldFetch, manualTrigger, ...dependencies]);

    return [data, isLoading, error, run];
};

export const useDebounceState = (initial, delay) => {
    const [unDebouncedValue, setUnDebouncedValue] = useState(initial);
    const [debouncedValue, setDebouncedValue] = useState(initial);

    const updateValue = useCallback(
        debounce(newValue => {
            setDebouncedValue(newValue);
        }, delay),
        [delay]
    );

    useEffect(() => {
        updateValue(unDebouncedValue);
    }, [unDebouncedValue]);

    return [debouncedValue, setUnDebouncedValue, unDebouncedValue];
};

export const useLocalStorage = (key, initialValue, additionalParsing) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);

            const parsedValue = item ? JSON.parse(item) : initialValue;

            return additionalParsing ? additionalParsing(parsedValue) : parsedValue;
        } catch (error) {
            return initialValue;
        }
    });

    const setValue = useCallback(
        value => {
            try {
                const valueToStore = value instanceof Function ? value(storedValue) : value;
                setStoredValue(valueToStore);
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            } catch (error) {}
        },
        [storedValue, key]
    );

    return [storedValue, setValue];
};

const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
};

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

export const useIsInViewport = ref => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(() => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)), []);

    useEffect(() => {
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
};

export const useProducts = () => {
    const context = useContext(ProductsContext);

    if (context === undefined) {
        throw new Error("useProducts must be used within a ProductsProvider");
    }

    return context;
};

const usePrevious = (value, initialValue) => {
    const ref = useRef(initialValue);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
export const useEffectDebugger = (effectHook, dependencies, dependencyNames = []) => {
    const previousDeps = usePrevious(dependencies, []);

    const changedDeps = dependencies.reduce((accum, dependency, index) => {
        if (dependency !== previousDeps[index]) {
            const keyName = dependencyNames[index] || index;
            return {
                ...accum,
                [keyName]: {
                    before: previousDeps[index],
                    after: dependency
                }
            };
        }

        return accum;
    }, {});

    useEffect(effectHook, dependencies);
};

export const useTimer = (endTime = null, hebrewFormat = false, delay = 1000) => {
    const [remainingTime, setRemainingTime] = useState(null);
    const [endTimestamp, setEndTimestamp] = useState();

    const savedEndTime = useMemo(() => endTime, [endTime]);

    useEffect(() => {
        setEndTimestamp(savedEndTime);
    }, [savedEndTime]);

    const getTimeLeft = useCallback(() => {
        if (!endTimestamp) return;

        const endTimeLeft = moment(endTimestamp);
        const now = moment();

        if (now.isSameOrAfter(endTimeLeft)) {
            return;
        }

        const timeLeft = moment.duration(endTimeLeft.diff(now));
        return new StringBuilder()
            .append(hebrewFormat ? formatHebrewTimeString(timeLeft) : formatTimeToTimer(timeLeft))
            .toString();
    }, [endTimestamp, hebrewFormat]);

    useEffect(() => {
        if (endTimestamp) {
            setRemainingTime(getTimeLeft());

            const interval = setInterval(() => {
                setRemainingTime(getTimeLeft());

                if (!getTimeLeft) {
                    clearInterval(interval);
                }
            }, delay);

            return () => clearInterval(interval);
        } else {
            setRemainingTime(null);
        }
    }, [endTimestamp]);

    return {remainingTime};
};
