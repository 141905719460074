import React from "react";
import {createUrlWithTransformations} from "../utils/cloudinaryUtils";

export const SmartImageContainer = ({
    width,
    height,
    src,
    transformations,
    style,
    className,
    onClick,
    title,
    children
}) => {
    src = createUrlWithTransformations(src, width, height, transformations);

    return (
        <div
            className={className}
            onClick={onClick}
            title={title}
            style={{
                width: parseInt(width, 10),
                height: style && style.paddingBottom ? void 0 : parseInt(height, 10),
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: `url(${src})`,
                ...style
            }}>
            {children}
        </div>
    );
};
