import React, {useCallback} from "react";
import {message} from "antd";
import SelectProductsModal from "../components/SelectProductsModal";
import {HttpClient} from "../../http/HttpClient";

const BlockProductsForCompanyModal = ({visible, onSave, onCancel, company}) => {
    const innerOnSave = useCallback(
        async blockedProducts => {
            const updatedCompany = await HttpClient.safePost("/admin/api/companies/update", {
                companyId: company.companyId,
                blockedProducts
            });

            if (updatedCompany.error) {
                message.error("שגיאה בעדכון מוצרים חסומים");
                return;
            }

            onSave(updatedCompany);
        },
        [company]
    );

    return (
        <SelectProductsModal
            visible={visible}
            onCancel={onCancel}
            productIds={company?.blockedProducts}
            onSave={innerOnSave}
            topBarButtons={<div>{`חסימת מוצרים חברת: ${company?.name}`}</div>}
        />
    );
};

export default BlockProductsForCompanyModal;
