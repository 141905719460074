import styled from "styled-components";
import {Tag} from "antd";
import React from "react";

const FreeOfChargeTag = styled(Tag)`
    font-size: 16px;
    border-radius: 25px;
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FreeOfCharge = props => {
    return (
        <FreeOfChargeTag color="success" {...props}>
            Free of charge
        </FreeOfChargeTag>
    );
};
