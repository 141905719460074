export const parseFloatNumber = (number, digitsCountAfterDot = 2) => {
    if (number === null || number === undefined || isNaN(Number(number)) || number.toString().length === 0) {
        return NaN;
    }
    return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: digitsCountAfterDot,
        roundingMode: "floor",
        useGrouping: false
    }).format(number);
};
