import React from "react";
import {DeliveryAddressInput} from "../DeliveryAddressInput.jsx";
import {Form, Radio} from "antd";
import EntireCountry from "../../../../../images/entire-country.svg";
import North from "../../../../../images/north.svg";
import {MAIN_AREAS_PLACE_ID} from "../../../../../admin/upload/AdminUploadUtils.jsx";
import Center from "../../../../../images/center.svg";
import South from "../../../../../images/south.svg";
import TelAviv from "../../../../../images/telaviv.svg";
import Jerusalem from "../../../../../images/jerusalem.svg";
import Haifa from "../../../../../images/haifa.svg";
import {deliveryAddressOptions} from "../consts.js";
import {CheckboxGroupOptions} from "../../../../../components/CheckboxGroup/index.jsx";
import {GoogleMapSubAreaApiKeys} from "../../../../../consts.js";

export const groupOptionKeys = {
    areaDelivery: "areaDelivery",
    deliveryToAddress: "deliveryToAddress"
};

export const areaOption = [
    {title: "כל הארץ", key: "entireCountry", image: EntireCountry},
    {title: "צפון", key: " north", image: North, placeIds: [MAIN_AREAS_PLACE_ID.NORTH, MAIN_AREAS_PLACE_ID.HAIFA]},
    {
        title: "מרכז",
        key: "center",
        image: Center,
        placeIds: [MAIN_AREAS_PLACE_ID.CENTRAL, MAIN_AREAS_PLACE_ID.TEL_AVIV]
    },
    {title: "דרום", key: "south", image: South, placeIds: [MAIN_AREAS_PLACE_ID.SOUTH]},
    {title: "ירושלים", key: "jerusalem", image: Jerusalem, placeIds: [MAIN_AREAS_PLACE_ID.JERUSALEM]}
];

const accessableCheckboxOptions = [{value: true, label: "סניף הפעילות נגיש :)"}];

export const boundingActivitiesRadioGroupOption = (
    onAreaSelected,
    innerState,
    onAreaDeliveryAccessibleChange,
    onDeliveryToAddressAccessibleChange
) => {
    return [
        {
            text: "בחירה לפי אזור",
            key: groupOptionKeys.areaDelivery,
            component: (
                <div>
                    <Radio.Group
                        value={
                            innerState?.locationFilter?.[deliveryAddressOptions.onSite]?.[groupOptionKeys.areaDelivery]
                                ?.placeKey
                        }
                        defaultValue="entireCountry"
                        className="area-container"
                        size="large"
                        onChange={event => onAreaSelected(event.target.value)}>
                        {areaOption.map((option, index) => {
                            return (
                                <div key={index} className="area-option">
                                    <Radio.Button className="area-option-btn" value={option.key}>
                                        <img className="area-icon" src={option.image} alt={option.key} />
                                    </Radio.Button>
                                    <span className="area-title">{option.title}</span>
                                </div>
                            );
                        })}
                    </Radio.Group>
                    <CheckboxGroupOptions
                        value={[
                            innerState?.locationFilter?.[deliveryAddressOptions.onSite]?.[groupOptionKeys.areaDelivery]
                                ?.isAccessible
                        ]}
                        options={accessableCheckboxOptions}
                        span={null}
                        onChange={onAreaDeliveryAccessibleChange}
                    />
                </div>
            )
        },
        {
            text: "בחירה לפי עיר",
            key: groupOptionKeys.deliveryToAddress,
            component: (
                <div>
                    <Form.Item className="address-input">
                        <DeliveryAddressInput
                            path={`locationFilter.${deliveryAddressOptions.onSite}.${groupOptionKeys.deliveryToAddress}`}
                            deliveryType={deliveryAddressOptions.onSite}
                            placeholder={"הזן עיר"}
                            showDeliveryPrice={false}
                            locationTypes={GoogleMapSubAreaApiKeys}
                        />
                    </Form.Item>
                    <CheckboxGroupOptions
                        value={[
                            innerState?.locationFilter?.[deliveryAddressOptions.onSite]?.[
                                groupOptionKeys.deliveryToAddress
                            ]?.isAccessible
                        ]}
                        options={accessableCheckboxOptions}
                        span={null}
                        onChange={onDeliveryToAddressAccessibleChange}
                    />
                </div>
            )
        }
    ];
};
