import {HttpClient} from "../http/HttpClient";

export class ErrorReporter {
    static reportError(error) {
        Promise.resolve().then(async () => {
            try {
                await HttpClient.post(
                    "/api/error",
                    {
                        error: typeof error === "string" ? error : error.message,
                        stack: typeof error === "string" || !error.stack ? "no-stack" : error.stack,
                        referrer: window.location.href
                    },
                    true
                );
            } catch (e) {
                console.log(e);
            }
        });
    }
}
