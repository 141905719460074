import CallToActionModal from "../components/CallToActionModal";
import React from "react";
import {Typography} from "antd";
import {ModalTitle} from "../components/ModalTitle";

export const EventCancelledModal = ({visible, history}) => {
    const Title = () => <ModalTitle title="Ohh..." subTitle="This proposal was cancelled" />;

    const SubTitle = () => (
        <div style={{display: "flex", flexDirection: "column"}}>
            <Typography.Paragraph style={{textAlign: "center"}}>
                You can check out your calendar for your upcoming sessions.
            </Typography.Paragraph>
        </div>
    );

    return (
        <CallToActionModal
            imageName="oops2"
            title={<Title />}
            subTitle={<SubTitle />}
            actionText="Check out my calendar"
            actionHandler={() => history.push("/dashboard/schedule")}
            visible={visible}
        />
    );
};
