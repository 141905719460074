import React, {useCallback, useEffect, useState} from "react";
import {Layout, message} from "antd";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
// import Color from "color-js";
import {Provider} from "mobx-react";
import {observable} from "mobx";
import {useIdleTimer} from "react-idle-timer";
import TagManager from "react-gtm-module";
import isMobileDevice from "is-mobile";
import {AppDashboardSelector} from "./dashboards/AppDashboardSelector";
import HappinessCalculator from "./happinessCalculator";
import {EmailConfirmation} from "./auth/EmailConfirmation";
import {ResetPassword} from "./auth/ResetPassword";
import {Login} from "./auth/Login";
import {ProviderSignUp} from "./provider/ProviderSignUp";
import {HttpClient} from "./http/HttpClient";
import {ContactUs} from "./support/ContactUs";
import {EventProposal} from "./event/EventProposal";
import {ForgotPassword} from "./auth/ForgotPassword";
import AppRouteListener from "./AppRouteListener";
import {AppContext} from "./AppContext";
import {EventRegisterModal} from "./event/EventRegisterModal";
import {EventJoinModal} from "./event/EventJoinModal";
import AppTopBar from "./AppTopBar";
import {ErrorModal} from "./error/ErrorModal";
import {EventNoMeetingLinkModal} from "./event/EventNoMeetingLinkModal";
import {AdminDashboard} from "./admin/AdminDashboard";
import {GoogleAnalytics} from "./GoogleAnalytics";
import {EmailConfirmationExpired} from "./auth/EmailConfirmationExpired";
import {sortProfessions} from "./data/professions";
import {CompanyEmployeeSignUp} from "./company/CompanyEmployeeSignUp";
import {ProviderDetails} from "./provider/ProviderDetails";
import {CompanyConfirmation} from "./auth/CompanyConfirmation";
import {CompanyBranding} from "./company/branding/CompanyBranding";
import {EventBus} from "./bus/EventBus";
import {CompanyWeeklyTemplate} from "./company/weekly/CompanyWeeklyTemplate";
import {CompanyBilling} from "./company/CompanyBilling";
import CreditCardRequiredModal from "./billing/CreditCardRequiredModal";
import {UnsubscribePage} from "./auth/UnsubscribePage";
import {ProviderBilling} from "./provider/ProviderBilling";
import {CreditCardDetailsModal} from "./billing/CreditCardDetailsModal";
import {BankInformationModal} from "./billing/BankInformationModal";
import BankInformationRequiredModal from "./billing/BankInformationRequiredModal";
import {CompanyAccountSettings} from "./company/CompanyAccountSettings";
import {CompanyWeeklyBestPractices} from "./company/weekly/CompanyWeeklyBestPractices";
import {AppTerms} from "./AppTerms";
import {AppPrivacy} from "./AppPrivacy";
import AppHistoryAdapter from "./AppHistoryAdapter";
import {EXCenter} from "./center/EXCenter";
import {ErrorReporter} from "./error/ErrorReporter";
import {AdminConsoleDashboard} from "./admin/console/AdminConsoleDashboard";
import {ProviderSignUpStepper} from "./wizards/providerStepper/ProviderSignUpStepper";
import {ZoomAuthCompleted} from "./zoom/ZoomAuthCompleted";
import {ZoomDocs} from "./zoom/ZoomDocs";
import {FeedbackBox} from "./feedback/FeedbackBox";
import {AdminUploadPage} from "./admin/upload/AdminUploadPage.jsx";
import {CompanySignUpShortStepperDetails} from "./wizards/companyStepper/CompanySignUpShortStepperDetails";
import {ContactUsBox} from "./support/ContactUsBox";
import {EventProposalV2} from "./event/EventProposalV2";
import {CompanyInsights} from "./company/insights/CompanyInsights";
import {CompanyOrders} from "./company/orders/CompanyOrders";
import {EventOrderV2} from "./event/EventOrderV2";
import {initialize as initializeLogger} from "./Logger";
import {constructBudgetObject} from "./company/CompanyAccountProfile/utils";
import {CompanyAccountProfile} from "./company/CompanyAccountProfile";
import {useRequest} from "./utils/hooks";
import {ADMIN_PATH_NAME} from "./admin/consts";
import {SelectPage} from "./marketplace/SelectPage";
import {CompanyMarketplaceContextProvider} from "./marketplace/CompanyMarketplaceContextProvider";
import ProviderApproveDelivery from "./provider/ProviderApproveDelivery";
import {authExcluded} from "./AppUtils.js";
import {PopupManager} from "./components";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {MarketplaceFilterContext} from "./marketplace/MarketplaceFilter";
import {OrderDeliveryStatus} from "./event/OrderDeliveryStatus/index.jsx";
import {NotFoundPage} from "./notFoundPage/NotFoundPage.jsx";
import {CompanyEditOrderPage} from "./company/orders/CompanyEditOrderPage.jsx";
import {initialize as initializeSentrySDK} from "./SentrySDK.jsx";
import {Auth0Provider} from "@auth0/auth0-react";
import {providerConfig} from "./OktaSDK.jsx";
import {AdminEditOrderPage} from "./admin/AdminOrders/AdminEditOrderPage.jsx";
import {CompanyRegister} from "./wizards/companyRegister";
import {RegisterSuccess} from "./wizards/RegisterSuccess/index.js";
import {getThemeByOrigin} from "./utils.js";
import {useSocket} from "./components/socket/useSockets.jsx";

const store = observable({
    productToBook: null,
    topBarBackButton: false,
    focusContent: null
});

const tagManagerArgs = {
    gtmId: "GTM-KRTN9WL"
};

TagManager.initialize(tagManagerArgs);

export const App = () => {
    const [me, setMe] = useState({features: {}});
    const [planBudgetOrder, setPlanBudgetOrder] = useState(null);
    const [selectedBranchId, setSelectedBranchId] = useState(null);
    const [isolationMode, setIsolationMode] = useState(null);
    const [searchInput, setSearchInput] = useState(null);
    const [filterValues, setFilterValues] = useState({});

    const [professions, setProfessions] = useState([]);
    const [company, setCompany] = useState(null);
    const [pathname, setPathname] = useState("");

    const [services = []] = useRequest(
        "/api/v2/categories",
        "post",
        {},
        [],
        !!(me?.userId && (me.type === "company" || me.type === "provider"))
    );
    const [likedProductsRequest = []] = useRequest(
        "/api/like/mylikes/product",
        "GET",
        null,
        [],
        !!(me?.userId && me?.type === "company")
    );
    const [likedImagesRequest = []] = useRequest(
        "/api/like/mylikes/orderImage",
        "GET",
        null,
        [],
        !!(me?.userId && me?.type === "company" && me.features.pinterest)
    );

    const [userLikedItems, setUserLikedItems] = useState([]);

    useEffect(() => {
        if (likedProductsRequest) {
            setUserLikedItems(currentItems => [...currentItems, ...likedProductsRequest]);
        }
    }, [likedProductsRequest]);

    useEffect(() => {
        if (likedImagesRequest) {
            setUserLikedItems(currentItems => [...currentItems, ...likedImagesRequest]);
        }
    }, [likedImagesRequest]);

    const likeEntityHandler = useCallback(async (targetType, targetId) => {
        const newLikeItem = await HttpClient.safePost(`/api/like/likeItem/${targetType}?targetId=${targetId}`);

        if (newLikeItem.error) {
            message.error(`לא ניתן לסמן לייק ל${targetType === "product" ? "מוצר" : "תמונה"}`);
            return;
        }

        setUserLikedItems(currentLikeItems => [...currentLikeItems, newLikeItem]);
    }, []);

    const unlikeEntityHandler = useCallback(async (targetType, targetId) => {
        const unLikeItem = await HttpClient.safeDelete(`/api/like/unlikeItem/${targetId}`);

        if (unLikeItem.error) {
            message.error(`לא ניתן להוריד את הלייק ל${targetType === "product" ? "מוצר" : "תמונה"}`);
            return;
        }

        setUserLikedItems(currentLikeItems => currentLikeItems.filter(like => like.targetId !== targetId));
    }, []);

    const fetchMe = useCallback(async (withMerge = false) => {
        const currentUser = await HttpClient.safeGet("/api/users/me");

        const pathName = window.location.pathname;
        if (currentUser.type === "unauthorized" && (pathName === "" || !authExcluded(pathName))) {
            window.location.replace(`/login?returnTo=${encodeURIComponent(window.location.href)}`);
            return;
        }

        if (currentUser.error) {
            setMe({type: "unauthorized"});
        } else {
            await updateMe(currentUser, withMerge);
        }
    }, []);

    const handleOnIdle = () => {
        window.location.reload(true);
    };

    useIdleTimer({
        timeout: 1000 * 60 * 30, // 30 minutes
        onIdle: handleOnIdle,
        debounce: 500
    });

    useEffect(() => {
        (async () => {
            await Promise.all([fetchMe(), fetchProfessions()]);
        })();

        EventBus.on("me:update", async user => {
            await updateMe(user);
            if (user.type === "employee" && user.branch) {
                EventBus.trigger("branch:select", user.branch.branchId);
            }
        });

        EventBus.on("branch:select", branchId => {
            setSelectedBranchId(branchId);
        });

        EventBus.on("company:update", cmpny => {
            setCompany(cmpny);
        });

        EventBus.on("me:updateBudget", async () => {
            await fetchUserBudget();
        });

        EventBus.on("setIsolationMode", async newIsolationState => {
            setIsolationMode(newIsolationState);
        });
    }, []);

    const fetchUserBudget = useCallback(async () => {
        const budgets = await HttpClient.safeGet("/api/budget");

        if (!budgets?.error) {
            const budget = constructBudgetObject(budgets);

            setMe(me => ({...me, budget}));
        }
    }, []);

    useEffect(() => {
        if (me.userId) {
            fetchUserBudget();
        }
    }, [me.userId, fetchUserBudget]);

    const fetchProfessions = async () => {
        try {
            const pros = await HttpClient.get("/api/professions");
            setProfessions(sortProfessions(pros));
        } catch (e) {
            setMe({type: "unauthorized"});
        }
    };

    const fetchCompany = useCallback(async () => {
        try {
            const cmpny = await HttpClient.get("/api/companies/me", true);
            setCompany(cmpny);
        } catch (e) {
            if (e.statusCode !== 401 && e.message !== "Failed to fetch") {
                ErrorReporter.reportError(`Failed to fetch company info: ${e.message}`);
                console.log(e);
                message.error("Something went wrong, please try again later.", 5);
            }
        }
    }, []);

    const updateMe = useCallback(async (user, merge = true) => {
        if (merge) {
            setMe(prev => ({...prev, ...user}));
        } else {
            setMe(user);
        }

        if (user.userId && user.type !== "provider" && !company) {
            await fetchCompany();
        }
        GoogleAnalytics.setUser(user);
        initializeLogger(user);
        initializeSentrySDK(user);
    }, []);

    const loginComplete = useCallback(
        async user => {
            await updateMe(user, false);
            await fetchUserBudget();

            if (user?.isAdminLogin) {
                window.localStorage.setItem("isAdminLogin", "true");
            } else {
                window.localStorage.removeItem("isAdminLogin");
            }
        },
        [updateMe, fetchUserBudget]
    );

    const showFeedbackButton = pathname => {
        return ["/login", "/stats"].every(path => !pathname.indexOf(path) < 0);
    };

    const onPathChange = useCallback(
        async location => {
            if (location.search) {
                const searchParams = new URLSearchParams(location.search);

                const selectPageId = searchParams.get("iMode");
                if (selectPageId && selectPageId !== isolationMode?.selectPageId) {
                    setIsolationMode({selectPageId});
                    const selectPage = await HttpClient.get(`/api/selectPage/${selectPageId}`);

                    if (selectPage.products.length) {
                        setIsolationMode({
                            selectPageId,
                            products: selectPage.products
                        });
                    }
                }
            }
            setPathname(location.pathname);
        },
        [isolationMode]
    );

    const theme = () => {
        const defaultTheme = {
            primary: "var(--secondary-color)",
            secondary: "var(--secondary-color)",
            textPrimary: "white",
            textSecondary: "white"
        };

        const currentTheme =
            me.type !== "provider" && me.branding
                ? {
                      primary: me.branding.primaryColor,
                      secondary: me.branding.secondaryColor
                  }
                : defaultTheme;

        const primaryLightness = null; //Color(currentTheme.primary).getLightness();
        const secondaryLightness = null; //Color(currentTheme.secondary).getLightness();
        const isPrimaryLight = primaryLightness > 0.75;
        const isSecondaryLight = secondaryLightness > 0.75;
        const textPrimary = isPrimaryLight ? "#273444" : "white";
        const textSecondary = isSecondaryLight ? "#273444" : "white";

        return {
            weeklyTheme: {
                ...currentTheme,
                textPrimary,
                textSecondary
            },
            ...defaultTheme
        };
    };

    const {listenToEvent, unListenToEvent} = useSocket(me?.features?.booking_offer_sockets ? me.userId : null);

    return (
        <Auth0Provider {...providerConfig}>
            <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_API_CLIENT_ID}>
                <Provider store={store}>
                    <AppContext.Provider
                        value={{
                            me,
                            professions,
                            isMobile: isMobileDevice(),
                            theme: theme(),
                            selectedBranchId,
                            company: company || null,
                            services,
                            userLikedItems,
                            setUserLikedItems,
                            likeEntityHandler,
                            unlikeEntityHandler,
                            isolationMode,
                            setIsolationMode,
                            planBudgetOrder,
                            setPlanBudgetOrder,
                            searchInput,
                            setSearchInput,
                            filterValues,
                            setFilterValues,
                            fetchMe,
                            fetchCompany,
                            fetchUserBudget,
                            listenToEvent,
                            unListenToEvent
                        }}>
                        <MarketplaceFilterContext>
                            <Router>
                                <AppRouteListener onRouteChange={onPathChange} />
                                <AppHistoryAdapter />
                                <Layout id={getThemeByOrigin(window.location.origin)} style={{marginBottom: 0}}>
                                    <AppTopBar pathname={pathname} />
                                    <Route component={ErrorModal} />
                                    <CompanyBranding />
                                    {me.type === "company" ? (
                                        <>
                                            <CreditCardRequiredModal />
                                            <CreditCardDetailsModal />
                                            {/*<ActivateAccountModal/>*/}
                                        </>
                                    ) : null}
                                    {me.type === "provider" ? (
                                        <>
                                            <BankInformationRequiredModal />
                                            <BankInformationModal />
                                        </>
                                    ) : null}
                                    {typeof me.type !== "undefined" ? (
                                        <Switch>
                                            <Route
                                                exact
                                                path="/"
                                                render={props => <Redirect to="/login" {...props} />}
                                            />
                                            <Route exact path="/company/wizard/success" component={RegisterSuccess} />
                                            <Route
                                                exact
                                                path="/company/wizard/details"
                                                component={CompanySignUpShortStepperDetails}
                                            />
                                            <Route path="/company/wizard" component={CompanyRegister} />
                                            <Route exact path="/provider/wizard" component={ProviderSignUpStepper} />
                                            <Route
                                                exact
                                                path="/provider/wizard/:step"
                                                component={ProviderSignUpStepper}
                                            />
                                            <Route exact path="/provider/upload" component={AdminUploadPage} />
                                            <Route
                                                exact
                                                path="/provider/upload/:providerId"
                                                component={AdminUploadPage}
                                            />
                                            <Route
                                                path="/companies/:companyId/signup"
                                                component={CompanyEmployeeSignUp}
                                            />
                                            <Route path="/provider/signup" component={ProviderSignUp} />
                                            <Route path="/email/:hashedEmail/verify" component={EmailConfirmation} />
                                            <Route
                                                path="/password/:hashedEmail/expired"
                                                component={EmailConfirmationExpired}
                                            />
                                            <Route
                                                path="/password/reset"
                                                render={props => <ResetPassword {...props} onSuccess={updateMe} />}
                                            />
                                            <Route
                                                path="/login"
                                                render={props => <Login {...props} onSuccess={loginComplete} />}
                                            />
                                            <Route path="/unsubscribe" component={UnsubscribePage} />
                                            <Route exact path="/company/insights" component={CompanyInsights} />
                                            <Route exact path="/company/orders/:companyId" component={CompanyOrders} />
                                            <Route path="/dashboard" component={AppDashboardSelector} />
                                            <Route
                                                path="/marketplace"
                                                render={({history}) => (
                                                    <CompanyMarketplaceContextProvider
                                                        history={history}
                                                        rootPath="/marketplace"
                                                    />
                                                )}
                                            />
                                            <Route path="/contact" component={ContactUs} />
                                            <Route path="/forgot" component={ForgotPassword} />
                                            <Route path="/events/:eventId/proposal" component={EventProposal} />
                                            <Route path="/events/:eventId/proposalV2" component={EventProposalV2} />
                                            <Route path="/events/:eventId/orderV2" component={EventOrderV2} />
                                            <Route
                                                path="/events/:eventId/orderDeliveryStatus"
                                                component={OrderDeliveryStatus}
                                            />
                                            <Route
                                                path="/events/:eventId/approveDelivery"
                                                component={ProviderApproveDelivery}
                                            />
                                            <Route
                                                path="/events/:eventId/register/thank"
                                                component={EventRegisterModal}
                                            />
                                            <Route path="/events/:eventId/join" component={EventJoinModal} />
                                            <Route
                                                path="/events/:eventId/missing"
                                                component={EventNoMeetingLinkModal}
                                            />
                                            <Route path="/events/:eventId/edit" component={CompanyEditOrderPage} />
                                            <Route path="/admin/events/:eventId/edit" component={AdminEditOrderPage} />
                                            <Route exact path="/dashboard/weekly" component={CompanyWeeklyTemplate} />
                                            <Route
                                                exact
                                                path="/company/weekly/practices"
                                                component={CompanyWeeklyBestPractices}
                                            />
                                            <Route path="/dashboard/billing" component={CompanyBilling} />
                                            <Route path="/company/account" component={CompanyAccountSettings} />
                                            <Route
                                                path="/company/profile/:companyId"
                                                component={CompanyAccountProfile}
                                            />
                                            {me?.features?.calculator ? (
                                                <Route path="/happiness-calculator" component={HappinessCalculator} />
                                            ) : null}
                                            <Route exact path="/selectionPage/:pageId" component={SelectPage} />
                                            <Route path="/provider/billing" component={ProviderBilling} />
                                            <Route path="/providers/:providerId" component={ProviderDetails} />
                                            <Route path="/company/confirm" component={CompanyConfirmation} />
                                            <Route path="/admin/console" component={AdminConsoleDashboard} />
                                            <Route path={`/${ADMIN_PATH_NAME}`} component={AdminDashboard} />
                                            <Route path="/terms" component={AppTerms} />
                                            <Route path="/privacy" component={AppPrivacy} />
                                            <Route path="/zoom_docs" component={ZoomDocs} />
                                            <Route path="/center" component={EXCenter} />
                                            <Route path="/zoom/auth/complete" component={ZoomAuthCompleted} />
                                            <Route component={NotFoundPage} />
                                        </Switch>
                                    ) : null}
                                </Layout>
                                <PopupManager />
                                {/*<TutorialBox/>*/}
                                {me.type === "company" && !me.isAdmin && !me?.features.hide_contact_box ? (
                                    <ContactUsBox />
                                ) : null}
                                {showFeedbackButton(pathname) ? <FeedbackBox /> : null}
                            </Router>
                        </MarketplaceFilterContext>
                    </AppContext.Provider>
                </Provider>
            </GoogleOAuthProvider>
        </Auth0Provider>
    );
};
