import ProviderEventAppointment from "../../provider/ProviderEventAppointment";
import React, {useContext, useState} from "react";
import {HttpClient} from "../../http/HttpClient";
import {GoogleAnalytics} from "../../GoogleAnalytics";
import {AppContext} from "../../AppContext";
import {message} from "antd";
import {EXCenterContext} from "../EXCenterContext";

export const EXCenterProfileAppointmentEdit = props => {
    const {me} = useContext(AppContext);
    const {theme} = useContext(EXCenterContext);
    const {history} = props;
    const [saving, setSaving] = useState(false);

    const saveChanges = async appointment => {
        setSaving(true);
        try {
            await HttpClient.post(`/api/appointments/${appointment.appointmentId}`, appointment);
            GoogleAnalytics.event("Employee Booking", "Update Appointment", appointment.appointmentId);
            history.goBack();
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setSaving(false);
    };

    return (
        <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
            <ProviderEventAppointment
                {...props}
                me={me}
                canControlEndTime={false}
                fixedSaveButton={false}
                saveButtonColor={theme.primaryColor}
                onSave={saveChanges}
                saving={saving}
            />
        </div>
    );
};
