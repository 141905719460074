import React, {useCallback, useContext, useEffect, useMemo} from "react";
import moment from "moment-timezone";
import {DatePicker, Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {RangePickerMobile, ResponsiveView, SquareButton} from "../index.jsx";
import "./table-date-search.css";
import {isFullMonthRange} from "../../admin/components/utils.js";
import {TableDateSearchContext} from "../../admin/Context/TableDateSearchContext.jsx";
import classnames from "classnames";
import {Devices} from "../ResponsiveView/consts.js";

const {RangePicker} = DatePicker;

export const TableDateSearch = ({
    date,
    onDatePicked,
    onSearch,
    todayButtonText,
    defaultValue,
    disableFuture = true,
    allowClear = true,
    className
}) => {
    const {debouncedSearchText, setUnDebouncedSearchText, unDebouncedSearchText} = useContext(TableDateSearchContext);
    const innerDefaultValue = useMemo(() => defaultValue ?? date, [defaultValue]);

    const isShowingToday = useMemo(
        () => date.length > 1 && moment().diff(date[0], "days") === 0 && moment().diff(date[1], "days") === 0,
        [date]
    );

    const onRageDatePicker = useCallback(
        newTime => {
            if (!newTime) {
                onDatePicked([]);
                return;
            }
            const [newStartTime, newEndTime] = newTime;

            onDatePicked([newStartTime.startOf("day"), newEndTime.endOf("day")]);
        },
        [onDatePicked]
    );

    const onMountPicker = useCallback(
        month => {
            if (!month) {
                onDatePicked([]);
                return;
            }

            onDatePicked([moment(month).startOf("month"), moment(month).endOf("month")]);
        },
        [onDatePicked]
    );

    const setTodayDates = useCallback(() => {
        if (isShowingToday) {
            onDatePicked(innerDefaultValue);
        } else {
            onDatePicked([moment().startOf("day"), moment().endOf("day")]);
        }
    }, [isShowingToday, innerDefaultValue]);

    useEffect(() => {
        onSearch(debouncedSearchText);
    }, [debouncedSearchText]);

    return (
        <div className={classnames("admin-table-date-search", className)}>
            <Input
                value={unDebouncedSearchText}
                className="admin-table-date-search-search-input"
                onChange={e => setUnDebouncedSearchText(e.target.value)}
                placeholder="חיפוש חופשי"
                suffix={<SearchOutlined />}
            />

            <div className="table-date-container">
                <div className="admin-table-date-search-month-input">
                    <span>בחרו חודש</span>
                    <DatePicker
                        format="MM-YYYY"
                        placeholder="בחרו חודש"
                        onChange={onMountPicker}
                        picker="month"
                        disabledDate={date => disableFuture && date > moment()}
                        allowClear={allowClear}
                        value={isFullMonthRange(date) ? moment(date[0]) : null}
                    />
                </div>
                <div className="admin-table-date-search-date-input">
                    <ResponsiveView devices={[Devices.desktop]}>
                        <span>או בין תאריכים מדויקים</span>
                        <RangePicker
                            format="DD-MM-YYYY"
                            value={date}
                            onChange={onRageDatePicker}
                            disabledDate={date => disableFuture && date > moment()}
                            allowClear={allowClear}
                        />
                    </ResponsiveView>

                    <ResponsiveView devices={[Devices.mobile]}>
                        <RangePickerMobile
                            props={{
                                value: date,
                                onChange: onRageDatePicker,
                                disabledDate: date => disableFuture && date > moment(),
                                allowClear: allowClear
                            }}
                        />
                    </ResponsiveView>
                </div>
            </div>
            {todayButtonText ? (
                <SquareButton
                    className={`admin-table-date-search-today-button ${isShowingToday ? "showing-today" : ""}`}
                    onClick={setTodayDates}>
                    {todayButtonText}
                </SquareButton>
            ) : null}
        </div>
    );
};
