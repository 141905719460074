import moment from "moment";
import {LastOrderBreakPoints} from "./consts";
import {getCompanySizeTier, getCustomerTypeLabel, PodiumBreakPoints} from "./utils";
import {createWorkbook, createWorksheet, DownloadExcel} from "../../utils/excelUtils.js";
import {SectorsNames} from "../../consts.js";

export const exportTableToExcel = async (companies, companiesOrdersStatistics, companiesCustomerStats) => {
    const workbook = createWorkbook();

    const sheet = createWorksheet(workbook);
    addColumns(sheet);
    addRows(sheet, companies, companiesOrdersStatistics, companiesCustomerStats);
    addStyledRules(sheet);

    await DownloadExcel(workbook, "companies");
};

const addColumns = sheet => {
    sheet.columns = [
        {header: "שם החברה", key: "companyName"},
        {header: "ח.פ", key: "businessNumber"},
        {header: "כתובת", key: "address"},
        {header: "תאריך יצירה", key: "createdAt"},
        {header: "מס׳ עובדים", key: "employeesCount"},
        {header: "הזמנה ממוצעת", key: "averageOrderPrice"},
        {header: "ממוצע הזמנות חודשי", key: "averageMonthlyOrders"},
        {header: "ת. טאצ׳פוינט אחרון", key: "lastTouchPointDate", width: 30, style: {numFmt: "dd.mm.yy"}},
        {header: "סטטוס לקוח", key: "customerType"},
        {header: "טיר חברה", key: "companyTier"},
        {header: "פודיום", key: "companyScore"},
        {header: "תקציב נותר", key: "loadedBudget"},
        {header: "תנאי תשלום", key: "paymentOption"},
        {header: "איש קשר", key: "contactName"},
        {header: "טלפון", key: "contactNumber"},
        {header: "מייל", key: "contactEmail"},
        {header: "מנהלת לקוח", key: "csm"},
        {header: "הערות", key: "comment"},
        {header: "סקטור", key: "sector"}
    ];
};

const addRows = (sheet, companies, companiesOrdersStatistics, companiesCustomerStats) => {
    const rows = companies.map(company => {
        const companyOrdersStatistics = companiesOrdersStatistics?.[company.companyId];
        const companyCustomerStats = companiesCustomerStats?.[company.companyId];
        const createdAt = moment(company.createdAt).format("DD.MM.YY");
        const lastTouchPointDate = companyOrdersStatistics
            ? moment(companyOrdersStatistics.lastTouchPointDate).toDate()
            : null;

        const row = [
            company.name,
            company.businessNumber,
            company.address,
            createdAt,
            company.employeesCount,
            (Math.round(companyOrdersStatistics?.averageOrderPrice) || null)?.toLocaleString(),
            (companyOrdersStatistics?.averageMonthlyOrders ?? null)?.toFixed(2),
            lastTouchPointDate,
            getCustomerTypeLabel(companyCustomerStats?.customerType)?.label,
            getCompanySizeTier(Number.parseInt(company.employeesCount)),
            companyOrdersStatistics?.companyScore,
            company.loadedBudget,
            company.paymentOption,
            company.contactName,
            company.phone,
            company.contact,
            company.assignedCSMs?.map(({firstName}) => firstName).join(", "),
            company.adminComment?.content ?? company.adminComment,
            SectorsNames?.[company.sector]
        ];

        return row;
    });

    sheet.addRows(rows);
};

const addStyledRules = sheet => {
    const bronzeColor = "DAA520";

    const tiers = [
        {
            max: 100,
            min: PodiumBreakPoints[0],
            color: "FFD700"
        },
        {
            max: PodiumBreakPoints[0] + 0.1,
            min: PodiumBreakPoints[1],
            color: "C0C0C0"
        },
        {
            max: PodiumBreakPoints[1] + 0.1,
            min: 1,
            color: bronzeColor
        }
    ];

    tiers.forEach(({max, min, color}) => {
        sheet.addConditionalFormatting({
            ref: "$K:$K",
            rules: [
                {
                    type: "cellIs",
                    operator: "between",
                    formulae: [`${min}`, `${max}`],
                    style: {fill: {type: "pattern", pattern: "solid", bgColor: {argb: color}}}
                }
            ]
        });
    });

    sheet.addConditionalFormatting({
        ref: "$K:$K",
        rules: [
            {
                type: "expression",
                formulae: ["AND(COUNTBLANK($J1)=0,$J1=0)"],
                style: {fill: {type: "pattern", pattern: "solid", bgColor: {argb: bronzeColor}}}
            }
        ]
    });

    LastOrderBreakPoints.forEach(({excelColor, breakpoint}) => {
        sheet.addConditionalFormatting({
            ref: "$H:$H",
            rules: [
                {
                    type: "expression",
                    formulae: [`AND(COUNTBLANK($H1)=0,$H1<=TODAY()-${breakpoint})`],
                    style: {fill: {type: "pattern", pattern: "solid", bgColor: {argb: excelColor}}}
                }
            ]
        });
    });
};
