import {ThemeType} from "../../color/ColorTheme";
import {LuxuryImages, ClassicImages, PlayfulImages} from "./images";

export const CompanyContentDefaults = {
    profile: {
        [ThemeType.LUXURY]: {
            coverPhoto: LuxuryImages.generic_cover
        },
        [ThemeType.CLASSIC]: {
            coverPhoto: ClassicImages.generic_cover
        },
        [ThemeType.PLAYFUL]: {
            coverPhoto: PlayfulImages.generic_cover
        }
    },
    top: {
        [ThemeType.LUXURY]: {
            coverPhoto: LuxuryImages.top_cover
        },
        [ThemeType.CLASSIC]: {
            coverPhoto: ClassicImages.top_cover
        },
        [ThemeType.PLAYFUL]: {
            coverPhoto: PlayfulImages.top_cover
        }
    },
    events: {
        [ThemeType.LUXURY]: {
            coverPhoto: LuxuryImages.generic_cover
        },
        [ThemeType.CLASSIC]: {
            coverPhoto: ClassicImages.generic_cover
        },
        [ThemeType.PLAYFUL]: {
            coverPhoto: PlayfulImages.generic_cover
        }
    },
    services: {
        [ThemeType.LUXURY]: {
            coverPhoto: LuxuryImages.generic_cover
        },
        [ThemeType.CLASSIC]: {
            coverPhoto: ClassicImages.generic_cover
        },
        [ThemeType.PLAYFUL]: {
            coverPhoto: PlayfulImages.generic_cover
        }
    },
    updates: {
        [ThemeType.LUXURY]: {
            backgroundPhoto: LuxuryImages.generic_cover,
            coverPhoto: LuxuryImages.generic_cover
        },
        [ThemeType.CLASSIC]: {
            backgroundPhoto: ClassicImages.generic_cover,
            coverPhoto: ClassicImages.generic_cover
        },
        [ThemeType.PLAYFUL]: {
            backgroundPhoto: PlayfulImages.generic_cover,
            coverPhoto: PlayfulImages.generic_cover
        }
    },
    benefits: {
        [ThemeType.LUXURY]: {
            coverPhoto: LuxuryImages.generic_cover
        },
        [ThemeType.CLASSIC]: {
            coverPhoto: ClassicImages.generic_cover
        },
        [ThemeType.PLAYFUL]: {
            coverPhoto: PlayfulImages.generic_cover
        }
    },
    discounts: {
        [ThemeType.LUXURY]: {
            coverPhoto: LuxuryImages.generic_cover
        },
        [ThemeType.CLASSIC]: {
            coverPhoto: ClassicImages.generic_cover
        },
        [ThemeType.PLAYFUL]: {
            coverPhoto: PlayfulImages.generic_cover
        }
    },
    information: {
        [ThemeType.LUXURY]: {
            coverPhoto: LuxuryImages.generic_cover
        },
        [ThemeType.CLASSIC]: {
            coverPhoto: ClassicImages.generic_cover
        },
        [ThemeType.PLAYFUL]: {
            coverPhoto: PlayfulImages.generic_cover
        }
    },
    recommendations: {
        [ThemeType.LUXURY]: {
            coverPhoto: LuxuryImages.generic_cover
        },
        [ThemeType.CLASSIC]: {
            coverPhoto: ClassicImages.generic_cover
        },
        [ThemeType.PLAYFUL]: {
            coverPhoto: PlayfulImages.generic_cover
        }
    },
    pickerPhotos: {
        [ThemeType.LUXURY]: {
            events: LuxuryImages.picker_events_cover,
            services: LuxuryImages.picker_services_cover,
            updates: LuxuryImages.picker_updates_cover,
            benefits: LuxuryImages.picker_benefits_cover,
            discounts: LuxuryImages.picker_discounts_cover,
            information: LuxuryImages.picker_information_cover,
            recommendations: LuxuryImages.picker_recommendations_cover
        },
        [ThemeType.CLASSIC]: {
            events: ClassicImages.picker_events_cover,
            services: ClassicImages.picker_services_cover,
            updates: ClassicImages.picker_updates_cover,
            benefits: ClassicImages.picker_benefits_cover,
            discounts: ClassicImages.picker_discounts_cover,
            information: ClassicImages.picker_information_cover,
            recommendations: ClassicImages.picker_recommendations_cover
        },
        [ThemeType.PLAYFUL]: {
            events: PlayfulImages.picker_events_cover,
            services: PlayfulImages.picker_services_cover,
            updates: PlayfulImages.picker_updates_cover,
            benefits: PlayfulImages.picker_benefits_cover,
            discounts: PlayfulImages.picker_discounts_cover,
            information: PlayfulImages.picker_information_cover,
            recommendations: PlayfulImages.picker_recommendations_cover
        }
    }
};
