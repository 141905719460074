import {StyledButton} from "../../components/StyledButton";
import React, {useContext, useState} from "react";
import {take} from "lodash";
import {EXCenterContext} from "../EXCenterContext";

const CategoryButton = ({onClick, isActive, children}) => {
    const {theme} = useContext(EXCenterContext);
    return (
        <StyledButton
            className="wb-section-category-button"
            onClick={onClick}
            style={{
                borderRadius: 25,
                margin: 10,
                height: 40,
                color: isActive ? "white" : theme.primaryColor,
                backgroundColor: isActive ? theme.primaryColor : "white",
                border: isActive ? "none" : `1px solid ${theme.primaryColor}`
            }}>
            {children}
        </StyledButton>
    );
};

export const EXCenterSectionCategories = ({
    onCategorySelect,
    categories,
    autoCategory,
    style,
    attachRef,
    maxLength,
    withAll = false
}) => {
    const [activeCategory, setActiveCategory] = useState(null);
    const [autoUpdatedCategory, setAutoUpdatedCategory] = useState(false);

    if (typeof attachRef === "function" && !attachRef.attached) {
        attachRef.attached = true;
        attachRef({
            reset: () => setActiveCategory(null)
        });
    }

    const markActive = category => {
        if (activeCategory === category) {
            removeCategorySelection();
        } else {
            setActiveCategory(category);
            onCategorySelect(category);
        }
    };

    const removeCategorySelection = () => {
        setActiveCategory(null);
        onCategorySelect(null);
    };

    if (autoCategory && activeCategory === null && !autoUpdatedCategory) {
        setAutoUpdatedCategory(true);
        setTimeout(() => markActive(autoCategory), 100);
    }

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                minHeight: 100,
                boxShadow: "0 5px 20px 0 rgba(22,29,37,0.1)",
                width: "100%",
                marginRight: 10,
                marginLeft: 10,
                ...style
            }}>
            {withAll ? (
                <CategoryButton onClick={() => removeCategorySelection()} isActive={activeCategory === null}>
                    All
                </CategoryButton>
            ) : null}
            {take(categories, maxLength || categories.length).map(category => (
                <CategoryButton
                    key={category}
                    onClick={() => markActive(category)}
                    isActive={activeCategory === category}>
                    {category}
                </CategoryButton>
            ))}
        </div>
    );
};
