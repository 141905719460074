import React, {useEffect, useState} from "react";
import {PageTitleLabel} from "../../../components/PageTitle";
import {PlusOutlined, CheckOutlined} from "@ant-design/icons";
import {CompanyContentTable} from "./CompanyContentTable";
import {CompanyContentEditModal} from "../edit/CompanyContentEditModal";
import {Dropdown, Menu, message, Tooltip} from "antd";
import {HttpClient} from "../../../http/HttpClient";
import {findContentSectionById} from "../CompanyContent";
import {parse as qsParse} from "query-string";
import {ensureDesignForSection} from "../../../center/control/ensureDeisgnForSection";
import {GoogleAnalytics} from "../../../GoogleAnalytics";
import {sortBy, clone} from "lodash";
import {DownIcon, TrashIcon} from "../../../icons";
import {CompanyContentNewTagModal} from "../tags/CompanyContentNewTagModal";
import shortid from "shortid";
import {EventBus} from "../../../bus/EventBus";
import {CompanyContentTagEdit} from "../tags/CompanyContentTagEdit";
import {StyledButton} from "../../../components/StyledButton";
import {CompanyContentTagRemove} from "../tags/CompanyContentTagRemove";

const MAX_MANUAL_PICK = 3;

const extractSectionIdFromPath = location => {
    const parts = location.pathname.split("/");
    return parts[parts.length - 1];
};

const findCategoryBySectionId = sectionId => {
    return findContentSectionById(sectionId).displayName;
};

const displayTypes = [
    {
        text: "Manual",
        value: "manual"
    },
    {
        text: "Random",
        value: "random"
    },
    {
        text: "Latest",
        value: "latest"
    }
];

export const AddTagsItem = {
    tagId: "addTags",
    name: (
        <div style={{display: "flex", justifyContent: "flex-start", marginBottom: "-15px"}}>
            <PlusOutlined style={{marginTop: "5px", marginRight: "5px", color: "#ED7FA6"}} />
            <p style={{color: "#ED7FA6"}}>Add Category</p>
        </div>
    )
};

export const CompanyContentDataStore = ({location, initialSectionId, maxHeight, maxWidth, style, onChange}) => {
    const [contentToEdit, setContentToEdit] = useState(null);
    const [contentToAdd, setContentToAdd] = useState(null);
    const [addNewContent, setAddNewContent] = useState(false);
    let [dataSource, setDataSource] = useState(null);
    const [processingId, setProcessingId] = useState(null);
    const [sectionId, _] = useState(initialSectionId || extractSectionIdFromPath(location));
    const [category, __] = useState(findCategoryBySectionId(sectionId));
    const [displayType, setDisplayType] = useState("latest");
    const [designInfo, setDesignInfo] = useState("latest");
    const [subCategories, setSubCategories] = useState([]);
    const [newTagModalVisibility, setNewTagModalVisibility] = useState(false);
    const [editTagModalVisibility, setEditTagModalVisibility] = useState(false);
    const [tagToEdit, setTagToEdit] = useState(null);
    const [tagToRemove, setTagToRemove] = useState(null);
    const [removeCategoryModalVisibility, setRemoveCategoryModalVisibility] = useState(false);

    let {branchId} = qsParse(location.search);
    if (branchId === "Global") {
        branchId = null;
    }

    const setSubCategoriesHelper = tags => {
        setSubCategories(tags.concat([AddTagsItem]));
    };

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const [data, designInfo, tags] = await Promise.all([
                    HttpClient.get(`/api/content/sections/${sectionId}?hidden=true`),
                    HttpClient.get(`/api/website/settings`),
                    await HttpClient.get(`/api/tags/${sectionId}`)
                ]);
                ensureDesignForSection(designInfo, sectionId);
                setDesignInfo(designInfo);
                const sectionDesignInfo = designInfo.design[sectionId];
                setDisplayType(branchId && sectionDesignInfo.displayType ? sectionDesignInfo.displayType : "latest");
                setDataSource(data);
                setSubCategoriesHelper(tags);
            } catch (e) {
                message.error("Something went wrong, please try again later.", 5);
            }
        });
    }, []);

    const onContentDisplayTypeSelect = async value => {
        setDisplayType(value);
        try {
            await HttpClient.post(`/api/website/settings`, {
                ...designInfo,
                design: {
                    ...designInfo.design,
                    [sectionId]: {
                        ...designInfo.design[sectionId],
                        displayType: value
                    }
                }
            });
            onChange && onChange();
            GoogleAnalytics.event("EX Center - Content", "Change Display Type", value);
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
    };

    const onContentAdded = async content => {
        try {
            content = await HttpClient.put(`/api/content/sections/${sectionId}`, {...content, serviceName: category});
            setDataSource(dataSource.concat([content]));
            onChange && onChange();
            GoogleAnalytics.event("EX Center - Content", "Add Content", sectionId);
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
    };

    const editContent = content => {
        setContentToEdit(content);
    };

    const addContent = () => {
        setAddNewContent(true);
    };

    const closeContentModal = () => {
        setContentToEdit(null);
        setContentToAdd(null);
        setAddNewContent(false);
    };

    const changeDisplayState = async changedContent => {
        changedContent.status = changedContent.status === "info-hidden" ? "info-shown" : "info-hidden";
        GoogleAnalytics.event(
            "EX Center - Content",
            changedContent.status === "info-hidden" ? "Hide Content" : "Show Content",
            sectionId
        );
        await updateContent(changedContent);
    };

    const updateContent = async changedContent => {
        setProcessingId(changedContent.eventId);
        try {
            changedContent = await HttpClient.post(`/api/content`, changedContent);
            setDataSource(
                dataSource
                    .map(content => {
                        if (content.eventId === changedContent.eventId) {
                            return changedContent;
                        } else {
                            return content;
                        }
                    })
                    .filter(content => content.sectionId === sectionId)
            );
            onChange && onChange();
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setProcessingId(null);
    };

    const removeContent = async content => {
        setProcessingId(content.eventId);
        try {
            await HttpClient.delete(`/api/content/items/${content.eventId}`);
            setDataSource(dataSource.filter(c => c.eventId !== content.eventId));
            onChange && onChange();
            GoogleAnalytics.event("EX Center - Content", "Remove Content", sectionId);
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setProcessingId(null);
    };

    const changeManualPickState = async changedContent => {
        if (!changedContent.pickedForHomePage && alreadyReachedMaxManualPickCount()) {
            return message.error(
                `You have ${MAX_MANUAL_PICK} items picked for home page. Please remove one of your picked items before selecting a new one.`,
                7
            );
        }

        changedContent.pickedForHomePage = !changedContent.pickedForHomePage;
        await updateContent(changedContent);
        GoogleAnalytics.event("EX Center - Content", "Manual Pick", sectionId);
    };

    const alreadyReachedMaxManualPickCount = () => {
        const count = dataSource.filter(item => item.pickedForHomePage).length;
        return count >= MAX_MANUAL_PICK;
    };

    const sortByLastUpdated = ds => {
        if (ds) {
            const now = Date.now();
            return sortBy(ds, ({updatedAt}) => now - updatedAt);
        }

        return ds;
    };

    const sortDisplayedFirst = ds => {
        if (ds) {
            return sortBy(ds, ({status}) => status === "info-hidden");
        }

        return ds;
    };

    const saveTags = async (oldCategories, newCategories) => {
        try {
            const savedTags = await HttpClient.post(`/api/tags/${sectionId}`, newCategories || []);
            setSubCategoriesHelper(savedTags);
            EventBus.trigger(`tags_update:${sectionId}`, savedTags);
            GoogleAnalytics.event("EX Center - Tags", "Update Tags", sectionId);
        } catch (e) {
            if (e.statusCode === 400) {
                setSubCategories(oldCategories);
                EventBus.triggerError(
                    "server-error",
                    {
                        content: {
                            description: e.message,
                            hideSteps: true,
                            hideSubTitle: true,
                            title: "Ohh..."
                        },
                        cta: {hide: true}
                    },
                    e.message
                );
            } else {
                message.error("Something went wrong, please try again later.", 5);
            }
        }
    };

    const onEditTag = async editTagInfo => {
        const oldCategories = clone(subCategories);
        const newCategories = subCategories
            .map(category => {
                if (category.tagId === editTagInfo.tagId) {
                    return editTagInfo;
                }
                return category;
            })
            .filter(category => category.tagId !== AddTagsItem.tagId);
        await saveTags(oldCategories, newCategories);
        setEditTagModalVisibility(false);
    };

    const onRemoveTag = async () => {
        const oldCategories = clone(subCategories);
        const newCategories = subCategories.filter(
            category => category.tagId !== AddTagsItem.tagId && category.tagId !== tagToRemove.tagId
        );
        await saveTags(oldCategories, newCategories);
        setRemoveCategoryModalVisibility(false);
    };

    const onAddTag = async newTagInfo => {
        newTagInfo.tagId = shortid.generate();
        newTagInfo.sectionId = sectionId;
        const oldCategories = clone(subCategories);
        const newCategories = subCategories.filter(category => category.tagId !== AddTagsItem.tagId).concat(newTagInfo);
        await saveTags(oldCategories, newCategories);
        setNewTagModalVisibility(false);
    };

    const displayTypeOverlay = (
        <Menu
            className="wb-app-menu"
            style={{border: "1px solid #E8EDF5"}}
            onClick={({key}) => {
                onContentDisplayTypeSelect(key);
            }}>
            {displayTypes.map(({text, value}) => {
                return (
                    <Menu.Item
                        key={value}
                        style={{
                            fontWeight: value === displayType ? "600" : "300",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "var(--secondary-color)",
                            fontSize: 14,
                            backgroundColor: value === displayType ? "#e6f6ff" : "unset"
                        }}>
                        {text}
                        {value === displayType ? <CheckOutlined style={{color: "rgb(61 157 255)"}} /> : null}
                    </Menu.Item>
                );
            })}
        </Menu>
    );

    const subCategoriesOverlay = (
        <Menu
            className="wb-app-menu"
            style={{border: "1px solid #E8EDF5"}}
            onClick={menuInfo => {
                if (menuInfo.key === AddTagsItem.tagId) {
                    setNewTagModalVisibility(true);
                } else {
                    setTagToEdit(subCategories.find(category => category.tagId === menuInfo.key));
                    setEditTagModalVisibility(true);
                }
            }}>
            {subCategories.map(({tagId, name}) => {
                return (
                    <Menu.Item
                        key={tagId}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "var(--secondary-color)",
                            fontSize: 14
                        }}>
                        {name}
                        {tagId !== AddTagsItem.tagId ? (
                            <TrashIcon
                                onClick={e => {
                                    e.stopPropagation();
                                    setTagToRemove(subCategories.find(category => category.tagId === tagId));
                                    setRemoveCategoryModalVisibility(true);
                                }}
                                style={{fill: "var(--secondary-color)", width: 16, height: 16, marginTop: 2}}
                            />
                        ) : null}
                    </Menu.Item>
                );
            })}
        </Menu>
    );

    dataSource = sortDisplayedFirst(sortByLastUpdated(dataSource));

    return (
        <div
            style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginLeft: 20,
                marginRight: 20,
                marginBottom: 100,
                ...style
            }}>
            <CompanyContentNewTagModal
                tags={subCategories}
                visible={newTagModalVisibility}
                onClose={() => setNewTagModalVisibility(false)}
                onAdd={tagInfo => onAddTag(tagInfo)}
            />
            <CompanyContentTagEdit
                tags={subCategories}
                tagToEdit={tagToEdit}
                visible={editTagModalVisibility}
                onClose={() => setEditTagModalVisibility(false)}
                onEdit={tagInfo => onEditTag(tagInfo)}
            />
            <CompanyContentTagRemove
                tagToRemove={tagToRemove}
                visible={removeCategoryModalVisibility}
                onClose={() => setRemoveCategoryModalVisibility(false)}
                onRemove={() => onRemoveTag()}
            />
            <CompanyContentEditModal
                sectionId={sectionId}
                category={category}
                contentToEdit={contentToEdit || contentToAdd}
                title={addNewContent ? "Add content" : "Edit content"}
                onClose={() => closeContentModal()}
                visible={addNewContent || contentToEdit !== null}
                onEditDone={
                    contentToEdit !== null
                        ? async content => {
                              await updateContent(content);
                              GoogleAnalytics.event("EX Center - Content", "Update Content", sectionId);
                          }
                        : null
                }
                onAddDone={
                    addNewContent
                        ? async content => {
                              await onContentAdded(content);
                          }
                        : null
                }
                onValuesChange={content => {
                    if (contentToEdit !== null) {
                        setContentToEdit(content);
                    } else {
                        setContentToAdd(content);
                    }
                }}
                onDelete={removeContent}
                onDisplayChange={changeDisplayState}
                onAddCategory={() => setNewTagModalVisibility(true)}
            />
            <div style={{display: "flex", flexDirection: "column", maxWidth: 1200}}>
                <div style={{display: "flex", justifyContent: "space-between", marginBottom: 20, marginTop: 10}}>
                    <PageTitleLabel
                        style={{justifyContent: "flex-start", fontWeight: 400, marginTop: 0, marginBottom: 0}}>
                        {category}
                    </PageTitleLabel>
                    <Tooltip title={"Control the sub categories for this section"}>
                        <Dropdown
                            trigger={["hover"]}
                            placement="bottomLeft"
                            overlayStyle={{width: "200px"}}
                            overlay={subCategoriesOverlay}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    fontSize: 14,
                                    margin: "5px 10px 0 0",
                                    width: "150px",
                                    height: "40px",
                                    fontWeight: 300,
                                    color: "var(--secondary-color)",
                                    cursor: "pointer",
                                    border: "1px solid rgba(131,152,166,0.2)",
                                    borderRadius: "5px"
                                }}>
                                <p style={{marginTop: "6px"}}>Sub categories</p>
                                <DownIcon
                                    style={{
                                        marginLeft: 5,
                                        color: "rgba(17, 52, 76, 1)",
                                        width: 10,
                                        height: 10,
                                        marginTop: 14
                                    }}
                                />
                            </div>
                        </Dropdown>
                    </Tooltip>
                    <Tooltip
                        title={
                            branchId
                                ? "Control how your employees see this content."
                                : "Please select a specific branch to control home page display state."
                        }>
                        <Dropdown
                            trigger={["hover"]}
                            disabled={!branchId}
                            placement="bottomLeft"
                            overlay={displayTypeOverlay}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    fontSize: 14,
                                    margin: "5px 10px 0 0",
                                    width: "170px",
                                    height: "40px",
                                    fontWeight: 300,
                                    color: "var(--secondary-color)",
                                    cursor: "pointer",
                                    border: "1px solid rgba(131,152,166,0.2)",
                                    borderRadius: "5px"
                                }}>
                                <p style={{marginTop: "6px"}}>Home page display</p>
                                <DownIcon
                                    style={{
                                        marginLeft: 5,
                                        color: "rgba(17, 52, 76, 1)",
                                        width: 10,
                                        height: 10,
                                        marginTop: 14
                                    }}
                                />
                            </div>
                        </Dropdown>
                    </Tooltip>
                    <StyledButton
                        style={{
                            borderRadius: 5,
                            height: 40,
                            backgroundColor: "#ED7FA6",
                            border: "none",
                            color: "white",
                            boxShadow: "none",
                            width: 120,
                            marginTop: 5,
                            fontSize: 14
                        }}
                        onClick={addContent}>
                        Add Another
                    </StyledButton>
                </div>
                <CompanyContentTable
                    processingId={processingId}
                    dataSource={dataSource}
                    maxHeight={maxHeight}
                    maxWidth={maxWidth}
                    onEdit={editContent}
                    onDisplayChange={changeDisplayState}
                    onRemove={removeContent}
                    onManualPick={changeManualPickState}
                    onContentChange={updateContent}
                    displayType={displayType}
                />
            </div>
        </div>
    );
};
