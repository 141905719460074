import React, {useContext} from "react";
import {Logo} from "./images/Images";
import {AppContext} from "./AppContext";
import {SmartImageContainer} from "./components/SmartImageContainer";
import {EventBus} from "./bus/EventBus";
import {getThemeByOrigin} from "./utils.js";

export const AppLogo = ({className, style, onClick, width, height}) => {
    const {me} = useContext(AppContext);

    if (!me.type) {
        return null;
    }

    const themeByOrigin = getThemeByOrigin(window.location.origin);
    const defaultLogo = Logo?.[themeByOrigin] || Logo.image;

    const logo = me.branding && me.branding.logoUrl ? me.branding.logoUrl : defaultLogo;

    return (
        <div className={`wb-app-logo${className ? ` ${className}` : ""}`} style={style}>
            {logo === Logo.image ? (
                <img
                    width={width || "80"}
                    height={height || "20"}
                    onClick={() => {
                        EventBus.trigger("clearFilterValues");
                        onClick();
                    }}
                    style={{cursor: "pointer"}}
                    alt="well-b-logo"
                    src={logo}
                />
            ) : (
                <SmartImageContainer
                    width={width || "125"}
                    height={height || "50"}
                    transformations={{
                        crop: "lpad"
                    }}
                    onClick={() => {
                        EventBus.trigger("clearFilterValues");
                        onClick();
                    }}
                    style={{cursor: "pointer"}}
                    src={logo}
                />
            )}
        </div>
    );
};
