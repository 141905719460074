import React, {useCallback, useEffect, useMemo, useState} from "react";
import {message, Modal} from "antd";
import moment from "moment-timezone";
import ProviderManageInvoicesTable from "../../provider/ProviderManageInvoices/ProviderManageInvoicesTable";
import {OrderStatus} from "../../components";
import {useRequest} from "../../utils/hooks";
import {HttpClient} from "../../http/HttpClient";

const AdminProviderMonthOrdersModal = ({
    providerId,
    visible,
    onCancel,
    addInvoice,
    removeInvoice,
    month,
    invoices = [],
    fetchInvoicesOnMonthChange = false
}) => {
    const [providerInfo] = useRequest(`/admin/api/providers/${providerId}`, "GET", null, [], !!providerId);
    const [selectedMonth, setSelectedMonth] = useState(moment().subtract(1, "month"));

    const [fetchedInvoices] = useRequest(
        `/admin/api/providerInvoice/byProvider/${providerId}`,
        "GET",
        null,
        [],
        !!providerId && fetchInvoicesOnMonthChange && !selectedMonth.isSame(month, "month")
    );

    useEffect(() => {
        if (month) {
            setSelectedMonth(month);
        }
    }, [month]);

    const uploadInvoice = useCallback(
        async invoice => {
            const newInvoice = await HttpClient.safePut("/admin/api/providerInvoice/createInvoice", {
                providerId,
                ...invoice
            });

            if (newInvoice.error) {
                message.error("תקלה ביצירת חשבונית");
                return;
            }

            addInvoice(newInvoice);
        },
        [providerId]
    );

    const ordersReqBody = useMemo(
        () => ({
            from: selectedMonth.clone().startOf("month").valueOf(),
            to: selectedMonth.clone().endOf("month").valueOf(),
            byCreatedAt: false,
            statuses: [OrderStatus.accepted, OrderStatus.pending]
        }),
        [selectedMonth]
    );

    const [orders, loadingOrders] = useRequest(
        `/admin/api/providers/${providerId}/events`,
        "POST",
        ordersReqBody,
        [ordersReqBody],
        !!selectedMonth
    );

    const deleteInvoice = useCallback(
        async invoiceId => {
            const {error} = await HttpClient.safeDelete(`/admin/api/providerInvoice/${providerId}/${invoiceId}`);

            if (error) {
                message.error("שגיאה בעת מחיקת חשבונית");
                return;
            }

            message.success("חשבונית נמחקה בהצלחה");
            removeInvoice(invoiceId);
        },
        [providerId]
    );

    return (
        <Modal
            className="admin-provider-month-invoices-modal"
            width="90vw"
            destroyOnClose={true}
            open={visible}
            onCancel={onCancel}
            footer={null}
            title={null}>
            <ProviderManageInvoicesTable
                orders={orders}
                loadingOrders={loadingOrders}
                selectedMonth={selectedMonth}
                setSelectedMonth={setSelectedMonth}
                providerDetails={providerInfo}
                deleteInvoice={deleteInvoice}
                uploadInvoice={uploadInvoice}
                invoices={fetchedInvoices ?? invoices}
                allowViewDatesBeforeInvoiceReform={true}
                logsURI={`/admin/api/providerInvoiceLog/providerId/${providerId}/byMonth/${selectedMonth.format(
                    "MM-YYYY"
                )}`}
            />
        </Modal>
    );
};

export default AdminProviderMonthOrdersModal;
