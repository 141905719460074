import {MainServices} from "../../../../consts.js";

export const CategoryToLocationFilterText = {
    [MainServices.HAPPY_HOURS]: "לאן לשלוח?",
    [MainServices.PACKAGES]: "שילוח המתנה",
    [MainServices.WORKSHOPS]: "איפה הפעילות?"
};

export const deliveryAddressOptions = {
    deliveryToAddress: "deliveryToAddress",
    deliveryToAddresses: "deliveryToAddresses",
    onSite: "onSite"
};
