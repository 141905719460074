import {DatePicker, Form, message, Modal, Select, Tooltip} from "antd";
import React, {useState, useContext, useEffect, useRef} from "react";
import {FormInput, FormTextArea, SaveButton} from "../components/form";
import {AppContext} from "../AppContext";
import {CloseIcon} from "../icons";
import {ColorPickerButton} from "../components/ColorPicker";
// import randomColor from "randomcolor";
import {SearchableSelect} from "../components/SearchableSelect";
import {validateEmailExistence} from "../components/form/validators";
import {StepperInput} from "../wizards/components/StepperInput";
import {CustomTimePicker} from "../components/CustomTimePicker";
import moment from "moment";
import {AllLocationsSelect} from "../components/LocationSelect";
import {CompanyBranchSelect, hasBranches} from "../company/CompanyBranchSelect";
import {EventActionButtonFormInput} from "./info/edit/EventActionButtonFormInput";
import {EventPreviewCover} from "./preview/EventPreviewCover";
import cover_placeholder from "../images/placeholder-image.jpg";
import {BulbOutlined} from "@ant-design/icons";
import {PageTitleLabel} from "../components/PageTitle";
import _ from "lodash";
import {LocationLabels} from "../data/locations";
import {ZoomLinkInput} from "../zoom/ZoomLinkInput";
import {AutoDirectionProvider, isRTL} from "../AutoDirectionProvider";
import {AsiaJerusalemWorkingDays} from "./DaysMapper";
import {StyledButton} from "../components/StyledButton";
import {EditableInput} from "../components/EditableInput";

const {Option} = Select;

export const EventCreateModal = ({visible, onSave, onClose, employees, date}) => {
    const {me, company} = useContext(AppContext);
    const [form] = Form.useForm();
    const [eventColor, setEventColor] = useState(randomColor({luminosity: "dark"}));
    const [eventHost, setEventHost] = useState("company");
    const [eventFrequency, setEventFrequency] = useState("once");
    const [actionButtonType, setActionButtonType] = useState("system");
    const [eventImageUrl, setEventImageUrl] = useState(cover_placeholder);
    const [previewName, setPreviewName] = useState("");
    const [previewDate, setPreviewDate] = useState(moment(Date.now()).format("MMM Do, dddd"));
    const [previewTime, setPreviewTime] = useState(moment(Date.now()).format("HH:mm"));
    const [previewAddress, setPreviewAddress] = useState(null);
    const [eventLocation, setEventLocation] = useState(LocationLabels.ONSITE);
    const [previewHost, setPreviewHost] = useState(company.name);
    const [startDateMom, setStartDateMom] = useState(moment(Date.now()));
    const [previewDescription, setPreviewDescription] = useState(null);
    const [previewActionButtonText, setPreviewActionButtonText] = useState("");

    useEffect(() => {
        if (date) {
            setPreviewDate(moment(date).format("MMM Do, dddd"));
            form.setFields([
                {name: "startDate", value: moment(date)},
                {name: "endDate", value: moment(date)}
            ]);
        }
    }, [date]);

    const getSessionHost = () => {
        return "By " + previewHost;
    };

    const getPreviewActionButtonText = () => {
        if (actionButtonType) {
            return actionButtonType === "system" ? "Register now" : previewActionButtonText;
        }
        return "";
    };

    const getDuration = (start, end) => {
        const diff = (end - start) / 60000;
        return Math.abs(Math.round(diff));
    };

    const clearModalAndClose = () => {
        setPreviewDate(moment(Date.now()).format("MMM Do, dddd"));
        setPreviewAddress(null);
        setPreviewHost(company.name);
        setPreviewTime(moment(Date.now()).format("HH:mm"));
        setPreviewDescription(null);
        setPreviewName("");
        setEventColor(randomColor({luminosity: "dark"}));
        setEventImageUrl(cover_placeholder);
        setActionButtonType("system");
        setEventHost("company");
        setEventFrequency("once");
        setEventLocation(LocationLabels.ONSITE);
        form.setFields([
            {name: "eventName", value: null},
            {name: "eventPhoto", value: null},
            {name: "description", value: null},
            {name: "address", value: null},
            {name: "freq", value: "once"},
            {name: "branches", value: []},
            {name: "location", value: LocationLabels.ONSITE},
            {name: "maxParticipants", value: null},
            {name: "actionType", value: "system"},
            {name: "email", value: null},
            {name: "eventHost", value: "company"}
        ]);
        onClose();
    };

    const getDurationType = durationInMin => {
        if (durationInMin) {
            if (durationInMin > 60 && durationInMin < 60 * 24) {
                return "hour";
            } else if (durationInMin >= 60 * 24) {
                return "day";
            }
        }
        return "min";
    };

    const save = async values => {
        const tzid = moment.tz.guess();
        const startTime = moment(
            `${moment(values.startDate).format("YYYY-MM-DD")} ${values.startTime.format("HH:mm")}`,
            "YYYY-MM-DD HH:mm"
        );
        const dtstart = startTime.toDate().getTime();

        const until = moment(
            `${moment(values.endDate).format("YYYY-MM-DD")} ${values.endTime.format("HH:mm")}`,
            "YYYY-MM-DD HH:mm"
        )
            .toDate()
            .getTime();

        values.description = previewDescription;
        values.dtstart = dtstart;
        values.until = eventFrequency === "once" ? until : null;

        values.duration = getDuration(dtstart, until);
        values.durationType = getDurationType(values.duration);

        values.freq = eventFrequency === "once" ? "weekly" : eventFrequency;

        const day = moment(values.startDate).format("dd");
        if (values.freq === "daily") {
            values.byweekday = Object.keys(AsiaJerusalemWorkingDays);
        } else {
            values.byweekday = [day.toUpperCase()];
        }

        values.interval = values.freq === "2-weekly" ? 2 : values.freq === "monthly" ? 4 : 1;
        values.freq = values.freq === "2-weekly" ? "weekly" : values.freq;
        values.tzid = tzid;
        values.sessionName = values.eventName;
        values.priceType = "group";
        values.notes = values.description;
        values.startTime = moment(dtstart);
        values.sessionType = eventHost;
        values.backgroundColor = eventColor;
        values.productCoverPhoto = values.eventPhoto ? values.eventPhoto.imageUrl : null;
        onSave(values);
        clearModalAndClose();
    };

    const validateDatesAndTime = values => {
        const startDateStr = values.startDate.format("YYYY-MM-DD");
        const startTimeStr = values.startTime.format("HH:mm");
        const endDateStr = values.endDate.format("YYYY-MM-DD");
        const endTimeStr = values.endTime.format("HH:mm");
        const startMom = moment(`${startDateStr} ${startTimeStr}`, "YYYY-MM-DD HH:mm");
        const endMom = moment(`${endDateStr} ${endTimeStr}`, "YYYY-MM-DD HH:mm");
        return startMom.isBefore(endMom);
    };

    return (
        <Modal
            className="wb-weekly-edit-modal"
            bodyStyle={{padding: 0, borderRadius: 10}}
            style={{marginTop: 50}}
            centered={true}
            closable={true}
            closeIcon={<CloseIcon fill={"#8398A6"} />}
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            zIndex={1001}
            footer={null}
            title={
                <div
                    style={{
                        display: "flex",
                        alignItems: "flex-start",
                        color: "var(--secondary-color)",
                        height: 60,
                        fontSize: 20,
                        fontWeight: 700,
                        paddingLeft: 20,
                        paddingTop: 15,
                        backgroundColor: "rgba(131,152,166,0.2)"
                    }}>
                    Create a new event
                </div>
            }>
            <Form
                form={form}
                onFinish={values => {
                    if (validateDatesAndTime(values)) {
                        save(values);
                    } else {
                        message.error("Please check your dates and time of the event", 3);
                    }
                }}
                initialValues={{
                    startDate: date ? moment(date) : moment(Date.now()),
                    endDate: date ? moment(date) : moment(Date.now())
                }}
                onValuesChange={(_, allValues) => {
                    if (allValues.eventName) {
                        setPreviewName(allValues.eventName);
                    }
                    if (allValues.startDate) {
                        setStartDateMom(allValues.startDate);
                        setPreviewDate(allValues.startDate.format("MMM Do, dddd"));
                    }
                    if (allValues.startTime) {
                        setPreviewTime(allValues.startTime.format("HH:mm"));
                    }
                    if (allValues.address) {
                        setPreviewAddress(allValues.address);
                    }
                    if (allValues.location) {
                        setEventLocation(allValues.location);
                    }
                    if (allValues.employeeId) {
                        setPreviewHost(allValues.employeeId);
                    }
                    if (allValues.email) {
                        setPreviewHost(allValues.email);
                    }
                    if (allValues.eventHost) {
                        setPreviewHost(company.name);
                    }
                }}>
                <div style={{width: 1100, display: "flex"}}>
                    <div style={{width: "49%", height: 700, backgroundColor: "white", overflow: "auto"}}>
                        <div style={{width: "100%", padding: 20}}>
                            <label style={{fontSize: 16, color: "var(--secondary-color)"}}>Name event</label>
                            <div style={{display: "flex", marginTop: 5}}>
                                <Form.Item
                                    name="eventName"
                                    style={{marginRight: 10, width: "420px", fontSize: "16px"}}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter event Name."
                                        }
                                    ]}>
                                    <FormInput
                                        style={{fontSize: "16px", textAlign: isRTL(previewName) ? "right" : "left"}}
                                        placeholder="Make it short, descriptive and exciting"
                                    />
                                </Form.Item>
                                <Tooltip title="Change event color">
                                    <Form.Item name="eventColor">
                                        <ColorPickerButton
                                            pickerStyle={{left: "-215px"}}
                                            buttonStyle={{
                                                height: "55px",
                                                width: "55px",
                                                borderRadius: "15px",
                                                marginTop: "-5px"
                                            }}
                                            onChange={hex => setEventColor(hex)}
                                            color={eventColor}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </div>
                            <label style={{fontSize: 16, color: "var(--secondary-color)"}}>Who is the host?</label>
                            <div style={{display: "flex", marginTop: 5}}>
                                <Form.Item name="eventHost" style={{width: "170px", marginRight: "10px"}}>
                                    <Select
                                        style={{fontSize: "16px"}}
                                        onChange={value => setEventHost(value)}
                                        defaultValue={eventHost}>
                                        <Option value="employee">Employee</Option>
                                        <Option value="company">Company</Option>
                                        <Option value="provider">Vendor</Option>
                                    </Select>
                                </Form.Item>
                                {eventHost === "employee" ? (
                                    <Form.Item
                                        name="employeeId"
                                        style={{width: "300px"}}
                                        rules={[{required: true, message: "Please select session host."}]}>
                                        <SearchableSelect style={{fontSize: "16px"}} placeholder="Session's host">
                                            {employees.map(emp => (
                                                <Option
                                                    key={`emp-${emp.userId}`}
                                                    value={emp.userId}>{`${emp.firstName} ${emp.lastName}`}</Option>
                                            ))}
                                        </SearchableSelect>
                                    </Form.Item>
                                ) : null}
                                {eventHost === "provider" ? (
                                    <Form.Item
                                        name="email"
                                        style={{width: "300px"}}
                                        rules={[
                                            {
                                                required: true,
                                                type: "email",
                                                message: "Please enter a valid email."
                                            },
                                            {
                                                validator: async (_, email) => await validateEmailExistence(email)
                                            }
                                        ]}>
                                        <StepperInput
                                            style={{fontSize: "16px", borderRadius: 10}}
                                            placeholder="Vendor's email"
                                        />
                                    </Form.Item>
                                ) : null}
                                {eventHost === "company" ? (
                                    <Select
                                        disabled={true}
                                        showArrow={false}
                                        className="event-day"
                                        style={{width: 300, fontSize: "16px"}}
                                        placeholder="Hosted by the company"></Select>
                                ) : null}
                            </div>
                            {eventHost === "provider" ? (
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <Form.Item
                                        name="providerFirstName"
                                        style={{marginRight: 10, width: "150px", fontSize: "16px"}}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter provider first name."
                                            }
                                        ]}>
                                        <FormInput style={{fontSize: "16px"}} placeholder="First name" />
                                    </Form.Item>
                                    <Form.Item name="providerLastName" style={{width: "150px", fontSize: "16px"}}>
                                        <FormInput style={{fontSize: "16px"}} placeholder="Last name" />
                                    </Form.Item>
                                </div>
                            ) : null}
                            <label style={{fontSize: 16, color: "var(--secondary-color)"}}>Set the time</label>
                            <div style={{display: "flex", marginTop: 5}}>
                                <Form.Item
                                    name="startDate"
                                    rules={[
                                        {
                                            required: true
                                        },
                                        {
                                            validator: (_, startDate) => {
                                                if (
                                                    startDate &&
                                                    moment(Date.now()).isBefore(moment(startDate).endOf("day"))
                                                ) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject("Please enter future date.");
                                            }
                                        }
                                    ]}>
                                    <DatePicker
                                        inputReadOnly={true}
                                        className="wb-date-picker"
                                        style={{minWidth: 120, maxWidth: 120, marginRight: 10, fontSize: "16px"}}
                                        suffixIcon={null}
                                        defaultValue={moment(Date.now())}
                                        format="MMM DD, YYYY"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="startTime"
                                    rules={[{type: "object", required: true, message: "Please select start time."}]}>
                                    <CustomTimePicker
                                        style={{width: 90, fontSize: "16px", marginRight: 5}}
                                        format="HH:mm"
                                        suffixIcon={null}
                                        minuteStep={15}
                                        inputReadOnly={false}
                                        showNow={true}
                                        placeholder={"Start"}
                                    />
                                </Form.Item>
                                <div style={{width: "25px", marginTop: "15px", marginLeft: "10px", fontSize: 16}}>
                                    to
                                </div>
                                <Form.Item
                                    name="endDate"
                                    rules={[
                                        {
                                            required: true
                                        },
                                        {
                                            validator: (_, endDate) => {
                                                if (
                                                    endDate &&
                                                    moment(startDateMom)
                                                        .startOf("day")
                                                        .isBefore(moment(endDate).endOf("day"))
                                                ) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject("Please enter future date.");
                                            }
                                        }
                                    ]}>
                                    <DatePicker
                                        inputReadOnly={true}
                                        className="wb-date-picker"
                                        style={{minWidth: 120, maxWidth: 120, marginRight: 10, fontSize: "16px"}}
                                        suffixIcon={null}
                                        defaultValue={moment(Date.now())}
                                        format="MMM DD, YYYY"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="endTime"
                                    rules={[{type: "object", required: true, message: "Please select end time."}]}>
                                    <CustomTimePicker
                                        style={{width: 90, fontSize: "16px"}}
                                        format="HH:mm"
                                        suffixIcon={null}
                                        minuteStep={15}
                                        inputReadOnly={false}
                                        showNow={true}
                                        placeholder={"End"}
                                    />
                                </Form.Item>
                            </div>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <label style={{fontSize: 16, color: "var(--secondary-color)"}}>
                                        Is this a one time event or recurring?
                                    </label>
                                    <Form.Item
                                        name="freq"
                                        style={{width: "280px", marginRight: "10px", marginTop: "5px"}}>
                                        <Select
                                            style={{fontSize: "16px"}}
                                            onChange={value => setEventFrequency(value)}
                                            defaultValue={eventFrequency}>
                                            <Option value="once">Does not repeat</Option>
                                            <Option value="daily">Every Day</Option>
                                            <Option value="weekly">Every week</Option>
                                            <Option value="2-weekly">Every 2 weeks</Option>
                                            <Option value="monthly">Every month</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <label style={{fontSize: 16, color: "var(--secondary-color)"}}>
                                        Participants limit
                                    </label>
                                    <Form.Item
                                        name="maxParticipants"
                                        style={{marginRight: 10, width: "190px", fontSize: "16px", marginTop: "5px"}}>
                                        <FormInput style={{fontSize: "16px"}} placeholder="Max Participants" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <label style={{fontSize: 16, color: "var(--secondary-color)"}}>
                                    Where is the event taking place?
                                </label>
                                <div style={{display: "flex", flexDirection: "row", width: "550px"}}>
                                    <Form.Item
                                        name="location"
                                        rules={[{required: true, message: "Please select location."}]}
                                        style={{width: "190px", marginRight: "10px", marginTop: "5px"}}>
                                        <AllLocationsSelect placeholder={"Choose Location"} />
                                    </Form.Item>
                                    {!eventLocation ||
                                    eventLocation === LocationLabels.ONSITE ||
                                    eventLocation === LocationLabels.OFFSITE ? (
                                        <Form.Item
                                            name="address"
                                            style={{
                                                marginRight: 10,
                                                width: "280px",
                                                fontSize: "16px",
                                                marginTop: "5px"
                                            }}>
                                            <FormInput
                                                style={{fontSize: "16px"}}
                                                placeholder="Specific address, floor, room"
                                            />
                                        </Form.Item>
                                    ) : (
                                        <Form.Item
                                            name="meetingLink"
                                            style={{marginTop: 5, width: 290}}
                                            rules={[
                                                {
                                                    required: true,
                                                    type: "url",
                                                    message: "Please enter valid meeting link."
                                                }
                                            ]}>
                                            <ZoomLinkInput placeholder="Insert meeting link" recurring={true} />
                                        </Form.Item>
                                    )}
                                </div>
                            </div>
                            <label style={{fontSize: 16, color: "var(--secondary-color)"}}>Describe your event</label>
                            <Form.Item name="description" style={{width: "480px"}}>
                                <EditableInput
                                    style={{
                                        width: "480px",
                                        minHeight: "20em",
                                        border: "1px solid #E8EDF5",
                                        backgroundColor: "#FCFDFE",
                                        fontSize: "16px",
                                        borderRadius: "10px",
                                        outline: "none"
                                    }}
                                    onChange={html => setPreviewDescription(html)}
                                />
                            </Form.Item>
                            {hasBranches(me, company) ? (
                                <div>
                                    <label style={{fontSize: 16, color: "var(--secondary-color)"}}>
                                        Assign locations
                                    </label>
                                    <Form.Item
                                        name="branches"
                                        rules={[
                                            {
                                                required: true,
                                                type: "array",
                                                message: "Please select site."
                                            }
                                        ]}
                                        style={{marginTop: "5px", width: "480px"}}>
                                        <CompanyBranchSelect mode="tags" branches={company.branches} />
                                    </Form.Item>
                                </div>
                            ) : null}
                            <Form.Item name="actionType">
                                <EventActionButtonFormInput
                                    labelStyle={{display: "none"}}
                                    actionButtonType={actionButtonType}
                                    onActionButtonTypeChange={type => setActionButtonType(type)}
                                    actionTypeStyle={{marginLeft: "-10px"}}
                                    onActionButtonTextChange={text => setPreviewActionButtonText(text)}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div
                        style={{
                            width: "51%",
                            padding: 20,
                            height: 700,
                            backgroundColor: "#F6F8FA",
                            overflow: "auto",
                            display: "flex",
                            flexDirection: "column"
                        }}>
                        <Form.Item name="eventPhoto">
                            <EventPreviewCover
                                event={{}}
                                coverStyle={{borderRadius: 5}}
                                showReload={true}
                                imageUrl={eventImageUrl}
                                onChange={({imageUrl}) => setEventImageUrl(imageUrl)}
                                coverSize={{width: 650, height: 300}}
                                showCoverTag={true}
                                query={!_.isEmpty(previewName) ? previewName : "event"}
                                reloadButtonInfo={{
                                    text: "Offer me",
                                    style: {
                                        width: 120,
                                        height: 40,
                                        borderRadius: 20,
                                        marginLeft: -15,
                                        color: "white",
                                        backgroundColor: "rgba(0,0,0,0.5)",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    },
                                    icon: <BulbOutlined width={20} height={20} />
                                }}
                            />
                        </Form.Item>
                        <AutoDirectionProvider text={previewName} style={{marginBottom: 15}}>
                            <PageTitleLabel
                                style={{fontSize: 28, marginBottom: "-10px", marginTop: "-20px", textAlign: "start"}}>
                                {_.isEmpty(previewName) ? "Your new session title" : previewName}
                            </PageTitleLabel>
                        </AutoDirectionProvider>
                        <AutoDirectionProvider text={previewName}>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{fontSize: 14, color: "#273444"}}>{previewDate}</div>
                                <div style={{fontSize: 14, color: "#273444"}}>, {previewTime}</div>
                                {_.isEmpty(previewAddress) ? null : (
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        <div style={{margin: "0 5px", color: "#273444"}}>|</div>
                                        <div style={{fontSize: 14, color: "#273444"}}>{previewAddress}</div>
                                    </div>
                                )}
                            </div>
                        </AutoDirectionProvider>
                        <AutoDirectionProvider
                            text={previewName}
                            style={{
                                display: "flex",
                                fontSize: "20px",
                                color: "rgb(18, 96, 209)",
                                marginTop: "10px"
                            }}>
                            {getSessionHost()}
                        </AutoDirectionProvider>
                        <div className="content" dangerouslySetInnerHTML={{__html: previewDescription}}></div>
                        {actionButtonType !== "none" ? (
                            <div
                                style={{
                                    height: 70,
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    paddingRight: 30
                                }}>
                                <StyledButton
                                    disabled
                                    style={{
                                        width: 155,
                                        backgroundColor: "#ED7FA6",
                                        color: "white",
                                        borderRadius: 10,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                    {getPreviewActionButtonText()}
                                </StyledButton>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div
                    style={{
                        height: 70,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        paddingRight: 30,
                        borderTop: "1px solid #DADEE4"
                    }}>
                    <SaveButton
                        onClick={() => onSave()}
                        style={{backgroundColor: "#ED7FA6", color: "white", borderRadius: 10}}
                    />
                </div>
            </Form>
        </Modal>
    );
};
