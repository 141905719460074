import React from "react";
import classNames from "classnames";
import "./table-double-value-cell.css";

export const TableDoubleValueCell = ({children = [], className}) => (
    <div className={classNames("table-double-value-cell", className)}>
        {children.slice(0, 2).map((child, index) => (
            <label key={index}>{child}</label>
        ))}
    </div>
);
