import {Input, Modal} from "antd";
import React, {useContext, useState} from "react";
import {CloseIcon} from "../icons";
import {Images} from "../images/Images";
import {PageTitleLabel} from "../components/PageTitle";
import {StyledButton} from "../components/StyledButton";
import {AppContext} from "../AppContext";
import {isEmpty} from "lodash";

export const CompanyEmployeesHibobModal = ({visible, onClose, onAdd}) => {
    const {company} = useContext(AppContext);

    const getToken = () => {
        return !isEmpty(company.integrations) && company.integrations.people.type === "hibob"
            ? company.integrations.people.params.token
            : null;
    };

    const [token, setToken] = useState(getToken());

    return (
        <Modal
            className="wb-modal-radius"
            destroyOnClose={true}
            closable={true}
            closeIcon={<CloseIcon style={{fill: "white"}} />}
            title={null}
            footer={null}
            onCancel={onClose}
            width={600}
            height={800}
            bodyStyle={{padding: 0}}
            open={visible}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <img width={600} height={300} src={Images.hibob} alt="hibob-gif" />
            </div>
            <PageTitleLabel
                style={{marginBottom: 10, color: "var(--secondary-color)", fontSize: "32px", fontWeight: "300"}}>
                HiBob integration
            </PageTitleLabel>
            <div
                style={{
                    fontSize: 20,
                    color: "var(--secondary-color)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                <p>Use Hibob integration to import all your employees to the</p>
                <p>platform at once, as well as fill in their personal</p>
                <p>information to well-b automatically!</p>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    paddingBottom: "30px",
                    margin: "30px 10px"
                }}>
                <Input
                    style={{
                        border: "1px solid #E8EDF5",
                        backgroundColor: "#FCFDFE",
                        fontSize: "16px",
                        borderRadius: "5px",
                        height: "40px",
                        width: "400px",
                        outline: "none",
                        webkitAppearance: "none !important",
                        mozAppearance: "textfield !important",
                        marginRight: "15px"
                    }}
                    placeholder={"Paste HiBob token here"}
                    value={token}
                    onChange={e => setToken(e.target.value)}></Input>
                <StyledButton
                    onClick={() => onAdd(token)}
                    style={{
                        height: "40px",
                        width: "126px",
                        borderRadius: "5px",
                        backgroundColor: "#ED7FA6 !important",
                        color: "#FFF"
                    }}>
                    Integrate!
                </StyledButton>
            </div>
        </Modal>
    );
};
