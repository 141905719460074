import React from "react";
import {StyledButton} from "../../components/index.jsx";
import "./order-actions.css";
import classnames from "classnames";
import {ConfigProvider, Spin} from "antd";

export const OrderActions = ({actions, className}) => {
    return (
        <div className={classnames("order-actions-container", className)}>
            <ConfigProvider direction="rtl">
                {!!actions?.length && (
                    <>
                        {actions.map(({onClick, text, disabled, icon, color, loading, className}, index) => {
                            return (
                                <StyledButton
                                    key={index}
                                    className={classnames("order-actions-btn", color, className)}
                                    onClick={onClick}
                                    disabled={!!disabled}
                                    icon={icon}>
                                    {text}
                                    {!!loading && <Spin />}
                                </StyledButton>
                            );
                        })}
                    </>
                )}
            </ConfigProvider>
        </div>
    );
};
