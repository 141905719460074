import React, {useCallback, useEffect, useState} from "react";
import classnames from "classnames";
import {isFinite} from "lodash";
import {FormInputV2} from "../../components/form";

export const DaysHoursInput = ({time, setTime}) => {
    const [days, setDays] = useState(isFinite(time) ? Math.floor(time / 24) : null);
    const [hours, setHours] = useState(isFinite(time) ? time % 24 : null);
    const [hourError, setHourError] = useState(false);

    useEffect(() => {
        if (isFinite(days) || isFinite(hours)) {
            const formattedHour = getNewTimeValue(hours);
            setTime((days || 0) * 24 + (formattedHour ?? 0));
        } else {
            setTime(null);
        }
    }, [days, hours]);

    const getNewTimeValue = useCallback(
        inputString => (!inputString || isNaN(Number(inputString)) ? null : Number(inputString)),
        []
    );

    return (
        <>
            <FormInputV2
                suffix="ימים"
                id="days-hours-input-days-input"
                className="days-hours-input-days-input"
                onChange={evt => setDays(getNewTimeValue(evt.target.value))}
                value={days}
                onWheel={e => e.target.blur()}
            />
            <FormInputV2
                suffix="שעות"
                id="days-hours-input-hours-input"
                className={classnames("days-hours-input-days-input", {"days-hours-input-days-input-error": hourError})}
                onChange={evt => {
                    const newValue = evt.target.value;
                    setHourError(newValue.length && getNewTimeValue(newValue) === null);
                    setHours(newValue);
                }}
                value={hours}
                onWheel={e => e.target.blur()}
            />
            {hourError ? <div>התקבל פלט שגוי, המידע לא ישמר</div> : null}
        </>
    );
};
