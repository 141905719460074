import React, {useCallback, useContext, useMemo} from "react";
import {ConfigProvider} from "antd";
import {ProviderContext} from "../ProviderContext";
import {MarketplaceSearchResults} from "../../marketplace/MarketplaceSearchResults/MarketplaceSearchResults.jsx";
import {CategorySearchSorterType} from "../../marketplace/MarketplaceUtils";
import "./provider-products.css";
import {partialRight} from "lodash";

export const ProviderProducts = ({history}) => {
    const {providerDetails} = useContext(ProviderContext);

    const transformProviderServices = useMemo(
        () =>
            providerDetails?.services
                .filter(product => product.isActive)
                .map(product => {
                    const {services, ...restProviderDetails} = providerDetails;
                    return {
                        provider: restProviderDetails,
                        service: product
                    };
                }),
        [providerDetails?.services]
    );

    const onContentClick = useCallback(
        (content, newTab = false) => {
            if (newTab) {
                window.open(`/dashboard/products/${content.service.productId}`, "_blank");
            } else {
                history.push(`/dashboard/products/${content.service.productId}`);
            }
        },
        [history]
    );

    return (
        <div className="provider-products">
            <div className="provider-products-content">
                <div className="provider-products-title">
                    שלום {providerDetails?.contactFirstName ?? "ספק יקר"}, להלן המוצרים הפעילים שלכם
                </div>
                <div className="provider-products-text">
                    פה תוכלו לצפות במוצרים כפי שהם מוצגים ללקוחות, כולל תהליך ההזמנה המוצג להם במעמד הרכישה.
                </div>
                <div className="provider-products-text">
                    במידה וברצונכם לבצע תיקונים, התאמות, תוספות או להקים מוצרים חדשים, אנא תפנו לצוות well-b ואנחנו
                    נסייע בשמחה :)
                </div>
            </div>

            {providerDetails?.services && (
                <ConfigProvider direction="ltr">
                    <MarketplaceSearchResults
                        onContentClick={onContentClick}
                        onNewTab={partialRight(onContentClick, true)}
                        searchedContent={transformProviderServices}
                        searchSorterType={CategorySearchSorterType.BestSeller}
                        loadingSearchedContent={false}
                        showSearchAmount={false}
                        showLikeButton={false}
                    />
                </ConfigProvider>
            )}
        </div>
    );
};
