import {CloseIcon} from "../../icons";
import {message, Modal} from "antd";
import React, {useContext, useEffect, useState} from "react";
import moment from "moment-timezone";
import {AppContext} from "../../AppContext";
import {EventInfoTitleV2} from "./EventInfoTitleV2";
import {EventInfoCardV2} from "./EventInfoCardV2";
import {EventInfoEditV2} from "./edit/EventInfoEditV2";
import {isHostedByProvider} from "./EventTransformer";
import {HttpClient} from "../../http/HttpClient";
import {GoogleAnalytics} from "../../GoogleAnalytics";

const ModalContent = ({
    event,
    dateMom,
    currentStart,
    onClose,
    onEventUpdate,
    onBook,
    onDelete,
    deleting,
    showEditView,
    onEdit,
    onNewCustomEvent,
    onDismiss,
    onSendEvent,
    onCalendarInvite,
    openActivityDetails
}) => {
    const [providerInfo, setProviderInfo] = useState(null);

    const {me} = useContext(AppContext);

    useEffect(() => {
        if (event.sessionType === "special") {
            GoogleAnalytics.event("Special Event", "Open", event.sessionName);
        }

        GoogleAnalytics.trackCalendarEvent(event, "View", me.branch.name);

        if (isHostedByProvider(event) && !providerInfo) {
            Promise.resolve().then(async () => {
                try {
                    const {providerId, eventId} = event;
                    const provider = await HttpClient.get(`/api/providers/${providerId}?eventId=${eventId}`);
                    setProviderInfo(provider);
                } catch (e) {
                    message.error("Something went wrong, please try again later.", 5);
                }
            });
        }
    }, []);

    return event.eventId ? (
        showEditView ? (
            <EventInfoEditV2
                eventInfo={event}
                currentStart={currentStart}
                providerInfo={providerInfo}
                onClose={onClose}
                onEventUpdate={onEventUpdate}
            />
        ) : (
            <EventInfoCardV2
                onClose={onClose}
                eventInfo={event}
                providerInfo={providerInfo}
                dateMom={dateMom}
                onBook={onBook}
                onEdit={onEventUpdate ? onEdit : null}
                onDelete={onDelete}
                deleting={deleting}
                onNewCustomEvent={onNewCustomEvent}
                onDismiss={onDismiss}
                onSendEvent={onSendEvent}
                onCalendarInvite={onCalendarInvite}
                openActivityDetails={openActivityDetails}
            />
        )
    ) : (
        <div style={{minHeight: 400}} />
    );
};

export const EventInfoModalV2 = ({
    event,
    currentStart,
    currentEnd,
    onEventUpdate,
    onClose,
    visible,
    onBook,
    onDelete,
    deleting,
    onNewCustomEvent,
    onDismiss,
    onSendEvent,
    onCalendarInvite,
    openActivityDetails
}) => {
    const {theme} = useContext(AppContext);
    const [showEditView, setShowEditView] = useState(false);

    const toDateMom = () => {
        return currentStart ? moment(currentStart) : null;
    };

    const dateMom = toDateMom();

    return (
        <Modal
            className={`wb-weekly-edit-modal ${showEditView ? "edit" : "view"}`}
            style={{marginBottom: 50, marginTop: 5}}
            bodyStyle={{padding: 0, width: "100%", borderRadius: 10}}
            centered={true}
            closable={true}
            closeIcon={<CloseIcon fill={theme.textSecondary || "white"} />}
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose(event)}
            title={<EventInfoTitleV2 dateMom={dateMom} />}
            footer={null}>
            {visible ? (
                <ModalContent
                    event={event}
                    dateMom={dateMom}
                    currentStart={currentStart}
                    onClose={onClose}
                    onEventUpdate={onEventUpdate}
                    onBook={onBook}
                    onDelete={onDelete}
                    deleting={deleting}
                    showEditView={showEditView}
                    onEdit={() => setShowEditView(true)}
                    onNewCustomEvent={onNewCustomEvent}
                    onDismiss={onDismiss}
                    onSendEvent={onSendEvent}
                    onCalendarInvite={onCalendarInvite}
                    openActivityDetails={openActivityDetails}
                />
            ) : null}
        </Modal>
    );
};
