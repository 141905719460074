import React, {useEffect, useState} from "react";
import {Form, Modal, Select} from "antd";
import {ContinuableForm} from "./ContinuableForm";
import {StepperDropdownInput} from "./StepperDropdownInput";
import {ActivityTypes, ActivityThemes, ActivityGroupSize, ActivityLocation, ActivityCities} from "../wizardConsts";
import {AutoCompleteAddressFormInput} from "../../utils/AutoCompleteAddress";
import {SearchableSelect} from "../../components/SearchableSelect";
import _ from "lodash";
import {StepperInput} from "./StepperInput";
import {StepperParagraphInput} from "./StepperParagraphInput";
import {languages} from "../../data/languages";
import {DurationInput} from "../../components/DurationInput";
import {csc} from "../../csc/CountyStateCityClient";
import {CloseIcon} from "../../icons";
import {StepperButton} from "./StepperButton";
import {CoverPhotoHandler} from "./CoverPhotoHandler";

const {Option} = Select;

const modalOpeningSteps = [
    "type",
    "themes",
    "groupSize",
    "duration",
    "name",
    "per",
    "details",
    "languages",
    "location",
    "address",
    "cities"
];

export const CityMultiSelect = ({countryId, onCitiesSelect, onCitiesDeselect}) => {
    const cities = csc.allCities(countryId);
    return (
        <SearchableSelect
            mode="multiple"
            onDeselect={cityId => onCitiesDeselect(csc.city(cityId))}
            onSelect={cityId => onCitiesSelect(csc.city(cityId))}
            className="wb-profession-selection"
            placeholder="Choose as many as you want">
            {cities.map(cityInfo => (
                <Option key={cityInfo.cityId} value={cityInfo.cityId}>
                    {cityInfo.cityName}
                </Option>
            ))}
        </SearchableSelect>
    );
};

export const GroupActivityModal = ({onSetupGroupActivityFinish}) => {
    const [formValues, setFormValues] = useState({});
    const [selectedThemes, setSelectedThemes] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [coverPhotoModalVisible, setCoverPhotoModalVisible] = useState(false);

    const onThemesSelect = value => {
        const newThemesSelected = selectedThemes.concat([value]);
        setSelectedThemes(newThemesSelected);
        onActivityDropdownFieldChange("themes", newThemesSelected);
    };

    const onThemesDeselect = value => {
        const newThemesSelected = selectedThemes.filter(pro => pro !== value);
        setSelectedThemes(newThemesSelected);
        onActivityDropdownFieldChange("themes", newThemesSelected);
    };

    const onLocationSelect = value => {
        const newLocationSelected = selectedLocation.concat([value]);
        setSelectedLocation(newLocationSelected);
        onActivityDropdownFieldChange("location", newLocationSelected);
    };

    const onLocationDeselect = value => {
        const newLocationSelected = selectedLocation.filter(loc => loc !== value);
        setSelectedLocation(newLocationSelected);
        onActivityDropdownFieldChange("location", newLocationSelected);
    };

    const onCitiesSelect = value => {
        const newCitiesSelected = selectedCities.concat([value]);
        setSelectedCities(newCitiesSelected);
        onActivityDropdownFieldChange("cities", newCitiesSelected);
    };

    const onCitiesDeselect = value => {
        const newCitiesSelected = selectedCities.filter(loc => loc !== value);
        setSelectedCities(newCitiesSelected);
        onActivityDropdownFieldChange("cities", newCitiesSelected);
    };

    const onLanguagesSelect = value => {
        const newLangsSelected = selectedLanguages.concat([value]);
        setSelectedLanguages(newLangsSelected);
        onActivityDropdownFieldChange("languages", newLangsSelected);
    };

    const onLanguagesDeselect = value => {
        const newLangsSelected = selectedLanguages.filter(lang => lang !== value);
        setSelectedLanguages(newLangsSelected);
        onActivityDropdownFieldChange("languages", newLangsSelected);
    };

    const onActivityDropdownFieldChange = (field, value) => {
        setFormValues({
            ...formValues,
            [field]: value
        });
    };

    const isFieldHidden = field => {
        const indexOfFieldBefore = _.indexOf(modalOpeningSteps, field) - 1;
        return typeof formValues[modalOpeningSteps[indexOfFieldBefore]] === "undefined";
    };

    const locationChosen = loc => {
        return selectedLocation.includes(loc);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const closeCoverPhotoModal = () => {
        setCoverPhotoModalVisible(false);
    };

    const onCoverPhotoUploadFinish = () => {
        closeCoverPhotoModal();
        onSetupGroupActivityFinish(formValues);
    };

    const onCoverPhotoUpload = (imageUrl, imageId) => {
        setFormValues({
            ...formValues,
            imageId: imageId,
            imageUrl: imageUrl
        });
    };

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
            <div className="wb-provider-modal-title">Setup your group activity</div>
            <ContinuableForm
                className={"activityGroupForm"}
                style={{marginTop: 10}}
                canContinueColor="#ED7FA6"
                onFinish={vals => {
                    setFormValues(vals);
                    setCoverPhotoModalVisible(true);
                }}
                fields={Object.keys(formValues).map(key => {
                    return {
                        name: [key],
                        value: formValues[key]
                    };
                })}>
                <div
                    style={{
                        display: "flex",
                        width: "500px",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginLeft: "40px"
                    }}>
                    <div className="wb-provider-activity-modal-titles">What type of activity will it be?</div>
                    <Form.Item
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: "Please select the type of activity."
                            }
                        ]}>
                        {/*<StepperDropdownInput*/}
                        {/*    style={{width: "450px"}}*/}
                        {/*    value={formValues.type}*/}
                        {/*    placeholder="Choose one"*/}
                        {/*    menuValues={ActivityTypes}*/}
                        {/*    onChange={value => onActivityDropdownFieldChange("type", value)}*/}
                        {/*/>*/}
                    </Form.Item>
                </div>
                {!isFieldHidden("themes") ? (
                    <div
                        style={{
                            display: "flex",
                            width: "438px",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "40px"
                        }}>
                        <div className="wb-provider-activity-modal-titles">
                            What are the primary themes for your activity?
                        </div>
                        <Form.Item
                            name="themes"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select the themes of your activity."
                                },
                                {
                                    validator: (_, value) => {
                                        if (Array.isArray(value) && value.length > 3) {
                                            return Promise.reject("Maximum 3 themes allowed.");
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ]}>
                            <SearchableSelect
                                mode="multiple"
                                onDeselect={value => onThemesDeselect(value)}
                                onSelect={value => onThemesSelect(value)}
                                className="wb-profession-selection"
                                placeholder="Choose up to 3">
                                {ActivityThemes.map(name => (
                                    <Option key={name} value={name}>
                                        {name}
                                    </Option>
                                ))}
                            </SearchableSelect>
                        </Form.Item>
                    </div>
                ) : null}
                {!isFieldHidden("groupSize") ? (
                    <div
                        style={{
                            display: "flex",
                            width: "500px",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "40px"
                        }}>
                        <div className="wb-provider-activity-modal-titles">What is the maximum group size?</div>
                        <Form.Item
                            name="groupSize"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select the maximum group size."
                                }
                            ]}>
                            {/*<StepperDropdownInput*/}
                            {/*    style={{width: "450px"}}*/}
                            {/*    value={formValues.groupSize}*/}
                            {/*    placeholder="Choose a number of participants"*/}
                            {/*    menuValues={ActivityGroupSize}*/}
                            {/*    onChange={value => onActivityDropdownFieldChange("groupSize", value)}*/}
                            {/*/>*/}
                        </Form.Item>
                    </div>
                ) : null}
                {!isFieldHidden("duration") ? (
                    <div
                        style={{
                            display: "flex",
                            width: "500px",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "40px"
                        }}>
                        <div className="wb-provider-activity-modal-titles">How long is the activity?</div>
                        <Form.Item
                            name="duration"
                            rules={[
                                {
                                    required: true,
                                    validateTrigger: "onSubmit",
                                    validator: (_, value) => {
                                        return value ? Promise.resolve() : Promise.reject("Please enter duration.");
                                    }
                                }
                            ]}>
                            <DurationInput
                                selectorStyle={{width: "120px"}}
                                style={{width: "438px"}}
                                onChange={value => onActivityDropdownFieldChange("duration", value)}
                            />
                        </Form.Item>
                    </div>
                ) : null}
                {!isFieldHidden("name") ? (
                    <div
                        style={{
                            display: "flex",
                            width: "500px",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "40px"
                        }}>
                        <div className="wb-provider-activity-modal-titles">Name your activity</div>
                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select a name for the activity."
                                }
                            ]}>
                            <StepperInput
                                style={{width: "438px"}}
                                placeholder="Make it short, descriptive and exciting"
                            />
                        </Form.Item>
                    </div>
                ) : null}
                {!isFieldHidden("name") ? (
                    <div
                        style={{
                            display: "flex",
                            width: "500px",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "40px"
                        }}>
                        <div className="wb-provider-activity-modal-titles">How much do you charge?</div>
                        <div
                            style={{
                                display: "flex",
                                width: "500px",
                                justifyContent: "flex-start",
                                flexDirection: "row"
                            }}>
                            <Form.Item
                                name="price"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select price."
                                    }
                                ]}>
                                <StepperInput style={{width: "200px", type: "number"}} placeholder="Price" />
                            </Form.Item>
                            <Form.Item
                                name="currency"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select currency."
                                    }
                                ]}>
                                {/*<StepperDropdownInput*/}
                                {/*    style={{width: "100px"}}*/}
                                {/*    value={formValues.currency}*/}
                                {/*    menuValues={["NIS", "$"]}*/}
                                {/*    placeholder="NIS"*/}
                                {/*    onChange={value => onActivityDropdownFieldChange("currency", value)}*/}
                                {/*/>*/}
                            </Form.Item>
                            <Form.Item
                                name="per"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select a name for the activity."
                                    }
                                ]}>
                                {/*<StepperDropdownInput*/}
                                {/*    style={{width: "165px", right: "14px"}}*/}
                                {/*    value={formValues.per}*/}
                                {/*    menuValues={["PER PERSON", "PER GROUP"]}*/}
                                {/*    placeholder="PER GROUP"*/}
                                {/*    onChange={value => onActivityDropdownFieldChange("per", value)}*/}
                                {/*/>*/}
                            </Form.Item>
                        </div>
                    </div>
                ) : null}
                {!isFieldHidden("details") ? (
                    <div
                        style={{
                            display: "flex",
                            width: "438px",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "40px"
                        }}>
                        <div className="wb-provider-activity-modal-titles">
                            Please describe your activity in more detail
                        </div>
                        <Form.Item
                            name="details"
                            rules={[
                                {
                                    required: true,
                                    message: "Please details about your activity."
                                }
                            ]}>
                            <StepperParagraphInput
                                placeholder="What are you hosting? Be specific!"
                                maxLength={400}
                                autoSize={{maxRows: 8, minRows: 8}}
                            />
                        </Form.Item>
                    </div>
                ) : null}
                {!isFieldHidden("details") ? (
                    <div
                        style={{
                            display: "flex",
                            width: "438px",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "40px"
                        }}>
                        <div className="wb-provider-activity-modal-titles">
                            In what languages can you host this activity?
                        </div>
                        <Form.Item
                            name="languages"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select languages available for your activity."
                                }
                            ]}>
                            <SearchableSelect
                                mode="multiple"
                                onDeselect={value => onLanguagesDeselect(value)}
                                onSelect={value => onLanguagesSelect(value)}
                                className="wb-profession-selection"
                                placeholder="Be as flexible as possible">
                                {languages.map(lang => (
                                    <Option key={lang.englishName} value={lang.englishName}>
                                        {lang.englishName}
                                    </Option>
                                ))}
                            </SearchableSelect>
                        </Form.Item>
                    </div>
                ) : null}
                {!isFieldHidden("location") ? (
                    <div
                        style={{
                            display: "flex",
                            width: "438px",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "40px"
                        }}>
                        <div className="wb-provider-activity-modal-titles">
                            Where are you willing to host this activity?
                        </div>
                        <Form.Item
                            name="location"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select location for your activity."
                                }
                            ]}>
                            <SearchableSelect
                                mode="multiple"
                                onDeselect={value => onLocationDeselect(value)}
                                onSelect={value => onLocationSelect(value)}
                                className="wb-profession-selection"
                                placeholder="Be as flexible as possible">
                                {ActivityLocation.map(({value, title}) => (
                                    <Option key={value} value={value}>
                                        {title}
                                    </Option>
                                ))}
                            </SearchableSelect>
                        </Form.Item>
                    </div>
                ) : null}
                {!isFieldHidden("address") && locationChosen("offsite") ? (
                    <div
                        style={{
                            display: "flex",
                            width: "500px",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "40px"
                        }}>
                        <div className="wb-provider-activity-modal-titles">
                            What is the address of this offsite location?
                        </div>
                        <Form.Item
                            name="address"
                            style={{width: "438px"}}
                            valuePropName="searchText"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select an address."
                                }
                            ]}>
                            <AutoCompleteAddressFormInput />
                        </Form.Item>
                    </div>
                ) : null}
                {!isFieldHidden("address") && locationChosen("office") ? (
                    <div
                        style={{
                            display: "flex",
                            width: "438px",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "40px"
                        }}>
                        <div className="wb-provider-activity-modal-titles">
                            To which cities are you willing to commute?
                        </div>
                        <Form.Item name="cities" rules={[{required: true, message: "Please select city."}]}>
                            <SearchableSelect
                                mode="multiple"
                                onDeselect={value => onCitiesDeselect(value)}
                                onSelect={value => onCitiesSelect(value)}
                                className="wb-profession-selection"
                                placeholder="Be as flexible as possible">
                                {ActivityCities.map(location => (
                                    <Option key={location} value={location}>
                                        {location}
                                    </Option>
                                ))}
                            </SearchableSelect>
                        </Form.Item>
                    </div>
                ) : null}
            </ContinuableForm>
            <hr
                style={{
                    backgroundColor: "#ED7FA6",
                    height: 8,
                    borderTopRightRadius: 25,
                    borderBottomRightRadius: 25,
                    width: "100%",
                    maxWidth: `${100}%`,
                    position: "absolute",
                    margin: 0,
                    border: "none",
                    bottom: -3,
                    left: 0
                }}
            />
            <Modal
                className="wb-modal-radius"
                destroyOnClose={true}
                closable={true}
                maskClosable={false}
                closeIcon={<CloseIcon style={{fill: "#767F90"}} />}
                title={null}
                footer={null}
                onCancel={closeCoverPhotoModal}
                open={coverPhotoModalVisible}>
                <div>
                    <div className="wb-provider-wizard-upload-photo-title">Upload a cover photo for your menu</div>
                    <CoverPhotoHandler
                        imageContainerStyle={{
                            minHeight: 240,
                            maxHeight: 240,
                            minWidth: 460,
                            maxWidth: 460,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px dashed #E8EDF5",
                            backgroundColor: "#FCFDFE",
                            borderRadius: 20
                        }}
                        placeholder={"Upload Photo"}
                        onPhotoChange={(url, id) => onCoverPhotoUpload(url, id)}
                    />
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <StepperButton
                            style={{marginTop: 35}}
                            disabled={typeof formValues.imageUrl === "undefined" || formValues.imageUrl === null}
                            canContinueColor={"#ED7FA6"}
                            onClick={onCoverPhotoUploadFinish}>
                            Save
                        </StepperButton>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
