import React from "react";
import {Route} from "react-router";
import {isCompanySubAdmin} from "../center/EXCenterHelper";
import {IaiPackagesMarketplace} from "./CrossServicePage/IaiPackagesMarketplace";

export const SpecialRoutesOverride = ({company, me}) => {
    return [
        // IAI
        isCompanySubAdmin(me) && company?.companyId === "18THFEwNBv" ? (
            <Route key="IAI" exact from="/dashboard/services/packages/providersV2" component={IaiPackagesMarketplace} />
        ) : null
    ];
};
