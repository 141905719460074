import React, {useCallback, useEffect, useMemo, useState, Fragment} from "react";
import {message, Spin, Upload} from "antd";
import {DownloadOutlined, UploadOutlined, UserOutlined} from "@ant-design/icons";
import {downloadTemplate, validateExcel} from "./OrderEmployeeExcelUtils";
import {OrderEmployeSingleInfoModal} from "./OrderEmployeSingleInfoModal";
import {GenericModal} from "../../components";
import {Images} from "../../images/Images";
import {TrashIcon} from "../../icons";
import {EventBus} from "../../bus/EventBus";
import {loadExcel} from "../../utils/excelUtils.js";
import {downloadFromUrl} from "../../utils/downloadFromUrl";
import {NOOP} from "../../utils/NOOP";
import "../marketplace.css";

const {excelAddressModalCover} = Images;
export const OrderEmployeeInput = ({onValueChange, employeesExcel, singleEmployeeDetails, log = NOOP}) => {
    const [numberOfExcelRows, setNumberOfExcelRows] = useState(0);
    const [excelRows, setExcelRows] = useState(null);
    const [innerEmployeeExcel, setInnerEmployeeExcel] = useState(employeesExcel);
    const [innerSingleEmployeeDetails, setInnerSingleEmployeeDetails] = useState(singleEmployeeDetails);
    const [uploadExcelForHomeDeliveryVisibility, setUploadExcelForHomeDeliveryVisibility] = useState(false);
    const [inputSingleEmployeeForHomeDeliveryVisibility, setInputSingleEmployeeForHomeDeliveryVisibility] =
        useState(false);
    const [loadingExcel, setLoadingExcel] = useState(false);

    const excelUploadProps = useMemo(
        () => ({
            action: "/api/upload",
            name: "file",
            accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            showUploadList: false,
            withCredentials: true,
            onChange: async info => {
                if (info.file.status === "done") {
                    setLoadingExcel(false);
                    const {imageId, imageUrl} = info.file.response;
                    const fileName = info.file.name;
                    setInnerEmployeeExcel({fileUrl: imageUrl, fileId: imageId, fileName});
                    setUploadExcelForHomeDeliveryVisibility(false);
                    message.success(`קובץ הועלה בהצלחה`, 4);
                } else if (info.file.status === "error") {
                    setLoadingExcel(false);
                    EventBus.triggerError(
                        "server-error",
                        {
                            content: {
                                title: "Upload Failed!",
                                hideSubTitle: true,
                                description: "הייתה בעיה עם העלאת רשימת מקבלי המתנות."
                            }
                        },
                        info.file.response.error || `${info.file.name} file upload failed.`
                    );
                } else {
                    setLoadingExcel(true);
                }
            },
            beforeUpload: async file => {
                return new Promise(async (resolve, reject) => {
                    const fileReader = new FileReader();
                    fileReader.onload = async data => {
                        const arrayBuffer = data.target.result;
                        const workbook = await loadExcel(arrayBuffer, file.type);
                        const {rowsCount, error, rows} = await validateExcel(workbook);

                        if (!error.length) {
                            setNumberOfExcelRows(rowsCount);
                            setExcelRows(rows);
                            return resolve();
                        }

                        log("User Had An Error With Validating Excel");

                        EventBus.triggerError("server-error", {
                            content: {
                                title: "שגיאה בטעינת אקסל",
                                description: error,
                                hideSteps: true
                            }
                        });

                        return reject();
                    };

                    fileReader.readAsArrayBuffer(file);
                });
            }
        }),
        []
    );

    const updateDeliveryMethod = useCallback(
        (chosenDeliveryMethod = null) => {
            if (!innerSingleEmployeeDetails && !innerEmployeeExcel) {
                onValueChange("employeesExcel", null);
                onValueChange("singleEmployeeDetails", null);
                return;
            }

            if (!innerSingleEmployeeDetails || chosenDeliveryMethod === "excel") {
                onValueChange("employeesExcel", innerEmployeeExcel);
                onValueChange("singleEmployeeDetails", null);
            } else if (!innerEmployeeExcel || chosenDeliveryMethod === "singleEmployee") {
                onValueChange("employeesExcel", null);
                onValueChange("singleEmployeeDetails", innerSingleEmployeeDetails);
            }
        },
        [innerEmployeeExcel, innerSingleEmployeeDetails]
    );

    useEffect(() => {
        if (innerEmployeeExcel?.fileId) {
            setInnerEmployeeExcel(prev => ({
                ...prev,
                rowCount: numberOfExcelRows,
                rows: excelRows
            }));
        }
    }, [setInnerEmployeeExcel, innerEmployeeExcel?.fileId, numberOfExcelRows, excelRows]);

    useEffect(() => {
        updateDeliveryMethod();
    }, [updateDeliveryMethod]);

    const openEmployeesExcel = useCallback(async () => {
        if (!innerEmployeeExcel) {
            return;
        }

        await downloadFromUrl(innerEmployeeExcel.fileUrl, innerEmployeeExcel.fileName);
    }, [innerEmployeeExcel]);

    return (
        <>
            <GenericModal
                visible={uploadExcelForHomeDeliveryVisibility}
                image={excelAddressModalCover}
                onClose={() => setUploadExcelForHomeDeliveryVisibility(false)}
                className={"order-location-input-upload-excel-modal"}
                options={[
                    {
                        id: "uploadExcelForHomeDelivery",
                        text: (
                            <Upload {...excelUploadProps}>
                                <span className="order-location-input-load-excel-title">
                                    {loadingExcel ? <Spin /> : <UploadOutlined style={{marginLeft: "5px"}} />}
                                    טעינת רשימת מקבלים
                                </span>
                            </Upload>
                        )
                    }
                ]}
                title="הפצה למס׳ מקבלים עם קובץ אקסל 📗"
                text={[
                    "אנא תורידו את הפורמט המצורף, תמלאו את המידע לפי העמודות",
                    <span>
                        {" "}
                        {"הרלוונטיות"} <u>{"בעברית בלבד"}</u> {" ותטענו את הרשימה למערכת"}{" "}
                    </span>,
                    <div style={{cursor: "pointer", direction: "ltr", fontWeight: "bold"}} onClick={downloadTemplate}>
                        הורדת פורמט אקסל <DownloadOutlined style={{color: "#243446"}} />
                    </div>
                ]}
            />
            <OrderEmployeSingleInfoModal
                content={innerSingleEmployeeDetails}
                visible={inputSingleEmployeeForHomeDeliveryVisibility}
                onClose={() => setInputSingleEmployeeForHomeDeliveryVisibility(false)}
                onSave={values => {
                    setInnerSingleEmployeeDetails(values);
                    setInputSingleEmployeeForHomeDeliveryVisibility(false);

                    onValueChange("employeesExcel", null);
                    onValueChange("singleEmployeeDetails", values);
                }}
            />

            <div>
                <div
                    className={`order-location-input-home-delivery-option-row ${employeesExcel ? "selected-row" : ""}`}
                    onClick={() => updateDeliveryMethod("excel")}>
                    <span>הפצה למס׳ מקבלים עם קובץ אקסל</span>
                    {innerEmployeeExcel ? (
                        <span className="order-location-input-home-delivery-has-value">
                            <span onClick={openEmployeesExcel}>{innerEmployeeExcel.fileName}</span>
                            <TrashIcon onClick={() => setInnerEmployeeExcel(null)} />
                        </span>
                    ) : (
                        <span onClick={() => setUploadExcelForHomeDeliveryVisibility(true)}>
                            <UploadOutlined />
                            טעינת רשימת מקבלים
                        </span>
                    )}
                </div>
                <div
                    className={`order-location-input-home-delivery-option-row ${
                        singleEmployeeDetails ? "selected-row" : ""
                    }`}
                    onClick={() => updateDeliveryMethod("singleEmployee")}>
                    <span>שילוח למקבל יחיד</span>
                    {innerSingleEmployeeDetails ? (
                        <span className="order-location-input-home-delivery-has-value">
                            <span onClick={() => setInputSingleEmployeeForHomeDeliveryVisibility(true)}>
                                {innerSingleEmployeeDetails.name}
                            </span>
                            <TrashIcon onClick={() => setInnerSingleEmployeeDetails(null)} />
                        </span>
                    ) : (
                        <span onClick={() => setInputSingleEmployeeForHomeDeliveryVisibility(true)}>
                            <UserOutlined />
                            הזנת פרטי קשר
                        </span>
                    )}
                </div>
            </div>
        </>
    );
};
