import React from "react";
import {Modal, message} from "antd";
import {HttpClient} from "../http/HttpClient";
import moment from "moment-timezone";
import {AppContext} from "../AppContext";
import {withRouter} from "react-router";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {EmployeeBooking} from "../employee/EmployeeBooking";
import {dateFormat, timeFormat} from "../utils/DateFormat";
import {FutureEventsModal} from "./FutureEventsModal";
import {EventEditView} from "./EventEditView";
import {eventDateToUtcTimestamp} from "./eventDateToUtc";
import EventInfoFrom from "./EventInfoForm";
import {EventInfoModalV2} from "./info/EventInfoModalV2";
import {CloseIcon} from "../icons";

export const toDateString = (event, dateObj, format) => {
    try {
        return moment(dateObj).format(format || "MMMM Do, YYYY");
    } catch (e) {
        return "";
    }
};

export const toTimeString = (event, dateObj, format) => {
    try {
        const timeFormat = `${dateObj.getHours()}:${dateObj.getMinutes()}`;
        return moment(timeFormat, "H:m").format(format || "HH:mm A");
    } catch (e) {
        return "";
    }
};

class EventInfoModal extends React.Component {
    static contextType = AppContext;

    state = {
        eventId: null,
        saving: false,
        cancelling: false,
        showAppointmentFlow: false,
        changes: null,
        changedFields: [],
        showFutureEventsModal: false,
        showDeleteFutureEventsModal: false,
        extraDeleteMessage: null,
        editMode: false
    };

    async updateEvent({meetingLink, notes, address}, changedFields) {
        const updatableFieldNames = ["notes", "address", "meetingLink"];

        if (changedFields.some(fieldName => updatableFieldNames.some(fn => fieldName === fn))) {
            this.setState({
                changes: {
                    meetingLink,
                    notes,
                    address
                },
                showFutureEventsModal: true
            });
        } else {
            const {event, onClose} = this.props;
            this.setState({changes: null});
            onClose(event, "only");
        }
    }

    async saveChanges(values, applyType) {
        const {me} = this.context;

        const {onClose, event, currentStart} = this.props;

        const {meetingLink, notes, address} = values;

        const today = moment(dateFormat(currentStart), "YYYY-M-D").format("YYYY-MM-DD");

        this.setState({saving: true});
        try {
            let updatedEvent = event;
            if ([meetingLink, notes, address].some(val => typeof val !== "undefined")) {
                updatedEvent = await HttpClient.post(`/api/events/${event.eventId}?apply=${applyType}&date=${today}`, {
                    meetingLink,
                    notes,
                    address
                });
                if (me.type === "provider") {
                    GoogleAnalytics.event("Professional Schedule", "Session Update", updatedEvent.eventId);
                } else {
                    GoogleAnalytics.event("Company Schedule", "Session Update", updatedEvent.eventId);
                }
            }
            onClose(updatedEvent, applyType);
        } catch (e) {
            message.error(e.message, 3);
        }
        this.setState({saving: false});
    }

    closeFutureEventsModal() {
        this.setState({showFutureEventsModal: false});
    }

    async showDeleteFutureEventsModal() {
        const {event, currentStart} = this.props;

        let extraDeleteMessage =
            Array.isArray(event.registeredEmployees) && event.registeredEmployees.length > 0
                ? "Attention: your employees are registered to this session."
                : null;

        try {
            const appts = await HttpClient.get(`/api/events/${event.eventId}/appointments`);
            if (appts.length > 0) {
                const dateMom = moment(dateFormat(currentStart), "YYYY-M-D");
                const date = dateMom.format("YYYY-MM-DD");
                if (appts.some(appt => appt.date === date)) {
                    extraDeleteMessage = "Attention: your employees have booked appointments on this date.";
                } else if (appts.some(appt => moment(appt.date, "YYYY-MM-DD").isAfter(dateMom))) {
                    extraDeleteMessage = "Attention: your employees have booked appointments for this event.";
                }
            }
        } catch (e) {
            message.error("Failed to fetch employees appointments.", 3);
        }

        this.setState({showDeleteFutureEventsModal: true, extraDeleteMessage});
    }

    closeDeleteFutureEventsModal() {
        this.setState({showDeleteFutureEventsModal: false});
    }

    async deleteAppointment(appointment) {
        const {me} = this.context;
        await HttpClient.delete(`/api/events/${appointment.eventId}/appointments/${appointment.appointmentId}`);
        GoogleAnalytics.event("Employee Booking", "Delete Appointment", me.userId);
        appointment.status = "cancelled";
    }

    async cancelEvent(applyType) {
        const {onClose, event, currentStart} = this.props;

        this.setState({cancelling: true});
        try {
            if (event.type === "appointment") {
                await this.deleteAppointment(event);
                onClose(event);
            } else {
                const datetime = event.appointmentBased
                    ? moment(
                          `${dateFormat(currentStart)} ${timeFormat(new Date(event.dtstart))}`,
                          "YYYY-M-D H:m:s"
                      ).valueOf()
                    : eventDateToUtcTimestamp(currentStart);
                const updatedEvent = await HttpClient.delete(
                    `/api/events/${event.eventId}/cancel?apply=${applyType}&datetime=${datetime}`
                );
                onClose(updatedEvent);
                GoogleAnalytics.event("Company Schedule", "Cancel Session", event.eventId);
            }
        } catch (e) {
            message.error(e.message, 3);
        }
        this.setState({cancelling: false, showDeleteFutureEventsModal: false});
    }

    closeModal() {
        const {event, onClose} = this.props;

        this.setState({
            showAppointmentFlow: false,
            editMode: false,
            changes: null
        });
        onClose(event);
    }

    enterEditMode() {
        this.setState({editMode: true});
    }

    exitEditMode(updatedEvent, applyType) {
        const {onUpdateEvent} = this.props;
        if (applyType !== "no-change") {
            onUpdateEvent(updatedEvent, applyType);
        }
        this.setState({editMode: false});
        this.closeModal();
    }

    render() {
        const {me, theme} = this.context;

        const {
            event,
            currentStart,
            currentEnd,
            visible,
            onClose,
            onNewCustomEvent,
            onDismiss,
            onSendEvent,
            onCalendarInvite,
            openActivityDetails
        } = this.props;

        const {
            saving,
            cancelling,
            showAppointmentFlow,
            changes,
            showFutureEventsModal,
            showDeleteFutureEventsModal,
            extraDeleteMessage,
            editMode
        } = this.state;

        if (!event || !event.eventId) return null;

        return (
            <div onClick={e => e.stopPropagation()}>
                <FutureEventsModal
                    visible={showFutureEventsModal}
                    onClose={() => this.closeFutureEventsModal()}
                    loading={saving}
                    saveStyle={{backgroundColor: theme.primary, color: theme.textPrimary}}
                    option2Text="This and all other events"
                    onSave={async ({applyType}) => {
                        this.closeFutureEventsModal();
                        await this.saveChanges(changes, applyType);
                    }}
                />
                <FutureEventsModal
                    visible={showDeleteFutureEventsModal}
                    onClose={() => this.closeDeleteFutureEventsModal()}
                    loading={cancelling}
                    title={event.type === "appointment" ? "Delete appointment" : "Delete recurring event"}
                    extraMessage={extraDeleteMessage}
                    disclaimer={
                        extraDeleteMessage
                            ? "Don't worry, the relevant employees will be notified regarding the cancellation."
                            : null
                    }
                    saveStyle={{color: "white", backgroundColor: "#F06060"}}
                    saveText="Delete"
                    onSave={async ({applyType}) => {
                        await this.cancelEvent(applyType);
                    }}
                />
                {!showAppointmentFlow && !editMode && me.type !== "provider" ? (
                    <EventInfoModalV2
                        event={event}
                        currentStart={currentStart}
                        currentEnd={currentEnd}
                        onEventUpdate={(updatedEvent, applyType) => this.exitEditMode(updatedEvent, applyType)}
                        onClose={onClose}
                        visible={!!event.eventId && ["accepted", "confirmed"].some(status => event.status === status)}
                        onBook={() => this.setState({showAppointmentFlow: true})}
                        onDelete={async () => {
                            if (event.type === "appointment") {
                                await this.cancelEvent("only");
                            } else {
                                await this.showDeleteFutureEventsModal();
                            }
                        }}
                        deleting={cancelling}
                        onNewCustomEvent={onNewCustomEvent}
                        onDismiss={onDismiss}
                        onSendEvent={onSendEvent}
                        onCalendarInvite={onCalendarInvite}
                        openActivityDetails={openActivityDetails}
                    />
                ) : (
                    <Modal
                        maskClosable={!showAppointmentFlow}
                        className={showAppointmentFlow ? "wb-modal-booking-confirmation" : "wb-modal"}
                        closable={true}
                        destroyOnClose={true}
                        style={{top: editMode ? 20 : void 0}}
                        bodyStyle={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 0
                        }}
                        closeIcon={<CloseIcon fill="var(--secondary-color)" />}
                        onCancel={() => this.closeModal()}
                        open={visible}
                        footer={null}>
                        {showAppointmentFlow ? (
                            <EmployeeBooking
                                me={me}
                                event={event}
                                currentStart={currentStart}
                                currentEnd={currentEnd}
                                onConfirm={() => this.closeModal()}
                                onBack={() => this.setState({showAppointmentFlow: false})}
                                onClose={() => this.closeModal()}
                            />
                        ) : editMode ? (
                            <EventEditView
                                event={event}
                                currentStart={currentStart}
                                currentEnd={currentEnd}
                                onClose={(updatedEvent, applyType) => this.exitEditMode(updatedEvent, applyType)}
                            />
                        ) : event.eventId ? (
                            <EventInfoFrom
                                event={event}
                                currentStart={currentStart}
                                currentEnd={currentEnd}
                                onClose={() => this.closeModal()}
                                onBookAppointment={() => this.setState({showAppointmentFlow: true})}
                                onEventUpdate={async (values, changedFields) =>
                                    await this.updateEvent(values, changedFields)
                                }
                                onEditEnter={() => this.enterEditMode()}
                                onEventCancel={() => this.showDeleteFutureEventsModal()}
                                cancelling={cancelling}
                                saving={saving}
                            />
                        ) : null}
                    </Modal>
                )}
            </div>
        );
    }
}

export default withRouter(EventInfoModal);
