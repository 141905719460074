import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import {AppContext} from "../../AppContext";
import {UploadableCoverPhoto} from "../../components/UploadableCoverPhoto";
import {StyledButton} from "../../components/StyledButton";
import {PictureIcon} from "../../icons";
import {EXCenterContext} from "../EXCenterContext";
import {LoadingOutlined} from "@ant-design/icons";
import {message} from "antd";
import {HttpClient} from "../../http/HttpClient";
import {EventBus} from "../../bus/EventBus";
import {cloneDeep} from "lodash";
import {isMyBranchSelected} from "../branches/EXCenterBranches";
import {GoogleAnalytics} from "../../GoogleAnalytics";
import {SmartImageContainer} from "../../components/SmartImageContainer";

export const EXCenterSectionsPickerCard = ({sectionId, sectionName, coverPhoto}) => {
    const {me} = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const {originalDesignInfo, theme} = useContext(EXCenterContext);

    const updatePickerPhoto = async imageUrl => {
        setLoading(true);
        try {
            const updatedCompanyDesign = cloneDeep(originalDesignInfo);
            updatedCompanyDesign.pickerPhotos = updatedCompanyDesign.pickerPhotos || {};
            updatedCompanyDesign.pickerPhotos[sectionId] = imageUrl;
            await HttpClient.post("/api/website/settings", updatedCompanyDesign);
            EventBus.trigger("ex_center_design:update", updatedCompanyDesign);
            GoogleAnalytics.event("EX Center - Section Picker", "Upload Cover", sectionId);
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
        setLoading(false);
    };

    return (
        <Link to={`/center/sections/${sectionId}`}>
            <div
                className="wb-ss-card-margin"
                style={{
                    height: 360,
                    width: 360,
                    borderRadius: 10,
                    margin: 15,
                    backgroundColor: "white",
                    boxShadow: "0 10px 30px 0 rgba(22,29,37,0.1)",
                    overflow: "hidden",
                    cursor: "pointer"
                }}>
                {me.type === "employee" || !isMyBranchSelected(me) ? (
                    <SmartImageContainer
                        width={360}
                        height={300}
                        src={coverPhoto}
                        style={{
                            width: "100%",
                            cursor: "pointer"
                        }}
                    />
                ) : (
                    <UploadableCoverPhoto
                        imageUrl={coverPhoto}
                        coverStyle={{width: "100%", paddingBottom: "83%", borderRadius: 0}}
                        onChange={updatePickerPhoto}
                        showPlaceholder={true}
                        width={360}
                        height={300}
                        quality={100}
                        showUploadOnly={true}
                        uploadStyle={{
                            left: 5,
                            top: 5
                        }}
                        CustomUploadButton={({uploading}) => (
                            <StyledButton
                                className={`wb-center-picker-cover-upload${
                                    loading || uploading ? " wb-force-show" : ""
                                }`}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: 32,
                                    height: 32,
                                    position: "absolute",
                                    left: 10,
                                    top: 10,
                                    backgroundColor: "white",
                                    borderRadius: 4,
                                    border: "none",
                                    padding: 0
                                }}>
                                {uploading || loading ? (
                                    <LoadingOutlined style={{fontSize: 16, color: theme.textColor}} />
                                ) : (
                                    <PictureIcon style={{width: 20, height: 16}} />
                                )}
                            </StyledButton>
                        )}
                    />
                )}
                <div
                    style={{
                        height: 60,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: theme.textColor,
                        fontSize: 24,
                        fontWeight: 300,
                        cursor: "pointer"
                    }}>
                    {sectionName}
                </div>
            </div>
        </Link>
    );
};
