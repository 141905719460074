import React from "react";
import {ProviderServices} from "../provider/ProviderServices";
import {parse as qsParse} from "query-string";

export const AdminProviderServices = ({history, match, location}) => {
    const {providerId} = match.params;
    const {search} = location;
    const {fn} = qsParse(search);

    return <ProviderServices history={history} providerId={providerId} providerName={fn} isAdmin={true} />;
};
