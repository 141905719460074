import React from "react";
import {ConfigProvider} from "antd";
import {FormModal} from "../../../components";
import {NOOP} from "../../../utils/NOOP";

export const CompanyInvoiceManageModal = ({formFields, financeInfo, onUpdateForm, visible, onSave, onClose}) => {
    return (
        <ConfigProvider direction="rtl">
            <FormModal
                modalWidth={700}
                header="הגדרת חשבונית"
                subTitle={
                    <>
                        <div>
                            <div>אנא הגדירו את הפרטים הנכונים להפקת החשבוניות בחשבונכם.</div>
                            <div>
                                יש לשים 💙 כי על הפרטים להיות מדויקים ככל האפשר על מנת למנוע תקלות התחשבנות בהמשך.
                            </div>
                        </div>
                    </>
                }
                content={financeInfo}
                onUpdate={onUpdateForm}
                onClose={onClose}
                visible={visible}
                fields={formFields}
                onSave={NOOP}
                validationFunction={async values => {
                    try {
                        onSave(values);
                        return true;
                    } catch (e) {
                        return false;
                    }
                }}
            />
        </ConfigProvider>
    );
};
