import {Form, Modal, Input, message, Spin} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {CloseIcon} from "../icons";
import {PageTitleLabel} from "../components/PageTitle";
import {FormInput} from "../components/form";
import {StyledButton} from "../components/StyledButton";
import {HttpClient} from "../http/HttpClient";
import {CustomSelect} from "../components/CustomSelect";
import {EventBus} from "../bus/EventBus";
import {AppContext} from "../AppContext";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {PageLoader} from "../components/PageLoader";

const {Option} = CustomSelect;

export const BankInformationModal = ({open, bankInfo, onClose, loading, onBankInfoSave}) => {
    const {me} = useContext(AppContext);
    const [visible, setVisible] = useState(false);
    const [saving, setSaving] = useState(false);
    const [banks, setBanks] = useState({});
    const [form] = Form.useForm();

    if (typeof open !== "undefined" && visible !== open) {
        setVisible(open);
    }

    useEffect(() => {
        EventBus.on("bank_info_details:open", () => {
            setVisible(true);
        });

        // Promise.resolve().then(async () => {
        //     try {
        //         const banks = await HttpClient.get("/api/billing/banks");
        //         setBanks(banks);
        //     } catch (e) {
        //         message.error("Unfortunately we couldn't list all banks.", 3);
        //     }
        // });
    }, []);

    const saveBankInfo = async ({accountName, bankNumber, branchNumber, accountNumber, idNumber}) => {
        setSaving(true);

        const updateBankInfo = {
            client_name: accountName,
            bank: bankNumber,
            branch: branchNumber,
            account: accountNumber,
            vat_id: idNumber
        };

        if (onBankInfoSave) {
            await onBankInfoSave(updateBankInfo);
            form.resetFields();
        } else {
            try {
                const {hasBankInfo} = await HttpClient.post(`/api/billing/bank`, updateBankInfo);
                if (hasBankInfo) {
                    GoogleAnalytics.event("Billing", "Saved New Bank Info", me.type);
                    EventBus.trigger("me:update", {...me, premiumAccount: true});
                    closeMe();
                } else {
                    showErrorMessage();
                }
            } catch (e) {
                showErrorMessage();
            }
        }
        setSaving(false);
    };

    const showErrorMessage = () => {
        message.error("Unfortunately we couldn't save your bank information :(", 3);
    };

    const onlyNumbers = e => {
        if (
            e.key !== "ArrowRight" &&
            e.key !== "ArrowLeft" &&
            e.key !== "Delete" &&
            e.key !== "Backspace" &&
            isNaN(parseInt(e.key, 10))
        ) {
            e.preventDefault();
        }
    };

    const closeMe = () => {
        form.resetFields();
        onClose && onClose();
        setVisible(false);
    };

    return (
        <Modal
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={closeMe}
            footer={null}
            title={null}
            closable={true}
            closeIcon={<CloseIcon fill="#767F90" />}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <PageTitleLabel style={{fontSize: 20, fontWeight: 400, marginBottom: 30}}>
                    Bank account information
                </PageTitleLabel>
                {loading ? (
                    <PageLoader align="flex-start" top={80} height={250} />
                ) : (
                    <Form form={form} initialValues={bankInfo} onFinish={saveBankInfo}>
                        <Form.Item>
                            <Input.Group
                                compact={true}
                                style={{display: "flex", alignItems: "flex-start", marginBottom: 0}}>
                                <Form.Item
                                    name="accountName"
                                    style={{marginRight: 10, marginBottom: 0}}
                                    rules={[{required: true, message: "Please enter account owner."}]}>
                                    <FormInput placeholder="Account owner" />
                                </Form.Item>
                                <Form.Item
                                    style={{marginBottom: 0, width: "50%"}}
                                    name="bankNumber"
                                    rules={[{required: true, message: "Please select bank."}]}>
                                    <CustomSelect style={{width: "100%"}} placeholder="Bank">
                                        {Object.keys(banks).map(bankNumber => (
                                            <Option key={bankNumber} value={bankNumber}>
                                                {banks[bankNumber]}
                                            </Option>
                                        ))}
                                    </CustomSelect>
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                        <Form.Item
                            name="branchNumber"
                            rules={[{required: true, message: "Please enter branch number."}]}>
                            <FormInput onKeyDown={onlyNumbers} placeholder="Branch number" />
                        </Form.Item>
                        <Form.Item>
                            <Input.Group
                                compact={true}
                                style={{display: "flex", alignItems: "flex-start", marginBottom: 0}}>
                                <Form.Item
                                    name="accountNumber"
                                    style={{marginRight: 10, marginBottom: 0}}
                                    rules={[{required: true, message: "Please enter account number."}]}>
                                    <FormInput onKeyDown={onlyNumbers} placeholder="Account number" />
                                </Form.Item>
                                <Form.Item
                                    style={{marginBottom: 0}}
                                    name="idNumber"
                                    rules={[{required: true, message: "Please enter ID number."}]}>
                                    <FormInput onKeyDown={onlyNumbers} placeholder="ID number" />
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                        <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                            <Form.Item style={{marginBottom: 0}}>
                                <StyledButton
                                    onClick={() => form.submit()}
                                    loading={saving}
                                    style={{color: "white", backgroundColor: "#13C296", width: 145}}>
                                    Save info
                                </StyledButton>
                            </Form.Item>
                        </div>
                    </Form>
                )}
            </div>
        </Modal>
    );
};
