import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../../AppContext";
import {MarketplaceContext} from "../marketplaceContext";
import {useRequest} from "../../utils/hooks";
import {BesideCarouselArrow, MarketplaceSeparatorLine} from "../MarketplaceHelper";
import {ProductDetailsPanelKeys} from "../ProductDetailsPage/consts";
import {PricingCalculatorComponent} from "../PricingCalculator";
import {TopBarSection} from "../ProductDetailsPage/TopBarSection";
import {NOOP} from "../../utils/NOOP";
import {PriceTypes} from "../../admin/upload/PriceSelectorHelper";
import {BOOKING_ERROR_TO_PANEL, BOOKING_TO_PANEL_COLORS} from "../MarketplaceBooking/consts";
import {debounce, flatten, intersection, sumBy, uniq, uniqBy} from "lodash";
import {getDefaultPriceTypeArray, getProductMinPrice, typeToHebrewName} from "../MarketplaceUtils";
import {GoogleAnalytics} from "../../GoogleAnalytics";
import {ActivityLocation, ActivityLocationTypes} from "../../consts.js";
import {isCompanySubAdmin} from "../../center/EXCenterHelper";
import {getDeliveryArea} from "../MarketplaceBooking/utils";
import classnames from "classnames";
import cover_placeholder from "../../images/cover_placeholder.jpg";

import "../ProductDetailsPage/product-details-page.css";
import {ProgressiveImageLoader, WBCarousel} from "../../components";
import {GeneralDescriptionPanel} from "../ProductDetailsPage/GeneralDescriptionPanel";
import {Collapse} from "antd";
import {ProductTagsPanel} from "../ProductDetailsPage/ProductTagsPanel";
import {PopularQuestionsPanel} from "../ProductDetailsPage/PopularQuestionsPanel";
import {PreviouslyOrderedCompanies} from "../PreviouslyOrderedCompanies";
import {ProviderAboutSection} from "../ProductDetailsPage/ProviderAboutSection";
import {PackageInfoPanel} from "../ProductDetailsPage/PackageInfoPanel";
import {AdditionalInfoPanel} from "../ProductDetailsPage/AdditionalInfoPanel";
import {calculateProductBundlePrice} from "./utils";

export const ProductBundleDetailsPage = ({onBook = NOOP, anyQuestionsClicked = NOOP, log = NOOP}) => {
    const {me, company} = useContext(AppContext);
    const {
        product,
        providerDetails,
        productId,
        serviceId,
        setParticipants,
        setHighlightOrderPanel = NOOP,
        isProductBundle,
        productBundle
    } = useContext(MarketplaceContext);

    const [productSearchTags] = useRequest(
        "/api/searchTags/getProductSearchTags/byProductIds",
        "POST",
        {productIds: productBundle?.products.map(({productId}) => productId)},
        [productBundle],
        !!productBundle
    );

    const [fromPrice, setFromPrice] = useState(null);
    const [isDeliverableToOffice, setIsDeliverableToOffice] = useState(null);
    const [activeKey, setActiveKey] = useState([]);

    const calculatePrice = useMemo(
        () => calculateProductBundlePrice(productBundle?.products),
        [productBundle?.products]
    );

    const onBookRedirectCollapse = useCallback(redirectPanelId => {
        setHighlightOrderPanel({
            id: BOOKING_ERROR_TO_PANEL[redirectPanelId],
            color: BOOKING_TO_PANEL_COLORS.REDIRECT
        });
        onBook();
    }, []);

    const carouselProps = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        nextArrow: <BesideCarouselArrow direction={"next"} />,
        prevArrow: <BesideCarouselArrow direction={"prev"} />,
        accessibility: true,
        arrows: true,
        className: "wb-carousel"
    };

    const ProductPriceHeader = useCallback(
        ({companyOrdersDiscount, chosenPriceType}) => {
            const priceBeforeDiscount = calculatePrice;

            const priceAfterDiscount = companyOrdersDiscount
                ? priceBeforeDiscount - Math.floor(priceBeforeDiscount * (companyOrdersDiscount / 100))
                : priceBeforeDiscount;

            const shouldShowDiscountedPrice = companyOrdersDiscount && priceAfterDiscount !== priceBeforeDiscount;
            const title = chosenPriceType === PriceTypes.ByModular ? "חבילה מומלצת" : "החל מ-";

            return (
                <>
                    <div className="marketplace-category-item-details-price-container">
                        <span className="marketplace-category-item-details-price-title">
                            <span>{title}</span>
                            <span className={classnames({"line-through": shouldShowDiscountedPrice})}>
                                {priceBeforeDiscount?.toLocaleString(undefined, {maximumFractionDigits: 2})}₪
                            </span>
                        </span>

                        {shouldShowDiscountedPrice ? (
                            <span
                                className={classnames(
                                    "marketplace-category-item-details-price-title-discount",
                                    "marketplace-category-item-details-price-title",
                                    {"responsive-font-size": companyOrdersDiscount && priceAfterDiscount > 999}
                                )}>
                                {priceAfterDiscount?.toLocaleString(undefined, {maximumFractionDigits: 2})}₪
                            </span>
                        ) : null}

                        <span style={{color: "#8398A6", fontSize: "12px", margin: "9px 5px 0 0"}}>
                            / {typeToHebrewName("byPerson")} (כולל מע״מ)
                        </span>
                    </div>
                </>
            );
        },
        [calculatePrice, productBundle]
    );

    const onParticipantsChange = debounce(participants => {
        if (participants) {
            setParticipants(participants);
            log("Participants Number Set", {participantsNumber: participants});
        }
    }, 200);

    useEffect(() => {
        if (product) {
            const defaultPriceTypeArray = getDefaultPriceTypeArray(product);
            let fromPrice = null;
            defaultPriceTypeArray?.forEach(row => {
                const rowPrice = parseFloat(row.price ?? row.redeemableValue);
                if (!fromPrice || fromPrice > rowPrice) {
                    fromPrice = rowPrice;
                }
            });
            setFromPrice(fromPrice);
            onParticipantsChange(null);
            GoogleAnalytics.ecommerceEvent("view_item", product, serviceId);
        }
        window.scrollTo(0, 0);
    }, [product]);

    useEffect(() => {
        if (me && company && isProductBundle && productBundle) {
            checkDeliverable();
        }

        async function checkDeliverable() {
            const productLocations = intersection(flatten(productBundle.products.map(product => product?.location)));
            const isOfficeActivity = productLocations.includes(ActivityLocationTypes.office);

            if (isOfficeActivity) {
                const officeAddress = {
                    address: isCompanySubAdmin(me) ? me?.address : company?.address,
                    addressId: isCompanySubAdmin(me) ? me?.addressId : company?.addressId
                };

                const deliveryAreas = await Promise.all(
                    productBundle.products.map(async product => {
                        const foundProviderDetails = productBundle.providers.find(
                            provider => provider.providerId === product.providerId
                        );
                        const deliveryArea = await getDeliveryArea(officeAddress, product, foundProviderDetails, log);
                        return deliveryArea;
                    })
                );

                if (deliveryAreas.every(deliveryArea => Object.keys(deliveryArea ?? {}).length)) {
                    setIsDeliverableToOffice(true);
                }
            }
        }
    }, [productBundle, isProductBundle, me, log, company, isCompanySubAdmin]);

    const locations = useMemo(() => {
        const officeText = ActivityLocation[ActivityLocationTypes.office];
        const productsLocations = intersection(flatten(productBundle.products.map(product => product?.location)));

        if (!productsLocations || !productsLocations?.length) return [];

        return productsLocations
            .map(location => ActivityLocation[location])
            .filter(loc => loc !== officeText || isDeliverableToOffice);
    }, [productBundle?.products, isDeliverableToOffice]);

    useEffect(() => {
        setActiveKey(Object.values(ProductDetailsPanelKeys));
    }, []);

    return (
        <div className="product-details-page">
            <TopBarSection
                productName={productBundle?.name}
                locations={locations}
                fromPrice={fromPrice}
                calculatePrice={calculatePrice}
                anyQuestionsClicked={anyQuestionsClicked}
                ProductPriceHeader={ProductPriceHeader}
            />

            <div className="product-details-page-container">
                <div className="product-details-page-wrapper">
                    <div>
                        <div className="product-details-page-pricing-table-container">
                            <PricingCalculatorComponent
                                product={product}
                                onBook={() => {
                                    log("Go To Booking Clicked");
                                    setHighlightOrderPanel(null);
                                    onBook();
                                }}
                                onParticipantsChange={onParticipantsChange}
                                modularCalculatedPrice={calculatePrice}
                                PriceCalculatorHeader={ProductPriceHeader({
                                    companyOrdersDiscount: company?.ordersDiscount
                                })}
                                showModularImages={providerDetails?.showProviderSubProductsImages}
                            />
                            {/*{PricingCalculatorComponent(*/}
                            {/*    product,*/}
                            {/*    () => {*/}
                            {/*        log("Go To Booking Clicked");*/}
                            {/*        setHighlightOrderPanel(null);*/}
                            {/*        onBook();*/}
                            {/*    },*/}
                            {/*    onParticipantsChange,*/}
                            {/*    calculatePrice,*/}
                            {/*    ProductPriceHeader({*/}
                            {/*        companyOrdersDiscount: company?.ordersDiscount*/}
                            {/*    })*/}
                            {/*)}*/}
                            <div className="product-details-page-pricing-table-disclaimer">
                                ההזמנה והתמחור כפופים לאישור סופי של הספק*
                            </div>
                        </div>
                    </div>

                    <div className="product-details-page-content">
                        <WBCarousel key={productId} carouselProps={carouselProps}>
                            {flatten(productBundle?.products?.map(product => product?.portfolioImages)).map(image => (
                                <div
                                    id={`portfolioImages_${image?.imageId}`}
                                    key={image?.imageId}
                                    className="marketplace-category-item-details-item-portfolio-image">
                                    <ProgressiveImageLoader
                                        src={image?.imageUrl || cover_placeholder}
                                        alt={image?.imageId}
                                        width={800}
                                        height={440}
                                    />
                                </div>
                            ))}
                        </WBCarousel>
                        <div style={{userSelect: "none", marginTop: "7px"}}>תמונה להמחשה בלבד*</div>

                        <MarketplaceSeparatorLine opacity={"0.1"} />
                        <>
                            <Collapse
                                ghost={true}
                                className="product-details-page-collapse"
                                expandIconPosition="start"
                                activeKey={activeKey}
                                onChange={newKeys => setActiveKey(newKeys)}>
                                {productBundle?.description ? (
                                    <>
                                        <GeneralDescriptionPanel key={ProductDetailsPanelKeys.GeneralDescription} />
                                        <MarketplaceSeparatorLine opacity={"0.1"} />
                                    </>
                                ) : null}

                                <>
                                    <PackageInfoPanel
                                        key={ProductDetailsPanelKeys.PackageInfo}
                                        onBookRedirectCollapse={onBookRedirectCollapse}
                                    />
                                    <MarketplaceSeparatorLine opacity={"0.1"} />
                                </>

                                <>
                                    <AdditionalInfoPanel
                                        key={ProductDetailsPanelKeys.AdditionalInfo}
                                        log={log}
                                        onBookRedirectCollapse={onBookRedirectCollapse}
                                        locations={locations}
                                    />
                                    <MarketplaceSeparatorLine opacity={"0.1"} />
                                </>

                                {productSearchTags?.length ? (
                                    <>
                                        <ProductTagsPanel
                                            key={ProductDetailsPanelKeys.ProductTags}
                                            productSearchTags={productSearchTags}
                                        />
                                        <MarketplaceSeparatorLine opacity={"0.1"} />
                                    </>
                                ) : null}

                                <>
                                    <PopularQuestionsPanel key={ProductDetailsPanelKeys.QuestionAndAnswers} />
                                    <MarketplaceSeparatorLine opacity={"0.1"} />
                                </>
                            </Collapse>
                        </>

                        <div className="product-details-page-section-container">
                            <div className="product-details-page-section-title">
                                ✅ הארגונים בקהילה שלנו סומכים על הספק
                            </div>
                            <div className={classnames("product-details-page-section-desc")}>
                                מחפשים ספק שאפשר לסמוך עליו? חברי הקהילה הארגונית שלנו כבר עובדים איתו :)
                                <PreviouslyOrderedCompanies providerIds={productBundle?.providerIds} />
                            </div>

                            {uniqBy(productBundle.products, product => product.providerId).map(product => {
                                const foundProvider = productBundle.providers.find(
                                    provider => provider.providerId === product.providerId
                                );
                                return <ProviderAboutSection product={product} providerDetails={foundProvider} />;
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
