import React, {useContext, useEffect, useState, useMemo} from "react";
import {Collapse} from "antd";
import {flatten, isEmpty, uniq} from "lodash";
import {PaymentInput} from "./PaymentInput";
import {PaymentInputHeader} from "./PaymentInputHelper";
import {OrderFormPanelKeys} from "../../consts";
import {PaymentType} from "../../../consts.js";
import {AppContext} from "../../../AppContext";
import "../../marketplace.css";
import {MarketplaceContext} from "../../marketplaceContext.jsx";

const {Panel} = Collapse;

export const PaymentPanel = ({
    updateCreditCard,
    onPaymentTypeChange,
    invoiceComment,
    onInvoiceCommentChange,
    invoicePONumber,
    onInvoicePONumberChange,
    separateInvoice,
    onSeparateInvoiceChange,
    budgetCategoriesPriority,
    onBudgetCategoriesPriorityChange,
    allowedPaymentMethods = null,
    updateFormValues,
    tenBisCreditCard,
    ...restOfPanelProps
}) => {
    const {me} = useContext(AppContext);
    const {product, productBundle, isProductBundle} = useContext(MarketplaceContext);

    const [creditCard, setCreditCard] = useState({});
    const [paymentType, setPaymentType] = useState(allowedPaymentMethods?.[0] ?? null);

    useEffect(() => {
        onPaymentTypeChange(paymentType);
    }, [paymentType]);

    useEffect(() => {
        updateCreditCard(isEmpty(creditCard) ? null : creditCard);
    }, [creditCard, updateCreditCard]);

    const productServices = useMemo(() => {
        if (isProductBundle) {
            return uniq(flatten(productBundle.products.map(({services}) => services)));
        }

        return product?.services;
    }, [product, productBundle, isProductBundle]);

    useEffect(() => {
        if (me.features?.category_budget && me.budget && productServices) {
            const defaultBudgetCategories = productServices.reduce((acc, serviceId) => {
                if (me.budget[serviceId]) {
                    acc.push(serviceId);
                }

                return acc;
            }, []);

            if (me.budget.amount) {
                defaultBudgetCategories.push(null);
            }

            onBudgetCategoriesPriorityChange(defaultBudgetCategories);
        }
    }, [me?.budget, product?.services, me.features?.category_budget]);

    return (
        <>
            <Panel
                {...restOfPanelProps}
                key={OrderFormPanelKeys.Payment}
                showArrow={true}
                header={<PaymentInputHeader paymentType={paymentType} cardDigits={creditCard.last4Digits} />}>
                <PaymentInput
                    onInvoiceCommentChange={onInvoiceCommentChange}
                    invoiceComment={invoiceComment}
                    onInvoicePONumberChange={onInvoicePONumberChange}
                    invoicePONumber={invoicePONumber}
                    onSeparateInvoiceChange={onSeparateInvoiceChange}
                    allowedPaymentMethods={allowedPaymentMethods}
                    paymentType={paymentType}
                    setPaymentType={setPaymentType}
                    budgetCategoriesPriority={budgetCategoriesPriority}
                    separateInvoice={separateInvoice}
                    creditCard={creditCard}
                    setCreditCard={setCreditCard}
                    onBudgetCategoriesPriorityChange={onBudgetCategoriesPriorityChange}
                    updateFormValues={updateFormValues}
                    tenBisCreditCard={tenBisCreditCard}
                    creditCardToolTipInfo={
                        <>
                            <div>
                                <p className="payment-input-tooltip-text">
                                    בחירה בכרטיס אשראי כאמצעי תשלום לעסקה מאפשרת לכם לשלם על העסקה הספציפית הזו בכרטיס
                                    אשראי המוזן כולל הנפקת חשבונית מס/קבלה.
                                </p>
                                <p className="payment-input-tooltip-text">
                                    שימו ❤️ האשראי האשראי יסלק בערב יום האספקה הראשון של ההזמנה בכפוף לאישור ההזמנה על
                                    ידי הספק ט.ל.ח
                                </p>
                            </div>
                        </>
                    }
                />
            </Panel>
        </>
    );
};
