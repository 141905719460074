import React, {useCallback, useContext, useMemo, useState} from "react";
import {parse as qsParse} from "query-string";
import {Card} from "antd";
import {Languages} from "./Login";
import {ResetPasswordCardBody} from "./ResetPasswordCard";
import {HttpClient} from "../http/HttpClient";
import {EventBus} from "../bus/EventBus";
import {AppContext} from "../AppContext";
import {Images} from "../images/Images";
import {hasEXCenterFeatureOpen} from "../center/EXCenterHelper";

export const ResetPassword = ({history, location, onSuccess}) => {
    const {company} = useContext(AppContext);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [language, setLanguage] = useState(Languages.Hebrew);

    async function onFinish(values) {
        const {search} = location;
        const {token, email} = qsParse(search);

        setSendingEmail(true);

        const user = await HttpClient.safePost(`/api/users/password/resetByToken`, {
            ...values,
            email,
            token
        });

        if (user.error) {
            EventBus.triggerError(
                "server-error",
                {
                    content: {
                        description: [
                            "Unfortunately we couldn't reset your password.",
                            `Error: ${user?.error}`,
                            "If you need further help, please contact us at: " + process.env.SUPPORT_EMAIL
                        ],
                        hideSteps: true
                    },
                    cta: {
                        hide: true
                    }
                },
                user.error.message
            );

            setSendingEmail(false);
            return;
        }

        await onSuccess(user);
        history.replace("/login");
        setSendingEmail(false);
    }

    const goToDashboard = useCallback(
        user => {
            if (user.type === "employee" && hasEXCenterFeatureOpen(user, company)) {
                history.push("/center");
            } else {
                history.replace("/dashboard");
            }
        },
        [history, company]
    );

    const isMobileView = useMemo(() => {
        return window.innerWidth < 550;
    }, [window?.innerWidth]);

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                overflow: "auto",
                height: isMobileView ? "100%" : "100vh",
                backgroundColor: "#999999"
            }}>
            <div
                onClick={() => {
                    setLanguage(language => (language === Languages.Hebrew ? Languages.English : Languages.Hebrew));
                }}
                style={{
                    fontSize: "20px",
                    marginTop: "30px",
                    cursor: "pointer",
                    color: "#FFFFFF",
                    display: "flex",
                    justifyContent: "center"
                }}>
                {language === Languages.Hebrew ? "Change language" : "שינוי שפה"}
            </div>
            <Card
                bordered={true}
                bodyStyle={{borderRadius: "5px"}}
                style={{
                    boxSizing: "border-box",
                    minHeight: "578px",
                    width: isMobileView ? "400px" : "500px",
                    border: "1px solid rgba(131,152,166,0.2)",
                    borderRadius: "5px",
                    marginTop: "110px"
                }}
                cover={
                    <ResetPasswordCardBody
                        title="ברוכים הבאים 👋"
                        language={language}
                        onFinish={values => onFinish(values)}
                        isMobileView={isMobileView}
                        sendingEmail={sendingEmail}
                    />
                }></Card>
        </div>
    );
};
