import {OrderStatus} from "../../components";
import {minBy, uniq} from "lodash";
import {OrderStatusPriority} from "../../components/OrderStatusLabel";
import {PaymentType} from "../../consts.js";
import moment from "moment";
import {EDIT_ORDER_ERRORS, ORDER_DEADLINE_HOURS} from "./consts.jsx";

export function productBundleOrderStatus(orders) {
    for (const order of orders) {
        if (order.status === OrderStatus.cancelled || order.status === OrderStatus.declined) {
            return order.status;
        }
    }

    const allOrdersStatus = uniq(orders.map(({status}) => status));

    if (allOrdersStatus.length === 1) {
        return allOrdersStatus[0];
    }

    return minBy(orders, order => OrderStatusPriority[order.status]).status;
}

export const validateOrderDisabledForEdit = (order, isAdmin) => {
    if (order?.isVoucherOrder) {
        return {error: EDIT_ORDER_ERRORS.VOUCHER};
    }

    if (isAdmin) {
        return;
    }

    const isOrderFailed = [OrderStatus.cancelled, OrderStatus.declined].includes(order.status);
    if (isOrderFailed) {
        return {error: EDIT_ORDER_ERRORS.CANCEL_OR_DECLINE};
    }

    const editDeadline = moment(order.dtstart).subtract(ORDER_DEADLINE_HOURS, "hours");
    const isEditDeadlinePassed = moment().isAfter(editDeadline);
    if (isEditDeadlinePassed) {
        return {error: EDIT_ORDER_ERRORS.ORDER_CHANGE_TIME_EXPIRED};
    }

    if (order?.paymentType === PaymentType.Card) {
        return {error: EDIT_ORDER_ERRORS.CREDIT_CARD};
    }

    if (order?.needManagerConfirm) {
        return {error: EDIT_ORDER_ERRORS.MANAGER_APPROVE};
    }
};

export const isOrderWaitingForManagerApprove = (order, me) =>
    order?.needManagerConfirm &&
    order.status === OrderStatus.pending &&
    order?.creatingUserId !== me?.userId &&
    (order?.eventStatusMap ?? []).some(({id}) => id === OrderStatus.pendingManagerConfirm);
