import {StyledButton} from "../components/StyledButton";
import {CloseOutlined, PlusOutlined} from "@ant-design/icons";
import {Button, List, Tooltip} from "antd";
import {EyeCrossIcon, EyeIcon} from "../icons";
import React from "react";
import {truncate} from "lodash";

export const CompanyBranchesList = ({
    branches,
    onNewBranchClick,
    onEditBranchClick,
    onRemoveBranchClick,
    onBranchVisibilityChange,
    onMarkMainBranchClick
}) => (
    <List
        size="small"
        style={{borderRadius: 10}}
        bordered={true}
        header={
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", height: 50}}>
                <label style={{fontSize: 18, color: "var(--secondary-color)"}}>Sites</label>
                <StyledButton
                    onClick={() => onNewBranchClick()}
                    style={{border: "none", width: 30, height: 30}}
                    icon={<PlusOutlined style={{fontSize: 16, color: "var(--secondary-color)"}} />}
                />
            </div>
        }
        locale={{
            emptyText: (
                <div style={{height: 100, display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <StyledButton
                        onClick={() => onNewBranchClick()}
                        style={{
                            borderRadius: 25,
                            width: 150,
                            textAlign: "center",
                            backgroundColor: "var(--secondary-color)",
                            height: 50,
                            color: "white"
                        }}>
                        Add branch
                    </StyledButton>
                </div>
            )
        }}
        dataSource={branches || []}
        renderItem={branchInfo => {
            const {branchId, name, address, isHQ, visibility} = branchInfo;
            return (
                <List.Item>
                    <div
                        style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <label style={{color: "var(--secondary-color)", fontSize: 14, fontWeight: 500}}>
                                <Button
                                    type="link"
                                    style={{padding: 0, fontSize: 16}}
                                    onClick={() => onEditBranchClick(branchInfo)}>
                                    {truncate(name, {length: 22})}
                                </Button>
                            </label>
                            <label style={{color: "#747F92", fontSize: 12, fontWeight: 400}}>{address}</label>
                        </div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            {typeof visibility === "undefined" || visibility === "all" ? (
                                <Tooltip title="Employees from this site will be able to see other sites' data.">
                                    <span
                                        style={{cursor: "pointer", paddingTop: 6, marginRight: 15}}
                                        onClick={() => onBranchVisibilityChange(branchInfo)}>
                                        <EyeIcon style={{fill: "#747F92", width: 22, height: 22}} />
                                    </span>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Employees from this site will be able to see its data only.">
                                    <span
                                        style={{cursor: "pointer", paddingTop: 6, marginRight: 15}}
                                        onClick={() => onBranchVisibilityChange(branchInfo)}>
                                        <EyeCrossIcon style={{fill: "#747F92", width: 22, height: 22}} />
                                    </span>
                                </Tooltip>
                            )}
                            <Tooltip
                                title={
                                    isHQ
                                        ? "This is your company's headquarters"
                                        : "Press to mark as your company's headquarters"
                                }>
                                <StyledButton
                                    onClick={() => onMarkMainBranchClick(branchId)}
                                    style={{
                                        color: "white",
                                        border: "none",
                                        backgroundColor: isHQ ? "#64D7BB" : "#747F92",
                                        height: 20,
                                        fontSize: 12,
                                        borderRadius: 25,
                                        padding: "6px 9px"
                                    }}>
                                    HQ
                                </StyledButton>
                            </Tooltip>
                            <StyledButton
                                onClick={() => onRemoveBranchClick(branchId)}
                                style={{border: "none", width: 20, height: 20, paddingRight: 10, marginLeft: 10}}
                                icon={<CloseOutlined style={{fontSize: 14, color: "#767F90"}} />}
                            />
                        </div>
                    </div>
                </List.Item>
            );
        }}
    />
);
