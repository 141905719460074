import React, {useContext, useEffect} from "react";
import {EXCenterTop} from "./top/EXCenterTop";
import {EXCenterBirthdaysSection} from "./birhtdays/EXCenterBirthdaysSection";
import {EXCenterUpdatesSection} from "./updates/EXCenterUpdatesSection";
import {EXCenterSectionsPicker} from "./picker/EXCenterSectionsPicker";
import {EXCenterInfoSection} from "./infos/EXCenterInfoSection";
import {CompanyContent} from "../company/content/CompanyContent";
import {EXCenterEventsSection} from "./events/EXCenterEventsSection";
import {EXCenterCalendarSection} from "./calendar/EXCenterCalendarSection";
import {EXCenterContext} from "./EXCenterContext";
import {PageLoader} from "../components/PageLoader";
import {announceSyncResult} from "../company/CompanySchedule";
import {scrollToElement} from "./utils/ViewUtil";
import {ArrowDownIcon} from "../icons";

export const EXCenterMain = ({history}) => {
    const {defaultBranchId, theme} = useContext(EXCenterContext);

    useEffect(() => {
        const syncResult = sessionStorage.getItem("syncResult");
        sessionStorage.removeItem("syncResult");
        if (syncResult) {
            announceSyncResult(syncResult);
        }
    }, []);

    if (!defaultBranchId) {
        return <PageLoader align="flex-start" top={200} height={800} />;
    }

    const scrollToSectionId = sessionStorage.getItem("scrollToSectionId");
    if (scrollToSectionId) {
        sessionStorage.removeItem("scrollToSectionId");
        scrollToElement(`#wb-section-${scrollToSectionId}`);
    }

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
            <EXCenterTop history={history} />
            <EXCenterCalendarSection />
            <EXCenterBirthdaysSection />
            <EXCenterUpdatesSection />
            <EXCenterSectionsPicker />
            <EXCenterEventsSection />
            <div className="scroll-indicator">
                <ArrowDownIcon />
            </div>
            {CompanyContent.Sections.filter(s => typeof s.order === "number")
                .sort((s1, s2) => (s1.order > s2.order ? 1 : -1))
                .map(({sectionId, displayName}) => (
                    <EXCenterInfoSection key={`info-${sectionId}`} displayName={displayName} sectionId={sectionId} />
                ))}
        </div>
    );
};
