import React, {useCallback, useMemo} from "react";
import {InputNumber} from "antd";
import {MultipleSubProductsCategory} from "./MultipleSubProductsCategory";
import {SquareButton} from "../../components";
import "./provider-price-by-modular.css";
import {calculateModularProductPrice} from "./utils.js";
import classnames from "classnames";

export const ProviderPriceByModular = ({providerSubProducts, subProducts = [], minPrice = 0, onChange}) => {
    const calculatePrice = useMemo(
        () => calculateModularProductPrice(subProducts, providerSubProducts),
        [subProducts, providerSubProducts]
    );

    const onChangeSubProductProperty = useCallback(
        (subProductId, key, value) => {
            const newPricingTableByModular = subProducts.map(subProduct => {
                if (subProduct.id === subProductId) {
                    return {...subProduct, [key]: value};
                }

                return subProduct;
            });

            onChange({minPrice, subProducts: newPricingTableByModular});
        },
        [minPrice, subProducts]
    );

    const onChangePrice = useCallback(
        newPrice => {
            onChange({subProducts, minPrice: newPrice});
        },
        [subProducts]
    );

    return (
        <>
            <div className="price-type-container">
                <label className="price-type-label">תמחור לפי מיני מוצרים (כולל מע״מ)</label>
            </div>

            <div>
                <SquareButton
                    onClick={() => {
                        onChange({
                            minPrice,
                            subProducts: subProducts.map(subProduct => ({
                                ...subProduct,
                                isActive: true
                            }))
                        });
                    }}>
                    הפעל את כל המיני מוצרים
                </SquareButton>

                <MultipleSubProductsCategory
                    subProducts={subProducts}
                    providerSubProducts={providerSubProducts}
                    onChangeSubProductProperty={onChangeSubProductProperty}
                    updateSubProductsOrder={newSubProductsOrder => {
                        onChange({
                            minPrice,
                            subProducts: newSubProductsOrder
                        });
                    }}
                />
            </div>

            {subProducts.length > 0 ? (
                <div className="provider-price-by-people-selector-content">
                    <div className="provider-price-by-people-selector-price-calculator">
                        <span>מחיר החבילה:</span>
                        <span>{calculatePrice.toLocaleString()}</span>
                        <span>₪</span>
                    </div>

                    <div className="provider-price-by-people-selector-min-price">
                        <span className={classnames({"high-price-alert": calculatePrice < minPrice})}>
                            מחיר מינימלי לביצוע הזמנה:
                        </span>
                        <InputNumber
                            onChange={onChangePrice}
                            value={minPrice}
                            min={0}
                            defaultValue={0}
                        />
                    </div>
                </div>
            ) : (
                <div>
                    <span>לא קיימים מיני מוצרים לספק זה</span>
                </div>
            )}
        </>
    );
};
