export const CompanyHomePageTypes = {
    pageLayout: "pageLayout",
    selectPage: "selectPage"
};

export const CompanyHomePageTypesToHebrew = {
    [CompanyHomePageTypes.pageLayout]: "אתר קרוס",
    [CompanyHomePageTypes.selectPage]: "אתר בחירה"
};

export const CompanyHomePageRedirectUserTypes = {
    companyMaster: "companyMaster",
    companyManageDepartment: "companyManageDepartment",
    companySubAdmin: "companySubAdmin"
};

export const CompanyHomePageRedirectUserTypesToHebrew = {
    [CompanyHomePageRedirectUserTypes.companyMaster]: "אדמין ראשי",
    [CompanyHomePageRedirectUserTypes.companyManageDepartment]: "מנהלי מחלקה",
    [CompanyHomePageRedirectUserTypes.companySubAdmin]: "תתי אדמין"
};
