import {Form, Modal, message} from "antd";
import {ReviewRating} from "../../review/ReviewRating";
import {FormInput, FormTextArea} from "../../components/form";
import {StyledButton} from "../../components/StyledButton";
import React, {useEffect, useState} from "react";
import {CloseIcon} from "../../icons";
import {SearchableSelect} from "../../components/SearchableSelect";
import {HttpClient} from "../../http/HttpClient";
import {UploadableCoverPhoto} from "../../components/UploadableCoverPhoto";

const {Option} = SearchableSelect;

export const AdminCustomReviewModal = ({visible, onClose}) => {
    const [loading, setLoading] = useState(false);
    const [providers, setProviders] = useState([]);

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const providers = await HttpClient.get("/admin/api/providers");
                setProviders(providers);
            } catch (e) {
                message.error(e.message, 3);
            }
        });
    }, []);

    const addReview = async review => {
        setLoading(true);
        try {
            review = await HttpClient.put("/admin/api/reviews", review);
            onClose(review);
        } catch (e) {
            message.error(e.message, 3);
        }
        setLoading(false);
    };

    return (
        <Modal
            centered={true}
            width={400}
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            bodyStyle={{padding: 0}}
            footer={null}
            title={null}
            closeIcon={<CloseIcon fill="#8398A6" />}
            closable={true}
            onCancel={() => onClose()}>
            <Form onFinish={addReview} style={{width: "100%", padding: 20}}>
                <div
                    style={{
                        fontSize: 20,
                        fontWeight: 700,
                        marginTop: 20,
                        marginBottom: 24,
                        color: "#273444",
                        width: "100%",
                        textAlign: "left"
                    }}>
                    Add your custom review
                </div>
                <Form.Item name="logoUrl" valuePropName="imageUrl" style={{display: "flex", justifyContent: "center"}}>
                    <UploadableCoverPhoto
                        width={320}
                        height={128}
                        quality={100}
                        showUploadOnly={true}
                        showPlaceholder={true}
                        coverStyle={{borderRadius: "none", width: 360, paddingBottom: 144}}
                    />
                </Form.Item>
                <Form.Item
                    name="providerId"
                    rules={[
                        {
                            required: true,
                            message: "Please select vendor."
                        }
                    ]}>
                    <SearchableSelect placeholder="Vendor">
                        {providers.map(provider => (
                            <Option key={provider.providerId} value={provider.providerId}>
                                {`${provider.firstName} ${provider.lastName ? provider.lastName : ""}`}
                            </Option>
                        ))}
                    </SearchableSelect>
                </Form.Item>
                <Form.Item name="from">
                    <FormInput placeholder="Reviewer name (optional)" />
                </Form.Item>
                <Form.Item
                    name="rating"
                    rules={[
                        {
                            required: true,
                            type: "number",
                            message: "Please select rating."
                        }
                    ]}>
                    <ReviewRating />
                </Form.Item>
                <div
                    style={{
                        width: "100%",
                        textAlign: "left",
                        fontSize: 16,
                        color: "#C1CBD3",
                        marginTop: 5,
                        marginBottom: 15
                    }}>
                    Content:
                </div>
                <Form.Item name="content">
                    <FormTextArea maxLength={120} autoSize={{maxRows: 5, minRows: 5}} />
                </Form.Item>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        alignItems: "center"
                    }}>
                    <Form.Item style={{marginBottom: 0, display: "flex"}}>
                        <StyledButton
                            loading={loading}
                            htmlType="submit"
                            style={{
                                backgroundColor: "#13C296",
                                color: "white",
                                height: 40,
                                borderRadius: 25,
                                width: 80
                            }}>
                            Add
                        </StyledButton>
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    );
};
