import {Carousel, Modal} from "antd";
import React from "react";
import {LeftIcon, RightIcon} from "../icons";

export const ProviderPortfolioViewModal = ({portfolioImages, visible, onClose}) => {
    let slider = null;

    const next = () => {
        slider.next();
    };

    const prev = () => {
        slider.prev();
    };

    return (
        <div onClick={e => e.stopPropagation()}>
            <Modal
                className="wb-portfolio-view-modal"
                title="Portfolio photos"
                open={visible}
                onCancel={() => onClose()}
                bodyStyle={{padding: 0}}
                footer={null}>
                <Carousel dots={false} ref={_slider => (slider = _slider)}>
                    {portfolioImages.map(imageData => {
                        return (
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginBottom: 15
                                    }}>
                                    <img
                                        width="95%"
                                        style={{margin: 10, borderRadius: 15}}
                                        src={imageData.imageUrl}
                                        alt="portfolio"
                                    />
                                </div>
                            </div>
                        );
                    })}
                </Carousel>
                <LeftIcon
                    style={{
                        fontSize: 20,
                        position: "absolute",
                        left: "5%",
                        top: "55%",
                        color: "white",
                        cursor: "pointer"
                    }}
                    onClick={() => prev()}
                />
                <RightIcon
                    style={{
                        fontSize: 20,
                        position: "absolute",
                        right: "5%",
                        top: "55%",
                        color: "white",
                        cursor: "pointer"
                    }}
                    onClick={() => next()}
                />
            </Modal>
        </div>
    );
};
