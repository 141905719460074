import {EventCalendarSyncModal} from "./sync/EventCalendarSyncModal";
import {EmployeeUpdateInfoModal} from "../../employee/EmployeeUpdateInfoModal";
import React, {useContext, useReducer} from "react";
import {AppContext} from "../../AppContext";
import {eventByDate} from "../EventByDate";
import {GoogleAnalytics} from "../../GoogleAnalytics";
import {HttpClient} from "../../http/HttpClient";
import {EventBus} from "../../bus/EventBus";
import {FrequencyMapper} from "../FrequencyMapper";
import {isHostedByProvider, transformEvent} from "./EventTransformer";
import {message, Spin} from "antd";
import {openWindow} from "../../http/WindowOpener";
import {PageLoader} from "../../components";
import {capitalize, isEmpty} from "lodash";
import moment from "moment-timezone";
import {hasCalendarSync} from "../EventRegisterModal";

const isRegistered = (event, me) => {
    return event.registeredEmployees.some(reg => reg.employeeId === me.userId);
};

export const EventInfoManager = ({
    Component,
    eventInfo,
    providerInfo,
    dateMom,
    onBook,
    onClose,
    ignoreProviderInfo = false,
    maxWidth = 630
}) => {
    const {me, theme, company} = useContext(AppContext);
    event = eventByDate(eventInfo, dateMom.toDate());
    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        registering: false,
        registered: isRegistered(event, me),
        registerEvent: null,
        showSyncOptions: false,
        showUserUpdateInfoModal: false
    });

    const {eventId, meetingLink} = event;

    let {registering, registered, registerEvent, showSyncOptions, showUserUpdateInfoModal} = state;

    if (registered !== isRegistered(event, me)) {
        registered = isRegistered(event, me);
    }

    const isAppointmentBased = event.appointmentBased;

    const syncEvent = async () => {
        const type = me.calendarApi.type;

        try {
            const date = dateMom.format("YYYY-MM-DD");
            const time = moment(eventInfo.dtstart).format("HH:mm");
            await HttpClient.post(`/api/events/${eventId}/sync/${type}?date=${date}&time=${time}`);
            message.success("Successfully synced event to your calendar.", 3);
            GoogleAnalytics.event("Calendar Sync", `${capitalize(type)} Event Sync`, eventId);
        } catch (e) {
            message.error("Something went wrong, please try again later.", 5);
        }
    };

    const register = async (applyType = "only", date = "") => {
        setState({registering: true});
        try {
            GoogleAnalytics.event("Company Schedule", "Register Session", eventId);
            GoogleAnalytics.trackCalendarEvent(event, "CTA", me.branch.name);
            await HttpClient.post(`/api/events/${eventId}/register?applyType=${applyType}&date=${date}`, {});
            setState({registered: true});

            if (applyType === "all") {
                addRegisteredEmployeeLocallyToAllSessions();
            } else {
                addRegisteredEmployeeLocallyPerDate();
            }
            if (await hasCalendarSync(me)) {
                await syncEvent();
            } else {
                openSyncOptions(applyType);
            }
        } catch (e) {
            if (e.statusCode === 400) {
                EventBus.triggerError(
                    "server-error",
                    {
                        content: {
                            description: e.message,
                            hideSteps: true,
                            hideSubTitle: true,
                            title: "Ohh..."
                        },
                        cta: {hide: true}
                    },
                    e.message
                );
            } else {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we couldn't complete your registration :("}},
                    e.message
                );
            }
        }
        setState({registering: false});
    };

    const openSyncOptions = applyType => {
        if (FrequencyMapper.oneTime(event)) {
            setState({showSyncOptions: "only"});
        } else {
            setState({showSyncOptions: applyType});
        }
    };

    const ensureRegisteredEmployeesPerDate = () => {
        const date = dateMom.format("YYYY-MM-DD");
        eventInfo.byDate = eventInfo.byDate || {};
        eventInfo.byDate[date] = eventInfo.byDate[date] || {};
        eventInfo.byDate[date].registeredEmployees = eventInfo.byDate[date].registeredEmployees || [];
        return eventInfo.byDate[date].registeredEmployees;
    };

    const addRegisteredEmployeeLocallyPerDate = () => {
        addRegisteredEmployeeByRef(ensureRegisteredEmployeesPerDate());
    };

    const addRegisteredEmployeeLocallyToAllSessions = () => {
        eventInfo.registeredEmployees = eventInfo.registeredEmployees || [];
        addRegisteredEmployeeByRef(eventInfo.registeredEmployees);
    };

    const addRegisteredEmployeeByRef = registeredEmployeesRef => {
        if (registeredEmployeesRef.every(reg => reg.employeeId !== me.userId)) {
            registeredEmployeesRef.push({
                timestampMs: Date.now(),
                employeeId: me.userId,
                employeeFirstName: me.firstName,
                employeeLastName: me.lastName,
                employeeEmail: me.email
            });
        }
    };

    const removeRegisteredEmployeeLocallyPerDate = () => {
        removeRegisteredEmployeeByRef(ensureRegisteredEmployeesPerDate());
    };

    const removeRegisteredEmployeeLocallyFromAllSessions = () => {
        eventInfo.registeredEmployees = eventInfo.registeredEmployees || [];
        removeRegisteredEmployeeByRef(eventInfo.registeredEmployees);
        Object.keys(eventInfo.byDate || {}).forEach(date => {
            const perDate = eventInfo.byDate ? eventInfo.byDate[date] || {} : {};
            if (Array.isArray(perDate.registeredEmployees)) {
                perDate.registeredEmployees = perDate.registeredEmployees.filter(reg => reg.employeeId !== me.userId);
            }
        });
    };

    const removeRegisteredEmployeeByRef = registeredEmployeesRef => {
        const foundIdx = registeredEmployeesRef.findIndex(reg => reg.employeeId === me.userId);
        if (foundIdx >= 0) {
            registeredEmployeesRef.splice(foundIdx, 1);
        }
    };

    const doRegistration = async event => {
        if (!me.phone && isHostedByProvider(eventInfo)) {
            setState({showUserUpdateInfoModal: true});
        } else if (FrequencyMapper.oneTime(event)) {
            await register();
        } else if (event.maxParticipants > 0) {
            await register("only", dateMom.format("YYYY-MM-DD"));
        } else {
            await register("only", dateMom.format("YYYY-MM-DD"));
        }
    };

    const doBooking = () => {
        if (!me.phone && isHostedByProvider(eventInfo)) {
            setState({showUserUpdateInfoModal: true});
        } else {
            GoogleAnalytics.trackCalendarEvent(event, "CTA", me.branch.name);
            onBook();
        }
    };

    const unregister = async () => {
        setState({registering: true});
        try {
            const applyType = isRegisteredToAllSessions() ? "all" : "only";
            const date = applyType === "all" ? "" : dateMom.format("YYYY-MM-DD");

            GoogleAnalytics.event("Company Schedule", "Unregister Session", eventId);
            await HttpClient.post(`/api/events/${eventId}/unregister?applyType=${applyType}&date=${date}`, {});
            setState({registered: false});

            if (applyType === "all") {
                removeRegisteredEmployeeLocallyFromAllSessions();
            } else {
                removeRegisteredEmployeeLocallyPerDate();
            }
        } catch (e) {
            message.error(e.message, 3);
        }
        setState({registering: false});
    };

    const isRegisteredToAllSessions = () => {
        if (Array.isArray(eventInfo.registeredEmployees)) {
            return eventInfo.registeredEmployees.some(reg => reg.employeeId === me.userId);
        }

        return false;
    };

    const join = () => {
        if (meetingLink) {
            openWindow(`/api/events/${eventId}/track?date=${dateMom.format("YYYY-MM-DD")}`, "_blank");
            GoogleAnalytics.event("Company Schedule", "Join Session", eventId);
            onClose && onClose(eventInfo);
        }
    };

    if (isHostedByProvider() && !providerInfo && !ignoreProviderInfo) {
        return <PageLoader align="flex-start" top={80} height={400} />;
    }

    if (!ignoreProviderInfo) {
        event = transformEvent(me, event, providerInfo);
        if (me.type === "provider") {
            event.callToAction.name.selected = false;
        } else if (isHostedByProvider(event)) {
            event.provider.name.link = `/dashboard/schedule/events/${event.eventId}/providers/${event.providerId}`;
        }
    }

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", maxWidth, width: "100%"}}>
            <EventCalendarSyncModal
                event={event}
                onClose={() => {
                    setState({showSyncOptions: false});
                    onClose && onClose(eventInfo);
                }}
                visible={!!showSyncOptions}
                dateMom={dateMom}
                applyType={showSyncOptions}
            />
            <EmployeeUpdateInfoModal
                visible={showUserUpdateInfoModal}
                onClose={() => setState({showUserUpdateInfoModal: false})}
            />
            <Component
                event={event}
                isAppointmentBased={isAppointmentBased}
                registering={registering}
                registered={registered}
                doRegistration={doRegistration}
                unregister={unregister}
                doBooking={doBooking}
                join={join}
            />
        </div>
    );
};
