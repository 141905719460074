import {useCallback, useContext, useMemo} from "react";
import {getLocationTitle} from "./utils.js";
import {buildFilters} from "../../advancedFilter/utils.js";
import {isEqual} from "lodash";
import {MarketplaceFilterProviderContext} from "../../MarketplaceFilterContext.jsx";
import {AppContext} from "../../../../AppContext.jsx";

export const useLocationFilter = () => {
    const {filterValues} = useContext(AppContext);
    const {innerState, setAddressName, setInnerState} = useContext(MarketplaceFilterProviderContext);

    const updateAddressTitle = useCallback(() => {
        setAddressName(getLocationTitle(innerState?.locationFilter));
    }, [innerState]);

    const onClearAddress = useCallback(() => {
        setInnerState(({locationFilter, ...otherProperties}) => otherProperties);
    }, []);

    const didStateChange = useMemo(() => {
        const filter = buildFilters(innerState);
        const isDeliveryAddressEqual = isEqual(filter.deliveryAddress?.[0], filterValues.deliveryAddress?.[0]);
        return !isDeliveryAddressEqual;
    }, [innerState?.locationFilter, filterValues?.deliveryAddress]);

    return {updateAddressTitle, onClearAddress, didStateChange};
};
