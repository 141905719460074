import React, {useState} from "react";
import moment from "moment";
import {groupBy} from "lodash";
import {ConfigProvider, Modal} from "antd";
import {MonthFormat} from "./consts";
import getColumns from "./columns";
import {PageTitleLabel, RoundedTable} from "../../../components";
import {useRequest} from "../../../utils/hooks";
import {getDeliveryPriceForInvoice, getInvoicePrice, reduceVAT} from "../../../utils/calculationUtils";
import {momentHebrewFormat} from "../../../utils/DateFormat";
import AdminProviderMonthOrdersModal from "../../AdminInvoices/AdminProviderMonthOrdersModal";
import {ProviderDealerTypes} from "../../upload/AdminUploadUtils";
import "./admin-provider-payment-history-modal.css";
import {isOrderHasShippingProvider} from "../../../event/utils.jsx";

const AdminProviderPaymentHistoryModal = ({provider, visible, onCancel}) => {
    const [providerOrdersModalMonth, setProviderOrdersModalMonth] = useState(null);

    const [providerOrders, loadingProviderOrders] = useRequest(
        `/admin/api/orders/getAllProviderOrders/${provider?.providerId}`,
        "GET",
        null,
        [],
        !!provider?.providerId,
        allOrders => {
            const groupedOrders = groupBy(allOrders, ({until}) => moment(until).format(MonthFormat));

            return Object.entries(groupedOrders).map(([month, orders]) => {
                const hebrewMonthName = momentHebrewFormat(moment(month, MonthFormat), MonthFormat);
                const isExemptDealerType = provider?.dealerType === ProviderDealerTypes.exempt;

                const orderSummedStats = orders.reduce(
                    (acc, order) => {
                        const isWellBDelivery = isOrderHasShippingProvider(order);

                        const deliveryPriceByWellB = getDeliveryPriceForInvoice(
                            order,
                            order.deliveryCommission,
                            isExemptDealerType,
                            provider?.maxShippingProvidersDeliveryPrice
                        );

                        const deliveryPrice = isExemptDealerType
                            ? reduceVAT(order.deliveryPrice ?? 0, order.vat)
                            : order.deliveryPrice;

                        return {
                            ...acc,
                            ...(isWellBDelivery
                                ? {deliveryPriceByWellB: (acc.deliveryPriceByWellB ?? 0) + (deliveryPriceByWellB ?? 0)}
                                : {deliveryPrice: (acc.deliveryPrice ?? 0) + (deliveryPrice ?? 0)}),
                            offeredPrice: acc.offeredPrice + order.offeredPrice,
                            providerPayment:
                                acc.providerPayment +
                                getInvoicePrice(order.offeredPrice, order.commission, isExemptDealerType, order.vat),

                            tip: acc.tip + (order.tip ?? 0),
                            providerDiscount: acc.providerDiscount + (order.providerDiscount ?? 0)
                        };
                    },
                    {
                        offeredPrice: 0,
                        providerPayment: 0,
                        deliveryPrice: 0,
                        providerDiscount: 0,
                        tip: 0,
                        deliveryPriceByWellB: 0
                    }
                );

                return {
                    month,
                    hebrewMonthName,
                    ordersCount: orders.length,
                    ...orderSummedStats
                };
            });
        }
    );

    const [providerInvoices, loadingInvoices, _, fetchProviderInvoices] = useRequest(
        `/admin/api/providerInvoice/byProvider/${provider?.providerId}`,
        "GET",
        null,
        [],
        !!provider?.providerId
    );

    return (
        <ConfigProvider direction="rtl">
            <Modal
                className="admin-provider-payment-history-modal"
                width="90vw"
                destroyOnClose={true}
                open={visible}
                onCancel={onCancel}
                footer={null}
                title={null}>
                <AdminProviderMonthOrdersModal
                    providerId={provider?.providerId}
                    visible={!!providerOrdersModalMonth}
                    invoices={providerInvoices}
                    onCancel={() => setProviderOrdersModalMonth(null)}
                    addInvoice={fetchProviderInvoices}
                    month={providerOrdersModalMonth}
                />
                <PageTitleLabel>היסטוריית תשלומים {provider?.businessName}</PageTitleLabel>
                <RoundedTable
                    rowKey="month"
                    pagination={false}
                    bordered={true}
                    loading={loadingProviderOrders}
                    dataSource={providerOrders}
                    className="admin-provider-payment-history-table"
                    columns={getColumns({
                        providerInvoices,
                        loadingInvoices,
                        openProviderOrders: setProviderOrdersModalMonth
                    })}
                    locale={{
                        emptyText: (
                            <div onClick={() => setProviderOrdersModalMonth(moment().subtract(1, "month"))}>
                                לפירוט חודשי
                            </div>
                        )
                    }}
                    summary={() => {
                        if (!providerOrders) {
                            return null;
                        }

                        const summaryRow = providerOrders.reduce(
                            (allMonthsInfo, monthInfo) => ({
                                ...[
                                    "offeredPrice",
                                    "providerPayment",
                                    "deliveryPrice",
                                    "deliveryPriceByWellB",
                                    "providerDiscount",
                                    "ordersCount",
                                    "tip"
                                ].reduce(
                                    (acc, key) => ({
                                        ...acc,
                                        [key]: (allMonthsInfo[key] ?? 0) + (monthInfo[key] ?? 0)
                                    }),
                                    {}
                                )
                            }),
                            {}
                        );

                        return (
                            <RoundedTable.Summary.Row className="colored-summary-row">
                                <RoundedTable.Summary.Cell colSpan={2} />
                                <RoundedTable.Summary.Cell>
                                    {summaryRow.offeredPrice ? `${summaryRow.offeredPrice.toLocaleString()} ₪` : null}
                                </RoundedTable.Summary.Cell>
                                <RoundedTable.Summary.Cell>
                                    {summaryRow.providerPayment
                                        ? `${summaryRow.providerPayment.toLocaleString()} ₪`
                                        : null}
                                </RoundedTable.Summary.Cell>
                                <RoundedTable.Summary.Cell>
                                    {summaryRow.deliveryPrice ? `${summaryRow.deliveryPrice.toLocaleString()} ₪` : null}
                                </RoundedTable.Summary.Cell>
                                <RoundedTable.Summary.Cell>
                                    {summaryRow?.deliveryPriceByWellB
                                        ? `${summaryRow?.deliveryPriceByWellB.toLocaleString()} ₪`
                                        : null}
                                </RoundedTable.Summary.Cell>
                                <RoundedTable.Summary.Cell className="ltr">
                                    {summaryRow.providerDiscount
                                        ? `₪ ${(-1 * summaryRow.providerDiscount).toLocaleString()}`
                                        : null}
                                </RoundedTable.Summary.Cell>
                                <RoundedTable.Summary.Cell>
                                    {summaryRow.tip ? `${summaryRow.tip.toLocaleString()} ₪` : null}
                                </RoundedTable.Summary.Cell>
                                <RoundedTable.Summary.Cell>{`${(
                                    summaryRow.providerPayment +
                                    summaryRow.deliveryPrice +
                                    summaryRow.deliveryPriceByWellB -
                                    summaryRow.providerDiscount +
                                    summaryRow.tip
                                ).toFixed(2)} ₪`}</RoundedTable.Summary.Cell>
                                <RoundedTable.Summary.Cell>{summaryRow.ordersCount}</RoundedTable.Summary.Cell>
                                <RoundedTable.Summary.Cell />
                            </RoundedTable.Summary.Row>
                        );
                    }}
                />
            </Modal>
        </ConfigProvider>
    );
};

export default AdminProviderPaymentHistoryModal;
