import React from "react";
import {Tag} from "antd";
import {kosherTypes} from "../../../ProductsFilterModal/consts.jsx";
import {kosherTab} from "./consts.js";

const {CheckableTag} = Tag;

export const kosherOptions = [
    {key: kosherTypes.TZOHAR, label: "צוהר"},
    {key: kosherTypes.MEHADRIN, label: "מהדרין"},
    {key: kosherTypes.BEDATZ, label: "בד״צ"}
];

export const kosherGroupOptions = (innerState, onKosherOptionChange) => {
    return [
        {
            text: "כל כשרות תספיק לי",
            key: kosherTab.anyKosher
        },
        {
            text: "הצג לי אחת מהכשרויות הבאות",
            key: kosherTab.allKosher,
            component: (
                <div className="kosher-options-container">
                    {kosherOptions.map(option => {
                        return (
                            <CheckableTag
                                className="kosher-option-tag"
                                key={option.key}
                                checked={innerState?.accuracyResults?.foodOptions?.indexOf(option?.key) > -1}
                                onChange={checked => onKosherOptionChange(option.key, checked)}>
                                {option.label}
                            </CheckableTag>
                        );
                    })}
                </div>
            )
        }
    ];
};
