import {Modal, Radio, Form} from "antd";
import {PageTitleLabel, PageSubTitleLabel} from "../components/PageTitle";
import {CancelButton, SaveButton} from "../components/form";
import React from "react";

const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
    color: "#637381",
    fontSize: 16
};

export const FutureEventsModal = ({
    visible,
    onClose,
    onSave,
    loading,
    saveStyle,
    saveText,
    title,
    extraMessage,
    disclaimer,
    option1Text = "This event",
    option2Text = "This and following events"
}) => (
    <Modal
        className="wb-future-events-modal"
        open={visible}
        closable={true}
        onCancel={() => onClose()}
        footer={null}
        title={null}>
        <div
            style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
            }}>
            <PageTitleLabel style={{fontSize: 20, color: "#273444"}}>{title || "Edit recurring event"}</PageTitleLabel>
            {extraMessage ? (
                <PageSubTitleLabel style={{color: "#F06060", fontSize: 15, fontWeight: 700, marginBottom: 5}}>
                    {extraMessage}
                </PageSubTitleLabel>
            ) : null}
            {disclaimer ? <PageSubTitleLabel style={{fontSize: 13}}>{disclaimer}</PageSubTitleLabel> : null}
            <Form
                onFinish={onSave}
                initialValues={{
                    applyType: "only"
                }}>
                <Form.Item name="applyType">
                    <Radio.Group>
                        <Radio style={radioStyle} value={"only"}>
                            {option1Text}
                        </Radio>
                        <Radio style={radioStyle} value={"all"}>
                            {option2Text}
                        </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item noStyle>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <CancelButton onClick={onClose} />
                        <SaveButton style={{marginLeft: 10, ...saveStyle}} text={saveText} loading={loading} />
                    </div>
                </Form.Item>
            </Form>
        </div>
    </Modal>
);
