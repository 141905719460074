import React, {useCallback, useEffect, useState} from "react";
import {Form, Modal, Tooltip} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {getInputComponent} from "../InputComponent";
import PageTitleHeader from "../PageTitle";
import {SquareButton} from "../SquareButton";
import {NOOP} from "../../utils/NOOP";
import "./form-modal.css";

export const FormModal = ({
    visible,
    content,
    header,
    onClose,
    onSave,
    fields,
    subTitle,
    disables = [],
    onUpdate = NOOP,
    className = "",
    showCancelButton = false,
    cancelButtonTitle = "ביטול",
    validationFunction = null,
    modalWidth,
    confirmButtonTitle = "שמירה",
    image,
    isConfirmButtonLoading = false
}) => {
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState({});

    useEffect(() => {
        if (content && visible) {
            setFormValues(prev => ({
                ...prev,
                ...content
            }));
        }
    }, [content, visible]);

    const innerOnClose = useCallback(() => {
        form.resetFields();
        setFormValues({});
        onClose();
    }, [onClose]);

    return (
        <Modal
            centered={true}
            width={modalWidth ?? 501}
            className={`wb-modal-radius form-modal-container ${className}`}
            style={{borderRadius: "5px"}}
            destroyOnClose={true}
            open={visible}
            onCancel={innerOnClose}
            maskClosable={false}
            footer={null}
            title={null}
            closable={true}>
            <div className="form-modal">
                {!!image && <img src={image} alt="modal image" className="form-modal-image" />}
                <PageTitleHeader className="form-modal-header" showBack={false}>
                    {header}
                </PageTitleHeader>
                <div className="simple-modal-break-line" />
                {subTitle ? <p className="form-modal-sub-title">{subTitle}</p> : null}
                <Form
                    form={form}
                    onFinish={async values => {
                        if (validationFunction === null || (await validationFunction(values))) {
                            await onSave(values);
                            form.resetFields();
                            setFormValues({});
                        }
                    }}
                    fields={Object.keys(formValues).map(key => {
                        return {
                            name: [key],
                            value: formValues[key]
                        };
                    })}
                    onValuesChange={(changedField, allValues) => {
                        setFormValues(allValues);
                        onUpdate(allValues, changedField);
                    }}>
                    {fields
                        .map(input => (disables.includes(input.name) ? {...input, disabled: true} : input))
                        .map(input => (
                            <div key={input.name} className="form-modal-input-line">
                                {input.label ? (
                                    <label>
                                        {input.label}
                                        {input.info ? (
                                            <Tooltip title={input.info}>
                                                <QuestionCircleOutlined className="form-modal-input-info" />
                                            </Tooltip>
                                        ) : null}
                                    </label>
                                ) : null}
                                {getInputComponent(input, formValues)}
                            </div>
                        ))}

                    <div className="form-modal-buttons-container">
                        <SquareButton
                            className="form-modal-save-button"
                            loading={isConfirmButtonLoading}
                            htmlType="submit">
                            {confirmButtonTitle}
                        </SquareButton>

                        {showCancelButton ? (
                            <SquareButton className="form-modal-cancel-button" onClick={onClose}>
                                {cancelButtonTitle}
                            </SquareButton>
                        ) : null}
                    </div>
                </Form>
            </div>
        </Modal>
    );
};
