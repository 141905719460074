import React, {useMemo, useState} from "react";
import {OrdersCalendarContext} from "../ordersCalendar/OrdersCalendarContext";
import OrdersCalendarEventsInjector from "../ordersCalendar/OrdersCalendarEventsInjector";

const HappinessCalculatorCalendarPreview = ({tableData = []}) => {
    const [month, setMonth] = useState();
    const [orders, setOrders] = useState([]);

    const transformedTableData = useMemo(() => {
        return (
            Object.values(tableData ?? {})?.reduce((allOccasions, currentCategory) => {
                if (!currentCategory.occasions?.length) return allOccasions;

                const activeOccasions = currentCategory.occasions.filter(occasion => occasion?.isActive);

                const flattenRecurringOccasions = activeOccasions.reduce(
                    (acc, occasion) => [
                        ...acc,
                        ...[occasion.actualStartDate, ...(occasion.extraDates ?? [])].map(actualStartDate => ({
                            ...occasion,
                            actualStartDate
                        }))
                    ],
                    []
                );

                allOccasions.push(...flattenRecurringOccasions);
                return allOccasions;
            }, []) ?? []
        );
    }, [tableData]);

    return (
        <OrdersCalendarContext.Provider
            value={{
                month,
                setMonth,
                plannedOccasions: transformedTableData,
                orders,
                setOrders,
                previewMode: true
            }}>
            <OrdersCalendarEventsInjector />
        </OrdersCalendarContext.Provider>
    );
};

export default HappinessCalculatorCalendarPreview;
