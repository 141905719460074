import React, {useCallback, useContext, useEffect, useMemo} from "react";
import {ConfigProvider} from "antd";
import "./provider-product-cart.css";
import {StringBuilder} from "../../../AppUtils.js";
import {PricingCalculatorComponent} from "../../PricingCalculator/index.jsx";
import {CartModularProductContext} from "../../PricingCalculator/CartModularProductContext.jsx";
import {ProviderModularProductList} from "../../OrderFormModularProductsPanel/modularProductList/ProviderModularProductList.jsx";
import {getModularProductSubProduct} from "../../PricingCalculator/utils.js";
import {MarketplaceContext} from "../../marketplaceContext.jsx";
import {GoogleAnalytics} from "../../../GoogleAnalytics.jsx";
import {selectServiceIdFromProduct} from "../../MarketplaceHelper.jsx";
import {AppContext} from "../../../AppContext.jsx";
import {cloneDeep} from "lodash";
import {useHistory} from "react-router";
import {getLogger} from "../../../Logger.jsx";

export const ProviderProductCart = ({provider, primaryProduct, subProducts}) => {
    const {me, services} = useContext(AppContext);
    const history = useHistory();
    const {marketplaceRoot} = useContext(MarketplaceContext);
    const {onPropertyChange, setSubProducts} = useContext(CartModularProductContext);

    useEffect(() => {
        if (provider && primaryProduct) {
            const subProducts = getModularProductSubProduct(provider, primaryProduct);
            setSubProducts(subProducts);
        }
    }, [provider, primaryProduct]);

    const log = useMemo(
        () =>
            getLogger(
                {
                    providerPage: true,
                    providerId: provider.providerId,
                    primaryProduct: primaryProduct?.productName,
                    providerBusinessName: provider?.businessName
                },
                "ProviderProductCart"
            ),
        [provider, primaryProduct]
    );

    const productTitle = useMemo(() => {
        const title = new StringBuilder().append(primaryProduct?.productName);
        const minPrice = primaryProduct?.pricingTable?.byModular?.minPrice;
        return minPrice
            ? title.append("|").append("מינימום הזמנה").append(`${minPrice}₪`).toString()
            : title.toString();
    }, [primaryProduct]);

    const onBook = useCallback(() => {
        const tempProduct = cloneDeep(primaryProduct);
        tempProduct.service.services = primaryProduct.services;
        const serviceId = selectServiceIdFromProduct(me, tempProduct, services);
        GoogleAnalytics.ecommerceEvent("begin_checkout", tempProduct, serviceId);
        history.push(
            `${marketplaceRoot}/${serviceId}/providersV2/${provider.providerId}/${tempProduct.productId}/booking`
        );
    }, [me, history, marketplaceRoot, provider, primaryProduct, services]);

    return (
        <>
            {!!subProducts?.length && (
                <div className="provider-chosen-product-page">
                    <ConfigProvider direction="rtl">
                        <div className="provider-product-title-container">{productTitle}</div>
                        <div className="provider-primary-product-container">
                            <ProviderModularProductList
                                subProducts={subProducts}
                                onPropertyChange={onPropertyChange}
                                showProviderSubProductsImages={provider?.showProviderSubProductsImages}
                            />
                            <div>
                                <div className="product-cart-container">
                                    <PricingCalculatorComponent
                                        product={primaryProduct}
                                        onBook={onBook}
                                        PriceCalculatorHeader="הזמנה שלי"
                                        showModularImages={provider?.showProviderSubProductsImages}
                                        log={log}
                                    />
                                    <div>* ההזמנה והתמחור כפופים לאישור סופי של הספק</div>
                                </div>
                            </div>
                        </div>
                    </ConfigProvider>
                </div>
            )}
        </>
    );
};
