import React from "react";
import {PlusCircleOutlined} from "@ant-design/icons";

export const EXCenterAddBranchCard = ({onNewBranchClick}) => {
    const addBranch = e => {
        onNewBranchClick();
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div
            onClick={addBranch}
            className="wb-ss-branch-card"
            style={{
                width: 360,
                height: 360,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                border: "1px dashed #1461D2",
                margin: 10,
                overflow: "hidden",
                cursor: "pointer",
                boxSizing: "border-box"
            }}>
            <PlusCircleOutlined style={{fontSize: 34, color: "#005EDA", cursor: "inherit"}} />
            <div
                style={{
                    textAlign: "center",
                    fontSize: 20,
                    fontWeight: 300,
                    color: "#005EDA",
                    marginTop: 15,
                    cursor: "inherit"
                }}>
                Add location
            </div>
        </div>
    );
};
