import {LocationLabels} from "../../data/locations";

export class EXCenterInfoItem {
    constructor(item) {
        this.item = item;
    }

    get originalItem() {
        return this.item;
    }

    get itemId() {
        return this.item.eventId;
    }

    get sectionId() {
        return this.item.sectionId;
    }

    get sectionName() {
        return this.item.serviceName;
    }

    get coverPhoto() {
        return this.item.productCoverPhoto;
    }

    get title() {
        return this.item.sessionName;
    }

    get subTitle() {
        return this._isEnabled("productName") ? this.item.productName : null;
    }

    get description() {
        return this._isEnabled("productDescription") ? this.item.productDescription : null;
    }

    get location() {
        if (this._isEnabled("location")) {
            return this._isOnline() ? {link: this.item.meetingLink} : {address: this.item.address};
        } else {
            return null;
        }
    }

    get button() {
        return this._isEnabled("actionText") ? {text: this.item.actionText, link: this.item.actionLink} : null;
    }

    get providerType() {
        return this.item.sessionType;
    }

    get provider() {
        return this._isEnabled("provider")
            ? {
                  name: `${this.item.providerFirstName}${
                      this.item.providerLastName ? ` ${this.item.providerLastName}` : ""
                  }`,
                  email: this.item.providerEmail
              }
            : null;
    }

    get categories() {
        return this.item.tags.map(({name}) => name);
    }

    hasAdditionalContent() {
        return (
            !!this.button ||
            !!this.subTitle ||
            !!this.description ||
            !!this.location ||
            this.providerType === "provider"
        );
    }

    _isOnline() {
        return this.item.location === LocationLabels.ONLINE;
    }

    _isEnabled(fieldName) {
        return this.item.viewState && this.item.viewState[fieldName] && this.item.viewState[fieldName].enabled;
    }
}
