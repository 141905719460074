import {StringBuilder} from "../../AppUtils.js";
import {TickerNotification} from "../../ordersCalendar/OrderTicker/index.jsx";
import {useListenToSocket} from "../../components/socket/useListenToSocket.jsx";
import {useState} from "react";
import {NOOP} from "../../utils/NOOP.jsx";

export const AdminAlertsManagement = () => {
    const [isSocketError, setIsSocketError] = useState(null);

    useListenToSocket({eventName: "connect_error", onUpdate: () => setIsSocketError(true)});
    useListenToSocket({eventName: "connect", onUpdate: () => setIsSocketError(false)});

    return (
        <>
            <div>
                <TickerNotification
                    contentString={new StringBuilder("שרת הסוקטים")
                        .append(isSocketError ? "לא פעיל" : "פעיל")
                        .toString()}
                    backgroundColor={isSocketError ? "red" : "green"}
                    onClose={NOOP}
                />
            </div>
        </>
    );
};
