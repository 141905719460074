import React, {useContext, useMemo, useState} from "react";
import {Card} from "antd";
import moment from "moment";
import {isEmpty} from "lodash";
import {EventDetailsModal} from "../EventDetailsModal.jsx";
import {PaymentTypeToName} from "../../consts.js";
import "../order-information-card.css";
import {OrderInformationBillSection} from "./OrderInformationBilling/OrderInformationBillSection.jsx";
import {OrderInformationCardHeader} from "./OrderInformationCardHeader.jsx";
import {shouldShowCancelReason} from "./utils.jsx";
import {BundleOrderInformationView, CancelReasonSection, PaymentInfo} from "./OrderInformationCardHelper.jsx";
import {OrderInformationView} from "./OrderInformationView.jsx";
import {NOOP} from "../../utils/NOOP.jsx";

export const OrderInformationCard = ({
    title,
    eventInfo,
    additionalInfo = [],
    children,
    contactsInfo,
    userType,
    actions = [],
    fetchOrder = NOOP
}) => {
    const [eventDetailsModalVisibility, setEventDetailsModalVisibility] = useState(false);

    const canceledEvent = useMemo(
        () => (eventInfo?.orders?.length ? eventInfo.orders.find(event => !!event.cancelReason) : eventInfo),
        [eventInfo]
    );

    return (
        <>
            <EventDetailsModal
                onClose={() => setEventDetailsModalVisibility(false)}
                visible={eventDetailsModalVisibility}
                event={eventInfo}
            />
            <Card
                className="order-information-card-container"
                bordered={false}
                cover={
                    <div className="order-information-card">
                        <OrderInformationCardHeader title={title} eventInfo={eventInfo} actions={actions} />

                        <div className="order-information-card-break-line" />

                        {!isEmpty(eventInfo) ? (
                            <div className="order-information-card-section">
                                {eventInfo.orders?.length ? (
                                    <BundleOrderInformationView
                                        eventInfo={eventInfo}
                                        userType={userType}
                                        contactsInfo={contactsInfo}
                                    />
                                ) : (
                                    <OrderInformationView
                                        eventInfo={eventInfo}
                                        userType={userType}
                                        contactsInfo={contactsInfo}
                                        fetchOrder={fetchOrder}
                                    />
                                )}

                                {eventInfo.paymentType ? (
                                    <PaymentInfo paymentType={PaymentTypeToName[eventInfo.paymentType]} />
                                ) : null}
                            </div>
                        ) : null}

                        <OrderInformationBillSection eventInfo={eventInfo} additionalInfo={additionalInfo} />

                        {!!shouldShowCancelReason(eventInfo) && (
                            <CancelReasonSection reason={canceledEvent.cancelReason} />
                        )}
                        {children}
                    </div>
                }
            />
        </>
    );
};
