import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {BackTop, ConfigProvider} from "antd";
import {uniq} from "lodash";
import EventsGallery from "./EventsGallery";
import {AppContext} from "../../AppContext";
import {useRequest} from "../../utils/hooks";
import {getLogger} from "../../Logger";
import "./events-gallery.css";
// import {pageSize} from "../../consts";

const EventsGalleryPage = ({history}) => {
    const {me} = useContext(AppContext);
    const pageRef = useRef();
    const [productRound, setProductRound] = useState(1);
    const [imagesFromServer = []] = useRequest(`/api/orderImages/products/${productRound}`);
    const [likedImagesFromServer = []] = useRequest("/api/like/mylikes/orderImage");
    const [stats] = useRequest("/admin/api/orderImages/total", "GET", null, [], !!me.features.admin_debug);

    const [allImages, setAllImages] = useState([]);
    const [likedImages, setLikeImages] = useState([]);

    const productIds = useMemo(() => uniq(allImages?.map(({productId}) => productId)), [allImages]);

    const log = useMemo(() => getLogger({}, "EventsGallery"), []);

    const [products] = useRequest(
        "/api/products/byIds",
        "POST",
        {
            productIds,
            pickedFields: ["brandName", "productName", "services", "provider.businessName"],
            includeNotApprove: true
        },
        [productIds.length],
        !!allImages?.length
    );

    useEffect(() => {
        if (imagesFromServer?.length) {
            setAllImages(prev => [...prev, ...imagesFromServer]);
        }
    }, [imagesFromServer, productRound]);

    useEffect(() => {
        if (likedImagesFromServer) setLikeImages(likedImagesFromServer);
    }, [likedImagesFromServer]);

    const onLastImageReached = useCallback(() => {
        if (imagesFromServer?.length) {
            setProductRound(prev => prev + 1);
        } else {
            setAllImages(prev => [...prev, ...prev]);
        }
    }, [imagesFromServer]);

    return (
        <div ref={pageRef} className="events-gallery">
            <ConfigProvider direction="rtl">
                <BackTop />

                <div className="events-gallery-page-title">
                    כל הטוב שלקוחותינו הביאו לעובדים פינטרסט סטייל 😍
                    {stats ? (
                        <div>
                            <div>{`מספר תמונות שטענו עד היום: ${stats.totalImages}`}</div>
                            <div>{`מספר המוצרים שטענו להם תמונות עד היום: ${stats.totalProducts}`}</div>
                        </div>
                    ) : null}
                </div>
                <EventsGallery
                    allImages={allImages}
                    likedImages={likedImages}
                    setLikeImages={setLikeImages}
                    history={history}
                    products={products}
                    log={log}
                    onLastImageReached={onLastImageReached}
                />
            </ConfigProvider>
        </div>
    );
};

export default EventsGalleryPage;
