import React from "react";

export const Areas = ["area1", "area2", "area3", "area4"];

export const ActivityTypes = ["Lecture", "Workshop", "Class", "Trip"];

export const ActivityGroupSize = ["1", "2-5", "10", "10+", "Any group size"];

export const ActivityThemes = [
    "Sports",
    "Yoga",
    "Pilates",
    "Music",
    "Art",
    "Dancing",
    "Kids",
    "Volunteer",
    "Nutrition",
    "Professional development"
];

export const ActivityLocation = [
    {
        value: "online",
        title: "Online"
    },
    {
        value: "office",
        title: "Company's Office"
    },
    {
        value: "offsite",
        title: "Offsite location"
    }
];

export const Gender = [
    {
        value: "female",
        title: "Female"
    },
    {
        value: "male",
        title: "Male"
    },
    {
        value: "generic",
        title: "None of them"
    }
];

export const Years = ["1 - 2 Years", "2 - 3 Years", "3 - 5 Years", "6 - 8 Years", "8 - 10 Years", "10+ Years"];

export const DealerType = ["Licensed dealer (עוסק מורשה)", "Exempt dealer (עוסק פטור)", "LLC (חברה בע״מ)"];

export const ActivityCities = [
    "Jerusalem",
    "Safet",
    "Kinneret",
    "Yizreel",
    "Acre",
    "Golan",
    "Haifa",
    "Krayot",
    "Hadera",
    "HaSharon",
    "Petach Tikva",
    "Ramla",
    "Rehovot",
    "Tel Aviv - North",
    "Tel Aviv - South",
    "Tel Aviv - Center",
    "Ashkelon",
    "Beer Sheva",
    "Kiryat Gat",
    "West Bank"
];
