import React, {useState, useContext, useEffect} from "react";
import {PageTitleLabel, PageSubTitleLabel} from "../components/PageTitle";
import {DatePicker, Form, Radio, Select, Input, message, Tooltip, Spin, Button} from "antd";
import {DaysMapper} from "./DaysMapper";
import {CancelButton, FormInput, FormTextArea, SaveButton} from "../components/form";
import {CustomTimePicker} from "../components/CustomTimePicker";
import moment from "moment";
import {dateFormat, timeFormat} from "../utils/DateFormat";
import {HttpClient} from "../http/HttpClient";
import {FrequencyMapper} from "./FrequencyMapper";
import {SYSTEM_DEFAULT_START_TIME} from "../data/defaults";
import {LocationLabels} from "../data/locations";
import {eventByDate} from "./EventByDate";
import {AutoDirectionProviderWithValueChange} from "../AutoDirectionProvider";
import {AppContext} from "../AppContext";
import {RRuleSetBuilder} from "./RRuleSetBuilder";
import {AreYouSureModal} from "../components/AreYouSureModal";
import {eventDateToUtcTimestamp} from "./eventDateToUtc";
import {EventLocationSelect} from "../components/LocationSelect";
import {DurationInput} from "../components/DurationInput";
import {PageLoader} from "../components/PageLoader";

const {Option} = Select;

export const EventEditView = ({event, currentStart, currentEnd, onClose}) => {
    const {theme, me, company} = useContext(AppContext);
    const today = moment(dateFormat(currentStart), "YYYY-M-D");
    event = eventByDate(event, currentStart);

    const [editMode, setEditMode] = useState("occurrence");
    const [firstPastDate, setFirstPastDate] = useState(null);
    const [location, setLocation] = useState(event.location);
    const [saving, setSaving] = useState(false);
    const [editDetails, setEditDetails] = useState(null);
    let [appointments, setAppointments] = useState(null);
    let [provider, setProvider] = useState(null);

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const {firstPastDate} = await fetchFirstPastDate();
                setFirstPastDate(firstPastDate || -1);

                if (event.appointmentBased) {
                    const appts = await fetchAppointments(true);
                    setAppointments(appts);
                }
                if (!event.customSession) {
                    const providerInfo = await fetchProviderInfo();
                    setProvider(providerInfo);
                }
            } catch (e) {
                message.error("Oopssy... something went wrong, please try again later...");
            }
        });
    }, []);

    const saveChanges = async details => {
        setSaving(true);
        let employeesAreRegistered = false;
        if (!Array.isArray(appointments)) {
            appointments = await fetchAppointments();
        }

        if (event.appointmentBased) {
            if (editMode === "recurring") {
                const dateMom = moment(dateFormat(currentStart), "YYYY-M-D");
                employeesAreRegistered = appointments.some(appt =>
                    moment(appt.date, "YYYY-MM-DD").isSameOrAfter(dateMom)
                );
            } else {
                const dateMom = moment(dateFormat(currentStart), "YYYY-M-D");
                employeesAreRegistered = appointments.some(appt => moment(appt.date, "YYYY-MM-DD").isSame(dateMom));
            }
        }

        if (employeesAreRegistered) {
            setEditDetails(details);
        } else {
            await sendChanges(details);
        }

        setSaving(false);
    };

    const fetchAppointments = async (throwError = false) => {
        try {
            return await HttpClient.get(`/api/events/${event.eventId}/appointments`);
        } catch (e) {
            if (throwError) {
                throw e;
            } else {
                message.error("Oopssy... something went wrong, please try again later...");
            }
        }
    };

    const fetchFirstPastDate = async () => {
        const datetime = eventDateToUtcTimestamp(currentStart);
        return await HttpClient.get(`/api/events/${event.eventId}/past/${datetime}`);
    };

    const fetchProviderInfo = async () => {
        return await HttpClient.get(`/api/providers/${event.providerId}`);
    };

    const sendChanges = async ({
        address,
        notes,
        startDate,
        freq,
        byweekday,
        whoPays,
        startTime,
        duration,
        location,
        meetingLink
    }) => {
        setEditDetails(null);
        setSaving(true);
        try {
            startTime = startTime || moment(SYSTEM_DEFAULT_START_TIME, "HH:mm");
            const dtstart = moment(`${startDate.format("YYYY-MM-DD")} ${startTime.format("HH:mm")}`, "YYYY-MM-DD HH:mm")
                .toDate()
                .getTime();
            let updatedEvent = event;
            let applyType = "only";
            const frequency = freq;
            if (editMode === "recurring") {
                const day = startDate.format("dddd");
                const weekday = [DaysMapper.mapDayToKey(day)];
                const {byweekday, freq, interval, until} = FrequencyMapper.map({
                    freq: frequency,
                    interval: event.interval,
                    byweekday: weekday,
                    dtstart,
                    tzid: event.tzid,
                    duration: duration || event.duration
                });
                const {originalEvent, newEvent} = await sendRecurringUpdate(event, {
                    dtstart,
                    freq,
                    byweekday,
                    interval,
                    until,
                    address,
                    notes,
                    whoPays,
                    duration: duration || event.duration,
                    location,
                    meetingLink
                });
                applyType = "all";
                onClose({originalEvent, newEvent}, applyType);
            } else {
                updatedEvent = await sendOccurrenceUpdate(event, {
                    dtstart,
                    address,
                    notes,
                    whoPays,
                    duration: duration || event.duration,
                    location,
                    meetingLink
                });
                applyType = "only";
                onClose(updatedEvent, applyType);
            }
        } catch (e) {
            message.error("Opps... something went wrong, please try again later.", 3);
        }
        setSaving(false);
    };

    const sendRecurringUpdate = async (
        event,
        {dtstart, freq, byweekday, interval, until, address, notes, whoPays, duration, location, meetingLink}
    ) => {
        const newUntil = moment(`${dateFormat(currentStart)} 00:00`, "YYYY-M-D HH:mm")
            .toDate()
            .getTime();
        const newRecurring = {
            dtstart,
            freq,
            byweekday,
            interval,
            until,
            duration,
            tzid: event.tzid
        };

        return await HttpClient.post(`/api/events/${event.eventId}/recurring`, {
            ...event,
            address,
            notes,
            whoPays,
            duration,
            location,
            meetingLink,
            newUntil,
            ...newRecurring
        });
    };

    const sendOccurrenceUpdate = async (event, {dtstart, address, notes, whoPays, duration, location, meetingLink}) => {
        const excludeDate = moment(`${dateFormat(currentStart)} ${timeFormat(currentStart)}`, "YYYY-M-D HH:mm")
            .toDate()
            .getTime();
        const includeDate = dtstart;

        const updatedEvent = await HttpClient.post(`/api/events/${event.eventId}/occurrence`, {
            ...event,
            address,
            notes,
            whoPays,
            duration,
            location,
            meetingLink,
            excludeDate,
            includeDate
        });

        return {
            ...updatedEvent,
            includeDate,
            excludeDate
        };
    };

    const freq = () => {
        return FrequencyMapper.toFreqString(event);
    };

    const isSingularEvent = () => {
        return (event.includeDates || []).some(
            datetime => dateFormat(currentStart) === moment(datetime).format("YYYY-M-D")
        );
    };

    const isTheNextOccurrence = () => {
        const rruleSet = RRuleSetBuilder.build(event);
        const nextOccurrence = rruleSet.after(new Date(), true);
        return (
            moment(nextOccurrence).format("YYYY-M-D") ===
            moment(dateFormat(currentStart), "YYYY-M-D").format("YYYY-M-D")
        );
    };

    const shouldShowEditModePicker = () => {
        return freq() !== "once" && !isSingularEvent() && isTheNextOccurrence();
    };

    const EditTooltip = ({children}) =>
        shouldShowEditModePicker() ? (
            <div style={{display: "flex", width: "100%"}}>{children}</div>
        ) : (
            <Tooltip title="You are only allowed to change a recurring event if this is the up coming recurring event.">
                {children}
            </Tooltip>
        );

    const isDateDisabled = currentDate => {
        return (
            moment(currentDate).isBefore(moment(Date.now()).subtract(1, "days")) ||
            (editMode === "recurring" && firstPastDate > 0
                ? moment(currentDate).isBefore(moment(firstPastDate))
                : false)
        );
    };

    if (firstPastDate === null) return <PageLoader align="flex-start" top={80} height={600} />;

    return (
        <div
            style={{
                display: "flex",
                justifyItems: "center",
                flexDirection: "column",
                minWidth: 320,
                maxWidth: 400,
                marginTop: 40
            }}>
            <AreYouSureModal
                visible={!!editDetails}
                onClose={() => setEditDetails(null)}
                loading={saving}
                title="Are you sure about this change?"
                subTitle={
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div style={{color: "#F06060", fontWeight: 700}}>
                            Attention: your employees have booked appointments for this event.
                        </div>
                        <div>Don't worry, the relevant employees will be notified regarding the cancellation.</div>
                    </div>
                }
                saveStyle={{color: "white", backgroundColor: "#F06060"}}
                onYes={async () => await sendChanges(editDetails)}
                onNo={() => setEditDetails(null)}
            />
            {freq() !== "once" ? (
                <EditTooltip>
                    <Radio.Group
                        disabled={!shouldShowEditModePicker()}
                        onChange={e => setEditMode(e.target.value)}
                        style={{width: "100%"}}
                        defaultValue="occurrence"
                        value={editMode}
                        size="large">
                        <Radio.Button
                            style={{
                                textAlign: "center",
                                width: "50%",
                                borderBottomLeftRadius: 5,
                                borderTopLeftRadius: 5
                            }}
                            value="occurrence">
                            Edit this event
                        </Radio.Button>
                        <Radio.Button
                            style={{
                                textAlign: "center",
                                width: "50%",
                                borderBottomRightRadius: 5,
                                borderTopRightRadius: 5
                            }}
                            value="recurring">
                            Edit recurring
                        </Radio.Button>
                    </Radio.Group>
                </EditTooltip>
            ) : null}
            <PageTitleLabel style={{marginBottom: 5, marginTop: 20}}>
                {editMode === "occurrence" ? "Edit this event only" : "Edit recurring event"}
            </PageTitleLabel>
            <PageTitleLabel style={{marginTop: 0, fontSize: 20}}>
                {`${event.serviceName || event.sessionName} ${event.sessionType === "company" ? "by" : "with"} ${
                    event.providerFirstName
                }${event.providerLastName ? ` ${event.providerLastName}` : ""}`}
            </PageTitleLabel>
            <PageSubTitleLabel>
                Please make sure to coordinate changes with your professional before hand
            </PageSubTitleLabel>
            <Form
                style={{marginTop: 10}}
                onFinish={saveChanges}
                initialValues={{
                    ...event,
                    startDate: today,
                    freq: freq(),
                    startTime: moment(currentStart)
                }}
                fields={[{name: ["location"], value: location}]}>
                <Input.Group style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                    <Form.Item
                        style={{width: editMode === "recurring" ? "50%" : "100%"}}
                        name="startDate"
                        rules={[{type: "object", required: true, message: "Please select date."}]}>
                        <DatePicker
                            inputReadOnly={true}
                            style={{width: "100%"}}
                            className="wb-date-picker"
                            format="dddd, MMMM D, YYYY"
                            showToday={false}
                            disabledDate={currentDate => isDateDisabled(currentDate)}
                            placeholder={editMode === "occurrence" ? "Date" : "Start date"}
                        />
                    </Form.Item>
                    {editMode === "recurring" ? (
                        <Form.Item
                            name="freq"
                            style={{width: "50%", marginLeft: 10}}
                            rules={[{required: true, message: "Please select repetition."}]}>
                            <Select style={{width: "100%"}} placeholder="Repetition">
                                <Option value="once">Just Once</Option>
                                <Option value="daily">Every Day</Option>
                                <Option value="weekly">Every week</Option>
                                <Option value="2-weekly">Every 2 weeks</Option>
                                <Option value="monthly">Every month</Option>
                            </Select>
                        </Form.Item>
                    ) : null}
                </Input.Group>
                {event.appointmentBased ? (
                    <Form.Item
                        name="whoPays"
                        rules={[
                            {
                                required: true,
                                message: "Please choose who pays."
                            }
                        ]}>
                        <Select style={{width: "100%"}} placeholder="Who pays?">
                            <Option value="Employees pay">Employees pay</Option>
                            <Option value="Company pays">Company pays</Option>
                        </Select>
                    </Form.Item>
                ) : (
                    <Form.Item
                        name="startTime"
                        style={{width: "100%"}}
                        rules={[{type: "object", required: true, message: "Please select start time."}]}>
                        <CustomTimePicker style={{width: "100%"}} format="HH:mm" minuteStep={15} placeholder={"Time"} />
                    </Form.Item>
                )}
                {event.customSession ? (
                    <div style={{display: "flex", marginBottom: 0}}>
                        <Form.Item
                            style={{width: "50%"}}
                            name="duration"
                            validateFirst={true}
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter duration."
                                },
                                {
                                    validator: (_, value) => {
                                        if (!isNaN(value) && value <= 0) {
                                            return Promise.reject("Please enter positive duration.");
                                        }

                                        return Promise.resolve();
                                    }
                                }
                            ]}>
                            <DurationInput />
                        </Form.Item>
                        <Form.Item
                            style={{width: "50%", marginLeft: 10}}
                            name="location"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select location."
                                }
                            ]}>
                            <EventLocationSelect
                                event={event}
                                provider={provider}
                                onSelect={value => setLocation(value)}
                                placeholder="Location"
                            />
                        </Form.Item>
                    </div>
                ) : (
                    <Form.Item
                        name="location"
                        rules={[
                            {
                                required: true,
                                message: "Please select location."
                            }
                        ]}>
                        <EventLocationSelect
                            event={event}
                            provider={provider}
                            onSelect={value => setLocation(value)}
                            placeholder="Location"
                        />
                    </Form.Item>
                )}
                {location === LocationLabels.ONSITE || location === LocationLabels.OFFSITE ? (
                    <Form.Item name="address">
                        <AutoDirectionProviderWithValueChange style={{width: "100%"}}>
                            {({onChange, defaultValue, value}) => (
                                <FormInput
                                    onChange={onChange}
                                    defaultValue={defaultValue}
                                    value={value}
                                    placeholder="Address"
                                />
                            )}
                        </AutoDirectionProviderWithValueChange>
                    </Form.Item>
                ) : (
                    <Form.Item name="meetingLink" rules={[{type: "url", message: "Please enter valid link."}]}>
                        <FormInput placeholder="Insert meeting link" />
                    </Form.Item>
                )}
                <Form.Item name="notes">
                    <AutoDirectionProviderWithValueChange style={{width: "100%"}}>
                        {({onChange, defaultValue, value}) => (
                            <FormTextArea
                                onChange={onChange}
                                defaultValue={defaultValue}
                                value={value}
                                placeholder="Write your notes..."
                                autoSize={{minRows: 4, maxRows: 4}}
                            />
                        )}
                    </AutoDirectionProviderWithValueChange>
                </Form.Item>
                <Form.Item>
                    <div style={{display: "flex", width: "100%", justifyContent: "flex-end", alignItems: "center"}}>
                        <CancelButton onClick={() => onClose(event, "no-change")} />
                        <SaveButton
                            loading={saving}
                            style={{marginLeft: 10, backgroundColor: theme.primary, color: theme.textPrimary}}
                        />
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};
