import React, {useContext, useEffect} from "react";
import {useParams} from "react-router";
import {Spin} from "antd";
import {createProductLink} from "./MarketplaceHelper";
import {useRequest} from "../utils/hooks";
import {MarketplaceContext} from "./marketplaceContext";
import {AppContext} from "../AppContext";

const CompanyMarketplaceProductDetailsRedirect = ({history}) => {
    const {productId} = useParams();
    const {me, services} = useContext(AppContext);
    const {marketplaceRoot} = useContext(MarketplaceContext);
    const [product] = useRequest(`/api/products/byId/${productId}`, "get", null, [], !!productId);

    useEffect(() => {
        if (product && services) {
            const url = createProductLink(product, {me, services, marketplaceRoot});
            history.replace(`${url}${history.location.search}`);
        }
    }, [product, history, me, services]);

    return <Spin />;
};

export default CompanyMarketplaceProductDetailsRedirect;
