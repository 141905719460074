import {Modal} from "antd";
import {PageTitleLabel} from "../../../components/PageTitle";
import React from "react";
import {StyledButton} from "../../../components/StyledButton";

export const CompanyContentTagRemove = ({tagToRemove, visible, onClose, onRemove}) => {
    return (
        <Modal
            centered={true}
            width={340}
            style={{marginBottom: 200}}
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            footer={null}
            title={null}
            closable={true}>
            <div style={{display: "flex", width: "100%", flexDirection: "column", alignItems: "center"}}>
                <PageTitleLabel style={{marginTop: 50, marginBottom: 50}}>
                    Are you sure you want to remove {tagToRemove ? tagToRemove.name : ""}?
                </PageTitleLabel>
                <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                    <StyledButton
                        htmlType="submit"
                        style={{
                            backgroundColor: "var(--secondary-color)",
                            color: "white",
                            border: "none",
                            width: 300,
                            fontSize: "20px"
                        }}
                        onClick={onRemove}>
                        Confirm
                    </StyledButton>
                </div>
            </div>
        </Modal>
    );
};
