import React, {useCallback, useEffect, useState} from "react";
import {RoundedTable} from "../RoundedTable.jsx";
import {SquareButton} from "../SquareButton.jsx";
import {DefaultReportPageSize, generateColumns} from "./consts.jsx";
import {StringBuilder} from "../../AppUtils.js";
import "./report-table.css";
import {groupFieldValidationByIndex} from "./utils.jsx";
import {NOOP} from "../../utils/NOOP.jsx";

export const ReportTable = ({
    data = [],
    columnsSettings = [],
    validationDetails = [],
    onSave = NOOP,
    pageSize = DefaultReportPageSize,
    increaseIndexNumber = 0
}) => {
    const [loadingRequest, setLoadingRequest] = useState(false);
    const [transformValidation, setTransformValidation] = useState({});
    const [errorLinesRow, setErrorLinesRow] = useState([]);

    const [pagination, setPagination] = useState({current: 1, pageSize: pageSize});

    useEffect(() => {
        if (validationDetails?.length) {
            const {validationResponse = {}, errorLines} = groupFieldValidationByIndex(validationDetails);
            setTransformValidation(validationResponse);
            setErrorLinesRow(
                Array.from(errorLines ?? {})
                    .reverse()
                    .map(index => parseInt(index) + increaseIndexNumber)
            );
        }
    }, [increaseIndexNumber, validationDetails]);

    const onFinish = useCallback(async () => {
        setLoadingRequest(true);
        await onSave();
        setLoadingRequest(false);
    }, [onSave]);

    return (
        <>
            <RoundedTable
                className="sub-product-view-table"
                dataSource={data}
                pagination={pagination}
                columns={generateColumns(columnsSettings, transformValidation, pagination)}
                onChange={pagination => setPagination(pagination)}
            />

            {!!errorLinesRow?.length && (
                <>
                    <span className="errors-container-title">ישנם שגיאות בשורות</span>
                    <div>{new StringBuilder(null, ", ").append(errorLinesRow).toString()}</div>
                </>
            )}

            <SquareButton
                className="load-admins-excel-modal-send-button"
                loading={loadingRequest}
                disabled={!!errorLinesRow.length}
                onClick={onFinish}>
                הכל נראה טוב, אפשר ליצור!
            </SquareButton>
        </>
    );
};
