import React, {useCallback, useMemo, useState} from "react";
import {message, Modal, Spin, Upload} from "antd";
import {DownloadOutlined, UploadOutlined} from "@ant-design/icons";
import {toLower} from "lodash";
import {
    SUB_ADMIN_CATEGORY_BUDGET_EXCEL_EXAMPLE_FILE_NAME,
    SUB_ADMIN_CATEGORY_BUDGET_EXCEL_EXAMPLE_FILE_URL,
    SUB_ADMIN_EXCEL_EXAMPLE_FILE_NAME,
    SUB_ADMIN_EXCEL_EXAMPLE_FILE_URL
} from "./consts";
import {downloadFromUrl} from "../../utils/downloadFromUrl";
import {getCellText, loadExcel} from "../../utils/excelUtils.js";
import {GenericModal, RoundedTable, SquareButton} from "../../components";
import {Images} from "../../images/Images";
import {MainServices, PaymentType, PaymentTypeToName, ServiceIdToName} from "../../consts.js";
import {HttpClient} from "../../http/HttpClient";
import {NOOP} from "../../utils/NOOP";
import {EventBus} from "../../bus/EventBus";
import "./load-admins-excel-modal.css";
import {normalizePhoneNumber} from "./utils.jsx";

const {pinkFolders} = Images;

const columns = [
    {
        title: "#",
        key: "#",
        width: 70,
        render(_, __, idx) {
            return `${idx + 1}`;
        }
    },
    {
        title: "מחלקה",
        dataIndex: "department"
    },
    {
        title: "שם",
        dataIndex: "firstName"
    },
    {
        title: "אימייל",
        dataIndex: "email"
    },
    {
        title: "טלפון",
        dataIndex: "phone"
    },
    {
        title: "כתובת",
        dataIndex: "address"
    },
    {
        title: "קומה",
        dataIndex: "floor"
    },
    {
        title: "תקציב",
        dataIndex: "budget"
    },
    {
        title: "אפשרויות תשלום",
        key: "allowedPaymentsMethods",
        dataIndex: "allowedPaymentsMethods",
        render(allowedPaymentsMethods) {
            return allowedPaymentsMethods?.map(paymentType => PaymentTypeToName[paymentType]).join(",");
        }
    },
    {
        title: "אפשרויות מוצרים",
        key: "allowedServices",
        dataIndex: "allowedServices",
        render(allowedServices) {
            return allowedServices?.map(serviceId => ServiceIdToName[serviceId]).join(",");
        }
    }
];

export const LoadAdminExcelModal = ({visible, companyId, onClose, loading, onFinish}) => {
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [loadingRequest, setLoadingRequest] = useState(false);
    const [subAdminsToUpload, setSubAdminsToUpload] = useState(null);
    const [isBudgetCategoryToUpload, setIsBudgetCategoryToUpload] = useState(null);

    const excelUploadProps = useMemo(
        () => ({
            customRequest: NOOP,
            action: async file => {
                return new Promise(async (resolve, reject) => {
                    setLoadingExcel(true);
                    const fileReader = new FileReader();
                    fileReader.onload = async data => {
                        const arrayBuffer = data.target.result;
                        const workbook = await loadExcel(arrayBuffer, file.type);
                        const sheet = workbook.worksheets[0];

                        const results = [];

                        sheet.eachRow(({values: row}) => {
                            results.push({
                                department: getCellText(row[1])?.toString(),
                                firstName: getCellText(row[2]),
                                email: toLower(getCellText(row[3])),
                                phone: normalizePhoneNumber(getCellText(row[4])),
                                address: getCellText(row[5]),
                                floor: getCellText(row[6]),
                                budget: getCellText(Math.round(row[7])),
                                allowedPaymentsMethods: [
                                    ...(getCellText(row[8]) === "מאופשר" ? [PaymentType.Budget] : []),
                                    ...(getCellText(row[9]) === "מאופשר" ? [PaymentType.Card] : []),
                                    ...(getCellText(row[10]) === "מאופשר" ? [PaymentType.BankTransfer] : [])
                                ],
                                allowedServices: [
                                    ...(getCellText(row[11]) === "מאופשר" ? [MainServices.HAPPY_HOURS] : []),
                                    ...(getCellText(row[12]) === "מאופשר" ? [MainServices.PACKAGES] : []),
                                    ...(getCellText(row[13]) === "מאופשר" ? [MainServices.PLATTERS] : []),
                                    ...(getCellText(row[14]) === "מאופשר" ? [MainServices.WORKSHOPS] : []),
                                    ...(getCellText(row[15]) === "מאופשר" ? [MainServices.LECTURES] : [])
                                ]
                            });
                        });

                        const cleanResults = results.splice(1).filter(({email}) => email);
                        setLoadingExcel(false);

                        setSubAdminsToUpload(cleanResults);
                        setIsBudgetCategoryToUpload(false);
                        return resolve();
                    };

                    fileReader.readAsArrayBuffer(file);
                });
            },
            name: "file",
            accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            showUploadList: false,
            withCredentials: true
        }),
        []
    );

    const excelCategoryBudgetUploadProps = useMemo(
        () => ({
            customRequest: NOOP,
            action: async file => {
                return new Promise(async (resolve, reject) => {
                    setLoadingExcel(true);
                    const fileReader = new FileReader();
                    fileReader.onload = async data => {
                        const arrayBuffer = data.target.result;
                        const workbook = await loadExcel(arrayBuffer, file.type);
                        const sheet = workbook.worksheets[0];

                        const results = [];

                        sheet.eachRow(({values: row}) => {
                            results.push({
                                department: getCellText(row[1])?.toString(),
                                firstName: getCellText(row[2]),
                                email: toLower(getCellText(row[3])),
                                phone: normalizePhoneNumber(getCellText(row[4])),
                                address: getCellText(row[5]),
                                floor: getCellText(row[6]),
                                budget: getCellText(Math.round(row[7])),
                                categoryBudgets: {
                                    [MainServices.HAPPY_HOURS]: getCellText(row[8]),
                                    [MainServices.PACKAGES]: getCellText(row[9]),
                                    [MainServices.PLATTERS]: getCellText(row[10]),
                                    [MainServices.WORKSHOPS]: getCellText(row[11])
                                },
                                allowedPaymentsMethods: [
                                    ...(getCellText(row[12]) === "מאופשר" ? [PaymentType.Budget] : []),
                                    ...(getCellText(row[13]) === "מאופשר" ? [PaymentType.Card] : []),
                                    ...(getCellText(row[14]) === "מאופשר" ? [PaymentType.BankTransfer] : [])
                                ],
                                allowedServices: [
                                    ...(getCellText(row[15]) === "מאופשר" ? [MainServices.HAPPY_HOURS] : []),
                                    ...(getCellText(row[16]) === "מאופשר" ? [MainServices.PACKAGES] : []),
                                    ...(getCellText(row[17]) === "מאופשר" ? [MainServices.PLATTERS] : []),
                                    ...(getCellText(row[18]) === "מאופשר" ? [MainServices.WORKSHOPS] : []),
                                    ...(getCellText(row[19]) === "מאופשר" ? [MainServices.LECTURES] : [])
                                ]
                            });
                        });

                        const cleanResults = results.splice(1).filter(({email}) => email);
                        setLoadingExcel(false);

                        setSubAdminsToUpload(cleanResults);
                        setIsBudgetCategoryToUpload(true);
                        return resolve();
                    };

                    fileReader.readAsArrayBuffer(file);
                });
            },
            name: "file",
            accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            showUploadList: false,
            withCredentials: true
        }),
        []
    );

    const innerOnClose = useCallback(() => {
        setSubAdminsToUpload(null);
        setIsBudgetCategoryToUpload(null);
        onClose();
    }, [onClose]);

    const validateSubAdmins = useCallback(async () => {
        const res = await HttpClient.safePost(
            `/admin/api/companies/${companyId}/validateBulkOfSubAdmins`,
            subAdminsToUpload
        );

        if (res.error) {
            EventBus.triggerError(
                "server-error",
                {content: {description: res.error, hideSteps: true}, cta: {hide: true}},
                res.error
            );
        } else {
            message.success("הכל תקין :)");
        }
    }, [companyId, subAdminsToUpload]);

    const onSave = useCallback(async () => {
        setLoadingRequest(true);
        const res = await HttpClient.safePost(`/admin/api/companies/${companyId}/loadBulkOfSubAdmins`, {
            subAdminsToUpload,
            isBudgetCategoryToUpload
        });
        setLoadingRequest(false);

        if (res.error) {
            EventBus.triggerError(
                "server-error",
                {content: {description: res.error, hideSteps: true}, cta: {hide: true}},
                res.error
            );
        } else {
            onFinish();
        }

        innerOnClose();
    }, [companyId, onFinish, subAdminsToUpload, isBudgetCategoryToUpload]);

    return (
        <>
            <GenericModal
                visible={visible}
                image={pinkFolders}
                onClose={innerOnClose}
                className={"order-location-input-upload-excel-modal"}
                options={[
                    {
                        id: "uploadExcelForHomeDelivery",
                        text: (
                            <Upload {...excelUploadProps}>
                                <span className="order-location-input-load-excel-title">
                                    {loadingExcel ? <Spin /> : <UploadOutlined style={{marginLeft: "5px"}} />}
                                    אקסל (תקציב רגיל)
                                </span>
                            </Upload>
                        )
                    },
                    {
                        id: "uploadExcelForCategoryBudget",
                        text: (
                            <Upload {...excelCategoryBudgetUploadProps}>
                                <span className="order-location-input-load-excel-title">
                                    {loadingExcel ? <Spin /> : <UploadOutlined style={{marginLeft: "5px"}} />}
                                    אקסל (תקציב מבוזר)
                                </span>
                            </Upload>
                        )
                    }
                ]}
                title="טעינת מס׳ אדמינים עם קובץ אקסל 📗"
                text={[
                    "אנא תורידו את הפורמט המצורף, תמלאו את המידע לפי העמודות",
                    <span>
                        {" "}
                        {"הרלוונטיות"} <u>{"בעברית בלבד"}</u> {" ותטענו את הרשימה למערכת"}{" "}
                    </span>,
                    <span
                        style={{cursor: "pointer", direction: "ltr", fontWeight: "bold"}}
                        onClick={() =>
                            downloadFromUrl(SUB_ADMIN_EXCEL_EXAMPLE_FILE_URL, SUB_ADMIN_EXCEL_EXAMPLE_FILE_NAME)
                        }>
                        הורדת פורמט אקסל רגיל
                        <DownloadOutlined style={{color: "#243446"}} />
                    </span>,
                    <span
                        style={{cursor: "pointer", direction: "ltr", fontWeight: "bold"}}
                        onClick={() =>
                            downloadFromUrl(
                                SUB_ADMIN_CATEGORY_BUDGET_EXCEL_EXAMPLE_FILE_URL,
                                SUB_ADMIN_CATEGORY_BUDGET_EXCEL_EXAMPLE_FILE_NAME
                            )
                        }>
                        הורדת פורמט אקסל מבוזר
                        <DownloadOutlined style={{color: "#243446"}} />
                    </span>
                ]}
            />
            <Modal
                centered={true}
                width={1000}
                className="wb-modal-radius simple-modal-container"
                style={{borderRadius: "5px"}}
                destroyOnClose={true}
                open={!!subAdminsToUpload}
                onCancel={() => setSubAdminsToUpload(null)}
                footer={null}
                title={null}
                closable={true}>
                {loadingRequest ? <Spin /> : <RoundedTable dataSource={subAdminsToUpload} columns={columns} />}
                <SquareButton
                    className="load-admins-excel-modal-validate-button"
                    loading={loadingRequest}
                    onClick={validateSubAdmins}>
                    בוא רק נוודא שהמידע תקין ליצירת אדמינים
                </SquareButton>
                <SquareButton className="load-admins-excel-modal-send-button" loading={loadingRequest} onClick={onSave}>
                    הכל נראה טוב, אפשר ליצור!
                </SquareButton>
            </Modal>
        </>
    );
};
