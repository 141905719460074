import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {debounce, noop} from "lodash";
import {AutoComplete, Button, Spin} from "antd";
import classNames from "classnames";
import {CloseOutlined} from "@ant-design/icons";
import {NOOP} from "../../utils/NOOP";
import {useRequest} from "../../utils/hooks";
import {SquareButton} from "../../components";
import ProductsFiltersModal from "../ProductsFilterModal";
import {AppContext} from "../../AppContext";
import {isObjectWithEmptyFields} from "../../utils/objectUtils";
import {AMOUNT_OF_SEARCH_RESULTS} from "../../consts.js";
import "./marketplace-search.css";

export const MarketplaceSearchInput = ({
    history = null,
    searchSelected = NOOP,
    filterValues,
    setFilterValues,
    searchInput,
    setSearchInput,
    placeholder = "חיפוש חופשי",
    debouncedSearchText,
    setDebouncedSearchText,
    filterModalSaved,
    log = NOOP,
    showSearchButton = true,
    showFiltersButton = true,
    onItemClick = ({link}) => {
        link && history && history.push(link);
    },
    onEnter = noop,
    tagClicked = noop
}) => {
    const {me} = useContext(AppContext);
    const autocompleteRef = useRef(null);
    const [extraFilters, setExtraFilters] = useState({});
    const [productFiltersModalVisible, setProductFiltersModalVisible] = useState(false);
    const [topTags] = useRequest("/api/searchTags/getTopSearchTags");

    const [searchResults, loadingSearchResults] = useRequest(
        `/api/search/${debouncedSearchText?.toLowerCase()}?amount=${AMOUNT_OF_SEARCH_RESULTS}`,
        "GET",
        null,
        [],
        !!debouncedSearchText?.length
    );

    const validateSearch = useCallback(search => {
        try {
            RegExp(search);
            setSearchInput(search);
        } catch {}
    }, []);

    const onTagClicked = useCallback(
        (e, tags) => {
            tagClicked(tags);
            searchSelected(tags.name, {tags: tags});
            setDebouncedSearchText(tags.name);
            autocompleteRef?.current.blur();
        },
        [searchSelected, autocompleteRef, tagClicked]
    );

    const renderTitle = useCallback(title => <span className="marketplace-search-render-title">{title}</span>, []);

    const renderItem = useCallback(
        (title, type, link = null, isRTL = true) => {
            let directionClass = isRTL ? "marketplace-search-render-item-rtl" : "marketplace-search-render-item-ltr";
            let boldSearchTitle = title.replace(/ /g, "\u00A0").split("");

            if (searchInput) {
                const trimmedInput = searchInput.trim();
                const searchInputIndex = title.indexOf(trimmedInput);
                if (searchInputIndex >= 0) {
                    if (isRTL) {
                        boldSearchTitle.splice(searchInputIndex, trimmedInput.length, <b key="key">{trimmedInput}</b>);
                    } else {
                        boldSearchTitle = [
                            ...boldSearchTitle.slice(searchInputIndex + trimmedInput.length),
                            <b>
                                {boldSearchTitle[searchInputIndex + trimmedInput.length] === "\u00A0" ? "\u00A0" : null}
                                {trimmedInput}
                            </b>,
                            ...boldSearchTitle.slice(0, searchInputIndex)
                        ];
                    }
                }
            }

            if (searchInput[0].match(/[a-zA-Z]/)) {
                directionClass = "marketplace-search-render-item-ltr";
            }

            return {
                value: title,
                label: (
                    <div
                        onClick={e => {
                            link && e.stopPropagation();
                            log("Search option selected", {text: title, type});
                            onItemClick({link, text: title});
                        }}
                        className={classNames("marketplace-search-render-item", directionClass)}>
                        {boldSearchTitle}
                    </div>
                )
            };
        },
        [searchInput, history]
    );

    const mergedSearchTags = useMemo(() => {
        const searchTags = searchInput && searchResults?.searchTags ? searchResults?.searchTags : topTags;

        if (!searchTags?.length) {
            return null;
        }

        const mergedTags = searchTags.reduce((acc, tag) => {
            if (acc[tag.name]) {
                acc[tag.name].tagIds.push(tag.tagId);
            } else {
                acc[tag.name] = {tagIds: [tag.tagId], name: tag.name};
            }

            return acc;
        }, {});

        return mergedTags;
    }, [searchInput, searchResults, topTags]);

    const tagsOptionsPanel = useMemo(() => {
        if (!mergedSearchTags) {
            return null;
        }

        return (
            <>
                <div className="simple-modal-break-line" />
                <div className="marketplace-search-tags-panel">
                    {Object.values(mergedSearchTags).map(tag => (
                        <span key={tag.name} onClick={e => onTagClicked(e, tag)}>
                            {tag.name}
                        </span>
                    ))}
                </div>
            </>
        );
    }, [mergedSearchTags, onTagClicked]);

    const searchOptions = useMemo(() => {
        if (loadingSearchResults) {
            return [{label: <Spin className="marketplace-search-loading" />, options: []}];
        }

        const searchOptions = [];

        if (searchResults && searchInput) {
            const {providers, products} = searchResults;

            if (products?.length) {
                searchOptions.push({
                    label: renderTitle("מוצרים"),
                    options: products.map(({productName, productId, providerId, category}) =>
                        renderItem(
                            productName,
                            "product",
                            `/dashboard/services/${category}/providersV2/${providerId}/${productId}`
                        )
                    )
                });
            }

            if (providers?.length) {
                searchOptions.push({
                    label: renderTitle("שמות שותפים"),
                    options: providers.map(({providerName}) => renderItem(providerName, "provider"))
                });
            }
        }

        if (tagsOptionsPanel) {
            const tagsOptions = {
                label: tagsOptionsPanel,
                options: []
            };

            searchOptions.push(tagsOptions);
        }

        return searchOptions;
    }, [searchResults, searchInput, tagsOptionsPanel, loadingSearchResults]);

    const onClickFiltersButton = useCallback(() => {
        log("Filters Button Clicked");
        setProductFiltersModalVisible(true);
    }, [log]);

    const onSearchKeyDown = useCallback(
        e => {
            if (e.key === "Enter") {
                onEnter(e);
                textualSearch();
            }
        },
        [searchSelected, searchOptions, searchInput]
    );

    const textualSearch = useCallback(() => {
        if (!searchInput) return;
        const tags = mergedSearchTags?.[searchInput];
        searchSelected(searchInput, {tags});
    }, [mergedSearchTags, searchInput, searchSelected]);

    const searchForTags = useCallback(
        debounce(searchText => {
            if (searchText) log("Text Searched", {searchText});
            setDebouncedSearchText(searchText);
        }, 200),
        [log]
    );

    useEffect(() => {
        searchForTags(searchInput);
    }, [searchForTags, searchInput]);

    const filterValuesWithoutSearchAndTags = useMemo(() => {
        const {searchTags, searchText, ...restFilters} = filterValues ?? {};
        return restFilters;
    }, [filterValues]);

    return (
        <div className="marketplace-search-container">
            <ProductsFiltersModal
                filters={filterValues}
                setFilters={setFilterValues}
                onClose={() => setProductFiltersModalVisible(false)}
                onCancel={() => {
                    setExtraFilters({});
                    setProductFiltersModalVisible(false);
                }}
                onSave={() => {
                    setProductFiltersModalVisible(false);

                    const relevantFilters = Object.entries(extraFilters).reduce((acc, [key, value]) => {
                        if (Array.isArray(value) && !value.length) {
                            return acc;
                        }

                        if (value === "" || value === null) {
                            return acc;
                        }

                        acc[key] = value;
                        return acc;
                    }, {});

                    filterModalSaved(relevantFilters);
                }}
                visible={productFiltersModalVisible}
                onFiltersChange={setExtraFilters}
                foundProductsCount={null}
            />
            <AutoComplete
                className="marketplace-search-autocomplete"
                allowClear
                showSearch={true}
                ref={autocompleteRef}
                options={searchOptions}
                onSelect={searchSelected}
                popupClassName="marketplace-search-dropdown-menu"
                showArrow={false}
                listHeight={600}
                dropdownMatchSelectWidth={400}
                placeholder={placeholder}
                onClear={() => {
                    setFilterValues(currentValues => ({...currentValues, searchText: null, searchTags: null}));
                }}
                onSearch={validateSearch}
                onKeyDown={onSearchKeyDown}
                searchValue={searchInput}
                value={searchInput ?? filterValues?.searchText}
            />
            {showSearchButton && (
                <Button onClick={textualSearch} className="marketplace-search-button">
                    חיפוש
                </Button>
            )}
            {showFiltersButton && me?.features?.filters_modal ? (
                <SquareButton
                    onClick={onClickFiltersButton}
                    className={classNames("marketplace-search-filter-button", {
                        "filters-active": !isObjectWithEmptyFields(filterValuesWithoutSearchAndTags)
                    })}>
                    <span>מסננים</span>

                    {!isObjectWithEmptyFields(filterValuesWithoutSearchAndTags) ? (
                        <div>
                            <CloseOutlined
                                className="marketplace-search-filter-close-button"
                                onClick={e => {
                                    setFilterValues({});
                                    setSearchInput("");
                                    e.stopPropagation();
                                }}
                            />
                        </div>
                    ) : null}
                </SquareButton>
            ) : null}
        </div>
    );
};
