import React, {useCallback, useContext, useMemo} from "react";
import moment from "moment";
import {Collapse, ConfigProvider, Progress} from "antd";
import {sumBy} from "lodash";
import classNames from "classnames";
import {Frequency} from "../consts";
import {HappinessCalculatorContext} from "../HappinessCalculatorContext";
import {getCategoryPolicyName} from "../calculatorStepper/consts";
import {Images} from "../../images/Images";
import {momentHebrewFormat} from "../../utils/DateFormat";
import "./happiness-calculator-summary-page.css";

const {Panel} = Collapse;
const {CalculatorBG} = Images;

export const HappinessCalculatorSummaryPage = ({
    timeRanges,
    employeesAmount,
    allCategoriesPrice,
    categoriesWithTotalPrice,
    monthAmounts
}) => {
    const {planOccasionsCategories} = useContext(HappinessCalculatorContext);

    const activeCategoriesWithTotalPrice = useMemo(() => {
        return categoriesWithTotalPrice.map(category => {
            const activeOccasions = category.occasions.filter(occasion => occasion.isActive);
            return {...category, occasions: activeOccasions};
        });
    }, [categoriesWithTotalPrice]);

    const getCategoryFrequency = useCallback(
        categoryOccasionsAmount =>
            Object.values(Frequency).reduce(
                (acc, frequency) => {
                    if (frequency.ratio > acc.ratio) {
                        const amountInFrequency = categoryOccasionsAmount / monthAmounts / frequency.ratio;

                        if (amountInFrequency >= 1) {
                            return {
                                value: `${amountInFrequency.toLocaleString(undefined, {maximumFractionDigits: 1})} ב${
                                    frequency.name
                                }`,
                                ratio: frequency.ratio
                            };
                        }
                    }

                    return acc;
                },
                {ratio: 0}
            ),
        []
    );

    return (
        <div className="happiness-calculator-summary-page" style={{backgroundImage: `url(${CalculatorBG})`}}>
            <ConfigProvider direction="rtl">
                <div className="happiness-calculator-summary-page-container">
                    <div className="happiness-calculator-summary-page-sum-container">
                        <span>סיכום תכנון</span>
                        <span>{allCategoriesPrice.toLocaleString("en-GB", {maximumFractionDigits: 1})}₪</span>
                    </div>

                    <Collapse expandIconPosition="left" defaultActiveKey={["2", "3"]}>
                        <Panel
                            className={classNames("happiness-calculator-summary-page-panel")}
                            header={"תקציר מנהלים"}
                            key={"1"}>
                            <div className="happiness-calculator-summary-page-panel-admin-container">
                                <div className="happiness-calculator-summary-page-panel-admin-item">
                                    <span>תקופת התוכנית</span>
                                    <span>{`${momentHebrewFormat(timeRanges[0], "MMMM YYYY")} - ${momentHebrewFormat(
                                        timeRanges[1],
                                        "MMMM YYYY"
                                    )}`}</span>
                                </div>
                                <div className="happiness-calculator-summary-page-panel-admin-item">
                                    <span>מס׳ עובדים</span>
                                    <span>{employeesAmount}</span>
                                </div>
                                <div className="happiness-calculator-summary-page-panel-admin-item">
                                    <span>סעיפי תקציב של התוכנית</span>
                                    <span> {`${planOccasionsCategories.length} סעיפי תקציב`}</span>
                                </div>
                                <div className="happiness-calculator-summary-page-panel-admin-item">
                                    <span>עלות כוללת לתוכנית</span>
                                    <span>
                                        {allCategoriesPrice.toLocaleString("en-GB", {maximumFractionDigits: 1})} ₪
                                    </span>
                                </div>
                                <div className="happiness-calculator-summary-page-panel-admin-item">
                                    <span>עלות ממוצעת לעובד</span>
                                    <span>{`${(
                                        sumBy(categoriesWithTotalPrice, "pricePerEmployee") / monthAmounts
                                    )?.toLocaleString(undefined, {maximumFractionDigits: 2})} ₪`}</span>
                                </div>
                                <div className="happiness-calculator-summary-page-panel-admin-item">
                                    <span>מס׳ נגיעות בעובד בטווח התוכנית</span>
                                    <span>{`${sumBy(categoriesWithTotalPrice, "occasionsCount")?.toLocaleString(
                                        undefined,
                                        {maximumFractionDigits: 2}
                                    )}`}</span>
                                </div>
                            </div>
                        </Panel>

                        <Panel
                            className={classNames("happiness-calculator-summary-page-panel")}
                            header={"פירוט נגיעות בעובד"}
                            key={"2"}>
                            {activeCategoriesWithTotalPrice.map(category => {
                                return (
                                    <div className="happiness-calculator-summary-page-employee-container">
                                        <div
                                            className="happiness-calculator-summary-page-employee-category-container"
                                            style={{
                                                backgroundColor: category.color
                                            }}>
                                            <div>
                                                <span>{category.categoryName}</span>
                                                <span>
                                                    {category.totalPrice.toLocaleString(undefined, {
                                                        maximumFractionDigits: 1
                                                    })}
                                                    ₪
                                                </span>
                                            </div>
                                            <div className="happiness-calculator-summary-page-line" />
                                            <div className="happiness-calculator-summary-page-employee-summary-items">
                                                <div>
                                                    <span>מס׳ אירועים בטווח</span>
                                                    <span>
                                                        {category.occasionsCount.toLocaleString(undefined, {
                                                            maximumFractionDigits: 1
                                                        })}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span>עלות ממוצעת לאירוע</span>
                                                    <span>{`${(
                                                        category.totalPrice / category.occasions.length
                                                    )?.toLocaleString(undefined, {maximumFractionDigits: 2})} ₪`}</span>
                                                </div>
                                                <div>
                                                    <span>עלות ממוצעת לעובד</span>
                                                    <span>{`${(
                                                        category.pricePerEmployee / monthAmounts
                                                    )?.toLocaleString(undefined, {maximumFractionDigits: 2})} ₪`}</span>
                                                </div>
                                                <div>
                                                    <span>ממוצע אירועים בטווח</span>
                                                    <span>{getCategoryFrequency(category.occasionsCount).value}</span>
                                                </div>
                                                <div>
                                                    <span>סוג ההגדרה של התקציב</span>
                                                    <span>{getCategoryPolicyName(category.isRangePolicy)}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="happiness-calculator-summary-page-employee-event-container">
                                            {category.occasions.map(occasion => {
                                                return (
                                                    <div key={occasion.occasionId}>
                                                        <div className="happiness-calculator-summary-page-employee-event-content">
                                                            {category.isRangePolicy ? (
                                                                <>
                                                                    <span>{category.occasionsCount}</span>
                                                                    <span> </span>
                                                                    <span>{category.categoryName}</span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span>{occasion.name}</span>
                                                                    <span> </span>
                                                                    <span>{occasion.icon}</span>
                                                                </>
                                                            )}

                                                            {occasion.startDate && (
                                                                <>
                                                                    <span>|</span>
                                                                    <span>
                                                                        {moment(occasion.startDate).format(
                                                                            "DD/MM/YYYY"
                                                                        )}
                                                                    </span>
                                                                </>
                                                            )}
                                                        </div>

                                                        <div className="happiness-calculator-summary-page-line-dashed" />

                                                        <div className="happiness-calculator-summary-page-employee-event-content">
                                                            <span>{occasion.price}</span>
                                                            <span> </span>
                                                            <span>₪</span>
                                                            <span> </span>
                                                            <span>
                                                                {occasion.priceType === "byGroup" ? "לקבוצה" : "למשתתף"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </Panel>

                        <Panel
                            className={classNames("happiness-calculator-summary-page-panel")}
                            header={"השוואה סקטוריאלית"}
                            key={"3"}>
                            {activeCategoriesWithTotalPrice.map(category => {
                                return (
                                    <div className="happiness-calculator-summary-page-sector-container">
                                        <div>
                                            <div
                                                className="happiness-calculator-summary-page-sector-bold-text"
                                                style={{
                                                    color: category.color
                                                }}>
                                                <span>{category.occasionsCount}</span>
                                                <span> </span>
                                                <span>מועדי</span>
                                                <span> </span>
                                                <span>{category.categoryName}</span>
                                                <span> </span>
                                                <span>|</span>
                                                <span> </span>
                                                <span>
                                                    {category.totalPrice.toLocaleString(undefined, {
                                                        maximumFractionDigits: 1
                                                    })}{" "}
                                                    ₪
                                                </span>
                                            </div>
                                            <span>הארגון הממוצע בסקטור שלכם משקיע XXX$ בסעיף זה בטווח הזמן שבחרתם</span>
                                        </div>
                                        <div>
                                            <Progress
                                                className="calculator-wizard-progress"
                                                percent={50}
                                                showInfo={false}
                                                strokeColor={category.color}
                                            />
                                        </div>
                                        <div>
                                            <span>ממוצע שוק XXX$</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </Panel>
                    </Collapse>
                </div>
            </ConfigProvider>
        </div>
    );
};
