import React, {useMemo} from "react";
import {orderBy, sumBy} from "lodash";
import CalculatorCategoriesBar from "../happinessCalculator/CalculatorCategoriesBar";
import {getOrderPrice} from "../utils/calculationUtils";

const OrdersCalendarCategoriesBar = ({orders, categoriesWithTotalPrice, totalCategoriesPrice}) => {
    const ordersPrice = useMemo(() => sumBy(orders ?? [], getOrderPrice), [orders]);

    const categoriesAndOrdersWithTotalPrice = useMemo(() => {
        if (!categoriesWithTotalPrice || !orders?.length) {
            return null;
        }

        let tempOrders = [...orders];

        const categoriesWithLinkEvents = Object.values(categoriesWithTotalPrice).map(
            ({occasionCategoryId, categoryName, color, occasions}) => {
                const linkedOrdersPrice = sumBy(occasions, occasion => {
                    if (!occasion.linkedEvent) {
                        return 0;
                    }

                    const orderIndex = tempOrders.findIndex(({eventId}) => eventId === occasion.linkedEvent);

                    if (orderIndex === -1) {
                        return 0;
                    }

                    const order = tempOrders.splice(orderIndex, 1)[0];

                    return getOrderPrice(order);
                });

                return {
                    occasionCategoryId,
                    categoryName,
                    color,
                    totalPrice: linkedOrdersPrice
                };
            }
        );

        const unLinkedOrdersCategory = {
            occasionCategoryId: "UnlinkedOrders",
            categoryName: "הזמנות לא משויכות",
            color: "var(--standard-text-color)",
            totalPrice: sumBy(tempOrders, getOrderPrice)
        };

        const allCategories = [...categoriesWithLinkEvents, unLinkedOrdersCategory];

        const restCategory = {
            color: "var(--light-background)",
            totalPrice: Math.max(totalCategoriesPrice - ordersPrice, 0)
        };

        return [...orderBy(allCategories, "totalPrice", "desc"), restCategory];
    }, [orders, ordersPrice, categoriesWithTotalPrice, totalCategoriesPrice]);

    const isUsedMoreThenBudget = useMemo(() => ordersPrice > totalCategoriesPrice, [ordersPrice, totalCategoriesPrice]);

    return (
        <CalculatorCategoriesBar
            className={isUsedMoreThenBudget ? "overflow-used-budget" : ""}
            categoriesWithTotalPrice={categoriesAndOrdersWithTotalPrice}
            totalPrice={isUsedMoreThenBudget ? ordersPrice : totalCategoriesPrice}
        />
    );
};

export default OrdersCalendarCategoriesBar;
