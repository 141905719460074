import {MainServices} from "../../../../consts.js";

export const CategoryToBudgetFilterText = {
    [MainServices.HAPPY_HOURS]: "תקציב האפי האוור",
    [MainServices.PACKAGES]: "תקציב המתנה",
    [MainServices.WORKSHOPS]: "תקציב הפעילות"
};

export const consistentOption = {
    consistent: "consistent",
    flexible: "flexible"
};

export const consistentCheckboxOptions = [
    {value: consistentOption.flexible, label: "הזמנה גמישה (:", tooltip: "עד 10% מהערכים המוגדרים"}
];

export const CategoryToBudgetAmountParticipantFilterText = {
    [MainServices.PACKAGES]: "מס׳ מתנות",
    [MainServices.WORKSHOPS]: "מס׳ משתתפים"
};

export const CategoryToTotalBudgetPriceFilterText = {
    [MainServices.HAPPY_HOURS]: "תקציב לאירוע",
    [MainServices.PACKAGES]: "תקציב להזמנה",
    [MainServices.WORKSHOPS]: "תקציב הפעילות"
};

export const CategoryToPricePerParticipantFilterText = {
    [MainServices.PACKAGES]: "תקציב למתנה",
    [MainServices.WORKSHOPS]: "תקציב למשתתף"
};

export const budgetTitleAmount = {
    [MainServices.PACKAGES]: "מתנות",
    [MainServices.WORKSHOPS]: "משתתפים"
};

export const budgetTitlePricePerParticipant = {
    [MainServices.PACKAGES]: "למתנה",
    [MainServices.WORKSHOPS]: "למשתתף"
};

export const budgetFields = {
    participantsAmount: "participantsAmount",
    pricePerParticipant: "pricePerParticipant",
    totalPrice: "totalPrice"
};
