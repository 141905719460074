import React, {useState, useEffect, useContext} from "react";
import {Button, Modal, Upload, message, ConfigProvider} from "antd";
import {EventBus} from "../../bus/EventBus";
import {PageTitleLabel, SmartImageContainer, StyledButton, ColorPicker} from "../../components";
import {HttpClient} from "../../http/HttpClient";
import {CloseIcon, UploadIcon} from "../../icons";
import Icon from "@ant-design/icons";
import {UploadClient} from "../../http/UploadClient";
import {GoogleAnalytics} from "../../GoogleAnalytics";
import {AppContext} from "../../AppContext";
import "./branding.css";
import {ColorsRow} from "./ColorPalette";
import {defaultSelectedTheme, defaultThemes} from "../../color/ColorTheme";
import {getBrandingPaletteWithExtraInfo} from "../../utils/colorPaletteUtils";
import _ from "lodash";
import {hasEXCenterFeatureOpen} from "../../center/EXCenterHelper";

const imageProps = {
    width: 300,
    height: 120
};

const CompanyBrandingForm = ({onClose}) => {
    const {me, company} = useContext(AppContext);

    if (!me || !company) return null;
    const branding = company.branding;

    const [uploading, setUploading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [companyThemes, setCompanyThemes] = useState(branding && branding.themes ? branding.themes : defaultThemes);
    const [selectedTheme] = useState(
        branding && branding.selectedTheme ? branding.selectedTheme : defaultSelectedTheme
    );
    const [logo, setLogo] = useState(null);
    const [color, setColor] = useState(null);

    if (company && color === null && logo === null) {
        const {branding} = company;
        if (branding) {
            const {logoUrl, logoId, primaryColor, secondaryColor} = branding;
            setLogo({logoUrl, logoId});
            setColor({primary: primaryColor, secondary: secondaryColor});
        } else {
            setColor({primary: "#2F3E83", secondary: "white"});
        }
    }

    const uploadLogo = async file => {
        setUploading(true);
        try {
            const formData = new FormData();
            formData.append("file", file);
            const {imageUrl, imageId} = await UploadClient.upload(
                "PUT",
                `/api/image?width=${imageProps.width}&height=${imageProps.height}&crop=fill&gravity=center&colors=1`,
                formData
            );
            GoogleAnalytics.event("Company Branding", "Upload Logo", me.userId);
            setLogo({logoUrl: imageUrl, logoId: imageId});
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we couldn't upload your logo :("}},
                e.message
            );
        }
        setUploading(false);
    };

    const saveSettings = async () => {
        setSaving(true);
        try {
            const branding = {
                logoUrl: logo ? logo.logoUrl || null : null,
                logoId: logo ? logo.logoId || null : null,
                primaryColor: color.primary,
                secondaryColor: color.secondary,
                selectedTheme,
                themes: companyThemes
            };

            const user = await HttpClient.post(`/api/companies/${me.companyId}/brand`, branding);
            GoogleAnalytics.event("Company Branding", "Save & Apply", me.userId);
            EventBus.trigger("me:update", user);
            EventBus.trigger("company:update", {
                ...company,
                branding: {
                    ...company.branding,
                    ...branding
                }
            });
            onClose();
        } catch (e) {
            message.error("Oppsy... something went wrong, please try again later.");
        }
        setSaving(false);
    };

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <ConfigProvider direction="rtl">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        padding: 30
                    }}>
                    <PageTitleLabel
                        style={{
                            fontSize: 20,
                            fontWeight: 700,
                            color: "#273444",
                            width: "100%",
                            marginBottom: 30,
                            marginTop: 0
                        }}>
                        שינוי לוגו
                    </PageTitleLabel>

                    <div style={{display: "flex", justifyContent: "center", position: "relative", marginTop: 15}}>
                        {logo && logo.logoUrl ? (
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <SmartImageContainer
                                    className="wb-image-container"
                                    width={imageProps.width}
                                    height={imageProps.height}
                                    src={logo.logoUrl}
                                    transformations={{
                                        crop: "lpad"
                                    }}
                                    style={{
                                        border: "1px solid #E8EDF5",
                                        color: "var(--secondary-color)",
                                        borderRadius: 10,
                                        margin: 0
                                    }}>
                                    <CloseIcon
                                        fill="#ccc"
                                        onClick={() => setLogo(null)}
                                        style={{
                                            position: "absolute",
                                            right: 15,
                                            top: 12,
                                            cursor: "pointer"
                                        }}
                                    />
                                </SmartImageContainer>
                            </div>
                        ) : (
                            <Upload
                                disabled={uploading}
                                name="file"
                                className="wb-logo-upload"
                                accept="image/png,image/jpeg"
                                showUploadList={false}
                                beforeUpload={async file => {
                                    await uploadLogo(file);
                                    return false;
                                }}>
                                <Button
                                    disabled={uploading}
                                    loading={uploading}
                                    type="link"
                                    style={{
                                        fontSize: 16,
                                        padding: 7,
                                        color: "var(--secondary-color)",
                                        opacity: uploading ? 0.65 : 1
                                    }}>
                                    <Icon component={UploadIcon} style={{fontSize: 20}} /> Upload logo (PNG / JPEG)
                                </Button>
                            </Upload>
                        )}
                    </div>
                </div>
                <div
                    style={{
                        height: 0,
                        width: "80%",
                        borderBottom: "1px dashed #DAE0E4",
                        marginTop: 20,
                        marginBottom: 20
                    }}
                />
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        width: "100%",
                        padding: 30,
                        paddingTop: 0
                    }}>
                    <StyledButton
                        loading={saving}
                        style={{color: "white", backgroundColor: "#13C296", height: 40, borderRadius: 25, width: 85}}
                        onClick={async () => {
                            const updatedCompany = _.clone(companyThemes);
                            setCompanyThemes(updatedCompany);
                            await saveSettings();
                        }}>
                        שמור
                    </StyledButton>
                </div>
            </ConfigProvider>
        </div>
    );
};

export const CompanyBranding = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        EventBus.on("branding:open", () => setVisible(true));
    }, []);

    const closeMe = () => {
        setVisible(false);
    };

    return (
        <Modal
            className="wb-branding-modal"
            title={null}
            footer={null}
            bodyStyle={{padding: 0}}
            destroyOnClose={true}
            closable={true}
            onCancel={() => closeMe()}
            open={visible}>
            {visible ? <CompanyBrandingForm onClose={closeMe} /> : null}
        </Modal>
    );
};
