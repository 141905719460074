import React, {useEffect, useState} from "react";
import {SmartImageContainer} from "../components/SmartImageContainer";
import {CloseIcon, UploadIcon} from "../icons";
import {UploadableCoverPhoto} from "../components/UploadableCoverPhoto";
import {StyledButton} from "../components/StyledButton";
import {LoadingOutlined} from "@ant-design/icons";

export const ImageUpload = ({
    imageUrl,
    onChange,
    onFileUploadFinish,
    customText,
    imageContainerStyle,
    customHeight,
    customWidth
}) => {
    const removeImage = () => {
        onChange(null);
    };

    useEffect(() => {
        if (customText) {
            setUploadText(customText);
        }
    }, [customText]);

    const [uploadText, setUploadText] = useState(customText ? customText : "Upload logo (PNG / JPEG)");

    return imageUrl ? (
        <SmartImageContainer
            width={customWidth ? customWidth : imageContainerStyle ? 185 : 300}
            height={customHeight ? customHeight : imageContainerStyle ? 180 : 120}
            src={imageUrl}
            transformations={{
                crop: "lpad"
            }}
            style={
                imageContainerStyle
                    ? {
                          ...imageContainerStyle,
                          border: "1px dashed #DAE0E4",
                          position: "relative"
                      }
                    : {
                          border: "1px dashed #DAE0E4",
                          position: "relative",
                          borderRadius: 10
                      }
            }>
            <CloseIcon
                style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    width: 12,
                    height: 12,
                    fill: "var(--secondary-color)",
                    cursor: "pointer"
                }}
                onClick={removeImage}
            />
        </SmartImageContainer>
    ) : (
        <UploadableCoverPhoto
            coverStyle={
                imageContainerStyle
                    ? {
                          ...imageContainerStyle,
                          paddingBottom: "40%",
                          border: "1px dashed #DAE0E4"
                      }
                    : {paddingBottom: "40%", border: "1px dashed #DAE0E4"}
            }
            width={customWidth ?? 300}
            height={customHeight ?? 120}
            imageUrl={imageUrl}
            onChange={onChange}
            gravity="center"
            crop="fill"
            showUploadOnly={true}
            withColorsExtraction={true}
            onFileUploadFinish={onFileUploadFinish}
            CustomUploadButton={({uploading}) => (
                <div style={{display: "flex", justifyContent: "center", width: "100%", marginLeft: "-25px"}}>
                    <StyledButton
                        style={{
                            backgroundColor: "transparent",
                            color: "var(--secondary-color)",
                            boxShadow: "none",
                            border: "none",
                            paddingBottom: 15,
                            cursor: "pointer"
                        }}
                        icon={uploading ? <LoadingOutlined /> : <UploadIcon style={{marginRight: 10}} />}>
                        {uploadText}
                    </StyledButton>
                </div>
            )}
        />
    );
};
