import React, {useMemo} from "react";
import {Skeleton} from "antd";
import {OLD_MAX_CAROUSEL_LENGTH} from "./MarketplaceUtils";
import {MarketplaceCategoryItem} from "./MarketplaceItems/MarketplaceCategoryItem.jsx";
import {MarketplaceCarouselProps} from "./MarketplaceHelper";
import {WBCarousel} from "../components";
import {Images} from "../images/Images";
import {getLogger} from "../Logger";

const MarketplaceProductsCarousel = ({
    products = [],
    title,
    onContentClick,
    onNewTab,
    onAllChoicesClicked,
    carouselLength = OLD_MAX_CAROUSEL_LENGTH,
    loading
}) => {
    const log = useMemo(
        () =>
            getLogger(
                {
                    title
                },
                "ProductsCarousel"
            ),
        [title]
    );

    const carouselProps = useMemo(() => {
        const {length} = products;
        const isShortCarousel = length < carouselLength;

        return {
            ...MarketplaceCarouselProps,
            slidesToShow: isShortCarousel ? length : carouselLength,
            slidesToScroll: isShortCarousel ? length : carouselLength,
            className: "wb-carousel" + (isShortCarousel ? " short-carousel" : ""),
            variableWidth: isShortCarousel,
            initialSlide: isShortCarousel ? 0 : length - carouselLength + 1
        };
    }, [carouselLength, products]);

    if (!products.length) {
        return null;
    }

    return (
        <div className="marketplace-category-items-container">
            <div className="marketplace-category-carousal-title-row">
                <span className="marketplace-category-sub-category-title">{title}</span>
                {products.length > carouselLength && onAllChoicesClicked && !loading ? (
                    <span
                        onClick={() => onAllChoicesClicked("label")}
                        className="marketplace-category-carousal-all-choices">
                        לכל הבחירות
                    </span>
                ) : null}
            </div>
            <div className="marketplace-category-carousel-container">
                <WBCarousel key="products" carouselProps={carouselProps}>
                    {products.length > carouselLength && onAllChoicesClicked ? (
                        <div>
                            <div
                                className="marketplace-category-carousel-load-all-card-container"
                                onClick={() => onAllChoicesClicked("card")}>
                                <div className="marketplace-category-carousel-load-all-card-text-container">
                                    <span>לכל</span>
                                    <span>הבחירות</span>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {products
                        .map((content, idx) => (
                            <MarketplaceCategoryItem
                                key={`category-item-${idx}`}
                                log={log}
                                content={content}
                                onContentClick={onContentClick}
                                onNewTab={onNewTab}
                            />
                        ))
                        .reverse()}
                </WBCarousel>
            </div>
        </div>
    );
};

export default MarketplaceProductsCarousel;
