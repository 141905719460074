import React, {useCallback, useContext, useState} from "react";
import {message} from "antd";
import {set} from "lodash";
import {NOOP} from "../../utils/NOOP.jsx";
import {AllowedSystemIdBudgetLoadPaymentType, PaymentType, ValidateBookingError} from "../../consts.js";
import {FormModal} from "../../components/index.jsx";
import {PaymentInput} from "../../marketplace/MarketplaceBooking/Payment/PaymentInput";
import {SeparatorLine} from "../../components/SeparatorLine/index.jsx";
import {AppContext} from "../../AppContext.jsx";
import {getThemeByOrigin} from "../../utils.js";
import {StringBuilder} from "../../AppUtils.js";
import {MIN_SELF_BUDGET_LOAD} from "./consts.js";
import {TEN_BIS_CARD_LENGTH} from "../../marketplace/MarketplaceBooking/consts.jsx";

export const CompanyBudgetLoadModal = ({visible, onClose, onSave = NOOP, validationFunction}) => {
    const {me, company} = useContext(AppContext);
    const [formValues, setFormValues] = useState({});
    const [paymentType, setPaymentType] = useState(PaymentType.Card);
    const [creditCard, setCreditCard] = useState(false);

    const onFormValueChange = useCallback(
        (field, val) => {
            setFormValues(prevValues => set({...prevValues}, field, val));
        },
        [setFormValues]
    );

    const closeHandler = useCallback(() => {
        setFormValues({});
        onClose();
    }, [onClose]);

    return (
        <FormModal
            visible={visible}
            onClose={closeHandler}
            header={"טעינת תקציב 🫰"}
            confirmButtonTitle={
                !isNaN(Number(formValues?.amount)) && Number(formValues?.amount) >= MIN_SELF_BUDGET_LOAD
                    ? `שמירה (${Number(formValues?.amount).toLocaleString()} ש"ח)`
                    : "שמירה"
            }
            subTitle={
                <>
                    <div>באפשרותכם לטעון תקציב ולשלם במרוכז עבור הזמנות במערכת עד לגודל התקציב הטעון :)</div>
                    <div>באפשרותכם גם לחלק את התקציב למשתמשים כפופים לכם ולעקוב אחר מימושו על ידם אם תרצו בכך.</div>
                </>
            }
            content={formValues}
            onUpdate={(allValues, changedField) => {
                const [fieldKey, fieldValue] = Object.entries(changedField)[0];
                onFormValueChange(fieldKey, fieldValue);
            }}
            modalWidth={700}
            onSave={({amount}) => onSave({amount, ...formValues})}
            validationFunction={({amount, invoiceComment, invoicePONumber}) => {
                if (paymentType === PaymentType.Card && !Object.keys(creditCard ?? {})?.length) {
                    message.error("שגיאה! לא הוכנסו פרטי כרטיס אשראי");
                    return false;
                }

                if (
                    paymentType === PaymentType.TenBisCard &&
                    (formValues?.tenBisCreditCard?.length ?? 0) < TEN_BIS_CARD_LENGTH
                ) {
                    message.error(
                        new StringBuilder("שגיאה!")
                            .append("יש להכניס כרטיס תן ביס באורך")
                            .append(TEN_BIS_CARD_LENGTH)
                            .append("ספרות")
                            .toString()
                    );
                    return false;
                }

                if (
                    paymentType === PaymentType.BankTransfer &&
                    !formValues.invoicePONumber?.length &&
                    (company?.requirePONumber || me?.isMustProvidePurchaseOrderNumber)
                ) {
                    message.error(ValidateBookingError.NO_INVOICE_PO_NUMBER);
                    return false;
                }

                return validationFunction({
                    ...formValues,
                    amount,
                    paymentType,
                    ...(paymentType === PaymentType.BankTransfer
                        ? {
                              invoiceComment,
                              invoicePONumber
                          }
                        : {invoiceComment: null, invoicePONumber: null}),
                    ...(paymentType === PaymentType.Card ? {creditCard} : {})
                });
            }}
            fields={[
                {
                    name: "amount",
                    label: "סכום לטעינה",
                    info: new StringBuilder()
                        .append("אנא הקלידו את הסכום הרצוי לטעינה")
                        .append("-")
                        .append("מינימום")
                        .append(MIN_SELF_BUDGET_LOAD)
                        .append("₪")
                        .append("לטעינה")
                        .toString(),
                    rules: [
                        {
                            validator: (_, amount) => {
                                const parsedAmount = Number(amount);

                                if (isNaN(parsedAmount)) {
                                    return Promise.reject("אנא הקלידו מספרים בלבד");
                                }

                                return parsedAmount < MIN_SELF_BUDGET_LOAD && !me?.isAdmin
                                    ? Promise.reject(
                                          new StringBuilder()
                                              .append("אנא הקלידו את הסכום הרצוי לטעינה")
                                              .append("-")
                                              .append("מינימום")
                                              .append(MIN_SELF_BUDGET_LOAD)
                                              .append("₪")
                                              .append("לטעינה")
                                              .toString()
                                      )
                                    : Promise.resolve();
                            }
                        }
                    ]
                },
                {
                    type: "custom",
                    name: "SeparatorLine",
                    customComponent: <SeparatorLine text="אמצעי טעינה לתשלום" />
                },
                {
                    type: "custom",
                    name: "payment",
                    customComponent: (
                        <PaymentInput
                            onInvoiceCommentChange={newInvoiceComment =>
                                onFormValueChange("invoiceComment", newInvoiceComment)
                            }
                            invoiceComment={formValues.invoiceComment}
                            onInvoicePONumberChange={newPONumber => onFormValueChange("invoicePONumber", newPONumber)}
                            invoicePONumber={formValues.invoicePONumber}
                            onSeparateInvoiceChange={newVal => onFormValueChange("separateInvoice", newVal)}
                            allowedPaymentMethods={
                                AllowedSystemIdBudgetLoadPaymentType[getThemeByOrigin(window.location.origin)]
                            }
                            paymentType={paymentType}
                            setPaymentType={setPaymentType}
                            separateInvoice={formValues?.separateInvoice}
                            creditCard={creditCard}
                            creditCardToolTipInfo={
                                <>
                                    <div>
                                        <p className="payment-input-tooltip-text">
                                            בחירה בכרטיס אשראי כאמצעי תשלום לעסקה מאפשרת לכם לשלם על העסקה הספציפית הזו
                                            בכרטיס אשראי המוזן כולל הנפקת חשבונית מס/קבלה.
                                        </p>
                                    </div>
                                </>
                            }
                            setCreditCard={setCreditCard}
                            budgetCategoriesPriority={null}
                            onBudgetCategoriesPriorityChange={NOOP}
                            updateFormValues={(formField, formValue) => onFormValueChange(formField, formValue)}
                            tenBisCreditCard={formValues?.tenBisCreditCard}
                        />
                    )
                }
            ]}
        />
    );
};
