import React, {useEffect} from "react";
import {withRouter} from "react-router";
import {Route, Switch} from "react-router-dom";
import moment from "moment";
import {CalculatorWizard} from "./calculatorStepper";
import {HappinessCalculatorBasePage} from "./HappinessCalculatorBasePage";
import {HappinessCalculatorContext} from "./HappinessCalculatorContext";
import {useLocalStorage} from "../utils/hooks";
import {HttpClient} from "../http/HttpClient";

const HappinessCalculatorContextProvider = ({location, history}) => {
    const [planTimeRange, setPlanTimeRange] = useLocalStorage(
        "calculatorPlainTimeRanges",
        [moment().startOf("year"), moment().endOf("year")],
        ([start, end]) => [moment(start), moment(end)]
    );
    const [planOccasionsCategories, setPlanOccasionsCategories] = useLocalStorage("calculatorPlainCategories", []);

    useEffect(() => {
        if (location?.state) {
            const {month} = location.state;

            if (month) {
                const currentMonth = moment(month, "MM/YYYY");

                async function applyAllCategories() {
                    const allCategories = await HttpClient.safeGet("/api/occasions/categories");

                    if (allCategories?.length) {
                        setPlanOccasionsCategories(allCategories?.map(({occasionCategoryId}) => occasionCategoryId));
                    }
                }

                applyAllCategories();

                setPlanTimeRange([currentMonth.clone().startOf("month"), currentMonth.clone().endOf("month")]);
            }

            history.replace({state: null});
        }
    }, [history, setPlanTimeRange]);

    return (
        <HappinessCalculatorContext.Provider
            value={{
                planTimeRange,
                setPlanTimeRange,
                planOccasionsCategories,
                setPlanOccasionsCategories
            }}>
            <Switch>
                <Route exact path="/happiness-calculator" component={CalculatorWizard} />
                <Route exact path="/happiness-calculator/wizard" component={CalculatorWizard} />
                <Route path="/happiness-calculator/plan/:mode/:planId?" component={HappinessCalculatorBasePage} />
            </Switch>
        </HappinessCalculatorContext.Provider>
    );
};

export default withRouter(HappinessCalculatorContextProvider);
