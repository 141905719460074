import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Modal} from "antd";
import {NOOP} from "../utils/NOOP";
import {SquareButton} from "./SquareButton";
import {SortableTabs} from "./SortableTabs";
import PageTitleHeader from "./PageTitle";
import {DynamicForm} from "./DynamicForm";
import "./FormModal/form-modal.css";

export const TabbedFormModal = ({
    visible,
    content,
    header,
    onClose,
    onSave,
    fields,
    disables = [],
    onUpdate = NOOP,
    className = "",
    showCancelButton = false,
    cancelButtonTitle = "ביטול",
    tabTitleField = "name",
    width = 500
}) => {
    const [items, setItems] = useState(content);

    useEffect(() => {
        setItems(content);
    }, [content, visible]);

    const innerOnClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const onUpdateTab = useCallback(
        (index, newValues) => {
            const newContent = [...content];
            newContent[index] = newValues;
            setItems(newContent);
            onUpdate(newContent);
        },
        [content, onUpdate]
    );

    const tabs = useMemo(() => {
        const allContent = items || [];
        const tabs = allContent.map((item, index) => ({
            key: index,
            label: item[tabTitleField],
            children: (
                <DynamicForm
                    visible={true}
                    content={item}
                    onClose={onClose}
                    fields={fields(item, onUpdateTab, index)}
                    disables={disables}
                    onUpdate={(allValues, changedField) => {
                        onUpdateTab(index, allValues, changedField);
                    }}
                />
            )
        }));
        return tabs;
    }, [items]);

    return (
        <Modal
            width={width}
            className={`wb-modal-radius form-modal-container ${className}`}
            style={{borderRadius: "5px"}}
            destroyOnClose={true}
            open={visible}
            onCancel={innerOnClose}
            maskClosable={false}
            footer={null}
            title={null}
            closable={true}>
            <div className="form-modal">
                <PageTitleHeader className="form-modal-header" showBack={false}>
                    {header}
                </PageTitleHeader>
                <SortableTabs
                    tabs={tabs}
                    onAddTab={() => {
                        setItems([...items, {}]);
                    }}
                    onRemoveTab={index => {
                        const newContent = [...items];
                        newContent.splice(index, 1);
                        setItems(newContent);
                    }}
                    onSort={(active, over) => {
                        if (active.id === over?.id) return;

                        const tempItems = [...items];
                        const draggedItem = tempItems.splice(active.id, 1)[0];
                        tempItems.splice(over?.id, 0, draggedItem);
                        setItems(tempItems);
                    }}
                />
                <div className="simple-modal-break-line" />

                <div className="form-modal-buttons-container">
                    <SquareButton
                        className="form-modal-save-button"
                        onClick={() => {
                            onSave(items);
                        }}>
                        שמירה
                    </SquareButton>

                    {showCancelButton ? (
                        <SquareButton className="form-modal-cancel-button" onClick={onClose}>
                            {cancelButtonTitle}
                        </SquareButton>
                    ) : null}
                </div>
            </div>
        </Modal>
    );
};
