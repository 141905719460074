import React from "react";
import {Switch} from "antd";
import {
    DeleteOutlined,
    DragOutlined,
    EditFilled,
    EyeFilled,
    FullscreenExitOutlined,
    FullscreenOutlined,
    InsertRowAboveOutlined
} from "@ant-design/icons";
import {carouselType} from "./consts";
import {ConfirmationRequiredWrapper} from "../../components";
import {TableDateCell} from "../../components/Table/TableDateCell";
import {DragHandle} from "../../components/dragableTable";
import {pinterestCarouselId} from "../../marketplace/DynamicLayoutPage/consts";

const getColumns = (
    deleteCarousel,
    editCarousel,
    updateCarouselStatus,
    editSelectPageCarousel,
    openSelectionPage,
    openMoveCarouselModal,
    updateCarouselGridNumber
) => [
    {
        title: "#",
        key: "#",
        width: 70,
        render(_, __, idx) {
            return `${idx + 1}`;
        }
    },
    {
        title: "סידור",
        dataIndex: "sort",
        width: 30,
        className: "drag-visible",
        render: () => {
            return <DragHandle />;
        }
    },
    {
        title: "מזהה",
        dataIndex: "carouselId"
    },
    {
        title: "שם קרוסלה",
        dataIndex: "carouselName"
    },
    {
        title: "תאריך יצירה",
        dataIndex: "createdAt",
        sorter: ({createdAt: r1}, {createdAt: r2}) => r1 - r2,
        render(createdAt) {
            return <TableDateCell date={createdAt} />;
        }
    },
    {
        title: "תאריך עדכון אחרון",
        dataIndex: "updatedAt",
        sorter: ({updatedAt: r1}, {updatedAt: r2}) => r1 - r2,
        render(updatedAt) {
            return <TableDateCell date={updatedAt} />;
        }
    },
    {
        title: "סוג קרוסלה",
        render(_, {gridNumber, selectPageId, carouselId}) {
            if (carouselId === pinterestCarouselId) {
                return "קרוסלת פינטרסט";
            }

            if (selectPageId) {
                return `${carouselType.product} ${gridNumber ? "(פרוס)" : ""}`;
            } else if (gridNumber > 0) {
                return carouselType.miniCategory;
            } else {
                return carouselType.banner;
            }
        }
    },
    {
        title: "מס׳ אייטמים",
        render(page) {
            if (page.selectPageId) {
                return <span>{"דינאמי"}</span>;
            } else {
                return <span>{page.carouselItems?.length}</span>;
            }
        }
    },
    {
        title: "מס׳ גריד",
        dataIndex: "gridNumber"
    },
    {
        title: "עמודים מציגים",
        dataIndex: "featuringPages"
    },
    {
        title: "סטטוס קרוסלה",
        filters: [
            {
                text: "פעיל",
                value: true
            },
            {
                text: "לא פעיל",
                value: false
            }
        ],
        filterMultiple: false,
        onFilter: (value, record) => record.isActive === value,
        dataIndex: "isActive",
        render(isActive, {carouselId}) {
            return (
                <Switch
                    checked={isActive}
                    onChange={newValue => updateCarouselStatus(carouselId, newValue)}
                    checkedChildren={<span className="accept-text">באויר</span>}
                    unCheckedChildren={<span className="error-text">לא פעיל</span>}
                />
            );
        }
    },
    {
        title: "פעולות",
        render(_, {carouselId, selectPageId, gridNumber = 0, carouselName}) {
            return (
                <>
                    <div className="page-layout-admin-column-action">
                        {carouselId !== pinterestCarouselId && (
                            <>
                                <ConfirmationRequiredWrapper
                                    title="האם אתה בטוח?"
                                    subTitle={
                                        selectPageId
                                            ? "שימו לב! בחרתם למחוק קרוסלת מוצרים, הפעולה לא תמחוק את אתר הבחירה שמקושר לקרוסלה אלא רק את הקרוסלה בלבד!"
                                            : ""
                                    }
                                    onYes={() => deleteCarousel(carouselId)}>
                                    <DeleteOutlined />
                                </ConfirmationRequiredWrapper>

                                <div>
                                    <EditFilled
                                        onClick={() => {
                                            if (selectPageId) {
                                                editSelectPageCarousel(selectPageId, carouselId);
                                            } else {
                                                editCarousel(carouselId);
                                            }
                                        }}
                                    />
                                </div>

                                {selectPageId ? (
                                    <>
                                        <div>
                                            <EyeFilled onClick={() => openSelectionPage(selectPageId)} />
                                        </div>

                                        {gridNumber ? (
                                            <div>
                                                <FullscreenExitOutlined
                                                    style={{color: "var(--primary-color)"}}
                                                    onClick={() => updateCarouselGridNumber(carouselId, 0)}
                                                />
                                            </div>
                                        ) : (
                                            <div>
                                                <FullscreenOutlined
                                                    onClick={() => updateCarouselGridNumber(carouselId, 1)}
                                                />
                                            </div>
                                        )}
                                    </>
                                ) : null}
                            </>
                        )}

                        <div>
                            <DragOutlined onClick={() => openMoveCarouselModal(carouselId, carouselName)} />
                        </div>
                    </div>
                </>
            );
        }
    }
];

export default getColumns;
