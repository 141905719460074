import {getLoginStatus} from "./utils";
import {PaymentType, PaymentTypeToName, ServiceIdToName} from "../../consts.js";
import {createWorkbook, createWorksheet, DownloadExcel} from "../../utils/excelUtils.js";
import {groupBy, sumBy} from "lodash";

export const TRANSFER_COMMENTS = ["טעינת תקציב", "הורדה מתקציב", "משיכת תקציב מ"];

export const exportAdminsToExcel = async (subAdmins, services, budgets, subAdminsIdsToOrders) => {
    const workbook = createWorkbook();
    const sheet = createWorksheet(workbook);
    addColumns(sheet, services);
    addRows(sheet, subAdmins, budgets, services, subAdminsIdsToOrders);
    addStyledRules(sheet, services);

    await DownloadExcel(workbook, "דוח שימושיות");
};

const addColumns = (sheet, services) => {
    sheet.columns = [
        {header: "מחלקה", key: "department"},
        {header: "שם מלא", key: "firstName"},
        {header: "כתובת מייל", key: "email"},
        {header: "טלפון", key: "phone"},
        {header: "כתובת", key: "address"},
        {header: "יתרת תקציב (כולל מעמ)", key: "remainingBudget"},
        {header: "תקציב שנוצל (כולל מעמ)", key: "usedBudget"},
        ...Object.values(PaymentType).map(paymentType => ({
            key: paymentType,
            header: `אפשרות לתשלום ב${PaymentTypeToName[paymentType]}`
        })),
        ...services.map(({serviceId}) => ({
            header: ServiceIdToName[serviceId],
            key: serviceId
        })),
        {header: "סטטוס התחברות", key: "loginStatus"}
    ];
};

const addRows = (sheet, subAdmins, budgets, services, subAdminsIdsToOrders) => {
    const userBudgetByUserId = groupBy(budgets, budget => budget?.userId);
    const rows = subAdmins.map(subAdmin => {
        const userTotalBudgetAmount = sumBy(userBudgetByUserId[subAdmin.userId], budget => budget?.amount || 0);
        const loginStatus = getLoginStatus(subAdmin);

        return [
            subAdmin.department,
            subAdmin.firstName,
            subAdmin.email,
            subAdmin.phone,
            subAdmin.address,
            userTotalBudgetAmount,
            subAdminsIdsToOrders[subAdmin.userId] || 0,
            ...Object.values(PaymentType).map(paymentType =>
                subAdmin.allowedPaymentsMethods?.includes(paymentType) ? "מאופשר" : "לא מאופשר"
            ),
            ...Object.values(services).map(({serviceId}) =>
                subAdmin.allowedServices?.includes(serviceId) ? "מאופשר" : "לא מאופשר"
            ),
            loginStatus
        ];
    });

    sheet.addRows(rows);
};

const addStyledRules = (sheet, services) => {
    const colors = ["92D050", "ADAAAA", "F5C242", "B7C6E4"];
    const getHeaderStyle = bgColor => ({
        fill: {type: "pattern", pattern: "solid", fgColor: {argb: bgColor}},
        font: {color: {argb: "75140C"}, bold: true}
    });

    const headerRow = sheet.getRow(1);

    [1, 2, 3].forEach(index => (headerRow.getCell(index).style = getHeaderStyle(colors[0])));
    [4, 5, 6].forEach(index => (headerRow.getCell(index).style = getHeaderStyle(colors[1])));
    [7, 8, 9].forEach(index => (headerRow.getCell(index).style = getHeaderStyle(colors[3])));
    Array.from(Array(services.length).keys()).forEach(
        index => (headerRow.getCell(10 + index).style = getHeaderStyle(colors[2]))
    );

    const startAllowableColumnLetter = "G";
    const endColumnLetter = String.fromCharCode(startAllowableColumnLetter.charCodeAt() + 2 + services.length);

    sheet.addConditionalFormatting({
        ref: `$${startAllowableColumnLetter}:$${endColumnLetter}`,
        rules: [
            {
                type: "cellIs",
                operator: "equal",
                formulae: [`"לא מאופשר"`],
                style: {
                    fill: {type: "pattern", fgColor: {argb: "75140C"}, pattern: "solid", bgColor: {argb: "FFC7CE"}}
                }
            },
            {
                type: "cellIs",
                operator: "equal",
                formulae: [`"מאופשר"`],
                style: {
                    fill: {type: "pattern", pattern: "solid", bgColor: {argb: "C6EFCE"}}
                }
            }
        ]
    });
};
