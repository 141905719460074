import React, {useContext} from "react";
import {StyledButton} from "../components/StyledButton";
import {Images} from "../images/Images";
import {AppContext} from "../AppContext";
import {AppointmentCalendarSyncActions} from "../event/info/sync/EventCalendarSyncActions";
import EventBookingConfirmationView from "../event/info/EventBookingConfirmationView";
import {ContactPhone} from "../support/ContactPhone";

const EmployeeBookingPaymentSucceed = ({onConfirm, confirmedAppointment, paymentStatus}) => {
    return (
        <EventBookingConfirmationView
            serviceId={confirmedAppointment.serviceId}
            subtitle={
                <>
                    Your confirmation code is: <strong style={{height: 25, marginLeft: 4}}>{paymentStatus.code}</strong>
                </>
            }>
            <AppointmentCalendarSyncActions
                appointment={confirmedAppointment}
                onClose={onConfirm}
                style={{marginBottom: 20, marginTop: 20}}
            />
        </EventBookingConfirmationView>
    );
};

const EmployeeBookingPaymentFailed = ({onBackward, paymentStatus}) => {
    const {theme} = useContext(AppContext);

    return (
        <EventBookingConfirmationView
            title="Payment failed"
            subtitle={
                paymentStatus.error ? paymentStatus.error : "We couldn't book your appointment, please try again."
            }
            image={Images.failWide}
            action={
                <StyledButton
                    onClick={() => onBackward()}
                    style={{
                        backgroundColor: theme.primary,
                        color: theme.textPrimary,
                        border: "none",
                        marginTop: 30,
                        width: 200
                    }}>
                    Try again
                </StyledButton>
            }
        />
    );
};

export const EmployeeBookingConfirmation = ({
    providerInfo,
    paymentStatus,
    event,
    onClose,
    confirmedAppointment,
    onBack
}) => {
    const {company} = useContext(AppContext);

    if (!confirmedAppointment && paymentStatus && paymentStatus.status === "confirmed") {
        return (
            <div style={{height: 200, display: "flex", justifyContent: "center", alignItems: "center"}}>
                <span>
                    Something went wrong, please chat with support at:{" "}
                    <a href="#" onClick={() => ContactPhone.openWhatsApp()}>
                        {ContactPhone.prettyPhoneNumber()}
                    </a>
                </span>
            </div>
        );
    }

    return providerInfo && providerInfo.transactionTerminal && paymentStatus && paymentStatus.status ? (
        paymentStatus && paymentStatus.status === "confirmed" ? (
            <EmployeeBookingPaymentSucceed
                onConfirm={() => onClose(confirmedAppointment)}
                confirmedAppointment={confirmedAppointment}
                paymentStatus={paymentStatus}
            />
        ) : (
            <EmployeeBookingPaymentFailed onBackward={onBack} paymentStatus={paymentStatus} />
        )
    ) : (
        <EventBookingConfirmationView
            serviceId={confirmedAppointment.serviceId}
            subtitle={
                event.whoPays === "Employees pay"
                    ? "Pay directly to professional after the appointment"
                    : "This appointment is pre-paid. Enjoy."
            }
            useIcal={company.disabledFeatures?.includes("sync")}>
            <AppointmentCalendarSyncActions
                appointment={confirmedAppointment}
                onClose={onClose}
                style={{marginBottom: 20, marginTop: 0}}
            />
        </EventBookingConfirmationView>
    );
};
