import React from "react";
// import FadeIn from "react-fade-in";

export const StepContainer = ({style, children}) => (
    <FadeIn>
        <div
            style={{
                display: "flex",
                alignItems: "center",
                marginTop: 50,
                flexDirection: "column",
                width: "100%",
                maxWidth: 600,
                marginBottom: 100,
                ...style
            }}>
            {children}
        </div>
    </FadeIn>
);
