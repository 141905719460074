export function getTenBisLastFourDigits(numberInput) {
    if (typeof numberInput !== "number" && typeof numberInput !== "string") {
        return null;
    }

    const numStr = numberInput.toString().replace(/\s+/g, "");

    if (!/^\d{16}$/.test(numStr)) {
        return null;
    }

    return numStr.slice(-4);
}
