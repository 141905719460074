import React, {useContext} from "react";
import {truncate} from "lodash";
import {EXCenterContext} from "../EXCenterContext";

export const EXCenterInfoTag = ({tags}) => {
    const {theme} = useContext(EXCenterContext);
    return Array.isArray(tags) && tags.length > 0 ? (
        <span
            style={{
                padding: "1px 10px 4px 10px",
                color: "white",
                backgroundColor: theme.textColor,
                borderRadius: 12.5,
                height: 25,
                fontSize: 16,
                lineHeight: "20px",
                fontWeight: 400,
                left: 10,
                bottom: 10,
                position: "absolute"
            }}>
            {truncate(tags[0], {length: 30})}
        </span>
    ) : null;
};
