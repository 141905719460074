import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ConfigProvider} from "antd";
import {set} from "lodash";
import {FormModal} from "../../components";
import "../marketplace.css";
import common from "@well-b/well-b-shared";
import {PHONE_NUMBER_ERROR_VALIDATION} from "../consts";
import "./choose-contact-modal.css";

const OrderMenu = [
    {
        id: "single",
        title: "איש קשר יחיד להזמנה",
        disabled: true,
        info: "לצערנו, האפשרות הזו אינה זמינה כעת. אנו ממליצים להוסיף איש קשר נוסף לתפעול ההזמנה."
    },
    {
        id: "mainly",
        title: "איש קשר ראשי להזמנה, לפני בעל החשבון"
    },
    {
        id: "secondly",
        title: "איש קשר משני להזמנה, אחרי בעל החשבון"
    }
];

const formDefaultProperties = {
    header: "הוספת איש קשר לתפעול ההזמנה 🙋‍",
    subTitle: "במידה וברצונכם לשנות, להוסיף או להגדיר איש קשר נוסף להזמנה אנא הזינו את הפרטים הבאים"
};

export const ChooseContactModal = ({values, visible, onSave, onClose}) => {
    const [formValues, setFormValues] = useState({});

    const updateFormValues = useCallback((key, value) => {
        setFormValues(currentFormValues => set({...currentFormValues}, key, value));
    }, []);

    const onFinish = useCallback(
        values => {
            onSave(values);
            setFormValues({});
            onClose();
        },
        [onClose]
    );

    const onUpdateForm = useCallback((allValues, changedField) => {
        const [fieldKey, fieldValue] = Object.entries(changedField)[0];
        updateFormValues(fieldKey, fieldValue);
    }, []);

    const formFields = useMemo(
        () => [
            {
                name: "contactName",
                label: "שם מלא של איש הקשר"
            },
            {
                name: "contactPhone",
                label: "טלפון",
                rules: [
                    {
                        required: true,
                        validator: (_, value) => {
                            return common.isValidPhoneMobileNumber(value)
                                ? Promise.resolve()
                                : Promise.reject(PHONE_NUMBER_ERROR_VALIDATION);
                        }
                    }
                ]
            },
            {
                name: "contactEmail",
                label: "דוא״ל"
            },
            {
                name: "contactOrder",
                label: "תעדוף איש קשר",
                type: "select",
                options: OrderMenu.map(({id, title, disabled, info}) => ({name: id, label: title, disabled, info}))
            }
        ],
        [OrderMenu]
    );

    useEffect(() => {
        if (visible && values) {
            setFormValues(currentFormValues => ({
                ...currentFormValues,
                ...values
            }));
        }
    }, [visible, values]);

    return (
        <ConfigProvider direction="rtl">
            <FormModal
                className="choose-contact-modal"
                modalWidth={501}
                visible={visible}
                header={formDefaultProperties.header}
                subTitle={formDefaultProperties.subTitle}
                onClose={onClose}
                content={formValues}
                onUpdate={onUpdateForm}
                fields={formFields}
                onSave={onFinish}
            />
        </ConfigProvider>
    );
};
