import React, {useContext, useState} from "react";
import {AppContext} from "../AppContext";
import {Checkbox, Form, Tooltip} from "antd";
import {EventActionButtonFormInput} from "../event/info/edit/EventActionButtonFormInput";
import {EventInfoPreview} from "../event/preview/EventInfoPreview";
import {FormInput, SaveButton, SendButton, TestButton} from "../components/form";
import {EditableInput} from "../components/EditableInput";
import {isEmpty} from "lodash";

const EditInput = ({title, type, placeholder, value, onChange, disabled, tooltip}) => {
    const [text, setText] = useState(value.text);
    const [selected, setSelected] = useState(value.selected);

    const change = (html, selected) => {
        setText(html);
        setSelected(selected);
        onChange({text: html, selected});
    };

    return (
        <Tooltip title={tooltip}>
            <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <label style={{fontSize: 16, color: "var(--secondary-color)"}}>{title}</label>
                    <Checkbox
                        defaultChecked={selected}
                        checked={selected}
                        disabled={disabled}
                        onChange={e => change(text, e.target.checked)}
                        style={{height: 20, width: 20, borderRadius: 10}}
                    />
                </div>
                {type === "textarea" ? (
                    <EditableInput
                        style={{marginTop: 15, width: "435px"}}
                        disabled={disabled}
                        onChange={html => change(html, selected)}
                        value={text}
                    />
                ) : (
                    <FormInput
                        value={text}
                        disabled={disabled}
                        onChange={e => change(e.target.value, selected)}
                        style={{marginTop: 15}}
                        placeholder={placeholder}
                    />
                )}
            </div>
        </Tooltip>
    );
};

export const EditForm = ({event, dateMom, onSave, onChange, onSend, onTest}) => {
    const {theme} = useContext(AppContext);
    const {weeklyTheme} = theme;
    const [loading, setLoading] = useState(false);
    const [actionButtonType, setActionButtonType] = useState(event.actionType || "system");
    const [chosenFinishButton, setChosenFinishButton] = useState(null);
    const [eventDescription, setEventDescription] = useState(null);
    const [formValues, setFormValues] = useState({});

    const toCTA = (actionType, externalLink) => {
        if (actionType === "none") {
            return {
                selected: false,
                text: null
            };
        } else if (actionType === "system") {
            return {
                selected: true,
                text: event.appointmentBased ? "Book now" : "Register now"
            };
        } else {
            return {
                selected: !!externalLink,
                text: externalLink ? externalLink.buttonText : null
            };
        }
    };

    const transform = ({
        eventTitle,
        providerName,
        providerProfession,
        eventDescription,
        actionType,
        externalLink,
        eventPhoto
    }) => {
        return {
            ...event,
            actionType,
            externalLink: actionType === "externalLink" ? externalLink : null,
            product: {
                name: eventTitle,
                description: eventDescription,
                coverPhoto: eventPhoto
            },
            provider: {
                name: providerName,
                profession: providerProfession
            },
            callToAction: {
                name: toCTA(actionType, externalLink)
            }
        };
    };

    return (
        <Form
            onFinish={values => {
                setLoading(true);
                if (chosenFinishButton) {
                    if (chosenFinishButton === "onSave") {
                        onSave(transform(values));
                    }
                    if (chosenFinishButton === "onSend") {
                        onSend(transform(values));
                    }
                    if (chosenFinishButton === "onTest") {
                        onTest(transform(values));
                    }
                }
            }}
            initialValues={{
                eventTitle: event.product.name,
                providerName: event.provider.name,
                providerProfession: event.provider.profession,
                eventDescription: event.product.description,
                eventPhoto: event.product.coverPhoto,
                callToAction: event.callToAction.name,
                actionType: event.actionType || "system",
                externalLink: event.externalLink || null
            }}
            onValuesChange={(changedValues, allValues) => {
                if (changedValues.eventDescription) {
                    setFormValues(allValues);
                } else {
                    const changedEvt = transform(allValues);
                    onChange(changedEvt);
                }
            }}>
            <div style={{width: 1100, display: "flex"}}>
                <div style={{width: "45%", height: 700, backgroundColor: "#F6F8FA", overflow: "auto"}}>
                    <div style={{width: "100%", padding: 30}}>
                        <Form.Item name="eventTitle">
                            <EditInput title="Event title:" placeholder="Event title" />
                        </Form.Item>
                        <Form.Item name="providerName">
                            <EditInput title="Professional:" placeholder="Professional" />
                        </Form.Item>
                        {event.provider.profession ? (
                            <Form.Item name="providerProfession">
                                <EditInput title="Profession:" placeholder="Profession" />
                            </Form.Item>
                        ) : null}
                        <Form.Item name="eventDescription">
                            <EditInput
                                type="textarea"
                                title="Description:"
                                placeholder="Description"
                                onChange={({text, selected}) => {
                                    if (selected) {
                                        setEventDescription(text);
                                    } else {
                                        setEventDescription(null);
                                    }
                                    if (!isEmpty(formValues)) {
                                        formValues.eventDescription = {text, selected};
                                        const changedEvt = transform(formValues);
                                        onChange(changedEvt);
                                    }
                                }}
                            />
                        </Form.Item>
                        <EventActionButtonFormInput
                            actionButtonType={actionButtonType}
                            onActionButtonTypeChange={type => setActionButtonType(type)}
                        />
                    </div>
                </div>
                <div style={{width: "55%", padding: 30, height: 700, backgroundColor: "white"}}>
                    <Form.Item name="eventPhoto">
                        <EventInfoPreview
                            event={event}
                            dateMom={dateMom}
                            hideBorderBottom={true}
                            showReload={true}
                            contentStyle={{maxHeight: 320, overflow: "auto"}}
                            eventDescription={eventDescription}
                        />
                    </Form.Item>
                </div>
            </div>
            {onSave ? (
                <div
                    style={{
                        height: 90,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        paddingRight: 30,
                        borderTop: "1px solid #DADEE4"
                    }}>
                    <SaveButton
                        onClick={() => setChosenFinishButton("onSave")}
                        loading={loading}
                        style={{backgroundColor: weeklyTheme.primary, color: weeklyTheme.textPrimary}}
                    />
                </div>
            ) : null}
            {onSend ? (
                <div
                    style={{
                        height: 90,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        paddingRight: 30,
                        borderTop: "1px solid #DADEE4"
                    }}>
                    <TestButton
                        onClick={() => setChosenFinishButton("onTest")}
                        loading={loading && chosenFinishButton === "onTest"}
                        style={{backgroundColor: weeklyTheme.primary, color: weeklyTheme.textPrimary}}
                    />
                    <SendButton
                        onClick={() => setChosenFinishButton("onSend")}
                        loading={loading && chosenFinishButton === "onSend"}
                        style={{backgroundColor: weeklyTheme.primary, color: weeklyTheme.textPrimary}}
                    />
                </div>
            ) : null}
        </Form>
    );
};
