import React from "react";
import moment from "moment";
import {Dropdown, Menu} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {ChangeableInvoiceStatuses} from "./consts";
import {ProviderNetPaymentTermsTypes} from "../upload/AdminUploadUtils";
import {TableDoubleValueCell} from "../../components";
import {momentHebrewFormat} from "../../utils/DateFormat";
import {InvoicesStatusToNameAndColor} from "../../provider/ProviderManageInvoices/consts";

const getColumns = ({openProviderOrders, openInvoice, updateStatus}) => [
    {
        title: "#",
        key: "#",
        width: 70,
        render(_, __, idx) {
            return `${idx + 1}`;
        }
    },
    {
        title: "מזהה חשבונית",
        dataIndex: "invoiceId"
    },
    {
        title: "שם השותף",
        key: "provider",
        sorter: (r1, r2) => {
            return r1.providerName.localeCompare(r2.providerName, undefined, {sensitivity: "accent"});
        },
        render(_, {providerName, providerEmail}) {
            return (
                <TableDoubleValueCell>
                    {providerName ?? "ספק לא מאושר"}
                    {providerEmail}
                </TableDoubleValueCell>
            );
        }
    },
    {
        title: "חשבונית עבור",
        dataIndex: "month",
        render(month, {providerId, providerName, providerEmail}) {
            return (
                <TableDoubleValueCell className="admin-invoices-table-clickable-sub-text">
                    {momentHebrewFormat(moment(month), "MMMM YY")}
                    <span
                        onClick={() =>
                            openProviderOrders({
                                providerId,
                                providerName,
                                providerEmail,
                                month: moment(month)
                            })
                        }>
                        לפירוט החודשי
                    </span>
                </TableDoubleValueCell>
            );
        }
    },
    {
        title: "תאריך טעינה",
        dataIndex: "createdAt",
        sorter: ({createdAt: r1}, {createdAt: r2}) => r1 - r2,
        render(createdAt) {
            return moment(createdAt).format("HH:mm | DD.MM.YY");
        }
    },
    {
        title: "מס׳ הזמנות משויכות",
        key: "orders",
        sorter: ({orders: r1}, {orders: r2}) => r1 - r2,
        render(_, {orders, totalOrders}) {
            return `${orders.length}/${totalOrders}`;
        }
    },
    {
        title: "סכום החשבונית המוצהר (כולל מע״מ)",
        dataIndex: "price",
        sorter: ({price: r1}, {price: r2}) => r1 - r2,
        render(price, invoice) {
            return (
                <TableDoubleValueCell className="admin-invoices-table-clickable-sub-text">
                    {`${price.toLocaleString()} ₪`}
                    <span onClick={() => openInvoice(invoice)}>לפירוט החשבונית</span>
                </TableDoubleValueCell>
            );
        }
    },
    {
        title: "צפייה במסמך",
        key: "file",
        render(_, {file}) {
            return file ? (
                <div onClick={() => window.open(file.fileUrl, "_blank")} className="admin-invoices-table-file-cell">
                    {file.fileName}
                </div>
            ) : null;
        }
    },
    {
        title: "תנאי שוטף",
        dataIndex: "providerNetPaymentTerms",
        filters: Object.values(ProviderNetPaymentTermsTypes).map(netPaymentValue => ({
            text: netPaymentValue,
            value: netPaymentValue
        })),
        filterMultiple: true,
        onFilter: (value, {providerNetPaymentTerms}) => value === providerNetPaymentTerms
    },
    {
        title: "סטטוס",
        dataIndex: "status",
        sorter: ({status: r1}, {status: r2}) => r1?.id.localeCompare(r2?.id),
        render(status, {invoiceId}) {
            const statusNameAndColor = InvoicesStatusToNameAndColor[status?.id];
            return statusNameAndColor ? (
                <Dropdown
                    trigger={["click"]}
                    overlay={
                        <Menu>
                            {ChangeableInvoiceStatuses.map(statusId => {
                                const optionNameAndColor = InvoicesStatusToNameAndColor[statusId];
                                return (
                                    <Menu.Item
                                        key={statusId}
                                        onClick={() => updateStatus(invoiceId, statusId)}
                                        style={{color: optionNameAndColor.color}}>
                                        {optionNameAndColor.name}
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                    }>
                    <span style={{color: statusNameAndColor.color}}>
                        {statusNameAndColor.name} <DownOutlined />
                    </span>
                </Dropdown>
            ) : null;
        }
    }
];

export default getColumns;
