import React, {useEffect, useState} from "react";
import {StepTitle} from "../../components/StepTitle";
import {StepContainer} from "../../components/StepContainer";
import {PlusOutlined} from "@ant-design/icons";
import {CloseIcon, DestinationIcon, GlobeIcon, TimerIcon, TeamIcon, RemoveIcon} from "../../../icons";
import {Card, Divider, Modal} from "antd";
import {ServicesSelectorModal} from "../../components/ServicesSelectorModal";
import {GroupActivityModal} from "../../components/GroupActivityModal";
import {
    groupActivityIcon,
    discountIcon,
    happyHourIcon,
    privateAppointmentsIcon,
    productIcon
} from "../../images/emojis";
import {StepperButton} from "../../components/StepperButton";
import {SmartImageContainer} from "../../../components/SmartImageContainer";
import cover_placeholder from "../../../images/cover_placeholder.jpg";
import {AutoDirectionProvider} from "../../../AutoDirectionProvider";
import {StyledButton} from "../../../components/StyledButton";
import {clone} from "lodash";

const servicesTypes = [
    {
        title: "Group Activity",
        icon: groupActivityIcon
    },
    {
        title: "Private Appointments",
        icon: privateAppointmentsIcon
    },
    {
        title: "Happy Hour",
        icon: happyHourIcon
    },
    {
        title: "Discount",
        icon: discountIcon
    },
    {
        title: "Products",
        icon: productIcon
    }
];

export const ProviderServiceCenterStep = ({initialValues, basicInfo, onNext}) => {
    const [servicesSelectorModalVisible, setServicesSelectorModalVisible] = useState(false);
    const [groupActivityModalVisible, setGroupActivityModalVisible] = useState(false);
    const [services, setServices] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const closeServicesModal = () => {
        setServicesSelectorModalVisible(false);
    };

    const closeGroupActivityModal = () => {
        setGroupActivityModalVisible(false);
    };

    const onAddService = () => {
        setGroupActivityModalVisible(true);
        closeServicesModal();
    };

    const onProviderBuildFinish = () => {
        onNext({services: services});
    };

    const onSetupGroupActivityFinish = formValues => {
        closeGroupActivityModal();
        setServices(services.concat(formValues));
    };

    const removeService = serviceIndex => {
        const tempServices = clone(services);
        tempServices.splice(serviceIndex, 1);
        setServices(tempServices);
    };

    const getStepTitle = () => {
        return services.length === 0 ? (
            <StepTitle>
                Thank you for that information {basicInfo ? basicInfo.firstName : "!"}.<br />
                Now, what are you selling?
            </StepTitle>
        ) : (
            <StepTitle>
                Great job!
                <br />
                Now, this is how potential clients will see your services:
            </StepTitle>
        );
    };

    return (
        <StepContainer style={{maxWidth: 900}}>
            {getStepTitle()}
            {/* Will be used in the future */}
            {/*<Modal*/}
            {/*    className="wb-provider-services-modal"*/}
            {/*    destroyOnClose={true}*/}
            {/*    closable={true}*/}
            {/*    closeIcon={<CloseIcon style={{fill: "#767F90"}}/>}*/}
            {/*    title={null}*/}
            {/*    footer={null}*/}
            {/*    onCancel={closeServicesModal}*/}
            {/*    visible={servicesSelectorModalVisible}*/}
            {/*>*/}
            {/*    <ServicesSelectorModal servicesTypes={servicesTypes} onServiceSelect={(service) => onAddService(service)}/>*/}
            {/*</Modal>*/}
            <Modal
                className="wb-modal-radius"
                destroyOnClose={true}
                closable={true}
                maskClosable={false}
                closeIcon={<CloseIcon style={{fill: "#767F90"}} />}
                title={null}
                footer={null}
                onCancel={closeGroupActivityModal}
                open={groupActivityModalVisible}>
                <GroupActivityModal onSetupGroupActivityFinish={onSetupGroupActivityFinish} />
            </Modal>
            {services.length === 0 ? (
                <div style={{display: "contents"}}>
                    <div className="wb-provider-add-service-outer" style={{marginTop: "96px"}} />
                    <div
                        className="wb-provider-add-service-inner"
                        style={{marginTop: "-270px"}}
                        onClick={onAddService}></div>
                    <div style={{marginTop: "-135px", display: "flex"}}>
                        <PlusOutlined
                            style={{color: "#797EFF", fontSize: "16px", marginRight: "10px", marginTop: "5px"}}
                        />
                        <div style={{color: "#797EFF", fontSize: "16px"}}>Add Service</div>
                    </div>
                </div>
            ) : null}
            {services.length > 0
                ? services.map((service, index) => (
                      <Card
                          className="wb-provider-content-card"
                          bordered={false}
                          hoverable={true}
                          bodyStyle={{paddingTop: 10}}
                          style={{
                              maxWidth: 800,
                              borderRadius: 10,
                              backgroundColor: "white",
                              boxShadow: "0 5px 15px 0 rgba(0,0,0,0.1)",
                              overflow: "hidden",
                              margin: 10,
                              marginBottom: 40
                          }}
                          cover={
                              <SmartImageContainer
                                  width={800}
                                  height={450}
                                  src={service.imageUrl ? service.imageUrl : cover_placeholder}
                                  style={{
                                      width: "800px",
                                      paddingBottom: "56%"
                                  }}
                              />
                          }>
                          <RemoveIcon
                              className="wb-remove-icon"
                              onClick={() => removeService(index)}
                              style={{
                                  cursor: "pointer",
                                  position: "absolute",
                                  right: 10,
                                  top: 10,
                                  zIndex: 1
                              }}
                          />
                          <div
                              style={{
                                  position: "relative",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column"
                              }}>
                              <div style={{display: "flex", alignItems: "center", color: "#203448", width: "100%"}}>
                                  <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                                      <AutoDirectionProvider
                                          text={service.name}
                                          style={{marginBottom: 5, marginRight: 10, width: "100%", textAlign: "left"}}>
                                          <label
                                              style={{
                                                  minHeight: 24,
                                                  fontSize: 20,
                                                  fontWeight: 700,
                                                  lineHeight: 1.5,
                                                  width: "100%"
                                              }}>
                                              {service.name}
                                          </label>
                                      </AutoDirectionProvider>
                                      <label style={{fontSize: 14, color: "rgba(0,0,0,0.65)", fontWeight: 300}}>
                                          <label
                                              style={{
                                                  fontSize: 16,
                                                  color: "#203448",
                                                  fontWeight: 700,
                                                  marginRight: 7
                                              }}>{`₪${service.price}`}</label>
                                          / {service.per === "PER GROUP" ? "group" : "employee"} per session
                                      </label>
                                  </div>
                                  <div style={{flex: 1}} />
                                  <StyledButton
                                      id={`bookNow-content-${index}`}
                                      onMouseEnter={() =>
                                          (document.getElementById(`bookNow-content-${index}`).style.backgroundColor =
                                              "rgba(0,171,121,0.1)")
                                      }
                                      onMouseLeave={() =>
                                          (document.getElementById(`bookNow-content-${index}`).style.backgroundColor =
                                              "transparent")
                                      }
                                      style={{
                                          borderRadius: 20,
                                          width: 120,
                                          color: "#00AB79",
                                          fontSize: 18,
                                          border: "none",
                                          backgroundColor: "transparent",
                                          boxShadow: "none"
                                      }}>
                                      Book now
                                  </StyledButton>
                              </div>
                              <Divider type="horizontal" style={{marginTop: 20, marginBottom: 20}} />
                              <AutoDirectionProvider text={service.details} style={{width: "100%"}}>
                                  <p style={{fontSize: 16, color: "#637381", marginBottom: 20, whiteSpace: "pre-line"}}>
                                      {service.details}
                                  </p>
                              </AutoDirectionProvider>
                              <div
                                  className="wb-ss-flex-column"
                                  style={{
                                      fontSize: 16,
                                      color: "#637381",
                                      display: "flex",
                                      width: "100%",
                                      flexWrap: "nowrap"
                                  }}>
                                  <span
                                      style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginRight: 25,
                                          marginBottom: 10
                                      }}>
                                      <TimerIcon style={{marginRight: 10}} />
                                      {service.duration} Minutes
                                  </span>
                                  <span
                                      style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginRight: 25,
                                          marginBottom: 10
                                      }}>
                                      <DestinationIcon style={{marginRight: 10}} />
                                      {service.location.join(" / ")}
                                  </span>
                                  <span style={{display: "flex", alignItems: "center", marginBottom: 10}}>
                                      <GlobeIcon style={{marginRight: 10}} />
                                      {service.languages.join(", ")}
                                  </span>
                                  <span
                                      style={{display: "flex", alignItems: "center", marginBottom: 10, marginLeft: 20}}>
                                      <TeamIcon style={{marginRight: 10}} />
                                      Up to {service.groupSize} people
                                  </span>
                              </div>
                          </div>
                      </Card>
                  ))
                : null}
            {services.length > 0 ? (
                <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                    <div style={{marginTop: "65px", display: "flex", marginLeft: "40px"}} onClick={onAddService}>
                        <PlusOutlined
                            style={{color: "#797EFF", fontSize: "16px", marginRight: "10px", marginTop: "5px"}}
                        />
                        <div style={{color: "#797EFF", fontSize: "16px"}}>Add Service</div>
                    </div>
                    <StepperButton
                        style={{marginTop: 50, marginRight: "40px"}}
                        disabled={services.length === 0}
                        canContinueColor="#ED7FA6"
                        onClick={onProviderBuildFinish}>
                        Continue
                    </StepperButton>
                </div>
            ) : null}
        </StepContainer>
    );
};
