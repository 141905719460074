import React from "react";
import {Link} from "react-router-dom";
import {StepperButton} from "./StepperButton";
import {StepTitle} from "./StepTitle";
import {StepContainer} from "./StepContainer";
import {StepSubTitle} from "./StepSubTitle";
import logo from "../images/logo.png";
import {handIcon} from "../images/emojis";
import {SizeAdapterWrapper} from "../../components/ScreenSizeAdapter";

export const WelcomeStep = ({onNext, welcomeProps}) => {
    const {backgroundImage, containerWidth, canContinueColor, title, subtitle} = welcomeProps;

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: `url(${backgroundImage})`,
                width: "100%",
                height: "100vh"
            }}>
            <img
                src={logo}
                alt="well_b_logo"
                style={{marginTop: 80, width: "160px", height: "40px", filter: "brightness(0) invert(1)"}}
            />
            <SizeAdapterWrapper factor={1}>
                <StepContainer
                    style={{
                        width: containerWidth ? containerWidth : "100%",
                        maxWidth: 860,
                        height: 530,
                        backgroundColor: "white",
                        justifyContent: "center",
                        borderRadius: 5
                    }}>
                    <img src={handIcon} alt="welcome" width={100} height={90} />
                    <StepTitle style={{marginTop: 40}}>{title}</StepTitle>
                    <StepSubTitle>{subtitle}</StepSubTitle>
                    <StepperButton style={{marginTop: 50}} canContinueColor={canContinueColor} onClick={onNext}>
                        Let's do this
                    </StepperButton>
                    <div
                        style={{
                            marginTop: 10,
                            color: "#8492A6",
                            fontSize: 16
                        }}>
                        Already have an account? <Link to="/login">Signin</Link>
                    </div>
                </StepContainer>
            </SizeAdapterWrapper>
        </div>
    );
};
