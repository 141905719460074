import React, {useCallback, useContext, useMemo, useState} from "react";
import {StyledButton} from "../../components";
import {EnvironmentOutlined, HeartOutlined, TagsOutlined, WhatsAppOutlined} from "@ant-design/icons";
import classnames from "classnames";
import {minBy, sumBy} from "lodash";
import {getProductMinOrderText, getProductMinPrice} from "../MarketplaceUtils";
import {kosherTypes, kosherTypesToHebrew, ProductKosherTypesPriority} from "../ProductsFilterModal/consts";
import {MarketplaceContext} from "../marketplaceContext";
import {kosherOptions} from "../../admin/upload/AdminUploadUtils";
import {AppContext} from "../../AppContext";

export const TopBarSection = ({productName, anyQuestionsClicked, ProductPriceHeader, locations}) => {
    const {me, company, userLikedItems, likeEntityHandler, unlikeEntityHandler} = useContext(AppContext);
    const {product, productBundle, providerDetails, productId, isProductBundle} = useContext(MarketplaceContext);

    const [isLoadingLike, setIsLoadingLike] = useState(false);

    const productKosherTagContent = useMemo(() => {
        if (isProductBundle) {
            const productKosherType =
                minBy(productBundle?.products, product => ProductKosherTypesPriority[product.productKosherType])
                    ?.productKosherType ?? kosherTypes.NOT_NEEDED;

            if (productKosherType) {
                return (
                    <div>
                        <span>{kosherTypesToHebrew[productKosherType]}</span>
                        <span>{" ✓ "}</span>
                    </div>
                );
            } else {
                return null;
            }
        }

        return (
            <>
                {providerDetails.kosher === kosherOptions[0] || providerDetails.kosher === kosherOptions[2] ? (
                    <div>
                        <span>
                            {product.productKosherType ? kosherTypesToHebrew[product.productKosherType] : "בית עסק כשר"}
                        </span>
                        <span>{" ✓ "}</span>
                    </div>
                ) : null}
            </>
        );
    }, [isProductBundle, productBundle, providerDetails, product]);

    const productMinOrderContent = useMemo(() => {
        if (isProductBundle) {
            const productsMinPrice = sumBy(productBundle?.products, product => getProductMinPrice({service: product}));

            if (productsMinPrice) {
                return (
                    <div
                        style={{
                            direction: "rtl",
                            display: "flex",
                            alignItems: "center",
                            gap: "0.3rem"
                        }}>
                        <TagsOutlined />
                        {`מינ׳ הזמנה: ${productsMinPrice} ₪`}
                    </div>
                );
            }
            return null;
        }

        return (
            <>
                {product && getProductMinOrderText({service: product}) ? (
                    <div
                        style={{
                            direction: "rtl",
                            display: "flex",
                            alignItems: "center",
                            gap: "0.3rem"
                        }}>
                        <TagsOutlined />
                        {getProductMinOrderText({service: product})}
                    </div>
                ) : null}
            </>
        );
    }, [isProductBundle, productBundle, providerDetails, product]);

    const productLocationContent = useMemo(
        () => (
            <>
                {locations.length ? (
                    <div
                        style={{
                            direction: "rtl",
                            display: "flex",
                            alignItems: "center",
                            gap: "0.3rem"
                        }}>
                        <EnvironmentOutlined />
                        {locations.join("/")}
                    </div>
                ) : null}
            </>
        ),
        [locations]
    );

    const isProductHasLiked = useMemo(
        () => !!userLikedItems.find(userLikedItem => userLikedItem?.targetId === productId),
        [userLikedItems, productId]
    );

    const onClickLikeHandler = useCallback(async () => {
        setIsLoadingLike(true);
        if (isProductHasLiked) {
            await unlikeEntityHandler("product", product.productId);
        } else {
            await likeEntityHandler("product", product.productId);
        }
        setIsLoadingLike(false);
    }, [isProductHasLiked, product?.productId, userLikedItems]);

    return (
        <>
            <div id={"upperBar"}>
                <div className="product-upper-container">
                    <div className="item">
                        <div id="name" style={{fontWeight: "bold"}}>
                            {productName}
                        </div>

                        <div className="actions-container" id="actions">
                            <StyledButton className="whatsapp-icon" id="whatsApp" onClick={anyQuestionsClicked}>
                                <WhatsAppOutlined />
                                <div>{"יש שאלות? זמינים בוואטסאפ :)"}</div>
                            </StyledButton>
                            <StyledButton
                                className={classnames("like-icon", {"like-icon-selected": isProductHasLiked})}
                                disabled={me?.type === "provider" || isLoadingLike}
                                onClick={onClickLikeHandler}
                                id="likebutton">
                                <HeartOutlined />
                                <div>{"שמירה"}</div>
                            </StyledButton>
                        </div>
                    </div>

                    <div className="item">
                        <ProductPriceHeader companyOrdersDiscount={company?.ordersDiscount} />

                        <div className="product-meta-data" id="productAdditionalInfo">
                            {productLocationContent}
                            {productMinOrderContent}
                            {productKosherTagContent}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
