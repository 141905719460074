import React, {useMemo} from "react";
import {sumBy, uniqBy} from "lodash";
import {CustomerType} from "./consts";
import {StatisticsLabel, getPercentageItemProperties} from "../components/StatisticsLabel";
import {useRequest} from "../../utils/hooks";

export const CompanyStatisticsLabel = ({companies, companiesStatics, companyBudgets}) => {
    const statistics = useMemo(() => {
        const customers = Object.entries(companiesStatics ?? {})
            .filter(([companyId]) => companies?.some(company => company.companyId === companyId))
            .map(([companyId, companyStats]) => companyStats);
        const returningCustomers = customers.filter(({customerType}) => customerType?.includes(CustomerType.Returning));
        const returningActiveCustomers = returningCustomers.filter(({customerType}) =>
            customerType?.includes(CustomerType.ActiveReturning)
        );

        const totalBudgetAmount = sumBy(companyBudgets, companyBudget => companyBudget?.totalBudget ?? 0);

        return [
            {
                name: "לקוחות",
                ...getPercentageItemProperties(customers.length, companies?.length),
                tooltip: "משתמשים רשומים שרכשו לפחות פעם אחת",
                loading: !customers,
                children: [
                    {
                        name: "לקוחות מזדמנים",
                        ...getPercentageItemProperties(customers?.length - returningCustomers.length, customers.length),
                        tooltip: "לקוחות שאינם לקוחות חוזרים",
                        loading: !customers
                    },
                    {
                        name: "לקוחות חוזרים",
                        ...getPercentageItemProperties(returningCustomers.length, customers.length),
                        tooltip:
                            "לקוחות שביצעו לפחות 3 עסקאות יוניקיות (כאשר עסקה אחת לפחות הזומנה לאחר מועד האספקה של אחרת)",
                        loading: !customers
                    },
                    {
                        name: "לקוחות חוזרים פעילים (משומרים)",
                        ...getPercentageItemProperties(returningActiveCustomers.length, returningCustomers.length),
                        tooltip: "לקוחות חוזרים שביצעו או קיבלו לפחות עסקה אחת ב60 יום האחרונים",
                        classname: "company-statistics-label-green",
                        loading: !customers
                    },
                    {
                        name: "לקוחות חוזרים לא פעילים (נוטשים)",
                        ...getPercentageItemProperties(
                            returningCustomers.length - returningActiveCustomers.length,
                            returningCustomers.length
                        ),
                        tooltip: "לקוחות חוזרים שאינם פעילים",
                        classname: "company-statistics-label-red",
                        loading: !customers
                    }
                ]
            },
            {
                name: "משתמשים שאינם לקוחות",
                ...getPercentageItemProperties(companies?.length - customers.length, companies?.length),
                tooltip: "משתמשים שמעולם לא ביצעו עסקה",
                loading: !customers,
                showAsParent: true,
                children: []
            },
            {
                name: "תקציבים",
                tooltip: "מידע אודות התקציבים של החברות",
                showAsParent: true,
                children: [
                    {
                        name: "תקציב חברות (שולם מראש)",
                        value: totalBudgetAmount,
                        tooltip: "סך הכל תקציבי חברות (לא כולל תקציב מסגרת)",
                        loading: !companyBudgets
                    }
                ]
            }
        ];
    }, [companiesStatics, companies, companyBudgets]);

    const uniqBusinessNumber = useMemo(() => uniqBy(companies, "businessNumber"), [companies]);

    return (
        <StatisticsLabel
            statistics={statistics}
            title={
                <span>
                    {companies?.length ?? "X"} משתמשים רשומים ({uniqBusinessNumber?.length ?? "X"} ח.פ יונקיים)
                </span>
            }
        />
    );
};
