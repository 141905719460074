import {useEffect, useState} from "react";
import {Prompt} from "react-router";
import CallToActionModal from "./CallToActionModal";
import React from "react";

export const ChangesNotSavedModal = ({history, changesMade, onSave}) => {
    const [visible, setVisible] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [nextLocation, setNextLocation] = useState(null);

    useEffect(() => {
        if (confirmed) {
            history.push(nextLocation.pathname);
        }
    });

    const handleBlockNavigation = location => {
        if (confirmed) {
            return true;
        } else {
            setVisible(true);
            setNextLocation(location);
            return false;
        }
    };

    const navigateToNextLocation = () => {
        setConfirmed(true);
        setVisible(false);
    };

    const closeMe = () => {
        setVisible(false);
    };

    const saveChanges = () => {
        onSave();
        setVisible(false);
    };

    return (
        <>
            <Prompt when={changesMade} message={handleBlockNavigation} />
            <CallToActionModal
                imageName="oops2"
                closable={true}
                onClose={() => closeMe()}
                visible={visible}
                history={history}
                title="Do you want to save your changes?"
                actionColor="white"
                actionBackgroundColor="#2F3E83"
                actionText="Save"
                actionHandler={() => saveChanges()}
                actionStyle={{width: 120}}
                anotherActionColor="#F06060"
                anotherActionBackgroundColor="white"
                anotherActionText="Continue without saving"
                anotherActionHandler={() => navigateToNextLocation()}
                anotherActionStyle={{width: 200, border: "1px solid #F06060"}}
                actionsContainerStyle={{marginTop: 30}}
            />
        </>
    );
};
