import React, {useCallback, useMemo, useState} from "react";
import {Modal, Select, Form, message, Button} from "antd";
import {set} from "lodash";
import classnames from "classnames";
import {AccountingDocumentType, FileSizeLimitInMB, FormErrorTypes, UploadServerURI} from "./consts";
import {FormInputV2} from "../../components/form";
import {FileUploadBase} from "../../components/FileUpload/FileUploadBase";
import {createUrlWithoutTransformations} from "../../utils/cloudinaryUtils";
import "./provider-accounting-document-modal.css";

export const ProviderAccountingDocumentModal = ({confirmInvoiceData, onSave, onClose, isExemptDealerType}) => {
    const {price, monthInHebrew, orders} = useMemo(() => confirmInvoiceData, [confirmInvoiceData]);

    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState({});
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [errors, setErrors] = useState({});

    const onBeforeFileUpload = useCallback(
        file => {
            const isLtSizeLimitInMB = file.size / 1024 / 1024 < FileSizeLimitInMB;
            if (!isLtSizeLimitInMB) {
                message.error(`File must smaller than ${FileSizeLimitInMB}MB!`);
                return false;
            }

            setFileList([file]);
            return false;
        },
        [FileSizeLimitInMB]
    );

    const updateErrors = useCallback((newError, value) => {
        setErrors(currentErrors => set({...currentErrors}, newError, value));
    }, []);

    const handleUpload = useCallback(async () => {
        setUploading(true);

        const invoiceFiles = [];
        for (const file of fileList) {
            const formData = new FormData();
            formData.append("file", file);

            const fileRequest = await fetch(UploadServerURI, {
                method: "POST",
                body: formData
            });

            if (!fileRequest.ok) {
                message.error("upload failed.");
                updateErrors(FormErrorTypes.FILE_UPLOAD, true);
                return {error: FormErrorTypes.FILE_UPLOAD};
            }

            const fileJson = await fileRequest.json();

            const url = createUrlWithoutTransformations(fileJson.imageUrl);

            const fileInfo = {
                fileId: fileJson.imageId,
                fileName: file.name,
                fileUrl: url
            };

            invoiceFiles.push(fileInfo);
            setFileList(currentFileList => currentFileList.filter(({id}) => id !== file.id));
            message.success("upload successfully " + file.name);
        }

        setUploading(false);
        return invoiceFiles;
    }, [fileList]);

    const PriceConfirmMessage = useCallback(() => {
        const formattedPrice = Number(price).toLocaleString(undefined, {maximumFractionDigits: 2});

        if (errors[FormErrorTypes.INVOICE_AMOUNT_NOT_MATCH]) {
            return (
                <span className="text-error">
                    הסכום שהוזן אינו תואם את ההזמנות שסומנו - אנא הזן את החשבונית בדיוק לפי החישוב {formattedPrice} ₪ או
                    תיצור איתנו קשר לתיקון ההזמנות
                </span>
            );
        } else {
            return (
                <span>
                    עבור {orders?.length} הזמנות המסומנות הסכום אמור להיות {formattedPrice}₪ כולל מעמ
                </span>
            );
        }
    }, [formValues?.price, price, errors, orders]);

    return (
        <Modal open={!!confirmInvoiceData} onCancel={onClose} onOk={onSave} footer={null} width={"60vw"}>
            <Form
                form={form}
                initialValues={{
                    invoiceType: "BILLING_INVOICE"
                }}
                onFinish={async values => {
                    updateErrors(
                        FormErrorTypes.INVOICE_AMOUNT_NOT_MATCH,
                        values.price && Number(values.price) !== price
                    );
                    if (values.price && Number(values.price) !== price) {
                        message.error(
                            `הסכום שהוזן אינו תואם את ההזמנות שסומנו - אנא הזן את ה${
                                isExemptDealerType ? "קבלה" : "חשבונית"
                            } בדיוק לפי החישוב או תיצור איתנו קשר לתיקון ההזמנות`
                        );
                        return;
                    }

                    if (!fileList.length) {
                        message.error(`אנא העלה את הקבצים הרלוונטים עבור ה${isExemptDealerType ? "קבלה" : "חשבונית"}`);
                        return;
                    }

                    const financeFile = await handleUpload();

                    if (!financeFile.error && financeFile[0]) {
                        onSave({...values, file: financeFile[0]});
                        setErrors({});
                        form.resetFields();
                    }
                }}
                onValuesChange={changedField => {
                    const [key, value] = Object.entries(changedField)[0];
                    setFormValues(currentFormValues => set({...currentFormValues}, key, value));
                }}>
                <div className="provider-accounting-document-modal">
                    <div className="title">טעינת מסמך חשבונאי</div>

                    <div className="form-item">
                        <div className="form-item-text">עבור הזמנות בחודש</div>
                        <div className={classnames("form-item-action", "text-blue")}>{monthInHebrew}</div>
                    </div>

                    <div className="form-item">
                        <div className="form-item-text">סוג המסמך</div>
                        <div className="form-item-action">
                            <Form.Item
                                name="invoiceType"
                                rules={[
                                    {
                                        required: true,
                                        message: "אנא בחר סוג מסמך"
                                    }
                                ]}>
                                <Select>
                                    {Object.entries(AccountingDocumentType).map(([key, value]) => (
                                        <Select.Option key={key} value={key}>
                                            {value}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="form-item-text">מס׳ ה{isExemptDealerType ? "קבלה" : "חשבונית"}</div>
                        <div className="form-item-action">
                            <Form.Item
                                name="invoiceNumber"
                                rules={[
                                    {
                                        required: true,
                                        message: `אנא הכנס מספר ${isExemptDealerType ? "קבלה" : "חשבונית"}`
                                    }
                                ]}>
                                <FormInputV2 type="number" onWheel={e => e.target.blur()} />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="form-item-text">
                            {isExemptDealerType ? (
                                <span>אנא אשר את סכום הקבלה הנטענת (לא כולל מע"מ)</span>
                            ) : (
                                <span>אנא אשר את סכום החשבונית הנטענת (כולל מע"מ)</span>
                            )}

                            <PriceConfirmMessage />
                        </div>
                        <div className="form-item-action">
                            <Form.Item
                                name="price"
                                style={{display: "flex"}}
                                rules={[
                                    {
                                        required: true,
                                        message: "אנא הכנס מחיר"
                                    }
                                ]}>
                                <FormInputV2 type="number" onWheel={e => e.target.blur()} />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="bar-line" />

                    <div>
                        <div className="form-item-text">טעינת קובץ</div>
                        <div>
                            {
                                <Form.Item name={"file"}>
                                    <FileUploadBase
                                        fileList={fileList}
                                        setFileList={setFileList}
                                        onBeforeUpload={onBeforeFileUpload}
                                        acceptedFormats={"application/pdf,image/png,image/jpeg"}
                                        showUploadButton={false}
                                        multiple={false}
                                    />
                                </Form.Item>
                            }
                        </div>
                    </div>

                    <div className="form-buttons">
                        <Button className="cancel-button" disabled={uploading} onClick={onClose}>
                            ביטול
                        </Button>

                        <Button className="save-button" disabled={uploading} htmlType={"submit"}>
                            שמירה
                        </Button>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};
