import {excludePaths} from "./consts.js";

export const authExcluded = pathName => {
    return excludePaths.some(pathItem => pathItem.path.test(pathName));
};

export class StringBuilder {
    strings = [];
    separator;

    constructor(initValue = null, separator = " ") {
        this.strings = initValue ? [initValue] : [];
        this.separator = separator;
    }

    append(...value) {
        this.strings.push(...value);
        return this;
    }

    toString() {
        return this.strings
            .filter(Boolean)
            .join(this.separator ?? " ")
            .replace(/ {2,}/g, " ");
    }
}
