import React, {useContext, useState} from "react";
// import MultiToggle from "react-multi-toggle";
import {PageTitleLabel} from "../../components/PageTitle";
import CompanySchedule from "../CompanySchedule";
import {Redirect, Route, Switch} from "react-router";
import {CompanyContentDataStore} from "./datastores/CompanyContentDataStore";
import {parse as qsParse} from "query-string";
import {CompanyContentDefaults} from "./CompanyContentDefaults";
import {AppContext} from "../../AppContext";

const ToggleOption = {
    Events: 0,
    Services: 1,
    Discounts: 2,
    Benefits: 3,
    Information: 4,
    Recommendations: 5,
    Updates: 6
};

export const findContentSectionById = sectionId => {
    return CompanyContent.Sections.find(s => s.sectionId === sectionId);
};

const findContentSectionByValue = value => {
    return CompanyContent.Sections.find(s => s.value === value);
};

export const getCompany = () => {
    const {company} = useContext(AppContext);
    return company;
};

const filterContentSectionWithCondition = conditionFn => {
    return CompanyContent.Sections.filter(s => conditionFn(s));
};

export const onlyContentSectionsWithManagedData = () =>
    filterContentSectionWithCondition(s => typeof s.value === "number");

export const CompanyContent = ({history, location}) => {
    const [toggle, setToggle] = useState(0);

    let {branchId} = qsParse(location.search);
    if (branchId === "Global") {
        branchId = null;
    }

    const onToggleChange = val => {
        setToggle(val);
        const sectionId = getSectionIdFromToggleValue(val);
        history.push(`/dashboard/content/${sectionId}`);
    };

    const getSectionIdFromToggleValue = val => {
        return findContentSectionByValue(val).sectionId;
    };

    const getToggleValueFromName = sectionId => {
        return findContentSectionById(sectionId).value;
    };

    const extractPathPostfix = () => {
        const parts = location.pathname.split("/");
        return parts[parts.length - 1];
    };

    const fixToggleAccordingToPath = () => {
        const pathVal = getToggleValueFromName(extractPathPostfix());
        if (pathVal !== toggle) {
            setToggle(pathVal);
        }
    };

    if (location.pathname === "/dashboard/content") {
        return <Redirect to={`/dashboard/content/events${location.search || ""}`} />;
    }

    fixToggleAccordingToPath();

    return (
        <div style={{display: "flex", alignItems: "center", flexDirection: "column", minWidth: 1200}}>
            <PageTitleLabel style={{marginTop: 30, marginBottom: 30}}>
                Manage all welfare components in one place
            </PageTitleLabel>
            <div
                data-title="Add additional content"
                data-intro="Have more to offer than just events? Setup additional content for your employees in a few clicks"
                data-namespace="admin"
                data-position="bottom"
                data-step="3"
                data-scrollTo="tooltip"
                style={{width: "100%"}}>
                <div className="wb-content-toggle-list">
                    {onlyContentSectionsWithManagedData().map(section => (
                        <div
                            key={section.sectionId}
                            className={
                                section.value === toggle
                                    ? "wb-content-toggle-item-overlay-selected"
                                    : "wb-content-toggle-item-overlay"
                            }>
                            <div
                                id={section.id}
                                className={
                                    section.value === toggle
                                        ? "wb-content-toggle-item-selected"
                                        : "wb-content-toggle-item"
                                }
                                onClick={() => onToggleChange(section.value)}>
                                {section.displayName}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Switch>
                {onlyContentSectionsWithManagedData().map(({sectionId, DataStore}) => (
                    <Route
                        key={`content-route-${sectionId}`}
                        exact
                        path={`/dashboard/content/${sectionId}`}
                        component={props => (
                            <div>{DataStore ? <DataStore {...props} /> : <CompanyContentDataStore {...props} />}</div>
                        )}
                    />
                ))}
            </Switch>
        </div>
    );
};

CompanyContent.Sections = [
    {
        displayName: "Top",
        sectionId: "top",
        defaultDesign: theme => ({
            coverPhoto: theme ? CompanyContentDefaults.top[theme.name].coverPhoto : null,
            title: "Anything you need!",
            description: {
                enabled: true,
                text: "We gather all information, events and recommendations for you in one place!"
            },
            search: {
                enabled: true,
                text: "Search for anything"
            },
            display: true
        })
    },
    {
        displayName: "Calendar",
        sectionId: "calendar",
        defaultDesign: theme => ({
            title: "Checkout our company calendar",
            display: true
        })
    },
    {
        displayName: "Birthdays",
        sectionId: "birthdays",
        defaultDesign: theme => ({
            title: "This week's celebrations...",
            action: {
                enabled: true,
                color: theme ? theme.primaryColor : "#00CF82",
                text: "Share your wishes"
            },
            display: true
        })
    },
    {
        displayName: "Picker",
        sectionId: "picker",
        defaultDesign: theme => ({
            title: "Everything you need",
            display: true
        })
    },
    {
        displayName: "Events",
        value: ToggleOption.Events,
        sectionId: "events",
        DataStore: props => <CompanySchedule {...props} hideTitle={true} />,
        defaultDesign: theme => ({
            coverPhoto: theme ? CompanyContentDefaults.events[theme.name].coverPhoto : null,
            title: getCompany() ? `This week at ${getCompany().name}` : "Coming up this week",
            action: {
                enabled: true,
                text: "See all events",
                color: theme ? theme.textColor : "#595AD4"
            },
            display: true
        })
    },
    {
        displayName: "Perks & Services",
        value: ToggleOption.Services,
        sectionId: "services",
        order: 0,
        defaultDesign: theme => ({
            coverPhoto: theme ? CompanyContentDefaults.services[theme.name].coverPhoto : null,
            title: "We make your life easier",
            action: {
                enabled: true,
                text: "See all services",
                color: theme ? theme.textColor : "#595AD4"
            },
            display: true
        })
    },
    {
        displayName: "Updates",
        value: ToggleOption.Updates,
        optionClass: "wb-content-toggle-updates",
        sectionId: "updates",
        defaultDesign: theme => ({
            backgroundPhoto: theme ? CompanyContentDefaults.updates[theme.name].backgroundPhoto : null,
            coverPhoto: theme ? CompanyContentDefaults.updates[theme.name].coverPhoto : null,
            display: true
        })
    },
    {
        displayName: "Benefits",
        value: ToggleOption.Benefits,
        sectionId: "benefits",
        order: 1,
        defaultDesign: theme => ({
            coverPhoto: theme ? CompanyContentDefaults.benefits[theme.name].coverPhoto : null,
            title: "We care about you",
            action: {
                enabled: true,
                text: "See all benefits",
                color: theme ? theme.textColor : "#595AD4"
            },
            display: true
        })
    },
    {
        displayName: "Discounts",
        value: ToggleOption.Discounts,
        sectionId: "discounts",
        order: 2,
        defaultDesign: theme => ({
            coverPhoto: theme ? CompanyContentDefaults.discounts[theme.name].coverPhoto : null,
            title: "We get you the best deals",
            action: {
                enabled: true,
                text: "See all discounts",
                color: theme ? theme.textColor : "#595AD4"
            },
            display: true
        })
    },
    {
        displayName: "Information",
        title: "Everything you need to know",
        value: ToggleOption.Information,
        sectionId: "information",
        order: 3,
        defaultDesign: theme => ({
            coverPhoto: theme ? CompanyContentDefaults.information[theme.name].coverPhoto : null,
            title: "Everything you need to know",
            action: {
                enabled: true,
                text: "See all information",
                color: theme ? theme.textColor : "#595AD4"
            },
            display: true
        })
    },
    {
        displayName: "Recommendations",
        value: ToggleOption.Recommendations,
        optionClass: "wb-content-toggle-recommendations",
        sectionId: "recommendations",
        order: 4,
        defaultDesign: theme => ({
            coverPhoto: theme ? CompanyContentDefaults.recommendations[theme.name].coverPhoto : null,
            title: "Let us recommend you some cool stuff",
            action: {
                enabled: true,
                text: "See all recommendations",
                color: theme ? theme.textColor : "#595AD4"
            },
            display: true
        })
    }
];
