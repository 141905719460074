import {Form, Input, Modal, message} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {StyledButton} from "../../components";
import PageTitleHeader from "../../components/PageTitle";
import {ImageUpload} from "../../utils/ImageUpload";
import {StepperParagraphInput} from "../../wizards/components/StepperParagraphInput";

export const EditBannerModal = ({visible, onClose, hideFields, onSave, banner, index, bannerDimension = {}}) => {
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState({});

    useEffect(() => {
        setFormValues({...banner});
    }, [banner]);

    const innerOnSave = useCallback(
        values => {
            onSave({...values}, index);
            onClose();
        },
        [onSave, onClose]
    );

    const onFileUploadFinish = useCallback(
        image => {
            setFormValues(prevValues => ({
                ...prevValues,
                image
            }));
        },
        [setFormValues]
    );

    return (
        <Modal
            centered={true}
            width={900}
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={() => {
                form.resetFields();
                setFormValues({});
                onClose();
            }}
            maskClosable={false}
            footer={null}
            title={null}
            closable={true}>
            <div style={{display: "flex", width: "100%", flexDirection: "column", alignItems: "center"}}>
                <PageTitleHeader showBack={false}>ערוך באנר</PageTitleHeader>
                <Form
                    form={form}
                    onFinish={values => {
                        const {image, name} = values ?? {};

                        if (!image) {
                            innerOnSave({});
                            form.resetFields();
                            return;
                        }

                        if (image && !name) {
                            message.error("שגיאה! יש לבחור מזהה לבאנר באותיות באנגלית בלבד");
                            return;
                        }

                        innerOnSave(values);
                        form.resetFields();
                    }}
                    fields={Object.keys(formValues).map(key => {
                        return {
                            name: [key],
                            value: formValues[key]
                        };
                    })}
                    onValuesChange={(_, allValues) => {
                        setFormValues(allValues);
                    }}>
                    <label style={{fontSize: 16, color: "var(--secondary-color)"}}>תמונה - גודל מומלץ (1240x210)</label>
                    <Form.Item name="image" valuePropName="image" style={{marginTop: 20}}>
                        <ImageUpload
                            customWidth={bannerDimension.width}
                            customHeight={bannerDimension.height}
                            customText={"Upload Image (PNG / JPEG)"}
                            imageUrl={formValues.image}
                            onFileUploadFinish={onFileUploadFinish}
                        />
                    </Form.Item>
                    <label style={{fontSize: 16, color: "var(--secondary-color)"}}>
                        מזהה באנר (שם באנגלית ללא רווחים)
                    </label>
                    <Form.Item name="name" style={{width: "800px", marginTop: 20}}>
                        <Input />
                    </Form.Item>
                    <label style={{fontSize: 16, color: "var(--secondary-color)"}}>מלל על הבאנר (לא חובה)</label>
                    <Form.Item
                        name="text"
                        rules={[
                            {
                                required: false,
                                message: "Please add description for special category."
                            }
                        ]}
                        style={{width: "800px", marginTop: 20}}>
                        <StepperParagraphInput
                            style={{marginTop: 10, width: "800px"}}
                            maxLength={2000}
                            autoSize={{minRows: 8, maxRows: 8}}
                        />
                    </Form.Item>

                    {!hideFields?.includes("link") ? (
                        <>
                            <label style={{fontSize: 16, color: "var(--secondary-color)"}}>
                                קישור (לעמוד בתוך המערכת יש להכניס את הכתובת אחרי הסלאש, לדוג: dashboard/services/)
                            </label>
                            <Form.Item
                                name="link"
                                className="edit-banners-modal-link-input"
                                rules={[
                                    {
                                        required: true,
                                        message: "יש להוסיף קישור"
                                    }
                                ]}
                                style={{width: "800px", marginTop: 20}}>
                                <Input />
                            </Form.Item>
                        </>
                    ) : null}

                    {!hideFields?.includes("buttonText") ? (
                        <>
                            <label style={{fontSize: 16, color: "var(--secondary-color)"}}>
                                טקסט על הכפתור (אם ריק הכפתור לא יוצג)
                            </label>
                            <Form.Item name="buttonText" style={{width: "300px", marginTop: 20}}>
                                <Input />
                            </Form.Item>
                        </>
                    ) : null}
                    <Form.Item>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <StyledButton
                                style={{
                                    backgroundColor: "#ED7FA6",
                                    color: "white",
                                    width: "150px",
                                    borderRadius: "5px"
                                }}
                                htmlType="submit">
                                הוסף
                            </StyledButton>
                            <StyledButton
                                style={{
                                    backgroundColor: "white",
                                    color: "#ED7FA6",
                                    width: "150px",
                                    borderRadius: "5px"
                                }}
                                onClick={() => onClose()}>
                                ביטול
                            </StyledButton>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};
