import moment from "moment";
import React from "react";
import {excelSheetsTitle} from "./consts";
import {getDeliveryRow, getDiscountRow, getTipRow} from "./orderExcelExporterUtils";
import {getStatus, OrderStatus, statusToTextAndColor} from "../components";
import {createWorkbook, createWorksheet, DownloadExcel} from "../utils/excelUtils.js";
import {extractVatPercent, reduceVAT} from "../utils/calculationUtils";
import {removeEmojis} from "../utils/StringFormat";
import {PaymentStatuses} from "../components/OrderStatusLabel";
import {ServiceIdToName} from "../consts.js";

const productNameColumnNumber = 4,
    productSummaryColumnNumber = 7,
    priceColumnNumber = 8,
    priceWithoutTaxColumnNumber = 9;

export const ExportCompanyOrdersToExcel = async (orders, companyUsers) => {
    const workbook = createWorkbook();

    const excelSheets = [
        {
            title: excelSheetsTitle.orders,
            list: orders
        }
    ];

    excelSheets.forEach(({list = [], title}) => {
        const sheet = createWorksheet(workbook, title);
        addColumns(sheet);
        addRows(sheet, list, companyUsers);
    });

    await DownloadExcel(workbook, "orders");
};

const addColumns = sheet => {
    sheet.columns = [
        {header: "מבצע ההזמנה", key: "companyName"},
        {header: "מספר הזמנה", key: "proposalId"},
        {header: "מייל", key: "userName"},
        {header: "מחלקה", key: "department"},
        {header: "שם החבילה", key: "productName"},
        {header: "תאריך הזמנה", key: "orderDate"},
        {header: "תאריך הספקה", key: "startTime"},
        {header: "שם המוצר ללקוח", key: "orderSummary"},
        {header: "סכום ₪ (כולל מעמ)", key: "price"},
        {header: "סכום ₪ (לפני מעמ)", key: "priceWithoutTax"},
        {header: "איש קשר לקבלת ההזמנה", key: "contactName"},
        {header: "טלפון", key: "contactPhone"},
        {header: "סטטוס ההזמנה", key: "status"},
        {header: "ברכה", key: "wishes"},
        {header: "קטגוריית צריכה", key: "category"}
    ];
};

const addRows = (sheet, orders, companyUsers) => {
    const rows = orders.reduce((allRows, order) => {
        order.productName = removeEmojis(order.productName);
        const createdAt = moment(order.createdAt).format("DD.MM.YY");
        const supplyDate = moment(order.dtstart).format("DD.MM.YY");

        function getOrderUser(order) {
            if (!companyUsers?.length) return "";

            return companyUsers.find(user => user.userId === order?.creatingUserId);
        }

        function getOrderContact(order) {
            if (order.employeesDeliveryDetail) {
                const {name, phone} = order.employeesDeliveryDetail?.[0] ?? {};
                return {name, phone};
            }

            if (order?.singleEmployeeDetails) {
                const {name} = order.singleEmployeeDetails;
                return {name, phone: order?.sendOptions?.sms};
            }

            if (order.employeesExcel) {
                return {name: "מספר מקבלי המארז"};
            }

            return {name: order.companyContactName, phone: order.companyPhone};
        }

        function getOrderStatus(order) {
            function statusResolver(statusesToShow = null, hidePaymentStatuses = true) {
                const statusArray = getStatus(order);

                const filteredStatues = statusArray.filter(
                    ({id: status}) => !hidePaymentStatuses || !PaymentStatuses.includes(status)
                );

                return (
                    filteredStatues.find(({id: status}) =>
                        statusesToShow?.length ? statusesToShow.includes(status) : true
                    ) ?? {}
                );
            }

            const {id: status} = statusResolver();

            if (status === OrderStatus.pending) {
                return "ממתין לאישור";
            } else if (status === OrderStatus.pendingDelivery) {
                return "מועד האפסקה חלף, ממתין לחיווי";
            } else if (statusToTextAndColor[status]) {
                const [text] = statusToTextAndColor[status];
                return text;
            }

            return null;
        }

        const user = getOrderUser(order);
        const contactInfo = getOrderContact(order);
        const orderStatus = getOrderStatus(order);

        const row = [
            user?.firstName || "",
            order?.proposalId || "",
            order?.creatingEmail || "",
            user?.department || "",
            order?.productName || "",
            createdAt,
            supplyDate,
            `${order.productName} ${supplyDate} (${order.productAmount ?? ""} ${order.productAmountType ?? ""}) ${
                order.invoiceComment ? `- ${order.invoiceComment}` : ""
            } ${order.invoicePONumber ? `- ${order.invoicePONumber}` : ""}`,
            order.offeredPrice,
            reduceVAT(order.offeredPrice, order.vat),
            contactInfo?.name,
            contactInfo?.phone,
            orderStatus,
            order?.singleEmployeeDetails?.wishes ?? order?.employeesDeliveryDetail?.[0]?.wishes ?? "",
            ServiceIdToName?.[order?.serviceId] ?? order?.serviceId
        ];

        allRows.push(row);

        if (order.deliveryPrice) {
            const deliveryRow = getDeliveryRow(
                row,
                order,
                supplyDate,
                false,
                false,
                productNameColumnNumber,
                productSummaryColumnNumber,
                priceColumnNumber,
                priceWithoutTaxColumnNumber
            );
            allRows.push(deliveryRow);
        }

        if (order?.couponDiscountAmount || order?.companyDiscountAmount) {
            const discountRow = getDiscountRow(
                row,
                order,
                createdAt,
                false,
                productNameColumnNumber,
                productSummaryColumnNumber,
                priceColumnNumber,
                priceWithoutTaxColumnNumber
            );
            allRows.push(discountRow);
        }

        if (order.tip) {
            const tipRow = getTipRow(
                row,
                order,
                createdAt,
                false,
                productNameColumnNumber,
                productSummaryColumnNumber,
                priceColumnNumber,
                priceWithoutTaxColumnNumber
            );
            allRows.push(tipRow);
        }

        return allRows;
    }, []);

    sheet.addRows(rows);
};
