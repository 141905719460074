import React from "react";
import {Tooltip} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {CheckboxController, CreditCardPreview} from "../PaymentInputHelper.jsx";
import {PaymentType, PaymentTypeToName} from "../../../../consts.js";
import "./ten-bis-payment-input.css";
import {GroupedDigitInput} from "../../../../components/GroupedDigitInput";
import classnames from "classnames";
import {getTenBisLastFourDigits} from "./utils.js";
import {StringBuilder} from "../../../../AppUtils.js";

export const TenBisPaymentInput = ({paymentType, setPaymentType, updateFormValues, tenBisCreditCard}) => {
    return (
        <>
            <div className={classnames("ten-bis-payment-input-container")}>
                <div className="payment-input-options-header">
                    <CheckboxController
                        isChecked={paymentType === PaymentType.TenBisCard}
                        setPaymentType={setPaymentType}
                        type={PaymentType.TenBisCard}
                    />
                    {new StringBuilder("תשלום באמצעות").append(PaymentTypeToName[PaymentType.TenBisCard]).toString()}
                    <Tooltip
                        title={
                            <p className="payment-input-tooltip-text">
                                בחירה באפשרות זו מאפשרת לכם לשלם על ההזמנה באמצעות כרטיס התן ביס שלכם. חשוב לשים לב אם
                                קיימת לכם תקרה יומית/חודשית בכרטיס בעת ביצוע ההזמנה
                            </p>
                        }>
                        <QuestionCircleOutlined className="payment-input-tooltip-icon" />
                    </Tooltip>
                </div>

                <CreditCardPreview last4Digits={getTenBisLastFourDigits(tenBisCreditCard)} />

                <GroupedDigitInput
                    onUpdate={value => updateFormValues("tenBisCreditCard", value)}
                    className="ten-bis-payment-input-digit-group-container"
                    disabled={paymentType !== PaymentType.TenBisCard}
                />
            </div>
        </>
    );
};
