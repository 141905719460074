import React, {useCallback, useMemo, useState} from "react";
import {Button, Modal} from "antd";
import {sumBy} from "lodash";
import {CalendarOutlined} from "@ant-design/icons";
import CalculatorCategoriesBar from "./CalculatorCategoriesBar";
import HappinessCalculatorCalendarPreview from "./HappinessCalculatorCalendarPreview";
import "./happiness-calculator-summary.css";

const HappinessCalculatorSummary = ({
    categoriesWithTotalPrice,
    allCategoriesPrice,
    employeesAmount,
    monthAmounts,
    onSave,
    log,
    tableData
}) => {
    const [loadingSave, setLoadingSave] = useState(false);
    const [showCalendarModal, setShowCalendarModal] = useState(false);

    const bottomStats = useMemo(() => {
        if (!categoriesWithTotalPrice?.length) {
            return null;
        }

        return [
            {
                title: "עלות בפריסה חודשית לעובד",
                value: `${(sumBy(categoriesWithTotalPrice, "pricePerEmployee") / monthAmounts)?.toLocaleString(
                    undefined,
                    {maximumFractionDigits: 2}
                )} ₪`
            },
            ...categoriesWithTotalPrice.map(({categoryName, occasionsCount}) => ({
                title: `${categoryName} בתקופה`,
                value: occasionsCount?.toLocaleString(undefined, {maximumFractionDigits: 1})
            }))
        ];
    }, [categoriesWithTotalPrice, monthAmounts, employeesAmount]);

    const innerSave = useCallback(async () => {
        setLoadingSave(true);
        await onSave();
        setLoadingSave(false);
    }, [onSave]);

    return (
        <>
            <Modal
                open={showCalendarModal}
                footer={null}
                title={null}
                centered={true}
                onCancel={() => setShowCalendarModal(false)}
                width={"80vw"}>
                <HappinessCalculatorCalendarPreview tableData={tableData} />
            </Modal>

            <div className="happiness-calculator-summary">
                <div>
                    <div className="happiness-calculator-summary-title">
                        <span>תמצית התוכנית</span>
                        <div
                            className="happiness-calculator-summary-icon"
                            onClick={() => {
                                log("Calendar Preview Button Clicked");
                                setShowCalendarModal(true);
                            }}>
                            <CalendarOutlined />
                        </div>
                    </div>
                </div>
                <div className="happiness-calculator-summary-total-cost">
                    <div>
                        <span>{allCategoriesPrice.toLocaleString("en-GB", {maximumFractionDigits: 1})} ₪</span>
                        עלות כוללת לתוכנית
                    </div>
                    <div className="happiness-calculator-summary-categories-bars-container">
                        <CalculatorCategoriesBar
                            categoriesWithTotalPrice={categoriesWithTotalPrice}
                            totalPrice={allCategoriesPrice}
                        />
                    </div>
                </div>
                {bottomStats?.length ? (
                    <div className="happiness-calculator-summary-categories-stats">
                        {bottomStats.map(({title, value}) => (
                            <div className="happiness-calculator-summary-category-stats" key={title}>
                                <span>{title}</span>
                                <span>{value}</span>
                            </div>
                        ))}
                    </div>
                ) : null}
                <Button loading={loadingSave} className="happiness-calculator-summary-save-button" onClick={innerSave}>
                    שמירה ומעבר ליומן ניהול
                </Button>
            </div>
        </>
    );
};

export default HappinessCalculatorSummary;
