import React from "react";

export const StepTitle = ({children, style}) => (
    <div
        style={{
            fontSize: 46,
            marginLeft: 20,
            marginRight: 20,
            color: "var(--secondary-color)",
            fontWeight: 500,
            lineHeight: "70px",
            textAlign: "center",
            ...style
        }}>
        {children}
    </div>
);
