import React, {useContext, useState} from "react";
import {Form, Select} from "antd";
import {FormBottomBar, FormConfirmButton, FormInput, FormTextArea} from "../components/form";
import {parse as qsParse} from "query-string";
import {HttpClient} from "../http/HttpClient";
import PageTitleHeader from "../components/PageTitle";
import {AppContext} from "../AppContext";
import {StyledButton} from "../components/StyledButton";
import {WhatsAppOutlined} from "@ant-design/icons";
import {ContactUsThankYouModal} from "./ContactUsThankYouModal";
import {EventBus} from "../bus/EventBus";
import {ContactPhone} from "./ContactPhone";
import {GoogleAnalytics} from "../GoogleAnalytics";

const {Option} = Select;

const placeholderBySubject = {
    "technical-problem": "What went wrong?",
    "decline-reason": "Why did you decline? Was it low offer? Long distance? Problematic hours?",
    general: "What's up?",
    "zoom-meetings": "What went wrong?",
    payments: "Want to upgrade your account? Other thoughts to share with us?"
};

export const ContactUs = props => {
    const {me, company} = useContext(AppContext);
    const [subject, setSubject] = useState(null);
    const [messagePlaceholder, setMessagePlaceholder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [messageSent, setMessageSent] = useState(false);
    const [form] = Form.useForm();

    const sendToSupport = async values => {
        const query = qsParse(window.location.search);
        const context = query.context;

        try {
            setLoading(true);
            GoogleAnalytics.event("Contact", "Email", values.subject);
            await HttpClient.put("/api/contact", context ? {context, ...values} : values);
            setMessageSent(true);
            form.resetFields();
        } catch (e) {
            EventBus.triggerError(
                "server-error",
                {content: {description: "Unfortunately we didn't manage to send your request :("}},
                e.message
            );
        }

        setLoading(false);
    };

    const selectSubject = subject => {
        setMessagePlaceholder(placeholderBySubject[subject]);
        setSubject(subject);
    };

    const {history} = props;

    const query = qsParse(window.location.search);
    const defaultSubject = query.subject || "general";
    const defaultMessagePlaceholder = placeholderBySubject[defaultSubject];

    return (
        <div style={{display: "flex", alignItems: "center", marginBottom: 80, flexDirection: "column"}}>
            <PageTitleHeader>Here to help you</PageTitleHeader>
            <ContactUsThankYouModal
                me={me}
                history={history}
                visible={messageSent}
                onClose={() => setMessageSent(false)}
            />
            <Form
                form={form}
                initialValues={{subject: defaultSubject}}
                style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    maxWidth: 440,
                    width: "100%"
                }}
                onFinish={async values => await sendToSupport(values)}>
                {me && me.userId ? null : (
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Please enter your email."
                            }
                        ]}>
                        <FormInput placeholder="Email" />
                    </Form.Item>
                )}
                <Form.Item
                    name="subject"
                    rules={[
                        {
                            required: true,
                            message: "Please select a subject."
                        }
                    ]}>
                    <Select placeholder="Subject" onSelect={subject => selectSubject(subject)}>
                        <Option value="general">General</Option>
                        <Option value="technical-problem">Technical problem</Option>
                        <Option value="zoom-meetings">Zoom meetings</Option>
                        <Option value="payments">Payment plans</Option>
                        {me.type === "provider" ? <Option value="decline-reason">Decline reason</Option> : null}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="message"
                    rules={[
                        {
                            required: true,
                            message: "Please type a message."
                        }
                    ]}>
                    <FormTextArea
                        className="contact-input"
                        placeholder={messagePlaceholder || defaultMessagePlaceholder}
                        autoSize={{minRows: 4, maxRows: 4}}
                    />
                </Form.Item>
                <Form.Item>
                    <FormConfirmButton
                        hoveropacity="0.85"
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        style={{width: "100%", backgroundColor: "#055E53"}}>
                        Send
                    </FormConfirmButton>
                </Form.Item>
            </Form>
            <div style={{display: "flex", flexDirection: "column", color: "#747F92"}}>
                <div>Support hours: Sun-Thu, 9AM-7PM</div>
                <div>SLA: within 24 hours</div>
                <div>Email: support@well-b.biz</div>
                <div>Phone: {ContactPhone.prettyPhoneNumber()}</div>
            </div>
            <FormBottomBar backgroundColor="#055E53" color="white">
                <div className="wb-justify-bottom-bar" style={{display: "flex", width: "100%", alignItems: "center"}}>
                    <label className="wb-block-desktop-only" style={{marginRight: 5, fontSize: 16, color: "white"}}>
                        Want a more personal touch?
                    </label>
                    <StyledButton
                        onClick={() => ContactPhone.openWhatsApp()}
                        className="wb-form-bottom-btn"
                        style={{
                            color: "#055E53",
                            border: "1px solid #055E53",
                            backgroundColor: "white",
                            borderRadius: 100
                        }}>
                        <WhatsAppOutlined style={{paddingTop: 3, fontSize: 18}} />
                        Let's chat
                    </StyledButton>
                </div>
            </FormBottomBar>
        </div>
    );
};
