import React, {useCallback, useContext, useState} from "react";
import {set} from "lodash";
import {BudgetTypeSelectOption} from "./BudgetTypeSelectOption";
import {userHasCategoryBudget} from "./utils";
import {FormModal} from "../../components";
import {AllowedSystemIdPaymentType, PaymentType, PaymentTypeToName, ServiceIdToName} from "../../consts.js";
import {SelectWithInputOption} from "../../components/SelectWithInputOption";
import {AppContext} from "../../AppContext";
import {PHONE_NUMBER_ERROR_VALIDATION} from "../../marketplace/consts";
import common from "@well-b/well-b-shared";
import {getThemeByOrigin} from "../../utils.js";

export const EditSubAdminModal = ({subAdmin, mainServices, departments = [], visible, onSave, onClose, disables}) => {
    const {me} = useContext(AppContext);
    const [innerValues, setInnerValues] = useState({});
    const innerOnSave = useCallback(
        values => {
            if (values.department) {
                values.department = values.department.trim();
            }

            if (values.budget) {
                const {hasCategoryBudget, ...otherBudgets} = values.budget;
                values.budget = otherBudgets;
            }

            onSave(values);
        },
        [onSave]
    );

    return (
        <FormModal
            className="company-profile-manage-admins-admin-edit"
            visible={visible}
            content={subAdmin}
            header={subAdmin?.userId ? "עריכת אדמין 🙋‍♂️" : "הוספת אדמין יחיד 🙋‍♂️"}
            subTitle={
                subAdmin?.userId
                    ? "עריכת פרטי האדמין"
                    : "במידה וברצונכם להוסיף אדמין לפלטפורמה אנא מלאו את הפרטים הבאים"
            }
            onSave={innerOnSave}
            onClose={onClose}
            onUpdate={(allValues, changedField) => {
                const [fieldKey, fieldValue] = Object.entries(changedField)[0];
                setInnerValues(currentInnerValues => set({...currentInnerValues}, fieldKey, fieldValue));
            }}
            disables={disables}
            fields={[
                {
                    name: "firstName",
                    label: "שם מלא"
                },
                {
                    name: "email",
                    label: "אימייל",
                    type: "email",
                    disabled: subAdmin?.userId
                },
                {
                    name: "department",
                    type: "custom",
                    label: "מחלקה",
                    customComponent: (
                        <SelectWithInputOption
                            className="company-profile-manage-admins-admin-edit-department-select"
                            options={departments.map(department => ({
                                value: department,
                                label: department
                            }))}
                        />
                    ),
                    optional: true
                },
                {
                    name: "manageDepartments",
                    label: "אחראי על מחלקה",
                    info: "מתן אפשרות למשתמש זה לצפות ולעקוב אחר פעילויות משתמשים אחרים הרשומים תחת המחלקות שיבחרו בשדה זה",
                    type: "select",
                    multiple: true,
                    options: departments.map(department => ({
                        name: department,
                        label: department
                    })),
                    optional: true
                },
                {
                    name: "phone",
                    label: "טלפון",
                    rules: [
                        {
                            validator: (_, value) => {
                                return common.isValidPhoneMobileNumber(value)
                                    ? Promise.resolve()
                                    : Promise.reject(PHONE_NUMBER_ERROR_VALIDATION);
                            }
                        }
                    ]
                },
                {
                    name: "fullAddress",
                    type: "multi",
                    inputs: [
                        {
                            label: "כתובת משרד",
                            name: "address",
                            value: subAdmin?.address,
                            type: "address",
                            optional: true
                        },
                        {
                            label: "קומה",
                            name: "floor",
                            type: "number",
                            optional: true
                        }
                    ]
                },
                {
                    name: "allowedServices",
                    id: "allowedServices",
                    label: "קטגוריות פתוחות",
                    type: "select",
                    multiple: true,
                    options: mainServices?.map(({serviceId}) => ({
                        name: serviceId,
                        label: ServiceIdToName[serviceId]
                    }))
                },
                {
                    name: "budget",
                    type: "custom",
                    customComponent: (
                        <BudgetTypeSelectOption
                            subAdminBudget={subAdmin?.budget}
                            disabled={subAdmin?.userId || !me.budget?.amount}
                            className="company-profile-manage-admins-admin-edit-budget-inputs"
                            mainServices={mainServices}
                            hasCategoryBudget={
                                innerValues.budget?.hasCategoryBudget ?? userHasCategoryBudget(subAdmin?.budget)
                            }
                        />
                    )
                },
                {
                    name: "allowedPaymentsMethods",
                    id: "allowedPaymentsMethods",
                    label: "צורות תשלום מאושרות",
                    type: "select",
                    multiple: true,
                    options: Object.values(AllowedSystemIdPaymentType[getThemeByOrigin(window.location.origin)]).map(
                        paymentType => ({
                            name: paymentType,
                            label: PaymentTypeToName[paymentType]
                        })
                    )
                },
                {
                    name: "isMustProvidePurchaseOrderNumber",
                    label: "חייב את המזמין להזנת מס׳ הזמנת רכש במעמד ההזמנה",
                    type: "boolean",
                    checkedChildren: "כן",
                    unCheckedChildren: "לא"
                }
            ]}
        />
    );
};
