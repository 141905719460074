import React from "react";
import {Route, Switch} from "react-router";
import {EXCenterBranches} from "./branches/EXCenterBranches";
import {EXCenterMain} from "./EXCenterMain";
import {EXCenterDetailsPage} from "./details/EXCenterDetailsPage";
import {EXCenterSectionPage} from "./sections/EXCenterSectionPage";
import {EXCenterSearchPage} from "./search/EXCenterSearchPage";
import {EXCenterProfilePage} from "./profile/EXCenterProfilePage";

export const EXCenterRoutes = () => {
    return (
        <Switch>
            <Route path="/center/branches/:type" component={EXCenterBranches} />
            <Route path="/center/main" component={EXCenterMain} />
            <Route
                exact
                path="/center/sections/:sectionId/content/:contentId/preview"
                component={EXCenterDetailsPage}
            />
            <Route exact path="/center/sections/:sectionId/content/:contentId" component={EXCenterDetailsPage} />
            <Route exact path="/center/sections/:sectionId/:tag" component={EXCenterSectionPage} />
            <Route exact path="/center/sections/:sectionId" component={EXCenterSectionPage} />
            <Route exact path="/center/search" component={EXCenterSearchPage} />
            <Route path="/center/profile" component={EXCenterProfilePage} />
        </Switch>
    );
};
