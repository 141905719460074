import "./admin-company-assign-csm.css";
import {useRequest} from "../../utils/hooks.jsx";
import {useCallback, useEffect, useMemo, useState} from "react";
import {companyNumberToTier} from "../AdminCompanies/consts.jsx";
import {StringBuilder} from "../../AppUtils.js";
import {Input, message} from "antd";
import classnames from "classnames";
import {StyledButton} from "../../components/index.jsx";
import {groupBy, minBy} from "lodash";
import {HttpClient} from "../../http/HttpClient.jsx";

export const AdminCompanyAssignCSM = () => {
    const [systemStaffUsersFromServer, _, __, fetchSystemStaffUsersFromServer] = useRequest(
        "/admin/api/users/systemStaffUsers"
    );

    const [systemStaffUsers, setSystemStaffUsers] = useState([]);

    useEffect(() => {
        if (systemStaffUsersFromServer?.length) {
            setSystemStaffUsers(systemStaffUsersFromServer.filter(user => user.position === "CSM"));
        }
    }, [systemStaffUsersFromServer]);

    const updateSystemStaffUsers = useCallback((userId, minimumAssignedCompanySize) => {
        setSystemStaffUsers(current =>
            current.map(user => {
                if (user.userId === userId) {
                    user.updatedMinimumAssignedCompanySize = minimumAssignedCompanySize;
                }

                return user;
            })
        );
    }, []);

    const onSaveChanges = useCallback(async () => {
        if (!systemStaffUsers || systemStaffUsers.length === 0) {
            message.error("לא נמצאו משתמשים מנהלים במערכת");
            return;
        }

        const userWithMinAssignedCompanySize = minBy(
            systemStaffUsers,
            user => user?.updatedMinimumAssignedCompanySize ?? user?.minimumAssignedCompanySize
        );

        const minimumAssignedCompanySize =
            userWithMinAssignedCompanySize?.updatedMinimumAssignedCompanySize ??
            userWithMinAssignedCompanySize?.minimumAssignedCompanySize;

        if (minimumAssignedCompanySize > 0) {
            message.error("חובה משתמש שיטפל בחברות עם לפחות לקוח אחד בחברה");
            return;
        }

        const usersGroupByMinimumAssignedCompanySize = groupBy(
            systemStaffUsers,
            user => user?.updatedMinimumAssignedCompanySize ?? user?.minimumAssignedCompanySize
        );

        const hasDuplicateGroupsAssignedCompanySize = Object.values(usersGroupByMinimumAssignedCompanySize).some(
            group => group.length > 1
        );

        if (hasDuplicateGroupsAssignedCompanySize) {
            message.error("לא ניתן ש2 משתמשים יהיו עם אותו מספר בהקצאת גודל חברה");
            return;
        }

        const usersToSave = systemStaffUsers
            .filter(
                user =>
                    user.updatedMinimumAssignedCompanySize &&
                    user.updatedMinimumAssignedCompanySize !== user.minimumAssignedCompanySize
            )
            .map(user => ({...user, minimumAssignedCompanySize: user.updatedMinimumAssignedCompanySize}));

        const {error} = await HttpClient.safePost("/admin/api/users/updateUserMinimumAssignedCompanySize", {
            users: usersToSave
        });

        if (error) {
            message.error("התהליך נכשל - אנא פנה לפיתוח");
        } else {
            fetchSystemStaffUsersFromServer();
            message.success("התהליך הושלם בהצלחה");
        }
    }, [systemStaffUsers]);

    return (
        <div className="admin-company-assign-csm-container">
            <div className="admin-company-assign-csm-title">הקצאות מנהלת לקוח לחברות</div>
            <div className="admin-company-assign-csm-list">
                {systemStaffUsers.map(user => (
                    <div className="admin-company-assign-csm-item">
                        <div key={user.userId}>
                            {new StringBuilder()
                                .append(user.firstName)
                                .append(
                                    companyNumberToTier[
                                        user.updatedMinimumAssignedCompanySize ?? user?.minimumAssignedCompanySize
                                    ] ?? ""
                                )
                                .toString()}
                        </div>

                        <div>
                            <Input
                                className={classnames({
                                    "admin-company-assign-csm-input-changed":
                                        user.updatedMinimumAssignedCompanySize &&
                                        user.updatedMinimumAssignedCompanySize !== user.minimumAssignedCompanySize
                                })}
                                value={user?.updatedMinimumAssignedCompanySize ?? user?.minimumAssignedCompanySize}
                                onChange={e => updateSystemStaffUsers(user.userId, Number(e.target.value))}
                            />
                        </div>
                    </div>
                ))}
            </div>

            <StyledButton onClick={() => onSaveChanges()}>שמור שינויים</StyledButton>
        </div>
    );
};
