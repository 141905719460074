import React from "react";
import {Checkbox, Form, message, Select, Tooltip} from "antd";
import {GoogleOutlined, PlusOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {
    ActivityExtras,
    ActivityManagement,
    allLocations,
    languages,
    PresentationsRequest
} from "../../AdminUploadUtils";
import {StepperInput} from "../../../../wizards/components/StepperInput";
import {DurationInput, PageSubTitleLabel, SearchableSelect} from "../../../../components";
import {ACTIVITY_REQUESTS, FoodRequests} from "../../../../marketplace/ProductsFilterModal/consts";
import {MainServices, ProviderActivityPossibleLocations, ServiceIdToName} from "../../../../consts.js";
import {AutoDirectionProvider} from "../../../../AutoDirectionProvider";
import {TrashIcon} from "../../../../icons";
import {AutoCompleteAddressFormInput} from "../../../../utils/AutoCompleteAddress";
import {StepperParagraphInput} from "../../../../wizards/components/StepperParagraphInput";
import {FormInput} from "../../../../components/form";

const {Option, OptGroup} = Select;
export const AdditionalInfoTab = ({
    formValues,
    onFoodRequestsSelect,
    onFoodRequestsDeselect,
    onActivityRequestsDeselect,
    onActivityRequestsSelect,
    onPresentationDeselect,
    onPresentationSelect,
    durationType,
    setDurationType,
    onLocationDeselect,
    onLocationSelect,
    onAreaDeselect,
    onAreaSelect,
    addressNames,
    setAddressNames,
    updateAddressField,
    onLanguagesDeselect,
    onLanguagesSelect,
    onActivityExtraDeselect,
    onActivityExtraSelect,
    onActivityManagementDeselect,
    onActivityManagementSelect,
    sites,
    onSelectSite,
    onDeselectSite
}) => (
    <>
        <div className="mixed-inputs">
            <div className="single-input">
                <label>כמות משתתפים אפשרית לפעילות</label>
            </div>

            <div className="multiple-inputs">
                <div className="single-input">
                    <Form.Item name="minParticipants">
                        <StepperInput placeholder="מינימום משתתפים" />
                    </Form.Item>
                </div>
                <div>
                    <label>עד</label>
                </div>
                <div className="single-input">
                    <Form.Item name="maxParticipants">
                        <StepperInput placeholder="מקסימום משתתפים" />
                    </Form.Item>
                </div>
            </div>
        </div>

        <div className="single-input">
            <label>במידה ומדובר בפעילות מזון - האם יש מענה לצרכים הבאים?</label>
            <Form.Item name="foodRequests">
                <SearchableSelect
                    mode="multiple"
                    onDeselect={value => onFoodRequestsDeselect(value)}
                    onSelect={value => onFoodRequestsSelect(value)}
                    className="wb-profession-selection"
                    placeholder="מה ניתן לקבל באירוח?">
                    {FoodRequests.map(({id, title}) => (
                        <Option key={id} value={id}>
                            {title}
                        </Option>
                    ))}
                </SearchableSelect>
            </Form.Item>
        </div>

        {formValues?.services?.includes(MainServices.WORKSHOPS) ? (
            <div className="single-input">
                <label>במידה ומדובר בפעילות צוותית - האם יש מענה לצרכים הבאים?</label>
                <Form.Item name="activityRequests">
                    <SearchableSelect
                        mode="multiple"
                        onDeselect={value => onActivityRequestsDeselect(value)}
                        onSelect={value => onActivityRequestsSelect(value)}
                        className="wb-profession-selection"
                        placeholder="מה ניתן לקבל באירוח?">
                        {ACTIVITY_REQUESTS.map(({id, title}) => (
                            <Option key={id} value={id}>
                                {title}
                            </Option>
                        ))}
                    </SearchableSelect>
                </Form.Item>
            </div>
        ) : null}

        {formValues?.services?.includes(MainServices.HAPPY_HOURS) ? (
            <div className="single-input">
                <label>במידה ומדובר בהאפי האוורס - צורת הגשה אפשרית</label>
                <Form.Item name="presentation">
                    <SearchableSelect
                        mode="multiple"
                        onDeselect={value => onPresentationDeselect(value)}
                        onSelect={value => onPresentationSelect(value)}
                        className="wb-profession-selection"
                        // style={{marginTop: 10, width: "600px"}}
                        placeholder="צורת הגשה">
                        {PresentationsRequest.map(({id, title}) => (
                            <Option key={id} value={id}>
                                {title}
                            </Option>
                        ))}
                    </SearchableSelect>
                </Form.Item>
            </div>
        ) : null}

        <div className="single-input">
            <label>משך הפעילות בדקות</label>
            <Form.Item name="durationMinutes">
                <FormInput
                    type="number"
                    value={formValues.durationMinutes ?? 0}
                    style={{borderRadius: 10}}
                    placeholder="בדקות"
                    selectorStyle={{minWidth: "80px", paddingLeft: "8px"}}
                />
            </Form.Item>
        </div>

        <PageSubTitleLabel
            style={{
                marginTop: 30,
                marginBottom: 10,
                fontSize: 20,
                color: "#ED7FA6",
                textDecoration: "underline"
            }}>
            מיקום לפעילות
        </PageSubTitleLabel>

        <div className="single-input">
            <label>מיקומים אפשריים לפעילות</label>
            <Form.Item
                name="location"
                rules={[
                    {
                        required: true,
                        message: "שדה חובה"
                    }
                ]}>
                <SearchableSelect
                    id="action-locations"
                    mode="multiple"
                    onDeselect={value => onLocationDeselect(value)}
                    onSelect={value => onLocationSelect(value)}
                    className="wb-profession-selection"
                    placeholder="מיקומים אפשריים לפעילות">
                    {Object.entries(ProviderActivityPossibleLocations).map(([value, title]) => (
                        <Option key={value} value={value}>
                            {title}
                        </Option>
                    ))}
                </SearchableSelect>
            </Form.Item>
        </div>

        <div className="single-input">
            <label>עבור פעילות במשרד או במקום חיצוני - לאן אתם יכולים להגיע?</label>
            <Form.Item name="area">
                <SearchableSelect
                    mode="multiple"
                    onDeselect={value => onAreaDeselect(value)}
                    onSelect={value => onAreaSelect(value)}
                    className="wb-profession-selection"
                    placeholder="לאן אתם יכולים להגיע?">
                    {Object.keys(allLocations).map(zone => {
                        const zoneTitle = allLocations[zone].title;
                        const zoneLocations = allLocations[zone].values;
                        return zoneTitle ? (
                            <OptGroup
                                key={zoneTitle}
                                label={<AutoDirectionProvider text={"עברית"}>{zoneTitle}</AutoDirectionProvider>}>
                                {zoneLocations.map(loc => {
                                    return (
                                        <Option style={{direction: "rtl"}} key={loc} value={loc}>
                                            {loc}
                                        </Option>
                                    );
                                })}
                            </OptGroup>
                        ) : (
                            zoneLocations.map(loc => {
                                return (
                                    <Option style={{direction: "rtl"}} key={loc} value={loc}>
                                        {loc}
                                    </Option>
                                );
                            })
                        );
                    })}
                </SearchableSelect>
            </Form.Item>
        </div>

        <div className="single-input">
            <label>
                {"סניפים"}
                <Tooltip title="יש באפשרותכם לשייך מוצר/שירות לסניפים שהוא קיים בהם">
                    <QuestionCircleOutlined style={{cursor: "pointer"}} />
                </Tooltip>
            </label>

            <SearchableSelect
                id="product-category"
                mode="multiple"
                onDeselect={value => onDeselectSite(value)}
                onSelect={value => onSelectSite(value)}
                className="wb-profession-selection"
                value={addressNames.map(({siteId}) => siteId)}
                placeholder="סניף">
                {sites?.map((site, index) => {
                    return (
                        <Option key={index} value={site.siteId}>
                            {site.name}
                        </Option>
                    );
                })}
            </SearchableSelect>
        </div>

        <div className="single-input">
            <label>עבור פעילות בבית העסק - אנא הזן את כלל הכתובות הרלוונטיות</label>
            <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                {addressNames.map(
                    (
                        {name, isAccessible = false, directionInfo, isSelfPickupPossible = false, placeId = null},
                        index
                    ) => (
                        <div key={`${name}-${index}`} id="locationBox" className="single-input">
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}>
                                {placeId && (
                                    <div>
                                        <GoogleOutlined style={{fontSize: 26}} />
                                    </div>
                                )}

                                <div style={{width: "100%"}}>
                                    <Form.Item style={{margin: 0}} key={name} valuePropName="searchText">
                                        <AutoCompleteAddressFormInput
                                            disabled={true}
                                            editValue={name}
                                            placeholder="כתובת בית העסק"
                                            countriesToSearchIn={["IL"]}
                                            allowSelectRegularText={true}
                                        />
                                    </Form.Item>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        whiteSpace: "nowrap",
                                        width: "100%"
                                    }}>
                                    <Form.Item
                                        style={{margin: 0}}
                                        key={`${name}-isAccessible-${index}`}
                                        disabled={name}>
                                        <Checkbox
                                            checked={isAccessible}
                                            disabled={true}
                                            onChange={e => {
                                                updateAddressField({isAccessible: e.target.checked}, index);
                                            }}>
                                            האם המקום נגיש?
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        style={{margin: 0}}
                                        key={`${name}-isSelfPickupPossible-${index}`}
                                        disabled={name}>
                                        <Checkbox
                                            checked={isSelfPickupPossible}
                                            disabled={true}
                                            onChange={e => {
                                                updateAddressField({isSelfPickupPossible: e.target.checked}, index);
                                            }}>
                                            האם ניתן לאיסוף עצמי?
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                            </div>
                            <div id="directionInfoSection">
                                <StepperParagraphInput
                                    disabled={true}
                                    value={directionInfo}
                                    onChange={e => updateAddressField({directionInfo: e.target.value}, index)}
                                    placeholder="מידע על דרכי הגעה?"
                                    maxLength={2000}
                                    autoSize={{maxRows: 4, minRows: 2}}
                                />
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>

        {formValues?.serviceIds?.includes("professional") ? (
            <>
                <AutoDirectionProvider
                    text={"שפות הפעילות"}
                    style={{
                        marginBottom: 15,
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column"
                    }}>
                    <label
                        style={{
                            fontSize: 16,
                            color: "var(--secondary-color)",
                            marginLeft: 475
                        }}>
                        {"שפות הפעילות"}{" "}
                        <Tooltip title="האם יודעים להעביר את הפעילות ביותר משפה אחת? אם כן ציינו אילו שפות.">
                            <QuestionCircleOutlined style={{cursor: "pointer"}} />
                        </Tooltip>
                    </label>
                    <Form.Item name="languages">
                        <SearchableSelect
                            mode="multiple"
                            onDeselect={value => onLanguagesDeselect(value)}
                            style={{
                                marginTop: 10,
                                width: "600px"
                            }}
                            onSelect={value => onLanguagesSelect(value)}
                            className="wb-profession-selection"
                            placeholder="נסה להיות גמיש ככל הניתן">
                            {languages.map(lang => (
                                <Option key={lang.englishName} value={lang.englishName}>
                                    {lang.hebrewName}
                                </Option>
                            ))}
                        </SearchableSelect>
                    </Form.Item>
                </AutoDirectionProvider>

                <AutoDirectionProvider
                    text={"במידה ומדובר בפעילות קבוצתית רגילה - האם יש מענה לצרכים הבאים?"}
                    style={{
                        marginBottom: 15,
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column"
                    }}>
                    <label
                        style={{
                            fontSize: 16,
                            color: "var(--secondary-color)",
                            marginLeft: 160
                        }}>
                        במידה ומדובר בפעילות רגילה - האם יש מענה לצרכים הבאים?
                    </label>
                    <Form.Item name="activityExtras">
                        <SearchableSelect
                            mode="multiple"
                            onDeselect={value => onActivityExtraDeselect(value)}
                            onSelect={value => onActivityExtraSelect(value)}
                            className="wb-profession-selection"
                            style={{
                                marginTop: 10,
                                width: "600px"
                            }}
                            placeholder="מה ניתן לקבל באירוח?">
                            {ActivityExtras.map(({id, title}) => (
                                <Option key={id} value={id}>
                                    {title}
                                </Option>
                            ))}
                        </SearchableSelect>
                    </Form.Item>
                </AutoDirectionProvider>

                <AutoDirectionProvider
                    text={"עברית"}
                    style={{
                        marginBottom: 15,
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column"
                    }}>
                    <label
                        style={{
                            fontSize: 16,
                            color: "var(--secondary-color)",
                            marginLeft: 415
                        }}>
                        אפשרויות לניהול הפעילות
                    </label>
                    <Form.Item name="activityManagement">
                        <SearchableSelect
                            mode="multiple"
                            onDeselect={value => onActivityManagementDeselect(value)}
                            onSelect={value => onActivityManagementSelect(value)}
                            className="wb-profession-selection"
                            style={{
                                marginTop: 10,
                                width: "600px"
                            }}
                            placeholder="אפשרויות לניהול">
                            {ActivityManagement.map(({id, title}) => (
                                <Option key={id} value={id}>
                                    {title}
                                </Option>
                            ))}
                        </SearchableSelect>
                    </Form.Item>
                </AutoDirectionProvider>
            </>
        ) : null}
    </>
);
