import {Images} from "../../images/Images";
import {PageSubTitleLabel, PageTitleLabel, StyledButton} from "../../components";
import {Link} from "react-router-dom";
import React, {useContext} from "react";
import {AppContext} from "../../AppContext";
import {withRouter} from "react-router";
import {hasEXCenterFeatureOpen} from "../../center/EXCenterHelper";

const EventBookingConfirmationView = ({history, title, subtitle, serviceId, children, image, action}) => {
    const {me, company} = useContext(AppContext);
    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <div
                style={{
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundImage: `url(${image || Images.successWide})`,
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                    paddingBottom: "52%"
                }}
            />
            <PageTitleLabel style={{marginBottom: 20, marginTop: 30}}>{title || "See you there!"}</PageTitleLabel>
            <PageSubTitleLabel style={{marginTop: 0, marginBottom: 30}}>{subtitle}</PageSubTitleLabel>
            <div
                style={{
                    marginTop: 0,
                    paddingBottom: 30,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <div>{children}</div>
                {action ||
                    (hasEXCenterFeatureOpen(me, company) ? (
                        <StyledButton
                            type="link"
                            style={{
                                color: "var(--secondary-color)",
                                fontSize: 16,
                                border: "none",
                                backgroundColor: "transparent",
                                boxShadow: "none"
                            }}
                            onClick={() => history.push("/center/profile/bookings")}>
                            Go to My Bookings
                        </StyledButton>
                    ) : (
                        <Link
                            to={`/dashboard/bookings${serviceId ? `?serviceId=${serviceId}` : ""}`}
                            style={{color: "var(--secondary-color)", fontSize: 16}}>
                            Go to My Bookings
                        </Link>
                    ))}
            </div>
        </div>
    );
};

export default withRouter(EventBookingConfirmationView);
