import {BalanceInfoCard} from "../../billing/BalanceInfoCard";
import creditCardIcon from "../../images/icons/credit_card.svg";
import appointmentIcon from "../../images/icons/appointment.svg";
import {BuildingIcon, CreditCardNoNumberIcon, SavingIcon, TeamIcon} from "../../icons";
import React, {useState, useEffect} from "react";
import {Spin, message} from "antd";
import {HttpClient} from "../../http/HttpClient";
import {round} from "lodash";
import {PageLoader} from "../../components/PageLoader";

const AdminBillingInfoCard = ({icon, title, subTitle, includeVatLabel}) => (
    <BalanceInfoCard
        style={{width: 380, margin: 15, padding: 15}}
        icon={icon}
        title={title}
        subTitle={
            <div style={{display: "flex", alignItems: "center"}}>
                <span style={{fontSize: 24, color: "var(--secondary-color)", fontWeight: 900}}>{subTitle}</span>
                {includeVatLabel ? (
                    <span
                        style={{
                            fontSize: 16,
                            color: "var(--secondary-color)",
                            fontWeight: 900,
                            marginLeft: 6,
                            marginTop: 6
                        }}>
                        {" "}
                        + VAT
                    </span>
                ) : null}
            </div>
        }
    />
);

export const AdminBillingSummary = ({match}) => {
    const {date} = match.params;
    const [summary, setSummary] = useState(null);

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const summary = await HttpClient.get(`/admin/api/billing/${date}/summary`);
                setSummary(summary);
            } catch (e) {
                message.error("Something went wrong, please try again later.", 5);
            }
        });
    }, []);

    if (summary === null) {
        return <PageLoader align="flex-start" top={80} height={400} />;
    }

    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap", marginTop: 30}}>
            <AdminBillingInfoCard
                icon={<img src={creditCardIcon} alt="credit_card" />}
                title="Total revenues to be received"
                subTitle={`₪${round(summary.totalToBeReceived).toLocaleString()}`}
                includeVatLabel={true}
            />
            <AdminBillingInfoCard
                icon={<CreditCardNoNumberIcon />}
                title="Total to be paid"
                subTitle={`₪${round(summary.totalToBePaid).toLocaleString()}`}
                includeVatLabel={true}
            />
            <AdminBillingInfoCard
                icon={<BuildingIcon style={{fill: "#F6AB2F", width: 38, height: 30}} />}
                title="Registered companies"
                subTitle={summary.registeredCompanies}
            />
            <AdminBillingInfoCard
                icon={<img src={creditCardIcon} alt="credit_card" />}
                title="Companies subscriptions revenues"
                subTitle={`₪${round(summary.companySubscriptions).toLocaleString()}`}
                includeVatLabel={true}
            />
            <AdminBillingInfoCard
                icon={<img src={creditCardIcon} alt="credit_card" />}
                title="Rev share revenues"
                subTitle={`₪${round(summary.revShares).toLocaleString()}`}
                includeVatLabel={true}
            />
            <AdminBillingInfoCard
                icon={<img src={appointmentIcon} alt="appointment" />}
                title="Transactions volume"
                subTitle={`₪${round(summary.transactionVolume).toLocaleString()}`}
                includeVatLabel={true}
            />
            <AdminBillingInfoCard
                icon={<SavingIcon style={{fill: "#F6AB2F", width: 32, height: 32}} />}
                title="Avg price per transaction"
                subTitle={`₪${round(summary.averagePricePerTransaction).toLocaleString()}`}
                includeVatLabel={true}
            />
            <AdminBillingInfoCard
                icon={<TeamIcon style={{fill: "#F6AB2F", width: 34, height: 38}} />}
                title="Registered employees"
                subTitle={summary.registeredEmployees}
            />
            <AdminBillingInfoCard
                icon={<TeamIcon style={{fill: "#F6AB2F", width: 36, height: 36}} />}
                title="Avg price per user"
                subTitle={`₪${round(summary.averagePricePerUser).toLocaleString()}`}
                includeVatLabel={true}
            />
        </div>
    );
};
