// import * as EmailValidator from "email-validator";
// import isURL from "validator/lib/isURL";

export const isValidURL = url => {
    if (typeof url === "string" && url.indexOf("mailto:") === 0) {
        const parts = url.split(":");
        return parts.length === 2 && EmailValidator.validate(parts[1]);
    }

    return isURL(url, {require_protocol: false, allow_underscores: true});
};
