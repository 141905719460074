import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {ConfigProvider, Popover} from "antd";
import "./search-text-filter-popover.css";
import {useDebounceState, useRequest} from "../../../../utils/hooks";
import {AMOUNT_OF_SEARCH_RESULTS} from "../../../../consts.js";
import {FormInputV2} from "../../../../components/form";
import {MarketplaceFilterProviderContext} from "../../MarketplaceFilterContext";
import {AppContext} from "../../../../AppContext";
import {SearchItemList} from "./SearchItemList.jsx";
import classnames from "classnames";
import {CategoryTextToOriginalCategories, FILTER_VERSION} from "../../consts.js";
import {getLogger} from "../../../../Logger.jsx";
import {useHistory} from "react-router";
import {cloneDeep} from "lodash";
import {buildFilters} from "../../advancedFilter/utils.js";

export const SearchTextFilter = () => {
    const history = useHistory();
    const {filterValues} = useContext(AppContext);
    const {panel, topTags, innerState, setInnerState, onSearch} = useContext(MarketplaceFilterProviderContext);
    const [debouncedSearchText, setUnDebouncedSearchText] = useDebounceState("", 200);
    const [tags, setTags] = useState([]);
    const [currentSearchResults, setCurrentSearchResults] = useState({});
    const inputRef = useRef(null);
    const [searchResults, loadingSearchResults] = useRequest(
        `/api/search/${debouncedSearchText?.toLowerCase()}?amount=${AMOUNT_OF_SEARCH_RESULTS}&category=${encodeURIComponent(
            JSON.stringify(CategoryTextToOriginalCategories[panel])
        )}`,
        "GET",
        null,
        [],
        !!debouncedSearchText?.length
    );

    const log = useMemo(
        () =>
            getLogger(
                {
                    version: FILTER_VERSION,
                    searchText: innerState?.searchText,
                    panel
                },
                "SearchTextFilter"
            ),
        [innerState?.searchText, panel]
    );

    const categoryTopTags = useMemo(() => {
        return (topTags ?? [])
            .filter(tag => (tag?.category ?? []).includes(panel))
            .sort((a, b) => b?.searchAmount - a?.searchAmount)
            .map(tag => ({name: tag.name, key: tag.tagId}));
    }, [topTags, panel]);

    const cleanSearch = useCallback(() => {
        setInnerState(({searchText, ...prevValues}) => prevValues);
        setCurrentSearchResults({});
        setUnDebouncedSearchText("");
    }, [setCurrentSearchResults]);

    useEffect(() => {
        if (!innerState?.searchText) {
            cleanSearch();
        }
    }, [innerState?.searchText]);

    useEffect(() => {
        if (!filterValues?.searchText) {
            cleanSearch();
        }
    }, [filterValues?.searchText]);

    useEffect(() => {
        if (innerState?.searchText) {
            setUnDebouncedSearchText(innerState.searchText);
        }
    }, []);

    useEffect(() => {
        if (categoryTopTags && debouncedSearchText === "") {
            setTags(categoryTopTags);
        }
    }, [categoryTopTags, debouncedSearchText]);

    useEffect(() => {
        if (searchResults) {
            setCurrentSearchResults(searchResults);
            setTags(searchResults.searchTags);
            if (innerState?.searchText) {
                const tag = (searchResults?.searchTags ?? []).find(
                    searchTag => searchTag?.name === innerState.searchText
                );
                log("Search option selected", {
                    text: innerState.searchText,
                    tagId: tag?.tagId,
                    isTag: !!tag,
                    type: "tag"
                });
            }
        }
    }, [searchResults]);

    const updateTitleSearchText = useCallback(searchText => {
        setInnerState(prevState => ({...prevState, searchText}));
        setUnDebouncedSearchText(searchText);
    }, []);

    const onSearchText = useCallback(event => {
        updateTitleSearchText(event.target.value);
        setCurrentSearchResults({});
        if (event.target.value === "") {
            cleanSearch();
        }
    }, []);

    const onSearchItem = useCallback(
        searchText => {
            const filters = cloneDeep(innerState);
            if (searchText) {
                filters.searchText = searchText;
            }
            onSearch(history, buildFilters(filters, panel));
        },
        [innerState, history]
    );

    const onEnterClick = useCallback(
        e => {
            if (e.key === "Enter") {
                onSearchItem();
            }
        },
        [onSearchItem]
    );

    return (
        <div id="search-text-filter-popover">
            <ConfigProvider direction="rtl">
                <Popover
                    overlayClassName="filter-popover search-text-filter-popover"
                    placement="bottomRight"
                    title={"חיפוש לפי"}
                    trigger="click"
                    onOpenChange={() => inputRef.current.focus()}
                    id="search-text-filter-popover"
                    content={
                        <SearchItemList
                            currentSearchResults={currentSearchResults}
                            updateTitleSearchText={updateTitleSearchText}
                            tags={tags}
                            loading={loadingSearchResults}
                            searchText={debouncedSearchText}
                            log={log}
                            onSearchItem={onSearchItem}
                        />
                    }>
                    <div>
                        <div className="advanced-filter-segmented-label-container">
                            <div className="advanced-filter-option-title">חיפוש חופשי</div>
                        </div>
                        <FormInputV2
                            ref={inputRef}
                            bordered={false}
                            value={innerState.searchText}
                            placeholder="הקלד כאן"
                            className={classnames("search-input-text", {selected: !!innerState.searchText})}
                            onChange={onSearchText}
                            onKeyDown={onEnterClick}
                        />
                    </div>
                </Popover>
            </ConfigProvider>
        </div>
    );
};
