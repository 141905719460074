import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Button, ConfigProvider, Popover} from "antd";
import {MarketplaceFilterProviderContext} from "../MarketplaceFilterContext.jsx";
import "./base-popover-filter-container.css";
import {NOOP} from "../../../utils/NOOP.jsx";
import {getLogger} from "../../../Logger.jsx";
import {FILTER_VERSION} from "../consts.js";
import {cloneDeep} from "lodash";
import {buildFilters} from "../advancedFilter/utils.js";
import {useHistory} from "react-router";
import classnames from "classnames";

export const BasePopoverFilter = ({
    onClear = NOOP,
    onClosePopover = NOOP,
    onOpenPopover = NOOP,
    className,
    title,
    content,
    searchOnClose,
    children
}) => {
    const history = useHistory();
    const {innerState, onSearch, panel} = useContext(MarketplaceFilterProviderContext);
    const [openPopover, setOpenPopover] = useState(false);

    const log = useMemo(
        () =>
            getLogger(
                {
                    version: FILTER_VERSION,
                    title,
                    panel
                },
                "BasePopoverFilter"
            ),
        [title, panel]
    );

    useEffect(() => {
        const popoverContent = document.querySelector(".filter-popover");
        if (!openPopover) {
            onClosePopover();
            if (searchOnClose) {
                onSearch(history, buildFilters(innerState, panel));
            }
            if (popoverContent) {
                popoverContent.removeEventListener("keydown", handleKeyDown);
            }
            return;
        }
        if (popoverContent) {
            popoverContent.tabIndex = 0;
            popoverContent.focus();
            popoverContent.addEventListener("keydown", handleKeyDown);
        }
        onOpenPopover();
    }, [openPopover]);

    const onPopoverOpenChange = useCallback(
        isOpen => {
            log("Filter Search", {outsideClicked: true});
            setOpenPopover(isOpen);
        },
        [innerState, log]
    );

    const onSave = useCallback(() => {
        log("Filter Search", {buttonClicked: true});
        setOpenPopover(false);
    }, [log]);

    const onFilterClick = useCallback(() => {
        setOpenPopover(!openPopover);
    }, [openPopover]);

    const handleKeyDown = useCallback(
        e => {
            if (e.key === "Enter") {
                log("Filter Search", {enteredClicked: true});
                setOpenPopover(false);
            }
        },
        [log]
    );

    return (
        <>
            <ConfigProvider direction="rtl">
                <Popover
                    overlayClassName={classnames("filter-popover", className)}
                    title={title}
                    onOpenChange={onPopoverOpenChange}
                    open={openPopover}
                    trigger="click"
                    placement="bottomRight"
                    content={
                        <div className="base-popover-filter-container">
                            {content && content}
                            <div className="popover-search-buttons-container">
                                <a className="clear-btn" href="#" onClick={onClear}>
                                    נקה
                                </a>
                                <Button className="apply-btn" onClick={onSave}>
                                    חפש
                                </Button>
                            </div>
                        </div>
                    }>
                    <div className="base-popover-filter-container" onClick={onFilterClick}>
                        {children}
                    </div>
                </Popover>
            </ConfigProvider>
        </>
    );
};
