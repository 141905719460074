import React from "react";
import {allHebrewLetters} from "../../marketplace/ProductDetailsPage/consts.jsx";
import classnames from "classnames";
import "../order-information-card.css";
import {OrderInformationView} from "./OrderInformationView.jsx";
import {timeToAccept, UserType} from "../../consts.js";
import {StringBuilder} from "../../AppUtils.js";
import {OrderStatus, statusToTextAndColor} from "../../components/index.jsx";

export const BundleOrderInformationView = ({eventInfo, userType, contactsInfo}) => {
    return (
        <>
            {eventInfo.orders.map((eventInfo, index) => (
                <>
                    <div
                        className={classnames(
                            "order-information-card-product-title",
                            "order-information-card-customer-information"
                        )}>
                        חלק {allHebrewLetters[index]} - {eventInfo.productName}
                    </div>
                    <OrderInformationView eventInfo={eventInfo} userType={userType} contactsInfo={contactsInfo} />
                </>
            ))}
        </>
    );
};

export const PaymentInfo = ({paymentType}) => (
    <div className="order-information-card-customer-information">
        <div>🧾 אמצעי תשלום</div>
        <div>{paymentType}</div>
    </div>
);

export const CancelReasonSection = ({reason}) => {
    return (
        <div className="event-order-decline-reason-container">
            <span className="event-order-decline-reason-title">סיבת הסירוב</span>
            <span className="event-order-decline-reason">{reason}</span>
        </div>
    );
};

export const ShippingDetailsLine = ({remainingTime, userType, shippingType}) => {
    return (
        <div
            className={`order-information-card-shipping-container
                                ${
                                    shippingType === timeToAccept.FAST
                                        ? "order-information-card-shipping-container-red"
                                        : "order-information-card-shipping-container-blue"
                                }`}>
            {!!(userType === UserType.Provider) && (
                <div>
                    <span>הזמנה ב{shippingType.name}</span>
                </div>
            )}

            <div className="order-information-card-shipping-container-bold">
                <span>
                    {remainingTime
                        ? new StringBuilder(remainingTime).append("למענה").toString()
                        : statusToTextAndColor[OrderStatus.pending]}
                </span>
            </div>
        </div>
    );
};
