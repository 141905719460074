import {groupBy} from "lodash";
import {DefaultReportPageSize} from "./consts.jsx";

export const groupFieldValidationByIndex = validationFieldsResponse => {
    if (!validationFieldsResponse) {
        return {};
    }

    const fieldsValidationByIndex = groupBy(validationFieldsResponse, val => val.index);

    const errorLines = new Set();

    const validationFieldByIndex = Object.fromEntries(
        Object.entries(fieldsValidationByIndex).map(([index, fieldValidationDetails]) => {
            const fieldErrorsByField = groupBy(fieldValidationDetails, err => err.key);

            const formattedFieldErrors = Object.fromEntries(
                Object.entries(fieldErrorsByField).map(([key, value]) => {
                    const errors = value?.map(v => v?.error).filter(Boolean);
                    const warnings = value?.map(v => v?.warning).filter(Boolean);

                    if (errors?.length) {
                        errorLines.add(index);
                    }

                    return [key, {...(errors?.length && {errors}), ...(warnings?.length && {warnings})}];
                })
            );

            return [index, formattedFieldErrors];
        })
    );

    return {
        validationResponse: validationFieldByIndex,
        errorLines
    };
};

export const getRowIndexByPagination = (currentPage = 1, pageSize = DefaultReportPageSize, rowIndex) =>
    (currentPage - 1) * pageSize + rowIndex;
