import React, {useCallback, useContext, useMemo} from "react";
import {flatten, isEmpty, minBy, uniq} from "lodash";
import {Collapse} from "antd";
import classnames from "classnames";
import {openExternalLink, openWindow} from "../../http/WindowOpener";
import {kosherTypes, kosherTypesToHebrew, ProductKosherTypesPriority} from "../ProductsFilterModal/consts";
import {MarketplaceImagesCarousel} from "../OrderImagesCarousel";
import {MarketplaceContext} from "../marketplaceContext";
import {MarketplaceItemDetailsDeliveryInformation} from "../MarketplaceItemDetailsDeliveryInformation";
import {kosherOptions, translateFoodRequest, translatePresentation} from "../../admin/upload/AdminUploadUtils";
import {ActivityLocation, ActivityLocationTypes, ValidateBookingError} from "../../consts.js";
import {PriceTypes} from "../../admin/upload/PriceSelectorHelper";
import {useRequest} from "../../utils/hooks";
import {NOOP} from "../../utils/NOOP";
import {AppContext} from "../../AppContext";
import {StringBuilder} from "../../AppUtils.js";
import {formatHebrewTimeString} from "../../utils/DateUtils.jsx";
import moment from "moment/moment.js";

const {Panel} = Collapse;

const AdditionalInfoItem = ({title, content, additionalClassName}) =>
    content ? (
        <div className={classnames(additionalClassName, "additional-info-panel-item")}>
            <div className="additional-info-panel-item-title">{title}</div>
            <div className="additional-info-panel-item-sub-title">{content}</div>
        </div>
    ) : null;

const AdditionalInfoLink = ({onClick, text}) => (
    <div onClick={onClick} className="additional-info-panel-item-info-link">
        {text}
    </div>
);

export const AdditionalInfoPanel = ({key, log = NOOP, onBookRedirectCollapse, locations, ...props}) => {
    const {me, company} = useContext(AppContext);
    const {product, providerDetails, productId, isProductBundle, productBundle} = useContext(MarketplaceContext);

    const [images] = useRequest(
        "/api/orderImages/product/byProductIds",
        "POST",
        {productIds: isProductBundle ? productBundle?.products?.map(product => product?.productId) : [productId]},
        [productId, isProductBundle, productBundle?.products],
        !!productId || (isProductBundle && !!productBundle?.products?.length)
    );

    const openKosherCertificatesInNewTab = useCallback(
        links => {
            for (const link of links) {
                openWindow(link, "_blank", true);
            }

            log("Kosher Certificate Downloaded", {links});
        },
        [log]
    );

    const supplyBrandsLinkContent = useMemo(
        () =>
            product?.supplyBrandsLink ? (
                <div className="additional-info-panel-item">
                    <div className="additional-info-panel-item-title">
                        {"🛍️"} {"בתי עסק מכבדים"}
                    </div>
                    <AdditionalInfoLink
                        onClick={() => openExternalLink(product.supplyBrandsLink)}
                        text={"לחצו כאן למעבר לבתי עסק מכבדים"}
                    />
                </div>
            ) : null,
        []
    );

    const kosherInfoContent = useMemo(() => {
        if (isProductBundle) {
            const isAllProvidersKosher = productBundle.providers.every(
                provider => provider.kosher === kosherOptions[0] || provider.kosher === kosherOptions[2]
            );

            const isSomeProviderHasKosherCertificateImageUrl = productBundle.providers.some(
                providerDetails => providerDetails?.kosherCertificateImageUrl
            );

            const productKosherType =
                minBy(productBundle.products, product => ProductKosherTypesPriority[product.productKosherType])
                    ?.productKosherType ?? kosherTypes.NOT_NEEDED;

            const providersKosherCertificates = productBundle.providers
                .map(providerDetails => providerDetails.kosherCertificateImageUrl)
                .filter(Boolean);

            return isAllProvidersKosher ? (
                <AdditionalInfoItem
                    title={`✡️ ${kosherOptions[0]}`}
                    content={
                        isSomeProviderHasKosherCertificateImageUrl && (
                            <>
                                <span>{`${kosherTypesToHebrew[productKosherType]} | `}</span>

                                {!!providersKosherCertificates?.length && (
                                    <AdditionalInfoLink
                                        onClick={() =>
                                            openKosherCertificatesInNewTab(
                                                productBundle.providers.map(
                                                    providerDetails => providerDetails.kosherCertificateImageUrl
                                                )
                                            )
                                        }
                                        text={
                                            providersKosherCertificates?.length > 1
                                                ? "תעודות כשרות להורדה"
                                                : "תעודת כשרות להורדה"
                                        }
                                    />
                                )}
                            </>
                        )
                    }
                />
            ) : null;
        }

        return providerDetails?.kosher === kosherOptions[0] || providerDetails?.kosher === kosherOptions[2] ? (
            <AdditionalInfoItem
                title={`✡️ ${providerDetails.kosher}`}
                content={
                    providerDetails.kosherCertificateImageUrl && (
                        <>
                            {product?.productKosherType && (
                                <span>{`${kosherTypesToHebrew[product.productKosherType]} | `}</span>
                            )}
                            {providerDetails.kosherCertificateImageUrl && (
                                <AdditionalInfoLink
                                    onClick={() =>
                                        openKosherCertificatesInNewTab([providerDetails.kosherCertificateImageUrl])
                                    }
                                    text={"תעודת כשרות להורדה"}
                                />
                            )}
                        </>
                    )
                }
            />
        ) : null;
    }, [providerDetails, product, isProductBundle, productBundle]);

    const participantsInfoContent = useMemo(() => {
        const minParticipants = isProductBundle
            ? productBundle?.minRecommendedProductParticipants
            : product?.minParticipants;
        const maxParticipants = isProductBundle
            ? productBundle?.maxRecommendedProductParticipants
            : product?.maxParticipants;

        return minParticipants && maxParticipants ? (
            <AdditionalInfoItem
                title={"ℹ️ כמות משתתפים אפשרית לפעילות"}
                content={`${minParticipants}-${maxParticipants} משתתפים`}
            />
        ) : null;
    }, [product]);

    const foodRequestsContent = useMemo(() => {
        const foodRequests = isProductBundle
            ? uniq(flatten(productBundle?.products.map(product => product?.foodRequests)))
            : product?.foodRequests;

        return !isEmpty(foodRequests) ? (
            <AdditionalInfoItem
                title={"🌱 מענה לצרכים ספציפיים"}
                content={foodRequests?.map(req => translateFoodRequest(req)).join(", ")}
            />
        ) : null;
    }, [product, productBundle, isProductBundle]);

    const presentationContent = useMemo(() => {
        const presentation = isProductBundle
            ? uniq(flatten(productBundle?.products.map(product => product?.presentation)))
            : product?.presentation;

        return !isEmpty(presentation) ? (
            <AdditionalInfoItem
                title={"🤲 פורמט הגשה אפשרי"}
                content={presentation.map(req => translatePresentation(req)).join(", ")}
            />
        ) : null;
    }, [product, isProductBundle, productBundle]);

    const geographicAvailabilityContent = useMemo(
        () =>
            locations?.includes(ActivityLocation[ActivityLocationTypes.office]) ? (
                <AdditionalInfoItem
                    additionalClassName="additional-info-geographic-availability"
                    title={"🏢 זמינות גיאוגרפית לפעילות"}
                    content={
                        <>
                            <div>
                                <span>ניתן לשילוח ל</span>
                                <span>{me?.address ?? company?.address}</span>
                                <span>{" | "}</span>
                            </div>
                            <AdditionalInfoLink
                                onClick={() => onBookRedirectCollapse(ValidateBookingError.NO_DELIVERY)}
                                text={"לבדיקת כתובת שילוח אחרת/פיזור למס׳ כתובות"}
                            />
                        </>
                    }
                />
            ) : null,
        [locations, me?.address, company?.address]
    );

    const onlineAvailabilityContent = useMemo(() => {
        if (isProductBundle) {
            return null;
        }

        return !isEmpty(product?.location) && product?.location?.includes("online") ? (
            <AdditionalInfoItem
                title={"🖥️ הפעילות זמינה און-ליין"}
                content={"בתיאום הפעילות ניתן לסנכרן את חשבון הזום שלכם לייצור לינק לאירוע"}
            />
        ) : null;
    }, [isProductBundle, product]);

    const onsiteAvailabilityContent = useMemo(() => {
        if (isProductBundle) {
            return null;
        }

        return !isEmpty(product?.location) && product?.location?.includes("onsite") ? (
            <AdditionalInfoItem
                additionalClassName="additional-info-onsite-availability"
                title={
                    <span>
                        <span>📍</span>
                        <span> </span>
                        <span>הפעילות זמינה</span>
                        <span> </span>
                        <span>{product.address.length === 1 ? "בסניף" : "בסניפי"}</span>
                        <span> </span>
                        <span>בבית העסק</span>
                    </span>
                }
                content={product.address?.map(address => {
                    if (!address.name) return null;

                    return (
                        <div key={address.name}>
                            {address.name} {address.isAccessible ? "(סניף נגיש)" : ""}
                        </div>
                    );
                })}
            />
        ) : null;
    }, [isProductBundle, product]);

    const durationMinutesContent = useMemo(() => {
        if (isProductBundle) {
            return null;
        }

        const now = moment();
        const endTime = moment().add(product?.durationMinutes, "minutes");
        const timeLeft = moment.duration(endTime.diff(now));
        const formatTime = formatHebrewTimeString(timeLeft);

        return product?.durationMinutes ? (
            <AdditionalInfoItem
                title={<span>{new StringBuilder().append("🕔").append("משך הפעילות").toString()}</span>}
                content={<div key={product?.durationMinutes}>{formatTime}</div>}
            />
        ) : null;
    }, [isProductBundle, product]);

    return (
        <Panel
            {...props}
            key={key}
            showArrow={true}
            header={
                <>
                    <div className="panel-header-title">{"מידע נוסף"}</div>
                </>
            }>
            <div className="additional-info-panel">
                {supplyBrandsLinkContent}

                {kosherInfoContent}
                {participantsInfoContent}
                {foodRequestsContent}
                {presentationContent}
                {geographicAvailabilityContent}
                {onlineAvailabilityContent}
                {onsiteAvailabilityContent}
                {durationMinutesContent}

                {product?.chosenPriceType !== PriceTypes.ByVoucher ? (
                    <MarketplaceItemDetailsDeliveryInformation />
                ) : null}

                {images && images.length > 0 && (
                    <>
                        <div className={"marketplace-category-item-details-item-title"}>
                            📷 תמונות מאירועים של חברות
                        </div>
                        <div className={"marketplace-category-item-details-item-desc"}>
                            אין טוב ממראה עיניים :) יש לנו תיעוד של המוצר מחברות אחרות שהזמינו:
                        </div>
                        <MarketplaceImagesCarousel
                            log={log}
                            images={images}
                            showCarouselArrows={images.length > 1}
                            carouselProps={{infinite: true}}
                        />
                    </>
                )}
            </div>
        </Panel>
    );
};
