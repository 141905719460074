import React, {useContext} from "react";
import {sumBy} from "lodash";
import classNames from "classnames";
import {Dropdown, Menu, message, Spin, Tag} from "antd";
import {CheckOutlined, CrownFilled, DownOutlined, EllipsisOutlined, FlagOutlined} from "@ant-design/icons";
import {getLoginStatus} from "./utils";
import {ConfirmationRequiredWrapper, TableDoubleValueCell} from "../../components";
import {MainServices, PaymentType, PaymentTypeToName, ServiceIdToName} from "../../consts.js";
import {parseFloatNumber} from "../../utils/NumberUtils";
import {HttpClient} from "../../http/HttpClient.jsx";
import {NOOP} from "../../utils/NOOP.jsx";
import {AppContext} from "../../AppContext.jsx";

const PaymentTypeToColor = {
    [PaymentType.Budget]: "#87d068",
    [PaymentType.Card]: "#FF6B6B",
    [PaymentType.BankTransfer]: "#108ee9"
};

const ServiceIdToColor = {
    [MainServices.HAPPY_HOURS]: "green",
    [MainServices.PACKAGES]: "gold",
    [MainServices.PLATTERS]: "geekblue",
    [MainServices.LECTURES]: "magenta",
    [MainServices.WORKSHOPS]: "volcano"
};

const subAdminsTableColumns = (
    budgetsByUserId,
    budgetLoading,
    onViewOrders,
    onSendInvitationToSubAdmin,
    onShowBudgetHistory,
    subAdminsIdsToOrders,
    isSubAdminsOrdersLoading,
    onEditAdmin,
    onEditBudget,
    onPromoteUserToCompanyMaster,
    systemCSMs,
    onSelectCSM,
    loadingUpdatedUser,
    showSubAdminActions = true,
    company,
    onUpdateCsmPartitionedStatus = NOOP
) => {
    const {me} = useContext(AppContext);

    return [
        {
            title: "#",
            key: "#",
            width: 50,
            render(_, __, idx) {
                return `${idx + 1}`;
            }
        },
        {
            title: "שם האדמין",
            key: "name",
            sorter: (r1, r2) => ((r1.firstName?.toLowerCase() ?? "") < (r2.firstName?.toLowerCase() ?? "") ? 1 : -1),
            filters: [
                {
                    text: (
                        <span>
                            <CrownFilled className="sub-admin-table-crown" />
                            אדמין ראשי
                        </span>
                    ),
                    value: record => record?.isCompanyMaster
                },
                {
                    text: (
                        <span>
                            <CrownFilled className={classNames("sub-admin-table-crown", "manage-departments")} />
                            מנהל מחלקה
                        </span>
                    ),
                    value: record => record?.manageDepartments?.length
                }
            ],
            onFilter: (value, record) => value?.(record),
            render(_, {isCompanyMaster, manageDepartments, firstName, lastName, email}) {
                return (
                    <TableDoubleValueCell>
                        <span>
                            {isCompanyMaster ? (
                                <CrownFilled title="אדמין ראשי" className="sub-admin-table-crown" />
                            ) : null}
                            {manageDepartments?.length ? (
                                <CrownFilled
                                    title={`אחראי על מחלקות: ${manageDepartments.join(", ")}`}
                                    className={classNames("sub-admin-table-crown", "manage-departments")}
                                />
                            ) : null}
                            {firstName} {lastName}
                        </span>
                        {email}
                    </TableDoubleValueCell>
                );
            }
        },
        {
            title: "טלפון",
            dataIndex: "phone",
            key: "phone"
        },
        {
            title: "כתובת",
            sorter: (r1, r2) => ((r1.address?.toLowerCase() ?? "") < (r2.address?.toLowerCase() ?? "") ? 1 : -1),
            dataIndex: "address",
            key: "address"
        },
        {
            title: "מחלקה",
            dataIndex: "department",
            sorter: (r1, r2) =>
                (r1.department?.toString().toLowerCase() ?? "") < (r2.department?.toString().toLowerCase() ?? "")
                    ? 1
                    : -1
        },
        {
            title: "סה״כ הזמנות",
            dataIndex: "userId",
            key: "orders",
            render(userId) {
                if (isSubAdminsOrdersLoading) {
                    return <Spin size={"small"} />;
                }
                return parseFloatNumber(subAdminsIdsToOrders[userId]) || 0;
            },
            sorter: (r1, r2) =>
                Number(subAdminsIdsToOrders[r1.userId] || 0) - Number(subAdminsIdsToOrders[r2.userId] || 0)
        },
        {
            title: "יתרת תקציב",
            key: "budget",
            sorter: ({userId: r1}, {userId: r2}) =>
                (sumBy(budgetsByUserId[r1], budget => budget?.amount ?? 0) ?? 0) -
                (sumBy(budgetsByUserId[r2], budget => budget?.amount ?? 0) ?? 0),
            render(_, {userId}) {
                if (budgetLoading) {
                    return <Spin />;
                }

                const userBudgets = budgetsByUserId[userId];
                return userBudgets?.length ? parseFloatNumber(sumBy(userBudgets, budget => budget?.amount ?? 0)) : null;
            }
        },
        {
            title: "צורות תשלום מאושרות",
            width: 250,
            key: "approvedPayment",
            render(_, {allowedPaymentsMethods}) {
                return allowedPaymentsMethods?.map(paymentMethod => (
                    <Tag key={paymentMethod} className="sub-admin-table-tag" color={PaymentTypeToColor[paymentMethod]}>
                        {PaymentTypeToName[paymentMethod]}
                    </Tag>
                ));
            }
        },
        {
            title: "קטגוריות פתוחות",
            width: 250,
            key: "allowedServices",
            render(_, {allowedServices}) {
                return allowedServices?.map(serviceId => (
                    <Tag key={serviceId} className="sub-admin-table-tag" color={ServiceIdToColor[serviceId]}>
                        {ServiceIdToName[serviceId]}
                    </Tag>
                ));
            }
        },
        !!systemCSMs && company
            ? {
                  title: "מנהלת לקוח",
                  dataIndex: "assignedCSMs",
                  filters: [
                      ...(systemCSMs ?? []).map(csm => ({
                          text: csm.firstName,
                          value: csm.userId
                      })),
                      {text: "ללא מנהלת לקוח", value: "empty"}
                  ],
                  onFilter: (value, record) =>
                      value === "empty"
                          ? !record.assignedCSMs?.length
                          : record.assignedCSMs?.some(({userId}) => userId === value),
                  width: 150,
                  render(_, {companyId, userId, assignedCSMs, isCompanyMaster, email, isCSMPartitionedExcluded}) {
                      const savedAssignedCSMs = (isCompanyMaster ? company.assignedCSMs : assignedCSMs) ?? [];
                      return (
                          <>
                              <ConfirmationRequiredWrapper
                                  title={"בטוח שאתה רוצה לשנות את ניהול להקוח?"}
                                  subTitle={[
                                      "לחיצה על אישור תוציא את היוזר מהלוגיקה הקיימת, כך שיישאר על הבחירה שעשית עד שתשנה אחרת",
                                      "",
                                      <b>אם אתה לא בטוח מה אתה עושה התייעץ עם הפרודקט הקרוב אליך</b>
                                  ]}
                                  onYes={() =>
                                      onUpdateCsmPartitionedStatus(
                                          userId,
                                          companyId,
                                          isCompanyMaster,
                                          !isCSMPartitionedExcluded
                                      )
                                  }>
                                  <FlagOutlined
                                      style={{
                                          color: isCSMPartitionedExcluded ? "red" : "black",
                                          fontSize: 16,
                                          cursor: "pointer"
                                      }}
                                  />
                              </ConfirmationRequiredWrapper>
                              <div id={email}>
                                  <Dropdown
                                      trigger={["click"]}
                                      overlay={
                                          <Menu id="system-csm-select">
                                              {systemCSMs?.map(csm => (
                                                  <Menu.Item
                                                      key={csm?.userId}
                                                      onClick={() =>
                                                          onSelectCSM(
                                                              csm,
                                                              companyId,
                                                              userId,
                                                              assignedCSMs,
                                                              isCompanyMaster
                                                          )
                                                      }>
                                                      {savedAssignedCSMs?.some(({userId}) => userId === csm.userId) ? (
                                                          <CheckOutlined />
                                                      ) : null}
                                                      {csm.firstName}
                                                  </Menu.Item>
                                              ))}
                                          </Menu>
                                      }>
                                      <span className="admin-companies-csm-cell" id="csm-manage-view">
                                          {loadingUpdatedUser === userId ? (
                                              <Spin />
                                          ) : (
                                              <>
                                                  {savedAssignedCSMs.length > 0
                                                      ? savedAssignedCSMs.map(({firstName}) => firstName).join(", ")
                                                      : "ללא"}
                                                  <DownOutlined />
                                              </>
                                          )}
                                      </span>
                                  </Dropdown>
                              </div>
                          </>
                      );
                  }
              }
            : {},
        {
            title: "סטטוס התחברות",
            dataIndex: "firstLogin",
            sorter: (r1, r2) => (getLoginStatus(r1) < getLoginStatus(r2) ? 1 : -1),
            render(_, user) {
                return getLoginStatus(user);
            }
        },
        showSubAdminActions
            ? {
                  width: 60,
                  render(_, admin) {
                      if (admin.isCompanyMaster || me.userId === admin.userId) {
                          return null;
                      }

                      return (
                          <div className="admin-orders-actions-cell">
                              <Dropdown
                                  overlay={
                                      <Menu>
                                          {onEditAdmin ? (
                                              <Menu.Item onClick={() => onEditAdmin(admin)}>עריכת אדמין</Menu.Item>
                                          ) : null}
                                          {onEditBudget ? (
                                              <Menu.Item onClick={() => onEditBudget(admin)}>עריכת תקציב</Menu.Item>
                                          ) : null}
                                          <Menu.Item onClick={() => onShowBudgetHistory(admin)}>
                                              היסטוריית תקציב
                                          </Menu.Item>
                                          <Menu.Item onClick={() => onViewOrders(admin)}>צפייה בהזמנות</Menu.Item>
                                          <Menu.Item onClick={() => onSendInvitationToSubAdmin(admin)}>
                                              שליחת מייל התחברות/איפוס סיסמה
                                          </Menu.Item>
                                          {onPromoteUserToCompanyMaster ? (
                                              <ConfirmationRequiredWrapper
                                                  onYes={() => onPromoteUserToCompanyMaster(admin)}
                                                  subTitle={
                                                      <div>
                                                          <div>
                                                              שימו לב! בעת לחיצה על כפתור האישור הינכם מאשרים את ביצוע
                                                              פעולת העברת ההרשאות לתת האדמין
                                                          </div>
                                                          <br />
                                                          <div>לאחר ההחלפה ניתן לתת את ההרשאות לתת אדמין שנוצר</div>
                                                          <br />
                                                          <div>כל ההרשאות יועברו למשתמש הבא</div>
                                                          <div>שם: {admin.firstName}</div>
                                                          <div>מייל: {admin.email}</div>
                                                      </div>
                                                  }>
                                                  <Menu.Item>
                                                      קדם משתמש לאדמין ראשי{" "}
                                                      <CrownFilled className="sub-admin-table-crown" />
                                                  </Menu.Item>
                                              </ConfirmationRequiredWrapper>
                                          ) : null}
                                      </Menu>
                                  }
                                  trigger={["click"]}>
                                  <EllipsisOutlined className="admin-orders-order-action-button" />
                              </Dropdown>
                          </div>
                      );
                  }
              }
            : {}
    ];
};

export default subAdminsTableColumns;
