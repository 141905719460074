import {getOrderDiscountPrice, reduceVAT} from "../utils/calculationUtils";

export const PRODUCT_NAME_COLUMN_NUMBER = [2, 3];
export const PRODUCT_SUMMARY_COLUMN_NUMBER = [6, 7];
export const PRICE_COLUMN_NUMBER = [7, 8];
const ADMIN_MODE_PROVIDER_PAYMENT_COLUMN = 21;
const ADMIN_MODE_COMMISSION_COLUMN = 20;

export const getDeliveryRow = (
    row,
    order,
    dtStart,
    forProvider = false,
    adminMode = false,
    productNameColumnNumber = null,
    productSummaryColumnNumber = null,
    priceColumnNumber = null,
    priceWithoutTaxColumnNumber = null
) => {
    const whatColumnToUse = Number(adminMode);
    const deliveryPrice = forProvider ? order.providerDeliveryPrice ?? order.deliveryPrice : order.deliveryPrice;

    const deliveryRow = [...row];
    deliveryRow[productNameColumnNumber ?? PRODUCT_NAME_COLUMN_NUMBER[whatColumnToUse]] = "עלות משלוח";
    deliveryRow[productSummaryColumnNumber ?? PRODUCT_SUMMARY_COLUMN_NUMBER[whatColumnToUse]] = `עלות משלוח ${dtStart}`;
    deliveryRow[priceColumnNumber ?? PRICE_COLUMN_NUMBER[whatColumnToUse]] = deliveryPrice;
    deliveryRow[priceWithoutTaxColumnNumber ?? PRICE_COLUMN_NUMBER[whatColumnToUse] + 1] = reduceVAT(
        deliveryPrice,
        order.vat
    );

    if (adminMode) {
        deliveryRow[ADMIN_MODE_PROVIDER_PAYMENT_COLUMN] = reduceVAT(deliveryPrice, order.vat);
        deliveryRow[ADMIN_MODE_PROVIDER_PAYMENT_COLUMN + 1] = deliveryPrice;
        deliveryRow[ADMIN_MODE_COMMISSION_COLUMN] = null;
    }
    return deliveryRow;
};

export const getDiscountRow = (
    row,
    order,
    createdAt,
    adminMode = false,
    productNameColumnNumber = null,
    productSummaryColumnNumber = null,
    priceColumnNumber = null,
    priceWithoutTaxColumnNumber = null
) => {
    const whatColumnToUse = Number(adminMode);
    const discountRow = [...row];
    discountRow[productNameColumnNumber ?? PRODUCT_NAME_COLUMN_NUMBER[whatColumnToUse]] = "הנחת קוד קופון";
    discountRow[
        productSummaryColumnNumber ?? PRODUCT_SUMMARY_COLUMN_NUMBER[whatColumnToUse]
    ] = `הנחת קוד קופון ${createdAt}`;
    discountRow[priceColumnNumber ?? PRICE_COLUMN_NUMBER[whatColumnToUse]] = getOrderDiscountPrice(order);
    discountRow[priceWithoutTaxColumnNumber ?? PRICE_COLUMN_NUMBER[whatColumnToUse] + 1] = getOrderDiscountPrice(
        order,
        false
    );
    if (adminMode) {
        discountRow[ADMIN_MODE_PROVIDER_PAYMENT_COLUMN] = null;
        discountRow[ADMIN_MODE_PROVIDER_PAYMENT_COLUMN + 1] = null;
        discountRow[ADMIN_MODE_COMMISSION_COLUMN] = null;
    }
    return discountRow;
};

export const getTipRow = (
    row,
    order,
    createdAt,
    adminMode = false,
    productNameColumnNumber = null,
    productSummaryColumnNumber = null,
    priceColumnNumber = null,
    priceWithoutTaxColumnNumber = null
) => {
    const whatColumnToUse = Number(adminMode);
    const discountRow = [...row];
    discountRow[productNameColumnNumber ?? PRODUCT_NAME_COLUMN_NUMBER[whatColumnToUse]] = "טיפ";
    discountRow[productSummaryColumnNumber ?? PRODUCT_SUMMARY_COLUMN_NUMBER[whatColumnToUse]] = `טיפ ${createdAt}`;
    discountRow[priceColumnNumber ?? PRICE_COLUMN_NUMBER[whatColumnToUse]] = order.tip;
    discountRow[priceWithoutTaxColumnNumber ?? PRICE_COLUMN_NUMBER[whatColumnToUse] + 1] = reduceVAT(
        order.tip,
        order.vat
    );

    if (adminMode) {
        discountRow[ADMIN_MODE_PROVIDER_PAYMENT_COLUMN] = reduceVAT(order.tip, order.vat);
        discountRow[ADMIN_MODE_PROVIDER_PAYMENT_COLUMN + 1] = order.tip;
        discountRow[ADMIN_MODE_COMMISSION_COLUMN] = null;
    }

    return discountRow;
};

export const getCompanyOrdersDiscountRow = (
    row,
    order,
    createdAt,
    adminMode = false,
    productNameColumnNumber = null,
    productSummaryColumnNumber = null,
    priceColumnNumber = null,
    priceWithoutTaxColumnNumber = null
) => {
    const whatColumnToUse = Number(adminMode);
    const calculateCompanyOrdersDiscountAmount = (Number(order.ordersDiscount) * Number(order.offeredPrice)) / 100;
    const companyOrdersDiscountRow = [...row];
    companyOrdersDiscountRow[productNameColumnNumber ?? PRODUCT_NAME_COLUMN_NUMBER[whatColumnToUse]] =
        "הנחת קאשבק לתתי אדמינים";
    companyOrdersDiscountRow[
        productSummaryColumnNumber ?? PRODUCT_SUMMARY_COLUMN_NUMBER[whatColumnToUse]
    ] = `הנחת קאשבק לתתי אדמינים ${createdAt}`;
    companyOrdersDiscountRow[priceColumnNumber ?? PRICE_COLUMN_NUMBER[whatColumnToUse]] =
        -1 * calculateCompanyOrdersDiscountAmount;
    companyOrdersDiscountRow[priceWithoutTaxColumnNumber ?? PRICE_COLUMN_NUMBER[whatColumnToUse] + 1] = reduceVAT(
        -1 * calculateCompanyOrdersDiscountAmount,
        order.vat
    );

    if (adminMode) {
        companyOrdersDiscountRow[ADMIN_MODE_PROVIDER_PAYMENT_COLUMN] = "";
        companyOrdersDiscountRow[ADMIN_MODE_PROVIDER_PAYMENT_COLUMN + 1] = "";
        companyOrdersDiscountRow[ADMIN_MODE_COMMISSION_COLUMN] = null;
    }

    return companyOrdersDiscountRow;
};
