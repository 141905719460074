import React, {useCallback, useContext, useMemo} from "react";
import "./company-permission-modal.css";
import {AppContext} from "../../AppContext.jsx";
import {FormModal} from "../../components/index.jsx";

export const CompanyPermissionModal = ({visible, onClose, onSave}) => {
    const {company, me} = useContext(AppContext);
    const content = useMemo(() => (
        {
            needManagerConfirm: company?.needManagerConfirm,
            allowBudgetTransferByManageDepartment: company?.allowBudgetTransferByManageDepartment
        }), [company]);
    const onSavePermissions = useCallback(values => onSave(values), [onSave]);

    return (
        <>
            <FormModal
                className="company-permission-modal-container"
                header="הרשאות של תתי אדמין במערכת"
                subTitle="בלחיצה על הטוגל, תוכלו לשלוט בצורה הדוקה יותר על התתי אדמינים שלכם. לחיצה על האייקון תפרט על כל יכולת בנפרד."
                visible={visible}
                fields={[
                    ...(me?.features?.accept_by_manager_option ? [{
                        name: "needManagerConfirm",
                        type: "boolean",
                        info: "סימון התיבה הזו יחייב את האדמין הראשי /מנהל מחלקה לאשר את ההזמנות שתתי האדמינים שתחתיו ביצועו. רק לאחר אישור זה ההזמנה תכנס למערכת ותעבור לאישור הספק",
                        label: "אישור הזמנה על ידי האחראי מחלקה",
                        checkedChildren: "כן",
                        unCheckedChildren: "לא"
                    }] : []),
                    {
                        name: "allowBudgetTransferByManageDepartment",
                        type: "boolean",
                        info: "סימון תיבה זו יאפשר למנהלי מחלקה להעביר תקציבים שברשותם למשתמשים המשויכים למחלקה שלהם",
                        label: "ניוד כספים של מנהלי מחלקה",
                        checkedChildren: "כן",
                        unCheckedChildren: "לא"
                    }
                ]}
                onSave={onSavePermissions}
                content={content}
                onClose={onClose}
            />
        </>
    );
};
