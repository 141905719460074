import React from "react";

export const CoverSizeTag = ({width, height, style}) => (
    <div
        style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            height: 40,
            width: 120,
            backgroundColor: "rgba(0,0,0,0.5)",
            color: "white",
            fontSize: 16,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...style
        }}>
        {width} X {height}
    </div>
);
