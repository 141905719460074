import React, {useCallback, useContext, useMemo} from "react";
import {ConfigProvider} from "antd";
import "./budget-filter-popover.css";
import {MarketplaceFilterProviderContext} from "../../MarketplaceFilterContext";
import {CategoryToBudgetFilterText, consistentCheckboxOptions} from "./consts.js";
import {BudgetFilterInput} from "./BudgetFilterInput.jsx";
import {CheckboxGroupOptions} from "../../../../components/CheckboxGroup";
import classnames from "classnames";
import {MainServices} from "../../../../consts.js";
import {getBudgetSectionTitle} from "./utils.js";
import {BasePopoverFilter} from "../basePopoverFilter.jsx";
import {buildFilters} from "../../advancedFilter/utils.js";
import {isEqual} from "lodash";
import {AppContext} from "../../../../AppContext.jsx";

export const BudgetFilter = () => {
    const {filterValues} = useContext(AppContext);
    const {innerState, updateInnerState, panel, setInnerState, budgetTitle, setBudgetTitle} = useContext(
        MarketplaceFilterProviderContext
    );

    const onConsistentOptionChange = useCallback(consistentOption => {
        updateInnerState("budget.consistentBudget", consistentOption);
    }, []);

    const onClear = useCallback(() => {
        setInnerState(({budget, ...otherProperties}) => ({...otherProperties}));
    }, [setInnerState]);

    const onClosePopover = useCallback(() => {
        setBudgetTitle(getBudgetSectionTitle(innerState.budget, panel));
    }, [innerState, panel]);

    const didStateChange = useMemo(() => {
        const filter = buildFilters(innerState);
        const isBudgetEqual = isEqual(filter.budget, filterValues.budget);
        return !isBudgetEqual;
    }, [innerState?.budget, filterValues?.budget]);

    return (
        <div>
            <ConfigProvider direction="rtl">
                <BasePopoverFilter
                    onClear={onClear}
                    searchOnClose={didStateChange}
                    onClosePopover={onClosePopover}
                    className={classnames("budget-filter-popover", panel)}
                    title={CategoryToBudgetFilterText[panel]}
                    content={
                        <>
                            {panel === MainServices.HAPPY_HOURS ? (
                                <BudgetFilterInput showAmountInput={false} showBudgetPerParticipantInput={false} />
                            ) : (
                                <BudgetFilterInput />
                            )}
                            <span className="budget-warning-title">המחירים בפלטפורמה כוללים מע״מ</span>
                            <div className="consistent-container">
                                <CheckboxGroupOptions
                                    value={innerState?.budget?.consistentBudget}
                                    options={consistentCheckboxOptions}
                                    span={null}
                                    onChange={onConsistentOptionChange}
                                />
                            </div>
                        </>
                    }>
                    <div className="advanced-filter-segmented-label-container">
                        <div className="advanced-filter-option-title">{CategoryToBudgetFilterText[panel]}</div>
                        <div
                            className={classnames("advanced-filter-option-sub-title", {
                                selected: !!budgetTitle
                            })}>
                            {budgetTitle ?? "הזן תקציב"}
                        </div>
                    </div>
                </BasePopoverFilter>
            </ConfigProvider>
        </div>
    );
};
