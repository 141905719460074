const envVars = import.meta.env;

export const ConfigProps = Object.freeze({
    ENVIRONMENT: "ENVIRONMENT",
    SUPPORT_PHONE_NUMBER: "SUPPORT_PHONE_NUMBER",
    SYSTEM_ID_TO_SUPPORT_PHONE_NUMBER: "SYSTEM_ID_TO_SUPPORT_PHONE_NUMBER",
    SOCKET_SERVER_URL: "SOCKET_SERVER_URL"
});

export class Config {
    static env(key) {
        return envVars[`VITE_${key}`];
    }

    static envNum(key) {
        return parseInt(Config.env(key), 10);
    }

    static envJson(key) {
        try {
            return JSON.parse(Config.env(key));
        } catch (e) {
            console.error(e);
            return {};
        }
    }

    static getEnvName() {
        return Config.env(ConfigProps.ENVIRONMENT);
    }
}
