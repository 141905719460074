import React, {useCallback, useContext, useEffect, useState} from "react";
import moment from "moment-timezone";
import {ConfigProvider, message, Spin} from "antd";
import {ButtonGroup} from "../../components";
import {TableDateSearch} from "../../admin/components";
import {generateProviderOrdersShortcuts} from "./utils.js";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {OrderShortcutFilterValues} from "./consts.js";
import {ProviderOrderContext} from "./ProviderOrdersContext.jsx";
import {OrderCollapse} from "./OrderCollapse/OrderCollapse.mobile.jsx";
import "./provider-orders.mobile.css";
import {SearchByCreatedSwitch} from "./ProviderOrdersHelper.jsx";
import {HttpClient} from "../../http/HttpClient.jsx";
import {GoogleAnalytics} from "../../GoogleAnalytics.jsx";
import {ContactPhone} from "../../support/ContactPhone.jsx";
import {DeclineOrderModal} from "../../event/DeclineOrderModal/DeclineOrderModal.jsx";
import {AppContext} from "../../AppContext.jsx";
import {Images} from "../../images/Images.jsx";
import "./date-search.mobile.css";
import common from "@well-b/well-b-shared";

const {noOrders} = Images;

export const ProviderOrdersMobile = () => {
    const {me} = useContext(AppContext);
    const [isShowDateFilter, setIsShowDateFilter] = useState(false);
    const [declineOrderModalVisible, setDeclineOrderModalVisible] = useState(null);

    const {
        orders,
        onDetails,
        acceptOffer,
        onSearchByCreatedAtChange,
        onShortcutClick,
        onDatePicker,
        setSearchInput,
        selectedOption,
        searchByCreated,
        searchDate,
        fetchOrders,
        loadingOrders
    } = useContext(ProviderOrderContext);

    useEffect(() => {
        const defaultShortcutOption = generateProviderOrdersShortcuts.find(
            op => op.value === OrderShortcutFilterValues.PENDING
        );
        onShortcutClick(defaultShortcutOption);
    }, []);

    const declineOffer = useCallback(
        async declineDetails => {
            const status = await HttpClient.post(`/api/events/${declineOrderModalVisible?.eventId}/declineV2`, {
                declineReason: declineDetails?.declineReason,
                declineComment: declineDetails?.declineComment
            });

            if (status?.error) {
                message.error(status?.error);
                return;
            }

            message.success("הזמנה בוטלה בהצלחה");
            fetchOrders();
            GoogleAnalytics.event("Session Proposal", "Decline", declineOrderModalVisible?.eventId);
            setDeclineOrderModalVisible(null);
        },
        [declineOrderModalVisible?.eventId, fetchOrders]
    );

    return (
        <div className="provider-orders-mobile">
            <div className="provider-manage-invoices-title">ניהול הזמנות</div>
            <div className="provider-orders-header">
                <ButtonGroup
                    onSelect={onShortcutClick}
                    options={generateProviderOrdersShortcuts}
                    selectedOption={selectedOption}
                    defaultSelectedOption={OrderShortcutFilterValues.PENDING}
                    allowDeselect={false}
                />

                <div>
                    <div
                        className="provider-orders-advanced-filter"
                        onClick={() => setIsShowDateFilter(isShowOrderActions => !isShowOrderActions)}>
                        <span>חיפוש מתקדם</span>
                        <span>{isShowDateFilter ? <DownOutlined /> : <UpOutlined />}</span>
                    </div>

                    {!!isShowDateFilter && (
                        <div className="provider-orders-top-bar-mobile">
                            <TableDateSearch
                                date={searchDate}
                                onDatePicked={onDatePicker}
                                onSearch={setSearchInput}
                                disableFuture={searchByCreated}
                                allowClear={false}
                                defaultValue={[moment().startOf("month"), moment().endOf("month")]}
                                className="data-search-mobile"
                            />

                            <SearchByCreatedSwitch
                                searchByCreated={searchByCreated}
                                onChange={onSearchByCreatedAtChange}
                            />
                        </div>
                    )}
                </div>
            </div>

            {loadingOrders ? (
                <Spin />
            ) : (
                <>
                    {orders?.length ? (
                        orders?.map((order, index) => {
                            return (
                                <div key={index} className="provider-orders-list">
                                    <ConfigProvider direction="rtl">
                                        <OrderCollapse
                                            key={index}
                                            order={order}
                                            onAcceptOffer={acceptOffer}
                                            onDetails={onDetails}
                                            onDecline={order => setDeclineOrderModalVisible(order)}
                                        />
                                    </ConfigProvider>
                                </div>
                            );
                        })
                    ) : (
                        <img className="no-found-orders" src={noOrders} alt="page_not_found_image" />
                    )}
                </>
            )}

            <DeclineOrderModal
                visible={!!declineOrderModalVisible}
                onSave={async declineDetails => {
                    if (me?.features?.declineReason) {
                        await declineOffer(declineDetails);
                        return;
                    }
                    await declineOffer();
                }}
                onClose={() => setDeclineOrderModalVisible(false)}
                onContactClick={() =>
                    ContactPhone.openWhatsApp(ContactPhone.phoneNumberBySystemId(common.themeNames.wellB))
                }
            />
        </div>
    );
};
