import {times as _times} from "lodash";

export class ImageCaching {
    static cache = {};

    static push(key, imageElem, times = 3) {
        if (Array.isArray(this.cache[key]) && this.cache[key].length < times) {
            this.cache[key] = this.cache[key].concat(
                _times(times - this.cache[key].length, () => imageElem.cloneNode(true))
            );
        } else {
            this.cache[key] = _times(times, () => imageElem.cloneNode(true));
        }
    }

    static pop(key) {
        return this.cache[key] && this.cache[key].length > 0 ? this.cache[key].pop() : null;
    }
}
