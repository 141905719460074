import React, {useCallback, useContext, useMemo} from "react";
import {ConfigProvider, Form} from "antd";
import "../../../../dashboards/app-dashboard.css";
import {AutoCompleteAddressFormInput} from "../../../../utils/AutoCompleteAddress";
import {FormInputV2} from "../../../../components/form";
import "./delivery-address-input.css";
import {MarketplaceFilterProviderContext} from "../../MarketplaceFilterContext";
import {get} from "lodash";

export const DeliveryAddressInput = ({
    placeholder,
    path,
    deliveryType,
    showDeliveryPrice = true,
    locationTypes,
    isSelfPickupPossible = false
}) => {
    const {innerState, removeKeyFromInnerState, updateInnerState} = useContext(MarketplaceFilterProviderContext);

    const address = useMemo(() => {
        return get(innerState, `${path}.displayName`) ?? "";
    }, [innerState.locationFilter, path]);

    const maxDeliveryPrice = useMemo(() => {
        return get(innerState, `${path}.deliveryPrice`) ?? null;
    }, [innerState.locationFilter, path]);

    const onAddressSelect = useCallback(
        (displayName, option) => {
            const deliveryAddressOptions = {displayName, placeIds: [option.place_id], deliveryType};
            if (isSelfPickupPossible) {
                deliveryAddressOptions.isSelfPickupPossible = isSelfPickupPossible
            }
            updateInnerState(path, deliveryAddressOptions);
        },
        [path, isSelfPickupPossible, deliveryType, updateInnerState]
    );

    const onResetAddress = useCallback(
        value => {
            if (value === "") {
                removeKeyFromInnerState(`${path}.placeIds`);
                removeKeyFromInnerState(`${path}.displayName`);
                removeKeyFromInnerState(`${path}.deliveryType`);
                removeKeyFromInnerState(`${path}.isSelfPickupPossible`);
            }
        },
        [path]
    );

    const onSetMaxDeliveryPrice = useCallback(
        event => {
            if (event.target.value === "" || parseInt(event.target.value) < 0) {
                removeKeyFromInnerState(`${path}.deliveryPrice`);
                return;
            }
            updateInnerState(`${path}.deliveryPrice`, Number(event.target.value));
        },
        [path]
    );

    return (
        <div id="radio-group-options">
            <ConfigProvider direction="rtl">
                <div className="delivery-address-container">
                    <Form.Item className="address-input">
                        <AutoCompleteAddressFormInput
                            onChange={onAddressSelect}
                            editValue={address}
                            onInputChange={onResetAddress}
                            placeholder={placeholder}
                            countriesToSearchIn={["IL"]}
                            locationTypes={locationTypes}
                            allowSelectRegularText={true}
                            showDefaultOption={false}
                        />
                    </Form.Item>
                    {showDeliveryPrice && (
                        <Form.Item>
                            <FormInputV2
                                disabled={!address || address === ""}
                                value={maxDeliveryPrice}
                                onChange={onSetMaxDeliveryPrice}
                                type="number"
                                placeholder="מקסימום מחיר משלוח"
                                className="products-filters-modal-number-input"
                            />
                        </Form.Item>
                    )}
                </div>
            </ConfigProvider>
        </div>
    );
};
