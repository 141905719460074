import {LinkOutlined, EyeOutlined} from "@ant-design/icons";
import {ConfigProvider, DatePicker, Input, Switch} from "antd";
import {noop} from "lodash";
import moment from "moment";
import React from "react";
import {TableDateCell} from "../../components/Table/TableDateCell";
import {ConfirmationRequiredWrapper} from "../../components";
import {SquareButton} from "../../components/SquareButton";
import {TrashIcon, EditIcon} from "../../icons";
import {DropdownWithTextFields} from "./DropdownWithTextFields";

const {RangePicker} = DatePicker;

const getColumns = (
    onDelete = noop,
    onEdit = noop,
    onCopyLink = noop,
    onPreview = noop,
    onEditSteps = noop,
    onSave = noop,
    isEditedValid = false
) => [
    {
        title: "#",
        dataIndex: "popupId",
        render(text, record) {
            return (
                <div className="popupId" onClick={() => copyToClipboard(record.popupId)}>
                    {record.popupId}
                </div>
            );
        }
    },
    {
        title: "שם הפופאפ",
        dataIndex: "name",
        render(text, record) {
            return <Input value={record.name} onChange={e => onEdit({...record, name: e.target.value})}></Input>;
        }
    },
    {
        title: "תאריך יצירה",
        dataIndex: "createdAt",
        sorter: ({createdAt: r1}, {createdAt: r2}) => r1 - r2,
        render(createdAt) {
            return <TableDateCell date={createdAt} />;
        }
    },
    {
        title: "תאריך עדכון אחרון",
        key: "updatedAt",
        sorter: ({updatedAt: r1}, {updatedAt: r2}) => r1 - r2,
        render({updatedAt}) {
            return <TableDateCell date={updatedAt} />;
        }
    },
    {
        title: "מס השלבים",
        key: "steps",
        render({steps}) {
            if (!steps) {
                return <>0</>;
            }
            return <>{steps.length}</>;
        }
    },
    {
        title: "עמודים",
        key: "pages",
        render(text, record) {
            return (
                <ConfigProvider direction="ltr">
                    <DropdownWithTextFields
                        className="popup-url-input"
                        title="page placement"
                        itemList={record?.pages || []}
                        onEdit={pages => {
                            onEdit({...record, pages});
                        }}
                    />
                </ConfigProvider>
            );
        }
    },
    {
        title: "תיחום זמן",
        key: "dateRange",
        render(text, record) {
            const {startDate, endDate} = record;
            return (
                <RangePicker
                    onChange={([startDate, endDate]) => {
                        onEdit({...record, startDate: startDate.unix() * 1000, endDate: endDate.unix() * 1000});
                    }}
                    bordered={false}
                    disabled={false}
                    value={[moment(startDate), moment(endDate)]}
                />
            );
        }
    },
    {
        title: "סטטוס",
        dataIndex: "isActive",
        render(text, record) {
            return (
                <Switch
                    onChange={({checked}) => {
                        onEdit({...record, isActive: !record.isActive});
                    }}
                    checked={record.isActive}
                    checkedChildren="באויר"
                    unCheckedChildren="לא פעיל"
                />
            );
        }
    },
    {
        title: "פעולות",
        key: "actions",
        render(text, record) {
            return record.isEditing ? (
                <SquareButton disabled={!isEditedValid} onClick={onSave} className="primary-color-button save-btn">
                    שמור
                </SquareButton>
            ) : (
                <div className="action-icons">
                    <EyeOutlined onClick={() => onPreview(record)} />
                    <div
                        onClick={() => {
                            onEditSteps(record);
                        }}>
                        <EditIcon />
                    </div>
                    <LinkOutlined onClick={() => onCopyLink(record)} />
                    <ConfirmationRequiredWrapper onYes={() => onDelete(record)}>
                        <TrashIcon />
                    </ConfirmationRequiredWrapper>
                </div>
            );
        }
    }
];

export default getColumns;
