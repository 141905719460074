const AllWeekDaysMap = {
    SU: {short: "Sun", normal: "Sunday"},
    MO: {short: "Mon", normal: "Monday"},
    TU: {short: "Tue", normal: "Tuesday"},
    WE: {short: "Wed", normal: "Wednesday"},
    TH: {short: "Thu", normal: "Thursday"},
    FR: {short: "Fri", normal: "Friday"},
    SA: {short: "Sat", normal: "Saturday"}
};

export const AsiaJerusalemTZ = "Asia/Jerusalem";

export const AsiaJerusalemWorkingDays = workingDays(0, 5);

export const ROWWorkingDays = workingDays(1, 6);

function workingDays(from, to) {
    return Object.keys(AllWeekDaysMap)
        .slice(from, to)
        .reduce((obj, key) => {
            obj[key] = AllWeekDaysMap[key];
            return obj;
        }, {});
}

export class DaysMapper {
    static mapKeyToDay(key, type = "normal") {
        return AllWeekDaysMap[key][type];
    }

    static mapDayToKey(day, type = "normal") {
        return Object.keys(AllWeekDaysMap).reduce(
            (keyObj, key) => {
                const d = AllWeekDaysMap[key][type];
                if (d === day) {
                    keyObj.key = key;
                }
                return keyObj;
            },
            {key: ""}
        ).key;
    }

    static allWorkingWeekDays(tzid, type = "normal") {
        const allDays = tzid === AsiaJerusalemTZ ? AsiaJerusalemWorkingDays : ROWWorkingDays;
        return Object.keys(allDays).map(dayKey => {
            return allDays[dayKey][type];
        });
    }
}
