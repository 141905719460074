import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import moment from "moment-timezone";
import {flatten, groupBy, maxBy, merge, minBy, sortBy, sumBy, uniq} from "lodash";
import {ConfigProvider, Dropdown, message, Switch} from "antd";
import {OrderChangesHistoryModal} from "./OrderChangesHistoryModal";
import {OrderImagesModal} from "./OrderImagesModal";
import {ORDERS_PER_PAGE, statusToHebrewAction} from "./consts";
import columns from "./columns";
import {OrdersStatisticsLabel} from "./OrdersStatisticsLabel";
import {ExportTableToCRMExcel} from "../../orders/orderExcelCRMExporter";
import {BUDGET_LOAD_TRANSACTION, TransactionServiceIdToName} from "../../orders/consts";
import {TableDateSearch} from "../components";
import {
    AreYouSureModal,
    GenericModal,
    OrderStatus,
    PageTitleHeader,
    RoundedTable,
    SquareButton
} from "../../components";
import {useLocalStorage, useRequest} from "../../utils/hooks";
import {HttpClient} from "../../http/HttpClient";
import {GoogleAnalytics} from "../../GoogleAnalytics";
import {AppContext} from "../../AppContext";
import {ExportTableToProviderExcel} from "../../orders/orderExcelProviderExporter";
import "./admin-orders.css";
import {AdminEditOrderPage} from "./AdminEditOrderPage.jsx";
import {Images} from "../../images/Images";
import {EventBus} from "../../bus/EventBus";
import {ExportTableToCSMSuppliesExcel} from "../../orders/orderExcelCSMSuppliesExporter.jsx";
import {StringBuilder} from "../../AppUtils.js";
import {isOrderHasShippingProvider} from "../../event/utils.jsx";
import {ExportWellBShippingExcel} from "../../orders/ExportWellBShippingExcel.jsx";
import {DeclineOrderModal} from "../../event/DeclineOrderModal/DeclineOrderModal.jsx";
import {openWindow} from "../../http/WindowOpener.jsx";
import {TableDateSearchProvider} from "../Context/TableDateSearchContext.jsx";
import {ChangeOrderDeliveryShippingModal} from "../../event/ChangeOrderDeliveryShippingModal/ChangeOrderDeliveryShippingModal.jsx";
import {UpdateOrderStatusModal} from "./UpdateOrderModal/index.js";

export const INTERVAL_TIME = 1000 * 60 * 10; // 10 minutes

export const AdminOrders = ({history}) => {
    const {me, services} = useContext(AppContext);
    const [innerOrders, setInnerOrders] = useState([]);
    const [searchInput, setSearchInput] = useState(null);

    const [searchDate, setSearchDate] = useLocalStorage(
        "adminOrdersSearchDate",
        [moment().startOf("month"), moment().endOf("month")],
        ([start, end]) => [moment(start), moment(end)]
    );

    const [searchByCreated, setSearchByCreated] = useState(true);
    const [historyOrder, setHistoryOrder] = useState(null);
    const [imagesOrder, setImagesOrder] = useState(null);
    const [orderImagesModalVisible, setOrderImagesModalVisible] = useState(false);
    const [updateOrderStatusInfo, setUpdateOrderStatusInfo] = useState(null);
    const [isExcelLoading, setIsExcelLoading] = useState(false);
    const [budgetLoadTransaction, setBudgetLoadTransaction] = useState();
    const [eventActionModal, setEventActionModal] = useState(null);
    const [eventActionModalText, setEventActionModalText] = useState(null);
    const [declineOrder, setDeclineOrder] = useState(null);
    const [changeDeliveryProviderOrder, setChangeDeliveryProviderOrder] = useState(null);

    const [orders, isLoadingOrders, _isErrorOnLoadingOrders, fetchOrders] = useRequest(
        "/admin/api/orders",
        "POST",
        {
            startTime: searchDate[0].valueOf(),
            endTime: searchDate[1].valueOf(),
            byCreatedAt: searchByCreated
        },
        [searchDate, searchByCreated],
        searchDate?.length
    );

    const [budgetLogs, isLoadingBudgetTransactions] = useRequest(
        `/admin/api/getLoadedBudgetTransactionBetweenDates/${searchDate[0].valueOf()}/${searchDate[1].valueOf()}?enrichCompanyDetails=true`,
        "get",
        null,
        [],
        searchDate?.length > 1
    );
    const [deliveryProviders] = useRequest("/admin/api/deliveryProviders");

    useEffect(() => {
        if (orders?.error) {
            message.error(
                new StringBuilder().append("שגיאה בעת טעינת הזמנות מוצרים").append(`(${orders?.error})`).toString(),
                10
            );
        }
    }, [orders?.error]);

    useEffect(() => {
        setBudgetLoadTransaction(
            (budgetLogs ?? []).map(({amount, id, ...rest}) => ({
                ...rest,
                eventId: id,
                productName: TransactionServiceIdToName[BUDGET_LOAD_TRANSACTION],
                offeredPrice: amount,
                serviceId: BUDGET_LOAD_TRANSACTION,
                dtstart: rest.createdAt,
                isBudgetTransaction: true,
                commission: 18
            }))
        );
    }, [budgetLogs]);

    const orderingCompanies = useMemo(() => orders?.length && uniq(orders.map(({companyId}) => companyId)), [orders]);

    const [companiesStats, loadingCompaniesStats] = useRequest(
        "/admin/api/statistics/getCompaniesOrderStatistics",
        "post",
        orderingCompanies,
        [orderingCompanies],
        orderingCompanies?.length
    );

    const [systemUsers, isLoadingSystemCSMs] = useRequest("/admin/api/users/systemStaffUsers");

    const ordersWithBudgetLoads = useMemo(
        () => [...(innerOrders ?? []), ...(budgetLoadTransaction ?? [])],
        [innerOrders, budgetLoadTransaction]
    );

    const onEditOrder = useCallback(order => {
        openWindow(`/admin/events/${order.eventId}/edit`);
    }, []);

    const onChangeOrderStatus = useCallback(
        async (event, status, toRemove) => {
            try {
                let showSuccess = true;
                let shouldSyncAllOrders = true;
                if (toRemove) {
                    await HttpClient.post(`/admin/api/orders/${event.eventId}/changeStatus/${status}`, {
                        remove: toRemove,
                        updatedAt: event.updatedAt
                    });
                } else {
                    switch (status) {
                        case OrderStatus.accepted: {
                            const {error, status} = await HttpClient.safePost(`/api/events/${event.eventId}/acceptV2`, {
                                updatedAt: event.updatedAt
                            });

                            if (status >= 400) {
                                const errorMessage = `Failed to accept event ${event.eventId}. Please contact support.`;
                                EventBus.triggerError(
                                    "server-error",
                                    {content: {description: errorMessage}},
                                    error.message
                                );
                                showSuccess = false;
                            } else if (error) {
                                setEventActionModalText(error);
                                setEventActionModal(event);
                                showSuccess = false;
                            }
                            GoogleAnalytics.event("Session Proposal", "Accept", event.eventId);
                            break;
                        }
                        case OrderStatus.declined: {
                            if (me?.features?.declineReason) {
                                setDeclineOrder(event);
                                showSuccess = false;
                                shouldSyncAllOrders = false;
                            } else {
                                await HttpClient.post(`/api/events/${event.eventId}/declineV2`);
                            }
                            break;
                        }
                        case OrderStatus.delivered: {
                            await HttpClient.post(`/api/events/${event.eventId}/delivered`);
                            break;
                        }
                        case OrderStatus.cancelled: {
                            await HttpClient.post(
                                `/admin/api/orders/${event.eventId}/cancel?updatedAt=${event.updatedAt}`
                            );
                            break;
                        }
                        default: {
                            await HttpClient.post(`/admin/api/orders/${event.eventId}/changeStatus/${status}`, {
                                remove: toRemove,
                                updatedAt: event.updatedAt
                            });
                            break;
                        }
                    }
                }

                if (showSuccess) {
                    message.success("ההזמנה השתנתה בהצלחה.");
                }

                if (shouldSyncAllOrders) {
                    fetchOrders();
                }
            } catch (e) {
                message.error(e.message, 3);
            }
            setUpdateOrderStatusInfo(null);
        },
        [fetchOrders, me?.features?.declineReason]
    );

    const declineOffer = useCallback(
        async ({declineReason, declineComment}) => {
            try {
                const {error} = await HttpClient.post(`/api/events/${declineOrder.eventId}/declineV2`, {
                    declineReason,
                    declineComment
                });

                if (error) {
                    message.error(error, 3);
                } else {
                    message.success("ההזמנה השתנתה בהצלחה.");
                }

                fetchOrders();
            } catch (e) {
                message.error(e.message, 3);
            }
            setDeclineOrder(null);
        },
        [declineOrder, fetchOrders]
    );

    useEffect(() => {
        if (orders?.length) {
            setInnerOrders(orders);
        }
    }, [orders]);

    const filteredTransactions = useMemo(
        () =>
            searchInput
                ? ordersWithBudgetLoads?.filter(order => {
                      const relevantOrders = order?.subOrders?.length ? order.subOrders : [order];

                      return relevantOrders.some(
                          order =>
                              [
                                  "companyName",
                                  "providerBrand",
                                  "proposalId",
                                  "productName",
                                  "companyContactEmail",
                                  "companyId",
                                  "providerId"
                              ].some(key => String(order[key])?.toLowerCase().includes(searchInput.toLowerCase())) ||
                              searchInput === order.eventId ||
                              order.assignedCSMs?.some(({email}) => email === searchInput.toLowerCase())
                      );
                  })
                : ordersWithBudgetLoads,
        [ordersWithBudgetLoads, searchInput]
    );

    const onExportToCRMExcelClicked = useCallback(async () => {
        setIsExcelLoading(true);
        const transactionsToExport = (filteredTransactions ?? []).filter(
            ({status}) => ![OrderStatus.cancelled, OrderStatus.declined].includes(status)
        );

        const firstTimeOrderingCompaniesIds = Object.entries(companiesStats).reduce(
            (acc, [companyId, {companyFirstOrder}]) => {
                if (transactionsToExport.find(({eventId}) => eventId === companyFirstOrder)) {
                    return [...acc, companyId];
                }

                return acc;
            },
            []
        );

        let firstTimeOrderingCompaniesInfo;

        if (firstTimeOrderingCompaniesIds?.length) {
            const companiesOrders = groupBy(transactionsToExport, "companyId");

            firstTimeOrderingCompaniesInfo = sortBy(
                firstTimeOrderingCompaniesIds.map(companyId => maxBy(companiesOrders[companyId], "createdAt")),
                order => order?.company?.businessNumber
            );
        }

        const filterOrders = transactionsToExport.filter(({serviceId}) => serviceId !== BUDGET_LOAD_TRANSACTION);
        const providers = await getProvidersWithSelectedFields(filterOrders);
        const providersInvoices = await HttpClient.safePost("/admin/api/providerInvoice/byOrderIds", {
            orderIds: filterOrders.map(({eventId}) => eventId)
        });

        try {
            await ExportTableToCRMExcel(
                transactionsToExport,
                true,
                firstTimeOrderingCompaniesInfo,
                [],
                systemUsers,
                providers,
                providersInvoices
            );
        } catch (e) {
            message.error("שגיאה בעת הוצאת נתוני האקסל, נא לפנות לפיתוח");
        }
        setIsExcelLoading(false);
    }, [filteredTransactions, companiesStats, systemUsers]);

    const onExportToExcelCSMSuppliesClicked = useCallback(async () => {
        setIsExcelLoading(true);
        const transactionsToExport = (filteredTransactions ?? []).filter(
            order => ![OrderStatus.cancelled, OrderStatus.declined].includes(order.status) && !order.isBudgetTransaction
        );
        try {
            await ExportTableToCSMSuppliesExcel(transactionsToExport, systemUsers);
        } catch (e) {
            message.error("שגיאה בעת הוצאת נתוני האקסל, נא לפנות לפיתוח");
        }
        setIsExcelLoading(false);
    }, [filteredTransactions, systemUsers]);

    const getProvidersWithSelectedFields = useCallback(
        async (
            filterOrders,
            projection = {
                provider: ["dealerType"],
                product: []
            }
        ) => {
            const providerIdsByOrders = groupBy(filterOrders, order => order.providerId);
            const providerIds = Object.keys(providerIdsByOrders).filter(Boolean);
            return HttpClient.safePost("/admin/api/vendors/byIds", {
                providerIds,
                projection
            });
        },
        []
    );

    const onExportWellBShippingExcelClicked = useCallback(async () => {
        setIsExcelLoading(true);
        const filterOrders = (filteredTransactions ?? []).filter(transaction =>
            isOrderHasShippingProvider(transaction)
        );

        const providersWithSelectedFields = await getProvidersWithSelectedFields(filterOrders, {
            provider: ["dealerType", "maxShippingProvidersDeliveryPrice"]
        });

        try {
            await ExportWellBShippingExcel(filterOrders, providersWithSelectedFields, deliveryProviders);
        } catch (e) {
            message.error("שגיאה בעת הוצאת נתוני האקסל משלוחים עם וולבי, נא לפנות לפיתוח");
        }
        setIsExcelLoading(false);
    }, [filteredTransactions, companiesStats, systemUsers, deliveryProviders]);

    const onExportToProviderExcelClicked = useCallback(async () => {
        setIsExcelLoading(true);
        const filterOrders = (filteredTransactions ?? []).filter(
            ({serviceId}) => serviceId !== BUDGET_LOAD_TRANSACTION
        );
        const providers = await getProvidersWithSelectedFields(filterOrders);
        try {
            await ExportTableToProviderExcel(filterOrders, systemUsers, providers);
        } catch (e) {
            message.error(
                new StringBuilder("שגיאה בעת הוצאת נתוני האקסל לספק, נא לפנות לפיתוח").append(`(${e.message})`)
            );
            console.error(e.message, e.stack);
        }
        setIsExcelLoading(false);
    }, [filteredTransactions, companiesStats, systemUsers]);

    const ordersForStatistics = useMemo(
        () =>
            filteredTransactions?.filter(
                ({status}) => ![OrderStatus.cancelled, OrderStatus.declined, OrderStatus.cancelPending].includes(status)
            ),
        [filteredTransactions]
    );

    const getRowClass = useCallback(({status, dtstart}) => {
        if (status !== OrderStatus.pending) {
            return;
        }

        const diff = moment(dtstart).startOf("day").diff(moment().startOf("day"), "days");

        if (diff <= 0) {
            return "order-warning-urgent";
        }

        if (diff === 1) {
            return "order-warning";
        }
    }, []);

    const innerUpdateOrder = useCallback((newOrderInfo, budgetTransaction = false) => {
        const updateFunction = prev => {
            const tempPrevTransactions = [...prev];

            if (!newOrderInfo?.parentOrderId) {
                const replaceItemIndex = tempPrevTransactions.findIndex(
                    ({eventId}) => newOrderInfo.eventId === eventId
                );
                tempPrevTransactions[replaceItemIndex] = {
                    ...tempPrevTransactions[replaceItemIndex],
                    ...newOrderInfo
                };
                return tempPrevTransactions;
            } else {
                return tempPrevTransactions.map(order => {
                    if (order.eventId === newOrderInfo?.parentOrderId) {
                        order.subOrders = order.subOrders.map(subOrder =>
                            subOrder.eventId === newOrderInfo.eventId ? {...subOrder, ...newOrderInfo} : subOrder
                        );
                    }

                    return order;
                });
            }
        };

        budgetTransaction ? setBudgetLoadTransaction(updateFunction) : setInnerOrders(updateFunction);
    }, []);

    const loadOrderImages = useCallback(async order => {
        const images = await HttpClient.safeGet(`/admin/api/orderImages/order/${order.eventId}`);

        if (images?.error) {
            message.error("שגיאה בטעינת התמונות");
            return;
        }

        setImagesOrder({
            productId: order.productId,
            providerId: order.providerId,
            orderId: order.eventId,
            images
        });
        setOrderImagesModalVisible(true);
    }, []);

    const onChangeCSM = useCallback(
        async (selectedCsm, currentCSMs = [], eventId, isBudgetLoadTransaction, updatedAt, parentOrderId = null) => {
            const csmExists = currentCSMs.some(({userId}) => userId === selectedCsm.userId);
            const assignedCSMs = csmExists
                ? currentCSMs.filter(({userId}) => userId !== selectedCsm.userId)
                : [...currentCSMs, selectedCsm];
            const toUpdate = {
                eventId,
                parentOrderId,
                assignedCSMs
            };

            const {error, status} =
                (await (!isBudgetLoadTransaction
                    ? HttpClient.safePost(`/admin/api/transactions/updateOrder?updatedAt=${updatedAt}`, {
                          order: toUpdate,
                          whoToSendUpdateEmail: "none"
                      })
                    : HttpClient.safePost(`/admin/api/updateBudgetTransactionAssignCSMs/${eventId}`, assignedCSMs))) ??
                {};

            if (status === 409) {
                message.error(error);
                return;
            }

            if (error) {
                message.error(`שגיאה בעת עדכון מנהלת לקוח | מזהה הזמנה ${eventId}`);
                return;
            }

            fetchOrders();
            innerUpdateOrder(toUpdate, isBudgetLoadTransaction);
        },
        [innerUpdateOrder]
    );

    const onChangePartner = useCallback(
        async (selectedPartner, currentPartners = [], eventId, updatedAt, parentOrderId = null) => {
            const partnerExists = currentPartners.some(({userId}) => userId === selectedPartner.userId);

            const assignedPartners = partnerExists
                ? currentPartners.filter(({userId}) => userId !== selectedPartner.userId)
                : [...currentPartners, selectedPartner];

            const toUpdate = {
                eventId,
                parentOrderId,
                assignedPartners
            };

            const {error, status} = await HttpClient.safePost(
                `/admin/api/transactions/updateOrder?updatedAt=${updatedAt}`,
                {
                    order: toUpdate,
                    whoToSendUpdateEmail: "none"
                }
            );

            if (status === 409) {
                message.error(error);
                return;
            }

            if (error) {
                message.error(`שגיאה בעת עדכון מנהל/ת שותפים | מזהה הזמנה ${eventId}`);
                return;
            }

            fetchOrders();
            innerUpdateOrder(toUpdate);
        },
        [innerUpdateOrder]
    );

    const saveOrderImages = useCallback(async (orderId, productId, providerId, images) => {
        const {error} = await HttpClient.safePost("/admin/api/orderImages", {
            images,
            orderId,
            productId,
            providerId
        });
        if (error) {
            message.error(error.message);
            return;
        }

        message.success("התמונות נשמרו בהצלחה");
        setOrderImagesModalVisible(false);
    }, []);

    const chargeCreditCard = useCallback(
        async eventId => {
            const res = await HttpClient.safePost(`/admin/api/orders/chargeCreditCard/${eventId}`);
            if (res.error) {
                message.error(res.error);
                return;
            }

            innerUpdateOrder(res);
            message.success("חיוב בהצלחה");
        },
        [innerUpdateOrder]
    );

    const disabledExportsActions = useMemo(
        () => loadingCompaniesStats || isLoadingOrders || isLoadingSystemCSMs,
        [loadingCompaniesStats, isLoadingOrders, isLoadingSystemCSMs]
    );

    const onCommentSave = useCallback(() => {
        fetchOrders();
    }, [fetchOrders]);

    const onSyncOrder = useCallback(
        async order => {
            const res = await HttpClient.safePost(`/api/events/${order?.eventId}/deliveryProvider/sync`, {});

            if (res?.error) {
                message.error(res.error);
                return;
            }
            message.success("הזמנה סונכרנה מול חברת המשלוחים בהצלחה");
            fetchOrders();
        },
        [fetchOrders]
    );

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                margin: 10,
                marginBottom: 40
            }}>
            <UpdateOrderStatusModal
                setUpdateOrderStatusInfo={setUpdateOrderStatusInfo}
                updateOrderStatusInfo={updateOrderStatusInfo}
                onChangeOrderStatus={onChangeOrderStatus}
            />

            <DeclineOrderModal visible={!!declineOrder} onSave={declineOffer} onClose={() => setDeclineOrder(null)} />

            <ChangeOrderDeliveryShippingModal
                onSave={fetchOrders}
                deliveryProviders={deliveryProviders}
                visible={!!changeDeliveryProviderOrder}
                orderId={changeDeliveryProviderOrder?.eventId}
                onClose={() => setChangeDeliveryProviderOrder(null)}
            />

            <ConfigProvider direction="rtl">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: 2000,
                        width: "100%"
                    }}>
                    <PageTitleHeader showBack={false}>עסקאות</PageTitleHeader>
                    <div className="admin-orders-top-bar">
                        <div className="admin-orders-top-bar-right-side">
                            <TableDateSearchProvider>
                                <TableDateSearch
                                    date={searchDate}
                                    onDatePicked={setSearchDate}
                                    onSearch={setSearchInput}
                                    todayButtonText="העסקאות של היום"
                                    disableFuture={searchByCreated}
                                    allowClear={false}
                                    defaultValue={[moment().startOf("month"), moment().endOf("month")]}
                                />
                            </TableDateSearchProvider>
                            <div
                                className={`admin-orders-date-search-switch-container ${
                                    !searchByCreated ? "active" : ""
                                }`}>
                                <span className="admin-orders-date-search-switch-label">
                                    סינון לפי תאריך {searchByCreated ? "הזמנה" : "אספקה"}
                                </span>
                                <Switch
                                    className="admin-orders-date-search-switch"
                                    checked={!searchByCreated}
                                    onClick={() => setSearchByCreated(prevState => !prevState)}
                                />
                            </div>
                        </div>
                        <div className="admin-orders-top-bar-left-side">
                            <OrdersStatisticsLabel
                                orders={ordersForStatistics}
                                startTime={searchDate?.[0].valueOf()}
                                endTime={searchDate?.[1].valueOf()}
                                companiesStats={companiesStats}
                            />

                            <Dropdown
                                disabled={disabledExportsActions}
                                menu={{
                                    items: [
                                        {
                                            key: "אקסל חשבוניות לקוח",
                                            disabled: disabledExportsActions,
                                            label: <div onClick={onExportToCRMExcelClicked}>אקסל חשבוניות לקוח</div>
                                        },
                                        {
                                            key: "אקסל שילוח עם וולבי",
                                            disabled: disabledExportsActions,
                                            label: (
                                                <div onClick={onExportWellBShippingExcelClicked}>
                                                    אקסל שילוח עם וולבי
                                                </div>
                                            )
                                        },
                                        {
                                            key: "אקסל אספקות שותפים",
                                            disabled: disabledExportsActions,
                                            label: (
                                                <>
                                                    <div onClick={onExportToProviderExcelClicked}>
                                                        <div>אקסל אספקות שותפים</div>
                                                    </div>
                                                </>
                                            )
                                        },
                                        {
                                            key: "אקסל אספקות מנהלות לקוח",
                                            disabled: disabledExportsActions,
                                            label: (
                                                <div onClick={onExportToExcelCSMSuppliesClicked}>
                                                    אקסל אספקות מנהלות לקוח
                                                </div>
                                            )
                                        }
                                    ]
                                }}>
                                <SquareButton
                                    disabled={loadingCompaniesStats || isLoadingOrders || isLoadingSystemCSMs}
                                    loading={isExcelLoading}
                                    className="admin-orders-export-button">
                                    ייצא לאקסל
                                </SquareButton>
                            </Dropdown>
                        </div>
                    </div>
                    <RoundedTable
                        className="admin-orders-table"
                        rowKey="eventId"
                        pagination={{
                            position: ["bottomCenter"],
                            showSizeChanger: false,
                            defaultPageSize: ORDERS_PER_PAGE,
                            showTotal: total => <span>סה״כ {total}</span>
                        }}
                        scroll={{x: 1400}}
                        loading={isLoadingOrders || isLoadingBudgetTransactions}
                        dataSource={filteredTransactions}
                        rowClassName={getRowClass}
                        expandable={{
                            expandedRowRender: record => (
                                <div>
                                    <RoundedTable
                                        className="admin-orders-table"
                                        rowKey="eventId"
                                        pagination={false}
                                        scroll={{x: 1400}}
                                        // loading={isLoadingOrders || isLoadingBudgetTransactions}
                                        dataSource={record.subOrders}
                                        // rowClassName={getRowClass}
                                        columns={columns(
                                            me,
                                            services,
                                            onEditOrder,
                                            (order, status, toRemove) =>
                                                setUpdateOrderStatusInfo({
                                                    event: order,
                                                    status,
                                                    toRemove
                                                }),
                                            innerUpdateOrder,
                                            systemUsers,
                                            onChangeCSM,
                                            onChangePartner,
                                            order => setHistoryOrder(order),
                                            order => loadOrderImages(order),
                                            true,
                                            companiesStats,
                                            chargeCreditCard,
                                            onCommentSave,
                                            deliveryProviders,
                                            null,
                                            onSyncOrder,
                                            order => setChangeDeliveryProviderOrder(order)
                                        )}
                                    />
                                </div>
                            ),
                            rowExpandable: record => !!record?.subOrders?.length
                        }}
                        columns={columns(
                            me,
                            services,
                            onEditOrder,
                            (order, status, toRemove) =>
                                setUpdateOrderStatusInfo({
                                    event: order,
                                    status,
                                    toRemove
                                }),
                            innerUpdateOrder,
                            systemUsers,
                            onChangeCSM,
                            onChangePartner,
                            order => setHistoryOrder(order),
                            order => loadOrderImages(order),
                            true,
                            companiesStats,
                            chargeCreditCard,
                            onCommentSave,
                            deliveryProviders,
                            null,
                            onSyncOrder,
                            order => setChangeDeliveryProviderOrder(order)
                        )}
                    />
                </div>

                <OrderChangesHistoryModal
                    visible={!!historyOrder}
                    order={historyOrder}
                    onClose={() => setHistoryOrder(null)}
                />
                <OrderImagesModal
                    visible={orderImagesModalVisible}
                    orderImages={imagesOrder?.images ?? []}
                    onClose={() => {
                        setOrderImagesModalVisible(false);
                        setImagesOrder(null);
                    }}
                    onSave={images =>
                        saveOrderImages(imagesOrder?.orderId, imagesOrder?.productId, imagesOrder?.providerId, images)
                    }
                />
                <GenericModal
                    title="שימו לב!"
                    className="admin-coupons-are-you-sure-modal"
                    text={[eventActionModalText]}
                    image={Images.areYouSure}
                    closeable={true}
                    visible={!!eventActionModal}
                    onClose={() => setEventActionModal(null)}
                    options={[
                        {
                            id: "refresh",
                            onClick: () => {
                                fetchOrders();
                                setEventActionModal(null);
                                setEventActionModalText(null);
                            },
                            text: "רענן"
                        }
                    ]}
                />
            </ConfigProvider>
        </div>
    );
};
