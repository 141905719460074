import React, {useContext, useEffect, useMemo, useState} from "react";
import {uniq} from "lodash";
import "./provider-pinters.css";
import {ConfigProvider, Skeleton} from "antd";
import {useRequest} from "../../../utils/hooks.jsx";
import EventsGallery from "../../../company/EventsGallary/EventsGallery.jsx";
import {useHistory} from "react-router";
import {AppContext} from "../../../AppContext.jsx";

export const ProviderPinterest = ({provider, log}) => {
    const {likedImagesFromServer} = useContext(AppContext);
    const history = useHistory();
    const [imagesFromServer = []] = useRequest(
        `/api/orderImages/byProviderIds`,
        "POST",
        {providerIds: [provider?.providerId]},
        [provider?.providerId],
        !!provider?.providerId
    );
    const [allImages, setAllImages] = useState([]);
    const [likedImages, setLikeImages] = useState([]);
    const productIds = useMemo(() => uniq(allImages?.map(({productId}) => productId)), [allImages]);
    const [products] = useRequest(
        "/api/products/byIds",
        "POST",
        {
            productIds,
            pickedFields: ["brandName", "productName", "services", "provider.businessName"],
            includeNotApprove: true
        },
        [productIds.length],
        !!allImages?.length
    );

    useEffect(() => {
        if (imagesFromServer?.length) {
            setAllImages(prev => [...prev, ...imagesFromServer]);
        }
    }, [imagesFromServer]);

    useEffect(() => {
        if (likedImagesFromServer) {
            setLikeImages(likedImagesFromServer);
        }
    }, [likedImagesFromServer]);

    return (
        <div className="provider-pinterest">
            <ConfigProvider direction="rtl">
                {!!allImages.length && (
                    <>
                        <div className="provider-events-gallery-page-title">תמונות השראה מלקוחות שהזמינו אצל השותף</div>
                        <EventsGallery
                            allImages={allImages}
                            likedImages={likedImages}
                            setLikeImages={setLikeImages}
                            history={history}
                            products={products}
                            log={log}
                            onLastImageReached={() => {}}
                        />
                    </>
                )}
            </ConfigProvider>
        </div>
    );
};

export const ProviderPinterestSkeleton = () => {
    return (
        <div className="skeleton-pinterest-container">
            {[...Array(6)].map(index => {
                return (
                    <div key={index}>
                        <Skeleton.Button className="skeleton-pinterest-item" key={index} active={true} />
                    </div>
                );
            })}
        </div>
    );
};
