import {parse as qsParse} from "query-string";
import moment from "moment-timezone";
import {HttpError} from "./HttpError";
import {EventBus} from "../bus/EventBus";
import {authExcluded} from "../AppUtils.js";

export class HttpClient {
    static async get(path, throwOnUnauthorized) {
        return await this.sendRequest("GET", path, false, throwOnUnauthorized);
    }

    static async safeGet(path) {
        return await this.safeSendRequest("GET", path, false);
    }

    static async put(path, data, throwOnUnauthorized) {
        return await this.sendRequest("PUT", path, data, throwOnUnauthorized);
    }

    static async safePut(path, data) {
        return await this.safeSendRequest("PUT", path, data);
    }

    static async post(path, data, throwOnUnauthorized) {
        return await this.sendRequest("POST", path, data, throwOnUnauthorized);
    }

    static async safePost(path, data) {
        return await this.safeSendRequest("POST", path, data);
    }

    static async delete(path, data, throwOnUnauthorized) {
        return await this.sendRequest("DELETE", path, data, throwOnUnauthorized);
    }

    static async safeDelete(path) {
        return await this.safeSendRequest("DELETE", path);
    }

    static async safeSendRequest(method, path, data, options = {}) {
        try {
            return await this.sendRequest(method, path, data, false, options, false);
        } catch (error) {
            return {error};
        }
    }

    static async sendRequest(method, path, data, throwOnUnauthorized, options, throwOnBadStatus = true) {
        const opts = {
            method,
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json"
            },
            ...options
        };

        if (data) {
            opts.body = JSON.stringify(data);
        }

        const tzid = moment.tz.guess();
        const {branchId} = qsParse(window.location.search);
        path = path.indexOf("?") >= 0 ? `${path}&tzid=${tzid}` : `${path}?tzid=${tzid}`;

        if (branchId && branchId !== "Global" && branchId !== "All") {
            path = `${path}&branchId=${branchId}`;
        }

        const response = await fetch(path, opts);
        if (!response.ok) {
            if (response.status === 401) {
                if (throwOnUnauthorized) {
                    throw new HttpError("unauthorized", 401);
                } else {
                    if (!authExcluded(window.location.pathname))
                        window.location.replace(`/login?returnTo=${encodeURIComponent(window.location.href)}`);
                    return;
                }
            } else if (response.status === 403) {
                if (throwOnUnauthorized) {
                    throw new HttpError("forbidden", 403);
                } else {
                    EventBus.trigger("server-error", {
                        hideClose: true,
                        content: {
                            title: "Ohhh... Man...",
                            subTitle: "It looks like you do not belong here!",
                            description: ["You do not have permissions to view this page."],
                            hideSteps: true
                        },
                        cta: {
                            hide: true
                        }
                    });
                    return;
                }
            }

            let error = "Oopss... please try again later.";
            let extraInfo = null;
            try {
                const result = await response.json();
                error = result.error;
                extraInfo = result.extraInfo;
            } catch (ignore) {}
            if (throwOnBadStatus) {
                throw new HttpError(error, response.status, extraInfo);
            }

            return {error, status: response.status, extraInfo};
        }

        return await response.json();
    }
}
