import React, {useCallback, useMemo, useState} from "react";
import {groupBy, keyBy, uniq} from "lodash";
import {ConfigProvider, Tabs} from "antd";
import {RenderSubProductsList} from "../RenderSubProductsList.jsx";
import {allOptionCategory} from "../../MarketplaceProviderPage/providerProductCart/consts.js";
import "./provider-modular-product-list.css";
import {notProductCategoryName} from "./consts.js";

export const ProviderModularProductList = ({subProducts, onPropertyChange, showProviderSubProductsImages}) => {
    const [activeProductCategory, setActiveProductCategory] = useState(encodeURIComponent(allOptionCategory));

    const subProductsByCategoryName = useMemo(() => {
        return groupBy(subProducts, product => product.subProductCategory ?? notProductCategoryName);
    }, [subProducts]);

    const subProductCategories = useMemo(() => {
        const subProductsCategoriesDetails = uniq(
            (subProducts ?? []).map(product => product.subProductCategory ?? notProductCategoryName)
        );
        return [allOptionCategory, ...subProductsCategoriesDetails].map(category => ({
            title: category,
            key: encodeURIComponent(category)
        }));
    }, [subProducts]);

    const selectedSubProductsCategoryList = useMemo(() => {
        const subProductCategoryById = keyBy(subProductCategories, category => category.key);
        const selectedSubProductCategory = subProductCategoryById?.[activeProductCategory]?.title;

        const subProductsBySelectedCategory =
            selectedSubProductCategory === allOptionCategory
                ? subProductsByCategoryName
                : {[selectedSubProductCategory]: subProductsByCategoryName[selectedSubProductCategory]};

        return Object.entries(subProductsBySelectedCategory).map(([subCategoryName, subProducts]) => ({
            subCategoryName,
            subProducts
        }));
    }, [subProductCategories, activeProductCategory, subProductsByCategoryName]);

    const onSelectedProductCategory = useCallback(activeKey => {
        setActiveProductCategory(activeKey);
    }, []);

    return (
        <>
            <ConfigProvider direction="rtl">
                <div className="provider-product-container">
                    <Tabs
                        className="provider-category-products-container"
                        onChange={onSelectedProductCategory}
                        items={subProductCategories.map(({key, title}) => ({label: title, key: key}))}
                        moreIcon={<span>אפשרויות נוספות</span>}
                    />
                    <div className="sub-products-container">
                        {selectedSubProductsCategoryList.map(({subCategoryName, subProducts}, index) => {
                            return (
                                <div className="product-category-list-container" key={index}>
                                    <div className="category-product-title">{subCategoryName}</div>
                                    <div className="product-sub-products-container">
                                        <RenderSubProductsList
                                            subProducts={subProducts ?? []}
                                            showProviderSubProductsImages={showProviderSubProductsImages}
                                            onChangeProperty={onPropertyChange}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </ConfigProvider>
        </>
    );
};
