export const scrollToElement = selector => {
    setTimeout(() => {
        const elem = document.querySelector(selector);
        if (elem) {
            elem.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "start"
            });
        }
    }, 100);
};

export const isCenterInPreviewMode = () => {
    const parts = window.location.pathname.split("/");
    return parts[parts.length - 1] === "preview";
};
