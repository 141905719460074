import {useContext, useState} from "react";
import {Button, Popconfirm, Tooltip} from "antd";
import {AppContext} from "../../AppContext";
import phoneFormat from "phone";
import {EventAcceptedIcon, PencilIcon, PhoneIcon, TrashIcon} from "../../icons";
import {
    WhatsAppOutlined,
    StarFilled,
    AppstoreFilled,
    MailTwoTone,
    MailOutlined,
    InfoCircleOutlined
} from "@ant-design/icons";
import {ContactPhone} from "../../support/ContactPhone";
import React from "react";
import {withRouter} from "react-router";
import moment from "moment-timezone";
import {isHostedByProvider} from "./EventTransformer";
import {dateFormat} from "../../utils/DateFormat";
import {MailFilled, LinkOutlined} from "@ant-design/icons";
import isMobileDevice from "is-mobile";
import {Link} from "react-router-dom";
import {copyPathToClipboard} from "../../utils/copyToClipboard";
import {GoogleAnalytics} from "../../GoogleAnalytics";
import {Images} from "../../images/Images";

const getEmployeeActions = (onReview, event, onRegistration) => {
    const employeeActions = [
        {
            title: "Registrations",
            button: (
                <EventInfoActionButton
                    textVisibleOnDesktopOnly={true}
                    key="registrations"
                    icon={<EventAcceptedIcon style={{fill: "var(--secondary-color)", width: 20, height: 20}} />}
                    onClick={() => onRegistration(event)}></EventInfoActionButton>
            )
        }
    ];
    if (isHostedByProvider(event)) {
        if (onReview) {
            employeeActions.push({
                title: "Review Provider",
                button: (
                    <EventInfoActionButton
                        textVisibleOnDesktopOnly={true}
                        key="review-provider"
                        icon={<StarFilled style={{color: "var(--secondary-color)", fontSize: 20, marginTop: 2}} />}
                        onClick={() => onReview()}></EventInfoActionButton>
                )
            });
        }
    }
    return employeeActions;
};

const getCompanyActions = (
    editableEvent,
    onEdit,
    onDelete,
    onSendEvent,
    onReview,
    event,
    dateMom,
    phoneNumber,
    me,
    company,
    onRegistration,
    onCalendarInvite,
    openActivityDetails
) => {
    const companyActions = [
        {
            title: "Registrations",
            button: (
                <EventInfoActionButton
                    textVisibleOnDesktopOnly={true}
                    key="registrations"
                    icon={<EventAcceptedIcon style={{fill: "var(--secondary-color)", width: 20, height: 20}} />}
                    onClick={() => onRegistration(event)}></EventInfoActionButton>
            )
        }
    ];

    if (editableEvent) {
        if (onEdit) {
            companyActions.push({
                title: "Edit Event",
                button: (
                    <EventInfoActionButton
                        textVisibleOnDesktopOnly={true}
                        key="edit-event"
                        onClick={onEdit}
                        icon={
                            <PencilIcon style={{fill: "var(--secondary-color)", width: 20, height: 20}} />
                        }></EventInfoActionButton>
                )
            });
        }
        if (onDelete) {
            companyActions.push({
                title: "Delete Event",
                button: (
                    <EventInfoActionButton
                        textVisibleOnDesktopOnly={true}
                        key="delete-event"
                        onClick={onDelete}
                        icon={
                            <TrashIcon style={{fill: "var(--secondary-color)", width: 20, height: 20}} />
                        }></EventInfoActionButton>
                )
            });
        }
    }

    if (isHostedByProvider(event)) {
        if (onReview) {
            companyActions.push({
                title: "Review Provider",
                button: (
                    <EventInfoActionButton
                        textVisibleOnDesktopOnly={true}
                        key="review-provider"
                        icon={<StarFilled style={{color: "var(--secondary-color)", fontSize: 20, marginTop: 2}} />}
                        onClick={() => onReview()}></EventInfoActionButton>
                )
            });
        }

        companyActions.push({
            title: "Chat With Provider",
            button: (
                <EventInfoActionButton
                    textVisibleOnDesktopOnly={true}
                    key="chat-with-provider"
                    icon={<WhatsAppOutlined style={{color: "var(--secondary-color)", fontSize: 20, marginTop: 2}} />}
                    onClick={() => phoneNumber && ContactPhone.openWhatsApp(phoneNumber)}></EventInfoActionButton>
            )
        });
    }

    if (onSendEvent) {
        companyActions.push({
            title: "Email Event",
            button: (
                <EventInfoActionButton
                    textVisibleOnDesktopOnly={true}
                    key="email-event"
                    icon={<MailOutlined style={{color: "var(--secondary-color)", fontSize: 20, marginTop: 2}} />}
                    onClick={onSendEvent}></EventInfoActionButton>
            )
        });
    }
    companyActions.push({
        title: "Sync to employees calendars",
        button: (
            <EventInfoActionButton
                textVisibleOnDesktopOnly={true}
                key="invite-to-event"
                icon={
                    <img
                        src={Images.calendarInvite}
                        height={20}
                        width={20}
                        style={{marginTop: "2px"}}
                        alt="calendar_invite"
                    />
                }
                onClick={onCalendarInvite}></EventInfoActionButton>
        )
    });

    companyActions.push({
        title: "Link To Event",
        button: (
            <EventInfoActionButton
                textVisibleOnDesktopOnly={true}
                key="link-to-event"
                icon={<LinkOutlined style={{color: "var(--secondary-color)", fontSize: 20, marginTop: 2}} />}
                onClick={() =>
                    copyPathToClipboard(
                        `/dashboard/schedule?eventId=${event.eventId}&date=${dateMom.format("YYYY-MM-DD")}`
                    )
                }></EventInfoActionButton>
        )
    });

    if (openActivityDetails && event.v2) {
        companyActions.push({
            title: "See activity details",
            button: (
                <EventInfoActionButton
                    textVisibleOnDesktopOnly={true}
                    key="link-to-details"
                    icon={<InfoCircleOutlined style={{color: "var(--secondary-color)", fontSize: 20, marginTop: 2}} />}
                    onClick={openActivityDetails}></EventInfoActionButton>
            )
        });
    }

    return companyActions;
};

export const EventInfoActionButton = props => {
    const [hover, setHover] = useState(false);

    const {buttonStyle, textStyle} = props;

    return (
        <Button
            className="wb-event-info-action"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
                height: 30,
                borderRadius: 25,
                backgroundColor: hover ? "#E7EAED" : "transparent",
                marginLeft: 4,
                border: "none",
                display: "flex",
                alignItems: "center",
                boxShadow: "none",
                padding: "4px 8px",
                ...buttonStyle
            }}
            {...props}>
            <span
                className={props.textVisibleOnDesktopOnly ? "wb-block-desktop-only" : null}
                style={{
                    marginLeft: props.icon ? 10 : 0,
                    fontSize: 16,
                    color: "var(--secondary-color)",
                    ...textStyle
                }}>
                {props.children}
            </span>
        </Button>
    );
};

const canControlThisEvent = (me, event) => {
    if (me.type === "company" && !me.isCompanyMaster) {
        return (
            Array.isArray(event.branches) &&
            event.branches.length > 0 &&
            event.branches.some(b => {
                return me.branch && b.branchId === me.branch.branchId;
            })
        );
    }

    return true;
};

const isEventByV2Provider = event => {
    return event && event.v2;
};

const EventInfoFooterV2 = ({
    history,
    dateMom,
    event,
    providerInfo,
    companyInfo,
    onEdit,
    onDelete,
    deleting,
    onReview,
    onNewCustomEvent,
    onDismiss,
    onSendEvent,
    onBookingModalOpen,
    onCalendarInvite,
    openActivityDetails
}) => {
    const {me, company} = useContext(AppContext);
    let editableEvent = event.appointmentBased
        ? moment(dateFormat(dateMom.toDate()), "YYYY-M-D").isSameOrAfter(moment(dateFormat(new Date()), "YYYY-M-D"))
        : moment(dateMom)
              .add(event.duration || 0, "minutes")
              .isSameOrAfter(moment(Date.now()));

    editableEvent = editableEvent && canControlThisEvent(me, event) && !isEventByV2Provider(event);

    let actions = [];

    let phoneNumber = null;

    const toPhoneNumber = (phone, countryCode) => {
        const [formattedPhoneNumber] = phoneFormat(phone, countryCode);
        return (formattedPhoneNumber || phone).replace("+", "");
    };

    if (me.type === "company") {
        phoneNumber =
            providerInfo && providerInfo.phone && providerInfo.countryCode
                ? toPhoneNumber(providerInfo.phone, providerInfo.countryCode)
                : null;
    } else if (me.type === "provider") {
        phoneNumber =
            companyInfo && companyInfo.phone && companyInfo.countryCode
                ? toPhoneNumber(companyInfo.phone, companyInfo.countryCode)
                : null;
    }

    if (event.sessionType === "special") {
        actions = actions.concat([
            <EventInfoActionButton
                textVisibleOnDesktopOnly={true}
                key="create-event"
                buttonStyle={{marginLeft: 0}}
                icon={<EventAcceptedIcon style={{fill: "var(--secondary-color)", width: 20, height: 20}} />}
                onClick={() => {
                    GoogleAnalytics.event("Special Event", "Internal Event", me.companyId);
                    onNewCustomEvent();
                }}>
                Create event
            </EventInfoActionButton>,
            <EventInfoActionButton
                textVisibleOnDesktopOnly={true}
                key="library"
                buttonStyle={{marginLeft: 20}}
                icon={<AppstoreFilled style={{color: "var(--secondary-color)", fontSize: 20, paddingTop: 3}} />}
                onClick={() => {
                    GoogleAnalytics.event("Special Event", "Library", me.companyId);
                    history.push(`/dashboard/services`);
                }}>
                Choose from library
            </EventInfoActionButton>,
            <EventInfoActionButton
                textVisibleOnDesktopOnly={true}
                key="dismiss-event"
                buttonStyle={{marginLeft: 0}}
                icon={<TrashIcon style={{fill: "var(--secondary-color)", width: 20, height: 20}} />}
                onClick={() => {
                    GoogleAnalytics.event("Special Event", "Dismiss Forever", me.companyId);
                    onDismiss(event);
                }}>
                Dismiss forever
            </EventInfoActionButton>
        ]);
    } else {
        switch (me.type) {
            case "company":
                const companyActions = getCompanyActions(
                    editableEvent,
                    onEdit,
                    onDelete,
                    onSendEvent,
                    onReview,
                    event,
                    dateMom,
                    phoneNumber,
                    me,
                    company,
                    onBookingModalOpen,
                    onCalendarInvite,
                    openActivityDetails
                );
                actions = companyActions.map(action => {
                    return <Tooltip title={action.title}>{action.button}</Tooltip>;
                });
                break;
            case "provider":
                if (editableEvent) {
                    actions.push(
                        <EventInfoActionButton
                            key="edit-event"
                            onClick={onEdit}
                            icon={<PencilIcon style={{fill: "var(--secondary-color)", width: 20, height: 20}} />}>
                            Edit
                        </EventInfoActionButton>
                    );
                }

                actions.push(
                    <EventInfoActionButton
                        key="chat-with-provider"
                        icon={
                            <WhatsAppOutlined style={{color: "var(--secondary-color)", fontSize: 20, marginTop: 2}} />
                        }
                        onClick={() => phoneNumber && ContactPhone.openWhatsApp(phoneNumber)}>
                        Chat
                    </EventInfoActionButton>
                );
                break;
            case "employee":
                if (event.type === "appointment") {
                    actions = actions.concat([
                        <Link
                            to={`/center/profile/bookings/${event.eventId}/appointments/${event.appointmentId}/${event.date}/${event.date}/edit`}>
                            <EventInfoActionButton
                                textVisibleOnDesktopOnly={true}
                                key="edit-appointment"
                                icon={<PencilIcon style={{fill: "var(--secondary-color)", width: 20, height: 20}} />}>
                                Edit
                            </EventInfoActionButton>
                        </Link>,
                        <Popconfirm
                            icon={null}
                            style={{borderRadius: 10}}
                            placement="topRight"
                            title={
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        width: "100%"
                                    }}>
                                    <span style={{fontSize: 16, color: "#333333", fontWeight: 700}}>
                                        Delete appointment?
                                    </span>
                                    <span style={{fontSize: 14, color: "#F06060", fontWeight: 400}}>
                                        Deleting an appointment is irreversible.
                                    </span>
                                </div>
                            }
                            onConfirm={() => onDelete()}
                            okText="Delete"
                            cancelText="No"
                            cancelButtonProps={{style: {height: 40, width: 80, borderRadius: 20}}}
                            okButtonProps={{
                                style: {
                                    height: 40,
                                    width: 80,
                                    borderRadius: 20,
                                    backgroundColor: "#595AD4",
                                    border: "none"
                                },
                                loading: deleting
                            }}>
                            <EventInfoActionButton
                                loading={deleting}
                                textVisibleOnDesktopOnly={true}
                                key="delete-appointment"
                                icon={<TrashIcon style={{fill: "var(--secondary-color)", width: 20, height: 20}} />}>
                                Delete
                            </EventInfoActionButton>
                        </Popconfirm>
                    ]);

                    if (event.providerEmail) {
                        actions.push(
                            <a target="_blank" href={`mailto:${event.providerEmail}`}>
                                <EventInfoActionButton
                                    textVisibleOnDesktopOnly={true}
                                    key="email-provider"
                                    icon={
                                        <MailFilled
                                            style={{color: "var(--secondary-color)", fontSize: 20, paddingTop: 4}}
                                        />
                                    }>
                                    Email
                                </EventInfoActionButton>
                            </a>
                        );
                    }

                    if (event.providerPhone) {
                        actions.push(
                            <a target="_blank" href={isMobileDevice() ? null : `tel:${event.providerPhone}`}>
                                <EventInfoActionButton
                                    textVisibleOnDesktopOnly={true}
                                    key="phone-provider"
                                    onClick={isMobileDevice() ? ContactPhone.openWhatsApp(event.providerPhone) : null}
                                    icon={
                                        <PhoneIcon style={{fill: "var(--secondary-color)", width: 20, height: 20}} />
                                    }>
                                    Phone
                                </EventInfoActionButton>
                            </a>
                        );
                    }
                } else {
                    const employeeActions = getEmployeeActions(onReview, event, onBookingModalOpen);
                    actions = employeeActions.map(action => {
                        return <Tooltip title={action.title}>{action.button}</Tooltip>;
                    });
                }
                break;
        }
    }

    return actions.length > 0 ? (
        <div
            className={"wb-modal-actions" + (event.sessionType === "special" ? " force-center" : "")}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: me.type === "employee" || event.sessionType === "special" ? "center" : "flex-start",
                height: 80,
                width: "100%",
                borderTop: "1px solid #DADEE4",
                paddingLeft: 20,
                paddingRight: 20
            }}>
            {actions}
        </div>
    ) : null;
};

export default withRouter(EventInfoFooterV2);
