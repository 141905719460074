import React from "react";

export const SaveCancelButtons = ({onSave, onClose}) => (
    <div className="admin-order-modal-save-cancel-buttons">
        <span className="admin-order-modal-cancel-button" onClick={onClose}>
            ביטול עריכה
        </span>
        <span className="admin-order-modal-save-button" onClick={onSave}>
            שליחת שינויים
        </span>
    </div>
);
