import React from "react";
import {hasBranches} from "../company/CompanyBranchSelect";
import {CompanyContentDefaults} from "../company/content/CompanyContentDefaults";
import {MainServices, UserType} from "../consts.js";

export const isBranchesPage = location => location.pathname.indexOf("/center/branches") >= 0;

export const shouldRedirectToBranchesSelection = (location, branchId) =>
    location.pathname === "/center" || (!branchId && !isBranchesPage(location));

export const hasEXCenterFeatureOpen = (me, company) => {
    return me && company && hasBranches(me, company);
};

export const isCompanySubAdmin = user => user.type === UserType.Company && user.isCompanySubAdmin;

export const isAllowedForCategory = (user, serviceId) =>
    user?.isCompanyMaster || user?.allowedServices?.includes(serviceId);

export const isAllowedAndExistingCategory = (user, serviceId, services) =>
    services?.some(service => service.serviceId === serviceId) && isAllowedForCategory(user, serviceId);

export const getAllowedCategories = (user, services) => {
    if (user?.isCompanyMaster) {
        return services?.map(service => service.serviceId) || [];
    } else {
        return user?.allowedServices || [];
    }
};

export const fillPickerPhotos = (designInfo, theme) => {
    if (designInfo.pickerPhotos) {
        return Object.keys(CompanyContentDefaults.pickerPhotos[theme.name]).reduce((photos, sectionId) => {
            if (designInfo.pickerPhotos[sectionId]) {
                photos[sectionId] = designInfo.pickerPhotos[sectionId];
            } else {
                photos[sectionId] = CompanyContentDefaults.pickerPhotos[theme.name][sectionId];
            }

            return photos;
        }, {});
    } else {
        return CompanyContentDefaults.pickerPhotos[theme.name];
    }
};

export const getCurrentScreenSize = () => {
    if (window.innerWidth >= 1600) {
        return ScreenSize.DesktopHuge;
    } else if (window.innerWidth >= 1400) {
        return ScreenSize.DesktopBig;
    } else if (window.innerWidth >= 1200) {
        return ScreenSize.DesktopMedium;
    } else if (window.innerWidth >= 800) {
        return ScreenSize.DesktopSmall;
    } else if (window.innerWidth >= 600) {
        return ScreenSize.Tablet;
    } else {
        return ScreenSize.Mobile;
    }
};

export const isScreenSizeOfTypes = (types, screenSize) => {
    return types.some(type => screenSize.indexOf(type) >= 0);
};

export const ScreenType = {
    Mobile: "mobile",
    Tablet: "tablet",
    Desktop: "desktop"
};

export const ScreenSize = {
    Mobile: `${ScreenType.Mobile}-screen`,
    Tablet: `${ScreenType.Tablet}-screen`,
    DesktopSmall: `${ScreenType.Desktop}-small-screen`,
    DesktopMedium: `${ScreenType.Desktop}-medium-screen`,
    DesktopBig: `${ScreenType.Desktop}-big-screen`,
    DesktopHuge: `${ScreenType.Desktop}-huge-screen`
};
