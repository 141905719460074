import {toProfessionName} from "../../data/professions";
import {cloneDeep} from "lodash";

export const isHostedByProvider = event => {
    return event && event.eventId && (!event.customSession || event.sessionType === "provider");
};

const getProviderText = event => {
    let text = "";
    if (event.sessionType === "company") {
        text = `by ${event.providerFirstName}`;
    } else if (event.v2) {
        text = event.brandName || event?.engBrandName || event.providerFirstName;
    } else {
        text = `with ${event.providerFirstName}${event.providerLastName ? ` ${event.providerLastName}` : ""}${
            event.appointmentBased ? " (for session details click here)" : ""
        }`;
    }
    return text;
};

export const transformEvent = (me, event, providerInfo) => {
    return {
        ...event,
        product: {
            name: {
                text: event.appointmentBased ? event.serviceName : event.productName || event.sessionName,
                selected: true
            },
            description: {text: event.productDescription || null, selected: !!event.productDescription},
            coverPhoto: {imageUrl: event.productCoverPhoto || null, selected: !!event.productCoverPhoto}
        },
        provider: {
            name: {
                text: getProviderText(event),
                selected: true
            },
            profession: {
                text: isHostedByProvider() ? toProfessionName(providerInfo.profession) : "",
                selected: isHostedByProvider()
            }
        },
        extra: {
            notes: {
                selected: !!event.notes,
                text: event.notes
            }
        },
        callToAction: {
            name: {
                text: event.appointmentBased ? "Book now" : "Register now",
                selected: true
            }
        }
    };
};

export const transformBackEvent = event => {
    event = cloneDeep(event);
    delete event.product;
    delete event.provider;
    delete event.extra;
    delete event.callToAction;
    delete event.startTime;
    delete event.startDate;

    return {
        ...event
    };
};
