import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {merge, set, isFinite, intersection, omit, isEmpty, cloneDeep} from "lodash";
import {Form, Modal, Checkbox, message} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import {useLocation} from "react-router-dom";
import classNames from "classnames";
import {NOOP} from "../../utils/NOOP";
import {FilterErrorTypes, FilterOptionIdToName, FilterOptions, kosherTypes} from "./consts";
import {GoogleMapMainAreaKey, GoogleMapSubAreaApiKeys, MainServices, ServiceIdToName} from "../../consts.js";
import {AutoCompleteAddressFormInput} from "../../utils/AutoCompleteAddress";
import {FormInputV2} from "../../components/form";
import {SquareButton} from "../../components";
import {HttpClient} from "../../http/HttpClient";
import {getAllowedCategories, isAllowedForCategory} from "../../center/EXCenterHelper";
import {AppContext} from "../../AppContext";
import {isObjectWithEmptyFields} from "../../utils/objectUtils";
import "./product-filters-modal.css";
import {parseFloatNumber} from "../../utils/NumberUtils";

const ProductsFiltersModal = ({
    filters,
    onClose,
    setFilters = NOOP,
    visible,
    onSave,
    onCancel,
    onFiltersChange,
    foundProductsCount
}) => {
    const [form] = Form.useForm();
    const {me, services} = useContext(AppContext);
    const {pathname} = useLocation();
    const [innerFilters, setInnerFilters] = useState({});
    const [isSearchEmpty, setIsSearchEmpty] = useState(true);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (filters) {
            form.resetFields();
            form.setFieldsValue({filters});
            setIsSearchEmpty(!filters.deliveryAddress?.[0]?.displayName);
            setInnerFilters(filters);
        }
    }, [filters, visible, form, setFilters]);

    useEffect(() => {
        if (visible && pathname) {
            const pattern = /^\/dashboard\/services\/(\w+)\/providersV2$/;
            const match = pattern.exec(pathname);
            const categoryId = match?.[1];

            if (categoryId) {
                setInnerFilters(prevState => ({
                    ...prevState,
                    categories: [categoryId]
                }));
            }
        }
    }, [visible, pathname]);

    const allowedServices = useMemo(() => getAllowedCategories(me, services), [me, services]);

    useEffect(() => {
        onFiltersChange(innerFilters);
    }, [innerFilters]);

    const onFormValueChange = useCallback((field, val) => {
        setInnerFilters(prevValues => set({...prevValues}, field, val));
    }, []);

    const onServiceClicked = useCallback(categoryId => {
        setInnerFilters(prevState => {
            const categories = prevState?.categories ?? [];
            const newCategories = categories.includes(categoryId) ? [] : [categoryId];
            return {...prevState, categories: newCategories};
        });
    }, []);

    const onParticipantsAmountChange = useCallback(event => {
        const participantsAmount = !isNaN(parseInt(event.currentTarget.value))
            ? parseInt(event.currentTarget.value)
            : null;
        onFormValueChange("budget.participantsAmount", participantsAmount);
    }, []);

    const onDeliveryAddressSelected = useCallback(async (displayName, option) => {
        const placeId = option?.place_id;
        const addressLocation = await HttpClient.safeGet(`/api/location/placeId/${placeId}`);

        const mainArea = addressLocation?.address_components?.find(({types}) =>
            types.includes(GoogleMapMainAreaKey)
        )?.place_id;
        const subArea = addressLocation?.address_components?.find(
            ({types}) => intersection(types, GoogleMapSubAreaApiKeys).length
        )?.place_id;

        setInnerFilters(prev => ({
            ...prev,
            deliveryAddress: [
                {
                    ...(prev.deliveryAddress?.[0] ?? {}),
                    displayName,
                    mainArea,
                    subArea
                }
            ]
        }));
    }, []);

    const resetDeliveryAddress = useCallback(inputValue => {
        setIsSearchEmpty(!inputValue?.length);
        setInnerFilters(prev => {
            const currentAddress = prev.deliveryAddress?.[0]?.displayName;
            if (!currentAddress || currentAddress === inputValue) {
                return prev;
            }

            return {
                ...prev,
                deliveryAddress: [
                    {
                        deliveryPrice: prev.deliveryAddress?.[0]?.deliveryPrice
                    }
                ]
            };
        });
    }, []);

    const updateBudgetPrice = useCallback(
        ({pricePerParticipant, totalPrice}, minMaxKey) => {
            let updatedBudgetFilterValues = {
                pricePerParticipant: {},
                totalPrice: {}
            };

            if (!isNaN(Number(pricePerParticipant))) {
                const pricePerParticipantValue = isEmpty(pricePerParticipant)
                    ? pricePerParticipant
                    : parseFloatNumber(pricePerParticipant);
                updatedBudgetFilterValues.pricePerParticipant[minMaxKey] = pricePerParticipantValue;

                if (innerFilters.budget?.participantsAmount) {
                    updatedBudgetFilterValues.totalPrice[minMaxKey] = parseFloat(
                        parseFloatNumber(pricePerParticipantValue * innerFilters.budget.participantsAmount)
                    );
                }
            } else if (!isNaN(Number(totalPrice))) {
                const totalPriceValue = isEmpty(totalPrice) ? totalPrice : parseFloatNumber(totalPrice);
                updatedBudgetFilterValues.totalPrice[minMaxKey] = totalPriceValue;

                if (innerFilters.budget?.participantsAmount) {
                    updatedBudgetFilterValues.pricePerParticipant[minMaxKey] = parseFloat(
                        parseFloatNumber(totalPriceValue / innerFilters.budget.participantsAmount)
                    );
                }
            } else {
                updatedBudgetFilterValues.totalPrice[minMaxKey] = null;
                updatedBudgetFilterValues.pricePerParticipant[minMaxKey] = null;
            }

            setInnerFilters(currentFilters =>
                set({...currentFilters}, "budget", merge(currentFilters.budget ?? {}, updatedBudgetFilterValues))
            );
        },
        [innerFilters.budget]
    );

    useEffect(() => {
        if (innerFilters.budget) {
            if (innerFilters.budget?.participantsAmount) {
                setInnerFilters(prev => ({
                    ...prev,
                    budget: {
                        ...innerFilters.budget,
                        totalPrice: {
                            min:
                                parseFloat(
                                    parseFloatNumber(
                                        innerFilters.budget.participantsAmount *
                                            innerFilters.budget.pricePerParticipant?.min
                                    )
                                ) || null,
                            max:
                                parseFloat(
                                    parseFloatNumber(
                                        innerFilters.budget.participantsAmount *
                                            innerFilters.budget.pricePerParticipant?.max
                                    )
                                ) || null
                        }
                    }
                }));
            } else {
                setInnerFilters(prev => ({
                    ...prev,
                    budget: {
                        ...innerFilters.budget,
                        totalPrice: null
                    }
                }));
            }
        }
    }, [innerFilters.budget?.participantsAmount]);

    const filterCheckBoxesOptions = useMemo(() => {
        return Object.values(FilterOptions).map(filterOptionId => ({
            value: filterOptionId,
            label: FilterOptionIdToName[filterOptionId],
            disabled: filterOptionId === FilterOptions.HomeDelivery
        }));
    }, []);

    useEffect(() => {
        if (innerFilters?.budget) {
            const {participantsAmount, pricePerParticipant, totalPrice} = innerFilters?.budget ?? {};
            const isValuesNumber = [
                pricePerParticipant?.min,
                pricePerParticipant?.max,
                totalPrice?.min,
                totalPrice?.max
            ].some(val => !isNaN(parseInt(val)));

            if (!participantsAmount && isValuesNumber) {
                setErrors(currentErrors => ({
                    ...currentErrors,
                    [FilterErrorTypes.MISSING_PARTICIPANTS_AMOUNT]: true
                }));
            } else {
                setErrors(currentErrors => {
                    delete currentErrors[FilterErrorTypes.MISSING_PARTICIPANTS_AMOUNT];
                    return currentErrors;
                });
            }
        }
    }, [innerFilters]);

    const canFilter = useMemo(
        () =>
            innerFilters?.deliveryAddress?.[0]?.displayName ||
            !isObjectWithEmptyFields(omit(innerFilters, "deliveryAddress")),
        [innerFilters]
    );

    const innerOnCancel = useCallback(() => {
        form.resetFields();
        setIsSearchEmpty(true);
        setInnerFilters({});
        onCancel();
    }, [form, onCancel]);

    return (
        <Modal
            forceRender
            className="products-filters-modal"
            destroyOnClose={true}
            maskClosable={false}
            closable={true}
            footer={null}
            width={700}
            title="מסננים נוספים"
            closeIcon={<CloseOutlined onClick={onClose} />}
            open={visible}>
            <Form
                form={form}
                onFinish={values => {
                    if (Object.keys(errors)?.length) {
                        const firstError = Object.keys(errors)?.[0];
                        message.error(firstError);
                        return;
                    }

                    onSave(innerFilters);
                }}>
                <div className="products-filters-modal-form-content">
                    <div className="products-filters-modal-category-panel">
                        הצגת מוצרים מתוך קטגוריות
                        <div>
                            {services
                                ?.filter(
                                    ({serviceId}) =>
                                        serviceId !== MainServices.LECTURES &&
                                        isAllowedForCategory(me, serviceId, false)
                                )
                                ?.reduce((acc, {serviceId}) => {
                                    if (allowedServices.includes(serviceId)) {
                                        return [
                                            ...acc,
                                            <span
                                                onClick={() => onServiceClicked(serviceId)}
                                                key={serviceId}
                                                className={classNames("products-filters-modal-category-option", {
                                                    "active-category": innerFilters.categories?.includes(serviceId)
                                                })}>
                                                {ServiceIdToName[serviceId]}
                                            </span>
                                        ];
                                    }
                                    return acc;
                                }, [])}
                        </div>
                    </div>
                    <div className="products-filters-modal-budget-panel">
                        <Form.Item label="מס׳ משתתפים">
                            <Form.Item className="products-filters-modal-budget-participants-amount">
                                <FormInputV2
                                    onChange={onParticipantsAmountChange}
                                    value={innerFilters.budget?.participantsAmount}
                                    placeholder="הזן מספר"
                                    className={classNames("products-filters-modal-number-input", {
                                        "form-error": errors[FilterErrorTypes.MISSING_PARTICIPANTS_AMOUNT]
                                    })}
                                />
                            </Form.Item>
                            <span className="products-filters-modal-budget-panel-math-sign">X</span>
                        </Form.Item>
                        <Form.Item label="תקציב למשתתף">
                            <div className="products-filters-modal-min-max-input">
                                <FormInputV2
                                    disabled={true}
                                    type="number"
                                    onChange={({target}) =>
                                        updateBudgetPrice({pricePerParticipant: target.value}, "min")
                                    }
                                    value={innerFilters.budget?.pricePerParticipant?.min}
                                    placeholder="מינימום"
                                    className="products-filters-modal-number-input"
                                />
                                <FormInputV2
                                    type="number"
                                    onChange={({target}) =>
                                        updateBudgetPrice({pricePerParticipant: Number(target.value)}, "max")
                                    }
                                    value={innerFilters.budget?.pricePerParticipant?.max}
                                    placeholder="מקסימום"
                                    className="products-filters-modal-number-input"
                                />
                            </div>
                            <span className="products-filters-modal-budget-panel-math-sign">=</span>
                        </Form.Item>
                        <Form.Item label="תקציב פעילות">
                            <div className="products-filters-modal-min-max-input">
                                <FormInputV2
                                    disabled={true}
                                    type="number"
                                    onChange={({target}) => updateBudgetPrice({totalPrice: target.value}, "min")}
                                    value={innerFilters.budget?.totalPrice?.min}
                                    placeholder="מינימום"
                                    className="products-filters-modal-number-input"
                                />
                                <FormInputV2
                                    type="number"
                                    onChange={({target}) => updateBudgetPrice({totalPrice: target.value}, "max")}
                                    value={innerFilters.budget?.totalPrice?.max}
                                    placeholder="מקסימום"
                                    className="products-filters-modal-number-input"
                                />
                            </div>
                        </Form.Item>
                    </div>
                    <div className="products-filters-modal-delivery">
                        <Form.Item label="הצג מוצרים שמגיעים לכתובת אספקה">
                            <AutoCompleteAddressFormInput
                                countriesToSearchIn={["IL"]}
                                locationTypes={[]}
                                onInputChange={resetDeliveryAddress}
                                editValue={innerFilters.deliveryAddress?.[0]?.displayName}
                                onChange={onDeliveryAddressSelected}
                                className={classNames("products-filters-modal-address-input", {
                                    noSearchOptionSelected: !innerFilters.deliveryAddress?.[0]?.displayName,
                                    inputBoxWithValue: !isSearchEmpty
                                })}
                                placeholder="הזן כתובת"
                                disabled={true}
                            />
                        </Form.Item>
                        <Form.Item label="מחיר משלוח מקסימלי">
                            <FormInputV2
                                disabled={
                                    !innerFilters?.deliveryAddress?.[0]?.displayName ||
                                    (innerFilters.deliveryAddress?.[0]?.mainArea &&
                                        !innerFilters.deliveryAddress?.[0]?.subArea)
                                }
                                value={innerFilters?.deliveryAddress?.[0]?.deliveryPrice}
                                placeholder="הזן סכום"
                                onChange={({target}) =>
                                    setInnerFilters(prev => {
                                        if (!target.value) {
                                            return {
                                                ...set(prev, "deliveryAddress[0].deliveryPrice", null)
                                            };
                                        }

                                        const newValue = Number(target.value);
                                        if (!isNaN(newValue)) {
                                            return {
                                                ...set(prev, "deliveryAddress[0].deliveryPrice", newValue)
                                            };
                                        }

                                        if (prev?.deliveryAddress[0].deliveryPrice) {
                                            return prev;
                                        }

                                        return {
                                            ...set(prev, "deliveryAddress[0].deliveryPrice", 0)
                                        };
                                    })
                                }
                            />
                        </Form.Item>
                    </div>
                    <div>הצגת מוצרים שעומדים במאפיינים</div>
                    <Form.Item>
                        <Checkbox.Group
                            className={classNames("products-filters-modal-check-box-group", "primary-color-checkbox")}
                            onChange={checkedValues => onFormValueChange("productProperties", checkedValues)}
                            value={innerFilters?.productProperties}
                            options={filterCheckBoxesOptions}
                        />
                    </Form.Item>
                </div>
                <div className="products-filters-modal-bottom-line">
                    <span
                        onClick={() => {
                            // form.resetFields();
                            setInnerFilters({});
                            setFilters({});
                            onCancel();
                        }}>
                        ניקוי הכל
                    </span>
                    <SquareButton disabled={!canFilter} htmlType="submit">
                        {"הצג"}
                        {isFinite(foundProductsCount) ? (
                            <span className="products-filters-modal-finish-button-product-count">
                                {foundProductsCount}
                            </span>
                        ) : (
                            " "
                        )}
                        מוצרים
                    </SquareButton>
                </div>
            </Form>
        </Modal>
    );
};

export default ProductsFiltersModal;
