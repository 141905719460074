import React, {useContext} from "react";
import {message} from "antd";
import {AppContext} from "../../AppContext";
import {ContactPhone} from "../../support/ContactPhone";

let updateTransactionState = () => {};

const onPaymentStateChange = ({origin, data}) => {
    try {
        if (origin === location.origin) {
            data = JSON.parse(data);
            const {payment} = data;
            updateTransactionState(payment);
        }
    } catch (e) {
        message.error("Something went wrong, please try again later.", 4);
    }
};

const TransactionMacros = {
    PRICE: "[PRICE]",
    PRODUCT_NAME: "[PRODUCT_NAME]",
    SUCCESS_URL: "[SUCCESS_URL]",
    FAIL_URL: "[FAIL_URL]",
    EMAIL: "[EMAIL]",
    NAME: "[NAME]"
};

let listenToPaymentState = false;

export const TransactionWithIframe = ({providerInfo, event, product, onComplete, tokenUrls}) => {
    const {me} = useContext(AppContext);

    const hasTokenUrls = () => tokenUrls && !!tokenUrls.successUrl && !!tokenUrls.failUrl;

    if (!hasTokenUrls()) {
        return (
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    maxWidth: 300,
                    fontSize: 16,
                    textAlign: "center",
                    minHeight: 400
                }}>
                Something went wrong, please contact support: {ContactPhone.prettyPhoneNumber()}
            </div>
        );
    }

    const successUrl = encodeURIComponent(
        `${location.origin}${tokenUrls.successUrl.replace(TransactionMacros.PRICE, product.price)}`
    );
    const failUrl = encodeURIComponent(`${location.origin}${tokenUrls.failUrl}`);

    const IFRAME_MACROS = {
        [TransactionMacros.PRICE]: () => product.price,
        [TransactionMacros.PRODUCT_NAME]: () => encodeURIComponent(product.productName),
        [TransactionMacros.SUCCESS_URL]: () => successUrl,
        [TransactionMacros.FAIL_URL]: () => failUrl,
        [TransactionMacros.EMAIL]: () => me.email,
        [TransactionMacros.NAME]: () => encodeURIComponent(`${me.firstName}${me.lastName ? ` ${me.lastName}` : ""}`)
    };

    if (listenToPaymentState) {
        window.removeEventListener("message", onPaymentStateChange);
    }

    updateTransactionState = payment => onComplete(payment);

    listenToPaymentState = true;

    window.addEventListener("message", onPaymentStateChange);

    const toQueryString = (params = {}) => {
        return Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join("&");
    };

    const toFullUrl = (url, params) => {
        return `${url}?${toQueryString(params)}`;
    };

    const replaceMacros = params => {
        Object.keys(params).forEach(key => {
            if (params[key] in IFRAME_MACROS) {
                params[key] = IFRAME_MACROS[params[key]]();
            }
        });
    };

    const terminal = providerInfo.transactionTerminal;
    const iframeInfo = terminal.info;
    const url = iframeInfo.url;
    const params = iframeInfo.params;
    const width = iframeInfo.width;
    const height = iframeInfo.height;

    replaceMacros(params);

    return (
        <iframe
            src={toFullUrl(url, params)}
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            allowTransparency={true}
            allowFullScreen={false}
            scrolling="none"
            width={width}
            height={height}
        />
    );
};
