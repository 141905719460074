import {PageTitleLabel} from "../../components/PageTitle";
import {RoundedTable} from "../../components/RoundedTable";
import {AdminBillingToBeReceivedSummaryLine} from "./AdminBillingToBeReceivedSummaryLine";
import moment from "moment-timezone";
import React, {useEffect, useState} from "react";
import {Avatar, Button, message} from "antd";
import {FrownOutlined} from "@ant-design/icons";
import {HttpClient} from "../../http/HttpClient";
import {sumBy, truncate, round} from "lodash";

const columns = () => {
    return [
        {
            title: "#",
            key: "#",
            width: 70,
            render(_, __, idx) {
                return `${idx + 1}`;
            }
        },
        {
            title: "NAME",
            key: "name",
            width: 300,
            sorter: (r1, r2) => {
                const name1 = `${r1.firstName}${r1.lastName ? ` ${r1.lastName}` : ""}`;
                const name2 = `${r2.firstName}${r2.lastName ? ` ${r2.lastName}` : ""}`;
                return name1.toLowerCase() < name2.toLowerCase() ? 1 : -1;
            },
            defaultSortOrder: "descend",
            render(_, {firstName, lastName, phone, imageUrl}) {
                return (
                    <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                        {imageUrl ? (
                            <Avatar
                                style={{minWidth: 32, minHeight: 32, maxWidth: 32, maxHeight: 32, marginRight: 8}}
                                src={imageUrl}
                            />
                        ) : (
                            <FrownOutlined style={{fontSize: 32, marginRight: 8}} />
                        )}
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <label
                                style={{
                                    color: "var(--secondary-color)",
                                    fontSize: 14,
                                    fontWeight: 500
                                }}>{`${firstName}${lastName ? ` ${lastName}` : ""}`}</label>
                            <label style={{color: "#747F92", fontSize: 12, fontWeight: 400}}>{phone}</label>
                        </div>
                    </div>
                );
            }
        },
        {
            title: "CATEGORY",
            dataIndex: "categories",
            width: 250,
            render(categories) {
                return (
                    <span style={{fontSize: 14, color: "#747F92"}}>
                        {truncate(categories.join(", "), {length: 50, separator: ","})}
                    </span>
                );
            }
        },
        {
            title: "MONTHLY SESSIONS",
            dataIndex: "sessionsCount",
            render(sessionsCount) {
                return <span style={{fontSize: 14, color: "#747F92"}}>{sessionsCount}</span>;
            }
        },
        {
            title: "MONTHLY SUM",
            dataIndex: "totalSum",
            width: 250,
            render(totalSum, {firstTransactionDate, lastTransactionDate}) {
                if (firstTransactionDate && lastTransactionDate) {
                    return (
                        <span style={{fontSize: 14, color: "#747F92"}}>
                            {`₪${round(totalSum, 2).toLocaleString()}+VAT | ${moment(
                                firstTransactionDate,
                                "YYYY-MM-DD"
                            ).format("DD")}-${moment(lastTransactionDate, "YYYY-MM-DD").format("DD/MM/YYYY")}`}
                        </span>
                    );
                } else if (firstTransactionDate) {
                    return (
                        <span style={{fontSize: 14, color: "#747F92"}}>
                            {`₪${round(totalSum, 2).toLocaleString()}+VAT | ${moment(
                                firstTransactionDate,
                                "YYYY-MM-DD"
                            ).format("DD/MM/YYYY")}`}
                        </span>
                    );
                } else {
                    return (
                        <span style={{fontSize: 14, color: "#747F92"}}>
                            {`₪${round(totalSum, 2).toLocaleString()}+VAT`}
                        </span>
                    );
                }
            }
        },
        {
            title: "PLAN",
            dataIndex: "paymentPlan",
            width: 200,
            render(paymentPlan) {
                return <span style={{fontSize: 14, color: "#747F92"}}>{paymentPlan}</span>;
            }
        },
        {
            title: "CARD",
            dataIndex: "cardExists",
            width: 200,
            render(cardExists) {
                return cardExists ? (
                    <Button type="link" style={{fontSize: 14, color: "#1260D1", fontWeight: 700}}>
                        YES
                    </Button>
                ) : (
                    <Button type="link" style={{fontSize: 14, color: "#747F92", fontWeight: 700}}>
                        NO
                    </Button>
                );
            }
        },
        {
            title: "TO BE RECEIVED",
            dataIndex: "totalToBeReceived",
            render(totalToBeReceived, {paymentClosed}) {
                return (
                    <span
                        style={{
                            fontSize: 14,
                            fontWeight: 700,
                            color: paymentClosed ? "#13C296" : "#D04949"
                        }}>{`₪${round(totalToBeReceived, 2).toLocaleString()}+VAT`}</span>
                );
            }
        }
    ];
};

export const AdminBillingProvidersToBeReceived = ({date}) => {
    const [providers, setProviders] = useState(null);
    const [totalProviders, setTotalProviders] = useState(null);

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const providers = await HttpClient.get(`/admin/api/billing/${date}/tobereceived/providers`);
                setProviders(providers);
                setTotalProviders(sumBy(providers, p => p.totalToBeReceived));
            } catch (e) {
                message.error("Something went wrong, please try again later.", 5);
            }
        });
    }, []);

    return (
        <>
            <PageTitleLabel
                style={{marginTop: 30, marginBottom: 20, fontSize: 20, justifyContent: "flex-start", fontWeight: 400}}>
                Professionals
            </PageTitleLabel>
            <RoundedTable
                rowKey="providerId"
                scroll={{x: 1600, y: 600}}
                style={{color: "#747F92"}}
                pagination={false}
                loading={providers === null}
                dataSource={providers || []}
                columns={columns()}
            />
            <AdminBillingToBeReceivedSummaryLine
                toBeReceivedOn={moment(date, "YYYY-MM").format("MMMM [1st]")}
                sumTotal={totalProviders ? totalProviders : 0}
            />
        </>
    );
};
