import {Workbook} from "exceljs";
import {StringBuilder} from "../AppUtils.js";

export const EXCEL_MAX_ROWS = 1048576;

export const createWorkbook = () => {
    const workbook = new Workbook();
    workbook.creator = "WellB";
    return workbook;
};

export const createWorksheet = (workbook, title) => {
    const sheet = workbook.addWorksheet(title);
    sheet.views = [{rightToLeft: true}];
    return sheet;
};

export const loadExcel = async fileBuffer => {
    const workbook = new Workbook();

    try {
        return await workbook.xlsx.load(fileBuffer);
    } catch (e) {
        return null;
    }
};

export const excelToBlob = async workbook => {
    const data = await workbook.xlsx.writeBuffer();
    return new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
};

export const DownloadExcel = async (workbook, fileName) => {
    const blob = await excelToBlob(workbook);

    const csvURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", `${fileName}.xlsx`);
    tempLink.click();
};

export const getCellText = (cell, trim = true) => {
    const text = cell?.text ?? cell;

    if (trim && text?.trim) {
        return text.trim();
    }

    return text;
};

export const applyDropdownCellValidation = (
    sheet,
    dropdownColumns,
    options = [],
    validationError,
    validationErrorTitle,
    initCellIndex = 2,
    endCellIndex = EXCEL_MAX_ROWS
) => {
    dropdownColumns.forEach(key => {
        const column = sheet.getColumn(key);

        if (!column) {
            return;
        }

        const range = `${column.letter}${initCellIndex}:${column.letter}${endCellIndex}`;

        const optionFormat = options.join(",");

        sheet.dataValidations.add(range, {
            type: "list",
            allowBlank: true,
            formulae: [`"${optionFormat}"`], // Dropdown options
            showErrorMessage: true,
            error:
                validationError ??
                new StringBuilder("בחירה שגויה, אנא בחר את אחת האופציות").append(optionFormat).toString(),
            errorTitle: validationErrorTitle ?? "שגיאת וולידציה"
        });
    });
};

export const extractDataFromExcel = (sheet, headerMapping, transformRow) => {
    const results = [];
    const headers = {};

    sheet.getRow(1).eachCell((cell, colNumber) => {
        const key = headerMapping[cell.value];
        if (key) headers[colNumber] = key;
    });

    sheet.eachRow((row, rowIndex) => {
        if (rowIndex === 1) return;

        const rowData = {};
        row.eachCell({includeEmpty: true}, (cell, colNumber) => {
            const key = headers[colNumber] || `Column${colNumber}`;
            if (cell.value) {
                rowData[key] = cell.value;
            }
        });

        rowData.excelRowNumber = rowIndex;

        const data = transformRow ? transformRow(rowData, rowIndex) : rowData;

        results.push(data);
    });

    return results;
};
