import React, {useCallback, useEffect, useMemo, useState} from "react";
import classnames from "classnames";
import {Dropdown, Menu, Spin, Tooltip} from "antd";
import {DownOutlined, LeftOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {NOOP} from "../../utils/NOOP";

export const getPercentageItemProperties = (partValue, wholeValue) => ({
    value: partValue,
    prefix: `(${Math.round((partValue / wholeValue || 0) * 100).toFixed(0)}%)`
});

export const StatisticsLabel = ({
    statistics,
    title,
    className,
    trigger = ["click"],
    onChangeTriggerPrentLabel = NOOP,
    triggerParentLabels = []
}) => {
    const [dropdownVisibility, setDropdownVisibility] = useState(false);
    const [selectedParents, setSelectedParents] = useState([]);

    const onPrentLabelClicked = useCallback(
        (parentLabel, event) => {
            event.stopPropagation();
            event.preventDefault();

            if (triggerParentLabels.includes(parentLabel)) {
                onChangeTriggerPrentLabel(parentLabel);
            }

            setSelectedParents(prevSelected =>
                prevSelected.includes(parentLabel)
                    ? prevSelected.filter(parent => parent !== parentLabel)
                    : [...prevSelected, parentLabel]
            );

            setDropdownVisibility(true);
        },
        [triggerParentLabels]
    );

    const menu = useMemo(() => {
        const renderMenuItem = (
            {
                name,
                classname,
                children,
                value,
                tooltip,
                prefix,
                suffix,
                removeIfEmpty,
                loading,
                parentKey,
                showAsParent
            },
            index
        ) => {
            const shouldHideItem = (removeIfEmpty && !value) || (!children && !selectedParents.includes(parentKey));
            return shouldHideItem
                ? null
                : [
                      name === "divider" ? (
                          <Menu.Divider key={`${name}-${index}`} />
                      ) : (
                          <Menu.Item
                              onClick={children ? event => onPrentLabelClicked(name, event.domEvent) : NOOP}
                              className={classnames(
                                  "admin-orders-statistics-menu-item",
                                  {"admin-orders-statistics-parent-menu": children?.length || showAsParent},
                                  classname
                              )}
                              key={name}>
                              <span>
                                  <span>{name}</span>:
                                  {tooltip ? (
                                      <Tooltip title={tooltip}>
                                          <QuestionCircleOutlined style={{cursor: "pointer", margin: "5px"}} />
                                      </Tooltip>
                                  ) : null}
                                  {children?.length ? (
                                      selectedParents.includes(name) ? (
                                          <LeftOutlined className="admin-orders-statistics-menu-item-arrow-icon" />
                                      ) : (
                                          <DownOutlined className="admin-orders-statistics-menu-item-arrow-icon" />
                                      )
                                  ) : null}
                              </span>
                              <span>
                                  {loading ? (
                                      <Spin />
                                  ) : (
                                      <>
                                          {prefix}
                                          <bdi>{value?.toLocaleString()}</bdi>
                                          {suffix}
                                      </>
                                  )}
                              </span>
                          </Menu.Item>
                      ),
                      ...(children ?? []).map((child, index) => renderMenuItem({...child, parentKey: name}, index))
                  ];
        };

        return <Menu className="admin-orders-statistics-drop-down-menu">{statistics.map(renderMenuItem)}</Menu>;
    }, [statistics, selectedParents, onPrentLabelClicked]);

    useEffect(() => {
        if (!dropdownVisibility) {
            setSelectedParents([]);
        }
    }, [dropdownVisibility]);

    return (
        <Dropdown
            open={dropdownVisibility}
            disabled={!statistics?.length}
            overlayClassName={className}
            onOpenChange={setDropdownVisibility}
            className="statistics-label-dropdown"
            trigger={trigger}
            overlay={menu}>
            <div className="statistics-label">
                <span>
                    {title}
                    {statistics?.length ? (
                        <DownOutlined
                            className={classnames("admin-orders-statistics-menu-item-arrow-icon", {
                                isActive: dropdownVisibility
                            })}
                        />
                    ) : null}
                </span>
            </div>
        </Dropdown>
    );
};
