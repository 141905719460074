import React, {useEffect, useMemo, useState} from "react";
import {InputNumber, Modal, Select} from "antd";
import {Frequency} from "../consts";

export const HappinessCalculatorRepeatOccasionModal = ({visible, onClose, monthAmount = 1, onSave}) => {
    const [selectedFrequency, setSelectedFrequency] = useState();
    const [repeatAmount, setRepeatAmount] = useState(1);

    const frequencyOption = useMemo(() => {
        if (!monthAmount) {
            return {};
        }

        return Object.values(Frequency).reduce((acc, freq) => {
            if (freq.ratio * monthAmount < 1) {
                return acc;
            }

            freq.maxAmount = Math.floor(freq.ratio * monthAmount);
            return {...acc, [freq.id]: freq};
        }, {});
    }, [monthAmount]);

    useEffect(() => {
        if (monthAmount) {
            setSelectedFrequency(frequencyOption.Weekly?.id);
        }
    }, [monthAmount]);

    return (
        <Modal
            onOk={() => onSave({repeatAmount, selectedFrequency: frequencyOption[selectedFrequency]})}
            onCancel={onClose}
            open={visible}
            className="happiness-calculator-range-occasion-repeat-date-modal">
            <div className="happiness-calculator-range-occasion-repeat-date-modal-title">הגדרת תדירות</div>
            <div className="happiness-calculator-range-occasion-repeat-date-modal-repeat-amount">
                <span>חזרה כל</span>
                <InputNumber
                    defaultValue={1}
                    onChange={setRepeatAmount}
                    max={frequencyOption[selectedFrequency]?.maxAmount}
                    min={1}
                />
                <Select
                    value={selectedFrequency}
                    onChange={setSelectedFrequency}
                    options={Object.values(frequencyOption).map(option => ({
                        label: repeatAmount > 1 ? option.pluralName : option.name,
                        value: option.id
                    }))}
                />
            </div>
        </Modal>
    );
};
