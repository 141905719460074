import React, {useContext, useEffect, useState} from "react";
import {EXCenterBirthdayCard} from "./EXCenterBirthdayCard";
import {HttpClient} from "../../http/HttpClient";
import moment from "moment";
import {sortBy} from "lodash";
import {EXCenterNoBirthdaysCard} from "./EXCenterNoBirthdaysCard";
import {EXCenterSectionDesignMenu} from "../control/EXCenterSectionDesignMenu";
import {EXCenterContext} from "../EXCenterContext";
import {AppContext} from "../../AppContext";
import {CarouselArrow} from "../../components/carousel/CarouselArrows";
import {WBCarousel} from "../../components/carousel/WBCarousel";
import {EXCenterProperties} from "../utils/EXCenterProperties";
import {useResizeHandler} from "../../utils/useResizeHandler";
import {message} from "antd";

const BIRTHDAYS_TO_SHOW = 3;

const onlyWithBirthdaysThisWeek = employees => {
    return sortBy(
        employees.filter(employee => {
            if (employee.birthday) {
                const empBirth = moment(moment(employee.birthday, "DD/MM/YYYY").format("DD/MM"), "DD/MM");
                const startOfWeek = moment().startOf("week");
                const endOfWeek = moment().endOf("week");
                return empBirth.isSameOrAfter(startOfWeek) && empBirth.isSameOrBefore(endOfWeek);
            }

            return false;
        }),
        e => moment(moment(e.birthday, "DD/MM/YYYY").format("DD/MM"), "DD/MM").valueOf()
    );
};

export const EXCenterBirthdaysSection = () => {
    const sectionId = "birthdays";
    let [employees, setEmployees] = useState(null);
    const [renderKey, setRenderKey] = useState(0);
    const {designInfoPerSection, theme} = useContext(EXCenterContext);
    const {me} = useContext(AppContext);

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const emps = await HttpClient.get("/api/employees?me2=true");
                setEmployees(onlyWithBirthdaysThisWeek(emps));
            } catch (e) {
                message.error("Something went wrong, please try again later.", 5);
            }
        });

        return useResizeHandler(() => setRenderKey(renderKey + 1));
    }, []);

    const cardsToShow = () => {
        if (isMobileView() || employees.length === 0) {
            return 1;
        } else if (employees.length >= BIRTHDAYS_TO_SHOW) {
            return BIRTHDAYS_TO_SHOW;
        } else {
            return employees.length;
        }
    };

    const isMobileView = () => window.innerWidth < 1200;

    if (employees === null) {
        return null;
    }

    const carouselProps = {
        dots: !isMobileView(),
        infinite: true,
        slidesToShow: cardsToShow(),
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        nextArrow: (
            <CarouselArrow
                backgroundColor="white"
                arrowColor={theme.primaryColor}
                direction={CarouselArrow.Direction.NEXT}
            />
        ),
        prevArrow: (
            <CarouselArrow
                backgroundColor="white"
                arrowColor={theme.primaryColor}
                direction={CarouselArrow.Direction.PREV}
            />
        ),
        accessibility: true,
        arrows: !isMobileView(),
        className: "wb-carousel wb-center-carousel-birthdays"
    };

    const sectionDesignInfo = designInfoPerSection[sectionId];

    if ((me.type === "employee" && !sectionDesignInfo.display) || employees.length === 0) {
        return null;
    }

    return (
        <div className="wb-center-section">
            <EXCenterSectionDesignMenu
                sectionId={sectionId}
                editItems={{
                    title: true,
                    buttonNoLink: true
                }}
            />
            <div
                key={`birthdays-${renderKey}`}
                className="wb-ss-top"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: EXCenterProperties.Section.Top,
                    paddingBottom: employees && employees.length > cardsToShow() ? 40 : 0,
                    width: "100%",
                    opacity: EXCenterProperties.Section.opacityByDisplay(sectionDesignInfo)
                }}>
                <div
                    className="wb-ss-section-title"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: 34,
                        fontWeight: 900,
                        color: theme.textColor,
                        marginBottom: 30
                    }}>
                    {sectionDesignInfo.title}
                </div>
                <div className="wb-ss-birthdays-carousel" style={{display: "block", maxWidth: 900, width: "100%"}}>
                    <WBCarousel
                        key={"carousel" + renderKey}
                        carouselProps={carouselProps}
                        primaryColor={theme.primaryColor}>
                        {employees.length === 0 ? (
                            <EXCenterNoBirthdaysCard />
                        ) : (
                            employees.map(employee => (
                                <EXCenterBirthdayCard
                                    key={employee.userId}
                                    designInfo={sectionDesignInfo}
                                    employee={employee}
                                />
                            ))
                        )}
                    </WBCarousel>
                </div>
            </div>
        </div>
    );
};
