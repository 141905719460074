import {StringBuilder} from "../../../../AppUtils.js";

export const getLocationFilterInnerState = locationFilter => {
    const currentTab = locationFilter?.[locationFilter?.currentTab];
    return currentTab?.key ? currentTab[currentTab.key] : currentTab;
};

export const getLocationTitle = locationFilter => {
    const deliveryAddressData = getLocationFilterInnerState(locationFilter);
    if (!deliveryAddressData?.displayName) {
        return;
    }
    return new StringBuilder()
        .append(deliveryAddressData.displayName)
        .append(deliveryAddressData?.isAccessible ? ",עם נגישות" : "")
        .toString();
};
