import {ValidateBookingError, ValidateVoucherBookingError} from "../../consts.js";
import {OrderFormPanelKeys} from "../consts";
import {OUT_OF_BUSINESS_HOURS_ERROR} from "../MarketplaceUtils";

export const FIRST_ORDER = "FIRST_ORDER";
export const UPDATE_DETAILS = "UPDATE_DETAILS";
export const UPDATE_INVOICE_DETAILS = "UPDATE_INVOICE_DETAILS";
export const DEFAULT_HOME_DELIVERY_PRICE = 30;

export const MIN_USER_FIELD_TRIGGER = 3;

export const TEN_BIS_CARD_LENGTH = 16;

export const BOOKING_ERROR_TO_PANEL = {
    [ValidateBookingError.NO_LOCATION]: OrderFormPanelKeys.Location,
    [ValidateBookingError.NO_FLOOR]: OrderFormPanelKeys.Location,
    [ValidateBookingError.NO_TIME_OR_DATE]: OrderFormPanelKeys.Time,
    [ValidateBookingError.NO_PAYMENT_TYPE]: OrderFormPanelKeys.Payment,
    [ValidateBookingError.NO_PAYMENT_TYPE_CREDIT_CARD]: OrderFormPanelKeys.Payment,
    [ValidateBookingError.NO_AMOUNT]: OrderFormPanelKeys.Amount,
    [ValidateBookingError.NO_DELIVERY]: OrderFormPanelKeys.Location,
    [ValidateBookingError.NO_INVOICE_PO_NUMBER]: OrderFormPanelKeys.Payment,
    [OUT_OF_BUSINESS_HOURS_ERROR]: OrderFormPanelKeys.Time,
    [ValidateBookingError.MAX_PRODUCT_AMOUNT]: OrderFormPanelKeys.Amount,
    [ValidateBookingError.MIN_PRODUCT_AMOUNT]: OrderFormPanelKeys.Amount,
    [ValidateBookingError.NO_MODULAR_AMOUNT]: OrderFormPanelKeys.Amount,
    [ValidateBookingError.NO_AMOUNT_UPGRADE_OPTIONS]: OrderFormPanelKeys.UpgradeOptions,

    [ValidateVoucherBookingError.NO_SENDER_NAME]: OrderFormPanelKeys.VoucherSender,
    [ValidateVoucherBookingError.NO_TIME_OPTION]: OrderFormPanelKeys.VoucherTime,
    [ValidateVoucherBookingError.NO_SEND_OPTION]: OrderFormPanelKeys.VoucherSendingMethod,
    [ValidateVoucherBookingError.NO_VOUCHER]: OrderFormPanelKeys.VoucherAmount,
    [ValidateVoucherBookingError.NO_RECEIVER]: OrderFormPanelKeys.VoucherRecipient,
    [ValidateVoucherBookingError.NO_DATE_OR_TIME]: OrderFormPanelKeys.VoucherTime,
    [ValidateVoucherBookingError.NO_RECEIVER_SMS_PHONE]: OrderFormPanelKeys.VoucherSendingMethod,
    [ValidateVoucherBookingError.NO_RECEIVER_EMAIL]: OrderFormPanelKeys.VoucherSendingMethod,
    [ValidateBookingError.POLICY_CONFIRMATION_REQUIRED]: OrderFormPanelKeys.PolicyConfirmation
};

export const BOOKING_TO_PANEL_COLORS = {
    ERROR: "error",
    REDIRECT: "redirect"
};
