import React, {useState} from "react";
import {Upload} from "antd";
import {InboxOutlined} from "@ant-design/icons";
import {NOOP} from "../../utils/NOOP";

const {Dragger} = Upload;

export const FileUploadBase = ({
    fileList,
    setFileList,
    onBeforeUpload = NOOP,
    sizeLimitInMB = 5,
    acceptedFormats = ".png,.jpg,.jpeg,.mp4",
    multiple = true,
    showUploadList = false
}) => {
    return (
        <Dragger
            name="file"
            accept={acceptedFormats}
            multiple={multiple}
            beforeUpload={onBeforeUpload}
            showUploadList={!!fileList.length}
            fileList={fileList}
            onRemove={file => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList);
            }}>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">לחץ או גרור קובץ לכאן על מנת להעלות</p>
            {multiple ? (
                <p className="ant-upload-hint">נתמך בבחירה יחידה או מרובה של קבצים</p>
            ) : (
                <p className="ant-upload-hint">נתמך בבחירה יחידה בלבד של קובץ בודד</p>
            )}
            <p className="ant-upload-hint"> שימו לב! מגבלת העלאה עבור כל קובץ היא עד {sizeLimitInMB}MB</p>
        </Dragger>
    );
};
