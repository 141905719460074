import React, {useCallback, useEffect, useReducer} from "react";
import {SmartImageContainer} from "../../components/SmartImageContainer";
import Icon, {CloseOutlined} from "@ant-design/icons";
import {Button, Form} from "antd";
import Dragger from "antd/es/upload/Dragger";
import {UploadIcon} from "../../icons";
import {UploadClient} from "../../http/UploadClient";
import {EventBus} from "../../bus/EventBus";
import {Images} from "../../images/Images";
import {NOOP} from "../../utils/NOOP.jsx";

export const CoverPhotoHandler = ({
    imageContainerStyle,
    placeholder,
    onPhotoChange,
    currentImageUrl,
    currentImageId,
    closable = true,
    onClose = NOOP,
    formatAccepted = "image/png,image/jpeg"
}) => {
    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        uploading: false,
        imageFile: null,
        imageUrl: null,
        imageId: null
    });

    useEffect(() => {
        setState({imageUrl: currentImageUrl, imageId: currentImageId});
        onPhotoChange(currentImageUrl, currentImageId);
    }, [currentImageUrl, currentImageId]);

    let isUploading = false;

    const uploadAsync = file => {
        isUploading = true;
        Promise.resolve().then(async () => {
            setState({uploading: true});
            try {
                const formData = new FormData();
                formData.append("file", file);
                const {imageUrl, imageId} = await UploadClient.upload("PUT", "/api/provider/image", formData);
                setState({imageUrl, imageId});
                onPhotoChange(imageUrl, imageId);
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we didn't manage to upload your image :("}},
                    e.message
                );
            }
            setState({uploading: false});
            isUploading = false;
        });
    };

    const uploadProps = {
        name: "file",
        accept: formatAccepted,
        showUploadList: false,
        beforeUpload: file => {
            if (file.size >= Images.SIZE_LIMIT) {
                EventBus.trigger("server-error", {
                    content: {
                        title: "Ohhh... Gush!",
                        subTitle: "This is a huge file!",
                        description: `The maximum allowed image size is ${
                            Images.SIZE_LIMIT / 1024 / 1024
                        }MB, your image is ${parseInt(file.size / (1024 * 1024))}MB.`,
                        hideSteps: true
                    },
                    cta: {
                        hide: true
                    }
                });
            } else {
                setState({uploading: true, imageFile: file});
                uploadAsync(file);
            }
            return false;
        }
    };

    const normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList;
    };

    const {imageId, imageUrl, uploading} = state;

    const getImagePlaceholder = () => {
        return placeholder ? placeholder : "Upload Cover";
    };

    const onCloseClick = useCallback(() => {
        setState({imageUrl: null});
        onClose();
    }, []);

    return imageUrl ? (
        <div style={{display: "flex", justifyContent: "center"}}>
            <SmartImageContainer
                className="wb-image-container"
                width={240}
                height={240}
                src={imageUrl}
                transformations={{gravity: "faces"}}
                style={
                    imageContainerStyle
                        ? imageContainerStyle
                        : {
                              border: "1px solid #E8EDF5",
                              color: "var(--secondary-color)",
                              borderRadius: 120,
                              minHeight: 240,
                              maxHeight: 240,
                              minWidth: 240,
                              maxWidth: 240,
                              marginBottom: 24
                          }
                }>
                {closable ? (
                    <CloseOutlined
                        onClick={onCloseClick}
                        style={{
                            fontSize: 16,
                            color: "var(--secondary-color)",
                            position: "absolute",
                            left: 15,
                            top: 15
                        }}
                    />
                ) : null}
            </SmartImageContainer>
        </div>
    ) : (
        <Form.Item
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
                {
                    required: true,
                    validator: async () => {
                        if (!imageUrl && !isUploading) throw Error("Please upload a picture.");
                    }
                }
            ]}>
            <div style={{display: "flex", justifyContent: "center"}}>
                <Dragger
                    disabled={uploading}
                    style={
                        imageContainerStyle
                            ? imageContainerStyle
                            : {
                                  minHeight: 240,
                                  maxHeight: 240,
                                  minWidth: 240,
                                  maxWidth: 240,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  border: "1px dashed #E8EDF5",
                                  backgroundColor: "#FCFDFE",
                                  borderRadius: 120
                              }
                    }
                    {...uploadProps}>
                    <Icon component={UploadIcon} style={{fontSize: 20}} />
                    <Button
                        loading={uploading}
                        type="link"
                        style={{fontSize: 16, padding: 7, color: "var(--secondary-color)"}}>
                        {uploading ? "Uploading" : getImagePlaceholder()}
                    </Button>
                </Dragger>
            </div>
        </Form.Item>
    );
};
