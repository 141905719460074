import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
// import "tippy.js/dist/tippy.css";
// import "tippy.js/animations/scale.css";
// import "tippy.js/themes/translucent.css";
// import "react-multi-toggle/style.css";
import "./css/index.css";
import "./css/coloredComponents.css";
import "./css/animations.css";
import "./css/print.css";
import "./center/css/ex_center.css";
import "./loaders.css";
import "whatwg-fetch";
import {polyfill as objAssignPolyfill} from "es6-object-assign";
import {App} from "./App";
import {GoogleAnalytics} from "./GoogleAnalytics";
import {AppErrorHandler} from "./AppErrorHandler";
import {configure} from "mobx";
import * as Sentry from "@sentry/react";
import "./css/root-variables.css";
import {getThemeByOrigin} from "./utils.js";

objAssignPolyfill();

GoogleAnalytics.injectSnippet();
GoogleAnalytics.listenToWindowExceptions();

const body = document.body;
body.classList.add(getThemeByOrigin(window.location.origin));

// Configure MobX to isolate global state across different versions,
// preventing state conflicts and ensuring components function independently.
configure({isolateGlobalState: true});

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_URL,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/well-b\.biz\/api/, /^https:\/\/well-b\.co.il\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
    <AppErrorHandler>
        <App />
    </AppErrorHandler>,
    document.getElementById("root")
);
