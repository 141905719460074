import {
    createWorkbook,
    createWorksheet,
    DownloadExcel,
    applyDropdownCellValidation
} from "../../../utils/excelUtils.js";
import {FoodRequestsIds, FoodRequestsShortIdToName} from "../../../marketplace/ProductsFilterModal/consts.jsx";
import {StringBuilder} from "../../../AppUtils.js";
import {excelSubProductsKeys, FoodRequestAnswerOption, isActiveAnswerOption} from "./consts.jsx";
import common from "@well-b/well-b-shared";
import {VAT} from "../../../consts.js";

export const ExportSubProductsProviderExcel = async subProducts => {
    const workbook = createWorkbook();

    const excelSheets = [
        {
            title: "מיני מוצרים",
            list: subProducts
        }
    ];

    excelSheets.forEach(({list = [], title}, index) => {
        const sheet = createWorksheet(workbook, title);
        addColumns(sheet);
        addRows(sheet, list, !index, title);
    });

    await DownloadExcel(workbook, "subProductsProvider");
};

const addRows = (sheet, subProducts) => {
    const rows = subProducts.map(subProduct => {
        return {
            [excelSubProductsKeys.id]: subProduct.id,
            [excelSubProductsKeys.name]: subProduct.name,
            [excelSubProductsKeys.description]: subProduct.description,
            [excelSubProductsKeys.subProductCategory]: subProduct.subProductCategory,
            [excelSubProductsKeys.veganFriendly]: subProduct?.foodRequests?.includes(FoodRequestsIds.veganFriendly)
                ? FoodRequestAnswerOption.YES
                : FoodRequestAnswerOption.NONE,
            [excelSubProductsKeys.vegetarianFriendly]: subProduct?.foodRequests?.includes(
                FoodRequestsIds.vegetarianFriendly
            )
                ? FoodRequestAnswerOption.YES
                : FoodRequestAnswerOption.NONE,
            [excelSubProductsKeys.glutenFree]: subProduct?.foodRequests?.includes(FoodRequestsIds.glutenFree)
                ? FoodRequestAnswerOption.YES
                : FoodRequestAnswerOption.NONE,
            [excelSubProductsKeys.lactoseFree]: subProduct?.foodRequests?.includes(FoodRequestsIds.lactoseFree)
                ? FoodRequestAnswerOption.YES
                : FoodRequestAnswerOption.NONE,
            [excelSubProductsKeys.furOption]: subProduct?.foodRequests?.includes(FoodRequestsIds.furOption)
                ? FoodRequestAnswerOption.YES
                : FoodRequestAnswerOption.NONE,
            [excelSubProductsKeys.price]: subProduct.price,
            [excelSubProductsKeys.commission]: parseFloat(subProduct.commission),
            [excelSubProductsKeys.priceWithoutCommission]: parseFloat(
                common.calculateSubProductPriceWithoutCommission(subProduct.price, subProduct.commission, VAT)
            ),
            [excelSubProductsKeys.catalogNumber]: subProduct.catalogNumber,
            [excelSubProductsKeys.isActive]: subProduct.isActive
                ? isActiveAnswerOption.ACTIVE
                : isActiveAnswerOption.NONE_ACTIVE
        };
    });

    sheet.addRows(rows);
};

const addColumns = sheet => {
    sheet.columns = [
        {header: "מזהה מיני מוצר", key: excelSubProductsKeys.id},
        {header: "מק״ט", key: excelSubProductsKeys.catalogNumber},
        {header: "שם מוצר", key: excelSubProductsKeys.name},
        {header: "תיאור המוצר", key: excelSubProductsKeys.description},
        {header: "קטגוריה", key: excelSubProductsKeys.subProductCategory},
        {header: FoodRequestsShortIdToName[FoodRequestsIds.veganFriendly], key: excelSubProductsKeys.veganFriendly},
        {
            header: FoodRequestsShortIdToName[FoodRequestsIds.vegetarianFriendly],
            key: excelSubProductsKeys.vegetarianFriendly
        },
        {header: FoodRequestsShortIdToName[FoodRequestsIds.glutenFree], key: excelSubProductsKeys.glutenFree},
        {header: FoodRequestsShortIdToName[FoodRequestsIds.lactoseFree], key: excelSubProductsKeys.lactoseFree},
        {header: FoodRequestsShortIdToName[FoodRequestsIds.furOption], key: excelSubProductsKeys.furOption},
        {header: "מחיר ללקוח", key: excelSubProductsKeys.price},
        {header: "עמלה (פלוס מע״מ)", key: excelSubProductsKeys.commission},
        {header: "מחיר קנייה", key: excelSubProductsKeys.priceWithoutCommission},
        {header: "סטטוס", key: excelSubProductsKeys.isActive}
    ];

    const dropdownColumns = [
        excelSubProductsKeys.veganFriendly,
        excelSubProductsKeys.vegetarianFriendly,
        excelSubProductsKeys.glutenFree,
        excelSubProductsKeys.lactoseFree,
        excelSubProductsKeys.furOption
    ];

    applyDropdownCellValidation(sheet, dropdownColumns, [FoodRequestAnswerOption.YES, FoodRequestAnswerOption.NONE]);
    applyDropdownCellValidation(
        sheet,
        [excelSubProductsKeys.isActive],
        [isActiveAnswerOption.ACTIVE, isActiveAnswerOption.NONE_ACTIVE]
    );
};
