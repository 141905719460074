import React, {useReducer} from "react";
import {Images} from "../images/Images";
import {Tag} from "antd";

export const PaymentPlan = {
    NOT_POPULAR: "0NIS + 20% of Revenues",
    POPULAR: "49NIS + 10% of Revenues",
    PER_ACTIVE_DAY: "99NIS Per Active Day"
};

export const PaymentPlanToRevenueSharePercent = {
    [PaymentPlan.POPULAR]: 0.1,
    [PaymentPlan.NOT_POPULAR]: 0.2,
    [PaymentPlan.PER_ACTIVE_DAY]: 0
};

export const ProviderPaymentSelection = props => {
    const {onChange, value} = props;

    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        popularSelected: value === PaymentPlan.POPULAR
    });

    const onSelectChange = popular => {
        setState({popularSelected: popular});
        onChange(popular ? PaymentPlan.POPULAR : PaymentPlan.NOT_POPULAR);
    };

    const {popularSelected} = state;

    return (
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center", position: "relative"}}>
            <div
                onClick={() => onSelectChange(true)}
                style={{
                    position: "relative",
                    backgroundImage: `url(${
                        popularSelected ? Images.paymentPopularSelected : Images.paymentPopularUnselected
                    })`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    cursor: "pointer",
                    width: 348,
                    height: 471,
                    margin: 10
                }}
            />
            <div
                onClick={() => onSelectChange(false)}
                style={{
                    backgroundImage: `url(${
                        popularSelected ? Images.paymentNotPopularUnselected : Images.paymentNoPopularSelected
                    })`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    cursor: "pointer",
                    width: 330,
                    height: 471,
                    margin: 10,
                    marginLeft: 20
                }}
            />
        </div>
    );
};
