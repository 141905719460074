import {CloseIcon} from "../../icons";
import {Modal} from "antd";
import React, {useContext} from "react";
import {CompanyContentDataStore} from "../../company/content/datastores/CompanyContentDataStore";
import {DesignDisplayItem} from "./EXCenterSectionDesignEditModal";
import {StyledButton} from "../../components/StyledButton";
import {EXCenterContext} from "../EXCenterContext";
import {Link} from "react-router-dom";
import {EventBus} from "../../bus/EventBus";

export const EXCenterContentModal = ({sectionId, visible, onClose, onDisplayChange, onChange}) => {
    const {designInfoPerSection, theme} = useContext(EXCenterContext);

    const refreshCenter = () => {
        EventBus.trigger("ex_center:refresh");
        onChange && onChange();
    };

    const sectionDesignInfo = designInfoPerSection[sectionId];

    return (
        <Modal
            width={1200}
            className="wb-modal-radius"
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            bodyStyle={{padding: 0}}
            footer={null}
            title={null}
            closable={true}
            closeIcon={<CloseIcon fill="white" style={{marginTop: 20}} />}>
            <div style={{display: "flex", width: "100%", flexDirection: "column", alignItems: "center"}}>
                <div
                    style={{
                        height: 56,
                        width: "100%",
                        backgroundColor: theme.textColor,
                        fontSize: 20,
                        color: "white",
                        fontWeight: 700,
                        paddingLeft: 20,
                        display: "flex",
                        alignItems: "center"
                    }}>
                    Edit component
                </div>
                <div style={{padding: 30, width: "100%"}}>
                    <CompanyContentDataStore
                        location={location}
                        initialSectionId={sectionId}
                        maxHeight={300}
                        maxWidth={1140}
                        style={{margin: 0}}
                        onChange={refreshCenter}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: 90,
                        borderTop: "1px solid #DADEE4",
                        width: "100%",
                        paddingLeft: 30,
                        paddingRight: 30
                    }}>
                    <DesignDisplayItem value={sectionDesignInfo.display} onChange={onDisplayChange} />
                    <Link to={`/center/sections/${sectionId}`}>
                        <StyledButton
                            style={{
                                backgroundColor: "white",
                                height: 50,
                                border: "1px solid #13C296",
                                color: "#13C296"
                            }}>
                            View in Employee Center
                        </StyledButton>
                    </Link>
                </div>
            </div>
        </Modal>
    );
};
