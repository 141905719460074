import React from "react";
import "./order-status-view.css";
import classnames from "classnames";
import {ConfigProvider} from "antd";
import {useTimer} from "../../utils/hooks.jsx";
import {OrderActions} from "../OrderActionList/OrderActions.jsx";

export const OrderStatusView = ({
    title,
    subTitle,
    description = [],
    actions,
    backgroundColor,
    titleColor,
    subTitleColor,
    endTime,
    avatar,
    avatarBackgroundColor,
    className
}) => {
    const {remainingTime} = useTimer(endTime);

    return (
        <div className={classnames("order-status-view-container", backgroundColor, className)}>
            <ConfigProvider direction="rtl">
                <div className="order-status-view-content-container">
                    {!!avatar && <div className={classnames("avatar-container", avatarBackgroundColor)}>{avatar}</div>}
                    <div className="order-status-view-description-container">
                        <div className="order-status-view-title-container">
                            <span className={classnames("order-status-view-title", titleColor)}>{title}</span>
                            {!!endTime && (
                                <div className={classnames("order-status-view-timer", titleColor)}>{remainingTime}</div>
                            )}
                        </div>
                        {!!subTitle && (
                            <span className={classnames("order-status-view-sib-title", subTitleColor)}>{subTitle}</span>
                        )}
                        {!!description?.length && (
                            <div className="order-status-view-sib-description">
                                {description.map((paragraph, index) => (
                                    <p className={classnames("description-line", {isEmpty: !paragraph})} key={index}>
                                        {paragraph}
                                    </p>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                {!!actions?.length && <OrderActions actions={actions} />}
            </ConfigProvider>
        </div>
    );
};
