export const ForceOccasionPolicyTypes = {
    RANGE_POLICY: "rangePolicy",
    OCCASION_POLICY: "occasionPolicy"
};

export const HappinessCalculatorRoutes = {
    SUMMARY: "/happiness-calculator/plan/summary",
    PLAN: "/happiness-calculator/plan/edit"
};

export const DynamicCategoriesIds = {
    HappyHours: "happyhours",
    OngoingWelfare: "ongoingwelfare",
    Gifts: "gifts"
};

export const ConsumptionOccasionType = {
    Holiday: "holiday",
    LifeCycle: "lifeCycle"
};

export const ConsumptionOccasionTypeToName = {
    [ConsumptionOccasionType.Holiday]: "חגים ומועדים",
    [ConsumptionOccasionType.LifeCycle]: "חיי עובד"
};

export const DefaultMaxOccasionsInColumn = 6;

export const MinCategoryBarWidth = 7;

export const TechnologyPrice = 2;

export const TechnologyId = "technology";

export const WeeksInAMonth = 52 / 12;

export const Frequency = {
    Weekly: {
        id: "Weekly",
        momentTimeName: "week",
        name: "שבוע",
        pluralName: "שבועות",
        ratio: WeeksInAMonth
    },
    Monthly: {
        id: "Monthly",
        name: "חודש",
        pluralName: "חודשים",
        ratio: 1
    },
    Quarterly: {
        id: "Quarterly",
        name: "רבעון",
        pluralName: "רבעונים",
        ratio: 1 / 3
    },
    Yearly: {
        id: "Yearly",
        name: "שנה",
        ratio: 1 / 12
    }
};

export const OccasionPriceType = {
    byParticipant: "byParticipant",
    byGroup: "byGroup"
};

export const OccasionPriceTypeDisplayName = {
    [OccasionPriceType.byParticipant]: "למשתתף",
    [OccasionPriceType.byGroup]: "לקבוצה"
};
