import {eventByDate} from "./EventByDate";
import {useState} from "react";
import {HttpClient} from "../http/HttpClient";
import {GoogleAnalytics} from "../GoogleAnalytics";
import {Divider, message} from "antd";
import {FormTextArea} from "../components/form";
import {toDateString, toTimeString} from "./EventInfoModal";
import {AutoDirectionProvider} from "../AutoDirectionProvider";
import PageTitleHeader, {PageSubTitleLabel} from "../components/PageTitle";
import React from "react";
import {Link} from "react-router-dom";
import {truncate} from "lodash";

const providerTitle = (me, event, providerApproved) => {
    const {eventId, companyName, providerId, providerFirstName, providerLastName, sessionType, providerBrand} = event;

    const providerName =
        providerBrand ||
        (providerFirstName ? `${providerFirstName}${providerLastName ? ` ${providerLastName}` : ""}` : "");

    const bySessionType = () => {
        if (sessionType === "company") {
            return `by ${providerName}`;
        } else {
            return event.v2
                ? `${providerName}`
                : `with ${providerName}${event.appointmentBased ? " (for session details click here)" : ""}`;
        }
    };

    switch (me.type) {
        case "provider":
            return `at ${companyName}`;
        case "employee":
            return bySessionType();
        default:
            return providerApproved && !event.v2 ? (
                <Link to={`/dashboard/schedule/events/${eventId}/providers/${providerId}`}>with {providerName}</Link>
            ) : (
                bySessionType()
            );
    }
};

export const EventInfoTitle = ({
    me,
    event,
    currentStart,
    currentEnd,
    titleStyle,
    subTitleStyle,
    showBack,
    onBack,
    providerApproved
}) => {
    const {serviceName, productName, sessionName, appointmentBased, minTime, maxTime} = eventByDate(
        event,
        currentStart
    );
    const [editMode, setEditMode] = useState(false);
    const [editName, setEditName] = useState(serviceName || sessionName);

    const startDate = toDateString(event, currentStart, "dddd, MMMM D");
    const startTime = toTimeString(event, currentStart, "h:mm");
    const endTime = toTimeString(event, currentEnd, "h:mm A");

    const editableTitle = event.customSession && me.type === "company";

    const saveSessionName = () => {
        event.sessionName = editName;
        setEditMode(false);
        Promise.resolve().then(async () => {
            try {
                await HttpClient.post(`/api/events/${event.eventId}/name`, {sessionName: editName});
                GoogleAnalytics.event("Company Schedule", "Edit Internal Session Name", event.eventId);
            } catch (e) {
                message.error("Something went wrong, please try again later.");
            }
        });
    };

    const saveChanges = e => {
        if (e && e.key === "Enter") {
            saveSessionName();
        } else if (e && e.key === "Escape") {
            exitEditMode();
        }
    };

    const exitEditMode = () => {
        setEditName(event.sessionName);
        setEditMode(false);
    };

    const enterEditMode = () => {
        if (editableTitle) {
            setEditName(event.sessionName);
            setEditMode(true);
        }
    };

    return (
        <>
            {editMode ? (
                <FormTextArea
                    ref={elem => elem && elem.focus()}
                    style={{
                        margin: 30,
                        marginBottom: 20,
                        textAlign: "center",
                        fontSize: 28,
                        fontWeight: 700,
                        color: "#273444",
                        maxWidth: 480,
                        ...titleStyle
                    }}
                    autoSize={{minRows: 2, maxRows: 4}}
                    defaultValue={editName}
                    value={editName}
                    onChange={e => setEditName(e.target.value)}
                    onBlur={exitEditMode}
                    onKeyDown={saveChanges}
                />
            ) : (
                <PageTitleHeader
                    className={"wb-event-info-title" + (editableTitle ? " hover-underline" : "")}
                    style={{marginTop: 10, marginBottom: 0, ...titleStyle}}
                    subTitleStyle={{marginBottom: 5, maxHeight: "max-content"}}
                    showBack={showBack}
                    onBack={onBack}
                    onClick={enterEditMode}>
                    {serviceName || sessionName}
                </PageTitleHeader>
            )}
            {productName && productName !== "Anything works for me" ? (
                <AutoDirectionProvider text={productName} style={{width: "100%"}}>
                    <PageSubTitleLabel style={{color: "#1260D1", fontSize: 24, fontWeight: 700}}>
                        {truncate(productName, {separator: " ", length: 90})}
                    </PageSubTitleLabel>
                </AutoDirectionProvider>
            ) : null}
            <PageSubTitleLabel style={{color: "#1260D1", fontSize: 20, ...subTitleStyle}}>
                {providerTitle(me, event, providerApproved)}
            </PageSubTitleLabel>
            <PageSubTitleLabel>
                {`${startDate}`}
                <Divider type="vertical" style={{height: 20}} />
                {appointmentBased ? `${minTime} - ${maxTime}` : `${startTime} - ${endTime}`}
            </PageSubTitleLabel>
        </>
    );
};
