import {groupBy} from "lodash";
import {createWorkbook, createWorksheet, excelToBlob} from "../../utils/excelUtils.js";
import {momentHebrewFormat} from "../../utils/DateFormat";
import {reduceVAT} from "../../utils/calculationUtils";
import {AccountingDocumentType} from "../../provider/ProviderAccountingDocumentModal/consts";
import {InvoicesStatusToNameAndColor} from "../../provider/ProviderManageInvoices/consts";
import {getSummaryData} from "../../provider/ProviderManageInvoices/utils.jsx";
import {ProviderDealerTypes} from "../upload/AdminUploadUtils.jsx";

export const exportTableToExcel = async ([currentMonthInvoice, oldInvoices], month) => {
    const workbook = createWorkbook();
    const invoicesByProviderNetPaymentTerms = groupBy(currentMonthInvoice, invoice => invoice.providerNetPaymentTerms);

    const providerNetPaymentTermsSheets = Object.keys(invoicesByProviderNetPaymentTerms).map(netPaymentTerms => ({
        worksheet: createWorksheet(workbook, `שוטף +${netPaymentTerms}`),
        invoices: invoicesByProviderNetPaymentTerms[netPaymentTerms]
    }));

    const sheets = [
        {
            worksheet: createWorksheet(workbook, `חשבוניות - ${momentHebrewFormat(month, "MMMM YYYY")}`),
            invoices: currentMonthInvoice
        },
        {
            worksheet: createWorksheet(workbook, "חשבוניות לתשלום - חוב בפיגור"),
            invoices: oldInvoices
        },
        ...(providerNetPaymentTermsSheets.length ? providerNetPaymentTermsSheets : [])
    ];

    sheets.forEach(({worksheet, invoices}) => {
        addColumns(worksheet);
        addRows(worksheet, invoices);
    });

    return await excelToBlob(workbook);
};

const addColumns = sheet => {
    sheet.columns = [
        {header: "שם השותף", key: "providerName"},
        {header: "חודש", key: "month"},
        {header: "סוג חשבונית", key: "invoiceType"},
        {header: "מס׳ חשבונית", key: "invoiceNumber"},
        {header: "סכום ₪ (כולל מעמ)", key: "price"},
        {header: "סכום ₪ (לפני מעמ)", key: "priceWithoutVAT"},
        {header: "איש קשר", key: "contactName"},
        {header: "טלפון", key: "phone"},
        {header: "מייל", key: "email"},
        {header: "סטאטוס", key: "status"},
        {header: "תנאי שוטף", key: "providerNetPaymentTerms"}
    ];
};

const addRows = (sheet, invoices) => {
    const rows = invoices.reduce((allRows, invoice) => {
        const invoiceStatus = InvoicesStatusToNameAndColor[invoice?.status.id]?.name;

        return [
            ...allRows,
            [
                invoice.providerName,
                momentHebrewFormat(invoice.month, "MMMM YYYY"),
                AccountingDocumentType[invoice.invoiceType],
                invoice.invoiceNumber,
                invoice.price,
                reduceVAT(invoice.price, invoice.vat),
                invoice.providerPhone,
                invoice.providerContactName,
                invoice.providerEmail,
                invoiceStatus,
                invoice.providerNetPaymentTerms
            ]
        ];
    }, []);

    sheet.addRows(rows);
};
