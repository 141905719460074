import React, {useEffect, useState} from "react";
import {FormModal} from "../../../components";
import {NOOP} from "../../../utils/NOOP";
import {useRequest} from "../../../utils/hooks";
import {
    CompanyHomePageRedirectUserTypes,
    CompanyHomePageRedirectUserTypesToHebrew,
    CompanyHomePageTypes,
    CompanyHomePageTypesToHebrew
} from "./consts.js";
import {set} from "lodash";
import {Button} from "antd";

export const CompanyPageLayoutModal = ({visible, onSave = NOOP, onClose = NOOP, company = null}) => {
    const [pageLayoutsFromServer] = useRequest("/api/pageLayout", "GET", null, [], !!visible, null, []);
    const [formValues, setFormValues] = useState({});

    useEffect(() => {
        if (visible && company) {
            setFormValues(company);
        } else {
            setFormValues({});
        }
    }, [company, visible]);

    return (
        <>
            <FormModal
                header={"הגדרת עמוד בית ראשי לחברה"}
                subTitle={"בעת הגדרת עמוד בית ראשי לחברה כל תתי האדמין של החברה ינותבו לעמוד הקרוס/אתר הבחירה שתבחרו"}
                visible={visible}
                content={formValues}
                onUpdate={(_, changedField) => {
                    const [key, value] = Object.entries(changedField)[0];
                    setFormValues(currentFormValues => set({...currentFormValues}, key, value));
                }}
                fields={[
                    {
                        name: "_company",
                        label: "שם החברה",
                        type: "custom",
                        customComponent: <span>{company?.name}</span>
                    },
                    {
                        name: "pageLayoutType",
                        label: "בחר סוג אתר",
                        type: "select",
                        optional: true,
                        placeholder: CompanyHomePageTypesToHebrew?.[company?.pageLayoutType] ?? "",
                        options: Object.keys(CompanyHomePageTypes).map(key => ({
                            label: CompanyHomePageTypesToHebrew[key],
                            name: key
                        }))
                    },
                    ...(formValues?.pageLayoutType === CompanyHomePageTypes.pageLayout
                        ? [
                              {
                                  name: "pageLayoutId",
                                  label: "בחר עמוד קרוס חדש לחברה",
                                  optional: true,
                                  disabled: formValues?.pageLayoutType !== CompanyHomePageTypes.pageLayout,
                                  type: "select",
                                  placeholder:
                                      pageLayoutsFromServer.find(
                                          ({pageLayoutId}) => pageLayoutId === company?.pageLayoutId
                                      )?.name ?? company?.pageLayoutId,
                                  options: pageLayoutsFromServer.map(({pageLayoutId, name}) => ({
                                      label: name,
                                      name: pageLayoutId
                                  }))
                              }
                          ]
                        : []),
                    ...(formValues?.pageLayoutType === CompanyHomePageTypes.selectPage
                        ? [
                              {
                                  name: "selectPageId",
                                  label: "הכנס מזהה אתר בחירה חדש לחברה",
                                  disabled: formValues?.pageLayoutType !== CompanyHomePageTypes.selectPage,
                                  optional: true,
                                  placeholder: company?.selectPageId
                              }
                          ]
                        : []),
                    {
                        name: "pageLayoutRedirectUserTypes",
                        label: "את מי לנתב לאתר בחירה/קרוס של החברה?",
                        info: "סוג היוזר שתבחרו מהרשימה ינותב לאתר בחירה /קרוס שהגדרת כדף בית",
                        optional: false,
                        type: "select",
                        multiple: true,
                        placeholder: (company?.pageLayoutRedirectUserTypes ?? [])
                            .map(key => CompanyHomePageRedirectUserTypesToHebrew[key])
                            .join(","),
                        options: Object.keys(CompanyHomePageRedirectUserTypes).map(key => ({
                            label: CompanyHomePageRedirectUserTypesToHebrew[key],
                            name: key
                        }))
                    },
                    {
                        name: "_resetCompanyPageLayoutSettings",
                        label: "איפוס הגדרות לברירת מחדל",
                        type: "custom",
                        customComponent: (
                            <Button
                                onClick={() =>
                                    onSave({
                                        pageLayoutType: null,
                                        pageLayoutId: null,
                                        selectPageId: null,
                                        pageLayoutRedirectUserTypes: null,
                                        companyId: company.companyId
                                    })
                                }
                                danger={true}>
                                אפס ושמור הגדרות
                            </Button>
                        )
                    }
                ]}
                onSave={formValues =>
                    onSave({
                        ...formValues,
                        companyId: company.companyId
                    })
                }
                onClose={onClose}
            />
        </>
    );
};
