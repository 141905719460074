import React from "react";
import {ConfigProvider, Divider} from "antd";
import {FilterButtons} from "./FilterButtons.jsx";
import "./filter-buttons-container.css";
import {TagFilters} from "../tagsFilter/TagFilters.jsx";

export const FilterButtonContainer = ({
    onSortSelected,
    searchSorterType,
    onDisplaySelected,
    displaySelected,
    disabledSorterButton
}) => (
    <ConfigProvider direction="rlt">
        <div className="filter-button-container">
            <div className="filter-buttons">
                <FilterButtons
                    onSortSelected={onSortSelected}
                    searchSorterType={searchSorterType}
                    onDisplaySelected={onDisplaySelected}
                    displaySelected={displaySelected}
                    disabledSorterButton={disabledSorterButton}
                />
            </div>
            <Divider className="button-filter-divider" type="vertical" />
            <div className="tag-filter-container">
                <TagFilters />
            </div>
        </div>
    </ConfigProvider>
);
