import React from "react";

import boost from "./boost.png";
import develop from "./develop.png";
import health from "./health.png";
import leader from "./leader.png";
import productivity from "./productivity.png";
import recognition from "./recognition.png";
import team from "./team.png";
import wellbeing from "./wellbeing.png";
import worklife from "./worklife.png";
import discount from "./discount-icon.png";
import groupActivity from "./group-activity-icon.png";
import happyHour from "./happy-hour-icon.png";
import privateAppointments from "./private-appointments-icon.png";
import product from "./products-icon.png";

import hand from "./hand.png";

export const boostIcon = boost;
export const developIcon = develop;
export const healthIcon = health;
export const leaderIcon = leader;
export const productivityIcon = productivity;
export const recognitionIcon = recognition;
export const teamIcon = team;
export const wellbeingIcon = wellbeing;
export const worklifeIcon = worklife;
export const discountIcon = discount;
export const groupActivityIcon = groupActivity;
export const happyHourIcon = happyHour;
export const privateAppointmentsIcon = privateAppointments;
export const productIcon = product;
export const handIcon = hand;
