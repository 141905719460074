import React, {useCallback, useMemo, useState} from "react";
import {message} from "antd";
import "../../admin/AdminOrders/admin-orders.css";
import {NotifyAboutAdminOrderUpdateModal} from "../../orders/orderModals/NotifyAboutAdminOrderUpdateModal";
import {HttpClient} from "../../http/HttpClient";
import EditCreatedOrderDate from "../../orders/orderModals/EditCreatedOrderDate";
import {useRequest} from "../../utils/hooks";
import {OrderFormPanelKeys} from "../../marketplace/consts.jsx";
import {isOrderHasShippingProvider} from "../../event/utils.jsx";
import {ActivityLocationTypes} from "../../consts.js";
import {HomeDeliveryUploadMethod} from "../../marketplace/OrderLocationInput/consts.jsx";
import {extractVatPercent} from "../../utils/calculationUtils.jsx";
import {EditOrderView} from "../../orders/EditOrderView/EditOrderView.jsx";
import {StringBuilder} from "../../AppUtils.js";
import {Images} from "../../images/Images.jsx";
import {GenericModal} from "../../components/index.jsx";
import {openWindow} from "../../http/WindowOpener.jsx";
import {isEmpty} from "lodash";

export const priceSectionLines = [
    {
        title: "מחיר הזמנה",
        key: "offeredPrice"
    },
    {
        title: "מחיר משלוח",
        key: "deliveryPrice"
    },
    {
        title: "הנחת קוד קופון",
        key: "couponDiscountAmount"
    },
    {
        title: "הנחה כללית",
        key: "companyDiscountAmount"
    },
    {
        title: "זיכוי",
        key: "providerDiscount"
    },
    {
        title: "עמלה",
        key: "commission",
        suffix: "% לפני מע״מ"
    },
    {
        title: "עמלת שילוח",
        key: "deliveryCommission",
        suffix: "% לפני מע״מ"
    },
    {
        title: "טיפ",
        key: "tip",
        disabled: "יש לעדכן טיפ דרך הטופס הזמנה",
        hideIfEmpty: true
    },
    {
        title: "מע״מ",
        key: "vat",
        disabled: "לא ניתן לערוך מע״מ",
        format: value => extractVatPercent(value)
    }
];

export const AdminEditOrderPage = ({match}) => {
    const [notifyAboutOrderUpdateModalVisibility, setNotifyAboutOrderUpdateModalVisibility] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [updateOrder, setUpdateOrder] = useState({});
    const [orderChangesSentToApproveModalVisibility, setOrderChangesSentToApproveModalVisibility] = useState(false);

    const [order] = useRequest(
        `/api/events/${match?.params?.eventId}/orderInfo`,
        "GET",
        null,
        [],
        !!match?.params?.eventId,
        null,
        {}
    );

    const [provider, isLoadingProvider] = useRequest(
        `/admin/api/providers/${order?.providerId}`,
        "get",
        null,
        [order],
        !!order?.providerId
    );

    const onSaveOrder = useCallback(
        async whoToSendUpdateEmail => {
            setNotifyAboutOrderUpdateModalVisibility(false);
            const {vat, ...restUpdatedOrderValues} = updateOrder;
            const {error} = await HttpClient.safePost(
                `/admin/api/transactions/updateOrder?updatedAt=${order.updatedAt}`,
                {
                    order: restUpdatedOrderValues,
                    whoToSendUpdateEmail
                }
            );

            if (error) {
                message.error({
                    content: new StringBuilder("שמירת השינויים בהזמנה נכשלה").append(`(${error})`).toString(),
                    duration: 5
                });
                return;
            }

            setOrderChangesSentToApproveModalVisibility(true);
        },
        [formValues, updateOrder, order?.updatedAt]
    );

    const onEditOrderModalBookingTimePanel = useCallback(
        createdAt => {
            setFormValues(prev => ({...prev, createdAt}));
        },
        [formValues]
    );

    const onEditOrderModalSave = useCallback(
        updatedOrder => {
            if (isNaN(parseFloat(formValues.commission))) {
                message.error("לעסקה אין עמלה, יש לנסות לעלות ולהחזיר את כמות המוצרים ולראות אם העמלה מתעדכנת");
                return;
            }

            setUpdateOrder(updatedOrder);
            setNotifyAboutOrderUpdateModalVisibility(true);
        },
        [formValues]
    );

    const isExcelHomeDelivery = useMemo(
        () =>
            order?.chosenActivityLocation === ActivityLocationTypes.homeDelivery &&
            order?.homeDeliveryUploadMethod === HomeDeliveryUploadMethod.excel,
        [order?.chosenActivityLocation, order?.homeDeliveryUploadMethod]
    );

    const onCancel = useCallback(() => {
        setFormValues({});
    }, []);

    return (
        <div className="edit-order-container">
            {!isEmpty(order) && (
                <EditOrderView
                    order={order}
                    onCancel={onCancel}
                    onSave={onEditOrderModalSave}
                    priceSectionLines={priceSectionLines}
                    extraOrderFormFields={
                        <EditCreatedOrderDate date={order?.createdAt} onChange={onEditOrderModalBookingTimePanel} />
                    }
                    formValues={formValues}
                    setFormValues={setFormValues}
                    provider={provider}
                    isLoadingProvider={isLoadingProvider}
                    adminMode={true}
                    disabledFields={
                        isOrderHasShippingProvider(order) && isExcelHomeDelivery ? [OrderFormPanelKeys.Location] : []
                    }
                />
            )}
            <NotifyAboutAdminOrderUpdateModal
                visible={notifyAboutOrderUpdateModalVisibility}
                onSave={onSaveOrder}
                onClose={() => setNotifyAboutOrderUpdateModalVisibility(false)}
            />

            <GenericModal
                title={new StringBuilder("השינויים שביצעת נשלחו").append(`ל${provider?.businessName}`).toString()}
                className="company-edit-order-sure-page"
                text={["הזמנה עודכנה בהצלחה"]}
                image={Images.handshake}
                closeable={true}
                visible={orderChangesSentToApproveModalVisibility}
                onClose={() => setOrderChangesSentToApproveModalVisibility(false)}
                options={[
                    {
                        id: "accept",
                        onClick: () => openWindow(`/events/${order?.eventId}/orderV2`),
                        text: "לצפייה בהזמנה החדשה"
                    }
                ]}
            />
        </div>
    );
};
