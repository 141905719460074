import {SearchInput} from "../../components/Search";
import {RoundedTable} from "../../components/RoundedTable";
import React, {useContext, useState} from "react";
import {Avatar, Image} from "antd";
import {truncate} from "lodash";
import {AppContext} from "../../AppContext";
import {GoogleAnalyticsIcon} from "../../icons";
import DataStudioIcon from "../../images/icons/data_studio.svg";

const columns = [
    {
        title: "#",
        key: "#",
        width: 70,
        render(_, __, idx) {
            return `${idx + 1}`;
        }
    },
    {
        title: "EVENT",
        key: "eventName",
        width: 300,
        render(_, {eventName, recurrence, backgroundColor}) {
            return (
                <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                    <Avatar style={{backgroundColor, verticalAlign: "middle"}} size="large">
                        {eventName.match(/^\d/) ? parseInt(eventName) : eventName.charAt(0).toUpperCase()}
                    </Avatar>
                    <div style={{display: "flex", flexDirection: "column", marginLeft: 20}}>
                        <label
                            style={{
                                color: "var(--secondary-color)",
                                fontSize: 14,
                                fontWeight: 500,
                                maxHeight: 50,
                                overflow: "hidden",
                                maxWidth: 200
                            }}>
                            {truncate(eventName, {
                                separator: " ",
                                length: 30
                            })}
                        </label>
                        <label style={{color: "#747F92", fontSize: 12, fontWeight: 400}}>{recurrence}</label>
                    </div>
                </div>
            );
        }
    },
    {
        title: "PROFESSIONAL",
        dataIndex: "professionalName",
        key: "professionalName",
        render(professionalName) {
            return <label style={{color: "#747F92", fontSize: 15, fontWeight: 400}}>{professionalName}</label>;
        }
    },
    {
        title: "REGISTERED",
        dataIndex: "registers",
        key: "registers",
        render(registers) {
            return <label style={{color: "#747F92", fontSize: 15, fontWeight: 400}}>{registers.length}</label>;
        }
    }
];

const DataStudioDashboardRedirect = ({company}) => (
    <a
        target="_blank"
        href={company.externalDataStudioLink}
        style={{
            display: "flex",
            alignItems: "center",
            paddingRight: 25
        }}>
        <Image src={DataStudioIcon} width={30} height={30} />
        <span
            style={{
                fontSize: 16,
                color: "var(--secondary-color)",
                marginLeft: 5
            }}>
            Google Data Report
        </span>
    </a>
);

const GoogleAnalyticsDashboardRedirect = ({company}) => (
    <a
        target="_blank"
        href={company.externalAnalyticsLink}
        style={{
            display: "flex",
            alignItems: "center",
            paddingRight: 5
        }}>
        <GoogleAnalyticsIcon style={{marginRight: 5}} />
        <span
            style={{
                fontSize: 16,
                color: "var(--secondary-color)"
            }}>
            Google Analytics Report
        </span>
    </a>
);

export const CompanyEventInsights = ({data}) => {
    const {company} = useContext(AppContext);
    const [term, setTerm] = useState("");

    const filter = term => {
        setTerm(term);
    };

    let events = data && data.events ? data.events : [];
    if (events.length > 0) {
        events = events.filter(evt => {
            return `${evt.eventName} ${evt.professionalName}`.toLowerCase().indexOf(term.toLowerCase()) >= 0;
        });
    }

    return (
        <div style={{maxWidth: 1140, marginLeft: 20, marginRight: 20, marginTop: 25}}>
            <div style={{marginBottom: 30, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <SearchInput
                    style={{maxWidth: 500, width: "100%"}}
                    onChange={evt => filter(evt.target.value)}
                    placeholder="Search event"
                />
                <div style={{display: "flex", flexDirection: "row"}}>
                    {company && company.externalDataStudioLink ? (
                        <DataStudioDashboardRedirect company={company} />
                    ) : null}
                    {company && company.externalAnalyticsLink ? (
                        <GoogleAnalyticsDashboardRedirect company={company} />
                    ) : null}
                </div>
            </div>
            <RoundedTable
                rowKey={({eventId}) => eventId}
                scroll={{x: "max-content", y: 600}}
                loading={data === null}
                pagination={false}
                dataSource={events}
                columns={columns}
            />
        </div>
    );
};
