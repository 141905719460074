import React, {useMemo} from "react";
import {ConfigProvider, Skeleton} from "antd";
import {EventGalleryItem} from "./EventGalleryItem";
import {ColumnsNumber, PlaceholdersHeights} from "./consts";
import {RenderOnlyInViewWrapper} from "../../components";
import {NOOP} from "../../utils/NOOP";
import "./events-gallery.css";
import {keyBy} from "lodash";

const EventsGallery = ({history, allImages, likedImages, products, setLikeImages, log, onLastImageReached = NOOP}) => {
    const imagesColumns = useMemo(() => {
        if (!allImages) {
            return null;
        }

        return allImages.reduce(
            (acc, image, index) => {
                const like = likedImages.find(({targetId}) => {
                    return targetId === image.image.id + ":" + image.productId;
                });
                acc[index % ColumnsNumber].push({...image, like});
                return acc;
            },
            Array.from({length: ColumnsNumber}).map(() => [])
        );
    }, [allImages, likedImages]);

    const productsById = useMemo(() => keyBy(products, product => product.service.productId), [products]);

    return (
        <ConfigProvider direction="rtl">
            <div className="events-gallery-images-container">
                {imagesColumns?.map((images, columnIndex) => (
                    <div className="events-gallery-images-column" key={images[0]?.image.id}>
                        {images.map(({productId, image, like}, index) => (
                            <div key={image.id + index}>
                                <RenderOnlyInViewWrapper
                                    onEnterView={
                                        index === images.length - 1 && columnIndex === imagesColumns.length - 1
                                            ? () => {
                                                  onLastImageReached();
                                              }
                                            : NOOP
                                    }
                                    placeholder={
                                        <div className="events-gallery-image-placeholder">
                                            <Skeleton.Input
                                                style={{
                                                    height: PlaceholdersHeights[(columnIndex + index) % 2]
                                                }}
                                                active={true}
                                            />
                                        </div>
                                    }>
                                    <EventGalleryItem
                                        history={history}
                                        image={image}
                                        log={log}
                                        productId={productId}
                                        product={productsById[productId]}
                                        like={like}
                                        openProductsInNewTab={true}
                                        setLikeImages={setLikeImages}
                                    />
                                </RenderOnlyInViewWrapper>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </ConfigProvider>
    );
};

export default EventsGallery;
