import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {ConfigProvider, message} from "antd";
import BlockProductsForCompanyModal from "./BlockProductsForCompanyModal";
import {LoadAdminExcelModal} from "./LoadAdminExcelModal";
import {AdminBudgetModal} from "./AdminBudgetModal";
import {AdminCompanyBranding} from "./AdminCompanyBranding";
import {AdminUpdateCompanyDetailsModal} from "./AdminUpdateCompanyDetailsModal";
import {exportTableToExcel} from "./excelExporter";
import {getCompanySizeTier} from "./utils";
import columns from "./columns";
import {CompanyPageLayoutModal} from "./CompanyPageLayoutModal";
import {CompanyStatisticsLabel} from "./CompanyStatisticsLabel";
import {TableDateSearch} from "../components";
import {EditableColumnsTable, PageTitleHeader, RoundedTable, SquareButton} from "../../components";
import {HttpClient} from "../../http/HttpClient";
import {useDebounceState, useRequest} from "../../utils/hooks";
import {copyPathToClipboard} from "../../utils/copyToClipboard";
import {AppContext} from "../../AppContext";
import "./admin-companies.css";
import {AddNewCompanyDomainModal} from "./AddNewCompanyDomainModal/AddNewCompanyDomainModal";
import {AdminSubAdminManagerModal} from "../../company/CompanyAccountProfile/AdminSubAdminManagerModal";
import {StringBuilder} from "../../AppUtils.js";
import {TableDateSearchProvider} from "../Context/TableDateSearchContext.jsx";

export const DEFAULT_PAGE = 100;

export const AdminCompanies = () => {
    const {me} = useContext(AppContext);
    const [loadingChangeIgnoreData, setLoadingChangeIgnoreData] = useState(null);
    const [searchDate, setSearchDate] = useState([]);
    const [searchInput, setSearchInput] = useDebounceState(null, 800);
    const [editCompanyDetails, setEditCompanyDetails] = useState(null);
    const [editCompanyBlockedProducts, setEditCompanyBlockedProducts] = useState(null);
    const [brandCompany, setBrandCompany] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [companyBudgetModalInfo, setCompanyBudgetModalInfo] = useState(null);
    const [loadSubAdminsExcelModal, setLoadSubAdminsExcelModal] = useState(null);
    const [loadingUpdateCSM, setLoadingUpdateCSM] = useState(null);
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [companyPageLayoutModal, setCompanyPageLayoutModal] = useState(null);
    const [addNewCompanyDomainModal, setAddNewCompanyDomainModal] = useState(null);
    const [viewSubAdminModal, setViewSubAdminModal] = useState(null);
    const [loadAdvanceStats, setLoadAdvanceStats] = useState(false);

    const [companies, isLoading, _, fetchCompanies] = useRequest("/admin/api/companies");
    const [companiesOrderStatistics, isLoadingCompaniesOrdersStatistics, errorOrdersStatistics] = useRequest(
        "/admin/api/statistics/getAllCompaniesOrderStatistics",
        "GET",
        null,
        [loadAdvanceStats],
        !!loadAdvanceStats
    );
    const [companyCustomerStats, isLoadingCompaniesStatistics] = useRequest(
        "/admin/api/statistics/getAllCompaniesStatistics",
        "GET",
        null,
        [loadAdvanceStats],
        !!loadAdvanceStats
    );
    const [masterBudgets, loadingMasterBudgets, __, fetchMasterBudgets] = useRequest("/admin/api/budget/allPositive");
    const [systemCSMs] = useRequest("/admin/api/users/csm");

    useEffect(() => {
        let newFilteredCompanies = [...(companies ?? [])];

        const [start, end] = searchDate;

        if (start && end) {
            const [start, end] = searchDate;
            newFilteredCompanies = newFilteredCompanies.filter(
                company => company.createdAt >= start && company.createdAt <= end
            );
        }

        if (searchInput) {
            newFilteredCompanies = newFilteredCompanies.filter(company =>
                `${company.name}${company.contact}${company.phone}${company.contactName}${company.businessNumber}`
                    .toLowerCase()
                    .includes(searchInput.toLowerCase())
            );
        }

        setFilteredCompanies(newFilteredCompanies);
    }, [companies, searchDate, searchInput]);

    const filteredCompanyBudgets = useMemo(() => {
        if (!masterBudgets || !filteredCompanies.length) return [];

        return filteredCompanies.reduce((acc, company) => {
            const budget = masterBudgets[company.companyId];
            if (!company?.payOnOrderBudget && budget?.totalBudget) {
                acc.push(budget);
            }
            return acc;
        }, []);
    }, [filteredCompanies, masterBudgets]);

    useEffect(() => {
        if (errorOrdersStatistics) {
            message.error(
                "קרתה שגיאה בשליפת דירוגי החברות, יש לנסות לרפרש את העמוד. אם הבעיה ממשיכה נא לפנות לצוות הפיתוח"
            );
        }
    }, [errorOrdersStatistics]);

    const onIgnoreDataChange = useCallback(
        async (companyId, ignoreData) => {
            setLoadingChangeIgnoreData(companyId);
            try {
                await HttpClient.post(`/admin/api/companies/${companyId}/ignoreData`, {ignoreData});
                companies.find(company => company.companyId === companyId).ignoreData = ignoreData;
            } catch (e) {
                message.error(e.message, 5);
            }
            setLoadingChangeIgnoreData(null);
        },
        [companies]
    );

    const deleteCompany = useCallback(
        async ({companyId}) => {
            const {error} = await HttpClient.safeDelete(`/admin/api/companies/${companyId}`);

            if (error) {
                message.error(error.message, 3);
                return;
            }

            fetchCompanies();
        },
        [fetchCompanies]
    );

    const onSelectCSM = useCallback(
        async (newCSM, companyId) => {
            setLoadingUpdateCSM(companyId);
            const currentCSMs = filteredCompanies.find(company => company.companyId === companyId)?.assignedCSMs ?? [];

            const [firstCurrentCSM] = currentCSMs;

            if (firstCurrentCSM?.userId === newCSM.userId) {
                setLoadingUpdateCSM(null);
                message.error("מנהלת הלקוח שנבחרה כבר קיימת על החברה הנוכחית");
                return;
            }

            const updatedCompany = await HttpClient.safePost("/admin/api/companies/update", {
                companyId,
                assignedCSMs: [newCSM]
            });

            if (updatedCompany.error) {
                message.error("שגיאה בעידכון CSM");
                setLoadingUpdateCSM(null);
                return;
            }

            innerUpdateCompany(updatedCompany);

            setLoadingUpdateCSM(null);
        },
        [filteredCompanies]
    );

    const innerUpdateCompany = useCallback(updatedCompany => {
        setFilteredCompanies(prevCompanies => {
            const index = prevCompanies.findIndex(company => company.companyId === updatedCompany.companyId);
            prevCompanies.splice(index, 1, updatedCompany);
            return [...prevCompanies];
        });
    }, []);

    useEffect(() => {
        if (companies && companiesOrderStatistics) {
            const res = companies?.reduce((acc, company) => {
                const companyStats = companiesOrderStatistics[company.companyId];

                if (!companyStats) {
                    return acc;
                }

                const companyTier = getCompanySizeTier(Number.parseInt(company.employeesCount));

                acc[companyTier] = acc[companyTier] ?? {numberOfOrders: 0, totalPrice: 0, companiesAmount: 0};

                acc[companyTier].numberOfOrders += companyStats.numberOfOrders;
                acc[companyTier].totalPrice += companyStats.totalPrice;
                acc[companyTier].companiesAmount++;

                return acc;
            }, {});
        }
    }, [companies, companiesOrderStatistics]);

    const changeLogo = useCallback(company => {
        setBrandCompany(company);
    }, []);

    const onResendCompanySignupEmail = useCallback(async ({companyId}) => {
        const company = await HttpClient.safePost(`/admin/api/companies/${companyId}/resend`);

        const {error} = company;

        if (error) {
            message.error(error.message, 5);
            return;
        }

        message.success(
            <span>
                Signup email was sent to <strong style={{marginLeft: 3}}>{company.contact}</strong>
            </span>,
            5
        );
    }, []);

    const copyInviteLink = useCallback(({companyId, companyName}) => {
        copyPathToClipboard(`/companies/${companyId}/signup?cn=${btoa(companyName)}`, "קישור הועתק בהצלחה");
    }, []);

    const cancelBudgetRequest = useCallback(
        async ({companyId}) => {
            await HttpClient.delete(`/admin/api/cancelBudgetRequest/${companyId}`);
            fetchCompanies();
        },
        [fetchCompanies]
    );

    const setupPageLayoutForCompany = useCallback(company => {
        setCompanyPageLayoutModal(company);
    }, []);

    const updateCompanyPageLayoutId = useCallback(
        async ({companyId, pageLayoutId, pageLayoutType, selectPageId, pageLayoutRedirectUserTypes}) => {
            const updatedCompany = await HttpClient.safePost("/admin/api/companies/update", {
                companyId,
                pageLayoutId,
                pageLayoutType,
                selectPageId,
                pageLayoutRedirectUserTypes
            });

            if (updatedCompany.error) {
                message.error("שגיאה בעת עידכון דף בית לחברה במערכת");
                return;
            }

            innerUpdateCompany(updatedCompany);
            setCompanyPageLayoutModal(null);

            if (pageLayoutId === null && selectPageId === null) {
                message.success("דף הבית אותחל לעמוד הראשי במערכת");
            } else {
                message.success("דף הבית לחברה הוגדר בהצלחה!");
            }
        },
        []
    );

    const onNewCompanyDomainSave = useCallback(
        async (newCompanyDomain, company) => {
            const updatedCompany = await HttpClient.safePost("/admin/api/companies/update", {
                companyId: company.companyId,
                allowedEmailDomains: [...(company.allowedEmailDomains || []), newCompanyDomain]
            });

            if (updatedCompany.error) {
                message.error("שגיאה, ההערה על החברה לא נשמרה");
                return;
            }

            innerUpdateCompany(updatedCompany);
            setAddNewCompanyDomainModal(null);
            message.success("ההערה על החברה נשמרה בהצלחה");
        },
        [addNewCompanyDomainModal]
    );

    const onViewOrders = useCallback(admin => {
        history.push(`/company/orders/${admin.companyId}?userId=${admin.userId}`);
    }, []);

    const subAdminModalCompany = useMemo(
        () => filteredCompanies?.find(company => company?.companyId === viewSubAdminModal?.companyId),
        [filteredCompanies, viewSubAdminModal]
    );

    const onTransferAllCompanyBudgetsToCompanyMaster = useCallback(async company => {
        message.loading({
            content: "הפעולה רצה כעת - אנא המתן...",
            key: "onTransferAllCompanyBudgetsToCompanyMaster"
        });

        const res = await HttpClient.safePost("/admin/api/transferAllCompanyBudgetsToCompanyMaster", {
            companyId: company.companyId
        });

        if (res?.error) {
            message.error({
                content: new StringBuilder("הפעולה נכשלה").append(`(${res?.error})`).toString(),
                key: "onTransferAllCompanyBudgetsToCompanyMaster",
                duration: 5
            });
        } else {
            message.success({
                content: "הפעולה בוצעה בהצלחה",
                key: "onTransferAllCompanyBudgetsToCompanyMaster"
            });
        }
    }, []);

    return (
        <div>
            <ConfigProvider direction="rtl">
                <PageTitleHeader showBack={false}>חברות</PageTitleHeader>
                <AdminCompanyBranding
                    visible={!!brandCompany}
                    companyInfo={brandCompany}
                    onClose={() => {
                        setBrandCompany(null);
                        fetchCompanies();
                    }}
                />
                <CompanyPageLayoutModal
                    visible={!!companyPageLayoutModal}
                    company={companyPageLayoutModal}
                    onClose={() => setCompanyPageLayoutModal(null)}
                    onSave={updateCompanyPageLayoutId}
                />

                <AdminUpdateCompanyDetailsModal
                    visibility={editCompanyDetails}
                    companyDetails={editCompanyDetails}
                    onClose={() => setEditCompanyDetails(null)}
                    onSave={fetchCompanies}
                />
                <BlockProductsForCompanyModal
                    visible={!!editCompanyBlockedProducts}
                    company={editCompanyBlockedProducts}
                    onCancel={() => setEditCompanyBlockedProducts(null)}
                    onSave={updatedCompany => {
                        setFilteredCompanies(prevCompanies => {
                            const index = prevCompanies.findIndex(
                                company => company.companyId === updatedCompany.companyId
                            );
                            prevCompanies.splice(index, 1, updatedCompany);
                            return [...prevCompanies];
                        });
                        setEditCompanyBlockedProducts(null);
                    }}
                />
                <AddNewCompanyDomainModal
                    visible={!!addNewCompanyDomainModal}
                    company={addNewCompanyDomainModal}
                    onClose={() => setAddNewCompanyDomainModal(null)}
                    onSave={onNewCompanyDomainSave}
                />
                <AdminBudgetModal
                    onFinish={() => {
                        fetchCompanies();
                        fetchMasterBudgets();
                    }}
                    updateCompany={newCompanyDetails => {
                        innerUpdateCompany(newCompanyDetails);
                        setCompanyBudgetModalInfo(newCompanyDetails);
                    }}
                    onClose={() => setCompanyBudgetModalInfo(null)}
                    companyDetails={companyBudgetModalInfo}
                />
                <LoadAdminExcelModal
                    visible={!!loadSubAdminsExcelModal}
                    onClose={() => setLoadSubAdminsExcelModal(null)}
                    companyId={loadSubAdminsExcelModal?.companyId}
                    onFinish={() => message.success("אדמינים עודכנו בהצלחה")}
                />

                <AdminSubAdminManagerModal
                    visible={!!viewSubAdminModal}
                    company={subAdminModalCompany}
                    onClose={() => setViewSubAdminModal(null)}
                    systemCSMs={systemCSMs}
                    onSelectCompanyMasterCSM={onSelectCSM}
                />

                <div className="admin-companies-top-bar">
                    <div className="admin-companies-top-bar-container">
                        <SquareButton
                            disabled={loadAdvanceStats}
                            onClick={() => {
                                setLoadAdvanceStats(true);
                            }}>
                            טען סטטיסטיקה מורחבת
                        </SquareButton>

                        <CompanyStatisticsLabel
                            companiesStatics={companyCustomerStats}
                            companies={filteredCompanies}
                            companyBudgets={filteredCompanyBudgets}
                        />
                        <TableDateSearchProvider>
                            <TableDateSearch
                                date={searchDate}
                                onDatePicked={setSearchDate}
                                onSearch={setSearchInput}
                                todayButtonText="חברות שהצטרפו היום"
                            />
                        </TableDateSearchProvider>
                    </div>

                    <SquareButton
                        className="admin-companies-export-to-excel"
                        disabled={isLoading || isLoadingCompaniesOrdersStatistics || isLoadingCompaniesStatistics}
                        onClick={() =>
                            exportTableToExcel(filteredCompanies, companiesOrderStatistics, companyCustomerStats)
                        }>
                        ייצא לאקסל
                    </SquareButton>
                </div>
                <EditableColumnsTable
                    name="adminCompaniesTable"
                    editButtonLocation="firstHeader"
                    rowKey="companyId"
                    className="admin-companies-table"
                    scroll={{x: 1240}}
                    pagination={{
                        position: ["bottomRight"],
                        showSizeChanger: false,
                        defaultPageSize: DEFAULT_PAGE,
                        showTotal: total => <span>סה״כ {total}</span>,
                        onChange: (page, pageSize) => setCurrentPage(page - 1)
                    }}
                    loading={isLoading}
                    dataSource={filteredCompanies}
                    columns={columns(
                        currentPage,
                        companiesOrderStatistics,
                        isLoadingCompaniesOrdersStatistics,
                        companyCustomerStats,
                        onIgnoreDataChange,
                        loadingChangeIgnoreData,
                        deleteCompany,
                        company => setEditCompanyDetails(company),
                        setCompanyBudgetModalInfo,
                        onResendCompanySignupEmail,
                        copyInviteLink,
                        changeLogo,
                        setLoadSubAdminsExcelModal,
                        cancelBudgetRequest,
                        masterBudgets,
                        loadingMasterBudgets,
                        systemCSMs,
                        onSelectCSM,
                        loadingUpdateCSM,
                        setEditCompanyBlockedProducts,
                        me,
                        innerUpdateCompany,
                        company => setupPageLayoutForCompany(company),
                        setAddNewCompanyDomainModal,
                        setViewSubAdminModal,
                        onTransferAllCompanyBudgetsToCompanyMaster
                    )}
                />
            </ConfigProvider>
        </div>
    );
};
