import React, {useContext, useState} from "react";
import {StyledButton} from "../../components/StyledButton";
import {RightOutlined} from "@ant-design/icons";
import {EXCenterBirthdayWishModal} from "./EXCenterBirthdayWishModal";
import {EXCenterBirthdayAvatar} from "./EXCenterBirthdayAvatar";
import {AppContext} from "../../AppContext";
import {truncate} from "lodash";
import {EXCenterContext} from "../EXCenterContext";
import moment from "moment";
import {Divider} from "antd";
// import hexToRgba from "hex-to-rgba";

export const EXCenterBirthdayCard = ({employee, designInfo}) => {
    const {me} = useContext(AppContext);
    const {theme} = useContext(EXCenterContext);
    const [employeeToWish, setEmployeeToWish] = useState(null);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 30,
                paddingTop: 0,
                width: 270,
                height: 290,
                borderRadius: 10,
                boxShadow: "0 10px 20px 0 rgba(22,29,37,0.1)",
                margin: 15,
                backgroundColor: "white",
                marginTop: 30,
                marginBottom: 30
            }}>
            <EXCenterBirthdayWishModal
                employee={employee}
                visible={employeeToWish !== null}
                onClose={() => setEmployeeToWish(null)}
            />
            <EXCenterBirthdayAvatar width={80} height={80} employee={employee} />
            {employee.userId !== me.userId ? (
                <span style={{fontSize: 20, height: 30, fontWeight: 500, color: theme.textColor, marginBottom: 10}}>
                    {truncate(`${employee.firstName} ${employee.lastName}`, {length: 20})}
                </span>
            ) : (
                <span style={{fontSize: 20, height: 30, fontWeight: 700, color: theme.textColor, marginBottom: 10}}>
                    YOU
                </span>
            )}
            <span style={{fontSize: 20, height: 30, fontWeight: 200, color: theme.textColor, marginBottom: 20}}>
                Birthday
                <Divider
                    type="vertical"
                    style={{
                        height: 14,
                        marginLeft: 10,
                        marginRight: 12,
                        backgroundColor: hexToRgba(theme.textColor, "0.5")
                    }}
                />
                {moment(employee.birthday, "DD/MM/YYYY").format("MMM Do")}
            </span>
            {employee.userId !== me.userId ? (
                designInfo.action.enabled ? (
                    <StyledButton
                        style={{
                            color: "white",
                            border: "none",
                            backgroundColor: designInfo.action.color,
                            minHeight: 40,
                            maxHeight: 40,
                            width: 195,
                            borderRadius: 20
                        }}
                        onClick={() => setEmployeeToWish(employee)}>
                        {designInfo.action.text} <RightOutlined style={{fontSize: 12, paddingTop: 3}} />
                    </StyledButton>
                ) : null
            ) : null}
        </div>
    );
};
