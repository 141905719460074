import isMobileDevice from "is-mobile";
import React from "react";
import {Select} from "antd";
import {DownOutlined} from "@ant-design/icons";

export const CustomSelect = props => {
    const {children, style, placeholder, value} = props;

    const onChange = e => {
        if (props.onChange) {
            props.onChange(e);
        }

        if (props.onSelect) {
            props.onSelect(e.target.value);
        }
    };

    return isMobileDevice() ? (
        <div style={{position: "relative"}}>
            <select
                className="wb-custom-select"
                value={value}
                onChange={onChange}
                style={{
                    padding: "4px 11px",
                    backgroundColor: "#FCFDFE",
                    color: !!value ? "rgba(0, 0, 0, 0.65)" : "rgba(0, 0, 0, 0.3)",
                    border: "1px solid #E8EDF5",
                    height: 50,
                    width: "100%",
                    borderRadius: 10,
                    fontSize: 16,
                    outline: "none",
                    WebkitAppearance: "none",
                    appearance: "none",
                    MozAppearance: "none",
                    ...style
                }}>
                {placeholder ? (
                    <option value="" disabled selected>
                        {placeholder}
                    </option>
                ) : null}
                {children}
            </select>
            <DownOutlined
                style={{
                    position: "absolute",
                    right: 11,
                    top: 20,
                    fontSize: 12,
                    color: "rgba(0,0,0,0.25)"
                }}
            />
        </div>
    ) : (
        <Select {...props}>{children}</Select>
    );
};

if (isMobileDevice()) {
    CustomSelect.Option = ({value, children}) => <option value={value}>{children}</option>;
} else {
    CustomSelect.Option = Select.Option;
}
