import {StyledButton} from "./StyledButton";
import React from "react";

export const ExternalLinkButton = props => (
    <StyledButton
        {...props}
        target={props.href ? "_blank" : void 0}
        onClick={e => {
            e.stopPropagation();
        }}>
        {props.children}
    </StyledButton>
);
