import React, {useContext, useState} from "react";
import {AppContext} from "../AppContext";
import {HttpClient} from "../http/HttpClient";
import {Button, notification} from "antd";

const notificationKey = "wb-missing-services-notification";

export const ProviderDefineServicesNotification = ({history, location}) => {
    const {me} = useContext(AppContext);
    const [providerInfo, setProviderInfo] = useState(null);

    const goToMyServices = () => {
        history.push("/dashboard/myservices");
        notification.close(notificationKey);
    };

    const alreadyNotified = window.sessionStorage.getItem(notificationKey);
    if (
        me &&
        me.type === "provider" &&
        !providerInfo &&
        !alreadyNotified &&
        location.pathname.indexOf("myservices") < 0
    ) {
        (async () => {
            try {
                const provider = await HttpClient.get(`/api/providers/me`);
                setProviderInfo(provider);
                if (!Array.isArray(provider.services) || provider.services.length === 0) {
                    const args = {
                        message: `Hey ${provider.firstName}!`,
                        description: (
                            <p>
                                It looks like you don't have any services, click{" "}
                                <Button style={{padding: 0}} type="link" onClick={() => goToMyServices()}>
                                    here
                                </Button>{" "}
                                to define them.
                            </p>
                        ),
                        duration: 0,
                        placement: "bottomRight",
                        key: notificationKey
                    };
                    notification.open(args);
                    window.sessionStorage.setItem(notificationKey, "true");
                }
            } catch (ignore) {}
        })();
    }

    return <></>;
};
