import React from "react";
import {giftLocationSegmentOptionTab} from "./consts.js";
import {DeliveryAddressInput} from "../DeliveryAddressInput.jsx";
import {deliveryAddressOptions} from "../consts.js";
import {Form} from "antd";
import {GoogleMapSubAreaApiKeys} from "../../../../../consts.js";

export const giftSegmentedOptions = () => {
    return [
        {
            key: giftLocationSegmentOptionTab.singledDeliveryTab,
            text: "כתובת בודדת",
            component: (
                <div>
                    <DeliveryAddressInput
                        path={`locationFilter.${giftLocationSegmentOptionTab.singledDeliveryTab}`}
                        placeholder={"הזן רחוב/ מס׳ בניין/ עיר"}
                        deliveryType={deliveryAddressOptions.deliveryToAddress}
                        showDeliveryPrice={false}
                    />
                </div>
            )
        },
        {
            key: giftLocationSegmentOptionTab.selfPickupTab,
            text: "איסוף עצמי",
            component: (
                <span className="multiple-address-title">בקרוב תפתח האופציה לאיסוף עצמי 😃</span>
                // <div className="products-filters-modal-budget-panel">
                //     <Form.Item className="address-input">
                //         <DeliveryAddressInput
                //             path={`locationFilter.${giftLocationSegmentOptionTab.selfPickupTab}`}
                //             placeholder={"הזן עיר רלוונטית לאיסוף"}
                //             deliveryType={deliveryAddressOptions.onSite}
                //             showDeliveryPrice={false}
                //             searchType={GoogleMapSubAreaApiKeys}
                //             isSelfPickupPossible={true}
                //         />
                //     </Form.Item>
                // </div>
            )
        },
        {
            key: giftLocationSegmentOptionTab.multipleAddressTab,
            text: "הפצה למס כתובות",
            component: (
                <span className="multiple-address-title">
                    לאחר בחירת המתנה תוכל להזין את רשימת הכתובות באמצעות קובץ אקסל מסודר 😇
                </span>
            )
        }
    ];
};
