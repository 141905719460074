import React, {useMemo} from "react";
import {Modal, Timeline} from "antd";
import {NOOP} from "../../utils/NOOP";

import "./timeline.css";

export const TimelineModal = ({title, list, visible, onClose, onSave = NOOP}) => {
    const filteredTimeLines = useMemo(() => {
        return list?.map(({id, icon, text}) => (
            <Timeline.Item key={id} dot={icon}>
                {text}
            </Timeline.Item>
        ));
    }, [list]);

    return (
        <>
            <Modal open={visible} onCancel={onClose} onOk={onSave} footer={null} width={"60vw"}>
                <div className="timeline-modal">
                    <div className="title">{title}</div>
                    <div className="line-separator" />

                    <Timeline>{filteredTimeLines}</Timeline>
                </div>
            </Modal>
        </>
    );
};
