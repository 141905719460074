import CallToActionModal from "./CallToActionModal";
import React, {useCallback, useMemo, useState} from "react";
import {debounce} from "lodash";

export const AreYouSureModal = ({
    visible,
    onClose,
    title = "Are you sure?",
    imageName = "oops2",
    subTitle,
    onYes,
    onNo,
    yesText = "Yes",
    noText = "No"
}) => {
    const [actionLoading, setActionLoading] = useState(false);

    return (
        <CallToActionModal
            imageName={imageName}
            closable={true}
            onClose={() => (onClose ? onClose() : onNo())}
            visible={visible}
            title={title}
            subTitle={subTitle}
            actionColor="white"
            actionLoading={actionLoading}
            actionBackgroundColor="#2F3E83"
            actionText={yesText}
            actionHandler={async () => {
                setActionLoading(true);
                if (!actionLoading) {
                    await onYes();
                }
                setActionLoading(false);
            }}
            actionStyle={{width: 120}}
            anotherActionColor="#F06060"
            anotherActionBackgroundColor="white"
            anotherActionText={noText}
            anotherActionHandler={() => onNo()}
            anotherActionStyle={{width: 120, border: "1px solid #F06060"}}
            actionsContainerStyle={{marginTop: 30}}
        />
    );
};
