import React, {useReducer, useEffect} from "react";
import {Button, Form, message, Slider, Spin, Upload} from "antd";
import {HttpClient} from "../http/HttpClient";
import {StyledButton} from "../components/StyledButton";
import {parse as qsParse} from "query-string";
import {LoadingOutlined} from "@ant-design/icons";
// import * as EmailValidator from "email-validator";
import {ProviderExtraFields, ProviderSignUpExtraInfoFields} from "../provider/ProviderSignUpExtraInfo";
import {fillLocationDetails, ProviderSignUpBasicInfoFields} from "../provider/ProviderSignUpBasicInfo";
import PageTitleHeader from "../components/PageTitle";
import {values as _values} from "lodash";
import {buildProviderUser, extractExtraDetails} from "../provider/ProviderSignUp";
import {EditIcon} from "../icons";
import profilePlaceholder from "../images/icons/profile-placeholder.png";
import {generateImageUploadProps} from "../provider/ProviderProfile";
import {FormInput} from "../components/form";
import {PageLoader} from "../components/PageLoader";
import {SmartImageContainer} from "../components/SmartImageContainer";

const {useForm} = Form;

const ProviderDetailsForm = ({form, providerInfo, onFinish, initialValues, onProviderTypeChange, approving}) => (
    <Form
        form={form}
        className="wb-approval-form"
        hideRequiredMark={true}
        labelCol={{span: 6}}
        labelAlign="left"
        scrollToFirstError={true}
        initialValues={initialValues}
        onFinish={vals => onFinish(vals)}
        onFinishFailed={({errorFields}) => {
            if (errorFields.length > 0) {
                window.scrollTo({left: window.innerWidth / 2, top: 0});
            }
        }}
        style={{maxWidth: 340, padding: 10, width: "100%", marginTop: 20}}>
        <Form.Item
            name="extra.rating"
            rules={[
                {
                    required: true,
                    validator: (_, value) => {
                        return !value || value < 50 ? Promise.reject("Rating must be at least 50.") : Promise.resolve();
                    }
                }
            ]}>
            <Slider min={0} max={100} format={percent => percent / 10} />
        </Form.Item>
        <Form.Item name="extra.customers">
            <FormInput placeholder="Customers (comma separated)" />
        </Form.Item>
        <ProviderSignUpBasicInfoFields
            disableEmail={true}
            allowExistingEmail={true}
            defaultCountry={{
                countryName: providerInfo.countryName,
                countryId: providerInfo.countryId,
                countryCode: providerInfo.countryCode,
                phonePrefix: providerInfo.phonePrefix
            }}
            defaultState={
                providerInfo.stateId
                    ? {
                          stateName: providerInfo.stateName,
                          stateId: providerInfo.stateId
                      }
                    : null
            }
            defaultCity={{
                cityName: providerInfo.cityName,
                cityId: providerInfo.cityId
            }}
            providerType={providerInfo.providerType}
            onProviderTypeChange={onProviderTypeChange}
        />
        <ProviderSignUpExtraInfoFields defaultDescription={providerInfo.description} />
        <Form.Item style={{marginTop: 40}}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <StyledButton
                    style={{
                        backgroundColor: providerInfo.approved ? "#2F3E83" : "#C44B4B",
                        color: "white",
                        borderRadius: 25,
                        width: "100%"
                    }}
                    loading={approving}
                    htmlType="submit">
                    {providerInfo.approved ? "Save" : "Approve"}
                </StyledButton>
            </div>
        </Form.Item>
    </Form>
);

export const AdminProviderApproval = ({location}) => {
    const [form] = useForm();
    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        uploading: false,
        approving: false,
        email: null,
        providerInfo: {}
    });

    useEffect(() => {
        (async () => {
            const {search} = location;
            const queryParams = qsParse(search);
            const {email} = queryParams;
            if (email) {
                setState({email});
                await fetchProviderInfo(email);
            }
        })();
    }, []);

    const fetchProviderInfo = async email => {
        email = email ? email.toLowerCase().trim() : email;
        if (EmailValidator.validate(email)) {
            try {
                const providerInfo = await HttpClient.get(`/admin/api/providers/email/${email}`);
                providerInfo.rating = providerInfo.rating ? providerInfo.rating * 10 : 0;
                setState({providerInfo});
            } catch (e) {
                message.error(e.message, 3);
            }
        } else {
            message.error("Invalid email.");
        }
    };

    const updateProfile = async values => {
        const {providerInfo} = state;

        setState({approving: true});

        try {
            if (providerInfo.providerType === "organization") {
                values.lastName = "";
                form.setFieldsValue({lastName: ""});
            }

            const updatedValues = fillLocationDetails(values);
            const userDetails = buildProviderUser(updatedValues, {});
            const extraDetails = extractExtraDetails({}, updatedValues);
            extraDetails.rating = extraDetails.rating / 10;

            const updatedProvider = await HttpClient.post("/admin/api/providers/approval", {
                userDetails: {...userDetails, userId: providerInfo.providerId},
                providerDetails: {...extraDetails, providerId: providerInfo.providerId}
            });

            setState({
                providerInfo: Object.assign({}, providerInfo, updatedProvider, {approved: true})
            });

            if (providerInfo.approved) {
                message.success("Changes saved successfully!");
            } else {
                message.success(`${values.email} was approved successfully!`);
            }
        } catch (e) {
            message.error(e.message, 3);
        }

        setState({approving: false});
    };

    const toInitialValues = providerDetails => {
        return Object.keys(providerDetails).reduce((info, fieldName) => {
            const extraFields = _values(ProviderExtraFields);
            if (extraFields.indexOf(fieldName) >= 0) {
                const extraFieldName = "extra." + fieldName;
                providerDetails[extraFieldName] = providerDetails[fieldName];
                fieldName = extraFieldName;
            }
            info[fieldName] = providerDetails[fieldName];
            return info;
        }, {});
    };

    const {uploading, approving, providerInfo} = state;

    const uploadProps = generateImageUploadProps(
        isUploading => {
            setState({uploading: isUploading});
        },
        (imageId, imageUrl) => {
            setState({
                providerInfo: Object.assign({}, providerInfo, {imageId, imageUrl})
            });
        },
        `/admin/api/providers/${providerInfo.providerId || ""}/image`
    );

    return (
        <div style={{display: "flex", alignItems: "center", flexDirection: "column", margin: 10}}>
            {providerInfo.email ? (
                <>
                    <PageTitleHeader>
                        {providerInfo.approved ? "Professional profile" : "Professional approval"}
                    </PageTitleHeader>
                    <SmartImageContainer
                        src={providerInfo.imageUrl || profilePlaceholder}
                        width={240}
                        height={240}
                        style={{
                            borderRadius: 120,
                            position: "relative"
                        }}>
                        <Upload {...uploadProps}>
                            <Button
                                className="wb-provider-edit-image"
                                icon={
                                    uploading ? (
                                        <LoadingOutlined style={{color: "white", paddingBottom: 4}} />
                                    ) : (
                                        <EditIcon style={{paddingTop: 2}} />
                                    )
                                }
                            />
                        </Upload>
                    </SmartImageContainer>
                    <ProviderDetailsForm
                        form={form}
                        onProviderTypeChange={providerType =>
                            setState({
                                providerInfo: Object.assign({}, providerInfo, {providerType})
                            })
                        }
                        onFinish={async vals => await updateProfile(vals)}
                        initialValues={toInitialValues(providerInfo)}
                        providerInfo={providerInfo}
                        approving={approving}
                    />
                </>
            ) : (
                <PageLoader align="flex-start" top={100} />
            )}
        </div>
    );
};
