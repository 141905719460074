import React, {useEffect, useState} from "react";
import {message, Tooltip} from "antd";
import {RoundedTable} from "../components/RoundedTable";
import moment from "moment-timezone";
import {SearchInput} from "../components/Search";
import {HttpClient} from "../http/HttpClient";
import PageTitleHeader from "../components/PageTitle";
import {InfoCircleOutlined} from "@ant-design/icons";

const isQueued = (state, response) => {
    return (
        state === "Delivery" &&
        typeof response === "string" &&
        response.toLowerCase().indexOf("queued mail for delivery") >= 0
    );
};

const columns = [
    {
        title: "MESSAGE ID",
        dataIndex: "messageId",
        key: "messageId",
        width: 250
    },
    {
        title: "FROM",
        dataIndex: "from",
        key: "from",
        width: 200,
        render(from) {
            return from.join(", ");
        }
    },
    {
        title: "TO",
        dataIndex: "to",
        key: "to",
        width: 200,
        render(to) {
            return to.join(", ");
        }
    },
    {
        title: "SUBJECT",
        dataIndex: "subject",
        key: "subject",
        width: 250
    },
    {
        title: "STATE",
        dataIndex: "state",
        key: "state",
        width: 150,
        filters: [
            {
                text: "Send",
                value: "Send"
            },
            {
                text: "Delivery",
                value: "Delivery"
            },
            {
                text: "Queued",
                value: "Queued"
            },
            {
                text: "Reject",
                value: "Reject"
            },
            {
                text: "Bounce",
                value: "Bounce"
            },
            {
                text: "Complaint",
                value: "Complaint"
            },
            {
                text: "Rendering Failure",
                value: "Rendering Failure"
            },
            {
                text: "Rendering Success",
                value: "Rendering Success"
            },
            {
                text: "Open",
                value: "Open"
            },
            {
                text: "Click",
                value: "Click"
            }
        ],
        filterMultiple: false,
        onFilter: (value, {state, response}) => {
            if (isQueued(state, response)) {
                state = "Queued";
            }
            return state === value;
        },
        render(state, {response}) {
            const colorMap = {
                Send: "blue",
                Reject: "red",
                Bounce: "red",
                Delivery: "green",
                Complaint: "red",
                "Rendering Failure": "red"
            };

            const color = colorMap.hasOwnProperty(state) ? colorMap[state] : void 0;

            if (isQueued(state, response)) {
                return <span style={{color: "red", fontWeight: 700}}>Queued</span>;
            }

            return <span style={{color, fontWeight: color === "red" ? 700 : 400}}>{state}</span>;
        }
    },
    {
        title: "TIME",
        dataIndex: "timestamp",
        key: "timestamp",
        width: 200,
        render(timestamp) {
            return moment(timestamp).format("ddd MMMM D, YYYY HH:mm");
        }
    },
    {
        title: "RESPONSE",
        dataIndex: "response",
        key: "response",
        width: 100,
        render(response) {
            return (
                <Tooltip placement="left" title={response}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: 20,
                            height: 20
                        }}>
                        <InfoCircleOutlined style={{fontSize: 16}} />
                    </div>
                </Tooltip>
            );
        }
    }
];

export const AdminEmails = () => {
    const [term, setTerm] = useState("");
    let [emailDeliveries, setEmailDeliveries] = useState(null);

    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const deliveries = await HttpClient.get("/admin/api/delivery");
                setEmailDeliveries(deliveries);
            } catch (e) {
                message.error(e.message, 3);
            }
        });
    }, []);

    const filter = term => {
        setTerm(term);
    };

    if (emailDeliveries) {
        emailDeliveries = emailDeliveries.filter(
            delivery => `${delivery.to.join(",")} ${delivery.subject}`.toLowerCase().indexOf(term.toLowerCase()) >= 0
        );
    }

    return (
        <div style={{display: "flex", justifyContent: "center", margin: 10, marginBottom: 40}}>
            <div style={{display: "flex", flexDirection: "column", maxWidth: 1500, width: "100%"}}>
                <PageTitleHeader showBack={false}>Email delivery</PageTitleHeader>
                <div>
                    <SearchInput
                        style={{marginBottom: 30, maxWidth: 500, width: "100%"}}
                        onChange={evt => filter(evt.target.value)}
                        placeholder="Search email"
                    />
                </div>
                <RoundedTable
                    scroll={{x: 1500, y: 800}}
                    pagination={false}
                    loading={emailDeliveries === null}
                    dataSource={emailDeliveries || []}
                    columns={columns}
                />
            </div>
        </div>
    );
};
