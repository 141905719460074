import React, {useState} from "react";
import {SketchColorPicker} from "../../components/ColorPicker";
import {cloneDeep, sortBy} from "lodash";
import {defaultThemes} from "../../color/ColorTheme";
import {Tooltip} from "antd";

export const defaultPickerColors = Object.keys(defaultThemes).reduce((colors, themeName) => {
    return colors.concat(defaultThemes[themeName].colors);
}, []);

const colorDotDiv = color => (
    <div
        style={{
            height: 26,
            width: 26,
            backgroundColor: color,
            borderRadius: 13,
            cursor: "pointer"
        }}
    />
);

const ColorDot = ({color, onChange, onClick, pickerStyle, tooltip}) => {
    const [showPicker, setShowPicker] = useState(false);

    return (
        <div
            onClick={
                onClick ||
                (e => {
                    setShowPicker(true);
                    e.preventDefault();
                    e.stopPropagation();
                })
            }
            style={{
                height: 30,
                width: 30,
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 15,
                boxShadow: "0 1px 4px 0 rgba(0,0,0,0.1)",
                margin: 10,
                cursor: "pointer"
            }}>
            {showPicker ? (
                <SketchColorPicker
                    colors={defaultPickerColors}
                    color={color}
                    onClose={e => {
                        setShowPicker(false);
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    style={{width: 140, ...pickerStyle}}
                    onColorPick={onChange}
                />
            ) : null}
            {!_.isEmpty(tooltip) ? <Tooltip title={tooltip}>{colorDotDiv(color)}</Tooltip> : colorDotDiv(color)}
        </div>
    );
};

const colorDotOnClick = (onClick, colorJson, index) => {
    if (colorJson.disabled) {
        return () => {};
    }
    return onClick ? () => onClick(index) : null;
};

export const ColorsRow = ({colors, onChange, onClick, pickerStyle}) => {
    return (
        <div
            style={{
                display: "flex"
            }}>
            {(colors || []).map((colorJson, idx) => (
                <ColorDot
                    color={colorJson.color}
                    onChange={({hex}) => onChange(hex, idx)}
                    onClick={colorDotOnClick(onClick, colorJson, idx)}
                    tooltip={colorJson.tooltip}
                    pickerStyle={pickerStyle}
                />
            ))}
        </div>
    );
};

export const ColorPalette = ({name, selected, colors, onColorsChange, onSelect, pickerStyle}) => {
    const doChange = (color, idx) => {
        const changedColors = cloneDeep(colors);
        changedColors[idx] = color;
        onColorsChange(changedColors);
    };

    return (
        <div onClick={() => onSelect()} className={"wb-color-palette" + (selected ? " selected" : "")}>
            <div
                style={{
                    fontSize: 16,
                    color: "inherit"
                }}>
                {name}
            </div>
            <ColorsRow colors={colors} onChange={doChange} pickerStyle={pickerStyle} />
        </div>
    );
};

export const ColorPaletteSelection = ({themes, selectedTheme = null, onSelect, onColorsChange, pickerStyle}) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
            }}>
            {sortBy(Object.keys(themes), themeName => defaultThemes[themeName].order).map(themeName => {
                const colors = themes[themeName].colors;
                const isSelected = selectedTheme === themeName;
                return (
                    <ColorPalette
                        name={defaultThemes[themeName].name}
                        selected={isSelected}
                        colors={colors}
                        onColorsChange={colors => onColorsChange(themeName, colors)}
                        onSelect={() => onSelect(themeName)}
                        pickerStyle={pickerStyle}
                    />
                );
            })}
        </div>
    );
};
