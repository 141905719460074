import {AreYouSureModal} from "../../../components/AreYouSureModal";
import {PageSubTitleLabel, PageTitleLabel} from "../../../components/PageTitle";
import {AutoDirectionProviderWithValueChange} from "../../../AutoDirectionProvider";
import {DatePicker, Form, Select, Tooltip, Radio, message} from "antd";
import {CancelButton, FormInput, FormTextArea, SaveButton} from "../../../components/form";
import {CustomTimePicker} from "../../../components/CustomTimePicker";
import {DurationInput} from "../../../components/DurationInput";
import {EventLocationSelect} from "../../../components/LocationSelect";
import {CompanyBranchSelect, hasBranches} from "../../../company/CompanyBranchSelect";
import {CostFormItem} from "../../EventCustomSessionModal";
import {EventInfoPreview} from "../../preview/EventInfoPreview";
import React, {useContext, useEffect, useState} from "react";
import {LocationLabels} from "../../../data/locations";
import moment from "moment-timezone";
import {RRuleSetBuilder} from "../../RRuleSetBuilder";
import {dateFormat} from "../../../utils/DateFormat";
import {eventDateToUtcTimestamp} from "../../eventDateToUtc";
import {HttpClient} from "../../../http/HttpClient";
import {DTStartCreator} from "./DTStartCreator";
import {AppContext} from "../../../AppContext";
import {FrequencyMapper} from "../../FrequencyMapper";
import {PageLoader} from "../../../components/PageLoader";
import {EventActionButtonFormInput} from "./EventActionButtonFormInput";
import {ZoomLinkInput} from "../../../zoom/ZoomLinkInput";
import {ColorPickerButton} from "../../../components/ColorPicker";
import {EditableInput} from "../../../components/EditableInput";

const {Option} = Select;

const EditTooltip = ({showEditModePicker, children}) =>
    showEditModePicker ? (
        <div style={{display: "flex", width: "100%"}}>{children}</div>
    ) : (
        <Tooltip title="You are only allowed to change a recurring event if this is the up coming recurring event.">
            {children}
        </Tooltip>
    );

export const EventEditForm = ({
    event,
    currentStart,
    onFinish,
    onCommitChanges,
    showConfirmMessage,
    onConfirmMessageClose,
    onEventChange,
    editMode,
    onEditModeChange,
    saving,
    providerInfo,
    priceType,
    onPriceTypeChange,
    onClose
}) => {
    const {me, company, theme} = useContext(AppContext);
    const [firstPastDate, setFirstPastDate] = useState(null);
    const [updatedStart, setUpdatedStart] = useState(moment(currentStart));
    const [actionButtonType, setActionButtonType] = useState(event.actionType || "system");
    useEffect(() => {
        Promise.resolve().then(async () => {
            try {
                const {firstPastDate} = await fetchFirstPastDate();
                setFirstPastDate(firstPastDate || -1);
            } catch (e) {
                message.error("Something went wrong, please try again later.");
            }
        });
    }, []);

    const fetchFirstPastDate = async () => {
        const datetime = eventDateToUtcTimestamp(currentStart);
        return await HttpClient.get(`/api/events/${event.eventId}/past/${datetime}`);
    };

    const isTheNextOccurrence = () => {
        const rruleSet = RRuleSetBuilder.build(event);
        const nextOccurrence = rruleSet.after(new Date(), true);
        return moment(nextOccurrence).format("YYYY-M-D") === dateFormat(currentStart);
    };

    const shouldShowEditModePicker = () => {
        return FrequencyMapper.toFreqString(event) !== "once" && isTheNextOccurrence();
    };

    const hasAddress = () => {
        return [LocationLabels.ONSITE, LocationLabels.OFFSITE].some(location => location === event.location);
    };

    const isDateDisabled = currentDate => {
        return (
            moment(currentDate).isBefore(moment(Date.now()).subtract(1, "days")) ||
            (editMode === "recurring" && firstPastDate > 0
                ? moment(currentDate).isBefore(moment(firstPastDate))
                : false)
        );
    };

    const oneTimerOrRecurring = () => {
        return editMode === "recurring" || FrequencyMapper.toFreqString(event) === "once";
    };

    const enabledForCustomSession = () => {
        return event.customSession && oneTimerOrRecurring();
    };

    if (firstPastDate === null) return <PageLoader align="flex-start" top={80} height={600} />;

    return (
        <Form
            onFinish={values => {
                if (actionButtonType !== "externalLink") {
                    values.externalLink = null;
                }
                onFinish(values);
            }}
            initialValues={{
                ...event,
                actionType: event.actionType || actionButtonType
            }}
            fields={Object.keys(event).map(field => {
                if (field === "actionType") {
                    return {
                        name: [field],
                        value: event[field] || actionButtonType
                    };
                }
                return {
                    name: [field],
                    value: event[field]
                };
            })}
            onValuesChange={(_, allValues) => {
                if (allValues.eventPhoto && allValues.eventPhoto.imageUrl) {
                    allValues.productCoverPhoto = allValues.eventPhoto.imageUrl;
                }
                if (allValues.startTime && allValues.startDate) {
                    const dtstart = DTStartCreator.fromDateTime(allValues.startTime, allValues.startDate);
                    setUpdatedStart(moment(dtstart));
                }

                if (allValues.actionType !== "externalLink") {
                    allValues.externalLink = null;
                } else if (allValues.externalLink) {
                    allValues.externalLink.enabled = true;
                }

                onEventChange({
                    ...event,
                    ...allValues
                });
            }}>
            <AreYouSureModal
                visible={showConfirmMessage}
                onClose={() => onConfirmMessageClose()}
                loading={saving}
                title="Are you sure about this change?"
                subTitle={
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div style={{color: "#F06060", fontWeight: 700}}>
                            Attention: your employees have booked appointments for this event.
                        </div>
                        <div>Don't worry, the relevant employees will be notified regarding the cancellation.</div>
                    </div>
                }
                saveStyle={{color: "white", backgroundColor: "#F06060"}}
                onYes={async () => await onCommitChanges()}
                onNo={() => onConfirmMessageClose()}
            />
            <div style={{width: 1100, display: "flex"}}>
                <div style={{width: "45%", height: 700, backgroundColor: "#F6F8FA", overflow: "auto"}}>
                    <div style={{width: "100%", padding: 30}}>
                        {FrequencyMapper.toFreqString(event) !== "once" ? (
                            <EditTooltip showEditModePicker={shouldShowEditModePicker()}>
                                <Radio.Group
                                    disabled={!shouldShowEditModePicker()}
                                    onChange={e => onEditModeChange(e.target.value)}
                                    style={{width: "100%", marginBottom: 24}}
                                    defaultValue="occurrence"
                                    value={editMode}
                                    size="large">
                                    <Radio.Button
                                        style={{
                                            textAlign: "center",
                                            width: "50%",
                                            borderBottomLeftRadius: 5,
                                            borderTopLeftRadius: 5
                                        }}
                                        value="occurrence">
                                        Edit this event
                                    </Radio.Button>
                                    <Radio.Button
                                        style={{
                                            textAlign: "center",
                                            width: "50%",
                                            borderBottomRightRadius: 5,
                                            borderTopRightRadius: 5
                                        }}
                                        value="recurring">
                                        Edit recurring
                                    </Radio.Button>
                                </Radio.Group>
                            </EditTooltip>
                        ) : null}
                        <PageTitleLabel style={{marginBottom: 5, marginTop: 0, fontSize: 28}}>
                            {editMode === "occurrence" ? "Edit this event only" : "Edit recurring event"}
                        </PageTitleLabel>
                        <PageTitleLabel style={{marginTop: 0, fontSize: 20}}>
                            {`${event.productName || event.sessionName || event.serviceName} ${
                                event.sessionType === "company" ? "by" : "with"
                            } ${event.providerFirstName}${event.providerLastName ? ` ${event.providerLastName}` : ""}`}
                        </PageTitleLabel>
                        <PageSubTitleLabel style={{marginBottom: 20, fontSize: 16}}>
                            Please make sure to coordinate changes with the service provider before hand
                        </PageSubTitleLabel>
                        {enabledForCustomSession() ? (
                            <AutoDirectionProviderWithValueChange style={{width: "100%"}}>
                                {({onChange, defaultValue, value}) => (
                                    <Form.Item
                                        name="sessionName"
                                        rules={[{required: true, message: "Please enter session name."}]}>
                                        <FormInput
                                            onChange={onChange}
                                            defaultValue={defaultValue}
                                            value={value}
                                            placeholder="Session name"
                                        />
                                    </Form.Item>
                                )}
                            </AutoDirectionProviderWithValueChange>
                        ) : null}
                        <div
                            style={{
                                display: "flex"
                            }}>
                            <Form.Item
                                name="startDate"
                                rules={[{type: "object", required: true, message: "Please select date."}]}
                                style={{marginRight: 10, width: "100%"}}>
                                <DatePicker
                                    inputReadOnly={true}
                                    style={{width: "100%"}}
                                    className="wb-date-picker"
                                    format="dddd, MMMM D, YYYY"
                                    showToday={false}
                                    disabledDate={currentDate => isDateDisabled(currentDate)}
                                    placeholder={editMode === "occurrence" ? "Date" : "Start date"}
                                />
                            </Form.Item>
                            <Tooltip title="Change event color">
                                <Form.Item
                                    name="backgroundColor"
                                    valuePropName="color"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please pick a color."
                                        }
                                    ]}>
                                    <ColorPickerButton pickerStyle={{left: -140, top: -80}} />
                                </Form.Item>
                            </Tooltip>
                        </div>
                        {editMode === "recurring" ? (
                            <Form.Item name="freq" rules={[{required: true, message: "Please select repetition."}]}>
                                <Select style={{width: "100%"}} placeholder="Repetition">
                                    <Option value="once">Just Once</Option>
                                    <Option value="daily">Every Day</Option>
                                    <Option value="weekly">Every week</Option>
                                    <Option value="2-weekly">Every 2 weeks</Option>
                                    <Option value="monthly">Every month</Option>
                                </Select>
                            </Form.Item>
                        ) : null}
                        {event.appointmentBased ? (
                            <Form.Item
                                name="whoPays"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please choose who pays."
                                    }
                                ]}>
                                <Select style={{width: "100%"}} placeholder="Who pays?">
                                    <Option value="Employees pay">Employees pay</Option>
                                    <Option value="Company pays">Company pays</Option>
                                </Select>
                            </Form.Item>
                        ) : (
                            <div style={{display: "flex", alignItems: "center"}}>
                                <Form.Item
                                    name="startTime"
                                    style={{width: "50%"}}
                                    rules={[{type: "object", required: true, message: "Please select start time."}]}>
                                    <CustomTimePicker
                                        style={{width: "100%"}}
                                        format="HH:mm"
                                        minuteStep={15}
                                        placeholder={"Time"}
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{width: "50%", marginLeft: 10}}
                                    name="maxParticipants"
                                    rules={[
                                        {
                                            validator: (_, value) => {
                                                if (value) {
                                                    if (value % 1 !== 0) {
                                                        return Promise.reject(
                                                            "Max participants must be a whole number."
                                                        );
                                                    } else if (value <= 0) {
                                                        return Promise.reject(
                                                            "Max participants must be a positive number."
                                                        );
                                                    }
                                                }

                                                return Promise.resolve();
                                            }
                                        }
                                    ]}>
                                    <FormInput
                                        className="wb-input-with-postfix"
                                        type="number"
                                        placeholder="Participants (optional)"
                                        addonAfter="MAX"
                                    />
                                </Form.Item>
                            </div>
                        )}
                        {event.customSession ? (
                            <div style={{display: "flex", marginBottom: 0}}>
                                <Form.Item
                                    style={{width: "50%"}}
                                    name="duration"
                                    validateFirst={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter duration."
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (!isNaN(value) && value <= 0) {
                                                    return Promise.reject("Please enter positive duration.");
                                                }

                                                return Promise.resolve();
                                            }
                                        }
                                    ]}>
                                    <DurationInput
                                        durationType={event.durationType || "min"}
                                        onDurationTypeChange={durationType => onEventChange({...event, durationType})}
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{width: "50%", marginLeft: 10}}
                                    name="location"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select location."
                                        }
                                    ]}>
                                    <EventLocationSelect event={event} provider={providerInfo} placeholder="Location" />
                                </Form.Item>
                            </div>
                        ) : (
                            <Form.Item
                                name="location"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select location."
                                    }
                                ]}>
                                <EventLocationSelect event={event} provider={providerInfo} placeholder="Location" />
                            </Form.Item>
                        )}
                        {hasBranches(me, company) && oneTimerOrRecurring() ? (
                            <Form.Item
                                name="branches"
                                rules={[
                                    {
                                        required: true,
                                        type: "array",
                                        message: "Please select site."
                                    }
                                ]}>
                                <CompanyBranchSelect mode="tags" branches={company.branches} />
                            </Form.Item>
                        ) : null}
                        {enabledForCustomSession() ? (
                            <CostFormItem priceType={priceType} onPriceTypeChange={type => onPriceTypeChange(type)} />
                        ) : null}
                        {hasAddress() ? (
                            <Form.Item name="address">
                                <AutoDirectionProviderWithValueChange style={{width: "100%"}}>
                                    {({onChange, defaultValue, value}) => (
                                        <FormInput
                                            onChange={onChange}
                                            defaultValue={defaultValue}
                                            value={value}
                                            placeholder="Address"
                                        />
                                    )}
                                </AutoDirectionProviderWithValueChange>
                            </Form.Item>
                        ) : (
                            <Form.Item name="meetingLink" rules={[{type: "url", message: "Please enter valid link."}]}>
                                <ZoomLinkInput placeholder="Insert meeting link" recurring={editMode === "recurring"} />
                            </Form.Item>
                        )}
                        <Form.Item name="notes">
                            <EditableInput style={{width: "435px"}} />
                        </Form.Item>
                        <EventActionButtonFormInput
                            actionButtonType={actionButtonType}
                            onActionButtonTypeChange={type => setActionButtonType(type)}
                        />
                    </div>
                </div>
                <div style={{width: "55%", padding: 30, height: 700, backgroundColor: "white", overflow: "auto"}}>
                    <Form.Item name="eventPhoto">
                        <EventInfoPreview
                            event={event}
                            dateMom={updatedStart}
                            hideBorderBottom={true}
                            showReload={true}
                            coverStyle={{borderRadius: 0}}
                            bodyStyle={{padding: 24, paddingBottom: 0, paddingTop: 0}}
                            useDefaultTheme={true}
                            withDefaultCover={me.type === "company"}
                            showCoverTag={true}
                            coverSize={{width: 650, height: 300}}
                        />
                    </Form.Item>
                </div>
            </div>
            <div
                style={{
                    height: 90,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    paddingRight: 30,
                    borderTop: "1px solid #DADEE4"
                }}>
                <CancelButton
                    style={{
                        backgroundColor: "white",
                        color: theme.primary || "#2F3E83",
                        border: theme.primary ? `1px solid ${theme.primary}` : "1px solid #2F3E83"
                    }}
                    onClick={() => onClose()}
                />
                <SaveButton
                    loading={saving}
                    style={{backgroundColor: theme.primary || "#2F3E83", color: theme.textPrimary || "white"}}
                />
            </div>
        </Form>
    );
};
