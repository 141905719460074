import React, {useCallback} from "react";
import copyToClipboard from "copy-to-clipboard";
import {message} from "antd";

export const CopyableSpan = ({textToCopy, children}) => {
    const onClick = useCallback(
        e => {
            copyToClipboard(textToCopy ?? e.target.textContent);
            message.success("טקסט הועתק בהצלחה");
        },
        [textToCopy]
    );

    return <span onDoubleClick={onClick}>{children}</span>;
};
