import React, {useContext} from "react";
import {AppContext} from "../AppContext";
import {Modal} from "antd";
import {CloseIcon} from "../icons";
import {EditForm} from "./EditFormUtils";

export const CompanyEventEdit = ({event, dateMom, visible, onClose, onSave, onChange, onSend, onTest}) => {
    const {theme} = useContext(AppContext);
    const {weeklyTheme} = theme;

    return (
        <Modal
            className="wb-weekly-edit-modal"
            bodyStyle={{padding: 0, width: 1100, borderRadius: 10}}
            centered={true}
            closable={true}
            closeIcon={<CloseIcon fill={weeklyTheme.textSecondary || "#dbe2ec"} />}
            destroyOnClose={true}
            open={visible}
            onCancel={() => onClose()}
            zIndex={1001}
            footer={null}
            title={
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        color: weeklyTheme.textSecondary,
                        height: 60,
                        fontSize: 20,
                        fontWeight: 700,
                        paddingLeft: 20,
                        backgroundColor: weeklyTheme.secondary
                    }}>
                    Edit event email presentation
                </div>
            }>
            {event ? (
                <EditForm
                    event={event}
                    dateMom={dateMom}
                    onSave={onSave ? evt => onSave(evt) : null}
                    onChange={onChange}
                    onSend={onSend}
                    onTest={onTest}
                />
            ) : null}
        </Modal>
    );
};
