import React, {useCallback, useContext, useEffect} from "react";
import {ConfigProvider} from "antd";
import "../../../../../dashboards/app-dashboard.css";
import "../happyHoursLocationFilter/happy-hours-location-filter-popover.css";
import "./gift-location-filter-popover.css";
import {SegmentedOptions} from "../../../../../components/SegmentedGroup/index.jsx";
import {MarketplaceFilterProviderContext} from "../../../MarketplaceFilterContext.jsx";
import {giftLocationSegmentOptionTab} from "./consts.js";
import {giftSegmentedOptions} from "./segmented-options.jsx";
import {deliveryAddressOptions} from "../consts.js";
import classnames from "classnames";
import {BasePopoverFilter} from "../../basePopoverFilter.jsx";
import {useLocationFilter} from "../locationFilterHooks.jsx";

export const GiftLocationFilter = ({children}) => {
    const {innerState, updateInnerState, panel} = useContext(MarketplaceFilterProviderContext);
    const {updateAddressTitle, onClearAddress, didStateChange} = useLocationFilter();

    useEffect(() => {
        if (!innerState?.locationFilter?.currentTab) {
            updateInnerState(`locationFilter.currentTab`, giftLocationSegmentOptionTab.singledDeliveryTab);
        }
    }, []);

    const onTabChange = useCallback(selectedTab => {
        updateInnerState("locationFilter.currentTab", selectedTab);
        if (selectedTab === giftLocationSegmentOptionTab.multipleAddressTab) {
            updateInnerState(`locationFilter.${giftLocationSegmentOptionTab.multipleAddressTab}`, {
                deliveryType: deliveryAddressOptions.deliveryToAddresses,
                displayName: "הפצה למספר כתובות"
            });
        }
    }, []);

    const onOpenPopover = useCallback(() => {
        if (!innerState?.locationFilter?.currentTab) {
            onTabChange(giftLocationSegmentOptionTab.singledDeliveryTab);
        }
    }, [innerState?.locationFilter?.currentTab]);

    const onClear = useCallback(() => {
        onClearAddress();
        onTabChange(giftLocationSegmentOptionTab.singledDeliveryTab);
    }, []);

    return (
        <div>
            <ConfigProvider direction="rtl">
                <BasePopoverFilter
                    searchOnClose={didStateChange}
                    onClear={onClear}
                    onClosePopover={updateAddressTitle}
                    onOpenPopover={onOpenPopover}
                    className={classnames("gift-location-filter-popover", panel)}
                    title="לאן לשלוח"
                    content={
                        <div id="gift-location-filter">
                            <div id="happy-hour-location-options">
                                <SegmentedOptions
                                    value={innerState?.locationFilter?.currentTab}
                                    options={giftSegmentedOptions()}
                                    onChange={onTabChange}
                                />
                            </div>
                        </div>
                    }>
                    {children}
                </BasePopoverFilter>
            </ConfigProvider>
        </div>
    );
};
