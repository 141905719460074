export const AdminOrderSearchFields = {
    proposalId: "proposalId",
    providerName: "providerName",
    creatingUserEmail: "creatingUserEmail",
    email: "email",
    companyId: "companyId",
    creatingUserId: "creatingUserId",
    providerId: "providerId",
    eventId: "eventId"
};

export const DefaultAdminOrderSearchField = AdminOrderSearchFields.proposalId;

export const AdminOrderSearchFieldsToHebrew = {
    [AdminOrderSearchFields.eventId]: "מזהה הזמנה",
    [AdminOrderSearchFields.providerId]: "מזהה שותף",
    [AdminOrderSearchFields.email]: "מייל מבצע ההזמנה",
    [AdminOrderSearchFields.companyId]: "מזהה חברה",
    [AdminOrderSearchFields.proposalId]: "מספר הזמנה",
    [AdminOrderSearchFields.providerName]: "שם השותף"
};

export const AdminOrderSearchFieldsSettings = {
    [AdminOrderSearchFields.eventId]: {
        shouldShowDropdown: false
    },
    [AdminOrderSearchFields.providerId]: {
        shouldShowDropdown: false
    },
    [AdminOrderSearchFields.companyId]: {
        shouldShowDropdown: false
    },
    [AdminOrderSearchFields.proposalId]: {
        shouldShowDropdown: false
    },
    [AdminOrderSearchFields.providerName]: {
        shouldShowDropdown: true,
        shouldBlockSearchButton: true
    }
};

export const AdminOrderSearchFieldsToFieldId = {
    [AdminOrderSearchFields.providerName]: AdminOrderSearchFields.providerId,
    [AdminOrderSearchFields.email]: AdminOrderSearchFields.email
};

export const AdminOrderSearchProjection = [
    "companyContactEmail",
    "companyContactName",
    "companyPhone",
    "companyName",
    "providerBrand",
    "providerPhone",
    "providerEmail",
    "productName",
    "sessionName",
    "serviceId",
    "providerId",
    "productId",
    "paymentType",
    "createdAt",
    "dtstart",
    "until",
    "offeredPrice",
    "discount",
    "deliveryPrice",
    "originalOfferedPrice",
    "errors",
    "tip",
    "couponDiscountAmount",
    "companyDiscountAmount",
    "vat",
    "proposalId",
    "status",
    "assignedCSMs",
    "assignedPartners",
    "providerDiscount",
    "couponCode",
    "isVoucherOrder",
    "needManagerConfirm",
    "creatingUserId",
    "eventStatusMap",
    "shippingDetails",
    "shippingProviders",
    "v2",
    "deliveryArrivingTimeRange"
];
