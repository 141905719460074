import {Form} from "antd";
import React from "react";
import {PaymentPlan, ProviderPaymentSelection} from "./ProviderPaymentSelection";
import {ContactUsPhone, FormInput} from "../components/form";
import {Images} from "../images/Images";
import {ProfessionType} from "../data/professions";

export const ProviderSignUpPaymentSelection = props => {
    const {onNext, values, form, visible, professionType, onValuesChange, freePlanCompany} = props;

    return (
        <Form
            form={form}
            onFinish={vals => onNext(vals)}
            onValuesChange={onValuesChange}
            style={{
                padding: 10,
                maxWidth: 800,
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                flexDirection: "column",
                visibility: visible ? "visible" : "hidden"
            }}
            initialValues={{
                paymentPlan:
                    professionType === ProfessionType.REVENUE_BASED ? values.paymentPlan : PaymentPlan.PER_ACTIVE_DAY
            }}>
            <Form.Item
                name="paymentPlan"
                style={{marginBottom: 0}}
                rules={[{required: true, message: "Please select payment plan"}]}>
                {freePlanCompany ? (
                    <div
                        style={{
                            marginTop: 30,
                            marginBottom: 40,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: "wrap"
                        }}>
                        <span style={{textAlign: "center", fontSize: 16}}>
                            You <b style={{marginLeft: 3, marginRight: 3}}>won't</b> be charged for events scheduled
                            with <u style={{marginLeft: 3}}>{freePlanCompany.name}</u>.
                        </span>
                        <span style={{textAlign: "center", fontSize: 12, marginTop: 20}}>
                            * Please notice: 20% platform fees will be charged for events scheduled with other
                            companies.
                        </span>
                    </div>
                ) : professionType === ProfessionType.REVENUE_BASED ? (
                    <ProviderPaymentSelection />
                ) : (
                    <div style={{display: "flex", justifyContent: "center", position: "relative"}}>
                        <div
                            style={{
                                position: "relative",
                                backgroundImage: `url(${Images.paymentPerActiveDay})`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "contain",
                                cursor: "pointer",
                                width: 330,
                                height: 471,
                                margin: 10
                            }}
                        />
                    </div>
                )}
            </Form.Item>
            <Form.Item name="couponCode" style={{marginTop: 10}}>
                <FormInput style={{width: 275}} placeholder="Coupon code" />
            </Form.Item>
            <ContactUsPhone />
        </Form>
    );
};
