import React from "react";

export const ModalTitle = ({title, subTitle, titleStyle, subtitleStyle}) => (
    <div
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            marginTop: 10,
            marginBottom: 10,
            ...titleStyle
        }}>
        <label style={{lineHeight: 1, marginBottom: 10, ...subtitleStyle}}>{title}</label>
        {subTitle ? (
            <label
                style={{
                    lineHeight: 1,
                    fontSize: 22,
                    marginTop: 10,
                    fontWeight: 400,
                    opacity: 0.8,
                    textAlign: "center"
                }}>
                {subTitle}
            </label>
        ) : null}
    </div>
);
