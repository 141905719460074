export const SUB_ADMIN_EXCEL_EXAMPLE_FILE_URL =
    "https://res.cloudinary.com/well-b-prod/raw/upload/v1649324069/Assets/load_sub_admins_with_excel_example.xlsx";
export const SUB_ADMIN_CATEGORY_BUDGET_EXCEL_EXAMPLE_FILE_URL =
    "https://res.cloudinary.com/well-b-prod/raw/upload/v1676467408/Assets/load_sub_admins_with_excel_category_budget_example.xlsx";
export const SUB_ADMIN_EXCEL_EXAMPLE_FILE_NAME = "פורמט_טעינת_אדמינים.xlsx";
export const SUB_ADMIN_CATEGORY_BUDGET_EXCEL_EXAMPLE_FILE_NAME = "פורמט_טעינת_אדמינים_עם_תקציב_מבוזר.xlsx";

export const companyNumberToTier = {
    451: "A+",
    186: "A",
    101: "B",
    71: "C",
    0: "SMB"
};

export const companyTierBreakPoints = [
    {
        breakpoint: 451,
        name: "A+"
    },
    {
        breakpoint: 186,
        name: "A"
    },
    {
        breakpoint: 101,
        name: "B"
    },
    {
        breakpoint: 71,
        name: "C"
    },
    {
        breakpoint: 0,
        name: "SMB"
    }
];

export const LastOrderBreakPoints = [
    {
        breakpoint: 60,
        color: "var(--error-color)",
        excelColor: "FFC7CE"
    },
    {
        breakpoint: 30,
        color: "var(--warning-color)",
        excelColor: "FFEB9C"
    }
];

export const CustomerType = {
    Casual: "Casual",
    Returning: "Returning",
    ActiveReturning: "ActiveReturning",
    InactiveReturning: "InactiveReturning"
};
