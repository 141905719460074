import React from "react";
import logo from "../images/logo.png";
import {PageTitleLabel, PageSubTitleLabel} from "../components/PageTitle";

export const CompanyConfirmation = () => {
    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: 100, padding: 10}}>
                <div style={{display: "flex", justifyContent: "center", marginBottom: 10}}>
                    <img width={160} height={40} src={logo} alt="logo" />
                </div>
                <PageTitleLabel style={{fontSize: 30}}>Thank you for choosing us as your partner!</PageTitleLabel>
                <PageSubTitleLabel style={{marginBottom: 5, fontSize: 18}}>
                    This is a new beginning of our journey together creating the best employee experience.
                </PageSubTitleLabel>
                <PageSubTitleLabel style={{fontSize: 18}}>
                    We are working on your confirmation now and will contact you soon via email.
                </PageSubTitleLabel>
            </div>
        </div>
    );
};
