import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ConfigProvider, DatePicker, Form, message, Modal, Select, Switch, Tabs, Tooltip} from "antd";
import {cloneDeep, differenceBy, get, isEmpty, set} from "lodash";
import moment from "moment/moment";
import classnames from "classnames";
import {DeleteOutlined, PlusOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {AdditionalInfoTab} from "./Tabs/AdditionalInfoTab";
import {
    ActivityExtras,
    ActivityManagement,
    defaultPricingTable,
    defaultState,
    getLocations,
    getMinNoticeId,
    getMinNoticeTitle,
    kosherOptions,
    MinNoticeOptions,
    PresentationsRequest
} from "../AdminUploadUtils";
import {ProviderPriceByPeopleSelector} from "../ProviderPriceByPeopleSelector";
import {ProviderPriceByModular} from "../ProviderPriceByModular";
import {AdminProductSearchTags} from "../AdminProductSearchTags";
import {PriceTypes, PriceTypesByStep, PriceTypesToHebrew} from "../PriceSelectorHelper";
import {PortfolioIcon, TrashIcon} from "../../../icons";
import {
    ActivityLocationTypes,
    ProductUpgradeOptionsAmountTypeToName,
    ProviderActivityPossibleLocations,
    ServiceIdToName
} from "../../../consts.js";
import {
    DurationInput,
    EditableInput,
    PageSubTitleLabel,
    PageTitleHeader,
    SearchableSelect,
    SquareButton,
    StyledButton
} from "../../../components";
import {ProviderPortfolioUploadModal} from "../../../provider/ProviderPortfolioUploadModal";
import {addAddressAreaId} from "../AdminUploadHelper";
import {StepperInput} from "../../../wizards/components/StepperInput";
import {
    ACTIVITY_REQUESTS,
    FoodRequests,
    kosherTypes,
    kosherTypesToHebrew
} from "../../../marketplace/ProductsFilterModal/consts";
import {StepperDropdownInput} from "../../../wizards/components/StepperDropdownInput";
import {FormInput, FormInputV2} from "../../../components/form";
import {AdminUploadNewProductDeliveryPanel} from "../AdminUploadNewProductDeliveryPanel";
import {StepperParagraphInput} from "../../../wizards/components/StepperParagraphInput";
import {SeparatorLine} from "../../../components/SeparatorLine";
import {SmartSwitch} from "../../../components/SmartSwitch";
import {deliveryFormFields} from "../consts.jsx";
import "./admin-upload-new-product-modal.css";
import {isProductHasShippingProvider} from "../utils.js";
import common from "@well-b/well-b-shared";

const {RangePicker} = DatePicker;

const {Option, OptGroup} = Select;

export const AdminUploadNewProductModal = ({
    editProduct,
    services,
    onCancel,
    onNewProduct,
    onEditProduct,
    visibility,
    otherProductsDeliveryCities,
    providerSubProducts = [],
    providerDetails,
    deliveryProviders = []
}) => {
    const [formValues, setFormValues] = useState(defaultState());
    const [form] = Form.useForm();
    const [portfolioVisibility, setPortfolioVisibility] = useState(false);
    const [selectedFoodRequests, setSelectedFoodRequests] = useState([]);
    const [selectedActivityRequests, setSelectedActivityRequests] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [searchTags, setSearchTags] = useState([]);
    const [selectedPlaces, setSelectedPlaces] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [durationType, setDurationType] = useState(DurationInput.Type.MIN);
    const [selectedActivityExtras, setSelectedActivityExtras] = useState([]);
    const [popularQuestions, setPopularQuestions] = useState([]);
    const [addressNames, setAddressNames] = useState([]);
    const [selectedPresentations, setSelectedPresentations] = useState([]);
    const [selectedActivityManagement, setSelectedActivityManagement] = useState([]);
    const [subCategories, setSubCategories] = useState(null);
    const [description, setDescription] = useState(null);
    const [chosenPriceType, setChosenPriceType] = useState(null);
    const [disabledDatesInputValues, setDisabledDatesInputValues] = useState([]);

    const locations = getLocations();

    useEffect(() => {
        form.resetFields();
        setFormValues(defaultState());
        setSelectedCategory([]);
        setAddressNames([]);
        setPopularQuestions([{question: "", answer: ""}]);
        const tempProviderSubProducts = [...providerSubProducts];

        if (editProduct) {
            const tempEditProduct = cloneDeep(editProduct);

            if (!isEmpty(tempEditProduct.address)) {
                setAddressNames(tempEditProduct.address);
            }

            if (!isEmpty(tempEditProduct.popularQuestions)) {
                setPopularQuestions(
                    tempEditProduct.popularQuestions.map(({question, answer}) => ({
                        question,
                        answer
                    }))
                );
            }

            if (!isEmpty(tempEditProduct?.services)) {
                setSelectedCategory(tempEditProduct.services);
            }

            const diffSubProducts = differenceBy(
                tempProviderSubProducts,
                tempEditProduct?.pricingTable?.byModular?.subProducts,
                subProduct => subProduct.id
            );
            const subProductsToEnrich = [
                ...(tempEditProduct?.pricingTable?.byModular?.subProducts ?? []),
                ...(diffSubProducts ?? [])
            ];

            const allProviderSubProducts = subProductsToEnrich.map(subProduct => {
                const foundProviderSubProduct = tempProviderSubProducts?.find(({id}) => id === subProduct?.id);

                if (foundProviderSubProduct) {
                    return {
                        ...foundProviderSubProduct,
                        ...subProduct,
                        subProductCategory: foundProviderSubProduct?.subProductCategory,
                        price: foundProviderSubProduct.price,
                        defaultAmount: subProduct?.defaultAmount ?? 0,
                        isActive: subProduct?.isActive ?? false
                    };
                }

                return {...subProduct, isActive: false};
            });

            set(tempEditProduct, "pricingTable.byModular.subProducts", allProviderSubProducts);

            setFormValues({...defaultState(), ...tempEditProduct});
            form.setFieldsValue({...defaultState(), ...tempEditProduct});

            setDescription(tempEditProduct.description);
            setSubCategories(tempEditProduct.subCategories);
            setSearchTags(tempEditProduct.searchTags);
        } else {
            setDescription(null);
            setSubCategories([]);
            setSearchTags([]);

            const allProviderSubProducts = tempProviderSubProducts.map(providerSubProduct => {
                return {...providerSubProduct, isActive: false, amount: 0};
            });

            setFormValues(currentValues => ({
                ...currentValues,
                pricingTable: {
                    ...currentValues.pricingTable,
                    byModular: {
                        subProducts: allProviderSubProducts,
                        minPrice: 0
                    }
                }
            }));
        }
    }, [visibility]);

    useEffect(() => {
        setChosenPriceType(editProduct?.chosenPriceType);
    }, [editProduct]);

    const onFormValueChange = useCallback((field, val) => {
        setFormValues(prevValues => set({...prevValues}, field, val));
    }, []);

    const onFormValueAddElementToArray = useCallback((field, val) => {
        setFormValues(currentFormValues => {
            const fieldValue = get(currentFormValues, field, []);
            fieldValue.push(val);
            return set({...currentFormValues}, field, fieldValue);
        });
    }, []);

    useEffect(() => {
        if (!formValues?.productKosherType) {
            const koshers = [kosherOptions[0], kosherOptions[2]];
            if (koshers.includes(providerDetails?.kosher)) {
                onFormValueChange("productKosherType", kosherTypes.REGULAR);
            } else if (!providerDetails?.kosher) {
                onFormValueChange("productKosherType", kosherTypes.NOT_NEEDED);
            }
        }
    }, [providerDetails?.kosher, formValues?.productKosherType]);

    const onCategorySelect = value => {
        if (selectedCategory.length > 1) {
            return;
        }

        setSelectedCategory([value]);
    };

    const onCategoryDeselect = value => {
        const newCategorySelected = selectedCategory.filter(category => category !== value);
        setSelectedCategory(newCategorySelected);
    };

    const onLocationSelect = value => {
        const newPlacesSelected = selectedPlaces.concat([value]);
        setSelectedPlaces(newPlacesSelected);
    };

    const onLocationDeselect = value => {
        const newPlacesSelected = selectedPlaces.filter(place => place !== value);
        setSelectedPlaces(newPlacesSelected);
    };

    const onAreaSelect = value => {
        const loc = locations.find(loc => loc === value);
        setSelectedLocations(selectedLocations.concat([loc]));
    };

    const onAreaDeselect = value => {
        setSelectedLocations(selectedLocations.filter(loc => loc !== value));
    };

    const onActivityExtraSelect = value => {
        const need = ActivityExtras.find(need => need === value);
        setSelectedActivityExtras(selectedActivityExtras.concat([need]));
    };

    const onActivityExtraDeselect = value => {
        setSelectedActivityExtras(selectedActivityExtras.filter(need => need !== value));
    };

    const onActivityManagementSelect = value => {
        const need = ActivityManagement.find(need => need === value);
        setSelectedActivityManagement(selectedActivityManagement.concat([need]));
    };

    const onActivityManagementDeselect = value => {
        setSelectedActivityManagement(selectedActivityManagement.filter(need => need !== value));
    };

    const onFoodRequestsSelect = value => {
        const req = FoodRequests.find(req => req === value);
        setSelectedFoodRequests(selectedFoodRequests.concat([req]));
    };

    const onFoodRequestsDeselect = value => {
        setSelectedFoodRequests(selectedFoodRequests.filter(req => req !== value));
    };

    const onActivityRequestsSelect = value => {
        const req = ACTIVITY_REQUESTS.find(req => req === value);
        setSelectedActivityRequests(selectedActivityRequests.concat([req]));
    };

    const onActivityRequestsDeselect = value => {
        setSelectedActivityRequests(selectedActivityRequests.filter(req => req !== value));
    };

    const onPresentationSelect = value => {
        const req = PresentationsRequest.find(req => req === value);
        setSelectedPresentations(selectedPresentations.concat([req]));
    };

    const onPresentationDeselect = value => {
        setSelectedPresentations(selectedPresentations.filter(req => req !== value));
    };

    const onLanguagesSelect = value => {
        const newLangsSelected = selectedLanguages.concat([value]);
        setSelectedLanguages(newLangsSelected);
        onFormValueChange("languages", newLangsSelected);
    };

    const onLanguagesDeselect = value => {
        const newLangsSelected = selectedLanguages.filter(lang => lang !== value);
        setSelectedLanguages(newLangsSelected);
        onFormValueChange("languages", newLangsSelected);
    };

    // TODO: fix this issue later
    const [descriptionRefresh, setDescriptionRefresh] = useState(1);
    const onDescriptionChange = html => {
        setDescription(html);
        onFormValueChange("description", html);
    };

    const removeUpgradeOption = useCallback(
        index => {
            const newOptions = [...(formValues.upgradeOptions?.options || [])];
            newOptions.splice(index, 1);

            onFormValueChange("upgradeOptions.options", newOptions);
        },
        [formValues.upgradeOptions, onFormValueChange]
    );

    const updateAddressField = useCallback((updateValues, index) => {
        setAddressNames(currentAddressNames =>
            currentAddressNames.map((currentItems, idx) => {
                if (idx === index) {
                    return {
                        ...currentItems,
                        ...updateValues
                    };
                }
                return currentItems;
            })
        );
    }, []);

    const updatePopularQuestionField = useCallback((updateValues, index) => {
        setPopularQuestions(current =>
            current.map((item, idx) => {
                if (idx === index) {
                    return {
                        ...item,
                        ...updateValues
                    };
                }
                return item;
            })
        );
    }, []);

    const onFinish = useCallback(
        async values => {
            const productDetails = {...values, ...formValues};
            productDetails.address = addressNames.filter(address => !!address.siteId);

            const validationResponse = common.isProductValid({
                ...productDetails,
                chosenPriceType,
                services: selectedCategory
            });
            if (validationResponse?.error) {
                message.error(validationResponse.error);
                return;
            }

            productDetails.address = await addAddressAreaId(productDetails.address);
            productDetails.popularQuestions = popularQuestions.filter(
                popularQuestion => popularQuestion.question !== ""
            );
            productDetails.chosenPriceType = chosenPriceType;
            productDetails.searchTags = searchTags;

            if (productDetails.pricingTable.byModular) {
                const transformSubProducts = productDetails?.pricingTable?.byModular?.subProducts?.map(subProduct => {
                    const {id, defaultAmount, isActive} = subProduct;
                    return {id, defaultAmount, isActive};
                });

                productDetails.pricingTable = {
                    ...productDetails.pricingTable,
                    byModular: {...productDetails.pricingTable.byModular, subProducts: transformSubProducts}
                };
            }

            if (productDetails.upgradeOptions?.options) {
                productDetails.upgradeOptions.options = productDetails.upgradeOptions.options.filter(
                    option => option.name
                );
            }

            if (subCategories) {
                productDetails.subCategories = subCategories;
            }

            if (selectedCategory) {
                productDetails.services = selectedCategory;
            }

            if (editProduct) {
                onEditProduct(productDetails);
            } else {
                onNewProduct(productDetails);
            }
        },
        [
            chosenPriceType,
            formValues,
            addressNames,
            popularQuestions,
            searchTags,
            subCategories,
            editProduct,
            onEditProduct,
            onNewProduct,
            selectedCategory
        ]
    );

    const brandOptions = useMemo(() => {
        const options = (providerDetails?.brandNames ?? []).map(brandName => ({value: brandName, label: brandName}));
        const defaultOption = {value: null};
        return [defaultOption, ...options];
    }, [providerDetails?.brandNames]);

    const onHebrewBrandNameChange = useCallback(selectedHebrewBrandName => {
        onFormValueChange("brandName", selectedHebrewBrandName);
    }, []);

    const onEnglishBrandNameChange = useCallback(selectedEnglishBrandName => {
        onFormValueChange("engBrandName", selectedEnglishBrandName);
    }, []);

    const onDeliveryCommissionChange = useCallback(
        event => {
            if (isEmpty(event.target.value)) {
                onFormValueChange("deliveryCommission", null);
                return;
            }
            onFormValueChange("deliveryCommission", parseInt(event.target.value));
        },
        [onFormValueChange]
    );

    const onSelectSite = useCallback(
        siteId => {
            const selectedAddress = providerDetails?.sites?.find(address => address.siteId === siteId);
            if (selectedAddress) {
                setAddressNames(currentAddressNames => [...currentAddressNames, {...selectedAddress.address, siteId}]);
            }
        },
        [providerDetails?.sites]
    );

    const onDeselectSite = useCallback(siteId => {
        setAddressNames(currentAddressNames => currentAddressNames.filter(address => address.siteId !== siteId));
    }, []);

    return (
        <Modal
            centered={true}
            width={900}
            className="admin-upload-new-product-modal"
            destroyOnClose={true}
            open={visibility}
            onCancel={onCancel}
            footer={null}
            maskClosable={false}
            getContainer={false}
            title={null}
            closable={true}>
            <>
                <PageTitleHeader showBack={false}>{editProduct ? "עריכת מוצר" : "הוספת מוצר"}</PageTitleHeader>

                <ProviderPortfolioUploadModal
                    onClose={() => setPortfolioVisibility(false)}
                    onSave={portfolioImages => {
                        onFormValueChange("portfolioImages", portfolioImages);
                        setPortfolioVisibility(false);
                    }}
                    providerDetails={formValues}
                    providerNonExist={true}
                    visible={portfolioVisibility}
                />

                <Form
                    form={form}
                    className="upload-product-form"
                    onFinish={onFinish}
                    initialValues={defaultState()}
                    onValuesChange={value => {
                        setFormValues(prev => ({
                            ...prev,
                            ...value
                        }));
                    }}
                    scrollToFirstError={true}
                    onFinishFailed={({errorFields}) => {
                        message.error("חסרים לך כמה שדות, שים לב!", 3);
                    }}>
                    <ConfigProvider direction="rtl">
                        <Tabs
                            centered={true}
                            items={[
                                {
                                    label: "מידע כללי ותיאור",
                                    key: "tab1",
                                    children: (
                                        <div className="tab-container">
                                            <div className="multiple-inputs">
                                                <div className="single-input">
                                                    <label>
                                                        {"קטגוריה"}
                                                        <Tooltip title="יש באפשרותכם לשייך מוצר/שירות לקטגוריה אחת או יותר עבורה הוא מתאים.">
                                                            <QuestionCircleOutlined style={{cursor: "pointer"}} />
                                                        </Tooltip>
                                                    </label>
                                                    <Form.Item
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "שדה חובה"
                                                            }
                                                        ]}>
                                                        <SearchableSelect
                                                            id="product-category"
                                                            mode="multiple"
                                                            status={!selectedCategory?.length ? "error" : null}
                                                            onDeselect={value => onCategoryDeselect(value)}
                                                            onSelect={value => onCategorySelect(value)}
                                                            className="wb-profession-selection"
                                                            value={selectedCategory ?? []}
                                                            placeholder="קטגוריה">
                                                            {services?.map(service => {
                                                                const {serviceId, categoryName} = service;
                                                                return (
                                                                    <Option key={serviceId} value={serviceId}>
                                                                        {ServiceIdToName[serviceId] ?? categoryName}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </SearchableSelect>
                                                    </Form.Item>
                                                </div>
                                                <div className="single-input">
                                                    <label>
                                                        {"שם מותג -עברית"}
                                                        <Tooltip title="בחרו שם שיווקי למוצר/שירות שיסביר מה אתם מוכרים בצורה הטובה ביותר, במידה והשדה חסר יושמש השם באנגלית">
                                                            <QuestionCircleOutlined style={{cursor: "pointer"}} />
                                                        </Tooltip>
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        direction="rtl"
                                                        placeholder="שם מותג בעברית"
                                                        value={formValues?.brandName || null}
                                                        onChange={onHebrewBrandNameChange}
                                                        options={brandOptions}
                                                    />
                                                </div>
                                                <div className="single-input" style={{flexGrow: 1}}>
                                                    <label>
                                                        {"שם מותג - אנגלית"}
                                                        <Tooltip title="בחרו שם שיווקי למוצר/שירות שיסביר מה אתם מוכרים בצורה הטובה ביותר, במידה והשדה חסר יושמש השם בעברית">
                                                            <QuestionCircleOutlined style={{cursor: "pointer"}} />
                                                        </Tooltip>
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        direction="rtl"
                                                        placeholder="שם מותג באנגלית"
                                                        value={formValues?.engBrandName || null}
                                                        onChange={onEnglishBrandNameChange}
                                                        options={brandOptions}
                                                    />
                                                </div>
                                            </div>

                                            <div className="multiple-inputs">
                                                <div className="single-input">
                                                    <label>
                                                        {"שם הפעילות"}
                                                        <Tooltip title="בחרו שם שיווקי למוצר/שירות שיסביר מה אתם מוכרים בצורה הטובה ביותר">
                                                            <QuestionCircleOutlined style={{cursor: "pointer"}} />
                                                        </Tooltip>
                                                    </label>
                                                    <Form.Item
                                                        name="productName"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "שדה חובה"
                                                            }
                                                        ]}>
                                                        <StepperInput
                                                            className="product-action-name"
                                                            placeholder="שם הפעילות"
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className={classnames("single-input", "commission-input")}>
                                                    <label>עמלת מערכת</label>
                                                    <Form.Item
                                                        name="commission"
                                                        rules={[{required: true, message: "נא לבחור עמלה"}]}>
                                                        <FormInput
                                                            type="number"
                                                            onWheel={e => e.target.blur()}
                                                            placeholder="לשימוש מנהל המערכת בלבד"
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className={classnames("single-input", "commission-input")}>
                                                    <label>עמלת שילוח</label>
                                                    <Form.Item>
                                                        <FormInput
                                                            type="number"
                                                            value={formValues?.deliveryCommission}
                                                            onWheel={e => e.target.blur()}
                                                            onChange={onDeliveryCommissionChange}
                                                            placeholder="עמלת שילוח"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="single-input">
                                                <Form.Item>
                                                    <AdminProductSearchTags
                                                        productTags={searchTags}
                                                        setProductTags={setSearchTags}
                                                        productId={editProduct?.productId}
                                                    />
                                                </Form.Item>
                                            </div>

                                            <div className="mixed-inputs">
                                                <div className="multiple-inputs">
                                                    <div className="single-input">
                                                        <label>
                                                            {"תיאור הפעילות"}
                                                            <Tooltip title="יש לתאר בפירוט מה הפעילות כוללת ועבור כמה אנשים היא מתאימה.">
                                                                <QuestionCircleOutlined style={{cursor: "pointer"}} />
                                                            </Tooltip>
                                                        </label>
                                                    </div>

                                                    <div className="single-input">
                                                        <StyledButton
                                                            className="single-button"
                                                            style={{height: 25, alignSelf: "end"}}
                                                            onClick={() => {
                                                                onDescriptionChange(null);
                                                                setDescriptionRefresh(current => current + 1);
                                                                message.success("השדה תיאור הפעילות נמחק בהצלחה");
                                                            }}>
                                                            מחק שדה
                                                        </StyledButton>
                                                    </div>
                                                </div>

                                                <div className="single-input">
                                                    <Form.Item
                                                        name="description"
                                                        valuePropName="description"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "שדה חובה"
                                                            }
                                                        ]}>
                                                        <EditableInput
                                                            key={descriptionRefresh}
                                                            style={{
                                                                width: "100%",
                                                                minHeight: "20em",
                                                                border: "1px solid #E8EDF5",
                                                                backgroundColor: "#FCFDFE",
                                                                fontSize: "16px",
                                                                borderRadius: "5px",
                                                                outline: "none"
                                                            }}
                                                            onChange={html => onDescriptionChange(html)}
                                                            value={description}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="multiple-inputs">
                                                <div className="single-input">
                                                    <label>הוספת תמונות למוצר</label>
                                                    <StyledButton
                                                        icon={<PortfolioIcon style={{marginRight: 11, marginTop: 2}} />}
                                                        style={{
                                                            borderRadius: 10,
                                                            backgroundColor: "#273444",
                                                            color: "white",
                                                            width: 160
                                                        }}
                                                        onClick={() => setPortfolioVisibility(true)}>
                                                        הוספת תמונות
                                                    </StyledButton>
                                                </div>

                                                <div className="single-input">
                                                    <label>הגבלת תאריכים</label>
                                                    <div className="admin-upload-new-product-disabled-dates-range-picker">
                                                        <RangePicker
                                                            format={"DD-MM-YYYY"}
                                                            onChange={setDisabledDatesInputValues}
                                                        />
                                                        <SquareButton
                                                            onClick={() => {
                                                                if (
                                                                    !disabledDatesInputValues[0] ||
                                                                    !disabledDatesInputValues[1]
                                                                ) {
                                                                    message.error(
                                                                        "יש לבחור תאריכים לפני לחיצה על אישור"
                                                                    );
                                                                    return;
                                                                }
                                                                setFormValues(prev => ({
                                                                    ...prev,
                                                                    disabledDates: [
                                                                        ...(prev.disabledDates ?? []),
                                                                        {
                                                                            from: disabledDatesInputValues[0]?.valueOf(),
                                                                            to: disabledDatesInputValues[1]?.valueOf()
                                                                        }
                                                                    ]
                                                                }));
                                                            }}>
                                                            הוסף
                                                        </SquareButton>
                                                    </div>
                                                    <div>
                                                        {formValues?.disabledDates?.map(({from, to}, index) => (
                                                            <div
                                                                className="admin-upload-new-product-disabled-dates-line"
                                                                key={`${to}-${from}-${index}`}>
                                                                {moment(from).format("DD/MM/YY")} -{" "}
                                                                {moment(to).format("DD/MM/YY")}
                                                                <TrashIcon
                                                                    onClick={() => {
                                                                        setFormValues(prev => {
                                                                            const newDisabledDates = [
                                                                                ...(prev.disabledDates || [])
                                                                            ];
                                                                            newDisabledDates.splice(index, 1);
                                                                            return {
                                                                                ...prev,
                                                                                disabledDates: newDisabledDates
                                                                            };
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="single-input">
                                                <label>סטטוס מוצר בספרייה</label>
                                                <Form.Item name="isActive">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            justifyContent: "space-between",
                                                            width: "150px",
                                                            marginTop: "10px"
                                                        }}>
                                                        <label style={{fontSize: 16, color: "var(--secondary-color)"}}>
                                                            לא מוצג
                                                        </label>
                                                        <Switch
                                                            onChange={isActive =>
                                                                onFormValueChange("isActive", isActive)
                                                            }
                                                            disabled={!providerDetails?.approved}
                                                            checked={formValues.isActive}
                                                            style={{
                                                                backgroundColor: formValues.isActive
                                                                    ? "#1260D1"
                                                                    : "#767F90"
                                                            }}
                                                        />
                                                        <label style={{fontSize: 16, color: "var(--secondary-color)"}}>
                                                            מוצג
                                                        </label>
                                                    </div>
                                                </Form.Item>
                                            </div>

                                            <div className="single-input">
                                                <label>הרשאת צפייה לחברות למוצר</label>

                                                {formValues?.allowedCompanies?.length ? (
                                                    formValues.allowedCompanies.map(companyId => (
                                                        <div>
                                                            <span>{companyId}</span>
                                                            <span>
                                                                <DeleteOutlined
                                                                    onClick={() => {
                                                                        onFormValueChange(
                                                                            "allowedCompanies",
                                                                            formValues.allowedCompanies.filter(
                                                                                current => current !== companyId
                                                                            )
                                                                        );
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <span>אין חברות מורשות</span>
                                                )}

                                                <FormInput
                                                    value={formValues.allowedCompaniesInput}
                                                    onChange={e =>
                                                        onFormValueChange("allowedCompaniesInput", e.target.value)
                                                    }
                                                    placeholder="מזהה חברה במערכת"
                                                    selectorStyle={{minWidth: "80px", paddingLeft: "8px"}}
                                                />

                                                <div
                                                    onClick={() => {
                                                        if (
                                                            formValues?.allowedCompaniesInput?.length &&
                                                            !formValues?.allowedCompanies.includes(
                                                                formValues?.allowedCompaniesInput
                                                            )
                                                        ) {
                                                            onFormValueAddElementToArray(
                                                                "allowedCompanies",
                                                                formValues?.allowedCompaniesInput
                                                            );
                                                        }
                                                    }}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "10px",
                                                        fontSize: "14px",
                                                        color: "#797EFF",
                                                        cursor: "pointer"
                                                    }}>
                                                    <PlusOutlined /> {"הוספת מזהה חברה"}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                },
                                {
                                    label: "הרכב החבילה",
                                    key: "tab2",
                                    children: (
                                        <>
                                            <div className="mixed-inputs">
                                                <div className="single-input">
                                                    <label>המלצת כמות משתתפים לחבילה</label>
                                                </div>

                                                <div className="multiple-inputs">
                                                    <div className="single-input">
                                                        <Form.Item name="minRecomendedProductParticipants">
                                                            <StepperInput placeholder="מינימום משתתפים" />
                                                        </Form.Item>
                                                    </div>
                                                    <div>
                                                        <label>עד</label>
                                                    </div>
                                                    <div className="single-input">
                                                        <Form.Item name="maxRecomendedProductParticipants">
                                                            <StepperInput placeholder="מקסימום משתתפים" />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="single-input">
                                                <label>סוג הכשרות של המוצר</label>

                                                <Form.Item name="productKosherType">
                                                    <Select
                                                        key={formValues?.productKosherType}
                                                        placeholder="בחרו את הסוג הספציפי של הכשרות של המוצר"
                                                        defaultValue={formValues?.productKosherType}
                                                        value={formValues?.productKosherType}
                                                        onSelect={newProductKosherType => {
                                                            onFormValueChange(
                                                                "productKosherType",
                                                                newProductKosherType
                                                            );
                                                        }}
                                                        disabled={providerDetails?.kosher === kosherOptions[1]}>
                                                        {Object.entries(kosherTypesToHebrew).map(([key, value]) => (
                                                            <Option key={key} value={key}>
                                                                {value}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </div>

                                            <div className="single-input">
                                                <label>הרכב החבילה</label>
                                                <Form.Item name="packageInfo">
                                                    <StepperParagraphInput
                                                        placeholder="הכנס כאן את הרכב החבילה"
                                                        maxLength={2500}
                                                        autoSize={{maxRows: 8, minRows: 3}}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </>
                                    )
                                },
                                {
                                    label: "מידע נוסף",
                                    key: "tab3",
                                    children: (
                                        <AdditionalInfoTab
                                            formValues={formValues}
                                            onFoodRequestsSelect={onFoodRequestsSelect}
                                            onFoodRequestsDeselect={onFoodRequestsDeselect}
                                            onActivityRequestsDeselect={onActivityRequestsDeselect}
                                            onActivityRequestsSelect={onActivityRequestsSelect}
                                            onPresentationDeselect={onPresentationDeselect}
                                            onPresentationSelect={onPresentationSelect}
                                            durationType={durationType}
                                            setDurationType={setDurationType}
                                            onLocationDeselect={onLocationDeselect}
                                            onLocationSelect={onLocationSelect}
                                            onAreaDeselect={onAreaDeselect}
                                            onAreaSelect={onAreaSelect}
                                            addressNames={addressNames}
                                            setAddressNames={setAddressNames}
                                            updateAddressField={updateAddressField}
                                            onLanguagesDeselect={onLanguagesDeselect}
                                            onLanguagesSelect={onLanguagesSelect}
                                            onActivityExtraDeselect={onActivityExtraDeselect}
                                            onActivityExtraSelect={onActivityExtraSelect}
                                            onActivityManagementDeselect={onActivityManagementDeselect}
                                            onActivityManagementSelect={onActivityManagementSelect}
                                            onSelectSite={onSelectSite}
                                            onDeselectSite={onDeselectSite}
                                            sites={providerDetails?.sites ?? []}
                                        />
                                    )
                                },
                                {
                                    label: "תנאי שילוח",
                                    key: "tab4",
                                    children: (
                                        <>
                                            <div className="single-input">
                                                <label>מינימום זמן הודעה מראש לקבלת ההזמנה</label>
                                                <Form.Item name="minNotice" valuePropName="minNotice">
                                                    <StepperDropdownInput
                                                        style={{width: "100%"}}
                                                        arrowStyle={{right: "-30px", top: "15px"}}
                                                        value={getMinNoticeTitle(formValues.minNotice)}
                                                        items={MinNoticeOptions.map(o => ({
                                                            label: o.title,
                                                            key: o.title
                                                        }))}
                                                        onChange={title =>
                                                            onFormValueChange("minNotice", getMinNoticeId(title))
                                                        }
                                                    />
                                                </Form.Item>
                                            </div>

                                            <AdminUploadNewProductDeliveryPanel
                                                otherProductsDeliveryAreas={otherProductsDeliveryCities}
                                                deliveryOptions={formValues?.deliveryOptions}
                                                homeDeliveryPrice={formValues?.homeDeliveryPrice}
                                                deliveryPrice={formValues?.deliveryPrice}
                                                onFormValueChange={onFormValueChange}
                                                doesProviderArriveToCustomer={formValues?.doesProviderArriveToCustomer}
                                                isUsingProviderDeliveryOptions={
                                                    formValues.isUsingProviderDeliveryOptions
                                                }
                                                disabledFields={[deliveryFormFields.maxShippingProvidersDeliveryPrice]}
                                                selectedShippingProviders={formValues?.shippingProviders}
                                                shippingProviders={deliveryProviders}
                                            />
                                        </>
                                    )
                                },
                                {
                                    label: "תמחור",
                                    key: "tab5",
                                    children: (
                                        <>
                                            <div className="tab-container">
                                                <div className="single-input">
                                                    <label>צורת תמחור (כולל מע״מ)</label>
                                                    <Select
                                                        id="price-kind"
                                                        placeholder={"סוג התמחור למוצר"}
                                                        value={chosenPriceType}
                                                        onSelect={newChosenPriceType =>
                                                            setChosenPriceType(newChosenPriceType)
                                                        }>
                                                        {Object.entries(PriceTypesToHebrew).map(([key, value]) => (
                                                            <Option key={key} value={key}>
                                                                {value}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </div>

                                                <div className="single-input">
                                                    <Form.Item
                                                        rules={[
                                                            {
                                                                validator() {
                                                                    if (!chosenPriceType)
                                                                        return Promise.reject(
                                                                            "עליך לבחור את סוג התמחור"
                                                                        );
                                                                    return Promise.resolve();
                                                                }
                                                            }
                                                        ]}>
                                                        {Object.values(PriceTypesByStep).includes(chosenPriceType) ? (
                                                            <ProviderPriceByPeopleSelector
                                                                chosenPriceType={chosenPriceType}
                                                                onChosenTypeChange={type => setChosenPriceType(type)}
                                                                activeFields={Object.values(PriceTypesByStep)}
                                                                value={formValues.pricingTable || defaultPricingTable}
                                                                onChange={updatedTable =>
                                                                    onFormValueChange("pricingTable", updatedTable)
                                                                }
                                                                toggleStepPricing={newValue =>
                                                                    onFormValueChange("stepPricing", newValue)
                                                                }
                                                                isStepPricing={formValues.stepPricing}
                                                            />
                                                        ) : null}

                                                        {chosenPriceType === PriceTypes.ByModular ? (
                                                            <ProviderPriceByModular
                                                                chosenPriceType={chosenPriceType}
                                                                onChosenTypeChange={type => setChosenPriceType(type)}
                                                                providerSubProducts={providerSubProducts}
                                                                subProducts={
                                                                    formValues.pricingTable?.byModular?.subProducts ||
                                                                    defaultPricingTable.byModular
                                                                }
                                                                minPrice={
                                                                    formValues.pricingTable?.byModular?.minPrice ?? 0
                                                                }
                                                                onChange={updatedTable =>
                                                                    onFormValueChange(
                                                                        "pricingTable.byModular",
                                                                        updatedTable
                                                                    )
                                                                }
                                                            />
                                                        ) : null}
                                                    </Form.Item>
                                                    <div className="admin-upload-new-product-advance-switch">
                                                        <label
                                                            style={{
                                                                fontSize: 16,
                                                                color: "var(--secondary-color)",
                                                                fontWeight: "bold"
                                                            }}>
                                                            להציג מודל טיפים
                                                        </label>
                                                        <Form.Item name="allowTipping">
                                                            <SmartSwitch
                                                                checked={formValues.allowTipping}
                                                                onChange={isActive =>
                                                                    onFormValueChange("allowTipping", isActive)
                                                                }
                                                                checkedText="מוצג"
                                                                unCheckedText="לא מוצג"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <ConfigProvider direction="rtl">
                                                    <div className="admin-upload-new-product-upgrade-options">
                                                        <PageSubTitleLabel
                                                            style={{
                                                                marginTop: 30,
                                                                marginBottom: 10,
                                                                fontSize: 20,
                                                                color: "#ED7FA6",
                                                                textDecoration: "underline"
                                                            }}>
                                                            אפשרויות שדרוג
                                                        </PageSubTitleLabel>
                                                        <div className="admin-upload-new-product-upgrade-options-first-row">
                                                            <label>תמחור עבור אפשרויות</label>
                                                            <div className="admin-upload-new-product-upgrade-option-file">
                                                                <Switch
                                                                    checked={
                                                                        formValues.upgradeOptions?.enableUploadFiles
                                                                    }
                                                                    onChange={value =>
                                                                        onFormValueChange(
                                                                            "upgradeOptions.enableUploadFiles",
                                                                            value
                                                                        )
                                                                    }
                                                                />
                                                                <label>מודל טעינת קבצים</label>
                                                            </div>
                                                        </div>
                                                        {[...(formValues.upgradeOptions?.options || []), {}].map(
                                                            ({name, type, price}, index) => (
                                                                <div
                                                                    key={`${index}`}
                                                                    className="admin-upload-new-product-upgrade-options-input">
                                                                    <Form.Item>
                                                                        <FormInputV2
                                                                            id="upgrade-options-option-name"
                                                                            onChange={({target}) =>
                                                                                onFormValueChange(
                                                                                    [
                                                                                        "upgradeOptions",
                                                                                        "options",
                                                                                        index,
                                                                                        "name"
                                                                                    ],
                                                                                    target.value
                                                                                )
                                                                            }
                                                                            value={name}
                                                                            placeholder="שם האפשרות"
                                                                        />
                                                                    </Form.Item>
                                                                    <Form.Item>
                                                                        <Select
                                                                            value={type}
                                                                            onSelect={value =>
                                                                                onFormValueChange(
                                                                                    [
                                                                                        "upgradeOptions",
                                                                                        "options",
                                                                                        index,
                                                                                        "type"
                                                                                    ],
                                                                                    value
                                                                                )
                                                                            }
                                                                            placeholder="סוג תמחור">
                                                                            {Object.entries(
                                                                                ProductUpgradeOptionsAmountTypeToName
                                                                            ).map(([key, displayValue]) => (
                                                                                <Option value={key} key={key}>
                                                                                    {displayValue}
                                                                                </Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Form.Item>
                                                                    <Form.Item>
                                                                        <FormInputV2
                                                                            value={price}
                                                                            className="admin-upload-new-product-upgrade-options-price-input"
                                                                            onChange={({target}) =>
                                                                                onFormValueChange(
                                                                                    `upgradeOptions.options[${index}].price`,
                                                                                    Number(target.value)
                                                                                )
                                                                            }
                                                                            type="number"
                                                                            placeholder="מחיר"
                                                                        />
                                                                    </Form.Item>

                                                                    <TrashIcon
                                                                        onClick={() => removeUpgradeOption(index)}
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </ConfigProvider>
                                            </div>
                                        </>
                                    )
                                },
                                {
                                    label: "שאלות ותשובות",
                                    key: "tab6",
                                    children: (
                                        <>
                                            <div className="single-input">
                                                <label>שאלות ותשובות נפוצות אודות המוצר</label>
                                                <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                                                    {popularQuestions.map(({question, answer}, index) => {
                                                        return (
                                                            <div
                                                                key={`${index}`}
                                                                style={{
                                                                    display: "flex",
                                                                    gap: "20px"
                                                                }}>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        gap: "10px",
                                                                        justifyContent: "space-between",
                                                                        width: "100%"
                                                                    }}>
                                                                    <StepperInput
                                                                        value={question}
                                                                        placeholder="שאלה נפוצה"
                                                                        onChange={e =>
                                                                            updatePopularQuestionField(
                                                                                {
                                                                                    question: e.target.value
                                                                                },
                                                                                index
                                                                            )
                                                                        }
                                                                    />

                                                                    <StepperParagraphInput
                                                                        value={answer}
                                                                        onChange={e =>
                                                                            updatePopularQuestionField(
                                                                                {
                                                                                    answer: e.target.value
                                                                                },
                                                                                index
                                                                            )
                                                                        }
                                                                        placeholder="תשובה"
                                                                        maxLength={2000}
                                                                        autoSize={{maxRows: 4, minRows: 2}}
                                                                    />
                                                                </div>

                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center"
                                                                    }}>
                                                                    <TrashIcon
                                                                        style={{
                                                                            fill: "black",
                                                                            cursor: "pointer"
                                                                        }}
                                                                        onClick={() => {
                                                                            setPopularQuestions(current =>
                                                                                current.filter(
                                                                                    (item, idx) => index !== idx
                                                                                )
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        const isEmptyQuestionExist = popularQuestions?.find(
                                                            ({question}) => question === ""
                                                        );

                                                        if (!isEmptyQuestionExist) {
                                                            setPopularQuestions(currentQuestions => [
                                                                ...currentQuestions,
                                                                {
                                                                    question: "",
                                                                    answer: ""
                                                                }
                                                            ]);
                                                        }
                                                    }}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "10px",
                                                        fontSize: "14px",
                                                        color: "#797EFF",
                                                        cursor: "pointer"
                                                    }}>
                                                    <PlusOutlined /> {"הוספת שאלה"}
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            ]}
                        />
                    </ConfigProvider>

                    <SeparatorLine />

                    <div className="action-buttons">
                        <StyledButton id="save-product-button" className="confirm-button" htmlType="submit">
                            {editProduct ? "עדכן מוצר קיים" : "צור מוצר חדש"}
                        </StyledButton>
                    </div>
                </Form>
            </>
        </Modal>
    );
};
