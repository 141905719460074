import React, {useState} from "react";
import styled from "styled-components";
import {SketchPicker, BlockPicker} from "react-color";
import {CloseIcon} from "../icons";

const ColorButton = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border: 1px solid #ededed;
    background-color: ${({color}) => (color ? color : "transparent")};
    cursor: pointer;
`;

const defaultColors = [
    "#ff4d4f",
    "#a8071a",
    "#ff7a45",
    "#ffa940",
    "#ffc53d",
    "#ffec3d",
    "#fadb14",
    "#73d13d",
    "#3f6600",
    "#36cfc9",
    "#40a9ff",
    "#597ef7",
    "#0050b3",
    "#9254de",
    "#ffadd2",
    "#f759ab",
    "#874d00",
    "#bfbfbf",
    "#8c8c8c",
    "#1f1f1f"
];

export const SketchColorPicker = ({onColorPick, color, colors, onClose, style, closeStyle}) => {
    return (
        <div
            style={{
                position: "absolute",
                zIndex: 2,
                left: 70,
                border: "1px solid #ededed",
                borderRadius: 10,
                overflow: "hidden",
                ...style
            }}>
            <div style={{position: "relative"}}>
                <div
                    style={{position: "absolute", right: 2, top: 0, zIndex: 3, cursor: "pointer", ...closeStyle}}
                    onClick={onClose}>
                    <CloseIcon fill="#ccc" />
                </div>
                <SketchPicker
                    presetColors={colors || defaultColors}
                    color={color}
                    width={style ? style.width : void 0}
                    onChangeComplete={onColorPick}
                />
            </div>
        </div>
    );
};

export const BlockColorPicker = ({onColorPick, color, colors, onClose, style, closeStyle}) => {
    return (
        <div
            style={{
                position: "absolute",
                zIndex: 2,
                left: 70,
                border: "1px solid #ededed",
                borderRadius: 10,
                overflow: "hidden",
                ...style
            }}>
            <div style={{position: "relative"}}>
                <div
                    style={{position: "absolute", right: 8, top: 8, zIndex: 3, cursor: "pointer", ...closeStyle}}
                    onClick={onClose}>
                    <CloseIcon fill="#ccc" />
                </div>
                <BlockPicker
                    colors={colors || defaultColors}
                    color={color}
                    width={style ? style.width : void 0}
                    onChangeComplete={onColorPick}
                    triangle="hide"
                />
            </div>
        </div>
    );
};

export const ColorPicker = ({
    label,
    pickerVisible,
    onPickerShow,
    onPickerHide,
    onColorPick,
    color,
    style,
    pickerStyle,
    hideButton
}) => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: 300,
                marginBottom: 10,
                position: "relative",
                ...style
            }}>
            <span>{label}</span>
            {!hideButton ? <ColorButton onClick={() => onPickerShow()} color={color || null} /> : null}
            {pickerVisible ? (
                <SketchColorPicker
                    onClose={() => onPickerHide()}
                    onColorPick={onColorPick}
                    color={color || null}
                    style={pickerStyle}
                />
            ) : null}
        </div>
    );
};

export const ColorPickerButton = ({
    onChange,
    color,
    buttonStyle,
    pickerStyle,
    colors = [
        "#69A6F7",
        "#F7B348",
        "var(--secondary-color)",
        "#2B2E37",
        "#3597A0",
        "#F06060",
        "#FD815D",
        "#E6C187",
        "#F4D664",
        "#B4ACE1",
        "#465680",
        "#13C296",
        "#767F90",
        "#2F3E83",
        "#BF7AA9",
        "#2EA5C1",
        "#E3B9B8",
        "#90BFDD"
    ]
}) => {
    const [pickerVisible, setPickerVisible] = useState(false);

    const togglePickerVisibilityState = isVisible => {
        setPickerVisible(isVisible);
    };

    return (
        <div
            onClick={() => togglePickerVisibilityState(true)}
            style={{
                height: 50,
                width: 50,
                backgroundColor: color,
                borderRadius: 25,
                border: "5px solid white",
                cursor: "pointer",
                position: "relative",
                ...buttonStyle
            }}>
            {pickerVisible ? (
                <BlockColorPicker
                    onClose={e => {
                        e.stopPropagation();
                        togglePickerVisibilityState(false);
                    }}
                    onColorPick={({hex}) => onChange(hex)}
                    color={color || null}
                    colors={colors}
                    style={{width: 202, ...pickerStyle}}
                />
            ) : null}
        </div>
    );
};
