import React, {useCallback, useContext, useMemo, useState} from "react";
import {groupBy, isNumber} from "lodash";
import {ConfigProvider, message, Tabs, Tooltip} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {EditSubAdminModal} from "./EditSubAdminModal";
import {CompanyProfileManageAdmins} from "./CompanyProfileManageAdmins";
import {CompanyBudgetHistoryModal} from "./CompanyBudgetHistoryModal";
import {CompanyInvoiceManage} from "./CompanyInvoiceManage";
import {CompanyBudgetLabel} from "../CompanyBudgetLabel";
import {ResetPasswordModal} from "../ResetPasswordModal";
import columns from "../../admin/AdminOrders/columns";
import {AppContext} from "../../AppContext";
import {RoundedTable, StyledButton} from "../../components";
import {useRequest} from "../../utils/hooks";
import {UpdateCompanyDetailsModal} from "../../marketplace/UpdateCompanyDetailsModal";
import {CompanyOrders} from "../orders/CompanyOrders";
import {attachCountryStateAndCity} from "../../wizards/wizardUtils";
import {HttpClient} from "../../http/HttpClient";
import {Languages} from "../../auth/Login";
import {EventBus} from "../../bus/EventBus";
import {getTotalBudget} from "../../utils/calculationUtils";
import {SavedOrderTable} from "../../components/savedOrder";
import "./company-profile.css";
import {PHONE_NUMBER_ERROR_VALIDATION} from "../../marketplace/consts";
import common from "@well-b/well-b-shared";
import {CompanyPermissionModal} from "../orders/CompanyPermissionModal.jsx";
import {StringBuilder} from "../../AppUtils.js";
import {ReportManagement} from "./Reports/ReportManagement.jsx";
import {reportsOptions, reportsOptionsToText} from "./Reports/consts.js";
import {ReportManagementSubAdminUsage} from "./Reports/reportManagementSubAdminUsage/ReportManagementSubAdminUsage.jsx";
import {openWindow} from "../../http/WindowOpener.jsx";

export const CompanyAccountProfile = ({history, match}) => {
    const {me, company, services, fetchCompany} = useContext(AppContext);

    const [orders = [], isOrdersLoading] = useRequest(`/api/companies/orders/${match.params.companyId}/open`);

    const [changePasswordModalVisibility, setChangePasswordModalVisibility] = useState(false);
    const [updateCompanyInfoModalVisibility, setUpdateCompanyInfoModalVisibility] = useState(false);
    const [updateSubAdminInfo, setUpdateSubAdminInfo] = useState(null);
    const [loadBudgetModalVisibility, setLoadBudgetModalVisibility] = useState(false);
    const [budgetHistoryModalVisibility, setBudgetHistoryModalVisibility] = useState(false);
    const [isCompanyPermissionModalOpen, setIsCompanyPermissionModalOpen] = useState(false);

    const isManager = useMemo(() => me?.isCompanyMaster || me?.manageDepartments?.length, [me]);

    const isAllowedToInvoiceTab = useMemo(() => {
        if (me?.isCompanyMaster) {
            return true;
        }

        return company?.separateSubAdminInvoices;
    }, [me?.isCompanyMaster, company?.separateSubAdminInvoices]);

    const [companyBudgets = [], loadingCompanyBudgets, _, fetchCompanyBudgets] = useRequest(
        "/api/budget/company",
        "get",
        null,
        [],
        !!isManager
    );

    const companyBudgetsByUserId = useMemo(
        () => groupBy(companyBudgets?.budgets ?? [], budget => budget.userId),
        [companyBudgets]
    );

    const remainingBudgets = useMemo(
        () =>
            isManager
                ? companyBudgets?.totalBudget
                : getTotalBudget(
                      me.budget,
                      services?.map(({serviceId}) => serviceId)
                  ),
        [isManager, companyBudgets, me.budget, services]
    );

    const onSaveCompanyDetails = useCallback(
        async values => {
            const valuesToUpdate = attachCountryStateAndCity(values);
            setUpdateCompanyInfoModalVisibility(false);
            const results = await HttpClient.safePost(
                `/api/companies/${company.companyId}/updateCompanyOrderDetails`,
                valuesToUpdate
            );

            if (results?.error) {
                message.error("קרתה שגיאה בשמירת פרטי החברה, יש לרפרש את העמוד לנסות שנית");
            } else {
                EventBus.trigger("company:update", results);

                message.success("הפרטים נשמרו בהצלחה!", 5);
            }
        },
        [company]
    );

    const onSaveSubAdmin = useCallback(
        async values => {
            const {budget, ...newInfo} = values;

            const user = {...me, ...newInfo};

            await HttpClient.safePost(`/api/companies/me/employees/${user.userId}`, user);

            setUpdateSubAdminInfo(null);
            EventBus.trigger("me:update", {...user});
        },
        [me]
    );

    const updateBudget = useCallback(() => {
        EventBus.trigger("me:updateBudget");
        fetchCompanyBudgets();
    }, [fetchCompanyBudgets]);

    const companyInitValues = useMemo(
        () => ({
            contactName: company?.contactName,
            contactEmail: company?.contactEmail,
            phone: company?.phone
        }),
        [company]
    );

    const onCompanyPermissionClick = useCallback(() => {
        setIsCompanyPermissionModalOpen(true);
    }, []);

    const onCompanyPermissionClose = useCallback(() => {
        setIsCompanyPermissionModalOpen(false);
    }, []);

    const onPermissionSave = useCallback(
        async permissions => {
            onCompanyPermissionClose();
            const updatedCompany = await HttpClient.safePut(
                `/api/companies/${company.companyId}/updateCompanyPermissions`,
                {
                    needManagerConfirm: !!permissions.needManagerConfirm,
                    allowBudgetTransferByManageDepartment: !!permissions.allowBudgetTransferByManageDepartment
                }
            );
            if (updatedCompany.error) {
                message.error("שגיאה קרתה במהלך שמירת הרשאות, אנא נסה שנית");
                return;
            }
            fetchCompany();
            message.success("הרשאות נשמרו בהצלחה");
        },
        [company, onCompanyPermissionClose, fetchCompany]
    );

    const reports = useMemo(() => {
        return [
            {
                key: 1,
                title: reportsOptionsToText[reportsOptions.extractCompanySubAdminReport],
                shouldShow: me?.features?.report_subadmin_usage && me?.isCompanyMaster,
                component: <ReportManagementSubAdminUsage companyId={match?.params?.companyId} />
            }
        ].filter(reportOption => reportOption.shouldShow);
    }, [me?.isCompanyMaster, match?.params?.companyId, me?.features?.report_subadmin_usage]);

    const onEditOrder = useCallback(order => {
        openWindow(`/events/${order.eventId}/edit`);
    }, []);

    return (
        <div className="company-profile-layout">
            <ConfigProvider direction="rtl">
                <CompanyPermissionModal
                    visible={isCompanyPermissionModalOpen}
                    onClose={onCompanyPermissionClose}
                    onSave={onPermissionSave}
                />
                {me.isCompanyMaster ? (
                    <UpdateCompanyDetailsModal
                        title="עריכת פרטי חשבון"
                        onSave={onSaveCompanyDetails}
                        visible={updateCompanyInfoModalVisibility}
                        onClose={() => setUpdateCompanyInfoModalVisibility(false)}
                        initFormValues={companyInitValues}
                        contactSection={{
                            title: "פרטי איש קשר",
                            fields: [
                                {
                                    name: ["contactName"],
                                    initialValue: ["contactName"],
                                    label: "שם מלא",
                                    rules: [
                                        {
                                            required: true,
                                            message: "יש להזין שם מלא"
                                        }
                                    ]
                                },
                                {
                                    name: ["phone"],
                                    initialValue: ["phone"],
                                    label: "טלפון",
                                    type: "number",
                                    rules: [
                                        {
                                            required: true,
                                            message: "יש להזין מספר טלפון"
                                        },
                                        {
                                            validator: (_, value) => {
                                                return common.isValidPhoneMobileNumber(value)
                                                    ? Promise.resolve()
                                                    : Promise.reject(PHONE_NUMBER_ERROR_VALIDATION);
                                            }
                                        }
                                    ]
                                }
                            ]
                        }}
                    />
                ) : (
                    <EditSubAdminModal
                        visible={!!updateSubAdminInfo}
                        onSave={onSaveSubAdmin}
                        mainServices={services}
                        onClose={() => setUpdateSubAdminInfo(null)}
                        subAdmin={updateSubAdminInfo}
                        disables={["allowedPaymentsMethods", "allowedServices", "department", "manageDepartments"]}
                    />
                )}
                <CompanyBudgetHistoryModal
                    history={history}
                    visible={budgetHistoryModalVisibility}
                    onClose={() => setBudgetHistoryModalVisibility(null)}
                />
                <ResetPasswordModal
                    language={Languages.Hebrew}
                    title="שינוי סיסמה"
                    visible={changePasswordModalVisibility}
                    onClose={() => setChangePasswordModalVisibility(false)}
                />
                <div className="company-profile-title-layout">
                    <div style={{display: "flex", justifyContent: "flex-end", flexDirection: "row"}}>
                        <span className="company-profile-header" style={{marginLeft: 10}}>
                            {"פרופיל"}
                        </span>
                        <span className="company-profile-header">{company?.name}</span>
                    </div>
                    {!!me?.isCompanyMaster && (
                        <div>
                            <StyledButton className="company-permission-btn" onClick={onCompanyPermissionClick}>
                                הרשאות
                            </StyledButton>
                        </div>
                    )}
                </div>
                <Tabs
                    className="company-profile-tabs"
                    defaultActiveKey="1"
                    items={[
                        {
                            label: "החשבון שלי",
                            key: "1",
                            children: (
                                <>
                                    <div className="company-profile-general-info-section">
                                        <div className="company-profile-info-box-layout">
                                            <div style={{display: "flex", flexDirection: "column"}}>
                                                <span
                                                    className="company-profile-header"
                                                    style={{fontSize: 24, direction: "rtl"}}>
                                                    {"פרטי חשבון"}
                                                </span>
                                                <span className="company-profile-info-text">
                                                    {"שם מלא: "}
                                                    {me?.firstName} {me?.lastName}
                                                </span>
                                                <span className="company-profile-info-text">
                                                    {"מייל: "} {me?.email}
                                                </span>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    justifyContent: "flex-end",
                                                    flexWrap: "wrap",
                                                    gap: "5px"
                                                }}>
                                                <div
                                                    onClick={() =>
                                                        me.isCompanyMaster
                                                            ? setUpdateCompanyInfoModalVisibility(true)
                                                            : setUpdateSubAdminInfo(me)
                                                    }
                                                    className="company-profile-budget-layout"
                                                    style={{cursor: "pointer"}}>
                                                    <span className="company-profile-budget-text">
                                                        {"עריכת פרטי חשבון"}
                                                    </span>
                                                </div>
                                                <div
                                                    onClick={() => setChangePasswordModalVisibility(true)}
                                                    className="company-profile-budget-layout"
                                                    style={{cursor: "pointer"}}>
                                                    <span className="company-profile-budget-text">{"שינוי סיסמא"}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="company-profile-info-box-layout">
                                            <div style={{display: "flex", flexDirection: "column"}}>
                                                <span
                                                    className="company-profile-header"
                                                    style={{fontSize: 24, direction: "rtl"}}>
                                                    {new StringBuilder()
                                                        .append("יתרת תקציב")
                                                        .append(me?.isCompanyMaster ? "של הארגון" : "")
                                                        .append(me?.manageDepartments?.length ? "של המחלקה" : "")
                                                        .toString()}
                                                    <Tooltip
                                                        title={new StringBuilder()
                                                            .append("סך הסכום הזמין עבורך")
                                                            .append(isManager ? "ועבור המשתמשים שתחתייך" : "")
                                                            .toString()}>
                                                        <QuestionCircleOutlined className="company-profile-info-question-icon" />
                                                    </Tooltip>
                                                </span>
                                                {isNumber(remainingBudgets) ? (
                                                    <span className="company-profile-info-colored-text">
                                                        {remainingBudgets.toLocaleString()}₪
                                                    </span>
                                                ) : null}
                                                {isManager ? (
                                                    <span className="company-profile-info-text">
                                                        לשימוש {Object.keys(companyBudgetsByUserId ?? {}).length}{" "}
                                                        משתמשים
                                                    </span>
                                                ) : null}
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    width: "100%"
                                                }}>
                                                <div className="company-profile-labels-layout">
                                                    <div
                                                        onClick={() => setBudgetHistoryModalVisibility(true)}
                                                        className="company-profile-budget-layout">
                                                        <span className="company-profile-budget-text">
                                                            {"היסטוריית תקציב"}
                                                        </span>
                                                    </div>
                                                    <CompanyBudgetLabel />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <span className="company-profile-header" style={{fontSize: 24}}>
                                        הזמנות פתוחות
                                    </span>
                                    <RoundedTable
                                        rowKey="eventId"
                                        pagination={false}
                                        style={{marginTop: "20px"}}
                                        scroll={{x: 1400}}
                                        loading={isOrdersLoading}
                                        dataSource={orders}
                                        columns={columns(me, services, onEditOrder)}
                                    />
                                </>
                            )
                        },
                        {
                            label: "ניהול הזמנות",
                            key: "2",
                            children: <CompanyOrders withHeader={false} match={match} />
                        },
                        isManager
                            ? {
                                  label: "ניהול אדמינים",
                                  className: "companyProfileManageAdmin",
                                  key: "3",
                                  children: (
                                      <CompanyProfileManageAdmins
                                          budgets={companyBudgets?.budgets}
                                          fetchBudgets={updateBudget}
                                          budgetLoading={loadingCompanyBudgets}
                                          history={history}
                                          services={services}
                                      />
                                  )
                              }
                            : null,
                        isAllowedToInvoiceTab
                            ? {
                                  label: "ניהול חשבוניות",
                                  key: "4",
                                  children: <CompanyInvoiceManage />
                              }
                            : null,
                        {
                            label: "ניהול טיוטות הזמנה",
                            key: "5",
                            children: <SavedOrderTable history={history} />
                        },
                        ...(reports?.length
                            ? [
                                  {
                                      label: "דוחות",
                                      key: "6",
                                      children: (
                                          <ReportManagement
                                              companyId={match?.params?.companyId}
                                              reports={reports}
                                              history={history}
                                          />
                                      )
                                  }
                              ]
                            : [])
                    ]}></Tabs>
            </ConfigProvider>
        </div>
    );
};
