import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {debounce} from "lodash";
import {Editor} from "react-draft-wysiwyg";
import Modifier from "draft-js/lib/DraftModifier";
import {convertToRaw, EditorState, ContentState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {isHTML} from "../marketplace/MarketplaceUtils";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const EditableInput = ({style, onChange, value, disabled, toolbar}) => {
    const htmlToDraftJs = html => {
        if (html) {
            if (!isHTML(html)) {
                html = `${html}`;
            }
            const blocksFromHtml = htmlToDraft(html);
            const {contentBlocks, entityMap} = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            return EditorState.createWithContent(contentState);
        }
        return EditorState.createEmpty();
    };

    const [editorState, setEditorState] = useState(htmlToDraftJs(value));

    const editorRef = useRef(null);

    useEffect(() => {
        setEditorFocus();
    }, []);

    const setEditorFocus = () => {
        if (editorRef && editorRef.current) {
            editorRef.current.focus();
        }
    };
    const fixSmallFontSizes = useCallback(rawContentState => {
        rawContentState.blocks = rawContentState.blocks.map(block => ({
            ...block,
            inlineStyleRanges: block.inlineStyleRanges.filter(inlineStyle => inlineStyle.style !== "fontsize-small")
        }));
    }, []);

    const updateForm = useMemo(
        () =>
            debounce(newState => {
                const currentState = newState.getCurrentContent();
                const rawContentState = convertToRaw(currentState);

                fixSmallFontSizes(rawContentState);

                const html = draftToHtml(rawContentState, {trigger: "#", separator: " "}, true);

                onChange(html);
            }, 300),
        [onChange, fixSmallFontSizes]
    );

    const onEditorStateChange = newState => {
        setEditorState(newState);
        updateForm(newState);
    };

    const handleReturn = () => {
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            "\n",
            editorState.getCurrentInlineStyle()
        );
        setEditorState(EditorState.push(editorState, contentState, "insert-characters"));
        return true;
    };

    return (
        <div
            onClick={() => (!disabled ? setEditorFocus() : null)}
            style={{
                width: "480px",
                minHeight: "20em",
                border: "1px solid #E8EDF5",
                backgroundColor: "#FCFDFE",
                fontSize: "16px",
                borderRadius: "10px",
                outline: "none",
                ...style
            }}>
            <Editor
                toolbar={toolbar}
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={newState => onEditorStateChange(newState)}
                handleReturn={handleReturn}
            />
        </div>
    );
};
