import React, {useCallback, useEffect, useMemo, useRef} from "react";
import "../../dashboards/app-dashboard.css";
import "./segmented-option-filter.css";
import {ConfigProvider, Segmented} from "antd";
import classnames from "classnames";
import {NOOP} from "../../utils/NOOP.jsx";

export const SegmentedOptions = ({options, onChange, value}) => {
    const ref = useRef();
    const showComponent = useMemo(() => {
        const component = options.find(option => option.key === value);
        if (component?.component) {
            return component.component;
        }
    }, [value, options]);

    return (
        <div id="segmented-option-container">
            <ConfigProvider direction="rlt">
                <Segmented
                    ref={ref}
                    value={value}
                    size="large"
                    id="segmented-option-filter"
                    className={value}
                    options={options?.map(({key, text, image, className}, index) => ({
                        label: (
                            <div
                                key={index}
                                className={classnames("segmented-label-container", className, {
                                    selected: key === value
                                })}>
                                {image && <img className="segmented-label-icon" src={image} alt={index} />}
                                <div className="segmented-label-text">{text}</div>
                            </div>
                        ),
                        value: key
                    }))}
                    onChange={onChange}
                />

                {showComponent && <div className="option-component">{showComponent}</div>}
            </ConfigProvider>
        </div>
    );
};
