import React from "react";
import {ConfigProvider, Dropdown} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {SquareButton} from "../../../../components";
import "./filter-button-show.css";

export const ButtonFilter = ({menuOption, disabled = false, buttonTypeText}) => (
    <ConfigProvider direction="rtl">
        <Dropdown overlayClassName="display-by-filter-button" disabled={disabled} menu={menuOption} trigger={["click"]}>
            <SquareButton
                className={`display-btn marketplace-category-filter-button marketplace-category-filters-sorter`}>
                <span>{buttonTypeText}</span>
                <DownOutlined />
            </SquareButton>
        </Dropdown>
    </ConfigProvider>
);
