import {Button, Checkbox, Select, Tooltip, Input, Form, Upload, message} from "antd";
import {ContactUsPhone, FormInput, FormTextArea} from "../components/form";
import {Availability} from "../components/Availability";
import React, {useContext, useReducer, useState} from "react";
import Icon, {CloseOutlined} from "@ant-design/icons";
import {UploadClient} from "../http/UploadClient";
import {EventBus} from "../bus/EventBus";
import {SearchableSelect} from "../components/SearchableSelect";
import {Images} from "../images/Images";
import {UploadIcon} from "../icons";
import {EmailOrPhoneDetector} from "../utils/EmailOrPhoneDetector";
import {AutoDirectionProvider} from "../AutoDirectionProvider";
import _ from "lodash";
import {AppContext} from "../AppContext";
import {ProfessionType} from "../data/professions";
import {SmartImageContainer} from "../components/SmartImageContainer";

const {Dragger} = Upload;
const {Option} = Select;

export const ProviderExtraFields = {
    PROFESSION: "profession",
    DESCRIPTION: "description",
    EXPERIENCE: "experience",
    AVAILABILITY: "availability",
    PORTFOLIO_LINK: "portfolioLink",
    HAS_INSURANCE: "hasInsurance",
    HAS_CERTIFICATION: "hasCertification",
    PROVIDER_TYPE: "providerType",
    RATING: "rating"
};

export const ProviderSignUpExtraInfoFields = ({defaultDescription, disableProfession = false}) => {
    let {professions, me} = useContext(AppContext);
    const [description, setDescription] = useState(defaultDescription);
    const [selectedProfessions, setSelectedProfessions] = useState([]);

    const onDescriptionChange = _.throttle(
        desc => {
            setDescription(desc);
        },
        100,
        {trailing: true, leading: false}
    );

    const hasAvailability = availability => {
        if (!availability) return false;
        return Object.keys(availability).reduce(
            (hasAvailability, daySetting) => {
                if (availability[daySetting].selected) {
                    return (
                        hasAvailability &&
                        (availability[daySetting].morning ||
                            availability[daySetting].noon ||
                            availability[daySetting].evening)
                    );
                } else return hasAvailability;
            },
            Object.keys(availability).some(daySetting => availability[daySetting].selected)
        );
    };

    const onProfessionsSelect = value => {
        const profession = professions.find(pro => pro.name === value);
        setSelectedProfessions(selectedProfessions.concat([profession]));
    };

    const onProfessionsDeselect = value => {
        setSelectedProfessions(selectedProfessions.filter(pro => pro.name !== value));
    };

    const appointmentBasedProfession = selectedProfessions.find(pro => pro.type === ProfessionType.PER_ACTIVE_DAY);
    if (appointmentBasedProfession) {
        professions = [appointmentBasedProfession];
    } else if (selectedProfessions.length > 0) {
        professions = professions.filter(pro => pro.type !== ProfessionType.PER_ACTIVE_DAY);
    }

    return (
        <>
            <Form.Item
                name={"extra." + ProviderExtraFields.PROFESSION}
                rules={[
                    {
                        required: true,
                        message: "Please select profession."
                    },
                    {
                        validator: (_, value) => {
                            if (Array.isArray(value) && value.length > 3) {
                                return Promise.reject("Maximum 3 professions allowed.");
                            }
                            return Promise.resolve();
                        }
                    }
                ]}>
                <SearchableSelect
                    mode="multiple"
                    onDeselect={value => onProfessionsDeselect(value)}
                    disabled={disableProfession}
                    onSelect={value => onProfessionsSelect(value)}
                    className="wb-profession-selection"
                    placeholder="What is your profession (up to 3)?">
                    {professions.map(({name}) => (
                        <Option key={name} value={name}>
                            {name}
                        </Option>
                    ))}
                </SearchableSelect>
            </Form.Item>
            <Tooltip title="Keep it short and to the point, please avoid giving out contact information and your last name">
                <AutoDirectionProvider text={description}>
                    <Form.Item
                        name={"extra." + ProviderExtraFields.DESCRIPTION}
                        validateFirst={true}
                        rules={[
                            {
                                required: true,
                                message: "Please describe yourself."
                            },
                            {
                                validateTrigger: "onSubmit",
                                validator: (_, value) => {
                                    const hasEmailOrPhone = EmailOrPhoneDetector.detect(value);
                                    return hasEmailOrPhone
                                        ? Promise.reject("Emails or phone numbers are not allowed.")
                                        : Promise.resolve();
                                }
                            }
                        ]}>
                        <FormTextArea
                            onChange={e => {
                                e.persist();
                                onDescriptionChange(e.target.value);
                            }}
                            placeholder="Tell us about yourself in one or two sentences (please do not mention last name or phone number)"
                            autoSize={{minRows: 4, maxRows: 4}}
                        />
                    </Form.Item>
                </AutoDirectionProvider>
            </Tooltip>
            <Form.Item
                name={"extra." + ProviderExtraFields.EXPERIENCE}
                rules={[{required: true, message: "Please select your experience."}]}>
                <Select placeholder="Experience">
                    <Option value="1 - 2">1 - 2 Years</Option>
                    <Option value="2 - 3">2 - 3 Years</Option>
                    <Option value="3 - 5">3 - 5 Years</Option>
                    <Option value="6 - 8">6 - 8 Years</Option>
                    <Option value="8 - 10">8 - 10 Years</Option>
                    <Option value="10+">10+ Years</Option>
                </Select>
            </Form.Item>
            <Tooltip title="Nothing binding here, dont worry! Specify what usually are the best working days & hours for you">
                <Form.Item
                    name={"extra." + ProviderExtraFields.AVAILABILITY}
                    rules={[
                        {
                            required: true,
                            validator: async (_, value) => {
                                if (hasAvailability(value)) {
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject("Please select availability including the part of the day.");
                                }
                            }
                        }
                    ]}>
                    <Availability />
                </Form.Item>
            </Tooltip>
            <Form.Item
                name={"extra." + ProviderExtraFields.PORTFOLIO_LINK}
                rules={[
                    {
                        type: "url",
                        message: "Please enter valid URL."
                    }
                ]}>
                <FormInput placeholder="Linkedin / Instagram / Facebook Link (Optional)" />
            </Form.Item>
            <Form.Item
                name={"extra." + ProviderExtraFields.HAS_INSURANCE}
                style={{marginBottom: 10}}
                valuePropName="checked">
                <Checkbox>I have a professional insurance</Checkbox>
            </Form.Item>
            <div style={{display: "flex", marginBottom: 10}}>
                <Form.Item name={"extra." + ProviderExtraFields.HAS_CERTIFICATION} valuePropName="checked" noStyle>
                    <Checkbox className="certCheck" />
                </Form.Item>
                <div style={{marginLeft: 8}}>
                    <label
                        onClick={() => document.getElementsByClassName("certCheck")[0].click()}
                        style={{cursor: "pointer"}}>
                        I have a college degree / professional certification in my field
                    </label>
                </div>
            </div>
        </>
    );
};

export const ProviderSignUpExtraInfo = props => {
    const {onNext, values, form, onUploadChange, visible, professions, onValuesChange} = props;
    const [state, setState] = useReducer((state, newState) => ({...state, ...newState}), {
        uploading: false,
        imageFile: null,
        imageUrl: null,
        imageId: null
    });

    let isUploading = false;

    const uploadAsync = file => {
        isUploading = true;
        Promise.resolve().then(async () => {
            onUploadChange(true);
            setState({uploading: true});
            try {
                const formData = new FormData();
                formData.append("file", file);
                const {imageUrl, imageId} = await UploadClient.upload("PUT", "/api/provider/image", formData);
                setState({imageUrl, imageId});
            } catch (e) {
                EventBus.triggerError(
                    "server-error",
                    {content: {description: "Unfortunately we didn't manage to upload your image :("}},
                    e.message
                );
            }
            setState({uploading: false});
            isUploading = false;
            onUploadChange(false);
        });
    };

    const uploadProps = {
        name: "file",
        accept: "image/png,image/jpeg",
        showUploadList: false,
        beforeUpload: file => {
            if (file.size >= Images.SIZE_LIMIT) {
                EventBus.trigger("server-error", {
                    content: {
                        title: "Ohhh... Gush!",
                        subTitle: "This is a huge file!",
                        description: `The maximum allowed image size is ${
                            Images.SIZE_LIMIT / 1024 / 1024
                        }MB, your image is ${parseInt(file.size / (1024 * 1024))}MB.`,
                        hideSteps: true
                    },
                    cta: {
                        hide: true
                    }
                });
            } else {
                setState({uploading: true, imageFile: file});
                uploadAsync(file);
                form.setFields([{name: "image", errors: [], value: file}]);
            }
            return false;
        }
    };

    const normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList;
    };

    const {imageId, imageUrl, uploading} = state;

    return (
        <Form
            form={form}
            scrollToFirstError={true}
            onFinish={vals => onNext({...vals, imageId, imageUrl})}
            onValuesChange={onValuesChange}
            style={{
                padding: 10,
                minWidth: 360,
                maxWidth: 420,
                width: "100%",
                overflow: "hidden",
                visibility: visible ? "visible" : "hidden"
            }}
            initialValues={values}>
            {imageUrl ? (
                <div style={{display: "flex", justifyContent: "center"}}>
                    <SmartImageContainer
                        className="wb-image-container"
                        width={240}
                        height={240}
                        src={imageUrl}
                        transformations={{gravity: "faces"}}
                        style={{
                            border: "1px solid #E8EDF5",
                            color: "var(--secondary-color)",
                            borderRadius: 120,
                            minHeight: 240,
                            maxHeight: 240,
                            minWidth: 240,
                            maxWidth: 240,
                            marginBottom: 24
                        }}>
                        <CloseOutlined
                            onClick={() => setState({imageUrl: null})}
                            style={{
                                fontSize: 16,
                                color: "var(--secondary-color)",
                                position: "absolute",
                                left: 15,
                                top: 15
                            }}
                        />
                    </SmartImageContainer>
                </div>
            ) : (
                <Form.Item
                    name="image"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[
                        {
                            required: true,
                            validator: async () => {
                                if (!imageUrl && !isUploading) throw Error("Please upload a picture.");
                            }
                        }
                    ]}>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Dragger
                            disabled={uploading}
                            style={{
                                minHeight: 240,
                                maxHeight: 240,
                                minWidth: 240,
                                maxWidth: 240,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "1px dashed #E8EDF5",
                                backgroundColor: "#FCFDFE",
                                borderRadius: 120
                            }}
                            {...uploadProps}>
                            <Icon component={UploadIcon} style={{fontSize: 20}} />
                            <Button
                                loading={uploading}
                                type="link"
                                style={{fontSize: 16, padding: 7, color: "var(--secondary-color)"}}>
                                {uploading ? "Uploading" : "Upload Picture"}
                            </Button>
                        </Dragger>
                    </div>
                </Form.Item>
            )}
            <ProviderSignUpExtraInfoFields professions={professions} />
            <ContactUsPhone />
        </Form>
    );
};
