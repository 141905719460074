import React, {useEffect, useMemo, useState} from "react";
import {useRequest} from "../../utils/hooks.jsx";
import moment from "moment-timezone";

export const useProviderOrders = () => {
    const [searchInput, setSearchInput] = useState("");
    const [searchDate, setSearchDate] = useState([moment().startOf("month"), moment().endOf("month")]);
    const [searchByCreated, setSearchByCreated] = useState(true);
    const [searchStatuses, setSearchStatuses] = useState([]);
    const [innerOrders, setInnerOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const ordersReqBody = useMemo(
        () => ({
            from: searchDate[0].valueOf(),
            to: searchDate[1].valueOf(),
            byCreatedAt: searchByCreated,
            ...(searchStatuses?.length ? {statuses: searchStatuses} : {})
        }),
        [searchDate, searchByCreated, searchStatuses]
    );

    const [orders, loadingOrders, error, fetchOrders] = useRequest(
        "/api/events/provider/myOrders?sortBy=status",
        "POST",
        ordersReqBody,
        [ordersReqBody]
    );

    useEffect(() => {
        if (loadingOrders) {
            setIsLoading(true);
        }
    }, [loadingOrders]);

    useEffect(() => {
        setInnerOrders(orders);
        setIsLoading(false);
    }, [orders]);

    const filteredOrders = useMemo(
        () =>
            innerOrders?.filter(({productName, proposalId}) =>
                [productName, String(proposalId)].some(property =>
                    property?.toLowerCase()?.includes(searchInput?.toLowerCase())
                )
            ),
        [innerOrders, searchInput]
    );

    const isOrderLoading = useMemo(() => loadingOrders || isLoading, [isLoading, loadingOrders]);

    return {
        loadingOrders: isOrderLoading,
        searchDate,
        searchInput,
        fetchOrders,
        setSearchInput,
        setSearchDate,
        setSearchByCreated,
        setSearchStatuses,
        filteredOrders,
        searchByCreated,
        setInnerOrders
    };
};
