import React, {useCallback, useMemo, useState} from "react";
import {ConfigProvider, Modal, message} from "antd";
import moment from "moment";
import copyToClipboard from "copy-to-clipboard";
import {columns} from "./columns";
import {TableDateSearch} from "../components";
import {ProductsProvider} from "../Context/ProductsProvider";
import {EditSelectionPage} from "../components/SelectProductsModal/EditSelectionPage";
import {RoundedTable, SquareButton} from "../../components";
import PageTitleHeader from "../../components/PageTitle";
import {useRequest} from "../../utils/hooks";
import {HttpClient} from "../../http/HttpClient";
import "./admin-select-page-top.css";
import {TableDateSearchProvider} from "../Context/TableDateSearchContext.jsx";

const AdminSelectPage = () => {
    const [isDateSearchByCreatedAt, setIsDateSearchByCreatedAt] = useState(true);
    const [searchDate, setSearchDate] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [selectorPageVisibility, setSelectorPageVisibility] = useState(false);
    const [selectorPageValues, setSelectorPageValues] = useState(null);

    const [selectPages, loadingSelectPages, _, fetchSelectPages] = useRequest("/admin/api/selectPage");

    const filterSelectPages = useMemo(
        () =>
            selectPages?.filter(page => {
                const dateProperty = isDateSearchByCreatedAt ? page.createdAt : page.updatedAt;
                return (
                    page.name.toLowerCase().includes(searchText?.toLowerCase() ?? "") &&
                    (searchDate.length !== 2 || moment(dateProperty).isBetween(searchDate[0], searchDate[1]))
                );
            }),
        [selectPages, searchText, searchDate, isDateSearchByCreatedAt]
    );

    const openPage = useCallback(pageId => {
        const link = `${window.location.origin}/selectionPage/${pageId}`;
        window.open(link, "_blank");
    }, []);

    const copyLink = useCallback(pageId => {
        if (pageId) {
            copyToClipboard(`${window.location.origin}/selectionPage/${pageId}`);
            message.success("הקישור הועתק בהצלחה");
        } else {
            message.error("אירעה שגיאה בעת העתקת הקישור");
        }
    }, []);

    const duplicatePage = useCallback(async pageId => {
        const {error} = await HttpClient.safePut(`/admin/api/selectPage/${pageId}/duplicate`);

        if (error) {
            message.error("אירעה שגיאה בעת שיכפול עמוד");
            return;
        }

        fetchSelectPages();
        message.success("עמוד שוכפל בהצלחה");
    }, []);

    const runSelectionPageRules = useCallback(async selectPageId => {
        const {error} = await HttpClient.safeGet(`/admin/api/selectPage/runSelectionRules/${selectPageId}`);

        if (error) {
            message.error(`אירעה שגיאה בעת רענון אתר בחירה | מזהה אתר ${selectPageId}`);
            return;
        }

        fetchSelectPages();
        message.success("אתר הבחירה רוענן בהצלחה");
    }, []);

    const deletePage = useCallback(async selectPageId => {
        const {error} = await HttpClient.safeDelete(`/admin/api/selectPage/${selectPageId}`);

        if (error) {
            message.error("אירעה שגיאה בעת מחיקת אתר הבחירה");
            return;
        }

        fetchSelectPages();
        message.success("האתר נמחק בהצלחה");
    }, []);

    const createSelectPage = useCallback(() => {
        setSelectorPageValues({});
        setSelectorPageVisibility(true);
    }, []);

    const changeActiveStatus = useCallback(async (selectPageId, newStatus) => {
        const {error} = await HttpClient.safePost(`/admin/api/selectPage/${selectPageId}`, {isActive: newStatus});

        if (error) {
            message.error("אירעה שגיאה בעת עידכון מצב דף הבחירה");
            return;
        }

        message.success("מצב דף הבחירה שונה בהצלחה");
        fetchSelectPages();
    }, []);

    const changeIsolatedStatus = useCallback(async (selectPageId, newStatus) => {
        const {error} = await HttpClient.safePost(`/admin/api/selectPage/${selectPageId}`, {isIsolated: newStatus});

        if (error) {
            message.error("אירעה שגיאה בעת עידכון מצב דף הבחירה");
            return;
        }

        message.success("מצב דף הבחירה שונה בהצלחה");
        fetchSelectPages();
    }, []);

    return (
        <ProductsProvider>
            <div className="admin-select-page">
                <ConfigProvider direction="rtl">
                    <PageTitleHeader showBack={false}>אתרי בחירה</PageTitleHeader>
                    <div className="admin-select-page-top-bar">
                        <TableDateSearchProvider>
                            <TableDateSearch date={searchDate} onDatePicked={setSearchDate} onSearch={setSearchText} />
                        </TableDateSearchProvider>
                        <SquareButton onClick={createSelectPage}>הוספת אתר בחירה</SquareButton>
                    </div>
                    <RoundedTable
                        rowKey="selectPageId"
                        className="admin-select-page-table"
                        scroll={{x: 800, y: 720}}
                        pagination={false}
                        loading={loadingSelectPages}
                        dataSource={filterSelectPages}
                        columns={columns(
                            openPage,
                            page => {
                                setSelectorPageVisibility(true);
                                setSelectorPageValues(page);
                            },
                            copyLink,
                            deletePage,
                            changeActiveStatus,
                            changeIsolatedStatus,
                            duplicatePage,
                            runSelectionPageRules
                        )}
                    />

                    <Modal
                        maskClosable={false}
                        footer={null}
                        open={selectorPageVisibility}
                        width={1800}
                        destroyOnClose={true}
                        onCancel={() => {
                            setSelectorPageVisibility(false);
                            setSelectorPageValues(null);
                        }}>
                        <EditSelectionPage
                            selectPageId={selectorPageValues?.selectPageId}
                            onSave={() => {
                                setSelectorPageVisibility(false);
                                setSelectorPageValues(null);
                                fetchSelectPages();
                            }}
                        />
                    </Modal>
                </ConfigProvider>
            </div>
        </ProductsProvider>
    );
};

export default AdminSelectPage;
