import CallToActionModal from "../components/CallToActionModal";
import React, {useEffect, useState} from "react";
import {Typography, message, Spin} from "antd";
import {HttpClient} from "../http/HttpClient";
import {ModalTitle} from "../components/ModalTitle";
import {openWindow} from "../http/WindowOpener";
import {PageLoader} from "../components/PageLoader";

export const EventJoinModal = ({history, match}) => {
    const [event, setEvent] = useState(null);

    const {eventId} = match.params;

    useEffect(() => {
        (async () => {
            try {
                const event = await HttpClient.get(`/api/events/${eventId}`);
                setEvent(event);
            } catch (e) {
                message.error(e.message, 3);
            }
        })();
    }, []);

    if (!event) {
        return <PageLoader align="center" top={60} />;
    }

    const {serviceName, providerFirstName, providerLastName, sessionType} = event;

    const Title = () => (
        <ModalTitle
            title={serviceName}
            subTitle={
                <label>
                    {sessionType === "company" ? "by" : "with"}{" "}
                    <label style={{lineHeight: 1, color: "#1260D1"}}>{`${providerFirstName}${
                        providerLastName ? ` ${providerLastName}` : ""
                    }`}</label>
                </label>
            }
        />
    );

    const SubTitle = () => (
        <div style={{display: "flex", justifyContent: "center"}}>
            <Typography.Paragraph style={{textAlign: "center"}}>
                <Typography.Text style={{fontSize: 16}} strong>
                    Click to join the session
                </Typography.Text>{" "}
                or check out your company's calendar.
            </Typography.Paragraph>
        </div>
    );

    return (
        <CallToActionModal
            imageName="success2"
            title={<Title />}
            subTitle={<SubTitle />}
            actionText="Join the session"
            actionHandler={() => openWindow(`/api/events/${eventId}/track`, "_blank")}
            anotherActionText="Check out calendar"
            anotherActionHandler={() => history.push("/dashboard/schedule")}
            visible={true}
        />
    );
};
