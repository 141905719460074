import "@fullcalendar/core/vdom";
import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import rrulePlugin from "@fullcalendar/rrule";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
// import tippy from "tippy.js";
import moment from "moment-timezone";
import {PlusOutlined, PlusCircleFilled} from "@ant-design/icons";
import * as _ from "lodash";
// import * as $ from "jquery";
import shortid from "shortid";
import isMobileDevice from "is-mobile";
import {AppContext} from "../AppContext";
import {StyledButton} from "./StyledButton";
import {dateFormat} from "../utils/DateFormat";
import {eventByDate} from "../event/EventByDate";
import {Tooltip} from "antd";
import {EventBus} from "../bus/EventBus";
// import hexToRgba from "hex-to-rgba";
import {ImageCaching} from "../utils/ImageCaching";

export class Calendar extends React.Component {
    static contextType = AppContext;

    state = {
        dateClicked: null,
        currentStart: null,
        currentEnd: null,
        weekClicked: null
    };

    async componentDidMount() {
        this.toUpperCaseHeader();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.toUpperCaseHeader();
    }

    toUpperCaseHeader() {
        const dayHeaders = document.getElementsByClassName("fc-day-header");
        for (const dayHeader of dayHeaders) {
            dayHeader.innerHTML = dayHeader.innerHTML.toUpperCase();
        }

        const {theme, isMobile} = this.context;
        if (isMobile) {
            setTimeout(() => {
                $(".fc-widget-header").attr(
                    "style",
                    `background-color: ${theme.secondary} !important; color: ${theme.textSecondary} !important;`
                );
            }, 0);
        }
    }

    markAsPending(el) {
        const {pendingCursor} = this.props;

        el.style.opacity = "0.7";
        el.style.cursor = pendingCursor;
        el.className = (el.className || "") + " wb-event-line-stripes";
    }

    addTooltip(el, startTime, endTime, extendedProps) {
        const serviceName = extendedProps.productName || extendedProps.serviceName || extendedProps.sessionName;

        let content = `
            <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                <label style='font-size: 12px; font-weight: 700; color: white'>
                    <span style="margin-right: 5px">${serviceName}</span> | <span style="margin-left: 5px">${startTime} - ${endTime}</span>
                </label>
                <label style='font-size: 12px; color: white'>${extendedProps["extra.tooltipText"]}</label>
            </div>
        `;

        if (extendedProps.sessionType === "special") {
            content = `
            <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                <label style='font-size: 12px; font-weight: 700; color: white'>
                    <span style="margin-right: 5px">${serviceName}</span>
                </label>
                <label style='font-size: 12px; color: white'>${extendedProps["extra.tooltipText"]}</label>
            </div>
        `;
        }

        tippy("#" + el.id, {
            touch: false,
            content,
            theme: "translucent",
            allowHTML: true,
            onUntrigger(instance) {
                instance.destroy();
            }
        });
    }

    openNewSession(date, startEnd) {
        const {onNewSession} = this.props;

        onNewSession(date, startEnd);
    }

    hasMoreNonSpecialEvents(events, currentDate) {
        return events.some(evt => {
            const calEvt = evt.extendedProps;
            return (
                calEvt.sessionType !== "special" &&
                moment(evt.start).format("YYYY-MM-DD") === moment(currentDate).format("YYYY-MM-DD")
            );
        });
    }

    findSpecialEventForDate(events, date) {
        return events.find(evt => {
            const calEvt = evt.extendedProps;
            return (
                calEvt.sessionType === "special" &&
                moment(evt.start).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")
            );
        });
    }

    isNonCurrentEventDay(currentStart, date) {
        const startOfMonth = moment(currentStart).startOf("day");
        const endOfMonth = moment(currentStart).endOf("month");
        return moment(date).isBefore(startOfMonth) || moment(date).isAfter(endOfMonth);
    }

    markNonCurrentEvents(el, currentStart, date) {
        if (this.isNonCurrentEventDay(currentStart, date)) {
            el.style.backgroundColor = "#F1F1F1";
        }
    }

    isMoreThanOneDayEvent(event) {
        return moment(event.end).format("YYYY-MM-DD") !== moment(event.start).format("YYYY-MM-DD");
    }

    addInteractiveBackgroundForSpecialEvent(el, events, date) {
        const {enableSpecialInteraction} = this.props;

        const todaySpecialEvent = events.find(evt => {
            const dateMom = moment(date);
            const startMom = moment(evt.start).startOf("day");
            const endMom = moment(evt.end).endOf("day");
            const calEvt = evt.extendedProps;
            return (
                calEvt.sessionType === "special" &&
                !this.isMoreThanOneDayEvent(evt) &&
                dateMom.isSameOrAfter(startMom) &&
                dateMom.isSameOrBefore(endMom)
            );
        });

        if (todaySpecialEvent) {
            const calEvt = todaySpecialEvent.extendedProps;
            const className = `wb-special-evt-${calEvt.eventId}`;

            if ($(el).hasClass(className)) {
                return;
            }

            $(el).addClass(className);
            let image = ImageCaching.pop(calEvt.eventId);
            if (!image) {
                image = $(
                    `<div class="wb-special-event-image" style="background-image: url(${calEvt.productCoverPhoto})"/>`
                );
                ImageCaching.push(calEvt.eventId, image[0]);
            }

            el.style.position = "relative";
            const title = $("<span/>")
                .css({
                    zIndex: 1
                })
                .text(calEvt.sessionName);

            const button = enableSpecialInteraction
                ? $("<span/>")
                      .css({
                          fontWeight: "bold",
                          cursor: "pointer",
                          marginBottom: "10px",
                          zIndex: 1
                      })
                      .text("+ Add event")
                : null;

            const innerContainer = $("<div/>")
                .css({
                    position: "absolute",
                    bottom: "0px",
                    left: "0px",
                    width: "100%",
                    paddingBottom: "5px",
                    height: "60px",
                    color: "white",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    flexDirection: "column",
                    fontSize: 12,
                    background: "linear-gradient(to top,rgba(0,0,0,0.6),rgba(255,255,255,0))"
                })
                .append(title);

            if (button) {
                innerContainer.append(button);
            }

            const content = $("<div class='wb-special-evt'/>")
                .css({
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    width: "100%",
                    height: "100%"
                })
                .append(image)
                .append(innerContainer);
            $(el).append(content);
        }
    }

    getAppointmentBasedEventStart = event => {
        return event.start ? moment(event.start).format("HH:mm") : event.extendedProps.minTime;
    };

    getAppointmentBasedEventEnd = event => {
        return event.end ? moment(event.end).format("HH:mm") : event.extendedProps.minTime;
    };

    render() {
        const {isMobile, company} = this.context;

        const {currentStart, currentEnd} = this.state;

        let {events, onEventClick, onNewSession, onSpecialEventsVisibilityChange, height} = this.props;

        height = height || (isMobileDevice() ? "auto" : (window.screen.availHeight / 1.5) | 0);

        return (
            <div
                className="wb-calendar"
                data-title="Introducing your company calendar"
                data-intro="Track our recommendations based on your unique organizational character. Edit and manage content for your employees."
                data-namespace="admin"
                data-position="top"
                data-step="1"
                data-highlightClass="wb-walk-through-highlight-calendar"
                data-scrollTo="tooltip"
                style={{display: "block", position: "relative", padding: 10, paddingTop: 20, maxWidth: 1200}}>
                {onNewSession ? (
                    <div
                        className="wb-flex-mobile-only"
                        style={{
                            alignItems: "center",
                            cursor: "pointer",
                            position: "absolute",
                            left: 10,
                            top: 70,
                            fontSize: 16
                        }}
                        onClick={() => this.openNewSession(null, {start: currentStart, end: currentEnd})}>
                        <PlusCircleFilled style={{marginRight: 8}} />
                        New Session
                    </div>
                ) : null}
                {onNewSession ? (
                    <>
                        <Tooltip title="Add your company's internal events & bookings with your existing providers. You'll enjoy platform's tools as booking, mailing and insights for these events as well!">
                            <StyledButton
                                className="wb-flex-desktop-only wb-calendar-top-action"
                                onClick={() => this.openNewSession()}
                                data-title="Create events for your employees"
                                data-intro="On-board your existing vendors and their regular events, create  internal company events or invite employees to host events."
                                data-namespace="admin"
                                data-position="bottom"
                                data-step="2"
                                data-highlightClass="wb-walk-through-highlight-create-event"
                                data-scrollTo="tooltip"
                                style={{
                                    borderRadius: 5,
                                    height: 40,
                                    backgroundColor: "#ED7FA6",
                                    borderColor: "#ED7FA6",
                                    color: "#FFFFFF",
                                    fontSize: 14,
                                    display: "flex",
                                    alignItems: "center",
                                    position: "absolute",
                                    right: 212,
                                    top: 20,
                                    minWidth: 140,
                                    padding: 0,
                                    boxShadow: "none"
                                }}>
                                Create event
                            </StyledButton>
                        </Tooltip>
                        <Tooltip title="Create events themed with this month’s national days and holidays.">
                            <StyledButton
                                className="wb-flex-desktop-only wb-calendar-top-action"
                                onClick={() => onSpecialEventsVisibilityChange()}
                                style={{
                                    borderRadius: 5,
                                    backgroundColor: "#FFFFFF",
                                    color: "var(--secondary-color)",
                                    fontSize: 14,
                                    height: 40,
                                    display: "flex",
                                    alignItems: "center",
                                    position: "absolute",
                                    right: 365,
                                    top: 20,
                                    minWidth: 215,
                                    padding: 0,
                                    boxShadow: "none",
                                    outline: "none"
                                }}>
                                {company.hideSpecialEvents
                                    ? "Hide Special dates & holidays"
                                    : "Show Special dates & holidays"}
                            </StyledButton>
                        </Tooltip>
                    </>
                ) : null}
                <FullCalendar
                    timeZone={moment.tz.guess()}
                    buttonText={{today: "Today"}}
                    noEventsContent="No upcoming sessions this week"
                    headerToolbar={{
                        left: "title",
                        center: "",
                        right: isMobile ? "prev,next" : "today prev,next"
                    }}
                    views={{
                        dayGridMonth: {
                            // name of view
                            titleFormat: {year: "numeric", month: "long"}
                        },
                        dayGridDay: {
                            // name of view
                            titleFormat: {month: "short", day: "numeric"}
                        },
                        listWeek: {
                            titleFormat: {month: "short", day: "numeric"}
                        }
                    }}
                    nowIndicator={false}
                    showNonCurrentDates={false}
                    fixedWeekCount={false}
                    plugins={[dayGridPlugin, rrulePlugin, listPlugin, interactionPlugin]}
                    height={height}
                    initialView={isMobile ? "listWeek" : "dayGridMonth"}
                    events={events}
                    eventTimeFormat={{
                        // like '14:30'
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false
                    }}
                    eventDidMount={({el, event}) => {
                        if (["pending", "cancel-pending"].some(status => event.extendedProps.status === status)) {
                            this.markAsPending(el);
                        }

                        el.id = "evt-" + shortid.generate();
                        $(el).addClass("wb-cal-evt").css({position: "relative"});

                        let timeText = "";
                        const calEvt = eventByDate(event.extendedProps, event.start);
                        if (calEvt.appointmentBased) {
                            timeText = calEvt.minTime;
                        } else {
                            timeText = (calEvt.includeDates || []).reduce((text, ts) => {
                                const included = moment(ts).format("YYYY-MM-DD");
                                const evtDate = moment(event.start).format("YYYY-MM-DD");
                                if (evtDate === included) {
                                    text = moment(ts).format("HH:mm");
                                }

                                return text;
                            }, moment(event.start).format("HH:mm"));
                        }

                        if (calEvt.sessionType !== "special") {
                            $(".fc-event-time", el).text(`${timeText} |`);
                            $(".fc-list-item-time", el).text(timeText);
                        } else {
                            if (
                                this.hasMoreNonSpecialEvents(events, event.start) ||
                                this.isMoreThanOneDayEvent(event)
                            ) {
                                addSpecialEventStyling();
                            } else {
                                el.style.display = "none";
                            }
                        }

                        if (calEvt.subtitle) {
                            $(".fc-event-main-frame", el).parent().append(calEvt.subtitle);
                        }

                        function addSpecialEventStyling() {
                            el.style.backgroundColor = hexToRgba(calEvt.backgroundColor, "0.15") || "#F6EBF2";
                            el.style.color = calEvt.backgroundColor || "#BF7AA9";
                            el.style.fontWeight = "400";
                            el.style.fontSize = "12px";
                            el.style.padding = "1px 8px";
                            el.style.borderRadius = "10px";
                            el.style.marginBottom = "5px";
                            el.style.padding = "4px 11px";
                            clearSpecialEventDate(".fc-event-time");
                            clearSpecialEventDate(".fc-list-item-time");
                        }

                        function clearSpecialEventDate(selector) {
                            $(selector, el).html("");
                        }
                    }}
                    dayCellDidMount={({el, date, view: {currentStart}}) => {
                        this.markNonCurrentEvents(el, currentStart, date);
                        if (
                            !this.hasMoreNonSpecialEvents(events, date) &&
                            !this.isNonCurrentEventDay(currentStart, date)
                        ) {
                            this.addInteractiveBackgroundForSpecialEvent(el, events, date);
                        }
                    }}
                    datesSet={({view: {currentStart, currentEnd}}) => {
                        this.toUpperCaseHeader();
                        this.setState({currentStart, currentEnd});
                    }}
                    eventClick={({event}) => {
                        const clickedEvent = _.cloneDeep(event.extendedProps);
                        clickedEvent.backgroundColor = event.backgroundColor;
                        if (clickedEvent.sessionType === "special") {
                            EventBus.trigger("special_event:click", {
                                event: clickedEvent,
                                date: event.start
                            });
                        } else {
                            delete clickedEvent["extra.tooltipText"];
                            onEventClick(clickedEvent, {start: event.start, end: event.end || event.start});
                        }
                    }}
                    eventMouseEnter={({el, event}) => {
                        const {start, end, extendedProps} = event;
                        const startTime = extendedProps.appointmentBased
                            ? this.getAppointmentBasedEventStart(event)
                            : moment(start).format("HH:mm");
                        const endTime = extendedProps.appointmentBased
                            ? this.getAppointmentBasedEventEnd(event)
                            : end
                            ? moment(end).format("HH:mm")
                            : startTime;
                        this.addTooltip(el, startTime, endTime, extendedProps);
                    }}
                    dateClick={({dateStr}) => {
                        const specialEvent = this.findSpecialEventForDate(events, dateStr);
                        if (specialEvent) {
                            EventBus.trigger("special_event:click", {
                                event: specialEvent.extendedProps,
                                date: dateStr
                            });
                        } else if (
                            moment(dateFormat(dateStr), "YYYY-M-D").isSameOrAfter(
                                moment(Date.now()).subtract(1, "days")
                            )
                        ) {
                            if (onNewSession) {
                                this.openNewSession(dateStr, null);
                            }
                        }
                    }}
                />
            </div>
        );
    }
}
