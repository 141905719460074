import {
    ADDRESS_EXCEL_EXAMPLE_FILE_NAME,
    ADDRESS_EXCEL_EXAMPLE_FILE_URL,
    employeesExcelKeyPositions,
    employeesExcelKeyToDisplayName,
    requiredEmployeesExcelKeys
} from "./consts";
import {downloadFromUrl} from "../../utils/downloadFromUrl";
import {getCellText} from "../../utils/excelUtils.js";
import {StringBuilder} from "../../AppUtils.js";
import {HttpClient} from "../../http/HttpClient.jsx";

export const downloadTemplate = async () => {
    await downloadFromUrl(ADDRESS_EXCEL_EXAMPLE_FILE_URL, ADDRESS_EXCEL_EXAMPLE_FILE_NAME);
};

export const validateExcel = async workbook => {
    const sheet = workbook.worksheets[0];
    let rowsCount = 0;

    const requiredColumns = [3, 4, 5, 6, 7, 8];

    const HEADERS_ROW = 2;
    const END_COLUMN_INDEX = 12;

    const defaultValueForRequiredColumns = "חובה";

    const headerRows = sheet.getRow(HEADERS_ROW).values;

    const errorParagraph = [];

    sheet.eachRow(({values: row, number}) => {
        if (!row.slice(0, END_COLUMN_INDEX).filter(cell => cell).length) {
            return;
        }

        const errorMessage = requiredColumns
            .filter(
                columnIndex => !row[columnIndex] || getCellText(row[columnIndex]) === defaultValueForRequiredColumns
            )
            .map(columnIndex => getCellText(headerRows[columnIndex]))
            .join(", ");

        if (errorMessage) {
            errorParagraph.push(`בשורה ${number} חסר ${errorMessage}`);
        }
        rowsCount++;
    });

    return {rowsCount: rowsCount - 2, error: errorParagraph};
};

export const validateAndTransformDeliveryProviderExcel = async workbook => {
    const sheet = workbook.worksheets[0];
    const addressesArray = [];
    const errorList = [];

    const END_COLUMN_INDEX = 12;

    sheet.eachRow(({values: row}, rowNumber) => {
        const isRowEmpty = !row.slice(0, END_COLUMN_INDEX).filter(cell => cell).length;

        if (isRowEmpty || rowNumber <= 2) {
            return;
        }

        const objectRow = {};
        row.forEach((rowItem, rowItemPosition) => {
            const columnName = employeesExcelKeyPositions[rowItemPosition];
            objectRow[columnName] = rowItem;
        });

        const missingFields = [];
        requiredEmployeesExcelKeys.forEach(field => {
            if (!objectRow[field]) {
                missingFields.push(employeesExcelKeyToDisplayName[field]);
            }
        });

        if (missingFields.length > 0) {
            errorList.push(
                new StringBuilder()
                    .append("שגיאה!")
                    .append("השדות הבאות חסרים")
                    .append(`"${missingFields.join(", ")}"`)
                    .append("בשורה")
                    .append(rowNumber)
                    .toString()
            );

            return;
        }

        addressesArray.push(objectRow);
    });

    if (errorList.length) {
        return {errorList};
    }

    const validateAddressesRes = await HttpClient.safePost("/api/events/validateAddresses", {
        addresses: addressesArray
    });

    if (validateAddressesRes?.error && validateAddressesRes?.extraInfo?.length) {
        return {
            errorList: [
                "לא הצלחנו לאמת את הכתובות הבאות, אפשר ליצור קשר עם מנהלת הלקוח לעזרה בסידור הכתובות",
                ...validateAddressesRes?.extraInfo
            ]
        };
    } else if (validateAddressesRes?.error) {
        return {
            errorList: ["שגיאה בעת אימות האקסל כתובות - צור קשר עם מנהלת הלקוח", validateAddressesRes?.error]
        };
    }

    return {addressesArray, errorList};
};
